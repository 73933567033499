import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ExecutionButton } from 'components/buttons/execution-button';

export interface CheckboxPopupProps {
    onClose: () => void;
    onSubmit: (values: number[]) => void;
    items: { value: number, name: string }[];
    defaultValues: number[];
    submitButton?: string;
    header: string;
    itemIdPrefix?: string;
    messageNoItems?: string;
}


export const CheckboxPopup: React.FC<CheckboxPopupProps> = (props) => {
    const [ selectedVals, setSelectedVals ] = useState(new Set<number>([]));
    
    useEffect(() => {
        const vals = props.defaultValues.filter(v => props.items.some(i => i.value === v));
        setSelectedVals(new Set(vals))

    }, [ props.items, props.defaultValues ]);


    const chkId = (idx: number) => `${props.itemIdPrefix ?? "modchk"}_${idx}`;

    const onChange = (value: number, checked: boolean) => {
        const newSet = new Set(selectedVals);
        if (checked) {
            newSet.add(value);
        } else {
            newSet.delete(value);
        }
        setSelectedVals(newSet);
    }

    return (
        <div>
            <Modal isOpen={true} className="modal-slim" scrollable={true}>
                <ModalHeader toggle={props.onClose}>{props.header}</ModalHeader>
                <ModalBody>
                    <div>
                        { props.items.length === 0 && props.messageNoItems && (
                            <div>{props.messageNoItems}</div>
                        )}
                        {
                            props.items.map((item, i) => (
                                <div key={i} className="form-group row">
                                    <div className="checkbox checkbox-css m-l-15">
                                        <input type="checkbox" id={chkId(i)}
                                            onChange={(e) => onChange(item.value, e.target.checked)}
                                            checked={selectedVals.has(item.value)} />
                                        <label htmlFor={chkId(i)}>{item.name}</label>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </ModalBody>
                <ModalFooter className="modal-footer-fix">
                    <ExecutionButton type="decide"
                        onClick={() => props.onSubmit(props.items.filter(i => selectedVals.has(i.value)).map(i => i.value))}
                    >{props.submitButton ?? "確定"}</ExecutionButton>
                </ModalFooter>
            </Modal>
        </div>
    )
}