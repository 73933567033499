import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ExecutionButton } from 'components/buttons/execution-button';
import styles from './robot-import.module.css';
import { RobotImportReqDuplicatedEnum } from 'api';
import { CommonSelect, SelectOption } from 'components/parts/common-select';
import { RobotImportSetting } from './robot-import';
import { useRanchFeeds } from 'stores/fetcher';
import { FetchWaiter, FetchError } from 'components/content/fetch-state';
import { ModalInputBlock } from 'components/parts/modal-input-block';
import { FormRadio } from 'components/form/form-radio';
import { LMT } from 'config/constant';

const DEFAULT_DISCARD_DESTS = ["M4USE", "乳タンク２", "バケツ", "下水", "子牛乳ライン"] as const;

const DUPLICATE_ITEMS = [
    { key: RobotImportReqDuplicatedEnum.ABORT, value: 1, name:"取り込みを中断する" },
    { key: RobotImportReqDuplicatedEnum.SKIP, value: 2, name:"スキップする" },
    { key: RobotImportReqDuplicatedEnum.ADD, value: 3, name:"追加で取り込む" },
];

interface Props {
    ranchId: number;
    original: RobotImportSetting | undefined;
    onClose: () => void;
    onSubmit: (setting: RobotImportSetting) => void;
}

type Editing = Omit<RobotImportSetting, "discard_dests"> & {
    discards: string;
}

export const RobotImportSettingPopup = React.memo((props: Props) => {
    const mFeeds = useRanchFeeds(props.ranchId)

    const [ setting, setSetting ] = useState<Editing>({ duplicated: RobotImportReqDuplicatedEnum.ABORT, discards:"", feed_no:undefined });
    const [ feedOptions, setFeedOptions ] = useState<SelectOption<number>[]>([]);

    useEffect(() => {
        if (mFeeds.data == null) return;

        let feedNo = props.original?.feed_no;
        if (feedNo != null && !mFeeds.data.some(m => m.feed_no === feedNo)) {
            //マスタに含まれていなかったら破棄
            feedNo = undefined;
        }

        setSetting({
            duplicated: props.original?.duplicated ?? RobotImportReqDuplicatedEnum.ABORT,
            discards: (props.original?.discard_dests ?? DEFAULT_DISCARD_DESTS).join(","),
            feed_no: feedNo
        });

    }, [ mFeeds.data, props.original ]);

    useEffect(() => {
        if (mFeeds.data == null) {
            setFeedOptions([]);
            return;
        }
        setFeedOptions(mFeeds.data.map(f => ({ label:f.name, value:f.feed_no })));

    }, [ mFeeds.data ]);

    const onSubmit = () => {
        props.onSubmit({
            ...setting,
            discard_dests: setting.discards.split(",").map(s => s.trim()).filter(s => s !== "")
        });
    }

    return (
        <div>
            <Modal isOpen={true} centered={true} scrollable={true}>
                <ModalHeader toggle={props.onClose}>取込設定</ModalHeader>
                { mFeeds.isLoading ? (
                    <FetchWaiter />
                ) : (mFeeds.isError || mFeeds.data == null) ? (
                    <FetchError />
                ) : (<>
                    <ModalBody>
                        <ModalInputBlock label="えさ品目">
                            <CommonSelect inputId="inputFeed"
                                options={feedOptions}
                                value={setting.feed_no}
                                onSelect={val => setSetting({ ...setting, feed_no:val })}
                                onClear={() => setSetting({ ...setting, feed_no:undefined })}
                                onCreate={undefined}
                                itemName="えさ"
                            />
                        </ModalInputBlock>
                        <ModalInputBlock label="重複確認">
                            <div className={styles["setting-hint"]}>同じ日時の記録が存在する場合</div>
                            <FormRadio
                                options={DUPLICATE_ITEMS}
                                isInline={false}
                                value={DUPLICATE_ITEMS.find(d => d.key === setting.duplicated)?.value}
                                onChange={val => setSetting({ ...setting, duplicated:DUPLICATE_ITEMS.find(d => d.value === val)!.key})}
                                prefix="radDup"
                            />
                        </ModalInputBlock>
                        <ModalInputBlock label="廃棄分離先">
                            <div className={styles["setting-hint"]}>※カンマ(,)区切りで入力</div>
                            <input type="text"
                                className="form-control w-100"
                                maxLength={LMT.ROBOT.DESTS_ALL_MAX_LEN}
                                value={setting.discards}
                                onChange={e => setSetting({ ...setting, discards:e.target.value})}
                            />
                        </ModalInputBlock>

                    </ModalBody>
                    <ModalFooter className="modal-footer-fix">
                        <ExecutionButton type="decide" onClick={onSubmit} />
                    </ModalFooter>
                </>)}
            </Modal>
        </div>
    );
});