import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { PageSettings } from '../../config/page-settings';
import { withContext } from '../../stores';
import moment from 'moment';
import { CommonUtil, saveTextFile, buildCsvRow } from '../../config/util';
import { AppState } from '../../app';
import styles from './report-sellcow.module.css';
import { COW_SELL_TYPE, COW_SELL_TYPES, ICowSellType } from '../../config/constant';
import { RequiredTermSelector } from '../../components/parts/optional-term-selector';
import { FormRadio } from '../../components/form/form-radio';
import { SellCowAggregatePartnerListDto, SellCowAggregatePartnerReq, SellCowApi, SellCowAggregateCarcassDto, SellCowAggregateCarcassReq, SellCowAggregateTransitionDto, AggregateTransitionReq, SellCowAggregateMotherReq, SellCowAggregateMotherListDto, SellCowAggregateChildrenCarcassReq } from '../../api';
import { ReportSellCowAggregateTable, buildHeader as buildAggregateHeader, getRowValue as getAggregateRowValue } from './report-sellcow-aggregate-table';
import { isBrowser } from 'react-device-detect';
import ReactToPrint from 'react-to-print';
import { ReportSellCowCarcassPopup, ReportCarcassItemKey } from './report-sellcow-carcass-popup';
import { YearSelector } from '../../components/parts/year-selector';
import { ReportSellCowTransitionTable, buildHeader as buildTransitionHeader, getRowValues as getTransitionRowValues } from './report-sellcow-transition-table';
import { ReportSellCowTransitionGraph } from './report-sellcow-transition-graph';
import { historyLocation } from '../../config/history-location-builder';
import { MultiCheckbox } from '../../components/form/form-multi-checkbox';
import { loadTeamStorage, saveTeamStorage } from 'stores/local-storage';

type ReportDataType = SellCowAggregatePartnerListDto | SellCowAggregateTransitionDto[] | SellCowAggregateMotherListDto;

interface LoadedData {
    condition: Readonly<Condition>;
    data: ReportDataType;
}

const loadPartnerReport = async (self: ReportSellCow) => {
    if (!CommonUtil.assertNotNull(self.state.ranch, "ranch", "loadPartnerReport")) return undefined;
    const cond = self.state.condition;
    if (cond.kind !== "PARTNER") {
        console.error("invalid condition for load partner", cond);
        return undefined;
    }

    const req: SellCowAggregatePartnerReq = {
        ranch_id: self.state.ranch.ranch_id,
        from: moment(cond.termFrom).format("YYYY-MM-DD"),
        to: moment(cond.termTo).format("YYYY-MM-DD"),
        sellType: cond.sellType.value,
        is_male: cond.isMale,
        is_disuse: cond.isDisuse,
    };

    const res = await self.comm().send((await SellCowApi()).aggregatePartnerUsingPOST(req));
    if (res.result !== "OK") return undefined;
    return res.data;
}
const loadMotherReport = async (self: ReportSellCow) => {
    if (!CommonUtil.assertNotNull(self.state.ranch, "ranch", "loadMotherReport")) return undefined;
    const cond = self.state.condition;
    if (cond.kind !== "MOTHER") {
        console.error("invalid condition for load mother", cond);
        return undefined;
    }

    const req: SellCowAggregateMotherReq = {
        ranch_id: self.state.ranch.ranch_id,
        from: moment(cond.termFrom).format("YYYY-MM-DD"),
        to: moment(cond.termTo).format("YYYY-MM-DD"),
        sellType: cond.sellType.value,
        is_male: cond.isMale,
        is_disuse: cond.isDisuse,
    };

    const res = await self.comm().send((await SellCowApi()).aggregateMotherUsingPOST(req));
    if (res.result !== "OK") return undefined;
    return res.data;
}
const loadTransitionReport = async (self: ReportSellCow) => {
    if (!CommonUtil.assertNotNull(self.state.ranch, "ranch", "loadTransitionReport")) return undefined;
    const cond = self.state.condition;
    if (cond.kind !== "TRANSITION") {
        console.error("invalid condition for load transition", cond);
        return undefined;
    }

    const req: AggregateTransitionReq = {
        ranch_id: self.state.ranch.ranch_id,
        from: cond.year + "-01-01",
        to: cond.year + "-12-31",
    };

    const res = await self.comm().send((await SellCowApi()).aggregateTransitionUsingPOST1(req));
    if (res.result !== "OK") return undefined;
    return res.data;
}

const REPORT_KINDS = ["PARTNER","TRANSITION","MOTHER"] as const;
type ReportKindKey = typeof REPORT_KINDS[number];
interface IReportKind {
    name: string;
    defaultCondition: () => Condition;
    loadData: (self: ReportSellCow) => Promise<ReportDataType | undefined>;
}
const REPORT_KIND: {[key in ReportKindKey]: IReportKind} = {
    PARTNER: {
        name: "出荷先別",
        loadData: loadPartnerReport,
        defaultCondition: () => {
            const st = moment().startOf("month");
            const ed = moment(st).endOf("month").startOf("day");
            return {
                kind: "PARTNER",
                sellType: COW_SELL_TYPE.CHILD,
                termFrom: st.toDate(),
                termTo: ed.toDate(),
                isMale: undefined,
                isDisuse: undefined,
            }
        }
    },
    MOTHER: {
        name: "母牛別",
        loadData: loadMotherReport,
        defaultCondition: () => {
            const st = moment().startOf("month");
            const ed = moment(st).endOf("month").startOf("day");
            return {
                kind: "MOTHER",
                sellType: COW_SELL_TYPE.CHILD,
                termFrom: st.toDate(),
                termTo: ed.toDate(),
                isMale: undefined,
                isDisuse: undefined,
            }
        }
    },
    TRANSITION: {
        name: "月別",
        loadData: loadTransitionReport,
        defaultCondition: () => {
            return {
                kind: "TRANSITION",
                year: moment().get("year")
            }
        }
    }
}

interface ICondition {
    kind: ReportKindKey;
}
interface IPartnerCondition extends ICondition {
    kind: "PARTNER",
    termFrom: Date;
    termTo: Date;
    sellType: ICowSellType;
    isMale: 0 | 1 | undefined;
    isDisuse: 0 | 1 | undefined;
}
interface ITransitionCondition extends ICondition {
    kind: "TRANSITION",
    year: number;
}
type IMotherCondition = Omit<IPartnerCondition, "kind"> & {
    kind: "MOTHER"
}
type Condition = IPartnerCondition | ITransitionCondition | IMotherCondition;

const buildConditionText = (cond: Condition) => {
    if (cond.kind === "PARTNER" || cond.kind === "MOTHER") {
        const sex = cond.isMale === 0 ? " ♀" : cond.isMale === 1 ? " ♂" : "";
        const disuse = cond.isDisuse === 0 ? " 正常出荷" : cond.isDisuse === 1 ? " 廃用/病畜" : "";
        return [
            `${moment(cond.termFrom).format("YYYY/MM/DD")} ～ ${moment(cond.termTo).format("YYYY/MM/DD")}`,
            `${cond.sellType.name}${sex}${disuse}`
        ];
    }
    if (cond.kind === "TRANSITION") {
        return [];
    }
    console.error("unknown condition", cond);
    return [];
}

interface MyState {
    ranch?: { ranch_id:number, name:string };
    condition: Condition;
    loadedData?: Readonly<LoadedData>;
    carcassDetail?: {
        carcasses: SellCowAggregateCarcassDto[],
        name: string,
        termFrom: Date,
        termTo: Date
    };
    carcassDispItems?: Set<ReportCarcassItemKey>;
}

class ReportSellCow extends Base<BaseProps, MyState> {

    private refPrintTarget = React.createRef<HTMLDivElement>();

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            condition: REPORT_KIND.PARTNER.defaultCondition()
        };
    }

    componentDidMount() {

        this.context.handleSetHeader({ title: "出荷統計" });
        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        if (this.handleNotAllowAccess(undefined, ["REPORT_RANCH"], [])) {
            return;
        }

        const ranchId = this.props.rootStore.cur_ranch_id;
        const ranchName = this.props.rootStore.getCurRanchName() ?? "";
        const savedCarcassItems = loadTeamStorage<ReportCarcassItemKey[]>("report_carcass_disp_item", ranchId);
        this.setState({
            ranch: { ranch_id: ranchId, name:ranchName },
            carcassDispItems: savedCarcassItems == null ? undefined : new Set(savedCarcassItems)
        }, () => {
            this.loadData();
        });
    }

    async loadData() {
        const kind = REPORT_KIND[this.state.condition.kind];

        this.context.handleSetIsLoading(true);

        const data = await kind.loadData(this);

        this.context.handleSetIsLoading(false);

        if (data == null) return;

        this.setState({
            loadedData:{
                data,
                condition: this.state.condition
            }
        });
    }

    onReportKindChanged(key: ReportKindKey) {
        this.setState({
            condition: REPORT_KIND[key].defaultCondition()
        })
    }
    onSellTypeChanged(sellTypeNo: number) {
        const cond = this.state.condition;
        if (cond.kind !== "PARTNER" && cond.kind !== "MOTHER") {
            console.error("invalid condition onSellTypeChanged", cond);
            return;
        }

        const sellType = COW_SELL_TYPES.find(k => COW_SELL_TYPE[k].value === sellTypeNo);
        if (!CommonUtil.assertNotNull(sellType, "sellType " + sellTypeNo)) return;

        this.setState({
            condition: {
                ...cond,
                sellType: COW_SELL_TYPE[sellType]
            }
        })
    }
    onTransitionYearChanged(year: number) {
        if (this.state.condition.kind !== "TRANSITION") {
            console.error("invalid condition onTransitionYearChanged", this.state.condition);
            return;
        }

        this.setState({
            condition: {
                ...this.state.condition,
                year
            }
        })
    }

    onCsvClick() {
        const loaded = this.state.loadedData;
        if (!CommonUtil.assertNotNull(loaded, "loadedData", "csv")) return;

        const rows: string[] = [];
        let fileName: string;

        const cond = loaded.condition;

        if (cond.kind === "PARTNER" && ("partners" in loaded.data)) {

            rows.push(buildCsvRow(buildAggregateHeader("partner")));

            loaded.data
                .partners
                .map(d => buildCsvRow(getAggregateRowValue("partner", d, true, false)))
                .forEach(r => rows.push(r));
            if (loaded.data.partners.length > 0) {
                rows.push(buildCsvRow(getAggregateRowValue("partner", loaded.data.total, true, true)));
            }

            //ファイル名にスラッシュを入れないように
            const sellTypeName = cond.sellType.name.replace("/", "");

            fileName = `出荷先別統計_${sellTypeName}_${moment(cond.termFrom).format("YYYYMMDD")}-${moment(cond.termTo).format("YYYYMMDD")}.csv`;
        }
        else if (cond.kind === "MOTHER" && ("mothers" in loaded.data)) {
            rows.push(buildCsvRow(buildAggregateHeader("mother")));

            loaded.data
                .mothers
                .map(d => buildCsvRow(getAggregateRowValue("mother", d, true, false)))
                .forEach(r => rows.push(r));
            if (loaded.data.mothers.length > 0) {
                rows.push(buildCsvRow(getAggregateRowValue("mother", loaded.data.total, true, true)));
            }

            //ファイル名にスラッシュを入れないように
            const sellTypeName = cond.sellType.name.replace("/", "");

            fileName = `母牛別統計_${sellTypeName}_${moment(cond.termFrom).format("YYYYMMDD")}-${moment(cond.termTo).format("YYYYMMDD")}.csv`;
        }
        else if (cond.kind === "TRANSITION" && Array.isArray(loaded.data)) {
            rows.push(buildCsvRow(buildTransitionHeader(cond.year)));

            const now = moment();
            
            getTransitionRowValues(cond.year, loaded.data, true, now.toDate())
                .map(r => buildCsvRow(r))
                .forEach(r => rows.push(r));
            fileName = `出荷月別統計_${cond.year}年_${now.format("YYYYMMDD")}.csv`;
        }
        else {
            console.error("invalid report kind on csv click: " + cond.kind, loaded.data);
            return;
        }

        saveTextFile(rows.join("\r\n"), fileName);
    }

    onCarcassDispItemsUpdated(items: Set<ReportCarcassItemKey>) {
        if (!CommonUtil.assertNotNull(this.state.ranch, "ranch")) return;

        this.setState({ carcassDispItems:items });
        saveTeamStorage("report_carcass_disp_item", this.state.ranch.ranch_id, [...items]);
    }

    async onCarcassDetail(no: number | undefined, name: string) {
        if (!CommonUtil.assertNotNull(this.state.ranch, "ranch", "carcass detail")) return;
        
        const data = this.state.loadedData;
        if (!CommonUtil.assertNotNull(data, "loadedData", "carcass detail")) return;

        if (data.condition.kind !== "PARTNER" && data.condition.kind !== "MOTHER") {
            console.error("invalid report kind on carcass detail", data.condition);
            return;
        }

        let carcasses: SellCowAggregateCarcassDto[] | undefined;
        this.context.handleSetIsLoading(true);

        if (data.condition.kind === "PARTNER") {
            const req: SellCowAggregateCarcassReq = {
                partner_no: no,
                ranch_id: this.state.ranch.ranch_id,
                from: moment(data.condition.termFrom).format("YYYY-MM-DD"),
                to: moment(data.condition.termTo).format("YYYY-MM-DD"),
                is_male: data.condition.isMale,
                is_disuse: data.condition.isDisuse,
                sell_type: data.condition.sellType.value,
                includesNoCarcass: true,
            };
            const res = await this.comm().send((await SellCowApi()).getPartnerCarcassListUsingPOST(req));
            carcasses = res.data;
    
        } else {
            if (!CommonUtil.assertNotNull(no, "cow_id", "carcass detail of children")) {
                this.context.handleSetIsLoading(false);
                return;
            }
            const req: SellCowAggregateChildrenCarcassReq = {
                cow_id: no,
                ranch_id: this.state.ranch.ranch_id,
                from: moment(data.condition.termFrom).format("YYYY-MM-DD"),
                to: moment(data.condition.termTo).format("YYYY-MM-DD"),
                is_male: data.condition.isMale,
                is_disuse: data.condition.isDisuse,
                sell_type: data.condition.sellType.value,
                includesNoCarcass: true,
            };
            const res = await this.comm().send((await SellCowApi()).getChildrenCarcassListUsingPOST(req));
            carcasses = res.data;
        }

        this.context.handleSetIsLoading(false);
        if (carcasses == null) return;

        this.setState({
            carcassDetail: {
                carcasses,
                name,
                termFrom: data.condition.termFrom,
                termTo: data.condition.termTo
            }
        })

    }

    async setThisMonth() {
        const st = moment().startOf("month");
        const ed = moment(st).endOf("month").startOf("day");
        return this.setTerm(st.toDate(), ed.toDate());
    }
    async setLastMonth() {
        const st = moment().add(-1, "month").startOf("month");
        const ed = moment(st).endOf("month").startOf("day");
        return this.setTerm(st.toDate(), ed.toDate());
    }
    async setThisYear() {
        const st = moment().startOf("year");
        const ed = moment(st).endOf("year").startOf("day");
        return this.setTerm(st.toDate(), ed.toDate());
    }
    async setTerm(st: Date, ed: Date) {
        const cond = this.state.condition;
        if (cond.kind !== "PARTNER" && cond.kind !== "MOTHER") {
            console.error("invalid report kind on setTerm", cond);
            return;
        }

        return this.setStateAsync({
            condition: {
                ...cond,
                termFrom: st,
                termTo: ed
            }
        })
    }

    onIsMaleCheck(values: Set<number>) {
        const cond = this.state.condition;
        if (cond.kind !== "PARTNER" && cond.kind !== "MOTHER") {
            console.error("invalid condition state on isMale checked", cond);
            return;
        }

        let isMale: 0 | 1 | undefined;
        if (values.size === 2) {
            isMale = undefined;
        } else {
            if (values.size !== 1) {
                console.error("invalid isMale update", values);
                return;
            }
            isMale = values.has(1) ? 1 : 0;
        }
        this.setState({ condition: { ...cond, isMale }});
    }
    onIsDisuseCheck(values: Set<number>) {
        const cond = this.state.condition;
        if (cond.kind !== "PARTNER" && cond.kind !== "MOTHER") {
            console.error("invalid condition state on isDisuse checked", cond);
            return;
        }

        let isDisuse: 0 | 1 | undefined;
        if (values.size === 2) {
            isDisuse = undefined;
        } else {
            if (values.size !== 1) {
                console.error("invalid isDisuse update", values);
                return;
            }
            isDisuse = values.has(1) ? 1 : 0;
        }
        this.setState({ condition: { ...cond, isDisuse }});
    }

    render() {
        if (this.state.ranch == null) {
            return <></>
        }
        const thisYear = moment().get("year");

        return (
            <div className="page-root">
                <div className="product" style={{ height: "100%", "minHeight": "0" }}>
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix p-b-5">

                            <div style={{ height: "100%", display:"flex", flexFlow:"column nowrap" }}>
                                <div className={styles.conditions}>
                                    <div className={styles["condition-row"]}>
                                        <select className="form-control" value={this.state.condition.kind}
                                                onChange={e => this.onReportKindChanged(e.target.value as ReportKindKey) }>
                                            { REPORT_KINDS.map(k => (
                                                <option key={k} value={k}>{REPORT_KIND[k].name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    { (this.state.condition.kind === "PARTNER" || this.state.condition.kind === "MOTHER") && (<>
                                        <div className={styles["condition-row"]}>
                                            <RequiredTermSelector
                                                stValue={this.state.condition.termFrom}
                                                edValue={this.state.condition.termTo}
                                                onChange={(s,e) => this.setTerm(s,e)}
                                            />
                                            <div className={styles["condition-presets"]}>
                                                <button className="btn btn-sm btn-light" onClick={() => this.setLastMonth()}>先月</button>
                                                <button className="btn btn-sm btn-light" onClick={() => this.setThisMonth()}>今月</button>
                                                <button className="btn btn-sm btn-light" onClick={() => this.setThisYear()}>今年</button>
                                            </div>
                                        </div>
                                        <div className={styles["condition-row"] + " m-b-0"}>
                                            <div className={styles["condition-block"]}>
                                                <FormRadio isInline={false}
                                                    options={COW_SELL_TYPES.map(k => ({ name:COW_SELL_TYPE[k].name, value:COW_SELL_TYPE[k].value }))}
                                                    onChange={v => this.onSellTypeChanged(v)}
                                                    value={this.state.condition.sellType.value}
                                                />
                                            </div>
                                            <div className={styles["condition-block"]}>
                                                <MultiCheckbox
                                                    prefix="chkIsMale"
                                                    options={[{ name:"♂", value:1 }, { name:"♀", value:0 }]}
                                                    values={new Set(this.state.condition.isMale != null ? [ this.state.condition.isMale ] : [ 1, 0 ])}
                                                    onChange={vals => this.onIsMaleCheck(vals)}
                                                />
                                            </div>
                                            <div className={styles["condition-block"]}>
                                                <MultiCheckbox
                                                    prefix="chkDisuse"
                                                    options={[{ name:"正常出荷", value:0 }, { name:"廃用/病畜", value:1 }]}
                                                    values={new Set(this.state.condition.isDisuse != null ? [ this.state.condition.isDisuse ] : [ 1, 0 ])}
                                                    onChange={vals => this.onIsDisuseCheck(vals)}
                                                />
                                            </div>
                                        </div>
                                    </>)}
                                    { this.state.condition.kind === "TRANSITION" && (
                                        <div className={styles["condition-row"]}>
                                            <YearSelector
                                                style={{ marginTop: "4px" }}
                                                year={this.state.condition.year}
                                                min={thisYear - 10}
                                                max={thisYear + 10}
                                                onChange={y => this.onTransitionYearChanged(y) }
                                            />
                                        </div>
                                    )}
                                </div>

                                <div className={styles["load-button-row"]}>
                                    <button className="btn btn-green" onClick={() => this.loadData()}>
                                        <i className="far fa-arrow-alt-circle-down"></i>
                                        <span> 再集計</span>
                                    </button>
                                </div>

                                { this.state.loadedData != null && (
                                    <div className={styles["loaded-header"]}>
                                        { buildConditionText(this.state.loadedData.condition).map((r,i) => <div key={i}>{r}</div>)}
                                    </div>
                                )}
                                { (this.state.loadedData?.condition?.kind === "PARTNER"
                                    || this.state.loadedData?.condition?.kind === "MOTHER")
                                    && ("total" in this.state.loadedData.data) && (<>
                                    <div ref={this.refPrintTarget} className={styles["table-container"]}>
                                        <ReportSellCowAggregateTable
                                            data={this.state.loadedData.data}
                                            sellType={this.state.loadedData.condition.sellType}
                                            onDetailClick={(no,name)=> this.onCarcassDetail(no,name)}
                                        />
                                    </div>
                                    { isBrowser && (
                                    <div className={styles["table-footer"]}>
                                        <button className="btn btn-orange m-r-5" onClick={() => this.onCsvClick()}>CSV出力</button>
                                        <ReactToPrint
                                            trigger={() => <button className="btn btn-orange">印刷</button>}
                                            content={() => this.refPrintTarget.current}
                                        />
                                    </div>
                                    )}
                                </>)}
                                { this.state.loadedData?.condition?.kind === "TRANSITION"
                                    && Array.isArray(this.state.loadedData.data) && (
                                    <ReportTransition
                                        records={this.state.loadedData.data}
                                        year={this.state.loadedData.condition.year}
                                        onCsvClick={() => this.onCsvClick()}
                                        refTable={this.refPrintTarget}
                                    />
                                )}
                            </div>

                        </div>
                    </div>
                </div>
                { this.state.carcassDetail != null && (
                    <ReportSellCowCarcassPopup
                        onClose={() => this.setState({ carcassDetail: undefined })}
                        carcasses={this.state.carcassDetail.carcasses}
                        name={this.state.carcassDetail.name}
                        term={{ from:this.state.carcassDetail.termFrom, to:this.state.carcassDetail.termTo }}
                        onCowLinkClick={(cowId) => this.props.history.push(historyLocation.toCowInfo(cowId))}
                        dispItemKeys={this.state.carcassDispItems}
                        onKeysUpdated={items => this.onCarcassDispItemsUpdated(items)}
                    />
                )}
            </div>
        )
    }
}

const ReportTransition = (props: {
    year: number,
    records: SellCowAggregateTransitionDto[],
    refTable: React.RefObject<HTMLDivElement>,
    onCsvClick: () => void,

}) => {
    const [ view, setView ] = useState<"table"|"graph">("table");

    return (<>
        <div className={styles["switch-view"]}>
            <div className="radio radio-css mr-3">
                <input type="radio" id="rad-table" checked={view === "table"} onChange={() => setView("table")} />
                <label htmlFor="rad-table">推移表</label>
            </div>
            <div className="radio radio-css">
                <input type="radio" id="rad-graph" checked={view === "graph"} onChange={() => setView("graph")} />
                <label htmlFor="rad-graph">グラフ</label>
            </div>
        </div>
        { view === "table" && (<>
            <div ref={props.refTable} className={styles["table-container"]}>
                <ReportSellCowTransitionTable year={props.year} records={props.records} />
            </div>
            { isBrowser && (
                <div className={styles["table-footer"]}>
                    <button className="btn btn-orange m-r-5" onClick={() => props.onCsvClick()}>CSV出力</button>
                    <ReactToPrint
                        trigger={() => <button className="btn btn-orange">印刷</button>}
                        content={() => props.refTable.current}
                    />
                </div>
            )}
        </>)}
        { view === "graph" && (
            <ReportSellCowTransitionGraph year={props.year} records={props.records} />
        )}
    </>)
}


export default withRouter(withContext(ReportSellCow));