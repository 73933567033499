import React, { useState, useEffect } from 'react';
import { SliderScore } from './slider-score';
import { OptionalInputContainer } from '../../components/parts/optional-input-container';
import { SliderInput } from '../../components/parts/slider-input';
import { Collapse } from 'reactstrap';
import { SymptomModifyReq, SymptomModifyReqCondition, DiseaseCauseDto, TeamDiseaseCauseDto } from '../../api';
import { IFecesState, IFecesColor, IDisease, ITeamCondition } from '../../stores';
import { DiseaseInputPopup } from './disease-input-popup';
import { FecesColorPopup } from './feces-color-popup';
import { Communicator } from '../../api/communicator';
import { LMT } from '../../config/constant';
import { loadCommonStorage, saveCommonStorage, saveUserStorage, loadUserStorage } from '../../stores/local-storage';
import styles from './symptom.module.css';
import classnames from 'classnames';
import { DiseaseInput } from './disease-input';
import { DataListInput } from '../../components/parts/data-list-input';
import { DiseaseInputItem } from './disease-name-input';
import { FreezedArray } from '../../config/util';

export type EditingPhisical = Pick<SymptomModifyReq, "temperature_x10"|"active_score"|"hungry_score"|"heart_rate"|"breath_count"|"feces_state"|"feces_color"|"symptom_name"> & {
    diseases: DiseaseInputItem[];
    conditions: SymptomModifyReqCondition[];
    notice: string;
}

export interface ISymptomName {
    symptom_name: string;
}

export const storeSymptomNameList = (nameList: ISymptomName[], newName: string) => {
    if (newName === "") return;
    
    const list = [
        { symptom_name: newName },
        ...nameList.filter(s => s.symptom_name !== newName)
    ];
    if (list.length > LMT.SYMPTOM.SYMPTOM_NAME_HIS_COUNT) {
        list.pop();
    }

    saveCommonStorage("symptom_name_list", list);
}

export const loadSymptomNameList = () => {
    const list = loadCommonStorage<ISymptomName[]>("symptom_name_list") ?? [
        {symptom_name: "風邪"},
        {symptom_name: "下痢"},
        {symptom_name: "熱"},
    ];
    return list;
}

export const storeNoticeList = (noticeList: Readonly<string[]>, newName: string, user_id: string) => {
    newName = newName.trim();
    if (newName === "") return;
    const list = [
        newName,
        ...noticeList.filter(n => n !== newName)
    ];
    if (list.length > LMT.SYMPTOM.NOTICE_HIS_COUNT) {
        list.pop();
    }

    saveUserStorage("notice_list", user_id, list);
}
export const loadNoticeList = (user_id: string) => {
    return loadUserStorage<string[]>("notice_list", user_id) ?? [];
}

interface MyProps {
    data: EditingPhisical;
    onChange: (data: EditingPhisical) => void;
    fecesStateList: FreezedArray<IFecesState>;
    fecesColorList: FreezedArray<IFecesColor>;
    diseases: FreezedArray<IDisease>;
    causes: FreezedArray<DiseaseCauseDto>;
    conditions: FreezedArray<ITeamCondition>;
    relations: FreezedArray<TeamDiseaseCauseDto>;
    symptomNameList: ISymptomName[];
    noticeList: Readonly<string[]>;
    comm: Communicator;
    onHasInvalidChanged: (hasInvalid: boolean) => void;
    onGrowthInputClick?: () => void;
    diseaseInputMode: "modal" | "flat" | "none";
}

type OptionalNumber = {
    has: boolean;
    val: number;
}

const DEFAULT_TEMPERATURE_X10 = 360;
const DEFAULT_HEARTBEAT_COUNT = 60;
const DEFAULT_BREATH_COUNT = 60;
const BREATH_DEGREE = [10, 24, 48, 99, 180] as const;
const BREATH_DEGREE_AVG = [10, 15, 30, 60, 120] as const;

export const calcBreathScore = (breathCount: number) => {
    for (let i = 0; i < BREATH_DEGREE.length; i++) {
        if (breathCount <= BREATH_DEGREE[i]) {
            return i + 1;
        }
    }
    return 0;
}

export const SymptomPhysicalInput = (props: MyProps) => {
    const [ temperature_x10, setTemperature_x10 ] = useState<OptionalNumber>({ has: false, val: DEFAULT_TEMPERATURE_X10 });
    const [ active_score, setActive_score ] = useState<OptionalNumber>({ has: false, val: 0 });
    const [ hungry_score, setHungry_score ] = useState<OptionalNumber>({ has: false, val: 0 });
    const [ heart_rate, setHeart_rate ] = useState<OptionalNumber>({ has: false, val: DEFAULT_HEARTBEAT_COUNT });
    const [ breath_count, setBreath_count ] = useState<OptionalNumber>({ has: false, val: DEFAULT_BREATH_COUNT });
    const [ expanded, setExpanded ] = useState(false);
    const [ mainFecesColors, setMainFecesColors ] = useState<IFecesColor[]>([]);
    const [ isFecesColorPopupShown, setIsFecesColorPopupShown ] = useState(false);
    const [ isDiseasePopupShown, setIsDiseasePopupShown ] = useState(false);
    const [ invalids, setInvalids ] = useState<Set<string>>(new Set());

    useEffect(() => {
        setTemperature_x10({ has: props.data.temperature_x10 != null, val: props.data.temperature_x10 ?? temperature_x10.val });
        setActive_score({ has: props.data.active_score != null, val: props.data.active_score ?? active_score.val });
        setHungry_score({ has: props.data.hungry_score != null, val: props.data.hungry_score ?? hungry_score.val });
        setHeart_rate({ has: props.data.heart_rate != null, val: props.data.heart_rate ?? heart_rate.val });
        setBreath_count({ has: props.data.breath_count != null, val: props.data.breath_count ?? breath_count.val });

    }, [ props.data ]);

    useEffect(() => {
        const mains = props.fecesColorList.filter(f => f.main_no);
        const sorted = mains.sort((a, b) => a.main_no - b.main_no);
        setMainFecesColors(sorted);

    }, [ props.fecesColorList ]);

    const onIsValidChange = (name: string, isValid: boolean) => {
        const newInvalids = new Set([...invalids]);
        if (isValid) {
            newInvalids.delete(name);
        }
        else {
            newInvalids.add(name);
        }
        setInvalids(newInvalids);

        if (newInvalids.size === 0 || (newInvalids.size === 1 && !isValid)) {
            props.onHasInvalidChanged(newInvalids.size > 0);
        }
    }

    const collapseRowClass = styles["row-collapse"];
    const collapseHeaderClass = "col-form-label col-md-4 col-xs-6 clickable p-l-10";
    const collapseArrowClass = (isOpen : boolean) => "fas fa-lg fa-fw " + (isOpen ? "fa-angle-up" : "fa-angle-down");

    const groupRowClass = classnames(styles.group, styles.row);
    const rowClass = styles.row;
    const headerCheckboxClass = "checkbox checkbox-css col-form-label col-md-4 col-xs-4 text-lg-right m-t-10";
    const labelClass = "col-form-label col-md-4 col-xs-4 text-lg-right p-l-10 p-r-10";

    const isFecesColorOtherChecked = props.data.feces_color != null
            && (props.fecesColorList.find(c => c.feces_color === props.data.feces_color)?.main_no ?? -1) === 0;

    return (<>
        <div className={groupRowClass}>
            <label className={labelClass}>診療理由</label>
            <div className="col-md-6 col-xs-7 p-l-0">
                <DataListInput testId="理由"
                    datalistId="symptom_name_list"
                    maxLength={LMT.SYMPTOM.SYMPTOM_NAME_LEN}
                    value={props.data.symptom_name}
                    onChange={v => props.onChange({ ...props.data, symptom_name: v }) }
                    dataList={props.symptomNameList.map(s => s.symptom_name)}
                />
            </div>
        </div>
        <div className={rowClass} data-testid="体温">
            <div className={headerCheckboxClass}>
                <input type="checkbox" id="chkTemperature"
                        onChange={e => props.onChange({ ...props.data, temperature_x10: e.target.checked ? temperature_x10.val : undefined })}
                        checked={temperature_x10.has} />
                <label htmlFor="chkTemperature">体温</label>
            </div>
            <OptionalInputContainer className="col-md-6 col-xs-8" isSelected={temperature_x10.has}>
                <SliderInput min={25} max={45} value={temperature_x10.val / 10.0} step={0.1}
                            sliderMin={35} sliderMax={43}
                            onChange={ val => {
                                props.onChange({ ...props.data, temperature_x10: val * 10 })
                            }}
                            onIsValidChange={v => onIsValidChange("temperature", v)} />
            </OptionalInputContainer>
        </div>
        <div className={collapseRowClass}>
            <div className={collapseHeaderClass} data-testid="体調詳細開閉"
                onClick={() => setExpanded(!expanded)}>
                <i className={collapseArrowClass(expanded)}></i>
                <span> 体調詳細記録</span>
            </div>
        </div>
        <Collapse isOpen={expanded}>
            <div className={rowClass} data-testid="活力">
                <div className={headerCheckboxClass}>
                    <input type="checkbox" id="chkActiveScore"
                        onChange={e =>　props.onChange({ ...props.data, active_score: e.target.checked ? active_score.val : undefined })}
                        checked={active_score.has} />
                    <label htmlFor="chkActiveScore">活力</label>
                </div>
                <OptionalInputContainer className="col-md-6 col-xs-8" isSelected={active_score.has}>
                    <SliderScore
                        min={0} max={3}
                        value={active_score.val}
                        onChange={val => props.onChange({ ...props.data, active_score: val }) }
                        marks={{ 0: "無", 1: "低", 2: "やや低", 3: "良" }}
                    />
                </OptionalInputContainer>
            </div>
            <div className={rowClass} data-testid="食欲">
                <div className={headerCheckboxClass}>
                    <input type="checkbox" id="chkHungryScore"
                        onChange={e => props.onChange({ ...props.data, hungry_score: e.target.checked ? hungry_score.val : undefined })}
                        checked={hungry_score.has} />
                    <label htmlFor="chkHungryScore">食欲</label>
                </div>
                <OptionalInputContainer className="col-md-6 col-xs-8" isSelected={hungry_score.has}>
                    <SliderScore
                        min={0} max={3}
                        value={hungry_score.val}
                        onChange={val => props.onChange({ ...props.data, hungry_score: val })}
                        marks={{ 0: "無", 1: "低", 2: "やや低", 3: "良" }}
                    />
                </OptionalInputContainer>
            </div>
            <div className={rowClass} data-testid="心拍">
                <div className={headerCheckboxClass}>
                    <div>
                        <input type="checkbox" id="chkHeartbeat"
                            onChange={e => props.onChange({ ...props.data, heart_rate: e.target.checked ? heart_rate.val : undefined })}
                            checked={heart_rate.has} />
                        <label htmlFor="chkHeartbeat">心拍</label>
                    </div>
                    <span style={{marginTop:"2px", fontSize:"0.75rem"}}>1分あたり</span>
                </div>
                <OptionalInputContainer className="col-md-6 col-xs-8" isSelected={heart_rate.has}>
                    <SliderInput min={10} max={300} value={heart_rate.val} step={1}
                                onChange={ val => props.onChange({ ...props.data, heart_rate: val }) }
                                onIsValidChange={v => onIsValidChange("heartbeat", v)} />
                </OptionalInputContainer>
            </div>
            <div className={rowClass} data-testid="呼吸">
                <div className={headerCheckboxClass}>
                    <div>
                        <input type="checkbox" id="chkBreath"
                            onChange={e => props.onChange({ ...props.data, breath_count: e.target.checked ? breath_count.val : undefined })}
                            checked={breath_count.has} />
                        <label htmlFor="chkBreath">呼吸</label>
                    </div>
                    <span style={{marginTop:"2px", fontSize:"0.75rem"}}>1分あたり</span>
                </div>
                <OptionalInputContainer className="col-md-6 col-xs-8" isSelected={breath_count.has}>
                    <SliderInput min={5} max={180} value={breath_count.val} step={1}
                                onChange={val => props.onChange({ ...props.data, breath_count: val })}
                                onIsValidChange={v => onIsValidChange("breath_count", v)} />
                    <SliderScore
                        min={1} max={5}
                        value={calcBreathScore(breath_count.val)}
                        onChange={(v) => props.onChange({
                            ...props.data,
                            breath_count: BREATH_DEGREE_AVG[v - 1]
                        })}
                        marks={{ 1: "遅", 2: "普通", 3: "微速", 4: "速", 5: "超速" }}
                    />
                </OptionalInputContainer>
            </div>
            <div className={groupRowClass}>
                <label className={labelClass}>便の状態</label>
                <div className="col-md-6 col-xs-7 p-l-0">
                    <select className="form-control" value={props.data.feces_state ?? 0}
                        data-testid="便状態選択"
                        onChange={e => props.onChange({ ...props.data, feces_state: Number(e.target.value) === 0 ? undefined : Number(e.target.value)}) }>
                        <option value={0}>選択</option>
                        {
                            props.fecesStateList.map((value, i) => (
                                <option key={i} value={value.state_no}>{value.name}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
            <div className={groupRowClass} data-testid="便色">
                <label className={labelClass}>便の色</label>
                <div className="col-md-6 col-xs-7 p-l-0">
                    <div className="radio radio-css m-l-10">
                        <input type="radio" name="radio_css_inline" id="fecesColor1" value="option1"
                            onChange={() => props.onChange({ ...props.data, feces_color: undefined })}
                            checked={props.data.feces_color == null} />
                        <label htmlFor="fecesColor1">未確認</label>
                    </div>
                    {
                        mainFecesColors.map((c, c_id) => (
                            <div key={c_id} className="radio radio-css m-l-10">
                                <input type="radio" name="radio_css_inline" id={"mainColor" + c.disp_no}
                                    onChange={() => props.onChange({ ...props.data, feces_color: c.feces_color } )}
                                    checked={props.data.feces_color === c.feces_color} />
                                <label htmlFor={"mainColor" + c.disp_no}><span style={{
                                    height: "16px",
                                    background: "#" + c.feces_color
                                }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;{c.name}</label>
                            </div>
                        ))
                    }
                    <div className="radio radio-css m-l-10">
                        <input type="radio" name="radio_css_inline" id="fecesColor2" value="option2"
                            checked={isFecesColorOtherChecked} readOnly />
                        <label htmlFor="fecesColor2" onClick={() => setIsFecesColorPopupShown(true)}>
                            その他&nbsp;&nbsp;&nbsp;
                        {
                                isFecesColorOtherChecked && (
                                    <span>
                                        <span style={{
                                            height: "16px",
                                            background: "#" + props.data.feces_color
                                        }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;
                                    {props.fecesColorList.find(c => c.feces_color === props.data.feces_color)?.name ?? ""}
                                    </span>
                                )
                            }
                        </label>
                    </div>
                </div>
            </div>

            { props.diseaseInputMode === "modal" && (
                <div className={groupRowClass}>
                    <button style={{ margin: "auto" }}
                        className="btn btn-theme btn-sm btn-success col-md-6 col-xs-9"
                        onClick={() => setIsDiseasePopupShown(true)}>詳細項目を入力
                    </button>
                </div>
            )}
            { props.diseaseInputMode === "flat" && (
                <DiseaseInput
                    causes={props.causes}
                    diseases={props.diseases}
                    relations={props.relations}
                    conditions={props.conditions}
                    noticeList={props.noticeList}
                    data={{
                        conditions: props.data.conditions,
                        notice: props.data.notice,
                        diseases: props.data.diseases
                    }}
                    onChange={input=> props.onChange({
                        ...props.data,
                        ...input
                    })}
                />
            )}

            { props.onGrowthInputClick &&
                <div className={groupRowClass}>
                    <button style={{ margin: "auto" }}
                        className="btn btn-theme btn-sm btn-success col-md-6 col-xs-9"
                        onClick={props.onGrowthInputClick}>身体測定値を入力
                    </button>
                </div>
            }

        </Collapse>
        {
            isDiseasePopupShown && (
                <DiseaseInputPopup isOpen={true}
                    causes={props.causes}
                    diseases={props.diseases}
                    relations={props.relations}
                    conditions={props.conditions}
                    noticeList={props.noticeList}
                    data={{
                        conditions: props.data.conditions,
                        notice: props.data.notice,
                        diseases: props.data.diseases
                    }}
                    onClose={() => setIsDiseasePopupShown(false)}
                    onSubmit={input => {
                        props.onChange({
                            ...props.data,
                            ...input
                        });
                        setIsDiseasePopupShown(false);
                    }}
                />
            )
        }
        {
            isFecesColorPopupShown && (
                <FecesColorPopup isOpen={true}
                    colors={props.fecesColorList}
                    onClose={() => setIsFecesColorPopupShown(false)}
                    onResult={b => { props.onChange({ ...props.data, feces_color: b}); setIsFecesColorPopupShown(false); } } />
            )
        }
    </>);
}