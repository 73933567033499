import React from 'react';
import { ISchedule, scheduleCustomColorToReq } from './schedule-popup';
import { RanchScheduleModifyReq } from '../../api';
import classnames from 'classnames';
import moment from 'moment';

interface MyProps {
    schedule: ISchedule;
    baseDate: Date;
    onRequest: (req: Omit<RanchScheduleModifyReq, "ranch_id"|"user_id">) => void;
    className?: string;
    style?: React.CSSProperties;
    showQuestionAsync: (text: string, buttons: string[]) => Promise<number|undefined>;
}

export const ScheduleForwarder = (props: MyProps) => {

    const onClick = async () => {
        const nextDay = moment(props.baseDate).add(1, "days").startOf("day");
        const diff = nextDay.diff(moment(props.schedule.start).startOf("day"), "days");
        if (diff < 1) {
          console.error("invalid schedule to forward date", props.schedule.start, nextDay);
          return;
        }
        const newStart = moment(props.schedule.start).add(diff, "days");
        const newEnd = moment(props.schedule.end).add(diff, "days");

        const confirmed = await props.showQuestionAsync(`予定の開始日を${newStart.format("M月D日")}に変更します`, ["実行", "キャンセル"]);
        if (confirmed !== 0) return;
    
        const req = {
            is_new: 0,
            schedule_id: props.schedule.id,
            title: props.schedule.rawTitle,
            start_at: newStart.format("YYYY-MM-DD HH:mm:00"),
            end_at: newEnd.format("YYYY-MM-DD HH:mm:00"),
            has_time: props.schedule.allDay ? 0 : 1,
            event_kind_no: props.schedule.eventKindNo,
            note: props.schedule.note,
            cow_id: props.schedule.cows.map(c => c.cow_id),
            status: props.schedule.status ? 1 : 0,
            color: scheduleCustomColorToReq(props.schedule.customColor),
            preset_id: props.schedule.preset?.preset_id
        };

        props.onRequest(req);
    }


    return (
        <div className={classnames("clickable", props.className)} onClick={onClick} style={props.style}>
            <i className="fas fa-forward d-print-none" style={{ color: "rgb(163, 163, 163)" }} />
        </div>
    )
}