import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { CarcassInput } from './carcass-input';
import { EditingCarcass } from './sellcow-single';
import { LMT } from '../../config/constant';

export interface SellCowDetailPopupProps {
    onClose: () => void;
    onSubmit:(data: EditingCarcass|undefined, comment: string)=>void;
    carcass: EditingCarcass | undefined;
    title: string;
    comment: string;
}

export const SellCowDetailPopup: React.FC<SellCowDetailPopupProps> = (props) => {
    const [ currentCarcass, setCurrentCarcass ] = useState<EditingCarcass|undefined>(undefined);
    const [ currentComment, setCurrentComment ] = useState("");

    useEffect(() => {
        setCurrentCarcass(props.carcass);
    }, [ props.carcass ]);
    useEffect(() => {
        setCurrentComment(props.comment);
    }, [ props.comment ]);

    return (
        <div className="modal-wide">
            <Modal isOpen={true} centered={true}>
                <ModalHeader toggle={props.onClose}>{props.title}</ModalHeader>
                <ModalBody style={{ overflowY: "auto", maxHeight: "calc(100vh - 180px)" }}>
                    <div>
                        { currentCarcass != null && (
                            <CarcassInput 
                                data={currentCarcass}
                                onChange={d => setCurrentCarcass(d)}
                            />
                        )}
                        <div style={{display:"flex"}}>
                            <label className="col-md-2 col-xs-2" style={{padding:"0 10px"}}>メモ</label>
                            <div className="col-md-10 col-xs-10">
                                <textarea className="form-control" rows={4} maxLength={LMT.SELLCOW.MEMO_LEN}
                                        value={currentComment} onChange={(e) => setCurrentComment(e.target.value)}></textarea>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter style={{display:"flex", justifyContent:"center"}}>
                    <button className="btn btn-green" onClick={() => props.onSubmit(currentCarcass, currentComment)}>確定</button>
                </ModalFooter>
            </Modal>
        </div>

    );
}