import React from 'react';
import classnames from 'classnames';

interface MyProps {
    value: string;
    onChange: (value: string) => void;
    datalistId: string;
    testId?: string;
    maxLength?: number;
    style?: React.CSSProperties;
    className?: string;
    dataList: Readonly<string[]>;
    disabled?: boolean;
}

export const DataListInput = (props: MyProps) => {
    return (<>
        <input type="text" list={props.datalistId}
            className={classnames("form-control", props.className)} 
            style={props.style}
            data-testid={props.testId}
            maxLength={props.maxLength}
            value={props.value}
            onChange={e => props.onChange(e.target.value) }
            disabled={props.disabled}
        />
        <datalist id={props.datalistId}>
            { props.dataList.map((s, i) => (
                <option key={i}>{s}</option>
            ))}
        </datalist>
    </>)
}