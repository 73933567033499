import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import moment from 'moment';
import { CommonUtil, FreezedArray, ar } from '../../config/util';
import { A, LMT, isUnusedStock as isUnusedStockUtil } from '../../config/constant';
import styles from './breeding.module.css';
import { usePageStore } from '../../config/page-settings';
import { IEditingCross } from './breeding-write';
import { ISeedType, ISeed, ISeedLot, ISeedStock } from '../../stores';
import { GetPedigreeStr } from '../setting/seed-management';
import { SelectMotherPopup, IMother } from '../cow/select-mother-popup';
import { CowToDispInfo } from '../../components/parts/cows-popup';
import { Communicator } from '../../api/communicator';
import { V3DateTime } from '../../components/parts/v3-date-time-picker';
import { ExtraCowDto, RutLatestDto } from '../../api';
import { AncestorSelect } from '../setting/ancestor-select';
import { SettingAncestorPopup } from '../setting/setting-ancestor-popup';
import { getEggTypeName, getEggRankName, getEggStageName } from '../../config/egg-kind';
import { buildLatestRutForCross } from '../cow/cow-event-renderer';
import { IconLink } from '../../components/parts/icon-link';
import { SeedStockListPopup, SeedStockItem } from '../setting/seed-stock-list-popup';
import { pickLastNum } from '../setting/seed-label-range-input';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { useAncestors } from '../../stores/fetcher';
import { FetchWaiter, FetchError } from '../../components/content/fetch-state';
import { useLatestRut } from '../../stores/fetcher_rut';

export type EditingCrossOriginal = Partial<IEditingCross> & { cow_id: number };

export interface ICrossPopupProps {
    ranchId: number;
    clinicId: number | undefined;
    isOfficialRanch: boolean;
    onClose:()=>void;
    onSubmit:(data:IEditingCross)=>void;
    original: EditingCrossOriginal;
    seedsRanch: ISeedType[];
    seedsClinic: ISeedType[];
    breeding_id?: number;
    watched_at: Date;
    comm: Communicator;
}

const INPUT_MANUAL = 1 as const;
const INPUT_STOCK_RANCH = 2 as const;
const INPUT_STOCK_CLINIC = 3 as const;
type InputType = typeof INPUT_MANUAL | typeof INPUT_STOCK_RANCH | typeof INPUT_STOCK_CLINIC;

type Ancestor = { exid: number, name: string };
const toAncestor = (id: number | undefined, name: string | undefined) => {
    if (id == null) return undefined;
    return ({ exid: id, name: name ?? ""});
}

export const CrossPopup: React.FC<ICrossPopupProps> = (props) => {
    const { showToast, handleSetIsLoading, showQuestionAsync } = usePageStore();

    //※診療所でも牧場の種雄牛リストが対象
    const mAncestors = useAncestors(props.ranchId);
    const latestRut = useLatestRut(props.ranchId, props.original.cow_id, props.watched_at);

    const [ crossType, setCrossType ] = useState(0);
    const [ seedId, setSeedId ] = useState(0);
    const [ lotId, setLotId ] = useState(0);
    const [ labels, setLabels ] = useState(["","",""]);
    const [ rutAt, setRutAt ] = useState<Date>();
    const [ motherId, setMotherId ] = useState<number>();
    const [ motherName, setMotherName ] = useState("");
    const [ father, setFather ] = useState<Ancestor>();
    const [ ancestor2, setAncestor2 ] = useState<Ancestor>();
    const [ ancestor3, setAncestor3 ] = useState<Ancestor>();
    const [ currentSeedIds, setCurrentSeedIds ] = useState<ISeed[]>([]);
    const [ currentSeedLots, setCurrentSeedLots ] = useState<ISeedLot[]>([]);
    const [ currentSeedStocks, setCurrentSeedStocks ] = useState<ISeedStock[]>([]);
    const [ currentLotInfo, setCurrentLotInfo ] = useState("");
    const [ inputType, setInputType ] = useState<InputType>(INPUT_MANUAL);
    const [ isMotherSelectShown, setIsMotherSelectShown ] = useState(false);
    const [ isAncestorPopupShown, setIsAncestorPopupShown ] = useState(false);
    const [ isSeedStockListShown, setIsSeedStockListShown ] = useState(false);
    const [ currentAllStocks, setCurrentAllStocks ] = useState<SeedStockItem[]>([]);

    useEffect(() => {
        const ori = props.original;
        setCrossType(ori.cross_type ?? 0);
        setRutAt(
            ori.rut_at != null ? moment(ori.rut_at).toDate()
            : latestRut.data != null ? moment(latestRut.data.watched_at).toDate()
            : moment(props.watched_at).add(-7, "days").startOf("day").toDate() 
        );
        setMotherId(ori.mother_id);
        setMotherName(ori.mother_name ?? "");
        setFather(toAncestor(ori.father_exid, ori.father_name));
        setAncestor2(toAncestor(ori.ancestor_2_exid, ori.ancestor_2));
        setAncestor3(toAncestor(ori.ancestor_3_exid, ori.ancestor_3));
        const hasCli = props.clinicId != null;
        const inTp = ori.seed_lot_id == null? INPUT_MANUAL
                  : (hasCli && findSeed(props.seedsClinic, ori.cross_type ?? 0, ori.seed_lot_id) != null) ? INPUT_STOCK_CLINIC
                  : INPUT_STOCK_RANCH;
        setInputType(inTp);
    }, [ props.original, props.watched_at, latestRut.data ]);

    const findSeed = (seedTypes: ISeedType[], cross_type: number, lot_id: number) => {
        const seeds = seedTypes.find(t => t.seed_type === cross_type)?.seeds ?? [];
        return seeds.find(s => s.lots.some(l => l.seed_lot_id === lot_id));
    }

    useEffect(() => {
        const seeds = inputType === INPUT_STOCK_RANCH ? props.seedsRanch : inputType === INPUT_STOCK_CLINIC ? props.seedsClinic : [];
        const newSeedIds = crossType === 0 ? [] : seeds.find(s => s.seed_type === crossType)?.seeds ?? [];
        setCurrentSeedIds(newSeedIds.filter(s => (s.lots ?? []).some(l => anyStock(l))));

        if ((props.original.cross_type ?? 0) !== 0 && props.original.cross_type === crossType
            && (props.original.seed_lot_id != null && props.original.seed_lot_id !== 0)) {
            
            const newSeed = newSeedIds.find(s => s.lots?.some(l => l.seed_lot_id === props.original.seed_lot_id));
            if (newSeed != null) {
                setSeedId(newSeed.seed_id);
                return;
            }
        }

        setSeedId(newSeedIds.length === 0 ? 0 : newSeedIds[0].seed_id);

    }, [props.seedsRanch, props.seedsClinic, inputType, crossType ]);

    useEffect(() => {
        const lots = seedId === 0 ? [] : currentSeedIds.find(s => s.seed_id === seedId)?.lots ?? [];
        setCurrentSeedLots(lots.filter(l => anyStock(l)));

    }, [currentSeedIds, seedId]);

    useEffect(() => {
        if (props.original.cross_type === crossType
            && props.original.seed_lot_id != null && props.original.seed_lot_id !== 0
            && currentSeedLots.some(l => l.seed_lot_id === props.original.seed_lot_id)) {
            
            setLotId(props.original.seed_lot_id);
            return;
        }

        setLotId(preLotId => currentSeedLots.length === 0 ? 0 : currentSeedLots.some(l => l.seed_lot_id === preLotId) ? preLotId : currentSeedLots[0].seed_lot_id);

    }, [currentSeedLots]);

    useEffect(() => {
        let stocks: ISeedStock[];
        if (lotId === 0) {
            stocks = [];
        } else {
            const lot = currentSeedLots.find(s => s.seed_lot_id === lotId);
            if (lot == null) return;    //変更中
            stocks = lot.stocks;
        }
        setCurrentSeedStocks(
            ar.orderBy(stocks, s => pickLastNum(s.label_no) ?? 0)
                .filter(st => isUnusedStock(st))
        );

    }, [lotId, currentSeedLots]);

    useEffect(() => {
        if (lotId === 0 || crossType !== ET_CROSS_TYPE) {
            setCurrentLotInfo("");
            return;
        }

        const lot = currentSeedLots.find(s => s.seed_lot_id === lotId);
        if (lot == null) return;    //変更中

        const type = getEggTypeName(lot);
        const rank = getEggRankName(lot);
        const stage = getEggStageName(lot);
        setCurrentLotInfo([type,rank,stage].filter(s => s != null).join(", "));

    }, [lotId, currentSeedLots]);

    useEffect(() => {
        if (lotId === 0) {
            setLabels(["","",""]);
            return;
        }

        const lot = currentSeedLots.find(l => l.seed_lot_id === lotId);
        if (lot == null) return;    //変更中とみなす

        setLabels(preLabels => {
            //在庫選択から更新済の場合
            if (preLabels.some(l => l !== "")
                && preLabels.every(l => l === "" || lot.stocks.some(s => s.label_no === l))) {

                return preLabels;
            }

            if (props.original.seed_lot_id === lotId) {
                return [ props.original.label_no_1 ?? "", props.original.label_no_2 ?? "", props.original.label_no_3 ?? "" ];
            }
    
            return ["","",""];
        })

    }, [lotId, currentSeedLots]);

    useEffect(() => {
        if (props.original.cross_type === crossType) {
            setFather(toAncestor(props.original.father_exid, props.original.father_name));
            return;
        }
        setFather(undefined);
    }, [crossType]);

    useEffect(() => {
        const lots = ar.flat(currentSeedIds.map(s => s.lots.map(l => ({ name: s.name, seed_id: s.seed_id, ancestor_1:s.ancestor_1, ...l }))));
        const stocks = ar.flat(lots.map(l => ar.orderBy(l.stocks, s => pickLastNum(s.label_no) ?? 0).map(s => ({ ...l, ...s }))))
                .filter(s => isUnusedStock(s));

        setCurrentAllStocks(stocks);

    }, [ currentSeedIds ]);


    const anyStock = (lot: ISeedLot) => {
        return (lot.stocks ?? []).some(st => isUnusedStock(st));
    }
    const isUnusedStock = (stock: ISeedStock) => {
        return isUnusedStockUtil(stock, props.breeding_id);
    }

    const AI_CROSS_TYPE = A.CROSS_TYPE.get("AI")?.no;
    const ET_CROSS_TYPE = A.CROSS_TYPE.get("ET")?.no;
    const HON_CROSS_TYPE = A.CROSS_TYPE.get("HON")?.no;

    const onSubmit = () => {
        const usedLabels = labels.filter(l => l !== "");

        if (new Set(usedLabels).size !== usedLabels.length) {
            showToast(A.MESSAGE.BREEDING_LABEL_DUPLICATE);
            return;
        }

        const ft = crossType === HON_CROSS_TYPE || ((crossType === AI_CROSS_TYPE || crossType === ET_CROSS_TYPE) && inputType === INPUT_MANUAL) ? father : undefined;
        const anc2 = (crossType === ET_CROSS_TYPE && inputType === INPUT_MANUAL) ? ancestor2 : undefined;
        const anc3 = (crossType === ET_CROSS_TYPE && inputType === INPUT_MANUAL) ? ancestor3 : undefined;

        props.onSubmit({ 
            cow_id          : props.original.cow_id,
            cross_no        : props.original.cross_no,
            cross_type      : crossType,
            rut_at          : (crossType === AI_CROSS_TYPE || rutAt == null) ? undefined: moment(rutAt).format("YYYY-MM-DD HH:mm") + ":00",
            father_exid     : ft?.exid,
            father_name     : ft?.name,
            mother_id       : (crossType === ET_CROSS_TYPE && inputType === INPUT_MANUAL) ? motherId : undefined,
            mother_name     : (crossType === ET_CROSS_TYPE && inputType === INPUT_MANUAL) ? motherName : "",
            ancestor_2_exid : anc2?.exid,
            ancestor_2      : anc2?.name,
            ancestor_3_exid : anc3?.exid,
            ancestor_3      : anc3?.name,
            seed_lot_id     : ((crossType === AI_CROSS_TYPE || crossType === ET_CROSS_TYPE) && inputType !== INPUT_MANUAL) ? lotId : undefined,
            label_no_1      : ((crossType === AI_CROSS_TYPE || crossType === ET_CROSS_TYPE) && inputType !== INPUT_MANUAL) ? usedLabels.length < 1 ? undefined : usedLabels[0] : undefined,
            label_no_2      : ((crossType === AI_CROSS_TYPE || crossType === ET_CROSS_TYPE) && inputType !== INPUT_MANUAL) ? usedLabels.length < 2 ? undefined : usedLabels[1] : undefined,
            label_no_3      : ((crossType === AI_CROSS_TYPE || crossType === ET_CROSS_TYPE) && inputType !== INPUT_MANUAL) ? usedLabels.length < 3 ? undefined : usedLabels[2] : undefined,
        });
    }

    const canSubmit = () => {
        if (crossType === 0) return false;
        if (crossType === HON_CROSS_TYPE) return true;

        if (inputType === INPUT_MANUAL) {
            if (crossType === AI_CROSS_TYPE) {
                if (father == null) return false;
            }
            if (crossType === ET_CROSS_TYPE) {
                if (father == null) return false;
                if (motherName === "" && (ancestor2 == null || ancestor3 == null)) return false;
            }
        }

        if (inputType !== INPUT_MANUAL) {
            if (seedId === 0) return false;
            if (lotId === 0) return false;
            if (labels.every(l => l === "")) return false;
        }

        return true;
    }

    const seedToDispName = (crossType:number, seed: ISeed) => {
        if (crossType === AI_CROSS_TYPE) {
            return `${seed.name}（${seed.sname}）`;
        }
        if (crossType === ET_CROSS_TYPE) {
            return `${GetPedigreeStr(seed)}、${seed.get_from ?? ""}`;
        }
        return "";
    }
    const lotToDispName = (lot: ISeedLot) => {
        return `${lot.lot_name}（${moment(lot.stock_day).format("YYYY/M/D")}, ${lot.unit_price}円）`
    }

    const onLabelSelected = (idx:number, value:string) => {
        const newLabels = [...labels];
        newLabels[idx] = value;
        setLabels(newLabels);
    }

    const onClearMother = () => {
        setMotherId(undefined);
        setMotherName("");
    }

    const onMotherSelected = (mother?:IMother) => {
        setIsMotherSelectShown(false);
        setMotherId(mother?.cow_id??undefined);
        setMotherName(mother == null ? "" : mother.name || CowToDispInfo(mother, false));
        if (mother?.cow_id != null) {
            setAncestor2(toAncestor(mother.ancestor_1_exid, mother.ancestor_1));
            setAncestor3(toAncestor(mother.ancestor_2_exid, mother.ancestor_2));
        }
    }

    const onSeedSelectedOnList = (newSeedId: number, newLotId: number, newLabelNo: string) => {
        setIsSeedStockListShown(false);

        if (lotId === newLotId) {
            if (labels.includes(newLabelNo)) return;

            let idx = labels.findIndex(l => l === "");
            if (idx < 0) {
                idx = labels.length - 1;
            }
            setLabels(labels.map((l,i) => i === idx ? newLabelNo : l));
            return;
        }

        setSeedId(newSeedId);
        setLotId(newLotId);
        setLabels([newLabelNo, "", ""]);
    }

    const ID = "cross-popup";

    return (
        <div>
            <Modal isOpen={true} id={ID} style={{ marginTop:"10px" }}>
                <ModalHeader toggle={props.onClose}>交配記録</ModalHeader>
                { (mAncestors.isLoading || latestRut.isLoading) ? (
                    <FetchWaiter />
                ) : (mAncestors.isError || mAncestors.data == null || latestRut.isError) ? (
                    <FetchError />
                ) : (<>
                    <ModalBody style={{ overflowY: "auto", maxHeight: "calc(100vh - 180px)", minHeight:"calc(100vh - 180px)"}}>
                        <div>
                            <div className={styles["popup-row"]}>
                                <span className={styles["popup-item-header"]}>
                                    <label className="col-form-label">交配手段</label>
                                </span>
                                <div>
                                    <select className={"form-control " + styles["popup-select"]} value={crossType} onChange={e => setCrossType(Number(e.target.value))}>
                                        <option value={0}>選択</option>
                                        {
                                            [...A.CROSS_TYPE.values()].map(t => (
                                                <option key={t.no} value={t.no}>{t.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            { (crossType === ET_CROSS_TYPE || crossType === HON_CROSS_TYPE) && (
                                <div className={styles["popup-row"]}>
                                    <label className={"col-form-label " + styles["popup-item-header"]}>発情日</label>
                                    <div>
                                        <span style={{display:"flex"}}>
                                            <V3DateTime value={rutAt} onChange={e => setRutAt(e.toDate())} portalContainerID={ID} />
                                        </span>
                                        { latestRut.data != null && (
                                            <div className={styles.hint}>発情記録 {buildLatestRutForCross(latestRut.data)}</div>
                                        )}
                                    </div>
                                </div>
                            )}
                            { (crossType === AI_CROSS_TYPE || crossType === ET_CROSS_TYPE) && (
                                <div className={styles["popup-row"]}>
                                    <span className={styles["popup-item-header"]}>
                                        <label className="col-form-label">{crossType === AI_CROSS_TYPE ? "使用精液" : "移植卵"}</label>
                                    </span>
                                    <div style={{flex:1, display:"flex", flexWrap:"wrap"}}>
                                        <div className="radio radio-css m-r-15">
                                            <input type="radio" name="radioInputType" id="radioInputType1" 
                                                checked={inputType === INPUT_MANUAL} onChange={() => setInputType(INPUT_MANUAL)}></input>
                                            <label htmlFor="radioInputType1">手入力</label>
                                        </div>
                                        { props.isOfficialRanch && (
                                            <div className="radio radio-css m-r-15">
                                                <input type="radio" name="radioInputType" id="radioInputType2" 
                                                    checked={inputType === INPUT_STOCK_RANCH} onChange={() => setInputType(INPUT_STOCK_RANCH)}></input>
                                                <label htmlFor="radioInputType2">牧場在庫</label>
                                            </div>
                                        )}
                                        { props.clinicId != null && (
                                            <div className="radio radio-css">
                                                <input type="radio" name="radioInputType" id="radioInputType3" 
                                                    checked={inputType === INPUT_STOCK_CLINIC} onChange={() => setInputType(INPUT_STOCK_CLINIC)}></input>
                                                <label htmlFor="radioInputType3">診療所在庫</label>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            { (crossType === HON_CROSS_TYPE || ( ( crossType === AI_CROSS_TYPE || crossType === ET_CROSS_TYPE ) && inputType === INPUT_MANUAL ) ) && (
                                <div className={styles["popup-row"]}>
                                    <label className={"col-form-label " + styles["popup-item-header"]}>{crossType === ET_CROSS_TYPE ? "一代祖" : "種雄牛名"}</label>
                                    <div>
                                        <AncestorSelect
                                            value={father?.exid}
                                            selectedName={father?.name}
                                            onEditClick={() => setIsAncestorPopupShown(true)}
                                            onChange={(id,n) => setFather(toAncestor(id,n))}
                                            list={mAncestors.data}
                                        />
                                    </div>
                                </div>
                            )}
                            { crossType === ET_CROSS_TYPE && inputType === INPUT_MANUAL && (<>
                                <div className={styles["popup-row"]} style={{alignItems:"start"}}>
                                    <label className={"col-form-label " + styles["popup-item-header"]}>母牛</label>
                                    <div>
                                        <div style={{display:"flex"}}>
                                            <button type="button" className="btn btn-green" onClick={() => setIsMotherSelectShown(true)}>牧場から選択</button>
                                            { motherId != null && (
                                                <a className="link m-l-10" style={{alignSelf:"flex-end"}} onClick={onClearMother}>選択クリア</a>
                                            )}
                                        </div>
                                        <input className="form-control m-t-10" type="text" value={motherName}
                                            maxLength={LMT.COW.NAME_LEN}
                                            onChange={ e => setMotherName(e.target.value)} disabled={motherId != null}/>
                                    </div>
                                </div>
                                <div className={styles["popup-row"]}>
                                    <label className={"col-form-label " + styles["popup-item-header"]}>二代祖</label>
                                    <div>
                                        <AncestorSelect
                                            value={ancestor2?.exid}
                                            selectedName={ancestor2?.name}
                                            onEditClick={() => setIsAncestorPopupShown(true)}
                                            onChange={(id,n) => setAncestor2(toAncestor(id,n))}
                                            list={mAncestors.data}
                                        />
                                    </div>
                                </div>
                                <div className={styles["popup-row"]}>
                                    <label className={"col-form-label " + styles["popup-item-header"]}>三代祖</label>
                                    <div>
                                        <AncestorSelect
                                            value={ancestor3?.exid}
                                            selectedName={ancestor3?.name}
                                            onEditClick={() => setIsAncestorPopupShown(true)}
                                            onChange={(id,n) => setAncestor3(toAncestor(id, n))}
                                            list={mAncestors.data}
                                        />
                                    </div>
                                </div>
                            </>)}
                            { (crossType === AI_CROSS_TYPE || crossType === ET_CROSS_TYPE) && inputType !== INPUT_MANUAL && (<>
                                <IconLink text="在庫一覧" iconType="popup" className="m-l-15" onClick={() => setIsSeedStockListShown(true)} />
                                <div className={styles["popup-row"]} style={{ marginBottom:"4px" }}>
                                    <div className={styles["popup-select-wide-container"]}>
                                        <select className="form-control" value={seedId} onChange={e => setSeedId(Number(e.target.value))}>
                                            <option value={0}>選択</option>
                                            {
                                                currentSeedIds.map(s => (
                                                    <option key={s.seed_id} value={s.seed_id}>{seedToDispName(crossType, s)}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className={styles["popup-row"]}>
                                    <div className={styles["popup-select-wide-container"]}>
                                        <select className="form-control" value={lotId} onChange={e => setLotId(Number(e.target.value))}>
                                            <option value={0}>Lot選択</option>
                                            {
                                                currentSeedLots.map(l => (
                                                    <option key={l.seed_lot_id} value={l.seed_lot_id}>{lotToDispName(l)}</option>
                                                ))
                                            }
                                        </select>
                                        { crossType === ET_CROSS_TYPE && <div className={styles["popup-detail-info"]}>{currentLotInfo ?? ""}</div> }
                                        { lotId !== 0 && <div className={styles["popup-detail-info"]}>{currentSeedLots.find(l => l.seed_lot_id === lotId)?.comment ?? ""}</div> }
                                    </div>
                                </div>
                                <div className={styles["popup-row"]} style={{marginBottom:"4px"}}>
                                    <span className={styles["popup-item-header"]}>
                                        <label className="col-form-label">ラベルNo.</label>
                                    </span>
                                </div>
                                { labels.map((_,i) => (
                                    <div key={i} className={styles["popup-row"]} style={{marginBottom:"4px"}}>
                                        <span className={styles["popup-item-header"]}>
                                            <label className="col-form-label">{crossType === AI_CROSS_TYPE ? `ストロー${i+1}本目` : `${i+1}卵目`}</label>
                                        </span>
                                        <div>
                                            <select className={"form-control " + styles["popup-select"]} value={labels[i]} onChange={e => onLabelSelected(i, e.target.value)}>
                                                <option value={""}>選択</option>
                                                {
                                                    currentSeedStocks.map(s => (
                                                        <option key={s.label_no} value={s.label_no}>{s.label_no}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                ))}
                            </>)}
                        </div>
                    </ModalBody>
                    <ModalFooter className="modal-footer-fix">
                        <ExecutionButton type="decide" onClick={onSubmit} disabled={!canSubmit()} />
                    </ModalFooter>
                    { isMotherSelectShown && (
                        <SelectMotherPopup
                            setIsLoading={handleSetIsLoading}
                            ranch_id={props.ranchId}
                            onClose={()=>setIsMotherSelectShown(false)}
                            onSubmit={(mother) => onMotherSelected(mother)} 
                            iniCheckedId={motherId}
                            comm={props.comm} />
                    )}
                    { isAncestorPopupShown && (
                        <SettingAncestorPopup
                            comm={props.comm}
                            confirm={showQuestionAsync}
                            list={mAncestors.data}
                            onClose={() => setIsAncestorPopupShown(false)}
                            // ※診療所であっても牧場リストが対象
                            teamId={props.ranchId}
                        />
                    )}
                    { isSeedStockListShown && (
                        <SeedStockListPopup
                            onClose={() => setIsSeedStockListShown(false)}
                            onSelect={onSeedSelectedOnList}
                            seed_type={crossType === AI_CROSS_TYPE ? A.SEED_TYPE.SEED.seed_type : A.SEED_TYPE.EGG.seed_type}
                            teamType={inputType === INPUT_STOCK_RANCH ? "ranch" : "clinic"}
                            stocks={currentAllStocks}
                        />
                    )}
                </>)}
            </Modal>
        </div>
    );
}