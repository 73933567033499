import React, { useEffect } from 'react';
import styles from './ranch.module.css';
import { Table } from "reactstrap";
import { CowListOutputKey, ICowListOutput, getOutputHeader } from './select-output-popup';
import { CowSearchCowDto, CowSearchScheduleDto, CowSearchDeliveryDto, CowSearchBreedingDto, CowSearchRutDto, CowSearchTreatDto, CowSearchCrossDto, CowSearchSymptomPhysicalDto, CowSearchSymptomDto, CowSearchPreventionDto, CowSearchLocationDto } from '../../api';
import { IRanchHouse, IUser, IFecesState, IFecesColor } from '../../stores';
import { BALANCE_CATEGORIES, BALANCE_CATEGORY } from '../balance/balance-graph';
import moment from 'moment';
import { A, BREEDING_STATES, BREEDING_STATE, TAG_SEARCH_SYMBOL } from '../../config/constant';
import { SortIcon } from '../../components/parts/sort-icon';
import { CowToDispInfo, RenderFullTraceId, LocalNoOrRepNo, ICowNameInfo } from '../../components/parts/cows-popup';
import { calcAge, formatYen, CommonUtil, ar, FreezedArray, calcDayAge } from '../../config/util';
import { hasRanchAuth } from '../../config/auth-checker';
import { getTreatHisStr, buildCowSearchRut, buildCowSearchBreeding, buildCowSearchCross, buildCowSearchDelivery, buildCowSearchSymptom } from '../cow/cow-event-renderer';
import { EventKind } from '../../config/event-kind';
import { isWashout } from '../../config/Washout';
import washoutIcon from '../../assets/icon/washout-on_s.png';
import { SortOrder } from '../feedbulk/BulkCowSortIcon';
import { useRanchHouses } from '../../stores/fetcher';
import { FetchWaiter, FetchError } from '../../components/content/fetch-state';

type TableCellValue = string | number | undefined | JSX.Element | string[];
type CowTableItemData = { value: TableCellValue, testId?: string, forCsv?: string | number };
export type CowDispData = CowSearchCowDto & { tableData: CowTableItemData[] };

export interface CowListTableProps {
    sortKey: CowListOutputKey;
    sortOrder: SortOrder;
    onSortChange: (key: CowListOutputKey, order: SortOrder) => void;
    cows: CowSearchCowDto[];
    dispList: CowDispData[];
    onDispListChange: (dispList: CowDispData[]) => void;
    checkedCowIds: number[];
    traceFilter: string;
    onCheckChanged: (cowId: number, checked: boolean) => void;
    onCowClick: (cowId: number) => void;
    output: ICowListOutput;
    fecesStates: Readonly<IFecesState[]>;
    fecesColors: Readonly<IFecesColor[]>;
    user: IUser;
    ranchId: number;
    now: Date;
    ageMode: number;
    onTagClick: (tag: string) => void;
}

export const buildLocationName = (sites:Readonly<Readonly<IRanchHouseForGetName>[]>, site: number, barn: number, room: number) => {
    const names = getHouseNames(sites, site, barn, room);
    if (names == null) return "未設定";

    if (names.site === "") return "削除された分場";

    if (names.barn === "" && barn > 0) {
        names.barn = "削除された牛舎";
        names.room = "";
    } else if (names.room === "" && room > 0) {
        names.room = "削除された部屋";
    }

    return [names.site, names.barn, names.room].filter(n => n !== "").join(" ");
}

const ALL_TABLE_COLUMN_KEYS: Readonly<CowListOutputKey[]> = [
    "rep_no",
    "trace_id",
    "name",
    "sex",
    "birthday",
    "age",
    "day_age",
    "accept_from",
    "accept_day",
    "breed",
    "use",
    "site",
    "barn",
    "room",
    "tag",
    "sell_month",
    "mother",
    "surrogate_mother",
    "ancestors",
    ...BALANCE_CATEGORIES,
    "profit",
    "loss",
    "balance",
    "breeding_state",
    "cross_count",
    "cross_day",
    "cross_kind",
    "days_after_cross",
    "delivery_count",
    "delivery_day",
    "days_after_delivery",
    "delivery_schedule",
    "days_after_rut",
    "note",
    "his_ruts",
    "his_breedings",
    "his_crosses",
    "his_deliveries",
    "his_symptom_physical",
    "his_symptom_treatment",
    "his_prevention",
    "his_location",
    "schedule"
];

const matchesWithIdentityCodeOrName = (cow: ICowNameInfo & { tags?: string[] }, filter: string) => {
    if (filter.startsWith(TAG_SEARCH_SYMBOL)) {
        const tag = filter.slice(TAG_SEARCH_SYMBOL.length).trimRight();
        return cow.tags != null && cow.tags.includes(tag);
    }

    const cd = LocalNoOrRepNo(cow);
    if (cd.indexOf(filter) >= 0) {
        return true;
    }
    if (cow.name != null && cow.name !== "" && cow.name.indexOf(filter) >= 0) {
        return true;
    }
    return false;
}

export const buildTableHeaders = (output: ICowListOutput) => ALL_TABLE_COLUMN_KEYS.filter(k => isInOutputs(output, k)).map(k => ({ key: k, header: getOutputHeader(k)}));

type TPropsForSort = { houses: FreezedArray<IRanchHouse> };
type TSort = ((cow: CowSearchCowDto, props: TPropsForSort) => string)|((cow: CowSearchCowDto, props: TPropsForSort) => number);
const sortKeyByEvtHis = <TEvent extends {}>(list: TEvent[] | undefined, pickDate: (e:TEvent) => string) => {
    if (list == null || list.length === 0) return "0001-01-01";
    return pickDate(list[list.length - 1]);
}
const sortKeyByHouse = (houses: Readonly<IRanchHouse[]>, site: number, barn?:number, room?:number) => {
    if (site === 0) return 0;

    const siteItem = houses.find(s => s.no === site);
    if (siteItem == null) return 0;

    let res = siteItem.disp_no * 100000;
    if (barn == null || barn === 0) return res;

    const barnItem = siteItem.data?.find(b => b.no === barn);
    if (barnItem == null) return res;

    res += barnItem.disp_no * 1000;
    if (room == null || room === 0) return res;

    const roomItem = barnItem.data?.find(r => r.no === room);
    if (roomItem == null) return res;

    return res + roomItem.disp_no;
}

const sortSchedules = (schedules: CowSearchScheduleDto[]): Array<CowSearchScheduleDto & { mst:moment.Moment, med:moment.Moment }> => {
    const rtn = schedules.map(s => ({ ...s, mst: moment(s.start_at), med: moment(s.end_at)}));
    rtn.sort((a,b) => {
        if (a.mst.isBefore(b.mst)) return -1;
        if (b.mst.isBefore(a.mst)) return 1;
        if (a.med.isBefore(b.med)) return -1;
        if (b.med.isBefore(a.med)) return 1;
        return 0;
    });
    return rtn;
}

export const isInOutputs = (output: ICowListOutput, key: CowListOutputKey) => {
    const item = output[key];
    return typeof(item) === "boolean" ? item : item.output;
}
export const findFirstSortableKey = (output: ICowListOutput) => {
    return ALL_TABLE_COLUMN_KEYS.find(k => isInOutputs(output, k) && SORT_MAP.has(k));
}

const SORT_MAP: Map<CowListOutputKey, TSort> = new Map<CowListOutputKey, TSort>([
    ["rep_no", c => c.rep_no],
    ["trace_id", c => c.trace_id],
    ["name", c => c.name],
    ["sex", c => c.is_male],
    ["birthday", c => c.birthday ?? "0001-01-01"],
    ["age", c => moment(c.birthday ?? "9999-12-31").unix() * -1],
    ["day_age", c => moment(c.birthday ?? "9999-12-31").unix() * -1],
    ["accept_from", c => c.accept_from ?? ""],
    ["accept_day", c => c.accept_day ?? "0001-01-01"],
    ["breed", c => c.breed_no ?? 0],
    ["use", c => c.use_no ?? 0 ],
    ["site", (c,p) => sortKeyByHouse(p.houses, c.site)],
    ["barn", (c,p) => sortKeyByHouse(p.houses, c.site, c.barn)],
    ["room", (c,p) => sortKeyByHouse(p.houses, c.site, c.barn, c.room)],
    ["tag", c => (c.tags ?? []).join(" ")],
    ["sell_month", c => c.sell_month ?? "0001-01-01"],
    ["mother", c => c.mother_name ?? ""],
    ["surrogate_mother", c => c.surrogate_mother_name ?? ""],
    ["ancestors", c => `${c.ancestor_1 ?? ""} ${c.ancestor_2 ?? ""} ${c.ancestor_3 ?? ""}`],
    ["profit", c => c.total_profit ?? 0],
    ["loss", c => c.total_loss ?? 0],
    ["balance", c => (c.total_profit ?? 0) - (c.total_loss ?? 0)],
    ["breeding_state", c => (c.use_no != null && A.IS_FOR_BREEDING_COW(c.use_no)) ? (c.breeding_state ?? -1) : -1],
    ["cross_count", c => c.cross_count ?? 0],
    ["cross_day", c => c.cross_day ?? "0001-01-01"],
    ["cross_kind", c => c.cross_kind ?? ""],
    ["days_after_cross", c => c.days_after_cross ?? -9999],
    ["delivery_count", c => c.delivery_count ?? 0 ],
    ["delivery_day", c => c.delivery_day ?? "0001-01-01" ],
    ["days_after_delivery", c => c.days_after_delivery ?? -9999 ],
    ["delivery_schedule", c => c.delivery_schedule ?? "0001-01-01" ],
    ["days_after_rut", c => c.days_after_rut ?? -9999],
    ["note", c => c.note ?? "" ],
    ["his_ruts", c => sortKeyByEvtHis(c.ruts, r => r.watched_at) ],
    ["his_breedings", c => sortKeyByEvtHis(c.breedings, b => b.watched_at) ],
    ["his_crosses", c => sortKeyByEvtHis(c.crosses, c => c.watched_at) ],
    ["his_deliveries", c => sortKeyByEvtHis(c.deliveries, d => d.delivered_at) ],
    ["his_symptom_physical", c => sortKeyByEvtHis(c.symptom_physicals, p => p.watched_at) ],
    ["his_symptom_treatment", c => sortKeyByEvtHis(c.symptom_treatments, t => t.watched_at) ],
    ["his_prevention", c => sortKeyByEvtHis(c.preventions, p => p.watched_at) ],
    ["his_location", c => sortKeyByEvtHis(c.locations, c => c.start_day) ],
    ["schedule", c => (c.schedules ?? []).length === 0 ? "0001-01-01" : sortSchedules(c.schedules!)[0].start_at ],
]);
BALANCE_CATEGORIES.forEach(bc => SORT_MAP.set(bc, c => (c.balances ?? []).find(b => b.balance_category === BALANCE_CATEGORY[bc].id)?.price ?? 0));

type TPropsForRender = Pick<CowListTableProps, "fecesStates"|"fecesColors"|"ranchId"|"user"|"ageMode"|"onTagClick"> & { houses: FreezedArray<IRanchHouse> };
type TRender = (cow: CowSearchCowDto, props: TPropsForRender, now:Date) => CowTableItemData;

const RENDER_MAP: Map<CowListOutputKey, TRender> = new Map<CowListOutputKey, TRender>([
    ["rep_no", cow => ({ value: CowToDispInfo(cow, false), testId: "耳標" }) ],
    ["trace_id", cow => ({ value: RenderFullTraceId(cow.trace_id, {}, { fontSize:"0.85rem" }), forCsv: cow.trace_id }) ],
    ["name", cow => ({ value: cow.name, testId: "名号" }) ],
    ["sex", cow => ({ value: A.GET_SEX_MARK(cow.is_male) }) ],
    ["birthday", cow => ({ value: toDispDate(cow.birthday) }) ],
    ["age", (cow,p,n) => ({ value: (cow.birthday ?? "") === "" ? "" : calcAge(moment(n), moment(cow.birthday), p.ageMode) }) ],
    ["day_age", (cow,_,n) => ({ value: (cow.birthday ?? "") === "" ? "" : calcDayAge(moment(n), moment(cow.birthday)) })],
    ["accept_from", cow => ({ value: cow.accept_from }) ],
    ["accept_day", cow => ({ value: toDispDate(cow.accept_day) }) ],
    ["breed", cow => ({ value: cow.breed }) ],
    ["use", cow => ({ value: cow.use }) ],
    ["site", (cow,p) => ({ value: getHouseName(p.houses, cow.site, cow.barn, cow.room, "", { site:true }) }) ],
    ["barn", (cow,p) => ({ value: getHouseName(p.houses, cow.site, cow.barn, cow.room, "", { barn:true }) }) ],
    ["room", (cow,p) => ({ value: getHouseName(p.houses, cow.site, cow.barn, cow.room, "", { room:true }) }) ],
    ["tag", (cow,p) => {
        const rendered = (
            <div className={styles["cell-tag"]}>
                { (cow.tags ?? []).map(tg => (
                    <div onClick={e => { e.stopPropagation(); p.onTagClick(tg); }} className={styles.tag}>{tg}</div>
                ))}
            </div>
        );

        return ({ value: rendered, forCsv: (cow.tags ?? []).join(", ") })
    } ],
    ["sell_month", cow => ({ value: cow.sell_month == null ? "" : moment(cow.sell_month).format("YYYY年M月") }) ],
    ["mother", cow => ({ value: cow.mother_name }) ],
    ["surrogate_mother", cow => ({ value: cow.surrogate_mother_name }) ],
    ["ancestors", cow => ({ value: getAncestorsName(cow) }) ],
    ["profit", cow => ({ value: formatYen(cow.total_profit ?? 0), forCsv: cow.total_profit ?? 0 }) ],
    ["loss", cow => ({ value: formatYen(cow.total_loss ?? 0), forCsv: cow.total_loss ?? 0 }) ],
    ["balance", cow => {
        const price = (cow.total_profit ?? 0) - (cow.total_loss ?? 0);
        return ({ value: formatYen(price), forCsv: price });
    }],
    ["breeding_state", cow => {
        //搾乳牛・繁殖牛以外は繁殖状態を表示しない
        const value = cow.breeding_state == null ? ""
                    : (cow.use_no == null || !A.IS_FOR_BREEDING_COW(cow.use_no)) ? ""
                    : (BREEDING_STATES.find(s => BREEDING_STATE[s].no === cow.breeding_state) ?? "");
        return ({ value } );
    }],
    ["cross_count", cow => ({ value: cow.cross_count }) ],
    ["cross_day", cow => ({ value: toDispDate(cow.cross_day) }) ],
    ["cross_kind", cow => ({ value: cow.cross_kind ?? "" }) ],
    ["days_after_cross", cow => ({ value: cow.days_after_cross }) ],
    ["delivery_count", cow => ({ value: cow.delivery_count }) ],
    ["delivery_day", cow => ({ value: toDispDate(cow.delivery_day) }) ],
    ["days_after_delivery", cow => ({ value: cow.days_after_delivery  }) ],
    ["delivery_schedule", cow => ({ value: toDispDate(cow.delivery_schedule) }) ],
    ["days_after_rut", cow => ({ value: cow.days_after_rut })],
    ["note", cow => ({ value: cow.note ?? "" }) ],
    ["his_ruts", (cow,p) => ({ value: renderRuts(cow.ruts, p.ranchId, p.user) }) ],
    ["his_breedings", (cow,p) => ({ value: renderBreedings(cow.breedings, p.ranchId, p.user) }) ],
    ["his_crosses", (cow,p) => ({ value: renderCrosses(cow.crosses, p.ranchId, p.user) }) ],
    ["his_deliveries", (cow,p) => ({ value: renderDeliveries(cow.deliveries) }) ],
    ["his_symptom_physical", (cow,p) => ({ value: renderPhysicals(cow.symptom_physicals, p.fecesStates, p.fecesColors) }) ],
    ["his_symptom_treatment", (cow,p) => ({ value: renderTreatments(cow.symptom_treatments, p.ranchId, p.user) }) ],
    ["his_prevention", (cow,p) => ({ value: renderPreventions(cow.preventions, p.ranchId, p.user) }) ],
    ["his_location", (cow,p,n) => ({ value: renderLocations(p.houses, cow.locations, n) }) ],
    ["schedule", cow => ({ value: renderSchedules(cow.schedules)}) ],
]);
BALANCE_CATEGORIES.forEach(keyBalance => {
    RENDER_MAP.set(keyBalance, cow => {
        const balance = (cow.balances ?? []).find(b => b.balance_category === BALANCE_CATEGORY[keyBalance].id);
        const price = balance?.price ?? 0;
        return ({ value: formatYen(price), forCsv: price });
    })
});

export type IRanchHouseForGetName = { no:number, name:string, data?:FreezedArray<IRanchHouseForGetName>}
export const getHouseNames = (houses: FreezedArray<IRanchHouseForGetName>, siteNo:number, barnNo:number, roomNo:number) => {
    if (siteNo === 0) return undefined;

    const result = { site: "", barn: "", room: "" };

    const site = houses.find(h => h.no === siteNo);
    if (site == null) return result;

    result.site = site.name;

    const barn = site.data?.find(b => b.no === barnNo);
    if (barn == null) return result;

    result.barn = barn.name;

    const room = barn.data?.find(r => r.no === roomNo);
    if (room == null) return result;

    result.room = room.name;

    return result;
}

const getHouseName = (houses:Readonly<Readonly<IRanchHouse>[]>,
                        siteNo:number, barnNo:number, roomNo:number,
                        whenUnset = "",
                        output?:{ site?:boolean, barn?:boolean, room?:boolean }) => {
    if (output == null) {
        output = { site:true, barn: true, room:true };
    }

    const names = getHouseNames(houses, siteNo, barnNo, roomNo);
    if (names == null) return whenUnset;

    const rtn: string[] = [];
    if (output.site && names.site !== "") {
        rtn.push(names.site);
    }
    if (output.barn && names.barn !== "") {
        rtn.push(names.barn);
    }
    if (output.room && names.room !== "") {
        rtn.push(names.room);
    }
    return rtn.join(" ");
}

const getAncestorsName = (cow: CowSearchCowDto) => {
    return [ cow.ancestor_1, cow.ancestor_2, cow.ancestor_3 ]
        .map((n,i) => ({ name: n, no: i + 1 }))
        .filter(a => (a.name ?? "") !== "")
        .map(a => `${a.no}.${a.name}`)
        .join(" ");
}


const bulidCowTableData = (cow: CowSearchCowDto, output: ICowListOutput, now: Date, props: TPropsForRender): CowTableItemData[] => {

    return ALL_TABLE_COLUMN_KEYS
        .filter(k => isInOutputs(output, k))
        .map(key => {
            const rend = RENDER_MAP.get(key);
            if (!CommonUtil.assertNotNull(rend, "render logic of " + key)) return { value: "" };
            return rend(cow, props, now);
        });
}

const buildDispList = (cows: CowSearchCowDto[], output: ICowListOutput, filter: string, sortKey: CowListOutputKey, sortOrder: SortOrder, now: Date,
                    props: TPropsForRender) => {
    const filteredListCows = filter === "" ? cows : cows.filter(c => matchesWithIdentityCodeOrName(c, filter));
    const filteredCows = filteredListCows.map(c => ({ ...c, tableData: bulidCowTableData(c, output, now, props)}));

    const sort = SORT_MAP.get(sortKey);
    if (!CommonUtil.assertNotNull(sort, "sort logic of " + sortKey)) return [];

    const list = sortOrder === "Asc" ? ar.orderBy(filteredCows, c => sort(c, props)) : ar.orderByDesc(filteredCows, c => sort(c, props));

    return list;
}

const toDispDate = (val?: string) => (val ?? "").replace(/-/g, "/");

const hisEventHeader = (at: string, hasTime: boolean) => `[${moment(at).format(hasTime ? "YYYY/MM/DD HH:mm" : "YYYY/MM/DD")}]`;

const hisTreat = (treat: CowSearchTreatDto, ranchId: number, user: IUser) => {
    const canRefAmount = hasRanchAuth("TREAT_REF_AMOUNT", ranchId, user);
    return "・" + getTreatHisStr(treat.treat_item_name, treat.amount, treat.treat_item_unit, canRefAmount);
}

const renderRuts = (ruts: CowSearchRutDto[] | undefined, ranchId: number, user: IUser): string[] => {

    return ar.flat((ruts ?? []).map(r => [
        `${hisEventHeader(r.watched_at, true)} ${buildCowSearchRut(r)}`,
        ...r.details.map(d => hisTreat(d, ranchId, user))
    ]));
}
const renderDeliveries = (deliveries?: CowSearchDeliveryDto[]): string[] => {

    return (deliveries ?? []).map(d => `${hisEventHeader(d.delivered_at, true)} ${buildCowSearchDelivery(d)}`);
}
const renderBreedings = (breedings: CowSearchBreedingDto[] | undefined, ranchId: number, user: IUser): string[] => {

    const rtn: string[] = [];
    if (breedings == null) return rtn;
    for (const b of breedings) {
        const info = buildCowSearchBreeding(b);
        rtn.push(`${hisEventHeader(b.watched_at, true)} ${info.summary}`);
        rtn.push(...info.structures.map(s => `・${s}`));
        rtn.push(...b.details.map(d => hisTreat(d, ranchId, user)));
    }
    return rtn;
}
const renderCrosses = (crosses: CowSearchCrossDto[] | undefined, ranchId: number, user: IUser): string[] => {
    const canRefName = hasRanchAuth("CROSS_REF_NAME", ranchId, user);

    return (crosses ?? []).map(c => `${hisEventHeader(c.watched_at, true)} ${buildCowSearchCross(c, canRefName)}`);
}

const renderPhysicals = (physicals: CowSearchSymptomPhysicalDto[] | undefined, fecesStates: Readonly<Readonly<IFecesState>[]>, fecesColors:Readonly<Readonly<IFecesColor>[]>): string[] => {
    return (physicals ?? [])
        .map(p => ({ dto: p, str:buildCowSearchSymptom(p, fecesStates, fecesColors) }))
        //※疾病項目／投薬／メモのみの記録は除外されるものとする
        .filter(p => p.str !== "")
        .map(p => `${hisEventHeader(p.dto.watched_at, true)} ${p.str}`);
}

const renderTreatments = (symptoms: CowSearchSymptomDto[] | undefined, ranchId: number, user: IUser): string[] => {
    return ar.flat(
        (symptoms ?? [])
            .filter(s => s.treats.length > 0)
            .map(s => [
                hisEventHeader(s.watched_at, true),
                ...s.treats.map(t => hisTreat(t, ranchId, user))
            ])
    );
}

const renderPreventions = (preventions: CowSearchPreventionDto[] | undefined, ranchId: number, user: IUser): string[] => {
    return ar.flat((preventions ?? []).map(p => [ hisEventHeader(p.watched_at, true), ...p.details.map(d => hisTreat(d, ranchId, user))]));
}

const renderLocations = (houses: Readonly<Readonly<IRanchHouse>[]>, locations: CowSearchLocationDto[] | undefined, now: Date): string[] => {
    const today = moment(now).startOf("day");

    const list = (locations ?? []).map(l => ({ ...l, mst: moment(l.start_day) }));

    return list.map((l,i) => ({ ...l, med: i === list.length - 1 ? today : list[i + 1].mst }))
            .map(l => `${hisEventHeader(l.start_day, false)} ${buildLocationName(houses, l.site, l.barn, l.room)}（${l.med.diff(l.mst, "days")}日）`);
}

const renderSchedules = (schedules: CowSearchScheduleDto[] | undefined): string[] => {
    const sorted = sortSchedules(schedules ?? []);
        
    return sorted.map(s => {
        let date: string;
        if (s.mst.format("YYYYMMDD") === s.med.format("YYYYMMDD")) {
            date = s.has_time === 1 ? s.mst.format("M/D HH:mm") : s.mst.format("M/D");
        } else {
            date = `${s.mst.format("M/D")}-${s.med.format("M/D")}`;
        }

        return `${date} [${EventKind.find(s.event_kind_no)?.schedule?.sname}]${s.title}`;
    });
}


export const CowListTable = (props: CowListTableProps) => {
    const mHouses = useRanchHouses(props.ranchId);

    useEffect(() => {
        if (mHouses.data == null) return;

        const propsForRender = { ...props, houses: mHouses.data };

        props.onDispListChange(buildDispList(props.cows, props.output, props.traceFilter, props.sortKey, props.sortOrder, props.now, propsForRender));

        //※厳密にはnowも監視すべきだが、そこまで厳密に同期させる必要はないので、renderを減らすため対象から外す
    }, [ props.cows, props.sortKey, props.sortOrder, props.traceFilter, props.output, mHouses.data ])

    const isChecked = (cow_id: number) => {
        var idx = props.checkedCowIds.indexOf(cow_id);
        return idx >= 0;
    }

    const onTableHeaderClick = (key: CowListOutputKey) => {
        const sort = SORT_MAP.get(key);
        if (sort == null) return;

        props.onSortChange(key, props.sortKey !== key ? "Asc" : (props.sortOrder === "Asc" ? "Desc" : "Asc"));
    }

    const tableHeaders = buildTableHeaders(props.output);

    const rowProps = (cow:CowSearchCowDto, testId?:string, dataType:"text"|"icon"|"check" = "text") => {
        return {
            onClick: dataType === "check" ? undefined : () => props.onCowClick(cow.cow_id),
            className: dataType === "icon" ? styles["rowdata-icon"] : styles["rowdata-text"],
            "data-testid": testId
        }
    }

    if (mHouses.isLoading) return <FetchWaiter />
    if (mHouses.isError) return <FetchError />

    return (
        <Table className={styles.table}>
            <thead>
                <tr>
                    <th></th>
                    { tableHeaders.map((h, i) => (
                        <th key={i} onClick={() => onTableHeaderClick(h.key)}>
                            <div>
                                <span>{h.header}</span>
                                <SortIcon state={props.sortKey === h.key ? props.sortOrder : undefined} />
                            </div>
                        </th>
                    ))}
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                { props.dispList.map((cow,i) => (
                    <tr key={cow.cow_id} className={isChecked(cow.cow_id) ? styles["row-checked"] : undefined}>
                        <td {...rowProps(cow, undefined, "check")}>
                            <div className="checkbox checkbox-css" style={{ display: "inline" }} data-testid="行選択">
                                <input type="checkbox" id={`cssCheckbox_${i}`}
                                    onChange={e => props.onCheckChanged(cow.cow_id, e.target.checked)}
                                    checked={isChecked(cow.cow_id)} />
                                <label htmlFor={`cssCheckbox_${i}`} style={{ padding: "2px 8px" }}>&nbsp;</label>
                            </div>
                        </td>
                        { cow.tableData.map((td,i) => (
                            <td key={i} {...rowProps(cow, td.testId)}>
                                { Array.isArray(td.value) ? td.value.map((str,si) => <React.Fragment key={si}>{str}<br/></React.Fragment>) : td.value }
                            </td>
                        ))}
                        <td {...rowProps(cow, undefined, "icon")}>
                            { !!cow.watching && 
                                <i className="fa fa-exclamation"
                                    style={{
                                        fontSize:".9rem",
                                        padding: "7px 10px 0 10px",
                                        color: "#ef3a3a"
                                    }} 
                                />
                            }
                        </td>
                        <td {...rowProps(cow, undefined, "icon")}>
                            { isWashout(cow.washouts, props.now) && 
                                <img alt="出荷制限" src={washoutIcon} style={{height: "20px", marginTop: "3px"}} />
                            }
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}