import React, { useCallback, useState, useEffect, useRef } from 'react';
import { FreezedArray, CommonUtil } from '../../config/util';
import { isMobile } from 'react-device-detect';
import CreatableSelect from 'react-select/creatable';
import Select, { components, createFilter } from 'react-select';
import classnames from 'classnames';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FilterBox } from './filter-box';
import styles from './common-select.module.css';

export type SelectOption<TVal> = { label: string, value: TVal };

export interface CommonSelectProps<TVal> {
    options: FreezedArray<SelectOption<TVal>>;
    value: TVal | null | undefined;
    onSelect: (value: TVal) => void;
    onCreate: ((name: string) => void) | undefined;
    canCreate?: (input:string) => boolean;
    formatCreateLabel?: (name: string) => string;
    onClear?: () => void;
    maxLength?: number;
    inputId?: string;
    optionTestId?: string;
    style?: React.CSSProperties;
    className?: string
    itemName?: string;
    /**
     * PC表示時、ドロップダウンをどこに配置するか（default: bottom）
     * ※モーダル内で使用する際、画面下部でbottomにメニューを開くと
     * 　親画面の領域が拡張してスクロールしてしまう場合、対処としてautoにする
     * （デフォルトでautoにしてしまっても問題ないかもしれないが、
     * 　影響が確認しきれないので、必要に応じて設定）
     */
    menuPlacementOnPc?: "auto"|"bottom"|"top";
}

const filterOption = createFilter({ stringify:o => o.label });

export const CommonSelect = <TVal extends string|number>(props: CommonSelectProps<TVal>) => {
    const [ isPopupShown, setIsPopupShown ] = useState(false);

    const noOptionMessage = useCallback(() => "一致なし", []);

    const selectedItem = props.value == null ? null
                    : props.options.find(o => o.value === props.value) ?? null;

    if (!isMobile) {
        const placeholder = (props.itemName ?? "") === "" ? "入力または選択" : `${props.itemName}を入力または選択`;

        const onChange = (val: SelectOption<TVal> | null) => {
            if (val == null) {
                if (props.onClear != null) {
                    props.onClear();
                }
                return;
            }
            props.onSelect(val.value);
        }

        if (props.onCreate != null) {
            const canCreate = props.canCreate;
            const isValidNewOption = canCreate == null ? undefined
                : (input: string, _, options: SelectOption<TVal>[]) => input.trim() !== "" && !options.some(o => o.label === input.trim()) && canCreate(input);

            return (
                <CreatableSelect
                    inputId={props.inputId}
                    value={selectedItem}
                    onCreateOption={props.onCreate}
                    isMulti={false}
                    placeholder={placeholder}
                    noOptionsMessage={noOptionMessage}
                    isClearable={props.onClear != null}
                    formatCreateLabel={props.formatCreateLabel}
                    onChange={onChange}
                    isValidNewOption={isValidNewOption}
                    className={props.className}
                    menuPortalTarget={document.body}
                    filterOption={filterOption}
                    menuPlacement={props.menuPlacementOnPc}
                    styles={{
                        container:(provided) => ({
                            ...provided,
                            ...props.style
                        }),
                        control:(provided)=>({
                            ...provided,
                            border: "2px solid rgb(206,212,218)"
                        }),
                        menuPortal:(provided) =>({
                            ...provided,
                            zIndex: 1100
                        }),
                    }}
                    options={ props.options }
                    onInputChange={(input:string) => (props.maxLength == null || input.length <= props.maxLength) ? input : input.substr(0, props.maxLength)}
                    components={{ Option: p => <components.Option {...p}><span data-testid={props.optionTestId}>{p.children}</span></components.Option> }}
                />
            )
        }

        return (
            <Select
                inputId={props.inputId}
                value={selectedItem}
                placeholder={placeholder}
                noOptionsMessage={noOptionMessage}
                onChange={onChange}
                isClearable={props.onClear != null}
                className={props.className}
                menuPortalTarget={document.body}
                filterOption={filterOption}
                menuPlacement={props.menuPlacementOnPc}
                styles={{
                    container:(provided) => ({
                        ...provided,
                        ...props.style
                    }),
                    control:(provided)=>({
                        ...provided,
                        border: "2px solid rgb(206,212,218)"
                    }),
                    menuPortal:(provided) =>({
                        ...provided,
                        zIndex: 1100
                    }),
                }}
                options={props.options}
                components={{ Option: p => <components.Option {...p}><span data-testid={props.optionTestId}>{p.children}</span></components.Option> }}
            />
        )
    }

    //mobile
    const mPlaceholder = (props.itemName ?? "") === "" ? "選択" : `${props.itemName}を選択`;

    return (<>
        <input type="text" className={classnames("form-control", styles["mobile-input"], props.className)} style={props.style}
            maxLength={props.maxLength}
            readOnly={true}
            onClick={() => setIsPopupShown(true)}
            onKeyDown={e => {
                if (e.key === "Enter") setIsPopupShown(true)
            }}
            value={selectedItem?.label ?? ""}
            placeholder={mPlaceholder}
            // fix default size=20
            size={1}
        />
        { isPopupShown && (
            <FullScreenSelectPopup
                {...props}
                onClose={() => setIsPopupShown(false)}
            />
        )}
    </>)
};

interface PopupProps<TVal> extends CommonSelectProps<TVal> {
    onClose:() => void;
}

const FullScreenSelectPopup = <TVal extends number|string>(props: PopupProps<TVal>) => {
    const [ options, setOptions ] = useState<FreezedArray<SelectOption<TVal>>>([]);
    const [ filter, setFilter ] = useState("");
    const [ nameToCreate, setNameToCreate ] = useState<string>();

    useEffect(() => {
        const text = filter.trim();
        if (text === "") {
            setOptions(props.options);
        } else {
            setOptions(props.options.filter(o => o.label.includes(text)));
        }

        //作成可否
        const canCreate = props.onCreate != null
                        && text !== ""
                        && (props.canCreate == null || props.canCreate(text))
                        && !props.options.some(o => o.label === text);
        setNameToCreate(canCreate ? text : undefined);

    }, [ props.options, filter, props.onCreate, props.canCreate ])

    const onFilter = (text: string) => {
        setFilter(text);
    }

    const onSelect = (val: TVal) => {
        props.onSelect(val);
        props.onClose();
    }

    const onClear = () => {
        if (props.onClear) {
            props.onClear();
        }
        props.onClose();
    }

    const onCreate = () => {
        if (!CommonUtil.assertNotNull(props.onCreate, "onCreate")) return;
        if (!CommonUtil.assertNotNull(nameToCreate, "nameToCreate")) return;

        props.onCreate(nameToCreate);
        props.onClose();
    }

    

    return (
        <div>
            <Modal isOpen={true} toggle={props.onClose} scrollable={true}>
                <ModalHeader toggle={props.onClose} className={styles["popup-header"]}>
                    <FilterBox placeholder={props.onCreate == null ? "名前で検索" : "検索 または 新規作成"}
                        onChange={onFilter} value={filter}
                        style={{ fontSize: "0.8125rem" }}
                        onEnterKeyDown={() => {
                            if (options.length === 1) onSelect(options[0].value)
                        }}
                    />
                </ModalHeader>
                <ModalBody>
                    { options.length > 0 && (
                        <ul className={styles["popup-list"]}>
                            { props.onClear != null && filter === "" && (
                                <li className={props.value == null ? styles["selected"] : undefined} onClick={onClear}>選択なし</li>
                            )}
                            { options.map(o => (
                                <li key={o.value} className={props.value === o.value ? styles["selected"] : undefined}
                                    onClick={() => onSelect(o.value)}
                                    >{o.label}</li>
                            ))}
                        </ul>
                    )}
                    { options.length === 0 && nameToCreate == null && (
                        <div>一致なし</div>
                    )}
                    { nameToCreate != null && (
                        <div className="link p-t-5 p-b-5 m-t-10" onClick={onCreate}>{props.formatCreateLabel == null ? `「${nameToCreate}」を作成` : props.formatCreateLabel(nameToCreate)}</div>
                    )}
                </ModalBody>
            </Modal>
        </div>
    )
}


interface TextLinkSelectProps<TVal> {
    onSelect: (val: TVal) => void;
    onClear?: () => void;
    options: FreezedArray<SelectOption<TVal>>;
    text: string;
    linkClassName?: string;
}

export const TextLinkSelect = <TVal extends string|number>(props: TextLinkSelectProps<TVal>) => {
    const [ isPopupShown, setIsPopupShown ] = useState(false);

    return (<>
        <div className={classnames("link", props.linkClassName)} onClick={() => setIsPopupShown(true)}>{props.text}</div>
        { isPopupShown && (
            <FullScreenSelectPopup
                onClose={() => setIsPopupShown(false)}
                onCreate={undefined}
                onClear={props.onClear}
                onSelect={props.onSelect}
                options={props.options}
                value={undefined}
            />
        )}
    </>)
}