import React from 'react';

export interface CheckboxProps {
    id: string;
    label?:string;
    className?: string;
    style?: React.CSSProperties;
    onChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
    checked: boolean;
}

export const Checkbox: React.FC<CheckboxProps> = (props) => (
    // ※"checkbox checkbox-css でpadding-top 7px が指定されているため、縦位置を中央揃えにしたときにずれないように同じ値をbadding-bottomにしておく" 
    <div className={"checkbox checkbox-css " + (props.className ?? "")} style={{ paddingBottom: "7px", ...props.style}}>
        <input type="checkbox" id={props.id} onChange={props.onChange} checked={props.checked} />
        <label htmlFor={props.id}>{props.label ?? ""}{props.children}</label>
    </div>
)