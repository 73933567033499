import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FormMultiLineText } from '../form/form-multi-line-text';

export type TextModalContent = { key: string, value: string | JSX.Element }
export interface ITextModalProps {
    onClose:()=>void;
    title: string;
    bodyMinHeight?: string;
    text:string|string[]|Array<TextModalContent>;
    buttons?: Readonly<{ text: string, onClick:(idx:number) => void, className?: string }>[];
}

export const TextModal: React.FC<ITextModalProps> = (props) => {
    const renderContent = () => {
        if (typeof(props.text) === "string") {
            return <FormMultiLineText>{props.text}</FormMultiLineText>
        }

        if (props.text.length === 0) return <></>;

        if (typeof(props.text[0]) === "string") {
            const rows = props.text as string[];

            return rows.map((r,i) => <div key={i}>{r}</div>);
        }

        const keyVals = props.text as Array<TextModalContent>;

        return (
            <div style={{display:"grid", gridTemplateColumns:"auto 1fr", gridRowGap:"2px", gridColumnGap:"4px"}}>
                { keyVals.map((p,i) => (
                    <React.Fragment key={i}>
                        <div style={{gridRow:i+1, gridColumn:1}}>{p.key}</div>
                        <div style={{gridRow:i+1, gridColumn:2}}>：{p.value}</div>
                    </React.Fragment>
                ))}
            </div>
        );
    }


    return (
        <div className="modal-wide">
            <Modal isOpen={true} toggle={props.onClose} centered={true}>
                <ModalHeader toggle={props.onClose}>{props.title}</ModalHeader>
                <ModalBody style={{ overflowY: "auto", maxHeight: "calc(100vh - 150px)", minHeight:props.bodyMinHeight ?? "200px"}}>
                    { renderContent() }
                </ModalBody>
                { (props.buttons ?? []).length > 0 && (
                    <ModalFooter style={{justifyContent:"space-around"}}>
                        { props.buttons?.map((b,i) => (
                            <button style={{minWidth:"90px"}} key={i} className={b.className} onClick={() => b.onClick(i)}>{b.text}</button>
                        ))}
                    </ModalFooter>
                )}
            </Modal>
        </div>
    );
}