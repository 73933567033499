const COMMON_STORAGE_KEYS = [
    "symptom_name_list"
] as const;

export type CommonStorageKey = typeof COMMON_STORAGE_KEYS[number];

const TEAM_STORAGE_KEYS = [
    "calendar_filter",
    "report_carcass_disp_item",
    "robot_import"
] as const;

export type TeamStorageKey = typeof TEAM_STORAGE_KEYS[number];

const USER_STORAGE_KEYS = [
    "default_ranch",
    "notice_list",
    "cow_feature",
    "preg_check_description",
    "cert_my_clinic_name",
    "cert_my_clinic_address",
    "cert_receiver"
] as const;

export type UserStorageKey = typeof USER_STORAGE_KEYS[number];


export const saveUserStorage = (key: UserStorageKey, userId: string, value: any): boolean => {
    try {
        const json = localStorage.getItem(key);
        let obj;
        if (json == null) {
            obj = {};
        } else {
            obj = JSON.parse(json);
        }
        obj[userId] = value;
        localStorage.setItem(key, JSON.stringify(obj));
        return true;
    } catch(e) {
        console.error(e);
        return false;
    }
}

/**
 * 文字列項目のリストを最新順に指定件数まで保持するよう、ストレージを更新する
 */
export const updateListInUserStorage = (key: UserStorageKey, userId: string, newItem:string, maxCount: number) => {
    newItem = newItem.trim();
    if (newItem === "") return;

    const preList = loadUserStorage<string[]>(key, userId) ?? [];

    const list = [
        newItem,
        ...preList.filter(n => n !== newItem)
    ];
    while (list.length > maxCount) {
        list.pop();
    }

    saveUserStorage(key, userId, list);
}

export const loadUserStorage = <T>(key: UserStorageKey, userId: string): T | undefined => {
    try {
        const json = localStorage.getItem(key);
        if (json == null) return undefined;
        
        const obj = JSON.parse(json);
        return obj[userId];

    } catch(e) {
        console.error(e);
        return undefined;
    }
}

export const saveCommonStorage = (key: CommonStorageKey, value: any): boolean => {
    try {
        localStorage.setItem(key, JSON.stringify(value));
        return true;
    } catch (e) {
        console.error(e);
        return false;
    } 
}

export const loadCommonStorage = <T>(key: CommonStorageKey): T | undefined => {
    try {
        const json = localStorage.getItem(key);
        if (json == null) return undefined;
        return JSON.parse(json);

    } catch (e) {
        console.error(e);
        return undefined;
    }
}

export const saveTeamStorage = <T>(key: TeamStorageKey, teamId: number, value: T): boolean => {
    try {
        const json = localStorage.getItem(key);
        let obj;
        if (json == null) {
            obj = {};
        } else {
            obj = JSON.parse(json);
        }
        obj[teamId] = value;
        localStorage.setItem(key, JSON.stringify(obj));
        return true;
    } catch(e) {
        console.error(e);
        return false;
    }
}

export const loadTeamStorage = <T>(key: TeamStorageKey, teamId: number): T | undefined => {
    try {
        const json = localStorage.getItem(key);
        if (json == null) return undefined;
        
        const obj = JSON.parse(json);
        return obj[teamId];

    } catch(e) {
        console.error(e);
        return undefined;
    }
}

export const clearUserStorage = (key: UserStorageKey | undefined, userId: string) => {
    const keys = key != null ? [ key ] : USER_STORAGE_KEYS;

    try {
        for (const k of keys) {
            const item = localStorage.getItem(k);
            if (item == null) continue;

            const obj = JSON.parse(item);
            if (!(userId in obj)) continue;

            if (Object.keys(obj).length === 1) {
                localStorage.removeItem(k);
            } else {
                delete obj[k];
                localStorage.setItem(k, obj);
            }
        }

    } catch(e) {
        console.error(e);
    }
}