import { EVENT_TYPE_PARAM } from "./constant";

const UNDO_MSG_PROGRAM_TAG = "連動するプログラムによる牛へのタグ付け状態は元に戻りません";
const UNDO_MSG_NEW_TREAT_ITEM = "記録と同時にリスト登録された薬品・処置項目は削除されません";
const UNDO_MSG_WATCHING = "要観察状態の変更は戻りません";
const UNDO_MSG_BREEDING_STATE  = "繁殖状態の変更は戻りません";
const UNDO_MSG_SELLCOW_ACTIVATE = "飼養終了状態の牛は飼養中に戻ります";
const UNDO_MSG_EGG_STOCK = "登録済みの受精卵在庫は削除されません";
const UNDO_MSG_DELIVERY_CHILD = "登録済みの子牛情報は削除されません";

export const ACTIVITY_KINDS = [
    "FEEDING", "LOCATION", "SYMPTOM", "PREVENTION", "RUT", "BREEDING", "DELIVERY", "COW", "SELL_COW", "SELL_MILK_COW", "SELL_MILK_RANCH", "GROWTH", "OTHER_PROFIT_COW", "OTHER_PROFIT_RANCH", "OTHER_LOSS_COW", "OTHER_LOSS_RANCH", "SOV", "OPU", "IVF", "IVF_EGG",
] as const;
type ActivityKindKey = typeof ACTIVITY_KINDS[number];

export const ACTIVITY_KIND: {[key in ActivityKindKey]: { no:number, name:string, eventPageTab?:number, undoNotifications?:string[] } } = {
    FEEDING: {
        no: 1, name:"えさ", eventPageTab:EVENT_TYPE_PARAM.FEED,
        undoNotifications:[ UNDO_MSG_WATCHING ]
    },
    LOCATION: { no: 2, name:"移動" },
    SYMPTOM: {
        no: 3, name:"体調・治療", eventPageTab:EVENT_TYPE_PARAM.SYMPTOM,
        undoNotifications:[ UNDO_MSG_WATCHING, UNDO_MSG_PROGRAM_TAG, UNDO_MSG_NEW_TREAT_ITEM ]
    },
    PREVENTION: {
        no: 4, name:"予防", eventPageTab:EVENT_TYPE_PARAM.SYMPTOM,
        undoNotifications:[ UNDO_MSG_PROGRAM_TAG, UNDO_MSG_NEW_TREAT_ITEM ]
    },
    RUT: {
        no: 5, name:"発情", eventPageTab:EVENT_TYPE_PARAM.BREEDING,
        undoNotifications:[ UNDO_MSG_BREEDING_STATE, UNDO_MSG_PROGRAM_TAG, UNDO_MSG_NEW_TREAT_ITEM ]
    },
    BREEDING: {
        no: 6, name:"検診", eventPageTab:EVENT_TYPE_PARAM.BREEDING,
        undoNotifications:[ UNDO_MSG_BREEDING_STATE, UNDO_MSG_PROGRAM_TAG, UNDO_MSG_NEW_TREAT_ITEM ]
    },
    DELIVERY: {
        no: 8, name:"分娩", eventPageTab:EVENT_TYPE_PARAM.BREEDING,
        undoNotifications: [ UNDO_MSG_BREEDING_STATE, UNDO_MSG_PROGRAM_TAG, UNDO_MSG_DELIVERY_CHILD ]
    }, 
    COW: { no: 11, name:"牛情報" },
    SELL_COW: { no: 12, name:"個体販売", undoNotifications:[ UNDO_MSG_SELLCOW_ACTIVATE ] },
    SELL_MILK_COW: { no: 13, name:"乳代" },
    SELL_MILK_RANCH: { no: 14, name:"乳代" },
    GROWTH: { no: 17, name:"身体測定", eventPageTab:EVENT_TYPE_PARAM.SYMPTOM },
    OTHER_PROFIT_COW: { no: 18, name:"雑収入" },
    OTHER_PROFIT_RANCH: { no: 19, name:"雑収入" },
    OTHER_LOSS_COW: { no: 20, name:"雑損失" },
    OTHER_LOSS_RANCH: { no: 21, name:"雑損失" },
    SOV: { no: 22, name:"体内受精卵", eventPageTab:EVENT_TYPE_PARAM.BREEDING, undoNotifications:[ UNDO_MSG_EGG_STOCK ] },
    OPU: { no: 23, name:"OPU", eventPageTab:EVENT_TYPE_PARAM.BREEDING },
    IVF: { no: 24, name:"媒精", eventPageTab:EVENT_TYPE_PARAM.BREEDING },
    IVF_EGG: { no: 25, name:"培養結果", eventPageTab:EVENT_TYPE_PARAM.BREEDING, undoNotifications:[ UNDO_MSG_EGG_STOCK ] },
};