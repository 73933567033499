import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Dashboard from './Dashboard'
import Menu from './Menu'
import Memo from './Memo'

import { useRootStore, IUser } from '../../stores';
import { usePageStore } from '../../config/page-settings';

import moment from 'moment';

import styles from './top.module.css'
import { ISchedule, EventDtoToISchedule, scheduleCustomColorToReq, SchedulePopup } from '../schedule/schedule-popup';
import { RanchApi, ActivityApi, ActivityDto, NoteApi, NoteDto, NoteModifyReq, RanchScheduleModifyReq, CowListDto, SigninTeamDto, ActivityUndoReq } from '../../api';
import { CommonUtil, ar } from '../../config/util';
import { historyLocation } from '../../config/history-location-builder';
import { isWashout, Washouts } from '../../config/Washout';
import { Communicator } from '../../api/communicator';
import { AppState } from '../../app';
import { DIALOG_BUTTONS } from '../../components/form/form-dialog';
import { ACTIVITY_KINDS, ACTIVITY_KIND } from '../../config/activity-kind';
import { A } from '../../config/constant';
import { UserTeams } from '../../config/user-teams';

const NOTES_PER_PAGE = 10;

interface RouteParams {
  ranchId: string
}

export default () => {
  const rootStore = useRootStore()
  const { user, auth } = rootStore
  const history = useHistory()
  const { ranchId } = useParams<RouteParams>()

  if ( !auth.enabled ) {
    return (<></>)
  }

  const userTeams = new UserTeams(user);

  const firstRanchId = userTeams.firstRanch()?.team_id;

  if ( !ranchId ){
    history.push(firstRanchId ? `/top/${ firstRanchId }` : `/team/search`)
    return (<></>)
  }

  const ranch = userTeams.findRanch(parseInt(ranchId));
  if (ranch === undefined) {
    history.push(firstRanchId ? `/top/${ firstRanchId }` : `/team/search`)
    return (<></>)
  }

  rootStore.setCurRanchId(ranch.team_id);

  return <TopComponent ranch={ ranch } user={ user } />;
}

const TopComponent = ({ ranch, user } : { ranch: Pick<SigninTeamDto, "team_id"|"name">, user:IUser }) => {
  const { handleSetHeader, handleSetPageError, handleSetFooter, handleSetIsLoading, showToast, showQuestion, showQuestionAsync, showDialog } = usePageStore() as AppState;
  const [ schedules, setSchedules ] = useState<ISchedule[]>([]);
  const [ preSchedules, setPreSchedules ] = useState<ISchedule[]>([]);
  const [ todayForSchedule, setTodayForSchedule ] = useState(new Date());
  const [ activities, setActivities ] = useState<ActivityDto[]>([]);
  const [ hasMoreActivities, setHasMoreActivities ] = useState(false);
  const [ cows, setCows ] = useState<Readonly<CowListDto[]>>([]);
  const [ notes, setNotes ] = useState<Array<NoteDto & { pageNo: number }>>([]);
  const history = useHistory();
  const rootStore = useRootStore();
  const [ hasMoreNotes, setHasMoreNotes ] = useState(false);
  const [ popupSchedule, setPopupSchedule ] = useState<ISchedule>();
  const [ isScheduleEditing, setIsScheduleEditing ] = useState(false);

  useEffect(() => {
    handleSetHeader({ title:ranch.name, backBtn:false });
    handleSetPageError(false)
    handleSetFooter(true)

    handleSetIsLoading(true);
    Promise.all([
      rootStore.fetchActiveCows(undefined, "DIFF").then(loadSchedule),
      loadAcitivity(true),
      loadNote(1, true)
    ]).then(() => {
      handleSetIsLoading(false);
      setCows(rootStore.getActiveCows());
    })

    //牧場マスタをfetchしておく
    rootStore.fetchCowTags(ranch.team_id)
  }, [ranch])

  const comm = new Communicator({ logout: rootStore.logout, showToast, showDialog });

  const loadNote = async (page: number, allRefresh: boolean) => {
    if (allRefresh && page !== 1) {
      console.error("page should be 1 when allRefresh");
    }

    const res = await comm.send((await NoteApi()).getListUsingPOST1({
      team_id: ranch.team_id,
      count: NOTES_PER_PAGE,
      page: page
    }), { showsErrorMessage: false });
    if (res.result === "NG" || res.result === "UNREACHED") {
      showToast("ノートの取得に失敗しました");
      return;
    }
    const addedNotes = (res.data ?? []).map(n => ({ ...n, pageNo: page }));

    //allRefreshでなければ、取得したページの部分だけ入れ替える
    const oldNotes = allRefresh ? [] : notes.filter(n => n.pageNo !== page && addedNotes.every(l => l.note_id !== n.note_id));
    const newNotes = CommonUtil.orderByDesc([ ...oldNotes, ...addedNotes ], n => n.note_id);
    setNotes(newNotes);

    const hasMore = addedNotes.length === NOTES_PER_PAGE
                  && (allRefresh || newNotes[newNotes.length - 1].pageNo === page);
    setHasMoreNotes(hasMore);
  }

  const onLoadMoreActivity = async () => {
    handleSetIsLoading(true);
    await loadAcitivity(false);
    handleSetIsLoading(false);
  }

  const loadAcitivity = async (resets: boolean) => {
    const LIMIT = 10;

    const res = await comm.send((await ActivityApi()).listUsingPOST({
      limit: LIMIT,
      ranch_id: ranch.team_id,
      cow_limit: 30,
      excluded_max_id: resets ? undefined : ar.lastOrUndef(activities)?.activity_id
    }), { showsErrorMessage: false });
    if (res.result === "NG" || res.result === "UNREACHED") {
      showToast("アクティビティの取得に失敗しました");
      return;
    }

    const list = (res.data ?? []);

    setActivities(resets ? list : [ ...activities, ...list ]);
    setHasMoreActivities(list.length === LIMIT);
  }

  const loadSchedule = async () => {
    const m = moment();
    const todayStr = m.format("YYYY-MM-DD");
    const yesterdayStr = m.add(-1, "days").format("YYYY-MM-DD");
    const res = await comm.send((await RanchApi()).getScheduleListUsingPOST({
        ranch_id: ranch.team_id,
        start_at: yesterdayStr,
        end_at: todayStr
    }), { showsErrorMessage: false });
    if (res.result === "NG" || res.result === "UNREACHED") {
      showToast("スケジュールの取得に失敗しました");
      return;
    }

    const list = res.data ?? [];
    const schedules = ar.notNull(list.map(d => EventDtoToISchedule(d, () => "")));

    const startOfToday = moment(todayStr).startOf("day");
    const today = startOfToday.toDate();
    const yesterdayAllList = schedules.filter(s => startOfToday.isAfter(s.end));
    const todayList = schedules.filter(s => !yesterdayAllList.some(y => y.id === s.id));

    const yesterdayList = yesterdayAllList.filter(s => !s.status);

    const sortList = (a:ISchedule, b: ISchedule) => {
      const dayA = moment(a.start).startOf("day");
      const dayB = moment(b.start).startOf("day");
      if (dayA.isBefore(dayB)) {
        return -1;
      }
      if (dayA.isAfter(dayB)) {
        return 1;
      }

      if (a.allDay) {
        return b.allDay ? 0 : -1;
      }
      if (b.allDay) return 1;
      return a.start.getTime() - b.start.getTime();      
    }

    yesterdayList.sort(sortList);
    todayList.sort(sortList);

    setPreSchedules(yesterdayList);
    setSchedules(todayList);
    setTodayForSchedule(today);
  }

  const onCowClick = (cow_id:number) => {
    history.push(historyLocation.toCowInfo(cow_id));
  };
  const onScheduleRegistered = (withEvent: boolean) => {
    setPopupSchedule(undefined);
    setIsScheduleEditing(false);
    loadSchedule();
    if (withEvent) loadAcitivity(true);
  };
  const onScheduleSplit = () => {
    setPopupSchedule(undefined);
    setIsScheduleEditing(false);
    loadSchedule();
  }

  const onLoadMoreNotes = async () => {
    handleSetIsLoading(true);
    const lastPageNo = notes[notes.length - 1].pageNo;
    await loadNote(lastPageNo + 1, false);
    handleSetIsLoading(false);
  }

  const onNoteSubmit = async (note_id: number | undefined, text: string) => {
    const req: NoteModifyReq = {
      is_new: note_id == null ? 1 : 0,
      note_id: note_id,
      team_id: ranch.team_id,
      note: text
    };
    handleSetIsLoading(true);
    const res = await comm.send((await NoteApi()).modifyUsingPOST2(req));
    if (res.result !== "OK") {
      handleSetIsLoading(false);
      return false;
    }

    //新規登録時はいったんロード済みのノートをクリア
    const refresh = note_id == null;
    const pageNo = note_id == null ? 1 : notes.find(n => n.note_id === note_id)?.pageNo ?? 1;
    await loadNote(pageNo, true);
    handleSetIsLoading(false);

    return true;
  }

  const onNoteDelete = (note_id: number) => {
    showQuestion('ノートを削除してよろしいですか？', async confirmed => {
      if (confirmed !== 0) return;

      handleSetIsLoading(true);
      const res = await comm.send((await NoteApi()).deleteUsingPOST2({ team_id: ranch.team_id, note_id }));
      handleSetIsLoading(false);
      if (res.result !== "OK") return;

      //リロードせずにローカルで更新とする
      setNotes(notes.filter(n => n.note_id !== note_id));

    }, ['削除', 'キャンセル']);
  }

  const forwardSchedule = async (req: RanchScheduleModifyReq) => {

    handleSetIsLoading(true);
    try {
      const res = await comm.send((await RanchApi()).modifyScheduleUsingPOST(req), { asksToReloadWhenNG:true });
      if (res.result !== "OK") {
        if (res.result !== "NG_RELOAD_REQUIRED") {
          return;
        }
      }
      //成功、またはリロード要求時
      await loadSchedule();
  
    } finally {
      handleSetIsLoading(false);
    }
  }

  const updateScheduleStatus = async (schedule: ISchedule, toFinish: boolean) => {
    const req: RanchScheduleModifyReq = {
      ranch_id: ranch.team_id,
      is_new: 0,
      schedule_id: schedule.id,
      title: schedule.rawTitle,
      start_at: moment(schedule.start).format("YYYY-MM-DD HH:mm:00"),
      end_at: moment(schedule.end).format("YYYY-MM-DD HH:mm:00"),
      has_time: schedule.allDay ? 0 : 1,
      event_kind_no: schedule.eventKindNo,
      note: schedule.note,
      cow_id: schedule.cows.map(c => c.cow_id),
      status: toFinish ? 1 : 0,
      color: scheduleCustomColorToReq(schedule.customColor),
      preset_id: schedule.preset?.preset_id
    };

    handleSetIsLoading(true);
    const res = await comm.send((await RanchApi()).modifyScheduleUsingPOST(req), { asksToReloadWhenNG:true });
    handleSetIsLoading(false);
    if (res.result !== "OK") {
      if (res.result === "NG_RELOAD_REQUIRED") {
        handleSetIsLoading(true);
        await loadSchedule();
        handleSetIsLoading(false);
      }
      return;
    }

    //ローカルで更新（前日分も、リロードされるまでは完了のまま残る）
    const idx = schedules.findIndex(s => s.id === schedule.id);
    if (0 <= idx) {
      setSchedules([
        ...schedules.slice(0, idx),
        { ...schedule, status: toFinish },
        ...schedules.slice(idx + 1)
      ]);
      return;
    }
    const pIdx = preSchedules.findIndex(s => s.id === schedule.id);
    if (pIdx < 0) {
      console.error("invalid idx", schedule, schedules);
      return;
    }
    setPreSchedules([
      ...preSchedules.slice(0, pIdx),
      { ...schedule, status: toFinish },
      ...preSchedules.slice(pIdx + 1)
    ]);
  }

  const undoActivity = async (activity: ActivityDto) => {
    const activityKey = ACTIVITY_KINDS.find(k => ACTIVITY_KIND[k].no === activity.activity_kind);
    if (!CommonUtil.assertNotNull(activityKey, "activity kind: " + activity.activity_kind)) return;
    const activityKind = ACTIVITY_KIND[activityKey];

    const name = activityKind.name;
    const at = moment(activity.created_at).format("M月D日 HH:mm")
    const msg = `${at}に記録した${activity.cows.length > 0 ? activity.cows.length + "頭の" : "" }${name}記録を削除しますか？`;
    const subMsgs = activityKind.undoNotifications ?? [];

    const confirmed = await showDialog("QUESTION", msg, DIALOG_BUTTONS.DELETE_CANCEL, { subTexts: subMsgs }) === 0;
    if (!confirmed) return;

    const req: ActivityUndoReq = {
      activity_id: activity.activity_id,
      ranch_id: activity.ranch_id
    };

    handleSetIsLoading(true);
    const res = await comm.send((await ActivityApi()).undoUsingPOST(req), { excludedErrCodes:[A.ERR_CODE.CANNOT_UNDO] });
    handleSetIsLoading(false);
    if (res.result !== "OK") return;

    if (res.code === A.ERR_CODE.CANNOT_UNDO) {
      await showDialog("NOTIFY", "このアクティビティを取り消すことはできません。\n個別に記録の削除を行ってください。")
      return;
    }

    showToast("記録が削除されました");

    //ローカルで更新
    setActivities(activities.map(a => a.activity_id === activity.activity_id ? ({ ...a, is_undone:1, can_undo:0 }) : a));
  }

  const isOfficial = new UserTeams(user).findOfficialRanch(ranch.team_id) != null;

  return (
    <main className={ styles.page }>
      <section className={ styles["content-area"] }>
        <Dashboard onCowClick={ onCowClick }
                   onForwardSchedule={ forwardSchedule }
                   onUpdateScheduleStatus={ updateScheduleStatus }
                   schedules={ schedules } preSchedules={ preSchedules } todayForSchedule={ todayForSchedule }
                   ranch_id={ranch.team_id} onLinkClick={link=>history.push(link)} activities={activities}
                   hasNextActivities={hasMoreActivities} onNextActivityClick={onLoadMoreActivity}
                   onUndo={ undoActivity }
                   watchingCows={cows.filter(c => c.watching || c.watching_memo !== "")}
                   washoutCows={cows.filter(c => isWashout(c.washouts, new Date())).map(c => ({...c, washouts: new Washouts(c.washouts)}))}
                   showQuestionAsync={showQuestionAsync}
                   onScheduleClick={setPopupSchedule} />
        <Menu ranchId={ ranch.team_id }
              user={user}
              onNavigate={l => history.push(l)}
              onActionSelected={a => history.push(historyLocation.toRanchHouses(a))} />
        { isOfficial && (
          <Memo ranchId={ ranch.team_id } notes={ notes } userId={user.id}
                hasMoreNotes={hasMoreNotes}
                onLoadMore={ onLoadMoreNotes }
                onDelete={ onNoteDelete }
                onSubmit={ onNoteSubmit }/>
        )}
      </section>
      { popupSchedule != null && (
        <SchedulePopup original={popupSchedule}
          isEditing={isScheduleEditing}
          onStartEdit={() => setIsScheduleEditing(true)}
          activeCows={cows}
          ranch_id={ranch.team_id}
          onClose={() => { setIsScheduleEditing(false); setPopupSchedule(undefined); }}
          onRegistered={onScheduleRegistered}
          onSplit={onScheduleSplit}
          onCowClick={onCowClick}
          onResultLinkClick={link => history.push(link)}
          rootStore={rootStore}
          clinic_id={rootStore.getClinicIdForMaster()}
        />
      )}
    </main>
  );
}