import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { A, TIMEPRESETS, EVENT_TYPE_PARAM } from '../../config/constant';
import { PageSettings } from '../../config/page-settings.js';
import { CommonUtil } from "../../config/util";
import { withContext } from '../../stores';
import moment from 'moment';
import { GrowthApi, GrowthDto, GrowthModifyReq, GrowthDeleteReq, GrowthBirthWeightDto } from '../../api';
import { IEventWriteLocationState, IEventWriteParamCow, historyLocation } from '../../config/history-location-builder';
import { AppState } from '../../app';
import { GrowthSelector, IGrowthSelectorData } from './growth-selector';
import { EVENT_KIND } from '../../config/event-kind';
import { ScheduleFinder } from '../schedule/schedule-finder';
import { V3DateTime } from '../../components/parts/v3-date-time-picker';
import { SingleCowHeader } from 'components/cow-header/single-cow-header';
import { MultiCowHeader } from 'components/cow-header/multi-cow-header';
import { getCowWithCache } from 'stores/fetcher_cow';

export interface IGrowth extends GrowthDto {

}

type IData = Omit<IGrowth, "event_id" | "ranch_id" | "cow_id" | "watched_by" | "watched_at"> & {
    watched_at: Date;
}

interface MyProps extends BaseProps<{id:string},{},IEventWriteLocationState|undefined> {
}

interface MyState {
    cow_top: boolean;
    cow_ids: number[];
    original?: Readonly<IGrowth>;
    original_id?: number;
    cows: Readonly<IEventWriteParamCow[]>;
    data: Readonly<IData>;
    anyInvalid: boolean;
    executing: boolean;
    singleCowBirthInfo?: GrowthBirthWeightDto;
}

class GrowthWrite extends Base<MyProps, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    readonly refBottom = React.createRef<HTMLDivElement>();

    constructor(props) {
        super(props);

        this.state = {
            cow_top: false,
            cow_ids: [],
            cows:[],
            data: {
                watched_at : new Date(),
                weight: 0,
                height: 0,
                chest: 0,
                abdomen: 0,
                bcs: 0,
                rfs: 0,
            },
            anyInvalid: false,
            executing: false
        }

        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    componentDidMount() {
        if (this.handleNotAllowAccess(undefined,["GROWTH_EDIT"],[])) {
            return;
        }

        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);
        
        this.init();
    }

    async init() {
        const oriId = parseInt(this.props.match.params.id ?? "");
        const cowIds = this.getParamCowIds(this.props);
        const cowTop = this.getParamCowTop(this.props);

        await this.setStateAsync({
            cow_ids: cowIds,
            original_id: isNaN(oriId) ? undefined : oriId,
            cow_top: cowTop,
        });

        this.context.handleSetHeader({ title: this.state.original_id == null ? "身体測定値を入力" : "身体測定値を編集" });

        if (this.state.original_id != null) {
            const resLoad = await this.loadGrowth(this.state.original_id);
            if (!resLoad) return;
        }

        if (this.state.cow_ids.length > 0) {
            const resCow = await this.loadCowInfo(this.state.cow_ids);
            if (!resCow) return;
        }

        //1頭のときのみ生時体重を取得
        if (this.state.cow_ids.length === 1) {
            const reqG = { ranch_id: this.props.rootStore.cur_ranch_id, cow_ids: this.state.cow_ids };
            const resGrowth = await this.comm().send((await GrowthApi()).getBirthWeightUsingPOST(reqG));
            const gs = resGrowth.data ?? [];
            await this.setStateAsync({
                singleCowBirthInfo: gs.length === 1 ? gs[0] : undefined 
            });
        }

        if (this.state.original == null) {
            this.setState({
                data: {
                    watched_at : new Date(),
                }
            })
        }
    }

    private getParamCowIds(props: this["props"]) {
        const parm = new URLSearchParams(props.location.search).get("param");
        if (parm == null) return [];

        return parm.split(",")
                    .map(p => parseInt(p))
                    .filter(p => !isNaN(p));
    }
    private getParamCowTop(props: this["props"]) {
        const parm = new URLSearchParams(props.location.search).get("cow_top");
        if (parm == null) return false;

        return parm === "1";
    }

    private async loadGrowth(id: number): Promise<boolean> {

        const url = "/growth/" + id;
        this.context.handleSetIsLoading(true);
        const opt = { retries: true, excludedErrCodes:[ A.ERR_CODE.DELETED ] };
        const res = await this.comm().send<GrowthDto>(() => this.context.postAsync(url, {}), opt);
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") return false;
        if (res.data == null) {
            this.context.showToast(A.MESSAGE.NO_DATA);
            return false; 
        }
        const data = res.data;

        if (data.ranch_id == null || data.cow_id == null || data.watched_at == null || data.watched_by == null) {
            console.error("invalid growth data", data);
            this.context.showToast("データの取得に失敗しました");
            return false;
        }
        
        if (this.navToRanchTopIfSelfEditOnly("GROWTH", data.watched_by)) return false;

        const original: IGrowth = {
            ...data,
            ranch_id: data.ranch_id,
            cow_id: data.cow_id,
            watched_at: data.watched_at,
            watched_by: data.watched_by,
        }

        await this.setStateAsync({
            original: original,
            cow_ids: [data.cow_id],
            data: {
                ...original,
                watched_at: moment(data.watched_at).toDate(),
            },
        });
        return true;
    }    

    private async loadCowInfo(cowIds: number[]): Promise<boolean> {
        if (cowIds.length === 0) return Promise.resolve(true);

        //2頭以上なら遷移元から渡された情報を利用
        if (cowIds.length >= 2) {
            const past = this.props.location.state?.cows;
            if (past != null && cowIds.every(id => past.some(p => p.cow_id === id))) {
                await this.setStateAsync({
                    cows: cowIds.map(id => past.find(p => p.cow_id === id)!),
                });
                return Promise.resolve(true);
            }
        }

        //1頭なら牛情報を取得する
        const info = await this.comm().send(() => getCowWithCache(this.props.rootStore.cur_ranch_id, cowIds[0]));
        const cow = info.data;
        if (cow == null || cow.cow_id == null) {
            console.error("invalid cow data");
            return false;
        }
        await this.setStateAsync({
            cows:[ { ...cow, use_no:cow.use_no, breed_no:cow.breed_no } ]
        });

        return true;
    }

    onGrowthUpdated(data: IGrowthSelectorData) {
        this.setState({
            data: {
                ...this.state.data,
                ...data,
            }
        });
    }

    canSave() {
        if(this.state.data.weight == null
            && this.state.data.height == null
            && this.state.data.chest == null
            && this.state.data.abdomen == null
            && this.state.data.bcs == null
            && this.state.data.rfs == null) {
            return false;
        }
        if (this.state.anyInvalid) return false;

        return true;
    }

    async onSave() {
        let registered = false;
        try {
            this.setState({ executing: true });

            const req: GrowthModifyReq = {
                ranch_id: this.props.rootStore.cur_ranch_id,
                is_new: this.state.original == null ? 1 : 0,
                event_id: this.state.original_id,
                cows: this.state.cow_ids.map(id => ({
                                                cow_id: id, 
                                                weight: this.state.data.weight, 
                                                height: this.state.data.height, 
                                                chest: this.state.data.chest, 
                                                abdomen: this.state.data.abdomen, 
                                                bcs: this.state.data.bcs, 
                                                rfs: this.state.data.rfs, 
                                            })),
                schedule_id: this.props.location.state?.schedule_id,
                watched_at: moment(this.state.data.watched_at).format("YYYY-MM-DD HH:mm:00"),
            };

            //関連する予定を探す
            if (req.schedule_id == null && this.state.cow_ids.length === 1 && req.is_new === 1) {
                const day = moment(this.state.data.watched_at).format("YYYY-MM-DD");
                const eventKinds = [ EVENT_KIND.GROWTH.no ];
                const finder = new ScheduleFinder(this.context, req.ranch_id, this.props.rootStore.user.id);
                const scheRes = await finder.findEventRelatedSchedule(day, eventKinds, this.state.cow_ids[0]);

                if (scheRes.result === "cancel") return;

                if (scheRes.result === "yes") {
                    req.schedule_id = scheRes.id;
                }
            }

            this.context.handleSetIsLoading(true)
            const res = await this.comm().send((await GrowthApi()).modifyGrowthUsingPOST(req));
            this.context.handleSetIsLoading(false)
            if (res.result !== "OK") return;

            registered = true;

        } finally {
            if (!registered) {
                this.setState({ executing: false });
            }
        }

        if (this.state.original == null) {
            if (this.state.cow_top) {
                this.props.history.replace(historyLocation.toCowInfo(this.state.cow_ids[0]));
            } else {
                window.history.go(-1);
            }
        } else {
            this.props.history.replace(historyLocation.toCowEvent(this.state.cow_ids[0], moment(this.state.data.watched_at).format("YYYY-MM-DD"), EVENT_TYPE_PARAM.SYMPTOM));
        }
    }

    private onDelete() {
        this.context.showQuestion('記録を削除してよろしいですか？', result => {
            if (result !== 0) return;

            if (!CommonUtil.assertNotNull(this.state.original_id, "orginal_id", "onDelete")) return;
            this.apiDelete(this.state.original_id);

        }, ['削除', 'キャンセル']);
    }

    async apiDelete(id: number) {
        const req: GrowthDeleteReq = {
            event_id: id,
            cow_id: this.state.cow_ids[0],
            ranch_id: this.props.rootStore.cur_ranch_id
        };

        this.context.handleSetIsLoading(true)
        const res = await this.comm().send((await GrowthApi()).deleteGrowthUsingPOST(req));
        this.context.handleSetIsLoading(false)
        if (res.result !== "OK") return;

        this.props.history.replace(
            historyLocation.toCowEvent(
                this.state.cow_ids[0], 
                this.state.original ? moment(this.state.original.watched_at).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"), 
                EVENT_TYPE_PARAM.SYMPTOM
            )
        )
    }

    render() {
        const state = this.state;
        const cows = state.cows;
        if (cows.length === 0) {
            return <></>
        }

        return (
            <div style={{ height: "100%" }}>
                <div className="product product-with-footer">
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix p-b-0">
                            <div className="product-info-header">
                                { state.cows.length === 1 && (
                                    <SingleCowHeader ranchId={this.props.rootStore.cur_ranch_id} cowId={state.cows[0].cow_id} />
                                )}
                                { state.cows.length > 1 && (
                                    <MultiCowHeader infoName="身体測定記録" cowCount={state.cows.length} cows={this.props.location.state?.cows} />
                                )}
                            </div>
                            {/* <!-- BEGIN content --> */}
                            <div className="feed-write" style={{paddingRight:"6px"}}>
                                <div className="form-group row treat-write-row">
                                    <label className="col-form-label col-md-4 col-xs-4 text-lg-right">測定日時</label>
                                    <div style={{ paddingLeft:0, flex:1 }}>
                                        <V3DateTime value={state.data.watched_at}
                                            timePresets={this.props.rootStore.user.setting?.time_preset ?? TIMEPRESETS}
                                            onChange={d => this.setState({
                                                data: {
                                                    ...state.data,
                                                    watched_at: d.toDate()
                                                }
                                            })} />
                                    </div>
                                </div>
                                <GrowthSelector 
                                    data={state.data}
                                    onChange={d => this.onGrowthUpdated(d)}
                                    onAnyInvalidChange={v => this.setState({anyInvalid: v})}
                                    dgSource={state.singleCowBirthInfo == null ? undefined : {
                                        stDate: state.singleCowBirthInfo.birthday,
                                        stScore: state.singleCowBirthInfo.actual ?? state.singleCowBirthInfo.standard,
                                        isStandardScoreUsed: state.singleCowBirthInfo.actual == null,
                                        edDate: state.data.watched_at
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    {/* <!-- BEGIN checkout-footer --> */}
                    <div className="content-footer page-footer">
                        {state.original == null && (
                            <div className="btn-row" style={{ textAlign: "center" }}>
                                <button type="button" className="btn btn-green m-r-5" onClick={this.onSave}
                                    disabled={this.state.executing || !this.canSave()}>保存</button>
                            </div>
                        )}
                        {state.original != null && (
                            <div className="btn-row">
                                <button className="btn btn-theme btn-sm btn-success m-r-5" onClick={this.onSave}
                                    disabled={this.state.executing || !this.canSave()}>
                                    保存
                                </button>
                                <button className="btn btn-theme btn-sm btn-danger m-l-5" onClick={this.onDelete}
                                    disabled={this.state.executing}>
                                    削除
                                </button>
                            </div>
                        )}
                    </div>

                </div>
            </div>
        )
    }
}

export default withRouter(withContext(GrowthWrite));