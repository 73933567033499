import React from 'react';
import { SellCowAggregateTransitionDto } from '../../api';
import { Table } from "reactstrap";
import styles from './report-sellcow.module.css';
import { ar, zeroPad } from '../../config/util';
import { COW_SELL_TYPE } from '../../config/constant';
import moment from 'moment';

interface MyProps {
    records: SellCowAggregateTransitionDto[];
    year: number;
}

export const buildHeader = (year: number) => {
    const vals = [year + "年"];
    vals.push(...ar.intRange(1, 12).map(n => n + "月"));
    vals.push("累計");
    return vals;
}

const sellTypes = [ COW_SELL_TYPE.CHILD, COW_SELL_TYPE.FAT ] as const;

export const getRowValues = (year: number, records: SellCowAggregateTransitionDto[], forCsv: boolean, today: Date) => {
    const rtn: Array<string|number>[] = [];

    const startOfNowMonth = moment(today).startOf("month");

    const diffs = ar.repeat(0, 12);

    for (const tp of sellTypes) {
        const targets = (records.find(r => r.sell_type === tp.value)?.months ?? []).map(t => ({ ...t, ymStr:moment(t.lastday).format("YYYYMM") }))
        
        const plans: Array<number> = [];
        const acts: Array<number|undefined> = [];

        for (const m of ar.intRange(1, 12)) {
            const mom = moment(`${zeroPad(year, 4)}-${zeroPad(m, 2)}-01`);
            const ymStr = mom.format("YYYYMM");
            const target = targets.find(t => t.ymStr === ymStr);
            if (target == null) {
                plans.push(0);
                acts.push(mom.isAfter(startOfNowMonth) ? undefined : 0);

            } else {
                plans.push(target.plan);
                if (target.actual === 0) {
                    acts.push(mom.isAfter(startOfNowMonth) ? undefined : 0);
                } else {
                    acts.push(target.actual);
                }
                diffs[m - 1] += (target.actual - target.plan);
            }
        }

        const sumPlan = plans.reduce((p,c) => p + c, 0);
        const sumAct = acts.map(a => a ?? 0).reduce((p,c) => p + c, 0);
        
        rtn.push([ tp.planName + "予定", ...plans, sumPlan ]);
        rtn.push([ tp.planName + "実績", ...acts.map(a => a == undefined ? "" : a), sumAct]);
    }

    const sumDiff = diffs.reduce((p,c) => p + c, 0);
    rtn.push([ "計画差異", ...(forCsv ? diffs : diffs.map(d => d > 0 ? `+${d}` : d)), (forCsv || (sumDiff <= 0)) ? sumDiff : `+${sumDiff}` ]);

    return rtn;
}

export const ReportSellCowTransitionTable = React.memo((props: MyProps) => {

    return (
        <Table className={styles.table}>
            <thead>
                <tr>
                    { buildHeader(props.year).map((h,i) => (
                        <th key={i}>{h}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                { getRowValues(props.year, props.records, false, new Date()).map((r,i) => (
                    <tr key={i}>
                        { r.map((d, ii) => (
                            <td className={ii === 0 ? styles["row-header"] : ""} key={ii}>{d}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </Table>

    );
});