import React from 'react';
import { SELECTABLE_MAX_DATE, SELECTABLE_MIN_DATE } from '../form/form-date-picker';

export interface YearSelectorProps {
    year: number;
    min?: number;
    max?: number;
    onChange: (year: number) => void;
    style?: React.CSSProperties;
}

export const YearSelector: React.FC<YearSelectorProps> = (props) => {

    const onSlide = (span: number) => {
        props.onChange(props.year + span);
    }

    const styles: { [key: string]: React.CSSProperties } = {
        container: {
            display:"flex",
            alignItems: "center"
        },
        year: {
            margin: "0 8px",
            fontWeight: "bold",
            color: "#999999",
            fontSize: "0.875rem"
        },
        slider: {
            height: "35px",
            width: "30px"
        }
    }

    const MAXYEAR = SELECTABLE_MAX_DATE.getFullYear();
    const MINYEAR = SELECTABLE_MIN_DATE.getFullYear();

    return (
        <div style={{ ...styles.container, ...props.style }} data-testid="year-selector">
            <button className="btn btn-sm btn-inverse m-r-5" onClick={() => onSlide(-1)} style={styles.slider} disabled={props.year <= (props.min ?? MINYEAR)}>
                <i className="fas fa-lg fa-caret-left" />
            </button>
            <div style={styles.year} data-testid="year-value">{props.year}年</div>
            <button className="btn btn-sm btn-inverse m-l-5" onClick={() => onSlide(1)} style={styles.slider} disabled={(props.max ?? MAXYEAR) <= props.year}>
                <i className="fas fa-lg fa-caret-right" />
            </button>
        </div>

    );
}