import { useQuery } from 'react-query';
import { QUERY, resetOrInvalidate, ResponseMetaException, postWithCache } from './fetcher';
import { CowApi } from 'api';

const fetchCow = async (ranchId: number, cowId: number) => {

    const api = await CowApi();
    const res = await api.getCowInfoUsingPOST({ ranch_id:ranchId, cow_id:cowId })();
 
    if (res.data.meta?.errCode !== 0 || res.data.data == null) {
        console.error(res.data);
        throw new ResponseMetaException(res.data.meta);
    }

    return res.data.data;
}

const fetchCowFilter = async (ranchId: number) => {
    const api = await CowApi();
    const res = await api.listFilterUsingPOST({ ranch_id: ranchId })();

    if (res.data.meta?.errCode !== 0 || res.data.data == null) {
        console.error(res.data);
        throw new ResponseMetaException(res.data.meta);
    }

    return res.data.data;
}

export const getCowWithCache = async (ranch_id: number, cow_id: number) => {
    return postWithCache([ QUERY.COW, cow_id ], () => fetchCow(ranch_id, cow_id));
}

export const useCow = (ranchId: number, cowId: number) => {

    return useQuery([QUERY.COW, cowId],
        () => fetchCow(ranchId, cowId),
        { cacheTime: 5 * 60 * 1000, staleTime: 1 * 60 * 1000 });
}
export const useCowFilter = (ranchId: number) => {
    return useQuery([QUERY.COW_FILTER, ranchId],
        () => fetchCowFilter(ranchId),
        { cacheTime: 5 * 60 * 1000, staleTime: 1 * 60 * 1000 });
}

export const resetCow = (cowId: number, immediate = true) => resetOrInvalidate([ QUERY.COW, cowId ], immediate);
export const resetCowFilter = (ranchId: number, immediate = true) => resetOrInvalidate([ QUERY.COW_FILTER, ranchId ], immediate);