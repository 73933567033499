import React from 'react';
import styles from './menu.module.css';

export interface MenuGroupProps {
    groupName: string;
    menus: Readonly<{ name: string, id: string }>[];
    onSelect: (id: string) => void;
}

export const MenuGroup: React.FC<MenuGroupProps> = React.memo( (props) => {

    return (<>
        { props.menus.length > 0 && (
            <nav className={styles["menu-group"]}>
                <div className={styles["menu-group-label"]}>{props.groupName}</div>
                <ul>
                    { props.menus.map((m,i) => (
                        <li key={i} className="clickable" onClick={() => props.onSelect(m.id)}><span>{m.name}</span></li>
                    ))}
                </ul>
            </nav>
        )}
    </>)

});