export interface ProcessResultCowIdAndTime {
    cow_id: number;
    time: string;
}
export interface ProcessResultCountWithDiscard {
    count_total: number;
    count_discard?: number;
}

export class ProcessResultDetail {

    static isCowIdAndTime(detail: any): detail is ProcessResultCowIdAndTime {
        if (detail == null) return false;
        return ("cow_id" in detail) && ("time" in detail);
    }

    static isCountWithDiscard(detail: any): detail is ProcessResultCountWithDiscard {
        return ("count_total" in detail);
    }

}