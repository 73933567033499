import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment'

import { CowListDto, SymptomBulkModifyReq, SymptomApi, ModifyReqTreat, ModifyReqVisit } from '../../api'

import { useRootStore } from '../../stores';
import { usePageStore } from '../../config/page-settings';

import ConditionSelect from '../feedbulk/ConditionSelect'
import RegisterTreat from './RegisterTreat'
import Completed from '../feedbulk/Completed'

import styles from './treatbulk.module.css'
import { hasRanchAuth } from '../../config/auth-checker';
import { Communicator } from '../../api/communicator';
import { PageType, A } from '../../config/constant';
import { EditingPhisical } from '../symptom/symptom-physical-input';
import { NextSchedule, toNextScheduleReq } from '../../components/next-schedule/next-schedule-selector';
import { Attribute } from '../feedbulk';
import { UserTeams } from '../../config/user-teams';
import { calcRouteFeeOf } from '../../config/medicine-route-fee-calculator';
import { calcTreatUnitPrice } from '../../config/treat-unit-price-calculator';
import { ar, CommonUtil } from '../../config/util';
import { CowToDispInfo } from '../../components/parts/cows-popup';
import { AppState } from '../../app';
import { PreloadedProgramSelectPopupProps, showPreloadedProgramSelectDialog, PreloadedProgramSelectPopup } from '../program/preloaded-program-select-popup';
import { useRanchHouses, useMyMedicineRoutes, resetMedicines, resetTreatItems } from '../../stores/fetcher';
import { FetchWaiter, FetchError } from '../../components/content/fetch-state';

export interface RouteParams {
  ranchId: string
}

type FlowPhase = 'ConditionSelect' | 'RegisterTreat' | 'Completed'
export type House = {
  id : string,
  levels: Array<number>,
}

export type Treat = Omit<ModifyReqTreat, "route_fee"|"ignores_washout"> & { name: string, unit?:string, route_name?:string };

export const isSameTreat = (a: Treat, b: Treat) => {
  if (a.treat_kind_no == null) {
    if (b.treat_kind_no != null) return false;
    if (a.team_id !== b.team_id) return false;

    if (a.medicine_id == null) {
      if (b.medicine_id != null) return false;
      
      return a.new_medicine_category === b.new_medicine_category
          && a.new_medicine_name === b.new_medicine_name;
    } else {
      return a.medicine_id === b.medicine_id;
    }

  } else {
    if (b.treat_kind_no == null) return false;
    //team_idは同じになるはずなので確認しない
    if (a.treat_kind_no !== b.treat_kind_no) return false;

    if (a.treat_item_no == null) {
      if (b.treat_item_no != null) return false;
      return a.new_treat_item_name === b.new_treat_item_name;
    } else {
      return a.treat_item_no === b.treat_item_no;
    }
  }
}

export type BulkTreatInput = {
  treats: Array<Treat>;
  physical?: EditingPhisical;
  memo: string;
  first_id?: number;
  status?: number;
  schedules: NextSchedule[];
}
export type Cow = {
  id: number,
  name: string,
  house: Array<number>,//[number,number,number],
  birthday: number,
  repId: string,
  earTag: boolean,
  selected : boolean,
  attrs : Attribute,
  input: BulkTreatInput;
}
export const blankInput = (): BulkTreatInput => {
  return {
    treats: [],
    memo: "",
    schedules: []
  }
}
export const anyInput = (input: BulkTreatInput, exceptTreat = false) => {
  if (!exceptTreat && input.treats.length > 0) return true;

  return input.memo !== ""
    || input.physical != null
    || input.first_id != null
    || input.schedules.length > 0
    || input.status != null;
}

export default () => {
  const rootStore = useRootStore()
  const { user, auth, logout } = rootStore

  if ( !auth.enabled ) {
    return (<></>)
  }

  const { handleSetHeader, handleSetPageError, handleSetFooter, handleSetIsLoading, showToast, showDialog } = usePageStore() as AppState;

  const { ranchId } = useParams<RouteParams>()
  const ranch = new UserTeams(user).findRanch(parseInt(ranchId));
  if (ranch === undefined) return (<></>)

  const ranchHouses = useRanchHouses(ranch.team_id, false);
  const medicineRoutes = useMyMedicineRoutes(ranch.team_id, rootStore.getClinicIdForMaster());

  const [ready, setReady] = useState(false);
  const [date, setDate] = useState<Date>(new Date());
  const [ precheckedCowIds, setPrecheckedCowIds ] = useState(new Set<number>());
  const [ precheckedCows, setPrecheckedCows ] = useState<CowListDto[]>([]);
  const [cows, setCows] = useState<Array<Cow>>([]);
  const [registerCount, setRegisterCount] = useState(0);
  const [ executing, setExecuting ] = useState(false);
  const [ clinicId, setClinicId ] = useState<number>();
  const [ visit, setVisit ] = useState<ModifyReqVisit>();

  const [phase, setPhase] = useState<FlowPhase>('ConditionSelect');
  const [preloadedProgram, setPreloadedProgram] = useState<PreloadedProgramSelectPopupProps>();

  const moveToConditionSelect = useCallback(() => { setPhase('ConditionSelect') }, [])
  
  const moveToRegisterTreat = () => {
    rootStore.setSelectedCowIds([...precheckedCowIds]);
    setPhase('RegisterTreat');
  };

  useEffect(() => {
    setPrecheckedCowIds(new Set(rootStore.getSelectedCowIds()));

  }, [])

  useEffect(() => {
    (async () => {
      if (!hasRanchAuth("TREAT_EDIT", ranch.team_id, user)) {
        rootStore.logout()
        return
      }

      handleSetHeader({ title:"一括治療登録", backBtn:false });
      handleSetPageError(false)
      handleSetFooter(true)

      await rootStore.fetchActiveCows(ranch.team_id)
      const clinicId = rootStore.getClinicIdForMaster();
      if (clinicId != null) {
        setClinicId(clinicId);
      }
      setReady(true)
    })()
  }, [ranchId, user])

  useEffect(() => {
    const preCows = (rootStore.getActiveCows(ranch.team_id) ?? [])
      .filter(c => precheckedCowIds.has(c.cow_id));
    setPrecheckedCows(preCows);

  }, [ precheckedCowIds ])

  const comm = new Communicator({ logout, showToast, showDialog });

  const register = async () => {
    if (!CommonUtil.assertNotNull(medicineRoutes.data, "medicineRoutes", "register")) return;

    const targetCows = cows.filter(cow => cow.selected && anyInput(cow.input));

    const routes = medicineRoutes.data;

    const req: SymptomBulkModifyReq = {
      ranch_id: ranch.team_id,
      watched_at: moment(date).format("YYYY-MM-DD HH:mm"),
      visit,
      preloaded_program: { list:[], tags:[] },
      cows: targetCows.map(c => {
        // route_id,amount are required by calcRouteFee
        // medicine_id is required by calcTreatUnitPrice
        const treats = c.input.treats.map(t => ({ ...t, route_id:t.route_id, amount:t.amount, medicine_id:t.medicine_id }));

        return {
          cow_id: c.id,
          symptom_name: c.input.physical?.symptom_name ?? "",
          temperature_x10: c.input.physical?.temperature_x10,
          active_score: c.input.physical?.active_score,
          breath_count: c.input.physical?.breath_count,
          breath_score: c.input.physical?.breath_count,
          heart_rate: c.input.physical?.heart_rate,
          hungry_score: c.input.physical?.hungry_score,
          notice: c.input.physical?.notice ?? "",
          diseases: c.input.physical?.diseases ?? [],
          conditions: c.input.physical?.conditions ?? [],
          feces_color: c.input.physical?.feces_color,
          feces_state: c.input.physical?.feces_state,
          comment: c.input.memo,
          first_id: c.input.first_id,
          treats: treats.map((t,i) => {
            const route = t.route_id == null ? undefined : routes.find(r => r.route_id === t.route_id);
            return {
              ...t,
              unit_price: calcTreatUnitPrice(t, route),
              route_fee: route == null ? 0 : calcRouteFeeOf(route, treats, i),
              ignores_washout: route?.is_instruction === 1 ? 1 : 0,
              name: undefined,
              route_name: undefined,
              unit: undefined
            }
          }),
          status: c.input.status,
          plans: c.input.schedules.map(s => toNextScheduleReq(s, clinicId))
        }
      })
    }

    //validate
    if (req.visit != null) {
      const reqCowIds = new Set(targetCows.map(t => t.id));
      const feeCowIds = [...new Set(ar.flat(req.visit.fees.map(f => f.cow_ids)))];
      const invalidCowIds = feeCowIds.filter(f => !reqCowIds.has(f));
      if (invalidCowIds.length > 0) {
        const cows = ar.notNull(invalidCowIds.map(id => precheckedCows.find(c => c.cow_id === id)))
                      .map(cw => CowToDispInfo(cw, true));
        showDialog("WARNING", A.MESSAGE.VISIT_FEE_WITHOUT_RECORD, undefined, { subTexts: cows });
        return;
      }
    }

    //プログラム確認
    const pg = await showPreloadedProgramSelectDialog(
      { handleSetIsLoading, showToast },
      async () => comm.send((await SymptomApi()).getProgramsForBulkTreatUsingPOST({ eventReq:req, clinic_id: clinicId })),
      rootStore,
      req.ranch_id,
      clinicId,
      setPreloadedProgram,
      precheckedCows
    );
    if (pg == null) return;
    req.preloaded_program = pg;

    setExecuting(true)
    handleSetIsLoading(true)
    const res = await comm.send((await SymptomApi()).bulkModifyUsingPOST(req));
    handleSetIsLoading(false)

    if (res.result !== "OK") {
      setExecuting(false);
      return;
    }

    rootStore.fetchActiveCows(undefined, "DIFF")
    resetMedicines(clinicId ?? ranch.team_id, false);
    if (req.cows.some(cw => cw.treats.some(t => t.new_treat_item_name != null))) {
      resetTreatItems(clinicId ?? ranch.team_id, false);
    }

    setRegisterCount(targetCows.length)
    setPhase('Completed')
    setExecuting(false)
  }

  if (ranchHouses.isLoading || medicineRoutes.isLoading) return <FetchWaiter />
  if (ranchHouses.isError || ranchHouses.data == null) return <FetchError />
  if (medicineRoutes.isError || medicineRoutes.data == null) return <FetchError />

  const view = phase === 'ConditionSelect' ? 0 : phase === 'RegisterTreat' ? 1 : 2

  return (
    <main data-testid="メイン" className={ styles.page }>
      <section data-testid="表示エリア" className={ styles["content-area"] } style={{width: `${100 * 3}%`, left: `-${100 * view}%` }}>
        <div className={ styles["content-phase"] }>
          {phase === 'ConditionSelect' &&
            <ConditionSelect
              dateName="診療"
              ready={ ready }
              ranchId={ parseInt(ranchId) }
              date={ date }
              setDate={ setDate }
              checkedCowIds={ precheckedCowIds }
              setCheckedCowIds={ setPrecheckedCowIds }
            />
          }
        </div>
        <div className={ styles["content-phase"] }>
        {phase === 'RegisterTreat' &&
          <RegisterTreat
              ranchId={ parseInt(ranchId) }
              clinicId={ clinicId }
              date={ date }
              allCows={ precheckedCows }
              cows={ cows }
              setCows={ setCows }
              visit={ visit }
              setVisit={ setVisit }
              ranchHouses={ ranchHouses.data }
            />
          }
        </div>
        <div className={ styles["content-phase"] }>
        {phase === 'Completed' &&
          <Completed count={registerCount} eventName="診療" />
        }
        </div>
      </section>
      <section className={ styles["fix-bottom"] }>
      {
        {
          'ConditionSelect': <ConditionMenu canNext={ precheckedCowIds.size > 0 } moveNext={ moveToRegisterTreat } count={ precheckedCowIds.size } button="処置を選ぶ" nextPage="content" />,
          'RegisterTreat'  : <RegisterMenu canNext={ !executing && cows.some(cow => cow.selected && anyInput(cow.input)) } movePrev={ moveToConditionSelect } moveNext={ register } />,
          'Completed'      : <CompletedMenu ranchId={ ranchId } />,
        } [phase]         || <></>
      }
      </section>
      { preloadedProgram != null && (
        <PreloadedProgramSelectPopup
          {...preloadedProgram}
        />
      )}
    </main>
  );
}

export const ConditionMenu: React.FC<{
  canNext  : boolean,
  moveNext : () => void,
  count    : number,
  button   : string,
  nextPage : PageType
}> = ({ canNext, moveNext, count, button, nextPage }) => (
  <div className={ styles.footmenu }>
    <button style={{visibility: 'hidden'}} />
    <div>
      { count > 0 && <span className={ styles.cowcount }>対象牛合計数 : <b data-testid="牛合計数">{ count }</b> 頭 </span> }
      <button className={ styles.bluebutton } onClick={ moveNext } disabled={ !canNext } data-testid="治療登録へ">
        { button } { <i className={nextPage === "content" ? "fas fa-caret-right" : "fas fa-external-link-alt"}></i> }
      </button>
    </div>
  </div>
)

const RegisterMenu: React.FC<{
  canNext  : boolean,
  moveNext : () => void,
  movePrev : () => void,
}> = ({ canNext, moveNext, movePrev }) => (
  <div className={ styles.footmenu }>
    <button className={ styles.bluebutton } onClick={ movePrev }><i className="fas fa-caret-left"></i> 戻る</button>
    <button className={ styles.bluebutton } onClick={ moveNext } disabled={ !canNext } data-testid="登録">登録する</button>
  </div>
)

const CompletedMenu: React.FC<{
  ranchId: string,
}> = ({ ranchId }) => {
  const history = useHistory()

  return (
    <div className={ styles.footmenu }>
      <button className={ styles.bluebutton } onClick={ () => history.push(`/top/${ ranchId }`) }>牧場Topに戻る</button>
    </div>
  )
}
