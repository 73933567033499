import React from 'react';
import { PartnerDto, PartnerModifyReq, PartnerListReq, TeamApi, ComTeamAndIdReq, PartnerSortReq } from '../../api';
import { Communicator } from '../../api/communicator';
import { LMT } from '../../config/constant';
import { SettingNameItemPopup } from '../setting/setting-name-item-popup';

interface MyProps {
    ranchId: number;
    comm: Communicator;
    partners: Readonly<PartnerDto[]>;
    onUpdated: (partners: PartnerDto[]) => void;
    onClose: () => void;
    partnerType: "client"|"supplier";
    partnerTypeName: string;
    confirm: (text: string, buttons:string[]) => Promise<number|undefined>;
}

export const SettingPartnerPopup: React.FC<MyProps> = (props) => {

    const reloadList = async () => {
        const req: PartnerListReq = {
            team_id: props.ranchId,
            is_client: props.partnerType === "client" ? 1 : 0,
            is_supplier: props.partnerType === "supplier" ? 1 : 0
        };
        const res = await props.comm.send((await TeamApi()).listPartnersUsingPOST(req), { retries: true });
        props.onUpdated(res.data ?? []);
    }

    const submit = async (no: number | undefined, name: string) => {

        const target = props.partners.find(p => p.partner_no === no);
        const req: PartnerModifyReq = {
            partner_no: no,
            name: name,
            is_client: target?.is_client ?? (props.partnerType === "client" ? 1 : 0),
            is_supplier: target?.is_supplier ?? (props.partnerType === "supplier" ? 1 : 0),
            team_id: props.ranchId
        };

        const res = await props.comm.send((await TeamApi()).modifyPartnerUsingPOST(req));
        return res.result === "OK";
    }

    const submitSort = async (order: number[]) => {
        const req: PartnerSortReq = {
            team_id: props.ranchId,
            order,
            is_client: props.partnerType === "client" ? 1 : 0,
            is_supplier: props.partnerType === "supplier" ? 1 : 0
        };

        const res = await props.comm.send((await TeamApi()).sortPartnersUsingPOST(req));
        return res.result === "OK";
    }

    const deleteItem = async (no: number) => {

        const req: ComTeamAndIdReq = {
            team_id: props.ranchId,
            id: no
        };
        const res = await props.comm.send((await TeamApi()).deletePartnerUsingPOST(req));
        return res.result === "OK";
    }

    return (
        <SettingNameItemPopup
            confirm={props.confirm}
            delete={deleteItem}
            submit={submit}
            submitSort={submitSort}
            reloadList={reloadList}
            itemType={props.partnerTypeName}
            items={props.partners}
            mapName={p => p.name}
            mapNo={p => p.partner_no}
            onClose={props.onClose}
            maxNameLength={LMT.PARTNER.NAME_LEN}
        />
    )
}