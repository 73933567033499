import { useQuery } from 'react-query';
import moment from 'moment';
import { QUERY, resetOrInvalidate } from './fetcher';
import { SellMilkGraphReq, SellMilkApi } from 'api';

const fetchSellMilkGraph = async (ranchId: number, cowId: number, endDay:Date, daySpan:number, seasonEndDay:Date | undefined) => {
    const endDayStr = moment(endDay).format("YYYY-MM-DD");
    const seasonEndDayStr = seasonEndDay == null ? undefined : moment(seasonEndDay).format("YYYY-MM-DD");

    const req: SellMilkGraphReq = {
        ranch_id: ranchId,
        cow_id: cowId,
        end_day: endDayStr,
        season_end_day: seasonEndDayStr,
        day_span: daySpan
    };

    const api = await SellMilkApi();
    const res = await api.getSellMilkGraphUsingPOST(req)();
    
    if (res.data.meta?.errCode !== 0 || res.data.data == null) {
        console.error(res.data);
        throw new Error(res.data.meta?.errMsg);
    }

    return res.data.data;
}

export const useSellMilkGraph = (ranchId: number, cowId: number, endDay:Date, daySpan:number, seasonEndDay:Date | undefined) => {
    const endDayStr = moment(endDay).format("YYYY-MM-DD");
    const seasonEndDayStr = seasonEndDay == null ? "" : moment(seasonEndDay).format("YYYY-MM-DD");

    return useQuery([QUERY.SELL_MILK_GRAPH, cowId, endDayStr, daySpan, seasonEndDayStr],
                    () => fetchSellMilkGraph(ranchId, cowId, endDay, daySpan, seasonEndDay),
                    { cacheTime: 5 * 60 * 1000, staleTime: 1 * 60 * 1000 });
}

export const resetSellMlikGraph = (cowId: number, immediate = true) => resetOrInvalidate([QUERY.SELL_MILK_GRAPH, cowId], immediate);