import React, { useEffect } from 'react';
import { ProgramTriggerKey } from '../../config/program-kind';
import { ProgramTriggerCondition, CowTriggerCondition, DeliveryTriggerCondition, CrossTriggerCondition, SymptomTriggerCondition, RutTriggerCondition, BreedingTriggerCondition } from '../../config/program-trigger-condition';
import styles from './program-management.module.css';
import { FreezedArray, ar } from '../../config/util';
import { IMedicine, ITreatItem, ICowBreed, ICowUse } from '../../stores';
import { Checkbox } from '../../components/form/form-checkbox';
import { FormRadio } from '../../components/form/form-radio';
import { OptionalInputContainer } from '../../components/parts/optional-input-container';
import { A } from '../../config/constant';
import { CommonSelect } from '../../components/parts/common-select';

interface MyProps {
    triggerEvent: ProgramTriggerKey | undefined;
    condition: ProgramTriggerCondition | undefined;
    onChange: (conditon: ProgramTriggerCondition | undefined) => void;
    medicines: FreezedArray<IMedicine>;
    treatItems: FreezedArray<ITreatItem>;
    cowBreeds: FreezedArray<ICowBreed>;
    cowUses: FreezedArray<ICowUse>;
    className?: string;
}

export const ProgramTriggerConditionSelector = React.memo((props: MyProps) => {
    useEffect(() => {
        const cond = props.condition;
        if (cond == null) return;
        //リストにない処置が選択されているときはクリアする
        if ("treat_kind_no" in cond && "treat_item_no" in cond && cond.treat_kind_no != null && cond.treat_item_no != null) {
            if (!props.treatItems.some(t => t.treat_kind_no === cond.treat_kind_no && t.treat_item_no === cond.treat_item_no)) {
                props.onChange(undefined);
                return;
            }
        }
        if ("medicine_id" in cond && cond.medicine_id != null) {
            if (!props.medicines.some(m => m.medicine_id === cond.medicine_id)) {
                props.onChange(undefined);
                return;
            }
        }

    }, [ props.condition ])

    if (props.triggerEvent === "COW") {
        const cond = (props.condition ?? {}) as CowTriggerCondition;

        const startKinds = ar.orderBy([...A.START_KIND.values()], s => s.no).map(s => ({ name:s.name, value:s.no }));

        return (
            <div className={props.className}>
                <div className={styles["condition-item"]}>
                    <div className={styles.header}>
                        <Checkbox label="導入元" id="chkStartKind"
                            checked={cond.start_kind != null}
                            onChange={e => props.onChange({ ...cond, start_kind: e.target.checked ? startKinds[0].value : undefined })}
                        />
                    </div>
                    <OptionalInputContainer isSelected={cond.start_kind != null} className={styles.content}>
                        <FormRadio prefix="radStartKind" isInline={false}
                            options={startKinds} onChange={n => props.onChange({ ...cond, start_kind:n })} value={cond.start_kind ?? undefined} />
                    </OptionalInputContainer>
                </div>
                { props.cowBreeds.length > 0 && (
                    <div className={styles["condition-item"]}>
                        <div className={styles.header}>
                            <Checkbox label="品種" id="chkBreed"
                                checked={cond.breed_no != null}
                                onChange={e => props.onChange({ ...cond, breed_no: e.target.checked ? props.cowBreeds[0].breed_no : undefined })}
                            />
                        </div>
                        <OptionalInputContainer isSelected={cond.breed_no != null} className={styles.content}>
                            <FormRadio prefix="radBreed" isInline={false}
                                options={props.cowBreeds.map(b => ({ name:b.name, value:b.breed_no}))}
                                onChange={n => props.onChange({ ...cond, breed_no:n })} value={cond.breed_no ?? undefined} />
                        </OptionalInputContainer>
                    </div>
                )}
                { props.cowUses.length > 0 && (
                    <div className={styles["condition-item"]}>
                        <div className={styles.header}>
                            <Checkbox label="用途" id="chkUse"
                                checked={cond.use_no != null}
                                onChange={e => props.onChange({ ...cond, use_no: e.target.checked ? props.cowUses[0].use_no : undefined })}
                            />
                        </div>
                        <OptionalInputContainer isSelected={cond.use_no != null} className={styles.content}>
                            <FormRadio prefix="radUse" isInline={false}
                                options={props.cowUses.map(b => ({ name:b.name, value:b.use_no }))}
                                onChange={n => props.onChange({ ...cond, use_no:n })} value={cond.use_no ?? undefined} />
                        </OptionalInputContainer>
                    </div>
                )}
            </div>
        )
    }
    if (props.triggerEvent === "PREVENTION" || props.triggerEvent === "SYMPTOM") {
        const cond = (props.condition ?? {}) as SymptomTriggerCondition;

        return (
            <div className={props.className}>
                <TreatConditionSelector
                    medicines={props.medicines}
                    treatItems={props.treatItems}
                    medicine_id={cond.medicine_id}
                    treat_kind_no={cond.treat_kind_no}
                    treat_item_no={cond.treat_item_no}
                    onChange={vals => props.onChange({ ...cond, ...vals })}
                />
            </div>
        )
    }
    if (props.triggerEvent === "BREEDING") {
        const cond = (props.condition ?? {}) as BreedingTriggerCondition;
        const types = ar.orderBy([...A.CROSS_TYPE.values()], t => t.no).map(t => ({ name:t.name, value:t.no }));

        return (
            <div className={props.className}>
                <div className={styles["condition-item"]}>
                    <div className={styles["content-only"]}>
                        <Checkbox label="妊娠鑑定 + の場合のみ" id="chkIsPreg"
                            checked={cond.is_preg != null}
                            onChange={e => props.onChange({ ...cond, is_preg: e.target.checked ? true : undefined })}
                        />
                    </div>
                </div>
                { cond.is_preg != null && (
                    <div className={styles["condition-item"]}>
                        <div className={styles.header}>
                            <Checkbox label="直前の交配" id="chkPregCross"
                                checked={cond.preg_cross_type != null}
                                onChange={e => props.onChange({ ...cond, preg_cross_type: e.target.checked ? types[0].value : undefined })}
                            />
                        </div>
                        <OptionalInputContainer isSelected={cond.preg_cross_type != null} className={styles.content}>
                            <FormRadio prefix="radPregCross" isInline={false}
                                options={types}
                                onChange={n => props.onChange({ ...cond, preg_cross_type:n })} value={cond.preg_cross_type ?? undefined} />
                        </OptionalInputContainer>
                    </div>
                )}
                <TreatConditionSelector
                    medicines={props.medicines}
                    treatItems={props.treatItems}
                    medicine_id={cond.medicine_id}
                    treat_kind_no={cond.treat_kind_no}
                    treat_item_no={cond.treat_item_no}
                    onChange={vals => props.onChange({ ...cond, ...vals })}
                />
            </div>
        )
    }
    if (props.triggerEvent === "RUT") {
        const cond = (props.condition ?? {}) as RutTriggerCondition;

        return (
            <div className={props.className}>
                <div className={styles["condition-item"]}>
                    <div className={styles["content-only"]}>
                        <Checkbox label="観察項目ありの場合のみ" id="chkHasSign"
                            checked={cond.has_sign != null}
                            onChange={e => props.onChange({ ...cond, has_sign: e.target.checked ? true : undefined })}
                        />
                    </div>
                </div>
                <TreatConditionSelector
                    medicines={props.medicines}
                    treatItems={[]}
                    medicine_id={cond.medicine_id}
                    treat_kind_no={undefined}
                    treat_item_no={undefined}
                    onChange={vals => props.onChange({ ...cond, medicine_id:vals.medicine_id })}
                />
            </div>
        )
    }
    if (props.triggerEvent === "CROSS") {
        const cond = (props.condition ?? {}) as CrossTriggerCondition;
        const types = ar.orderBy([...A.CROSS_TYPE.values()], t => t.no).map(t => ({ name:t.name, value:t.no }));

        return (
            <div className={props.className}>
                <div className={styles["condition-item"]}>
                    <div className={styles.header}>
                        <Checkbox label="交配手段" id="chkCross"
                            checked={cond.cross_type != null}
                            onChange={e => props.onChange({ ...cond, cross_type: e.target.checked ? types[0].value : undefined })}
                        />
                    </div>
                    <OptionalInputContainer isSelected={cond.cross_type != null} className={styles.content}>
                        <FormRadio prefix="radCross" isInline={false}
                            options={types} onChange={n => props.onChange({ ...cond, cross_type:n })} value={cond.cross_type ?? undefined} />
                    </OptionalInputContainer>
                </div>
            </div>
        )
    }
    if (props.triggerEvent === "DELIVERY") {
        const cond = (props.condition ?? {}) as DeliveryTriggerCondition;
        const options = [{ value:1, name:"流産" },{ value:0, name:"流産でない"}];

        return (
            <div className={props.className}>
                <div className={styles["condition-item"]}>
                    <div className={styles.header}>
                        <Checkbox label="区分" id="chkAbort"
                            checked={cond.is_aborted != null}
                            onChange={e => props.onChange({ ...cond, is_aborted: e.target.checked ? false : undefined })}
                        />
                    </div>
                    <OptionalInputContainer isSelected={cond.is_aborted != null} className={styles.content}>
                        <FormRadio prefix="radAbort" isInline={false}
                            options={options} onChange={n => props.onChange({ ...cond, is_aborted:n === 1 })} value={cond.is_aborted == null ? undefined : cond.is_aborted ? 1 : 0} />
                    </OptionalInputContainer>
                </div>
            </div>
        )
    }

    return <></>
});


const TreatConditionSelector = (props: {
    medicines: FreezedArray<IMedicine>;
    treatItems: FreezedArray<ITreatItem>;
    medicine_id?: number | null,
    treat_kind_no?: number | null,
    treat_item_no?: number | null,
    onChange: (data:{ medicine_id?:number | null, treat_kind_no?:number | null, treat_item_no?:number | null }) => void;
    
}) => {
    //※未加入牧場の場合は牧場マスタが0件なので表示されない
    return (<>
        { props.medicines.length > 0 && (
            <div className={styles["condition-item"]}>
                <div className={styles.header}>
                    <Checkbox label="投薬" id="chkMedicine"
                        checked={props.medicine_id != null}
                        onChange={e => props.onChange({
                            treat_kind_no: props.treat_kind_no,
                            treat_item_no: props.treat_item_no,
                            medicine_id: e.target.checked ? props.medicines[0].medicine_id : undefined,
                        })}
                    />
                </div>
                <OptionalInputContainer isSelected={props.medicine_id != null} className={styles.content}>
                    <CommonSelect className={styles.treat}
                        itemName="薬品"
                        value={props.medicine_id ?? undefined}
                        options={props.medicines.map(m => ({ label: m.name, value: m.medicine_id }))}
                        onCreate={undefined}
                        onSelect={val => props.onChange({
                            treat_kind_no: props.treat_kind_no,
                            treat_item_no: props.treat_item_no,
                            medicine_id:val
                        })}
                    />
                </OptionalInputContainer>
            </div>
        )}
        { props.treatItems.length > 0 && (
            <div className={styles["condition-item"]}>
                <div className={styles.header}>
                    <Checkbox label="処置" id="chkTreat"
                        checked={props.treat_item_no != null}
                        onChange={e => props.onChange({
                            medicine_id: props.medicine_id,
                            treat_kind_no: e.target.checked ? props.treatItems[0].treat_kind_no : undefined,
                            treat_item_no: e.target.checked ? props.treatItems[0].treat_item_no : undefined
                        })}
                    />
                </div>
                <OptionalInputContainer isSelected={props.treat_item_no != null} className={styles.content}>
                    <CommonSelect className={styles.treat}
                        itemName="処置"
                        value={props.treat_kind_no == null || props.treat_item_no == null ? undefined : props.treat_kind_no * 100000 + props.treat_item_no}
                        options={props.treatItems.map(t => ({ label: t.name, value: t.treat_kind_no * 100000 + t.treat_item_no}))}
                        onCreate={undefined}
                        onSelect={val => props.onChange({
                            medicine_id: props.medicine_id,
                            treat_kind_no: val == null ? undefined : Math.floor(val / 100000),
                            treat_item_no: val == null ? undefined : val % 100000
                        })}
                    />
                </OptionalInputContainer>
            </div>
        )}
    </>)

}