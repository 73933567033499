import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { EditingVisitFee } from './visit-fee-master';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { VisitFeeType, VISIT_FEE_TYPES, VISIT_FEE_TYPE, LMT } from '../../config/constant';
import { ModalInputBlock } from '../../components/parts/modal-input-block';
import { RequiredNumInput, OptionalNumInput } from '../../components/parts/num-input';
import styles from './setting.module.css';
import { ClinicVisitFeeModifyReqStep } from '../../api';
import { generateKey, ar } from '../../config/util';
import { IconLink } from '../../components/parts/icon-link';
import { IconRight } from '../../components/parts/icon-right';

interface MyProps {
    onClose: () => void;
    onSubmit: (data: EditingVisitFee) => void;
    onDelete: () => void;
    isSubmitExecuting: boolean;
    original: EditingVisitFee;
}

type KeyedStep = ClinicVisitFeeModifyReqStep & { key: string };
type EditingVIsitFeeWithKeyedStep = Omit<EditingVisitFee, "steps"> & {
    steps?: Array<KeyedStep>;
}

export const VisitFeeEditPopup = (props: MyProps) => {
    const [ data, setData ] = useState<EditingVIsitFeeWithKeyedStep>({ fee:0, name:"", fee_type:1 });
    const [ feeType, setFeeType ] = useState<VisitFeeType>("FIRST");

    useEffect(() => {
        setData({
            ...props.original,
            steps: props.original.steps?.map(s => ({ ...s, key: generateKey("step")}))
        });

        setFeeType(VISIT_FEE_TYPES.find(t => VISIT_FEE_TYPE[t].no === props.original.fee_type) ?? "FIRST");

    }, [ props.original ]);

    const onAddStep = () => {
        const preSteps = data.steps ?? [];
        const maxStepTo = ar.notNull(preSteps.map(s => s.distance_to)).reduce((pre,next) => pre < next ? next : pre, 0);
        setData({
            ...data,
            steps: [
                ...preSteps,
                { distance_from: maxStepTo, fee: 0, key: generateKey("step") }
            ]
        });
    }

    const typeName = VISIT_FEE_TYPE[feeType].name;
    const hasStep = VISIT_FEE_TYPE[feeType].hasStep;
    const hasPoint = VISIT_FEE_TYPE[feeType].hasPoint;

    return (
        <div>
            <Modal isOpen={true} centered={true}  scrollable={true}>
                <ModalHeader toggle={props.onClose}></ModalHeader>
                <ModalBody style={{ minHeight: "calc(100vh - 280px)"}}>
                    <ModalInputBlock label="名称" labelSize="s">
                        <input type="text" className="form-control" maxLength={LMT.VISIT_FEE.NAME_LEN}
                            value={data.name} onChange={e => setData({ ...data, name:e.target.value })}
                        />
                    </ModalInputBlock>
                    <ModalInputBlock label={typeName} labelSize="s" contentClassName="row-no-margin align-items-center">
                        <RequiredNumInput
                            style={{ width: "80px" }}
                            min={LMT.VISIT_FEE.FEE_MIN} max={LMT.VISIT_FEE.FEE_MAX} step={LMT.VISIT_FEE.FEE_STEP}
                            value={data.fee}
                            onChange={fee => setData({ ...data, fee })}
                        />
                        <span className={styles["popup-unit"]}>円</span>
                        { hasPoint && (<>
                            <span className={styles["popup-prefix"]}>：点数</span>
                            <OptionalNumInput placeholder="A"
                                style={{ width:"60px" }}
                                min={LMT.VISIT_FEE.POINT_MIN} max={LMT.VISIT_FEE.POINT_MAX} step={LMT.VISIT_FEE.POINT_STEP}
                                value={data.point_a}
                                onChange={point_a => setData({ ...data, point_a })}
                            />
                            <OptionalNumInput placeholder="B"
                                style={{ width:"60px" }}
                                min={LMT.VISIT_FEE.POINT_MIN} max={LMT.VISIT_FEE.POINT_MAX} step={LMT.VISIT_FEE.POINT_STEP}
                                value={data.point_b}
                                onChange={point_b => setData({ ...data, point_b })}
                            />
                        </>)}
                    </ModalInputBlock>

                    { hasStep && (
                        <ModalInputBlock label="" labelSize="s">
                            { (data.steps ?? []).map((step,si) => (
                                <StepInput key={step.key} data={step} hasPoint={hasPoint === true}
                                    onChange={s => setData({ ...data, steps: data.steps?.map((preSt,psi) => si === psi ? s : preSt) })}
                                    onRemove={() => setData({ ...data, steps: data.steps?.filter((_,psi) => psi !== si)})}
                                />
                            ))}
                            { (data.steps?.length ?? 0) < LMT.VISIT_FEE.EXTRA_STEP_COUNT_MAX && (
                                <div className="m-t-15">
                                    <IconLink text="増加料金を追加" iconType="add" onClick={onAddStep} />
                                </div>
                            )}
                        </ModalInputBlock>
                    )}

                    { hasPoint && (
                        <ModalInputBlock labelSize="s" label="コード">
                            <input type="text" className="form-control"
                                maxLength={LMT.VISIT_FEE.CODE_LEN}
                                value={data.code ?? ""}
                                onChange={e => setData({ ...data, code:e.target.value })}
                            />
                        </ModalInputBlock>
                    )}


                </ModalBody>
                <ModalFooter className="modal-footer-fix">
                    <ExecutionButton type="save" disabled={props.isSubmitExecuting} onClick={() => props.onSubmit(data)} />
                    { props.original.fee_no != null && (
                        <ExecutionButton type="delete" disabled={props.isSubmitExecuting} onClick={props.onDelete} />
                    )}
                </ModalFooter>
            </Modal>
        </div>
    )

}

interface StepProps {
    data:KeyedStep;
    onChange: (data:KeyedStep) => void;
    onRemove: () => void;
    hasPoint: boolean;
}

const StepInput = React.memo((props:StepProps) => {
    return (
        <div className={styles["medicine-route-extra-box"] + " m-b-5"}>
            <IconRight iconType="remove" className="pull-right"
                onClick={props.onRemove}
            />
            <div className="row-no-margin align-items-center m-b-5">
                <RequiredNumInput style={{ width: "60px" }}
                    value={props.data.distance_from}
                    min={LMT.VISIT_FEE.DISTANCE_MIN} max={LMT.VISIT_FEE.DISTANCE_MAX} step={LMT.VISIT_FEE.DISTANCE_STEP}
                    onChange={val => props.onChange({ ...props.data, distance_from:val })}
                />
                <span className={styles["popup-unit"]}>km ～</span>
                <OptionalNumInput style={{ width: "60px" }}
                    value={props.data.distance_to}
                    min={LMT.VISIT_FEE.DISTANCE_MIN} max={LMT.VISIT_FEE.DISTANCE_MAX} step={LMT.VISIT_FEE.DISTANCE_STEP}
                    onChange={val => props.onChange({ ...props.data, distance_to:val })}
                />
                <span className={styles["popup-unit"]}>km</span>
            </div>
            <div className="row-no-margin align-items-center m-b-5">
                <OptionalNumInput style={{ width: "60px" }}
                    value={props.data.distance_step}
                    min={LMT.VISIT_FEE.DISTANCE_MIN} max={LMT.VISIT_FEE.DISTANCE_MAX} step={LMT.VISIT_FEE.DISTANCE_STEP}
                    onChange={val => props.onChange({ ...props.data, distance_step:val })}
                />
                <span className={styles["popup-unit"]}>km 毎に</span>
            </div>
            <div className="row-no-margin align-items-center">
                <RequiredNumInput
                    style={{ width: "68px" }}
                    min={LMT.VISIT_FEE.FEE_MIN} max={LMT.VISIT_FEE.FEE_MAX} step={LMT.VISIT_FEE.FEE_STEP}
                    value={props.data.fee}
                    onChange={fee => props.onChange({ ...props.data, fee })}
                />
                <span className={styles["popup-unit"]}>円</span>
                { props.hasPoint && (<>
                    <span className={styles["popup-prefix"]}>：点数</span>
                    <OptionalNumInput placeholder="A"
                        style={{ width:"58px" }}
                        min={LMT.VISIT_FEE.POINT_MIN} max={LMT.VISIT_FEE.POINT_MAX} step={LMT.VISIT_FEE.POINT_STEP}
                        value={props.data.point_a}
                        onChange={point_a => props.onChange({ ...props.data, point_a })}
                    />
                    <OptionalNumInput placeholder="B"
                        style={{ width:"58px" }}
                        min={LMT.VISIT_FEE.POINT_MIN} max={LMT.VISIT_FEE.POINT_MAX} step={LMT.VISIT_FEE.POINT_STEP}
                        value={props.data.point_b}
                        onChange={point_b => props.onChange({ ...props.data, point_b })}
                    />
                </>)}
            </div>

        </div>
    )
});