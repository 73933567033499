import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { A, TeamType } from '../../config/constant';
import { PageSettings } from '../../config/page-settings.js';
import { CommonUtil } from '../../config/util';
import styles from './team-authority.module.css';
import { withContext } from '../../stores';
import { TeamApi, TeamUsersDto, TeamUserDto, TeamAuthModifyReq, TeamAuthDeleteReq } from '../../api';
import classnames from 'classnames';
import { AppState } from '../../app';
import { hasAuthLvDetail, getAuthLevels, getAuthUIDetailItems, isRanchAuthFilled, RanchAuthUIItemKey, ClinicAuthUIItemKey, isClinicAuthFilled, getRanchAuthDetailValue, getClinicAuthDetailValue, getRequiredRanchAuth, RequiredAuthName, getRequiredClinicAuth, findAuthUIDetailsByValue } from '../../config/auth-checker';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { UserTeams } from '../../config/user-teams';
import { TeamAuthorityTable } from './team-authority-table';

interface MyProps extends BaseProps<{id:string},{},{ user_id?:string }> {
}

type ITeamUsers = Omit<TeamUsersDto, "users"> & {
    users: ITeamUserSelectable[];
}

export type ITeamUserSelectable = TeamUserDto & {
    selected: boolean;
}

type EditingData = {
    level: number;
    level_detail: Set<string>;
}

interface MyState {
    teamType?: TeamType;
    team_users: ITeamUsers;
    editingData: EditingData;
}

const DEFAULT_LEVEL_RANCH = 1;
const DEFAULT_LEVEL_CLINIC = 2;

const convertUserInfoToEditingData = (teamType: TeamType, user: TeamUserDto): EditingData => {
    const details = findAuthUIDetailsByValue(teamType, user.level, user.level_detail);

    return  {
        level: user.level,
        level_detail: new Set<string>(details),
    }
}

class TeamAuthority extends Base<MyProps, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            team_users: {
                owner_id: "",
                users: [],
            },
            editingData : {
                level: 0,
                level_detail: new Set(),
            }
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onChangeLevel = this.onChangeLevel.bind(this);
    }

    componentDidMount() {

        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);
        
        this.init(this.props.location.state?.user_id);
    }

    componentDidUpdate(prevProps: this["props"]) {

        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.init();
            return;
        }
        //画面を表示した状態で承認ダイアログから承認したケース
        if (this.props.location.state?.user_id !== prevProps.location.state?.user_id) {
            this.init(this.props.location.state.user_id);
            return;
        }
    }

    private selectUser = (user: ITeamUserSelectable, single:boolean) => {
        const teamType = this.state.teamType;
        if (teamType == null) return;

        const selected_users = this.state.team_users.users.filter(u => u.selected);
        // NOTE: 排他的選択状態のユーザ自身を行クリックした場合は何もしない
        if( selected_users.length === 1 && selected_users.every(u => u.user_id === user.user_id) && single ) return; 

        const no_selected = selected_users.length === 0;

        this.setState({ 
            team_users: { 
                owner_id: this.state.team_users.owner_id,
                users: this.state.team_users.users.map(u => u.user_id === user.user_id ? { ...u, selected: single ? true : !u.selected } : single ? { ...u, selected: false } : u),
            },
        }, () => {
            // NOTE: ユーザ未選択状態の場合、または排他的にユーザ選択された場合は選択ユーザの権限を初期値とする
            if ( no_selected || single ) {
                this.setState({
                    editingData: convertUserInfoToEditingData(teamType, user)
                });
            }
        });
    }

    private async init(user_id?: string) {
        const teamId = parseInt(this.props.match.params.id);
        if (this.handleNotAllowAccess(teamId, ["USER_MANAGEMENT"], ["USER_MANAGEMENT"])) {
            return;
        }

        const team = new UserTeams(this.props.rootStore.user).findTeam(teamId);
        if (!CommonUtil.assertNotNull(team)) return;    //↑権限チェックしているのであるはず

        const teamType = team.isRanch ? "ranch" : "clinic";

        this.context.handleSetHeader({
            iconType: teamType,
            title:team.name,
            backBtn: (this.props.location.state?.user_id ?? "") === ""
        });

        try {
            this.context.handleSetIsLoading(true);
            const res = await this.comm().send((await TeamApi()).listUserUsingPOST({team_id: parseInt(this.props.match.params.id)}));
            if (res.result !== "OK" || res.data == null) return;

            const iniUser = user_id == null ? undefined : res.data.users.find(u => u.user_id === user_id);
            let editingData: EditingData;
            if (iniUser == null) {
                editingData = {
                    level: team.isRanch ? DEFAULT_LEVEL_RANCH : DEFAULT_LEVEL_CLINIC,
                    level_detail: new Set(),
                };
            } else {
                editingData = convertUserInfoToEditingData(teamType, iniUser);
            }

            await this.setStateAsync({
                teamType,
                team_users: {
                    owner_id: res.data.owner_id ?? "",
                    users: (res.data.users ?? []).map(u => ({ ...u, selected: user_id === u.user_id }) ),
                },
                editingData,
            });
        } finally {
            this.context.handleSetIsLoading(false);
        }
    }

    private onChangeLevel(e:React.ChangeEvent<HTMLSelectElement>) {
        if (!CommonUtil.assertNotNull(this.state.teamType)) return;

        const level = Number(e.target.value);
        const level_details = getAuthUIDetailItems(this.state.teamType, level);
        const level_detail = new Set(level_details.map(l => l.key));
        this.setState({editingData: { level: level, level_detail: level_detail} });
    }

    private onChangeLevelDetail(e:React.ChangeEvent<HTMLInputElement>) {

        if(e.target.checked) {
            this.state.editingData.level_detail.add(e.target.value);
        } else {
            this.state.editingData.level_detail.delete(e.target.value);
        }

        this.setState({ 
            editingData: {
                ...this.state.editingData, 
                level_detail: this.state.editingData.level_detail,
            }
        });
    }

    private canSubmit() {
        if (this.state.teamType == null) return false;

        if (this.state.team_users.users.every(u => !u.selected)) return false;

        if (hasAuthLvDetail(this.state.teamType, this.state.editingData.level)) {
            if (this.state.editingData.level_detail.size === 0) return false;
        }

        return true;
    }

    private validateLevelDetail(): boolean {
        if (!CommonUtil.assertNotNull(this.state.teamType, "teamType")) return false;

        const details = [...this.state.editingData.level_detail.values()];
        if (details.length === 0) return true;

        let required: RequiredAuthName | undefined;
        if (this.state.teamType === "ranch" ) {
            required = getRequiredRanchAuth(details as RanchAuthUIItemKey[]);
        } else {
            required = getRequiredClinicAuth(details as ClinicAuthUIItemKey[]);
        }

        if (required != null) {
            this.context.showDialog("NOTIFY", A.MESSAGE.INVALID_AUTH_DEPENDENCY(required.required, required.selected))
            return false;
        }
        return true;
    }


    private isRevokeUserManagement() {

        const user = this.state.team_users.users.find(u => u.selected && u.user_id === this.props.rootStore.user.id);
        if (user == null)  return false;

        if (!CommonUtil.assertNotNull(this.state.teamType, "teamType")) return false;

        if (this.state.teamType === "ranch") {
            const details = [ ...this.state.editingData.level_detail.values()].map(v => v as RanchAuthUIItemKey);
            return !isRanchAuthFilled("USER_MANAGEMENT", this.state.editingData.level, details);

        } else {
            const details = [ ...this.state.editingData.level_detail.values()].map(v => v as ClinicAuthUIItemKey);
            return !isClinicAuthFilled("USER_MANAGEMENT", this.state.editingData.level, details);
        }
    }

    private async onSubmit() {

        if (!this.validateLevelDetail()) return;

        const isRevokeUserManagement = this.isRevokeUserManagement();
        if (isRevokeUserManagement) {
            const msg = "ユーザ管理権限が選択されていません。\nこのまま続行すると、ユーザ管理権限が取消されます。\nよろしいですか？";
            const confRes = await this.context.showDialog("WARNING", msg, [{ type:"delete", text:"続行" }, { type:"cancel", text:"中断" }]);
            if (confRes !== 0) return;
        }

        const users = this.state.team_users.users.filter(u => u.selected);
        const isRanch = this.state.teamType === "ranch";
        const details = [...this.state.editingData.level_detail.values()];
        const level_detail = isRanch ? getRanchAuthDetailValue(details as RanchAuthUIItemKey[]) : getClinicAuthDetailValue(details as ClinicAuthUIItemKey[]);

        try {
            let isAnySuccessed = false;
            this.context.handleSetIsLoading(true);
            for (const user of users) {
                const req:TeamAuthModifyReq = {
                    team_id: parseInt(this.props.match.params.id),
                    user_id: user.user_id,
                    level: this.state.editingData.level,
                    level_detail: level_detail,
                };
                const res = await this.comm().send((await TeamApi()).modifyAuthUsingPOST(req), { showsErrorMessage: !isAnySuccessed });
                if (res.result !== "OK") {
                    if (!isAnySuccessed) {
                        return;
                    }
                    //1件以上状態が変更されている場合
                    this.context.handleSetIsLoading(false);
                    await this.context.showDialog("NOTIFY", A.MESSAGE.FAILED_TO_SOME_AUTH_UPDATE, [ { type:"decide", text:"確認" }])
                    break;
                }
                isAnySuccessed = true;

                if (user.user_id === this.props.rootStore.user.id) {
                    const teamId = parseInt(this.props.match.params.id);
                    const newTeams = new UserTeams(this.props.rootStore.user)
                            .getUpdatedAuthTeams(teamId, this.state.editingData.level, level_detail);
                    this.props.rootStore.setUserInfo({
                        teams: newTeams
                    });
                }
            }
        } finally {
            this.context.handleSetIsLoading(false);
        }

        if (isRevokeUserManagement) {
            if (this.props.rootStore.cur_ranch_id > 0) {
                this.props.history.replace("/top/" + this.props.rootStore.cur_ranch_id);
            } else {
                this.props.history.replace("/team/search");
            }
        } else {
            this.init();
        }

    }

    canDelete(){
        if(!this.state.team_users.users.some(u => u.selected)) return false;
        return !this.state.team_users.users.some(u => u.selected && u.user_id === this.props.rootStore.user.id);
    }

    async onDelete(){
        const teamType = this.state.teamType === "ranch"? "牧場" : "診療所";
        const msg = `選択されたユーザはこの${teamType}へのアクセスができなくなります。よろしいですか？`;
        const confRes = await this.context.showDialog("QUESTION", msg, [{ type:"delete" }, { type:"cancel" }]);
        if (confRes !== 0) return;
        const selected_users = this.state.team_users.users.filter(u => u.selected);
        const teamId = parseInt(this.props.match.params.id);
        
        this.context.handleSetIsLoading(true);
        let isAnySuccessed = false;
        for (const user of selected_users) {
            const req:TeamAuthDeleteReq = {
                user_id: user.user_id,
                team_id: teamId
            }
            const res = await this.comm().send((await TeamApi()).deleteAuthUsingPOST(req), { showsErrorMessage: !isAnySuccessed });
            if (res.result !== "OK") {
                this.context.handleSetIsLoading(false);
                if (!isAnySuccessed) return;
                //1件以上更新されている場合
                await this.context.showDialog("NOTIFY", A.MESSAGE.FAILED_TO_SOME_AUTH_UPDATE, [ { type:"decide", text:"確認" }])
                break;
            }
            isAnySuccessed = true;
        }
        this.context.handleSetIsLoading(false);
        this.init();
    }

    render() {

        const getUserTitleStr = () => {
            let names = "";
            const users = this.state.team_users.users.filter(u => u.selected);
            users.forEach((u) => {
                if (names.length > 0) names += "、";
                names += `${u.name}`;
            });
            return names.length > 0 ? names + "の権限" : "権限";
        }

        const teamType = this.state.teamType;
        if (teamType == null || (teamType !== "clinic" && teamType !== "ranch")) return <></>
        
        const anySelectedUser = this.state.team_users.users.some(u => u.selected);
        const currentLvDetails = (!anySelectedUser || !hasAuthLvDetail(teamType, this.state.editingData.level)) ? undefined
            : getAuthUIDetailItems(teamType, this.state.editingData.level)
                .map(item => ({ ...item, selected: this.state.editingData.level_detail.has(item.key)}));

        return (
            <div className="page-root width-limit">
                <div className="product product-full-height">
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix">
                            <div className={styles["table-container"]}>
                                <TeamAuthorityTable
                                    teamType={teamType}
                                    users={this.state.team_users.users}
                                    ownerId={this.state.team_users.owner_id}
                                    onSelect={this.selectUser}
                                />
                            </div>
                            { anySelectedUser && (
                                <div className={styles["auth-level"]}>
                                    <div className={styles["selected-user-name"]}>
                                        <span>{getUserTitleStr()}</span>
                                    </div>
                                    <div className={styles["edit-row"]}>
                                        <span className={styles["edit-item-header"]}>
                                            <label className="col-form-label">レベル</label>
                                        </span>
                                        <div className={styles["edit-item-level"]}>
                                            <select className={classnames("form-control", styles["edit-select"])} value={this.state.editingData.level}
                                                onChange={e => this.onChangeLevel(e)}>
                                                {
                                                    getAuthLevels(teamType).map(l => (
                                                        <option key={l.lv} value={l.lv}>{l.lv}.{l.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className={styles["level-detail"]}>
                                        { currentLvDetails != null && (<>
                                            <div className={styles["edit-item-header"]}>
                                                <label className="col-form-label">詳細</label>
                                                <div className={styles["edit-item-header-sub"]}>{currentLvDetails.filter(d => d.selected).length}/{currentLvDetails.length}選択中</div>
                                            </div>
                                            <div className={styles["edit-item-level-detail"]}>
                                                { currentLvDetails.map((ld, i) => (
                                                    <div key={i} className={classnames(styles["auth-level-detail"], "checkbox", "checkbox-css")}>
                                                        <input type="checkbox" id={`level_detail${i}`}
                                                            value={ld.key} checked={ld.selected} 
                                                            onChange={e => this.onChangeLevelDetail(e)} />
                                                        <label htmlFor={`level_detail${i}`}>{ld.name}</label>
                                                    </div>
                                                ))}
                                            </div>
                                        </>)}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="button-page-footer">
                    <ExecutionButton type="save" onClick={this.onSubmit} disabled={!this.canSubmit()} />
                    <ExecutionButton type="delete" onClick={this.onDelete} disabled={!this.canDelete()} />
                </div>
            </div>
        )
    }
}

export default withRouter(withContext(TeamAuthority));