import React from 'react'
import { Redirect } from 'react-router-dom'
import { useRootStore } from '../../stores';
import { useObserver } from 'mobx-react';

import * as firebase from 'firebase/app';


const Auth = (props) => {
  const { user, auth } = useRootStore()

  return useObserver(() => user.id === '' ?
    <Redirect to={'/login'}/> :
    auth.enabled ?
      props.children : <></>
  )
}

export default Auth
