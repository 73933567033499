import React from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

interface MyProps {
    min: number;
    max: number;
    value: number;
    marks: { [key:number]: string }
    onChange: (value:number) => void;
}

export const SliderScore = (props: MyProps) => {
    if (props.max < props.min) return <></>;

    const marks = { ...props.marks };
    marks[props.min] = "";
    marks[props.max] = "";

    return (
        <div className="row" style={{ display: "flex" }}>
            <label className="col-form-label text-lg-right" style={{ fontSize:"0.8125rem" }}>{props.marks[props.min]}</label>
            <div style={{ flex: "1", marginTop: "12px" }}>
                <Slider min={props.min} max={props.max}
                    value={props.value}
                    onChange={props.onChange}
                    marks={marks} step={null} />
            </div>
            <label className="col-form-label text-lg-right" style={{ fontSize:"0.8125rem" }}>{props.marks[props.max]}</label>
        </div>
    )
}