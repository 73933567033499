import React, { useState, useEffect } from 'react';
import { IMedicineRoute, IMedicine, ITreatItem, ITreatKind, IMedicineCategory } from '../../stores';
import { Panel, PanelBody, PanelHeader } from '../../components/panel/panel.jsx';
import { A, TeamType, LMT, TreatBenefitType, TREAT_BENEFIT_TYPE, TREAT_BENEFIT_TYPES, convertBenefitType, getDefaultBenefitType } from "../../config/constant";
import { calcMedicineAmountByWeight, ICowWeightInfo, FreezedArray, CommonUtil } from '../../config/util';
import { InfoPopup } from '../../components/parts/info-popup';
import { OptionalNumInput } from './num-input';
import { TeamTreatPresetItemDto, TeamTreatNamedPresetItemDto } from '../../api';
import { Checkbox } from '../form/form-checkbox';
import moment from 'moment';
import { FadeInPanel } from './fadein-panel';
import { OptionalDatePicker } from '../form/form-date-picker';
import { CommonSelect } from './common-select';
import { calcRouteFeeOf } from '../../config/medicine-route-fee-calculator';
import { calcTreatUnitPrice } from '../../config/treat-unit-price-calculator';
import { ModifyReqTreat_fix } from '../../api/api-fix';
import { useMedicineAndTreats } from '../../stores/fetcher';
import { FetchWaiter, FetchError } from '../content/fetch-state';

/**
 * ※clinic-ranch: 診療所ユーザで牧場マスタを使用
 */
export type MedicineTeamType = TeamType | 'clinic-ranch'

export type ITreatSelectorData = {
    unit_price: number;
    team_id: number;
    amount?: number;
    medicine_id?: number;
    note?: string;
    route_id?: number;
    treat_item_no?: number;
    treat_kind_no?: number;
    is_next_default_amount?: boolean;
    is_next_default_route?: boolean;

    new_medicine_name?: string;
    new_treat_item_name?: string;
    new_medicine_category?: number;
    new_item_unit?: string;

    lot_no?: string;
    expiring_month?: Date;

    benefit_type: TreatBenefitType;
}

/**
 * 予定設定された処置プリセットを記録用に変換する
 * （同じ組織のプリセットであればそのまま使用し、他組織のプリセットの場合、参照できない項目は新規項目として生成する）
 */
export const convScheduledPresetToTreatSelectorData
    = (preset: TeamTreatNamedPresetItemDto,
        presetTeamId: number,
        ranchId: number,
        userClinicId: number | undefined,
        ranchMedicines: FreezedArray<IMedicine> | undefined,
        clinicMedicines: FreezedArray<IMedicine> | undefined,
        routes: FreezedArray<IMedicineRoute>,
        treatItems: FreezedArray<ITreatItem>,
        canEditUserTeamMaster: boolean)
    : ITreatSelectorData & { name:string, route_name:string|undefined, unit:string|undefined } | undefined => {

    const userTeamId = userClinicId ?? ranchId;
    const userTeamMedicines = userClinicId != null ? clinicMedicines : ranchMedicines;
    if (presetTeamId === userTeamId) {
        return convPresetToTreatSelectorData(preset, presetTeamId, userTeamMedicines ?? [], routes, treatItems);
    }

    const benefitType = getDefaultBenefitType(userClinicId != null);

    //他組織の投薬
    if (preset.medicine_id != null) {
        const route = preset.route_id == null ? undefined : routes.find(r => r.route_id === preset.route_id);   //参照できない経路の場合は捨てる

        const medList = presetTeamId === ranchId ? ranchMedicines : presetTeamId === userClinicId ? clinicMedicines : undefined;
        const medicine = (medList ?? []).find(m => m.medicine_id === preset.medicine_id);
        if (medicine == null) {
            if (!canEditUserTeamMaster) return undefined;
            if (preset.item_name == null) return undefined; //通常ないはず

            //同じ名前の薬があれば採用
            const sameNameMed = userTeamMedicines?.find(m => m.name === preset.item_name);

            //同名の薬、または新規
            return {
                team_id: userTeamId,
                medicine_id: sameNameMed?.medicine_id,
                name: preset.item_name,
                route_id: route?.route_id,
                route_name: route?.name,
                unit: sameNameMed?.unit ?? preset.item_unit,
                new_item_unit: sameNameMed == null ? preset.item_unit : undefined,
                new_medicine_name: sameNameMed == null ? preset.item_name : undefined,
                new_medicine_category: undefined,   //新規の場合、これは自分で選択させる
                unit_price: sameNameMed?.unit_price ?? 0,
                amount: preset.amount,
                benefit_type: benefitType
            };
        }
        //他組織だが参照できる薬
        return {
            team_id: presetTeamId,
            medicine_id: medicine.medicine_id,
            amount: preset.amount,
            unit_price: medicine.unit_price,
            route_id: route?.route_id,
            name: medicine.name,
            route_name: route?.name,
            unit: medicine.unit,
            benefit_type: benefitType
        };
    }

    //他組織の処置->新規生成
    if (!canEditUserTeamMaster) return undefined;
    if (preset.item_name == null) return undefined; //通常ないはず

    //同じ名前の処置があれば採用
    const sameNameTreat = treatItems.find(t => t.name === preset.item_name);

    return {
        team_id: userTeamId,
        name: sameNameTreat?.name ?? preset.item_name,
        treat_kind_no: sameNameTreat?.treat_kind_no ?? preset.treat_kind_no,    //新規でも処置種別は引継ぎ
        treat_item_no: sameNameTreat?.treat_item_no,
        new_treat_item_name: sameNameTreat == null ? preset.item_name : undefined,
        unit: sameNameTreat?.fee_unit ?? preset.item_unit,
        new_item_unit: sameNameTreat == null ? preset.item_unit : undefined,
        unit_price: sameNameTreat?.fee ?? 0,
        amount: preset.amount,
        route_name: undefined,
        benefit_type: benefitType
    };
}

export const convPresetToTreatSelectorData
    = (preset: TeamTreatPresetItemDto, 
        teamId: number,
        medicines: FreezedArray<IMedicine>,
        routes: FreezedArray<IMedicineRoute>, 
        treatItems: FreezedArray<ITreatItem>,
        options?: { completeOnly?:boolean }
    ) : ITreatSelectorData & { name:string, route_name:string|undefined, unit:string|undefined } | undefined => {

    if (preset.medicine_id != null) {
        const medicine = medicines.find(m => m.medicine_id === preset.medicine_id);
        if (medicine == null) return undefined;

        //経路がリストに存在しない場合でも、プリセット自体は生かす
        const route = preset.route_id == null ? undefined : routes.find(r => r.route_id === preset.route_id);

        if (options?.completeOnly) {
            //※現状経路未設定も不可としておく
            if (route == null || preset.amount == null) return undefined;
        }

        return {
            team_id: teamId,
            medicine_id: preset.medicine_id,
            amount: preset.amount,
            unit_price: medicine.unit_price,
            route_id: route?.route_id,
            name: medicine.name,
            route_name: route?.name,
            unit: medicine.unit,
            benefit_type: convertBenefitType(preset.benefit_type)
        };
    }

    if (preset.treat_kind_no == null || preset.treat_item_no == null) return undefined;

    const treatItem = treatItems.find(t => t.treat_kind_no === preset.treat_kind_no && t.treat_item_no === preset.treat_item_no);
    if (treatItem == null) return undefined;

    if (options?.completeOnly) {
        if (treatItem.fee_unit != null && preset.amount == null) return undefined;
    }

    return {
        team_id: teamId,
        treat_kind_no: treatItem.treat_kind_no,
        treat_item_no: treatItem.treat_item_no,
        amount: preset.amount,
        unit_price: treatItem.fee,
        name:treatItem.name,
        route_name: undefined,
        unit: treatItem.fee_unit ?? undefined,
        benefit_type: convertBenefitType(preset.benefit_type)
    };
}

export interface ITreatSelectorProps {
    index:number;
    medicineCategories: FreezedArray<IMedicineCategory>;
    treatKinds: FreezedArray<ITreatKind>;
    data: ITreatSelectorData;
    onChange: (data:ITreatSelectorData) => void;
    onDelete: () => void;
    treatKindMedicineName: string;
    defaultMedicineCategory?: number;
    weightInfo: Readonly<ICowWeightInfo>;
    filterMedicine?: (medicine:IMedicine) => boolean;
    isMedicineOnly?: boolean;
    hasAuthToEditMaster: boolean;
    usesLotForVaccine?: boolean;
    usesBenefitForClinic?: boolean;
    isOfficialRanch: boolean;
    ranchId: number;
    clinicId: number | undefined;
}

export const TREAT_VALIDATION_RESULT = {
    OK: { msg: undefined },
    BLANK: { msg: undefined },
    NO_MEDICINE_AMOUNT: { msg: "投与量を入力してください" },
    NO_MEDICINE_UNIT: { msg: "作成する薬品の投与単位を入力してください" },
    NO_MEDICINE: { msg: "薬品を選択してください" },
    NO_ROUTE: { msg: "投与方法を選択してください" },
    NO_CATEGORY: { msg: "作成する薬品の分類を選択してください" },
    NO_TREAT_AMOUNT: { msg: "数量を入力してください" },
    NO_TREAT_UNIT: { msg: "作成する処置の数量単位を入力してください" },
    NO_TREAT_ITEM: { msg: "処置項目を選択してください" }
} as const;

export type TreatValidationResultKey = keyof typeof TREAT_VALIDATION_RESULT;

export const validateTreat = (data: ITreatSelectorData, allTreats: FreezedArray<ITreatItem>, allowsNoRoute: boolean): TreatValidationResultKey => {
    //薬
    if (data.treat_kind_no == null || data.treat_kind_no === 0) {
        const hasAmount = data.amount != null;
        const hasRoute = data.route_id != null && data.route_id !== 0;
        const hasMedicine = data.medicine_id != null && data.medicine_id !== 0;
        const hasNewName = data.new_medicine_name != null && data.new_medicine_name !== "";
        const hasNewCate = data.new_medicine_category != null && data.new_medicine_category !== 0;
        const hasNewUnit = data.new_item_unit != null && data.new_item_unit !== "";
        if (!hasAmount && !hasRoute && !hasMedicine && !hasNewName && !hasNewCate && !hasNewUnit) return "BLANK";
        
        if (!hasAmount) return "NO_MEDICINE_AMOUNT";
        if (!allowsNoRoute && !hasRoute) return "NO_ROUTE";

        if (hasNewName) {
            if (!hasNewCate) return "NO_CATEGORY";
            if (!hasNewUnit) return "NO_MEDICINE_UNIT";
        } else {
            if (!hasMedicine) return "NO_MEDICINE";
        }
    } else {
        //処置
        const hasItem = data.treat_item_no != null && data.treat_item_no !== 0;
        const hasAmount = data.amount != null;
        const hasNote = data.note != null && data.note !== "";
        const hasNewName = data.new_treat_item_name != null && data.new_treat_item_name !== "";
        const hasNewUnit = data.new_item_unit != null;
        if (!hasItem && !hasNote && !hasNewName && !hasNewUnit) return "BLANK";
        if (hasNewName) {
            if (hasNewUnit && !hasAmount) return "NO_TREAT_AMOUNT";
            if (hasAmount && !hasNewUnit) return "NO_TREAT_UNIT";
        } else {
            if (!hasItem) return "NO_TREAT_ITEM";
            const item = allTreats.find(t => t.treat_kind_no === data.treat_kind_no && t.treat_item_no === data.treat_item_no);
            if ((item?.fee_unit ?? "") !== "") {
                if (!hasAmount) return "NO_TREAT_AMOUNT";
            }
        }
    }

    return "OK";
}


export const convertTreatsToModifyReq = (treats: FreezedArray<ITreatSelectorData>, routes: FreezedArray<IMedicineRoute>): ModifyReqTreat_fix[] => {

    return treats.map((_,i) => convertTreatToModifyReq(treats, i, routes));
}
const convertTreatToModifyReq = (treats: FreezedArray<ITreatSelectorData>, index:number, routes: FreezedArray<IMedicineRoute>): ModifyReqTreat_fix => {
    const data = treats[index];
    const route = data.route_id == null ? undefined : routes.find(r => r.route_id === data.route_id);
    const routeFee = route == null ? 0 : calcRouteFeeOf(route, treats.map(t => ({ route_id:t.route_id, amount:t.amount })), index);

    return {
        team_id: data.team_id,
        unit_price: calcTreatUnitPrice({ ...data, medicine_id:data.medicine_id }, route),
        amount: data.amount,
        medicine_id: data.medicine_id,
        route_id: data.route_id,
        treat_kind_no: data.treat_kind_no,
        treat_item_no: data.treat_item_no,
        note: data.note,
        route_fee: routeFee,
        new_medicine_category: data.new_medicine_category,
        new_medicine_name: data.new_medicine_name,
        new_item_unit: data.new_item_unit,
        new_treat_item_name: data.new_treat_item_name,
        as_default_amount: data.is_next_default_amount === true ? 1 : 0,
        as_default_route : data.is_next_default_route === true ? 1 :0,
        lot_no: data.lot_no,
        expiring_day: data.expiring_month == null ? undefined : moment(data.expiring_month).format("YYYY-MM-DD"),
        ignores_washout: route?.is_instruction === 1 ? 1 : 0,
        benefit_type: TREAT_BENEFIT_TYPE[data.benefit_type].no
    };
}

export const TreatSelector: React.FC<ITreatSelectorProps> = (props) => {

    const master = useMedicineAndTreats(props.ranchId, props.clinicId);

    const userTeamType:TeamType = props.clinicId != null ? 'clinic' : 'ranch';

    const [ medicineTeamType, setMedicineTeamType ] = useState<MedicineTeamType>(
        userTeamType === 'ranch' ? 'ranch'
                    //牧場の薬が選択済の場合は初期値を「牧場の薬品」に
                    : props.data.team_id === props.ranchId ? 'clinic-ranch'
                    : 'clinic'
    )
    const [ category, setCategory ] = useState(
        (props.data.medicine_id ?? 0) > 0 ? 0 : (props.defaultMedicineCategory ?? 0)
    );

    const [ medicines, setMedicines ] = useState<IMedicine[]>([]);
    const [ hasNewTreatAmount, setHasNewTreatAmount ] = useState(false);

    const [ showsLot, setShowsLot ] = useState(false);

    useEffect(() => {
        let newMedicines = medicineTeamType === "clinic" ? master.data?.clinicMedicines : master.data?.ranchMedicines;
        if (newMedicines == null) return;

        const teamId = getTreatTeamId(medicineTeamType);

        const filterMedicine = props.filterMedicine;
        if (filterMedicine != null) {
            //※治療薬／予防薬のフィルタで含まれないものであっても、選択済みの薬があればリストに含める
            newMedicines = newMedicines.filter(m => filterMedicine(m) || props.data.team_id === teamId && props.data.medicine_id === m.medicine_id);
        }
        if (category !== 0) {
            newMedicines = newMedicines.filter(m => m.category === category);
        }
        setMedicines(newMedicines);

        if (props.data.new_medicine_name != null) {
            props.onChange({ ...props.data, new_medicine_category: category })
        }

    },[ medicineTeamType, category, props.data.medicine_id, master.data?.clinicMedicines, master.data?.ranchMedicines ]);

    useEffect(() => {
        const isMedicine = (props.data.treat_kind_no ?? 0) === 0;
        if (!props.usesLotForVaccine || !isMedicine) {
            setShowsLot(false);
            return;
        }
        if (props.data.new_medicine_name != null) {
            setShowsLot(category === A.MEDICINE_CATEGORY_VACCINE);
            return;
        }
        if (props.data.medicine_id == null) {
            setShowsLot(false);
            return;
        }

        const medicines = medicineTeamType === "clinic" ? master.data?.clinicMedicines : master.data?.ranchMedicines;
        if (medicines == null) return;

        const medicine = medicines.find(m => m.medicine_id === props.data.medicine_id);
        setShowsLot(medicine?.category === A.MEDICINE_CATEGORY_VACCINE);

    },[ medicineTeamType, category, props.data, props.usesLotForVaccine, master.data?.clinicMedicines, master.data?.ranchMedicines ]);

    useEffect(() => {
        if (master.data == null) return;
        const allMedicines = [ ...master.data.ranchMedicines, ...(master.data.clinicMedicines ?? []) ];

        //存在しないマスタ項目が含まれている場合、その部分を未設定に戻す
        if (props.data.medicine_id != null) {
            const medicine = allMedicines.find(m => m.team_id === props.data.team_id && m.medicine_id === props.data.medicine_id);
            const route = props.data.route_id == null ? undefined : master.data.medicineRoutes.find(r => r.route_id === props.data.route_id);
            const changed = medicine == null
                        || (props.data.route_id ?? 0) !== (route?.route_id ?? 0)
                        || props.data.unit_price !== medicine.unit_price;

            if (changed) {
                props.onChange({
                    ...props.data,
                    medicine_id: medicine?.medicine_id,
                    unit_price: medicine?.unit_price ?? 0,  //マスタの金額が変わっている場合も反映される（薬価の発生しない経路の場合もいったんマスタ金額が設定される）
                    route_id: route?.route_id,
                });
            }
            return;
        }
        if (props.data.treat_kind_no != null && props.data.treat_item_no != null) {
            const treatItem = master.data.treatItems.find(t => t.treat_kind_no === props.data.treat_kind_no && t.treat_item_no === props.data.treat_item_no);
            const changed = treatItem == null || props.data.unit_price !== treatItem.fee;
            if (changed) {
                props.onChange({
                    ...props.data,
                    treat_item_no: treatItem?.treat_item_no,
                    amount: (treatItem?.fee_unit ?? "") !== "" ? props.data.amount : undefined,
                    unit_price: treatItem?.fee ?? 0  //マスタの金額が変わっている場合も反映される
                });
            }
        }

    }, [ props.data, master.data ]);

    useEffect(() => {
        //他組織のプリセットから新規処置項目を初期値とする場合に、数量が定義されていれば反映されるように
        const isNew = props.data.new_treat_item_name != null;
        const hasItemUnit = (props.data.new_item_unit ?? "") !== "";
        if (isNew && hasItemUnit) {
            setHasNewTreatAmount(true);
        }

    }, [ props.data.new_item_unit, props.data.new_treat_item_name ]);

    const onChangeTreatKind = (value: string) => {
        const kindNo = Number(value);

        if (kindNo === 0) { setCategory(props.defaultMedicineCategory ?? 0); }

        const teamId = kindNo === 0 ? getTreatTeamId(medicineTeamType) : userTeamType === 'clinic' ? props.clinicId : props.ranchId;
        if (!CommonUtil.assertNotNull(teamId, "teamId")) return;

        props.onChange({
            benefit_type: props.data.benefit_type,
            unit_price: 0,
            team_id: teamId,
            treat_kind_no: kindNo === 0 ? undefined : kindNo,
            treat_item_no: undefined,
            note: undefined,
            new_item_unit: undefined,
            new_treat_item_name: undefined,
            new_medicine_category: undefined,
            new_medicine_name: undefined,
            lot_no: undefined,
            expiring_month: undefined,
        });
    }
    const onChangeMedicineTeamType = (value: MedicineTeamType) => {
        setMedicineTeamType(value);
        setCategory(props.defaultMedicineCategory ?? 0);

        const teamId = getTreatTeamId(value);
        if (!CommonUtil.assertNotNull(teamId, "teamId of " + value)) return;

        props.onChange({
            ...props.data,
            team_id: teamId,
            medicine_id: undefined,
            unit_price: 0,
            new_medicine_category: undefined,
            new_medicine_name: undefined,
            new_item_unit: undefined,
            lot_no: undefined,
            expiring_month: undefined
        })
    }
    const onChangeCategory = (value: string) => {
        const cateId = Number(value);
        setCategory(cateId);

        const preMedicineId = props.data.medicine_id;
        if (cateId > 0 && (preMedicineId ?? 0) > 0) {
            const preMed = medicines.find(m => m.medicine_id === preMedicineId);
            if (preMed?.category !== cateId) {
                props.onChange({
                    ...props.data,
                    medicine_id: undefined,
                    unit_price: 0,
                    lot_no: undefined,
                    expiring_month: undefined
                })
            }
        }

    }
    const onChangeMedicine = (medicine_id: number) => {
        if (medicine_id === -1) return;

        const medicine = medicines.find(m => m.medicine_id === medicine_id);
        props.onChange({
            ...props.data,
            medicine_id: medicine_id === 0 ? undefined : medicine_id, 
            unit_price: medicine?.unit_price ?? 0,
            amount: calcMedicineAmountByWeight(props.weightInfo, medicine) ?? props.data.amount,
            route_id: medicineTeamType === 'clinic-ranch' ? props.data.route_id : medicine?.default_route_id ?? props.data.route_id,
            new_medicine_category: undefined,
            new_medicine_name: undefined,
            new_item_unit: undefined
        });
    }
    const onNewMedicineCreated = (name: string) => {
        props.onChange({
            ...props.data,
            medicine_id: undefined,
            unit_price: 0,
            new_medicine_name: name,
            new_medicine_category: category,
            new_item_unit: "ml", //初期値
        })
    }
    const onNewTreatCreated = (name: string) => {
        props.onChange({
            ...props.data,
            treat_item_no: undefined,
            amount: undefined,
            unit_price: 0,
            new_treat_item_name: name,
            new_item_unit: undefined,
            note: undefined
        })
    }
    const onHasNewTreatAmountChanged = (hasAmount:boolean) => {
        setHasNewTreatAmount(hasAmount)
        props.onChange({
            ...props.data,
            new_item_unit: hasAmount ? "種" : undefined,    //初期値「種」
            amount: undefined
        })
    }

    const onChangeTreatItem = (treat_item_no: number) => {
        if (treat_item_no === -1) return;
        if (master.data == null) return;

        props.onChange({
            ...props.data,
            treat_item_no: treat_item_no === 0 ? undefined : treat_item_no,
            medicine_id: undefined,
            unit_price: master.data.treatItems.find(t => t.treat_kind_no === props.data.treat_kind_no && t.treat_item_no === treat_item_no)?.fee ?? 0,
            route_id: undefined,
            amount: undefined,
            new_treat_item_name: undefined,
            new_item_unit: undefined,
            lot_no: undefined,
            expiring_month: undefined
        });
    }
    const onChangeAmount = (amount: number | undefined) => {
        props.onChange({
            ...props.data,
            amount: amount,
        });
    }

    const onChangeIsNextDefaultAmount = (checked:boolean) => {
        props.onChange({
            ...props.data,
            is_next_default_amount: checked,
        });
    }

    const onChangeRoute = (value: string) => {
        const route = Number(value);

        props.onChange({
            ...props.data,
            route_id: route === 0 ? undefined : route
        });
    }

    const onChangeIsNextDefaultRoute = (checked:boolean) => {
        props.onChange({
            ...props.data,
            is_next_default_route: checked,
        });
    }

    const onLotNoChange = (lotNo: string) => {
        props.onChange({
            ...props.data,
            lot_no: lotNo
        });
    }
    const onExpiringDayChange = (day: Date | undefined) => {
        props.onChange({
            ...props.data,
            expiring_month: day
        })
    }

    const getTreatTeamId = (medicine_team_type:MedicineTeamType) => {
        return medicine_team_type === 'ranch' || medicine_team_type === 'clinic-ranch' ? props.ranchId : props.clinicId; 
    }

    const getTreatItemList = (treatKindNo: number) => {
        if (master.data == null) return [];
        return master.data.treatItems.filter(t => t.treat_kind_no === treatKindNo);
    }
    const getMedicineUnit = (medicineId?: number) => {
        if (medicineId == null) return "";
        return medicines.find(m => m.medicine_id === medicineId)?.unit ?? "";
    }

    const renderTreatItemAmount = (data: ITreatSelectorData) => {
        if (master.data == null) return <></>;

        const isNew = data.new_treat_item_name != null;

        let unit: string;
        if (isNew) {
            unit = data.new_item_unit ?? "";
        } else {
            const item = master.data.treatItems.find((v) => v.treat_kind_no === data.treat_kind_no && v.treat_item_no === data.treat_item_no);
            unit = item?.fee_unit ?? "";
            //数量なし
            if (unit === "") return <></>;
        }

        const disabled = isNew && !hasNewTreatAmount;

        return (
            <div className="form-group row" data-testid="treat-amount">
                <div className="col-form-label col-md-3 col-xs-3">
                    { isNew ? (
                        <Checkbox label="数量"
                            id={"chkTreatAmount_" + props.index}
                            checked={hasNewTreatAmount}
                            onChange={e => onHasNewTreatAmountChanged(e.target.checked)}
                        />
                    ) : (
                        <span>数量</span>
                    )}
                </div>
                <div style={{ display:"flex", flexWrap:"wrap", alignItems:"center" }}>
                    <OptionalNumInput disabled={disabled}
                        min={LMT.MEDICINE.AMOUNT_MIN} max={LMT.MEDICINE.AMOUNT_MAX} step={LMT.MEDICINE.AMOUNT_STEP}
                        style={{ marginRight: "5px", width: "76px", display: "inline" }}
                        value={data.amount}
                        onChange={val => onChangeAmount(val)}
                    />
                    { isNew ? (
                        <input type="text" disabled={disabled} maxLength={LMT.TREAT.UNIT_LEN}
                            className="form-control"
                            style={{ width:"80px" }}
                            value={unit} placeholder="単位"
                            onChange={e => props.onChange({ ...props.data, new_item_unit: e.target.value })}
                        />
                    ) : (
                        <span>{unit}</span>
                    )}
                </div>
            </div>
        );
    };
    const renderTreatItemNote = (data:ITreatSelectorData) => {
        const kind = props.treatKinds.find((v) => v.treat_kind_no === data.treat_kind_no);
        const noteName = kind?.note_name ?? "";
        if (noteName === "") return <></>;

        return (
            <div className="form-group row" data-testid="treat-note">
                <label className="col-form-label col-md-3 col-xs-3">{noteName}</label>
                <div className="col-md-9 col-xs-9 p-l-0">
                    <textarea className="form-control" rows={4} maxLength={LMT.TREAT.MEMO_LEN}
                        value={data.note ?? ""} 
                        onChange={e => props.onChange({...data, note: e.target.value})} />
                </div>
            </div>
        );
    };

    const isMyTeam = medicineTeamType !== 'clinic-ranch';
    const canSelectTeamType = userTeamType === "clinic" && props.isOfficialRanch;
    const canCreateNew = isMyTeam && props.hasAuthToEditMaster;
    const hasBenefitSelect = props.usesBenefitForClinic && userTeamType === "clinic";

    if (master.isLoading) return <FetchWaiter />
    if (master.isError || master.data == null) return <FetchError />

    return (
        <Panel onClose={props.onDelete} className="treat-selector-panel">
            <PanelHeader>
                { props.isMedicineOnly ? (
                    <span>{props.treatKindMedicineName}</span>
                ) : (
                    <select className="form-control" value={props.data.treat_kind_no ?? 0}
                        style={{ width: "220px" }}
                        onChange={(e) => { onChangeTreatKind(e.target.value) }}>
                        <option value={0}>{props.treatKindMedicineName}</option>
                        {
                            props.treatKinds.map((value, p) => (
                                <option key={p}
                                    value={value.treat_kind_no}>
                                    {value.name}
                                </option>
                            ))
                        }
                    </select>
                )}
            </PanelHeader>
            <PanelBody>
                { hasBenefitSelect && (
                    <div style={{ textAlign:"right", marginBottom:"6px" }}>
                        <select className="borderless-select"
                            value={props.data.benefit_type}
                            onChange={e => props.onChange({ ...props.data, benefit_type:e.target.value as TreatBenefitType})}
                        >
                            { TREAT_BENEFIT_TYPES.map(t => (
                                <option key={t} value={t}>{TREAT_BENEFIT_TYPE[t].name}</option>
                            ))}
                        </select>
                    </div>
                )}
                {((props.data.treat_kind_no ?? 0) === 0) && (
                <div>
                    { canSelectTeamType && 
                        <div className="form-group row">
                            <label className="col-form-label col-md-3 col-xs-3">&nbsp;</label>
                            <div className="radio radio-css radio-inline" data-testid="medicine-stock">
                                <input type="radio" name={`radioMedicineTeamType${props.index}`} id={`radioMedicineTeamTypeClinic${props.index}`} 
                                    checked={medicineTeamType === 'clinic'} value={'clinic'}
                                    onChange={e => onChangeMedicineTeamType(e.target.value as MedicineTeamType)} />
                                <label  className ="m-r-10" htmlFor={`radioMedicineTeamTypeClinic${props.index}`}>診療所の薬品</label>
                                <input type="radio" name={`radioMedicineTeamType${props.index}`} id={`radioMedicineTeamTypeRanch${props.index}`} 
                                    checked={medicineTeamType === 'clinic-ranch'} value={'clinic-ranch'} 
                                    onChange={e => onChangeMedicineTeamType(e.target.value as MedicineTeamType)} />
                                <label htmlFor={`radioMedicineTeamTypeRanch${props.index}`}>牧場の薬品</label>
                            </div>
                        </div>

                    }
                    <div className="form-group row">
                        <label className="col-form-label col-md-3 col-xs-3">分類</label>
                        <div>
                            <select className="form-control" value={category}
                                style={{ width: "220px" }} data-testid="medicine-category"
                                onChange={(e) => onChangeCategory(e.target.value)}>
                                <option value={0}>すべての薬</option>
                                {
                                    props.medicineCategories.map((value) => (
                                        <option key={value.category}
                                            value={value.category}>
                                            {value.name}
                                        </option>
                                    ))
                                }
                            </select>                                                        
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-form-label col-md-3 col-xs-3">薬品</label>
                        <div style={{width:"220px"}} data-testid="medicine-name">
                            <CommonSelect
                                itemName="薬品"
                                formatCreateLabel={n => `薬品「${n}」を作成`}
                                options={[
                                    ...medicines.map(m => ({ label: m.name, value: m.medicine_id})),
                                    ...(canCreateNew && props.data.new_medicine_name != null ? [{ label:props.data.new_medicine_name, value:-1 }] :[])
                                ]}
                                // 新規作成権限が無い状態で新規項目が入ってきた場合は選択なしになる
                                value={canCreateNew && props.data.new_medicine_name != null ? -1 : props.data.medicine_id}
                                onCreate={canCreateNew ? (name => onNewMedicineCreated(name)) : undefined}
                                onSelect={val => onChangeMedicine(val)}
                                maxLength={LMT.MEDICINE.NAME_LEN}
                            />
                        </div>
                    </div>
                    <FadeInPanel isVisible={showsLot}>
                        <div>
                            <div className="form-group row">
                                <label className="col-form-label col-md-3 col-xs-3">Lot No.</label>
                                <div data-testid="lot-no">
                                    <input type="text" className="form-control"
                                        value={props.data.lot_no ?? ""}
                                        maxLength={LMT.PREVENTION.LOT_NO_LEN}
                                        onChange={e => onLotNoChange(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-form-label col-md-3 col-xs-3">有効期限</label>
                                <div data-testid="expiring-day">
                                    <OptionalDatePicker monthOnly={true} value={props.data.expiring_month}
                                        name="expiringday" onChange={e => onExpiringDayChange(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </FadeInPanel>
                    <div className="form-group row">
                        <label className="col-form-label col-md-3 col-xs-3">投与量</label>
                        <div data-testid="medicine-amount">
                            <div style={{ display:"flex", alignItems:"center", flexWrap:"wrap" }}>
                                <OptionalNumInput
                                    min={LMT.MEDICINE.AMOUNT_MIN} max={LMT.MEDICINE.AMOUNT_MAX} step={LMT.MEDICINE.AMOUNT_STEP}
                                    style={{ marginRight: "5px", width: "76px", display: "inline" }}
                                    value={props.data.amount}
                                    onChange={val => onChangeAmount(val)}
                                />
                                { props.data.new_medicine_name == null ? (
                                    <span>{getMedicineUnit(props.data.medicine_id)}</span>
                                ) : (
                                    <input className="form-control" type="text"
                                        style={{ width:"80px" }}
                                        placeholder="単位" maxLength={LMT.MEDICINE.UNIT_LEN}
                                        value={props.data.new_item_unit ?? ""}
                                        onChange={e => props.onChange({ ...props.data, new_item_unit:e.target.value })}
                                    />
                                )}
                            </div>
                            { isMyTeam && (props.data.new_medicine_name != null
                                            || medicines.some(m => m.medicine_id === props.data.medicine_id && m.default_amount == null)) && (
                                <div className="checkbox checkbox-css checkbox-inline">
                                    <input type="checkbox" id={`chkNextDefaultAmount${props.index}`}
                                        onChange={(e) => onChangeIsNextDefaultAmount(e.target.checked)}
                                        checked={props.data.is_next_default_amount??false} />
                                    <label htmlFor={`chkNextDefaultAmount${props.index}`}>次回の初期値として使用</label>
                                    <InfoPopup placement="top" message={A.MESSAGE.INFO_MEDICINE_DEFAULT_AMOUNT.split('\n')} iconType="info" iconStyle={{fontSize:"0.9rem"}}/>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-form-label col-md-3 col-xs-3">投与方法</label>
                        <div data-testid="medicine-route">
                            <div>
                                <select className="form-control" value={props.data.route_id ?? 0}
                                    onChange={(e) => onChangeRoute(e.target.value)}>
                                    <option value={0}>選択</option>
                                    {
                                        (master.data.medicineRoutes ?? []).map((route, k) => (
                                            <option key={k}
                                                value={route.route_id}>
                                                {route.name}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            { isMyTeam && (props.data.new_medicine_name != null
                                            || medicines.some(m => m.medicine_id === props.data.medicine_id && m.default_route_id == null)) && (
                                <div className="checkbox checkbox-css checkbox-inline">
                                    <input type="checkbox" id={`chkNextDefaultRoute${props.index}`}
                                        onChange={(e) => onChangeIsNextDefaultRoute(e.target.checked)}
                                        checked={props.data.is_next_default_route??false} />
                                    <label htmlFor={`chkNextDefaultRoute${props.index}`}>次回の初期値として使用</label>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                )}
                {(props.data.treat_kind_no != null && props.data.treat_kind_no !== 0) && (
                    <div>
                        <div className="form-group row">
                            <label className="col-form-label col-md-3 col-xs-3">項目</label>
                            <div style={{ width: "220px" }} data-testid="treat-name">
                                <CommonSelect
                                    itemName="項目"
                                    onCreate={canCreateNew ? (name => onNewTreatCreated(name)) : undefined}
                                    value={canCreateNew && props.data.new_treat_item_name != null ? -1 : (props.data.treat_item_no)}
                                    formatCreateLabel={n => `処置「${n}」を作成`}
                                    maxLength={LMT.TREAT.NAME_LEN}
                                    options={[
                                        ...getTreatItemList(props.data.treat_kind_no).map(m => ({ label: m.name, value: m.treat_item_no})),
                                        ...(canCreateNew && props.data.new_treat_item_name != null ? [{ label:props.data.new_treat_item_name, value:-1 }] :[])
                                    ]}
                                    onSelect={val => onChangeTreatItem(val)}
                                />
                            </div>
                        </div>
                        {renderTreatItemAmount(props.data)}
                        {renderTreatItemNote(props.data)}
                    </div>
                )}
            </PanelBody>
        </Panel>
    )
}