import React, { useState, useEffect } from 'react';
import { ExtraCowDto } from '../../api';
import { FreezedArray } from '../../config/util';
import { IconRight } from '../../components/parts/icon-right';
import { CommonSelect } from '../../components/parts/common-select';

interface MyProps {
    list: FreezedArray<ExtraCowDto>;
    value: number | undefined;
    selectedName: string | undefined;
    onChange: (value: number | undefined, name: string | undefined) => void;
    onEditClick?: () => void;
    testId?: string;
}

export const AncestorSelect = (props: MyProps) => {
    const [ selectedItem, setSelectedItem ] = useState<ExtraCowDto>();

    useEffect(() => {
        if (props.value == null) {
            setSelectedItem(undefined);
            return;
        }

        const item = props.list.find(a => a.ex_cow_id === props.value);
        setSelectedItem(item);

    }, [ props.list, props.value ]);

    const onClear = () => {
        props.onChange(undefined, undefined);
    }
    const onSelect = (val: number) => {
        const name = props.list.find(p => p.ex_cow_id === val)?.name;
        props.onChange(val, name);
    }


    //削除済みの項目や、診療所の項目で、リストに存在しない場合
    if (props.value != null && props.selectedName != null && selectedItem == null) {
        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                <div className="p-5 p-r-0" style={{ fontSize: "0.875rem" }}>{props.selectedName}</div>
                <IconRight iconType="remove" onClick={() => props.onChange(undefined, undefined) } />
            </div>
        )
    }

    return (
        <div style={{ display: "flex", alignItems: "center" }} data-testid={props.testId}>
            <CommonSelect style={{ flex: 1, marginRight: "10px", minWidth:"160px", maxWidth:"240px" }}
                value={props.value}
                onClear={onClear}
                onSelect={onSelect}
                options={props.list.map(a => ({ label: a.name, value:a.ex_cow_id }))}
                onCreate={undefined}
            />
            <div className="link" onClick={props.onEditClick} style={{ visibility:props.onEditClick ? "visible" : "hidden", width:"auto" }}>編集</div>
        </div>
    )

}