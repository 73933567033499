import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { PageSettings } from '../../config/page-settings.js';
import { withContext } from '../../stores';
import { AppState } from '../../app';
import bStyles from './egg-base-style.module.css';
import { V3DateTime } from '../../components/parts/v3-date-time-picker';
import { TIMEPRESETS, LMT, A, EVENT_TYPE_PARAM } from '../../config/constant';
import { OvumRankCounter, OvumRankData } from './ovum-rank-counter';
import { IEventWriteLocationState, IEventWriteParamCow, historyLocation } from '../../config/history-location-builder';
import { OpuDto, EggApi, OpuRankDto, OpuModifyReqRank, OpuModifyReq } from '../../api';
import { FreezedArray, CommonUtil, ar } from '../../config/util';
import moment from 'moment';
import { ICowNameInfo, CowToDispInfo } from '../../components/parts/cows-popup';
import { CollapseContainer } from '../../components/parts/collapse-container';
import { getCowWithCache } from 'stores/fetcher_cow';
import { SingleCowHeader } from 'components/cow-header/single-cow-header';
import { ExecutionButton } from 'components/buttons/execution-button';
import { DIALOG_BUTTONS } from 'components/form/form-dialog';

type OpuInputData = {
    countMap: Map<number, OvumRankData>;
    comment: string;
}
type CowOpuData = IEventWriteParamCow & { input: OpuInputData };
interface MyState {
    executing: boolean;
    ranchId?: number;
    watchedAt: Date;
    cows: FreezedArray<CowOpuData>;
    original?: OpuDto;
    expandedCows:Set<number>;
}

const toCountMap = (ranks: OpuRankDto[]) => {
    const map = new Map<number, OvumRankData>();
    ranks.forEach(r => {
        const data: OvumRankData = {
            count: r.egg_count,
            countL: r.egg_count_l,
        };
        map.set(r.ovum_rank, data);
    });
    return map;
}

const toReqRanks = (countMap: Map<number, OvumRankData>):OpuModifyReqRank[] => {
    return [...countMap.entries()]
        .filter(([_,data]) => data.count > 0)
        .map(([n,data]) => ({
            ovum_rank: n,
            egg_count: data.count,
            egg_count_l: data.countL
        }));
}

const classColLabel = "col-form-label col-md-4 col-xs-4 text-lg-right";

class OpuWrite extends Base<BaseProps<{ id:string },{},IEventWriteLocationState|undefined>, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            watchedAt: new Date(),
            executing: false,
            cows: [],
            expandedCows:new Set(),
        }
    }

    componentDidMount() {

        this.context.handleSetHeader({ title:"OPU記録" });
        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        const ranchId = this.props.rootStore.cur_ranch_id;
        const cows = this.props.location.state?.cows ?? [];
        const mayEvtId = parseInt(this.props.match.params.id);
        const evtId = isNaN(mayEvtId) ? undefined : mayEvtId;

        this.setState({
            ranchId: ranchId === 0 ? undefined : ranchId,
            cows: cows.map(c => ({ ...c, input: { comment:"", countMap:new Map() }})),
            expandedCows:new Set(cows.map(c => c.cow_id))
        }, () => {
            this.loadInitial(evtId);
        });
    }

    private async loadInitial(eventId: number | undefined) {
        if (this.state.ranchId == null) return;

        this.context.handleSetIsLoading(true);

        try {
            if (eventId != null) {
                const oriReq = { ranch_id: this.state.ranchId, id: eventId };
                const oriRes = await this.comm().send((await EggApi()).getOpuUsingPOST(oriReq), { retries: true });
                const original = oriRes.data;
                if (original == null) return;

                if (this.navToRanchTopIfSelfEditOnly("OPU", original.watched_by)) return;

                //媒精記録済みなら牧場TOPへとばす
                if (original.ivfs.length > 0) {
                    await this.context.showModalAsync(A.MESSAGE.OPU_CANNOT_EDIT, "ALERT", ["OK"]);
                    this.props.history.replace("/top/" + this.state.ranchId);
                    return;
                }

                const cowRes = await this.comm().send(() => getCowWithCache(oriReq.ranch_id, original.cow_id), { retries: true });
                if (cowRes.data == null) return;

                this.setState({
                    original,
                    cows: [ {
                        ...cowRes.data,
                        use_no:cowRes.data.use_no,
                        breed_no:cowRes.data.breed_no,
                        input: {
                            comment: original.comment,
                            countMap: toCountMap(original.ranks),
                        }
                    }],
                    expandedCows: new Set([ cowRes.data.cow_id ]),
                    watchedAt: moment(original.watched_at).toDate(),
                })
            }
        } finally {
            this.context.handleSetIsLoading(false);
        }
    }

    private isExpandedChange(cowId: number, expanded: boolean) {
        const newSet = new Set(this.state.expandedCows);
        if (expanded) {
            newSet.add(cowId);
        } else {
            newSet.delete(cowId);
        }
        this.setState({ expandedCows: newSet });
    }

    private setExecuting(executing: boolean) {
        this.context.handleSetIsLoading(executing);
        this.setState({ executing });
    }


    async onSubmit() {
        if (!CommonUtil.assertNotNull(this.state.ranchId)) return;

        const req: OpuModifyReq = {
            ranch_id: this.state.ranchId,
            is_new: this.state.original == null ? 1 : 0,
            watched_at: moment(this.state.watchedAt).format("YYYY-MM-DD HH:mm:00"),
            schedule_id: this.props.location.state?.schedule_id,
            cows: this.state.cows.map(c => ({
                cow_id: c.cow_id,
                comment: c.input.comment,
                event_id: this.state.original?.event_id,
                ranks: toReqRanks(c.input.countMap),
            }))
        };
        this.setExecuting(true);

        try {
            const res = await this.comm().send((await EggApi()).modifyOpuUsingPOST(req));
            if (res.result !== "OK") return;

            this.navigateToNext();

        } finally {
            this.setExecuting(false);
        }
    }

    private navigateToNext() {
        if (this.state.cows.length === 1 && this.state.original == null) {
            this.props.history.replace(historyLocation.toCowInfo(this.state.cows[0].cow_id));
        } else {
            this.props.history.go(-1);
        }
    }

    async onDelete() {
        if (!CommonUtil.assertNotNull(this.state.ranchId, "ranchId", "delete")) return;
        if (!CommonUtil.assertNotNull(this.state.original, "original", "delete")) return;
        const conf = (await this.context.showDialog("QUESTION", "記録を削除してよろしいですか？", DIALOG_BUTTONS.DELETE_CANCEL)) === 0;
        if (!conf) return;

        this.setExecuting(true);

        const req = {
            ranch_id: this.state.ranchId,
            id: this.state.original.event_id
        };
        const res = await this.comm().send((await EggApi()).deleteOpuUsingPOST(req));
        this.setExecuting(false);

        if (res.result !== "OK") return;

        this.props.history.replace(historyLocation.toCowEvent(
            this.state.cows[0].cow_id,
            moment(this.state.original.watched_at).format("YYYY-MM-DD"),
            EVENT_TYPE_PARAM.BREEDING
        ));

    }


    render() {
        if (this.state.ranchId == null || this.state.cows.length === 0) {
            return <></>;
        }

        const anyInvalid = this.state.cows.some(c => !isValidInput(c.input));

        return (
            <div className="page-root">
                <div className="product product-full-height">
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix">
                            <div className="product-info-header">
                                { this.state.cows.length === 1 ? (
                                    <SingleCowHeader ranchId={this.state.ranchId} cowId={this.state.cows[0].cow_id} />
                                ) : (
                                    <span>{this.state.cows.length}頭の記録</span>
                                )}
                            </div>

                            <div className="product-body">
                                <div className={bStyles.row}>
                                    <label className={classColLabel}>採卵日時</label>
                                    <div className={bStyles["watched-at"]}>
                                        <V3DateTime value={this.state.watchedAt}
                                            popperClassName={bStyles["datepicker-popper"]}
                                            timePresets={this.props.rootStore.user.setting?.time_preset ?? TIMEPRESETS}
                                            onChange={d => this.setState({ watchedAt: d.toDate() })} />
                                    </div>
                                </div>

                                { this.state.cows.length === 1 ? (
                                    <OpuInput
                                        data={this.state.cows[0].input}
                                        onChange={d => this.setState({ cows: [ { ...this.state.cows[0], input: d } ] })}
                                        cow={this.state.cows[0]}
                                    />
                                ) : this.state.cows.map(c => (
                                    <CollapseContainer key={c.cow_id}
                                        isOpen={this.state.expandedCows.has(c.cow_id)}
                                        header={CowToDispInfo(c, true)}
                                        onIsOpenChange={o => this.isExpandedChange(c.cow_id, o)}
                                        className="m-t-5 m-b-15"
                                        headerStyle={{ fontWeight: "bold", color: "#999999" }}
                                    >
                                        <OpuInput
                                            data={c.input}
                                            onChange={d => this.setState({
                                                cows: this.state.cows.map(oldC => oldC.cow_id !== c.cow_id ? oldC : { ...oldC, input:d })
                                            })}
                                            cow={c}
                                        />
                                    </CollapseContainer>
                                ))}

                            </div>
                        </div>
                    </div>
                </div>
                <div className="button-page-footer">
                    <ExecutionButton type="save" disabled={this.state.executing || anyInvalid}
                        onClick={() => this.onSubmit()}
                    />
                    { this.state.original != null && (
                        <ExecutionButton type="delete" disabled={this.state.executing}
                            onClick={() => this.onDelete()}
                        />
                    )}
                </div>
            </div>
        )
    }
}

const isValidInput = (data: OpuInputData) => {
    const ranks = [...data.countMap.values()];
    if (ar.sum(ranks.map(r => r.count)) === 0) return false;
    return true;
}
const OpuInput = (props: {
    cow: ICowNameInfo;
    data: OpuInputData;
    onChange: (data: OpuInputData) => void;
}) => {

    const onRankChange = (countMap: Map<number, OvumRankData>) => {
        props.onChange({
            ...props.data,
            countMap
        });
    }

    return (
        <div data-testid={`cow__${props.cow.cow_id}`}>
            <div className={bStyles.row}>
                <label className={classColLabel}>ランク</label>
                <OvumRankCounter className="m-l-15"
                    cowKey={props.cow.cow_id}
                    canLR={true}
                    countMap={props.data.countMap}
                    onChange={onRankChange}
                />
            </div>
            <div className={bStyles.row}>
                <label className={classColLabel}>メモ</label>
                <div className={bStyles["column-value"]}>
                    <textarea className="form-control" rows={2}
                        maxLength={LMT.OPU.MEMO_LEN}
                        value={props.data.comment}
                        onChange={e => props.onChange({ ...props.data, comment:e.target.value })}
                    />
                </div>
            </div>
        </div>
    )
}



export default withRouter(withContext(OpuWrite));