import React, { useState, useMemo, useEffect } from 'react';
import { ICowNameInfo, CowToDispInfo } from 'components/parts/cows-popup';
import { Table } from "reactstrap";
import styles from './report-sensor.module.css';
import repoStyles from '../report-sellcow/report-sellcow.module.css';
import { useSensorHistory } from 'stores/fetcher_sensor';
import { FetchWaiter, FetchError } from 'components/content/fetch-state';
import { SensorHistoryListDto } from 'api';
import moment from 'moment';
import { ResponsiveContainer, CartesianGrid, YAxis, XAxis, ScatterChart, Scatter, Cell } from 'recharts';
import { IconLink } from 'components/parts/icon-link';
import { SortOrder } from 'pages/feedbulk/BulkCowSortIcon';
import { SortIcon } from 'components/parts/sort-icon';
import { ar, CommonUtil } from 'config/util';
import { ChartTooltip } from 'components/parts/chart-tooltip';

interface Props {
    ranchId: number;
    onCowSelect: (cow: ICowNameInfo) => void;
    submitId: number;
}

export const SensorHistoryView = React.memo((props: Props) => {
    const { data, isLoading, isError } = useSensorHistory(props.ranchId, props.submitId);

    const [ view, setView ] = useState<"table"|"graph">("table");

    if (isLoading) return <FetchWaiter />
    if (isError || data == null) return <FetchError />

    const allTimes = data.map(d => d.measured_at);
    const minTime = allTimes.length === 0 ? "" : allTimes.reduce((a,b) => a < b ? a : b);
    const maxTime = allTimes.length === 0 ? "" : allTimes.reduce((a,b) => a < b ? b : a);
    const summary = allTimes.length === 0 ? "" : `${moment(minTime).format("YYYY/M/D HH:mm")} ～ ${moment(maxTime).format("HH:mm")}　${data.length}頭の計測`;

    return (
        <div className={styles["view-root"]}>
            <div className={styles["history-view-header"]}>
                <div className={styles.summary}>{summary}</div>
                <div className={styles.switch}>
                    <div className="radio radio-css mr-3">
                        <input type="radio" id="rad-table" checked={view === "table"} onChange={() => setView("table")} />
                        <label htmlFor="rad-table">表</label>
                    </div>
                    <div className="radio radio-css">
                        <input type="radio" id="rad-graph" checked={view === "graph"} onChange={() => setView("graph")} />
                        <label htmlFor="rad-graph">グラフ</label>
                    </div>
                </div>
            </div>
            { view === "table" && (
                <HisTable data={data} onCowSelect={props.onCowSelect} />
            )}
            { view === "graph" && (
                <HisGraph data={data} onCowSelect={props.onCowSelect} />
            )}
        </div>
    )
})

const HIS_TABLE_COLUMNS = ["measured_at","rep_no","temperature"] as const;
type HisTableColumn = typeof HIS_TABLE_COLUMNS[number];
const HIS_TABLE_COLUMN: { [key in HisTableColumn]: { pickSortVal: (data:SensorHistoryListDto) => number|string }} = {
    measured_at: { pickSortVal: d => d.measured_at },
    rep_no: { pickSortVal: d => (d.local_no ?? "") === "" ? d.trace_id.substr(5,4) : d.local_no! },
    temperature: { pickSortVal: d => d.temperature },
};

const HisTable = (props: {
    data: SensorHistoryListDto[],
    onCowSelect: (cow: ICowNameInfo) => void,
}) => {
    const [ sortKey, setSortKey ] = useState<HisTableColumn>();
    const [ sortOrder, setSortOrder ] = useState<SortOrder>("Desc");


    const dispList = useMemo(() => {
        const list = props.data.map(d => ({ ...d, dispDate: moment(d.measured_at).format("M/D HH:mm"), repNo:CowToDispInfo(d, false) }));

        if (sortKey == null) return list;

        const column = HIS_TABLE_COLUMN[sortKey];

        return sortOrder === "Asc" ? ar.orderBy(list, column.pickSortVal) : ar.orderByDesc(list, column.pickSortVal);

    }, [ props.data, sortKey, sortOrder ]);

    const Th = (props: { header:string, column:HisTableColumn }) => {

        const onClick = () => {
            if (sortKey === props.column) {
                setSortOrder(sortOrder === "Asc" ? "Desc" : "Asc");
            } else {
                setSortKey(props.column);
                setSortOrder("Asc");
            }
        }

        return (
            <th onClick={onClick}>
                <div className="row-no-margin align-items-center">
                    <span>{props.header}</span>
                    <SortIcon state={sortKey === props.column ? sortOrder : undefined} />
                </div>
            </th>
        )
    }

    return (
        <div className={repoStyles["table-container"]}>
            <Table className={repoStyles.table}>
                <thead>
                    <tr>
                        <Th header="計測日時" column="measured_at" />
                        <Th header="耳標" column="rep_no" />
                        <Th header="計測値" column="temperature" />
                    </tr>
                </thead>
                <tbody>
                    { dispList.map(d => (
                        <tr key={d.cow_id}>
                            <td>{d.dispDate}</td>
                            <td>
                                {d.repNo}
                                <IconLink className="m-l-10" text="個体値" iconType="navigate" onClick={() => props.onCowSelect(d)} />
                            </td>
                            <td>{d.temperature}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

type HisGraphData = SensorHistoryListDto & { epocSec : number };

type ScatterClickEventData = HisGraphData & {
    tooltipPosition: { x: number, y:number },
    yAxis: { width: number, height: number },
    xAxis: { width: number, height: number } 
}

const HisGraph = (props: {
    data: SensorHistoryListDto[],
    onCowSelect: (cow: ICowNameInfo) => void
}) => {
    const [ currentData, setCurrentData ] = useState<ScatterClickEventData>();

    const graphData : HisGraphData[] = useMemo(() => {

        return props.data.map(d => ({ ...d, epocSec: moment(d.measured_at).unix() }));

    }, [ props.data ]);

    const toolTipPosition = useMemo(() => {
        const MARGIN = 10;

        if (currentData == null) return undefined;
        const width = currentData.yAxis.width + currentData.xAxis.width;
        let x: number;
        if (currentData.tooltipPosition.x <= (width / 2)) {
            x = currentData.tooltipPosition.x + MARGIN;
        } else {
            const pos = Math.max(0, width - currentData.tooltipPosition.x);
            x = (pos * -1) - MARGIN;
        }

        const height = currentData.yAxis.height + currentData.xAxis.height;
        let y: number;
        if (currentData.tooltipPosition.y <= (height / 2)) {
            y = currentData.tooltipPosition.y + MARGIN;
        } else {
            const pos = Math.max(0, height - currentData.tooltipPosition.y);
            y = (pos * -1) - MARGIN;
        }
        return { x, y };

    }, [ currentData ]);

    useEffect(() => {
        setCurrentData(undefined);

    }, [ props.data ]);

    return (
        <div style={{width:"calc(100%-10px)", flex: 1, position:"relative", marginLeft:"-10px"}}>
            <ResponsiveContainer width="100%" height="100%">
                <ScatterChart>
                    <XAxis dataKey="epocSec" type="number"
                        tick={{fontSize:"10px"}}
                        height={33}
                        domain={[ "dataMin", "dataMax" ]}
                        tickFormatter={s => moment.unix(s).format("HH:mm")}
                        tickCount={10}
                    />
                    <YAxis type="number" dataKey="temperature" unit="℃" domain={["auto", "auto"]} />
                    <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />

                    <Scatter data={graphData} onClick={setCurrentData} animationDuration={500}>
                        {/* 選択されているときは強調、選択されていないときは透明なstrokeでクリック可能な領域を作る */}
                        { graphData.map(d => (
                            <Cell key={d.cow_id}
                                strokeWidth={currentData?.cow_id === d.cow_id ? 3 : 16}
                                stroke={currentData?.cow_id === d.cow_id ? "#ff3333" : "transparent"}
                                fill={currentData?.cow_id === d.cow_id ? "#ff3333" : "#ffa500"}
                            />
                        ))}
                    </Scatter>
                </ScatterChart>
            </ResponsiveContainer>
            { currentData != null && toolTipPosition != null && (
                // ※標準のTooltipは、スタイルの自作はできるが、表示非表示の手動制御ができず、
                // 　state更新などのトリガで勝手に閉じてしまうので、自前で作り直す
                <ChartTooltip
                    headers={[moment.unix(currentData.epocSec).format("YYYY/M/D HH:mm")]}
                    link={{ icon:"navigate", text:"個体値", onClick:() => props.onCowSelect(currentData)}}
                    items={[{ color: "#ffa500", name:CowToDispInfo(currentData, true), value:currentData.temperature, unit:"℃" }]}
                    onClose={() => setCurrentData(undefined)}
                    x={toolTipPosition.x} y={toolTipPosition.y}
                />
            )}
        </div>
    )
}