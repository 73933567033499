import React from 'react';
import styles from './button.module.css';
import classnames from 'classnames';

type ExecutitonTypeInfo = {
    buttonClass: string;
    defaultText: string;
}
const EXEC_TYPES = [ "save", "decide", "delete", "cancel", "close" ] as const;
export type ExecutionType = typeof EXEC_TYPES[number];
const EXEC_TYPE: { [ key in ExecutionType]: ExecutitonTypeInfo } = {
    save: {
        buttonClass: "primary",
        defaultText: "保存"
    },
    decide: {
        buttonClass: "primary",
        defaultText: "確定"
    },
    delete: {
        buttonClass: "danger",
        defaultText: "削除"
    },
    cancel: {
        buttonClass: "cancel",
        defaultText: "キャンセル"
    },
    close: {
        buttonClass: "cancel",
        defaultText: "閉じる"
    }
}

export interface ExecutionButtonProps {
    disabled?: boolean;
    onClick?: () => void;
    testId?: string;
    className?: string;
    style?: React.CSSProperties;
    type: ExecutionType;
    children?: React.ReactNode;
}

export const ExecutionButton = (props: ExecutionButtonProps) => {
    const type = EXEC_TYPE[props.type];
    return (
        <button className={classnames(styles.execbutton, styles[type.buttonClass], props.className)}
            onClick={props.onClick} disabled={props.disabled} data-testid={props.testId}
            style={props.style}
            children={props.children ?? type.defaultText}
        />
    )

}