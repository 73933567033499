import React from 'react';
import classnames from 'classnames';
import styles from './star-icon.module.css';

interface MyProps {
    testId?: string;
    isOn: boolean;
    onChange: (toOn: boolean) => void;
    disable?: boolean;
    className?: string;
    style?: React.CSSProperties;
}

export const StarIcon = (props: MyProps) => (
    <i className={classnames("fas fa-star", styles.icon, (props.disable ? styles.disable : undefined), (props.isOn ? styles.on : styles.off), props.className)}
        data-testid={props.testId}
        style={props.style}
        onClick={() => {
            if (props.disable !== true) {
                props.onChange(!props.isOn)
            }
        }}
    />
)

export const StarIconS = React.memo((props: {
    visible:boolean
    className?: string;
    style?: React.CSSProperties;
}) => (
    <span className={classnames(styles.sicon, (props.visible ? styles.on : styles.off), props.className)}
        style={props.style}
    >★</span>
))