import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { A, LMT, TIMEPRESETS, EVENT_TYPE_PARAM } from '../../config/constant';
import { PageSettings } from '../../config/page-settings.js';
import { FeedRemainedPopup } from './feed-remained-popup';
import queryString from 'query-string'
import { withContext, IRanchFeed, IFeedType } from '../../stores';

import moment from "moment";
import { AppState } from '../../app';
import { IFeedLeft } from './feed-remained-content';
import {  FeedModifyReq, ComOnpVetellMybatisFeedDataDao, FeedRecentReq, FeedModifyReqAmount } from '../../api';
import { CommonUtil, calcSolute, FreezedArray } from '../../config/util';
import { IEventWriteLocationState, historyLocation } from '../../config/history-location-builder';
import { ScheduleFinder } from '../schedule/schedule-finder';
import { EVENT_KIND } from '../../config/event-kind';
import { V3DateTime } from '../../components/parts/v3-date-time-picker';
import { FeedInputPanel } from './feed-input-panel';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { DIALOG_BUTTONS } from '../../components/form/form-dialog';
import { useRanchFeeds, WithQuery } from '../../stores/fetcher';
import { FetchError, FetchWaiter } from '../../components/content/fetch-state';
import { getCowWithCache } from 'stores/fetcher_cow';
import { SingleCowHeader } from 'components/cow-header/single-cow-header';
import { MultiCowHeader } from 'components/cow-header/multi-cow-header';

interface MyState {
    cow_top: boolean;
    edit_feeding_id: number;
    popup_remained_feed_open: boolean;
    cows: Readonly<ICow[]>;
    feeds_left: Readonly<IFeedLeft[]>;
    feeds: Readonly<IFeed[]>;
    time: Date;
    memo: string;
    original_time: Date;
    feed_types: Readonly<IFeedType[]>;
    executing: boolean;
}

interface ICow {
    cow_id:number;
    trace_id?:string;
    local_no?:string;
    name?:string;
}
interface IFeed {
    delivered: number;
    feed_type_no: number;
    leftover: number;
    feed_no: number;
}

class FeedWrite extends Base<BaseProps<{},{},IEventWriteLocationState|undefined>, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    //何日前からの食べ残し未入力をチェックするか
    readonly FEED_LEFT_FROM_DAYS_BEFORE = 7;
    //何日前までの食べ残し未入力をチェックするか
    readonly FEED_LEFT_TO_DAYS_BEFORE = 1;

    constructor(props) {

        super(props);

        this.state = {
            cow_top: false,
            edit_feeding_id: 0,

            // Popup which inputs left feed
            popup_remained_feed_open: false,

            cows: [],

            feeds_left: [],
            feeds: [],
            feed_types:[],

            time: moment().toDate(),
            original_time: moment().toDate(),
            memo: "",

            executing: false
        }

        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onAddFeed = this.onAddFeed.bind(this);
    }

    componentDidMount() {
        if (this.handleNotAllowAccess(undefined, ["FOODING_EDIT"], [])) {
            return;
        }

        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        const edit_feeding_id = this.getFeedIdFromPath(this.props.history.location.pathname);
        const queryInfo = this.parseQuery(this.props.history.location.search);

        this.setState({
            edit_feeding_id: edit_feeding_id,
            cow_top: queryInfo.cow_top,
            cows: queryInfo.cow_ids.map(id => ({ cow_id: id })),
            feed_types: this.props.rootStore.options.feed_type
        }, () => {
            if (this.state.edit_feeding_id > 0) {
                this.context.handleSetHeader({ title:"えさ記録を編集" });
                this.api_getGetFeedInfo();
            } else {
                this.context.handleSetHeader({ title:"えさ記録を入力" });
                this.api_getRecentFeedInfo();
            }
        });
    }

    getFeedIdFromPath(path: string) {
        let root_path = "/feed/write";
        if (path.length < root_path.length) {
            return 0;
        }

        let id = path.substring(root_path.length);
        if (id.length > 1) {
            id = id.substring(1);
            console.log(id);
        } else {
            return 0;
        }
        return parseInt(id);
    }

    onRemoveFeedItem(feed: IFeed) {
        const feeds: IFeed[] = [];
        for (var i = 0; i < this.state.feeds.length; i++) {
            if (this.state.feeds[i] === feed) continue;
            feeds.push(this.state.feeds[i]);
        }
        this.setState({ feeds: feeds });
    }

    private onAddFeed() {
        this.setState({
            feeds: [
                ...this.state.feeds,
                this.newDefaultFeedItem()
            ]
        })
    }
    private newDefaultFeedItem(): IFeed {
        return ({
            delivered: 0,
            leftover: -1,
            feed_no: 0,
            feed_type_no: 0,
        });
    }

    private onFeedNoChanging(idx: number, feedNo: number) {
        if (this.state.feeds.some((f,i) => i !== idx && f.feed_no === feedNo)) {
            this.context.showToast(A.MESSAGE.FEED_ALREADY_ADDED);
            return false;
        }
        return true;
    }

    parseQuery(query: string): { cow_top: boolean, cow_ids: number[] } {
        const values = queryString.parse(query);

        const cow_top = values.cow_top != null && Number(values.cow_top) === 1;
        let cow_ids: number[];
        if (values.param == null) {
            cow_ids = [];
        }
        else {
            const cowIdsStr = Array.isArray(values.param) ? values.param : values.param.split(",");
            cow_ids = cowIdsStr.map(s => parseInt(s)).filter(id => !isNaN(id));
        }
        return { cow_top, cow_ids };
    }


    getCowIdsStr() {
        return this.state.cows.map(c => c.cow_id).join(",");
    }

    async onSave(ranchFeedList: FreezedArray<IRanchFeed>) {
        this.setState({ executing: true });
        const registered = await this.api_postFeedWrite(ranchFeedList);
        if (!registered) {
            this.setState({ executing: false });
            return;
        }
        
        if (this.state.edit_feeding_id === 0) {
            if (this.state.cow_top) {
                this.props.history.replace(historyLocation.toCowInfo(this.state.cows[0].cow_id));
            } else {
                window.history.go(-1);
            }
        } else {
            const fedAt = moment(this.state.time)
            this.props.history.replace(historyLocation.toCowEvent(this.state.cows[0].cow_id, fedAt.format("YYYY-MM-DD"), EVENT_TYPE_PARAM.FEED));
        }
    }

    async onDelete() {
        const res = await this.context.showDialog("QUESTION", "記録を削除してよろしいですか？", DIALOG_BUTTONS.DELETE_CANCEL);
        if (res === 0) {
            this.api_postFeedDelete();
        }
    }

    api_getGetFeedInfo = async () => {
        const url = `/feed/${this.state.edit_feeding_id}?user_id=${this.props.rootStore.user.id}&ranch_id=${this.props.rootStore.cur_ranch_id}`;
        this.context.handleSetIsLoading(true);
        const response = await this.comm().send<any>(() => this.context.postAsync(url, {}), {retries: true});
        this.context.handleSetIsLoading(false);

        if (response.result === "OK" && response.data != null) {
            const feed_info = response.data.info as Required<ComOnpVetellMybatisFeedDataDao>;
            console.log(feed_info);

            if (this.navToRanchTopIfSelfEditOnly("FEEDING", feed_info.fed_by)) return;

            await this.setStateAsync({
                memo: feed_info.comment,
                time: moment(feed_info.fed_at).toDate(),
                original_time: moment(feed_info.fed_at).toDate(),
                feeds: feed_info.feed_amount.map(a => ({...a, leftover: a.leftover ?? -1 })),
                cows: [ ...this.state.cows, { cow_id: feed_info.cow_id } ]
            });

            if (this.state.cows.length === 1) {
                await this.api_getGetCowInfo();
            }
        }
    }

    api_getLeftFeedList = async () => {
        let cow_ids = this.getCowIdsStr();

        let params = {
            user_id: this.props.rootStore.user.id,
            ranch_id: this.props.rootStore.cur_ranch_id,
            cow_id: cow_ids,
            from_days_before: this.FEED_LEFT_FROM_DAYS_BEFORE,
            to_days_before: this.FEED_LEFT_TO_DAYS_BEFORE
        };

        this.context.handleSetIsLoading(true);
        const response = await this.comm().send<any>(() => this.context.postAsync('/feed/amount/left/list', params), { showsErrorMessage: false });
        this.context.handleSetIsLoading(false);

        if (response.result === "OK" && response.data != null) {
            var list = response.data.list;
            this.setState({ feeds_left: list });
        }
    }

    api_getRecentFeedInfo = async () => {
        const cow_ids = this.getCowIdsStr();
        const params: FeedRecentReq = {
            user_id: this.props.rootStore.user.id,
            ranch_id: this.props.rootStore.cur_ranch_id,
            cow_id: cow_ids,
        };

        this.context.handleSetIsLoading(true);
        const response = await this.comm().send<any>(() => this.context.postAsync('/feed/recent', params), { retries: true })
        this.context.handleSetIsLoading(false);

        if (response.result === "OK" && response.data != null) {
            const feed_info = response.data.info as Required<ComOnpVetellMybatisFeedDataDao> | null;
            console.log(feed_info);
            if (feed_info !== null && feed_info.feeding_id > 0) {
                await this.setStateAsync({
                    feeds: feed_info.feed_amount.map(a => ({ ...a, leftover: a.leftover ?? -1 }))
                });
            }
            if (this.state.feeds.length === 0) {
                await this.setStateAsync({
                    feeds: [ this.newDefaultFeedItem() ]
                })
            }

            if (this.state.cows.length === 1) {
                await this.api_getGetCowInfo();
            } else {
                await this.api_getLeftFeedList();
            }
        }
    }

    api_getGetCowInfo = async () => {

        this.context.handleSetIsLoading(true);
        const response = await this.comm().send(() => getCowWithCache(this.props.rootStore.cur_ranch_id, this.state.cows[0].cow_id), { retries: true });
        this.context.handleSetIsLoading(false);
        if (response.result === "OK" && response.data != null) {
            const cow = response.data;
            this.setState({ cows: [cow] });

            //更新のときは食べ残し未入力を確認しない
            if (this.state.edit_feeding_id <= 0) {
                this.api_getLeftFeedList();
            }
        }
    }

    api_postFeedWrite = async (ranchFeedList: FreezedArray<IRanchFeed>): Promise<boolean> => {
        const cowIds = this.state.cows.map(c => c.cow_id);
        if (cowIds.length === 0) {
            this.context.showToast(A.MESSAGE.INVALID_COW_IDS);
            return Promise.resolve(false);
        }

        const fedAt = moment(this.state.time);
        if (!fedAt.isValid()) {
            this.context.showToast(A.MESSAGE.INVALID_FEED_TIME);
            return Promise.resolve(false);
        }

        const feed_amount: FeedModifyReqAmount[] = [];

        console.log(this.state.feeds);

        for (let i = 0; i < this.state.feeds.length; i++) {
            const feed = this.state.feeds[i];
            if (feed.feed_no === 0) {
                this.context.showToast(A.MESSAGE.INVALID_FEED_NO);
                return false;
            }
            if (feed.leftover > feed.delivered) {
                this.context.showToast(A.MESSAGE.INVALID_FEED_LEFTOVER);
                return false;
            }

            const feedInfo = ranchFeedList.find(f => f.feed_no === feed.feed_no);
            if (!CommonUtil.assertNotNull(feedInfo, "feedInfo")) {
                continue;
            }

            feed_amount.push({
                delivered: feed.delivered,
                delivered_raw: calcSolute(feed.feed_no, feed.delivered, ranchFeedList),
                //※分類は、画面上で選択されていなくても、その品目の分類で書き込む必要あり
                feed_type_no: feedInfo.feed_type_no,
                feed_no: feed.feed_no,
                leftover: feed.leftover,
                leftover_raw: calcSolute(feed.feed_no, feed.leftover, ranchFeedList),
                unit_price: feedInfo.unit_price
            });
        }

        if (feed_amount.some(f => (f.delivered_raw ?? 0) > LMT.FEEDING.AMOUNT_RAW_MAX
                                || (f.leftover_raw ?? 0) > LMT.FEEDING.AMOUNT_RAW_MAX)) {
            this.context.showDialog("NOTIFY", A.MESSAGE.INVALID_FEED_RAW_AMOUNT);
            return false;
        }

        const params: FeedModifyReq = {
            comment: this.state.memo,
            cow_ids: cowIds,
            fed_at: fedAt.format("YYYY-MM-DD HH:mm"),
            feed_amount: feed_amount,
            feeding_id: this.state.edit_feeding_id,
            is_new: this.state.edit_feeding_id === 0 ? 1 : 0,
            ranch_id: this.props.rootStore.cur_ranch_id,
            is_deleted: 0,
            schedule_id: this.props.location.state?.schedule_id
        }

        //関連する予定を探す
        if (params.schedule_id == null && cowIds.length === 1 && params.is_new === 1) {
            const day = fedAt.format("YYYY-MM-DD");

            const finder = new ScheduleFinder(this.context, this.props.rootStore.cur_ranch_id, this.props.rootStore.user.id);
            const scheRes = await finder.findEventRelatedSchedule(day, [EVENT_KIND.FEEDING.no], cowIds[0]);

            if (scheRes.result === "cancel") return false;

            if (scheRes.result === "yes") {
                params.schedule_id = scheRes.id;
            }
        }

        console.log("FeedModifyReq", params);

        this.context.handleSetIsLoading(true);

        const response = await this.comm().send(() => this.context.postAsync('/feed/modify', params));

        this.context.handleSetIsLoading(false);
        if (response.result === "OK") {
            // アクティブ牛リストを再取得
            this.props.rootStore.fetchActiveCows(undefined, "DIFF");
            return true;
        }
        return false;
    }

    api_postFeedDelete = async () => {
        //TODO IFの型付けを正しく
        const params = {
            feeding_id: this.state.edit_feeding_id,
            ranch_id: this.props.rootStore.cur_ranch_id,
            is_deleted: 1,
            cow_ids: [ this.state.cows[0].cow_id ],
        };

        this.context.handleSetIsLoading(true);
        const res = await this.comm().send(() => this.context.postAsync('/feed/modify', params));
        this.context.handleSetIsLoading(false);
        if (res.result === "OK") {
            // アクティブ牛リストを再取得
            this.props.rootStore.fetchActiveCows(undefined, "DIFF");
            this.props.history.replace(
                historyLocation.toCowEvent(
                    this.state.cows[0].cow_id, 
                    moment(this.state.original_time).format("YYYY-MM-DD"), 
                    EVENT_TYPE_PARAM.FEED
                )
            );
        }
    }

    render() {
        const ranchId = this.props.rootStore.cur_ranch_id;

        return (
            <WithQuery query={() => useRanchFeeds(ranchId)}>
                { ({data, isError, isLoading }) => {
                    if (isLoading) return <FetchWaiter />
                    if (isError || data == null) return <FetchError />

                    return this.renderPage(data);
                }}
            </WithQuery>
        )
    }

    renderPage(ranchFeedList: FreezedArray<IRanchFeed>) {
        const state = this.state;
        return (
            <div className="page-root">
                <div className="product product-full-height">
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix p-b-0">
                            {/* <!-- BEGIN product-info-header --> */}
                            <div className="product-info-header">
                                {
                                    state.cows.length === 1 && (
                                        <SingleCowHeader
                                            ranchId={this.props.rootStore.cur_ranch_id}
                                            cowId={state.cows[0].cow_id}
                                        />
                                    )
                                }
                                {
                                    state.cows.length > 1 && (
                                        <MultiCowHeader
                                            cowCount={state.cows.length}
                                            cows={this.props.location.state?.cows}
                                            infoName="えさ記録"
                                        />
                                    )
                                }
                            </div>
                            {/* <!-- END product-info-header --> */}
                            {/* <!-- BEGIN content --> */}
                            <div className="feed-write">
                                <div className="form-group row treat-write-row">
                                    <label className="col-form-label col-md-4 col-xs-4 text-lg-right">えさやり日時</label>
                                    <div style={{ flex: 1 }}>
                                        <V3DateTime
                                            value={this.state.time}
                                            timePresets={this.props.rootStore.user.setting?.time_preset ?? TIMEPRESETS}
                                            onChange={m => this.setState({ time: m.toDate() })}
                                        />
                                    </div>
                                </div>
                                <div>
                                    {
                                        state.feeds_left.length > 0 && (
                                            <div className="m-b-10">
                                                <button style={{ color: "#ff5b57", backgroundColor: "transparent", borderWidth: "0px" }}
                                                    onClick={() => this.setState({ popup_remained_feed_open: true })}>食べ残し量を入力していない日があります</button>
                                            </div>
                                        )
                                    }
                                    {
                                        state.feeds.map((feed, i) => (
                                            <FeedInputPanel key={i}
                                                feedTypeList={this.state.feed_types}
                                                ranchFeedList={ranchFeedList}
                                                feed={feed}
                                                onChange={f => this.setState({ feeds: [
                                                    ...this.state.feeds.slice(0, i),
                                                    f,
                                                    ...this.state.feeds.slice(i + 1)
                                                ]})}
                                                onRemove={() => this.onRemoveFeedItem(feed)}
                                                onFeedNoChanging={no => this.onFeedNoChanging(i, no)}
                                            />
                                        ))
                                    }
                                </div>

                                <div className="form-group row treat-write-row"
                                     style={{ display: "flex", marginLeft: "0px", marginRight: "0px" }}>
                                    <button style={{ flex: "1" }} className="btn btn-theme btn-sm btn-inverse"
                                            onClick={this.onAddFeed}>
                                        <i className="fas fa-lg fa-fw m-r-10 fa-plus-circle"></i>
                                        えさ品目を追加
                                    </button>
                                </div>

                                <div className="form-group row treat-write-row">
                                    <label className="col-form-label col-md-2 col-xs-2 text-lg-right">メモ</label>
                                    <div className="col-md-10 col-xs-10">
                                        <textarea className="form-control" rows={4}
                                            maxLength={LMT.FEEDING.MEMO_LEN} data-testid="メモ"
                                            value={state.memo} onChange={(e) => this.onValueChange(e, "memo")}></textarea>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- END content --> */}
                        </div>
                    </div>
                </div>

                {/* <!-- BEGIN checkout-footer --> */}
                <div className="button-page-footer">
                    <ExecutionButton type="save" onClick={() => this.onSave(ranchFeedList)} disabled={this.state.executing} />
                    { state.edit_feeding_id > 0 && (
                        <ExecutionButton type="delete" onClick={this.onDelete} disabled={this.state.executing} />
                    )}
                </div>
                {/* <!-- END checkout-footer --> */}

                {
                    state.popup_remained_feed_open && (
                        <FeedRemainedPopup isOpen={true}
                            feeds_left={state.feeds_left}
                            ranch_id={this.props.rootStore.cur_ranch_id}
                            user_id={this.props.rootStore.user.id}
                            cow_ids={this.getCowIdsStr()}
                            ranch_feeds={ranchFeedList}
                            feed_types={this.props.rootStore.options.feed_type}
                            from_days_before={this.FEED_LEFT_FROM_DAYS_BEFORE}
                            to_days_before={this.FEED_LEFT_TO_DAYS_BEFORE}
                            onClose={() => {
                                this.api_getLeftFeedList();
                                this.setState({ popup_remained_feed_open: false })
                            }}
                            comm={this.comm()}
                        />
                    )
                }
            </div>
        )
    }
}

export default withRouter(withContext(FeedWrite));
