import React, { useState, useEffect } from 'react';
import V3DatePicker from '../../components/parts/v3-date-picker';
import { Table } from "reactstrap";
import { ICalendarEvent } from './ranch-calendar';
import moment from 'moment';
import { CommonUtil } from '../../config/util';
import { EventKind } from '../../config/event-kind';
import { IEventWriteParamCow } from '../../config/history-location-builder';
import { CowToDispInfo, CowsPopup } from '../../components/parts/cows-popup';
import { Checkbox } from '../../components/form/form-checkbox';
import styles from './daily-calendar.module.css';
import { ScheduleForwarder } from './schedule-forwarder';
import { RanchScheduleModifyReq } from '../../api';

interface MyProps {
    date: Date;
    onDateChange: (date:Date) => void;
    events: ICalendarEvent[];
    onClick: (event: ICalendarEvent) => void;
    onStatusChange: (event: ICalendarEvent, completed: boolean) => void;
    refTable: React.RefObject<HTMLDivElement>;
    onForwardSchedule: (req: Omit<RanchScheduleModifyReq, "user_id"|"ranch_id">) => void;
    showQuestionAsync: (text: string, buttons: string[]) => Promise<number|undefined>;
    onCowClick: (data: { cow_id: number, ranch_id: number }) => void;
}

export const DailyCalendar = React.memo((props: MyProps) => {
    const [ events, setEvents ] = useState<ICalendarEvent[]>([]);

    useEffect(() => {
        const thisDaySt = moment(props.date).startOf("day");
        const thisDayEd = moment(props.date).endOf("day");
        const thisDayEvents = props.events.filter(e => {
            const st = e.allDay ? moment(e.start).startOf("day") : moment(e.start);
            const ed = e.allDay ? moment(e.end).endOf("day") : moment(e.end);
            return thisDayEd.isSameOrAfter(st) && thisDaySt.isSameOrBefore(ed);
        });

        //先に種別でソートしておく
        let sorted = CommonUtil.orderBy(thisDayEvents, e => e.eventKindNo);

        //つぎに時系列にソート
        sorted = CommonUtil.orderBy(sorted, e => {
            //前日以前開始のイベントは開始日順
            const stDay = moment(e.start).startOf("day");
            if (thisDaySt.isAfter(stDay)) return stDay.diff(thisDaySt, "days");
            
            //次に終日イベント
            if (e.allDay) return 0;

            //開始時刻順
            return e.start.getTime();
        });

        setEvents(sorted);

    }, [ props.events, props.date ]);

    const renderTime = (event: ICalendarEvent) => {
        const stDay = moment(event.start).startOf("day");
        const edDay = moment(event.end).startOf("day");
        if (!stDay.isSame(edDay)) {
            return `${stDay.format("M/D")}-${edDay.format("M/D")}`;
        }
        if (event.allDay) return "終日";
        return `${moment(event.start).format("HH:mm")}-${moment(event.end).format("HH:mm")}`;
    }
    const renderCows = (ranchId: number|undefined, cows: IEventWriteParamCow[]) => {
        if (cows.length === 0 || ranchId == null) return <></>;

        if (cows.length === 1) return <span>{CowToDispInfo(cows[0], true)}</span>

        const txt = CowToDispInfo(cows[0], false) + " 他";
        return <span>
            {txt}
            <span className="p-2 d-print-none" onClick={e => e.stopPropagation()}>
                <CowsPopup cows={cows} placement="top" height="22px" onCowClick={c => props.onCowClick({ ranch_id:ranchId, cow_id:c.cow_id })} />
            </span>
        </span>
    }

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <V3DatePicker
                    style={{ justifyContent: "center" }}
                    name="caldate"
                    popperPlacement="bottom"
                    value={props.date}
                    onChange={e => props.onDateChange(e.target.value)}
                    arrowLStyle={{ margin: "0 8px", padding: "10px 8px 10px 18px" }}
                    arrowRStyle={{ margin: "0 8px", padding: "10px 18px 10px 8px" }}
                />
            </div>
            <Table innerRef={props.refTable} hover>
                <tbody>
                { events.map(ev => (
                    <tr key={ev.id}>
                        <td style={{ width:"120px" }} onClick={() => props.onClick(ev)}>{renderTime(ev)}</td>
                        <td style={{ width:"20px", paddingRight:"0" }}>
                            <Checkbox
                                className={styles["chk-status"]}
                                style={{ display: "inline" }}
                                id={"chkDone_"+ev.id}
                                onChange={e => props.onStatusChange(ev, e.target.checked)}
                                checked={ev.status}
                                label="&nbsp;"
                            />
                        </td>
                        <td style={{ width:"180px" }} className={ev.status ? styles.done : ""} onClick={() => props.onClick(ev)}>
                            {renderCows(ev.ranchId, ev.cows)}
                        </td>
                        <td style={{ width:"800px" }} onClick={() => props.onClick(ev)}>
                            <span className={ev.status ? styles.done : ""}>[{EventKind.find(ev.eventKindNo)?.schedule?.sname ?? ""}]</span>
                            <span className={ev.status ? styles.done : ""}> {ev.rawTitle}</span>
                            { ev.results.length > 0 && (<span className={styles["has-record"]}> (記録済)</span>)}
                        </td>
                        { ev.status ? (
                            <td style={{ width:"1px"}} onClick={() => props.onClick(ev) } />
                        ) : (
                            <td style={{ width:"1px", padding: "2px"}}>
                                <ScheduleForwarder
                                    style={{ padding:"10px" }}
                                    schedule={ev}
                                    showQuestionAsync={props.showQuestionAsync}
                                    onRequest={props.onForwardSchedule}
                                    baseDate={props.date}
                                />
                            </td>
                        )}
                    </tr>
                ))}
                </tbody>
            </Table>
        </div>

    ) 
});