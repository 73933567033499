import { useQuery } from 'react-query';
import { RutLatestReq, RutApi } from '../api';
import moment from 'moment';
import { LMT } from '../config/constant';
import { QUERY } from './fetcher';

const fetchLatestRuts = async (ranchId: number, cowIds: number[], endDay:Date) => {
    const dateStr = moment(endDay).format("YYYY-MM-DD");

    const req: RutLatestReq = {
        ranch_id: ranchId,
        cow_ids: cowIds,
        end_day: dateStr,
        start_day: moment(endDay).subtract(LMT.BREEDING.CROSS_RUT_SEARCH_DAYS, "days").format("YYYY-MM-DD"),
        any_signs_only: 1
    };
    const api = await RutApi();
    const res = await api.getLatestRutsUsingPOST(req)();
    
    if (res.data.meta?.errCode !== 0 || res.data.data == null) {
        console.error(res.data);
        throw new Error(res.data.meta?.errMsg);
    }

    return res.data.data;
}

export const useLatestRut = (ranchId: number, cowId: number, endDay:Date) => {
    const dateStr = moment(endDay).format("YYYY-MM-DD");

    const res = useQuery([QUERY.LATEST_RUT, cowId, dateStr],
                    () => fetchLatestRuts(ranchId, [cowId], endDay),
                    { cacheTime: 5 * 60 * 1000, staleTime: 1 * 60 * 1000 });
    return { ...res, data: res.data == null ? undefined : res.data[0] };
}