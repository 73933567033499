import React from 'react';
import { Treat } from '.';
import styles from './treatbulk.module.css';
import classnames from 'classnames';

export type TempTreatItem = Treat & {
    valid: boolean;
}

export const TempTreatItemRow = React.memo((props: {
    treat: TempTreatItem,
    onEdit: () => void,
    onRemove: () => void,
    className?: string
}) => (
    <div className={ classnames(styles["temp-treat-item-row"], { [styles.invalid]:!props.treat.valid }, props.className) }>
        <span>{props.treat.name}</span>
        { props.treat.amount != null && <span>{props.treat.amount}{props.treat.unit ?? ""}</span>}
        { props.treat.route_name != null && <span>{props.treat.route_name}</span>}
        <i className="fas fa-edit clickable" onClick={props.onEdit} />
        <i className="fas fa-times clickable" onClick={props.onRemove} />
    </div>
));