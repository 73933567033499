import React from 'react';
import styles from './fetch-state.module.css';
import classnames from 'classnames';

export const FetchWaiter = React.memo((props: {
    mode?: "default"|"dark",
    size?: "normal"|"small",
}) => {
    return (
        <div className={styles["spinner-container"]}>
            <div className={classnames(styles.spinner, styles[props.mode ?? "default"], styles[props.size ?? "normal"])}>Loading...</div>
        </div>
    );
});

export const FetchError = React.memo(() => {
    return <div className={styles.error}>データ取得に失敗しました。画面を再読み込みしてください。</div>
});
