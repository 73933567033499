import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind'
import { ITreatItem, ITreatKind, IMedicineRoute, IMedicineCategory, IMedicine } from '../../stores';
import styles from './RegisterTreat.module.css'
import { RequiredNumInput, OptionalNumInput } from '../../components/parts/num-input';
import { CommonUtil, FreezedArray } from '../../config/util';
import { LMT, TreatBenefitType, TREAT_BENEFIT_TYPES, TREAT_BENEFIT_TYPE } from '../../config/constant';
import { FormRadio } from '../../components/form/form-radio';
import { Checkbox } from '../../components/form/form-checkbox';
import { CommonSelect } from '../../components/parts/common-select';


let styleCxt = classNames.bind(styles);

export type TreatInputItem = {
    benefit_type: TreatBenefitType,
    treat_kind_no?: number,
    treat_item_no?: number,
    name?: string,
    unit?: string,
    amount?: number,
    unit_price: number,
    new_treat_item_name?: string,
    new_item_unit?: string,
};
export type MedicineInputItem = {
    team_id?: number,
    benefit_type: TreatBenefitType,
    medicine_id?: number,
    route_id?: number,
    name?: string,
    unit?: string,
    amount: number,
    unit_price: number,
    route_name?: string,
    new_medicine_name?: string,
    new_medicine_category?: number,
    new_item_unit?: string,
};
export type OptionalMedicineInputItem = Omit<MedicineInputItem, "amount"> & {
    amount?: number,
}

export interface TreatInputProps {
    input: TreatInputItem;
    treats: FreezedArray<Pick<ITreatItem, "treat_kind_no"|"treat_item_no"|"name"|"fee"|"fee_unit">>;
    treatKinds: FreezedArray<Pick<ITreatKind, "treat_kind_no"|"name">>;
    onChange: (treat: TreatInputItem) => void;
    canEditMaster: boolean;
    /**
     * checkboxのidを区別するための任意の文字列
     */
    idPrefix: string;
    hasBenefit: boolean;
}


interface BaseMedicineInputProps {
    routes: FreezedArray<Pick<IMedicineRoute, "route_id"|"name">>;
    medicineCategories: FreezedArray<Pick<IMedicineCategory, "category"|"name">>;
    ranchMedicines: FreezedArray<Pick<IMedicine,"medicine_id"|"name"|"category"|"default_amount"|"default_route_id"|"team_id"|"unit"|"unit_price"|"default_amount_weight">>;
    /**
     * プリセット設定に使用する場合、不要
     */
    clinicMedicines: FreezedArray<Pick<IMedicine,"medicine_id"|"name"|"category"|"default_amount"|"default_route_id"|"team_id"|"unit"|"unit_price"|"default_amount_weight">>;
    /**
     * radiobuttonのidを区別するための任意の文字列
     */
    idPrefix: string;
    canEditMaster: boolean;
    /**
     * プリセット設定に使用する場合、診療所IDもここで扱う
     */
    ranchId: number;
    /**
     * プリセット設定に使用する場合、不要
     */
    clinicId: number | undefined;
    hasBenefit: boolean;
}
export interface MedicineInputProps extends BaseMedicineInputProps {
    input: MedicineInputItem;
    onChange: (treat: MedicineInputItem) => void;
}
export interface OptionalMedicineInputProps extends BaseMedicineInputProps {
    input: OptionalMedicineInputItem;
    onChange: (treat: OptionalMedicineInputItem) => void;
}

export const isValidMedicine = (input: Omit<MedicineInputItem, "benefit_type">, allowsZeroAmount: boolean) => {
    if (input.team_id == null) return false;
    if (input.medicine_id == null) {
        if (input.new_medicine_name == null || input.new_medicine_category == null || (input.new_item_unit ?? "") === "") {
            return false;
        }
    }
    if (input.route_id == null) return false;
    if (!allowsZeroAmount && input.amount === 0) return false;
    return true;
}
export const isValidTreat = (input: Omit<TreatInputItem, "benefit_type">, allowsZeroAmount: boolean) => {
    if (input.treat_kind_no == null) return false;
    if (input.treat_item_no == null) {
        if (input.new_treat_item_name == null) return false;
        if (input.new_item_unit != null && input.amount == null) return false;
        if (input.new_item_unit == null && input.amount != null) return false;
    }

    if (input.unit != null) {
        if (input.amount == null) return false;
        if (!allowsZeroAmount && input.amount === 0) return false;
    }
    return true;
}

export const TreatInput = (props: TreatInputProps) => {
    const [ currentTreatKind, setCurrentTreatKind ] = useState<number>();
    const [ currentTreats, setCurrentTreats ] = useState<{label: string, value: number}[]>([]);
    const [ hasNewItemAmount, setHasNewItemAmount ] = useState(false);

    useEffect(() => {
        //※未選択を反映してしまうと、内部的に種別だけ選択している状態まで解除されてしまう
        if (props.input.treat_kind_no != null) {
            setCurrentTreatKind(props.input.treat_kind_no);
        }

    }, [ props.input.treat_kind_no ]);

    useEffect(() => {
        const treats: FreezedArray<{ treat_kind_no:number, treat_item_no:number,name:string }>
            = currentTreatKind == null ? props.treats : props.treats.filter(t => t.treat_kind_no === currentTreatKind);
        const forSelect = treats.map(t => ({ label: t.name, value: toTreatSelectValue(t.treat_kind_no, t.treat_item_no) }));
        if (props.input.new_treat_item_name != null) {
            forSelect.push({ label: props.input.new_treat_item_name, value: -1 });
        }
        setCurrentTreats(forSelect);

        //絞込み後のリストに含まれない処置が選択されていればクリア
        if (props.input.treat_item_no != null && currentTreatKind != null) {
            if (!treats.some(t => t.treat_kind_no === props.input.treat_kind_no && t.treat_item_no === props.input.treat_item_no)) {
                props.onChange({
                    benefit_type: props.input.benefit_type,
                    treat_kind_no: undefined,
                    treat_item_no: undefined,
                    amount: undefined,
                    unit: undefined,
                    name: undefined,
                    unit_price: 0
                });
            }
        }
    }, [ props.treats, currentTreatKind, props.input.new_treat_item_name ]);

    useEffect(() => {
        setHasNewItemAmount(props.input.new_item_unit != null);

    }, [ props.input.new_item_unit ])

    const toTreatSelectValue = (treatKindNo: number, treatItemNo: number) => {
        //※treat_item_noに100000以上の値を使わない前提にしてしまう（本来は _ などで繋いで文字列のkeyにするのが望ましいが）
        return treatKindNo * 100000 + treatItemNo;
    }
    const fromTreatSelectValue = (value: number): { treat_kind_no: number, treat_item_no: number} => {
        return { treat_kind_no: Math.floor(value / 100000), treat_item_no: value % 100000 };
    }

    const onCurrentTreatKindChange = (val:number | undefined) => {
        setCurrentTreatKind(val);
        if (props.input.new_treat_item_name != null) {
            props.onChange({
                ...props.input,
                treat_kind_no: val
            })
        }
    }

    const onTreatSelect = (value: number) => {
        if (value === -1) return;
        const { treat_kind_no, treat_item_no } = fromTreatSelectValue(value);
        const item = props.treats.find(t => t.treat_kind_no === treat_kind_no && t.treat_item_no === treat_item_no);
        if (!CommonUtil.assertNotNull(item)) return;

        props.onChange({
            benefit_type: props.input.benefit_type,
            treat_kind_no,
            treat_item_no,
            unit: item.fee_unit ?? undefined,
            amount: undefined,
            name: item.name,
            unit_price: item.fee
        })

    }

    const onNewTreatCreated = (name: string) => {
        props.onChange({
            benefit_type: props.input.benefit_type,
            treat_kind_no: currentTreatKind,
            treat_item_no: undefined,
            name: name,
            new_treat_item_name: name,
            amount: undefined,
            new_item_unit: undefined,
            unit_price: 0,
            unit: undefined
        })
    }

    return (
        <div>
            { props.hasBenefit && (
                <div style={{ textAlign:"right", marginBottom:"6px" }}>
                    <select className="borderless-select"
                        value={props.input.benefit_type}
                        onChange={e => props.onChange({ ...props.input, benefit_type:e.target.value as TreatBenefitType})}
                    >
                        { TREAT_BENEFIT_TYPES.map(t => (
                            <option key={t} value={t}>{TREAT_BENEFIT_TYPE[t].name}</option>
                        ))}
                    </select>
                </div>
            )}
            <div className={ styleCxt('bulk-row') }>
                <label htmlFor={props.idPrefix + "_selTreatKind"}>分類</label>
                <select value={currentTreatKind} onChange={e => onCurrentTreatKindChange(e.target.value === "" ? undefined : Number(e.target.value))}
                    id={props.idPrefix + "_selTreatKind"}>
                    <option value=''>すべての処置</option>
                    { props.treatKinds.map(t => (
                        <option key={t.treat_kind_no} value={t.treat_kind_no}>{t.name}</option>
                    ))}
                </select>
            </div>
            <div className={ styleCxt('bulk-row') }>
                <label htmlFor={props.idPrefix + "_treatInput"}>処置</label>
                <div className={ styleCxt('ac-select') } data-testid={props.idPrefix + "_treat"}>
                    <CommonSelect
                        inputId={props.idPrefix + "_treatInput"}
                        itemName="処置"
                        formatCreateLabel={n => `処置「${n}」を作成`}
                        options={currentTreats}
                        value={
                            (props.canEditMaster && props.input.new_treat_item_name != null) ? -1
                            : (props.input.treat_kind_no == null || props.input.treat_item_no == null) ? undefined
                            : toTreatSelectValue(props.input.treat_kind_no, props.input.treat_item_no)
                        }
                        onCreate={props.canEditMaster ? onNewTreatCreated : undefined}
                        onSelect={onTreatSelect}
                        maxLength={LMT.TREAT.NAME_LEN}
                        optionTestId={props.idPrefix + "_optTr"}
                    />
                </div>
            </div>
            { (props.input.unit != null || props.input.new_treat_item_name != null) && (
                <div className={ styleCxt('bulk-row') }>
                    { props.input.new_treat_item_name != null ? (
                        <Checkbox label="数量"
                            id={"chkTreatAmount_" + props.idPrefix}
                            checked={hasNewItemAmount}
                            onChange={e => {
                                const unit = e.target.checked ? "種" : undefined;
                                props.onChange({ ...props.input, new_item_unit: unit, unit:unit, amount: undefined });
                            }}
                        />
                    ) : (
                        <span>数量</span>
                    )}
                    <OptionalNumInput testId={props.idPrefix + "_amount"}
                        min={LMT.MEDICINE.AMOUNT_MIN} max={LMT.MEDICINE.AMOUNT_MAX} step={LMT.MEDICINE.AMOUNT_STEP}
                        value={props.input.amount}
                        onChange={n => props.onChange({ ...props.input, amount: n })}
                        disabled={props.input.new_treat_item_name != null && !hasNewItemAmount}
                    />
                    { props.input.new_treat_item_name != null ? (
                        <input type="text"
                            disabled={props.input.new_treat_item_name != null && !hasNewItemAmount}
                            maxLength={LMT.TREAT.UNIT_LEN}
                            className="form-control"
                            style={{ width:"60px", margin:0 }}
                            value={props.input.new_item_unit ?? ""} placeholder="単位"
                            onChange={e => props.onChange({ ...props.input, new_item_unit: e.target.value, unit:e.target.value })}
                        />
                    ) : (
                        <span>{props.input.unit}</span>
                    )}
                </div>
            )}
        </div>
    )
}

const TEAM_RANCH = 1;
const TEAM_CLINIC = 2;
type TEAM = typeof TEAM_RANCH | typeof TEAM_CLINIC;

export const OptionalMedicineInput = (props: OptionalMedicineInputProps) => {

    return <MedicineInputCore {...props} isAmountRequired={false} />
}

export const MedicineInput = (props: MedicineInputProps) => {
    return (
        <MedicineInputCore {...props}
            isAmountRequired={true}
            onChange={data => props.onChange({ ...data, amount: data.amount ?? 0 })}
        />
    )
}

const MedicineInputCore = (props: OptionalMedicineInputProps & {
    isAmountRequired: boolean,
}) => {
    const [ currentCategory, setCurrentCategory ] = useState<number>();
    const [ currentMedicines, setCurrentMedicines ] = useState<{label: string, value: number}[]>([]);
    const [ currentTeamKind, setCurrentTeamKind ] = useState<TEAM>();

    useEffect(() => {
        if (props.clinicId == null) {
            if (currentTeamKind !== TEAM_RANCH) {
                setCurrentTeamKind(TEAM_RANCH);
            }
        } else {
            if (props.ranchMedicines.length === 0 && props.clinicMedicines.length > 0 && currentTeamKind !== TEAM_CLINIC) {
                setCurrentTeamKind(TEAM_CLINIC);
            } else if (props.ranchMedicines.length > 0 && props.clinicMedicines.length === 0 && currentTeamKind !== TEAM_RANCH) {
                setCurrentTeamKind(TEAM_RANCH);
            } else if (props.input.team_id != null) {
                const team = props.input.team_id === props.clinicId ? TEAM_CLINIC : props.input.team_id === props.ranchId ? TEAM_RANCH : undefined;
                setCurrentTeamKind(team);

            } else if (currentTeamKind == null) {
                setCurrentTeamKind(TEAM_CLINIC);
            }
        }
    }, [ props.ranchMedicines, props.clinicMedicines, props.clinicId, props.ranchId, props.input.team_id ]);

    useEffect(() => {
        if (props.input.new_medicine_name != null) {
            setCurrentCategory(props.input.new_medicine_category);
        }

    }, [ props.input ])

    useEffect(() => {
        //初期化中
        if (currentTeamKind == null) return;

        const list = currentTeamKind === TEAM_RANCH ? props.ranchMedicines : props.clinicMedicines;
        const medicines = currentCategory == null ? list : list.filter(m => m.category === currentCategory);
        setCurrentMedicines(medicines.map(m => ({ label: m.name, value: m.medicine_id })));

        //絞込み後のリストに含まれない薬が選択されていればクリア
        if (props.input.medicine_id != null) {
            if (!medicines.some(m =>　m.team_id === props.input.team_id && m.medicine_id === props.input.medicine_id)) {
                props.onChange({
                    ...props.input,
                    team_id: undefined,
                    medicine_id: undefined,
                    name: undefined,
                    unit: undefined,
                    unit_price: 0
                });
            }
        } else if (props.input.new_medicine_name != null && props.input.team_id != null) {
            const newTeamId = currentTeamKind === TEAM_RANCH ? props.ranchId : props.clinicId;
            if (props.input.team_id !== newTeamId) {
                props.onChange({
                    ...props.input,
                    team_id: undefined,
                    new_medicine_name: undefined,
                    new_medicine_category: undefined,
                    new_item_unit: undefined,
                    unit: undefined,
                    name: undefined,
                });
            }

        }

    }, [ props.ranchMedicines, props.clinicMedicines, currentCategory, currentTeamKind ]);

    const onMedicineSelect = (value:number) => {
        if (value === -1) return;

        const list = currentTeamKind === TEAM_RANCH ? props.ranchMedicines : props.clinicMedicines;
        const item = list.find(m => m.medicine_id === value);
        if (!CommonUtil.assertNotNull(item)) return;

        const amount = (props.input.amount ?? 0) > 0 ? props.input.amount
                     : !props.isAmountRequired ? undefined
                     //※体重ごとの初期値には対応しない
                     : (item.default_amount != null && item.default_amount_weight == null) ? item.default_amount
                     : 0;
        let routeId = props.input.route_id != null ? props.input.route_id
                    : item.default_route_id;
        if (routeId != null && !props.routes.some(r => r.route_id === routeId)) {
            routeId = undefined;
        }
        const routeName = routeId == null ? undefined : props.routes.find(r => r.route_id === routeId)?.name;

        props.onChange({
            team_id: item.team_id,
            benefit_type: props.input.benefit_type,
            medicine_id: item.medicine_id,
            unit: item.unit,
            name: item.name,
            unit_price: item.unit_price,
            amount,
            route_id: routeId,
            route_name: routeName,
            new_item_unit: undefined,
            new_medicine_category: undefined,
            new_medicine_name: undefined,
        })
    }
    const onMedicineCreate = (name: string) => {
        if (!CommonUtil.assertNotNull(currentTeamId, "currentTeamId")) return;

        props.onChange({
            team_id: currentTeamId,
            benefit_type: props.input.benefit_type,
            medicine_id: undefined,
            unit: "ml",
            new_item_unit:"ml",
            new_medicine_category: currentCategory,
            new_medicine_name:name,
            amount: props.isAmountRequired ? 0 : undefined,
            unit_price:0,
            name: name,
            route_id: props.input.route_id,
            route_name: props.input.route_name
        })
    }

    const onRouteSelect = (value:number|undefined) => {
        if (value == null) {
            props.onChange({
                ...props.input,
                route_id: undefined,
                route_name: undefined
            });
            return;
        }

        const route = props.routes.find(r => r.route_id === value);
        if (!CommonUtil.assertNotNull(route)) return;
        props.onChange({
            ...props.input,
            route_id: route.route_id,
            route_name: route.name
        })
    }

    const isDoctor = props.clinicId != null;
    const isMyTeam = currentTeamKind == null ? false
                    : isDoctor ? (currentTeamKind === TEAM_CLINIC) : (currentTeamKind === TEAM_RANCH);
    const currentTeamId = currentTeamKind == null ? undefined : currentTeamKind === TEAM_CLINIC ? props.clinicId : props.ranchId;
    const canCreateNew = props.canEditMaster && isMyTeam && currentTeamId != null;

    return (
        <div>
            { props.hasBenefit && (
                <div style={{ textAlign:"right", marginBottom:"6px" }}>
                    <select className="borderless-select"
                        value={props.input.benefit_type}
                        onChange={e => props.onChange({ ...props.input, benefit_type:e.target.value as TreatBenefitType})}
                    >
                        { TREAT_BENEFIT_TYPES.map(t => (
                            <option key={t} value={t}>{TREAT_BENEFIT_TYPE[t].name}</option>
                        ))}
                    </select>
                </div>
            )}
            { props.ranchMedicines.length > 0 && props.clinicMedicines.length > 0 && (
                <div className={ styleCxt('bulk-row') }>
                    <span style={{ width:"40px"}} />
                    <FormRadio isInline={false}
                        prefix={props.idPrefix + "_teamkind"}
                        value={currentTeamKind}
                        options={[ { name: "診療所の薬品", value:TEAM_CLINIC }, { name: "牧場の薬品", value:TEAM_RANCH } ]}
                        onChange={val => setCurrentTeamKind(val as TEAM)}
                    />
                </div>
            )} 
            <div className={ styleCxt('bulk-row') }>
                <label htmlFor={props.idPrefix + "_selCategory"}>分類</label>
                <select id={props.idPrefix + "_selCategory"} value={currentCategory} onChange={e => {
                    const no = e.target.value === "" ? undefined : Number(e.target.value);
                    if (props.input.new_medicine_name != null) {
                        props.onChange({ ...props.input, new_medicine_category: no })
                    } else {
                        setCurrentCategory(no);
                    }
                }}>
                    <option value=''>すべての薬</option>
                    { props.medicineCategories.map(m => (
                        <option key={m.category} value={m.category}>{m.name}</option>
                    ))}
                </select>
            </div>
            <div className={ styleCxt('bulk-row') }>
                <label htmlFor={props.idPrefix + "_medicineInput"}>薬品</label>
                <div className={ styleCxt('ac-select') } data-testid={props.idPrefix + "_medicine"}>
                    <CommonSelect
                        inputId={props.idPrefix + "_medicineInput"}
                        options={[
                            ...currentMedicines,
                            ...((canCreateNew && props.input.new_medicine_name != null) ? [{ label:props.input.new_medicine_name, value:-1 }] : [])
                        ]}
                        value={(canCreateNew && props.input.new_medicine_name != null) ? -1 : props.input.medicine_id}
                        formatCreateLabel={n => `薬品「${n}」を作成`}
                        itemName="薬品"
                        onSelect={onMedicineSelect}
                        onCreate={canCreateNew ? onMedicineCreate : undefined}
                        maxLength={LMT.MEDICINE.NAME_LEN}
                        optionTestId={props.idPrefix + "_optMed"}
                    />
                </div>
            </div>
            <div className={ styleCxt('bulk-row') }>
                <span>数量</span>
                { props.isAmountRequired ? (
                    <RequiredNumInput testId={props.idPrefix + "_amount"}
                        min={LMT.MEDICINE.AMOUNT_MIN} max={LMT.MEDICINE.AMOUNT_MAX} step={LMT.MEDICINE.AMOUNT_STEP}
                        value={props.input.amount ?? 0}
                        onChange={m => props.onChange({ ...props.input, amount: m })}
                    />
                ) : (
                    <OptionalNumInput testId={props.idPrefix + "_amount"}
                        min={LMT.MEDICINE.AMOUNT_MIN} max={LMT.MEDICINE.AMOUNT_MAX} step={LMT.MEDICINE.AMOUNT_STEP}
                        value={props.input.amount}
                        onChange={m => props.onChange({ ...props.input, amount: m })}
                    />
                )}
                { props.input.new_medicine_name == null ? (
                    <span>{props.input.unit ?? ""}</span>
                ) : (
                    <input className="form-control" type="text"
                        style={{ width:"60px", margin:0 }}
                        placeholder="単位" maxLength={LMT.MEDICINE.UNIT_LEN}
                        value={props.input.new_item_unit ?? ""}
                        onChange={e => props.onChange({ ...props.input, new_item_unit:e.target.value, unit:e.target.value })}
                    />
                )}                
                <select style={{ width:"130px" }} data-testid={props.idPrefix + "_selRoute"}
                    value={props.input.route_id ?? ""}
                    onChange={e => onRouteSelect(e.target.value === "" ? undefined : Number(e.target.value)) }>
                    <option value=''>選択</option>
                    { props.routes.map(r => (
                        <option key={r.route_id} value={r.route_id}>{r.name}</option>
                    ))}
                </select>
            </div>
        </div>
    );
}