import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import styles from '../setting/seed-management-stock-popup.module.css';
import { CommonUtil, FreezedArray, ar } from '../../config/util';
import { RanchTagDto } from '../../api/index';
import { Checkbox } from '../../components/form/form-checkbox';
import { EditingTag } from '../../components/tag-edit-popup/tag-edit-popup';
import { listLabels } from '../setting/seed-label-range-input';
import { SeedTagPopup } from '../setting/seed-tag-popup';
import { findEggRank, findEggStage } from '../../config/egg-kind';

type Stock = { label_no:string, tags: EditingTag[] };

interface MyProps {
    rank: number;
    stage: number;
    isFrozen: boolean;
    egg_count: number;
    labelFrom: string;
    taggedStocks: FreezedArray<Stock>;
    onClose: ()=>void;
    onSubmit: (taggedStocks: FreezedArray<Stock>) => void;
    allTags: FreezedArray<RanchTagDto>;
    unsubmittedTagList: Readonly<string[]>;
    //※未使用だが、tagFiltering を指定すればタグでの絞込みもできるようにしておく
    listMode: "tagFiltering"|"selectAll";
}

export const EggStockTagModal = (props:MyProps) => {
    const [ stocks, setStocks ] = useState<Stock[]>([]);
    const [ selectedLabels, setSelectedLabels ] = useState<Set<string>>(new Set());
    const [ filteringTags, setFilteringTags ] = useState<string[]>([]);
    const [ dispList, setDispList ] = useState<Stock[]>([]);
    const [ tagging, setTagging ] = useState<{
        stocks: { label_no:string, tags: RanchTagDto[] }[],
        dummyTagIdMap: Map<string, number>,
        tagsDummyContained: FreezedArray<RanchTagDto>
    }>();

    useEffect(() => {
        const labels = listLabels(props.labelFrom, props.egg_count);
        const list = labels.map(l => ({ label_no:l, tags: props.taggedStocks.find(s => s.label_no === l)?.tags ?? [] }));
        setStocks(list);
        setSelectedLabels(new Set(labels));
        setFilteringTags([]);
        setDispList(list);

    },[ props.labelFrom, props.egg_count, props.taggedStocks ]);

    useEffect(() => {
        if (filteringTags.length === 0) {
            setDispList(stocks);
        } else {
            const newDispList = stocks.filter(s => filteringTags.every(t => s.tags.some(st => st.tag_name === t)));
            setDispList(newDispList);
            setSelectedLabels(new Set(
                [...selectedLabels].filter(l => newDispList.some(s => s.label_no === l))
            ));
        }

    }, [ stocks, filteringTags]);

    const onRowCheck = (label: string, checked: boolean) => {
        const newSet = new Set(selectedLabels);
        if (checked) {
            newSet.add(label);
        } else {
            newSet.delete(label);
        }
        setSelectedLabels(newSet);
    }
    const onRowClick = (label:string) => {
        setSelectedLabels(new Set([label]));
    }

    const onTagEdit = () => {
        //編集モーダルに渡す際に、未確定のタグにはダミーのIDを採番しておく
        const tmpTags = ar.distinct([
            ...ar.flat(stocks.map(s => s.tags.filter(tg => tg.tag_id == null).map(tg => tg.tag_name))),
            ...props.unsubmittedTagList
        ]);
        const idMap = new Map<string, number>();
        let id = 100000;    //※これ以上の番号は採番されない前提
        tmpTags.forEach(t => idMap.set(t, id++));

        const targets = stocks.filter(s => selectedLabels.has(s.label_no));

        const list = targets.map(s => ({
            label_no:s.label_no,
            tags:s.tags.map(t => ({ tag_name:t.tag_name, tag_id:t.tag_id ?? idMap.get(t.tag_name) ?? 0 }))
        }));

        setTagging({
            stocks: list,
            dummyTagIdMap: idMap,
            tagsDummyContained: [
                ...props.allTags,
                ...[...idMap.entries()].map(([tag_name,tag_id]) => ({ tag_id, tag_name }))
            ]
        });
    }

    const onTagSubmit = (changedStocks: Stock[]) => {
        if (!CommonUtil.assertNotNull(tagging, "tagging")) return;

        setStocks(preStocks => preStocks.map(st => {
            const changed = changedStocks.find(ch => ch.label_no === st.label_no);
            if (changed == null) return st;

            return {
                label_no: st.label_no,
                tags: changed.tags.map(tg => ({
                    tag_name:tg.tag_name,
                    //ダミーのIDを振ったものはIDを削除
                    tag_id: tagging.dummyTagIdMap.get(tg.tag_name) != null ? undefined : tg.tag_id
                }))
            };
        }));

        setTagging(undefined);
    }

    const onSubmit = () => {
        props.onSubmit(stocks.filter(s => s.tags.length > 0));
    }
    
    const buildTitle = () => {
        const rank = findEggRank(props.rank)?.name ?? "";
        const stage = findEggStage(props.stage)?.name ?? "";
        return `${rank} ${stage}（${props.isFrozen ? "凍結" : "新鮮" }）`;
    }

    return (
        <div>
            <Modal isOpen={true} centered={true}>
                <ModalHeader toggle={onSubmit} style={{ wordBreak:"break-all" }}>{buildTitle()}</ModalHeader>
                <ModalBody style={{ overflowY: "auto", display:"flex", flexFlow:"column nowrap", height: "calc(100vh - 240px)" }}>
                    <div className={styles["stock-top"]}>
                        { props.listMode === "tagFiltering" && (
                            <div className={styles.tags}>
                                <div style={{ visibility: filteringTags.length === 0 ? "hidden" : "visible" }}>絞込み：</div>
                                { filteringTags.map((tg,i) => (
                                    <div key={i} className={styles.tag}>
                                        <span>{tg}</span>
                                        <i className="fas fa-times clickable p-2"
                                            onClick={() => setFilteringTags(filteringTags.filter(t => t !== tg))}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                        { props.listMode === "selectAll" && (
                            <Checkbox label="全選択" style={{ marginLeft:"12px" }}
                                onChange={e => setSelectedLabels(new Set(e.target.checked ? dispList.map(s => s.label_no) : []))}
                                checked={dispList.length > 0 && dispList.every(s => selectedLabels.has(s.label_no))}
                                id="chkAllStocks"
                            />
                        )}
                        { selectedLabels.size > 0 && (
                            <div style={{ fontSize:"0.75rem", marginTop:"8px" }}>{selectedLabels.size}個 選択中</div>
                        )}
                    </div>
                    <div className={styles["stock-table-container"]}>
                    { dispList.length > 0 && (
                        <table className={styles["stock"]}>
                            <tbody>
                                { dispList.map((stock, i) => (
                                    <tr key={i} className={selectedLabels.has(stock.label_no) ? styles.selected : "clickable"}>
                                        <td className={styles["td-check"]}>
                                            <Checkbox className={styles["checkbox"]} id={"chkStock_" + i} checked={selectedLabels.has(stock.label_no)}
                                                onChange={e => onRowCheck(stock.label_no, e.target.checked)}
                                                label="&nbsp;" />
                                        </td>
                                        <td className={styles["td-label"]} style={{ wordBreak:"break-all" }} onClick={() => onRowClick(stock.label_no)}>
                                            {stock.label_no}
                                        </td>
                                        <td className={styles["td-tag"]} onClick={() => onRowClick(stock.label_no)}>
                                            <div className={styles["tag-container"]}>
                                                { stock.tags.map(t => (
                                                    <div key={t.tag_id} className={styles.tag}
                                                        onClick={e => {
                                                            if (props.listMode !== "tagFiltering") return;
                                                            e.stopPropagation();
                                                            if (!filteringTags.includes(t.tag_name)) {
                                                                setFilteringTags([...filteringTags, t.tag_name ])
                                                            }
                                                        }}
                                                    >{t.tag_name}</div>
                                                ))}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                    </div>
                    <div style={{ textAlign:"center" }}>
                        <button className="btn btn-orange" disabled={selectedLabels.size === 0} onClick={() => onTagEdit()}>タグを選択</button>
                    </div>

                </ModalBody>
            </Modal>
            { tagging != null && (
                <SeedTagPopup
                    allTags={tagging.tagsDummyContained}
                    stocks={tagging.stocks}
                    isExecuting={false}
                    onClose={() => setTagging(undefined)}
                    onSubmit={stocks => onTagSubmit(stocks)}
                    submitType="確定"
                />
            )}
        </div>
    )
}