import React, { useEffect, useState } from 'react';
import moment from 'moment'
import classNames from 'classnames/bind'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import { CowApi, CowListDto, FeedApi } from '../../api'

import { useRootStore, IRanchFeed, IRanchHouse } from '../../stores';

import { Cow, Feed } from './index'

import EditModal from './modal/Edit'
import NoticeModal from './modal/Notice'

import baseStyles from './feedbulk.module.css'
import styles from './RegisterFeed.module.css'
import PrevNextDatePicker from '../../components/parts/prev-next-date-picker';
import { HourSpanSelector, HourSpan } from '../../components/parts/hour-span-selector';
import { usePageStore } from '../../config/page-settings';
import { ar, FreezedArray } from '../../config/util';
import { AppState } from '../../app';
import { Communicator } from '../../api/communicator';
import { RequiredNumInput } from '../../components/parts/num-input';
import { LMT } from '../../config/constant';
import { BulkCowRow } from './BulkCowRow';
import { SortType, SortOrder, BulkCowSortIcon } from './BulkCowSortIcon';
import { BulkCowNoticeIcon } from './BulkCowNoticeIcon';

let styleCxt = classNames.bind(styles);

type FeedAmount = {
  id         : number,
  name       : string,
  type       : number,
  amount     : number,
  unit       : string,
  unit_price : number,
}
type FeedTypeError = boolean
type FeedAmountError = boolean

const CowRow: React.FC<{
  cow           : Cow,
  toggleSelect  : (event: React.ChangeEvent<HTMLInputElement>, cow: Cow) => void,
  openEditModal : (cow : Cow) => void,
}> = ({ cow, toggleSelect, openEditModal }) => {

  const toRowData = (cow: Cow) => (<>
    {
      cow.feeds.map(feed => (
        <div key={ feed.id } className={ styleCxt('feed-row') } data-testid="えさ項目">
          <span data-testid={ `えさ種類:${feed.name}` }>{ feed.name }</span>
          <span data-testid={ `えさ量:${feed.amount}${feed.unit}` }>{ `(${feed.amount}${feed.unit})` }</span>
        </div>
      ))
    }
  </>)

  return <BulkCowRow cow={cow} toggleSelect={toggleSelect} openEditModal={openEditModal} showsOvereat={true} toRowData={toRowData} />
}

type PropType = {
  ranchId : number,
  date    : Date,
  allCows : Array<CowListDto>,
  cows    : Array<Cow>,
  setCows : React.Dispatch<React.SetStateAction<Array<Cow>>>,
  ranchFeeds: FreezedArray<IRanchFeed>,
  ranchHouses: FreezedArray<IRanchHouse>
}

type TabIndex = "1" | "2";

const RegisterFeed: React.FC<PropType> = ({ ranchId, date, allCows, cows, setCows, ranchFeeds, ranchHouses }) => {
  const { user, options: { feed_type }, logout } = useRootStore()
  const rootStore = useRootStore()
  const { handleSetIsLoading, showToast, showQuestion, showDialog } = usePageStore() as AppState;

  const [feedBulk, setFeedBulk] = useState<FeedAmount>({ id: -1, name: '', type: -1, unit: '', amount: 0, unit_price:0 });
  const [feedTypeErr, setFeedTypeErr] = useState<FeedTypeError>(true);
  const [feedAmountErr, setFeedAmountErr] = useState<FeedAmountError>(false);
  const [sortType, setSortType] = useState<SortType>('Trace');
  const [sortOrder, setSortOrder] = useState<SortOrder>('Asc');
  const [editCow, setEditCow] = useState<Cow>({
    id: -1, name: '', house: [0,0,0], birthday: 0, repId: '', earTag: false, selected : true, feeds : [],
    attrs : { moved : false, watched : false, overeat : false, watched_memo: "" }
  });

  const [showEditModal, setShowEditModal] = useState(false)
  const [showNoticeModal, setShowNoticeModal] = useState(false)
  const [activeTab, setActiveTab] = useState<TabIndex>("1")
  const [hourSpan, setHourSpan] = useState<HourSpan>({ from: 0, to: 0 })
  const [hisDate, setHisDate] = useState<Date>(new Date())

  const comm = new Communicator({ logout, showToast, showDialog });

  useEffect(() => {
    const span = getDefaultTimeSpan(moment(date));
    setHourSpan(span.span)
    setHisDate(moment(date).add(span.isNextDay ? -2 : -1, "days").toDate())

  }, [])

  const toggleTab = (tab: TabIndex) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  const getDefaultTimeSpan = (baseDate: moment.Moment): { span: HourSpan, isNextDay: boolean } => {

    const spans = [
      { from: 4, to: 8 },
      { from: 8, to: 12 },
      { from: 12, to: 16 },
      { from: 16, to: 19 },
      { from: 19, to: 22 },
    ];

    const hour = baseDate.hour();
    const span = spans.find(s => s.from <= hour && hour < s.to);
    if (span != null) return { span, isNextDay: false };
    const isNextDay = hour < spans[0].from;
    return { span: { from: 22, to: 28 }, isNextDay };
  }

  const fetchCows = async () => {

    const hourSpan = getDefaultTimeSpan(moment(date))
    const feedRes = await comm.send((await FeedApi()).getRecentTimeSpanUsingPOST({
      ranch_id: ranchId,
      day_from: moment(date).add(hourSpan.isNextDay ? -11 : -10, "days").format("YYYY-MM-DD"),
      day_to: moment(date).add(hourSpan.isNextDay ? -2 : -1, "days").format("YYYY-MM-DD"),
      time_from: ("00" + hourSpan.span.from).slice(-2) + ":00",
      time_to: ("00" + hourSpan.span.to).slice(-2) + ":00",
      cow_ids: allCows.map(c => c.cow_id)
    }));
    if(feedRes.result !== "OK") {
      return
    }

    const overeatRes = await comm.send((await FeedApi()).getRecordedCowIdUsingPOST({
      user_id: user.id,
      ranch_id: ranchId,
      date: moment(date).format("YYYY-MM-DD HH:mm"),
      cow_id: allCows.map(c => c.cow_id),
    }));
    if(overeatRes.result !== "OK") {
      return
    }

    const movedLimit = moment(date).add(-1,'days').startOf('day')

    const appending = (allCows)
      .sort((a, b) => parseInt(a.rep_no ?? '0') - parseInt(b.rep_no ?? '0'))
      .map((r, i) => {
        let feedList : Array<Feed> = []

        for(let prev of feedRes.data?.find(f => f.cow_id === r.cow_id)?.amounts ?? []) {
          const feed = ranchFeeds.find(f => f.feed_no === prev.feed_no)
          if ( feed ) {
            feedList.push({ id: feed.feed_no, name: feed.name, type: feed.feed_type_no, unit: feed.unit, price:feed.unit_price, amount: prev.delivered ?? 0 })
          }
        }

        return {
          id       : r.cow_id ?? 0,
          name     : r.name ?? '',
          house    : [r.site ?? 0, r.barn ?? 0, r.room ?? 0],
          birthday : moment(r.birthday ?? 0).toDate().getTime(),
          repId    : r.rep_no ?? '',
          earTag   : (r.local_no ?? '') !== '',
          selected : true,
          feeds    : feedList,
          attrs    : {
            moved : moment(r.moved_at).isSameOrAfter(movedLimit) || false,
            watched : (r.watching ?? 0) > 0,
            watched_memo: r.watching_memo,
            overeat : overeatRes.data?.cow_id ? overeatRes.data.cow_id.some(o => o === r.cow_id) : false,
          },
        }
      }) || []

    setCows(appending)
  }

  useEffect(() => {
    setCows([])
    fetchCows()
  }, [allCows, date])


  const selectFeed = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const no = parseInt((event.target as HTMLSelectElement).value)
    const feed = ranchFeeds.find(f => f.feed_no === no)
    if ( feed ) {
      setFeedBulk(prevState => ({ id: feed.feed_no, name: feed.name, type: feed.feed_type_no, unit: feed.unit, amount: 0, unit_price:feed.unit_price }))
      setFeedTypeErr(false)
    } else {
      setFeedBulk(prevState => ({ ...prevState, id: -1, name: '' }))
      setFeedTypeErr(true)
    }
  }

  const changeAmount = (val: number) => {
    if ( !Number.isNaN(val) ) {
      setFeedBulk(prevState => ({ ...prevState,  amount: val }))
      setFeedAmountErr(false)
    } else {
      setFeedBulk(prevState => ({ ...prevState,  amount: NaN }))
      setFeedAmountErr(true)
    }
  }

  const applyFeed = () => {
    if ( !(feedBulk.id > 0 && feedBulk.amount >= 0) ) return
    setCows(
      cows.map(cow => {
        if (!cow.selected) { return cow  }
        const feeds = [...cow.feeds.filter(f => f.id !== feedBulk.id)]
        if (feedBulk.amount !== 0) {
          feeds.push({id : feedBulk.id, name: feedBulk.name, type: feedBulk.type, amount: feedBulk.amount, unit: feedBulk.unit, price:feedBulk.unit_price })
        }
        return { ...cow, feeds: feeds }
      })
    )
  }

  const applyHistory = async () => {

    const cowIds = cows.filter(c => c.selected).map(c => c.id);
    if (cowIds.length === 0) return;

    const dayStr = moment(hisDate).format("YYYY-MM-DD");

    handleSetIsLoading(true);
    const res = await comm.send((await FeedApi()).getRecentTimeSpanUsingPOST({
      ranch_id: ranchId,
      day_from: dayStr,
      day_to: dayStr,
      time_from: ("00" + hourSpan.from).slice(-2) + ":00",
      time_to: ("00" + hourSpan.to).slice(-2) + ":00",
      cow_ids: cowIds
    }));
    handleSetIsLoading(false);
    if (res.result !== "OK") return;

    const list = res.data ?? [];

    console.log("amounts", list);

    setCows(
      cows.map(cow => {
        if (!cow.selected) return cow;

        const feeds = (list.find(d => d.cow_id === cow.id)?.amounts ?? [])
          .map(amount => {
            const feed = ranchFeeds.find(f => f.feed_no === amount.feed_no);
            if (feed == null) return null;

            return ({
              amount: amount.delivered,
              name: feed.name,
              type: amount.feed_type_no,
              unit: feed.unit,
              id: amount.feed_no,
              price: feed.unit_price
            });
          });
        
        return { ...cow, feeds: ar.notNull(feeds) };
      })
    )
  }

  const onClear = () => {
    showQuestion("選択された牛のえさ情報がクリアされます", res => {
      if (res !== 0) return;

      setCows(
        cows.map(cow => {
          if (!cow.selected) return cow;

          return ({ ...cow, feeds: [] });
        })
      )


    }, [ "実行", "キャンセル" ]);
  }

  const setWatch = async (cow : Cow, watching: boolean, memo: string) => {
    const res = await comm.send((await CowApi()).modifyCowWatchingUsingPOST({
      cow_id: cow.id, ranch_id: ranchId, watching : watching ? 1 : 0, watching_memo: memo,
    }));
    if (res.result !== "OK") return;

    rootStore.fetchActiveCows(ranchId, "DIFF")
    
    setCows(cows => cows.map(c => c.id === cow.id ? { ...cow, attrs: { ...cow.attrs, watched : watching, watched_memo: memo } } : c))
  }

  const openEdit = (cow : Cow) => {
    setEditCow(cow)
    setShowEditModal(true)
  }

  const toggleAllSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCows(cows.map(c => ({ ...c, selected: (event.target as HTMLInputElement).checked })))
  }

  const toggleSelect = (event: React.ChangeEvent<HTMLInputElement>, cow: Cow) => {
    setCows(cows.map(c => c.id === cow.id ? { ...c, selected: (event.target as HTMLInputElement).checked } : c))
  }

  const getHouseName = (house: Array<number>) => {
    const first = ranchHouses.find(h => h.no === house[0])
    if (!first || !first.data) return ''
    const second = first.data.find(h => h.no === house[1])
    if (!second || !second.data) return first.name
    const third = second.data.find(h => h.no === house[2])
    if (!third) return `${first.name} ${second.name}`
    return  `${first.name} ${second.name} ${third.name}`
  }


  return (
    <>
      <section className={ styleCxt('section', 'section-padding') }>
        <p className={ styleCxt('date') }>
          <span>餌やり日時</span> { moment(date).format('YYYY/MM/DD HH:mm') }
        </p>
      </section>

      <section className={ styleCxt('section', 'no-padding') }>
        <Nav tabs className={ styleCxt('nav-container') }>
          <NavItem>
            <NavLink className={classNames({ active: activeTab === "1", [styles.active]:activeTab === "1" }, styles["nav-link"])} onClick={() => toggleTab("1")}>履歴を反映</NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classNames(styles["nav-link"], { active: activeTab === "2", [styles.active]:activeTab === "2" })} onClick={() => toggleTab("2")}>えさを追加</NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className={ styleCxt('tab-content') }>
          <TabPane tabId="1">
            <div className={ styleCxt('bulk-row') }>
              <PrevNextDatePicker onChange={e => setHisDate(e.target.value)} name="bulk-feed-his-date" value={hisDate} popperPlacement="bottom-start"
                dateSelectContainerStyle={{ width:"156px", height:"1.8rem" }} />
              <HourSpanSelector min={0} max={28} value={hourSpan} onChange={val => setHourSpan(val)}
                containerStyle={{ marginLeft: "6px", height: "1.8rem" }} />
            </div>
            <div className={ styleCxt('bulk-row-menu') }>
              <span>この時間帯の記録を</span>
              <button data-testid="履歴流用実行" className={ baseStyles.bluebutton } onClick={ e => applyHistory() }>選択された牛に反映</button>
            </div>
          </TabPane>
          <TabPane tabId="2">
            <div className={ styleCxt('bulk-row') }>
              <select data-testid="一括えさ種類" className={ styleCxt({'select-error': feedTypeErr})} value={ feedBulk.id } onChange={ e => selectFeed(e) }>
                <option value='' key="default"></option>
                { ranchFeeds.map(f => <option key={ `${f.ranch_id}---${f.feed_no}`} value={ f.feed_no }>{ f.name }</option>) }
              </select>
              を
              <RequiredNumInput testId="一括えさ量" className={ styleCxt({'input-error': feedAmountErr})}
                min={LMT.FEEDING.AMOUNT_MIN} max={LMT.FEEDING.AMOUNT_MAX} step={LMT.FEEDING.AMOUNT_STEP}
                value={ feedBulk.amount } onChange={val => changeAmount(val) } />
              { feedBulk.unit }
            </div>
            <div className={ styleCxt('bulk-row-menu') }>
              <button data-testid="一括えさ追加" disabled={ feedTypeErr || feedAmountErr } className={ baseStyles.bluebutton } onClick={ e => applyFeed() }>選択された牛に反映</button>
            </div>
          </TabPane>
        </TabContent>
      </section>

      <div className={ styleCxt('operation-bar') }>
        <div className={ styleCxt('operation-all') }>
          <input id="chkAll" data-testid="牛全選択" type="checkbox" className={ styleCxt('operation-checkbox') } checked={ cows.every(cow => cow.selected) } onChange={ e => toggleAllSelect(e) } />
          <label htmlFor="chkAll">全選択</label>
        </div>
        { cows.some(c => c.attrs.moved || c.attrs.watched || c.attrs.overeat) && (
          <div className={ styleCxt('notice-button-container') }>
            <BulkCowNoticeIcon onClick={ () => setShowNoticeModal(true) } />
          </div>
        )}
        <BulkCowSortIcon order={sortOrder} type={sortType} onChangeOrder={setSortOrder} onChangeType={setSortType} />
      </div>

      <section className={ styleCxt('section') }>
        <div className={ styles.clear }>
          <button className="btn btn-red btn-sm" onClick={ onClear }>入力値をクリア</button>
        </div>
      {(() => {
        if(cows.length === 0)
          return <div className={ baseStyles.loader }>Loading...</div>

        switch(sortType + sortOrder) {
          case 'TraceAsc':
            return cows
              .map(cow => <CowRow key={ cow.id } cow={ cow } toggleSelect={ toggleSelect } openEditModal={ openEdit } />)
          case 'TraceDesc':
            return [...cows]
              .reverse()
              .map(cow => <CowRow key={ cow.id } cow={ cow } toggleSelect={ toggleSelect } openEditModal={ openEdit } />)
          case 'BirthAsc':
            return [...cows]
              .sort((a,b) => a.birthday - b.birthday)
              .map(cow => <CowRow key={ cow.id } cow={ cow } toggleSelect={ toggleSelect } openEditModal={ openEdit } />)
          case 'BirthDesc':
            return [...cows]
              .sort((a,b) => b.birthday - a.birthday)
              .map(cow => <CowRow key={ cow.id } cow={ cow } toggleSelect={ toggleSelect } openEditModal={ openEdit } />)
          case 'HouseAsc': {
            const groups: Map<string, Cow[]> = cows.reduce((map, cow) => map.set(cow.house.join('-'), [...map.get(cow.house.join('-')) || [], cow]), new Map());
            return [...groups.entries()]
              .sort(([houseA, cowsA], [houseB, cowsB]) => cowsA[0].house[0] - cowsB[0].house[0] || cowsA[0].house[1] - cowsB[0].house[1] || cowsA[0].house[2] - cowsB[0].house[2])
              .map(([house, cows], i) =>
                <React.Fragment key={ house }>
                  <p className={ styleCxt('notice-group', { first: i === 0 }) }>{ getHouseName(cows[0].house) }</p>
                  { cows.map(cow => <CowRow key={ cow.id } cow={ cow } toggleSelect={ toggleSelect } openEditModal={ openEdit } />) }
                </React.Fragment>
              )
          }
          case 'HouseDesc': {
            const groups: Map<string, Cow[]> = cows.reduce((map, cow) => map.set(cow.house.join('-'), [...map.get(cow.house.join('-')) || [], cow]), new Map());
            return [...groups.entries()]
              .sort(([houseA, cowsA], [houseB, cowsB]) => cowsB[0].house[0] - cowsA[0].house[0] || cowsB[0].house[1] - cowsA[0].house[1] || cowsB[0].house[2] - cowsA[0].house[2])
              .map(([house, cows], i) =>
                <React.Fragment key={ house }>
                  <p className={ styleCxt('notice-group', { first: i === 0 }) }>{ getHouseName(cows[0].house) }</p>
                  { cows.map(cow => <CowRow key={ cow.id } cow={ cow } toggleSelect={ toggleSelect } openEditModal={ openEdit } />) }
                </React.Fragment>
              )
          }
        }

      })()}
      </section>

      <EditModal
        ranchId={ ranchId }
        showModal={ showEditModal }
        setShowModal={ setShowEditModal }
        cow={ editCow }
        setWatch={ setWatch }
        setCows={ setCows }
      />

      <NoticeModal
        showModal={ showNoticeModal }
        setShowModal={ setShowNoticeModal }
        moved={ cows.filter(c => c.attrs.moved).length }
        watched={ cows.filter(c => c.attrs.watched).length }
        overeat={ cows.filter(c => c.attrs.overeat).length }
      />
    </>
  )
}
export default RegisterFeed