import React from 'react';
import classnames from 'classnames';

interface MyProps {
    total: number;
    l:number;
    onChange: (l: number) => void;
}

export const LrCounter = React.memo((props: MyProps) => {

    const styles: { [key: string]: React.CSSProperties } = {
        container: {
            display: "flex",
            alignItems: "center"
        },
        label: {

        },
        count: {
            fontWeight: "bold",
            color: "#666666",
            margin: "0 4px",
            fontSize: "1rem"
        },
        button: {
            fontSize: "1.5rem",
            padding: "6px",
            margin: "0 2px"
        },
        disabled: {
            color: "lightgray"
        }
    }

    const r = props.total - props.l;
    const canL = props.l < props.total;
    const canR = r < props.total;

    return (
        <div style={styles.container}>
            <div style={styles.label}>左卵巣</div>
            <div style={styles.count}>{props.l}</div>
            <i className={classnames("fas fa-caret-left", {"clickable":canL })} 
                onClick={() => {
                    if (canL) props.onChange(props.l + 1);
                }}
                style={ canL ? styles.button : {...styles.button, ...styles.disabled }} />
            <i className={classnames("fas fa-caret-right", {"clickable":canR })}
                onClick={() => {
                    if (canR) props.onChange(props.l - 1);
                }}
                style={ canR ? styles.button : {...styles.button, ...styles.disabled }} />
            <div style={styles.count}>{r}</div>
            <div style={styles.label}>右卵巣</div>
        </div>
    )
});