import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FeedLastDayDto, FeedModifyMultiReqRecord, FeedModifyReqAmount } from '../../api';
import moment from 'moment';
import { IRanchFeed, IFeedType } from '../../stores';
import styles from './feed-lastday-popup.module.css';
import classnames from 'classnames';
import { FeedLastDayEditPopup, ModalEditingLastDayFeed } from './feed-lastday-edit-popup';
import { buildFeedingDetailStr } from '../cow/cow-event-renderer';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { FreezedArray, CommonUtil } from '../../config/util';
import { useRanchFeeds } from '../../stores/fetcher';
import { FetchWaiter, FetchError } from '../../components/content/fetch-state';

export type FeedLastDayEditingAmount = Omit<FeedModifyReqAmount, "unit_price">;
export type FeedLastDayModified = Omit<FeedModifyMultiReqRecord, "cow_id">;
export type FeedLastDayEditing = Omit<FeedLastDayModified, "feed_amount"|"fed_at"> & {
    feeding_id: number;
    time: string;
    disabled: boolean;
    comment: string;
    feed_amount: FeedLastDayEditingAmount[];
}

export interface FeedLastDayPopupProps {
    ranchId: number;
    feedTypeList: FreezedArray<IFeedType>;
    feeds: FreezedArray<FeedLastDayDto>;
    onClose:()=>void;
    onSubmit:(modified:FeedLastDayModified[])=>void;
    /**
     * 保存先の日付 "YYYY-MM-DD"
     */
    dayToSave:string;
    isSubmitExecuting: boolean;
}

export const FeedLastDayPopup: React.FC<FeedLastDayPopupProps> = (props) => {
    const [ editingList, setEditingList ] = useState<FeedLastDayEditing[]>([]);
    const [ editingFeed, setEditingFeed ] = useState<FeedLastDayEditing>();

    const mFeeds = useRanchFeeds(props.ranchId);

    useEffect(() => {
        const list:FeedLastDayEditing[]
            = props.feeds
                .map(f => (
                {
                    feeding_id: f.feeding_id,
                    feed_amount: f.amounts,
                    time: f.fed_at.substr("yyyy-mm-dd ".length, "hh:mm".length),
                    comment: "",
                    disabled: false
                }));
        setEditingList(list);

    }, [ props.feeds, props.dayToSave ]);

    const onDisableChange = (disable: boolean, idx:number) => {
        const feed = { ...editingList[idx], disabled: disable };
        setEditingList([
            ...editingList.slice(0, idx),
            feed,
            ...editingList.slice(idx + 1)
        ]);
    }

    const showFeedLastDayEditPopup = (idx:number) => {
        setEditingFeed(editingList[idx]);
    }

    const onEdit = (editingFeed:ModalEditingLastDayFeed) => {
        const idx = editingList.findIndex(f => f.feeding_id === editingFeed.feeding_id);
        setEditingList([
            ...editingList.slice(0, idx),
            { ...editingFeed, disabled: false },
            ...editingList.slice(idx + 1)
        ]);
        setEditingFeed(undefined);
    };

    const onSubmit = () => {
        const ranchFeeds = mFeeds.data;
        if (!CommonUtil.assertNotNull(ranchFeeds, "mFeeds")) return;

        const dayStr = props.dayToSave.substr(0, "yyyy-mm-dd".length);
        const modified: FeedLastDayModified[]
            = editingList.filter(f => !f.disabled).map(f => ({
                feed_amount: feedAmount(ranchFeeds, f.feed_amount),
                fed_at: moment(dayStr + " " + f.time).format("YYYY-MM-DD HH:mm") + ":00",
                comment: f.comment
            }));

        props.onSubmit(modified);
    }

    const feedAmount = (ranchFeeds: FreezedArray<IRanchFeed>, feed_amount:FeedLastDayEditingAmount[]): FeedModifyReqAmount[] => {
        return feed_amount.map(a => ({
            ...a,
            feed_type_no: (a.feed_type_no ?? 0) === 0 ? 
                ranchFeeds.find(f => f.feed_no === a.feed_no)!.feed_type_no :
                a.feed_type_no,
            unit_price: ranchFeeds.find(f => f.feed_no === a.feed_no)!.unit_price,
        }));
    }

    const title = () => "前回の記録" + (props.feeds.length === 0 ? "" : `（${moment(props.feeds[0].fed_at).format("M月D日")}）`);
    const saveBtnText = () => moment(props.dayToSave).format("M月D日") + "へ記録";

    const feedLine = (ranchFeeds: FreezedArray<IRanchFeed>, amount: FeedLastDayEditingAmount) => {
        return buildFeedingDetailStr({ ...amount, leftover: amount.leftover === -1 ? undefined : amount.leftover }, ranchFeeds, props.feedTypeList, true);
    }

    return (
        <div>
            <Modal isOpen={true} centered={true} scrollable={true}>
                <ModalHeader toggle={props.onClose}>{title()}</ModalHeader>
                { mFeeds.isLoading ? (
                    <FetchWaiter />
                ) : (mFeeds.isError || mFeeds.data == null) ? (
                    <FetchError />
                ) : (<>
                    <ModalBody style={{ minHeight: "200px"}}>
                        { editingList.map((e,i) => (
                            <div key={i} className={classnames(styles["record-container"], { [styles.disabled]: e.disabled })}>
                                <div className={styles["record-header-container"]}>
                                    <b>{e.time}</b>
                                    <div className="checkbox checkbox-css m-l-10 p-0">
                                        <input type="checkbox" id={"chkDisable" + i} checked={e.disabled}
                                            onChange={e => onDisableChange(e.target.checked, i)}></input>
                                        <label className={classnames(styles["label-ignore"], { [styles.disabled]: e.disabled})} htmlFor={"chkDisable" + i}>反映しない</label>
                                    </div>
                                </div>

                                <div className={styles["record-body-container"]}>
                                    <div className={styles["amount-container"]}>
                                        <ul>
                                            { e.feed_amount.map((am, iam) => (
                                                <li key={iam}>{feedLine(mFeeds.data, am)}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <button className="btn btn-sm btn-green" disabled={e.disabled} onClick={() => showFeedLastDayEditPopup(i)}>編集</button>
                                </div>
                            </div>
                        ))}
                    </ModalBody>
                    <ModalFooter className="modal-footer-fix">
                        <ExecutionButton type="save"
                            onClick={onSubmit}
                            disabled={props.isSubmitExecuting || editingList.every(e => e.disabled)}
                        >{saveBtnText()}</ExecutionButton>
                    </ModalFooter>
                    { editingFeed != null && (
                        <FeedLastDayEditPopup 
                            onClose={()=> setEditingFeed(undefined)}
                            onEdit={onEdit}
                            ranchFeedList={mFeeds.data}
                            feedTypeList={props.feedTypeList}
                            feed={editingFeed}
                        />
                    )}
                </>)}
            </Modal>
        </div>
    );
}