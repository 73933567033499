import React from 'react';

import styles from './feedbulk.module.css'


type PropType = {
  count    : number,
  eventName: string
}

const Completed: React.FC<PropType> = ({ count, eventName }) => {
  return (
    <section className={ styles.section }>
      <p data-testid="登録完了" className={ styles.message }>{ `${count}頭分の${eventName}記録が登録されました。` }</p>
    </section>
  )
}
export default Completed
