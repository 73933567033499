import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Washouts } from '../../config/Washout';
import styles from './cow-washout-popup.module.css';
import { AutoCollapse } from '../../components/parts/collapse-container';

export interface ICowWashoutPopupProps {
    onClose:()=>void;
    washouts: Washouts;
    canViewDetail: boolean;
}

export const CowWashoutPopup: React.FC<ICowWashoutPopupProps> = (props) => {

    return (
        <div>
            <Modal isOpen={true} toggle={props.onClose} centered={true}>
                <ModalHeader toggle={props.onClose}>出荷制限中</ModalHeader>
                <ModalBody style={{ overflowY: "auto", maxHeight: "calc(100vh - 150px)"}}>
                    <div className={styles["washout-header"]}>
                        <label className="col-form-label">出荷制限期間</label>
                        { props.washouts.meat && 
                            <div className={styles["washout-term"]}>
                                肉：{Washouts.formatDateTime(props.washouts.meat.from)} ～ {Washouts.formatDateTime(props.washouts.meat.to)}
                            </div>
                        }
                        { props.washouts.milk && 
                            <div className={styles["washout-term"]}>
                                乳：{Washouts.formatDateTime(props.washouts.milk.from)} ～ {Washouts.formatDateTime(props.washouts.milk.to)}
                            </div>
                        }
                    </div>
                    { props.canViewDetail && (
                        <AutoCollapse header="詳細" className={styles["washout-collapse"]}>
                            <>
                            {
                                props.washouts.data.map((w, i) => 
                                    <div key={i} className={styles["washout-detail"]}>
                                        <div className={styles["washout-detail-name"]}>
                                            <label className="col-form-label">{w.val.item_type_name} {w.val.item_name}</label>
                                        </div>
                                        { w.val.meat && 
                                            <div className={styles["washout-detail-term"]}>
                                                肉：{Washouts.formatDateTime(w.val.meat.from)} ～ {Washouts.formatDateTime(w.val.meat.to)}
                                            </div>
                                        }
                                        { w.val.milk && 
                                            <div className={styles["washout-detail-term"]}>
                                                乳：{Washouts.formatDateTime(w.val.milk.from)} ～ {Washouts.formatDateTime(w.val.milk.to)}
                                            </div>
                                        }
                                    </div>
                                )
                            }
                            </>
                        </AutoCollapse>
                    )}
                    </ModalBody>
            </Modal>
        </div>
    );
}