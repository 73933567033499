import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { BREEDING_STATE, BREEDING_STATES } from '../../config/constant';
import { CommonUtil } from '../../config/util';
import { BreedingStateSelector } from '../../components/parts/breeding-state-selector';

export interface BreedingStatePopupProps {
    onClose: ()=>void;
    state: number;
    openday: Date;
    onSubmit:(state: number, openday?: Date) => void;
    isSubmitExecuting: boolean;
}

export const BreedingStatePopup: React.FC<BreedingStatePopupProps> = (props) => {
    const [ currentState, setCurrentState ] = useState(BREEDING_STATE.OPEN.no);
    const [ currentOpenDay, setCurrentOpenDay ] = useState(new Date());

    useEffect(() => {
        setCurrentState(props.state);
    }, [ props.state ]);

    useEffect(() => {
        setCurrentOpenDay(props.openday);
    }, [ props.openday ]);

    const onChange = (state: number, openday: Date) => {
        setCurrentState(state);
        setCurrentOpenDay(openday);
    }

    const onSubmit = () => {
        const stateKey = BREEDING_STATES.find(k => BREEDING_STATE[k].no === currentState);
        if (!CommonUtil.assertNotNull(stateKey, "breedingState " + currentState, "onSubmit")) return;

        props.onSubmit(currentState, BREEDING_STATE[stateKey].hasOpenDay ? currentOpenDay : undefined);
    }

    const ID = "breeding-state-popup";

    return (
        <div className="modal-wide">
            <Modal isOpen={true} centered={true} id={ID}>
                <ModalHeader toggle={props.onClose}>繁殖状態の設定</ModalHeader>
                <ModalBody style={{ minHeight:"180px", maxHeight: "calc(100vh - 150px)" }}>
                    <BreedingStateSelector openday={currentOpenDay} state={currentState}
                        onChange={onChange} portalContainerID={ID} />
                </ModalBody>
                <ModalFooter style={{display:"flex", justifyContent:"center"}}>
                    <button className="btn btn-green m-r-10" onClick={onSubmit} disabled={props.isSubmitExecuting}>保存</button>

                </ModalFooter>
            </Modal>
        </div>
    );
}