import React, { useState, useEffect, useCallback } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ExecutionButton } from 'components/buttons/execution-button';
import { Table } from "reactstrap";
import styles from './robot-import.module.css';
import { RobotPreviewDto, RobotPreviewCowDto, RobotCowModifyReqCow } from 'api';
import { ar, FreezedArray, CommonUtil } from 'config/util';
import { ICowNameInfo, CowToDispInfo } from 'components/parts/cows-popup';
import { IconRight } from 'components/parts/icon-right';
import { CommonSelect, SelectOption } from 'components/parts/common-select';

const findCowLike = (robotCowId: string, cows: FreezedArray<ICowNameInfo>): ICowNameInfo|undefined => {
    //牧場耳標で探す
    const sameLocalNo = cows.find(c => c.local_no != null && c.local_no.toLowerCase() === robotCowId.toLowerCase());
    if (sameLocalNo != null) return sameLocalNo;

    //拡大4桁で探す
    let robotCowNo = parseInt(robotCowId);
    if (isNaN(robotCowNo) || robotCowNo < 0 || 9999 < robotCowNo) return undefined;

    return cows.find(c => (c.local_no == null || c.local_no === "") && Number(CowToDispInfo(c, false)) == robotCowNo);
}

interface Props {
    previewData: RobotPreviewDto;
    onClose: () => void;
    isSubmitExecuting: boolean;
    allCows: FreezedArray<ICowNameInfo>;
    onSubmit: (changedCows: RobotCowModifyReqCow[]) => void;
}

type EditingCow = RobotPreviewCowDto & {
    /**
     * 未確定の選択中牛
     */
    currentCowId: number | undefined,
    isChanging: boolean;
};

export const RobotCowPopup = React.memo((props: Props) => {
    const [ list, setList ] = useState<EditingCow[]>([]);
    const [ cowList, setCowList ] = useState<FreezedArray<SelectOption<number>>>([]);

    useEffect(() => {
        setCowList(props.allCows.map(cw => ({ label:CowToDispInfo(cw), value: cw.cow_id })));

        //robot cow id に含まれる先頭の数字でソート
        const ordered = ar.orderBy(props.previewData.cows, c => {
            const n = parseInt(c.robot_cow_id);
            return isNaN(n) ? 0 : n;
        });

        setList(ordered.map(cow => ({
            ...cow,
            currentCowId: cow.cow_id != null ? undefined : findCowLike(cow.robot_cow_id, props.allCows)?.cow_id,
            isChanging: cow.cow_id == null
        })));

    }, [ props.allCows, props.previewData ]);

    const onCowEditClick = useCallback((cow: EditingCow) => {
        const newList = list.map(c => c.robot_cow_id !== cow.robot_cow_id ? c : ({
            ...c,
            isChanging:true
        }));

        setList(newList);

    }, [ list ]);

    const onCowSelect = useCallback((robotCowId: string, cowId: number | undefined) => {
        const newList = list.map(c => c.robot_cow_id !== robotCowId ? c : ({
            ...c,
            currentCowId: cowId
        }));

        setList(newList);

    }, [ list ]);

    const onSubmit = useCallback(() => {
        //変更のあったものを抽出
        const changed = list.filter(cow => cow.isChanging && cow.cow_id !== cow.currentCowId)
                .map(cow => ({ robot_cow_id:cow.robot_cow_id, cow_id: cow.currentCowId }));

        props.onSubmit(changed);

    }, [ list, props.onSubmit ]);

    const CowCell = ({ cw }: { cw: EditingCow }) => {
        if (!cw.isChanging && CommonUtil.assertNotNull(cw.cow_id, "cow_id of comitted cow")) {

            const name = cw.trace_id == null
                    ? (cw.name ?? cw.local_no ?? "(不明な牛)")
                    : CowToDispInfo({ ...cw, cow_id:cw.cow_id, trace_id:cw.trace_id }, true);

            return (
                <td className={styles["td-cow-saved"]}>
                    <span>{name}</span>
                    <IconRight iconType="edit" onClick={() => onCowEditClick(cw)} />
                </td>
            )
        }

        return (
            <td className={styles["td-cow-changing"]}>
                <CommonSelect
                    onCreate={undefined}
                    itemName="耳標・名前"
                    options={cowList}
                    value={cw.currentCowId}
                    onClear={() => onCowSelect(cw.robot_cow_id, undefined)}
                    onSelect={val => onCowSelect(cw.robot_cow_id, val)}
                    inputId={"inputCow_" + cw.robot_cow_id}
                    menuPlacementOnPc="auto"
                />
            </td>
        )
    }

    const cowCount = list.length;
    const savedCowCount = list.filter(c => c.cow_id != null).length;
    const unselectedCowCount = list.filter(c => c.cow_id == null && c.currentCowId == null).length;

    return (
        <div>
            <Modal isOpen={true} centered={true} scrollable={true}>
                <ModalHeader toggle={props.onClose}>牛番号の紐づけ</ModalHeader>
                <ModalBody>
                    <div className={styles["table-header"]}>{props.previewData.count}件の記録（全{cowCount}頭、紐づけ済{savedCowCount}頭、未選択{unselectedCowCount}頭）</div>

                    <Table className={styles.table}>
                        <thead>
                            <tr>
                                <th>牛番号</th>
                                <th>vetell内の牛</th>
                            </tr>
                        </thead>
                        <tbody>
                            { list.map(cw => (
                                <tr key={cw.robot_cow_id}>
                                    <td className={styles["td-robot"]}>{cw.robot_cow_id}</td>
                                    <CowCell cw={cw} />
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    
                </ModalBody>
                <ModalFooter className="modal-footer-fix">
                    <ExecutionButton type="save" disabled={props.isSubmitExecuting} onClick={onSubmit} />
                </ModalFooter>
            </Modal>
        </div>
    );
});