import React, { useState } from 'react';
import styles from './swipe-to-next-panel.module.css';
import classnames from 'classnames';
import { useSwipeable } from 'react-swipeable';

interface MyProps {
    className?: string;
    style?: React.CSSProperties;
    children?: React.ReactNode;
    next: string | undefined;
    prev: string | undefined;
    onNext: () => void;
    onPrev: () => void;
}

const SWIPE_THRESHOLD = 100;

export const SwipeToNextPanel = (props: MyProps) => {
    const [ delta, setDelta ] = useState(0);

    const handlers = useSwipeable({
        onSwiped: (s) => {
            setDelta(0);
            if (s.deltaX > SWIPE_THRESHOLD && props.prev != null) {
                props.onPrev();
            } else if (s.deltaX < (SWIPE_THRESHOLD * -1) && props.next != null) {
                props.onNext();
            }
        },
        onSwiping: (s) => {
            setDelta(s.deltaX);
        },
    });

    return (<>
        <div className={classnames(styles.wide, styles.container, props.className)} style={props.style}>
            <div className={styles.nav}>
                <div className={classnames("clickable", {"invisible": props.prev == null})} onClick={props.onPrev}>
                    <i className="fas fa-angle-double-left m-r-5" />
                    <span>{props.prev}</span>
                </div>
                <div className={classnames("clickable", {"invisible": props.next == null})} onClick={props.onNext}>
                    <span>{props.next}</span>
                    <i className="fas fa-angle-double-right m-l-5" />
                </div>
            </div>
            <div children={props.children} />
        </div>

        <div className={classnames(styles.slim, props.className)} style={props.style}>
            { props.prev != null && (
                <div className={classnames(styles.slide, styles.prev, {[ styles.submit]: delta > SWIPE_THRESHOLD })} style={{ opacity:delta / SWIPE_THRESHOLD }}>
                    <i className="fas fa-angle-double-left m-r-5" />
                    <span>{props.prev}</span>
                </div>
            )}
            { props.next != null && (
                <div className={classnames(styles.slide, styles.next, {[ styles.submit ]: delta < (SWIPE_THRESHOLD * -1) })} style={{ opacity:delta / SWIPE_THRESHOLD * -1 }}>
                    <span>{props.next}</span>
                    <i className="fas fa-angle-double-right m-l-5" />
                </div>
            )}
            <div className={styles.container} {...handlers} style={{ marginLeft: delta / 1.5, marginRight: delta / 1.5 * -1 }}>
                <div children={props.children} />
            </div>
        </div>
    </>)
}