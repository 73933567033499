import React, { useEffect, useState } from "react";
import PrevNextDatePicker from "./prev-next-date-picker";
import { BREEDING_STATES, BREEDING_STATE, A } from "../../config/constant";
import { InfoPopup } from "./info-popup";
import { IDeliveryHis, CrossHistory } from "../../pages/cow/cow-event";
import { ar } from "../../config/util";
import moment from "moment";

export interface BreedingStateSelectorProps {
    state: number;
    openday: Date;
    onChange:(state: number, openday: Date) => void;
    className?: string;
    containerStyle?: React.CSSProperties;
    portalContainerID?: string;
}

export const DEFAULT_OPEN_DAYS_AFTER_DELIVERY = 60;

export type BreedingStateConfirmedKey = "OK_NO_CONFIRMED"|"OK"|"NO"|"CANCEL";
export const BREEDING_STATE_CONFIRMED = {OK_NO_CONFIRMED: -1, OK: 0, NO: 1, CANCEL: 2} as const;

export const confirmBreedingStateChange
    = async (before: number,
        after: number,
        deliveryHistory:IDeliveryHis[],
        crossHistory: CrossHistory[],
        askAsync: (text: string, buttons: string[]) => Promise<number|undefined>,
        buttons: string[] = [ "変更", "キャンセル" ]): Promise<number|undefined> => {
    
    if (after === BREEDING_STATE.OPEN.no) {
        if (before === BREEDING_STATE.PREG.no) {
            return (await askAsync(A.MESSAGE.BREEDING_STATE_PREG_TO_OPEN, buttons));
        }
    }
    if (after === BREEDING_STATE.CARE.no) {
        if (before === BREEDING_STATE.PREG.no) {
            return (await askAsync(A.MESSAGE.BREEDING_STATE_PREG_TO_CARE, buttons));
        }
    }
    if (after === BREEDING_STATE.CLOSE.no) {
        if (before === BREEDING_STATE.PREG.no) {
            return (await askAsync(A.MESSAGE.BREEDING_STATE_PREG_TO_CLOSE, buttons));
        }
    }
    if (after === BREEDING_STATE.PREG.no) {
        if (!ar.any(crossHistory)
            || (ar.any(deliveryHistory) && moment(ar.last(crossHistory).watched_at).isBefore(ar.last(deliveryHistory).delivered_at) )) {

            return (await askAsync(A.MESSAGE.BREEDING_STATE_TO_PREG_WITHOUT_CROSS, buttons));
        }
    }
    if (after === BREEDING_STATE.FRESH.no) {
        if (!ar.any(deliveryHistory)) {
            return (await askAsync(A.MESSAGE.BREEDING_STATE_TO_FRESH_WITHOUT_DELIVERY, buttons));
        }
    }

    // console.error("unknown breeding state", after);
    return -1;
}

export const BreedingStateSelector: React.FC<BreedingStateSelectorProps> = (props) => {
    const [ needsOpenDay, setNeedsOpenDay ] = useState(false);

    useEffect(() => {
        const key = BREEDING_STATES.find(k => BREEDING_STATE[k].no === props.state);
        if (key == null) return;

        setNeedsOpenDay(BREEDING_STATE[key].hasOpenDay);

    }, [ props.state ]);

    return (
        <div className={props.className ?? ""} style={{display:"flex", flexWrap:"wrap", justifyContent:"center", ...props.containerStyle }}>
            <div style={{marginRight:"16px"}}>
                { BREEDING_STATES.map(s => (
                    <div key={BREEDING_STATE[s].no}>
                        <div className="radio radio-css" style={{ marginBottom:"2px" }}>
                            <input type="radio" id={`radB_${s}`} checked={props.state === BREEDING_STATE[s].no}
                                onChange={() => props.onChange(BREEDING_STATE[s].no, props.openday)} />
                            <label htmlFor={`radB_${s}`} style={{ width:"76px" }}>{s}</label>
                            <InfoPopup placement="top" message={BREEDING_STATE[s].help} />
                        </div>
                    </div>
                ))}
            </div>
            <div style={{marginTop:"4px", visibility:needsOpenDay ? "visible":"hidden"}}>
                <div>OPEN予定日</div>
                <PrevNextDatePicker value={props.openday} name="openday" onChange={e => props.onChange(props.state, e.target.value)} portalContainerID={props.portalContainerID} />
            </div>
        </div>
    )
}