import React, { useState, useEffect } from 'react';
import styles from './symptom.module.css';
import { FreezedArray, CommonUtil, formatDiseaseCode } from '../../config/util';
import { IDisease } from '../../stores';
import { DISEASE_ITEM_STATUS } from '../../config/constant';
import { DiseaseCauseDto, TeamDiseaseCauseDto } from '../../api';
import { CommonSelect, SelectOption } from '../../components/parts/common-select';

export type DiseaseInputItem = { disease_id: number, code_no?: number, cause_no?: number };
type NamedDiseaseItem = DiseaseInputItem & { name: string };

interface MyProps {
    diseases: FreezedArray<IDisease>;
    causes: FreezedArray<DiseaseCauseDto>;
    /**
     * ※初期化前のみundefined
     */
    relations: FreezedArray<TeamDiseaseCauseDto> | undefined;
    selectedItem: DiseaseInputItem | undefined;
    onChange: (item: DiseaseInputItem | undefined) => void;
}

export const DiseaseNameInput = (props: MyProps) => {
    const [ favoriteList, setFavoriteList ] = useState<NamedDiseaseItem[]>([]);
    const [ diseaseOptions, setDiseaseOptions ] = useState<SelectOption<string>[]>([]);
    const [ relMap, setRelMap ] = useState<Map<number, TeamDiseaseCauseDto[]>>(new Map());

    useEffect(() => {
        const item = props.selectedItem;
        if (item == null) return;

        //※propsに正しいrelationリストが生成される前に↓の初期化処理が走って選択項目がクリアされてしまうのを抑止
        if (props.relations == null) return;

        //選択できない項目が選ばれているときはクリア
        const hasItem = props.relations.some(r => r.disease_id === item.disease_id
                                        && (item.cause_no == null || r.cause_no === item.cause_no));
        if (!hasItem) {
            props.onChange(undefined);
        }

    }, [ props.relations, props.selectedItem ]);

    useEffect(() => {
        if (props.relations == null) return;

        const favs = props.relations.filter(r => r.status == DISEASE_ITEM_STATUS.FAVORITE.no)
            .map(r => {
                const disease = props.diseases.find(d => r.disease_id === d.disease_id);
                return {
                    ...r,
                    dName: disease?.name ?? "",
                    cName: r.cause_no == null ? undefined : props.causes.find(c => r.cause_no === c.cause_no)?.cause_name,
                    order: (disease?.disp_no ?? 9999),
                };
            })
            .map(r => ({
                ...r,
                name: r.cName == null ? r.dName : (r.dName + "：" + r.cName)
            }));

        favs.sort((a, b) => a.order === b.order ? ((a.code_no ?? 0) - (b.code_no ?? 0)) : a.order - b.order);

        setFavoriteList(favs);

        const relationMap = CommonUtil.groupBy(props.relations, r => r.disease_id);
        setRelMap(relationMap);

        const isVisibleDisease = (id:number) => {
            const causes = relationMap.get(id);
            //原因項目が（「原因なし」含めて）1件もないものは表示しない
            if (causes == null || causes.length === 0) return false;

            return true;
        }

        const options = [
            ...favs.map(f => ({ label: "★" + f.name, value: f.disease_id + "_" + (f.cause_no ?? "")})),
            ...props.diseases
                    .filter(d => isVisibleDisease(d.disease_id))
                    .map(d => ({ label: d.name, value:d.disease_id + "" }))
        ];
        setDiseaseOptions(options);

    }, [ props.diseases, props.causes, props.relations ]);

    const onDiseaseChange = (val: string) => {
        if (val.includes("_")) {
            //favorite item
            const vals = val.split("_");
            const id = Number(vals[0]);
            const causeNo = vals[1] === "" ? undefined : Number(vals[1]);
            const item = favoriteList.find(f => f.disease_id === id && (causeNo == null ? f.cause_no == null : f.cause_no === causeNo));
            props.onChange(item);

        } else {
            const id = Number(val);
            const causes = relMap.get(id) ?? [];
            const cause_no = causes.length === 0 ? undefined : causes[0].cause_no;
            const code_no = causes.length === 0 ? undefined : causes[0].code_no;

            props.onChange({
                disease_id: id, cause_no, code_no
            })
        }

    }

    const onCauseChange = (val: string) => {
        if (props.selectedItem == null) return;
        if (!CommonUtil.assertNotNull(props.relations, "relations")) return;

        const diseaseId = props.selectedItem.disease_id;
        const causeNo = val === "" ? undefined : Number(val);

        const item = props.relations.find(r => r.disease_id === diseaseId && (causeNo == null ? r.cause_no == null : r.cause_no === causeNo));
        //※疾病に対して原因なしの項目が定義されていなくても、コード無しで使用可
        props.onChange({ disease_id: diseaseId, cause_no: causeNo, code_no: item?.code_no });
    }

    const causeList = props.selectedItem == null ? []
                    : (relMap.get(props.selectedItem.disease_id) ?? [])
                        .filter(c => c.cause_no != null)
                        .map(c => ({ ...c, name: props.causes.find(cc => c.cause_no === cc.cause_no)?.cause_name ?? "" }));

    return (
        <div className={styles["disease-block"]} data-testid="disease-name-input">
            <CommonSelect
                itemName="診断名"
                value={props.selectedItem?.disease_id == null ? undefined : (props.selectedItem.disease_id + "")}
                onClear={() => props.onChange(undefined)}
                onCreate={undefined}
                onSelect={ onDiseaseChange }
                options={diseaseOptions}
            />
            <div className={styles.cause}>
                <select className="form-control" data-testid="select-cause"
                    value={props.selectedItem?.cause_no ?? ""}
                    onChange={e => onCauseChange(e.target.value)}>
                    <option value="">{props.selectedItem == null ? "-" : "(小分類なし)"}</option>
                    {
                        causeList.map(c => (
                            <option key={c.cause_no} value={c.cause_no}>{c.name}</option>
                        ))
                    }
                </select>
                <div className={styles.code} data-testid="code">{props.selectedItem?.code_no == null ? "" : formatDiseaseCode(props.selectedItem.code_no)}</div>
            </div>
        </div>
    )
}