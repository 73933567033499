import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { LMT, TeamType } from '../../config/constant';
import styles from './setting.module.css';
import pgStyles from './program-management.module.css';
import { CommonUtil, FreezedArray, ar } from '../../config/util';
import { IMedicine, ITreatItem, ITreatKind, IMedicineRoute, IMedicineCategory } from '../../stores/RootStore';
import { IconLink } from '../../components/parts/icon-link';
import { EditingPreset, buildPresetItemName } from './preset-master';
import classnames from 'classnames';
import { PresetItemEditPopup, EditingPresetItem } from './preset-item-edit-popup';
import { ExecutionButton } from '../../components/buttons/execution-button';

interface MyProps {
    teamId: number;
    data: EditingPreset;
    onSubmit: (data :EditingPreset)=>void;
    onClose: ()=>void;
    onDelete: ()=>void;
    isSubmitExecuting: boolean;
    medicines: FreezedArray<IMedicine>;
    treatItems: FreezedArray<ITreatItem>;
    treatKinds: FreezedArray<ITreatKind>;
    medicineRoutes: FreezedArray<IMedicineRoute>;
    medicineCategories: FreezedArray<IMedicineCategory>;
    teamType: TeamType;
}

export const PresetEditPopup = (props: MyProps) => {
    const [ data, setData ] = useState<EditingPreset>({ items:[], preset_name: "", preset_id:undefined });
    const [ editingItemIndex, setEditingItemIndex ] = useState<number>();

    useEffect(() => {
        setData(props.data);

    }, [ props.data ]);

    const onItemSubmit = (item: EditingPresetItem) => {
        if (!CommonUtil.assertNotNull(editingItemIndex, "editingItemIndex")) return;

        const isNew = data.items.length === editingItemIndex;

        setData(pre => {
            return {
                ...pre,
                items: isNew ? [ ...pre.items, item ] : pre.items.map((p,idx) => idx === editingItemIndex ? item : p)
            }
        });
        setEditingItemIndex(undefined);
    }
    const onItemDelete = () => {
        if (!CommonUtil.assertNotNull(editingItemIndex, "editingItemIndex")) return;
        setData(pre => {
            return {
                ...pre,
                items: pre.items.filter((_,i) => i !== editingItemIndex)
            }
        });
        setEditingItemIndex(undefined);
    }

    const buildName = (item: EditingPresetItem) => buildPresetItemName(item, props.medicines, props.treatItems);

    const buildDetail = (item: EditingPresetItem) => {
        //投薬
        if (item.medicine_id != null) {
            const unit = props.medicines.find(m => m.medicine_id === item.medicine_id)?.unit ?? "";
            const amount = item.amount != null ? `${item.amount}${unit}` : undefined;
            const route = item.route_id == null ? undefined : props.medicineRoutes.find(r => r.route_id === item.route_id)?.name;
            return ar.notNull([amount, route]).join(" ");
        }

        //処置
        const treat = props.treatItems.find(t => item.treat_kind_no === t.treat_kind_no && item.treat_item_no === t.treat_item_no);
        if (treat?.fee_unit == null || item.amount == null) return "";
        return `${item.amount}${treat.fee_unit}`;
    }
    
    return (
        <div>
            <Modal isOpen={true} centered={true}>
                <ModalHeader toggle={props.onClose}>プリセット編集</ModalHeader>
                <ModalBody style={{ overflowY: "auto", maxHeight: "calc(100vh - 210px)", minHeight:"calc(100vh - 280px)", paddingLeft:"20px" }}>
                    <div className="form-group" style={{display:"flex"}}>
                        <label className={"col-form-label " + pgStyles["popup-label"]}>プリセット名</label>
                        <input className="form-control" type="text" value={data.preset_name}
                            maxLength={LMT.TREAT_PRESET.NAME_LEN}
                            onChange={e => setData({ ...data, preset_name:e.target.value })}/>
                    </div>
                    <div className="product-detail">
                        <ul className={styles["list"]}>
                            { data.items.map((item,i) => (
                                <li key={i} className={classnames(styles["list-item"], styles["list-item-mid"])}>
                                    <div className={styles["list-item-content"]}>
                                        <div className={styles["list-item-name"]}>{buildName(item)}</div>
                                        <div className={styles["list-item-detail"]}>{buildDetail(item)}</div>
                                    </div>
                                    <div className={styles["list-item-icon"]} onClick={() => setEditingItemIndex(i)}><i className="fas fa-pen clickable"></i></div>
                                </li>))
                            }
                        </ul>
                    </div>
                    <div style={{marginTop:"5px"}} onClick={() => setEditingItemIndex(data.items.length)}>
                        <IconLink iconType="add" text="処置を追加" />
                    </div>
                </ModalBody>
                <ModalFooter className="modal-footer-fix">
                    <ExecutionButton type="save" onClick={() => props.onSubmit(data) } disabled={props.isSubmitExecuting} />
                    { props.data.preset_id != null && (
                        <ExecutionButton type="delete" onClick={props.onDelete} disabled={props.isSubmitExecuting} />
                    )}
                </ModalFooter>
            </Modal>
            { editingItemIndex != null && (
                <PresetItemEditPopup
                    data={data.items[editingItemIndex]}
                    medicineCategories={props.medicineCategories}
                    routes={props.medicineRoutes}
                    medicines={props.medicines}
                    treatKinds={props.treatKinds}
                    treats={props.treatItems}
                    onClose={() => setEditingItemIndex(undefined)}
                    teamId={props.teamId}
                    onSubmit={d => onItemSubmit(d)}
                    onDelete={() => onItemDelete()}
                    teamType={props.teamType}
                />
            )}                
        </div>
    )

}