import { ar } from "./util";

export class MapUtil {

    public static toObj<V>(map: Map<string|number, V>) {
        const rtn = {};
        [...map.entries()].forEach(([k,v]) => {
            if (v instanceof Map) {
                rtn[k] = this.toObj(v);
            } else {
                rtn[k] = v;
            }
        });
        return rtn;
    }

    public static sumValues<TVal>(map: Map<number|string, TVal>, picker: (val:TVal) => number) {
        return ar.sum([...map.values()].map(picker));
    }
}