import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { A } from '../../config/constant';
import { PageSettings } from '../../config/page-settings.js';
import { CommonUtil } from '../../config/util';
import styles from './ranch.module.css';
import { withContext, ICowSearchCondition, ISelectedSite, ISelectedBarn, defaultCowListCondition, CowListFilter } from '../../stores';
import { SchedulePopup, ISchedule } from '../schedule/schedule-popup';
import { AppState } from '../../app';
import { CowListDto, ProgramDto, ProgramApi } from '../../api';
import { ConditionMenu } from '../feedbulk';
import { historyLocation, IEventWriteLocationState, IEventWriteSearch } from '../../config/history-location-builder';
import { LinkParam } from '../top/Dashboard';
import classnames from 'classnames';
import { CowSearchPopup } from './cow-search-popup';
import { ActionCowSelector } from '../../components/parts/action-cow-selector';
import { buildDefaultOutputs, ICowListOutput } from './select-output-popup';
import { EVENT_KIND } from '../../config/event-kind';

interface MyState {
    cows: Readonly<CowListDto[]>;
    isSchedulePopupShown: boolean;
    currentScheduleEvent: Partial<ISchedule>;
    checkedCowIds: Set<number>;
    isAllSelected: boolean;
    medicineTeamId: number;
    actionType?: COW_ACTION_TYPE;
    cowSearchFilter?: CowListFilter;
    programs?: Readonly<ProgramDto[]>;
}

export const COW_ACTION_TYPES = [
    "MOVE", "FEEDING", "PREVENTION", "SYMPTOM", "GROWTH",
    "BREEDING", "RUT", "DELIVERY",
    "SCHEDULE", "PROGRAM",
    "BALANCE", "SELLCOW", "SELLMILK", "OTHER_PL",
    "SOV", "OPU", "IVF", "IVF_EGG",
    "CERT_VACCINE", "CERT_DISEASE", "CERT_KARTE"
] as const;

export type COW_ACTION_TYPE = typeof COW_ACTION_TYPES[number];

interface ICowActionType {
    name: string;
    useNoList?: number[];
    onSelect: (cows: CowListDto[], self:RanchHouses) => void;
    singleSelect: boolean;
    isModal?: boolean;
    title: string;
}

const toEventWriteSearch = (cows: CowListDto[]): IEventWriteSearch => {
    return { param: cows.map(c => c.cow_id).join(","), cow_top: cows.length === 1 ? 1 : 0 };
}
const toEventWriteLocationState = (cows: CowListDto[]): IEventWriteLocationState => {
    return { cows: cows.map(c => ({...c, use_no: c.use_no, breed_no:c.breed_no })) };
}

const CowActionType: { [key in COW_ACTION_TYPE ]: ICowActionType } = {
    MOVE:         {
        title: "移動",
        name: "移動を記録",
        singleSelect: EVENT_KIND.LOCATION.singleCowOnly === true,
        onSelect:(cows, self) => self.props.history.push(historyLocation.toLocationWrite(toEventWriteSearch(cows), toEventWriteLocationState(cows)))
    },
    FEEDING:      {
        title: "えさ記録",
        name: "えさを記録",
        singleSelect: EVENT_KIND.FEEDING.singleCowOnly === true,
        onSelect:(cows, self) => self.props.history.push(historyLocation.toFeedWrite(toEventWriteSearch(cows), toEventWriteLocationState(cows)))
    },
    PREVENTION:   {
        title: "予防処置",
        name: "予防を記録",
        singleSelect: EVENT_KIND.PREVENTION.singleCowOnly === true,
        onSelect:(cows, self) => self.props.history.push(historyLocation.toPreventionWrite(toEventWriteSearch(cows), toEventWriteLocationState(cows)))
    },
    SYMPTOM:      {
        title: "体調・治療",
        name: "体調・治療を記録",
        singleSelect: EVENT_KIND.SYMPTOM.singleCowOnly === true,
        onSelect:(cows, self) => self.props.history.push(historyLocation.toSymptomWrite(toEventWriteSearch(cows), toEventWriteLocationState(cows)))
    },
    GROWTH:       {
        title: "身体測定",
        name: "身体測定値を記録",
        singleSelect: EVENT_KIND.GROWTH.singleCowOnly === true,
        onSelect:(cows, self) => self.props.history.push(historyLocation.toGrowthWrite(toEventWriteSearch(cows), toEventWriteLocationState(cows)))
    },
    BREEDING:     {
        title: "検診・交配",
        name: "検診・交配を記録",
        useNoList: A.GET_FOR_BREEDING_USE_NO(),
        singleSelect: EVENT_KIND.BREEDING.singleCowOnly === true,
        onSelect:(cows, self) => self.props.history.push(historyLocation.toBreedingWrite(toEventWriteSearch(cows), toEventWriteLocationState(cows)))
    },
    RUT: {
        title: "発情記録",
        name: "発情を記録",
        useNoList: A.GET_FOR_BREEDING_USE_NO(),
        singleSelect: EVENT_KIND.RUT.singleCowOnly === true,
        onSelect:(cows,self) => self.props.history.push(historyLocation.toRutWrite({
            cow_ids:cows.map(c => c.cow_id),
            cows: cows.map(c => ({ ...c, use_no:c.use_no, breed_no:c.breed_no })),
            cow_top: cows.length === 1,
        }))
    },
    DELIVERY:     {
        title: "分娩記録",
        name: "分娩を記録",
        singleSelect: EVENT_KIND.DELIVERY.singleCowOnly === true,
        useNoList: A.GET_FOR_BREEDING_USE_NO(),
        onSelect:(cows, self) => self.props.history.push(historyLocation.toDeliveryWrite(toEventWriteSearch(cows), toEventWriteLocationState(cows)))
    },
    BALANCE:      {
        title: "収支",
        name: "収支グラフを確認",
        singleSelect: false,
        onSelect:(cows, self) => self.props.history.push(historyLocation.toBalanceCow(cows.map(c => c.cow_id)))
    },
    SELLCOW:      {
        title: "個体販売",
        name: "個体販売を記録",
        singleSelect: EVENT_KIND.SELL_COW.singleCowOnly === true,
        onSelect:(cows, self) => self.props.history.push(historyLocation.toSellCow(toEventWriteLocationState(cows)))
    },
    SELLMILK:     {
        title: "乳代売上",
        name: "乳代売上を記録",
        useNoList: A.GET_FOR_MILK_USE_NO(),
        singleSelect: EVENT_KIND.SELL_MILK_COW.singleCowOnly === true,
        onSelect:(cows, self) => self.props.history.push(historyLocation.toSellMilk(toEventWriteSearch(cows), toEventWriteLocationState(cows)))
    },
    OTHER_PL:     {
        title: "雑収入・雑損失",
        name: "雑収入・雑損失を記録",
        singleSelect: EVENT_KIND.OTHER_PROFIT_COW.singleCowOnly === true,
        onSelect:(cows, self) => self.props.history.push(historyLocation.toOtherPl(toEventWriteSearch(cows), toEventWriteLocationState(cows)))
    },
    SCHEDULE:     {
        title: "予定登録",
        name: "予定を登録",
        singleSelect:false,
        onSelect:(cows, self) => self.setState({ isSchedulePopupShown:true, currentScheduleEvent:{ cows: toEventWriteLocationState(cows).cows.map(c => ({ ...c, is_active:1 })) } }),
        isModal: true
    },
    PROGRAM:      {
        title: "プログラム",
        name: "プログラムを設定",
        singleSelect:false,
        onSelect:(cows, self) => self.props.history.push(historyLocation.toCowProgram(toEventWriteLocationState(cows).cows))
    },
    SOV: {
        title: "体内受精卵",
        name: "体内受精卵を記録",
        useNoList: A.GET_FOR_BREEDING_USE_NO(),
        singleSelect: EVENT_KIND.SOV.singleCowOnly === true,
        onSelect:(cows, self) => self.props.history.push(historyLocation.toSovWrite(toEventWriteLocationState(cows)))
    },
    OPU: {
        title: "OPU",
        name: "OPUを記録",
        useNoList: A.GET_FOR_BREEDING_USE_NO(),
        singleSelect: EVENT_KIND.OPU.singleCowOnly === true,
        onSelect:(cows, self) => self.props.history.push(historyLocation.toOpuWrite(toEventWriteLocationState(cows)))
    },
    IVF: {
        title: "媒精",
        name: "媒精を記録",
        useNoList: A.GET_FOR_BREEDING_USE_NO(),
        singleSelect: EVENT_KIND.IVF.singleCowOnly === true,
        onSelect:(cows, self) => self.props.history.push(historyLocation.toIvfWrite(toEventWriteLocationState(cows)))
    },
    IVF_EGG: {
        title: "培養結果",
        name: "培養結果を記録",
        useNoList: A.GET_FOR_BREEDING_USE_NO(),
        singleSelect: EVENT_KIND.IVF_EGG.singleCowOnly === true,
        onSelect:(cows, self) => self.props.history.push(historyLocation.toIvfEggWrite(toEventWriteLocationState(cows)))
    },
    CERT_VACCINE: {
        title: "ワクチン証明書",
        name: "ワクチン証明書を発行",
        singleSelect: false,
        onSelect:(cows, self) => self.props.history.push(historyLocation.toCertVaccine(toEventWriteLocationState(cows))),
    },
    CERT_DISEASE: {
        title: "事故発生通知",
        name: "事故発生通知を発行",
        singleSelect: false,
        onSelect:(cows, self) => self.props.history.push(historyLocation.toCertDisease(toEventWriteLocationState(cows))),
    },
    CERT_KARTE: {
        title: "診療カルテ",
        name: "診療カルテを発行",
        singleSelect: false,
        onSelect:(cows, self) => self.props.history.push(historyLocation.toCertKarte(toEventWriteLocationState(cows)))
    }
}

const cowsToHouseCondition = (allCows: Readonly<Readonly<CowListDto[]>>,
            checkedCowIds: Readonly<Set<number>>): ISelectedSite[] => {
    
    const rtn: ISelectedSite[] = [];
    const siteGrouped = CommonUtil.groupBy(allCows, c => c.site);

    for (const [siteNo, cows] of siteGrouped.entries()) {
        if (cows.every(c => checkedCowIds.has(c.cow_id))) {
            rtn.push({ isAllSelected: 1, no: siteNo, level: 1, data:[] });
            continue;
        }
        if (cows.every(c => !checkedCowIds.has(c.cow_id))) {
            continue;
        }
        const barnGrouped = CommonUtil.groupBy(cows, c => c.barn);

        const site: ISelectedSite = ({
            level: 1,
            isAllSelected: 0,
            no: siteNo,
            data: []
        });

        for (const [barnNo, bCows] of barnGrouped.entries()) {
            if (bCows.every(b => checkedCowIds.has(b.cow_id))) {
                site.data.push({
                    isAllSelected: 1,
                    level: 2,
                    no: barnNo,
                    data: []
                });
                continue;
            }
            if (bCows.every(b => !checkedCowIds.has(b.cow_id))) {
                continue;
            }
            const roomGrouped = CommonUtil.groupBy(bCows, b => b.room);

            const barn: ISelectedBarn = ({
                no: barnNo,
                level: 2,
                isAllSelected: 0,
                data: []
            });

            for (const [roomNo, rCows] of roomGrouped.entries()) {
                if (rCows.every(r => checkedCowIds.has(r.cow_id))) {
                    barn.data.push({
                        level: 3,
                        no: roomNo
                    });
                }
            }
            if (barn.data.length > 0) {
                site.data.push(barn);
            }
        }
        if (site.data.length > 0) {
            rtn.push(site);
        }

    }
    return rtn;
}


class RanchHouses extends Base<BaseProps<{},{},{}>, MyState> {

    static contextType = PageSettings;
    context!:AppState;

    constructor(props) {
        super(props);

        this.state = {
            cows:[],
            checkedCowIds: new Set<number>(),
            currentScheduleEvent:{},
            isSchedulePopupShown:false,
            isAllSelected: false,
            medicineTeamId: 0,
        }

        this.onFinishScheduleEdit = this.onFinishScheduleEdit.bind(this);
        this.onSchedulePopupClose = this.onSchedulePopupClose.bind(this);
        this.onMoveNext = this.onMoveNext.bind(this);
        this.onScheduleLinkClick = this.onScheduleLinkClick.bind(this);
    }

    componentDidMount() {
        if (this.handleNotAllowAccess(undefined, [], [])) {
            return;
        }

        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        const actionParam = new URLSearchParams(this.props.location.search).get("action");
        const actionType = (actionParam != null && actionParam in CowActionType) ? actionParam as COW_ACTION_TYPE : undefined;
        const action = actionType == null ? undefined : CowActionType[actionType];

        this.context.handleSetHeader({ title:action?.title ?? "", backBtn:false });

        const ranch_id = this.props.rootStore.cur_ranch_id;
        this.context.handleSetIsLoading(true);
        const fetches: Promise<unknown>[] = [
            this.props.rootStore.fetchActiveCows(ranch_id),
        ];
        const clinicId = this.props.rootStore.getClinicIdForMaster();

        const medicineTeamId = clinicId ?? ranch_id;

        this.setState({
            medicineTeamId
        });

        Promise.all(fetches)
            .then(async () => {
                this.context.handleSetIsLoading(false);

                const cows = action == null ? [] : this.props.rootStore.getActiveCows(ranch_id).filter(c => action.useNoList == null || action.useNoList.includes(c.use_no ?? 0));

                let cowIds = this.props.rootStore.getSelectedCowIds()
                                .filter(id => cows.some(c => c.cow_id === id));
                if (action?.singleSelect === true && cowIds.length >= 2) {
                    cowIds = [ cowIds[0] ];
                }

                await this.setStateAsync({
                    cows,
                    actionType,
                    checkedCowIds: new Set(cowIds)
                });

            });
        
    }

    static buildEventWriteLocationState(cowIds: number[], allCows: Readonly<Readonly<CowListDto>[]>): IEventWriteLocationState {
        return ({ 
            cows: cowIds.map(id => allCows.find(c => c.cow_id === id))
                        .filter((c): c is CowListDto => c != null)
                        .map(c => ({...c, use_no: c.use_no, breed_no:c.breed_no }))
        });
    }

    onFinishScheduleEdit() {
        this.setState({
            currentScheduleEvent:{},
            isSchedulePopupShown: false
        });
    }
    onSchedulePopupClose() {
        this.setState({
            currentScheduleEvent:{},
            isSchedulePopupShown: false
        });
    }
    onScheduleLinkClick(link: LinkParam) {
        this.props.history.push(link);
    }

    async onCowChecked(cowIds: Set<number>) {
        await this.setStateAsync({
            checkedCowIds: cowIds,
        });
    }

    onMoveNext(cowId?: number) {
        if (!CommonUtil.assertNotNull(this.state.actionType)) return;

        const cowIds = cowId != null ? new Set([cowId]) : this.state.checkedCowIds;

        const cows = this.state.cows.filter(c => cowIds.has(c.cow_id));
        if (cows.length === 0) {
            console.error("no cow selected", this.state.checkedCowIds);
            return;
        }

        //選択状態を保持
        this.props.rootStore.setSelectedCowIds([...this.state.checkedCowIds.keys()]);

        CowActionType[this.state.actionType].onSelect(cows, this);
    }

    async showSearchCondition() {
        const action = this.state.actionType;
        if (!CommonUtil.assertNotNull(action)) return;

        const cond = defaultCowListCondition();
        cond.selected_house = cowsToHouseCondition(this.state.cows, this.state.checkedCowIds);
        const uses = CowActionType[action].useNoList;
        if (uses != null) {
            cond.uses = uses;
        }

        this.context.handleSetIsLoading(true);
        let programs = this.state.programs;
        if (programs == null) {
            const pgReq = { ranch_id: this.props.rootStore.cur_ranch_id };
            const pgRes = await this.comm().send((await ProgramApi()).getProgramListUsingPOST(pgReq));
            if (pgRes.result !== "OK") return;

            programs = pgRes.data;
        }
        await this.props.rootStore.fetchCowTags(undefined, true);
        this.context.handleSetIsLoading(false);

        this.setState({
            cowSearchFilter: {
                condition: cond,
                output: buildDefaultOutputs(),
            },
            programs
        });
    }

    searchWithCondition(condition: ICowSearchCondition, output: ICowListOutput) {
        this.props.rootStore.setCowListFilter({ condition, output });
        this.props.history.push("/ranch/main");
    }

    render() {
        const action = this.state.actionType;
        if (action == null) {
            return <></>;
        }

        const setCheckedCowIds = (cowIdsOrReturnCowIds:React.SetStateAction<Set<number>>) => {
            if (typeof cowIdsOrReturnCowIds === "object") {
                this.onCowChecked(cowIdsOrReturnCowIds);
            } else {
                this.onCowChecked(cowIdsOrReturnCowIds(this.state.checkedCowIds));
            }
        }

        return (
            <div style={{ height: "100%", display: "flex", flexFlow: "column nowrap", maxWidth:"840px", margin:"0 auto" }}>
                <div className="product" style={{ height: "100%", minHeight: "0" }}>
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix" style={{ paddingBottom: "10px" }} >
                            {/* <!-- BEGIN product-info-header -- > */}
                            <div style={{ display:"flex", alignItems:"center", justifyContent:"space-between", marginBottom:"10px" }}>
                                <div style={{ color:"#969696", fontSize:"0.875rem" }}>牛を選択</div>
                                <button type="button" className={classnames(styles["search-detail"], styles.house)} onClick={()=>this.showSearchCondition()}>
                                    <i className="fas fa-book-open m-r-5"></i>
                                    <span>詳しく探す</span>
                                </button>
                            </div>
                            {/* <!-- END product-info-header --> */}
                            {/* <!-- BEGIN content --> */}
                            <div style={{ flex:1, minHeight:0 }}>
                                <ActionCowSelector
                                    ranchId={this.props.rootStore.cur_ranch_id}
                                    cows={this.state.cows}
                                    setCheckedCowIds={setCheckedCowIds}
                                    checkedCowIds={this.state.checkedCowIds}
                                    singleSelection={CowActionType[action].singleSelect}
                                    onSubmit={cowId => this.onMoveNext(cowId)}
                                />
                            </div>
                            {/* <!-- END content --> */}
                        </div>
                    </div>
                </div>
                <div>
                    <ConditionMenu
                        button={CowActionType[action].name}
                        count={this.state.checkedCowIds.size}
                        canNext={CowActionType[action].singleSelect ? this.state.checkedCowIds.size === 1 : this.state.checkedCowIds.size > 0}
                        moveNext={() => this.onMoveNext(undefined) }
                        nextPage={CowActionType[action].isModal === true ? "modal" : "content"}
                    />
                </div>
                {
                    this.state.isSchedulePopupShown && (
                    <SchedulePopup onClose={this.onSchedulePopupClose} onStartEdit={()=>{}} original={this.state.currentScheduleEvent}
                                   onRegistered={this.onFinishScheduleEdit}
                                   onSplit={() => {}}
                                   isEditing={true} onCowClick={()=>{}}
                                   ranch_id={this.props.rootStore.cur_ranch_id} onResultLinkClick={this.onScheduleLinkClick}
                                   activeCows={this.state.cows}
                                   clinic_id={this.props.rootStore.getClinicIdForMaster()}
                                   rootStore={this.props.rootStore}
                                   />
                )}
                { this.state.cowSearchFilter != null && (
                    <CowSearchPopup isOpen={true}
                        cow_breed={this.props.rootStore.options.cow_breed}
                        cow_use={this.props.rootStore.getCowUses()}
                        condition={this.state.cowSearchFilter.condition}
                        outputs={this.state.cowSearchFilter.output}
                        onClose={() => this.setState({ cowSearchFilter: undefined })}
                        onCloseWithResult={(c,o) => { this.searchWithCondition(c, o) }}
                        onReset={()=>{}}
                        resetButton="すべての牛"
                        comm={this.comm()}
                        ranch_id={this.props.rootStore.cur_ranch_id}
                        user={this.props.rootStore.user}
                        tags={this.props.rootStore.getCowTags()}
                        programs={this.state.programs ?? []}
                    />
                )}
            </div>
        )
    }
}

export default withRouter(withContext(RanchHouses));