import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { IRanchFeed, IFeedType } from '../../stores';
import { calcSolute } from '../../config/util';
import { FeedLastDayEditingAmount, FeedLastDayEditing } from './feed-lastday-popup';
import { usePageStore } from '../../config/page-settings';
import { A, LMT } from '../../config/constant';
import { TimeSelector } from '../../components/parts/time-selector';
import { FeedInputPanel } from './feed-input-panel';
import { ExecutionButton } from '../../components/buttons/execution-button';

export type ModalEditingLastDayFeed = Omit<FeedLastDayEditing, "disabled">;

export interface FeedLastDayEditPopupProps {
    ranchFeedList: Readonly<Readonly<IRanchFeed>[]>;
    feedTypeList: Readonly<Readonly<IFeedType>[]>;
    feed: Readonly<ModalEditingLastDayFeed>;
    onClose:()=>void;
    onEdit:(editingFeed:ModalEditingLastDayFeed)=>void;
}

type TmpEditingAmount = Omit<FeedLastDayEditingAmount, "delivered_raw"|"leftover_raw">;
type TmpEditingFeed = Omit<ModalEditingLastDayFeed, "feed_amount"> & {
    feed_amount: TmpEditingAmount[];
}

export const FeedLastDayEditPopup: React.FC<FeedLastDayEditPopupProps> = (props) => {
    const { showToast, showQuestion } = usePageStore();

    const [ tempFeed, setTempFeed ] = useState<TmpEditingFeed>({...props.feed});

    const onTimeChange = (time: string) => {
        setTempFeed({ ...tempFeed, time: time });
    }

    const onFeedNoChanging = (idx:number, feedNo: number) => {
        if (tempFeed.feed_amount.some((f,i) => i !== idx && f.feed_no === feedNo)) {
            showToast(A.MESSAGE.FEED_ALREADY_ADDED);
            return false;
        }
        return true
    }

    const onRemoveAmountItem = (idx:number) => {
        const amounts = [ 
            ...tempFeed.feed_amount.slice(0, idx),
            ...tempFeed.feed_amount.slice(idx + 1),
        ];
        setTempFeed({ ...tempFeed, feed_amount: amounts });
    }

    const onAddFeedAmount = () => {
        const amounts = [ 
            ...tempFeed.feed_amount,
            newDefaultFeedAmountItem(),
        ];
        setTempFeed({ ...tempFeed, feed_amount: amounts });
    }

    const newDefaultFeedAmountItem = (): TmpEditingAmount => {
        return ({
            delivered: 0,
            leftover: -1,
            feed_no: 0,
            feed_type_no: 0,
        });
    }

    const onCommentChange = (comment: string) => {
        setTempFeed({ ...tempFeed, comment: comment });
    }

    const onCommit = () => {

        if (tempFeed.feed_amount.some(a => a.delivered < (a.leftover ?? 0))) {
            showToast(A.MESSAGE.INVALID_FEED_LEFTOVER);
            return;
        }

        const amounts = tempFeed.feed_amount.map(fa => ({
            ...fa,
            delivered: fa.delivered,
            delivered_raw: calcSolute(fa.feed_no, fa.delivered, props.ranchFeedList),
            leftover: fa.leftover ?? -1,
            leftover_raw: fa.leftover == null ? 0 :calcSolute(fa.feed_no!, fa.leftover, props.ranchFeedList),
        }));

        if (amounts.some(f => f.delivered_raw > LMT.FEEDING.AMOUNT_RAW_MAX
                || f.leftover_raw > LMT.FEEDING.AMOUNT_RAW_MAX)) {
            showQuestion(A.MESSAGE.INVALID_FEED_RAW_AMOUNT, ()=>{}, [ "OK" ]);
            return;
        }

        props.onEdit({...tempFeed, feed_amount: amounts});
    }

    const hasError = (): boolean => {
        if (tempFeed.time == null) return true;
        if (tempFeed.feed_amount.length === 0) return true;
        for(const amount of tempFeed.feed_amount) {
            if(amount.feed_no === 0) return true;
            if(isNaN(amount.delivered)) return true;
            if(amount.leftover != null && isNaN(amount.leftover)) return true;
        }
        return false;
    }

    return (
        <div>
            <Modal isOpen={true} centered={true} scrollable={true}>
                <ModalHeader toggle={props.onClose}>えさの編集</ModalHeader>
                <ModalBody style={{ minHeight: "200px"}}>
                <div className="feed-write">
                    <div className="form-group row treat-write-row">
                        <label className="col-form-label col-md-4 col-xs-4 text-lg-right">えさやり時刻</label>
                        <div className="col-md-4 col-xs-5">
                            <TimeSelector value={tempFeed.time} onChange={val => onTimeChange(val)} />
                        </div>
                    </div>
                    <div>
                        {
                            tempFeed.feed_amount.map((amount, i) => (
                                <FeedInputPanel key={i}
                                    feedTypeList={props.feedTypeList}
                                    ranchFeedList={props.ranchFeedList}
                                    feed={{ ...amount, leftover:amount.leftover }}
                                    onChange={f => setTempFeed({ ...tempFeed, feed_amount: [
                                        ...tempFeed.feed_amount.slice(0, i),
                                        f,
                                        ...tempFeed.feed_amount.slice(i + 1)
                                    ]}) }
                                    onRemove={() => onRemoveAmountItem(i)}
                                    onFeedNoChanging={no => onFeedNoChanging(i, no)}
                                />
                            ))
                        }
                    </div>
                    <div className="form-group row treat-write-row"
                            style={{ display: "flex", marginLeft: "0px", marginRight: "0px" }}>
                        <button style={{ flex: "1" }} className="btn btn-theme btn-sm btn-inverse"
                                onClick={onAddFeedAmount}>
                            <i className="fas fa-lg fa-fw m-r-10 fa-plus-circle"></i>
                            えさ品目を追加
                        </button>
                    </div>
                    <div className="form-group row treat-write-row">
                        <label className="col-form-label col-md-2 col-xs-2 text-lg-right">メモ</label>
                        <div className="col-md-10 col-xs-10">
                            <textarea className="form-control" rows={4}
                                maxLength={LMT.FEEDING.MEMO_LEN}
                                value={tempFeed.comment} onChange={(e) => onCommentChange(e.target.value)}></textarea>
                        </div>
                    </div>
                </div>
                </ModalBody>
                <ModalFooter className="modal-footer-fix">
                    <ExecutionButton type="decide" onClick={onCommit} disabled={ hasError() } />
                </ModalFooter>
            </Modal>
        </div>
    );
}