import React from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

export interface SliderInputProps {
    onChange:(val:number)=>void;
    //スライダー範囲の最小値（min<=sliderMin）
    sliderMin?:number;
    //スライダー範囲の最大値（sliderMax<=max)
    sliderMax?:number;
    min:number;
    max:number;
    step:number;
    value:number;
    onIsValidChange?:(valid:boolean)=>void;
    inputWidth?:number;
}
interface MyState {
    dispVal: number;
    isValid:boolean;
}

export class SliderInput extends React.Component<SliderInputProps, MyState> {

    constructor(props) {
        super(props);

        this.state = {
            dispVal: this.props.value,
            isValid: true
        }

        this.onInputChange = this.onInputChange.bind(this);
        this.onSliderChange = this.onSliderChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            isValid: this.isValid(this.state.dispVal)
        });

        const validVal = this.props.value < this.props.min ? this.props.min : this.props.max < this.props.value ? this.props.max : null;
        if (validVal != null) {
            this.props.onChange(validVal);
        }
    }

    componentDidUpdate(prevProps: SliderInputProps, prevState: MyState) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                dispVal: this.props.value,
                isValid: this.isValid(this.props.value)
            });
        }
        
        if (prevState.isValid !== this.state.isValid) {
            if (this.props.onIsValidChange) {
                this.props.onIsValidChange(this.state.isValid);
            }
        }
    }

    isValid(val:number) {
        return this.props.min <= val && val <= this.props.max;
    }

    onSliderChange(val:number) {
        //※初期値でスライダー範囲外の値がバインドされた際、それによってスライダーが動いてonChangeが発生するので無視する
        if (this.props.sliderMin != null && this.props.value < this.props.sliderMin && val === this.props.sliderMin) return;
        if (this.props.sliderMax != null && this.props.sliderMax < this.props.value && val === this.props.sliderMax) return;

        this.fireOnChangeOrAdjustMe(val);
    }
    onInputChange(e:React.ChangeEvent<HTMLInputElement>) {
        const val = Number(e.target.value);
        if (isNaN(val)) return;

        const isValid = this.isValid(val);

        if (isValid) {
            this.fireOnChangeOrAdjustMe(val);
        } else {
            this.setState({
                dispVal: val,
                isValid: false
            }); 
        }
    }
    fireOnChangeOrAdjustMe(val:number) {
        //異常値から正常値に戻る際、最後に正常値だったときの値と変わらない場合、親にonChangeを投げても更新が走らない
        //ので、自身の表示値を直接なおして終わり
        //（例：最小値が 10 で、 10 -> 9 -> 10 と変更させた場合など）
        if (this.props.value === val) {
            this.setState({
                dispVal: val,
                isValid: this.isValid(val)
            });
        
        } else {
            this.props.onChange(val);
        }
    }

    render() {

        return (
            <div style={{ display: "flex" }}>
                <div style={{ flex: "1", marginTop: "12px" }}>
                    <Slider min={this.props.sliderMin ?? this.props.min} max={this.props.sliderMax ?? this.props.max}
                        value={this.props.value}
                        onChange={this.onSliderChange}
                        step={this.props.step} />
                </div>
                <input type="number" className="form-control form-input-number m-l-10"
                    onFocus={e => e.target.select()}
                    min={this.props.min} max={this.props.max} step={this.props.step}
                    style={{ width: `${this.props.inputWidth ?? 62 }px`, background: this.state.isValid ? "" : "#ffb9b9" }}
                    value={this.state.dispVal}
                    onChange={this.onInputChange}
                    placeholder="" />
            </div>

        );
    }
}

