import { QueryClient } from "react-query";

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false,
            staleTime: 10 * 60 * 1000,
            cacheTime: 60 * 60 * 1000,
        },
    },
});