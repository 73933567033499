import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { A } from '../../config/constant';
import styles from './setting.module.css';
import { IRanchFeed, IFeedType } from '../../stores/RootStore';
import { PageSettings } from '../../config/page-settings';
import { FeedEditPopup } from './feed-edit-popup';
import { withContext } from '../../stores';
import Big from 'big.js';
import { AppState } from '../../app';
import { RanchFeedSortReq, RanchFeedModifyReq, RanchFeedDeleteReq, RanchFeedDao } from '../../api';
import { CommonUtil } from '../../config/util';
import { SortableList } from '../../components/parts/sortable-list';
import { WithQuery, useRanchFeeds, resetRanchFeeds } from '../../stores/fetcher';
import { FetchWaiter, FetchError } from '../../components/content/fetch-state';

interface MyProps extends BaseProps<{id:string}> {
}

interface MyState {
    ranchId?: number;
    sortingList: IFeed[];
    isSorting: boolean;
    isEditing: boolean;
    editingData: IEditingFeed | null;
    feedTypeList: IFeedType[];
    executing: boolean;
}

const renderWashoutStr = (washout_meat_day?, washout_milk_hour?) => {
    if( washout_meat_day && washout_milk_hour) {
        return <> 出荷期限 肉{washout_meat_day}日 乳{washout_milk_hour}時間</>
    }
    if( washout_meat_day) {
        return <> 出荷期限 肉{washout_meat_day}日</>
    }
    if( washout_milk_hour) {
        return <> 出荷期限 乳{washout_milk_hour}時間</>
    }
    return <></>
}

type IFeed = IRanchFeed & {
    readonly feedTypeName: string;
    readonly feedTypeDetailName: string;
    readonly unit_for_price: string;
    readonly unit_raw: string | null;
}
export type IEditingFeed = {
    readonly isNew: boolean;
    feed_type_no?: number;
    readonly feed_no?: number;
    name: string;
    readonly ranch_id: number;
    convert_scale: number | undefined;
    unit_price: number;
    unit: string;
    unit_for_price: string;
    unit_raw: string | null;
    feed_type_detail_no: number | undefined;
    typeDetailList: { feed_type_detail_no:number, name:string }[];
    unit_amount?: number;
    total_price?: number;
    washout_meat_day?: number;
    washout_milk_hour?: number;
}

class FeedMaster extends Base<MyProps, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            isSorting: false,
            sortingList: [],
            isEditing: false,
            editingData: null,
            feedTypeList: [],
            executing: false
        };

        this.startSorting = this.startSorting.bind(this);
        this.finishSorting = this.finishSorting.bind(this);
        this.cancelSorting = this.cancelSorting.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onCancelEdit = this.onCancelEdit.bind(this);
        this.onSubmitEdit = this.onSubmitEdit.bind(this);
        this.startEdit = this.startEdit.bind(this);
        this.onNameEdited = this.onNameEdited.bind(this);
        this.onUnitAmountEdited = this.onUnitAmountEdited.bind(this);
        this.onPriceEdited = this.onPriceEdited.bind(this);
        this.onTypeEdited = this.onTypeEdited.bind(this);
        this.onUnitEdited = this.onUnitEdited.bind(this);
        this.onTypeDetailEdited = this.onTypeDetailEdited.bind(this);
        this.addFeed = this.addFeed.bind(this);
        this.onConvertScaleEdited = this.onConvertScaleEdited.bind(this);
        this.onWashoutMeatDayEdited = this.onWashoutMeatDayEdited.bind(this);
        this.onWashoutMilkHourEdited = this.onWashoutMilkHourEdited.bind(this);
    }

    componentDidMount() {

        this.context.handleSetHeader({ title:"えさ品目の設定" });
        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        this.init();
    }

    componentDidUpdate(prevProps: this['props'], prevState: MyState) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.init();
        }
    }

    private init() {
        const ranch_id = Number(this.props.match.params.id);
        if (this.handleNotAllowAccess(ranch_id, ["MASTER_EDIT"],[])) {
            return;
        }

        this.setState({
            ranchId: ranch_id,
            feedTypeList: this.props.rootStore.options.feed_type
        });
    }

    private buildDispFeedList(dao: RanchFeedDao) : IFeed {
        const tp = this.state.feedTypeList.find(t => t.feed_type_no === dao.feed_type_no);
        return {
            ...dao,
            feedTypeName: tp?.name ?? "",
            feedTypeDetailName: (tp?.feed_type_detail ?? []).find(d => d.feed_type_detail_no === dao.feed_type_detail_no)?.name ?? "",
            //※希釈のあるえさ種別のみ、m_feed_type.unit_for_priceで表示
            unit_for_price: tp?.unit_raw == null ? dao.unit : tp?.unit_for_price ?? "",
            unit_raw: tp?.unit_raw ?? null
        };
    }

    private startSorting(list : IFeed[]) {
        if (this.state.isSorting) {
            console.error("already sorting");
            return;
        }

        this.setState({
            isSorting: true,
            sortingList: [...list]
        });
    }
    private cancelSorting() {
        if (!this.state.isSorting) {
            console.error("not sotring on cancel");
            return;
        }

        this.setState({
            isSorting: false,
            sortingList:[]
        });
    }
    private async finishSorting(oldList: IFeed[]) {
        if (!this.state.isSorting) {
            console.error("not sorting on finish");
            return;
        }
        if (!CommonUtil.assertNotNull(this.state.ranchId)) return;

        const newOrder = this.state.sortingList.map(p => p.feed_no);
        const oldOrder = oldList.map(p => p.feed_no);
        if (JSON.stringify(newOrder) === JSON.stringify(oldOrder)) {
            this.setState({
                isSorting: false,
                sortingList:[]
            });
            return;
        }

        //ソート要求
        this.context.handleSetIsLoading(true);
        const req: RanchFeedSortReq = {
            order: newOrder,
            ranch_id: this.state.ranchId,
            user_id: this.props.rootStore.user.id
        }
        const res = await this.comm().send(() => this.context.postAsync("/ranch/feed/sort", req));
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") return;

        this.setState({
            isSorting: false,
            sortingList:[]
        });

        resetRanchFeeds();
    }

    private addFeed() {
        if (!this.assertNotNull(this.state.ranchId, "ranchId", "addFeed")) return;
        this.setState({
            isEditing: true,
            editingData: {
                isNew: true,
                name: "",
                ranch_id: this.state.ranchId,
                convert_scale: undefined,
                unit_price: 0,
                unit: "",
                feed_type_detail_no: undefined,
                typeDetailList:[],
                unit_for_price: "",
                unit_raw: null,
                unit_amount: 1,
                total_price: 0,
            }
        })
    }

    private startEdit(feed: IFeed) {
        this.setState({
            isEditing: true,
            editingData: {
                isNew: false,
                feed_type_no: feed.feed_type_no,
                feed_no: feed.feed_no,
                name: feed.name,
                ranch_id: feed.ranch_id,
                convert_scale: feed.convert_scale,
                unit_price: feed.unit_price,
                unit: feed.unit,
                unit_raw: feed.unit_raw,
                unit_for_price: feed.unit_for_price,
                unit_amount: 1,
                total_price: feed.unit_price,
                feed_type_detail_no: feed.feed_type_detail_no,
                typeDetailList: this.state.feedTypeList.find(t => t.feed_type_no === feed.feed_type_no)?.feed_type_detail ?? [],
                washout_meat_day: feed.washout_meat_day,
                washout_milk_hour: feed.washout_milk_hour,
            }
        });
    }

    private onNameEdited(name:string) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onNameEdited")) return;

        const data = { ...this.state.editingData};
        data.name = name;
        this.setState({
            editingData: data
        });
    }
    private onTypeEdited(typeCd: number) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onTypeEdited")) return;

        const data = { ...this.state.editingData};
        data.feed_type_no = typeCd;
        const tp = this.state.feedTypeList.find(t => t.feed_type_no === typeCd);
        data.unit = tp?.unit ?? "";
        data.unit_for_price = tp?.unit_for_price ?? "";
        data.unit_raw = tp?.unit_raw ?? null;
        data.typeDetailList = tp?.feed_type_detail ?? [];
        data.feed_type_detail_no = undefined;
        if (data.unit_raw == null) {
            data.convert_scale = undefined;
        }
        this.setState({
            editingData: data,
        });
    }
    private onUnitEdited(unit:string) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onUnitEdited")) return;

        this.setState({
            editingData: { ...this.state.editingData, unit, unit_for_price: unit }
        })
    }
    private onTypeDetailEdited(typeDetailCd: number) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onTypeDetailEdited")) return;

        const data = { ...this.state.editingData};
        data.feed_type_detail_no = typeDetailCd;
        this.setState({
            editingData: data,
        });
    }
    private onUnitAmountEdited(unit_amount:number) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onUnitAmountEdited")) return;
        const data = { ...this.state.editingData};
        data.unit_amount = isNaN(unit_amount) ? undefined : unit_amount;
        data.unit_price = this.calcUnitPrice(unit_amount, data.total_price);
        this.setState({
            editingData: data
        });
    }
    private onPriceEdited(price:number) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onPriceEdited")) return;
        const data = { ...this.state.editingData};
        data.total_price = isNaN(price) ? 0 : price;
        data.unit_price = this.calcUnitPrice(data.unit_amount, price);
        this.setState({
            editingData: data
        });
    }
    private onConvertScaleEdited(scale:number|undefined) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onScaleEdited")) return;

        const data = { ...this.state.editingData};
        data.convert_scale = scale;
        this.setState({
            editingData:data
        });
    }

    private onWashoutMeatDayEdited(washout_meat_day?:number) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onWashoutMeatDayEdited")) return;

        const data = { ...this.state.editingData};
        data.washout_meat_day = washout_meat_day;
        this.setState({
            editingData: data
        });

    }
    
    private onWashoutMilkHourEdited(washout_milk_hour?:number) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onWashoutMilkHourEdited")) return;

        const data = { ...this.state.editingData};
        data.washout_milk_hour = washout_milk_hour;
        this.setState({
            editingData: data
        });

    }    

    private assertNotNull<T>(v: T | null | undefined, name?: string, when?: string): v is T {
        if (v == null) {
            console.error(`${name ?? "value"} is null or undef when ${when ?? "assertion"} is called.`);
            return false;
        }
        return true;
    }
    private calcUnitPrice(unit_amount: number | undefined, total_price: number | undefined ) : number {
        if( unit_amount === undefined || unit_amount === 0 || total_price === undefined
            || isNaN(unit_amount) || isNaN(total_price)) { 
            return 0; 
        }
        const b_unit_amount = new Big(unit_amount ?? 1);
        const b_total_price = new Big(total_price ?? 0);
        Big.DP = 2; // 小数第2位まで
        Big.RM = 1; // 四捨五入
        return Number(b_total_price.div(b_unit_amount))
    } 

    private async onSubmitEdit() {
        const data = this.state.editingData;
        if (!this.assertNotNull(data, "editingData", "submitEdit")) return;

        if (data.name === "") {
            this.context.showToast(A.MESSAGE.NO_FEED_NAME_INPUT);
            return;
        }
        if (data.feed_type_no == null || data.feed_type_no === 0) {
            this.context.showToast(A.MESSAGE.NO_FEED_TYPE_SELECTED);
            return;
        }
        if (data.unit_raw != null && data.convert_scale == null) {
            this.context.showToast(A.MESSAGE.NO_FEED_SCALE_INPUT);
            return;
        }

        console.log("detail", data.feed_type_detail_no);

        try {
            this.setState({ executing: true });

            //更新要求
            this.context.handleSetIsLoading(true);
            const req: RanchFeedModifyReq = {
                convert_scale: data.convert_scale,
                feed_no: data.feed_no ?? 0,
                feed_type_no: data.feed_type_no,
                is_new: data.feed_no == null ? 1 : 0,
                name: data.name,
                ranch_id: data.ranch_id,
                unit_price: data.unit_price,
                user_id: this.props.rootStore.user.id,
                feed_type_detail_no: data.feed_type_detail_no === 0 ? undefined : data.feed_type_detail_no,
                washout_meat_day: data.washout_meat_day,
                washout_milk_hour: data.washout_milk_hour,
                unit: data.unit
            }
            const res = await this.comm().send(() => this.context.postAsync("/ranch/feed/modify", req));
            this.context.handleSetIsLoading(false);
            if (res.result !== "OK") return;

            this.setState({
                isEditing: false,
                editingData: null
            });
        } finally {
            this.setState({ executing: false });
        }

        resetRanchFeeds();
    }

    private async onDelete() {
        const res = await this.context.showDialog("QUESTION", "えさ品目を削除してよろしいですか？", [{ type:"delete" },{ type:"cancel" }]);
        if (res === 0) {
            return this._execDelete();
        }
    }
    //削除実行
    private async _execDelete() {
        const data = this.state.editingData;
        if (!this.assertNotNull(data, "editingData", "delete")) return;
        if (!this.assertNotNull(data.feed_no, "feed_no", "delete")) return;

        this.context.handleSetIsLoading(true);
        const req: RanchFeedDeleteReq = {
            feed_no: data.feed_no,
            ranch_id: data.ranch_id,
            user_id: this.props.rootStore.user.id
        };
        const res = await this.comm().send(() => this.context.postAsync("/ranch/feed/delete", req));
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") return;

        this.setState({
            isEditing: false,
            editingData: null
        });

        resetRanchFeeds();
    }

    private onCancelEdit() {
        this.setState({
            editingData: null,
            isEditing:false
        })
    }

    render() {
        const ranchId = this.state.ranchId;
        if (ranchId == null) return <></>;

        return (
            <WithQuery query={() => useRanchFeeds(ranchId)}>
                {({ data, isError, isLoading }) => {
                    if (isLoading) return <FetchWaiter />
                    if (isError || data == null) return <FetchError />

                    return this.renderPage(data.map(d => this.buildDispFeedList(d)))
                }}
            </WithQuery>
        )
    }


    renderPage(feedList: IFeed[]) {
        return (
            <div className="page-root">
                <div className="product" style={{ height: "100%", "minHeight": "0" }}>
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix p-b-0">
                            <div className={"product-info-header " + styles.header}>
                                <div className={styles["sortable-list-shoulder"]}>
                                    {!this.state.isSorting && (<span className="link" onClick={() => this.startSorting(feedList)}>並び替え</span>)}
                                    {this.state.isSorting && (<span className="link" onClick={() => this.finishSorting(feedList)}>完了</span>)}
                                    {this.state.isSorting && (<span className="link" onClick={this.cancelSorting}>キャンセル</span>)}
                                </div>
                            </div>

                            <div className="ranch-main" style={{flex: "auto"}}>
                                <SortableList
                                    className={styles.list}
                                    items={this.state.isSorting ? this.state.sortingList : feedList}
                                    isSorting={this.state.isSorting}
                                    onSorted={l => this.setState({ sortingList: l })}
                                    listItem={feed => (
                                        <li className={styles["list-item"]}>
                                            <div className={styles["list-item-content"]}>
                                                <div className={styles["list-item-name"]}>{feed.name}</div>
                                                <div className={styles["list-item-detail"]}>
                                                    {feed.feedTypeName}{feed.feedTypeDetailName === "" ? "" : ` (${feed.feedTypeDetailName})`} 1{feed.unit_for_price}あたり {feed.unit_price}円
                                                    {renderWashoutStr(feed.washout_meat_day, feed.washout_milk_hour)}
                                                </div>
                                            </div>
                                            { this.state.isSorting ? (
                                                <div className={styles["list-item-icon"]}><i className="fas fa-bars"></i></div>
                                            ) : (
                                                <div onClick={()=>this.startEdit(feed)} className={styles["list-item-icon"]}><i className="fas fa-pen clickable"></i></div>
                                            )}
                                        </li>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{height:"40px", marginTop:"6px"}} onClick={this.addFeed}>
                    {!this.state.isSorting && (
                        <span className="link">
                            <i className="fa fa-plus"></i>
                            <span> 品目を追加</span>
                        </span>
                    )}
                </div>
                {
                    this.state.ranchId && this.state.isEditing && (
                        <FeedEditPopup
                            data={this.state.editingData}
                            feedTypeList={this.state.feedTypeList}
                            onNameEdited={this.onNameEdited}
                            onPriceEdited={this.onPriceEdited}
                            onUnitAmountEdited={this.onUnitAmountEdited}
                            onTypeEdited={this.onTypeEdited}
                            onUnitEdited={this.onUnitEdited}
                            onClose={this.onCancelEdit}
                            onDelete={this.onDelete}
                            onSubmit={this.onSubmitEdit}
                            onTypeDetailEdited={this.onTypeDetailEdited}
                            onConvertStateEdited={this.onConvertScaleEdited}
                            onWashoutMeatDayEdited={this.onWashoutMeatDayEdited}
                            onWashoutMilkHourEdited={this.onWashoutMilkHourEdited}
                            ranchId={this.state.ranchId}
                            user={this.props.rootStore.user}
                            isSubmitExecuting={this.state.executing}
                        />
                    )
                }
            </div>
        )
    }
}

export default withRouter(withContext(FeedMaster));