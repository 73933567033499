import React from 'react';
import { SortOrder } from '../../pages/feedbulk/BulkCowSortIcon';

interface MyProps {
    state: SortOrder|undefined;
    style?: React.CSSProperties;
    className?: string;
}

export const SortIcon = React.memo((props: MyProps) => {

    const styles: { [ key: string ]: React.CSSProperties } = {
        container: {
            color: "#83aec7",
            fontSize: "1.0rem",
            lineHeight: 1,
            paddingLeft: "2px"
        }
    }

    if (props.state == null) return <></>

    return (
        <div className={props.className} data-testid="sort-icon" style={{ ...styles.container, ...props.style }}>
            <i className={props.state === "Asc" ? "fas fa-sort-up" : "fas fa-sort-down" } />
        </div>
    )
});