import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styles from './edit-memo-popup.module.css';

type PropType = {
    isOpen: boolean;
    close : () => void;
    comment: string;
    onSubmit: (comment: string) => void;
    maxLength: number;
    submitButton?: string;
}

export const EditMemoPopup: React.FC<PropType> = (props) => {

    const [comment, setComment] = useState("");

    useEffect(() => {
        setComment(props.comment);
    }, [ props.comment, props.isOpen ])

    const buttonText = props.submitButton ?? "確定";

    return (
        <div>
            <Modal isOpen={ props.isOpen } className={ styles['modal-wrapper'] } centered={true}>
                <ModalHeader toggle={() => props.close()}>メモ</ModalHeader>
                <ModalBody className={ styles['modal-body'] }>
                    <div style={{flex:1}}>
                        <textarea className="form-control" rows={3} maxLength={props.maxLength} value={comment}
                                onChange={e => setComment(e.target.value)} />
                    </div>
                </ModalBody>
                <ModalFooter className={styles["modal-footer"]}>
                   <button className="btn btn-success" onClick={() => props.onSubmit(comment)}>{buttonText}</button>
                </ModalFooter>
            </Modal>
        </div>
    )
}