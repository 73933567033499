import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { LMT, getSymptomStatus, A } from '../../config/constant';
import { PageSettings } from '../../config/page-settings.js';
import { FreezedArray, saveBase64File, generateKey, ar } from "../../config/util";
import { withContext } from '../../stores';
import moment from 'moment';
import { SymptomApi, KarteListSeriesDto, KarteListReq, CertKarteReq, CertKarteReqCowEventKindEnum } from '../../api';
import { IEventWriteParamCow, ICowsLocationState } from '../../config/history-location-builder';
import { AppState } from '../../app';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { UserTeams } from '../../config/user-teams';
import { CowToDispInfo, LocalNoOrRepNo } from '../../components/parts/cows-popup';
import { RequiredTermSelector } from '../../components/parts/optional-term-selector';
import styles from './cert-karte.module.css';
import { FormInputBlock, FormCheckInputBlock } from '../../components/form/form-input-block';
import { InfoPopup } from '../../components/parts/info-popup';
import { updateListInUserStorage, loadUserStorage } from '../../stores/local-storage';
import { IconRight } from '../../components/parts/icon-right';
import { CertKarteOutputSelectPopup, CertKarteOutput } from './cert-karte-output-select-popup';
import { CertKarteConditionSelectPopup, CertKarteListCondition } from './cert-karte-condition-select-popup';
import { karteSeriesWithSummary, EditingKarteEvent, EditingKarteSeries, CertKarteEditPopup } from './cert-karte-edit-popup';


interface MyState {
    executing : boolean;
    searchFrom: Date;
    searchTo: Date;
    condition: CertKarteListCondition;
    output: CertKarteOutput;
    cowDataList: FreezedArray<CowData>;
    searchedHealingDays: number;
    ranchId: number;
    clinicId: number;
    allCows: FreezedArray<IEventWriteParamCow>;
    ignoredCowReps: string[];
    clinicNameList: string[];
    addressList: string[];
    receiverList: string[];
    isConditionShown: boolean;
    isOutputShown: boolean;
    editingCow?: CowData;
}

type CowData = {
    cow_id: number;
    selected:boolean;
    repNo: string;
    dispName: string;
    seriesList: karteSeriesWithSummary[];
    selectedSeries: EditingKarteSeries;
}

interface MyProps extends BaseProps<{id:string},{}, ICowsLocationState|undefined> {
}

class CertKarte extends Base<MyProps, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        const today = moment().startOf("day").toDate();
        const startOfMonth = moment(today).startOf("month").toDate();

        this.state = {
            executing: false,
            searchFrom: startOfMonth,
            searchTo: today,
            condition: { hasBreeding:true, hasSymptom:true, healingDays:0 },
            output: {
                hasCover:true,
                hasDetail:true,
                publishDay:today,
                month:startOfMonth,
                clinicName: "",
                address: "",
                tel: "",
                receiver: ""
            },
            cowDataList: [],
            searchedHealingDays: 0,
            ranchId: 0,
            clinicId: 0,
            allCows:[],
            ignoredCowReps: [],
            clinicNameList:[],
            addressList:[],
            receiverList:[],
            isConditionShown:false,
            isOutputShown:false,
        };
    }

    componentDidMount() {
        const ranchId = this.props.rootStore.cur_ranch_id;
        const user = this.props.rootStore.user;
        const userTeams = new UserTeams(user);
        const clinic = userTeams.findSingleClinic();
        if (clinic == null) {
            this.props.rootStore.logout();
            return;
        }
        if (this.handleNotAllowAccess(ranchId, ["TREAT_REF_PRICE"], [])) {
            return;
        }
        if (this.handleNotAllowAccess(clinic.team_id, [], ["MASTER_REF"])) {
            return;
        }

        this.context.handleSetHeader({ title:"カルテ出力" });
        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        const allCows = this.props.location.state?.cows ?? [];
        if (allCows.length === 0) return;

        const clinicNameList: string[] = loadUserStorage("cert_my_clinic_name", user.id) ?? [];
        const addressList: string[] = loadUserStorage("cert_my_clinic_address", user.id) ?? [];
        const receiverList: string[] = loadUserStorage("cert_receiver", user.id) ?? [];

        //診療所設定がないときは最新履歴を初期値に
        let clinicName = clinic.name;
        if (clinicName === "") {
            clinicName = clinicNameList[0] ?? "";
        }
        let address = clinic.address ?? "";
        if (address === "") {
            address = addressList[0] ?? "";
        }

        this.setState({
            ranchId,
            clinicId:clinic.team_id,
            output: {
                ...this.state.output,
                clinicName,
                address,
                tel: clinic.tel ?? "",
                receiver: receiverList[0] ?? ""
            },
            allCows,
            clinicNameList,
            addressList,
            receiverList,
        }, () => {
            this.search();
        });
    }

    private setLoading(loading: boolean) {
        this.context.handleSetIsLoading(loading);
        this.setState({ executing: loading });
    }

    private buildEventSummary = (event:EditingKarteEvent) => {
        const date = moment(event.watched_at).format("M/D");
        const treats = event.treats.filter(t => t.selected)
                        .map(t => t.medicine_name ?? t.treat_item_name)
                        .join("、");
        return `・${date} ${treats}`;
    }
    private buildSummary = (series:KarteListSeriesDto): karteSeriesWithSummary | undefined => {
        //転帰
        const statuses = ar.notNull(
                            series.events
                            .map(e => e.status == null ? undefined : getSymptomStatus(e.status))
                        ).filter(s => s.hasNext !== true);
        //正常な転帰のイベントが含まれていないときはこのseriesを破棄
        if (statuses.length === 0) return undefined;

        const status = statuses[statuses.length - 1];
        
        const diseases = ar.flat(series.events.map(e => e.diseases));
        const dis = ar.distinct(diseases).join("、");
        const summaryForList = `${dis} ${status.name}`;

        const from = moment(series.events[0].watched_at).format("YYYY/M/D");
        const to = moment(series.events[series.events.length - 1].watched_at).format("M/D");
        const summaryForSelect = `${from}～${to} ${dis}`;

        return {
            ...series,
            summaryForList,
            summaryForSelect,
            key: generateKey("seri"),
            status: status.no
        };
    }

    private search = async () => {
        const healing_days = this.state.condition.healingDays;
        const req: KarteListReq = {
            ranch_id: this.state.ranchId,
            clinic_id: this.state.clinicId,
            from: moment(this.state.searchFrom).format("YYYY-MM-DD"),
            to: moment(this.state.searchTo).format("YYYY-MM-DD"),
            breeding: this.state.condition.hasBreeding,
            symptom: this.state.condition.hasSymptom,
            cow_ids: this.state.allCows.map(c => c.cow_id),
            healing_days
        };

        this.setLoading(true);
        const res = await this.comm().send((await SymptomApi()).getListForKarteUsingPOST(req));
        this.setLoading(false);

        if (res.result !== "OK") return;

        const resCowIdSet = new Set(res.data?.map(c => c.cow_id) ?? []);
        const ignoredCowReps = this.state.allCows.filter(c => !resCowIdSet.has(c.cow_id))
                                    .map(c => CowToDispInfo(c, false));

        const ordered = ar.orderBy(res.data ?? [], cw => LocalNoOrRepNo(cw));

        const cowDataList: Array<CowData|undefined> = ordered.map(data => {
            const seriesList = ar.notNull(data.series.map(s => this.buildSummary(s)));
            if (seriesList.length === 0) return undefined;  //正常なseriesが1件もない牛は破棄

            return {
                cow_id: data.cow_id,
                selected:true,
                repNo: CowToDispInfo(data, false),
                dispName: CowToDispInfo(data, true),
                seriesList,
                selectedSeries: {
                    ...seriesList[0],
                    events:seriesList[0].events.map(ev => ({
                        ...ev,
                        selected:true,
                        treats: ev.treats.map(t => ({ ...t, selected: true }))
                    }))
                }
            };
        })

        this.setState({
            cowDataList: ar.notNull(cowDataList),
            searchedHealingDays: healing_days,
            ignoredCowReps
        });

    }

    private onSave = async () => {

        const req: CertKarteReq = {
            ranch_id: this.state.ranchId,
            clinic_id: this.state.clinicId,
            clinic_address: this.state.output.address,
            clinic_name: this.state.output.clinicName,
            clinic_tel: this.state.output.tel,
            receiver: this.state.output.receiver,
            published_on: moment(this.state.output.publishDay).format("YYYY-MM-DD"),
            month: moment(this.state.output.month).format("YYYY-MM-DD"),
            has_cover: this.state.output.hasCover,
            has_detail: this.state.output.hasDetail,
            healing_days: this.state.searchedHealingDays,
            cows: this.state.cowDataList.filter(cw => cw.selected).map(cw => ({
                cow_id: cw.cow_id,
                event_kind: cw.selectedSeries.event_kind as unknown as CertKarteReqCowEventKindEnum,    //※ENUMの型定義が違うものになってしまっているが、実際は値も全く同じもの
                found_at: cw.selectedSeries.found_at,
                status: cw.selectedSeries.status,
                events: cw.selectedSeries.events.filter(ev => ev.selected).map(ev => ({
                    event_id:ev.event_id,
                    watched_by_name:ev.watched_by_name ?? "",
                    treat_ids: ev.treats.filter(t => t.selected).map(t => t.id)
                }))
            }))
        };

        //出力件数が多くなるときは確認を表示しておく
        const allEventCount = ar.sum(req.cows.map(c => c.events.length));
        if (allEventCount >= LMT.CERT.KARTE_CONFIRM_EVENT_COUNT) {
            const confirmed = await this.context.showDialog("WARNING", A.MESSAGE.KARTE_CONFIRM_LONG_EXEC, [{ type:"decide", text:"続行" }, { type:"cancel" }]);
            if (confirmed !== 0) return;
        }

        this.setLoading(true);
        const res = await this.comm().send((await SymptomApi()).getCertKarteUsingPOST(req));
        this.setLoading(false);

        if (res.result !== "OK" || res.data == null) return;

        saveBase64File(res.data.body, res.data.file_name, res.data.content_type);

        const userId = this.props.rootStore.user.id;
        updateListInUserStorage("cert_my_clinic_address", userId, req.clinic_address ?? "", LMT.CERT.CLINIC_ADDRESS_HIS_COUNT);
        updateListInUserStorage("cert_my_clinic_name", userId, req.clinic_name ?? "", LMT.CERT.CLINIC_NAME_HIS_COUNT);
        updateListInUserStorage("cert_receiver", userId, req.receiver ?? "", LMT.CERT.RECEIVER_HIS_COUNT);
    }

    private buildIgnoredCowsMsg = (): string[] => {
        if (this.state.ignoredCowReps.length === 0) return [];

        const MAX = 10;
        const isOver = this.state.ignoredCowReps.length > MAX;

        const rtn = [
            "以下の牛の記録は見つかりませんでした",
            ...(isOver
                ? this.state.ignoredCowReps.slice(0, MAX)
                : this.state.ignoredCowReps
            ),
        ];
        if (isOver) {
            rtn.push(`他 ${this.state.ignoredCowReps.length - MAX}頭`);
        }
        return rtn;
    }

    render() {
        const cowsToExport = this.state.cowDataList.filter(c => c.selected).length;
        const isOverLimit = LMT.CERT.KARTE_MAX_COWS < cowsToExport;
        const canExport = cowsToExport !== 0 && !isOverLimit;

        const today = moment().startOf("day");
        const maxStSearchDate = today.toDate();
        const minSearchDate = moment(today).subtract(LMT.CERT.KARTE_MAX_BACK_MONTH, "month").toDate();

        const outputSummary = (this.state.output.hasCover && this.state.output.hasDetail) ? "事故内訳、カルテ"
                            : this.state.output.hasCover ? "事故内訳"
                            : this.state.output.hasDetail ? "カルテ"
                            : ""; 

        return (
            <div className="page-root width-limit">
                <div className="product product-full-height">
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix p-b-0">

                            <div className="product-info-header">
                                <div className="link" onClick={() => this.setState({ isConditionShown: true })}>詳細条件</div>
                                <div className={styles.header}>
                                    <RequiredTermSelector
                                        minStDate={minSearchDate}
                                        maxStDate={maxStSearchDate}
                                        minEdDate={minSearchDate}
                                        maxMonthSpan={LMT.CERT.KARTE_MAX_MONTH_SPAN}
                                        stValue={this.state.searchFrom}
                                        edValue={this.state.searchTo}
                                        onChange={(st,ed) => this.setState({ searchFrom:st, searchTo:ed })}
                                    />
                                    <button className="btn btn-green" onClick={this.search}>検索</button>
                                </div>
                            </div>

                            <div className={"product-body " + styles.body}>
                                <div className={styles["selection-info"]}>
                                    <span className={styles["cow-count"]}>{cowsToExport} / {this.state.allCows.length}頭 選択中</span>
                                    { this.state.ignoredCowReps.length > 0 && (
                                        <InfoPopup iconType="warning" placement="bottom"
                                            message={this.buildIgnoredCowsMsg()}
                                        />
                                    )}
                                    { isOverLimit && <span className={styles["over-limit"]}>1度に{LMT.CERT.KARTE_MAX_COWS}頭の選択が可能です</span>}
                                </div>
                                <FormInputBlock label="出力内容">
                                    <div className={styles["output-summary"]}>
                                        <span>{outputSummary}</span>
                                        <IconRight iconType="edit" className="m-l-10" onClick={() => this.setState({ isOutputShown:true })} />
                                    </div>
                                </FormInputBlock>
                                { this.state.cowDataList.map(cw => (
                                    <FormCheckInputBlock key={cw.cow_id} id={"chkCow" + cw.cow_id}
                                        label={cw.repNo}
                                        checked={cw.selected}
                                        onChange={checked => this.setState({
                                            cowDataList: this.state.cowDataList.map(tmpCw => tmpCw.cow_id !== cw.cow_id ? tmpCw : ({
                                                ...cw,
                                                selected: checked
                                            }))
                                        })}
                                        contentClassName={styles["cow-content"]}
                                    >
                                        <div className={styles.left}>
                                            <div>{cw.selectedSeries.summaryForList}</div>
                                            { cw.selectedSeries.events.filter(e => e.selected).map(ev => (
                                                <div className={styles["event-summary"]} key={ev.event_id}>{this.buildEventSummary(ev)}</div>
                                            ))}
                                        </div>
                                        <IconRight iconType="edit" onClick={() => this.setState({ editingCow:cw })} />
                                    </FormCheckInputBlock>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="button-page-footer">
                    <ExecutionButton type="save" onClick={this.onSave} disabled={this.state.executing || !canExport}>出力</ExecutionButton>
                </div>
                { this.state.isConditionShown && (
                    <CertKarteConditionSelectPopup
                        condition={this.state.condition}
                        onClose={() => this.setState({ isConditionShown:false })}
                        onSubmit={d => this.setState({ condition: d, isConditionShown:false })}
                    />
                )}
                { this.state.isOutputShown && (
                    <CertKarteOutputSelectPopup
                        output={this.state.output}
                        onClose={() => this.setState({ isOutputShown:false })}
                        onSubmit={d => this.setState({ output:d, isOutputShown:false })}
                        addressList={this.state.addressList}
                        clinicNameList={this.state.clinicNameList}
                        receiverList={this.state.receiverList}
                    />
                )}
                { this.state.editingCow && (
                    <CertKarteEditPopup
                        dispName={this.state.editingCow.dispName}
                        onClose={() => this.setState({ editingCow:undefined })}
                        series={this.state.editingCow.seriesList}
                        selectedSeries={this.state.editingCow.selectedSeries}
                        onSubmit={s => this.setState({
                            cowDataList: this.state.cowDataList.map(tmpCw => this.state.editingCow?.cow_id !== tmpCw.cow_id ? tmpCw : ({
                                ...tmpCw,
                                selectedSeries:s
                            })),
                            editingCow: undefined
                        })}
                    />

                )}
            </div>
        )
    }
}

export default withRouter(withContext(CertKarte));