import React from 'react';
import { withRouter } from 'react-router-dom';
import { VETELL_CONST, TeamType, A, TAG_SEARCH_SYMBOL, LMT } from '../../config/constant';
import { withContext, IUser, CowForSelect } from '../../stores';
import { BaseProps } from '../content/base';
import Select, { components} from 'react-select';
import { SearchedCowPopup } from './searched-cow-popup';
import { CowDetailDto, DeliveryApi, BreedingApi, TeamApi, ClinicApi } from '../../api';
import { PageSettings } from '../../config/page-settings';
import { AppState } from '../../app';
import { EventData } from '../../pages/cow/cow-info';
import { IDeliveryHis, CrossHistory } from '../../pages/cow/cow-event';
import moment from 'moment';
import { observer } from 'mobx-react';
import styles from './header.module.css';
import classnames from 'classnames';
import { historyLocation } from '../../config/history-location-builder';
import AcceptPopup from './accept-popup';
import { hasClinicAuth, hasRanchAuth } from '../../config/auth-checker';
import { Communicator } from '../../api/communicator';
import { CSSTransition } from 'react-transition-group';
import { AGE_MODE, CommonUtil } from '../../config/util';
import { IUserTeam, UserTeams } from '../../config/user-teams';
import { NewRanchPopup } from './new-ranch-popup';

const MIN_LENGTH_TO_SEARCH_BY_ID = 4;

const $ = require("jquery");

const PageIcon = React.memo(({ iconType, className }: {
    iconType: "ranch"|"clinic",
    className?:((iconType:"ranch"|"clinic") => string) | string,
}) => {
    const cls = className == null ? undefined : (typeof className === "string") ? className : className(iconType);

    if (iconType === "ranch") {
      return <img src="/assets/img/ranch.png" className={cls} />;
    }
    
    return <i className={classnames("fas fa-hospital fa-2x", cls)} />;
});

export type ISearchedCow = Omit<CowDetailDto, "event_list"> & {
    event_list: EventData[];
}

export interface HeaderProps extends BaseProps {
    onBack: ()=> void;
    showBackBtn: boolean;
    pageIconType: "ranch"|"clinic";
    pageTitle: string;
    cowSearchInput?: string;
    onCowSearchInputChange: (e: string) => void;
}
interface MyState {
    isSearchVisible: boolean;
    searchedCowInfoSet?: Readonly<SearchedCowInfoSet>;
    acceptInfo?: { teamType: TeamType, teamId: number, fromUserId: string };
    appVer?: string;
    isNewRanchPopupShown: boolean;
    executing: boolean;
}

export interface SearchedCowInfoSet {
    cow: Readonly<ISearchedCow>;
    deliveryHis: Readonly<IDeliveryHis>[];
    crossHis: Readonly<CrossHistory>[];
}

/**
 * 指定した牛の簡易情報を取得する
 * @param cow_id 
 * @param eventCount 
 */
export const loadCowSummary = async (ranch_id: number,
                                context: AppState,
                                cow_id: number,
                                eventCount: number,
                                logout:()=>void,
                                showsError = true): Promise<SearchedCowInfoSet|null> => {

    context.handleSetIsLoading(true);

    try {
        const comm = new Communicator({ logout, ...context });
        const cowInfo = await loadCow(ranch_id, cow_id, context, comm, eventCount, showsError);
        if (cowInfo == null) return null;

        //交配記録があるとき
        let crossHis: CrossHistory[] | undefined;
        let deliveryHis: IDeliveryHis[] | undefined;
        if (cowInfo.event_list.some(e => ("cross" in e) && e.cross != null)) {
            crossHis = await loadCrossHistory(ranch_id, cow_id, comm, showsError);
            deliveryHis = await loadDeliveryHistory(ranch_id, cow_id, comm, showsError);
            if (crossHis == null || deliveryHis == null) {
                return null;
            }
        }
        return { cow:cowInfo, deliveryHis: deliveryHis ?? [], crossHis: crossHis ?? [] };

    } finally {
        context.handleSetIsLoading(false);
    }
}

const loadCow = async (ranch_id: number, cow_id: number, context: AppState, comm: Communicator, eventCount: number, showsError: boolean) => {
    const detail = eventCount <= 0 ? 0 : 1;
    const url = `/cow/${cow_id}?ranch_id=${ranch_id}&is_detail=${detail}&amount_days=0`
        + (detail === 0 ? "" : `&event_count=${eventCount}`);
    const res = await comm.send(() => context.postAsync(url, {}), { showsErrorMessage: showsError });
    return res.data as ISearchedCow;
}

const loadDeliveryHistory = async(ranch_id: number, cow_id: number, comm: Communicator, showsError: boolean) => {

    const res = await comm.send((await DeliveryApi()).getHistoryListUsingPOST1({ranch_id, cow_id}), { showsErrorMessage: showsError });
    if (res.result !== "OK") return undefined;

    return (res.data?.list ?? []).map<IDeliveryHis>(h => ({
        delivered_at: moment(h.delivered_at).toDate(),
        delivery_id: h.delivery_id!
    }));
}

const loadCrossHistory = async(ranch_id: number, cow_id: number, comm: Communicator, showsError: boolean) => {

    const res = await comm.send((await BreedingApi()).getBreedingCrossHistoryUsingPOST({ranch_id, cow_id}), { showsErrorMessage: showsError });
    if (res.result !== "OK") return undefined;

    return (res.data ?? []).map(c => ({
        ...c,
        watched_at: moment(c.watched_at).toDate(),
    }));
}


@observer
// @ts-ignore
class Header extends React.Component<HeaderProps, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);
        this.state = {
            isSearchVisible: false,
            isNewRanchPopupShown: false,
            executing: false
        };

        this.onBack = this.onBack.bind(this);
        this.onLogout = this.onLogout.bind(this);
        this.onMenu = this.onMenu.bind(this);
        this.filterCows = this.filterCows.bind(this);
        this.onGrantAuthority = this.onGrantAuthority.bind(this);
        this.accept = this.accept.bind(this);
        this.deny = this.deny.bind(this);
        this.canAcceptThis = this.canAcceptThis.bind(this);
        this.hasRequest = this.hasRequest.bind(this);
        this.loadRequest = this.loadRequest.bind(this);
        this.onNewRanchSubmit = this.onNewRanchSubmit.bind(this);
    }

    componentDidMount() {
        //dropdownの内側をクリックしたときにもdropdownが閉じてしまう問題の対処
        $("." + styles["dropdown-menu"]).on("click",  e => {
            //クリックを受け付ける対象リスト
            const classList = [ "clickable", "link" ];
            const tagList = [ "button", "i", "a" ];
            const containerClassList = [ styles.selectable ];

            const elm = e.target as HTMLElement;
            if ([...elm.classList].some(c => classList.includes(c))) return;
            if (tagList.includes(elm.tagName.toLowerCase())) return;

            let parent = elm.parentElement;
            while (parent != null) {
                if (parent.classList.contains(styles["dropdown-menu"])) break;
                if ([...parent.classList].some(c => containerClassList.includes(c))) return;
                parent = parent.parentElement;
            }

            e.stopPropagation();
        });

        this.setState({ appVer: process.env.REACT_APP_VERSION });
    }

    componentDidUpdate(prevProps: this["props"], prevState: this["state"]) {
        if (prevProps.pageTitle !== this.props.pageTitle || prevProps.showBackBtn !== this.props.showBackBtn) {
            if (this.state.isSearchVisible) {
                this.setState({ isSearchVisible: false });
            }
        }
        if (this.props.cowSearchInput !== prevProps.cowSearchInput && (this.props.cowSearchInput ?? "") !== "") {
            this.setState({
                isSearchVisible:true
            });
        }

        //※ react-selectのautoFocusではマウント時しかフォーカスされないので、自前でフォーカスを処理
        //  （refの参照が取得されるよう一瞬遅延させる）
        if (!prevState.isSearchVisible && this.state.isSearchVisible) {
            setTimeout(() => {
                this.selectRef.current?.focus();
            }, 10);
        }
    }

    selectRef = React.createRef<any>();

    filterCows(item: { label: string, value: number, data:CowForSelect }, input: string) {

        if (input === "") return true;
        if (input.startsWith(TAG_SEARCH_SYMBOL)) {
            const tName = input.slice(TAG_SEARCH_SYMBOL.length).trimRight();
            //名前の完全一致で検索
            const tag = this.props.rootStore.getCowTags().find(t => t.tag_name === tName)
            if (tag == null) return false;
            return item.data.tags.includes(tag.tag_id);
        }

        if (item.label.includes(input)) return true;

        if (input.length >= MIN_LENGTH_TO_SEARCH_BY_ID) {
            if (item.data.traceId.includes(input)) return true;
        }

        return false;
    }

    onBack() {
        this.props.onBack();
    }

    onMenu(id: number) {
        this.props.history.push("/menu/" + id);
    }
    onCalendar(id: number) {
        this.props.history.push(historyLocation.toCalendar(id));
    }

    onLogout() {
        this.props.rootStore.logout();
    }

    async onCowSearched(cow_id: number) {
        this.setState({ isSearchVisible: false });

        const info = await loadCowSummary(this.props.rootStore.cur_ranch_id, this.context, cow_id, 5, this.props.rootStore.logout);
        if (info == null) return;

        await this.props.rootStore.fetchSettings();

        this.setState({
            searchedCowInfoSet: info
        });
    }

    private async loadRequest() {

        this.context.handleSetIsLoading(true);
        const res = await this.props.rootStore.fetchUserInfo();
        this.context.handleSetIsLoading(false);
        if (!res) {
            this.context.showToast(A.MESSAGE.FAILED_TO_LOAD_DATA);
        }
    }

    async accept(team_type:TeamType, user_id:string, team_id:number) {
        const result = await this.context.showDialog("QUESTION", '参加申請を承認してよろしいですか？',
                                                [{ type:"decide", text:"承認" }, { type:"cancel" }]);
        if (result === 0) {
            this.acceptRequset(team_type, user_id, team_id);
        }
    }       

    private async acceptRequset(team_type:TeamType, user_id:string, team_id:number) {
        const comm = new Communicator({ logout: this.props.rootStore.logout, ...this.context });
        this.context.handleSetIsLoading(true);
        const res = await comm.send((await TeamApi()).acceptRequestUsingPOST({from_user_id: user_id, team_id: team_id }), { excludedErrCodes: [A.ERR_CODE.DUPLICATED] });
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") return;

        await this.loadRequest();

        if (res.code === A.ERR_CODE.DUPLICATED) {
            this.context.showToast(A.MESSAGE.TEAM_REQUEST_ALREADY_ACCEPTED);
            return;
        }

        this.setState({ acceptInfo: { teamType: team_type, teamId: team_id, fromUserId: user_id } });
    }

    async deny(user_id:string, team_id:number) {
        const result = await this.context.showDialog("QUESTION", '参加申請を拒否してよろしいですか？',
                                                [{ type:"delete", text:"拒否" }, { type:"cancel" }]);
        if (result === 0) {
            this.deleteRequest(user_id, team_id);
        }
    }

    private async deleteRequest(user_id:string, team_id:number) {
        const comm = new Communicator({ logout: this.props.rootStore.logout, ...this.context });
        this.context.handleSetIsLoading(true);
        const res = await comm.send((await TeamApi()).deleteRequestUsingPOST({from_user_id: user_id, team_id: team_id }));
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") return;

        await this.loadRequest();
    }

    private canAccept() {
        const user = this.props.rootStore.user;
        return　new UserTeams(user).allOfficialTeams().some(t => this.canAcceptThis(t));
    }
    private canAcceptThis(team: IUserTeam) {
        const user = this.props.rootStore.user;

        if (team.isRanch) {
            return hasRanchAuth("USER_MANAGEMENT", team.team_id, user);
        }
        return hasClinicAuth("USER_MANAGEMENT", team.team_id, user);
    }

    private hasRequest() {
        return new UserTeams(this.props.rootStore.user).allOfficialTeams().some(t => (t.requests ?? []).length > 0);
    }

    private onGrantAuthority() {
        this.props.history.push(historyLocation.toTeamAuthority(this.state.acceptInfo!.teamId, this.state.acceptInfo!.fromUserId))
        this.setState({acceptInfo: undefined});
    }

    private async onNewRanchSubmit(name: string) {
        const clinic = new UserTeams(this.props.rootStore.user).findSingleClinic();
        if (!CommonUtil.assertNotNull(clinic, "clinic")) return false;

        try {
            this.setState({ executing: true });

            this.context.handleSetIsLoading(true);
            const comm = new Communicator({ logout: this.props.rootStore.logout, ...this.context });
            const res = await comm.send((await ClinicApi()).createRanchUsingPOST({ clinic_id: clinic.team_id, name }));

            if (res.result !== "OK") return;

            await this.props.rootStore.fetchUserInfo();

            this.setState({ isNewRanchPopupShown:false });

        } finally {
            this.context.handleSetIsLoading(false);
            this.setState({ executing: false });
        }
    }

    render() {

        const { activeCowsForSelect } = this.props.rootStore;

        const Option = (props: { data: CowForSelect, selectProps: { inputValue:string }}) => {
            const input = props.selectProps.inputValue;

            if (input.length < MIN_LENGTH_TO_SEARCH_BY_ID
                || input.startsWith(TAG_SEARCH_SYMBOL)) {
                return <components.Option {...props} />
            }

            const idIdx = props.data.traceId.indexOf(input);

            return (
                <components.Option {...props}>
                    <div>
                        <div>{props.data.label}</div>
                        { 0 <= idIdx && (
                            <div className={styles["option-trace"]}>
                                <span>{props.data.traceId.slice(0, idIdx)}</span>
                                <span className={styles.input}>{input}</span>
                                <span>{props.data.traceId.slice(idIdx + input.length)}</span>
                            </div>
                        )}
                    </div>
                </components.Option>
            )
        }

        return (
            <div id="header" className={styles.header}>
                <CSSTransition classNames="fadein"
                    timeout={this.state.isSearchVisible ? 0 : 400}
                    in={!this.state.isSearchVisible}
                    unmountOnExit={true}>

                    <div className={styles.container}>
                        <div className={classnames(styles.back, "clickable", { [styles.shown]:this.props.showBackBtn })} onClick={() => this.onBack()}>
                            <i className="fas fa-lg fa-fw fa-caret-left"></i>
                            <span style={{ marginLeft: "-5px" }}>Back</span>
                        </div>

                        <div className={styles.title}>
                            <PageIcon iconType={this.props.pageIconType} className={t => t === "ranch" ? styles["header-ranch-icon"] : "m-r-5"} />
                            <span>
                                <span style={{ color: VETELL_CONST.PRIMARY_COLOR }}>{this.props.pageTitle}</span>
                            </span>
                        </div>
                        <div className={styles.icons}>
                            { this.canAccept() && (
                                <div className={classnames("dropdown", styles.dd)}>
                                    <div data-toggle="dropdown" className={classnames(styles.ddtoggle, "clickable")} data-offset="20,0">
                                        <span className="arrow-spacer" />
                                        <i className={classnames(styles.notification, this.hasRequest() ? styles.request : "")}></i>
                                        <span className="arrow-top" />
                                    </div>
                                    <div className={classnames("dropdown-menu dropdown-menu-right", styles["dropdown-menu"])}>
                                        <DropDownAccept
                                            accept={this.accept}
                                            deny={this.deny}
                                            canAcceptThis={this.canAcceptThis}
                                            hasRequest={this.hasRequest}
                                            loadRequest={this.loadRequest}
                                            user={this.props.rootStore.user}
                                        />
                                    </div>
                                </div>
                            )}

                            <div>
                                <div className="clickable" onClick={()=>this.setState({isSearchVisible:true})}>
                                    <i className="fas fa-search" />
                                </div>
                            </div>

                            <div className={classnames("dropdown", styles.dd)} data-testid="cart-icon">
                                <div data-toggle="dropdown" className={classnames(styles.ddtoggle, "clickable")} data-offset="10,0">
                                    <span className="arrow-spacer" />
                                    <i className="fas fa-ellipsis-v" />
                                    <span className="arrow-top" />
                                </div>
                                <div className={classnames("dropdown-menu dropdown-menu-right", styles["dropdown-menu"]) }>
                                    <DropDownMenu
                                        user={this.props.rootStore.user}
                                        currentRanchId={this.props.rootStore.cur_ranch_id}
                                        onUserSetting={() => this.props.history.push(historyLocation.toUserSetting())}
                                        onClinicSetting={id => this.onMenu(id)}
                                        onClinicCalendar={id => this.onCalendar(id)}
                                        logout={this.onLogout}
                                        appVer={this.state.appVer}
                                        onNewRanchAdd={() => this.setState({ isNewRanchPopupShown:true })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </CSSTransition>

                <CSSTransition classNames="fadein"
                    timeout={this.state.isSearchVisible ? 400 : 0}
                    onEntering={() => {}}
                    in={this.state.isSearchVisible}
                    unmountOnExit={true}>

                    <div className={classnames(styles.container, styles["container-search"])}>
                        <div className={styles.search} onClick={e => e.stopPropagation()}>
                            <i className="fas fa-search m-r-5" />
                            <span>牛を検索</span>
                            <Select 
                                className={styles["search-select"]}
                                ref={this.selectRef}
                                placeholder="耳標・名前" noOptionsMessage={()=>"一致なし"}
                                onChange={e => { if (e != null) this.onCowSearched(e.value)} }
                                inputValue={this.props.cowSearchInput}
                                onInputChange={this.props.onCowSearchInputChange}
                                value={null}
                                isClearable={true}
                                openMenuOnFocus={true}
                                styles={{
                                    control:(provided)=>({
                                        ...provided,
                                        border: "2px solid rgb(206,212,218)"
                                    })
                                }}
                                filterOption={this.filterCows}
                                options={activeCowsForSelect}
                                components={{ Option }}
                                onKeyDown={e => {
                                    //スペース押下によって選択されてしまわないように
                                    if (e.keyCode === 32 && e.target.value === "") {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            <i className={classnames("fas fa-times clickable", styles["search-close"])}
                                onClick={() => this.setState({ isSearchVisible: false })}
                            />
                        </div>
                    </div>
                </CSSTransition>
                { this.state.searchedCowInfoSet && (
                    <SearchedCowPopup onClose={()=>this.setState({searchedCowInfoSet:undefined})}
                        cow={this.state.searchedCowInfoSet.cow}
                        ageMode={this.props.rootStore.getSettings()?.age_mode ?? AGE_MODE.BIRTHDAY}
                        ranch_id={this.props.rootStore.cur_ranch_id}
                        user={this.props.rootStore.user}
                        deliveryHis={this.state.searchedCowInfoSet.deliveryHis}
                        crossHis={this.state.searchedCowInfoSet.crossHis}
                        useList={this.props.rootStore.options.cow_use}
                        breedList={this.props.rootStore.options.cow_breed}
                        feedTypeList={this.props.rootStore.options.feed_type}
                        fecesColorList={this.props.rootStore.options.feces_color}
                        fecesStateList={this.props.rootStore.options.feces_state}
                        treatKindList={this.props.rootStore.options.treat_kind}
                        onCowLinkClick={()=> {
                            const cowId = this.state.searchedCowInfoSet!.cow.cow_id;
                            this.setState({ searchedCowInfoSet: undefined });
                            this.props.history.push(historyLocation.toCowInfo(cowId));
                        }}
                    />
                )}
                { this.state.acceptInfo && (
                    <AcceptPopup 
                        onClose={()=>this.setState({ acceptInfo: undefined })}
                        onGrantAuthority={this.onGrantAuthority}
                    />
                )}
                { this.state.isNewRanchPopupShown && (
                    <NewRanchPopup
                        onClose={() => this.setState({ isNewRanchPopupShown: false })}
                        onSubmit={this.onNewRanchSubmit}
                        isSubmitExecuting={this.state.executing}
                    />
                )}
            </div>
        )
    }
}

const DropDownMenu = (props: {
    user: IUser,
    currentRanchId: number,
    logout: () => void,
    onUserSetting: () => void,
    onClinicSetting: (teamId: number) => void,
    onClinicCalendar: (teamId: number) => void,
    appVer?: string,
    onNewRanchAdd: () => void
}) => {
    const teams = new UserTeams(props.user);
    const clinic = teams.findSingleClinic();
    const canAddRanch = clinic != null
                    && hasClinicAuth("MASTER_EDIT", clinic.team_id, props.user)
                    && teams.getUnofficialTeamCount() < LMT.RANCH.UNOFFICIAL_COUNT_MAX;

    return (<div>
        <div className={classnames(styles["cart-header"], styles.teams)}>
            { props.appVer && (<div className={styles.version}>v{props.appVer}</div>)}
            <div className={styles["user-info"]}>
                <img src="/assets/img/user/user-1.jpg" className={styles["user-img"]} alt="" />
                <span style={{ marginLeft:"4px" }}>{props.user.name}</span>
                <i className="fas fa-cog clickable p-5"
                        style={{ fontSize:"1.1rem", color:"#666" }}
                        data-testid="user-setting"
                        onClick={props.onUserSetting}
                />
            </div>
            <a href="#" onClick={props.logout} style={{ padding:"0 4px" }}>ログアウト</a>
        </div>
        <div className={styles["cart-body"]}>
            <ul className={styles["team-list"]}>
                {
                    teams.allTeams().map(team => (
                        <TeamListItem key={team.team_id}
                            isCurrent={team.team_id === props.currentRanchId}
                            teamType={team.isRanch ? (("team_symbol" in team) ? "ranch" : "unofficial-ranch") : "clinic"}
                            name={team.name}
                            teamId={team.team_id}
                            symbol={("team_symbol" in team) ? team.team_symbol : undefined}
                            notAccepted={false}
                            onClinicSettingClick={!team.isRanch && hasClinicAuth("MASTER_EDIT", team.team_id, props.user) ? props.onClinicSetting : undefined}
                            onClinicCalendarClick={!team.isRanch && hasClinicAuth("MASTER_REF", team.team_id, props.user) ? props.onClinicCalendar : undefined}
                        />
                    ))
                }
                {
                    props.user.requests.map((value) => (
                        <TeamListItem key={value.team_id}
                            isCurrent={false}
                            teamType={value.team_type === "clinic" ? "clinic" : "ranch"}
                            notAccepted={true}
                            name={value.name}
                            teamId={value.team_id}
                            symbol={value.team_symbol}
                        />
                    ))
                }
            </ul>
            { canAddRanch && (
                <div className={styles["add-unofficial"]}>
                    <button className="btn btn-success" onClick={props.onNewRanchAdd}>vetell未加入牧場の追加</button>
                </div>
            )}
            <div className={styles["search-team"]}>
                <a href="/team/search">組織への参加を申請する</a>
            </div>
        </div>
    </div>)
}

const TeamListItem = (props: {
    teamType: "ranch"|"clinic"|"unofficial-ranch",
    notAccepted: boolean,
    teamId: number,
    symbol?: string,
    name: string,
    isCurrent: boolean,
    onClinicSettingClick?: (teamId: number) => void,
    onClinicCalendarClick?: (teamId: number) => void,
}) => {

    const isRanch = props.teamType !== "clinic";

    const canClick = isRanch && !props.notAccepted;

    const onClick = () => {
        if (!canClick) return;
        window.location.href = "/top/" + props.teamId;
    }

    return (
        <li data-testid="team"
            className={classnames({[styles.current]:props.isCurrent, [styles.joining]:props.notAccepted, [styles.selectable]:canClick})}
            onClick={onClick}
        >
            <div className={styles["team-icon"]}>
                <PageIcon iconType={isRanch ? "ranch" : "clinic"} className={t => t === "ranch" ? styles.ranch : styles.clinic} />
            </div>
            <div className={styles["team-info"]}>
                <div className={styles["team-name"]}>{props.name}</div>
                <div data-testid="team-symbol" className={styles.symbol}>
                    <span>{props.teamType === "unofficial-ranch" ? "（未加入）" : props.symbol}</span>
                    { props.notAccepted && (<span>（参加申請中）</span>)}
                </div>
            </div>
            { !isRanch && !props.notAccepted && (<>
                { props.onClinicCalendarClick != null && (
                    <div className={classnames("clickable", styles["team-action"])} onClick={() => props.onClinicCalendarClick!(props.teamId)}>
                        <i className="far fa-calendar-alt" />
                    </div>
                )}
                { props.onClinicSettingClick != null && (
                    <div className={classnames("clickable", styles["team-action"])} onClick={() => props.onClinicSettingClick!(props.teamId)}>
                        <i className="fas fa-cog" />
                    </div>
                )}
            </>)}
        </li>
    )

}

const DropDownAccept = (props: {
    loadRequest: () => void,
    hasRequest: () => boolean,
    user: IUser,
    canAcceptThis: (team: IUserTeam) => boolean,
    accept: (team_type:TeamType, user_id:string, team_id:number) => void,
    deny: (user_id:string, team_id:number) => void,


}) => {

    return (<div>
        <div className={styles["cart-header"]}>
            <span className={styles["cart-title"]}>参加申請者一覧</span>
            <button className="btn btn-warning btn-xs m-l-10" onClick={() => props.loadRequest()}>更新<i className="fas fa-redo m-l-5"></i></button>
        </div>
        <div className={styles["cart-body"]}>
            <ul className={styles["cart-item"]}>
                {
                    !props.hasRequest() && (
                        <li key={0}>
                            <div className={styles["cart-item-info"]}>
                                <h4>参加申請はありません。</h4>
                            </div>
                        </li>
                    )
                }
                { new UserTeams(props.user).allOfficialTeams()
                        .filter(team => props.canAcceptThis(team)) 
                        .map(team => (
                            (team.requests??[]).map((value) => (                                                        
                                <li key={value.from_user_id}>
                                    <div>
                                        <i className="fas fa-info-circle m-r-10" style={{fontSize:"x-large"}}></i>
                                    </div>
                                    <div className={styles["cart-item-info"]}>
                                        <h4>{value.from_user_name}さんから{team.name}への参加申請が届きました。</h4>
                                    </div>
                                    <div className={styles["cart-item-info"]}>
                                        <button className="btn btn-success m-l-5 text-nowrap" onClick={() => props.accept(team.isRanch ? "ranch" : "clinic", value.from_user_id, team.team_id)}>許可</button>
                                    </div>
                                    <div className={styles["cart-item-info"]}>
                                        <button className="btn btn-danger m-l-5 text-nowrap" onClick={() => props.deny(value.from_user_id, team.team_id)}>拒否</button>
                                    </div>
                                </li>
                            ))
                ))}
            </ul>
        </div>
    </div>);

}


export default withRouter(withContext(Header));