import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import { FreezedArray, ar } from '../../config/util';
import { EditingTag } from '../../components/tag-edit-popup/tag-edit-popup';

export interface CowTagEditorProps {
    containerStyle?: React.CSSProperties;
    allTags: Readonly<Readonly<ITag>[]>;
    tag_ids: Readonly<number[]>;
    onCalcel: () => void;
    onSubmit: (tags: EditingTag[]) => void;
}

interface ITag {
    tag_id: number;
    tag_name: string;
}

export const CowTagEditor: React.FC<CowTagEditorProps> = (props) => {
    const [ currentTags, setCurrentTags ] = useState<EditingTag[]>([]);

    useEffect(() => {
        const tags = ar.notNull(props.tag_ids.map(id => props.allTags.find(t => t.tag_id === id)));
        setCurrentTags(tags);

    }, [ props.tag_ids ]);

    const onSubmit = () => {
        props.onSubmit(currentTags);
    };

    const defaultContainerStyle = ({
        padding:"6px", 
        background:"#fafafa", 
        border:"1px solid lightgray", 
        borderRadius:"4px"
    });
    return (
        <div style={ { ...defaultContainerStyle, ...props.containerStyle } } data-testid="cow-tag-editor">
            <CowTagMultiInput
                className="tag-edit-select"
                allTags={props.allTags}
                tags={currentTags}
                onChange={setCurrentTags}
            />
            <div style={{ display:"flex", justifyContent:"space-between", marginTop:"4px" }}>
                <div className="link" onClick={ props.onCalcel }>キャンセル</div>
                <button type="button" onClick={ onSubmit } className="btn btn-sm btn-success">確定</button>
            </div>
        </div>
    );
}


type CowTagOption = { label:string, value:string, data?: ITag }
type CowTagChangedOption = CowTagOption & { __isNew__? : boolean };
export const CowTagMultiInput = React.memo((props: {
    allTags: FreezedArray<ITag>;
    tags: FreezedArray<EditingTag>;
    onChange: (tags: EditingTag[]) => void;
    className?: string;
}) => {
    const options:CowTagOption[] = props.allTags.map(t => ({ label:t.tag_name, value:t.tag_name, data:t }));
    const value:CowTagOption[] = props.tags.map(t => ({ value:t.tag_name, label:t.tag_name, data:t.tag_id == null ? undefined : ({ tag_id:t.tag_id, tag_name:t.tag_name}) }));

    return (
        <CreatableSelect
            className={props.className}
            value={value}
            isMulti={true}
            placeholder="タグを追加"
            isClearable={false}
            formatCreateLabel={val => `タグ「${val}」を作成`}
            onChange={(vals?: CowTagChangedOption[]) => props.onChange((vals ?? []).map(v => ({ tag_name:v.label, tag_id:v.data == null ? undefined : v.data.tag_id }) ))}
            styles={{
                control:(provided)=>({
                    ...provided,
                    border: "2px solid rgb(206,212,218)"
                })
            }}
            options={options}
        />
    )
});