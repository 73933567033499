import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { RanchTagDto } from '../../api';
import { FreezedArray, ar, CommonUtil } from '../../config/util';
import baseStyles from './tag-edit-popup.module.css';
import { SingleCreatableSelect } from '../../components/parts/single-creatable-select';
import { ExecutionButton } from 'components/buttons/execution-button';

export type EditingTag = { tag_id: number | undefined, tag_name: string };
type Target<TId extends string | number> = { id: TId, tags: FreezedArray<RanchTagDto> };

interface MyProps<TId extends string | number> {
    allTags: FreezedArray<RanchTagDto>;
    onClose: () => void;
    onSubmit: (changedTargets: { id: TId, tags: EditingTag[] }[]) => void;
    targets: FreezedArray<Target<TId>>;
    isExecuting : boolean;
    targetNames: Readonly<string[]>;
    targetKindName: string;
    submitType: "保存" | "確定";
    targetUnit:string;
    targetNameOmitBorderCount?:number;
}

export const TagEditPopup = <TId extends number | string>(props: MyProps<TId>) => {
    const [ allTagMap, setAllTagMap ] = useState<Map<number, RanchTagDto>>(new Map());
    const [ everyTags, setEveryTags ] = useState<FreezedArray<EditingTag>>([]);
    const [ partTags, setPartTags ] = useState<FreezedArray<RanchTagDto>>([]);
    const [ selectableTags, setSelectableTags ] = useState<RanchTagDto[]>([]);

    useEffect(() => {
        setAllTagMap(ar.toMap([...props.allTags], t => t.tag_id, t => t));

    }, [ props.allTags ]);

    useEffect(() => {
        if (props.targets.length === 0 || allTagMap.size === 0) {
            setEveryTags([]);
            setPartTags([]);
            return;
        }

        let every = props.targets[0].tags;
        props.targets.slice(1).forEach(st => {
            every = every.filter(t => st.tags.some(tt => tt.tag_id === t.tag_id));
        });

        const partIds = ar.distinct(ar.flat(props.targets.map(st => st.tags.map(t => t.tag_id))))
                            .filter(id => !every.some(e => e.tag_id === id));
        
        setEveryTags(every);
        setPartTags(ar.notNull(partIds.map(id => allTagMap.get(id))));

    }, [ allTagMap, props.targets ]);

    useEffect(() => {
        setSelectableTags(props.allTags.filter(t => !everyTags.some(e => t.tag_id === e.tag_id)));

    }, [ props.allTags, everyTags ]);

    const remove = (list:"every"|"part", tag_name: string) => {
        if (list === "every") {
            const newList = everyTags.filter(t => t.tag_name !== tag_name);
            setEveryTags(newList);
        } else {
            const newList = partTags.filter(t => t.tag_name !== tag_name);
            setPartTags(newList);
        }
    }
    const onAdd = (id: number) => {
        const tag = allTagMap.get(id);
        if (!CommonUtil.assertNotNull(tag)) return;

        setEveryTags([...everyTags, tag]);
        
        const pidx = partTags.findIndex(p => p.tag_id === id);
        if (0 <= pidx) {
            setPartTags(partTags.filter((_,i) => i !== pidx));
        }
    }
    const onAddNew = (name: string) => {
        if (everyTags.some(t => t.tag_name === name)) return;

        setEveryTags([...everyTags, { tag_id:undefined, tag_name: name }]);
    }

    const onSubmit = () => {
        const targets = props.targets.map(convPropTargetToSubmit);
        const targetsToSubmit = ar.notNull(targets);
        if (targetsToSubmit.length === 0) {
            props.onClose();    //変更なし
            return;
        }

        props.onSubmit(targetsToSubmit);
    }

    const convPropTargetToSubmit = (target: Target<TId>): { id: TId, tags: EditingTag[] } | undefined => {
        const allSelected = [ ...everyTags.filter(e => e.tag_id != null), ...partTags ]; // as RanchTagDto[]
        const alives = ar.notNull(target.tags.map(t => allSelected.find(e => e.tag_id === t.tag_id)));
        const adds = everyTags.filter(e => e.tag_id == null || !alives.some(a => a.tag_id === e.tag_id));

        if (adds.length === 0 && alives.length === target.tags.length) return undefined;

        return { id: target.id, tags: [...alives, ...adds] };
    }

    const styles: { [key: string]: React.CSSProperties } = {
        container: {
            display: "flex",
            flexFlow: "column nowrap",
            minHeight: "220px",
            alignItems: "flex-start"
        },
        block: {
            display: "flex",
            alignItems: "center",
            flexFlow: "row wrap",
            marginBottom: "10px"
        },
        targetName: {
            marginLeft: "5px"
        },
        newTag: {
            marginTop: "10px"
        }
    } as const;

    const nameMax = props.targetNameOmitBorderCount;
    const name = (nameMax == null || props.targetNames.length <= nameMax)
                ? props.targetNames.join(", ")
                : `${props.targetNames.slice(0, nameMax).join(", ")} 他${props.targetNames.length - nameMax}${props.targetUnit}`;

    return (
        <div>
            <Modal isOpen={true} className="modal-slim">
                <ModalHeader toggle={() => props.onClose()}>タグ設定</ModalHeader>
                <ModalBody>
                    <div style={styles.container} className={baseStyles["tag-popup-container"]}>
                        <div style={styles.block}>
                            <div>{`選択中${props.targetKindName}：`}</div>
                            <div style={styles.targetName}>{name}</div>
                        </div>
                        { everyTags.map((tg,i) => (
                            <div key={i} className={baseStyles["tag-every"]}>
                                <span>{tg.tag_name}</span>
                                <i className="fas fa-times clickable" onClick={() => remove("every", tg.tag_name)} />
                            </div>
                        ))}
                        { partTags.map((tg,i) => (
                            <div key={i} className={baseStyles["tag-part"]}>
                                <span>{tg.tag_name}</span>
                                <i className="fas fa-times clickable" onClick={() => remove("part", tg.tag_name)} />
                            </div>
                        ))}
                        <div style={styles.newTag}>
                            <div>タグを追加</div>
                            <SingleCreatableSelect
                                options={selectableTags.map(t => ({ name:t.tag_name, value:t.tag_id }))}
                                onCreate={onAddNew}
                                onSelect={onAdd}
                                value={null}
                                style={{ width:"200px" }}
                                placeholder=""
                                formatCreateLabel={l => `タグ「${l}」を作成`}
                                canCreate={n => !everyTags.some(t => t.tag_name === n.trim())}
                            />

                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className="modal-footer-fix">
                    <ExecutionButton
                        type={props.submitType === "保存" ? "save" : "decide"}
                        disabled={props.isExecuting}
                        onClick={onSubmit}
                    >{props.submitType}</ExecutionButton>
                    <ExecutionButton type="cancel" disabled={props.isExecuting} onClick={props.onClose} />
                </ModalFooter>
            </Modal>
        </div>
    )


}