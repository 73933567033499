import React, { useState, useEffect } from 'react';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, Legend, ReferenceLine } from 'recharts';
import moment from 'moment';
import { CommonUtil, calcDg, ar, calcAge, AGE_MODE } from '../../config/util';

interface GrowthGraphProps {
    birthday: string | moment.Moment | Date;
    standards: { age_from: number, score: number }[];
    actuals: { date: string, score: number }[];
    onDaySelect: (day: string) => void;
    unit: string;
}

interface EventData {
    activeLabel: number;
    activePayload?: Payload[];
}
interface SelectedData {
    day: number;
    payload: Payload;
}
interface Payload {
    unit: string;
    color: string;
    name: string;
    value: number | null;
    dataKey: "standard" | "actual";
}

interface GraphData {
    day: number;
    standard?: number;
    actual?: number;
}

interface DGData {
    from: string;
    to: string;
    dg: number;
    fromDate: Date;
    toDate: Date;
    fromAge: number;
    toAge: number;
}

export const GrowthGraph: React.FC<GrowthGraphProps> = React.memo((props) => {
    const [ currentData, setCurrentData ] = useState<SelectedData|null>(null);
    const [ xTicks, setXTicks ] = useState<number[]>([]);
    const [ graphData, setGraphData ] = useState<GraphData[]>([]);
    const [ dgData, setDgData ] = useState<DGData>();
    const [ selectingDgPoint, setSelectingDgPoint ] = useState<"from"|"to">();
    const [ graphTerm, setGraphTerm ] = useState<{ start: Date, end: Date }>();

    useEffect(() => {
        setCurrentData(null);
        setSelectingDgPoint(undefined);

        const birth = moment(props.birthday);
        const isValid = birth.isValid;
        
        if (!isValid) {
            setGraphTerm(undefined);
            setGraphData([]);
            setXTicks([]);
            setDgData(undefined);
            return;
        }

        const sortedActuals = ar.orderBy(props.actuals, a => a.date)
        const stds = props.standards
            .map(s => ({ ...s, date: moment(props.birthday).add(s.age_from, "months").startOf("day") }));

        birth.startOf("day");
        const stDate = birth.toDate();
        const edMom = props.actuals.length === 0 
            ? moment(birth).add(1, "days")
            : moment(sortedActuals[sortedActuals.length -1].date).startOf("day");
        const nextStd = stds.find(s => s.date.isSameOrAfter(edMom));

        //実測値のひとつ後の標準値までをグラフ範囲とする
        const edDate = (nextStd?.date ?? edMom).toDate();

        setGraphTerm({ start: stDate, end: edDate });

        const xTicks: number[] = [];
        let age = 0;
        while(true) {
            const tick = moment(birth).add(age, "months").startOf("day");
            if (tick.isBefore(stDate)) {
                age++;
                continue;
            }
            if (tick.isAfter(edDate)) {
                break;
            }
            xTicks.push(tick.valueOf());
            age++;
        }
        setXTicks(xTicks);

        const data: GraphData[] = [];
        //標準値
        stds.filter(s => s.date.isSameOrAfter(stDate) && s.date.isSameOrBefore(edDate))
            .map(s => ({ day: s.date.valueOf(), standard: s.score }))
            .forEach(d => data.push(d));

        //測定値
        sortedActuals
            .map(a => ({ day: moment(a.date).valueOf(), actual: a.score }))
            .forEach(d => data.push(d));
        
        setGraphData(data);

        //DG
        if (props.actuals.length <= 1) {
            setDgData(undefined);
        } else {
            const first = sortedActuals[0];
            const last = sortedActuals[sortedActuals.length - 1];
            setDgData(buildDgInfo(first, last));
        }

    }, [ props.birthday, props.actuals, props.standards ]);


    const buildDgInfo = (dataFrom: { score: number, date: string }, dataTo: { score: number, date: string }) : DGData | undefined => {
        const dg = calcDg(dataFrom, dataTo);
        if (dg == null) return undefined;

        const mFrom = moment(dataFrom.date);
        const mTo = moment(dataTo.date);
        
        const from = mFrom.format("YYYY/M/D");
        const to = mTo.format("YYYY/M/D");

        const fromAge = calcAge(mFrom, moment(props.birthday), AGE_MODE.BIRTHDAY, true);
        const toAge = calcAge(mTo, moment(props.birthday), AGE_MODE.BIRTHDAY, true);

        return { from, to, dg, fromDate: mFrom.toDate(), toDate: mTo.toDate(), fromAge, toAge };
    }

    const onChartClick = (e:EventData|null) => {

        if (e == null || e.activePayload == null) {
            setCurrentData(null);
            return;
        }

        const actual = e.activePayload.find(p => p.dataKey === "actual");
        if (actual == null) {
            setCurrentData(null);
            return;
        }

        let data: SelectedData | undefined;
        if (actual.value != null) {
            data = { day: e.activeLabel, payload:actual };
        } else {
            //※標準値と同じ日に計測値があって、それがグラフの右端の場合、計測値に対してクリックイベントが発生しないので、
            //　同日に計測値がないか自分で探す
            const sameDayActual = graphData.find(d => d.day === e.activeLabel && d.actual != null);
            if (sameDayActual != null) {
                data = { day:e.activeLabel, payload: { ...actual, value:sameDayActual.actual! } };
            }
        }
        if (data == null) {
            setCurrentData(null);
            return;
        }

        if (selectingDgPoint != null) {
            onDgPointSelected(selectingDgPoint, e.activeLabel);
        } else {
            setCurrentData(data);
        }
    }

    const onDgPointSelected = (point:"from"|"to", day:number) => {
        if (!CommonUtil.assertNotNull(dgData)) return;

        const date = moment(day);
        if (point === "from" && date.isSameOrAfter(dgData.toDate)) return;
        if (point === "to" && date.isSameOrBefore(dgData.fromDate)) return;

        const from = (point === "from" ? date : moment(dgData.fromDate)).format("YYYY-MM-DD");
        const to = (point === "to" ? date : moment(dgData.toDate)).format("YYYY-MM-DD");

        const dataFrom = props.actuals.find(a => a.date === from);
        const dataTo = props.actuals.find(a => a.date === to);
        if (!CommonUtil.assertNotNull(dataFrom, "dataFrom") || !CommonUtil.assertNotNull(dataTo, "dataTo")) return;

        setDgData(buildDgInfo(dataFrom, dataTo));
        setSelectingDgPoint(undefined);
    }

    if (graphTerm == null) {
        return <></>
    }

    const renderDot = (e: { value?: number, cx?:number, cy?:number, fill:string, height: number, width:number, strokeWidth:number, r:number, stroke:string, index:number, payload: { day:number, actual: number } }) => {
        if (e.value == null) return <></>

        let r = 2;
        if (dgData != null) {
            //選択可能なDG期間ポイントのみ強調
            if (selectingDgPoint === "from") {
                const mTo = moment(dgData.toDate);
                r = mTo.isAfter(e.payload.day) ? 4 : 0
            } else if (selectingDgPoint === "to") {
                const mFrom = moment(dgData.fromDate);
                r = mFrom.isBefore(e.payload.day) ? 4 : 0;
            }
        }

        return (
            <circle
                className="recharts-dot recharts-line-dot"
                r={r}
                stroke={e.stroke}
                strokeWidth={e.strokeWidth}
                fill={e.fill}
                width={e.width}
                height={e.height}
                cx={e.cx}
                cy={e.cy}
            />
        )
    }

    const styles: { [key:string]:React.CSSProperties } = {
        dgContainer: {
            display:"flex",
            alignItems:"center",
            flexWrap:"wrap",
            margin: "1px 0 6px"
        },
        dgDateContainer: {
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "center",
            margin: "0 4px"
        },
        dgAge: {
            fontSize:"0.6875rem",
            lineHeight: 1
        },
        dg: {
            fontWeight: "bold",
            marginLeft: "2px",
            color: "#666666"
        }
    }

    return (
        <div style={{height:"100%",width:"100%"}}>
            { selectingDgPoint != null && (
                <div style={{position:"fixed", top:0, left:0, width:"100%", height:"100%", background:"#00000099", color:"white", textAlign:"center", paddingTop:"200px", zIndex:1}}>
                    <span style={{fontWeight:"bold", fontSize:"1rem"}}>DG期間の{selectingDgPoint === "from" ? "開始日" : "終了日"}を選択</span>
                    <div style={{ marginTop:"10px", textDecoration:"underline", cursor:"pointer"}} onClick={() => setSelectingDgPoint(undefined)}>キャンセル</div>
                </div>
            )}
            { dgData != null && (
                <div style={styles.dgContainer}>
                    <span>[DG]</span>
                    <div style={styles.dgDateContainer}>
                        <span className="link" onClick={() => { setCurrentData(null); setSelectingDgPoint("from"); }}>{dgData.from}</span>
                        <span style={styles.dgAge}> ({dgData.fromAge}ヵ月)</span>
                    </div>
                    <span>～</span>
                    <div style={styles.dgDateContainer}>
                        <span className="link" onClick={() => { setCurrentData(null); setSelectingDgPoint("to"); }}>{dgData.to}</span>
                        <span style={styles.dgAge}> ({dgData.toAge}ヵ月)</span>
                    </div>
                    <span>=</span>
                    <span style={styles.dg}>{dgData.dg} kg/日</span>
                </div>
            )}

            <div style={{width:"calc(100%-10px)", height:"94%", position:"relative", marginLeft:"-10px", background:"white", zIndex:2 }}>
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={graphData} onClick={onChartClick}>
                        <Line key={1}
                            unit={props.unit}
                            dataKey="standard"
                            name="標準発育"
                            yAxisId={1}
                            stroke="pink"
                            type="monotoneX"
                            strokeWidth={2}
                            animationDuration={500}
                            dot={{ r:0 }}
                            strokeDasharray="5 5"
                            activeDot={{ r: 5 }}
                        />
                        <Line key={2}
                            unit={props.unit}
                            dataKey="actual"
                            name="測定値"
                            yAxisId={1}
                            stroke="#4646ff"
                            type="monotoneX"
                            strokeWidth={2}
                            dot={renderDot}
                            animationDuration={500}
                            activeDot={{ r: 5 }}
                        />
                        <XAxis dataKey="day" type="number" scale="time" angle={10} 
                                tick={{fontSize:"10px"}}
                                tickFormatter={time => moment(time).diff(props.birthday, "month")}
                                domain={[ "auto", "auto"]}
                                ticks={xTicks}
                                height={33}
                                label={{ value:"（月齢）", position:"insideBottomRight"}}
                        />
                        <YAxis yAxisId={1} />
                        <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
                        <Tooltip content={<></>} />

                        { currentData != null && (
                            <ReferenceLine x={currentData.day} stroke="red" strokeDasharray="3 3" yAxisId={1} />
                        ) }
                        <Legend height={40} margin={{top:40, left:0, right:0, bottom:0}} />
                    </LineChart>
                </ResponsiveContainer>
                { currentData != null && (
                    // ※標準のTooltipは、スタイルの自作はできるが、表示非表示の手動制御ができず、
                    // 　state更新などのトリガで勝手に閉じてしまうので、自前で作り直す
                    //　（マウスオーバーでのフォーカスライン表示だけしたいので Tooltip 部品も残しておく）
                    <div className="chart-legend">
                        <div>{moment(currentData.day).format("M/D")}</div>
                        <div>
                            <span style={{ color:currentData.payload.color }}>●</span>
                            <span>{currentData.payload.name} : {currentData.payload.value}{currentData.payload.unit}</span>
                        </div>
                        <div style={{textAlign:"right", marginTop:"4px", fontSize:"0.75rem"}}>
                            <span className="link" onClick={() => props.onDaySelect(moment(currentData.day).format("YYYY-MM-DD"))}>イベント詳細</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
})