import React, { useState, useEffect } from 'react';
import { RanchEventHis, SellMilkRanchEventHisDto, SellSeedEventHisDto, BuySeedEventHisDto, isSellMilk, isSellSeed, isBuySeed, isOtherPlRanch, OtherPlRanchEventHisDto } from './ranch-event';
import { CommonUtil, formatYen } from '../../config/util';
import moment from 'moment';
import { A } from '../../config/constant';
import styles from './ranch-event-list.module.css';
import cowStyles from '../cow/cow.module.css';
import classnames from 'classnames';
import { EventKind, EVENT_KIND } from '../../config/event-kind';
import { hasRanchAuth } from '../../config/auth-checker';
import { IUser } from '../../stores';
import { PL_TYPE_PROFIT } from '../otherpl';
import { YearSeparator } from '../cow/cow-event-his-list';

interface RanchEventListProps {
    events: Readonly<Readonly<RanchEventHis>[]>;
    style?: React.CSSProperties;
    onEdit: (event: RanchEventHis) => void;
    user: IUser;
    ranch_id: number;
}
interface DayEvent {
    day: string;
    events: Readonly<Readonly<RanchEventHis>[]>;
}

export const RanchEventList = React.memo<RanchEventListProps>((props) => {
    const [ dayGroupedList, setDayGroupedList ] = useState<DayEvent[]>([]);

    useEffect(() => {
        const list = Array.from(CommonUtil.groupBy(props.events, e => e.day).entries())
                        .map(e => ({ day:e[0], events:e[1] }));
        setDayGroupedList(list);

    }, [ props.events ]);

    const canEdit = (ev: RanchEventHis) => {
        if (isSellMilk(ev) || isOtherPlRanch(ev)) {
            const key = isSellMilk(ev) ? "SELL_MILK_RANCH" : ev.profit_loss === PL_TYPE_PROFIT ? "OTHER_PROFIT_RANCH" : "OTHER_LOSS_RANCH";
            const selfEdit = EVENT_KIND[key].selfEditOnly === true;
            return !selfEdit || (props.user.id === ev.created_by);
        }
        if (isSellSeed(ev) || isBuySeed(ev)) {
            if (ev.is_deleted === 1) return false;
            if (!hasRanchAuth("MASTER_EDIT", props.ranch_id, props.user)) return false;
            return true;
        }
        console.error("unknown event", ev);
        return false;
    }

    const renderSellMilk = (ev: SellMilkRanchEventHisDto) => {
        const rows: string[] = [];
        if (ev.amount > 0 || ev.amount_discard === 0) {
            rows.push(`出荷乳量 ${ev.amount}kg ${formatYen(ev.unit_price * ev.amount, true)}円`);
        }
        if (ev.amount_discard > 0) {
            rows.push(`廃棄乳量 ${ev.amount_discard}kg (${formatYen(ev.unit_price * ev.amount_discard, true)}円相当)`)
        }
        if ((ev.comment ?? "") !== "") {
            rows.push(ev.comment);
        }

        return rows.map((r,i) => <div style={{ wordBreak:"break-all" }} key={i}>{r}</div>);
    }

    const renderSellSeed = (ev: SellSeedEventHisDto) => {
        const rows: string[] = [];

        if (ev.seed_type === A.SEED_TYPE.EGG.seed_type) {
            rows.push(`${A.SEED_TYPE.EGG.name} ${formatYen(ev.price)}円`);
            rows.push(`${ev.ancestor_1 ?? ""} ${ev.name} ${ev.lot_name} ${ev.label_no}`);
        } else if (ev.seed_type === A.SEED_TYPE.SEED.seed_type) {
            rows.push(`${A.SEED_TYPE.SEED.name} ${formatYen(ev.price)}円`);
            rows.push(`${ev.name} ${ev.sname ?? ""} ${ev.lot_name} ${ev.label_no}`);
        }
        if ((ev.note ?? "") !== "") {
            rows.push(ev.note!);
        }

        return rows.map((r,i) => <div style={{ wordBreak:"break-all" }} key={i}>{r}</div>);
    }

    const renderBuySeed = (ev: BuySeedEventHisDto) => {
        const rows: string[] = [];

        const price = Math.round(ev.unit_price * ev.stock_count);

        if (ev.seed_type === A.SEED_TYPE.EGG.seed_type) {
            rows.push(`${A.SEED_TYPE.EGG.name} ${formatYen(price)}円`);
            rows.push(`${ev.ancestor_1 ?? ""} ${ev.name} ${ev.lot_name}`);
        } else if (ev.seed_type === A.SEED_TYPE.SEED.seed_type) {
            rows.push(`${A.SEED_TYPE.SEED.name} ${formatYen(price)}円`);
            rows.push(`${ev.name} ${ev.sname ?? ""} ${ev.lot_name}`);
        }
        if ((ev.note ?? "") !== "") {
            rows.push(ev.note!);
        }

        return rows.map((r,i) => <div style={{ wordBreak:"break-all" }} key={i}>{r}</div>);
    }

    const renderOtherPl = (ev: OtherPlRanchEventHisDto) => {
        const rows: string[] = [];
        const typeName = ev.event_kind_no === EVENT_KIND.OTHER_PROFIT_RANCH.no ? "雑収入" : "雑損失";
        rows.push(`${typeName} ${formatYen(ev.price)}円`);
        if (ev.comment !== "") {
            rows.push(ev.comment);
        }

        return rows.map((r,i) => <div style={{ wordBreak:"break-all" }} key={i}>{r}</div>);
    }

    const isSameYear = (day1:string, day2:string) => day1.substr(0,4) === day2.substr(0,4);

    return (
        <div style={props.style}>
        { dayGroupedList.map((daily,di) => (<>
            { (di === 0 || !isSameYear(dayGroupedList[di-1].day, daily.day)) && (
                <YearSeparator year={moment(daily.day).year()} />
            )}
            <div key={di} className={styles["day-container"]}>
                { di !== 0 && <hr className={cowStyles["hr-border"]} />}
                <label className="col-md-2 col-xs-3">{moment(daily.day).format("M月D日")}</label>
                <div className="col-md-10 col-xs-9 p-r-0">
                    { daily.events.map((ev, ei) => (
                        <div key={ei}>
                            { (ei !== 0 && daily.events[ei - 1].event_id !== ev.event_id) && (
                                <hr className={cowStyles["hr-border"]} />
                            )}
                            <div className={styles["event-item"]}>
                                {/* 精液・受精卵の購入のみ時刻表示なし */}
                                <div className={classnames(styles["event-time"], {invisible:isBuySeed(ev)})}>{moment(ev.reg_time).format("HH:mm")}</div>
                                <div>［{EventKind.find(ev.event_kind_no)?.group ?? ""}］</div>
                                <div className={styles["event-body"]}>
                                    { isSellMilk(ev) && (
                                        <span>{ renderSellMilk(ev) }</span>
                                    )}
                                    { isSellSeed(ev) && (
                                        <span>{ renderSellSeed(ev) }</span>
                                    )}
                                    { isBuySeed(ev) && (
                                        <span>{ renderBuySeed(ev) }</span>
                                    )}
                                    { isOtherPlRanch(ev) && (
                                        <span>{ renderOtherPl(ev) }</span>
                                    )}
                                </div>
                                { canEdit(ev) && (
                                    <div className="link" style={{ marginLeft:"4px" }} onClick={()=>{ props.onEdit(ev) }}>編集</div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>))}
        </div>
    )


});
