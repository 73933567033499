import React, { ReactNode } from 'react';
import classnames from 'classnames';

export interface MyProps {
    label: string;
    children: ReactNode;
    contentClassName?: string;
    contentStyle?: React.CSSProperties;
    labelSize?:"s"|"m";
    containerClassName?: string;
}

export const ModalInputBlock = React.memo((props: MyProps) => {
    const labelClass = props.labelSize === "s" ? "label-s" : "label-m";
    return (
        <div className={classnames("form-group row-no-margin modal-input-block", props.containerClassName)}>
            <label className={classnames("col-form-label", labelClass)}>{props.label}</label>
            <div className={classnames("flex-fill", props.contentClassName)} children={props.children}
                style={props.contentStyle}
            />
        </div>
    )
});