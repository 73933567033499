import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment'

import { CowListDto, FeedApi, FeedBulkAddReq } from '../../api'

import { useRootStore } from '../../stores';
import { usePageStore } from '../../config/page-settings';

import ConditionSelect from './ConditionSelect'
import RegisterFeed from './RegisterFeed'
import Completed from './Completed'

import styles from './feedbulk.module.css'
import { hasRanchAuth } from '../../config/auth-checker';
import { calcSolute, CommonUtil } from '../../config/util';
import { Communicator } from '../../api/communicator';
import { A, LMT, PageType } from '../../config/constant';
import { historyLocation } from '../../config/history-location-builder';
import { UserTeams } from '../../config/user-teams';
import { useRanchFeeds, useRanchHouses } from '../../stores/fetcher';
import { FetchWaiter, FetchError } from '../../components/content/fetch-state';
import { AppState } from 'app';


export interface RouteParams {
  ranchId: string
}

type FlowPhase = 'ConditionSelect' | 'RegisterFeed' | 'Completed'

export type Feed = {
  id : number,
  type : number,
  name: string,
  amount: number,
  unit: string,
  price: number
}
export type Attribute = {
  moved : boolean,
  watched : boolean,
  overeat : boolean,
  watched_memo: string
}
export type Cow = {
  id: number,
  name: string,
  house: Array<number>,//[number,number,number],
  birthday: number,
  repId: string,
  earTag: boolean,
  selected : boolean,
  feeds : Array<Feed>,
  attrs : Attribute,
}

export default () => {
  const rootStore = useRootStore() // todo: setRanchHouse, setRanchFeeds can not bind RootStore instance
  const { user, auth, logout } = rootStore

  if ( !auth.enabled ) {
    return (<></>)
  }

  const { handleSetHeader, handleSetPageError, handleSetFooter, handleSetIsLoading, showModalAsync, showDialog, showToast } = usePageStore() as AppState

  const { ranchId } = useParams<RouteParams>()
  const ranch = new UserTeams(user).findRanch(parseInt(ranchId));
  if (ranch === undefined) return (<></>)

  const ranchFeeds = useRanchFeeds(ranch.team_id);
  const ranchHouses = useRanchHouses(ranch.team_id, false);

  const [ready, setReady] = useState(false);
  const [date, setDate] = useState<Date>(new Date());
  const [ precheckedCowIds, setPrecheckedCowIds ] = useState(new Set<number>());
  const [ precheckedCows, setPrecheckedCows ] = useState<CowListDto[]>([]);
  const [cows, setCows] = useState<Array<Cow>>([]);
  const [registerCount, setRegisterCount] = useState(0);
  const [ executing, setExecuting ] = useState(false);

  const [phase, setPhase] = useState<FlowPhase>('ConditionSelect');
  const moveToConditionSelect = useCallback(() => { setPhase('ConditionSelect') }, [])
  
  const moveToRegisterFeed = () => {
    rootStore.setSelectedCowIds([...precheckedCowIds]);
    setPhase('RegisterFeed');
  };

  useEffect(() => {
    setPrecheckedCowIds(new Set(rootStore.getSelectedCowIds()));

  }, [])

  useEffect(() => {
    (async () => {
      if (!hasRanchAuth("FOODING_EDIT", ranch.team_id, user)) {
        rootStore.logout()
        return
      }

      handleSetHeader({ title:"一括えさ登録", backBtn:false })
      handleSetPageError(false)
      handleSetFooter(true)

      await rootStore.fetchActiveCows(ranch.team_id)
      setReady(true)
    })()
  }, [ranchId])

  useEffect(() => {
    const preCows = (rootStore.getActiveCows(ranch.team_id) ?? [])
      .filter(c => precheckedCowIds.has(c.cow_id));
    setPrecheckedCows(preCows);

  }, [ precheckedCowIds ])

  const comm = new Communicator({ logout, showToast, showDialog });

  const register = async () => {
    const feeds = ranchFeeds.data;
    if (!CommonUtil.assertNotNull(feeds, "ranchFeeds", "register")) return;

    const targetCows = cows.filter(cow => cow.selected && cow.feeds.length > 0);

    const req: FeedBulkAddReq = ({
      ranch_id: ranch.team_id,
      fed_at: moment(date).format("YYYY-MM-DD HH:mm"),
      cows: targetCows.map(cow => ({
        cow_id: cow.id,
        comment: '',
        feed_amount: cow.feeds.map(feed => ({
          feeding_id: 0,
          feed_no: feed.id,
          feed_type_no: feed.type,
          delivered: feed.amount,
          delivered_raw: calcSolute(feed.id, feed.amount, feeds),
          unit_price: feed.price
        }))
      }))
    })
    if (req.cows.some(c => c.feed_amount!.some(fa => (fa.delivered_raw ?? 0) > LMT.FEEDING.AMOUNT_RAW_MAX))) {
      await showModalAsync(A.MESSAGE.INVALID_FEED_RAW_AMOUNT, "QUESTION", ["OK"])
      return
    }

    setExecuting(true)
    handleSetIsLoading(true)
    const feedRes = await comm.send((await FeedApi()).bulkAddFeedUsingPOST(req))
    handleSetIsLoading(false)

    if(feedRes.result !== "OK") {
      setExecuting(false)
      return
    }

    rootStore.fetchActiveCows(undefined, "DIFF")

    setRegisterCount(targetCows.length)
    setPhase('Completed')
    setExecuting(false)
  }


  if (ranchFeeds.isLoading || ranchHouses.isLoading) return <FetchWaiter />
  if (ranchFeeds.isError || ranchFeeds.data == null) return <FetchError />
  if (ranchHouses.isError || ranchHouses.data == null) return <FetchError />

  const view = phase === 'ConditionSelect' ? 0 : phase === 'RegisterFeed' ? 1 : 2

  return (
    <main data-testid="メイン" className={ styles.page }>
      <section data-testid="表示エリア" className={ styles["content-area"] } style={{width: `${100 * 3}%`, left: `-${100 * view}%` }}>
        <div className={ styles["content-phase"] }>
          {phase === 'ConditionSelect' &&
            <ConditionSelect
              ready={ ready }
              ranchId={ parseInt(ranchId) }
              date={ date }
              dateName="餌やり"
              setDate={ setDate }
              checkedCowIds={ precheckedCowIds }
              setCheckedCowIds={ setPrecheckedCowIds }
            />
          }
        </div>
        <div className={ styles["content-phase"] }>
        {phase === 'RegisterFeed' &&
          <RegisterFeed
              ranchFeeds={ranchFeeds.data}
              ranchHouses={ranchHouses.data}
              ranchId={ parseInt(ranchId) }
              date={ date }
              allCows={ precheckedCows }
              cows={ cows }
              setCows={ setCows }
            />
          }
        </div>
        <div className={ styles["content-phase"] }>
        {phase === 'Completed' &&
          <Completed count={registerCount} eventName="えさ" />
        }
        </div>
      </section>
      <section className={ styles["fix-bottom"] }>
      {
        {
          'ConditionSelect': <ConditionMenu canNext={ precheckedCowIds.size > 0 } moveNext={ moveToRegisterFeed } count={ precheckedCowIds.size } button="えさを選ぶ" nextPage="content" />,
          'RegisterFeed'   : <RegisterMenu canNext={ !executing && cows.map(cow => cow.selected ? cow.feeds.length : 0).reduce((v,c) => v+c, 0) > 0 } movePrev={ moveToConditionSelect } moveNext={ register } />,
          'Completed'      : <CompletedMenu ranchId={ ranchId } />,
        } [phase]         || <></>
      }
      </section>
    </main>
  );
}

export const ConditionMenu: React.FC<{
  canNext  : boolean,
  moveNext : () => void,
  count    : number,
  button   : string,
  nextPage : PageType
}> = ({ canNext, moveNext, count, button, nextPage }) => (
  <div className={ styles.footmenu }>
    <button style={{visibility: 'hidden'}} />
    <div>
      { count > 0 && <span className={ styles.cowcount }>対象牛合計数 : <b data-testid="牛合計数">{ count }</b> 頭 </span> }
      <button className={ styles.bluebutton } onClick={ moveNext } disabled={ !canNext } data-testid="餌登録へ">
        { button } { <i className={nextPage === "content" ? "fas fa-caret-right" : "fas fa-external-link-alt"}></i> }
      </button>
    </div>
  </div>
)

const RegisterMenu: React.FC<{
  canNext  : boolean,
  moveNext : () => void,
  movePrev : () => void,
}> = ({ canNext, moveNext, movePrev }) => (
  <div className={ styles.footmenu }>
    <button className={ styles.bluebutton } onClick={ movePrev }><i className="fas fa-caret-left"></i> 戻る</button>
    <button className={ styles.bluebutton } onClick={ moveNext } disabled={ !canNext } data-testid="登録">登録する</button>
  </div>
)

const CompletedMenu: React.FC<{
  ranchId: string,
}> = ({ ranchId }) => {
  const history = useHistory()

  return (
    <div className={ styles.footmenu }>
      <button className={ styles.bluebutton } onClick={ () => history.push(`/top/${ ranchId }`) }>牧場Topに戻る</button>
      <button className={ styles.bluebutton } onClick={ () => history.push(historyLocation.toFeedRemained()) }>続けて食べ残しを登録</button>
    </div>
  )
}
