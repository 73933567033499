import React, { useState, useEffect } from 'react';
import { SellCowAggregateTransitionDto } from '../../api';
import { COW_SELL_TYPE } from '../../config/constant';
import { ar, zeroPad } from '../../config/util';
import moment from 'moment';
import { ResponsiveContainer, LineChart, Line, Legend, CartesianGrid, YAxis, XAxis, LineProps } from 'recharts';

interface MyProps {
    year: number;
    records: SellCowAggregateTransitionDto[];
}

interface MonthData {
    actual_cc: number;
    plan_cc: number;
    actual_chi: number,
    plan_chi: number,
    month: number;
}
interface MonthSellTypeData {
    actual: number;
    plan: number;
}

const parseMonthData = (year: number, records: SellCowAggregateTransitionDto[], sellType: number): MonthSellTypeData[] => {
    const targets = (records.find(r => r.sell_type === sellType)?.months ?? [])
                    .map(r => ({ ...r, ymStr: moment(r.lastday).format("YYYYMM")}));

    return ar.intRange(1, 12)
        .map(m => {
            const ymStr = zeroPad(year, 4) + zeroPad(m, 2);
            const target = targets.find(t => t.ymStr === ymStr);
            if (target == null) {
                return { actual: 0, plan: 0 };
            }
            return { actual: target.actual, plan: target.plan };
        });
}

export const ReportSellCowTransitionGraph = React.memo((props: MyProps) => {
    const [ graphData, setGraphData ] = useState<MonthData[]>([]);

    useEffect(() => {
        const childs = parseMonthData(props.year, props.records, COW_SELL_TYPE.CHILD.value);
        const carcasses = parseMonthData(props.year, props.records, COW_SELL_TYPE.FAT.value);

        const data = ar.intRange(1, 12).map(m => {
            const cc = carcasses[m - 1];
            const chi = childs[m - 1];
            return {
                month: m,
                actual_cc: cc.actual,
                plan_cc: cc.plan,
                actual_chi: chi.actual,
                plan_chi: chi.plan
            }
        });

        setGraphData(data);

    }, [ props.records, props.year ]);

    const lineProps = (sellTypeSuffix: string, sellTypeName: string, dataKind: "plan"|"actual", stroke: string): LineProps => {
        return {
            unit: "頭",
            dataKey: `${dataKind}_${sellTypeSuffix}`,
            name: `${sellTypeName}${dataKind === "actual" ? "実績" : "予定"}`,
            yAxisId: 1,
            stroke,
            type: "monotoneX",
            strokeWidth: 2,
            animationDuration: 500,
            dot: { r: 2 },
            activeDot: { r: 5 },
            strokeDasharray: dataKind === "plan" ? "5 5" : undefined,
        }
    }

    return (
        <div style={{width:"calc(100%-10px)", flex: 1, position:"relative", marginLeft:"-10px"}}>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart data={graphData}>
                    <Line {...lineProps("chi", COW_SELL_TYPE.CHILD.planName, "plan", "#ff9299")} />
                    <Line {...lineProps("chi", COW_SELL_TYPE.CHILD.planName, "actual", "red")} />
                    <Line {...lineProps("cc", COW_SELL_TYPE.FAT.planName, "plan", "#9299ff")} />
                    <Line {...lineProps("cc", COW_SELL_TYPE.FAT.planName, "actual", "#4646ff")} />
                    <XAxis dataKey="month" type="category"
                        tick={{fontSize:"10px"}}
                        tickFormatter={m => m + "月"}
                        height={33}
                    />
                    <YAxis yAxisId={1} />
                    <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />

                    <Legend height={40} margin={{top:40, left:0, right:0, bottom:0}} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    )

})