import React from 'react';
import styles from './filter-box.module.css';

export const FilterBox: React.FC<{ 
        value: string,
        onChange: (val:string) => void,
        placeholder: string,
        style?: React.CSSProperties,
        onEnterKeyDown?: () => void,
        inputRef?: React.RefObject<HTMLInputElement>;
    }> = ({ value, onChange, placeholder, style, onEnterKeyDown, inputRef }) => (
    <input type="text" className={styles["search-box"]} value={value}
        ref={inputRef}
        style={style}
        onKeyDown={e => {
            if (e.key === "Enter") {
                e.currentTarget.blur();
                if (onEnterKeyDown) {
                    onEnterKeyDown();
                }
            }
        }}
        onChange={(e) => onChange(e.target.value)} placeholder={placeholder} />
)
