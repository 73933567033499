import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { A } from '../../config/constant';
import { PageSettings } from '../../config/page-settings.js';
import Validation from './validation';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import styles from './user.module.css'

interface MyProps {
    onClose:()=>void;
}
interface IUserData {
    email:string;
}

interface MyState {
    activeBg: string,
    info:IUserData;
    message:IUserData;
    isLoading: boolean;
}

export class PasswordReminderPopup extends React.Component<MyProps, MyState> {

    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            activeBg: '/assets/img/login-bg/login-bg.jpg',
            info : { email: "" },
            message : { email: "" },
            isLoading: false,
        }

        this.reminder = this.reminder.bind(this);
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const key = event.target.name;
        const value = event.target.value;
        const { info, message } = this.state;
    
        this.setState({
            info: { ...info, [key]: value }
        });

        this.setState({
            message: {
                ...message,
                [key]: Validation.formValidate(key, value)
            }
        });
    };

    canSubmit = (): boolean => {
        const { info, message, isLoading } = this.state;
        const validInfo = Object.values(info).filter(value => {
            return value === '';
          }).length === 0;
        const validMessage = Object.values(message).filter(value => {
            return value !== '';
          }).length === 0;

        return validInfo && validMessage && !isLoading;
      };    

    async reminder() {

        this.setState({isLoading: true});

        const { info } = this.state;
        const email = info.email;

        // 登録の確認
        const providers = await firebase.auth().fetchSignInMethodsForEmail(email);

        if (providers.findIndex(p => p === firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) === -1) {
            // 登録有無の判別をさせないように、未登録のメールアドレスでもメール送信のメッセージを表示する（メールは送信しない）
            this.setState({isLoading: false});
            this.context.showToast(A.MESSAGE.SEND_MAIL);
            return;
        }

        await firebase.auth().sendPasswordResetEmail(email, {
        　　url: `${window.location.protocol}//${window.location.host}/login`,
            handleCodeInApp: false,
        })
        .then(() => {
            this.setState({isLoading: false});
            this.context.showToast(A.MESSAGE.SEND_MAIL);
        })
        .catch((error) => {
            this.setState({isLoading: false});
            console.error(`${error.code} - ${error.message}`);
            this.context.showAlert(A.MESSAGE.FAILED_MAIL_SEND);
            return;
        });
    }

    render() {
        const {info, message} = this.state;
        return (
            <div className="modal-wide">
                <Modal isOpen={true} centered={true}>
                    {/* style={{maxWidth:"1000px", position:"absolute",  top:"50%", left:"50%", transform:"translate(-50%, -50%)"}}> */}
                    <ModalHeader toggle={this.props.onClose}>パスワード再設定</ModalHeader>
                    <ModalBody style={{ overflowY: "auto", maxHeight: "calc(100vh - 150px)" }}>
                        <div className="form-group m-b-10"> 
                            <span className={styles.label}>パスワード再設定用のメールを送信します</span>
                        </div>
                        <div className="form-group m-b-5">
                                <input type="text" className="form-control form-control-lg" placeholder="メールアドレス"
                                    name="email" value={info.email} onChange={e => this.handleChange(e)} />
                        </div>
                        <div className="form-group m-b-10" style={{height:"20px"}}> 
                            <span className={styles.error}>{message.email}</span>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-warning" onClick={this.reminder} disabled={!this.canSubmit()}>送信</button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }

}