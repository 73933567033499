import React, { useState, useEffect } from 'react';
import { TwitterPicker } from 'react-color';

interface Props {
    disabled?: boolean;
    color: string;
    onChange: (color: string) => void;
}

export const ColorPicker = (props: Props) => {
    const [ isOpen, setIsOpen ] = useState(false);

    useEffect(() => {
        if (props.disabled) {
            setIsOpen(false);
        }

    }, [ props.disabled ]);

    const COLORS = [ "#FF3333","#B06000","#00CCFF","#00FF66","#CCFF00","#FFCCEB","#D6CCFF","#CCFFFF","#D6FFCC","#FFEBCC" ];

    const styles = {
        default: {
            input: {
                display:"none"
            },
            hash: {
                display:"none"
            }
        }
    } as const;

    return (
        <div>
            <div style={{ width: "20px", height: "20px", background: props.color, borderRadius:"2px", border:"solid 1px #aaa" }}
                 onClick={ () => { if (props.disabled !== true) setIsOpen(!isOpen); } } />
            { isOpen && (
                <div style={{ position:"absolute", zIndex:20, marginLeft:"-10px", marginTop:"10px" }}>
                    <div style={{ position:"fixed", top:0, left:0, right:0, bottom:0 }} onClick={ () => setIsOpen(false) }/>
                    <TwitterPicker color={ props.color } onChange={ c => props.onChange(c.hex) } width={"206px"}
                        styles={styles}
                        colors={COLORS}/>
                </div>
            )}

        </div>
    )

}