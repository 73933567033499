import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { A } from '../../config/constant';
import styles from './setting.module.css';
import { IMedicineRoute } from '../../stores/RootStore';
import { PageSettings } from '../../config/page-settings';
import { MedicineRouteEditPopup } from './medicine-route-edit-popup';
import { withContext } from '../../stores';
import { AppState } from '../../app';
import { TeamMedicineRouteDeleteReq, TeamMedicineSortReq, TeamMedicineRouteModifyReq, TeamApi } from '../../api';
import { CommonUtil, FreezedArray } from '../../config/util';
import { DIALOG_BUTTONS } from '../../components/form/form-dialog';
import { SortableList } from '../../components/parts/sortable-list';
import { resetMedicineRoutes, WithQuery, useMedicineRoutes } from '../../stores/fetcher';
import { FetchWaiter, FetchError } from '../../components/content/fetch-state';
import { UserTeams } from 'config/user-teams';

interface MyProps extends BaseProps<{id:string}> {
}

interface MyState {
    teamId: number | null;
    sortingList: IMedicineRoute[];
    isSorting: boolean;
    editingData: Readonly<IEditingMedicineRoute> | null;
    executing: boolean;
}

export type IEditingMedicineRoute = {
    isNew: boolean;
    route_id?: number;
    name: string;
    base_fee: number;
    base_point_a: number | undefined;
    base_point_b: number | undefined;
    extra_fee: number | undefined;
    extra_point_a: number | undefined;
    extra_point_b: number | undefined;
    base_limit: number | undefined;
    extra_step: number | undefined;
    code: string;
    hasExtra: boolean;
    isFreeMedicine: boolean;
    isInstruction: boolean;
    isOnceFee: boolean;
    prior_fee_route_id: number | undefined;
}

class MedicineRouteMaster extends Base<MyProps, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            teamId: null,
            isSorting: false,
            sortingList: [],
            editingData: null,
            executing: false
        };

        this.startSorting = this.startSorting.bind(this);
        this.finishSorting = this.finishSorting.bind(this);
        this.cancelSorting = this.cancelSorting.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onCancelEdit = this.onCancelEdit.bind(this);
        this.onSubmitEdit = this.onSubmitEdit.bind(this);
        this.startEdit = this.startEdit.bind(this);
        this.onExtraChecked = this.onExtraChecked.bind(this)
        this.addMedicineRoute = this.addMedicineRoute.bind(this);
        this.onDataEdit = this.onDataEdit.bind(this);
    }

    componentDidMount() {

        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        this.init();
    }

    componentDidUpdate(prevProps: this['props'], prevState: MyState) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.init();
        }
    }

    private init() {
        const team_id = Number(this.props.match.params.id);
        if (this.handleNotAllowAccess(team_id, ["MASTER_EDIT"], ["MASTER_EDIT"])) {
            return;
        }
        const isRanch = new UserTeams(this.props.rootStore.user).findRanch(team_id) != null;
        this.context.handleSetHeader({ title:"投薬経路の設定", iconType:isRanch ? "ranch" : "clinic" });

        this.setState({
            teamId: team_id,
        });
    }

    private startSorting(routes: FreezedArray<IMedicineRoute>) {
        if (this.state.isSorting) {
            console.error("already sorting");
            return;
        }

        this.setState({
            isSorting: true,
            sortingList: [...routes]
        });
    }
    private cancelSorting() {
        if (!this.state.isSorting) {
            console.error("not sotring on cancel");
            return;
        }

        this.setState({
            isSorting: false,
            sortingList:[]
        });
    }
    private async finishSorting(routes: FreezedArray<IMedicineRoute>) {
        if (!this.state.isSorting) {
            console.error("not sorting on finish");
            return;
        }
        if (!CommonUtil.assertNotNull(this.state.teamId)) return;

        const newOrder = this.state.sortingList.map(p => p.route_id);
        const oldOrder = routes.map(p => p.route_id);
        if (JSON.stringify(newOrder) === JSON.stringify(oldOrder)) {
            this.setState({
                isSorting: false,
                sortingList:[]
            });
            return;
        }

        //ソート要求
        this.setState({ executing:true });
        this.context.handleSetIsLoading(true);
        const req: TeamMedicineSortReq = {
            order: newOrder,
            team_id: this.state.teamId,
            user_id: this.props.rootStore.user.id
        };
        try {
            const res = await this.comm().send(() => this.context.postAsync("/team/medicine/route/sort", req));
            if (res.result !== "OK") return;
    
            this.setState({
                isSorting: false,
                sortingList:[]
            });

            await resetMedicineRoutes(this.state.teamId, false);

        } finally {
            this.context.handleSetIsLoading(false);
            this.setState({ executing:false });
        }
    }

    private addMedicineRoute() {
        if (!this.assertNotNull(this.state.teamId, "teamId", "addMedicineRoute")) return;
        this.setState({
            editingData: {
                isNew: true,
                name: "",
                base_fee: 0,
                base_point_a: undefined,
                base_point_b: undefined,
                extra_fee: undefined,
                extra_point_a: undefined,
                extra_point_b: undefined,
                base_limit: undefined,
                extra_step: undefined,
                code: "",
                hasExtra: false,
                isFreeMedicine:false,
                isInstruction:false,
                isOnceFee:false,
                prior_fee_route_id: undefined,
            }
        })
    }

    private startEdit(route: IMedicineRoute) {
        this.setState({
            editingData: {
                isNew: false,
                name: route.name,
                route_id: route.route_id,
                base_fee: route.base_fee,
                base_point_a: route.base_point_a,
                base_point_b: route.base_point_b,
                extra_fee: route.extra_fee,
                extra_point_a: route.extra_point_a,
                extra_point_b: route.extra_point_b,
                base_limit: route.base_limit,
                extra_step: route.extra_step,
                code: route.code ?? "",
                hasExtra: Number(route.extra_fee) > 0,
                isFreeMedicine: route.is_free_medicine === 1,
                isInstruction: route.is_instruction === 1,
                isOnceFee: route.is_once_fee === 1,
                prior_fee_route_id: route.prior_fee_route_id
            }
        });
    }

    private onExtraChecked(extra:boolean) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onExtraChecked")) return;

        const data = { ...this.state.editingData};
        data.hasExtra = extra;
        if (!data.hasExtra) {
            data.base_limit = undefined;
            data.extra_step = undefined;
            data.extra_fee = undefined;
            data.extra_point_a = undefined;
            data.extra_point_b = undefined;
        }
        this.setState({
            editingData: data
        });
    }

    private onDataEdit<K extends keyof IEditingMedicineRoute>(data: Pick<IEditingMedicineRoute, K>) {
        if (!this.assertNotNull(this.state.editingData, "edintingData", "onEdit")) return;

        this.setState({
            editingData: {
                ...this.state.editingData,
                ...data
            }
        });
    }

    private assertNotNull<T>(v: T | null | undefined, name?: string, when?: string): v is T {
        if (v == null) {
            console.error(`${name ?? "value"} is null or undef when ${when ?? "assertion"} is called.`);
            return false;
        }
        return true;
    }


    private async onSubmitEdit() {
        const data = this.state.editingData;
        if (!this.assertNotNull(data, "editingData", "submitEdit")) return;
        if (!this.assertNotNull(this.state.teamId, "teamId")) return;

        if (data.name === "") {
            this.context.showToast(A.MESSAGE.NO_MEDICINE_ROUTE_NAME_INPUT);
            return;
        }

        if (data.base_fee == null) {
            this.context.showToast(A.MESSAGE.NO_MEDICINE_ROUTE_BASE_FEE_INPUT);
            return;
        }

        if (data.hasExtra) {
            if (data.base_limit == null) {
                this.context.showToast(A.MESSAGE.NO_MEDICINE_ROUTE_BASE_LIMIT_INPUT);
                return;
            }
            if (data.extra_step == null) {
                this.context.showToast(A.MESSAGE.NO_MEDICINE_ROUTE_EXTRA_STEP_INPUT);
                return;
            }
            if (data.extra_fee == null) {
                this.context.showToast(A.MESSAGE.NO_MEDICINE_ROUTE_EXTRA_FEE_INPUT);
                return;
            }
        }

        try {
            this.setState({ executing: true });

            //更新要求
            this.context.handleSetIsLoading(true);
            const req: TeamMedicineRouteModifyReq = {
                route_id: data.route_id ?? 0,
                is_new: data.route_id == null ? 1 : 0,
                name: data.name,
                team_id: this.state.teamId,
                base_fee: data.base_fee,
                base_point_a: data.base_point_a,
                base_point_b: data.base_point_b,
                extra_fee: data.hasExtra ? data.extra_fee : undefined,
                extra_point_a: data.hasExtra ? data.extra_point_a : undefined,
                extra_point_b: data.hasExtra ? data.extra_point_b : undefined,
                base_limit: data.hasExtra ? data.base_limit : undefined,
                extra_step: data.hasExtra ? data.extra_step : undefined,
                code: data.code,
                is_free_medicine: data.isFreeMedicine ? 1 : 0,
                is_instruction: data.isInstruction ? 1 : 0,
                is_once_fee: data.isOnceFee ? 1 : 0,
                prior_fee_route_id : data.isOnceFee ? data.prior_fee_route_id : undefined,
            }
            const res = await this.comm().send(() => this.context.postAsync("/team/medicine/route/modify", req));
            if (res.result !== "OK") return;

            this.setState({
                editingData: null
            });

            await resetMedicineRoutes(this.state.teamId, false);

        } finally {
            this.context.handleSetIsLoading(false);
            this.setState({ executing: false });
        }
    }

    private async onDelete() {
        const confirmed = await this.context.showDialog("QUESTION", '投薬経路を削除してよろしいですか？', DIALOG_BUTTONS.DELETE_CANCEL);
        if (confirmed === 0) {
            this._execDelete();
        }
    }
    //削除実行
    private async _execDelete() {
        const data = this.state.editingData;
        if (!this.assertNotNull(data, "editingData", "delete")) return;
        if (!this.assertNotNull(data.route_id, "route_id", "delete")) return;
        if (!this.assertNotNull(this.state.teamId, "teamId", "delete")) return;

        this.setState({ executing:true });
        this.context.handleSetIsLoading(true);
        const req: TeamMedicineRouteDeleteReq = {
            route_id: data.route_id,
            team_id: this.state.teamId,
        }
        try {
            const res = await this.comm().send((await TeamApi()).deleteMedicineRouteUsingPOST(req), { excludedErrCodes:[ A.ERR_CODE.ERR_USING_DATA ] });
            if (res.result !== "OK") return;
    
            if (res.code === A.ERR_CODE.ERR_USING_DATA) {
                this.context.showDialog("WARNING", A.MESSAGE.MEDICINE_ROUTE_USED_IN_PRESET_OR_MEDICINE);
                return;
            }
    
            this.setState({
                editingData: null
            });

            await resetMedicineRoutes(this.state.teamId, false);

        } finally {
            this.context.handleSetIsLoading(false);
            this.setState({ executing:false });
        }
    }

    private onCancelEdit() {
        this.setState({
            editingData: null,
        })
    }

    render() {
        const teamId = this.state.teamId;
        if (teamId == null) return <></>

        return (
            <WithQuery query={() => useMedicineRoutes(teamId)}>
            {({ data, isError, isLoading }) => {
                if (isLoading) return <FetchWaiter />
                if (isError || data == null) return <FetchError />
                
                return this.renderPage(data);
            }}
            </WithQuery>
        )

    }

    renderPage(medicineRoutes: FreezedArray<IMedicineRoute>) {
        return (
            <div className="page-root">
                <div className="product product-full-height">
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix">
                            <div className={"product-info-header " + styles.header}>
                                <div className={styles["sortable-list-shoulder"]}>
                                    {!this.state.isSorting && (<span className="link" onClick={() => this.startSorting(medicineRoutes)}>並び替え</span>)}
                                    {this.state.isSorting && (<span className="link" onClick={() => this.finishSorting(medicineRoutes)}>完了</span>)}
                                    {this.state.isSorting && (<span className="link" onClick={this.cancelSorting}>キャンセル</span>)}
                                </div>
                            </div>

                            <div className="product-body">
                                <SortableList
                                    className={styles.list + " m-b-0"}
                                    items={this.state.isSorting
                                        ? this.state.sortingList
                                        : medicineRoutes
                                    }
                                    isSorting={this.state.isSorting}
                                    onSorted={l => this.setState({ sortingList: l })}
                                    listItem={route => (
                                        <li key={route.route_id} className={styles["list-item"]}>
                                            <div className={styles["list-item-content"]}>
                                                <div className={styles["list-item-name"]}>{route.name}</div>
                                                <div className={styles["list-item-detail"]}>
                                                    {route.base_fee}円{route.base_point_a === null ? "" : ` A:${route.base_point_a}点`}{route.base_point_b === null ? "" : ` B:${route.base_point_b}点`}{route.code === "" ? "" : ` (${route.code})`}
                                                </div>
                                                {(route.extra_fee !== null) && (
                                                    <div className={styles["list-item-detail"]}>
                                                        ({route.base_limit}ml以上、{route.extra_step}ml毎{route.extra_fee}円{route.extra_point_a === null ? "" : ` A:${route.extra_point_a}点`}{route.extra_point_b === null ? "" : ` B:${route.extra_point_b}点`})
                                                    </div>
                                                )}                                                            
                                            </div>
                                            { this.state.isSorting ? (
                                                <div className={styles["list-item-icon"]}><i className="fas fa-bars" /></div>        
                                            ) : (
                                                <div onClick={()=>this.startEdit(route)} className={styles["list-item-icon"]}><i className="fas fa-pen clickable" /></div>
                                            )}
                                        </li>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{height:"40px", marginTop:"6px"}} onClick={this.addMedicineRoute}>
                    {!this.state.isSorting && (
                        <span className="link">
                            <i className="fa fa-plus"></i>
                            <span> 投薬経路を追加</span>
                        </span>
                    )}
                </div>
                {
                    this.state.editingData != null && (
                        <MedicineRouteEditPopup 
                            data={this.state.editingData}
                            onNameEdited={name => this.onDataEdit({ name })}
                            onBaseFeeEdited={base_fee => this.onDataEdit({ base_fee })}
                            onBasePointAEdited={base_point_a => this.onDataEdit({ base_point_a })}
                            onBasePointBEdited={base_point_b => this.onDataEdit({ base_point_b })}
                            onExtraChecked={this.onExtraChecked}
                            onBaseLimitEdited={base_limit => this.onDataEdit({ base_limit })}
                            onExtraStepEdited={extra_step => this.onDataEdit({ extra_step })}
                            onExtraFeeEdited={extra_fee => this.onDataEdit({ extra_fee })}
                            onExtraPointAEdited={extra_point_a => this.onDataEdit({ extra_point_a })}
                            onExtraPointBEdited={extra_point_b => this.onDataEdit({ extra_point_b })}
                            onCodeEdited={code => this.onDataEdit({ code })}
                            onIsFreeMedicineChanged={isFreeMedicine => this.onDataEdit({ isFreeMedicine })}
                            onIsInstructionChanged={isInstruction => this.onDataEdit({ isInstruction })}
                            onOnceFeeChanged={(isOnceFee,prior_fee_route_id) => this.onDataEdit({ isOnceFee, prior_fee_route_id })}
                            onClose={this.onCancelEdit}
                            onDelete={this.onDelete}
                            onSubmit={this.onSubmitEdit}
                            isSubmitExecuting={this.state.executing}
                            allRouteList={medicineRoutes}
                        />
                    )
                }
            </div>
        )
    }
}

export default withRouter(withContext(MedicineRouteMaster));