import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

import { ExecutionButton } from '../buttons/execution-button';
import { LMT } from '../../config/constant';

interface MyProps {
    onSubmit:(name:string) => void;
    onClose:() => void;
    isSubmitExecuting: boolean;
}

export const NewRanchPopup = (props:MyProps) => {
    const [ name, setName ] = useState("");

    return (
        <div>
            <Modal isOpen={true} className="modal-slim">
                <ModalHeader toggle={props.onClose}>未加入牧場の作成</ModalHeader>
                <ModalBody>
                    <div style={{ display:"flex", flexWrap:"wrap", alignItems:"center" }}>
                        <label className="m-b-0 m-r-10">牧場名</label>
                        <input type="text" className="form-control" style={{ flex:1 }}
                            maxLength={LMT.RANCH.NAME_LEN}
                            value={name} onChange={e => setName(e.target.value)}
                        />
                    </div>
                </ModalBody>
                <ModalFooter className="modal-footer-fix">
                    <ExecutionButton type="save" disabled={props.isSubmitExecuting || name.trim() === ""} onClick={() => props.onSubmit(name)}>作成</ExecutionButton>
                </ModalFooter>
            </Modal>
        </div>
    )
}