import React from 'react';
import classnames from 'classnames';
import styles from './numeric-updown.module.css';
import { RequiredNumInput } from './num-input';

interface NumericUpDownProps {
    min: number;
    max: number;
    value: number;
    onChange: (val:number) => void;
    className?: string;
    style?: React.CSSProperties;
}

export const NumericUpDown: React.FC<NumericUpDownProps> = (props) => {

    return (
        <div className={classnames(styles.container, props.className)} style={props.style}>
            <button className={classnames("btn","btn-outline-secondary",styles.button)}
                disabled={props.value <= props.min}
                onClick={() => props.onChange(props.value - 1)}>－</button>
            <RequiredNumInput
                min={props.min}
                max={props.max}
                step={1}
                value={props.value}
                onChange={props.onChange}
                className={styles.input}
            />
            <button className={classnames("btn","btn-outline-secondary",styles.button)}
                disabled={props.max <= props.value}
                onClick={() => props.onChange(props.value + 1)}>＋</button>
        </div>
    )
}