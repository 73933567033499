import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { PageSettings } from '../../config/page-settings';
import { withContext } from '../../stores';
import { AppState } from '../../app';
import { OptionalNumInput } from '../../components/parts/num-input';
import { RanchApi, RanchConstModifyReq, RanchConstModifySellAgeReq } from '../../api';
import { COW_SELL_TYPE, LMT } from '../../config/constant';
import { CommonUtil } from '../../config/util';
import styles from './ranch-const.module.css';

type EditingData = { [sellType: number] : { [breed: number]: { male: number | undefined, female: number | undefined } } };

interface MyState {
    ranch_id?: number;
    values: EditingData;
}

class RanchConst extends Base<BaseProps<{id?:string},{},{}>, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            values: {}
        };
    }

    componentDidMount() {

        this.context.handleSetHeader({ title:"牧場計画値の設定" });
        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        this.init();
    }

    componentDidUpdate(prevProps: this['props']) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.init();
        }
    }

    async init() {
        const ranchIdStr = this.props.match.params.id;
        const ranchId = Number(ranchIdStr);
        if (ranchIdStr == null || isNaN(ranchId)) {
            this.setState({ ranch_id: undefined });
            return;
        }

        if (this.handleNotAllowAccess(ranchId, ["MASTER_EDIT"], [])) {
            this.setState({ ranch_id: undefined });
            return;
        }

        await this.setStateAsync({ ranch_id: ranchId });
        this.context.handleSetIsLoading(true);
        await this.reloadConst(ranchId);
        this.context.handleSetIsLoading(false);
    }

    async reloadConst(ranch_id: number) {
        const res = await this.comm().send((await RanchApi()).getConstUsingPOST({ ranch_id }), { retries: true });

        if (res.result !== "OK") return;

        const records = res.data?.sell_ages ?? [];
        const vals: EditingData = {};

        CommonUtil.groupBy(records, r => r.sell_type)
            .forEach((breeds, sellType) => {
                const breedVals: EditingData[number] = {};

                CommonUtil.groupBy(breeds, b => b.breed_no)
                    .forEach((sexes, breedNo) => {
                        breedVals[breedNo] = {
                            male: sexes.find(s => s.is_male === 1)?.age,
                            female: sexes.find(s => s.is_male === 0)?.age
                        };
                    });

                vals[sellType] = breedVals;
            });

        await this.setStateAsync({
            values: vals
        });
    }

    async onSubmit() {
        if (!CommonUtil.assertNotNull(this.state.ranch_id, "ranch_id", "submit")) return;

        const ages: RanchConstModifySellAgeReq[] = [];
        Object.keys(this.state.values).forEach(sellType => {
            const breeds = this.state.values[sellType];

            Object.keys(breeds).forEach(breedNo => {
                const breed = breeds[breedNo];

                if (breed.male != null) {
                    ages.push({ sell_type: Number(sellType), breed_no: Number(breedNo), is_male: 1, age: breed.male });
                }
                if (breed.female != null) {
                    ages.push({ sell_type: Number(sellType), breed_no: Number(breedNo), is_male: 0, age: breed.female });
                }
            })
        })

        const req: RanchConstModifyReq = {
            ranch_id: this.state.ranch_id,
            sell_ages: ages
        };

        this.context.handleSetIsLoading(true);
        try {
            const res = await this.comm().send((await RanchApi()).modifyConstUsingPOST(req));
            if (res.result !== "OK") return;

            this.context.showToast("設定が保存されました");
    
        } finally {
            this.context.handleSetIsLoading(false);
        }
    }

    onAgeChange(sellType: number, breedNo: number, isMale: boolean, value: number | undefined) {
        const values = { ...this.state.values };
        let breeds = values[sellType];
        if (breeds == null) {
            breeds = {};
            values[sellType] = breeds;
        }
        let breedData = breeds[breedNo];
        if (breedData == null) {
            breedData = { male: isMale ? value : undefined, female: isMale ? undefined : value };
        } else {
            breedData = { male: isMale ? value : breedData.male, female: isMale ? breedData.female : value }
        }
        breeds[breedNo] = breedData;

        //同じ項目のメスが未設定のときは同じ値を設定する
        if (isMale && breedData.female == null) {
            breedData.female = value;
        }

        this.setState({
            values
        })
    }

    render() {
        if (this.state.ranch_id == null) {
            return <></>
        }

        const breeds = this.props.rootStore.options.cow_breed;

        const sellTypes = [ COW_SELL_TYPE.CHILD, COW_SELL_TYPE.FAT ];

        return (
            <div className="page-root">
                <div className="product product-with-footer">
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix" style={{ overflowY: "auto" }}>
                            { sellTypes.map(tp => (<React.Fragment key={tp.value}>
                                <div className={styles["group-label"]}>{tp.planName}月齢</div>
                                <div className={styles.group}>
                                    { breeds.map(b => (
                                        <div key={b.breed_no} className={styles.row}>
                                            <div className={styles.header}>{b.name}</div>
                                            <div className={styles.content}>
                                                <div className={styles.age}>
                                                    <span>♂</span>
                                                    <OptionalNumInput
                                                        className={styles.input}
                                                        value={(this.state.values[tp.value] ?? {})[b.breed_no]?.male}
                                                        min={LMT.COW.AGE_M_MIN}
                                                        max={LMT.COW.AGE_M_MAX}
                                                        step={LMT.COW.AGE_M_STEP}
                                                        onChange={val => this.onAgeChange(tp.value, b.breed_no, true, val)}
                                                    />
                                                    <span>ヵ月</span>
                                                </div>
                                                <div className={styles.age}>
                                                    <span>♀</span>
                                                    <OptionalNumInput
                                                        className={styles.input}
                                                        value={(this.state.values[tp.value] ?? {})[b.breed_no]?.female}
                                                        min={LMT.COW.AGE_M_MIN}
                                                        max={LMT.COW.AGE_M_MAX}
                                                        step={LMT.COW.AGE_M_STEP}
                                                        onChange={val => this.onAgeChange(tp.value, b.breed_no, false, val)}
                                                    />
                                                    <span>ヵ月</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </React.Fragment>))}
                        </div>
                    </div>
                    <div className="content-footer page-footer">
                        <div className="btn-row">
                            <button className="btn btn-success" onClick={() => this.onSubmit()}>保存</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(withContext(RanchConst));