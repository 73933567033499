import React, { useCallback } from 'react';
import { SortableContainer, SortableElement, SortEnd } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { FreezedArray } from '../../config/util';

interface MyProps<TItem> {
    isSorting: boolean;
    items: FreezedArray<TItem>;
    listItem: (item: TItem) => React.ReactElement;
    className?: string;
    style?: React.CSSProperties;
    onSorted: (items: TItem[]) => void;
}

export const SortableList = <TItem extends {}>(props: MyProps<TItem>) => {

    const ListItem = SortableElement(({item}:{item: TItem}) => props.listItem(item))

    const Container = SortableContainer(() => (
        <ul className={props.className} style={props.style}>
            { props.items.map((item,idx) => <ListItem key={idx} item={item} index={idx} disabled={!props.isSorting} />)}
        </ul>
    ));

    const onSortEnd = useCallback((sort: SortEnd) => {
        props.onSorted(arrayMove(props.items, sort.oldIndex, sort.newIndex))

    }, [ props.items, props.onSorted ]);

    return <Container onSortEnd={onSortEnd} />
}

export const MemoSortableList = React.memo(SortableList) as typeof SortableList;