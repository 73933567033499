import React from 'react';
import { ICowUse } from '../../stores';
import { ResponsiveContainer,  Legend, CartesianGrid, YAxis, XAxis, BarChart, BarProps, Bar } from 'recharts';
import { A } from '../../config/constant';
import { FreezedArray } from '../../config/util';
import { DynamicsAgeDto } from '../../api';

export interface AgeGraphProps {
    data: FreezedArray<DynamicsAgeDto>;
    cowUses: FreezedArray<ICowUse>;
}

type AgeGraphData = {[useNo:number]:number} & {
    age: number
};

export const AgeGraph = React.memo((props: AgeGraphProps) => {

    const barProps = (use: ICowUse): BarProps => {

        return {
            unit: "頭",
            dataKey: use.use_no,
            name: use.name,
            yAxisId: 1,
            fill: A.COW_USE_COLORS.find(c => c.use_no === use.use_no)?.color ?? "white",
            animationDuration: 500,
            stackId:"a",
        }
    }

    const graphData: AgeGraphData[] = props.data.filter(d => d.age != null).map(d => {
        const age = d.age!;
        const data: AgeGraphData = { age };
        d.score.filter(s => s.use_no != null).forEach(s => data[s.use_no!] = s.count);
        return data;
    });


    return (
        <div style={{width:"calc(100%-10px)", flex: 1, position:"relative", marginLeft:"-10px"}}>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart data={graphData}>
                    { props.cowUses.map(u => (
                        <Bar key={u.use_no} {...barProps(u)} />
                    ))}
                    <XAxis dataKey="age" type="number"
                        tick={{fontSize:"10px"}}
                        height={33}
                        tickCount={20}
                        domain={[0,"dataMax"]}
                    />
                    <YAxis yAxisId={1} />
                    <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />

                    <Legend height={40} margin={{top:40, left:0, right:0, bottom:0}} />
                </BarChart>
            </ResponsiveContainer>
        </div>
    )

});