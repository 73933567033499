import React, { useState, useEffect } from 'react';
import { ModalHeader, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { EditingPhisical, SymptomPhysicalInput, ISymptomName, loadSymptomNameList, storeSymptomNameList, loadNoticeList, storeNoticeList } from '../../symptom/symptom-physical-input';
import { Communicator } from '../../../api/communicator';
import { useRootStore } from '../../../stores';
import { LMT } from '../../../config/constant';
import baseStyles from '../treatbulk.module.css';
import { useDiseaseRelationsForInput } from '../../../stores/fetcher';
import { FetchWaiter, FetchError } from '../../../components/content/fetch-state';

interface Data {
    physical?: EditingPhisical;
    memo: string;
}

interface MyProps extends Data {
    onSubmit: (data: Data) => void;
    onClose: () => void;
    comm: Communicator;
    ranch_id: number;
    clinic_id: number | undefined;
    cowId: number;
}

export const anyPhysicalValue = (data: EditingPhisical) => {
    if (data.symptom_name !== "") return true;
    if (data.active_score != null) return true;
    if (data.hungry_score != null) return true;
    if (data.breath_count != null) return true;
    if (data.temperature_x10 != null) return true;
    if (data.heart_rate != null) return true;
    if (data.feces_color != null || data.feces_state != null) return true;
    if (data.diseases.length > 0) return true;
    if (data.conditions.length > 0) return true;
    if (data.notice !== "") return true;
    return false;
}

export const PhysicalEditModal = (props: MyProps) => {
    const [ physical, setPhysical ] = useState<EditingPhisical>({ conditions: [], diseases: [], symptom_name: "", notice:"" });
    const [ memo, setMemo ] = useState("");
    const [ symptomNameList, setSymptomNameList ] = useState<ISymptomName[]>([]);
    const [ noticeList, setNoticeList ] = useState<Readonly<string[]>>([]);
    const [ anyInvalid, setAnyInvalid ] = useState(false);

    const mDiseases = useDiseaseRelationsForInput(props.clinic_id ?? props.ranch_id);

    const rootStore = useRootStore();

    useEffect(() => {
        setSymptomNameList(loadSymptomNameList());
        setNoticeList(loadNoticeList(rootStore.user.id));

    }, []);

    useEffect(() => {
        setPhysical(props.physical ?? { conditions: [], diseases: [], symptom_name: "", notice:"" });

    }, [ props.physical ]);

    useEffect(() => {
        setMemo(props.memo);

    }, [ props.memo ]);
    
    const onSubmit = () => {
        if (physical.symptom_name !== "") {
            storeSymptomNameList(symptomNameList, physical.symptom_name);
        }
        if (physical.notice !== "") {
            storeNoticeList(noticeList, physical.notice, rootStore.user.id);
        }

        const ph = anyPhysicalValue(physical) ? physical : undefined;
        props.onSubmit({ physical: ph, memo });
    }

    return (
        <div>
            <Modal isOpen={true} style={{ maxWidth:"480px", margin:"auto" }}>
                <ModalHeader toggle={() => props.onClose()}>体調記録</ModalHeader>
                { mDiseases.isLoading ? (
                    <FetchWaiter />
                ) : (mDiseases.isError || mDiseases.data == null) ? (
                    <FetchError />
                ) : (<>
                    <ModalBody className={ baseStyles["modal-body"] }>
                        <SymptomPhysicalInput
                            comm={props.comm}
                            data={physical}
                            conditions={mDiseases.data.conditions}
                            relations={mDiseases.data.relations}
                            diseases={rootStore.options.disease}
                            causes={rootStore.options.causes}
                            fecesColorList={rootStore.options.feces_color}
                            fecesStateList={rootStore.options.feces_state}
                            symptomNameList={symptomNameList}
                            noticeList={noticeList}
                            onChange={d => setPhysical(d)}
                            onHasInvalidChanged={inv => setAnyInvalid(inv)}
                            diseaseInputMode={props.clinic_id != null ? "flat" : "none"}
                        />
                        <div className="form-group row treat-write-row">
                            <label className="col-form-label col-md-2 col-xs-2 text-lg-right">メモ</label>
                            <div className="col-md-10 col-xs-10">
                                <textarea className="form-control" rows={4} maxLength={LMT.SYMPTOM.MEMO_LEN} data-testid="memo"
                                    value={memo} onChange={(e) => setMemo(e.target.value)} />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className={ baseStyles.bluebutton } disabled={anyInvalid} onClick={() => onSubmit()}>確定</button>
                        <button className={ baseStyles.graybutton } onClick={props.onClose}>キャンセル</button>
                    </ModalFooter>
                </>)}
            </Modal>
        </div>
    )
}