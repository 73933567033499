import React, { useState } from 'react';
import iconOn from '../../assets/icon/washout-on_s.png';
import iconOff from '../../assets/icon/washout-off_s.png';
import { CowWashoutPopup } from '../../pages/cow/cow-washout-popup';
import { Washouts } from '../../config/Washout';

export interface WashoutIconProps {
    isON: boolean;
    washouts?: Washouts,
    onClick?: ()=>void;
    style?: React.CSSProperties;
    className?: string;
    canViewDetail: boolean;
}

export const WashoutIcon = React.memo<WashoutIconProps>((props) => {

    const defaultStyle: React.CSSProperties = {
        height: "22px",
    };

    const [ isWashoutPopupShown, setIsWashoutPopupShown ] = useState(false);

    const onIconClick = () => {
        if(props.washouts == null) return;
        if(!props.isON) return;
        setIsWashoutPopupShown(true)
        if(props.onClick != null) props.onClick();
    }

    return (<>
        <img src={props.isON ? iconOn : iconOff} className={props.className} onClick={onIconClick}
            style={{ ...defaultStyle, ...props.style }}
            alt="" 
        />
        {
            props.washouts && isWashoutPopupShown && (
                <CowWashoutPopup
                    washouts={props.washouts}
                    onClose={() => setIsWashoutPopupShown(false)}
                    canViewDetail = {props.canViewDetail??false}
                />
            )
        }

    </>);
});
