import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ProgramDto, ProgramItemDto, RanchTagDto } from '../../api';
import { IPopupCow, CowsPopup } from '../../components/parts/cows-popup';
import { ProgramKind, PROGRAM_TRIGGER_KIND } from '../../config/program-kind';
import styles from './cow-program.module.css';
import classnames from 'classnames';
import PrevNextDatePicker from '../../components/parts/prev-next-date-picker';
import { ColorPicker } from '../../components/parts/color-picker';
import { FreezedArray } from '../../config/util';
import { ICowBreed, ICowUse } from '../../stores';
import { listConditionNames, ProgramTriggerCondition } from '../../config/program-trigger-condition';
import { TAG_ACTION } from '../../config/constant';
import { useRanchMedicineAndTreats } from '../../stores/fetcher';
import { FetchWaiter, FetchError } from '../../components/content/fetch-state';

export interface CowProgramPopupProps {
    onClose: () => void;
    onApplyEvent: () => void;
    onApplyManual: (baseDate: Date, color?:string) => void;
    onRemove: () => void;
    onMoveToManage: (() => void) | undefined;
    program: Readonly<ProgramDto>;
    cows: FreezedArray<IPopupCow>;
    isSubmitExecuting: boolean;
    cowBreeds: FreezedArray<ICowBreed>;
    cowUses: FreezedArray<ICowUse>;
    tags: FreezedArray<RanchTagDto>;
}

export const CowProgramPopup: React.FC<CowProgramPopupProps> = (props) => {
    //イベントトリガ条件の表示に使用
    //（診療所プログラムはここでは手動のみなので、牧場プログラムの場合のみ）
    const masters = useRanchMedicineAndTreats(props.program.ranch_id);

    const [ baseDate, setBaseDate ] = useState(new Date());
    const [ sortedItems, setSortedItems ] = useState<ProgramItemDto[]>([]);
    const [ appliedCows, setAppliedCows ] = useState<IPopupCow[]>([]);
    const [ unappliedCows, setUnappliedCows ] = useState<IPopupCow[]>([]);
    const [ color, setColor ] = useState<string>();

    useEffect(() => {
        setSortedItems(props.program.items.sort((a,b) => a.item_no - b.item_no))

    }, [ props.program ]);

    useEffect(() => {
        setAppliedCows(props.cows.filter(c => props.program.cow_ids.includes(c.cow_id)));
        setUnappliedCows(props.cows.filter(c => !props.program.cow_ids.includes(c.cow_id)));

    }, [ props.program, props.cows ]);

    const getBaseDateKindName = (pg: ProgramDto) => {
        if (pg.base_date_kind == null || pg.trigger_event == null) return "";
        return ProgramKind.getBaseDateKindName(pg.trigger_event, pg.base_date_kind) ?? "";
    }

    const getTriggerName = (pg: ProgramDto) => {
        if (pg.trigger_event == null) return "";

        const evt = ProgramKind.findTrigger(pg.trigger_event)?.name;
        if (evt == null) return "";

        if (pg.trigger_condition == null) return evt;

        const cond = JSON.parse(pg.trigger_condition) as ProgramTriggerCondition;
        const conds = listConditionNames(cond, props.cowBreeds, props.cowUses, masters.data?.medicines ?? [], masters.data?.treatItems ?? []);
        if (conds.length === 0) return evt;

        return `${evt}（${conds.join(",")}）`;
    }

    const isEvent = props.program.trigger_kind === PROGRAM_TRIGGER_KIND.EVENT.no;

    const ID = "cow-program-popup";

    const tagsToAdd = props.program.tags.filter(t => t.action_kind === TAG_ACTION.ADD)
                        .map(t => props.tags.find(pt => pt.tag_id === t.tag_id)?.tag_name ?? "");
    const tagsToRem = props.program.tags.filter(t => t.action_kind === TAG_ACTION.REM)
                        .map(t => props.tags.find(pt => pt.tag_id === t.tag_id)?.tag_name ?? "");

    return (
        <div>
            <Modal isOpen={true} toggle={props.onClose} centered={true} id={ID}>
                <ModalHeader toggle={props.onClose}>{props.program.name}</ModalHeader>
                { masters.isLoading ? (
                    <FetchWaiter />
                ) : (masters.isError || masters.data == null) ? (
                    <FetchError />
                ) : (<>
                    <ModalBody style={{ maxHeight: "calc(100vh - 180px)" }}>
                        <div className={styles["modal-content"]}>
                            { isEvent && (<>
                                <div className={styles.row}><span className={styles.label}>起動タイミング</span>：{getTriggerName(props.program)}</div>
                                { props.program.base_date_kind != null && (
                                    <div className={styles.row}><span className={styles.label}>起算日</span>：{getBaseDateKindName(props.program)}</div>
                                )}
                            </>)}

                            <div className={classnames(styles.row, styles.label)}>プログラム項目</div>

                            { sortedItems.map(i => (
                                <div key={i.item_no} className={classnames(styles["row-sub"])}>{i.item_no}. {i.title}（{i.base_item_no === 0 ? "起算日" : `${i.base_item_no}.の`}{i.interval_days}日後）</div>
                            ))}
                            { tagsToAdd.length > 0 && (
                                <div className={classnames(styles["row-sub"])}>タグをつける：{tagsToAdd.join(", ")}</div>
                            )}
                            { tagsToRem.length > 0 && (
                                <div className={classnames(styles["row-sub"])}>タグをはずす：{tagsToRem.join(", ")}</div>
                            )}

                            { props.onMoveToManage && (
                                <div className={classnames(styles.row,  styles.right)}>
                                    <span className={classnames("link", styles.right)} onClick={props.onMoveToManage}>管理画面で編集</span>
                                </div>
                            )}

                            { appliedCows.length > 0 && unappliedCows.length > 0 && (
                                <div className={styles.row}>※{appliedCows.length}頭に適用中 <CowsPopup height="22px" cows={appliedCows} placement="top" /></div>
                            )}

                            { !isEvent && (
                                <div className={classnames(styles.row, styles.inline)}>
                                    <div className="m-r-10 m-t-5 m-b-5">予定の背景色</div>
                                    <ColorPicker color={color ?? "white"} onChange={setColor} />
                                    { color == null ? (
                                        <div className="m-l-15" style={{ marginTop:"-2px", color:"#666", fontSize: "0.8125rem" }}>指定なし</div>
                                    ) : (
                                        <button type="button" className="btn btn-light btn-sm ml-2" onClick={() => setColor(undefined)}>初期値に戻す</button>
                                    )}
                                </div>
                            )}

                        </div>
                    </ModalBody>
                    { isEvent && (
                        <ModalFooter style={{display:"flex", justifyContent:"space-around"}}>
                            { unappliedCows.length > 0 && (
                                <button className="btn btn-green" onClick={props.onApplyEvent} disabled={props.isSubmitExecuting}>適用する</button>
                            )}
                            { appliedCows.length > 0 && (
                                <button className="btn btn-red" onClick={props.onRemove} disabled={props.isSubmitExecuting}>解除する</button>
                            )}
                        </ModalFooter>
                    )}
                    { !isEvent && (
                        <ModalFooter style={{display:"flex"}}>
                            <div>起算日</div>
                            <PrevNextDatePicker name="pndt" value={baseDate} onChange={e => setBaseDate(e.target.value)} portalContainerID={ID} />
                            <button className="btn btn-green" onClick={() => props.onApplyManual(baseDate, color)} disabled={props.isSubmitExecuting}>適用する</button>
                        </ModalFooter>
                    )}
                </>)}
            </Modal>
        </div>

    );
}