import { IPopupCow } from "../components/parts/cows-popup";
import { IBreedingWriteLocationState } from "../pages/breeding/breeding-write";
import { RutWriteLocationState } from "../pages/rut/rut-write";
import { TeamType } from "./constant";
import { CowWriteLocationState } from "../pages/cow/cow-write";
import { EVENT_KIND } from "./event-kind";
import { COW_ACTION_TYPE } from "../pages/ranch/ranch-houses";
import { IIvfWriteLocationState } from "../pages/egg/ivf-write";
import { IIvfEggWriteLocationState } from "../pages/egg/ivf-egg-write";
import { RanchSchedulePresetDto } from "../api";

export type IEventWriteParamCow = IPopupCow & {
    use_no: number | undefined;
    breed_no: number | undefined;
} 
export interface ICowsLocationState {
    cows: IEventWriteParamCow[];
}
export interface IEventWriteLocationState {
    schedule_id?: number;
    cows: IEventWriteParamCow[];
    preset?: RanchSchedulePresetDto;
}
export interface IEventWriteSearch {
    param: string | number;
    cow_top?: 1 | 0;
}

class HistoryLocationBuilder {

    toFeedWrite(search: IEventWriteSearch, state?: IEventWriteLocationState) {
        return this.build("/feed/write", search, state);
    }
    toFeedWriteEdit(id: number) {
        return this.build("/feed/write/" + id);
    }
    toSymptomWrite(search: IEventWriteSearch & { first_id?:number }, state?: IEventWriteLocationState) {
        return this.build("/symptom/write", search, state);
    }
    toSymtomWriteEdit(id: number) {
        return this.build("/symptom/write/" + id);
    }
    toPreventionWrite(search: IEventWriteSearch, state?: IEventWriteLocationState) {
        return this.build("/prevention/write", search, state);
    }
    toPreventionWriteEdit(id: number) {
        return this.build("/prevention/write/" + id);
    }
    toBreedingWrite(search: IEventWriteSearch, state?: IBreedingWriteLocationState) {
        return this.build("/breeding/write", search, state);
    }
    toBreedingWriteEdit(id: number) {
        return this.build("/breeding/write/" + id);
    }
    toDeliveryWrite(search: IEventWriteSearch, state?: IEventWriteLocationState) {
        return this.build("/delivery/write", search, state);
    }
    toDeliveryWriteEdit(id: number) {
        return this.build("/delivery/write/" + id);
    }
    //※新規のときは cow_idsの指定が必須
    toRutWrite(state: RutWriteLocationState & { cow_ids: number[] }) {
        return this.build("/rut/write", undefined, state);
    }
    toRutWriteEdit(id: number, state: RutWriteLocationState) {
        return this.build("/rut/write/" + id, undefined, state);
    }
    toLocationWrite(search: IEventWriteSearch, state?: IEventWriteLocationState) {
        return this.build("/cow/location/write", search, state);
    }
    toGrowthWrite(search: IEventWriteSearch, state?: IEventWriteLocationState) {
        return this.build("/growth/write", search, state);
    }
    toGrowthWriteEdit(id: number) {
        return this.build("/growth/write/" + id);
    }
    toSovWrite(state: IEventWriteLocationState) {
        return this.build("/egg/sov-write", undefined, state);
    }
    toSovWriteEdit(id: number) {
        return this.build("/egg/sov-write/" + id);
    }
    toOpuWrite(state: IEventWriteLocationState) {
        return this.build("/egg/opu-write", undefined, state);
    }
    toOpuWriteEdit(id: number) {
        return this.build("/egg/opu-write/" + id);
    }
    toIvfWrite(state: IIvfWriteLocationState) {
        return this.build("/egg/ivf-write", undefined, state);
    }
    toIvfWriteEdit(id: number) {
        return this.build("/egg/ivf-write/" + id);
    }
    toIvfEggWrite(state: IIvfEggWriteLocationState) {
        return this.build("/egg/ivf-egg-write", undefined, state);
    }
    toIvfEggWriteEdit(id: number) {
        return this.build("/egg/ivf-egg-write/" + id);
    }
    toCertVaccine(state: ICowsLocationState) {
        return this.build("/cert/vaccine", undefined, state);
    }
    toCertDisease(state: ICowsLocationState) {
        return this.build("/cert/disease", undefined, state);
    }
    toCertKarte(state: ICowsLocationState) {
        return this.build("/cert/karte", undefined, state);
    }
    toRobotImport() {
        return this.build("/robot/import");
    }
    toReportSensor() {
        return this.build("/report/sensor");
    }

    toEventWriteEdit(eventKindNo: number, id: number, cows: IEventWriteParamCow[]) {
        switch (eventKindNo) {
            case EVENT_KIND.FEEDING.no:
                return this.toFeedWriteEdit(id);
            case EVENT_KIND.SYMPTOM.no:
                return this.toSymtomWriteEdit(id);
            case EVENT_KIND.PREVENTION.no:
                return this.toPreventionWriteEdit(id);
            case EVENT_KIND.BREEDING.no:
            case EVENT_KIND.CROSS.no:
                return this.toBreedingWriteEdit(id);
            case EVENT_KIND.RUT.no:
                return this.toRutWriteEdit(id, { cows:cows });
            case EVENT_KIND.DELIVERY.no:
                return this.toDeliveryWriteEdit(id);
            case EVENT_KIND.GROWTH.no:
                return this.toGrowthWriteEdit(id);
            case EVENT_KIND.SOV.no:
                return this.toSovWriteEdit(id);
            case EVENT_KIND.OPU.no:
                return this.toOpuWriteEdit(id);
            case EVENT_KIND.IVF.no:
                return this.toIvfWriteEdit(id);
            case EVENT_KIND.IVF_EGG.no:
                return this.toIvfEggWriteEdit(id);
            case EVENT_KIND.SELL_COW.no:
                return this.toSellCowEdit(id);
            case EVENT_KIND.LOCATION.no:
            case EVENT_KIND.OTHER.no:
                return null;
            default:
                console.error("invalid param ", eventKindNo);
                return null;
        }
    }
    toEventWrite(eventKindNo: number, cows: IEventWriteParamCow[], schedule_id: number | undefined, preset: RanchSchedulePresetDto | undefined) {
        const cow_ids = cows.map(c => c.cow_id);
        const search = { param: cow_ids.join(",") }
        const state = { cows, schedule_id, preset };

        switch (eventKindNo) {
            case EVENT_KIND.FEEDING.no:
                return this.toFeedWrite(search, state);
            case EVENT_KIND.SYMPTOM.no:
                return this.toSymptomWrite(search, state);
            case EVENT_KIND.PREVENTION.no:
                return this.toPreventionWrite(search, state);
            case EVENT_KIND.LOCATION.no:
                return this.toLocationWrite(search, state);
            case EVENT_KIND.BREEDING.no:
            case EVENT_KIND.CROSS.no:
                return this.toBreedingWrite(search, { ...state, seed: undefined });
            case EVENT_KIND.RUT.no:
                return this.toRutWrite({ ...state, cow_ids });
            case EVENT_KIND.DELIVERY.no:
                return this.toDeliveryWrite(search, state);
            case EVENT_KIND.SELL_COW.no:
                return this.toSellCow(state);
            case EVENT_KIND.GROWTH.no:
                return this.toGrowthWrite(search, state);
            case EVENT_KIND.SOV.no:
                return this.toSovWrite(state);
            case EVENT_KIND.OPU.no:
                return this.toOpuWrite(state);
            case EVENT_KIND.IVF.no:
                return this.toIvfWrite(state);
            case EVENT_KIND.IVF_EGG.no:
                return this.toIvfEggWrite(state);
            case EVENT_KIND.OTHER.no:
                return null;
            default:
                console.error("invalid param ", eventKindNo);
                return null;
        }
    }

    toCowWrite(state?: CowWriteLocationState) {
        return this.build("/cow/write", undefined, state);
    }
    toCowWriteEdit(cow_id: number) {
        return this.build("/cow/write/" + cow_id);
    }
    toRanchMain() {
        return this.build("/ranch/main/");
    }
    toCowInfo(cow_id: number) {
        return this.build("/cow/info/" + cow_id);
    }
    toCowEvent(cow_id: number, day: string, type?: number) {
        return this.build("/cow/event/" + cow_id, { param: day, type });
    }

    toTeamAuthority(team_id: number, user_id?:string) {
        return this.build("/team/authority/" + team_id, undefined, { user_id });
    }

    toBalanceCow(cowIds: number[]) {
        return this.build("/balance/cow", { param: cowIds.join(",") });
    }
    toBalanceRanch() {
        return this.build("/balance/ranch");
    }
    toSeedSetting(ranchId: number, seedType?:number) {
        return this.build("/setting/seed/" + ranchId, { seed_type: seedType });
    }
    toProgramSetting(ranchId: number) {
        return this.build("/setting/program/" + ranchId);
    }
    toDiseaseSetting(clinicId: number) {
        return this.build("/setting/disease/" + clinicId);
    }
    toPresetSetting(teamId: number) {
        return this.build("/setting/preset/" + teamId);
    }
    toVisitingRanchSetting(clinicId: number) {
        return this.build("/setting/visiting/ranch/" + clinicId);
    }
    toVisitFeeSetting(clinicId: number) {
        return this.build("/setting/visit/fee/" + clinicId);
    }
    
    toSellCow(state: IEventWriteLocationState) {
        if (state.cows.length === 1) {
            return this.build("/sellcow/single", { param: state.cows[0].cow_id }, state);
        } else {
            return this.build("/sellcow/multi", undefined, state);
        }
    }
    toSellCowEdit(id: number) {
        return this.build("/sellcow/single/" + id);
    }

    toSellMilk(search?: IEventWriteSearch, state?: IEventWriteLocationState) {
        const targetType = state?.cows == null ? "ranch" : "cow" ;
        return this.build("/sell/milk/" + targetType, search, state );
    }
    toSellMilkRanchEdit(id:number) {
        return this.build(`/sell/milk/ranch/${id}`);
    }
    toSellMilkCowEdit(id:number) {
        return this.build(`/sell/milk/cow/${id}`);
    }
    toOtherPl(search?: IEventWriteSearch, state?: IEventWriteLocationState) {
        const targetType = state?.cows == null ? "ranch" : "cow" ;
        return this.build("/balance/other/" + targetType, search, state );
    }
    toOtherPlRanchEdit(id:number) {
        return this.build(`/balance/other/ranch/${id}`);
    }
    toOtherPlCowEdit(id:number) {
        return this.build(`/balance/other/cow/${id}`);
    }

    toRanchHouseSettingEdit(id:number) {
        return this.build(`/setting/ranch/house/${id}`);
    }
    toCowProgram(cows:IEventWriteParamCow[]) {
        return this.build("/cow/program", undefined, {cows});
    }
    toRanchHouses(actionType: COW_ACTION_TYPE) {
        return this.build("/ranch/houses", { action: actionType });
    }
    toFeedRemained(cowIds?:number[]) {
        return this.build("/feed/remained", cowIds == null ? undefined : { param: cowIds.join(",") })
    }
    toUserSetting() {
        return this.build("/user/setting");
    }
    toRanchConst(ranchId: number) {
        return this.build("/setting/ranch/const/" + ranchId);
    }
    toOtherSetting(ranchId: number) {
        return this.build("/setting/ranch/setting/" + ranchId);
    }
    toSettingDynamics(ranchId: number) {
        return this.build("/setting/dynamics/" + ranchId);
    }
    toTeamInfo(teamId: number) {
        return this.build("/team/info/" + teamId);
    }
    toCalendar(teamId: number, eventId?: number) {
        return this.build("/schedule/" + teamId, { event: eventId });
    }
    toIncompletedSchedule(teamId: number, eventId?: number) {
        return this.build("/schedule/incompleted/" + teamId, { event: eventId });
    }

    build(path: string, search?: any, state?: any): IHistoryLocation {
        let searchStr: string | undefined;
        if (search != null) {
            const vals = Object.keys(search)
                .filter(k => search[k] != null)
                .map(k => k + "=" + search[k]);
            if (vals.length > 0) {
                searchStr = "?" + vals.join("&")
            }
        }
        return { pathname: path, search: searchStr, state };
    }
}

export type IHistoryLocation = {
    pathname: string,
    search?: string,
    state?: any
}

export const historyLocation = new HistoryLocationBuilder();