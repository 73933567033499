import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PageSettings } from '../../config/page-settings.js';
import { FeedRemainedContent, FeedRemainedContentProps } from './feed-remained-content';

type MyProps = FeedRemainedContentProps & {
    onClose: ()=>void;
    isOpen: boolean;
}

export class FeedRemainedPopup extends React.Component<MyProps,{}> {

    static contextType = PageSettings;

    constructor(props) {

        super(props);

        this.state = {
            feeds_left: this.props.feeds_left,
            arranged_data: [],
        };

        this.onClose = this.onClose.bind(this);
    }


    onClose() {
        this.props.onClose();
    }


    render() {
        return (
            <div className="modal-wide">
                <Modal isOpen={this.props.isOpen} style={{ maxWidth: "1000px" }}>
                    {/* style={{maxWidth:"1000px", position:"absolute",  top:"50%", left:"50%", transform:"translate(-50%, -50%)"}}> */}
                    <ModalHeader toggle={this.props.onClose} style={{ width: "100%" }}>食べ残し入力</ModalHeader>
                    <ModalBody style={{ overflowY: "auto", maxHeight: "calc(100vh - 150px)" }}>
                        <FeedRemainedContent {...this.props} isModal = {this.props.isOpen} />
                    </ModalBody>
                    <ModalFooter>
                        <div style={{ margin: "auto" }}>
                            <button className="btn btn-theme btn-sm btn-success" onClick={this.onClose}>閉じる</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}