import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { BreedingCertPregnantReq, BreedingCertAiReq, BreedingCertEtReq, BreedingCertEtReqEggTypeEnum } from '../../api';
import { ModalInputBlock } from '../../components/parts/modal-input-block';
import { IUser } from '../../stores';
import { UserTeams } from '../../config/user-teams';
import { FormDatePicker } from '../../components/form/form-date-picker';
import moment from 'moment';
import { CommonUtil } from '../../config/util';
import { A, LMT } from '../../config/constant';
import { RenderFullTraceId } from '../../components/parts/cows-popup';
import { IBreeding } from './cow-event';
import { UserLicenseKey, USER_LICENSE } from '../../config/user-license';
import { EGG_TYPES } from '../../config/egg-kind';
import { FormRadio } from '../../components/form/form-radio';
import { DataListInput } from '../../components/parts/data-list-input';

export interface CertPregPopupProps {
    onClose: () => void;
    onSubmit: (data: BreedingCertPregnantReq) => void;
    traceId: string;
    user: IUser;
    ranchId: number;
    cowId: number;
    crossDay?: Date;
    crossType?: number;
    watchedDay: Date;
    today: Date;
    cowFeatureList: string[];
    pregCheckDescriptionList: string[];
    clinicNameList: string[];
    addressList: string[];
}
export interface CertCrossPopupProps {
    onClose: () => void;
    onAiSubmit: (data: BreedingCertAiReq) => void;
    onEtSubmit: (data: BreedingCertEtReq) => void;
    traceId: string;
    user: IUser;
    ranchId: number;
    cowId: number;
    today: Date;
    eventData: IBreeding;
    cowFeatureList: string[];
    clinicNameList: string[];
    addressList: string[];
}

/**
 * 妊娠鑑定書情報編集モーダル
 */
export const CertPregPopup = React.memo((props: CertPregPopupProps) => {
    const [ data, setData ] = useState<Omit<BreedingCertPregnantReq, "published_on"|"crossed_on"|"checked_on"|"cross_type"> & { cross_type?:number }>({
        ranch_id:0,
        cow_id:0,
        checked_desc:props.pregCheckDescriptionList[0] ?? "",
        clinic_address:"",
        clinic_name:"",
        cow_feature:props.cowFeatureList[0] ?? "",
        doctor_name:"",
    });
    const [ crossDay, setCrossDay ] = useState(new Date());
    const [ publishDay, setPublishDay ] = useState(new Date());
    const [ watchedDay, setWatchedDay ] = useState(new Date());

    useEffect(() => {
        const userTeams = new UserTeams(props.user);
        //農家ユーザのときは牧場情報を初期値として使用
        const clinic = userTeams.findSingleClinic() ?? userTeams.findRanch(props.ranchId);


        //診療所（牧場）設定がないときは最新履歴を初期値に
        let clinicName = clinic?.name ?? "";
        if (clinicName === "") {
            clinicName = props.clinicNameList[0] ?? "";
        }
        let address = clinic?.address ?? "";
        if (address === "") {
            address = props.addressList[0] ?? "";
        }

        setData(d => ({
            ...d,
            ranch_id:props.ranchId,
            cow_id:props.cowId,
            clinic_name: clinicName,
            clinic_address: address,
            doctor_name: props.user.setting?.legal_name ?? "",
            cross_type: props.crossType
        }));

    }, [ props.ranchId, props.cowId, props.user, props.crossType ]);

    useEffect(() => {
        setCrossDay(props.crossDay ?? new Date());
        setWatchedDay(props.watchedDay);
        setPublishDay(props.today);

    }, [ props.crossDay, props.watchedDay, props.today ]);

    const onSubmit = () => {
        if (!CommonUtil.assertNotNull(data.cross_type, "cross_type")) return;

        const crossType = [...A.CROSS_TYPE.values()].find(c => c.no === data.cross_type);
        if (!CommonUtil.assertNotNull(crossType, "cross type of " + data.cross_type)) return;

        const req:BreedingCertPregnantReq = {
            ...data,
            checked_on: moment(watchedDay).format("YYYY-MM-DD"),
            published_on: moment(publishDay).format("YYYY-MM-DD"),
            crossed_on: moment(crossDay).format("YYYY-MM-DD"),
            cross_type: crossType.pregCertReq
        };
        props.onSubmit(req);
    }

    const ID = "cert-preg-popup";

    return (
        <div>
            <Modal isOpen={true} id={ID}>
                <ModalHeader toggle={props.onClose}>妊娠鑑定書</ModalHeader>
                <ModalBody>
                    <ModalInputBlock label="ID" contentStyle={{ margin: "8px 0"}}>
                        { RenderFullTraceId(props.traceId, {}, { fontSize:"1rem", padding:"0 1px" }) }
                    </ModalInputBlock>
                    <ModalInputBlock label="特徴">
                        <DataListInput
                            datalistId="cow_features"
                            value={data.cow_feature}
                            dataList={props.cowFeatureList}
                            onChange={val => setData({ ...data, cow_feature:val })}
                            maxLength={LMT.COW.FEATURE_LEN}
                        />
                    </ModalInputBlock>
                    <ModalInputBlock label="交配">
                        <select className="form-control" value={data.cross_type ?? ""}
                            onChange={e => setData({ ...data, cross_type: e.target.value === "" ? undefined : Number(e.target.value)})}>
                            <option value="">未選択</option>
                            { [...A.CROSS_TYPE.values()].map(c => (
                                <option key={c.no} value={c.no}>{c.name}</option>
                            ))}
                        </select>
                        <div className="m-t-5">
                            <FormDatePicker portalContainerID={ID} value={crossDay} name="crossday" onChange={e => setCrossDay(e.target.value)} />
                        </div>
                    </ModalInputBlock>
                    <ModalInputBlock label="検査方法">
                        <DataListInput
                            datalistId="preg_check_descriptions"
                            dataList={props.pregCheckDescriptionList}
                            value={data.checked_desc}
                            onChange={val => setData({ ...data, checked_desc:val })}
                            maxLength={LMT.BREEDING.PREG_CHECK_DESCRIPTION_LEN}
                        />
                    </ModalInputBlock>
                    <ModalInputBlock label="鑑定日">
                        <FormDatePicker portalContainerID={ID} value={watchedDay} name="watchedday" onChange={e => setWatchedDay(e.target.value)} />
                    </ModalInputBlock>
                    <ModalInputBlock label="発行日">
                        <FormDatePicker portalContainerID={ID} value={publishDay} name="publishday" onChange={e => setPublishDay(e.target.value)} />
                    </ModalInputBlock>
                    <ModalInputBlock label="住所">
                        <DataListInput
                            datalistId="clinic-address-list"
                            dataList={props.addressList}
                            value={data.clinic_address}
                            onChange={val => setData({ ...data, clinic_address:val })}
                            maxLength={LMT.RANCH.ADDRESS_LEN}
                        />
                    </ModalInputBlock>
                    <ModalInputBlock label="診療所名">
                        <DataListInput
                            datalistId="clinic-name-list"
                            dataList={props.clinicNameList}
                            value={data.clinic_name}
                            onChange={val => setData({ ...data, clinic_name:val })}
                            maxLength={LMT.RANCH.NAME_LEN}
                        />
                    </ModalInputBlock>
                </ModalBody>
                <ModalFooter className="modal-footer-fix">
                    <ExecutionButton type="save" onClick={onSubmit} disabled={data.cross_type == null}>発行</ExecutionButton>
                </ModalFooter>
            </Modal>
        </div>
    )
});


/**
 * AI/ET証明書情報編集モーダル
 */
export const CertCrossPopup = React.memo((props: CertCrossPopupProps) => {
    const [ data, setData ] = useState<Omit<BreedingCertEtReq, "published_on"|"crossed_on"|"cross_type"|"license_type"|"egg_type"> & { license_type:UserLicenseKey, isSov?:boolean }>({
        ranch_id:0,
        cow_id:0,
        clinic_address:"",
        clinic_name:"",
        cow_feature:props.cowFeatureList[0] ?? "",
        doctor_name:"",
        father_name:"",
        label_no:"",
        license_type:"AI",
        license_id:"",
    });
    const [ crossDay, setCrossDay ] = useState(new Date());
    const [ publishDay, setPublishDay ] = useState(new Date());
    const [ isEt, setIsEt ] = useState(false);

    useEffect(() => {
        const userTeams = new UserTeams(props.user);
        //農家ユーザのときは牧場情報を初期値として使用
        const clinic = userTeams.findSingleClinic() ?? userTeams.findRanch(props.ranchId);

        //診療所（牧場）設定がないときは最新履歴を初期値に
        let clinicName = clinic?.name ?? "";
        if (clinicName === "") {
            clinicName = props.clinicNameList[0] ?? "";
        }
        let address = clinic?.address ?? "";
        if (address === "") {
            address = props.addressList[0] ?? "";
        }

        setData(d => ({
            ...d,
            ranch_id:props.ranchId,
            cow_id:props.cowId,
            clinic_name: clinicName,
            clinic_address: address,
            doctor_name: props.user.setting?.legal_name ?? "",
            license_type: props.user.setting?.license?.type ?? "AI",
            license_id: props.user.setting?.license?.id ?? ""
        }));

    }, [ props.ranchId, props.cowId, props.user ]);

    useEffect(() => {
        setCrossDay(moment(props.eventData.watched_at).toDate());
        setPublishDay(props.today);

        const cross = props.eventData.cross;
        if (cross == null) return;

        const et = cross.cross_type === A.CROSS_TYPE.get("ET")!.no;
        setIsEt(et);

        const defaultEggType = cross.egg_type == null ? undefined : EGG_TYPES.find(e => e.egg_type === cross.egg_type)?.isSov ?? true;

        setData(d => ({
            ...d,
            label_no: cross.label_no_1 ?? "",
            father_name: cross.father_name ?? (et ? cross.seed_ancestor_1 : cross.seed_name) ?? "",
            isSov : et ? (d.isSov ?? defaultEggType) : undefined
        }))

    }, [ props.eventData, props.today ]);

    const onSubmit = () => {
        if (isEt) {
            const req:BreedingCertEtReq = {
                ...data,
                published_on: moment(publishDay).format("YYYY-MM-DD"),
                crossed_on: moment(crossDay).format("YYYY-MM-DD"),
                license_type: USER_LICENSE[data.license_type].certEtReq,
                egg_type: data.isSov ? BreedingCertEtReqEggTypeEnum.SOV : BreedingCertEtReqEggTypeEnum.OPUIVF
            };
            props.onEtSubmit(req);
            
        } else {
            const req:BreedingCertAiReq = {
                ...data,
                published_on: moment(publishDay).format("YYYY-MM-DD"),
                crossed_on: moment(crossDay).format("YYYY-MM-DD"),
                license_type: USER_LICENSE[data.license_type].certAiReq,
            };
            props.onAiSubmit(req);
        }
    }

    const ID = "cert-aiet-popup";

    return (
        <div>
            <Modal isOpen={true} id={ID}>
                <ModalHeader toggle={props.onClose}>{isEt ? "受精卵移植証明書" : "人工授精証明書"}</ModalHeader>
                <ModalBody>
                    <ModalInputBlock label="ID" contentStyle={{ margin: "8px 0"}}>
                        { RenderFullTraceId(props.traceId, {}, { fontSize:"1rem", padding:"0 1px" }) }
                    </ModalInputBlock>
                    { isEt && (
                        <ModalInputBlock label="種別">
                            <FormRadio prefix="radEggType"
                                value={data.isSov ? 1 : 0}
                                onChange={val => setData({ ...data, isSov: val === 1 })}
                                options={[ { name:"体内受精卵", value:1 }, { name:"体外受精卵", value:0 } ]}
                            />
                        </ModalInputBlock>
                    )}
                    <ModalInputBlock label="ラベル No.">
                        <input type="text" className="form-control" value={data.label_no} onChange={e => setData({ ...data, label_no:e.target.value })}
                            maxLength={LMT.SEED.STOCK_LABEL_LEN} />
                    </ModalInputBlock>
                    <ModalInputBlock label="種雄牛">
                        <input type="text" className="form-control" value={data.father_name} onChange={e => setData({ ...data, father_name:e.target.value })}
                            maxLength={LMT.COW.NAME_LEN} />
                    </ModalInputBlock>
                    <ModalInputBlock label="特徴">
                        <DataListInput
                            datalistId="cow_features"
                            value={data.cow_feature}
                            dataList={props.cowFeatureList}
                            onChange={val => setData({ ...data, cow_feature:val })}
                            maxLength={LMT.COW.FEATURE_LEN}
                        />
                    </ModalInputBlock>
                    <ModalInputBlock label={isEt ? "移植日" : "精液注入日"}>
                        <FormDatePicker portalContainerID={ID} value={crossDay} name="crossday" onChange={e => setCrossDay(e.target.value)} />
                    </ModalInputBlock>
                    <ModalInputBlock label="発行日">
                        <FormDatePicker portalContainerID={ID} value={publishDay} name="publishday" onChange={e => setPublishDay(e.target.value)} />
                    </ModalInputBlock>
                    <ModalInputBlock label="住所">
                        <DataListInput
                            datalistId="clinic-address-list"
                            dataList={props.addressList}
                            value={data.clinic_address}
                            onChange={val => setData({ ...data, clinic_address:val })}
                            maxLength={LMT.RANCH.ADDRESS_LEN}
                        />
                    </ModalInputBlock>
                    <ModalInputBlock label="診療所名">
                        <DataListInput
                            datalistId="clinic-name-list"
                            dataList={props.clinicNameList}
                            value={data.clinic_name}
                            onChange={val => setData({ ...data, clinic_name:val })}
                            maxLength={LMT.RANCH.NAME_LEN}
                        />
                    </ModalInputBlock>
                </ModalBody>
                <ModalFooter className="modal-footer-fix">
                    <ExecutionButton type="save" onClick={onSubmit}>発行</ExecutionButton>
                </ModalFooter>
            </Modal>
        </div>
    )

});