import React from 'react';
import classnames from 'classnames';

interface MyProps {
    onClick?:() => void;
    className?: string;
    style?: React.CSSProperties;
}

export const LoadMore = (props: MyProps) => (
    <div className={classnames("clickable", props.className)} onClick={props.onClick} style={props.style}>
        <i className="fas fa-angle-double-down"></i> さらに表示</div>
)