import React, { useState, useRef, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import settingStyles from '../setting/setting.module.css';
import classnames from 'classnames';
import { CommonUtil } from '../../config/util';
import { IconLink } from '../../components/parts/icon-link';
import { SortableList } from '../../components/parts/sortable-list';

interface MyProps<TItem> {
    items: Readonly<TItem[]>;
    mapNo: (item:TItem) => number;
    mapName: (item:TItem) => string;
    submit: (no: number | undefined, name: string) => Promise<boolean>;
    submitSort: (order: number[]) => Promise<boolean>;
    delete: (no: number) => Promise<boolean>;
    reloadList: () => Promise<void>;
    onClose: () => void;
    itemType: string;
    confirm: (text: string, buttons:string[]) => Promise<number|undefined>;
    maxNameLength: number;
}

export const SettingNameItemPopup = <TItem extends {}>(props: MyProps<TItem>) => {
    const [ editingNo, setEditingNo ] = useState<number>();
    const [ inputName, setInputName ] = useState<string>();
    const [ isExecuting, setIsExecuting ] = useState(false);
    const [ sortingList, setSortingList ] = useState<Readonly<TItem[]>>();

    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (inputRef.current != null) {
            inputRef.current.focus();
        }
    }, [ inputName, editingNo ]);


    const submitName = async () => {
        if (!CommonUtil.assertNotNull(inputName, "inputName", "submit")) return;

        setIsExecuting(true);
        try {
            const res = await props.submit(editingNo, inputName);
            if (res !== true) return;

            setEditingNo(undefined);
            setInputName(undefined);
            await props.reloadList();

        } finally {
            setIsExecuting(false);
        }
    }

    const submitSort = async () => {
        if (!CommonUtil.assertNotNull(sortingList)) return;

        //変更有無チェック
        const newOrder = sortingList.map(s => props.mapNo(s));
        const oldOrder = props.items.map(p => props.mapNo(p));
        if (JSON.stringify(newOrder) === JSON.stringify(oldOrder)) {
            setSortingList(undefined);
            return;
        }

        setIsExecuting(true);
        try {
            const res = await props.submitSort(newOrder);
            if (res !== true) return;

            setSortingList(undefined);
            await props.reloadList();

        } finally {
            setIsExecuting(false);
        }
    }

    const deleteItem = async () => {
        if (!CommonUtil.assertNotNull(editingNo, "editingNo", "delete")) return;
        const target = props.items.find(p => props.mapNo(p) === editingNo);
        if (!CommonUtil.assertNotNull(target)) return;

        const confirmed = await props.confirm(`${props.itemType} ${props.mapName(target)}を削除してよろしいですか？`, [ "OK", "キャンセル"]) === 0;
        if (!confirmed) return;

        setIsExecuting(true);
        try {
            const res = await props.delete(editingNo);
            if (res !== true) return;

            setEditingNo(undefined);
            setInputName(undefined);
            await props.reloadList();

        } finally {
            setIsExecuting(false);
        }
    }

    const styles: {[key:string]: React.CSSProperties } = {
        container: {
            height: "100%",
            display: "flex",
            flexFlow: "column nowrap",
        },
        header: {
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "4px"
        },
        listWrapper: {
            overflowY: "auto",
            flex: 1
        },
        list: {
            height: "100%",
            marginBottom: 0
        },
        listitem: {
            height: "55px",
            display: "flex",
            alignItems: "center"
        },
        cancel: {
            fontSize: "0.7rem"
        },
        name: {
            fontWeight: "bold",
            marginRight: "5px"
        },
        "icon-disabled": {
            color: "#aaa",
            cursor: "default",
            pointerEvents: "none"
        },
        editing: {
            display: "flex",
            width: "100%",
            paddingTop: "2px",
            alignItems: "center",
            justifyContent: "space-between"
        },
        "editing-name": {
            display: "flex"
        },
        "name-input": {
            height: "28px"
        },
        submit: {
            margin: "0 5px"
        }
    } as const;

    const renderItem = (item: TItem | undefined, sorting: boolean) => {
        //新規項目
        if (item == null) {
            if (editingNo != null || inputName == null) {
                return <></>
            }
            return (
                <li className={settingStyles["list-item"]} style={styles.listitem}>
                    { renderEditing(inputName) }
                </li>
            )
        }

        return (
            <li key={props.mapNo(item)} className={classnames(settingStyles["list-item"], { [settingStyles["sortable-helper"]]:sorting })}
                style={styles.listitem}>
                { (props.mapNo(item) === editingNo && inputName != null) ? (
                    renderEditing(inputName)
                ) : (<>
                    <div className={settingStyles["list-item-content"]}>
                        <span style={styles.name}>{props.mapName(item)}</span>
                        { !sorting && (
                            <i className="fas fa-sm fa-pen clickable"
                                style={isExecuting || inputName != null ? styles["icon-disabled"] : {}}
                                onClick={() => { setEditingNo(props.mapNo(item)); setInputName(props.mapName(item)); }} />
                        )}
                    </div>
                    { sorting && (
                        <div className={settingStyles["list-item-icon"]}><i className="fas fa-bars" /></div>
                    )}
                </>)}
            </li>
        )
    }

    const renderEditing = (name: string) => (
        <div style={styles.editing}>
            <div>
                <div style={styles["editing-name"]}>
                    <input type="text" className={"form-control"} style={styles["name-input"]} 
                        value={inputName} 
                        maxLength={props.maxNameLength}
                        onChange={e => setInputName(e.target.value)} 
                        ref={inputRef}
                    />
                    <button className="btn btn-green btn-sm text-nowrap"
                            style={styles.submit}
                            disabled={isExecuting || name.trim() === ""}
                            onClick={()=> submitName()}>確定</button>
                </div>
                <div style={styles.cansel}>
                    <span className="link" onClick={ () => { setEditingNo(undefined); setInputName(undefined) }}>キャンセル</span>
                </div>
            </div>
            <div>
                <button className="btn btn-danger text-nowrap"
                        disabled={isExecuting || editingNo == null}
                        onClick={() => deleteItem()}>削除</button>
            </div>
        </div>
    )

    return (
        <div>
            <Modal isOpen={true} centered={true}>
                <ModalHeader toggle={props.onClose}>{props.itemType}一覧</ModalHeader>
                <ModalBody style={{ height: "calc(100vh - 200px)" }}>
                    <div style={styles.container}>
                        <div style={styles.header}>
                            { sortingList == null ? (
                                <>
                                    <span />
                                    <div className={classnames("link", { "disabled": inputName != null } )}
                                        onClick={() => setSortingList(props.items)}>並び替え</div>
                                </>
                            ):(
                                <>
                                    <div className="link" onClick={() => setSortingList(undefined)}>キャンセル</div>
                                    <div className="link" onClick={() => submitSort()}>完了</div>
                                </>
                            )}
                        </div>
                        <div style={styles.listWrapper}>
                        { sortingList != null ? (
                            <SortableList className={settingStyles.list} style={styles.list}
                                isSorting={true}
                                items={sortingList}
                                onSorted={setSortingList}
                                listItem={p => renderItem(p, true)}
                            />
                        ) : (
                            <ul className={settingStyles.list} style={styles.list}>
                            { props.items.map(p => renderItem(p, false)) }
                            { renderItem(undefined, false) }
                            </ul>
                        )}
                        </div>

                        <div onClick={() => setInputName("")}>
                            <IconLink text={props.itemType + "を追加"} iconType="add" disabled={inputName != null || isExecuting} />
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}