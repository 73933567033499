import React from 'react';

import styles from './otherpl.module.css';
import { OtherPlContentProps, PlTypeSelector } from '.';
import classnames from 'classnames';
import { RequiredNumInput } from '../../components/parts/num-input';
import { ICowNameInfo } from '../../components/parts/cows-popup';
import { V3DateTime } from '../../components/parts/v3-date-time-picker';
import { LMT, TIMEPRESETS } from '../../config/constant';
import { SingleCowHeader } from 'components/cow-header/single-cow-header';

const RegisterCow: React.FC<OtherPlContentProps> = ({ data, onChange, user, ranchId }) => {

    if (data.details.length !== 1) return <></>;
    const tmpCow = data.details[0];
    if (tmpCow.cow_id == null || tmpCow.trace_id == null) return <></>;
    const cow: Readonly<ICowNameInfo> = { ...tmpCow, cow_id: tmpCow.cow_id, trace_id:tmpCow.trace_id };

    const rowClass = "form-group row treat-write-row";
    const rowHeaderClass = "col-form-label col-md-4 col-xs-4 text-lg-right";

    return (
        <div className="page-root">
            <div className="product product-full-height">
                <div className="product-detail" style={{ height: "100%" }}>
                    <div className="product-info product-info-fix p-b-0">
                        <div className={"product-info-header"}>
                            <SingleCowHeader ranchId={ranchId} cowId={cow.cow_id} />
                        </div>
                        <div className={styles["content-body"]}>
                            <div className={rowClass}>
                                <label className={rowHeaderClass}>記録日時</label>
                                <div className={styles["date-input"]}>
                                    <V3DateTime value={data.watchedAt}
                                        timePresets={user.setting?.time_preset ?? TIMEPRESETS}
                                        onChange={val => onChange({...data, watchedAt: val.toDate()})}
                                    />
                                </div>
                            </div>
                            <div className={rowClass}>
                                <label className={rowHeaderClass}>区分</label>
                                <PlTypeSelector value={data.profitLoss} onChange={v => onChange({ ...data, profitLoss:v })} />
                            </div>
                            <div className={rowClass}>
                                <label className={rowHeaderClass}>金額</label>
                                <RequiredNumInput className={styles["number-input"]}
                                    min={LMT.OTHER_PL.PRICE_MIN} max={LMT.OTHER_PL.PRICE_MAX}
                                    step={LMT.OTHER_PL.PRICE_STEP}
                                    value={data.details[0].price}
                                    onChange={v => onChange({...data, details: [{ ...data.details[0], price:v }]})}
                                />
                                <label className={classnames("col-form-label", styles["label-unit"])}>円</label>
                            </div>
                            <div className={rowClass}>
                                <label className={rowHeaderClass}>メモ</label>
                                <div style={{flex:1}}>
                                    <textarea className="form-control" rows={3} maxLength={LMT.OTHER_PL.MEMO_LEN} value={data.details[0].comment}
                                              onChange={e => onChange({ ...data, details: [{ ...data.details[0], comment: e.target.value }] })} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegisterCow