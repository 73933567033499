import React from 'react';
import { FreezedArray } from '../../config/util';
import moment from 'moment';
import { buildLocationName } from '../ranch/cow-list-table';
import { IRanchHouse } from '../../stores';

export interface ILocationHis {
    site:number;
    barn:number;
    room:number;
    start_day: Date;
    end_day: Date;
}

interface HisBoardProps {
    onDelete: () => void;
    histories: FreezedArray<ILocationHis>;
    sites: FreezedArray<IRanchHouse>;
}

export const LocationHistoryBoard = React.memo((props: HisBoardProps) => {

    const buildRow = (his: ILocationHis) => {
        const date = moment(his.start_day).format("YYYY/MM/DD");
        const location = buildLocationName(props.sites, his.site, his.barn, his.room);
        const days = moment(his.end_day).diff(his.start_day, "days");

        return `${date}～ ${location}（${days}日）`;
    }

    return (
        <div className="border rounded p-5 m-b-10" style={{ overflowY:"auto", height: "170px", color:"#666666" }}>
            {
                props.histories
                    .map((his, i) => ({ ...his, isLast: i === props.histories.length - 1 }))
                    .map((his, i) => (
                    <div key={i} className="m-b-5 m-t-5" data-testid="location-history"
                        style={ his.isLast ? {} : { borderBottom:"dotted 1px #ddd", paddingBottom:"2px" } }>
                        <span style={ his.isLast ? { fontWeight:"bold" } : {}} >{buildRow(his)}</span>
                        {
                            (props.histories.length > 1 && his.isLast) && (
                                <i className="fas fa-times clickable p-2 p-t-3" style={{ color: "red", fontSize:"0.875rem" }} onClick={props.onDelete} />
                            )
                        }
                    </div>
                ))
            }
        </div>
    )


});