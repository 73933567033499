import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { IGrowthSelectorData, GrowthSelector } from '../growth/growth-selector';
import { DgViewerProps } from '../sellcow/dg-viewer';
import { ExecutionButton } from '../../components/buttons/execution-button';

interface MyProps {
    isOpen: boolean;
    growth?: IGrowthSelectorData;
    onClose: () => void;
    onCommit: (g: IGrowthSelectorData) => void;
    dgSource?: Omit<DgViewerProps, "edScore">;
}

interface MyState {
    editingData: IGrowthSelectorData;
    anyInvalid: boolean;
}

export class GrowthInputPopup extends React.Component<MyProps, MyState> {

    constructor(props) {
        super(props);

        this.state = {
            editingData: this.props.growth ?? {},
            anyInvalid: false
        };

        this.onGrowthUpdated = this.onGrowthUpdated.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onCloseWithResult = this.onCloseWithResult.bind(this);
    }

    componentDidMount() {
    }

    onGrowthUpdated(data: IGrowthSelectorData) {
        this.setState({
            editingData: {
                ...this.state.editingData,
                ...data,
            }
        });
    }

    onClose() {
        this.props.onClose();
    }

    onCloseWithResult() {
        this.props.onCommit(this.state.editingData);
    }

    render() {
        let state = this.state;
        return (
            <div className="modal-wide">
                <Modal isOpen={this.props.isOpen} style={{ maxWidth: "1000px" }}>
                    <ModalHeader toggle={this.props.onClose} style={{ width: "100%" }}>身体測定値を入力</ModalHeader>
                    <ModalBody style={{ overflowY: "auto", maxHeight: "calc(100vh - 150px)" }}>
                        <GrowthSelector
                            data={state.editingData}
                            onChange={g => this.onGrowthUpdated(g)}
                            onAnyInvalidChange={v => this.setState({anyInvalid: v})}
                            dgSource={this.props.dgSource}
                        />
                    </ModalBody>
                    <ModalFooter className="modal-footer-fix">
                        <ExecutionButton type="decide" onClick={this.onCloseWithResult}
                            disabled={this.state.anyInvalid} />
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}