import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FormRadio } from '../../components/form/form-radio';
import { RanchTagDto } from '../../api';
import styles from './select-tags-popup.module.css';

export interface SelectTagsPopupProps {
    onClose:()=>void;
    onSubmit: (tags: TagCondition[]) => void;
    tags: Readonly<TagCondition>[];
    allTags: Readonly<Readonly<RanchTagDto>[]>;
}

export type TagCondition = {
    name: string;
    id: number;
    includes: boolean;
}

const MAX_COUNT = 3;

export const SelectTagsPopup: React.FC<SelectTagsPopupProps> = (props) => {
    const [ tags, setTags ] = useState<TagCondition[]>([]);

    useEffect(() => {
        setTags(props.tags);

    }, [ props.tags ]);
    
    const TagBlock = ({ tag, index } :{ tag: TagCondition | undefined, index: number }) => {

        const onChange = (val: number) => {
            if (val === 0) {
                setTags(preTags => preTags.filter((_,i) => i !== index));
            } else {
                const item = props.allTags.find(t => t.tag_id === val);
                if (item == null) return;
                setTags(preTags => {
                    return index === preTags.length
                            ? [...preTags, { id:val, name: item.tag_name, includes: true } ]
                            : preTags.map((p,i) => i === index ? ({ ...p, id: val, name: item.tag_name }) : p);
                });
            }
        }
        
        return (
            <div className={styles.block}>
                <select className="form-control" value={tag?.id ?? 0} onChange={e => onChange(Number(e.target.value))}>
                    <option value={0}>選択</option>
                    { props.allTags.map(t => <option key={t.tag_id} value={t.tag_id}>{t.tag_name}</option>)}
                </select>
                { tag != null && (
                    <div>
                        <div className={styles["s-text"]}>このタグの牛を</div>
                        <FormRadio
                            prefix={"includes_" + index}
                            options={[{ name:"含める", value: 1 }, { name:"除外する", value: 0 }]}
                            value={tag.includes ? 1 : 0 }
                            onChange={val => setTags(preTags => preTags.map((p,i) => i === index ? ({ ...p, includes: val === 1 }) : p))}
                        />
                    </div>
                )}
            </div>
        )
    }

    return (
        <div>
            <Modal isOpen={true} className="modal-slim">
                <ModalHeader toggle={props.onClose}>タグ条件選択</ModalHeader>
                <ModalBody style={{ overflowY: "auto", maxHeight: "calc(100vh - 180px)" }}>
                    { tags.map((t,i) => (
                        <TagBlock key={i} tag={t} index={i} />
                    ))}
                    { tags.length < MAX_COUNT && (
                        <TagBlock tag={undefined} index={tags.length} />
                    )}
                </ModalBody>
                <ModalFooter style={{ display:"flex", justifyContent:"center" }}>
                    <button className="btn btn-success"　onClick={() => props.onSubmit(tags)}>確定</button>
                </ModalFooter>
            </Modal>
        </div>
    )
}