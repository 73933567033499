import React, { useCallback } from 'react';
import { useCow, resetCow } from 'stores/fetcher_cow';
import { FetchWaiter, FetchError } from 'components/content/fetch-state';
import styles from './cow-header.module.css';
import { WatchingIcon } from 'pages/cow/watching-icon';
import { useCommunicator } from 'api/communicator';
import { usePageStore } from 'config/page-settings';
import { AppState } from 'app';
import { CowToDispInfo, ICowNameInfo } from 'components/parts/cows-popup';
import { A } from 'config/constant';
import classnames from 'classnames';
import { CowDetailDto } from 'api';

interface Props {
    ranchId: number;
    cowId: number;
    noWatching?: boolean;
}

export const SingleCowHeader = React.memo((props: Props) => {
    const { data, isError, isLoading } = useCow(props.ranchId, props.cowId);
    const comm = useCommunicator();
    const context = usePageStore() as AppState;

    const onWatchingUpdated = useCallback(() => {
        //引数で更新後の値が渡ってくるが、無視してシンプルに再取得しておく
        resetCow(props.cowId, false);
    }, [ props.cowId ]);

    if (isLoading) return <FetchWaiter />
    if (isError || data == null) return <FetchError />

    return (
        <div className={classnames(styles.container, styles.single)}>
            { props.noWatching ? (
                <span />
            ) : (
                <WatchingIcon
                    className={styles.watching}
                    watchingInfo={data}
                    showAlert={context.showAlert}
                    onUpdated={onWatchingUpdated}
                    comm={comm}
                />
            )}
            <SingleCowHeaderTitle cow={data} />
            <SingleCowHeaderDetail cow={data} />
        </div>
    )

});

export const SingleCowHeaderTitle = React.memo(({ cow }: { cow: ICowNameInfo }) => {
    return (
        <div className={styles.title}>
            <span className={styles.repno}>{CowToDispInfo(cow, false)}</span>
            <span className={styles.name}>{cow.name ?? ""}</span>
        </div>
    )
});

export const SingleCowHeaderDetail = React.memo(({ cow }: { cow: CowDetailDto }) => {
    return (
        <div className={styles.detail}>
            { cow.trace_id.length === 10 ? (<>
                <span className={styles.trace}>{cow.trace_id.substr(0,5)}</span>
                <span className={classnames(styles.trace, styles.four)}>{cow.trace_id.substr(5,4)}</span>
                <span className={styles.trace}>{cow.trace_id.substr(9)}</span>
            </>) : (
                <span className={styles.trace}>{cow.trace_id}</span>
            )}
            <span className={styles.sex}>{A.GET_SEX_MARK(cow.is_male)}</span>
        </div>
    )
});