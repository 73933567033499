import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { A } from '../../config/constant';
import { usePageStore } from '../../config/page-settings.js';
import { CommonUtil, FreezedArray } from '../../config/util';
import styles from './report-sensor.module.css';
import repoStyles from '../report-sellcow/report-sellcow.module.css';
import { useRootStore } from '../../stores';
import { AppState } from '../../app';
import { useAccessCheck } from 'config/access-checker';
import { useCommunicator } from 'api/communicator';
import { ICowNameInfo, CowToDispInfo } from 'components/parts/cows-popup';
import moment from 'moment';
import { RanchContractItemKey } from 'config/contract-checker';
import { SensorHistoryGroupDto, SensorHistoryGroupReq, SensorApi } from 'api';
import { FormRadio } from 'components/form/form-radio';
import { SensorHistoryView } from './sensor-history-view';
import { SensorCowView } from './sensor-cow-view';
import { resetSensorCow, resetSensorHistory } from 'stores/fetcher_sensor';
import { CommonSelect } from 'components/parts/common-select';

type ReportKind = "history"|"cow";

const COW_DAYS_PER_PAGE = 14;
const HIS_SEARCH_DAYS = 31;

const ReportKindSelector = (props: {
    kind: ReportKind,
    onChange: (kind:ReportKind) => void
}) => {
    return (
        <FormRadio
            options={[ { name:"計測履歴", value:1 }, { name:"個体値と群平均", value:2 }]}
            onChange={val => props.onChange(val === 1 ? "history" : "cow")}
            value={props.kind === "history" ? 1 : 2}
        />
    )
}


interface HisCondition {
    reportKind:"history",
    submitId:number,
}
interface CowCondition {
    reportKind:"cow",
    cow: ICowNameInfo,
    from:Date,
    to:Date,
}
type Condition = HisCondition | CowCondition;

export default () => {
    const context = usePageStore() as AppState;
    const { cur_ranch_id } = useRootStore();
    const rootStore = useRootStore();
    const { isUnallowedAccess } = useAccessCheck();
    const comm = useCommunicator();

    const [ reportKind, setReportKind ] = useState<ReportKind>("history");
    const [ selectedHisSubmitId, setSelectedHisSubmitId ] = useState<number>();
    const [ selectedCow, setSelectedCow ] = useState<ICowNameInfo>();
    const [ submittedCondition, setSubmittedConditon ] = useState<Condition>();
    const [ activeCows, setActiveCows ] = useState<FreezedArray<ICowNameInfo>>([]);
    const [ hisGroups, setHisGroups ] = useState<SensorHistoryGroupDto[]>([]);

    const canLoadData = useMemo(() => {
        return (reportKind === "history" && selectedHisSubmitId != null)
            || (reportKind === "cow" && selectedCow != null);

    }, [ reportKind, selectedCow, selectedHisSubmitId ])

    useEffect(() => {
        //※現時点では、契約チェックなしとする
        const contracts: RanchContractItemKey[] = [ /* "THERMOTELL" */];
        if (isUnallowedAccess(undefined, ["BROWSE_INSIDE"], [], contracts)) return;

        context.handleSetHeader({ title:"チップ計測値表示" });
        context.handleSetPageError(false);
        context.handleSetFooter(true);

        init();

    }, [ cur_ranch_id ]);

    const selectableCows = useMemo(() => {
        if (selectedCow == null) return activeCows;
        if (activeCows.some(c => c.cow_id === selectedCow.cow_id)) return activeCows;

        //計測履歴で選択された牛が飼養中でない場合、リストに含まれないので、追加しておく
        return [ ...activeCows, selectedCow ];

    }, [ activeCows, selectedCow ])

    const onCowNext = useCallback(() => {
        if (submittedCondition?.reportKind !== "cow") return;

        const newFrom = moment(submittedCondition.to).add(1, "days").toDate()

        setSubmittedConditon({
            ...submittedCondition,
            from: newFrom,
            to: moment(newFrom).add(COW_DAYS_PER_PAGE - 1, "days").toDate()
        });

    }, [ submittedCondition ])

    const onCowPrev = useCallback(() => {
        if (submittedCondition?.reportKind !== "cow") return;

        const newTo = moment(submittedCondition.from).subtract(1, "days").toDate();

        setSubmittedConditon({
            ...submittedCondition,
            to: newTo,
            from: moment(newTo).subtract(COW_DAYS_PER_PAGE -1 , "days").toDate()
        });

    }, [ submittedCondition ])

    const onCowSelectInHisView = useCallback((cow: ICowNameInfo) => {
        setReportKind("cow");

        setSelectedCow(cow);

        const today = moment().startOf("day");
        setSubmittedConditon({
            reportKind:"cow",
            cow,
            from: moment(today).subtract(COW_DAYS_PER_PAGE - 1, "days").toDate(),
            to: today.toDate(),
        });
    }, [])




    const init = async () => {
        context.handleSetIsLoading(true);

        try {
            const cows = await loadActiveCows();
            if (cows == null) return;
            setActiveCows(cows);
            if (cows.length > 0) {
                setSelectedCow(cows[0]);
            }

            const grps = await loadHisGroups();
            if (grps == null) return;
            setHisGroups(grps);
            if (grps.length > 0) {
                setSelectedHisSubmitId(grps[0].submit_id);
            }

        } finally {
            context.handleSetIsLoading(false);
        }
    }

    const reloadCows = async () => {
        context.handleSetIsLoading(true);
        try {
            const cows = await loadActiveCows();
            if (cows != null) {
                setActiveCows(cows);

                //※もともと選択されていた牛が新しいリストに含まれない場合、selectableCowsを作るところで追加される
            }
        } finally {
            context.handleSetIsLoading(false);
        }
    }

    const reloadHisGroups = async () => {
        context.handleSetIsLoading(true);
        try {
            const grps = await loadHisGroups();
            if (grps != null) {
                setHisGroups(grps);

                if (selectedHisSubmitId != null) {
                    if (!grps.some(g => g.submit_id === selectedHisSubmitId)) {
                        setSelectedHisSubmitId(undefined);
                    }
                }
            }
        } finally {
            context.handleSetIsLoading(false);
        }
    }

    const loadActiveCows = async () => {
        try {
            const res = await rootStore.fetchActiveCows(cur_ranch_id, "DIFF");
            if (res === "NG") {
                context.showToast(A.MESSAGE.FAILED_TO_LOAD_DATA);
                return undefined;
            }
    
        } catch(err) {
            console.error(err);
            context.showToast(A.MESSAGE.FAILED_TO_LOAD_DATA);
            return undefined;
        }
        return rootStore.getActiveCows(cur_ranch_id);
    }

    const loadHisGroups = async () => {
        const now = moment();
        const grpReq: SensorHistoryGroupReq = {
            ranch_id:cur_ranch_id,
            from: moment(now).subtract(HIS_SEARCH_DAYS - 1, "days").format("YYYY-MM-DD"),
            to: now.format("YYYY-MM-DD"),
        };
        const grpRes = await comm.send((await SensorApi()).getSensorHistoryGroupsUsingPOST(grpReq));
        if (grpRes.result !== "OK" || grpRes.data == null) return undefined;

        return grpRes.data;
    }


    const loadData = () => {
        if (reportKind === "history" && CommonUtil.assertNotNull(selectedHisSubmitId, "submitId")) {

            //※ staleTimeを0にしても、直前の呼び出しとkeyが変わらないときはuseQueryのfetchが走らないようなので明示的にinvalidate
            resetSensorHistory(selectedHisSubmitId, false);

            setSubmittedConditon({
                reportKind:"history",
                submitId: selectedHisSubmitId
            });
            return;
        }
        if (reportKind === "cow" && CommonUtil.assertNotNull(selectedCow, "selectedCow")) {
            const today = moment().startOf("day");

            const from = moment(today).subtract(COW_DAYS_PER_PAGE - 1, "days").toDate();
            const to = today.toDate();

            //※ staleTimeを0にしても、直前の呼び出しとkeyが変わらないときはuseQueryのfetchが走らないようなので明示的にinvalidate
            resetSensorCow(selectedCow.cow_id, from, to, false);

            setSubmittedConditon({
                reportKind:"cow",
                cow:selectedCow,
                from,
                to,
            });
        }
    }


    return (
        <div className="page-root">
            <div className="product product-full-height">
                <div className="product-detail" style={{ height: "100%" }}>
                    <div className="product-info product-info-fix">

                        <div className={styles.conditions}>
                            <div className={styles["condition-row"]}>
                                <ReportKindSelector kind={reportKind} onChange={setReportKind} />
                            </div>
                            { reportKind === "history" && (
                                <div className={styles["condition-row"]}>
                                    <div className={styles.label}>日時</div>
                                    <div className={styles.select}>
                                        <select className="form-control" value={selectedHisSubmitId}
                                                onChange={e => setSelectedHisSubmitId(Number(e.target.value)) }>
                                            { hisGroups.map(h => (
                                                <option key={h.submit_id} value={h.submit_id}>{ moment(h.grouped_at).format("M/D HH:mm")} {h.site_name ?? ""} {h.barn_name ?? ""}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <button className="btn btn-warning btn-xs m-l-10" onClick={reloadHisGroups}>更新<i className="fas fa-redo m-l-5" /></button>
                                </div>
                            )}
                            { reportKind === "cow" && (
                                <div className={styles["condition-row"]}>
                                    <div className={styles.label}>牛</div>
                                    <div className={styles.select}>
                                        <CommonSelect value={selectedCow?.cow_id}
                                            options={selectableCows.map(c => ({ value:c.cow_id, label:CowToDispInfo(c, true) }))}
                                            onSelect={val => setSelectedCow(selectableCows.find(c => c.cow_id === val))}
                                            onCreate={undefined}
                                        />
                                    </div>
                                    <button className="btn btn-warning btn-xs m-l-10" onClick={reloadCows}>更新<i className="fas fa-redo m-l-5" /></button>
                                </div>
                            )}
                        </div>

                        <div className={repoStyles["load-button-row"]}>
                            <button className="btn btn-green" onClick={loadData} disabled={!canLoadData}>
                                <i className="far fa-arrow-alt-circle-down"></i>
                                <span> 再集計</span>
                            </button>
                        </div>

                        { submittedCondition?.reportKind === "history" && (
                            <SensorHistoryView
                                ranchId={cur_ranch_id}
                                submitId={submittedCondition.submitId}
                                onCowSelect={onCowSelectInHisView}
                            />
                        )}
                        { submittedCondition?.reportKind === "cow" && (
                            <SensorCowView
                                ranchId={cur_ranch_id}
                                cow={submittedCondition.cow}
                                from={submittedCondition.from}
                                to={submittedCondition.to}
                                onNext={onCowNext}
                                onPrev={onCowPrev}
                            />
                        )}

                    </div>
                </div>
            </div>
        </div>
    )

}
