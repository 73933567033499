import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FormRadio } from '../../components/form/form-radio';
import { PageSettings } from '../../config/page-settings.js';
import { SelectRanchHousePopup } from './select-ranch-house-popup';
import { A, LMT, BREEDING_STATES, BREEDING_STATE } from '../../config/constant';
import moment from "moment";
import { ICowSearchCondition, IRanchHouse, ICowUse, ICowBreed, ISelectedSite, defaultCowListCondition, IUser } from '../../stores';
import { FormPage } from '../../components/form/form-page';
import { RequiredNumInput } from '../../components/parts/num-input';
import { SelectOutputPopup, ICowListOutput, buildDefaultOutputs, outputsToDispText, removeUnauthorizedOutput, outputToRequired } from './select-output-popup';
import { SelectListPopup } from './select-list-popup';
import { SaveListPopup } from './save-list-popup';
import { CowFilterItemDto, CowFilterModifyReq, CowApi, RanchTagDto, ProgramDto, CowSearchReqEvent, CowSearchReqSchedule, CowFilterSortReq } from '../../api';
import { AppState } from '../../app';
import { Communicator } from '../../api/communicator';
import { CheckboxPopup } from '../../components/parts/checkbox-popup';
import { PROGRAM_TRIGGER_KIND } from '../../config/program-kind';
import { EventConditionSelector, calcDateFromBeforeDays, calcBeforeDaysFromDate } from './event-condition-selector';
import styles from './cow-search-popup.module.css';
import { hasRanchContract } from '../../config/contract-checker';
import { hasRanchAuth } from '../../config/auth-checker';
import { OptionalTermSelector } from '../../components/parts/optional-term-selector';
import { calcAfterDaysFromDate, calcDateFromAfterDays, ScheduleConditionSelector } from './schedule-condition-selector';
import { getRanchHouses } from '../../stores/fetcher';
import { FetchWaiter, FetchError } from '../../components/content/fetch-state';
import { DIALOG_BUTTONS } from 'components/form/form-dialog';
import { resetCowFilter } from 'stores/fetcher_cow';

export interface CowSearchPopupProps {
    condition: Readonly<ICowSearchCondition>;
    outputs: Readonly<ICowListOutput>;
    onClose: () => void;
    isOpen: boolean;
    cow_breed: ICowBreed[];
    cow_use: ICowUse[];
    onCloseWithResult:(condition:ICowSearchCondition, outputs: ICowListOutput) => void;
    onReset: () => void;
    resetButton: string;
    comm: Communicator;
    ranch_id: number;
    user: IUser;
    tags: Readonly<RanchTagDto[]>;
    programs: Readonly<ProgramDto[]>
}

interface MyState {
    popup_breed_open: boolean;
    popup_use_open: boolean;
    popup_ranch_house_open: boolean;
    popup_breeding_state_open: boolean;
    popup_tag_open: boolean;
    popup_output_open: boolean;
    popup_savelist_open: boolean;
    popup_program_open: boolean;

    condition: Readonly<ICowSearchCondition>;
    outputs: Readonly<ICowListOutput>;
    selectedListItem?: { filter_no: number, name: string };
    executing: boolean;
    isSelectListShown: boolean;

    ranchHouses: Readonly<IRanchHouse[]>;
    initStatus: "ready"|"loading"|"error";
}

type ICowSearchConditionJson = Omit<ICowSearchCondition, "events"|"schedules"|"hasDayAge"|"day_age_from"|"day_age_to"> & {
    events: CowSearchReqEventJson[] | undefined;
    schedules: CowSearchReqScheduleJson[] | undefined;
    day_age_from: number | undefined;
    day_age_to: number | undefined;
}
type CowSearchReqEventJson = Omit<CowSearchReqEvent, "date_from"|"date_to"> & {
    before_days_from: number;
    before_days_to: number;
}
type CowSearchReqScheduleJson = Omit<CowSearchReqSchedule, "date_from"|"date_to"> & {
    after_days_from: number;
    after_days_to: number;
}

export class CowSearchPopup extends FormPage<CowSearchPopupProps, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props: CowSearchPopupProps) {
        super(props);

        this.state = {
            popup_breed_open: false,
            popup_use_open: false,
            popup_breeding_state_open: false,
            popup_output_open: false,
            popup_ranch_house_open: false,
            popup_savelist_open: false,
            popup_tag_open: false,
            popup_program_open: false,

            condition: props.condition,
            outputs: props.outputs,
            executing: false,
            isSelectListShown:false,

            ranchHouses:[],
            initStatus:"loading"
        }

        this.onCloseWithResult = this.onCloseWithResult.bind(this);
        this.onConditionFormat = this.onConditionFormat.bind(this);
        this.handleTermChange = this.handleTermChange.bind(this);
        this.onSaveList = this.onSaveList.bind(this);
        this.showSelectListPopup = this.showSelectListPopup.bind(this);
        this.showBreedingStates = this.showBreedingStates.bind(this);
        this.showTags = this.showTags.bind(this);
        this.showPrograms = this.showPrograms.bind(this);
        this.onListSelected = this.onListSelected.bind(this);
        this.onListDeleted = this.onListDeleted.bind(this);
        this.onListSort = this.onListSort.bind(this);
    }

    componentDidMount() {
        this.init();
    }
    async init() {
        const houses = await getRanchHouses(this.props.ranch_id);
        this.setState({
            ranchHouses:houses ?? [],
            initStatus: houses == null ? "error" : "ready"
        })
    }

    handleTermChange(st: Date | undefined, ed: Date | undefined) {
        this.setState({
            condition: {
                ...this.state.condition,
                period_start: st == null ? undefined : moment(st).format("YYYY-MM-DD"),
                period_end: ed == null ? undefined : moment(ed).format("YYYY-MM-DD")
            }
        });
    }

    onCloseWithResult() {
        const cond = this.state.condition;
        if (cond.is_period === 1) {
            const pSt = cond.period_start;
            const pEd = cond.period_end;
            if (pSt == null && pEd == null) {
                this.context.showToast(A.MESSAGE.PERIOD_UNSELECTED);
                return;
            }
        }

        const result = { ...cond };
        this.props.onCloseWithResult(result, this.state.outputs);
    }

    onConditionFormat() {
        this.props.onCloseWithResult(defaultCowListCondition(), buildDefaultOutputs());
        this.props.onReset();
    }

    onBreedSelected(b: number[]) {
        this.setState({
            condition: {
                ...this.state.condition,
                breeds: b
            },
            popup_breed_open: false
        });
    }

    onRanchHouseSelected(b: ISelectedSite[]) {
        this.setState({
            popup_ranch_house_open: false,
            condition: {
                ...this.state.condition,
                selected_house: b,
                is_period: b.length === 0 ? 0 : this.state.condition.is_period,
                period_start: b.length === 0 ? undefined : this.state.condition.period_start,
                period_end: b.length === 0 ? undefined : this.state.condition.period_end
            }
        });
    }

    onUseSelected(u: number[]) {
        this.setState({
            condition: {
                ...this.state.condition,
                uses: u
            },
            popup_use_open: false
        });
    }

    onBreedingStateSelected(b: number[]) {
        this.setState({
            condition: {
                ...this.state.condition,
                breeding_states: b
            },
            popup_breeding_state_open: false
        })
    }

    onTagSelected(t: number[]) {
        this.setState({
            condition: {
                ...this.state.condition,
                tags: t
            },
            popup_tag_open: false,
        })
    }

    onProgramSelected(p: number[]) {
        this.setState({
            condition: {
                ...this.state.condition,
                programs: p
            },
            popup_program_open: false
        })
    }

    onAgeFromChange(age_from: number) {
        //逆転を補正
        const age_to = age_from <= this.state.condition.age_to ? this.state.condition.age_to : age_from;
        this.setState({
            condition: {
                ...this.state.condition,
                age_from,
                age_to
            }
        })
    }
    onAgeToChange(age_to: number) {
        //逆転を補正
        const age_from = this.state.condition.age_from <= age_to ? this.state.condition.age_from : age_to;
        this.setState({
            condition: {
                ...this.state.condition,
                age_from,
                age_to
            }
        })
    }
    onDayAgeFromChange(age_from: number) {
        //逆転を補正
        const age_to = age_from <= this.state.condition.day_age_to ? this.state.condition.day_age_to : age_from;
        this.setState({
            condition: {
                ...this.state.condition,
                day_age_from: age_from,
                day_age_to: age_to
            }
        })
    }
    onDayAgeToChange(age_to: number) {
        //逆転を補正
        const age_from = this.state.condition.day_age_from <= age_to ? this.state.condition.day_age_from : age_to;
        this.setState({
            condition: {
                ...this.state.condition,
                day_age_from: age_from,
                day_age_to: age_to
            }
        })
    }

    showUseTypes() {
        return this.props.cow_use
                    .filter(u => this.state.condition.uses.includes(u.use_no))
                    .map(u => u.name)
                    .join(", ");
    }

    showBreedTypes() {
        return this.props.cow_breed
                    .filter(b => this.state.condition.breeds.includes(b.breed_no))
                    .map(b => b.name)
                    .join(", ");
    }

    showBreedingStates() {
        const states = this.state.condition.breeding_states ?? [];
        return BREEDING_STATES.filter(k => states.includes(BREEDING_STATE[k].no))
                    .join(", ");
    }

    showTags() {
        const tags = this.state.condition.tags ?? [];
        //※削除済みのタグは空文字で表示（タグを選択しなおした時点で削除される）
        return tags.map(t => this.props.tags.find(pt => pt.tag_id === t)?.tag_name ?? " ")
                .sort()
                .join(", ");
    }

    showPrograms() {
        const programs = this.state.condition.programs ?? [];
        //※削除済みのプログラムは空文字で表示（プログラムを選択しなおした時点で削除される）
        return programs.map(p => this.props.programs.find(pp => pp.program_id === p)?.name ?? " ")
                .join(", ");
    }

    showOutputs() {
        return outputsToDispText(this.state.outputs);
    }

    showRanchHouses(site: ISelectedSite) {
        if (site.no === 0) return "分場未設定";

        const sInfo = this.state.ranchHouses.find(h => h.no === site.no);
        const sName = sInfo?.name ?? "";
        
        if (site.isAllSelected) {
            return `${sName}: すべて`;
        }
        return sName + ": "
            + site.data.map(b => {
                if (b.no === 0) return "牛舎未設定";

                const bInfo = sInfo?.data?.find(d => d.no === b.no);
                const bName = bInfo?.name ?? "";
                if (b.isAllSelected) {
                    return `${bName}（すべて）`;
                }
                const rNames = b.data.map(r => r.no === 0 ? "部屋未設定" : bInfo?.data?.find(d => d.no === r.no)?.name ?? "").join(", ");
                return `${bName}（${rNames}）`;
            })
    }

    async showSelectListPopup() {
        this.setState({
            isSelectListShown:true
        });
    }

    onEventSelected(idx: number, values: CowSearchReqEvent | undefined) {
        const preEvents = this.state.condition.events ?? [];

        let events: CowSearchReqEvent[];
        if (values == null) {
            events = preEvents.filter((_,i) => i !== idx);

        } else {
            events = [
                ...preEvents.slice(0, idx),
                values,
                ...preEvents.slice(idx + 1)
            ];
        }

        this.setState({
            condition: {
                ...this.state.condition,
                events
            }
        })
    }
    onScheduleSelected(idx: number, values: CowSearchReqSchedule | undefined) {
        const preSchedules = this.state.condition.schedules ?? [];

        let schedules: CowSearchReqSchedule[];
        if (values == null) {
            schedules = preSchedules.filter((_,i) => i !== idx);
        
        } else {
            schedules = [
                ...preSchedules.slice(0, idx),
                values,
                ...preSchedules.slice(idx + 1)
            ];
        }

        this.setState({
            condition: {
                ...this.state.condition,
                schedules
            }
        })
    }

    async onListSelected(filter: CowFilterItemDto) {

        const today = moment().startOf("day");

        const condJson = JSON.parse(filter.conditions) as ICowSearchConditionJson;
        //条件の保存形式→検索APIリクエスト形式
        const cond: ICowSearchCondition = {
            ...condJson,
            hasDayAge : condJson.day_age_from != null && condJson.day_age_to != null,
            day_age_from: condJson.day_age_from ?? 0,
            day_age_to: condJson.day_age_to ?? 0,
            events: condJson.events?.map(e => ({
                event_kind: e.event_kind,
                excluded_event_kind: e.excluded_event_kind,
                //※本番リリース前だが、before_days_from, before_days_toがない形式だった時期があるので
                //  undefinedの場合もエラーにだけならないよう簡易的に対処
                date_from: calcDateFromBeforeDays(today, e.before_days_from ?? 0),
                date_to: calcDateFromBeforeDays(today, e.before_days_to ?? 0),
                only_treat: e.only_treat
            })),
            schedules: condJson.schedules?.map(s => ({
                event_kind: s.event_kind,
                date_from: calcDateFromAfterDays(today, s.after_days_from),
                date_to: calcDateFromAfterDays(today, s.after_days_to),
                status: s.status
            })),
        }
        const outputs = outputToRequired(JSON.parse(filter.outputs) as Partial<ICowListOutput>);

        const hasBreedingContract = hasRanchContract("BREEDING", this.props.ranch_id, this.props.user);
        const hasCowBalanceAuth = hasRanchAuth("BALANCE_COW", this.props.ranch_id, this.props.user);

        this.setState({
            condition: cond,
            outputs: removeUnauthorizedOutput(outputs, hasBreedingContract, hasCowBalanceAuth),
            isSelectListShown: false,
            selectedListItem: filter
        });
    }
    async onListDeleted(filterNo: number) {
        const confirmed = await this.context.showDialog("QUESTION", "この絞り込み条件を削除してよろしいですか？", DIALOG_BUTTONS.DELETE_CANCEL) === 0;
        if (!confirmed) return;

        this.setState({ executing: true });
        this.context.handleSetIsLoading(true);

        const req = { ranch_id: this.props.ranch_id, id: filterNo };
        const res = await this.props.comm.send((await CowApi()).deleteFilterUsingPOST(req));
        if (res.result !== "OK") return;

        await resetCowFilter(this.props.ranch_id, false);

        this.context.handleSetIsLoading(false);
        this.setState({
            executing: false,
        });
    }

    async onListSort(order:number[]) {
        const req: CowFilterSortReq = {
            ranch_id: this.props.ranch_id,
            order 
        };

        this.setState({ executing: true });
        this.context.handleSetIsLoading(true);

        const res = await this.props.comm.send((await CowApi()).sortFilterUsingPOST(req));
        if (res.result !== "OK") return false;

        await resetCowFilter(this.props.ranch_id, false);

        this.context.handleSetIsLoading(false);
        this.setState({
            executing: false,
        });

        return true;
    }

    async onSaveList(no: number | undefined, name: string) {
        this.setState({ executing: true });
        this.context.handleSetIsLoading(true);

        const today = moment().startOf("day");
        //検索APIリクエスト形式→条件の保存形式
        const cond = this.state.condition;
        const condJson: ICowSearchConditionJson = {
            ... { ...cond, hasDayAge:undefined },
            day_age_from: cond.hasDayAge ? cond.day_age_from : undefined,
            day_age_to: cond.hasDayAge ? cond.day_age_to : undefined,
            events: cond.events?.map(e => ({
                event_kind: e.event_kind,
                excluded_event_kind: e.excluded_event_kind,
                before_days_from: calcBeforeDaysFromDate(today, moment(e.date_from)),
                before_days_to: calcBeforeDaysFromDate(today, moment(e.date_to)),
                only_treat: e.only_treat
            })),
            schedules: cond.schedules?.map(s => ({
                event_kind: s.event_kind,
                after_days_from: calcAfterDaysFromDate(today, moment(s.date_from)),
                after_days_to: calcAfterDaysFromDate(today, moment(s.date_to)),
                status: s.status
            }))
        }

        const req: CowFilterModifyReq = {
            name,
            filter_no: no,
            is_new: no == null ? 1 : 0,
            conditions: JSON.stringify(condJson),
            outputs: JSON.stringify(this.state.outputs),
            ranch_id: this.props.ranch_id
        };
        const res = await this.props.comm.send((await CowApi()).modifyFilterUsingPOST(req));
        this.context.handleSetIsLoading(false);

        resetCowFilter(this.props.ranch_id, false);

        this.setState({
            executing: false,
            popup_savelist_open: res.result !== "OK",
            selectedListItem: res.data == null ? undefined : { name, filter_no: no ?? res.data.id }
        });
    }

    render() {

        const ID = "cow-search-popup";

        const hasBreedingContract = hasRanchContract("BREEDING", this.props.ranch_id, this.props.user);
        const hasCowBalanceAuth = hasRanchAuth("BALANCE_COW", this.props.ranch_id, this.props.user);

        const state = this.state;
        return (
            <div>
                <Modal isOpen={this.props.isOpen} style={{ maxWidth: "1000px" }} id={ID} scrollable={true}>
                    <ModalHeader toggle={this.props.onClose}>絞り込み条件</ModalHeader>
                    { state.initStatus === "loading" ? (
                        <FetchWaiter />
                    ) : state.initStatus === "error" ? (
                        <FetchError />
                    ) : (<>
                        <ModalBody>
                            <div>
                                <div style={{ textAlign:"right" }}>
                                    <span className="link" onClick={this.showSelectListPopup}>条件リスト呼び出し</span>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-xs-3 col-md-4 text-lg-right">性別</label>
                                    <div className="col-md-4 col-xs-9" data-testid="sex">
                                        <div className="radio radio-css" style={{ width: "100%" }}>
                                            <input type="radio" id="chkSex0"
                                                onChange={() => this.setState({ condition: {...this.state.condition, is_male: undefined }})}
                                                checked={state.condition.is_male == null} />
                                            <label htmlFor="chkSex0">指定なし</label>
                                        </div>
                                        <FormRadio options={[{ name:"オス", value: 1 }, { name:"メス", value: 0 }]}
                                            value={state.condition.is_male}
                                            prefix="radsex_"
                                            onChange={val => this.setState({ condition: { ...this.state.condition, is_male: val === 1 ? 1 : 0 }})} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-md-4 col-xs-3 text-lg-right">月齢</label>
                                    <div className="col-md-4 col-xs-9" data-testid="age">
                                        <div className="radio radio-css" style={{ width: "100%" }}>
                                            <input type="radio" name="radio_age" id="radioAge0" value="0"
                                                onChange={(e) => this.onNumValueChange(e, 'condition.is_age')}
                                                checked={state.condition.is_age === 0} />
                                            <label htmlFor="radioAge0">指定なし</label>
                                        </div>
                                        <div style={{ display:"flex" }}>
                                            <div className="radio radio-css m-t-10">
                                                <input type="radio" name="radio_age" id="radioAge1" value="1"
                                                    onChange={(e) => this.onNumValueChange(e, 'condition.is_age')}
                                                    checked={state.condition.is_age === 1} />
                                                <label htmlFor="radioAge1">&nbsp;</label>
                                            </div>
                                            <div className="m-t-5" style={{ display: "inline" }}>
                                                <RequiredNumInput
                                                    min={LMT.COW.AGE_M_MIN} max={LMT.COW.AGE_M_MAX} step={LMT.COW.AGE_M_STEP}
                                                    style={{ display: "inline", width: "64px" }}
                                                    disabled={state.condition.is_age !== 1}
                                                    value={state.condition.age_from}
                                                    onChange={val => this.onAgeFromChange(val)}
                                                />
                                                <span style={{ lineHeight: "37px" }}>ヵ月 ～ </span>
                                                <RequiredNumInput 
                                                    min={LMT.COW.AGE_M_MIN} max={LMT.COW.AGE_M_MAX} step={LMT.COW.AGE_M_STEP}
                                                    style={{ display: "inline", width: "64px" }}
                                                    value={state.condition.age_to}
                                                    disabled={state.condition.is_age !== 1}
                                                    onChange={val => this.onAgeToChange(val)}
                                                />
                                                <span style={{ lineHeight: "37px" }}>ヵ月</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-md-4 col-xs-3 text-lg-right">日齢</label>
                                    <div className="col-md-4 col-xs-9" data-testid="day-age">
                                        <div className="radio radio-css" style={{ width: "100%" }}>
                                            <input type="radio" id="radioDayAge0"
                                                onChange={() => this.setState({
                                                    condition: {
                                                        ...this.state.condition,
                                                        hasDayAge: false
                                                    }
                                                })}
                                                checked={!state.condition.hasDayAge} />
                                            <label htmlFor="radioDayAge0">指定なし</label>
                                        </div>
                                        <div style={{ display:"flex" }}>
                                            <div className="radio radio-css m-t-10">
                                                <input type="radio" id="radioDayAge1"
                                                    onChange={() => this.setState({
                                                        condition: {
                                                            ...this.state.condition,
                                                            hasDayAge: true
                                                        }
                                                    })}
                                                    checked={state.condition.hasDayAge} />
                                                <label htmlFor="radioDayAge1">&nbsp;</label>
                                            </div>
                                            <div className="m-t-5" style={{ display: "inline" }}>
                                                <RequiredNumInput
                                                    min={LMT.COW.AGE_D_MIN} max={LMT.COW.AGE_D_MAX} step={LMT.COW.AGE_D_STEP}
                                                    style={{ display: "inline", width: "64px" }}
                                                    disabled={!state.condition.hasDayAge}
                                                    value={state.condition.day_age_from}
                                                    onChange={val => this.onDayAgeFromChange(val)}
                                                />
                                                <span style={{ lineHeight: "37px" }}>日 ～ </span>
                                                <RequiredNumInput 
                                                    min={LMT.COW.AGE_D_MIN} max={LMT.COW.AGE_D_MAX} step={LMT.COW.AGE_D_STEP}
                                                    style={{ display: "inline", width: "64px" }}
                                                    value={state.condition.day_age_to}
                                                    disabled={!state.condition.hasDayAge}
                                                    onChange={val => this.onDayAgeToChange(val)}
                                                />
                                                <span style={{ lineHeight: "37px" }}>日</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-md-4 col-xs-3 text-lg-right">品種</label>
                                    <div className="col-md-4 col-xs-9" data-testid="breed">
                                        <button type="button" className="btn btn-primary" onClick={() => this.setState({ popup_breed_open: true })}>選択</button>
                                        <div>
                                            <label className={styles["s-text"]}>
                                                {this.showBreedTypes()}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-md-4 col-xs-3 text-lg-right">用途</label>
                                    <div className="col-md-4 col-xs-9" data-testid="use">
                                        <button type="button" className="btn btn-primary" onClick={() => this.setState({ popup_use_open: true })}>選択</button>
                                        <div>
                                            <label className={styles["s-text"]}>
                                                {this.showUseTypes()}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-md-4 col-xs-3 text-lg-right">場所</label>
                                    <div className="col-md-4 col-xs-9">
                                        <button type="button" className="btn btn-primary" onClick={() => this.setState({ popup_ranch_house_open: true })}>選択</button>
                                        <div className={styles["s-text"]}>
                                            {
                                                this.state.condition.selected_house.map((value, i) => (
                                                    <label key={i}>
                                                        {this.showRanchHouses(value)}
                                                    </label>
                                                ))
                                            }
                                        </div>
                                        <div>
                                            <FormRadio prefix="radPeriod_"
                                                disabled={this.state.condition.selected_house.length === 0}
                                                options={[ { name: "現在ここにいる牛", value: 0 }, { name:"以下の期間にいた牛", value: 1 } ]}
                                                value={state.condition.is_period}
                                                isInline={false}
                                                onChange={val => this.setState({ condition: {...this.state.condition, is_period: val === 1 ? 1 : 0 }})} />
                                        </div>
                                        <OptionalTermSelector className="m-t-10"
                                            stValue={this.state.condition.period_start == null ? undefined : new Date(this.state.condition.period_start)}
                                            edValue={this.state.condition.period_end == null ? undefined : new Date(this.state.condition.period_end)}
                                            disabled={state.condition.is_period !== 1}
                                            portalContainerID={ID}
                                            onChange={(st,ed) => this.handleTermChange(st,ed)}
                                        />
                                    </div>
                                </div>
                                { hasBreedingContract && (
                                    <div className="form-group row">
                                        <label className="col-form-label col-md-4 col-xs-3 text-lg-right">繁殖状態</label>
                                        <div className="col-md-4 col-xs-9" data-testid="breeding-state">
                                            <button type="button" className="btn btn-primary" onClick={() => this.setState({ popup_breeding_state_open: true })}>選択</button>
                                            <div>
                                                <label className={styles["s-text"]}>
                                                    { this.showBreedingStates() }
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="form-group row">
                                    <label className="col-form-label col-md-4 col-xs-3 text-lg-right">タグ</label>
                                    <div className="col-md-4 col-xs-9" data-testid="tag">
                                        <button type="button" className="btn btn-primary" onClick={() => this.setState({ popup_tag_open: true })}>選択</button>
                                        <div>
                                            <label className={styles["s-text"]}>
                                                { this.showTags() }
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-xs-3 col-md-4 text-lg-right">導入元</label>
                                    <div className="col-md-4 col-xs-9" data-testid="start-kind">
                                        <div className="radio radio-css" style={{ width: "100%" }}>
                                            <input type="radio" id="chkStartKind0"
                                                onChange={() => this.setState({ condition: {...this.state.condition, start_kind: undefined }})}
                                                checked={state.condition.start_kind == null} />
                                            <label htmlFor="chkStartKind0">指定なし</label>
                                        </div>
                                        <FormRadio options={[ A.START_KIND.get("SELF")!, A.START_KIND.get("OUTSIDE")! ].map(s => ({ name: s.name, value: s.no }))}
                                            value={state.condition.start_kind}
                                            prefix="radstKind_"
                                            onChange={val => this.setState({ condition: { ...this.state.condition, start_kind: val }})} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-form-label col-md-4 col-xs-3 text-lg-right">プログラム</label>
                                    <div className="col-md-4 col-xs-9" data-testid="program">
                                        <button type="button" className="btn btn-primary" onClick={() => this.setState({ popup_program_open: true })}>選択</button>
                                        <div>
                                            <label className={styles["s-text"]}>
                                                { this.showPrograms() }
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-form-label col-md-4 col-xs-3 text-lg-right">イベント履歴</label>
                                    <div className="col-xs-9 col-md-7">
                                        <div className={styles["s-text"]}>以下の全イベントの記録がある牛</div>
                                        { (state.condition.events ?? []).map((ev,i) => (
                                            <EventConditionSelector key={i} index={i}
                                                ranch_id={this.props.ranch_id}
                                                user={this.props.user}
                                                values={ev}
                                                onChange={vals => this.onEventSelected(i, vals)}
                                                className={styles["condition-itemset"]}
                                            />
                                        ))}
                                        { (state.condition.events ?? []).length < 3 && (
                                            <EventConditionSelector index={(state.condition.events ?? []).length}
                                                ranch_id={this.props.ranch_id}
                                                user={this.props.user}
                                                values={undefined}
                                                onChange={vals => this.onEventSelected((state.condition.events ?? []).length, vals)}
                                                className={styles["condition-itemset"]}
                                            />
                                        )}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-form-label col-md-4 col-xs-3 text-lg-right">予定</label>
                                    <div className="col-xs-9 col-md-7">
                                        <div className={styles["s-text"]}>以下のすべての予定がある牛</div>
                                        { (state.condition.schedules ?? []).map((s,i) => (
                                            <ScheduleConditionSelector key={i} index={i}
                                                ranch_id={this.props.ranch_id}
                                                user={this.props.user}
                                                values={s}
                                                onChange={vals => this.onScheduleSelected(i, vals)}
                                                className={styles["condition-itemset"]}
                                            />
                                        ))}
                                        { (state.condition.schedules ?? []).length < 2 && (
                                            <ScheduleConditionSelector index={(state.condition.schedules ?? []).length}
                                                ranch_id={this.props.ranch_id}
                                                user={this.props.user}
                                                values={undefined}
                                                onChange={vals => this.onScheduleSelected((state.condition.schedules ?? []).length, vals)}
                                                className={styles["condition-itemset"]}
                                            />
                                        )}
                                    </div>
                                </div>

                                <div className="form-group row m-b-30">
                                    <label className="col-form-label col-md-4 col-xs-3 text-lg-right">飼養状態</label>
                                    <div className="col-md-4 col-xs-9">
                                        <FormRadio prefix="radIsActive_"
                                            options={[ { name:"飼養中", value:1 }, { name:"飼養終了済", value: 0 } ]}
                                            value={state.condition.is_active}
                                            isInline={false}
                                            onChange={val => this.setState({ condition: {...this.state.condition, is_active:val === 1 ? 1 : 0 }})} />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-form-label col-md-4 col-xs-3 text-lg-right">表示項目</label>
                                    <div className="col-md-4 col-xs-9" data-testid="output">
                                        <button type="button" className="btn btn-primary" onClick={() => this.setState({ popup_output_open: true })}>選択</button>
                                        <div>
                                            <label className={styles["s-text"]}>
                                                { this.showOutputs() }
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ textAlign:"right" }}>
                                    <span className="link" onClick={() => this.setState({ popup_savelist_open: true })}>この条件を保存する</span>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <div style={{ width: "100%", display: "flex", justifyContent:"space-between" }}>
                                <button className="btn btn-secondary" style={{minWidth:"100px"}} onClick={this.onConditionFormat}>{this.props.resetButton}</button>
                                <button className="btn btn-success" style={{minWidth:"100px"}} onClick={this.onCloseWithResult}>検索</button>
                            </div>
                        </ModalFooter>
                    </>)}
                </Modal>
                {
                    state.popup_breed_open && (
                        <CheckboxPopup
                            header="品種選択"
                            items={this.props.cow_breed.map(c => ({ value: c.breed_no, name: c.name }))}
                            onClose={() => this.setState({ popup_breed_open: false })}
                            defaultValues={state.condition.breeds}
                            onSubmit={b => this.onBreedSelected(b)} />
                    )
                }
                {
                    state.popup_use_open && (
                        <CheckboxPopup
                            header="用途選択"
                            items={this.props.cow_use.map(c => ({ value: c.use_no, name: c.name }))}
                            onClose={() => this.setState({ popup_use_open: false })}
                            defaultValues={state.condition.uses}
                            onSubmit={b => this.onUseSelected(b)} />
                    )
                }
                {
                    state.popup_output_open && (
                        <SelectOutputPopup
                            hasBreedingContract={hasBreedingContract}
                            hasCowBalanceAuth={hasCowBalanceAuth}
                            onClose={() => this.setState({ popup_output_open: false })}
                            outputs={this.state.outputs}
                            onSubmit={outputs => this.setState({ popup_output_open: false, outputs }) }/>
                    )
                }
                {
                    state.popup_ranch_house_open && (
                        <SelectRanchHousePopup isOpen={true}
                            ranchHouse={this.state.ranchHouses}
                            selected_house={this.state.condition.selected_house}
                            onClose={() => this.setState({ popup_ranch_house_open: false })}
                            onResult={(b) => this.onRanchHouseSelected(b)} />
                    )
                }
                {
                    state.popup_breeding_state_open && (
                        <CheckboxPopup
                            header="繁殖状態"
                            items={BREEDING_STATES.map(k => ({ value: BREEDING_STATE[k].no, name: k }))}
                            onClose={() => this.setState({ popup_breeding_state_open: false })}
                            defaultValues={state.condition.breeding_states ?? []}
                            onSubmit={b => this.onBreedingStateSelected(b)} />
                    )
                }
                {
                    state.popup_tag_open && (
                        <CheckboxPopup
                            header="タグ"
                            messageNoItems="選択可能なタグはありません"
                            items={this.props.tags.map(t => ({ value: t.tag_id, name: t.tag_name })).sort((a,b) => a.name < b.name ? -1 : a.name === b.name ? 0 : 1)}
                            onClose={() => this.setState({ popup_tag_open: false })}
                            defaultValues={state.condition.tags ?? []}
                            onSubmit={t => this.onTagSelected(t)} />
                    )
                }
                {
                    state.popup_program_open && (
                        <CheckboxPopup
                            header="適用中のプログラム"
                            messageNoItems="選択可能なプログラムはありません"
                            items={this.props.programs.filter(p => p.trigger_kind === PROGRAM_TRIGGER_KIND.EVENT.no).map(p => ({ name: p.name, value: p.program_id }))}
                            defaultValues={state.condition.programs ?? []}
                            onClose={() => this.setState({ popup_program_open: false })}
                            onSubmit={p => this.onProgramSelected(p)} />
                    )}
                {
                    state.isSelectListShown && (
                        <SelectListPopup
                            ranchId={this.props.ranch_id}
                            onClose={() => this.setState({ isSelectListShown: false })}
                            isSubmitExecuting={this.state.executing}
                            onDelete={this.onListDeleted}
                            onSubmit={this.onListSelected}
                            onSort={this.onListSort} />
                    )
                }
                {
                    state.popup_savelist_open && (
                        <SaveListPopup
                            ranchId={this.props.ranch_id}
                            onClose={() => this.setState({ popup_savelist_open: false })}
                            filterName={this.state.selectedListItem?.name ?? ""}
                            filterNo={this.state.selectedListItem?.filter_no}
                            isSubmitExecuting={this.state.executing}
                            onSubmit={this.onSaveList} />
                    )
                }
            </div>
        )
    }
}