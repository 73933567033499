import React from 'react';
import styles from './icon-right.module.css';
import classnames from 'classnames';

interface MyProps {
    iconType: "remove"|"edit";
    onClick?: () => void;
    className?: string;
    style?: React.CSSProperties;
    testId?: string;
}

export const IconRight = React.memo((props: MyProps) => {

    const iClass = props.iconType === "remove" ? "fas fa-times"
                : props.iconType === "edit" ? "fas fa-pen"
                : undefined;
    const addClass = props.iconType === "remove" ? styles.remove : undefined;

    return (
        <i className={classnames("clickable", iClass, styles.icon, addClass, props.className)}
            onClick={props.onClick}
            style={props.style}
            data-testid={props.testId}
        />
    )
});