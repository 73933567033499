import React from 'react';
import classNames from 'classnames/bind';
import styles from './RegisterFeed.module.css';

let styleCxt = classNames.bind(styles);

export type SortType = 'Trace' | 'House' | 'Birth'
export type SortOrder = 'Asc' | 'Desc'

interface MyProps {
    order: SortOrder;
    type: SortType;
    onChangeOrder: (order: SortOrder) => void;
    onChangeType: (type: SortType) => void;
}

export const BulkCowSortIcon = React.memo((props: MyProps) => {
    return (
        <div className={ styleCxt('operation-sort') }>
            <select data-testid="牛ソート種類" dir="rtl" className={ styleCxt('operation-sort-type')} value={ props.type } onChange={ e => props.onChangeType(e.target.value as SortType) }>
                <option value='Trace'>耳標順</option>
                <option value='House'>牛舎・部屋毎</option>
                <option value='Birth'>生年月日順</option>
            </select>
            <button data-testid="牛ソート向き" className={ styleCxt('operation-sort-order') } onClick={() => props.onChangeOrder(props.order === "Asc" ? "Desc" : "Asc") }>
                { props.order === 'Asc' ? <i className="fas fa-sort-up" /> : <i className="fas fa-sort-down" /> }
            </button>
        </div>
    )
});