import React from 'react';
import { Link } from 'react-router-dom';

import styles from './top.module.css'
import { IUser } from '../../stores';
import { ActionIconBar } from '../../components/parts/action-icon-bar';
import { COW_ACTION_TYPE } from '../ranch/ranch-houses';
import { historyLocation, IHistoryLocation } from '../../config/history-location-builder';

export default React.memo<{ ranchId: number, user: IUser, onActionSelected:(action:COW_ACTION_TYPE)=>void, onNavigate(location: IHistoryLocation) }>(
  ({ ranchId, user, onActionSelected, onNavigate }) => (
    <>
      <p className={  styles.menuheader }>アクション</p>

      <ActionIconBar
          optionalStyles={{
            margin:"8px 1rem 2px",
            padding:"8px 0 4px",
            boxShadow: "0px 3px 10px rgba(0,0,0,0.2)",
            borderRadius:"6px",
            background:"white",
        }}
          arrowTop={14}
          arrowLeft={8}
          isActive={true}
          onBalanceClick={()=>onActionSelected("BALANCE")}
          onBreedingClick={()=>onActionSelected("BREEDING")}
          onDeliveryClick={()=>onActionSelected("DELIVERY")}
          onFeedingClick={()=>onActionSelected("FEEDING")}
          onBulkFeedingClick={()=> onNavigate({ pathname:"/feed/bulk/" + ranchId })}
          onFeedingLeftClick={()=> onNavigate(historyLocation.toFeedRemained())}
          onGrowthClick={()=>onActionSelected("GROWTH")}
          onLocationClick={()=>onActionSelected("MOVE")}
          onPreventionClick={()=>onActionSelected("PREVENTION")}
          onProgramClick={()=>onActionSelected("PROGRAM")}
          onRutDetailClick={()=>onActionSelected("RUT")}
          onScheduleClick={()=>onActionSelected("SCHEDULE")}
          onSellCowClick={()=>onActionSelected("SELLCOW")}
          onSellMilkClick={()=>onActionSelected("SELLMILK")}
          onOtherPlCowClick={()=>onActionSelected("OTHER_PL")}
          onSymptomClick={()=>onActionSelected("SYMPTOM")}
          onBulkTreatClick={() => onNavigate({ pathname:"/symptom/bulk/" + ranchId })}
          onBalanceRanchClick={()=> onNavigate({ pathname:"/balance/ranch"})}
          onRanchEventClick={() => onNavigate({ pathname:"/ranch/event"})}
          onSellRanchMilkClick={() => onNavigate(historyLocation.toSellMilk(undefined, undefined))}
          onOtherPlRanchClick={() => onNavigate(historyLocation.toOtherPl(undefined, undefined))}
          onReportSellCowClick={() => onNavigate({ pathname:"/report/sellcow" })}
          onReportDeliveryClick={() => onNavigate({ pathname: "/report/delivery" })}
          onReportDynamicsClick={() => onNavigate({ pathname: "/report/dynamics" })}
          onSovClick={()=>onActionSelected("SOV")}
          onOpuClick={()=>onActionSelected("OPU")}
          onIvfClick={()=>onActionSelected("IVF")}
          onIvfEggClick={()=>onActionSelected("IVF_EGG")}
          onCertClick={tp => onActionSelected(tp === "vaccine" ? "CERT_VACCINE" : tp === "disease" ? "CERT_DISEASE" : "CERT_KARTE")}
          onImportClick={tp => {
            if (tp === "robot") onNavigate(historyLocation.toRobotImport());
          }}
          onReportSensorClick={() => onNavigate(historyLocation.toReportSensor())}
          ranchId={ranchId}
          showsBreedingGroup={true}
          showsSingleCowIcon={true}
          showsSellMilk={true}
          user={user}

      />
      <div className="m-t-20" style={{ textAlign:"right", marginRight:"1rem" }}>
        <Link to="/ranch/main" className="link">
          <i className="fas fa-angle-right m-r-5"></i>
          <span>牛一覧</span>
        </Link>
      </div>
    </>
  )
)