import React, { useState, useEffect } from 'react';
import Big from 'big.js';

interface BaseProps {
    className?: string;
    style?: React.CSSProperties;
    min?: number;
    max?: number;
    step?: number;
    type?: "number"|"tel";
    disabled?: boolean;
    placeholder?: string;
    testId?: string;
}

export interface RequiredNumInputProps extends BaseProps {
    value: number;
    onChange: (val: number) => void;
}
export interface OptionalNumInputProps extends BaseProps {
    value?: number|undefined;
    onChange: (val: number|undefined) => void;
}

const renderInput = (props: BaseProps,
                    value: string,
                    onChange: (val:string) => void,
                    onBlur: () => void) => (
    <input type={props.type ?? "number"}
        min={props.min}
        max={props.max}
        //※上下スピンボタンでの増減量は1にするが、入力精度はprops.stepの値にしたいため、
        //　input側ではstepをかけない（1にすると、小数入力した際にchromeだとtooltipで警告が出る）
        step="any"
        className={"form-control form-input-number " + (props.className ?? "")}
        style={props.style}
        value={value}
        onChange={e => onChange(e.target.value)}
        onFocus={e => e.target.select()}
        onBlur={onBlur}
        disabled={props.disabled}
        placeholder={props.placeholder}
        data-testid={props.testId}
     />
)

export const RequiredNumInput: React.FC<RequiredNumInputProps> = (props) => {
    const [ val, setVal ] = useState("");

    useEffect(() => {
        setVal(props.value.toString());

    }, [ props.value ]);

    const onChange = (val: string) => {
        setVal(val);
    }

    const onBlur = () => {
        let num = Number(val);

        if (val === "" || isNaN(num)) {
            setVal(props.value.toString());
            return;
        }

        if (props.min != null && num < props.min) {
            num = props.min;
        } else if (props.max != null && props.max < num) {
            num = props.max;
        }

        if (props.step != null) {
            const mod = Big(num).mod(props.step);
            if (!mod.eq(0)) {
                num = Number(Big(num).minus(mod).toString());
            }
        }

        if (num !== props.value) {
            props.onChange(num);
        } else {
            //丸めこみなどで見た目上だけ変化している場合のため
            setVal(num.toString());
        }

    }

    return renderInput(props, val, onChange, onBlur);
}

export const OptionalNumInput: React.FC<OptionalNumInputProps> = (props) => {
    const [ val, setVal ] = useState("");

    useEffect(() => {
        setVal(props.value == null ? "" : props.value.toString());

    }, [ props.value ]);

    const onChange = (val: string) => {
        setVal(val);
    }

    const onBlur = () => {
        let num = Number(val);

        if (val === "" || isNaN(num)) {
            props.onChange(undefined);
            return;
        }

        if (props.min != null && num < props.min) {
            num = props.min;
        } else if (props.max != null && props.max < num) {
            num = props.max;
        }

        if (props.step != null) {
            const mod = Big(num).mod(props.step);
            if (!mod.eq(0)) {
                num = Number(Big(num).minus(mod).toString());
            }
        }

        if (num !== props.value) {
            props.onChange(num);
        } else {
            //丸めこみなどで見た目上だけ変化している場合のため
            setVal(num.toString());
        }
    }

    return renderInput(props, val, onChange, onBlur);
}