import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PageSettings } from '../../config/page-settings.js';
import { A, LMT } from '../../config/constant';
import { IEditingProgram, ITeamProgramItem, IEditingProgramItem } from './program-management';
import { ProgramKind, ProgramTriggerKey, PROGRAM_TRIGGER } from '../../config/program-kind';
import styles from './setting.module.css';
import pgStyles from './program-management.module.css';
import { EventKind } from '../../config/event-kind';
import { ProgramManagementItemEditPopup } from './program-management-item-edit-popup';
import { CommonUtil, FreezedArray } from '../../config/util';
import { IUser, IMedicine, ITreatItem, ICowBreed, ICowUse, IMedicineRoute } from '../../stores/RootStore';
import { ProgramTriggerConditionSelector } from './program-trigger-condition-selector';
import { ProgramTriggerCondition } from '../../config/program-trigger-condition';
import { CollapseContainer } from '../../components/parts/collapse-container';
import { RanchTagDto, TeamTreatPresetDto } from '../../api/api';
import { CowTagMultiInput } from '../cow/cow-tag-editor';
import { EditingTag } from '../../components/tag-edit-popup/tag-edit-popup';
import { AppState } from '../../app';
import { IconLink } from '../../components/parts/icon-link';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { InfoPopup } from '../../components/parts/info-popup';

interface MyProps {
    data: IEditingProgram;
    onSubmit: ()=>void;
    onClose: ()=>void;
    onDelete: ()=>void;
    onNameEdited:(name:string)=>void;
    onTriggerKindEdited:(trigger_kind:number)=>void;
    onTriggerEventSelected:(trigger_event?:ProgramTriggerKey)=>void;
    onTriggerConditionEdited:(condition?:ProgramTriggerCondition)=>void;
    onBaseDateKindSelected:(base_date_kind?:number)=>void;
    onIsAutoAppliedChecked:(is_auto_applied:1|0)=>void;
    onCommitItem:(editingItem:IEditingProgramItem)=>void;
    onDeleteItem:(item_no:number)=>void;
    onTagsToAddChanged: (tags:EditingTag[]) => void;
    onTagsToRemChanged: (tags:EditingTag[]) => void;
    ranch_id: number | undefined;
    user: IUser;
    isSubmitExecuting: boolean;
    medicines: FreezedArray<IMedicine>;
    treatItems: FreezedArray<ITreatItem>;
    medicineRoutes: FreezedArray<IMedicineRoute>;
    presets: FreezedArray<TeamTreatPresetDto>;
    cowBreeds: FreezedArray<ICowBreed>;
    cowUses: FreezedArray<ICowUse>;
    allTags: FreezedArray<RanchTagDto>;
    tagsToAdd: FreezedArray<EditingTag>;
    tagsToRem: FreezedArray<EditingTag>;
}
interface MyState {
    isEditing: boolean;
    editingData?: IEditingProgramItem;
    isTriggerOpen: boolean;
    isResultOpen: boolean;
}

export class ProgramManagementEditPopup extends React.Component<MyProps,MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            isEditing: false,
            editingData: undefined,
            isTriggerOpen: true,
            isResultOpen: true,
        }

        this.onAddItem = this.onAddItem.bind(this);
        this.onStartEdit = this.onStartEdit.bind(this);
        this.onCommit = this.onCommit.bind(this);
        this.onTitleEdited = this.onTitleEdited.bind(this);
        this.onEventKindSelected = this.onEventKindSelected.bind(this);
        this.onBaseItemSelected = this.onBaseItemSelected.bind(this);
        this.onIntervalDaysEdited = this.onIntervalDaysEdited.bind(this);
        this.onPresetSelected = this.onPresetSelected.bind(this);
        this.onDeleteItem = this.onDeleteItem.bind(this);
    }

    private onAddItem() {
        if (!CommonUtil.assertNotNull(this.props.data, "data", "onAddItem")) return;
        this.setState({
            isEditing: true,
            editingData: {
                isNew: true,
                program_id: this.props.data.program_id,
                item_no: 0,
                title: "",
                event_kind_no: 0,
                base_item_no: 0,
                interval_days: 0,
            }
        })
    }

    private onStartEdit(data: ITeamProgramItem) {
        if (!CommonUtil.assertNotNull(this.props.data, "data", "onStartEdit")) return;
        this.setState({
            isEditing: true,
            editingData: {
                isNew: false,
                program_id: data.program_id,
                item_no: data.item_no,
                title: data.title,
                event_kind_no: data.event_kind_no,
                base_item_no: data.base_item_no,
                interval_days: data.interval_days,
                preset_id: data.preset_id,
            }
        });
    }

    private onTitleEdited(title: string) {
        this.onDataEdited({ title });
    }
    private onEventKindSelected(event_kind_no: number) {
        this.onDataEdited({
            event_kind_no,
            preset_id: undefined
        });
    }
    private onBaseItemSelected(base_item_no: number) {
        this.onDataEdited({ base_item_no });
    }
    private onIntervalDaysEdited(interval_days: number) {
        this.onDataEdited({ interval_days });
    }
    private onPresetSelected(preset_id?: number) {
        this.onDataEdited({ preset_id });
    }
    private onDataEdited<K extends keyof IEditingProgramItem>(state: Pick<IEditingProgramItem, K>) {
        if (!CommonUtil.assertNotNull(this.state.editingData, "editingData", "onDataEdited")) return;
        this.setState({
            editingData: {
                ...this.state.editingData,
                ...state
            }
        });
    }

    private onCommit() {
        const data = this.state.editingData;
        if (!CommonUtil.assertNotNull(data, "editingData", "onCommit")) return;

        if (data.title === "") {
            this.context.showToast(A.MESSAGE.NO_PROGRAM_ITEM_TITLE_INPUT);
            return;
        }

        if (data.event_kind_no === 0) {
            this.context.showToast(A.MESSAGE.NO_PROGRAM_ITEM_EVENT_KIND_SELECT);
            return;
        }

        this.props.onCommitItem(data);

        this.setState({
            isEditing: false,
            editingData: undefined,
        })
    }

    private onDeleteItem(item_no:number) {
        const data = this.state.editingData;
        if (!CommonUtil.assertNotNull(data, "editingData", "onDeleteItem")) return;

        if(this.props.data.items.some(pi => pi.base_item_no === data.item_no)) {
            this.context.showToast(A.MESSAGE.PROGRAM_ITEM_USED_BASE_ITEM);
            return;
        }

        this.props.onDeleteItem(item_no)

        this.setState({
            isEditing: false,
            editingData: undefined,
        })
    }

    render() {
        const isRanch = this.props.ranch_id != null;
        const currentTriggerKind = ProgramKind.findTriggerKind(this.props.data.trigger_kind);
        if (currentTriggerKind == null) return <></>;

        const triggerKinds = ProgramKind.allTriggerKinds.filter(t => isRanch ? t.asRanch : t.asClinic);

        return (
            <div>
                <Modal isOpen={true} centered={true}>
                    <ModalHeader toggle={this.props.onClose}>プログラム編集</ModalHeader>
                    <ModalBody style={{ overflowY: "auto", height: "calc(100vh - 210px)", paddingLeft:"20px" }}>
                        <div className="form-group" style={{display:"flex"}}>
                            <label className={"col-form-label " + pgStyles["popup-label"]}>プログラム名</label>
                            <input className="form-control" type="text" value={this.props.data.name}
                                maxLength={LMT.PROGRAM.NAME_LEN}
                                onChange={(e)=>this.props.onNameEdited(e.target.value)}/>
                        </div>
                        <CollapseContainer headerClassName={pgStyles.collapse}
                            isOpen={this.state.isTriggerOpen}
                            onIsOpenChange={open => this.setState({ isTriggerOpen:open })}
                            header="起動条件">
                            <>
                            <div className="form-group" style={{display:"flex"}}>
                                <label className={"col-form-label " + pgStyles["popup-label"]}>起動方法</label>
                                { triggerKinds.map(tr => (
                                    <div className="radio radio-css m-l-10 radio-inline" key={tr.no}>
                                        <input type="radio" name="radioTriggerKind" id={"radioTriggerKind" + tr.no}
                                            value={tr.no}
                                            onChange={e => this.props.onTriggerKindEdited(parseInt(e.target.value))}
                                            checked={this.props.data.trigger_kind === tr.no} />
                                        <label htmlFor={"radioTriggerKind" + tr.no}>{tr.name}</label>
                                    </div>
                                ))}
                            </div>
                            { currentTriggerKind.hasTriggerEvent && <>
                                <div className="form-group" style={{display:"flex"}}>
                                    <label className={"col-form-label " + pgStyles["popup-label"]}>起動タイミング</label>
                                    <select className="form-control" value={this.props.data.triggerEventKey}
                                        onChange={(e) => this.props.onTriggerEventSelected(e.target.value === "0" ? undefined : e.target.value as ProgramTriggerKey)}>
                                        <option value="0">未選択</option>
                                        {
                                            Object.entries(PROGRAM_TRIGGER).map(([key, value], i) => (
                                                <option key={i} value={key}>{value.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <ProgramTriggerConditionSelector className="form-group"
                                    condition={this.props.data.triggerCondition}
                                    cowBreeds={this.props.cowBreeds}
                                    cowUses={this.props.cowUses}
                                    medicines={this.props.medicines}
                                    treatItems={this.props.treatItems}
                                    triggerEvent={this.props.data.triggerEventKey}
                                    onChange={this.props.onTriggerConditionEdited}
                                />
                                <div className="form-group" style={{display:"flex"}}>
                                    <label className={"col-form-label " + pgStyles["popup-label"]}></label>
                                    <div>
                                        <div className="checkbox checkbox-css m-t-10">
                                            <input type="checkbox" id={`isAutoApplied`} onChange={e => this.props.onIsAutoAppliedChecked(e.target.checked ? 1 : 0)} 
                                                checked={this.props.data.is_auto_applied === 1} />
                                            <label htmlFor={`isAutoApplied`}>{currentTriggerKind.autoApply?.description}</label>
                                            { currentTriggerKind.autoApply?.help != null && (
                                                <InfoPopup message={currentTriggerKind.autoApply.help} iconType="question" placement="top" />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </>}
                            </>
                        </CollapseContainer>
                        <CollapseContainer headerClassName={pgStyles.collapse}
                            isOpen={this.state.isResultOpen}
                            onIsOpenChange={open => this.setState({ isResultOpen: open})}
                            header="実行内容">
                            <div>
                                { isRanch && (<>
                                    <div className="form-group" style={{display:"flex"}}>
                                        <label className={"col-form-label " + pgStyles["popup-label"]}>タグをつける</label>
                                        <CowTagMultiInput className="w-100"
                                            allTags={this.props.allTags}
                                            tags={this.props.tagsToAdd}
                                            onChange={tags => this.props.onTagsToAddChanged(tags)}
                                        />
                                    </div>
                                    <div className="form-group" style={{display:"flex"}}>
                                        <label className={"col-form-label " + pgStyles["popup-label"]}>タグをはずす</label>
                                        <CowTagMultiInput className="w-100"
                                            allTags={this.props.allTags}
                                            tags={this.props.tagsToRem}
                                            onChange={tags => this.props.onTagsToRemChanged(tags)}
                                        />
                                    </div>
                                </>)}
                                { currentTriggerKind.hasTriggerEvent && (
                                    <div className="form-group" style={{display:"flex"}}>
                                        <label className={"col-form-label " + pgStyles["popup-label"]}>起算日</label>
                                        <select className="form-control" value={this.props.data.base_date_kind??0}
                                            onChange={(e) => this.props.onBaseDateKindSelected(parseInt(e.target.value))}>
                                            <option value="0">未選択</option>
                                            {
                                                this.props.data.triggerEventKey && ProgramKind.getBaseDates(this.props.data.triggerEventKey!).map((value, i) => (
                                                    <option key={i} value={value.kindNo}>{value.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                )}
                                <div className="product-detail" style={{ height: "100%" }}>
                                    <ul className={styles["list"]}>
                                        {this.props.data.items.map(item => (
                                            <li key={item.item_no} className={styles["list-item"]}>
                                                <div className={styles["list-item-content"]}>
                                                    <div className={styles["list-item-name"]}>{item.item_no}.{item.title}</div>
                                                    <div className={styles["list-item-detail"]}>{EventKind.find(item.event_kind_no)?.schedule?.sname} {item.base_item_no === 0 ? "起算日" : `(${item.base_item_no})`}の{item.interval_days}日後</div>
                                                </div>
                                                <div className={styles["list-item-icon"]} onClick={()=>this.onStartEdit(item)}><i className="fas fa-pen clickable"></i></div>
                                            </li>))
                                        }
                                    </ul>
                                </div>
                                <div style={{marginTop:"5px"}} onClick={this.onAddItem}>
                                    <IconLink iconType="add" text="予定項目を追加" />
                                </div>
                            </div>
                        </CollapseContainer>
                    </ModalBody>
                    <ModalFooter className="modal-footer-fix">
                        <ExecutionButton type="save" onClick={this.props.onSubmit} disabled={this.props.isSubmitExecuting} />
                        { this.props.data && !this.props.data.isNew && (
                            <ExecutionButton type="delete" onClick={this.props.onDelete} disabled={this.props.isSubmitExecuting} />
                        )}
                    </ModalFooter>
                </Modal>
                { this.state.editingData != null && (
                    <ProgramManagementItemEditPopup
                        items={this.props.data.items}
                        data={this.state.editingData}
                        onClose={() => this.setState({editingData: undefined})}
                        onCommit={this.onCommit}
                        onDelete={this.onDeleteItem}
                        onTitleEdited={this.onTitleEdited}
                        onEventKindSelected={this.onEventKindSelected}
                        onBaseItemSelected={this.onBaseItemSelected}
                        onIntervalDaysEdited={this.onIntervalDaysEdited}
                        onPresetSelected={this.onPresetSelected}
                        ranch_id={this.props.ranch_id}
                        user={this.props.user}
                        medicines={this.props.medicines}
                        medicineRoutes={this.props.medicineRoutes}
                        treatItems={this.props.treatItems}
                        presets={this.props.presets}
                    />
                )}                
            </div>
        )
    }
}