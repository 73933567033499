import React, { useState, useEffect, useCallback } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ExecutionButton } from '../../components/buttons/execution-button';
import styles from './cert-vaccine.module.css';
import { FreezedArray } from '../../config/util';
import { PreventionCertVaccinationReqCowVaccination } from '../../api';
import { Checkbox } from '../../components/form/form-checkbox';
import { LMT } from '../../config/constant';
import moment from 'moment';
import classnames from 'classnames';

export type SelectableVaccine = PreventionCertVaccinationReqCowVaccination & { selected: boolean };

interface MyProps {
    onClose: () => void;
    onSubmit: (cowId: number, vaccines: FreezedArray<SelectableVaccine>) => void;
    cowId: number;
    dispName: string;
    vaccines: FreezedArray<SelectableVaccine>;
}

export const CertVaccineEditPoppup = (props: MyProps) => {
    const [ vaccines, setVaccines ] = useState<FreezedArray<SelectableVaccine>>([]);

    useEffect(() => {
        setVaccines(props.vaccines);

    }, [ props.vaccines ])

    const onChange = useCallback((i:number, checked:boolean) => {

        setVaccines(vs => vs.map((preV,preI) => {
            if (i !== preI) return preV;

            return {
                ...preV,
                selected: checked
            }
        }))
    }, []);

    const isValid = vaccines.filter(v => v.selected).length <= LMT.CERT.VACCINE_MAX_COUNT_PER_COW;

    return (
        <div>
            <Modal isOpen={true} scrollable={true}>
                <ModalHeader toggle={props.onClose}>{props.dispName}</ModalHeader>
                <ModalBody>
                    { vaccines.map((v,i) => (
                        <div key={i} className={styles["popup-item"]}>
                            <Checkbox checked={v.selected}
                                label={`${moment(v.inoculated_on).format("YYYY/M/D")} ${v.name}`}
                                id={"chkVt"+i}
                                onChange={e => onChange(i, e.target.checked)}
                            />
                            <div className={styles["popup-lot"]}>{v.lot_no ?? ""}{v.expire_on == null ? "" : `（～${moment(v.expire_on).format("YYYY年M月")}）`}</div>
                        </div>
                    ))}
                    <div className={classnames(styles["popup-invalid"], { invisible:isValid })}
                        >1度に{LMT.CERT.VACCINE_MAX_COUNT_PER_COW}件の選択が可能です</div>
                </ModalBody>
                <ModalFooter className="modal-footer-fix">
                    <ExecutionButton type="decide" disabled={!isValid} onClick={() => props.onSubmit(props.cowId, vaccines)} />
                </ModalFooter>
            </Modal>
        </div>
    )

}