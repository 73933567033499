import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PageSettings } from '../../config/page-settings.js';
import { IEditingCondition, IEditingConditionDetail } from './condition-master';
import { ICondition } from '../../stores/RootStore';
import styles from './setting.module.css';
import classnames from 'classnames';
import { LMT } from '../../config/constant';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { IconLink } from '../../components/parts/icon-link';
import { ModalInputBlock } from '../../components/parts/modal-input-block';
import { MemoSortableList } from '../../components/parts/sortable-list';
import { IconRight } from '../../components/parts/icon-right';
import { FreezedArray } from '../../config/util';

interface MyProps {
    data: Readonly<IEditingCondition>;
    conditionList: FreezedArray<ICondition>;
    onSubmit: (data: IEditingCondition)=>void;
    onClose: ()=>void;
    onDelete: ()=>void;
    isSubmitExecuting: boolean;
}
interface MyState {
    sortingList: IEditingConditionDetail[];
    isSorting: boolean;
    editingData : Readonly<IEditingCondition>;
}

export class ConditionEditPopup extends React.Component<MyProps,MyState> {

    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            sortingList: [],
            isSorting: false,
            editingData: this.props.data
        }

        this.startSorting=this.startSorting.bind(this);
        this.finishSorting=this.finishSorting.bind(this);
        this.cancelSorting=this.cancelSorting.bind(this);
        this.onClassNameEdited = this.onClassNameEdited.bind(this);
        this.onDetailNameEdited = this.onDetailNameEdited.bind(this);
        this.onAddDetail = this.onAddDetail.bind(this);
        this.onRemoveDetail = this.onRemoveDetail.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    private startSorting() {
        if (this.state.isSorting) {
            console.error("already sorting");
            return;
        }

        this.setState({
            isSorting: true,
            sortingList:　this.state.editingData.details,
        });
    }

    private cancelSorting() {
        if (!this.state.isSorting) {
            console.error("not sotring on cancel");
            return;
        }

        this.setState({
            isSorting: false,
            sortingList:[]
        });
    }

    private finishSorting() {
        if (!this.state.isSorting) {
            console.error("not sotring on cancel");
            return;
        }

        const data = { ...this.state.editingData};
        data.details = this.state.sortingList;

        this.setState({
            isSorting: false,
            editingData: data,
            sortingList:[],
        });
    }

    private onClassNameEdited(name: string) {
        const data = { ...this.state.editingData};
        data.name = name;
        this.setState({
            editingData:data
        });
    }

    private onDetailNameEdited(detail:IEditingConditionDetail, name: string) {
        const data = { ...this.state.editingData};
        for (var i = 0; i < data.details.length; i++) {
            if (data.details[i] === detail) {
                data.details[i].name = name
                break;
            }
        }
        this.setState({ editingData: data });
    }

    private onAddDetail() {
        const data = { ...this.state.editingData};
        data.details.push({
            detail_id: null,
            name: "",
        });
        this.setState({ editingData: data });
    }

    private onRemoveDetail(detail:IEditingConditionDetail) {
        const data = { ...this.state.editingData};
        for (var i = 0; i < data.details.length; i++) {
            if (data.details[i] === detail) {
                data.details.splice(i, 1);
                break;
            }
        }
        this.setState({ editingData: data });
    }

    private onClose() {
        this.setState({
            isSorting: false,
            sortingList:[]
        });

        this.props.onClose();
    }

    render() {
        return (
            <div>
                <Modal isOpen={true} centered={true} scrollable={true}>
                    <ModalHeader toggle={this.onClose}>症状詳細編集</ModalHeader>
                    <ModalBody id="modal-body" style={{ minHeight: "calc(100vh - 320px)" }}>
                        <ModalInputBlock label="大分類" contentStyle={{ alignSelf:"center", fontWeight:"bold" }}>
                            <span>
                                {this.props.conditionList.find(k => k.condition_id === this.props.data.condition_id)?.name??""}
                            </span>
                        </ModalInputBlock>
                        <ModalInputBlock label="症状名">
                            <input className="form-control" type="text" value={this.state.editingData.name}
                                    maxLength={LMT.SYMPTOM.CONDITION_CLASS_LEN}
                                    onChange={(e)=>this.onClassNameEdited(e.target.value)}/>
                        </ModalInputBlock>
                        <ModalInputBlock label="詳細項目">
                        <div>
                            <div className={styles["sortable-list-shoulder"]} style={{ margin:"8px 0 5px"}}>
                                {!this.state.isSorting && (<span className="link" onClick={this.startSorting}>並び替え</span>)}
                                {this.state.isSorting && (<span className="link" onClick={this.finishSorting}>完了</span>)}
                                {this.state.isSorting && (<span className="link" onClick={this.cancelSorting}>キャンセル</span>)}
                            </div>
                            { this.state.isSorting ? (
                                <MemoSortableList className={styles.list}
                                    isSorting={true}
                                    items={this.state.sortingList}
                                    onSorted={l => this.setState({ sortingList:l })}
                                    listItem={item => (
                                        <li className={classnames(styles["list-item"], styles["fix-height"], styles["sortable-helper"])}>
                                            <div className={styles["list-item-content"]}>
                                                <div className={classnames(styles["list-item-name"], "m-t-10")}>{item.name}</div>
                                            </div>
                                            <div className={styles["list-item-icon"]}>
                                                <i className="fas fa-bars p-5" />
                                            </div>
                                        </li>
                                    )}
                                />
                            ) : (
                                <ul className={styles.list}>
                                    { this.state.editingData.details.map((dtl,i) => (
                                        <li className={classnames(styles["list-item"], styles["fix-height"])} key={i}>
                                            <div className={styles["list-item-content"]}>
                                                <input type="text" className="form-control"
                                                    size={1}
                                                    style={{ width:"100%", marginRight:"8px" }}
                                                    value={dtl.name}
                                                    onChange={e => this.onDetailNameEdited(dtl, e.target.value)}
                                                    maxLength={LMT.SYMPTOM.CONDITION_NAME_LEN}
                                                />
                                            </div>
                                            <div className={styles["list-item-icon"]}>
                                                <IconRight iconType="remove" onClick={() => this.onRemoveDetail(dtl)} />
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            )}
                            <div className="m-t-10">
                                <IconLink iconType="add" text="追加" onClick={this.onAddDetail}
                                    className={this.state.isSorting ? "invisible" : ""}
                                />
                            </div>
                        </div>
                        </ModalInputBlock>
                    </ModalBody>
                    <ModalFooter className="modal-footer-fix">
                        <ExecutionButton type="save" onClick={() => this.props.onSubmit(this.state.editingData)} disabled={this.props.isSubmitExecuting || this.state.isSorting} />
                        { !this.props.data.isNew && (
                            <ExecutionButton type="delete" onClick={this.props.onDelete} disabled={this.props.isSubmitExecuting || this.state.isSorting} />
                        )}
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}