import { ar, FreezedArray } from "./util";
import { RanchSeedStockDto, BreedingCertPregnantReqCrossTypeEnum } from "../api";
import { SymptomStatus } from "../pages/symptom/symptom-status";

export const VETELL_CONST = {
    // vetell constant
    PRIMARY_COLOR: '#0073BC',
}

export type TeamType = "ranch"|"clinic";
export type PageType = "modal"|"content";

export const TAG_SEARCH_SYMBOL = "#";

export const K = { // System Constant
    SERVER_URL: process.env.REACT_APP_API_SERVER_ORIGIN,
    HEADER: {
        AUTHORIZATION: {
            NAME: 'Authorization',
            BEARER: 'Bearer'
        },
        CONTENT_TYPE: {
            NAME: 'Content-Type', // HEADER field name 
            JSON: 'application/json', // JSON
            FORM: 'multipart/form-data', // FORM
        },
        ACCEPT: {
            NAME: 'Accept', // HEADER field name 
            JSON: 'application/json', // JSON
        },
    }
}
export const A = { // All Constants
    PASSWORD: 'pwd',

    GET_SEX_MARK: (is_male: number) => {
        return is_male > 0 ? "♂" : "♀";
    },
    ERR_CODE: {
        INVALID_PARAM: 100,
        ERR_COW_DUP_TRACE_ID: 111,
        INVALID_PERMISSION: 112,
        DELETED: 121,
        ERR_USED_LABEL_NO: 123,
        DUPLICATED: 124,
        ERR_COW_DUP_ID_AND_DELIVERY_CHILD: 125,
        ERR_COW_DUP_DELIVERY_CHILD: 126,
        ERR_USING_DATA: 128,
        CANNOT_UNDO: 129,
        OUTDATED: 199,
        SERVER_ERROR: 503,
    } as const,
    MESSAGE: {
        GO_HOME: "ホーム画面へ戻る",
        INVALID_PERMISSION: "権限がありません",
        NO_INPUT_ID_PWD: 'ユーザーIDとパスワードを入力してください',
        NO_INPUT_USER_ID: 'ユーザーIDを入力してください',
        NO_INPUT_PASSWORD: 'パスワードを入力してください',
        NO_MATCH_ID_PWD: 'ユーザーIDまたはパスワードが間違っています',
        NO_INPUT_USER_NAME: '表示名を入力してください',
        NO_INPUT_EMAIL: 'メールアドレスを入力してください',
        INVALID_EMAIL: 'メールアドレスが正しくありません',
        INVALID_EMAIL_CONFIRM: '再入力のメールアドレスが正しくありません',
        INVALID_PASSWORD_LENGTH: 'パスワードは$1文字以上で入力してください',
        INVALID_PASSWORD_CONFIRM: '再入力のパスワードが正しくありません',
        USER_ALREADY_ADDED: 'すでに登録されているユーザです',
        FAILED_USER_ADD: 'ユーザの登録に失敗しました',
        FAILED_USER_DELETE: 'ユーザの削除に失敗しました',
        SEND_MAIL: 'メールを送信しました。',
        FAILED_MAIL_SEND: 'メールの送信に失敗しました',

        FAILED_TO_LOAD_DATA: 'データの取得に失敗しました',
        FAILED_TO_LOAD_NEW_INFO: '登録後の最新情報取得に失敗しました',
        FAILED_AND_ASK_RELOAD: "エラーが発生しました。\n更新対象の情報が変更されている可能性があります。",

        NO_DATA: "指定されたデータが存在しません",
        SELF_EDIT_ONLY: "記録者が異なるため、この記録を編集することはできません",

        FILE_SAVED: "ファイルが保存されました",

        // team 
        NO_MATCH_TEAM: '一致する組織が見つかりません',
        INVALID_AUTH_DEPENDENCY: (required:string, selected:string) => `「${selected}」は、「${required}」とセットで選択する必要があります`,
        FAILED_TO_SOME_AUTH_UPDATE: '権限の更新に失敗したユーザがあります',
        CLINIC_TEAM_ALREADY_JOINED: '同じユーザで2つ以上の診療所に参加することはできません',
        TEAM_REQUEST_ALREADY_ACCEPTED: 'このユーザは承認済みです',

        // clinic
        NO_MATCH_RANCH: '一致する牧場が見つかりません',
        VISITING_FROM_DUPLICATED: '同じ名前の往診元があります',
        VISITING_RANCH_ALREADY_REGISTERED: '指定された往診先はすでに登録されています',
        VISITING_RANCH_CANT_REGISTER: "この牧場を往診先として登録することはできません",

        // ranch
        NO_SELECTED_COW: '牛を選択してください',
        PERIOD_UNSELECTED: '期間を入力してください',
        PERIOD_INVALID: '期間が正しくありません',
        AGE_INVALID: '月齢の指定が正しくありません',

        // cow
        INVALID_TRACE_ID: 'IDは10桁の数字で入力してください',
        INVALID_COW_PATTERN: '$1 - $2 の組み合わせは選択できません',
        COW_TRACE_ID_DUPLICATED: (id: string) => `ID:${id}の牛はすでに登録されています。\n入力に間違いがないことを確認してください。\nこのまま登録を実行しますか？`,
        COW_DELIVERY_CHILD_DUPLICATED: '指定された分娩に対する子牛はすでに登録されています。\nこのまま登録を実行しますか？',

        // cow-info
        FAILED_TO_LOAD_EVENT: 'イベント記録の取得に失敗しました',

        // feed
        INVALID_COW_IDS: '牛が有効ではありません。',
        INVALID_FEED_TIME: '日時を選択してください',
        INVALID_FEED_DATA: '入力した値が有効ではありません',
        INVALID_FEED_NO: '詳細品目を選択してください',
        INVALID_FEED_DELIVERED: 'あげた量を入力してください',
        INVALID_FEED_LEFTOVER: '残した量があげた量より多く入力されています',
        FEED_ALREADY_ADDED: "同じ品目が既に登録されています",
        NO_LASTDAY_FEED_DATA: '前回の記録がありません',
        INVALID_FEED_RAW_AMOUNT: 'えさの量に大きすぎる値が設定されています。入力値、またはえさ品目の希釈倍率を確認してください。',

        // symptom
        MEDICINE_ALREADY_ADDED: "同じ薬が既に登録されています",
        TREAT_ITEM_ALREADY_ADDED: "同じ項目が既に登録されています",
        NO_PREV_SYMPTOM_DATA: "前回の診療が見つかりません",

        KARTE_CONFIRM_LONG_EXEC: "出力処理に時間がかかることがあります。\nエラーになる場合は一度に出力する内容を減らしてください。",

        // prevention
        INVALID_PREVENTION_DETAILS: '予防処置を入力してください',

        // feed master
        NO_FEED_TYPE_SELECTED: "種別を選択してください",
        NO_FEED_NAME_INPUT: "品名を入力してください",
        NO_FEED_SCALE_INPUT: "希釈倍率を入力してください",
        // treat master
        NO_TREAT_NAME_INPUT: "処置名を入力してください",
        NO_TREAT_UNIT_INPUT: "単位を入力してください",
        TREAT_ITEM_USED_IN_PRESET: "この処置を使用している処置プリセットがあります。\n先に処置プリセットの内容を変更してください。",
        // medicine master
        NO_MEDICINE_CATEGORY_SELECTED: "分類を選択してください",
        NO_MEDICINE_NAME_INPUT: "薬名を入力してください",
        NO_MEDICINE_UNIT_INPUT: "単位を入力してください",
        MEDICINE_USED_IN_PRESET: "この薬品を使用している処置プリセットがあります。\n先に処置プリセットの内容を変更してください。",
        // medicine route master
        NO_MEDICINE_ROUTE_NAME_INPUT: "経路名を入力してください",
        NO_MEDICINE_ROUTE_BASE_FEE_INPUT: "技術料を入力してください",
        NO_MEDICINE_ROUTE_EXTRA_FEE_INPUT: "増加料金の技術料を入力してください",
        NO_MEDICINE_ROUTE_BASE_LIMIT_INPUT: "増加料金の基準量を入力してください",
        NO_MEDICINE_ROUTE_EXTRA_STEP_INPUT: "増加料金の増加量を入力してください",
        MEDICINE_ROUTE_USED_IN_PRESET_OR_MEDICINE: "この経路を初期値として使用している処置プリセットか薬品があります。\n先に処置プリセットまたは薬品の内容を変更してください。",
        MEDICINE_ROUTE_HINT_ONCE_FEE: ["同じ投薬経路で複数の薬を投与した際、","そのすべての投与量を合算し、","1回分の技術料として計算します。"],
        MEDICINE_ROUTE_HINT_PRIOR_FEE: ["同じ記録に、現在編集中の経路と以下で選択した経路の","両方の投薬が含まれる場合、","現在編集中の経路による技術料が発生しません。"],

        // condition master
        NO_CONDITION_NAME_INPUT: "症状名を入力してください",
        NO_CONDITION_DETAIL_INPUT: "詳細項目を入力してください",
        // seed
        NO_SEED_NAME_INPUT: "種雄牛名を入力してください",
        NO_SEED_SNAME_INPUT: "略号を入力してください",
        NO_SEED_ANCESTOR1_INPUT: "一代祖を入力してください",
        NO_SEED_ANCESTOR_PATTERN_INPUT: "一代祖・母牛または一代祖・二代祖・三大祖の組合せで入力してください",
        NO_SEED_GET_FROM_INPUT: "入手先を入力してください",
        NO_SEED_LOT_LOT_NAME_INPUT: "製造Lot No.を入力してください",
        NO_SEED_LOT_STOCK_DAY_INPUT: "仕入日を入力してください",
        LIMIT_OVER_SEED_LOT_STOCK_COUNT: "$1は$2以内で入力してください",
        NO_SEED_LOT_LABEL_FROM_INPUT: "開始ラベルNo.を入力してください",
        INVALID_NUMERIC_SEED_LOT_LABEL_FROM: "開始ラベルNo.には半角数字が含まれている必要があります",

        // schedule
        // NO_SCHEDULE_TITLE: "タイトルを入力してください",
        NO_SCHEDULE_EVENT_KIND: "種別を選択してください",
        INVALID_SCHEDULE_TERM: "期間が有効ではありません",
        FAILED_TO_LOAD_SCHEDULE: "予定の取得に失敗しました",

        // breeding
        BREEDING_LABEL_DUPLICATE: "同じラベルが複数選択されています",
        BREEDING_USED_SEED_EGG: (cross_type_name:string) => `選択された${cross_type_name}は使用済みです`,

        BREEDING_STATE_TO_PREG_WITHOUT_CROSS: "交配記録がありません。繁殖状態を「PREG：妊娠中」へ変更しますか？",
        BREEDING_STATE_TO_FRESH_WITHOUT_DELIVERY: "分娩記録がありません。繁殖状態を「FRESH：分娩後回復期」へ変更しますか？",
        BREEDING_STATE_PREG_TO_OPEN: "繁殖状態が「PREG：妊娠中」です。変更しますか？\n※流産の場合は「分娩記録」から流産を記録できます",
        BREEDING_STATE_PREG_TO_CARE: "繁殖状態が「PREG：妊娠中」です。変更しますか？\n※流産により治療を行う場合は「分娩記録」から流産を記録できます",
        BREEDING_STATE_PREG_TO_CLOSE: "繁殖状態が「PREG：妊娠中」です。変更しますか？\n※流産により今後の繁殖を行わない場合は「分娩記録」から流産を記録できます",

        BREEDING_STATE_NOT_PREG_TO_FRESH: "繁殖状態が「PREG：妊娠中」ではありません。状態を「FRESH：分娩後回復期」へ変更しますか？",
        BREEDING_STATE_NOT_PREG_TO_OPEN: "繁殖状態が「PREG：妊娠中」ではありません。状態を「OPEN：交配待ち/交配中」へ変更しますか？",

        BREEDING_STATE_NO_COMMIT: "現在の繁殖状態は更新されません。変更する場合は牛TOPから状態を選択してください。",
        BREEDING_STATE_COMMIT_FAILED: "繁殖状態の更新に失敗しました。牛TOPで繁殖状態を確認してください。",

        INFO_MEDICINE_DEFAULT_AMOUNT: "体重によらず一律の値となります。\n薬の管理画面から変更が可能です。",

        SELLCOW_GRADE_UNSELECTED: "格付を選択してください",
        SELLCOW_CHANGE_PARTNER: "記録済の個体販売情報の出荷先が変更されます",
        SELLCOW_NO_BREED_NO: "牛の種別が未設定の場合、上物率が正しく集計されません。\n登録を続行しますか？",

        CONFIRM_RANCH_HOUSE_SITE_DELETE: "この分場に属する牛舎・部屋がすべて削除されます。\nここにいる牛は場所未設定の状態になります。\n分場を削除しますか？",
        CONFIRM_RANCH_HOUSE_BARN_DELETE: "この牛舎に属する部屋がすべて削除されます。\nここにいる牛は場所未設定の状態になります。\n牛舎を削除しますか？",
        CONFIRM_RANCH_HOUSE_ROOM_DELETE: "ここにいる牛は場所未設定の状態になります。\n部屋を削除しますか？",

        // Program
        NO_PROGRAM_NAME_INPUT: "プログラム名を入力してください",
        NO_PROGRAM_TRIGGER_EVENT_SELECT: "起動タイミングを選択してください",
        NO_PROGRAM_BASE_DATE_KIND_SELECT: "起算日を選択してください",
        NO_PROGRAM_ITEMS_ADD: "項目を追加してください",
        NO_PROGRAM_ITEM_TITLE_INPUT: "予定タイトルを入力してください",
        NO_PROGRAM_ITEM_EVENT_KIND_SELECT: "種別を選択してください",
        PROGRAM_ITEM_USED_BASE_ITEM: "他の項目の予定日がこの項目を基準としています",
        PROGRAM_ITEM_CIRCULAR: "項目の予定日設定に誤りがあります",
        PROGRAM_TAG_DUPLICATED: "タグの設定に重複があります",

        // egg
        OPU_CANNOT_EDIT: "このOPUに対する媒精が記録済のため、編集できません",
        IVF_CANNOT_EDIT: "この媒精の培養結果が記録済のため、編集できません",

        // preset
        NO_PRESET_NAME_INPUT: "プリセット名を入力してください",
        NO_PRESET_ITEM_INPUT: "処置項目を入力してください",
        CONFIRM_TO_DELETE_PRESET: "プリセットを削除してよろしいですか？",
        CONFIRM_TO_DELETE_PRESET_AGAIN: "このプリセットを使用する予定またはプログラムがあります。\n削除を続行しますか？",
        PRESET_ITEM_DUPLICATED: "同じ処置が複数登録されています",
        PRESET_BENEFIT_IGNORED: "※プリセットに含まれる給付金対象の設定は無視されます",

        // visit fee
        NO_VISIT_FEE_NAME_INPUT: "名称を入力してください",
        VISIT_FEE_ITEM_DUPLICATED: "同じ項目が複数選択されています",
        VISIT_FEE_NO_COW_SELECTED: "対象の牛を選択してください",
        DISTANCE_REQUIRED: "往診距離を入力してください",
        VISIT_FEE_WITHOUT_RECORD: "診療費のみを記録することはできません",

        // robot
        ROBOT_NO_SETTING: "取込実行前に「取込設定」を選択してください",
        ROBOT_FEED_NO_REQUIRED: "えさの品目が設定されていません",
    },

    CRUD: {
        CREATE: 1,
        READ: 2,
        UPDATE: 3,
        DELETE: 4
    },

    SEED_TYPE: {
        'SEED': { seed_type: 1, name: "精液",   unit: "本", label_unit: "ストロー", edit_title: "種雄牛" },
        'EGG':  { seed_type: 2, name: "受精卵", unit: "個", label_unit: "受精卵",   edit_title: "血統" },
    },

    GET_ALL_SEED_TYPES() {
        return Object.keys(A.SEED_TYPE)
                     .map(t => A.SEED_TYPE[t]) as [{ seed_type:number, name:string, unit:string, label_unit:string, edit_title:string }]
    },

    GET_SEED_TYPE(seed_type:number) {
        return this.GET_ALL_SEED_TYPES().find(s => s.seed_type === seed_type);
    },

    SEED_STOCK_STATUS: {
        DEFAULT: 0,
        DISPOSED: 1,
        SOLD: 2,
    },

    /**
     * 牛の用途が繁殖機能の対象となるかどうかを判断
     * （※サーバ側で制限をかける必要ができたらマスタ管理に変更）
     * @param cow_use 用途番号
     */
    IS_FOR_BREEDING_COW(cow_use:number) {
        return this.GET_FOR_BREEDING_USE_NO().includes(cow_use);
    },
    IS_FOR_MILK(cow_use:number) {
        return this.GET_FOR_MILK_USE_NO().includes(cow_use);
    },
    GET_FOR_BREEDING_USE_NO() {
        return [1,3];
    },
    GET_FOR_MILK_USE_NO() {
        return [1];
    },
    MILK_USE_NO: 1,
    FATTENING_USE_NO: 2,
    BREEDING_USE_NO: 3,
    CHILD_USE_NO: 4,
    COW_USE_COLORS: [
        { use_no: 1, color: "#ffa500" },
        { use_no: 2, color: "#2fa765" },
        { use_no: 3, color: "#a83255" },
        { use_no: 4, color: "#0000ff" },
    ] as const,
    SELL_PLANS(cow_use: number): CowSellTypeKey[] {
        if (cow_use === this.MILK_USE_NO || cow_use === this.BREEDING_USE_NO) return ["UPDATE"];
        if (cow_use === this.FATTENING_USE_NO) return ["FAT"];
        if (cow_use === this.CHILD_USE_NO) return ["CHILD", "FAT", "UPDATE"];
        return [];
    },
    SINGLE_SELL_PLAN_NO(cow_use: number): number | undefined {
        const plans = this.SELL_PLANS(cow_use);
        if (plans.length !== 1) return undefined;
        return COW_SELL_TYPE[plans[0]].value;
    },

    HOLSTEIN_BREED_NO: 1,

    DELIVERY_DIFFICULTY: [
        { no: 0, name: "自然分娩", sname: "自然分娩" },
        { no: 1, name: "軽度の介助", sname: "軽度の介助" },
        { no: 2, name: "滑車使用など中等度の牽引", sname: "中等度の牽引" },
        { no: 3, name: "複数人による重度の牽引", sname: "重度の牽引" },
        { no: 4, name: "帝王切開", sname: "帝王切開" },
    ],

    LIFE_STATES: [
        { no: 1, name: "生存", aborted:false },
        { no: 2, name: "胎内死", aborted: true },
        { no: 3, name: "介助中死亡", aborted:false },
        { no: 4, name: "分娩直後死", aborted:false },
    ],

    CROSS_TYPE: new Map<"AI"|"ET"|"HON", {no:number, sname:string, name:string, daysToDelivery:number, pregCertReq: BreedingCertPregnantReqCrossTypeEnum }>([
        ["AI",  { no: 1, sname: "AI", name:"人工授精（AI）", daysToDelivery:280, pregCertReq:BreedingCertPregnantReqCrossTypeEnum.AI } ],
        ["ET",  { no: 2, sname: "ET", name:"受精卵移植（ET）", daysToDelivery:273, pregCertReq:BreedingCertPregnantReqCrossTypeEnum.ET } ],
        ["HON", { no: 3, sname: "本交", name:"本交", daysToDelivery:280, pregCertReq:BreedingCertPregnantReqCrossTypeEnum.NATURAL } ],
    ]),

    RUT_WATCHED_TYPE: {
        "RUT":         { no: 0, name: "発情" },
        "PERHAPS_RUT": { no: 1, name: "発情疑い/発情後" },
    },

    RUT_SIGNS: [ 
        { sign : 1<<0, name: "ST" },
        { sign : 1<<1, name: "MT" },
        { sign : 1<<2, name: "咆哮" },
        { sign : 1<<3, name: "粘液" },
        { sign : 1<<4, name: "活動性up" },
        { sign : 1<<5, name: "排血" },
    ],

    MEDICINE_CATEGORY_BREEDING: 4,
    MEDICINE_CATEGORY_VACCINE: 14,

    START_KIND: new Map<"SELF"|"OUTSIDE", {no:number, name:string}>([
        ["SELF",     { no: 1, name: "自家産" }],
        ["OUTSIDE",  { no: 2, name: "外部導入" }],
    ]),
}

export const checkInvalidCowPattern = (
    breeds:FreezedArray<{ breed_no: number, name: string }>,
    uses: FreezedArray<{ use_no: number, name: string }>,
    isMale?: boolean,
    breedNo?: number,
    useNo?: number
): string | undefined => {

    // 品種と用途の組み合わせチェック
    if ( ( useNo === A.MILK_USE_NO && (breedNo??0) > 0 && breedNo !== A.HOLSTEIN_BREED_NO )
        || ( useNo === A.BREEDING_USE_NO && breedNo === A.HOLSTEIN_BREED_NO ) ) {
        const breed_name = breeds.find(r => r.breed_no === breedNo )?.name ?? "";
        const use_name = uses.find(r => r.use_no === useNo)?.name ?? "";
        return A.MESSAGE.INVALID_COW_PATTERN.replace('$1', breed_name).replace('$2', use_name);
    }

    // 性別と用途の組み合わせチェック
    if( isMale && ( useNo === A.MILK_USE_NO || useNo === A.BREEDING_USE_NO ) ) {
        const use_name = uses.find(r => r.use_no === useNo)?.name ?? "";
        return A.MESSAGE.INVALID_COW_PATTERN.replace('$1', "オス").replace('$2', use_name);
    }
    return undefined;
}

export const isUnusedStock = (stock: RanchSeedStockDto, omitBreedingId?: number, omitIvfId?: number) => {
    if (stock.status === A.SEED_STOCK_STATUS.DISPOSED
        || stock.status === A.SEED_STOCK_STATUS.SOLD) {

        return false;
    }
    if (stock.cross != null && stock.cross.breeding_id !== omitBreedingId) return false;
    if (stock.ivf != null && stock.ivf.event_id !== omitIvfId) return false;

    return true;
}

export const BREEDING_STRUCTURE_KINDS = ['CL1', 'CL2', 'CL3', 'CCL', 'CLCy', 'F1', 'F2', 'F3', 'CyF', 'FCy', 'OV', 'OTHER'] as const;
export type BreedingStructureKind = typeof BREEDING_STRUCTURE_KINDS[number];
export interface IBreedingStructureConst { no:number, name:string, detail:string|null };

export const BREEDING_STRUCTURE: {[key in BreedingStructureKind]:IBreedingStructureConst} = {
    CL1: { no:1, name: "CL1", detail: "黄体:ｽｺｱ1 (～12mm)" },
    CL2: { no:2, name: "CL2", detail: "黄体:ｽｺｱ2 (12～20mm)" },
    CL3: { no:3, name: "CL3", detail: "黄体:ｽｺｱ3 (21mm～)" },
    CCL: { no:4, name: "CCL", detail: "嚢腫様黄体" },
    CLCy: { no:5, name: "CLCy", detail: "黄体嚢腫" },
    F1: { no:6, name: "F1", detail: "卵胞:ｽｺｱ1 (～5mm)" },
    F2: { no:7, name: "F2", detail: "卵胞:ｽｺｱ2 (5～10mm)" },
    F3: { no:8, name: "F3", detail: "卵胞:ｽｺｱ3 (10～15mm)" },
    CyF: { no:9, name: "CyF", detail: "嚢腫様卵胞 (15～20mm)" },
    FCy: { no:10, name: "FCy", detail: "卵胞嚢腫" },
    OV: { no:11, name: "OV", detail: "排卵窩" },
    OTHER: { no:99, name: "その他", detail: null },
};
export const findBreedingStructure = (no:number) => {
    const key = BREEDING_STRUCTURE_KINDS.find(k => BREEDING_STRUCTURE[k].no === no);
    if (key == null) return undefined;
    return BREEDING_STRUCTURE[key];
}

export const SIDES = ['L', 'R'] as const;
export type Side = typeof SIDES[number];
export const BREEDING_STRUCTURE_SIDE: {[key in Side]: number} = {
    L: 1,
    R: 2
};

export const BREEDING_STATES = ["FRESH", "OPEN", "CARE", "PREG", "CLOSE"] as const;
export type BreedingState = typeof BREEDING_STATES[number];
export const BREEDING_STATE: {[key in BreedingState]: { no: number, help: string, hasOpenDay: boolean }} = {
    FRESH: { no: 3, help: "分娩後回復期につき交配しない", hasOpenDay: true },
    OPEN: { no: 0, help: "交配待ち、または交配中", hasOpenDay: false },
    CARE: { no: 2, help: "治療中につき交配一時中止", hasOpenDay: false },
    PREG: { no: 1, help: "妊娠中", hasOpenDay: false },
    CLOSE: { no: 4, help: "繁殖中止につき交配しない", hasOpenDay: false },
}

const SYMPTOM_STATUSES = ["UNDER_TREATMENT","WATCHING","CURED","CANCELED","DIED","CULLED","EPIDEMIC"] as const;
export type SymptomStatus = typeof SYMPTOM_STATUSES[number];
export const SYMPTOM_STATUS: { [key in SymptomStatus]: { no:number, name:string, hasNext?:boolean, epidemic?:boolean, preg?:boolean }} = {
    "UNDER_TREATMENT" : { no: 1,  name: "治療中", hasNext:true },
    "WATCHING"        : { no: 2,  name: "経過観察", hasNext:true },
    "CURED"           : { no: 3,  name: "治癒", preg:true },
    "CANCELED"        : { no: 4,  name: "中止" },
    "DIED"            : { no: 5,  name: "死亡" },
    "CULLED"          : { no: 6,  name: "廃用" },
    "EPIDEMIC"        : { no: 7,  name: "法定", epidemic:true }
} as const;

export const ALL_SYMPTOM_STATUSES = () => SYMPTOM_STATUSES.map(s => SYMPTOM_STATUS[s]);
export const getSymptomStatus = (no: number) => ALL_SYMPTOM_STATUSES().find(s => s.no === no);

export const COW_SELL_TYPES = ["CHILD", "FAT", "UPDATE"] as const;
type CowSellTypeKey = typeof COW_SELL_TYPES[number];
export interface ICowSellType {
    name: string;
    value: number;
    planName: string;
}
export const COW_SELL_TYPE: {[key in CowSellTypeKey]: ICowSellType } = {
    CHILD: { name: "子牛/育成", value: 1, planName:"子牛販売" },
    FAT: { name: "肥育出荷", value: 2, planName:"肥育出荷" },
    UPDATE: { name: "更新", value: 3, planName:"自家保留" },
};
export const findSellTypeName = (sellTypeNo: number) => {
    const key = COW_SELL_TYPES.find(k => COW_SELL_TYPE[k].value === sellTypeNo);
    if (key == null) return undefined;
    return COW_SELL_TYPE[key].name;
}
export const DEFECTS = ["ア","イ","ウ","エ","オ","カ"] as const;
type DefectKey = typeof DEFECTS[number];
export const DEFECT: { [key in DefectKey]: number } = {
    ア: 0x01,
    イ: 0x02,
    ウ: 0x04,
    エ: 0x08,
    オ: 0x10,
    カ: 0x20
};
export const hasDefect = (defect: DefectKey, value:number) => {
    return (DEFECT[defect] & value) !== 0;
}
export const CARCASS_GRADES = ar.flat(["A","B","C"].map(r => ar.intRange(1,5).reverse().map(n => r + n)));

export const RANCH_HOUSE_LEVELS = ['SITE', 'BARN', 'ROOM'] as const;
export type RanchHouseLevelKey = typeof RANCH_HOUSE_LEVELS[number];
export interface IRanchHouseLevel { level:number, name:string, parentLevel?:number, childLevel?:number };

export const RANCH_HOUSE_LEVEL: {[key in RanchHouseLevelKey]:IRanchHouseLevel} = {
    SITE: { level: 1, name: "分場", childLevel: 2 },
    BARN: { level: 2, name: "牛舎", parentLevel: 1, childLevel: 3 },
    ROOM: { level: 3, name: "部屋", parentLevel: 2 },
} as const;
export const findRanchHouseLevel = (level:number):IRanchHouseLevel|undefined => {
    const key = RANCH_HOUSE_LEVELS.find(k => RANCH_HOUSE_LEVEL[k].level === level);
    if (key == null) return undefined;
    return RANCH_HOUSE_LEVEL[key];
}

export const WASHOUT_ITEM_TYPES = ['FEEDING', 'TREAT'] as const;
export type WashoutItemTypeKey = typeof WASHOUT_ITEM_TYPES[number];
export interface IWashoutItemType { item_type:number, name:string };

export const WASHOUT_ITEM_TYPE: {[key in WashoutItemTypeKey]:IWashoutItemType} = {
    FEEDING: { item_type: 1, name: "えさ" },
    TREAT:   { item_type: 2, name: "薬" },
};
export const findWashoutItemType = (item_type:number):IWashoutItemType|undefined => {
    const key = WASHOUT_ITEM_TYPES.find(k => WASHOUT_ITEM_TYPE[k].item_type === item_type);
    if (key == null) return undefined;
    return WASHOUT_ITEM_TYPE[key];
}

export const TIMESPAN = {
    AM: { FROM: "8:00", TO: "12:00" },
    PM: { FROM: "13:00", TO: "17:00" }
} as const;

export const TIMEPRESETS = [
    { name:"朝", time:"08:00" },
    { name:"昼", time:"12:00" },
    { name:"夕", time:"17:00" }
] as const;

export const COW_END_KIND_KEYS = [ "SOLD", "DEAD", "LEFT" ] as const;
export const COW_END_KIND: { [key in typeof COW_END_KIND_KEYS[number] ]:{ no:number, name:string }} = {
    SOLD: { no: 1, name: "出荷" },
    DEAD: { no: 2, name: "死亡" },
    LEFT: { no: 3, name: "退牧" },
} as const;

export const DYNAMICS_SETTING_ITEM_KIND = {
    ACCIDENT_PERCENT: { no:1, name:"事故率目標", unit: "%", step:0.1, max:100, min:0 }
} as const;
export type DynamicsSettingItemKindKey = keyof typeof DYNAMICS_SETTING_ITEM_KIND;
export const DYNAMICS_SETTING_ITEM_KIND_KEYS = Object.keys(DYNAMICS_SETTING_ITEM_KIND) as DynamicsSettingItemKindKey[];
export type DynamicsSettingItemKindValue = typeof DYNAMICS_SETTING_ITEM_KIND[DynamicsSettingItemKindKey];

const DISEASE_ITEM_STATUSES = [ "HIDDEN", "VISIBLE", "FAVORITE" ] as const;
export const DISEASE_ITEM_STATUS: { [key in typeof DISEASE_ITEM_STATUSES[number] ]:{ no: number }} = {
    HIDDEN: { no: 0 },
    VISIBLE: { no: 1 },
    FAVORITE: { no: 2 }
};

export type DiseaseCategory = { no: number, name: string };
export const DISEASE_CATEGORIES: FreezedArray<DiseaseCategory> = [
    { no:  1, name: "循環器病" },
    { no:  2, name: "血液及び造血器病" },
    { no:  3, name: "呼吸器病" },
    { no:  4, name: "消化器病" },
    { no:  5, name: "泌尿器病" },
    { no:  6, name: "生殖器病" },
    { no:  7, name: "泌乳器病" },
    { no:  8, name: "妊娠・分娩期及び産後の疾患" },
    { no:  9, name: "新生子異常" },
    { no: 10, name: "神経系病" },
    { no: 11, name: "感覚器(眼、耳)病" },
    { no: 12, name: "内分泌及び代謝疾患" },
    { no: 13, name: "運動器病" },
    { no: 14, name: "皮膚病" },
    { no: 15, name: "中毒" },
    { no: 16, name: "ウイルス病" },
    { no: 17, name: "細菌・真菌病" },
    { no: 18, name: "原虫・寄生虫病" },
    { no: 19, name: "外傷不慮その他" },
] as const;

export const TAG_ACTION = {
    ADD: 1,
    REM: 0
} as const;

export const EVENT_TYPE_PARAM = {
    FEED: 0,
    SYMPTOM: 1,
    BREEDING: 2
};

export const TREAT_BENEFIT_TYPES = ["BENEFIT","NO_BENEFIT","UNLISTED"] as const;
export type TreatBenefitType = typeof TREAT_BENEFIT_TYPES[number];
export const TREAT_BENEFIT_TYPE: { [key in TreatBenefitType ]: { no: number, name: string } } = {
    BENEFIT: { no: 1, name:"給付金対象" },
    NO_BENEFIT: { no: 0, name:"給付金対象外" },
    UNLISTED: { no: -1, name:"カルテ記載対象外" },
} as const;
export const convertBenefitType = (no: number) => {
    return TREAT_BENEFIT_TYPES.find(key => TREAT_BENEFIT_TYPE[key].no === no) ?? "NO_BENEFIT";
}
export const getDefaultBenefitType = (isClinicUser: boolean): TreatBenefitType => isClinicUser ? "BENEFIT" : "NO_BENEFIT";

export const VISIT_FEE_TYPES = ["FIRST","VISITING","CAR"] as const;
export type VisitFeeType = typeof VISIT_FEE_TYPES[number];
export const VISIT_FEE_TYPE: { [key in VisitFeeType]: { no:number, name:string, selectableCount:number, oneCow?:boolean, hasPoint?:boolean, hasStep?:boolean, hasBenefit?:boolean }} = {
    FIRST:    { no: 1, name: "初診料", selectableCount:1, hasPoint:true, hasBenefit:true },
    VISITING: { no: 2, name: "往診料", selectableCount:4, oneCow:true, hasPoint:true, hasBenefit:true, hasStep:true },
    CAR:      { no: 3, name: "車代", selectableCount:1, oneCow:true  }
} as const;

export const ROBOT_KINDS = ["LELY_T4C"] as const;
export type RobotKind = typeof ROBOT_KINDS[number];
export const ROBOT_KIND: { [key in RobotKind]: { no:number, name:string, hint?:Readonly<string[]> }} = {
    LELY_T4C: { no: 1, name: "Lely T4C", hint:["搾乳訪問記録をExcel形式で出力し、","CSVに変換したファイルを使用してください。","（文字コード：Shift_JIS）"] }
} as const;

export const PROCESS_KINDS = ["IMPORT_MILK","IMPORT_FEEDING","IMPORT_WEIGHT"] as const;
export type ProcessKind = typeof PROCESS_KINDS[number];
export const PROCESS_KIND: { [key in ProcessKind]: { no:number,name:string,isRobotImport:boolean }} = {
    IMPORT_MILK: { no: 1, name: "乳量", isRobotImport:true },
    IMPORT_FEEDING: { no: 2, name: "えさ", isRobotImport:true },
    IMPORT_WEIGHT: { no: 3, name: "体重", isRobotImport:true },
} as const;

export const PROCESS_STATUSES = ["EXECUTING", "SUCCESS", "FAILED", "SKIP"] as const;
export type ProcessStatus = typeof PROCESS_STATUSES[number];
export const PROCESS_STATUS: { [key in ProcessStatus]: { no:number, name:string }} = {
    EXECUTING: { no:0, name:"実行中" },
    SUCCESS: { no:1, name:"成功" },
    FAILED: { no:2, name:"失敗" },
    SKIP: { no:3, name:"スキップ" },
} as const;


export const LMT = {
    RANCH: {
        NAME_LEN: 50,
        HOUSE_NAME_LEN: 20,
        ADDRESS_LEN: 100,
        TEL_LEN: 20,
        UNOFFICIAL_COUNT_MAX: 30
    },
    USER: {
        NAME_LEN: 30,
        LICENSE_ID_LEN: 30
    },
    COW: {
        NAME_LEN: 50,
        LOCAL_NO_LEN: 20,
        ACCEPT_PRICE_MAX: 9999999,
        ACCEPT_PRICE_MIN: 0,
        ACCEPT_PRICE_STEP: 1,
        MEMO_LEN: 4000,
        WATCHING_MEMO_LEN: 200,
        AGE_M_MAX: 999,
        AGE_M_MIN: 0,
        AGE_M_STEP: 1,
        AGE_D_MAX: 365,
        AGE_D_MIN: 0,
        AGE_D_STEP: 1,
        UNOFFICIAL_COUNT_MAX: 500,
        FEATURE_LEN: 100,
        FEATURE_HIS_COUNT : 5,
        FILTER_NAME_LEN: 50,
        FILTER_COUNT_MAX: 50,
    },
    SCHEDULE: {
        TITLE_LEN: 100,
        MEMO_LEN: 1200,
    },
    NOTE: {
        TEXT_LEN: 200
    },
    TREAT: {
        NAME_LEN: 50,
        CODE_LEN: 10,
        POINT_MAX: 9999,
        POINT_MIN: 0,
        POINT_STEP: 1,
        FEE_MAX: 99999,
        FEE_MIN: 0,
        FEE_STEP: 1,
        UNIT_LEN: 10,
        MEMO_LEN: 4000,
    },
    MEDICINE: {
        NAME_LEN: 50,
        UNIT_LEN: 10,
        UNIT_PRICE_MIN: 0,
        UNIT_PRICE_MAX: 99999.99,
        UNIT_PRICE_STEP: 0.01,
        AMOUNT_MAX: 9999.99,
        AMOUNT_MIN: 0,
        AMOUNT_STEP: 0.01,
        EXTRA_FEE_BASELINE_MAX: 9999,
        EXTRA_FEE_BASELINE_MIN: 0,
        EXTRA_FEE_BASELINE_STEP: 1,
        EXTRA_FEE_STEP_MAX: 9999,
        EXTRA_FEE_STEP_MIN: 1,
        EXTRA_FEE_STEP_STEP: 1,
    },
    TREAT_PRESET: {
        NAME_LEN: 50,
    },
    SEED: {
        STOCK_MEMO_LEN: 400,
        LOT_NO_LEN: 20,
        LOT_MEMO_LEN: 200,
        STOCK_PRICE_MIN: 0,
        STOCK_PRICE_MAX: 999999,
        STOCK_PRICE_STEP: 1,
        STOCK_COUNT_MAX: 500,
        STOCK_COUNT_MIN: 1,
        STOCK_LABEL_LEN: 20,
        SNAME_LEN: 10,
        GET_FROM_LEN: 20,
    },
    FEEDING: {
        NAME_LEN: 30,
        AMOUNT_MIN: 0,
        AMOUNT_MAX: 9999.9,
        AMOUNT_STEP: 0.1,
        AMOUNT_RAW_MAX: 9999.9,
        MEMO_LEN: 4000,
        UNIT_PRICE_MAX: 99999.99,
        CONVERT_SCALE_MIN: 1,
        CONVERT_SCALE_MAX: 15,
        CONVERT_SCALE_STEP: 0.1
    },
    BREEDING: {
        MEMO_LEN: 4000,
        ITEM_MEMO_LEN: 200,
        CROSS_RUT_SEARCH_DAYS: 14,
        PREG_CHECK_DESCRIPTION_LEN: 100,
        PREG_CHECK_DESCRIPTION_HIS_COUNT : 5
    },
    DELIVERY: {
        MEMO_LEN: 4000,
        WEIGHT_MIN: 0,
        WEIGHT_MAX: 99.9,
        WEIGHT_STEP: 0.1
    },
    RUT: {
        MEMO_LEN: 4000
    },
    PREVENTION: {
        MEMO_LEN: 4000,
        LOT_NO_LEN: 50
    },
    SYMPTOM: {
        MEMO_LEN: 4000,
        CONDITION_NAME_LEN: 30,
        CONDITION_CLASS_LEN: 30,
        SYMPTOM_NAME_LEN: 50,
        NOTICE_LEN: 30,
        DISEASE_COUNT_MAX: 4,
        SYMPTOM_NAME_HIS_COUNT: 8,
        NOTICE_HIS_COUNT: 10,
    },
    SELLCOW: {
        REWARD_LEN: 20,
        MEMO_LEN: 4000,
        PRICE_MAX: 9999999,
        PRICE_MIN: 0,
        PRICE_STEP: 1,
        UNIT_PRICE_MAX: 99999,
        UNIT_PRICE_MIN: 0,
        UNIT_PRICE_STEP: 1,
        WEIGHT_MAX: 999.9,
        WEIGHT_MIN: 0,
        WEIGHT_STEP: 0.1,
        LOIN_MAX: 299.9,
        LOIN_MIN: 0,
        LOIN_STEP: 0.1,
        RIB_MAX: 49.9,
        RIB_MIN: 0,
        RIB_STEP: 0.1,
        FAT_MAX: 49.9,
        FAT_MIN: 0,
        FAT_STEP: 0.1,
        BASIS_MAX: 99.9,
        BASIS_MIN: 0,
        BASIS_STEP: 0.1,
        OLEIN_MAX: 100,
        OLEIN_MIN: 0,
        OLEIN_STEP: 0.1
    },
    SELLMILK: {
        MEMO_LEN: 4000,
        UNIT_PRICE_MIN: 0,
        UNIT_PRICE_MAX: 999.9,
        UNIT_PRICE_STEP: 0.1,
        AMOUNT_COW_MIN: 0,
        AMOUNT_COW_MAX: 999.9,
        AMOUNT_COW_STEP: 0.1,
        AMOUNT_RANCH_MIN: 0,
        AMOUNT_RANCH_MAX: 999999.9,
        AMOUNT_RANCH_STEP: 0.1,
    },
    OTHER_PL: {
        MEMO_LEN: 4000,
        PRICE_MAX: 9999999,
        PRICE_MIN: 0,
        PRICE_STEP: 1
    },
    WASHOUT: {
        MEAT_DAY_MAX: 150,
        MILK_HOUR_MAX: 240
    },
    PROGRAM: {
        NAME_LEN: 50
    },
    PARTNER: {
        NAME_LEN: 100
    },
    GROWTH: {
        WEIGHT_MIN: 1,
        WEIGHT_MAX: 1500,
        WEIGHT_STEP: 1
    },
    SOV: {
        MEMO_LEN: 2000,
        MAX_EGG_COUNT: 99,
        AI_SEARCH_DAYS: 21
    },
    OPU: {
        MEMO_LEN: 2000,
        MAX_EGG_COUNT: 99
    },
    IVF: {
        MEMO_LEN: 2000,
        OPU_SEARCH_DAYS: 3
    },
    IVF_EGG: {
        MEMO_LEN: 2000,
        IVF_SEARCH_DAYS: 10
    },
    TIME_PRESET: {
        MAX_NAME_LEN: 10,
        MAX_COUNT: 5
    },
    VISIT_FEE: {
        NAME_LEN: 50,
        CODE_LEN: 10,
        FEE_MIN: 0,
        FEE_MAX: 99999,
        FEE_STEP: 1,
        POINT_MIN:0,
        POINT_MAX:9999,
        POINT_STEP:1,
        EXTRA_STEP_COUNT_MAX: 5,
        DISTANCE_MIN:0,
        DISTANCE_MAX:999.9,
        DISTANCE_STEP:0.1
    },
    CERT: {
        VACCINE_MAX_COWS: 50,
        DISEASE_MAX_COWS: 50,
        KARTE_MAX_COWS: 10,
        KARTE_CONFIRM_EVENT_COUNT: 50,
        VACCINE_MAX_COUNT_PER_COW: 2,
        VACCINE_MAX_MONTH_SPAN: 11,
        VACCINE_MAX_BACK_MONTH: 24,
        DISEASE_MAX_MONTH_SPAN: 1,
        DISEASE_MAX_BACK_MONTH: 24,
        KARTE_MAX_MONTH_SPAN: 2,
        KARTE_MAX_BACK_MONTH: 24,
        CLINIC_ADDRESS_HIS_COUNT: 3,
        CLINIC_NAME_HIS_COUNT: 3,
        RECEIVER_HIS_COUNT: 5,
        RECEIVER_LEN: 50,
    },
    ROBOT: {
        MIN_CSV_SIZE_B: 1,
        MAX_CSV_SIZE_B: 10 * 1000 * 1000,
        DESTS_ALL_MAX_LEN: 100,
    }

} as const;