export const PROGRAM_TRIGGER_KINDS = ["EVENT","EACH_TIME","MANUAL"] as const;
export type ProgramTriggerKind = {
    no:number,
    name:string,
    asRanch:boolean,
    asClinic:boolean,
    hasTriggerEvent:boolean,
    defaultInRanch?:boolean,
    defaultInClinic?:boolean,
    autoApply?: { description:string, default: 0|1, help?:Readonly<string[]> }
};
export const PROGRAM_TRIGGER_KIND: { [key in typeof PROGRAM_TRIGGER_KINDS[number]]: ProgramTriggerKind } = {
    EVENT:     { no:1, asRanch:true,  asClinic:false, hasTriggerEvent:true,  name:"イベント記録", defaultInRanch:true, autoApply: { description:"新しい牛に自動で適用", default:0 } },
    EACH_TIME: { no:3, asRanch:false, asClinic:true,  hasTriggerEvent:true,  name:"イベント記録", defaultInClinic:true, autoApply: { description:"有効", default:1, help:["「有効」にしておくと、条件に一致するイベント記録時に","このプログラムの適用を選択することができます"] } },
    MANUAL:    { no:2, asRanch:true,  asClinic:true,  hasTriggerEvent:false, name:"手動" },
} as const;


export const PROGRAM_BASE_DATES = [
    "BIRTHDAY",
    "ACCEPT_DAY",
    "PREVENTION",
    "SYMPTOM",
    "RUT",
    "BREEDING",
    "CROSS",
    "DELIVERY",
    "LAST_CROSS",
    "LAST_DELIVERY"
] as const;
export type ProgramBaseDateKey = typeof PROGRAM_BASE_DATES[number];

interface IProgramBaseDate {
    kindNo: number;
    name: string;
}

export const PROGRAM_BASE_DATE: { [ key in ProgramBaseDateKey]: IProgramBaseDate } = {
    BIRTHDAY        : { kindNo: 1, name: "生年月日" },
    ACCEPT_DAY      : { kindNo: 2, name: "導入日" },
    PREVENTION      : { kindNo: 3, name: "予防処置日" },
    SYMPTOM         : { kindNo: 3, name: "診療日" },
    RUT             : { kindNo: 3, name: "発情観察日" },
    BREEDING        : { kindNo: 3, name: "検診日" },
    CROSS           : { kindNo: 3, name: "検診日（交配日）" },
    DELIVERY        : { kindNo: 3, name: "分娩日" },
    LAST_CROSS      : { kindNo: 4, name: "直前の交配日" },
    LAST_DELIVERY   : { kindNo: 5, name: "直前の分娩日" },
}



export const PROGRAM_TRIGGERS = [
    "COW",
    "PREVENTION",
    "SYMPTOM",
    "RUT",
    "BREEDING",
    "CROSS",
    "DELIVERY",
] as const;

export type ProgramTriggerKey = typeof PROGRAM_TRIGGERS[number];

interface IProgramTrigger {
    no: number;
    name: string;
    baseDates: ProgramBaseDateKey[];
}

export const PROGRAM_TRIGGER: { [key in ProgramTriggerKey]: IProgramTrigger } = {
    COW:                { no: 1, name: "牛情報登録",             baseDates: ["BIRTHDAY", "ACCEPT_DAY"] },
    PREVENTION:         { no: 2, name: "予防記録",               baseDates: ["PREVENTION"] },
    SYMPTOM:            { no: 3, name: "診療記録",               baseDates: ["SYMPTOM"] },
    RUT:                { no: 4, name: "発情記録",               baseDates: ["RUT"] },
    BREEDING:           { no: 5, name: "検診記録",               baseDates: ["BREEDING","LAST_CROSS","LAST_DELIVERY"] },
    CROSS:              { no: 6, name: "交配記録",               baseDates: ["CROSS", "LAST_DELIVERY"] },
    DELIVERY:           { no: 7, name: "分娩記録",               baseDates: ["DELIVERY", "LAST_CROSS"] },
}


export class ProgramKind {
    static readonly allTriggerKinds = Object.values(PROGRAM_TRIGGER_KIND)

    static findTriggerKind = (no:number) => {
        return ProgramKind.allTriggerKinds.find(t => t.no === no);
    }

    static readonly allTriggers = Object.values(PROGRAM_TRIGGER);

    static findTrigger = (no:number) => {
        return ProgramKind.allTriggers.find(t => t.no === no);
    }

    static getTriggerKey = (no:number) => {
        const entry = Object.entries(PROGRAM_TRIGGER).find(([k, v]) => v.no === no);
        if (entry == null) return undefined;
        return entry[0] as ProgramTriggerKey;
    }

    static getBaseDates = (trigger: ProgramTriggerKey) => {
        return PROGRAM_TRIGGER[trigger].baseDates.map(b => PROGRAM_BASE_DATE[b]);
    }

    static getBaseDateKindName = (triggerNo:number, baseDateKind:number) => {
        const trigger = ProgramKind.findTrigger(triggerNo);
        if (trigger == null) return undefined;

        const kind = trigger.baseDates.find(b => PROGRAM_BASE_DATE[b].kindNo === baseDateKind);
        if (kind == null) return undefined;
        return PROGRAM_BASE_DATE[kind].name;
    }
}
