import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { PageSettings } from '../../config/page-settings.js';
import { ISeedLot, ISeedStock, ISeedStockCross } from '../../stores/RootStore';
import styles from './seed-management-stock-popup.module.css';
import classnames from 'classnames';
import { CommonUtil, formatYen, FreezedArray, ar } from '../../config/util';
import { RanchApi, RanchSeedStockModifyReq, CowApi, CowListReq, CowListDto, ClinicApi, RanchSeedStockIvfDto, RanchTagDto, TeamSeedTagModifyReq, TeamApi, TeamSeedTagModifyReqStock } from '../../api/index';
import moment from 'moment';
import { CowToDispInfo } from '../../components/parts/cows-popup';
import { SelectCowsPopup } from '../cow/select-cows-popup';
import { A, TeamType, LMT, isUnusedStock, EVENT_TYPE_PARAM } from '../../config/constant';
import { BreedingWriteSeedParam } from '../breeding/breeding-write';
import { historyLocation } from '../../config/history-location-builder';
import { AppState } from '../../app';
import { SellSeedPopup, SellSeedValues } from './sell-seed-popup';
import { Communicator } from '../../api/communicator';
import { IconLink } from '../../components/parts/icon-link';
import { IIvfWriteLocationState } from '../egg/ivf-write';
import { Checkbox } from '../../components/form/form-checkbox';
import { FormRadio } from '../../components/form/form-radio';
import { SeedTagPopup } from './seed-tag-popup';
import { pickLastNum } from './seed-label-range-input';
import { DIALOG_BUTTONS } from 'components/form/form-dialog';

interface MyProps {
    seed_type: number;
    data: ISeedLot;
    onClose: ()=>void;
    user_id: string;
    team_id: number;
    teamType: TeamType;
    onUpdated: ()=>void;
    onLink: (ranch_id: number | undefined, location:{pathname:string,search?:string,state?:any})=>void;
    comm: Communicator;
    allTags: FreezedArray<RanchTagDto>;
}

interface MyState {
    selectedStock?: ISeedStock;
    selectedStockLabels: Set<string>;
    isCowSelectShown: boolean;
    selectedCow?: CowListDto;
    cows: CowListDto[];
    isSellingNewSeed: boolean;
    isEditingSoldSeed: boolean;
    executing: boolean;
    filteringTags: FreezedArray<RanchTagDto>;
    isTagEditShown: boolean;
    dispList: FreezedArray<ISeedStock>;
}

export class SeedManagementStockPopup extends React.Component<MyProps,MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            isCowSelectShown: false,
            cows: [],
            isSellingNewSeed: false,
            isEditingSoldSeed: false,
            executing: false,
            selectedStockLabels: new Set(),
            filteringTags: [],
            isTagEditShown: false,
            dispList: []
        }

        this.onReuseClick = this.onReuseClick.bind(this);
        this.onDisposeClick = this.onDisposeClick.bind(this);
        this.onClinicSellClick = this.onClinicSellClick.bind(this);
        this.onCrossLinkClick = this.onCrossLinkClick.bind(this);
        this.onIvfLinkClick = this.onIvfLinkClick.bind(this);
        this.onSoldLinkClick = this.onSoldLinkClick.bind(this);
        this.onIvfWriteClick = this.onIvfWriteClick.bind(this);
        this.onCrossWriteClick = this.onCrossWriteClick.bind(this);
        this.onSelectCowClick = this.onSelectCowClick.bind(this);
        this.onSellSeedClick = this.onSellSeedClick.bind(this);
        this.onSellSeedSubmit = this.onSellSeedSubmit.bind(this);
        this.onSellSeedDelete = this.onSellSeedDelete.bind(this);
    }

    componentDidMount() {
        this.buildDispList();
    }
    componentDidUpdate(prevProps: this["props"], prevState: MyState) {
        if (prevProps.data.stocks !== this.props.data.stocks
            || prevState.filteringTags !== this.state.filteringTags) {
            this.buildDispList();
        }
        if (this.state.selectedStockLabels !== prevState.selectedStockLabels) {
            const labs = [...this.state.selectedStockLabels.values()];
            this.setState({
                selectedStock: labs.length === 1 ? this.props.data.stocks.find(s => s.label_no === labs[0]) : undefined
            })
        }
    }

    private buildDispList() {
        const dispList = this.props.data.stocks.filter(s => this.state.filteringTags.every(ft => s.tags.some(t => t.tag_id === ft.tag_id)));
        const dispLabels = new Set(dispList.map(d => d.label_no));
        const selected = [...this.state.selectedStockLabels].filter(l => dispLabels.has(l));
        this.setState({
            dispList: ar.orderBy(dispList, d => pickLastNum(d.label_no) ?? 0),
            selectedStockLabels: new Set(selected)
        });
    }

    private onRowClick(stock: ISeedStock) {
        this.setState({
            selectedStockLabels: new Set([stock.label_no])
        })
    }
    private onRowCheck(stock: ISeedStock, checked: boolean) {
        const newSet = new Set(this.state.selectedStockLabels);
        if (checked) {
            newSet.add(stock.label_no);
        } else {
            newSet.delete(stock.label_no);
        }
        this.setState({
            selectedStockLabels: newSet,
        })
    }
    

    private onReuseClick() {
        const stock = this.state.selectedStock;
        if (!CommonUtil.assertNotNull(stock, "selectedStock", "reuse")) return;

        this.updateStock({
            status: A.SEED_STOCK_STATUS.DEFAULT,
            label_no: stock.label_no,
            seed_lot_id: this.props.data.seed_lot_id,
        });
    }
    private async onDisposeClick(note: string) {
        const stock = this.state.selectedStock;
        if (!CommonUtil.assertNotNull(stock, "selectedStock", "dispose")) return;

        const confirm = await this.context.showDialog("QUESTION", `${stock.label_no}を廃棄してよろしいですか？`,
                        [ { type:"save", text:"廃棄" }, { type:"cancel" } ]);
        if (confirm !== 0) return;

        this.updateStock({
            status: A.SEED_STOCK_STATUS.DISPOSED,
            label_no: stock.label_no,
            seed_lot_id: this.props.data.seed_lot_id,
            note,
        });
    }
    private async onClinicSellClick(note: string) {
        const stock = this.state.selectedStock;
        if (!CommonUtil.assertNotNull(stock, "selectedStock", "clinic sell")) return;

        const confirm = await this.context.showDialog("QUESTION", `${stock.label_no}を売却してよろしいですか？`,
                        [{ type:"save", text:"売却" }, { type:"cancel" }]);
        if (confirm !== 0) return;

        this.updateStock({
            status: A.SEED_STOCK_STATUS.SOLD,
            label_no: stock.label_no,
            seed_lot_id: this.props.data.seed_lot_id,
            note,
            price: 0
        });
    }
    private async updateStock(req: Omit<RanchSeedStockModifyReq, "ranch_id">) {
        this.context.handleSetIsLoading(true);

        const postSome = this.props.teamType === "ranch"
                  ? (await RanchApi()).modifySeedStockUsingPOST({...req, ranch_id: this.props.team_id})
                  : (await ClinicApi()).modifyClinicSeedStockUsingPOST({...req, clinic_id: this.props.team_id});
        const res = await this.props.comm.send(postSome);

        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") return false;

        this.props.onUpdated();
        this.setState({
            selectedStockLabels: new Set(),
            isSellingNewSeed: false,
            isEditingSoldSeed: false
        });
        return true;
    }

    private onCrossLinkClick() {
        const stock = this.state.selectedStock;
        if (!CommonUtil.assertNotNull(stock, "selectedStock", "crossLink")) return;
        if (!CommonUtil.assertNotNull(stock.cross, "cross", "crossLink")) return;

        const ranchId = stock.cross.ranch_id;
        const location = historyLocation.toCowEvent(
            stock.cross.cow_id,
            moment(stock.cross.watched_at).format("YYYY-MM-DD"),
            EVENT_TYPE_PARAM.BREEDING);

        this.props.onLink(ranchId, location);
    }
    private onIvfLinkClick() {
        const stock = this.state.selectedStock;
        if (!CommonUtil.assertNotNull(stock, "selectedStock", "ivfLink")) return;
        if (!CommonUtil.assertNotNull(stock.ivf, "ivf", "ivfLink")) return;

        const ranchId = stock.ivf.ranch_id;
        const location = historyLocation.toCowEvent(
            stock.ivf.cow_id,
            moment(stock.ivf.watched_at).format("YYYY-MM-DD"),
            EVENT_TYPE_PARAM.BREEDING);

        this.props.onLink(ranchId, location);
    }
    private onSoldLinkClick() {
        this.setState({ isEditingSoldSeed: true });
    }
    private onSellSeedClick() {
        this.setState({ isSellingNewSeed: true });
    }
    private async onSellSeedSubmit(data: SellSeedValues) {
        const stock = this.state.selectedStock;
        if (!CommonUtil.assertNotNull(stock, "selectedStock", "sellSeedSubmit")) return;

        this.setState({ executing: true });
        await this.updateStock({
            label_no: stock.label_no,
            seed_lot_id: this.props.data.seed_lot_id,
            note: data.note,
            price: data.price,
            watched_at: moment(data.soldAt).format("YYYY-MM-DD HH:mm"),
            status: A.SEED_STOCK_STATUS.SOLD
        });
        this.setState({ executing: false });
    }
    private async onSellSeedDelete() {
        const stock = this.state.selectedStock;
        if (!CommonUtil.assertNotNull(stock, "selectedStock", "onSellSeedDelete")) return;

        const confirm = await this.context.showDialog("QUESTION", `${stock.label_no}の売却記録を削除しますか？`, DIALOG_BUTTONS.DELETE_CANCEL);
        if (confirm !== 0) return;

        this.updateStock({
            status: A.SEED_STOCK_STATUS.DEFAULT,
            label_no: stock.label_no,
            seed_lot_id: this.props.data.seed_lot_id,
        });
    }

    private onCrossWriteClick() {
        const stock = this.state.selectedStock;
        if (!CommonUtil.assertNotNull(stock, "selectedStock", "cross write")) return;
        const cow = this.state.selectedCow;
        if (!CommonUtil.assertNotNull(cow, "selectedCow", "cross write")) return;

        const crossState: BreedingWriteSeedParam = {
            cow_id: cow.cow_id,
            cross_type: this.props.seed_type === A.SEED_TYPE.SEED.seed_type ? A.CROSS_TYPE.get("AI")!.no : A.CROSS_TYPE.get("ET")!.no,
            seed_lot_id: this.props.data.seed_lot_id,
            label_no_1: stock.label_no,
        };

        //※診療所マスタから交配記録入力への遷移は現状未対応のため、ranchIdはマスタのteamIDと同じケースのみ
        const ranchId = undefined;
        const hisLocation = historyLocation.toBreedingWrite({ param: cow.cow_id }, { cows:[], seed: crossState });
        this.props.onLink(ranchId, hisLocation);
    }
    private onIvfWriteClick() {
        const stock = this.state.selectedStock;
        if (!CommonUtil.assertNotNull(stock, "selectedStock", "ivf write")) return;
        const cow = this.state.selectedCow;
        if (!CommonUtil.assertNotNull(cow, "selectedCow", "ivf write")) return;

        const ivfState: IIvfWriteLocationState = {
            cows: [ { ...cow, use_no:cow.use_no, breed_no:cow.breed_no } ],
            seed_lot: { id: this.props.data.seed_lot_id, label_no:stock.label_no }
        };

        //※診療所マスタから入力への遷移は現状未対応のため、ranchIdはマスタのteamIDと同じケースのみ
        const ranchId = undefined;
        const hisLocation = historyLocation.toIvfWrite(ivfState);
        this.props.onLink(ranchId, hisLocation);
    }

    private async onSelectCowClick() {
        let cows: CowListDto[] | undefined;
        if (this.state.cows.length === 0) {
            this.context.handleSetIsLoading(true);

            const req: CowListReq = {
                user_id: this.props.user_id,
                ranch_id: this.props.team_id,
                is_active: 1,
                count: 1000,
                page: 1,
            };
            const res = await this.props.comm.send((await CowApi()).getListUsingPOST(req));
            this.context.handleSetIsLoading(false);
            if (res.result !== "OK") return;
    
            cows = (res.data?.list ?? [])
                    .filter(dao => dao.cow_id != null && dao.trace_id != null && A.IS_FOR_BREEDING_COW(dao.use_no ?? 0));
        }

        this.setState({
            isCowSelectShown: true,
            cows: cows ?? this.state.cows
        });
    }

    onCowSelected({ cow_id }: { cow_id: number }) {
        const cow = this.state.cows.find(c => c.cow_id === cow_id);
        if (!CommonUtil.assertNotNull(cow, "cow", "onCowSelect")) return;

        this.setState({selectedCow:cow, isCowSelectShown:false})
    }

    async onTagSubmit(stocks: { label_no: string; tags: { tag_id: number | undefined; tag_name: string; }[]; }[]) {

        const toReqStock = (st: { label_no: string; tags: { tag_id: number | undefined; tag_name: string; }[] }): TeamSeedTagModifyReqStock => {
            return {
                label_no: st.label_no,
                tags: st.tags.map(t => ({ id:t.tag_id, name:t.tag_name }))
            }
        }

        const req: TeamSeedTagModifyReq = {
            team_id: this.props.team_id,
            seed_lot_id: this.props.data.seed_lot_id,
            stocks: stocks.map(toReqStock)
        };
        this.setState({ executing: true });
        this.context.handleSetIsLoading(true);
        const res = await this.props.comm.send((await TeamApi()).modifySeedTagUsingPOST(req));
        this.context.handleSetIsLoading(false);

        if (res.result !== "OK") {
            this.setState({ executing: false });
            return;
        }

        this.props.onUpdated();

        this.setState({
            isTagEditShown: false,
            executing: false
        })
    }


    render() {
        const buildUsedEventText = (evt: ISeedStockCross | RanchSeedStockIvfDto) => {
            return `${moment(evt.watched_at).format("YYYY/M/D HH:mm")} ${CowToDispInfo(evt)}`;
        }
        const renderSoldText = (stock: ISeedStock) => {
            if (stock.watched_at == null || stock.price == null) return <></>;

            const date = moment(stock.watched_at).format("YYYY/MM/DD HH:mm");
            const price = formatYen(stock.price);

            return <>{date} {price}円<br/>{stock.note ?? ""}</>
        }

        const selected = this.state.selectedStock;

        return (
            <div>
                <Modal isOpen={true} centered={true} style={{ maxWidth:"600px" }}>
                    <ModalHeader toggle={this.props.onClose} style={{ wordBreak:"break-all" }}>{this.props.data.lot_name}</ModalHeader>
                    <ModalBody style={{ overflowY: "auto", display:"flex", flexFlow:"column nowrap", height: "calc(100vh - 180px)" }}>
                        <div className={styles["stock-top"]}>
                            <div className={styles.tags}>
                                <div style={{ visibility: this.state.filteringTags.length === 0 ? "hidden" : "visible" }}>絞込み：</div>
                                { this.state.filteringTags.map((tg) => (
                                    <div key={tg.tag_id} className={styles.tag}>
                                        <span>{tg.tag_name}</span>
                                        <i className="fas fa-times clickable p-2"
                                            onClick={() => this.setState({ filteringTags: this.state.filteringTags.filter(t => t.tag_id !== tg.tag_id)})}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={styles["stock-table-container"]}>
                        { this.state.dispList.length > 0 && (
                            <table className={styles["stock"]}>
                                <tbody>
                                    { this.state.dispList.map((stock, i) => (
                                        <tr key={i} className={this.state.selectedStockLabels.has(stock.label_no) ? styles.selected : "clickable"}>
                                            <td className={styles["td-check"]}>
                                                <Checkbox className={styles["checkbox"]} id={"chkStock_" + i} checked={this.state.selectedStockLabels.has(stock.label_no)}
                                                    onChange={e => this.onRowCheck(stock, e.target.checked)}
                                                    label="&nbsp;" />
                                            </td>
                                            <td className={styles["td-status"]} onClick={() => this.onRowClick(stock)}>
                                                <StatusIcon status={stock.status} hasCross={stock.cross != null} hasIvf={stock.ivf != null} />
                                            </td>
                                            <td className={styles["td-label"]} style={{ wordBreak:"break-all" }} onClick={() => this.onRowClick(stock)}>
                                                {stock.label_no}
                                            </td>
                                            <td className={styles["td-tag"]} onClick={() => this.onRowClick(stock)}>
                                                <div className={styles["tag-container"]}>
                                                    { stock.tags.map(t => (
                                                        <div key={t.tag_id} className={styles.tag}
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                if (!this.state.filteringTags.some(ft => ft.tag_id === t.tag_id)) {
                                                                    this.setState({ filteringTags: [...this.state.filteringTags, t ]})
                                                                }
                                                            }}
                                                        >{t.tag_name}</div>
                                                    ))}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                        </div>
                        <div style={{ alignSelf:"flex-end", textAlign:"right" }}>
                            { this.state.selectedStockLabels.size >= 2 && (
                                <div style={{ fontSize:"0.75rem" }}>{this.state.selectedStockLabels.size}個 選択中</div>
                            )}
                            { this.state.selectedStockLabels.size >= 1 && (
                                <IconLink text="タグづけ" iconType="tag" onClick={() => this.setState({ isTagEditShown: true })} />
                            )}
                        </div>
                        { selected != null && (
                            <div className={styles["stock-bottom"]}>
                                { selected.status === A.SEED_STOCK_STATUS.DISPOSED && (<>
                                    <div>{selected.updated_at} by {selected.updated_by_name}</div>
                                    <div>{selected.note ?? ""}</div>
                                    <button type="button" className="btn btn-orange" style={{alignSelf:"flex-end"}} onClick={this.onReuseClick}>未使用に戻す</button>
                                </>)}
                                { selected.status === A.SEED_STOCK_STATUS.SOLD && (<>
                                    { this.props.teamType === "ranch" && (
                                        <div style={{ display:"flex", flexDirection:"row", marginBottom: "10px" }}>
                                            <div style={{width:"72px"}}>売却記録</div>
                                            <div className="link" style={{flex:1}} onClick={this.onSoldLinkClick}>{renderSoldText(selected)}</div>
                                        </div>
                                    )}
                                    { this.props.teamType === "clinic" && (<>
                                        <div>{selected.updated_at} by {selected.updated_by_name}</div>
                                        <div>{selected.note ?? ""}</div>
                                        <button type="button" className="btn btn-orange" style={{alignSelf:"flex-end"}} onClick={this.onReuseClick}>未使用に戻す</button>
                                    </>)}
                                </>)}
                                { selected.cross != null && (
                                    <div style={{display:"flex", flexDirection:"row", marginBottom: "10px" }}>
                                        <div style={{width:"68px"}}>交配記録</div>
                                        <div>{buildUsedEventText(selected.cross)}</div>
                                        <IconLink text="確認" className="m-l-10" iconType="navigate" onClick={this.onCrossLinkClick} />
                                    </div>
                                )}
                                { selected.ivf != null && (
                                    <div style={{display:"flex", flexDirection:"row", marginBottom: "10px" }}>
                                        <div style={{width:"68px"}}>媒精記録</div>
                                        <div>{buildUsedEventText(selected.ivf)}</div>
                                        <IconLink text="確認" className="m-l-10" iconType="navigate" onClick={this.onIvfLinkClick} />
                                    </div>
                                )}
                                { isUnusedStock(selected) && (
                                    <SeedActionSelector
                                        teamType={this.props.teamType}
                                        selectedCow={this.state.selectedCow}
                                        isSeed={this.props.seed_type === A.SEED_TYPE.SEED.seed_type}
                                        onSelectCow={this.onSelectCowClick}
                                        onSellInRanch={this.onSellSeedClick}
                                        onCross={this.onCrossWriteClick}
                                        onIvf={this.onIvfWriteClick}
                                        onDispose={n => this.onDisposeClick(n)}
                                        onSellInClinic={n => this.onClinicSellClick(n)}
                                    />
                                )}
                            </div>
                        )}
                    </ModalBody>
                </Modal>
                { this.state.isCowSelectShown && (
                    <SelectCowsPopup cows={this.state.cows}
                        onClose={()=>this.setState({isCowSelectShown:false})}
                        onSubmit={(cows) => this.onCowSelected(cows[0])}
                        singleSelection={true}
                        iniSelectedCows={[]}/>
                )}
                { this.state.isSellingNewSeed && (
                    <SellSeedPopup
                        onClose={() => this.setState({ isSellingNewSeed: false })}
                        canDelete={false}
                        data={{ soldAt: new Date(), price: this.props.data.unit_price, note: "" }}
                        onSubmit={this.onSellSeedSubmit}
                        onDelete={()=>{}}
                        isSubmitExecuting={this.state.executing}
                    />
                )}
                { this.state.isEditingSoldSeed && CommonUtil.assertNotNull(selected) && (
                    <SellSeedPopup
                        onClose={() => this.setState({ isEditingSoldSeed: false })}
                        canDelete={true}
                        data={{ price: selected.price ?? 0, note: selected.note ?? "", soldAt: moment(selected.watched_at ?? undefined).toDate() }}
                        onSubmit={this.onSellSeedSubmit}
                        onDelete={this.onSellSeedDelete}
                        isSubmitExecuting={this.state.executing}
                    />
                )}
                { this.state.isTagEditShown && (
                    <SeedTagPopup
                        allTags={this.props.allTags}
                        stocks={
                            this.props.data.stocks
                                .filter(s => this.state.selectedStockLabels.has(s.label_no))
                        }
                        isExecuting={this.state.executing}
                        onClose={() => this.setState({ isTagEditShown: false })}
                        onSubmit={stocks => this.onTagSubmit(stocks)}
                        submitType="保存"
                    />
                )}
            </div>
        )
    }
}

const StatusIcon = (props: {
    status: number,
    hasCross: boolean,
    hasIvf: boolean
}) => {
    const status = props.status === A.SEED_STOCK_STATUS.SOLD ? { name:"売却済", className:"sold" }
                : props.status === A.SEED_STOCK_STATUS.DISPOSED ? { name:"廃棄", className:"disposed" }
                : (props.hasCross || props.hasIvf) ? { name:"使用済", className:"used"}
                : { name:"未使用", className:"unused"};
    return (
        <div className={classnames(styles.status, styles[status.className])}>{status.name}</div>
    )
}

const SeedActionKeys = ["sell", "dispose", "use" ] as const;
type SeedActionKey = typeof SeedActionKeys[number];
type SeedActionInfo = { name: string, value: number, ranchOnly: boolean };

const SEED_ACTION: { [key in SeedActionKey] : SeedActionInfo } = {
    sell:    { name: "売却する", value: 1, ranchOnly: false },
    use:     { name: "移植する", value: 2, ranchOnly: true  },
    dispose: { name: "廃棄する", value: 3, ranchOnly: false },
} as const;

const SeedActionSelector = (props: {
    teamType: TeamType,
    onDispose: (note: string) => void,
    onCross: () => void,
    onIvf: () => void,
    onSellInRanch: () => void,    
    onSellInClinic: (note: string) => void,
    selectedCow: CowListDto | undefined,
    onSelectCow: () => void,
    isSeed: boolean,

}) => {

    const [ disposeNote, setDisposeNote ] = useState("");
    const [ clinicSellNote, setClinicSellNote ] = useState("");
    const [ selectedAction, setSelectedAction ] = useState<SeedActionKey>("sell");
    const [ actions, setActions ] = useState<SeedActionInfo[]>([]);

    useEffect(() => {
        setActions(
            Object.values(SEED_ACTION)
                .filter(a => props.teamType === "ranch" || a.ranchOnly === false)
        );

    }, [ props.teamType ]);

    useEffect(() => {
        if (actions.length === 0) return;

        const val = SEED_ACTION[selectedAction].value;
        if (!actions.some(a => a.value === val)) {
            const newAct = SeedActionKeys.find(k => actions.some(a => a.value === SEED_ACTION[k].value));
            if (CommonUtil.assertNotNull(newAct)) {
                setSelectedAction(newAct);
            }
        }

    }, [ actions ]);

    const onActionSelect = (val: number) => setSelectedAction(
        SeedActionKeys.find(k => SEED_ACTION[k].value === val)!
    );

    return (
        <div style={{ display:"flex", flexFlow:"column nowrap" }}>
            <div style={{ display:"flex", marginBottom: "14px", flexWrap:"wrap" }}>
                { actions.length === 1 ? (
                    <div>{actions[0].name}</div>
                ) : (
                    <FormRadio isInline={false} prefix="seedAct"
                        value={SEED_ACTION[selectedAction].value}
                        options={actions}
                        className="p-t-0"
                        onChange={onActionSelect}
                    />
                )}
            </div>

            { selectedAction === "sell" && props.teamType === "ranch" && (
                <button type="button" className="btn btn-green" onClick={props.onSellInRanch}
                    style={{alignSelf:"flex-start"}}>売却情報入力</button>
            )}

            { selectedAction === "sell" && props.teamType === "clinic" && (
                <div>
                    <div style={{display:"flex"}}>
                        <textarea placeholder="メモ" maxLength={LMT.SEED.STOCK_MEMO_LEN} className="form-control"
                            value={clinicSellNote}
                            onChange={e => setClinicSellNote(e.target.value)}/>
                    </div>
                    <button type="button" className="btn btn-green" onClick={() => props.onSellInClinic(clinicSellNote)}
                        style={{alignSelf:"flex-end", marginTop:"2px", width:"84px"}}>売却</button>
                </div>
            )}

            { selectedAction === "use" && (
                <div>
                    <div style={{display:"flex", alignItems:"center"}}>
                        <button type="button" className="btn btn-sm btn-gray" style={{ marginRight:"4px" }} onClick={props.onSelectCow}>牛選択</button>
                        <div style={{fontSize:"0.7rem"}}>{props.selectedCow == null ? "" : CowToDispInfo(props.selectedCow)}</div>
                    </div>
                    <div style={{ display:"flex", justifyContent:"flex-end", marginTop:"5px" }}>
                        <IconLink text="交配記録入力" iconType="navigate" disabled={props.selectedCow == null}
                            onClick={props.onCross} />
                        { props.isSeed && (
                            <IconLink text="媒精記録入力" iconType="navigate" disabled={props.selectedCow == null}
                                onClick={props.onIvf} className="m-l-10" />
                        )}
                    </div>
                </div>
            )}

            { selectedAction === "dispose" && (
                <div>
                    <div style={{display:"flex"}}>
                        <textarea placeholder="廃棄理由" maxLength={LMT.SEED.STOCK_MEMO_LEN} className="form-control"
                            value={disposeNote}
                            onChange={e => setDisposeNote(e.target.value)}/>
                    </div>
                    <button type="button" className="btn btn-gray" onClick={() => props.onDispose(disposeNote)}
                        style={{alignSelf:"flex-end", marginTop:"4px", width:"100px"}}>廃棄</button>
                </div>
            )}
        </div>
    )
}