import { A } from '../../config/constant';


const userNameValidation = (user_name: string): string => {
  if (!user_name) return '';
  return '';
};

const emailValidation = (email: string): string => {
  if (!email) return '';
  const regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!regex.test(email)) return A.MESSAGE.INVALID_EMAIL;
  return '';
};

const emailConfirmValidation = (email_confirm: string, email: string): string => {
  if (!email_confirm) return '';
  if (email !== email_confirm) return A.MESSAGE.INVALID_EMAIL_CONFIRM;
  return '';
};

const passwordValidation = (password: string): string => {
  if (!password) return '';
  if (password.length < 6) return A.MESSAGE.INVALID_PASSWORD_LENGTH.replace("$1", "6");
  return '';
};

const passwordConfirmValidation = (password_confirm: string, password: string): string => {
  if (!password_confirm) return '';
  if (password !== password_confirm) return A.MESSAGE.INVALID_PASSWORD_CONFIRM;
  return '';
};


class Validation {
  static formValidate = (type: string, value: string, confirm_value?: string) => {
    switch (type) {
      case 'user_name':
        return userNameValidation(value);
      case 'email':
        return emailValidation(value);
      case 'email_confirm':
        return emailConfirmValidation(value, confirm_value ?? "");
      case 'password':
        return passwordValidation(value);
      case 'password_confirm':
        return passwordConfirmValidation(value, confirm_value ?? "");
      default:
        return "";
    }
  };
}
  
export default Validation;