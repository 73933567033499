import React, { useState } from 'react';
import { CowWatchingPopup } from './cow-watching-popup';
import { CommonUtil } from "../../config/util";
import { CowApi } from '../../api';
import { useRootStore } from '../../stores';
import { Communicator } from '../../api/communicator';
import classnames from 'classnames';

export type ICowWatchingInfo = {
    cow_id: number; 
    watching: number;
    watching_memo: string;
}

export interface WatchingIconProps {
    watchingInfo: Readonly<ICowWatchingInfo>;
    onUpdated: (info:ICowWatchingInfo)=>void;
    showAlert: (err:string)=>void;
    comm: Communicator;
    className?: string;
}

export const WatchingIcon: React.FC<WatchingIconProps> = (props) => {
    const rootStore = useRootStore();
    const { user, cur_ranch_id } = rootStore;
    const [ isCowWatchingPopupShown, setIsCowWatchingPopupShown ] = useState(false);
    const [ executing, setExecuting ] = useState(false);

    const onSubmit = async (editingWatchingInfo:ICowWatchingInfo) => {

        if (!CommonUtil.assertNotNull(editingWatchingInfo, "editingWatchingInfo", "onSubmit")) {
            return;
        }

        try {
            setExecuting(true);

            const res = await props.comm.send((await CowApi()).modifyCowWatchingUsingPOST({
                cow_id: editingWatchingInfo.cow_id,
                ranch_id: cur_ranch_id, 
                watching : editingWatchingInfo.watching, 
                watching_memo: editingWatchingInfo.watching_memo,
            }));
            if (res.result !== "OK") return;
        
            props.onUpdated(editingWatchingInfo);
            rootStore.fetchActiveCows(cur_ranch_id, "DIFF");
            setIsCowWatchingPopupShown(false);
        } finally {
            setExecuting(false);
        }
    }
    
    return (
        <>
            <i className={classnames("fas fa-exclamation-triangle", props.className)}
                style={{
                    fontSize:"1.2rem",
                    marginLeft:"2px",
                    color: props.watchingInfo.watching ? "#ef3a3a" : props.watchingInfo.watching_memo !== "" ? "#ebc034" : "#dddddd"
                }}
                onClick={() => setIsCowWatchingPopupShown(true)}></i>
            {
                isCowWatchingPopupShown && (
                    <CowWatchingPopup
                        watchingInfo={props.watchingInfo}
                        onClose={() => setIsCowWatchingPopupShown(false)}
                        onSubmit={onSubmit}
                        isSubmitExecuting={executing}
                    />
                )
            }
        </>
    );
}