import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { IIvfEggEvent } from './cow-event';
import { IvfEggRankDetailTable } from '../egg/ivf-egg-rank-detail-table';

interface MyProps {
    onClose: () => void;
    data: IIvfEggEvent;
}

export const IvgEggRankDetailTablePopup = (props: MyProps) => {

    return (
        <div>
            <Modal isOpen={true}>
                <ModalHeader toggle={() => props.onClose()}>受精卵ランク内訳</ModalHeader>
                <ModalBody>
                    <IvfEggRankDetailTable
                        ranks={props.data.ranks}
                    />
                </ModalBody>
            </Modal>
        </div>
    )
}