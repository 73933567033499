import React, { useState, useEffect } from 'react';
import { ResponsiveContainer, LineChart, Line, XAxis, Tooltip, CartesianGrid, Legend, ReferenceLine } from 'recharts';
import moment from 'moment';
import { CommonUtil, ar } from '../../config/util';
import Big from 'big.js';
import { FeedDayAmountDao } from '../../api';

interface FeedingGraphProps {
    amountList: FeedDayAmountDao[];
    dayFrom: string | moment.Moment | Date;
    dayTo: string | moment.Moment | Date;
    onDaySelect: (day: string) => void;
}

interface EventData {
    activeLabel: string;
    activePayload?: Payload[];
}
interface Payload {
    unit: string | undefined;
    color: string;
    name: string;
    value: number | null;
}

const LINE_COLORS = [
    { feed_type_no: 1, colors: [ "#00ccff", "#ccffff", "#ddeeff" ] },
    { feed_type_no: 2, colors: [ "#a83255", "#ff3333", "#ffcceb" ] },
    { feed_type_no: 3, colors: [ "#2fa765", "#005c29", "#a8e2c2" ] },
    { feed_type_no: 4, colors: [ "#0000ff", "#00ccff", "#727272" ] },
    { feed_type_no: 5, colors: [ "#ffa500", "#ffebcc", "#b06000" ] },
    { feed_type_no: 6, colors: [ "#9632a8", "#d6ccff", "#808080" ] },
] as const;

const pickLineColor = (typeNo: number, idx: number) => {
    const colors = LINE_COLORS.find(c => c.feed_type_no === typeNo)?.colors;
    if (colors == null) return "black";

    return colors[ idx % colors.length ];
}

export const FeedingGraph = React.memo((props: FeedingGraphProps) => {
    const [ currentData, setCurrentData ] = useState<EventData|null>(null);

    useEffect(() => {
        setCurrentData(null);

    }, [ props.amountList, props.dayFrom, props.dayTo ]);

    const stDay = moment(props.dayFrom);
    const edDay = moment(props.dayTo);
    if (!stDay.isValid || !edDay.isValid || edDay.isBefore(stDay)) return <></>;

    //全レコードに含まれるえさ項目のリストを作成
    const recordPerFeed = [...CommonUtil.groupBy(props.amountList, a => a.no).values()].map(l => l[0]);
    const groupedByType = [...CommonUtil.groupBy(recordPerFeed, r => r.feed_type_no).values()]
        .map(g => g.map((a,i) => ({ no: a.no, name: a.name, unit: a.unit, lineColor: pickLineColor(a.feed_type_no, i) })));
    const feeds = ar.flat(groupedByType, 1);

    const amountsByDay = CommonUtil.groupBy(props.amountList, a => a.day);

    const data: any[] = [];

    const day = moment(stDay);
    while(day.isSameOrBefore(edDay)) {
        const amt = amountsByDay.get(day.format("YYYY-MM-DD")) ?? [];
        const dayData: any = { day: day.format("YYYY-MM-DD") };

        amt.forEach(a => {
            dayData[a.name] = Number(new Big(a.delivered).minus(a.leftover));
        });

        data.push(dayData);
        day.add(1, "days");
    }

    const onChartClick = (e:EventData|null) => {
        if (e == null
            || e.activePayload == null
            || e.activePayload.every(p => p.value == null)) {
            setCurrentData(null);
            return;
        }
        setCurrentData(e);
    }

    return (
        <div style={{width:"100%", height:"100%", position:"relative"}}>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart data={data} onClick={onChartClick}>
                    {/* 記録のない日を補完した点線 */}
                    { feeds.map(f => (
                        <Line key={f.no * -1}
                            dataKey={f.name}
                            yAxisId={f.no}
                            stroke={f.lineColor}
                            type="linear"
                            strokeWidth={1}
                            animationDuration={500}
                            dot={{ r:0 }}
                            legendType={"none"}
                            activeDot={{ r: 0 }}
                            connectNulls={true}
                            strokeDasharray={"2 2"}
                        />
                    ))}
                    {/* 実線 */}
                    { feeds.map(f => (
                        <Line key={f.no}
                            unit={f.unit}
                            dataKey={f.name}
                            yAxisId={f.no}
                            stroke={f.lineColor}
                            type="linear"
                            strokeWidth={2}
                            animationDuration={500}
                            dot={{ r:2 }}
                            activeDot={{ r: 5 }}
                        />
                    ))}
                    <XAxis dataKey="day" interval={"preserveStartEnd"} angle={10} tick={{fontSize:"11px"}} tickMargin={1} tickFormatter={(day) => moment(day).format("M/D")} />
                    <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
                    <Tooltip content={<></>} />
                    {/* 
                      * ※yAxisIdを指定しないとエラーになるので、存在する適当なものを指定
                      * 　あわせて、遷移時にエラーになるので「props.amountList.length > 0」を入れておく
                      */}
                    { currentData != null && props.amountList.length > 0 && (
                        <ReferenceLine x={currentData.activeLabel} stroke="red" strokeDasharray="3 3" yAxisId={props.amountList[0].no} />
                    ) }
                    <Legend wrapperStyle={{ maxHeight:"120px", overflowY:"auto" }} />
                </LineChart>
            </ResponsiveContainer>
            { currentData != null && (
                // ※標準のTooltipは、スタイルの自作はできるが、表示非表示の手動制御ができず、
                // 　state更新などのトリガで勝手に閉じてしまうので、自前で作り直す
                //　（マウスオーバーでのフォーカスライン表示だけしたいので Tooltip 部品も残しておく）
                <div className="chart-legend">
                    <div>{moment(currentData.activeLabel).format("M/D")}</div>
                    { (currentData.activePayload ?? []).filter(p => p.value != null && p.unit != null).map((p,i) => (
                        <div key={i}>
                            <span style={{ color:p.color }}>●</span>
                            <span>{p.name} : {p.value}{p.unit}</span>
                        </div>
                    ))}
                    <div style={{textAlign:"right", marginTop:"4px", fontSize:"0.75rem"}}>
                        <span className="link" onClick={() => props.onDaySelect(currentData.activeLabel)}>イベント詳細</span>
                    </div>
                </div>
            )}
        </div>
    )
})