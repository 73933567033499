import React, { useState, useEffect } from 'react';
import moment from 'moment';
import V3DatePicker from './v3-date-picker';
import { TimeSelector } from './time-selector';
import { PopperPlacement } from '../form/form-date-picker';
import { TimePreset } from '../../stores';

export interface V3DateTimeProps {
    onChange: (value: moment.Moment) => void;
    value: moment.Moment | Date | undefined;
    popperPlacement?: PopperPlacement;
    portalContainerID?: string;
    timePresets?: Readonly<Readonly<TimePreset>[]>;
    popperClassName?: string;
}

export const V3DateTime: React.FC<V3DateTimeProps> = (props) => {
    const [ currentValue, setCurrentValue ] = useState(new Date());

    useEffect(() => {
        setCurrentValue(moment(props.value).toDate());

    }, [ props.value ]);

    const onDateChange = (val: Date) => {
        const mom = moment(val);
        mom.set("hour", currentValue.getHours());
        mom.set("minute", currentValue.getMinutes());
        props.onChange(mom);
    }
    const onTimeChange = (val: string) => {
        const h = parseInt(val.slice(0, 2));
        const m = parseInt(val.slice(3, 5));
        const res = moment(currentValue).set("hour", h).set("minute", m);
        props.onChange(res);
    }

    return (
        <div style={{ display:"flex", flexWrap:"wrap" }} data-testid="v3-datetime">
            <V3DatePicker name=""
                value={currentValue}
                popperPlacement={props.popperPlacement}
                onChange={e => onDateChange(e.target.value)}
                style={{ marginBottom: "2px" }}
                portalContainerID={props.portalContainerID}
                popperClassName={props.popperClassName}
            />
            <TimeSelector
                mobileStyle={{ marginLeft: "4px" }}
                browserStyle={{ marginLeft: "14px" }}
                value={moment(currentValue).format("HH:mm")}
                onChange={onTimeChange}
                style={{ marginBottom: "2px" }}
            />
            <div>
                { props.timePresets?.map((t,i) => (
                    <button key={i} type="button" className="btn btn-light btn-sm ml-2 m-t-5 m-b-5"
                        onClick={() => onTimeChange(t.time)}>{t.name}</button>
                ))}
            </div>
        </div>
    )
}
