import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ar } from '../../config/util';
import { OVUM_RANKS, findEggRank } from '../../config/egg-kind';
import { OvumRankCounter, OvumRankData } from './ovum-rank-counter';

interface MyProps {
    egg_rank: number;
    rank_total: number;
    allOvumCountMap: Map<number, number>;
    ovumCountMap: Map<number, number>;
    onSubmit: (ovumCountMap: Map<number, number>) => void;
    onClose: () => void;
}

export const IvfEggRankDetailPopup = (props: MyProps) => {
    const [ countMap, setCountMap ] = useState<Map<number, OvumRankData>>(new Map());
    const [ totalOvumCountText, setTotalOvumCountText ] = useState("");
    const [ currentSum, setCurrentSum ] = useState(0);

    useEffect(() => {
        setCountMap(
            ar.toMap([...props.ovumCountMap.entries()],
                ([r,c]) => r,
                ([r,c]) => ({ count: c, countL: undefined })
            )
        );
    }, [ props.ovumCountMap]);

    useEffect(() => {
        const text = OVUM_RANKS.map(r => ({ R:r, cnt: props.allOvumCountMap.get(r.ovum_rank)}))
            .filter(a => a.cnt != null && a.cnt > 0)
            .map(a => `${a.R.name}${a.cnt}個`)
            .join(" ");
        setTotalOvumCountText(text);

    }, [ props.allOvumCountMap ]);

    useEffect(() => {
        const sum = ar.sum([...countMap.values()].map(o => o.count));
        setCurrentSum(sum);

    }, [ countMap ]);


    const onSubmit = () => {
        const resultCountMap
            = ar.toMap(
                [...countMap.entries()].filter(([r,v]) => v.count > 0),
                ([r,v]) => r,
                ([r,v]) => v.count
            );
        props.onSubmit(resultCountMap);
    }

    const styles: { [key: string]: React.CSSProperties } = {
        container: {
            maxWidth: "380px"
        },
        hint: {
            color:"#999999",
            marginBottom: "10px",
            marginTop: "5px"
        },
        row: {
            display: "flex",
            alignItems: "center",
            marginBottom: "8px"
        },
        counter: {
            marginLeft: "20px"
        },
        rest: {
            marginTop: "10px",
            textAlign: "right",
            color: "#666666"
        },
        footer: {
            display: "flex",
            justifyContent: "center"
        }
    }

    const eggRankName = findEggRank(props.egg_rank)?.name ?? "";

    return (
        <div>
            <Modal isOpen={true} style={styles.container}>
                <ModalHeader toggle={() => props.onClose()}>ランク{eggRankName} 個数内訳</ModalHeader>
                <ModalBody>
                    <div style={styles.hint}>媒精時個数：{totalOvumCountText}</div>
                    <OvumRankCounter
                        style={styles.counter}
                        countMap={countMap}
                        canLR={false}
                        cowKey={0}
                        onChange={m => setCountMap(m)}
                        total={props.rank_total}
                    />
                    <div style={styles.rest}>残 {props.rank_total - currentSum} 個</div>
                </ModalBody>
                <ModalFooter style={styles.footer}>
                    <button className="btn btn-success"
                        disabled={currentSum !== 0 && currentSum !== props.rank_total}
                        onClick={onSubmit}
                        >確定</button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
