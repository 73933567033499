import React, { useState } from "react";
import { CowListDto } from "../../api";
import { HouseTree } from "./housetree";
import { ToggleButton } from "./toggle-switch";
import { CowList } from "./cow-list";
import treeStyles from './housetree.module.css';
import { useRanchHouses } from "../../stores/fetcher";
import { FetchWaiter, FetchError } from "../content/fetch-state";

type ActionCowSelectorProps = {
    cows      : Readonly<Array<CowListDto>>,
    checkedCowIds: Readonly<Set<number>>,
    setCheckedCowIds: React.Dispatch<React.SetStateAction<Set<number>>>,
    singleSelection?: boolean;
    onSubmit?: (cowId: number) => void;
    ranchId: number;
}
  
export const ActionCowSelector: React.FC<ActionCowSelectorProps> = (props) => {
    const [ selectionMode, setSelectionMode ] = useState<"list"|"house">("house");

    const mHouses = useRanchHouses(props.ranchId, true);

    const onAllCheckChange = (checked: boolean) => {
        if (checked) {
            props.setCheckedCowIds(new Set(props.cows.map(c => c.cow_id)));
        } else {
            props.setCheckedCowIds(new Set<number>());
        }
    }

    if (mHouses.isLoading) return <FetchWaiter />
    if (mHouses.isError || mHouses.data == null) return <FetchError />

    return (
        <div style={{ height: "100%", display:"flex", flexDirection:"column" }}>
            <div style={{ display:"flex", justifyContent:"space-between", marginBottom:"8px" }}>
                <div style={{ display:"flex" }}>
                    <ToggleButton id="view-select" label1="部屋" label2="一覧" state={selectionMode === "house" ? 1 : 2} onChange={st => setSelectionMode(st === 1 ? "house" : "list")} />
                </div>

                { props.singleSelection !== true && (
                    <div style={{ display:"flex", alignItems:"center" }}>
                        <input type="checkbox" id="chkAllHouse" className={ treeStyles['row-checkbox'] }
                            checked={props.cows.length === props.checkedCowIds.size}
                            onChange={e => onAllCheckChange(e.target.checked)}
                        />
                        <label htmlFor="chkAllHouse" style={{ marginBottom:"4px", marginLeft:"6px" }}>全選択</label>
                    </div>
                )}

            </div>
            { selectionMode === "house" ? (
                <HouseTree {...props} ranchHouses={mHouses.data} style={{ flex:1, overflowY: "auto" }} /> 
            ) : (
                <CowList {...props} style={{ flex:1, minHeight:0 }} />
            )}
        </div>
    )

}