import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ICowWatchingInfo } from './watching-icon';
import { LMT } from '../../config/constant';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { ModalInputBlock } from '../../components/parts/modal-input-block';

export interface ICowWatchingPopupProps {
    onClose:()=>void;
    onSubmit:(editingWatchingInfo:ICowWatchingInfo)=>void;
    watchingInfo:ICowWatchingInfo;
    isSubmitExecuting: boolean;
}

export const CowWatchingPopup: React.FC<ICowWatchingPopupProps> = (props) => {
    const [ editingWatchingInfo, setEditingWatchingInfo] = useState<ICowWatchingInfo>({cow_id: 0, watching: 0, watching_memo: ""});

    useEffect(() => {
        setEditingWatchingInfo(props.watchingInfo);
    }, [props.watchingInfo]);

    return (
        <div>
            <Modal isOpen={true} centered={true} scrollable={true}>
                <ModalHeader toggle={props.onClose}>要観察の設定</ModalHeader>
                <ModalBody>
                    <ModalInputBlock label="状態">
                        <div className="checkbox checkbox-css">
                            <input type="checkbox" id="chkWatching"
                                onChange={(e) => setEditingWatchingInfo({ ...editingWatchingInfo, watching: Number(e.target.checked)})}
                                checked={Boolean(editingWatchingInfo.watching)} />
                            <label htmlFor="chkWatching" style={{fontWeight:editingWatchingInfo.watching === 1 ? "bold": "normal"}}>要観察</label>
                        </div>
                    </ModalInputBlock>
                    <ModalInputBlock label="観察事項">
                        <textarea className="form-control" rows={3} maxLength={LMT.COW.WATCHING_MEMO_LEN}
                            value={editingWatchingInfo.watching_memo} 
                            onChange={(e) => setEditingWatchingInfo({ ...editingWatchingInfo, watching_memo: e.target.value })}
                        />
                    </ModalInputBlock>
                </ModalBody>
                <ModalFooter className="modal-footer-fix">
                    <ExecutionButton type="save" onClick={() => props.onSubmit(editingWatchingInfo)} disabled={props.isSubmitExecuting} />
                </ModalFooter>
            </Modal>
        </div>
    );
}