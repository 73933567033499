import React from 'react';
import moment from 'moment';
import { IUser } from '../../stores';
import { CowSearchReqSchedule } from '../../api';
import { CommonUtil } from '../../config/util';
import { EventKind } from '../../config/event-kind';
import { RequiredNumInput } from '../../components/parts/num-input';
import { Checkbox } from '../../components/form/form-checkbox';

const KIND_UNSELECTED  = "";
const KIND_ALL = 0;

interface ScheduleConditionSelectorProps {
    ranch_id: number;
    user: IUser;
    values: CowSearchReqSchedule | undefined;
    onChange: (values?: CowSearchReqSchedule) => void;
    index: number;
    className?: string;
}

export const calcAfterDaysFromDate = (today: moment.Moment, date: moment.Moment) => {
    return date.diff(today, "day");
}

export const calcDateFromAfterDays = (today: moment.Moment, days: number) => {
    return moment(today).add(days, "day").format("YYYY-MM-DD");
}

export const ScheduleConditionSelector = React.memo<ScheduleConditionSelectorProps>((props) => {

    const today = moment().startOf("day");
    const values = props.values;

    const from = values == null ? undefined : moment(values.date_from);
    const to = values == null ? undefined : moment(values.date_to);

    const onEventKindChange = (val: string) => {
        if (val === KIND_UNSELECTED) {
            props.onChange(undefined);
            return;
        }
        const no = Number(val);
        const newValues: CowSearchReqSchedule = {
            event_kind: no === KIND_ALL ? undefined : no,
            date_from: props.values?.date_from ?? calcDateFromAfterDays(today, 0),
            date_to: props.values?.date_to ?? calcDateFromAfterDays(today, 1),
            status: props.values?.status
        }
        props.onChange(newValues);
    }

    const onFromChange = (val: number) => {
        if (!CommonUtil.assertNotNull(values)) return;
        if (!CommonUtil.assertNotNull(to)) return;

        const date_from = calcDateFromAfterDays(today, val);
        //逆転を補正
        const toVal = calcAfterDaysFromDate(today, to);
        const date_to = val <= toVal ? values.date_to : date_from;
        
        props.onChange({ ...values, date_from, date_to });
    }
    const onToChange = (val: number) => {
        if (!CommonUtil.assertNotNull(values)) return;
        if (!CommonUtil.assertNotNull(from)) return;

        const date_to = calcDateFromAfterDays(today, val);
        //逆転を補正
        const fromVal = calcAfterDaysFromDate(today, from);
        const date_from = fromVal <= val ? values.date_from : date_to;

        props.onChange({ ...values, date_from, date_to });
    }

    return (
        <div className={props.className} data-testid="schedule-condition">
            <div style={{ display:"flex", flexWrap:"wrap" }}>
                <select className="form-control" style={{ width:"160px", marginRight:"6px", marginBottom:"2px" }}
                        value={values == null ? KIND_UNSELECTED : (values.event_kind ?? KIND_ALL)}
                        onChange={e => onEventKindChange(e.target.value)}>
                    <option value={KIND_UNSELECTED}>選択</option>
                    <option value={KIND_ALL}>すべての種別</option>
                    { EventKind.forSchedule(props.ranch_id, props.user)
                        .map(ev => (
                            <option key={ev.no} value={ev.no}>{ev.schedule.sname}</option>
                        ))
                    }
                </select>
                { values != null
                    && CommonUtil.assertNotNull(from)
                    && CommonUtil.assertNotNull(to)
                    && (<>
                    <div style={{ display:"flex", flexWrap:"nowrap", alignItems:"center", marginBottom:"2px" }}>
                        <RequiredNumInput
                            min={-30} max={364} step={1} style={{ width: "76px" }}
                            value={calcAfterDaysFromDate(today, from)}
                            onChange={onFromChange}
                        />
                        日後～
                        <RequiredNumInput
                            min={-30} max={364} step={1} style={{ width: "76px" }}
                            value={calcAfterDaysFromDate(today, to)}
                            onChange={onToChange}
                        />
                        日後
                    </div>
                    <div style={{ fontSize: "0.8rem" }}>（{from.format("YYYY/MM/DD")} ～ {to.format("YYYY/MM/DD")}）</div>
                </>)}
            </div>
            { values != null && (
                <Checkbox label="未完了のみ" id={"chkOnlyIncompleted_" + props.index} checked={values.status === 0}
                    onChange={e => props.onChange({ ...values, status:e.target.checked ? 0 : undefined })}
                />
            )}
        </div>
    )
})