import React from 'react';
import { FreezedArray, ar } from '../../config/util';
import { Table } from "reactstrap";
import styles from '../report-sellcow/report-sellcow.module.css';
import { ICowUse } from '../../stores';
import { DynamicsAgeDto } from '../../api';

export const buildAgeTableHeader = (data: FreezedArray<DynamicsAgeDto>) => {
    const vals = [
        "月齢（ヵ月）",
        ...data.filter(d => d.age != null).map(d => d.age!)
    ];
    return vals;
}
export const getAgeTableRowValues = (data: FreezedArray<DynamicsAgeDto>, useList:FreezedArray<ICowUse>) => {
    const rtn: Array<string|number>[] = [];

    useList.forEach(use => {
        const row = [
            use.name,
            ...data.filter(d => d.age != null).map(d => d.score.find(s => s.use_no === use.use_no)?.count ?? 0)
        ];
        rtn.push(row);
    })

    const total = [
        "計",
        ...data.filter(d => d.age != null).map(d => ar.sum(d.score.filter(s => s.use_no != null).map(s => s.count)))
    ];
    rtn.push(total);

    return rtn;
}

export interface AgeTableProps {
    data: FreezedArray<DynamicsAgeDto>;
    cowUses: FreezedArray<ICowUse>;
    tableRef: React.RefObject<HTMLElement>;
}

export const AgeTable = React.memo((props: AgeTableProps) => {

    return (
        <Table className={styles.table} innerRef={props.tableRef}>
            <thead>
                <tr>
                    { buildAgeTableHeader(props.data).map((h,i) => (
                        <th key={i}>{h}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                { getAgeTableRowValues(props.data, props.cowUses).map((r,i) => (
                    <tr key={i}>
                        { r.map((d, ii) => (
                            <td className={ii === 0 ? styles["row-header"] : ""} key={ii}>{d}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </Table>
    )
});