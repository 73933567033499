import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import moment from 'moment';
import styles from '../../pages/schedule/schedule.module.css';
import { FormDatePicker } from '../../components/form/form-date-picker';
import { A, LMT, TIMESPAN } from '../../config/constant';
import { NextSchedule } from './next-schedule-selector';
import { EventKind, EVENT_KIND } from '../../config/event-kind';
import { TimeSelector } from '../parts/time-selector';
import { RanchSchedulePresetDto, TeamTreatPresetDto, TeamTreatPresetItemDto, TeamTreatNamedPresetItemDto } from '../../api';
import { ar, CommonUtil } from '../../config/util';
import { IconLink } from '../parts/icon-link';
import { TreatPresetSelectPopup } from '../parts/treat-preset-select-popup';
import { IconRight } from '../parts/icon-right';
import { ExecutionButton } from '../buttons/execution-button';
import { usePresets } from '../../stores/fetcher';
import { FetchWaiter, FetchError } from '../content/fetch-state';

export interface NextSchedulePopupProps {
    onClose:()=>void;
    onSubmit:(value:NextScheduleInputValue)=>void;
    original: NextSchedule;
    ranchId: number;
    presetTeamId: number;
    showToast: (msg:string) => void;
}

export interface NextScheduleInputValue {
    title: string;
    start: Date;
    end: Date;
    allDay: boolean;
    note: string;
    preset: RanchSchedulePresetDto | undefined;
}

export const NextSchedulePopup = React.memo((props: NextSchedulePopupProps) => {

    const [ currentValue, setCurrentValue ] = useState<Readonly<NextScheduleInputValue>>({ allDay:true, start:new Date(), end:new Date(), note:"", title:"", preset:undefined });
    const [ isPresetSelectShown, setIsPresetSelectShown ] = useState(false);

    const masters = usePresets(props.presetTeamId);

    useEffect(() => {
        setCurrentValue({ ...props.original })

    }, [ props.original ]);

    const onSubmit = async () => {

        let start = moment(currentValue.start);
        let end = moment(currentValue.end);
        if (currentValue.allDay) {
            start = start.startOf("day");
            end = end.startOf("day");
        }
        if (start.isAfter(end)) {
            props.showToast(A.MESSAGE.INVALID_SCHEDULE_TERM);
            return;
        }

        props.onSubmit(currentValue);
    }

    const onStDateSelected = (e:{target:{value:Date}}) => {
        const old = currentValue;
        const start = getNewDateTimeFromSelectedDate(e.target.value, old.start);
        const isAfterEndDay = moment(e.target.value).startOf("day").isAfter(old.end);
        const end = isAfterEndDay ? getNewDateTimeFromSelectedDate(e.target.value, old.end) : old.end;

        setCurrentValue({ ...old, start, end });
    }
    const onEdDateSelected = (e:{target:{value:Date}}) => {
        const old = currentValue;
        setCurrentValue({
            ...old,
            end: getNewDateTimeFromSelectedDate(e.target.value, old.end)
        });
    }
    const getNewDateTimeFromSelectedDate = (newDate:Date, preDateTime:Date) => {
        const newVal = moment(newDate).startOf("day");
        if (!currentValue.allDay) {
            newVal.add(preDateTime.getHours(), "hour");
            newVal.add(preDateTime.getMinutes(), "minute");
        }
        return newVal.toDate();
    }

    const onStTimeSelected = (value: string) => {
        const old = currentValue;
        if (old.allDay) {
           console.error("st time was selected on allday mode");
           return;
        }
        setCurrentValue({
            ...old,
            start:getNewDateTimeFromSelectedTime(value, old.start)
        })
    }
    const onEdTimeSelected = (value: string) => {
        const old = currentValue;
        if (old.allDay) {
            console.error("ed time was selected on allday mode");
            return;
        }
        setCurrentValue({
            ...old,
            end:getNewDateTimeFromSelectedTime(value, old.end)
        })
    }
    const getNewDateTimeFromSelectedTime = (newTime:string, preDateTime:Date) => {
        if (newTime === "") {
            newTime = "00:00";
        }
        const timeStrs = newTime.split(":");
        const newDate = moment(preDateTime).startOf("day");
        newDate.add(parseInt(timeStrs[0]), "hour");
        newDate.add(parseInt(timeStrs[1]), "minute");
        return newDate.toDate();
    }
    const setTimeRange = (start: string, end:string) => {
        const old = currentValue;
        if (old.allDay) {
            console.error("time range was selected on allday mode");
            return;
        }

        setCurrentValue({
            ...old,
            start: getNewDateTimeFromSelectedTime(start, old.start),
            end: getNewDateTimeFromSelectedTime(end, old.end)
        })
    }

    const onPresetSelected = (preset: TeamTreatPresetDto) => {
        if (!CommonUtil.assertNotNull(masters.data, "masters.data")) return;

        const medicines = masters.data.medicines;
        const treatItems = masters.data.treatItems;

        const presetItemToNamed = (item: TeamTreatPresetItemDto): TeamTreatNamedPresetItemDto | undefined => {
            let item_name: string;
            let item_unit: string | undefined;
            if (item.medicine_id != null) {
                const med = medicines.find(m => m.medicine_id === item.medicine_id);
                if (med == null) return undefined;
                item_name =  med.name;
                item_unit = med.unit;
            } else {
                const treat = treatItems.find(t => t.treat_kind_no === item.treat_kind_no && t.treat_item_no === item.treat_item_no);
                if (treat == null) return undefined;
                item_name = treat.name;
                item_unit = treat.fee_unit ?? undefined;
            }
            return { ...item, item_name, item_unit };
        }

        setCurrentValue({
            ...currentValue,
            preset: {
                ...preset,
                team_id: props.presetTeamId,
                items: ar.notNull(preset.items.map(presetItemToNamed))
            }
        });
        setIsPresetSelectShown(false);
    }

    const current = currentValue;
    const currentEventKind = props.original.eventKindNo === 0 ? undefined : EventKind.find(props.original.eventKindNo);

    const canEditPreset  = currentEventKind?.treatment != null; //記録画面で利用するので権限チェックは不要

    const ID = "next-schedule-popup";

    const isClinicUser = props.ranchId !== props.presetTeamId;
    const showsPresetBenefitWarning
        = isClinicUser
            && current.preset?.team_id === props.presetTeamId   //自診療所プリセット選択中
            //給付金設定のないイベント種別選択中
            && (props.original.eventKindNo === EVENT_KIND.PREVENTION.no || props.original.eventKindNo === EVENT_KIND.RUT.no);

    return (
        <div>
            <Modal isOpen={true} centered={true} id={ID}>
                <ModalHeader toggle={props.onClose}></ModalHeader>
                { masters.isLoading ? (
                    <FetchWaiter />
                ) : ( (masters.isError || masters.data == null) ? (
                    <FetchError />
                ) : (<>
                    <ModalBody style={{ overflowY: "auto", maxHeight: "calc(100vh - 180px)" }}>
                        <div>
                            <div className={"form-group " + styles["popup-row"]}>
                                <label className={"col-form-label " + styles["popup-item-header"]}>タイトル</label>
                                <div style={{flex:1}}>
                                        <input className="form-control" type="text" value={current.title}
                                            onChange={e => setCurrentValue({ ...current, title:e.target.value })}
                                            maxLength={LMT.SCHEDULE.TITLE_LEN}
                                        />
                                </div>
                            </div>
                            <div className={"form-group " + styles["popup-row"]}>
                                <div className="radio radio-css" style={{padding:0}}>
                                    <input id="radioAllDay" type="radio" checked={current.allDay}
                                            onChange={()=> setCurrentValue({ ...current, allDay:true })}></input>
                                    <label htmlFor="radioAllDay">終日</label>
                                </div>
                                <div className="radio radio-css ml-3" style={{padding:0}}>
                                    <input id="radioHasTime" type="radio" checked={!current.allDay}
                                            onChange={()=> setCurrentValue({ ...current, allDay:false })}></input>
                                    <label htmlFor="radioHasTime">時間帯</label>
                                </div>
                                <span style={{visibility: current.allDay ? "hidden" : "visible" }}>
                                    <button type="button" className="btn btn-light btn-sm ml-2" onClick={()=>setTimeRange(TIMESPAN.AM.FROM, TIMESPAN.AM.TO)}>午前</button>
                                    <button type="button" className="btn btn-light btn-sm ml-1" onClick={()=>setTimeRange(TIMESPAN.PM.FROM, TIMESPAN.PM.TO)}>午後</button>
                                </span>
                            </div>
                            <div className={"form-group " + styles["popup-row"]} style={{marginBottom:"2px"}}>
                                <label className={"col-form-label " + styles["popup-item-header"]}>開始</label>
                                <div>
                                    <span style={{display:"flex", alignItems:"center" }}>
                                        <FormDatePicker name="" value={current.start} onChange={onStDateSelected}
                                            placement="auto" portalContainerID={ID} />
                                        { !current.allDay && (
                                            <TimeSelector mobileStyle={{ marginLeft:"2px" }} value={moment(current.start).format("HH:mm")} onChange={onStTimeSelected}/>
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div className={"form-group " + styles["popup-row"]}>
                                <label className={"col-form-label " + styles["popup-item-header"]}>終了</label>
                                <div>
                                    <span style={{display:"flex", alignItems:"center"}}>
                                        <FormDatePicker name="" value={current.end} onChange={onEdDateSelected}
                                            placement="auto" portalContainerID={ID} />
                                        { !current.allDay && (
                                            <TimeSelector mobileStyle={{ marginLeft:"2px" }} value={moment(current.end).format("HH:mm")} onChange={onEdTimeSelected} />
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div className={"form-group " + styles["popup-row"]}>
                                <label className={"col-form-label " + styles["popup-item-header"]}>種別</label>
                                <div className={styles["popup-nested-row-content"]}>
                                    <span>{currentEventKind?.schedule?.sname ?? ""}</span>
                                    { canEditPreset && (<>
                                        <IconLink className={styles["popup-preset"]}
                                            text={current.preset?.preset_name ?? "処置選択"} iconType="popup"
                                            onClick={() => setIsPresetSelectShown(true)} />
                                        { current.preset != null && (
                                            <IconRight iconType="remove"
                                                onClick={() => setCurrentValue({
                                                    ...current,
                                                    preset: undefined
                                                })}
                                            />
                                        )}
                                    </>)}
                                    { showsPresetBenefitWarning && (
                                        <div className={styles["popup-benefit-warning"]}>{A.MESSAGE.PRESET_BENEFIT_IGNORED}</div>
                                    )}
                                </div>
                            </div>
                            <div className={"form-group " + styles["popup-row"]}>
                                <label className={"col-form-label " + styles["popup-item-header"]}>メモ</label>
                                <div style={{flex:1}}>
                                    <textarea className="form-control" rows={4} maxLength={LMT.SCHEDULE.MEMO_LEN} value={current.note}
                                                onChange={e => setCurrentValue({ ...current, note:e.target.value })} />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter className="modal-footer-fix">
                        <ExecutionButton type="decide" onClick={onSubmit} />
                    </ModalFooter>
                    { isPresetSelectShown && (
                        <TreatPresetSelectPopup
                            presets={masters.data.presets}
                            medicines={masters.data.medicines}
                            routes={masters.data.medicineRoutes}
                            treatItems={masters.data.treatItems}
                            selectedPreset={current.preset?.preset_id}
                            medicinesOnly={currentEventKind?.treatment === "medicine"}
                            onClose={() => setIsPresetSelectShown(false)}
                            onSubmit={onPresetSelected}
                        />
                    )}
                </>))}
            </Modal>
        </div>
    )
});