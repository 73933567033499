import React, { useState, useEffect } from 'react';
import { MobileView, BrowserView, isTablet } from 'react-device-detect';
import Select from 'react-select';
import { ar } from '../../config/util';
import { ACSelectOption } from './autocomplete-select';

export const TimeSelector: React.FC<{
    value: string;
    onChange: (value:string) => void;
    style?: React.CSSProperties;
    mobileStyle?: React.CSSProperties;
    browserStyle?: React.CSSProperties;
}> = (props) => {

    const [ hour, setHour ] = useState(0);
    const [ min, setMin ] = useState(0);

    useEffect(() => {
        if (props.value.length !== "hh:mm".length) return;
        const hStr = props.value.slice(0, 2);
        const mStr = props.value.slice(3, 5);
        const h = parseInt(hStr);
        if (isNaN(h) || h < 0 || 23 < h) return;
        const m = parseInt(mStr);
        if (isNaN(m) || m < 0 || 59 < m) return;
        setHour(h);
        setMin(m);

    }, [ props.value ]);


    const zeroPad = (n: number) => ("00" + n).slice(-2);

    const onHourChange = (val: number) => {
        props.onChange(zeroPad(val) + ":" + zeroPad(min));
    }
    const onMinChange = (val: number) => {
        props.onChange(zeroPad(hour) + ":" + zeroPad(val));
    }

    const hours: ACSelectOption<number>[] = ar.intRange(0, 24).map(n => ({ label: zeroPad(n), value:n }));
    const minutes: ACSelectOption<number>[] = ar.intRange(0, 60).map(n => ({ label: zeroPad(n), value:n }));

    const selectStyle = {
        control:(provided) => ({
            ...provided,
            width:"40px",
            padding: "0px!important",
            border: "0px",
        }),
        input:(provided)=>({
            ...provided,
        }),
        valueContainer:(provided) => ({
            ...provided,
            padding: "0 2px 0",
            justifyContent: "center"
        }),
        indicatorsContainer:(provided)=>({
            ...provided,
            display: "none"
        }),
        singleValue:(provided) => ({
            ...provided,
            margin: 0,
            color: "#348fe2",
        }),
        option:(provided) => ({
            ...provided,
            paddingLeft: "2px"
        })
    };

    return (
        <>
            <MobileView>
                <input style={{
                        width: "67px",
                        padding: "2px 0px 2px 1px",
                        textAlign: "center",
                        borderRadius: "4px",
                        border: "1px solid #d5dbe0",
                        background: "white",
                        fontSize: "14px",
                        ...props.style,
                        ...props.mobileStyle
                    }}
                    type="time" value={props.value} onChange={e => { if (e.target.value !== "" ) props.onChange(e.target.value)} }
                />
            </MobileView>
            {/* ※ iPadが両方にヒットする不具合対処 */}
            { !isTablet && (
            <BrowserView>
                <div data-testid="time-selector-pc" style={{ display:"flex", alignItems:"center", ...props.style, ...props.browserStyle }}>
                    <Select value={hours.find(h => h.value === hour)}
                        noOptionsMessage={()=>""}
                        onChange={e => onHourChange(e.value)}
                        isClearable={false}
                        styles={selectStyle}
                        options={hours}
                        className="time-selector-hour"
                    />
                    :
                    <Select value={minutes.find(m => m.value === min)}
                        noOptionsMessage={()=>""}
                        onChange={e => onMinChange(e.value)}
                        isClearable={false}
                        styles={selectStyle}
                        options={minutes}
                        className="time-selector-minute"
                    />
                </div>
            </BrowserView>
            )}
        </>
    )
}