import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { OptionalInputContainer } from '../parts/optional-input-container';
import PrevNextDatePicker from '../parts/prev-next-date-picker';
import { NextSchedulePopup, NextScheduleInputValue } from './next-schedule-popup';
import { ISchedule } from '../../pages/schedule/schedule-popup';
import { EventNextScheduleReq } from '../../api';
import { TIMESPAN } from '../../config/constant';
import { FormRadio } from '../form/form-radio';
import { CommonUtil } from '../../config/util';
import { usePageStore } from '../../config/page-settings';
import { AppState } from '../../app';

export type NextSchedule = Omit<ISchedule, "dispTitle"|"rawTitle"|"cows"|"id"|"status"|"results"|"editedInfo"|"creator"|"ranchId"|"clinicId"> & {
    title: string;
}
export const toNextScheduleReq = (schedule: NextSchedule, clinicId: number|undefined): EventNextScheduleReq => {
    return {
        event_kind_no: schedule.eventKindNo,
        title: schedule.title,
        start_at: moment(schedule.start).format(schedule.allDay ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm:00"),
        end_at: moment(schedule.end).format(schedule.allDay ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm:00"),
        has_time: schedule.allDay ? 0 : 1,
        note: schedule.note,
        preset_id: schedule.preset?.preset_id,
        clinic_id: clinicId
    }
}

export const nextScheduleDefaultTitleFormatter = (prefix: string | undefined, candidate: string | undefined) => {
    if (prefix == null) return candidate ?? "";
    if (candidate == null) return prefix;

    return `${prefix}（${candidate}）`;
}

export interface NextScheduleSelectorProps {
    baseDate?: Date | moment.Moment;
    defaultTitlePrefix?: string;
    defaultTitleSuffix?: string;
    titleCandidates?: string[];
    excludesOther?: boolean;
    titleFormatter?: (prefix: string|undefined, candidate: string|undefined) => string;
    label?: string;
    isSelected: boolean;
    value: NextSchedule;
    onChange: (selected: boolean, value: NextSchedule) => void;
    index: number;
    canTitleCandidateSelect?: (candidate: string|undefined, index: number) => boolean;
    portalContainerIDForDatePicker?: string;
    presetTeamId: number;
    ranchId: number;
}

const TIME_ALLDAY = 1;
const TIME_AM = 2;
const TIME_PM = 3;
type TimePreset = 1 | 2 | 3;
export const NextScheduleSelector: React.FC<NextScheduleSelectorProps> = (props) => {
    const [ isPopupShown, setIsPopupShown ] = useState(false);
    const [ selectedTitleIndex, setSelectedTitleIndex ] = useState(-1);
    const [ dayTimeKind, setDayTimeKind ] = useState<TimePreset>();

    const { showToast } = usePageStore() as AppState;

    const selectTitleFormatter = () => props.titleFormatter ?? nextScheduleDefaultTitleFormatter;

    useEffect(() => {
        if (props.titleCandidates == null) {
            setSelectedTitleIndex(-1);
            return;
        }

        const formatter = selectTitleFormatter();
        const idx = props.titleCandidates
                    .findIndex(t => formatter(props.defaultTitlePrefix, t) === props.value.title);
        setSelectedTitleIndex(idx);

    }, [ props.titleCandidates, props.titleFormatter, props.defaultTitlePrefix, props.value ])

    useEffect(() => {
        const getDay = (d: Date) => moment(d).format("YYYYMMDD");
        const isOneDay = getDay(props.value.start) === getDay(props.value.end);
        const getTime = (d: Date) => moment(d).format("H:mm");
        const st = getTime(props.value.start);
        const ed = getTime(props.value.end);

        const dtKind = (isOneDay && props.value.allDay) ? TIME_ALLDAY
                     : (isOneDay && st === TIMESPAN.AM.FROM && ed === TIMESPAN.AM.TO) ? TIME_AM
                     : (isOneDay && st === TIMESPAN.PM.FROM && ed === TIMESPAN.PM.TO) ? TIME_PM
                     : undefined;
        setDayTimeKind(dtKind);

    }, [ props.value ]);

    const onTitleCandidateSelect = (title: string | undefined) => {
        props.onChange(true, { ...props.value, title: selectTitleFormatter()(props.defaultTitlePrefix, title) });
    }

    const onDateSelect = (date: Date) => {
        const value: NextSchedule = {
            ...props.value,
            allDay: true,
            start: date,
            end: date,
        };
        props.onChange(true, value);
    }
    const onDayTimeKindSelect = (time: TimePreset) => {
        let value: NextSchedule;
        if (time === TIME_ALLDAY) {
            value = {
                ...props.value,
                allDay: true,
                start: moment(props.value.start).startOf("day").toDate(),
                end: moment(props.value.start).startOf("day").toDate(),
            };
        } else {
            const dayStr = moment(props.value.start).format("YYYY-MM-DD");
            const SPAN = time === TIME_AM ? TIMESPAN.AM : time === TIME_PM ? TIMESPAN.PM : undefined;
            if (!CommonUtil.assertNotNull(SPAN, "TIMESPAN of " + time)) return;

            const toHHmmss = (time: typeof TIMESPAN.AM.FROM | typeof TIMESPAN.AM.TO | typeof TIMESPAN.PM.FROM | typeof TIMESPAN.PM.TO) => {
                return time.length === "hh:mm".length ? `${time}:00` : `0${time}:00`;
            }

            value = {
                ...props.value,
                allDay: false,
                start: moment(`${dayStr} ${toHHmmss(SPAN.FROM)}`).toDate(),
                end:   moment(`${dayStr} ${toHHmmss(SPAN.TO)}`).toDate(),
            };
        }
        props.onChange(true, value);
    }

    const onPopupSubmit = (value: NextScheduleInputValue) => {
        setIsPopupShown(false);
        props.onChange(true, { ...value, eventKindNo: props.value.eventKindNo });
    }

    const isTitleCandidateDisabled = (t:string) => { return props.canTitleCandidateSelect == null ? false : !props.canTitleCandidateSelect(t, props.index) }

    return (<>
        <div style={{marginLeft:"8px"}} data-testid="next-schedule">
            <div className="checkbox checkbox-css" style={{marginBottom:"4px"}}>
                <input type="checkbox" id={`nextSchedule${props.index}`} onChange={e => props.onChange(e.target.checked, props.value)} 
                    checked={props.isSelected}/>
                <label htmlFor={`nextSchedule${props.index}`}>{props.label ?? "予定"}</label>
            </div>
            <OptionalInputContainer isSelected={props.isSelected} containerStyle={{display:"flex", flexDirection:"column"}}>

                { (props.titleCandidates ?? []).length > 0 && (
                    <div style={{background:props.isSelected ? "transparent" : "#e5e5e5", padding:"0 0 2px 6px", display: "flex", flexWrap: "wrap"}}>
                        { props.titleCandidates?.map((t,i) => (
                            <div key={i} className="radio radio-css" style={{flexShrink: 0, marginRight: "13px"}}>
                                <input type="radio" name={`radioInputType${props.index}`} id={`radTitle${props.index}${i}`} 
                                    checked={selectedTitleIndex === i}
                                    onChange={() => onTitleCandidateSelect(t)} 
                                    disabled={isTitleCandidateDisabled(t)}/>
                                <label htmlFor={`radTitle${props.index}${i}`} style={{color: isTitleCandidateDisabled(t) ? "#999999" : "inherit" }}>{t}</label>
                            </div>
                        ))}
                        { !props.excludesOther && (
                            <div className="radio radio-css">
                                <input type="radio" name={`radioInputType${props.index}`} id={`radTitleOther${props.index}`}
                                        checked={selectedTitleIndex === -1}
                                        onChange={() => onTitleCandidateSelect(undefined)} />
                                <label htmlFor={`radTitleOther${props.index}`}>その他</label>
                            </div>
                        )}
                    </div>
                )}

                { selectedTitleIndex === -1 && props.value.title !== "" && props.value.title !== selectTitleFormatter()(props.defaultTitlePrefix, props.titleCandidates ? props.titleCandidates[selectedTitleIndex] : "") && (
                    <div data-testid="title-custom" style={{color:"#aaa", margin:"0 6px", fontSize:"0.8rem"}}>タイトル：{props.value.title}</div>
                )}

                <div style={{display:"flex", flexWrap:"nowrap", alignItems:"flex-start", minHeight:"40px", marginTop:"14px"}}>
                    <div style={{ margin:"8px 2px 0 6px", width:"60px", fontWeight:"bold", fontSize:"0.875rem", color:props.isSelected ? "#999" : "#aaa" }}>予定日</div>
                    { dayTimeKind != null && (
                        <PrevNextDatePicker name="" baseDate={props.baseDate} value={props.value.start}
                            popperPlacement="auto"
                            portalContainerID={props.portalContainerIDForDatePicker}
                            onChange={ e =>  onDateSelect(e.target.value) }/>
                    )}
                    { dayTimeKind == null && props.value.allDay && (
                        <div style={{display:"flex", flexWrap:"wrap"}} data-testid="custom-term">
                            <span>{moment(props.value.start).format("YYYY/MM/DD")}</span>
                            <span>～{moment(props.value.end).format("YYYY/MM/DD")}</span>
                        </div>
                    )}
                    { dayTimeKind == null && !props.value.allDay && (
                        <div style={{display:"flex", flexWrap:"wrap"}} data-testid="custom-term">
                            <span>{moment(props.value.start).format("YYYY/MM/DD HH:mm")}</span>
                            <span>～{moment(props.value.end).format("YYYY/MM/DD HH:mm")}</span>
                        </div>
                    )}
                </div>
                { dayTimeKind != null && (
                    <div style={{ marginLeft:"62px", display:"flex", flexWrap:"wrap" }}>
                        <FormRadio prefix={"radTimePreset" + props.index}
                            isInline={false}
                            value={dayTimeKind}
                            options={[
                                { name: "終日", value: TIME_ALLDAY }, { name: "午前", value: TIME_AM }, { name: "午後", value: TIME_PM }
                            ]}
                            onChange={n => onDayTimeKindSelect(n as TimePreset)}
                        />
                    </div>
                )}
                <div style={{alignSelf:"flex-end", margin:"14px 4px 0 0"}} className="link"
                    onClick={ () => { setIsPopupShown(true);} }>詳細を設定</div>
            
            </OptionalInputContainer>
        </div>
        { isPopupShown && (
            <NextSchedulePopup
                showToast={ showToast }
                onClose={ () => setIsPopupShown(false) }
                onSubmit={ onPopupSubmit }
                original={ props.value }
                ranchId={ props.ranchId }
                presetTeamId={ props.presetTeamId }
            />
        )}
    </>)
}