import React from 'react';

export class FormPage<P,S> extends React.Component<P,S> {

	constructor(props) {
		super(props);

		this.onInputChange = this.onInputChange.bind(this);
		this.onRadioChange = this.onRadioChange.bind(this);
		this.onCheckChange = this.onCheckChange.bind(this);
		this.onSelectChange = this.onSelectChange.bind(this);
		this.onDateChange = this.onDateChange.bind(this);
		this.onRangeChange = this.onRangeChange.bind(this);
		this.onSearchGroupChange = this.onSearchGroupChange.bind(this);
		this.onValueChange = this.onValueChange.bind(this);
		this.onNumValueChange = this.onNumValueChange.bind(this);
		this.onStateChange = this.onStateChange.bind(this);
		this.onModalClose = this.onModalClose.bind(this);
		this.onImageChange = this.onImageChange.bind(this);
		this.onSliderChange = this.onSliderChange.bind(this);
	}

	onInputChange(e: React.ChangeEvent<HTMLInputElement>, name: string) {
		let attr = {};
		attr[name] = e.target.value;
		this.setState(attr);
	}

	onRadioChange(e, name) {
		this.onValueChange(e, name);
	}

	onCheckChange(e: React.ChangeEvent<HTMLInputElement>, name: string) {
		this.onStateChange(e.target.checked, name);
	}

	onSliderChange(value: number, name: string) {
		this.onValueChange(value, name);
	}

	onSelectChange(e, name) {
		let attr = {};
		let names = name.split(".");
		if (names.length === 0) {
			return;
		} else if (names.length === 1) {
			attr[name] = e.target.value;
		} else {
			attr[names[0]] = this.state[names[0]];
			attr[names[0]][names[1]] = e.target.value;
		}
		this.setState(attr);
	}

	onDateChange(value: {target: {value: Date}} | Date, name: string) {
		if (!(value instanceof Date) && value.target && value.target.value !== undefined) {
			value = value.target.value;
		}
		let attr = {};
		let names = name.split(".");
		if (names.length === 0) {
			return;
		} else if (names.length === 1) {
			attr[name] = value;
		} else {
			attr[names[0]] = this.state[names[0]];
			attr[names[0]][names[1]] = value;
		}
		this.setState(attr);
	}

	onRangeChange(date_obj, name) {
		var attr = {};
		attr[name] = this.state[name];
		attr[name].start = date_obj['start'];
		attr[name].end = date_obj['end'];
		this.setState(attr);
	}

	onSearchGroupChange(data, name) {
		var attr = {};
		attr[name] = data;
		this.setState(attr);
	}

	onValueChange(e: any, name: string) {
		if (e.target && e.target.value !== undefined) {
			this.onStateChange(e.target.value, name);
		} else {
			this.onStateChange(e, name);
		}
	}

	onNumValueChange(e: any, name: string) {
		if (e.target && e.target.value !== undefined) {
			this.onStateChange(Number(e.target.value), name);
		} else {
			this.onStateChange(Number(e), name);
		}
	}

	onStateChange(value: any, name: string) {
		try {
			if (value.target && value.target.value !== undefined) {
				value = value.target.value;
			}
			let attr = {};
			let names = name.split(".");

			if (names.length === 0) {
				return;
			} else if (names.length === 1) {
				attr[name] = value;
			} else if (names.length === 2) {
				attr[names[0]] = this.state[names[0]];
				attr[names[0]][names[1]] = value;
			} else if (names.length === 3) {
				attr[names[0]] = this.state[names[0]];
				attr[names[0]][names[1]][names[2]] = value;
			} else if (names.length === 4) {
				attr[names[0]] = this.state[names[0]];
				attr[names[0]][names[1]][names[2]][names[3]] = value;
			} else if (names.length === 5) {
				attr[names[0]] = this.state[names[0]];
				attr[names[0]][names[1]][names[2]][names[3]][names[4]] = value;
			} else if (names.length === 6) {
				attr[names[0]] = this.state[names[0]];
				attr[names[0]][names[1]][names[2]][names[3]][names[4]][names[5]] = value;
			} else if (names.length === 7) {
				attr[names[0]] = this.state[names[0]];
				attr[names[0]][names[1]][names[2]][names[3]][names[4]][names[5]][names[6]] = value;
			} else {
				attr[names[0]] = this.state[names[0]];
				attr[names[0]][names[1]][names[2]][names[3]][names[4]][names[5]][names[6]][names[7]] = value;
			}
			this.setState(attr);
		} catch (e) {
			console.log(e);
		}
	}

	onModalClose(name) {
		var attr = {};
		attr[name] = false;
		this.setState(attr);
	}

	onImageChange(value, name) {
		if (value.target && value.target.value) {
			value = value.target.value;
		}

		let attr = {};
		let names = name.split(".");

		if (names.length === 0) {
			return;
		} else if (names.length === 1) {
			attr[name] = value;
		} else if (names.length === 2) {
			attr[names[0]] = this.state[names[0]];
			attr[names[0]][names[1]] = value;
		} else if (names.length === 3) {
			attr[names[0]] = this.state[names[0]];
			attr[names[0]][names[1]][names[2]] = value;
		} else {
			attr[names[0]] = this.state[names[0]];
			attr[names[0]][names[1]][names[2]][names[3]] = value;
		}
		this.setState(attr);
	}
}