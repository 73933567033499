import React, { useState } from 'react';
import classnames from 'classnames'
import { CowListDto } from '../../api';
import { FreezedArray } from '../../config/util';
import { IRanchHouse } from '../../stores';
import styles from './housetree.module.css';
import { CowsPopup } from './cows-popup';

type House = {
  id : string,
  levels: Array<number>,
}
type TreeStateLeaf = {
  name      : string,
  route     : Array<number>,
  selected  : boolean | undefined,
  cows      : Array<CowListDto>,
}
type TreeStateNode = {
  name      : string,
  route     : Array<number>,
  selected  : boolean | undefined,
  fold      : boolean,
  children  : Array<TreeStateNode | TreeStateLeaf>,
  anyCows   : boolean
}
type HouseTreePropType = {
  cows      : Readonly<Array<CowListDto>>,
  checkedCowIds: Readonly<Set<number>>,
  setCheckedCowIds: React.Dispatch<React.SetStateAction<Set<number>>>,
  singleSelection?: boolean,
  style?    : React.CSSProperties,
  onSubmit? : (cowId: number) => void,
  ranchHouses: FreezedArray<IRanchHouse>;
}
  
export const HouseTree: React.FC<HouseTreePropType> = ({ cows, checkedCowIds, setCheckedCowIds, singleSelection, style, onSubmit, ranchHouses }) => {

  const [folds, setFolds] = useState<Array<Array<number>>>([])

  const sameArray = (a: Array<number>, b: Array<number>) => a.length === b.length && a.every((v,i) => v === b[i])
  const sameRoute = (a: Array<number>, b: Array<number>) => a.length <= b.length && a.every((v,i) => v === b[i])

  const treeState = ranchHouses.map<TreeStateNode>(level1 => {
    const level2Node = level1.data?.map<TreeStateNode>(level2 => {
      const level3Node = level2.data?.map<TreeStateLeaf>(level3 => {
        const cowsHere = cows.filter(c => sameRoute([level1.no, level2.no, level3.no], [c.site, c.barn, c.room ]))
        const selected = cowsHere.every(c => checkedCowIds.has(c.cow_id)) ? true
                       : cowsHere.some(c => checkedCowIds.has(c.cow_id)) ? undefined
                       : false;
        return { name: level3.name, route: [level1.no, level2.no, level3.no], selected, cows:cowsHere }
      }) ?? []
      const selected = level3Node.every(h => h.selected) ? true
                     : level3Node.some(h => h.cows.length > 0 && h.selected !== false) ? undefined
                     : false
      const fold = folds.some(f => f.length === 2 && f[0] === level1.no && f[1] === level2.no)
      return { name: level2.name, route: [level1.no, level2.no], selected, fold, children: level3Node, anyCows: level3Node.some(l => l.cows.length > 0) }
    }) ?? []
    const selected = level2Node.every(h => h.selected) ? true
                   : level2Node.some(h => h.anyCows && h.selected !== false) ? undefined
                   : false
    const fold = folds.some(f => f.length === 1 && f[0] === level1.no)
    return { name: level1.name, route: [level1.no], selected, fold, children: level2Node, anyCows: level2Node.some(l => l.anyCows) }
  })

  const allHouses: Array<House> = treeState.map(level1 =>
    level1.children.map(level2 => ('children' in level2) ? level2.children.map<House>(level3 => ({id: level3.name, levels: level3.route})) : [] )
  ).reduce((a,v) => a.concat(...v), [] as House[]) // same as .flat(2)

  const toggleSelect = (event: React.ChangeEvent<HTMLInputElement>, route: Array<number>) => {
    const cowsHere = cows.filter(c => sameRoute(route, [c.site, c.barn, c.room]));
    const cowIds = new Set<number>([...checkedCowIds.keys()]);
    const checked = (event.target as HTMLInputElement).checked;
    cowsHere.forEach(c => checked ? cowIds.add(c.cow_id) : cowIds.delete(c.cow_id));
    setCheckedCowIds(cowIds);
  }
  const toggleFold = (route: Array<number>) => {
    setFolds(prevState => prevState.some(s => sameArray(s, route)) ? prevState.filter(s => !sameArray(s, route)) : [...prevState, route])
  }


  const renderLeaf = ({name, route, selected, cows }: TreeStateLeaf, depth: number) => {
    
    return (
      <div className={ classnames(styles.row, styles['row-level' + depth]) } key={ route.join('_') } data-testid={`部屋:Lv${depth}__${name}`}>
        <div>
          <span>{ name } </span>
          { cows.length > 0 && (<>
            <CowsPopup
              height="20px"
              placement="top"
              cows={cows.map(c => ({ ...c, isChecked: checkedCowIds.has(c.cow_id) }))}
              isCheckable={true}
              onCowClick={onSubmit == null ? undefined : c => onSubmit(c.cow_id)}
              isCheckedChanged={(id,_,chk) => {
                const cowIds = new Set<number>([...checkedCowIds.keys()])
                if (chk) {
                  if (singleSelection) {
                    cowIds.clear();
                  }
                  cowIds.add(id);
                } else {
                  cowIds.delete(id);
                }
                setCheckedCowIds(cowIds);
              } }
            />
            <span className={styles["cow-count"]}>{cows.length}</span>
          </>)}
          { cows.some(c => c.watching)
            ? (<i style={{ marginLeft:"4px", color:"#ff3333" }} className="fas fa-sm fa-exclamation"></i>)
            : cows.some(c => c.watching_memo !== "")
            ? (<i style={{ marginLeft:"4px", color:"#ffc666" }} className="fas fa-sm fa-exclamation"></i>)
            : (<></>)}
        </div>
        { cows.length > 0 && !singleSelection && (
          <input type="checkbox" className={ classnames(styles['row-checkbox'], { [styles.indeterminate]: selected == null}) } checked={ selected === true } onChange={ e => toggleSelect(e, route) } />
        )}
      </div>
    )
  }

  const renderNode = ({name, route, fold , selected, children, anyCows }: TreeStateNode, depth: number) =>
    fold ? (
      <div className={ classnames(styles.row, styles['row-level' + depth], styles['row-collapsed']) } key={ route.join('_') } data-testid={`部屋:Lv${depth}__${name}`}>
        <div onClick={e => toggleFold(route)}>
          <i className="fas fa-caret-right"></i>
          <span>{ name }</span>
        </div>
        { anyCows && !singleSelection && (
          <input type="checkbox" className={ classnames(styles['row-checkbox'], { [styles.indeterminate]: selected == null} ) } checked={ selected === true } onChange={ e => toggleSelect(e, route) } />
        )}
      </div>
    )
    :
    (
      <React.Fragment key={ route.join('_') }>
        <div className={ classnames(styles.row, styles['row-level' + depth], styles['row-expand']) } key={ route.join('_') } data-testid={`部屋:Lv${depth}__${name}`}>
          <div onClick={e => toggleFold(route)}>
            <i className="fas fa-caret-down"></i>
            <span>{ name }</span>
          </div>
          { anyCows && !singleSelection && (
            <input type="checkbox" className={ classnames(styles['row-checkbox'], { [styles.indeterminate]: selected == null} ) } checked={ selected } onChange={ e => toggleSelect(e, route) } />
          )}
        </div>
        { children.map(child => renderer(child, depth + 1)) }
      </React.Fragment>
    )



  const renderer = (state: TreeStateLeaf | TreeStateNode, depth: number) =>
    'children' in state ? renderNode(state, depth) : renderLeaf(state, depth)

  return (
    <div style={style} data-testid="house-tree">
      { treeState.map(state => renderer(state, 1)) }
    </div>
  )
}
  