import { TeamMedicineRouteDto } from "../api";

type MedicineRoute = Pick<TeamMedicineRouteDto, "route_id"|"is_free_medicine">;
type Treatment = { medicine_id:number|undefined, unit_price:number };

export const calcTreatUnitPrice = (treat: Treatment, route: MedicineRoute | undefined) => {
    const isMedicine = (treat.medicine_id ?? 0) !== 0;

    if (!isMedicine) {
        return treat.unit_price;
    }

    if (route?.is_free_medicine === 1) return 0;

    return treat.unit_price;
}