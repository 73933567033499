import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PageSettings } from '../../config/page-settings.js';
import styles from './symptom.module.css'
import { renderSymptomStr, renderTreat, renderDisease } from '../cow/cow-event-renderer';
import moment from "moment";
import { AppState } from '../../app';
import { IFecesState, IFecesColor, IUser } from '../../stores';
import { getSymptomStatus } from '../../config/constant';
import { hasRanchAuth } from '../../config/auth-checker';
import { Communicator } from '../../api/communicator';
import { SymptomApi, SymptomInfoReq, SympEventDto } from '../../api/';
import { FreezedArray } from '../../config/util';

interface MyState {
    symptoms: SympEventDto[];
}
interface SymptomSeriesPopupProps {
    onClose: () => void;
    onAddSymptom?: () => void;
    data: {
        first_id: number,
        user: Readonly<IUser>,
        ranch_id: number,
        feces_colors: FreezedArray<IFecesColor>,
        feces_states: FreezedArray<IFecesState>,
    };
    comm: Communicator;
}

export class SymptomSeriesPopup extends React.Component<SymptomSeriesPopupProps, MyState> {
    
    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            symptoms: [],
        }

        this.onClose = this.onClose.bind(this);
    }

    componentDidMount() {
        this.api_getGetSymptiomSeriesList();
    }

    componentDidUpdate(prevProps: this["props"]) {
        if( this.props.data.first_id !== prevProps.data.first_id) {
            this.api_getGetSymptiomSeriesList();
        }
    }

    onClose() {
        this.props.onClose();
    }

    getAmountWithUnit(amount: number | null, treat_item_unit: string) {
        if (amount == null) { return ""; }
        return amount + treat_item_unit;
    }

    api_getGetSymptiomSeriesList = async () => {

        const params: SymptomInfoReq = {
            ranch_id: this.props.data.ranch_id,
            first_id: this.props.data.first_id,
        };

        this.context.handleSetIsLoading(true);
        const res = await this.props.comm.send((await SymptomApi()).getSeriesListUsingPOST(params));
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") return;
        const symptoms = res.data ?? [];
        this.setState({
            symptoms: symptoms,
        });
    }

    render() {
        return (
            <div className="modal-wide">
                <Modal isOpen={true} toggle={this.props.onClose} style={{ maxWidth: "360px", margin: "auto" }}>
                    <ModalHeader toggle={this.props.onClose}>診療記録確認</ModalHeader>
                    <ModalBody style={{ overflowY: "auto", maxHeight: "calc(100vh - 150px)" }}>
                    {
                        this.state.symptoms.map((symptom, i) => (
                            <div key={i} className={styles["symptom-series-item"]}>
                                <div className="row" style={{ marginLeft: "0px", marginRight: "0px" }}>
                                    <span style={{ lineHeight: "28px", fontWeight: "bold", fontSize: "0.9rem" }}>{moment(symptom.watched_at).format("M月D日 HH:mm")}</span>
                                    <span style={{ lineHeight: "28px", fontSize: "0.9rem", marginLeft: "10px" }}>記録者：{symptom.watched_by}</span>
                                </div>
                                {
                                    (symptom.status) && (
                                        <div className="row m-t-5" style={{ marginLeft: "0px", marginRight: "0px", display: "flex" }}>
                                            <div >
                                                <span>［状態］&nbsp;</span>
                                            </div>
                                            <div style={{ flex: "1" }}>
                                                {getSymptomStatus(symptom.status)?.name}
                                            </div>                                                
                                        </div>                                                
                                    )
                                }
                                <div className="row m-t-5" style={{ marginLeft: "0px", marginRight: "0px", display: "flex" }}>
                                    <div >
                                        <span>［体調］&nbsp;</span>
                                    </div>
                                    <div style={{ flex: "1" }}>
                                        {renderSymptomStr(symptom, this.props.data.feces_states, this.props.data.feces_colors)}
                                    </div>
                                </div>
                                { renderDisease(symptom) }
                                {
                                    symptom.treat.map((treat, k) => (
                                        <div key={k}>
                                            { renderTreat(treat, hasRanchAuth("TREAT_REF_AMOUNT", this.props.data.ranch_id, this.props.data.user)) }
                                        </div>
                                    ))
                                }
                                {
                                    (symptom.comment != null && symptom.comment !== "") && (
                                        <div className="row m-t-5" style={{ marginLeft: "0px", marginRight: "0px" }}>
                                            <span><i className="fas fa-lg fa-fw m-r-5 fa-comment" style={{ marginTop: "4px" }}></i>{symptom.comment}</span>
                                        </div>
                                    )
                                }
                            </div>
                        ))
                    }
                    </ModalBody>
                    <ModalFooter style={{height: "60px", justifyContent:"center"}}>
                        { (this.props.onAddSymptom != null) && (
                            <a className="link" onClick={ this.props.onAddSymptom }>
                                再診/継続記録を追加
                            </a>
                        )}
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}