import { AppState } from "../../app";
import { RanchScheduleReq, RanchApi } from "../../api";
import moment from "moment";
import { CommonUtil } from "../../config/util";
import { EventKind } from "../../config/event-kind";
import { Communicator } from "../../api/communicator";

export interface FindScheduleResult {
    /**
     * yes: 関連するスケジュールの状態を更新
     * no: 関連するスケジュールがない、または状態を更新しない
     * cancel: 処理を中止
     */
    result: "yes" | "no" | "cancel";
    id: number | undefined;
}

export class ScheduleFinder {

    readonly communicator: Communicator;

    constructor(readonly context: AppState,
        readonly ranch_id: number,
        readonly user_id: string) {

        this.communicator = new Communicator({ ...context, logout: () => {}});
    }

    /**
     * 指定した条件のスケジュールがあれば、完了に更新するかどうかを確認し、結果を返す
     * @param day YYYY-MM-DD
     * @param eventKindNo 
     * @param cowId 
     */
    async findEventRelatedSchedule(day: string, eventKindNo: number[], cowId: number): Promise<FindScheduleResult> {
        const req: RanchScheduleReq = {
            ranch_id: this.ranch_id,
            cow_id: [ cowId ],
            start_at: day,
            end_at: day,
            event_kind_no: eventKindNo
        }
        this.context.handleSetIsLoading(true);
        const res = await this.communicator.send((await RanchApi()).getScheduleListUsingPOST(req), { showsErrorMessage: false });
        this.context.handleSetIsLoading(false);

        if (res.result !== "OK") {
            return { result:"no", id:undefined };
        }

        const list = res.data ?? [];
        const targets = list.filter(s => s.cows?.length === 1 && s.results.length === 0);
        //複数該当あるときは不可
        if (targets.length !== 1) {
            return { result:"no", id:undefined }
        }

        const target = targets[0];

        let kindName = EventKind.find(target.event_kind_no)?.schedule?.sname;
        if (!CommonUtil.assertNotNull(kindName, "eventKind name of " + target.event_kind_no)) {
            return { result:"no", id:undefined };
        }
        if (!kindName.endsWith("予定")) {
            kindName += "予定";
        }

        const confMsg = `この牛の${moment(targets[0].start_at).format("M月D日")}の${kindName}を完了にしますか？`;
        const confirm = await this.context.showQuestionAsync(confMsg, [ "はい", "いいえ" ]);

        return ({
            result: (confirm === 0 ? "yes" : confirm === 1 ? "no" : "cancel"),
            id: targets[0].schedule_id
        });

    }

}