import React from 'react';
import { DynamicsSettingItemData } from './dynamics';
import { ar, FreezedArray } from '../../config/util';
import { RequiredNumInput } from '../../components/parts/num-input';
import { ICowUse } from '../../stores';

interface MyProps {
    useList: FreezedArray<ICowUse>;
    data: Readonly<DynamicsSettingItemData>;
    onValueChange: (data: DynamicsSettingItemData) => void;
    step: number;
    min: number;
    max: number;
}

export const DynamicsTable = (props: MyProps) => {

    const styles: {[key: string]: React.CSSProperties } = {
        container: {
            display: "grid",
            gridTemplateColumns: "minmax(60px, 100px) repeat(12, 66px)",
            gridAutoRows: "auto",
            gridAutoFlow: "row",
            alignItems: "center",
            justifyItems: "center",
            gridRowGap: "5px",
            gridColumnGap: "2px"
        },
        columnHeader: {
        },
        rowHeader: {
            justifySelf:"end",
            paddingRight:"4px"
        },
        cell: {
        }
    };

    const onValueChange = (useNo: number, index: number, value: number) => {
        const data = new Map<number, number[]>();
        [...props.data.entries()]
            .forEach(([uNo,vals]) => {
                const newVals = [...vals];
                if (uNo === useNo) {
                    newVals[index] = value;
                }
                data.set(uNo, newVals);
            });
        props.onValueChange(data);
    }

    return (
        <div style={styles.container}>
            <div />
            { ar.intRange(1, 12).map(n => <div key={n} style={styles.columnHeader}>{n}月</div>)}

            { props.useList
                .map(u => (
                <React.Fragment key={u.use_no}>
                    <div style={styles.rowHeader}>{u.name}</div>
                    { (props.data.get(u.use_no) ?? []).map((v,i) => (
                        <RequiredNumInput style={styles.cell} key={i} value={v}
                            step={props.step} max={props.max} min={props.min}
                            onChange={n => onValueChange(u.use_no, i, n)}
                        />
                    ))}
                </React.Fragment>
            ))}
        </div>

    );
}