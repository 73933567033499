import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { A } from '../../config/constant';
import styles from './setting.module.css';
import { PageSettings } from '../../config/page-settings';
import { TreatEditPopup } from './treat-edit-popup';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ITreatKind, ITreatItem } from '../../stores/RootStore';
import { withContext } from '../../stores';
import { AppState } from '../../app';
import { TeamTreatItemModifyReq, TeamTreatItemDeleteReq, TeamTreatItemSortReq } from '../../api';
import { CommonUtil } from '../../config/util';
import { DIALOG_BUTTONS } from '../../components/form/form-dialog';
import { SortableList } from '../../components/parts/sortable-list';
import { getTreatItems, resetTreatItems } from '../../stores/fetcher';
import { UserTeams } from 'config/user-teams';


interface MyProps extends BaseProps<{id:string}> {
}

interface MyState {
    teamId?: number;
    treatItemList: { [kind_no:number]: ITreatItem[] };
    sortingList: ITreatItem[];
    isSorting: boolean;
    isEditing: boolean;
    editingData: IEditingTreat | null;
    treatKindList: ITreatKind[];
    currentTreatKind: number | null;
    sliderTab?: Slider;
    sliderContent?: Slider;
    executing: boolean;
}

export type IEditingTreat = {
    readonly isNew: boolean;
    readonly treat_kind_no: number;
    readonly treat_item_no?: number;
    name: string;
    readonly team_id: number;
    fee : number;
    point_a : number | null;
    point_b : number | null;
    code : string;
    fee_unit : string | null;
    hasUnit: boolean;
}

class TreatMaster extends Base<MyProps, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            treatItemList: {},
            isSorting: false,
            sortingList: [],
            isEditing: false,
            editingData: null,
            treatKindList: [],
            currentTreatKind: null,
            executing: false,
        };

        this.startSorting = this.startSorting.bind(this);
        this.finishSorting = this.finishSorting.bind(this);
        this.cancelSorting = this.cancelSorting.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onCancelEdit = this.onCancelEdit.bind(this);
        this.onSubmitEdit = this.onSubmitEdit.bind(this);
        this.startEdit = this.startEdit.bind(this);
        this.addItem = this.addItem.bind(this);
        this.onTabChanged = this.onTabChanged.bind(this);
        this.onNameEdited = this.onNameEdited.bind(this);
        this.onHasUnitChanged = this.onHasUnitChanged.bind(this);
        this.onUnitEdited = this.onUnitEdited.bind(this);
        this.onFeeEdited = this.onFeeEdited.bind(this);
        this.onPointAEdited = this.onPointAEdited.bind(this);
        this.onPointBEdited = this.onPointBEdited.bind(this);
        this.onCodeEdited = this.onCodeEdited.bind(this);
    }

    componentDidMount() {

        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        this.init();
    }

    componentDidUpdate(prevProps: this['props']) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.init();
        }
    }

    private init() {
        const team_id = Number(this.props.match.params.id);
        if (this.handleNotAllowAccess(team_id,["MASTER_EDIT"],["MASTER_EDIT"])) {
            return;
        }
        const isRanch = new UserTeams(this.props.rootStore.user).findRanch(team_id) != null;
        this.context.handleSetHeader({ title:"処置の設定", iconType:isRanch ? "ranch" : "clinic" });

        const kindList = this.props.rootStore.options.treat_kind;

        this.setState({
            teamId: team_id,
            treatKindList: kindList,
            currentTreatKind: kindList.length === 0 ? null : kindList[0].treat_kind_no
        }, () => {
            this.reloadList();
        });
    }

    private async reloadList() {
        const team_id = this.state.teamId;
        if (team_id == null || team_id === 0) return;

        this.context.handleSetIsLoading(true);
        await resetTreatItems(team_id, false);
        const items = await getTreatItems(team_id);
        this.context.handleSetIsLoading(false);
        if (items == null) {
            this.context.showToast("データ取得に失敗しました");
            return;
        }
        const newList = this.groupBy(items, t => t.treat_kind_no);

        this.setState({
            treatItemList: newList
        });
    }

    private groupBy<TItem>(items: TItem[], selector: (item: TItem) => number): { [index:number]: TItem[] } {
        const rtn = {};

        for (const item of items) {
            const key = selector(item);
            let l = rtn[key];
            if (l == null) {
                rtn[key] = [];
                l = rtn[key];
            }
            l.push(item);
        }

        return rtn;
    }


    private startSorting() {
        if (this.state.currentTreatKind == null) return;

        if (this.state.isSorting) {
            console.error("already sorting");
            return;
        }

        this.setState({
            isSorting: true,
            sortingList:　[...this.state.treatItemList[this.state.currentTreatKind]??[]]
        });
    }
    private cancelSorting() {
        if (!this.state.isSorting) {
            console.error("not sotring on cancel");
            return;
        }

        this.setState({
            isSorting: false,
            sortingList:[]
        });
    }
    private async finishSorting() {
        if (this.state.currentTreatKind == null) return;

        if (!this.state.isSorting) {
            console.error("not sorting on finish");
            return;
        }
        if (!CommonUtil.assertNotNull(this.state.teamId)) return;

        if (this.state.treatItemList[this.state.currentTreatKind] == null) {
            this.setState({
                isSorting: false,
                sortingList:[]
            });
            return;
        }

        const newOrder = this.state.sortingList.map(p => p.treat_item_no);
        const oldOrder = this.state.treatItemList[this.state.currentTreatKind].map(p => p.treat_item_no);
        if (JSON.stringify(newOrder) === JSON.stringify(oldOrder)) {
            this.setState({
                isSorting: false,
                sortingList:[]
            });
            return;
        }

        //ソート要求
        this.context.handleSetIsLoading(true);
        const req: TeamTreatItemSortReq = {
            order: newOrder,
            treat_kind_no: this.state.currentTreatKind,
            team_id: this.state.teamId,
            user_id: this.props.rootStore.user.id
        };
        const res = await this.comm().send(() => this.context.postAsync("/team/treat/item/sort", req));
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") return;

        this.setState({
            isSorting: false,
            sortingList:[]
        });

        this.reloadList();
    }

    private addItem() {
        if (this.state.currentTreatKind == null) return;

        if (!CommonUtil.assertNotNull(this.state.teamId, "teamId", "addItem")) return;
        this.setState({
            isEditing: true,
            editingData: {
                isNew: true,
                name: "",
                team_id: this.state.teamId,
                treat_kind_no: this.state.currentTreatKind,
                fee: 0,
                fee_unit: null,
                point_a: null,
                point_b: null,
                code: "",
                hasUnit: false
            }
        })
    }

    private startEdit(treat: ITreatItem) {
        this.setState({
            isEditing: true,
            editingData: {
                isNew: false,
                team_id: treat.team_id,
                treat_kind_no: treat.treat_kind_no,
                treat_item_no: treat.treat_item_no,
                name: treat.name,
                fee: treat.fee,
                fee_unit: treat.fee_unit,
                point_a: treat.point_a,
                point_b: treat.point_b,
                code: treat.code ?? "",
                hasUnit: (treat.fee_unit ?? "") !== ""
            }
        });
    }


    private async onSubmitEdit() {
        const data = this.state.editingData;
        if (!CommonUtil.assertNotNull(data, "editingData", "submitEdit")) return;

        if (data.name === "") {
            this.context.showToast(A.MESSAGE.NO_TREAT_NAME_INPUT);
            return;
        }
        if (data.hasUnit && (data.fee_unit ?? "") === "") {
            this.context.showToast(A.MESSAGE.NO_TREAT_UNIT_INPUT);
            return;
        }

        try {
            this.setState({ executing: true });

            //更新要求
            this.context.handleSetIsLoading(true);
            const req: TeamTreatItemModifyReq = {
                is_new: data.treat_item_no == null ? 1 : 0,
                team_id: data.team_id,
                treat_kind_no: data.treat_kind_no,
                treat_item_no: data.treat_item_no ?? 0,
                name: data.name,
                fee: data.fee,
                fee_unit: data.hasUnit ? (data.fee_unit ?? undefined) : undefined,
                point_a: data.point_a ?? undefined,
                point_b: data.point_b ?? undefined,
                code: data.code,
                user_id: this.props.rootStore.user.id
            };
            const res = await this.comm().send(() => this.context.postAsync("/team/treat/item/modify", req));
            this.context.handleSetIsLoading(false);
            if (res.result !== "OK") return;

            this.setState({
                isEditing: false,
                editingData: null
            });

        } finally {
            this.setState({ executing: false });
        }

        this.reloadList();
    }

    private async onDelete() {
        const res = await this.context.showDialog("QUESTION", '処置項目を削除してよろしいですか？', DIALOG_BUTTONS.DELETE_CANCEL);
        if (res === 0) {
            this._execDelete();
        }
    }
    //削除実行
    private async _execDelete() {
        const data = this.state.editingData;
        if (!CommonUtil.assertNotNull(data, "editingData", "delete")) return;
        if (!CommonUtil.assertNotNull(data.treat_item_no, "treat_item_no", "delete")) return;

        this.context.handleSetIsLoading(true);
        const req: TeamTreatItemDeleteReq = {
            treat_item_no: data.treat_item_no,
            treat_kind_no: data.treat_kind_no,
            team_id: data.team_id,
            user_id: this.props.rootStore.user.id
        };
        const res = await this.comm().send(() => this.context.postAsync("/team/treat/item/delete", req), { excludedErrCodes:[ A.ERR_CODE.ERR_USING_DATA ] });
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") return;

        if (res.code === A.ERR_CODE.ERR_USING_DATA) {
            this.context.showDialog("WARNING", A.MESSAGE.TREAT_ITEM_USED_IN_PRESET);
            return;
        }

        this.setState({
            isEditing: false,
            editingData: null
        });

        this.reloadList();
    }

    private onCancelEdit() {
        this.setState({
            editingData: null,
            isEditing:false
        })
    }

    readonly sliderContentSettings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:false
    };
    readonly sliderTabSettings = {
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows:false,
        responsive: [{
            breakpoint: 768,
            settings: {
                slidesToShow: 3,
            }
        }]
    };

    private onTabChanged(index:number) {
        this.setState({
            currentTreatKind: this.state.treatKindList[index].treat_kind_no
        });
    }

    private onNameEdited(name: string) {
        if (!CommonUtil.assertNotNull(this.state.editingData, "editingData", "nameEdited")) return;
        const data = { ...this.state.editingData};
        data.name = name;
        this.setState({
            editingData:data
        });
    }
    private onHasUnitChanged(hasUnit:boolean) {

        if (!CommonUtil.assertNotNull(this.state.editingData, "editingData", "hasUnitEdited")) return;
        const data = { ...this.state.editingData};
        data.hasUnit = hasUnit;
        this.setState({
            editingData:data
        });

    }
    private onUnitEdited(unit:string) {
        if (!CommonUtil.assertNotNull(this.state.editingData, "editingData", "unitEdited")) return;
        const data = { ...this.state.editingData};
        data.fee_unit = unit;
        this.setState({
            editingData:data
        });

    }
    private onFeeEdited(fee:number) {
        if (!CommonUtil.assertNotNull(this.state.editingData, "editingData", "feeEdited")) return;
        const data = { ...this.state.editingData};
        data.fee = fee;
        this.setState({
            editingData:data
        });
    }
    private onPointAEdited(point:number|null) {
        if (!CommonUtil.assertNotNull(this.state.editingData, "editingData", "pointAEdited")) return;
        const data = { ...this.state.editingData};
        data.point_a = point;
        this.setState({
            editingData:data
        });
    }
    private onPointBEdited(point:number|null) {
        if (!CommonUtil.assertNotNull(this.state.editingData, "editingData", "pointBEdited")) return;
        const data = { ...this.state.editingData};
        data.point_b = point;
        this.setState({
            editingData:data
        });
    }
    private onCodeEdited(code:string) {
        if (!CommonUtil.assertNotNull(this.state.editingData, "editingData", "codeEdited")) return;
        const data = { ...this.state.editingData};
        data.code = code;
        this.setState({
            editingData:data
        });
    }

    render() {
        return (
            <div className="page-root">
                <div className="product product-full-height">
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix w-100">
                            <div className={"product-info-header " + styles["header-with-tab"]}>
                                <div className={styles["sortable-list-shoulder"]}>
                                    {!this.state.isSorting && (<span className="link" onClick={this.startSorting}>並び替え</span>)}
                                    {this.state.isSorting && (<span className="link" onClick={this.finishSorting}>完了</span>)}
                                    {this.state.isSorting && (<span className="link" onClick={this.cancelSorting}>キャンセル</span>)}
                                </div>
                            </div>
                            <div>
                                <Slider asNavFor={this.state.sliderContent}
                                        ref={s => {
                                            if (s != null && s !== this.state.sliderTab) {
                                                this.setState({ sliderTab: s});
                                            }
                                        }}
                                        swipe={!this.state.isSorting} focusOnSelect={!this.state.isSorting}
                                        {...this.sliderTabSettings}>
                                    {
                                        this.state.treatKindList.map(kind => (
                                            <div key={kind.treat_kind_no} className={this.state.currentTreatKind === kind.treat_kind_no ? styles["tab-active"] : styles["tab-default"]}>{kind.name}</div>
                                        ))
                                    }
                                </Slider>
                            </div>

                            <div className="product-body">
                                <Slider className={styles.slider} asNavFor={this.state.sliderTab}
                                    ref={s => {
                                        if (s != null && s !== this.state.sliderContent) {
                                            this.setState({ sliderContent: s });
                                        }
                                    }}
                                    swipe={!this.state.isSorting}
                                    afterChange={this.onTabChanged}
                                    {...this.sliderContentSettings}
                                >
                                    { this.state.treatKindList.map(kind => (
                                        <div key={kind.treat_kind_no}>
                                            <SortableList className={styles["list"] + " m-b-0"}
                                                isSorting={this.state.isSorting}
                                                items={this.state.isSorting
                                                    ? (this.state.currentTreatKind === kind.treat_kind_no ? this.state.sortingList : [])
                                                    : (this.state.treatItemList[kind.treat_kind_no] ?? [])
                                                }
                                                onSorted={l => this.setState({ sortingList:l })}
                                                listItem={treat => (
                                                    <li key={treat.treat_item_no} className={styles["list-item"]}>
                                                        <div className={styles["list-item-content"]}>
                                                            <div className={styles["list-item-name"]}>{treat.name}</div>
                                                            <div className={styles["list-item-detail"]}>
                                                                1{treat.fee_unit ?? "頭"}あたり {treat.fee}円
                                                            </div>
                                                        </div>
                                                        { this.state.isSorting ? (
                                                            <div className={styles["list-item-icon"]}><i className="fas fa-bars" /></div>
                                                        ) : (
                                                            <div onClick={()=>this.startEdit(treat)} className={styles["list-item-icon"]}><i className="fas fa-pen clickable" /></div>
                                                        )}
                                                    </li>
                                                )}
                                            />
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{height:"40px", marginTop:"6px"}} onClick={this.addItem}>
                    {!this.state.isSorting && (
                        <span className="link">
                            <i className="fa fa-plus"></i>
                            <span> 項目を追加</span>
                        </span>
                    )}
                </div>
                {
                    this.state.isEditing && (
                        <TreatEditPopup 
                            data={this.state.editingData}
                            treatKindList={this.state.treatKindList}
                            onClose={this.onCancelEdit}
                            onDelete={this.onDelete}
                            onSubmit={this.onSubmitEdit}
                            onCodeEdited={this.onCodeEdited}
                            onFeeEdited={this.onFeeEdited}
                            onHasUnitChanged={this.onHasUnitChanged}
                            onNameEdited={this.onNameEdited}
                            onPointAEdited={this.onPointAEdited}
                            onPointBEdited={this.onPointBEdited}
                            onUnitEdited={this.onUnitEdited}
                            isSubmitExecuting={this.state.executing}
                        />
                    )
                }
            </div>
        )
    }
}

export default withRouter(withContext(TreatMaster));