import { ResponseMeta } from "./api";
import { A } from '../config/constant';
import { FormDialogType, DialogButtonType } from "../components/form/form-dialog";
import { FreezedArray } from "../config/util";
import { useRootStore } from "stores";
import { usePageStore } from "config/page-settings";
import { AppState } from "app";

export type PostSomeAsync<T> = () => Promise<{ data: { data?:T, meta?:ResponseMeta } }>;

export interface CommunicateResult<T> {
    result:"OK"|"UNREACHED"|"NG"|"LOGOUT"|"BACK"|"NG_RELOAD_REQUIRED";
    code:number|undefined;
    data: T | undefined;
}

export type CommunicateOnError = "NOTHING"|"MSG";
export type CommunicateOnTimeout = "NOTHING"|"MSG"|"RETRY";

export interface CommunicateOptions {
    /**
     * エラー発生時にエラーメッセージを表示するか（default:true）
     */
    showsErrorMessage?: boolean;
    /**
     * エラー発生時に再試行ダイアログを表示し、再試行を強制するか（default:false）
     */
    retries?: boolean;
    /**
     * エラーとみなさないレスポンスコード
     */
    excludedErrCodes?: Readonly<number[]>;
    /**
     * 固定のエラーメッセージを指定
     */
    errorMessage?: string;
    /**
     * 結果がNGの場合、最新リロードを実行するか確認を表示する
     */
    asksToReloadWhenNG?: boolean;
}

export interface CommunicatorContext {
    logout:() => void,
    showDialog: (type:FormDialogType, text: string, buttons?: FreezedArray<DialogButtonType>, options?: { subTexts?:string[], required?:boolean }) => Promise<number|undefined>;
    showToast:(text: string, callback?: (result?:number)=>void)=>void
}

export const useCommunicator = () => {
    const { logout } = useRootStore();
    const { showDialog, showToast } = usePageStore() as AppState;

    return new Communicator({ logout, showDialog, showToast });
}

export class Communicator {

    constructor(readonly context: CommunicatorContext) {

        if (context.logout == null || context.showDialog == null) {
            console.error(context);
            throw new Error("invalid context");
        }
    }

    async send<T>(postSome: PostSomeAsync<T>, options?: CommunicateOptions): Promise<CommunicateResult<T>>  {
        const showsErrMsg = options?.showsErrorMessage ?? true;
        const retries = options?.retries ?? false;
        const exErrCodes = options?.excludedErrCodes ?? [];
        const errMsg = options?.errorMessage;
        const reloadIfNg = options?.asksToReloadWhenNG ?? false;

        const res = await postSome().catch(async (er) => {
            console.error(er);

            if (showsErrMsg && !retries) {
                this.context.showToast(errMsg ?? "通信エラーが発生しました");
            }
            return null;
        });
        if (res == null) {
            if (retries) {
                const toRetry = await this.context.showDialog("WARNING",
                                                    errMsg ?? "通信エラーが発生しました",
                                                    [{ type:"decide", text: "再試行" }, { type:"cancel", text:"前画面へ戻る" }],
                                                    { required:true }
                                                ) === 0;
                if (!toRetry) {
                    window.history.go(-1);
                    return {
                        code:undefined,
                        data:undefined,
                        result:"BACK"
                    };
                }
                return this.send(postSome, options);
            }

            return {
                result: "UNREACHED",
                data: undefined,
                code: undefined,
            };
        }

        const code = res.data.meta?.errCode;
        const isError = code == null
                     || (code !== 0 && !exErrCodes.includes(code));

        if (!isError) {
            return {
                result: "OK",
                code: code,
                data: res.data.data
            };
        }

        console.error(res.data);

        if (code === A.ERR_CODE.INVALID_PERMISSION) {
            if (showsErrMsg) {
                await this.context.showDialog("NOTIFY", "不正な操作が行われました。ログイン画面に遷移します。");
            }
            this.context.logout();
            return {
                code: code,
                data: undefined,
                result: "LOGOUT"
            };
        }

        const msg = errMsg ?? (
            (code === A.ERR_CODE.OUTDATED ? "アプリケーションの最新化が必要です。\nブラウザをリフレッシュしてから再度お試しください。"
            : code === A.ERR_CODE.SERVER_ERROR ? undefined
            : res.data.meta?.errMsg)
                ?? "エラーが発生しました"
        );

        if (retries) {
            const toRetry = await this.context.showDialog("WARNING",
                                                        msg,
                                                        [{ type:"decide", text: "再試行" }, { type:"cancel", text:"前画面へ戻る" }],
                                                        { required:true }
                                                    ) === 0;
            if (!toRetry) {
                window.history.go(-1);
                return {
                    code:undefined,
                    data:undefined,
                    result:"BACK"
                };
            }
            return this.send(postSome, options);
        }

        if (reloadIfNg && code !== A.ERR_CODE.OUTDATED) {
            const reloads = await this.context.showDialog("WARNING",
                                                A.MESSAGE.FAILED_AND_ASK_RELOAD,
                                                [{ type:"decide", text:"最新の情報を再取得" }, { type:"cancel" }]
                                        ) === 0;
            return {
                code,
                data: undefined,
                result: reloads ? "NG_RELOAD_REQUIRED" : "NG"
            }
        }

        if (showsErrMsg) {
            if (code === A.ERR_CODE.OUTDATED) {
                await this.context.showDialog("WARNING", msg);
            } else {
                this.context.showToast(msg);
            }
        }
        return {
            code: code,
            data: undefined,
            result: "NG"
        };
    }


}