import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { A, TAG_SEARCH_SYMBOL, LMT } from '../../config/constant';
import { PageSettings } from '../../config/page-settings.js';
import { CommonUtil, ar, saveTextFile, FreezedArray, buildCsvRow } from '../../config/util';
import { CowSearchPopup } from './cow-search-popup';
import styles from './ranch.module.css';
import { withContext, ICowSearchCondition, ISelectedSite, defaultCowListCondition } from '../../stores';
import { SchedulePopup, ISchedule } from '../schedule/schedule-popup';
import { CowListReqHouse, CowApi, CowSearchReq, CowSearchCowDto, ProgramDto, ProgramApi, RanchTagDto, CowTagModifyReq } from '../../api';
import { ActionIconBar, CertType } from '../../components/parts/action-icon-bar';
import classnames from 'classnames';
import { historyLocation, IEventWriteLocationState } from '../../config/history-location-builder';
import { LinkParam } from '../top/Dashboard';
import { hasRanchAuth } from '../../config/auth-checker';
import { ICowListOutput, buildDefaultOutputs, CowListOutputKey, anyOutputInGroup } from './select-output-popup';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import { isBrowser } from 'react-device-detect';
import { LoadMore } from '../../components/parts/load-more';
import { CowListTable, CowDispData, buildTableHeaders, isInOutputs, findFirstSortableKey } from './cow-list-table';
import { IconLink } from '../../components/parts/icon-link';
import { CowToDispInfo } from '../../components/parts/cows-popup';
import { TagEditPopup, EditingTag } from '../../components/tag-edit-popup/tag-edit-popup';
import { SortOrder } from '../feedbulk/BulkCowSortIcon';
import { UserTeams } from '../../config/user-teams';

const COWS_PER_PAGE = 1000;

interface MyState {
    popup_search_open: boolean;
    ranch_id: number;
    isOfficial: boolean;
    name: string;
    cows_total_cnt: number;
    cows: CowSearchCowDto[];
    dispCowList: CowDispData[];
    checked_cows: number[];
    is_checked_all: boolean;
    condition: ICowSearchCondition;
    output: ICowListOutput;
    traceFilter: string;
    page: number;
    isSchedulePopupShown: boolean;
    currentScheduleEvent: Partial<ISchedule>;
    sortKey: CowListOutputKey;
    sortOrder: SortOrder;
    medicineTeamId: number;
    isActiveCowsShown: boolean;
    programs?: Readonly<ProgramDto[]>;
    ageMode: number;
    tags?:FreezedArray<RanchTagDto>;
    tagEditProps?: {
        cows: Array<{ id:number, tags: RanchTagDto[] }>,
        selectedCowNames: Readonly<string[]>,
    },
    isExecuting: boolean
}

class RanchMain extends Base<BaseProps, MyState> {

    static contextType = PageSettings;

    private refTable = React.createRef<HTMLDivElement>();

    constructor(props) {
        super(props);

        this.state = {
            popup_search_open: false,

            ranch_id: 0,
            isOfficial: true,
            name: "",

            cows_total_cnt: 0,
            cows: [],
            dispCowList: [],

            checked_cows: [],

            is_checked_all: false,

            condition: defaultCowListCondition(),
            output: buildDefaultOutputs(),

            traceFilter: '',

            page: 1,
            isSchedulePopupShown: false,
            currentScheduleEvent:{},

            sortOrder: "Asc",
            sortKey: "rep_no",
            medicineTeamId: 0,
            isActiveCowsShown: true,
            ageMode: 0,
            isExecuting: false,
        }

        this.onSearchClick = this.onSearchClick.bind(this);
        this.onWriteCow = this.onWriteCow.bind(this);
        this.onFeedHistory = this.onFeedHistory.bind(this);
        this.onFeedLeft = this.onFeedLeft.bind(this);
        this.onConditionHeal = this.onConditionHeal.bind(this);
        this.onMoveCow = this.onMoveCow.bind(this);
        this.onPrevention = this.onPrevention.bind(this);
        this.onAddScheduleClick = this.onAddScheduleClick.bind(this);
        this.onFinishScheduleEdit = this.onFinishScheduleEdit.bind(this);
        this.onSchedulePopupClose = this.onSchedulePopupClose.bind(this);
        this.onRut = this.onRut.bind(this);
        this.onDelivery = this.onDelivery.bind(this);
        this.onBreeding = this.onBreeding.bind(this);
        this.onScheduleLinkClick = this.onScheduleLinkClick.bind(this);
        this.onSellCowClick = this.onSellCowClick.bind(this);
        this.onBalanceClick = this.onBalanceClick.bind(this);
        this.onSellMilkClick = this.onSellMilkClick.bind(this);
        this.onOtherPlClick = this.onOtherPlClick.bind(this);
        this.onProgramClick = this.onProgramClick.bind(this);
        this.onGrowth = this.onGrowth.bind(this);
        this.onEggClick = this.onEggClick.bind(this);
        this.onCertClick = this.onCertClick.bind(this);
    }

    componentDidMount() {
        if (this.handleNotAllowAccess(undefined, [], [])) {
            return;
        }

        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        const ranch_id = this.props.rootStore.cur_ranch_id;
        const ranchName = this.props.rootStore.getCurRanchName() ?? "";
        const isOfficial = new UserTeams(this.props.rootStore.user).findOfficialRanch(ranch_id) != null;

        const mTeamId = this.props.rootStore.getClinicIdForMaster() ?? ranch_id;

        this.context.handleSetHeader({ title:ranchName });

        const filter = this.props.rootStore.getCowListFilter();
        const dispState = this.props.rootStore.cowListDispState;
        //※dispState.sortKeyは旧バージョンの入れ替えのときだけundefの可能性あり
        const sortKey = (dispState.sortKey != null && isInOutputs(filter.output, dispState.sortKey))
                    ? dispState.sortKey
                    : (findFirstSortableKey(filter.output) ?? "rep_no");
        const setStates = this.setStateAsync({
            ranch_id: ranch_id,
            isOfficial,
            name: ranchName,
            condition: filter.condition,
            output: filter.output,
            sortKey,
            traceFilter: dispState.filter,
            sortOrder: dispState.sortOrder,
            medicineTeamId: mTeamId
        });

        setStates.then(() => {

            this.api_getGetCowList();
        });

    }

    componentDidUpdate(_:this["props"], prevState:MyState) {
        if (this.state.cows.some(cow => cow.cow_id === this.props.rootStore.ranch_scroll_status.selected_cow_id)) {
            // 初回スクロール位置指定
            document.getElementById("ranch_main")?.scroll(0, this.props.rootStore.ranch_scroll_status.scroll_top);
            // 初回スクロール位置状態初期化
            this.props.rootStore.setRanchScrollStatus();
        }

        if (prevState.dispCowList !== this.state.dispCowList
            || prevState.checked_cows !== this.state.checked_cows) {

            this.setState({
                is_checked_all: this.isCheckedAll()
            })
        }
    }

    isCheckedAll() {
        if (this.state.dispCowList.length === 0) {
            return false;
        }
        const checkedIds = new Set(this.state.checked_cows);
        return this.state.dispCowList.every(c => checkedIds.has(c.cow_id));
    }

    componentWillUnmount() {
        this.props.rootStore.setCowListDispState({
            filter: this.state.traceFilter,
            sortOrder: this.state.sortOrder,
            sortKey: this.state.sortKey,
        });
        this.props.rootStore.setCurrentCowList(this.state.dispCowList);
    }

    async onSearchClick() {
        this.context.handleSetIsLoading(true);
        try {
            let programs = this.state.programs;
            if (programs == null) {
                const pgReq = { ranch_id: this.state.ranch_id };
                const pgRes = await this.comm().send((await ProgramApi()).getProgramListUsingPOST(pgReq));
                if (pgRes.result !== "OK") return;
    
                programs = pgRes.data;
            }
            let tags = this.state.tags;
            if (tags == null) {
                const tgRes = await this.fetchTags();
                if (!tgRes) return;

                tags = this.props.rootStore.getCowTags(this.state.ranch_id);
            }
            this.setState({
                popup_search_open: true,
                programs,
                tags
            })

        } finally {
            this.context.handleSetIsLoading(false);
        }
    }

    onWriteCow() {
        // 初回スクロール位置状態初期化
        this.props.rootStore.setRanchScrollStatus();
        this.props.history.push(historyLocation.toCowWrite());
    }

    onDetailCow(cow_id: number) {
        // 初回スクロール位置状態更新
        const parent = document.querySelector(".ranch-main") as HTMLDivElement;
        let current_scroll_status = {
            selected_cow_id: cow_id,
            cows_length: this.state.cows.length,
            scroll_top: parent.scrollTop,
        };
        this.props.rootStore.setRanchScrollStatus(current_scroll_status);
        this.props.history.push(historyLocation.toCowInfo(cow_id));
    }

    static buildEventWriteLocationState(cowIds: number[], allCows: Readonly<Readonly<CowSearchCowDto>[]>): IEventWriteLocationState {
        return ({
            cows: cowIds.map(id => allCows.find(c => c.cow_id === id))
                        .filter((c): c is CowSearchCowDto => c != null)
                        .map(c => ({...c, use_no: c.use_no, breed_no: c.breed_no }))
        });
    }

    onFeedHistory() {
        var str = CommonUtil.arrayToStr(this.state.checked_cows);
        if (str === "") {
            console.error("遷移時に牛の選択なし");
            return;
        }

        // 初回スクロール位置状態初期化
        this.props.rootStore.setRanchScrollStatus();

        const isCowTop = this.state.checked_cows.length === 1;
        const hisState = RanchMain.buildEventWriteLocationState(this.state.checked_cows, this.state.cows);
        this.props.history.push(historyLocation.toFeedWrite({ param: str, cow_top: isCowTop ? 1 : 0 }, hisState));
    }
    onFeedLeft() {
        if (this.state.checked_cows.length === 0) {
            console.error("遷移時に牛の選択なし");
            return;
        }
        // 初回スクロール位置状態初期化
        this.props.rootStore.setRanchScrollStatus();

        this.props.history.push(historyLocation.toFeedRemained(this.state.checked_cows));
    }

    onConditionHeal() {
        var str = CommonUtil.arrayToStr(this.state.checked_cows);
        if (str === "") {
            console.error("遷移時に牛の選択なし");
            return;
        }

        // 初回スクロール位置状態初期化
        this.props.rootStore.setRanchScrollStatus();

        const isCowTop = this.state.checked_cows.length === 1;
        const hisState = RanchMain.buildEventWriteLocationState(this.state.checked_cows, this.state.cows);
        this.props.history.push(historyLocation.toSymptomWrite({ param: str, cow_top: isCowTop ? 1 : 0 }, hisState));
    }

    onMoveCow() {
        var str = CommonUtil.arrayToStr(this.state.checked_cows);
        if (str === "") {
            console.error("遷移時に牛の選択なし");
            return;
        }

        // 初回スクロール位置状態初期化
        this.props.rootStore.setRanchScrollStatus();

        const isCowTop = this.state.checked_cows.length === 1;
        const hisState = RanchMain.buildEventWriteLocationState(this.state.checked_cows, this.state.cows);
        this.props.history.push(historyLocation.toLocationWrite({ param: str, cow_top: isCowTop ? 1 : 0 }, hisState));
    }

    onPrevention() {
        var str = CommonUtil.arrayToStr(this.state.checked_cows);
        if (str === "") {
            console.error("遷移時に牛の選択なし");
            return;
        }

        // 初回スクロール位置状態初期化
        this.props.rootStore.setRanchScrollStatus();

        const isCowTop = this.state.checked_cows.length === 1;
        const hisState = RanchMain.buildEventWriteLocationState(this.state.checked_cows, this.state.cows);
        this.props.history.push(historyLocation.toPreventionWrite({ param: str, cow_top: isCowTop ? 1 : 0 }, hisState));
    }

    onRut() {
        if (this.state.checked_cows.length === 0) {
            console.error("遷移時に牛の選択なし");
            return;
        }

        // 初回スクロール位置状態初期化
        this.props.rootStore.setRanchScrollStatus();

        const isCowTop = this.state.checked_cows.length === 1;
        const hisState = RanchMain.buildEventWriteLocationState(this.state.checked_cows, this.state.cows);
        this.props.history.push(historyLocation.toRutWrite({
            cow_ids: this.state.checked_cows,
            cow_top:isCowTop,
            cows:hisState.cows
        }));
    }

    onDelivery() {
        if (this.state.checked_cows.length !== 1) {
            console.error("invaild selection state", this.state.checked_cows);
            return;
        }

        // 初回スクロール位置状態初期化
        this.props.rootStore.setRanchScrollStatus();

        const hisState = RanchMain.buildEventWriteLocationState(this.state.checked_cows, this.state.cows);

        this.props.history.push(historyLocation.toDeliveryWrite({ param: this.state.checked_cows[0], cow_top: 1 }, hisState));
    }

    onBreeding() {
        var str = CommonUtil.arrayToStr(this.state.checked_cows);
        if (str === "") {
            console.error("遷移時に牛の選択なし");
            return;
        }

        // 初回スクロール位置状態初期化
        this.props.rootStore.setRanchScrollStatus();

        const isCowTop = this.state.checked_cows.length === 1;
        const hisState = RanchMain.buildEventWriteLocationState(this.state.checked_cows, this.state.cows);
        this.props.history.push(historyLocation.toBreedingWrite({ param: str, cow_top: isCowTop ? 1: 0 }, { ...hisState, seed:undefined }));
    }

    onSellCowClick() {
        if (this.state.checked_cows.length === 0){
            console.error("遷移時に牛の選択なし");
            return;
        }

        // 初回スクロール位置状態初期化
        this.props.rootStore.setRanchScrollStatus();

        const hisState = RanchMain.buildEventWriteLocationState(this.state.checked_cows, this.state.cows);

        this.props.history.push(historyLocation.toSellCow(hisState));
    }

    onBalanceClick() {
        if (this.state.checked_cows.length === 0){
            console.error("遷移時に牛の選択なし");
            return;
        }
        // 初回スクロール位置状態初期化
        this.props.rootStore.setRanchScrollStatus();

        this.props.history.push(historyLocation.toBalanceCow(this.state.checked_cows));
    }

    onProgramClick() {
        if (this.state.checked_cows.length === 0){
            console.error("遷移時に牛の選択なし");
            return;
        }

        // 初回スクロール位置状態初期化
        this.props.rootStore.setRanchScrollStatus();

        const hisState = RanchMain.buildEventWriteLocationState(this.state.checked_cows, this.state.cows);

        this.props.history.push(historyLocation.toCowProgram(hisState.cows));
    }

    onGrowth() {
        var str = CommonUtil.arrayToStr(this.state.checked_cows);
        if (str === "") {
            console.error("遷移時に牛の選択なし");
            return;
        }

        // 初回スクロール位置状態初期化
        this.props.rootStore.setRanchScrollStatus();

        const isCowTop = this.state.checked_cows.length === 1;
        const hisState = RanchMain.buildEventWriteLocationState(this.state.checked_cows, this.state.cows);
        this.props.history.push(historyLocation.toGrowthWrite({ param: str, cow_top: isCowTop ? 1 : 0 }, hisState));
    }
    onEggClick(kind:"sov"|"opu"|"ivf"|"ivf-egg") {
        if (this.state.checked_cows.length === 0){
            console.error("遷移時に牛の選択なし");
            return;
        }

        // 初回スクロール位置状態初期化
        this.props.rootStore.setRanchScrollStatus();

        const hisState = RanchMain.buildEventWriteLocationState(this.state.checked_cows, this.state.cows);
        const location = kind === "sov" ? historyLocation.toSovWrite(hisState)
                    : kind === "opu" ? historyLocation.toOpuWrite(hisState)
                    : kind === "ivf" ? historyLocation.toIvfWrite(hisState)
                    : undefined;
        if (location == null) {
            console.error("invalid egg event kind", kind);
            return;
        }

        this.props.history.push(location);
    }

    loadMore = () => {
        this.api_getGetCowList({ page: this.state.page + 1 });
    }

    onCheckedAll(checked:boolean, isFiltered: boolean) {
        let checked_cows: number[];
        if (isFiltered) {
            const dispCowIds = this.state.dispCowList.map(c => c.cow_id);

            if (checked) {
                checked_cows = [...new Set([...this.state.checked_cows, ...dispCowIds])]
            } else {
                const dispCowIdSet = new Set(dispCowIds);
                checked_cows = this.state.checked_cows.filter(id => !dispCowIdSet.has(id))
            }

        } else {
            if (checked) {
                checked_cows = this.state.cows.map(c => c.cow_id);
            } else {
                checked_cows = [];
            }
        }
        this.setState({ checked_cows });
    }

    onChecked(cow_id: number, checked: boolean) {
        if (checked) {
            this.setState({ checked_cows: [ ...this.state.checked_cows, cow_id ]});
        } else {
            this.setState({ checked_cows: this.state.checked_cows.filter(id => id !== cow_id)});
        }
    }

    searchWithCondition(condition: ICowSearchCondition, output: ICowListOutput) {
        this.props.rootStore.setCowListFilter({ condition, output });

        this.setState({
            condition,
            output,
            popup_search_open: false,
            page: 1,
            cows: [],
            checked_cows: [],
        }, () => {
            this.api_getGetCowList({ resetsChecked:true });
        });
    }

    traceFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({traceFilter: e.target.value})
    }

    convHousesToReq(selected_houses: ISelectedSite[]) {
        const rtn: CowListReqHouse[] = [];

        for (const site of selected_houses) {
            if (site.isAllSelected) {
                rtn.push({ site: site.no });
                continue;
            }
            
            for (const barn of site.data) {
                if (barn.isAllSelected) {
                    rtn.push({
                        site: site.no,
                        barn: barn.no
                    });
                    continue;
                }
                
                for (const room of barn.data) {
                    rtn.push({
                        site: site.no,
                        barn: barn.no,
                        room: room.no
                    });
                }
            }
        }
        return rtn;
    }

    onAddScheduleClick() {
        const cows = this.state.checked_cows.map(id => this.state.cows.find(c => c.cow_id === id));
        if (cows.some(c => c == null)) {
            console.error("unknown cow", cows, this.state.checked_cows);
        }

        const is_active = this.state.isActiveCowsShown ? 1 : 0;
        console.assert(is_active === 1);

        this.setState({
            isSchedulePopupShown: true,
            currentScheduleEvent: {
                cows: ar.notNull(cows).map(c => ({ ...c, use_no: c.use_no, breed_no: c.breed_no, is_active }))
            },
        });
    }

    onFinishScheduleEdit() {
        this.setState({
            currentScheduleEvent:{},
            isSchedulePopupShown: false
        });
    }
    onSchedulePopupClose() {
        this.setState({
            currentScheduleEvent:{},
            isSchedulePopupShown: false
        });
    }
    onScheduleLinkClick(link: LinkParam) {
        this.props.history.push(link);
    }

    onSellMilkClick() {
        var str = CommonUtil.arrayToStr(this.state.checked_cows);
        if (str === "") {
            console.error("遷移時に牛の選択なし");
            return;
        }

        // 選択された牛から搾乳牛を抽出
        const hisState = RanchMain.buildEventWriteLocationState(this.state.checked_cows, this.state.cows);
        const sellMilkState = { ...hisState, cows: hisState.cows.filter(c => A.IS_FOR_MILK(c.use_no ?? 0)) }
        if (sellMilkState.cows.length === 0) {
            console.error("遷移時に搾乳牛の選択なし");
            return;
        }

        // 初回スクロール位置状態初期化
        this.props.rootStore.setRanchScrollStatus();

        const cow_top = sellMilkState.cows.length === 1 ? 1 : 0;
        this.props.history.push(historyLocation.toSellMilk({ param: str, cow_top }, sellMilkState));
    }

    onOtherPlClick() {
        var str = CommonUtil.arrayToStr(this.state.checked_cows);
        if (str === "") {
            console.error("遷移時に牛の選択なし");
            return;
        }

        const hisState = RanchMain.buildEventWriteLocationState(this.state.checked_cows, this.state.cows);

        // 初回スクロール位置状態初期化
        this.props.rootStore.setRanchScrollStatus();

        const cow_top = this.state.checked_cows.length === 1 ? 1 : 0;
        this.props.history.push(historyLocation.toOtherPl({ param: str, cow_top }, hisState));
    }

    onCertClick(certType: CertType) {
        // 初回スクロール位置状態初期化
        this.props.rootStore.setRanchScrollStatus();

        const state = RanchMain.buildEventWriteLocationState(this.state.checked_cows, this.state.cows);
        if (certType === "vaccine") {
            this.props.history.push(historyLocation.toCertVaccine(state));
        } else if (certType === "disease") {
            this.props.history.push(historyLocation.toCertDisease(state));
        } else if (certType === "karte") {
            this.props.history.push(historyLocation.toCertKarte(state));
        }
    }

    api_getGetCowList = async (options?: { page?: number, resetsList?: boolean, resetsChecked?: boolean }): Promise<boolean> => {
        if (options?.page != null && options?.page <= 0) {
            console.error("invalid page", options?.page);
            return false;
        }

        const condition = this.state.condition;
        const output = this.state.output;

        const hasBalance = anyOutputInGroup(this.state.output, "grp_balance");

        //※繁殖状態が指定された場合、搾乳牛・繁殖牛のみを対象とする
        //（現状、サーバ側で用途を意識させないよう、フロント側のリクエストで用途を調整する）
        const breedingStates = (condition.breeding_states ?? []).length === 0 ? undefined : condition.breeding_states;
        const uses = breedingStates == null ? condition.uses
                   : condition.uses.length === 0 ? A.GET_FOR_BREEDING_USE_NO()
                   : condition.uses.some(u => A.IS_FOR_BREEDING_COW(u)) ? condition.uses.filter(u => A.IS_FOR_BREEDING_COW(u))
                   : [ -1 ];    //<-搾乳牛・繁殖牛以外だけが指定されているときは便宜的にヒットしない条件を設定
        
        const req: CowSearchReq = {
            page: options?.page ?? this.state.page,
            count: COWS_PER_PAGE,
            condition: {
                ranch_id: this.state.ranch_id,
                is_male: condition.is_male,
                age_from: condition.is_age === 0 ? undefined : condition.age_from,
                age_to: condition.is_age === 0 ? undefined : condition.age_to,
                day_age_from: condition.hasDayAge ? condition.day_age_from : undefined,
                day_age_to: condition.hasDayAge ? condition.day_age_to : undefined,
                breeds: condition.breeds,
                houses: this.convHousesToReq(condition.selected_house),
                uses: uses,
                period_end: (condition.is_period === 0) ? undefined : condition.period_end,
                period_start: (condition.is_period === 0) ? undefined : condition.period_start,
                is_active: condition.is_active,
                breeding_states: breedingStates,
                tags: (condition.tags ?? []).length === 0 ? undefined : condition.tags,
                start_kind: condition.start_kind,
                programs: (condition.programs ?? []).length === 0 ? undefined : condition.programs,
                events: (condition.events ?? []).length === 0 ? undefined : condition.events,
                schedules: (condition.schedules ?? []).length === 0 ? undefined : condition.schedules
            },
            output: {
                breed: output.breed,
                use: output.use,
                ancestors: output.ancestors,
                tags: output.tag,
                note: output.note,
                sell_month: output.sell_month,
                breedings: output.his_breedings,
                cross_count: output.cross_count,
                crosses: output.his_crosses,
                cross_day: output.cross_day,
                cross_kind: output.cross_kind,
                days_after_cross: output.days_after_cross,
                deliveries: output.his_deliveries,
                delivery_count: output.delivery_count,
                delivery_day: output.delivery_day,
                days_after_delivery: output.days_after_delivery,
                delivery_schedule: output.delivery_schedule,
                ruts: output.his_ruts,
                days_after_rut: output.days_after_rut,
                symptom_physical_days: output.his_symptom_physical.output ? output.his_symptom_physical.days : undefined,
                symptom_treatment_days: output.his_symptom_treatment.output ? output.his_symptom_treatment.days : undefined,
                prevention_days: output.his_prevention.output ? output.his_prevention.days : undefined,
                location_days: output.his_location.output ? output.his_location.days : undefined,
                schedule_days: output.schedule.output ? output.schedule.days : undefined,
                balance: hasBalance,
                balance_from: hasBalance ? output.grp_balance?.from : undefined,
                balance_to: hasBalance ? output.grp_balance?.to : undefined
            }
        }
        this.context.handleSetIsLoading(true);

        const response = await this.comm().send((await CowApi()).searchCowUsingPOST(req));
        this.context.handleSetIsLoading(false);
        if (response.result !== "OK" || response.data == null) return false;

        const new_cows = response.data.list;
        const cows = options?.resetsList ? new_cows : [...this.state.cows, ...new_cows];

        const filtered = this.state.traceFilter !== "";
        const checked_cows = options?.resetsChecked ? [] 
                        //全選択時は追加分も選択状態に
                        : (!filtered && this.state.is_checked_all) ? cows.map(c => c.cow_id)
                        : options?.resetsList ? this.state.checked_cows.filter(id => cows.some(c => c.cow_id === id))
                        : this.state.checked_cows;
        this.setState({
            cows,
            checked_cows,
            cows_total_cnt: response.data.total_cnt,
            isActiveCowsShown: condition.is_active === 1,
            ageMode: response.data.age_mode,
            page: req.page
        });
        return true;
    }

    onCsvClick() {
        const csvRows: string[] = [];
        const headers = buildTableHeaders(this.state.output);
        csvRows.push(headers.map(h => h.header).join(","));

        for (const cow of this.state.dispCowList) {
            const dataRowVals = cow.tableData.map(dt => {
                let val: string | number;
                if (dt.forCsv != null) {
                    val = dt.forCsv;
                } else if (dt.value == null) {
                    val = "";
                } else if (typeof(dt.value) === "string" || typeof(dt.value) === "number") {
                    val = dt.value;
                } else if (Array.isArray(dt.value)) {
                    val = dt.value.join("\n");
                } else {
                    console.error("invalid celldata", dt.value);
                    val = "";
                }
                return val;

            });
            const dataRow = buildCsvRow(dataRowVals);

            csvRows.push(dataRow)
        }

        saveTextFile(csvRows.join("\r\n"), `牛一覧_${moment().format("YYYYMMDD")}.csv`);
    }

    async onEditTag() {
        const cowIds = this.state.checked_cows;
        if (cowIds.length === 0) return;

        this.context.handleSetIsLoading(true);
        try {
            let tags = this.state.tags;
            if (tags == null) {
                const fetchRes = await this.fetchTags();
                if (!fetchRes) return;
                tags = this.props.rootStore.getCowTags(this.state.ranch_id);
            }

            const cowRepNums = cowIds.map(id => this.state.cows.find(c => c.cow_id === id))
                    .map(cw => cw == null ? undefined : CowToDispInfo(cw, false));
            const nums = ar.notNull(cowRepNums);

            const cowReq = { ranch_id: this.state.ranch_id, cow_ids: cowIds };
            const cowRes = await this.comm().send((await CowApi()).getCowTagsUsingPOST(cowReq));
            if (cowRes.result !== "OK") return;

            const cowTags = cowRes.data ?? [];
            const cows = cowIds.map(cwId => ({
                id: cwId,
                tagIds: cowTags.find(t => t.cow_id === cwId)?.tag_ids ?? []
            }))
                .map(c => ({ id: c.id, tags: ar.notNull(c.tagIds.map(id => tags!.find(t => t.tag_id === id))) }));

            this.setState({
                tagEditProps: {
                    selectedCowNames: nums,
                    cows
                },
                tags
            });

        } finally {
            this.context.handleSetIsLoading(false);
        }
    }

    async onSubmitTags(changedTargets: { id: number, tags: EditingTag[] }[]) {
        if (changedTargets.length === 0) return;

        const req: CowTagModifyReq = {
            ranch_id: this.state.ranch_id,
            cows: changedTargets.map(t => ({
                cow_id: t.id,
                tags: t.tags.map(t => ({ id:t.tag_id, name:t.tag_name }))
            }))
        };

        this.context.handleSetIsLoading(true);
        this.setState({ isExecuting: true });
        try {
            const res = await this.comm().send((await CowApi()).modifyTagUsingPOST(req));
            if (res.result !== "OK") return;

            await this.props.rootStore.fetchCowTags(req.ranch_id, true);
            this.setState({
                tags: this.props.rootStore.getCowTags(req.ranch_id),
                tagEditProps: undefined
            })

            this.props.rootStore.fetchActiveCows(req.ranch_id, "DIFF");

        } finally {
            this.context.handleSetIsLoading(false);
            this.setState({ isExecuting: false });
        }

        //タグを表示中の場合は一覧をリロードしておく
        if (this.state.output.tag) {
            if ((await this.api_getGetCowList({ page:1, resetsList:true })) === false) {
                this.setState({
                    cows:[],
                    checked_cows:[],
                    page:1
                })
            }
        }
    }

    private async fetchTags() {
        const res = await this.props.rootStore.fetchCowTags(this.state.ranch_id, true);
        if (res === "NG") {
            this.context.showToast("タグ情報の取得に失敗しました");
            return false;
        }
        return true;
    }

    render() {

        const isOnlyBreedingCowsSelected
            = this.state.checked_cows.length > 0
            && this.state.checked_cows.map(id => this.state.cows.find(c => c.cow_id === id))
                    .every(c => c != null && A.IS_FOR_BREEDING_COW(c.use_no ?? 0));

        const isOnlyMilkCowsSelected
            = this.state.checked_cows.length > 0
            && this.state.checked_cows.map(id => this.state.cows.find(c => c.cow_id === id))
                    .some(c => c != null && A.IS_FOR_MILK(c.use_no ?? 0));

        const hasMore = (this.state.page * COWS_PER_PAGE) < this.state.cows_total_cnt;

        const filtered = this.state.traceFilter !== "";

        const canAddCow = hasRanchAuth("COW_EDIT", this.state.ranch_id, this.props.rootStore.user)
                    && (this.state.isOfficial || (this.props.rootStore.getActiveCows() ?? []).length < LMT.COW.UNOFFICIAL_COUNT_MAX);

        return (
            <div style={{ height: "100%", "display": "flex", flexFlow: "column nowrap" }}>
                <div className="product" style={{ height: "100%", minHeight: "0" }}>
                    {/* ※↓の display:block がないと、ウィンドウ幅が一定を越えたときに横スクロールする領域がおかしくなる */}
                    <div className="product-detail" style={{ height: "100%", display:"block" }}>
                        <div className="product-info product-info-fix" style={{ paddingBottom: "6px" }} >
                            {/* <!-- BEGIN product-info-header --> */}
                            <div className="product-info-header" style={{ paddingBottom : ".5rem", marginBottom: "0", borderBottom: "1px solid #dee2e6", "flex" : "none" }}>
                                <div style={{ display: "flex" }}>
                                    <span data-testid="表示中頭数">{this.state.cows.length} 頭表示 / 全 {this.state.cows_total_cnt} 頭</span>
                                </div>
                                <div style={{ display: "flex", marginTop: "0.3rem", marginBottom: "1rem", justifyContent: "flex-end" }}>
                                    <input type="text" className={styles["search-box"]} value={this.state.traceFilter}
                                        onKeyDown={e => {
                                            if (e.key === "Enter") {
                                                e.currentTarget.blur();
                                            }
                                        }}
                                        onChange={(e) => this.traceFilterChange(e)} placeholder="耳標・名前で探す" />
                                    <button type="button" className={classnames(styles["search-detail"], styles.cow)} style={{ "flex": "none" }}
                                        onClick={this.onSearchClick}>
                                            <i className="fas fa-book-open m-r-5"></i>
                                            <span>詳しく探す</span>
                                    </button>
                                </div>
                                <div style={{ flex: "1", display: "flex", paddingLeft: "10px", textAlign: "center", alignItems: "baseline", justifyContent:"space-between" }} >
                                    <div className="checkbox checkbox-css" style={{ display: "inline" }}>
                                        <input type="checkbox" id="cssCheckboxAll"
                                            onChange={e => this.onCheckedAll(e.target.checked, filtered)}
                                            checked={this.state.is_checked_all} />
                                        <label htmlFor="cssCheckboxAll">{filtered ? `表示中の ${this.state.dispCowList.length}頭を選択` : "全選択"}</label>
                                    </div>
                                    { this.state.checked_cows.length > 0 && (
                                        <IconLink iconType="tag" text="タグづけ" style={{ marginBottom:"-1px" }} onClick={() => this.onEditTag()} />
                                    )}
                                </div>
                            </div>
                            {/* <!-- END product-info-header --> */}
                            {/* <!-- BEGIN content --> */}
                            <div id="ranch_main" className="ranch-main" style={{ flex:1, minHeight:0 }}>
                                <div ref={this.refTable}>
                                    { this.state.ranch_id !== 0 && (
                                        <CowListTable
                                            cows={this.state.cows}
                                            sortKey={this.state.sortKey}
                                            sortOrder={this.state.sortOrder}
                                            traceFilter={this.state.traceFilter}
                                            onCheckChanged={(id,ch) => this.onChecked(id,ch)}
                                            checkedCowIds={this.state.checked_cows}
                                            dispList={this.state.dispCowList}
                                            onDispListChange={l => this.setState({ dispCowList: l }) }
                                            onCowClick={id => this.onDetailCow(id)}
                                            fecesStates={this.props.rootStore.options.feces_state}
                                            fecesColors={this.props.rootStore.options.feces_color}
                                            onSortChange={(sortKey, sortOrder) => this.setState({ sortKey, sortOrder })}
                                            output={this.state.output}
                                            ranchId={this.state.ranch_id}
                                            user={this.props.rootStore.user}
                                            now={new Date()}
                                            ageMode={this.state.ageMode}
                                            onTagClick={t => this.setState({ traceFilter:`${TAG_SEARCH_SYMBOL}${t}` })}
                                        />
                                    )}
                                </div>
                                { hasMore && (
                                    <LoadMore style={{ textAlign:"center", padding:"4px 0" }}
                                        onClick={() => this.loadMore() }
                                    />
                                )}
                            </div>
                            {/* <!-- END content --> */}
                        </div>
                    </div>
                </div>
                {/* <!-- BEGIN footer --> */}
                <div className={styles.footer}>
                    <div className="row" style={{ display: "flex", justifyContent: "space-between", marginBottom: "4px" }}>
                        <IconLink
                            onClick={() => this.onWriteCow()}
                            style={{ visibility:canAddCow ? "visible":"hidden" }}
                            iconType="add" text="新しい牛を追加"
                        />
                        {this.state.checked_cows.length > 0 && (
                            <span>{this.state.checked_cows.length} 頭を選択中</span>
                        )}
                        {/* ※現状は iPadOS も含まれる */}
                        { this.state.checked_cows.length === 0 && isBrowser && (
                            <div>
                                <button disabled={filtered}
                                    className="btn btn-orange m-r-5" onClick={() => this.onCsvClick()}>CSV出力</button>
                                <ReactToPrint
                                    trigger={() => <button disabled={filtered} className="btn btn-orange">印刷</button>}
                                    content={() => this.refTable.current}
                                />
                            </div>
                        )}
                    </div>

                    { this.state.checked_cows.length > 0 && (
                        <ActionIconBar containerClassName="row m-t-10"
                            ranchId={this.state.ranch_id}
                            user={this.props.rootStore.user}
                            showsBreedingGroup={isOnlyBreedingCowsSelected}
                            showsSingleCowIcon={this.state.checked_cows.length === 1}
                            onRutDetailClick={this.onRut}
                            onBreedingClick={this.onBreeding}
                            onDeliveryClick={this.onDelivery}
                            onLocationClick={this.onMoveCow}
                            onFeedingClick={this.onFeedHistory}
                            onFeedingLeftClick={this.onFeedLeft}
                            onSymptomClick={this.onConditionHeal}
                            onPreventionClick={this.onPrevention}
                            onScheduleClick={this.onAddScheduleClick}
                            onBalanceClick={this.onBalanceClick}
                            onSellCowClick={this.onSellCowClick}
                            showsSellMilk={isOnlyMilkCowsSelected}
                            onSellMilkClick={this.onSellMilkClick}
                            onOtherPlCowClick={this.onOtherPlClick}
                            onProgramClick={this.onProgramClick}
                            onGrowthClick={this.onGrowth}
                            onSovClick={() => this.onEggClick("sov")}
                            onOpuClick={() => this.onEggClick("opu")}
                            onIvfClick={() => this.onEggClick("ivf")}
                            onIvfEggClick={() => this.onEggClick("ivf-egg")}
                            onCertClick={this.onCertClick}
                            isActive={this.state.isActiveCowsShown}
                            arrowLeft={-6} arrowRight={-12}
                        />
                    )}
                </div>
                {/* <!-- END footer --> */}
               {
                    this.state.popup_search_open && (
                        <CowSearchPopup isOpen={true}
                            cow_breed={this.props.rootStore.options.cow_breed}
                            cow_use={this.props.rootStore.getCowUses()}
                            condition={this.state.condition}
                            outputs={this.state.output}
                            onClose={() => this.setState({ popup_search_open: false })}
                            onCloseWithResult={(c,o) => { this.searchWithCondition(c,o) }}
                            onReset={() => this.setState({ traceFilter: "" })}
                            resetButton="条件クリア"
                            comm={this.comm()}
                            ranch_id={this.state.ranch_id}
                            user={this.props.rootStore.user}
                            tags={this.state.tags ?? []}
                            programs={this.state.programs ?? []}
                        />
                    )
                }
                {
                    this.state.isSchedulePopupShown && (
                    <SchedulePopup onClose={this.onSchedulePopupClose} onStartEdit={()=>{}} original={this.state.currentScheduleEvent}
                                   onRegistered={this.onFinishScheduleEdit}
                                   onSplit={() => {}}
                                   isEditing={true} onCowClick={()=>{}}
                                   ranch_id={this.state.ranch_id} onResultLinkClick={this.onScheduleLinkClick}
                                   clinic_id={this.props.rootStore.getClinicIdForMaster()}
                                   activeCows={this.props.rootStore.getActiveCows()}
                                   rootStore={this.props.rootStore}
                                   />
                )}
                {
                    this.state.tagEditProps != null && CommonUtil.assertNotNull(this.state.tags, "tags") && (
                        <TagEditPopup
                            onClose={() => this.setState({ tagEditProps: undefined })}
                            targetNames={this.state.tagEditProps.selectedCowNames}
                            targetUnit="頭"
                            targetNameOmitBorderCount={10}
                            targetKindName=""
                            targets={this.state.tagEditProps.cows}
                            allTags={this.state.tags}
                            isExecuting={this.state.isExecuting}
                            onSubmit={targets => this.onSubmitTags(targets)}
                            submitType="保存"
                        />
                    )
                }
            </div>
        )
    }
}

export default withRouter(withContext(RanchMain));