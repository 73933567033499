import React from 'react';
import classnames from 'classnames';

export interface OptionalInputContainerProps {
    isSelected: boolean;
    className?: string;
    containerStyle?: React.CSSProperties;
}

export class OptionalInputContainer extends React.Component<OptionalInputContainerProps,{}> {
    render() {
        return (
            <div className={classnames("optional-input", { "unselected": !this.props.isSelected }, this.props.className)} style={{ ...this.props.containerStyle }}>
                {this.props.children}
            </div>
            );
    }
}