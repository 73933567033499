import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { PageSettings } from '../../config/page-settings.js';
import { withContext, TimePreset } from '../../stores';
import { AppState } from '../../app';
import styles from './user-setting.module.css';
import * as firebase from 'firebase/app';
import { UserApi, UserUpdateReq } from '../../api';
import { TIMEPRESETS, LMT } from '../../config/constant';
import { FreezedArray } from '../../config/util';
import { TimeSelector } from '../../components/parts/time-selector';
import { IconLink } from '../../components/parts/icon-link';
import { AutoCollapse } from '../../components/parts/collapse-container';
import { IconRight } from '../../components/parts/icon-right';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { FormRadio } from '../../components/form/form-radio';
import { All_USER_LICENSES, UserLicenseKey, USER_LICENSE, getLicenseKey } from '../../config/user-license';
import { DIALOG_BUTTONS } from '../../components/form/form-dialog';

type SettingData = {
    userName: string,
    timePresets: FreezedArray<TimePreset>,
    license?: { type: UserLicenseKey, id:string },
    legalName: string
};
const blankData = () => ({ userName:"", timePresets:[], legalName:"" });

interface MyState {
    executing: boolean;
    data: SettingData;
    original: SettingData;
}

class UserSetting extends Base<BaseProps, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            executing: false,
            data: blankData(),
            original: blankData()
        }
    }

    componentDidMount() {

        this.context.handleSetHeader({ title:"ユーザ設定" });
        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        const userName = this.props.rootStore.user.name;
        const setting = this.props.rootStore.user.setting;
        const timePresets = setting?.time_preset ?? TIMEPRESETS;
        const legalName = setting?.legal_name ?? userName;
        const license = setting?.license;
        this.setState({
            data: { userName, timePresets, legalName, license },
            original: { userName, timePresets, legalName, license }
        })
    }

    onEdit = <K extends keyof SettingData>(value: Pick<SettingData, K>) => {
        this.setState({
            data: {
                ...this.state.data,
                ...value
            }
        })
    }

    async onSave() {

        this.setState({ executing: true });
        this.context.handleSetIsLoading(true);

        const setting = {
            time_preset: [...this.state.data.timePresets],
            legal_name: this.state.data.legalName,
            license: this.state.data.license,
        };
        const req: UserUpdateReq = {
            name: this.state.data.userName,
            setting: JSON.stringify(setting)
        };

        const res = await this.comm().send((await UserApi()).updateUserUsingPOST(req));

        this.context.handleSetIsLoading(false);
        this.setState({ executing: false });

        if (res.result !== "OK") return;

        //ローカルで更新
        this.props.rootStore.setUserInfo({
            name: this.state.data.userName,
            setting
        });
        this.setState({
            original: this.state.data
        });
        this.context.showToast("ユーザ情報が更新されました");

    }

    async onDelete() {
        const msg = "削除を実行すると、このユーザで再びログインすることはできなくなります。\n"
                　+ "この操作は取り消しできません。\n"
                  + "本当に削除を実行しますか？";
        const confirmed = await this.context.showDialog("QUESTION", msg, DIALOG_BUTTONS.DELETE_CANCEL) === 0;
        if (!confirmed) return;

        this.setState({ executing: true });
        this.context.handleSetIsLoading(true);

        //firebase情報を消してしまうとAPI認証用のtokenが使えなくなるので、先にテーブルを削除
        const res = await this.comm().send(() => this.context.postAsync("/user/delete", {}));
        if (res.result !== "OK") {
            this.setState({ executing: false });
            this.context.handleSetIsLoading(false);
            return;
        }

        //firebase側だけ削除に失敗するケースも考えられるが、
        //ログインはできなくなるので、削除されたものとして扱う
        await firebase.auth().currentUser?.delete()
                .catch(err => {
                    console.error(err);
                });
        this.setState({ executing: false });
        this.context.handleSetIsLoading(false);
        this.logout();
    }

    render() {

        const classColLabel = "col-form-label col-md-4 col-xs-4 text-lg-right";

        const anyChanged = JSON.stringify(this.state.original) !== JSON.stringify(this.state.data);
        const canSave = this.state.data.userName !== ""
                    && this.state.data.timePresets.every(t => t.name !== "")                
                    && anyChanged;

        return (
            <div className="page-root width-limit">
                <div className="product product-full-height">
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix">
                            <div className="product-body">
                                <div className={styles.row}>
                                    <label className={classColLabel}>表示名</label>
                                    <div className={styles["column-value"]}>
                                        <input type="text" maxLength={LMT.USER.NAME_LEN} className="form-control"
                                            value={this.state.data.userName} data-testid="user-name"
                                            onChange={e => this.onEdit({ userName: e.target.value })} />
                                    </div>
                                </div>
                                <AutoCollapse header="操作設定" className={styles.collapse}>
                                    <div className={styles.row}>
                                        <label className={classColLabel}>時刻入力プリセット</label>
                                        <TimePresetsInput
                                            presets={this.state.data.timePresets}
                                            onChange={ps => this.onEdit({ timePresets:ps })}
                                        />
                                    </div>
                                </AutoCollapse>
                                <AutoCollapse header="資格設定" className={styles.collapse}>
                                    <>
                                    <div className={styles.row}>
                                        <label className={classColLabel}>所有資格</label>
                                        <div className={styles["column-value"]} data-testid="license">
                                            <FormRadio isInline={false}
                                                options={[
                                                    { name:"なし", value:0 },
                                                    ...All_USER_LICENSES.map(l => ({ name:l.name, value: l.no }))
                                                ]}
                                                onChange={val => this.onEdit({
                                                    license: getLicenseKey(val) == null ? undefined : ({ type: getLicenseKey(val)!, id: this.state.data.license?.id ?? "" })
                                                })}
                                                value={this.state.data.license == null ? 0 : USER_LICENSE[this.state.data.license.type].no}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.row}>
                                        <label className={classColLabel}>免許番号</label>
                                        <div className={styles["column-value"]}>
                                            <input type="text" maxLength={LMT.USER.LICENSE_ID_LEN} className="form-control"
                                                data-testid="license-no"
                                                disabled={this.state.data.license == null}
                                                value={this.state.data.license?.id ?? ""}
                                                onChange={e => this.onEdit({
                                                    license: { type: this.state.data.license?.type ?? "AI", id: e.target.value }
                                                })} />
                                        </div>
                                    </div>
                                    <div className={styles.row}>
                                        <label className={classColLabel}>氏名（本名）</label>
                                        <div className={styles["column-value"]}>
                                            <input type="text" maxLength={LMT.USER.NAME_LEN} className="form-control"
                                                data-testid="legal-name"
                                                value={this.state.data.legalName}
                                                onChange={e => this.onEdit({ legalName: e.target.value })} />
                                        </div>
                                    </div>
                                    </>
                                </AutoCollapse>
                                <hr />
                                <AutoCollapse header="重大な設定">
                                    <div className={styles.row}>
                                        <label className={classColLabel}>削除</label>
                                        <div className={styles["column-value"]}>
                                            <button className="btn btn-danger" onClick={() => this.onDelete()}
                                                disabled={this.state.executing}>アカウントを削除する</button>
                                        </div>
                                    </div>
                                </AutoCollapse>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="button-page-footer">
                    <ExecutionButton type="save" onClick={() => this.onSave()} disabled={this.state.executing || !canSave} />
                </div>
            </div>
        )
    }
}

const TimePresetsInput = (props: {
    presets: FreezedArray<TimePreset>,
    onChange: (presets: FreezedArray<TimePreset>) => void,
}) => {

    return (
        <div className={styles["time-preset-container"]}>
            { props.presets.map((ps,i) => (
                <div key={i} className={styles["time-preset"]}>
                    <input type="text" className={"form-control " + styles["time-preset-name"]} value={ps.name}
                        maxLength={LMT.TIME_PRESET.MAX_NAME_LEN}
                        onChange={e => props.onChange(props.presets.map((pp,ppi) => i !== ppi ? pp : ({ name:e.target.value, time:ps.time })))} />
                    <TimeSelector value={ps.time}
                        onChange={t => props.onChange(props.presets.map((pp,ppi) => i !== ppi ? pp : ({ name:ps.name, time:t })))} />
                    <IconRight iconType="remove" onClick={() => props.onChange( props.presets.filter((_,ppi) => i !== ppi) )} />
                </div>
            ))}
            { props.presets.length < LMT.TIME_PRESET.MAX_COUNT && (
                <IconLink text="追加" iconType="add" className="m-l-5"
                    onClick={() => props.onChange([...props.presets, { name: "", time: "00:00" }])} />
            )}
        </div>
    )

}

export default withRouter(withContext(UserSetting));