import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PageSettings } from '../../config/page-settings.js';
import { ICowNameInfo } from '../../components/parts/cows-popup';
import { CowListDto } from '../../api/api';
import { CowList } from '../../components/parts/cow-list';

export interface ISelectCowsPopupProps {
    onClose:()=>void;
    onSubmit:(cows:CowListDto[])=>void;
    cows:Readonly<CowListDto[]>;
    iniSelectedCows:ICowNameInfo[];
    singleSelection?:boolean;
}

interface MyState {
    checkedIds: Set<number>;
}

export class SelectCowsPopup extends React.Component<ISelectCowsPopupProps, MyState> {

    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            checkedIds:new Set<number>()
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.setCheckedIds = this.setCheckedIds.bind(this);
    }

    componentDidMount() {
        this.setState({
            checkedIds: this.props.iniSelectedCows.length === 0 ? new Set()
                : this.props.singleSelection ? new Set([this.props.iniSelectedCows[0].cow_id])
                : new Set<number>(this.props.iniSelectedCows.map(c => c.cow_id))
        });
    }

    private onSubmit() {
        this.props.onSubmit(this.props.cows.filter(c => this.state.checkedIds.has(c.cow_id)));
    }

    setCheckedIds: React.Dispatch<React.SetStateAction<Set<number>>> = (s) => {
        if (typeof s === "object") {
            this.setState({ checkedIds: s })
        } else {
            this.setState({ checkedIds: s(this.state.checkedIds) })
        }
    }

    render() {
        return (
            <div className="modal-wide">
                <Modal isOpen={true} style={{ maxWidth: "350px", margin: "auto", marginTop:"10px" }}>
                    <ModalHeader toggle={this.props.onClose}>牛を選択</ModalHeader>
                    <ModalBody style={{ overflowY: "auto", maxHeight: "calc(100vh - 160px)" }}>
                        <CowList
                            checkedCowIds={this.state.checkedIds}
                            cows={this.props.cows}
                            setCheckedCowIds={this.setCheckedIds}
                            singleSelection={this.props.singleSelection}
                        />
                    </ModalBody>
                    <ModalFooter style={{display:"flex", justifyContent: this.props.singleSelection ? "flex-end" : "space-between" }}>
                        { !this.props.singleSelection && <button className="btn btn-gray" disabled={this.state.checkedIds.size === 0} onClick={() => this.setState({checkedIds:new Set<number>()})}>選択解除</button> }
                        <button className="btn btn-success" disabled={this.props.singleSelection && this.state.checkedIds.size === 0} onClick={this.onSubmit}>決定</button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}