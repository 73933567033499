import { observer } from 'mobx-react';
import { FormPage } from "../form/form-page";
import { RouteComponentProps } from 'react-router-dom';
import RootStore from '../../stores/RootStore';
import { StaticContext } from 'react-router';
import * as H from 'history';
import { RanchAuthItemKey, ClinicAuthItemKey, hasRanchAuth, hasClinicAuth } from '../../config/auth-checker';
import { RanchContractItemKey, hasRanchContract } from '../../config/contract-checker';
import { Communicator } from '../../api/communicator';
import { PageSettings } from '../../config/page-settings';
import { AppState } from '../../app';
import { GenericResponseCowDetailDto } from '../../api';
import { AxiosResponse } from 'axios';
import { EventKindKey, EVENT_KIND } from '../../config/event-kind';
import { A } from '../../config/constant';
import { historyLocation } from '../../config/history-location-builder';
import { UserTeams } from '../../config/user-teams';

export interface BaseProps<
		Params extends { [K in keyof Params]?: string } = {},
		C extends StaticContext = StaticContext,
		S = H.LocationState
	> extends RouteComponentProps<Params,C,S> {
	rootStore: RootStore
}

class Base<P extends BaseProps, S> extends FormPage<P,S> {

	readonly id : string;

	private _comm?: Communicator;

	static contextType = PageSettings;
	context!: AppState;
	
	comm = () => {
		if (this._comm == null) {
			if (this.context == null) {
				throw Error("context not found");
			}
			this._comm = new Communicator({ ...this, ...this.context });
		}
		return this._comm;
	}

	//※ api.tsを使ったリクエストがエラーになるので便宜的に定義しておく
	getCowInfo = (ranchId: number, cowId: number, isDetail: boolean): Promise<AxiosResponse<GenericResponseCowDetailDto>> => {
		const url = `/cow/${cowId}?user_id=xxx&ranch_id=${ranchId}&is_detail=${isDetail ? 1 : 0}`;
		return this.context.postAsync(url, {});
	}

	constructor(props) {
		super(props);

		this.id = "Page-" + Math.random().toString().substr(2, 5);
	}

	logout = () => {
		this.props.rootStore.logout();
	}

	//TODO 各自で呼び出しているので基底クラスに持っている必要があまりない
	handleNotAllowAccess = (teamId?: number|undefined,
							ranchAuthes?: RanchAuthItemKey[],
							clinicAuthes?: ClinicAuthItemKey[],
							ranchContracts?: RanchContractItemKey[]) => {
		let location = this.props.location;

		if (location === undefined || location === null) {
			location = this.props.history.location;
		}

		const user = this.props.rootStore.user;

		if (location.pathname !== '/') {
			if (user.id === '') {
				this.props.rootStore.logout();
				return true;
			}
		}

		if (teamId == null) {
			teamId = this.props.rootStore.cur_ranch_id;
		}

		const team = new UserTeams(user).findTeam(teamId);
		if (team == null) {
			this.props.rootStore.logout();
			return true;
		}

		if (team.isRanch && ranchAuthes != null) {
			if (ranchAuthes.some(r => !hasRanchAuth(r, team.team_id, user))) {
				this.props.rootStore.logout();
				return true;
			}
		} else if (!team.isRanch && clinicAuthes != null) {
			if (clinicAuthes.some(c => !hasClinicAuth(c, team.team_id, user))) {
				this.props.rootStore.logout();
				return true;
			}
		}
		if (team.isRanch && ranchContracts != null) {
			if (ranchContracts.some(r => !hasRanchContract(r, team.team_id, user))) {
				this.props.rootStore.logout();
				return true;
			}
		}
		
		return false;
	}

	navToRanchTopIfSelfEditOnly = (eventKind: EventKindKey, watchedBy: string): boolean => {
		if (EVENT_KIND[eventKind].selfEditOnly !== true) return false;

		const myId = this.props.rootStore.user.id;
		if (myId === watchedBy) return false;

		this.context.showModal(A.MESSAGE.SELF_EDIT_ONLY, "ALERT", ["OK"], () => {
			this.props.history.replace("/top/" + this.props.rootStore.cur_ranch_id);
		});
		return true;
	}

	setStateAsync = <K extends keyof S>(state:Pick<S, K>) => {
        return new Promise(resolve => {
            this.setState(state, resolve);
        });
	}

}

export default observer(Base);