import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { AppState } from '../../app';
import { A } from '../../config/constant';
import { PageSettings } from '../../config/page-settings.js';
import Validation from './validation';
import { withContext } from '../../stores';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import styles from './user.module.css'
import { UserApi } from '../../api';
import classnames from 'classnames';
import { ServiceTermPopup } from './service-term-popup';

interface MyProps extends BaseProps {
}

interface IUserData {
    user_name:string,
    email:string;
    email_confirm:string;
    password:string;
    password_confirm:string;
}

interface MyState {
    activeBg: string;
    info:IUserData;
    message:IUserData;
    isMailSent:boolean;
    executing: boolean;
    isAgreed: boolean;
    isServiceTermPopupShown: boolean;
}

class Register extends Base<MyProps, MyState> {

    static contextType = PageSettings;
    context!:AppState;

    constructor(props) {
        super(props);

        this.state = {
            activeBg: '/assets/img/login-bg/login-bg.jpg',
            info: {
                user_name:"",
                email:"",
                email_confirm:"",
                password:"",
                password_confirm:"",
            },
            message: {
                user_name:"",
                email:"",
                email_confirm:"",
                password:"",
                password_confirm:"",
            },
            isMailSent: false,
            executing: false,
            isAgreed: false,
            isServiceTermPopupShown: false,
        }

        this.handleChange = this.handleChange.bind(this);
        this.register = this.register.bind(this);
        this.back = this.back.bind(this);
    }

    componentDidMount() {
        this.context.handleSetHeader(undefined);
        this.context.handleSetFooter(false);
        this.context.handleSetPageError(false);
    }

    componentWillUnmount() {
        this.context.handleSetHeader({ title:"" });
        this.context.handleSetFooter(true);
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const key = event.target.name;
        const value = event.target.value;
        const { info, message } = this.state;
    
        this.setState({
            info: { ...info, [key]: value }
        });
        let target_key = key.replace("_confirm", "");

        let temp_message:IUserData = {
            ...message,
            [key]: Validation.formValidate(key, value, info[target_key]),
        }

        const confirm_key = `${key}_confirm`;
        if(confirm_key in info) {
            temp_message = {
                ...temp_message,
                [key]: Validation.formValidate(key, value),
                [confirm_key]: Validation.formValidate(confirm_key, info[confirm_key], value),
            }
        }

        if (key.indexOf("_confirm") !== -1) {
            const target_value = info[key.replace("_confirm", "")];
            temp_message = {
                ...temp_message,
                [key]: Validation.formValidate(key, value, target_value),
            }
        }

        this.setState({
            message: temp_message,
        });
    };

    canSubmit = (): boolean => {
        if (!this.state.isAgreed) return false;

        const { info } = this.state;
        const validInfo = Object.values(info).filter(value => {
            return value === '';
        }).length === 0;
        return validInfo && !this.context.isLoadingProgress;
    };

    async register() {

        const { message } = this.state;
        const messages = Object.values(message).filter(value => {
            return value !== '';
        });

        if (messages.length > 0) {
            this.context.showAlert(messages.join("\n"));
            return;
        }

        this.setState({ executing: true });
        this.context.handleSetIsLoading(true);
        try {
            // 登録の確認
            const email = this.state.info.email;
            const providers = await firebase.auth().fetchSignInMethodsForEmail(email)
                                .catch(e => {
                                    console.error(e);
                                });
            if (providers == null) {
                this.context.handleSetIsLoading(false);
                this.context.showToast(A.MESSAGE.FAILED_USER_ADD);
                return;
            }
            
            if (providers.findIndex(p => p === firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) !== -1) {
                this.context.handleSetIsLoading(false);
                this.context.showAlert(A.MESSAGE.USER_ALREADY_ADDED);
                return;
            }
            
            // ユーザ作成・登録
            const user = await this.createUser();
            this.context.handleSetIsLoading(false);

            if( user != null ) {
                this.props.history.replace('sendmail',{sendMail: true});
            }
        } finally {
            this.setState({ executing: false});
        }
    }

    private async createUser(): Promise<firebase.User|null> {

        const email = this.state.info.email;
        const password = this.state.info.password;

        // ユーザ作成（firebase)
        return await firebase.auth().createUserWithEmailAndPassword(email, password)
        .then( async uc => {
            if (uc.user == null) {
                console.error("firebase auth returns null");
                this.context.showAlert(A.MESSAGE.FAILED_USER_ADD);
                return null;
            }

            // ユーザ作成(DB)
            const res = await (this.comm()).send((await UserApi()).signupUsingPOST({
                name: this.state.info.user_name,
                mail: this.state.info.email
            }));
            if (res.result !== "OK") {
                if ( uc.user != null) {
                    this.deleteUser(uc.user);
                }
                return null;
            }

            return uc.user;
        })
        .catch((error) => {
            console.error(`ERROR:${error.code} - ${error.message}`);
            this.context.showAlert(A.MESSAGE.FAILED_USER_ADD);
            return Promise.resolve(null);
        });

    }

    private async deleteUser(user:firebase.User) {
        await user.delete().catch(error => {
            console.error(`ERROR:${error.code} - ${error.message}`);
            this.context.showAlert(A.MESSAGE.FAILED_USER_DELETE);
        });
    }

    back() {
        window.location.replace("login");
    }

    render() {
        const { info, message } = this.state;
        return (
            <React.Fragment>
                <div className="login-cover" style={{background:"#0066aa"}}>
                    {/*
                    //use background image
                    <div className="login-cover-image"
                        style={{ backgroundImage: 'url(' + this.state.activeBg + ')' }}></div>
                    */}
                    {/*
                    //use mask layer
                    <div className="login-cover-bg"></div>
                    */}
                </div>

                <div className="login login-v2">
                    <div className="login-header">
                        <div className="brand">
                            <span className="logo"></span> <b>vetell</b>
                        </div>
                    </div>
                    <div className="login-content">
                        <div className="form-group m-b-10">
                            <span className={styles.title}>新規登録</span>
                        </div>
                        <div className="form-group m-b-20">
                            <input type="text" className={classnames("form-control","form-control-lg",styles["user-name"])} placeholder="表示名（例：ベッテル太郎、獣医師ベッテル）"
                                name="user_name" value={info.user_name} onChange={e => this.handleChange(e)} />
                        </div>
                        <div className="form-group m-b-10">
                            <input type="text" className="form-control form-control-lg" placeholder="メールアドレス"
                                name="email" value={info.email} onChange={e => this.handleChange(e)} />
                        </div>
                        <div className="form-group m-b-20">
                            <input type="text" className="form-control form-control-lg" placeholder="メールアドレス（再入力）"
                                name="email_confirm" value={info.email_confirm} onChange={e => this.handleChange(e)} />
                        </div>
                        <div className="form-group m-b-10">
                            <input type="password" className="form-control form-control-lg" placeholder="パスワード"
                                name="password" value={info.password} onChange={e => this.handleChange(e)} />
                        </div>
                        <div className="form-group m-b-20">
                            <input type="password" className="form-control form-control-lg" placeholder="パスワード（再入力）"
                                name="password_confirm" value={info.password_confirm} onChange={e => this.handleChange(e)} />
                        </div>
                        <div className="login-buttons m-t-10 m-b-20 text-center">
                            <div className="checkbox checkbox-css">
                                <input type="checkbox" id="chkAgree" checked={this.state.isAgreed} onChange={() => this.setState({ isServiceTermPopupShown: true })}></input>
                                <label htmlFor="chkAgree">
                                    <a style={{textDecoration: "underline", color:"white"}}>利用規約</a> に同意する
                                </label>
                            </div>
                        </div>
                        <div className="login-buttons">
                            <button type="button" className="btn btn-warning btn-block btn-lg" onClick={this.register} disabled={this.state.executing || !this.canSubmit()}>新規登録</button>
                            <button type="button" className="btn btn-secondary btn-block btn-lg" onClick={this.back}>戻る</button>
                        </div>
                    </div>
                </div>

                { this.state.isServiceTermPopupShown && (
                    <ServiceTermPopup
                        isAgreed={this.state.isAgreed}
                        onSubmit={agreed => {
                            this.setState({
                                isAgreed: agreed,
                                isServiceTermPopupShown: false
                            })
                        }}
                        onClose={() => this.setState({ isServiceTermPopupShown: false })}
                    />
                )} 

            </React.Fragment>
        )
    }
}

export default withRouter(withContext(Register))