import React from 'react';
import { OptionalDatePicker, PopperPlacement, FormDatePicker } from '../form/form-date-picker';
import moment from 'moment';

interface BaseProps {
    className?: string;
    style?: React.CSSProperties;
    disabled?: boolean;
    portalContainerID?: string;
    label?: string;
    stPlacement?: PopperPlacement;
    edPlacement?: PopperPlacement;
    monthOnly?: boolean;
    maxMonthSpan?: number;
    minStDate?: Date;
    maxStDate?: Date;
    minEdDate?: Date;
    maxEdDate?: Date;
}

interface OptionalTermProps extends BaseProps {
    stValue: Date | undefined;
    edValue: Date | undefined;
    onChange: (st?: Date, ed?: Date) => void;
}

interface RequiredTermProps extends BaseProps {
    stValue: Date;
    edValue: Date;
    onChange: (st: Date, ed: Date) => void;
}

const fixStByEd = <T extends Date | undefined>(st: T, ed: T, maxMonthSpan: number | undefined) => {
    if (st == null || ed == null) return st;

    const mst = moment(st);
    const med = moment(ed);
    if (med.isBefore(mst)) return ed;

    if (maxMonthSpan != null && maxMonthSpan > 0 && med.diff(mst, "month") > maxMonthSpan) {
        const res = moment(med).subtract(maxMonthSpan, "month");
        return res.toDate();
    }
    return st;
}
const fixEdBySt = <T extends Date | undefined>(st: T, ed: T, maxMonthSpan: number | undefined) => {
    if (st == null || ed == null) return ed;
    const mst = moment(st);
    const med = moment(ed);
    if (mst.isAfter(med)) return st;

    if (maxMonthSpan != null && maxMonthSpan > 0 && med.diff(mst, "month") > maxMonthSpan) {
        const res = moment(mst).add(maxMonthSpan, "month");
        return res.toDate();
    }

    return ed;
}

export const OptionalTermSelector: React.FC<OptionalTermProps> = (props) => {

    const onStChange = (st?: Date) => {
        const ed = fixEdBySt(st, props.edValue, props.maxMonthSpan);
        props.onChange(st, ed);
    }
    const onEdChange = (ed?: Date) => {
        const st = fixStByEd(props.stValue, ed, props.maxMonthSpan);
        props.onChange(st, ed);
    }

    return (
        <div className={props.className} style={{ alignItems:"center", display:"flex", ...props.style }}>
            { props.label && (
                <span>{props.label}</span>
            )}
            <OptionalDatePicker
                name="s"
                value={props.stValue}
                onChange={e => onStChange(e.target.value)}
                placement={props.stPlacement ?? "top-start"}
                disabled={props.disabled}
                portalContainerID={props.portalContainerID}
                monthOnly={props.monthOnly}
                minDate={props.minStDate}
                maxDate={props.maxStDate}
            />
            <span className="m-r-5 m-l-5">～</span>
            <OptionalDatePicker
                name="e"
                value={props.edValue}
                onChange={e => onEdChange(e.target.value)}
                placement={props.edPlacement ?? "top-end"}
                disabled={props.disabled}
                portalContainerID={props.portalContainerID}
                monthOnly={props.monthOnly}
                minDate={props.minEdDate}
                maxDate={props.maxEdDate}
            />
        </div>
    )
}

export const RequiredTermSelector: React.FC<RequiredTermProps> = (props) => {

    const onStChange = (st: Date) => {
        const ed = fixEdBySt(st, props.edValue, props.maxMonthSpan);
        props.onChange(st, ed);
    }
    const onEdChange = (ed: Date) => {
        const st = fixStByEd(props.stValue, ed, props.maxMonthSpan);
        props.onChange(st, ed);
    }

    return (
        <div className={props.className} style={{ alignItems:"center", display:"flex", ...props.style }}>
            { props.label && (
                <span>{props.label}</span>
            )}
            <FormDatePicker
                name="s"
                value={props.stValue}
                onChange={e => onStChange(e.target.value)}
                placement={props.stPlacement ?? "top-start"}
                disabled={props.disabled}
                portalContainerID={props.portalContainerID}
                monthOnly={props.monthOnly}
                minDate={props.minStDate}
                maxDate={props.maxStDate}
            />
            <span className="m-r-5 m-l-5">～</span>
            <FormDatePicker
                name="e"
                value={props.edValue}
                onChange={e => onEdChange(e.target.value)}
                placement={props.edPlacement ?? "top-end"}
                disabled={props.disabled}
                portalContainerID={props.portalContainerID}
                monthOnly={props.monthOnly}
                minDate={props.minEdDate}
                maxDate={props.maxEdDate}
            />
        </div>
    )
}