import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ISymptomForDoctor } from './symptom-write';
import { IDisease, ITeamCondition } from '../../stores';
import { DiseaseInput } from './disease-input';
import { FreezedArray } from '../../config/util';
import { DiseaseCauseDto, TeamDiseaseCauseDto } from '../../api';
import { ExecutionButton } from '../../components/buttons/execution-button';

interface MyProps {
    onClose: () => void;
    isOpen: boolean;
    data: ISymptomForDoctor;
    diseases: FreezedArray<IDisease>;
    causes: FreezedArray<DiseaseCauseDto>;
    relations: FreezedArray<TeamDiseaseCauseDto>
    conditions: FreezedArray<ITeamCondition>;
    noticeList: Readonly<string[]>;
    onSubmit: (d: ISymptomForDoctor) => void;
    isBreeding?: boolean;
}

interface MyState {
    input: ISymptomForDoctor;
}

export class DiseaseInputPopup extends React.Component<MyProps, MyState> {

    constructor(props) {
        super(props);

        this.state = {
            input: this.props.data,
        };

        this.onClose = this.onClose.bind(this);
        this.onCloseWithResult = this.onCloseWithResult.bind(this);
    }

    onClose() {
        this.props.onClose();
    }

    onCloseWithResult() {
        this.props.onSubmit(this.state.input);
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.isOpen} style={{ maxWidth: "540px" }}>
                    <ModalHeader toggle={this.props.onClose} style={{ width: "100%" }}>疾病入力</ModalHeader>
                    <ModalBody style={{ overflowY: "auto", height: "calc(100vh - 190px)" }}>
                        <DiseaseInput
                            conditions={this.props.conditions}
                            causes={this.props.causes}
                            diseases={this.props.diseases}
                            data={this.state.input}
                            relations={this.props.relations}
                            noticeList={this.props.noticeList}
                            onChange={d => this.setState({ input: d })}
                            isBreeding={this.props.isBreeding}
                        />
                    </ModalBody>
                    <ModalFooter className="modal-footer-fix">
                        <ExecutionButton type="decide" onClick={this.onCloseWithResult} />
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}