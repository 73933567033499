import React, { useState, useEffect } from 'react';
import { Panel, PanelBody, PanelHeader } from '../../components/panel/panel.jsx';
import { IRanchFeed, IFeedType } from '../../stores';
import { LMT } from '../../config/constant';
import { RequiredNumInput, OptionalNumInput } from '../../components/parts/num-input';
import { CommonSelect } from '../../components/parts/common-select';

interface IFeed {
    delivered: number;
    feed_type_no: number;
    leftover: number | undefined;
    feed_no: number;
}

interface MyProps<TFeed extends IFeed> {
    ranchFeedList: Readonly<Readonly<IRanchFeed>[]>;
    feedTypeList: Readonly<Readonly<IFeedType>[]>;
    feed: Readonly<TFeed>;
    onRemove: () => void;
    onChange: (feed: TFeed) => void;
    onFeedNoChanging: (feedNo: number) => boolean;
}

export const FeedInputPanel = <TFeed extends IFeed>(props: MyProps<TFeed>) => {
    const [ unit, setUnit ] = useState("");

    useEffect(() => {
        if (props.feed.feed_no === 0) {
            setUnit("");
            return;
        }
        const item = props.ranchFeedList.find(f => f.feed_no === props.feed.feed_no);
        setUnit(item?.unit ?? "");

    }, [ props.ranchFeedList, props.feed ]);

    const onFeedTypeChange = (typeNo: number) => {
        const feedNo = props.feed.feed_no === 0 ? 0
                     : typeNo === 0 ? props.feed.feed_no
                     : props.ranchFeedList.find(f => f.feed_no === props.feed.feed_no)?.feed_type_no === typeNo ? props.feed.feed_no
                     : 0;

        props.onChange({
            ...props.feed,
            feed_type_no: typeNo,
            feed_no: feedNo
        })
    }

    const onFeedNoChange = (feedNo: number) => {
        //※実装として対応しているが、現在のコンポーネントではvalue=0の更新が発生しない想定
        if (feedNo === 0) {
            console.error("feedNo=0 selected");
        }

        if (feedNo !== 0 && !props.onFeedNoChanging(feedNo)) {
            return;
        }

        props.onChange({ ...props.feed, feed_no: feedNo });
    }

    return (
        <Panel onClose={props.onRemove}>
            <PanelHeader>
                <select className="form-control" style={{ width: "200px" }} value={props.feed.feed_type_no}
                    onChange={e => onFeedTypeChange(Number(e.target.value))}>
                    <option value={0}>すべての品目</option>
                    {
                        props.feedTypeList.map(t => (
                            <option key={t.feed_type_no} value={t.feed_type_no}>{t.name}</option>
                        ))
                    }
                </select>
            </PanelHeader>
            <PanelBody>
                <div>
                    <div className="form-group row">
                        <label className="col-form-label col-md-3 col-xs-3">品目</label>
                        <div style={{ width: "210px" }} data-testid="えさ品目">
                            <CommonSelect
                                itemName="品目"
                                onCreate={undefined}
                                value={props.feed.feed_no === 0 ? undefined : props.feed.feed_no}
                                onSelect={onFeedNoChange}
                                options={props.ranchFeedList
                                            .filter(f => props.feed.feed_type_no === 0 || f.feed_type_no === props.feed.feed_type_no)
                                            .map(f => ({ label:f.name, value:f.feed_no }))}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-form-label col-md-3 col-xs-3">あげた量</label>
                        <div>
                            <RequiredNumInput
                                testId="あげた量"
                                min={LMT.FEEDING.AMOUNT_MIN} max={LMT.FEEDING.AMOUNT_MAX}
                                step={LMT.FEEDING.AMOUNT_STEP}
                                value={props.feed.delivered}
                                style={{ width: "76px", marginRight:"5px", display: "inline" }}
                                onChange={val => props.onChange({...props.feed, delivered: val})}
                            />
                            <span>{unit}</span>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-form-label col-md-3 col-xs-3">残した量</label>
                        <div>
                            <OptionalNumInput
                                testId="残した量"
                                min={LMT.FEEDING.AMOUNT_MIN} max={LMT.FEEDING.AMOUNT_MAX}
                                step={LMT.FEEDING.AMOUNT_STEP}
                                value={ props.feed.leftover === -1 ? undefined : props.feed.leftover }
                                style={{ width: "76px", marginRight:"5px", display: "inline" }}
                                onChange={val => props.onChange({ ...props.feed, leftover: val ?? -1 }) }
                            />
                            <span>{unit}</span>
                            <span>（食べた量 {Number(props.feed.delivered - ((props.feed.leftover ?? 0) === -1 ? 0 : props.feed.leftover ?? 0)).toFixed(1)}</span>
                            <span>{unit}）</span>
                        </div>
                    </div>
                </div>
            </PanelBody>
        </Panel>
    );
}