import React from 'react';
import { Checkbox } from './form-checkbox';
import classnames from 'classnames';

interface MultiCheckboxProps {
    allowsNoSelect?: boolean;
    onChange: (values:Set<number>) => void;
    values: Set<number>;
    options: { name:string, value:number }[];
    prefix: string;
    className?: string;
}

export const MultiCheckbox = (props: MultiCheckboxProps) => {
    const onCheck = (value: number, checked: boolean) => {
        const newSet = new Set(props.values);
        if (checked) {
            newSet.add(value);
        } else {
            newSet.delete(value);
            if (newSet.size === 0 && props.allowsNoSelect !== true) return;
        }

        props.onChange(newSet);
    }

    return (<>
        { props.options.map((o,i) => (
            <Checkbox key={o.value} label={o.name} checked={props.values.has(o.value)}
                id={props.prefix + i} onChange={e => onCheck(o.value, e.target.checked)}
                //※class名の指定がないときだけデフォルトでマージンを付与
                className={props.className ?? classnames({"m-l-10":i > 0})} />
        ))}
    </>)
}