import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { RequiredNumInput } from '../../components/parts/num-input';
import { V3DateTime } from '../../components/parts/v3-date-time-picker';
import { LMT } from '../../config/constant';
import { ExecutionButton } from 'components/buttons/execution-button';

export interface SellSeedValues {
    soldAt: Date;
    price: number;
    note: string;
}

export interface SellSeedPopupProps {
    data: SellSeedValues;
    onClose: () => void;
    onSubmit:(data: SellSeedValues)=>void;
    onDelete: ()=>void;
    canDelete: boolean;
    isSubmitExecuting: boolean;
}

export const SellSeedPopup: React.FC<SellSeedPopupProps> = (props) => {
    const [ note, setNote ] = useState("");
    const [ soldAt, setSoldAt ] = useState(new Date());
    const [ price, setPrice ] = useState(0);

    useEffect(() => {
        setNote(props.data.note);
        setSoldAt(props.data.soldAt);
        setPrice(props.data.price);
        
    }, [ props.data ]);

    const rowStyle: React.CSSProperties = { display:"flex", marginBottom:"14px" };
    const rowHeaderStyle: React.CSSProperties = { width: "90px", paddingTop:"7px" };
    const rowContentStyle: React.CSSProperties = { flex: 1, display:"flex", alignItems:"center" };
    const inputStyle = { width:"180px" };

    const ID = "sell-seed-popup";

    return (
        <div className="modal-wide">
            <Modal isOpen={true} centered={true} style={{ padding:"0 14px"}} id={ID}>
                <ModalHeader toggle={props.onClose}></ModalHeader>
                <ModalBody>
                    <div style={rowStyle}>
                        <div style={rowHeaderStyle}>売却日時</div>
                        <div style={rowContentStyle}>
                            <span style={{ flex: 1 }}>
                                <V3DateTime value={soldAt}
                                    portalContainerID={ID}
                                    onChange={d => setSoldAt(d.toDate())} />
                            </span>
                        </div>
                    </div>
                    <div style={rowStyle}>
                        <div style={rowHeaderStyle}>売却価格</div>
                        <div style={rowContentStyle} >
                            <RequiredNumInput value={price} onChange={n => setPrice(n)}
                                max={LMT.SEED.STOCK_PRICE_MAX} min={LMT.SEED.STOCK_PRICE_MIN}
                                step={LMT.SEED.STOCK_PRICE_STEP}
                                style={inputStyle}
                            />
                            <span style={{marginLeft:"4px"}} >円</span>
                        </div>
                    </div>
                    <div style={rowStyle}>
                        <div style={{ ...rowHeaderStyle }}>メモ</div>
                        <div style={rowContentStyle}>
                            <textarea className="form-control" rows={3} maxLength={LMT.SEED.STOCK_MEMO_LEN}
                                value={note} onChange={(e) => setNote(e.target.value)}></textarea>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className="modal-footer-fix">
                    <ExecutionButton type="save" onClick={() => props.onSubmit({ note, price, soldAt })} disabled={props.isSubmitExecuting}>売却</ExecutionButton>
                    { props.canDelete && (
                        <ExecutionButton type="delete" onClick={props.onDelete} disabled={props.isSubmitExecuting} />
                    )}
                </ModalFooter>
            </Modal>
        </div>

    );
}