import moment from "moment";
import { MapUtil } from "../config/map-util";

const MASTER_NAMES = ["COW"] as const;
export type MasterName = typeof MASTER_NAMES[number];

//端末ごとの時間誤差を考えて最終取得日時から減算する分数
const MINUTE_MARGIN = 5;

class FetchedDateMap {

    private readonly teamDateMap: Map<number, Map<MasterName, Date>> = new Map();

    private saveStore() {

        try {
            const obj = MapUtil.toObj(this.teamDateMap);
            const json = JSON.stringify(obj);
            sessionStorage.setItem("fetched", json);
            console.log("saved", json);
        } catch (e) {
            console.error("failed to save fetched dates to session storage", e);
        }
    }

    public loadStore() {
        const json = sessionStorage.getItem("fetched");
        if (json == null) return;
        const obj = JSON.parse(json);

        try {
            for (const key in obj) {
                const teamId = parseInt(key);
                if (isNaN(teamId)) continue;

                const teamMap = new Map<MasterName, Date>();
                this.teamDateMap.set(teamId, teamMap);

                const teamObj = obj[key];

                for (const name in teamObj) {
                    if (MASTER_NAMES.every(m => m !== name)) {
                        continue;
                    }
                    if (teamObj[name] == null || teamObj[name] === "") continue;
                    const m = moment(teamObj[name]);
                    if (!m.isValid) continue;
                    teamMap.set(name as MasterName, m.toDate());
                }
            }
            console.log("loaded", this.teamDateMap);

        } catch(e) {
            console.error("failed to load fetched dates from session storage", e);
        }
    }

    public clear() {
        console.log("clear fetched dates");
        this.teamDateMap.clear();
        this.saveStore();
    }
    public delete(teamId:number, name: MasterName) {
        const m = this.teamDateMap.get(teamId);
        if (m == null) return;

        m.delete(name);
        this.saveStore();
    }

    public getDate(teamId:number, name: MasterName) {
        const m = this.teamDateMap.get(teamId);
        if (m == null) return undefined;

        return m.get(name);
    }
    public update(teamId:number, name: MasterName, now: Date) {
        let m = this.teamDateMap.get(teamId);
        if (m == null) {
            m = new Map();
            this.teamDateMap.set(teamId, m);
        }

        const val = moment(now).subtract(MINUTE_MARGIN, "minute").toDate();
        m.set(name, val);
        this.saveStore();
    }
}

export const fetchedDateMap = new FetchedDateMap();