import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { PageSettings } from '../../config/page-settings.js';
import { withContext } from '../../stores';
import { AppState } from '../../app';
import bStyles from './egg-base-style.module.css';
import { EggRankCounter, EggRankData, isValidRankData } from './egg-rank-counter';
import { V3DateTime } from '../../components/parts/v3-date-time-picker';
import { TIMEPRESETS, LMT, EVENT_TYPE_PARAM } from '../../config/constant';
import { EggStockModal } from './egg-stock-modal';
import { IEventWriteLocationState, IEventWriteParamCow, historyLocation } from '../../config/history-location-builder';
import { ExtraCowDto, EggApi, SovRankDto, SovModifyReq, SovModifyReqRank, SovDto, BreedingApi } from '../../api';
import { FreezedArray, CommonUtil, ar } from '../../config/util';
import moment from 'moment';
import { ICowNameInfo, CowToDispInfo } from '../../components/parts/cows-popup';
import { AncestorSelect } from '../setting/ancestor-select';
import { CollapseContainer } from '../../components/parts/collapse-container';
import { SettingAncestorPopup } from '../setting/setting-ancestor-popup';
import { hasRanchAuth } from '../../config/auth-checker';
import { UserTeams } from '../../config/user-teams';
import { getAncestors } from '../../stores/fetcher';
import { getCowWithCache } from 'stores/fetcher_cow';
import { SingleCowHeader } from 'components/cow-header/single-cow-header';
import { ExecutionButton } from 'components/buttons/execution-button';
import { DIALOG_BUTTONS } from 'components/form/form-dialog';

type SovInputData = {
    countMap: Map<number, EggRankData>;
    comment: string;
    selectedAncestor?: ExtraCowDto;
}
type CowSovData = IEventWriteParamCow & { input: SovInputData };
type CowSovToStockData = CowSovData & { event_id: number };

interface MyState {
    executing: boolean;
    ranchId?: number;
    isOfficialRanch: boolean;
    watchedAt: Date;
    cows: FreezedArray<CowSovData>;
    original?: SovDto;
    ancestors: FreezedArray<ExtraCowDto>;
    expandedCows:Set<number>;
    isAncestorPopupShown: boolean;

    restStockCows: FreezedArray<CowSovToStockData>;
    currentStockCow?: CowSovToStockData;
}

const toCountMap = (ranks: SovRankDto[]) => {
    const map = new Map<number, EggRankData>();
    ranks.forEach(r => {
        const data: EggRankData = {
            count: r.egg_count,
            stages: r.stages.map(s => ({ stage:s.egg_stage, isFrozen:s.is_frozen === 1, count:s.egg_count, hasStocked:s.seed_lot_id != null }))
        };
        map.set(r.egg_rank, data);
    });
    return map;
}
const toReqRanks = (countMap: Map<number, EggRankData>):SovModifyReqRank[] => {
    return [...countMap.entries()]
        .filter(([_,data]) => data.count > 0)
        .map(([n,data]) => ({
            egg_rank: n,
            egg_count: data.count,
            stages: data.stages.map(s => ({ egg_stage:s.stage, is_frozen:s.isFrozen ? 1 : 0, egg_count: s.count }))
        }));
}

const classColLabel = "col-form-label col-md-4 col-xs-4 text-lg-right";

class SovWrite extends Base<BaseProps<{ id:string },{},IEventWriteLocationState|undefined>, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            watchedAt: new Date(),
            executing: false,
            cows:[],
            ancestors: [],
            expandedCows:new Set(),
            isAncestorPopupShown: false,
            restStockCows: [],
            isOfficialRanch:false
        }
    }

    componentDidMount() {

        this.context.handleSetHeader({ title:"体内受精卵記録" });
        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        const ranchId = this.props.rootStore.cur_ranch_id;
        const cows = this.props.location.state?.cows ?? [];
        const mayEvtId = parseInt(this.props.match.params.id);
        const evtId = isNaN(mayEvtId) ? undefined : mayEvtId;
        const isOfficial = new UserTeams(this.props.rootStore.user).findOfficialRanch(ranchId) != null;

        this.setState({
            ranchId: ranchId === 0 ? undefined : ranchId,
            isOfficialRanch: isOfficial,
            cows: cows.map(c => ({ ...c, input: { comment:"", countMap:new Map() }})),
            expandedCows:new Set(cows.map(c => c.cow_id))
        }, () => {
            this.loadInitial(evtId);
        });
    }

    private async loadInitial(eventId: number | undefined) {
        if (this.state.ranchId == null) return;

        this.context.handleSetIsLoading(true);

        try {
            const ancestors = await getAncestors(this.state.ranchId);
            if (ancestors == null) {
                this.context.showToast("データの取得に失敗しました");
                return;
            }
            this.setState({ ancestors });

            if (eventId != null) {
                const oriReq = { ranch_id: this.state.ranchId, id: eventId };
                const oriRes = await this.comm().send((await EggApi()).getSovUsingPOST(oriReq), { retries: true });
                const original = oriRes.data;
                if (original == null) return;

                if (this.navToRanchTopIfSelfEditOnly("SOV", original.watched_by)) return;

                const cowRes = await this.comm().send(() => getCowWithCache(oriReq.ranch_id, original.cow_id), { retries: true });
                if (cowRes.data == null) return;

                this.setState({
                    original,
                    cows: [ {
                        ...cowRes.data,
                        use_no:cowRes.data.use_no,
                        breed_no:cowRes.data.breed_no,
                        input: {
                            comment: original.comment,
                            countMap: toCountMap(original.ranks),
                            selectedAncestor: { ex_cow_id:original.father_exid, name:original.father_name }
                        }
                    }],
                    expandedCows: new Set([ cowRes.data.cow_id ]),
                    watchedAt: moment(original.watched_at).toDate(),
                })
            } else {
                if (this.state.cows.length === 0) return;

                const searchFrom = moment().add(LMT.SOV.AI_SEARCH_DAYS * -1, "days").format("YYYY-MM-DD");
                const aiReq = {
                    ranch_id: this.state.ranchId,
                    cow_ids: this.state.cows.map(c => c.cow_id),
                    from: searchFrom
                };
                const aiRes = await this.comm().send((await BreedingApi()).getLastAiFatherUsingPOST(aiReq), { showsErrorMessage: false });
                if (aiRes.result !== "OK") return;
                const aiList = aiRes.data ?? [];

                this.setState({
                    cows: this.state.cows.map(cw => {
                        const ai = aiList.find(a => a.cow_id === cw.cow_id);
                        if (ai == null) return cw;

                        return {
                            ...cw,
                            input: {
                                ...cw.input,
                                selectedAncestor: { ex_cow_id: ai.father_exid, name: ai.father_name }
                            }
                        }
                    })
                })

            }

        } finally {
            this.context.handleSetIsLoading(false);
        }
    }

    private async onAncestorUpdated() {
        if (!CommonUtil.assertNotNull(this.state.ranchId, "ranchId")) return;
        const ancestors = await getAncestors(this.state.ranchId);
        if (ancestors == null) {
            this.context.showToast("データの取得に失敗しました");
            return;
        }
        this.setState({ ancestors });
    }

    private isExpandedChange(cowId: number, expanded: boolean) {
        const newSet = new Set(this.state.expandedCows);
        if (expanded) {
            newSet.add(cowId);
        } else {
            newSet.delete(cowId);
        }
        this.setState({ expandedCows: newSet });
    }

    private setExecuting(executing: boolean) {
        this.context.handleSetIsLoading(executing);
        this.setState({ executing });
    }

    async onSubmit() {
        if (!CommonUtil.assertNotNull(this.state.ranchId)) return;

        const req: SovModifyReq = {
            ranch_id: this.state.ranchId,
            is_new: this.state.original == null ? 1 : 0,
            watched_at: moment(this.state.watchedAt).format("YYYY-MM-DD HH:mm:00"),
            schedule_id: this.props.location.state?.schedule_id,
            cows: this.state.cows.map(c => ({
                cow_id: c.cow_id,
                father_exid: c.input.selectedAncestor!.ex_cow_id,
                comment: c.input.comment,
                event_id: this.state.original?.event_id,
                ranks: toReqRanks(c.input.countMap)
            }))
        };
        this.setExecuting(true);

        try {
            const res = await this.comm().send((await EggApi()).modifySovUsingPOST(req));
            if (res.result !== "OK") return;

            if (!this.state.isOfficialRanch
                || !hasRanchAuth("MASTER_EDIT", this.state.ranchId, this.props.rootStore.user)) {
                this.navigateToNext();
                return;
            }

            const stageContained = this.state.cows.filter(c => [...c.input.countMap.values()].some(r => r.stages.some(s => !s.hasStocked)));
            if (stageContained.length === 0) {
                this.navigateToNext();
                return;
            }
            const cowAndIds = res.data ?? [];
            const cowsWithId = stageContained.map(s => ({ ...s, event_id: cowAndIds.find(c => c.cow_id === s.cow_id)?.id ?? 0 }));
            if (cowsWithId.some(c => c.event_id === 0)) {
                console.error("no event id returned", cowsWithId);
                this.navigateToNext();
                return;
            }

            this.setState({
                currentStockCow: cowsWithId[0],
                restStockCows: cowsWithId.slice(1)
            });

        } finally {
            this.setExecuting(false);
        }
    }

    async onStocked() {
        this.setState({ currentStockCow: undefined });
        this.nextStock();
    }
    onSkipStock(isAll: boolean) {
        if (isAll) {
            this.navigateToNext();
            return;
        }

        this.nextStock();
    }
    nextStock() {
        if (this.state.restStockCows.length === 0) {
            this.navigateToNext();
            return;
        }
        this.setState({
            currentStockCow: this.state.restStockCows[0],
            restStockCows: this.state.restStockCows.slice(1)
        })
    }

    private navigateToNext() {
        if (this.state.cows.length === 1 && this.state.original == null) {
            this.props.history.replace(historyLocation.toCowInfo(this.state.cows[0].cow_id));
        } else {
            this.props.history.go(-1);
        }
    }


    async onDelete() {
        if (!CommonUtil.assertNotNull(this.state.ranchId, "ranchId", "delete")) return;
        if (!CommonUtil.assertNotNull(this.state.original, "original", "delete")) return;
        const conf = (await this.context.showDialog("QUESTION", "記録を削除してよろしいですか？", DIALOG_BUTTONS.DELETE_CANCEL)) === 0;
        if (!conf) return;

        this.setExecuting(true);

        const req = {
            ranch_id: this.state.ranchId,
            id: this.state.original.event_id
        };
        const res = await this.comm().send((await EggApi()).deleteSovUsingPOST(req));
        this.setExecuting(false);

        if (res.result !== "OK") return;

        this.props.history.replace(historyLocation.toCowEvent(
            this.state.cows[0].cow_id,
            moment(this.state.original.watched_at).format("YYYY-MM-DD"),
            EVENT_TYPE_PARAM.BREEDING
        ));

    }

    render() {
        if (this.state.ranchId == null || this.state.cows.length === 0) {
            return <></>;
        }


        const anyInvalid = this.state.cows.some(c => !isValidInput(c.input));

        return (
            <div className="page-root">
                <div className="product product-full-height">
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix">
                            <div className="product-info-header">
                                { this.state.cows.length === 1 ? (
                                    <SingleCowHeader ranchId={this.state.ranchId} cowId={this.state.cows[0].cow_id} />
                                ) : (
                                    <span>{this.state.cows.length}頭の記録</span>
                                )}
                            </div>

                            <div className="product-body">
                                <div className={bStyles.row}>
                                    <label className={classColLabel}>採卵日時</label>
                                    <div className={bStyles["watched-at"]}>
                                        <V3DateTime value={this.state.watchedAt}
                                            popperClassName={bStyles["datepicker-popper"]}
                                            timePresets={this.props.rootStore.user.setting?.time_preset ?? TIMEPRESETS}
                                            onChange={d => this.setState({ watchedAt: d.toDate() })} />
                                    </div>
                                </div>

                                { this.state.cows.length === 1 ? (
                                    <SovInput
                                        ancestors={this.state.ancestors}
                                        data={this.state.cows[0].input}
                                        onChange={d => this.setState({ cows: [ { ...this.state.cows[0], input: d } ] })}
                                        cow={this.state.cows[0]}
                                        onAncestorEdit={() => this.setState({ isAncestorPopupShown: true })}
                                    />
                                ) : this.state.cows.map(c => (
                                    <CollapseContainer key={c.cow_id}
                                        isOpen={this.state.expandedCows.has(c.cow_id)}
                                        header={CowToDispInfo(c, true)}
                                        onIsOpenChange={o => this.isExpandedChange(c.cow_id, o)}
                                        className="m-t-5 m-b-15"
                                        headerStyle={{ fontWeight: "bold", color: "#999999" }}
                                    >
                                        <SovInput
                                            ancestors={this.state.ancestors}
                                            data={c.input}
                                            onChange={d => this.setState({
                                                cows: this.state.cows.map(oldC => oldC.cow_id !== c.cow_id ? oldC : { ...oldC, input:d })
                                            })}
                                            cow={c}
                                            onAncestorEdit={() => this.setState({ isAncestorPopupShown: true })}
                                        />
                                    </CollapseContainer>

                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                { this.state.currentStockCow != null && (
                    <EggStockModal
                        ranchId={this.state.ranchId}
                        comm={this.comm()}
                        setIsLoading={this.context.handleSetIsLoading}
                        showQuestion={this.context.showQuestionAsync}
                        isSov={true}
                        onSkip={() => this.onSkipStock(false)}
                        onClose={() => this.onSkipStock(true)}
                        onRegistered={() => this.onStocked()}
                        eventId={this.state.currentStockCow.event_id}
                        father={this.state.currentStockCow.input.selectedAncestor!}
                        cow={this.state.currentStockCow}
                        ranks={[...this.state.currentStockCow.input.countMap.entries()]
                                .map(([r,data]) => ({ rank:r, stages:data.stages.filter(s => !s.hasStocked) }))
                                .filter(r => r.stages.length > 0)
                            }
                    />
                )}
                { this.state.isAncestorPopupShown && (
                    <SettingAncestorPopup
                        comm={this.comm()}
                        confirm={this.context.showQuestionAsync}
                        list={this.state.ancestors}
                        onClose={() => this.setState({ isAncestorPopupShown: false })}
                        teamId={this.state.ranchId}
                        onUpdated={() => this.onAncestorUpdated()}
                    />
                )}
                <div className="button-page-footer">
                    <ExecutionButton type="save" disabled={this.state.executing || anyInvalid}
                        onClick={() => this.onSubmit()}
                    />
                    { this.state.original != null && (
                        <ExecutionButton type="delete" disabled={this.state.executing}
                            onClick={() => this.onDelete()}
                        />
                    )}
                </div>
            </div>
        )
    }
}

const isValidInput = (data: SovInputData) => {
    if (data.selectedAncestor == null) return false;
    const ranks = [...data.countMap.values()];
    if (ranks.some(r => !isValidRankData(r))) return false;
    if (ar.sum(ranks.map(r => r.count)) === 0) return false;
    return true;
}
const SovInput = (props: {
    cow: ICowNameInfo;
    ancestors: FreezedArray<ExtraCowDto>;
    data: SovInputData;
    onChange: (data: SovInputData) => void;
    onAncestorEdit: () => void;
}) => {

    const onAncestorSelect = (id:number|undefined, name:string|undefined) => {
        const data = {...props.data};
        data.selectedAncestor = id == null ? undefined : { ex_cow_id:id, name: name ?? "" };
        props.onChange(data);
    }
    const onRankChange = (countMap: Map<number, EggRankData>) => {
        props.onChange({
            ...props.data,
            countMap
        });
    }

    return (
        <div data-testid={`cow__${props.cow.cow_id}`}>
            <div className={bStyles.row}>
                <label className={classColLabel}>種雄牛</label>
                <div className={bStyles["column-value"]}>
                    <AncestorSelect list={props.ancestors} testId="ancestor"
                        onEditClick={() => props.onAncestorEdit()}
                        selectedName={props.data.selectedAncestor?.name}
                        value={props.data.selectedAncestor?.ex_cow_id}
                        onChange={onAncestorSelect}
                    />
                </div>
            </div>
            <div className={bStyles.row}>
                <label className={classColLabel}>ランク</label>
                <EggRankCounter className="m-l-15"
                    countMap={props.data.countMap}
                    onChange={onRankChange}
                />
            </div>
            <div className={bStyles.row}>
                <label className={classColLabel}>メモ</label>
                <div className={bStyles["column-value"]}>
                    <textarea className="form-control" rows={2}
                        maxLength={LMT.SOV.MEMO_LEN}
                        value={props.data.comment}
                        onChange={e => props.onChange({ ...props.data, comment:e.target.value })}
                    />
                </div>
            </div>
        </div>
    )
}

export default withRouter(withContext(SovWrite));