import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FreezedArray, ar } from '../../config/util';
import { EGG_STAGES, findEggStage } from '../../config/egg-kind';
import { NumericUpDown } from '../../components/parts/numeric-updown';
import { Checkbox } from '../../components/form/form-checkbox';

export interface EggStageData {
    isFrozen: boolean;
    stage: number;
    count: number;
    hasStocked: boolean;
}

interface MyProps {
    stages: FreezedArray<EggStageData>;
    onSubmit: (stages: FreezedArray<EggStageData>) => void;
    onClose: () => void;
    total: number;
}

type Stage = { egg_stage: number, name: string };

export const EggStageCountModal = (props: MyProps) => {
    const [ stages, setStages ] = useState<FreezedArray<EggStageData>>([]);
    const [ newStage, setNewStage ] = useState<number>();
    const [ restStageList, setRestStageList ] = useState<Stage[]>([]);
    const [ currentSum, setCurrentSum ] = useState(0);

    useEffect(() => {
        setStages(props.stages);
    }, [ props.stages ]);

    useEffect(() => {
        setCurrentSum(ar.sum(stages.map(s => s.count)));
        setRestStageList(EGG_STAGES.filter(S => stages.filter(s => s.stage === S.egg_stage).length < 2));

    }, [ stages ]);

    const onNewStageSelected = (stage: number) => {
        const isFrozen = stages.find(s => s.stage === stage)?.isFrozen !== true;
        setStages([...stages, { stage, count: 0, isFrozen, hasStocked:false }]);
        setNewStage(undefined);
    }

    const onCountChange = (idx: number, count: number) => {
        const newStages = stages.map((s,i) => i !== idx ? s : { ...s, count });

        setStages(newStages);
    }
    const onIsFrozenChange = (idx: number, isFrozen: boolean) => {
        const stg = stages[idx];
        if (stages.filter(s => s.stage === stg.stage).length === 2) return; //新鮮・凍結ともに登録済みなら変更不可

        setStages(stages.map((s,i) => i !== idx ? s : { ...s, isFrozen }));
    }

    const styles: { [key: string]: React.CSSProperties } = {
        row: {
            display: "flex",
            alignItems: "center",
            marginBottom: "8px"
        },
        add: {
            marginTop: "10px",
        },
        select: {
            width: "160px"
        },
        container: {
            minHeight:"340px"
        },
        label: {
            width: "80px"
        },
        counter: {
            width: "90px",
            marginRight: "16px"
        },
        rest: {
            textAlign: "right",
            color: "#666666"
        },
        footer: {
            display: "flex",
            justifyContent: "center"
        }
    }

    return (
        <div>
            <Modal isOpen={true}>
                <ModalHeader toggle={() => props.onClose()}>ステージ</ModalHeader>
                <ModalBody>
                    <div style={styles.container}>
                        { stages.map((st,i) => (
                            <div key={i} style={styles.row} data-testid="stage-item">
                                <div style={styles.label}>{findEggStage(st.stage)?.name ?? ""}</div>
                                { st.hasStocked ? (
                                    <div>{st.count} 個 {st.isFrozen ? "凍結" : ""}（保存済）</div>
                                ) : (<>
                                    <NumericUpDown min={0} max={props.total - (currentSum - st.count)}
                                        style={styles.counter}
                                        onChange={n => onCountChange(i, n)}
                                        value={st.count}
                                    />
                                    <Checkbox checked={st.isFrozen} label="凍結" id={"chkFrozen" + i}
                                        onChange={e => onIsFrozenChange(i, e.target.checked)}
                                    />
                                </>)}
                            </div>
                        ))}
                        { restStageList.length > 0 && (
                            <div style={styles.add}>
                                <select style={styles.select} className="form-control"
                                    value={newStage == null ? "" : newStage}
                                    onChange={e => { if (e.target.value !== "") onNewStageSelected(Number(e.target.value)) }}>
                                    <option value="">選択</option>
                                    { restStageList.map(s => (
                                        <option key={s.egg_stage} value={s.egg_stage}>{s.name}</option>
                                    ))}
                                </select>
                            </div>
                        )}
                        <div style={styles.rest}>残 {props.total - currentSum} 個</div>
                    </div>
                </ModalBody>
                <ModalFooter style={styles.footer}>
                    <button className="btn btn-success"
                        disabled={currentSum !== 0 && currentSum !== props.total}
                        onClick={() => props.onSubmit(stages.filter(s => s.count > 0))}
                        >確定</button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
