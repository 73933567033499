import React from 'react';
import { SellCowAggregatePartnerListDto, SellCowAggregatePartnerDto, SellCowAggregateMotherListDto, SellCowAggregateMotherDto } from '../../api';
import { Table } from "reactstrap";
import { ICowSellType } from '../../config/constant';
import { formatYen, roundDg, CommonUtil } from '../../config/util';
import styles from './report-sellcow.module.css';
import { CowToDispInfo } from '../../components/parts/cows-popup';

interface MyProps {
    data: SellCowAggregatePartnerListDto | SellCowAggregateMotherListDto;
    sellType: ICowSellType;
    onDetailClick: (id: number | undefined, name: string) => void;
}

const AGGR_NAME_TOTAL = "合計";
const PARTNER_NAME_OTHER = "その他";

type AggregateType = "partner"|"mother";

const isMother = (data: SellCowAggregatePartnerListDto | SellCowAggregateMotherListDto): data is SellCowAggregateMotherListDto => "mothers" in data;
const isPartner = (data: SellCowAggregatePartnerListDto | SellCowAggregateMotherListDto): data is SellCowAggregatePartnerListDto => "partners" in data;

export const buildHeader = (aggrType: AggregateType) => {
    const rtn = [
        aggrType === "partner" ? "出荷先" : aggrType === "mother" ? "母牛" : "",
        "頭数",
        "平均体重 (kg)",
        "平均DG (kg/日)",
        "平均単価 (円/kg)",
        "平均重量 (kg)",
        "平均価格 (円/頭)",
        "平均出荷月齢",
        "上物率 (%)",
    ]
    return rtn;
}

export const getRowValue = (aggrType:AggregateType, data: SellCowAggregatePartnerDto | SellCowAggregateMotherDto, forCsv: boolean, isTotal: boolean) => {
    const rtn: Array<string|number> = [];
    let name: string;
    if (aggrType === "partner") {
        if (("partner_name" in data) && data.partner_name != null) {
            name = data.partner_name;
        } else {
            name = isTotal ? AGGR_NAME_TOTAL : PARTNER_NAME_OTHER;
        }
    } else if (aggrType === "mother") {
        if (("cow_id" in data) && data.cow_id != null && CommonUtil.assertNotNull(data.trace_id, "trace_id")) {
            name = CowToDispInfo({ cow_id:data.cow_id, trace_id:data.trace_id, local_no:data.local_no, name:data.name }, false);
        } else {
            name = isTotal ? AGGR_NAME_TOTAL : "";
        }
    } else {
        console.error("不明な集計種別 " + aggrType);
        name = "";
    }

    rtn.push(
        name,
        data.count_male + data.count_female,
        data.weight ?? "",
        roundDg(data.dg) ?? "",
    );
    rtn.push(
        data.unit_price == null ? "" : forCsv ? data.unit_price : formatYen(data.unit_price),
        data.carcass_amount ?? ""
    );
    rtn.push(forCsv ? data.price : formatYen(data.price));
    rtn.push(data.age ?? "");
    rtn.push(data.high_percent ?? "");
    return rtn;
}

export const ReportSellCowAggregateTable = React.memo((props: MyProps) => {
    const aggrType: AggregateType | undefined
                                = ("partners" in props.data) ? "partner"
                                  : ("mothers" in props.data) ? "mother"
                                  : undefined;
    if (aggrType == null) return <></>;

    return (
        <Table className={styles.table}>
            <thead>
                <tr>
                    { buildHeader(aggrType).map((h,i) => (
                        <th key={i}>{h}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                { isPartner(props.data) && props.data.partners.map(data => (
                    <tr key={data.partner_no ?? 0}>
                        { getRowValue("partner", data, false, false).map((v,i) => (
                            <td key={i} className={i === 0 ? styles["row-header"] : ""}>{v}{i === 0 && (
                                <span className="link m-l-5" onClick={() => props.onDetailClick(data.partner_no, data.partner_name ?? PARTNER_NAME_OTHER)}>詳細</span>
                            ) }</td>
                        )) }
                    </tr>
                ))}
                { isPartner(props.data) && props.data.partners.length > 0 && (
                    <tr className={styles["tr-total"]}>
                        { getRowValue("partner", props.data.total, false, true).map((v,i) => <td key={i} className={i === 0 ? styles["row-header"] : ""}>{v}</td> )}
                    </tr>
                )}
                { isMother(props.data)
                    && props.data.mothers
                        .map(d => ({ ...d, cowDispInfo: d.cow_id == null ? "" : CowToDispInfo({ cow_id:d.cow_id, trace_id:d.trace_id ?? "", local_no:d.local_no, name:d.name })}))
                        .map(data => (
                    <tr key={data.cow_id ?? 0}>
                        { getRowValue("mother", data, false, false).map((v,i) => (
                            <td key={i} className={i === 0 ? styles["row-header"] : ""}>{v}{i === 0 && (
                                <span className="link m-l-5" onClick={() => props.onDetailClick(data.cow_id, data.cowDispInfo)}>詳細</span>
                            ) }</td>
                        )) }
                    </tr>
                ))}
                { isMother(props.data) && props.data.mothers.length > 0 && (
                    <tr className={styles["tr-total"]}>
                        { getRowValue("mother", props.data.total, false, true).map((v,i) => <td key={i} className={i === 0 ? styles["row-header"] : ""}>{v}</td> )}
                    </tr>
                )}
            </tbody>
        </Table>

    );
});