import React from 'react';
import styles from './RegisterFeed.module.css';

export const BulkCowNoticeIcon = React.memo((props: { onClick: () => void }) => {

    return (
        <button data-testid="注意表示" className={ styles['notice-button'] } onClick={props.onClick}>
            <i className="fas fa-exclamation" />
        </button>
    );
})