import React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

import { withContext } from '../../stores';
import Base, { BaseProps } from '../../components/content/base';
import { PageSettings } from '../../config/page-settings';
import { IEventWriteLocationState } from '../../config/history-location-builder';
import { AppState } from '../../app';
import { ICowNameInfo, CowToDispInfo } from '../../components/parts/cows-popup';
import { CommonUtil, ar, formatYen } from '../../config/util';
import styles from './sellcow.module.css';
import { A, CARCASS_GRADES, LMT, TIMEPRESETS, findSellTypeName } from '../../config/constant';
import { EditingSellCowData, EditingCarcass } from './sellcow-single';
import { Checkbox } from '../../components/form/form-checkbox';
import clsx from 'classnames';
import { SellCowDetailPopup } from './sellcow-detail-popup';
import { RequiredNumInput, OptionalNumInput } from '../../components/parts/num-input';
import { SellCowModifyReq, SellCowApi, SellCowDto, SellCowSearchReq, PartnerDto, PartnerListReq, TeamApi, GrowthApi, GrowthBirthWeightDto } from '../../api';
import { V3DateTime } from '../../components/parts/v3-date-time-picker';
import { SettingPartnerPopup } from './setting-partner-popup';
import { InfoPopup } from '../../components/parts/info-popup';
import { DgViewer } from './dg-viewer';
import { CommonSelect } from '../../components/parts/common-select';
import { resetCow } from 'stores/fetcher_cow';

type SellCowDetail = SellCowDto;
type ICow = ICowNameInfo & { breed_no: number | undefined, sell_type: number | undefined };
type TCowData = {
    cow: ICow,
    original?: SellCowDetail,
    data: Readonly<EditingSellCowData>,
    carcass: Readonly<EditingCarcass>,
    birth: Omit<GrowthBirthWeightDto, "cow_id">
}

interface MyState {
    ranch_id?: number;
    hasCarcass: boolean;
    isDisuse: boolean;
    watched_at: Date;
    dataList: Readonly<Readonly<TCowData>[]>;
    editingDetail?: { cow: ICowNameInfo, carcass?: Readonly<EditingCarcass>, comment: string };
    executing: boolean;
    partners: Readonly<PartnerDto[]>;
    partner_no?: number;
    isPartnerPopupShown: boolean;
    ignoredCows: ICow[];
}

class SellCowMulti extends Base<BaseProps<{},{},IEventWriteLocationState|undefined>, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            watched_at: new Date(),
            dataList: [],
            executing: false,
            partners: [],
            isPartnerPopupShown: false,
            hasCarcass: false,
            isDisuse: false,
            ignoredCows: [],
        };

        this.onSave = this.onSave.bind(this);
    }

    componentDidMount() {
        this.context.handleSetHeader({ title:"個体販売記録を入力" });
        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        this.init();
    }

    defaultData(): EditingSellCowData {
        return { event_id:undefined, comment:"", deactivate:0, price_not_carcass:0 };
    }
    defaultCarcass(): EditingCarcass {
        return { amount: 0, defect: 0, reward:"", unit_price:0 };
    }

    async init() {
        const ranchId = this.props.rootStore.cur_ranch_id;
        if (ranchId === 0) {
            this.setState({ranch_id:undefined});
            return;
        }
        if (this.handleNotAllowAccess(ranchId, ["BALANCE_COW"], [])) {
            return;
        }

        const useList = this.props.rootStore.options.cow_use;
        const findSellType = (useNo : number | undefined) => useNo == null ? undefined : useList.find(u => u.use_no === useNo)?.sell_type;

        const allCows = (this.props.location.state?.cows ?? [])
                    .map(c => ({ ...c, sell_type: findSellType(c.use_no)}));
        const ignoredCows = allCows.filter(c => c.sell_type == null);
        const validCows = allCows.filter(c => c.sell_type != null);

        if (validCows.length === 0) {
            this.setState({
                ranch_id: ranchId,
                dataList: [],
                ignoredCows
            });
            return;
        }

        const partners = await this.loadPartners(ranchId);

        const validCowIds = validCows.map(c => c.cow_id);
        const originals = await this.loadOriginals(ranchId, validCowIds);
        if (originals == null) {
            this.setState({ ranch_id: ranchId, dataList: [] });
            this.context.showToast("データ取得に失敗しました。");
            return;
        }

        //生時体重の取得に失敗した場合も無視して続行しておく
        const resBirthes = await this.comm().send((await GrowthApi()).getBirthWeightUsingPOST({ ranch_id:ranchId, cow_ids:validCowIds }), { showsErrorMessage: false });
        const birthes = resBirthes.data ?? [];

        await this.setStateAsync({
            ranch_id: ranchId,
            dataList: validCows.map(cow => ({ cow, ori: originals.find(o => o.cow_id === cow.cow_id)}))
                        .map(d => ({
                            cow: d.cow,
                            original: d.ori,
                            data: (d.ori != null)
                                ? { ...d.ori, deactivate: 0, price_not_carcass: d.ori.price }
                                : this.defaultData(),
                            carcass: d.ori?.carcass ?? this.defaultCarcass(),
                            birth: birthes.find(b => b.cow_id === d.cow.cow_id) ?? {}
                        })),
            partners,
            //適当な1件を初期値にする（※混在している場合は登録時に警告を表示）
            partner_no: originals.find(o => o.partner_no != null)?.partner_no,
            hasCarcass: originals.some(o => o.carcass != null),
            isDisuse: originals.length > 0 && originals.every(o => o.is_disuse === 1),
            ignoredCows
        });
    }

    async loadPartners(ranch_id: number) {
        const req: PartnerListReq = {
            is_client: 1,
            is_supplier: 0,
            team_id: ranch_id
        };
        const res = await this.comm().send((await TeamApi()).listPartnersUsingPOST(req), { retries: true });
        return res.data ?? [];
    }
    async loadOriginals(ranchId: number, cowIds: number[]): Promise<SellCowDetail[] | undefined> {
        const req: SellCowSearchReq = { ranch_id: ranchId, cow_ids: cowIds };
        const res = await this.comm().send((await SellCowApi()).getSellCowUsingPOST(req), { retries: true });
        return res.data;
    }

    onDetailSubmit(carcass: EditingCarcass|undefined, comment: string) {
        const cow = this.state.editingDetail?.cow;
        if (!CommonUtil.assertNotNull(cow, "cow")) return;

        this.setState({
            editingDetail: undefined,
            dataList: this.state.dataList.map(d => d.cow.cow_id !== cow.cow_id ? d : ({
                ...d,
                carcass: carcass ?? d.carcass,
                data: { ...d.data, comment: comment }
            }))
        });
    }

    async onSave() {
        if (!CommonUtil.assertNotNull(this.state.ranch_id)) return;

        //validate
        if (this.state.hasCarcass && this.state.dataList.some(d => d.carcass.total_grade == null)) {
            this.context.showToast(A.MESSAGE.SELLCOW_GRADE_UNSELECTED);
            return;
        }
        if (this.state.dataList.some(d => d.original?.partner_no != null && this.state.partner_no !== d.original.partner_no)) {
            const confirmed = await this.context.showQuestionAsync(A.MESSAGE.SELLCOW_CHANGE_PARTNER, ["OK","キャンセル"]) === 0;
            if (!confirmed) return;
        }
        if (this.state.hasCarcass && this.state.dataList.some(d => d.cow.breed_no == null)) {
            const confirmed = await this.context.showQuestionAsync(A.MESSAGE.SELLCOW_NO_BREED_NO, ["OK","キャンセル"]) === 0;
            if (!confirmed) return;
        }

        try {
            this.setState({ executing: true });

            const req: SellCowModifyReq = {
                ranch_id: this.state.ranch_id,
                is_disuse: this.state.isDisuse ? 1 : 0,
                cows: this.state.dataList.map(d => ({
                    ...d.data,
                    event_id: d.original?.event_id,    //記録済みのデータがあるときは種別に関わらずevent_idを流用
                    price: this.state.hasCarcass ? Math.round(d.carcass.amount * d.carcass.unit_price) : d.data.price_not_carcass,
                    carcass: this.state.hasCarcass ? { ...d.carcass, total_grade: d.carcass.total_grade! } : undefined,
                    cow_id: d.cow.cow_id
                })),
                watched_at: moment(this.state.watched_at).format("YYYY-MM-DD HH:mm") + ":00",
                partner_no: this.state.partner_no,
                schedule_id: this.props.location.state?.schedule_id
            };        

            this.context.handleSetIsLoading(true);
            const res = await this.comm().send((await SellCowApi()).modifySellCowUsingPOST(req));
            this.context.handleSetIsLoading(false);
            if (res.result  !== "OK") return;

            //キャッシュリセット
            req.cows.forEach(cw => resetCow(cw.cow_id, false));
            
            this.props.history.go(-1);

        } finally {
            this.setState({ executing: false });
        }
    }


    render() {
        if (this.state.ranch_id == null || (this.state.dataList.length === 0 && this.state.ignoredCows.length === 0)) {
            return <></>;
        }

        const renderContent = () => {
            const rowClass = "form-group row treat-write-row";
            const rowHeaderClass = "col-form-label col-md-4 col-xs-3 text-lg-right";
    
            const totalPrice = ar.sum(this.state.dataList.map(d => this.state.hasCarcass ? (d.carcass.unit_price * d.carcass.amount) : d.data.price_not_carcass));

            const grouped = ar.orderBy([...CommonUtil.groupBy(this.state.dataList, d => d.cow.sell_type)], g => g[0] ?? 0)
                .map(g => ({ list:g[1], sellTypeName: findSellTypeName(g[0] ?? 0)}));

            const updateData = <K extends keyof TCowData>(cowId: number, newVal: Pick<TCowData,K>) => {
                this.setState({
                    dataList: this.state.dataList.map(d => d.cow.cow_id === cowId ? { ...d, ...newVal } : d)
                });
            }

            return (
                <div className={styles.content}>
                    <div className={rowClass}>
                        <Checkbox label="廃用/病畜" id="chkDisuse"
                            className="m-r-15"
                            checked={this.state.isDisuse}
                            onChange={e => this.setState({ isDisuse: e.target.checked })}
                        />
                        <Checkbox label="枝肉あり" id="chkCarcass"
                            className="m-l-10"
                            checked={this.state.hasCarcass}
                            onChange={e => this.setState({ hasCarcass:e.target.checked })}
                        />
                    </div>
                    <hr />
                    <div className={rowClass}>
                        <label className={rowHeaderClass}>販売日時</label>
                        <div style={{ paddingLeft:0, flex:1 }}>
                            <V3DateTime value={this.state.watched_at}
                                timePresets={this.props.rootStore.user.setting?.time_preset ?? TIMEPRESETS}
                                onChange={d => this.setState({ watched_at: d.toDate() })} />
                        </div>
                    </div>
                    <div className={rowClass}>
                        <label className={rowHeaderClass}>出荷先</label>
                        <div className={styles.partner}>
                            <CommonSelect
                                className={styles["partner-select"]}
                                value={this.state.partner_no}
                                options={this.state.partners.map(p => ({ label:p.name, value:p.partner_no }))}
                                onClear={() => this.setState({ partner_no: undefined })}
                                onSelect={val => this.setState({ partner_no:val })}
                                onCreate={undefined}
                            />
                            <div className="link" onClick={() => this.setState({ isPartnerPopupShown:true })}>編集</div>
                        </div>
                    </div>
                    { grouped.map((g,ig) => (
                        <div key={ig} className={styles["selltype-container"]}>
                            <div className={clsx(styles["selltype-label"], styles.multi)}>{g.sellTypeName}</div>
                            { g.list.map((dt,i) => 
                                this.state.hasCarcass ? (
                                    <div key={dt.cow.cow_id} className={clsx(styles["multi-cow-container"], styles.carcass)}>
                                        <div className={clsx(rowHeaderClass, styles["multi-cow"])}>{CowToDispInfo(dt.cow, false)}</div>
                                        <div className={styles["multi-values"]}>
                                            <div className={styles["multi-value-item"]}>
                                                <span className={styles.slabel}>体重</span>
                                                <OptionalNumInput
                                                    min={LMT.GROWTH.WEIGHT_MIN} max={LMT.GROWTH.WEIGHT_MAX} step={LMT.GROWTH.WEIGHT_STEP}
                                                    value={dt.data.weight}
                                                    onChange={n => updateData(dt.cow.cow_id, { data: { ...dt.data, weight: n } })}
                                                />
                                                <span>kg</span>
                                                <DgViewer
                                                    stDate={dt.birth.birthday}
                                                    stScore={dt.birth.actual ?? dt.birth.standard}
                                                    isStandardScoreUsed={dt.birth.actual == null}
                                                    edDate={this.state.watched_at}
                                                    edScore={dt.data.weight}
                                                />
                                            </div>
                                            <div className={styles["multi-value-item"]}>
                                                <span className={styles.slabel}>枝肉</span>
                                                <RequiredNumInput
                                                    min={LMT.SELLCOW.WEIGHT_MIN} max={LMT.SELLCOW.WEIGHT_MAX} step={LMT.SELLCOW.WEIGHT_STEP}
                                                    value={dt.carcass.amount}
                                                    onChange={n => updateData(dt.cow.cow_id, { carcass: { ...dt.carcass, amount: n }})}
                                                />
                                                <span>kg ×</span>
                                                <RequiredNumInput
                                                    min={LMT.SELLCOW.UNIT_PRICE_MIN} max={LMT.SELLCOW.UNIT_PRICE_MAX} step={LMT.SELLCOW.UNIT_PRICE_STEP}
                                                    value={dt.carcass.unit_price}
                                                    onChange={n => updateData(dt.cow.cow_id, { carcass: { ...dt.carcass, unit_price: n }})}
                                                />
                                                <span>円</span>
                                                <span>={formatYen(dt.carcass.unit_price * dt.carcass.amount, true) }円</span>
                                            </div>
                                            <div className={styles["multi-value-item"]}>
                                                <span className={styles.slabel}>格付</span>
                                                <select className="form-control" value={dt.carcass.total_grade}
                                                    onChange={e => updateData(dt.cow.cow_id,
                                                                    { carcass: { ...dt.carcass, total_grade: e.target.value === "" ? undefined : e.target.value }})
                                                    }>
                                                    <option value="">未選択</option>
                                                    { CARCASS_GRADES.map((c,i) => (
                                                        <option key={i} value={c}>{c}</option>
                                                    ))}
                                                </select>
                                                <Checkbox
                                                    style={{marginLeft:"10px"}}
                                                    id={"chkDeactive_" + dt.cow.cow_id}
                                                    label="飼養終了する"
                                                    onChange={e => updateData(dt.cow.cow_id, { data: { ...dt.data, deactivate: e.target.checked ? 1 :0 }}) }
                                                    checked={dt.data.deactivate === 1}
                                                />
                                                <div className="link" style={{padding:"7px 0", marginLeft:"10px"}}
                                                    onClick={()=>this.setState({editingDetail:{ cow:dt.cow, carcass:dt.carcass, comment:dt.data.comment }})}
                                                    >詳細</div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div key={dt.cow.cow_id} className={styles["multi-cow-container"]}>
                                        <div className={clsx(rowHeaderClass, styles["multi-cow"])}>{CowToDispInfo(dt.cow, false)}</div>
                                        <div className={styles["multi-values"]}>
                                            <div className={styles["multi-value-item"]}>
                                                <span className={styles.slabel}>体重</span>
                                                <OptionalNumInput
                                                    min={LMT.GROWTH.WEIGHT_MIN} max={LMT.GROWTH.WEIGHT_MAX} step={LMT.GROWTH.WEIGHT_STEP}
                                                    value={dt.data.weight}
                                                    onChange={n => updateData(dt.cow.cow_id, { data: { ...dt.data, weight: n } })}
                                                />
                                                <span>kg</span>
                                                <DgViewer
                                                    stDate={dt.birth.birthday}
                                                    stScore={dt.birth.actual ?? dt.birth.standard}
                                                    isStandardScoreUsed={dt.birth.actual == null}
                                                    edDate={this.state.watched_at}
                                                    edScore={dt.data.weight}
                                                />
                                            </div>
                                            <div className={styles["multi-value-item"]}>
                                                <span className={styles.slabel}>価格</span>
                                                <RequiredNumInput
                                                    min={LMT.SELLCOW.PRICE_MIN} max={LMT.SELLCOW.PRICE_MAX} step={LMT.SELLCOW.PRICE_STEP}
                                                    value={dt.data.price_not_carcass}
                                                    onChange={n => updateData(dt.cow.cow_id, { data: { ...dt.data, price_not_carcass: n }}) }
                                                />
                                                <span>円</span>
                                            </div>
                                            <div className={styles["multi-value-item"]}>
                                                <Checkbox
                                                    style={{marginLeft:"10px"}}
                                                    id={"chkDeactive_" + dt.cow.cow_id}
                                                    label="飼養終了する"
                                                    onChange={e => updateData(dt.cow.cow_id, { data: { ...dt.data, deactivate: e.target.checked ? 1 :0 }}) }
                                                    checked={dt.data.deactivate === 1}
                                                />
                                                <div className="link" style={{padding:"7px 0", marginLeft:"10px"}}
                                                    onClick={() => this.setState({editingDetail: { cow:dt.cow, comment: dt.data.comment }})}>
                                                    メモ
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    ))}
                    <hr />
                    <div className={rowClass}>
                        <label className={rowHeaderClass}>合計</label>
                        <label className="col-form-label">{formatYen(totalPrice, true)}円</label>
                        <label className="col-form-label" style={{marginLeft:"20px"}}>{this.state.dataList.length}頭</label>
                    </div>
        
                </div>
            )
        }

        return (
            <div style={{ height: "100%" }}>
                <div className="product product-with-footer">
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix">
                            { this.state.ignoredCows.length > 0 && this.state.dataList.length > 0 && (
                                <div className="product-info-header p-b-5">
                                    {this.state.dataList.length}頭の個体販売
                                    <InfoPopup iconType="warning" placement="bottom" message={[
                                        "以下の牛の個体販売を記録するには、",
                                        "牛の用途を設定する必要があります",
                                        ...this.state.ignoredCows.map(c => CowToDispInfo(c, true))
                                    ]} />
                                </div>
                            )}

                            {/* <!-- BEGIN content --> */}
                            { this.state.dataList.length > 0 ? renderContent() : (
                                <div>個体販売を記録するには、牛の用途を設定する必要があります</div>
                            )}
                        </div>
                    </div>

                    { this.state.dataList.length > 0 && (
                        <div className="content-footer page-footer">
                            <div className="btn-row" style={{ textAlign: "center" }}>
                                <button type="button" className="btn btn-green" onClick={this.onSave} disabled={this.state.executing}>保存</button>
                            </div>
                        </div>
                    )}
                </div>
                { this.state.editingDetail != null && (
                    <SellCowDetailPopup
                        title={CowToDispInfo(this.state.editingDetail.cow, false)}
                        carcass={this.state.editingDetail.carcass}
                        comment={this.state.editingDetail.comment}
                        onClose={()=>this.setState({editingDetail: undefined })}
                        onSubmit={(d,c) => this.onDetailSubmit(d,c)}
                    />
                )}
                { this.state.isPartnerPopupShown && (
                    <SettingPartnerPopup
                        partnerTypeName="出荷先"
                        onClose={() => this.setState({ isPartnerPopupShown: false })}
                        partners={this.state.partners}
                        comm={this.comm()}
                        onUpdated={p => this.setState({ partners: p})}
                        partnerType="client"
                        ranchId={this.state.ranch_id}
                        confirm={this.context.showQuestionAsync}
                    />
                )}
            </div>
        )
    }


}

export default withRouter(withContext(SellCowMulti));

