import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { A, LMT } from '../../config/constant';
import { PageSettings } from '../../config/page-settings.js';
import { FreezedArray, saveBase64File } from "../../config/util";
import { withContext } from '../../stores';
import moment from 'moment';
import { PreventionCertVaccinationReq, PreventionTreatListReq, PreventionApi } from '../../api';
import PrevNextDatePicker from '../../components/parts/prev-next-date-picker';
import { IEventWriteParamCow, ICowsLocationState } from '../../config/history-location-builder';
import { AppState } from '../../app';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { UserTeams } from '../../config/user-teams';
import { CowToDispInfo } from '../../components/parts/cows-popup';
import { RequiredTermSelector } from '../../components/parts/optional-term-selector';
import styles from './cert-vaccine.module.css';
import { FormInputBlock } from '../../components/form/form-input-block';
import { Checkbox } from '../../components/form/form-checkbox';
import { SelectableVaccine, CertVaccineEditPoppup } from './cert-vaccine-edit-popup';
import { InfoPopup } from '../../components/parts/info-popup';
import { updateListInUserStorage, loadUserStorage } from '../../stores/local-storage';
import { DataListInput } from '../../components/parts/data-list-input';

interface MyState {
    executing : boolean;
    searchFrom: Date;
    searchTo: Date;
    publishDay: Date;
    hasLot: boolean;
    cowDataList: FreezedArray<CowData>;
    ranchId: number;
    allCows: FreezedArray<IEventWriteParamCow>;
    editingCow?: CowData;
    ignoredCowReps: string[];
    clinicName: string;
    address: string;
    clinicNameList: string[];
    addressList: string[];
}

type CowData = {
    cow_id: number;
    repNo: string;
    dispName: string;
    vaccinations: FreezedArray<SelectableVaccine>;
}

interface MyProps extends BaseProps<{id:string},{}, ICowsLocationState|undefined> {
}

class CertVaccine extends Base<MyProps, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        const today = moment().startOf("day");

        this.state = {
            executing: false,
            searchFrom: moment(today).add("month", -2).toDate(),
            searchTo: today.toDate(),
            hasLot: true,
            publishDay: today.toDate(),
            cowDataList: [],
            ranchId: 0,
            allCows:[],
            ignoredCowReps: [],
            clinicName: "",
            address: "",
            clinicNameList:[],
            addressList:[]
        };
    }

    componentDidMount() {
        if (this.handleNotAllowAccess(undefined, ["TREAT_REF_NAME"], ["MASTER_REF"])) {
            return;
        }

        this.context.handleSetHeader({ title:"ワクチン証明書出力" });
        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        const ranchId = this.props.rootStore.cur_ranch_id;

        const allCows = this.props.location.state?.cows ?? [];
        if (allCows.length === 0) return;

        const user = this.props.rootStore.user;
        const userTeams = new UserTeams(user);
        //農家ユーザのときは牧場情報を初期値として使用
        const clinic = userTeams.findSingleClinic() ?? userTeams.findRanch(ranchId);

        const clinicNameList: string[] = loadUserStorage("cert_my_clinic_name", user.id) ?? [];
        const addressList: string[] = loadUserStorage("cert_my_clinic_address", user.id) ?? [];

        //診療所（牧場）設定がないときは最新履歴を初期値に
        let clinicName = clinic?.name ?? "";
        if (clinicName === "") {
            clinicName = clinicNameList[0] ?? "";
        }
        let address = clinic?.address ?? "";
        if (address === "") {
            address = addressList[0] ?? "";
        }

        this.setState({
            ranchId,
            clinicName,
            address,
            allCows,
            clinicNameList,
            addressList
        }, () => {
            this.search();
        });
    }

    private setLoading(loading: boolean) {
        this.context.handleSetIsLoading(loading);
        this.setState({ executing: loading });
    }

    private search = async () => {
        const req: PreventionTreatListReq = {
            ranch_id: this.state.ranchId,
            search_from: moment(this.state.searchFrom).format("YYYY-MM-DD"),
            search_to: moment(this.state.searchTo).format("YYYY-MM-DD"),
            medicine_category: A.MEDICINE_CATEGORY_VACCINE,
            cow_ids: this.state.allCows.map(c => c.cow_id)
        };

        this.setLoading(true);
        const res = await this.comm().send((await PreventionApi()).postPreventionTreatListUsingPOST(req));
        this.setLoading(false);

        if (res.result !== "OK") return;

        const resCowIdSet = new Set(res.data?.map(c => c.cow_id) ?? []);
        const ignoredCowReps = this.state.allCows.filter(c => !resCowIdSet.has(c.cow_id))
                                    .map(c => CowToDispInfo(c, false));

        this.setState({
            cowDataList: res.data?.map(data => ({
                cow_id: data.cow_id,
                repNo: CowToDispInfo(data, false),
                dispName: CowToDispInfo(data, true),
                vaccinations: data.medicines.map((m,i) => ({
                    selected: data.medicines.length - 2 <= i,
                    expire_on: m.expiring_day,
                    inoculated_on: m.watched_at.substr(0, "yyyy-mm-dd".length),
                    lot_no: m.lot_no,
                    name: m.medicine_name
                }))
            })) ?? [],
            ignoredCowReps
        });


    }

    private onEditSubmit = (cowId: number, vaccines: FreezedArray<SelectableVaccine>) => {
        this.setState({
            cowDataList: this.state.cowDataList.map(cw => cw.cow_id !== cowId ? cw : ({
                ...cw,
                vaccinations: vaccines
            })),
            editingCow: undefined
        });
    }

    private onSave = async () => {

        const req: PreventionCertVaccinationReq = {
            ranch_id: this.state.ranchId,
            clinic_address: this.state.address,
            clinic_name: this.state.clinicName,
            doctor_name: this.props.rootStore.user.setting?.legal_name ?? "",
            has_lot_no: this.state.hasLot,
            published_on: moment(this.state.publishDay).format("YYYY-MM-DD"),
            cows: this.state.cowDataList.map(c => ({ cow_id: c.cow_id, vaccinations: c.vaccinations.filter(c => c.selected) }))
                    .filter(c => c.vaccinations.length > 0)
        };
        this.setLoading(true);
        const res = await this.comm().send((await PreventionApi()).postPreventionCertVaccinationUsingPOST(req));
        this.setLoading(false);

        if (res.result !== "OK" || res.data == null) return;

        saveBase64File(res.data.body, res.data.file_name, res.data.content_type);

        const userId = this.props.rootStore.user.id;
        updateListInUserStorage("cert_my_clinic_address", userId, req.clinic_address, LMT.CERT.CLINIC_ADDRESS_HIS_COUNT);
        updateListInUserStorage("cert_my_clinic_name", userId, req.clinic_name, LMT.CERT.CLINIC_NAME_HIS_COUNT);
    }

    private buildIgnoredCowsMsg = (): string[] => {
        if (this.state.ignoredCowReps.length === 0) return [];

        const MAX = 10;
        const isOver = this.state.ignoredCowReps.length > MAX;

        const rtn = [
            "以下の牛の記録は見つかりませんでした",
            ...(isOver
                ? this.state.ignoredCowReps.slice(0, MAX)
                : this.state.ignoredCowReps
            ),
        ];
        if (isOver) {
            rtn.push(`他 ${this.state.ignoredCowReps.length - MAX}頭`);
        }
        return rtn;
    }

    render() {
        const cowsToExport = this.state.cowDataList.filter(c => c.vaccinations.some(v => v.selected)).length;
        const isOverLimit = LMT.CERT.VACCINE_MAX_COWS < cowsToExport;
        const canExport = cowsToExport !== 0 && !isOverLimit;

        const today = moment().startOf("day");
        const maxStSearchDate = today.toDate();
        const minSearchDate = moment(today).subtract(LMT.CERT.VACCINE_MAX_BACK_MONTH, "month").toDate();

        return (
            <div className="page-root width-limit">
                <div className="product product-full-height">
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix p-b-0">

                            <div className={"product-info-header " + styles.header}>
                                <RequiredTermSelector
                                    minStDate={minSearchDate}
                                    maxStDate={maxStSearchDate}
                                    minEdDate={minSearchDate}
                                    maxMonthSpan={LMT.CERT.VACCINE_MAX_MONTH_SPAN}
                                    stValue={this.state.searchFrom}
                                    edValue={this.state.searchTo}
                                    onChange={(st,ed) => this.setState({ searchFrom:st, searchTo:ed })}
                                />
                                <button className="btn btn-green" onClick={this.search}>検索</button>
                            </div>

                            <div className={"product-body " + styles.body}>
                                <div className={styles["selection-info"]}>
                                    <span className={styles["cow-count"]}>{cowsToExport} / {this.state.allCows.length}頭 選択中</span>
                                    { this.state.ignoredCowReps.length > 0 && (
                                        <InfoPopup iconType="warning" placement="bottom"
                                            message={this.buildIgnoredCowsMsg()}
                                        />
                                    )}
                                    { isOverLimit && <span className={styles["over-limit"]}>1度に{LMT.CERT.VACCINE_MAX_COWS}頭の選択が可能です</span>}
                                </div>
                                <FormInputBlock label="発行日">
                                    <PrevNextDatePicker
                                        value={this.state.publishDay}
                                        name="datePublishDay"
                                        onChange={e => this.setState({ publishDay: e.target.value })}
                                    />
                                    <Checkbox id="chkHasLot"
                                        checked={this.state.hasLot}
                                        onChange={e => this.setState({ hasLot: e.target.checked })}
                                        label="Lot No. と有効期限を出力"
                                    />
                                </FormInputBlock>
                                <FormInputBlock label="住所">
                                    <DataListInput
                                        dataList={this.state.addressList}
                                        datalistId="address-list"
                                        value={this.state.address}
                                        onChange={address => this.setState({ address })}
                                        maxLength={LMT.RANCH.ADDRESS_LEN}
                                    />
                                </FormInputBlock>
                                <FormInputBlock label="診療所名">
                                    <DataListInput
                                        dataList={this.state.clinicNameList}
                                        datalistId="clinic-name-list"
                                        value={this.state.clinicName}
                                        onChange={clinicName => this.setState({ clinicName })}
                                        maxLength={LMT.RANCH.NAME_LEN}
                                    />
                                </FormInputBlock>
                                <div className={styles.cows}>
                                    { this.state.cowDataList.map(cw => (
                                        <div className={styles.cow} key={cw.cow_id}>
                                            <div className={styles.repno}>{cw.repNo}</div>
                                            <div>
                                                { cw.vaccinations.filter(v => v.selected).map((v,i) => (
                                                    <div key={i}>{moment(v.inoculated_on).format("YYYY/M/D")} {v.name}</div>
                                                ))}
                                                { !cw.vaccinations.some(v => v.selected) && <div>（未選択）</div> }
                                            </div>
                                            <div className={styles.edit}>
                                                <i className="fas fa-pen clickable" onClick={() => this.setState({ editingCow: cw })} />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="button-page-footer">
                    <ExecutionButton type="save" onClick={this.onSave} disabled={this.state.executing || !canExport}>出力</ExecutionButton>
                </div>
                { this.state.editingCow != null && (
                    <CertVaccineEditPoppup
                        cowId={this.state.editingCow.cow_id}
                        dispName={this.state.editingCow.dispName}
                        vaccines={this.state.editingCow.vaccinations}
                        onSubmit={this.onEditSubmit}
                        onClose={() => this.setState({ editingCow:undefined })}
                    />
                )}
            </div>
        )
    }
}

export default withRouter(withContext(CertVaccine));