import React from 'react';
import { OVUM_RANKS } from '../../config/egg-kind';
import { NumericUpDown } from '../../components/parts/numeric-updown';
import { CommonUtil, ar } from '../../config/util';
import { Checkbox } from '../../components/form/form-checkbox';
import { LrCounter } from './lr-counter';
import { LMT } from '../../config/constant';

export interface OvumRankData {
    count: number;
    countL: number | undefined;
}

interface MyProps {
    total?: number;
    cowKey: number;
    countMap: Map<number, OvumRankData>;
    onChange: (countMap: Map<number, OvumRankData>) => void;
    style?: React.CSSProperties;
    className?: string;
    canLR: boolean;
}

export const OvumRankCounter = React.memo((props: MyProps) => {

    const styles: { [key:string]:React.CSSProperties } = {
        container: {
            display: "flex",
            flexFlow: "column nowrap"
        },
        item: {
            display: "flex",
            flexFlow: "row nowrap",
            marginBottom: "8px"
        },
        counter: {
            width: "90px"
        },
        label: {
            width: "74px",
            paddingTop: "8px",
            fontWeight: "bold",
            color: "#666666"
        },
        colInput: {

        },
        row: {
            display:"flex",
            alignItems:"center"
        },
        suffix: {
            marginLeft: "6px",
            color: "#666666"
        },
        stageBtn: {
            marginLeft: "12px"
        },
        validStageInfo: {
            color: "#999999"
        },
        invalidStageInfo: {
            color: "red"
        }
    }

    const onRankCountChange = (rank: number, count: number) => {
        const newMap = new Map(props.countMap);
        const oldRankData = props.countMap.get(rank);
        const oldR = oldRankData?.countL == null ? undefined : (oldRankData.count - oldRankData.countL);

        const newRankData = { count, countL: oldR == null ? undefined : Math.max(count - oldR, 0) };
        newMap.set(rank, newRankData);

        props.onChange(newMap);
    }
    const onLCountChange = (rank: number, count: number) => {

        const newMap = new Map(props.countMap);
        const oldRankData = props.countMap.get(rank);
        if (!CommonUtil.assertNotNull(oldRankData)) return;

        newMap.set(rank, { ...oldRankData, countL: count });
        props.onChange(newMap);
    }

    const onHasLrChange = (rank: number, hasLr: boolean) => {
        const newMap = new Map(props.countMap);
        const oldCount = props.countMap.get(rank)?.count ?? 0;
        const newRankData = { count: oldCount, countL: hasLr ? oldCount : undefined };
        newMap.set(rank, newRankData);

        props.onChange(newMap);
    }

    const currentSum = ar.sum([...props.countMap.values()].map(o => o.count));

    const calcMax = (currentCount: number) => {
        if (props.total == null) return LMT.OPU.MAX_EGG_COUNT;
        return props.total - (currentSum - currentCount);
    }

    return (
        <div className={props.className} style={{ ...styles.container, ...props.style }}>
            { OVUM_RANKS
                .map(rank => ({ rank, data: props.countMap.get(rank.ovum_rank) }))
                .map(item => (
                <div key={item.rank.ovum_rank} style={styles.item} data-testid={"rank__" + item.rank.name}>
                    <div style={styles.label}>{item.rank.name}</div>
                    <div style={styles.colInput}>
                        <div style={styles.row}>
                            <NumericUpDown style={styles.counter}
                                min={0} max={calcMax(item.data?.count ?? 0)}
                                value={item.data?.count ?? 0}
                                onChange={n => onRankCountChange(item.rank.ovum_rank, n)} />
                            <span style={styles.suffix}>個</span>

                            { props.canLR && (
                                <Checkbox id={`chkLr_${props.cowKey}_${item.rank.ovum_rank}`}
                                    className="m-l-10"
                                    checked={item.data?.countL != null}
                                    onChange={e => onHasLrChange(item.rank.ovum_rank, e.target.checked)}
                                    label="左右別"
                                />
                            )}

                        </div>
                        { item.data?.countL != null && (
                            <LrCounter l={item.data.countL}
                                total={item.data.count}
                                onChange={l => onLCountChange(item.rank.ovum_rank, l)}
                            />
                        )}
                    </div>
                </div>
            ))}
        </div>
    )

})