import React from 'react';
import { A } from '../../config/constant';

class PageError extends React.Component {

	constructor(props) {
		super(props);
		this.toggleMegaMenu = this.toggleMegaMenu.bind(this);
		this.state = {
			collapseMegaMenu: false,
		};
	}

	toggleMegaMenu() {
		this.setState({ collapseMegaMenu: !this.state.collapseMegaMenu });
	}

	//※現在は不正なURLからはどうせTOPにリダイレクトしてしまう。
	//  認証待ちの間に404画面がちらつかないように消しておく
	render() {
		return (
			// <div id="page-header" className="section-container page-header-container">
			// 	<div>
			// 		<a href="/"><span style={{ marginLeft: "20px", fontSize: "20px" }}>{A.MESSAGE.GO_HOME}</span></a>
			// 	</div>
			// 	<span style={{ margin: "20px", color: "red", fontSize: "30px" }}> 404 Error </span>
			// </div>
			<></>
		)
	}
}

export default PageError;