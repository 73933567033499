import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { PageSettings } from '../../config/page-settings.js';
import { withContext } from '../../stores';
import { AppState } from '../../app';
import bStyles from './egg-base-style.module.css';
import { EggRankCounter, EggRankData, isValidRankData } from './egg-rank-counter';
import { V3DateTime } from '../../components/parts/v3-date-time-picker';
import { TIMEPRESETS, LMT, EVENT_TYPE_PARAM } from '../../config/constant';
import { EggStockModal } from './egg-stock-modal';
import { IEventWriteLocationState, IEventWriteParamCow, historyLocation } from '../../config/history-location-builder';
import { EggApi, IvfEggDto, IvfEggRankDto, IvfEggModifyReqRank, IvfListDto, IvfDto, IvfEggModifyReq, IvfRankDto, IvfEggRankDetailDto } from '../../api';
import { FreezedArray, CommonUtil, ar } from '../../config/util';
import moment from 'moment';
import { RenderCowNameInfo, ICowNameInfo } from '../../components/parts/cows-popup';
import { hasRanchAuth } from '../../config/auth-checker';
import { EggStageData } from './egg-stage-count-modal';
import { UserTeams } from '../../config/user-teams';
import { getCowWithCache } from 'stores/fetcher_cow';
import { SingleCowHeader } from 'components/cow-header/single-cow-header';
import { ExecutionButton } from 'components/buttons/execution-button';
import { DIALOG_BUTTONS } from 'components/form/form-dialog';

type IvfEggInputData = {
    countMap: Map<number, EggRankData>;
    comment: string;
    ivf: IvfListDto | undefined;
}
type IvfEggToStockData = {
    event_id: number,
    ranks: FreezedArray<{ rank: number, stages: FreezedArray<EggStageData> }>,
    ivf: IvfListDto;
};

interface MyState {
    executing: boolean;
    ranchId?: number;
    isOfficialRanch: boolean;
    watchedAt: Date;
    original?: IvfEggDto;
    cow?: IEventWriteParamCow;
    ivfListOrSingle: FreezedArray<IvfListDto> | IvfDto;
    input: IvfEggInputData;

    stocking?: IvfEggToStockData;
}

const toOvumCountMap = (ivfRanks: Array<IvfRankDto | IvfEggRankDetailDto>) => {
    const map = new Map<number, number>();
    ivfRanks.forEach(r => map.set(r.ovum_rank, r.egg_count));
    return map;
}

const toCountMap = (ranks: IvfEggRankDto[]) => {
    const map = new Map<number, EggRankData>();
    ranks.forEach(r => {
        const data: EggRankData = {
            count: r.egg_count,
            stages: r.stages.map(s => ({ stage:s.egg_stage, isFrozen:s.is_frozen === 1, count:s.egg_count, hasStocked:s.seed_lot_id != null })),
            ovumCountMap: toOvumCountMap(r.details)
        };
        map.set(r.egg_rank, data);
    });
    return map;
}
const toReqRanks = (countMap: Map<number, EggRankData>):IvfEggModifyReqRank[] => {
    return [...countMap.entries()]
        .filter(([_,data]) => data.count > 0)
        .map(([n,data]) => ({
            egg_rank: n,
            egg_count: data.count,
            stages: data.stages.map(s => ({ egg_stage:s.stage, is_frozen:s.isFrozen ? 1 : 0, egg_count: s.count })),
            details: data.ovumCountMap == null ? [] : ([...data.ovumCountMap.entries()].map(([r,c]) => ({ ovum_rank:r, egg_count:c })))
        }));
}

const classColLabel = "col-form-label col-md-4 col-xs-4 text-lg-right";

export interface IIvfEggWriteLocationState extends IEventWriteLocationState {
    ivf_id?: number;
}

class IvfEggWrite extends Base<BaseProps<{ id:string },{},IIvfEggWriteLocationState|undefined>, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            watchedAt: new Date(),
            executing: false,
            ivfListOrSingle:[],
            input: { countMap: new Map(), comment: "", ivf: undefined },
            isOfficialRanch:false
        }
    }

    componentDidMount() {

        this.context.handleSetHeader({ title:"培養結果記録" });
        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        const ranchId = this.props.rootStore.cur_ranch_id;
        const cows = this.props.location.state?.cows ?? [];
        const mayEvtId = parseInt(this.props.match.params.id);
        const evtId = isNaN(mayEvtId) ? undefined : mayEvtId;
        const isOfficial = new UserTeams(this.props.rootStore.user).findOfficialRanch(ranchId) != null;

        this.setState({
            ranchId: ranchId === 0 ? undefined : ranchId,
            cow: cows.length === 0 ? undefined : cows[0],
            isOfficialRanch: isOfficial
        }, () => {
            this.loadInitial(evtId);
        });
    }

    private async loadInitial(eventId: number | undefined) {
        if (this.state.ranchId == null) return;

        this.context.handleSetIsLoading(true);

        try {
            const api = await EggApi();

            if (eventId != null) {
                const oriReq = { ranch_id: this.state.ranchId, id: eventId };
                const oriRes = await this.comm().send(api.getIvfEggUsingPOST(oriReq), { retries: true });
                const original = oriRes.data;
                if (original == null) return;

                if (this.navToRanchTopIfSelfEditOnly("IVF_EGG", original.watched_by)) return;

                const ivfRes = await this.comm().send(api.getIvfUsingPOST({ ranch_id: this.state.ranchId, id: original.ivf_id }), { retries: true });
                if (ivfRes.data == null) return;

                const cowRes = await this.comm().send(() => getCowWithCache(oriReq.ranch_id, original.cow_id), { retries: true });
                if (cowRes.data == null) return;

                this.setState({
                    original,
                    ivfListOrSingle: ivfRes.data,
                    cow: {
                        ...cowRes.data,
                        use_no:cowRes.data.use_no,
                        breed_no:cowRes.data.breed_no,
                    },
                    input: {
                        comment: original.comment,
                        countMap: toCountMap(original.ranks),
                        ivf: ivfRes.data
                    },
                    watchedAt: moment(original.watched_at).toDate(),
                })
            } else {
                if (this.state.cow == null) return;
                const cowId = this.state.cow.cow_id;

                const ivfId = this.props.location.state?.ivf_id;
                if (ivfId == null) {
                    const from = moment().add(LMT.IVF_EGG.IVF_SEARCH_DAYS * -1, "days").format("YYYY-MM-DD");
                    const listRes = await this.comm().send(api.listIvfUsingPOST({ ranch_id: this.state.ranchId, cow_id: cowId, from }), { retries: true });
                    if (listRes.result !== "OK") return;

                    //記録済みのものをぬかす
                    const ivfList = (listRes.data ?? []).filter(i => i.egg == null);
                    const ivf = ivfList.length === 0 ? undefined : ivfList[ivfList.length - 1];
                    this.setState({
                        ivfListOrSingle: ivfList,
                        input: {
                            ...this.state.input,
                            ivf: ivf
                        }
                    })
                } else {
                    const ivfRes = await this.comm().send(api.getIvfUsingPOST({ ranch_id: this.state.ranchId, id: ivfId }), { retries: true });
                    if (ivfRes.data == null) return;

                    this.setState({
                        ivfListOrSingle: ivfRes.data,
                        input: {
                            ...this.state.input,
                            ivf: ivfRes.data
                        }
                    })
                }
            }

        } finally {
            this.context.handleSetIsLoading(false);
        }

    }

    private setExecuting(executing: boolean) {
        this.context.handleSetIsLoading(executing);
        this.setState({ executing });
    }

    async onSubmit() {
        if (!CommonUtil.assertNotNull(this.state.ranchId, "ranchId")) return;
        if (!CommonUtil.assertNotNull(this.state.cow, "cow")) return;
        if (!CommonUtil.assertNotNull(this.state.input.ivf, "ivf")) return;

        const req: IvfEggModifyReq = {
            ranch_id: this.state.ranchId,
            watched_at: moment(this.state.watchedAt).format("YYYY-MM-DD HH:mm:00"),
            schedule_id: this.props.location.state?.schedule_id,
            cow_id: this.state.cow.cow_id,
            comment: this.state.input.comment,
            event_id: this.state.original?.event_id,
            ranks: toReqRanks(this.state.input.countMap),
            ivf_id: this.state.input.ivf.event_id,
        };
        this.setExecuting(true);

        try {
            const res = await this.comm().send((await EggApi()).modifyIvfEggUsingPOST(req));
            if (res.result !== "OK") return;

            if (!this.state.isOfficialRanch
                || !hasRanchAuth("MASTER_EDIT", this.state.ranchId, this.props.rootStore.user)) {
                this.navigateToNext();
                return;
            }

            const stockableRanks = [...this.state.input.countMap.entries()]
                    .map(([r,data]) => ({ rank:r, stages:data.stages.filter(s => !s.hasStocked) }))
                    .filter(r => r.stages.length > 0);
            if (stockableRanks.length === 0 || res.data == null) {
                this.navigateToNext();
                return;
            }

            this.setState({
                stocking: { 
                    event_id: res.data.id,
                    ranks: stockableRanks,
                    ivf: this.state.input.ivf
                },
            });

        } finally {
            this.setExecuting(false);
        }
    }

    async onStocked() {
        this.setState({ stocking: undefined });
        this.navigateToNext();
    }
    onSkipStock() {
        this.navigateToNext();
    }

    private navigateToNext() {
        if (!CommonUtil.assertNotNull(this.state.cow)) return;

        if (this.state.original == null) {
            this.props.history.replace(historyLocation.toCowInfo(this.state.cow.cow_id));
        } else {
            this.props.history.go(-1);
        }
    }


    async onDelete() {
        if (!CommonUtil.assertNotNull(this.state.ranchId, "ranchId", "delete")) return;
        if (!CommonUtil.assertNotNull(this.state.original, "original", "delete")) return;
        if (!CommonUtil.assertNotNull(this.state.cow, "cow", "delete")) return;
        const conf = (await this.context.showDialog("QUESTION", "記録を削除してよろしいですか？", DIALOG_BUTTONS.DELETE_CANCEL)) === 0;
        if (!conf) return;

        this.setExecuting(true);

        const req = {
            ranch_id: this.state.ranchId,
            id: this.state.original.event_id
        };
        const res = await this.comm().send((await EggApi()).deleteIvfEggUsingPOST(req));
        this.setExecuting(false);

        if (res.result !== "OK") return;

        this.props.history.replace(historyLocation.toCowEvent(
            this.state.cow.cow_id,
            moment(this.state.original.watched_at).format("YYYY-MM-DD"),
            EVENT_TYPE_PARAM.BREEDING
        ));

    }

    render() {
        if (this.state.ranchId == null || this.state.cow == null) {
            return <></>;
        }
        if (Array.isArray(this.state.ivfListOrSingle) && this.state.ivfListOrSingle.length === 0) {
            return <div>対象となる媒精記録が見つかりません</div>
        }
        //新規だが、指定されたIVFの培養結果が記録済み
        if (this.state.original == null && ("event_id" in this.state.ivfListOrSingle) && this.state.ivfListOrSingle.egg != null) {
            return <div>指定された培養の媒精結果は記録済みです</div>
        }

        const anyInvalid = !isValidInput(this.state.input);


        const stocking = this.state.stocking;

        return (
            <div className="page-root">
                <div className="product product-full-height">
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix">
                            <div className="product-info-header">
                                <SingleCowHeader ranchId={this.state.ranchId} cowId={this.state.cow.cow_id} />
                            </div>

                            <div className="product-body">
                                <div className={bStyles.row}>
                                    <label className={classColLabel}>記録日時</label>
                                    <div className={bStyles["watched-at"]}>
                                        <V3DateTime value={this.state.watchedAt}
                                            popperClassName={bStyles["datepicker-popper"]}
                                            timePresets={this.props.rootStore.user.setting?.time_preset ?? TIMEPRESETS}
                                            onChange={d => this.setState({ watchedAt: d.toDate() })} />
                                    </div>
                                </div>

                                <IvfEggInput
                                    data={this.state.input}
                                    onChange={d => this.setState({ input: d })}
                                    cow={this.state.cow}
                                    ivfListOrSingle={this.state.ivfListOrSingle}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="button-page-footer">
                    <ExecutionButton type="save" disabled={this.state.executing || anyInvalid}
                        onClick={() => this.onSubmit()}
                    />
                    { this.state.original != null && (
                        <ExecutionButton type="delete" disabled={this.state.executing}
                            onClick={() => this.onDelete()}
                        />
                    )}
                </div>
                { stocking != null && (
                    <EggStockModal
                        ranchId={this.state.ranchId}
                        comm={this.comm()}
                        setIsLoading={this.context.handleSetIsLoading}
                        showQuestion={this.context.showQuestionAsync}
                        isSov={false}
                        onSkip={() => this.onSkipStock()}
                        onClose={() => this.onSkipStock()}
                        onRegistered={() => this.onStocked()}
                        eventId={stocking.event_id}
                        father={{ ex_cow_id: stocking.ivf.father_exid, name: stocking.ivf.father_name }}
                        cow={this.state.cow}
                        ranks={stocking.ranks}
                    />
                )}
            </div>
        )
    }
}

const isValidInput = (data: IvfEggInputData) => {
    if (data.ivf == null) return false;
    const ranks = [...data.countMap.values()];
    if (ranks.some(r => !isValidRankData(r))) return false;
    if (ar.sum(ranks.map(r => r.count)) === 0 && data.comment === "") return false;
    return true;
}
const IvfEggInput = (props: {
    cow: ICowNameInfo;
    ivfListOrSingle: FreezedArray<IvfListDto> | IvfDto;
    data: IvfEggInputData;
    onChange: (data: IvfEggInputData) => void;
}) => {

    const onRankChange = (countMap: Map<number, EggRankData>) => {
        props.onChange({
            ...props.data,
            countMap
        });
    }

    const onIvfSelected = (val: string) => {
        let ivf: IvfListDto | undefined;
        if (val === "") {
            ivf = undefined;
        } else if ("event_id" in props.ivfListOrSingle) {
            if (Number(val) === props.ivfListOrSingle.event_id) {
                ivf = props.ivfListOrSingle;
            } else {
                console.error("unknown ivf selected", val, props.ivfListOrSingle);
                ivf = undefined;
            }
        } else {
            ivf = props.ivfListOrSingle.find(i => i.event_id === Number(val));
        }

        props.onChange({
            ...props.data,
            ivf
        });
    }

    const buildIvfListItem = (ivf: IvfListDto | IvfDto) => {

        return `${moment(ivf.watched_at).format("M/D")} ×${ivf.father_name}`;
    }

    return (
        <div>
            <div className={bStyles.row}>
                <label className={classColLabel}>媒精</label>
                { ("event_id" in props.ivfListOrSingle) ? (
                    <div data-testid="ivf-fixed" className="p-t-10 p-b-10">{buildIvfListItem(props.ivfListOrSingle)}</div>
                ) : (
                    <select className={"form-control " + bStyles.select}
                        onChange={e => onIvfSelected(e.target.value)}
                        value={props.data.ivf?.event_id ?? ""}>
                        { props.ivfListOrSingle.map(ivf => (
                            <option key={ivf.event_id} value={ivf.event_id}>{buildIvfListItem(ivf)}</option>
                        ))}
                    </select>
                )}
            </div>

            <div className={bStyles.row}>
                <label className={classColLabel}>ランク</label>
                <EggRankCounter className="m-l-15"
                    countMap={props.data.countMap}
                    onChange={onRankChange}
                    ovumCountMap={props.data.ivf == null ? undefined : toOvumCountMap(props.data.ivf.ranks)}
                />
            </div>
            <div className={bStyles.row}>
                <label className={classColLabel}>メモ</label>
                <div className={bStyles["column-value"]}>
                    <textarea className="form-control" rows={2}
                        maxLength={LMT.IVF_EGG.MEMO_LEN}
                        value={props.data.comment}
                        onChange={e => props.onChange({ ...props.data, comment:e.target.value })}
                    />
                </div>
            </div>
        </div>
    )
}

export default withRouter(withContext(IvfEggWrite));