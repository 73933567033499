
import React from 'react';
import ToolTip from 'rc-tooltip';

export interface InfoPopupProps {
    placement: "bottom" | "top" | "left" | "right";
    iconStyle?: React.CSSProperties;
    message: string | Readonly<string[]>;
    iconType?: "question"|"info"|"warning";
    testId?: string;
}

export class InfoPopup extends React.Component<InfoPopupProps,{}> {

    render() {
        const iconType = this.props.iconType ?? "question";
        const iconClass = iconType === "question" ? "far fa-question-circle" : "fas fa-info-circle";
        const iconColor = iconType === "warning" ? "rgb(235, 192, 52)" : "#348fe2";

        return (
            <ToolTip placement={this.props.placement} trigger={['click']} overlay={
                (<div>{Array.isArray(this.props.message) ? this.props.message.map((m,i) => <div key={i}>{m}</div>) : this.props.message}</div>)
            }>
                <i className={iconClass + " clickable"}
                    style={{ marginLeft:"3px", color:iconColor, ...this.props.iconStyle ?? {} }}
                    data-testid={this.props.testId}
                />
            </ToolTip>
        );
    }
}

