import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { PageSettings } from '../../config/page-settings';
import { withContext, ICowUse } from '../../stores';
import moment from 'moment';
import { CommonUtil, saveTextFile } from '../../config/util';
import { AppState } from '../../app';
import styles from '../report-sellcow/report-sellcow.module.css';
import { FormRadio } from '../../components/form/form-radio';
import { isBrowser } from 'react-device-detect';
import ReactToPrint from 'react-to-print';
import { YearSelector } from '../../components/parts/year-selector';
import { DynamicsAggregateDto, DynamicsAggregateReq, DynamicsApi, DynamicsAgeDto, DynamicsAgeReq } from '../../api';
import { DynamicsTable, buildHeader as buildDynamicsHeader, getRowValues as getDynamicsRowValues } from './dynamics-table';
import { InfoPopup } from '../../components/parts/info-popup';
import { AgeTable, buildAgeTableHeader, getAgeTableRowValues } from './age-table';
import { BalanceDateSelector } from '../balance/balance-date-selector';
import { AgeGraph } from './age-graph';
import { getRanchHouses } from '../../stores/fetcher';
import { FetchWaiter, FetchError } from '../../components/content/fetch-state';

type ReportDataType = DynamicsAggregateDto | DynamicsAgeDto[];
const isDynamics = (data:ReportDataType): data is DynamicsAggregateDto => "months" in data;
const isAge = (data:ReportDataType): data is DynamicsAgeDto[] => Array.isArray(data);

interface LoadedData {
    condition: Readonly<Condition>;
    data: ReportDataType;
    at: Date;
}

const loadDynamics = async (self: ReportDynamics) => {
    if (!CommonUtil.assertNotNull(self.state.ranch, "ranch", "loadDynamics")) return undefined;
    const cond = self.state.condition;
    if (cond.kind !== "DYNAMICS") {
        console.error("invalid condition for load dynamics", cond);
        return undefined;
    }

    const req: DynamicsAggregateReq = {
        ranch_id: self.state.ranch.ranch_id,
        site: cond.site,
        use_no: cond.useNo,
        from: `${cond.year}-01-01`,
        to: `${cond.year}-12-31`,
    };

    const res = await self.comm().send((await DynamicsApi()).aggregateDynamicsUsingPOST(req));
    if (res.result !== "OK") return undefined;
    return res.data;
}
const loadAge = async (self: ReportDynamics): Promise<DynamicsAgeDto[] | undefined> => {
    if (!CommonUtil.assertNotNull(self.state.ranch, "ranch", "loadAge")) return undefined;

    const cond = self.state.condition;
    if (cond.kind !== "AGE") {
        console.error("invalid condition for load age", cond);
        return undefined;
    }

    const req: DynamicsAgeReq = {
        ranch_id: self.state.ranch.ranch_id,
        site: cond.site,
        month: moment(cond.month).format("YYYY-MM-DD"),
    };

    const res = await self.comm().send((await DynamicsApi()).getAgeUsingPOST(req));
    if (res.result !== "OK") return undefined;
    return res.data;
}

const REPORT_KINDS = ["DYNAMICS","AGE"] as const;
type ReportKindKey = typeof REPORT_KINDS[number];
interface IReportKind {
    name: string;
    defaultCondition: () => Condition;
    loadData: (self: ReportDynamics) => Promise<ReportDataType | undefined>;
}
const REPORT_KIND: {[key in ReportKindKey]: IReportKind} = {
    DYNAMICS: {
        name: "動態表",
        loadData: loadDynamics,
        defaultCondition: () => {
            return {
                kind: "DYNAMICS",
                year: moment().get("year"),
                site: undefined,
                useNo: undefined
            }
        }
    },
    AGE: {
        name: "月齢分布",
        loadData: loadAge,
        defaultCondition: () => {
            return {
                kind: "AGE",
                site: undefined,
                month: moment().startOf("month").toDate(),
            }
        }
    }
}

interface ICondition {
    kind: ReportKindKey;
}
interface IDynamicsCondition extends ICondition {
    kind: "DYNAMICS",
    year: number;
    useNo: number | undefined;
    site: number | undefined;
}
interface IAgeCondition extends ICondition {
    kind: "AGE",
    month: Date;
    site: number | undefined;
}
type Condition = IDynamicsCondition | IAgeCondition;

const buildConditionText = (cond: Condition) => {
    if (cond.kind === "DYNAMICS" || cond.kind === "AGE") {
        return [];
    }
    console.error("unknown condition", cond);
    return [];
}

type ReportView = "graph"|"table";

interface MyState {
    initStatus: "ready"|"loading"|"error";
    ranch?: { ranch_id:number, name:string };
    condition: Condition;
    loadedData?: Readonly<LoadedData>;
    cowUseList: Readonly<Readonly<ICowUse>[]>;
    siteList: Readonly<Readonly<{ no: number, name: string }>[]>;
    currentView: ReportView;
}

class ReportDynamics extends Base<BaseProps, MyState> {

    private refPrintTarget = React.createRef<HTMLDivElement>();

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            initStatus:"loading",
            condition: REPORT_KIND.DYNAMICS.defaultCondition(),
            cowUseList: [],
            siteList: [],
            currentView: "table"
        };
    }

    componentDidMount() {

        this.context.handleSetHeader({ title: "動態統計" });
        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        if (this.handleNotAllowAccess(undefined, ["REPORT_RANCH"], [])) {
            return;
        }

        const ranchId = this.props.rootStore.cur_ranch_id;
        const ranchName = this.props.rootStore.getCurRanchName() ?? "";
        this.setState({
            ranch: { ranch_id: ranchId, name:ranchName }

        }, async () => {
            const sites = await getRanchHouses(ranchId, false);

            await this.setStateAsync({
                cowUseList: this.props.rootStore.getCowUses(ranchId),
                siteList: sites ?? [],
                initStatus: sites == null ? "error" : "ready"
            })

            this.loadData();
        });
    }

    async loadData() {
        const kind = REPORT_KIND[this.state.condition.kind];

        this.context.handleSetIsLoading(true);
        const at = new Date();
        const data = await kind.loadData(this);
        this.context.handleSetIsLoading(false);

        if (data == null) return;

        this.setState({
            loadedData:{
                data,
                at,
                condition: this.state.condition
            }
        });
    }

    onReportKindChanged(key: ReportKindKey) {
        this.setState({
            condition: REPORT_KIND[key].defaultCondition()
        })
    }
    onDynamicsYearChanged(year: number) {
        if (this.state.condition.kind !== "DYNAMICS") {
            console.error("invalid condition onDynamicsYearChanged", this.state.condition);
            return;
        }

        this.setState({
            condition: {
                ...this.state.condition,
                year
            }
        })
    }
    onDynamicsUseChanged(useNo: number | undefined) {
        if (this.state.condition.kind !== "DYNAMICS") {
            console.error("invalid condition onDynamicsUseChanged", this.state.condition);
            return;
        }
        this.setState({
            condition: {
                ...this.state.condition,
                useNo
            }
        })

    }
    onDynamicsSiteChanged(site: number | undefined) {
        if (this.state.condition.kind !== "DYNAMICS") {
            console.error("invalid condition onDynamicsSiteChanged", this.state.condition);
            return;
        }
        this.setState({
            condition: {
                ...this.state.condition,
                site
            }
        })
    }
    onAgeMonthChanged(month: Date) {
        if (this.state.condition.kind !== "AGE") {
            console.error("invalid condition onAgeMonthChanged", this.state.condition);
            return;
        }

        this.setState({
            condition: {
                ...this.state.condition,
                month
            }
        })
    }
    onAgeSiteChanged(site: number | undefined) {
        if (this.state.condition.kind !== "AGE") {
            console.error("invalid condition onAgeSiteChanged", this.state.condition);
            return;
        }
        this.setState({
            condition: {
                ...this.state.condition,
                site
            }
        })
    }

    onCsvClick() {
        const loaded = this.state.loadedData;
        if (!CommonUtil.assertNotNull(loaded, "loadedData", "csv")) return;

        const rows: string[] = [];
        let fileName: string;

        const cond = loaded.condition;
        const valsToRow = (vals: Array<string|number>) => vals.map(v => `"${v}"`).join(",");

        if (cond.kind === "DYNAMICS") {
            if (!isDynamics(loaded.data)) {
                console.error("invalid loaded data on dynamics csv click", loaded.data);
                return;
            }

            rows.push(valsToRow(buildDynamicsHeader(cond.year, loaded.at, true)));

            const now = moment(loaded.at);
            
            getDynamicsRowValues(cond.year, loaded.data, true)
                .map(r => valsToRow(r))
                .forEach(r => rows.push(r));
            fileName = `動態表_${cond.year}年_${now.format("YYYYMMDD")}.csv`;
        }
        else if (cond.kind === "AGE") {
            if (!isAge(loaded.data)) {
                console.error("invalid loaded data on age csv click", loaded.data);
                return;
            }
            rows.push(valsToRow(buildAgeTableHeader(loaded.data)));

            const now = moment(loaded.at);

            getAgeTableRowValues(loaded.data, this.state.cowUseList)
                .map(r => valsToRow(r))
                .forEach(r => rows.push(r));

            const month = moment(cond.month).format("YYYY年MM月");
            fileName = `月齢分布_${month}_${now.format("YYYYMMDD")}.csv`;
        }
        else {
            console.error("invalid report kind on csv click", cond, loaded.data);
            return;
        }

        saveTextFile(rows.join("\r\n"), fileName);
    }

    render() {
        if (this.state.ranch == null) {
            return <></>
        }
        if (this.state.initStatus === "loading") return <FetchWaiter />
        if (this.state.initStatus === "error") return <FetchError />

        const thisYear = moment().get("year");

        return (
            <div className="page-root">
                <div className="product" style={{ height: "100%", "minHeight": "0" }}>
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix p-b-5 w-100">

                            <div style={{ height: "100%", display:"flex", flexFlow:"column nowrap" }}>
                                <div className={styles.conditions}>
                                    <div className={styles["condition-row"]}>
                                        <select className="form-control" value={this.state.condition.kind}
                                                onChange={e => this.onReportKindChanged(e.target.value as ReportKindKey) }>
                                            { REPORT_KINDS.map(k => (
                                                <option key={k} value={k}>{REPORT_KIND[k].name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    { this.state.condition.kind === "DYNAMICS" && (<>
                                        <div className={styles["condition-row"]}>
                                            <YearSelector
                                                style={{ marginTop: "4px" }}
                                                year={this.state.condition.year}
                                                min={thisYear - 10}
                                                max={thisYear + 2}
                                                onChange={y => this.onDynamicsYearChanged(y) }
                                            />
                                        </div>
                                        <div className={styles["condition-row"] + " m-b-0"}>
                                            { this.state.cowUseList.length !== 0 && (
                                                <div className={styles["condition-block"]}>
                                                    <FormRadio isInline={false} prefix="radUse"
                                                        options={[
                                                            { value: 0, name: "全用途" },
                                                            ...this.state.cowUseList.map(u => ({ value: u.use_no, name: u.name }))
                                                        ]}
                                                        onChange={v => this.onDynamicsUseChanged(v === 0 ? undefined : v)}
                                                        value={this.state.condition.useNo ?? 0}
                                                    />
                                                </div>
                                            )}
                                            { this.state.siteList.length !== 0 && (
                                                <div className={styles["condition-block"]}>
                                                    <FormRadio isInline={false} prefix="radSite"
                                                        options={[
                                                            { value: 0, name: "全分場" },
                                                            ...this.state.siteList.map(s => ({ value: s.no, name: s.name }))
                                                        ]}
                                                        onChange={v => this.onDynamicsSiteChanged(v === 0 ? undefined : v)}
                                                        value={this.state.condition.site ?? 0}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </>)}
                                    { this.state.condition.kind === "AGE" && (<>
                                        <div className={styles["condition-row"]} style={{ paddingTop:"3px" }}>
                                            <BalanceDateSelector
                                                selectUnit="month"
                                                selectedDate={this.state.condition.month}
                                                onDateChanged={d => this.onAgeMonthChanged(d)}
                                            />
                                        </div>
                                        <div className={styles["condition-row"] + " m-b-0"}>
                                            { this.state.siteList.length !== 0 && (
                                                <div className={styles["condition-block"]}>
                                                    <FormRadio isInline={false} prefix="radSite"
                                                        options={[
                                                            { value: 0, name: "全分場" },
                                                            ...this.state.siteList.map(s => ({ value: s.no, name: s.name }))
                                                        ]}
                                                        onChange={v => this.onAgeSiteChanged(v === 0 ? undefined : v)}
                                                        value={this.state.condition.site ?? 0}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </>)}
                                </div>

                                <div className={styles["load-button-row"]}>
                                    <button className="btn btn-green" onClick={() => this.loadData()}>
                                        <i className="far fa-arrow-alt-circle-down"></i>
                                        <span> 再集計</span>
                                    </button>
                                </div>

                                { this.state.loadedData != null && (
                                    <div className={styles["loaded-header"]}>
                                        { buildConditionText(this.state.loadedData.condition).map((r,i) => <div key={i}>{r}</div>)}
                                    </div>
                                )}
                                { this.state.loadedData?.condition?.kind === "DYNAMICS"
                                    && isDynamics(this.state.loadedData.data)
                                    && (<>
                                    { this.state.loadedData.condition.year >= thisYear && (
                                        <div style={{ marginTop:"-15px", color:"#999999" }}>
                                            ※( )は予測値
                                            <InfoPopup iconType="info" message="前年度実績などから自動算出されます" placement="top" />
                                        </div>
                                    )}
                                    <div ref={this.refPrintTarget} className={styles["table-container"]}>
                                        <DynamicsTable
                                            now={this.state.loadedData.at}
                                            year={this.state.loadedData.condition.year}
                                            data={this.state.loadedData.data}
                                        />
                                    </div>
                                    { isBrowser && (
                                    <div className={styles["table-footer"]}>
                                        <button className="btn btn-orange m-r-5" onClick={() => this.onCsvClick()}>CSV出力</button>
                                        <ReactToPrint
                                            trigger={() => <button className="btn btn-orange">印刷</button>}
                                            content={() => this.refPrintTarget.current}
                                        />
                                    </div>
                                    )}
                                </>)}
                                { this.state.loadedData?.condition?.kind === "AGE"
                                    && isAge(this.state.loadedData.data)
                                    && (<>
                                    <div className={styles["switch-view"]}>
                                        <div className="radio radio-css mr-3">
                                            <input type="radio" id="rad-table" checked={this.state.currentView === "table"} onChange={() => this.setState({ currentView:"table" })} />
                                            <label htmlFor="rad-table">一覧表</label>
                                        </div>
                                        <div className="radio radio-css">
                                            <input type="radio" id="rad-graph" checked={this.state.currentView === "graph"} onChange={() => this.setState({ currentView: "graph" })} />
                                            <label htmlFor="rad-graph">グラフ</label>
                                        </div>
                                    </div>
                                    { this.state.currentView === "table" && (<>
                                        <div className={styles["table-container"]}>
                                            <AgeTable
                                                tableRef={this.refPrintTarget}
                                                data={this.state.loadedData.data}
                                                cowUses={this.state.cowUseList}
                                            />
                                        </div>
                                        { isBrowser && (
                                            <div className={styles["table-footer"]}>
                                                <button className="btn btn-orange m-r-5" onClick={() => this.onCsvClick()}>CSV出力</button>
                                                <ReactToPrint
                                                    trigger={() => <button className="btn btn-orange">印刷</button>}
                                                    content={() => this.refPrintTarget.current}
                                                />
                                            </div>
                                        )}
                                    </>)}
                                    { this.state.currentView === "graph" && (
                                        <AgeGraph
                                            data={this.state.loadedData.data}
                                            cowUses={this.state.cowUseList}
                                        />
                                    )}
                                </>)}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(withContext(ReportDynamics));