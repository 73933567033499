import React, { Fragment } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { IServiceTermBody, IServiceTermOrderedList, PrivacyPolicy, ServiceTerm } from './service-term';
import styles from './service-term-popup.module.css';

interface ServiceTermPopupProps {
    onClose: () => void;
    isAgreed: boolean;
    onSubmit: (isAgreed: boolean) => void;
}

export const ServiceTermPopup = React.memo<ServiceTermPopupProps>((props) => {

    const renderBody = (body: IServiceTermBody) => body.map((b,i) => renderBodyContent(b, i));

    const renderBodyContent = (content: string | IServiceTermOrderedList, key: number) => {
        if (typeof content === "string") {
            return <div key={key}>{content}</div>
        }

        return (
            <ol key={key} className={styles["body-list"]}>
            { content.items.map((item, li) => (
                <li key={li}>{(typeof item === "string") ? item : (<>
                    <span>{item.text}</span>
                    <ol className={styles["child-list"]}>
                        {item.childList.map((child, chi) => (
                            <li key={chi}>{child}</li>
                        ))}
                    </ol>
                </>)}</li>
            ))}
            </ol>
        )
    }

    return (
        <div className="modal-wide">
            <Modal isOpen={true} centered={true} className={styles.modal}>
                <ModalHeader toggle={props.onClose}>利用規約・プライバシーポリシー</ModalHeader>
                <ModalBody style={{ overflowY: "auto", maxHeight: "calc(100vh - 160px)",　minHeight:"200px"}}>
                    <div className={styles.title}>{ServiceTerm.title}</div>
                    <div className={styles.body}>
                        { ServiceTerm.summaries.map((s,i) => (
                            <div key={i}>{s}</div>
                        ))}
                        { ServiceTerm.chapters.map((ch,i) => (
                            <div key={i} className={styles.chapter}>
                                <div className={styles["chapter-title"]}>第{i+1}章　{ch.title}</div>
                                { ("body" in ch) && renderBody(ch.body)}
                                { ("chapters" in ch) && (<ol className={styles["subchapter-list"]}>
                                    { ch.chapters.map((sub, si) => (
                                        <li key={si}>
                                            { renderBody(sub.body) }
                                        </li>
                                    ))}
                                </ol>)}
                            </div>
                        ))}
                    </div>
                    <div className={styles.footer}>
                        { ServiceTerm.footer.map((f,fi) => (
                            <div key={fi}>{f}</div>
                        ))}
                    </div>
                    <hr className={styles.separator}/>
                    <div className={styles.title}>{PrivacyPolicy.title}</div>
                    <div className={styles.body}>
                        { PrivacyPolicy.summaries.map((s,i) => (
                            <div key={i}>{s}</div>
                        ))}
                        <ol className={styles["privacy-list"]}>
                        { PrivacyPolicy.chapters.map((ch,ci) => (
                            <li key={ci}>
                                <div>{ch.title}</div>
                                { renderBody(ch.body)}
                            </li>
                        ))}
                        </ol>
                    </div>
                    <div className={styles.footer}>
                        { PrivacyPolicy.footer.map((f,fi) => (
                            <div key={fi}>{f}</div>
                        ))}
                    </div>
                </ModalBody>
                <ModalFooter style={{display:"flex", justifyContent:"center"}}>
                    <button className="btn btn-green m-r-10" onClick={() => props.onSubmit(true)}>本規約に同意する</button>
                    <button className="btn btn-red" onClick={() => props.onSubmit(false)}>同意しない</button>
                </ModalFooter>
            </Modal>
        </div>
    )
});
