import { decorate, observable, computed } from "mobx";
import { RanchApi, TeamApi, CowApi, CowListDto, RanchTagDto, TeamRequestByUserIdDto, ComOnpVetellMybatisTeam1Dao, RanchSeedDto, RanchSeedLotDto, RanchSeedStockDto, RanchSeedListDto, RanchSeedStockCrossDto, ClinicApi, RanchFeedDao, TeamMedicineDao, CowSearchReqEvent, CowSearchReqSchedule, TeamSettingDto, DiseaseCauseDto, TeamDiseaseCauseDto, TeamTreatPresetDto, SigninReq, UserApi, VisitListDto, TeamMedicineRouteDto, ClinicVisitFeeDto } from "../api";
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { CowToDispInfo, ICowNameInfo } from "../components/parts/cows-popup";
import { AppState } from "../app";
import { hasRanchContract } from "../config/contract-checker";
import { A } from "../config/constant";
import { fetchedDateMap } from "./fetched-date-map";
import moment from "moment";
import { saveUserStorage } from "./local-storage";
import { ICowListOutput, buildDefaultOutputs, CowListOutputKey } from "../pages/ranch/select-output-popup";
import { FreezedArray } from "../config/util";
import { SortOrder } from "../pages/feedbulk/BulkCowSortIcon";
import { UserLicenseKey } from "../config/user-license";
import { IUserTeam, UserTeams } from "../config/user-teams";

export type FetchType = "ONCE"|"DIFF"|"ALL";
export type FetchResult = "OK"|"NG"|"SKIP";

export type CowForSelect = { label: string, tags: number[], value: number, traceId: string };

export interface ICowUse {
  disp_no: number;
  is_deleted: number;
  name: string;
  use_no: number;
  sell_type: number | undefined;
}
export interface ICowBreed {
  breed_no: number;
  is_deleted: number;
  disp_no: number;
  name: string;
}
export interface IDisease {
  category: number;
  disease_id: number;
  disp_no: number;
  is_deleted: number;
  name: string;
  //V4.3以降必ず入ってくるが、V4.2->V4.3入れ替えの際のlocalStore参照を考慮してoptionalにしておく
  is_epidemic?: 1 | 0;
  is_breeding?: 1 | 0;
}
export interface IFecesState {
  name: string;
  state_no: number;
  disp_no: number;
}
export interface IFecesColor {
  feces_color: string;
  name: string;
  disp_no: number;
  main_no: number;
}
export interface IFeedType {
  disp_no: number;
  feed_type_no: number;
  feeding_unit_amount: number;
  feeding_unit_amount_raw: number;
  is_deleted: number;
  name: string;
  price_unit_amount: number;
  unit: string;
  unit_raw: string;
  unit_for_price: string;
  feed_type_detail:{ feed_type_detail_no:number, name:string }[];
}
export interface ITreatKind {
  treat_kind_no: number;
  name: string;
  note_name: string | null;
  short_name: string;
  is_prevention: 0 | 1;
}
export interface IMedicineCategory {
  category: number;
  name: string;
  disp_no: number;
  is_deleted: number;
}
export interface ICondition {
  condition_id: number;
  name: string;
  disp_no: number;
  is_deleted: number;
}
export type IRanchFeed = RanchFeedDao;
export interface IRanchHouse {
  no: number;
  // ranch_id: number;
  level: number;
  parent_no: number;
  name: string;
  disp_no: number;
  data?: Readonly<Readonly<IRanchHouse>[]>;
}
export type ISeedType = RanchSeedListDto;
export type ISeed = RanchSeedDto;
export type ISeedLot = RanchSeedLotDto;
export type ISeedStock = RanchSeedStockDto;
export type ISeedStockCross = RanchSeedStockCrossDto;
export type IMedicine = TeamMedicineDao;
export type IMedicineRoute = TeamMedicineRouteDto;
export interface ITreatItem {
  team_id:number;
  treat_kind_no:number;
  treat_item_no:number;
  name:string;
  fee:number;
  point_a:number | null;
  point_b:number | null;
  code:string | null;
  fee_unit:string | null;
}
export interface ITeamCondition {
  condition_id: number;
  name: string;
  classes: IConditionClass[];
}
export interface IConditionClass {
  class_id: number;
  name: string;
  condition_id: number;
  team_id: number | null
  is_bookmarked: number;
  details: IConditionDetail[];
}
export interface IConditionDetail {
  detail_id: number;
  name: string;
}
export interface ICowSearchCondition {
  is_male?: 0 | 1;
  is_age: 0 | 1;
  age_from: number;
  age_to: number;
  breeds: number[];
  uses: number[];
  selected_house: ISelectedSite[];
  is_period: 0 | 1;
  period_start: string | undefined;
  period_end: string | undefined;
  is_active: 0 | 1;
  breeding_states: number[] | undefined;
  tags: number[] | undefined;
  start_kind: number | undefined;
  programs: number[] | undefined;
  events: CowSearchReqEvent[] | undefined;
  schedules: CowSearchReqSchedule[] | undefined;
  hasDayAge: boolean;
  day_age_from: number;
  day_age_to: number;
}
export type ISelectedSite = {
  level:number;
  no:number;
  isAllSelected:0 | 1;
  data: ISelectedBarn[];
}
export type ISelectedBarn = Omit<ISelectedSite, "data"> & {
  data: ISelectedRoom[];
}
export type ISelectedRoom = Omit<ISelectedSite, "isAllSelected"|"data">;

export interface IUser {
  id:string;
  name:string;
  requests: FreezedArray<TeamRequestByUserIdDto>;
  setting: IUserSetting | undefined;
  /**
   * ※直接参照せず、UserTeamsを利用する
   */
  teams:FreezedArray<IUserTeam>;
  licenses: number;
}

export interface IUserSetting {
  time_preset: Array<TimePreset> | undefined;
  legal_name: string | undefined;
  license: { type:UserLicenseKey, id:string } | undefined;
}

export type TimePreset = {
  time: string;
  name: string;
}

export interface IRanchScrollStatus {
  selected_cow_id:number;
  cows_length:number;
  scroll_top:number;
}

type CowListDispState = {
  filter: string;
  sortKey: CowListOutputKey;
  sortOrder: SortOrder;
}

export type CowListFilter = {
  condition: ICowSearchCondition;
  output: ICowListOutput;
}

export const defaultCowListCondition = (): ICowSearchCondition => {
  return {
    is_age: 0,
    age_from: 0,
    age_to: 0,
    breeds: [],
    uses: [],
    selected_house: [],
    is_period: 0,
    period_start: undefined,
    period_end: undefined,
    is_active: 1,
    breeding_states: undefined,
    tags: undefined,
    start_kind: undefined,
    programs: undefined,
    events: undefined,
    schedules: undefined,
    hasDayAge:false,
    day_age_from: 0,
    day_age_to: 0
  };
}

const DEFAULT_COW_LIST_FILTER: Readonly<CowListFilter> = { condition: defaultCowListCondition(), output: buildDefaultOutputs() };

class RootStore {

  createCowListDispState(): CowListDispState {
    return {
      filter: "",
      sortKey: "rep_no",
      sortOrder: "Asc"
    };
  }

  private createDefaultScrollStatus(): IRanchScrollStatus {
    return {
      selected_cow_id: 0,
      cows_length: 0,
      scroll_top: 0,
    };
  }

  navs = {
    stack: [],
    index: 0,
  };

  user: IUser = {
    id: '',
    name: '',
    licenses: 0,
    teams:[],
    requests: [],
    setting:undefined
  };

  options: {
      cow_breed: ICowBreed[],
      cow_use:ICowUse[],
      disease:IDisease[],
      feces_state:IFecesState[],
      feces_color:IFecesColor[],
      feed_type:IFeedType[],
      treat_kind:ITreatKind[],
      medicine_category:IMedicineCategory[],
      condition:ICondition[],
      causes: DiseaseCauseDto[],
    } = {
      cow_breed: [],
      cow_use: [],
      disease: [],
      feces_state: [],
      feces_color: [],
      feed_type: [],
      treat_kind: [],
      medicine_category: [],
      condition: [],
      causes: []
  }

  active_cows: Readonly<{ [ranch_id:number]: Readonly<CowListDto[]> }> = {};

  cow_tags: Readonly<{ [ranch_id:number]: Readonly<Readonly<RanchTagDto>[]> }> = {};

  settings: { [team_id:number]: TeamSettingDto } = {};

  cur_ranch_id = 0;

  cowListFilter: Readonly<CowListFilter> = DEFAULT_COW_LIST_FILTER;
  selectedCowIds: Readonly<number[]> = [];

  cowListDispState: Readonly<CowListDispState> = this.createCowListDispState();

  ranch_scroll_status = this.createDefaultScrollStatus();

  private currentCowList: FreezedArray<{ cow_id: number, disp: string }> = [];

  auth = {
    enabled : false
  }

  constructor() {
    this.loadStore = this.loadStore.bind(this);
    this.saveStore = this.saveStore.bind(this);
    this.logout = this.logout.bind(this);

    //init firebase
    const fbConfig = process.env.REACT_APP_FIREBASE_CONFIG;
    if (fbConfig == null) {
      console.error("fb config not found");

    } else {
      firebase.initializeApp(JSON.parse(fbConfig));

      const cancel = firebase.auth().onAuthStateChanged(async authUser => {
        this.auth.enabled = authUser ? true : false
      });
    }

    this.loadStore();
  }

  async fetchUserInfo(): Promise<boolean> {
    const req: SigninReq = {
      uh_os: 'W',
    };
    const api = await UserApi();
    const res = await api.signinUsingPOST(req)();
    if (res.data.meta?.errCode !== 0 || res.data.data == null) {
      console.error(res.data);
      return false;
    }

    const data = res.data.data;
    this.user = {
      id: data.user_id,
      licenses: data.licenses,
      name: data.name,
      requests: data.requests ?? [],
      setting: data.setting == null ? undefined : JSON.parse(data.setting),
      teams: data.teams.map(t => (
        {
          ...t,
          is_ranch: undefined,
          as_clinic_user: undefined,
          isRanch: t.is_ranch === 1,
          asClinicUser: t.as_clinic_user === 1,
          unofficial_ranches: (t.unofficial_ranches ?? []).map(ur => ({ ...ur, isRanch:true, asClinicUser:true }))
        }
      )),
    };

    this.saveStore();
    return true;
  }
  setUserInfo<K extends keyof IUser>(user: Pick<IUser, K>) {
    this.user = {
      ...this.user,
      ...user
    }
    this.saveStore();
  }

  getCurRanchName() {
    const id = this.cur_ranch_id;
    return new UserTeams(this.user).findTeam(id)?.name;
  }

  logout() {

    fetchedDateMap.clear();
    this.user = { id: '', name: '', teams:[], licenses: 0, requests: [], setting:undefined };
    this.settings = {};
    this.active_cows = {};
    this.cow_tags = {};
    this.cur_ranch_id = 0;
    this.selectedCowIds = [];
    this.cowListFilter = DEFAULT_COW_LIST_FILTER;
    this.options = {
      cow_breed: [],
      cow_use: [],
      disease: [],
      feces_state: [],
      feces_color: [],
      feed_type: [],
      treat_kind: [],
      medicine_category: [],
      condition: [],
      causes: []
    };
    this.cowListDispState = this.createCowListDispState();
    this.ranch_scroll_status = this.createDefaultScrollStatus();
    this.currentCowList = [];
    this.saveStore();

    firebase.auth().signOut()
			.then(() => {
				window.location.replace('/login');
			})
			.catch(err => {
				console.error(err);
				window.location.replace('/login');  //ログアウト失敗のケースもログイン画面に飛ばしておく
			});
  }

  getClinicIdForMaster() {
    const userTeams = new UserTeams(this.user);

    //現在の牧場に診療所ユーザとして紐づけられていることを確認
    const currentRanch = userTeams.findRanch(this.cur_ranch_id);
    if (currentRanch == null) return undefined;
    if (!currentRanch.asClinicUser) return undefined;

    const id = userTeams.findSingleClinic()?.team_id;
    if (id == null) return undefined;

    //診療所でマスタ参照不可の権限は無くなったのでチェック不要
    //if (!hasClinicAuth("MASTER_REF", id, this.user)) return undefined;
    return id;
  }

  async fetchActiveCows(ranch_id?: number, fetchType: FetchType = "ONCE"): Promise<FetchResult> {
    const ranchId = ranch_id ?? this.cur_ranch_id;
    if (fetchType === "ONCE" && this.active_cows[ranchId] != null) {
      return Promise.resolve("SKIP");
    }

    const now = new Date();

    const lastLoaded = fetchedDateMap.getDate(ranchId, "COW");
    const since = (fetchType === "ALL" || lastLoaded == null) ? undefined : lastLoaded;
    const api = await CowApi();
    const res = await api.getListUsingPOST({
      ranch_id: ranchId,
      page: 1,
      count: -1,
      is_active: since == null ? 1 : undefined,
      since: since == null ? undefined : moment(since).format("YYYY-MM-DD HH:mm:ss")
    })();
    if (res.data.meta?.errCode !== 0) {
      console.error(res.data);
      return "NG";
    }

    const loadedList = res.data.data?.list ?? [];
    let newList: CowListDto[];
    if (since == null) {
      newList = loadedList;
    } else {
      newList = [ ...this.active_cows[ranchId] ?? [] ];

      for (const cow of loadedList) {
        const idx = newList.findIndex(c => c.cow_id === cow.cow_id);
        if (0 <= idx) {
          newList.splice(idx, 1);
        }
        if (cow.is_deleted === 0 && cow.is_active === 1) {
          newList.push(cow);
        }
      }
      newList.sort((a,b) => (a.rep_no ?? "") < (b.rep_no ?? "") ? -1 : 1);
    }
    
    const activeCows = { ...this.active_cows };
    activeCows[ranchId] = newList;
    this.active_cows = activeCows;

    fetchedDateMap.update(ranchId, "COW", now);
    this.saveStore();
    return "OK";
  }
  
  getActiveCows(ranch_id?: number) {
    const rId = ranch_id ?? this.cur_ranch_id;
    return this.active_cows[rId];
  }

  get activeCowsForSelect(): CowForSelect[] {
    const cows = this.active_cows[this.cur_ranch_id];
    if (cows == null) return [];
    return cows.map(c => ({ label: CowToDispInfo(c), value: c.cow_id, tags: c.tags, traceId: c.trace_id }));
  }

  async fetchCowTags(ranch_id?: number, forceRefresh: boolean = false): Promise<FetchResult> {
    const rId = ranch_id ?? this.cur_ranch_id;
    if (!forceRefresh && this.cow_tags[rId] != null) {
      return Promise.resolve("SKIP");
    }
    const api = await RanchApi();
    const res = await api.getTagListUsingPOST({ ranch_id:rId })();
    if (res.data.meta?.errCode !== 0) {
      console.error(res.data);
      return "NG";
    }

    const tags = { ...this.cow_tags };
    tags[rId] = res.data.data ?? [];
    this.cow_tags = tags;
    this.saveStore();
    return "OK";
  }

  getCowTags(ranch_id?:number) {
    return this.cow_tags[ranch_id ?? this.cur_ranch_id] ?? [];
  }

  async fetchSettings(team_id?: number, forceRefresh = false): Promise<FetchResult> {
    const id = team_id ?? this.cur_ranch_id;
    if (!forceRefresh && this.settings[id] != null) return Promise.resolve("SKIP");

    const res = await (await TeamApi()).getSettingUsingPOST({ team_id: id })();

    if (res.data.meta?.errCode !== 0 || res.data.data == null) {
      console.error(res.data);
      return "NG";
    }

    this.settings[id] = res.data.data;

    this.saveStore();
    return "OK";
  }
  
  getSettings(team_id?: number) {
    const res = this.settings[team_id ?? this.cur_ranch_id];
    if (res == null) return undefined;
    return res;
  }

  //※リクエストbodyのないAPIでapi.tsが使えないので暫定対処としてcontextを受け取って使う
  async fetchOptions(context: AppState): Promise<FetchResult> {
    const response = await context.postAsync('/ranch/info', {});
    if (response.data.meta.errCode === 0) {
        this.options.cow_breed = response.data.data.cow_breed;
        this.options.cow_use = response.data.data.cow_use;
        this.options.disease = response.data.data.disease;
        this.options.feces_color = response.data.data.feces_color;
        this.options.feces_state = response.data.data.feces_state;
        this.options.feed_type = response.data.data.feed_type;
        this.options.treat_kind = response.data.data.treat_kind;
        this.options.medicine_category = response.data.data.medicine_category;
        this.options.condition = response.data.data.condition;
        this.options.causes = response.data.data.causes;
        this.saveStore();
        return "OK";
    } else {
        console.error(response.data);
        return "NG";
    }
  }

  async tryChangeRanch(ranch_id: number): Promise<boolean> {
    if (this.cur_ranch_id === ranch_id) return true;

    //所属確認
    if (new UserTeams(this.user).findRanch(ranch_id) == null) return false;

    //先にマスタ取得を実行
    try {
      const cowRes = await this.fetchActiveCows(ranch_id, "ONCE");
      if (cowRes === "NG") return false;
      const tagRes = await this.fetchCowTags(ranch_id, false);
      if (tagRes === "NG") return false;
    } catch(er) {
      console.error(er);
      return false;
    }

    this.setCurRanchId(ranch_id);
    return true;
  }

  setCurRanchId(ranch_id: number) {
    if (this.cur_ranch_id !== ranch_id) {
      this.selectedCowIds = [];
      this.cowListFilter = DEFAULT_COW_LIST_FILTER;
      this.cowListDispState = this.createCowListDispState();
      this.ranch_scroll_status = this.createDefaultScrollStatus();
      this.currentCowList = [];
      saveUserStorage("default_ranch", this.user.id, ranch_id);
    }
    this.cur_ranch_id = ranch_id;
    this.saveStore();
  }

  getSelectedCowIds(): number[] {
    return [...this.selectedCowIds];
  }
  getCowListFilter() { return this.cowListFilter; }
  setCowListFilter(filter: CowListFilter) {
    this.cowListFilter = filter;
  }
  setSelectedCowIds(ids: number[]) {
    this.selectedCowIds = ids;
  }

  setCowListDispState(state: CowListDispState) {
    this.cowListDispState = state;
  }

  setRanchScrollStatus(scroll_status?: IRanchScrollStatus) {
    if (scroll_status == null) {
      this.ranch_scroll_status = this.createDefaultScrollStatus();
    } else {
      this.ranch_scroll_status = scroll_status;
    }
  }

  getCowUses(ranch_id?:number) {
    return this.options.cow_use.filter(c => hasRanchContract("BREEDING", ranch_id ?? this.cur_ranch_id, this.user) ? true : !A.IS_FOR_BREEDING_COW(c.use_no))
  }

  setCurrentCowList(cows: FreezedArray<ICowNameInfo> | undefined) {
    this.currentCowList = (cows ?? []).map(c => ({ cow_id: c.cow_id, disp: CowToDispInfo(c, false)}));
  }
  getNextCows(cowId: number) {
    const idx = this.currentCowList.findIndex(c => c.cow_id === cowId);
    if (idx < 0) return { next: undefined, previous: undefined };
    const next = idx === this.currentCowList.length - 1 ? undefined : this.currentCowList[idx + 1];
    const previous = idx === 0 ? undefined : this.currentCowList[idx - 1];
    return { next, previous };
  }

  loadStore() {
    try {
      const s = sessionStorage.getItem('store');
      if (s == null) return;
      const store = JSON.parse(s);
      if (!store) {
        return;
      }

      //互換用処置
      this.user = {
        ...store.user,
        requests: store.user.requests ?? [],
        teams: store.user.teams ?? ((store.user.ranch_list ?? []) as ComOnpVetellMybatisTeam1Dao[]).map(r => ({
          ...r,
          isRanch: r.ranch_id > 0,
          asClinicUser: store.user.ranch_list.some(ur => ur.clinic_id > 0),
        }))
      };
      this.navs = store.navs;
      this.cur_ranch_id = store.cur_ranch_id;
      this.options = {
        ...store.options,
        causes: store.options.causes ?? []
      };
      //アプリ更新時の互換用に、以後は有無をチェックする
      if (store.active_cows) {
        this.active_cows = store.active_cows;
      }
      if (store.cow_tags) {
        this.cow_tags = store.cow_tags;
      }
      if (store.settings) {
        this.settings = store.settings;
      }

//      this.ranch_condition = store.ranch_condition;
//      this.ranch_scroll_status = store.ranch_scroll_status;
//      this.cowListDispState = store.cowListDispState;

      fetchedDateMap.loadStore();

    } catch (e) {
      console.error(e);
    }
  }

  saveStore() {
    //※入れ替えの直後、ブラウザキャッシュにより、一度V4.3になってからV4.2に戻ったケースでsessionStorageのuserにranch_listがなくてエラーになるのを回避。次期以降に消す
    const data: Omit<RootStore,"user"> & { user: IUser & { ranch_list: ComOnpVetellMybatisTeam1Dao[] } } = {
      ...this,
      user: {
        ...this.user,
        ranch_list: this.user.teams.map(t => ({ ...t, ranch_id:t.isRanch ? t.team_id : 0, clinic_id:t.isRanch ? 0 : t.team_id, team_symbol:t.team_symbol ?? "" }))
      }
    };

    try {
      sessionStorage.setItem('store', JSON.stringify(data));
    } catch(e) {
      console.error(e);
    }
  }
}

decorate(RootStore, {
  user: observable,
  auth: observable,
  cur_ranch_id: observable,
  active_cows: observable,
  activeCowsForSelect: computed,
});

export default RootStore;