import React from 'react';
import { TeamApi, ExtraCowDto, ExtraCowModifyReq, ComTeamAndIdReq, ComTeamSortReq } from '../../api';
import { Communicator } from '../../api/communicator';
import { LMT } from '../../config/constant';
import { SettingNameItemPopup } from './setting-name-item-popup';
import { resetAncestors } from '../../stores/fetcher';

interface MyProps {
    teamId: number;
    comm: Communicator;
    list: Readonly<ExtraCowDto[]>;
    /**
     * 呼び出し元でuseQueryせずに手動で種雄牛リストを取得している場合、
     * このハンドラ呼び出しをトリガに最新を再取得し、props.listに設定する必要がある
     */
    onUpdated?: () => void;
    onClose: () => void;
    confirm: (text: string, buttons:string[]) => Promise<number|undefined>;
}

export const SettingAncestorPopup: React.FC<MyProps> = (props) => {

    const reloadList = async () => {
        await resetAncestors(props.teamId, false);
        if (props.onUpdated != null) {
            props.onUpdated();
        }
    }

    const submit = async (no: number | undefined, name: string) => {

        const req: ExtraCowModifyReq = {
            ex_cow_id: no,
            name: name,
            team_id: props.teamId,
        };

        const res = await props.comm.send((await TeamApi()).modifyAncestorUsingPOST(req));
        return res.result === "OK";
    }

    const submitSort = async (order: number[]) => {
        const req: ComTeamSortReq = {
            team_id: props.teamId,
            order,
        };

        const res = await props.comm.send((await TeamApi()).sortAncestorsUsingPOST(req));
        return res.result === "OK";
    }

    const deleteItem = async (no: number) => {

        const req: ComTeamAndIdReq = {
            team_id: props.teamId,
            id: no
        };
        const res = await props.comm.send((await TeamApi()).deleteAncestorUsingPOST(req));
        return res.result === "OK";
    }

    return (
        <SettingNameItemPopup
            confirm={props.confirm}
            delete={deleteItem}
            submit={submit}
            submitSort={submitSort}
            reloadList={reloadList}
            itemType="種雄牛"
            items={props.list}
            mapName={p => p.name}
            mapNo={p => p.ex_cow_id}
            onClose={props.onClose}
            maxNameLength={LMT.COW.NAME_LEN}
        />
    )
}