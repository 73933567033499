import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { IEditingSeedLot } from './seed-management-lot-popup';
import moment from 'moment'
import { A, LMT } from '../../config/constant';
import V3DatePicker from '../../components/parts/v3-date-picker';
import { RequiredNumInput } from '../../components/parts/num-input';
import { SeedLabelRangeInput } from './seed-label-range-input';
import { EGG_TYPES, getEggRanks, EGG_STAGES } from '../../config/egg-kind';
import { ExecutionButton } from '../../components/buttons/execution-button';

interface MyProps {
    seed_type: number;
    data: IEditingSeedLot;
    onSubmit: ()=>void;
    onClose: ()=>void;
    onDelete: ()=>void;
    onLotNameEdited: (lot_name:string)=>void;
    onEggTypeEdited: (egg_type:number | undefined)=>void;
    onEggRankEdited: (egg_rank:number | undefined)=>void;
    onEggStageEdited: (egg_stage:number | undefined)=>void;
    onStockDayEdited: (stock_day:Date)=>void;
    onStockCountEdited: (stock_count:number)=>void;
    onUnitPriceEdited: (unit_price:number)=>void;
    onLabelEdited: (from: string, to: string) => void;
    onCommentEdited: (comment: string) => void;
    isSubmitExecuting: boolean;
}

export class SeedManagementLotEditPopup extends React.Component<MyProps> {

    private onEggTypeEdited = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const n = Number(e.target.value);
        this.props.onEggTypeEdited(n === 0 ? undefined : n);
    }

    private onEggRankEdited = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const n = Number(e.target.value);
        this.props.onEggRankEdited(n === 0 ? undefined : n);
    }

    private onEggStageEdited = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const n = Number(e.target.value);
        this.props.onEggStageEdited(n === 0 ? undefined : n);
    }

    render() {
        const ID = "seed-management-lot-edit-popup";

        return (
            <div>
                <Modal isOpen={true} centered={true} id={ID} scrollable={true}>
                    <ModalHeader toggle={this.props.onClose}>仕入</ModalHeader>
                    <ModalBody>
                        <div className="form-group" style={{display:"flex"}}>
                            <label className="col-form-label" style={{width:"110px", flexShrink:0}}>製造Lot No.<br/>(ケーンNo.)</label>
                            <div>
                                <input className="form-control" type="text" value={this.props.data.lot_name}
                                    maxLength={LMT.SEED.LOT_NO_LEN}
                                    onChange={(e)=>this.props.onLotNameEdited(e.target.value)}/>
                                <span style={{fontSize:"0.75rem"}}>※なければ採卵/凍結年月日</span>
                            </div>
                        </div>
                        { this.props.seed_type === A.SEED_TYPE.EGG.seed_type && (
                            <React.Fragment>
                                <div className="form-group" style={{display:"flex"}}>
                                    <label className="col-form-label" style={{width:"110px", flexShrink:0}}>受精卵種類</label>
                                    <select className="form-control" value={this.props.data.egg_type ?? 0} style={{width:"160px"}}
                                            onChange={this.onEggTypeEdited}>
                                        <option key={0} value={0}>選択</option>
                                        {
                                            EGG_TYPES.map((tp) => (
                                                <option key={tp.egg_type} value={tp.egg_type}>{tp.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="form-group" style={{display:"flex"}}>
                                    <label className="col-form-label" style={{width:"110px", flexShrink:0}}>ランク</label>
                                    <select className="form-control" value={this.props.data.egg_rank ?? 0} style={{width:"160px"}}
                                            onChange={this.onEggRankEdited}>
                                        <option key={0} value={0}>選択</option>
                                        {
                                            getEggRanks(true).map((rk) => (
                                                <option key={rk.egg_rank} value={rk.egg_rank}>{rk.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>                            
                                <div className="form-group" style={{display:"flex"}}>
                                    <label className="col-form-label" style={{width:"110px", flexShrink:0}}>ステージ</label>
                                    <select className="form-control" value={this.props.data.egg_stage ?? 0} style={{width:"160px"}}
                                            onChange={this.onEggStageEdited}>
                                        <option key={0} value={0}>選択</option>
                                        {
                                            EGG_STAGES.map((stg) => (
                                                <option key={stg.egg_stage} value={stg.egg_stage}>{stg.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>                            
                            </React.Fragment>
                        )}
                        <div className="form-group" style={{display:"flex"}}>
                            <label className="col-form-label" style={{width:"110px", flexShrink:0}}>仕入日</label>
                            <V3DatePicker name="" value={moment(this.props.data.stock_day).toDate()}
                                portalContainerID={ID}
                                onChange={(e) => this.props.onStockDayEdited(e.target.value)}
                                popperPlacement="auto" />
                        </div>
                        <div className="form-group" style={{display:"flex"}}>
                            <label className="col-form-label" style={{width:"110px"}}>仕入{A.GET_SEED_TYPE(this.props.seed_type ?? 0)?.unit}数/単価</label>
                            <div>
                                <div style={{display:"flex", alignItems:"center"}}>
                                    <RequiredNumInput
                                        max={LMT.SEED.STOCK_COUNT_MAX} min={LMT.SEED.STOCK_COUNT_MIN} step={1}
                                        style={{ width:"70px", marginRight:"2px" }}
                                        value={this.props.data.stock_count}
                                        disabled={this.props.data.isNew === false}
                                        onChange={val => this.props.onStockCountEdited(val)}
                                    />
                                    <span>{A.GET_SEED_TYPE(this.props.seed_type ?? 0)?.unit} ×</span>
                                    <RequiredNumInput
                                        min={LMT.SEED.STOCK_PRICE_MIN} max={LMT.SEED.STOCK_PRICE_MAX} step={LMT.SEED.STOCK_PRICE_STEP}
                                        style={{ width:"90px", marginLeft:"2px", marginRight:"2px" }}
                                        value={this.props.data.unit_price}
                                        onChange={val => this.props.onUnitPriceEdited(val)}
                                    />
                                    <span>円</span>
                                </div>
                                <div className="m-t-10">
                                    <span>仕入値 {this.props.data.stock_count * this.props.data.unit_price}円</span>
                                </div>
                            </div>
                        </div>
                        <div className="form-group" style={{display:"flex", alignItems: "flex-start"}}>
                            <label className="col-form-label" style={{width:"110px"}}>ラベルNo.範囲<br/><span style={{fontSize:"0.7rem"}}>({A.GET_SEED_TYPE(this.props.seed_type ?? 0)?.label_unit}別Lot No.)</span></label>
                            <SeedLabelRangeInput
                                style={{ flex: 1 }}
                                from={this.props.data.label_from}
                                to={this.props.data.label_to}
                                maxLength={LMT.SEED.STOCK_LABEL_LEN}
                                disabled={this.props.data.isNew === false}
                                onChange={(f,t) => this.props.onLabelEdited(f,t) }
                                count={this.props.data.stock_count}
                            />
                        </div>
                        <div className="form-group" style={{display:"flex"}}>
                            <label className="col-form-label" style={{width:"110px", flexShrink:0}}>メモ</label>
                            <textarea className="form-control" style={{ flex: 1 }} maxLength={LMT.SEED.LOT_MEMO_LEN}
                                value={this.props.data.comment}
                                onChange={e => this.props.onCommentEdited(e.target.value)}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter className="modal-footer-fix">
                        <ExecutionButton type="save" onClick={this.props.onSubmit} disabled={this.props.isSubmitExecuting} />
                        { this.props.data && !this.props.data.isNew && (
                            <ExecutionButton type="delete" onClick={this.props.onDelete} disabled={this.props.isSubmitExecuting} />
                        )}
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}