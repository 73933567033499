import React, { useState, useEffect } from 'react';
import { SelectConditionPopup } from './select-condition-popup';
import { ISymptomForDoctor } from './symptom-write';
import { IDisease, ITeamCondition } from '../../stores';
import { SymptomModifyReqCondition, DiseaseCauseDto, TeamDiseaseCauseDto } from '../../api';
import styles from './symptom.module.css';
import classnames from 'classnames';
import { DiseaseNameInput, DiseaseInputItem } from './disease-name-input';
import { FreezedArray } from '../../config/util';
import { DataListInput } from '../../components/parts/data-list-input';
import { LMT } from '../../config/constant';

interface MyProps {
    data: ISymptomForDoctor;
    diseases: FreezedArray<IDisease>;
    causes: FreezedArray<DiseaseCauseDto>;
    relations: FreezedArray<TeamDiseaseCauseDto>
    conditions: FreezedArray<ITeamCondition>;
    noticeList: Readonly<string[]>;
    onChange: (d: ISymptomForDoctor) => void;
    /**
     * 繁殖の場合、稟告・症状を表示しない＆繁殖関連の診断のみ表示
     */
    isBreeding?: boolean;
}

export const DiseaseInput = (props: MyProps) => {
    const [ isConditionPopupOpen, setIsConditionPopupOpen ] = useState(false);
    const [ selectedConditionNames, setSelectedConditionNames ] = useState<string[]>([]);
    const [ diseases, setDiseases ] = useState<FreezedArray<IDisease>>([]);
    const [ relations, setRelations ] = useState<FreezedArray<TeamDiseaseCauseDto>>();

    useEffect(() => {
        if (!props.isBreeding) {
            setDiseases(props.diseases);
            setRelations(props.relations);
            return;
        }

        const availableDiseases = props.diseases.filter(d => d.is_breeding === 1);
        setDiseases(availableDiseases);

        const idSet = new Set(availableDiseases.map(d => d.disease_id));
        setRelations(props.relations.filter(r => idSet.has(r.disease_id)));

    }, [ props.diseases, props.isBreeding, props.relations ]);

    useEffect(() => {
        const names = props.data.conditions.map(selected => {
            for (const cond of props.conditions) {
                const cls = cond.classes.find(c => c.class_id === selected.class_id);
                if (cls != null) {
                    const trg = cls.details.find(d => d.detail_id === selected.detail_id);
                    if (trg != null) return trg.name;
                }
            }
            return "";
        });
        setSelectedConditionNames(names);

    }, [ props.conditions, props.data.conditions ]);

    const onConditionsSelected = (c: SymptomModifyReqCondition[]) => {
        setIsConditionPopupOpen(false);
        props.onChange({
            ...props.data,
            conditions: c
        })
    }
    
    const groupRowClass = classnames(styles.group, styles.row);
    const labelClass = "col-form-label col-md-4 col-xs-4 text-lg-right p-l-10 p-r-10";
    const contentClass = "col-md-4 col-xs-7 p-l-0";

    const onDiseaseChange = (data: DiseaseInputItem | undefined, idx: number) => {
        if (data == null) {
            props.onChange({
                ...props.data,
                diseases: props.data.diseases.filter((_,i) => i !== idx)
            })
        } else {
            props.onChange({
                ...props.data,
                diseases: props.data.diseases.map((d,i) => i === idx ? data : d)
            })
        }
    }
    const onNewDiseaseSelected = (data: DiseaseInputItem | undefined) => {
        if (data == null) return;
        props.onChange({
            ...props.data,
            diseases: [ ...props.data.diseases, data ]
        })
    }

    return (<>
            <div>
                <div className={styles.row}>
                    <label className="p-l-10">診断</label>
                </div>

                { props.data.diseases
                    .map(datD => ({ ...datD, omitDiseaseIds: props.data.diseases.filter(d => d !== datD).map(d => d.disease_id)}))
                    .map((datD,i) => (
                    <DiseaseNameInput key={i}
                        causes={props.causes}
                        diseases={diseases.filter(pd => !datD.omitDiseaseIds.includes(pd.disease_id))}
                        relations={relations?.filter(r => !datD.omitDiseaseIds.includes(r.disease_id))}
                        selectedItem={datD}
                        onChange={data => onDiseaseChange(data,i)}
                    />
                ))}
                { props.data.diseases.length < LMT.SYMPTOM.DISEASE_COUNT_MAX && (
                    <DiseaseNameInput
                        causes={props.causes}
                        diseases={diseases.filter(pd => !props.data.diseases.some(pDatD => pd.disease_id === pDatD.disease_id))}
                        relations={relations?.filter(r => !props.data.diseases.some(pDatD => r.disease_id === pDatD.disease_id))}
                        selectedItem={undefined}
                        onChange={data => onNewDiseaseSelected(data)}
                    />

                )}

                { props.isBreeding !== true && (<>
                    <div className={groupRowClass}>
                        <label className={labelClass}>稟告</label>
                        <div className={contentClass}>
                            <DataListInput testId="notice"
                                datalistId="notice_list"
                                maxLength={LMT.SYMPTOM.NOTICE_LEN}
                                value={props.data.notice}
                                onChange={v => props.onChange({ ...props.data, notice: v }) }
                                dataList={props.noticeList}
                            />
                        </div>
                    </div>
                    <div className={groupRowClass}>
                        <label className={labelClass}>症状</label>
                        <div className={contentClass}>
                            <div>
                                <button type="button" className="btn btn-primary" onClick={() => setIsConditionPopupOpen(true)} data-testid="select-condition">選択</button>
                            </div>
                            { selectedConditionNames.map((n,i) => (
                                <div key={i}>
                                    <label className="col-form-label pb-0 pt-1" data-testid="selected-condition">{n}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                </>)}
            </div>
            {
                isConditionPopupOpen && (
                    <SelectConditionPopup isOpen={true}
                        data_conditions={props.conditions}
                        conditions={props.data.conditions}
                        onClose={() => setIsConditionPopupOpen(false)}
                        onConditions={onConditionsSelected} />
                )
            }
    </>)
}