import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PageSettings } from '../../config/page-settings.js';
import { IRanchHouse, ISelectedSite } from '../../stores/RootStore';
import { ExecutionButton } from 'components/buttons/execution-button';

interface MyState {
    startDate: Date;
    endDate: Date;
    ranch_houses: ISite[];
    selected_house: Readonly<ISelectedSite[]>;
}

type ISite = Omit<IRanchHouse,"data" | "disp_no"> & {
    isSelected: boolean;
    data: ISite[];
}
type IBarn = Omit<ISite, "data"> & {
    data: IRoom[];
}
type IRoom = Omit<IBarn, "data"> & {
    data: never[];
}

interface MyProps {
    selected_house: Readonly<Array<Readonly<ISelectedSite>>>;
    ranchHouse: Readonly<Array<Readonly<IRanchHouse>>>;
    onClose: ()=>void;
    onResult: (b:ISelectedSite[])=>void;
    isOpen: boolean;
}

export class SelectRanchHousePopup extends React.Component<MyProps, MyState> {

    static contextType = PageSettings;

    constructor(props: MyProps) {
        super(props);

        this.state = {
            startDate: new Date(),
            endDate: new Date(),

            selected_house: this.props.selected_house,

            ranch_houses: [],
        }

        const ranchHouses = this.props.ranchHouse;
        for (var i = 0; i < ranchHouses.length; i++) {
            var level1House:ISite = {
                no: ranchHouses[i].no,
                level: ranchHouses[i].level,
                parent_no: ranchHouses[i].parent_no,
                name: ranchHouses[i].name,
                isSelected: false,
                data: [],
            };

            const lv2Data = (ranchHouses[i].data ?? []);

            for (var j = 0; j < lv2Data.length; j++) {
                var level2House: IBarn = {
                    no: lv2Data[j].no,
                    level: lv2Data[j].level,
                    parent_no: lv2Data[j].parent_no,
                    name: lv2Data[j].name,
                    isSelected: false,
                    data: [],
                };
                const lv3Data = (lv2Data[j].data ?? [])
                for (var k = 0; k < lv3Data.length; k++) {
                    var level3House: IRoom = {
                        no: lv3Data[k].no,
                        level: lv3Data[k].level,
                        parent_no: lv3Data[k].parent_no,
                        name: lv3Data[k].name,
                        isSelected: false,
                        data: [],
                    };
                    level2House.data.push(level3House);
                }
                level2House.data.push({
                    data:[],
                    isSelected:false,
                    no:0,
                    level:3,
                    name:"部屋未設定",
                    parent_no:level2House.no
                });

                level1House.data.push(level2House);
            }
            level1House.data.push({
                data:[],
                isSelected:false,
                no:0,
                level:2,
                name:"牛舎未設定",
                parent_no:level1House.no
            });

            this.state.ranch_houses.push(level1House);
        }
        this.state.ranch_houses.push({
            data:[],
            isSelected:false,
            level:1,
            name:"分場未設定",
            no:0,
            parent_no:0
        })

        this.onClose = this.onClose.bind(this);
        this.onCloseWithResult = this.onCloseWithResult.bind(this);
    }

    componentDidMount() {
        const selectedHouses = this.state.selected_house;

        for (const site of selectedHouses) {
            const siteIdx = this.state.ranch_houses.findIndex(h => h.no === site.no);
            if (siteIdx < 0) continue;

            if (site.isAllSelected) {
                this.onHouseSelected(true, 1, siteIdx, -1, -1);
                continue;
            }

            for (const barn of site.data) {
                const barnIdx = this.state.ranch_houses[siteIdx].data.findIndex(b => b.no === barn.no);

                if (barn.isAllSelected) {
                    this.onHouseSelected(true, 2, siteIdx, barnIdx, -1);
                    continue;
                }

                for (const room of barn.data) {
                    const roomIdx = this.state.ranch_houses[siteIdx].data[barnIdx].data.findIndex(r => r.no === room.no);

                    this.onHouseSelected(true, 3, siteIdx, barnIdx, roomIdx);
                }
            }
        }
    }

    onClose() {
        this.props.onClose();
    }

    onCloseWithResult() {
        const b: ISelectedSite[] = [];
        for (var i = 0; i < this.state.ranch_houses.length; i++) {
            if (this.state.ranch_houses[i].isSelected) {
                let ranch_house0 = this.state.ranch_houses[i];
                b.push({
                    level: ranch_house0.level,
                    no: ranch_house0.no,
                    isAllSelected: 1,
                    data: []
                });
                continue;
            }
            var is_level1_added = false;

            for (var j = 0; j < this.state.ranch_houses[i].data.length; j++) {
                if (this.state.ranch_houses[i].data[j].isSelected) {
                    if (!is_level1_added) {
                        let ranch_house1 = this.state.ranch_houses[i];
                        b.push({
                            level: ranch_house1.level,
                            no: ranch_house1.no,
                            isAllSelected: 0,
                            data: []
                        });
                        is_level1_added = true;
                    }
                    let ranch_house2 = this.state.ranch_houses[i].data[j];
                    b[b.length - 1].data.push({
                        level: ranch_house2.level,
                        no: ranch_house2.no,
                        isAllSelected: 1,
                        data: []
                    });
                    continue;
                }

                var is_level2_added = false;
                for (var k = 0; k < this.state.ranch_houses[i].data[j].data.length; k++) {
                    if (this.state.ranch_houses[i].data[j].data[k].isSelected) {
                        if (!is_level1_added) {
                            var ranch_house = this.state.ranch_houses[i];
                            b.push({
                                level: ranch_house.level,
                                no: ranch_house.no,
                                isAllSelected: 0,
                                data: []
                            });
                            is_level1_added = true;
                        }
                        if (!is_level2_added) {
                            let ranch_house = this.state.ranch_houses[i].data[j];
                            b[b.length - 1].data.push({
                                level: ranch_house.level,
                                no: ranch_house.no,
                                isAllSelected: 0,
                                data: []
                            });
                            is_level2_added = true;
                        }
                        let ranch_house3 = this.state.ranch_houses[i].data[j].data[k];
                        b[b.length - 1].data[b[b.length - 1].data.length - 1].data.push({
                            level: ranch_house3.level,
                            no: ranch_house3.no,
                        });
                    }
                }
            }
        }

        console.log(b);
        this.props.onResult(b);
    }

    onHouseSelected(isSelected: boolean, level: number, level1_idx: number, level2_idx: number, level3_idx: number) {
        const houses = [ ...this.state.ranch_houses ];

        if (level === 1) {
            const site = houses[level1_idx];
            site.isSelected = isSelected;
            for (const barn of site.data) {
                barn.isSelected = isSelected;
                barn.data.forEach(r => { r.isSelected = isSelected });
            }

        } else if (level === 2) {
            const barn = houses[level1_idx].data[level2_idx];
            barn.isSelected = isSelected;
            barn.data.forEach(r => { r.isSelected = isSelected });

        } else if (level === 3) {
            houses[level1_idx].data[level2_idx].data[level3_idx].isSelected = isSelected;
        }

        if (level >= 3) {
            const parent = houses[level1_idx].data[level2_idx];
            const isAllSelected3 = parent.data.every(r => r.isSelected);
            parent.isSelected = isAllSelected3;
        }
        if (level >= 2) {
            const parent = houses[level1_idx];
            const isAllSelected2 = parent.data.every(b => b.isSelected);
            parent.isSelected = isAllSelected2;
        }

        this.setState({ ranch_houses: houses });
    }

    isLevel1HouseSelected(level1_idx: number) {
        return this.state.ranch_houses[level1_idx].isSelected;
    }

    isLevel2HouseSelected(level1_idx: number, level2_idx: number) {
        return this.state.ranch_houses[level1_idx].data[level2_idx].isSelected;
    }

    isLevel3HouseSelected(level1_idx: number, level2_idx: number, level3_idx: number) {
        return this.state.ranch_houses[level1_idx].data[level2_idx].data[level3_idx].isSelected;
    }

    render() {
        const state = this.state;
        return (
            <div>
                <Modal isOpen={this.props.isOpen} style={{ maxWidth: "500px" }} scrollable={true}>
                    <ModalHeader toggle={this.props.onClose}>場所選択</ModalHeader>
                    <ModalBody>
                        <div>
                            {
                                state.ranch_houses.map((value, i) => (
                                    <div key={value.no} className="form-group row">
                                        <div className="checkbox checkbox-css m-l-15">
                                            <input type="checkbox" id={"level1_" + i}
                                                onChange={(e) => this.onHouseSelected(e.target.checked, 1, i, -1, -1)}
                                                checked={this.isLevel1HouseSelected(i)} />
                                            <label htmlFor={"level1_" + i}>{value.name}</label>
                                        </div>
                                        <div className="m-l-15">
                                            {
                                                value.data.map((value2, j) => (
                                                    <div key={value2.no} className="form-group row">
                                                        <div className="checkbox checkbox-css m-l-15">
                                                            <input type="checkbox" id={"level2_" + i + "_" + j}
                                                                onChange={(e) => this.onHouseSelected(e.target.checked, 2, i, j, -1)}
                                                                checked={this.isLevel2HouseSelected(i, j)} />
                                                            <label htmlFor={"level2_" + i + "_" + j}>{value2.name}</label>
                                                        </div>
                                                        <div className="m-l-15">
                                                            {
                                                                value2.data.map((value3, k) => (
                                                                    <div className="row" key={value3.no}>
                                                                        <div className="checkbox checkbox-css m-l-15">
                                                                            <input type="checkbox" id={"level3_" + i + "_" + j + "_" + k}
                                                                                onChange={(e) => this.onHouseSelected(e.target.checked, 3, i, j, k)}
                                                                                checked={this.isLevel3HouseSelected(i, j, k)} />
                                                                            <label htmlFor={"level3_" + i + "_" + j + "_" + k}>{value3.name}</label>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </ModalBody>
                    <ModalFooter className="modal-footer-fix">
                        <ExecutionButton type="decide" onClick={this.onCloseWithResult} />
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}