import React, { useEffect, useState } from 'react';
import { BalanceCowChildrenDto } from '../../api';
import { ICowNameInfo, CowToDispInfo } from '../../components/parts/cows-popup';
import styles from './balance-children.module.css';
import clsx from 'classnames';
import { ar, formatYen } from '../../config/util';

interface BalanceChildrenViewerProps {
    onCowSelected: (cowId: number) => void;
    mother: ICowNameInfo;
    data: Readonly<BalanceCowChildrenDto>;
}

export const BalanceChildrenViewer: React.FC<BalanceChildrenViewerProps> = (props) => {
    const [ selectedChildIds, setSelectedChildIds ] = useState(new Set<number>());
    const [ profitSum, setProfitSum ] = useState(0);
    const [ lossSum, setLossSum ] = useState(0);

    useEffect(() => {
        setSelectedChildIds(new Set(props.data.children.map(ch => ch.cow_id)));

    }, [ props.data ]);

    useEffect(() => {
        const childs = props.data.children.filter(ch => selectedChildIds.has(ch.cow_id));
        setProfitSum(ar.sum(childs.map(ch => ch.profit)));
        setLossSum(ar.sum(childs.map(ch => ch.loss)));

    }, [ props.data, selectedChildIds ]);

    const onCheckChange = (id: number, checked: boolean) => {
        const newSet = new Set([...selectedChildIds]);
        if (checked) {
            newSet.add(id);
        } else {
            newSet.delete(id);
        }
        setSelectedChildIds(newSet);
    }

    if (props.mother.cow_id !== props.data.mother.cow_id) {
        console.error("invalid mother id", props.mother.cow_id, props.data.mother.cow_id);
        return <></>;
    }

    return (
        <div className={styles["container"]}>
            <div className={styles.row}>
                <div className={styles["row-header"]}></div>
                <div className={clsx(styles["column-val"], styles["column-header"])}>売上</div>
                <div className={clsx(styles["column-val"], styles["column-header"])}>経費</div>
                <div className={clsx(styles["column-val"], styles["column-header"])}>収支</div>
            </div>
            <div className={styles.row}>
                <div className={clsx(styles["row-header"])}>母牛{CowToDispInfo(props.mother, false)}</div>
                <div className={clsx(styles["column-val"], styles["column-profit"])}>{formatYen(props.data.mother.profit)}</div>
                <div className={clsx(styles["column-val"], styles["column-loss"])}>{formatYen(props.data.mother.loss)}</div>
                <div className={clsx(styles["column-val"], styles["column-balance"])}>{formatYen(props.data.mother.profit - props.data.mother.loss)}</div>
            </div>
            <hr />
            { props.data.children.map((ch,i) => (
                <div className={styles.row} key={ch.cow_id}>
                    <div className={clsx(styles["row-header"])}>
                        <div className={clsx("checkbox", "checkbox-css", styles["check-cow"])}>
                            <input type="checkbox" id={"chkChild_" + i} checked={selectedChildIds.has(ch.cow_id)}
                                onChange={e => onCheckChange(ch.cow_id, e.target.checked)} />
                            <label htmlFor={"chkChild_" + i} />
                        </div>
                        <span className="link" onClick={()=>props.onCowSelected(ch.cow_id)}>{CowToDispInfo(ch, false)}</span>
                    </div>
                    <div className={clsx(styles["column-val"],
                                styles["column-profit"],
                                {[styles["unselected"]]:!selectedChildIds.has(ch.cow_id)})}
                        >{formatYen(ch.profit)}</div>
                    <div className={clsx(styles["column-val"],
                                styles["column-loss"],
                                {[styles["unselected"]]:!selectedChildIds.has(ch.cow_id)})}
                        >{formatYen(ch.loss)}</div>
                    <div className={clsx(styles["column-val"],
                                styles["column-balance"],
                                {[styles["unselected"]]:!selectedChildIds.has(ch.cow_id)})}
                        >{formatYen(ch.profit - ch.loss)}</div>
                </div>
            ))}
            <div className={clsx(styles["row"], styles["row-sum"])}>
                <div className={clsx(styles["row-header"])}>子牛合計</div>
                <div className={clsx(styles["column-val"], styles["column-profit"])}>{formatYen(profitSum)}</div>
                <div className={clsx(styles["column-val"], styles["column-loss"])}>{formatYen(lossSum)}</div>
                <div className={clsx(styles["column-val"], styles["column-balance"])}>{formatYen(profitSum - lossSum)}</div>
            </div>
        </div>
    );
}