import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FormMultiLineText } from '../../components/form/form-multi-line-text';

export interface CowNotePopupProps {
    note: string | undefined;
    isOpen: boolean;
    onClose: () => void;
}

export class CowNotePopup extends React.Component<CowNotePopupProps> {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="modal-wide">
                <Modal isOpen={this.props.isOpen} toggle={this.props.onClose} style={{ maxWidth: "100%" }}>
                    {/* style={{maxWidth:"1000px", position:"absolute",  top:"50%", left:"50%", transform:"translate(-50%, -50%)"}}> */}
                    <ModalHeader toggle={this.props.onClose}>メモ</ModalHeader>
                    <ModalBody style={{ overflowY: "auto", minHeight: "400px" }}>
                        <FormMultiLineText>{this.props.note ?? ""}</FormMultiLineText>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}