import React from 'react';
import { Redirect } from 'react-router';
import CowEvent from '../pages/cow/cow-event';
import CowInfo from '../pages/cow/cow-info';
import CowWrite from '../pages/cow/cow-write';
import CowLocationWrite from '../pages/cow/cow-location-write';
import FeedWrite from '../pages/feed/feed-write';
import RanchMain from '../pages/ranch/ranch-main';
import SymptomWrite from '../pages/symptom/symptom-write';
import PreventionWrite from '../pages/prevention/prevention-write';
import Login from '../pages/user/login';
import Register from '../pages/user/register';
import SendMail from '../pages/user/send-mail';
import MenuMain from '../pages/menu/menu-main';
import ConditionMaster from '../pages/setting/condition-master';
import FeedMaster from '../pages/setting/feed-master';
import TreatMaster from '../pages/setting/treat-master';
import MedicineMaster from '../pages/setting/medicine-master';
import MedicineRouteMaster from '../pages/setting/medicine-route-master';
import SeedManagement from '../pages/setting/seed-management';
import TopPage from '../pages/top';
import FeedBulkPage from '../pages/feedbulk';
import FeedRemained from '../pages/feed/feed-remained';
import RanchCalendar from '../pages/schedule/ranch-calendar';
import DeliveryWrite from '../pages/delivery/delivery-write';
import BreedingWrite from '../pages/breeding/breeding-write';
import RutWrite from '../pages/rut/rut-write';
import RanchHouses from '../pages/ranch/ranch-houses';
import TeamSearch from '../pages/team/team-search';
import TeamAuthority from '../pages/team/team-authority';
import BalanceRanch from '../pages/balance/balance-ranch';
import BalanceCow from '../pages/balance/balance-cow';
import RanchEvent from '../pages/balance/ranch-event';
import SellCowSingle from '../pages/sellcow/sellcow-single';
import SellCowMulti from '../pages/sellcow/sellcow-multi';
import SellMilkPage from '../pages/sellmilk';
import OtherPlPage from '../pages/otherpl';
import IncompletedSchedule from '../pages/schedule/incompleted-schedule';
import RanchHouseMaster from '../pages/setting/ranch-house-master';
import ProgramManagement from '../pages/setting/program-management';
import CowProgram from '../pages/program/cow-program';
import GrowthWrite from '../pages/growth/growth-write';
import UserSetting from '../pages/user/user-setting';
import ReportSellCow from '../pages/report-sellcow/report-sellcow';
import RanchConst from '../pages/setting/ranch-const';
import Dynamics from '../pages/setting/dynamics';
import OtherSetting from '../pages/setting/other-setting';
import ReportDelivery from '../pages/report-delivery/report-delivery';
import TreatBulkPage from '../pages/treatbulk';
import ReportDynamics from '../pages/report-dynamics/report-dynamics';
import SovWrite from '../pages/egg/sov-write';
import OpuWrite from '../pages/egg/opu-write';
import IvfWrite from '../pages/egg/ivf-write';
import IvfEggWrite from '../pages/egg/ivf-egg-write';
import DiseaseMaster from '../pages/setting/disease-master';
import PresetMaster from '../pages/setting/preset-master';
import TeamInfo from '../pages/setting/team-info';
import VisitingRanchMaster from '../pages/setting/visiting-ranch-master';
import VisitFeeMaster from '../pages/setting/visit-fee-master';
import CertVaccine from '../pages/cert/cert-vaccine';
import CertDisease from '../pages/cert/cert-disease';
import CertKarte from '../pages/cert/cert-karte';
import RobotImport from '../pages/robot/robot-import';
import ReportSensor from '../pages/report-sensor/report-sensor';

export type PageRoute = {
  path: string;
  exact?: boolean;
  requireAuth: boolean;
  component: () => JSX.Element;
  title: string | undefined;
}

const routes: PageRoute[] = [
  {
    path: '/',
    title: undefined,
    exact: true,
    requireAuth : true,
    component: () => <Redirect to='/top' />
  },
  {
    path: '/login',
    title: 'ログイン',
    exact: true,
    requireAuth : false,
    component: () => <Login />,
  },
  {
    path: '/register',
    title: '新規登録',
    exact: true,
    requireAuth : false,
    component: () => <Register />,
  },
  {
    path: '/sendmail',
    title: '確認メール送信',
    exact: true,
    requireAuth : false,
    component: () => <SendMail />,
  },
  {
    path: '/team/search',
    title: '組織を探す',
    exact: true,
    requireAuth : true,
    component: () => <TeamSearch />,
  },
  {
    path: '/team/authority/:id',
    title: 'ユーザ権限の設定',
    exact: true,
    requireAuth : true,
    component: () => <TeamAuthority />,
  },
  {
    path: '/top/:ranchId?',
    title: '牧場Top',
    exact: true,
    requireAuth : true,
    component: () => <TopPage />,
  },
  {
    path: '/ranch/main',
    title: '牛一覧',
    requireAuth : true,
    component: () => <RanchMain />,
  },
  {
    path: '/cow/write',
    title: '牛を追加/編集',
    requireAuth : true,
    component: () => <CowWrite />,
  },
  {
    path: '/cow/info/:id',
    title: '牛TOP',
    requireAuth : true,
    component: () => <CowInfo />,
  },
  {
    path: '/cow/event/:id',
    title: 'イベント詳細',
    requireAuth : true,
    component: () => <CowEvent />,
  },
  {
    path: '/feed/write',
    title: 'えさ記録',
    requireAuth : true,
    component: () => <FeedWrite />,
  },
  {
    path: '/feed/bulk/:ranchId',
    title: '一括えさ登録',
    exact: true,
    requireAuth : true,
    component: () => <FeedBulkPage />,
  },
  {
    path: '/feed/remained',
    title: '食べ残しの入力',
    requireAuth: true,
    component: () => <FeedRemained />
  },
  {
    path: '/symptom/write/:id?',
    title: '体調・治療記録',
    requireAuth : true,
    component: () => <SymptomWrite />,
  },
  {
    path: '/symptom/bulk/:ranchId',
    title: '一括治療登録',
    exact: true,
    requireAuth: true,
    component: () => <TreatBulkPage />,
  },
  {
    path: '/cow/location/write',
    title: '場所の移動',
    requireAuth : true,
    component: () => <CowLocationWrite />,
  },
  {
    path: '/prevention/write/:id?',
    title: '予防記録',
    requireAuth : true,
    component: () => <PreventionWrite />,
  },
  {
    path: '/menu/:id',
    title: '設定メニュー',
    requireAuth : true,
    component: () => <MenuMain />,
  },
  {
    path: '/setting/feed/:id',
    title: 'えさ品目の設定',
    requireAuth : true,
    component: () => <FeedMaster />
  },
  {
    path: '/setting/treat/:id',
    title: '処置の設定',
    requireAuth : true,
    component: () => <TreatMaster />
  },
  {
    path: '/setting/medicine/:id',
    title: '治療薬・予防薬の設定',
    requireAuth : true,
    component: () => <MedicineMaster />
  },
  {
    path: '/setting/medicine_route/:id',
    title: '投薬経路の設定',
    requireAuth : true,
    component: () => <MedicineRouteMaster />
  },
  {
    path: '/setting/condition/:id',
    title: '症状の設定',
    requireAuth : true,
    component: () => <ConditionMaster />
  },
  {
    path: '/schedule/:id',
    title: 'カレンダー',
    exact: true,
    requireAuth : true,
    component: () => <RanchCalendar />
  },
  {
    path: '/setting/seed/:id',
    title: '精液・受精卵の管理',
    requireAuth : true,
    component: () => <SeedManagement />
  },
  {
    path: '/delivery/write/:id?',
    title: '分娩記録',
    exact: true,
    requireAuth : true,
    component: () => <DeliveryWrite />,
  },
  {
    path: '/breeding/write/:id?',
    title: '検診・交配',
    exact: true,
    requireAuth: true,
    component: () => <BreedingWrite />
  },
  {
    path: '/rut/write/:id?',
    title: '発情観察記録',
    exact: true,
    requireAuth : true,
    component: () => <RutWrite />,
  },
  {
    path: '/ranch/houses',
    title: '牛舎・部屋を選択',
    exact: true,
    requireAuth : true,
    component: () => <RanchHouses />,
  },
  {
    path: '/balance/ranch',
    title: '牧場収支',
    exact: true,
    requireAuth : true,
    component: () => <BalanceRanch />,
  },
  {
    path: '/balance/cow',
    title: '収支',
    exact: true,
    requireAuth : true,
    component: () => <BalanceCow />,
  },
  {
    path: '/ranch/event',
    title: '牧場イベント履歴',
    exact: true,
    requireAuth: true,
    component: () => <RanchEvent />
  },
  {
    path: '/sellcow/single/:id?',
    title: '個体販売',
    exact: true,
    requireAuth : true,
    component: () => <SellCowSingle />,
  },
  {
    path: '/sellcow/multi',
    title: '個体販売',
    exact: true,
    requireAuth : true,
    component: () => <SellCowMulti />,
  },
  {
    path: '/sell/milk/ranch/:id?',
    title: '乳代売上',
    exact: true,
    requireAuth : true,
    component: () => <SellMilkPage />,
  },
  {
    path: '/sell/milk/cow/:id?',
    title: '乳代売上',
    exact: true,
    requireAuth : true,
    component: () => <SellMilkPage />,
  },
  {
    path: '/balance/other/ranch/:id?',
    title: '雑収入・雑損失',
    exact: true,
    requireAuth : true,
    component: () => <OtherPlPage />,
  },
  {
    path: '/balance/other/cow/:id?',
    title: '雑収入・雑損失',
    exact: true,
    requireAuth : true,
    component: () => <OtherPlPage />,
  },
  {
    path: '/schedule/incompleted/:id',
    title: '未完了予定一覧',
    exact: true,
    requireAuth : true,
    component: () => <IncompletedSchedule />,
  },
  {
    path: '/setting/ranch/house/:id',
    title: '場所の設定',
    exact: true,
    requireAuth : true,
    component: () => <RanchHouseMaster />,
  },
  {
    path: '/setting/program/:id',
    title: 'プログラムの管理',
    exact: true,
    requireAuth : true,
    component: () => <ProgramManagement />,
  },
  {
    path: '/cow/program',
    title: 'プログラム設定',
    exact: true,
    requireAuth: true,
    component: () => <CowProgram />
  },
  {
    path: '/growth/write/:id?',
    title: '身体測定値を入力',
    exact: true,
    requireAuth : true,
    component: () => <GrowthWrite />,
  },
  {
    path: '/user/setting',
    title: 'ユーザ設定',
    exact: true,
    requireAuth: true,
    component: () => <UserSetting />
  },
  {
    path: '/report/sellcow',
    title: '出荷統計',
    exact: true,
    requireAuth: true,
    component: () => <ReportSellCow />
  },
  {
    path: '/report/delivery',
    title: '繁殖統計',
    exact: true,
    requireAuth: true,
    component: () => <ReportDelivery />
  },
  {
    path: '/setting/ranch/const/:id',
    title: '牧場計画値の設定',
    exact: true,
    requireAuth: true,
    component: () => <RanchConst />
  },
  {
    path: '/setting/dynamics/:id',
    title: '動態目標の設定',
    exact: true,
    requireAuth: true,
    component: () => <Dynamics />
  },
  {
    path: '/setting/ranch/setting/:id',
    title: 'その他設定',
    exact: true,
    requireAuth: true,
    component: () => <OtherSetting />
  },
  {
    path: '/report/dynamics',
    title: '動態統計',
    exact: true,
    requireAuth: true,
    component: () => <ReportDynamics />
  },
  {
    path: '/egg/sov-write/:id?',
    title: '体内受精卵記録',
    exact: true,
    requireAuth: true,
    component: () => <SovWrite />
  },
  {
    path: '/egg/opu-write/:id?',
    title: 'OPU記録',
    exact: true,
    requireAuth: true,
    component: () => <OpuWrite />
  },
  {
    path: '/egg/ivf-write/:id?',
    title: '媒精記録',
    exact: true,
    requireAuth: true,
    component: () => <IvfWrite />
  },
  {
    path: '/egg/ivf-egg-write/:id?',
    title: '培養結果記録',
    exact: true,
    requireAuth: true,
    component: () => <IvfEggWrite />
  },
  {
    path: '/setting/disease/:id',
    title: '診断項目の管理',
    exact: true,
    requireAuth: true,
    component: () => <DiseaseMaster />
  },
  {
    path: '/setting/preset/:id',
    title: '処置プリセットの管理',
    exact: true,
    requireAuth: true,
    component: () => <PresetMaster />
  },
  {
    path: '/team/info/:id',
    title: '基本情報設定',
    exact: true,
    requireAuth: true,
    component: () => <TeamInfo />
  },
  {
    path: '/setting/visiting/ranch/:id',
    title: '往診先リスト管理',
    exact: true,
    requireAuth: true,
    component: () => <VisitingRanchMaster />
  },
  {
    path: '/setting/visit/fee/:id',
    title: '診療固定費設定',
    exact: true,
    requireAuth: true,
    component: () => <VisitFeeMaster />
  },
  {
    path: '/cert/vaccine',
    title: 'ワクチン証明書出力',
    exact: true,
    requireAuth: true,
    component: () => <CertVaccine />
  },
  {
    path: '/cert/disease',
    title: '事故発生通知出力',
    exact: true,
    requireAuth: true,
    component: () => <CertDisease />
  },
  {
    path: '/cert/karte',
    title: 'カルテ出力',
    exact: true,
    requireAuth: true,
    component: () => <CertKarte />
  },
  {
    path: '/robot/import',
    title: '搾乳データ取込',
    exact: true,
    requireAuth: true,
    component: () => <RobotImport />
  },
  {
    path: '/report/sensor',
    title: 'チップ計測値表示',
    exact: true,
    requireAuth: true,
    component: () => <ReportSensor />
  }
];

export default routes;