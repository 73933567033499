import { ar } from "../../config/util";

export const TRACE_ID_LEN = 10;

export const checkTraceId = (traceId: string) => {
    traceId = traceId.trim();
    //※9桁の場合も先頭ゼロにしないとダメ
    if (traceId.length !== TRACE_ID_LEN) return false;

    const num = Number(traceId);
    if (isNaN(num) || num <= 0) return false;

    //check digit
    const digits = ar.intRange(0, 10).map(n => Math.pow(10, n)).map(n => ((num % (n * 10)) - (num % n)) / n);
    const evens = [2,4,6,8].map(i => digits[i]);
    const odds = [1,3,5,7,9].map(i => digits[i]);
    const sum = ar.sum(evens) + (ar.sum(odds) * 3);
    const mod = sum % 10;
    const digit = mod === 0 ? 0 : (10 - mod);
    return digits[0] === digit;
}