import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PageSettings } from '../../config/page-settings.js';
import RootStore from '../../stores/RootStore';
import { ISeed, ISeedLot } from '../../stores/RootStore';
import styles from './setting.module.css';
import moment from 'moment'
import { SeedManagementLotEditPopup } from './seed-management-lot-edit-popup';
import { SeedManagementStockPopup } from './seed-management-stock-popup';
import { A, LMT, TeamType, isUnusedStock } from '../../config/constant';
import { AppState } from '../../app';
import { SeedLotDeleteReq, RanchTagDto } from '../../api/api';
import { RanchApi, ClinicApi } from '../../api/index';
import { Communicator } from '../../api/communicator';
import { getEggRankName, getEggStageName, getEggTypeName } from '../../config/egg-kind';
import { FreezedArray } from '../../config/util';
import { DIALOG_BUTTONS } from '../../components/form/form-dialog';
import { resetSeeds, getSeeds } from '../../stores/fetcher';

interface MyProps {
    rootStore: RootStore;
    teamType: TeamType;
    team_id: number;
    seed_type: number;
    data: ISeed;
    onClose: ()=>void;
    refreshSeedList: (seed_type:number, seed_id:number, lots: ISeedLot[])=>void;
    onLink: (ranchId: number|undefined, location:{pathname:string,search?:string,state?:any})=>void;
    comm: Communicator;
    allTags: FreezedArray<RanchTagDto>;
}
interface MyState {
    seedLotList: ISeedLot[];
    editingData: IEditingSeedLot | null;
    selectedSeedLot: ISeedLot | null;
    executing: boolean;
}

export type IEditingSeedLot = Omit<ISeedLot, "stocks"> & {
    readonly isNew: boolean;
    readonly team_id: number;
    readonly seed_type: number;
    readonly seed_id: number;
}

export class SeedManagementLotPopup extends React.Component<MyProps,MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            seedLotList: [],
            editingData: null,
            selectedSeedLot: null,
            executing: false
        }

        this.addItem = this.addItem.bind(this);
        this.startEdit = this.startEdit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onCancelEdit = this.onCancelEdit.bind(this);
        this.onSubmitEdit = this.onSubmitEdit.bind(this);
        this.onLotNameEdited = this.onLotNameEdited.bind(this);
        this.onEggTypeEdited = this.onEggTypeEdited.bind(this);
        this.onEggRankEdited = this.onEggRankEdited.bind(this);
        this.onEggStageEdited = this.onEggStageEdited.bind(this);
        this.onStockDayEdited = this.onStockDayEdited.bind(this);
        this.onStockCountEdited = this.onStockCountEdited.bind(this);
        this.onUnitPriceEdited = this.onUnitPriceEdited.bind(this);
        this.onLabelEdited = this.onLabelEdited.bind(this);
        this.onCommentEdited = this.onCommentEdited.bind(this);
        // 在庫
        this.onShowSeedStock = this.onShowSeedStock.bind(this);
    }

    componentDidMount() {
        this.setState({
            seedLotList: this.props.data?.lots ?? [],
        });
    }

    private getTitle() {
        const data = this.props.data;
        if( this.props.seed_type === A.SEED_TYPE.SEED.seed_type) {
            return `${data.name}(${data.sname})`;
        }
        if( this.props.seed_type === A.SEED_TYPE.EGG.seed_type) {

            let ancestor = data.ancestor_2 ?? ""; 
            ancestor += (data.ancestor_3 ?? "") !== "" ? `×${data.ancestor_3}` : "";
            ancestor += (data.ancestor_4 ?? "") !== "" ? `×${data.ancestor_4}` : "";
    
            if (data.name !== "") {
                if (ancestor !== "") {
                    return `${data.ancestor_1}×${data.name}(${ancestor})`;
                }
                return `${data.ancestor_1}×${data.name}`;
            } else {
                return `${data.ancestor_1}×${ancestor}`;
            }
        }
        return "";
    }

    private getLotNo(lot:ISeedLot) {
        if (this.props.seed_type === A.SEED_TYPE.EGG.seed_type) {
            const typeName = getEggTypeName(lot);
            if (typeName == null) return lot.lot_name;

            return `${lot.lot_name}(${typeName})`;
        }
        return lot.lot_name;
    }
    
    private getLotDetail(lot:ISeedLot) {
        let detail = "";
        detail += `${moment(lot.stock_day).format("YYYY/M/D")} `;
        detail += `${lot.unit_price}円 `;
        if( this.props.seed_type === A.SEED_TYPE.EGG.seed_type) {
            detail += `${getEggRankName(lot) ?? ""} `;
            detail += `${getEggStageName(lot) ?? ""} `;
        }
        const inventories = (lot.stocks ?? []).filter(s => isUnusedStock(s));
        detail += `${inventories.length}/${lot.stock_count}`;
        return detail;
    }

    private async reloadList() {
        const teamId = this.props.team_id;
        if (teamId == null || teamId === 0) return;

        this.context.handleSetIsLoading(true);
        await resetSeeds(teamId);
        const items = await getSeeds(this.props.teamType, teamId, this.props.seed_type);
        this.context.handleSetIsLoading(false);
        if (items == null) return;

        let newList:ISeedLot[] = [];
        if( items !== undefined && items.length > 0) {
            const seed = (items[0].seeds ?? []).find(s => s.seed_id === this.props.data.seed_id);
            if(seed !== undefined) {
                newList = seed.lots ?? [];
            }
        }
        const preSelected = this.state.selectedSeedLot;
        const newSelected = preSelected == null
            ? null
            : newList.find(l => l.seed_lot_id === preSelected.seed_lot_id) ?? null;

        this.setState({
            seedLotList: newList,
            selectedSeedLot: newSelected
        });

        this.props.refreshSeedList(this.props.seed_type, this.props.data.seed_id, newList);
    }


    private addItem() {
        this.setState({
            editingData: {
                isNew: true,
                team_id: this.props.team_id,
                seed_type: this.props.seed_type,
                seed_id: this.props.data.seed_id,
                seed_lot_id: 0,
                lot_name: "",
                egg_type: undefined,
                egg_rank: undefined,
                egg_stage: undefined,
                stock_day: moment().format("YYYY-MM-DD"),
                stock_count: LMT.SEED.STOCK_COUNT_MIN,
                unit_price: LMT.SEED.STOCK_PRICE_MIN,
                label_from: "",
                label_to: "",
                comment: ""
            }
        })
    }

    private startEdit(lot: ISeedLot) {
        this.setState({
            editingData: {
                isNew: false,
                team_id: this.props.team_id,
                seed_type: this.props.seed_type,
                seed_id: this.props.data.seed_id,
                seed_lot_id: lot.seed_lot_id,
                lot_name: lot.lot_name,
                egg_type: lot.egg_type,
                egg_rank: lot.egg_rank,
                egg_stage: lot.egg_stage,
                stock_day: lot.stock_day,
                stock_count: lot.stock_count,
                unit_price: lot.unit_price,
                label_from: lot.label_from,
                label_to: lot.label_to,
                comment: lot.comment
            }
        });
    }

    private assertNotNull<T>(v: T | null | undefined, name?: string, when?: string): v is T {
        if (v == null) {
            console.error(`${name ?? "value"} is null or undef when ${when ?? "assertion"} is called.`);
            return false;
        }
        return true;
    }

    private async onSubmitEdit() {

        const data = this.state.editingData;
        if (!this.assertNotNull(data, "editingData", "onSubmitEdit")) return;

        if (data.lot_name === "") {
            this.context.showToast(A.MESSAGE.NO_SEED_LOT_LOT_NAME_INPUT);
            return;
        }

        if ((data.stock_day ?? "") === "") {
            this.context.showToast(A.MESSAGE.LIMIT_OVER_SEED_LOT_STOCK_COUNT);
            return;
        }

        if ((data.label_from ?? "") === "") {
            this.context.showToast(A.MESSAGE.NO_SEED_LOT_LABEL_FROM_INPUT);
            return;
        }

        if ((data.label_to ?? "") === "") {
            this.context.showToast(A.MESSAGE.INVALID_NUMERIC_SEED_LOT_LABEL_FROM);
            return;
        }

        if (data.isNew) {
            const res = await this.context.showDialog("QUESTION", "ラベルNo.範囲は登録後の変更ができません。登録を実行してよろしいですか？",
                                                    [{ text:"登録", type:"save" }, { type:"cancel" } ]);
            if (res === 0) {
                this._execEdit();
            }
        } else {
            this._execEdit();
        }
    }

    private async _execEdit() {
        const data = this.state.editingData;
        if (!this.assertNotNull(data, "editingData", "_execEdit")) return;

        try {
            this.setState({ executing: true });

            //更新要求
            this.context.handleSetIsLoading(true);
            const comReq = {
                is_new: Number(data.isNew),
                team_id: data.team_id,
                seed_type: data.seed_type,
                seed_id: data.seed_id,
                seed_lot_id: data.seed_lot_id,
                lot_name: data.lot_name,
                egg_type: data.egg_type,
                egg_rank: data.egg_rank,
                egg_stage: data.egg_stage,
                stock_day: data.stock_day,
                stock_count: data.stock_count,
                unit_price: data.unit_price,
                label_from: data.label_from,
                label_to: data.label_to,
                comment: data.comment
            };
            const postSome = this.props.teamType === "ranch"
                    ? (await RanchApi()).modifySeedLotUsingPOST({ ...comReq, ranch_id: data.team_id })
                    : (await ClinicApi()).modifyClinicSeedLotUsingPOST({ ...comReq, clinic_id: data.team_id });
            const res = await this.props.comm.send(postSome);
            this.context.handleSetIsLoading(false);
            if (res.result !== "OK") return;

            this.setState({
                editingData: null
            });

        } finally {
            this.setState({ executing: false });
        }

        this.reloadList();
    }

    private async onDelete() {
        const res = await this.context.showDialog("QUESTION", `この仕入を削除してよろしいですか？`, DIALOG_BUTTONS.DELETE_CANCEL);
        if (res === 0) {
            this._execDelete();
        }
    }
    //削除実行
    private async _execDelete() {
        const data = this.state.editingData;
        if (!this.assertNotNull(data, "editingData", "delete")) return;

        this.context.handleSetIsLoading(true);
        const req: SeedLotDeleteReq = {
            team_id: this.props.team_id,
            seed_lot_id: data.seed_lot_id
        };
        const postSome = this.props.teamType === "ranch"
                  ? (await RanchApi()).deleteSeedLotUsingPOST(req)
                  : (await ClinicApi()).deleteClinicSeedLotUsingPOST(req);
        const res = await this.props.comm.send(postSome);
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") return;

        this.setState({
            editingData: null
        });

        this.reloadList();
    }

    private onCancelEdit() {
        this.setState({
            editingData: null
        })
    }

    private onLotNameEdited(lot_name: string) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onLotNameEdited")) return;
        const data = { ...this.state.editingData};
        data.lot_name = lot_name;
        this.setState({
            editingData:data
        });
    }
    
    private onEggTypeEdited(egg_type: number | undefined) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onEggTypeEdited")) return;
        const data = { ...this.state.editingData};
        data.egg_type = egg_type;
        this.setState({
            editingData:data
        });
    }

    private onEggRankEdited(egg_rank: number | undefined) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onEggRankEdited")) return;
        const data = { ...this.state.editingData};
        data.egg_rank = egg_rank;
        this.setState({
            editingData:data
        });
    }

    private onEggStageEdited(egg_stage: number | undefined) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onEggStageEdited")) return;
        const data = { ...this.state.editingData};
        data.egg_stage = egg_stage;
        this.setState({
            editingData:data
        });
    }

    private onStockDayEdited(stock_day: Date) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onStockDayEdited")) return;
        const data = { ...this.state.editingData};
        data.stock_day = moment(stock_day).format("YYYY-MM-DD");
        this.setState({
            editingData:data
        });
    }

    private onStockCountEdited(stock_count:number) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onStockCountEdited")) return;
        const data = { ...this.state.editingData};
        data.stock_count = stock_count;
        this.setState({
            editingData:data
        });
    }

    private onUnitPriceEdited(unit_price:number) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onUnitPriceEdited")) return;
        const data = { ...this.state.editingData};
        data.unit_price = unit_price;
        this.setState({
            editingData:data
        });
    }

    private onLabelEdited(from: string, to: string) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onLabelEdited")) return;
        const data = { ...this.state.editingData};
        data.label_from = from;
        data.label_to = to;
        this.setState({
            editingData:data
        });
    }

    private onCommentEdited(comment: string) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onCommentEdited")) return;
        this.setState({
            editingData: {
                ...this.state.editingData, comment
            }
        })
    }

    private onShowSeedStock(lot: ISeedLot) {
        this.setState({
            selectedSeedLot: lot,
        });
    }

    render() {
        return (
            <div>
                <Modal isOpen={true} toggle={this.props.onClose} centered={true} scrollable={true}>
                    <ModalHeader toggle={this.props.onClose} style={{ wordBreak:"break-all" }}>{this.getTitle()}</ModalHeader>
                    <ModalBody style={{ minHeight: "360px" }}>
                        <ul className={styles["list"] + " m-b-0"}>
                            { this.state.seedLotList.map((lot,i) => (
                                <li key={lot.seed_lot_id} className={styles["list-item"]}>
                                    <div className={styles["list-item-content"]}>
                                        <div data-testid="lot-name" className={styles["list-item-name"]} style={{ wordBreak: "break-all" }}>{this.getLotNo(lot)}</div>
                                        <div data-testid="lot-detail" className={styles["list-item-detail"]}>
                                            {this.getLotDetail(lot)}
                                            <a className="link" onClick={() => this.onShowSeedStock(lot)}>(在庫)</a>
                                        </div>
                                        { lot.comment !== "" && (
                                            <div data-testid="lot-memo" className={styles["list-item-detail"]}>{lot.comment}</div>
                                        )}
                                    </div>
                                    <div onClick={()=>this.startEdit(lot)} className={styles["list-item-icon"]}><i className="fas fa-pen clickable"></i></div>
                                </li>))
                            }
                        </ul>
                    </ModalBody>
                    <ModalFooter style={{justifyContent:"flex-start"}}>
                        <span className="link" onClick={this.addItem}>
                            <i className="fa fa-plus"></i>
                            <span> 仕入を追加</span>
                        </span>
                    </ModalFooter>
                </Modal>
                {
                    this.state.editingData != null && (
                        <SeedManagementLotEditPopup
                            seed_type={this.props.seed_type}
                            data={this.state.editingData}
                            onClose={this.onCancelEdit}
                            onDelete={this.onDelete}
                            onSubmit={this.onSubmitEdit}
                            onLotNameEdited={this.onLotNameEdited}
                            onEggTypeEdited={this.onEggTypeEdited}
                            onEggRankEdited={this.onEggRankEdited}
                            onEggStageEdited={this.onEggStageEdited}
                            onStockDayEdited={this.onStockDayEdited}
                            onStockCountEdited={this.onStockCountEdited}
                            onUnitPriceEdited={this.onUnitPriceEdited}
                            onLabelEdited={this.onLabelEdited}
                            onCommentEdited={this.onCommentEdited}
                            isSubmitExecuting={this.state.executing}
                        />
                    )
                }
                {
                    this.state.selectedSeedLot != null && (
                        <SeedManagementStockPopup
                            data={this.state.selectedSeedLot}
                            onClose={() => this.setState({selectedSeedLot:null})}
                            onUpdated={()=>this.reloadList()}
                            user_id={this.props.rootStore.user.id}
                            team_id={this.props.team_id}
                            teamType={this.props.teamType}
                            seed_type={this.props.seed_type}
                            onLink={this.props.onLink}
                            comm={this.props.comm}
                            allTags={this.props.allTags}
                        />
                    )
                }
            </div>
        )
    }
}