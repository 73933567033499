import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { PageSettings } from '../../config/page-settings';
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import interaction from '@fullcalendar/interaction';
import EventApi from '@fullcalendar/core/api/EventApi';
import { withContext, IUser, IRanchHouse } from '../../stores';
import { RanchScheduleDto, RanchApi, RanchScheduleModifyReq, ClinicApi } from '../../api';
import { View } from '@fullcalendar/core';
import moment from 'moment';
import { ISchedule, SchedulePopup, EventDtoToISchedule, scheduleCustomColorToReq } from './schedule-popup';
import { Terms, Term } from './Term';
import { CommonUtil, ar, FreezedArray, QueryUtil } from '../../config/util';
import { CowToDispInfo } from '../../components/parts/cows-popup';
import { historyLocation, IEventWriteParamCow, IHistoryLocation } from '../../config/history-location-builder';
import { LinkParam } from '../top/Dashboard';
import { EventKind } from '../../config/event-kind';
import { ToggleButton } from '../../components/parts/toggle-switch';
import { DailyCalendar } from './daily-calendar';
import ReactToPrint from 'react-to-print';
import { isBrowser } from 'react-device-detect';
import { IconLink } from '../../components/parts/icon-link';
import { CalendarFilterPopup } from './calendar-filter-popup';
import { saveTeamStorage, loadTeamStorage } from 'stores/local-storage';
import { UserTeams } from 'config/user-teams';
import { A } from 'config/constant';
import { getRanchHouses } from 'stores/fetcher';

export interface CalendarFilterCondition {
    eventKinds: number[] | undefined;
    isIncompletedOnly: boolean;
    ranchIds: number[] | undefined;
    sites: number[] | undefined;
}

const buildFilterSummary = (filter: CalendarFilterCondition, user: IUser, houses: IRanchHouse[]) => {
    const items: string[] = [];

    const ranchIds = filter.ranchIds ?? [];
    if (ranchIds.length !== 0) {
        if (ranchIds.length === 1) {
            const ranch = new UserTeams(user).findRanch(ranchIds[0])?.name ?? "";
            items.push(ranch);
        } else {
            items.push(`${ranchIds.length}牧場`);
        }
    }

    const eventKinds = filter.eventKinds ?? [];
    if (eventKinds.length !== 0) {
        if (eventKinds.length <= 3) {
            const kinds = eventKinds.map(e => EventKind.find(e)?.schedule?.sname ?? "").join("/");
            items.push(kinds);
        } else {
            items.push(`${eventKinds.length}種別`);
        }
    }

    if (filter.isIncompletedOnly) {
        items.push("未完了のみ");
    }

    const sites = filter.sites ?? [];
    if (sites.length !== 0) {
        if (sites.length === 1) {
            const no = sites[0];
            const site = no === 0 ? "牛なし/分場未設定" : houses.find(h => h.no === no)?.name ?? "";
            items.push(site);
        } else {
            items.push(`${sites.length}分場`);
        }
    }

    if (items.length === 0) return "絞込み";
    
    let text = items.join("、");

    return "絞込み：" + text;
}

interface MyState {
    teamId?: number;
    teamType: "clinic"|"officialRanch"|"unofficialRanch";
    ranchHouses?: IRanchHouse[];
    events: ICalendarEvent[];
    loadedTerms: Terms;
    currentTerm: Term;
    currentEvent?: Partial<ISchedule>;
    isEditing: boolean;
    currentView: "month"|"day";
    currentDay: Date;
    filter: CalendarFilterCondition;
    isFilterPopupShown: boolean;
    /**
     * 遷移元から指定されたスケジュールIDを保持しておき、初回の予定取得時に、その中に含まれていれば選択する
     */
    scheduleIdToSelect?: number;
}

export type ICalendarEvent = ISchedule & {
    color:string;
    textColor: "black"|"white";
    sites: number[];
};

export const parseHexToRgb = (hex: string) => {
    if (hex.startsWith("#")) {
        hex = hex.substr(1);
    }
    if (hex.length !== 6) {
        return undefined;
    }

    const r = hex.substr(0, 2);
    const g = hex.substr(2, 2);
    const b = hex.substr(4);
    const res = { r: parseInt(r, 16), g: parseInt(g, 16), b: parseInt(b, 16) };
    if (isNaN(res.r) || isNaN(res.g) || isNaN(res.b)) {
        return undefined;
    }
    return res;
}

/**
 * 背景色とのコントラストで文字色を決定する
 * @param bgColor 
 */
export const chooseTextColor = (bgColor: string): "black"|"white" => {
    const rgb = parseHexToRgb(bgColor);
    if (rgb == null) {
        console.error("invalid bgColor", bgColor);
        return "black";
    }

    const toRgbItem = (item: number) => {
        const i = item / 255
        return i <= 0.03928 ? i / 12.92 : Math.pow((i + 0.055) / 1.055, 2.4)
    }
    const R = toRgbItem(rgb.r)
    const G = toRgbItem(rgb.g)
    const B = toRgbItem(rgb.b)
    const Lbg = 0.2126 * R + 0.7152 * G + 0.0722 * B
 
    const Lw = 1
    const Lb = 0
 
    const Cw = (Lw + 0.05) / (Lbg + 0.05)
    const Cb = (Lbg + 0.05) / (Lb + 0.05)
 
    return Cw < Cb ? 'black' : 'white'
}
 
const dtoToEvent = (dto: RanchScheduleDto): ICalendarEvent | null => {
    const convCows = (cows:IEventWriteParamCow[]) => {
        if (cows.length === 0) return "";
        const one = CowToDispInfo(cows[0], false);
        return cows.length === 1 ? one : (one + "他");
    }
    const schedule = EventDtoToISchedule(dto, convCows);
    if (schedule == null) return null;

    const color = schedule.customColor ?? EventKind.find(schedule.eventKindNo)?.schedule?.color ?? "#ffffff";

    const res = {
        ...schedule,
        color: color,
        textColor: chooseTextColor(color),
        sites: dto.sites,
    };
    return res;
};


class RanchCalendar extends Base<BaseProps<{ id?: string }>, MyState> {

    static contextType = PageSettings;

    readonly refCalendar = React.createRef<FullCalendar>();
    readonly refTable = React.createRef<HTMLDivElement>();

    constructor(props) {
        super(props);

        this.state = {
            teamType:"officialRanch",
            events:[],
            loadedTerms: new Terms(),
            currentTerm: new Term(new Date(), new Date()),
            isEditing: false,
            currentView: "month",
            currentDay: moment().startOf("day").toDate(),
            filter: { isIncompletedOnly:false, eventKinds:undefined, ranchIds:undefined, sites:undefined },
            isFilterPopupShown:false,
        };

        this.onDateClick = this.onDateClick.bind(this);
        this.onEventClick = this.onEventClick.bind(this);
        this.onDatesRender = this.onDatesRender.bind(this);
        this.onPopupClose = this.onPopupClose.bind(this);
        this.onStartEdit = this.onStartEdit.bind(this);
        this.onFinishEdit = this.onFinishEdit.bind(this);
        this.onCowClickInPopup = this.onCowClickInPopup.bind(this);
        this.onCowClick = this.onCowClick.bind(this);
        this.onLinkClick = this.onLinkClick.bind(this);
        this.onSplit = this.onSplit.bind(this);
        this.onMoveToRanchCalendar = this.onMoveToRanchCalendar.bind(this);
    }

    componentDidMount() {

        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        this.init(false);
    }

    componentDidUpdate(prevProps: this["props"]) {
        if (prevProps.match.params.id !== this.props.match.params.id
            || prevProps.location.search !== this.props.location.search) {

            this.init(true);
        }
    }

    parseParamId = () => {
        const idStr = this.props.match.params.id;
        if (idStr == null) return undefined;
        const id = Number(idStr);
        if (isNaN(id)) return undefined;
        return id;
    }

    private async init(reloadsManually: boolean) {

        const teamId = this.parseParamId();
        if (teamId == null) {
            this.setState({ teamId: undefined });
            return;
        }

        if (this.handleNotAllowAccess(teamId, ["BROWSE_INSIDE"], ["MASTER_REF"])) {
            return;
        }
        const teams = new UserTeams(this.props.rootStore.user);
        const isRanch = teams.findRanch(teamId) != null;
        const isOfficialRanch = isRanch && teams.findOfficialRanch(teamId) != null;
        this.context.handleSetHeader({
            title:isRanch ? "牧場カレンダー" : "診療所カレンダー",
            iconType:isRanch ? "ranch" : "clinic",
            backBtn:false
        });

        let ranchHouses: IRanchHouse[] | undefined = undefined;

        if (isRanch) {
            this.context.handleSetIsLoading(true);
            try {
                if (isOfficialRanch) {
                    ranchHouses = await getRanchHouses(teamId, false);
                    if (ranchHouses == null) {
                        this.context.showToast(A.MESSAGE.FAILED_TO_LOAD_DATA);
                        this.setState({ teamId:undefined });
                        return;
                    }
                }
                this.props.rootStore.fetchActiveCows(teamId, "DIFF");

            } finally {
                this.context.handleSetIsLoading(false);
            }
        }

        const scheduleId = QueryUtil.parseNumFromSearch(this.props.location.search, "event");

        await this.setStateAsync({
            teamId,
            teamType: isOfficialRanch ? "officialRanch" : isRanch ? "unofficialRanch" : "clinic",
            ranchHouses,
            events:[],
            loadedTerms: new Terms(),
            currentEvent:undefined,
            isEditing: false,
            filter: { isIncompletedOnly:false, eventKinds:undefined, ranchIds:undefined, sites:undefined },
            isFilterPopupShown:false,
            scheduleIdToSelect: scheduleId
        });

        const filter = loadTeamStorage<Partial<CalendarFilterCondition>>("calendar_filter", teamId);
        if (filter != null) {
            let ranchIds = filter.ranchIds;
            if (ranchIds != null) {
                //所属していない牧場IDが含まれているときは条件クリアしてしまう
                if (isRanch || ranchIds.some(id => teams.findRanch(id) == null)) {
                    ranchIds = undefined;
                }
            }
            let sites = filter.sites;
            if (sites != null) {
                //マスタにない分場が含まれているときはクリア
                if (ranchHouses == null || sites.some(site => site !== 0 && !ranchHouses!.some(h => h.no === site))) {
                    sites = undefined;
                }
            }

            this.setState({
                filter: {
                    eventKinds: filter.eventKinds,
                    isIncompletedOnly: filter.isIncompletedOnly ?? false,
                    ranchIds,
                    sites
                }
            })
        }

        if (reloadsManually) {
            this.reloadCurrentTermEvents();
        }
    }

    private onDatesRender(view: View) {
        const start = view.activeStart;
        const end = moment(view.activeEnd).add(-1,"day").toDate();
        this.setState({
            currentTerm: new Term(start, end)
        });
        this.loadEvents(start, end, false);
    }
    private onDayViewDateSelected(date: Date) {
        this.setState({
            currentDay: date
        });
        this.loadEvents(date, date, false);
    }
    private async onForwardSchedule(req: RanchScheduleModifyReq) {

        this.context.handleSetIsLoading(true);
        const res = await this.comm().send((await RanchApi()).modifyScheduleUsingPOST(req), { asksToReloadWhenNG:true });
        this.context.handleSetIsLoading(false);

        if (res.result !== "OK") {
            if (res.result !== "NG_RELOAD_REQUIRED") {
                return;
            }
        }

        await this.reloadCurrentTermEvents();
    }

    private async loadEvents(fromDate:Date, toDate:Date, reset: boolean) {
        if (!CommonUtil.assertNotNull(this.state.teamId, "teamId")) return;

        const from = moment(fromDate);
        const to = moment(toDate);
        if (from.isAfter(to)) {
            console.error("invalid term", from, to);
            return;
        }

        const loadedTerms = reset ? new Terms() : this.state.loadedTerms.clone();

        const term = new Term(from, to);
        const toLoad = loadedTerms.getUncontained(term);
        if (toLoad == null) {
            this.trySelectEvent();
            return;
        }

        this.context.handleSetIsLoading(true);
        const res = this.state.teamType !== "clinic"
                ? await this.comm().send((await RanchApi()).getScheduleListUsingPOST({
                    ranch_id: this.state.teamId,
                    start_at: moment(toLoad.from).format("YYYY-MM-DD"),
                    end_at: moment(toLoad.to).format("YYYY-MM-DD")
                }))
                : await this.comm().send((await ClinicApi()).getClinicScheduleListUsingPOST({
                    clinic_id: this.state.teamId,
                    start_at: moment(toLoad.from).format("YYYY-MM-DD"),
                    end_at: moment(toLoad.to).format("YYYY-MM-DD")
                }));
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") return;
        
        const list = res.data ?? [];
        const added = ar.notNull(list.map(d => dtoToEvent(d)));

        //月またぎのイベントや、取得済み期間が歯抜けだった分は重複して取得されるので捨てる
        const events = reset ? added : [ ...this.state.events, ...added.filter(a => !this.state.events.some(e => e.id === a.id)) ];
        loadedTerms.add(toLoad);

        await this.setStateAsync({
            events: events,
            loadedTerms: loadedTerms
        });

        this.trySelectEvent();
    }

    /**
     * 遷移元から指定されていたスケジュールが表示されていれば選択する
     */
    private trySelectEvent() {
        if (this.state.scheduleIdToSelect == null) return;

        const evt = this.state.events.find(e => e.id === this.state.scheduleIdToSelect);
        if (evt != null) {
            this.setState({
                currentEvent:evt
            });
        }
        //あってもなくてもクリア
        this.setState({ scheduleIdToSelect:undefined });
    }

    private onDateClick(date: Date) {
        this.addNewEvent(date);
    }
    private onAddEventClick() {
        const date = this.state.currentView === "month" ? new Date() : this.state.currentDay;
        this.addNewEvent(date);
    }
    private addNewEvent(date: Date) {
        this.setState({
            isEditing: true,
            currentEvent: {
                start: date,
                end: date,
                allDay: true
            }
        })        
    }
    private onEventClick(eventApi: EventApi) {
        const event = this.state.events.find(o => o.id === Number(eventApi.id));
        if (!CommonUtil.assertNotNull(event, "event", "onEventClick")) return;

        this.setState({
            currentEvent: event,
            isEditing: false
        });
    }
    private onDayViewEventClick(event: ICalendarEvent) {
        this.setState({
            currentEvent: event,
            isEditing: false
        })
    }
    private async onStatusChange(event:ICalendarEvent, completed: boolean) {
        if (!CommonUtil.assertNotNull(this.state.teamId, "teamId")) return;

        const req: RanchScheduleModifyReq = {
            ranch_id: event.ranchId,
            clinic_id: event.clinicId,
            is_new: 0,
            schedule_id: event.id,
            title: event.rawTitle,
            start_at: moment(event.start).format("YYYY-MM-DD HH:mm:00"),
            end_at: moment(event.end).format("YYYY-MM-DD HH:mm:00"),
            has_time: event.allDay ? 0 : 1,
            event_kind_no: event.eventKindNo,
            note: event.note,
            cow_id: event.cows.map(c => c.cow_id),
            status: completed ? 1 : 0,
            color: scheduleCustomColorToReq(event.customColor),
            preset_id: event.preset?.preset_id
        };
    
        this.context.handleSetIsLoading(true);
        const res = await this.comm().send((await RanchApi()).modifyScheduleUsingPOST(req), { asksToReloadWhenNG:true });
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") {
            if (res.result === "NG_RELOAD_REQUIRED") {
                await this.reloadCurrentTermEvents();
            }
            return;
        }

        //ローカルで更新
        const events = this.state.events;
        this.setState({
            events: events.map(e => e.id === event.id ? { ...e, status: completed } : e)
        })
    }

    private onFinishEdit() {
        this.setState({
            currentEvent:undefined,
        });
        this.reloadCurrentTermEvents();
    }
    private async reloadCurrentTermEvents() {
        await this.loadEvents(this.state.currentTerm.from, this.state.currentTerm.to, true);
        if (!this.state.currentTerm.includesDate(this.state.currentDay)) {
            await this.loadEvents(this.state.currentDay, this.state.currentDay, false);
        }
    }
    private onStartEdit() {
        this.setState({
            isEditing: true
        });
    }
    private onPopupClose() {
        this.setState({
            currentEvent: undefined,
        });
    }
    private onSplit(results: RanchScheduleDto[]) {
        const oldId = this.state.currentEvent?.id;
        if (!CommonUtil.assertNotNull(oldId, "id", "onSplit")) return;

        const newEvents = ar.notNull(results.map(d => dtoToEvent(d)));

        this.setState({
            currentEvent: undefined,
            //再取得されたイベントと入れ替える
            events: [...this.state.events.filter(e => e.id !== oldId), ...newEvents ]
        });
    }

    private onCowClickInPopup(cowId: number) {
        const eventRanchId = this.state.currentEvent?.ranchId;
        if (CommonUtil.assertNotNull(eventRanchId, "eventRanchId")) {
            this.tryNavigateToRanchPage(eventRanchId, historyLocation.toCowInfo(cowId));
        }
    }
    private onCowClick({ ranch_id, cow_id }: { ranch_id:number, cow_id:number }) {
        this.tryNavigateToRanchPage(ranch_id, historyLocation.toCowInfo(cow_id));
    }
    private onLinkClick(link: LinkParam) {
        const eventRanchId = this.state.currentEvent?.ranchId;
        if (CommonUtil.assertNotNull(eventRanchId, "eventRanchId")) {
            this.tryNavigateToRanchPage(eventRanchId, link);
        }
    }

    private onFilterSubmit(filter: CalendarFilterCondition) {
        if (!CommonUtil.assertNotNull(this.state.teamId, "teamId")) return;

        this.setState({
            isFilterPopupShown:false,
            filter
        });

        saveTeamStorage("calendar_filter", this.state.teamId, filter);
    }

    private async onMoveToRanchCalendar() {
        const event = this.state.currentEvent;
        if (!CommonUtil.assertNotNull(event, "event")) return;
        if (!CommonUtil.assertNotNull(event.ranchId, "ranchId")) return;
        if (!CommonUtil.assertNotNull(event.id, "id")) return;

        this.tryNavigateToRanchPage(event.ranchId, historyLocation.toCalendar(event.ranchId, event.id));
    }

    private async tryNavigateToRanchPage(ranchId: number, dest: IHistoryLocation) {
        if (this.props.rootStore.cur_ranch_id !== ranchId) {
            this.context.handleSetIsLoading(true);
            const res = await this.props.rootStore.tryChangeRanch(ranchId);
            this.context.handleSetIsLoading(false);

            if (res !== true) {
                this.context.showToast(A.MESSAGE.FAILED_TO_LOAD_DATA);
                return;
            }
        }
        this.props.history.push(dest);
    }

    render() {
        const teamId = this.state.teamId;
        if (teamId == null) return <></>

        return (
            <div className="page-root">
                <div className="product" style={{ height: "100%", "minHeight": "0" }}>
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix p-b-10 p-t-10">
                            <div style={{ display:"flex", justifyContent:"space-between", alignItems:"flex-end", marginBottom:"5px" }}>
                                <ToggleButton id="view-select" label1="月" label2="日" state={this.state.currentView === "month" ? 1 : 2} onChange={st => this.setState({ currentView: st === 1 ? "month" : "day"})} />
                                <div style={{ flex:1, margin:"0 8px", overflow:"hidden", textOverflow:"ellipsis", whiteSpace:"nowrap" }}>
                                    <IconLink iconType="filter" text={buildFilterSummary(this.state.filter, this.props.rootStore.user, this.state.ranchHouses ?? [])}
                                        onClick={() => this.setState({ isFilterPopupShown:true })} />
                                </div>
                                { isBrowser && (
                                    <ReactToPrint
                                        trigger={() => <button className="btn btn-orange">印刷</button>}
                                        content={() => this.state.currentView === "month" ? this.refCalendar.current : this.refTable.current}
                                    />
                                )}
                            </div>

                            <div className="ranch-main" style={{flex: "auto"}}>
                                <Calendar
                                    events={this.state.events}
                                    refCalendar={this.refCalendar}
                                    refTable={this.refTable}
                                    onDateClick={this.onDateClick}
                                    onEventClick={this.onEventClick}
                                    onDatesRender={this.onDatesRender}
                                    currentDay={this.state.currentDay}
                                    onDateChange={d => this.onDayViewDateSelected(d)}
                                    onDayViewEventClick={e => this.onDayViewEventClick(e)}
                                    onStatusChange={(ev, comp) => this.onStatusChange(ev, comp)}
                                    onForwardSchedule={r => this.onForwardSchedule(r)}
                                    showQuestionAsync={this.context.showQuestionAsync}
                                    onCowClick={this.onCowClick}
                                    currentView={this.state.currentView}
                                    filter={this.state.filter}
                                />
                                <div className="m-t-10 m-b-10" style={{display:"flex", justifyContent: "space-between"}}>
                                    <IconLink iconType="add"      text="予定を追加"     onClick={() => this.onAddEventClick()} />
                                    <IconLink iconType="navigate" text="未完了予定一覧" onClick={() => this.props.history.push(historyLocation.toIncompletedSchedule(teamId))} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                { this.state.currentEvent != null && (
                    <SchedulePopup onClose={this.onPopupClose} onStartEdit={this.onStartEdit} original={this.state.currentEvent}
                                   onRegistered={this.onFinishEdit}
                                   onSplit={this.onSplit}
                                   isEditing={this.state.isEditing}
                                   onCowClick={this.onCowClickInPopup}
                                   ranch_id={this.state.teamType !== "clinic" ? teamId : undefined}
                                   onResultLinkClick={this.onLinkClick}
                                   activeCows={this.state.teamType !== "clinic" ? this.props.rootStore.getActiveCows() : []}
                                   clinic_id={this.props.rootStore.getClinicIdForMaster()}
                                   rootStore={this.props.rootStore}
                                   onMoveToRanchCalendar={this.state.teamType !== "clinic" ? undefined : this.onMoveToRanchCalendar}
                                   />
                )}
                { this.state.isFilterPopupShown && (
                    <CalendarFilterPopup
                        ranchId={this.state.teamType !== "clinic" ? teamId : undefined}
                        isOfficialRanch={this.state.teamType === "officialRanch"}
                        user={this.props.rootStore.user}
                        onClose={() => this.setState({ isFilterPopupShown: false })}
                        onSubmit={cond => this.onFilterSubmit(cond)}
                        condition={this.state.filter}
                    />
                )}
            </div>
        )
    }
}

export default withRouter(withContext(RanchCalendar));


const Calendar = React.memo((props: {
    events: ICalendarEvent[],
    currentView: "month"|"day",
    currentDay: Date,
    onCowClick: (data:{ ranch_id: number, cow_id: number }) => void,
    filter: CalendarFilterCondition,
    onDateChange: (date:Date) => void,
    onDateClick: (date:Date) => void,
    onDayViewEventClick: (event: ICalendarEvent) => void,
    onStatusChange: (event: ICalendarEvent, completed: boolean) => void,
    refTable: React.RefObject<HTMLDivElement>,
    refCalendar: React.RefObject<FullCalendar>,
    onForwardSchedule: (req: Omit<RanchScheduleModifyReq, "user_id"|"ranch_id">) => void,
    showQuestionAsync: (text: string, buttons: string[]) => Promise<number|undefined>,
    onEventClick: (ev:EventApi) => void,
    onDatesRender: (view:View) => void,
}) => {
    const [ dispEvents, setDispEvents ] = useState<ICalendarEvent[]>([]);

    useEffect(() => {
        const kindSet = props.filter.eventKinds == null ? undefined : new Set(props.filter.eventKinds);
        const ranchSet = props.filter.ranchIds == null ? undefined : new Set(props.filter.ranchIds);
        const siteSet = props.filter.sites == null ? undefined : new Set(props.filter.sites);

        const pick = (ev: ICalendarEvent): boolean => {
            if (kindSet != null && !kindSet.has(ev.eventKindNo)) return false;
            if (ranchSet != null && (ev.ranchId == null || !ranchSet.has(ev.ranchId))) return false;
            if (props.filter.isIncompletedOnly && ev.status === true) return false;

            if (siteSet != null) {
                if (ev.sites.length === 0) {
                    if (!siteSet.has(0)) return false;
                } else {
                    if (ev.sites.every(evs => !siteSet.has(evs))) return false;
                }
            }

            return true;
        }

        setDispEvents(
            props.events.filter(ev => pick(ev))
        );

    }, [ props.events, props.filter ]);


    if (props.currentView === "month") {
        return (
            <div className="fc-ranch">
                <FullCalendar defaultView="dayGridMonth" plugins={[ dayGridPlugin, interaction ]} locale="ja"
                    ref={props.refCalendar}
                    buttonText={{prev:"<", next:">"}}
                    header={{left:"prev",right:"next",center:"title"}}
                    allDayText="終日" dateClick={e => props.onDateClick(e.date)}
                    eventClick={e => props.onEventClick(e.event)}
                    datesRender={e => props.onDatesRender(e.view)}
                    eventDataTransform={ev => {
                        //終日のイベントが1日短く表示される対処
                        if (ev.allDay) {
                            ev.end = moment(ev.end).add(1, "day").toDate();
                        }
                        return ev;
                    }}
                    eventRender={({event,el}) => el.setAttribute("data-testid", `ev_${event.start == null ? "" : moment(event.start).format("YYYYMMDD")}`) }
                    events={dispEvents.map(e => ({ ...e, title:e.dispTitle }))}
                />
            </div>
        );
    }

    return (
        <DailyCalendar
            refTable={props.refTable}
            date={props.currentDay}
            events={dispEvents}
            onDateChange={props.onDateChange}
            onClick={props.onDayViewEventClick}
            onStatusChange={props.onStatusChange}
            onForwardSchedule={props.onForwardSchedule}
            showQuestionAsync={props.showQuestionAsync}
            onCowClick={props.onCowClick}
        />
    );

});