import React, { useState, useEffect } from 'react';
import { FreezedArray, CommonUtil, formatDiseaseCode } from '../../config/util';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styles from './setting.module.css';
import { SortableElement, SortableContainer, SortEnd } from 'react-sortable-hoc';
import classnames from 'classnames';
import arrayMove from 'array-move';
import { StarIcon } from './star-icon';
import { DISEASE_ITEM_STATUS } from '../../config/constant';
import { DiseaseAddRmIcon } from './disease-master';
import { ExecutionButton } from '../../components/buttons/execution-button';

export type EditingCause = {
    cause_no: number;
    code_no: number | undefined;
    cause_name: string;
    status: number;
}

interface MyProps {
    diseaseName: string;
    code_no: number | undefined;
    causes: FreezedArray<EditingCause>;
    onClose: () => void;
    onSubmit: (favorite: boolean, causes: FreezedArray<EditingCause>) => void;
    isSubmitExecuting: boolean;
    visibleOnly: boolean;
    favorite: boolean;
    isEpidemic: boolean;
}

//NOTE: li要素にkeyをつけないとreactで警告されるが、単純に↓のliにkeyを入れるだけだと
//      警告が解消されない＆下から上方向に行をドラッグしたときにライブラリでエラーが発生する
const SortableItem = SortableElement(({item}:{item:EditingCause}) => (
    <ListItem cause={item} sorting={true} favorite={item.status === DISEASE_ITEM_STATUS.FAVORITE.no} />
));

const SortableListContainer = SortableContainer(({ items }: { items: FreezedArray<EditingCause> }) => (
    <ul className={styles["list"]}>
        {
            items.map((c, i) => (
                <SortableItem item={c} index={i} />
            ))
        }
    </ul>
));

const ListItem = (props :
    { cause: EditingCause, sorting: boolean, favorite:boolean, onStatusChange?:(status:number) => void }
) => {
    const isHidden = props.cause.status === DISEASE_ITEM_STATUS.HIDDEN.no;

    return (
        <li className={classnames(styles["list-item"], styles["sortable-helper"], styles["list-item-mid"], {[styles.hidden]:isHidden})}
            style={{ alignItems:"center" }}>
            <StarIcon className="m-r-10"
                isOn={props.favorite}
                onChange={on => {
                    if (props.onStatusChange) {
                        props.onStatusChange(on ? DISEASE_ITEM_STATUS.FAVORITE.no : DISEASE_ITEM_STATUS.VISIBLE.no)}
                    }
                }
                disable={props.sorting || isHidden}
                style={{ visibility:isHidden ? "hidden" : "visible"}}
            />
            <div className={styles["list-item-content"]}>
                <div className={styles["list-item-name"]}>
                    <span>{props.cause.cause_name}</span>
                    { !props.sorting && (
                        <DiseaseAddRmIcon toAdd={isHidden} onClick={() => {
                            if (props.onStatusChange) {
                                props.onStatusChange(isHidden ? DISEASE_ITEM_STATUS.VISIBLE.no : DISEASE_ITEM_STATUS.HIDDEN.no)
                            }
                        }} />
                    )}
                </div>
            </div>
            { props.sorting && (
                <div className={styles["list-item-icon"]}><i className="fas fa-bars" /></div>        
            )}
        </li>
    )
};


export const DiseaseEditPopup = (props: MyProps) => {
    const [ allList, setAllList ] = useState<FreezedArray<EditingCause>>([]);
    const [ dispList, setDispList ] = useState<FreezedArray<EditingCause>>([]);
    const [ sortingList, setSortingList ] = useState<FreezedArray<EditingCause>>();
    const [ isFavorite, setIsFavorite ] = useState(false);

    useEffect(() => {
        setAllList(props.causes);

    }, [ props.causes ]);

    useEffect(() => {
        setIsFavorite(props.favorite);

    }, [ props.favorite]);

    useEffect(() => {
        setDispList(
            props.visibleOnly ? allList.filter(c => c.status !== DISEASE_ITEM_STATUS.HIDDEN.no) : allList
        )

    }, [ props.visibleOnly, allList ]);

    const onSortEnd = (sort: SortEnd) => {
        if (!CommonUtil.assertNotNull(sortingList)) return;

        setSortingList(arrayMove(sortingList, sort.oldIndex, sort.newIndex));
    };

    const submitSort = () => {
        if (!CommonUtil.assertNotNull(sortingList)) return;

        if (props.visibleOnly) {
            const dispNoSet = new Set(sortingList.map(s => s.cause_no));
            setAllList([
                ...sortingList,
                //非表示の項目を後ろに
                ...allList.filter(c => !dispNoSet.has(c.cause_no))
            ])

        } else {
            setAllList(sortingList);
        }
        setSortingList(undefined);
    }

    const onStatusChange = (cause: EditingCause, status: number) => {
        setAllList(preList => {
            return preList.map(c => c.cause_no === cause.cause_no ? ({ ...c, status }) : c)
        })
    }

    return (
        <div>
            <Modal isOpen={true} centered={true}>
                <ModalHeader toggle={props.onClose}>原因リスト設定</ModalHeader>
                <ModalBody style={{ height: "calc(100vh - 210px)", display:"flex", flexFlow:"column nowrap" }}>
                    <div style={{ display:"flex", alignItems: "center", marginBottom: "8px" }}>
                        <div style={{ width:"80px" }}>疾病名</div>
                        <StarIcon testId="modal-disease-fav"
                            style={{ marginTop:"-3px", marginRight:"10px" }}
                            isOn={isFavorite}
                            onChange={s => setIsFavorite(s)}
                        />
                        <div>
                            <span data-testid="modal-disease-name" style={{ fontWeight: "bold" }}>{props.diseaseName}</span>
                            { props.code_no != null && (
                                <span className="m-l-5">({formatDiseaseCode(props.code_no)})</span>
                            )}
                        </div>
                    </div>
                    { props.isEpidemic && (
                        <div style={{ margin:"-4px 0 8px 80px", fontSize:"0.75rem", color:"#ff3333" }}>法定伝染病</div>
                    )}

                    <div style={{ paddingBottom : ".5rem", marginBottom: "4px" }}>
                        { props.causes.length > 0 && (
                            <div style={{ display: "flex", fontSize:"0.9rem", flexDirection:"row-reverse", justifyContent:"space-between"}}>
                                { sortingList == null ? (
                                    <span className="link" onClick={() => setSortingList(dispList)}>並び替え</span>
                                ) : (<>
                                    <span className="link" onClick={() => submitSort() }>完了</span>
                                    <span className="link" onClick={() => setSortingList(undefined)}>キャンセル</span>
                                </>)}
                            </div>
                        )}
                    </div>

                    <div style={{ flex: 1, overflowY:"auto" }}>
                        { sortingList == null ? (
                            <ul className={styles["list"]}>
                                { dispList.map((c,i) => (
                                    <ListItem key={i} cause={c} sorting={false}
                                        favorite={c.status === DISEASE_ITEM_STATUS.FAVORITE.no}
                                        onStatusChange={s => onStatusChange(c,s)} />
                                ))}
                            </ul>
                        ) : (
                            <SortableListContainer onSortEnd={onSortEnd} items={sortingList} />
                        )}
                    </div>
                </ModalBody>
                <ModalFooter className="modal-footer-fix">
                    <ExecutionButton type="save" onClick={() => props.onSubmit(isFavorite, allList)} disabled={props.isSubmitExecuting || sortingList != null} />
                </ModalFooter>
            </Modal>
        </div>
    )
}