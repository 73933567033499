import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import ToolTip from 'rc-tooltip';
import { IUser } from '../../stores';
import { hasRanchAuth } from '../../config/auth-checker';
import { hasRanchContract } from '../../config/contract-checker';
import { UserTeams } from '../../config/user-teams';
import { isBrowser } from 'react-device-detect';
import { USER_LICENSE } from '../../config/user-license';
import Tooltip from 'rc-tooltip';

interface ActionIconProps {
    faClass: string;
    onClick?: () => void;
    name: string;
}

const ActionIcon = (props: ActionIconProps) => (
    <div className="event-button-container" onClick={props.onClick}>
        <span className="fa-stack fa-lg event-icon">
            <i className="fa fa-circle fa-stack-2x"></i>
            <i className={`fa-inverse ${props.faClass} fa-stack-1x`}></i>
        </span>
        <div className="event-text">{props.name}</div>
    </div>
)

export type CertType = "vaccine"|"disease"|"karte";
export type ImportType = "robot";
export interface ActionIconBarProps {
    showsBreedingGroup: boolean;
    showsSingleCowIcon: boolean;
    showsSellMilk: boolean;
    onRutDetailClick: ()=>void;
    onDeliveryClick: ()=>void;
    onBreedingClick: ()=>void;
    onFeedingClick: ()=>void;
    onBulkFeedingClick?: ()=>void;
    onFeedingLeftClick: ()=>void;
    onPreventionClick: ()=>void;
    onSymptomClick: ()=>void;
    onBulkTreatClick?: ()=>void;
    onLocationClick: ()=>void;
    onScheduleClick: ()=>void;
    onBalanceClick: ()=>void;
    onSellMilkClick: ()=>void;
    onSellCowClick: ()=>void;
    onProgramClick: ()=>void;
    onGrowthClick: ()=>void;
    onBalanceRanchClick?: ()=>void;
    onRanchEventClick?: ()=>void;
    onSellRanchMilkClick?: ()=>void;
    onOtherPlRanchClick?: ()=>void;
    onOtherPlCowClick: ()=>void;
    onReportSellCowClick?: ()=>void;
    onReportDeliveryClick?: ()=>void;
    onReportDynamicsClick?: ()=>void;
    onSovClick: () => void;
    onOpuClick: () => void;
    onIvfClick: () => void;
    onIvfEggClick: () => void;
    onCertClick: (certType: CertType) => void;
    onImportClick?: (importType: ImportType) => void;
    onReportSensorClick?: () => void;
    optionalStyles?: React.CSSProperties;
    containerClassName?: string;
    ranchId: number;
    user: IUser;
    isActive: boolean;
    arrowTop?: number;
    arrowLeft?: number;
    arrowRight?: number;
}

export const ActionIconBar: React.FC<ActionIconBarProps> = (props) => {
    const [ isBreedingTooltipVisible, setIsBreedingTooltipVisible ] = useState(false);
    const [ isSymptomTooltipVisible, setIsSymptomTooltipVisible ] = useState(false);
    const [ isFeedingTooltipVisible, setIsFeedingTooltipVisible ] = useState(false);
    const [ scrollLeft, setScrollLeft ] = useState(0);
    const [ scrollRight, setScrollRight ] = useState(0);

    const node = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        window.addEventListener("resize", onResized);
        return () => window.removeEventListener("resize", onResized);
    }, []);

    useEffect(() => {
        refreshScrollState();
    }, [ props.showsBreedingGroup, props.ranchId, props.user ]);

    const onResized = ()=> {
        refreshScrollState();
    }
    const refreshScrollState = () => {
        if (node.current == null) return;

        setScrollLeft(node.current.scrollLeft);
        const right = node.current.scrollWidth - node.current.offsetWidth - node.current.scrollLeft;
        setScrollRight(right);
    }

    const onScroll = (e: React.UIEvent<HTMLDivElement>): void => {
        refreshScrollState();
    };

    const onArrowClick = (direction: "l"|"r") => {
        node.current?.scrollBy(direction === "l" ? -60 : 60, 0);
    }

    const userTeams = new UserTeams(props.user);
    const isOfficial = userTeams.findOfficialRanch(props.ranchId) != null;
    const isClinicUser = userTeams.findSingleClinic() != null;
    const license = props.user.setting?.license == null ? undefined : USER_LICENSE[props.user.setting.license.type];

    const isPC = isBrowser;

    const hasBreedingContract = hasRanchContract("BREEDING", props.ranchId, props.user);
    //TODO リリース前に契約チェックを入れる
    // const hasRobotImportContract = hasRanchContract("ROBOT_IMPORT", props.ranchId, props.user);
    // const hasSensorContract = hasRanchContract("THERMOTELL", props.ranchId, props.user);
    const hasRobotImportContract = true;
    const hasSensorContract = true;
    const canFeeding = hasRanchAuth("FOODING_EDIT", props.ranchId, props.user);
    const canBreeding = hasBreedingContract && hasRanchAuth("BREEDING_EDIT", props.ranchId, props.user);
    const canTreat = hasRanchAuth("TREAT_EDIT", props.ranchId, props.user);
    const canEditCow = hasRanchAuth("COW_EDIT", props.ranchId, props.user);
    const canBrowseInside = hasRanchAuth("BROWSE_INSIDE", props.ranchId, props.user);
    const canRefBalanceCow = hasRanchAuth("BALANCE_COW", props.ranchId, props.user);
    const canRefBalanceRanch = hasRanchAuth("BALANCE_RANCH", props.ranchId, props.user);
    const canRefReport = hasRanchAuth("REPORT_RANCH", props.ranchId, props.user);
    const canEditGrowth = hasRanchAuth("GROWTH_EDIT", props.ranchId, props.user);
    const canRefTreatName = hasRanchAuth("TREAT_REF_NAME", props.ranchId, props.user);
    const canRefTreatPrice = hasRanchAuth("TREAT_REF_PRICE", props.ranchId, props.user);

    const showsGrowth = canEditGrowth && props.isActive;
    const showsSensor = canBrowseInside && hasSensorContract && props.onReportSensorClick;

    const arrowTop = (props.arrowTop ?? 8) + "px";
    const arrowLeft = (props.arrowLeft ?? 2) + "px";
    const arrowRight = (props.arrowRight ?? 2) + "px";

    return (
        <div style={{position:"relative"}}>
            {/* ※2.0は適当な値 */}
            { scrollLeft > 2.0 && (
                <span className="action-icon-bar-arrow l" style={{ position:"absolute", left: arrowLeft, top:arrowTop }}
                    onClick={()=>onArrowClick("l")}>
                    <i className="fas fa-caret-left"></i>
                </span>
            )}
            <div style={props.optionalStyles ?? {}} className={"action-icon-bar " + (props.containerClassName ?? "")} onScroll={onScroll}
                ref={node}>

                { props.showsBreedingGroup && canBreeding && props.isActive && (
                <ToolTip overlayClassName="icon-tooltip" placement="topRight" trigger={['click']} visible={isBreedingTooltipVisible}
                    onVisibleChange={v => setIsBreedingTooltipVisible(v ?? false)}
                    overlay={
                    (<div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                        <ActionIcon faClass="fas fa-eye" name="発情記録" onClick={()=> { setIsBreedingTooltipVisible(false); props.onRutDetailClick(); }} />
                        <ActionIcon faClass="fas fa-stethoscope" name="検診・交配" onClick={() => { setIsBreedingTooltipVisible(false); props.onBreedingClick(); }} />
                        { props.showsSingleCowIcon && (
                            <ActionIcon faClass="far fa-heart" name="分娩記録" onClick={() => { setIsBreedingTooltipVisible(false); props.onDeliveryClick(); }} />
                        )}
                    </div>)
                }>
                    {/*
                        ※カスタムコンポーネントをDOMでラップしないと、onClickが伝播しないらしく、微妙な動きをする
                        （ブラウザリロード直後の初回だけツールチップ内アイコンのクリックイベントが発生しない）
                        https://github.com/react-component/tooltip/issues/24
                    */}
                    <div>
                        <ActionIcon faClass="fas fa-heart" name="繁殖記録" />
                    </div>
                </ToolTip>
                )}
                { //未加入牧場のときは、移動だけ、権限ではじくことができないのでチェックしておく
                  canEditCow && isOfficial && props.isActive && (
                    <ActionIcon faClass="fas fa-warehouse" name="移動" onClick={props.onLocationClick} />
                )}
                { canFeeding && props.isActive && (
                <ToolTip overlayClassName="icon-tooltip" placement="top" trigger={['click']} visible={isFeedingTooltipVisible}
                    onVisibleChange={v => setIsFeedingTooltipVisible(v ?? false)}
                    overlay={
                    (<div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                        <ActionIcon faClass="fab fa-pagelines" name="えさやり" onClick={() => { setIsFeedingTooltipVisible(false); props.onFeedingClick(); }} />
                        { props.onBulkFeedingClick && (
                            <ActionIcon faClass="fas fa-warehouse" name="一括入力" onClick={() => { setIsFeedingTooltipVisible(false); props.onBulkFeedingClick!(); }} />
                        )}
                        <ActionIcon faClass="far fa-trash-alt" name="食べ残し" onClick={() => { setIsFeedingTooltipVisible(false); props.onFeedingLeftClick(); }} />
                    </div>)
                }>
                    <div>
                        <ActionIcon faClass="fab fa-pagelines" name="えさ記録" />
                    </div>
                </ToolTip>
                )}
                { canTreat && props.isActive && (
                <ToolTip overlayClassName="icon-tooltip" placement="top" trigger={['click']} visible={isSymptomTooltipVisible}
                    onVisibleChange={v => setIsSymptomTooltipVisible(v ?? false)}
                    overlay={
                    (<div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                        <ActionIcon faClass="fas fa-briefcase-medical" name="体調・治療" onClick={() => { setIsSymptomTooltipVisible(false); props.onSymptomClick(); }} />
                        { props.onBulkTreatClick && (
                            <ActionIcon faClass="fas fa-warehouse" name="一括治療" onClick={() => { setIsSymptomTooltipVisible(false); props.onBulkTreatClick!(); }} />
                        )}
                        <ActionIcon faClass="fas fa-syringe" name="予防" onClick={() => { setIsSymptomTooltipVisible(false); props.onPreventionClick(); }} />
                    </div>)
                }>
                    <div>
                        <ActionIcon faClass="fas fa-notes-medical" name="診療・予防" />
                    </div>
                </ToolTip>
                )}
                { canBrowseInside && props.isActive && (
                    <ActionIcon faClass="far fa-calendar-plus" name="予定の登録" onClick={props.onScheduleClick} />
                )}
                { canRefBalanceCow && (
                <ToolTip overlayClassName="icon-tooltip" placement="topLeft" trigger={['click']}
                    overlay={
                    (<div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                        { props.showsSellMilk && hasBreedingContract && props.isActive &&(
                            <ActionIcon faClass="fas fa-glass-whiskey" name="乳代売上" onClick={props.onSellMilkClick} />
                        )}
                        { props.isActive && (
                            <ActionIcon faClass="fas fa-truck" name="個体販売" onClick={props.onSellCowClick} />
                        )}
                        { props.isActive && (
                            <ActionIcon faClass="fas fa-wallet" name="収入・損失" onClick={props.onOtherPlCowClick} />
                        )}
                        <ActionIcon faClass="fas fa-chart-bar" name="グラフ" onClick={props.onBalanceClick} />
                    </div>)
                }>
                    <div>
                        <ActionIcon faClass="fas fa-yen-sign" name="個体収支" />
                    </div>
                </ToolTip>
                )}
                { canRefBalanceRanch
                    && (props.onBalanceRanchClick || props.onRanchEventClick || props.onSellRanchMilkClick || props.onOtherPlRanchClick) && (
                <ToolTip overlayClassName="icon-tooltip" placement="topLeft" trigger={['click']}
                    overlay={
                    (<div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                        { props.showsSellMilk && props.onSellRanchMilkClick && hasBreedingContract && props.isActive && (
                            <ActionIcon faClass="fas fa-glass-whiskey" name="乳代売上" onClick={props.onSellRanchMilkClick} />
                        )}
                        { props.onOtherPlRanchClick && (
                            <ActionIcon faClass="fas fa-wallet" name="収入・損失" onClick={props.onOtherPlRanchClick} />
                        )}
                        { props.onRanchEventClick && props.isActive && (
                            <ActionIcon faClass="fas fa-history" name="履歴" onClick={props.onRanchEventClick} />
                        )}
                        { props.onBalanceRanchClick && (
                            <ActionIcon faClass="fas fa-chart-bar" name="グラフ" onClick={props.onBalanceRanchClick} />
                        )}
                    </div>)
                }>
                    <div>
                        <ActionIcon faClass="fas fa-yen-sign" name="牧場収支" />
                    </div>
                </ToolTip>
                )}
                { canRefReport
                    && (props.onReportSellCowClick || props.onReportDeliveryClick || props.onReportDynamicsClick) && (
                <ToolTip overlayClassName="icon-tooltip" placement="topLeft" trigger={['click']}
                    overlay={
                    (<div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                        { props.onReportSellCowClick && (
                            <ActionIcon faClass="fas fa-truck" name="出荷統計" onClick={props.onReportSellCowClick} />
                        )}
                        { hasBreedingContract && props.onReportDeliveryClick && (
                            <ActionIcon faClass="far fa-heart" name="繁殖統計" onClick={props.onReportDeliveryClick} />
                        )}
                        { props.onReportDynamicsClick && (
                            <ActionIcon faClass="fas fa-chart-pie" name="動態統計" onClick={props.onReportDynamicsClick} />
                        )}
                    </div>)
                }>
                    <div>
                        <ActionIcon faClass="fas fa-chart-bar" name="統計" />
                    </div>
                </ToolTip>
                )}
                { canBrowseInside && props.isActive && (
                    <ActionIcon faClass="fas fa-clipboard-list" name="プログラム" onClick={props.onProgramClick} />
                )}
                { showsGrowth && showsSensor ? (
                    <ToolTip overlayClassName="icon-tooltip" placement="topLeft" trigger={['click']}
                        overlay={
                        (<div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                            <ActionIcon faClass="fas fa-weight" name="身体測定" onClick={props.onGrowthClick} />
                            <ActionIcon faClass="fas fa-temperature-high" name="チップ体温" onClick={props.onReportSensorClick} />
                        </div>)
                    }>
                        <div>
                            <ActionIcon faClass="fas fa-tachometer-alt" name="計測" />
                        </div>
                    </ToolTip>
                ) : showsGrowth ? (
                    <ActionIcon faClass="fas fa-weight" name="身体測定" onClick={props.onGrowthClick} />
                ) : showsSensor ? (
                    <ActionIcon faClass="fas fa-temperature-high" name="チップ体温" onClick={props.onReportSensorClick} />
                ) : (
                    <></>
                )}
                { props.showsBreedingGroup && canBreeding && props.isActive && (
                <ToolTip overlayClassName="icon-tooltip" placement="topLeft" trigger={['click']}
                    overlay={
                    (<div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                        <ActionIcon faClass="fas fa-circle" name="体内受精卵" onClick={props.onSovClick} />
                        <ActionIcon faClass="fas fa-slash" name="OPU" onClick={props.onOpuClick} />
                        { props.showsSingleCowIcon && (<>
                            <ActionIcon faClass="far fa-dot-circle" name="媒精" onClick={props.onIvfClick} />
                            <ActionIcon faClass="fas fa-circle-notch" name="培養結果" onClick={props.onIvfEggClick} />
                        </>)}
                    </div>)
                }>
                    <div>
                        <ActionIcon faClass="far fa-circle" name="採卵" />
                    </div>
                </ToolTip>
                )}
                { license?.canCertificate && isPC && canRefTreatName && (
                    <ToolTip overlayClassName="icon-tooltip" placement="topLeft" trigger={['click']}
                        overlay={
                        (<div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                            <ActionIcon faClass="fas fa-syringe" name="ワクチン" onClick={() => props.onCertClick("vaccine")} />
                            { canRefTreatPrice && isClinicUser && (<>
                                <ActionIcon faClass="fas fa-briefcase-medical" name="事故通知" onClick={() => props.onCertClick("disease")} />
                                <ActionIcon faClass="far fa-clipboard" name="カルテ" onClick={() => props.onCertClick("karte")} />
                            </>)}
                        </div>)
                    }>
                        <div>
                            <ActionIcon faClass="fas fa-file-download" name="証明書" />
                        </div>
                    </ToolTip>
                )}
                { props.onImportClick && hasRobotImportContract && isPC && canRefBalanceCow && (
                    <Tooltip overlayClassName="icon-tooltip" placement="topLeft" trigger={['click']}
                        overlay={
                        (<div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                            <ActionIcon faClass="fas fa-door-open" name="ロボット" onClick={() => props.onImportClick!("robot")} />
                        </div>)
                    }>
                        <div>
                            <ActionIcon faClass="fas fa-file-import" name="データ取込" />
                        </div>
                    </Tooltip>
                )}
            </div>


            { scrollRight > 2.0 && (
                <span className="action-icon-bar-arrow r" style={{ position:"absolute", top:arrowTop, right:arrowRight }}
                    onClick={()=>onArrowClick("r")}>
                    <i className="fas fa-caret-right"></i>
                </span>
            )}
        </div>
    );
}