import React from 'react'
import RootStore from './RootStore'
import { useLocalStore } from 'mobx-react'


let rootStore = new RootStore()
let StoreContext = React.createContext(rootStore)

export const StoreProvider = ({ children }) => (
  <StoreContext.Provider value={rootStore}>
    {children}
  </StoreContext.Provider>
)

export const withContext = (Component) => {
  return function ConnectedComponent(props) {
    return (
      <StoreContext.Consumer>
        {value => <Component {...props} rootStore={value}/>}
      </StoreContext.Consumer>
    );
  }
}

export const useRootStore = () => React.useContext(StoreContext)

export * from "./RootStore";
