import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { RequiredNumInput } from '../../components/parts/num-input';
import classnames from 'classnames';
import pgStyles from './program-management.module.css';
import { LMT } from '../../config/constant';

interface MyProps {
    onClose: () => void;
    original?: { name: string, distance_km: number };
    onSubmit: (name:string, distance: number) => void;
    onDelete: () => void;
}

export const VisitingRanchFromEditPopup = (props: MyProps) => {
    const [ name, setName ] = useState("");
    const [ distance, setDistance ] = useState(0);

    useEffect(() => {
        setName(props.original?.name ?? "");
        setDistance(props.original?.distance_km ?? 0);

    }, [ props.original ])

    return (
        <div>
            <Modal isOpen={true} style={{ maxWidth: "320px", margin: "auto" }}>
                <ModalHeader toggle={props.onClose}>往診元</ModalHeader>
                <ModalBody>
                    <div className={classnames("form-group", pgStyles["item-modal-block"])}>
                        <label className="col-form-label">名前</label>
                        <div className={pgStyles.content}>
                            <input className="form-control" type="text" value={name} onChange={e => setName(e.target.value)} />
                        </div>
                    </div>
                    <div className={classnames("form-group", pgStyles["item-modal-block"])}>
                        <label className="col-form-label">往診先まで</label>
                        <div className={pgStyles.content} style={{ display:"flex", flexWrap:"nowrap", alignItems:"center" }}>
                            <RequiredNumInput
                                style={{ width:"100px" }}
                                step={LMT.VISIT_FEE.DISTANCE_STEP} max={LMT.VISIT_FEE.DISTANCE_MAX} min={LMT.VISIT_FEE.DISTANCE_MIN}
                                value={distance} onChange={setDistance}
                            />
                            <span className="m-l-5">km</span>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className="modal-footer-fix">
                    <ExecutionButton type="decide" onClick={() => props.onSubmit(name, distance) } disabled={name === ""} />
                    { props.original != null && (
                        <ExecutionButton type="delete" onClick={props.onDelete} />
                    )}
                </ModalFooter>
            </Modal>
        </div>
    )
}