import React from 'react';
import { ICowNameInfo } from "./cows-popup";

export function CowToDispInfo(cow: ICowNameInfo, includesName = true) {
    const no = (cow.local_no == null || cow.local_no === "") ? `(${cow.trace_id?.slice(5,9) ?? ""})` : cow.local_no;
    if (!includesName) return no;
    return no + " " + (cow.name ?? "");
};

export function RenderCowNameInfo(cow: ICowNameInfo) {
    const no = CowToDispInfo(cow, false);
    return (<>
        <span style={{ color: "red" }}>{no}</span>
        {" " + (cow.name ?? "")}
    </>)
}

export function LocalNoOrRepNo(cow: ICowNameInfo) {

    if (cow.local_no != null && cow.local_no !== "") return cow.local_no;
    return cow.trace_id.slice(5,9);
}

export function RenderFullTraceId(traceId: string, baseStyle: React.CSSProperties, repStyle: React.CSSProperties) {
    if (traceId.length !== 10) {
        return <span style={baseStyle}>{traceId}</span>
    }

    return (<>
        <span style={baseStyle}>{traceId.slice(0, 5)}</span>
        <span style={{...baseStyle, color: "red", ...repStyle }}>{traceId.slice(5, 9)}</span>
        <span style={baseStyle}>{traceId.slice(9, 10)}</span>
    </>)
}