import React from 'react';
import styles from './sellcow.module.css';
import { EditingCarcass } from './sellcow-single';
import { ar } from '../../config/util';
import { OptionalNumInput } from '../../components/parts/num-input';
import { LMT } from '../../config/constant';

interface CarcassInputProps {
    data: EditingCarcass;
    onChange: (data: EditingCarcass)=>void;
}

const DEFECTS = ["ア","イ","ウ","エ","オ","カ"] as const;
type DefectKey = typeof DEFECTS[number];
const DEFECT: { [key in DefectKey]: number } = {
    ア: 0x01,
    イ: 0x02,
    ウ: 0x04,
    エ: 0x08,
    オ: 0x10,
    カ: 0x20
};

export const CarcassInput: React.FC<CarcassInputProps> = (props) => {
    const rowClass = "form-group row treat-write-row";
    const rowHeaderClass = "col-form-label col-md-4 col-xs-4 text-lg-right";

    const onDefectChange = (bit: number, checked: boolean) => {
        const val = checked ? (props.data.defect + bit) : (props.data.defect - bit);
        props.onChange({ ...props.data, defect: val });
    }

    const renderSelect = (min: number, max: number, value:number|undefined, onChange:(val:number|undefined) => void) => (
        <select className="form-control" value={value}
                onChange={e => onChange(e.target.value === "" ? undefined : Number(e.target.value)) }>
            <option value="">未選択</option>
            { ar.intRange(min, max - min + 1).map(n => (
                <option key={n} value={n}>{n}</option>
            ))}
        </select>
    )

    return (<>
        <div className={rowClass}>
            <label className={rowHeaderClass}>ロース芯面積</label>
            <div className={styles["col-input-num"]}>
                <OptionalNumInput
                    min={LMT.SELLCOW.LOIN_MIN} max={LMT.SELLCOW.LOIN_MAX} step={LMT.SELLCOW.LOIN_STEP}
                    onChange={n => props.onChange({...props.data, yield_loin: n})}
                    value={props.data.yield_loin}
                />cm2
            </div>
        </div>
        <div className={rowClass}>
            <label className={rowHeaderClass}>バラ厚さ</label>
            <div className={styles["col-input-num"]}>
                <OptionalNumInput
                    min={LMT.SELLCOW.RIB_MIN} max={LMT.SELLCOW.RIB_MAX} step={LMT.SELLCOW.RIB_STEP}
                    onChange={n => props.onChange({...props.data, yield_rib: n})}
                    value={props.data.yield_rib}
                />cm
            </div>
        </div>
        <div className={rowClass}>
            <label className={rowHeaderClass}>皮下脂肪</label>
            <div className={styles["col-input-num"]}>
                <OptionalNumInput
                    min={LMT.SELLCOW.FAT_MIN} max={LMT.SELLCOW.FAT_MAX} step={LMT.SELLCOW.FAT_STEP}
                    onChange={n => props.onChange({...props.data, yield_fat: n})}
                    value={props.data.yield_fat}
                />cm
            </div>
        </div>
        <div className={rowClass}>
            <label className={rowHeaderClass}>歩留基準</label>
            <div className={styles["col-input-num"]}>
                <OptionalNumInput
                    min={LMT.SELLCOW.BASIS_MIN} max={LMT.SELLCOW.BASIS_MAX} step={LMT.SELLCOW.BASIS_STEP}
                    onChange={n => props.onChange({...props.data, yield_basis: n})}
                    value={props.data.yield_basis}
                />
            </div>
        </div>
        <div className={rowClass}>
            <label className={rowHeaderClass}>BMS</label>
            <div>
                { renderSelect(1, 12, props.data.quality_bms, val => props.onChange({ ...props.data, quality_bms: val }))}
            </div>
        </div>
        <div className={rowClass}>
            <label className={rowHeaderClass}>肉色BCS</label>
            <div>
                { renderSelect(1, 7, props.data.quality_bcs, val => props.onChange({ ...props.data, quality_bcs: val }))}
            </div>
        </div>
        <div className={rowClass}>
            <label className={rowHeaderClass}>きめ締まり</label>
            <div>
                { renderSelect(1, 5, props.data.quality_texture, val => props.onChange({ ...props.data, quality_texture: val }))}
            </div>
        </div>
        <div className={rowClass}>
            <label className={rowHeaderClass}>脂肪の質</label>
            <div>
                { renderSelect(1, 5, props.data.quality_fat, val => props.onChange({ ...props.data, quality_fat: val }))}
            </div>
        </div>
        <div className={rowClass}>
            <label className={rowHeaderClass}>オレイン酸含量</label>
            <div className={styles["col-input-num"]}>
                <OptionalNumInput
                    min={LMT.SELLCOW.OLEIN_MIN} max={LMT.SELLCOW.OLEIN_MAX} step={LMT.SELLCOW.OLEIN_STEP}
                    onChange={n => props.onChange({...props.data, oleic_acid: n})}
                    value={props.data.oleic_acid}
                />%
            </div>
        </div>
        <div className={rowClass} style={{flexWrap:"nowrap"}}>
            <label className={rowHeaderClass}>瑕疵</label>
            <div style={{ display:"flex", flexWrap: "wrap", justifyContent: "space-around" }}>
                { DEFECTS.map((d,i) => (
                    <div key={i} className="checkbox checkbox-css" style={{marginRight:"20px"}}>
                        <input type="checkbox" id={"chkDefect_" + i} checked={(props.data.defect & DEFECT[d]) !== 0}
                            onChange={e => onDefectChange(DEFECT[d], e.target.checked)}
                        />
                        <label htmlFor={"chkDefect_" + i}>{d}</label>
                    </div>
                )) }
            </div>
        </div>
        <div className={rowClass} style={{flexWrap:"nowrap"}}>
            <label className={rowHeaderClass}>褒賞</label>
            <div>
                <textarea className="form-control" rows={2} maxLength={LMT.SELLCOW.REWARD_LEN}
                    value={props.data.reward}
                    onChange={e => props.onChange({ ...props.data, reward: e.target.value })}
                />
            </div>
        </div>
    </>);
}