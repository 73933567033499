import React from 'react';
import { RanchTagDto } from '../../api';
import { FreezedArray, ar } from '../../config/util';
import { pickLastNum } from './seed-label-range-input';
import { EditingTag, TagEditPopup } from '../../components/tag-edit-popup/tag-edit-popup';

type Stock = { label_no: string, tags: FreezedArray<RanchTagDto> };

interface MyProps {
    allTags: FreezedArray<RanchTagDto>;
    onClose: () => void;
    onSubmit: (changedStocks: { label_no: string, tags: EditingTag[] }[]) => void;
    stocks: FreezedArray<Stock>;
    submitType: "保存" | "確定";
    isExecuting : boolean;
}

export const SeedTagPopup = React.memo((props: MyProps) => {

    const stocksName = ar.orderBy(props.stocks, s => pickLastNum(s.label_no) ?? 0).map(s => s.label_no);

    return (
        <TagEditPopup
            targetKindName="ラベル"
            targetNames={stocksName}
            allTags={props.allTags}
            onClose={props.onClose}
            isExecuting={props.isExecuting}
            targets={props.stocks.map(s => ({ tags:s.tags, id: s.label_no }))}
            onSubmit={targets => props.onSubmit(targets.map(t => ({ tags:t.tags, label_no:t.id })))}
            submitType={props.submitType}
            targetNameOmitBorderCount={20}
            targetUnit="個"
        />
    )

})