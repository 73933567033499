import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PageSettings } from '../../config/page-settings.js';
import { IEditingFeed } from './feed-master';
import { IFeedType, IUser } from '../../stores/RootStore';
import styles from './setting.module.css';
import { OptionalNumInput, RequiredNumInput } from '../../components/parts/num-input';
import { hasRanchContract } from '../../config/contract-checker';
import { LMT } from '../../config/constant';
import { ExecutionButton } from '../../components/buttons/execution-button';

interface MyProps {
    data: IEditingFeed | null;
    feedTypeList: IFeedType[];
    onSubmit: ()=>void;
    onClose: ()=>void;
    onDelete: ()=>void;
    onNameEdited:(name:string)=>void;
    onTypeEdited:(typeCd:number)=>void;
    onUnitEdited:(unit:string)=>void;
    onUnitAmountEdited:(unit_amount:number)=>void;
    onPriceEdited:(price:number)=>void;
    onTypeDetailEdited:(typeDetailCd:number)=>void;
    onConvertStateEdited: (scale:number|undefined)=>void;
    onWashoutMeatDayEdited:(washout_meat_day?:number)=>void;
    onWashoutMilkHourEdited:(washout_milk_hour?:number)=>void;
    ranchId: number;
    user: IUser;
    isSubmitExecuting: boolean;
}
interface MyState {
}

export class FeedEditPopup extends React.Component<MyProps,MyState> {

    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        const isUnitPriceOver = (this.props.data?.unit_price ?? 0) > LMT.FEEDING.UNIT_PRICE_MAX;

        return (
            <div className="modal-wide">
                <Modal isOpen={true} centered={true}>
                    <ModalHeader toggle={this.props.onClose}></ModalHeader>
                    <ModalBody style={{ overflowY: "auto", maxHeight: "calc(100vh - 150px)" }}>
                        <div>
                            <div className="form-group" style={{display:"flex"}}>
                                <label className="col-form-label" style={{width:"80px"}}>品名</label>
                                <div>
                                    <input className="form-control" type="text" value={this.props.data?.name ?? ""}
                                            maxLength={LMT.FEEDING.NAME_LEN}
                                            onChange={(e)=>this.props.onNameEdited(e.target.value)}/>
                                </div>
                            </div>
                            <div className="form-group" style={{display:"flex"}}>
                                <label className="col-form-label" style={{width:"80px"}}>種別</label>
                                <div>
                                    <select className="form-control" value={this.props.data?.feed_type_no} style={{width:"160px"}}
                                            onChange={ (e)=>this.props.onTypeEdited(Number(e.target.value))}>
                                        <option key={0} value={0}>選択</option>
                                        {
                                            this.props.feedTypeList.map((tp,i) => (
                                                <option key={tp.feed_type_no} value={tp.feed_type_no}>{tp.name}</option>
                                            ))
                                        }
                                    </select>
                                    <div style={{height:"40px", marginTop:"2px"}}>
                                        {(this.props.data?.typeDetailList?.length ?? 0) > 0 && (
                                        <select className="form-control" value={this.props.data?.feed_type_detail_no ?? 0} style={{width:"160px"}}
                                                onChange={ (e)=>this.props.onTypeDetailEdited(Number(e.target.value))}>
                                        {
                                            this.props.data?.typeDetailList.map((td,i) => (
                                                <option key={td.feed_type_detail_no} value={td.feed_type_detail_no}>{td.name}</option>
                                            ))
                                        }
                                        </select>)}
                                        {
                                        this.props.data?.unit_raw != null && (
                                            <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                                                希釈倍率
                                                <OptionalNumInput
                                                       min={LMT.FEEDING.CONVERT_SCALE_MIN}
                                                       max={LMT.FEEDING.CONVERT_SCALE_MAX}
                                                       step={LMT.FEEDING.CONVERT_SCALE_STEP}
                                                       style={{width:"54px", marginLeft:"4px"}}
                                                       value={this.props.data.convert_scale}
                                                       onChange={val => this.props.onConvertStateEdited(val)}
                                                />
                                                倍
                                                {(this.props.data.convert_scale ?? 0) > 0 && (
                                                    <span style={{fontSize:"0.75rem"}}>（1{this.props.data.unit}={(1000/(this.props.data.convert_scale??0)).toFixed(2)}{this.props.data.unit_raw}）</span>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group" style={{display:"flex"}}>
                                <label className="col-form-label" style={{width:"80px"}}>単位</label>
                                <div>
                                    <input className="form-control" type="text" maxLength={10} 
                                        style={{width:"70px", marginLeft:"2px", marginRight:"2px"}}
                                        value={this.props.data?.unit ?? ""}
                                        onChange={e => this.props.onUnitEdited(e.target.value)}
                                        disabled={this.props.data?.unit_raw != null}
                                    />
                                </div>

                            </div>
                            <div className="form-group" style={{display:"flex"}}>
                                <label className="col-form-label" style={{width:"80px"}}>価格</label>
                                <div>
                                    <div style={{display:"flex", alignItems:"center"}}>
                                        <RequiredNumInput min={0.1} step={0.1} max={99999}
                                            style={{width:"54px", marginLeft:"2px", marginRight:"2px"}}
                                            value={this.props.data?.unit_amount??1}
                                            onChange={val=>this.props.onUnitAmountEdited(val)}
                                        />
                                        <span>{this.props.data?.unit_for_price ?? ""} あたり</span>
                                        <RequiredNumInput min={0} step={0.01} max={999999}
                                            style={{width:"90px", marginLeft:"2px", marginRight:"2px"}}
                                            value={this.props.data?.total_price??0}
                                            onChange={val=>this.props.onPriceEdited(val)}
                                        />
                                        <span>円</span>
                                    </div>
                                    <div style={ isUnitPriceOver ? { color: "red" } : undefined}>
                                        <span>(1{this.props.data?.unit_for_price ?? ""} あたり {this.props.data?.unit_price}円)</span>
                                    </div>
                                </div>                                
                            </div>
                            <div className="form-group" style={{display:"flex"}}>
                                <div style={{width:"120px", display:"flex", justifyContent: "space-between"}}>
                                    <label className="col-form-label">出荷制限期間</label>
                                    <label className="col-form-label">肉</label>
                                </div>
                                <div style={{flex:"1"}}>
                                    <div style={{display:"flex", alignItems: "center"}}>
                                        <OptionalNumInput min={0} max={LMT.WASHOUT.MEAT_DAY_MAX} step={1} 
                                            onChange={n => this.props.onWashoutMeatDayEdited(n)}
                                            value={this.props.data?.washout_meat_day}
                                            className={styles["washout-input"]}
                                        />日
                                    </div>
                                </div>
                            </div>
                            { hasRanchContract("BREEDING", this.props.ranchId, this.props.user) &&
                                <div className="form-group" style={{display:"flex"}}>
                                    <div style={{width:"120px", display:"flex", justifyContent: "space-between"}}>
                                        <label className="col-form-label">&nbsp;</label>
                                        <label className="col-form-label">乳</label>
                                    </div>
                                    <div style={{flex:"1"}}>
                                        <div style={{display:"flex", alignItems: "center"}}>
                                            <OptionalNumInput min={0} max={LMT.WASHOUT.MILK_HOUR_MAX} step={1} 
                                                onChange={n => this.props.onWashoutMilkHourEdited(n)}
                                                value={this.props.data?.washout_milk_hour}
                                                className={styles["washout-input"]}
                                            />時間
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </ModalBody>
                    <ModalFooter className="modal-footer-fix">
                        <ExecutionButton type="save" onClick={this.props.onSubmit} disabled={this.props.isSubmitExecuting || isUnitPriceOver} />
                        { this.props.data && !this.props.data.isNew && (
                            <ExecutionButton type="delete" onClick={this.props.onDelete} disabled={this.props.isSubmitExecuting} />
                        )}
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}