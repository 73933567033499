import queryString from 'query-string';
import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { A, LMT, TIMEPRESETS, SYMPTOM_STATUS, EVENT_TYPE_PARAM, getDefaultBenefitType, convertBenefitType } from '../../config/constant';
import { PageSettings } from '../../config/page-settings.js';
import { SymptomSeriesPopup } from './symptom-series-popup';
import { withContext, IConditionClass, IUser, IDisease, IFecesColor, IFecesState, IMedicineCategory, ITreatKind } from '../../stores';
import { TreatSelector, ITreatSelectorData, validateTreat, TREAT_VALIDATION_RESULT, convertTreatsToModifyReq, convPresetToTreatSelectorData, convScheduledPresetToTreatSelectorData } from '../../components/parts/treat-selector';
import { Collapse } from 'reactstrap';
import moment from 'moment';
import { CowDetailDto, SymptomModifyReqCondition, SympConditionDto, SympTreatDto, SymptomDto, SymptomModifyReq, SymptomApi, SymptomHistoryReq, GrowthBirthWeightDto, GrowthApi, SymptomHistoryInfoDto, TeamTreatPresetDto, ModifyReqVisit, VisitListDtoDistance, ClinicVisitFeeDto, SympDataDto, RanchSchedulePresetDto, TeamDiseaseCauseDto, DiseaseCauseDto } from '../../api';
import { ICowWatchingInfo } from '../cow/watching-icon';
import { NextSchedule, toNextScheduleReq } from '../../components/next-schedule/next-schedule-selector';
import { IEventWriteLocationState, historyLocation, IEventWriteParamCow } from '../../config/history-location-builder';
import { ScheduleFinder } from '../schedule/schedule-finder';
import { AppState } from '../../app';
import { EVENT_KIND } from '../../config/event-kind';
import { GrowthInputPopup } from './growth-input-popup';
import { IGrowthSelectorData } from '../growth/growth-selector';
import { hasRanchAuth, hasTeamAuth } from '../../config/auth-checker';
import { V3DateTime } from '../../components/parts/v3-date-time-picker';
import { CommonUtil, generateKey, FreezedArray, ar } from '../../config/util';
import { storeSymptomNameList, loadSymptomNameList, calcBreathScore, SymptomPhysicalInput, EditingPhisical, ISymptomName, storeNoticeList, loadNoticeList } from './symptom-physical-input';
import { canNextSchedule, SymptomStatusAndScheduleInput, defaultSchedule } from './symptom-status-and-schedule-input';
import styles from './symptom.module.css';
import classnames from 'classnames';
import { PREVIOUS_SYMPTOMS_DAYS, convertPreviousSymptomsForSelect, IPreviousSymptom } from './previous-symptoms-converter';
import { DiseaseInputItem } from './disease-name-input';
import { IconLink } from '../../components/parts/icon-link';
import { TreatPresetSelectPopup } from '../../components/parts/treat-preset-select-popup';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { DIALOG_BUTTONS } from '../../components/form/form-dialog';
import { UserTeams } from '../../config/user-teams';
import { VisitFeeSelectPopup, ModifyReqVisitWithName } from './visit-fee-select-popup';
import { PreloadedProgramSelectPopupProps, showPreloadedProgramSelectDialog, PreloadedProgramSelectPopup } from '../program/preloaded-program-select-popup';
import { resetMedicines, resetTreatItems, usePresetsWithBothTeamMedicines, useVisitFeesForRanch, useDiseaseRelationsForInput } from '../../stores/fetcher';
import { FetchWaiter, FetchError } from '../../components/content/fetch-state';
import { Communicator } from '../../api/communicator';
import { ModifyReqTreat_fix } from '../../api/api-fix';
import { SingleCowHeader } from 'components/cow-header/single-cow-header';
import { MultiCowHeader } from 'components/cow-header/multi-cow-header';
import { getCowWithCache, resetCow } from 'stores/fetcher_cow';

type EditingSymptom = EditingPhisical & {
    watched_at: Date;
    isEpidemic: boolean;
}

export interface ISymptomForDoctor {
    diseases: Array<DiseaseInputItem>;
    notice: string;
    conditions: SymptomModifyReqCondition[];
}

interface MyProps extends BaseProps<{id?:string},{},IEventWriteLocationState|undefined> {
}

interface MyState {
    cow_top: boolean;
    cow_ids: Readonly<number[]>;
    cow?: CowDetailDto;
    ranchId: number;
    clinicId: number | undefined;
    prev_first_id?: number;
    symptomNameList: ISymptomName[];
    noticeList: string[];
    original?: SympDataDto;
    initialHistories?: FreezedArray<SymptomHistoryInfoDto>;
    preloadedProgram?: PreloadedProgramSelectPopupProps;    
    executing: boolean;
}

class SymptomWrite extends Base<MyProps, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            ranchId: 0,
            clinicId: undefined,
            cow_top: false,
            cow_ids: [],
            symptomNameList: [],
            noticeList: [],
            executing: false,
        }
    }

    componentDidMount() {
        if (this.handleNotAllowAccess(undefined,["TREAT_EDIT"],[])) {
            return;
        }
        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        this.init();
    }

    async init() {
        const _mayId = parseInt(this.props.match.params.id ?? "");
        const edit_symptom_id = isNaN(_mayId) ? undefined : _mayId;
        const queryData = this.parseQuery(this.props.history.location.search);

        //validate
        if (edit_symptom_id != null) {
            if (queryData.cow_ids.length >= 2 || queryData.prev_first_id != null) {
                console.error("invalid location param", edit_symptom_id, queryData);
                return;
            }
        }
        if (edit_symptom_id == null) {
            if (queryData.cow_ids.length === 0) {
                console.error("invalid location param: no cow");
                return;
            }
        }

        this.context.handleSetHeader({ title:`体調・治療を${ edit_symptom_id != null ? "編集" : "入力"}` });

        const ranchId = this.props.rootStore.cur_ranch_id;
        const clinicId = this.props.rootStore.getClinicIdForMaster();

        const nameList = loadSymptomNameList();
        const noticeList = loadNoticeList(this.props.rootStore.user.id);

        let original: SympDataDto | undefined;
        let cow: CowDetailDto | undefined;
        let histories: SymptomHistoryInfoDto[] | undefined;
        
        if (edit_symptom_id != null) {
            //編集時
            original = await this.loadSymptom(ranchId, edit_symptom_id, true);
            if (original == null) return;

            if (this.navToRanchTopIfSelfEditOnly("SYMPTOM", original.watched_by)) return;

            if (queryData.cow_ids.length === 1 && queryData.cow_ids[0] !== original.cow_id) {
                console.error("cow_id unmatch", queryData.cow_ids, original.cow_id);
                return;
            }

            cow = await this.loadCowInfo(ranchId, original.cow_id);
            if (cow == null) return;

            if (original.first_id != null) {
                histories = await this.loadHistories(ranchId, cow.cow_id, moment(original.watched_at).toDate(), original.first_id);
                if (histories == null) return;
            }

        } else {
            //新規
            if (queryData.cow_ids.length === 1) {
                cow = await this.loadCowInfo(ranchId, queryData.cow_ids[0]);
                if (cow == null) return;

                if (queryData.prev_first_id != null) {
                    histories = await this.loadHistories(ranchId, cow.cow_id, new Date(), queryData.prev_first_id);
                    if (histories == null) return;
                }
            }
        }

        this.setState({
            ranchId,
            clinicId,
            symptomNameList: nameList,
            noticeList,
            cow,
            cow_ids: cow != null ? [ cow.cow_id ] : queryData.cow_ids,
            cow_top: queryData.cow_top,
            original,
            prev_first_id: queryData.prev_first_id,
            initialHistories: histories,
        });
    }

    parseQuery(query: string): { cow_top: boolean, prev_first_id: number|undefined, cow_ids: number[] } {
        const values = queryString.parse(query);

        const cow_top = (values.cow_top != null) && (Number(values.cow_top) === 1);
        let prev_first_id = values.first_id !== undefined ? Number(values.first_id) : undefined;  // 初診ID(診療記録確認画面より遷移してきた場合のみ)
        if (prev_first_id != null && isNaN(prev_first_id)) {
            prev_first_id = undefined;
        }

        if (values.param == null) {
            return { cow_top: cow_top, prev_first_id: prev_first_id, cow_ids:[] };
        }

        const ids = Array.isArray(values.param) ? values.param : values.param.split(",");
        const cow_ids = ids.map(id => parseInt(id)).filter(id => !isNaN(id));

        return { cow_top, prev_first_id, cow_ids };
    }

    private getCowBirthInfo = async (cowId: number) => {
        this.context.handleSetIsLoading(true);
        const req = { ranch_id: this.state.ranchId, cow_ids:[cowId] };
        //失敗してもエラー表示なし
        const res = await this.comm().send((await GrowthApi()).getBirthWeightUsingPOST(req), { showsErrorMessage: false });
        this.context.handleSetIsLoading(false);

        return res.data?.[0];
    }

    onSave = async (values:{
        symptom: EditingSymptom,
        treats: ModifyReqTreat_fix[],
        firstId: number | undefined,
        memo: string,
        plans: NextSchedule[],
        status: number | undefined,
        growth: IGrowthSelectorData | undefined,
        visitFee: ModifyReqVisit | undefined
    }) => {

        this.setState({ executing: true });

        const registered = await this.api_postSymptomWrite(values);
        if (!registered) {
            this.setState({ executing: false });
            return false;
        }
        if (values.symptom.symptom_name !== "") {
            storeSymptomNameList(this.state.symptomNameList, values.symptom.symptom_name);
        }
        if (values.symptom.notice !== "") {
            storeNoticeList(this.state.noticeList, values.symptom.notice, this.props.rootStore.user.id);
        }

        if (this.state.original == null) {
            if (this.state.cow_top) {
                this.props.history.replace(historyLocation.toCowInfo(this.state.cow_ids[0]));
            } else {
                window.history.go(-1);
            }
        } else {
            const watchedAt = moment(values.symptom.watched_at);
            this.props.history.replace(historyLocation.toCowEvent(this.state.cow_ids[0], watchedAt.format("YYYY-MM-DD"), EVENT_TYPE_PARAM.SYMPTOM));
        }
    }

    onDelete = async () => {
        if (!CommonUtil.assertNotNull(this.state.original, "original", "delete")) return false;

        const confirm = await this.context.showDialog("QUESTION", '記録を削除してよろしいですか？', DIALOG_BUTTONS.DELETE_CANCEL);
        if (confirm !== 0) return;

        this.setState({ executing:true });

        const req = {
            id: this.state.original.symptom_id,
            ranch_id: this.state.ranchId
        };

        this.context.handleSetIsLoading(true);
        const res = await this.comm().send((await SymptomApi()).deleteSymptomUsingPOST(req));
        this.context.handleSetIsLoading(false);

        if (res.result !== "OK") {
            this.setState({ executing:false });
            return false;
        }

        // アクティブ牛リストを再取得
        this.props.rootStore.fetchActiveCows(undefined, "DIFF");
        this.props.history.replace(
            historyLocation.toCowEvent(
                this.state.cow_ids[0], 
                moment(this.state.original.watched_at).format("YYYY-MM-DD"), 
                EVENT_TYPE_PARAM.SYMPTOM
            )
        );
    }

    askToBeWatching = async () => {
        const res = await this.context.showDialog("QUESTION", "「要観察」状態にしますか？", DIALOG_BUTTONS.YES_NO);
        if (res === 0) return true;
        if (res === 1) return false;
        return undefined;
    }

    askToBeUnwatching = async () => {
        const res = await this.context.showDialog("QUESTION", "「要観察」状態も解除しますか？", DIALOG_BUTTONS.YES_NO);
        if (res === 0) return true;
        if (res === 1) return false;
        return undefined;
    }

    private loadCowInfo = async (ranchId: number, cowId: number) => {
        const response = await this.comm().send(() => getCowWithCache(ranchId, cowId), { retries: true });

        return response.data;
    }

    private loadSymptom = async (ranchId: number, id: number, retries: boolean) => {
        const url = `/symptom/${id}?ranch_id=${ranchId}`;
        const response = await this.comm().send<SymptomDto>(() => this.context.postAsync(url, {}), { retries });
        return response.data?.info;
    } 

    api_postSymptomWrite = async (vals: {
        symptom: EditingSymptom,
        treats: ModifyReqTreat_fix[],
        firstId: number | undefined,
        memo: string,
        plans: NextSchedule[],
        status: number | undefined,
        growth: IGrowthSelectorData | undefined,
        visitFee: ModifyReqVisit | undefined
    }): Promise<boolean> => {

        const watchedAt = moment(vals.symptom.watched_at);

        // 要観察状態の設定
        let watching: 0 | 1 | undefined;
        if (this.state.original == null && vals.status != null) {
            let pre: ICowWatchingInfo | undefined;
            if (this.state.cow_ids.length === 1) {
                //キャッシュのものを取り直しておく
                const cow = await this.loadCowInfo(this.state.ranchId, this.state.cow_ids[0]);
                pre = cow;
            }

            if (canNextSchedule(vals.status)) {
                if (pre == null || pre.watching === 0) {
                    const watchRes = await this.askToBeWatching();
                    if (watchRes == null) return false;
                    watching = watchRes === true ? 1 : undefined;
                }   
            } else {
                if (pre == null || pre.watching === 1) {
                    const watchRes = await this.askToBeUnwatching();
                    if (watchRes == null) return false;
                    watching = watchRes === true ? 0 : undefined;
                }
            }
        }

        // 次回予定の設定
        const plans = vals.plans.map(p => toNextScheduleReq(p, this.state.clinicId));

        const params:SymptomModifyReq = {
            symptom_id: this.state.original?.symptom_id,
            is_new: this.state.original == null ? 1 : 0,
            ranch_id: this.state.ranchId,
            cow_ids: [...this.state.cow_ids],
            watched_at: watchedAt.format("YYYY-MM-DD HH:mm"),
            comment: vals.memo,
            temperature_x10: vals.symptom.temperature_x10,
            active_score: vals.symptom.active_score,
            hungry_score: vals.symptom.hungry_score,
            heart_rate: vals.symptom.heart_rate,
            breath_count: vals.symptom.breath_count,
            breath_score: vals.symptom.breath_count == null ? undefined : calcBreathScore(vals.symptom.breath_count),
            feces_state: vals.symptom.feces_state,
            feces_color: vals.symptom.feces_color, // "rrggbb"
            first_id: vals.firstId,
            notice: vals.symptom.notice,
            diseases: vals.symptom.diseases,
            treat: vals.treats,
            condition: vals.symptom.conditions,
            symptom_name: vals.symptom.symptom_name,
            schedule_id: this.props.location.state?.schedule_id,
            status: vals.status,
            watching: watching,
            plans: vals.status != null && canNextSchedule(vals.status) ? plans : undefined,
            growths: vals.growth && Object.values(vals.growth).some(v => v != null) ? this.state.cow_ids.map(cow_id => ({ cow_id, ...vals.growth }) ) : undefined,
            visit: vals.visitFee,
            preloaded_program: { list:[], tags:[] }
        };
        //関連する予定を探す
        if (params.schedule_id == null && params.cow_ids.length === 1 && params.is_new === 1) {
            const day = watchedAt.format("YYYY-MM-DD");

            const finder = new ScheduleFinder(this.context, this.state.ranchId, this.props.rootStore.user.id);
            const scheRes = await finder.findEventRelatedSchedule(day, [ EVENT_KIND.SYMPTOM.no ], params.cow_ids[0]);

            if (scheRes.result === "cancel") return false;

            if (scheRes.result === "yes") {
                params.schedule_id = scheRes.id;
            }
        }

        //プログラム確認
        if (params.is_new === 1) {
            const cows = this.state.cow != null ? [ this.state.cow ] : this.props.location.state?.cows ?? [];

            const pgReq = await showPreloadedProgramSelectDialog(
                this.context,
                async () => this.comm().send((await SymptomApi()).getProgramsForSymptomUsingPOST({ eventReq:params, clinic_id:this.state.clinicId })),
                this.props.rootStore,
                params.ranch_id,
                this.state.clinicId,
                st => this.setState({ preloadedProgram:st }),
                cows
            );
            if (pgReq == null) return false;
            params.preloaded_program = pgReq;
        }
        
        this.context.handleSetIsLoading(true);
        const response = await this.comm().send((await SymptomApi()).modifySymptomUsingPOST(params));
        this.context.handleSetIsLoading(false);

        if (response.result === "OK") {
            const treatTeamId = this.state.clinicId ?? this.state.ranchId;
            //薬マスタを再取得しておく
            resetMedicines(treatTeamId, false);
            //処置作成があれば再取得
            if (params.treat.some(t => t.new_treat_item_name != null)) {
                resetTreatItems(treatTeamId, false);
            }

            //要観察の更新時
            if (params.watching != null) {
                params.cow_ids.forEach(id => resetCow(id, false));
            }
            
            // アクティブ牛リストを再取得
            await this.props.rootStore.fetchActiveCows(undefined, "DIFF");
            return true;

        } else {
            return false;
        }
    }


    private getHistoryList = async (watchedAt: Date) => {
        if (this.state.cow_ids.length !== 1) {
            console.error("history call for multi cows", this.state.cow_ids);
            return;
        }
        this.context.handleSetIsLoading(true);
        const res = await this.loadHistories(this.state.ranchId, this.state.cow_ids[0], watchedAt, this.state.prev_first_id ?? this.state.original?.first_id);
        this.context.handleSetIsLoading(false);

        return res;
    }


    private loadHistories = async (ranchId: number, cowId: number, watchedAt: Date, firstId: number | undefined) => {

        const req: SymptomHistoryReq = {
            cow_id: cowId,
            ranch_id: ranchId,
            watched_at: moment(watchedAt).format("YYYY-MM-DD"),
            first_id: firstId,
            days: PREVIOUS_SYMPTOMS_DAYS,

        };
        const res = await this.comm().send((await SymptomApi()).getHistoryUsingPOST(req));
        return res.data?.info;
    }



    render() {
        //初期化前
        if (this.state.cow_ids.length === 0) return <FetchWaiter />

        const options = this.props.rootStore.options;

        return (
            <div className="page-root">
                <SymptomWriteContent
                    user={this.props.rootStore.user}
                    ranchId={this.state.ranchId}
                    clinicId={this.state.clinicId}
                    paramCows={this.props.location.state?.cows}
                    cow={this.state.cow}
                    cow_ids={this.state.cow_ids}
                    original={this.state.original}
                    firstId={this.state.prev_first_id}

                    symptom_name_list={this.state.symptomNameList}
                    noticeList={this.state.noticeList}

                    initialHistories={this.state.initialHistories}
                    schedulePreset={this.props.location.state?.preset}

                    showAlert={this.context.showAlert}
                    showToast={this.context.showToast}
                    comm={this.comm()}
                    executing={this.state.executing}

                    diseases={options.disease}
                    causes={options.causes}
                    fecesColors={options.feces_color}
                    fecesStates={options.feces_state}
                    treatKinds={options.treat_kind}
                    medicineCategories={options.medicine_category}
                    
                    onDelete={this.onDelete}
                    onSave={this.onSave}
                
                    loadSymptom={id => this.loadSymptom(this.state.ranchId, id, false)}
                    getHistoryList={this.getHistoryList}
                    getCowBirthInfo={this.getCowBirthInfo}
                />
                { this.state.preloadedProgram && (
                    <PreloadedProgramSelectPopup
                        {...this.state.preloadedProgram}
                    />
                )}
            </div>
        )
    }
}

export default withRouter(withContext(SymptomWrite));

interface SymptomWriteContentProps {
    user: IUser;
    ranchId: number;
    clinicId: number | undefined;
    paramCows: IEventWriteParamCow[] | undefined;
    cow: CowDetailDto | undefined;
    cow_ids: Readonly<number[]>;
    original: SympDataDto | undefined;
    firstId: number | undefined;
    symptom_name_list: ISymptomName[];
    noticeList: string[];
    /**
     * 編集対象の記録、または初診IDとして指定されて新規登録する際の初期状態で選択可能な前回診リスト
     */
    initialHistories: FreezedArray<SymptomHistoryInfoDto> | undefined;
    schedulePreset: RanchSchedulePresetDto | undefined;
    showAlert: (err:string) => void;
    comm: Communicator;
    showToast: (msg:string) => void;
    executing: boolean;

    diseases: FreezedArray<IDisease>;
    causes: FreezedArray<DiseaseCauseDto>;
    fecesColors: FreezedArray<IFecesColor>;
    fecesStates: FreezedArray<IFecesState>;
    treatKinds: FreezedArray<ITreatKind>;
    medicineCategories: FreezedArray<IMedicineCategory>;
    
    onDelete: () => void;
    onSave: (values:{
        symptom: EditingSymptom,
        treats: ModifyReqTreat_fix[],
        firstId: number | undefined,
        memo: string,
        plans: NextSchedule[],
        status: number | undefined,
        growth: IGrowthSelectorData | undefined,
        visitFee: ModifyReqVisit | undefined
    }) => void;

    loadSymptom: (id: number) => Promise<SympDataDto | undefined>;
    getHistoryList: (watchedAt:Date) => Promise<SymptomHistoryInfoDto[] | undefined>;
    getCowBirthInfo: (cowId: number) => Promise<GrowthBirthWeightDto | undefined>;
}

const SymptomWriteContent = (props: SymptomWriteContentProps) => {
    const treatTeamId = props.clinicId ?? props.ranchId;

    const mPresets = usePresetsWithBothTeamMedicines(props.ranchId, props.clinicId);
    const mVisiting = useVisitFeesForRanch(props.ranchId, props.clinicId);
    const mDiseases = useDiseaseRelationsForInput(treatTeamId);

    const [ growthInputPopup, setGrowthInputPopup ] = useState(false);
    const [ seriesPopupFirstId, setSeriesPopupFirstId ] = useState<number>();
    const [ symptom, setSymptom ] = useState<Readonly<EditingSymptom>>({ watched_at: new Date(), diseases:[], conditions: [], symptom_name: "", isEpidemic:false, notice: "" });
    const [ treats, setTreats ] = useState<Array<ITreatSelectorData & { key: string }>>([]);
    const [ anyInvalidInput, setAnyInvalidInput ] = useState(false);
    const [ memo, setMemo ] = useState("");
    const [ prevSymptomList, setPrevSymptomList ] = useState<IPreviousSymptom[]>([]);
    const [ prevSymptom, setPrevSymptom ] = useState<IPreviousSymptom>();
    const [ plans, setPlans ] = useState<FreezedArray<{ isSelected: boolean, schedule: NextSchedule }>>([]);
    const [ isNextScheduleOpen, setIsNextScheduleOpen ] = useState(false);
    const [ status, setStatus ] = useState<Readonly<{ isSelected: boolean, value: number }>>({ isSelected:false, value:SYMPTOM_STATUS.UNDER_TREATMENT.no });
    const [ growth, setGrowth ] = useState<IGrowthSelectorData>();
    const [ isAutoScrollDisabled, setIsAutoScrollDisabled ] = useState(false);
    const [ singleCowBirthInfo, setSingleCowBirthInfo ] = useState<GrowthBirthWeightDto>();
    const [ isPresetShown, setIsPresetShown ] = useState(false);
    const [ visitFee, setVisitFee ] = useState<ModifyReqVisitWithName>();
    const [ isVisitFeeEditing, setIsVisitFeeEditing ] = useState(false);

    //スケジュールプリセットの反映
    useEffect(() => {
        if (mPresets.data == null || props.schedulePreset == null) return;
        //編集のときは無視
        if (props.original != null) return;

        const preset = props.schedulePreset;
        const list = mPresets.data;

        const converted = preset.items.map(pi => 
            convScheduledPresetToTreatSelectorData(pi,
                preset.team_id,
                props.ranchId,
                props.clinicId,
                list.ranchMedicines,
                list.clinicMedicines,
                list.medicineRoutes,
                list.treatItems,
                hasTeamAuth("MASTER_EDIT", "MASTER_EDIT", treatTeamId, props.user)
            )
        );
        setTreats(ar.notNull(converted).map(t => ({ ...t, key: generateKey() })));

    }, [ props.schedulePreset, mPresets.data == null, props.original == null ]);


    const anyEpidemic = useCallback((diseaseIds:number[]) => {
        return diseaseIds.some(id => props.diseases.find(li => li.disease_id === id)?.is_epidemic === 1);

    }, [ props.diseases ]);

    //initialize
    useEffect(() => {
        if (mPresets.data == null) return;

        if (props.original != null) {
            const received = props.original;

            const newSymptom = convertReceivedToEditing(received, false);
    
            const newTreats: ITreatSelectorData[] = received.treat.map(t => ({ ...t, benefit_type: convertBenefitType(t.benefit_type) }));

            setSymptom(newSymptom);
            setTreats(newTreats.map(t => ({ ...t, key:generateKey()})));
            setMemo(received.comment);
            setStatus({ isSelected: received.status != null, value: received.status ?? status.value });
            //「治療中」「経過観察」のとき、このタイミングでスクロールが走らないように
            setIsAutoScrollDisabled(canNextSchedule(received.status));
            setIsNextScheduleOpen(received.status != null);
            setVisitFee(
                received.visit == null
                ? undefined
                : ({ ...received.visit, fees: received.visit.fees.map(f => ({ ...f, cow_ids:[ received.cow_id ]})) })
            );
        }

        let date = symptom.watched_at;
        let stsVal = status.isSelected ? status.value : undefined;
        if (props.original  != null) {
            date = moment(props.original.watched_at).toDate();
            stsVal = props.original.status;
        }
        setPlans([ { isSelected:false, schedule: defaultSchedule(date, stsVal) } ]);

        if (props.original?.first_id != null || props.firstId != null) {
            resetPreviousList(props.initialHistories);
        }

    }, [ props.original, props.diseases, props.firstId, props.initialHistories, mPresets.data == null ]);

    const convertReceivedToEditing = (received: SympDataDto, isPrevCopy: boolean) => {
        const newSymptom:EditingSymptom = {
            temperature_x10: received.temperature_x10,
            active_score: received.active_score,
            hungry_score: received.hungry_score,
            heart_rate: received.heart_rate,
            breath_count: received.breath_count,
            //※breath_scoreの値は使わない
            feces_state: received.feces_state,
            feces_color: received.feces_color,
            watched_at: isPrevCopy ? symptom.watched_at : moment(received.watched_at).toDate(),
            symptom_name: received.symptom_name,
            //※診療所ユーザでない場合、前回診の診断項目は反映しない
            //（編集は同じ人でないとできないので通常ありえないが、編集時も診療所ユーザでなければ破棄）
            notice: "",
            diseases: [],
            isEpidemic: false,
            conditions: [],
        };
        if (props.clinicId != null) {
            newSymptom.notice = received.notice;
            newSymptom.diseases = received.diseases;
            newSymptom.isEpidemic = anyEpidemic(received.diseases.map(d => d.disease_id));
            newSymptom.conditions = isPrevCopy ? copyConditions(received.condition) : received.condition;
        }
        return newSymptom;
    };

    const resetPreviousList = (histories: FreezedArray<SymptomHistoryInfoDto>|undefined, ) => {
        if (!CommonUtil.assertNotNull(mPresets.data, "masters.data")) return;

        if (histories == null) {
            setPrevSymptomList([]);
            setPrevSymptom(undefined);
            return;
        }

        const ranchMedicines = mPresets.data.ranchMedicines;
        const clinicMedicines = mPresets.data.clinicMedicines ?? [];
        const treatItems = mPresets.data.treatItems;

        // 前回診療の初診IDが指定されている(診療記録確認画面より遷移)場合は、前回診療の初診IDで絞り込み
        const onlyFirstId = props.firstId;
        // 処置がふくまれていなくても対象にするもの
        const includeId = props.original?.first_id;
        // 編集中の診療を除外する
        const omitId = props.original?.symptom_id;

        const list = convertPreviousSymptomsForSelect(histories, onlyFirstId, includeId, omitId, props.clinicId != null,
            teamId => ranchMedicines[0]?.team_id === teamId ? ranchMedicines : clinicMedicines[0]?.team_id === teamId ? clinicMedicines : [],
            teamId => treatItems[0]?.team_id === teamId ? treatItems : []);

        if (list.length === 0) {
            props.showToast(A.MESSAGE.NO_PREV_SYMPTOM_DATA);
            setPrevSymptomList([]);
            setPrevSymptom(undefined);
            return;
        }

        setPrevSymptomList(list);
        //編集中の記録の初診ID、または先頭（直近の前回診療）を初期値にする
        const selected = (includeId != null ? list.find(p => p.first_id === includeId) : undefined) ?? list[0];
        setPrevSymptom(selected);
    }

    const copyPrevSymptom = async (symptomId: number) => {
        if (!CommonUtil.assertNotNull(mPresets.data, "masters.data")) return;

        const received = await props.loadSymptom(symptomId);
        if (received == null) return;

        const newSymptom = convertReceivedToEditing(received, true);
        const newTreats = copyTreats(received.treat);
        const newStsVal = received.status ?? status.value;

        setSymptom(newSymptom);
        setTreats(newTreats.map(t => ({ ...t, key:generateKey()})));
        setMemo(received.comment);
        setStatus({ isSelected: received.status != null, value: newStsVal });
        //「治療中」「経過観察」のとき、このタイミングでスクロールが走らないように
        setIsAutoScrollDisabled(canNextSchedule(received.status));
        setIsNextScheduleOpen(received.status != null);
        //診療固定費は反映しない

        //statusによって予定選択可否も変わるので、一度リセット
        setPlans([ { isSelected:false, schedule: defaultSchedule(symptom.watched_at, newStsVal) } ]);
    }

    const copyConditions = (from: SympConditionDto[]): SymptomModifyReqCondition[] => {
        if (!CommonUtil.assertNotNull(mDiseases.data, "mDiseases")) return [];
        const myConditionList = mDiseases.data.conditions;
        
        const rtn: SymptomModifyReqCondition[] = [];
        for (const fromCond of from) {
            let cls: IConditionClass|undefined;
            for (const myCond of myConditionList) {
                const c = myCond.classes.find(mc => mc.class_id === fromCond.class_id);
                if (c != null) {
                    cls = c;
                    break;
                }
            }
            if (cls == null) continue;

            const dtl = cls.details.find(d => d.detail_id === fromCond.detail_id);
            if (dtl == null) continue;

            rtn.push({ class_id: cls.class_id, detail_id: dtl.detail_id });
        }
        return rtn;
    }

    const copyTreats = (from: SympTreatDto[]): ITreatSelectorData[] => {
        if (!CommonUtil.assertNotNull(mPresets.data, "masters.data", "copyTreats")) return [];

        const myRoutes = mPresets.data.medicineRoutes;
        const myTreatItems = mPresets.data.treatItems;
        const myRanchMedicines = mPresets.data.ranchMedicines;
        const myClinicMedicines = mPresets.data.clinicMedicines ?? [];

        const rtn: ITreatSelectorData[] = [];

        for (const fromTreat of from) {
            if (fromTreat.team_id !== props.ranchId && fromTreat.team_id !== props.clinicId) continue;

            const benefit_type = (props.clinicId == null || fromTreat.team_id !== props.clinicId) ? getDefaultBenefitType(props.clinicId != null)
                            : convertBenefitType(fromTreat.benefit_type);

            //投薬
            if (fromTreat.treat_kind_no == null) {
                const medList = fromTreat.team_id === props.ranchId ? myRanchMedicines : myClinicMedicines;
                const medicine = medList.find(m => m.medicine_id === fromTreat.medicine_id && m.is_treatment === 1);
                if (medicine == null) continue;

                const route = myRoutes.find(m => m.route_id === fromTreat.route_id);

                rtn.push({ ...fromTreat, route_id: route?.route_id ?? 0, benefit_type })
                continue;
            }

            //処置
            if (fromTreat.team_id !== treatTeamId) continue;
            const treat = myTreatItems.find(m => m.treat_kind_no === fromTreat.treat_kind_no && m.treat_item_no === fromTreat.treat_item_no);
            if (treat == null) continue;

            rtn.push({ ...fromTreat, benefit_type });
        }
        return rtn;
    }



    const onChangeReexamine = async (checked: boolean) => {
        if (!checked) {
            setPrevSymptom(undefined);
            return;
        }

        const list = await props.getHistoryList(symptom.watched_at);
        resetPreviousList(list);
    }

    const showVisitFee = () => {
        if (!CommonUtil.assertNotNull(props.clinicId, "clinicId")) return;
        if (!CommonUtil.assertNotNull(mVisiting.data, "visiting")) return;

        if (visitFee == null) {
            setVisitFee({ clinic_id: props.clinicId, fees:[] });
        }
        setIsVisitFeeEditing(true);
    }


    const onGrowthInputPopup = async () => {
        if (props.cow_ids.length === 1 && singleCowBirthInfo == null) {
            const birth = await props.getCowBirthInfo(props.cow_ids[0]);
            setSingleCowBirthInfo(birth);   //失敗していても続行
        }

        setGrowthInputPopup(true);
    }

    const onAddTreatment = () => {
        setTreats([
            ...treats,
            {
                medicine_id: 0,
                route_id: 0,
                unit_price: 0,
                treat_kind_no: undefined,
                treat_item_no: 0,
                note: "",
                team_id: treatTeamId,
                benefit_type: getDefaultBenefitType(props.clinicId != null),
                key: generateKey()
            }
        ]);
    }

    const onRemTreatment = (idx: number) => {
        setTreats(treats.filter((_,i) => i !== idx));
    }

    const onTreatUpdated = (idx: number, data: ITreatSelectorData) => {
        const newTreats = [...treats];
        newTreats[idx] = { ...data, key:treats[idx].key };

        //薬品重複
        if (data.treat_kind_no == null || data.treat_kind_no === 0) {
            if (data.medicine_id != null && data.medicine_id > 0
                && newTreats.some((d,i) => i !== idx && d.medicine_id === data.medicine_id && d.team_id === data.team_id)) {
            
                props.showToast(A.MESSAGE.MEDICINE_ALREADY_ADDED);
                return;
            }
        }
        //処置重複
        else {
            if (data.treat_item_no != null && data.treat_item_no > 0
                && newTreats.some((d,i) => i !== idx && d.treat_kind_no === data.treat_kind_no && d.treat_item_no === data.treat_item_no)) {
                
                props.showToast(A.MESSAGE.TREAT_ITEM_ALREADY_ADDED);
                return;
            }
        }

        setTreats(newTreats);
    }

    const onPresetSelect = (preset: TeamTreatPresetDto) => {
        if (!CommonUtil.assertNotNull(mPresets.data, "masters.data", "preset select")) return;

        const medicines = mPresets.data.clinicMedicines ?? mPresets.data.ranchMedicines;
        const routes = mPresets.data.medicineRoutes;
        const treatItems = mPresets.data.treatItems;

        const newTreats = ar.notNull(preset.items.map(p =>
            convPresetToTreatSelectorData(
                p,
                treatTeamId,
                medicines,
                routes,
                treatItems
            )));
        
        setTreats(newTreats.map(t => ({ ...t, key: generateKey() })));
        setIsPresetShown(false);
    }

    const onSave = () => {
        if (!CommonUtil.assertNotNull(mPresets.data, "masters.data", "submit")) return;

        // 処置入力チェック
        const validTreats = new Array<ITreatSelectorData>();
        for (const treat of treats) {
            const res = validateTreat(treat, mPresets.data.treatItems, false);

            if (res === "BLANK") continue;
            if (res !== "OK") {
                const msg = TREAT_VALIDATION_RESULT[res].msg;
                props.showToast(msg);
                return;
            }
            validTreats.push(treat);
        }
        const reqTreats = convertTreatsToModifyReq(validTreats, mPresets.data.medicineRoutes);

        props.onSave({ 
            symptom,
            treats: reqTreats,
            firstId: prevSymptom?.first_id,
            memo,
            plans: plans.filter(p => p.isSelected).map(p => p.schedule),
            status: status.isSelected ? status.value : undefined,
            growth,
            visitFee
        });
    }



    if (mPresets.isLoading || mVisiting.isLoading || mDiseases.isLoading) return <FetchWaiter />
    if (mPresets.isError || mPresets.data == null) return <FetchError />
    if (mVisiting.isError || mVisiting.data == null) return <FetchError />
    if (mDiseases.isError || mDiseases.data == null) return <FetchError />
    if (props.cow_ids.length === 0) return <></>;

    const collapseRowClass = styles["row-collapse"];
    const collapseHeaderClass = "col-form-label col-md-4 col-xs-6 clickable p-l-10 p-r-10";
    const collapseArrowClass = (isOpen : boolean) => "fas fa-lg fa-fw " + (isOpen ? "fa-angle-up" : "fa-angle-down");
    const groupRowClass = classnames(styles.group, styles.row);
    const canRegisterGrowth = props.original == null && hasRanchAuth("GROWTH_EDIT", props.ranchId, props.user);
    const canEditMaster = hasTeamAuth("MASTER_EDIT", "MASTER_EDIT", treatTeamId, props.user);
    const isOfficial = new UserTeams(props.user).findOfficialRanch(props.ranchId) != null;
    const labelClass = "col-form-label col-md-4 col-xs-4 text-lg-right p-l-10 p-r-10";

    //牛情報が正しく保持されていることを確認
    const cowsInfoForVisitFee = (props.cow_ids.length === 1 && props.cow != null)
            ? props.cow
            : props.paramCows;
    
    const selectedVisitFeeNames = (props.clinicId == null) ? ""
            : (visitFee == null || visitFee.fees.length === 0) ? "選択"
            : visitFee.fees.map(f => f.name ?? "").join(",");

    return (
        <>
            <div className="product product-full-height">
                <div className="product-detail" style={{ height: "100%" }}>
                    <div className="product-info product-info-fix p-b-0">
                        <div className="product-info-header">
                            {
                                props.cow != null && (
                                    <SingleCowHeader ranchId={props.ranchId} cowId={props.cow.cow_id} />
                                )
                            }
                            {
                                props.cow_ids.length > 1 && (
                                    <MultiCowHeader infoName="診療記録" cowCount={props.cow_ids.length} cows={props.paramCows} />
                                )
                            }
                        </div>
                        {/* <!-- BEGIN content --> */}
                        <div className="feed-write" style={{paddingRight:"6px"}}>
                            <div className={groupRowClass}>
                                <label className={labelClass}>診療日時</label>
                                <div style={{ flex: 1 }}>
                                    <V3DateTime value={symptom.watched_at}
                                        timePresets={props.user.setting?.time_preset ?? TIMEPRESETS}
                                        onChange={m => setSymptom({ ...symptom, watched_at: m.toDate() })} />
                                </div>
                            </div>
                            { props.clinicId != null && cowsInfoForVisitFee != null && (
                                <div className={groupRowClass}>
                                    <label className={labelClass}>診療費</label>
                                    <div className="col-md-6 col-xs-7" style={{ padding: "8px 0"}}>
                                        <IconLink iconType="popup" text={selectedVisitFeeNames} onClick={showVisitFee} />
                                    </div>
                                </div>
                            )}
                            { props.cow_ids.length === 1 && (
                                <div className={groupRowClass}>
                                    <label className={labelClass}>診療区分</label>
                                    <div className="col-md-6 col-xs-7 p-l-0">
                                        <div className="checkbox checkbox-css" data-testid="再診">
                                            <input type="checkbox" id="cssChkReexamine"
                                                onChange={(e) => onChangeReexamine(e.target.checked)}
                                                checked={prevSymptom != null} />
                                            <label htmlFor="cssChkReexamine">再診／継続</label>
                                        </div>
                                    </div>
                                </div>
                            )}
                            { prevSymptom != null && (
                                <div className={groupRowClass}>
                                    <label className={labelClass}>前回診療</label>
                                    <div className="col-md-6 col-xs-8 p-l-0">
                                        <div>
                                            <select className="form-control" value={prevSymptom.symptom_id}
                                                    onChange={e => setPrevSymptom(prevSymptomList.find(p => p.symptom_id === Number(e.target.value)))}>
                                                {
                                                    prevSymptomList.map(prev => (
                                                        <option key={prev.symptom_id} value={prev.symptom_id}>{prev.date} {prev.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="m-t-10">
                                            <a className="link" onClick={() => setSeriesPopupFirstId(prevSymptom.first_id)}>
                                                診療記録確認
                                            </a>
                                        </div>
                                        <div className="m-t-10">
                                            <button className="btn btn-blue" onClick={() => copyPrevSymptom(prevSymptom.symptom_id)}>
                                                <i className="fas fa-arrow-alt-circle-down"></i> 前回の値を反映
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <SymptomPhysicalInput
                                data={symptom}
                                comm={props.comm}
                                conditions={mDiseases.data.conditions}
                                relations={mDiseases.data.relations}
                                diseases={props.diseases}
                                causes={props.causes}
                                fecesColorList={props.fecesColors}
                                fecesStateList={props.fecesStates}
                                onChange={data => setSymptom({
                                    ...symptom,
                                    ...data,
                                    isEpidemic: anyEpidemic(data.diseases.map(d => d.disease_id))
                                })}
                                onHasInvalidChanged={setAnyInvalidInput}
                                symptomNameList={props.symptom_name_list}
                                noticeList={props.noticeList}
                                onGrowthInputClick={canRegisterGrowth ? onGrowthInputPopup : undefined}
                                diseaseInputMode={props.clinicId != null ? "modal" : "none"}
                            />

                            <div className="treat-block-header">
                                <label>処置</label>
                                <IconLink text="プリセット呼び出し" iconType="popup" onClick={() => setIsPresetShown(true)}/>
                            </div>

                            {
                                treats.map((treatment, i) => (
                                    <TreatSelector data={{ ...treatment }} key={treatment.key} index={i}
                                        onChange={d => onTreatUpdated(i,d)} onDelete={() => onRemTreatment(i)}
                                        treatKinds={props.treatKinds}
                                        medicineCategories={props.medicineCategories}
                                        treatKindMedicineName={"治療薬"} 
                                        weightInfo={ { std_weight: props.cow?.std_weight ?? null, birthday: props.cow?.birthday ?? null} } 
                                        filterMedicine={m => m.is_treatment === 1}
                                        hasAuthToEditMaster={canEditMaster}
                                        isOfficialRanch={isOfficial}
                                        usesBenefitForClinic={true}
                                        ranchId={props.ranchId}
                                        clinicId={props.clinicId}
                                    />
                                ))
                            }

                            <div style={{ display: "flex" }}>
                                <button style={{ flex: "1" }} className="btn btn-theme btn-sm btn-inverse"
                                    onClick={onAddTreatment}>
                                    <i className="fas fa-lg fa-fw m-r-10 fa-plus-circle"></i>
                                    処置を追加
                                </button>
                            </div>

                            <div className={groupRowClass}>
                                <label className="col-form-label col-md-2 col-xs-2 text-lg-right p-l-10 p-r-10">メモ</label>
                                <div className="col-md-10 col-xs-10">
                                    <textarea className="form-control" rows={4} maxLength={LMT.SYMPTOM.MEMO_LEN} data-testid="memo"
                                        value={memo} onChange={e => setMemo(e.target.value)}></textarea>
                                </div>
                            </div>

                            <div className={collapseRowClass} data-testid="状態予定開閉">
                                <div className={collapseHeaderClass}
                                    onClick={() => setIsNextScheduleOpen(!isNextScheduleOpen)}>
                                    <span>状態と予定を設定</span>
                                    <i className={collapseArrowClass(isNextScheduleOpen)}></i>
                                </div>
                            </div>
                            <Collapse isOpen={isNextScheduleOpen}>
                                <SymptomStatusAndScheduleInput
                                    baseDate={symptom.watched_at}
                                    isAutoScrollDisabled={isAutoScrollDisabled}
                                    onScrollTriedWhenDisabled={() => setIsAutoScrollDisabled(false)}
                                    plans={plans}
                                    status={status}
                                    onPlanChange={p => setPlans(p)}
                                    onStatusChange={s => setStatus(s)}
                                    presetTeamId={treatTeamId}
                                    ranchId={props.ranchId}
                                    isEpidemic={symptom.isEpidemic}
                                />

                            </Collapse>
                        </div>
                        {/* <!-- END checkout-body --> */}
                    </div>
                </div>
            </div>
            <div className="button-page-footer">
                <ExecutionButton type="save" onClick={onSave} disabled={props.executing || anyInvalidInput} />
                { props.original != null && (
                    <ExecutionButton type="delete" onClick={props.onDelete} disabled={props.executing} />
                )}
            </div>
            {
                growthInputPopup && (
                    <GrowthInputPopup isOpen={true}
                        growth={growth}
                        onClose={() => setGrowthInputPopup(false)}
                        onCommit={g => {
                            setGrowth({ ...g });
                            setGrowthInputPopup(false);
                        }}
                        dgSource={singleCowBirthInfo == null ? undefined : {
                            stDate: singleCowBirthInfo.birthday,
                            stScore: singleCowBirthInfo.actual ?? singleCowBirthInfo.standard,
                            isStandardScoreUsed: singleCowBirthInfo.actual == null,
                            edDate: symptom.watched_at
                        }}
                    />
                )
            }                    
            {
                seriesPopupFirstId != null && (
                    <SymptomSeriesPopup
                        data={{
                            user: props.user,
                            ranch_id: props.ranchId,
                            first_id: seriesPopupFirstId,
                            feces_colors: props.fecesColors,
                            feces_states: props.fecesStates,
                        }}
                        onClose={() => setSeriesPopupFirstId(undefined)}
                        comm={props.comm}
                    />
                )
            }
            { isPresetShown && (
                <TreatPresetSelectPopup
                    presets={mPresets.data.presets}
                    medicinesOnly={false}
                    medicines={mPresets.data.clinicMedicines ?? mPresets.data.ranchMedicines}
                    routes={mPresets.data.medicineRoutes}
                    treatItems={mPresets.data.treatItems}
                    onClose={() => setIsPresetShown(false)}
                    onSubmit={onPresetSelect}
                />
            )}
            { isVisitFeeEditing && visitFee != null && cowsInfoForVisitFee != null && (
                <VisitFeeSelectPopup
                    allCows={cowsInfoForVisitFee}
                    onClose={() => setIsVisitFeeEditing(false)}
                    onSubmit={fee => {
                        setVisitFee(fee);
                        setIsVisitFeeEditing(false);
                    }}
                    fromList={mVisiting.data.froms}
                    feeItemList={mVisiting.data.fees}
                    data={visitFee}
                    showToast={props.showToast}
                />
            )}
        </>
    )
}