import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PageSettings } from '../../config/page-settings.js';
import { IEditingMedicine } from './medicine-master';
import { IMedicineCategory, IMedicineRoute, IUser } from '../../stores/RootStore';
import { OptionalNumInput, RequiredNumInput } from '../../components/parts/num-input';
import styles from './setting.module.css';
import { hasRanchContract } from '../../config/contract-checker';
import { TeamType, LMT } from '../../config/constant';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { FreezedArray } from '../../config/util';

interface MyProps {
    data: IEditingMedicine | null;
    medicineCategoryList: FreezedArray<IMedicineCategory>;
    medicineRouteList: FreezedArray<IMedicineRoute>;
    onSubmit: ()=>void;
    onClose: ()=>void;
    onDelete: ()=>void;
    onNameEdited:(name:string)=>void;
    onCategoryEdited:(categoryCd:number)=>void;
    onUnitAmountEdited:(unit_amount:number)=>void;
    onUnitEdited:(unit:string)=>void;
    onPriceEdited:(price:number)=>void;
    onIsTreatmentEdited:(is_treatment:number)=>void;
    onIsPreventionEdited:(is_prevention:number)=>void;
    onDefaultAmountWeightEdited:(default_amount_weight:number|null)=>void;
    onDefaultAmountEdited:(default_amount:number|undefined)=>void;
    onDefaultRouteIdEdited:(default_route_id:number)=>void;
    onWashoutMeatDayEdited:(washout_meat_day?:number)=>void;
    onWashoutMilkHourEdited:(washout_milk_hour?:number)=>void;
    teamId: number;
    teamType: TeamType;
    user: IUser;
    isSubmitExecuting: boolean;
}
interface MyState {
}

export class MedicineEditPopup extends React.Component<MyProps,MyState> {

    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        return (
            <div>
                <Modal isOpen={true} centered={true} scrollable={true}>
                    <ModalHeader toggle={this.props.onClose}></ModalHeader>
                    <ModalBody>
                        <div>
                            <div className="form-group row" style={{display:"flex"}}>
                                <label className="col-form-label col-md-2 col-xs-2">薬名</label>
                                <div className="col-md-10 col-xs-10">
                                    <input className="form-control" type="text" value={this.props.data?.name ?? ""}
                                            maxLength={LMT.MEDICINE.NAME_LEN}
                                            onChange={(e)=>this.props.onNameEdited(e.target.value)}/>
                                </div>
                            </div>
                            <div className="form-group row" style={{display:"flex"}}>
                                <label className="col-form-label col-md-2 col-xs-2">分類</label>
                                <div className="col-md-10 col-xs-10">
                                    <select className="form-control" value={this.props.data?.category} onChange={ (e)=>this.props.onCategoryEdited(Number(e.target.value))}>
                                        <option key={0} value={0}>選択</option>
                                        {
                                            this.props.medicineCategoryList.map((cg) => (
                                                <option key={cg.category} value={cg.category}>{cg.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row" style={{display:"flex"}}>
                                <label className="col-form-label col-md-2 col-xs-2">単位</label>
                                <div className="col-md-10 col-xs-10" style={{display:"flex", alignItems:"center"}}>
                                    <input className="form-control" type="text" maxLength={LMT.MEDICINE.UNIT_LEN}
                                            style={{width:"70px", marginLeft:"2px", marginRight:"2px"}}
                                            value={this.props.data?.unit ?? ""}
                                            onChange={(e)=>this.props.onUnitEdited(e.target.value)}/>
                                </div>
                            </div>
                            <div className="form-group row" style={{display:"flex"}}>
                                <label className="col-form-label col-md-2 col-xs-2">価格</label>
                                <div className="col-md-10 col-xs-10" >
                                    <div style={{display:"flex", alignItems:"center"}}>
                                        <RequiredNumInput
                                            min={1}
                                            step={1}
                                            max={LMT.MEDICINE.AMOUNT_MAX}
                                            style={{width:"54px", marginLeft:"2px", marginRight:"2px"}}
                                            value={this.props.data?.unit_amount??1}
                                            onChange={val => this.props.onUnitAmountEdited(val)} />
                                        <span>{this.props.data?.unit ?? ""} あたり</span>
                                        <RequiredNumInput
                                            min={LMT.MEDICINE.UNIT_PRICE_MIN}
                                            max={LMT.MEDICINE.UNIT_PRICE_MAX}
                                            step={LMT.MEDICINE.UNIT_PRICE_STEP}
                                            style={{width:"90px", marginLeft:"2px", marginRight:"2px"}}
                                            value={this.props.data?.total_price??0}
                                            onChange={val => this.props.onPriceEdited(val)} />
                                        <span>円</span>
                                    </div>
                                    <div>
                                        <span>(1{this.props.data?.unit ?? ""} あたり {this.props.data?.unit_price}円)</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row mb-2" style={{display:"flex"}}>
                                <label className="col-form-label col-md-2 col-xs-2">用途</label>
                                <div className="col-md-10 col-xs-10 custom-control custom-checkbox" style={{display:"flex", alignItems:"center", paddingLeft: "2.2rem"}}>
                                    <input type="checkbox" id="chkTreatment" className="custom-control-input"
                                            checked={Boolean(this.props.data?.is_treatment)}
                                            onChange={(e)=>this.props.onIsTreatmentEdited(Number(e.target.checked))} />
                                    <label className="custom-control-label" htmlFor="chkTreatment">治療薬</label>
                                </div>
                            </div>
                            <div className="form-group row" style={{display:"flex"}}>
                                <label className="col-form-label col-md-2 col-xs-2"></label>
                                <div className="col-md-10 col-xs-10 custom-control custom-checkbox" style={{display:"flex", alignItems:"center", paddingLeft: "2.2rem"}}>
                                    <input type="checkbox" id="chkPrevention" className="custom-control-input" 
                                            checked={Boolean(this.props.data?.is_prevention)}
                                            onChange={(e)=>this.props.onIsPreventionEdited(Number(e.target.checked))} />
                                    <label className="custom-control-label" htmlFor="chkPrevention">予防薬</label>
                                </div>
                            </div>
                            <div className="form-group row" style={{display:"flex"}}>
                                <label className="col-form-label col-md-3 col-xs-4">標準投与量</label>
                                <div className="col-md-8 col-xs-8">
                                    <div style={{display:"flex", flexDirection:"column"}}>
                                        <div className="m-b-10"> 
                                            <div className="radio radio-css m-l-10 radio-inline">
                                                <input type="radio" name="radio_default_amount_weight" id="defaultAmountWeight1"
                                                    onChange={(e) => this.props.onDefaultAmountWeightEdited(null)}
                                                    checked={this.props.data?.default_amount_weight == null } />
                                                <label htmlFor="defaultAmountWeight1">一律</label>
                                            </div>
                                            <div className="radio radio-css m-l-10 radio-inline">
                                                <input type="radio" name="radio_default_amount_weight" id="defaultAmountWeight2" value="100" 
                                                    onChange={(e) => this.props.onDefaultAmountWeightEdited(Number(e.target.value))}
                                                    checked={this.props.data?.default_amount_weight === 100 } />
                                                <label htmlFor="defaultAmountWeight2">体重100kgあたり</label>
                                            </div>
                                        </div>
                                        <div style={{display:"flex", alignItems:"center"}}>
                                            <OptionalNumInput
                                                min={LMT.MEDICINE.AMOUNT_MIN}
                                                max={LMT.MEDICINE.AMOUNT_MAX}
                                                step={LMT.MEDICINE.AMOUNT_STEP}
                                                style={{width:"70px", marginLeft:"2px", marginRight:"2px"}}
                                                value={this.props.data?.default_amount}
                                                onChange={val => this.props.onDefaultAmountEdited(val)}
                                                />
                                            <span>{this.props.data?.unit}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row" style={{display:"flex"}}>
                                <label className="col-form-label col-md-3 col-xs-4">標準投与方法</label>
                                <div className="col-md-4 col-xs-5">
                                    <select className="form-control" value={this.props.data?.default_route_id ?? 0} onChange={ (e)=>this.props.onDefaultRouteIdEdited(Number(e.target.value))}>
                                        <option key={0} value={0}>選択</option>
                                        {
                                            this.props.medicineRouteList.map((cr) => (
                                                <option key={cr.route_id} value={cr.route_id}>{cr.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className="form-group row" style={{display:"flex"}}>
                                <div className="col-md-3 col-xs-4 p-r-0" style={{display:"flex", justifyContent: "space-between"}}>
                                    <label className="col-form-label">出荷制限期間</label>
                                    <label className="col-form-label">肉</label>
                                </div>
                                <div className="col-md-9 col-xs-8">
                                    <div style={{display:"flex", alignItems: "center"}}>
                                        <OptionalNumInput min={0} max={LMT.WASHOUT.MEAT_DAY_MAX} step={1} 
                                            onChange={n => this.props.onWashoutMeatDayEdited(n)}
                                            value={this.props.data?.washout_meat_day}
                                            className={styles["washout-input"]}
                                        />日
                                    </div>
                                </div>
                            </div>

                            { (this.props.teamType === 'clinic' || hasRanchContract("BREEDING", this.props.teamId, this.props.user)) &&
                                <div className="form-group row" style={{display:"flex"}}>
                                    <div className="col-md-3 col-xs-4 p-r-0" style={{display:"flex", justifyContent: "space-between"}}>
                                        <label className="col-form-label">&nbsp;</label>
                                        <label className="col-form-label">乳</label>
                                    </div>
                                    <div className="col-md-9 col-xs-8">
                                        <div style={{display:"flex", alignItems: "center"}}>
                                            <OptionalNumInput min={0} max={LMT.WASHOUT.MILK_HOUR_MAX} step={1} 
                                                onChange={n => this.props.onWashoutMilkHourEdited(n)}
                                                value={this.props.data?.washout_milk_hour}
                                                className={styles["washout-input"]}
                                            />時間
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                    </ModalBody>
                    <ModalFooter className="modal-footer-fix">
                        <ExecutionButton type="save" onClick={this.props.onSubmit} disabled={this.props.isSubmitExecuting} />
                        { this.props.data && !this.props.data.isNew && (
                            <ExecutionButton type="delete" onClick={this.props.onDelete} disabled={this.props.isSubmitExecuting} />
                        )}
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}