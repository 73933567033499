import React from 'react';
import classnames from 'classnames';

export interface IconLinkProps {
    onClick?: () => void;
    className?: string;
    style?: React.CSSProperties;
    text: string;
    iconType: "add" | "navigate" | "popup" | "stock" | "reply" | "copy" | "tag" | "download" | "filter";
    testId?: string;
    fixIconWidth?: boolean;
    disabled?: boolean;
}

export const IconLink = React.memo((props: IconLinkProps) => {

    const iconClass = props.iconType === "add" ? "fa fa-plus"
                    : props.iconType === "navigate" ? "fas fa-angle-right"
                    : props.iconType === "popup" ? "far fa-clone"
                    : props.iconType === "stock" ? "fas fa-archive"
                    : props.iconType === "reply" ? "fas fa-reply-all"
                    : props.iconType === "copy" ? "far fa-copy"
                    : props.iconType === "tag" ? "fas fa-tag"
                    : props.iconType === "download" ? "fas fa-file-download"
                    : props.iconType === "filter" ? "fas fa-check"
                    : undefined;

    return (
        <span className={classnames("link", props.disabled ? "disabled" : undefined, props.fixIconWidth ? "fa-fw" : undefined, props.className)}
            style={props.style} onClick={props.onClick} data-testid={props.testId}>
            <i className={classnames(iconClass, "m-r-5")} />
            <span>{props.text}</span>
        </span>
    )
})