import { IUser } from "../stores";
import { UserTeams } from "./user-teams";

export const RANCH_CONTRACT_ITEMS = [
    "BREEDING",
    "ROBOT_IMPORT",
    "THERMOTELL"
] as const;

export type RanchContractItemKey = typeof RANCH_CONTRACT_ITEMS[number];

interface IRanchContractItem {
    val: number;
    allowsUnofficial: boolean;
}

const RANCH_CONTRACT_ITEM: { [key in RanchContractItemKey]: IRanchContractItem } = {
    BREEDING: { val: 0x0001, allowsUnofficial:true },

    //以下、β機能の利用可否を管理
    ROBOT_IMPORT: { val: 0x0100, allowsUnofficial:false },
    THERMOTELL: { val: 0x0200, allowsUnofficial:false },
};

export const hasRanchContract = (item: RanchContractItemKey, ranch_id: number, user: Readonly<IUser>): boolean => {
    const userTeams = new UserTeams(user);
    const contract = RANCH_CONTRACT_ITEM[item];

    const ranch = userTeams.findOfficialRanch(ranch_id);
    if (ranch == null) {
        const unofficial = userTeams.findUnofficialRanch(ranch_id);
        if (unofficial == null) return false;
        return contract.allowsUnofficial;
    }

    return (contract.val & ranch.contracts) === contract.val;
}

