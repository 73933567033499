import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import classnames from 'classnames'
import classNames from 'classnames/bind'

import { Cow, Treat, blankInput, BulkTreatInput, isSameTreat } from '../index'

import baseStyles from '../treatbulk.module.css'
import styles from './Edit.module.css'
import { LMT, A, SYMPTOM_STATUS, TREAT_BENEFIT_TYPE } from '../../../config/constant';
import { IMedicineCategory, ITreatKind, useRootStore, ITeamCondition, IDisease } from '../../../stores';
import { TreatEditModal } from './TreatEdit';
import { CommonUtil, ar, formatDiseaseCode } from '../../../config/util';
import { usePageStore } from '../../../config/page-settings';
import { AppState } from '../../../app';
import { buildPhysicalStrForBulkTreat } from '../../cow/cow-event-renderer';
import { Checkbox } from '../../../components/form/form-checkbox';
import { Communicator } from '../../../api/communicator';
import { SymptomApi, SymptomModifyReqCondition, SymptomDto, SympTreatDto, SympDataDto, DiseaseCauseDto, TeamTreatPresetDto } from '../../../api';
import moment from 'moment';
import { PhysicalEditModal } from './PhysicalEdit';
import { SymptomStatusAndScheduleInput, defaultSchedule, canNextSchedule } from '../../symptom/symptom-status-and-schedule-input';
import { NextSchedule } from '../../../components/next-schedule/next-schedule-selector';
import { PREVIOUS_SYMPTOMS_DAYS, convertPreviousSymptomsForSelect } from '../../symptom/previous-symptoms-converter';
import { ISymptomForDoctor } from '../../symptom/symptom-write';
import { SymptomSeriesPopup } from '../../symptom/symptom-series-popup';
import { EditingPhisical } from '../../symptom/symptom-physical-input';
import { hasTeamAuth } from '../../../config/auth-checker';
import { isValidMedicine, isValidTreat } from '../TreatInput';
import { IconLink } from '../../../components/parts/icon-link';
import { TreatPresetSelectPopup } from '../../../components/parts/treat-preset-select-popup';
import { convPresetToTreatSelectorData } from '../../../components/parts/treat-selector';
import { TempTreatItem, TempTreatItemRow } from '../temp-treat-item-row';
import { usePresetsWithBothTeamMedicines, useConditions } from '../../../stores/fetcher';
import { FetchWaiter, FetchError } from '../../../components/content/fetch-state';

let styleCxt = classNames.bind(styles);


type PropType = {
  ranchId           : number;
  clinicId          : number | undefined;
  cow               : Cow;
  setWatch          : (cow: Cow, watching: boolean, memo: string) => void;
  setCows           : React.Dispatch<React.SetStateAction<Array<Cow>>>;
  treatKinds        : ITreatKind[];
  medicineCategories: IMedicineCategory[];
  watchedAt         : Date;
  comm              : Communicator;
  onClose           : () => void;
  convertLatestTreatToInput    : (dto: SympTreatDto) => Treat | undefined;
  convertLatestPhysicalToInput : (dto: SympDataDto) => EditingPhisical;
}

interface IPrevSymptom {
  symptom_id: number;
  date      : string;
  name      : string;
  first_id  : number;
  watched_at: string;
}

type TempInput = Omit<BulkTreatInput, "schedules"|"status"|"treats"> & {
  plans: { schedule: NextSchedule, isSelected: boolean }[];
  status: { isSelected: boolean, value: number };
  treats: Array<TempTreatItem>;
}

const buildDiseaseInfo = (diseases: IDisease[], causes: DiseaseCauseDto[], conditions: ITeamCondition[], input: ISymptomForDoctor) => {
  const cond = input.conditions.map(c => findCondition(conditions, c)?.name ?? "").join(", ");

  const res: string[] = [];
  input.diseases.forEach((dis,i) => {
    const name = diseases.find(d => d.disease_id === dis.disease_id)?.name ?? "";
    const cause = dis.cause_no == null ? undefined : causes.find(c => c.cause_no === dis.cause_no)?.cause_name;
    const code = dis.code_no == null ? "" : `(${formatDiseaseCode(dis.code_no)})`;
    const head = `診断${i+1}`;
    res.push(cause == null ? `${head}：${name} ${code}` : `${head}：${name} - ${cause} ${code}`);
  })
  if (input.notice !== "") {
    res.push(`稟告：${input.notice}`);
  }
  if (cond !== "") {
    res.push("症状：" + cond);
  }
  return res;
}

const findCondition = (conditions: ITeamCondition[], target: SymptomModifyReqCondition) => {
  for (const cond of conditions) {
    const trgClass = cond.classes.find(c => c.class_id === target.class_id);
    if (trgClass == null) continue;

    return trgClass.details.find(d => d.detail_id === target.detail_id);
  }
  return undefined;
}

const EditModal: React.FC<PropType> = (props) => {
  const [ tempWatch, setTempWatch ] = useState(false);
  const [ tempWatchMemo, setTempWatchMemo ] = useState("");
  const [ tempInput, setTempInput ] = useState<TempInput>({ ...blankInput(), treats:[], plans: [], status: { isSelected: false, value: SYMPTOM_STATUS.UNDER_TREATMENT.no } });
  const [ editingTreat, setEditingTreat ] = useState<{ treat:Treat|undefined, index:number }>();
  const [ prevSymptomList, setPrevSymptomList ] = useState<IPrevSymptom[]>([]);
  const [ isPhysicalEditing, setIsPhysicalEditing ] = useState(false);
  const [ isSeriesShown, setIsSeriesShown ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ isPresetShown, setIsPresetShown ] = useState(false);
  const [ isEpidemic, setIsEpidemic ] = useState(false);

  const { showToast, handleSetIsLoading, postAsync } = usePageStore() as AppState;
  const { user, options } = useRootStore();

  const mPresets = usePresetsWithBothTeamMedicines(props.ranchId, props.clinicId);
  const mConditions = useConditions(props.clinicId ?? props.ranchId);

  useEffect(() => {
    if (loading) {
      handleSetIsLoading(true);
    } else {
      handleSetIsLoading(false);
    }
  }, [ loading ]);

  useEffect(() => {

    setTempWatch(props.cow.attrs.watched);
    setTempWatchMemo(props.cow.attrs.watched_memo);

    const input = {
      ...props.cow.input,
      plans: props.cow.input.schedules.map(s => ({ isSelected: true, schedule: s})),
      status: { isSelected: props.cow.input.status != null, value: props.cow.input.status ?? tempInput.status.value },
      treats: props.cow.input.treats.map(t => ({ ...t, valid: isValidInputTreat(t) }))
    };
    if (input.plans.length === 0) {
      input.plans = [ { isSelected:false, schedule: defaultSchedule(props.watchedAt, input.status.value) } ];
    }
    setTempInput(input);
  }, [props.cow, props.watchedAt])

  useEffect(() => {
    if (mPresets.data == null) return;
    const ranchMedicines = mPresets.data.ranchMedicines;
    const clinicMedicines = mPresets.data.clinicMedicines;
    const treatItems = mPresets.data.treatItems;

    const load = async () => {
      if (props.cow.input.first_id != null) {
        setLoading(true);
        const res = await props.comm.send((await SymptomApi()).getHistoryUsingPOST({
          ranch_id: props.ranchId,
          cow_id: props.cow.id,
          days: PREVIOUS_SYMPTOMS_DAYS,
          watched_at: moment(props.watchedAt).format("YYYY-MM-DD"),
          first_id: props.cow.input.first_id
        }), { retries: true });
        setLoading(false);

        if (res.result !== "OK") return;
  
        const resList = res.data?.info ?? [];
  
        const list = convertPreviousSymptomsForSelect(
                      resList, undefined, props.cow.input.first_id, undefined,
                      props.clinicId != null,
                      (tId: number) => tId === ranchMedicines[0]?.team_id ? ranchMedicines : tId === (clinicMedicines ?? [])[0]?.team_id ? clinicMedicines : [],
                      (tId: number) => tId === treatItems[0]?.team_id ? treatItems : []);
        if (list.length === 0) return;
        setPrevSymptomList(list);
      }
    }
    load();
  }, [ props.cow, mPresets.data == null ]);

  useEffect(() => {
    if (tempInput.physical == null) {
      setIsEpidemic(false);
    } else {
      const list = options.disease;
      setIsEpidemic(tempInput.physical.diseases.some(d => list.find(li => li.disease_id === d.disease_id)?.is_epidemic === 1));
    }

  }, [ tempInput.physical?.diseases ])

  const isValidInputTreat = (treat: Treat) => {
    if (treat.medicine_id != null || treat.new_medicine_name != null) {
      return isValidMedicine({ ...treat, amount:treat.amount ?? 0 }, false);
    }
    return isValidTreat(treat, false);
  }

  const save = () => {
    const newCow: Cow = ({
      ...props.cow,
      input: {
        ...tempInput,
        schedules:tempInput.status.isSelected && canNextSchedule(tempInput.status.value) ? tempInput.plans.filter(p => p.isSelected).map(p => p.schedule) : [],
        status: tempInput.status.isSelected ? tempInput.status.value : undefined
      }
    });

    props.setCows(cows => cows.map(c => c.id === props.cow.id ? newCow : c));

    if (props.cow.attrs.watched !== tempWatch || props.cow.attrs.watched_memo !== tempWatchMemo) {
      props.setWatch(newCow, tempWatch, tempWatchMemo)
    }
    props.onClose()
  }

  const add = () => {
    setEditingTreat({ treat: undefined, index: tempInput.treats.length })
  }

  const onTreatSubmit = (treat: Treat) => {
    if (!CommonUtil.assertNotNull(editingTreat)) return;

    const treats = [...tempInput.treats];

    if (treats.some((t,i) => i !== editingTreat.index && isSameTreat(t, treat))) {
      showToast("同じ項目が選択されています");
      return;
    }

    if (treats.length <= editingTreat.index) {
      treats.push({ ...treat, valid:true });
    } else {
      treats[editingTreat.index] = { ...treat, valid:true };
    }
    setTempInput({ ...tempInput, treats })
    setEditingTreat(undefined);
  }

  const onReexamineChange = async (isReexamine: boolean) => {
    if (!isReexamine) {
      setTempInput({ ...tempInput, first_id: undefined });
      return;
    }

    if (!CommonUtil.assertNotNull(mPresets.data, "mPresets")) return;

    let list = prevSymptomList;

    if (list.length === 0) {
      setLoading(true);
      const res = await props.comm.send((await SymptomApi()).getHistoryUsingPOST({
        ranch_id: props.ranchId,
        cow_id: props.cow.id,
        days: PREVIOUS_SYMPTOMS_DAYS,
        watched_at: moment(props.watchedAt).format("YYYY-MM-DD")
      }));
      setLoading(false);

      if (res.result !== "OK") return;

      const resList = res.data?.info ?? [];

      const ranchMedicines = mPresets.data.ranchMedicines;
      const clinicMedicines = mPresets.data.clinicMedicines;
      const treatItems = mPresets.data.treatItems;

      list = convertPreviousSymptomsForSelect(resList, undefined, undefined, undefined, props.clinicId != null,
                                  (tId: number) => tId === ranchMedicines[0]?.team_id ? ranchMedicines : tId === (clinicMedicines ?? [])[0]?.team_id ? clinicMedicines : [],
                                  (tId: number) => tId === treatItems[0]?.team_id ? treatItems : []);
      if (list.length === 0) {
        showToast(A.MESSAGE.NO_PREV_SYMPTOM_DATA);
        return;
      }
      setPrevSymptomList(list);
    }
    setTempInput({ ...tempInput, first_id: list[0].first_id })
  }

  const setPrevious = async () => {
    if (!CommonUtil.assertNotNull(tempInput.first_id, "first_id")) return;
    const prevItem = prevSymptomList.find(p => p.first_id === tempInput.first_id);
    if (!CommonUtil.assertNotNull(prevItem, "prevSymptom first_id=" + tempInput.first_id)) return;

    const url = `/symptom/${prevItem.symptom_id}?ranch_id=${props.ranchId}`;
    setLoading(true);
    const res = await props.comm.send<SymptomDto>(() => postAsync(url, {}));
    setLoading(false);
    
    if (res.result !== "OK" || res.data == null) return;
    const data = res.data.info;

    setTempInput(pre => ({
      status: { isSelected: data.status != null, value: data.status ?? SYMPTOM_STATUS.UNDER_TREATMENT.no },
      //statusが次回予定を取れるものじゃなかったときに整合性がつかなくなるので、入力中のものがあっても消してしまう
      plans: [ { isSelected: false, schedule: defaultSchedule(props.watchedAt, data.status )}],
      memo: data.comment,
      first_id: pre.first_id,
      physical: props.convertLatestPhysicalToInput(data),
      treats: ar.notNull(data.treat.map(t => props.convertLatestTreatToInput(t))).map(t => ({ ...t, valid: true }))
    }));
  }

  const setPreset = (preset: TeamTreatPresetDto) => {
    if (!CommonUtil.assertNotNull(mPresets.data, "mPreset")) return;
    const medicines = mPresets.data.clinicMedicines ?? mPresets.data.ranchMedicines;
    const routes = mPresets.data.medicineRoutes;
    const treatItems = mPresets.data.treatItems;

    setTempInput({
      ...tempInput,
      treats: ar.notNull(preset.items.map(pi => {
        const treatSelData = convPresetToTreatSelectorData(pi, props.clinicId ?? props.ranchId, medicines, routes, treatItems);
        if (treatSelData == null) return undefined;

        const benefit_type = treatSelData.benefit_type == null ? undefined : TREAT_BENEFIT_TYPE[treatSelData.benefit_type].no;
        const treat = { ...treatSelData, benefit_type };

        return {
          ...treat,
          valid: isValidInputTreat(treat),
        }
      }))
    });
    setIsPresetShown(false);
  }

  const cowNo = props.cow.earTag ? props.cow.repId : `(${props.cow.repId})`;

  const canEditMaster = hasTeamAuth("MASTER_EDIT", "MASTER_EDIT", props.clinicId ?? props.ranchId, user);

  const anyInvalid = tempInput.treats.some(t => !t.valid);

  const ID = "treatbulk-edit-modal";

  return (
    <div>
      <Modal isOpen={true} id={ID} scrollable={true} centered={true}>
        <ModalHeader toggle={() => { if (!loading) props.onClose(); } }>{ `個別編集 : ${cowNo} ${ props.cow.name }` }</ModalHeader>
        { (mPresets.isLoading || mConditions.isLoading) ? (
          <FetchWaiter />
        ) : (mPresets.isError || mPresets.data == null || mConditions.isError || mConditions.data == null) ? (
          <FetchError />
        ) : (<>
          <ModalBody>
            <div className={ styleCxt('reexamine')}>
              <div className={ styleCxt('left') }>診療区分</div>
              <Checkbox
                className={ styleCxt('content') }
                label="再診／継続" id="chkReexamine"
                checked={tempInput.first_id != null}
                onChange={e => onReexamineChange(e.target.checked)}
              />
            </div>
            { tempInput.first_id != null && (<>
              <div className={ styleCxt('reexamine')}>
                <div className={ styleCxt('left') }>前回診療</div>
                <div className={ styleCxt('content') }>
                    <select className="form-control"
                      value={tempInput.first_id}
                      onChange={e => setTempInput({ ...tempInput, first_id: Number(e.target.value)})}>
                      { prevSymptomList.map(p => (
                        <option key={p.symptom_id} value={p.first_id}>{p.date} {p.name}</option>
                      ))}
                    </select>
                </div>
              </div>
              <div className={ styleCxt('reexamine')}>
                <a className={ "link " + styleCxt('content-only') } onClick={() => setIsSeriesShown(true)}>診療記録確認</a>
              </div>
              <div className={ styleCxt('reexamine')}>
                <button className={ "btn btn-blue " + styleCxt('content-only') } disabled={loading} onClick={() => setPrevious()}>
                  <i className="fas fa-arrow-alt-circle-down" /> 前回の値を反映
                </button>
              </div>
            </>)}

            <hr className={ baseStyles.spliter } />

            <p>
              <span className={ styleCxt('title') }>投薬・処置</span>
              <IconLink text="プリセット呼び出し" iconType="popup" className="m-l-20" onClick={() => setIsPresetShown(true)} />
            </p>
            { tempInput.treats.map((treat,i) => (
              <TempTreatItemRow key={i} className={styleCxt("treat-row")}
                treat={treat}
                onEdit={() => setEditingTreat({ treat, index: i })}
                onRemove={() => setTempInput({ ...tempInput, treats: tempInput.treats.filter((_,ti) => ti !== i) })}
              />
            ))}
            <div className={ styleCxt('plus-bar') }>
              <button className={ styleCxt('plus') } data-testid="個別追加" onClick={() => add() }>
                <i className="fas fa-plus"></i>
              </button>
            </div>

            <hr className={ baseStyles.spliter } />

            <div className={ styleCxt('physical') }>
              <div className={ styleCxt('left') }>体調記録</div>
              <div className={ styleCxt('content') }>
              { (tempInput.physical != null || tempInput.memo !== "") ? (
                <>
                  { tempInput.physical != null && (
                    <>
                      {tempInput.physical.symptom_name !== "" && (<div>{tempInput.physical.symptom_name}</div>)}
                      <div>{buildPhysicalStrForBulkTreat(tempInput.physical, options.feces_state, options.feces_color)}</div>
                      { buildDiseaseInfo(options.disease,
                                          options.causes,
                                          mConditions.data,
                                          {
                                            diseases: tempInput.physical.diseases,
                                            notice: tempInput.physical.notice,
                                            conditions: tempInput.physical.conditions,
                                          }).map((text,i) => (<div key={i}>{text}</div>))  }
                    </>
                  )}
                  { tempInput.memo !== "" && (
                    <div>{tempInput.memo}</div>
                  )}
                </>
              ) : (
                <div>なし</div>
              )}
              </div>
              <div>
                <i className="fas fa-edit clickable" onClick={() => setIsPhysicalEditing(true)}/>
              </div>
            </div>

            <hr className={ baseStyles.spliter } />

            <p className={ styleCxt('title') }>状態と予定</p>
            <SymptomStatusAndScheduleInput
              baseDate={props.watchedAt}
              isAutoScrollDisabled={true}
              plans={tempInput.plans}
              status={tempInput.status}
              onPlanChange={p => setTempInput(pre => ({ ...pre, plans: p })) }
              onStatusChange={s => setTempInput(pre => ({ ...pre, status: s })) }
              portalContainerID={ID}
              presetTeamId={props.clinicId ?? props.ranchId}
              ranchId={props.ranchId}
              isEpidemic={isEpidemic}
            />

            <hr className={ baseStyles.spliter } />

            <p className={ styleCxt('title') }>要観察設定</p>
            <div className={ styleCxt('state-row') }>
              <div className={ styleCxt('header') }>状態</div>
              <div className={ styleCxt('state-value') }>
                <div className="checkbox checkbox-css p-t-0" data-testid="要観察切り替え">
                  <input id="chkWatching" checked={ tempWatch } type="checkbox"
                    onChange={e => setTempWatch(e.target.checked)}
                  />
                  <label htmlFor="chkWatching" className={ tempWatch ? styles.active : "" }>要観察</label>
                </div>
              </div>
            </div>
            <div className={ styleCxt('state-row') }>
              <div className={ styleCxt('header') }>観察事項</div>
              <textarea className={ classnames("form-control", styles["state-val"]) }
                maxLength={LMT.COW.WATCHING_MEMO_LEN}
                value={ tempWatchMemo } onChange={ e => setTempWatchMemo(e.target.value) } />
            </div>
          </ModalBody>
          <ModalFooter>
            <button data-testid="個別保存" disabled={loading || anyInvalid} className={ baseStyles.bluebutton } onClick={() => save()}>保存</button>
            <button data-testid="個別キャンセル" className={ baseStyles.graybutton } onClick={() => props.onClose()}>キャンセル</button>
          </ModalFooter>
          { editingTreat != null && (
            <TreatEditModal
              clinicId={props.clinicId}
              ranchId={props.ranchId}
              medicineCategories={props.medicineCategories}
              ranchMedicines={mPresets.data.ranchMedicines}
              clinicMedicines={mPresets.data.clinicMedicines ?? []}
              routes={mPresets.data.medicineRoutes}
              treatKinds={props.treatKinds}
              treats={mPresets.data.treatItems}
              onClose={() => setEditingTreat(undefined)}
              onSubmit={t => onTreatSubmit(t)}
              treat={editingTreat.treat}
              canEditMaster={canEditMaster}
            />
          )}
          { isPhysicalEditing && (
            <PhysicalEditModal
              comm={props.comm}
              cowId={props.cow.id}
              clinic_id={props.clinicId}
              ranch_id={props.ranchId}
              memo={tempInput.memo}
              physical={tempInput.physical}
              onClose={() => setIsPhysicalEditing(false)}
              onSubmit={d => { 
                setTempInput({ ...tempInput, physical: d.physical, memo:d.memo });
                setIsPhysicalEditing(false);
              }}
            />
          )}
          { isSeriesShown && tempInput.first_id != null && (
            <SymptomSeriesPopup
              comm={props.comm}
              onClose={() => setIsSeriesShown(false)}
              data={{
                user,
                ranch_id: props.ranchId,
                first_id: tempInput.first_id,
                feces_colors: options.feces_color,
                feces_states: options.feces_state,
              }}
            />
          )}
          { isPresetShown && (
            <TreatPresetSelectPopup
              medicinesOnly={false}
              medicines={mPresets.data.clinicMedicines ?? mPresets.data.ranchMedicines}
              routes={mPresets.data.medicineRoutes}
              treatItems={mPresets.data.treatItems}
              onClose={() => setIsPresetShown(false)}
              onSubmit={p => setPreset(p)}
              presets={mPresets.data.presets}
            />
          )}
        </>)}
      </Modal>
    </div>
  )
}


export default EditModal

