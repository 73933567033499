import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { useSensorCow } from 'stores/fetcher_sensor';
import { FetchWaiter, FetchError } from 'components/content/fetch-state';
import { GraphPager } from 'components/parts/graph-pager';
import { ResponsiveContainer, LineChart, Line, CartesianGrid, Tooltip, Legend, XAxis, YAxis, ReferenceLine } from 'recharts';
import moment from 'moment';
import { SensorHistoryCowDto } from 'api';
import { ICowNameInfo, CowToDispInfo } from 'components/parts/cows-popup';
import styles from './report-sensor.module.css';
import { ChartTooltip } from 'components/parts/chart-tooltip';

interface Props {
    ranchId: number;
    cow: ICowNameInfo;
    from: Date,
    to: Date,
    onNext: () => void;
    onPrev: () => void;
}

const COLOR_COW = "#ffa500";
const COLOR_AVG = "#808080";
const COLOR_HOUSE = "#00ccff";

export const SensorCowView = (props: Props) => {
    const { data, isLoading, isError } = useSensorCow(props.ranchId, props.cow.cow_id, props.from, props.to);

    const [ currentData, setCurrentData ] = useState<SensorHistoryCowDto & { epocSec: number, x:number }>();

    const dispList = useMemo(() => {
        if (data == null) return [];

        return data.map(d => ({ ...d, epocSec: moment(d.measured_at).unix() }))

    }, [ data ])

    const ticks = useMemo(() => {
        const tmp = moment(props.from).startOf("day");
        const to = moment(props.to).startOf("day");

        //期間内に含まれる日付の0時を列挙
        const list: number[] = [];
        while(tmp.isSameOrBefore(to)) {
            list.push(tmp.unix());
            tmp.add(1, "days");
        }
        return list;

    }, [ props.from, props.to ])

    const tooltipX = useMemo(() => {
        if (currentData == null) return 0;

        const MARGIN = 4;

        const idx = ticks.findIndex(t => currentData.epocSec <= t);
        if (idx < 0) return 0;

        //グラフ左半分のときは、選択日のすぐ右に表示
        if (idx <= (ticks.length / 2)) {
            return currentData.x + MARGIN;
        }
        //右半分のときは、簡易的に右端の固定位置に
        return -60;

    }, [ ticks, currentData ])

    useEffect(() => {
        setCurrentData(undefined);

    }, [ dispList ]);

    const onChartClick = useCallback(e => {
        if (e == null || e.activePayload == null || e.activePayload.length === 0) {
            setCurrentData(undefined);
            return;
        }
        const p = e.activePayload[0].payload;
        setCurrentData({ ...p, x: e.activeCoordinate.x });

    }, [])

    if (isLoading) return <FetchWaiter />
    if (isError || data == null) return <FetchError />

    const defaultLineProps = { type:"linear", strokeWidth:2, animationDuration:500, dot:{ r:2 }, activeDot:{ r: 5 } } as const;

    const repNo = CowToDispInfo(props.cow, false);

    return (
        <div className={styles["view-root"]}>
            <GraphPager containerStyle={{ lineHeight:1 }}
                hasPrev={true}
                hasNext={true}
                onPrev={props.onPrev}
                onNext={props.onNext}
            />

            <div style={{width:"calc(100% + 40px)", flex: 1, position:"relative", margin:"0 -20px" }}>
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={dispList} onClick={onChartClick}>
                        <Line {...defaultLineProps} unit="℃" dataKey="cow"     yAxisId={1} stroke={COLOR_COW} />
                        <Line {...defaultLineProps} unit="℃" dataKey="average" yAxisId={1} stroke={COLOR_AVG} strokeDasharray="5 5" />
                        <Line {...defaultLineProps} unit="℃" dataKey="house"   yAxisId={2} stroke={COLOR_HOUSE} />
                        <XAxis dataKey="epocSec" type="number" interval="preserveStartEnd" tick={{fontSize:"11px"}} tickMargin={1}
                            domain={["auto", "auto"]} scale="time"
                            ticks={ticks}
                            tickFormatter={e => moment.unix(e).format("M/D")} 
                        />

                        <YAxis yAxisId={1} orientation="left" domain={["auto","auto"]} />
                        <YAxis yAxisId={2} orientation="right" domain={["auto","auto"]} />
                        
                        <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
                        <Tooltip content={<></>} />
                        {/* 
                        * ※yAxisIdを指定しないとエラーになるので、存在する適当なものを指定
                        * 　あわせて、遷移時にエラーになるので「props.amountList.length > 0」を入れておく
                        */}
                        { currentData != null && dispList.length > 0 && (
                            <ReferenceLine x={currentData.epocSec} stroke="red" strokeDasharray="3 3" yAxisId={1} />
                        ) }
                        <Legend wrapperStyle={{ maxHeight:"120px", overflowY:"auto" }}
                            formatter={k => k === "cow" ? repNo : k === "average" ? "群平均" : "牛舎気温"}
                        />
                    </LineChart>
                </ResponsiveContainer>
                { currentData != null && (
                    // ※標準のTooltipは、スタイルの自作はできるが、表示非表示の手動制御ができず、
                    // 　state更新などのトリガで勝手に閉じてしまうので、自前で作り直す
                    //　（マウスオーバーでのフォーカスライン表示だけしたいので Tooltip 部品も残しておく）
                    <ChartTooltip
                        headers={[moment.unix(currentData.epocSec).format("YYYY/M/D HH:mm")]}
                        items={[
                            { name:repNo, value: currentData.cow, color: COLOR_COW, unit:"℃" },
                            { name:currentData.average_count + "頭平均", value: currentData.average, color: COLOR_AVG, unit:"℃" },
                            ...(currentData.house != null ? [{ name:"牛舎気温", value: currentData.house, color: COLOR_HOUSE, unit:"℃" }] :[]),
                        ]}
                        onClose={() => setCurrentData(undefined)}
                        x={tooltipX} y={-52}
                    />
                )}
            </div>

        </div>
    )

}