import * as firebase from 'firebase/app';
import { Configuration } from "./configuration";

import {
  CowControllerApiFp,
  EventControllerApiFp,
  FeedControllerApiFp,
  PreventionControllerApiFp,
  RanchControllerApiFp,
  SymptomControllerApiFp,
  TeamControllerApiFp,
  UserControllerApiFp,
  BreedingControllerApiFp,
  DeliveryControllerApiFp,
  RutControllerApiFp,
  ActivityControllerApiFp,
  NoteControllerApiFp,
  BalanceControllerApiFp,
  SellCowControllerApiFp,
  SellMilkControllerApiFp,
  ProgramControllerApiFp,
  GrowthControllerApiFp,
  ClinicControllerApiFp,
  OtherPlControllerApiFp,
  DynamicsControllerApiFp,
  EggControllerApiFp,
  RobotControllerApiFp,
  SensorControllerApiFp
} from "./api";

const defautlHeader = {
  'Content-Type': 'application/json'
}

const getToken = async () => await firebase.auth().currentUser?.getIdToken() ?? ''

const genConf = async () => new Configuration({ baseOptions:{ headers: {Authorization: `Bearer ${await getToken()}`, ...defautlHeader }}});

export const CowApi = async () => CowControllerApiFp(await genConf())
export const EventApi = async () => EventControllerApiFp(await genConf())
export const FeedApi = async () => FeedControllerApiFp(await genConf())
export const PreventionApi = async () => PreventionControllerApiFp(await genConf())
export const RanchApi = async () => RanchControllerApiFp(await genConf())
export const SymptomApi = async () => SymptomControllerApiFp(await genConf())
export const TeamApi = async () => TeamControllerApiFp(await genConf())
export const UserApi = async () => UserControllerApiFp(await genConf())
export const BreedingApi = async () => BreedingControllerApiFp(await genConf())
export const DeliveryApi = async () => DeliveryControllerApiFp(await genConf())
export const RutApi = async () => RutControllerApiFp(await genConf())
export const ActivityApi = async () => ActivityControllerApiFp(await genConf())
export const NoteApi = async () => NoteControllerApiFp(await genConf())
export const BalanceApi = async () => BalanceControllerApiFp(await genConf())
export const SellCowApi = async () => SellCowControllerApiFp(await genConf())
export const SellMilkApi = async () => SellMilkControllerApiFp(await genConf())
export const ProgramApi = async () => ProgramControllerApiFp(await genConf())
export const GrowthApi = async () => GrowthControllerApiFp(await genConf())
export const ClinicApi = async () => ClinicControllerApiFp(await genConf())
export const OtherPlApi = async () => OtherPlControllerApiFp(await genConf())
export const DynamicsApi = async () => DynamicsControllerApiFp(await genConf())
export const EggApi = async () => EggControllerApiFp(await genConf())
export const RobotApi = async () => RobotControllerApiFp(await genConf())
export const SensorApi = async () => SensorControllerApiFp(await genConf())


export * from "./api";
