import React, { useEffect, useState } from 'react';

import styles from './sellmilk.module.css'
import { SellMilk } from '.';
import moment from 'moment';
import classnames from 'classnames';
import Big from "big.js";
import { formatYen } from '../../config/util';
import { RequiredNumInput } from '../../components/parts/num-input';
import { V3DateTime } from '../../components/parts/v3-date-time-picker';
import { LMT, TIMEPRESETS } from '../../config/constant';
import { IUser } from '../../stores';

type PropType = {
  sellMilk: SellMilk,
  setSellMilk: React.Dispatch<React.SetStateAction<SellMilk>>,
  user: IUser,
}

const RegisterRanch: React.FC<PropType> = ({ sellMilk, setSellMilk, user }) => {

    const [price, setPrice] = useState<number>(0)
    const [discardPrice, setDiscardPrice] = useState<number>(0)
    const [totalAmount, setTotalAmount] = useState<number>(0)

    useEffect(() => { 
        const unit_price = Big(isNaN(sellMilk.unit_price) ? 0 : sellMilk.unit_price)
        const amount = Big(sellMilk.details[0].amount)
        const amount_discard = Big(sellMilk.details[0].amount_discard)
        setPrice(Number(unit_price.times(amount).round(0,1).toString())) // 小数第一位で四捨五入
        setDiscardPrice(Number(unit_price.times(amount_discard).round(0,1).toString())) // 小数第一位で四捨五入
    }, [sellMilk.unit_price]);

    useEffect(() => { 
        const unit_price = Big(sellMilk.unit_price)
        const amount = Big(isNaN(sellMilk.details[0].amount) ? 0 : sellMilk.details[0].amount)
        const amount_discard = Big(sellMilk.details[0].amount_discard)
        setPrice(Number(unit_price.times(amount).round(0,1).toString())) // 小数第一位で四捨五入
        setTotalAmount(Number(amount.plus(amount_discard).toString()))
    }, [sellMilk.details[0].amount]);

    useEffect(() => { 
        const unit_price = Big(sellMilk.unit_price)
        const amount = Big(sellMilk.details[0].amount)
        const amount_discard = Big(isNaN(sellMilk.details[0].amount_discard) ? 0 : sellMilk.details[0].amount_discard)
        setDiscardPrice(Number(unit_price.times(amount_discard).round(0,1).toString())) // 小数第一位で四捨五入
        setTotalAmount(Number(amount.plus(amount_discard).toString()))
    }, [sellMilk.details[0].amount_discard]);

    const rowClass = "form-group row treat-write-row";
    const rowHeaderClass = "col-form-label col-md-4 col-xs-4 text-lg-right";

    return (
        <div className="page-root">
            <div className="product product-full-height">
                <div className="product-detail" style={{ height: "100%" }}>
                    <div className="product-info product-info-fix" style={{ paddingBottom: "0px" }} >
                        <div className={styles["sellmilk-write"]}>
                            <div className={rowClass}>
                                <label className={rowHeaderClass}>記録日時</label>
                                <div className={styles["date-input"]}>
                                    <V3DateTime value={moment(sellMilk.watched_at).toDate()}
                                        timePresets={user.setting?.time_preset ?? TIMEPRESETS}
                                        onChange={d => setSellMilk({ ...sellMilk, watched_at: d.format("YYYY-MM-DD HH:mm") + ":00"})}
                                    />
                                </div>
                            </div>
                            <div className={rowClass}>
                                <label className={rowHeaderClass}>乳価</label>
                                <RequiredNumInput className={styles["number-input"]}
                                    min={LMT.SELLMILK.UNIT_PRICE_MIN} max={LMT.SELLMILK.UNIT_PRICE_MAX}
                                    step={LMT.SELLMILK.UNIT_PRICE_STEP}
                                    value={sellMilk.unit_price}
                                    onChange={v => setSellMilk({ ...sellMilk, unit_price: v})}
                                />
                                <label className={classnames("col-form-label", styles["label-unit"])}>円/kg</label>
                            </div>
                            <div className={rowClass}>
                                <label className={rowHeaderClass}>出荷乳量</label>
                                <RequiredNumInput className={styles["number-input"]}
                                    min={LMT.SELLMILK.AMOUNT_RANCH_MIN} max={LMT.SELLMILK.AMOUNT_RANCH_MAX}
                                    step={LMT.SELLMILK.AMOUNT_RANCH_STEP}
                                    value={sellMilk.details[0].amount}
                                    onChange={v => setSellMilk({ ...sellMilk, details: [{ ...sellMilk.details[0], amount: v }] })}
                                />
                                <label className={classnames("col-form-label", styles["label-unit"])}>kg</label>
                                <label className={classnames("col-form-label", styles["label-price"])}>{formatYen(price)}円</label>
                            </div>
                            <div className={rowClass}>
                                <label className={rowHeaderClass}>廃棄乳量</label>
                                <RequiredNumInput className={styles["number-input"]}
                                    min={LMT.SELLMILK.AMOUNT_RANCH_MIN} max={LMT.SELLMILK.AMOUNT_RANCH_MAX}
                                    step={LMT.SELLMILK.AMOUNT_RANCH_STEP}
                                    value={sellMilk.details[0].amount_discard}
                                    onChange={v => setSellMilk({ ...sellMilk, details: [{ ...sellMilk.details[0], amount_discard: v }] })}
                                />
                                <label className={classnames("col-form-label", styles["label-unit"])}>kg</label>
                                <label className={classnames("col-form-label", styles["label-price"])}>{formatYen(discardPrice)}円</label>
                            </div>
                            <hr className={styles["hr-border"]}/>
                            <div className={rowClass}>
                                <label className={rowHeaderClass}>合計乳量</label>
                                <label className={classnames("col-form-label")}>{formatYen(totalAmount)}kg</label>
                            </div>
                            <div className={rowClass}>
                                <label className={rowHeaderClass}>メモ</label>
                                <div style={{flex:1}}>
                                    <textarea className="form-control" rows={3} maxLength={LMT.SELLMILK.MEMO_LEN} value={sellMilk.details[0].comment}
                                              onChange={e => setSellMilk({ ...sellMilk, details: [{ ...sellMilk.details[0], comment: e.target.value }] })} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegisterRanch