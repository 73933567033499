import moment from 'moment';
import { CommonUtil } from '../../config/util';

export class Term {
    readonly from: Date;
    readonly to: Date;

    public static readonly MAX_DATE = moment("9999-12-31").toDate();
    public static readonly MIN_DATE = moment("1700-01-01").toDate();
    public isFromMin() {
        return moment(this.from).isSame(Term.MIN_DATE);
    }
    public isToMax() {
        return moment(this.to).isSame(Term.MAX_DATE);
    }

    constructor(from: Date | moment.Moment, to: Date | moment.Moment) {
        this.from = (from instanceof Date) ? from : from.toDate();
        this.to = (to instanceof Date) ? to : to.toDate();
    }

    public merge(another: Term): Term | null {
        if (!this.overlaps(another)) return null;
        if (this.includes(another)) return this;
        if (another.includes(this)) return another;

        const from = moment(this.from).isSameOrBefore(another.from) ? this.from : another.from;
        const to = moment(this.to).isSameOrAfter(another.to) ? this.to : another.to;
        return new Term(from, to);
    }

    public overlaps(another: Term) {
        return moment(this.from).isSameOrBefore(another.to) && moment(this.to).isSameOrAfter(another.from);
    }

    public includes(another: Term): boolean {
        return this.includesDate(another.from) && this.includesDate(another.to);
    }

    public includesDate(date: Date | moment.Moment): boolean {

        return moment(this.from).isSameOrBefore(date) && moment(this.to).isSameOrAfter(date);
    }
}

export class Terms {
    private readonly terms: Term[];

    constructor(terms?:Term[], checkDuplicates: boolean = true) {
        if (checkDuplicates) {
            this.terms = [];

            for (const t of terms ?? []) {
                this.add(t);
            }
        }
        else {
            this.terms = terms ?? [];
        }
    }

    public add(term: Term) {
        let merged: Term | null = null;
        let remIdx: number | null = null;

        for (let i = 0; i < this.terms.length; i++) {
            merged = this.terms[i].merge(term);
            if (merged != null) {
                remIdx = i;
                break;
            }
        }
        if (merged != null) {
            if (!CommonUtil.assertNotNull(remIdx, "remIdx", "Terms.add")) {
                return;
            }
            this.terms.splice(remIdx, 1, merged);
        }
        else {
            this.terms.push(term);
        }
    }

    public getUncontained(term: Term): Term | null {

        const included = this.terms.find(t => t.includes(term));
        if (included != null) return null;

        const overlaps = this.terms.filter(t => t.overlaps(term));
        if (overlaps.length === 0) return term;

        overlaps.sort((a,b) => moment(a.from).isSameOrBefore(b.from) ? -1 : 1);
        const first = overlaps[0];
        const last = overlaps[overlaps.length - 1];

        const from = moment(first.from).isSameOrBefore(term.from) ? first.to : term.from;
        const to = moment(last.to).isSameOrAfter(term.to) ? last.from : term.to;

        return new Term(from, to);
    }

    public clone(): Terms {
        return new Terms(this.terms, false);
    }
}