import queryString from 'query-string';
import React, { useState, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { A, LMT, TIMEPRESETS, EVENT_TYPE_PARAM, TreatBenefitType } from '../../config/constant';
import { PageSettings } from '../../config/page-settings.js';
import { withContext, IUser, ITreatKind, IMedicineCategory } from '../../stores';
import { TreatSelector, ITreatSelectorData, validateTreat, TREAT_VALIDATION_RESULT, convPresetToTreatSelectorData, convScheduledPresetToTreatSelectorData, convertTreatsToModifyReq } from '../../components/parts/treat-selector';
import { PreventionModifyReq, CowDetailDto, PreventionDto, TeamTreatPresetDto, PreventionApi, RanchSchedulePresetDto } from '../../api';
import moment from 'moment';
import { AppState } from '../../app';
import { IEventWriteLocationState, historyLocation, IEventWriteParamCow } from '../../config/history-location-builder';
import { NextScheduleSelector, NextSchedule, toNextScheduleReq } from '../../components/next-schedule/next-schedule-selector';
import { ScheduleFinder } from '../schedule/schedule-finder';
import { Collapse } from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styles from './prevention.module.css';
import { EVENT_KIND } from '../../config/event-kind';
import { V3DateTime } from '../../components/parts/v3-date-time-picker';
import { hasTeamAuth } from '../../config/auth-checker';
import { generateKey, FreezedArray, ar, CommonUtil } from '../../config/util';
import { IconLink } from '../../components/parts/icon-link';
import { TreatPresetSelectPopup } from '../../components/parts/treat-preset-select-popup';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { DIALOG_BUTTONS } from '../../components/form/form-dialog';
import { UserTeams } from '../../config/user-teams';
import { PreloadedProgramSelectPopupProps, PreloadedProgramSelectPopup, showPreloadedProgramSelectDialog } from '../program/preloaded-program-select-popup';
import { resetMedicines, resetTreatItems, usePresetsWithBothTeamMedicines } from '../../stores/fetcher';
import { Communicator } from '../../api/communicator';
import { ModifyReqTreat_fix } from '../../api/api-fix';
import { FetchWaiter, FetchError } from '../../components/content/fetch-state';
import { SingleCowHeader } from 'components/cow-header/single-cow-header';
import { getCowWithCache } from 'stores/fetcher_cow';
import { MultiCowHeader } from 'components/cow-header/multi-cow-header';

interface IPreventionDetail {
    medicine_id?: number;
    amount?: number;
    route_id?: number;
    unit_price: number;
    treat_kind_no?: number;
    treat_item_no?: number;
    note?: string;
    team_id: number;
    is_next_default_amount?: boolean;
    is_next_default_route?: boolean;
    lot_no?: string;
    expiring_month?: Date;
    benefit_type:TreatBenefitType;
}

interface MyState {
    cow_top: boolean;
    original?: PreventionDto;
    cow_ids: number[];
    cow?: CowDetailDto;
    ranchId: number;
    clinicId: number | undefined;
    executing: boolean;
    preloadedProgram?: PreloadedProgramSelectPopupProps;
}

class PreventionWrite extends Base<BaseProps<{id?:string},{},IEventWriteLocationState|undefined>, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            ranchId:0,
            clinicId:undefined,
            cow_top: false,
            cow_ids: [],
            executing: false,
        }

        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    componentDidMount() {
        if (this.handleNotAllowAccess(undefined, ["TREAT_EDIT"], [])) {
            return;
        }

        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        this.init();
    }

    async init () {
        const _mayId = parseInt(this.props.match.params.id ?? "");
        const edit_prevention_id = isNaN(_mayId) ? undefined : _mayId;
        const queryData = this.parseQuery(this.props.history.location.search);

        //validate
        if (edit_prevention_id != null) {
            if (queryData.cow_ids.length >= 2) {
                console.error("invalid location param", edit_prevention_id, queryData);
                return;
            }
        }
        if (edit_prevention_id == null) {
            if (queryData.cow_ids.length === 0) {
                console.error("invalid location param: no cow");
                return;
            }
        }

        this.context.handleSetHeader({ title:`予防処置を${edit_prevention_id == null ? "入力" : "編集"}` });

        const clinicId = this.props.rootStore.getClinicIdForMaster();
        const ranchId = this.props.rootStore.cur_ranch_id;

        let original: PreventionDto | undefined;
        let cow : CowDetailDto | undefined;

        if (edit_prevention_id != null) {
            //編集時
            original = await this.loadPrevention(ranchId, edit_prevention_id);
            if (original == null) return;

            if (this.navToRanchTopIfSelfEditOnly("PREVENTION", original.watched_by)) return;

            if (queryData.cow_ids.length === 1 && queryData.cow_ids[0] !== original.cow_id) {
                console.error("cow_id unmatch", queryData.cow_ids, original.cow_id);
                return;
            }

            cow = await this.loadCowInfo(ranchId, original.cow_id);
            if (cow == null) return;
        } else {
            //新規
            if (queryData.cow_ids.length === 1) {
                cow = await this.loadCowInfo(ranchId, queryData.cow_ids[0]);
                if (cow == null) return;
            }
        }

        this.setState({
            ranchId,
            clinicId,
            cow_top: queryData.cow_top,
            cow,
            cow_ids: cow != null ? [ cow.cow_id ] : queryData.cow_ids,
            original
        });
    }

    parseQuery(query: string): { cow_top: boolean, cow_ids: number[] } {
        const values = queryString.parse(query);
        const cow_top = values.cow_top != null && (Number(values.cow_top) === 1);

        if (values.param == null) {
            return { cow_top, cow_ids: [] };
        }

        const ids = Array.isArray(values.param) ? values.param : values.param.split(",");
        const cow_ids = ids.map(id => parseInt(id)).filter(id => !isNaN(id));
        return { cow_top, cow_ids };
    }

    onSave = async (vals: {
        watchedAt: Date,
        details: ModifyReqTreat_fix[],
        memo: string,
        plans: NextSchedule[],
    }) => {

        this.setState({ executing: true });

        const registered = await this.api_postPreventionWrite(vals);
        if (!registered) {
            this.setState({ executing: false });
            return;
        }
        
        if (this.state.original == null) {
            if (this.state.cow_top) {
                this.props.history.replace(historyLocation.toCowInfo(this.state.cow_ids[0]));
            } else {
                window.history.go(-1);
            }
        } else {
            const watchedAt = moment(vals.watchedAt);
            this.props.history.replace(historyLocation.toCowEvent(this.state.cow_ids[0], watchedAt.format("YYYY-MM-DD"), EVENT_TYPE_PARAM.SYMPTOM));
        }
    }

    onDelete = async () => {
        if (!CommonUtil.assertNotNull(this.state.original, "original", "delete")) return false;

        const confirmed = await this.context.showDialog("QUESTION", '記録を削除してよろしいですか？', DIALOG_BUTTONS.DELETE_CANCEL);
        if (confirmed !== 0) return;

        this.setState({ executing:true });

        const params = {
            is_new: 0,
            prevention_id: this.state.original.prevention_id,
            user_id: this.props.rootStore.user.id,
            ranch_id: this.state.ranchId,
            is_deleted: 1,
        }

        this.context.handleSetIsLoading(true);
        const res = await this.comm().send(() => this.context.postAsync('/prevention/modify', params));
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") {
            this.setState({ executing:false });
            return;
        }

        // アクティブ牛リストを再取得
        this.props.rootStore.fetchActiveCows(undefined, "DIFF");
        this.props.history.replace(
            historyLocation.toCowEvent(
                this.state.cow_ids[0], 
                moment(this.state.original.watched_at).format("YYYY-MM-DD"), 
                EVENT_TYPE_PARAM.SYMPTOM
            )
        );
    }


    private loadCowInfo = async (ranchId: number, cowId: number) => {
        const response = await this.comm().send(() => getCowWithCache(ranchId, cowId), { retries: true });

        return response.data;
    }

    private loadPrevention = async (ranchId: number, id: number) => {
        const url = `/prevention/${id}?user_id=${this.props.rootStore.user.id}&ranch_id=${ranchId}`;
        const response = await this.comm().send<PreventionDto>(() => this.context.postAsync(url, {}), { retries:true });
        return response.data;
    }    

    api_postPreventionWrite = async (vals: {
        watchedAt: Date,
        details: ModifyReqTreat_fix[],
        memo: string,
        plans: NextSchedule[],
    }): Promise<boolean> => {

        const watchedAt = moment(vals.watchedAt);

        const plans = vals.plans.map(p => toNextScheduleReq(p, this.state.clinicId));

        const isNew = this.state.original == null;
        const params: PreventionModifyReq = {
            prevention_id: this.state.original?.prevention_id,
            is_new: isNew ? 1 : 0,
            ranch_id: this.state.ranchId,
            cow_ids: this.state.cow_ids,
            watched_at: watchedAt.format("YYYY-MM-DD HH:mm"),
            comment: vals.memo,
            detail: vals.details,
            is_deleted: 0,
            schedule_id: this.props.location.state?.schedule_id,
            plans: plans,
            preloaded_program: { tags:[], list:[] }
        };

        //関連する予定を探す
        if (params.schedule_id == null && params.cow_ids.length === 1 && params.is_new === 1) {
            const day = watchedAt.format("YYYY-MM-DD");

            const finder = new ScheduleFinder(this.context, this.state.ranchId, this.props.rootStore.user.id);
            const scheRes = await finder.findEventRelatedSchedule(day, [ EVENT_KIND.PREVENTION.no ], params.cow_ids[0]);

            if (scheRes.result === "cancel") return false;

            if (scheRes.result === "yes") {
                params.schedule_id = scheRes.id;
            }
        }

        if (isNew) {
            //プログラムの確認
            const cows = this.state.cow != null ? [ this.state.cow ] : this.props.location.state?.cows ?? [];

            const pgReq = await showPreloadedProgramSelectDialog(
                this.context,
                async () => this.comm().send((await PreventionApi()).getProgramsForPreventionUsingPOST({ eventReq:params, clinic_id:this.state.clinicId })),
                this.props.rootStore,
                params.ranch_id,
                this.state.clinicId,
                st => this.setState({ preloadedProgram:st }),
                cows
            );
            if (pgReq == null) return false;
            params.preloaded_program = pgReq;
        }

        this.context.handleSetIsLoading(true);
        const response = await this.comm().send((await PreventionApi()).modifyPreventionUsingPOST(params));
        this.context.handleSetIsLoading(false);

        if (response.result === "OK") {
            const detailTeamId = this.state.clinicId ?? this.state.ranchId;
            //薬マスタを再取得しておく
            resetMedicines(detailTeamId, false);
            //処置作成があれば再取得
            if (vals.details.some(t => t.new_treat_item_name != null)) {
                resetTreatItems(detailTeamId, false);
            }

            // アクティブ牛リストを再取得
            await this.props.rootStore.fetchActiveCows(undefined, "DIFF");
            return true;

        } else {
            return false;
        }
    }

    render() {
        //初期化前
        if (this.state.cow_ids.length === 0) return <FetchWaiter />

        const user = this.props.rootStore.user;
        const isOfficial = new UserTeams(user).findOfficialRanch(this.state.ranchId) != null;

        const options = this.props.rootStore.options;

        return (
            <div className="page-root">
                <PreventionWriteContent
                    user={user}
                    ranchId={this.state.ranchId}
                    clinicId={this.state.clinicId}
                    isOfficial={isOfficial}
                    paramCows={this.props.location.state?.cows}
                    cow={this.state.cow}
                    cow_ids={this.state.cow_ids}
                    original={this.state.original}
                    schedulePreset={this.props.location.state?.preset}

                    showAlert={this.context.showAlert}
                    showToast={this.context.showToast}
                    comm={this.comm()}
                    executing={this.state.executing}

                    medicineCategories={options.medicine_category}
                    treatKinds={options.treat_kind}

                    onDelete={this.onDelete}
                    onSave={this.onSave}
                />
                { this.state.preloadedProgram && (
                    <PreloadedProgramSelectPopup
                        {...this.state.preloadedProgram}
                    />
                )}
            </div>
        )

    }
}

interface ContentProps {
    user: IUser;
    ranchId: number;
    clinicId: number | undefined;
    isOfficial: boolean;
    paramCows: IEventWriteParamCow[] | undefined;
    cow: CowDetailDto | undefined;
    cow_ids: Readonly<number[]>;
    original: PreventionDto | undefined;

    schedulePreset: RanchSchedulePresetDto | undefined;
    showAlert: (err:string) => void;
    comm: Communicator;
    showToast: (msg:string) => void;
    executing: boolean;

    treatKinds: FreezedArray<ITreatKind>;
    medicineCategories: FreezedArray<IMedicineCategory>;

    onDelete: () => void;
    onSave: (values:{
        watchedAt: Date,
        details: ModifyReqTreat_fix[],
        memo: string,
        plans: NextSchedule[],
    }) => void;
}

const PreventionWriteContent = React.memo((props: ContentProps) => {
    const detailTeamId = props.clinicId ?? props.ranchId;

    const mPresets = usePresetsWithBothTeamMedicines(props.ranchId, props.clinicId);

    const [ watchedAt, setWatchedAt ] = useState(new Date());
    const [ memo, setMemo ] = useState("");
    const [ details, setDetails ] = useState<Array<IPreventionDetail & { key: string }>>([]);
    const [ plans, setPlans ] = useState<FreezedArray<{ isSelected: boolean, schedule: NextSchedule }>>([]);
    const [ isNextScheduleOpen, setIsNextScheduleOpen ] = useState(false);
    const [ isPresetShown, setIsPresetShown ] = useState(false);

    //initialize
    useEffect(() => {
        if (mPresets.data == null) return;

        if (props.original != null) {
            const received = props.original;

            setWatchedAt(moment(received.watched_at).toDate());
            setMemo(received.comment);
            setDetails(received.detail.map(d => ({
                ...d,
                expiring_month: d.expiring_day == null ? undefined : moment(d.expiring_day).toDate(), 
                benefit_type:"NO_BENEFIT",
                key: generateKey(),
            })));
        
        } else if (props.schedulePreset != null) {
            const preset = props.schedulePreset;
            const list = mPresets.data;
    
            const converted = preset.items.map(pi => 
                convScheduledPresetToTreatSelectorData(pi,
                    preset.team_id,
                    props.ranchId,
                    props.clinicId,
                    list.ranchMedicines,
                    list.clinicMedicines,
                    list.medicineRoutes,
                    list.treatItems,
                    hasTeamAuth("MASTER_EDIT", "MASTER_EDIT", detailTeamId, props.user)
                )
            );
            setDetails(ar.notNull(converted).map(t => ({ ...t, key: generateKey() })));
        
        } else {
            //1件表示しておく
            setDetails([buildDefaultDetail(props.clinicId ?? props.ranchId)]);
        }

        const at = props.original != null ? props.original.watched_at : watchedAt;
        const nextDay = moment(at).add(1, "day").toDate();
        setPlans([
            {
                isSelected: false,
                schedule: { title:"", note:"", allDay:true, start: nextDay, end: nextDay, eventKindNo: EVENT_KIND.PREVENTION.no, preset:undefined }
            }
        ]);

    }, [ props.ranchId, props.clinicId, props.original, mPresets.data == null, props.schedulePreset ]);


    const onAddDetail = useCallback(() => {
        const newDetail = buildDefaultDetail(props.clinicId ?? props.ranchId);
        setDetails([ ...details, newDetail ]);
    }, [ details, props.ranchId, props.clinicId ]);

    const buildDefaultDetail = useCallback((teamId: number) => {
        const newDetail: IPreventionDetail & {key:string} = {
            unit_price: 0,
            treat_kind_no: undefined,
            team_id: teamId,
            benefit_type:"NO_BENEFIT",
            key: generateKey()
        };

        return newDetail;
    }, []);

    const onRemTreatment = useCallback((idx: number) => {
        setDetails(details.filter((_,i) => i !== idx));

    }, [ details ]);

    const onTreatUpdated = useCallback((idx: number, data: ITreatSelectorData) => {
        const newDetails = [...details];
        newDetails[idx] = { ...data, key: details[idx].key }

        //薬品重複
        if (data.treat_kind_no == null || data.treat_kind_no === 0) {
            if (data.medicine_id != null && data.medicine_id > 0
                && newDetails.some((d,i) => i !== idx && d.medicine_id === data.medicine_id && d.team_id === data.team_id)) {
            
                props.showToast(A.MESSAGE.MEDICINE_ALREADY_ADDED);
                return;
            }
        }
        //処置重複
        else {
            if (data.treat_item_no != null && data.treat_item_no > 0
                && newDetails.some((d,i) => i !== idx && d.treat_kind_no === data.treat_kind_no && d.treat_item_no === data.treat_item_no)) {
                
                props.showToast(A.MESSAGE.TREAT_ITEM_ALREADY_ADDED);
                return;
            }
        }

        setDetails(newDetails);

    }, [ details, props.showToast ]);


    const onAddNextSchedule = useCallback(() => {
        const date = moment(watchedAt).add(1, "day").toDate();
        const newSchedule = { title:"", start: date, end: date, note: "", allDay: true, eventKindNo: EVENT_KIND.PREVENTION.no, preset:undefined };
        setPlans([
            ...plans,
            { isSelected: true, schedule: newSchedule },
        ]);

    }, [ watchedAt, plans ]);

    const setPreset = (preset: TeamTreatPresetDto) => {
        if (!CommonUtil.assertNotNull(mPresets.data, "mPreset")) return;

        const teamId = props.clinicId ?? props.ranchId;
        const list = mPresets.data;

        const newDetails = ar.notNull(preset.items.map(p =>
            convPresetToTreatSelectorData(
                p,
                teamId,
                list.clinicMedicines ?? list.ranchMedicines,
                list.medicineRoutes,
                list.treatItems
            )));
        
        setDetails(newDetails.map(d => ({ ...d, key:generateKey() })));
        setIsPresetShown(false);
    }


    const onSave = () => {
        if (!CommonUtil.assertNotNull(mPresets.data, "masters.data", "submit")) return;

        // 処置入力チェック
        const validDetails = new Array<IPreventionDetail>();
        for (const detail of details) {
            const validated = validateTreat(detail, mPresets.data.treatItems, true);
            if (validated === "BLANK") continue;
            if (validated !== "OK") {
                props.showToast(TREAT_VALIDATION_RESULT[validated].msg);
                return;
            }
            validDetails.push(detail);
        }
        const reqDetails = convertTreatsToModifyReq(validDetails, mPresets.data.medicineRoutes);

        if (reqDetails.length === 0) {
            props.showToast(A.MESSAGE.INVALID_PREVENTION_DETAILS);
            return;
        }

        props.onSave({
            watchedAt,
            details: reqDetails,
            memo,
            plans: plans.filter(p => p.isSelected).map(p => p.schedule)
        });
    }


    if (mPresets.isLoading) return <FetchWaiter />
    if (mPresets.isError || mPresets.data == null) return <FetchError />
    if (props.cow_ids.length === 0) return <></>;

    const collapseRowClass = "row-no-margin";
    const collapseHeaderClass = "col-form-label col-md-4 col-xs-5 clickable";
    const collapseArrowClass = (isOpen : boolean) => "fas fa-lg fa-fw " + (isOpen ? "fa-angle-up" : "fa-angle-down");
    const canEditMaster = hasTeamAuth("MASTER_EDIT", "MASTER_EDIT", detailTeamId, props.user);


    return (<>
        <div className="product product-full-height">
            <div className="product-detail" style={{ height: "100%" }}>
                <div className="product-info product-info-fix p-b-0">
                    <div className="product-info-header">
                        {
                            props.cow != null && (
                                <SingleCowHeader
                                    ranchId={props.ranchId}
                                    cowId={props.cow.cow_id}
                                />
                            )
                        }
                        {
                            props.cow_ids.length > 1 && (
                                <MultiCowHeader cowCount={props.cow_ids.length} cows={props.paramCows} infoName="予防記録" />
                            )
                        }
                    </div>

                    <div className="product-body">
                        <div className="form-group row-no-margin">
                            <label className="col-form-label col-md-4 col-xs-4 text-lg-right">予防処置日時</label>
                            <div style={{ flex:1 }}>
                                <V3DateTime
                                    value={watchedAt}
                                    timePresets={props.user.setting?.time_preset ?? TIMEPRESETS}
                                    onChange={m => setWatchedAt(m.toDate())}
                                />
                            </div>
                        </div>

                        <div className="treat-block-header">
                            <label>予防処置</label>
                            <IconLink text="プリセット呼び出し" iconType="popup" onClick={() => setIsPresetShown(true)}/>
                        </div>

                        {
                            details.map((detail, i) => (
                                <TreatSelector data={{ ...detail }} key={detail.key} index={i}
                                    onChange={d => onTreatUpdated(i,d)} onDelete={() => onRemTreatment(i)}
                                    treatKinds={props.treatKinds}
                                    medicineCategories={props.medicineCategories}
                                    treatKindMedicineName={"予防薬"} 
                                    weightInfo={{ std_weight: props.cow?.std_weight ?? null, birthday: props.cow?.birthday ?? null }} 
                                    filterMedicine={m => m.is_prevention === 1}
                                    hasAuthToEditMaster={canEditMaster}
                                    usesLotForVaccine={true}
                                    isOfficialRanch={props.isOfficial}
                                    ranchId={props.ranchId}
                                    clinicId={props.clinicId}
                                />
                            ))
                        }

                        <div className="form-group row-no-margin"
                            style={{ display: "flex", marginLeft: "0px", marginRight: "0px" }}>
                            <button style={{ flex: "1" }} className="btn btn-theme btn-sm btn-inverse"
                                onClick={onAddDetail}>
                                <i className="fas fa-lg fa-fw m-r-10 fa-plus-circle"></i>
                                予防処置を追加
                            </button>
                        </div>

                        <div className="form-group row-no-margin">
                            <label className="col-form-label col-md-2 col-xs-2 text-lg-right">メモ</label>
                            <div className="col-md-10 col-xs-10">
                                <textarea className="form-control" rows={4} maxLength={LMT.PREVENTION.MEMO_LEN} data-testid="memo"
                                    value={memo} onChange={e => setMemo(e.target.value)}></textarea>
                            </div>
                        </div>

                        <div className={collapseRowClass}>
                            <div className={collapseHeaderClass}
                                data-testid="予定開閉"
                                onClick={() => setIsNextScheduleOpen(!isNextScheduleOpen)}>
                                <span>予定を設定</span>
                                <i className={collapseArrowClass(isNextScheduleOpen)}></i>
                            </div>
                        </div>
                        <Collapse isOpen={isNextScheduleOpen}>
                            <TransitionGroup>
                            { plans.map((plan, i) => (
                                <CSSTransition classNames={{ enter: styles["plan-enter"], enterActive:styles["plan-enter-active"] }} key={i} timeout={500}>
                                <div>
                                <NextScheduleSelector
                                    index={i}
                                    isSelected={plan.isSelected}
                                    baseDate={moment(watchedAt)}
                                    value={plans[i].schedule}
                                    onChange={(selected,val) => setPlans([
                                        ...plans.slice(0, i),
                                        { isSelected: selected, schedule: val },
                                        ...plans.slice(i+1)
                                    ])}
                                    presetTeamId={detailTeamId}
                                    ranchId={props.ranchId}
                                />
                                </div>

                            </CSSTransition>
                            ))}
                            </TransitionGroup>
                            <div className="form-group row"
                                style={{ marginLeft: "8px", marginRight: "0px", marginTop: "10px" }}>
                                <button style={{ flex: "1" }} className="btn btn-theme btn-black"
                                    onClick={onAddNextSchedule}>
                                    <i className="fas fa-lg fa-fw m-r-10 fa-plus-circle"></i>
                                    予定を追加
                                </button>
                            </div>
                        </Collapse>
                    </div>

                </div>
            </div>
        </div>

        <div className="button-page-footer">
            <ExecutionButton type="save" onClick={onSave} disabled={props.executing} />
            {props.original != null && (
                <ExecutionButton type="delete" onClick={props.onDelete} disabled={props.executing} />
            )}
        </div>

        { isPresetShown && (
            <TreatPresetSelectPopup
                presets={mPresets.data.presets}
                medicinesOnly={false}
                medicines={mPresets.data.clinicMedicines ?? mPresets.data.ranchMedicines}
                routes={mPresets.data.medicineRoutes}
                treatItems={mPresets.data.treatItems}
                onClose={() => setIsPresetShown(false)}
                onSubmit={setPreset}
            />
        )}
    </>)
});


export default withRouter(withContext(PreventionWrite));