import React, { useState, useEffect } from 'react';
import img from '../../assets/icon/calf.png';
import ToolTip from 'rc-tooltip';
import { A } from '../../config/constant';

export interface CalfIconProps {
    use_no: number | undefined;
    age: number | undefined;
    borderAge: number;
    style?: React.CSSProperties;
    className?: string;
}

export const CalfIcon = React.memo<CalfIconProps>((props) => {
    const [ isON, setIsON ] = useState(false);

    useEffect(() => {
        if (props.age == null || props.use_no == null || props.use_no !== A.CHILD_USE_NO) {
            setIsON(false);
            return;
        }
        setIsON(props.borderAge <= props.age);

    }, [ props.use_no, props.age, props.borderAge ]);

    const defaultStyle: React.CSSProperties = {
        height: "22px",
    };

    if (!isON) return <></>

    return (
        <ToolTip placement="bottom" trigger={['click']} overlay="育成牛から他用途への変更を推奨">
            <img src={img} className={props.className}
                style={{ ...defaultStyle, ...props.style }} 
                alt="育成牛"
            />
        </ToolTip>
    );
});
