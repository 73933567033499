import '@fortawesome/fontawesome-free/css/all.min.css';
// ※テンプレートのCSS内に埋め込まれていて二重になるのでimportしない
// import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'simple-line-icons/css/simple-line-icons.css';
import App from './app';
import './index.css';
import './scss/react.scss';
import { StoreProvider } from './stores';
import 'rc-tooltip/assets/bootstrap.css';

ReactDOM.render(
    <BrowserRouter>
        <StoreProvider>
            <App />
        </StoreProvider>
    </BrowserRouter>,
    document.getElementById('root')
);