import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { PageSettings } from '../../config/page-settings';
import { QueryUtil, FreezedArray } from '../../config/util';
import { withContext, IRanchFeed } from '../../stores';
import { FeedRemainedContent, FeedRemainedContentProps } from './feed-remained-content';
import { WithQuery, useRanchFeeds } from '../../stores/fetcher';
import { FetchError, FetchWaiter } from '../../components/content/fetch-state';

interface MyProps extends BaseProps {
}

interface MyState {
}

class FeedRemained extends Base<MyProps, MyState> {

    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
        };

    }

    componentDidMount() {

        this.context.handleSetHeader({ title:"食べ残しの入力", backBtn:false });
        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        if (this.handleNotAllowAccess(undefined, ["FOODING_EDIT"], [])) {
            return;
        }
    }

    render() {
        const ranchId = this.props.rootStore.cur_ranch_id;


        return (
            <WithQuery query={() => useRanchFeeds(ranchId)}>
                {({ data, isError, isLoading }) => {
                    if (isLoading) return <FetchWaiter />
                    if (isError || data == null) return <FetchError />

                    return this.renderPage(ranchId, data);
                }}
            </WithQuery>
        )
    }

    renderPage(ranchId: number, ranchFeedList: FreezedArray<IRanchFeed>) {
        const cowIds = QueryUtil.parseNumsFromSearch(this.props.location.search, "param");

        const props: FeedRemainedContentProps = {
            cow_ids: cowIds.join(","),
            feed_types: this.props.rootStore.options.feed_type,
            ranch_feeds: ranchFeedList,
            user_id: this.props.rootStore.user.id,
            ranch_id: ranchId,
            from_days_before:6,
            to_days_before:0,
            comm:this.comm()
        };

        return (
            <div style={{ height: "100%", display: "flex", flexFlow: "column nowrap", maxWidth:"840px", margin:"0 auto" }}>
                <div className="product" style={{ height: "100%", "minHeight": "0" }}>
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix">

                            <div className="ranch-main" style={{flex: "auto" }}>
                                <FeedRemainedContent {...props} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(withContext(FeedRemained));