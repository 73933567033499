import React, { useState, useEffect } from 'react';

import { OptionalInputContainer } from '../../components/parts/optional-input-container';
import { ALL_SYMPTOM_STATUSES } from '../../config/constant';
import classnames from 'classnames';
import styles from './symptom.module.css';

export interface SymptomStatusProps {
    isSelected: boolean;
    value: number;
    onChange: (selected: boolean, value: number) => void;
    isEpidemic: boolean;
    outcomeOnly?: boolean;
    pregOnly: boolean;
    className?: string;
}

export const SymptomStatus: React.FC<SymptomStatusProps> = (props) => {
    const [ statusList, setStatusList ] = useState<{ no:number, name:string }[]>([]);

    useEffect(() => {
        const list = ALL_SYMPTOM_STATUSES()
                .filter(s => props.isEpidemic === (s.epidemic ?? false))
                .filter(s => !props.outcomeOnly || !s.hasNext)
                .filter(s => !props.pregOnly || s.preg === true);
        setStatusList(list);

        console.assert(list.length !== 0);

        if (!list.some(s => s.no === props.value)) {
            props.onChange(props.isSelected, list[0].no);
        }

    }, [ props.isEpidemic, props.value, props.outcomeOnly, props.pregOnly ]);

    const onStatusSelected = (value: string) => {
        const status = parseInt(value);
        props.onChange(true, status);
    }

    return (
        <div className={props.className} style={{marginLeft:"8px"}} data-testid="symptom-status">
            <div className="checkbox checkbox-css" style={{marginBottom:"4px"}}>
                <input type="checkbox" id={`chkSymptomStatus`} onChange={e => props.onChange(e.target.checked, props.value)} 
                    checked={props.isSelected}/>
                <label htmlFor={`chkSymptomStatus`}>{ props.outcomeOnly ? "転帰" : "状態または転帰"}</label>
            </div>
            <OptionalInputContainer isSelected={props.isSelected} containerStyle={{display:"flex", flexDirection:"column"}}>
                <div style={{background:props.isSelected ? "transparent" : "#e5e5e5"}}>
                    { statusList.map((s,i) => (
                        <div className={classnames("radio", "radio-css", "radio-inline", styles["symptom-status"])} key={s.no}>
                            <input type="radio" name={`radioSymptomStatus`} id={`radioSymptomStatus${i}`} 
                                checked={props.value === s.no} value={s.no}
                                onChange={ e => onStatusSelected(e.target.value)} />
                            <label htmlFor={`radioSymptomStatus${i}`}>{s.name}</label>
                        </div>
                    ))}
                </div>
            </OptionalInputContainer>
        </div>
    )
}