import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import styles from './top.module.css';
import { NoteDto } from '../../api';
import moment from 'moment';
import { LMT } from '../../config/constant';
import { LoadMore } from '../../components/parts/load-more';
import { IconLink } from '../../components/parts/icon-link';

interface MemoItemProps {
    isEditMode: boolean;
    onEditRequest: () => void;
    onDeleteRequest: () => void;
    onSubmit: (text: string) => void;
    onCancel: () => void;
    original?: NoteDto;
    canEdit: boolean;
    isSubmitExecuting: boolean;
}

const MemoItem: React.FC<MemoItemProps> = (props) => {
    const [ text, setText ] = useState("");

    useEffect(() => {
        setText(props.original?.note ?? "")

    }, [ props.original ]);

    return (
        <section className={ styles.memo } data-testid="ノート">
            { props.isEditMode && (<>
                <textarea className="form-control" maxLength={LMT.NOTE.TEXT_LEN} value={ text } data-testid="ノート入力"
                    onChange={e => setText(e.target.value) } />
                <div className={ styles.buttons }>
                    <div className="link" onClick={props.onCancel}>キャンセル</div>
                    <button className="btn btn-sm btn-success" onClick={() => props.onSubmit(text)}
                        disabled={ props.isSubmitExecuting || text === "" }>確定</button>
                </div>
            </>)}
            { !props.isEditMode && props.original != null && (<>
                <div className={ styles.memoheader }>
                    <div className={ styles.date }>{ moment(props.original.created_at).format("M月D日 HH:mm") } <span>{ props.original.created_by_name }</span></div>
                    { props.canEdit && (
                        <span className={ styles.icon }>
                            <i className="fas fa-edit clickable" onClick={ props.onEditRequest }></i>
                            <i className="fas fa-times clickable" onClick={ props.onDeleteRequest }></i>
                        </span>
                    )}
                </div>
                <div className={ styles.text } data-testid="ノート本文">
                    { props.original.note.split("\n").map((l,i) => <span key={i}>{l}<br/></span>) }
                </div>
            </>)}
        </section>
    );
}

export interface MemoProps {
    ranchId: number;
    userId: string;
    notes: NoteDto[];
    onSubmit: (note_id: number | undefined, text: string) => Promise<boolean>;
    onDelete: (note_id: number) => void;
    hasMoreNotes: boolean;
    onLoadMore: () => void;
}

type INote = NoteDto & {
    isEditMode: boolean;
}

const Memo: React.FC<MemoProps> = (props) => {
    const [ notes, setNotes ] = useState<INote[]>([]);
    const [ newNote, setNewNote ] = useState<string>();
    const [ executing, setExecuting ] = useState(false);

    useEffect(() => {
        setNotes(props.notes.map(n => ({ ...n, isEditMode: false })));

    }, [ props.notes ]);

    const onSubmitEdit = async (id: number, text: string) => {
        setExecuting(true);
        await props.onSubmit(id, text);
        setExecuting(false);
    }

    const onSubmitNewNote = async (text: string) => {
        setExecuting(true);
        const res = await props.onSubmit(undefined, text);
        if (res) {
            setNewNote(undefined);
        }
        setExecuting(false);
    }

    return (<>
        <p className={classnames(styles.menuheader, styles.memoareaheader)}>共有ノート</p>

        <div className={ styles.memoarea }>
            { newNote == null && (

                <div className={styles.addmemo}>
                    <IconLink text="ノートを追加" iconType="add" onClick={() => setNewNote("")} />
                </div>
            )}
            { newNote != null && (
                <MemoItem
                    isEditMode={true}
                    canEdit={true}
                    onDeleteRequest={()=>{ console.error("delete requested on new note") }}
                    onEditRequest={()=>{ console.error("edit requested on new note") }}
                    onSubmit={text => onSubmitNewNote(text) }
                    onCancel={()=>setNewNote(undefined)}
                    isSubmitExecuting={executing}
                />
            )}

            { notes.map((n) => (
                <MemoItem key={n.note_id}
                    original={n}
                    isEditMode={n.isEditMode}
                    canEdit={ props.userId === n.created_by }
                    onCancel={ () => { setNotes(
                        notes.map(nn => nn.note_id === n.note_id ? { ...nn, isEditMode: false } : nn)
                    ) } }
                    onDeleteRequest={() => props.onDelete(n.note_id)}
                    onEditRequest={ () => { setNotes(
                        notes.map(nn => nn.note_id === n.note_id ? { ...nn, isEditMode: true } : nn)
                    ) } }
                    onSubmit={ text => onSubmitEdit(n.note_id, text) }
                    isSubmitExecuting={executing}
                />
            ))}
            { props.hasMoreNotes && (
                <LoadMore className={styles.morenote} onClick={props.onLoadMore} />
            )}

        </div>
    </>)
}

export default Memo;
