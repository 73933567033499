import axios from 'axios';
import ja from 'date-fns/locale/ja';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { K } from './constant';

const $ = window.jQuery = require('jquery');
window.moment = require('moment');

//dropdownなどで必要
require('bootstrap');

$.fn.center = function () {
    return $(this).addClass('text-center');
}
$.fn.right = function () {
    return $(this).addClass('text-right');
}
$.fn.pointer = function () {
    return $(this).css('cursor', 'pointer');
}
$.color = function (data, color) {
    return data ? $('<span class="text-' + color + '">' + data + '</span>') : data;
}
$.fn.htmlAll = function () {
    //let args = [];
    let el = $(this).html('');
    for (let i = 0; i < arguments.length; i++) {
        let arg = arguments[i];
        if (typeof arg !== 'object') {
            if (typeof arg === 'number') {
                if (isNaN(arg)) {
                    arg = '-';
                } else {
                    let numbers = [];
                    while (arg > 1000) {
                        let d = arg % 1000;
                        numbers.splice(0, 0, d >= 100 ? d : d >= 10 ? '0' + d : '00' + d);
                        arg = Math.floor(arg / 1000);
                    }
                    numbers.splice(0, 0, arg);
                    arg = numbers.join(',');
                }
            }
        }
        el.append(arg);
        if (i < arguments.length - 1) {
            el.append('<br>');
        }
    }
    $('[data-toggle="popover"]', el).popover({ trigger: 'hover' });
    return el;
}
$.fn.htmlCheck = function (value, id) {
    return $(this).html(`
    <div class="checkbox checkbox-css checkbox-inline">
        <input type="checkbox" name="`+ id + `" id="` + id + `" ` + (value ? 'checked' : '') + `/>
        <label htmlFor="`+ id + `"></label>
    </div>
    `).on('click', function (e) {
        e.stopPropagation();
        let $input = $('input', this);
        let checked = !$input[0].checked;
        if (checked) {
            $input.attr('checked', '');
        } else {
            $input.removeAttr('checked');
        }
        $(this).trigger('clicked', checked);
    }).center();
}
$.fn.htmlSub1 = function (data, cb) {
    let el = $(this).html('').addClass('has-sub').attr('data-count', data.length);
    for (let i in data) {
        let sub1 = $('<div class="td-sub sub1"></div>').attr('data-index', i);
        cb.call(this, sub1, data[i]);
        el.append(sub1);
    }
    return el;
}
$.fn.htmlSub2 = function (data, name, cb) {
    let el = $('<div></div>');
    for (let i in data) {
        let sub1 = $('<div class="sub1"></div>').attr('data-index', i).attr('data-count', data[i][name].length);
        if (data[i][name].length > 0) {
            for (let k in data[i][name]) {
                let sub2 = $('<div class="td-sub sub2"></div>').attr('data-index', k);
                cb.call(this, sub2, data[i][name][k], data[i]);
                sub1.append(sub2);
            }
        } else {
            let sub2 = $('<div class="td-sub sub2"></div>').attr('data-index', 0);
            cb.call(this, sub2, null, data[i]);
            sub1.append(sub2);
        }
        el.append(sub1);
    }
    return $(this).html('').append(el).addClass('has-sub').attr('data-count', data.length);
}
$.fn.adjustSub = function () {
    let $row = $(this);
    setTimeout(function () {
        let height_row = $row.outerHeight();
        let $sub1 = $('.sub1', $row);
        let $td = $sub1.parents('td');
        let heights1 = [];
        let i, j, k;
        for (i = 0; i < $td.length; i++) {
            let $sub1 = $('.sub1', $td[i]);
            for (j = 0; j < $sub1.length; j++) {
                if (!heights1[j]) {
                    heights1[j] = $($sub1[j]).outerHeight();
                } else {
                    heights1[j] = Math.max(heights1[j], $($sub1[j]).outerHeight());
                }
            }
        }
        let $sub2 = $('.sub2', $row);
        let heights2 = [];
        let level2 = $sub2.length > 0;
        if (level2) {
            $td = $sub2.parents('td');
            for (i = 0; i < $td.length; i++) {
                $sub1 = $('.sub1', $td[i]);
                for (j = 0; j < $sub1.length; j++) {
                    if (!heights2[j]) {
                        heights2[j] = [];
                    }
                    $sub2 = $('.sub2', $sub1[j]);
                    for (k = 0; k < $sub2.length; k++) {
                        if (!heights2[j][k]) {
                            heights2[j][k] = $($sub2[k]).outerHeight();
                        } else {
                            heights2[j][k] = Math.max(heights2[j][k], $($sub2[k]).outerHeight())
                        }
                    }
                }
            }
        }
        let height1 = 0;
        let heights2_sum = [];
        for (j = 0; j < heights1.length; j++) {
            heights2_sum[j] = 0;
            if (level2) {
                for (k = 0; k < heights2[j].length; k++) {
                    heights2_sum[j] += heights2[j][k]
                }
                if (heights2_sum[j] > heights1[j]) {
                    heights1[j] = heights2_sum[j];
                }
            }
            height1 += heights1[j];
        }
        let height_max = Math.max(height_row, height1);
        $row.outerHeight(height_max);
        for (j = 0; j < heights1.length; j++) {
            heights1[j] += (height_max - height1) / heights1.length;
            $('.sub1[data-index=' + j + ']', $row).outerHeight(heights1[j]);
            for (k = 0; level2 && k < heights2[j].length; k++) {
                heights2[j][k] += (heights1[j] - heights2_sum[j]) / heights2[j].length;
                $('.sub1[data-index=' + j + '] .sub2[data-index=' + k + ']', $row).outerHeight(heights2[j][k]);
            }
        }
    }, 100);
}
$.link = function (data, path) {
    if (path) {
        return $('<a href="#' + path + '">' + (data ? data : '') + '</a>').on('click', function (e) {
            e.stopPropagation();
        });
    } else {
        return $('<a href="#">' + (data ? data : '') + '</a>');
    }
}
$.links = function (data, name, callback) {
    let $links = $('<span></span>');
    for (let i = 0; i < data.length; i++) {
        $links.append($('<a href="#">' + data[i][name] + '</a>').on('click', function (e) {
            e.stopPropagation();
            if (callback) {
                callback.call(this, i);
            }
        }));
        if (i < data.length - 1) {
            $links.append('<br>');
        }
    }
    return $links;
}
$.popover = function (val, $pop) {
    let $el;
    if (!$pop) {
        $el = $('<span>-</span>');
    } else {
        let id = 'popover-' + Math.random().toString().substr(2);
        let content = '<div id="' + id + '"></div';
        $el = $('<span>' + val + '<span class="text-red bg-yellow badge f-w-700 ml-1">i</span></span>').popover({
            html: true,
            content: content,
            trigger: 'hover',
        }).on('shown.bs.popover', function () {
            $('#' + id).append($pop);
        });
    }
    return $el;
}

$.date = function (data) {
    if (data) {
        return $('<span>' + data.substr(0, 10) + '</span>').popover({ trigger: 'hover', content: window.moment(data).format("YYYY-MM-DD hh:mm:ss") });
    } else {
        return $('<span>-</span>');
    }
}

$.score = function (score) {
    let str = '';
    for (let i = 0; i < score; i++) {
        str += '★';
    }
    return $('<span></span>').html(str);
}

// localization
registerLocale('ja', ja);
setDefaultLocale('ja');

// communication
axios.defaults.baseURL = K.SERVER_URL/*process.env.BASE_URL*/;
axios.defaults.headers.post['Content-Type'] = 'application/json';//'application/x-www-form-urlencoded';