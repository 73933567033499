import React, { useEffect } from 'react';
import { zeroPad, ar } from '../../config/util';

interface MyProps {
    from: string;
    to: string;
    onChange: (f: string, t: string) => void;
    disabled?: boolean;
    maxLength?: number;
    count: number;
    style?: React.CSSProperties;
}

export const listLabels = (labelStart: string, count: number): string[] => {
    if (count <= 0) return [];

    const fromNumStr = pickLastNumStr(labelStart);
    if (fromNumStr == null) return [];

    const fromNum = Number(fromNumStr);
    if (!Number.isSafeInteger(fromNum)) return [];

    if (fromNum > Number.MAX_SAFE_INTEGER - count + 1) return [];

    const regex = new RegExp("(.*)" + fromNumStr);
    return ar.intRange(fromNum, count)
        .map(n => labelStart.replace(regex, "$1" + zeroPad(n, fromNumStr.length)));
}

export const calcLabelEnd = (labelStart: string, count: number) => {
    if (count <= 0) return "";

    const fromNumStr = pickLastNumStr(labelStart);
    if (fromNumStr == null) return "";

    const fromNum = Number(fromNumStr);
    if (!Number.isSafeInteger(fromNum)) return "";

    if (fromNum > Number.MAX_SAFE_INTEGER - count + 1) return "";

    const toNum = fromNum + count - 1;

    const toNumStr = zeroPad(toNum, fromNumStr.length);
    return labelStart.replace(new RegExp("(.*)" + fromNumStr), "$1" + toNumStr);
}
export const pickLastNum = (label: string) => {
    const numStr = pickLastNumStr(label);
    if (numStr == null) return undefined;

    const num = Number(numStr);
    if (!Number.isSafeInteger(num)) return undefined;

    return num;
}
const pickLastNumStr = (label: string) => {
    const numsInLabel = label.match(/([\d]+)/g);
    if (numsInLabel == null) return undefined;

    const numStr = numsInLabel.pop();
    if (numStr == null) return undefined;
    return numStr;
}

export const SeedLabelRangeInput: React.FC<MyProps> = (props) => {

    useEffect(() => {

        onFromChanged(props.from);

    }, [ props.from, props.count ]);

    const onFromChanged = (value: string) => {

        const to = calcLabelEnd(value, props.count);
        if (value !== props.from || to !== props.to) {
            props.onChange(value, to);
        }
    }


    const styles: { [key:string]: React.CSSProperties } = {
        container: {
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap"
        },
        input: {
            width: "140px"
        },
        to: {
            marginLeft: "4px"
        }
    }


    return (
        <div style={{...styles.container, ...props.style}}>
            <input className="form-control" style={styles.input} type="text" value={props.from}
                onChange={(e) => onFromChanged(e.target.value) }
                maxLength={props.maxLength}
                data-testid="label-from"
                disabled={props.disabled} />
            <span style={styles.to} data-testid="label-to">～ {props.to}</span>
        </div>
    )
}