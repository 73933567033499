import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { ROBOT_KINDS, ROBOT_KIND, PROCESS_KIND, LMT, PROCESS_STATUS, A } from '../../config/constant';
import { usePageStore } from '../../config/page-settings.js';
import { CommonUtil, FreezedArray } from '../../config/util';
import styles from './robot-import.module.css';
import { useRootStore } from '../../stores';
import classnames from 'classnames';
import { AppState } from '../../app';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { useAccessCheck } from 'config/access-checker';
import { useCommunicator } from 'api/communicator';
import { FormInputBlock } from 'components/form/form-input-block';
import { Checkbox } from 'components/form/form-checkbox';
import { EVENT_KIND } from 'config/event-kind';
import { useProcess, resetProcess } from 'stores/fetcher';
import { FetchWaiter, FetchError } from 'components/content/fetch-state';
import { TeamProcessDto, RobotApi, RobotPreviewDto, RobotCowModifyReq, RobotCowModifyReqCow, RobotImportReq, RobotImportReqDuplicatedEnum } from 'api';
import { RobotCowPopup } from './robot-cow-popup';
import { ICowNameInfo, CowToDispInfo } from 'components/parts/cows-popup';
import moment from 'moment';
import { ProcessResultDetail } from 'config/process-result-detail';
import { FadeInPanel } from 'components/parts/fadein-panel';
import { RequiredNumInput } from 'components/parts/num-input';
import { DIALOG_BUTTONS } from 'components/form/form-dialog';
import { loadTeamStorage, saveTeamStorage } from 'stores/local-storage';
import { RobotImportSettingPopup } from './robot-import-setting-popup';
import { RanchContractItemKey } from 'config/contract-checker';

const TARGET_EVENTS = [
    { kind: EVENT_KIND.SELL_MILK_COW, name:"乳量" },
    { kind: EVENT_KIND.FEEDING, name:"えさ" },
    { kind: EVENT_KIND.GROWTH, name:"体重" }
] as const;

const TARGET_PROCESS_KINDS = Object.values(PROCESS_KIND).filter(p => p.isRobotImport);

export interface RobotImportSetting {
    duplicated: RobotImportReqDuplicatedEnum;
    discard_dests: string[];
    feed_no: number | undefined;
}

export default () => {
    const context = usePageStore() as AppState;
    const { cur_ranch_id } = useRootStore();
    const rootStore = useRootStore();
    const { isUnallowedAccess } = useAccessCheck();
    const comm = useCommunicator();

    const [ executing, setExecuting ] = useState(false);
    const [ file, setFile ] = useState<File>();
    const [ eventKindSet, setEventKindSet ] = useState<Set<number>>(new Set());
    const [ csvContent, setCsvContent ] = useState<string>();
    const [ fileError, setFileError ] = useState<string>();
    const [ robotKind, setRobotKind ] = useState<number>(ROBOT_KIND.LELY_T4C.no);
    const [ previewData, setPreviewData ] = useState<RobotPreviewDto>();
    const [ isPreviewed, setIsPreviewed ] = useState(false);
    const [ activeCows, setActiveCows ] = useState<FreezedArray<ICowNameInfo>>();
    const [ milkPrice, setMilkPrice ] = useState<number>(0);
    const [ setting, setSetting ] = useState<RobotImportSetting>();
    const [ isSettingShown, setIsSettingShown ] = useState(false);
    const [ anyExecutingProcess, setAnyExecutingProcess ] = useState(false);

    const process = useProcess(cur_ranch_id);

    const robotCsvHint = useMemo(() => {
        const kind = Object.values(ROBOT_KIND).find(r => r.no === robotKind);
        return kind?.hint;

    }, [ robotKind ]);

    useEffect(() => {
        //未取得のときはfalseとする
        if (process.data == null) {
            setAnyExecutingProcess(false);
            return;
        }

        setAnyExecutingProcess(
            process.data.some(p => TARGET_PROCESS_KINDS.some(t => t.no === p.process_kind) && p.process_status === PROCESS_STATUS.EXECUTING.no)
        )

    }, [ process.data ]);

    useEffect(() => {
        //※現時点では、契約チェックなしとする
        const contracts: RanchContractItemKey[] = [ /* "ROBOT_IMPORT" */];
        if (isUnallowedAccess(undefined, ["BALANCE_COW"], [], contracts)) return;

        context.handleSetHeader({ title:"搾乳データ取込" });
        context.handleSetPageError(false);
        context.handleSetFooter(true);

        init();

    }, []);

    const init = async () => {
        setExecuting(true);
        context.handleSetIsLoading(true);

        try {
            const res = await rootStore.fetchActiveCows(cur_ranch_id, "DIFF");
            if (res === "NG") {
                context.showToast(A.MESSAGE.FAILED_TO_LOAD_DATA);
                return;
            }

            setActiveCows(rootStore.getActiveCows(cur_ranch_id));

            //取込設定
            const savedSetting = loadTeamStorage<Partial<RobotImportSetting>>("robot_import", cur_ranch_id);
            if (savedSetting != null && savedSetting.duplicated != null) {
                setSetting({
                    duplicated: savedSetting.duplicated,
                    discard_dests: savedSetting.discard_dests ?? [],
                    feed_no: savedSetting.feed_no
                });
            }

        } finally {
            context.handleSetIsLoading(false);
            setExecuting(false);
        }

    }

    useEffect(() => {
        setIsPreviewed(false);

    }, [ robotKind ]);

    useEffect(() => {
        if (file == null) {
            setCsvContent(undefined);
            setFileError(undefined);
            return;
        }

        if (file.size < LMT.ROBOT.MIN_CSV_SIZE_B || LMT.ROBOT.MAX_CSV_SIZE_B < file.size) {

            const msg = file.size < LMT.ROBOT.MIN_CSV_SIZE_B
                    ? "このファイルを取り込むことはできません"
                    : `${LMT.ROBOT.MAX_CSV_SIZE_B / (1000 * 1000)}MB以下のファイルを選択してください`;
            setFileError(msg);
            setCsvContent(undefined);
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            reader.onload = null;
            if (reader.result == null || typeof(reader.result) !== "string") {
                if (reader.error != null) {
                    console.error(reader.error);
                }
                setFileError("ファイルの読み込みに失敗しました");
                setCsvContent(undefined);
                return;
            }
            setFileError(undefined);
            setCsvContent(reader.result);
        }

        //※Shift_JISを指定していてもBOM付きのUTF-8ならちゃんと読めているようなので、Shift_JISを指定しておく。
        //  BOMなしUTF-8の場合は文字化けする（取り込まれるが、乳量の廃棄判定が機能しない）
        reader.readAsText(file, "Shift_JIS");

    }, [ file ]);

    const onCowLinkClose = useCallback(() => {
        setPreviewData(undefined);
    }, []);

    const onCowLinkSubmit = useCallback(async (changed: RobotCowModifyReqCow[]) => {
        if(changed.length === 0) {
            setPreviewData(undefined);
            return;
        }

        setExecuting(true);
        context.handleSetIsLoading(true);

        try {
            const req: RobotCowModifyReq = {
                ranch_id:cur_ranch_id,
                robot_kind:robotKind,
                cows:changed
            };
            const res = await comm.send((await RobotApi()).modifyRobotCowUsingPOST(req));
            if (res.result !== "OK") return;

            setPreviewData(undefined);

        } finally {
            context.handleSetIsLoading(false);
            setExecuting(false);
        }

    }, [ cur_ranch_id, robotKind ]);

    const reloadLast = () => {
        resetProcess(cur_ranch_id);
    }

    const onEventKindChange = (eventKind: number, checked: boolean) => {
        const newSet = new Set(eventKindSet);
        if (checked) {
            newSet.add(eventKind);
        } else {
            newSet.delete(eventKind);
        }
        setEventKindSet(newSet);
    }

    const preview = async () => {
        if (!CommonUtil.assertNotNull(csvContent, "csv", "preview")) return;

        setExecuting(true);
        context.handleSetIsLoading(true);

        try {
            const res = await comm.send((await RobotApi()).previewRobotUsingPOST({ ranch_id: cur_ranch_id, robot_kind:robotKind, csv:csvContent }));
            if (res.result !== "OK" || res.data == null) return;

            //previewデータを取得した時点で確認済みとする
            setIsPreviewed(true);
            setPreviewData(res.data);

        } finally {
            context.handleSetIsLoading(false);
            setExecuting(false);
        }
    }

    const onSettingSubmit = (newSetting : RobotImportSetting) => {
        setSetting(newSetting);
        setIsSettingShown(false);

        saveTeamStorage("robot_import", cur_ranch_id, newSetting);
    }


    const onSubmit = async () => {
        if (!CommonUtil.assertNotNull(csvContent, "csv", "submit")) return;

        if (setting == null) {
            await context.showDialog("WARNING", A.MESSAGE.ROBOT_NO_SETTING, DIALOG_BUTTONS.OK);
            return;
        }

        const hasMilk = eventKindSet.has(EVENT_KIND.SELL_MILK_COW.no);
        const hasFeed = eventKindSet.has(EVENT_KIND.FEEDING.no);

        if (hasFeed && setting.feed_no == null) {
            await context.showDialog("WARNING", A.MESSAGE.ROBOT_FEED_NO_REQUIRED, DIALOG_BUTTONS.OK);
            return;
        }

        const req: RobotImportReq = {
            ranch_id: cur_ranch_id,
            robot_kind: robotKind,
            duplicated: setting.duplicated,
            event_kinds: [...eventKindSet],
            csv: csvContent,
            discard_dests: setting.discard_dests,
            feed_no: hasFeed ? setting.feed_no : undefined,
            milk_price: hasMilk ? milkPrice : undefined,
        }

        setExecuting(true);
        context.handleSetIsLoading(true);

        try {
            const res = await comm.send((await RobotApi()).importRobotUsingPOST(req));
            if (res.result !== "OK") return;

            resetProcess(cur_ranch_id, false);

        } finally {
            context.handleSetIsLoading(false);
            setExecuting(false);
        }

    }

    const canSubmit = () => {
        if (executing || csvContent == null || !isPreviewed || eventKindSet.size === 0) {
            return false;
        }

        if (process.data == null || anyExecutingProcess) return false;

        return true;
    }


    if (activeCows == null) return <></>

    return (
        <div className="page-root width-limit">
            <div className="product product-full-height">
                <div className="product-detail" style={{ height: "100%" }}>
                    <div className="product-info product-info-fix">
                        <div className="product-body">
                            <div className={styles["last-header"]}>
                                <div>
                                    <span>前回の取込</span>
                                    <button className="btn btn-warning btn-xs m-l-10" onClick={reloadLast}>更新<i className="fas fa-redo m-l-5" /></button>
                                </div>
                                <div className="link" onClick={() => setIsSettingShown(true)}>取込設定</div>
                            </div>
                            <LastView ranchId={cur_ranch_id} allCows={activeCows} />
                            { anyExecutingProcess ? (
                                <div className={styles["executing-msg"]}>実行中の処理が完了してから次の取込を行うことができます。</div>
                            ) : (
                                <div className={styles["input-area"]}>
                                    <FormInputBlock label="機種">
                                        <select className="form-control" value={robotKind}
                                            onChange={e => setRobotKind(Number(e.target.value))}>
                                            { ROBOT_KINDS.map(kind => (
                                                <option key={ROBOT_KIND[kind].no} value={ROBOT_KIND[kind].no}>{ROBOT_KIND[kind].name}</option>
                                            ))}
                                        </select>
                                    </FormInputBlock>
                                    <FormInputBlock label="CSVファイル" hint={robotCsvHint}>
                                        <FileInput file={file} onFileChanged={setFile} />
                                        { fileError != null && (<div className={styles["file-error"]}>{fileError}</div>)}
                                    </FormInputBlock>
                                    <FormInputBlock>
                                        <button className="btn btn-success" onClick={preview} disabled={csvContent == null}>牛の紐づけを確認</button>
                                    </FormInputBlock>
                                    <FormInputBlock label="取込対象">
                                        <div className={styles["target-events"]}>
                                            { TARGET_EVENTS.map(ev => (
                                                <Checkbox key={ev.kind.no}
                                                    label={ev.name}
                                                    checked={eventKindSet.has(ev.kind.no)}
                                                    onChange={e => onEventKindChange(ev.kind.no, e.target.checked)}
                                                    id={"chkEvt" + ev.kind.no}
                                                />
                                            ))}
                                        </div>
                                    </FormInputBlock>
                                    <FadeInPanel isVisible={eventKindSet.has(EVENT_KIND.SELL_MILK_COW.no)}>
                                        <FormInputBlock label="乳価" contentClassName={styles["milk-price"]}>
                                            <RequiredNumInput
                                                min={LMT.SELLMILK.UNIT_PRICE_MIN} max={LMT.SELLMILK.UNIT_PRICE_MAX}
                                                step={LMT.SELLMILK.UNIT_PRICE_STEP}
                                                value={milkPrice}
                                                onChange={setMilkPrice}
                                            />
                                            <span>円/kg</span>
                                        </FormInputBlock>
                                    </FadeInPanel>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="button-page-footer">
                <ExecutionButton type="save" onClick={onSubmit} disabled={!canSubmit()}>取込実行</ExecutionButton>
            </div>
            { previewData != null && (
                <RobotCowPopup
                    onClose={onCowLinkClose}
                    previewData={previewData}
                    isSubmitExecuting={executing}
                    allCows={activeCows}
                    onSubmit={onCowLinkSubmit}
                />
            )}
            { isSettingShown && (
                <RobotImportSettingPopup
                    ranchId={cur_ranch_id}
                    onClose={() => setIsSettingShown(false)}
                    onSubmit={onSettingSubmit}
                    original={setting}
                />
            )}
        </div>
    )

}

const FileInput = (props: {
    file: File | undefined,
    onFileChanged: (file:File | undefined) => void
}) => {

    const onSelect = useCallback((fileList: FileList | null) => {
        if (fileList == null || fileList.length === 0) {
            return;
        }
        props.onFileChanged(fileList[0]);
    }, [ props.onFileChanged ]);

    return (
        <div className={styles["file-input"]}>
            <label>
                <input type="file" accept=".csv" className="d-none" onChange={e => onSelect(e.target.files)} />
                <div className={classnames("btn btn-gray", styles["button"])} tabIndex={0}>選択</div>
            </label>
            <span className={styles["file-name"]}>{props.file?.name}</span>
        </div>
    )
}

/**
 * 前回処理結果表示欄
 */
const LastView = React.memo((props: {
    ranchId: number,
    allCows: FreezedArray<ICowNameInfo>
}) => {
    const { data, isError, isLoading } = useProcess(props.ranchId);

    return (
        <div className={styles["last-viewer"]}>
            { isLoading ? (
                <FetchWaiter size="small" />
            ) : (isError || data == null) ? (
                <FetchError />

            ) : TARGET_PROCESS_KINDS.map(pr => <ProcessItem key={pr.no} processKind={pr} dto={data.find(d => d.process_kind === pr.no)} allCows={props.allCows} />)
            }
        </div>
    )
});


/**
 * 前回処理結果の1種別分
 */
const ProcessItem = React.memo(({ processKind, dto, allCows }: {
    processKind: {no:number,name:string},
    dto: TeamProcessDto | undefined,
    allCows: FreezedArray<ICowNameInfo>
}) => {

    const buildSuccessDetail = useCallback((detail: string | undefined) => {
        if (detail == null) return "";
        const val = JSON.parse(detail);
        if (!ProcessResultDetail.isCountWithDiscard(val)) return "";

        let rtn = `${val.count_total}件の記録`;
        if (val.count_discard != null) {
            rtn += `（内、廃棄${val.count_discard}件）`;
        }
        return rtn;
    }, []);

    const buildFailedDetail = useCallback((msg:string, detail: string | undefined) => {
        if (detail == null) return msg;
        const val = JSON.parse(detail);
        if (!ProcessResultDetail.isCowIdAndTime(val)) return msg;

        const cow = allCows.find(c => c.cow_id === val.cow_id);
        if (cow == null) return msg;

        return `${msg}（${CowToDispInfo(cow, false)} ${moment(val.time).format("M/D HH:mm")}）`;

    }, [ allCows ]);


    return (
        <div className={styles.item}>
            <div className={styles["process-kind"]}>{processKind.name}</div>
            <div className={styles["info"]}>
                { dto == null ? (
                    <div>未実施</div>
                ) : (<>
                    <div className="row-no-margin align-items-center m-r-10">
                        <span className={styles["lasttime"]}>{moment(dto.start_at).format("YYYY/M/D HH:mm")}</span>
                        { dto.process_status === PROCESS_STATUS.SUCCESS.no && (
                            <i className={classnames("fas fa-check-circle", styles["status-icon"], styles.success)} />
                        )}
                        { dto.process_status === PROCESS_STATUS.FAILED.no && (
                            <i className={classnames("fas fa-times-circle", styles["status-icon"], styles.failed)} />
                        )}
                        <span>{Object.values(PROCESS_STATUS).find(p => p.no === dto.process_status)?.name ?? ""}</span>
                    </div>
                    { dto.process_status === PROCESS_STATUS.SUCCESS.no && (
                        <div className={styles["detail-success"]}>{buildSuccessDetail(dto.detail)}</div>
                    )}
                    { dto.process_status === PROCESS_STATUS.FAILED.no && (
                        <div className={styles["detail-failed"]}>{buildFailedDetail(dto.error_message, dto.detail)}</div>
                    )}
                </>)}
            </div>
        </div>
    )
});