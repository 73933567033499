import axios, { AxiosResponse } from 'axios';
import React from 'react';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import Content from './components/content/content';
import Footer from './components/footer/footer';
import { FormModal } from './components/form/form-modal';
import Header from './components/header/header';
import PageError from './components/page-header/page-error.jsx';
import { A, K, TeamType } from './config/constant';
import { PageSettings } from './config/page-settings.js';
import './config/setting.js';
import LoadingOverlay from 'react-loading-overlay';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { FormModalType } from './components/form/form-modal';
import { CommonUtil, FreezedArray } from './config/util';
import { FormDialogProps, FormDialogType, DialogButtonType, FormDialog } from './components/form/form-dialog';
import { QueryClientProvider } from 'react-query';
import { queryClient } from 'stores/query-client';

export interface AppState {
	pageIconType: TeamType;
	pageTitle: string;
	backBtn: boolean;
	header: boolean;
	pageContent: boolean;
	footer: boolean;
	pageError: boolean;
	isLoadingProgress: boolean;
	hasScroll: boolean;

	//※現状は以下をcontextとして使用させるためにこのstateをcontextとしている

	/**
	 * iconType: 指定ない場合、牧場
	 * backBtn: 指定ない場合、表示
	 */
	handleSetHeader: (info:{
		iconType?: TeamType,
		title: string,
		backBtn?: boolean,
	} | undefined) => void;
	handleSetPageContent:  (value:boolean) => void;
	handleSetFooter:  (value:boolean) => void;
	handleSetPageError:  (value:boolean) => void;
	handleSetIsLoading:  (value:boolean) => void;
	showToast:  (text: string) => void;
	postAsync: (url: string, data: any) => Promise<AxiosResponse<any>>;
	showQuickSearch:(text:string)=>void;
	cowSearchInput?: string;
	currentDialog?: Readonly<Omit<FormDialogProps, "isOpen">>;
	showDialog: (type:FormDialogType, text: string, buttons?: FreezedArray<DialogButtonType>, options?: { subTexts?:string[], required?:boolean }) => Promise<number|undefined>;


	//TODO 以下は順次削除
	currentModal?: {
		text: string;
		buttons: string[] | undefined;
		title?: string;
		subText: string;
		type: FormModalType;
		callback: (result?:number)=>void;
	}
	/** @deprecated */
	showModal:  (text: string, type: FormModalType, buttons: string[]|undefined, callback?: (result?:number)=>void) => void;
	/** @deprecated */
	showModalAsync:  (text: string, type: FormModalType, buttons: string[]|undefined) => Promise<number|undefined>;
	/** @deprecated */
	showCommonModal:  (title: string, text: string, subtext: string, type: FormModalType, buttons:string[]|undefined, callback?:(result?:number)=>void) => void;
	/** @deprecated */
	showQuestion:  (text: string, callback: (result?:number)=>void, buttons: string[]) => void;
	/** @deprecated */
	showRequired:  (text: string, callback: (result?:number)=>void, buttons: string[]) => void;
	/** @deprecated */
	showQuestionAsync: (text: string, buttons: string[]) => Promise<number|undefined>;
	/** @deprecated */
	showAlert:  (text: string, callback?: (result?:number)=>void) => void;
	/** @deprecated */
	showConfirm:  (text: string, callback?:(result?:number) => void) => void;
}

class App extends React.Component<{},AppState> {

	private notificationDOMRef : React.RefObject<any>;

	showQuickSearch = (text: string) => {
		this.setState({cowSearchInput:text})
	}

	handleSetHeader = (info:{
		iconType?: TeamType,
		title: string,
		backBtn?: boolean,
	} | undefined) => {
		this.setState({
			header: info != null,
			pageTitle: info?.title ?? "",
			backBtn: info?.backBtn ?? true,
			pageIconType: info?.iconType ?? "ranch"
		});
	}
	handleSetPageContent = (value: boolean) => {
		this.setState({
			pageContent: value
		});
	}
	handleSetFooter = (value: boolean) => {
		this.setState({
			footer: value
		});
	}
	handleSetPageError = (value: boolean) => {
		this.setState({
			pageError: value
		});
	}
	handleSetIsLoading = (value: boolean) => {
		this.setState({
			isLoadingProgress: value
		});
	}

	showCommonModal = (title: string, text: string, subtext: string, type: FormModalType, buttons:string[]|undefined, callback?:(result?:number)=>void) => {
		this.setState({
			currentModal: {
				title: title,
				subText: subtext,
				type: type,
				text: text,
				callback: callback ?? (() => { }),
				buttons: buttons,
			}
		});
	}


	showModal = (text: string, type: FormModalType, buttons: string[]|undefined, callback?: (result?:number)=>void) => {
		this.showCommonModal('', text, '', type, buttons, callback);
	}
	showModalAsync = async (text: string, type: FormModalType, buttons: string[]|undefined): Promise<number|undefined> => {
		return new Promise(resolve => this.showModal(text, type, buttons, resolve));
	}

	showRequired = (text: string, callback: (result?:number)=>void, buttons: string[]) => {
		this.showModal(text, "REQUIRED", buttons, callback);
	}

	showQuestion = (text: string, callback: (result?:number)=>void, buttons: string[]) => {
		this.showModal(text, "QUESTION", buttons, callback);
	}
	showQuestionAsync = async (text: string, buttons:string[]): Promise<number|undefined> => {
		return new Promise(resolve => this.showQuestion(text, resolve, buttons));
	}

	showAlert = (text: string, callback?: (result?:number)=>void) => {
		this.showModal(text, "ALERT", undefined, callback);
	}

	showToast = (text: string) => {
		this.notificationDOMRef.current.addNotification({
			title: '',
			message: text,
			type: 'warning',
			insert: "top",
			container: 'top-center',
			animationIn: ["animated", "fadeIn"],
			animationOut: ["animated", "fadeOut"],
			dismiss: { duration: 2000 },
			dismissable: { click: true },
			content: false
		});
	}

	showConfirm = (text: string, callback?:(result?:number)=>void) => {
		this.showModal(text, "CONFIRM", undefined, callback);
	}

	onModalClose = (result?:number) => {
		if (!CommonUtil.assertNotNull(this.state.currentModal,"currentModal", "onModalClose")) return;
		const cb = this.state.currentModal.callback;
		this.setState({
			currentModal: undefined
		});
		cb(result);
	}

	showDialog = (type:FormDialogType,
		text: string,
		buttons?: FreezedArray<DialogButtonType>,
		options?: { subTexts?:Readonly<string[]>, required?:boolean }): Promise<number|undefined> => {

		return new Promise(resolve => this.setState({
			currentDialog: {
				text,
				type,
				buttons,
				required: options?.required,
				subTexts: options?.subTexts,
				onClose: resolve
			}
		}));
	}
	onDialogClose = (result?: number) => {
		if (!CommonUtil.assertNotNull(this.state.currentDialog, "currentDialog")) return;
		const cb = this.state.currentDialog.onClose;

		this.setState({
			currentDialog: undefined
		});

		cb(result);
	}

	handleApiError = (e: any) => {
		// this.handleSetPageProgress(false);

		let msg = this.handleApiFalse(e);
		if (msg === '') {
			return;
		}

		if (msg.sqlState !== undefined && msg.sqlMessage !== undefined) {
			let text = msg.sqlMessage + '(sqlState: ' + msg.sqlState + ')'
			this.showAlert(text);
		} else {
			if (typeof msg == 'string') {
				this.showAlert(msg);
			}
		}
	}

	handleApiFalse = (e: any) => {
		let message = '';

		if (!e.config || !e.config.url) {
			return message;
		}

		return this.handleApiFalseMsg(e);
	}

	handleApiFalseMsg = (e: any) => {
		let message: any = '';

		do {
			if (e.response === undefined || !e.response) {
				break;
			}
			if (e.response.data === undefined || !e.response.data) {
				break;
			}
			if (e.response.data.errors === undefined || !e.response.data.errors) {
				break;
			}
			if (e.response.data.errors.length === 0) {
				break;
			}
			if (e.response.data.errors[0].msg === undefined || !e.response.data.errors[0].msg) {
				break;
			}

			message = e.response.data.errors[0].msg;

			if (e.response.data.errors[0].param === undefined || !e.response.data.errors[0].param) {
				break;
			}

			message += '(' + e.response.data.errors[0].param + ')';
			message += '\n' + e.config.url;

		} while (false);

		return message;
	}

	getUserToken = async () => {
		//※Auth以下のコンポーネントが描画される時点で
		//　既に firebase.auth().currentUser が取得できるようになっている前提
		const user = firebase.auth().currentUser;
		if (user != null) {
			return user.getIdToken();
		}
		return Promise.resolve(null);
	}

	postAsync = async (url: string, data: any):  Promise<AxiosResponse<any>> => {
		const token = await this.getUserToken();
		if (token == null) {
			throw Error("user token not found");
		}

		axios.defaults.headers.post[K.HEADER.CONTENT_TYPE.NAME] = K.HEADER.CONTENT_TYPE.JSON;
		axios.defaults.headers.post[K.HEADER.ACCEPT.NAME] = K.HEADER.ACCEPT.JSON;
		axios.defaults.headers.post[K.HEADER.AUTHORIZATION.NAME] = `${K.HEADER.AUTHORIZATION.BEARER} ${token}`;
		return axios.post(K.SERVER_URL + '/api' + url, JSON.stringify(data));
	}

	constructor(props) {
		super(props);

		this.notificationDOMRef = React.createRef();

		this.state = {
			pageIconType: "ranch",
			pageTitle: '',
			backBtn: true,
			header: false,
			handleSetHeader: this.handleSetHeader,

			pageContent: true,
			handleSetPageContent: this.handleSetPageContent,

			footer: false,
			handleSetFooter: this.handleSetFooter,

			pageError: true,
			handleSetPageError: this.handleSetPageError,

			isLoadingProgress: false,
			handleSetIsLoading: this.handleSetIsLoading,

			showModal: this.showModal,
			showModalAsync: this.showModalAsync,
			showCommonModal: this.showCommonModal,
			showQuestion: this.showQuestion,
			showQuestionAsync: this.showQuestionAsync,
			showRequired: this.showRequired,
			showAlert: this.showAlert,
			showToast: this.showToast,
			showConfirm: this.showConfirm,
			postAsync: this.postAsync,

			showDialog: this.showDialog,

			hasScroll: false,

			showQuickSearch: this.showQuickSearch
		};

		this.onBack = this.onBack.bind(this);
		this.handleSetHeader = this.handleSetHeader.bind(this);
		this.handleSetIsLoading = this.handleSetIsLoading.bind(this);
	}

	onBack() {
		window.history.go(-1);
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll)
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll)
	}

	handleScroll = () => {
		if (window.scrollY > 0) {
			this.setState(state => ({
				hasScroll: true
			}));
		} else {
			this.setState(state => ({
				hasScroll: false
			}));
		}
	}

	render() {
		return (
			<PageSettings.Provider value={this.state}>
				<QueryClientProvider client={queryClient}>
					<div id='page-container' className='fade show'>

						<ReactNotification ref={this.notificationDOMRef} />
						<FormModal
							isOpen={this.state.currentModal != null}
							type={this.state.currentModal?.type ?? "ALERT"}
							buttons={this.state.currentModal?.buttons}
							text={this.state.currentModal?.text ?? ""}
							onClose={this.onModalClose}
							title={this.state.currentModal?.title}
							subtext={this.state.currentModal?.subText}
						/>
						{ this.state.currentDialog != null && (
							<FormDialog
								isOpen={true}
								type={this.state.currentDialog.type}
								text={this.state.currentDialog.text}
								subTexts={this.state.currentDialog.subTexts}
								buttons={this.state.currentDialog.buttons}
								required={this.state.currentDialog.required}
								onClose={this.onDialogClose}
							/>
						)}

						{this.state.header && (
							<Header pageIconType={this.state.pageIconType}
								pageTitle={this.state.pageTitle}
								onBack={() => this.onBack()}
								cowSearchInput={this.state.cowSearchInput}
								onCowSearchInputChange={e => this.setState({cowSearchInput:e})}
								showBackBtn={this.state.backBtn} />
								)}
						{this.state.pageError && (<PageError />)}
						{this.state.pageContent && (<Content hasHeader={this.state.header} hasFooter={this.state.footer} />)}
						{this.state.footer && (<Footer />)}

						{this.state.isLoadingProgress && (
							<div style={{ position: "absolute", top: "0", left: "0", width: "100vw", height: "100vh", background: "rgba(33, 33, 33, 0.5)", zIndex:10 }}>
								<div style={{ position: "relative", top: "40%" }}>
									<LoadingOverlay
										active={this.state.isLoadingProgress}
										spinner>
									</LoadingOverlay>
								</div>
							</div>
						)}
					</div>
					{/* {this.state.floatPanel && (<FloatPanel />)} */}
				</QueryClientProvider>
			</PageSettings.Provider>
		)
	}
}

export default App;