import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PageSettings } from '../../config/page-settings.js';
import { CowApi, CowSearchReq } from '../../api';
import { Communicator } from '../../api/communicator';
import { A } from '../../config/constant';
import { CowList } from '../../components/parts/cow-list';
import { ExecutionButton } from '../../components/buttons/execution-button';

export interface ISelectMotherPopupProps {
    setIsLoading:(isLoading:boolean)=>void;
    editing_cow_id?: number;
    ranch_id: number;
    onClose:()=>void;
    onSubmit:(mother?:IMother)=>void;
    iniCheckedId?:number;
    comm: Communicator;
}

export interface IMother {
    cow_id: number;
    trace_id: string;
    local_no?: string; 
    name?: string;
    ancestor_1_exid: number | undefined;
    ancestor_2_exid: number | undefined;
    ancestor_3_exid: number | undefined;
    ancestor_1: string | undefined;
    ancestor_2: string | undefined;
    ancestor_3: string | undefined;
}

interface MyState {
    motherList: Array<IMother>;
    checkedIds: Set<number>;
}

export class SelectMotherPopup extends React.Component<ISelectMotherPopupProps, MyState> {

    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            motherList: [],
            checkedIds: new Set()
        }

        this.onClose = this.onClose.bind(this);
        this.onCloseWithMother = this.onCloseWithMother.bind(this);
        this.setCheckedIds = this.setCheckedIds.bind(this);
    }

    componentDidMount() {
        if (this.props.iniCheckedId) {
            this.setState({
                checkedIds: new Set([this.props.iniCheckedId])
            })
        }

        this.loadMothers();
    }

    onClose() {
        this.props.onClose();
    }

    onCloseWithMother() {
        const motherId = this.state.checkedIds.size === 0 ? undefined : [...this.state.checkedIds.keys()][0];
        this.props.onSubmit(motherId == null ? undefined : this.state.motherList.find(m => m.cow_id === motherId));
    }

    private async loadMothers() {

        const req: CowSearchReq = {
            condition: {
                ranch_id: this.props.ranch_id,
                is_active: 1,
                is_male: 0,
            },
            output: {
                ancestors: true,
            },
            count: 5000,
            page: 1,
        };
        this.props.setIsLoading(true);
        const res = await this.props.comm.send((await CowApi()).searchCowUsingPOST(req));
        this.props.setIsLoading(false);
        if (res.result !== "OK") return;

        const list = (res.data?.list ?? [])
                    .filter(c => c.use_no !== A.CHILD_USE_NO && c.cow_id !== this.props.editing_cow_id);
        this.setState({
            motherList: list.map(m => ({
                ...m,
                ancestor_1_exid: m.ancestor_1_exid,
                ancestor_2_exid: m.ancestor_2_exid,
                ancestor_3_exid: m.ancestor_3_exid,
                ancestor_1: m.ancestor_1,
                ancestor_2: m.ancestor_2,
                ancestor_3: m.ancestor_3,
            }))
        });
    }

    setCheckedIds: React.Dispatch<React.SetStateAction<Set<number>>> = (s) => {
        if (typeof s === "object") {
            this.setState({ checkedIds: s })
        } else {
            this.setState({ checkedIds: s(this.state.checkedIds) })
        }
    }

    render() {
        return (
            <div>
                <Modal isOpen={true} style={{ maxWidth: "350px", margin: "auto" }}>
                    <ModalHeader toggle={this.props.onClose}>母牛を選択</ModalHeader>
                    <ModalBody style={{ overflowY: "auto", maxHeight: "calc(100vh - 180px)" }}>
                        <CowList
                            cows={this.state.motherList}
                            singleSelection={true}
                            checkedCowIds={this.state.checkedIds}
                            setCheckedCowIds={this.setCheckedIds}
                        />
                    </ModalBody>
                    <ModalFooter className="modal-footer-fix">
                        <ExecutionButton type="decide" onClick={this.onCloseWithMother} />
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}