import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { AppState } from '../../app';
import { A } from '../../config/constant';
import { PageSettings } from '../../config/page-settings.js';
import { withContext } from '../../stores';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import styles from './user.module.css'

interface MyProps extends BaseProps {
}

interface MyLocationState {
    sendMail: boolean;
}

interface MyState {
    activeBg: string,
}

class SendMail extends Base<BaseProps<{},{},MyLocationState>, MyState> {

    static contextType = PageSettings;
    context!:AppState;

    constructor(props) {
        super(props);

        this.state = {
            activeBg: '/assets/img/login-bg/login-bg.jpg',
        }

        this.sendMail = this.sendMail.bind(this);
    }

    componentDidMount() {
        this.context.handleSetHeader(undefined);
        this.context.handleSetFooter(false);
        this.context.handleSetPageError(false);

        if(this.props.location.state?.sendMail) {
            this.sendMail(false);
        }
    }

    componentWillUnmount() {
        this.context.handleSetHeader({ title:"" });
        this.context.handleSetFooter(true);
    }

    async sendMail(isResend:boolean) {
        try {
            this.context.handleSetIsLoading(true);

            // 確認メールの送信
            const user = firebase.auth().currentUser;

            if (user != null) {
                await user.sendEmailVerification({
                　　url: `${window.location.protocol}//${window.location.host}/top`,
                　　handleCodeInApp: false,
                })
                .then( () => {
                    if (isResend) this.context.showToast(A.MESSAGE.SEND_MAIL);
                })
                .catch((error) => {
                    console.error(`ERROR:${error.code} - ${error.message}`);
                    this.context.showAlert(A.MESSAGE.FAILED_MAIL_SEND);
                });

                // 自動ログイン抑止のため、認証状態をクリア
                await firebase.auth().setPersistence('none');
            }

        } catch (e) {
            console.log(e);
        } finally {
            this.context.handleSetIsLoading(false);
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="login-cover" style={{background:"#0066aa"}}>
                    {/*
                    //use background image
                    <div className="login-cover-image"
                        style={{ backgroundImage: 'url(' + this.state.activeBg + ')' }}></div>
                    */}
                    {/*
                    //use mask layer
                    <div className="login-cover-bg"></div>
                    */}
                </div>

                <div className="login login-v2">
                    <div className="login-header">
                        <div className="brand">
                            <span className="logo"></span> <b>vetell</b>
                        </div>
                    </div>
                    <div className="login-content">
                        <div className="form-group m-b-5">
                            <span className={styles.message}>確認用のメールを送信しました。</span>
                        </div>
                        <div className="form-group m-b-5">
                            <span className={styles.message}>メールのリンクから、登録を完了してください。</span>
                        </div>
                        <div className="form-group m-b-10">
                            <span className={styles.message}>（メールが届くまでに数分かかる場合があります）</span>
                        </div>
                        <div className="login-buttons">
                            <button type="button" className="btn btn-warning btn-block btn-lg" onClick={() => this.sendMail(true)}>再送</button>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

export default withRouter(withContext(SendMail))
