import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';

import baseStyles from '../treatbulk.module.css'
import { Treat } from '..';
import { FormRadio } from '../../../components/form/form-radio';
import { MedicineInputItem, TreatInputItem, MedicineInput, TreatInput, isValidMedicine, isValidTreat } from '../TreatInput';
import { CommonUtil } from '../../../config/util';
import { ITreatItem, ITreatKind, IMedicineRoute, IMedicineCategory, IMedicine } from '../../../stores';
import { getDefaultBenefitType, convertBenefitType, TREAT_BENEFIT_TYPE } from '../../../config/constant';

type PropType = {
    ranchId: number;
    clinicId: number | undefined;
    onClose: () => void;
    onSubmit: (treat: Treat) => void;
    treat: Treat | undefined;

    treats: ITreatItem[];
    treatKinds: ITreatKind[];
    routes: IMedicineRoute[];
    medicineCategories: IMedicineCategory[];
    ranchMedicines: IMedicine[];
    clinicMedicines: IMedicine[];
    canEditMaster: boolean;
}

const MEDICINE = 1;
const TREAT = 2;
type TType = typeof MEDICINE | typeof TREAT;

export const TreatEditModal = (props: PropType) => {
    const [ treat, setTreat ] = useState<MedicineInputItem | TreatInputItem>({ unit_price: 0, benefit_type:"NO_BENEFIT" });
    const [ currentType, setCurrentType ] = useState<TType>(MEDICINE);
    const [ isValidInput, setIsValidInput ] = useState(false);

    useEffect(() => {
        const defaultBenefit = getDefaultBenefitType(props.clinicId != null);
        if (props.treat == null) {
            setCurrentType(MEDICINE);
            setTreat({ unit_price: 0, benefit_type:defaultBenefit });
            return;
        }

        if (props.treat.treat_kind_no == null) {
            setCurrentType(MEDICINE);
        } else {
            setCurrentType(TREAT);
        }
        setTreat({
            ...props.treat,
            benefit_type:props.treat.benefit_type == null ? defaultBenefit : convertBenefitType(props.treat.benefit_type)
        })

    }, [ props.treat ]);

    useEffect(() => {
        const isValid = currentType === MEDICINE ? isValidMedicine(treat as MedicineInputItem, false) : isValidTreat(treat, false);
        setIsValidInput(isValid);
        
    }, [ treat ])

    const onTypeChange = (tp: number) => {
        setCurrentType(tp === MEDICINE ? MEDICINE : TREAT);
        setTreat({ benefit_type:treat.benefit_type, unit_price: 0 });
    }

    const onSubmit = () => {
        const name = treat.name;
        if (!CommonUtil.assertNotNull(name, "name")) return;

        let result: Treat;
        if (currentType === MEDICINE) {
            if (!("medicine_id" in treat)) {
                console.error("invalid medicine input", treat);
                return;
            }
            const team_id = treat.team_id;
            if (!CommonUtil.assertNotNull(team_id, "team_id")) {
                return;
            }
            result = {
                ...treat,
                name,
                team_id,
                benefit_type: TREAT_BENEFIT_TYPE[treat.benefit_type].no
            };

        } else {
            if (!("treat_kind_no" in treat)) {
                console.error("invalid treat input", treat);
                return;
            }
            if (!CommonUtil.assertNotNull(treat.treat_kind_no, "treat_kind_no")) {
                return;
            }
            if (treat.unit != null) {
                if (!CommonUtil.assertNotNull(treat.amount, "treat amount")) return;
            }
            result = {
                ...treat,
                name,
                team_id: props.clinicId ?? props.ranchId,
                note: "",
                benefit_type: TREAT_BENEFIT_TYPE[treat.benefit_type].no
            };
        }

        props.onSubmit(result);
    }

    return (
        <div>
        <Modal isOpen={true} style={{ maxWidth: "320px", margin: "auto" }}>
            <ModalBody>
                <FormRadio
                    prefix="treatkind"
                    options={[ { name: "投薬", value:MEDICINE }, { name: "処置", value: TREAT } ]}
                    value={currentType}
                    onChange={v => onTypeChange(v)}
                    className="m-b-10"
                />
                <hr className="m-t-0" />
                { currentType === MEDICINE ? (
                    <MedicineInput
                        input={{...treat, amount: treat.amount ?? 0 }}
                        medicineCategories={props.medicineCategories}
                        ranchMedicines={props.ranchMedicines}
                        clinicMedicines={props.clinicMedicines}
                        routes={props.routes}
                        onChange={m => setTreat(m)}
                        idPrefix="modal"
                        canEditMaster={props.canEditMaster}
                        ranchId={props.ranchId}
                        clinicId={props.clinicId}
                        hasBenefit={props.clinicId != null}
                    />
                ) : (
                    <TreatInput
                        idPrefix="modal"
                        input={treat}
                        treatKinds={props.treatKinds}
                        treats={props.treats}
                        onChange={t => setTreat(t)}
                        canEditMaster={props.canEditMaster}
                        hasBenefit={props.clinicId != null}
                    />
                )}
            </ModalBody>
            <ModalFooter>
                <button className={ baseStyles.bluebutton } disabled={!isValidInput} onClick={() => onSubmit()}>確定</button>
                <button className={ baseStyles.graybutton } onClick={props.onClose}>キャンセル</button>
            </ModalFooter>
            </Modal>
        </div>
    )
}
