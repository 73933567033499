import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { usePageStore } from '../../config/page-settings.js';
import { IEditingSeed } from './seed-management';
import { SelectMotherPopup, IMother } from '../cow/select-mother-popup';
import { A, LMT } from '../../config/constant';
import { CommonUtil } from '../../config/util';
import { Communicator } from '../../api/communicator';
import { AncestorSelect } from './ancestor-select';
import { SettingAncestorPopup } from './setting-ancestor-popup';
import { AppState } from '../../app';
import { useAncestors } from '../../stores/fetcher';
import { FetchWaiter, FetchError } from '../../components/content/fetch-state';
import { ExecutionButton } from '../../components/buttons/execution-button';

interface MyProps {
    data: IEditingSeed;
    team_id: number;
    ranch_id: number | undefined;
    onSubmit: ()=>void;
    onClose: ()=>void;
    onDelete: ()=>void;
    onNameEdited:(name:string)=>void;
    onSNameEdited:(sname:string)=>void;
    onGetFromEdited:(get_from:string)=>void;
    onFatherSelected:(id:number | undefined, name: string|undefined)=>void;
    onAncestor2Selected:(id:number | undefined, name: string|undefined)=>void;
    onAncestor3Selected:(id:number | undefined, name: string|undefined)=>void;
    onAncestor4Selected:(id:number | undefined, name: string|undefined)=>void;
    onMotherSelected:(mother:IMother|null|undefined)=>void;
    comm: Communicator;
    isSubmitExecuting: boolean;
}

export const SeedManagementEditPopup = (props: MyProps) => {

    const { showQuestionAsync, handleSetIsLoading } = usePageStore() as AppState;

    const [ isMotherSelectShown, setIsMotherSelectShown ] = useState(false);
    const [ isAncestorPopupShown, setIsAncestorPopupShown ] = useState(false);

    const ancestors = useAncestors(props.team_id);

    const onMotherSelectClick = () => setIsMotherSelectShown(true);

    const onMotherSelected = (mother?:IMother) => {
        setIsMotherSelectShown(false);
        props.onMotherSelected(mother);
    }
    const onClearMother = () => props.onMotherSelected(null);

    return (
        <div>
            <Modal isOpen={true} centered={true} scrollable={true}>
                <ModalHeader toggle={props.onClose}>
                    { A.GET_SEED_TYPE(props.data.seed_type)?.edit_title}
                </ModalHeader>
                { ancestors.isLoading ? (
                    <FetchWaiter />
                ) : (ancestors.isError || ancestors.data == null) ? (
                    <FetchError />
                ) : (<>
                    <ModalBody>
                        {/* 種雄牛 */}
                        {(props.data.seed_type === A.SEED_TYPE.SEED.seed_type) && (
                        <React.Fragment>
                            <div className="form-group" style={{display:"flex"}}>
                                <label className="col-form-label" style={{width:"80px"}}>種雄牛名</label>
                                <AncestorSelect
                                    list={ancestors.data}
                                    value={props.data.father_exid}
                                    selectedName={props.data.name}
                                    onChange={(id,n) => props.onFatherSelected(id,n)}
                                    onEditClick={() => setIsAncestorPopupShown(true)}
                                />
                            </div>
                            <div className="form-group" style={{display:"flex"}}>
                                <label className="col-form-label" style={{width:"80px"}}>略号</label>
                                <input className="form-control" type="text" value={props.data.sname ?? ""}
                                    maxLength={LMT.SEED.SNAME_LEN}
                                    onChange={(e)=>props.onSNameEdited(e.target.value)}/>
                            </div>
                        </React.Fragment>
                        )}
                        {/* 血統 */}
                        {(props.data.seed_type === A.SEED_TYPE.EGG.seed_type) && (
                        <React.Fragment>
                            <div className="form-group" style={{display:"flex"}}>
                                <label className="col-form-label" style={{width:"60px", flexShrink:0}}>一代祖</label>
                                <AncestorSelect
                                    list={ancestors.data}
                                    value={props.data.father_exid}
                                    selectedName={props.data.ancestor_1}
                                    onChange={(id,n) => props.onFatherSelected(id,n)}
                                    onEditClick={() => setIsAncestorPopupShown(true)}
                                />
                            </div>
                            <div className="form-group" style={{display:"flex"}}>
                                <label className="col-form-label" style={{width:"60px", flexShrink:0}}>母牛</label>
                                <div style={{width:"100%"}}>
                                    { props.ranch_id != null && (
                                        <div style={{display:"flex"}}>
                                            <button type="button" className="btn btn-green" onClick={onMotherSelectClick}>牧場から選択</button>
                                            { props.data.cow_id != null && (
                                                <a className="link m-l-10" style={{alignSelf:"flex-end"}} onClick={onClearMother}>選択クリア</a>
                                            )}
                                        </div>
                                    )}
                                    <input className="form-control m-t-10" type="text" value={props.data.name}
                                        onChange={(e)=>props.onNameEdited(e.target.value)}
                                        maxLength={LMT.COW.NAME_LEN}
                                        disabled={(props.data.cow_id != null)}/>
                                </div>
                            </div>
                            <div className="form-group" style={{display:"flex"}}>
                                <label className="col-form-label" style={{width:"60px", flexShrink:0}}>二代祖</label>
                                <AncestorSelect
                                    list={ancestors.data}
                                    value={props.data.ancestor_2_exid}
                                    selectedName={props.data.ancestor_2}
                                    onChange={(id,n) => props.onAncestor2Selected(id,n)}
                                    onEditClick={() => setIsAncestorPopupShown(true)}
                                />
                            </div>
                            <div className="form-group" style={{display:"flex"}}>
                                <label className="col-form-label" style={{width:"60px", flexShrink:0}}>三代祖</label>
                                <AncestorSelect
                                    list={ancestors.data}
                                    value={props.data.ancestor_3_exid}
                                    selectedName={props.data.ancestor_3}
                                    onChange={(id,n) => props.onAncestor3Selected(id,n)}
                                    onEditClick={() => setIsAncestorPopupShown(true)}
                                />
                            </div>
                            <div className="form-group" style={{display:"flex"}}>
                                <label className="col-form-label" style={{width:"60px", flexShrink:0}}>四代祖</label>
                                <AncestorSelect
                                    list={ancestors.data}
                                    value={props.data.ancestor_4_exid}
                                    selectedName={props.data.ancestor_4}
                                    onChange={(id,n) => props.onAncestor4Selected(id,n)}
                                    onEditClick={() => setIsAncestorPopupShown(true)}
                                />
                            </div>
                            <div className="form-group" style={{display:"flex"}}>
                                <label className="col-form-label" style={{width:"60px", flexShrink:0}}>入手先</label>
                                <input className="form-control" type="text" value={props.data.get_from ?? ""}
                                    maxLength={LMT.SEED.GET_FROM_LEN}
                                    onChange={(e)=>props.onGetFromEdited(e.target.value)}/>
                            </div>
                        </React.Fragment>
                        )}
                    </ModalBody>
                    <ModalFooter className="modal-footer-fix">
                        <ExecutionButton type="save" onClick={props.onSubmit} disabled={props.isSubmitExecuting} />
                        { !props.data.isNew && (
                            <ExecutionButton type="delete" onClick={props.onDelete} disabled={props.isSubmitExecuting} />
                        )}
                    </ModalFooter>
                    { isMotherSelectShown && CommonUtil.assertNotNull(props.ranch_id) && (
                        <SelectMotherPopup
                            setIsLoading={handleSetIsLoading}
                            ranch_id={props.ranch_id}
                            onClose={() => setIsMotherSelectShown(false)}
                            onSubmit={onMotherSelected} 
                            iniCheckedId={props.data.cow_id ?? undefined}
                            comm={props.comm} />
                    )}
                    { isAncestorPopupShown && (
                        <SettingAncestorPopup
                            comm={props.comm}
                            confirm={showQuestionAsync}
                            list={ancestors.data}
                            onClose={() => setIsAncestorPopupShown(false)}
                            teamId={props.team_id}
                        />
                    )}
                </>)}
            </Modal>
        </div>
    )
}