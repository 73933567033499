import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ITeamProgramItem } from './program-management';
import { IEditingProgramItem } from './program-management';
import styles from './program-management.module.css';
import { EventKind } from '../../config/event-kind';
import { RequiredNumInput } from '../../components/parts/num-input';
import { IUser, IMedicine, ITreatItem, IMedicineRoute } from '../../stores/index';
import { LMT } from '../../config/constant';
import { TeamTreatPresetDto } from '../../api';
import { FreezedArray } from '../../config/util';
import { IconLink } from '../../components/parts/icon-link';
import classnames from 'classnames';
import { TreatPresetSelectPopup } from '../../components/parts/treat-preset-select-popup';
import { IconRight } from '../../components/parts/icon-right';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { UserTeams } from '../../config/user-teams';

interface MyProps {
    items: Readonly<Readonly<ITeamProgramItem>[]>;
    data: IEditingProgramItem;
    onCommit:()=>void;
    onClose:()=>void;
    onDelete:(item_no:number)=>void;
    onTitleEdited:(title:string)=>void;
    onEventKindSelected:(event_kind_no:number)=>void;
    onBaseItemSelected:(base_item_no:number)=>void;
    onIntervalDaysEdited:(interval_days:number)=>void;
    onPresetSelected:(preset_id?:number)=>void;
    medicines: FreezedArray<IMedicine>;
    treatItems: FreezedArray<ITreatItem>;
    medicineRoutes: FreezedArray<IMedicineRoute>;
    presets: FreezedArray<TeamTreatPresetDto>;

    ranch_id: number | undefined;
    user: IUser;
}

export const ProgramManagementItemEditPopup = (props: MyProps) => {
    const [ isPresetShown, setIsPresetShown ] = useState(false);
    const [ teamType, setTeamType ] = useState<"officialRanch"|"unofficialRanch"|"clinic">();

    useEffect(() => {
        if (props.ranch_id == null) {
            setTeamType("clinic");
        } else {
            const isOfficial = new UserTeams(props.user).findOfficialRanch(props.ranch_id) != null;
            setTeamType(isOfficial ? "officialRanch" : "unofficialRanch");
        }

    }, [ props.user, props.ranch_id ]);

    const currentEvent = props.data.event_kind_no === 0 ? undefined : EventKind.find(props.data.event_kind_no);

    const preset = props.data.preset_id == null ? undefined : props.presets.find(p => p.preset_id === props.data.preset_id);

    const canSelectPreset = (currentEvent?.treatment != null) && (teamType !== "unofficialRanch");

    return (
        <div>
            <Modal isOpen={true} className={styles["item-modal"]}>
                <ModalHeader toggle={props.onClose}>項目編集</ModalHeader>
                <ModalBody style={{ overflowY: "auto", maxHeight: "calc(100vh - 240px)" }}>
                    <div className={classnames("form-group", styles["item-modal-block"])}>
                        <label className="col-form-label">予定タイトル</label>
                        <div className={styles.content}>
                            <input className="form-control" type="text" value={props.data.title}
                                    maxLength={LMT.SCHEDULE.TITLE_LEN}
                                    onChange={e => props.onTitleEdited(e.target.value)}/>
                        </div>
                    </div>
                    <div className={classnames("form-group", styles["item-modal-block"])}>
                        <label className="col-form-label">種別</label>
                        <div className={classnames(styles.content, styles["item-modal-eventkind"])}>
                            <select className="form-control" value={props.data.event_kind_no}
                                onChange={e => props.onEventKindSelected(parseInt(e.target.value))}>
                                <option value="0">未選択</option>
                                {
                                    (teamType === "clinic" ? EventKind.forScheduleWithClinicUser() : EventKind.forSchedule(props.ranch_id!, props.user))
                                        .map((ev) => (
                                        <option key={ev.no} value={ev.no}>{ev.schedule.sname}</option>
                                    ))
                                }
                            </select>
                            { canSelectPreset && (<>
                                <IconLink className="m-l-10 p-t-5 p-b-5"
                                    text={preset?.preset_name ?? "処置選択"} iconType="popup"
                                    onClick={() => setIsPresetShown(true) } />
                                { preset != null && (
                                    <IconRight iconType="remove" onClick={() => props.onPresetSelected(undefined)} />
                                )}
                            </>)}
                        </div>
                    </div>
                    <div className={classnames("form-group", styles["item-modal-block"])}>
                        <label className="col-form-label">予定日</label>
                        <div className={styles.content}>
                            <select className="form-control" value={props.data.base_item_no}
                                onChange={e => props.onBaseItemSelected(parseInt(e.target.value))}>
                                <option value="0">起算日</option>
                                {
                                    props.items.filter(pi => pi.item_no !== props.data.item_no).map(pi => (
                                        <option key={pi.item_no} value={pi.item_no}>{pi.title}</option>
                                    ))
                                }
                            </select>
                            <div className={styles["item-modal-day-after"]}>
                                <span>の</span>
                                <RequiredNumInput min={-1000} max={1000} step={1} className={styles["interval-days-input"]}
                                        onChange={n => props.onIntervalDaysEdited(n)}
                                        value={props.data.interval_days}
                                />
                                <span>日後</span>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className="modal-footer-fix">
                    <ExecutionButton type="decide" onClick={() => props.onCommit()} />
                    { props.data && !props.data.isNew && (
                        <ExecutionButton type="delete" onClick={() => props.onDelete(props.data.item_no)} />
                    )}
                </ModalFooter>
                { isPresetShown && (
                    <TreatPresetSelectPopup
                        presets={props.presets}
                        medicines={props.medicines}
                        routes={props.medicineRoutes}
                        treatItems={props.treatItems}
                        selectedPreset={props.data.preset_id}
                        medicinesOnly={currentEvent?.treatment === "medicine"}
                        onClose={() => setIsPresetShown(false)}
                        onSubmit={p => { props.onPresetSelected(p.preset_id); setIsPresetShown(false); }}
                    />
                )}
            </Modal>
        </div>
    )
}