import { IMedicine, IRanchFeed } from "../stores";
import moment from "moment";
import Big from "big.js";
import queryString from 'query-string';
import { saveAs } from 'file-saver';
import { RanchSeedStockDto } from "../api";
import { A } from "./constant";
import content from "../components/content/content";

export type FreezedArray<T> = Readonly<Readonly<T>[]>;

export class QueryUtil {
    static parseStringFromSearch(search: string, paramName: string) {
        const parsed = queryString.parse(search);
        const val = parsed[paramName];
        if (val == null) return undefined;
        if (Array.isArray(val)) {
            return val.join(",");
        }
        return val;
    }
    static parseNumFromSearch(search: string, paramName: string): number | undefined {
        const str = this.parseStringFromSearch(search, paramName);
        if (str == null) return undefined;
        const n = Number(str);
        if (isNaN(n)) return undefined;
        return n;
    }
    static parseNumsFromSearch(search: string, paramName: string): number[] {
        const parsed = queryString.parse(search);
        const val = parsed[paramName];
        if (val == null) return [];
        
        const vals = Array.isArray(val) ? val : val.split(",");
        return vals.map(v => Number(v)).filter(n => !isNaN(n));
    }
    static parseDateFromSearch(search: string, paramName: string): Date | undefined {
        const parsed = queryString.parse(search);
        const val = parsed[paramName];
        if (val == null) return undefined;

        const m = moment(val);
        if (!m.isValid()) return undefined;
        return m.toDate();
    }
}

export class CommonUtil {

    static arrayToStr<T>(array: T[]): string {
        return array.filter(a => a !== undefined).join(",");
    }

    static getActiveLevelStr(level) {
        if (level === 0) {
            return "無";
        } else if (level === 1) {
            return "低";
        } else if (level === 2) {
            return "やや低";
        } else if (level === 3) {
            return "良";
        }
        return "";
    }

    static getHungryLevelStr(level) {
        if (level === 0) {
            return "無";
        } else if (level === 1) {
            return "低";
        } else if (level === 2) {
            return "やや低";
        } else if (level === 3) {
            return "良";
        }
        return "";
    }

    static getBreathLevelStr(level) {
        if (level === 1) {
            return "遅";
        } else if (level === 2) {
            return "普通";
        } else if (level === 3) {
            return "微速";
        } else if (level === 4) {
            return "速";
        } else if (level === 5) {
            return "超速";
        }
        return "";
    }
        
    static truncate(val: string | undefined, len: number) {
        if (val == null) return "";
        return val.length <= len ? val: (val.substr(0, len)+"...");
    }

    static assertNotNull<T>(v: T | null | undefined, name?: string, when?: string): v is T {
        if (v == null) {
            console.error(`${name ?? "value"} is null or undef when ${when ?? "assertion"} is called.`);
            return false;
        }
        return true;
    }
    static assertRequired<T>(v: T, name?: string, when?: string): v is Required<T> {
        for (const key in v) {
            if (v[key] == null) {
                console.error(`${key} in ${name ?? "object"} is null or undef when ${when ?? "assertion"} is called.`);
                return false;
            }
        }
        return true;
    }

    static skipWhlie<T>(array: Readonly<T[]>, predicate: (item:T) => boolean) {
        const idx = array.findIndex(p => !predicate(p));
        if (idx === 0) return array;
        if (idx < 0) return [];
        return array.slice(idx);
    }

    static groupBy<TItem, TKey>(items: Readonly<TItem[]>, selector: (item: TItem) => TKey): Map<TKey, TItem[]> {
        const rtn = new Map<TKey, TItem[]>();

        for (const item of items) {
            const key = selector(item);
            let vals = rtn.get(key);
            if (vals == null) {
                vals = [];
                rtn.set(key, vals);
            }
            vals.push(item);
        }

        return rtn;
    }
    /**
     * use ar.orderBy instead
     */
    static orderBy<TItem>(items: Readonly<TItem[]>, selector: (item: TItem) => number): TItem[] {
        const rtn = [...items];
        rtn.sort((a,b) => selector(a) - selector(b));
        return rtn;
    }
    /**
     * use ar.orderByDesc instead
     */
    static orderByDesc<TItem>(items: Readonly<TItem[]>, selector: (item: TItem) => number): TItem[] {
        const rtn = [...items];
        rtn.sort((a,b) => selector(b) - selector(a));
        return rtn;
    }
}
//TODO ↑の配列系を順次↓に
export class ar {

    static orderBy<TItem, TVal extends number|string>(items: Readonly<TItem[]>, selector: (item: TItem) => TVal): TItem[] {
        const rtn = [...items];
        rtn.sort((a,b) => {
            const valA = selector(a);
            const valB = selector(b);
            if (valA === valB) return 0;
            return valA < valB ? -1 : 1;
        });
        return rtn;
    }
    static orderByDesc<TItem, TVal extends number|string>(items: Readonly<TItem[]>, selector: (item: TItem) => TVal): TItem[] {
        const rtn = [...items];
        rtn.sort((a,b) => {
            const valA = selector(a);
            const valB = selector(b);
            if (valA === valB) return 0;
            return valA > valB ? -1 : 1;
        });
        return rtn;
    }

    static last<T>(array: T[]): T {
        return array[array.length-1];
    }
    static lastOrUndef<T>(array: T[]): T|undefined {
        return array[array.length-1];
    }

    static any<T>(array: T[]): boolean {
        return array.length > 0;
    }

    static notNull<T>(array: Array<T|null|undefined>): T[] {
        return array.filter((t): t is T => t != null);
    }

    static flat<T>(items: T[][][], depth: 2): T[];
    static flat<T>(items: T[][], depth?:1):T[];
    static flat<T>(items, depth?: 1 | 2) {
        const flattend:T[] = [];
        (function f(array: T[], depth) {
            for (let el of array) {
                if (Array.isArray(el) && depth > 0) {
                    f(el, depth - 1); 
                } else {
                    flattend.push(el);
                }
            }
        })(items, Math.floor(depth ?? 1) || 1);

        return flattend;
    }
    static distinct<T>(items:T[]) {
        return [...new Set(items)];
    }

    static sum(nums: number[]): number {
        return nums.reduce((n,nn) => n + nn, 0);
    }

    static intRange(from: number, count: number): number[] {
        return [...Array(count).keys()].map(n => n + from);
    }
    static repeat<T>(val: T, count: number): T[] {
        return [...Array(count).keys()].map(() => val);
    }

    static toMap<TItem,TKey,TVal>(items:TItem[], keyPicker:(item:TItem) => TKey, valPicker: (item:TItem) => TVal) {
        const map = new Map<TKey, TVal>();
        for (const item of items) {
            const key = keyPicker(item);
            if (map.has(key)) throw new Error("key duplicated " + key);
            map.set(key, valPicker(item));
        }
        return map;
    }
}

export type ICowWeightInfo = {
    std_weight: number | null;
    birthday: string | null;
}

export const AGE_MODE = {
    BIRTHDAY: 1,
    FIRST_OF_MONTH: 2
} as const;

export const calcAge = (now: moment.Moment, birthday: moment.Moment, mode: number, precise?: boolean) => {
    if (mode === AGE_MODE.FIRST_OF_MONTH && precise === true) {
        console.warn("precise can't be selected in first_of_month age mode");
        precise = false;
    }

    const baseDate = mode === AGE_MODE.FIRST_OF_MONTH ? moment(birthday).startOf("month") : birthday;

    if (precise === true) {
        return Math.round(now.diff(baseDate, "months", true) * 10) / 10;

    } else {
        return now.diff(baseDate, "months");
    }
}
export const calcDayAge = (now: moment.Moment, birthday: moment.Moment) => {
    const today = moment(now).startOf("day");
    return today.diff(birthday, "days");
}

export const calcDg = (dataFrom: { score: number, date: Date|string|moment.Moment }, dataTo: { score: number, date: Date|string|moment.Moment }) : number | undefined => {
    //時間までは加味しない
    const from = moment(dataFrom.date).startOf("day");
    const to = moment(dataTo.date).startOf("day");
    const days = to.diff(from, "days");
    if (days <= 0) {
        return undefined;
    }

    const total = dataTo.score - dataFrom.score;
    const dg = total / days;

    return roundDg(dg);
}
export const roundDg = (dg: number | undefined) => {
    if (dg == null) return undefined;
    return Math.round(dg * 100) / 100;
}

/**
 * 体重別薬剤投与量の算出
 * @param cow
 * @param medicine
 */
export const calcMedicineAmountByWeight = (cow:Readonly<ICowWeightInfo>, medicine: IMedicine|undefined): number|null => {
    if(medicine?.default_amount == null) { return null; }
    if(medicine.default_amount_weight == null) { return medicine.default_amount; }
    if(cow.std_weight == null || cow.birthday == null) { return null; }
    const age = calcAge(moment(), moment(cow.birthday), AGE_MODE.BIRTHDAY);
    let weight = Big(50); // 体重
    if (age > 0) {
        const round_unit = cow.std_weight < 200 ? 10 : 50;  // 丸め単位
        weight = Big(cow.std_weight).div(round_unit).round(0, 1).times(round_unit); 
    }
    const amount = weight.div(100).times(medicine.default_amount).round(0, 3);
    return Number(amount);
}

/**
 * 希釈前の給餌量・残餌量を算出
 * @param feed_no 
 * @param solution 
 * @param feeds 
 */
export const calcSolute = (feed_no:number, solution: number, feeds: Readonly<Readonly<IRanchFeed>[]>) => {
    if (solution <= 0) return 0;
    const scale = feeds.find(f => f.feed_no === feed_no)?.convert_scale;
    if (scale == null) return solution;
    //conver_scale: 0 も未設定扱いとする
    if (scale === 0) return solution;
    return solution / scale * 1000;
}

const yenFormat = new Intl.NumberFormat('ja-JP');
export const formatYen = (val:number, round?:boolean) => yenFormat.format(round ? Math.round(val) : val);

export const ifNaN = (val:number, or:number) => (isNaN(val) ? or : val);

export const buildCsvRow = (vals: Readonly<Array<string|number>>) => {
    //ダブルクォートのエスケープ
    const escaped = vals.map(v => typeof(v) === "string" ? v.replace(/"/g, '""') : v);
    return escaped.map(c => `"${c}"`).join(",");

}

export const saveTextFile = (content: string, fileName:string, fileType = "text/csv") => {

    //※現在のバージョンでAutoBomが正常動作しないので自前で付与
    const blob = new Blob([
        String.fromCharCode(0xFEFF),
        content,
    ], { type: `${fileType};charset=utf-8` });

    saveAs(blob, fileName);
}
export const saveBase64File = (content: string, fileName: string, fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") => {
    const blob = b64toBlob(content, fileType);
    saveAs(blob, fileName);
}

const b64toBlob = (b64Data:string, contentType:string, sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays: Uint8Array[] = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
}

export const zeroPad = (num: number, len: number) => {
    if (num < 0) throw new Error("not supported " + num);
    if (len < 0 || 1000 < len) throw new Error("invalid len " + len);

    const numStr = num.toString();
    if (numStr.length >= len) return numStr;

    return (Array(len).join('0') + numStr ).slice( -len );
}

export const averageNum = <T>(list: Readonly<T[]>, picker: (item:T) => number | undefined, point = 0) => {
    const vals = ar.notNull(list.map(picker));
    if (vals.length === 0) return undefined;
    const sum = ar.sum(vals);
    const av = new Big(sum).div(vals.length);
    return Number(av.round(point, 1).toString());
}

/**
 * from ～ to に含まれる月に対し、各月の1日を列挙する
 * @param from 開始月（日付は無視）
 * @param to 終了月（日付は無視）
 */
export const enumerateStartOfMonth = (from: Date, to: Date) => {
    const tmpMonth1st = moment(from).startOf("month");
    const endMonth1st = moment(to).startOf("month");

    const dates: Date[] = [];
    while(tmpMonth1st.isSameOrBefore(endMonth1st)) {
        dates.push(tmpMonth1st.toDate());
        tmpMonth1st.add(1, "month");
    }
    return dates;
}

export const formatDiseaseCode = (code: number) => zeroPad(code, 6);

export const generateKey = (prefix?: string) => {
    return `${prefix ?? ""}${Math.floor(Math.random() * 100000)}` 
}