import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FormMultiLineText } from '../form/form-multi-line-text';
import { CowDetailDto } from '../../api';
import { CowToDispInfo } from '../parts/cows-popup';
import { A, BREEDING_STATES, BREEDING_STATE } from '../../config/constant';
import moment from 'moment';
import { ICowUse, ICowBreed, IFeedType, IFecesState, IFecesColor, ITreatKind, IUser } from '../../stores';
import styles from './header.module.css';
import { ISearchedCow } from './header';
import { CommonUtil, calcAge } from '../../config/util';
import { EventData, isSellCow, isCowSellMilk, isGrowth, isSov, isOpu, isIvf, isIvfEgg, isOtherProfitCow, isOtherLossCow } from '../../pages/cow/cow-info';
import { buildFeedingHisStr, renderSymptomStr, selectTreatTitle, renderRutHisInfo, renderBreedingHisInfo, renderDeliveryHisInfo, renderCrossHisInfo, getTreatHisStr, renderSellCowHisInfo, renderCowSellMilkHisInfo, renderGrowthHisStr, renderSovHis, renderOpuHis, renderIvfHis, renderIvfEggHis } from '../../pages/cow/cow-event-renderer';
import { IDeliveryHis, CrossHistory } from '../../pages/cow/cow-event';
import { hasRanchAuth } from '../../config/auth-checker';
import { EVENT_KIND } from '../../config/event-kind';
import { useRanchHouses, useRanchFeeds } from '../../stores/fetcher';
import { FetchWaiter, FetchError } from '../content/fetch-state';

export interface SearchedCowPopupProps {
    onClose:()=>void;
    cow:Readonly<ISearchedCow>;
    deliveryHis: Readonly<IDeliveryHis[]>;
    crossHis: Readonly<CrossHistory[]>;
    useList: Readonly<Readonly<ICowUse>[]>;
    breedList: Readonly<Readonly<ICowBreed>[]>;
    feedTypeList: Readonly<Readonly<IFeedType>[]>;
    fecesStateList: Readonly<Readonly<IFecesState>[]>;
    fecesColorList: Readonly<Readonly<IFecesColor>[]>;
    treatKindList: Readonly<Readonly<ITreatKind>[]>;
    onCowLinkClick: () => void;
    showsEvents?: boolean;
    showsIsActive?: boolean;
    ranch_id: number;
    user: Readonly<IUser>;
    ageMode: number;
}

export const SearchedCowPopup: React.FC<SearchedCowPopupProps> = (props) => {
    const mHouses = useRanchHouses(props.ranch_id);
    const mFeeds = useRanchFeeds(props.ranch_id);

    const buildAgeStr = (cow: CowDetailDto) => {
        if (cow.birthday == null) return "生年月日未設定";

        const bMom = moment(cow.birthday);

        const birthDay = bMom.format("YYYY/MM/DD") + "生";
        const age = "月齢 " + calcAge(moment(), bMom, props.ageMode) + "ヵ月";
        return `${birthDay}（${age}）`;
    }

    const buildLocationStr = (siteNo: number, barnNo:number, roomNo:number) => {
        if (siteNo === 0) return "未設定";

        if (mHouses.data == null) return "";

        const site = mHouses.data.find(h => h.level === 1 && h.no === siteNo);
        if (site == null) return "削除された分場";

        const barn = barnNo === 0 ? null : (site.data ?? []).find(b => b.no === barnNo);
        if (barn == null && barnNo > 0) return site.name + " 削除された牛舎";
        
        const room = (barn == null || roomNo === 0) ? null
                    : (barn.data ?? []).find(r => r.no === roomNo);
        if (barn != null && room == null && roomNo > 0) return `${site.name} ${barn.name} 削除された部屋`;
        
        return `${site.name} ${barn?.name ?? ""} ${room?.name ?? ""}`;
    }

    const buildAncestorStr = (cow: CowDetailDto) => {
        const a1 = (cow.ancestor_1 ?? "") === "" ? "" : "1." + cow.ancestor_1;
        const a2 = (cow.ancestor_2 ?? "") === "" ? "" : "2." + cow.ancestor_2;
        const a3 = (cow.ancestor_3 ?? "") === "" ? "" : "3." + cow.ancestor_3;

        if (a1 === "" && a2 === "" && a3 === "") return "未設定";

        return `${a1} ${a2} ${a3}`;
    }

    const renderEvent = (event: EventData) => {
        if (!CommonUtil.assertNotNull(mFeeds.data, "mFeeds")) return <></>

        const canRefFeedAmount = hasRanchAuth("FOODING_REF_AMOUNT", props.ranch_id, props.user);
        const canRefTreatAmount = hasRanchAuth("TREAT_REF_AMOUNT", props.ranch_id, props.user);
        const canRefCrossName = hasRanchAuth("CROSS_REF_NAME", props.ranch_id, props.user);

        return (<>
            { ("feed_no" in event) && (
                <div className={styles["event-row"]}>
                    <span className={styles["event-row-header"]}>［えさ］</span>
                    <span>{buildFeedingHisStr(event, mFeeds.data, props.feedTypeList, canRefFeedAmount)}</span>
                </div>
            )}
            { ("symptom_id" in event && event.event_kind_no === EVENT_KIND.SYMPTOM.no) && (
                <div className={styles["event-row"]}>
                    <span className={styles["event-row-header"]}>［体調］</span>
                    <span>{ renderSymptomStr(event, props.fecesStateList, props.fecesColorList) }</span>
                    
                </div>
            )}
            { ("treat" in event && event.event_kind_no === EVENT_KIND.SYMPTOM.no) && event.treat.map((treat) => (
                <div key={treat.treat_no} className={styles["event-row"]}>
                    <span className={styles["event-row-header"]}>［{selectTreatTitle(treat.treat_kind_no, "symptom", props.treatKindList)}］</span>
                    <span>
                        { getTreatHisStr(treat.treat_item_name, treat.amount, treat.treat_item_unit, canRefTreatAmount)}
                    </span>
                </div>
            ))}
            { ("prevention_id" in event && event.event_kind_no === EVENT_KIND.PREVENTION.no) && event.prevention_detail.map((p_detail) => (
                <div key={p_detail.detail_no} className={styles["event-row"]}>
                    <span className={styles["event-row-header"]}>［{selectTreatTitle(p_detail.treat_kind_no, "prevention", props.treatKindList)}］</span>
                    <span>
                        { getTreatHisStr(p_detail.treat_item_name, p_detail.amount, p_detail.treat_item_unit, canRefTreatAmount)}
                    </span>
                </div>
            ))}
            { ("location_id" in event && event.event_kind_no === EVENT_KIND.LOCATION.no) && (
                <div className={styles["event-row"]}>
                    <span className={styles["event-row-header"]}>［移動］</span>
                    <span>{buildLocationStr(event.site, event.barn, event.room)}</span>
                </div>
            )}
            { ("rut_id" in event) && (
                <div className={styles["event-row"]}>
                    <span className={styles["event-row-header"]}>［発情］</span>
                    <span>{renderRutHisInfo(event)}</span>
                </div>
            )}
            { ("breeding_id" in event) && (
                <div className={styles["event-row"]}>
                    <span className={styles["event-row-header"]}>［検診］</span>
                    <span>{renderBreedingHisInfo(event)}</span>
                </div>
            )}
            { ("delivery_id" in event) && (
                <div className={styles["event-row"]}>
                    <span className={styles["event-row-header"]}>［分娩］</span>
                    <span>{renderDeliveryHisInfo(event)}</span>
                </div>
            )}
            { ("cross" in event) && event.cross != null && (
                <div className={styles["event-row"]}>
                    <span className={styles["event-row-header"]}>［交配］</span>
                    <span>{renderCrossHisInfo(event.cross, canRefCrossName, { watched_at: event.reg_time, deliveryHistories: props.deliveryHis, crossHistories: props.crossHis })}</span>
                </div>
            )}
            { ("rut_id" in event || "breeding_id" in event) && event.details.map((treat, i) => (
                <div key={i} className={styles["event-row"]}>
                    <span className={styles["event-row-header"]}>［{selectTreatTitle(treat.treat_kind_no, "breeding", props.treatKindList)}］</span>
                    <span>
                        { getTreatHisStr(treat.treat_item_name, treat.amount, treat.treat_item_unit, canRefTreatAmount)}
                    </span>
                </div>
            ))}
            { isSellCow(event) && (
                <div className={styles["event-row"]}>
                    <span className={styles["event-row-header"]}>［販売］</span>
                    <span>{renderSellCowHisInfo(event)}</span>
                </div>
            )}
            { isCowSellMilk(event) && (
                <div className={styles["event-row"]}>
                    <span className={styles["event-row-header"]}>［販売］</span>
                    <span>{renderCowSellMilkHisInfo(event)}</span>
                </div>
            )}
            { (isOtherProfitCow(event) || isOtherLossCow(event)) && (
                <div className={styles["event-row"]}>
                    <span className={styles["event-row-header"]}>［{isOtherProfitCow(event) ? "販売" : "購入"}］</span>
                    <span>{isOtherProfitCow(event) ? "雑収入" : "雑損失"}</span>
                </div>
            )}
            { isGrowth(event) && (
                <div className={styles["event-row"]}>
                    <span className={styles["event-row-header"]}>［測定］</span>
                    <span>{renderGrowthHisStr(event)}</span>
                </div>
            )}
            { isSov(event) && (
                <div className={styles["event-row"]}>
                    <span className={styles["event-row-header"]}>［体内受精卵］</span>
                    <span>{renderSovHis(event)}</span>
                </div>
            )}
            { isOpu(event) && (
                <div className={styles["event-row"]}>
                    <span className={styles["event-row-header"]}>［OPU］</span>
                    <span>{renderOpuHis(event)}</span>
                </div>
            )}
            { isIvf(event) && (
                <div className={styles["event-row"]}>
                    <span className={styles["event-row-header"]}>［媒精］</span>
                    <span>{renderIvfHis(event)}</span>
                </div>
            )}
            { isIvfEgg(event) && (
                <div className={styles["event-row"]}>
                    <span className={styles["event-row-header"]}>［培養結果］</span>
                    <span>{renderIvfEggHis(event)}</span>
                </div>
            )}
        </>)
    }

    return (
        <div>
            <Modal isOpen={true} toggle={props.onClose} centered={true} scrollable={true}>
                <ModalHeader toggle={props.onClose}>{CowToDispInfo(props.cow)}</ModalHeader>
                { (mHouses.isLoading || mFeeds.isLoading) ? (
                    <FetchWaiter />
                ) : (mHouses.isError || mHouses.data == null || mFeeds.isError || mFeeds.data == null) ? (
                    <FetchError />
                ) : (<>
                    <ModalBody style={{ minHeight:"200px"}}>
                        <div className={styles["cow-popup-basic-info"]}>
                            <div>{props.cow.trace_id} {A.GET_SEX_MARK(props.cow.is_male)} {buildAgeStr(props.cow)}</div>
                            <div>品種：{props.breedList.find(b => b.breed_no === props.cow.breed_no)?.name ?? "未設定"}</div>
                            <div>用途：{props.useList.find(u => u.use_no === props.cow.use_no)?.name ?? "未設定"}</div>
                            <div>場所：{buildLocationStr(props.cow.site, props.cow.barn, props.cow.room)}</div>
                            <div>三代祖：{buildAncestorStr(props.cow)}</div>
                            <div>母牛：{(props.cow.mother == null || props.cow.mother.cow_id == null) ? (props.cow.mother_name ?? "") === "" ? "未設定" : props.cow.mother_name : CowToDispInfo(props.cow.mother)}</div>
                            <div>代理母：{(props.cow.surrogate_mother == null || props.cow.surrogate_mother.cow_id == null) ? (props.cow.surrogate_mother_name ?? "") === "" ? "未設定" : props.cow.surrogate_mother_name : CowToDispInfo(props.cow.surrogate_mother)}</div>
                            <div>導入元：{[...A.START_KIND.values()].find(s => s.no === props.cow.start_kind)?.name}</div>
                            { (props.cow.start_kind === A.START_KIND.get("OUTSIDE")!.no) && (<> 
                                <div>導入日：{(props.cow.accept_day ?? "") === "" ? "未設定" : moment(props.cow.accept_day!).format("YYYY/MM/DD")}</div>
                                <div>導入牧場：{(props.cow.accept_from ?? "") === "" ? "未設定" : props.cow.accept_from}</div>
                            </>)}
                            { A.IS_FOR_BREEDING_COW(props.cow.use_no ?? 0) && (
                                <div>
                                    <span>繁殖状態：{BREEDING_STATES.find(s => BREEDING_STATE[s].no === props.cow.breeding_state) ?? ""}</span>
                                    { props.cow.open_day != null && (
                                        <span>（OPEN予定 {moment(props.cow.open_day).format("M月D日")}）</span>
                                    )}
                                </div>
                            )}
                            { props.showsIsActive === true && (
                                <div>飼養状態：{props.cow.is_active === 1 ? "飼養中" : "飼養終了"}</div>
                            )}
                            { props.cow.note != null && props.cow.note !== "" && (
                                <FormMultiLineText>{props.cow.note}</FormMultiLineText>
                            )}
                        </div>
                        { props.showsEvents !== false && (
                            <div style={{marginTop:"14px"}}>
                                <b>イベント履歴</b>
                                { [...CommonUtil.groupBy(props.cow.event_list ?? [], e => e.day).entries()]
                                    .map((g,di) => (
                                        <React.Fragment key={g[0]}>
                                            <hr className={styles["hr-border"]} />
                                            <div style={{display:"flex", alignItems:"flex-start"}}>
                                                <div style={{width:"80px"}}>{moment(g[0]).format("M月D日")}</div>
                                                <div style={{flex:1}}>
                                                    { g[1].map((e,i) => (
                                                        <React.Fragment key={i}>
                                                            {renderEvent(e)}
                                                            { i !== g[1].length -1 && (
                                                                <hr className={styles["hr-border"]} />
                                                            )}
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )
                                )}
                            </div>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <div className="link" onClick={props.onCowLinkClick}>牛TOPへ</div>
                    </ModalFooter>
                </>)}
            </Modal>
        </div>
    );
}