import React, { useState, useCallback, useEffect } from 'react';
import { Collapse } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { A, BREEDING_STATE, LMT, TIMEPRESETS, EVENT_TYPE_PARAM } from '../../config/constant';
import { PageSettings } from '../../config/page-settings';
import { CommonUtil,  FreezedArray, generateKey, ar } from '../../config/util';
import { withContext, IMedicineCategory, IUser } from '../../stores';
import Base, { BaseProps } from '../../components/content/base';
import { CowsPopup, RenderCowNameInfo } from '../../components/parts/cows-popup';
import moment from 'moment';
import { RutModifyReq, RutDto, CowBreedingModifyReq, CowApi, DeliveryApi, BreedingApi, CowDetailDto, TeamTreatPresetDto, RutApi, RanchSchedulePresetDto } from '../../api';
import { IEventWriteLocationState, historyLocation, IEventWriteParamCow } from '../../config/history-location-builder';
import { ScheduleFinder } from '../schedule/schedule-finder';
import { AppState } from '../../app';
import { NextSchedule, NextScheduleSelector, toNextScheduleReq } from '../../components/next-schedule/next-schedule-selector';
import { DEFAULT_OPEN_DAYS_AFTER_DELIVERY, confirmBreedingStateChange, BREEDING_STATE_CONFIRMED } from '../../components/parts/breeding-state-selector';
import { BreedingState, hasOpenDay } from '../breeding/breeding-state';
import styles from './rut.module.css';
import { IDeliveryHis } from "../../pages/cow/cow-event";
import { TreatSelector, ITreatSelectorData, validateTreat, TREAT_VALIDATION_RESULT, convPresetToTreatSelectorData, convScheduledPresetToTreatSelectorData, convertTreatsToModifyReq } from '../../components/parts/treat-selector';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { EVENT_KIND } from '../../config/event-kind';
import { Communicator } from '../../api/communicator';
import { V3DateTime } from '../../components/parts/v3-date-time-picker';
import { hasTeamAuth } from '../../config/auth-checker';
import { IconLink } from '../../components/parts/icon-link';
import { TreatPresetSelectPopup } from '../../components/parts/treat-preset-select-popup';
import { UserTeams } from '../../config/user-teams';
import { PreloadedProgramSelectPopupProps, showPreloadedProgramSelectDialog, PreloadedProgramSelectPopup } from '../program/preloaded-program-select-popup';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { DIALOG_BUTTONS } from '../../components/form/form-dialog';
import { resetMedicines, usePresetsWithBothTeamMedicines } from '../../stores/fetcher';
import { FetchError, FetchWaiter } from '../../components/content/fetch-state';
import { ModifyReqTreat_fix } from '../../api/api-fix';
import { SingleCowHeader } from 'components/cow-header/single-cow-header';
import { MultiCowHeader } from 'components/cow-header/multi-cow-header';
import { getCowWithCache, resetCow } from 'stores/fetcher_cow';

interface MyState {
    ranchId: number;
    clinicId: number | undefined;
    baseOpenDay: Date;
    executing: boolean;
    preloadedProgram?: PreloadedProgramSelectPopupProps;
    original?: RutDto;
    cow_top: boolean;
    cow?: CowDetailDto;
    cows: IEventWriteParamCow[];
}

interface IEditingRut {
    watched_at: Date;
    watched_type: number;
    signs: number;
    details: FreezedArray<ITreatSelectorData & { key: string }>;
    comment: string;
    cross_plan: Readonly<{ isSelected: boolean, schedule: NextSchedule }>;
    breeding_plans: Readonly<Readonly<{ isSelected: boolean, schedule: NextSchedule }>[]>;
    state: Readonly<{ isSelected: boolean, value: number, openday?: Date }>;
}

export type RutWriteLocationState = IEventWriteLocationState & {
    cow_top?: boolean;
}

export const RUT_INTERVAL_DAYS = 19;

export const crossPlanTitleFormatter = (prefix: string | undefined, candidate: string | undefined) => {
    return candidate ? `${candidate}予定` : "";
}

class RutWrite extends Base<BaseProps<{id:string},{},RutWriteLocationState|undefined>, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            ranchId:0,
            clinicId:undefined,
            baseOpenDay: new Date(),
            executing: false,
            cow_top:false,
            cows:[],
        }
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    componentDidMount() {

        this.context.handleSetHeader({ title: "発情観察記録" });
        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        if (this.handleNotAllowAccess(undefined,["BREEDING_EDIT"], [], ["BREEDING"])) {
            return;
        }

        this.init();
    }

    private async init() {
        const _mayId = parseInt(this.props.match.params.id ?? "");
        const originalId = isNaN(_mayId) ? undefined : _mayId;

        let cows: IEventWriteParamCow[] = this.props.location.state?.cows ?? [];

        //validate
        if (originalId == null && cows.length === 0) {
            console.error("invalid location param: no cow");
            return;
        }

        const ranchId = this.props.rootStore.cur_ranch_id;
        const clinicId = this.props.rootStore.getClinicIdForMaster();

        await this.setStateAsync({ ranchId, clinicId });

        let cow: CowDetailDto | undefined;
        let original : RutDto | undefined;

        if (originalId != null) {
            original = await this.loadRut(originalId);
            if (original == null) return;
            
            cow = await this.loadCowInfo(ranchId, original.cow_id);
            if (cow == null) return;
            cows = [ { ...cow, use_no:cow.use_no, breed_no:cow.breed_no } ];
        } else {
            if (cows.length === 1) {
                cow = await this.loadCowInfo(ranchId, cows[0].cow_id);
                if (cow == null) return;
            }
        }

        const openDay = await this.getBaseOpenDay(cows.map(c => c.cow_id), new Date());

        this.setState({
            cow_top: this.props.location.state?.cow_top === true,
            baseOpenDay: openDay,
            cow,
            cows,
            original
        })
    }

    private loadRut = async (id: number) => {
        const url = "/rut/" + id;
        const res = await this.comm().send<RutDto>(() => this.context.postAsync(url, {}), { retries: true });
        if (res.result !== "OK" || res.data == null) return undefined;

        if (this.navToRanchTopIfSelfEditOnly("RUT", res.data.watched_by)) return undefined;
        return res.data;
    }

    private loadCowInfo = async (ranchId: number, cowId: number) => {
        const res = await this.comm().send(() => getCowWithCache(ranchId, cowId));
        return res.data;
    }

    private getBaseOpenDay = async (cowIds: number[], now:Date) => {
        if (cowIds.length !== 1) {
            return moment(now).add(DEFAULT_OPEN_DAYS_AFTER_DELIVERY, "days").toDate();
        }

        //分娩日からopen予定初期値を決定
        const deliveryHistory = await this.loadDeliveryHistory(this.state.ranchId, cowIds[0]) ?? [];
        if (deliveryHistory.length > 0) {
            const lastDelivery = deliveryHistory[deliveryHistory.length - 1];

            const openDayMom = moment(lastDelivery.delivered_at).add(DEFAULT_OPEN_DAYS_AFTER_DELIVERY, "days");
            if (openDayMom.isAfter(now)) {
                return openDayMom.toDate();
            }
        }
        return moment(now).add(DEFAULT_OPEN_DAYS_AFTER_DELIVERY, "days").toDate();
    }

    private onSave = async (data: Omit<IEditingRut, "details">, details: ModifyReqTreat_fix[]) => {

        try {
            this.setState({ executing: true });

            const validated = await this.validateBreedingState(data);
            if (validated == null || validated === BREEDING_STATE_CONFIRMED.CANCEL ) return;

            const plans = [ data.cross_plan, ...data.breeding_plans ].filter(p => p.isSelected)
                                                                        .map(p => toNextScheduleReq(p.schedule, this.state.clinicId));

            const cowIds = this.state.cows.map(c => c.cow_id);

            const req: RutModifyReq = {
                is_new: this.state.original == null ? 1 : 0,
                ranch_id: this.state.ranchId,
                rut_id: this.state.original?.rut_id,
                cow_ids: cowIds,
                watched_type: data.watched_type,
                watched_at: moment(data.watched_at).format("YYYY-MM-DD HH:mm"),
                signs: data.signs,
                details: details,
                comment: data.comment,
                next_rut_day: Number(data.signs) === 0 ? undefined : moment(data.watched_at!).add(RUT_INTERVAL_DAYS, 'd').format("YYYY-MM-DD"),
                schedule_id: this.props.location.state?.schedule_id,
                plans:plans,
                preloaded_program: { list:[], tags:[] }
            }    

            //関連する予定を探す
            if (req.schedule_id == null && req.cow_ids.length === 1 && req.is_new === 1) {
                const day = moment(data.watched_at).format("YYYY-MM-DD");

                const finder = new ScheduleFinder(this.context, this.state.ranchId, this.props.rootStore.user.id);
                const scheRes = await finder.findEventRelatedSchedule(day, [ EVENT_KIND.RUT.no ], req.cow_ids[0]);

                if (scheRes.result === "cancel") return;

                if (scheRes.result === "yes") {
                    req.schedule_id = scheRes.id;
                }
            }

            if (req.is_new === 1) {
                //プログラムの確認
                const pgReq = await showPreloadedProgramSelectDialog(
                    this.context,
                    async () => this.comm().send((await RutApi()).getProgramsForRutUsingPOST({ eventReq:req, clinic_id:this.state.clinicId })),
                    this.props.rootStore,
                    req.ranch_id,
                    this.state.clinicId,
                    st => this.setState({ preloadedProgram:st }),
                    this.state.cows
                );
                if (pgReq == null) return false;
                req.preloaded_program = pgReq;
            }

            // 更新要求
            this.context.handleSetIsLoading(true);
            const res = await this.comm().send((await RutApi()).modifyUsingPOST4(req));
            this.context.handleSetIsLoading(false);

            if (res.result === "OK") {
                //薬マスタを再取得しておく
                resetMedicines(this.state.clinicId ?? this.state.ranchId, false);

                // 繁殖状態更新
                if (validated === BREEDING_STATE_CONFIRMED.OK) {
                    const resbs = await this.onSubmitBreedingState(data);
                    if (!resbs) {
                        await this.context.showDialog("WARNING", A.MESSAGE.BREEDING_STATE_COMMIT_FAILED, DIALOG_BUTTONS.OK);
                        window.history.go(-1);
                        return;
                    }
                } else {
                    // アクティブ牛リストを再取得
                    await this.props.rootStore.fetchActiveCows(undefined, "DIFF");
                }

                if (req.is_new) {
                    if (this.state.cow_top) {
                        this.props.history.replace(historyLocation.toCowInfo(cowIds[0]));
                    } else {
                        window.history.go(-1);
                    }
                } else {
                    this.props.history.replace(historyLocation.toCowEvent(cowIds[0], moment(data.watched_at).format("YYYY-MM-DD"), EVENT_TYPE_PARAM.BREEDING));
                }
            }
        } finally {
            this.setState({ executing: false });
        }
    }

    private async onDelete() {
        if (!CommonUtil.assertNotNull(this.state.original, "original", "delete")) return;

        const confirmResult = await this.context.showDialog("QUESTION", "この記録を削除してよろしいですか？", DIALOG_BUTTONS.DELETE_CANCEL);
        if (confirmResult !== 0) return;

        const params = {
            rut_id: this.state.original.rut_id,
            ranch_id: this.state.ranchId
        };
        this.context.handleSetIsLoading(true);
        const res = await this.comm().send((await RutApi()).deleteUsingPOST4(params));
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") return;

        // アクティブ牛リストを再取得
        this.props.rootStore.fetchActiveCows(undefined, "DIFF");
        this.props.history.replace(
            historyLocation.toCowEvent(
                this.state.cows[0].cow_id, 
                moment(this.state.original.watched_at).format("YYYY-MM-DD"),
                EVENT_TYPE_PARAM.BREEDING
            )
        )
    }

    private async validateBreedingState(data: Pick<IEditingRut, "state">) {
        if (!data.state.isSelected) return BREEDING_STATE_CONFIRMED.NO;

        this.context.handleSetIsLoading(true);
        try {
            for (const { cow_id } of this.state.cows) {

                const cow = await this.loadCowInfo(this.state.ranchId, cow_id);
                if (cow == null) return;
    
                const deliveryHistory = await this.loadDeliveryHistory(this.state.ranchId, cow_id);
                if (!deliveryHistory) return;
                const crossHistory = await this.loadCrossHistory(this.state.ranchId, cow_id);
                if (!crossHistory) return;
                const confirmed = await confirmBreedingStateChange(
                    cow.breeding_state,
                    data.state.value,
                    deliveryHistory,
                    crossHistory,
                    this.context.showQuestionAsync,
                );
                if (confirmed === -1) continue;
                if (confirmed === 1) return BREEDING_STATE_CONFIRMED.CANCEL;
                if (confirmed !== 0) return BREEDING_STATE_CONFIRMED.CANCEL;
                break;
            }
            return BREEDING_STATE_CONFIRMED.OK;

        } finally {
            this.context.handleSetIsLoading(false);
        }
    }

    private async onSubmitBreedingState(data: Pick<IEditingRut, "state">) {
        if (!data.state.isSelected) {
            console.error("onSubmitBreedingState called with unseleced state");
            return true;
        }

        const req: CowBreedingModifyReq = {
            cow_ids: this.state.cows.map(c => c.cow_id),
            state: data.state.value,
            activity: 0,
            open_day: hasOpenDay(data.state.value) ? moment(data.state.openday).format("YYYY-MM-DD") : undefined,
            ranch_id: this.state.ranchId,
        };
        this.context.handleSetIsLoading(true);
        const res = await this.comm().send((await CowApi()).modifyBreedingUsingPOST(req), { showsErrorMessage: false })
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") return false;
        //アクティブ牛リスト更新しておく
        this.props.rootStore.fetchActiveCows(undefined, "DIFF");
        req.cow_ids.forEach(id => resetCow(id, false));
        return true;
    }

    private loadDeliveryHistory = async (ranch_id: number, cow_id:number): Promise<IDeliveryHis[]|undefined> => {

        const req = { cow_id, ranch_id };
        const res = await this.comm().send((await DeliveryApi()).getHistoryListUsingPOST1(req), { showsErrorMessage: false });

        if (res.result !== "OK") return undefined;

        return (res.data?.list ?? []).map(h => ({
            delivered_at: moment(h.delivered_at).toDate(),
            delivery_id: h.delivery_id!
        }));
    }

    private loadCrossHistory = async (ranch_id: number, cow_id:number) => {
        const req = { cow_id, ranch_id };
        const res = await this.comm().send((await BreedingApi()).getBreedingCrossHistoryUsingPOST(req), { showsErrorMessage: false });
        if (res.result !== "OK") return undefined;

        return (res.data ?? []).map(c => ({
            ...c,
            watched_at: moment(c.watched_at).toDate(),
        }));
    }


    render() {
        if (this.state.cows.length === 0) return <FetchWaiter />

        return (
            <div className="page-root">
                <RutWriteContent
                    user={this.props.rootStore.user}
                    ranchId={this.state.ranchId}
                    clinicId={this.state.clinicId}
                    original={this.state.original}
                    cow={this.state.cow}
                    cows={this.state.cows}
                    baseOpenDay={this.state.baseOpenDay}
                    schedulePreset={this.props.location.state?.preset}
                    showAlert={this.context.showAlert}
                    showToast={this.context.showToast}
                    comm={this.comm()}
                    executing={this.state.executing}
                    medicineCategories={this.props.rootStore.options.medicine_category}
                    onDelete={this.onDelete}
                    onSave={this.onSave}
                />
                { this.state.preloadedProgram && (
                    <PreloadedProgramSelectPopup
                        {...this.state.preloadedProgram}
                    />
                )}
            </div>

        )
    }
}

interface ContentProps {
    user: IUser;
    ranchId: number;
    clinicId: number | undefined;
    cow: CowDetailDto | undefined;
    cows: IEventWriteParamCow[];
    baseOpenDay: Date;
    original: RutDto | undefined;

    schedulePreset: RanchSchedulePresetDto | undefined;
    showAlert: (err:string) => void;
    comm: Communicator;
    showToast: (msg:string) => void;
    executing: boolean;

    medicineCategories: FreezedArray<IMedicineCategory>;

    onDelete: () => void;
    onSave: (data: Omit<IEditingRut, "details">, details: ModifyReqTreat_fix[]) => void;
}

const RutWriteContent = React.memo((props: ContentProps) => {
    const treatTeamId = props.clinicId ?? props.ranchId;

    const mPresets = usePresetsWithBothTeamMedicines(props.ranchId, props.clinicId);

    const [ editingData, setEditingData ] = useState<IEditingRut>({
        watched_at: new Date(),
        breeding_plans:[],
        comment:"",
        cross_plan:{ isSelected:false, schedule:{ title:"", allDay:true, start:new Date(), end:new Date(), eventKindNo:EVENT_KIND.CROSS.no, preset:undefined, note:"" } },
        details:[],
        signs:0,
        state: { isSelected:false, value: BREEDING_STATE.OPEN.no },
        watched_type: A.RUT_WATCHED_TYPE.RUT.no,
    });
    const [ isNextScheduleOpen, setIsNextScheduleOpen ] = useState(false);
    const [ isPresetShown, setIsPresetShown ] = useState(false);

    // initialize
    useEffect(() => {
        if (mPresets.data == null) return;

        const tomorrow = moment().add(1, "day").startOf("day").toDate();
        const defaultCrossPlan = {
            isSelected: false,
            schedule: {
                title: crossPlanTitleFormatter(undefined, A.CROSS_TYPE.get("AI")?.sname),
                start: tomorrow,
                end: tomorrow,
                note: "",
                allDay: true,
                eventKindNo: EVENT_KIND.CROSS.no,
                preset:undefined
            }
        };
        const defaultBreedingPlans = [{
            isSelected: false,
            schedule: {
                title: "再診",
                start: tomorrow,
                end: tomorrow,
                note: "",
                allDay: true,
                eventKindNo: EVENT_KIND.BREEDING.no,
                preset:undefined
            }
        }];
        const breedingState = props.cow == null
                    ? { isSelected: false, value: BREEDING_STATE.OPEN.no }
                    : {
                        isSelected:false,
                        value: props.cow.breeding_state,
                        openday: props.cow.open_day == null ? undefined : moment(props.cow.open_day).toDate()
                    };

        if (props.original != null) {
            const recv = props.original;
            const nextDay = moment(recv.watched_at).add(1, "day").toDate();
            setEditingData({
                watched_type: recv.watched_type,
                watched_at: moment(recv.watched_at).toDate(),
                signs: recv.signs,
                comment: recv.comment,
                details: recv.details.map(d => ({ ...d, benefit_type:"NO_BENEFIT", key:generateKey() })),
                cross_plan: { isSelected:defaultCrossPlan.isSelected, schedule: { ...defaultCrossPlan.schedule, start:nextDay, end:nextDay } },
                breeding_plans: defaultBreedingPlans.map(p => ({ ...p, schedule:{ ...p.schedule, start: nextDay, end: nextDay } })),
                state: breedingState,
            });
        } else {
            let details: ITreatSelectorData[] = [];
            if (props.schedulePreset != null) {
                const preset = props.schedulePreset;
                const mas = mPresets.data;
                const converted = preset.items.map(pi =>
                    convScheduledPresetToTreatSelectorData(pi,
                    preset.team_id,
                    props.ranchId,
                    props.clinicId,
                    mas.ranchMedicines,
                    mas.clinicMedicines,
                    mas.medicineRoutes,
                    [],
                    hasTeamAuth("MASTER_EDIT", "MASTER_EDIT", treatTeamId, props.user)));
                details = ar.notNull(converted);
            }

            setEditingData({
                watched_at: new Date(),
                breeding_plans:defaultBreedingPlans,
                comment:"",
                cross_plan:defaultCrossPlan,
                details: details.map(d => ({ ...d, key:generateKey() })),
                signs:0,
                state: breedingState,
                watched_type: A.RUT_WATCHED_TYPE.RUT.no,
            });
        }

    }, [ mPresets.data == null, props.cows, props.original, props.schedulePreset ]);





    const onEditWatchedType = useCallback((e:React.ChangeEvent<HTMLInputElement>) => {
        setEditingData({
            ...editingData,
            watched_type: Number(e.target.value)
        })
    }, [ editingData ]);

    const onEditSigns = useCallback((e:React.ChangeEvent<HTMLInputElement>) => {
        let signs = editingData.signs;
        if (e.target.checked) {
            signs! |= Number(e.target.value);
        } else {
            signs! &= ~(Number(e.target.value));
        }

        setEditingData({
            ...editingData,
            signs
        });
    }, [ editingData ]);

    const onTreatUpdated = useCallback((idx: number, detail: ITreatSelectorData) => {
        const newDetails = [ ...editingData.details ];
        newDetails[idx] = { ...detail, key:editingData.details[idx].key };

        if (detail.medicine_id != null
            && newDetails.some((d,i) => i !== idx && d.medicine_id === detail.medicine_id && d.team_id === detail.team_id)) {
            
            props.showToast(A.MESSAGE.MEDICINE_ALREADY_ADDED);
            return;
        }

        setEditingData({
            ...editingData,
            details: newDetails
        });

    }, [ editingData, props.showToast ]);

    const onAddDetail = useCallback(() => {
        setEditingData({
            ...editingData,
            details: [
                ...editingData.details,
                {
                    medicine_id: 0,
                    route_id: 0,
                    unit_price: 0,
                    team_id: props.clinicId ?? props.ranchId,
                    benefit_type: "NO_BENEFIT",
                    key: generateKey()
                }
            ]
        })
    }, [ props.ranchId, props.clinicId, editingData ]);

    const onRemoveDetail = useCallback((detail: ITreatSelectorData) => {
        setEditingData({
            ...editingData,
            details: editingData.details.filter(d => d !== detail)
        })
    }, [ editingData ]);

    const setPreset = (preset: TeamTreatPresetDto) => {
        if (!CommonUtil.assertNotNull(mPresets.data, "mPreset")) return;

        const teamId = props.clinicId ?? props.ranchId;
        const medicines = mPresets.data.clinicMedicines ?? mPresets.data.ranchMedicines;
        const routes = mPresets.data.medicineRoutes;

        const newDetails = ar.notNull(preset.items.map(p =>
            convPresetToTreatSelectorData(
                p,
                teamId,
                medicines,
                routes,
                []
            )));
        
        setEditingData({
            ...editingData,
            details: newDetails.map(d => ({ ...d, key: generateKey() }))
        });
        setIsPresetShown(false);
    }
    
    const onAddNextSchedule = (isSelected?: boolean) => {
        const date = moment(editingData.watched_at).add(1, "day").toDate();
        const newSchedule = { title: "再診", start: date, end: date, note: "", allDay: true, eventKindNo: EVENT_KIND.BREEDING.no, preset:undefined };

        setEditingData({
            ...editingData,
            breeding_plans: [
                ...editingData.breeding_plans,
                { isSelected: isSelected ?? false, schedule: newSchedule },
            ]
        });
    }    

    const onBreedingStateSelected = (isSelected:boolean, value:number, openday: Date) => {
        setEditingData({
            ...editingData,
            state: { isSelected, value, openday },
        });
    }

    const onSave = () => {
        if (!CommonUtil.assertNotNull(mPresets.data, "mPresets")) return;

        // 薬剤入力チェック
        const validDetails = new Array<ITreatSelectorData>();
        for(const detail of editingData.details) {
            const validated = validateTreat(detail, [], false);
            if (validated === "BLANK") continue;
            if (validated !== "OK") {
                const msg = TREAT_VALIDATION_RESULT[validated].msg;
                props.showToast(msg);
                return;
            }
            validDetails.push(detail);
        }
        const reqDetails = convertTreatsToModifyReq(validDetails, mPresets.data.medicineRoutes);
        props.onSave(editingData, reqDetails);
    }


    if (props.cows.length === 0) return <></>
    if (mPresets.isLoading) return <FetchWaiter />
    if (mPresets.isError || mPresets.data == null) return <FetchError />

    const collapseRowClass = "form-group row treat-write-row";
    const collapseHeaderClass = "col-form-label col-md-4 col-xs-6 clickable";
    const collapseArrowClass = (isOpen : boolean) => "fas fa-lg fa-fw " + (isOpen ? "fa-angle-up" : "fa-angle-down");

    const canEditMaster = hasTeamAuth("MASTER_EDIT", "MASTER_EDIT", treatTeamId, props.user);
    const isOfficial = new UserTeams(props.user).findOfficialRanch(props.ranchId) != null;

    const canExecSave = editingData.signs > 0
        || editingData.details.length > 0
        || editingData.comment !== "";

    return (<>
        <div className="product product-full-height">
            <div className="product-detail" style={{ height: "100%" }}>
                <div className="product-info product-info-fix">
                    <div className="product-info-header">
                        { props.cows.length === 1 && (
                            <SingleCowHeader ranchId={props.ranchId} cowId={props.cows[0].cow_id} />
                        )}
                        { props.cows.length > 1 && (
                            <MultiCowHeader infoName="発情観察記録" cowCount={props.cows.length} cows={props.cows} />
                        )}
                    </div>
                    <div className="product-body">
                        <div className="form-group row treat-write-row">
                            <label className="col-md-2 col-sm-2 col-xs-3 col-form-label">発情観察日時</label>
                            <div style={{ paddingLeft:0, flex: 1 }}>
                                <V3DateTime value={editingData.watched_at}
                                    onChange={d => setEditingData({ ...editingData, watched_at:d.toDate() })}
                                    timePresets={props.user.setting?.time_preset ?? TIMEPRESETS}
                                />
                            </div>
                        </div>
                        <div className="form-group row treat-write-row" data-testid="watched-type">
                            <label className="col-md-2 col-sm-2 col-xs-3 col-form-label">&nbsp;</label>
                            <div className="col-md-10 col-sm-10 col-xs-9">
                                <div className="radio radio-css radio-inline">
                                    <input type="radio" id="watchedTypeRut" value={A.RUT_WATCHED_TYPE.RUT.no}
                                        onChange={onEditWatchedType}
                                        checked={editingData.watched_type === A.RUT_WATCHED_TYPE.RUT.no}/>
                                    <label htmlFor="watchedTypeRut">発情</label>
                                </div>                        
                                <div className="radio radio-css radio-inline">
                                    <input type="radio" id="watchedTypePerhapsRut" value={A.RUT_WATCHED_TYPE.PERHAPS_RUT.no}
                                        onChange={onEditWatchedType}
                                        checked={editingData.watched_type === A.RUT_WATCHED_TYPE.PERHAPS_RUT.no}/>
                                    <label htmlFor="watchedTypePerhapsRut">発情疑い/発情後</label>
                                </div>                        
                            </div>
                        </div>                        
                        <div className="form-group row treat-write-row">
                            <label className="col-md-2 col-sm-2 col-xs-3 col-form-label">観察項目</label>
                            <div className="col-md-10 col-sm-10 col-xs-9 checkbox checkbox-css">
                                { A.RUT_SIGNS.map( (m, i) => (
                                    <div key={i} className="m-b-15" data-testid="rut-item">
                                        <input type="checkbox" id={`chkSign${i}`} value={m.sign} 
                                            onChange={onEditSigns}
                                            checked={(editingData.signs! & m.sign) !== 0} />
                                        <label htmlFor={`chkSign${i}`}>{m.name}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="treat-block-header">
                            <label>処置</label>
                            <IconLink text="プリセット呼び出し" iconType="popup" onClick={() => setIsPresetShown(true)} />
                        </div>
                        { editingData.details.map((detail, i) => (
                            <TreatSelector  data={{ ...detail }} key={detail.key} index={i}
                                isMedicineOnly={true}
                                onDelete={() => onRemoveDetail(detail)}
                                onChange={d => onTreatUpdated(i,d)}
                                treatKinds={[]}
                                medicineCategories={props.medicineCategories}
                                treatKindMedicineName={"投薬"}
                                defaultMedicineCategory={A.MEDICINE_CATEGORY_BREEDING}
                                weightInfo={{ std_weight: props.cow?.std_weight ?? null, birthday: props.cow?.birthday ?? null }} 
                                hasAuthToEditMaster={canEditMaster}
                                isOfficialRanch={isOfficial}
                                ranchId={props.ranchId}
                                clinicId={props.clinicId}
                            />
                        ))}
                        <div className="form-group row treat-write-row"
                                style={{ display: "flex", marginLeft: "0px", marginRight: "0px" }}>
                                <button style={{ flex: "1" }} className="btn btn-theme btn-sm btn-inverse"
                                    onClick={onAddDetail}>
                                    <i className="fas fa-lg fa-fw m-r-10 fa-plus-circle"></i>
                                    薬剤を追加
                            </button>
                        </div>
                        <div className="form-group row treat-write-row">
                            <label className="col-form-label col-md-2 col-xs-2 text-lg-right">メモ</label>
                            <div className="col-md-10 col-xs-10">
                                <textarea className="form-control" rows={4} maxLength={LMT.RUT.MEMO_LEN} data-testid="memo"
                                    value={editingData.comment} onChange={(e) => setEditingData({ ...editingData, comment:e.target.value })} />
                            </div>
                        </div>                 
                        <div className={collapseRowClass}>
                            <div className={collapseHeaderClass} data-testid="状態予定開閉"
                                onClick={() => setIsNextScheduleOpen(!isNextScheduleOpen)}>
                                <span>状態と予定を設定</span>
                                <i className={collapseArrowClass(isNextScheduleOpen)}></i>
                            </div>
                        </div>
                        <Collapse isOpen={isNextScheduleOpen}>
                            <BreedingState
                                isSelected={editingData.state.isSelected}
                                state={editingData.state.value}
                                openday={editingData.state.openday ?? props.baseOpenDay}
                                onChange={onBreedingStateSelected}
                            />

                            <hr className={styles["hr-border"]} />

                            <NextScheduleSelector
                                index={-1}
                                label={"交配予定"}
                                isSelected={editingData.cross_plan.isSelected}
                                baseDate={editingData.watched_at}
                                titleCandidates={[...A.CROSS_TYPE.values()].map(v => v.sname)}
                                excludesOther={true}
                                titleFormatter={crossPlanTitleFormatter}
                                value={editingData.cross_plan.schedule}
                                onChange={(selected,val) => 
                                    setEditingData({ 
                                        ...editingData,
                                        cross_plan: { isSelected: selected, schedule: val },
                                    })
                                }
                                presetTeamId={props.clinicId ?? props.ranchId}
                                ranchId={props.ranchId}
                            />

                            <hr className={styles["hr-border"]} />

                            <TransitionGroup>
                            { editingData.breeding_plans.map((plan, i) => (
                                <CSSTransition classNames={{ enter: styles["plan-enter"], enterActive:styles["plan-enter-active"] }} key={i} timeout={500}>
                                <div>
                                    <NextScheduleSelector
                                        index={i}
                                        label={"検診予定"}
                                        isSelected={plan.isSelected}
                                        baseDate={editingData.watched_at}
                                        titleCandidates={["排卵確認", "黄体確認", "妊娠鑑定", "再診"]}
                                        value={plan.schedule}
                                        onChange={(selected,val) => 
                                            setEditingData({ 
                                                ...editingData,
                                                breeding_plans: [
                                                    ...editingData.breeding_plans.slice(0, i),
                                                    { isSelected: selected, schedule: val },
                                                    ...editingData.breeding_plans.slice(i+1)
                                                ]
                                            })
                                        }
                                        presetTeamId={props.clinicId ?? props.ranchId}
                                        ranchId={props.ranchId}
                                    />
                                </div>
                                </CSSTransition>
                            ))}
                            </TransitionGroup>
                            <div className="form-group row"
                                style={{ marginLeft: "8px", marginRight: "0px", marginTop: "10px" }}>
                                <button style={{ flex: "1" }} className="btn btn-theme btn-black"
                                    onClick={() => onAddNextSchedule(true)}>
                                    <i className="fas fa-lg fa-fw m-r-10 fa-plus-circle"></i>
                                    検診予定を追加
                                </button>
                            </div>
                        </Collapse>
                    </div>
                </div>
            </div>
        </div>
        <div className="button-page-footer">
            <ExecutionButton type="save" onClick={onSave} disabled={props.executing || !canExecSave} />
            { props.original != null && (
                <ExecutionButton type="delete" onClick={props.onDelete} disabled={props.executing} />
            )}
        </div>
        { isPresetShown && (
            <TreatPresetSelectPopup
                presets={mPresets.data.presets ?? []}
                medicinesOnly={true}
                medicines={mPresets.data.clinicMedicines ?? mPresets.data.ranchMedicines}
                routes={mPresets.data.medicineRoutes}
                treatItems={[]}
                onClose={() => setIsPresetShown(false)}
                onSubmit={setPreset}
            />
        )}
    </>)
});

export default withRouter(withContext(RutWrite));