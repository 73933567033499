import classnames from 'classnames';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import Base, { BaseProps } from '../../components/content/base';
import { PageSettings } from '../../config/page-settings.js';
import { A, getSymptomStatus, EVENT_TYPE_PARAM, LMT } from '../../config/constant';
import { CommonUtil, FreezedArray, saveBase64File, QueryUtil } from '../../config/util';
import { withContext, IRanchFeed, IFeedType } from '../../stores';
import { SymptomSeriesPopup } from '../symptom/symptom-series-popup';
import { AppState } from '../../app';
import { IPopupCow } from '../../components/parts/cows-popup';
import moment from 'moment';
import { TextModal } from '../../components/parts/text-modal';
import { Term } from '../schedule/Term';
import { BreedingSeriesPopup } from './breeding-series-popup';
import { renderHeader, renderComment, renderTreat, renderBreedingInfo, renderCrossInfo, renderRutInfo, renderDeliveryInfo, 
    GetPedigreeStr, renderSymptomStr, buildFeedingDetailStr, renderSovEvent, renderOpuEvent, renderIvfEvent, renderIvfEggEvent, renderGrowthEvent, renderDisease
} from './cow-event-renderer';
import { buildWriteChildrenParams } from '../delivery/delivery-write';
import { DeliveryApi, BreedingApi, FeedApi, FeedLastDayDto, CowDetailDto, EventInfoReq, FeedModifyMultiReq, SympEventDto, TreatEventDto, BreedingCrossCurrentCrossDto, ExtraCowDto, GrowthBirthWeightDto, SympDiseaseEventDto, BreedingCertPregnantReq, BreedingCertEtReq, BreedingCertAiReq, FileDownloadDto } from '../../api';
import { FeedLastDayPopup, FeedLastDayModified } from '../feed/feed-lastday-popup';
import { historyLocation } from '../../config/history-location-builder';
import { hasRanchAuth } from '../../config/auth-checker';
import { EVENT_KIND, EventKindKey } from '../../config/event-kind';
import V3DatePicker from '../../components/parts/v3-date-picker';
import { getEggTypeName, getEggRankName, getEggStageName } from '../../config/egg-kind';
import { IEggRankAndCount, IOvumRankAndCount } from './cow-info';
import { EggStageData } from '../egg/egg-stage-count-modal';
import { EggStockModal } from '../egg/egg-stock-modal';
import { IvgEggRankDetailTablePopup } from './ivf-egg-rank-detail-table-popup';
import { IconLink } from '../../components/parts/icon-link';
import { UserTeams } from '../../config/user-teams';
import { CertPregPopupProps, CertPregPopup, CertCrossPopup, CertCrossPopupProps } from './cert-preg-popup';
import { USER_LICENSE } from '../../config/user-license';
import { PostSomeAsync } from '../../api/communicator';
import { loadUserStorage, updateListInUserStorage } from '../../stores/local-storage';
import { isBrowser } from 'react-device-detect';
import { useRanchFeeds } from '../../stores/fetcher';
import { FetchWaiter, FetchError } from '../../components/content/fetch-state';
import { getCowWithCache } from 'stores/fetcher_cow';
import { SingleCowHeaderTitle, SingleCowHeaderDetail } from 'components/cow-header/single-cow-header';

export const selectEventTypeParam = (eventKindNo: number) => {
    switch (eventKindNo) {
        case EVENT_KIND.FEEDING.no:
            return EVENT_TYPE_PARAM.FEED;
        case EVENT_KIND.SYMPTOM.no:
        case EVENT_KIND.PREVENTION.no:
        case EVENT_KIND.GROWTH.no:
            return EVENT_TYPE_PARAM.SYMPTOM;
        case EVENT_KIND.BREEDING.no:
        case EVENT_KIND.DELIVERY.no:
        case EVENT_KIND.CROSS.no:
        case EVENT_KIND.RUT.no:
        case EVENT_KIND.SOV.no:
        case EVENT_KIND.OPU.no:
        case EVENT_KIND.IVF.no:
        case EVENT_KIND.IVF_EGG.no:
            return EVENT_TYPE_PARAM.BREEDING;
        default:
            return undefined;
    }
}

interface IFeedAmount {
    delivered: number;
    // delivered_raw: number;
    feed_no: number;
    feed_type_no: number;
    feeding_id: number;
    leftover: number;
    // leftover_raw: number;
    unit_price: number;
}
interface IFeed {
    comment: string;
    cow_id: number;
    fed_at: string;
    fed_by: string;
    feed_amount: IFeedAmount[],
    feeding_id: number;
    ranch_id: number;
}

interface PreventionEventDto {
    prevention_id: number;
    ranch_id: number;
    cow_id: number;
    trace_id: string;
    local_no?: string;
    name?: string;
    watched_at: string;
    watched_by?: string;
    comment: string;
    created_by: string;
    detail: TreatEventDto[];
}
export interface GrowthEventDto {
    event_id: number;
    event_kind: number;
    ranch_id: number;
    cow_id: number;
    weight?: number;
    height?: number;
    chest?: number;
    abdomen?: number;
    bcs?: number;
    rfs?: number;
    watched_at: string;
    watched_by: string;
    created_by: string;
    birth_weight?: GrowthBirthWeightDto;
}

type ISymptomGrpEvent = SympEventDto | PreventionEventDto | GrowthEventDto;
type IBreedingGrpEvent = IBreeding | IDelivery | IRut | ISovEvent | IOpuEvent | IIvfEvent | IIvfEggEvent;

export interface IBreedingBase {
    watched_at: string;
    watched_by: string;
    created_by: string;
}
export interface IBreeding extends IBreedingBase {
    breeding_id: number;
    // ranch_id : number;
    // cow_id : number;
    pregnant_score? : number
    pregnant_note : string;
    bcs? : number;
    bcs_note : string;
    vulva_blood_score? : number;
    vulva_expand_score? : number;
    vulva_note : string;
    vagina_tear_score? : number;
    vagina_mucus_score? : number;
    vagina_rut_score? : number;
    vagina_note : string;
    canal_tear_score? : number;
    canal_cm? : number;
    canal_note : string;
    cornu_l_cm? : number;
    cornu_l_bright_score? : number;
    cornu_l_image_score? : number;
    cornu_l_shrink_score? : number;
    cornu_r_cm? : number;
    cornu_r_bright_score? : number;
    cornu_r_image_score? : number;
    cornu_r_shrink_score? : number;
    cornu_note : string;
    ovary_l_mm? : number;
    ovary_r_mm? : number;
    ovary_note : string;
    comment : string;
    // next_breeding? : string;
    // delivery? : string;
    // dry_up? : string;
    details: Readonly<TreatEventDto[]>;
    structures: Readonly<IBreedingStructure[]>;
    cross?: Readonly<IBreedingCross>;
    diseases?: FreezedArray<SympDiseaseEventDto>;
    status?: number;
}

export interface IBreedingCross {
    cow_id: number;
    cross_no : number;
    cross_type : number;
    seed_lot_id? : number;
    label_no_1? : string;
    label_no_2? : string;
    label_no_3? : string;
    rut_at? : string;
    father_name?: string;
    seed_name?: string;
    seed_sname?: string;
    seed_get_from?: string;
    seed_ancestor_1?: string;
    seed_ancestor_2?: string;
    seed_ancestor_3?: string;
    seed_ancestor_4?: string;
    lot_name?: string;
    egg_type?: number;
    egg_rank?: number;
    egg_stage?: number;
    stock_day?: string;
    stock_count?: number;
    unit_price?: number;
    mother_id?: number;
    mother_name?: string;
    ancestor_2?: string;
    ancestor_3?: string;
}
export interface IBreedingStructure {
    item_kind : number;
    side : number;
    item_count : number;
}
export interface IDelivery extends IBreedingBase {
    delivery_id: number;
    // ranch_id: number;
    // cow_id: number;
    // delivered_at: string;
    // delivered_by: string;
    is_aborted: 0 | 1;
    difficulty: number;
    comment: string;
    // created_by: string;
    cross_no?: number;
    children: IDeliveryCow[];
}
export interface IDeliveryCow {
    child_no:number;
    life_status: number;
    is_male: 0 | 1;
    weight_kg:number | undefined;
    cow?: IPopupCow;
}
export interface IRut extends IBreedingBase {
    rut_id: number;
    // breeding_day?: string;
    // cow_id: number;
    // cross_day?: string;
    details: Readonly<TreatEventDto[]>;
    // ranch_id: number;
    signs: number;
    watched_type: number;
    comment:string;
}

export interface ISovEvent extends IBreedingBase {
    sov_id: number;
    cow_id: number;
    comment: string;
    /**
     * 血統参照権限がないときはnull
     */
    father_exid?: number;
    /**
     * 血統参照権限がないときはnull
     */
    father_name?: string;
    ranks: Array<IEggEventRankAndStages>;
}
export interface IOpuEvent extends IBreedingBase {
    opu_id: number;
    cow_id: number;
    comment: string;
    ranks: Array<IOvumRankAndCount & { egg_count_l?: number }>;
    ivfs: {
        event_id: number;
        watched_at: string;
        watched_by: string;
        seed_lot_id: number;
        label_no: string;
        ranks: IOvumRankAndCount[];
    }[];
}
export interface IIvfEvent extends IBreedingBase {
    ivf_id: number;
    cow_id: number;
    comment: string;

    /**
     * 血統参照権限がないときはnull
     */
    seed_lot_id?: number;
    /**
     * 血統参照権限がないときはnull
     */
    label_no?: string;
    /**
     * 血統参照権限がないときはnull
     */
    seed_name?: string;
    /**
     * 血統参照権限がないときはnull
     */
    seed_sname?: string;
    /**
     * 血統参照権限がないときはnull
     */
    lot_name?: string;
    /**
     * 血統参照権限がないときはnull
     */
    stock_day?: string;
    /**
     * 血統参照権限がないときはnull
     */
    stock_count?: number;
    /**
     * 交配金額参照権限がないときはnull
     */
    unit_price?: number;

    opu_at: string;
    ranks: IOvumRankAndCount[];
    egg?: {
        watched_at: string;
        ranks: IEggRankAndCount[];
    }
}
export interface IIvfEggEvent extends IBreedingBase {
    ivf_egg_id: number;
    cow_id: number;
    comment: string;
    ivf_at: string;
    ivf_father_exid?: number;
    ivf_father_name?: string;
    ranks: Array<IEggEventRankAndStages & {
        details:IOvumRankAndCount[];
    }>;
    ivf_ranks: IOvumRankAndCount[];
}
export type IEggEventRankAndStages = IEggRankAndCount & {
    stages : {
        egg_stage: number;
        is_frozen: number;
        egg_count: number;
        seed_lot_id?: number;
        label_from?: string;
        label_to?: string;
        lot_name?: string;
        unit_price?: number;
    }[];
}


export interface IDeliveryHis {
    delivered_at: Date;
    delivery_id: number;
}
export type CrossHistory = Omit<BreedingCrossCurrentCrossDto, "watched_at"> & {
    watched_at: Date;
}

interface MyState {
    activeTab: '1' | '2' | '3';
    startDate: Date;
    cow?: CowDetailDto;
    feeds: IFeed[];
    symptoms: ISymptomGrpEvent[];
    sympton_series_popup: boolean;
    cur_first_id: number;   //TODO undefined
    breedings: IBreedingGrpEvent[];
    breeding_series_term?: Term;
    deliveryHistory: IDeliveryHis[];
    crossHistory: CrossHistory[];
    popupCrossNo?: number;
    popupIvf?: IIvfEvent;
    lastDayFeeds?: Readonly<Readonly<FeedLastDayDto>[]>;
    executing: boolean;
    stockingEgg?: {
        eventId: number;
        isSov: boolean;
        ranks: FreezedArray<{ rank: number, stages: FreezedArray<EggStageData> }>;
        father: ExtraCowDto;
    };
    ivfEggForDetail?: IIvfEggEvent;
    isOfficialRanch: boolean;
    certPregInfo?: Omit<CertPregPopupProps, "onSubmit"|"onClose">;
    certCrossInfo?: Omit<CertCrossPopupProps, "onAiSubmit"|"onEtSubmit"|"onClose">;
}


class CowEvent extends Base<BaseProps<{ id?:string }>, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            activeTab: '1',

            startDate: new Date(),

            feeds: [],
            symptoms: [],
            breedings:[],

            // 診療記録確認画面
            sympton_series_popup: false,
            cur_first_id: 0,
            deliveryHistory:[],
            crossHistory:[],
            executing: false,
            isOfficialRanch:true
        }

        this.onWriteFeed = this.onWriteFeed.bind(this);
        this.onWriteSymptom = this.onWriteSymptom.bind(this);
        this.onWritePrevention = this.onWritePrevention.bind(this);
        this.onWriteGrowth = this.onWriteGrowth.bind(this);
        this.onSymptomSeriesPopup = this.onSymptomSeriesPopup.bind(this);
        this.onWriteReexamine = this.onWriteReexamine.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.buildPopupCrossInfo = this.buildPopupCrossInfo.bind(this);
        this.onFeedLastDayModified = this.onFeedLastDayModified.bind(this);
    }

    handleChange(date: Date) {
        this.setState({
            startDate: date
        }, ()=> {
            this.api_getGetEventInfo();
        });
    }

    componentDidMount() {
        if (this.handleNotAllowAccess(undefined,[],[])) {
            return;
        }

        this.context.handleSetHeader({ title:"イベント" });
        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        const cowId = this.props.match.params.id == null ? undefined : Number(this.props.match.params.id);
        if (cowId == null || isNaN(cowId)) return;

        let startDate = QueryUtil.parseDateFromSearch(this.props.location.search, "param");
        const initialType = QueryUtil.parseNumFromSearch(this.props.location.search, "type");

        if (startDate == null) {
            startDate = moment().startOf("day").toDate();
        }

        const ranchId = this.props.rootStore.cur_ranch_id;
        const isOfficialRanch = new UserTeams(this.props.rootStore.user).findOfficialRanch(ranchId) != null;

        if (initialType === EVENT_TYPE_PARAM.FEED && isOfficialRanch) {
            this.toggleTab('1');
        } else if (initialType === EVENT_TYPE_PARAM.SYMPTOM) {
            this.toggleTab('2');
        } else if (initialType === EVENT_TYPE_PARAM.BREEDING) {
            this.toggleTab('3');
        } else if (!isOfficialRanch) {
            //未加入で指定なしなら初期表示をsymptomに
            this.toggleTab('2');
        }

        this.setState({
            startDate: startDate,
            isOfficialRanch
        }, async () => {
            if (!await this.api_getGetCowInfo(cowId)) return;
            if (!await this.api_getCrossHistory(cowId)) return;
            if (!await this.api_getDeliveryHistory(cowId)) return;

            this.api_getGetEventInfo();
        });
    }

    toggleTab(tab: "1" | "2" | "3") {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    onWriteFeed(feeding_id: number) {
        this.props.history.push(historyLocation.toFeedWriteEdit(feeding_id));
    }

    onWriteSymptom(symptom_id?: number) {
        if (!CommonUtil.assertNotNull(symptom_id, "symptom_id", "onWriteSymptom")) return;
        this.props.history.push(historyLocation.toSymtomWriteEdit(symptom_id));
    }

    onWritePrevention(prevention_id?: number) {
        if (!CommonUtil.assertNotNull(prevention_id, "prevention_id", "onWritePrevention")) return;
        this.props.history.push(historyLocation.toPreventionWriteEdit(prevention_id));
    }

    onWriteGrowth(event_id?: number) {
        if (!CommonUtil.assertNotNull(event_id, "event_id", "onWriteGrowth")) return;
        this.props.history.push(historyLocation.toGrowthWriteEdit(event_id));
    }

    onSymptomSeriesPopup(first_id?: number) {
        if (!CommonUtil.assertNotNull(first_id, "first_id", "onSymptomSeriesPopup")) return;
        this.setState({
            sympton_series_popup: true,
            cur_first_id: first_id,
        });
    }

    onWriteReexamine(first_id?: number) {
        if (!CommonUtil.assertNotNull(first_id, "first_id", "onWriteReexamine")) return;
        if (!CommonUtil.assertNotNull(this.state.cow, "cow", "onWriteReexamine")) return;

        this.props.history.push(historyLocation.toSymptomWrite({ param: this.state.cow.cow_id, first_id: first_id }));
    }

    onDateSlide(delta: number) {
        let stDateN: number;
        if (delta > 0) {
            stDateN = Date.parse(moment(this.state.startDate).add(delta, "days").format("YYYY-MM-DD"));
        } else {
            stDateN = Date.parse(moment(this.state.startDate).subtract(-delta, "days").format("YYYY-MM-DD"));
        }
        const stDate = new Date(stDateN);
        this.setState({
            startDate: stDate
        }, () => {
            this.api_getGetEventInfo();
        });
    }


    onWriteBreeding(breedBase:IBreedingGrpEvent) {
        if ("breeding_id" in breedBase) {
            this.props.history.push(historyLocation.toBreedingWriteEdit(breedBase.breeding_id));
            return;
        }
        if ("delivery_id" in breedBase) {
            this.props.history.push(historyLocation.toDeliveryWriteEdit(breedBase.delivery_id));
            return;
        }
        if ("rut_id" in breedBase) {
            if (!CommonUtil.assertNotNull(this.state.cow, "state.cow", "onRutWrite")) return;

            this.props.history.push(historyLocation.toRutWriteEdit(breedBase.rut_id, {
                cows: [{
                    cow_id: this.state.cow.cow_id,
                    local_no: this.state.cow.local_no,
                    trace_id: this.state.cow.trace_id,
                    name: this.state.cow.name,
                    use_no: this.state.cow.use_no,
                    breed_no: this.state.cow.breed_no,
                    watching: this.state.cow.watching,
                    watching_memo: this.state.cow.watching_memo
                }]
            }));
            return;
        }
        if ("sov_id" in breedBase) {
            this.props.history.push(historyLocation.toSovWriteEdit(breedBase.sov_id));
            return;
        }
        if ("opu_id" in breedBase) {
            if (breedBase.ivfs.length > 0) {
                this.context.showModalAsync(A.MESSAGE.OPU_CANNOT_EDIT, "ALERT", ["OK"]);
                return;
            }
            this.props.history.push(historyLocation.toOpuWriteEdit(breedBase.opu_id));
            return;
        }
        if ("ivf_id" in breedBase) {
            if (breedBase.egg != null) {
                this.context.showModalAsync(A.MESSAGE.IVF_CANNOT_EDIT, "ALERT", ["OK"]);
                return;
            }
            this.props.history.push(historyLocation.toIvfWriteEdit(breedBase.ivf_id));
            return;
        }
        if ("ivf_egg_id" in breedBase) {
            this.props.history.push(historyLocation.toIvfEggWriteEdit(breedBase.ivf_egg_id));
            return;
        }

        console.error("invalid arg", breedBase);
    }

    buildPopupIvfInfo (ivf: IIvfEvent) {
        const rtn: Array<{key:string,value:string}> = [];
        rtn.push({ key:"種雄牛名",      value:ivf.seed_name ?? "" })
        rtn.push({ key:"略号",          value:ivf.seed_sname ?? "" })
        rtn.push({ key:"製造Lot No.",   value:ivf.lot_name ?? "" });
        rtn.push({ key:"仕入日",        value:ivf.stock_day == null ? "" : moment(ivf.stock_day).format("YYYY/MM/DD") });
        rtn.push({ key:"仕入個数/単価", value:`${ivf.stock_count} × ${ivf.unit_price}円` });
        rtn.push({ key:"使用ラベルNo.", value:`${ivf.label_no ?? ""}` });
        return rtn;
    }
    buildPopupCrossInfo (crossNo:number) {
        const crossEvent = this.state.breedings.find(b => "breeding_id" in b && b.cross?.cross_no === crossNo);
        const cross = (crossEvent as IBreeding)?.cross;
        const rtn: Array<{key:string,value:string}> = [];
        if (cross == null) return rtn;
    
        if (cross.cross_type === 1) {
            rtn.push({key:"種雄牛名", value: cross.seed_lot_id == null ? cross.father_name??"" : cross.seed_name??""});
            if (cross.seed_lot_id != null) {
                rtn.push({key:"略号",value:cross.seed_sname??""});
            }
        }
        else if (cross.cross_type === 2) {
            rtn.push({key:"血統", value:GetPedigreeStr(cross)});
            if (cross.seed_lot_id != null) {
                rtn.push({key:"入手先", value:cross.seed_get_from ?? ""});
                rtn.push({key:"受精卵種類", value: getEggTypeName(cross) ?? ""});
                rtn.push({key:"ランク", value:getEggRankName(cross) ?? ""});
                rtn.push({key:"ステージ", value:getEggStageName(cross) ?? ""});
            }
        }
        else {
            console.error("invalid cross type for popup", cross);
            return rtn;
        }
    
        if (cross.seed_lot_id != null) {
            rtn.push({key:"製造Lot No.", value:cross.lot_name ?? ""});
            rtn.push({key:"仕入日", value:cross.stock_day == null ? "" : moment(cross.stock_day).format("YYYY/MM/DD")});
            rtn.push({key:"仕入個数/単価", value:`${cross.stock_count} × ${cross.unit_price}円`});
            rtn.push({key:"使用ラベルNo.", value:`${cross.label_no_1 ?? ""} ${cross.label_no_2 ?? ""} ${cross.label_no_3 ?? ""}`});
        }
    
        return rtn;
    }

    onMoveToCow(cow_id: number) {
        this.props.history.push(historyLocation.toCowInfo(cow_id));
    }

    async onNewCow(delivery: IDelivery, child_no: number) {
        if (!CommonUtil.assertNotNull(this.state.cow, "cow", "newCow")) return;

        const trgChild = delivery.children.find(c => c.child_no === child_no);
        if (!CommonUtil.assertNotNull(trgChild, "child", "onNewCow")) return;

        const ranchId = this.props.rootStore.cur_ranch_id;

        const getCow = async (cowId:number) => {
            this.context.handleSetIsLoading(true);
            const cowRes = await this.comm().send(() => getCowWithCache(ranchId, cowId));
            this.context.handleSetIsLoading(false);
            if (cowRes.result !== "OK") return null;
            if (cowRes.data == null || cowRes.data.cow_id == null) {
                console.error("failed to load cow");
                return null;
            }
            return ({
                cow_id: cowRes.data.cow_id,
                local_no: cowRes.data.local_no,
                name: cowRes.data.name,
                trace_id: cowRes.data.trace_id,
                ancestor_1: cowRes.data.ancestor_1,
                ancestor_2: cowRes.data.ancestor_2,
                ancestor_3: cowRes.data.ancestor_3,
                ancestor_1_exid: cowRes.data.ancestor_1_exid,
                ancestor_2_exid: cowRes.data.ancestor_2_exid,
                ancestor_3_exid: cowRes.data.ancestor_3_exid,
            });
        };

        const cross = delivery.cross_no == null ? null : this.state.crossHistory.find(cr => cr.cross_no === delivery.cross_no) ?? null;
        const param = await buildWriteChildrenParams(ranchId, getCow, this.state.cow.cow_id, delivery.delivery_id, cross, moment(delivery.watched_at), [ trgChild ], this.comm());

        this.props.history.push(historyLocation.toCowWrite(param));
    }

    showBreedingSeriesPopup(watched_at:string) {
        const targetAt = moment(watched_at);
        if (!targetAt.isValid()) {
            console.error("invalid watched_at", watched_at);
            return;
        }

        //この日時の次の分娩（この日時を含む）
        const nextDelilveryIdx = this.state.deliveryHistory.findIndex(d => targetAt.isSameOrBefore(d.delivered_at));
        const nextDelivery = nextDelilveryIdx < 0 ? null : this.state.deliveryHistory[nextDelilveryIdx];
        //この日時の直前の分娩
        const lastDeliveryIdx = nextDelilveryIdx < 0 ? this.state.deliveryHistory.length - 1 : nextDelilveryIdx - 1;
        const lastDelivery = lastDeliveryIdx < 0 ? null : this.state.deliveryHistory[lastDeliveryIdx];

        this.setState({
            breeding_series_term: new Term(lastDelivery?.delivered_at ?? Term.MIN_DATE, nextDelivery?.delivered_at ?? Term.MAX_DATE)
        });
    }

    showFeedLastDayPopup = async (ranchFeeds: FreezedArray<IRanchFeed>) => {
        const res = await this.api_getLastDayFeed(ranchFeeds);
        if (res == null) return;
        this.setState({ lastDayFeeds: res });
    }

    async onFeedLastDayModified(modified:FeedLastDayModified[]) {
        if (!CommonUtil.assertNotNull(this.state.cow, "cow", "onFeedLastDayModified")) return;
        const cowId = this.state.cow.cow_id;

        try {
            this.setState({ executing: true });

            const req: FeedModifyMultiReq = {
                ranch_id: this.props.rootStore.cur_ranch_id,
                records: modified.map(m => ({
                    cow_id: cowId,
                    feed_amount: m.feed_amount,
                    comment: m.comment,
                    fed_at: m.fed_at
                }))
            };

            const res = await this.comm().send((await FeedApi()).modifyFeedMultiUsingPOST(req));
            if (res.result !== "OK") return;
            await this.api_getGetEventInfo();
            await this.setStateAsync({ lastDayFeeds: undefined });

        } finally {
            this.setState({ executing: false});
        }
    }

    onStockIvfEgg(ivfEgg: IIvfEggEvent) {
        if (!CommonUtil.assertNotNull(ivfEgg.ivf_father_exid, "ivf_father_exid")) return;

        this.startEggSocking(false, ivfEgg.ivf_egg_id, ivfEgg.ranks, ivfEgg.ivf_father_exid, ivfEgg.ivf_father_name ?? "");
    }

    onStockSov(sov : ISovEvent) {
        if (!CommonUtil.assertNotNull(sov.father_exid, "father_exid")) return;

        this.startEggSocking(true, sov.sov_id, sov.ranks, sov.father_exid, sov.father_name ?? "");
    }

    onIvfWrite(opu: IOpuEvent) {
        if (!CommonUtil.assertNotNull(this.state.cow)) return;

        const cow = {
            ...this.state.cow,
            use_no: this.state.cow.use_no,
            breed_no: this.state.cow.breed_no
        };

        this.props.history.push(historyLocation.toIvfWrite({ cows: [ cow ], opu_id:opu.opu_id }));
    }
    onIvfEggWrite(ivf: IIvfEvent) {
        if (!CommonUtil.assertNotNull(this.state.cow)) return;

        const cow = {
            ...this.state.cow,
            use_no: this.state.cow.use_no,
            breed_no: this.state.cow.breed_no
        };

        this.props.history.push(historyLocation.toIvfEggWrite({ cows: [ cow ], ivf_id:ivf.ivf_id }));
    }

    private async startEggSocking(isSov: boolean, eventId: number, eventRanks: IEggEventRankAndStages[], father_exid: number, father_name: string) {

        const ranks = eventRanks.map(rank => ({
            rank: rank.egg_rank,
            stages: rank.stages.map(s => ({
                stage: s.egg_stage,
                isFrozen: s.is_frozen === 1,
                count: s.egg_count,
                hasStocked: s.seed_lot_id != null
            })).filter(s => !s.hasStocked)
        })).filter(r => r.stages.length > 0);

        this.setState({
            stockingEgg: {
                eventId,
                father: { ex_cow_id: father_exid, name: father_name },
                isSov,
                ranks
            }
        })
    }
    
    onEggStocked() {
        this.setState({
            stockingEgg: undefined
        });

        this.api_getGetEventInfo();
    }

    api_getGetCowInfo = async (cowId: number) => {

        this.context.handleSetIsLoading(true);
        const response = await this.comm().send(() => getCowWithCache(this.props.rootStore.cur_ranch_id, cowId));
        this.context.handleSetIsLoading(false);
        if (response.result !== "OK" || response.data == null) return false;

        await this.setStateAsync({ cow: response.data });
        return true;
    }

    api_getGetEventInfo = async () => {
        if (!CommonUtil.assertNotNull(this.state.cow, "cow", "getEvent")) return;

        const params: EventInfoReq = {
            cow_ids: [ this.state.cow.cow_id ],
            day: moment(this.state.startDate).format("YYYY-MM-DD"),
            ranch_id: this.props.rootStore.cur_ranch_id,
            user_id: this.props.rootStore.user.id
        };

        this.context.handleSetIsLoading(true);
        const response = await this.comm().send<any>(() => this.context.postAsync('/event/info', params));
        this.context.handleSetIsLoading(false);
        if (response.result !== "OK" || response.data == null) return false;

        const feeds = response.data.feed;
        const symptoms = response.data.symptom;
        const breedings = response.data.breedings;

        await this.setStateAsync({ feeds: feeds, symptoms: symptoms, breedings: breedings });
        return true;
    }

    api_getDeliveryHistory = async (cowId: number) => {
        this.context.handleSetIsLoading(true);
        const res = await this.comm().send((await DeliveryApi()).getHistoryListUsingPOST1({
            cow_id: cowId,
            ranch_id: this.props.rootStore.cur_ranch_id
        }));
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") return false;

        await this.setStateAsync({
            deliveryHistory: (res.data?.list ?? []).map(h => ({
                delivered_at: moment(h.delivered_at).toDate(),
                delivery_id: h.delivery_id!
            }))
        });
        return true;
    }
    api_getCrossHistory = async (cowId: number) => {
        this.context.handleSetIsLoading(true);
        const res = await this.comm().send((await BreedingApi()).getBreedingCrossHistoryUsingPOST({
            cow_id: cowId,
            ranch_id: this.props.rootStore.cur_ranch_id
        }));
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") return false;

        await this.setStateAsync({
            crossHistory: (res.data ?? []).map(c => ({
                ...c,
                watched_at: moment(c.watched_at).toDate(),
            }))
        });
        return true;
    }
    api_getLastDayFeed = async (ranchFeeds: FreezedArray<IRanchFeed>): Promise<FeedLastDayDto[]|undefined> => {
        if (!CommonUtil.assertNotNull(this.state.cow, "cow", "getLastDayFeed")) return Promise.resolve(undefined);

        this.context.handleSetIsLoading(true);
        const res = await this.comm().send((await FeedApi()).getLastDayUsingPOST({
            ranch_id: this.props.rootStore.cur_ranch_id,
            cow_id: this.state.cow.cow_id,
            day: moment(this.state.startDate).format("YYYY-MM-DD")
        }));
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") return undefined;

        const lastDayfeeds = (res.data ?? []);
        if (lastDayfeeds.length === 0) {
            this.context.showToast(A.MESSAGE.NO_LASTDAY_FEED_DATA);
            return undefined;
        }

        //マスタに存在しないえさの記録を捨てる
        const resList = lastDayfeeds.map(f => ({
            ...f,
            amounts: f.amounts.filter(a => ranchFeeds.some(rf => rf.feed_no === a.feed_no))
        })).filter(f => f.amounts.length > 0);

        if (resList.length === 0) {
            this.context.showToast(A.MESSAGE.NO_LASTDAY_FEED_DATA);
            return undefined;
        }
        return resList;
    }

    exportPregCert = async (breeding: IBreeding) => {
        if (breeding.pregnant_score !== 1) {
            console.error("invalid preg score for export preg", breeding);
            return;
        }
        const cow = this.state.cow;
        if (!CommonUtil.assertNotNull(cow, "cow")) return;

        const watchedAt = moment(breeding.watched_at);
        const latestCross = [...this.state.crossHistory].reverse()
                            .find(c => watchedAt.isAfter(c.watched_at));

        const user = this.props.rootStore.user;
        const cowFeatureList = loadUserStorage<string[]>("cow_feature", user.id) ?? [];
        const pregCheckDescriptionList = loadUserStorage<string[]>("preg_check_description", user.id) ?? [];
        const clinicNameList = loadUserStorage<string[]>("cert_my_clinic_name", user.id) ?? [];
        const addressList = loadUserStorage<string[]>("cert_my_clinic_address", user.id) ?? [];

        this.setState({
            certPregInfo: {
                user,
                ranchId: this.props.rootStore.cur_ranch_id,
                cowId: cow.cow_id,
                traceId: cow.trace_id,
                today: new Date(),
                watchedDay: watchedAt.toDate(),
                crossDay: latestCross?.watched_at,
                crossType: latestCross?.cross_type,
                cowFeatureList,
                pregCheckDescriptionList,
                clinicNameList,
                addressList
            }
        });

    }
    submitPregCert = async (data: BreedingCertPregnantReq) => {

        this.context.handleSetIsLoading(true);
        const res = await this.comm().send((await BreedingApi()).postCertPrgnantUsingPOST(data));
        this.context.handleSetIsLoading(false);

        if (res.result !== "OK" || res.data == null) return;

        saveBase64File(res.data.body, res.data.file_name, res.data.content_type);

        //出力モーダルは開いたままにしておく
        this.context.showToast(A.MESSAGE.FILE_SAVED);

        const userId = this.props.rootStore.user.id;
        updateListInUserStorage("cow_feature", userId, data.cow_feature, LMT.COW.FEATURE_HIS_COUNT);
        updateListInUserStorage("preg_check_description", userId, data.checked_desc, LMT.BREEDING.PREG_CHECK_DESCRIPTION_HIS_COUNT);
        updateListInUserStorage("cert_my_clinic_address", userId, data.clinic_address, LMT.CERT.CLINIC_ADDRESS_HIS_COUNT);
        updateListInUserStorage("cert_my_clinic_name", userId, data.clinic_name, LMT.CERT.CLINIC_NAME_HIS_COUNT);
    }

    exportCrossCert = async (breeding: IBreeding) => {
        if (!CommonUtil.assertNotNull(breeding.cross)) return;

        const cow = this.state.cow;
        if (!CommonUtil.assertNotNull(cow, "cow")) return;

        const user = this.props.rootStore.user;
        const cowFeatureList = loadUserStorage<string[]>("cow_feature", user.id) ?? [];
        const clinicNameList = loadUserStorage<string[]>("cert_my_clinic_name", user.id) ?? [];
        const addressList = loadUserStorage<string[]>("cert_my_clinic_address", user.id) ?? [];

        this.setState({
            certCrossInfo: {
                user,
                ranchId: this.props.rootStore.cur_ranch_id,
                cowId: cow.cow_id,
                traceId: cow.trace_id,
                today: new Date(),
                eventData: breeding,
                cowFeatureList,
                clinicNameList,
                addressList
            }
        })
    }

    submitAiCert = async (data: BreedingCertAiReq) => {
        return this.submitCrossCert((await BreedingApi()).postCertAiUsingPOST(data), data.cow_feature, data.clinic_name, data.clinic_address);
    }
    submitEtCert = async (data: BreedingCertEtReq) => {
        return this.submitCrossCert((await BreedingApi()).postCertEtUsingPOST(data), data.cow_feature, data.clinic_name, data.clinic_address);
    }
    submitCrossCert = async (post: PostSomeAsync<FileDownloadDto>, cowFeature: string, clinicName:string, address:string) => {
        this.context.handleSetIsLoading(true);
        const res = await this.comm().send(post);
        this.context.handleSetIsLoading(false);

        if (res.result !== "OK" || res.data == null) return;

        saveBase64File(res.data.body, res.data.file_name, res.data.content_type);

        //出力モーダルは開いたままにしておく
        this.context.showToast(A.MESSAGE.FILE_SAVED);

        const userId = this.props.rootStore.user.id;
        updateListInUserStorage("cow_feature", userId, cowFeature, LMT.COW.FEATURE_HIS_COUNT);
        updateListInUserStorage("cert_my_clinic_address", userId, address, LMT.CERT.CLINIC_ADDRESS_HIS_COUNT);
        updateListInUserStorage("cert_my_clinic_name", userId, clinicName, LMT.CERT.CLINIC_NAME_HIS_COUNT);
    }

    render() {
        const props = this.props;
        const state = this.state;

        const renderBreedingSeriesLink = (watched_at:string) => (
            <IconLink iconType="popup" text="経過を表示" className="m-l-5" onClick={() => this.showBreedingSeriesPopup(watched_at)} />
        )

        const user = this.props.rootStore.user;
        const ranchId = this.props.rootStore.cur_ranch_id;
        const canEditCow = hasRanchAuth("COW_EDIT", ranchId, user);
        const canTreat = hasRanchAuth("TREAT_EDIT", ranchId, user);
        const canBreeding = hasRanchAuth("BREEDING_EDIT", ranchId, user);
        const canRefCrossName = hasRanchAuth("CROSS_REF_NAME", ranchId, user);
        const canRefCrossPrice = hasRanchAuth("CROSS_REF_PRICE", ranchId, user);
        const canFeeding = hasRanchAuth("FOODING_EDIT", ranchId, user);
        const canRefFeedAmount = hasRanchAuth("FOODING_REF_AMOUNT", ranchId, user);
        const canRefTreatAmount = hasRanchAuth("TREAT_REF_AMOUNT", ranchId, user);
        const canEditMaster = hasRanchAuth("MASTER_EDIT", ranchId, user);
        const canStockEgg = canEditMaster && this.state.isOfficialRanch;
        const isActive = state.cow?.is_active === 1;

        const canEdit = (auth: boolean, createdBy: string | undefined, eventKind: EventKindKey) => {
            if (!auth) return false;
            if (!isActive) return false;

            if (EVENT_KIND[eventKind].selfEditOnly) {
                if (!CommonUtil.assertNotNull(createdBy, "created_by", "editable check for selfEditOnly event")) return false;
                if (createdBy !== user.id) return false;
            }

            return true;
        }
        const license = user.setting?.license == null ? undefined : USER_LICENSE[user.setting.license.type];
        const canExportPregCert = isBrowser && canRefCrossName && license?.canCheckPreg === true;
        const canExportAiCert = isBrowser && canRefCrossName && license?.canAi === true;
        const canExportEtCert = isBrowser && canRefCrossName && license?.canEt === true;

        const renderAiEtCertLink = (breeding: IBreeding) => {
            const certType = breeding.cross?.cross_type === A.CROSS_TYPE.get("AI")!.no ? "ai"
                            : breeding.cross?.cross_type === A.CROSS_TYPE.get("ET")!.no ? "et"
                            : undefined;
            if (certType == null) {
                // flex:space-between のためスペーサーを描画しておく
                return <span />
            }
            if ((certType === "ai" && !canExportAiCert) || (certType === "et" && !canExportEtCert)) {
                return <span />
            }

            return (
                <IconLink iconType="download" text="証明書の発行"
                    onClick={() => this.exportCrossCert(breeding) } />
            )
        }

        return (
            <div className="page-root">
                <div className="product" style={{marginBottom:"10px"}}>
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info" style={{ height: "100%", paddingBottom:"10px" }}>
                            { state.cow != null && (
                                <div style={{ display:"flex", flexFlow:"row wrap", alignItems:"baseline", justifyContent:"center" }}>
                                    <SingleCowHeaderTitle cow={state.cow} />
                                    <SingleCowHeaderDetail cow={state.cow} />
                                </div>
                            )}
                            <div style={{ marginTop: "10px", display:"flex", justifyContent:"center" }}>
                                <V3DatePicker
                                    name="evtdate"
                                    popperPlacement="bottom"
                                    value={this.state.startDate}
                                    onChange={e => this.handleChange(e.target.value)}
                                    arrowLStyle={{ margin: "0 8px", padding: "10px 8px 10px 18px" }}
                                    arrowRStyle={{ margin: "0 8px", padding: "10px 18px 10px 8px" }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="product" style={{ flex:1, background:"#EEEEEE", minHeight:0 }}>
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix p-0">
                            
                            <Nav tabs style={{display:"flex"}} className="white-tab">
                                { this.state.isOfficialRanch && (
                                    <NavItem style={{flex:1}}>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '1' })}
                                            style={{ textAlign: "center" }}
                                            onClick={() => {
                                                this.toggleTab('1');
                                            }}
                                        >
                                            <span className="d-sm-none">えさ記録</span>
                                            <span className="d-sm-block d-none">えさ記録</span>
                                        </NavLink>
                                    </NavItem>
                                )}
                                <NavItem style={{flex:1}}>
                                    <NavLink
                                        className={classnames({ active: this.state.activeTab === '2' })}
                                        style={{ textAlign: "center" }}
                                        onClick={() => {
                                            this.toggleTab('2');
                                        }}
                                    >
                                        <span className="d-sm-none">体調・治療</span>
                                        <span className="d-sm-block d-none">体調・治療</span>
                                    </NavLink>
                                </NavItem>
                                { (A.IS_FOR_BREEDING_COW(this.state.cow?.use_no ?? 0) || this.state.breedings.length > 0) &&
                                    <NavItem style={{flex:1}}>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '3' })}
                                            style={{ textAlign: "center" }}
                                            onClick={() => {
                                                this.toggleTab('3');
                                            }}
                                        >
                                            <span className="d-sm-none">繁殖</span>
                                            <span className="d-sm-block d-none">繁殖</span>
                                        </NavLink>
                                    </NavItem>
                                }
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}
                                className="white-tab-content">
                                <TabPane tabId="1" style={{ padding: "10px", height: "100%" }}>
                                    <FeedTabContent
                                        feeds={this.state.feeds}
                                        ranchId={this.props.rootStore.cur_ranch_id}
                                        canFeeding={canFeeding}
                                        canRefFeedAmount={canRefFeedAmount}
                                        isActiveCow={isActive}
                                        onEdit={this.onWriteFeed}
                                        onCopyLastDay={this.showFeedLastDayPopup}
                                        //※本来はcanEdit内で判断を完結させるためにcreated_byを渡すべきだが、
                                        //  現状受信データに含まれていないので、
                                        //  給餌記録は他ユーザでも編集可能であることを前提としてしまう
                                        canEdit={canEdit(canFeeding, undefined, "FEEDING")}
                                        feedTypes={this.props.rootStore.options.feed_type}
                                    />
                                </TabPane>
                                <TabPane tabId="2" style={{ padding: "10px", height: "100%" }}>
                                    {
                                        this.state.symptoms.map((symptom, i) => (
                                            <div key={i} data-testid={("symptom_id" in symptom) ? "symptom" : ("prevention_id" in symptom) ? "prevention" : "growth"}>
                                                { ("symptom_id" in symptom) && (
                                                    <React.Fragment>
                                                        { renderHeader(symptom.watched_at, symptom.watched_by, canEdit(canTreat, symptom.created_by, "SYMPTOM"), () => { this.onWriteSymptom(symptom.symptom_id)} )}
                                                        {
                                                            (symptom.status) && (
                                                                <div className="row-no-margin m-t-5">
                                                                    <div >
                                                                        <span>［状態］&nbsp;</span>
                                                                    </div>
                                                                    <div style={{ flex: "1" }} data-testid="symptom-status">
                                                                        {getSymptomStatus(symptom.status)?.name}
                                                                    </div>                                                
                                                                </div>                                                
                                                            )
                                                        }                                                    
                                                        <div className="row-no-margin m-t-5">
                                                            <div >
                                                                <span>［体調］&nbsp;</span>
                                                            </div>
                                                            <div style={{ flex: "1" }}>
                                                                {renderSymptomStr(symptom, this.props.rootStore.options.feces_state, this.props.rootStore.options.feces_color)}
                                                            </div>
                                                        </div>
                                                        { renderDisease(symptom) }
                                                        {
                                                            symptom.treat.map((treat, k) => (
                                                                <div key={k} data-testid="symptom-treat">{renderTreat(treat, canRefTreatAmount)}</div>
                                                            ))
                                                        }
                                                        { renderComment(symptom.comment, "m-t-5") }
                                                        {
                                                            (symptom.first_id != null || symptom.treat.length > 0) && (
                                                                <div className="row m-t-5 m-r-5" style={{ justifyContent:"flex-end" }}>
                                                                    <IconLink iconType="popup" text="初診から表示" className="m-l-5"
                                                                        onClick={() => this.onSymptomSeriesPopup(symptom.first_id != null ? symptom.first_id : symptom.symptom_id)} />
                                                                    { canTreat && (
                                                                        <IconLink iconType="reply" text="再診/継続" className="m-l-5"
                                                                            onClick={() => this.onWriteReexamine(symptom.first_id != null ? symptom.first_id : symptom.symptom_id)} />
                                                                    )}
                                                                </div>
                                                            )
                                                        }
                                                    </React.Fragment>
                                                )}
                                                { ("prevention_id" in symptom) && (
                                                <React.Fragment>
                                                    { renderHeader(symptom.watched_at, symptom.watched_by, canEdit(canTreat, symptom.created_by, "PREVENTION"), () => { this.onWritePrevention(symptom.prevention_id) }) }
                                                    <div className="row-no-margin m-t-5">
                                                        <div>
                                                            <span>［予防］&nbsp;</span>
                                                        </div>
                                                    </div>
                                                    {
                                                        symptom.detail.map((p_detail, k) => (
                                                            <div key={k} data-testid="prevention-detail">{renderTreat(p_detail, canRefTreatAmount)}</div>
                                                        ))
                                                    }
                                                    { renderComment(symptom.comment, "m-t-5") }
                                                </React.Fragment>
                                                )}
                                                { ("event_id" in symptom) &&
                                                    <React.Fragment>
                                                        { renderHeader(symptom.watched_at, symptom.watched_by, canEdit(canTreat, symptom.created_by, "GROWTH"), () => { this.onWriteGrowth(symptom.event_id) }) }
                                                        <div className="row-no-margin m-t-5">
                                                            <div >
                                                                <span>［測定］&nbsp;</span>
                                                            </div>
                                                            <div style={{ flex: "1" }}>
                                                                <span>{ renderGrowthEvent(symptom) }</span>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </div>
                                        ))
                                    }
                                </TabPane>
                                <TabPane tabId="3" style={{ padding: "10px", height: "100%" }}>
                                    {
                                        this.state.breedings
                                            .map((bBase): IBreedingGrpEvent & { eventKind: { testId: string, key: EventKindKey } | undefined } => ({
                                                ...bBase,
                                                eventKind
                                                    : "breeding_id" in bBase ? { testId:"breeding", key: "BREEDING" }
                                                    : "rut_id" in bBase ? { testId: "rut", key: "RUT" }
                                                    : "delivery_id" in bBase ? { testId: "delivery", key: "DELIVERY" }
                                                    : "sov_id" in bBase ? { testId: "sov", key: "SOV" }
                                                    : "opu_id" in bBase ? { testId: "opu", key: "OPU" }
                                                    : "ivf_id" in bBase ? { testId: "ivf", key: "IVF" }
                                                    : "ivf_egg_id" in bBase ? { testId: "ivf-egg", key: "IVF_EGG" }
                                                    : undefined
                                            }))
                                            .filter((b): b is IBreedingGrpEvent & { eventKind: { testId: string, key: EventKindKey } } => b.eventKind != null)
                                            .map((bBase, i)=> (
                                            <div key={i} data-testid={bBase.eventKind.testId}>
                                                { renderHeader(bBase.watched_at, bBase.watched_by, canEdit(canBreeding, bBase.created_by, bBase.eventKind.key), () => { this.onWriteBreeding(bBase) })}

                                                { ("breeding_id" in bBase) && (
                                                    <>
                                                        <div className="row-no-margin m-t-5">
                                                            <div>
                                                                <span>［検診］&nbsp;</span>
                                                            </div>
                                                            <div style={{ flex: "1" }}>
                                                                {renderBreedingInfo(bBase, canExportPregCert ? this.exportPregCert : undefined)}
                                                            </div>
                                                        </div>
                                                        { renderDisease(bBase) }
                                                        { bBase.status != null && (
                                                            <div className="row-no-margin m-t-5">
                                                                <div>
                                                                    <span>［転帰］&nbsp;</span>
                                                                </div>
                                                                <div style={{ flex: "1" }} data-testid="symptom-status">
                                                                    {getSymptomStatus(bBase.status)?.name}
                                                                </div>
                                                            </div>
                                                        )}
                                                        { bBase.cross != null && (
                                                            <div className="row-no-margin m-t-5">
                                                                <div>
                                                                    <span>［交配］&nbsp;</span>
                                                                </div>
                                                                <div style={{ flex: "1" }}>
                                                                    {renderCrossInfo(bBase.cross,
                                                                            bBase.watched_at,
                                                                            state.deliveryHistory,
                                                                            state.crossHistory,
                                                                            canRefCrossName,
                                                                            canRefCrossPrice ? ((no) => this.setState({popupCrossNo:no})) : undefined)
                                                                    }
                                                                </div>
                                                            </div>
                                                        )}
                                                        { bBase.details.map((dtl,i) => (
                                                            <div key={i} data-testid="breeding-treat">{renderTreat(dtl, canRefTreatAmount)}</div>
                                                        ))}
                                                        { renderComment(bBase.comment, "m-t-5") }
                                                        <div className="m-t-5" style={{ display:"flex", justifyContent:"space-between"}}>
                                                            { renderAiEtCertLink(bBase) }
                                                            { bBase.cross != null && renderBreedingSeriesLink(bBase.watched_at)}
                                                        </div>
                                                    </>
                                                )}
                                                { ("rut_id" in bBase) && (
                                                    <>
                                                        <div className="row-no-margin m-t-5">
                                                            <div>
                                                                <span>［発情］&nbsp;</span>
                                                            </div>
                                                            <div style={{ flex: "1" }}>
                                                                {renderRutInfo(bBase)}
                                                            </div>
                                                        </div>
                                                        { bBase.details.map((dtl,i) => (
                                                            <div key={i} data-testid="rut-treat">{renderTreat(dtl, canRefTreatAmount)}</div>
                                                        ))}
                                                        { renderComment(bBase.comment, "m-t-5") }
                                                    </>
                                                )}
                                                { ("delivery_id" in bBase) && (
                                                    <>
                                                        <div className="row-no-margin m-t-5">
                                                            <div>
                                                                <span>［分娩］&nbsp;</span>
                                                            </div>
                                                            <div style={{ flex: "1" }}>
                                                                {renderDeliveryInfo(bBase, canEditCow ? ((d,cNo) => this.onNewCow(d, cNo)) : undefined, (cowId) => this.onMoveToCow(cowId))}
                                                            </div>
                                                        </div>
                                                        { renderComment(bBase.comment, "m-t-5") }
                                                        <div className="m-t-5" style={{ display:"flex", justifyContent:"flex-end" }}>
                                                            { renderBreedingSeriesLink(bBase.watched_at) }
                                                        </div>
                                                    </>
                                                )}
                                                { ("sov_id" in bBase) && (
                                                    <>
                                                        <div className="row-no-margin m-t-5">
                                                            <div>
                                                                <span>［体内受精卵］&nbsp;</span>
                                                            </div>
                                                            <div style={{ flex: "1" }}>
                                                                {renderSovEvent(bBase, canStockEgg ? (() => this.onStockSov(bBase)) : undefined)}
                                                            </div>
                                                        </div>
                                                        { renderComment(bBase.comment, "m-t-5") }
                                                    </>
                                                )}
                                                { ("opu_id" in bBase) && (
                                                    <>
                                                        <div className="row-no-margin m-t-5">
                                                            <div>
                                                                <span>［OPU］&nbsp;</span>
                                                            </div>
                                                            <div style={{ flex: "1" }}>
                                                                {renderOpuEvent(bBase, canBreeding ? () => this.onIvfWrite(bBase) : undefined)}
                                                            </div>
                                                        </div>
                                                        { renderComment(bBase.comment, "m-t-5") }
                                                    </>
                                                )}
                                                { ("ivf_id" in bBase) && (
                                                    <>
                                                        <div className="row-no-margin m-t-5">
                                                            <div>
                                                                <span>［媒精］&nbsp;</span>
                                                            </div>
                                                            <div style={{ flex: "1" }}>
                                                                { renderIvfEvent(bBase,
                                                                        canBreeding ? () => this.onIvfEggWrite(bBase) : undefined,
                                                                        canRefCrossPrice ? () => this.setState({ popupIvf:bBase }) : undefined) }
                                                            </div>
                                                        </div>
                                                        { renderComment(bBase.comment, "m-t-5") }
                                                    </>
                                                )}
                                                { ("ivf_egg_id" in bBase) && (
                                                    <>
                                                        <div className="row-no-margin m-t-5">
                                                            <div>
                                                                <span>［培養結果］&nbsp;</span>
                                                            </div>
                                                            <div style={{ flex: "1" }}>
                                                                {renderIvfEggEvent(bBase, canStockEgg ? (() => this.onStockIvfEgg(bBase)) : undefined, () => this.setState({ ivfEggForDetail: bBase }))}
                                                            </div>
                                                        </div>
                                                        { renderComment(bBase.comment, "m-t-5") }
                                                    </>
                                                )}
                                            </div>
                                        ))
                                    }
                                </TabPane>
                            </TabContent>
                        </div>
                    </div>
                    {
                        state.sympton_series_popup && CommonUtil.assertNotNull(state.cow) && (
                            <SymptomSeriesPopup
                                data={{
                                    user: props.rootStore.user,
                                    ranch_id: props.rootStore.cur_ranch_id,
                                    first_id: state.cur_first_id,
                                    feces_colors: props.rootStore.options.feces_color,
                                    feces_states: props.rootStore.options.feces_state,
                                }}
                                onAddSymptom={canTreat ? () => { this.props.history.push(historyLocation.toSymptomWrite({ param: state.cow!.cow_id, first_id: state.cur_first_id })) } : undefined }
                                onClose={() => this.setState({ sympton_series_popup: false })}
                                comm={this.comm()}
                            />
                        )
                    }
                    {
                        state.popupCrossNo != null && (
                            <TextModal title="" onClose={() => this.setState({popupCrossNo:undefined})}
                                    text={this.buildPopupCrossInfo(state.popupCrossNo)} />
                        )
                    }
                    {
                        state.popupIvf != null && (
                            <TextModal title="" onClose={() => this.setState({ popupIvf: undefined })}
                                    text={this.buildPopupIvfInfo(state.popupIvf)} />
                        )
                    }
                    {
                        state.breeding_series_term != null && CommonUtil.assertNotNull(state.cow) && (
                            <BreedingSeriesPopup cow_id={state.cow.cow_id} term={state.breeding_series_term}
                                                onClose={()=>this.setState({breeding_series_term:undefined})}
                                                handleSetIsLoading={this.context.handleSetIsLoading}
                                                canRefTreatAmount={canRefTreatAmount}
                                                canRefCrossName={canRefCrossName}
                                                deliveryHistory={state.deliveryHistory} crossHistory={state.crossHistory}
                                                comm={this.comm()} />
                        )
                    }
                    {
                        state.lastDayFeeds != null && (
                            <FeedLastDayPopup onClose={()=>this.setState({lastDayFeeds: undefined})}
                                ranchId={this.props.rootStore.cur_ranch_id}
                                feedTypeList={props.rootStore.options.feed_type}
                                onSubmit={this.onFeedLastDayModified}
                                feeds={state.lastDayFeeds}
                                dayToSave={moment(state.startDate).format("YYYY-MM-DD")}
                                isSubmitExecuting={state.executing}
                            />
                        )
                    }
                    {
                        state.stockingEgg != null && state.cow != null && (
                            <EggStockModal
                                ranchId={this.props.rootStore.cur_ranch_id}
                                comm={this.comm()}
                                cow={state.cow}
                                { ...state.stockingEgg }
                                onClose={() => this.setState({ stockingEgg: undefined })}
                                setIsLoading={this.context.handleSetIsLoading}
                                showQuestion={this.context.showQuestionAsync}
                                onRegistered={() => this.onEggStocked()}
                            />
                        )
                    }
                    {
                        state.ivfEggForDetail != null && (
                            <IvgEggRankDetailTablePopup
                                data={state.ivfEggForDetail}
                                onClose={() => this.setState({ ivfEggForDetail: undefined })}
                            />
                        )
                    }
                    {
                        state.certPregInfo != null && (
                            <CertPregPopup {...state.certPregInfo}
                                onClose={() => this.setState({ certPregInfo: undefined })}
                                onSubmit={this.submitPregCert}
                            />
                        )
                    }
                    {
                        state.certCrossInfo != null && (
                            <CertCrossPopup {...state.certCrossInfo}
                                onClose={() => this.setState({ certCrossInfo: undefined })}
                                onAiSubmit={this.submitAiCert}
                                onEtSubmit={this.submitEtCert}
                        />
                        )
                    }
                </div>
            </div>
        )
    }
}

const FeedTabContent = React.memo((props: {
    feeds: FreezedArray<IFeed>,
    ranchId: number,
    canEdit: boolean,
    canFeeding: boolean,
    canRefFeedAmount: boolean,
    isActiveCow: boolean,
    feedTypes: FreezedArray<IFeedType>,
    onEdit: (id: number) => void,
    onCopyLastDay: (ranchFeeds: FreezedArray<IRanchFeed>) => void
}) => {
    const mFeeds = useRanchFeeds(props.ranchId);

    const buildFeedInfo = (ranchFeeds: FreezedArray<IRanchFeed>, feedAmount: IFeedAmount) => {

        return buildFeedingDetailStr(feedAmount, ranchFeeds, props.feedTypes, props.canRefFeedAmount);
    }

    if (mFeeds.isLoading) return <FetchWaiter />
    if (mFeeds.isError || mFeeds.data == null) return <FetchError />

    if (props.feeds.length === 0 && props.canFeeding && props.isActiveCow) {
        return (
            <div>
                <div className="row-no-margin m-t-5">
                    えさの記録がありません（<a className="link" onClick={() => props.onCopyLastDay(mFeeds.data) }>前回の記録を流用</a>）
                </div>
            </div>
        )
    }

    return (<>
        { props.feeds.map((feed, i) => (
            <div key={i} data-testid="feeding">
                { renderHeader(feed.fed_at, feed.fed_by, props.canEdit, () => props.onEdit(feed.feeding_id)) }
                <div className="row-no-margin m-t-5">
                    <div >
                        <span>［えさ］&nbsp;</span>
                    </div>
                    <div style={{ flex: "1" }}>
                        <ul style={{ marginBottom: "5px" }}>
                            {
                                feed.feed_amount.length === 0 && (
                                    <span>内容なし</span>
                                )
                            }
                            {
                                feed.feed_amount.map((feedVal, k) => (
                                    <li key={k}>{buildFeedInfo(mFeeds.data, feedVal)}</li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
                { renderComment(feed.comment) }
            </div>
        ))}
    </>)




});

export default withRouter(withContext(CowEvent));