import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { OptionalNumInput, RequiredNumInput } from '../../components/parts/num-input';
import { LMT, A } from '../../config/constant';
import { PageSettings } from '../../config/page-settings.js';
import { IEditingMedicineRoute } from './medicine-route-master';
import styles from './setting.module.css';
import { Checkbox } from '../../components/form/form-checkbox';
import { FreezedArray } from '../../config/util';
import { IMedicineRoute } from '../../stores';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { InfoPopup } from '../../components/parts/info-popup';

interface MyProps {
    data: IEditingMedicineRoute;
    onSubmit: ()=>void;
    onClose: ()=>void;
    onDelete: ()=>void;
    onNameEdited:(name:string)=>void;
    onBaseFeeEdited:(base_fee:number)=>void;
    onBasePointAEdited:(base_point_a:number|undefined)=>void;
    onBasePointBEdited:(base_point_b:number|undefined)=>void;
    onExtraChecked:(extra:boolean)=>void;
    onBaseLimitEdited:(base_limit:number|undefined)=>void;
    onExtraStepEdited:(extra_step:number|undefined)=>void;
    onExtraFeeEdited:(extra_fee:number|undefined)=>void;
    onExtraPointAEdited:(extra_point_a:number|undefined)=>void;
    onExtraPointBEdited:(extra_point_b:number|undefined)=>void;
    onCodeEdited:(code:string)=>void;
    onIsFreeMedicineChanged:(isFreeMedicine:boolean)=>void;
    onIsInstructionChanged:(isInstruction:boolean)=>void;
    onOnceFeeChanged:(isOnceFee:boolean, prior_fee_route_id:number|undefined)=>void;
    isSubmitExecuting: boolean;
    allRouteList: FreezedArray<IMedicineRoute>;
}
interface MyState {
}

export class MedicineRouteEditPopup extends React.Component<MyProps,MyState> {

    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {

        const otherRoutes = this.props.data.route_id == null ? this.props.allRouteList
                        : this.props.allRouteList.filter(r => r.route_id !== this.props.data.route_id);

        return (
            <div>
                <Modal isOpen={true} centered={true}>
                    <ModalHeader toggle={this.props.onClose}></ModalHeader>
                    <ModalBody style={{ overflowY: "auto", maxHeight: "calc(100vh - 150px)" }}>
                        <div>
                            <div className="form-group" style={{display:"flex"}}>
                                <label className="col-form-label" style={{width:"60px"}}>経路名</label>
                                <div>
                                    <input className="form-control" type="text" value={this.props.data.name}
                                            maxLength={LMT.TREAT.NAME_LEN}
                                            onChange={(e)=>this.props.onNameEdited(e.target.value)}/>
                                </div>
                            </div>
                            <div className="form-group" style={{display:"flex"}}>
                                <label className="col-form-label" style={{width:"60px"}}>技術料</label>
                                <div style={{ display:"flex", flexWrap:"wrap" }}>
                                    <div style={{ display:"flex", alignItems:"center" }}>
                                        <RequiredNumInput
                                            max={LMT.TREAT.FEE_MAX} min={LMT.TREAT.FEE_MIN} step={LMT.TREAT.FEE_STEP}
                                            style={{ width:"80px", marginRight:"2px" }}
                                            value={this.props.data.base_fee}
                                            onChange={val => this.props.onBaseFeeEdited(val)}
                                        />
                                        <span>円</span>
                                    </div>
                                    <div style={{ display:"flex", alignItems:"center" }}>
                                        <span>：点数</span>
                                        <OptionalNumInput
                                            max={LMT.TREAT.POINT_MAX} min={LMT.TREAT.POINT_MIN} step={LMT.TREAT.POINT_STEP}
                                            style={{ width:"60px", marginLeft:"2px" }}
                                            placeholder="A"
                                            value={this.props.data.base_point_a}
                                            onChange={val => this.props.onBasePointAEdited(val)}
                                        />
                                        <OptionalNumInput
                                            max={LMT.TREAT.POINT_MAX} min={LMT.TREAT.POINT_MIN} step={LMT.TREAT.POINT_STEP}
                                            style={{ width:"60px" }}
                                            placeholder="B"
                                            value={this.props.data.base_point_b}
                                            onChange={val => this.props.onBasePointBEdited(val)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-1" style={{display:"flex"}}>
                                <label className="col-form-label" style={{width:"60px"}}></label>
                                <div className="custom-control custom-checkbox" style={{display:"flex", alignItems:"center"}}>
                                    <input type="checkbox" id="chkTreatment" className="custom-control-input"
                                            checked={this.props.data.hasExtra}
                                            onChange={(e)=>this.props.onExtraChecked(e.target.checked)} />
                                    <label className="custom-control-label" htmlFor="chkTreatment">増加料金</label>
                                </div>
                            </div>
                            <div className="form-group" style={{display:"flex"}}>
                                <label className="col-form-label" style={{width:"60px"}}></label>
                                <div className={styles["medicine-route-extra-box"]}>
                                    <div style={{display:"flex", alignItems:"center"}}>
                                        <OptionalNumInput
                                            disabled={!this.props.data.hasExtra}
                                            max={LMT.MEDICINE.EXTRA_FEE_BASELINE_MAX} min={LMT.MEDICINE.EXTRA_FEE_BASELINE_MIN} step={LMT.MEDICINE.EXTRA_FEE_BASELINE_STEP}
                                            style={{width:"60px", marginLeft:"2px", marginRight:"2px"}}
                                            value={this.props.data?.base_limit ?? undefined}
                                            onChange={val => this.props.onBaseLimitEdited(val)}
                                        />
                                        <span style={{"fontSize" : "0.9em"}}> ml 以上、</span>
                                        <OptionalNumInput
                                            disabled={!this.props.data.hasExtra}
                                            max={LMT.MEDICINE.EXTRA_FEE_STEP_MAX} min={LMT.MEDICINE.EXTRA_FEE_STEP_MIN} step={LMT.MEDICINE.EXTRA_FEE_STEP_STEP}
                                            style={{width:"60px", marginLeft:"2px", marginRight:"2px"}}
                                            value={this.props.data?.extra_step ?? undefined}
                                            onChange={val =>this.props.onExtraStepEdited(val)}
                                        />
                                        <span style={{"fontSize" : "0.9em"}}> ml 毎に</span>
                                    </div>
                                    <div className="mt-2" style={{ display:"flex", flexWrap:"wrap" }}>
                                        <div style={{ display:"flex", alignItems:"center" }}>
                                            <OptionalNumInput
                                                disabled={!this.props.data.hasExtra}
                                                max={LMT.TREAT.FEE_MAX} min={LMT.TREAT.FEE_MIN} step={LMT.TREAT.FEE_STEP}
                                                style={{ width:"68px", marginLeft:"2px", marginRight:"2px" }}
                                                value={this.props.data.extra_fee}
                                                onChange={val => this.props.onExtraFeeEdited(val)}
                                            />
                                            <span>円</span>
                                        </div>
                                        <div style={{ display:"flex", alignItems:"center" }}>
                                            <span>：点数</span>
                                            <OptionalNumInput
                                                disabled={!this.props.data.hasExtra}
                                                max={LMT.TREAT.POINT_MAX} min={LMT.TREAT.POINT_MIN} step={LMT.TREAT.POINT_STEP}
                                                style={{ width:"58px", marginLeft:"2px" }}
                                                value={this.props.data.extra_point_a}
                                                onChange={val => this.props.onExtraPointAEdited(val)}
                                                placeholder="A"
                                            />
                                            <OptionalNumInput
                                                disabled={!this.props.data.hasExtra}
                                                max={LMT.TREAT.POINT_MAX} min={LMT.TREAT.POINT_MIN} step={LMT.TREAT.POINT_STEP}
                                                style={{ width:"58px" }}
                                                value={this.props.data.extra_point_b}
                                                onChange={val => this.props.onExtraPointBEdited(val)}
                                                placeholder="B"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group" style={{display:"flex"}}>
                                <label className="col-form-label" style={{width:"60px"}}>コード</label>
                                <div>
                                    <input style={{width:"120px"}} className="form-control" type="text" value={this.props.data.code}
                                            maxLength={LMT.TREAT.CODE_LEN}
                                            onChange={(e)=>this.props.onCodeEdited(e.target.value)}/>
                                </div>
                            </div>

                            <div className="form-group" style={{display:"flex"}}>
                                <label className="col-form-label" style={{width:"60px"}}></label>
                                <div>
                                    <Checkbox id="chkInstruction" label="指示/処方のみで投与しない"
                                        className="m-b-5"
                                        checked={this.props.data.isInstruction}
                                        onChange={e => this.props.onIsInstructionChanged(e.target.checked)}
                                    />
                                    <Checkbox id="chkFreeMedicine" label="薬剤費が発生しない"
                                        className="m-b-5"
                                        checked={this.props.data.isFreeMedicine}
                                        onChange={e => this.props.onIsFreeMedicineChanged(e.target.checked)}
                                    />
                                    <div style={{ display:"flex", alignItems:"center" }}>
                                        <Checkbox id="chkOnceFee" label="1診療につき1回のみ技術料が発生"
                                            checked={this.props.data.isOnceFee}
                                            onChange={e => this.props.onOnceFeeChanged(e.target.checked, undefined)}
                                        />
                                        <InfoPopup iconType="question" message={A.MESSAGE.MEDICINE_ROUTE_HINT_ONCE_FEE} placement="top" />
                                    </div>
                                    <div className="m-l-20 m-t-10">
                                        <div className="m-b-5">
                                            <span style={{ fontSize:"0.75rem" }}>以下の投薬があるときは技術料が発生しない</span>
                                            <InfoPopup iconType="question" message={A.MESSAGE.MEDICINE_ROUTE_HINT_PRIOR_FEE} placement="top" />
                                        </div>
                                        <select className="form-control"
                                            disabled={!this.props.data.isOnceFee}
                                            value={this.props.data.prior_fee_route_id ?? ""}
                                            onChange={e => this.props.onOnceFeeChanged(true, e.target.value === "" ? undefined : Number(e.target.value))}>
                                            <option value="">未選択</option>
                                            { otherRoutes.map(r => (
                                                <option key={r.route_id} value={r.route_id}>{r.name}</option>
                                            )) }
                                        </select>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </ModalBody>
                    <ModalFooter className="modal-footer-fix">
                        <ExecutionButton type="save" onClick={this.props.onSubmit} disabled={this.props.isSubmitExecuting} />
                        { !this.props.data.isNew && (
                            <ExecutionButton type="delete" onClick={this.props.onDelete} disabled={this.props.isSubmitExecuting} />
                        )}
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}