import { FreezedArray } from "./util";
import { TeamMedicineRouteDto } from "../api";
import Big from 'big.js';

type MedicineRoute = Pick<TeamMedicineRouteDto, "route_id"|"base_fee"|"base_limit"|"extra_fee"|"extra_step"|"is_once_fee"|"prior_fee_route_id">;
type Treatment = { route_id: number|undefined, amount:number|undefined };

export const calcRouteFee = (routes: FreezedArray<MedicineRoute>, allTreats:FreezedArray<Treatment>, indexOfThis: number) => {
    if (allTreats.length <= indexOfThis) {
        throw new Error("index out of range: " + indexOfThis);
    }

    const routeId = allTreats[indexOfThis].route_id;
    if (routeId == null) return 0;

    const route = routes.find(r => r.route_id === routeId);
    if (route == null) return 0;

    return calcRouteFeeOf(route, allTreats, indexOfThis);
}

export const calcRouteFeeOf = (route: MedicineRoute, allTreats:FreezedArray<Treatment>, indexOfThis: number) => {
    const thisAmount = allTreats[indexOfThis]?.amount;
    if (thisAmount == null) return 0;

    let amount: Big;
    if (route.is_once_fee === 1) {
        //優先経路が選択されていればこの処置からは技術料が発生しない
        if (route.prior_fee_route_id != null && allTreats.some(t => t.route_id === route.prior_fee_route_id)) {
            return 0;
        }
        //1回のみ発生の場合は、先頭の技術料に集約
        if (allTreats.some((t,i) => i < indexOfThis && t.route_id === route.route_id)) {
            return 0;
        }
        
        amount = new Big(0);
        allTreats.filter(t => t.route_id === route.route_id)
            .forEach(t => {
                if (t.amount != null) {
                    amount = amount.plus(t.amount);
                }
            });

    } else {
        amount = new Big(thisAmount);
    }

    let val = route.base_fee;
    const exFee = route.extra_fee ?? 0;
    if (exFee > 0) {
        const extraAmount = new Big(amount).minus(route.base_limit ?? 0);
        if (extraAmount.gt(0)) {
            const exStep = route.extra_step ?? 1;
            const extraCount = extraAmount.mod(exStep).eq(0) ? extraAmount.div(exStep) : extraAmount.div(exStep).plus(1).round(0, 0);
            val += Number(extraCount.times(exFee).toString());
        }
    }
    return val;
}