import React from 'react';
import { Card, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export type FormModalType = "CONFIRM"|"ALERT"|"QUESTION"|"REQUIRED";

interface MyProps {
	onClose: (result?: number)=>void;
	isOpen: boolean;
	text: string;
	subtext?: string;
	title?: string;
	buttons?: string[]
	type: FormModalType;
}

export class FormModal extends React.Component<MyProps> {

	constructor(props) {
		super(props);

		this.onClose = this.onClose.bind(this);
		this.getTitle = this.getTitle.bind(this);
	}

	onClose(result?: number) {
		if (this.props.onClose) {
			this.props.onClose(result);
		}
	}

	getTitle() {
		if ((this.props.title ?? "") !== "") {
			return this.props.title;
		}

		return this.props.type === "CONFIRM" ? "通知"
			: this.props.type === "QUESTION" ? "確認"
			: this.props.type === "ALERT" ? "警告"
			: "";
	}

	render() {
		let props = this.props;
		return (
			<Modal isOpen={props.isOpen}>
				<ModalHeader className="popup-title" style={{ borderBottom: "none" }} toggle={props.type === "REQUIRED" ? undefined : this.onClose}>{this.getTitle()}</ModalHeader>
				<ModalBody>
					<div className="m-t-20">
						<div>
							{props.text.split('\n').map((line, i) => (
								<h6 key={i}>
									{line}
								</h6>
							))}
							{
								(props.subtext ?? "") !== "" ?
									(
										<Card body outline color="" className="card-outline-secondary bg-white text-secondary m-b-10">
											<blockquote className="card-blockquote mb-0">
												<p className="f-s-14 f-w-600">{props.subtext}</p>
											</blockquote>
										</Card>
									)
									:
									(
										''
									)
							}
						</div>
					</div>
				</ModalBody>
				<ModalFooter style={{ borderTop: "none" }}>
					{props.buttons ?
						(<div style={{ width:"100%", display:"flex", justifyContent:"center" }}>
							{
								props.buttons.map((button, i) => (
									<button key={i} className={"btn btn-primary" + (i === 0 ? "" : " m-l-10")} onClick={() => this.onClose(i)}>{button}</button>
								))
							}
						</div>
						)
						: props.type === "ALERT" ? (
							<button className="m-auto btn btn-primary" onClick={() => this.onClose(0)}>確認</button>
						)
							: props.type === "CONFIRM" ? (
								<button className="btn btn-primary" onClick={() => this.onClose(0)}>キャンセル</button>
							) : (
									<>
										<button className="btn btn-primary m-r-10" onClick={() => this.onClose(0)}>確認</button>
										<button className="btn btn-primary" onClick={() => this.onClose(1)}>キャンセル</button>
									</>
								)}
				</ModalFooter>
			</Modal>
		)
	}
}