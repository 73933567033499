import React from 'react';
import styles from './cow-program.module.css';
import settingStyles from '../setting/setting.module.css';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { PageSettings } from '../../config/page-settings';
import { withContext, IUser, ICowBreed, ICowUse } from '../../stores';
import moment from 'moment';
import { AppState } from '../../app';
import classnames from 'classnames';
import { IEventWriteParamCow, historyLocation } from '../../config/history-location-builder';
import { CommonUtil, FreezedArray } from '../../config/util';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { ProgramApi, ProgramDto, ProgramApplyReq, ProgramApplyResultItemDto, ProgramCowDeleteReq } from '../../api';
import { PROGRAM_TRIGGER_KIND } from '../../config/program-kind';
import { CowProgramPopup } from './cow-program-popup';
import { EventKind } from '../../config/event-kind';
import { scheduleCustomColorToReq } from '../schedule/schedule-popup';
import { hasRanchAuth, hasClinicAuth } from '../../config/auth-checker';
import { SingleCowHeader } from 'components/cow-header/single-cow-header';
import { MultiCowHeader } from 'components/cow-header/multi-cow-header';

interface MyState {
    activeTab:"event"|"manual"|"clinic";
    ranch_id?: number;
    clinic_id?: number;
    user?: IUser;
    cows:Readonly<Readonly<IEventWriteParamCow>[]>;
    eventPrograms: ProgramDto[];
    manualPrograms: ProgramDto[];
    clinicPrograms: ProgramDto[];
    selectedProgram?: Readonly<ProgramDto>;
    executing: boolean;
    cowBreeds: FreezedArray<ICowBreed>;
    cowUses: FreezedArray<ICowUse>;
}

class CowProgram extends Base<BaseProps<{},{},{cows:IEventWriteParamCow[]}|undefined>, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            activeTab:"event",
            cows:[],
            eventPrograms: [],
            manualPrograms: [],
            clinicPrograms: [],
            executing: false,
            cowBreeds:[],
            cowUses:[]
        };
    }

    componentDidMount() {

        this.context.handleSetHeader({ title:"プログラム設定" });
        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        if (this.handleNotAllowAccess(undefined, ["BROWSE_INSIDE"], [])) {
            return;
        }

        const cows = this.props.location.state?.cows ?? [];

        const user = this.props.rootStore.user;
        const ranchId = this.props.rootStore.cur_ranch_id;
        const clinicId = this.props.rootStore.getClinicIdForMaster();
        this.setState({
            user: user,
            ranch_id: ranchId,
            clinic_id: clinicId,
            cows: cows,
            cowBreeds: this.props.rootStore.options.cow_breed,
            cowUses: this.props.rootStore.getCowUses(ranchId)
        }, () => {
            this.reloadPrograms(ranchId);

        });
    }

    async reloadPrograms(ranchId: number): Promise<boolean> {
        try {
            this.context.handleSetIsLoading(true);
            const opt = { retries: true };
            const res = await this.comm().send((await ProgramApi()).getProgramListUsingPOST({ ranch_id: ranchId }), opt);
            if (res.data == null) return false;

            let clinicList: ProgramDto[] = [];
            if (this.state.clinic_id != null) {
                const cliRes = await this.comm().send((await ProgramApi()).getProgramListUsingPOST({ clinic_id:this.state.clinic_id }), opt);
                if (cliRes.data == null) return false;
                clinicList = cliRes.data;
            }

            await this.props.rootStore.fetchCowTags(ranchId);

            const events = res.data.filter(d => d.trigger_kind === PROGRAM_TRIGGER_KIND.EVENT.no);
            const manuals = res.data.filter(d => d.trigger_kind === PROGRAM_TRIGGER_KIND.MANUAL.no);
            const clinics = clinicList.filter(d => d.trigger_kind === PROGRAM_TRIGGER_KIND.MANUAL.no);
            await this.setStateAsync({
                eventPrograms: events,
                manualPrograms: manuals,
                clinicPrograms: clinics
            });
            return true;
            
        } finally {
            this.context.handleSetIsLoading(false);
        }
    }

    async onDetailClick(pg: ProgramDto) {

        this.setState({
            selectedProgram:pg,
        });
    }
    

    async onApplyEventByIcon(pg: ProgramDto) {
        await this.onApplyEvent(pg);
    }
    async onApplyEventByPopup() {
        if (!CommonUtil.assertNotNull(this.state.selectedProgram)) return;
        if (this.state.selectedProgram.ranch_id == null) {
            console.error("診療所プログラムに対してイベント連動プログラムの適用実行", this.state.selectedProgram);
            return;
        }

        this.setState({ executing: true });

        const res = await this.onApplyEvent(this.state.selectedProgram);
        if (res) {
            await this.setStateAsync({
                selectedProgram: undefined,
            });
        }

        this.setState({ executing: false });
    }
    async onApplyEvent(pg: ProgramDto): Promise<boolean> {
        if (!CommonUtil.assertNotNull(this.state.ranch_id)) return Promise.resolve(false);

        const cowIds = this.state.cows
            .filter(c => !pg.cow_ids.includes(c.cow_id))
            .map(c => c.cow_id);
        if (cowIds.length === 0) {
            console.error("no cows to apply", pg, this.state.cows);
            return Promise.resolve(false);
        }

        const req: ProgramApplyReq = {
            ranch_id: this.state.ranch_id,
            cow_ids: cowIds,
            program_id: pg.program_id,
            
            //※プログラム適用時の過去イベント遡り生成は廃止
            ignores_past_event: 1,
            force: 0,
        };
        this.context.handleSetIsLoading(true);
        const res = await this.comm().send((await ProgramApi()).applyUsingPOST(req));
        this.context.handleSetIsLoading(false);

        if (res.result !== "OK") return false;

        const list = res.data?.list ?? [];
        if (list.length === 0) {
            //リロードに失敗した場合も処理は成功で返す
            await this.reloadPrograms(req.ranch_id);
            return true;
        }

        //※即時生成を廃止したのでレスポンスが返るケースは想定していない
        console.error("unexpected response of apply event programs", list);
        return false;
    }

    async onApplyManualByPopup(baseDate: Date, color: string | undefined) {
        if (!CommonUtil.assertNotNull(this.state.selectedProgram)) return;

        this.setState({ executing: true });
        await this.onApplyManual(baseDate, color);
        this.setState({ executing: false });
    }
    async onApplyManual(baseDate: Date, color: string | undefined) {
        const program = this.state.selectedProgram;
        if (!CommonUtil.assertNotNull(this.state.ranch_id, "ranch_id")) return;
        if (!CommonUtil.assertNotNull(this.state.user, "user")) return;
        if (!CommonUtil.assertNotNull(program, "program")) return;

        const req: ProgramApplyReq = {
            ranch_id: this.state.ranch_id,
            clinic_id: program.clinic_id,
            cow_ids: this.state.cows.map(c => c.cow_id),
            force: 0,
            program_id: program.program_id,
            base_date: moment(baseDate).format("YYYY-MM-DD"),
            color: scheduleCustomColorToReq(color)
        };
        this.context.handleSetIsLoading(true);
        const resPre = await this.comm().send((await ProgramApi()).applyUsingPOST(req));
        this.context.handleSetIsLoading(false);
        if (resPre.result !== "OK") return;

        //未加入牧場・繁殖契約なし牧場で選択できない種別の予定を捨てる
        const selectableEventKindNoSet = new Set(EventKind.forSchedule(req.ranch_id, this.state.user).map(e => e.no));
        const list = (resPre.data?.list ?? []).filter(p => selectableEventKindNoSet.has(p.event_kind_no));
        const tags = resPre.data?.tags ?? [];

        if (list.length === 0 && tags.length === 0) {
            this.context.showDialog("NOTIFY", "このプログラムにより生成される予定はありません");
            return;
        }

        let msg: string;
        let subTexts: string[] | undefined = undefined;
        if (list.length === 0) {
            msg = "このプログラムではタグのみが生成されます。プログラムを適用しますか？"
        } else {
            msg = "以下の予定が生成されます。プログラムを適用しますか？";
            subTexts = list.map(i => this.buildScheduleText(i));
        }

        const confirmed = await this.context.showDialog("QUESTION", msg, [{ type:"save", text:"適用" }, { type:"cancel" }], { subTexts }) === 0;
        if (!confirmed) {
            return;
        }

        //再送
        req.preloaded = { list, tags };
        req.force = 1;  //<- preloadedが指定されていれば必要ないが一応
        this.context.handleSetIsLoading(true);
        const res = await this.comm().send((await ProgramApi()).applyUsingPOST(req));
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") return;

        if ((res.data?.list ?? []).length > 0) {
            console.warn("unexpected response when apply manual", req, res.data);
        }
        await this.setStateAsync({ selectedProgram: undefined });
        await this.reloadPrograms(req.ranch_id);
    }
    async onRemoveByPopup() {
        if (!CommonUtil.assertNotNull(this.state.selectedProgram)) return;

        const res = await this.onRemove(this.state.selectedProgram);
        if (res) {
            await this.setStateAsync({
                selectedProgram: undefined,
            });
        }
    }
    async onRemoveByIcon(pg: ProgramDto) {
        this.onRemove(pg);
    }
    async onRemove(pg: ProgramDto): Promise<boolean> {
        if (!CommonUtil.assertNotNull(this.state.ranch_id)) return Promise.resolve(false);

        const cowIds = this.state.cows
            .filter(c => pg.cow_ids.includes(c.cow_id))
            .map(c => c.cow_id);
        if (cowIds.length === 0) {
            console.error("no cows to unapply", pg, this.state.cows);
            return Promise.resolve(false);
        }

        const msg = "プログラムの適用を解除します。生成済みの予定は削除されません。";
        const confirmed = await this.context.showDialog("QUESTION", msg, [{ type:"delete", text:"解除" }, { type:"cancel" }]) === 0;
        if (!confirmed) return false;


        const req: ProgramCowDeleteReq = {
            ranch_id: this.state.ranch_id,
            program_id: pg.program_id,
            cow_ids: cowIds
        };

        this.context.handleSetIsLoading(true);
        const res = await this.comm().send((await ProgramApi()).deleteAppliedUsingPOST(req));
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") return false;

        //リロードに失敗した場合も処理は成功で返す
        await this.reloadPrograms(req.ranch_id);
        return true;
    }

    onMoveToManage() {
        if (!CommonUtil.assertNotNull(this.state.selectedProgram, "selectedProgram")) return;

        const teamId = this.state.selectedProgram.ranch_id ?? this.state.selectedProgram.clinic_id;

        if (!CommonUtil.assertNotNull(teamId, "teamId of selectedProgram " + this.state.selectedProgram.program_id)) return;

        this.props.history.push(historyLocation.toProgramSetting(teamId));
    }

    buildScheduleText(item: ProgramApplyResultItemDto) {
        const eventKind = EventKind.find(item.event_kind_no)?.schedule?.sname ?? "";
        const date = moment(item.scheduled_date).format("MM/DD");
        return `${date} [${eventKind}]${item.title}`;
    }

    render() {
        const cows = this.state.cows;
        if (this.state.ranch_id == null || this.state.user == null || cows.length === 0) {
            return <></>
        }

        const renderEventIcon = (pg: ProgramDto) => {
            const stateName = cows.length === 1
                ? (pg.cow_ids.includes(cows[0].cow_id) ? "applied" : undefined)
                : (cows.every(c => pg.cow_ids.includes(c.cow_id)) ? "applied"
                    : cows.some(c => pg.cow_ids.includes(c.cow_id)) ? "part-applied"
                    : undefined);
            
            const onClick = stateName == undefined ? () => this.onApplyEventByIcon(pg)
                    : stateName === "applied" ? () => this.onRemoveByIcon(pg)
                    : () => this.setState({ selectedProgram:pg });

            return (
                <div onClick={onClick}>
                    <i className={classnames("fas fa-check-circle", styles["event-icon"], stateName == null ? "" : styles[stateName])}></i>
                </div>
            );
        }

        const renderDetailButton = (pg: ProgramDto) => (
            <button className={classnames("btn btn-green btn-sm", styles["btn-detail"])} onClick={() => this.onDetailClick(pg)}>詳細</button>
        )

        const seledctedPg = this.state.selectedProgram;
        const canMoveToManage = seledctedPg != null
            && (seledctedPg.ranch_id != null && hasRanchAuth("MASTER_EDIT", seledctedPg.ranch_id, this.state.user)
                || seledctedPg.clinic_id != null && hasClinicAuth("MASTER_EDIT", seledctedPg.clinic_id, this.state.user))

        return (
            <div className="page-root">
                <div className="product" style={{ marginBottom:"12px", fontSize:"1.25rem", padding:"12px" }}>
                {
                    cows.length === 1 && (
                        <SingleCowHeader ranchId={this.state.ranch_id} cowId={cows[0].cow_id} />
                    )
                }
                {
                    cows.length > 1 && (
                        <MultiCowHeader infoName="設定" cowCount={cows.length} cows={this.props.location.state?.cows} />
                    )
                }

                </div>
                <div className="product" style={{ height: "100%", minHeight: 0, background:"#EEEEEE" }}>
                    <div className="product-detail" style={{ height: "100%" }}>
                        
                        <div className="product-info product-info-fix p-0">
                            <div className="ranch-main" style={{flex: "auto", height: "100%"}}>
                                <Nav tabs className={ styles['nav-container'] }>
                                    <NavItem>
                                        <NavLink className={classnames({ active: this.state.activeTab === "event", [styles.active]:this.state.activeTab === "event" }, styles["nav-link"])}
                                            onClick={() => this.setState({activeTab:"event"})}>イベント記録連動</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={classnames(styles["nav-link"], { active: this.state.activeTab === "manual", [styles.active]:this.state.activeTab === "manual" })}
                                            onClick={() => this.setState({activeTab:"manual"})}>手動起動</NavLink>
                                    </NavItem>
                                    { this.state.clinic_id != null && (
                                        <NavItem>
                                            <NavLink className={classnames(styles["nav-link"], { active: this.state.activeTab === "clinic", [styles.active]:this.state.activeTab === "clinic" })}
                                                onClick={() => this.setState({activeTab:"clinic"})}>診療所</NavLink>
                                        </NavItem>
                                    )}
                                </Nav>
                                <TabContent activeTab={this.state.activeTab} className={ styles['tab-content'] }>
                                    <TabPane tabId="event">
                                        <ul className={settingStyles["list"]}>
                                            { this.state.eventPrograms.map(pg => (
                                                <li key={pg.program_id} className={settingStyles["list-item"]}>
                                                    <div className={styles["list-item-content"]}>
                                                        { renderEventIcon(pg) }
                                                        <div className={styles["list-item-name"]}>{pg.name}</div>
                                                        { renderDetailButton(pg) }
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </TabPane>
                                    <TabPane tabId="manual">
                                        <ul className={settingStyles["list"]}>
                                            { this.state.manualPrograms.map(pg => (
                                                <li key={pg.program_id} className={settingStyles["list-item"]}>
                                                    <div className={styles["list-item-content"]}>
                                                        <div className={styles["list-item-name"]}>{pg.name}</div>
                                                        { renderDetailButton(pg) }
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </TabPane>
                                    { this.state.clinic_id != null && (
                                        <TabPane tabId="clinic">
                                            <ul className={settingStyles["list"]}>
                                                { this.state.clinicPrograms.map(pg => (
                                                    <li key={pg.program_id} className={settingStyles["list-item"]}>
                                                        <div className={styles["list-item-content"]}>
                                                            <div className={styles["list-item-name"]}>{pg.name}</div>
                                                            { renderDetailButton(pg) }
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </TabPane>
                                    )}
                                </TabContent>
                            </div>
                        </div>
                    </div>
                    { this.state.selectedProgram != null && (
                        <CowProgramPopup
                            program={this.state.selectedProgram}
                            cows={this.state.cows}
                            onApplyManual={(d,c) => this.onApplyManualByPopup(d,c)}
                            onApplyEvent={() => this.onApplyEventByPopup()}
                            onClose={() => { this.setState({ selectedProgram: undefined })}}
                            onMoveToManage={canMoveToManage ? (() => this.onMoveToManage()) : undefined}
                            onRemove={() => this.onRemoveByPopup()}
                            isSubmitExecuting={this.state.executing}
                            cowBreeds={this.state.cowBreeds}
                            cowUses={this.state.cowUses}
                            tags={this.props.rootStore.getCowTags()}
                        />
                    )}
                </div>
            </div>
        )
    }
}

export default withRouter(withContext(CowProgram));


