import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import { FreezedArray } from '../../config/util';
import { LMT } from '../../config/constant';

type OptionItem = { value: number, label: string };
export interface SingleCreatableSelectProps {
    options: FreezedArray<{ name: string, value: number }>;
    onSelect: (value: number) => void;
    onCreate: (name: string) => void;
    placeholder?: string;
    formatCreateLabel?: (name: string) => string;
    value: number | null | undefined;
    style?: React.CSSProperties;
    canCreate?: (input:string) => boolean;
    maxLength?: number;
    inputId?: string;
    optionTestId?: string;
}

export const SingleCreatableSelect: React.FC<SingleCreatableSelectProps> = (props) => {

    const canCreate = props.canCreate;
    const isValidNewOption = canCreate == null ? undefined
        : (input: string, _, options: OptionItem[]) => input.trim() !== "" && !options.some(o => o.label === input.trim()) && canCreate(input);


    const selectedItem = props.value == null ? null
                        : props.options.find(o => o.value === props.value);

    return (
        <CreatableSelect inputId={props.inputId}
            value={selectedItem == null ? null : ({ label:selectedItem.name, value: selectedItem.value})}
            onCreateOption={props.onCreate}
            isMulti={false}
            placeholder={props.placeholder}
            isClearable={false}
            formatCreateLabel={props.formatCreateLabel}
            onChange={(o: OptionItem) => props.onSelect(o.value)}
            isValidNewOption={isValidNewOption}
            styles={{
                container:(provided) => ({
                    ...provided,
                    ...props.style
                }),
                control:(provided)=>({
                    ...provided,
                    border: "2px solid rgb(206,212,218)"
                })
            }}
            options={ props.options.map(o => ({ label:o.name, value: o.value })) }
            onInputChange={(input:string) => (props.maxLength == null || input.length <= props.maxLength) ? input : input.substr(0, props.maxLength)}
            components={{ Option: p => <components.Option {...p}><span data-testid={props.optionTestId}>{p.children}</span></components.Option> }}
        />
    );
}