import { IUser } from "../stores";
import { hasRanchAuth } from "./auth-checker";
import { RanchContractItemKey, hasRanchContract } from "./contract-checker";
import { UserTeams } from "./user-teams";

export const EVENT_KINDS = [
    "FEEDING",
    "LOCATION",
    "SYMPTOM",
    "PREVENTION",
    "RUT",
    "BREEDING",
    "CROSS",
    "DELIVERY",
    "SELL_COW",
    "SELL_MILK_COW",
    "SELL_MILK_RANCH",
    "SELL_SEED",
    "BUY_SEED",
    "GROWTH",
    "OTHER_PROFIT_COW",
    "OTHER_PROFIT_RANCH",
    "OTHER_LOSS_COW",
    "OTHER_LOSS_RANCH",
    "SOV",
    "OPU",
    "IVF",
    "IVF_EGG",
    "OTHER",
] as const;

export type EventKindKey = typeof EVENT_KINDS[number];

interface IEventKindSchedule {
    sname: string;
    recName: string;
    color: string;
    canEdit: (ranchId: number, user: IUser) => boolean;
}
interface IEventKind {
    no: number;
    eventType: "cow"|"ranch";
    group: string|undefined;
    schedule: Readonly<IEventKindSchedule> | undefined;
    contracts?: RanchContractItemKey[];
    nameForCowSearch: string | undefined;
    canSearchByTreat?: boolean;
    selfEditOnly?: boolean;
    treatment?: "medicine"|"treatAndMedicine";
    singleCowOnly?: boolean;
    officialOnly?: boolean;
}

export const EVENT_KIND: { [ key in EventKindKey]: IEventKind } = {
    FEEDING:            { no:  1, eventType: "cow",   group: "えさ",    nameForCowSearch: "えさ",      schedule: { sname: "えさ",      recName: "えさ",      color: "#ffbb22",  canEdit: (r,u) => hasRanchAuth("FOODING_EDIT", r, u) }, officialOnly:true },
    LOCATION:           { no:  2, eventType: "cow",   group: "移動",    nameForCowSearch: "移動",      schedule: { sname: "移動",      recName: "移動",      color: "#008000",   canEdit: (r,u) => hasRanchAuth("COW_EDIT", r, u) }, officialOnly:true },
    SYMPTOM:            { no:  3, eventType: "cow",   group: "診療",    nameForCowSearch: "診療",      schedule: { sname: "診療",      recName: "体調・治療", color: "#9632a8", canEdit: (r,u) => hasRanchAuth("TREAT_EDIT", r, u) }, canSearchByTreat:true, selfEditOnly:true, treatment:"treatAndMedicine" },
    PREVENTION:         { no:  4, eventType: "cow",   group: "予防",    nameForCowSearch: "予防",      schedule: { sname: "予防",      recName: "予防",      color: "#0000ff",    canEdit: (r,u) => hasRanchAuth("TREAT_EDIT", r, u) }, selfEditOnly:true, treatment:"treatAndMedicine" },
    RUT:                { no:  5, eventType: "cow",   group: "繁殖",    nameForCowSearch: "発情",      schedule: { sname: "発情",      recName: "発情",      color: "#a83255", canEdit: (r,u) => hasRanchAuth("BREEDING_EDIT", r, u) }, contracts: [ "BREEDING" ], selfEditOnly:true, treatment:"medicine" },
    BREEDING:           { no:  6, eventType: "cow",   group: "繁殖",    nameForCowSearch: "検診",      schedule: { sname: "検診",      recName: "検診",      color: "#a83255", canEdit: (r,u) => hasRanchAuth("BREEDING_EDIT", r, u) }, contracts: [ "BREEDING" ], selfEditOnly:true, treatment:"treatAndMedicine" },
    CROSS:              { no:  7, eventType: "cow",   group: "繁殖",    nameForCowSearch: "交配",      schedule: { sname: "交配",      recName: "交配",      color: "#a83255", canEdit: (r,u) => hasRanchAuth("BREEDING_EDIT", r, u) }, contracts: [ "BREEDING" ] },
    DELIVERY:           { no:  8, eventType: "cow",   group: "繁殖",    nameForCowSearch: "分娩",      schedule: { sname: "分娩予定",  recName: "分娩",   color: "#a83255", canEdit: (r,u) => hasRanchAuth("BREEDING_EDIT", r, u) }, contracts: [ "BREEDING" ], selfEditOnly:true, singleCowOnly:true },
    SELL_COW:           { no: 12, eventType: "cow",   group: "販売",    nameForCowSearch: "牛個体販売", schedule: { sname: "牛個体販売", recName: "牛個体販売", color: "#727272",   canEdit: (r,u) => hasRanchAuth("BALANCE_COW", r, u) }, officialOnly:true },
    SELL_MILK_COW:      { no: 13, eventType: "cow",   group: "販売",    nameForCowSearch: undefined,  schedule: undefined, officialOnly:true },
    SELL_MILK_RANCH:    { no: 14, eventType: "ranch", group: "販売",    nameForCowSearch: undefined,  schedule: undefined, officialOnly:true },
    SELL_SEED:          { no: 15, eventType: "ranch", group: "販売",    nameForCowSearch: undefined,  schedule: undefined, officialOnly:true },
    BUY_SEED:           { no: 16, eventType: "ranch", group: "購入",    nameForCowSearch: undefined,  schedule: undefined, officialOnly:true },
    GROWTH:             { no: 17, eventType: "cow",   group: "測定",    nameForCowSearch: "身体測定",  schedule: { sname: "身体測定",  recName: "身体測定",      color: "#0000ff",    canEdit: (r,u) => hasRanchAuth("GROWTH_EDIT", r, u) }, selfEditOnly:true, officialOnly:true },
    OTHER_PROFIT_COW:   { no: 18, eventType: "cow",   group: "販売",    nameForCowSearch: undefined,  schedule: undefined, officialOnly:true },
    OTHER_PROFIT_RANCH: { no: 19, eventType: "ranch", group: "販売",    nameForCowSearch: undefined,  schedule: undefined, officialOnly:true },
    OTHER_LOSS_COW:     { no: 20, eventType: "cow",   group: "購入",    nameForCowSearch: undefined,  schedule: undefined, officialOnly:true },
    OTHER_LOSS_RANCH:   { no: 21, eventType: "ranch", group: "購入",    nameForCowSearch: undefined,  schedule: undefined, officialOnly:true },
    SOV:                { no: 22, eventType: "cow",   group: "採卵",    nameForCowSearch: undefined,  schedule: { sname: "体内受精卵", recName: "体内受精卵", color: "#fcfc03", canEdit: (r,u) => hasRanchAuth("BREEDING_EDIT", r, u) }, contracts: [ "BREEDING" ], selfEditOnly:true },
    OPU:                { no: 23, eventType: "cow",   group: "採卵",    nameForCowSearch: undefined,  schedule: { sname: "OPU",       recName: "OPU",        color: "#fcfc03", canEdit: (r,u) => hasRanchAuth("BREEDING_EDIT", r, u) }, contracts: [ "BREEDING" ], selfEditOnly:true },
    IVF:                { no: 24, eventType: "cow",   group: "採卵",    nameForCowSearch: undefined,  schedule: { sname: "媒精",      recName: "媒精",       color: "#fcfc03", canEdit: (r,u) => hasRanchAuth("BREEDING_EDIT", r, u) }, contracts: [ "BREEDING" ], selfEditOnly:true, singleCowOnly:true },
    IVF_EGG:            { no: 25, eventType: "cow",   group: "採卵",    nameForCowSearch: undefined,  schedule: { sname: "培養結果",  recName: "培養結果",    color: "#fcfc03", canEdit: (r,u) => hasRanchAuth("BREEDING_EDIT", r, u) }, contracts: [ "BREEDING" ], selfEditOnly:true, singleCowOnly:true },
    OTHER:              { no: 99, eventType: "cow",   group: undefined, nameForCowSearch: undefined,  schedule: { sname: "その他",    recName: "",    color: "#727272", canEdit: () => false } },
};

export class EventKind {
    static readonly all = Object.values(EVENT_KIND);

    static find = (no: number) => {
        return EventKind.all.find(k => k.no === no);
    }

    private static filterContract = (ranchId: number, user: IUser, event_kinds?: IEventKind[]) => {
        let kinds = event_kinds ?? EventKind.all;

        if (new UserTeams(user).findUnofficialRanch(ranchId) != null) {
            kinds = kinds.filter(k => k.officialOnly !== true);
        }

        return kinds.filter(e => e.contracts == null || e.contracts.some(c => hasRanchContract(c, ranchId, user)))
    }

    static forRanchEventHis = (ranchId: number, user: IUser) => {
        return EventKind.filterContract(ranchId, user).filter(e => e.eventType === "ranch" && e.group != null).map(e => ({ ...e, group: e.group! }));
    }

    static forCowEventHis = (ranchId: number, user: IUser) => {
        return EventKind.filterContract(ranchId, user).filter(e => e.eventType === "cow" && e.group != null).map(e => ({ ...e, group: e.group! }));
    }

    static forSchedule = (ranchId: number, user: IUser) => {
        return EventKind.filterContract(ranchId, user).filter(e => e.schedule != null).map(e => ({ ...e, schedule: e.schedule! }));
    }
    /**
     * 診療所プログラム作成のための予定に使用できるリスト
     * ※現状は繁殖の予定や加入牧場のみの予定を含む
     */
    static forScheduleWithClinicUser = () => {
        return EventKind.all.filter(e => e.schedule != null).map(e => ({ ...e, schedule: e.schedule! }));
    }

    static forCowSearch = (ranchId: number, user: IUser) => {
        return EventKind.filterContract(ranchId, user)
                        .filter(e => e.nameForCowSearch != null).map(e => ({ ...e, nameForCowSearch: e.nameForCowSearch! }));
    }
}
