import React, { ReactNode } from 'react';
import { Checkbox } from './form-checkbox';
import { InfoPopup } from 'components/parts/info-popup';

interface FormInputBlockProps {
    label?: string;
    labelClassName?:string;
    children: ReactNode;
    contentClassName?: string;
    contentStyle?: React.CSSProperties;
    hint?: string|Readonly<string[]>;
}
interface FormCheckInputBlockProps extends Omit<FormInputBlockProps, "hint"> {
    checked: boolean;
    id: string;
    onChange: (checked: boolean) => void;
}

export const FormInputBlock = React.memo((props: FormInputBlockProps) => {
    return (
        <div className="form-group row-no-margin">
            <label className={"col-form-label col-md-4 col-xs-4 text-lg-right " + (props.labelClassName ?? "")}>
                <span>{props.label ?? ""}</span>
                { props.hint && (
                    <InfoPopup iconType="question" message={props.hint} placement="top" />
                )}
            </label>
            <div className={props.contentClassName} style={{ flex:1, ...props.contentStyle }} children={props.children} />
        </div>
    );
});

export const FormCheckInputBlock = React.memo((props: FormCheckInputBlockProps) => {
    return (
        <div className="form-group row-no-margin">
            <Checkbox id={props.id}
                label={props.label}
                className={"col-form-label col-md-4 col-xs-4 text-lg-right " + (props.labelClassName ?? "")}
                checked={props.checked}
                onChange={e => props.onChange(e.target.checked)}
            />
            <div className={props.contentClassName} style={{ flex:1, ...props.contentStyle }} children={props.children} />
        </div>
    );
});