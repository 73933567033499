import React from 'react';
import { FreezedArray, ar } from '../../config/util';
import { IEggRankAndCount, IOvumRankAndCount } from '../cow/cow-info';
import { Table } from "reactstrap";
import { OVUM_RANKS, getEggRanks } from '../../config/egg-kind';
import styles from './ivf-egg-rank-detail-table.module.css';

interface MyProps {
    ranks: FreezedArray<IEggRankAndCount & {
        details: IOvumRankAndCount[];
    }>;
}

export const IvfEggRankDetailTable = React.memo((props: MyProps) => {

    const calcOvumSum = (ovumRank: number) => {
        return ar.sum(props.ranks.map(r => r.details.find(d => d.ovum_rank === ovumRank)?.egg_count ?? 0));
    }
    const total = ar.sum(props.ranks.map(r => r.egg_count));

    return (
        <div>
            <Table className={styles.table}>
                <thead>
                    <tr>
                        <th colSpan={2}>卵子ランク</th>
                        { OVUM_RANKS.map(o => (
                            <th key={o.ovum_rank}>{o.name}</th>
                        ))}
                        <th>計</th>
                    </tr>
                </thead>
                <tbody>
                    { getEggRanks(false)
                        .map(e => ({ ...e, data: props.ranks.find(r => r.egg_rank === e.egg_rank)}))
                        .map((e,ie) => (
                        <tr key={e.egg_rank}>
                            { ie === 0 && (
                                <th rowSpan={getEggRanks(false).length + 1}>受精卵<br/>ランク</th>
                            )}
                            
                            <th>{e.sname}</th>
                            { OVUM_RANKS.map(o => ({ ...o, dtl: e.data?.details?.find(d => d.ovum_rank === o.ovum_rank)}))
                                .map(o => (
                                    <td key={o.ovum_rank}>{o.dtl?.egg_count ?? 0}</td>
                                ))
                            }
                            <td className={styles.total}>{e.data?.egg_count ?? 0}</td>
                        </tr>
                    ))}
                    <tr>
                        <th>計</th>
                        { OVUM_RANKS.map(o => ({ ...o, sum: calcOvumSum(o.ovum_rank) }))
                            .map(o => (
                                <td className={styles.total} key={o.ovum_rank}>{o.sum}</td>
                            ))
                        }
                        <td className={styles.total}>{total}</td>
                    </tr>
                </tbody>

            </Table>
        </div>
    )

})