import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { PageSettings } from '../../config/page-settings';
import { withContext } from '../../stores';
import moment from 'moment';
import { CommonUtil, ar, formatYen } from '../../config/util';
import { AppState } from '../../app';
import styles from './balance.module.css';
import { BalanceDateSelector } from './balance-date-selector';
import { BalanceApi, BalanceRanchReq, BalanceDto } from '../../api';
import classnames from 'classnames';
import { BalanceGraph, BalanceGraphType } from './balance-graph';
import { GraphPager } from 'components/parts/graph-pager';

interface BalanceCondition {
    to : Date;
    kind : AggregateKindKey;
    isActive: 1 | 0 | undefined;
}

interface LoadedData {
    dto: Readonly<BalanceDto>;
    from: Date;
    to: Date;
    totalProfit: number;
    totalLoss: number;
    isActiveCow: 0 | 1 | undefined;
    condition: BalanceCondition;
    hasPrev: boolean;
    hasNext: boolean;
}

interface MyState {
    ranch?: { ranch_id:number, name:string };
    condition : Readonly<BalanceCondition>;
    currentData: Readonly<LoadedData> | undefined;
    graphType: BalanceGraphType;
    allCowCount?: number;
}

const AGGREGATE_KINDS = ["DAY","MONTH","DAYS30"] as const;
type AggregateKindKey = typeof AGGREGATE_KINDS[number];
interface IAggregateKind {
    name: string;
    value: string;
    graphSpan: number;
    graphSpanUnit: "day"|"month";
    dispSpanInfo: string;
    newPageEndDay: (pre:Date, move:number) => Date;
}
const AGGREGATE_KIND: {[key in AggregateKindKey]: IAggregateKind} = {
    DAY:    { name: "日別",     value:"day",    graphSpan:14,  graphSpanUnit:"day",   dispSpanInfo:"14日間",
                newPageEndDay: (pre, move) => moment(pre).add(13 * move, "days").toDate() },
    MONTH:  { name: "月別",     value:"month",  graphSpan:13,  graphSpanUnit:"month", dispSpanInfo:"1年間",
                newPageEndDay: (pre, move) => moment(pre).add(12 * move, "months").endOf("month").startOf("day").toDate() },
    DAYS30: { name: "30日単位", value:"30days", graphSpan:390, graphSpanUnit:"day",   dispSpanInfo:"1年間",
                newPageEndDay: (pre, move) => moment(pre).add(360 * move, "days").toDate() },
}

const calcStartDay = (end: Date, kind:IAggregateKind) => {
    const m = moment(end);
    if (kind.graphSpanUnit === "month") {
        return m.add(kind.graphSpan * -1 + 1, "months").startOf("month").toDate();
    }
    if (kind.graphSpanUnit === "day") {
        return m.add(kind.graphSpan * -1 + 1, "days").toDate();
    }
    console.error("unknown kind", kind);
    return end;
}

class BalanceRanch extends Base<BaseProps, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        const today = moment().startOf("day").toDate();

        this.state = {
            condition: {
                to: today,
                isActive: undefined,
                kind: "DAY"
            },
            currentData: undefined,
            graphType: "balance"
        };
    }

    componentDidMount() {

        this.context.handleSetHeader({ title:"牧場収支" });
        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        if (this.handleNotAllowAccess(undefined, ["BALANCE_RANCH"], [])) {
            return;
        }

        const ranchId = this.props.rootStore.cur_ranch_id;
        const ranchName = this.props.rootStore.getCurRanchName() ?? "";
        this.setState({
            ranch: { ranch_id: ranchId, name:ranchName }

        }, () => {
            this.loadGraph(this.state.condition);
        });
    }

    selectCowConditionName(isActive: 0 | 1 | undefined) {
        return isActive === 1 ? "現在飼養中" :
            isActive === 0 ? "飼養終了" :
            "すべての牛";
    }

    loadGraph = async (cond: Readonly<BalanceCondition>) => {
        if (!CommonUtil.assertNotNull(this.state.ranch, "ranch", "loadGraph")) return;
        const from = calcStartDay(cond.to, AGGREGATE_KIND[cond.kind]);

        const req: BalanceRanchReq = {
            from: moment(from).format("YYYY-MM-DD"),
            to: moment(cond.to).format("YYYY-MM-DD"),
            ranch_id: this.state.ranch.ranch_id,
            is_active: cond.isActive,
            span: AGGREGATE_KIND[cond.kind].value
        };
        this.context.handleSetIsLoading(true);
        const res = await this.comm().send((await BalanceApi()).getRanchBalanceUsingPOST(req));
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK" || res.data == null) return;

        this.setState({
            currentData: {
                dto: res.data,
                from: from,
                to: cond.to,
                totalProfit: ar.sum(res.data.values.map(d => ar.sum(d.profits.map(p => p.price)))),
                totalLoss: ar.sum(res.data.values.map(d => ar.sum(d.losses.map(l => l.price)))),
                isActiveCow: cond.isActive,
                condition: cond,
                hasNext: true,
                hasPrev: true
            },
            //※全頭データをロードしたタイミングで頭数を保持しておく
            allCowCount: cond.isActive == null ? res.data.cow_count : this.state.allCowCount
        });
    }

    onAggrKindChanged(value: string) {
        if (!AGGREGATE_KINDS.some(k => k === value)) {
            console.error("unknown selected kind", value);
            return;
        }
        
        this.setState({
            condition: { ...this.state.condition, kind: value as AggregateKindKey }
        });
    }
    onDateChanged(date: Date) {
        this.setState({
            condition: { ...this.state.condition, to: date }
        });
    }
    onIsActiveChanged(value: string) {
        const val = Number(value);
        const isActive = (val === 1 || val === 0) ? val : undefined;
        this.setState({
            condition: { ...this.state.condition, isActive }
        });
    }
    async onNewPage(move: number) {
        if (!CommonUtil.assertNotNull(this.state.currentData, "currentData", "onNewPage")) return;
        const oldCond = this.state.currentData.condition;
        const kind = AGGREGATE_KIND[oldCond.kind];
        const newCond: BalanceCondition = {
            ...oldCond,
            to: kind.newPageEndDay(oldCond.to, move)
        };
        await this.loadGraph(newCond);
    }
 
    render() {
        if (this.state.ranch == null) {
            return <></>
        }
        const ranch = this.state.ranch;

        const aggrKind = AGGREGATE_KIND[this.state.condition.kind];
        const data = this.state.currentData;

        return (
            <div className="page-root">
                <div className="product" style={{ height: "100%", "minHeight": "0" }}>
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix p-b-0">
                            <div className={classnames("product-info-header", styles["content-header"])}>
                                {ranch.name}
                            </div>

                            <div className="ranch-main" style={{flex: "auto"}}>
                                <div className={classnames(styles["graph-select-container"], styles["row"])}>
                                    <select className="form-control"
                                        value={this.state.condition.kind}
                                        onChange={e => this.onAggrKindChanged(e.target.value)}>
                                        { AGGREGATE_KINDS.map(k => (
                                            <option key={k} value={k}>{AGGREGATE_KIND[k].name}</option>
                                        ))}
                                    </select>
                                    <select className="form-control" value={this.state.condition.isActive}
                                            onChange={e => this.onIsActiveChanged(e.target.value)}>
                                        <option value={undefined}>{this.selectCowConditionName(undefined)}</option>
                                        <option value={1}>{this.selectCowConditionName(1)}</option>
                                        <option value={0}>{this.selectCowConditionName(0)}</option>
                                    </select>
                                </div>
                                <div>
                                    <BalanceDateSelector
                                        selectUnit={aggrKind.graphSpanUnit}
                                        selectedDate={this.state.condition.to}
                                        onDateChanged={d => this.onDateChanged(d)}
                                        spanInfo={aggrKind.dispSpanInfo} />
                                </div>

                                <div className={styles["load-button-row"]}>
                                    <button className="btn btn-green" onClick={() => this.loadGraph(this.state.condition)}>
                                        <i className="far fa-arrow-alt-circle-down"></i>
                                        <span> 再集計</span>
                                    </button>
                                </div>

                                { data != null && (
                                    <div className={styles["data-container"]}>
                                        <GraphPager containerClassName={styles["graph-header"]}
                                            hasPrev={data.hasPrev} hasNext={data.hasNext}
                                            onPrev={() => this.onNewPage(-1)} onNext={() => this.onNewPage(1)}
                                        >
                                            <div className={styles.summary}>
                                                <span>{this.selectCowConditionName(data.isActiveCow)} {data.dto.cow_count}頭{(this.state.allCowCount == null || data.isActiveCow == null) ? "" : ` / 全 ${this.state.allCowCount}頭`}</span><br />
                                                <span>売上 {formatYen(data.totalProfit)}　経費 {formatYen(data.totalLoss)}　収支 {formatYen(data.totalProfit - data.totalLoss)}</span><br />
                                                <span>（{moment(data.from).format("YYYY/M/D")} ～ {moment(data.to).format("YYYY/M/D")}）</span>
                                            </div>
                                        </GraphPager>
                                        <div className={styles["disptype-selector"]}>
                                            <div className="radio radio-css">
                                                <input type="radio" id="radBalance" checked={this.state.graphType === "balance"}
                                                    onChange={() => this.setState({graphType:"balance"})} />
                                                <label htmlFor="radBalance">収支</label>
                                            </div>
                                            <div className="radio radio-css">
                                                <input type="radio" id="radProfit" checked={this.state.graphType === "profit"}
                                                    onChange={() => this.setState({graphType:"profit"})} />
                                                <label htmlFor="radProfit">売上内訳</label>
                                            </div>
                                            <div className="radio radio-css">
                                                <input type="radio" id="radLoss" checked={this.state.graphType === "loss"}
                                                    onChange={() => this.setState({graphType:"loss"})} />
                                                <label htmlFor="radLoss">経費内訳</label>
                                            </div>
                                        </div>
                                        <BalanceGraph
                                            dispType={this.state.graphType}
                                            data={data.dto.values}
                                        />
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(withContext(BalanceRanch));