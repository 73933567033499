import React, { useEffect, useState } from 'react';

import baseStyles from './sellmilk.module.css'
import styles from './RegisterCows.module.css'
import { SellMilk, SellMilkDetail } from '.';
import moment from 'moment';
import classnames from 'classnames';
import Big from "big.js";
import { formatYen } from '../../config/util';
import { EditMemoPopup } from '../../components/parts/edit-memo-popup';
import { RequiredNumInput } from '../../components/parts/num-input';
import { CowToDispInfo } from '../../components/parts/cows-popup';
import { V3DateTime } from '../../components/parts/v3-date-time-picker';
import { LMT, TIMEPRESETS } from '../../config/constant';
import { IUser } from '../../stores';

const DetailRow: React.FC<{
    detail           : SellMilkDetail,
    index            : number,
    detailChange     : (idx:number, detail: SellMilkDetail) => void,
    showEditMemoPopup: (index : number, detail: SellMilkDetail) => void,
}> = ({ detail, index, detailChange, showEditMemoPopup}) => {

    const rowClass = "form-group row treat-write-row";
    const rowHeaderClass = "col-form-label col-md-3 col-xs-3 text-lg-right";

    return (
        <div className={classnames(rowClass, styles["detail-row"])}>
            <label className={rowHeaderClass}>{ CowToDispInfo({...detail, trace_id: detail.trace_id ?? "", cow_id: detail.cow_id ?? 0}, false) }</label>
            <RequiredNumInput className={baseStyles["number-input"]}
                min={LMT.SELLMILK.AMOUNT_COW_MIN} max={LMT.SELLMILK.AMOUNT_COW_MAX}
                step={LMT.SELLMILK.AMOUNT_COW_STEP}
                value={detail.amount}
                onChange={v => detailChange(index, { ...detail, amount: v})}
            />
            <label className={classnames("col-form-label", styles["label-unit"])}>kg</label>
            <div className="checkbox checkbox-css p-0">
                <input type="checkbox" id={`is_discard${index}`} checked={detail.is_discard}
                    onChange={e => detailChange(index, { ...detail, is_discard: e.target.checked})} />
                <label htmlFor={`is_discard${index}`}>廃棄する</label>
            </div>
            <div className={classnames("link", styles["link-memo"])} onClick={() => showEditMemoPopup(index, detail)}>メモ</div>
        </div>
    )
}

type PropType = {
  cowIds: number[],
  sellMilk: SellMilk,
  setSellMilk: React.Dispatch<React.SetStateAction<SellMilk>>,
  user: IUser
}

type TotalInfo = {
    amount: number;
    price: number;
    count: number;
}

const RegisterCows: React.FC<PropType> = ({ cowIds, sellMilk, setSellMilk, user }) => {

    const [editingDetail, setEditingDetail] = useState<{index: number, detail: SellMilkDetail}|undefined>()
    const [totalSell, setTotalSell] = useState<TotalInfo>({amount: 0, price: 0, count: 0})
    const [totalDiscard, setTotalDiscard] = useState<TotalInfo>({amount: 0, price: 0, count: 0})

    useEffect(() => {
        setTotalSell({...totalSell, price: calcPrice(sellMilk.unit_price, totalSell.amount)})
        setTotalDiscard({...totalDiscard, price: calcPrice(sellMilk.unit_price, totalDiscard.amount)})
    }, [sellMilk.unit_price])

    const calcPrice = (unit_price:number, amount:number) => {
        if ( !unit_price || !amount ) return 0;
        const b_unit_price = Big(unit_price)
        const b_amount = Big(amount)
        return parseInt(Number(b_unit_price.times(b_amount).round(0,1)).toString()) // 小数第一位で四捨五入
    }

    const detailChange = (idx:number, detail: SellMilkDetail) => {
        const details = [
            ...sellMilk.details.slice(0, idx),
            detail,
            ...sellMilk.details.slice(idx + 1)
        ]
        setSellMilk({ ...sellMilk, details})
        const tmpTotalSell = {amount: 0, price: 0, count: 0}
        details.filter(d => !d.is_discard).forEach(d => {
            tmpTotalSell.amount = tmpTotalSell.amount + d.amount
            tmpTotalSell.price = tmpTotalSell.price + calcPrice(sellMilk.unit_price, d.amount)
            tmpTotalSell.count = tmpTotalSell.count + 1
        })
        setTotalSell(tmpTotalSell)
        const tmpTotalDiscard = {amount: 0, price: 0, count: 0}
        details.filter(d => d.is_discard).forEach(d => {
            tmpTotalDiscard.amount = tmpTotalDiscard.amount + d.amount
            tmpTotalDiscard.price = tmpTotalDiscard.price + calcPrice(sellMilk.unit_price, d.amount)
            tmpTotalDiscard.count = tmpTotalDiscard.count + 1
        })
        setTotalDiscard(tmpTotalDiscard)
        setEditingDetail(undefined)
    }

    const showEditMemoPopup = (index : number, detail: SellMilkDetail) => {
        setEditingDetail({ index, detail })
    }

    const rowClass = "form-group row treat-write-row";
    const rowHeaderClass = "col-form-label col-md-3 col-xs-3 text-lg-right";

    return (<>
        <div className="page-root">
            <div className="product product-full-height">
                <div className="product-detail" style={{ height: "100%" }}>
                    <div className="product-info product-info-fix" style={{ paddingBottom: "0px" }} >
                        <div className={baseStyles["sellmilk-write"]}>
                            <div className={rowClass}>
                                <label className={rowHeaderClass}>記録日時</label>
                                <div className={baseStyles["date-input"]}>
                                    <V3DateTime value={moment(sellMilk.watched_at).toDate()}
                                        timePresets={user.setting?.time_preset ?? TIMEPRESETS}
                                        onChange={d => setSellMilk({ ...sellMilk, watched_at: d.format("YYYY-MM-DD HH:mm") + ":00"})}
                                    />
                                </div>
                            </div>
                            <div className={rowClass}>
                                <label className={rowHeaderClass}>乳価</label>
                                <RequiredNumInput className={baseStyles["number-input"]}
                                    min={LMT.SELLMILK.UNIT_PRICE_MIN} max={LMT.SELLMILK.UNIT_PRICE_MAX}
                                    step={LMT.SELLMILK.UNIT_PRICE_STEP}
                                    value={sellMilk.unit_price}
                                    onChange={(v) => setSellMilk({ ...sellMilk, unit_price: v})}
                                />
                                <label className={classnames("col-form-label", baseStyles["label-unit"])}>円/kg</label>
                            </div>
                            <div className={styles["label-detail-header"]}>
                                <label className={"col-form-label"}>{`${sellMilk.details.length}頭表示 / ${cowIds.length}頭中`}</label>
                            </div>
                            <hr className={styles["hr-border"]}/>
                            { sellMilk.details.map((d, i) => 
                                <DetailRow key={i} index={i} detail={d} detailChange={detailChange} showEditMemoPopup={showEditMemoPopup} />
                            ) }
                            <hr className={baseStyles["hr-border"]}/>
                            <div className={rowClass}>
                                <label className={rowHeaderClass}>売上合計</label>
                                <label className={classnames("col-form-label", styles["label-total-amount"])}>{formatYen(totalSell.amount)}kg</label>
                                <label className={classnames("col-form-label", styles["label-total-price"])}>{formatYen(totalSell.price)}円</label>
                                <label className={classnames("col-form-label", styles["label-total-count"])}>{formatYen(totalSell.count)}頭</label>
                            </div>
                            <div className={rowClass}>
                                <label className={rowHeaderClass}>廃棄合計</label>
                                <label className={classnames("col-form-label", styles["label-total-amount"])}>{formatYen(totalDiscard.amount)}kg</label>
                                <label className={classnames("col-form-label", styles["label-total-price"])}>{formatYen(totalDiscard.price)}円</label>
                                <label className={classnames("col-form-label", styles["label-total-count"])}>{formatYen(totalDiscard.count)}頭</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <EditMemoPopup
            maxLength={LMT.SELLMILK.MEMO_LEN}
            comment={ editingDetail?.detail?.comment ?? "" }
            isOpen={ editingDetail != null }
            close={ () => setEditingDetail(undefined) }
            onSubmit={ val => detailChange(editingDetail!.index, { ...editingDetail!.detail, comment: val }) }
        />

    </>)
}

export default RegisterCows