import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { Checkbox } from '../../components/form/form-checkbox';
import { ModalInputBlock } from '../../components/parts/modal-input-block';

export interface CertDiseaseListCondition {
    hasBreeding: boolean;
    hasSymptom: boolean;
    firstOnly: boolean;
}

interface MyProps {
    condition: CertDiseaseListCondition;
    onClose: () => void;
    onSubmit: (condition: CertDiseaseListCondition) => void;
}

export const CertDiseaseConditionSelectPopup = (props: MyProps) => {
    const [ data, setData ] = useState<CertDiseaseListCondition>({ hasSymptom:false, hasBreeding:false, firstOnly:false });

    useEffect(() => {
        setData(props.condition);

    }, [ props.condition ])

    const isValid = data.hasSymptom || data.hasBreeding;

    return (
        <div>
            <Modal isOpen={true} scrollable={true}>
                <ModalHeader toggle={props.onClose}>検索条件選択</ModalHeader>
                <ModalBody>
                    <ModalInputBlock label="検索対象">
                        <Checkbox label="診療記録" id="chkSymptom" checked={data.hasSymptom}
                            onChange={e => setData({ ...data, hasSymptom:e.target.checked })}
                        />
                        <Checkbox label="検診記録" id="chkBreeding" checked={data.hasBreeding}
                            onChange={e => setData({ ...data, hasBreeding:e.target.checked })}
                        />
                    </ModalInputBlock>
                    <ModalInputBlock label="">
                        <Checkbox label="初回の記録のみ取得" id="chkFirst" checked={data.firstOnly}
                            onChange={e => setData({ ...data, firstOnly:e.target.checked })}
                        />
                    </ModalInputBlock>
                </ModalBody>
                <ModalFooter className="modal-footer-fix">
                    <ExecutionButton type="decide" disabled={!isValid} onClick={() => props.onSubmit(data)} />
                </ModalFooter>
            </Modal>
        </div>
    )

}