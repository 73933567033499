import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { PageSettings } from '../../config/page-settings.js';
import { withContext } from '../../stores';
import { MenuGroup } from './menu-group';
import { RanchAuthItemKey, ClinicAuthItemKey, hasRanchAuth, hasClinicAuth } from '../../config/auth-checker';
import { AppState } from '../../app';
import { historyLocation } from '../../config/history-location-builder';
import { CommonUtil } from '../../config/util';
import { MenuChildrenPopup } from './menu-children-popup';
import { RanchContractItemKey, hasRanchContract } from '../../config/contract-checker';
import { UserTeams } from '../../config/user-teams';

interface MenuItem {
    name: string;
    location?: (teamId: number) => ({ pathname: string, search?: string, state?:any });
}
interface IRanchMenu extends MenuItem {
    rauth: RanchAuthItemKey[];
    rcontract?: RanchContractItemKey[];
    officialOnly?: boolean;
    children?: Omit<IRanchMenu, "children">[];
}
interface IClinicMenu extends MenuItem {
    cauth: ClinicAuthItemKey[];
    children?: Required<Omit<IClinicMenu, "children">>[];
}

const ranch_menu_list: {[id:string]:IRanchMenu} = {
    "r-setting-feed": { name: "えさ品目の設定", rauth: [ "MASTER_EDIT" ], location: (id => ({pathname: "/setting/feed/" + id })), officialOnly:true },
    "r-setting-med":  { name: "治療薬・予防薬の設定", rauth: [ "MASTER_EDIT" ], location: (id => ({pathname: "/setting/medicine/" + id })), officialOnly:true },
    "r-setting-treat":{ name: "処置の設定",  rauth: [ "MASTER_EDIT" ], location: (id => ({pathname: "/setting/treat/" + id })), officialOnly:true },
    "r-setting-seed": { name: "精液・受精卵の管理", rauth: [ "MASTER_EDIT" ], location: (id => historyLocation.toSeedSetting(id)), rcontract: [ "BREEDING" ], officialOnly:true },
    "r-setting-house": { name: "場所の設定", rauth: [ "MASTER_EDIT" ], location: (id => historyLocation.toRanchHouseSettingEdit(id)), officialOnly:true },
    "r-setting-program": { name: "プログラムの管理", rauth: [ "MASTER_EDIT" ], location: (id => historyLocation.toProgramSetting(id))},
    "r-setting-preset": { name: "処置プリセットの管理", rauth: [ "MASTER_EDIT" ], location: (id => historyLocation.toPresetSetting(id)), officialOnly:true },
};

const clinic_menu_list: {[id:string]:IClinicMenu} = {
    "c-setting-med": { name: "治療薬・予防薬の設定", cauth: [ "MASTER_EDIT" ], location: (id => ({pathname: "/setting/medicine/" + id })) },
    "c-setting-treat": { name: "処置の設定",        cauth: [ "MASTER_EDIT" ], location: (id => ({pathname: "/setting/treat/" + id })) },
    "c-setting-seed": { name: "精液・受精卵の管理", cauth: [ "MASTER_EDIT" ], location: (id => historyLocation.toSeedSetting(id))},
    "c-setting-medroute": { name: "投薬経路の設定", cauth: [ "MASTER_EDIT" ], location: (id => ({pathname: "/setting/medicine_route/" + id })) },
    "c-setting-cond": { name: "症状の設定",         cauth: [ "MASTER_EDIT" ], location: (id => ({pathname: "/setting/condition/" + id })) },
    "c-setting-disease": { name: "診断項目の管理", cauth: [ "MASTER_EDIT" ], location: (id => historyLocation.toDiseaseSetting(id)) },
    "c-setting-program": { name: "プログラムの管理", cauth: [ "MASTER_EDIT" ], location: (id => historyLocation.toProgramSetting(id))},
    "c-setting-preset": { name: "処置プリセットの管理", cauth: [ "MASTER_EDIT" ], location: (id => historyLocation.toPresetSetting(id))},
    "c-setting-visiting": { name: "往診先リスト管理", cauth: [ "MASTER_EDIT" ], location: (id => historyLocation.toVisitingRanchSetting(id))},
    "c-setting-visit-fee": { name: "診療固定費の設定", cauth: [ "MASTER_EDIT" ], location: (id => historyLocation.toVisitFeeSetting(id))},
};

const ranch_user_menu_list: {[id:string]:IRanchMenu} = {
    "r-auth": { name: "ユーザ権限の設定",  rauth: [ "USER_MANAGEMENT" ], location: (id => historyLocation.toTeamAuthority(id)), officialOnly:true },
};

const clinic_user_menu_list: {[id:string]:IClinicMenu} = {
    "c-auth": { name: "ユーザ権限の設定", cauth: [ "USER_MANAGEMENT" ], location: (id => historyLocation.toTeamAuthority(id)) },
};

const ranch_config_menu_list: {[id:string]:IRanchMenu} = {
    "r-setting-team":     { name: "基本情報設定", rauth: ["MASTER_EDIT"], location: (id => historyLocation.toTeamInfo(id)) },
    "r-setting-const":    { name: "牧場計画値の設定", rauth: [ "MASTER_EDIT" ], location: (id => historyLocation.toRanchConst(id)), officialOnly:true },
    "r-setting-dynamics": { name: "動態目標の設定", rauth: ["MASTER_EDIT" ], location: (id => historyLocation.toSettingDynamics(id)), officialOnly:true },
    "r-setting-other":    { name: "その他設定", rauth: ["MASTER_EDIT"], location:(id => historyLocation.toOtherSetting(id))},
}
const clinic_config_menu_list: {[id:string]:IClinicMenu} = {
    "c-setting-team":     { name: "基本情報設定", cauth: ["MASTER_EDIT"], location: (id => historyLocation.toTeamInfo(id)) },
}


interface MyState {
    team_id: number;
    isRanch: boolean;
    isOfficial: boolean;
    name: string;
    currentChildren?: MenuItem[];
}

class MenuMain extends Base<BaseProps<{id:string}>, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            // data
            team_id: 0,
            isRanch: true,
            isOfficial: true,
            name: "",
        }

        this.onMenu = this.onMenu.bind(this);
    }

    componentDidMount() {

        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        this.init();
    }

    componentDidUpdate(prevProps: this["props"]) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.init();
            return;
        }
    }

    private init() {
        const teamId = parseInt(this.props.match.params.id ?? "");
        if (isNaN(teamId)) return;

        const userTeams = new UserTeams(this.props.rootStore.user);

        const team = userTeams.findTeam(teamId);
        if (team == null) return;

        const isOfficial = userTeams.findOfficialTeam(teamId) != null;

        this.setState({
            team_id: teamId,
            isRanch: team.isRanch,
            isOfficial,
            name: team.name,
        }, () => {
            this.context.handleSetHeader({
                backBtn:false,
                title: this.state.isRanch ? "牧場設定" : "診療所設定",
                iconType: this.state.isRanch ? "ranch" : "clinic"
            });
        });

    }

    onMenu(id: string, forRanch: {[id:string]:IRanchMenu}, forClinic: {[id:string]:IClinicMenu}) {
        const menu = this.state.isRanch ? forRanch[id] : forClinic[id];
        if (!CommonUtil.assertNotNull(menu)) return;

        if (menu.location != null) {
            this.props.history.push(menu.location(this.state.team_id));
            return;
        }

        if (menu.children == null) return;
        const children: Array<IRanchMenu|IClinicMenu> = menu.children;

        this.setState({
            currentChildren: children.filter(ch => this.isVisible(ch))
        });
    }
    onChildSelect(idx:number) {
        if (!CommonUtil.assertNotNull(this.state.currentChildren)) return;
        const child = this.state.currentChildren[idx];

        if (child.location != null) {
            this.props.history.push(child.location(this.state.team_id));
        }
    }

    isVisible(menu: IRanchMenu|IClinicMenu) {
        const user = this.props.rootStore.user;

        if ("rauth" in menu) {
            if (menu.rauth.some(a => !hasRanchAuth(a, this.state.team_id, user))) return false;
            if (menu.rcontract != null && menu.rcontract.some(a => !hasRanchContract(a, this.state.team_id, user))) return false;
            if (menu.officialOnly && !this.state.isOfficial) return false;
            if (menu.children == null) return true;
            //子メニューがある場合、1つ以上権限を満たすメニューがあること
            return menu.children.some(ch => ch.rauth.every(a => hasRanchAuth(a, this.state.team_id, user)));

        } else {
            if (menu.cauth.some(a => !hasClinicAuth(a, this.state.team_id, user))) return false;
            if (menu.children == null) return true;
            //子メニューがある場合、権限を満たすメニューが1つ以上あること
            return menu.children.some(ch => ch.cauth.every(a => hasClinicAuth(a, this.state.team_id, user)));
        }

    }

    buildMenu = (forRanch: {[id:string]:IRanchMenu}, forClinic: {[id:string]:IClinicMenu}) => {
        
        if (this.state.isRanch) {
            return Object.keys(forRanch)
                .filter(k => this.isVisible(forRanch[k]))
                .map(k => ({ id: k, name: forRanch[k].name }));
        } else {
            return Object.keys(forClinic)
                .filter(k => this.isVisible(forClinic[k]))
                .map(k => ({ id: k, name: forClinic[k].name }));
        }
    }

    render() {
        if (this.state.team_id === 0) return <></>;

        return (
            <div style={{ height: "100%", "display": "flex", flexFlow: "column nowrap" }}>
                <div className="product" style={{ height: "100%", minHeight: "0" }}>
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix">
                            {/* <!-- BEGIN content --> */}
                            <div id="ranch_main" className="ranch-main" style={{"flex": "auto"}}>
                                <MenuGroup
                                    groupName={`${this.state.isRanch ? "牧場" : "診療所"}固有リスト管理`}
                                    menus={this.buildMenu(ranch_menu_list, clinic_menu_list)}
                                    onSelect={id => this.onMenu(id, ranch_menu_list, clinic_menu_list)}
                                />
                                <MenuGroup
                                    groupName="ユーザ管理"
                                    menus={this.buildMenu(ranch_user_menu_list, clinic_user_menu_list)}
                                    onSelect={id => this.onMenu(id, ranch_user_menu_list, clinic_user_menu_list)}
                                />
                                <MenuGroup
                                    groupName={`${this.state.isRanch ? "牧場" : "診療所"}設定`}
                                    menus={this.buildMenu(ranch_config_menu_list, clinic_config_menu_list)}
                                    onSelect={id => this.onMenu(id, ranch_config_menu_list, clinic_config_menu_list)}
                                />
                            </div>
                            {/* <!-- END content --> */}
                        </div>
                    </div>
                </div>
                { this.state.currentChildren != null && (
                    <MenuChildrenPopup
                        onClose={() => this.setState({currentChildren:undefined})}
                        menu={this.state.currentChildren.map(c => c.name)}
                        onSelect={i => this.onChildSelect(i)}
                     />
                )}
            </div>
        )
    }
}

export default withRouter(withContext(MenuMain));