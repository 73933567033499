import React, { useRef } from 'react';
import { SymptomStatus } from './symptom-status';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { getSymptomStatus, SYMPTOM_STATUS } from '../../config/constant';
import styles from './symptom.module.css';
import { NextScheduleSelector, NextSchedule, nextScheduleDefaultTitleFormatter } from '../../components/next-schedule/next-schedule-selector';
import moment from 'moment';
import { EVENT_KIND } from '../../config/event-kind';
import { FadeInPanel } from '../../components/parts/fadein-panel';

interface MyProps {
    status: Readonly<{ isSelected: boolean, value: number }>;
    isAutoScrollDisabled: boolean;
    onScrollTriedWhenDisabled?: () => void;
    baseDate: Date;
    plans: Readonly<Readonly<{ isSelected: boolean, schedule: NextSchedule }>[]>;
    onStatusChange: (status: { isSelected: boolean, value: number }) => void;
    onPlanChange: (plans: Array<{ isSelected: boolean, schedule: NextSchedule }>) => void;
    portalContainerID?: string;
    presetTeamId: number;
    ranchId: number;
    isEpidemic: boolean;
}

export const canNextSchedule = (status: number|undefined) => status != null && getSymptomStatus(status)?.hasNext === true;

export const defaultSchedule = (baseDate: Date, status: number|undefined): NextSchedule => {
    const date = moment(baseDate).add(1, "day").toDate();
    return { title: nextScheduleDefaultTitle(status, "再診"), start: date, end: date, note: "", allDay: true, eventKindNo: EVENT_KIND.SYMPTOM.no, preset:undefined };
}

const nextScheduleDefaultTitle = (status: number|undefined, candidate:string|undefined) => {
    const symptom_status = status ?? SYMPTOM_STATUS.UNDER_TREATMENT.no;
    const defaultTitlePrefix = getSymptomStatus(symptom_status)?.name;
    return nextScheduleDefaultTitleFormatter(defaultTitlePrefix, candidate);
}

export const SymptomStatusAndScheduleInput = (props: MyProps) => {

    const refBottom = useRef<HTMLDivElement>(null);

    const onStatusSelected = (selected: boolean, value: number) => {
        const prevTitleCandidate = getSymptomStatus(props.status.value)?.name;
        const convertTitleCandidate = (title:string) => { 
            if (!prevTitleCandidate) return title;
            return title.startsWith(prevTitleCandidate) ? title.replace(prevTitleCandidate, getSymptomStatus(value)?.name ?? "") : title;
        }
        props.onStatusChange({ isSelected: selected, value });
        props.onPlanChange(props.plans.map(p => ({ ...p, schedule: { ...p.schedule, title: convertTitleCandidate(p.schedule.title) } })));
    }

    const onAddNextSchedule = () => {
        const newSchedule = defaultSchedule(props.baseDate, props.status.value);
        props.onPlanChange([
            ...props.plans,
            { isSelected: true, schedule: newSchedule },
        ])
    }

    const canTitleCandidateSelect = (candidate:string|undefined, index: number) => {
        if(candidate == null || candidate !== "継続") return true;
        const title = nextScheduleDefaultTitle(props.status.value, candidate);
        if(!props.plans.some(p => p.schedule.title === title)) return true;
        return props.plans.some((p, i) => p.schedule.title === title && i === index);
    }

    return (<>
        <SymptomStatus
            isSelected={props.status.isSelected}
            value={props.status.value}
            onChange={onStatusSelected}
            isEpidemic={props.isEpidemic}
            pregOnly={false}
        />

        <FadeInPanel
            onEntering={() => {
                if (props.isAutoScrollDisabled) {
                    if (props.onScrollTriedWhenDisabled) {
                        props.onScrollTriedWhenDisabled();
                    }
                    return;
                }
                refBottom.current?.scrollIntoView({ behavior: 'smooth', block:"end" });
            }}
            isVisible={props.status.isSelected && canNextSchedule(props.status.value)}
        >
            <div>
                <hr className={styles["hr-border"]} />
                <TransitionGroup>
                { props.plans.map((plan, i) => (
                    <CSSTransition classNames={{ enter: styles["plan-enter"], enterActive:styles["plan-enter-active"] }} key={i} timeout={500}>
                        <div>
                            <NextScheduleSelector
                                index={i}
                                isSelected={plan.isSelected}
                                baseDate={props.baseDate}
                                defaultTitlePrefix={canNextSchedule(props.status.value) ? getSymptomStatus(props.status.value!)?.name : ""}
                                titleCandidates={["継続","再診","再検査"]}
                                value={plan.schedule}
                                onChange={(selected,val) => 
                                    props.onPlanChange([
                                    ...props.plans.slice(0, i),
                                    { isSelected: selected, schedule: val },
                                    ...props.plans.slice(i+1)
                                ]) }
                                canTitleCandidateSelect={canTitleCandidateSelect}
                                portalContainerIDForDatePicker={props.portalContainerID}
                                presetTeamId={props.presetTeamId}
                                ranchId={props.ranchId}
                            />
                        </div>

                    </CSSTransition>
                ))}
                </TransitionGroup>

                <div className="form-group row"
                    style={{ marginLeft: "8px", marginRight: "0px", marginTop: "10px" }}>
                    <button style={{ flex: "1" }} className="btn btn-theme btn-black"
                        onClick={() => onAddNextSchedule()}>
                        <i className="fas fa-lg fa-fw m-r-10 fa-plus-circle"></i>
                        予定を追加
                    </button>
                </div>
            </div>
        </FadeInPanel>

        <div ref={refBottom} />
    </>);
}