import React from 'react';
import styles from './hour-span-selector.module.css';

//[0,1,2,...,30]
export const HOURS = [...Array(30).keys()] as const;
export type Hour = typeof HOURS[number];

export interface HourSpanSelectorProps {
    value: Readonly<HourSpan>;
    onChange: (value: HourSpan) => void;
    min: Hour;
    max: Hour;
    containerStyle?: React.CSSProperties;
}

export interface HourSpan {
    from: Hour;
    to: Hour;
}

export const HourSpanSelector: React.FC<HourSpanSelectorProps> = (props) => {

    const onChange = (isFrom: boolean, value: string) => {
        const h = parseInt(value) as Hour;
        let from = isFrom ? h : props.value.from;
        let to = isFrom ? props.value.to : h;
        if (to < from) {
            if (isFrom) {
                to = from;
            } else {
                from = to;
            }
        }
        props.onChange({ from, to });
    }

    const hours = HOURS.slice(props.min, props.max + 1);

    const renderSelect = (isFrom: boolean) => (
        <select className={"form-control " + styles.select}
            value={isFrom ? props.value.from : props.value.to }
            onChange={e => onChange(isFrom, e.target.value)}>
            { hours.map(h => (
                <option key={h} value={h}>{h}</option>
            ))}
        </select>
    );

    return (
        <div style={{ display:"flex", alignItems:"center", ...props.containerStyle }}>
            { renderSelect(true) }
            <span style={{ margin:"0 2px" }}>～</span>
            { renderSelect(false) }
            <span style={{ marginLeft: "2px" }}>時</span>
        </div>
    );
}