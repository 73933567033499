import classnames from 'classnames';
import React from 'react';
import { FeedingGraph } from './feeding-graph';
import { GrowthGraph } from './growth-graph';
import { withRouter } from 'react-router-dom';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import Base, { BaseProps } from '../../components/content/base';
import { A, BREEDING_STATES, BREEDING_STATE, COW_SELL_TYPES, COW_SELL_TYPE, DEFECTS, hasDefect, TAG_SEARCH_SYMBOL, EVENT_TYPE_PARAM } from '../../config/constant';
import { PageSettings } from '../../config/page-settings.js';
import { CommonUtil, formatYen, ar, calcAge, saveTextFile, AGE_MODE, calcDg, FreezedArray } from '../../config/util';
import { CowNotePopup } from './cow-note-popup';
import { withContext, IRanchHouse, IRanchFeed } from '../../stores';
import styles from './cow.module.css';
import { CowDetailDto, RanchApi, BreedingApi, DeliveryApi, RanchTagDto, CowApi, CowBreedingModifyReq, SingleEventReq, SellCowApi, SellMilkApi, GrowthStandardDto, GrowthApi, GrowthGraphReq, CowEventHistoryReq, FeedAmoutHistReq, BreedingCrossCurrentDto, FeedAmountHistDto, CowEventListDto, OtherPlApi, CowEventHistoryDto, FeedDayAmountDao } from '../../api';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import "@fullcalendar/core/main.css";
import "@fullcalendar/list/main.css";
import { Terms, Term } from '../schedule/Term';
import { RanchScheduleDto } from '../../api';
import { ISchedule, SchedulePopup, EventDtoToISchedule } from '../schedule/schedule-popup';
import moment from 'moment';
import { Collapse } from 'reactstrap';
import { IRut, IBreeding, IDelivery, IDeliveryHis, CrossHistory } from './cow-event';
import { ActionIconBar, CertType } from '../../components/parts/action-icon-bar';
import { WatchingIcon, ICowWatchingInfo } from './watching-icon';
import { CowTagEditor } from './cow-tag-editor';
import { historyLocation, IEventWriteParamCow, IEventWriteLocationState } from '../../config/history-location-builder';
import { LinkParam } from '../top/Dashboard';
import { BreedingStatePopup } from './breeding-state-popup';
import { DEFAULT_OPEN_DAYS_AFTER_DELIVERY, confirmBreedingStateChange, BREEDING_STATE_CONFIRMED } from '../../components/parts/breeding-state-selector';
import { AppState } from '../../app';
import { TextModal, TextModalContent } from '../../components/parts/text-modal';
import { hasRanchAuth } from '../../config/auth-checker';
import { EventKind, EVENT_KIND } from '../../config/event-kind';
import { CowToDispInfo, RenderCowNameInfo } from '../../components/parts/cows-popup';
import { WashoutIcon } from '../../components/parts/washout-icon';
import { CalfIcon } from '../../components/parts/calf-icon';
import { Washouts } from '../../config/Washout';
import { isBrowser } from 'react-device-detect';
import { LoadMore } from '../../components/parts/load-more';
import { MultiFilter } from '../../components/parts/multi-filter';
import { buildLocationName } from '../ranch/cow-list-table';
import { FormRadio } from '../../components/form/form-radio';
import { SwipeToNextPanel } from '../../components/panel/swipe-to-next-panel';
import { EditingTag } from '../../components/tag-edit-popup/tag-edit-popup';
import { ACTIVITY_KIND } from '../../config/activity-kind';
import { UserTeams } from '../../config/user-teams';
import { DailyEventData, CowEventHisList } from './cow-event-his-list';
import { getRanchHouses, getRanchFeeds } from '../../stores/fetcher';
import { FetchWaiter, FetchError } from '../../components/content/fetch-state';
import { SellMilkGraphContainer } from './sell-milk-graph';
import { EventApi, View } from '@fullcalendar/core';
import { GraphPager } from 'components/parts/graph-pager';
import { resetCow } from 'stores/fetcher_cow';

function getCowIdFromPath(path) {
    let root_path = "/cow/info";
    if (path.length < root_path.length) {
        return 0;
    }

    let id = path.substring(root_path.length);
    if (id.length > 1) {
        id = id.substring(1);
        console.log(id);
    } else {
        return 0;
    }
    return parseInt(id);
}

const EVENTS_PER_PAGE = 30;

const FEED_CHART_DAYS_PER_PAGE = 31;
export const SELL_MILK_CHART_DAYS_PER_PAGE = 30; 

const GRAPH_KINDS = ["feed", "weight", "milk"] as const;
type GraphKindKey = typeof GRAPH_KINDS[number];
interface IGraphKind {
    name: string;
    growthScoreType?: number;
    milkUseOnly?: boolean;
}
const GRAPH_KIND: { [key in GraphKindKey]: IGraphKind } = {
    feed:   { name: "えさ" },
    weight: { name: "体重", growthScoreType: 1 },
    milk:   { name: "乳量", milkUseOnly:true }
};

type IHisCom = CowEventHistoryDto;
interface IHisTreatCom {
    team_id: number;
    amount?: number;
    medicine_id?: number;
    medicine_route_name?: string;
    note?: string;
    route_id?: number;
    treat_item_name?: string;
    treat_item_no?: number;
    treat_item_unit?: string;
    treat_kind_no?: number;
    unit_price?: number;
}
interface IHisPreventionDetail extends IHisTreatCom {
    prevention_id: number;
    detail_no: number;
}
interface IHisSymptomTreat extends IHisTreatCom {
    symptom_id: number;
    treat_no: number;
}
export interface IHisFeed extends IHisCom {
    feed_no: number;
    feed_type_no: number;
    delivered: number;
    leftover?: number;
}
export interface IHisSymptom extends IHisCom {
    symptom_id: number;
    first_id?: number;
    treat: Array<IHisSymptomTreat>;
    feces_color?: string;
    feces_state?: number;
    active_score?: number;
    breath_count?: number;
    breath_score?: number;
    heart_rate?: number;
    hungry_score?: number;
    temperature_x10?: number;
    symptom_name: string;
}
interface IHisPrevention extends IHisCom {
    prevention_id: number;
    prevention_detail: Array<IHisPreventionDetail>;
}
export interface IHisLocation extends IHisCom {
    location_id: number;
    room: number;
    site: number;
    barn: number;
}
export type IHisRut = Omit<IRut,"watched_at"|"comment"|"watched_type"> & IHisCom;
export type IHisBreeding = Omit<IBreeding, "watched_at"|"comment"|"cross"|"pregnant_note"|"bcs_note"|"vulva_note"|"vagina_note"|"canal_note"|"cornu_note"|"ovary_note"> & IHisCom & {
    cross?: Readonly<IHisBreedingCross>;
}
export interface IHisBreedingCross {
    cross_no: number;
    cross_type : number;
    seed_lot_id? : number;
    father_name?: string;
    seed_name?: string;
    seed_sname?: string;
    seed_ancestor_1?: string;
    seed_ancestor_2?: string;
    seed_ancestor_3?: string;
    seed_ancestor_4?: string;
    mother_id?: number;
    mother_name?: string;
    ancestor_2?: string;
    ancestor_3?: string;
}
export type IHisDelivery = Omit<IDelivery, "watched_at"|"comment"|"cross_no"|"children"> & IHisCom & {
    children: Readonly<IHisDeliveryCow[]>;
}
export interface IHisDeliveryCow {
    child_no: number;
    life_status: number;
    is_male: 0 | 1;
    weight_kg?: number;
}
export interface IHisSellCow extends IHisCom {
    sell_type: number;
    price: number;
    comment: string;
}
export interface IHisCowSellMilk extends IHisCom {
    unit_price: number;
    amount: number;
    amount_discard: number;
    comment: string;    
}

export interface IHisOtherProfitCow extends IHisCom {
    comment: string;
    price: number;
}
export interface IHisOtherLossCow extends IHisCom {
    comment: string;
    price: number;
}

export interface IHisGrowth extends IHisCom {
    weight?: number;
    height?: number;
    chest?: number;
    abdomen?: number;
    bcs?: number;
    rfs?: number;
}

export interface IEggRankAndCount {
    egg_rank: number;
    egg_count: number;
}

export interface IOvumRankAndCount {
    ovum_rank: number;
    egg_count: number;
}

export interface IHisSov extends IHisCom {
    father_exid?: number;
    father_name?: string;
    ranks: IEggRankAndCount[];
}
export interface IHisOpu extends IHisCom {
    ranks: IOvumRankAndCount[];
}
export interface IHisIvf extends IHisCom {
    father_exid?: number;
    father_name?: string;
    ranks: IOvumRankAndCount[];
}
export interface IHisIvfEgg extends IHisCom {
    ranks: IEggRankAndCount[];
}

export const isCowSellMilk = (event: EventData): event is IHisCowSellMilk => {
    return event.event_kind_no === EVENT_KIND.SELL_MILK_COW.no;
}
export const isSellCow = (event: EventData): event is IHisSellCow => {
    return event.event_kind_no === EVENT_KIND.SELL_COW.no;
}
export const isOtherProfitCow = (event: EventData): event is IHisOtherProfitCow => {
    return event.event_kind_no === EVENT_KIND.OTHER_PROFIT_COW.no;
}
export const isOtherLossCow = (event: EventData): event is IHisOtherLossCow => {
    return event.event_kind_no === EVENT_KIND.OTHER_LOSS_COW.no;
}
export const isGrowth = (event: EventData): event is IHisGrowth => {
    return event.event_kind_no === EVENT_KIND.GROWTH.no;
}
export const isSov = (event: EventData): event is IHisSov => {
    return event.event_kind_no === EVENT_KIND.SOV.no;
}
export const isOpu = (event: EventData): event is IHisOpu => {
    return event.event_kind_no === EVENT_KIND.OPU.no;
}
export const isIvf = (event: EventData): event is IHisIvf => {
    return event.event_kind_no === EVENT_KIND.IVF.no;
}
export const isIvfEgg = (event: EventData): event is IHisIvfEgg => {
    return event.event_kind_no === EVENT_KIND.IVF_EGG.no;
}
export type EventData
    = IHisFeed | IHisSymptom | IHisPrevention
        | IHisLocation | IHisRut | IHisBreeding | IHisDelivery
        | IHisSellCow | IHisCowSellMilk | IHisGrowth
        | IHisSov | IHisOpu | IHisIvf | IHisIvfEgg;

type IEvent = ISchedule & {
    dotColor: string;
}

interface GrowthData {
    scoreTypeNo: number;
    standards: GrowthStandardDto[];
    actuals: { date: string, score: number}[];
}

interface MyState {
    initStatus: "ready"|"loading"|"error";
    activeTab: "1" | "2" | "3";
    cur_cow_id?: number;
    intial_start_day: string;
    today: Date;
    has_next_feed: boolean;
    has_prev_feed: boolean;
    feed_chart_end_day: string;
    feed_chart_start_day: string;
    popup_note_open: boolean;
    cow: ICowData;
    nextCow?: { cow_id: number, disp: string };
    prevCow?: { cow_id: number, disp: string };
    amount_list: FeedDayAmountDao[];
    feed_amount_list_key: "type"|"item";
    daily_data: DailyEventData[];
    location_sites: FreezedArray<IRanchHouse>;
    ranchFeeds: FreezedArray<IRanchFeed>;
    has_more_events: boolean;
    current_event_page: number;
    eventFilterItems: { name: string, values: number[] }[];
    selectedEventKinds: Set<number>;
    isHeaderOpen: boolean;
    last_cross_day?: Date;
    watchingInfo: Readonly<ICowWatchingInfo>;

    currentGraphKind: GraphKindKey;
    growthData?: Readonly<GrowthData>;

    scheduleEvents: IEvent[];
    loadedScheduleTerms: Terms;
    currentScheduleTerm: Term;
    isSchedulePopupShown: boolean;
    currentScheduleEvent: Partial<ISchedule>;
    isScheduleEditing: boolean;
    isTagEditing: boolean;

    deliveryHistory: IDeliveryHis[];
    crossHistory: CrossHistory[];

    isBreedingStatePopupShown: boolean;
    tmpOpenDay: Date;

    currentEventDetail?: Readonly<{ texts: TextModalContent[], title: string, onClick?:()=>void }>;
    medicineTeamId: number;

    isOfficialRanch: boolean;

    executing: boolean;
}

//APIから生成された型定義との差異を吸収
type ICowData = Omit<CowDetailDto, "event_list"|"mother"|"created_by"|"created_at"|"updated_by"|"updated_at"|"is_deleted"|"surrogate_mother"|"washouts"> & {
    is_male: 0 | 1;
    amount_list: FeedDayAmountDao[];
    event_list: EventData[];
    mother?: {
        cow_id: number,
        trace_id: string,
        local_no: string,
        name: string,
    },
    surrogate_mother?: {
        cow_id: number,
        trace_id: string,
        local_no: string,
        name: string,
    },
    washouts: Washouts,
}

const dtoToEvent = (dto: RanchScheduleDto): IEvent | null => {
    if (dto.event_kind_no == null
        || dto.schedule_id == null
        || dto.start_at == null
        || dto.end_at == null
        || dto.title == null) {

        console.warn("invalid schedule dto", dto);
        return null;
    }

    const convCows = (cows:IEventWriteParamCow[]) => cows.length === 1 ? "" : (CowToDispInfo(cows[0], false) + "他");

    const schedule = EventDtoToISchedule(dto, convCows);
    if (schedule == null) return null;

    const dotColor = schedule.customColor ?? EventKind.find(schedule.eventKindNo)?.schedule?.color ?? "#ffffff";

    const pre = schedule.dispTitle;

    return {
        ...schedule,
        dispTitle: `[${EventKind.find(schedule.eventKindNo)?.schedule?.sname ?? ""}]${pre}`,
        dotColor
    };
};

class CowInfo extends Base<BaseProps<{id:string}>,MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        const eventKinds = [...CommonUtil.groupBy(EventKind.forCowEventHis(this.props.rootStore.cur_ranch_id, this.props.rootStore.user), e => e.group).values()]
                .map(g => ({ values: g.map(e => e.no), name:g[0].group }));
        const selectedEventKinds = new Set(ar.flat(eventKinds.map(e => e.values)));

        this.state = {
            initStatus: "loading",
            activeTab: '1',

            intial_start_day: "",
            today: moment().startOf("day").toDate(),
            has_next_feed: false,
            has_prev_feed: false,
            feed_chart_end_day: "",
            feed_chart_start_day: "",
            popup_note_open: false,

            cow: {
                cow_id: 0,
                ranch_id: 0,
                trace_id: "",                // ID
                local_no: "",                // 牧場耳標
                name: '',                   // 名前
                is_male: 0,                 // 性別
                birthday: "",               // 生年月日
                breed_no: 0,                // 品種
                use_no: 0,                  // 用途
                site: 0,                    // 分場選択
                barn: 0,                    // 牛舎選択
                room: 0,                    // 部屋選択
                ancestor_1: '',             // 一代祖
                ancestor_2: '',             // 二代祖
                ancestor_3: '',             // 三代祖
                is_active: 1,
                mother_id: 0,
                mother_name: "",
                mother: {
                    cow_id: 0,
                    trace_id: "",
                    local_no: "",
                    name: "",
                },
                note: "",
                amount_list:[],
                event_list:[],
                surrogate_mother_id: 0,
                surrogate_mother_name: "",
                surrogate_mother: {
                    cow_id: 0,
                    trace_id: "",
                    local_no: "",
                    name: "",
                },
                watching: 0,
                watching_memo: "",
                tags: [],
                start_kind: A.START_KIND.get("SELF")!.no,
                breeding_state: 0,
                open_day: undefined,
                washouts: new Washouts([]),
            },

            currentGraphKind: "feed",
            amount_list: [],
            feed_amount_list_key: "type",

            daily_data: [],
            location_sites: [],
            ranchFeeds:[],
            has_more_events: false,
            current_event_page: 0,

            watchingInfo: {
                cow_id: 0,
                watching: 0,
                watching_memo: "",
            },

            eventFilterItems: eventKinds,
            selectedEventKinds: selectedEventKinds,
            isHeaderOpen: false,

            scheduleEvents: [],
            loadedScheduleTerms: new Terms(),
            currentScheduleTerm: new Term(new Date(), new Date()),
            isSchedulePopupShown: false,
            currentScheduleEvent: {},
            isScheduleEditing: false,
            isTagEditing: false,

            deliveryHistory: [],
            crossHistory: [],

            isBreedingStatePopupShown: false,
            tmpOpenDay: new Date(),
            medicineTeamId: 0,
            isOfficialRanch: true,
            executing: false
        }

        this.onEventItemClick = this.onEventItemClick.bind(this);
        this.onFeedHistory = this.onFeedHistory.bind(this);
        this.onFeedLeft = this.onFeedLeft.bind(this);
        this.onConditionHeal = this.onConditionHeal.bind(this);
        this.onPrevention = this.onPrevention.bind(this);
        this.onWriteCow = this.onWriteCow.bind(this);
        this.onMoveCow = this.onMoveCow.bind(this);
        this.onRut = this.onRut.bind(this);
        this.onBreeding = this.onBreeding.bind(this);
        this.onDelivery = this.onDelivery.bind(this);
        this.onGraphDayClick = this.onGraphDayClick.bind(this);
        this.onMotherInfo = this.onMotherInfo.bind(this);
        this.onNote = this.onNote.bind(this);
        this.onScheduleClick = this.onScheduleClick.bind(this);
        this.onDatesRender = this.onDatesRender.bind(this);
        this.onAddScheduleClick = this.onAddScheduleClick.bind(this);
        this.onFinishScheduleEdit = this.onFinishScheduleEdit.bind(this);
        this.onStartScheduleEdit = this.onStartScheduleEdit.bind(this);
        this.onSchedulePopupClose = this.onSchedulePopupClose.bind(this);
        this.onScheduleCowClick = this.onScheduleCowClick.bind(this);
        this.onWriteReexamine = this.onWriteReexamine.bind(this);
        this.onSubmitTags = this.onSubmitTags.bind(this);
        this.onScheduleLinkClick = this.onScheduleLinkClick.bind(this);
        this.onSellCowClick = this.onSellCowClick.bind(this);
        this.onBalanceClick = this.onBalanceClick.bind(this);
        this.onSellMilkClick = this.onSellMilkClick.bind(this);
        this.onOtherPlClick = this.onOtherPlClick.bind(this);
        this.onProgramClick = this.onProgramClick.bind(this);
        this.onGrowth = this.onGrowth.bind(this);
        this.onSovClick = this.onSovClick.bind(this);
        this.onOpuClick = this.onOpuClick.bind(this);
        this.onIvfClick = this.onIvfClick.bind(this);
        this.onIvfEggClick = this.onIvfEggClick.bind(this);
        this.onCertClick = this.onCertClick.bind(this);
        this.onShowBreedingStatePopup = this.onShowBreedingStatePopup.bind(this);
        this.onSubmitBreedingState = this.onSubmitBreedingState.bind(this);
        this.onScheduleSplit = this.onScheduleSplit.bind(this);
    }

    componentDidMount() {
        if (this.handleNotAllowAccess(undefined,[],[])) {
            return;
        }

        const cow_id = getCowIdFromPath(this.props.history.location.pathname);
        const nexts = this.props.rootStore.getNextCows(cow_id);

        this.context.handleSetHeader({ title:"牛TOP" });
        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        const ranchId = this.props.rootStore.cur_ranch_id;
        const mTeamId = this.props.rootStore.getClinicIdForMaster() ?? ranchId;
        const isOfficialRanch = new UserTeams(this.props.rootStore.user).findOfficialRanch(ranchId) != null;

        this.setState({
            cur_cow_id: cow_id,
            medicineTeamId: mTeamId,
            nextCow: nexts.next,
            prevCow: nexts.previous,
            isOfficialRanch,

        }, async () => {
            const houses = await getRanchHouses(ranchId);
            const feeds = await getRanchFeeds(ranchId);
            await this.setStateAsync({
                location_sites: houses ?? [],
                ranchFeeds: feeds ?? [],
                initStatus: (houses == null || feeds == null) ? "error" : "ready"
            });

            await this.props.rootStore.fetchActiveCows();
            await this.props.rootStore.fetchSettings();
            
            await this.api_getDeliveryHistory();
            await this.api_getCrossHistory();

            await this.api_getGetCowInfo();
            this.loadSchedules(this.state.currentScheduleTerm.from, this.state.currentScheduleTerm.to, true);
            this.loadCrossDay();
        });
    }

    componentDidUpdate (prevProps) {
        // NOTE: 同一ページの画面遷移（URLパラメータのみ変更）の場合、
        //       マウントされているコンポーネントをそのまま使う？
        //       そのため、componentDidMountが呼ばれないため、
        //       代わりに、componentDidUpdateで牛情報を取得する
        if (prevProps.match.params.id !== this.props.match.params.id) {
            const cow_id = parseInt(this.props.match.params.id);
            const nexts = this.props.rootStore.getNextCows(cow_id);
            this.setState({
                cur_cow_id: cow_id,
                nextCow: nexts.next,
                prevCow: nexts.previous,
                currentGraphKind: "feed",
                growthData: undefined,
                isTagEditing: false,
                isHeaderOpen: false,
                feed_amount_list_key: "type",
            }, async () => {
                await this.api_getDeliveryHistory();
                await this.api_getCrossHistory();
                await this.api_getGetCowInfo();
                this.loadSchedules(this.state.currentScheduleTerm.from, this.state.currentScheduleTerm.to, true);
                this.loadCrossDay();
            });
        }
    }

    getBreedName(breed_no) {
        for (var i = 0; i < this.props.rootStore.options.cow_breed.length; i++) {
            if (this.props.rootStore.options.cow_breed[i].breed_no === breed_no) {
                return this.props.rootStore.options.cow_breed[i].name;
            }
        }
        return "未設定";
    }

    getUseName(use_no: number | undefined) {
        if (use_no == null) return "未設定";

        return this.props.rootStore.options.cow_use.find(u => u.use_no === use_no)?.name ?? "未設定";
    }

    getLocationName(site: number, barn: number, room: number) {
        const sites = this.state.location_sites;
        return buildLocationName(sites, site, barn, room);
    }

    getAncestorStr(ancestor_1: string|undefined, ancestor_2: string|undefined, ancestor_3: string|undefined) {

        const ancestor = [ ancestor_1, ancestor_2, ancestor_3 ]
            .map((a,i) => (a == null || a === "") ? undefined : `${i+1}.${a}`)
            .filter(a => a != null)
            .join(" ");
        
        if (ancestor === "") return "未設定";
        return ancestor;
    }

    toggleTab(tab: "1"|"2"|"3") {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    onEventItemClick(date: string, type: number) {
        if (!CommonUtil.assertNotNull(this.state.cur_cow_id, "cur_cow_id", "onEventItemClick")) return;
        this.props.history.push(historyLocation.toCowEvent(this.state.cur_cow_id, date, type))
    }

    onFeedHistory() {
        if (!CommonUtil.assertNotNull(this.state.cur_cow_id, "cur_cow_id", "onFeedHistory")) return;
        this.props.history.push(historyLocation.toFeedWrite({ param: this.state.cur_cow_id }));
    }

    onFeedLeft() {
        if (!CommonUtil.assertNotNull(this.state.cur_cow_id, "cur_cow_id", "onFeedLeft")) return;
        this.props.history.push(historyLocation.toFeedRemained([this.state.cur_cow_id]));
    }

    onConditionHeal() {
        if (!CommonUtil.assertNotNull(this.state.cur_cow_id, "cur_cow_id", "onConditionHeal")) return;
        this.props.history.push(historyLocation.toSymptomWrite({ param: this.state.cur_cow_id }));
    }

    onPrevention() {
        if (!CommonUtil.assertNotNull(this.state.cur_cow_id, "cur_cow_id", "onPrevention")) return;
        this.props.history.push(historyLocation.toPreventionWrite({ param: this.state.cur_cow_id }));
    }

    onWriteCow() {
        if (!CommonUtil.assertNotNull(this.state.cur_cow_id, "cur_cow_id", "onWriteCow")) return;
        this.props.history.push(historyLocation.toCowWriteEdit(this.state.cur_cow_id));
    }

    onMoveCow() {
        if (!CommonUtil.assertNotNull(this.state.cur_cow_id, "cur_cow_id", "onMoveCow")) return;
        this.props.history.push(historyLocation.toLocationWrite({ param: this.state.cur_cow_id }));
    }

    onRut() {
        if (!CommonUtil.assertNotNull(this.state.cur_cow_id, "cur_cow_id", "onRut")) return;
        this.props.history.push(historyLocation.toRutWrite({
            cow_top:true,
            cow_ids:[this.state.cur_cow_id],
            cows: this.buildEventWriteLocationState().cows,
        }))
    }

    buildEventWriteLocationState(): IEventWriteLocationState {
        const cow = this.state.cow;
        const cows = [{
            cow_id: cow.cow_id,
            local_no: cow.local_no,
            trace_id: cow.trace_id,
            name: cow.name,
            use_no: cow.use_no,
            breed_no: cow.breed_no,
            watching: this.state.watchingInfo.watching,
            watching_memo: this.state.watchingInfo.watching_memo
        }];
        return { cows };
    }

    onBreeding() {
        if (!CommonUtil.assertNotNull(this.state.cur_cow_id, "cur_cow_id", "onBreeding")) return;
        this.props.history.push(historyLocation.toBreedingWrite({ param: this.state.cur_cow_id }));
    }
    onDelivery() {
        if (!CommonUtil.assertNotNull(this.state.cur_cow_id, "cur_cow_id", "onDelivery")) return;
        this.props.history.push(historyLocation.toDeliveryWrite({ param: this.state.cur_cow_id }));
    }

    onGraphDayClick(day: string) {
        if (!CommonUtil.assertNotNull(this.state.cur_cow_id, "cur_cow_id", "onGraphDayClick")) return;

        const evtParam = this.state.currentGraphKind === "feed" ? EVENT_TYPE_PARAM.FEED : EVENT_TYPE_PARAM.SYMPTOM;
        this.props.history.push(historyLocation.toCowEvent(this.state.cur_cow_id, day, evtParam));
    }

    onMotherInfo(e, cow_id: number) {
        e.preventDefault();
        this.props.history.push(historyLocation.toCowInfo(cow_id));
    }

    onSwipe(cowId: number) {
        this.props.history.replace(historyLocation.toCowInfo(cowId));
    }

    onNote() {
        this.setState({ popup_note_open: true });
    }

    onPrevFeedGraph() {
        let prev_day = moment(this.state.feed_chart_end_day).subtract(FEED_CHART_DAYS_PER_PAGE - 1, "days").format("YYYY-MM-DD");
        this.api_getFeedAmountHistory(prev_day);
    }

    onNextFeedGraph() {
        let next_day = moment(this.state.feed_chart_end_day).add(FEED_CHART_DAYS_PER_PAGE - 1, "days").format("YYYY-MM-DD");
        this.api_getFeedAmountHistory(next_day);
    }
    async onFeedGraphKeyChanged(key: "type"|"item") {
        await this.api_getFeedAmountHistory(this.state.feed_chart_end_day, key);
    }

    onScheduleClick(e: { event: EventApi }) {
        const event = this.state.scheduleEvents.find(o => o.id === Number(e.event.id));
        if (!CommonUtil.assertNotNull(event, "event", "onScheduleClick")) return;

        this.setState({
            currentScheduleEvent: event,
            isSchedulePopupShown: true,
            isScheduleEditing: false
        });
    }
    onSellCowClick() {
        this.props.history.push(historyLocation.toSellCow(this.buildEventWriteLocationState()));
    }
    onBalanceClick() {
        if (!CommonUtil.assertNotNull(this.state.cur_cow_id, "cur_cow_id", "onBreeding")) return;
        this.props.history.push(historyLocation.toBalanceCow([this.state.cur_cow_id]));
    }
    onSellMilkClick() {
        const search = {
            param: this.state.cow.cow_id,
            cow_top: 1 as 1 | 0,
        }
        const state = this.buildEventWriteLocationState();

        this.props.history.push(historyLocation.toSellMilk(search, state));
    }
    onOtherPlClick() {
        const search = {
            param: this.state.cow.cow_id,
            cow_top: 1 as 1 | 0,
        }
        const state = this.buildEventWriteLocationState();

        this.props.history.push(historyLocation.toOtherPl(search, state));
    }
    onProgramClick() {
        this.props.history.push(historyLocation.toCowProgram(this.buildEventWriteLocationState().cows));
    }
    onGrowth() {
        if (!CommonUtil.assertNotNull(this.state.cur_cow_id, "cur_cow_id", "onGrowth")) return;
        this.props.history.push(historyLocation.toGrowthWrite({ param: this.state.cur_cow_id }));
    }
    onSovClick() {
        this.props.history.push(historyLocation.toSovWrite(this.buildEventWriteLocationState()));
    }
    onOpuClick() {
        this.props.history.push(historyLocation.toOpuWrite(this.buildEventWriteLocationState()));
    }
    onIvfClick() {
        this.props.history.push(historyLocation.toIvfWrite(this.buildEventWriteLocationState()));
    }
    onIvfEggClick() {
        this.props.history.push(historyLocation.toIvfEggWrite(this.buildEventWriteLocationState()));
    }
    onCertClick(certType: CertType) {
        if (certType === "vaccine") {
            this.props.history.push(historyLocation.toCertVaccine(this.buildEventWriteLocationState()));
        } else if (certType === "disease") {
            this.props.history.push(historyLocation.toCertDisease(this.buildEventWriteLocationState()));
        } else if (certType === "karte") {
            this.props.history.push(historyLocation.toCertKarte(this.buildEventWriteLocationState()));
        }
    }


    onDatesRender(info: { view: View }) {
        const start = info.view.activeStart;
        const end = moment(info.view.activeEnd).add(-1,"day").toDate();
        this.setState({
            currentScheduleTerm: new Term(start, end)
        });

        //マウント完了前
        if (this.state.cur_cow_id == null) return;

        this.loadSchedules(start, end, false);
    }

    async loadSchedules(fromDate: Date, toDate: Date, reset: boolean) {
        const from = moment(fromDate);
        const to = moment(toDate);
        if (from.isAfter(to)) {
            console.error("invalid term", from, to);
            return;
        }

        const loadedTerms = reset ? new Terms() : this.state.loadedScheduleTerms.clone();

        const term = new Term(from, to);
        const toLoad = loadedTerms.getUncontained(term);
        if (toLoad == null) {
            return;
        }

        this.context.handleSetIsLoading(true);
        const res = await this.comm().send((await RanchApi()).getScheduleListUsingPOST({
            ranch_id: this.props.rootStore.cur_ranch_id,
            start_at: moment(toLoad.from).format("YYYY-MM-DD"),
            end_at: moment(toLoad.to).format("YYYY-MM-DD"),
            cow_id: [ this.state.cow.cow_id ]
        }), { errorMessage: A.MESSAGE.FAILED_TO_LOAD_SCHEDULE });
        this.context.handleSetIsLoading(false);

        if (res.result !== "OK") return;
        
        const list = res.data ?? [];
        const added = ar.notNull(list.map(d => dtoToEvent(d)));
        //月またぎのイベントや、取得済み期間が歯抜けだった分は重複して取得されるので捨てる
        const events = reset ? added : [ ...this.state.scheduleEvents, ...added.filter(a => !this.state.scheduleEvents.some(e => e.id === a.id)) ];
        loadedTerms.add(toLoad);

        this.setState({
            scheduleEvents: events,
            loadedScheduleTerms: loadedTerms
        });
    }

    onAddScheduleClick() {
        this.setState({
            isSchedulePopupShown: true,
            isScheduleEditing: true,
            currentScheduleEvent: {
                cows:[ { ...this.state.cow, use_no:this.state.cow.use_no, breed_no:this.state.cow.breed_no } ]
            },
        });
    }

    async onFinishScheduleEdit(withEvent: boolean) {
        this.setState({
            currentScheduleEvent:{},
            isSchedulePopupShown: false
        });
        await this.loadSchedules(this.state.currentScheduleTerm.from, this.state.currentScheduleTerm.to, true);
        if (withEvent) {
            await this.loadEvents(true);
        }
    }
    onStartScheduleEdit() {
        this.setState({
            isScheduleEditing: true
        });
    }
    onSchedulePopupClose() {
        this.setState({
            currentScheduleEvent: {},
            isSchedulePopupShown: false
        });
    }
    onScheduleSplit(results: RanchScheduleDto[]) {
        const oldId = this.state.currentScheduleEvent.id;
        if (!CommonUtil.assertNotNull(oldId, "id", "onSplit")) return;

        const newEvent = results.map(d => dtoToEvent(d))
                           .find(r => r != null && r.cows.some(c => c.cow_id === this.state.cur_cow_id));
        //再取得されたイベントと入れ替える
        const events = this.state.scheduleEvents.filter(e => e.id !== oldId);
        if (newEvent != null) {
            events.push(newEvent);
        }

        this.setState({
            currentScheduleEvent: {},
            isSchedulePopupShown: false,
            scheduleEvents: events
        });

    }

    onScheduleCowClick(cow_id: number) {
        if (this.state.cow.cow_id === cow_id) return;
        this.setState({
            currentScheduleEvent: {},
            isSchedulePopupShown: false
        });
        this.props.history.push(historyLocation.toCowInfo(cow_id));
    }

    onWriteReexamine(first_id: number) {
        if (!CommonUtil.assertNotNull(this.state.cur_cow_id, "cur_cow_id", "onWriteReexamine")) return;
        this.props.history.push(historyLocation.toSymptomWrite({ param: this.state.cur_cow_id, first_id: first_id }));
    }

    async onSubmitTags(tags: EditingTag[]) {
        if (!CommonUtil.assertNotNull(this.state.cur_cow_id, "cur_cow_id", "onSubmitTags")) return;

        this.context.handleSetIsLoading(true);

        const res = await this.comm().send((await CowApi()).modifyTagUsingPOST({
            ranch_id: this.props.rootStore.cur_ranch_id,
            cows: [{
                cow_id: this.state.cur_cow_id,
                tags: tags.map(t => ({ id: t.tag_id, name: t.tag_name }))
            }]
        }));
        if (res.result !== "OK") {
            this.context.handleSetIsLoading(false);
            return;
        }

        await this.props.rootStore.fetchCowTags(undefined, true);
        this.context.handleSetIsLoading(false);

        //タグ表示はローカルで更新する
        const allTags = this.props.rootStore.getCowTags();
        const cowTagIds = tags.map(t => {
            if (t.tag_id == null) {
                return allTags.find(rt => rt.tag_name === t.tag_name)?.tag_id;
            }
            if (allTags.some(rt => rt.tag_id === t.tag_id)) return t.tag_id;

            //指定したタグがすでに削除済みだった場合、採番し直されている
            return allTags.find(rt => rt.tag_name === t.tag_name)?.tag_id;
        });
        if (cowTagIds.some(i => i == null)) {
            console.error("unknown tag found after submit", allTags, tags);
        }

        this.setState({
            cow: {
                ...this.state.cow,
                tags:ar.notNull(cowTagIds)
            },
            isTagEditing: false
        });

        //更新しておく
        this.props.rootStore.fetchActiveCows(undefined, "DIFF");
    }

    async loadCrossDay() {
        const cowId = this.state.cur_cow_id;
        if (!CommonUtil.assertNotNull(cowId, "cowId", "loadCrossDay")) {
            return;
        }
        if (!A.IS_FOR_BREEDING_COW(this.state.cow.use_no ?? 0)) {
            await this.setStateAsync({
                last_cross_day: undefined
            });
            return;
        }

        const res = await this.comm().send<BreedingCrossCurrentDto>((await BreedingApi()).getBreedingCrossCurrentUsingPOST({
            cow_id: cowId,
            last_day: moment(this.state.today).format("YYYY-MM-DD"),
            ranch_id: this.props.rootStore.cur_ranch_id
        }), { showsErrorMessage: false });
        if (res.result !== "OK") return;

        const crossHis = res.data?.crosses ?? [];
        const lastDay = crossHis.length === 0 ? undefined : moment(crossHis[crossHis.length - 1].watched_at).toDate();

        await this.setStateAsync({
            last_cross_day: lastDay
        });
    }

    onScheduleLinkClick(link: LinkParam) {
        this.props.history.push(link);
    }

    async onGraphKindSelected(value: string) {
        const kind = value as GraphKindKey;
        this.setState({
            currentGraphKind: kind
        });

        const growthType = GRAPH_KIND[kind].growthScoreType;
        const birthdayStr = this.state.cow.birthday;
        if (growthType == null || birthdayStr == null) {
            this.setState({
                growthData: undefined
            })
            return;
        }

        this.context.handleSetIsLoading(true);

        const stds = await this.loadGrowthStandards(growthType);
        if (stds == null) {
            this.context.handleSetIsLoading(false);
            return;
        }

        const scores = await this.loadGrowthData(growthType);
        this.context.handleSetIsLoading(false);
        if (scores == null) {
            return;
        }
        
        this.setState({
            growthData: {
                actuals: scores.map(s => ({ date: moment(s.watched_at).format("YYYY-MM-DD"), score: s.score })),
                standards: stds,
                scoreTypeNo: growthType,
            }
        });

    }

    async loadGrowthData(score_type: number) {
        const req: GrowthGraphReq = {
            cow_id: this.state.cow.cow_id,
            ranch_id: this.props.rootStore.cur_ranch_id,
            score_type
        };
        const res = await this.comm().send((await GrowthApi()).getGrowthGraphUsingPOST(req));
        if (res.result !== "OK") return undefined;
        return res.data?.scores;
    }

    async loadGrowthStandards(score_type: number) {
        const use_no = this.state.cow.use_no;
        const breed_no = this.state.cow.breed_no;
        const is_male = this.state.cow.is_male;
        if (use_no == null || breed_no == null) return Promise.resolve([]);

        const res = await this.comm().send((await GrowthApi()).getStandardUsingPOST({
            breed_no,
            use_no,
            is_male,
            score_type
        }));

        if (res.result !== "OK") return undefined;
        return res.data?.scores;
    }



    api_getGetCowInfo = async () => {
        const query_str = `?ranch_id=${this.props.rootStore.cur_ranch_id}&is_detail=1&amount_days=${FEED_CHART_DAYS_PER_PAGE}&event_count=${EVENTS_PER_PAGE}`;

        this.context.handleSetIsLoading(true);
        const response = await this.comm().send<CowDetailDto>(() => this.context.postAsync('/cow/' + this.state.cur_cow_id + query_str, {}), { retries: true });
        if (response.data == null) {
            this.context.handleSetIsLoading(false);
            return;
        }

        if (response.data.is_deleted === 1) {
            this.context.handleSetIsLoading(false);
            await this.context.showModalAsync("この牛の情報は削除されています", "ALERT", ["OK"]);
            this.props.history.replace("/top/" + this.props.rootStore.cur_ranch_id);
            return;
        }

        //牛情報のタグでローカルのリストにないものがある場合は、リストをリロードしておく
        const allTagIds = new Set((this.props.rootStore.getCowTags() ?? []).map(t => t.tag_id));
        if (response.data.tags.some(t => !allTagIds.has(t))) {
            await this.props.rootStore.fetchCowTags(undefined, true);
        }

        this.context.handleSetIsLoading(false);

        const cow = { ...response.data, washouts: new Washouts(response.data.washouts)} as ICowData;

        const event_list = cow.event_list ?? [];
        const eventIds = [ ...new Set(event_list.map(e => e.event_id)) ];
        const has_more_events = eventIds.length === EVENTS_PER_PAGE;

        const start_day = cow.start_day ?? "";

        await this.setStateAsync({ 
            intial_start_day: start_day,
            feed_chart_end_day: moment(this.state.today).format("YYYY-MM-DD"),
            cow: cow,
            amount_list: cow.amount_list,
            has_more_events: has_more_events,
            current_event_page: 1,
            daily_data: this.groupEventListByDay(event_list),
            watchingInfo: { cow_id: cow.cow_id, watching: cow.watching, watching_memo: cow.watching_memo },
        });

        this.updateFeedChart();
    }

    updateFeedChart = () => {
        let end_day = this.state.feed_chart_end_day;

        const period = Math.min(FEED_CHART_DAYS_PER_PAGE - 1, moment(end_day).diff(moment(this.state.intial_start_day), 'days'));
        const st_day = moment(end_day).subtract(period, "days").format("YYYY-MM-DD");

        const is_prev = st_day > this.state.intial_start_day;
        const is_next = end_day < moment(this.state.today).format("YYYY-MM-DD");

        this.setState({ has_prev_feed: is_prev, has_next_feed: is_next, feed_chart_start_day: st_day });
    }

    api_getFeedAmountHistory = async (end_day: string, key?:"item"|"type") => {
        if (!CommonUtil.assertNotNull(this.state.cur_cow_id, "cur_cow_id", "getFeedAmountHis")) return;

        const reqKey = key ?? this.state.feed_amount_list_key;
        const params: FeedAmoutHistReq = {
            cow_id: this.state.cur_cow_id,
            end_day: end_day,
            amount_days: FEED_CHART_DAYS_PER_PAGE,
            is_per_item: reqKey === "item" ? 1 : 0
        };

        console.log(params);

        this.context.handleSetIsLoading(true);
        const response = await this.comm().send<FeedAmountHistDto>(() => this.context.postAsync('/feed/amount/history', params));
        this.context.handleSetIsLoading(false);
        if (response.result !== "OK" || response.data == null) return;

        const amount_list = (response.data.list ?? []);
        await this.setStateAsync({ feed_chart_end_day: end_day, amount_list: amount_list, feed_amount_list_key: reqKey });

        this.updateFeedChart();
    }

    api_getDeliveryHistory = async () => {
        if (!CommonUtil.assertNotNull(this.state.cur_cow_id)) return Promise.resolve(false);

        this.context.handleSetIsLoading(true);
        const res = await this.comm().send((await DeliveryApi()).getHistoryListUsingPOST1({
            cow_id: this.state.cur_cow_id,
            ranch_id: this.props.rootStore.cur_ranch_id
        }), { showsErrorMessage: false });
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") return false;

        await this.setStateAsync({
            deliveryHistory: (res.data?.list ?? []).map(h => ({
                delivered_at: moment(h.delivered_at).toDate(),
                delivery_id: h.delivery_id!
            }))
        });
        return true;
    }
    api_getCrossHistory = async () => {
        if (!CommonUtil.assertNotNull(this.state.cur_cow_id)) return Promise.resolve(false);

        this.context.handleSetIsLoading(true);
        const req = {
            cow_id:this.state.cur_cow_id,
            ranch_id: this.props.rootStore.cur_ranch_id
        };
        const res = await this.comm().send((await BreedingApi()).getBreedingCrossHistoryUsingPOST(req), { showsErrorMessage: false });
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") return false;

        await this.setStateAsync({
            crossHistory: (res.data ?? []).map(c => ({
                ...c,
                watched_at: moment(c.watched_at).toDate(),
            }))
        });
        return true;
    }

    async loadEvents(reset: boolean) {
        if (!CommonUtil.assertNotNull(this.state.cur_cow_id, "cur_cow_id", "loadEvents")) return;

        this.context.handleSetIsLoading(true);
        const nextPage = reset ? 1 : this.state.current_event_page + 1;
        const prm: CowEventHistoryReq = {
            user_id:this.props.rootStore.user.id,
            ranch_id:this.props.rootStore.cur_ranch_id,
            cow_id:this.state.cur_cow_id,
            page_no:nextPage,
            event_count: EVENTS_PER_PAGE
        };
        const opt = { errorMessage: A.MESSAGE.FAILED_TO_LOAD_EVENT };
        const res = await this.comm().send<CowEventListDto>(() => this.context.postAsync('/cow/event/history', prm), opt);
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK" || res.data == null) return;

        const new_events = res.data.event_list as EventData[];
        const pre_daily_data = this.state.daily_data;

        const new_daily_data = this.groupEventListByDay(new_events);
        const all_daily_data = reset ? new_daily_data : pre_daily_data.concat(new_daily_data);

        const eventIds = [ ...new Set(new_events.map(e => e.event_id)) ];

        this.setState({
            has_more_events: eventIds.length === EVENTS_PER_PAGE,
            current_event_page: nextPage,
            daily_data: all_daily_data
        });

    }

    groupEventListByDay(event_list: EventData[]):DailyEventData[]  {
        const daily_data : DailyEventData[] = [];
        var last_day_data : DailyEventData | null = null;

        for (var i = 0; i < event_list.length; i++) {
            var event = event_list[i];
            let cur_date = event.day;
            if (cur_date == null) {
                console.error("invalid date data", event);
                continue;
            }

            if (last_day_data === null || cur_date !== last_day_data.day) {
                last_day_data = {
                    day: cur_date,
                    events: [],
                };
                daily_data.push(last_day_data);
            }

            last_day_data.events.push(event);
        }
        return daily_data;
    }

    onShowBreedingStatePopup() {
        //分娩日からopen予定初期値を決定
        let openday = this.state.cow.open_day == null ? null : moment(this.state.cow.open_day).toDate();
        if (openday == null) {
            if (this.state.deliveryHistory.length > 0) {
                const lastDelivery = this.state.deliveryHistory[this.state.deliveryHistory.length - 1];

                const openDayMom = moment(lastDelivery.delivered_at).add(DEFAULT_OPEN_DAYS_AFTER_DELIVERY, "days");
                if (openDayMom.isAfter(new Date())) {
                    openday = openDayMom.toDate();
                }
            }
        }
        this.setState({
            isBreedingStatePopupShown: true,
            tmpOpenDay: openday ?? moment().add(DEFAULT_OPEN_DAYS_AFTER_DELIVERY, "days").toDate()
        });
    }
    async onSubmitBreedingState(state: number, openday?: Date) {
        if (!CommonUtil.assertNotNull(this.state.cur_cow_id, "cow_id", "onSubmitBreedingState")) return;

        try {
            this.setState({ executing: true });

            const confirmed = await confirmBreedingStateChange(
                                        this.state.cow.breeding_state,
                                        state,
                                        this.state.deliveryHistory,
                                        this.state.crossHistory,
                                        this.context.showQuestionAsync);
            if (confirmed !== BREEDING_STATE_CONFIRMED.OK_NO_CONFIRMED && confirmed !== BREEDING_STATE_CONFIRMED.OK) return;

            const req: CowBreedingModifyReq = {
                cow_ids: [this.state.cur_cow_id],
                state: state,
                activity: 1,
                open_day: openday == null ? undefined : moment(openday).format("YYYY-MM-DD"),
                ranch_id: this.props.rootStore.cur_ranch_id
            };
            this.context.handleSetIsLoading(true);
            const res = await this.comm().send((await CowApi()).modifyBreedingUsingPOST(req));
            this.context.handleSetIsLoading(false);
            if (res.result !== "OK") return;

            //状態をローカルで更新
            this.setState({
                cow: { ...this.state.cow, breeding_state: req.state, open_day: req.open_day },
                isBreedingStatePopupShown: false
            });

        } finally {
            this.setState({ executing: false });
        }

        //アクティブ牛リスト更新しておく
        this.props.rootStore.fetchActiveCows(undefined, "DIFF");
        resetCow(this.state.cur_cow_id, false);
    }

    showSellCowDetail = async (event: IHisSellCow) => {
        const req: SingleEventReq = {
            ranch_id: this.props.rootStore.cur_ranch_id,
            event_id: event.event_id
        };
        this.context.handleSetIsLoading(true);
        const res = await this.comm().send((await SellCowApi()).getEventUsingPOST1(req));
        this.context.handleSetIsLoading(false);

        if (res.result !== "OK" || res.data == null) return;

        const ev = res.data;
        const sellTypeKey = COW_SELL_TYPES.find(c => COW_SELL_TYPE[c].value === ev.sell_type);
        let sellType = sellTypeKey == null ? "" : COW_SELL_TYPE[sellTypeKey].name;
        if (ev.is_disuse === 1) {
            sellType += "（廃用/病畜）";
        }

        let weight = ev.weight == null ? "" : (ev.weight + "kg");
        const birthWeight = ev.birth.actual_weight ?? ev.birth.standard_weight;
        if (ev.weight != null && birthWeight != null && ev.birth.birthday != null) {
            const dg = calcDg({ score: birthWeight, date: ev.birth.birthday }, { score: ev.weight, date: ev.watched_at });
            if (dg != null) {
                weight += ` (DG ${dg} kg/日)`
            }
        }

        const texts: TextModalContent[] = [
            { key: "記録者", value: ev.watched_by_name },
            { key: "種別", value: sellType },
            { key: "出荷先", value: ev.partner_name ?? "" },
            { key: "出荷体重", value: weight }
        ];
        if (ev.carcass != null) {
            const ccs = ev.carcass;
            texts.push({key:"格付", value:ccs.total_grade});
            texts.push({key:"販売価格", value:`${formatYen(ccs.unit_price)}円 × ${ccs.amount}kg = ${formatYen(ev.price)}円`});
            texts.push({key:"ロース芯面積", value:(ccs.yield_loin == null ? "" : ccs.yield_loin + " cm2")});
            texts.push({key:"バラ厚さ", value:(ccs.yield_rib == null ? "" : ccs.yield_rib + " cm")});
            texts.push({key:"皮下脂肪", value:(ccs.yield_fat == null ? "" : ccs.yield_fat + " cm")});
            texts.push({key:"歩留基準", value:(`${ccs.yield_basis ?? ""}`)});
            texts.push({key:"BMS", value:`${ccs.quality_bms ?? ""}`});
            texts.push({key:"肉色BCS", value:`${ccs.quality_bcs ?? ""}`});
            texts.push({key:"きめ締まり", value:`${ccs.quality_texture ?? ""}`});
            texts.push({key:"脂肪の質", value:`${ccs.quality_fat ?? ""}`});
            texts.push({key:"オレイン酸含量", value:(ccs.oleic_acid == null ? "" : ccs.oleic_acid + " %")});
            texts.push({key:"瑕疵", value:this.renderDefects(ccs.defect)});
            texts.push({key:"褒賞", value:ccs.reward});

        } else {
            texts.push({key:"販売価格", value:formatYen(ev.price) + "円"});
        }
        if (ev.comment !== "") {
            texts.push({key:"メモ", value:ev.comment});
        }

        const canEdit = (EVENT_KIND.SELL_COW.selfEditOnly !== true) || ev.created_by === this.props.rootStore.user.id;

        return this.setStateAsync({
            currentEventDetail: ({
                texts,
                title:"個体販売 " + moment(ev.watched_at).format("YYYY/MM/DD HH:mm"),
                onClick: canEdit ? () => { this.props.history.push(historyLocation.toSellCowEdit(ev.event_id)) } : undefined
            })

        });
    }

    renderDefects(value: number) {
        //一個もない場合は薄字でも「アイウエオカ」と書いてしまうと全部あるように見えるので、空欄にしておく
        if (value === 0) return <></>;

        return (<>
            { DEFECTS.map(k => (
                <span key={k}
                    style={{
                        marginRight:"6px",
                        fontWeight: hasDefect(k, value) ? "bold" : "normal",
                        color: hasDefect(k, value) ? "#333333" : "#bbbbbb"
                    }}
                >{k}</span>
            ))}
            
        </>)
    }

    showSellMilkDetail = async (event: IHisCowSellMilk) => {
        const req: SingleEventReq = {
            ranch_id: this.props.rootStore.cur_ranch_id,
            event_id: event.event_id
        };
        this.context.handleSetIsLoading(true);
        const res = await this.comm().send((await SellMilkApi()).getEventUsingPOST2(req));
        this.context.handleSetIsLoading(false);

        if (res.result !== "OK" || res.data == null) {
            return;
        }

        const ev = res.data;
        const texts = [
            { key: "記録者", value: ev.watched_by_name },
        ];
        if (ev.amount_discard > 0) {
            texts.push({ key: "乳量（廃棄）", value: ev.amount_discard + " kg" });
            texts.push({ key: "金額", value: `${formatYen(Math.round(ev.amount_discard * ev.unit_price))}円相当` });
        } else {
            texts.push({ key: "乳量", value: ev.amount + " kg" });
            texts.push({ key: "金額", value: `${formatYen(Math.round(ev.amount * ev.unit_price))}円` });
        }
        if (ev.comment !== "") {
            texts.push({key:"メモ", value:ev.comment});
        }

        const isEditableUser = (EVENT_KIND.SELL_MILK_COW.selfEditOnly !== true) || ev.created_by === this.props.rootStore.user.id;

        const onEditClick = (isEditableUser && this.state.cow.is_active === 1)
                        ? (() => { this.props.history.push(historyLocation.toSellMilkCowEdit(ev.event_id)) })
                        : undefined;
        return this.setStateAsync({
            currentEventDetail: ({
                texts,
                title:"乳代記録 " + moment(ev.watched_at).format("YYYY/MM/DD HH:mm"),
                onClick: onEditClick
            })

        });
    }

    showOtherPlDetail = async (event: IHisOtherProfitCow | IHisOtherLossCow) => {
        const req: SingleEventReq = {
            ranch_id: this.props.rootStore.cur_ranch_id,
            event_id: event.event_id
        };
        this.context.handleSetIsLoading(true);
        const res = await this.comm().send((await OtherPlApi()).getEventUsingPOST(req));
        this.context.handleSetIsLoading(false);

        if (res.result !== "OK" || res.data == null) {
            return;
        }

        const ev = res.data;
        const plType = isOtherProfitCow(event) ? "雑収入" : "雑損失";
        const texts = [
            { key: "記録者", value: ev.watched_by_name },
            { key: "区分", value: plType },
            { key: "金額", value: `${formatYen(ev.price)}円` }
        ];
        if (ev.comment !== "") {
            texts.push({key:"メモ", value:ev.comment});
        }

        const isEditableUser = (EVENT_KIND.OTHER_PROFIT_COW.selfEditOnly !== true) || ev.created_by === this.props.rootStore.user.id;

        const onEditClick = (isEditableUser && this.state.cow.is_active === 1)
                        ? (() => { this.props.history.push(historyLocation.toOtherPlCowEdit(ev.event_id)) })
                        : undefined;
        return this.setStateAsync({
            currentEventDetail: ({
                texts,
                title: plType + " " + moment(ev.watched_at).format("YYYY/MM/DD HH:mm"),
                onClick: onEditClick
            })

        });
    }

    async exportCsv() {
        const ranch_id = this.props.rootStore.cur_ranch_id;
        const cow_id = this.state.cur_cow_id;
        if (!CommonUtil.assertNotNull(cow_id)) return;

        const event_kinds = ar.flat(this.state.eventFilterItems.map(e => e.values))
                                .every(no => this.state.selectedEventKinds.has(no))
                          ? undefined
                          : [ ...this.state.selectedEventKinds, ACTIVITY_KIND.COW.no ];

        this.setState({ executing: true });
        this.context.handleSetIsLoading(true);
        const res = await this.comm().send((await CowApi()).exportEventsUsingPOST({ ranch_id, cow_id, event_kinds }));
        this.context.handleSetIsLoading(false);
        this.setState({ executing: false });

        if (res.result !== "OK" || res.data == null) return;

        saveTextFile(res.data.csv, `イベント履歴_${res.data.trace_id}_${moment().format("YYYYMMDD")}.csv`);
    }

    render() {
        if (this.state.initStatus === "loading") return <FetchWaiter />
        if (this.state.initStatus === "error") return <FetchError />

        const collapseArrowClass = (isOpen : boolean) => "clickable fas fa-lg fa-fw " + (isOpen ? "fa-angle-up" : "fa-angle-down");

        const state = this.state;

        const allTags = this.props.rootStore.getCowTags() ?? [];

        const ranchId = this.props.rootStore.cur_ranch_id;
        const user = this.props.rootStore.user;
        const canEditCow = hasRanchAuth("COW_EDIT", ranchId, user);
        const canRefFeedAmount = hasRanchAuth("FOODING_REF_AMOUNT", ranchId, user);
        const canBreeding = hasRanchAuth("BREEDING_EDIT", ranchId, user);
        const canBrowseInside = hasRanchAuth("BROWSE_INSIDE", ranchId, user);
        const canRefGrowth = hasRanchAuth("GROWTH_EDIT", ranchId, user);

        const canViewWashoutDetail = state.isOfficialRanch
                                ? hasRanchAuth("FOODING_REF_NAME", ranchId, user) && hasRanchAuth("TREAT_REF_NAME", ranchId, user)
                                : hasRanchAuth("TREAT_REF_NAME", ranchId, user);
        
        const isForBreeding = this.state.cow.use_no != null && A.IS_FOR_BREEDING_COW(this.state.cow.use_no);
        const isForMilk = this.state.cow.use_no != null && A.IS_FOR_MILK(this.state.cow.use_no);

        const setting = this.props.rootStore.getSettings();
        const age = state.cow.birthday == null ? undefined : calcAge(moment(), moment(state.cow.birthday), setting?.age_mode ?? AGE_MODE.BIRTHDAY);

        return (
            <div style={{ height: "100%", display:"flex", flexDirection:"column" }}>
                <SwipeToNextPanel className="m-b-10"
                    next={state.nextCow?.disp} prev={state.prevCow?.disp}
                    onNext={() => this.onSwipe(state.nextCow!.cow_id)}
                    onPrev={() => this.onSwipe(state.prevCow!.cow_id)}
                    >
                    <div className={styles["cow-title"]} data-testid="cow-title">
                        <WatchingIcon
                            watchingInfo={state.watchingInfo}
                            showAlert={this.context.showAlert}
                            onUpdated={d => this.setState({watchingInfo:d})}
                            comm={this.comm()}
                            className="pull-left m-t-5"
                        />
                        <WashoutIcon isON={this.state.cow.washouts.data.length > 0} className="pull-left" style={{marginTop:"3px", marginLeft:"4px"}} 
                            washouts={this.state.cow.washouts}
                            canViewDetail={canViewWashoutDetail}
                        />
                        { RenderCowNameInfo(state.cow) }
                        { canEditCow && (
                            <a onClick={this.onWriteCow} style={{marginLeft:"14px"}}>
                                <i className="fa fa-edit clickable" style={{fontSize: "1.4rem"}}></i>
                            </a>
                        )}
                    </div>
                    <div className="m-t-10" style={{display:"flex", justifyContent:"space-between", alignItems:"flex-end"}}>
                        <span data-testid="trace_id_and_birthday">
                            {state.cow.trace_id + " " + A.GET_SEX_MARK(state.cow.is_male) + " "}
                            {state.cow.birthday !== null ? (moment(state.cow.birthday).format("YYYY/MM/DD") + "生") : "生年月日未設定"}
                            {(age != null) && (
                                "（月齢 " + age + "ヵ月）"
                            )}
                            <CalfIcon age={age} borderAge={11} use_no={state.cow.use_no} style={{marginTop:"-5px", marginLeft:"2px"}} />
                        </span>
                        <div style={{fontSize:"1.2rem", lineHeight:0.9}}>
                            <i className={collapseArrowClass(state.isHeaderOpen)} onClick={()=>this.setState({isHeaderOpen: !state.isHeaderOpen})}></i>
                        </div>
                    </div>
                    <Collapse isOpen={state.isHeaderOpen}>

                        { !this.state.isTagEditing && (
                            <div style={{lineHeight:1.7}} data-testid="cow-tag-viewer">
                                <span>タグ：</span>
                                { this.state.cow.tags
                                    .map(tn => allTags.find(t => t.tag_id === tn))
                                    .filter((t): t is RanchTagDto => t != null)
                                    .map(t => (
                                    <span key={t.tag_id} data-testid="タグ" className={styles.tag} onClick={() => this.context.showQuickSearch(`${TAG_SEARCH_SYMBOL}${t.tag_name}`)}>{t.tag_name}</span>
                                ))}
                                { canEditCow && <span className="link" onClick={() => this.setState({ isTagEditing: true })}>編集</span>}
                            </div>
                        )}
                        { this.state.isTagEditing && (
                            <CowTagEditor
                                containerStyle={{ margin:"4px 0" }}
                                allTags={allTags}
                                tag_ids={this.state.cow.tags}
                                onCalcel={()=>{ this.setState({ isTagEditing:false })}}
                                onSubmit={this.onSubmitTags}
                            />
                        )}

                        <div>{"品種：" + this.getBreedName(state.cow.breed_no) + " 用途：" + this.getUseName(state.cow.use_no)}</div>
                        <div>{"場所：" + this.getLocationName(state.cow.site, state.cow.barn, state.cow.room)}
                            { canEditCow && state.cow.is_active === 1 && state.isOfficialRanch && (
                                <a className="link m-l-5" onClick={this.onMoveCow}>(場所を移動)</a>
                            )}
                        </div>
                        <div>
                            {"三代祖：" + this.getAncestorStr(state.cow.ancestor_1, state.cow.ancestor_2, state.cow.ancestor_3)}
                        </div>
                        <div>
                            {" 母牛：" + (state.cow.mother_id === null || state.cow.mother_id === 0 ? (state.cow.mother_name ?? "") === "" ? "未設定" : state.cow.mother_name : "")}
                            {(state.cow.mother_id != null && state.cow.mother_id > 0) && (
                                <a className="link" onClick={(e) =>this.onMotherInfo(e, state.cow.mother_id!)}>
                                    {state.cow.mother == null ? "" : CowToDispInfo(state.cow.mother, false)}
                                </a>
                            )}
                            {" 代理母：" + (state.cow.surrogate_mother_id === null || state.cow.surrogate_mother_id === 0 ? (state.cow.surrogate_mother_name ?? "") === "" ? "未設定" : state.cow.surrogate_mother_name : "")}
                            {(state.cow.surrogate_mother_id != null && state.cow.surrogate_mother_id > 0) && (
                                <a className="link" onClick={(e) =>this.onMotherInfo(e, state.cow.surrogate_mother_id!)}>
                                    {state.cow.surrogate_mother == null ? "" : CowToDispInfo(state.cow.surrogate_mother, false)}
                                </a>
                            )}
                        </div>
                        <div>
                            {` 導入元：${[...A.START_KIND.values()].find(s => s.no === state.cow.start_kind)?.name ?? "未設定"}`} 
                            { state.cow.start_kind === A.START_KIND.get("OUTSIDE")!.no && (
                                ` 導入日：${(state.cow.accept_day ?? "") === "" ? "未設定" : moment(state.cow.accept_day).format("YYYY/MM/DD")}`
                            )}
                        </div>
                        { state.cow.start_kind === A.START_KIND.get("OUTSIDE")!.no && (
                            <div>
                                {` 導入牧場：${(state.cow.accept_from ?? "") === "" ? "未設定" : state.cow.accept_from}`}
                            </div>
                        )}
                        { state.last_cross_day != null && (
                            <div>交配日：{moment(state.last_cross_day).format("YYYY/MM/DD")}（妊娠{moment(this.state.today).diff(state.last_cross_day, "days")}日）</div>
                        )}
                        { isForBreeding && (
                            <div>
                                <span data-testid="breeding-state">繁殖状態：{BREEDING_STATES.find(s => BREEDING_STATE[s].no === state.cow.breeding_state) ?? ""}</span>
                                { state.cow.open_day != null && (
                                    <span data-testid="breeding-openday">（OPEN予定 {moment(state.cow.open_day).format("M月D日")}）</span>
                                )}
                                { canBreeding && state.cow.is_active === 1 && <span data-testid="breeding-edit" style={{marginLeft:"6px"}} className="link" onClick={this.onShowBreedingStatePopup} >変更</span>}
                            </div>
                        )}
                        {(state.cow.note != null && state.cow.note !== "") && (
                            <div>
                                <i className="fa fa-comment"></i>
                                <span className="m-l-5">{CommonUtil.truncate(state.cow.note, 19)}</span>
                                <a className="link m-l-5" onClick={this.onNote}>全文</a>
                            </div>
                        )}
                    </Collapse>
                </SwipeToNextPanel>

                <div className="product" style={{ flex:1, background:"#EEEEEE", minHeight:0 }}>
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix p-0">
                            <Nav tabs style={{ minHeight:"41px" }} className="white-tab">
                                <NavItem style={{ flex:1 }}>
                                    <NavLink data-testid="schedule-tab"
                                        className={classnames({ active: this.state.activeTab === '3' })}
                                        style={{ textAlign: "center" }}
                                        onClick={() => {
                                            this.toggleTab('3');
                                        }}
                                    >
                                        <span className="d-sm-none">予定</span>
                                        <span className="d-sm-block d-none">予定</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem style={{ flex:1 }}>
                                    <NavLink data-testid="event-tab"
                                        className={classnames({ active: this.state.activeTab === '1' })}
                                        style={{ textAlign: "center" }}
                                        onClick={() => {
                                            this.toggleTab('1');
                                        }}
                                    >
                                        <span className="d-sm-none">イベント履歴</span>
                                        <span className="d-sm-block d-none">イベント履歴</span>
                                    </NavLink>
                                </NavItem>
                                { this.state.isOfficialRanch && (
                                    <NavItem style={{ flex:1 }}>
                                        <NavLink data-testid="graph-tab"
                                            className={classnames({ active: this.state.activeTab === '2' })}
                                            style={{ textAlign: "center" }}
                                            onClick={() => {
                                                this.toggleTab('2');
                                            }}
                                        >
                                            <span className="d-sm-none">グラフ</span>
                                            <span className="d-sm-block d-none">グラフ</span>
                                        </NavLink>
                                    </NavItem>
                                )}
                            </Nav>
                            <TabContent activeTab={this.state.activeTab} className={"white-tab-content " + styles["tab-content"]}>
                                <TabPane tabId="1" className={styles["event-tab-pane"]}>
                                    <div className={styles["event-tab-content"]}>
                                        <div className={styles["event-list-container"]}>
                                            {/* ※直接の親要素が overflow: auto だとsafariでstickyしないので divでラップしておく */}
                                            <div>
                                                <MultiFilter modalTitle="種別選択"
                                                    items={this.state.eventFilterItems}
                                                    selectedValues={this.state.selectedEventKinds}
                                                    onChange={vals => this.setState({ selectedEventKinds: vals })}
                                                    prefix="event-"
                                                    style={{ margin: "10px 0" }}
                                                />
                                                <CowEventHisList
                                                    dailyDataList={this.state.daily_data}
                                                    selectedEventKinds={this.state.selectedEventKinds}
                                                    ranchId={ranchId}
                                                    user={user}
                                                    onEventItemClick={this.onEventItemClick}
                                                    fecesColors={this.props.rootStore.options.feces_color}
                                                    fecesStates={this.props.rootStore.options.feces_state}
                                                    feedTypes={this.props.rootStore.options.feed_type}
                                                    treatKinds={this.props.rootStore.options.treat_kind}
                                                    ranchFeeds={this.state.ranchFeeds}
                                                    ranchHouses={this.state.location_sites}
                                                    onWriteReexamine={this.onWriteReexamine}
                                                    showOtherPlDetail={this.showOtherPlDetail}
                                                    showSellCowDetail={this.showSellCowDetail}
                                                    showSellMilkDetail={this.showSellMilkDetail}
                                                    deliveryHistory={this.state.deliveryHistory}
                                                    crossHistory={this.state.crossHistory}
                                                />
                                                {
                                                    this.state.has_more_events && (
                                                        <LoadMore style={{ textAlign:"center", marginTop:"15px" }}
                                                            onClick={() => this.loadEvents(false)}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                        {/* ※現状は iPadOS も含まれる */}
                                        { isBrowser && (
                                            <div className={styles["event-list-footer"]}>
                                                <button className="btn btn-orange" onClick={() => this.exportCsv()}
                                                    disabled={this.state.executing || this.state.selectedEventKinds.size === 0}>CSV出力</button>
                                            </div>
                                        )}
                                    </div>
                                </TabPane>
                                <TabPane tabId="2" style={{ height: "98%", width:"100%" }}>
                                    <div style={{display:"flex", flexDirection:"column", alignItems:"flex-end", width:"100%"}}>
                                        <select className="form-control" style={{width:"120px", marginTop:"4px", alignSelf:"flex-end"}}
                                            value={this.state.currentGraphKind}
                                            onChange={e => this.onGraphKindSelected(e.target.value)}>
                                            { GRAPH_KINDS.filter(k => isForMilk || GRAPH_KIND[k].milkUseOnly !== true)
                                                .map(k => (
                                                    <option value={k}>{GRAPH_KIND[k].name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    { this.state.currentGraphKind === "feed" ? (<>
                                        { canRefFeedAmount && (
                                            <GraphPager containerStyle={{ lineHeight:1 }}
                                                hasPrev={this.state.has_prev_feed}
                                                hasNext={this.state.has_next_feed}
                                                onPrev={() => this.onPrevFeedGraph()}
                                                onNext={() => this.onNextFeedGraph()}
                                            >
                                                <div style={{ fontSize:"0.8125rem", flex:1, marginLeft:"30px", marginBottom:"2px" }}>
                                                    <FormRadio prefix="radAmountListKey"
                                                        options={[{ name:"種別", value:1 }, { name:"品目", value:2 }]}
                                                        className="p-t-5"
                                                        value={this.state.feed_amount_list_key === "type" ? 1 : 2 }
                                                        onChange={n => this.onFeedGraphKeyChanged(n === 1 ? "type" : "item") }
                                                    />
                                                </div>
                                            </GraphPager>
                                        )}
                                        <div style={{ width:"100%", height:"76%", overflow:"hidden" }}>
                                            { canRefFeedAmount ? (
                                                <FeedingGraph
                                                    dayFrom={this.state.feed_chart_start_day}
                                                    dayTo={this.state.feed_chart_end_day}
                                                    amountList={this.state.amount_list}
                                                    onDaySelect={this.onGraphDayClick}
                                                />
                                            ) : (
                                                <div>参照権限がありません</div>
                                            )}
                                        </div>
                                    </>) : this.state.currentGraphKind === "milk" ? (
                                        <SellMilkGraphContainer
                                            ranchId={this.props.rootStore.cur_ranch_id}
                                            cowId={this.state.cow.cow_id}
                                            birthday={this.state.cow.birthday}
                                            initialEndDay={this.state.today}
                                        />
                                    ) : (<>
                                        <div style={{ width:"100%", height:"80%", overflow:"hidden" }}>
                                            { canRefGrowth ? (<>
                                                { this.state.cow.birthday == null && (
                                                    <div>グラフを見るには生年月日を設定してください</div>
                                                )}
                                                { this.state.cow.birthday != null && this.state.growthData != null && (
                                                <GrowthGraph
                                                    onDaySelect={this.onGraphDayClick}
                                                    actuals={this.state.growthData.actuals}
                                                    standards={this.state.growthData.standards}
                                                    birthday={this.state.cow.birthday}
                                                    unit="kg"
                                                />
                                                )}
                                            </>) : (
                                                <div>参照権限がありません</div>
                                            )}
                                        </div>
                                    </>)}
                                </TabPane>
                                <TabPane tabId="3" style={{ height: "100%", width:"100%" }}>
                                    <div className="fc-cow" style={{ height: "100%", minHeight: "300px", width:"100%", padding:"2px" }}>
                                        { canBrowseInside ? (
                                            <FullCalendar defaultView="listMonth" plugins={[ listPlugin ]} locale="ja"
                                                        buttonText={{prev:"<", next:">"}}
                                                        header={{left:"prev",right:"next addButton",center:"title"}}
                                                        titleFormat={{month:"short"}}
                                                        allDayText="終日"
                                                        noEventsMessage="登録された予定はありません"
                                                        eventClick={this.onScheduleClick}
                                                        datesRender={this.onDatesRender}
                                                        customButtons={this.state.cow.is_active === 1 ? {addButton:{text:"予定の追加", click:this.onAddScheduleClick}} : undefined}
                                                        eventDataTransform={ev => {
                                                            //終日のイベントが1日短く表示される対処
                                                            if (ev.allDay) {
                                                                ev.end = moment(ev.end).add(1, "day").toDate();
                                                            }
                                                            return ev;
                                                        }}
                                                        events={this.state.scheduleEvents.map(e => ({ ...e, title:e.dispTitle }))}
                                                        eventRender={arg => {
                                                            const elm = arg.el as any;
                                                            const dot = elm.cells[1]?.children[0];
                                                            if (dot != null) {
                                                                dot.style.background = arg.event.extendedProps.dotColor;
                                                            }
                                                        }}
                                            />
                                        ) : (
                                            <div>参照権限がありません</div>
                                        )}
                                    </div>
                                </TabPane>
                            </TabContent>
                        </div>
                    </div>
                </div>
                {/* <!-- BEGIN checkout-footer --> */}
                <div style={{ marginTop:"4px", marginBottom:"-6px" }}>
                    <ActionIconBar
                        ranchId={this.props.rootStore.cur_ranch_id}
                        user={this.props.rootStore.user}
                        optionalStyles={{ height:"100%" }}
                        showsBreedingGroup={isForBreeding}
                        onBreedingClick={this.onBreeding}
                        showsSingleCowIcon={true}
                        onRutDetailClick={this.onRut}
                        onDeliveryClick={this.onDelivery}
                        onLocationClick={this.onMoveCow}
                        onFeedingClick={this.onFeedHistory}
                        onFeedingLeftClick={this.onFeedLeft}
                        onSymptomClick={this.onConditionHeal}
                        onPreventionClick={this.onPrevention}
                        onScheduleClick={this.onAddScheduleClick}
                        onBalanceClick={this.onBalanceClick}
                        onSellCowClick={this.onSellCowClick}
                        showsSellMilk={isForMilk}
                        onSellMilkClick={this.onSellMilkClick}
                        onOtherPlCowClick={this.onOtherPlClick}
                        onProgramClick={this.onProgramClick}
                        onGrowthClick={this.onGrowth}
                        onSovClick={this.onSovClick}
                        onOpuClick={this.onOpuClick}
                        onIvfClick={this.onIvfClick}
                        onIvfEggClick={this.onIvfEggClick}
                        onCertClick={this.onCertClick}
                        isActive={this.state.cow.is_active === 1}
                        arrowLeft={6}
                    />
                </div>
                {/* <!-- END checkout-footer --> */}
                {
                    state.popup_note_open && (
                        <CowNotePopup
                            isOpen={true}
                            note={state.cow.note}
                            onClose={() => this.setState({ popup_note_open: false })}
                        />
                    )
                }
                {
                    this.state.isSchedulePopupShown && (
                    <SchedulePopup onClose={this.onSchedulePopupClose} onStartEdit={this.onStartScheduleEdit} original={this.state.currentScheduleEvent}
                                   onRegistered={this.onFinishScheduleEdit} onSplit={this.onScheduleSplit}
                                   isEditing={this.state.isScheduleEditing} onCowClick={this.onScheduleCowClick}
                                   ranch_id={this.props.rootStore.cur_ranch_id} onResultLinkClick={this.onScheduleLinkClick}
                                   activeCows={this.props.rootStore.getActiveCows()}
                                   clinic_id={this.props.rootStore.getClinicIdForMaster()}
                                   rootStore={this.props.rootStore}
                                   />
                )}
                { this.state.isBreedingStatePopupShown && (
                    <BreedingStatePopup
                        openday={this.state.tmpOpenDay}
                        state={this.state.cow.breeding_state}
                        onClose={()=> this.setState({isBreedingStatePopupShown: false}) }
                        onSubmit={this.onSubmitBreedingState}
                        isSubmitExecuting={this.state.executing}
                    />
                )}
                { this.state.currentEventDetail != null && (
                    <TextModal
                        onClose={()=>this.setState({ currentEventDetail:undefined})}
                        text={this.state.currentEventDetail.texts}
                        title={this.state.currentEventDetail.title}
                        buttons={this.state.currentEventDetail.onClick == null ? [] : [ { className:"btn btn-green", text:"編集", onClick: this.state.currentEventDetail.onClick } ]}
                    />

                )}
            </div>
        )
    }
}

export default withRouter(withContext(CowInfo));