import React from 'react';
import { DeliveryAggregateTransitionMonthDto } from '../../api';
import { Table } from "reactstrap";
import styles from '../report-sellcow/report-sellcow.module.css';
import moment from 'moment';
import { enumerateStartOfMonth } from '../../config/util';

interface MyProps {
    records: DeliveryAggregateTransitionMonthDto[];
    today: Date;
    from: Date;
    to: Date;
}

export const buildHeaders = (from: Date, to: Date, forCsv: boolean): string[][] => {
    const dates = enumerateStartOfMonth(from, to);

    if (forCsv) {
        return [[
            "",
            ...dates.map(d => moment(d).format("YYYY年M月")),
            "累計"
        ]];
    } else {
        const years = [ "", ...dates.map((d,i) => i === 0 || d.getMonth() === 0 ? moment(d).year() + "年" : "") ];
        const months = [ "", ...dates.map(d => (d.getMonth() + 1) + "月") ];
        years.push("累計");
        months.push("");
        return [ years, months ];
    }
}

export const getRowValues = (from: Date, to: Date, records: DeliveryAggregateTransitionMonthDto[], forCsv: boolean, today: Date) => {

    const startOfNowMonth = moment(today).startOf("month");

    const targets = records.map(t => ({ ...t, ymStr:moment(t.lastday).format("YYYYMM") }));
    const dates = enumerateStartOfMonth(from, to);

    const mDataList = dates.map(d => {
        const mom = moment(d);
        const ymStr = mom.format("YYYYMM");
        const target = targets.find(t => t.ymStr === ymStr);
        if (target == null) {
            return { plan: 0, actual: mom.isAfter(startOfNowMonth) ? undefined : 0, diff: 0 };
        }

        return {
            plan: target.plan,
            actual: (target.actual === 0 && mom.isAfter(startOfNowMonth)) ? undefined : target.actual,
            interval: target.interval_days,
            diff: target.actual - target.plan
        }
    });

    const sumPlan = mDataList.reduce((p,c) => p + c.plan, 0);
    const sumAct = mDataList.reduce((p,c) => p + (c.actual ?? 0), 0);
    const sumDiff = mDataList.reduce((p,c) => p + (c.diff), 0);
    const sumInterval = calcTotalInterval(records);
    
    return [
        [ "分娩予定", ...mDataList.map(m => m.plan), sumPlan ],
        [ "分娩実績", ...mDataList.map(m => m.actual ?? ""), sumAct ],
        [ "計画差異", ...mDataList.map(m => (forCsv || m.diff <= 0) ? m.diff : `+${m.diff}`), (forCsv || sumDiff <= 0) ? sumDiff : `+${sumDiff}`],
        [ "平均分娩間隔 (日)", ...mDataList.map(m => m.interval ?? ""), sumInterval ?? ""]
    ];
}

const calcTotalInterval = (records: DeliveryAggregateTransitionMonthDto[]) => {
    const targets = records.filter(r => r.interval_days != null);
    if (targets.length === 0) return undefined;

    const sumCount = targets.reduce((p,c) => p + c.count_for_interval_days, 0);
    if (sumCount === 0) return undefined;
    const sumIntervals = targets.reduce((p,c) => p + (c.interval_days! * c.count_for_interval_days), 0);
    return Math.round(sumIntervals / sumCount);
}

export const ReportDeliveryTransitionTable = React.memo((props: MyProps) => {

    return (
        <Table className={styles.table}>
            <thead>
                { buildHeaders(props.from, props.to, false).map((row,ri) => (
                    <tr key={ri}>
                        { row.map((h,i) => <th key={i} className={ri === 0 ? "p-r-0" : ""}>{h}</th>)}
                    </tr>
                ))}
            </thead>
            <tbody>
                { getRowValues(props.from, props.to, props.records, false, props.today).map((r,i) => (
                    <tr key={i}>
                        { r.map((d, ii) => (
                            <td className={ii === 0 ? styles["row-header"] : ""} key={ii}>{d}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </Table>

    );
});