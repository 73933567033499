import React from 'react';

interface MyProps {
    className?: string;
    onChange: (value: number) => void;
    isInline?: boolean;
    value?: number;
    options: Array<{ name: string, value: number }>;
    prefix?: string;
    disabled?: boolean;
}

export class FormRadio extends React.Component<MyProps> {

    constructor(props) {
        super(props);
    }

    render() {
        const prefix = this.props.prefix ?? "formradio_"

        return (
            this.props.options.map((option, i) => (
                <div className={"radio radio-css mr-3 " + (this.props.className ?? "") + ((this.props.isInline !== false) ? " radio-inline" : "")} key={i}>
                    <input type="radio"
                        id={prefix + i}
                        onChange={() => this.props.onChange(option.value)}
                        checked={option.value === this.props.value}
                        disabled={this.props.disabled}
                    />
                    <label htmlFor={prefix + i} style={{ color: this.props.disabled ? "#AAAAAA" : undefined }}>
                        {option.name}
                    </label>
                </div>
            ))
        )
    }
}