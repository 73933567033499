import React from 'react';

export class FormMultiLineText extends React.Component<{ className?: string }> {
    render() {
      const renderTexts = () => {
        if (typeof(this.props.children) === "string") {
          return this.props.children.split("\n").map((m,i) => <span key={i}>{m}<br/></span>)
        } else {
          return "";
        }
      }
      return (
        <div className={this.props.className}>
          {renderTexts()}
        </div>
      );
    }
}