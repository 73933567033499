import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MobileView, BrowserView, isBrowser, isTablet } from 'react-device-detect';
import moment from 'moment';
import Portal from 'reactstrap/lib/Portal';

export const SELECTABLE_MIN_DATE = new Date(1700, 0, 1);
export const SELECTABLE_MAX_DATE = new Date(3020, 11, 31);

const toDateStr = (date: Date) => moment(date).format("YYYY-MM-DD");
const toMonthStr = (date: Date) => moment(date).format("YYYY-MM");

export type PopperPlacement =
    | 'auto'
    | 'auto-start'
    | 'auto-end'
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'left'
    | 'left-start'
    | 'left-end';

type DatePickerPropsBase = {
    name: string,
    className?: string,
    value?: Date,
    placement?: PopperPlacement,
    /**
     * Modal内で使用する場合、Portalが裏に隠れてしまうので、ModalのIDを指定
     */
    portalContainerID?: string,
    monthOnly?: boolean,
    disabled?: boolean,
    /**
     * DatePickerカレンダーのpopperに指定するclassName
     */
    popperClassName?: string,
    minDate?: Date,
    maxDate?: Date,
}

type FormDatePickerProps = DatePickerPropsBase & {
    onChange: ((e:{target: {value: Date}}, name: string) => void) | undefined,
};
type OptionalDatePickerProps = DatePickerPropsBase & {
    onChange: ((e:{target: {value?: Date}}, name: string) => void) | undefined,
};

export const OptionalDatePicker: React.FC<OptionalDatePickerProps> = (props) => {
    const onChange = (value: Date) => {
        if (props.onChange) {
            const e = { target: { value: value } }
            props.onChange(e, props.name);
        }
    }

    const browserDateInput = (props) => (
        <button className="btn btn-link p-r-0 p-l-5" onClick={props.onClick} disabled={props.disabled}>
            {(props.value ?? "") === "" ? "----/--/--" : props.value}
        </button>
    )

    const onInputChange = (val: string) => {
        if (!props.onChange) return;

        //※ 本来は iOSでの「消去」ボタン押下時もここで処理したいが、
        //　 現状React側の問題でそのような挙動にならない
        //　 https://github.com/facebook/react/issues/8938
        //　 workaroundはあるが挙動が安定しないので、×ボタンを設ける形で対応する
        if (val === "" || val == null) {
            props.onChange({ target: { value: undefined }}, props.name);
            return;
        }

        const m = moment(val);
        if (!m.isValid()) {
            props.onChange({ target: { value: undefined }}, props.name);
            return;
        }

        props.onChange({ target: { value: m.toDate() }}, props.name);
    }

    return (
        <div style={{ display: "flex", alignItems:"center" }}>
            { renderDatePicker({ ...props, onChange, onInputChange, browserDateInput }) }
            { props.value != null && (
                <i className={"fas fa-times p-l-5 " + (props.disabled ? "" : "clickable")}
                    style={{ color: "red" }}
                    onClick={() => { if (props.disabled !== true) onInputChange(""); }}></i>
            )}
        </div>
    );
}


export const FormDatePicker: React.FC<FormDatePickerProps> = (props) => {

    const onChange = (value: Date) => {
        if (props.onChange) {
            const e = { target: { value: value ?? new Date() } }
            props.onChange(e, props.name);
        }
    }

    const browserDateInput = (props) => (
        <button className="btn btn-link" onClick={props.onClick} disabled={props.disabled}>{(props.value ?? "") === "" ? "----/--/--" : props.value}</button>
    )

    const onInputChange = (val: string) => {
        if (!props.onChange) return;
        const m = moment(val);
        if (!m.isValid()) return;

        props.onChange({ target: { value: m.toDate() }}, props.name);
    }

    return renderDatePicker({ ...props, onChange, onInputChange, browserDateInput });
}

const renderDatePicker: React.FC<DatePickerPropsBase & {
    onChange: (val:Date) => void,
    onInputChange: (val:string) => void,
    browserDateInput: (props:any) => JSX.Element;
}> = (props) => {

    const portalContainer = (!isBrowser || props.portalContainerID == null) ? undefined
                        : document.getElementById(props.portalContainerID);

    const portalContainerNotFound = props.portalContainerID != null && portalContainer == null;

    //※本来はこのように、指定された要素をコンテナとしたPortalで表示したいが
    //  この実装だと、Portal内でDatePickerに対するクリックイベントが発生しない。
    //　暫定対応として、コンテナを指定された場合（=Modal）は Portal を使用せず、そのまま表示するものとする
    //（PCで、かつモニタheightが極端に狭い場合はスクロールしないとカレンダーが表示されないケースがありえるが、
    //　実用上問題になることはほぼないはず）
    // const createPortal = portalContainerNotFound ? undefined : (
    //     (p: { children: React.ReactNode[] }) => {
    //
    //         //コンテナIDの指定がなければ reactstrapのPortalを使用（親の指定が不要）
    //         if (props.portalContainerID == null) {
    //             return <Portal {...p} />;
    //         }
    //
    //         if (!CommonUtil.assertNotNull(portalContainer)) return undefined;
    //
    //         return ReactDOM.createPortal(p.children, portalContainer);
    // });

    const minDate = props.minDate ?? SELECTABLE_MIN_DATE;
    const maxDate = props.maxDate ?? SELECTABLE_MAX_DATE;

    return (
        <>
            <MobileView>
                <input type={props.monthOnly ? "month" : "date"}
                    value={props.value == null ? "" : moment(props.value).format(props.monthOnly ? "YYYY-MM" : "YYYY-MM-DD")}
                    style={{ border: "1px solid #d5dbe0", padding:"2px 0px 2px 1px", borderRadius: "4px", background:"white", width:"105px", fontSize:"14px", minHeight:"22px" }}
                    min={props.monthOnly ? toMonthStr(minDate) : toDateStr(minDate)}
                    max={props.monthOnly ? toMonthStr(maxDate) : toDateStr(maxDate)}
                    onChange={e => props.onInputChange(e.target.value)}
                    disabled={props.disabled}
                />
            </MobileView>
            {/* ※ iPadが両方にヒットする不具合対処 */}
            { !isTablet &&
            (<BrowserView>
                <DatePicker className={"form-control " + (props.className ? props.className : "")}
                    dateFormat={props.monthOnly ? "yyyy/MM" : "yyyy/MM/dd"}
                    popperPlacement={ props.placement ?? "bottom-end" }
                    selected={props.value}
                    customInput={<props.browserDateInput />}
                    onChange={props.onChange}
                    minDate={minDate}
                    maxDate={maxDate}
                    showMonthYearPicker={props.monthOnly === true}
                    //※上記コメント参照
                    popperContainer={props.portalContainerID != null ? undefined : Portal}
                    disabled={props.disabled}
                    popperClassName={props.popperClassName}
                />
            </BrowserView>
            )}
        </>
    )
}