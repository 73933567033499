import React from 'react';
import { DynamicsAggregateDto, DynamicsAggregateMonthDto, DynamicsAggregateTotalDto } from '../../api';
import { Table } from "reactstrap";
import { ar, zeroPad } from '../../config/util';
import moment from 'moment';
import styles from '../report-sellcow/report-sellcow.module.css';

interface MyProps {
    data: DynamicsAggregateDto;
    year: number;
    now: Date;
}

export const buildHeader = (year: number, now: Date, forCsv: boolean) => {
    const vals = [year + "年"];
    const nowMonth = moment(now).startOf("month");
    const suffix = (month: number) => {
        if (!forCsv) return "";
        const m = moment(`${year}-${zeroPad(month, 2)}-01`);
        return m.isBefore(nowMonth) ? "" : m.isSame(nowMonth) ? "（暫定）" : "（予測）";
    }
    vals.push(...ar.intRange(1, 12).map(n => `${n}月${suffix(n)}`));
    vals.push(forCsv ? "累計" : "累計 (予測)");
    if (forCsv) {
        vals.push("累計（予測）");
    }
    return vals;
}
const COL_IDX_TOTAL = 13;   //累計の列index

type MonthKind = "past"|"current"|"future";

export const getRowValues = (year: number, data: DynamicsAggregateDto, forCsv: boolean) => {
    const rtn: Array<string|number>[] = [];

    if (data.months.length !== 12) {
        console.error("invalid records", data);
        return [];
    }

    const genRow = (isPredict:(monthKind: MonthKind) => boolean, pick:(record: DynamicsAggregateMonthDto|DynamicsAggregateTotalDto) => string|number) => {
        const rtn: Array<string|number> = [];
        
        data.months.forEach(m => {
            let val = pick(m);
            if (val !== "" && !forCsv && isPredict(m.month_kind as MonthKind)) {
                val = `(${val})`;
            }
            rtn.push(val);
        });

        if (forCsv) {
            rtn.push(pick(data.total));
            rtn.push(pick(data.total_future_included));
        } else {
            const future = pick(data.total_future_included);
            if (future === "") {
                rtn.push(pick(data.total));
            } else {
                rtn.push(`${pick(data.total)} (${pick(data.total_future_included)})`);
            }
        }
        return rtn;
    }

    const withPlusMinus = (val:number|undefined) => val == null ? "" : (!forCsv && val > 0) ? `+${val}` : val;

    rtn.push([ "在頭数",      ...genRow(m => m !== "past",   m => ("lastday" in m) ? (m.cow_count ?? "") : "") ]);  //累計値はあまり意味がないので非表示
    rtn.push([ "頭数 前月差", ...genRow(m => m !== "past",   m => withPlusMinus(m.cow_count_diff)) ]);
    rtn.push([ "導入頭数",    ...genRow(m => m === "future", m => m.accepted ?? "") ]);
    rtn.push([ "出生頭数",    ...genRow(m => m === "future", m => m.born ?? "") ]);
    rtn.push([ "出荷頭数",    ...genRow(m => m === "future", m => m.sold ?? "") ]);
    rtn.push([ "退牧頭数",    ...genRow(m => m === "future", m => m.left ?? "") ]);
    rtn.push([ "事故頭数",    ...genRow(m => m === "future", m => m.dead ?? "") ]);
    rtn.push([ "事故率 (%)",  ...genRow(m => m !== "past",   m => m.dead_percent ?? "") ]);
    rtn.push([ "事故頭数 目標",     ...genRow(m => m !== "past",   m => m.dead_expect ?? "") ]);
    rtn.push([ "事故頭数 計画差異", ...genRow(m => m !== "past",   m => withPlusMinus((m.dead == null || m.dead_expect == null) ? undefined : m.dead - m.dead_expect)) ]);
    return rtn;
}

export const DynamicsTable = React.memo((props: MyProps) => {

    return (
        <Table className={styles.table}>
            <thead>
                <tr>
                    { buildHeader(props.year, props.now, false).map((h,i) => (
                        <th key={i} style={i === COL_IDX_TOTAL ? { minWidth:"74px" } : {}}>{h}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                { getRowValues(props.year, props.data, false).map((r,i) => (
                    <tr key={i}>
                        { r.map((d, ii) => (
                            <td className={ii === 0 ? styles["row-header"] : ""} key={ii}>{d}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </Table>

    );
});