import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import styles from './preset-item-edit-popup.module.css';
import { FormRadio } from '../../components/form/form-radio';
import { TreatInput, OptionalMedicineInput } from '../treatbulk/TreatInput';
import { FreezedArray } from '../../config/util';
import { ITreatItem, ITreatKind, IMedicineRoute, IMedicineCategory, IMedicine } from '../../stores';
import { TeamTreatPresetItemDto } from '../../api';
import { getDefaultBenefitType, TeamType, TreatBenefitType } from '../../config/constant';
import { ExecutionButton } from '../../components/buttons/execution-button';

export type EditingPresetItem = Omit<TeamTreatPresetItemDto, "benefit_type"> & { benefit_type: TreatBenefitType };

type PropType = {
    teamId: number
    onClose: () => void;
    onDelete: () => void;
    onSubmit: (data: EditingPresetItem) => void;
    data: EditingPresetItem | undefined;

    treats: FreezedArray<ITreatItem>;
    treatKinds: FreezedArray<ITreatKind>;
    routes: FreezedArray<IMedicineRoute>;
    medicineCategories: FreezedArray<IMedicineCategory>;
    medicines: FreezedArray<IMedicine>;
    teamType: TeamType;
}

const MEDICINE = 1;
const TREAT = 2;
type TType = typeof MEDICINE | typeof TREAT;

export const PresetItemEditPopup = (props: PropType) => {
    const [ data, setData ] = useState<EditingPresetItem>({ benefit_type:"NO_BENEFIT" });
    const [ currentType, setCurrentType ] = useState<TType>(MEDICINE);
    const [ isValidInput, setIsValidInput ] = useState(false);

    useEffect(() => {
        if (props.data == null) {
            setCurrentType(MEDICINE);
            setData({ benefit_type:getDefaultBenefitType(props.teamType === "clinic") });
            return;
        }

        if (props.data.treat_kind_no == null) {
            setCurrentType(MEDICINE);
        } else {
            setCurrentType(TREAT);
        }
        setData(props.data)

    }, [ props.data ]);

    useEffect(() => {
        const isValid = currentType === MEDICINE ? data.medicine_id != null : data.treat_item_no != null;
        setIsValidInput(isValid);
        
    }, [ data ])

    const onTypeChange = (tp: number) => {
        setCurrentType(tp === MEDICINE ? MEDICINE : TREAT);
        setData({ benefit_type:data.benefit_type })
    }

    const onSubmit = () => {
        props.onSubmit(data);
    }

    return (
        <div>
        <Modal isOpen={true} style={{ maxWidth: "320px", margin: "auto" }}>
            <ModalHeader toggle={props.onClose} className={ styles.header }>
                <FormRadio
                    prefix="treatkind"
                    options={[ { name: "投薬", value:MEDICINE }, { name: "処置", value: TREAT } ]}
                    value={currentType}
                    onChange={v => onTypeChange(v)}
                    className="m-b-10"
                />
            </ModalHeader>
            <ModalBody>
                { currentType === MEDICINE ? (
                    <OptionalMedicineInput
                        input={{ ...data, unit_price:0, team_id:props.teamId }}
                        medicineCategories={props.medicineCategories}
                        ranchMedicines={props.medicines}
                        routes={props.routes}
                        onChange={d => setData(d) }
                        idPrefix="modal"
                        canEditMaster={false}
                        hasBenefit={props.teamType === "clinic"}
                        // ※以下は便宜的な値
                        ranchId={props.teamId}
                        clinicId={undefined}
                        clinicMedicines={[]}
                    />
                ) : (
                    <TreatInput
                        idPrefix="modal"
                        input={{ ...data, unit_price:0 }}
                        treatKinds={props.treatKinds}
                        treats={props.treats}
                        onChange={d => setData(d)}
                        canEditMaster={false}
                        hasBenefit={props.teamType === "clinic"}
                    />
                )}
            </ModalBody>
            <ModalFooter className="modal-footer-fix">
                <ExecutionButton type="decide" disabled={!isValidInput} onClick={onSubmit} />
                { props.data != null && (
                    <ExecutionButton type="delete" onClick={props.onDelete} />
                )}
            </ModalFooter>
            </Modal>
        </div>
    )
}
