import React from 'react';
import classNames from 'classnames/bind';
import { Attribute } from '.';
import baseStyles from './feedbulk.module.css';
import styles from './RegisterFeed.module.css';

let styleCxt = classNames.bind(styles);

interface CowProps {
    id: number,
    name: string,
    repId: string,
    earTag: boolean,
    selected : boolean,
    attrs : Attribute,
}

interface MyProps<T extends CowProps> {
    cow           : T;
    toggleSelect  : (event: React.ChangeEvent<HTMLInputElement>, cow: T) => void;
    openEditModal : (cow : T) => void;
    showsOvereat  : boolean;
    toRowData     : (cow: T) => JSX.Element;
}

export const BulkCowRow = <T extends CowProps>({ cow, toggleSelect, openEditModal, showsOvereat, toRowData }: MyProps<T>) => (

    <div data-testid={`牛:${cow.repId}`} className={ styleCxt('cow-row', {'cow-row-exclude': !cow.selected, 'cow-row-notice': cow.attrs.moved || cow.attrs.watched || cow.attrs.overeat }) }>
      <input data-testid="牛選択" type="checkbox" className={ styleCxt('row-checkbox') } checked={ cow.selected } onChange={ e => toggleSelect(e, cow) } />
      <div className={ styleCxt('cow-col-name') }>
        <div className={ styleCxt('cow-name') } >
          { cow.earTag ?
            <span className={ styleCxt('cow-name-align') }>{ cow.repId }</span>
            :
            <span>{ `(${cow.repId})` }</span>
          }
          <span>{ cow.name }</span>
        </div>
        <div className={ styleCxt('cow-attrs') } >
          <span data-testid="属性:移動" className={ styleCxt({'attr-house-active' : cow.attrs.moved})} ><i className="fas fa-warehouse"></i></span>
          <span data-testid="属性:要観察" className={ styleCxt({'attr-cation-active' : cow.attrs.watched})} ><i className="fas fa-exclamation-triangle"></i></span>
          { showsOvereat && (
            <span data-testid="属性:過食" className={ styleCxt({'attr-overeat-active' : cow.attrs.overeat} , styleCxt('attr-small')) } ><i className="fab fa-2x fa-pagelines"></i></span>
          )}
        </div>
      </div>
      <div className={ styleCxt('cow-col-feeds') }>
          { toRowData(cow) }
      </div>
      <div className={ styleCxt('cow-col-menu') }>
        <button data-testid="個別画面表示" className={ styleCxt(baseStyles.bluebutton, 'smallbutton') } disabled={ !cow.selected } onClick={ e => openEditModal(cow) }>
          <i className="far fa-edit"></i>
        </button>
      </div>
    </div>
)