import React, { useEffect } from 'react';
import styles from './balance.module.css';
import { ar } from '../../config/util';
import classnames from 'classnames';
import { RequiredNumInput } from '../../components/parts/num-input';

export interface AfterDeliveryDaysSelectorProps {
    selectedTime: number;
    maxTime: number;
    selectedDays: number;
    dayStep: number;
    onChange: (time:number, days:number) => void;
    maxDays?:number;
}

export const AfterDeliveryDaysSelector: React.FC<AfterDeliveryDaysSelectorProps> = (props) => {
    //fix time
    useEffect(() => {
        if (props.maxTime < props.selectedTime) {
            props.onChange(props.maxTime, props.selectedDays);
        }

    },[props.selectedTime, props.maxTime ])

    //fix days
    useEffect(() => {
        const step = Math.max(1, props.dayStep);
        if (props.selectedDays % step !== 0) {
            props.onChange(props.selectedTime, (props.selectedDays - (props.selectedDays % step) + step));
        }

    },[props.dayStep, props.selectedDays]);


    const onSlide = (span: number) => {
        const step = Math.max(1, props.dayStep);
        const newDays = props.selectedDays + (span * step);
        props.onChange(props.selectedTime, newDays);
    }
    const onDayChange = (val:number) => {
        props.onChange(props.selectedTime, val);
    }

    const onTimeChange = (value: string) => {
        const time = Number(value);
        if (isNaN(time)) return;

        props.onChange(time, props.selectedDays);
    }

    const dayStep = Math.max(props.dayStep, 1);
    const minDays = dayStep;
    const maxDaysIngnoreStep = props.maxDays ?? 9999;
    const maxDays = maxDaysIngnoreStep - (maxDaysIngnoreStep % dayStep);

    return (
        <div className={styles["date-selector"]}>
            <div className={styles["date-select-controls"]}>
                <select className={classnames("form-control", styles["deli-time-select"])}
                    value={props.selectedTime} onChange={e => onTimeChange(e.target.value)}>
                    { ar.intRange(1, props.maxTime).map(i => (
                        <option key={i} value={i}>{i}</option>
                    ))}
                </select>
                <div className={styles["suffix"]}>産次</div>
                <button type="button" className="btn btn-sm btn-inverse m-r-5"
                    disabled={props.selectedDays <= minDays}
                    onClick={() => onSlide(-1)}><i
                        className="fas fa-lg fa-caret-left"></i></button>
                <div>
                    <RequiredNumInput className={styles["age-input"]}
                        onChange={(n => onDayChange(n))} type="tel" step={dayStep}
                        min={minDays} max={maxDays} value={props.selectedDays} />
                </div>
                <button type="button" className="btn btn-sm btn-inverse m-l-5"
                    disabled={maxDays <= props.selectedDays}
                    onClick={() => onSlide(1)}><i
                        className="fas fa-lg fa-caret-right"></i></button>
                <div className={styles["suffix"]}>日まで</div>

            </div>
        </div>

    );
}