import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { A, LMT } from '../../config/constant';
import { RequiredNumInput } from '../../components/parts/num-input';
import { SeedLabelRangeInput } from '../setting/seed-label-range-input';
import { InfoPopup } from '../../components/parts/info-popup';
import { usePageStore } from '../../config/page-settings';
import { AppState } from '../../app';
import { findEggRank, findEggStage } from '../../config/egg-kind';

export interface LotInfo {
    unitPrice: number;
    labelFrom: string;
    labelTo: string;
    note: string;
}
export interface LotInfoForEdit {
    unitPrice?: number;
    labelFrom?: string;
    labelTo?: string;
    note?: string;
    rank: number;
    stage: number;
    isFrozen: boolean;
    egg_count: number;
}

interface MyProps extends LotInfoForEdit {
    onSubmit: (data: LotInfo)=>void;
    onClose: ()=>void;
    onClear: ()=>void;
}

export const EggLotPopup = (props: MyProps) => {
    const [ unitPrice, setUnitPrice ] = useState(0);
    const [ labelFrom, setLabelFrom ] = useState("");
    const [ labelTo, setLabelTo ] = useState("");
    const [ note, setNote ] = useState("");

    const { showToast } = usePageStore() as AppState;

    useEffect(() => {
        setUnitPrice(props.unitPrice ?? 0);

    }, [ props.unitPrice ]);

    useEffect(() => {
        setLabelFrom(props.labelFrom ?? "");
        setLabelTo(props.labelTo ?? "");

    }, [ props.labelFrom, props.labelTo ]);

    useEffect(() => {
        setNote(props.note ?? "");
    }, [ props.note ]);

    const onSubmit = () => {
        if (labelFrom === "") {
            showToast(A.MESSAGE.NO_SEED_LOT_LABEL_FROM_INPUT);
            return;
        }
        if (labelTo === "") {
            showToast(A.MESSAGE.INVALID_NUMERIC_SEED_LOT_LABEL_FROM);
            return;
        }

        props.onSubmit({
            labelFrom, labelTo, unitPrice, note
        });
    }
    
    const buildTitle = () => {
        const rank = findEggRank(props.rank)?.name ?? "";
        const stage = findEggStage(props.stage)?.name ?? "";
        return `${rank} ${stage}（${props.isFrozen ? "凍結" : "新鮮" }）`;
    }

    return (
        <div>
            <Modal isOpen={true} centered={true}>
                <ModalHeader toggle={props.onClose}>{buildTitle()}</ModalHeader>
                <ModalBody style={{ overflowY: "auto", maxHeight: "calc(100vh - 150px)" }}>
                    <div className="form-group" style={{display:"flex"}}>
                        <label className="col-form-label" style={{width:"110px"}}>単価</label>
                        <div>
                            <div style={{display:"flex", alignItems:"center"}}>
                                <RequiredNumInput
                                    testId="count"
                                    style={{ width:"70px", marginRight:"2px" }}
                                    value={props.egg_count}
                                    disabled={true}
                                    onChange={() =>{}}
                                />
                                <span>個 ×</span>
                                <RequiredNumInput
                                    testId="unit-price"
                                    min={LMT.SEED.STOCK_PRICE_MIN} max={LMT.SEED.STOCK_PRICE_MAX} step={LMT.SEED.STOCK_PRICE_STEP}
                                    style={{ width:"90px", marginLeft:"2px", marginRight:"2px" }}
                                    value={unitPrice}
                                    onChange={val => setUnitPrice(val)}
                                />
                                <span>円</span>
                            </div>
                            <div className="m-t-10">
                                <span>仕入値 {props.egg_count * unitPrice}円</span>
                                <InfoPopup
                                    message={["牧場内で交配に使用した際、", "母牛の個体収支に計上される金額です"]}
                                    placement="top"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group" style={{display:"flex", alignItems: "flex-start"}}>
                        <label className="col-form-label" style={{width:"110px"}}>ラベルNo.範囲<br/><span style={{fontSize:"0.7rem"}}>({A.SEED_TYPE.EGG.label_unit}別Lot No.)</span></label>
                        <SeedLabelRangeInput
                            style={{ flex: 1 }}
                            from={labelFrom}
                            to={labelTo}
                            maxLength={LMT.SEED.STOCK_LABEL_LEN}
                            onChange={(f,t) => { setLabelFrom(f); setLabelTo(t); }}
                            count={props.egg_count}
                        />
                    </div>
                    <div className="form-group" style={{display:"flex"}}>
                        <label className="col-form-label" style={{width:"110px", flexShrink:0}}>メモ</label>
                        <textarea className="form-control" style={{ flex: 1 }} maxLength={LMT.SEED.LOT_MEMO_LEN}
                            value={note}
                            data-testid="memo"
                            onChange={e => setNote(e.target.value)}
                        />
                    </div>
                </ModalBody>
                <ModalFooter style={{ display:"flex", justifyContent:"center"}}>
                    <button className="btn btn-green m-l-10 m-r-10" onClick={onSubmit}>確定</button>
                    { props.unitPrice != null && (
                        <button className="btn btn-danger m-l-10 m-r-10" onClick={props.onClear}>クリア</button>
                    )}
                </ModalFooter>
            </Modal>
        </div>
    )
}