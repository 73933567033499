import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { usePageStore } from '../../config/page-settings';
import moment from 'moment';
import styles from './schedule.module.css';
import { FormDatePicker } from '../../components/form/form-date-picker';
import { CowsPopup, CowToDispInfo, ICowNameInfo } from '../../components/parts/cows-popup';
import { RanchScheduleModifyReq, RanchScheduleDto, RanchApi, CowListDto, RanchSchedulePresetDto, TeamTreatPresetDto, TeamTreatPresetItemDto, TeamTreatNamedPresetItemDto, SymptomModifyReq, SymptomApi, BreedingModifyReq, BreedingApi, PreventionModifyReq, PreventionApi, RutModifyReq, RutApi, GenericResponseProgramApplyResultDto, PreloadedProgramReq, GenericResponseVoid } from '../../api';
import { CommonUtil, ar } from '../../config/util';
import { SelectCowsPopup } from '../cow/select-cows-popup';
import { A, LMT, TIMESPAN, TREAT_BENEFIT_TYPE } from '../../config/constant';
import { IEventWriteParamCow, historyLocation } from '../../config/history-location-builder';
import { LinkParam } from '../top/Dashboard';
import { AppState } from '../../app';
import { EventKind, EVENT_KIND } from '../../config/event-kind';
import { useCommunicator } from '../../api/communicator';
import { TimeSelector } from '../../components/parts/time-selector';
import { ColorPicker } from '../../components/parts/color-picker';
import { IconLink } from '../../components/parts/icon-link';
import { TreatPresetSelectPopup } from '../../components/parts/treat-preset-select-popup';
import RootStore from '../../stores/RootStore';
import { IconRight } from '../../components/parts/icon-right';
import { convPresetToTreatSelectorData } from '../../components/parts/treat-selector';
import { selectEventTypeParam } from '../cow/cow-event';
import { calcRouteFeeOf } from '../../config/medicine-route-fee-calculator';
import { calcTreatUnitPrice } from '../../config/treat-unit-price-calculator';
import { AxiosPromise } from 'axios';
import { showPreloadedProgramSelectDialog, PreloadedProgramSelectPopupProps, PreloadedProgramSelectPopup } from '../program/preloaded-program-select-popup';
import { DIALOG_BUTTONS } from '../../components/form/form-dialog';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { usePresets } from '../../stores/fetcher';
import { FetchWaiter, FetchError } from '../../components/content/fetch-state';
import { UserTeams } from 'config/user-teams';

const EXEC_STATUS = "status";
const EXEC_WRITE = "write";
const EXEC_PRESET = "preset";
type ScheduleExecution = "status"|"write"|"preset";

export interface ISchedule {
    ranchId: number | undefined;
    clinicId: number | undefined;
    dispTitle: string;
    start:Date;
    end:Date;
    id:number;
    eventKindNo: number;
    cows: Array<IEventWriteParamCow & { is_active:number }>;
    note: string;
    allDay: boolean;
    rawTitle: string;
    status: boolean;
    results: { event_id: number, occured_at: Date, cow:ICowNameInfo|undefined }[];
    customColor?: string;
    creator: string;
    editedInfo: string | undefined;
    preset: RanchSchedulePresetDto | undefined;
}

export function EventDtoToISchedule(dto:RanchScheduleDto, cowsToTitle: (cows:IEventWriteParamCow[]) => string): ISchedule | null {
    if (dto.event_kind_no == null
        || dto.schedule_id == null
        || dto.start_at == null
        || dto.end_at == null
        || dto.title == null
        || (dto.status !== 1 && dto.status !== 0)) {

        console.warn("invalid schedule dto", dto);
        return null;
    }

    const res: ISchedule = {
        ranchId: dto.ranch_id,
        clinicId: dto.clinic_id,
        rawTitle: dto.title,
        eventKindNo: dto.event_kind_no,
        id: dto.schedule_id,
        start: moment(dto.start_at).toDate(),
        end: moment(dto.end_at).toDate(),
        allDay: dto.has_time !== 1,
        note: dto.note ?? "",
        preset: dto.preset,
        cows: (dto.cows ?? []).map(cw => ({ ...cw, use_no:cw.use_no, breed_no:cw.breed_no })),
        dispTitle:"",
        status: dto.status === 1,
        customColor: dto.color == null ? undefined : `#${dto.color}`,
        results: dto.results.map(r => ({
            event_id: r.event_id,
            occured_at: moment(r.occured_at).toDate(),
            cow: r.cow_id == null ? undefined : dto.cows?.find(c => c.cow_id === r.cow_id)
        })),
        creator: dto.created_by_name,
        editedInfo: dto.updated_at === dto.created_at ? undefined : `編集 ${moment(dto.updated_at).format("M月D日")} ${dto.updated_by_name}`
    };

    const titleCows = cowsToTitle(res.cows);
    if (res.rawTitle === "") {
        if (titleCows === "") {
            res.dispTitle = EventKind.find(res.eventKindNo)?.schedule?.sname ?? "";
        } else {
            res.dispTitle = titleCows;
        }
    } else {
        if (titleCows === "") {
            res.dispTitle = res.rawTitle;
        } else {
            res.dispTitle = titleCows + "：" + res.rawTitle;
        }
    }

    if (res.cows.some(c => c.cow_id === 0)) {
        console.warn("invalid cow data", res.cows);
        return null;
    }
    return res;
}

export const scheduleCustomColorToReq = (customColor: string | undefined) => {
    if (customColor == null) return undefined;

    if (!customColor.startsWith("#")) {
        console.error("invalid color format", customColor);
        return undefined;
    }
    return customColor.substr(1);
}

export interface ISchedulePopupProps {
    onClose:()=>void;
    onRegistered:(withEvent: boolean, reloadsWithError: boolean)=>void;
    isEditing: boolean;
    onStartEdit:()=>void;
    original: Partial<Omit<ISchedule, "dispTitle">>;
    onCowClick:(cow_id:number)=>void;
    /**
     * 新規予定作成時に使用する、表示中画面の牧場ID（診療所カレンダーではundef）
     */
    ranch_id: number | undefined;
    /**
     * ユーザ所属の診療所ID
     */
    clinic_id: number | undefined;
    /**
     * 牧場画面（イベント詳細、記録入力）への遷移リンククリック時
     */
    onResultLinkClick:(link: LinkParam)=>void;
    activeCows: Readonly<CowListDto[]>;
    onSplit: (result: RanchScheduleDto[]) => void;
    rootStore: Pick<RootStore, "getCowTags"|"fetchCowTags"|"user">;
    onMoveToRanchCalendar?: () => void;
}

export const SchedulePopup = React.memo((props: ISchedulePopupProps) => {

    const comm = useCommunicator();
    const context = usePageStore() as AppState;

    const presetTeamId = props.clinic_id ?? props.ranch_id;
    const masters = usePresets(presetTeamId);

    const [ title, setTitle ] = useState("");
    const [ startAt, setStartAt ] = useState(new Date());
    const [ endAt, setEndAt ] = useState(new Date());
    const [ allDay, setAllDay ] = useState(true);
    const [ eventKindNo, setEventKindNo ] = useState(0);
    const [ note, setNote ] = useState("");
    const [ cows, setCows ] = useState<IEventWriteParamCow[]>([]);
    const [ isCowSelectShown, setIsCowSelectShown ] = useState(false);
    const [ status, setStatus ] = useState(false);
    const [ executing, setExecuting ] = useState(false);
    const [ customColor, setCustomColor ] = useState<string>();
    const [ preset, setPreset ] = useState<RanchSchedulePresetDto>();
    const [ isPresetSelectShown, setIsPresetSelectShown ] = useState(false);
    const [ selectedExecution, setSelectedExecution ] = useState<ScheduleExecution>(EXEC_STATUS);
    const [ selectedResultCow, setSelectedResultCow ] = useState<number>();
    const [ preloadedProgram, setPreloadedProgram ] = useState<PreloadedProgramSelectPopupProps>();
    const [ allEventKinds, setAllEventKinds ] = useState<{ no:number, name:string }[]>([]);

    useEffect(() => {
        const ranchId = props.original.ranchId ?? props.ranch_id;
        const events = ranchId == null
                    ? EventKind.forScheduleWithClinicUser()
                    : EventKind.forSchedule(ranchId, props.rootStore.user);
        setAllEventKinds(events.map(e => ({ no: e.no, name: e.schedule.sname })));

    }, [ props.ranch_id, props.original.ranchId, props.rootStore.user ]);

    useEffect(() => {
        reset();

    }, [ JSON.stringify(props.original) ])

    const onSubmit = async () => {
        if (eventKindNo === 0) {
            context.showToast(A.MESSAGE.NO_SCHEDULE_EVENT_KIND);
            return;
        }

        let start = moment(startAt);
        let end = moment(endAt);
        if (allDay) {
            start = start.startOf("day");
            end = end.startOf("day");
        }
        if (start.isAfter(end)) {
            context.showToast(A.MESSAGE.INVALID_SCHEDULE_TERM);
            return;
        }

        const req: RanchScheduleModifyReq = {
            ranch_id: props.original.ranchId ?? props.ranch_id,
            clinic_id: props.original.clinicId ?? props.clinic_id,
            is_new: props.original?.id == null ? 1: 0,
            schedule_id: props.original?.id ?? 0,
            title: title.trim(),
            start_at: start.format("YYYY-MM-DD HH:mm:00"),
            end_at: end.format("YYYY-MM-DD HH:mm:00"),
            has_time: allDay ? 0 : 1,
            event_kind_no: eventKindNo,
            note: note,
            cow_id: cows.map(c => c.cow_id),
            status: status ? 1 : 0,
            color: scheduleCustomColorToReq(customColor),
            preset_id: preset?.preset_id
        };

        setExecuting(true);
        context.handleSetIsLoading(true);
        //更新の場合、使用されていたプリセットが削除されているケースではエラーになるのでリロードを促す
        const res = await comm.send((await RanchApi()).modifyScheduleUsingPOST(req), { asksToReloadWhenNG:req.is_new === 0 });
        context.handleSetIsLoading(false);
        setExecuting(false);

        if (res.result !== "OK") {
            if (res.result === "NG_RELOAD_REQUIRED") {
                props.onRegistered(false, true);
            }
            return;
        }

        props.onRegistered(false, false);
    }
    const onDelete = async () => {
        const confirm = await context.showDialog("QUESTION", "この予定を削除してよろしいですか？", DIALOG_BUTTONS.DELETE_CANCEL) === 0;
        if (!confirm) return;

        if (!CommonUtil.assertNotNull(props.original.id, "id", "onDelete")) return;

        setExecuting(true);
        context.handleSetIsLoading(true);
        const res = await comm.send((await RanchApi()).deleteScheduleUsingPOST({
            ranch_id: props.original.ranchId,
            clinic_id: props.original.clinicId,
            schedule_id: props.original.id
        }));
        context.handleSetIsLoading(false);
        setExecuting(false);
        if (res.result !== "OK") return;

        props.onRegistered(false, false);
    }

    const reset = () => {
        const ori = props.original;

        setTitle(ori.rawTitle ?? "");
        setStartAt(ori.start == null ? new Date() : ori.start);
        setEndAt(ori.end == null ? new Date() : ori.end);
        setAllDay(ori.allDay ?? true);
        setEventKindNo(ori.eventKindNo ?? 0);
        setNote(ori.note ?? "");
        setCows(ori.cows ?? []);
        setStatus(ori.status === true);
        setCustomColor(ori.customColor);
        setPreset(ori.preset);
        setSelectedResultCow((ori.results ?? [])[0]?.cow?.cow_id);
    }

    const onStDateSelected = (e:{target:{value:Date}}) => {
        const start = getNewDateTimeFromSelectedDate(e.target.value, startAt);
        const isAfterEndDay = moment(e.target.value).startOf("day").isAfter(endAt);
        const end = isAfterEndDay ? getNewDateTimeFromSelectedDate(e.target.value, endAt) : endAt;

        setStartAt(start);
        setEndAt(end);
    }
    const onEdDateSelected = (e:{target:{value:Date}}) => {
        setEndAt(getNewDateTimeFromSelectedDate(e.target.value, endAt));
    }
    const getNewDateTimeFromSelectedDate = (newDate:Date, preDateTime:Date) => {
        const newVal = moment(newDate).startOf("day");
        if (!allDay) {
            newVal.add(preDateTime.getHours(), "hour");
            newVal.add(preDateTime.getMinutes(), "minute");
        }
        return newVal.toDate();
    }

    const onStTimeSelected = (value: string) => {
        if (allDay) {
           console.error("st time was selected on allday mode");
           return;
        }
        setStartAt(getNewDateTimeFromSelectedTime(value, startAt));
    }
    const onEdTimeSelected = (value: string) => {
        if (allDay) {
            console.error("ed time was selected on allday mode");
            return;
        }
        setEndAt(getNewDateTimeFromSelectedTime(value, endAt));
    }
    const getNewDateTimeFromSelectedTime = (newTime:string, preDateTime:Date) => {
        if (newTime === "") {
            newTime = "00:00";
        }
        const timeStrs = newTime.split(":");
        const newDate = moment(preDateTime).startOf("day");
        newDate.add(parseInt(timeStrs[0]), "hour");
        newDate.add(parseInt(timeStrs[1]), "minute");
        return newDate.toDate();
    }
    const setTimeRange = (start: string, end:string) => {
        if (allDay) {
            console.error("time range was selected on allday mode");
            return;
        }

        setStartAt(getNewDateTimeFromSelectedTime(start, startAt));
        setEndAt(getNewDateTimeFromSelectedTime(end, endAt));
    }

    const onCowSelectClick = () => setIsCowSelectShown(true);

    const onCowsSelected = (cows:{ cow_id: number }[]) => {
        setCows(
            ar.notNull(cows.map(c => props.activeCows.find(ac => ac.cow_id === c.cow_id)))
                .map(c => ({ ...c, use_no: c.use_no, breed_no: c.breed_no }))
        );
        setIsCowSelectShown(false);
    }

    const onEventKindChange = (no: number) => {
        let clearPreset = false;
        if (no === 0) {
            clearPreset = true;
        } else {
            const kind = EventKind.find(no);
            if (!CommonUtil.assertNotNull(kind, "eventKind=" + no) || kind.treatment == null) {
                clearPreset = true;
            
            } else if (preset?.items.some(i => i.medicine_id == null) && kind.treatment === "medicine") {
                clearPreset = true;
            }
        }

        setEventKindNo(no);
        if (clearPreset) {
            setPreset(undefined);
        }
    }

    const onSplitClick = async () => {
        if (executing) return;

        const original = props.original;
        if (!CommonUtil.assertNotNull(original.cows, "cows", "split")) return;
        if (!CommonUtil.assertNotNull(original.id, "id", "split")) return;
        if (!CommonUtil.assertNotNull(original.ranchId, "ranchId", "split")) return;

        const confMsg = `この予定を 1頭 ずつ ${original.cows.length}件 の予定に分割します`;
        const confirmed = await context.showDialog("QUESTION", confMsg, [{ type:"save" , text:"続行" }, { type:"cancel" }]) === 0;
        if (!confirmed) return;

        setExecuting(true);
        context.handleSetIsLoading(true);

        const req = { ranch_id: original.ranchId, id: original.id };
        const res = await comm.send((await RanchApi()).splitScheduleUsingPOST(req));

        context.handleSetIsLoading(false);
        setExecuting(false);

        if (res.result !== "OK") return;
        if (res.data == null || res.data.length === 0) {
            context.showToast(A.MESSAGE.NO_DATA);
            return;
        }

        props.onSplit(res.data);
    }

    const onPresetSelected = (newPreset: TeamTreatPresetDto) => {
        if (!CommonUtil.assertNotNull(masters.data, "masters.data")) return;
        if (!CommonUtil.assertNotNull(presetTeamId, "presetTeamId")) return;

        const medicines = masters.data.medicines;
        const treatItems = masters.data.treatItems;

        const presetItemToNamed = (item: TeamTreatPresetItemDto): TeamTreatNamedPresetItemDto | undefined => {
            let item_name: string;
            let item_unit: string | undefined;
            if (item.medicine_id != null) {
                const med = medicines.find(m => m.medicine_id === item.medicine_id);
                if (med == null) return undefined;
                item_name =  med.name;
                item_unit = med.unit;
            } else {
                const treat = treatItems.find(t => t.treat_kind_no === item.treat_kind_no && t.treat_item_no === item.treat_item_no);
                if (treat == null) return undefined;
                item_name = treat.name;
                item_unit = treat.fee_unit ?? undefined;
            }
            return { ...item, item_name, item_unit };
        }

        setPreset({
            ...newPreset,
            team_id: presetTeamId,
            items: ar.notNull(newPreset.items.map(presetItemToNamed))
        });
        setIsPresetSelectShown(false);
    }

    const onExecute = () => {
        if (selectedExecution === EXEC_STATUS) {
            executeUpdateStatus();
            return;
        }
        if (selectedExecution === EXEC_WRITE) {
            executeNaviToEventWrite();
            return;
        }
        if (selectedExecution === EXEC_PRESET) {
            executeFinishWithPreset()
            return;
        }

        console.error("unknown schedule execution " + selectedExecution);
    }

    const executeUpdateStatus = async () => {

        const original = props.original;
        if (!CommonUtil.assertNotNull(original.id, "original id")) return;
        if (!CommonUtil.assertNotNull(original.eventKindNo, "original eventKindNo")) return;

        const req: RanchScheduleModifyReq = {
            ranch_id: original.ranchId,
            clinic_id: original.clinicId,
            is_new: 0,
            schedule_id: original.id,
            title: original.rawTitle ?? "",
            start_at: moment(original.start).format("YYYY-MM-DD HH:mm:00"),
            end_at: moment(original.end).format("YYYY-MM-DD HH:mm:00"),
            has_time: original.allDay ? 0 : 1,
            event_kind_no: original.eventKindNo,
            note: original.note ?? "",
            cow_id: original.cows?.map(c => c.cow_id) ?? [],
            status: original.status === true ? 0 : 1,   //反転
            color: scheduleCustomColorToReq(original.customColor),
            preset_id: original.preset?.preset_id
        };

        setExecuting(true);
        context.handleSetIsLoading(true);
        const res = await comm.send((await RanchApi()).modifyScheduleUsingPOST(req), { asksToReloadWhenNG:true });
        context.handleSetIsLoading(false);
        setExecuting(false);
        if (res.result !== "OK") {
            //使用されていたプリセットが削除されているケースではエラーになるのでリロードを促す
            if (res.result === "NG_RELOAD_REQUIRED") {
                props.onRegistered(false, true);
            }
            return;
        }

        props.onRegistered(false, false);
    }
    const executeNaviToEventWrite = async () => {
        const original = props.original;
        const results = original.results ?? [];
        if (results.length > 1) {
            console.error("invalid schedule results when naviToEventWrite", results);
            return;
        }
        if (!CommonUtil.assertNotNull(original.eventKindNo, "eventKindNo")) return;
        const cows = original.cows ?? [];
        console.assert(cows.length > 0);

        const eventKind = EventKind.find(original.eventKindNo);
        if (!CommonUtil.assertNotNull(eventKind, "eventKind of " + original.eventKindNo)) return;

        if (cows.length > 1 && eventKind.singleCowOnly) {
            await context.showModalAsync(`複数頭の${eventKind.schedule?.recName ?? ""}記録をつけることはできません。\n先に予定の分割を行ってください。`, "CONFIRM", ["OK"]);
            return;
        }

        let param: LinkParam | null;
        if (results.length === 0) {
            param = historyLocation.toEventWrite(original.eventKindNo, cows, original.id, original.preset);
        }
        else {
            param = historyLocation.toEventWriteEdit(original.eventKindNo, results[0].event_id, cows);
        }
        if (!CommonUtil.assertNotNull(param, "historyLocation", "naviToEventWrite")) return;
        
        props.onResultLinkClick(param);
    }

    const executeFinishWithPreset = async () => {
        if (!CommonUtil.assertNotNull(masters.data, "masters.data")) return;
        if (!CommonUtil.assertNotNull(presetTeamId, "presetTeamId")) return;
        if (!CommonUtil.assertNotNull(props.original.ranchId, "ranchId")) return;

        const schedule_id = props.original.id;
        const preset = props.original.preset;
        if (!CommonUtil.assertNotNull(schedule_id, "scheduleId")) return;
        if (!CommonUtil.assertNotNull(preset, "preset")) return;

        const eventKind = props.original.eventKindNo == null ? undefined : EventKind.find(props.original.eventKindNo);
        if (!CommonUtil.assertNotNull(eventKind, "original eventKind " + props.original.eventKindNo)) return;

        const cow_ids = props.original.cows?.map(c => c.cow_id) ?? [];
        if (cow_ids.length === 0) {
            console.error("no cow for preset event");
            return;
        }

        const comReq = {
            watched_at: moment().format("YYYY-MM-DD HH:mm") + ":00",
            schedule_id,
            ranch_id: props.original.ranchId,
            is_new: 1,
            cow_ids,
            preloaded_program: { list:[], tags:[] }
        };

        const routes = masters.data.medicineRoutes;
        const medicines = masters.data.medicines;
        const treatItems = masters.data.treatItems;

        const validTreats = ar.notNull(
            preset.items.map(pi => convPresetToTreatSelectorData(pi,
                                        presetTeamId,
                                        medicines,
                                        routes,
                                        treatItems,
                                        { completeOnly: true }))
        );
        const treats = validTreats.map((data,i) => {
            const route = data.route_id == null ? undefined : routes.find(r => r.route_id === data.route_id);
            return {
                ...data,
                route_fee: route == null ? 0 : calcRouteFeeOf(route, validTreats.map(t => ({ route_id:t.route_id, amount:t.amount })), i),
                unit_price: calcTreatUnitPrice({ ...data, medicine_id:data.medicine_id } , route),
                ignores_washout: route?.is_instruction === 1 ? 1 : 0,
                //予防や発情では使われないがそのまま送っておく
                benefit_type:TREAT_BENEFIT_TYPE[data.benefit_type].no
            }
        });

        setExecuting(true);

        let result: boolean;
        if (eventKind.no === EVENT_KIND.SYMPTOM.no) {
            const req: SymptomModifyReq = {
                ...comReq,
                treat: treats,
                symptom_name: "",
                comment:"",
                uses_schedule_as_first_id: 1    //次回予定として登録された予定の場合、再診として記録
            };

            const api = await SymptomApi();
            result = await recordPreset(req, (r,c) => api.getProgramsForSymptomUsingPOST({ eventReq:r, clinic_id:c })(), r => api.modifySymptomUsingPOST(r)());

        } else if (eventKind.no === EVENT_KIND.BREEDING.no) {
            const req: BreedingModifyReq = {
                ...comReq,
                details: treats,
                comment:"",
                crosses:[],
                plans:[],
                structures:[],
            };

            const api = await BreedingApi();
            result = await recordPreset(req, (r,c) => api.getProgramsForBreedingUsingPOST({ eventReq:r, clinic_id:c })(), r => api.modifyUsingPOST(r)());

        } else if (eventKind.no === EVENT_KIND.PREVENTION.no) {
            const req: PreventionModifyReq = {
                ...comReq,
                detail: treats,
                is_deleted: 0,
                comment: "",
            };

            const api = await PreventionApi();
            result = await recordPreset(req, (r,c) => api.getProgramsForPreventionUsingPOST({ eventReq:r, clinic_id:c })(), r => api.modifyPreventionUsingPOST(r)());

        } else if (eventKind.no === EVENT_KIND.RUT.no) {
            const req: RutModifyReq = {
                ...comReq,
                details: treats,
                comment: "",
            };
            const api = await RutApi();
            result = await recordPreset(req, (r,c) => api.getProgramsForRutUsingPOST({ eventReq:r, clinic_id:c })(), r => api.modifyUsingPOST4(r)());

        } else {
            console.error("invalid eventkind for preset", eventKind);
            setExecuting(false);
            return;
        }

        setExecuting(false);

        if (result === false) return;

        context.showToast((eventKind.schedule?.recName ?? "") + "記録が保存されました");
        props.onRegistered(true, false);
    }

    const recordPreset = async <TReq extends { preloaded_program: PreloadedProgramReq, ranch_id: number }>(
            req: TReq,
            preloadProgram: (req:TReq, clinicId:number|undefined) => AxiosPromise<GenericResponseProgramApplyResultDto>,
            modify: (req:TReq) => AxiosPromise<GenericResponseVoid>
    ): Promise<boolean> => {

        const preloaded = await showPreloadedProgramSelectDialog(
            context,
            async () => comm.send(() => preloadProgram(req, props.clinic_id)),
            props.rootStore,
            req.ranch_id,
            props.clinic_id,
            setPreloadedProgram,
            cows
        );
        if (preloaded == null) return false;
        req.preloaded_program = preloaded;

        context.handleSetIsLoading(true);

        const res = await comm.send(() => modify(req));
        context.handleSetIsLoading(false);

        if (res.result !== "OK") return false;

        return true;
    }

    const navigateToResult = () => {
        const ori = props.original;
        const cowId = ori.results?.length === 1 ? ori.results[0].cow?.cow_id : selectedResultCow;
        if (!CommonUtil.assertNotNull(cowId, "cowId")) return;

        const result = ori.results?.find(r => r.cow?.cow_id === cowId);
        if (!CommonUtil.assertNotNull(result, "event result for cow " + cowId)) return;

        const tabType = selectEventTypeParam(ori.eventKindNo ?? 0);
        if (!CommonUtil.assertNotNull(tabType, "tabType", "navigateToResult")) {
            return;
        }
        const day = moment(result.occured_at).format("YYYY-MM-DD");
        props.onResultLinkClick(historyLocation.toCowEvent(cowId, day, tabType));
    }

    const editing = props.isEditing;

    const currentEventKind = eventKindNo === 0 ? undefined : EventKind.find(eventKindNo);

    const currentColor = customColor ?? currentEventKind?.schedule?.color ?? "white";

    const renderOptions = () => {
        if (editing) return <></>;

        const original = props.original;
        const finished = original.status === true;
        const recordCount = (original.results ?? []).length;
        //飼養終了済みの牛が混ざっていないこと
        const hasCow = ((original.cows?.length ?? 0) > 0)
            && original.cows!.every(c => c.is_active === 1);

        const eventKind = original.eventKindNo == null ? undefined
                : EventKind.find(original.eventKindNo);
        const canWrite = hasCow
                    && eventKind?.schedule != null
                    && props.original.ranchId != null
                    && eventKind.schedule.canEdit(props.original.ranchId, props.rootStore.user);
        const canEdit = canWrite
                && recordCount === 1
                //リンクを作って確認
                && (historyLocation.toEventWriteEdit(eventKind?.no ?? 0, (original.results ?? [])[0]?.event_id ?? 0, original.cows ?? []) != null)
        const eventName = eventKind?.schedule?.recName ?? "";

        return (<>
            <option value={EXEC_STATUS}>{finished ? "未完了に戻す" : "完了する"}</option>
            { canWrite && recordCount === 0 && (
                <option value={EXEC_WRITE}>{eventName}記録をつける</option>
            )}
            { canEdit && (
                <option value={EXEC_WRITE}>{eventName}記録の編集</option>
            )}
            { canWrite && recordCount === 0 && original.preset != null && isAvailablePreset(eventKind?.treatment, original.preset) && (
                <option value={EXEC_PRESET}>{(finished ? "" : "完了＆") + "処置内容を自動記録"}</option>
            )}

        </>);
    }

    const isAvailablePreset = (treatmentType: "medicine"|"treatAndMedicine"|undefined, preset: RanchSchedulePresetDto) => {
        if (!CommonUtil.assertNotNull(masters.data, "masters.data")) return false;

        if (treatmentType == null) return false;
        if (treatmentType === "medicine" && preset.items.some(p => p.medicine_id == null)) return false;

        //他組織のプリセットや、未設定項目がある場合は不可
        if (preset.team_id !== presetTeamId) return false;
        for (const item of preset.items) {
            if (item.medicine_id != null) {
                //※現時点では投与経路を必須とする
                if (item.amount == null || item.route_id == null) return false;

                //マスタにあること
                if (!masters.data.medicines.some(m => m.medicine_id === item.medicine_id)) return false;
            
            } else {
                if (item.treat_kind_no == null || item.treat_item_no == null) return false; //不正レコード

                const treatItem = masters.data.treatItems.find(t => t.treat_kind_no === item.treat_kind_no && t.treat_item_no === item.treat_item_no);
                if (treatItem == null) return false;
                if (treatItem.fee_unit != null && item.amount == null) return false;
            }
        }

        return true;
    }

    if (presetTeamId == null) return <></>; //不正

    const eventRanchId = props.original.ranchId ?? props.ranch_id;

    const canEdit = props.ranch_id != null || props.original.ranchId == null;

    const canEditEventKind = editing && (props.original.results ?? []).length === 0;
    const canEditCow = canEditEventKind && props.ranch_id != null;
    const canEditPreset = editing
                        && props.ranch_id != null
                        && currentEventKind?.treatment != null
                        && (props.original.status !== true || status !== true)
                        && currentEventKind.schedule?.canEdit(eventRanchId!, props.rootStore.user); //記録編集可能な種別に対してのみプリセットも選択させる
    
    const isClinicUser = props.clinic_id != null;
    const showsPresetBenefitWarning
        = editing
            && isClinicUser
            && preset?.team_id === presetTeamId   //自診療所プリセット選択中
            //給付金設定のないイベント種別選択中
            && (eventKindNo === EVENT_KIND.PREVENTION.no || eventKindNo === EVENT_KIND.RUT.no);

    const ranchName = props.original.ranchId == null ? "-"
                    : new UserTeams(props.rootStore.user).findRanch(props.original.ranchId)?.name ?? "";

    const ID = "schedule-popup";

    return (
        <div>
            <Modal isOpen={true} toggle={editing ? undefined : props.onClose} id={ID} scrollable={true}>
                <ModalHeader toggle={props.onClose}>
                    { !editing && (props.original.creator ?? "") !== "" && (<>
                        <div>作成者：{props.original.creator}</div>
                        { props.original.editedInfo != null && (
                            <div className={styles["edited-info"]}>{props.original.editedInfo}</div>
                        )}
                    </>)}
                </ModalHeader>
                { masters.isLoading ? (
                    <FetchWaiter />
                ) : (masters.isError || masters.data == null) ? (
                    <FetchError />
                ) : (<>
                    <ModalBody>
                        <div>
                            { !editing && canEdit && (
                                <i className="fas fa-edit clickable pull-right" style={{fontSize:"1.2rem"}} onClick={props.onStartEdit}></i>
                            )}
                            { !canEdit && props.onMoveToRanchCalendar && (
                                <IconLink className="pull-right" style={{ fontSize:"0.8125rem" }} iconType="navigate" text="牧場カレンダーで編集" onClick={props.onMoveToRanchCalendar} />
                            )}
                            { props.ranch_id == null && !editing && (
                                <div className={"form-group " + styles["popup-row"]}>
                                    <label className={"col-form-label " + styles["popup-item-header"]}>牧場</label>
                                    <div style={{flex:1}} data-testid="ranch-name">
                                        {ranchName}
                                    </div>
                                </div>
                            )}
                            <div className={"form-group " + styles["popup-row"]}>
                                <label className={"col-form-label " + styles["popup-item-header"]}>タイトル</label>
                                <div style={{flex:1}} data-testid="title">
                                    { editing && (
                                        <input className="form-control" type="text" value={title} onChange={e => setTitle(e.target.value)}
                                                maxLength={LMT.SCHEDULE.TITLE_LEN} />
                                    )}
                                    { !editing && (
                                        <span>{title}</span>
                                    )}
                                </div>
                            </div>
                            <div className={"form-group " + styles["popup-row"]}>
                                <label className={"col-form-label " + styles["popup-item-header"]}>状態</label>
                                <div style={{ flex: 1 }} data-testid="status">
                                    { editing && (
                                        <div className={"checkbox checkbox-css " + styles["popup-checkbox"]}>
                                            <input type="checkbox" id="chkStatus" checked={status} onChange={e => setStatus(e.target.checked)} />
                                            <label htmlFor="chkStatus">完了</label>
                                        </div>
                                    )}
                                    { !editing && (
                                        <div style={{ padding: "6px 0" }}>
                                            <div className={styles["popup-nested-row-content"]}>
                                                <span>{status ? "完了" : "未完了"}</span>
                                                {/* 1頭のみの予定で記録済み */}
                                                { props.original.cows?.length === 1
                                                    && props.original.results?.length === 1
                                                    && selectEventTypeParam(props.original.eventKindNo ?? 0) != null
                                                    && (
                                                    <IconLink iconType="navigate" text="記録を見る" className="m-l-20"
                                                        onClick={navigateToResult}
                                                    />
                                                )}
                                            </div>
                                            {/* 複数頭の予定で1件以上記録済み */}
                                            { (props.original.results ?? []).length > 0
                                                && (props.original.cows?.length ?? 0) > 1
                                                && selectEventTypeParam(props.original.eventKindNo ?? 0) != null
                                                && (
                                                <div className={styles["popup-nested-row-content"]} style={{ marginTop: "2px", flexWrap:"nowrap" }}>
                                                    <select className="form-control" style={{ maxWidth:"160px", width:"auto", padding: "3px 10px" }}
                                                        onChange={e => setSelectedResultCow(Number(e.target.value))}
                                                        value={selectedResultCow}>
                                                        { (props.original.results ?? [])
                                                            .filter(r => r.cow != null)
                                                            .map(r => (
                                                            <option key={r.event_id} value={r.cow!.cow_id}>{CowToDispInfo(r.cow!, true)}</option>
                                                        ))}
                                                    </select>
                                                    <IconLink iconType="navigate" text="記録を見る" className="m-l-10"
                                                        onClick={navigateToResult}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                            {editing && (<div className={"form-group " + styles["popup-row"]}>
                                <div className="radio radio-css" style={{padding:0}}>
                                    <input id="radioAllDay" type="radio" checked={allDay} onChange={()=> setAllDay(true)}></input>
                                    <label htmlFor="radioAllDay">終日</label>
                                </div>
                                <div className="radio radio-css ml-3" style={{padding:0}}>
                                    <input id="radioHasTime" type="radio" checked={!allDay} onChange={()=> setAllDay(false)}></input>
                                    <label htmlFor="radioHasTime">時間帯</label>
                                </div>
                                <span style={{visibility: allDay ? "hidden" : "visible" }}>
                                    <button type="button" className="btn btn-light btn-sm ml-2" onClick={()=>setTimeRange(TIMESPAN.AM.FROM, TIMESPAN.AM.TO)}>午前</button>
                                    <button type="button" className="btn btn-light btn-sm ml-1" onClick={()=>setTimeRange(TIMESPAN.PM.FROM, TIMESPAN.PM.TO)}>午後</button>
                                </span>
                            </div>)}
                            <div className={"form-group " + styles["popup-row"]} style={{marginBottom:"2px"}}>
                                <label className={"col-form-label " + styles["popup-item-header"]}>開始</label>
                                <div data-testid="start">
                                    { editing && (
                                        <span style={{ display:"flex", alignItems:"center" }}>
                                            <FormDatePicker name="" value={startAt} onChange={onStDateSelected}
                                                placement="auto" portalContainerID={ID} />
                                            { !allDay && (
                                                <TimeSelector mobileStyle={{ marginLeft:"2px" }} value={moment(startAt).format("HH:mm")} onChange={onStTimeSelected}/>
                                            )}
                                        </span>
                                    )}
                                    { !editing && (
                                        <span>{moment(startAt).format("YYYY/MM/DD" + (allDay ? "" : " HH:mm"))}</span>
                                    )}
                                </div>
                            </div>
                            <div className={"form-group " + styles["popup-row"]}>
                                <label className={"col-form-label " + styles["popup-item-header"]}>終了</label>
                                <div data-testid="end">
                                    { editing && (
                                        <span style={{ display:"flex", alignItems:"center" }}>
                                            <FormDatePicker name="" value={endAt} onChange={onEdDateSelected}
                                                placement="auto" portalContainerID={ID} />
                                            { !allDay && (
                                                <TimeSelector mobileStyle={{ marginLeft:"2px" }} value={moment(endAt).format("HH:mm")} onChange={onEdTimeSelected} />
                                            )}
                                        </span>
                                    )}
                                    { !editing && (
                                        <span>{moment(endAt).format("YYYY/MM/DD" + (allDay ? "" : " HH:mm"))}</span>
                                    )}
                                </div>
                            </div>
                            <div className={"form-group " + styles["popup-row"]}>
                                <label className={"col-form-label " + styles["popup-item-header"]}>種別</label>
                                <div data-testid="event-kind" className={styles["popup-nested-row-content"]}>
                                    { canEditEventKind && (
                                        <select className="form-control"
                                            value={currentEventKind?.no ?? 0}
                                            onChange={e => onEventKindChange(parseInt(e.target.value))}
                                            style={{ width: "140px" }}>
                                            <option value={0}>種別選択</option>
                                            { allEventKinds.map(e => (
                                                <option key={e.no} value={e.no}>{e.name}</option>
                                            ))}
                                        </select>
                                    )}
                                    { !canEditEventKind && (
                                        <span>{currentEventKind?.schedule?.sname ?? ""}</span>
                                    )}
                                    { editing && (
                                    <>
                                        { canEditPreset && (
                                            <IconLink className={styles["popup-preset"]}
                                                text={preset?.preset_name ?? "処置選択"} iconType="popup"
                                                onClick={() => setIsPresetSelectShown(true)} />
                                        )}
                                        { canEditPreset && preset != null && (
                                            <IconRight iconType="remove" onClick={() => setPreset(undefined)} />
                                        )}
                                        { !canEditPreset && preset != null && (
                                            <span className={styles["popup-preset"]}>{preset.preset_name}</span>
                                        )}
                                    </>
                                    )}
                                    { showsPresetBenefitWarning && (
                                        <div className={styles["popup-benefit-warning"]}>{A.MESSAGE.PRESET_BENEFIT_IGNORED}</div>
                                    )}
                                </div>
                            </div>
                            { !editing && preset != null && (
                                <div className={"form-group " + styles["popup-row"]}>
                                    <label className={"col-form-label " + styles["popup-item-header"]}>処置内容</label>
                                    <div>
                                        <div>{preset.preset_name}</div>
                                        { preset.items.map(p => ({
                                                ...p,
                                                dispAmount: p.amount == null ? "" : `${p.amount}${p.item_unit}`,
                                                //※他組織プリセットの経路は反映されない
                                                routeName: p.route_id == null ? null : masters.data!.medicineRoutes.find(r => r.route_id === p.route_id)?.name
                                            }))
                                            .map((pi,i) => (
                                                <div className={styles["popup-preset-item"]} key={i}>- {pi.item_name} {pi.dispAmount} {pi.routeName}</div>
                                            ))
                                        }
                                    </div>
                                </div>
                            )}
                            <div className={"form-group " + styles["popup-row"]}>
                                <label className={"col-form-label " + styles["popup-item-header"]}>背景色</label>
                                <div data-testid="color">
                                    <ColorPicker color={currentColor} onChange={setCustomColor} disabled={!editing} />
                                </div>
                                { editing && customColor != null && (
                                    <button type="button" className="btn btn-light btn-sm ml-2" onClick={() => setCustomColor(undefined)}>初期値に戻す</button>
                                )}
                            </div>
                            { eventRanchId != null && (
                                <div className={"form-group " + styles["popup-row"]}>
                                    <label className={"col-form-label " + styles["popup-item-header"]}>牛</label>
                                    <div data-testid="cow">
                                        { canEditCow && (
                                            <span>
                                                <button type="button" className="btn btn-gray" onClick={onCowSelectClick}>選択</button>
                                                { cows.length === 1 && (
                                                    <span >{CowToDispInfo(cows[0])}</span>
                                                )}
                                                { cows.length > 1 && (
                                                    <span>
                                                        {cows.length}頭選択中
                                                        <CowsPopup cows={cows} placement="top" />
                                                    </span>
                                                )}
                                            </span>
                                        )}
                                        { !canEditCow && cows.length === 1 && (
                                            <span className={editing ? "" : "link"} onClick={() => { if (!editing) props.onCowClick(cows[0].cow_id) }}>{CowToDispInfo(cows[0])}</span>
                                        )}
                                        { !canEditCow && cows.length > 1 && (
                                            <>
                                            <span>
                                                {cows.length}頭選択中
                                                <CowsPopup cows={cows} placement="top" onCowClick={c => { if (!editing) props.onCowClick(c.cow_id) }} />
                                            </span>
                                            { !editing && (
                                                <IconLink iconType="copy" text="分割" className="m-l-25" style={{ fontSize:"0.8rem"}}
                                                    onClick={onSplitClick}/>
                                            )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className={"form-group " + styles["popup-row"]}>
                                <label className={"col-form-label " + styles["popup-item-header"]}>メモ</label>
                                <div style={{flex:1}} data-testid="note">
                                    { editing && (
                                        <textarea className="form-control" rows={4} maxLength={LMT.SCHEDULE.MEMO_LEN} value={note}
                                                    onChange={e => setNote(e.target.value)} />
                                    )}
                                    { !editing && (
                                        <span>{note}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter className="modal-footer-fix">
                        { editing && (
                            <ExecutionButton type="save" onClick={onSubmit} disabled={executing} />
                        )}
                        { editing && props.original?.id != null && (
                            <ExecutionButton type="delete" onClick={onDelete} disabled={executing} />
                        )}
                        { !editing && (<>
                            <select className="form-control w-auto" value={selectedExecution}
                                onChange={e => setSelectedExecution(e.target.value as ScheduleExecution)}>
                                { renderOptions() }
                            </select>
                            <button className="btn btn-green p-r-20 p-l-20" onClick={onExecute} disabled={executing}>実行</button>
                        </>)}
                    </ModalFooter>
                    { isCowSelectShown && (
                        <SelectCowsPopup cows={props.activeCows} onClose={()=> setIsCowSelectShown(false)}
                                            onSubmit={onCowsSelected} iniSelectedCows={cows} />
                    )}
                    { isPresetSelectShown && (
                        <TreatPresetSelectPopup
                            presets={masters.data.presets}
                            medicines={masters.data.medicines}
                            routes={masters.data.medicineRoutes}
                            treatItems={masters.data.treatItems}
                            selectedPreset={preset?.preset_id}
                            medicinesOnly={currentEventKind?.treatment === "medicine"}
                            onClose={() => setIsPresetSelectShown(false)}
                            onSubmit={onPresetSelected}
                        />
                    )}
                    { preloadedProgram != null && (
                        <PreloadedProgramSelectPopup
                            {...preloadedProgram}
                        />
                    )}
                </>)}
            </Modal>
        </div>
    )
});