import React, { ReactNode } from "react";
import classnames from 'classnames';
import styles from './graph-pager.module.css';

interface Props {
    hasNext: boolean;
    hasPrev: boolean;
    onNext: () => void;
    onPrev: () => void;
    children?: ReactNode;
    containerClassName?: string;
    containerStyle?: React.CSSProperties;
}

export const GraphPager = React.memo((props:Props) => {

    return (
        <div className={classnames(styles.container, props.containerClassName)}
            style={props.containerStyle}>
            <div onClick={props.onPrev} className={classnames("clickable", styles.arrow)}
                style={{ visibility:props.hasPrev ? "visible" : "hidden" }}>
                <i className="fas fa-lg fa-angle-left" />
            </div>
            { props.children ?? <></> }
            <div onClick={props.onNext} className={classnames("clickable", styles.arrow)}
                style={{ visibility:props.hasNext ? "visible" : "hidden" }}>
                <i className="fas fa-lg fa-angle-right" />
            </div>
        </div>
    )

})