import React from 'react';

import { useRootStore } from '../../stores';

import baseStyles from './feedbulk.module.css'
import styles from './ConditionSelect.module.css'
import { V3DateTime } from '../../components/parts/v3-date-time-picker';
import { TIMEPRESETS } from '../../config/constant';
import { ActionCowSelector } from '../../components/parts/action-cow-selector';


type PropType = {
  ready     : boolean,
  ranchId   : number,
  date      : Date,
  dateName  : string,
  setDate   : (date: Date) => void,
  checkedCowIds: Set<number>,
  setCheckedCowIds: React.Dispatch<React.SetStateAction<Set<number>>>,
}

const ConditionSelect: React.FC<PropType> = ({ ready, ranchId, date, dateName, setDate, checkedCowIds, setCheckedCowIds }) => {
  const rootStore = useRootStore()

  const allCows =  rootStore.getActiveCows(ranchId)

  return (
    <section className={ baseStyles.section }>
      <p className={ styles.label }>{dateName}日時</p>
      <div data-testid={dateName + "日時"} className={ styles.inputarea }>
        <V3DateTime
          timePresets={rootStore.user.setting?.time_preset ?? TIMEPRESETS}
          value={ date }
          onChange={ m => setDate(m.toDate()) }
          popperPlacement="bottom-start"
        />
      </div>
      <div>
        {ready ?
          <ActionCowSelector
            checkedCowIds={ checkedCowIds}
            setCheckedCowIds={ setCheckedCowIds }
            cows={ allCows }
            singleSelection={false}
            ranchId={ ranchId }
          />
        :
        <div className={ baseStyles.loader }>Loading...</div>
      }
      </div>
    </section>
  )
}
export default ConditionSelect