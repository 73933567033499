import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import classnames from 'classnames'
import classNames from 'classnames/bind'

import { Cow, Feed } from '../index'

import baseStyles from '../feedbulk.module.css'
import styles from './Edit.module.css'
import { LMT } from '../../../config/constant';
import { RequiredNumInput } from '../../../components/parts/num-input';
import { useRanchFeeds } from '../../../stores/fetcher';
import { FetchWaiter, FetchError } from '../../../components/content/fetch-state';

let styleCxt = classNames.bind(styles);


type FeedTemp = Feed & {
  typeErr   : boolean,
  amountErr : boolean,
}

type PropType = {
  ranchId      : number,
  showModal    : boolean,
  setShowModal : (showModal: boolean) => void,
  cow          : Cow,
  setWatch     : (cow: Cow, watching: boolean, memo: string) => void,
  setCows      : React.Dispatch<React.SetStateAction<Array<Cow>>>,
}

const EditModal: React.FC<PropType> = ({ ranchId, showModal, setShowModal, cow, setWatch, setCows }) => {

  const mFeeds = useRanchFeeds(ranchId);

  const [tempFeeds, setTempFeeds] = useState<Array<FeedTemp>>([]);
  const [tempWatch, setTempWatch] = useState(false);
  const [tempWatchMemo, setTempWatchMemo] = useState("");

  useEffect(() => {
    setTempFeeds(cow.feeds.map(feed => ({...feed, typeErr : false, amountErr : false})))
    setTempWatch(cow.attrs.watched)
    setTempWatchMemo(cow.attrs.watched_memo)
  }, [showModal, cow])


  const selectFeed = (event: React.ChangeEvent<HTMLSelectElement>, now: FeedTemp) => {
    if (mFeeds.data == null) return;

    const no = parseInt((event.target as HTMLSelectElement).value)
    const feed = mFeeds.data.find(f => f.feed_no === no)
    if ( feed ) {
      setTempFeeds(tempFeeds.map(f => f.id === now.id ? { id: feed.feed_no, name: feed.name, type: feed.feed_type_no, unit: feed.unit, price:feed.unit_price, amount: 0, typeErr : false, amountErr : false } : f))
    } else {
      setTempFeeds(tempFeeds.map(f => f.id === now.id ? { ...f, id: -1, name: '', typeErr : true, amountErr : false } : f))
    }
  }

  const changeAmount = (val: number, now: FeedTemp) => {
    if ( !Number.isNaN(val) ) {
      setTempFeeds(tempFeeds.map(f => f.id === now.id ? { ...f, amount: val, amountErr : false } : f))
    } else {
      //※ 実際は発生しないはず
      setTempFeeds(tempFeeds.map(f => f.id === now.id ? { ...f, amount: NaN, amountErr : true } : f))
    }
  }

  const add = () => {
    setTempFeeds([...tempFeeds, { id: -1, name: '', type: -1, unit: '', price:0, amount: 0, typeErr : true, amountErr : false }])
  }

  const remove = (feed:FeedTemp) => {
    setTempFeeds(tempFeeds.filter(f => f.id !== feed.id))
  }

  const save = () => {
    setCows(cows => cows.map(c => c.id === cow.id ? { ...c, feeds: tempFeeds as Array<Feed> } : c))
    if (cow.attrs.watched !== tempWatch || cow.attrs.watched_memo !== tempWatchMemo) {
      setWatch(cow, tempWatch, tempWatchMemo)
    }
    setShowModal(false)
  }

  const cowNo = cow.earTag ? cow.repId : `(${cow.repId})`;

  return (
    <div className="modal-wide">
      <Modal isOpen={ showModal } className={ baseStyles['modal-wrapper'] } style={{ maxWidth: "340px", margin: "auto" }}>
        <ModalHeader>{ `個別編集 : ${cowNo} ${ cow.name }` }</ModalHeader>
        { mFeeds.isLoading ? (
          <FetchWaiter />
        ) : (mFeeds.isError || mFeeds.data == null) ? (
          <FetchError />
        ) : (
          <ModalBody className={ baseStyles['modal-body'] }>
            {tempFeeds.map(feed =>
              <div key={ feed.id } className={ baseStyles.noticerow }>
                <select data-testid={ `個別えさ種類:${feed.name}` } className={ styleCxt('select', {'select-error': feed.typeErr})} value={ feed.id } onChange={ e => selectFeed(e, feed) }>
                  <option value='' key="default"></option>
                  { mFeeds.data
                    .filter(f => feed.id === f.feed_no || !tempFeeds.some(t => t.id === f.feed_no))
                    .map(f => <option key={ `${f.ranch_id}---${f.feed_no}`} value={ f.feed_no }>{ f.name }</option>) }
                </select>
                を
                <RequiredNumInput testId={ `個別えさ量:${feed.name}` } className={ styleCxt('input', {'input-error': feed.amountErr})} 
                  min={LMT.FEEDING.AMOUNT_MIN} max={LMT.FEEDING.AMOUNT_MAX} step={LMT.FEEDING.AMOUNT_STEP}
                  value={ feed.amount } onChange={ val => changeAmount(val, feed) } />
                {/* <input value={ feed.amount } onChange={ e => changeAmount(e, feed) } onFocus={e => e.target.select()}/> */}
                <span className={ styleCxt('value-unit')}>{ feed.unit }</span>
                <i data-testid={ `個別えさ削除:${feed.name}` } className="fas fa-times" onClick={() => remove(feed) }></i>
              </div>
            )}

            <div className={ styleCxt('plus-bar') }>
              <button className={ styleCxt('plus') } data-testid="個別追加" onClick={e => add() } disabled={ tempFeeds.some(t => t.id === -1) }>
                <i className="fas fa-plus"></i>
              </button>
            </div>

            <hr className={ baseStyles.spliter } />

            <p className={ styleCxt('title') }>この牛の状態を変更できます</p>
            <div className={ styleCxt('state-row') }>
              <div className={ styleCxt('header') }>状態</div>
              <div className={ styleCxt('state-value') }>
                <div className="checkbox checkbox-css p-t-0" data-testid="要観察切り替え">
                  <input id="chkWatching" checked={ tempWatch } type="checkbox"
                    onChange={e => setTempWatch(e.target.checked)}
                  />
                  <label htmlFor="chkWatching" className={ tempWatch ? styles.active : "" }>要観察</label>
                </div>
              </div>
            </div>
            <div className={ styleCxt('state-row') }>
              <div className={ styleCxt('header') }>観察事項</div>
              <textarea className={ classnames("form-control", styles["state-val"]) }
                maxLength={LMT.COW.WATCHING_MEMO_LEN}
                value={ tempWatchMemo } onChange={ e => setTempWatchMemo(e.target.value) } />
            </div>
          </ModalBody>
        )}
        <ModalFooter>
          <button data-testid="個別保存" className={ baseStyles.bluebutton } onClick={e => save()} disabled={ tempFeeds.some(f => f.typeErr || f.amountErr) }>保存</button>
          <button data-testid="個別キャンセル" className={ baseStyles.graybutton } onClick={e => setShowModal(false)}>キャンセル</button>
        </ModalFooter>
      </Modal>
    </div>
  )
}


export default EditModal

