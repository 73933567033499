import { A, checkInvalidCowPattern } from "./constant";
import { ICowBreed, IMedicine, ITreatItem, ICowUse } from "../stores";
import { FreezedArray } from "./util";

export interface CowTriggerCondition {
    start_kind? : number | null;
    breed_no? : number | null;
    use_no?: number | null;
}
export interface RutTriggerCondition {
    has_sign? : boolean | null;
    medicine_id? : number | null;
}

export interface BreedingTriggerCondition {
    is_preg? : boolean | null;
    preg_cross_type? : number | null;
    medicine_id? : number | null;
    treat_kind_no? : number | null;
    treat_item_no? : number | null;
}

export interface SymptomTriggerCondition {
    medicine_id? : number | null;
    treat_kind_no? : number | null;
    treat_item_no? : number | null;
}

export type PreventionTriggerCondition = SymptomTriggerCondition;

export interface CrossTriggerCondition {
    cross_type? : number | null;
}

export interface DeliveryTriggerCondition {
    is_aborted? : boolean | null;
}

export type ProgramTriggerCondition = CowTriggerCondition | RutTriggerCondition | BreedingTriggerCondition | SymptomTriggerCondition | PreventionTriggerCondition | CrossTriggerCondition | DeliveryTriggerCondition;

export const checkInvalidCondition = (cond: ProgramTriggerCondition, breeds: FreezedArray<ICowBreed>, uses: FreezedArray<ICowUse>) => {
    //牛属性
    if ("breed_no" in cond || "use_no" in cond) {
        const res = checkInvalidCowPattern(breeds, uses, undefined, cond.breed_no ?? undefined, cond.use_no ?? undefined);
        if (res != null) return res;
    }

    return undefined;
}

export const listConditionNames = (cond: ProgramTriggerCondition, breeds: FreezedArray<ICowBreed>, uses: FreezedArray<ICowUse>, medicines: FreezedArray<IMedicine>, treats: FreezedArray<ITreatItem>) => {
    const UNKNOWN = "?";

    const rtn: string[] = [];
    if ("start_kind" in cond && cond.start_kind != null) {
        rtn.push([...A.START_KIND.values()].find(s => s.no === cond.start_kind)?.name ?? UNKNOWN);
    }
    if ("breed_no" in cond && cond.breed_no != null) {
        rtn.push(breeds.find(b => b.breed_no === cond.breed_no)?.name ?? UNKNOWN);
    }
    if ("use_no" in cond && cond.use_no != null) {
        rtn.push(uses.find(u => u.use_no === cond.use_no)?.name ?? UNKNOWN);
    }
    if ("has_sign" in cond && cond.has_sign === true) {
        rtn.push("観察項目あり");
    }
    if ("is_preg" in cond && cond.is_preg === true) {
        rtn.push("妊娠鑑定+");
    }
    if ("preg_cross_type" in cond && cond.preg_cross_type != null) {
        const crossType = [...A.CROSS_TYPE.values()].find(t => t.no === cond.preg_cross_type);
        rtn.push((crossType?.name ?? UNKNOWN) + "後");
    }
    if ("medicine_id" in cond && cond.medicine_id != null) {
        rtn.push(medicines.find(m => m.medicine_id === cond.medicine_id)?.name ?? UNKNOWN);
    }
    if ("treat_kind_no" in cond && cond.treat_kind_no != null && cond.treat_item_no != null) {
        rtn.push(treats.find(t => t.treat_kind_no === cond.treat_kind_no && t.treat_item_no === cond.treat_item_no)?.name ?? UNKNOWN);
    }
    if ("cross_type" in cond && cond.cross_type != null) {
        rtn.push([...A.CROSS_TYPE.values()].find(t => t.no === cond.cross_type)?.name ?? UNKNOWN);
    }
    if ("is_aborted" in cond && cond.is_aborted != null) {
        rtn.push(cond.is_aborted ? "流産" : "流産でない");
    }
    return rtn;
}