import React from 'react';
import moment from 'moment';
import { CowSearchReqEvent } from '../../api';
import { RequiredNumInput } from '../../components/parts/num-input';
import { CommonUtil } from '../../config/util';
import { EventKind, EVENT_KIND } from '../../config/event-kind';
import { IUser } from '../../stores';
import { Checkbox } from '../../components/form/form-checkbox';

interface EventConditionSelectorProps {
    ranch_id: number;
    user: IUser;
    values: CowSearchReqEvent | undefined;
    onChange: (values?: CowSearchReqEvent) => void;
    index: number;
    className?: string;
}

export const calcBeforeDaysFromDate = (today: moment.Moment, date: moment.Moment) => {
    return today.diff(date, "day");
}

export const calcDateFromBeforeDays = (today: moment.Moment, days: number) => {
    return moment(today).subtract(days, "day").format("YYYY-MM-DD");
}

const toOptValue = (eventKind: number | undefined, exEventKind?: number) => {
    return `${eventKind ?? -1}__${exEventKind ?? -1}`;
}
const fromOptValue = (value: string) => {
    if (value === "") return undefined;
    const vals = value.split("__").map(s => Number(s));
    if (vals.length !== 2 || vals.some(n => isNaN(n))) {
        console.error("invalid eventKind", value);
        return undefined;
    }

    return {
        eventKind: vals[0] === -1 ? undefined : vals[0],
        exEventKind: vals[1] === -1 ? undefined : vals[1]
    };
}

export const EventConditionSelector = React.memo<EventConditionSelectorProps>((props) => {

    const today = moment().startOf("day");
    const values = props.values;

    const from = values == null ? undefined : moment(values.date_from);
    const to = values == null ? undefined : moment(values.date_to);

    const onEventKindChange = (val: string) => {
        const parsed = fromOptValue(val);
        if (parsed == null) {
            props.onChange(undefined);
            return;
        }

        const newValues: CowSearchReqEvent = {
            event_kind: parsed.eventKind,
            excluded_event_kind: parsed.exEventKind,
            date_from: props.values?.date_from ?? calcDateFromBeforeDays(today, 1),
            date_to: props.values?.date_to ?? calcDateFromBeforeDays(today, 0),
        }
        props.onChange(newValues);
    }

    const onFromChange = (val: number) => {
        if (!CommonUtil.assertNotNull(values)) return;
        if (!CommonUtil.assertNotNull(to)) return;

        const date_from = calcDateFromBeforeDays(today, val);
        //逆転を補正
        const toVal = calcBeforeDaysFromDate(today, to);
        const date_to = toVal <= val ? values.date_to : date_from;
        
        props.onChange({ ...values, date_from, date_to });
    }
    const onToChange = (val: number) => {
        if (!CommonUtil.assertNotNull(values)) return;
        if (!CommonUtil.assertNotNull(from)) return;

        const date_to = calcDateFromBeforeDays(today, val);
        //逆転を補正
        const fromVal = calcBeforeDaysFromDate(today, from);
        const date_from = val <= fromVal ? values.date_from : date_to;

        props.onChange({ ...values, date_from, date_to });
    }

    const eventKind = props.values?.event_kind == null ? undefined : EventKind.find(props.values.event_kind);

    return (
        <div className={props.className} data-testid="event-condition">
            <div style={{ display:"flex", flexWrap:"wrap" }}>
                <select className="form-control" style={{ width:"220px", marginRight:"6px", marginBottom:"2px" }}
                        value={props.values == null ? "" : toOptValue(props.values.event_kind, props.values.excluded_event_kind)}
                        onChange={e => onEventKindChange(e.target.value)}>
                    <option value="">選択</option>
                    <option value={toOptValue(undefined, undefined)}>すべての種別</option>
                    <option value={toOptValue(undefined, EVENT_KIND.FEEDING.no)}>すべての種別（えさ以外）</option>
                    { EventKind.forCowSearch(props.ranch_id, props.user)
                        .map(ev => (
                            <option key={ev.no} value={toOptValue(ev.no, undefined)}>{ev.nameForCowSearch}</option>
                        ))
                    }
                </select>
                { values != null
                    && CommonUtil.assertNotNull(from)
                    && CommonUtil.assertNotNull(to)
                    && (<>
                    <div style={{ display:"flex", flexWrap:"nowrap", alignItems:"center", marginBottom:"2px" }}>
                        <RequiredNumInput
                            min={0} max={364} step={1} style={{ width: "76px" }}
                            value={calcBeforeDaysFromDate(today, from)}
                            onChange={onFromChange}
                        />
                        日前～
                        <RequiredNumInput
                            min={0} max={364} step={1} style={{ width: "76px" }}
                            value={calcBeforeDaysFromDate(today, to)}
                            onChange={onToChange}
                        />
                        日前
                    </div>
                    <div style={{ fontSize: "0.8rem" }}>（{from.format("YYYY/MM/DD")} ～ {to.format("YYYY/MM/DD")}）</div>
                </>)}
            </div>
            { eventKind != null && eventKind.canSearchByTreat && (
                <Checkbox label="処置のある記録のみ" id={"chkOnlyTreat_" + props.index} checked={props.values?.only_treat === true}
                    onChange={e => props.onChange({ ...props.values!, only_treat:e.target.checked })}
                />
            )}
        </div>
    )
})