import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { PageSettings } from '../../config/page-settings.js';
import { withContext } from '../../stores';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import styles from './user.module.css'
import classnames from 'classnames';
import { PasswordReminderPopup } from './password-reminder-popup';
import { AppState } from '../../app';
import { UserApi, SigninReq } from '../../api';
import { loadUserStorage } from '../../stores/local-storage';
import { UserTeams } from '../../config/user-teams';

interface MyProp extends BaseProps {
}
interface MyState {
    activeBg: string,
    email: string,
    password: string,
    saveLogin: boolean,
    isPasswordReminderShown: boolean;
}

class Login extends Base<MyProp, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            activeBg: '/assets/img/login-bg/login-bg.jpg',
            email: "",
            password: "",
            saveLogin: false,
            isPasswordReminderShown: false,
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
        this.handleSaveLogin = this.handleSaveLogin.bind(this);

        firebase.auth().onAuthStateChanged(user => {
            if (user == null) {
                //自動ログイン済みでない場合（画面表示後に1度コールされる）
                this.context.handleSetIsLoading(false);
            } else {
                this.api_postLogin(user);
            }
        });
    }

    componentDidMount() {
        this.context.handleSetHeader(undefined);
        this.context.handleSetFooter(false);
        this.context.handleSetPageError(false);

        //自動ログインかどうかが確定するまでローディングを表示しておく
        this.context.handleSetIsLoading(true);
    }

    componentWillUnmount() {
        this.context.handleSetHeader({ title:"" });
        this.context.handleSetFooter(true);
    }

    handleEmail(e) {
        this.setState({ email: e.target.value });
    }

    handlePassword(e) {
        this.setState({ password: e.target.value });
    }

    handleSaveLogin(e) {
        this.setState({ saveLogin: e.target.checked });
    }

    canSubmit() {
        return this.state.email !== "" && this.state.password !== "";
    }

    onEnter(e:React.KeyboardEvent<HTMLInputElement>) {
        if(e.keyCode === 13) {
            e.preventDefault();
            if(this.canSubmit()) {
                this.handleSubmit();
            }
        }
    }

    async handleSubmit() {
        //すでにfirebase側はログイン済みの場合（再試行時など）
        const u = firebase.auth().currentUser;
        if (u != null) {
            await firebase.auth().setPersistence(this.state.saveLogin ? "local" :"session")
            await this.api_postLogin(u);
            return;
        }

        this.context.handleSetIsLoading(true);

        let email = this.state.email;
        if ( email.indexOf('@') < 0) {
            email = email + "@example.com";   //TODO @example.comのメールアドレスでのログイン
        }
        await firebase.auth().signInWithEmailAndPassword(email, this.state.password)
            .then(() => {
                return firebase.auth().setPersistence(this.state.saveLogin ? "local" :"session")
            })
            .catch(err => {
                this.context.handleSetIsLoading(false);

                console.error(err);
                this.context.showToast("ログインに失敗しました。");
            });
        
        //※onAuthStateChangedが走るまでloadingを表示したままにしておく
    }

    register() {
        window.location.replace("register");
    }

    /*******************************************************************************
     * API: User Login
     * /user/signin
     *******************************************************************************/

    api_postLogin = async (user: firebase.User) => {

        if(!user.emailVerified) {
            if( (user.email?.indexOf('@example.com') ?? -1) === -1 ) { //TODO @example.comのメールアドレスでのログイン
                this.context.handleSetIsLoading(false);
                window.location.replace('sendmail');
                return;
            }
        }

        this.context.handleSetIsLoading(true);
        if ((await this.props.rootStore.fetchUserInfo()) === false) {
            this.context.handleSetIsLoading(false);
            this.context.showToast("ログインに失敗しました");

            //firebase側をログアウトしておく（firebase側だけユーザ削除されていないケースで、このあと別ユーザでのログイン試行が失敗しないように）
            firebase.auth().signOut().catch(er => {
                console.error("fbログアウト失敗", er);
            });

            return;
        }
        if (await this.props.rootStore.fetchOptions(this.context) === "NG") {
            this.context.handleSetIsLoading(false);
            this.context.showToast("ログインに失敗しました");
            return;
        }

        const userTeams = new UserTeams(this.props.rootStore.user);
        let new_ranch_id = userTeams.firstRanch()?.team_id;
        //前回の選択牧場確認
        const lastRanchId = loadUserStorage<number>("default_ranch", this.props.rootStore.user.id);
        if (lastRanchId != null && userTeams.findRanch(lastRanchId)) {
            new_ranch_id = lastRanchId;
        }

        this.context.handleSetIsLoading(false);
        
        if (new_ranch_id != null) {
            window.location.replace('/top/' + new_ranch_id);
        } else {
            window.location.replace('team/search');
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="login-cover" style={{background:"#0066aa"}}>
                    {/*
                    //use background image
                    <div className="login-cover-image"
                        style={{ backgroundImage: 'url(' + this.state.activeBg + ')' }}></div>
                    */}
                    {/*
                    //use mask layer
                    <div className="login-cover-bg"></div>
                    */}
                </div>

                <div className="login login-v2">
                    <div className="login-header">
                        <div className="brand">
                            <span className="logo"></span> <b>vetell</b>
                        </div>
                    </div>
                    <div className="login-content">
                        <div className="form-group m-b-20">
                            <input type="text" className="form-control form-control-lg" placeholder="メールアドレス"
                                value={this.state.email} onChange={this.handleEmail} onKeyDown={e => this.onEnter(e)} required />
                        </div>
                        <div className="form-group m-b-20">
                            <input type="password" className="form-control form-control-lg" placeholder="パスワード"
                                value={this.state.password} onChange={this.handlePassword} onKeyDown={e => this.onEnter(e)} required />
                        </div>
                        <div className="checkbox checkbox-css m-b-20">
                            <input type="checkbox" id="remember_checkbox"
                                checked={this.state.saveLogin} onChange={this.handleSaveLogin} />
                            <label htmlFor="remember_checkbox">
                                次回から自動でログイン
                            </label>
                        </div>
                        <div className="login-buttons">
                            <button type="button" className="btn btn-success btn-block btn-lg" onClick={this.handleSubmit} disabled={!this.canSubmit()}>ログイン</button>
                        </div>
                        <div className="login-buttons m-t-5 text-right">
                            <a className={classnames(styles.link, styles.label)} onClick={() => this.setState({isPasswordReminderShown: true})}>パスワードを忘れた場合</a>
                        </div>
                        <div className="login-buttons m-t-10 text-center">
                            <span className={styles.label}>または</span>
                        </div>
                        <div className="login-buttons m-t-10 text-center">
                            <a className={styles.link} onClick={this.register}>新規登録</a>
                        </div>
                    </div>
                </div>
                { this.state.isPasswordReminderShown && (
                    <PasswordReminderPopup onClose={() => this.setState({isPasswordReminderShown: false})}/>
                )}
            </React.Fragment>
        )
    }
}

export default withRouter(withContext(Login))
