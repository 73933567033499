import { useRootStore } from "stores"
import { useLocation } from "react-router-dom";
import { RanchAuthItemKey, ClinicAuthItemKey, hasRanchAuth, hasClinicAuth } from "./auth-checker";
import { RanchContractItemKey, hasRanchContract } from "./contract-checker";
import { UserTeams } from "./user-teams";
import { useCallback } from "react";

export const useAccessCheck = () => {
    const { logout, user, cur_ranch_id } = useRootStore();
    const location = useLocation();

    const isUnallowedAccess = useCallback((teamId?: number|undefined,
                                ranchAuthes?: RanchAuthItemKey[],
                                clinicAuthes?: ClinicAuthItemKey[],
                                ranchContracts?: RanchContractItemKey[]) => {
        
        if (location.pathname !== '/') {
            if (user.id === '') {
                logout();
                return true;
            }
        }

        if (teamId == null) {
            teamId = cur_ranch_id;
        }

        const team = new UserTeams(user).findTeam(teamId);
        if (team == null) {
            logout();
            return true;
        }

        if (team.isRanch && ranchAuthes != null) {
            if (ranchAuthes.some(r => !hasRanchAuth(r, team.team_id, user))) {
                logout();
                return true;
            }
        } else if (!team.isRanch && clinicAuthes != null) {
            if (clinicAuthes.some(c => !hasClinicAuth(c, team.team_id, user))) {
                logout();
                return true;
            }
        }
        if (team.isRanch && ranchContracts != null) {
            if (ranchContracts.some(r => !hasRanchContract(r, team.team_id, user))) {
                logout();
                return true;
            }
        }
        
        return false;

    }, [ logout, user, cur_ranch_id, location ]);

    return { isUnallowedAccess };
}