import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

export interface MenuChildrenPopupProps {
    onClose: () => void;
    menu: string[];
    onSelect: (idx:number)=>void;
}

export const MenuChildrenPopup = React.memo<MenuChildrenPopupProps>((props) => {

    return (
        <div>
            <Modal isOpen={true} toggle={props.onClose} centered={true} className="modal-slim">
                <ModalHeader toggle={props.onClose}>遷移先を選択</ModalHeader>
                <ModalBody style={{ overflowY: "auto", maxHeight: "calc(100vh - 180px)" }}>
                    <div style={{ display:"flex", flexFlow:"column wrap", alignItems:"center", margin:"10px" }}>
                        { props.menu.map((m,i) => (
                            <button key={i}
                                className="btn btn-green"
                                style={{ width:"120px", margin:"12px 16px" }}
                                onClick={()=>{ props.onSelect(i) }}
                                >{m}</button>
                        ))}
                    </div>
                </ModalBody>
            </Modal>
        </div>

    );
})