import { SensorHistoryListReq, SensorApi, SensorHistoryCowReq } from "api";
import moment from "moment";
import { useQuery } from "react-query";
import { QUERY, resetOrInvalidate } from "./fetcher";

const fetchSensorHistory = async (ranchId: number, submitId: number) => {

    const req: SensorHistoryListReq = {
        ranch_id: ranchId,
        submit_id: submitId
    };;
    const api = await SensorApi();
    const res = await api.listSensorHistoryUsingPOST(req)();
    
    if (res.data.meta?.errCode !== 0 || res.data.data == null) {
        console.error(res.data);
        throw new Error(res.data.meta?.errMsg);
    }

    return res.data.data;
}

const fetchSensorCow = async (ranchId: number, cowId: number, from: Date, to: Date) => {
    const req: SensorHistoryCowReq = {
        ranch_id: ranchId,
        cow_id: cowId,
        from: moment(from).format("YYYY-MM-DD"),
        to: moment(to).format("YYYY-MM-DD")
    };

    const api = await SensorApi();
    const res = await api.getSensorHistoryCowUsingPOST(req)();
    
    if (res.data.meta?.errCode !== 0 || res.data.data == null) {
        console.error(res.data);
        throw new Error(res.data.meta?.errMsg);
    }

    return res.data.data;
}

export const useSensorHistory = (ranchId: number, submitId: number) => {
    return useQuery([ QUERY.SENSOR_HIS, submitId ],
                    () => fetchSensorHistory(ranchId, submitId),
                    { cacheTime: 5 * 60 * 1000, staleTime: 1 * 60 * 1000 });
}
export const useSensorCow = (ranchId: number, cowId: number, from: Date, to: Date) => {

    const fromStr = moment(from).format("YYYY-MM-DD");
    const toStr = moment(to).format("YYYY-MM-DD");

    return useQuery([ QUERY.SENSOR_COW, cowId, fromStr, toStr ],
                    () => fetchSensorCow(ranchId, cowId, from, to),
                    { cacheTime: 5 * 60 * 1000, staleTime: 1 * 60 * 1000 });
}

export const resetSensorHistory = async (
        submitId: number,
        immediate = true
    ) => resetOrInvalidate([QUERY.SENSOR_HIS, submitId], immediate);
export const resetSensorCow = async (
        cowId: number,
        from:Date,
        to:Date,
        immediate = true
    ) => resetOrInvalidate([QUERY.SENSOR_COW, cowId, moment(from).format("YYYY-MM-DD"), moment(to).format("YYYY-MM-DD") ], immediate);