import React, { useEffect, useState } from 'react';

import baseStyles from './otherpl.module.css'
import styles from './RegisterCows.module.css'
import classnames from 'classnames';
import { formatYen } from '../../config/util';
import { EditMemoPopup } from '../../components/parts/edit-memo-popup';
import { RequiredNumInput } from '../../components/parts/num-input';
import { CowToDispInfo, ICowNameInfo } from '../../components/parts/cows-popup';
import { V3DateTime } from '../../components/parts/v3-date-time-picker';
import { LMT, TIMEPRESETS } from '../../config/constant';
import { OtherPlContentProps, EditingOtherPlDetailData, PlTypeSelector } from '.';
import { PricePopup } from './price-popup';

const rowClass = "form-group row treat-write-row";
const rowHeaderClass = "col-form-label col-md-3 col-xs-3 text-lg-right";

const DetailRow: React.FC<{
    detail           : EditingOtherPlDetailData,
    index            : number,
    detailChange     : (idx:number, detail: EditingOtherPlDetailData) => void,
    showEditMemoPopup: (index : number, detail: EditingOtherPlDetailData) => void,
}> = ({ detail, index, detailChange, showEditMemoPopup}) => {

    const cow: Readonly<ICowNameInfo> = { ...detail, cow_id: detail.cow_id!, trace_id: detail.trace_id! };

    return (
        <div className={classnames(rowClass, styles["detail-row"])}>
            <label className={rowHeaderClass}>{ CowToDispInfo(cow, false) }</label>
            <RequiredNumInput className={baseStyles["number-input"]}
                min={LMT.OTHER_PL.PRICE_MIN} max={LMT.OTHER_PL.PRICE_MAX}
                step={LMT.OTHER_PL.PRICE_STEP}
                value={detail.price}
                onChange={v => detailChange(index, { ...detail, price: v})}
            />
            <label className={classnames("col-form-label", styles["label-unit"])}>円</label>
            <div className={classnames("link", styles["link-memo"])} onClick={() => showEditMemoPopup(index, detail)}>メモ</div>
        </div>
    )
}

const RegisterCows: React.FC<OtherPlContentProps> = ({ data, onChange, user }) => {
    const [ editingDetail, setEditingDetail ] = useState<{ index: number, detail: EditingOtherPlDetailData }|undefined>()
    const [ totalPrice, setTotalPrice ] = useState(0);
    const [ isAllPriceInputVisible, setIsAllPriceInputVisible ] = useState(false);
    const [ isAllMemoInputVisible, setIsAllMemoInputVisible ] = useState(false);

    useEffect(() => {
        setTotalPrice(data.details.map(d => d.price).reduce((p,c) => p + c, 0));

    }, [ data ]);

    if (data.details.length <= 1) return <></>;
    if (data.details.some(d => d.cow_id == null || d.trace_id == null)) return <></>;

    const detailChange = (idx:number, detail: EditingOtherPlDetailData) => {
        const details = [
            ...data.details.slice(0, idx),
            detail,
            ...data.details.slice(idx + 1)
        ];
        onChange({ ...data, details });
        setEditingDetail(undefined);
    }

    const showEditMemoPopup = (index : number, detail: EditingOtherPlDetailData) => {
        setEditingDetail({ index, detail });
    }

    return (<>
        <div className="page-root">
            <div className="product product-full-height">
                <div className="product-detail" style={{ height: "100%" }}>
                    <div className="product-info product-info-fix p-b-0">
                        <div className={baseStyles["content-body"]}>
                            <div className={rowClass}>
                                <label className={rowHeaderClass}>記録日時</label>
                                <div className={baseStyles["date-input"]}>
                                    <V3DateTime value={data.watchedAt}
                                        timePresets={user.setting?.time_preset ?? TIMEPRESETS}
                                        onChange={d => onChange({ ...data, watchedAt: d.toDate() })}
                                    />
                                </div>
                            </div>
                            <div className={rowClass}>
                                <label className={rowHeaderClass}>区分</label>
                                <PlTypeSelector value={data.profitLoss} onChange={v => onChange({ ...data, profitLoss:v })} />
                            </div>
                            <div className={styles["all-input-label-row"]}>
                                <label className={rowHeaderClass} />
                                <label className={"link " + styles["all-input-label-price"]} onClick={() => setIsAllPriceInputVisible(true)}>まとめて入力</label>
                                <label className={"link " + styles["all-input-label-memo"]} onClick={() => setIsAllMemoInputVisible(true)}>まとめて入力</label>
                            </div>
                            <hr className={styles["hr-border"]}/>
                            { data.details.map((d, i) => 
                                <DetailRow key={i} index={i} detail={d} detailChange={detailChange} showEditMemoPopup={showEditMemoPopup} />
                            ) }
                            <hr className={baseStyles["hr-border"]}/>
                            <div className={rowClass}>
                                <label className={rowHeaderClass}>合計金額</label>
                                <label className={classnames("col-form-label", styles["label-total-price"])}>{formatYen(totalPrice)}円</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <EditMemoPopup
            maxLength={LMT.OTHER_PL.MEMO_LEN}
            comment={ editingDetail?.detail?.comment ?? "" }
            isOpen={ editingDetail != null }
            close={ () => setEditingDetail(undefined) }
            onSubmit={ val => detailChange(editingDetail!.index, { ...editingDetail!.detail, comment: val }) }
        />
        { isAllPriceInputVisible && (
            <PricePopup
                onClose={() => setIsAllPriceInputVisible(false)}
                onSubmit={val => {
                    onChange({ ...data, details: data.details.map(d => ({ ...d, price: val })) });
                    setIsAllPriceInputVisible(false);
                }}
            />
        )}
        { isAllMemoInputVisible && (
            <EditMemoPopup
                isOpen={true}
                maxLength={LMT.OTHER_PL.MEMO_LEN}
                submitButton="全頭に反映"
                comment=""
                close={() => setIsAllMemoInputVisible(false)}
                onSubmit={val => {
                    onChange({ ...data, details: data.details.map(d => ({ ...d, comment: val })) });
                    setIsAllMemoInputVisible(false);
                }}
            />
        )}

    </>)
}

export default RegisterCows