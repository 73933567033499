import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { PopperPlacement } from '../form/form-date-picker';
import V3DatePicker from './v3-date-picker';

export interface PrevNextDatePickerProp {
    /**
     * 「N日前」「N日後」の基準となる日付
     */
    baseDate?: Date | moment.Moment;
    /**
     * 「N日後」の前に付与する固定文字列
     */
    dateSpanPrefix?: string;
    value: Date;
    name: string;
    onChange?: ((e:{target: {value: Date}}, name: string) => void);
    dateSelectContainerStyle?: React.CSSProperties;
    popperPlacement?: PopperPlacement;
    style?: React.CSSProperties;
    portalContainerID?: string;
}

const PrevNextDatePicker: React.FC<PrevNextDatePickerProp> = (prop) => {
    const [ dateSpan, setDateSpan ] = useState("");

    useEffect(() => {
        let newSpan: string;
        if (prop.baseDate == null) {
            newSpan = "";
        }
        else {
            const baseDate = moment(prop.baseDate).startOf("day");
            const diff = moment(prop.value).startOf("day").diff(baseDate, "days");
            newSpan = `${prop.dateSpanPrefix ?? ""} ${Math.abs(diff)}${diff < 0 ? "日前" : "日後"}`;
        }
        setDateSpan(newSpan);

    }, [prop.baseDate, prop.dateSpanPrefix, prop.value]);

    return (
        <div style={{ display:"flex", flexWrap:"wrap", ...prop.style }}>
            <V3DatePicker
                value={prop.value}
                name={prop.name}
                onChange={prop.onChange}
                popperPlacement={prop.popperPlacement}
                style={{ width:"174px", marginRight:"4px", ...prop.dateSelectContainerStyle }}
                portalContainerID={prop.portalContainerID}
            />
            { prop.baseDate && <div style={{ color:"#999", fontSize:"0.8rem", marginTop:"2px", marginLeft:"auto", alignSelf:"flex-end", justifySelf:"flex-end"}}>{dateSpan}</div> }
        </div>

    )
}


export default PrevNextDatePicker;