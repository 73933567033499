import React from 'react';
import moment from 'moment';
import { FormDatePicker, PopperPlacement } from '../form/form-date-picker';

export interface V3DatePickerProp {
    value: Date;
    name: string;
    onChange?: ((e:{target: {value: Date}}, name: string) => void);
    popperPlacement?: PopperPlacement;
    style?: React.CSSProperties;
    portalContainerID?: string;
    arrowRStyle?: React.CSSProperties;
    arrowLStyle?: React.CSSProperties;
    popperClassName?: string;
}

const V3DatePicker: React.FC<V3DatePickerProp> = (prop) => {

    const onSideClick = (span: number) => {
        if (prop.onChange == null) return;
        const param = { target: { value:moment(prop.value).add(span, "days").toDate() } };
        prop.onChange(param, prop.name);
    }

    return (
        <div style={{ display:"flex", alignItems:"center", width:"auto", ...prop.style }} data-testid="v3-datepicker">
            <i className="fas fa-chevron-left clickable"
                style={{ padding: "2px", margin:"0 4px 0 0", fontWeight:"bold", color:"#348fe2", ...prop.arrowLStyle }}
                onClick={()=>onSideClick(-1)}></i>
            <FormDatePicker value={prop.value} name={prop.name} onChange={prop.onChange} placement={ prop.popperPlacement }
                portalContainerID={prop.portalContainerID} popperClassName={prop.popperClassName} />
            <i className="fas fa-chevron-right clickable"
                style={{ padding:"2px", margin:"0 0 0 4px", fontWeight:"bold", color:"#348fe2", ...prop.arrowRStyle }}
                onClick={()=>onSideClick(1)}></i>
        </div>
    )
}


export default V3DatePicker;