import moment from 'moment';
import { WashoutDto } from '../api';
import { findWashoutItemType } from './constant';

export interface IWashout {
    item_type: number;
    item_type_name: string;
    item_name: string;
    meat? :{ from: Date, to: Date }
    milk? :{ from: Date, to: Date }
}

export const isWashout = (washouts: WashoutDto[], current_date: Date | moment.Moment): boolean => {
    const date = (current_date instanceof Date) ? current_date : current_date.toDate();
    return washouts.some(w => {
        if( ( w.end_meat != null && moment(date).isBetween(w.start_meat, w.end_meat) )
            || ( w.end_milk != null && moment(date).isBetween(w.start_milk, w.end_milk) ) ) {
            return true;
        }
        return false;
    });
}

export class Washout {
    readonly val: IWashout;

    constructor(washout: IWashout) {
        this.val = washout
    }

    isWashout(current_date: Date | moment.Moment): boolean {
        const date = (current_date instanceof Date) ? current_date : current_date.toDate();
        const data = this.val;
        if( ( data.meat != null && moment(date).isBetween(data.meat.from, data.meat.to) ) 
            || ( data.milk != null && moment(date).isBetween(data.milk.from, data.milk.to) ) ) {
            return true;
        }
        return false;
    }
}

export class Washouts {
    readonly meat?: { from: Date, to: Date }
    readonly milk?: { from: Date, to: Date }
    readonly data: Washout[];

    public static readonly MIN_DATE = moment("1700-01-01").toDate();
    public static readonly MAX_DATE = moment("9999-12-31").toDate();

    static formatDateTime(date: Date | moment.Moment) {
        const d = (date instanceof Date) ? moment(date) : date;
        return d.format("M月D日 HH:mm")
    }

    static formatEndDate(date: Date | moment.Moment) {
        const d = (date instanceof Date) ? moment(date) : date;
        return d.subtract(1, "minute").format("M/D")
    }

    constructor(washouts: WashoutDto[]) {
        const current_date = new Date();

        this.data = washouts.map(w => {
                if(w.start_meat != null && w.start_milk != null && w.start_meat !== w.start_milk) {
                    console.error("mismatch meat start_at and milk start_at", w)
                }
                const meat = w.end_meat != null ? {from: moment(w.start_meat).toDate(), to: moment(w.end_meat).toDate()}  : undefined
                const milk = w.end_milk != null ? {from: moment(w.start_milk).toDate(), to: moment(w.end_milk).toDate()}  : undefined
                return (new Washout({
                    item_type: w.item_type,
                    item_type_name: `[${findWashoutItemType(w.item_type)?.name}]`,
                    item_name: w.item_name,
                    meat : meat,
                    milk : milk,
                }))
            }).filter(w => (w.val.meat != null || w.val.milk != null) && w.isWashout(current_date))

        const min_meat_from = this.data.filter(w => w.val.meat && current_date <= w.val.meat.to).map(w => w.val.meat!.from).reduce((p,c) => p < c ? p : c, Washouts.MAX_DATE)
        const max_meat_to   = this.data.filter(w => w.val.meat && current_date >= w.val.meat.from).map(w => w.val.meat!.to).reduce((p,c) => p > c ? p : c, Washouts.MIN_DATE)
        const min_milk_from = this.data.filter(w => w.val.milk && current_date <= w.val.milk.to).map(w => w.val.milk!.from).reduce((p,c) => p < c ? p : c, Washouts.MAX_DATE)
        const max_milk_to   = this.data.filter(w => w.val.milk && current_date >= w.val.milk.from).map(w => w.val.milk!.to).reduce((p,c) => p > c ? p : c, Washouts.MIN_DATE)
        this.meat = moment(current_date).isBetween(min_meat_from, max_meat_to) ? { from: min_meat_from, to: max_meat_to } : undefined
        this.milk = moment(current_date).isBetween(min_milk_from, max_milk_to) ? { from: min_milk_from, to: max_milk_to } : undefined
    }
}