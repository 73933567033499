import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { VisitFeeType, VISIT_FEE_TYPES, VISIT_FEE_TYPE, LMT, A } from '../../config/constant';
import { ModalInputBlock } from '../../components/parts/modal-input-block';
import { OptionalNumInput } from '../../components/parts/num-input';
import { generateKey, ar, FreezedArray, CommonUtil } from '../../config/util';
import { ModifyReqVisit, VisitListDtoDistance, ModifyReqVisitFee, ClinicVisitFeeDto } from '../../api';
import { TextLinkSelect } from '../../components/parts/common-select';
import styles from './visit-fee-select-popup.module.css';
import { IconRight } from '../../components/parts/icon-right';
import { Checkbox } from '../../components/form/form-checkbox';
import { CowsPopup, IPopupCow, ICheckablePopupCow, CowToDispInfo } from '../../components/parts/cows-popup';

export type ModifyReqVisitWithName = Omit<ModifyReqVisit, "fees"> & {
    fees: Array<ModifyReqVisitFee & { name?:string }>
}

interface MyProps {
    onClose: () => void;
    onSubmit: (data: ModifyReqVisitWithName) => void;
    fromList: FreezedArray<VisitListDtoDistance>;
    feeItemList: FreezedArray<ClinicVisitFeeDto>;
    data:ModifyReqVisitWithName;
    allCows: FreezedArray<IPopupCow> | IPopupCow;
    showToast: (msg:string) => void;
}

type KeyedFee = Omit<ModifyReqVisitFee, "is_benefit"|"cow_ids"> & {
    key: string;
    name?: string;
}

export const VisitFeeSelectPopup = (props: MyProps) => {
    const [ disetance, setDistance ] = useState<number>();
    const [ fees, setFees ] = useState<KeyedFee[]>([]);
    const [ groupedFeeItemList, setGroupedFeeItemList ] = useState<{ [ key in VisitFeeType ]: ClinicVisitFeeDto[] }>({ FIRST:[], CAR:[], VISITING:[] });
    //現状、給付金対象と牛は種別ごとにまとめて設定させるので、fee項目と別で管理
    const [ groupedIsBenefit, setGroupedIsBenefit ] = useState<{ [key in VisitFeeType ]: boolean }>({ FIRST:false, CAR:false, VISITING:false });
    const [ groupedCows, setGroupedCows ] = useState<{ [key in VisitFeeType ]: ICheckablePopupCow[] }>({ FIRST:[], CAR:[], VISITING:[] });

    useEffect(() => {
        const allCows = Array.isArray(props.allCows) ? props.allCows : [ props.allCows ];
        if (allCows.length === 0) return;

        setDistance(props.data.distance_km);

        //マスタにないものを捨てる（マスタ削除済みの場合もここで破棄される）
        const originalFees = props.data.fees.filter(f => props.feeItemList.some(pf => f.fee_type === pf.fee_type && f.fee_no === pf.fee_no));

        //給付金有無の初期値の決め方
        const selectIsBenefit = (type:VisitFeeType): boolean => {
            const ori = originalFees.find(f => f.fee_type === VISIT_FEE_TYPE[type].no)?.is_benefit;
            if (ori != null) return ori === 1;

            return VISIT_FEE_TYPE[type].hasBenefit === true;
        }
        setGroupedIsBenefit({
            FIRST: selectIsBenefit("FIRST"),
            VISITING: selectIsBenefit("VISITING"),
            CAR: selectIsBenefit("CAR")
        });

        //対象牛の初期値の決め方
        const selectCows = (type:VisitFeeType): ICheckablePopupCow[] => {
            const ori = originalFees.find(f => f.fee_type === VISIT_FEE_TYPE[type].no)?.cow_ids;
            if (ori != null) {
                return allCows.map(c => ({ ...c, isChecked : ori.includes(c.cow_id) }))
            }

            const oneCow = VISIT_FEE_TYPE[type].oneCow;

            //複数頭選択の項目（初診料）はデフォルト選択なし、ただし記録対象が1頭のみのときは選択
            return allCows.map((c,i) => ({ ...c, isChecked: (i === 0 && oneCow) || allCows.length === 1 }))
        }
        setGroupedCows({
            FIRST: selectCows("FIRST"),
            VISITING: selectCows("VISITING"),
            CAR: selectCows("CAR")
        })

        setFees(originalFees.map(fee => ({
            ...fee,
            key: generateKey("fee")
        })));

    }, [ props.data, props.allCows, props.feeItemList ]);

    useEffect(() => {
        setGroupedFeeItemList({
            FIRST: props.feeItemList.filter(f => f.fee_type === VISIT_FEE_TYPE["FIRST"].no),
            VISITING: props.feeItemList.filter(f => f.fee_type === VISIT_FEE_TYPE["VISITING"].no),
            CAR: props.feeItemList.filter(f => f.fee_type === VISIT_FEE_TYPE["CAR"].no),
        });

    }, [ props.feeItemList ]);

    const onFeeSelect = (feeTypeNo:number, key: string|undefined, value:string) => {
        const feeNo = Number(value);
        if (isNaN(feeNo)) {
            console.error("invalid fee selected", feeTypeNo, key, value);
            return;
        }

        const item = props.feeItemList.find(f => f.fee_type === feeTypeNo && f.fee_no === feeNo);
        if (!CommonUtil.assertNotNull(item, `fee Item type=${feeTypeNo} no=${feeNo}`)) return;

        if (key == null) {
            setFees([
                ...fees,
                { fee_type:feeTypeNo, fee_no: feeNo, key: generateKey("fee"), name:item.name }
            ]);

        } else {
            setFees(fees.map(f => (f.fee_type === feeTypeNo && f.key === key) ? { ...f, fee_no:feeNo, name:item.name } : f))
        }
    }

    const onRemoveFee = (feeTypeNo: number, key:string) => {
        setFees(fees.filter(f => f.fee_type !== feeTypeNo || f.key !== key));
    }

    const onIsBenefitChange = (feeType: VisitFeeType, checked: boolean) => {
        const newVals = {...groupedIsBenefit};
        newVals[feeType] = checked;
        setGroupedIsBenefit(newVals);
    }

    const onCowChange = (feeType: VisitFeeType, cowId: number, idx: number, isChecked: boolean) => {
        if (groupedCows[feeType][idx].cow_id !== cowId) {
            console.error("invalid cow id on check handle", feeType, cowId, idx);
            return;
        }

        const isSingle = VISIT_FEE_TYPE[feeType].oneCow === true;

        const newVals = { ...groupedCows };
        newVals[feeType] = groupedCows[feeType].map((c,i) => ({
            ...c,
            isChecked: i === idx ? isChecked
                    : isSingle ? false
                    : c.isChecked
        }));

        setGroupedCows(newVals);
    }

    const onSubmit = () => {
        //validate
        if (fees.some((f,i) => fees.some((f2,i2) => i < i2 && f.fee_type === f2.fee_type && f.fee_no === f2.fee_no))) {
            props.showToast(A.MESSAGE.VISIT_FEE_ITEM_DUPLICATED);
            return;
        }
        if (VISIT_FEE_TYPES.some(t => fees.some(f => f.fee_type === VISIT_FEE_TYPE[t].no) && !groupedCows[t].some(c => c.isChecked))) {
            props.showToast(A.MESSAGE.VISIT_FEE_NO_COW_SELECTED);
            return;
        }
        if (disetance == null
            && VISIT_FEE_TYPES.some(t => VISIT_FEE_TYPE[t].hasStep && fees.some(f => f.fee_type === VISIT_FEE_TYPE[t].no))) {
            
            props.showToast(A.MESSAGE.DISTANCE_REQUIRED);
            return;
        }

        const rtnFees = fees.map(fee => {
            const type = VISIT_FEE_TYPES.find(t => VISIT_FEE_TYPE[t].no === fee.fee_type);
            if (!CommonUtil.assertNotNull(type, "feeType of " + fee.fee_type)) return undefined;

            return {
                ...fee,
                key: undefined,
                is_benefit: groupedIsBenefit[type] ? 1 : 0,
                cow_ids: groupedCows[type].filter(c => c.isChecked).map(c => c.cow_id)
            };
        });
        props.onSubmit({
            clinic_id: props.data.clinic_id,
            distance_km: disetance,
            //種別でだけソートしておく
            fees: ar.orderBy(ar.notNull(rtnFees), f => f.fee_type)
        });
    }

    const buildSelectedCowInfo = (cows: ICheckablePopupCow[]) => {
        const checked = cows.filter(c => c.isChecked);
        if (checked.length === 1) {
            return CowToDispInfo(checked[0], true);
        }
        return checked.length + "頭選択中";
    }

    return (
        <div>
            <Modal isOpen={true} scrollable={true}>
                <ModalHeader toggle={props.onClose}>診療固定費の選択</ModalHeader>
                <ModalBody style={{ minHeight: "calc(100vh - 280px)"}}>
                    <ModalInputBlock label="往診距離" contentClassName="row-no-margin align-items-center">
                        <OptionalNumInput style={{ width: "80px" }}
                            min={LMT.VISIT_FEE.DISTANCE_MIN} max={LMT.VISIT_FEE.DISTANCE_MAX} step={LMT.VISIT_FEE.DISTANCE_STEP}
                            value={disetance}
                            onChange={setDistance}
                        />
                        <span className="p-l-2">km</span>
                        <TextLinkSelect
                            text="往診元から選択"
                            linkClassName={styles["link-right"]}
                            options={props.fromList.map((f,i) => ({ label:`${f.name} (${f.distance_km}km)`, value:i }))}
                            onSelect={idx => setDistance(props.fromList[idx].distance_km)}
                        />
                    </ModalInputBlock>

                    { VISIT_FEE_TYPES.map(t => ({ key:t, ...VISIT_FEE_TYPE[t] })).map(feeType => (
                        <ModalInputBlock key={feeType.key} label={feeType.name}>
                            { groupedCows[feeType.key].length > 1 && (
                                <div className={styles["cow-row"]}>
                                    <div className={styles["cow-label"]}>{buildSelectedCowInfo(groupedCows[feeType.key])}</div>
                                    <CowsPopup height="24px" prefix={feeType.key + "_"}
                                        cows={groupedCows[feeType.key]}
                                        placement="top"
                                        isCheckable={true}
                                        isCheckedChanged={(cId, idx, checked) => onCowChange(feeType.key, cId, idx, checked)}
                                    />
                                </div>
                            )}

                            { fees.filter(f => f.fee_type === feeType.no).map(fee => (
                                <div className={styles["select-row"]} key={fee.key}>
                                    <select className="form-control"
                                        value={fee.fee_no}
                                        onChange={e => onFeeSelect(feeType.no, fee.key, e.target.value)}
                                    >
                                        { groupedFeeItemList[feeType.key].map(item => (
                                            <option key={item.fee_no} value={item.fee_no}>{item.name}</option>
                                        ))}
                                    </select>
                                    <IconRight iconType="remove" onClick={() => onRemoveFee(feeType.no, fee.key)} />
                                </div>
                            ))}
                            { fees.filter(f => f.fee_type === feeType.no).length < feeType.selectableCount && (
                                <div className={styles["select-row"]}>
                                    <select className="form-control"
                                        value=""
                                        onChange={e => onFeeSelect(feeType.no, undefined, e.target.value)}
                                    >
                                        <option value="">選択</option>
                                        { groupedFeeItemList[feeType.key].map(item => (
                                            <option key={item.fee_no} value={item.fee_no}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            { feeType.hasBenefit && (
                                <Checkbox label="給付金対象" className="m-t-5"
                                    checked={groupedIsBenefit[feeType.key]}
                                    id={"chkIsBenefit_" + feeType.no}
                                    onChange={e => onIsBenefitChange(feeType.key, e.target.checked)}
                                />
                            )}

                        </ModalInputBlock>
                    ))}



                </ModalBody>
                <ModalFooter className="modal-footer-fix">
                    <ExecutionButton type="decide" onClick={onSubmit} />
                </ModalFooter>
            </Modal>
        </div>
    )

}