import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { A, convertBenefitType, TeamType, TREAT_BENEFIT_TYPE } from '../../config/constant';
import styles from './setting.module.css';
import { PageSettings } from '../../config/page-settings';
import { withContext, IMedicine, ITreatKind, ITreatItem, IMedicineRoute, IMedicineCategory } from '../../stores';
import { AppState } from '../../app';
import { TeamTreatPresetDto, TeamTreatPresetItemDto, TeamApi, TeamTreatPresetDeleteReq, ComTeamSortReq } from '../../api';
import { CommonUtil, FreezedArray } from '../../config/util';
import { PresetEditPopup } from './preset-edit-popup';
import { CommunicateOptions } from '../../api/communicator';
import { SortableList } from '../../components/parts/sortable-list';
import { EditingPresetItem } from './preset-item-edit-popup';
import { TeamTreatPresetModifyReq_fix } from '../../api/api-fix';
import { DIALOG_BUTTONS } from '../../components/form/form-dialog';
import { WithQuery, usePresets, resetPresets } from '../../stores/fetcher';
import { FetchWaiter, FetchError } from '../../components/content/fetch-state';

interface MyProps extends BaseProps<{id:string}> {
}
export type EditingPreset = Omit<TeamTreatPresetDto, "preset_id"|"items"> & {
    preset_id: number | undefined;
    items: EditingPresetItem[];
}

type NamedPresetItem = TeamTreatPresetItemDto & {
    name: string;
}
type NamedPreset = Omit<TeamTreatPresetDto, "items"> & {
    items: NamedPresetItem[];
}

interface MyState {
    teamId?: number;
    teamType: TeamType;
    sortingList?: NamedPreset[];
    editingData?: EditingPreset;
    executing: boolean;
    treatKinds: FreezedArray<ITreatKind>;
    medicineCategories: FreezedArray<IMedicineCategory>;
}

export const buildPresetItemName = (item: EditingPresetItem|TeamTreatPresetItemDto, medicines: FreezedArray<IMedicine>, treatItems: FreezedArray<ITreatItem>) => {
    if (item.medicine_id != null) {
        return medicines.find(m => m.medicine_id === item.medicine_id)?.name ?? "";
    }
    if (item.treat_kind_no == null || item.treat_item_no == null) return "";

    return treatItems.find(t => t.treat_kind_no === item.treat_kind_no && t.treat_item_no === item.treat_item_no)?.name ?? "";
}


class PresetMaster extends Base<MyProps, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            teamType:"ranch",
            executing: false,
            treatKinds:[],
            medicineCategories:[]
        };
    }

    componentDidMount() {

        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        this.init();
    }

    componentDidUpdate(prevProps: this['props']) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.init();
        }
    }

    private async init() {
        const teamId = Number(this.props.match.params.id);
        if (this.handleNotAllowAccess(teamId, ["MASTER_EDIT"], ["MASTER_EDIT"])) {
            return;
        }
        const teamType = this.props.rootStore.getClinicIdForMaster() === teamId ? "clinic" : "ranch";

        this.context.handleSetHeader({ title:"処置プリセットの管理", iconType:teamType });

        await this.setStateAsync({
            teamId,
            teamType,
            treatKinds: this.props.rootStore.options.treat_kind,
            medicineCategories: this.props.rootStore.options.medicine_category
        });
    }

    private startSorting(presetList: FreezedArray<NamedPreset>) {
        if (this.state.sortingList != null) return;

        this.setState({
            sortingList: [...presetList]
        });
    }

    private cancelSorting() {
        this.setState({
            sortingList:undefined
        });
    }

    private async finishSorting(oldList: FreezedArray<NamedPreset>) {
        if (!CommonUtil.assertNotNull(this.state.sortingList, "sortingList")) return;
        if (!CommonUtil.assertNotNull(this.state.teamId, "teamId")) return;

        const newOrder = this.state.sortingList.map(p => p.preset_id);
        const oldOrder = oldList.map(p => p.preset_id);
        if (JSON.stringify(newOrder) === JSON.stringify(oldOrder)) {
            this.setState({
                sortingList:undefined
            });
            return;
        }

        //ソート要求
        this.context.handleSetIsLoading(true);
        const req: ComTeamSortReq = {
            order: newOrder,
            team_id: this.state.teamId
        }
        const res = await this.comm().send((await TeamApi()).sortPresetUsingPOST(req));
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") return;

        this.setState({
            sortingList:undefined
        });

        resetPresets(this.state.teamId);
    }

    private addPreset() {
        if (!CommonUtil.assertNotNull(this.state.teamId, "teamId", "addPreset")) return;

        this.setState({
            editingData: {
                preset_id: undefined,
                preset_name: "",
                items:[],
            }
        })
    }

    private startEdit(preset: Readonly<TeamTreatPresetDto>) {
        this.setState({
            editingData: {
                ...preset,
                items: preset.items.map(pi => ({
                    ...pi,
                    benefit_type: convertBenefitType(pi.benefit_type)
                }))
            }
        });
    }

    private async onSubmitEdit(data: EditingPreset) {
        if (!CommonUtil.assertNotNull(this.state.teamId, "teamId")) return;

        if (data.preset_name === "") {
            this.context.showToast(A.MESSAGE.NO_PRESET_NAME_INPUT);
            return;
        }
        if (data.items.length === 0) {
            this.context.showToast(A.MESSAGE.NO_PRESET_ITEM_INPUT);
            return;
        }
        
        const itemKey = (item: EditingPresetItem) => item.medicine_id != null ? `m${item.medicine_id}` : `t${item.treat_kind_no}_${item.treat_item_no}`;
        if (new Set(data.items.map(i => itemKey(i))).size !== data.items.length) {
            this.context.showToast(A.MESSAGE.PRESET_ITEM_DUPLICATED);
            return;
        }

        try {
            this.setState({ executing: true });

            //更新要求
            this.context.handleSetIsLoading(true);
            const req: TeamTreatPresetModifyReq_fix = {
                team_id: this.state.teamId,
                preset_id: data.preset_id,
                preset_name: data.preset_name,
                items: data.items.map(item => ({ ...item, benefit_type: TREAT_BENEFIT_TYPE[item.benefit_type].no }))
            };
            const res = await this.comm().send((await TeamApi()).modifyPresetUsingPOST(req));
            this.context.handleSetIsLoading(false);
            if (res.result !== "OK") return;

            this.setState({
                editingData: undefined
            });
        } finally {
            this.setState({ executing: false });
        }

        resetPresets(this.state.teamId);
    }

    private async onDelete() {
        const confirmed = await this.context.showDialog("QUESTION", A.MESSAGE.CONFIRM_TO_DELETE_PRESET, DIALOG_BUTTONS.DELETE_CANCEL) === 0;
        if (!confirmed) return;

        const id = this.state.editingData?.preset_id;
        if (!CommonUtil.assertNotNull(id, "preset_id", "delete")) return;
        
        if (!CommonUtil.assertNotNull(this.state.teamId, "teamId")) return;

        this.setState({ executing: true });
        this.context.handleSetIsLoading(true);
        try {
            const req: TeamTreatPresetDeleteReq = {
                team_id: this.state.teamId,
                id
            };
            const res = await this.executeDelete(req);
            this.context.handleSetIsLoading(false);
            if (!res) return;

            this.setState({
                editingData: undefined
            });
    
            resetPresets(this.state.teamId);
            
        } finally {
            this.setState({ executing: false });
        }
    }
    private async executeDelete(req: TeamTreatPresetDeleteReq): Promise<boolean> {
        const opt: CommunicateOptions = { excludedErrCodes: [ A.ERR_CODE.ERR_USING_DATA ] };
        const res = await this.comm().send((await TeamApi()).detelePresetUsingPOST(req), opt);
        if (res.result !== "OK") return false;

        if (res.code === A.ERR_CODE.ERR_USING_DATA) {
            if (req.is_force === 1) {
                console.error("invalid response to force delete preset");
                return false;
            }

            //※ローディングを表示した状態のまま確認
            const confirmed = await this.context.showDialog("QUESTION", A.MESSAGE.CONFIRM_TO_DELETE_PRESET_AGAIN, DIALOG_BUTTONS.DELETE_CANCEL) === 0;
            if (!confirmed) return false;
            return this.executeDelete({ ...req, is_force: 1 });
        }

        return true;
    }

    render() {
        const teamId = this.state.teamId;
        if (teamId == null) return <></>;

        return (
            <WithQuery query={() => usePresets(teamId)}>
                {({ data, isError, isLoading }) => {
                    if (isLoading) return <FetchWaiter />
                    if (isError || data == null) return <FetchError />

                    const presets: NamedPreset[] = data.presets.map(t => ({
                        ...t,
                        items: t.items.map(item => ({
                            ...item,
                            name: buildPresetItemName(item, data.medicines, data.treatItems)
                        }))
                    }));

                    return this.renderPage(data.medicines, data.medicineRoutes, data.treatItems, presets);
                }}
            </WithQuery>
        )
    }

    renderPage(
        medicines: FreezedArray<IMedicine>,
        medicineRoutes: FreezedArray<IMedicineRoute>,
        treatItems: FreezedArray<ITreatItem>,
        presetList: FreezedArray<NamedPreset>
    ) {
        const isSorting = this.state.sortingList != null;

        return (
            <div className="page-root">
                <div className="product" style={{ height: "100%", "minHeight": "0" }}>
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix p-b-0">
                            <div className={"product-info-header " + styles.header}>
                                <div className={styles["sortable-list-shoulder"]}>
                                    { !isSorting && (<span className="link" onClick={() => this.startSorting(presetList)}>並び替え</span>)}
                                    { isSorting && (<span className="link" onClick={() => this.finishSorting(presetList)}>完了</span>)}
                                    { isSorting && (<span className="link" onClick={() => this.cancelSorting()}>キャンセル</span>)}
                                </div>
                            </div>

                            <div className="ranch-main" style={{flex: "auto"}}>
                                <SortableList
                                    className={styles.list}
                                    items={isSorting ? (this.state.sortingList ?? []) : presetList}
                                    isSorting={isSorting}
                                    onSorted={l => this.setState({ sortingList: l })}
                                    listItem={preset => (
                                        <li key={preset.preset_id} className={styles["list-item"]}>
                                            <div className={styles["list-item-content"]}>
                                                <div className={styles["list-item-name"]}>{preset.preset_name}</div>
                                                <div className={styles["list-item-detail"]}>
                                                    {preset.items.map(i => i.name).join(", ")}
                                                </div>
                                            </div>
                                            { isSorting ? (
                                                <div className={styles["list-item-icon"]}><i className="fas fa-bars"></i></div>
                                            ) : (
                                                <div onClick={() => this.startEdit(preset) } className={styles["list-item-icon"]}><i className="fas fa-pen clickable"></i></div>
                                            )}
                                        </li>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{height:"40px", marginTop:"6px"}} onClick={() => this.addPreset()}>
                    { !isSorting && (
                        <span className="link">
                            <i className="fa fa-plus"></i>
                            <span> プリセットを追加</span>
                        </span>
                    )}
                </div>
                {
                    this.state.teamId && this.state.editingData && (
                        <PresetEditPopup
                            teamId={this.state.teamId}
                            teamType={this.state.teamType}
                            data={this.state.editingData}
                            onClose={() => this.setState({ editingData: undefined })}
                            onDelete={() => this.onDelete() }
                            onSubmit={d => this.onSubmitEdit(d) }
                            medicineRoutes={medicineRoutes}
                            medicines={medicines}
                            treatKinds={this.state.treatKinds}
                            treatItems={treatItems}
                            medicineCategories={this.state.medicineCategories}
                            isSubmitExecuting={this.state.executing}
                        />
                    )
                }
            </div>
        )
    }
}

export default withRouter(withContext(PresetMaster));