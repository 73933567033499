import React, { useState, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { A } from '../../config/constant';
import styles from './setting.module.css';
import { PageSettings } from '../../config/page-settings';
import { withContext, IUser } from '../../stores';
import { AppState } from '../../app';
import { TeamApi, VisitModifyReq, VisitListDto, VisitDeleteReq, ClinicApi, TeamNameDto } from '../../api';
import { CommonUtil, FreezedArray } from '../../config/util';
import { VisitingRanchEditPopup, UserRanch } from './visiting-ranch-edit-popup';
import { IconLink } from '../../components/parts/icon-link';
import { UserTeams } from '../../config/user-teams';
import { resetVisitingRanches, useVisitingRanches } from '../../stores/fetcher';
import { FetchWaiter, FetchError } from '../../components/content/fetch-state';

export type EditingVisitingRanch = Omit<VisitModifyReq, "clinic_id"|"ranch_id"> & {
    ranch_id: number | undefined;
    name: string | undefined;
};

type VisitRanch = Omit<VisitListDto, "clinic_id">;

interface MyState {
    clinicId?: number;
    executing: boolean;
}

class VisitingRanchMaster extends Base<BaseProps<{id:string}>, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            executing: false,
        };
    }

    componentDidMount() {

        this.context.handleSetHeader({ title:"往診先リスト管理", iconType:"clinic" });
        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        this.init();
    }

    componentDidUpdate(prevProps: this['props']) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.init();
        }
    }

    private async init() {
        const teamId = Number(this.props.match.params.id);
        if (this.handleNotAllowAccess(teamId, ["MASTER_EDIT"], ["MASTER_EDIT"])) {
            return;
        }

        await this.setStateAsync({
            clinicId: teamId,
        });
    }



    private onSubmitEdit = async (data: EditingVisitingRanch): Promise<boolean> => {
        if (!CommonUtil.assertNotNull(this.state.clinicId, "clinicId")) return false;
        if (!CommonUtil.assertNotNull(data.ranch_id, "ranch_id")) return false;

        //往診元の重複確認
        if (data.distances.length !== new Set(data.distances.map(d => d.name)).size) {
            this.context.showToast(A.MESSAGE.VISITING_FROM_DUPLICATED);
            return false;
        }

        try {
            this.setState({ executing: true });
            this.context.handleSetIsLoading(true);

            const req: VisitModifyReq = {
                clinic_id: this.state.clinicId,
                ranch_id: data.ranch_id,
                team_symbol: data.team_symbol,
                code: data.code,
                distances: data.distances
            };

            const res = await this.comm().send((await ClinicApi()).modifyVisitUsingPOST(req),
                                                { excludedErrCodes: [A.ERR_CODE.INVALID_PARAM ] });
            if (res.result !== "OK") return false;

            //加入牧場・未加入牧場ともに削除済の場合はパラメータエラーになる
            if (res.code === A.ERR_CODE.INVALID_PARAM) {
                this.context.showToast(A.MESSAGE.VISITING_RANCH_CANT_REGISTER);
                return false;
            }

            await resetVisitingRanches(false);
            return true;

        } finally {
            this.context.handleSetIsLoading(false);
            this.setState({ executing: false });
        }
    }

    private onDelete = async (ranchId: number): Promise<boolean> => {
        const confirmed = await this.context.showDialog("QUESTION", "牧場をリストから削除してよろしいですか？", [ { type:"delete" }, { type:"cancel" }]);
        if (confirmed !== 0) return false;

        if (!CommonUtil.assertNotNull(this.state.clinicId, "clinicId")) return false;

        this.setState({ executing: true });
        this.context.handleSetIsLoading(true);
        try {
            const req: VisitDeleteReq = {
                ranch_id:ranchId,
                clinic_id:this.state.clinicId
            };
            const res = await this.comm().send((await ClinicApi()).deleteVisitUsingPOST(req));
            if (res.result !== "OK") return false;

            await resetVisitingRanches(false);
            return true;
            
        } finally {
            this.context.handleSetIsLoading(false);
            this.setState({ executing: false });
        }
    }

    private onRanchSearched = async (symbol: string, otherRanches: FreezedArray<VisitRanch>): Promise<TeamNameDto | undefined> => {
        this.setState({ executing: true });
        this.context.handleSetIsLoading(true);

        try {
            const res = await this.comm().send((await TeamApi()).getNameUsingPOST({ team_symbol: symbol }));
            this.context.handleSetIsLoading(false);

            if (res.result !== "OK") return undefined;

            const received = res.data;

            if (received == null || received.team_type === "clinic") {
                this.context.showToast(A.MESSAGE.NO_MATCH_RANCH);
                return undefined;
            }
            if (otherRanches.some(r => r.ranch_id === received.team_id)) {
                this.context.showToast(A.MESSAGE.VISITING_RANCH_ALREADY_REGISTERED);
                return undefined;
            }

            return res.data;

        } finally {
            this.setState({ executing: false });
        }
    }

    render() {
        if (this.state.clinicId == null) return <></>

        return (
            <VisitingRanchMasterContent
                clinicId={this.state.clinicId}
                //※observableなので、画面を開いた状態で未加入牧場を追加した場合にpropsの変更が走る
                user={this.props.rootStore.user}
                executing={this.state.executing}
                onSubmitEdit={this.onSubmitEdit}
                onDelete={this.onDelete}
                onRanchSearched={this.onRanchSearched}
            />
        )
    }
}

interface ContentProps {
    clinicId: number;
    user: IUser;
    executing: boolean;
    onSubmitEdit: (data: EditingVisitingRanch) => Promise<boolean>;
    onDelete: (ranchId: number) => Promise<boolean>;
    onRanchSearched: (symbol: string, otherRanches: FreezedArray<VisitRanch>) => Promise<TeamNameDto | undefined>;
}

const VisitingRanchMasterContent = React.memo((props: ContentProps) => {
    const ranchList = useVisitingRanches(props.clinicId);

    const [ selectableRanches, setSelectableRanches ] = useState<FreezedArray<UserRanch>>([]);
    const [ editingData, setEditingData ] = useState<EditingVisitingRanch>();

    useEffect(() => {
        const userRanches = new UserTeams(props.user).allTeams().filter(t => t.isRanch).map(r => ({
            name: r.name,
            ranch_id: r.team_id,
            team_symbol: ("team_symbol" in r) ? r.team_symbol : undefined,
        }));

        setSelectableRanches(preList => {
            return [
                ...preList,
                ...userRanches.filter(r => !preList.some(pr => pr.ranch_id === r.ranch_id))
            ]
        });

    }, [ props.user ]);

    useEffect(() => {
        if (ranchList.data == null) return;

        //所属する牧場リストに含まれていないものがあれば追加しておく
        setSelectableRanches(preList => {
            const newList: UserRanch[] = [
                ...preList,
                ...ranchList.data.filter(r => !preList.some(ur => ur.ranch_id === r.ranch_id))
                    .map(r => ({ name:r.name, ranch_id:r.ranch_id, team_symbol:undefined }))
            ];
            return newList;
        });

    }, [ ranchList.data ]);

    const onAdd = useCallback(() => {
        setEditingData({
            code:"",
            distances:[],
            ranch_id:undefined,
            name:undefined
        });

    }, []);

    const startEdit = useCallback((ranch: Readonly<VisitRanch>) => {
        setEditingData({ ...ranch });
    }, []);

    const onDelete = useCallback(async () => {
        if (!CommonUtil.assertNotNull(editingData, "editingData")) return;
        if (!CommonUtil.assertNotNull(editingData.ranch_id, "editing ranch id")) return;

        const res = await props.onDelete(editingData.ranch_id);
        if (res === true) {
            setEditingData(undefined);
        }

    }, [ editingData, props.onDelete ]);

    const onSubmitEdit = useCallback(async (data: EditingVisitingRanch) => {
        const res = await props.onSubmitEdit(data);
        if (res === true) {
            setEditingData(undefined);
        }

    }, [ props.onSubmitEdit ]);

    const onRanchSearched = useCallback(async (symbol: string) => {
        if (!CommonUtil.assertNotNull(ranchList.data, "ranchList", "search")) return;
        
        const otherRanches = ranchList.data.filter(r => r.ranch_id !== editingData?.ranch_id);

        const res = await props.onRanchSearched(symbol, otherRanches);
        if (res == null) return undefined;

        //自分の所属牧場リストに含まれていないものであれば追加しておく
        if (!selectableRanches.some(r => r.ranch_id === res.team_id)) {
            setSelectableRanches([
                ...selectableRanches,
                { name: res.name, ranch_id: res.team_id, team_symbol: symbol }
            ]);
        }
        
        return res;

    }, [ props.onRanchSearched, ranchList.data, editingData, selectableRanches ]);



    if (ranchList.isLoading) return <FetchWaiter />
    if (ranchList.isError || ranchList.data == null) return <FetchError />

    return (
        <div className="page-root">
            <div className="product product-full-height">
                <div className="product-detail" style={{ height: "100%" }}>
                    <div className="product-info product-info-fix">
                        <div className="product-body">
                            <ul className={styles.list + " m-b-0"}>
                                { ranchList.data.map(r => (
                                    <li key={r.ranch_id} className={styles["list-item"]}>
                                        <div className={styles["list-item-content"]}>
                                            <div className={styles["list-item-name"]}>{r.name}</div>
                                            <div className={styles["list-item-detail"]}>
                                                <span>{r.code} </span>
                                                <span>{r.distances.map(f => `${f.name}から${f.distance_km}km`).join(", ")}</span>
                                            </div>
                                        </div>
                                        <div onClick={() => startEdit(r) } className={styles["list-item-icon"]}><i className="fas fa-pen clickable" /></div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{height:"40px", marginTop:"6px"}} onClick={onAdd}>
                <IconLink iconType="add" text="往診先を追加" />
            </div>
            {
                editingData != null && (
                    <VisitingRanchEditPopup
                        data={editingData}
                        userRanchList={selectableRanches}
                        registeredRanchIds={ranchList.data.map(r => r.ranch_id)}
                        isSubmitExecuting={props.executing}
                        onClose={() => setEditingData(undefined)}
                        onDelete={onDelete}
                        onSubmit={onSubmitEdit}
                        onSearch={onRanchSearched}
                    />
                )
            }
        </div>
    )
});

export default withRouter(withContext(VisitingRanchMaster));