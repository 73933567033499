import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { A } from '../../config/constant';
import { PageSettings } from '../../config/page-settings.js';
import queryString from 'query-string'
import { withContext, IRanchHouse } from '../../stores';
import { ICowNameInfo } from '../../components/parts/cows-popup';
import { IEventWriteLocationState, historyLocation } from '../../config/history-location-builder';
import { CowLocationModifyReq, CowLocationDeleteReq, CowLocationReq, CowApi, CowLocationHistReq, CowLocationDto } from '../../api';
import { ScheduleFinder } from '../schedule/schedule-finder';
import { AppState } from '../../app';
import { EVENT_KIND } from '../../config/event-kind';
import V3DatePicker from '../../components/parts/v3-date-picker';
import moment from 'moment';
import { FreezedArray } from '../../config/util';
import { LocationHistoryBoard, ILocationHis } from './location-history-board';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { FormInputBlock } from '../../components/form/form-input-block';
import { DIALOG_BUTTONS } from '../../components/form/form-dialog';
import { FetchWaiter, FetchError } from '../../components/content/fetch-state';
import { getRanchHouses } from '../../stores/fetcher';
import { getCowWithCache, resetCow } from 'stores/fetcher_cow';
import { SingleCowHeader } from 'components/cow-header/single-cow-header';
import { MultiCowHeader } from 'components/cow-header/multi-cow-header';

interface MyProps extends BaseProps<{},{},IEventWriteLocationState|undefined> {
}

interface ILocation {
    site:number;
    barn:number;
    room:number;
    start_day: Date;
}

const convertHisResponseToState = (response: CowLocationDto[], today: Date): ILocationHis[] => {
    const list = response.map(l => ({ ...l, start_day: moment(l.start_day).toDate() }));
    return list.map((l,i) => ({ ...l, end_day: i === list.length - 1 ? today : list[i+1].start_day }))
}

interface MyState {
    initStatus: "ready"|"error"|"loading";
    cow_top:boolean;
    cow_ids: Readonly<number[]>;
    cow_name?: ICowNameInfo;
    cow_location: ILocation;
    cow_locations: FreezedArray<ILocationHis>;
    location_sites: FreezedArray<IRanchHouse>;
    location_barns: FreezedArray<IRanchHouse>;
    location_rooms: FreezedArray<IRanchHouse>;
    executing: boolean;
    today: Date;
}

class CowLocationWrite extends Base<MyProps,MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        const today = moment().startOf("day").toDate();

        this.state = {
            initStatus: "loading",
            cow_top: false,

            cow_ids:[],
            cow_location: {
                site: 0,
                barn: 0,
                room: 0,
                start_day: today
            },
            cow_locations: [],
            today,

            location_sites: [],
            location_barns: [],
            location_rooms: [],

            executing: false
        }

        this.onSiteSelected = this.onSiteSelected.bind(this);
        this.onBarnSelected = this.onBarnSelected.bind(this);
        this.onRoomSelected = this.onRoomSelected.bind(this);

        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    componentDidMount() {
        if (this.handleNotAllowAccess(undefined,["COW_EDIT"],[])) {
            return;
        }

        // Get Cow IDs from URL
        const queryData = this.parseCowIdsFromQuery(this.props.history.location.search);

        this.context.handleSetHeader({ title:"場所の移動" });
        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        this.setState({
            cow_top: queryData.cow_top,
            cow_ids: queryData.cow_ids
        }, () => {
            this.init(queryData.cow_ids);
        });
    }

    async init (cowIds: number[]) {
        const sites = await getRanchHouses(this.props.rootStore.cur_ranch_id);
        await this.setStateAsync({
            location_sites: sites ?? [],
            initStatus: sites == null ? "error" : "ready"
        });
        if (sites == null) return;

        if (cowIds.length === 1) {
            this.api_getGetCowInfo(cowIds[0]);
        } else if (cowIds.length > 1) {
            this.api_getLocation(cowIds);
        }
    }

    parseCowIdsFromQuery(query: string): { cow_top: boolean, cow_ids: number[] } {
        const values = queryString.parse(query);

        const cow_top = (values.cow_top != null) && (Number(values.cow_top) === 1);
        if (values.param == null) {
            return { cow_top: cow_top, cow_ids:[] };
        }

        const ids = Array.isArray(values.param) ? values.param : values.param.split(",");

        return { cow_top, cow_ids: ids.map(id => parseInt(id)).filter(id => !isNaN(id)) };
    }

    onSiteSelected(site_no: number) {
        if (site_no === 0) {
            this.setState({
                cow_location: {
                    start_day: this.state.cow_location.start_day,
                    site: 0,
                    barn: 0,
                    room: 0
                },
                location_barns: [],
                location_rooms: []
            });
            return;
        }

        const site = this.state.location_sites.find(s => s.no === site_no);
        if (site == null) return;
        if (this.state.cow_location.site === site_no) return;


        const barns = site.data ?? [];

        this.setState({
            cow_location: {
                start_day: this.state.cow_location.start_day,
                site: site_no,
                barn: 0,
                room: 0
            },
            location_barns: barns,
            location_rooms: []
        }, () => {
            if (barns.length === 1) {
                this.onBarnSelected(barns[0].no);
            }
        });
    }

    onBarnSelected(barn_no: number) {
        if (barn_no === 0) {
            this.setState({
                cow_location: { ...this.state.cow_location, barn: 0, room: 0 },
                location_rooms: []
            });
            return;
        }
        const barn = this.state.location_barns.find(b => b.no === barn_no);
        if (barn == null) return;
        if (this.state.cow_location.barn === barn_no) return;

        const rooms = barn.data ?? [];

        this.setState({
            cow_location: { ...this.state.cow_location, barn: barn_no, room: 0 },
            location_rooms: rooms
        }, () => {
            if (rooms.length === 1) {
                this.onRoomSelected(rooms[0].no);
            }
        });
    }

    onRoomSelected(room_no: number) {
        if (room_no === 0) {
            this.setState({
                cow_location: { ...this.state.cow_location, room:0 }
            });
            return;
        }

        let rooms = this.state.location_rooms;
        for (let i = 0; i < rooms.length; i++) {
            if (rooms[i].no === room_no) {
                if (this.state.cow_location.room !== room_no) {
                    this.setState({
                        cow_location: { ...this.state.cow_location, room: room_no }
                    });
                }
                break;
            }
        }
    }

    async onSave() {
        this.setState({ executing: true });
        const registered = await this.api_postCowLocationWrite();
        if (!registered) {
            this.setState({ executing: false });
            return;
        }

        if (this.state.cow_top) {
            this.props.history.replace(historyLocation.toCowInfo(this.state.cow_ids[0]));
        } else {
            window.history.go(-1);
        }
    }

    async onDelete() {
        const res = await this.context.showDialog("QUESTION", '記録を削除してよろしいですか？', DIALOG_BUTTONS.DELETE_CANCEL);
        if (res === 0) {
            this.api_deleteLocation();
        }
    }

    setAsDefault(site:number, barn:number, room:number) {
        let barns: Readonly<IRanchHouse[]> = [];
        let rooms: Readonly<IRanchHouse[]> = [];
        const siteItem = site === 0 ? null : this.state.location_sites.find(s => s.no === site);
        if (siteItem != null) {
            barns = siteItem.data ?? [];
        }
        const barnItem = barn === 0 ? null : barns.find(b => b.no === barn);
        if (barnItem != null) {
            rooms = barnItem.data ?? [];
        }

        this.setState({
            cow_location: {
                start_day: this.state.cow_location.start_day,
                site: site,
                barn: barn,
                room: room,
            },
            location_barns: barns,
            location_rooms: rooms
        });
    }

    api_getLocation = async (cow_ids: number[]) => {
        const params: CowLocationReq = {
            ranch_id: this.props.rootStore.cur_ranch_id,
            cow_id: cow_ids
        };

        this.context.handleSetIsLoading(true);
        const res = await this.comm().send((await CowApi()).getLocationUsingPOST(params), { retries: true });
        this.context.handleSetIsLoading(false);
        if (res.data != null) {
            const location = res.data;
            this.setAsDefault(location.site, location.barn, location.room);
        }
    }

    api_getGetCowInfo = async (cow_id: number) => {

        this.context.handleSetIsLoading(true);
        const response = await this.comm().send(() => getCowWithCache(this.props.rootStore.cur_ranch_id, cow_id), { retries: true });
        this.context.handleSetIsLoading(false);
        if (response.data != null) {
            const cow = response.data;
            this.setState({ cow_name: cow });
            await this.api_getGetCowLocationHistory(cow_id);
        }
    }

    api_getGetCowLocationHistory = async (cow_id: number) => {

        const params: CowLocationHistReq = {
            ranch_id: this.props.rootStore.cur_ranch_id,
            cow_id: cow_id,
        };

        this.context.handleSetIsLoading(true);
        const response = await this.comm().send((await CowApi()).getLocationInfoListUsingPOST(params), { retries: true });
        this.context.handleSetIsLoading(false);
        if (response.data == null) return;

        const list = response.data.list;
        this.setState({
            cow_locations: convertHisResponseToState(list, this.state.today)
        });

        let site = 0;
        let barn = 0;
        let room = 0;
        if (list.length > 0) {
            const current = list[list.length - 1];
            site = current.site;
            barn = current.barn;
            room = current.room;
        }
        this.setAsDefault(site, barn, room);
    }

    api_postCowLocationWrite = async (): Promise<boolean> => {
        if (this.state.cow_ids.length === 0) {
            this.context.showToast(A.MESSAGE.INVALID_COW_IDS);
            return Promise.resolve(false);
        }

        const cow_location = this.state.cow_location;
        const params: CowLocationModifyReq = {
            ranch_id: this.props.rootStore.cur_ranch_id,
            site: cow_location.site,
            barn: cow_location.barn,
            room: cow_location.room,
            start_day: moment(cow_location.start_day).format("YYYY-MM-DD"),
            cow_ids: [...this.state.cow_ids],
            schedule_id: this.props.location.state?.schedule_id
        }

        //関連する予定を探す
        if (params.schedule_id == null && params.cow_ids.length === 1) {

            const finder = new ScheduleFinder(this.context, params.ranch_id, this.props.rootStore.user.id);
            const scheRes = await finder.findEventRelatedSchedule(params.start_day, [ EVENT_KIND.LOCATION.no ], params.cow_ids[0]);

            if (scheRes.result === "cancel") return false;

            if (scheRes.result === "yes") {
                params.schedule_id = scheRes.id;
            }
        }

        console.log("cow_location_modify", params);

        try {
            this.context.handleSetIsLoading(true);
            const response = await this.comm().send(() => this.context.postAsync('/cow/location/modify', params));
            if (response.result !== "OK") return false;

            //牛リストをリロードしておく
            if (await this.props.rootStore.fetchActiveCows(undefined, "DIFF") === "NG") {
                this.context.showToast(A.MESSAGE.FAILED_TO_LOAD_NEW_INFO);
            }
            params.cow_ids.forEach(id => resetCow(id, false));

        } finally {
            this.context.handleSetIsLoading(false);
        }
        return true;
    }

    api_deleteLocation = async () => {
        const params: CowLocationDeleteReq = {
            ranch_id: this.props.rootStore.cur_ranch_id,
            cow_id: this.state.cow_ids[0]
        }
        console.log(params);

        try {
            this.context.handleSetIsLoading(true);
            const response = await this.comm().send((await CowApi()).deleteCowLocationUsingPOST(params));
            if (response.result !== "OK") return;
            
            //牛リストをリロードしておく
            await this.props.rootStore.fetchActiveCows(undefined, "DIFF");

            const list = response.data?.list ?? [];
            this.setState({
                cow_locations: convertHisResponseToState(list, this.state.today)
            });
    
        } finally {
            this.context.handleSetIsLoading(false);
        }

    }

    render() {
        if (this.state.initStatus === "loading") return <FetchWaiter />
        if (this.state.initStatus === "error") return <FetchError />

        const state = this.state;
        return (
            <div className="page-root">
                <div className="product product-full-height">
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix">
                            <div className="product-info-header">
                                {
                                    state.cow_name != null && (
                                        <SingleCowHeader ranchId={this.props.rootStore.cur_ranch_id} cowId={state.cow_name.cow_id} />
                                    )
                                }
                                {
                                    state.cow_ids.length > 1 && (
                                        <MultiCowHeader infoName="移動" cowCount={state.cow_ids.length} cows={this.props.location.state?.cows} />
                                    )
                                }
                            </div>
                            <div className="product-body">
                                {
                                    state.cow_locations.length > 0 && (
                                        <LocationHistoryBoard
                                            histories={state.cow_locations}
                                            onDelete={this.onDelete}
                                            sites={this.state.location_sites}
                                        />
                                    )
                                }
                                <FormInputBlock label="移動先">
                                    <select className="form-control m-b-5" value={state.cow_location.site}
                                        data-testid="select-site"
                                        onChange={(e) => { this.onSiteSelected(Number(e.target.value)) }}>
                                        <option value={0}>分場選択</option>
                                        {
                                            state.location_sites.map((value, i) => (
                                                <option key={i} value={value.no}>{value.name}</option>
                                            ))
                                        }
                                    </select>
                                    <select className="form-control m-b-5" value={state.cow_location.barn}
                                        data-testid="select-barn"
                                        onChange={(e) => { this.onBarnSelected(Number(e.target.value)) }}>
                                        <option value={0}>牛舎選択</option>
                                        {
                                            state.location_barns.map((value, i) => (
                                                <option key={i} value={value.no}>{value.name}</option>
                                            ))
                                        }
                                    </select>
                                    <select className="form-control" value={state.cow_location.room}
                                        data-testid="select-room"
                                        onChange={(e) => { this.onRoomSelected(Number(e.target.value)) }}>
                                        <option value={0}>部屋選択</option>
                                        {
                                            state.location_rooms.map((value, i) => (
                                                <option key={i} value={value.no}>{value.name}</option>
                                            ))
                                        }
                                    </select>
                                </FormInputBlock>
                                <FormInputBlock label="移動日">
                                    <V3DatePicker value={state.cow_location.start_day} name="cow_location.start_day"
                                        onChange={e => this.setState({
                                            cow_location: {
                                                ...this.state.cow_location,
                                                start_day: e.target.value
                                            }
                                        })}
                                    />
                                </FormInputBlock>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="button-page-footer">
                    <ExecutionButton type="save" onClick={this.onSave} disabled={this.state.executing}>移動</ExecutionButton>
                </div>
            </div>
        )
    }
}

export default withRouter(withContext(CowLocationWrite));