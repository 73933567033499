import { useQuery, UseQueryResult, useQueries } from 'react-query';
import { RanchApi, RanchFeedDao, TeamApi, TeamMedicineDao, TeamMedicineRouteDto, TeamTreatPresetDto, ClinicApi, VisitListDto, ClinicVisitFeeDto, TeamDiseaseCauseDto, RanchSeedListDto, ResponseMeta, RobotApi } from '../api';
import { ReactElement } from 'react';
import { queryClient } from './query-client';
import { ITreatItem } from '.';
import { IRanchHouse, ITeamCondition, IConditionClass } from './RootStore';
import { DISEASE_ITEM_STATUS, TeamType } from '../config/constant';

export const fetchRanchHouses = async (ranchId: number): Promise<IRanchHouse[]> => {
    const api = await RanchApi();
    const res = await api.getHouseListUsingPOST({ ranch_id: ranchId })();
    if (res.data.meta?.errCode !== 0 || res.data.data == null) {
        console.error(res.data);
        throw new Error(res.data.meta?.errMsg)
    }
  
    const houseList = (res.data.data.list ?? []) as IRanchHouse[];   //※型定義の差異吸収
    
    return houseList
        .filter(h => h.level === 1)
        .map(lv1 => {
            const level2 = houseList
                .filter(h => h.level === 2 && h.parent_no === lv1.no)
                .map(lv2 => {
                    const level3 = houseList
                        .filter(h => h.level === 3 && h.parent_no === lv2.no)
                    return { ...lv2, data: level3 }
                })
            return { ...lv1, data: level2 }
        });
}

export const fetchRanchFeeds = async (ranchId: number) => {

    const api = await RanchApi();
    const res = await api.getFeedListUsingPOST({
        ranch_id: ranchId,
    })();
    if (res.data.meta?.errCode !== 0 || res.data.data == null) {
        console.error(res.data);
        throw new Error(res.data.meta?.errMsg);
    }

    const loadedList = res.data.data.list ?? [];
    loadedList.sort((a,b) => a.disp_no - b.disp_no);

    return loadedList;
}

export const fetchMedicines = async (teamId: number) => {

    const api = await TeamApi();
    const res = await api.listMedicineUsingPOST({
        team_id: teamId,
    })();

    if (res.data.meta?.errCode !== 0 || res.data.data == null) {
        console.error(res.data);
        throw new Error(res.data.meta?.errMsg);
    }

    return res.data.data.list ?? [];
}

export const fetchMedicineRoutes = async (teamId:number) => {

    const api = await TeamApi();
    const res = await api.listMedicineRouteUsingPOST({ team_id: teamId })();
    if (res.data.meta?.errCode !== 0 || res.data.data == null) {
        console.error(res.data);
        throw new Error(res.data.meta?.errMsg);
    }
    return res.data.data.list ?? [];
}

export const fetchTreatItems = async (teamId: number) => {

    const api = await TeamApi();
    const res = await api.listTreatItemUsingPOST({ team_id: teamId })();
    if (res.data.meta?.errCode !== 0 || res.data.data == null) {
        console.error(res.data);
        throw new Error(res.data.meta?.errMsg);
    }
    return (res.data.data.list ?? []) as ITreatItem[];  //※型定義の差異吸収
}

export const fetchPresets = async (teamId: number) => {

    const api = await TeamApi();
    const res = await api.listPresetUsingPOST({ team_id:teamId })();
    if (res.data.meta?.errCode !== 0 || res.data.data == null) {
        console.error(res.data);
        throw new Error(res.data.meta?.errMsg);
    }
    return res.data.data;
}

const fetchVisitingRanches = async (clinicId: number) => {

    const api = await ClinicApi();
    const res = await api.listVisitUsingPOST({ clinic_id: clinicId })();
    if (res.data.meta?.errCode !== 0 || res.data.data == null) {
        console.error(res.data);
        throw new Error(res.data.meta?.errMsg);
    }
    return res.data.data;
}

const fetchVisitFees = async (clinicId: number) => {

    const api = await ClinicApi();
    const res = await api.listVisitFeeUsingPOST({ clinic_id: clinicId })();
    if (res.data.meta?.errCode !== 0 || res.data.data == null) {
        console.error(res.data);
        throw new Error(res.data.meta?.errMsg);
    }
    return res.data.data;
}

const fetchDiseaseRelations = async (teamId: number) => {

    const api = await TeamApi();
    const res = await api.listTeamDiseasesUsingPOST({ team_ids: [ teamId ] })();
    if (res.data.meta?.errCode !== 0 || res.data.data == null) {
        console.error(res.data);
        throw new Error(res.data.meta?.errMsg);
    }
    return res.data.data[0]?.list ?? [];
}

const fetchConditions = async (teamId: number) => {

    const api = await TeamApi();
    const res = await api.listConditionUsingPOST({ team_id: teamId })();
    if (res.data.meta?.errCode !== 0 || res.data.data == null) {
        console.error(res.data);
        throw new Error(res.data.meta?.errMsg);
    }

    return res.data.data as ITeamCondition[];  //※型定義の差異吸収
}

const fetchSeeds = async (teamType: TeamType, teamId: number) => {

    const res = teamType === "ranch"
              ? await (await RanchApi()).getSeedListUsingPOST({ ranch_id: teamId })()
              : await (await ClinicApi()).getClinicSeedListUsingPOST({ clinic_id: teamId })();

    if (res.data.meta?.errCode !== 0 || res.data.data == null) {
        console.error(res.data);
        throw new Error(res.data.meta?.errMsg);
    }

    return res.data.data;
}

const fetchAncestors = async (teamId: number) => {
    const api = await TeamApi();
    const res = await api.listAncestorsUsingPOST({ team_id: teamId })();

    if (res.data.meta?.errCode !== 0 || res.data.data == null) {
        console.error(res.data);
        throw new Error(res.data.meta?.errMsg);
    }

    return res.data.data;
}

const fetchProcess = async (ranchId: number) => {
    const api = await TeamApi();
    const res = await api.listProcessUsingPOST({ team_id:ranchId })();
    if (res.data.meta?.errCode !== 0 || res.data.data == null) {
        console.error(res.data);
        throw new Error(res.data.meta?.errMsg);
    }

    return res.data.data;
}

export const QUERY = {
    RANCH_HOUSE: "ranch_house",
    RANCH_FEED: "ranch_feed",
    TEAM_TREAT_ITEM: "team_treat_item",
    TEAM_MEDICINE: "team_medicine",
    TEAM_MEDICINE_ROUTE: "team_medicine_route",
    TEAM_PRESET: "team_preset",
    VISITING_RANCH: "visiting_ranch",
    VISIT_FEE: "visit_fee",
    DISEASE_RELATION: "disease_relation",
    CONDITION: "condition",
    SEED: "seed",
    ANCESTOR: "ancestor",
    COW: "cow",
    COW_FILTER: "cow_filter",
    LATEST_RUT: "latest_rut",
    SELL_MILK_GRAPH: "sell_milk_graph",
    ACTIVITY_COW: "activity_cow",
    PROCESS: "process",
    SENSOR_COW: "sensor_cow",
    SENSOR_HIS: "sensor_his"

} as const;

export type UseQueryResultSummary<TData> = Pick<UseQueryResult<TData>, "data"|"isError"|"isFetched"|"isLoading"|"isFetching"|"isLoadingError"|"isSuccess">;

// resetQueries      : キャッシュを破棄して再取得する
// removeQueries     : キャッシュを破棄する
// invalidateQueries : 再取得し、取得でき次第キャッシュと入れ替える
// ※マスタ更新後、すぐに同画面で更新された値を表示する場合は、再取得完了まで古い値が表示されないようにresetが良い
//   （モーダル内で更新＆表示が完結する場合、親画面の再描画が走ってモーダルが消えてしまわないよう注意）
// ※マスタ更新後、すぐに同画面で表示するわけでない（別の画面にいく）場合は、再取得によって再描画が走らないようにinvalidateが良い
//   または、画面を表示したままコード内で再取得を待機したいような場合は invalidateをawaitする

export const resetOrInvalidate = (key: string|readonly unknown[], immediate: boolean) => immediate ? queryClient.resetQueries(key) : queryClient.invalidateQueries(key);

export const resetRanchHouses = () => queryClient.resetQueries(QUERY.RANCH_HOUSE);
export const resetRanchFeeds = () => queryClient.resetQueries(QUERY.RANCH_FEED);
export const resetMedicines = (teamId: number, immediate = true) => resetOrInvalidate([ QUERY.TEAM_MEDICINE, teamId ], immediate);
export const resetMedicineRoutes = (teamId: number, immediate = true) => resetOrInvalidate([ QUERY.TEAM_MEDICINE_ROUTE, teamId ], immediate);
export const resetTreatItems = (teamId: number, immediate = true) => resetOrInvalidate([ QUERY.TEAM_TREAT_ITEM, teamId ], immediate);
export const resetPresets = (teamId: number) => queryClient.resetQueries([ QUERY.TEAM_PRESET, teamId ]);
export const resetVisitingRanches = (immediate = true) => resetOrInvalidate(QUERY.VISITING_RANCH, immediate);
export const resetVisitFees = (immediate = true) => resetOrInvalidate(QUERY.VISIT_FEE, immediate);
export const resetDiseaseRelations = (immediate = true) => resetOrInvalidate(QUERY.DISEASE_RELATION, immediate);
export const resetConditions = (immediate = true) => resetOrInvalidate(QUERY.CONDITION, immediate);
export const resetSeeds = (teamId: number, immediate = true) => resetOrInvalidate([ QUERY.SEED, teamId ], immediate);
export const resetAncestors = (teamId: number, immediate = true) => resetOrInvalidate([ QUERY.ANCESTOR, teamId ], immediate);
export const resetProcess = (ranchId: number, immediate =true) => resetOrInvalidate([ QUERY.PROCESS, ranchId ], immediate);

export const useRanchHouses = (ranchId: number | undefined, appendsUnset?: boolean): UseQueryResult<IRanchHouse[]> => {
    const res = useQuery([ QUERY.RANCH_HOUSE, ranchId ], () => fetchRanchHouses(ranchId ?? 0), { enabled: ranchId != null });
    if (res.data == null || appendsUnset !== true) return res;

    const data = appendsUnsetToRanchHouses(res.data);
    return { ...res, data };
}
export const getRanchHouses = async (ranchId: number, appendsUnset?: boolean) => {
    const res = await getManually([ QUERY.RANCH_HOUSE, ranchId ], () => fetchRanchHouses(ranchId));
    if (res.data == null) return undefined;
    
    if (appendsUnset !== true) return res.data;

    return appendsUnsetToRanchHouses(res.data);
}
const appendsUnsetToRanchHouses = (sites: IRanchHouse[]) => {
    return [ ...sites, { no:0, level: 1, name: "（分場未設定）", parent_no: 0, data: [], disp_no: 1 } ]
        .map(s => ({
            ...s,
            data: [ ...s.data, { no:0, level:2, name: "（牛舎未設定）", parent_no: s.no, data: [], disp_no: 1 } ]
                .map(b => ({
                    ...b,
                    data: [ ...b.data, { no:0, level:3, name: "（部屋未設定）", parent_no: b.no, data: [], disp_no: 1 } ]
                }))
            }));
}

export const useRanchFeeds = (ranchId: number): UseQueryResult<RanchFeedDao[]> => {
    return useQuery([ QUERY.RANCH_FEED, ranchId ], () => fetchRanchFeeds(ranchId));
}
export const getRanchFeeds = async (ranchId: number) => {
    const res = await getManually([ QUERY.RANCH_FEED, ranchId ], () => fetchRanchFeeds(ranchId));
    return res.data;
}

export const getTreatItems = async (teamId: number) => {
    const res = await getManually([ QUERY.TEAM_TREAT_ITEM, teamId ], () => fetchTreatItems(teamId));
    return res.data;
}

export const useMedicineAndRoutes = (teamId: number) => {
    const res = useQueries([
        { queryKey: [ QUERY.TEAM_MEDICINE, teamId ], queryFn:() => fetchMedicines(teamId) },
        { queryKey: [ QUERY.TEAM_MEDICINE_ROUTE, teamId ], queryFn: () => fetchMedicineRoutes(teamId) },
    ]);
    return {
        ...mergeQueryResults(res),
        data: res.some(r => r.data == null) ? undefined :
        {
            medicines: res[0].data as TeamMedicineDao[],
            medicineRoutes: res[1].data as TeamMedicineRouteDto[],
        }
    }
}

export const useMedicineRoutes = (teamId: number) => {
    return useQuery([QUERY.TEAM_MEDICINE_ROUTE, teamId], () => fetchMedicineRoutes(teamId));
}
export const useMyMedicineRoutes = (ranchId: number, clinicId: number | undefined) => {
    const teamId = clinicId ?? ranchId;
    return useMedicineRoutes(teamId);
}

export const useRanchMedicineAndTreats = (ranchId: number | undefined): UseQueryResultSummary<{ medicines?: TeamMedicineDao[], treatItems?: ITreatItem[] }> => {
    const res = useQueries([
        { queryKey: [ QUERY.TEAM_MEDICINE, ranchId ], queryFn:() => fetchMedicines(ranchId ?? 0), enabled: ranchId != null },
        { queryKey: [ QUERY.TEAM_TREAT_ITEM, ranchId ], queryFn:() => fetchTreatItems(ranchId ?? 0), enabled: ranchId != null },
    ]);

    if (ranchId == null) {
        return {
            data: { },
            isError: false,
            isFetched: true,
            isFetching: false,
            isLoading: false,
            isLoadingError: false,
            isSuccess: true
        };
    }

    return {
        ...mergeQueryResults(res),
        data: res.some(r => r.data == null) ? undefined :
        {
            medicines: res[0].data as TeamMedicineDao[],
            treatItems: res[1].data as ITreatItem[]
        }
    }

}

export const useMedicineAndTreats = (ranchId: number, clinicId?: number) => {
    const priorTeamId = clinicId ?? ranchId;

    const queries = [
        { queryKey: [ QUERY.TEAM_MEDICINE_ROUTE, priorTeamId ], queryFn: () => fetchMedicineRoutes(priorTeamId) },
        { queryKey: [ QUERY.TEAM_TREAT_ITEM, priorTeamId ], queryFn:() => fetchTreatItems(priorTeamId) },
        { queryKey: [ QUERY.TEAM_MEDICINE, ranchId ], queryFn:() => fetchMedicines(ranchId) },
    ];

    if (clinicId != null) {
        queries.push(
            { queryKey: [ QUERY.TEAM_MEDICINE, clinicId ], queryFn:() => fetchMedicines(clinicId) }
        )
    }

    const res = useQueries(queries);

    return {
        ...mergeQueryResults(res),
        data: res.some(r => r.data == null) ? undefined :
        {
            medicineRoutes: res[0].data as TeamMedicineRouteDto[],
            treatItems: res[1].data as ITreatItem[],
            ranchMedicines: res[2].data as TeamMedicineDao[],
            clinicMedicines: queries.length === 3 ? undefined : res[3].data as TeamMedicineDao[]
        }
    }
}

export const usePresets = (teamId: number | undefined) => {
    const enabled = teamId != null;
    const res = useQueries([
        { queryKey: [ QUERY.TEAM_MEDICINE, teamId ], queryFn:() => fetchMedicines(teamId ?? 0), enabled  },
        { queryKey: [ QUERY.TEAM_MEDICINE_ROUTE, teamId ], queryFn:() => fetchMedicineRoutes(teamId ?? 0), enabled },
        { queryKey: [ QUERY.TEAM_TREAT_ITEM, teamId ], queryFn:() => fetchTreatItems(teamId ?? 0), enabled },
        { queryKey: [ QUERY.TEAM_PRESET, teamId ], queryFn:() => fetchPresets(teamId ?? 0), enabled },
    ]);

    return {
        ...mergeQueryResults(res),
        data: res.some(r => r.data == null) ? undefined : {
            medicines: res[0].data as TeamMedicineDao[],
            medicineRoutes: res[1].data as TeamMedicineRouteDto[],
            treatItems: res[2].data as ITreatItem[],
            presets: res[3].data as TeamTreatPresetDto[]
        }
    }
}

export const getPresets = async (teamId: number) => {
    try {
        const medicines = await queryClient.fetchQuery([ QUERY.TEAM_MEDICINE, teamId ], () => fetchMedicines(teamId));
        const medicineRoutes = await queryClient.fetchQuery([ QUERY.TEAM_MEDICINE_ROUTE, teamId ], () => fetchMedicineRoutes(teamId));
        const treatItems = await queryClient.fetchQuery([ QUERY.TEAM_TREAT_ITEM, teamId ], () => fetchTreatItems(teamId));
        const presets = await queryClient.fetchQuery([ QUERY.TEAM_PRESET, teamId ], () => fetchPresets(teamId));

        return { medicines, medicineRoutes, treatItems, presets };

    } catch(err) {
        return undefined;
    }
}

export const usePresetsWithBothTeamMedicines = (ranchId: number, clinicId: number | undefined) => {
    const teamId = clinicId ?? ranchId;
    const queries = [
        { queryKey: [ QUERY.TEAM_PRESET, teamId ], queryFn:() => fetchPresets(teamId) },
        { queryKey: [ QUERY.TEAM_MEDICINE_ROUTE, teamId ], queryFn:() => fetchMedicineRoutes(teamId) },
        { queryKey: [ QUERY.TEAM_TREAT_ITEM, teamId ], queryFn:() => fetchTreatItems(teamId) },
        { queryKey: [ QUERY.TEAM_MEDICINE, ranchId ], queryFn:() => fetchMedicines(ranchId) },
    ];
    if (clinicId != null) {
        queries.push({ queryKey: [ QUERY.TEAM_MEDICINE, clinicId ], queryFn:() => fetchMedicines(clinicId) })
    }
    const res = useQueries(queries);

    return {
        ...mergeQueryResults(res),
        data: res.some(r => r.data == null) ? undefined : {
            presets: res[0].data as TeamTreatPresetDto[],
            medicineRoutes: res[1].data as TeamMedicineRouteDto[],
            treatItems: res[2].data as ITreatItem[],
            ranchMedicines: res[3].data as TeamMedicineDao[],
            clinicMedicines: res.length === 4 ? undefined : res[4].data as TeamMedicineDao[]
        }
    }
}

export const useVisitingRanches = (clinicId: number) => {
    return useQuery([ QUERY.VISITING_RANCH, clinicId ], () => fetchVisitingRanches(clinicId));
}
export const useVisitFees = (clinicId: number) => {
    return useQuery([ QUERY.VISIT_FEE, clinicId ], () => fetchVisitFees(clinicId));
}
export const useVisitFeesForRanch = (ranchId: number, clinicId: number | undefined) => {
    const res = useQueries([
        { queryKey: [ QUERY.VISITING_RANCH, clinicId ], queryFn:() => clinicId == null ? Promise.resolve([]) : fetchVisitingRanches(clinicId) },
        { queryKey: [ QUERY.VISIT_FEE, clinicId ], queryFn:() => clinicId == null ? Promise.resolve([]) : fetchVisitFees(clinicId) },
    ]);

    return {
        ...mergeQueryResults(res),
        data: res.some(r => r.data == null) ? undefined : {
            froms: (res[0].data as VisitListDto[]).find(p => p.ranch_id === ranchId)?.distances ?? [],
            fees: res[1].data as ClinicVisitFeeDto[]
        }
    };
}
export const useDiseaseRelations = (teamId: number) => {
    return useQuery([ QUERY.DISEASE_RELATION, teamId ], () => fetchDiseaseRelations(teamId));
}
export const useConditions = (teamId) => {
    return useQuery([ QUERY.CONDITION, teamId ], () => fetchConditions(teamId));
}
export const useConditionMap = (teamId: number) => {
    const res = useConditions(teamId);

    let data : Map<number, IConditionClass[]> | undefined;
    if (res.data != null) {
        const map = new Map<number, IConditionClass[]>();
        res.data.forEach(d => map.set(d.condition_id, d.classes));
        data = map;
    }
    return { ...res, data }
}
export const useSeeds = (teamType: TeamType, teamId: number) => {
    return useQuery([ QUERY.SEED, teamId ], () => fetchSeeds(teamType, teamId));
}
export const useBothTeamSeeds = (ranchId: number, clinicId: number | undefined) => {
    const queries = [
        { queryKey: [ QUERY.SEED, ranchId ], queryFn:() => fetchSeeds("ranch", ranchId) },
    ];
    if (clinicId != null) {
        queries.push(
            { queryKey: [ QUERY.SEED, clinicId ], queryFn:() => fetchSeeds("clinic", clinicId) },
        )
    }
    const res = useQueries(queries);

    return {
        ...mergeQueryResults(res),
        data: res.some(r => r.data == null) ? undefined : {
            ofRanch: res[0].data as RanchSeedListDto[],
            ofClinic: res.length === 1 ? undefined : res[1].data as RanchSeedListDto[]
        }
    };
}

export const getSeeds = async (teamType: TeamType, teamId: number, seedType?: number) => {
    const res = await getManually([QUERY.SEED, teamId ], () => fetchSeeds(teamType, teamId));
    if (res.data == null) return undefined;
    if (seedType == null) return res.data;
    return res.data.filter(s => s.seed_type === seedType);
}

export const useAncestors = (teamId: number) => {
    return useQuery([ QUERY.ANCESTOR, teamId ], () => fetchAncestors(teamId));
}
export const getAncestors = async (teamId: number) => {
    const res = await getManually([ QUERY.ANCESTOR, teamId ], () => fetchAncestors(teamId));
    return res.data;
}


const sortConditionsByBookmark = (original: ITeamCondition[]): ITeamCondition[] => {
    // NOTE: コピー元の症状リストに影響を与えないようにJSON.parse(JSON.stringify(obj))によるDeepCopy
    //       スプリット(...)によるコピーでは2階層以降のオブジェクト(classes、details)がシャロウコピーになる
    let list: ITeamCondition[] = JSON.parse(JSON.stringify(original));
    let bookmark: ITeamCondition = {
        condition_id: 0,
        name: "よく使う",
        classes: []
    };
    for (let i = 0; i < list.length; i++) {
        for(let j = 0; j < list[i].classes.length; j++) {
            if (list[i].classes[j].is_bookmarked === 1) {
                bookmark.classes.push(list[i].classes[j]);
            }
        }
    }
    return [ bookmark, ...list ];
}
const filterInvisibleDiseases = (list: TeamDiseaseCauseDto[]): TeamDiseaseCauseDto[] => {
    //病名レベルで非表示のもの
    const hiddenDiseaseIds = new Set(
        list.filter(r => r.cause_no == null && r.status === DISEASE_ITEM_STATUS.HIDDEN.no)
            .map(r => r.disease_id)
    );

    return list.filter(r => r.status !== DISEASE_ITEM_STATUS.HIDDEN.no
                      && !hiddenDiseaseIds.has(r.disease_id));
}

export const useDiseaseRelationsForInput = (teamId: number) => {
    const res = useQueries([
        { queryKey: [ QUERY.DISEASE_RELATION, teamId ], queryFn:() => fetchDiseaseRelations(teamId) },
        { queryKey: [ QUERY.CONDITION, teamId ], queryFn:() => fetchConditions(teamId) },
    ]);

    return {
        ...mergeQueryResults(res),
        data: res.some(r => r.data == null) ? undefined : {
            relations: filterInvisibleDiseases(res[0].data as TeamDiseaseCauseDto[]),
            conditions: sortConditionsByBookmark(res[1].data as ITeamCondition[])
        }
    };
}

export const useProcess = (ranchId: number) => {
    return useQuery([ QUERY.PROCESS, ranchId ], () => fetchProcess(ranchId));
}

const mergeQueryResults = <TData>(results: UseQueryResult<TData>[]): Omit<UseQueryResultSummary<TData>, "data"> => {
    return {
        isError: results.some(r => r.isError),
        isFetched: results.every(r => r.isFetched),
        isLoading: results.some(r => r.isLoading),
        isFetching: results.some(r => r.isFetching),
        isLoadingError: results.some(r => r.isLoadingError),
        isSuccess: results.every(r => r.isSuccess)
    }
}

export const WithQuery = <T>(props: { query: () => UseQueryResultSummary<T>, children:(result:UseQueryResultSummary<T>) => ReactElement }) => {
    return props.children(props.query());
}

const getManually = async <T>(key:string | unknown[], fn: () => Promise<T>): Promise<{ data:T | undefined, err:any|undefined }> => {
    try {
        const res = await queryClient.fetchQuery(key, fn);
        return { data:res, err:undefined };

    } catch (err) {
        return { data:undefined, err };
    }
}

export class ResponseMetaException {
    constructor(readonly meta?: ResponseMeta){}
}

/**
 * queryClientをCommunicator経由で利用するため、レスポンスにResponseMetaの情報を再付与する
 * ※fn はレスポンスエラーを ResponseMetaException でスローする形になっている必要あり
 */
export const postWithCache = async <T>(key: string | unknown[], fn: () => Promise<T>): Promise<{ data: { data?:T, meta?:ResponseMeta }}> => {
    try {
        const res = await queryClient.fetchQuery(key, fn);
        return { data: { data: res, meta: { errCode:0, errMsg:"OK" }} };

    } catch (err) {
        if (err instanceof ResponseMetaException) {
            return { data: { meta: err.meta } };
        }
        throw err;
    }
}