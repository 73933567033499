import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withContext } from '../../stores';
import { BaseProps } from '../content/base';
import { hasRanchAuth } from '../../config/auth-checker';

class Footer extends React.Component<BaseProps> {

	render() {

		const renderHiddenIcon = () => <div style={{width:"40px", visibility:"hidden"}}></div>;


		const renderSettingICon = () => {

			const ranchId = this.props.rootStore.cur_ranch_id;
			if (ranchId === 0) return renderHiddenIcon();
			const user = this.props.rootStore.user;

			if (hasRanchAuth("MASTER_EDIT", ranchId, user)) {
				return (
					<Link to={"/menu/" + this.props.rootStore.cur_ranch_id}>
						<button className="btn btn-inverse" style={{fontSize:"1.4rem"}}><i className="fas fa-cog"></i></button>
					</Link>
				);
			}
			return renderHiddenIcon();
		}

		const renderScheduleIcon = () => {
			const ranchId = this.props.rootStore.cur_ranch_id;
			if (ranchId === 0) return renderHiddenIcon();
			const user = this.props.rootStore.user;

			if (hasRanchAuth("BROWSE_INSIDE", ranchId, user)) {
				return (
					<Link to={"/schedule/" + ranchId}><button className="btn btn-inverse" style={{fontSize:"1.4rem"}}><i className="far fa-calendar-alt"></i></button></Link>
				)
			}
			return renderHiddenIcon();
		}

		const renderHomeIcon = () => {
			const ranchId = this.props.rootStore.cur_ranch_id;
			if (ranchId === 0) return renderHiddenIcon();

			return (
				<Link to={"/top/" + ranchId}><button className="btn btn-inverse" style={{fontSize:"1.4rem"}}><i className="fa fa-home"></i></button></Link>
			);
		}

		return (
			<div id="footer" className="footer">
				<div style={{display:"flex", alignItems:"center", justifyContent:"space-around", height:"100%"}}>
					{ renderSettingICon() }
					{ renderHomeIcon() }
					{ renderScheduleIcon() }
				</div>
			</div>
		)
	}
}

export default withRouter(withContext(Footer));