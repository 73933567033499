import React, { Fragment } from 'react';
import { FreezedArray } from 'config/util';
import { IconLinkProps, IconLink } from './icon-link';

interface Props {
    headers: string[];
    items: FreezedArray<{ name:string, color?:string, value:number, unit?:string }>;
    onClose?: () => void;
    link?: {
        onClick: () => void,
        text: string,
        icon: IconLinkProps["iconType"]
    };
    x: number;
    y: number;
}

export const ChartTooltip = (props: Props) => {

    const styles = {
        container: {
            position: "absolute",
            left: props.x < 0 ? undefined : props.x + "px",
            right: props.x < 0 ? (props.x * -1) + "px" : undefined,
            top: props.y < 0 ? undefined : props.y + "px",
            bottom: props.y < 0 ? (props.y * -1) + "px" : undefined,
            border: "solid 1px #999999",
            borderRadius: "6px",
            background: "white",
            opacity: "0.8",
            padding: "6px 12px 8px",
            display: "flex",
            flexFlow: "column nowrap",
            color:"#121212"
        },
        close: {
            width: "18px",
            height:"18px",
            padding: 0,
            borderRadius: "50%",
            cursor: "pointer",
            background: "#ff9999",
            color: "white",
            alignSelf: "flex-end",
            marginRight: "-16px",
            marginTop: "-10px",
            marginBottom: "-4px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        header: {

        },
        items: {
            display:"grid",
            gridTemplateRows:`repeat(${props.items.length}, 1fr)`,
            rowGap: "4px",
            marginTop: "4px"
        },
        color: (col:string) => ({
            color:col,
            gridColumn: "1 / 2",
        }),
        name: {
            gridColumn: "2 / 3",
        },
        value: {
            gridColumn: "3 / 4",
            paddingLeft: "6px"
        },
        link: {
            alignSelf: "flex-end"
        }

    } as const;

    return (
        <div style={styles.container}>
            { props.onClose && (
                <div style={styles.close} onClick={props.onClose}>×</div>
            )}
            { props.headers.map((h,i) => (
                <div style={styles.header} key={i}>{h}</div>
            ))}
            <div style={styles.items}>
                { props.items.map((item,i) => (
                    <Fragment key={i}>
                        { item.color && (
                            <div style={styles.color(item.color)}>●</div>
                        )}
                        <div style={styles.name}>{item.name}</div>
                        <div style={styles.value}>
                            <span>{item.value}</span>
                            { item.unit && (<span>{item.unit}</span>)}
                        </div>
                    </Fragment>
                ))}
            </div>

            { props.link != null && (
                <div style={styles.link}>
                    <IconLink iconType={props.link.icon} text={props.link.text} onClick={props.link.onClick} />
                </div>
            )}
        </div>
    );

}