import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { PageSettings } from '../../config/page-settings.js';
import { withContext, ISeed, ISeedLot } from '../../stores';
import { AppState } from '../../app';
import bStyles from './egg-base-style.module.css';
import { V3DateTime } from '../../components/parts/v3-date-time-picker';
import { TIMEPRESETS, LMT, A, isUnusedStock, EVENT_TYPE_PARAM } from '../../config/constant';
import { OvumRankCounter, OvumRankData } from './ovum-rank-counter';
import { IEventWriteLocationState, IEventWriteParamCow, historyLocation } from '../../config/history-location-builder';
import { EggApi, IvfDto, IvfRankDto, IvfModifyReqRank, OpuListDto, OpuDto, IvfModifyReq } from '../../api';
import { FreezedArray, CommonUtil, ar } from '../../config/util';
import moment from 'moment';
import { ICowNameInfo } from '../../components/parts/cows-popup';
import { findOvumRank } from '../../config/egg-kind';
import { FormRadio } from '../../components/form/form-radio';
import classnames from 'classnames';
import { IconLink } from '../../components/parts/icon-link';
import { SeedStockListPopup, SeedStockItem } from '../setting/seed-stock-list-popup';
import { pickLastNum } from '../setting/seed-label-range-input';
import { UserTeams } from '../../config/user-teams';
import { useBothTeamSeeds } from '../../stores/fetcher';
import { FetchWaiter, FetchError } from '../../components/content/fetch-state';
import { getCowWithCache } from 'stores/fetcher_cow';
import { SingleCowHeader } from 'components/cow-header/single-cow-header';
import { ExecutionButton } from 'components/buttons/execution-button';
import { DIALOG_BUTTONS } from 'components/form/form-dialog';

type IvfInputData = {
    seed_lot_id: number | undefined;
    label_no: string | undefined;
    countMap: Map<number, OvumRankData>;
    comment: string;
    opu_id: number | undefined;
}
interface MyState {
    executing: boolean;
    ranchId?: number;
    clinicId?: number;
    watchedAt: Date;
    cow?: IEventWriteParamCow;
    original?: IvfDto;
    input: IvfInputData;
    opuListOrSingle: FreezedArray<OpuListDto> | OpuDto;
    isOfficialRanch: boolean;
}

const toCountMap = (ranks: IvfRankDto[]) => {
    const map = new Map<number, OvumRankData>();
    ranks.forEach(r => {
        const data: OvumRankData = {
            count: r.egg_count,
            countL: undefined,
        };
        map.set(r.ovum_rank, data);
    });
    return map;
}
const initCountMapFromOpu = (opu: OpuListDto) => {
    const map = new Map<number, OvumRankData>();

    opu.ranks.forEach(opuRank => {
        const usedCounts = opu.ivfs.map(i => i.ranks.find(ir => ir.ovum_rank === opuRank.ovum_rank)?.egg_count ?? 0);
        const used = ar.sum(usedCounts);
        const rest = Math.max(0, opuRank.egg_count - used);
        map.set(opuRank.ovum_rank, { count: rest, countL: undefined })
    });
    return map;
}

export interface IIvfWriteLocationState extends IEventWriteLocationState {
    opu_id?: number;
    seed_lot?: { id: number, label_no: string };
}

const toReqRanks = (countMap: Map<number, OvumRankData>):IvfModifyReqRank[] => {
    return [...countMap.entries()]
        .filter(([_,data]) => data.count > 0)
        .map(([n,data]) => ({
            ovum_rank: n,
            egg_count: data.count,
        }));
}

const classColLabel = "col-form-label col-md-4 col-xs-4 text-lg-right";

class IvfWrite extends Base<BaseProps<{ id:string },{},IIvfWriteLocationState|undefined>, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            watchedAt: new Date(),
            executing: false,
            opuListOrSingle: [],
            input:{ countMap: new Map(), comment: "", label_no: undefined, seed_lot_id: undefined, opu_id: undefined },
            isOfficialRanch: false
        }
    }

    componentDidMount() {

        this.context.handleSetHeader({ title:"媒精記録" });
        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        const ranchId = this.props.rootStore.cur_ranch_id;
        const cows = this.props.location.state?.cows ?? [];
        const mayEvtId = parseInt(this.props.match.params.id);
        const evtId = isNaN(mayEvtId) ? undefined : mayEvtId;
        const isOfficial = ranchId === 0 ? false : new UserTeams(this.props.rootStore.user).findOfficialRanch(ranchId) != null;
        const clinicId = this.props.rootStore.getClinicIdForMaster();

        this.setState({
            ranchId: ranchId === 0 ? undefined : ranchId,
            cow: cows.length === 0 ? undefined : cows[0],
            isOfficialRanch: isOfficial,
            clinicId
        }, () => {
            this.loadInitial(evtId);
        });
    }

    private async loadInitial(eventId: number | undefined) {
        if (this.state.ranchId == null) return;

        this.context.handleSetIsLoading(true);

        try {
            const api = await EggApi();

            if (eventId != null) {
                const oriReq = { ranch_id: this.state.ranchId, id: eventId };
                const oriRes = await this.comm().send(api.getIvfUsingPOST(oriReq), { retries: true });
                const original = oriRes.data;
                if (original == null) return;

                if (this.navToRanchTopIfSelfEditOnly("IVF", original.watched_by)) return;

                //培養結果記録済みなら牧場TOPへとばす
                if (original.egg != null) {
                    await this.context.showModalAsync(A.MESSAGE.IVF_CANNOT_EDIT, "ALERT", ["OK"]);
                    this.props.history.replace("/top/" + this.state.ranchId);
                    return;
                }

                const opuRes = await this.comm().send(api.getOpuUsingPOST({ ranch_id: this.state.ranchId, id: original.opu_id }), { retries: true });
                if (opuRes.data == null) return;

                const cowRes = await this.comm().send(() => getCowWithCache(oriReq.ranch_id, original.cow_id), { retries: true });
                if (cowRes.data == null) return;

                this.setState({
                    original,
                    opuListOrSingle: opuRes.data,
                    cow: {
                        ...cowRes.data,
                        use_no:cowRes.data.use_no,
                        breed_no:cowRes.data.breed_no,
                    },
                    input: {
                        comment: original.comment,
                        countMap: toCountMap(original.ranks),
                        label_no: original.label_no,
                        seed_lot_id: original.seed_lot_id,
                        opu_id: original.opu_id
                    },
                    watchedAt: moment(original.watched_at).toDate(),
                })
            } else {
                if (this.state.cow == null) return;
                const cowId = this.state.cow.cow_id;
                const lot = this.props.location.state?.seed_lot;

                const opuId = this.props.location.state?.opu_id;
                if (opuId == null) {
                    const from = moment().add(LMT.IVF.OPU_SEARCH_DAYS * -1, "days").format("YYYY-MM-DD");
                    const listRes = await this.comm().send(api.listOpuUsingPOST({ ranch_id: this.state.ranchId, cow_id: cowId, from }), { retries: true });
                    if (listRes.result !== "OK") return;
                    const opuList = listRes.data ?? [];
                    const opu = opuList.length === 0 ? undefined : opuList[opuList.length - 1];
                    this.setState({
                        opuListOrSingle: opuList,
                        input: {
                            ...this.state.input,
                            seed_lot_id: lot?.id,
                            label_no: lot?.label_no,
                            opu_id: opu?.event_id,
                            countMap: opu == null ? this.state.input.countMap : initCountMapFromOpu(opu)
                        }
                    })
                } else {
                    const opuRes = await this.comm().send(api.getOpuUsingPOST({ ranch_id: this.state.ranchId, id: opuId }), { retries: true });
                    if (opuRes.data == null) return;
                    this.setState({
                        opuListOrSingle: opuRes.data,
                        input: {
                            ...this.state.input,
                            opu_id: opuId,
                            seed_lot_id: lot?.id,
                            label_no: lot?.label_no,
                            countMap: initCountMapFromOpu(opuRes.data)
                        }
                    })
                }
            }
        } finally {
            this.context.handleSetIsLoading(false);
        }
    }

    private setExecuting(executing: boolean) {
        this.context.handleSetIsLoading(executing);
        this.setState({ executing });
    }

    private onInputDataChange(data: IvfInputData) {
        const old = this.state.input;
        let countMap: Map<number, OvumRankData>;

        if (old.opu_id !== data.opu_id && data.opu_id != null) {
            if ("event_id" in this.state.opuListOrSingle) {
                if (this.state.opuListOrSingle.event_id !== data.opu_id) {
                    console.error("opu id unmatched", data.opu_id, this.state.opuListOrSingle);
                    return;
                } else {
                    countMap = initCountMapFromOpu(this.state.opuListOrSingle);
                }
            } else {
                const opu = this.state.opuListOrSingle.find(o => o.event_id === data.opu_id);
                if (!CommonUtil.assertNotNull(opu, "opu id=" + data.opu_id)) return;
                countMap = initCountMapFromOpu(opu)
            }
        } else {
            countMap = data.countMap;
        }

        this.setState({
            input: {
                ...data,
                countMap
            }
        });
    }


    async onSubmit() {
        if (!CommonUtil.assertNotNull(this.state.ranchId, "ranchId")) return;
        if (!CommonUtil.assertNotNull(this.state.cow, "cow")) return;
        if (!CommonUtil.assertNotNull(this.state.input.opu_id, "opuId")) return;
        if (!CommonUtil.assertNotNull(this.state.input.label_no, "labelNo")) return;
        if (!CommonUtil.assertNotNull(this.state.input.seed_lot_id, "lot_id")) return;

        const req: IvfModifyReq = {
            ranch_id: this.state.ranchId,
            watched_at: moment(this.state.watchedAt).format("YYYY-MM-DD HH:mm:00"),
            schedule_id: this.props.location.state?.schedule_id,
            comment: this.state.input.comment,
            cow_id: this.state.cow.cow_id,
            seed_lot_id: this.state.input.seed_lot_id,
            label_no: this.state.input.label_no,
            opu_id: this.state.input.opu_id,
            event_id: this.state.original?.event_id,
            ranks: toReqRanks(this.state.input.countMap)
        };
        this.setExecuting(true);

        try {
            const res = await this.comm().send((await EggApi()).modifyIvfUsingPOST(req));
            if (res.result !== "OK") return;

            if (this.state.original == null) {
                this.props.history.replace(historyLocation.toCowInfo(this.state.cow.cow_id));
            } else {
                this.props.history.go(-1);
            }

        } finally {
            this.setExecuting(false);
        }
    }

    async onDelete() {
        if (!CommonUtil.assertNotNull(this.state.ranchId, "ranchId", "delete")) return;
        if (!CommonUtil.assertNotNull(this.state.cow, "cow", "delete")) return;
        if (!CommonUtil.assertNotNull(this.state.original, "original", "delete")) return;
        const conf = (await this.context.showDialog("QUESTION", "記録を削除してよろしいですか？", DIALOG_BUTTONS.DELETE_CANCEL)) === 0;
        if (!conf) return;

        this.setExecuting(true);

        const req = {
            ranch_id: this.state.ranchId,
            id: this.state.original.event_id
        };
        const res = await this.comm().send((await EggApi()).deleteIvfUsingPOST(req));
        this.setExecuting(false);

        if (res.result !== "OK") return;

        this.props.history.replace(historyLocation.toCowEvent(
            this.state.cow.cow_id,
            moment(this.state.original.watched_at).format("YYYY-MM-DD"),
            EVENT_TYPE_PARAM.BREEDING
        ));

    }


    render() {
        if (this.state.ranchId == null || this.state.cow == null) {
            return <></>;
        }
        if (Array.isArray(this.state.opuListOrSingle) && this.state.opuListOrSingle.length === 0) {
            return <div>対象となるOPU記録が見つかりません</div>
        }

        const anyInvalid = !isValidInput(this.state.input);

        return (
            <div className="page-root">
                <div className="product product-full-height">
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix">
                            <div className="product-info-header">
                                <SingleCowHeader ranchId={this.state.ranchId} cowId={this.state.cow.cow_id} />
                            </div>

                            <div className="product-body">
                                <div className={bStyles.row}>
                                    <label className={classColLabel}>媒精日時</label>
                                    <div className={bStyles["watched-at"]}>
                                        <V3DateTime value={this.state.watchedAt}
                                            popperClassName={bStyles["datepicker-popper"]}
                                            timePresets={this.props.rootStore.user.setting?.time_preset ?? TIMEPRESETS}
                                            onChange={d => this.setState({ watchedAt: d.toDate() })} />
                                    </div>
                                </div>

                                <IvfInput
                                    opuListOrSingle={this.state.opuListOrSingle}
                                    data={this.state.input}
                                    onChange={d => this.onInputDataChange(d)}
                                    cow={this.state.cow}
                                    editingIvfId={this.state.original?.event_id}
                                    ranchId={this.state.ranchId}
                                    clinicId={this.state.clinicId}
                                    isOfficial={this.state.isOfficialRanch}
                                />

                            </div>
                        </div>
                    </div>
                </div>
                <div className="button-page-footer">
                    <ExecutionButton type="save" disabled={this.state.executing || anyInvalid}
                        onClick={() => this.onSubmit()}
                    />
                    { this.state.original != null && (
                        <ExecutionButton type="delete" disabled={this.state.executing}
                            onClick={() => this.onDelete()}
                        />
                    )}
                </div>
            </div>
        )
    }
}

const isValidInput = (data: IvfInputData) => {
    if (data.label_no == null || data.seed_lot_id == null) return false;
    if (data.opu_id == null) return false;
    const ranks = [...data.countMap.values()];
    if (ar.sum(ranks.map(r => r.count)) === 0) return false;
    return true;
}
const IvfInput = (props: {
    cow: ICowNameInfo;
    data: IvfInputData;
    onChange: (data: IvfInputData) => void;
    opuListOrSingle: FreezedArray<OpuListDto> | OpuDto;
    editingIvfId: number | undefined;
    ranchId: number;
    clinicId: number | undefined;
    isOfficial: boolean;
}) => {
    const mSeeds = useBothTeamSeeds(props.ranchId, props.clinicId);
    const [ seeds, setSeeds ] = useState<{ ranch:FreezedArray<ISeed>, clinic?:FreezedArray<ISeed> }>();

    useEffect(() => {
        if (mSeeds.data == null) {
            setSeeds(undefined);
        } else {
            setSeeds({
                ranch: mSeeds.data.ofRanch.find(r => r.seed_type === A.SEED_TYPE.SEED.seed_type)?.seeds ?? [],
                clinic: mSeeds.data.ofClinic?.find(c => c.seed_type === A.SEED_TYPE.SEED.seed_type)?.seeds
            });
        }

    }, [ mSeeds.data?.ofRanch, mSeeds.data?.ofClinic ]);

    const onRankChange = (countMap: Map<number, OvumRankData>) => {
        props.onChange({
            ...props.data,
            countMap
        });
    }

    const onOpuSelected = (val: string) => {
        props.onChange({
            ...props.data,
            opu_id: val === "" ? undefined : Number(val)
        });
    }

    const buildOpuListItem = (opu: OpuListDto | OpuDto) => {
        const rank = opu.ranks.map(r => `${(findOvumRank(r.ovum_rank)?.name ?? "")}${r.egg_count}個`).join(" ");

        return `${moment(opu.watched_at).format("M/D")} ${rank}`;
    }

    return (
        <div>
            <div className={bStyles.row}>
                <label className={classColLabel}>OPU</label>
                { ("event_id" in props.opuListOrSingle) ? (
                    <div className="p-t-10 p-b-10" data-testid="opu-fixed">{buildOpuListItem(props.opuListOrSingle)}</div>
                ) : (
                    <select className={"form-control " + bStyles.select}
                        onChange={e => onOpuSelected(e.target.value)}
                        value={props.data.opu_id}>
                        { props.opuListOrSingle.map(opu => (
                            <option key={opu.event_id} value={opu.event_id}>{buildOpuListItem(opu)}</option>
                        ))}
                    </select>
                )}
            </div>

            { mSeeds.isLoading ? (
                <FetchWaiter />
            ) : (mSeeds.isError || seeds == null) ? (
                <FetchError />
            ) : props.data.opu_id != null && (<>
                <SeedLotSelect
                    ranchSeedList={seeds.ranch}
                    clinicSeedList={seeds.clinic}
                    seed_lot_id={props.data.seed_lot_id}
                    label_no={props.data.label_no}
                    onChange={(s,l) => props.onChange({
                        ...props.data,
                        seed_lot_id:s,
                        label_no:l
                    })}
                    editingIvfId={props.editingIvfId}
                    isOfficialRanch={props.isOfficial}
                />

                <div className={bStyles.row}>
                    <label className={classColLabel}>ランク</label>
                    <OvumRankCounter className="m-l-15"
                        canLR={false}
                        cowKey={props.cow.cow_id}
                        countMap={props.data.countMap}
                        onChange={onRankChange}
                    />
                </div>
                <div className={bStyles.row}>
                    <label className={classColLabel}>メモ</label>
                    <div className={bStyles["column-value"]}>
                        <textarea className="form-control" rows={2}
                            maxLength={LMT.IVF.MEMO_LEN}
                            value={props.data.comment}
                            onChange={e => props.onChange({ ...props.data, comment:e.target.value })}
                        />
                    </div>
                </div>
            </>)}
        </div>
    )
}



const SeedLotSelect = React.memo((props: {
    ranchSeedList: FreezedArray<ISeed>;
    clinicSeedList?: FreezedArray<ISeed>;
    seed_lot_id: number | undefined;
    label_no: string | undefined;
    onChange: (seed_lot_id: number | undefined, label_no: string | undefined) => void;
    editingIvfId: number | undefined;
    isOfficialRanch: boolean;
}) => {
    const [ isRanchSelected, setIsRanchSelected ] = useState(true);
    const [ selectedSeed, setSelectedSeed ] = useState<{ seed: ISeed, isRanch: boolean }>();
    const [ lots, setLots ] = useState<ISeedLot[]>([]);
    const [ labels, setLabels ] = useState<string[]>([]);
    const [ isSeedStockListShown, setIsSeedStockListShown ] = useState(false);
    const [ currentAllStocks, setCurrentAllStocks ] = useState<SeedStockItem[]>([]);

    useEffect(() => {
        setIsRanchSelected(props.isOfficialRanch);

    }, [ props.isOfficialRanch ]);

    useEffect(() => {
        const list = isRanchSelected ? props.ranchSeedList : (props.clinicSeedList ?? []);
        setSelectedSeed(preSeed => {
            if (preSeed == null) return undefined;
            if (isRanchSelected === preSeed.isRanch
                && list.some(s => s.seed_id === preSeed.seed.seed_id)) {

                return preSeed;
            }
            return undefined;
        });

        const lots = ar.flat(list.map(s => s.lots.map(l => ({ name: s.name, seed_id: s.seed_id, ancestor_1:s.ancestor_1, ...l }))));
        const stocks = ar.flat(lots.map(l => ar.orderBy(l.stocks, s => pickLastNum(s.label_no) ?? 0).map(s => ({ ...l, ...s }))))
                .filter(s => isUnusedStock(s));

        setCurrentAllStocks(stocks);

    }, [ props.ranchSeedList, props.clinicSeedList, isRanchSelected ]);

    useEffect(() => {
        if (selectedSeed == null) {
            setLots([]);
        } else {
            setLots(selectedSeed.seed.lots.filter(l => l.stocks.some(s => isUnusedStock(s, undefined, props.editingIvfId))));
        }
    }, [ selectedSeed ]);

    useEffect(() => {
        if (props.seed_lot_id == null) {
            setLabels([]);
            return;
        }

        let isRanch: boolean | undefined = undefined;
        let seed = findSeed(props.ranchSeedList, props.seed_lot_id);
        if (seed != null) {
            isRanch = true;
        } else if (props.clinicSeedList != null) {
            seed = findSeed(props.clinicSeedList, props.seed_lot_id);
            if (seed != null) {
                isRanch = false;
            }
        }
        if (seed == null) {
            props.onChange(undefined, undefined);
            return;
        }

        if (CommonUtil.assertNotNull(isRanch)) {
            setIsRanchSelected(isRanch);
            setSelectedSeed({ seed, isRanch });
        }

        const lot = seed.lots.find(l => l.seed_lot_id === props.seed_lot_id);
        if (CommonUtil.assertNotNull(lot)) {
            setLabels(
                ar.orderBy(lot.stocks, s => pickLastNum(s.label_no) ?? 0)
                    .filter(s => isUnusedStock(s, undefined, props.editingIvfId))
                    .map(s => s.label_no)
            );
        }
        

    }, [ props.seed_lot_id ]);

    const findSeed = (list: FreezedArray<ISeed>, lot: number) => {
        return list.find(s => s.lots.some(l => l.seed_lot_id === lot));
    }

    const lotToDispName = (lot: ISeedLot) => {
        return `${lot.lot_name}（${moment(lot.stock_day).format("YYYY/M/D")}, ${lot.unit_price}円）`
    }
    const onIsRanchSelectedChangeManualy = (isRanch: boolean) => {
        setIsRanchSelected(isRanch);
        props.onChange(undefined, undefined);
    }
    const onSeedIdChangeManualy = (seedId: number | undefined) => {
        if (seedId == null) {
            setSelectedSeed(undefined);
        } else {
            const list = isRanchSelected ? props.ranchSeedList : (props.clinicSeedList ?? []);
            const seed = list.find(s => s.seed_id === seedId);
            if (CommonUtil.assertNotNull(seed)) {
                setSelectedSeed({ seed, isRanch: isRanchSelected });
            }
        }
        props.onChange(undefined, undefined);
    }

    const onSeedSelectedOnList = (newSeedId: number, newLotId: number, newLabelNo: string) => {
        setIsSeedStockListShown(false);

        if (props.seed_lot_id === newLotId) {
            if (props.label_no === newLabelNo) return;

            props.onChange(props.seed_lot_id, newLabelNo);
            return;
        }

        props.onChange(newLotId, newLabelNo);
    }

    return (<>
        <div className={bStyles.row}>
            <label className={classColLabel}>使用精液</label>
            <div className={bStyles["column-value"]}>
                { props.clinicSeedList != null && props.isOfficialRanch && (
                    <div className={classnames(bStyles.item, "m-b-10")}>
                        <FormRadio
                            prefix="stockKind"
                            isInline={true}
                            value={isRanchSelected ? 1 : 0}
                            onChange={n => onIsRanchSelectedChangeManualy(n === 1)}
                            options={[ { value: 1, name:"牧場在庫" }, { value: 0, name:"診療所在庫" } ]}
                        />
                    </div>
                )}

                <IconLink text="在庫一覧" iconType="popup" onClick={() => setIsSeedStockListShown(true)} />
                <select className={classnames("form-control", bStyles.select, bStyles.item)} value={selectedSeed?.seed.seed_id ?? ""}
                    onChange={e => onSeedIdChangeManualy(e.target.value === "" ? undefined : Number(e.target.value))}
                    data-testid="seed">
                    <option value="">選択</option>
                    { (isRanchSelected ? props.ranchSeedList : (props.clinicSeedList ?? [])).map(s => (
                        <option key={s.seed_id} value={s.seed_id}>{s.name}（{s.sname}）</option>
                    ))}
                </select>

                <select className={classnames("form-control", bStyles.select, bStyles.item)} value={props.seed_lot_id ?? ""}
                    onChange={e => props.onChange(e.target.value === "" ? undefined : Number(e.target.value), undefined)}
                    data-testid="seed-lot">
                    <option value="">Lot選択</option>
                    { lots.map(l => (
                        <option key={l.seed_lot_id} value={l.seed_lot_id}>{lotToDispName(l)}</option>
                    ))}
                </select>
            </div>
        </div>

        <div className={bStyles.row}>
            <label className={classColLabel}>ラベルNo.</label>
            <select className={"form-control " + bStyles.select} value={props.label_no ?? ""}
                onChange={e => props.onChange(props.seed_lot_id, e.target.value === "" ? undefined : e.target.value)}
                data-testid="seed-label">
                <option value="">選択</option>
                { labels.map((l,i) => (
                    <option key={i} value={l}>{l}</option>
                ))}
            </select>
        </div>
        { isSeedStockListShown && (
            <SeedStockListPopup
                onClose={() => setIsSeedStockListShown(false)}
                onSelect={onSeedSelectedOnList}
                seed_type={A.SEED_TYPE.SEED.seed_type}
                teamType={isRanchSelected ? "ranch" : "clinic"}
                stocks={currentAllStocks}
            />
        )}
    </>)
});


export default withRouter(withContext(IvfWrite));