import React, { useEffect, useState } from 'react';
import moment from 'moment'
import classNames from 'classnames/bind'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import { CowApi, CowListDto, SymptomLatestReq, SymptomApi, SympTreatDto, SympDataDto, SympConditionDto, TeamTreatPresetDto, ModifyReqVisit } from '../../api'

import { useRootStore, IMedicineCategory, ITreatKind, ITeamCondition, IConditionClass, IRanchHouse } from '../../stores';

import { Cow, blankInput, Treat, isSameTreat } from './index'

import EditModal from './modal/Edit'
import NoticeModal from '../feedbulk/modal/Notice'

import baseStyles from './treatbulk.module.css'
import styles from './RegisterTreat.module.css'
import { usePageStore } from '../../config/page-settings';
import { CommonUtil, ar, FreezedArray } from '../../config/util';
import { AppState } from '../../app';
import { Communicator } from '../../api/communicator';
import { MedicineInput, MedicineInputItem, TreatInputItem, TreatInput, isValidMedicine, isValidTreat } from './TreatInput';
import { convPresetToTreatSelectorData } from '../../components/parts/treat-selector';
import { RequiredNumInput } from '../../components/parts/num-input';
import { Checkbox } from '../../components/form/form-checkbox';
import { InfoPopup } from '../../components/parts/info-popup';
import { EditingPhisical } from '../symptom/symptom-physical-input';
import { BulkCowRow } from '../feedbulk/BulkCowRow';
import { anyPhysicalValue } from './modal/PhysicalEdit';
import { hasTeamAuth } from '../../config/auth-checker';
import { SortType, SortOrder, BulkCowSortIcon } from '../feedbulk/BulkCowSortIcon';
import { BulkCowNoticeIcon } from '../feedbulk/BulkCowNoticeIcon';
import { IconLink } from '../../components/parts/icon-link';
import { TreatPresetSelectPopup } from '../../components/parts/treat-preset-select-popup';
import { TempTreatItemRow } from './temp-treat-item-row';
import { TreatEditModal } from './modal/TreatEdit';
import { TREAT_BENEFIT_TYPE, getDefaultBenefitType } from '../../config/constant';
import { VisitFeeSelectPopup } from '../symptom/visit-fee-select-popup';
import { usePresetsWithBothTeamMedicines, useVisitFeesForRanch, useConditions } from '../../stores/fetcher';
import { FetchWaiter, FetchError } from '../../components/content/fetch-state';

let styleCxt = classNames.bind(styles);

const CowRow: React.FC<{
  cow           : Cow,
  toggleSelect  : (event: React.ChangeEvent<HTMLInputElement>, cow: Cow) => void,
  openEditModal : (cow : Cow) => void,
}> = ({ cow, toggleSelect, openEditModal }) => {
  const toRowData = (cow: Cow) => (
    <>
    { cow.input.first_id != null && (
      <div className={ styleCxt('feed-row') }>再診/継続</div>
    )}
    { ((cow.input.physical != null && anyPhysicalValue(cow.input.physical)) || cow.input.memo !== "") && (
      <div className={ styleCxt('feed-row') }>体調記録あり</div>
    )}
    { (cow.input.status != null || cow.input.schedules.length > 0) && (
      <div className={ styleCxt('feed-row') }>状態・予定あり</div>
    )}
    {
      cow.input.treats.map((treat,i) => (
        <div key={i} className={ styleCxt('feed-row') } data-testid="処置項目">
          {treat.name} {treat.amount ?? ""}{treat.unit ?? ""}
        </div>
      ))
    }
    </>
  )
  
  return <BulkCowRow cow={cow} toggleSelect={toggleSelect} openEditModal={openEditModal} showsOvereat={false} toRowData={toRowData} />
}

type PropType = {
  ranchId : number,
  clinicId: number | undefined,
  date    : Date,
  allCows : Array<CowListDto>,
  cows    : Array<Cow>,
  setCows : React.Dispatch<React.SetStateAction<Array<Cow>>>,
  visit   : ModifyReqVisit | undefined,
  setVisit: React.Dispatch<React.SetStateAction<ModifyReqVisit | undefined>>,
  ranchHouses : FreezedArray<IRanchHouse>
}

const TAB_MEDICINE = "1";
const TAB_TREAT = "2";
const TAB_HISTORY = "3";
const TAB_PRESET  = "4";
type TabIndex = typeof TAB_MEDICINE| typeof TAB_TREAT | typeof TAB_HISTORY | typeof TAB_PRESET;

const RegisterTreat: React.FC<PropType> = ({ ranchId, clinicId, date, allCows, cows, setCows, visit, setVisit, ranchHouses }) => {
  const { user, logout } = useRootStore()
  const rootStore = useRootStore()
  const { showToast, showQuestion, showDialog, handleSetIsLoading } = usePageStore() as AppState;

  const [sortType, setSortType] = useState<SortType>('Trace');
  const [sortOrder, setSortOrder] = useState<SortOrder>('Asc');
  const [editCow, setEditCow] = useState<Cow>({
    id: -1, name: '', house: [0,0,0], birthday: 0, repId: '', earTag: false, selected : true, input:blankInput(),
    attrs : { moved : false, watched : false, overeat : false, watched_memo: "" }
  });

  const [showEditModal, setShowEditModal] = useState(false)
  const [showNoticeModal, setShowNoticeModal] = useState(false)
  const [activeTab, setActiveTab] = useState<TabIndex>(TAB_MEDICINE)
  const [currentMedicine, setCurrentMedicine] = useState<Readonly<MedicineInputItem>>({ amount:0, unit_price:0, benefit_type:"NO_BENEFIT" });
  const [currentTreat, setCurrentTreat] = useState<Readonly<TreatInputItem>>({ unit_price:0, benefit_type:"NO_BENEFIT" });
  const [currentPresetItems, setCurrentPresetItems] = useState<FreezedArray<Treat & { valid: boolean }>>([]);
  const [isPresetSelectShown, setIsPresetSelectShown] = useState(false);
  const [ editingPresetItem, setEditingPresetItem ] = useState<{ treat:Treat, index:number }>();

  const [ medicineCategories, setMedicineCategories ] = useState<IMedicineCategory[]>([]);
  const [ treatKinds, setTreatKinds ] = useState<ITreatKind[]>([]);
  const [ isValidInput, setIsValidInput ] = useState(false);
  const [ latestDays, setLatestDays ] = useState(30);
  const [ asReexamine, setAsReExamine ] = useState(true);
  const [ isVisitFeeSelectShown, setIsVisitFeeSelectShown ] = useState(false);

  const mPresets = usePresetsWithBothTeamMedicines(ranchId, clinicId);
  const mVisits = useVisitFeesForRanch(ranchId, clinicId);
  const mConditions = useConditions(clinicId ?? ranchId);

  useEffect(() => {
    setMedicineCategories(rootStore.options.medicine_category);
    setTreatKinds(rootStore.options.treat_kind);

    const benefit_type = getDefaultBenefitType(clinicId != null);
    setCurrentMedicine({ ...currentMedicine, benefit_type });
    setCurrentTreat({ ...currentTreat, benefit_type });

  }, [ rootStore, ranchId, clinicId ]);

  useEffect(() => {
    const isValid = activeTab === TAB_MEDICINE ? isValidMedicine(currentMedicine, true)
                  : activeTab === TAB_TREAT ? isValidTreat(currentTreat, true)
                  : activeTab === TAB_PRESET ? isValidPreset()
                  : true
    setIsValidInput(isValid);

  }, [ activeTab, currentMedicine, currentTreat, currentPresetItems ]);

  const comm = new Communicator({ logout, showToast, showDialog });

  const toggleTab = (tab: TabIndex) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  const isValidPreset = () => {
    if (currentPresetItems.length === 0) return false;

    return currentPresetItems.every(p => p.valid);
  }

  const fetchCows = async () => {

    const movedLimit = moment(date).add(-1,'days').startOf('day')

    const appending = (allCows)
      .sort((a, b) => parseInt(a.rep_no ?? '0') - parseInt(b.rep_no ?? '0'))
      .map((r): Cow => {
        return {
          id       : r.cow_id,
          name     : r.name ?? "",
          house    : [r.site, r.barn, r.room],
          birthday : moment(r.birthday ?? 0).toDate().getTime(),
          repId    : r.rep_no ?? "",
          earTag   : (r.local_no ?? "") !== '',
          selected : true,
          attrs    : {
            moved : moment(r.moved_at).isSameOrAfter(movedLimit) || false,
            watched : (r.watching ?? 0) > 0,
            watched_memo: r.watching_memo,
            overeat: false
          },
          input: blankInput()
        }
      });

    setCows(appending)
  }

  useEffect(() => {
    setCows([])
    fetchCows()
  }, [allCows, date])

  const onClear = () => {
    showQuestion("選択された牛の入力情報がクリアされます", res => {
      if (res !== 0) return;

      setCows(
        cows.map(cow => {
          if (!cow.selected) return cow;

          return ({ ...cow, input: blankInput() });
        })
      )

    }, [ "実行", "キャンセル" ]);
  }

  const onPresetSelected = (preset: TeamTreatPresetDto) => {
    if (!CommonUtil.assertNotNull(mPresets.data, "mPreset.data")) return;

    const medicines = mPresets.data.clinicMedicines ?? mPresets.data.ranchMedicines;
    const routes = mPresets.data.medicineRoutes;
    const treats = mPresets.data.treatItems;

    setCurrentPresetItems(
      ar.notNull(preset.items.map(pi => {
        const treatSelData = convPresetToTreatSelectorData(pi, clinicId ?? ranchId, medicines, routes, treats);
        if (treatSelData == null) return undefined;

        const benefit_type = TREAT_BENEFIT_TYPE[treatSelData.benefit_type].no;
        const treat = { ...treatSelData, benefit_type };
        
        const valid = treat.medicine_id == null
                ? isValidTreat(treat, true)
                : (treat.amount != null && isValidMedicine( { ...treat, amount:treat.amount }, true));

        return {
          ...treat,
          valid
        }
      }))
    );
    setIsPresetSelectShown(false);
  }

  const onPresetItemSubmit = (item: Treat) => {
    if (!CommonUtil.assertNotNull(editingPresetItem)) return;

    const treats = [...currentPresetItems];

    if (treats.some((t,i) => i !== editingPresetItem.index && isSameTreat(t, item))) {
      showToast("同じ項目が選択されています");
      return;
    }

    treats[editingPresetItem.index] = { ...item, valid:true };

    setCurrentPresetItems(treats);
    setEditingPresetItem(undefined);
  }

  const setWatch = async (cow : Cow, watching: boolean, memo: string) => {
    const res = await comm.send((await CowApi()).modifyCowWatchingUsingPOST({
      cow_id: cow.id, ranch_id: ranchId, watching : watching ? 1 : 0, watching_memo: memo,
    }));
    if (res.result !== "OK") return;

    rootStore.fetchActiveCows(ranchId, "DIFF")
    
    setCows(cows => cows.map(c => c.id === cow.id ? { ...cow, attrs: { ...cow.attrs, watched : watching, watched_memo: memo } } : c))
  }

  const openEdit = (cow : Cow) => {
    setEditCow(cow)
    setShowEditModal(true)
  }

  const toggleAllSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCows(cows.map(c => ({ ...c, selected: (event.target as HTMLInputElement).checked })))
  }

  const toggleSelect = (event: React.ChangeEvent<HTMLInputElement>, cow: Cow) => {
    setCows(cows.map(c => c.id === cow.id ? { ...c, selected: (event.target as HTMLInputElement).checked } : c))
  }

  const getHouseName = (house: Array<number>) => {
    const first = ranchHouses.find(h => h.no === house[0])
    if (!first || !first.data) return ''
    const second = first.data.find(h => h.no === house[1])
    if (!second || !second.data) return first.name
    const third = second.data.find(h => h.no === house[2])
    if (!third) return `${first.name} ${second.name}`
    return  `${first.name} ${second.name} ${third.name}`
  }

  const applyMedicine = () => {
    const med = currentMedicine;
    if (!CommonUtil.assertNotNull(med.name, "name")
      || !CommonUtil.assertNotNull(med.route_id, "route_id")
      || !CommonUtil.assertNotNull(med.unit, "unit")
      || !CommonUtil.assertNotNull(med.route_name, "route_name")
      || !CommonUtil.assertNotNull(med.team_id, "team_id")) {
      return;
    }

    setCows(
      cows.map(cow => {
        if (!cow.selected) return cow;
        const treats = [...cow.input.treats.filter(t => !(t.team_id === med.team_id && t.medicine_id === med.medicine_id))];
        if (med.amount > 0) {
          treats.push({
            medicine_id: med.medicine_id,
            amount: med.amount,
            unit: med.unit,
            name: med.name!,
            treat_kind_no: undefined,
            treat_item_no: undefined,
            team_id: med.team_id!,
            unit_price: med.unit_price,
            route_id: med.route_id,
            route_name: med.route_name,
            new_item_unit: med.new_item_unit,
            new_medicine_category: med.new_medicine_category,
            new_medicine_name: med.new_medicine_name,
            benefit_type: TREAT_BENEFIT_TYPE[med.benefit_type].no
          });
        }
        return { ...cow, input: { ...cow.input, treats }};
      })
    )
  }

  const applyTreat = () => {
    const treat = currentTreat;
    if (!CommonUtil.assertNotNull(treat.treat_kind_no, "treat_kind_no")
      || !CommonUtil.assertNotNull(treat.name, "name")) {
      return;
    }

    const teamId = clinicId ?? ranchId;

    setCows(
      cows.map(cow => {
        if (!cow.selected) return cow;
        const treats = [...cow.input.treats.filter(t => !(t.team_id === teamId && t.treat_kind_no === treat.treat_kind_no && t.treat_item_no === treat.treat_item_no))];
        if (treat.amount == null || treat.amount > 0) {
          treats.push({
            medicine_id: undefined,
            amount: treat.amount,
            unit: treat.unit,
            name: treat.name!,
            treat_kind_no: treat.treat_kind_no,
            treat_item_no: treat.treat_item_no,
            team_id: teamId,
            unit_price: treat.unit_price,
            note: "",
            new_treat_item_name: treat.new_treat_item_name,
            new_item_unit: treat.new_item_unit,
            benefit_type:TREAT_BENEFIT_TYPE[treat.benefit_type].no
          });
        }
        return { ...cow, input: { ...cow.input, treats }};
      })
    )
  }

  const applyPreset = () => {
    const treats: Treat[] = currentPresetItems.map(pi => ({
      ...pi,
      valid:undefined,
    }))

    setCows(
      cows.map(cow => {
        if (!cow.selected) return cow;

        return { ...cow, input: { ...cow.input, treats }}
      })
    );
  }

  const onVisitFeeSubmit = (data: ModifyReqVisit) => {
    setVisit(data);
    setIsVisitFeeSelectShown(false);
  }

  const latestPhysicalToInput = (dto: SympDataDto): EditingPhisical => {
    return {
      symptom_name: dto.symptom_name,
      temperature_x10: dto.temperature_x10,
      active_score: dto.active_score,
      hungry_score: dto.hungry_score,
      heart_rate: dto.heart_rate,
      breath_count: dto.breath_count,
      feces_state: dto.feces_state,
      feces_color: dto.feces_color,
      diseases: dto.diseases,
      notice: dto.notice,
      conditions: dto.condition.filter(c => isConditionContained(c, mConditions.data ?? []))
    }
  }

  /**
   * 症状マスタリストの中に一致する項目があるかを検索
   */
  const isConditionContained = (dto: SympConditionDto, list: ITeamCondition[]) => {
    let classItem: IConditionClass | undefined;
    for (const condGrp of list) {
      classItem = condGrp.classes.find(c => c.class_id === dto.class_id);
      if (classItem != null) {
        break;
      }
    }
    if (classItem == null) return false;
    return classItem.details.some(d => d.detail_id === dto.detail_id);
  }

  const latestTreatToInput = (dto: SympTreatDto, options?: { logsForUnknownMedicineTeam?: boolean }): Treat | undefined => {
    if (!CommonUtil.assertNotNull(mPresets.data, "mPresets", "latestTreatToInput")) return undefined;

    let name: string;
    let route_name: string|undefined;
    let unit: string|undefined;

    if (dto.medicine_id != null && dto.amount != null) {
      const medList = dto.team_id === ranchId ? mPresets.data.ranchMedicines : dto.team_id === clinicId ? mPresets.data.clinicMedicines : undefined;
      if (medList == null) {
        if (options?.logsForUnknownMedicineTeam) {
          console.error("treat of invalid medicine team received", dto);
        }
        return undefined;
      }
      const med = medList.find(m => m.medicine_id === dto.medicine_id);
      if (med == null) return undefined;
      name = med.name;
      unit = med.unit;

      //現状は投薬経路も必須
      const route = mPresets.data.medicineRoutes.find(r => r.route_id === dto.route_id);
      if (route == null) return undefined;
      route_name = route.name;
    
    } else if (dto.treat_kind_no != null && dto.treat_item_no != null) {
      const item = mPresets.data.treatItems.find(t => t.team_id === dto.team_id
                                && t.treat_kind_no === dto.treat_kind_no
                                && t.treat_item_no === dto.treat_item_no);
      if (item == null) return undefined;
      name = item.name;
      unit = item.fee_unit ?? undefined;

    } else {
      console.error("invalid treat", dto);
      return undefined;
    }

    return {
      ...dto,
      note: undefined,  //一括治療では入力欄がないのでクリアしておく
      name,
      route_name,
      unit,
    };
  }

  const applyLatest = async () => {
    const cowIds = cows.filter(c => c.selected).map(c => c.id);
    if (cowIds.length === 0) return;

    const req: SymptomLatestReq = {
      ranch_id: ranchId,
      cow_ids: cowIds,
      days: latestDays,
      watched_at: moment(date).format("YYYY-MM-DD HH:mm:00"),
      clinic_id: clinicId,
    };

    handleSetIsLoading(true);
    const res = await comm.send((await SymptomApi()).getLatestUsingPOST(req));
    handleSetIsLoading(false);

    if (res.result !== "OK" || res.data == null) return;
    const list = res.data;

    setCows(
      cows.map(c => {
        if (!c.selected) return c;
        const item = list.find(r => r.cow_id === c.id);
        //前回診療がない牛も入力値はクリアする
        if (item == null) {
          return {
            ...c,
            input: {
              memo: "",
              treats: [],
              schedules: [],
            }
          };
        }
        return {
          ...c,
          input: {
            first_id: asReexamine ? (item.first_id ?? item.symptom_id) : undefined,
            memo: item.comment,
            status: item.status,
            schedules: [],  //クリアする
            treats: ar.notNull(item.treat.map(t => latestTreatToInput(t, { logsForUnknownMedicineTeam: true }))),
            physical: latestPhysicalToInput(item)
          }
        }
      })
    );
  }

  const buildSelectedVisitFeeNames = () => {
    if (clinicId == null) return "";
    if (mVisits.data == null) return "";
    if (visit == null || visit.fees.length === 0) return "選択";

    const feeItems = mVisits.data.fees;

    return visit.fees.map(df => feeItems.find(mf => df.fee_type === mf.fee_type && df.fee_no === mf.fee_no)?.name ?? "")
                .join(",");
  }

  const canEditMaster = hasTeamAuth("MASTER_EDIT", "MASTER_EDIT", clinicId ?? ranchId, user);

  if (mPresets.isLoading || mVisits.isLoading || mConditions.isLoading) return <FetchWaiter />
  if (mPresets.isError || mPresets.data == null
      || mVisits.isError || mVisits.data == null
      || mConditions.isError || mConditions.data == null) {

      return <FetchError />
  }

  const selectedVisitFeeNames = buildSelectedVisitFeeNames();

  return (
    <>
      <section className={ styleCxt('section', 'section-padding') }>
        <div className={ styleCxt('head') }>
          <div className={ styleCxt('head-label') }>診療日時</div>
          { moment(date).format('YYYY/MM/DD HH:mm') }
        </div>
        { clinicId != null && (
          <div className={ styleCxt('head') }>
            <div className={ styleCxt('head-label') }>診療費</div>
            <IconLink text={selectedVisitFeeNames} iconType="popup" onClick={() => setIsVisitFeeSelectShown(true)} />
          </div>
        )}
      </section>

      <section className={ styleCxt('section', 'no-padding') }>
        <Nav tabs className={ styleCxt('nav-container') }>
          <NavItem>
            <NavLink className={classNames(styles["nav-link"], { active: activeTab === TAB_MEDICINE, [styles.active]:activeTab === TAB_MEDICINE })} onClick={() => toggleTab(TAB_MEDICINE)}>投薬</NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classNames(styles["nav-link"], { active: activeTab === TAB_TREAT, [styles.active]:activeTab === TAB_TREAT })} onClick={() => toggleTab(TAB_TREAT)}>処置</NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classNames(styles["nav-link"], { active: activeTab === TAB_HISTORY, [styles.active]:activeTab === TAB_HISTORY })} onClick={() => toggleTab(TAB_HISTORY)}>履歴</NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classNames(styles["nav-link"], { active: activeTab === TAB_PRESET, [styles.active]:activeTab === TAB_PRESET })} onClick={() => toggleTab(TAB_PRESET)}>プリセット</NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className={ styleCxt('tab-content') }>
          <TabPane tabId={TAB_MEDICINE} className={ styleCxt('tab-pane') }>
            <MedicineInput
              ranchMedicines={mPresets.data.ranchMedicines}
              clinicMedicines={mPresets.data.clinicMedicines ?? []}
              medicineCategories={medicineCategories}
              routes={mPresets.data.medicineRoutes}
              input={currentMedicine}
              onChange={m => setCurrentMedicine(m)}
              idPrefix="bulk"
              canEditMaster={canEditMaster}
              ranchId={ranchId}
              clinicId={clinicId}
              hasBenefit={clinicId != null}
            />
            <div className={ styleCxt('bulk-row-menu') }>
              <button className={ baseStyles.bluebutton } disabled={!isValidInput} onClick={() => applyMedicine()}>選択された牛に反映</button>
            </div>
          </TabPane>
          <TabPane tabId={TAB_TREAT} className={ styleCxt('tab-pane') }>
            <TreatInput
              idPrefix="bulk"
              treatKinds={treatKinds}
              treats={mPresets.data.treatItems}
              input={currentTreat}
              onChange={t => setCurrentTreat(t)}
              canEditMaster={canEditMaster}
              hasBenefit={clinicId != null}
            />
            <div className={ styleCxt('bulk-row-menu') }>
              <button className={ baseStyles.bluebutton } disabled={!isValidInput} onClick={() => applyTreat()}>選択された牛に反映</button>
            </div>
          </TabPane>
          <TabPane tabId={TAB_HISTORY} className={ styleCxt('tab-pane') }>
            <div className={ styleCxt('bulk-row') }>
              <span className={ styleCxt('caption') }>直近の診療記録を反映する</span>
              <InfoPopup iconType="info" message={[
                "投薬/処置を含む記録、およびその再診の記録が",
                "対象となります。",
                clinicId == null ? "診療所ユーザによる記録は反映されません。" : "同じ診療所のユーザによる記録のみが反映されます。",
              ]} placement="bottom" />
            </div>
            <div className={ styleCxt('bulk-row') }>
              過去
              <RequiredNumInput max={30} min={0} step={1} value={latestDays} onChange={n => setLatestDays(n)} />
              日以内から検索
            </div>
            <div className={ styleCxt('bulk-row') }>
              <Checkbox
                id="chkAsReexamine"
                label="今回の診療を再診/継続にする"
                checked={asReexamine}
                onChange={e => setAsReExamine(e.target.checked)}
              />
            </div>
            <div className={ styleCxt('bulk-row-menu') }>
              <button className={ baseStyles.bluebutton } disabled={!isValidInput} onClick={() => applyLatest()}>選択された牛に反映</button>
            </div>
          </TabPane>
          <TabPane tabId={TAB_PRESET} className={ styleCxt('tab-pane') }>
            <div className={ styleCxt('preset-content') }>
              <IconLink text="プリセット呼び出し" iconType="popup" onClick={() => setIsPresetSelectShown(true)} />
              { currentPresetItems.map((pi,i) => (
                <TempTreatItemRow key={i} className={styleCxt('bulk-preset-item')}
                  treat={pi}
                  onEdit={() => setEditingPresetItem({ treat:pi, index:i })}
                  onRemove={() => setCurrentPresetItems(pre => pre.filter((_,preIdx) => preIdx !== i))}
                />
              ))}
            </div>
            <div className={ styleCxt('bulk-row-menu') }>
              <button className={ baseStyles.bluebutton } disabled={!isValidInput} onClick={() => applyPreset()}>選択された牛に反映</button>
            </div>
          </TabPane>
        </TabContent>
      </section>

      <div className={ styleCxt('operation-bar') }>
        <div className={ styleCxt('operation-all') }>
          <input id="chkAll" data-testid="牛全選択" type="checkbox" className={ styleCxt('operation-checkbox') } checked={ cows.every(cow => cow.selected) } onChange={ e => toggleAllSelect(e) } />
          <label htmlFor="chkAll">全選択</label>
        </div>
        { cows.some(c => c.attrs.moved || c.attrs.watched || c.attrs.overeat) && (
          <div className={ styleCxt('notice-button-container') }>
            <BulkCowNoticeIcon onClick={ () => setShowNoticeModal(true) } />
          </div>
        )}
        <BulkCowSortIcon order={sortOrder} type={sortType} onChangeOrder={setSortOrder} onChangeType={setSortType} />
      </div>

      <section className={ styleCxt('section') }>
        <div className={ classNames( styles.clear )}>
          <button className="btn btn-red btn-sm" onClick={ onClear }>入力値をクリア</button>
        </div>
      {(() => {
        if(cows.length === 0)
          return <div className={ baseStyles.loader }>Loading...</div>

        switch(sortType + sortOrder) {
          case 'TraceAsc':
            return cows
              .map(cow => <CowRow key={ cow.id } cow={ cow } toggleSelect={ toggleSelect } openEditModal={ openEdit } />)
          case 'TraceDesc':
            return [...cows]
              .reverse()
              .map(cow => <CowRow key={ cow.id } cow={ cow } toggleSelect={ toggleSelect } openEditModal={ openEdit } />)
          case 'BirthAsc':
            return [...cows]
              .sort((a,b) => a.birthday - b.birthday)
              .map(cow => <CowRow key={ cow.id } cow={ cow } toggleSelect={ toggleSelect } openEditModal={ openEdit } />)
          case 'BirthDesc':
            return [...cows]
              .sort((a,b) => b.birthday - a.birthday)
              .map(cow => <CowRow key={ cow.id } cow={ cow } toggleSelect={ toggleSelect } openEditModal={ openEdit } />)
          case 'HouseAsc': {
            const groups: Map<string, Cow[]> = cows.reduce((map, cow) => map.set(cow.house.join('-'), [...map.get(cow.house.join('-')) || [], cow]), new Map());
            return [...groups.entries()]
              .sort(([houseA, cowsA], [houseB, cowsB]) => cowsA[0].house[0] - cowsB[0].house[0] || cowsA[0].house[1] - cowsB[0].house[1] || cowsA[0].house[2] - cowsB[0].house[2])
              .map(([house, cows], i) =>
                <React.Fragment key={ house }>
                  <p className={ styleCxt('notice-group', { first: i === 0 }) }>{ getHouseName(cows[0].house) }</p>
                  { cows.map(cow => <CowRow key={ cow.id } cow={ cow } toggleSelect={ toggleSelect } openEditModal={ openEdit } />) }
                </React.Fragment>
              )
          }
          case 'HouseDesc': {
            const groups: Map<string, Cow[]> = cows.reduce((map, cow) => map.set(cow.house.join('-'), [...map.get(cow.house.join('-')) || [], cow]), new Map());
            return [...groups.entries()]
              .sort(([houseA, cowsA], [houseB, cowsB]) => cowsB[0].house[0] - cowsA[0].house[0] || cowsB[0].house[1] - cowsA[0].house[1] || cowsB[0].house[2] - cowsA[0].house[2])
              .map(([house, cows], i) =>
                <React.Fragment key={ house }>
                  <p className={ styleCxt('notice-group', { first: i === 0 }) }>{ getHouseName(cows[0].house) }</p>
                  { cows.map(cow => <CowRow key={ cow.id } cow={ cow } toggleSelect={ toggleSelect } openEditModal={ openEdit } />) }
                </React.Fragment>
              )
          }
        }

      })()}
      </section>

      { isPresetSelectShown && (
        <TreatPresetSelectPopup
          medicinesOnly={false}
          medicines={mPresets.data.clinicMedicines ?? mPresets.data.ranchMedicines}
          treatItems={mPresets.data.treatItems}
          routes={mPresets.data.medicineRoutes}
          presets={mPresets.data.presets}
          onClose={() => setIsPresetSelectShown(false)}
          onSubmit={onPresetSelected}
        />
      )}
      { editingPresetItem != null && (
        <TreatEditModal
          clinicId={clinicId}
          ranchId={ranchId}
          medicineCategories={medicineCategories}
          ranchMedicines={mPresets.data.ranchMedicines}
          clinicMedicines={mPresets.data.clinicMedicines ?? []}
          routes={mPresets.data.medicineRoutes}
          treatKinds={treatKinds}
          treats={mPresets.data.treatItems}
          onClose={() => setEditingPresetItem(undefined)}
          onSubmit={t => onPresetItemSubmit(t)}
          treat={editingPresetItem.treat}
          canEditMaster={false}
        />
      )}

      { showEditModal && (
        <EditModal
          ranchId={ ranchId }
          clinicId={ clinicId }
          cow={ editCow }
          setWatch={ setWatch }
          setCows={ setCows }
          medicineCategories={medicineCategories}
          treatKinds={treatKinds}
          comm={comm}
          watchedAt={date}
          convertLatestTreatToInput={dto => latestTreatToInput(dto)}
          convertLatestPhysicalToInput={dto => latestPhysicalToInput(dto)}
          onClose={() => setShowEditModal(false)}
        />
      )}

      { isVisitFeeSelectShown && CommonUtil.assertNotNull(clinicId,"clinicId") && (
        <VisitFeeSelectPopup
          allCows={ allCows }
          feeItemList={ mVisits.data.fees }
          fromList={ mVisits.data.froms }
          onClose={ () => setIsVisitFeeSelectShown(false) }
          showToast={ showToast }
          data={ visit ?? { clinic_id: clinicId, fees:[] } }
          onSubmit={ onVisitFeeSubmit }
        />
      )}

      <NoticeModal
        showModal={ showNoticeModal }
        setShowModal={ setShowNoticeModal }
        moved={ cows.filter(c => c.attrs.moved).length }
        watched={ cows.filter(c => c.attrs.watched).length }
        overeat={ 0 }
      />
    </>
  )
}
export default RegisterTreat