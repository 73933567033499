import React from 'react';
import styles from './cow-header.module.css';
import { CowsPopup, IPopupCow } from 'components/parts/cows-popup';
import { FreezedArray } from 'config/util';
import classnames from 'classnames';

interface Props {
    cowCount: number;
    cows?: FreezedArray<IPopupCow>;
    infoName: string;
}

export const MultiCowHeader = React.memo((props: Props) => {

    return (
        <div className={classnames(styles.container, styles.multi)}>
            <span className={styles.title}>{props.cowCount}頭の{props.infoName}</span>
            { props.cows && (
                <CowsPopup cows={props.cows} placement="bottom" />
            )}
        </div>
    )
});