import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { A } from '../../config/constant';
import styles from './setting.module.css';
import { IMedicine as IMedicineBase, IMedicineCategory } from '../../stores/RootStore';
import { PageSettings } from '../../config/page-settings';
import { MedicineEditPopup } from './medicine-edit-popup';
import { withContext, IMedicineRoute } from '../../stores';
import Big from 'big.js';
import { AppState } from '../../app';
import { TeamMedicineSortReq, TeamMedicineModifyReq, TeamMedicineDeleteReq } from '../../api';
import { CommonUtil, FreezedArray } from '../../config/util';
import { SortableList } from '../../components/parts/sortable-list';
import { UserTeams } from '../../config/user-teams';
import { WithQuery, useMedicineAndRoutes, resetMedicines } from '../../stores/fetcher';
import { FetchWaiter, FetchError } from '../../components/content/fetch-state';

interface MyProps extends BaseProps<{id:string}> {
}

interface MyState {
    teamId: number | null;
    sortingList: IMedicine[];
    isSorting: boolean;
    isEditing: boolean;
    editingData: IEditingMedicine | null;
    targetCategory: number;
    executing: boolean;
}

const renderWashoutStr = (washout_meat_day?, washout_milk_hour?) => {
    if( washout_meat_day && washout_milk_hour) {
        return <> 出荷期限 肉{washout_meat_day}日 乳{washout_milk_hour}時間</>
    }
    if( washout_meat_day) {
        return <> 出荷期限 肉{washout_meat_day}日</>
    }
    if( washout_milk_hour) {
        return <> 出荷期限 乳{washout_milk_hour}時間</>
    }
    return <></>
}

type IMedicine = IMedicineBase & {
    readonly medicineCategoryName: string;
    readonly use: string;
}
export type IEditingMedicine = {
    readonly isNew: boolean;
    readonly team_id: number;
    readonly medicine_id?: number;
    name: string;
    category: number;
    unit: string;
    unit_price: number;
    is_treatment: number;
    is_prevention: number;
    default_amount: number|undefined;
    default_route_id: number|undefined;
    default_amount_weight: number|undefined;
    unit_amount?: number;
    total_price?: number;
    washout_meat_day?: number;
    washout_milk_hour?: number;
}

class MedicineMaster extends Base<MyProps, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            teamId: null,
            isSorting: false,
            sortingList: [],
            isEditing: false,
            editingData: null,
            targetCategory: 0,
            executing: false
        };

        this.startSorting = this.startSorting.bind(this);
        this.finishSorting = this.finishSorting.bind(this);
        this.cancelSorting = this.cancelSorting.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onCancelEdit = this.onCancelEdit.bind(this);
        this.onSubmitEdit = this.onSubmitEdit.bind(this);
        this.startEdit = this.startEdit.bind(this);
        this.onNameEdited = this.onNameEdited.bind(this);
        this.onUnitAmountEdited = this.onUnitAmountEdited.bind(this);
        this.onPriceEdited = this.onPriceEdited.bind(this);
        this.onCategoryEdited = this.onCategoryEdited.bind(this);
        this.onUnitEdited = this.onUnitEdited.bind(this);
        this.onIsTreatmentEdited = this.onIsTreatmentEdited.bind(this);
        this.onIsPreventionEdited = this.onIsPreventionEdited.bind(this);
        this.onDefaultAmountWeightEdited = this.onDefaultAmountWeightEdited.bind(this);
        this.onDefaultAmountEdited = this.onDefaultAmountEdited.bind(this);
        this.onDefaultRouteIdEdited = this.onDefaultRouteIdEdited.bind(this);
        this.onWashoutMeatDayEdited = this.onWashoutMeatDayEdited.bind(this);
        this.onWashoutMilkHourEdited = this.onWashoutMilkHourEdited.bind(this);
        this.addMedicine = this.addMedicine.bind(this);
    }

    componentDidMount() {

        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        this.init();
    }

    componentDidUpdate(prevProps: this['props']) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.init();
        }
    }

    private init() {
        const team_id = Number(this.props.match.params.id);
        if (this.handleNotAllowAccess(team_id, ["MASTER_EDIT"], ["MASTER_EDIT"])) {
            return;
        }
        const isRanch = new UserTeams(this.props.rootStore.user).findRanch(team_id) != null;
        this.context.handleSetHeader({ title:"治療薬・予防薬の設定", iconType:isRanch ? "ranch" : "clinic" });

        this.setState({
            teamId: team_id,
        });
    }

    private startSorting(medicines: FreezedArray<IMedicine>) {

        if(this.state.targetCategory !== 0) {
            return;
        }

        if (this.state.isSorting) {
            console.error("already sorting");
            return;
        }

        this.setState({
            isSorting: true,
            sortingList: [...medicines]
        });
    }
    private cancelSorting() {
        if (!this.state.isSorting) {
            console.error("not sotring on cancel");
            return;
        }

        this.setState({
            isSorting: false,
            sortingList:[]
        });
    }
    private async finishSorting(medicines: FreezedArray<IMedicine>) {
        if (!this.state.isSorting) {
            console.error("not sorting on finish");
            return;
        }
        if (!CommonUtil.assertNotNull(this.state.teamId)) return;

        const newOrder = this.state.sortingList.map(p => p.medicine_id);
        const oldOrder = medicines.map(p => p.medicine_id);
        if (JSON.stringify(newOrder) === JSON.stringify(oldOrder)) {
            this.setState({
                isSorting: false,
                sortingList:[]
            });
            return;
        }

        //ソート要求
        this.setState({ executing:true });
        this.context.handleSetIsLoading(true);
        const req: TeamMedicineSortReq = {
            order: newOrder,
            team_id: this.state.teamId,
            user_id: this.props.rootStore.user.id
        };
        try {
            const res = await this.comm().send(() => this.context.postAsync("/team/medicine/sort", req));
            if (res.result !== "OK") return;
    
            this.setState({
                isSorting: false,
                sortingList:[]
            });
    
            await resetMedicines(this.state.teamId, false);
    
        } finally {
            this.context.handleSetIsLoading(false);
            this.setState({ executing:false });
        }
    }

    private addMedicine() {
        if (!this.assertNotNull(this.state.teamId, "teamId", "addMedicine")) return;
        this.setState({
            isEditing: true,
            editingData: {
                isNew: true,
                team_id: this.state.teamId,
                name: "",
                category: 0,
                unit: "",
                unit_price: 0,
                is_treatment: 1,
                is_prevention: 1,
                default_amount: undefined,
                default_route_id: 0,
                default_amount_weight: undefined,
                unit_amount: 1,
                total_price: 0,
            }
        })
    }

    private startEdit(medicine: IMedicine) {
        this.setState({
            isEditing: true,
            editingData: {
                isNew: false,
                team_id: medicine.team_id,
                medicine_id: medicine.medicine_id,
                name: medicine.name,
                category: medicine.category,
                unit: medicine.unit,
                unit_price: medicine.unit_price,
                is_treatment: medicine.is_treatment,
                is_prevention: medicine.is_prevention,
                default_amount: medicine.default_amount,
                default_route_id: medicine.default_route_id,
                default_amount_weight: medicine.default_amount_weight,
                unit_amount: 1,
                total_price: medicine.unit_price,
                washout_meat_day: medicine.washout_meat_day,
                washout_milk_hour: medicine.washout_milk_hour,
            }
        });
    }

    private onNameEdited(name:string) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onNameEdited")) return;

        const data = { ...this.state.editingData};
        data.name = name;
        this.setState({
            editingData: data
        });
    }
    private onCategoryEdited(categoryCd: number) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onCategoryEdited")) return;

        const data = { ...this.state.editingData};
        data.category = categoryCd;
        this.setState({
            editingData: data,
        });
    }

    private onUnitAmountEdited(unit_amount:number) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onUnitAmountEdited")) return;
        const data = { ...this.state.editingData};
        data.unit_amount = isNaN(unit_amount) ? undefined : unit_amount;
        data.unit_price = this.calcUnitPrice(unit_amount, data.total_price);
        this.setState({
            editingData: data
        });
    }

    private onPriceEdited(price:number) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onPriceEdited")) return;
        const data = { ...this.state.editingData};
        data.total_price = isNaN(price) ? 0 : price;
        data.unit_price = this.calcUnitPrice(data.unit_amount, price);
        this.setState({
            editingData: data
        });
    }
    private onUnitEdited(unit:string) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onUnitEdited")) return;

        const data = { ...this.state.editingData};
        data.unit = unit;
        this.setState({
            editingData: data
        });
    }
    private onIsTreatmentEdited(is_treatment:number) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onIsTreatmentEdited")) return;

        const data = { ...this.state.editingData};
        data.is_treatment = is_treatment;
        this.setState({
            editingData: data
        });
    }
    private onIsPreventionEdited(is_prevention:number) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onIsPreventionEdited")) return;

        const data = { ...this.state.editingData};
        data.is_prevention = is_prevention;
        this.setState({
            editingData: data
        });
    }

    private onDefaultAmountWeightEdited(default_amount_weight:number|null) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onDefaultAmountWeightEdited")) return;

        const data = { ...this.state.editingData};
        data.default_amount_weight = default_amount_weight == null ? undefined : Number(default_amount_weight);
        this.setState({
            editingData: data
        });

    }

    private onDefaultAmountEdited(amount:number|undefined) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onDefaultAmountEdited")) return;
        const data = { ...this.state.editingData};
        data.default_amount = amount;
        this.setState({
            editingData: data
        });

    }

    private onDefaultRouteIdEdited(default_route_id:number) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onDefaultRouteIdEdited")) return;

        const data = { ...this.state.editingData};
        data.default_route_id = default_route_id;
        this.setState({
            editingData: data
        });

    }

    private onWashoutMeatDayEdited(washout_meat_day?:number) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onWashoutMeatDayEdited")) return;

        const data = { ...this.state.editingData};
        data.washout_meat_day = washout_meat_day;
        this.setState({
            editingData: data
        });

    }
    
    private onWashoutMilkHourEdited(washout_milk_hour?:number) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onWashoutMilkHourEdited")) return;

        const data = { ...this.state.editingData};
        data.washout_milk_hour = washout_milk_hour;
        this.setState({
            editingData: data
        });

    }

    private assertNotNull<T>(v: T | null | undefined, name?: string, when?: string): v is T {
        if (v == null) {
            console.error(`${name ?? "value"} is null or undef when ${when ?? "assertion"} is called.`);
            return false;
        }
        return true;
    }

    private calcUnitPrice(unit_amount: number | undefined, total_price: number | undefined ) : number {
        if( unit_amount === undefined || unit_amount === 0 || total_price === undefined
            || isNaN(unit_amount) || isNaN(total_price)) { 
            return 0; 
        }
        const b_unit_amount = new Big(unit_amount ?? 1);
        const b_total_price = new Big(total_price ?? 0);
        Big.DP = 3; // 小数第3位まで
        Big.RM = 1; // 四捨五入
        return Number(b_total_price.div(b_unit_amount))
    } 

    private async onSubmitEdit() {
        if (!CommonUtil.assertNotNull(this.state.teamId, "teamId")) return;
        const data = this.state.editingData;
        if (!CommonUtil.assertNotNull(data, "editingData", "submitEdit")) return;

        if (data.name === "") {
            this.context.showToast(A.MESSAGE.NO_MEDICINE_NAME_INPUT);
            return;
        }
        if ((data.category ?? 0) === 0) {
            this.context.showToast(A.MESSAGE.NO_MEDICINE_CATEGORY_SELECTED);
            return;
        }
        if (data.unit === "") {
            this.context.showToast(A.MESSAGE.NO_MEDICINE_UNIT_INPUT);
            return;
        }

        try {
            this.setState({ executing: true });

            //更新要求
            this.context.handleSetIsLoading(true);
            const req: TeamMedicineModifyReq = {
                team_id: data.team_id,
                medicine_id: data.medicine_id ?? 0,
                name: data.name,
                category: data.category,
                unit: data.unit,
                unit_price: data.unit_price,
                is_treatment: data.is_treatment,
                is_prevention: data.is_prevention,
                user_id: this.props.rootStore.user.id,
                is_new: data.medicine_id == null ? 1 : 0,
                default_amount: data.default_amount,
                default_route_id: data.default_route_id === 0 ? undefined : data.default_route_id,
                default_amount_weight: data.default_amount_weight,
                washout_meat_day: data.washout_meat_day,
                washout_milk_hour: data.washout_milk_hour,
            };
            const res = await this.comm().send(() => this.context.postAsync("/team/medicine/modify", req));
            if (res.result !== "OK") return;

            this.setState({
                isEditing: false,
                editingData: null
            });

            await resetMedicines(this.state.teamId, false);

        } finally {
            this.context.handleSetIsLoading(false);
            this.setState({ executing: false });
        }

    }

    private async onDelete() {
        const res = await this.context.showDialog("QUESTION", "薬品を削除してよろしいですか？", [{ type:"delete" },{ type:"cancel" }]);
        if (res === 0) {
            return this._execDelete();
        }
    }
    //削除実行
    private async _execDelete() {
        const data = this.state.editingData;
        if (!CommonUtil.assertNotNull(data, "editingData", "delete")) return;
        if (!CommonUtil.assertNotNull(data.medicine_id, "medicine_id", "delete")) return;
        if (!CommonUtil.assertNotNull(this.state.teamId, "teamId", "delete")) return;

        this.setState({ executing:true });
        this.context.handleSetIsLoading(true);

        const req: TeamMedicineDeleteReq = {
            medicine_id: data.medicine_id,
            team_id: data.team_id,
            user_id: this.props.rootStore.user.id
        };
        try {
            const res = await this.comm().send(() => this.context.postAsync("/team/medicine/delete", req), { excludedErrCodes:[ A.ERR_CODE.ERR_USING_DATA ] });
            if (res.result !== "OK") return;
    
            if (res.code === A.ERR_CODE.ERR_USING_DATA) {
                this.context.showDialog("WARNING", A.MESSAGE.MEDICINE_USED_IN_PRESET);
                return;
            }
    
            this.setState({
                isEditing: false,
                editingData: null
            });
    
            await resetMedicines(this.state.teamId, false);

        } finally {
            this.context.handleSetIsLoading(false);
            this.setState({ executing:false });
        }
    }

    private onCancelEdit() {
        this.setState({
            editingData: null,
            isEditing:false
        })
    }


    targetCategoryChange = (e) => {
        this.setState({targetCategory: Number(e.target.value)})
    }

    render() {
        const teamId = this.state.teamId;
        if (teamId == null) return <></>

        const categories = this.props.rootStore.options.medicine_category;

        return (
            <WithQuery query={() => useMedicineAndRoutes(teamId)}>
                {({ data, isError, isLoading }) => {
                    if (isLoading) return <FetchWaiter />
                    if (isError || data == null) return <FetchError />

                    const medicines = data.medicines.map(m => {
                        return { item:m, cg: categories.find(c => c.category === m.category) }
                        })
                        .map(a => {
                            return {
                                ...a.item,
                                medicineCategoryName: a.cg?.name ?? "",
                                unit: a.item.unit ?? "",
                                use: a.item.is_treatment === 1 && a.item.is_prevention === 1 ? "治療・予防" :
                                    a.item.is_treatment === 1 ? "治療" : a.item.is_prevention === 1 ? "予防" : "",
                            }
                        });

                    return this.renderPage(categories, medicines, data.medicineRoutes)
                }}
            </WithQuery>
        )

    }

    renderPage(categories: FreezedArray<IMedicineCategory>, medicines: FreezedArray<IMedicine>, medicineRoutes: FreezedArray<IMedicineRoute>) {
        if (this.state.teamId == null) return <></>

        const isClinic = new UserTeams(this.props.rootStore.user).findClinic(this.state.teamId) != null;

        return (
            <div className="page-root">
                <div className="product product-full-height">
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix">
                            <div className={"product-info-header " + styles.header} style={{ "height" : "45px" }}>
                                <div className={styles["sortable-list-shoulder"]}>
                                    {!this.state.isSorting && (<span className={this.state.targetCategory === 0 ? "normal link" : styles["text-disabled"]} 
                                                                    style={{"marginTop" : ".5rem", "marginLeft" : "15px", "whiteSpace": "nowrap"}}
                                                                    onClick={() => this.startSorting(medicines)}>並び替え</span>)}
                                    {!this.state.isSorting && (
                                        <select className="form-control" style={{"maxWidth" : "400px"}} 
                                                value={this.state.targetCategory} onChange={(e) => this.targetCategoryChange(e)}>
                                            <option key={0} value={0}>分類で絞り込み</option>
                                            {
                                                categories.map((cg) => (
                                                    <option key={cg.category} value={cg.category}>{cg.name}</option>
                                                ))
                                            }
                                        </select>
                                    )}
                                    {this.state.isSorting && (<span className="mt-2 link" onClick={() => this.finishSorting(medicines)}>完了</span>)}
                                    {this.state.isSorting && (<span className="mt-2 link" onClick={this.cancelSorting}>キャンセル</span>)}
                                </div>
                            </div>

                            <div className="product-body">
                                <SortableList
                                    className={styles.list + " m-b-0"}
                                    items={this.state.isSorting
                                        ? this.state.sortingList
                                        : medicines.filter(m => this.state.targetCategory === 0 || m.category === this.state.targetCategory)
                                    }
                                    isSorting={this.state.isSorting}
                                    onSorted={l => this.setState({ sortingList: l })}
                                    listItem={medicine => (
                                        <li className={styles["list-item"]}>
                                            <div className={styles["list-item-content"]}>
                                                <div className={styles["list-item-name"]}>{medicine.name}</div>
                                                <div className={styles["list-item-detail"]}>
                                                    {medicine.medicineCategoryName} 1{medicine.unit}あたり {medicine.unit_price}円 {medicine.use}
                                                    {renderWashoutStr(medicine.washout_meat_day, medicine.washout_milk_hour)}
                                                </div>
                                            </div>
                                            { this.state.isSorting ? (
                                                <div className={styles["list-item-icon"]}><i className="fas fa-bars"></i></div>
                                            ) : (
                                                <div onClick={()=>this.startEdit(medicine)} className={styles["list-item-icon"]}><i className="fas fa-pen clickable"></i></div>
                                            )}
                                        </li>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{height:"18px", marginTop:"6px"}} onClick={this.addMedicine}>
                    {!this.state.isSorting && (
                        <span className="link">
                            <i className="fa fa-plus"></i>
                            <span> 薬品を追加</span>
                        </span>
                    )}
                </div>
                {
                    this.state.isEditing && (
                        <MedicineEditPopup 
                            data={this.state.editingData}
                            medicineCategoryList={categories}
                            medicineRouteList={medicineRoutes}
                            onNameEdited={this.onNameEdited}
                            onCategoryEdited={this.onCategoryEdited}
                            onUnitAmountEdited={this.onUnitAmountEdited}
                            onUnitEdited={this.onUnitEdited}
                            onPriceEdited={this.onPriceEdited}
                            onIsTreatmentEdited={this.onIsTreatmentEdited}
                            onIsPreventionEdited={this.onIsPreventionEdited}
                            onDefaultAmountWeightEdited={this.onDefaultAmountWeightEdited}
                            onDefaultAmountEdited={this.onDefaultAmountEdited}
                            onDefaultRouteIdEdited={this.onDefaultRouteIdEdited}
                            onWashoutMeatDayEdited={this.onWashoutMeatDayEdited}
                            onWashoutMilkHourEdited={this.onWashoutMilkHourEdited}
                            onClose={this.onCancelEdit}
                            onDelete={this.onDelete}
                            onSubmit={this.onSubmitEdit}
                            teamId={this.state.teamId}
                            teamType={isClinic ? 'clinic' : 'ranch'}
                            user={this.props.rootStore.user}
                            isSubmitExecuting={this.state.executing}
                        />
                    )
                }
            </div>
        )
    }
}

export default withRouter(withContext(MedicineMaster));