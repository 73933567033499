import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { PageSettings } from '../../config/page-settings';
import { withContext, ICowUse } from '../../stores';
import { AppState } from '../../app';
import { DYNAMICS_SETTING_ITEM_KIND, DYNAMICS_SETTING_ITEM_KIND_KEYS, DynamicsSettingItemKindValue } from '../../config/constant';
import { CommonUtil, ar, FreezedArray } from '../../config/util';
import moment from 'moment';
import { YearSelector } from '../../components/parts/year-selector';
import { DynamicsTable } from './dynamics-table';
import { DynamicsApi, DynamicsExpectValueDto, DynamicsExpectModifyReq, DynamicsExpectModifyReqItem, DynamicsExpectListReq } from '../../api';
import { DynamicsBulkModal } from './dynamics-bulk-modal';

type YearData = number[];
export type DynamicsSettingItemData = Map<number, YearData>;

const convertDtoToYearData = (year: number, values: DynamicsExpectValueDto[]) => {
    return ar.intRange(1, 12)
        .map(m => values.find(v => v.month_yyyymm === year * 100 + m)?.expected);
}

interface MyState {
    ranch_id?: number;
    year: number;
    useList: FreezedArray<ICowUse>;
    itemKind: DynamicsSettingItemKindValue;
    values: DynamicsSettingItemData;
    isChanged: boolean;
    original: DynamicsSettingItemData;
    isBulkModalShown: boolean;
}

class Dynamics extends Base<BaseProps<{id?:string},{},{}>, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            year: moment().year(),
            useList: [],
            values: new Map<number,YearData>(),
            isChanged: false,
            original: new Map<number,YearData>(),
            itemKind: DYNAMICS_SETTING_ITEM_KIND.ACCIDENT_PERCENT,
            isBulkModalShown: false,
        };
    }

    componentDidMount() {

        this.context.handleSetHeader({ title:"動態目標の設定" });
        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        this.init();
    }

    componentDidUpdate(prevProps: this['props']) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.init();
        }
    }

    anyChanged() {
        for (const use of this.state.useList) {
            const input = this.state.values.get(use.use_no) ?? [];
            const ori = this.state.original.get(use.use_no) ?? [];
            if (input.length !== ori.length || JSON.stringify(input) !== JSON.stringify(ori)) return true;
        }
        return false;
    }
    anyNonZero() {
        return [...this.state.values.values()].some(vals => vals.some(n => n !== 0));
    }

    async init() {
        const ranchIdStr = this.props.match.params.id;
        const ranchId = Number(ranchIdStr);
        if (ranchIdStr == null || isNaN(ranchId)) {
            this.setState({ ranch_id: undefined });
            return;
        }

        if (this.handleNotAllowAccess(ranchId, ["MASTER_EDIT"], [])) {
            this.setState({ ranch_id: undefined });
            return;
        }

        const useList = this.props.rootStore.getCowUses(ranchId);
        if (useList == null || useList.length === 0) return;

        await this.setStateAsync({
            ranch_id: ranchId,
            useList
        });
        this.context.handleSetIsLoading(true);
        await this.reload();
        this.context.handleSetIsLoading(false);
    }

    async reload() {
        if (!CommonUtil.assertNotNull(this.state.ranch_id, "ranch_id")) {
            return;
        }

        const req = {
            ranch_id: this.state.ranch_id,
            item_kind: this.state.itemKind.no,
            from: this.state.year + "-01-01",
            to: this.state.year + "-12-31",
        };
        const res = await this.comm().send((await DynamicsApi()).getExpectsUsingPOST(req), { retries: true });
        if (res.result !== "OK") return;
        const loaded = res.data ?? [];

        const original = new Map<number, YearData>();
        const values = new Map<number, YearData>();
        this.state.useList
            .forEach(u=> {
                const vals = loaded.find(l => l.use_no === u.use_no)?.values ?? [];
                const yData = convertDtoToYearData(this.state.year, vals);
                const ediData = yData.map(n => n ?? 0);
                //データに欠損がないときのみ、originalとして保持（変更有無チェックに使用）
                if (yData.every(n => n != null)) {
                    original.set(u.use_no, ediData);
                }
                values.set(u.use_no, ediData);
            });
        await this.setStateAsync({
            original,
            values
        });
    }

    onItemKindChange(_: number) {
        //※2項目以上になった際には、リロード処理が必要
    }
    async onYearChange(year: number) {
        if (this.anyChanged() && this.anyNonZero()) {
            const confirmed = await this.context.showQuestionAsync("保存されていない変更が破棄されます。続行しますか？", [ "続行", "キャンセル" ]) === 0;
            if (!confirmed) return;
        }

        await this.setStateAsync({ year });
        this.context.handleSetIsLoading(true);
        await this.reload();
        this.context.handleSetIsLoading(false);
    }

    async copyLastYear() {
        if (!CommonUtil.assertNotNull(this.state.ranch_id, "ranch_id")) {
            return;
        }

        const lastYear = this.state.year - 1;

        const req: DynamicsExpectListReq = {
            ranch_id: this.state.ranch_id,
            item_kind: this.state.itemKind.no,
            from: `${lastYear}-01-01`,
            to: `${lastYear}-12-31`,
        };
        this.context.handleSetIsLoading(true);
        const res = await this.comm().send((await DynamicsApi()).getExpectsUsingPOST(req));
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") return;

        const loaded = res.data ?? [];
        if (loaded.length === 0) {
            this.context.showToast("前年の設定がありません");
            return;
        }

        const values = new Map<number, YearData>();
        this.state.useList
            .forEach(u=> {
                const vals = loaded.find(l => l.use_no === u.use_no)?.values ?? [];
                const yData = convertDtoToYearData(lastYear, vals);
                values.set(u.use_no, yData.map(n => n ?? 0));
            });
        this.setState({ values });
    }
    onBulkInputSubmit(data: Map<number, number>) {
        const newValues = new Map(this.state.values);
        for (const [useNo, val] of data.entries()) {
            newValues.set(useNo, ar.repeat(val, 12));
        }

        this.setState({
            values: newValues,
            isBulkModalShown: false
        })
    }


    async onSubmit() {
        if (!CommonUtil.assertNotNull(this.state.ranch_id, "ranch_id", "submit")) return;

        const item_kind = this.state.itemKind.no;
        const year = this.state.year;

        const items: DynamicsExpectModifyReqItem[] = [];
        this.state.useList.forEach(u => {
            const vals = this.state.values.get(u.use_no);
            if (vals == null || vals.length === 0) {
                console.error("input data not found for use_no " + u.use_no);
                return;
            }
            items.push({
                item_kind,
                use_no:u.use_no,
                values: vals.map((v,i) => ({ month_yyyymm: year * 100 + (i + 1), value: v }))  })
        });

        const req: DynamicsExpectModifyReq = {
            ranch_id: this.state.ranch_id,
            items:items
        };

        this.context.handleSetIsLoading(true);
        const res = await this.comm().send((await DynamicsApi()).modifyExpectUsingPOST(req));
        this.context.handleSetIsLoading(false);

        if (res.result === "OK") {
            this.context.showToast("設定が保存されました");
            this.setState({
                original: this.state.values
            })
        }
    }

    render() {
        if (this.state.ranch_id == null) {
            return <></>
        }

        const thisYear = moment().year();

        return (
            <div className="page-root">
                <div className="product product-with-footer">
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix">
                            <div className="product-info-header">
                                <YearSelector year={this.state.year} onChange={y => this.onYearChange(y)}
                                    min={thisYear - 10}
                                    max={thisYear + 10}
                                />
                                <div style={{ marginTop: "10px", maxWidth:"200px" }}>
                                    <select className="form-control" value={this.state.itemKind.no}
                                        onChange={e => this.onItemKindChange(Number(e.target.value))}>
                                        { DYNAMICS_SETTING_ITEM_KIND_KEYS.map(k => DYNAMICS_SETTING_ITEM_KIND[k])
                                            .map(item => (
                                                <option key={item.no} value={item.no}>{item.name}{item.unit != null ? ` (${item.unit})` : ""}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div style={{ overflowY: "auto", margin:"0 auto", maxWidth:"100%" }}>
                                <div style={{ display:"flex", flexFlow:"column nowrap" }}>
                                    <div style={{ display:"flex", justifyContent:"flex-end", marginBottom:"10px" }}>
                                        <span className="link" onClick={ ()=> this.copyLastYear() }>前年の値をコピー</span>
                                        <span className="link m-l-20" onClick={() => this.setState({ isBulkModalShown: true })}>まとめて入力</span>
                                    </div>
                                    <div style={{ overflowX:"auto", maxWidth: "100%", paddingBottom:"4px" }}>
                                        <DynamicsTable
                                            useList={this.state.useList}
                                            data={this.state.values}
                                            onValueChange={d => this.setState({ values: d })}
                                            step={this.state.itemKind.step}
                                            min={this.state.itemKind.min}
                                            max={this.state.itemKind.max}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="content-footer page-footer">
                        <div className="btn-row">
                            <button className="btn btn-success" onClick={() => this.onSubmit()}
                                disabled={!this.anyChanged()}>保存</button>
                        </div>
                    </div>
                </div>
                { this.state.isBulkModalShown && (
                    <DynamicsBulkModal
                        onClose={() => this.setState({ isBulkModalShown: false })}
                        useList={this.state.useList}
                        min={this.state.itemKind.min}
                        max={this.state.itemKind.max}
                        step={this.state.itemKind.step}
                        onSubmit={d => this.onBulkInputSubmit(d)}
                    />
                )}
            </div>
        )
    }
}

export default withRouter(withContext(Dynamics));