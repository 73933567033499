import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { A, TAG_ACTION } from '../../config/constant';
import styles from './setting.module.css';
import { PageSettings } from '../../config/page-settings';
import { withContext, IMedicine, ITreatItem, ICowBreed, ICowUse, IMedicineRoute } from '../../stores';
import { AppState } from '../../app';
import { CommonUtil, FreezedArray, ar } from '../../config/util';
import { PROGRAM_TRIGGER, ProgramKind, ProgramTriggerKey } from '../../config/program-kind';
import { ProgramManagementEditPopup } from './program-management-edit-popup';
import { ProgramApi, ProgramDto, ProgramItemDto, ProgramModifyReq, RanchTagDto, TeamTreatPresetDto, ProgramListReq, ProgramDeleteReq } from '../../api';
import { ProgramTriggerCondition, listConditionNames, checkInvalidCondition } from '../../config/program-trigger-condition';
import { EditingTag } from '../../components/tag-edit-popup/tag-edit-popup';
import { UserTeams } from '../../config/user-teams';
import { DIALOG_BUTTONS } from '../../components/form/form-dialog';
import { getPresets } from '../../stores/fetcher';
import { FetchWaiter, FetchError } from '../../components/content/fetch-state';

interface MyProps extends BaseProps<{id:string}> {
}

interface MyState {
    initStatus: "ready"|"loading"|"error";
    clinicId?: number;
    ranchId?: number;
    programList: ITeamProgram[];
    isEditing: boolean;
    editingData?: IEditingProgram;
    isProgramEditPopupShown: boolean;
    executing: boolean;
    medicines?: FreezedArray<IMedicine>;
    treatItems?: FreezedArray<ITreatItem>;
    medicineRoutes?: FreezedArray<IMedicineRoute>;
    presets?: FreezedArray<TeamTreatPresetDto>;
    cowBreeds: FreezedArray<ICowBreed>;
    cowUses: FreezedArray<ICowUse>;
    allTags?: FreezedArray<RanchTagDto>;
}

export type ITeamProgram = ProgramDto;

export type ITeamProgramItem = ProgramItemDto;

export type IEditingProgram = Omit<ITeamProgram, "trigger_condition" | "tags" | "is_auto_applied"> & {
    readonly isNew: boolean;
    triggerEventKey?: ProgramTriggerKey;
    triggerCondition: ProgramTriggerCondition | undefined;
    tags: Array<EditingTag & { action_kind:number }>;
    is_auto_applied: 1 | 0;
}

export type IEditingProgramItem = ITeamProgramItem & {
    readonly isNew: boolean;
}

class ProgramManagement extends Base<MyProps, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            initStatus:"loading",
            programList: [],
            isEditing: false,
            isProgramEditPopupShown: false,
            executing: false,
            cowBreeds:[],
            cowUses:[]
        };

        this.onAddProgram = this.onAddProgram.bind(this);
        this.onStartEdit = this.onStartEdit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onCancelEdit = this.onCancelEdit.bind(this);
        this.onSubmitEdit = this.onSubmitEdit.bind(this);
        this.onNameEdited = this.onNameEdited.bind(this);
        this.onTriggerKindEdited = this.onTriggerKindEdited.bind(this);
        this.onTriggerEventSelected = this.onTriggerEventSelected.bind(this);
        this.onBaseDateKindSelected = this.onBaseDateKindSelected.bind(this);
        this.onIsAutoAppliedChecked = this.onIsAutoAppliedChecked.bind(this);
        this.onTriggetConditionEdited = this.onTriggetConditionEdited.bind(this);
        this.onCommitItem = this.onCommitItem.bind(this);
        this.onDeleteItem = this.onDeleteItem.bind(this);
    }

    componentDidMount() {

        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        this.setState({
            cowBreeds: this.props.rootStore.options.cow_breed
        });

        this.init();
    }

    componentDidUpdate(prevProps: this['props'], prevState: MyState) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.init();
        }
    }

    private init() {
        const teamId = Number(this.props.match.params.id);
        const isClinic = new UserTeams(this.props.rootStore.user).findClinic(teamId) != null;
        if (this.handleNotAllowAccess(teamId, isClinic ? [] : ["MASTER_EDIT"], isClinic ? ["MASTER_EDIT"] : [])) {
            return;
        }
        this.context.handleSetHeader({ title:"プログラムの管理", iconType:isClinic ? "clinic" : "ranch" });

        this.setState({
            ranchId: isClinic ? undefined : teamId,
            clinicId: isClinic ? teamId : undefined,
            cowUses: isClinic ? this.props.rootStore.options.cow_use : this.props.rootStore.getCowUses(teamId)
        }, async () => {

            const masters = await getPresets(teamId);
            if (masters == null) {
                this.setState({ initStatus:"error" });
                return;
            }
            await this.setStateAsync({
                medicines: masters.medicines,
                medicineRoutes: masters.medicineRoutes,
                treatItems: masters.treatItems,
                presets: masters.presets,
                initStatus: "ready"
            });

            this.loadProgramList(false);
        });
    }

    private async loadProgramList(forceReloadTag: boolean) {

        this.context.handleSetIsLoading(true);

        //牧場のみ
        if (this.state.ranchId != null && this.state.allTags == null || forceReloadTag) {
            await this.props.rootStore.fetchCowTags(this.state.ranchId, true);
            await this.setStateAsync({
                allTags: this.props.rootStore.getCowTags(this.state.ranchId)
            })
        }

        const req:ProgramListReq = {
            ranch_id: this.state.ranchId,
            clinic_id: this.state.clinicId,
        };

        const res = await this.comm().send((await ProgramApi()).getProgramListUsingPOST(req));
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") return;

        this.setStateAsync({
            programList: res.data ?? [],
        });
    }

    private async onAddProgram() {
        const isRanch = this.state.ranchId != null;
        const triggerKind = ProgramKind.allTriggerKinds.find(p => isRanch ? p.defaultInRanch : p.defaultInClinic);
        if (!CommonUtil.assertNotNull(triggerKind, "default trigger kind")) return;

        this.setState({
            isEditing: true,
            isProgramEditPopupShown: true,
            editingData: {
                isNew: true,
                program_id: 0,
                ranch_id: this.state.ranchId,
                clinic_id: this.state.clinicId,
                name: "",
                trigger_kind: triggerKind.no,
                triggerCondition: undefined,
                is_auto_applied: triggerKind.autoApply?.default ?? 0,
                items: [],
                cow_ids: [],
                tags:[],
            }
        })
    }

    private async onStartEdit(prog: ITeamProgram) {
        const triggerKind = ProgramKind.findTriggerKind(prog.trigger_kind);
        if (!CommonUtil.assertNotNull(triggerKind, "triggerKind of " + prog.trigger_kind)) return;

        this.setState({
            isEditing: true,
            isProgramEditPopupShown: true,
            editingData: {
                isNew: false,
                program_id: prog.program_id,
                ranch_id: prog.ranch_id,
                name: prog.name,
                trigger_kind: prog.trigger_kind,
                trigger_event: prog.trigger_event,
                triggerCondition: prog.trigger_condition == null ? undefined : JSON.parse(prog.trigger_condition),  //as ProgramTriggerCondition
                base_date_kind: prog.base_date_kind,
                is_auto_applied: prog.is_auto_applied === 1 ? 1 : 0,
                items: prog.items,
                cow_ids: prog.cow_ids,
                triggerEventKey: triggerKind.hasTriggerEvent ? ProgramKind.getTriggerKey(prog.trigger_event!) : undefined,
                tags: prog.tags
                        .map(t => ({ ...t, tag_name: this.state.allTags?.find(at => t.tag_id === at.tag_id)?.tag_name ?? "" }))
                        .filter(t => t.tag_name !== "")
            }
        });
    }

    private async onSubmitEdit() {
        const data = this.state.editingData;
        if (!CommonUtil.assertNotNull(data, "editingData", "submitEdit")) return;

        if (data.name === "") {
            this.context.showToast(A.MESSAGE.NO_PROGRAM_NAME_INPUT);
            return;
        }

        const triggerKind = ProgramKind.findTriggerKind(data.trigger_kind);
        if (!CommonUtil.assertNotNull(triggerKind, "trigger kind of " + data.trigger_kind, "submitEdit")) return;

        if (triggerKind.hasTriggerEvent) {
            const triggerKey = ProgramKind.getTriggerKey(data.trigger_event??0)
            if  (data.triggerEventKey == null || data.triggerEventKey !== triggerKey) {
                this.context.showToast(A.MESSAGE.NO_PROGRAM_TRIGGER_EVENT_SELECT);
                return;
            }
            if (data.items.length > 0 && !data.base_date_kind) {
                this.context.showToast(A.MESSAGE.NO_PROGRAM_BASE_DATE_KIND_SELECT);
                return;
            }
            if (data.triggerCondition != null) {
                const invalidCondMsg = checkInvalidCondition(data.triggerCondition, this.state.cowBreeds, this.state.cowUses);
                if (invalidCondMsg != null) {
                    this.context.showToast(invalidCondMsg);
                    return;
                }
            }
        }

        if (data.items.length === 0 && data.tags.length === 0) {
            this.context.showToast(A.MESSAGE.NO_PROGRAM_ITEMS_ADD);
            return;
        }
        if (data.tags.length > 0) {
            //つけるとはずすに同じ項目が選択されていないか
            const ids = ar.notNull(data.tags.map(t => t.tag_id));
            const names = ar.notNull(data.tags.filter(t => t.tag_id == null));
            if (ar.distinct(ids).length !== ids.length || ar.distinct(names).length !== names.length) {
                this.context.showToast(A.MESSAGE.PROGRAM_TAG_DUPLICATED);
                return;
            }

        }

        try {
            this.setState({ executing: true });

            const req:ProgramModifyReq = {
                is_new: data.isNew ? 1 : 0,
                program_id: data.program_id,
                ranch_id: this.state.ranchId,
                clinic_id: this.state.clinicId,
                name: data.name,
                trigger_kind: data.trigger_kind,
                trigger_event: triggerKind.hasTriggerEvent ? data.trigger_event : undefined,
                trigger_condition: data.triggerCondition == null ? undefined : JSON.stringify(data.triggerCondition),
                base_date_kind: triggerKind.hasTriggerEvent ? data.base_date_kind : undefined,
                is_auto_applied: triggerKind.hasTriggerEvent ? data.is_auto_applied : undefined,
                items: data.items,
                tags: data.tags
            }

            // 更新要求
            this.context.handleSetIsLoading(true);
            const res = await this.comm().send((await ProgramApi()).modifyUsingPOST3(req), { excludedErrCodes:[A.ERR_CODE.INVALID_PARAM] });
            this.context.handleSetIsLoading(false);
            if (res.result !== "OK") return;
            if (res.code === A.ERR_CODE.INVALID_PARAM) {
                this.context.showToast(A.MESSAGE.PROGRAM_ITEM_CIRCULAR)
                return;
            }

            await this.setStateAsync({
                isProgramEditPopupShown: false,
                isEditing: false,
                editingData: undefined,
            });

            this.loadProgramList(true);

        } finally {
            this.setState({ executing: false });
        }

    }

    private async onDelete() {
        const res = await this.context.showDialog("QUESTION", "牛に適用中のプログラムは解除されます。\n（生成済みの予定は削除されません）\nプログラムを削除しますか？", DIALOG_BUTTONS.DELETE_CANCEL);
        if (res === 0) {
            return this._execDelete();
        }
    }
    //削除実行
    private async _execDelete() {
        const data = this.state.editingData;
        if (!CommonUtil.assertNotNull(data, "editingData", "delete")) return;

        const req:ProgramDeleteReq = {
            id: data.program_id,
            ranch_id: this.state.ranchId,
            clinic_id: this.state.clinicId
        };

        // 削除要求
        this.context.handleSetIsLoading(true);
        const res = await this.comm().send((await ProgramApi()).deleteUsingPOST3(req));
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") return;

        this.setState({
            isProgramEditPopupShown: false,
            isEditing: false,
            editingData: undefined,
        }, () => this.loadProgramList(false));
    }

    private onCancelEdit() {
        this.setState({
            editingData: undefined,
            isEditing: false,
        })
    }

    private onNameEdited(name: string) {
        if (!CommonUtil.assertNotNull(this.state.editingData, "editingData", "onNameEdited")) return;
        const data = {...this.state.editingData};
        data.name = name;
        this.setState({
            editingData:data
        });
    }

    private onTriggerKindEdited(trigger_kind: number) {
        if (!CommonUtil.assertNotNull(this.state.editingData, "editingData", "onTriggerKindEdited")) return;
        const data = {...this.state.editingData};
        data.trigger_kind = trigger_kind;
        data.trigger_event = data.trigger_event ?? 0;
        data.base_date_kind = data.base_date_kind ?? 0;
        data.is_auto_applied = data.is_auto_applied ?? ProgramKind.findTriggerKind(data.trigger_kind)?.autoApply?.default ?? 0;
        data.triggerCondition = undefined;
        this.setState({
            editingData:data
        });
    }
    private onTriggerEventSelected(trigger_event?: ProgramTriggerKey) {
        if (!CommonUtil.assertNotNull(this.state.editingData, "editingData", "onTriggerEventSelected")) return;
        const data = {...this.state.editingData};
        data.triggerEventKey = trigger_event;
        data.trigger_event = trigger_event ? PROGRAM_TRIGGER[trigger_event].no : undefined;
        data.base_date_kind = 0;
        data.triggerCondition = undefined;
        this.setState({
            editingData:data
        });
    }
    private onBaseDateKindSelected(base_date_kind?: number) {
        if (!CommonUtil.assertNotNull(this.state.editingData, "editingData", "onBaseDateKindSelected")) return;
        const data = {...this.state.editingData};
        data.base_date_kind = base_date_kind;
        this.setState({
            editingData:data
        });
    }
    private onIsAutoAppliedChecked(is_auto_applied: 1 | 0) {
        if (!CommonUtil.assertNotNull(this.state.editingData, "editingData", "onIsAutoAppliedChecked")) return;
        const data = {...this.state.editingData};
        data.is_auto_applied = is_auto_applied;
        this.setState({
            editingData:data
        });
    }
    private onTriggetConditionEdited(condition: ProgramTriggerCondition | undefined) {
        if (!CommonUtil.assertNotNull(this.state.editingData, "editingData", "onTriggetConditionEdited")) return;
        this.setState({
            editingData: {
                ...this.state.editingData,
                triggerCondition: condition
            }
        })
    }

    private onCommitItem(editingItem: IEditingProgramItem) {
        if (!CommonUtil.assertNotNull(this.state.editingData, "editingData", "onCommitItem")) return;
        const data = {...this.state.editingData};
        if (editingItem.isNew) {
            const max = data.items?.map(pi => pi.item_no).reduce((prev, current) => (prev > current) ? prev : current, 0)??0
            data.items = [...data.items, {...editingItem, item_no: max + 1}];
        } else {
            data.items = data.items.map(pi => pi.item_no === editingItem.item_no ? editingItem : pi);
        }
        this.setState({
            editingData:data
        });
    }

    private onDeleteItem(item_no:number) {
        if (!CommonUtil.assertNotNull(this.state.editingData, "editingData", "onDeleteItem")) return;
        const data = { ...this.state.editingData};
        data.items = data.items.filter(pi => pi.item_no !== item_no).map(pi => ({
            ...pi, 
            item_no: pi.item_no > item_no ? pi.item_no - 1 : pi.item_no,
            base_item_no: pi.base_item_no === item_no ? 0 : pi.base_item_no > item_no ? pi.base_item_no - 1 : pi.base_item_no,
        }))
        this.setState({
            editingData:data
        });
    }

    private buildTriggerName(program: ITeamProgram) {
        const triggerKind = ProgramKind.findTriggerKind(program.trigger_kind);
        if (!CommonUtil.assertNotNull(triggerKind, "trigger kind of " + program.trigger_kind)) return "";
        if (!triggerKind.hasTriggerEvent) return triggerKind.name;

        const evt = program.trigger_event == null ? undefined : ProgramKind.findTrigger(program.trigger_event)?.name;
        if (evt == null) return "";

        if (program.trigger_condition == null) return evt;
        const cond = JSON.parse(program.trigger_condition) as ProgramTriggerCondition;
        const condNames = listConditionNames(cond, this.state.cowBreeds, this.state.cowUses, this.state.medicines ?? [], this.state.treatItems ?? []);
        if (condNames.length === 0) return evt;

        return `${evt}（${condNames.join(",")}）`;
    }

    render() {

        if (this.state.initStatus === "loading") return <FetchWaiter />
        if (this.state.initStatus === "error") return <FetchError />

        return (<>
            <div className="page-root">
                <div className="product product-full-height">
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix w-100">
                            <div className="product-body">
                                <ul className={styles["list"] + " m-b-0"}>
                                    {this.state.programList.map((prog,i) => (
                                        <li key={prog.program_id} className={styles["list-item"]}>
                                            <div className={styles["list-item-content"]}>
                                                <div className={styles["list-item-name"]}>{prog.name}</div>
                                                <div className={styles["list-item-detail"]}>適用:{this.buildTriggerName(prog)}</div>
                                            </div>
                                            <div onClick={()=>this.onStartEdit(prog)} className={styles["list-item-icon"]}><i className="fas fa-pen clickable"></i></div>
                                        </li>))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{height:"40px", marginTop:"6px"}} onClick={this.onAddProgram}>
                        <span className="link">
                            <i className="fa fa-plus"></i>
                            <span> プログラムを追加</span>
                        </span>
                </div>
            </div>
            { this.state.editingData && this.state.isProgramEditPopupShown && (
                <ProgramManagementEditPopup
                    data={this.state.editingData}
                    onClose={() => this.setState({isProgramEditPopupShown: false}) }
                    onSubmit={this.onSubmitEdit}
                    onDelete={this.onDelete}
                    onNameEdited={this.onNameEdited}
                    onTriggerKindEdited={this.onTriggerKindEdited}
                    onTriggerEventSelected={this.onTriggerEventSelected}
                    onBaseDateKindSelected={this.onBaseDateKindSelected}
                    onIsAutoAppliedChecked={this.onIsAutoAppliedChecked}
                    onTriggerConditionEdited={this.onTriggetConditionEdited}
                    onCommitItem={this.onCommitItem}
                    onDeleteItem={this.onDeleteItem}
                    ranch_id={this.state.ranchId}
                    user={this.props.rootStore.user}
                    isSubmitExecuting={this.state.executing}
                    cowBreeds={this.state.cowBreeds}
                    cowUses={this.state.cowUses}
                    medicines={this.state.medicines ?? []}
                    treatItems={this.state.treatItems ?? []}
                    medicineRoutes={this.state.medicineRoutes ?? []}
                    presets={this.state.presets ?? []}
                    allTags={this.state.allTags ?? []}
                    tagsToAdd={this.state.editingData.tags.filter(t => t.action_kind === TAG_ACTION.ADD)}
                    tagsToRem={this.state.editingData.tags.filter(t => t.action_kind !== TAG_ACTION.ADD)}
                    onTagsToAddChanged={tags => this.setState({
                        editingData: {
                            ...this.state.editingData!,
                            tags: [
                                ...tags.map(t => ({ ...t, action_kind: TAG_ACTION.ADD })),
                                ...this.state.editingData!.tags.filter(t => t.action_kind !== TAG_ACTION.ADD)
                            ]
                        }}
                    )}
                    onTagsToRemChanged={tags => this.setState({
                        editingData: {
                            ...this.state.editingData!,
                            tags: [
                                ...this.state.editingData!.tags.filter(t => t.action_kind === TAG_ACTION.ADD),
                                ...tags.map(t => ({ ...t, action_kind: TAG_ACTION.REM }))
                            ]
                        }}
                    )}
                />
            )}
        </>)
    }
}

export default withRouter(withContext(ProgramManagement));