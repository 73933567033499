import React, { useState, useCallback } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ExecutionButton } from 'components/buttons/execution-button';
import { MemoSortableList } from 'components/parts/sortable-list';
import settingStyles from '../setting/setting.module.css';
import classnames from 'classnames';
import { CommonUtil } from 'config/util';
import { useCowFilter } from 'stores/fetcher_cow';
import { FetchWaiter, FetchError } from 'components/content/fetch-state';
import { CowFilterItemDto } from 'api';

export interface SelectListPopupProps {
    ranchId: number;
    onClose:()=>void;
    onDelete: (filterNo: number) => void;
    onSubmit: (filter: CowFilterItemDto) => void;
    isSubmitExecuting: boolean;
    onSort: (order:number[]) => Promise<boolean>;
}

export const SelectListPopup: React.FC<SelectListPopupProps> = (props) => {
    const { data, isError, isLoading } = useCowFilter(props.ranchId);

    const [ selectedNo, setSelectedNo ] = useState<number>();
    const [ sortingList, setSortingList ] = useState<{ filter_no: number, name: string }[]>();

    const onFinishSort = useCallback(async () => {
        if (!CommonUtil.assertNotNull(sortingList, "sortingList")) return;
        if (!CommonUtil.assertNotNull(data, "data")) return;

        const order = sortingList.map(s => s.filter_no);
        const original = data.list.map(o => o.filter_no);

        if (JSON.stringify(order) === JSON.stringify(original)) {
            setSortingList(undefined);
            return;
        }

        const res = await props.onSort(order);
        if (!res) return;

        setSortingList(undefined);

    }, [ sortingList, data, props.onSort ]);

    const onSubmit = useCallback(() => {
        if (!CommonUtil.assertNotNull(data, "data")) return;
        if (!CommonUtil.assertNotNull(selectedNo, "selectedNo")) return;

        const filter = data.list.find(f => f.filter_no === selectedNo);
        if (!CommonUtil.assertNotNull(filter, "filter of " + selectedNo)) return;

        props.onSubmit(filter);

    }, [ data, selectedNo ]);

    const isSorting = sortingList != null;

    return (
        <div>
            <Modal isOpen={true} scrollable={true} style={{ maxWidth:"320px", margin:"auto" }}>
                <ModalHeader toggle={props.onClose}>絞り込み条件リスト選択</ModalHeader>
                <ModalBody className="p-r-20 p-l-20">
                    { isLoading ? (
                        <FetchWaiter />
                    ) : (isError || data == null) ? (
                        <FetchError />
                    ) : data.list.length === 0 ? (
                        <div>保存されているリストはありません</div>
                    ) : (<>
                        <div className={settingStyles["sortable-list-shoulder"]} style={{ margin:"5px 0 5px"}}>
                            {!isSorting && (<span className="link" onClick={() => setSortingList(data.list)}>並び替え</span>)}
                            {isSorting && (<span className="link" onClick={onFinishSort}>完了</span>)}
                            {isSorting && (<span className="link" onClick={() => setSortingList(undefined)}>キャンセル</span>)}
                        </div>
                        <MemoSortableList className={settingStyles.list}
                            isSorting={isSorting}
                            items={sortingList ?? data.list}
                            onSorted={setSortingList}
                            listItem={item => (
                                <li className={classnames(settingStyles["list-item"], settingStyles["fix-height"], settingStyles["sortable-helper"])}>
                                    <div className={classnames(settingStyles["list-item-content"], "align-self-center")}>
                                        <div className={settingStyles["list-item-name"]}>
                                            { !isSorting ? (
                                                <div className="radio radio-css p-t-0">
                                                    <input type="radio" id={"radFil_" + item.filter_no} checked={selectedNo === item.filter_no}
                                                        onChange={() => setSelectedNo(item.filter_no)}
                                                    />
                                                    <label htmlFor={"radFil_" + item.filter_no}>{item.name}</label>
                                                </div>
                                            ) : (
                                                <span>{item.name}</span>
                                            )}
                                        </div>
                                    </div>
                                    { isSorting && (
                                        <div className={settingStyles["list-item-icon"]}>
                                            <i className="fas fa-bars p-5" />
                                        </div>
                                    )}
                                </li>
                            )}
                        />
                    </>)}
                </ModalBody>
                <ModalFooter className="modal-footer-fix">
                    <ExecutionButton type="decide" disabled={isSorting || selectedNo == null || props.isSubmitExecuting}
                        onClick={onSubmit}>反映</ExecutionButton>
                    <ExecutionButton type="delete" disabled={isSorting || selectedNo == null || props.isSubmitExecuting}
                        onClick={() => props.onDelete(selectedNo!)} />
                </ModalFooter>
            </Modal>
        </div>
    )
}