import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import bStyles from './egg-base-style.module.css';
import { FreezedArray, CommonUtil, ar } from '../../config/util';
import { EggStageData } from './egg-stage-count-modal';
import { findEggRank, findEggStage } from '../../config/egg-kind';
import { Communicator } from '../../api/communicator';
import { EggStockReq, EggStockReqLot, EggApi, ExtraCowDto, RanchSeedModifyReq, RanchApi, RanchTagDto, TeamSeedTagModifyReqStock } from '../../api';
import { ISeed } from '../../stores/RootStore';
import { ICowNameInfo, CowToDispInfo } from '../../components/parts/cows-popup';
import { A, LMT } from '../../config/constant';
import { GetPedigreeStr, listAllSeedTags } from '../setting/seed-management';
import { AddSeedPopup } from './add-seed-popup';
import { LotInfoForEdit, LotInfo, EggLotPopup } from './egg-lot-popup';
import { IconLink } from '../../components/parts/icon-link';
import { EggStockTagModal } from './egg-stock-tag-modal';
import { EditingTag } from '../../components/tag-edit-popup/tag-edit-popup';
import { useSeeds, resetSeeds } from '../../stores/fetcher';
import { FetchWaiter, FetchError } from '../../components/content/fetch-state';
import { ExecutionButton } from '../../components/buttons/execution-button';

export type EggStageAndLotData = Omit<EggStageData, "hasStocked"> & {
    lot: LotInfo
}

interface MyProps {
    ranchId: number;
    eventId: number;
    comm: Communicator;
    setIsLoading: (loading: boolean) => void;
    showQuestion: (text: string, buttons:string[]) => Promise<number|undefined>;
    cow: ICowNameInfo;
    father: ExtraCowDto;
    onClose: () => void;
    onSkip?: () => void;
    ranks: FreezedArray<{ rank: number, stages: FreezedArray<EggStageData> }>;
    onRegistered: () => void;
    isSov: boolean;
}

type TaggedStock = { label_no:string, tags: EditingTag[] };
type EditingStage = EggStageData & {
    lot: LotInfo | undefined,
    taggedStocks: FreezedArray<TaggedStock>
};

type TaggingLot = {
    egg_count: number;
    labelFrom: string;
    tags: FreezedArray<TaggedStock>;
    rank: number;
    stage: number;
    isFrozen: boolean;
}

export const EggStockModal = React.memo((props: MyProps) => {

    const mSeeds = useSeeds("ranch", props.ranchId);

    const [ ranks, setRanks ] = useState<FreezedArray<{ rank: number, stages: Array<EditingStage> }>>([]);
    const [ seedId, setSeedId ] = useState<number>();
    const [ lotName, setLotName ] = useState("");
    const [ executing, setExecuting ] = useState(false);
    const [ seedList, setSeedList ] = useState<FreezedArray<ISeed>>();
    const [ isAddSeedPopupShown, setIsAddSeedPopupShown ] = useState(false);
    const [ editingLot, setEditingLot ] = useState<LotInfoForEdit>();
    const [ allTags, setAllTags ] = useState<RanchTagDto[]>([]);
    const [ taggingLot, setTaggingLot ] = useState<TaggingLot>();
    const [ addingTagNames, setAddingTagNames ] = useState<string[]>([]);

    useEffect(() => {
        if (mSeeds.data == null) {
            setSeedList(undefined);
            setSeedId(undefined);
            setAllTags([]);
            return;
        }

        const eggSeeds = mSeeds.data.find(s => s.seed_type === A.SEED_TYPE.EGG.seed_type)?.seeds ?? [];
        const list = eggSeeds.filter(s => s.cow_id === props.cow.cow_id && s.father_exid === props.father.ex_cow_id);
        setSeedList(list);
        setAllTags(listAllSeedTags(mSeeds.data))
        if (seedId == null || !list.some(l => l.seed_id === seedId)) {
            setSeedId(list.length === 0 ? undefined : list[0].seed_id);
        }

        //lotNameは残しておく（同じ名前にすることもありそうなので）

    }, [ props.cow, props.ranchId, props.father, mSeeds.data ]);

    useEffect(() => {
        setRanks(
            props.ranks.map(r => ({ rank:r.rank, stages: r.stages.map(s => ({ ...s, lot: undefined, taggedStocks:[] }))}))
        );

    }, [ props.ranks ]);

    useEffect(() => {
        const newSet = new Set<string>();
        for (const rank of ranks) {
            for (const stage of rank.stages) {
                for (const stock of stage.taggedStocks) {
                    stock.tags.filter(t => t.tag_id == null).forEach(t => newSet.add(t.tag_name));
                }
            }
        }
        setAddingTagNames([...newSet]);

    }, [ ranks ]);

    const onLotInputClick = (rank: number, stage: number, isFrozen: boolean) => {
        const rankItem = ranks.find(r => r.rank === rank);
        if (!CommonUtil.assertNotNull(rankItem, "rankItem")) return;
        const stageItem = rankItem.stages.find(s => s.stage === stage && s.isFrozen === isFrozen);
        if (!CommonUtil.assertNotNull(stageItem, "stageItem")) return;

        setEditingLot({
            rank,
            stage,
            isFrozen,
            egg_count: stageItem.count,
            ...stageItem.lot
        });
    }

    const onLotSubmit = (lot: LotInfo | undefined) => {
        if (!CommonUtil.assertNotNull(editingLot, "editingLot")) return;

        const shouldClearTag = (before: LotInfo|undefined, after: LotInfo|undefined) => {
            if (before == null) return false;
            if (after == null) return true;
            return before.labelFrom !== after.labelFrom || before.labelTo !== after.labelTo;
        }

        setRanks(
            ranks.map(r => r.rank !== editingLot.rank
                    ? r
                    : ({
                        rank: r.rank,
                        stages: r.stages.map(st => (st.stage !== editingLot.stage || st.isFrozen !== editingLot.isFrozen)
                                                ? st
                                                : ({
                                                    ...st,
                                                    lot,
                                                    taggedStocks: shouldClearTag(st.lot, lot) ? [] : st.taggedStocks
                                                }))}))
        );
        setEditingLot(undefined);
    }

    const onTagEdit = (rank: number, stage: EditingStage) => {
        if (!CommonUtil.assertNotNull(stage.lot, "lot")) return;
        setTaggingLot({
            rank,
            stage: stage.stage,
            egg_count: stage.count,
            isFrozen: stage.isFrozen,
            labelFrom: stage.lot.labelFrom,
            tags: stage.taggedStocks
        })
    }

    const onTagSubmit = (stocks: FreezedArray<{ label_no:string, tags:EditingTag[] }>) => {
        if (!CommonUtil.assertNotNull(taggingLot, "taggingLot")) return;

        setRanks(
            ranks.map(r => r.rank !== taggingLot.rank
                    ? r
                    : ({
                        rank: r.rank,
                        stages: r.stages.map(st => (st.stage !== taggingLot.stage || st.isFrozen !== taggingLot.isFrozen)
                                                ? st
                                                : ({
                                                    ...st,
                                                    taggedStocks:stocks
                                                }))
                    }))
        );

        setTaggingLot(undefined);
    }

    const onSubmit = async () => {
        if (!CommonUtil.assertNotNull(seedId, "seedId")) return;

        props.setIsLoading(true);
        setExecuting(true);

        const lots: EggStockReqLot[] = [];
        for (const rank of ranks) {
            for (const stage of rank.stages.filter(s => s.lot != null)) {
                lots.push({
                    egg_rank: rank.rank,
                    egg_stage: stage.stage,
                    is_frozen: stage.isFrozen ? 1 : 0,
                    label_from: stage.lot!.labelFrom,
                    label_to: stage.lot!.labelTo,
                    unit_price: stage.lot!.unitPrice,
                    comment: stage.lot!.note,
                    tags: stage.taggedStocks.map(stk => ({
                        label_no:stk.label_no,
                        tags: stk.tags.map(t => ({ id:t.tag_id, name: t.tag_name }))
                    }))
                })
            }
        }

        const req: EggStockReq = {
            ranch_id: props.ranchId,
            event_id: props.eventId,
            lot_name: lotName,
            seed_id: seedId,
            lots
        };

        const api = await EggApi();
        const post = props.isSov ? api.stockSovUsingPOST : api.stockIvfEggUsingPOST;
        const res = await props.comm.send(post(req));

        props.setIsLoading(false);
        setExecuting(false);

        if (res.result !== "OK") return;

        resetSeeds(props.ranchId, false);
        props.onRegistered();
    }

    const addSeed = async () => {
        setIsAddSeedPopupShown(true);
    }

    const onNewSeedSubmit = async (data: {
        father_exid: number,
        ancestor_2_exid: number | undefined,
        ancestor_3_exid: number | undefined,
        ancestor_4_exid: number | undefined,
        get_from: string
    }) => {
        if (!CommonUtil.assertNotNull(seedList, "seedList")) return;

        const anySame = seedList.some(s => {
            return s.father_exid === data.father_exid
                && (s.ancestor_2_exid ?? null) === (data.ancestor_2_exid ?? null)
                && (s.ancestor_3_exid ?? null) === (data.ancestor_3_exid ?? null)
                && (s.ancestor_4_exid ?? null) === (data.ancestor_4_exid ?? null)
                && (s.get_from ?? "") === data.get_from;
        });
        if (anySame) {
            const confirmed = await props.showQuestion("同じ内容の血統がすでに登録されています。登録を実行しますか？", ["OK","キャンセル"]);
            if (confirmed !== 0) return;
        }

        const req: RanchSeedModifyReq = {
            ranch_id: props.ranchId,
            is_new: 1,
            father_exid: data.father_exid,
            ancestor_2_exid: data.ancestor_2_exid,
            ancestor_3_exid: data.ancestor_3_exid,
            ancestor_4_exid: data.ancestor_4_exid,
            cow_id: props.cow.cow_id,
            name: (props.cow.name ?? "") === "" ? CowToDispInfo(props.cow, false) : props.cow.name,
            seed_type: A.SEED_TYPE.EGG.seed_type,
            get_from: data.get_from,
            seed_id: 0,
        };

        setExecuting(true);
        try {
            const res = await props.comm.send((await RanchApi()).modifySeedUsingPOST(req));

            if (res.result !== "OK") return;

            await resetSeeds(props.ranchId, false);
    
            if (res.data?.id != null) {
                setSeedId(res.data.id);
            }
        } finally {
            setExecuting(false);
            setIsAddSeedPopupShown(false);
        }
    }

    const styles: { [key: string]: React.CSSProperties } = {
        container: {
            display: "flex",
            flexFlow: "column nowrap"
        },
        block: {
            display: "flex",
            alignItems: "center",
            flexFlow: "row wrap",
            marginBottom: "10px"
        },
        blockHeader: {
            minWidth: "84px"
        },
        row: {
            display: "flex",
            alignItems: "center",
            flexFlow: "row wrap"
        },
        rowNoWrap: {
            display: "flex",
            alignItems: "center",
            flexFlow: "row nowrap"
        },
        selectBrood: {
            flex: 1,
            marginRight: "8px"
        },
        rankContainer: {
            marginTop: "10px"
        },
        rank: {
            fontWeight: "bold",
            color: "#666666"
        },
        stageItem: {
            padding: "3px 0 3px 4px"
        },
        stage: {
            minWidth: "50px"
        },
        stageCount: {
            minWidth: "46px",
            textAlign: "right",
            marginRight: "12px"
        },
        lot: {
            fontSize: "0.75rem",
            color: "#999999",
            marginLeft: "10px"
        }
    } as const;

    const canSubmit = lotName !== ""
                    && !ranks.every(r => r.stages.every(s => s.lot == null))
                    && seedId != null;

    return (
        <div>
            <Modal isOpen={true} scrollable={true}>
                <ModalHeader toggle={() => props.onClose()}>在庫登録：{CowToDispInfo(props.cow, true)}</ModalHeader>
                { mSeeds.isLoading ? (
                    <FetchWaiter />
                ) : (mSeeds.isError || seedList == null) ? (
                    <FetchError />
                ) : (<>
                    <ModalBody>
                        <div style={styles.container}>
                            <div style={styles.block}>
                                <div style={styles.blockHeader}>製造Lot No.<br/>(ケーンNo.)</div>
                                <div>
                                    <input type="text" className="form-control" data-testid="lot-no"
                                        value={lotName}
                                        maxLength={LMT.SEED.LOT_NO_LEN}
                                        onChange={e => setLotName(e.target.value)}
                                    />
                                    <span style={{fontSize:"0.75rem"}}>※なければ採卵/凍結年月日</span>
                                </div>
                            </div>
                            <div style={styles.block}>
                                <div style={styles.blockHeader}>血統</div>
                                <select className="form-control" style={styles.selectBrood}
                                    value={seedId ?? ""}
                                    onChange={e => setSeedId(e.target.value === "" ? undefined : Number(e.target.value))}
                                >
                                    <option value="">選択</option>
                                    { seedList.map(s => (
                                        <option key={s.seed_id} value={s.seed_id}>{GetPedigreeStr(s)}</option>
                                    ))}
                                </select>
                                <div className="link" onClick={() => addSeed()}>新規</div>
                            </div>
                        </div>
                        { ranks.map(r => (
                            <div key={r.rank} style={styles.rankContainer} data-testid="rank-container">
                                <div style={styles.rank} data-testid="rank-name">ランク {findEggRank(r.rank)?.name ?? ""}</div>
                                <div className={bStyles["block"]}>
                                    { r.stages.map((st,is) => (
                                        <div style={styles.stageItem} key={is} data-testid="stage-container">
                                            <div style={styles.row}>
                                                <div style={styles.rowNoWrap} data-testid="stage-info">
                                                    <div style={styles.stage}>{findEggStage(st.stage)?.name ?? ""}</div>
                                                    <div>{st.isFrozen ? "凍結" : "新鮮"}</div>
                                                    <div style={styles.stageCount}>{st.count}個</div>
                                                </div>
                                                <div className="link" onClick={() => onLotInputClick(r.rank, st.stage, st.isFrozen)}>単価・ラベル</div>
                                                <IconLink iconType="tag"
                                                    disabled={st.lot == null}
                                                    text={st.taggedStocks.length === 0 ? "タグづけ" : ar.distinct(ar.flat(st.taggedStocks.map(t => t.tags.map(tg => tg.tag_name)))).join(",") }
                                                    style={{ fontSize:"0.75rem", marginLeft:"14px" }}
                                                    onClick={() => onTagEdit(r.rank, st) }
                                                />
                                            </div>
                                            { st.lot != null && (
                                                <div data-testid="lot-info" style={styles.lot}>{st.lot.unitPrice}円 {st.lot.labelFrom}～ {st.lot.note}</div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </ModalBody>
                    <ModalFooter className="modal-footer-fix">
                        <ExecutionButton type="save" disabled={executing || !canSubmit} onClick={() => onSubmit()} />
                        { props.onSkip != null && (
                            <ExecutionButton type="cancel" disabled={executing} onClick={props.onSkip}>スキップ</ExecutionButton>
                        )}
                    </ModalFooter>
                </>)}
            </Modal>
            { isAddSeedPopupShown && (
                <AddSeedPopup
                    comm={props.comm}
                    father={props.father}
                    mother_id={props.cow.cow_id}
                    get_from="自家"
                    isSubmitExecuting={executing}
                    onClose={() => setIsAddSeedPopupShown(false) }
                    ranch_id={props.ranchId}
                    onSubmit={d => onNewSeedSubmit(d)}
                />
            )}
            { editingLot != null && (
                <EggLotPopup
                    { ...editingLot}
                    onClose={() => setEditingLot(undefined)}
                    onSubmit={onLotSubmit}
                    onClear={() => onLotSubmit(undefined)}
                />
            )}
            { taggingLot != null && (
                <EggStockTagModal
                    listMode="selectAll"
                    allTags={allTags}
                    onClose={() => setTaggingLot(undefined)}
                    egg_count={taggingLot.egg_count}
                    labelFrom={taggingLot.labelFrom}
                    taggedStocks={taggingLot.tags}
                    isFrozen={taggingLot.isFrozen}
                    rank={taggingLot.rank}
                    stage={taggingLot.stage}
                    onSubmit={stocks => onTagSubmit(stocks)}
                    unsubmittedTagList={addingTagNames}
                />
            )}
        </div>

    )
});