export type IServiceTerm = {
    title: string;
    summaries: string[];
    chapters: IServiceTermChapter[];
    footer: string[];
}
export type IPrivacyPolicy = {
    title: string;
    summaries: string[];
    chapters: IPrivacyPolicyChapter[];
    footer: string[];
}

export type IPrivacyPolicyChapter = IServiceTermChapterWithBody;

export type IServiceTermChapter = IServiceTermChapterWithBody | IServiceTermChapterWithChapters;
type IServiceTermChapterWithBody = {
    title: string;
    body: IServiceTermBody;
}
type IServiceTermChapterWithChapters = {
    title: string;
    chapters: IServiceTermSubChapter[];
}

export type IServiceTermBody = Array<string|IServiceTermOrderedList>;

export type IServiceTermOrderedList = {
    items: Array<string|{ text:string, childList: string[] }>;
}

export type IServiceTermSubChapter = {
    body: IServiceTermBody;
}


export const ServiceTerm: IServiceTerm = {
    "title": "vetell（ベッテル）利用規約",
    "summaries": [
        "「vetell（ベッテル）利用規約」（以下「本規約」といいます。）は、株式会社VETELL（以下「当社」といいます。）が運営する、牛群管理及び共有型電子カルテサービス「vetell（ベッテル）」を通じて提供する各サービスの利用条件を定めるもので、本サービスの利用に関する当社と本利用者との間の契約（以下「利用契約」といいます。）に適用されるものです。",
        "本サービスをご利用頂く（利用契約をお申込み頂く）にあたっては、本規約の全文をお読み頂いたうえで、本規約の全ての条項について承諾頂く必要があります。",
        "本サービスをご利用頂いた場合、利用者の方が本規約の内容を理解しており、かつ、本規約の全ての条項について承諾したものとみなします。"
    ],
    "chapters": [
        {
            "title": "（用語の定義）",
            "body": [
                "本規約において使用する以下の用語の意味は、以下に定めるとおりとします。",
                {
                    "items": [
                        "「本サービス」とは、当社が運営する「vetell」を通じて当社が提供する各サービスの総称をいいます。",
                        "「登録ユーザー」とは、当社が運営する「vetell」にユーザー登録した法人又は個人の総称をいいます。",
                        "「オーナー農家ユーザー」とは、当社とサービス利用契約を締結した畜産業又は酪農業を営む法人又は個人事業主をいいます。",
                        "「オーナー外部関係者ユーザー」とは、当社とサービス利用契約を締結した獣医師、家畜人工授精師、家畜受精卵移植師、削蹄師、薬品製造/販売業者、飼料製造/販売業者その他畜産業又は酪農業に関係する法人又は個人事業主をいいます。",
                        "「オーナーユーザー」とは、オーナー農家ユーザー及びオーナー外部関係者ユーザーの総称をいいます。",
                        "「管理権限」とは、登録ユーザーに対し、一般ユーザー権限を付与することのできる権限をいいます。",
                        "「管理農家ユーザー」とは、オーナー農家ユーザーから管理権限を付与された登録ユーザーをいいます。",
                        "「管理外部関係者ユーザー」とは、オーナー外部関係者ユーザーから管理権限を付与された登録ユーザーをいいます。",
                        "「管理ユーザー」とは、管理農家ユーザー及び管理外部関係者ユーザーの総称をいいます",
                        "「一般農家ユーザー」とは、管理農家ユーザーからデータアクセス権限を付与された登録ユーザーをいいます。",
                        "「一般外部関係者ユーザー」とは、管理農家ユーザー又は管理外部関係者ユーザーからデータアクセス権限を付与された登録ユーザーをいいます。",
                        "「一般ユーザー」とは、一般農家ユーザー及び一般外部関係者ユーザーの総称をいいます。",
                        "「農家ユーザー」とは、オーナー農家ユーザー、管理農家ユーザー及び一般農家ユーザーの総称をいいます。",
                        "「外部関係者ユーザー」とは、オーナー外部関係者ユーザー、管理外部関係者ユーザー及び一般外部関係者ユーザーの総称をいいます。",
                        "「組織」とは、畜産業又は酪農業を営む法人及び個人事業主、並びに獣医師、家畜人工授精師、家畜受精卵移植師、削蹄師、薬品製造/販売業者、飼料製造/販売業者その他畜産業又は酪農業に関係する法人及び個人事業主の総称をいいます。",
                        "「本利用者」とは、オーナーユーザー、管理ユーザー及び一般ユーザーの総称の総称をいいます。",
                        "「本ソフトウェア」とは、本サービス上において当社から本利用者に提供される第7条第1項に定める各機能を有するソフトウェアをいいます。",
                        "「当社ウェブサイト」とは、ドメイン「https://vetell.jp/」である当社が運営するウェブサイトをいいます。"
                    ]
                }
            ]
        },
        {
            "title": "（本サービスに関する注意事項）",
            "body": [
                "本利用者は、本サービスの利用に際して、以下に定める内容を正確に理解し、その内容について了解の上で本サービスを利用するものとします。",
                {
                    "items": [
                        "本サービスは、畜産農家・酪農家による餌の摂取量等の日常の記録と、獣医師等の畜産・酪農関係者による診療等の記録とを網羅的に一元管理し、対象となる牛群の情報を関係者間で共有する機能を提供するものです。本サービスにより、特定の牛群に対する効果的な治療や予防による健康維持、生産性向上及び牧場収益の向上を図ることが期待できます。ただし、本サービスはこれらの期待される効果の発生を保証するものではありません。",
                        "本サービスは、本利用者による適切な情報の入力及び管理が必要となりますので、本利用者自身で入力情報の真偽や入力方法の正確性をご確認ください。本利用者の操作方法によっては、正確なデータが作成・反映されない場合があります。作成・反映されたデータについては、本利用者自身の責任で確認するものとします。",
                        "本サービスを利用して表示された情報の正確性や集計結果が、対象となる牛群の実態を正確に反映していることについて保証するものではありません。"
                    ]
                }
            ]
        },
        {
            "title": "（本規約の範囲と変更）",
            "chapters": [
                {
                    "body": [
                        "本規約は、登録ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。"
                    ]
                },
                {
                    "body": [
                        "当社が本サービスサイト上で掲載する本サービスの利用に関するルールは、本規約の一部を構成するものとします。"
                    ]
                },
                {
                    "body": [
                        "本規約の内容と、前項のルールその他の本規約外における本サービスの説明等とが矛盾・抵触する場合は、当該説明等の規定を優先させる旨の特段の定めがない限り、本規約の規定が優先して適用されるものとします。"
                    ]
                }
            ]
        },
        {
            "title": "（ユーザー登録）",
            "chapters": [
                {
                    "body": [
                        "本サービスの利用を希望する方（以下「利用希望者」といいます。）は、当社の定める方法によって、新規ユーザー登録に必要な情報（メールアドレス及びパスワード等）を事前に当社に対して提供した上で、登録ユーザーとなることが必要です。"
                    ]
                },
                {
                    "body": [
                        "利用希望者は、本規約に定める利用条件の全ての内容に同意した上で、当社の定める方法により、利用申込みを行い、当社の承諾を得るものとします。"
                    ]
                },
                {
                    "body": [
                        "利用希望者は、第1項に定める登録に必要な情報を当社に対して提供した後に当社から送信される本人確認用のメールを受信してください。利用希望者が当該メールに記載されたリンクを経由し、新規ユーザー登録を完了した日をもって、当該利用希望者は登録ユーザーになるものとします。"
                    ]
                },
                {
                    "body": [
                        "利用希望者は、当社の定める方法によって、ユーザー登録に必要な利用者情報を正確に当社に対して提供する必要があります。"
                    ]
                },
                {
                    "body": [
                        "利用希望者が次のいずれかに該当し、又は該当するおそれがあると当社が判断した場合、当社は、当該利用希望者による申込みを承諾しないことがあります。この場合、当社は拒絶の理由に関し一切の説明義務及び損害賠償義務を負わないものとします。",
                        {
                            "items": [
                                "本規約に違反するおそれがあると当社が判断した場合",
                                "当社に提供された利用者情報の全部又は一部につき虚偽、誤記又は記載漏れがあった場合",
                                "過去に本サービスの利用契約を解除された方又は本規約に違反したことがある方である場合",
                                "重複して利用申込みを行う場合",
                                "暴力団等反社会的勢力（第26条第1項にて定義します。）と関係を有し、又は公序良俗に反する事業を自ら行っている場合",
                                "本サービスと同種又は類似するサービスを提供している場合又は将来提供する予定である場合",
                                "その他、当社が当該利用希望者による本サービスの利用を相当でないと判断した場合"
                            ]
                        }
                    ]
                },
                {
                    "body": [
                        "本サービスの利用開始後に、前項に定める各号事由のいずれかに該当した方、又は該当することが判明した方については、当社は、当社の判断により、その時点で本サービスの全部若しくは一部の利用を制限することができるものとします。なお、当社はその理由について一切の開示義務を負わないものとします。"
                    ]
                },
                {
                    "body": [
                        "本サービスの利用は、本条に規定する登録を行った者自身（登録ユーザーが法人である場合、当該法人の従業員が法人の業務として利用することを含みます。）で利用をするものとし、当社の許可なく第三者に本サービスを利用させてはならないものとします。"
                    ]
                },
                {
                    "body": [
                        "当社は、本条に基づき当社が行った行為により利用希望者又は登録ユーザーに生じた損害について、一切の責任を負いません。"
                    ]
                }
            ]
        },
        {
            "title": "（登録情報の変更）",
            "chapters": [
                {
                    "body": [
                        "登録ユーザーは、当社に届け出ているユーザー登録情報に変更が生じた場合には、速やかに、本サービス内の設定変更画面にて登録内容の変更を行うものとします。登録内容の変更がなされなかったことにより生じた損害については、全て当該登録ユーザーが負うものとし、当社は一切の責任を負いません。なお、メールアドレスについて変更を行った場合、再度会員登録を行うものとします。"
                    ]
                },
                {
                    "body": [
                        "登録ユーザーに対する当社からの通知は、通知時において、本サービスに登録されている登録ユーザーのメールアドレスにメールを送信することをもって通知したものとみなします。"
                    ]
                },
                {
                    "body": [
                        "登録ユーザーは、当社から登録ユーザー宛に送信される電子メールの受信を拒否する設定等を行ってはならないものとし、受信拒否設定や登録ユーザーのメールアドレスが変更された等の事情により、当該登録ユーザーに配信されたメールがエラー等により不着であった場合でも、当社からの通知の発信時をもって通知がなされたものとみなします。"
                    ]
                },
                {
                    "body": [
                        "当社は、登録ユーザーに配信されたメールがエラー等になった場合、当該メールアドレスへのメールの配信を止めることができるものとします。"
                    ]
                },
                {
                    "body": [
                        "前二項の場合に、登録ユーザーが当社からの通知を受領できなかったことで、登録ユーザーに損害が生じたとしても、当社は一切の責任を負いません。"
                    ]
                }
            ]
        },
        {
            "title": "（本サービスのパスワードの管理）",
            "chapters": [
                {
                    "body": [
                        "登録ユーザーは、ユーザー登録時に自ら設定したパスワードの管理責任を負うものとし、当該パスワードを第三者に知られるような態様で管理してはならないものとします。この義務を怠ったことにより損害が生じても当社は、一切の責任を負いません。"
                    ]
                },
                {
                    "body": [
                        "パスワードを用いてなされた本サービスの利用は、全て当該登録ユーザー本人によりなされたものとみなし、当該パスワードの不正利用により当社又は第三者が損害を被った場合には、当該パスワードを使用した者が登録ユーザー本人であるか否かを問わず、当該登録ユーザーは当該損害を賠償するものとします。"
                    ]
                },
                {
                    "body": [
                        "登録ユーザーは、以下の各号に従い、第三者から推測されにくいパスワードを使用する義務があるものとします。この義務を怠ったことにより損害が生じても当社は、一切の責任を負いません",
                        {
                            "items": [
                                "英数6文字〜32文字の、大文字、小文字、数字及び記号を全て混ぜたパスワード",
                                "他のウェブサイトやサービスなどで利用していない独自のパスワード",
                                "住所、電話番号、生年月日など容易に推測できる情報に基づかないパスワード",
                                "その他第三者から推測されやすい要素・情報に基づかないパスワード"
                            ]
                        }
                    ]
                },
                {
                    "body": [
                        "登録ユーザーはパスワードを第三者に利用させたり、貸与、譲渡、売買、質入等を行うことはできないものとします。"
                    ]
                },
                {
                    "body": [
                        "当社は、一定回数以上にわたりログインに失敗したなど、登録ユーザーのパスワードに不正利用の可能性があると判断した場合は、当該アカウントを利用停止とすることができるものとします。その場合、登録ユーザーは当社が定める手続に従い、利用停止の解除を行うこととします。なお、当社がこのような処置をとったことにより、当該登録ユーザーが本サービスを利用できず損害が生じても、当社は一切の責任を負いません。"
                    ]
                }
            ]
        },
        {
            "title": "（本サービスの機能）",
            "chapters": [
                {
                    "body": [
                        "本サービスには、以下の機能が含まれるものとします。なお、各機能の詳細については、本サービス上の各機能の説明ページの記載に従うものとします。",
                        {
                            "items": [
                                "餌の摂取量記録・管理機能",
                                "投薬及び処置等の治療記録・管理機能",
                                "交配及び繁殖記録・管理機能",
                                "収支管理機能"
                            ]
                        }
                    ]
                },
                {
                    "body": [
                        "オーナー農家ユーザー及び管理農家ユーザーは、前項各号に定める全ての情報について編集及び閲覧をすることができます。"
                    ]
                },
                {
                    "body": [
                        "一般農家ユーザーは、第1項各号に定める情報のうち、オーナー農家ユーザー又は管理農家ユーザーから権限を与えられた範囲内において、編集又は閲覧をすることができます。"
                    ]
                },
                {
                    "body": [
                        "オーナー外部関係者ユーザー又は管理外部関係者ユーザーは、第1項第2号から第3号に定める情報について、編集及び閲覧をすることができます。"
                    ]
                },
                {
                    "body": [
                        "一般外部関係者ユーザーは、第1項第2号から第3号に定める情報のうち、オーナー外部関係者ユーザー又は管理外部関係者ユーザーから権限を与えられた範囲内において、編集又は閲覧をすることができます。"
                    ]
                },
                {
                    "body": [
                        "前二項の定めにかかわらず、外部関係者ユーザーは、第1項各号に定める情報のうち、オーナー農家ユーザー又は管理農家ユーザーから権限を与えられた範囲内において、本サービスを通じて当該農家の情報を編集又は閲覧することができます。"
                    ]
                },
                {
                    "body": [
                        "当社は、自らの判断により、本サービスの機能の変更及び追加などを随時実施することができることとします。"
                    ]
                }
            ]
        },
        {
            "title": "（農家ユーザー契約）",
            "chapters": [
                {
                    "body": [
                        "当社は、オーナー農家ユーザーになることを希望する者との間で、本サービスをオーナー農家ユーザーとして利用することについての利用契約（以下「農家ユーザー契約」といいます。）を締結します。"
                    ]
                },
                {
                    "body": [
                        "オーナー農家ユーザーは、当社に対し、当該オーナーが自ら指定したID（以下「牧場ID」といいます。）を申請し、当社がこれを本サービスに登録することによって当該IDの利用が可能となります。"
                    ]
                },
                {
                    "body": [
                        "オーナー農家ユーザーは、本サービスの利用料、支払い方法及び利用期間等について、前項に定める農家ユーザー契約の内容に従うものとします。"
                    ]
                }
            ]
        },
        {
            "title": "（外部関係者ユーザー契約）",
            "chapters": [
                {
                    "body": [
                        "当社は、オーナー外部関係者ユーザーになることを希望する者との間で、本サービスをオーナー外部関係者ユーザーとして利用することについての利用契約（以下「外部関係者ユーザー契約」といいます。）を締結します。"
                    ]
                },
                {
                    "body": [
                        "オーナー外部関係者ユーザーは、当該オーナーが自ら指定したID（以下「診療所ID」といいます。）を申請し、当社がこれを本サービスに登録することによって当該IDの利用が可能となります。"
                    ]
                },
                {
                    "body": [
                        "オーナー外部関係者ユーザーは、本サービスを無料で利用することができますが、利用期間等については、前項に定める外部関係者ユーザー契約の内容に従うものとします。"
                    ]
                }
            ]
        },
        {
            "title": "（本サービスの組織IDの管理）",
            "chapters": [
                {
                    "body": [
                        "オーナーユーザーは、当社が登録した牧場ID及び診療所ID（以下「組織ID」と総称します。）の管理責任を負うものとし、管理ユーザー又は一般ユーザーとなることを希望し、オーナーユーザーが権限を付与する予定の第三者に対してのみ共有するものとします。この義務を怠ったことにより損害が生じても当社は、一切の責任を負いません。"
                    ]
                },
                {
                    "body": [
                        "組織IDを用いてなされた本サービスの利用は、全て当社が組織IDを登録したオーナーユーザーの責任のもとに利用されたものとみなし、当該組織IDの不正利用により当社又は第三者が損害を被った場合には、当該組織IDを使用した者が、当社が組織IDを登録したオーナーユーザー又は当該オーナーユーザーから編集又は閲覧権限を付与された本利用者であるか否かを問わず、当該オーナーユーザーは当該損害を賠償するものとします。"
                    ]
                }
            ]
        },
        {
            "title": "（オーナーユーザー）",
            "chapters": [
                {
                    "body": [
                        "オーナー農家ユーザーは、管理農家ユーザーに対し、一般農家ユーザー及び外部関係者ユーザーを登録する権限並びに当該ユーザーに対し、情報の編集又は閲覧権限を設定する権限を与えることができます。"
                    ]
                },
                {
                    "body": [
                        "オーナー外部関係者ユーザーは、管理外部関係者ユーザーに対し、一般外部関係者ユーザーを登録する権限及び当該一般外部関係者ユーザーに対し、情報の編集又は閲覧権限を設定する権限を与えることができます。"
                    ]
                }
            ]
        },
        {
            "title": "（管理ユーザー）",
            "chapters": [
                {
                    "body": [
                        "管理農家ユーザーは、次条第1項の定めにより認証申請を行った登録ユーザーに対し、一般農家ユーザー又は一般外部関係者ユーザーとなることを許可することができます。"
                    ]
                },
                {
                    "body": [
                        "管理外部関係者ユーザーは、次条第1項の定めにより認証申請を行った登録ユーザーに対し、一般外部関係者ユーザーとなることを許可することができます。"
                    ]
                },
                {
                    "body": [
                        "管理ユーザーは、前二項の定めにより本サービスの利用を許可した一般ユーザーに対し、それぞれ情報への編集又は閲覧権限を設定することができます。"
                    ]
                }
            ]
        },
        {
            "title": "（一般ユーザー）",
            "chapters": [
                {
                    "body": [
                        "登録ユーザーが一般ユーザーとなることを希望する場合、当社の定める方法によって、本ソフトウェアにログイン後、組織検索画面から組織を検索し、情報へのアクセスを希望する組織に対し認証申請を行う必要があります。"
                    ]
                },
                {
                    "body": [
                        "登録ユーザーは、前項の認証申請手続きに際して、予め当社が本サービス上に登録した組織IDの入力が必要となります。"
                    ]
                },
                {
                    "body": [
                        "登録ユーザーは、第1項に定める認証申請に対し、管理ユーザーから利用を許可された時点をもって、一般ユーザーとして本サービスを利用することができます。なお、一般ユーザーが編集又は閲覧することができる情報の範囲は、管理ユーザーから編集又は閲覧権限を設定された範囲内に限られるものとします。"
                    ]
                },
                {
                    "body": [
                        "一般ユーザーは、情報へのアクセスを希望する複数の組織から利用許可を受けることにより、本サービスを通じて複数の組織の情報を編集又は閲覧することができるようになります。ただし、一般外部関係者ユーザーは、獣医師、家畜人工授精師、家畜受精卵移植師、削蹄師、薬品製造/販売業者、飼料製造/販売業者その他畜産業又は酪農業に関係する法人及び個人事業主からは、複数の利用許可を受けることができず、1つの組織のみから利用許可を得ることができるものとします。"
                    ]
                }
            ]
        },
        {
            "title": "（サービス利用料）",
            "chapters": [
                {
                    "body": [
                        "オーナー農家ユーザーは、個別の農家ユーザー契約に従い、本サービスの利用料を支払うものとします。"
                    ]
                },
                {
                    "body": [
                        "管理農家ユーザー及び当該管理農家ユーザーから情報アクセス権限を付与された一般ユーザーは、本サービスを無料でご利用頂くことができます。ただし、オーナー農家ユーザーがサービス利用料の支払いを遅滞した場合には、サービスの利用が停止されることがあります。"
                    ]
                },
                {
                    "body": [
                        "オーナー外部関係者ユーザー、管理外部関係者ユーザー及び当該管理外部関係者ユーザーから情報アクセス権限を付与された一般外部関係者ユーザーは、本サービスを無料でご利用頂くことができます。"
                    ]
                },
                {
                    "body": [
                        "本利用者が、本サービスに関連して当社に対して金銭支払い義務を負う場合において、金銭の支払を遅延したときは、支払期日の翌日から支払済みに至るまで、年14.6％の割合（年365日日割計算）による遅延損害金を支払うものとします。"
                    ]
                }
            ]
        },
        {
            "title": "（知的財産権）",
            "chapters": [
                {
                    "body": [
                        "本サービス及び本サービスに関するすべての知的財産権は当社又は当社にライセンスを許諾している者に帰属します。この対象には、本サービスの構成、デザイン、イラスト・写真等のグラフィックス、文章及びソースコード等のすべてを含みます。本規約の定めに基づく本サービスの利用許諾は、当該知的財産権の譲渡又は利用許諾を意味するものではありません。"
                    ]
                },
                {
                    "body": [
                        "登録ユーザーは当社の事前の承諾を得た場合を除いて、本サービス及び本サービスに含まれる内容を複製、公開、譲渡、貸与、翻訳、転売、転送、翻訳、使用許諾、再利用等してはならないものとします。"
                    ]
                },
                {
                    "body": [
                        "登録ユーザーが前二項に違反する行為を行った場合、当社は当該登録ユーザーが行なう行為について差止めを行なうことができることとし、また当該登録ユーザーがこれらの行為によって受けた損害に関し、当社は、一切の保証をしないと同時に、当該登録ユーザーがこれらの行為によって得た利益相当額について、損害賠償請求を行う場合があります。"
                    ]
                }
            ]
        },
        {
            "title": "（情報の取り扱い）",
            "chapters": [
                {
                    "body": [
                        "当社は、登録ユーザーが本サービスを通じて当社に提供した個人情報（以下、単に「個人情報」といいます。）を含むすべての情報（以下「送信情報」と総称します。）を安全に管理するよう努めます。ただし、本サービスが、本質的に情報の喪失、改変、破壊等の危険が内在するインターネット通信網を利用した電磁的サービスであることを踏まえ、送信情報が喪失、改変、破壊等されたことにより本利用者が被った損害について、当社は、一切の責任（送信情報の復旧責任及び損害賠償責任を含みますが、これらに限りません。）を負わないものとします。"
                    ]
                },
                {
                    "body": [
                        "当社は、本サービスを通じて取得した個人情報を本サービスの「プライバシーポリシー」に基づき、適切に取り扱うものとします。なお、当社は、法令に定める場合を除き、個人情報を含む送信情報について、本利用者からの削除要請に応じる義務を負わないものとします。"
                    ]
                },
                {
                    "body": [
                        "本利用者が、本サービスを通じて提供又は入力した個々の送信情報（第7条第1項に定める機能を利用するために入力した牛個体情報及び牛群情報等を含む。）についての権利は、当該情報を提供又は入力した本利用者その他当該情報に対し権利を有する者に帰属するものとします。"
                    ]
                },
                {
                    "body": [
                        "当社は、以下のいずれかに該当し又は該当すると当社が判断した場合は、本利用者に事前に通知することなく、送信情報の全部又は一部を閲覧、保存、復元し、又は第三者へ開示すること（以下「閲覧等」といいます。）ができるものとします。なお、当社は上記判断に関する理由を開示する義務は負わないものとします。",
                        {
                            "items": [
                                "本利用者の同意を得た場合",
                                "本利用者が本規約所定の禁止行為を行った場合",
                                "当社が閲覧等の同意を求める連絡を本利用者に行なってから7日以内に、当該本利用者からの回答がなかった場合",
                                "本利用者又は第三者の生命・身体・その他重要な権利を保護するために必要な場合",
                                "裁判所、警察等の公的機関から、法令に基づく正式な要請を受けた場合",
                                "法律に従い閲覧等の義務を負う場合",
                                "本サービスのメンテナンスや技術的不具合解消のために必要がある場合",
                                "上記各号に準じる必要性があると当社が判断した場合"
                            ]
                        },
                        "前2項の定めにかかわらず、当社は、本サービスを通じて得た情報や本利用者から提供又は入力された送信情報（これらの情報には、個人又は法人を特定する情報を除いたうえでのアンケート情報等を含みます。）を、情報の分析や評価をし、第三者への情報提供等のために利用することがあります。ただし、当社が第三者への情報提供を行う場合、情報については統計処理を行ったものに限ることとし、本利用者の機密情報の侵害行為とならないよう最大限の配慮を行うこととします。"
                    ]
                }
            ]
        },
        {
            "title": "（秘密保持）",
            "body": [
                "登録ユーザーは、本サービスに関連して当社が登録ユーザーに対して秘密に取り扱うことを求めて開示した秘密情報について、当社の事前の書面による承諾がある場合を除いて、第三者に提供、開示又は漏えいをしたり、当社が開示した目的の範囲外での利用を行ったりしてはならないものとします。"
            ]
        },
        {
            "title": "（サービス提供の一時停止）",
            "chapters": [
                {
                    "body": [
                        "当社は、以下のいずれかに該当する場合には、登録ユーザーに事前に通知することなく、本サービスの全部又は一部の提供を停止又は中断することができるものとします。",
                        {
                            "items": [
                                "本サービスの提供に係るコンピューター・システムの点検又は保守作業を定期的又は緊急に行う場合",
                                "コンピューター、通信回線が事故や第三者からの攻撃によって停止した場合",
                                "地震、落雷、火災、伝染病の蔓延などの不可抗力によって本サービスの運営ができなくなった場合",
                                "電気通信事業者が電気通信サービスの提供を中断すること又は電気通信事業法第8条の規定に基づき、天災その他の非常事態が発生し、若しくはその恐れがあるため、公共の利益のため緊急を要する通信を優先させる必要があることにより、本サービスを提供できない場合",
                                "その他、当社が停止又は中断を行う必要があると判断した場合"
                            ]
                        }
                    ]
                },
                {
                    "body": [
                        "当社は、本条に基づき当社が行った措置に基づき本利用者に生じた損害について一切の責任を負いません。"
                    ]
                }
            ]
        },
        {
            "title": "（禁止事項）",
            "body": [
                "登録ユーザーは、本サービスの利用に当たり、以下の行為を行ってはならないものとします。なお、登録ユーザーがこれらの行為を行い又は行なう可能性が高いと当社が判断した場合、当社は当該登録ユーザーが行なう行為について差止めを行なうことができるものとします。",
                {
                    "items": [
                        "本規約に違反する行為",
                        "他人（他の登録ユーザーも含みます。以下同じ。）の知的財産権を侵害する行為",
                        "他人の収支情報、牛個体・牛群情報、投薬情報等その他本サービスの利用により知ることができる情報を第三者に開示又は漏洩する行為",
                        "他人のプライバシーを侵害する行為",
                        "他人の名誉・信用等を侵害する行為",
                        "他人の業務を妨害する行為",
                        "他の登録ユーザーをストーカーする行為",
                        "公序良俗に反する行為",
                        "犯罪的行為又は犯罪的行為に結びつく行為",
                        "事実に反する情報を提供する行為",
                        "当社や本サービスの運営を妨げたり、信用を毀損する行為",
                        "当社の管理するサーバーに対して、コンピュータウィルスなどの有害なプログラムを配信する行為",
                        "本サービス及び本サービスに関連して使用されている全てのソフトウェア又はプログラム等についてリバースエンジニアリング、逆コンパイル、逆アセンブルその他改変等を行う行為",
                        "IDやパスワードを不正に使用する行為",
                        "当社の書面による事前の承諾なく、本規約に基づく権利又は義務を第三者に譲渡し、又は担保に供する行為",
                        "法令に違反する行為又は法令に違反するおそれのある行為",
                        "その他、当社が不適切であると判断する行為"
                    ]
                }
            ]
        },
        {
            "title": "（利用停止等）",
            "chapters": [
                {
                    "body": [
                        "登録ユーザーが、以下の各号の一つに該当する事由が生じた場合、当社は、当該登録ユーザーに対して事前に通知することなく、当該登録ユーザーによる本サービスの利用を一時的に停止し、又は利用契約を解除することができるものとします。また、登録ユーザーが、以下の各号の一つに該当する場合、当社は、当該登録ユーザーに対し情報アクセス権を付与した組織のオーナーユーザーに対して連絡を行ったりする等の適切な措置をとることができ、また当社とオーナーユーザー間の利用契約の解除及び当該利用者の利用を禁止することができるものとします。なお、当社は利用停止又は契約解除等の措置を行った理由について、当該利用者に開示する義務を負いません。",
                        {
                            "items": [
                                "登録ユーザーが本規約の何れかの条項に違反したとき",
                                "登録ユーザーが本サービス上で登録した情報又は当社に提供した情報に虚偽の事実があることが判明したとき",
                                "登録ユーザーが当社のコンピューターに保存されているデータを当社に無断で閲覧、変更若しくは破壊したとき、又はそのおそれがあると当社が判断したとき",
                                "登録ユーザーの本サービス利用態様が公序良俗に反し又は本サービスの利用者としてふさわしくないと当社が判断したとき",
                                {
                                    "text": "登録ユーザーが自ら又は第三者を利用して、次のAないしEのいずれかに該当する行為を行ったとき",
                                    "childList": [
                                        "暴力的な要求行為",
                                        "法的な責任を超えた不当な要求行為",
                                        "取引に関して、脅迫的な言動をし、又は暴力を用いる行為",
                                        "風説を流布し、偽計を用い又は威力を用いて相手方の信用を毀損し、又は相手方の業務を妨害する行為",
                                        "その他、AないしDのいずれかに準ずる行為"
                                    ]
                                },
                                "当社に対する重大な背信行為があったとき",
                                "その他、当社が当該登録ユーザーに対し本サービスの提供を継続することが不適当であると判断したとき"
                            ]
                        }
                    ]
                },
                {
                    "body": [
                        "オーナーユーザーが、以下の各号の一つに該当する事由が生じた場合、当社は、当該オーナーユーザーに対して事前に通知することなく、当該オーナーユーザーとの間の本サービスの利用契約を解除し、又は当該オーナーユーザー並びに当該オーナー契約に基づき利用を行なっている管理ユーザー又は一般ユーザーによる本サービスの利用を一時的に停止する等の適切な措置をとることができるものとします。",
                        {
                            "items": [
                                "本規約に違反したとき",
                                "手形又は小切手の不渡りが発生したとき",
                                "差押え、仮差押え、仮処分その他の強制執行又は滞納処分又は滞納処分の申し立てを受けたとき",
                                "破産、民事再生、会社更生又は特別清算の申し立てがされたとき",
                                "解散又は営業停止状態となったとき",
                                "オーナーユーザーの経済状態に重大な変化が生じたと当社が判断したとき",
                                "その他、当社が当該オーナーユーザーに対し本サービスの提供を継続することが不適当であると判断したとき"
                            ]
                        }
                    ]
                },
                {
                    "body": [
                        "第1項各号又は前項各号のいずれかの事由に該当した場合、登録ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払いを行わなければなりません。"
                    ]
                },
                {
                    "body": [
                        "前項に定める措置は、当社から当該登録ユーザーに対する損害賠償請求を行うことを妨げるものではありません。なお、前項に定める措置を行ったことについて、当社は一切の損害賠償義務を負いません。"
                    ]
                },
                {
                    "body": [
                        "オーナーユーザーと当社との間の利用契約が終了した場合、当該契約に基づき利用を行なっている管理ユーザー又は一般ユーザーは、当該オーナー契約の範囲内において、本サービスを一切利用することができなくなります。なお、本項に定める措置を行ったことについて、当社は一切の損害賠償義務を負いません。"
                    ]
                }
            ]
        },
        {
            "title": "（サービスの変更・追加・廃止）",
            "chapters": [
                {
                    "body": [
                        "当社は、理由のいかんを問わず、登録ユーザーに事前に通知することなく、いつでも本サービスの内容の全部又は一部を変更又は追加することができるものとします。"
                    ]
                },
                {
                    "body": [
                        "当社は、登録ユーザーに通知の上、本サービスの全部又は一部を中止又は廃止することができるものとします。ただし、本サービスの全部又は一部を中止又は廃止する緊急の必要性がある場合は、事前の通知を行うことなく、中止又は廃止をすることができるものとします。"
                    ]
                },
                {
                    "body": [
                        "当社は、本サービスを中止又は廃止したことにより登録ユーザー又は第三者に生じた損害について、一切責任を負わないものとします。"
                    ]
                }
            ]
        },
        {
            "title": "（本規約の基づく地位の譲渡等）",
            "chapters": [
                {
                    "body": [
                        "登録ユーザーは、当社の書面による事前の承諾なく、本規約に基づく権利又は義務につき、第三者に対し、譲渡、移転、担保設定及びその他の処分をすることはできないものとします。"
                    ]
                },
                {
                    "body": [
                        "当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い利用契約及びオーナーユーザー契約上の地位、本規約に基づく権利及び義務、並びに登録ユーザーの登録情報その他の登録ユーザーに関する送信情報（個人情報を含みますが、これに限りません。）を当該事業譲渡の譲受人に譲渡することができるものとし、登録ユーザーはかかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。"
                    ]
                }
            ]
        },
        {
            "title": "（利用終了）",
            "chapters": [
                {
                    "body": [
                        "登録ユーザーは、いつでも本サービスの利用を終了することができます。"
                    ]
                },
                {
                    "body": [
                        "前項の定めにかかわらず、登録ユーザーが本サービスの利用を終了した場合であっても、本規約は有効に存続し、適用されるものとします。"
                    ]
                }
            ]
        },
        {
            "title": "（損害賠償）",
            "chapters": [
                {
                    "body": [
                        "登録ユーザーは、登録ユーザーが法令又は本規約に違反して本サービスを利用したことに起因して、当社が直接的若しくは間接的に何らかの損害、損失又は費用負担（弁護士費用の負担を含みますが、これに限りません。）を被った場合、当社の請求にしたがって直ちにこれを賠償又は補償しなければならないものとします。"
                    ]
                },
                {
                    "body": [
                        "登録ユーザーが、本サービスに関連して他の利用者、その他の第三者からクレームを生じさせ又はそれらの者との間で紛争が生じさせた場合、直ちにその内容を当社に通知するとともに、自己の費用と責任において当該クレーム又は紛争を処理し、当社からの要請に基づき、その経過及び結果を当社に報告しなければならないものとします。"
                    ]
                },
                {
                    "body": [
                        "オーナー契約に基づき利用を行なっている管理ユーザー又は一般ユーザーが、前項に定めるクレーム又は紛争を生じさせた場合、当社は直ちにオーナーユーザーに対して連絡を行うとともに、当該オーナーユーザーの費用と責任において当該クレーム又は紛争を処理し、当社からの要請に基づき、その経過及び結果を当社に報告しなければならないものとします。"
                    ]
                }
            ]
        },
        {
            "title": "（免責）",
            "chapters": [
                {
                    "body": [
                        "当社は、本サービス又は本サービスが提携するサービスの提供中止、停止、故障等により、本利用者又は第三者に損害が生じたとしても、これについて一切の責任を負わないものとします。"
                    ]
                },
                {
                    "body": [
                        "本利用者は、本サービス又は本サービスが提携するサービスにおいて、下記の事情により一定期間、本サービス又は本サービスが提携するサービスの利用が停止される場合があることを予め承諾し、本サービス又は本サービスが提携するサービスの停止による損害の補償等を当社に請求しないこととします。",
                        {
                            "items": [
                                "本サービス又は本サービスが提携するサービスのサーバー、ソフトウェア等の点検、修理、補修等のための停止の場合",
                                "コンピューター、通信回線等の事故による停止の場合",
                                "その他、やむをえない事情による停止が必要であると当社が判断し、停止を行った場合"
                            ]
                        }
                    ]
                },
                {
                    "body": [
                        "当社はいかなる状況においても、またいかなる方に対しても、以下の各号に定める事項について一切責任を負いません。",
                        {
                            "items": [
                                "本サービスを通じて提供される情報の入手、収集、編纂、解釈、分析、編集、翻訳、送付、伝達、配布に関わる誤り（当社の不注意によるか、その他によるかを問いません。）又はその他の状況により（全部、一部を問わず）引き起こされ、発生し、若しくはこれらに起因する損失又は損害",
                                "本サービスを通じて提供される情報の使用又は使用不可能により発生する、あらゆる種類の直接的、間接的、特別、二次的、又は付随的な損害（このような損害の可能性について当社が事前に通告を受けたかどうかを問いません。）"
                            ]
                        }
                    ]
                },
                {
                    "body": [
                        "本サービスを通じて、本利用者が取得する情報については、その完全性、正確性、確実性、有効性、安全性、合目的性等につき、すべて本利用者の自己の責任と判断のもとで利用するものとし、その内容から発生するあらゆる問題について当社は一切の責任を負いません。"
                    ]
                },
                {
                    "body": [
                        "本ウェブサイトから他のウェブサイトへのリンク又は他のウェブサイトから本ウェブサイトへのリンクが提供されている場合でも、当社は、本ウェブサイト以外のウェブサイト及びそこから得られる情報関していかなる理由に基づいても一切の責任を負わないものとします。"
                    ]
                },
                {
                    "body": [
                        "本サービスからリンクされているサイトの提供する商品、サービス及び全ての情報等の完全性、正確性、確実性、有効性、安全性、適法性、合目的性等、及びその内容から発生するあらゆる問題について当社はいかなる保証もせず、一切の責任を負いません。"
                    ]
                },
                {
                    "body": [
                        "本サービス又は本サービスが提携するサービスにおいて、本利用者間に生じたトラブル、損害その他の一切の事項に対して、当社はいかなる責任も負わず、補償を行いません。ただし、当社は、本サービスのために必要と判断した場合、当該トラブルに介入できますが、この場合でも、当該トラブルに関して一切の責任を負わないものとし、損害その他の一切の事項に対して、当社はいかなる責任も負わず、補償を行いません。"
                    ]
                },
                {
                    "body": [
                        "当社は、当社が提供するサービス又は本サービスが提携するサービスの、デザイン等の仕様を予告なく変更することができるものとし、当該変更によって本利用者に生じた損害について一切責任を負わないものとします。"
                    ]
                },
                {
                    "body": [
                        "当社は、本利用者が本サービスを利用したことにより、本利用者又は第三者に損害が生じたとしても、これについて一切の責任を負わないものとします。"
                    ]
                },
                {
                    "body": [
                        "当社は、当社による本サービスの提供の中断、停止、終了、利用不能又は変更、本利用者に関する情報の削除又は消失、本利用者の本サービスの利用によるデータの消失又は機器の故障若しくは損傷、その他本サービスに関連して生じた損害につき、賠償する責任を一切負わないものとします。"
                    ]
                },
                {
                    "body": [
                        "当社は、本利用者が本サービスの利用に伴って負担するあらゆるデータ通信料について一切の責任を負わないものとします。"
                    ]
                },
                {
                    "body": [
                        "本利用者は、本サービスで接続を行う他社サービスのシステム又は仕様変更により、本サービスが利用できなくなる可能性について予め了解の上で、本サービスを利用することとし、これにより本利用者に損害が生じた場合であっても当社は一切の責任を負わないものとします。"
                    ]
                },
                {
                    "body": [
                        "本規約に定める免責条項が適用されない等の理由により、当社が本利用者又は第三者に対して責任を負うべき場合、それらの責任に基づく損害賠償額はいかなる場合でも当該本利用者が本サービスの利用に際して当社に対し過去3か月間以内に現実に支払った利用料金の合計額を上限額とすることに、当社及び本利用者は予め合意します。なお、本利用者は、本利用者がこの上限額の定めに同意しない限り当社が本サービスを提供しないことを十分に理解の上で、予め同意し承諾するものとします。"
                    ]
                }
            ]
        },
        {
            "title": "（反社会的勢力への不関与）",
            "chapters": [
                {
                    "body": [
                        "登録ユーザーは、過去及び現在において、自身が暴力団、暴力団員、暴力団関係企業、総会屋、社会運動標ぼうゴロ、政治運動標ぼうゴロ、特殊知能暴力集団、その他反社会的勢力（以下｢暴力団等反社会的勢力｣といいます。）に所属又は該当せず、かつ、暴力団等反社会的勢力と関与していないことを表明し、将来にわたっても所属若しくは該当、又は関与しないことを確約するものとします。"
                    ]
                },
                {
                    "body": [
                        "当社は、登録ユーザーが暴力団等反社会的勢力に所属若しくは該当する、又は正当な理由なく関与していると判断した場合、当該登録ユーザーに事前に通知等を行うことなく、当該登録ユーザーの本サービスの利用を禁止する等の措置を講じることがあります。"
                    ]
                },
                {
                    "body": [
                        "当社は前項の措置による登録ユーザーの損害を賠償する責任を一切負わないものとします。"
                    ]
                }
            ]
        },
        {
            "title": "（本規約の変更）",
            "chapters": [
                {
                    "body": [
                        "当社は、次のいずれかに該当する場合、登録ユーザーの承諾を得ることなく、当社が適当と判断する方法で登録ユーザーに通知することにより、本規約を変更できるものとします。なお、当社は変更の内容を登録ユーザーに個別に通知することは要さず、登録ユーザーが自らの責任において本サービス利用の際、随時、最新の本規約を確認することとします。",
                        {
                            "items": [
                                "本規約の変更が、登録ユーザーの一般の利益に適合する場合",
                                "本規約の変更が、契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものである場合"
                            ]
                        }
                    ]
                },
                {
                    "body": [
                        "前項の定めにかかわらず、当社は、本規約の重要な変更については、変更後の利用規約（以下「変更後規約」といいます。）の効力発生日の1か月前までに、本規約を変更する旨及び変更後規約の内容とその効力発生日を本サービスサイト上に掲示し、又は登録ユーザーに電子メールで通知します。"
                    ]
                },
                {
                    "body": [
                        "登録ユーザーに本規約の重要な変更により著しい悪影響が及ぶ場合、当該登録ユーザーは前項の定める通知の受領後1か月以内に、当該変更に異議がある旨を当社に通知することができます。"
                    ]
                },
                {
                    "body": [
                        "変更後規約の効力発生日以降に登録ユーザーが異議を述べることなく本サービスを利用した場合、又は前項に定める期間内に異議を述べなかった場合、当該登録ユーザーは、利用規約の変更に同意したものとみなします。"
                    ]
                }
            ]
        },
        {
            "title": "（分離可能性）",
            "body": [
                "本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りのその他の条項、及び一部が無効又は執行不能と判断された条項の残りの部分は、継続して完全にその効力を有するものとします。"
            ]
        },
        {
            "title": "（準拠法、合意管轄）",
            "body": [
                "登録ユーザーと当社との間における紛争については、日本国法を適用し、東京地方裁判所又は札幌地方裁判所を第一審の専属的合意管轄裁判所とします。また、本規約は、日本国法に準拠し、解釈されるものとします。"
            ]
        }
    ],
    "footer": [
        "【附則】",
        "本規約は2020年10月1日から施行します。"
    ]
}
 

export const PrivacyPolicy: IPrivacyPolicy = {
    "title": "プライバシーポリシー",
    "summaries": [
        "株式会社VETELL（以下「当社」といいます。）は、当社が提供するサービス「vetell」（以下「本サービス」といいます。）において当社が取得する個人情報を適切に管理運用するために遵守するべき基本的事項として、以下のとおりプライバシーポリシー（以下「本プライバシーポリシー」といいます。）を定め、個人情報を適正に取り扱います。"
    ],
    "chapters": [
        {
            "title": "個人情報の取得",
            "body": [
                "本プライバシーポリシーにおいて、「個人情報」とは、個人情報保護法第2条第1項に定める個人情報をいいます。当社は、個人情報の取得に際しては、あらかじめ取得目的を正当な事業の範囲内で明確に定め、その目的達成に必要な限度において、適法かつ公正な方法で取得し、利用します。"
            ]
        },
        {
            "title": "個人情報の利用目的",
            "body": [
                "当社は、本サービスにおいて取得した個人情報については、以下の利用目的の範囲内に限って利用いたします。もし、利用目的の範囲を超えて利用するときは、予めご本人から同意を取得します。なお、当社は、変更前の利用目的と関連性を有すると合理的に認められる範囲内において個人情報の利用目的を変更することがありますが、利用目的を変更した場合には速やかに通知又は公表します。",
                {
                    "items": [
                        "本サービスを提供するため",
                        "本サービスの利便性向上を図るため",
                        "本サービス利用に関する統計データを作成や、マーケティングを行うため",
                        "現行サービス又は新規に提供を検討しているサービスに関するアンケート等の実施のため",
                        "本サービスに関する新企画等の検討や提供をするため",
                        "利用規約に違反した方や、不正・不当な目的で本サービスを利用しようとされる方のご利用をお断りするため",
                        "お問い合わせへの対応や本人確認を行うため",
                        "当社からの情報提供（当社の提供する新サービスや新商品又は、当社がお勧めする他社の新サービスや新商品の情報提供を含みます。）及びメールマガジン等を送信するため",
                        "その他本サービスに関するお知らせ等、本サービスをご利用いただくにあたって、必要に応じて連絡を行うため",
                        "契約や法律等に基づく権利の行使や義務の履行のため",
                        "上記の利用目的に付随する業務の履行のため"
                    ]
                }
            ]
        },
        {
            "title": "個人情報の開示について",
            "body": [
                "当社は、個人情報保護法その他の法令に基づき開示・提供が認められる場合を除くほか、本人に同意を得ることなく、個人情報を第三者に開示・提供することはいたしません。"
            ]
        },
        {
            "title": "個人情報の閲覧・修正等について",
            "body": [
                "当社は、ご本人より個人情報の開示、訂正、利用停止、削除のご依頼があった場合、ご本人様であることを確認させていただいたうえで、対応いたしますので、末尾に記載のお問合せ窓口よりご連絡ください。",
                "なお、不合理に繰り返される要請、過度の技術的努力を要する要請、他者のプライバシーを害するおそれのある要請等、当社が不当な要請であると判断する要請についてはお断りすることがあります。"
            ]
        },
        {
            "title": "クッキー及びIPアドレス情報について",
            "body": [
                "当社は、利用者の方が、本サービスをご利用になった際に、当社のクッキーを保存及び参照し、IPアドレス、ご覧になった広告・ページ、ご利用環境等の情報を自動的に取得し、サーバーに記録することがあります。",
                "当社は、この方法により取得した情報を、本サービスをより良いサービスに変えていくため、及び新サービスの検討用の資料として活用させていただいたり、広告主や情報提供元等に対して、どのような広告や情報及びサービスなどを掲載又は提供することが効果的であるかを分析し提供したりするために利用させていただきます。なお、個人が識別できるような情報が分析結果に含まれることはありません。"
            ]
        },
        {
            "title": "アクセスログ情報について",
            "body": [
                "Google Analyticsを使用して本サービスを利用した利用者のアクセスログ（履歴）の収集・解析をすることがあります。Google Analyticsではクッキーを使用し、個人を特定する情報を含まずにログを収集しております。これらのアクセスログは、本サービスをより利用者の方に使いやすい良いサービスに変えていくため及び新サービスの検討用の資料として活用させていただいたり、サーバーの稼動状況や統計障害情報の分析及びWebマーケティングに利用させていただくものとし、それ以外の目的で利用されることはございません。",
                "なお、収集されるログはGoogle社のプライバシーポリシーに基づいて管理されますのでGoogle社のプライバシーポリシー(https://www.google.com/intl/ja/policies/privacy/)についてご確認ください。"
            ]
        },
        {
            "title": "個人情報取扱いの委託",
            "body": [
                "当社は、事業運営上、個人情報の取扱いを外部に委託することがあります。この場合、当社は、委託先を監督し、適切な個人情報の管理を実施させます。"
            ]
        },
        {
            "title": "統計処理されたデータの利用",
            "body": [
                "当社は、提供を受けた個人情報をもとに、個人を特定できないよう加工した統計データを作成することがあります。個人を特定できないよう加工した統計データについては、当社は当社の裁量により自由に利用することができるものとします。"
            ]
        },
        {
            "title": "安全管理体制",
            "body": [
                "当社は、個人情報の漏えい、滅失又は毀損の防止その他個人情報の保護のため、当社役員を含むすべての従業員に対する教育の実施や研修等を行い、さらにシステム面でも個人情報ファイルへのアクセス制限の実施、アクセスログの記録及び外部からの不正アクセス防止のためのセキュリティ対策の実施等、個人情報の安全管理のための必要かつ適切な措置を講じます。"
            ]
        },
        {
            "title": "プライバシーポリシーの範囲",
            "body": [
                "本プライバシーポリシーは、当社の業務提携先企業・業務委託先企業・当社の運営するウェブサイト内のリンク先のサイト等には適用されません。それらのサイト等において、トラブルが発生した場合は、当社は一切責任を負いません。各サイト等の個人情報の取扱いについてご確認下さい。"
            ]
        },
        {
            "title": "プライバシーポリシーの改定",
            "body": [
                "当社は、個人情報の取り扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて本プライバシーポリシーを改定することがあります。 プライバシーポリシーの変更については、個別に通知又は本サービス内で皆様に告知する方法で通知いたします。"
            ]
        },
        {
            "title": "お問合せ窓口",
            "body": [
                "当社の個人情報の取扱いに関するご質問や苦情等のお問い合わせについては、下記の窓口までご連絡ください。",
                "［個人情報についてのお問い合わせ先］",
                "株式会社VETELL　 E-mail：info@vetell.jp"
            ]
        }
    ],
    "footer": [
        "2020年10月1日　制定・施行"
    ]
}