import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ExecutionButton } from 'components/buttons/execution-button';
import { LMT } from 'config/constant';
import { useCowFilter } from 'stores/fetcher_cow';
import { FetchWaiter, FetchError } from 'components/content/fetch-state';

export interface SaveListPopupProps {
    onClose:()=>void;
    isSubmitExecuting: boolean;
    filterNo: number|undefined;
    filterName: string;
    ranchId: number;
    onSubmit: (filterNo: number|undefined, filterName: string) => void;
}

export const SaveListPopup: React.FC<SaveListPopupProps> = (props) => {
    const { data, isLoading, isError } = useCowFilter(props.ranchId);

    const [ isNew, setIsNew ] = useState(true);
    const [ currentName, setCurrentName ] = useState("");

    const onSubmit = () => {
        const no = isNew ? undefined : props.filterNo;
        const name = isNew ? currentName : props.filterName;
        props.onSubmit(no, name);
    }

    const canSubmit = (!isNew || currentName !== "")
            && data != null
            && (!isNew || data.list.length < LMT.COW.FILTER_COUNT_MAX);

    return (
        <div>
            <Modal isOpen={true} className="modal-slim">
                <ModalHeader toggle={props.onClose}>絞り込み条件リスト保存</ModalHeader>
                <ModalBody>
                    { isLoading ? (
                        <FetchWaiter />
                    ) : (isError || data == null) ? (
                        <FetchError />
                    ) : (<>
                        { props.filterNo != null && (
                            <div className="form-group">
                                <div className="radio radio-css">
                                    <input type="radio" id="rad_save_update" checked={!isNew}
                                        onChange={() => setIsNew(false)}/>
                                    <label htmlFor="rad_save_update">選択中のリストに上書き</label>
                                </div>
                                <div className="m-l-20 m-t-5" style={{ wordBreak:"break-all" }}>リスト名：<span data-testid="selected-list-name">{props.filterName}</span></div>
                            </div>
                        )}
                        <div className="form-group">
                            <div className="radio radio-css">
                                <input type="radio" id="rad_save_new" checked={isNew}
                                    onChange={() => setIsNew(true)} />
                                <label htmlFor="rad_save_new">名前をつけて新規保存</label>
                            </div>
                            { data.list.length < LMT.COW.FILTER_COUNT_MAX ? (
                                <input type="text" className="form-control m-t-5"
                                    disabled={!isNew}
                                    onChange={e => setCurrentName(e.target.value)}
                                    maxLength={LMT.COW.FILTER_NAME_LEN}
                                    value={currentName}
                                />
                            ) : (
                                <div className="m-l-20 m-t-5" style={{ color:"red" }}>作成可能な件数上限を超過しています</div>
                            )}
                        </div>
                    </>)}
                </ModalBody>
                <ModalFooter className="modal-footer-fix">
                    <ExecutionButton type="save" disabled={!canSubmit || props.isSubmitExecuting}
                            onClick={onSubmit} />
                </ModalFooter>
            </Modal>
        </div>
    )
}