import React, { useState } from 'react';
import { FreezedArray } from '../../config/util';
import { ICowUse } from '../../stores';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { OptionalNumInput } from '../../components/parts/num-input';

interface MyProps {
    useList: FreezedArray<ICowUse>;
    onSubmit: (data: Map<number, number>) => void;
    onClose: () => void;
    min: number;
    max: number;
    step: number;
}

export const DynamicsBulkModal = (props: MyProps) => {
    const [ data, setData ] = useState<Map<number, number>>(new Map());

    const styles: { [key:string]: React.CSSProperties } = {
        row: {
            display: "flex",
            alignItems: "center",
            padding:"4px 0"
        },
        label: {
            width: "74px",
            textAlign: "right",
            marginRight: "8px"
        },
        input: {
            width: "100px"
        },
        footer: {
            display: "flex",
            justifyContent: "center"
        }
    };

    return (
        <div>
            <Modal isOpen={true} className="modal-slim">
                <ModalHeader toggle={props.onClose}>まとめて入力</ModalHeader>
                <ModalBody style={{ overflowY: "auto", maxHeight: "calc(100vh - 180px)" }}>
                    { props.useList.map(u => (
                        <div key={u.use_no} style={styles.row}>
                            <div style={styles.label}>{u.name}</div>
                            <OptionalNumInput
                                style={styles.input}
                                value={data.get(u.use_no)}
                                step={props.step} min={props.min} max={props.max}
                                onChange={n => {
                                    const newData = new Map(data);
                                    newData.delete(u.use_no);
                                    if (n != null) {
                                        newData.set(u.use_no, n);
                                    }
                                    setData(newData);
                                }}
                            />
                        </div>
                    ))}
                </ModalBody>
                <ModalFooter style={styles.footer}>
                    <button className="btn btn-success"　onClick={() => props.onSubmit(data)}>確定</button>
                </ModalFooter>
            </Modal>
        </div>
    )
}