import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styles from './RegisterCows.module.css';
import baseStyles from './otherpl.module.css';
import { RequiredNumInput } from '../../components/parts/num-input';
import { LMT } from '../../config/constant';

type PropType = {
    onClose : () => void;
    onSubmit: (price: number) => void;
}

export const PricePopup: React.FC<PropType> = (props) => {

    const [price, setPrice] = useState(0);

    return (
        <div>
            <Modal isOpen={ true } className="modal-slim" centered={true}>
                <ModalHeader toggle={() => props.onClose()}>金額</ModalHeader>
                <ModalBody>
                    <div className={styles["modal-body-row"]}>
                        <RequiredNumInput
                            className={baseStyles["number-input"]}
                            min={LMT.OTHER_PL.PRICE_MIN}
                            max={LMT.OTHER_PL.PRICE_MAX}
                            step={LMT.OTHER_PL.PRICE_STEP}
                            value={price}
                            onChange={v => setPrice(v)}
                        />
                        <span className={baseStyles["label-unit"]}>円</span>
                    </div>
                </ModalBody>
                <ModalFooter className={styles["modal-footer"]}>
                   <button className="btn btn-success" onClick={() => props.onSubmit(price)}>全頭に反映</button>
                </ModalFooter>
            </Modal>
        </div>
    )
}