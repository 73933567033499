import React, { useState, ReactNode } from 'react';
import cowIcon from '../../assets/icon/cow.svg';
import ToolTip from 'rc-tooltip';
import { CowToDispInfo, LocalNoOrRepNo } from './cow-to-disp-info';
import styles from './cows-popup.module.css';
import classnames from 'classnames';
import { ActivityCowDto } from '../../api';
import { FreezedArray } from '../../config/util';
import { useActivityCows } from '../../stores/fetcher_activity';
import { FetchWaiter, FetchError } from '../content/fetch-state';

export * from './cow-to-disp-info';

export interface CowsPopupContainerProps {
    cows: Readonly<ICheckablePopupCow[]>;
    placement: "bottom" | "top" | "left" | "right";
    /**
     * チェック不可モードのときは、牛の行をクリックした際に発生する。
     * チェック可能モードでは、チェックされた牛の >> をクリックした際に発生する（ハンドラがundefinedの場合は >> を表示しない）
     */
    onCowClick?: (cow:IPopupCow) => void;
    isCheckedChanged?: (cowId: number, index: number, checked: boolean) => void;
    /**
     * チェックボックスを表示するかどうか
     */
    isCheckable?: boolean;
    /**
     * 同一画面内に複数配置する場合のid区別用
     */
    prefix?: string;
    children:ReactNode;
}
export interface CowsPopupProps extends Omit<CowsPopupContainerProps, "children"> {
    height?: string;
}
export interface IPopupCow extends ICowNameInfo {
    watching: number;
    watching_memo: string;
}
export interface ICheckablePopupCow extends IPopupCow {
    isChecked?: boolean;
}
export interface ICowNameInfo {
    cow_id: number;
    local_no?: string;
    trace_id: string;
    name?: string;
}

const sortCows = <T extends ICowNameInfo>(cows: FreezedArray<T>) => {
    return cows.slice().sort((a,b) => {
        const valA = LocalNoOrRepNo(a);
        const valB = LocalNoOrRepNo(b);
        return valA === valB ? 0 : valA < valB ? -1 : 1;
    });
}

interface ActivityCowsPopupContainerProps {
    placement: "bottom" | "top" | "left" | "right";
    onCowClick?: (cow:ICowNameInfo) => void;
    children:ReactNode;
    cowCount: number;
    cows: ActivityCowDto[];
    activityId:number;
    ranchId: number;
}
export const ActivityCowsPopupContainer = React.memo((props: ActivityCowsPopupContainerProps) => {
    const [ isTooltipVisible, setIsTooltipVisible ] = useState(false);

    const { data, isError, isLoading } = useActivityCows(props.ranchId, props.activityId, props.cows, props.cowCount);

    const onCowClick = (cow: ICowNameInfo) => {
        if (props.onCowClick) {
            setIsTooltipVisible(false);
            props.onCowClick(cow);
        }
    }

    let cows = (data ?? [])
                .map(c => ({ ...c, trace_id:c.trace_id ?? ""}))
                .filter(c => c.trace_id !== "");
    cows = sortCows(cows);

    const cowTestId = (cow: ICowNameInfo) => `popcow__${CowToDispInfo(cow, false)}`;

    return (
        <ToolTip placement={props.placement} trigger={['click']}
            visible={isTooltipVisible}
            onVisibleChange={v => setIsTooltipVisible(v ?? false)}
            overlay={
            (<div className={styles["overlay-root"]}>
                {
                    isLoading ? (
                        <FetchWaiter mode="dark" size="small" />
                    ) : isError ? (
                        <FetchError />
                    
                    ) : cows.map((c) => (
                        <div key={c.cow_id} className={classnames(styles["overlay-row"], { [styles.clickable]: props.onCowClick != null })}
                            onClick={() => onCowClick(c)} data-testid={cowTestId(c)}>
                            <span>{CowToDispInfo(c)}</span>
                        </div>
                    ))
                }
            </div>)
        }>
            { props.children }
        </ToolTip>
    );
});

export const CowsPopupContainer = (props:CowsPopupContainerProps) => {
    const [ isTooltipVisible, setIsTooltipVisible ] = useState(false);

    const onCowClick = (cow: IPopupCow) => {
        if (props.onCowClick) {
            setIsTooltipVisible(false);
            props.onCowClick(cow);
        }
    }

    const list = sortCows(props.cows);

    const cowTestId = (cow: ICheckablePopupCow) => `popcow__${CowToDispInfo(cow, false)}`;

    const checkboxId = (cowId:number) => `${props.prefix ?? ""}chkCow${cowId}`;

    return (
        <ToolTip placement={props.placement} trigger={['click']}
            visible={isTooltipVisible}
            onVisibleChange={v => setIsTooltipVisible(v ?? false)}
            overlay={
            (<div className={styles["overlay-root"]}>
                {
                    list.map((c,i) => props.isCheckable ? 
                    (<div key={c.cow_id} className={classnames(styles["overlay-row"], styles.checkable, { [styles.clickable]: props.onCowClick != null })}
                        data-testid={cowTestId(c)}>
                        <input type="checkbox" id={checkboxId(c.cow_id)}
                            checked={c.isChecked === true}
                            onChange={e => props.isCheckedChanged && props.isCheckedChanged(c.cow_id, i, e.target.checked)}
                        />
                        <label htmlFor={checkboxId(c.cow_id)}>
                            <span>{CowToDispInfo(c)}</span>
                            { (c.watching === 1 || c.watching_memo !== "") && (
                                <i className={classnames("fas fa-exclamation", styles.watching, { [styles.memo]:c.watching !== 1 })}></i>
                            )}
                        </label>
                        { props.onCowClick && (
                            <div className={classnames("clickable", styles.single, { [styles.hidden]:c.isChecked !== true })}
                                onClick={() => onCowClick(c)}
                                >
                                <i className="fas fa-angle-double-right"></i>
                            </div>
                        )}
                    </div>)
                    :
                    (<div key={c.cow_id} className={classnames(styles["overlay-row"], { [styles.clickable]: props.onCowClick != null })}
                        onClick={() => onCowClick(c)} data-testid={cowTestId(c)}>
                        <span>{CowToDispInfo(c)}</span>
                        { (c.watching === 1 || c.watching_memo !== "") && (
                            <i className={classnames("fas fa-exclamation", styles.watching, { [styles.memo]:c.watching !== 1 })}></i>
                        )}
                    </div>))
                }
            </div>)
        }>
            { props.children }
        </ToolTip>
    );

}

export class CowsPopup extends React.Component<CowsPopupProps,{}> {

    render() {

        const imgStyle = {
            height: this.props.height ?? "28px",
            cursor: "pointer"
        };

        return (
            <CowsPopupContainer {...this.props}>
                <img src={cowIcon} style={imgStyle}/>
            </CowsPopupContainer>
        );
    }
}

