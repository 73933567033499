import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { IFecesColor } from '../../stores';
import { FreezedArray } from '../../config/util';

interface MyProps {
    isOpen: boolean;
    colors: FreezedArray<IFecesColor>;
    onClose: () => void;
    onResult: (color: string) => void;
}

interface MyState {
    ui_colors: { 
        title: string,
        borderColor: string,
        colors: IFecesColor[];
    }[];
}

export class FecesColorPopup extends React.Component<MyProps, MyState> {

    constructor(props) {
        super(props);

        this.state = {

            ui_colors: [
                {
                    title: "哺乳期",
                    borderColor: "#ffe699",
                    colors: [],
                },
                {
                    title: "哺乳〜配合飼料移行期/肝機能低下時",
                    borderColor: "#ffc000",
                    colors: [],
                },
                {
                    title: "配合飼料主体",
                    borderColor: "#bf8f00",
                    colors: [],
                },
                {
                    title: "粗飼料主体",
                    borderColor: "#548235",
                    colors: [],
                },
                {
                    title: "血便",
                    borderColor: "#ff0000",
                    colors: [],
                },
            ],
        }

        for (var i = 0; i < this.props.colors.length; i++) {
            let feces_color = this.props.colors[i];

            if (feces_color.disp_no === 0 || feces_color.disp_no > 25) {
                continue;
            }

            let idx = Math.floor((feces_color.disp_no - 1) / 5);
            this.state.ui_colors[idx].colors.push(feces_color);
        }

        console.log(this.state.ui_colors);

        this.onCloseWithResult = this.onCloseWithResult.bind(this);
    }

    onCloseWithResult(color: string) {
        if (color === "00000000") {
            return;
        }

        this.props.onResult(color);
    }

    render() {
        const state = this.state;
        return (
            <div className="modal-wide">
                <Modal isOpen={this.props.isOpen} style={{ maxWidth: "400px" }}>
                    <ModalHeader toggle={this.props.onClose}>便の色を選択</ModalHeader>
                    <ModalBody style={{ overflowY: "auto", maxHeight: "calc(100vh - 30px)", backgroundColor: "#f0f0f0" }}>
                        <div className="checkout-body">

                            <div className="form-group row" style={{ display: "flex" }}>
                                {
                                    state.ui_colors.map((color_group, i) => (
                                        <div key={i}
                                            style={{
                                                flex: "1",
                                                marginLeft: "2px", marginRight: "2px",
                                                borderColor: color_group.borderColor, borderWidth: "1px", borderStyle: "solid"
                                            }}>
                                            <div className="color-div-center">
                                                <span className="color-span-center">{color_group.title}</span>
                                            </div>
                                            {
                                                color_group.colors.map((value, j) => (
                                                    <div key={j} style={{ display: "block" }}
                                                        className="m-l-5 m-r-5 m-t-5"
                                                        onClick={() => { this.onCloseWithResult(value.feces_color) }}>
                                                        <div style={{
                                                            width: "100%", height: "20px",
                                                            borderColor: "black", borderWidth: "1px", borderStyle: "solid",
                                                            backgroundColor: "#" + value.feces_color
                                                        }}>
                                                        </div>
                                                        <p style={{ fontSize: "12px", height: "35px", textAlign: "center", marginBottom: "0px" }}>{value.name}</p>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}