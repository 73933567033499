import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import moment from 'moment'

import { OtherPlApi, CowDetailDto, OtherPlModifyReq, OtherPlDeleteReq, OtherPlDto } from '../../api'

import { useRootStore, IUser } from '../../stores';
import { usePageStore } from '../../config/page-settings';

import styles from './otherpl.module.css'
import RegisterRanch from './RegisterRanch';
import RegisterCow from './RegisterCow';
import RegisterCows from './RegisterCows';
import { IEventWriteLocationState, historyLocation } from '../../config/history-location-builder';
import { CommonUtil } from '../../config/util';
import { hasRanchAuth } from '../../config/auth-checker';
import { Communicator } from '../../api/communicator';
import { ICowNameInfo } from '../../components/parts/cows-popup';
import { AppState } from '../../app';
import { FormRadio } from '../../components/form/form-radio';
import { EVENT_KIND } from '../../config/event-kind';
import { A } from '../../config/constant';
import { ExecutionButton } from 'components/buttons/execution-button';
import { DIALOG_BUTTONS } from 'components/form/form-dialog';

type TargetType = 'ranch' | 'cow' | 'cows'
export const PL_TYPE_PROFIT = 1;
export const PL_TYPE_LOSS = 2;
export type ProfitLoss = 1 | 2;

export interface EditingOtherPlData {
  watchedAt: Date;
  profitLoss: ProfitLoss;
  details: EditingOtherPlDetailData[];
}
export interface EditingOtherPlDetailData {
  cow_id?: number;
  name?: string;
  trace_id?: string;
  local_no?: string;
  price: number;
  comment: string;
}

const getParamCowIds = (url:string) => {
  const parm = new URLSearchParams(url).get("param");
  if (parm == null) return [];

  return parm.split(",")
              .map(p => parseInt(p))
              .filter(p => !isNaN(p));
}

const getParamCowTop = (url:string) => {
  const parm = new URLSearchParams(url).get("cow_top");
  if (parm == null) return false;

  return parm === "1";
}

export type OtherPlContentProps = {
  data: EditingOtherPlData,
  onChange: (data: EditingOtherPlData) => void;
  user: IUser;
  ranchId: number;
}

export const PlTypeSelector: React.FC<{ value: ProfitLoss, onChange: (value: ProfitLoss) => void }> = ({ value, onChange }) => {

  return (
    <FormRadio prefix="radPLType"
      options={[{ name:"雑収入", value: PL_TYPE_PROFIT }, { name:"雑損失", value: PL_TYPE_LOSS }]}
      value={value}
      onChange={v => onChange(v as ProfitLoss)}
      isInline={true}
    />
  )
}

export default () => {

  const rootStore = useRootStore()
  const { user, auth, logout } = rootStore

  if ( !auth.enabled ) {
    rootStore.logout();
    return (<></>)
  }

  const { id } = useParams<{id?:string}>()
  const eventId = id == null ? undefined : parseInt(id)
  const location = useLocation<IEventWriteLocationState>()
  const cowIds = getParamCowIds(location.search)
  const isCowTop = getParamCowTop(location.search)
  const cowInfos = location.state?.cows ?? [];

  if(!eventId && location.pathname.match('cow') ) {
    if (cowIds == null || cowIds.length === 0 ) {
      rootStore.logout();
      return (<></>)
    }
    if (cowInfos.length === 0 ) {
      rootStore.logout();
      return (<></>)
    }
  }

  const history = useHistory()

  const { 
    handleSetHeader, handleSetPageError, 
    handleSetFooter, handleSetIsLoading, 
    showToast, postAsync , showModalAsync, showDialog
  } = usePageStore() as AppState;
  
  const [data, setData] = useState<EditingOtherPlData>({
    watchedAt: new Date(),
    profitLoss: PL_TYPE_PROFIT,
    details: []
  });
  const [ executing, setExecuting ] = useState(false);
  const [ original, setOriginal ] = useState<OtherPlDto>();

  const targetType: TargetType = location.pathname.match('ranch') ? 'ranch' : (eventId || cowInfos.length === 1) ? 'cow' : 'cows';

  useEffect(() => {
    handleSetHeader({ title: eventId ? "雑収入・雑損失を編集" : "雑収入・雑損失を入力" })
    handleSetPageError(false)
    handleSetFooter(true)

    const item = targetType === "ranch" ? "BALANCE_RANCH" : "BALANCE_COW";
    if (!hasRanchAuth(item, rootStore.cur_ranch_id, user)) {
      rootStore.logout();
      return;
    }

    init();

  }, [])

  const comm = new Communicator({ logout, showDialog, showToast });


  const init = async () => {
    if (eventId == null) {
      setData({
        ...data,
        details: targetType === "ranch"
          ? [{
            price: 0,
            comment: "",
          }]
          : cowInfos.map(cow => ({
            cow_id: cow.cow_id,
            trace_id: cow.trace_id,
            name: cow.name,
            local_no: cow.local_no,
            price: 0,
            comment: "",
          }))
      })
      return;
    }

    handleSetIsLoading(true);
    try {
      const reqInfo = { ranch_id: rootStore.cur_ranch_id, id: eventId };
      const res = await comm.send((await OtherPlApi()).getOtherPlInfoUsingPOST(reqInfo), { retries: true });
      if (res.result !== "OK" || res.data == null) return;

      if (navToRanchTopIfSelfEditOnly(res.data.profit_loss, res.data.watched_by)) return;
  
      let cowInfo: ICowNameInfo | undefined;
      if (res.data.cow_id != null) {
        cowInfo = await loadCowInfo(res.data.cow_id);
        if (cowInfo == null) return;
      }

      setOriginal(res.data);
  
      setData({
        profitLoss: res.data.profit_loss === PL_TYPE_PROFIT ? PL_TYPE_PROFIT : PL_TYPE_LOSS,
        watchedAt: moment(res.data.watched_at).toDate(),
        details: [{
          price: res.data.price,
          comment: res.data.comment,
          cow_id: cowInfo?.cow_id,
          trace_id: cowInfo?.trace_id,
          local_no: cowInfo?.local_no,
          name: cowInfo?.name,
        }]
      })

    } finally {
      handleSetIsLoading(false);
    }
  }

  const navToRanchTopIfSelfEditOnly = (pl: number, watchedBy: string): boolean => {
    if (targetType === "ranch") {
      if (pl === PL_TYPE_PROFIT) {
        if (EVENT_KIND.OTHER_PROFIT_RANCH.selfEditOnly !== true) return false;
      } else {
        if (EVENT_KIND.OTHER_LOSS_RANCH.selfEditOnly !== true) return false;
      }
    } else {
      if (pl === PL_TYPE_PROFIT) {
        if (EVENT_KIND.OTHER_PROFIT_COW.selfEditOnly !== true) return false;
      } else {
        if (EVENT_KIND.OTHER_LOSS_COW.selfEditOnly !== true) return false;
      }
    }

    if (user.id === watchedBy) return false;

    showModalAsync(A.MESSAGE.SELF_EDIT_ONLY, "ALERT", ["OK"]).then(() => {
      history.replace("/top/" + rootStore.cur_ranch_id);
    });
    return true;
  }

  const loadCowInfo = async (cowId:number): Promise<ICowNameInfo|undefined> => {
    //普通はアクティブ牛リストにあるはずなのでそこからとって返す
    const cow = rootStore.getActiveCows().find(c => c.cow_id === cowId);
    if (cow != null) return Promise.resolve(cow);

    const query_str = `?ranch_id=${rootStore.cur_ranch_id}&is_detail=0`;
    const res = await comm.send<CowDetailDto>(() => postAsync("/cow/" + cowId + query_str, {}), { retries: true });
    if (res.result !== "OK") return undefined;

    return res.data;
  }
  
  const canRegister = () => {
    return true;
  }

  const register = async () => {
    setExecuting(true);
    const req:OtherPlModifyReq = {
      is_new: eventId == null ? 1 : 0,
      ranch_id: rootStore.cur_ranch_id,
      event_id: eventId,
      watched_at: moment(data.watchedAt).format("YYYY-MM-DD HH:mm:00"),
      cows: data.details.map(d => ({
        price: d.price,
        cow_id: d.cow_id,
        comment: d.comment,
      })),
      profit_loss: data.profitLoss
    };
    handleSetIsLoading(true)
    const res = await comm.send((await OtherPlApi()).modifyOtherPlUsingPOST(req));
    handleSetIsLoading(false)
    setExecuting(false);

    if (res.result !== "OK") {
      return;
    }

    isCowTop ? history.replace(historyLocation.toCowInfo(data.details[0].cow_id!)) : history.go(-1)
  }

  const execDelete = async () => {
    if (!CommonUtil.assertNotNull(eventId, "eventId", "delete")) return;
    if (!CommonUtil.assertNotNull(original, "original", "delete")) return;

    if (await showDialog("QUESTION", '記録を削除してよろしいですか？', DIALOG_BUTTONS.DELETE_CANCEL) !== 0) {
      return;
    }

    const req: OtherPlDeleteReq = {
      ranch_id: rootStore.cur_ranch_id,
      event_id: eventId,
      cow_id: data.details[0].cow_id,
      profit_loss: original.profit_loss
    };

    setExecuting(true);
    handleSetIsLoading(true)
    const res = await comm.send((await OtherPlApi()).deleteOtherPlUsingPOST(req));
    handleSetIsLoading(false)
    setExecuting(false);

    if (res.result !== "OK") return;

    isCowTop ? history.replace(historyLocation.toCowInfo(data.details[0].cow_id!)) : history.go(-1)
  }

  return (
    <main className={ styles.page }>
      <section className={ styles["content-area"] }>
        <div className={ styles["content"] }>
        { targetType === 'ranch' &&
          <RegisterRanch
            data={data} 
            onChange={d => setData(d)} 
            user={user}
            ranchId={rootStore.cur_ranch_id}
          />
        }
        { targetType === 'cow' &&
          <RegisterCow
            data={data}
            onChange={d => setData(d)} 
            user={user}
            ranchId={rootStore.cur_ranch_id}
        />
        }
        { targetType === 'cows' &&
          <RegisterCows
            data={data}
            onChange={d => setData(d)} 
            user={user}
            ranchId={rootStore.cur_ranch_id}
          />
        }
        </div>
      </section>
      <section className={ styles["fix-bottom"] }>
        <div className={ styles.footmenu }>
          <ExecutionButton type="save" disabled={executing || !canRegister()} onClick={register} />
          { eventId != null && (
            <ExecutionButton type="delete" onClick={execDelete} disabled={executing} />
          )}
        </div>
      </section>
    </main>
  );
}