import React from 'react';
import { InfoPopup } from '../../components/parts/info-popup';
import styles from './sellcow.module.css';
import { calcDg } from '../../config/util';

export interface DgViewerProps {
    stScore: number | undefined;
    stDate: string | Date | undefined;
    isStandardScoreUsed: boolean;
    edScore: number | undefined;
    edDate: Date;
}

export const DgViewer = React.memo((props: DgViewerProps) => {

    if (props.stScore == null || props.stDate == null || props.edScore == null) {
        return <></>
    }

    const dg = calcDg({ score: props.stScore, date: props.stDate }, { score: props.edScore, date: props.edDate });
    if (dg == null) {
        return <></>
    }

    return (
        <div className={styles.addition}>
            DG: {dg}kg/日
            { props.isStandardScoreUsed && (
                <InfoPopup iconType="warning"
                    testId="warning-std"
                    message={["生時体重の記録がないため、","標準生時体重に基づいて算出"]}
                    placement="top"
                />
            )}
        </div>
    )

});