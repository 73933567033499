import React, { useState, useEffect } from 'react';
import { ResponsiveContainer, Line, XAxis, YAxis, Tooltip, CartesianGrid, Legend, ReferenceLine, ComposedChart, Bar, BarChart } from 'recharts';
import { BalanceSpanDto } from '../../api';
import { CommonUtil, ar, formatYen } from '../../config/util';

export const BALANCE_GRAPH_TYPES = ["balance", "profit", "loss"] as const;
export type BalanceGraphType = typeof BALANCE_GRAPH_TYPES[number];

interface BalanceGraphProps {
    data: Readonly<Readonly<BalanceSpanData>[]>;
    onSelect?: (label: string) => void;
    dispType: BalanceGraphType;
}

interface EventData {
    activeLabel: string;
    activePayload?: Payload[];
}
interface CurrentData {
    additional: string | undefined;
    activeLabel: string;
    label: string;
    payloads: { unit: string, color: string, name: string, value: number }[];
}

interface Payload {
    unit: string;
    color: string;
    name: string;
    value: number | null;
    payload: GraphData;
}

export type BalanceSpanData = BalanceSpanDto & {
    additional?: string;
}

interface GraphData {
    label: string;
    from: string;
    to: string;
    profitSum: number;
    lossSum: number;
    balance: number;
    profits: {[key:string]:number};
    losses: {[key:string]:number};
    additional: string | undefined;
}

interface BalanceCategory {
    id: number;
    name: string;
    color: string;
}

const dtoToGraphData = (dto: BalanceSpanData): GraphData => {
    const profits = {};
    dto.profits.forEach(p => profits[p.name] = p.price);
    const losses = {};
    dto.losses.forEach(l => losses[l.name] = l.price);
    const profitSum = dto.profits.map(p => p.price).reduce((p,pp) => p + pp, 0);
    const lossSum = dto.losses.map(l => l.price).reduce((p,pp) => p + pp, 0);

    return ({
        label: dto.label,
        from: dto.span_from,
        to: dto.span_to,
        profits,
        profitSum,
        losses,
        lossSum,
        balance: profitSum - lossSum,
        additional: dto.additional
    });
}

export const BALANCE_CATEGORIES = ["MILK", "EGG", "DISUSED", "CHILD", "CARCASS", "OTHER_PROFIT", "FEED", "TREAT", "PREVEINTION", "BREEDING", "BUY_COW", "OTHER_LOSS"] as const;
type BalanceCategoryKey = typeof BALANCE_CATEGORIES[number];
export const BALANCE_CATEGORY: {[key in BalanceCategoryKey]: { id: number, name: string, fill: string }} = {
    MILK:         { id: 1,  name: "乳代", fill: "orange" },
    EGG:          { id: 2,  name: "受精卵", fill: "#a83255" },
    DISUSED:      { id: 3,  name: "更新", fill: "#005c29" },
    CHILD:        { id: 4,  name: "子牛/育成", fill: "#a8e2c2" },
    CARCASS:      { id: 5,  name: "肥育出荷", fill: "#2fa765" },
    OTHER_PROFIT: { id: 6,  name: "雑収入", fill: "gray" },
    FEED:         { id: 7,  name: "えさ", fill: "orange" },
    TREAT:        { id: 8,  name: "治療", fill: "#9632a8" },
    PREVEINTION:  { id: 9,  name: "予防", fill: "blue" },
    BREEDING:     { id: 10, name: "繁殖", fill: "#a83255" },
    BUY_COW:      { id: 11, name: "導入費", fill: "#2fa765" },
    OTHER_LOSS:   { id: 12, name: "雑損失", fill: "gray" }
} as const;

export const BalanceGraph: React.FC<BalanceGraphProps> = React.memo((props) => {
    const [ currentData, setCurrentData ] = useState<CurrentData|null>(null);
    const [ graphData, setGraphData ] = useState<GraphData[]>([]);
    const [ currentCategories, setCurrentCategories ] = useState<BalanceCategory[]>([]);

    useEffect(() => {
        setCurrentData(null);
        setGraphData(props.data.map(d => dtoToGraphData(d)));

    }, [ props.data ]);

    useEffect(() => {
        setCurrentData(null);

        if (props.dispType === "balance") {
            setCurrentCategories([]);
            return;
        }
        const list = props.dispType === "profit" ? props.data.map(d => d.profits)
            : props.dispType === "loss" ? props.data.map(d => d.losses)
            : null;
        if (!CommonUtil.assertNotNull(list, "list", "props.dispType: " + props.dispType)) return;

        const allItemDtos = ar.flat(list, 1);
        const ids = ar.distinct(allItemDtos.map(dt => dt.category_id).sort((a,b) => a - b));
        const categories = ids.map(id => {
            const key = BALANCE_CATEGORIES.find(k => BALANCE_CATEGORY[k].id === id);
            if (!CommonUtil.assertNotNull(key, "category key", "id " + id)) return null;
            return {
                id,
                color: BALANCE_CATEGORY[key].fill,
                name: allItemDtos.find(d => d.category_id === id)?.name??""
            }
        });

        setCurrentCategories(ar.notNull(categories));

    }, [ props.data, props.dispType ])


    const onChartClick = (e:EventData|null) => {
        if (e == null
            || e.activePayload == null
            || e.activePayload.every(p => p.value == null)) {
            setCurrentData(null);
            return;
        }
        const payloads = e.activePayload.filter(p => p.value != null);
        const from = payloads[0].payload.from;
        const to = payloads[0].payload.to;
        const additional = payloads[0].payload.additional;
        const label = from === to ? from : `${from}～${to}`;
        setCurrentData({
            label: label,
            activeLabel: e.activeLabel,
            payloads: payloads.map(p => ({ ...p, value:p.value! })),
            additional
        });
    }

    return (
        <div style={{width:"calc(100% + 12px)", height:"240px", position:"relative", marginLeft:"-8px", marginRight:"-8px"}}>
            { props.dispType === "balance" && (
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart onClick={onChartClick} data={graphData} barGap={1}>
                        <Bar key="profit"
                            dataKey="profitSum"
                            name="売上"
                            yAxisId={1}
                            fill="darkblue"
                            unit="円"
                        />
                        <Bar key="loss"
                            dataKey="lossSum"
                            name="経費"
                            yAxisId={1}
                            fill="lightblue"
                            unit="円"
                        />
                        <Line key="balance"
                            name="収支"
                            dataKey="balance"
                            yAxisId={2}
                            stroke="orange"
                            strokeWidth={2}
                            animationDuration={700}
                            dot={{ r: 2 }}
                            activeDot={{ r: 5 }}
                            type="monotoneX"
                            unit="円"
                        />
                        <XAxis dataKey="label" type="category"
                            tick={{fontSize:"10px"}}
                            height={33}
                        />
                        <YAxis yAxisId={1} tick={{fontSize:"10px"}} />
                        <YAxis yAxisId={2} tick={{fontSize:"10px"}} orientation="right" />
                        <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />

                        <Tooltip content={<></>} />

                        { currentData != null && (
                            <ReferenceLine x={currentData.activeLabel} stroke="red" strokeDasharray="3 3" yAxisId={1} />
                        ) }
                        <Legend height={40} margin={{top:40, left:0, right:0, bottom:0}} />
                    </ComposedChart>
                </ResponsiveContainer>
            )}
            { props.dispType !== "balance" && (
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart onClick={onChartClick} data={graphData}>
                        { currentCategories.map(cate => (
                            <Bar key={cate.id}
                                fill={cate.color}
                                name={cate.name}
                                unit="円"
                                yAxisId={1}
                                stackId="a"
                                dataKey={props.dispType === "profit" ? ("profits." + cate.name) : ("losses." + cate.name)}
                            />
                        ))}

                        <XAxis dataKey="label" type="category"
                            tick={{fontSize:"10px"}}
                            height={30}
                        />
                        <YAxis yAxisId={1} tick={{fontSize:"10px"}} />
                        <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />

                        { currentData != null && (
                            <ReferenceLine x={currentData.activeLabel} stroke="red" strokeDasharray="3 3" yAxisId={1} />
                        ) }
                        <Legend height={40} margin={{top:40, left:0, right:0, bottom:0}} />

                    </BarChart>
                </ResponsiveContainer>
            )}
            { currentData != null && (
                // ※標準のTooltipは、スタイルの自作はできるが、表示非表示の手動制御ができず、
                // 　state更新などのトリガで勝手に閉じてしまうので、自前で作り直す
                //　（マウスオーバーでのフォーカスライン表示だけしたいので 収支では Tooltip 部品も残しておく）
                <div className="chart-legend">
                    { currentData.additional != null && (<div>{currentData.additional}</div>)}
                    <div>{currentData.label}</div>
                    { currentData.payloads.map((p,i) => (
                        <div key={i}>
                            <span style={{ color:p.color }}>●</span>
                            <span>{p.name} : {formatYen(p.value)}{p.unit}</span>
                        </div>
                    ))}
                    { props.onSelect != null && (
                        <div style={{textAlign:"right", marginTop:"4px", fontSize:"0.75rem"}}>
                            <span className="link" onClick={() => props.onSelect!(currentData.activeLabel)}>イベント詳細</span>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
})