import React from 'react';
import styles from './toggle-switch.module.css';
import classnames from 'classnames';

export interface ToggleSwitchProps {
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    label?: string;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = (props) => {

    return (
        <div className={classnames(styles["container"], { [styles["checked"]]:props.checked })}>
            <div className={styles["switch"]}>
                <label><input type="checkbox" onChange={props.onChange} checked={props.checked} /></label>
            </div>
            <div className={styles["toggle-label"]}>{props.label ?? ""}</div>
        </div>
        
    );
}

export type ToggleButtonState = 1 | 2;

export interface ToggleButtonProps {
    state: ToggleButtonState;
    label1: string;
    label2: string;
    onChange: (st: ToggleButtonState) => void;
    ariaLabel?: string;
    id: string;
}

export const ToggleButton: React.FC<ToggleButtonProps> = (props) => {
    return (
        <div className={styles["toggle-button"]}>
            <input id={props.id} type="checkbox" checked={props.state === 1}
                onChange={e => props.onChange(e.target.checked ? 1 : 2)} />
            <label htmlFor={props.id}>
                <span className={styles["l"]}>{props.label1}</span>
                <span className={styles["r"]}>{props.label2}</span>
            </label>
        </div>
    )
}