import React from 'react';
import { Card, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ExecutionType, ExecutionButton } from '../buttons/execution-button';
import { FreezedArray } from '../../config/util';

export type FormDialogType = "NOTIFY"|"WARNING"|"QUESTION"|"NONE";
export type DialogButtonType = { type:ExecutionType, text?:string };

export const DIALOG_BUTTONS = {
    YES_NO: [{ type:"decide", text:"はい" }, { type:"cancel", text:"いいえ" }],
    OK: [{ type:"decide", text:"OK" }],
    OK_CANCEL: [ { type:"decide", text:"OK"}, { type:"cancel" } ],
    YES_NO_CANCEL: [{ type:"decide", text:"はい" }, { type:"cancel", text:"いいえ" }, { type:"cancel" }],
    DELETE_CANCEL: [{ type: "delete" }, { type: "cancel" }]
} as const;

const DIALOG_TYPE: { [key in FormDialogType] : { title: string, defaultButtons: FreezedArray<DialogButtonType> }} = {
    NOTIFY: { title: "通知", defaultButtons: DIALOG_BUTTONS.OK },
    WARNING: { title: "注意", defaultButtons: DIALOG_BUTTONS.OK },
    QUESTION: { title: "確認", defaultButtons: DIALOG_BUTTONS.OK_CANCEL },
    NONE: { title: "", defaultButtons:[{ type:"decide", text:"OK" }] }
} as const;

export interface FormDialogProps {
	onClose: (result?: number)=>void;
	isOpen: boolean;
	text: string;
	subTexts?: Readonly<string[]>;
	buttons?: FreezedArray<DialogButtonType>;
	type: FormDialogType;
    required?: boolean;
}

export const FormDialog = (props: FormDialogProps) => {

    const buttons = props.buttons ?? DIALOG_TYPE[props.type].defaultButtons;

    return (
        <Modal isOpen={props.isOpen}>
            <ModalHeader className="popup-title" style={{ borderBottom: "none" }} toggle={props.required ? undefined : props.onClose}>{DIALOG_TYPE[props.type].title}</ModalHeader>
            <ModalBody>
                <div className="m-t-20">
                    <div>
                        {props.text.split('\n').map((line, i) => (
                            <h6 key={i}>
                                {line}
                            </h6>
                        ))}
                        { (props.subTexts != null && props.subTexts.length > 0) && (
                            <ul style={{ paddingLeft:"22px", fontSize:"0.8125rem", color:"#999999" }}>
                                { props.subTexts.map((txt,i) => (
                                    <li key={i}>{txt.split('\n').map((l,il) => <span key={il}>{l}<br/></span>)}</li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className="modal-footer-fix" style={{ borderTop: "none" }}>
            {
                buttons.map((button, i) => (
                    <ExecutionButton key={i} type={button.type} children={button.text} onClick={() => props.onClose(i)} />
                ))
            }
            </ModalFooter>
        </Modal>
    )
}