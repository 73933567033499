import React from 'react';
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import { PageSettings } from '../../config/page-settings.js';
import { IFeedType, IRanchFeed } from '../../stores';
import { calcSolute, CommonUtil, ar, FreezedArray } from '../../config/util';
import { CowToDispInfo } from '../../components/parts/cows-popup';
import { Communicator } from '../../api/communicator';
import { FeedLeftModifyReq, FeedLeftModifyReqFeed } from '../../api/api';
import { AppState } from '../../app';
import { A, LMT } from '../../config/constant';
import { RequiredNumInput } from '../../components/parts/num-input';
import moment from 'moment';
import styles from './feed-remained.module.css';
import classnames from 'classnames';

interface MyState {
    arranged_data?: FreezedArray<IArrangedData>,
    executing: boolean
}

const INPUT_TYPE_ALL = 0 as const;
const INPUT_TYPE_EACH = 1 as const;
type INPUT_TYPE = 0 | 1;

export interface IFeedLeft {
    cow_id: number;
    delivered: number;
    delivered_raw: number;
    fed_at: string;
    feed_no: number;
    feed_type_no: number;
    feeding_id: number;
    local_no?: string;
    name?: string;
    trace_id: string;

    //※現状、APIのレスポンスでは常に0で返ってくる。危険なので無視
//    leftover?: number;
//    leftover_raw?: number;
}
type EditingFeedLeft = IFeedLeft & {
    leftover: number;
    feed_name: string;
    unit: string;
}
type TimeGroupedFeeds = {
    time: string;
    collapsed: boolean;
    cows: CowGroupedFeeds[];
}
type CowGroupedFeeds = {
    cowDispInfo: string;
    feeds: EditingFeedLeft[];
}
interface IArrangedData {
    date: string;
    collapsed: boolean;
    input_type: INPUT_TYPE;
    selected_item_idx: number;
    leftover: number;
    times: TimeGroupedFeeds[];
    feed_items: { feed_type_no:number, feed_no:number, feed_name:string, unit:string }[];
}
export interface FeedRemainedContentProps {
    feeds_left?: Readonly<IFeedLeft[]>;
    cow_ids: string;
    feed_types: IFeedType[];
    ranch_feeds: FreezedArray<IRanchFeed>;
    user_id: string;
    ranch_id: number;
    from_days_before:number;
    to_days_before:number;
    isModal?: boolean;
    comm: Communicator;
}

const flatDayFeeds = (dayFeeds: TimeGroupedFeeds[]) => {
    const layered = dayFeeds.map(d => d.cows.map(c => c.feeds));
    return ar.flat(layered, 2);
}

export class FeedRemainedContent extends React.Component<FeedRemainedContentProps,MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {

        super(props);

        this.state = {
            executing: false
        };

        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.onProcessAtOnce = this.onProcessAtOnce.bind(this);
        this.onProcessItem = this.onProcessItem.bind(this);
    }

    parseFeedsLeftData(feeds_left : Readonly<IFeedLeft[]>) {
        const arranged_data: IArrangedData[] = [];
        console.log(feeds_left);

        const openDayData = this.state.arranged_data?.find(d => !d.collapsed);

        const dayGrouped = CommonUtil.groupBy(feeds_left, f => f.fed_at.slice(0, 10));
        for (const [ fed_date, feedsOfDay ] of dayGrouped.entries()) {

            const feed_items = [...CommonUtil.groupBy(feedsOfDay, f => f.feed_no).entries()]
                                    .map(([feed_no, list]) => ({
                                        feed_no,
                                        feed_type_no: list[0].feed_type_no,
                                        item: this.props.ranch_feeds.find(f => f.feed_no === feed_no),
                                        type_item: this.props.feed_types.find(t => t.feed_type_no === list[0].feed_type_no)
                                    }))
                                    .map(a => ({
                                        feed_no: a.feed_no,
                                        feed_type_no: a.feed_type_no,
                                        feed_name: a.item?.name ?? a.type_item?.name ?? "",
                                        unit: a.item?.unit ?? ""
                                    }));

            const feeds = feedsOfDay.map(f => ({ ...f, leftover: 0 }));

            const isOpenDay = openDayData != null && fed_date === openDayData.date;

            const times: TimeGroupedFeeds[] = [];
            const timeGrouped = CommonUtil.groupBy(feeds, f => f.fed_at);
            for (const [ fed_at, feedsOfTime ] of timeGrouped.entries()) {
                const time = moment(fed_at).format("HH:mm");

                const cowGrouped = CommonUtil.groupBy(feedsOfTime, f => f.cow_id);
                const cows: CowGroupedFeeds[] = [];
                for (const feedsOfCow of cowGrouped.values()) {
                    const cowDispInfo = CowToDispInfo(feedsOfCow[0]);
                    const feeds = feedsOfCow.map(fc => ({
                        ...fc,
                        feed_name: feed_items.find(i => i.feed_no === fc.feed_no)!.feed_name,
                        unit: feed_items.find(i => i.feed_no === fc.feed_no)!.unit
                    }))

                    cows.push({ cowDispInfo, feeds });
                }

                let collapsed = false;
                if (isOpenDay) {
                    const pre = openDayData!.times.find(t => t.time === time);
                    if (pre != null) {
                        collapsed = pre.collapsed;
                    }
                }

                times.push({ time, cows, collapsed });
            }
            
            arranged_data.push({
                date: fed_date,
                collapsed: !isOpenDay,
                input_type: isOpenDay ? openDayData!.input_type : INPUT_TYPE_ALL,
                selected_item_idx: -1,
                leftover: 0,
                feed_items,
                times
            })
        }

        this.setState({ arranged_data: arranged_data });
        console.log(arranged_data);
    }

    componentDidMount() {
        if (this.props.feeds_left != null) {
            this.parseFeedsLeftData(this.props.feeds_left);
        }
        else {
            this.api_getLeftFeedList();
        }
    }


    async onProcessAtOnce(date_data: IArrangedData) {
        const isAllFeed = date_data.selected_item_idx < 0;

        if (isAllFeed) {
            if (flatDayFeeds(date_data.times).some(f => f.delivered < date_data.leftover)) {
                this.context.showToast(A.MESSAGE.INVALID_FEED_LEFTOVER);
                return;
            }
        } else {
            const feedNo = date_data.feed_items[date_data.selected_item_idx].feed_no;
            if (flatDayFeeds(date_data.times).some(f => f.feed_no === feedNo && f.delivered < date_data.leftover)) {
                this.context.showToast(A.MESSAGE.INVALID_FEED_LEFTOVER);
                return;
            }
        }

        this.setState({ executing: true });
        await this.api_postFeedLeftWriteAtOnce(date_data);
        this.setState({ executing: false });
    }

    async onProcessItem(feed: EditingFeedLeft) {
        if (feed.delivered < feed.leftover) {
            this.context.showToast(A.MESSAGE.INVALID_FEED_LEFTOVER);
            return;
        }

        this.setState({ executing: true });
        await this.api_postFeedLeftWrite(feed);
        this.setState({ executing: false });
    }

    toggleCollapse(index: number) {
        if (!CommonUtil.assertNotNull(this.state.arranged_data)) return;

        const newArray = this.state.arranged_data.map((data, i) => ({ ...data, collapsed: i === index ? !data.collapsed : true }));
        this.setState({
            arranged_data: newArray
        });
    }

    api_getLeftFeedList = async () => {
        let cow_ids = this.props.cow_ids;

        const params = {
            user_id: this.props.user_id,
            ranch_id: this.props.ranch_id,
            cow_id: cow_ids,
            from_days_before: this.props.from_days_before,
            to_days_before: this.props.to_days_before
        };

        this.context.handleSetIsLoading(true);
        const response = await this.props.comm.send<any>(() => this.context.postAsync('/feed/amount/left/list', params));
        this.context.handleSetIsLoading(false);
        if (response.result !== "OK") return;

        this.parseFeedsLeftData(response.data?.list ?? []);
    }

    api_postFeedLeftWrite = async (feed: EditingFeedLeft) => {

        const params: FeedLeftModifyReq = {
            day: moment(feed.fed_at).format("YYYY-MM-DD"),
            feeding_ids: [ feed.feeding_id ],
            ranch_id: this.props.ranch_id,
            feeds: [
                {
                    feed_no: feed.feed_no,
                    leftover: feed.leftover,
                    leftover_raw: calcSolute(feed.feed_no, feed.leftover, this.props.ranch_feeds),
                }
            ]
        }
        if (params.feeds[0].leftover_raw > LMT.FEEDING.AMOUNT_RAW_MAX) {
            this.context.showQuestion(A.MESSAGE.INVALID_FEED_RAW_AMOUNT, ()=>{}, [ "OK" ]);
            return;
        }

        console.log("modify left", params);

        this.context.handleSetIsLoading(true);
        const res = await this.props.comm.send(() => this.context.postAsync('/feed/amount/left/modify', params));
        this.context.handleSetIsLoading(false);

        if (res.result === "OK") {
            await this.api_getLeftFeedList();
        }
    }

    // processAtOnce
    api_postFeedLeftWriteAtOnce = async (date_data: IArrangedData) => {

        const feed_no = date_data.selected_item_idx < 0 ? undefined : date_data.feed_items[date_data.selected_item_idx].feed_no;
        const ids = flatDayFeeds(date_data.times).filter(f => feed_no == null || f.feed_no === feed_no).map(f => f.feeding_id);

        const amounts: FeedLeftModifyReqFeed[]
            = feed_no == null
            ? date_data.feed_items.map(t => ({ feed_no: t.feed_no, leftover: date_data.leftover, leftover_raw: calcSolute(t.feed_no, date_data.leftover, this.props.ranch_feeds) }))
            : [{ feed_no, leftover: date_data.leftover, leftover_raw: calcSolute(feed_no, date_data.leftover, this.props.ranch_feeds) }];

        const params: FeedLeftModifyReq = {
            day: date_data.date,
            feeding_ids: [ ...new Set(ids)], //distinct
            feeds: amounts,
            ranch_id: this.props.ranch_id,
        }

        console.log("modify left", params);

        if (amounts.some(a => a.leftover_raw > LMT.FEEDING.AMOUNT_RAW_MAX)) {
            this.context.showQuestion(A.MESSAGE.INVALID_FEED_RAW_AMOUNT, ()=>{}, [ "OK" ]);
            return;
        }

        const res = await this.props.comm.send(() => this.context.postAsync('/feed/amount/left/modify', params));
        if (res.result === "OK") {
            await this.api_getLeftFeedList();
        }
    }

    render() {
        const currentData = this.state.arranged_data;
        if (currentData == null) return <div />

        return (
            <div id="accordion" className="accordion">
                {
                    currentData.length === 0 && !this.props.isModal &&(
                        <p>過去1週間に食べ残し未入力の記録はありません。</p>
                    )
                }
                {
                    currentData.map((dayData, iDay) => (
                        <Card className="bg-light text-black" key={iDay}>
                            <CardHeader
                                className={'card-header bg-light-lighter text-black pointer-cursor ' + (dayData.collapsed ? 'collapsed ' : '')}
                                onClick={() => this.toggleCollapse(iDay)}>
                                <span data-testid={"day-header__" + dayData.date}>
                                    <i className={"fas fa-lg fa-fw m-r-10 " +  (dayData.collapsed ? "fa-angle-down" : "fa-angle-up")}></i> {dayData.date}
                                </span>
                            </CardHeader>
                            <Collapse isOpen={!dayData.collapsed}>
                                <CardBody className="p-15">
                                    <div data-testid={"day-body__" + dayData.date}>
                                        <div className={classnames("radio", "radio-css", styles["input-type"], { [styles.checked]: dayData.input_type === INPUT_TYPE_ALL})}>
                                            <input type="radio" name={"radioInputType" + iDay}
                                                id={"radioInputType1_" + iDay} value={INPUT_TYPE_ALL}
                                                onChange={() => {
                                                    this.setState({
                                                        arranged_data: [
                                                            ...currentData.slice(0, iDay),
                                                            { ...dayData, input_type: INPUT_TYPE_ALL },
                                                            ...currentData.slice(iDay + 1)
                                                        ]
                                                    });
                                                }}
                                                checked={dayData.input_type === INPUT_TYPE_ALL} />
                                            <label htmlFor={"radioInputType1_" + iDay}>まとめて入力</label>
                                        </div>
                                        <div className="m-b-15" style={{ display: "flex", alignItems:"center" }} data-testid="row-all">
                                            <div style={{ flex: "1" }}>
                                                <select disabled={dayData.input_type !== INPUT_TYPE_ALL} className="form-control" value={dayData.selected_item_idx}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            arranged_data: [
                                                                ...currentData.slice(0, iDay),
                                                                { ...dayData, selected_item_idx: Number(e.target.value) },
                                                                ...currentData.slice(iDay + 1),
                                                            ]
                                                        });
                                                    }}>
                                                    <option value="-1">すべての餌</option>
                                                    {
                                                        dayData.feed_items.map((item, j) => (
                                                            <option key={j} value={j}>{item.feed_name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <RequiredNumInput
                                                disabled={dayData.input_type !== INPUT_TYPE_ALL}
                                                className="m-l-10 m-r-5"
                                                value={dayData.leftover}
                                                style={{ width: "70px", display: "inline" }}
                                                min={LMT.FEEDING.AMOUNT_MIN} max={LMT.FEEDING.AMOUNT_MAX}
                                                step={LMT.FEEDING.AMOUNT_STEP}
                                                onChange={val => {
                                                    this.setState({
                                                        arranged_data: [
                                                            ...currentData.slice(0, iDay),
                                                            { ...dayData, leftover: val },
                                                            ...currentData.slice(iDay + 1),
                                                        ]
                                                    })
                                                }}
                                            />
                                            <span className={styles.unit}>{dayData.selected_item_idx >= 0 ? dayData.feed_items[dayData.selected_item_idx].unit : ""}</span>
                                            <button
                                                disabled={this.state.executing || dayData.input_type !== INPUT_TYPE_ALL}
                                                type="button" className="btn btn-green m-l-5"
                                                onClick={() => { this.onProcessAtOnce(dayData); }}>確定
                                            </button>
                                        </div>
                                        <div className={classnames("radio", "radio-css", styles["input-type"], { [styles.checked]: dayData.input_type === INPUT_TYPE_EACH })}>
                                            <input type="radio" name={"radioInputType" + iDay}
                                                id={"radioInputType2_" + iDay} value={INPUT_TYPE_EACH}
                                                onChange={() => {
                                                    this.setState({
                                                        arranged_data: [
                                                            ...currentData.slice(0, iDay),
                                                            { ...dayData, input_type: INPUT_TYPE_EACH },
                                                            ...currentData.slice(iDay + 1),
                                                        ]
                                                    });
                                                }}
                                                checked={dayData.input_type === INPUT_TYPE_EACH} />
                                            <label htmlFor={"radioInputType2_" + iDay}>個別に入力</label>
                                        </div>
                                        {
                                            dayData.times.map((timeData, iTime) => (
                                                <div key={iTime} data-testid="time-data">
                                                    <div className={styles.time} onClick={() => {
                                                        this.setState({
                                                            arranged_data: [
                                                                ...currentData.slice(0, iDay),
                                                                { ...dayData, times:[
                                                                    ...dayData.times.slice(0, iTime),
                                                                    { ...timeData, collapsed: !timeData.collapsed },
                                                                    ...dayData.times.slice(iTime + 1),
                                                                ]},
                                                                ...currentData.slice(iDay + 1),
                                                            ]
                                                        })
                                                    }}>
                                                        <i className={"fas fa-lg fa-fw m-r-5 " +  (timeData.collapsed ? "fa-angle-down" : "fa-angle-up")}></i>
                                                        <span data-testid="time-label">{timeData.time}</span>
                                                    </div>
                                                    <Collapse isOpen={!timeData.collapsed} className={classnames(styles["time-data-container"], { [styles.enabled]: dayData.input_type === INPUT_TYPE_EACH })}>
                                                    {
                                                        timeData.cows.map((cowData, iCow) => (
                                                            <div key={iCow} className={styles["cow-data-container"]} data-testid="cow-data">
                                                                <div className={styles.cow} data-testid="cow-label">{cowData.cowDispInfo}</div>
                                                                {
                                                                    cowData.feeds.map((feed, iFeed) => (
                                                                        <div className="m-b-5" data-testid="row-each" key={iFeed}
                                                                            style={{ display: "flex", alignItems:"center" }}>
                                                                            <label style={{ margin: "0", flex: "1" }} data-testid="feed-label">
                                                                                {feed.feed_name + " " + feed.delivered + feed.unit}
                                                                            </label>
                                                                            <span className="m-r-5">残</span>
                                                                            <RequiredNumInput
                                                                                disabled={dayData.input_type !== INPUT_TYPE_EACH}
                                                                                className="m-r-5"
                                                                                value={feed.leftover}
                                                                                style={{ width: "70px" }}
                                                                                min={LMT.FEEDING.AMOUNT_MIN} max={LMT.FEEDING.AMOUNT_MAX}
                                                                                step={LMT.FEEDING.AMOUNT_STEP}
                                                                                onChange={val => {
                                                                                    this.setState({
                                                                                        arranged_data: [
                                                                                            ...currentData.slice(0, iDay),
                                                                                            { ...dayData, times: [
                                                                                                ...dayData.times.slice(0, iTime),
                                                                                                { ...timeData, cows:[
                                                                                                    ...timeData.cows.slice(0, iCow),
                                                                                                    { ...cowData, feeds: [
                                                                                                        ...cowData.feeds.slice(0, iFeed),
                                                                                                        { ...feed, leftover: val },
                                                                                                        ...cowData.feeds.slice(iFeed + 1),
                                                                                                    ]},
                                                                                                    ...timeData.cows.slice(iCow + 1),
                                                                                                ] },
                                                                                                ...dayData.times.slice(iTime + 1),
                                                                                            ]},
                                                                                            ...currentData.slice(iDay + 1),
                                                                                        ]
                                                                                    })
                                                                                }}
                                                                            />
                                                                            <span className={styles.unit}>{feed.unit}</span>
                                                                            <button disabled={this.state.executing || dayData.input_type !== INPUT_TYPE_EACH}
                                                                                type="button" 
                                                                                className="btn btn-green m-l-5"
                                                                                onClick={(e) => { this.onProcessItem(feed) }}>確定
                                                                            </button>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                    </Collapse>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </CardBody>
                            </Collapse>
                        </Card>
                    ))
                }
            </div>
        )
    }
}