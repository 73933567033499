import { FreezedArray } from "./util";

export const EGG_TYPES = [ 
    { egg_type : 1, name: "体内-凍結卵", isSov: true, isFrozen: true },
    { egg_type : 2, name: "体内-新鮮卵", isSov: true, isFrozen: false },
    { egg_type : 3, name: "体外-凍結卵", isSov: false, isFrozen: true },
    { egg_type : 4, name: "体外-新鮮卵", isSov: false, isFrozen: false },
] as const;

export const getEggTypeName = (lot: { egg_type?: number }) => EGG_TYPES.find(e => e.egg_type === lot.egg_type)?.name;

export const OVUM_RANKS = [
    { ovum_rank: 1, name: "A" },
    { ovum_rank: 2, name: "B" },
    { ovum_rank: 3, name: "C" },
    { ovum_rank: 4, name: "D" },
] as const;

export const findOvumRank = (rank: number) => OVUM_RANKS.find(o => o.ovum_rank === rank);

type EggRank = { egg_rank : number, name: string, canUse:boolean, sname: string };
const EGG_RANKS = [ 
    { egg_rank : 1, name: "A",         sname:"A", canUse:true },
    { egg_rank : 2, name: "B",         sname:"B", canUse:true },
    { egg_rank : 3, name: "C",         sname:"C", canUse:true },
    { egg_rank : 10, name: "変性卵",   sname:"変", canUse:false },
    { egg_rank : 11, name: "未受精卵", sname:"未", canUse:false },
] as const;

export const getEggRanks = (forUseOnly: boolean): FreezedArray<EggRank> => {
    return forUseOnly ? EGG_RANKS.filter(e => e.canUse) : EGG_RANKS;
}

export const getEggRankName = (lot: { egg_rank?: number }) => EGG_RANKS.find(e => e.egg_rank === lot.egg_rank)?.name;
export const findEggRank = (rank: number) => EGG_RANKS.find(e => e.egg_rank === rank);

export const EGG_STAGES = [ 
    { egg_stage : 1, name: "Day6" },
    { egg_stage : 2, name: "Day7" },
    { egg_stage : 3, name: "Day8" },
    { egg_stage : 4, name: "M" },
    { egg_stage : 5, name: "CM" },
    { egg_stage : 6, name: "EB" },
    { egg_stage : 7, name: "BL" },
    { egg_stage : 8, name: "ExBL" },
    { egg_stage : 9, name: "HiBL" },
    { egg_stage : 10, name: "HdBL" },
] as const;

export const getEggStageName = (lot: { egg_stage?: number }) => EGG_STAGES.find(e => e.egg_stage === lot.egg_stage)?.name;
export const findEggStage = (stage: number) => EGG_STAGES.find(e => e.egg_stage === stage);