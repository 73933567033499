import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ITeamCondition } from '../../stores';
import styles from './select-condition-popup.module.css';
import { SymptomModifyReqCondition } from '../../api';
import classnames from 'classnames';
import { Collapse } from 'reactstrap';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { FreezedArray } from '../../config/util';

interface MyProps {
    isOpen: boolean;
    onClose: () => void;
    data_conditions: FreezedArray<ITeamCondition>;
    conditions: SymptomModifyReqCondition[];
    onConditions: (result: SymptomModifyReqCondition[]) => void;
}
interface MyState {
    conditions: SymptomModifyReqCondition[];
    expandedConditionIds: Set<number>;
}

export class SelectConditionPopup extends React.Component<MyProps, MyState> {
    
    constructor(props: MyProps) {
        super(props);

        this.state = {
            conditions: [...props.conditions],
            //先頭の「よく使う」だけ開いておく
            expandedConditionIds: new Set([ props.data_conditions[0].condition_id ])
        }

        this.onCloseWithResult = this.onCloseWithResult.bind(this);
    }


    onCloseWithResult() {
        const result: SymptomModifyReqCondition[] = [];
        const conditions = this.props.data_conditions;
        // NOTE:表示順ソート
        //      中分類の`よく使う`(index:0)を除外するためindexは1から始める
        for (let i = 1; i < conditions.length; i++) {
            for (let j = 0; j < conditions[i].classes.length; j++) {
                const cls = this.state.conditions.find(c => c.class_id === conditions[i].classes[j].class_id);
                if (cls !== undefined) {
                    result.push(cls);
                }
            }
        }
        this.props.onConditions(result);
    }

    getConditionValue(class_id: number) {
        let condition = this.state.conditions.find(c => c.class_id === class_id);
        if(condition === undefined) {
            return 0;
        } else {
            return condition.detail_id ?? 0;
        }
    }

    onConditionSelected(e: React.ChangeEvent<HTMLSelectElement>, class_id: number) {
        const detail_id = Number(e.target.value);
        let idx = this.state.conditions.findIndex(c => c.class_id === class_id);
        if(idx < 0) {
            if(detail_id > 0) {
                this.state.conditions.push({class_id: class_id, detail_id: detail_id});
            }
        } else {
            if(detail_id === 0) {
                this.state.conditions.splice(idx, 1);
            } else {
                this.state.conditions[idx].detail_id = detail_id;
            }
        }
        this.setState({ conditions: this.state.conditions });
    }

    onLabelClick(condId: number, isExpanded: boolean) {
        const newSet = new Set([...this.state.expandedConditionIds]);
        if (isExpanded) {
            newSet.delete(condId);
        } else {
            newSet.add(condId);
        }
        this.setState({ expandedConditionIds: newSet });
    }

    render() {
        const collapseArrowClass = (isExpanded : boolean) => "fas fa-lg fa-fw " + (isExpanded ? "fa-angle-up" : "fa-angle-down");
        const isExpanded = (condId: number) => this.state.expandedConditionIds.has(condId);

        return (
            <div>
                <Modal isOpen={this.props.isOpen} style={{ maxWidth: "360px", margin: "10px auto" }}>
                    <ModalHeader toggle={this.props.onClose}>症状</ModalHeader>
                    <ModalBody style={{ overflowY: "auto", maxHeight: "calc(100vh - 160px)" }}>
                        <div>
                            { this.props.data_conditions.map((cond, i) => (
                                <div key={cond.condition_id}>
                                    <div className={classnames("clickable", styles["group-label"], {[styles.selected]:cond.classes.some(c => this.getConditionValue(c.class_id) !== 0) })}
                                        onClick={() => this.onLabelClick(cond.condition_id, isExpanded(cond.condition_id))}>
                                        <i className={collapseArrowClass(isExpanded(cond.condition_id))}></i>
                                        <span> {cond.name}</span>
                                    </div>
                                    <Collapse isOpen={isExpanded(cond.condition_id)} className={styles["select-container"]}>
                                    { cond.classes.map(cls => (
                                        <select key={cls.class_id}
                                            className={classnames("form-control", styles["select"], {[styles.selected]:this.getConditionValue(cls.class_id) !== 0 })}
                                            data-testid={"condition-class__" + cls.name}
                                            value={this.getConditionValue(cls.class_id)}
                                            onChange={(e) => this.onConditionSelected(e, cls.class_id)}>
                                            <option key={0} value={0}>{cls.name}</option>
                                            { cls.details.map( detail => (
                                                <option key={detail.detail_id} value={detail.detail_id}>{detail.name}</option>
                                            ))}
                                        </select>
                                    ))}
                                    </Collapse>
                                </div>
                            ))}
                        </div>
                    </ModalBody>
                    <ModalFooter className="modal-footer-fix">
                        <ExecutionButton type="decide" onClick={this.onCloseWithResult} />
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}