import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styles from './setting.module.css';
import pgStyles from './program-management.module.css';
import { IconLink } from '../../components/parts/icon-link';
import classnames from 'classnames';

import { EditingVisitingRanch } from './visiting-ranch-master';
import { TeamNameDto, VisitModifyReqDistance } from '../../api';
import { SingleCreatableSelect } from '../../components/parts/single-creatable-select';
import { FreezedArray, CommonUtil, generateKey } from '../../config/util';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { VisitingRanchFromEditPopup } from './visiting-ranch-from-edit-popup';

export type UserRanch = {
    name: string,
    ranch_id: number,
    team_symbol: string | undefined
}

interface MyProps {
    data: EditingVisitingRanch;
    userRanchList: FreezedArray<UserRanch>;
    registeredRanchIds: Readonly<number[]>;
    onClose: () => void;
    onDelete: () => void;
    onSubmit: (data: EditingVisitingRanch) => void;
    isSubmitExecuting: boolean;
    onSearch: (symbol: string) => Promise<TeamNameDto|undefined>;
}

type EditingFrom = VisitModifyReqDistance & { key:string }

export const VisitingRanchEditPopup = (props: MyProps) => {
    const [ data, setData ] = useState<Omit<EditingVisitingRanch, "distances"> & { distances: EditingFrom[] }>({ code:"", distances:[], ranch_id:undefined, name:undefined });
    const [ editingIndex, setEditingIndex ] = useState<number>();
    const [ ranchList, setRanchList ] = useState<FreezedArray<UserRanch>>([]);

    useEffect(() => {
        setRanchList(
            props.userRanchList.filter(r => !props.registeredRanchIds.includes(r.ranch_id) || props.data.ranch_id === r.ranch_id)
        );
    }, [ props.userRanchList, props.registeredRanchIds, props.data ])

    useEffect(() => {
        setData({
            ...props.data,
            distances: props.data.distances.map(f => ({ ...f, key: generateKey("from") }))
        });

    }, [ props.data ]);

    const onSearch = async (symbol: string) => {
        const res = await props.onSearch(symbol);
        if (res == null) return;
        
        setData({
            ...data,
            name: res.name,
            ranch_id: res.team_id,
            team_symbol: symbol
        })
    }

    const onSelect = (id: number) => {
        const ranch = props.userRanchList.find(r => r.ranch_id === id);
        if (!CommonUtil.assertNotNull(ranch, "ranch of " + id)) return;
        setData({
            ...data,
            name: ranch.name,
            ranch_id: ranch.ranch_id,
            team_symbol: ranch.team_symbol,
        });
    }

    const onFromDelete = () => {
        if (!CommonUtil.assertNotNull(editingIndex, "editingIndex", "onDelete")) return;
        
        setData({
            ...data,
            distances: data.distances.filter((_,i) => i !== editingIndex)
        });
        setEditingIndex(undefined);
    }
    const onFromSubmit = (name: string, distance: number) => {
        if (!CommonUtil.assertNotNull(editingIndex, "editingIndex", "onSubmit")) return;

        setData({
            ...data,
            distances: (editingIndex < data.distances.length)
                ? data.distances.map((f,i) => i === editingIndex ? ({ name, distance_km:distance, key:f.key }) : f)
                : [ ...data.distances, { name, distance_km:distance, key: generateKey("from") } ]
        });
        setEditingIndex(undefined);
    }

    return (
        <div>
            <Modal isOpen={true} centered={true}>
                <ModalHeader toggle={props.onClose}>往診先の編集</ModalHeader>
                <ModalBody style={{ overflowY: "auto", maxHeight: "calc(100vh - 210px)", minHeight:"calc(100vh - 280px)", paddingLeft:"20px" }}>
                    <div className="form-group" style={{display:"flex"}}>
                        <label className={"col-form-label " + pgStyles["popup-label"]}>牧場名</label>
                        <SingleCreatableSelect
                            style={{ flex: 1 }}
                            placeholder="選択 またはIDで検索"
                            formatCreateLabel={n => `「${n}」で検索`}
                            onCreate={n => onSearch(n)}
                            options={ranchList.map(r => ({ name:r.name, value:r.ranch_id }))}
                            value={data.ranch_id}
                            onSelect={id => onSelect(id)}
                        />
                    </div>
                    <div className="form-group" style={{display:"flex"}}>
                        <label className={"col-form-label " + pgStyles["popup-label"]}>コード</label>
                        <input type="text" className="form-control" style={{ flex: 1 }}
                            value={data.code}
                            onChange={e => setData({ ...data, code: e.target.value })}
                        />
                    </div>
                    {/* <div className="product-detail"> */}
                        <ul className={styles["list"]}>
                            { data.distances.map((frm,i) => (
                                <li key={frm.key} className={classnames(styles["list-item"], styles["list-item-mid"])}>
                                    <div className={styles["list-item-content"]}>
                                        <div className={styles["list-item-name"]}>{frm.name}</div>
                                        <div className={styles["list-item-detail"]}>{frm.distance_km}km</div>
                                    </div>
                                    <div className={styles["list-item-icon"]} onClick={() => setEditingIndex(i)}>
                                        <i className="fas fa-pen clickable" />
                                    </div>
                                </li>))
                            }
                        </ul>
                    {/* </div> */}
                    <div style={{marginTop:"5px"}} onClick={() => setEditingIndex(data.distances.length)}>
                        <IconLink iconType="add" text="往診元を追加" />
                    </div>
                </ModalBody>
                <ModalFooter className="modal-footer-fix">
                    <ExecutionButton type="save" onClick={() => props.onSubmit(data) } disabled={props.isSubmitExecuting || data.ranch_id == null} />
                    { props.data.ranch_id != null && (
                        <ExecutionButton type="delete" onClick={props.onDelete} disabled={props.isSubmitExecuting} />
                    )}
                </ModalFooter>
            </Modal>
            { editingIndex != null && (
                <VisitingRanchFromEditPopup
                    original={data.distances[editingIndex]}
                    onClose={() => setEditingIndex(undefined)}
                    onDelete={onFromDelete}
                    onSubmit={onFromSubmit}
                />
            )}     
        </div>
    )}