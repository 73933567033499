import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styles from './team.module.css';
import { ITeam } from './team-search';

export interface TeamRequestPopupProps {
    onClose:()=>void;
    onSubmit:()=>void;
    team:ITeam;
    canRequest:boolean;
    isSubmitExecuting: boolean;
}

export const TeamRequestPopup: React.FC<TeamRequestPopupProps> = (props) => {

    return (
        <div className="modal-wide">
            <Modal isOpen={true} toggle={props.onClose} centered={true} style={{ maxWidth: "300px", margin: "auto" }}>
                {/* style={{maxWidth:"1000px", position:"absolute",  top:"50%", left:"50%", transform:"translate(-50%, -50%)"}}> */}
                <ModalHeader toggle={props.onClose}>参加申請</ModalHeader>
                <ModalBody style={{ overflowY: "auto", maxHeight: "calc(100vh - 150px)" }}>
                    <div className="checkout-body">
                        <div className="form-group row m-b-0" style={{flexDirection:"column", alignItems:"center"}}>
                            <div className={styles["team-name"]}>
                                {props.team.name}
                            </div>
                            <div className={styles["team-symbol"]}>
                                {props.team.team_symbol}
                            </div>
                        </div>
                    </div>
                </ModalBody>
                { props.canRequest && (
                    <ModalFooter style={{display:"flex", justifyContent:"center"}}>
                        <button className="btn btn-warning" onClick={props.onSubmit} disabled={props.isSubmitExecuting}>参加申請</button>
                    </ModalFooter>
                )}
                </Modal>
        </div>
    );
}