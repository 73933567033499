import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import cowListStyles from './cow-list.module.css';
import { TeamTreatPresetDto, TeamTreatPresetItemDto } from '../../api';
import { FreezedArray, ar } from '../../config/util';
import classnames from 'classnames';
import { IMedicine, ITreatItem, IMedicineRoute } from '../../stores';
import { ExecutionButton } from '../buttons/execution-button';

type MedicineForPreset = Pick<IMedicine, "medicine_id"|"name"|"unit">;
type TreatItemForPreset = Pick<ITreatItem, "treat_kind_no"|"treat_item_no"|"name"|"fee_unit">;
type MedicineRouteForPreset = Pick<IMedicineRoute, "route_id"|"name">;

export interface MyProps {
    onClose:()=>void;
    selectedPreset?: number;
    presets: FreezedArray<TeamTreatPresetDto>;
    onSubmit:(preset: TeamTreatPresetDto) => void;
    medicinesOnly: boolean;
    medicines: FreezedArray<MedicineForPreset>;
    treatItems: FreezedArray<TreatItemForPreset>;
    routes: FreezedArray<MedicineRouteForPreset>;
}

export type NamedPresetItem = TeamTreatPresetItemDto & { name: string, routeName: string, dispAmount:string };
type NamedPreset = Omit<TeamTreatPresetDto, "items"> & {
    items: Array<NamedPresetItem>;
}

export const namePresetItem = (item: TeamTreatPresetItemDto,
                medicines: FreezedArray<MedicineForPreset>,
                treatItems: FreezedArray<TreatItemForPreset>,
                routes: FreezedArray<MedicineRouteForPreset>)
    : NamedPresetItem | undefined => {
    
    if (item.medicine_id != null) {
        const medicine = medicines.find(m => m.medicine_id === item.medicine_id);
        if (medicine == null) return undefined;
        const route = item.route_id == null ? undefined : routes.find(r => r.route_id === item.route_id);
        return {
            ...item,
            name:medicine.name,
            routeName: route?.name ?? "",
            dispAmount: item.amount == null ? "" : (item.amount + medicine.unit)
        };
    }
    if (item.treat_item_no == null || item.treat_kind_no == null) return undefined;

    const treat = treatItems.find(t => t.treat_kind_no === item.treat_kind_no && t.treat_item_no === item.treat_item_no);
    if (treat == null) return undefined;
    return {
        ...item,
        name:treat.name,
        routeName:"",
        dispAmount: (item.amount == null || treat.fee_unit == null) ? "" : (item.amount + treat.fee_unit)
    };
}

export const TreatPresetSelectPopup = (props: MyProps) => {
    const [ selected, setSelected ] = useState<NamedPreset>();
    const [ availablePresets, setAvailablePresets ] = useState<FreezedArray<NamedPreset>>([]);

    useEffect(() => {
        const presets = (props.medicinesOnly ? props.presets.filter(p => p.items.every(i => i.medicine_id != null)) : props.presets)
                .map(p => ({
                    ...p,
                    items: ar.notNull(p.items.map(pi => namePresetItem(pi, props.medicines, props.treatItems, props.routes)))
                }));

        setAvailablePresets(presets);

        if (props.selectedPreset == null) {
            setSelected(undefined);
        } else {
            const preset = presets.find(p => p.preset_id === props.selectedPreset);
            setSelected(preset);    //リストに含まれないならクリア
        }

    }, [ props.presets, props.selectedPreset, props.medicines, props.treatItems, props.routes ]);

    const rowClass = (preset: TeamTreatPresetDto) => classnames(cowListStyles["list-item"], { [cowListStyles.selected]: selected?.preset_id === preset.preset_id})

    const styles: {[key:string]:React.CSSProperties } = {
        modal: {
            maxWidth: "400px",
            margin: "auto"
        },
        modalBody: {
            maxHeight: "calc(100vh - 210px)",
            minHeight: "calc(100vh - 320px)"
        },
        container: {
            flex: 1,
            overflowY: "auto"
        },
        listItem: {
            flexFlow: "column nowrap",
            padding: "6px 10px"
        },
        name: {
            fontWeight: "bold",
            color: "#666"
        },
        detail: {
            fontSize: "0.6875rem"
        }
    }

    return (
        <div>
            <Modal isOpen={true} toggle={props.onClose} style={styles.modal} scrollable={true}>
                <ModalHeader toggle={props.onClose}>プリセットを選択</ModalHeader>
                <ModalBody style={styles.modalBody}>
                    { availablePresets.length === 0 ? (
                        <div>利用可能なプリセットはありません</div>
                    ) : (
                        <div className={cowListStyles["list"]} style={styles.container}>
                            { availablePresets.map(preset => (
                                <div key={preset.preset_id} className={rowClass(preset)} onClick={() => setSelected(preset)} style={styles.listItem}>
                                    <div style={styles.name}>{preset.preset_name}</div>
                                    <div style={styles.detail}>{preset.items.map(pi => [pi.name,pi.dispAmount,pi.routeName].filter(v => v !== "").join(" ")).join(", ")}</div>
                                </div>
                            ))}
                        </div>
                    )}
                </ModalBody>
                <ModalFooter className="modal-footer-fix">
                    <ExecutionButton type="decide" disabled={selected == null}
                        onClick={() => props.onSubmit(selected!)} />
                </ModalFooter>
            </Modal>
        </div>
    )
}