import React from 'react';
import styles from './balance.module.css';
import { RequiredNumInput } from '../../components/parts/num-input';
import { LMT } from '../../config/constant';

export interface AgeSelectorProps {
    selectedValue: number;
    onChange: (value:number) => void;
    min?: number;
    max?: number;
}

export const AgeSelector: React.FC<AgeSelectorProps> = (props) => {

    const onSlide = (span: number) => {
        props.onChange(props.selectedValue + span);
    }

    const min = props.min ?? LMT.COW.AGE_M_MIN;
    const max = props.max ?? LMT.COW.AGE_M_MAX;
    const step = LMT.COW.AGE_M_STEP;

    return (
        <div className={styles["date-selector"]}>
            <div className={styles["date-select-controls"]}>
                <button type="button" className="btn btn-sm btn-inverse m-r-5"
                    disabled={props.selectedValue <= min}
                    onClick={() => onSlide(-1)}><i
                        className="fas fa-lg fa-caret-left"></i></button>
                <div>
                    <RequiredNumInput type="tel" className={styles["age-input"]}
                        min={min} max={max} step={step}
                        onChange={props.onChange}
                        value={props.selectedValue} />
                </div>
                <button type="button" className="btn btn-sm btn-inverse m-l-5"
                    disabled={max <= props.selectedValue}
                    onClick={() => onSlide(1)}><i
                        className="fas fa-lg fa-caret-right"></i></button>
            </div>
            <div className={styles["suffix"]}>ヵ月齢まで</div>
        </div>

    );
}