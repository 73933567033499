import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { ProgramApplyResultDto, ProgramOfCowApplyResultDto, ProgramApplyResultItemDto, ProgramApplyResultTagDto, RanchTagDto, PreloadedProgramReq, PreloadedCowProgramReq, ProgramOfCowApplyResultItem } from '../../api';
import { FreezedArray, ar } from '../../config/util';
import { ICowNameInfo, CowToDispInfo } from '../../components/parts/cows-popup';
import moment from 'moment';
import { Checkbox } from '../../components/form/form-checkbox';
import { EventKind } from '../../config/event-kind';
import styles from './preloaded-program-select-popup.module.css';
import { TAG_ACTION } from '../../config/constant';
import names from 'classnames';
import RootStore, { IUser } from '../../stores/RootStore';
import { CommunicateResult } from '../../api/communicator';
import { AppState } from '../../app';

/**
 * 事前取得したプログラムの選択ダイアログを表示し、選択を待機して結果を返す（牛情報登録時以外）
 */
export const showPreloadedProgramSelectDialog = async (
    context: Pick<AppState,"handleSetIsLoading"|"showToast">,
    preload: () => Promise<CommunicateResult<ProgramApplyResultDto>>,
    rootStore: Pick<RootStore,"getCowTags"|"fetchCowTags"|"user">,
    ranchId: number,
    clinicId: number | undefined,
    /**
     * Stateに、選択ダイアログ表示用のprops、またはundefinedを設定する。
     * propsが設定されている間、選択ダイアログが表示される形での実装を想定している。
     */
    setDialogPropsToState: (props:PreloadedProgramSelectPopupProps|undefined) => void,
    cows: FreezedArray<ICowNameInfo>

) : Promise<PreloadedProgramReq|undefined> => {
    
    return showDialogCommon(
        context,
        preload,
        rootStore,
        ranchId,
        setDialogPropsToState,
        (resolver, preloaded, allTags) => ({
            onCancel: () => {
                setDialogPropsToState(undefined);
                resolver();
            },
            allTags,
            cows,
            preloaded,
            onSubmit: (d => {
                setDialogPropsToState(undefined);
                resolver(d);
            }),
            isClinicUser: clinicId != null
        })
    )
}

/**
 * 事前取得したプログラムの選択ダイアログを表示し、選択を待機して結果を返す（牛情報登録時）
 */
export const showPreloadedCowProgramSelectDialog = async (
    context: Pick<AppState,"handleSetIsLoading"|"showToast">,
    preload: () => Promise<CommunicateResult<ProgramOfCowApplyResultDto>>,
    rootStore: Pick<RootStore,"getCowTags"|"fetchCowTags"|"user">,
    ranchId: number,
    clinicId: number | undefined,
    /**
     * Stateに、選択ダイアログ表示用のprops、またはundefinedを設定する。
     * propsが設定されている間、選択ダイアログが表示される形での実装を想定している。
     */
    setDialogPropsToState: (props:PreloadedCowProgramSelectPopupProps|undefined) => void,
    dispCow: string

) : Promise<PreloadedCowProgramReq|undefined> => {
    
    return showDialogCommon(
        context,
        preload,
        rootStore,
        ranchId,
        setDialogPropsToState,
        (resolver, preloaded, allTags) => ({
            onCancel: () => {
                setDialogPropsToState(undefined);
                resolver();
            },
            allTags,
            preloaded,
            onSubmit: (d => {
                setDialogPropsToState(undefined);
                resolver(d);
            }),
            dispCow,
            isClinicUser: clinicId != null
        })
    )
}

type TPreloadedBase = { list:Array<{ event_kind_no:number }>, tags:Array<{}> };

const showDialogCommon = async<TProps, TPreloaded extends TPreloadedBase> (
    context: Pick<AppState,"handleSetIsLoading"|"showToast">,
    preload: () => Promise<CommunicateResult<TPreloaded>>,
    rootStore: Pick<RootStore,"getCowTags"|"fetchCowTags"|"user">,
    ranchId: number,
    /**
     * Stateに、選択ダイアログ表示用のprops、またはundefinedを設定する。
     * propsが設定されている間、選択ダイアログが表示される形での実装を想定している。
     */
    setDialogPropsToState: (props:TProps|undefined) => void,
    genProps: (resolver:(value?:TPreloaded|PromiseLike<TPreloaded>) => void, 
                preloaded: TPreloaded,
                allTags: FreezedArray<RanchTagDto>) => TProps

) : Promise<TPreloaded|undefined> => {
    
    context.handleSetIsLoading(true);

    let preloaded: TPreloaded;
    try {
        const res = await preload();
        if (res.result !== "OK" || res.data == null) return undefined;

        //繁殖契約なし・未加入牧場で選択できない種別の予定は捨てる
        const selectableEventKindNoSet = new Set(EventKind.forSchedule(ranchId, rootStore.user).map(e => e.no));

        const tags = res.data.tags;
        const list = res.data.list.filter(e => selectableEventKindNoSet.has(e.event_kind_no));
        preloaded = { ...res.data, tags, list };
        if (tags.length === 0 && list.length === 0) return preloaded;   //0件ならそのまま空を返す

        //最新タグをとっておく
        const tagRes = await rootStore.fetchCowTags(undefined, true);
        if (tagRes === "NG") {
            context.showToast("タグ情報の取得に失敗しました。");
            return undefined;
        }
    } finally {
        context.handleSetIsLoading(false);
    }

    //ダイアログを表示して選択を待機
    return new Promise<TPreloaded>(resolve => {
        setDialogPropsToState(genProps(resolve, preloaded, rootStore.getCowTags()))
    });
}





export interface PreloadedProgramSelectPopupProps {
    onSubmit:(data: PreloadedProgramReq) => void;
    onCancel:() => void;
    preloaded: Readonly<ProgramApplyResultDto>;
    cows: FreezedArray<ICowNameInfo>;
    allTags: FreezedArray<RanchTagDto>;
    isClinicUser: boolean;
}
type EditingItem = ProgramApplyResultItemDto & TItemCommon;
type EditingTag = Omit<ProgramApplyResultTagDto,"tags"> & TTagCommon;
/**
 * 牛情報登録時以外の事前ロード済みプログラムを選択するダイアログ
 */
export const PreloadedProgramSelectPopup = (props: PreloadedProgramSelectPopupProps) => {
    const [ items, setItems ] = useState<EditingItem[]>([]);
    const [ tags, setTags ] = useState<EditingTag[]>([]);

    useEffect(() => {
        const UNKNOWN_COW = "?";

        setItems(
            props.preloaded.list.map(i => {
                let dispCow = "";
                if (i.cow_ids.length > 0) {
                    const cow = props.cows.find(c => c.cow_id === i.cow_ids[0]);
                    if (cow == null) {
                        dispCow = UNKNOWN_COW;
                    } else {
                        dispCow = CowToDispInfo(cow, false);
                        //※手動でないプログラムにより生成される予定は1頭ずつになる想定のため、ありえないはずだが一応の対応
                        if (i.cow_ids.length >= 2) {
                            dispCow += ` 他${i.cow_ids.length - 1}頭`;
                        }
                    }
                }

                return {
                    ...i,
                    dispCow,
                    selected:true,
                    dispDate: moment(i.scheduled_date).format("MM/DD"),
                    eventKindName: EventKind.find(i.event_kind_no)?.schedule?.sname ?? ""
                }
            })
        );
        setTags(
            props.preloaded.tags.map(t => {
                const cow = props.cows.find(c => c.cow_id === t.cow_id);
                const dispCow = cow == null ? UNKNOWN_COW : CowToDispInfo(cow, false);

                const addTags = t.tags.filter(tt => tt.action_kind === TAG_ACTION.ADD)
                                    .map(tt => props.allTags.find(pt => pt.tag_id === tt.tag_id));
                const remTags = t.tags.filter(tt => tt.action_kind === TAG_ACTION.REM)
                                .map(tt => props.allTags.find(pt => pt.tag_id === tt.tag_id));

                return {
                    ...t,
                    dispCow,
                    //ローカルのタグマスタで見つからないものは捨ててしまう
                    add: {
                        selected:true,
                        tags: ar.notNull(addTags)
                    },
                    rem: {
                        selected:true,
                        tags: ar.notNull(remTags)
                    }
                }
            }).filter(t => t.add.tags.length > 0 || t.rem.tags.length > 0)
        );

    }, [ props.allTags, props.preloaded, props.cows ]);

    const onSubmit = (itemsResult:FreezedArray<EditingItem>, tagsResult:FreezedArray<EditingTag>) => {
        props.onSubmit({
            list: itemsResult.filter(i => i.selected),
            tags: tagsResult.map(tg => ({
                cow_id: tg.cow_id,
                tags: [
                    ...(tg.add.selected ? tg.add.tags.map(at => ({ tag_id:at.tag_id, action_kind:TAG_ACTION.ADD })) : []),
                    ...(tg.rem.selected ? tg.rem.tags.map(rt => ({ tag_id:rt.tag_id, action_kind:TAG_ACTION.REM })) : []),
                ],
            })).filter(tg => tg.tags.length !== 0)
        });
    }

    return (
        <CommonPopup
            items={items}
            tags={tags}
            onCancel={props.onCancel}
            onSubmit={onSubmit}
            isClinicUser={props.isClinicUser}
        />
    )
}


export interface PreloadedCowProgramSelectPopupProps {
    onSubmit:(data: PreloadedCowProgramReq) => void;
    onCancel:() => void;
    dispCow: string;
    preloaded: Readonly<ProgramOfCowApplyResultDto>;
    allTags: FreezedArray<RanchTagDto>;
    isClinicUser: boolean;
}
type CowEditingItem = ProgramOfCowApplyResultItem & TItemCommon;
type CowEditingTag = TTagCommon;
/**
 * 牛情報登録時の事前ロード済みプログラムを選択するダイアログ
 */
export const PreloadedCowProgramSelectPopup = (props: PreloadedCowProgramSelectPopupProps) => {
    const [ items, setItems ] = useState<CowEditingItem[]>([]);
    const [ tags, setTags ] = useState<CowEditingTag[]>([]);

    useEffect(() => {
        setItems(
            props.preloaded.list.map(i => {
                return {
                    ...i,
                    dispCow: props.dispCow,
                    selected:true,
                    dispDate: moment(i.scheduled_date).format("MM/DD"),
                    eventKindName: EventKind.find(i.event_kind_no)?.schedule?.sname ?? ""
                }
            })
        );

        const addTags = props.preloaded.tags
                            .filter(tt => tt.action_kind === TAG_ACTION.ADD)
                            .map(tt => props.allTags.find(pt => pt.tag_id === tt.tag_id));
        const remTags = props.preloaded.tags
                            .filter(tt => tt.action_kind === TAG_ACTION.REM)
                            .map(tt => props.allTags.find(pt => pt.tag_id === tt.tag_id));
        
        //ローカルのタグマスタで見つからないものは捨ててしまう
        const adds = ar.notNull(addTags);
        const rems = ar.notNull(remTags);
        
        if (adds.length === 0 && rems.length === 0) {
            setTags([]);
        } else {
            setTags([
                {
                    dispCow: props.dispCow,
                    add: {
                        selected:true,
                        tags: adds
                    },
                    rem: {
                        selected:true,
                        tags: rems
                    }
                }
            ]);
        }

    }, [ props.allTags, props.preloaded, props.dispCow ]);

    const onSubmit = (itemsResult:FreezedArray<CowEditingItem>, tagsResult:FreezedArray<CowEditingTag>) => {

        props.onSubmit({
            list: itemsResult.filter(i => i.selected),
            tags: ar.flat(tagsResult.map(tg => ([
                ...(tg.add.selected ? tg.add.tags.map(at => ({ tag_id:at.tag_id, action_kind:TAG_ACTION.ADD })) : []),
                ...(tg.rem.selected ? tg.rem.tags.map(rt => ({ tag_id:rt.tag_id, action_kind:TAG_ACTION.REM })) : []),
            ])))
        });
    }

    return (
        <CommonPopup
            items={items}
            tags={tags}
            onCancel={props.onCancel}
            onSubmit={onSubmit}
            isClinicUser={props.isClinicUser}
        />
    )
}


export interface CommonPopupProps<TItem extends TItemCommon, TTag extends TTagCommon> {
    onSubmit:(items:FreezedArray<TItem>, tags:FreezedArray<TTag>) => void;
    onCancel:() => void;
    items: FreezedArray<TItem>;
    tags: FreezedArray<TTag>;
    isClinicUser: boolean;
}

type TItemCommon = { selected: boolean, dispCow: string, dispDate:string, event_kind_no:number, eventKindName:string, title:string, clinic_id?:number };
type TTagCommon = {
    dispCow: string,
    add: { selected:boolean, tags: Array<{ tag_id:number, tag_name:string }> },
    rem: { selected:boolean, tags: Array<{ tag_id:number, tag_name:string }> }
};

const CommonPopup = <TItem extends TItemCommon, TTag extends TTagCommon>(props: CommonPopupProps<TItem, TTag>) => {
    const [ items, setItems ] = useState<FreezedArray<TItem>>([]);
    const [ tags, setTags ] = useState<FreezedArray<TTag>>([]);

    useEffect(() => {
        setItems(props.items);
    }, [ props.items ]);

    useEffect(() => {
        setTags(props.tags);
    }, [ props.tags ])


    const onItemSelect = useCallback((index:number, selected:boolean) => {
        setItems(
            preItems => preItems.map((p,i) => i !== index ? p : ({ ...p, selected }))
        );
    }, []);
    
    const onTagSelect = useCallback((index:number, action:"add"|"rem", selected:boolean) => {
        setTags(
            preTags => preTags.map((t,i) => i !== index ? t : ({
                ...t,
                add: action === "add" ? { ...t.add, selected } : t.add,
                rem: action === "rem" ? { ...t.rem, selected } : t.rem
            }))
        )
    }, []);

    const onSubmit = () => {
        props.onSubmit(items, tags);
    }

    return (
        <div>
            <Modal isOpen={true} scrollable={true}>
                <ModalHeader toggle={props.onCancel}></ModalHeader>
                <ModalBody>
                    <div className={styles.description}>プログラムにより、以下の項目を生成します</div>
                    { items.length > 0 && (
                        <div className={styles.group}>
                            <div className={styles.label}>
                                <Checkbox label="予定" checked={items.every(i => i.selected)}
                                    id="chkItemsAll"
                                    onChange={e => setItems(items.map(i => ({ ...i, selected:e.target.checked })))}
                                />
                            </div>
                            <Table size="sm" className={styles.table}>
                                <tbody>
                                    { items.map((item,i) => (
                                        <tr key={i}>
                                            <td>
                                                <Checkbox className={styles.checkcell} id={"chkItem_" + i} label="&nbsp;"
                                                    checked={item.selected} onChange={e => onItemSelect(i, e.target.checked)}
                                                />
                                            </td>
                                            { props.isClinicUser && (
                                                <td className={styles.ranch}>{item.clinic_id == null ? "牧" : ""}</td>
                                            )}
                                            <td className={item.selected ? "" : styles.unchecked}>{item.dispCow}</td>
                                            <td className={item.selected ? "" : styles.unchecked}>{item.dispDate}</td>
                                            <td className={item.selected ? "" : styles.unchecked}>[{item.eventKindName}] {item.title}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            { props.isClinicUser && items.some(p => p.clinic_id == null) && (
                                <div className={styles.hint}>※牧：牧場カレンダーのみに追加されます</div>
                            )}
                        </div>
                    )}
                    { tags.length > 0 && (
                        <div className={styles.group}>
                            <div className={styles.label}>
                                <Checkbox label="タグ" checked={tags.every(t => t.add.selected && t.rem.selected)}
                                    id="chkTagsAll"
                                    onChange={e => setTags(tags.map(t => ({
                                        ...t,
                                        add: { ...t.add, selected:e.target.checked },
                                        rem: { ...t.rem, selected:e.target.checked }
                                    })))}
                                />
                            </div>
                            <Table size="sm" className={styles.table}>
                                <tbody>
                                    { tags.map((tag,i) => (
                                        <Fragment key={i}>
                                            { tag.add.tags.length > 0 && (
                                                <tr>
                                                    <td>
                                                        <Checkbox className={styles.checkcell} id={"chkAddTag_" + i} label="&nbsp;"
                                                            checked={tag.add.selected} onChange={e => onTagSelect(i, "add", e.target.checked)}
                                                        />
                                                    </td>
                                                    <td className={tag.add.selected ? "" : styles.unchecked}>{tag.dispCow}</td>
                                                    <td className={names(styles["cell-tag"], {[styles.unchecked]: !tag.add.selected })}>
                                                        { tag.add.tags.map(at => (
                                                            <div key={at.tag_id} className={styles.tag}>{at.tag_name}</div>
                                                        ))}
                                                        <span>をつける</span>
                                                    </td>
                                                </tr>
                                            )}
                                            { tag.rem.tags.length > 0 && (
                                                <tr>
                                                    <td>
                                                        <Checkbox className={styles.checkcell} id={"chkRemTag_" + i} label="&nbsp;"
                                                            checked={tag.rem.selected} onChange={e => onTagSelect(i, "rem", e.target.checked)}
                                                        />
                                                    </td>
                                                    <td className={tag.rem.selected ? "" : styles.unchecked}>{tag.dispCow}</td>
                                                    <td className={names(styles["cell-tag"], {[styles.unchecked]: !tag.rem.selected })}>
                                                        { tag.rem.tags.map(rt => (
                                                            <div key={rt.tag_id} className={styles.tag}>{rt.tag_name}</div>
                                                        ))}
                                                        <span>をはずす</span>
                                                    </td>
                                                </tr>
                                            )}
                                        </Fragment>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    )}

                </ModalBody>
                <ModalFooter className="modal-footer-fix">
                    <ExecutionButton type="save" onClick={onSubmit}>実行</ExecutionButton>
                    <ExecutionButton type="cancel" onClick={props.onCancel} />
                </ModalFooter>
            </Modal>
        </div>
    )
}