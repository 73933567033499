import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import styles from '../feedbulk.module.css'

type PropType = {
  showModal    : boolean,
  setShowModal : (boolean) => void,
  moved        : number,
  watched      : number,
  overeat      : number,
}

const NoticeModal: React.FC<PropType> = ({ showModal, setShowModal, moved, watched, overeat }) => (
  <div className="modal-wide">
    <Modal isOpen={ showModal } toggle={e => setShowModal(false)} className={ styles['modal-wrapper'] } style={{ maxWidth: "300px", margin: "auto" }}>
      <ModalHeader>注意</ModalHeader>
      <ModalBody className={ styles['modal-body'] }>
        {moved > 0 &&
          <div className={ styles.noticerow }>
            <p><i className="fas fa-warehouse"></i> 最近場所を移動した牛</p>
            <p data-testid="移動属性牛数">{ `${ moved } 頭` }</p>
          </div>
        }
        {watched > 0 &&
          <div className={ styles.noticerow }>
            <p><i className="fas fa-exclamation-triangle"></i> 要観察の牛</p>
            <p data-testid="要観察属性牛数">{ `${ watched } 頭` }</p>
          </div>
        }
        {overeat > 0 &&
          <div className={ styles.noticerow }>
            <p><i className="fab fa-2x fa-pagelines"></i> 近い時間に給餌記録のある牛</p>
            <p data-testid="過食属性牛数">{ `${ overeat } 頭` }</p>
          </div>
        }
      </ModalBody>
      <ModalFooter>
        <button data-testid="注意閉じ" className={ styles.bluebutton } onClick={e => setShowModal(false)}>閉じる</button>
      </ModalFooter>
    </Modal>
  </div>
)

export default NoticeModal

