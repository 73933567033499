import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { Checkbox } from '../../components/form/form-checkbox';
import { ModalInputBlock } from '../../components/parts/modal-input-block';
import { RequiredNumInput } from '../../components/parts/num-input';
import styles from './cert-karte.module.css';

export interface CertKarteListCondition {
    hasBreeding: boolean;
    hasSymptom: boolean;
    healingDays: number;
}

interface MyProps {
    condition: CertKarteListCondition;
    onClose: () => void;
    onSubmit: (condition: CertKarteListCondition) => void;
}

export const CertKarteConditionSelectPopup = (props: MyProps) => {
    const [ data, setData ] = useState<CertKarteListCondition>({ hasSymptom:false, hasBreeding:false, healingDays:0 });

    useEffect(() => {
        setData(props.condition);

    }, [ props.condition ])

    const isValid = data.hasSymptom || data.hasBreeding;

    return (
        <div>
            <Modal isOpen={true} scrollable={true}>
                <ModalHeader toggle={props.onClose}>検索条件選択</ModalHeader>
                <ModalBody>
                    <ModalInputBlock label="検索対象">
                        <Checkbox label="診療記録" id="chkSymptom" checked={data.hasSymptom}
                            onChange={e => setData({ ...data, hasSymptom:e.target.checked })}
                        />
                        <Checkbox label="検診記録" id="chkBreeding" checked={data.hasBreeding}
                            onChange={e => setData({ ...data, hasBreeding:e.target.checked })}
                        />
                    </ModalInputBlock>
                    <ModalInputBlock label="治癒転帰日" contentClassName={styles["condition-healing-days"]}>
                        <div>診療記録で「治癒」の場合、終診日の</div>
                        <div>
                            <RequiredNumInput
                                disabled={!data.hasSymptom}
                                value={data.healingDays}
                                step={1} min={0} max={50}
                                onChange={n => setData({ ...data, healingDays:n })}
                            />
                            <div>日後を転帰日とする</div>
                        </div>
                    </ModalInputBlock>
                </ModalBody>
                <ModalFooter className="modal-footer-fix">
                    <ExecutionButton type="decide" disabled={!isValid} onClick={() => props.onSubmit(data)} />
                </ModalFooter>
            </Modal>
        </div>
    )

}