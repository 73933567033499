import { IUser } from "../stores";
import { ar } from "./util";
import { TeamType } from "./constant";
import { UserTeams } from "./user-teams";

export const RANCH_AUTH_LEVELS = [
//    { lv: 0, name: "ゲスト" },
//    { lv: 1, name: "閲覧者" },
    { lv: 1, name: "閲覧者" },
    { lv: 2, name: "作業者" },
    { lv: 3, name: "牧場情報管理者" },
    { lv: 4, name: "ユーザ管理者" },
] as const;
export const CLINIC_AUTH_LEVELS = [
    { lv: 2, name: "作業者" },
    { lv: 3, name: "診療所情報管理者" },
    { lv: 4, name: "ユーザ管理者" },
] as const;
export const getAuthLevels = (teamType:TeamType): Readonly<{lv:number, name:string}[]> => {
    if (teamType === "ranch") {
        return RANCH_AUTH_LEVELS;
    }
    if (teamType === "clinic") {
        return CLINIC_AUTH_LEVELS;
    }
    return [];
}

export const getAuthLevelName = (teamType:TeamType, lv:number) => {
    if (teamType === "ranch") {
        return RANCH_AUTH_LEVELS.find(r => r.lv === lv)?.name;
    }
    if (teamType === "clinic") {
        return CLINIC_AUTH_LEVELS.find(c => c.lv === lv)?.name;
    }
    return undefined;
}

export const RANCH_AUTH_ITEMS = [
    "BROWSE_SUMMARY",
    "BROWSE_INSIDE",
    "FOODING_REF_NAME",
    "FOODING_REF_AMOUNT",
    "FOODING_REF_PRICE",
    "FOODING_EDIT",
    "TREAT_REF_NAME",
    "TREAT_REF_AMOUNT",
    "TREAT_REF_PRICE",
    "TREAT_EDIT",
    "CROSS_REF_NAME",
    "CROSS_REF_PRICE",
    "BREEDING_EDIT",
    "GROWTH_EDIT",
    "COW_EDIT",
    "MASTER_EDIT",
    "BALANCE_COW",
    "BALANCE_RANCH",
    "REPORT_RANCH",
    "USER_MANAGEMENT",
] as const;
export const CLINIC_AUTH_ITEMS = [
    "MASTER_REF",
    "MASTER_EDIT",
    "USER_MANAGEMENT",
] as const;

export type RanchAuthItemKey = typeof RANCH_AUTH_ITEMS[number];
export type ClinicAuthItemKey = typeof CLINIC_AUTH_ITEMS[number];
type AuthItem = {
    name:string,
    lv:number,
    detail:number|undefined,
};
type RanchAuthItem = AuthItem & { requires?: Readonly<RanchAuthItemKey[]>, forUnofficial: ClinicAuthItemKey|undefined };
type ClinicAuthItem = AuthItem & { requires?: Readonly<ClinicAuthItemKey[]> };
const RANCH_AUTH_ITEM: { [key in RanchAuthItemKey]: RanchAuthItem } = {
    BROWSE_SUMMARY      : { lv: 0, detail: undefined, name: "記録概要閲覧",         forUnofficial:"MASTER_REF" },   
    BROWSE_INSIDE       : { lv: 1, detail: undefined, name: "牧場内共有情報の閲覧", forUnofficial:"MASTER_REF" },
    FOODING_REF_NAME    : { lv: 2, detail: 0x0001,    name: "えさ記録品目閲覧",     forUnofficial:undefined },
    FOODING_REF_AMOUNT  : { lv: 2, detail: 0x0002,    name: "えさ記録給餌量閲覧",   forUnofficial:undefined, requires: [ "FOODING_REF_NAME" ] },
    FOODING_REF_PRICE   : { lv: 2, detail: 0x0004,    name: "えさ記録価格閲覧",     forUnofficial:undefined, requires: [ "FOODING_REF_AMOUNT" ] },
    FOODING_EDIT        : { lv: 2, detail: 0x0008,    name: "えさ記録編集",         forUnofficial:undefined, requires: [ "FOODING_REF_PRICE" ] },
    TREAT_REF_NAME      : { lv: 2, detail: 0x0010,    name: "投薬記録薬名閲覧",     forUnofficial:"MASTER_REF" },
    TREAT_REF_AMOUNT    : { lv: 2, detail: 0x0020,    name: "投薬記録投与量閲覧",   forUnofficial:"MASTER_REF", requires: [ "TREAT_REF_NAME" ] },
    TREAT_REF_PRICE     : { lv: 2, detail: 0x0040,    name: "投薬記録価格閲覧",     forUnofficial:"MASTER_REF", requires: [ "TREAT_REF_AMOUNT" ] },
    TREAT_EDIT          : { lv: 2, detail: 0x0080,    name: "診療記録編集",         forUnofficial:"MASTER_REF", requires:[ "TREAT_REF_PRICE" ] },
    CROSS_REF_NAME      : { lv: 2, detail: 0x0100,    name: "交配記録血統閲覧",     forUnofficial:"MASTER_REF" },
    CROSS_REF_PRICE     : { lv: 2, detail: 0x0200,    name: "交配記録詳細閲覧",     forUnofficial:"MASTER_REF", requires: [ "CROSS_REF_NAME" ] },
    BREEDING_EDIT       : { lv: 2, detail: 0x0400,    name: "繁殖記録編集",         forUnofficial:"MASTER_REF", requires: [ "CROSS_REF_PRICE", "TREAT_EDIT" ] },
    GROWTH_EDIT         : { lv: 2, detail: 0x0800,    name: "身体測定値編集",       forUnofficial:undefined },
    COW_EDIT            : { lv: 3, detail: 0x0001,    name: "牛の登録編集",         forUnofficial:"MASTER_EDIT" },
    MASTER_EDIT         : { lv: 3, detail: 0x0002,    name: "牧場固有リスト編集",   forUnofficial:"MASTER_EDIT" },
    BALANCE_COW         : { lv: 3, detail: 0x0004,    name: "牛個体収支",           forUnofficial:undefined },
    BALANCE_RANCH       : { lv: 3, detail: 0x0008,    name: "牧場収支",             forUnofficial:undefined, requires:[ "BALANCE_COW" ] },
    REPORT_RANCH        : { lv: 3, detail: 0x0010,    name: "牧場統計",             forUnofficial:undefined, requires:[ "BALANCE_COW" ] },
    USER_MANAGEMENT     : { lv: 4, detail: undefined, name: "ユーザ管理",           forUnofficial:undefined },
} as const;
const CLINIC_AUTH_ITEM: { [key in ClinicAuthItemKey]: ClinicAuthItem } = {
    MASTER_REF      : { lv: 2, detail: undefined, name: "診療所固有リスト参照" },
    MASTER_EDIT     : { lv: 3, detail: undefined, name: "診療所固有リスト編集" },
    USER_MANAGEMENT : { lv: 4, detail: undefined, name: "ユーザ管理" },
} as const;

export const hasAuthLvDetail = (teamType:TeamType, lv:number) => {
    const items: AuthItem[] = (teamType ==="ranch" ? Object.values(RANCH_AUTH_ITEM) : Object.values(CLINIC_AUTH_ITEM));
    return items.some(a => a.lv === lv && a.detail != null);
}

export const RANCH_AUTH_UI_ITEMS = [
    "BROWSE_SUMMARY",
    "BROWSE_FEEDING_TREAT",
    "BROWSE_CROSS",
    "EDIT_RECORD",
    "GROWTH_EDIT",
    "COW_EDIT",
    "MASTER_EDIT",
    "BALANCE_COW",
    "BALANCE_RANCH",
    "REPORT_RANCH",
    "USER_MANAGEMENT",    
] as const;
export const CLINIC_AUTH_UI_ITEMS = CLINIC_AUTH_ITEMS;
export type RanchAuthUIItemKey = typeof RANCH_AUTH_UI_ITEMS[number];
export type ClinicAuthUIItemKey = typeof CLINIC_AUTH_UI_ITEMS[number];

//UI選択項目の単位に権限項目をまとめる
const _groupRanchAuthItemsForUI = (name:string, keys:RanchAuthItemKey[]): RanchAuthItem => {
    const items = keys.map(k => RANCH_AUTH_ITEM[k]);
    const lvs = [...new Set(items.map(i => i.lv))];
    if (lvs.length !== 1) throw Error("異なるレベルの権限マージ");

    return ({
        lv: lvs[0],
        name,
        detail: items.every(i => i.detail == null) ? undefined : items.reduce((a,b) => (a | (b.detail ?? 0)), 0),
        requires: items.every(i => i.requires == null) ? undefined : ar.flat(items.map(i => [...(i.requires ?? [])])),
        forUnofficial: undefined
    });
}

const RANCH_AUTH_UI_ITEM: { [key in RanchAuthUIItemKey]: RanchAuthItem } = {
    BROWSE_SUMMARY: RANCH_AUTH_ITEM.BROWSE_INSIDE,
    BROWSE_FEEDING_TREAT: _groupRanchAuthItemsForUI("えさ・薬品詳細閲覧", ["FOODING_REF_NAME","FOODING_REF_AMOUNT","FOODING_REF_PRICE","TREAT_REF_NAME","TREAT_REF_AMOUNT","TREAT_REF_PRICE"]),
    BROWSE_CROSS: _groupRanchAuthItemsForUI("交配詳細閲覧", ["CROSS_REF_NAME","CROSS_REF_PRICE"]),
    EDIT_RECORD: _groupRanchAuthItemsForUI("えさ・診療・繁殖記録編集", ["FOODING_EDIT","TREAT_EDIT","BREEDING_EDIT"]),
    GROWTH_EDIT: RANCH_AUTH_ITEM.GROWTH_EDIT,
    COW_EDIT: RANCH_AUTH_ITEM.COW_EDIT,
    MASTER_EDIT: RANCH_AUTH_ITEM.MASTER_EDIT,
    BALANCE_COW: RANCH_AUTH_ITEM.BALANCE_COW,
    BALANCE_RANCH: RANCH_AUTH_ITEM.BALANCE_RANCH,
    REPORT_RANCH: RANCH_AUTH_ITEM.REPORT_RANCH,
    USER_MANAGEMENT: RANCH_AUTH_ITEM.USER_MANAGEMENT
}

const CLINIC_AUTH_UI_ITEM: { [key in ClinicAuthUIItemKey]: ClinicAuthItem } = CLINIC_AUTH_ITEM;

export const getAuthUIDetailItems = (teamType:TeamType, level: number): { key:string, name:string }[] => {
    if (teamType === "ranch") {
        return RANCH_AUTH_UI_ITEMS.filter(k => RANCH_AUTH_UI_ITEM[k].lv === level && RANCH_AUTH_UI_ITEM[k].detail != null)
            .map(k => ({ key:k, name:RANCH_AUTH_UI_ITEM[k].name }));
    }
    if (teamType === "clinic") {
        return CLINIC_AUTH_UI_ITEMS.filter(k => CLINIC_AUTH_UI_ITEM[k].lv === level && CLINIC_AUTH_UI_ITEM[k].detail != null)
            .map(k => ({ key:k, name:CLINIC_AUTH_UI_ITEM[k].name }));
    }
    return [];
}
export const findAuthUIDetailsByValue = (teamType:TeamType, level: number, value: number) => {
    let items: { key: string, item: AuthItem }[];
    if (teamType === "ranch") {
        items = RANCH_AUTH_UI_ITEMS.map(k => ({ key: k, item: RANCH_AUTH_UI_ITEM[k] }));

    } else if (teamType === "clinic") {
        items = CLINIC_AUTH_UI_ITEMS.map(k => ({ key: k, item: CLINIC_AUTH_UI_ITEM[k] }));

    } else {
        console.error("invalid teamType " + teamType);
        return [];
    }

    return items.filter(i => i.item.lv === level && i.item.detail != null && (i.item.detail & value) === i.item.detail)
                .map(i => i.key);
}


export const getAuthLevelDetailInfo = (teamType:TeamType, keys:string[]) => {
    let names: string[];
    if (teamType === "ranch") {
        names = (keys as RanchAuthUIItemKey[]).map(k => RANCH_AUTH_UI_ITEM[k].name);
    } else if (teamType === "clinic") {
        names = (keys as ClinicAuthItemKey[]).map(k => CLINIC_AUTH_UI_ITEM[k].name);
    } else {
        return "";
    }
    return names.length === 0 ? "" : `（${names.join("／")}）`;
}

export const hasRanchAuth = (item: RanchAuthItemKey, ranch_id: number, user: Readonly<IUser>): boolean => {
    const ranch = new UserTeams(user).findOfficialRanch(ranch_id);
    if (ranch == null) {
        return hasUnofficialRanchAuth(item, ranch_id, user);
    }

    const authItem = RANCH_AUTH_ITEM[item];
    return _hasAuth(authItem, ranch);
}

export const hasClinicAuth = (item: ClinicAuthItemKey, clinic_id: number, user: Readonly<IUser>): boolean => {
    const clinic = new UserTeams(user).findClinic(clinic_id);
    if (clinic == null) return false;

    const authItem = CLINIC_AUTH_ITEM[item];
    return _hasAuth(authItem, clinic);
}

export const hasTeamAuth = (itemForRanch: RanchAuthItemKey, itemForClinic: ClinicAuthItemKey, team_id: number, user: Readonly<IUser>): boolean => {
    const team = new UserTeams(user).findOfficialTeam(team_id);
    if (team == null) {
        return hasUnofficialRanchAuth(itemForRanch, team_id, user);
    }

    const authItem = team.isRanch ? RANCH_AUTH_ITEM[itemForRanch] : CLINIC_AUTH_ITEM[itemForClinic];
    return _hasAuth(authItem, team);
}

const hasUnofficialRanchAuth = (item: RanchAuthItemKey, ranch_id: number, user: Readonly<IUser>) => {
    const clinicAndRanch = new UserTeams(user).findUnofficialRanch(ranch_id);
    if (clinicAndRanch == null) return false;

    const ranchAuth = RANCH_AUTH_ITEM[item];
    if (ranchAuth.forUnofficial == null) return false;
    const authForClinic = CLINIC_AUTH_ITEM[ranchAuth.forUnofficial];
    return _hasAuth(authForClinic, clinicAndRanch.clinic);
}

const _hasAuth = (authItem: AuthItem, userAuth: { level:number, level_detail:number }) => {
    if (authItem.lv < userAuth.level) return true;
    if (userAuth.level < authItem.lv) return false;

    if (authItem.detail == null) return true;
    return (userAuth.level_detail & authItem.detail) === authItem.detail;
}


const _isFilled = (targetItem: AuthItem, byLevel: number, byDetails: AuthItem[]) => {
    if (targetItem.lv < byLevel) return true;
    if (byLevel < targetItem.lv) return false;

    const trgDetail = targetItem.detail;
    if (trgDetail == null) return true;

    return byDetails.some(d => ((d.detail ?? 0) & trgDetail) === trgDetail);
}
export const isRanchAuthFilled = (target: RanchAuthItemKey, byLevel:number, byDetails: RanchAuthUIItemKey[]) => {
    const targetItem = RANCH_AUTH_ITEM[target];
    const byDetailItems = byDetails.map(d => RANCH_AUTH_UI_ITEM[d]);

    return _isFilled(targetItem, byLevel, byDetailItems);
}
export const isClinicAuthFilled = (target: ClinicAuthItemKey, byLevel: number, byDetails:ClinicAuthUIItemKey[]) => {
    const targetItem = CLINIC_AUTH_ITEM[target];
    const byDetailItems = byDetails.map(d => CLINIC_AUTH_UI_ITEM[d]);

    return _isFilled(targetItem, byLevel, byDetailItems);
}

export const getRanchAuthDetailValue = (keys: RanchAuthUIItemKey[]) => {
    return keys.map(k => RANCH_AUTH_UI_ITEM[k]).reduce((a,b) => (a | (b.detail ?? 0)), 0);
}
export const getClinicAuthDetailValue = (keys: ClinicAuthUIItemKey[]) => {
    return keys.map(k => CLINIC_AUTH_UI_ITEM[k]).reduce((a,b) => (a | (b.detail ?? 0)), 0);
}

export type RequiredAuthName = { required:string, selected: string }
const _getRequiredAuth = (items: Array<AuthItem & { requires: AuthItem[] }>, allUIItems: AuthItem[]) => {
    if (items.length === 0) return undefined;
    if (ar.distinct(items.map(i => i.lv)).length !== 1) {
        console.error("invalid parameter for getRequiredAuth", items);
        return undefined;
    }

    const allValue = items.reduce((pre,i) => (pre | (i.detail ?? 0)), 0);

    for (const item of items) {
        const less = item.requires.find(r => r.detail != null && (r.detail & allValue) !== r.detail);
        if (less == null) continue;

        //この値を含む項目をUI権限項目から探す
        const lessUI = allUIItems.find(ui => ui.lv === less.lv && ((ui.detail ?? 0) & less.detail!) === less.detail);
        if (lessUI == null) {
            console.error("ui auth item not found", less);
            return undefined;
        }

        return ({ required:lessUI.name, selected:item.name });
    }

    return undefined;
}

export const getRequiredRanchAuth = (keys: RanchAuthUIItemKey[]) => {
    const items = keys.map(k => RANCH_AUTH_UI_ITEM[k])
                    .map(i => ({ ...i, requires: (i.requires ?? []).map(k => RANCH_AUTH_ITEM[k])}));
    const allUIItems = Object.values(RANCH_AUTH_UI_ITEM);

    return _getRequiredAuth(items, allUIItems);
}
export const getRequiredClinicAuth = (keys: ClinicAuthUIItemKey[]) => {
    const items = keys.map(k => CLINIC_AUTH_UI_ITEM[k])
                    .map(i => ({ ...i, requires: (i.requires ?? []).map(k => CLINIC_AUTH_ITEM[k])}));
    const allUIItems = Object.values(CLINIC_AUTH_UI_ITEM);

    return _getRequiredAuth(items, allUIItems);
}