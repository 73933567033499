import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import styles from './header.module.css'

type PropType = {
  onClose : () => void,
  onGrantAuthority : () => void,
}

const AcceptPopup: React.FC<PropType> = ({ onClose, onGrantAuthority }) => (
  <div className="modal-wide">
    <Modal isOpen={true} toggle={e => onClose()} className={ styles['modal-wrapper'] } style={{ maxWidth: "300px", margin: "auto" }}>
      <ModalHeader className="popup-title" style={{ borderBottom: "none" }} toggle={e => onClose()}></ModalHeader>
      <ModalBody className={ styles['modal-body'] }>
          <div className={ styles.acceptrow }>
            <p>申請を許可しました。</p>
          </div>
          <div className={ styles.authorityrow }>
            <a className="link" onClick={() => onGrantAuthority()}>ユーザ権限の設定</a>
          </div>
      </ModalBody>
    </Modal>
  </div>
)

export default AcceptPopup