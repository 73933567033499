import React from 'react';
import Base, { BaseProps } from '../../components/content/base';

import moment from 'moment';
import { withContext } from '../../stores';
import { withRouter } from 'react-router-dom';
import { PageSettings } from '../../config/page-settings';
import { ICowNameInfo } from '../../components/parts/cows-popup';
import { CowApi, BreedingApi, DeliveryModifyReq, BreedingCrossCurrentCrossDto, CowBreedingModifyReq, DeliveryApi } from '../../api';
import { CommonUtil } from '../../config/util';
import { A, BREEDING_STATE, LMT, TIMEPRESETS, EVENT_TYPE_PARAM } from '../../config/constant';
import Slider from 'rc-slider';
import { InfoPopup } from '../../components/parts/info-popup';
import { AppState } from '../../app';
import { historyLocation, IEventWriteLocationState } from '../../config/history-location-builder';
import { ScheduleFinder } from '../schedule/schedule-finder';
import { BREEDING_STATE_CONFIRMED, DEFAULT_OPEN_DAYS_AFTER_DELIVERY, BreedingStateConfirmedKey } from '../../components/parts/breeding-state-selector';
import { hasOpenDay } from '../breeding/breeding-state';
import { hasRanchAuth } from '../../config/auth-checker';
import { EVENT_KIND } from '../../config/event-kind';
import { Communicator } from '../../api/communicator';
import { V3DateTime } from '../../components/parts/v3-date-time-picker';
import { OptionalNumInput } from '../../components/parts/num-input';
import { PreloadedProgramSelectPopupProps, PreloadedProgramSelectPopup, showPreloadedProgramSelectDialog } from '../program/preloaded-program-select-popup';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { DIALOG_BUTTONS } from '../../components/form/form-dialog';
import { getCowWithCache, resetCow } from 'stores/fetcher_cow';
import { SingleCowHeader } from 'components/cow-header/single-cow-header';

export interface ICowChildWriteParam {
    is_male: 0 | 1;
    is_active: boolean;
    use_no: number;
    birthday: Date;
    mother?: ICowNameInfo;
    mother_name?: string;
    surrogate_mother?: ICowNameInfo;
    ancestor_1?: string;
    ancestor_2?: string;
    ancestor_3?: string;
    delivery_id: number;
    delivery_child_no: number;
    weight: number | undefined;
}

export type ICowAndAncestor = ICowNameInfo & {
    ancestor_1?: string;
    ancestor_2?: string;
    ancestor_3?: string;
    ancestor_1_exid: number | undefined;
    ancestor_2_exid: number | undefined;
    ancestor_3_exid: number | undefined;
}

const getSeedFromCross = async (ranch_id: number, cross_no: number, comm: Communicator) => {
    const res = await comm.send((await BreedingApi()).getCrossSeedUsingPOST({ ranch_id, id: cross_no }), { showsErrorMessage:false });
    if (res.result !== "OK" || res.data == null) return null;

    return res.data;
}

export const buildWriteChildrenParams = async(
    ranch_id: number,
    getCowInfo: (cow_id:number) => Promise<ICowAndAncestor|null>,
    mother_id: number,
    delivery_id: number,
    cross: Omit<BreedingCrossCurrentCrossDto, "watched_at"> | null,
    birthday: moment.Moment,
    children: { is_male:0|1, life_status:number, child_no:number, weight_kg: number | undefined }[],
    communicator: Communicator
) => {
    const parent = await getCowInfo(mother_id);
    if (parent == null) return undefined;

    let mother: ICowNameInfo | undefined;
    let mother_name: string | undefined;
    let surrogate_mother: ICowNameInfo | undefined;
    let ancestor_1: { id:number, name:string} | undefined;
    let ancestor_2: { id:number, name:string} | undefined;
    let ancestor_3: { id:number, name:string} | undefined;
    const toAncestor = (id: number | undefined, name: string | undefined) => id == null ? undefined : ({ id, name: name ?? "" });

    if (cross != null) {
        //ET
        if (cross.cross_type === A.CROSS_TYPE.get("ET")!.no) {
            surrogate_mother = {
                cow_id: parent.cow_id,
                local_no: parent.local_no,
                trace_id: parent.trace_id,
                name: parent.name
            };

            const egg = await getSeedFromCross(ranch_id, cross.cross_no, communicator);
            if (egg != null) {
                ancestor_1 = toAncestor(egg.father_exid, egg.ancestor_1);
                ancestor_2 = toAncestor(egg.ancestor_2_exid, egg.ancestor_2);
                ancestor_3 = toAncestor(egg.ancestor_3_exid, egg.ancestor_3);
                mother = egg.mother;
            } else {
                ancestor_1 = toAncestor(cross.father_exid, cross.father_name);
                ancestor_2 = toAncestor(cross.ancestor_2_exid, cross.ancestor_2);
                ancestor_3 = toAncestor(cross.ancestor_3_exid, cross.ancestor_3);
                mother_name = cross.mother_name;
                if (cross.mother_id != null) {
                    mother = await getCowInfo(cross.mother_id) ?? undefined;
                }
            }
        }
        //AI
        else if (cross.cross_type === A.CROSS_TYPE.get("AI")!.no) {
            mother = {
                cow_id: parent.cow_id,
                local_no: parent.local_no,
                trace_id: parent.trace_id,
                name: parent.name
            };
            ancestor_2 = toAncestor(parent.ancestor_1_exid, parent.ancestor_1);
            ancestor_3 = toAncestor(parent.ancestor_2_exid, parent.ancestor_2);
            const seed = await getSeedFromCross(ranch_id, cross.cross_no, communicator);
            if (seed != null) {
                ancestor_1 = toAncestor(seed.father_exid, seed.name);
            } else {
                ancestor_1 = toAncestor(cross.father_exid, cross.father_name);
            }
        }
        //本交
        else {
            mother = {
                cow_id: parent.cow_id,
                local_no: parent.local_no,
                trace_id: parent.trace_id,
                name: parent.name
            };
            ancestor_1 = toAncestor(cross.father_exid, cross.father_name);
            ancestor_2 = toAncestor(parent.ancestor_1_exid, parent.ancestor_1);
            ancestor_3 = toAncestor(parent.ancestor_2_exid, parent.ancestor_2);
        }
    }

    const param: ICowChildWriteParam[] = children.map(ch => ({
        is_male: ch.is_male,
        is_active: ch.life_status === 1,
        use_no: A.CHILD_USE_NO,
        birthday: birthday.toDate(),
        mother,
        mother_name,
        surrogate_mother,
        ancestor_1: ancestor_1?.name,
        ancestor_2: ancestor_2?.name,
        ancestor_3: ancestor_3?.name,
        ancestor_1_exid: ancestor_1?.id,
        ancestor_2_exid: ancestor_2?.id,
        ancestor_3_exid: ancestor_3?.id,
        delivery_id: delivery_id,
        delivery_child_no: ch.child_no,
        weight: ch.weight_kg
    }));

    console.log("param", param);
    return { defaults: param };
}

interface IDelivery {
    delivery_id: number;
    ranch_id: number;
    cow_id: number;
    delivered_at: string;
    delivered_by: string;
    is_aborted: 0 | 1;
    difficulty: number;
    comment: string;
    created_by: string;
    cross_no?: number;
    children: IDeliveryCow[];
}
interface IDeliveryCow {
    child_no?:number;
    life_status: number;
    is_male: 0 | 1;
    weight_kg?:number;
    cow_id?:number;
}

interface MyState {
    cow_top: boolean;
    original?: Readonly<IDelivery>;
    original_id?: number;
    cow_id?: number;
    cow?: Readonly<ICowNameInfo>;
    data: Readonly<IData>;
    executing: boolean;
    preloadedProgram?: PreloadedProgramSelectPopupProps;
}
interface IData {
    delivered_at: Date;
    is_aborted: boolean;
    difficulty: number;
    cows: IDeliveryCow[];
    comment: string;
}

class DeliveryWrite extends Base<BaseProps<{id:string},{},IEventWriteLocationState|undefined>, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            cow_top: false,
            data: {
                delivered_at: new Date(),
                is_aborted: false,
                difficulty: 0,
                cows: [ { life_status: 1, is_male: 1 } ],
                comment: ""
            },
            executing: false
        };

        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    private getParamCowTop(props: this["props"]) {
        const parm = new URLSearchParams(props.location.search).get("cow_top");
        if (parm == null) return false;

        return parm === "1";
    }

    private getParamCowId(props: this["props"]) {
        return new URLSearchParams(props.location.search).get("param");
    }

    componentDidUpdate(prevProps: this["props"]) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.init();
            return;
        }
        if (this.getParamCowId(this.props) !== this.getParamCowId(prevProps)) {
            this.init();
            return;
        }
    }

    componentDidMount() {
        if (this.handleNotAllowAccess(undefined, ["BREEDING_EDIT"], [], ["BREEDING"])) {
            return;
        }
        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        this.init();
    }

    private init() {
        const id = parseInt(this.props.match.params.id);
        if (isNaN(id)) {
            this.context.handleSetHeader({ title:"分娩記録を入力" });
        }
        else {
            this.context.handleSetHeader({ title:"分娩記録を編集" });
        }

        //※複数頭一括入力には対応しない
        //　（カンマ区切りの牛IDでは先頭のみ対象になる）
        const cowId = parseInt(this.getParamCowId(this.props) ?? "");
        const cowTop = this.getParamCowTop(this.props);

        this.setState({
            original_id: isNaN(id) ? undefined : id,
            cow_id: isNaN(cowId) ? undefined : cowId,
            cow_top: cowTop
        }, async ()=> {
            if (this.state.original_id != null) {
                await this.loadDelivery(this.state.original_id);
            }

            if (this.state.cow_id != null) {
                await this.loadCowInfo();
            }
        });
    }

    private async loadDelivery(id: number) {
        const url = "/delivery/" + id;
        this.context.handleSetIsLoading(true);
        const res = await this.comm().send(() => this.context.postAsync(url, {}), { retries: true });
        this.context.handleSetIsLoading(false);

        if(res.result === "OK" && res.data != null) {
            const data = res.data as IDelivery;

            if (this.navToRanchTopIfSelfEditOnly("DELIVERY", data.delivered_by)) return;

            return await this.setStateAsync({
                original: data,
                cow_id: data.cow_id,
                data: {
                    delivered_at: moment(data.delivered_at).toDate(),
                    is_aborted: data.is_aborted === 1,
                    difficulty: data.difficulty,
                    cows: data.children,
                    comment: data.comment
                }
            });
        }
    }

    private async loadCowInfo() {
        const cowId = this.state.cow_id;
        if (!CommonUtil.assertNotNull(cowId, "cow_id", "loadCowInfo")) {
            return Promise.resolve();
        }

        const data = await this.apiGetCow(cowId, true);
        if (data != null) {

            return this.setStateAsync({
                cow: {
                    cow_id: data.cow_id,
                    trace_id: data.trace_id,
                    local_no: data.local_no,
                    name: data.name
                },
            });
        }
    }

    private onChildCountChange(count: number) {
        const newChilds = [...this.state.data.cows]; //※shallow copy
        const abortLifeNo = A.LIFE_STATES.find(l => l.aborted)?.no;
        if (!CommonUtil.assertNotNull(abortLifeNo)) return;

        while (newChilds.length < count) {
            const nextIdx = newChilds.length;
            const oriCows = this.state.original?.children ?? [];
            
            if (nextIdx < oriCows.length) {
                const ori = oriCows[nextIdx];
                newChilds.push({...ori, life_status: this.state.data.is_aborted ? abortLifeNo : ori.life_status});
            } else {
                newChilds.push({ life_status: this.state.data.is_aborted ? abortLifeNo : 1, is_male: 1 });
            }
        }
        while (count < newChilds.length) {
            newChilds.pop();
        }
        this.setState({
            data: { ...this.state.data, cows: newChilds }
        });
    }
    private onLifeChange(idx: number, value:string) {
        const val = parseInt(value);
        if (isNaN(val)) return;

        const target = this.state.data.cows[idx];
        const newChild = { ...target, life_status: val };
        const newChilds = [...this.state.data.cows];
        newChilds[idx] = newChild;

        this.setState({data:{...this.state.data, cows:newChilds}});
    }
    private onIsMaleChange(idx: number, isMale:boolean) {
        const target = this.state.data.cows[idx];
        const newChild: IDeliveryCow = { ...target, is_male: isMale ? 1 : 0 };
        const newChilds = [...this.state.data.cows];
        newChilds[idx] = newChild;
        
        this.setState({data:{...this.state.data, cows:newChilds}});
    }

    private onIsAbortedChanged(isAborted: boolean) {
        if (!isAborted) {
            this.setState({
                data:{...this.state.data, is_aborted:false}
            });
            return;
        }
        const lifeNo = A.LIFE_STATES.find(l => l.aborted)?.no;
        if (!CommonUtil.assertNotNull(lifeNo)) return;

        const newChilds = this.state.data.cows
                            .map(c => ({ ...c, life_status: lifeNo}));
        this.setState({
            data: { ...this.state.data, cows: newChilds, is_aborted: true, difficulty: 0 }
        });
    }
    private onWeightChange(idx: number, value: number|undefined) {
        const target = this.state.data.cows[idx];
        const newChild: IDeliveryCow = { ...target, weight_kg: value };
        const newChilds = [...this.state.data.cows];
        newChilds[idx] = newChild;
        
        this.setState({data:{...this.state.data, cows:newChilds}});
    }

    private async loadCrossHis(): Promise<{ success:boolean, data: BreedingCrossCurrentCrossDto|null }> {
        const cowId = this.state.cow_id;
        if (!CommonUtil.assertNotNull(cowId, "cow_id", "loadCrossHis")) {
            return Promise.resolve({ success:false, data:null });
        }

        const res = await this.comm().send((await BreedingApi()).getBreedingCrossCurrentUsingPOST({ 
            cow_id: cowId,
            ranch_id: this.props.rootStore.cur_ranch_id,
            last_day: moment(this.state.data.delivered_at).format("YYYY-MM-DD")
        }));

        if (res.result !== "OK") {
            return { success:false, data:null };
        }
        const list = res.data?.crosses ?? [];
        const data = list.length === 0 ? null : list[list.length - 1];
        return { success: true, data };
    }
    private async onSave() {
        const cowId = this.state.cow_id;
        const cow = this.state.cow;
        if (!CommonUtil.assertNotNull(cowId, "cow_id", "onSave")
            || !CommonUtil.assertNotNull(cow, "cow", "onSave")) {
            return Promise.resolve(null);
        }

        try {
            this.setState({ executing: true });

            const validated = await this.validateBreedingState();
            if (validated !== BREEDING_STATE_CONFIRMED.OK && validated !== BREEDING_STATE_CONFIRMED.NO ) return;

            const isNew = this.state.original_id == null;
            let schedule_id = this.props.location.state?.schedule_id;

            //関連する予定を探す
            if (schedule_id == null && isNew) {
                const day = moment(this.state.data.delivered_at).format("YYYY-MM-DD");

                const finder = new ScheduleFinder(this.context, this.props.rootStore.cur_ranch_id, this.props.rootStore.user.id);
                const scheRes = await finder.findEventRelatedSchedule(day, [ EVENT_KIND.DELIVERY.no ], cowId);

                if (scheRes.result === "cancel") return;

                if (scheRes.result === "yes") {
                    schedule_id = scheRes.id;
                }
            }


            //現状は画面に交配日表示する必要はないため、登録時に選択中の記録日時に対して
            //登録直前に交配記録を取得
            this.context.handleSetIsLoading(true);      //loadingがぶつ切れになるが、プログラム取得を挟む関係で妥協
            const crossRes = await this.loadCrossHis();
            this.context.handleSetIsLoading(false);
            if (!crossRes.success) {
                return;
            }

            const children = this.state.data.cows.map(c => ({
                child_no: c.child_no,
                cow_id: c.cow_id,
                is_male: c.is_male,
                life_status: c.life_status,
                weight_kg: c.weight_kg
            }));
            const req: DeliveryModifyReq = {
                children: children,
                comment: this.state.data.comment,
                cow_id: cowId,
                delivered_at: moment(this.state.data.delivered_at).format("YYYY-MM-DD HH:mm") + ":00",
                delivery_id: this.state.original_id ?? 0,
                difficulty: this.state.data.difficulty,
                is_aborted: this.state.data.is_aborted ? 1 : 0,
                is_new: isNew ? 1 : 0,
                ranch_id: this.props.rootStore.cur_ranch_id,
                cross_no: crossRes.data?.cross_no,
                schedule_id: schedule_id,
                preloaded_program: { list:[], tags:[] }
            }

            if (isNew) {
                //プログラムの確認
                const clinic_id = this.props.rootStore.getClinicIdForMaster();
    
                const pgReq = await showPreloadedProgramSelectDialog(
                    this.context,
                    async () => this.comm().send((await DeliveryApi()).getProgramsForDeliveryUsingPOST({ eventReq:req, clinic_id })),
                    this.props.rootStore,
                    req.ranch_id,
                    clinic_id,
                    st => this.setState({ preloadedProgram:st }),
                    [ cow ]
                );
                if (pgReq == null) return false;
                req.preloaded_program = pgReq;
            }

            this.context.handleSetIsLoading(true);
            const res = await this.comm().send((await DeliveryApi()).modifyUsingPOST1(req));
            this.context.handleSetIsLoading(false);

            if (res.result !== "OK") {
                return;
            }

            if (isNew) {
                if (validated === BREEDING_STATE_CONFIRMED.OK) {
                    // 繁殖状態更新
                    const resbs = await this.onSubmitBreedingState();
                    if (!resbs) {
                        await this.context.showDialog("WARNING", A.MESSAGE.BREEDING_STATE_COMMIT_FAILED);
                        this.props.history.replace(historyLocation.toCowInfo(cowId));
                        return;
                    }
                }
            } else {
                await this.context.showDialog("WARNING", A.MESSAGE.BREEDING_STATE_NO_COMMIT);
            }

            //未登録の子牛があればナビゲート
            if (req.is_aborted === 0
                && res.data != null
                && children.some(ch => ch.cow_id == null)
                && hasRanchAuth("COW_EDIT", req.ranch_id, this.props.rootStore.user)) {

                const id = res.data.id;

                const next = await this.context.showDialog("QUESTION", "続いて子牛情報を登録しますか？",
                                                [{ type:"decide", text:"子牛を登録" }, { type:"cancel", text: isNew ? "牛TOPへ" : "イベント詳細へ" }]);
                if (next === 0) {
                    //※便宜的に 1からchild_noを採番（サーバ側で振られているはずの値）
                    const newChilds = children.map((ch,i) => ({
                        ...ch,
                        child_no: i + 1,
                    })).filter(ch => ch.cow_id == null);

                    this.registerChildren(id, newChilds, moment(req.delivered_at), crossRes.data);

                } else {
                    if (isNew) {
                        //※cow_topパラメータを見ずに常に牛TOPに飛ばしておく
                        this.props.history.replace(historyLocation.toCowInfo(cowId));
                    } else {
                        this.props.history.replace(historyLocation.toCowEvent(cowId, moment(this.state.data.delivered_at).format("YYYY-MM-DD"), EVENT_TYPE_PARAM.BREEDING));
                    }
                }

            } else {
                if (isNew) {
                    this.props.history.replace(historyLocation.toCowInfo(cowId));
                } else {
                    this.props.history.replace(historyLocation.toCowEvent(cowId, moment(this.state.data.delivered_at).format("YYYY-MM-DD"), EVENT_TYPE_PARAM.BREEDING));
                }            
            }
        } finally {
            this.setState({ executing: false });
        }
    }

    private async registerChildren(id: number,
                                children: {is_male:0|1, life_status:number, child_no:number, weight_kg: number | undefined }[],
                                birthday: moment.Moment,
                                cross: BreedingCrossCurrentCrossDto | null) {
        const motherId = this.state.cow_id;
        if (!CommonUtil.assertNotNull(motherId, "cow_id", "registerChildren")) {
            return;
        }

        const getCow = async (cowId:number) => {
            const data = await this.apiGetCow(cowId, false);
            if (data != null) {
                return ({
                    cow_id: data.cow_id,
                    local_no: data.local_no,
                    name: data.name,
                    trace_id: data.trace_id,
                    ancestor_1: data.ancestor_1,
                    ancestor_2: data.ancestor_2,
                    ancestor_3: data.ancestor_3,
                    ancestor_1_exid: data.ancestor_1_exid,
                    ancestor_2_exid: data.ancestor_2_exid,
                    ancestor_3_exid: data.ancestor_3_exid,
                });
            }

            return null;
        }

        this.context.handleSetIsLoading(true);
        const ranchId = this.props.rootStore.cur_ranch_id;
        const param = await buildWriteChildrenParams(ranchId, getCow, motherId, id, cross, birthday, children, this.comm());
        this.context.handleSetIsLoading(false);

        this.props.history.replace(historyLocation.toCowWrite(param));
    }


    private async onDelete() {

        const confirmResult = await this.context.showDialog("QUESTION", "この記録を削除してよろしいですか？", DIALOG_BUTTONS.DELETE_CANCEL);
        if (confirmResult !== 0) return;

        if (!CommonUtil.assertNotNull(this.state.original_id, "orginal_id", "onDelete")) return;
        await this.apiDelete(this.state.original_id);
    }

    private async apiDelete(id: number) {

        if (!CommonUtil.assertNotNull(this.state.cow_id, "cow_id", "apiDelete")) {
            return;
        }

        const params = {
            delivery_id: id,
            ranch_id: this.props.rootStore.cur_ranch_id
        };
        
        this.context.handleSetIsLoading(true);
        const res = await this.comm().send(() => this.context.postAsync('/delivery/delete', params))
        this.context.handleSetIsLoading(false);

        if (res.result === "OK") {
            this.props.history.replace(
                historyLocation.toCowEvent(
                    this.state.cow_id, 
                    this.state.original ? moment(this.state.original.delivered_at).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"), 
                    EVENT_TYPE_PARAM.BREEDING
                )
            )
        }
    }

    private async validateBreedingState(): Promise<typeof BREEDING_STATE_CONFIRMED[BreedingStateConfirmedKey]> {
        if (!CommonUtil.assertNotNull(this.state.cow_id, "cow_id " + this.state.cow_id, "validateBreedingState")) {
            return BREEDING_STATE_CONFIRMED.CANCEL;
        }
        if (!CommonUtil.assertNotNull(this.state.data.is_aborted, "is_aborted " + this.state.data.is_aborted, "validateBreedingState")) {
            return BREEDING_STATE_CONFIRMED.CANCEL;
        }

        if (this.state.original_id != null) return BREEDING_STATE_CONFIRMED.NO;

        const cow = await this.apiGetCow(this.state.cow_id, true);
        if (!cow) {
            return BREEDING_STATE_CONFIRMED.CANCEL;
        }
        if (cow.breeding_state !== BREEDING_STATE.PREG.no) {
            let res: number|undefined;
            if (this.state.data.is_aborted) {
                res = await this.context.showDialog("QUESTION", A.MESSAGE.BREEDING_STATE_NOT_PREG_TO_OPEN, DIALOG_BUTTONS.YES_NO_CANCEL);
            } else {
                res = await this.context.showDialog("QUESTION", A.MESSAGE.BREEDING_STATE_NOT_PREG_TO_FRESH, DIALOG_BUTTONS.YES_NO_CANCEL);
            }
            return res === 0 ? BREEDING_STATE_CONFIRMED.OK
                : res === 1 ? BREEDING_STATE_CONFIRMED.NO
                : BREEDING_STATE_CONFIRMED.CANCEL;
        }
        return BREEDING_STATE_CONFIRMED.OK;
    }

    private async onSubmitBreedingState(): Promise<boolean> {
        if (!CommonUtil.assertNotNull(this.state.cow_id, "cow_id " + this.state.cow_id, "onSubmitBreedingState")) {
            return Promise.resolve(false);
        }

        const breeding_state = this.state.data.is_aborted ? BREEDING_STATE.OPEN.no : BREEDING_STATE.FRESH.no;
        const req: CowBreedingModifyReq = {
            cow_ids: [this.state.cow_id],
            state: breeding_state,
            activity: 0,
            open_day: hasOpenDay(breeding_state) ? moment(this.state.data.delivered_at).add(DEFAULT_OPEN_DAYS_AFTER_DELIVERY, "days").format("YYYY-MM-DD"): undefined,
            ranch_id: this.props.rootStore.cur_ranch_id
        };
        this.context.handleSetIsLoading(true);
        const res = await this.comm().send((await CowApi()).modifyBreedingUsingPOST(req), { showsErrorMessage: false });
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") return false;

        //アクティブ牛リスト更新しておく
        this.props.rootStore.fetchActiveCows(undefined, "DIFF");
        resetCow(this.state.cow_id, false);

        return true;
    }

    apiGetCow = async (cow_id:number, withLoading: boolean) => {

        if (withLoading) {
            this.context.handleSetIsLoading(true);
        }
        const res = await this.comm().send(() => getCowWithCache(this.props.rootStore.cur_ranch_id, cow_id));
        if (withLoading) {
            this.context.handleSetIsLoading(false);
        }
        if (res.result !== "OK") return undefined;
        return res.data;
    }

    render() {
        const msgDifficulty = A.DELIVERY_DIFFICULTY.map(d => d.no + ":" + d.name);
        const difficultyMarks = {};
        A.DELIVERY_DIFFICULTY.forEach(d => difficultyMarks[d.no] = d.no);
        const dnums = A.DELIVERY_DIFFICULTY.map(d => d.no);
        const minDifficulty = Math.min(...dnums);
        const maxDifficulty = Math.max(...dnums);


        const rowClass = "form-group row treat-write-row";
        const rowHeaderClass = "col-form-label col-md-4 col-xs-4 text-lg-right";

        return (
            <div className="page-root">
                <div className="product product-full-height">
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix">
                            <div className="product-info-header">
                                { this.state.cow != null && (
                                    <SingleCowHeader ranchId={this.props.rootStore.cur_ranch_id} cowId={this.state.cow.cow_id} />
                                )}
                            </div>
                            <div className="product-body" style={{paddingRight:"6px"}}>
                                <div className={rowClass}>
                                    <label className={rowHeaderClass}>分娩日時</label>
                                    <div style={{paddingLeft:0, flex:1}}>
                                        <V3DateTime value={this.state.data.delivered_at}
                                            timePresets={this.props.rootStore.user.setting?.time_preset ?? TIMEPRESETS}
                                            onChange={d => this.setState({data: { ...this.state.data, delivered_at: d.toDate() }})}
                                        />
                                    </div>
                                </div>
                                <div className={rowClass}>
                                    <label className={rowHeaderClass}></label>
                                    <div className="checkbox checkbox-css">
                                        <input type="checkbox" id="chkAborted" checked={this.state.data.is_aborted}
                                                onChange={e => this.onIsAbortedChanged(e.target.checked)}></input>
                                        <label htmlFor="chkAborted">流産</label>
                                    </div>
                                </div>
                                <div className={rowClass}>
                                    <span className={rowHeaderClass}>
                                        <label className="col-form-label">分娩難易度</label>
                                        <InfoPopup message={msgDifficulty} placement="top" />
                                    </span>
                                    <div style={{flex:1, marginLeft:"3px", marginRight: "14px", paddingTop:"16px"}}>
                                        <Slider min={minDifficulty} max={maxDifficulty}
                                                disabled={this.state.data.is_aborted}
                                                value={this.state.data.difficulty}
                                                onChange={(e) => this.setState({data: {...this.state.data, difficulty: e}})}
                                                marks={difficultyMarks} step={null} />
                                    </div>
                                </div>
                                <div className={rowClass} style={{margin:"10px 0 16px"}}>
                                    <span className={rowHeaderClass}></span>
                                    <div style={{flex:1}}>
                                        <div className="radio radio-css radio-inline">
                                            <input type="radio" id="childcount1" checked={this.state.data.cows.length === 1}
                                                onChange={() => this.onChildCountChange(1)}></input>
                                            <label htmlFor="childcount1">単子</label>
                                        </div>
                                        <div className="radio radio-css radio-inline">
                                            <input type="radio" id="childcount2" checked={this.state.data.cows.length === 2}
                                                onChange={() => this.onChildCountChange(2)}></input>
                                            <label htmlFor="childcount2">双子</label>
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.data.cows.map((child,i) => (
                                        <React.Fragment key={i}>
                                            <div className={rowClass} style={{marginBottom:"2px"}}>
                                                <label className={rowHeaderClass}>{i + 1}子目</label>
                                                <div>
                                                    <select className="form-control" value={child.life_status} onChange={e => this.onLifeChange(i, e.target.value)}
                                                            disabled={this.state.data.is_aborted}>
                                                        {
                                                            A.LIFE_STATES.map(l => <option key={l.no} value={l.no}>{l.name}</option>)
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className={rowClass} style={{marginBottom:"3px"}}>
                                                <label className={rowHeaderClass}></label>
                                                <div style={{flex:1}}>
                                                    <div className="radio radio-css radio-inline">
                                                        <input type="radio" id={"childmale" + i} checked={child.is_male === 1}
                                                            onChange={() => this.onIsMaleChange(i, true)}></input>
                                                        <label htmlFor={"childmale" + i}>オス</label>
                                                    </div>
                                                    <div className="radio radio-css radio-inline">
                                                        <input type="radio" id={"childfemale" + i} checked={child.is_male === 0}
                                                            onChange={() => this.onIsMaleChange(i, false)}></input>
                                                        <label htmlFor={"childfemale" + i}>メス</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={rowClass}>
                                                <label className={rowHeaderClass}></label>
                                                <div style={{display:"flex", alignItems:"center"}}>
                                                    <label className="col-form-label">生時体重</label>
                                                    <OptionalNumInput
                                                        min={LMT.DELIVERY.WEIGHT_MIN} max={LMT.DELIVERY.WEIGHT_MAX}
                                                        step={LMT.DELIVERY.WEIGHT_STEP}
                                                        style={{width:"70px", margin:"0 4px"}}
                                                        value={child.weight_kg}
                                                        onChange={val => this.onWeightChange(i, val)}
                                                    />
                                                    <div>kg</div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ))
                                }
                                <div className={rowClass}>
                                    <label className="col-form-label col-md-2 col-xs-2 text-lg-right">メモ</label>
                                    <div className="col-md-10 col-xs-10">
                                        <textarea className="form-control" rows={4} maxLength={LMT.DELIVERY.MEMO_LEN}
                                                value={this.state.data.comment} onChange={(e) => this.setState({data:{...this.state.data, comment:e.target.value}})}></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="button-page-footer">
                    <ExecutionButton type="save" onClick={this.onSave} disabled={this.state.executing} />
                    {this.state.original_id != null && (
                        <ExecutionButton type="delete" onClick={this.onDelete} disabled={this.state.executing} />
                    )}
                </div>
                { this.state.preloadedProgram && (
                    <PreloadedProgramSelectPopup
                        {...this.state.preloadedProgram}
                    />
                )}
            </div>
        )
    }
}

export default withRouter(withContext(DeliveryWrite));