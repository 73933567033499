// tslint:disable
/**
 * Vetell REST API
 *   
 *
 * The version of the OpenAPI document: Version 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActivityCowDto
 */
export interface ActivityCowDto {
    /**
     * 
     * @type {number}
     * @memberof ActivityCowDto
     */
    cow_id: number;
    /**
     * 
     * @type {string}
     * @memberof ActivityCowDto
     */
    local_no?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityCowDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityCowDto
     */
    trace_id?: string;
}
/**
 * 
 * @export
 * @interface ActivityDto
 */
export interface ActivityDto {
    /**
     * 
     * @type {number}
     * @memberof ActivityDto
     */
    activity_id: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityDto
     */
    activity_kind: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityDto
     */
    can_undo: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityDto
     */
    cow_count: number;
    /**
     * 
     * @type {Array<ActivityCowDto>}
     * @memberof ActivityDto
     */
    cows: Array<ActivityCowDto>;
    /**
     * 
     * @type {string}
     * @memberof ActivityDto
     */
    created_at: string;
    /**
     * 
     * @type {number}
     * @memberof ActivityDto
     */
    crud: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityDto
     */
    is_undone: number;
    /**
     * 
     * @type {string}
     * @memberof ActivityDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityDto
     */
    occured_at?: string;
    /**
     * 
     * @type {number}
     * @memberof ActivityDto
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface ActivityListReq
 */
export interface ActivityListReq {
    /**
     * 各アクティビティのレスポンスに含める頭数上限
     * @type {number}
     * @memberof ActivityListReq
     */
    cow_limit?: number;
    /**
     * このIDより小さいものを取得
     * @type {number}
     * @memberof ActivityListReq
     */
    excluded_max_id?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityListReq
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityListReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface ActivityUndoReq
 */
export interface ActivityUndoReq {
    /**
     * 
     * @type {number}
     * @memberof ActivityUndoReq
     */
    activity_id: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityUndoReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface AggregateTransitionReq
 */
export interface AggregateTransitionReq {
    /**
     * 
     * @type {Array<number>}
     * @memberof AggregateTransitionReq
     */
    excluded_tags?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof AggregateTransitionReq
     */
    from?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof AggregateTransitionReq
     */
    included_tags?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof AggregateTransitionReq
     */
    ranch_id: number;
    /**
     * 
     * @type {string}
     * @memberof AggregateTransitionReq
     */
    to?: string;
}
/**
 * 
 * @export
 * @interface BalanceCowAgeReq
 */
export interface BalanceCowAgeReq {
    /**
     * cow_ids
     * @type {Array<number>}
     * @memberof BalanceCowAgeReq
     */
    cow_ids: Array<number>;
    /**
     * from
     * @type {number}
     * @memberof BalanceCowAgeReq
     */
    from: number;
    /**
     * ranch_id
     * @type {number}
     * @memberof BalanceCowAgeReq
     */
    ranch_id: number;
    /**
     * to
     * @type {number}
     * @memberof BalanceCowAgeReq
     */
    to: number;
}
/**
 * 
 * @export
 * @interface BalanceCowChildDto
 */
export interface BalanceCowChildDto {
    /**
     * cow_id
     * @type {number}
     * @memberof BalanceCowChildDto
     */
    cow_id: number;
    /**
     * is_active
     * @type {number}
     * @memberof BalanceCowChildDto
     */
    is_active: number;
    /**
     * is_male
     * @type {number}
     * @memberof BalanceCowChildDto
     */
    is_male: number;
    /**
     * local_no
     * @type {string}
     * @memberof BalanceCowChildDto
     */
    local_no: string;
    /**
     * loss
     * @type {number}
     * @memberof BalanceCowChildDto
     */
    loss: number;
    /**
     * name
     * @type {string}
     * @memberof BalanceCowChildDto
     */
    name?: string;
    /**
     * profit
     * @type {number}
     * @memberof BalanceCowChildDto
     */
    profit: number;
    /**
     * trace_id
     * @type {string}
     * @memberof BalanceCowChildDto
     */
    trace_id: string;
    /**
     * use_no
     * @type {number}
     * @memberof BalanceCowChildDto
     */
    use_no?: number;
}
/**
 * 
 * @export
 * @interface BalanceCowChildrenDto
 */
export interface BalanceCowChildrenDto {
    /**
     * children
     * @type {Array<BalanceCowChildDto>}
     * @memberof BalanceCowChildrenDto
     */
    children: Array<BalanceCowChildDto>;
    /**
     * 
     * @type {BalanceCowProfitLossDto}
     * @memberof BalanceCowChildrenDto
     */
    mother: BalanceCowProfitLossDto;
}
/**
 * 
 * @export
 * @interface BalanceCowChildrenReq
 */
export interface BalanceCowChildrenReq {
    /**
     * cow_id
     * @type {number}
     * @memberof BalanceCowChildrenReq
     */
    cow_id: number;
    /**
     * ranch_id
     * @type {number}
     * @memberof BalanceCowChildrenReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface BalanceCowCondCowDto
 */
export interface BalanceCowCondCowDto {
    /**
     * age
     * @type {number}
     * @memberof BalanceCowCondCowDto
     */
    age?: number;
    /**
     * cow_id
     * @type {number}
     * @memberof BalanceCowCondCowDto
     */
    cow_id: number;
    /**
     * delivery_times
     * @type {number}
     * @memberof BalanceCowCondCowDto
     */
    delivery_times: number;
    /**
     * is_male
     * @type {number}
     * @memberof BalanceCowCondCowDto
     */
    is_male: number;
    /**
     * last_delivery_day
     * @type {string}
     * @memberof BalanceCowCondCowDto
     */
    last_delivery_day?: string;
    /**
     * local_no
     * @type {string}
     * @memberof BalanceCowCondCowDto
     */
    local_no: string;
    /**
     * name
     * @type {string}
     * @memberof BalanceCowCondCowDto
     */
    name?: string;
    /**
     * trace_id
     * @type {string}
     * @memberof BalanceCowCondCowDto
     */
    trace_id: string;
    /**
     * use_no
     * @type {number}
     * @memberof BalanceCowCondCowDto
     */
    use_no?: number;
}
/**
 * 
 * @export
 * @interface BalanceCowConditionDto
 */
export interface BalanceCowConditionDto {
    /**
     * cows
     * @type {Array<BalanceCowCondCowDto>}
     * @memberof BalanceCowConditionDto
     */
    cows: Array<BalanceCowCondCowDto>;
}
/**
 * 
 * @export
 * @interface BalanceCowConditionReq
 */
export interface BalanceCowConditionReq {
    /**
     * cow_ids
     * @type {Array<number>}
     * @memberof BalanceCowConditionReq
     */
    cow_ids: Array<number>;
    /**
     * date
     * @type {string}
     * @memberof BalanceCowConditionReq
     */
    date: string;
    /**
     * ranch_id
     * @type {number}
     * @memberof BalanceCowConditionReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface BalanceCowDeliDayDto
 */
export interface BalanceCowDeliDayDto {
    /**
     * delivery_day
     * @type {string}
     * @memberof BalanceCowDeliDayDto
     */
    delivery_day?: string;
    /**
     * values
     * @type {Array<BalanceCowDeliDaySpanDto>}
     * @memberof BalanceCowDeliDayDto
     */
    values: Array<BalanceCowDeliDaySpanDto>;
}
/**
 * 
 * @export
 * @interface BalanceCowDeliDaySpanDto
 */
export interface BalanceCowDeliDaySpanDto {
    /**
     * losses
     * @type {Array<BalanceItemDto>}
     * @memberof BalanceCowDeliDaySpanDto
     */
    losses: Array<BalanceItemDto>;
    /**
     * profits
     * @type {Array<BalanceItemDto>}
     * @memberof BalanceCowDeliDaySpanDto
     */
    profits: Array<BalanceItemDto>;
    /**
     * span_from
     * @type {number}
     * @memberof BalanceCowDeliDaySpanDto
     */
    span_from: number;
    /**
     * span_to
     * @type {number}
     * @memberof BalanceCowDeliDaySpanDto
     */
    span_to: number;
}
/**
 * 
 * @export
 * @interface BalanceCowDeliTimeDto
 */
export interface BalanceCowDeliTimeDto {
    /**
     * values
     * @type {Array<BalanceCowDeliTimeSpanDto>}
     * @memberof BalanceCowDeliTimeDto
     */
    values: Array<BalanceCowDeliTimeSpanDto>;
}
/**
 * 
 * @export
 * @interface BalanceCowDeliTimeSpanDto
 */
export interface BalanceCowDeliTimeSpanDto {
    /**
     * cow_ids
     * @type {Array<number>}
     * @memberof BalanceCowDeliTimeSpanDto
     */
    cow_ids: Array<number>;
    /**
     * losses
     * @type {Array<BalanceItemDto>}
     * @memberof BalanceCowDeliTimeSpanDto
     */
    losses: Array<BalanceItemDto>;
    /**
     * profits
     * @type {Array<BalanceItemDto>}
     * @memberof BalanceCowDeliTimeSpanDto
     */
    profits: Array<BalanceItemDto>;
    /**
     * time
     * @type {number}
     * @memberof BalanceCowDeliTimeSpanDto
     */
    time: number;
}
/**
 * 
 * @export
 * @interface BalanceCowDeliveryDayReq
 */
export interface BalanceCowDeliveryDayReq {
    /**
     * cow_ids
     * @type {Array<number>}
     * @memberof BalanceCowDeliveryDayReq
     */
    cow_ids: Array<number>;
    /**
     * day_from
     * @type {number}
     * @memberof BalanceCowDeliveryDayReq
     */
    day_from: number;
    /**
     * day_span
     * @type {number}
     * @memberof BalanceCowDeliveryDayReq
     */
    day_span: number;
    /**
     * day_to
     * @type {number}
     * @memberof BalanceCowDeliveryDayReq
     */
    day_to: number;
    /**
     * delivery_time
     * @type {number}
     * @memberof BalanceCowDeliveryDayReq
     */
    delivery_time: number;
    /**
     * ranch_id
     * @type {number}
     * @memberof BalanceCowDeliveryDayReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface BalanceCowDeliveryTimeReq
 */
export interface BalanceCowDeliveryTimeReq {
    /**
     * cow_ids
     * @type {Array<number>}
     * @memberof BalanceCowDeliveryTimeReq
     */
    cow_ids: Array<number>;
    /**
     * ranch_id
     * @type {number}
     * @memberof BalanceCowDeliveryTimeReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface BalanceCowDto
 */
export interface BalanceCowDto {
    /**
     * values
     * @type {Array<BalanceSpanDto>}
     * @memberof BalanceCowDto
     */
    values: Array<BalanceSpanDto>;
}
/**
 * 
 * @export
 * @interface BalanceCowProfitLossDto
 */
export interface BalanceCowProfitLossDto {
    /**
     * cow_id
     * @type {number}
     * @memberof BalanceCowProfitLossDto
     */
    cow_id: number;
    /**
     * loss
     * @type {number}
     * @memberof BalanceCowProfitLossDto
     */
    loss: number;
    /**
     * profit
     * @type {number}
     * @memberof BalanceCowProfitLossDto
     */
    profit: number;
}
/**
 * 
 * @export
 * @interface BalanceCowReq
 */
export interface BalanceCowReq {
    /**
     * cow_ids
     * @type {Array<number>}
     * @memberof BalanceCowReq
     */
    cow_ids: Array<number>;
    /**
     * from
     * @type {string}
     * @memberof BalanceCowReq
     */
    from: string;
    /**
     * ranch_id
     * @type {number}
     * @memberof BalanceCowReq
     */
    ranch_id: number;
    /**
     * span
     * @type {string}
     * @memberof BalanceCowReq
     */
    span: string;
    /**
     * to
     * @type {string}
     * @memberof BalanceCowReq
     */
    to: string;
}
/**
 * 
 * @export
 * @interface BalanceDto
 */
export interface BalanceDto {
    /**
     * cow_count
     * @type {number}
     * @memberof BalanceDto
     */
    cow_count: number;
    /**
     * values
     * @type {Array<BalanceSpanDto>}
     * @memberof BalanceDto
     */
    values: Array<BalanceSpanDto>;
}
/**
 * 
 * @export
 * @interface BalanceItemDto
 */
export interface BalanceItemDto {
    /**
     * category_id
     * @type {number}
     * @memberof BalanceItemDto
     */
    category_id: number;
    /**
     * name
     * @type {string}
     * @memberof BalanceItemDto
     */
    name: string;
    /**
     * price
     * @type {number}
     * @memberof BalanceItemDto
     */
    price: number;
}
/**
 * 
 * @export
 * @interface BalanceRanchReq
 */
export interface BalanceRanchReq {
    /**
     * from
     * @type {string}
     * @memberof BalanceRanchReq
     */
    from: string;
    /**
     * is_active
     * @type {number}
     * @memberof BalanceRanchReq
     */
    is_active?: number;
    /**
     * ranch_id
     * @type {number}
     * @memberof BalanceRanchReq
     */
    ranch_id: number;
    /**
     * span
     * @type {string}
     * @memberof BalanceRanchReq
     */
    span: string;
    /**
     * to
     * @type {string}
     * @memberof BalanceRanchReq
     */
    to: string;
}
/**
 * 
 * @export
 * @interface BalanceSpanDto
 */
export interface BalanceSpanDto {
    /**
     * label
     * @type {string}
     * @memberof BalanceSpanDto
     */
    label: string;
    /**
     * losses
     * @type {Array<BalanceItemDto>}
     * @memberof BalanceSpanDto
     */
    losses: Array<BalanceItemDto>;
    /**
     * profits
     * @type {Array<BalanceItemDto>}
     * @memberof BalanceSpanDto
     */
    profits: Array<BalanceItemDto>;
    /**
     * span_from
     * @type {string}
     * @memberof BalanceSpanDto
     */
    span_from: string;
    /**
     * span_to
     * @type {string}
     * @memberof BalanceSpanDto
     */
    span_to: string;
}
/**
 * 
 * @export
 * @interface BreedingCertAiReq
 */
export interface BreedingCertAiReq {
    /**
     * 診療所住所
     * @type {string}
     * @memberof BreedingCertAiReq
     */
    clinic_address: string;
    /**
     * 診療所名
     * @type {string}
     * @memberof BreedingCertAiReq
     */
    clinic_name: string;
    /**
     * 特徴
     * @type {string}
     * @memberof BreedingCertAiReq
     */
    cow_feature: string;
    /**
     * 牛ID
     * @type {number}
     * @memberof BreedingCertAiReq
     */
    cow_id: number;
    /**
     * 交配日
     * @type {string}
     * @memberof BreedingCertAiReq
     */
    crossed_on: string;
    /**
     * 獣医師名
     * @type {string}
     * @memberof BreedingCertAiReq
     */
    doctor_name: string;
    /**
     * 種雄牛
     * @type {string}
     * @memberof BreedingCertAiReq
     */
    father_name: string;
    /**
     * ラベルNo.
     * @type {string}
     * @memberof BreedingCertAiReq
     */
    label_no: string;
    /**
     * 免許番号
     * @type {string}
     * @memberof BreedingCertAiReq
     */
    license_id: string;
    /**
     * 免許種別
     * @type {string}
     * @memberof BreedingCertAiReq
     */
    license_type: BreedingCertAiReqLicenseTypeEnum;
    /**
     * 発行日
     * @type {string}
     * @memberof BreedingCertAiReq
     */
    published_on: string;
    /**
     * 牧場ID
     * @type {number}
     * @memberof BreedingCertAiReq
     */
    ranch_id: number;
}

/**
    * @export
    * @enum {string}
    */
export enum BreedingCertAiReqLicenseTypeEnum {
    DR = 'DR',
    AI = 'AI'
}

/**
 * 
 * @export
 * @interface BreedingCertEtReq
 */
export interface BreedingCertEtReq {
    /**
     * 診療所住所
     * @type {string}
     * @memberof BreedingCertEtReq
     */
    clinic_address: string;
    /**
     * 診療所名
     * @type {string}
     * @memberof BreedingCertEtReq
     */
    clinic_name: string;
    /**
     * 特徴
     * @type {string}
     * @memberof BreedingCertEtReq
     */
    cow_feature: string;
    /**
     * 牛ID
     * @type {number}
     * @memberof BreedingCertEtReq
     */
    cow_id: number;
    /**
     * 交配日
     * @type {string}
     * @memberof BreedingCertEtReq
     */
    crossed_on: string;
    /**
     * 獣医師名
     * @type {string}
     * @memberof BreedingCertEtReq
     */
    doctor_name: string;
    /**
     * 受精卵種別
     * @type {string}
     * @memberof BreedingCertEtReq
     */
    egg_type: BreedingCertEtReqEggTypeEnum;
    /**
     * 種雄牛
     * @type {string}
     * @memberof BreedingCertEtReq
     */
    father_name: string;
    /**
     * ラベルNo.
     * @type {string}
     * @memberof BreedingCertEtReq
     */
    label_no: string;
    /**
     * 免許番号
     * @type {string}
     * @memberof BreedingCertEtReq
     */
    license_id: string;
    /**
     * 免許種別
     * @type {string}
     * @memberof BreedingCertEtReq
     */
    license_type: BreedingCertEtReqLicenseTypeEnum;
    /**
     * 発行日
     * @type {string}
     * @memberof BreedingCertEtReq
     */
    published_on: string;
    /**
     * 牧場ID
     * @type {number}
     * @memberof BreedingCertEtReq
     */
    ranch_id: number;
}

/**
    * @export
    * @enum {string}
    */
export enum BreedingCertEtReqEggTypeEnum {
    SOV = 'SOV',
    OPUIVF = 'OPUIVF'
}
/**
    * @export
    * @enum {string}
    */
export enum BreedingCertEtReqLicenseTypeEnum {
    DR = 'DR',
    AI = 'AI'
}

/**
 * 
 * @export
 * @interface BreedingCertPregnantReq
 */
export interface BreedingCertPregnantReq {
    /**
     * 検査所見
     * @type {string}
     * @memberof BreedingCertPregnantReq
     */
    checked_desc: string;
    /**
     * 検査年月日
     * @type {string}
     * @memberof BreedingCertPregnantReq
     */
    checked_on: string;
    /**
     * 診療所住所
     * @type {string}
     * @memberof BreedingCertPregnantReq
     */
    clinic_address: string;
    /**
     * 診療所名
     * @type {string}
     * @memberof BreedingCertPregnantReq
     */
    clinic_name: string;
    /**
     * 特徴
     * @type {string}
     * @memberof BreedingCertPregnantReq
     */
    cow_feature: string;
    /**
     * 牛ID
     * @type {number}
     * @memberof BreedingCertPregnantReq
     */
    cow_id: number;
    /**
     * 交配タイプ
     * @type {string}
     * @memberof BreedingCertPregnantReq
     */
    cross_type: BreedingCertPregnantReqCrossTypeEnum;
    /**
     * 交配日
     * @type {string}
     * @memberof BreedingCertPregnantReq
     */
    crossed_on: string;
    /**
     * 獣医師名
     * @type {string}
     * @memberof BreedingCertPregnantReq
     */
    doctor_name: string;
    /**
     * 発行日
     * @type {string}
     * @memberof BreedingCertPregnantReq
     */
    published_on: string;
    /**
     * 牧場ID
     * @type {number}
     * @memberof BreedingCertPregnantReq
     */
    ranch_id: number;
}

/**
    * @export
    * @enum {string}
    */
export enum BreedingCertPregnantReqCrossTypeEnum {
    AI = 'AI',
    ET = 'ET',
    NATURAL = 'NATURAL'
}

/**
 * 
 * @export
 * @interface BreedingCrossCurrentCrossDto
 */
export interface BreedingCrossCurrentCrossDto {
    /**
     * 
     * @type {string}
     * @memberof BreedingCrossCurrentCrossDto
     */
    ancestor_2?: string;
    /**
     * 
     * @type {number}
     * @memberof BreedingCrossCurrentCrossDto
     */
    ancestor_2_exid?: number;
    /**
     * 
     * @type {string}
     * @memberof BreedingCrossCurrentCrossDto
     */
    ancestor_3?: string;
    /**
     * 
     * @type {number}
     * @memberof BreedingCrossCurrentCrossDto
     */
    ancestor_3_exid?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingCrossCurrentCrossDto
     */
    cross_no: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingCrossCurrentCrossDto
     */
    cross_type: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingCrossCurrentCrossDto
     */
    father_exid?: number;
    /**
     * 
     * @type {string}
     * @memberof BreedingCrossCurrentCrossDto
     */
    father_name?: string;
    /**
     * 
     * @type {number}
     * @memberof BreedingCrossCurrentCrossDto
     */
    mother_id?: number;
    /**
     * 
     * @type {string}
     * @memberof BreedingCrossCurrentCrossDto
     */
    mother_name?: string;
    /**
     * 
     * @type {string}
     * @memberof BreedingCrossCurrentCrossDto
     */
    watched_at: string;
}
/**
 * 
 * @export
 * @interface BreedingCrossCurrentDto
 */
export interface BreedingCrossCurrentDto {
    /**
     * crosses
     * @type {Array<BreedingCrossCurrentCrossDto>}
     * @memberof BreedingCrossCurrentDto
     */
    crosses?: Array<BreedingCrossCurrentCrossDto>;
    /**
     * delivered_at
     * @type {string}
     * @memberof BreedingCrossCurrentDto
     */
    delivered_at?: string;
}
/**
 * 
 * @export
 * @interface BreedingCrossCurrentReq
 */
export interface BreedingCrossCurrentReq {
    /**
     * cow_id
     * @type {number}
     * @memberof BreedingCrossCurrentReq
     */
    cow_id: number;
    /**
     * last_day
     * @type {string}
     * @memberof BreedingCrossCurrentReq
     */
    last_day: string;
    /**
     * ranch_id
     * @type {number}
     * @memberof BreedingCrossCurrentReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface BreedingCrossDto
 */
export interface BreedingCrossDto {
    /**
     * 
     * @type {string}
     * @memberof BreedingCrossDto
     */
    ancestor_2?: string;
    /**
     * 
     * @type {number}
     * @memberof BreedingCrossDto
     */
    ancestor_2_exid?: number;
    /**
     * 
     * @type {string}
     * @memberof BreedingCrossDto
     */
    ancestor_3?: string;
    /**
     * 
     * @type {number}
     * @memberof BreedingCrossDto
     */
    ancestor_3_exid?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingCrossDto
     */
    cow_id: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingCrossDto
     */
    cross_no: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingCrossDto
     */
    cross_type: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingCrossDto
     */
    father_exid?: number;
    /**
     * 
     * @type {string}
     * @memberof BreedingCrossDto
     */
    father_name?: string;
    /**
     * 
     * @type {string}
     * @memberof BreedingCrossDto
     */
    label_no_1?: string;
    /**
     * 
     * @type {string}
     * @memberof BreedingCrossDto
     */
    label_no_2?: string;
    /**
     * 
     * @type {string}
     * @memberof BreedingCrossDto
     */
    label_no_3?: string;
    /**
     * 
     * @type {number}
     * @memberof BreedingCrossDto
     */
    mother_id?: number;
    /**
     * 
     * @type {string}
     * @memberof BreedingCrossDto
     */
    mother_name?: string;
    /**
     * 
     * @type {string}
     * @memberof BreedingCrossDto
     */
    rut_at?: string;
    /**
     * 
     * @type {number}
     * @memberof BreedingCrossDto
     */
    seed_lot_id?: number;
}
/**
 * 
 * @export
 * @interface BreedingCrossHistoryReq
 */
export interface BreedingCrossHistoryReq {
    /**
     * cow_id
     * @type {number}
     * @memberof BreedingCrossHistoryReq
     */
    cow_id: number;
    /**
     * ranch_id
     * @type {number}
     * @memberof BreedingCrossHistoryReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface BreedingDeleteReq
 */
export interface BreedingDeleteReq {
    /**
     * breeding_id
     * @type {number}
     * @memberof BreedingDeleteReq
     */
    breeding_id: number;
    /**
     * ranch_id
     * @type {number}
     * @memberof BreedingDeleteReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface BreedingDetailDto
 */
export interface BreedingDetailDto {
    /**
     * 
     * @type {number}
     * @memberof BreedingDetailDto
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingDetailDto
     */
    benefit_type: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingDetailDto
     */
    detail_no: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingDetailDto
     */
    medicine_id?: number;
    /**
     * 
     * @type {string}
     * @memberof BreedingDetailDto
     */
    note?: string;
    /**
     * 
     * @type {number}
     * @memberof BreedingDetailDto
     */
    route_id?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingDetailDto
     */
    team_id: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingDetailDto
     */
    treat_item_no?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingDetailDto
     */
    treat_kind_no?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingDetailDto
     */
    unit_price?: number;
}
/**
 * 
 * @export
 * @interface BreedingDto
 */
export interface BreedingDto {
    /**
     * 
     * @type {number}
     * @memberof BreedingDto
     */
    bcs?: number;
    /**
     * 
     * @type {string}
     * @memberof BreedingDto
     */
    bcs_note?: string;
    /**
     * 
     * @type {number}
     * @memberof BreedingDto
     */
    breeding_id: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingDto
     */
    canal_cm?: number;
    /**
     * 
     * @type {string}
     * @memberof BreedingDto
     */
    canal_note?: string;
    /**
     * 
     * @type {number}
     * @memberof BreedingDto
     */
    canal_tear_score?: number;
    /**
     * 
     * @type {string}
     * @memberof BreedingDto
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof BreedingDto
     */
    cornu_l_bright_score?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingDto
     */
    cornu_l_cm?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingDto
     */
    cornu_l_image_score?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingDto
     */
    cornu_l_shrink_score?: number;
    /**
     * 
     * @type {string}
     * @memberof BreedingDto
     */
    cornu_note?: string;
    /**
     * 
     * @type {number}
     * @memberof BreedingDto
     */
    cornu_r_bright_score?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingDto
     */
    cornu_r_cm?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingDto
     */
    cornu_r_image_score?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingDto
     */
    cornu_r_shrink_score?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingDto
     */
    cow_id: number;
    /**
     * 
     * @type {BreedingCrossDto}
     * @memberof BreedingDto
     */
    cross?: BreedingCrossDto;
    /**
     * 
     * @type {string}
     * @memberof BreedingDto
     */
    delivery?: string;
    /**
     * 
     * @type {Array<BreedingDetailDto>}
     * @memberof BreedingDto
     */
    details?: Array<BreedingDetailDto>;
    /**
     * 
     * @type {Array<SympDiseaseDto>}
     * @memberof BreedingDto
     */
    diseases?: Array<SympDiseaseDto>;
    /**
     * 
     * @type {string}
     * @memberof BreedingDto
     */
    dry_up?: string;
    /**
     * 
     * @type {number}
     * @memberof BreedingDto
     */
    ovary_l_mm?: number;
    /**
     * 
     * @type {string}
     * @memberof BreedingDto
     */
    ovary_note?: string;
    /**
     * 
     * @type {number}
     * @memberof BreedingDto
     */
    ovary_r_mm?: number;
    /**
     * 
     * @type {string}
     * @memberof BreedingDto
     */
    pregnant_note?: string;
    /**
     * 
     * @type {number}
     * @memberof BreedingDto
     */
    pregnant_score?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingDto
     */
    ranch_id: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingDto
     */
    status?: number;
    /**
     * 
     * @type {Array<BreedingStructureDto>}
     * @memberof BreedingDto
     */
    structures?: Array<BreedingStructureDto>;
    /**
     * 
     * @type {number}
     * @memberof BreedingDto
     */
    vagina_mucus_score?: number;
    /**
     * 
     * @type {string}
     * @memberof BreedingDto
     */
    vagina_note?: string;
    /**
     * 
     * @type {number}
     * @memberof BreedingDto
     */
    vagina_rut_score?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingDto
     */
    vagina_tear_score?: number;
    /**
     * 
     * @type {VisitDistanceDto}
     * @memberof BreedingDto
     */
    visit?: VisitDistanceDto;
    /**
     * 
     * @type {number}
     * @memberof BreedingDto
     */
    vulva_blood_score?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingDto
     */
    vulva_expand_score?: number;
    /**
     * 
     * @type {string}
     * @memberof BreedingDto
     */
    vulva_note?: string;
    /**
     * 
     * @type {string}
     * @memberof BreedingDto
     */
    watched_at: string;
    /**
     * 
     * @type {string}
     * @memberof BreedingDto
     */
    watched_by: string;
}
/**
 * 
 * @export
 * @interface BreedingHistoryListDto
 */
export interface BreedingHistoryListDto {
    /**
     * list
     * @type {Array<ComOnpVetellMybatisBreedingEventBaseDao>}
     * @memberof BreedingHistoryListDto
     */
    list?: Array<ComOnpVetellMybatisBreedingEventBaseDao>;
}
/**
 * 
 * @export
 * @interface BreedingHistoryReq
 */
export interface BreedingHistoryReq {
    /**
     * cow_id
     * @type {number}
     * @memberof BreedingHistoryReq
     */
    cow_id: number;
    /**
     * from
     * @type {string}
     * @memberof BreedingHistoryReq
     */
    from?: string;
    /**
     * to
     * @type {string}
     * @memberof BreedingHistoryReq
     */
    to?: string;
}
/**
 * 
 * @export
 * @interface BreedingLastAiFatherDto
 */
export interface BreedingLastAiFatherDto {
    /**
     * 
     * @type {number}
     * @memberof BreedingLastAiFatherDto
     */
    cow_id: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingLastAiFatherDto
     */
    father_exid: number;
    /**
     * 
     * @type {string}
     * @memberof BreedingLastAiFatherDto
     */
    father_name: string;
}
/**
 * 
 * @export
 * @interface BreedingLastAiFatherReq
 */
export interface BreedingLastAiFatherReq {
    /**
     * 
     * @type {Array<number>}
     * @memberof BreedingLastAiFatherReq
     */
    cow_ids: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof BreedingLastAiFatherReq
     */
    from: string;
    /**
     * 
     * @type {number}
     * @memberof BreedingLastAiFatherReq
     */
    ranch_id: number;
    /**
     * 
     * @type {string}
     * @memberof BreedingLastAiFatherReq
     */
    to?: string;
}
/**
 * 
 * @export
 * @interface BreedingModifyReq
 */
export interface BreedingModifyReq {
    /**
     * 
     * @type {number}
     * @memberof BreedingModifyReq
     */
    bcs?: number;
    /**
     * 
     * @type {string}
     * @memberof BreedingModifyReq
     */
    bcs_note?: string;
    /**
     * 
     * @type {number}
     * @memberof BreedingModifyReq
     */
    breeding_id?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingModifyReq
     */
    canal_cm?: number;
    /**
     * 
     * @type {string}
     * @memberof BreedingModifyReq
     */
    canal_note?: string;
    /**
     * 
     * @type {number}
     * @memberof BreedingModifyReq
     */
    canal_tear_score?: number;
    /**
     * comment
     * @type {string}
     * @memberof BreedingModifyReq
     */
    comment: string;
    /**
     * 
     * @type {number}
     * @memberof BreedingModifyReq
     */
    cornu_l_bright_score?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingModifyReq
     */
    cornu_l_cm?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingModifyReq
     */
    cornu_l_image_score?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingModifyReq
     */
    cornu_l_shrink_score?: number;
    /**
     * 
     * @type {string}
     * @memberof BreedingModifyReq
     */
    cornu_note?: string;
    /**
     * 
     * @type {number}
     * @memberof BreedingModifyReq
     */
    cornu_r_bright_score?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingModifyReq
     */
    cornu_r_cm?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingModifyReq
     */
    cornu_r_image_score?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingModifyReq
     */
    cornu_r_shrink_score?: number;
    /**
     * cow_ids
     * @type {Array<number>}
     * @memberof BreedingModifyReq
     */
    cow_ids: Array<number>;
    /**
     * crosses
     * @type {Array<BreedingModifyReqCross>}
     * @memberof BreedingModifyReq
     */
    crosses: Array<BreedingModifyReqCross>;
    /**
     * 
     * @type {string}
     * @memberof BreedingModifyReq
     */
    delivery?: string;
    /**
     * details
     * @type {Array<ModifyReqTreat>}
     * @memberof BreedingModifyReq
     */
    details: Array<ModifyReqTreat>;
    /**
     * 
     * @type {Array<SymptomModifyReqDisease>}
     * @memberof BreedingModifyReq
     */
    diseases?: Array<SymptomModifyReqDisease>;
    /**
     * 
     * @type {BreedingModifyReqDryUp}
     * @memberof BreedingModifyReq
     */
    dry_up?: BreedingModifyReqDryUp;
    /**
     * is_new
     * @type {number}
     * @memberof BreedingModifyReq
     */
    is_new: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingModifyReq
     */
    ovary_l_mm?: number;
    /**
     * 
     * @type {string}
     * @memberof BreedingModifyReq
     */
    ovary_note?: string;
    /**
     * 
     * @type {number}
     * @memberof BreedingModifyReq
     */
    ovary_r_mm?: number;
    /**
     * plans
     * @type {Array<EventNextScheduleReq>}
     * @memberof BreedingModifyReq
     */
    plans: Array<EventNextScheduleReq>;
    /**
     * 
     * @type {string}
     * @memberof BreedingModifyReq
     */
    pregnant_note?: string;
    /**
     * 
     * @type {number}
     * @memberof BreedingModifyReq
     */
    pregnant_score?: number;
    /**
     * 
     * @type {PreloadedProgramReq}
     * @memberof BreedingModifyReq
     */
    preloaded_program: PreloadedProgramReq;
    /**
     * ranch_id
     * @type {number}
     * @memberof BreedingModifyReq
     */
    ranch_id: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingModifyReq
     */
    schedule_id?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingModifyReq
     */
    status?: number;
    /**
     * structures
     * @type {Array<BreedingModifyReqStructure>}
     * @memberof BreedingModifyReq
     */
    structures: Array<BreedingModifyReqStructure>;
    /**
     * 
     * @type {number}
     * @memberof BreedingModifyReq
     */
    vagina_mucus_score?: number;
    /**
     * 
     * @type {string}
     * @memberof BreedingModifyReq
     */
    vagina_note?: string;
    /**
     * 
     * @type {number}
     * @memberof BreedingModifyReq
     */
    vagina_rut_score?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingModifyReq
     */
    vagina_tear_score?: number;
    /**
     * 
     * @type {ModifyReqVisit}
     * @memberof BreedingModifyReq
     */
    visit?: ModifyReqVisit;
    /**
     * 
     * @type {number}
     * @memberof BreedingModifyReq
     */
    vulva_blood_score?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingModifyReq
     */
    vulva_expand_score?: number;
    /**
     * 
     * @type {string}
     * @memberof BreedingModifyReq
     */
    vulva_note?: string;
    /**
     * watched_at
     * @type {string}
     * @memberof BreedingModifyReq
     */
    watched_at: string;
}
/**
 * 
 * @export
 * @interface BreedingModifyReqCross
 */
export interface BreedingModifyReqCross {
    /**
     * ※廃止
     * @type {string}
     * @memberof BreedingModifyReqCross
     */
    ancestor_2?: string;
    /**
     * 
     * @type {number}
     * @memberof BreedingModifyReqCross
     */
    ancestor_2_exid?: number;
    /**
     * ※廃止
     * @type {string}
     * @memberof BreedingModifyReqCross
     */
    ancestor_3?: string;
    /**
     * 
     * @type {number}
     * @memberof BreedingModifyReqCross
     */
    ancestor_3_exid?: number;
    /**
     * cow_id
     * @type {number}
     * @memberof BreedingModifyReqCross
     */
    cow_id: number;
    /**
     * V4.3 Deprecated
     * @type {number}
     * @memberof BreedingModifyReqCross
     */
    cross_no?: number;
    /**
     * cross_type
     * @type {number}
     * @memberof BreedingModifyReqCross
     */
    cross_type: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingModifyReqCross
     */
    father_exid?: number;
    /**
     * ※廃止
     * @type {string}
     * @memberof BreedingModifyReqCross
     */
    father_name?: string;
    /**
     * 
     * @type {string}
     * @memberof BreedingModifyReqCross
     */
    label_no_1?: string;
    /**
     * 
     * @type {string}
     * @memberof BreedingModifyReqCross
     */
    label_no_2?: string;
    /**
     * 
     * @type {string}
     * @memberof BreedingModifyReqCross
     */
    label_no_3?: string;
    /**
     * 
     * @type {number}
     * @memberof BreedingModifyReqCross
     */
    mother_id?: number;
    /**
     * 
     * @type {string}
     * @memberof BreedingModifyReqCross
     */
    mother_name?: string;
    /**
     * 
     * @type {string}
     * @memberof BreedingModifyReqCross
     */
    rut_at?: string;
    /**
     * seed_lot_id
     * @type {number}
     * @memberof BreedingModifyReqCross
     */
    seed_lot_id?: number;
}
/**
 * 
 * @export
 * @interface BreedingModifyReqDryUp
 */
export interface BreedingModifyReqDryUp {
    /**
     * 
     * @type {Array<number>}
     * @memberof BreedingModifyReqDryUp
     */
    cow_ids: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof BreedingModifyReqDryUp
     */
    day: string;
}
/**
 * 
 * @export
 * @interface BreedingModifyReqStructure
 */
export interface BreedingModifyReqStructure {
    /**
     * item_count
     * @type {number}
     * @memberof BreedingModifyReqStructure
     */
    item_count: number;
    /**
     * item_kind
     * @type {number}
     * @memberof BreedingModifyReqStructure
     */
    item_kind: number;
    /**
     * side
     * @type {number}
     * @memberof BreedingModifyReqStructure
     */
    side: number;
}
/**
 * 
 * @export
 * @interface BreedingStructureDto
 */
export interface BreedingStructureDto {
    /**
     * 
     * @type {number}
     * @memberof BreedingStructureDto
     */
    item_count: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingStructureDto
     */
    item_kind: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingStructureDto
     */
    side: number;
}
/**
 * 
 * @export
 * @interface CertDiseaseReq
 */
export interface CertDiseaseReq {
    /**
     * 受信診療所住所
     * @type {string}
     * @memberof CertDiseaseReq
     */
    clinic_address?: string;
    /**
     * 診療所ID
     * @type {number}
     * @memberof CertDiseaseReq
     */
    clinic_id: number;
    /**
     * 受信診療所名
     * @type {string}
     * @memberof CertDiseaseReq
     */
    clinic_name?: string;
    /**
     * 受信診療所TEL
     * @type {string}
     * @memberof CertDiseaseReq
     */
    clinic_tel?: string;
    /**
     * 
     * @type {Array<CertDiseaseReqCow>}
     * @memberof CertDiseaseReq
     */
    cows: Array<CertDiseaseReqCow>;
    /**
     * 事故発生年月日
     * @type {string}
     * @memberof CertDiseaseReq
     */
    occured_on?: string;
    /**
     * 事故発生通知年月日
     * @type {string}
     * @memberof CertDiseaseReq
     */
    published_on?: string;
    /**
     * 牧場ID
     * @type {number}
     * @memberof CertDiseaseReq
     */
    ranch_id: number;
    /**
     * 宛名
     * @type {string}
     * @memberof CertDiseaseReq
     */
    receiver?: string;
}
/**
 * 
 * @export
 * @interface CertDiseaseReqCow
 */
export interface CertDiseaseReqCow {
    /**
     * 牛ID
     * @type {number}
     * @memberof CertDiseaseReqCow
     */
    cow_id: number;
    /**
     * 病名または症状
     * @type {Array<string>}
     * @memberof CertDiseaseReqCow
     */
    diseases: Array<string>;
}
/**
 * 
 * @export
 * @interface CertKarteReq
 */
export interface CertKarteReq {
    /**
     * 
     * @type {string}
     * @memberof CertKarteReq
     */
    clinic_address?: string;
    /**
     * 
     * @type {number}
     * @memberof CertKarteReq
     */
    clinic_id: number;
    /**
     * 
     * @type {string}
     * @memberof CertKarteReq
     */
    clinic_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CertKarteReq
     */
    clinic_tel?: string;
    /**
     * 
     * @type {Array<CertKarteReqCow>}
     * @memberof CertKarteReq
     */
    cows: Array<CertKarteReqCow>;
    /**
     * 
     * @type {boolean}
     * @memberof CertKarteReq
     */
    has_cover?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CertKarteReq
     */
    has_detail?: boolean;
    /**
     * 診療で転帰が「治癒」の場合、転帰日を終診日の何日後とするか（default: 0）
     * @type {number}
     * @memberof CertKarteReq
     */
    healing_days?: number;
    /**
     * 
     * @type {string}
     * @memberof CertKarteReq
     */
    month?: string;
    /**
     * 
     * @type {string}
     * @memberof CertKarteReq
     */
    published_on?: string;
    /**
     * 
     * @type {number}
     * @memberof CertKarteReq
     */
    ranch_id: number;
    /**
     * 
     * @type {string}
     * @memberof CertKarteReq
     */
    receiver?: string;
}
/**
 * 
 * @export
 * @interface CertKarteReqCow
 */
export interface CertKarteReqCow {
    /**
     * 
     * @type {number}
     * @memberof CertKarteReqCow
     */
    cow_id: number;
    /**
     * 
     * @type {string}
     * @memberof CertKarteReqCow
     */
    event_kind: CertKarteReqCowEventKindEnum;
    /**
     * 
     * @type {Array<CertKarteReqEvent>}
     * @memberof CertKarteReqCow
     */
    events: Array<CertKarteReqEvent>;
    /**
     * 
     * @type {string}
     * @memberof CertKarteReqCow
     */
    found_at: string;
    /**
     * 
     * @type {number}
     * @memberof CertKarteReqCow
     */
    status: number;
}

/**
    * @export
    * @enum {string}
    */
export enum CertKarteReqCowEventKindEnum {
    SYMPTOM = 'SYMPTOM',
    BREEDING = 'BREEDING'
}

/**
 * 
 * @export
 * @interface CertKarteReqEvent
 */
export interface CertKarteReqEvent {
    /**
     * 
     * @type {number}
     * @memberof CertKarteReqEvent
     */
    event_id: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CertKarteReqEvent
     */
    treat_ids: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof CertKarteReqEvent
     */
    watched_by_name: string;
}
/**
 * 
 * @export
 * @interface ClinicRanchCreateReq
 */
export interface ClinicRanchCreateReq {
    /**
     * 
     * @type {number}
     * @memberof ClinicRanchCreateReq
     */
    clinic_id: number;
    /**
     * 
     * @type {string}
     * @memberof ClinicRanchCreateReq
     */
    name: string;
}
/**
 * 
 * @export
 * @interface ClinicScheduleReq
 */
export interface ClinicScheduleReq {
    /**
     * 
     * @type {number}
     * @memberof ClinicScheduleReq
     */
    clinic_id: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ClinicScheduleReq
     */
    cow_id?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ClinicScheduleReq
     */
    end_at: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ClinicScheduleReq
     */
    event_kind_no?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ClinicScheduleReq
     */
    start_at: string;
    /**
     * 
     * @type {number}
     * @memberof ClinicScheduleReq
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ClinicSeedDeleteReq
 */
export interface ClinicSeedDeleteReq {
    /**
     * 
     * @type {number}
     * @memberof ClinicSeedDeleteReq
     */
    clinic_id: number;
    /**
     * seed_id
     * @type {number}
     * @memberof ClinicSeedDeleteReq
     */
    seed_id: number;
    /**
     * seed_type
     * @type {number}
     * @memberof ClinicSeedDeleteReq
     */
    seed_type: number;
}
/**
 * 
 * @export
 * @interface ClinicSeedLotModifyReq
 */
export interface ClinicSeedLotModifyReq {
    /**
     * 
     * @type {number}
     * @memberof ClinicSeedLotModifyReq
     */
    clinic_id: number;
    /**
     * 
     * @type {string}
     * @memberof ClinicSeedLotModifyReq
     */
    comment: string;
    /**
     * egg_rank
     * @type {number}
     * @memberof ClinicSeedLotModifyReq
     */
    egg_rank?: number;
    /**
     * egg_stage
     * @type {number}
     * @memberof ClinicSeedLotModifyReq
     */
    egg_stage?: number;
    /**
     * egg_type
     * @type {number}
     * @memberof ClinicSeedLotModifyReq
     */
    egg_type?: number;
    /**
     * is_new
     * @type {number}
     * @memberof ClinicSeedLotModifyReq
     */
    is_new: number;
    /**
     * label_from
     * @type {string}
     * @memberof ClinicSeedLotModifyReq
     */
    label_from: string;
    /**
     * label_to
     * @type {string}
     * @memberof ClinicSeedLotModifyReq
     */
    label_to: string;
    /**
     * lot_name
     * @type {string}
     * @memberof ClinicSeedLotModifyReq
     */
    lot_name: string;
    /**
     * seed_id
     * @type {number}
     * @memberof ClinicSeedLotModifyReq
     */
    seed_id: number;
    /**
     * seed_lot_id
     * @type {number}
     * @memberof ClinicSeedLotModifyReq
     */
    seed_lot_id?: number;
    /**
     * seed_type
     * @type {number}
     * @memberof ClinicSeedLotModifyReq
     */
    seed_type: number;
    /**
     * stock_count
     * @type {number}
     * @memberof ClinicSeedLotModifyReq
     */
    stock_count: number;
    /**
     * stock_day
     * @type {string}
     * @memberof ClinicSeedLotModifyReq
     */
    stock_day: string;
    /**
     * unit_price
     * @type {number}
     * @memberof ClinicSeedLotModifyReq
     */
    unit_price: number;
}
/**
 * 
 * @export
 * @interface ClinicSeedModifyReq
 */
export interface ClinicSeedModifyReq {
    /**
     * ※廃止
     * @type {string}
     * @memberof ClinicSeedModifyReq
     */
    ancestor_1?: string;
    /**
     * ※廃止
     * @type {string}
     * @memberof ClinicSeedModifyReq
     */
    ancestor_2?: string;
    /**
     * 
     * @type {number}
     * @memberof ClinicSeedModifyReq
     */
    ancestor_2_exid?: number;
    /**
     * ※廃止
     * @type {string}
     * @memberof ClinicSeedModifyReq
     */
    ancestor_3?: string;
    /**
     * 
     * @type {number}
     * @memberof ClinicSeedModifyReq
     */
    ancestor_3_exid?: number;
    /**
     * ※廃止
     * @type {string}
     * @memberof ClinicSeedModifyReq
     */
    ancestor_4?: string;
    /**
     * 
     * @type {number}
     * @memberof ClinicSeedModifyReq
     */
    ancestor_4_exid?: number;
    /**
     * 
     * @type {number}
     * @memberof ClinicSeedModifyReq
     */
    clinic_id: number;
    /**
     * cow_id
     * @type {number}
     * @memberof ClinicSeedModifyReq
     */
    cow_id?: number;
    /**
     * 精液なら種雄牛、受精卵なら一代祖
     * @type {number}
     * @memberof ClinicSeedModifyReq
     */
    father_exid: number;
    /**
     * 入手元
     * @type {string}
     * @memberof ClinicSeedModifyReq
     */
    get_from?: string;
    /**
     * 
     * @type {number}
     * @memberof ClinicSeedModifyReq
     */
    is_new: number;
    /**
     * 受精卵のみ、母牛名
     * @type {string}
     * @memberof ClinicSeedModifyReq
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ClinicSeedModifyReq
     */
    seed_id: number;
    /**
     * 
     * @type {number}
     * @memberof ClinicSeedModifyReq
     */
    seed_type: number;
    /**
     * 精液略号
     * @type {string}
     * @memberof ClinicSeedModifyReq
     */
    sname?: string;
}
/**
 * 
 * @export
 * @interface ClinicSeedSortReq
 */
export interface ClinicSeedSortReq {
    /**
     * 
     * @type {number}
     * @memberof ClinicSeedSortReq
     */
    clinic_id: number;
    /**
     * order
     * @type {Array<number>}
     * @memberof ClinicSeedSortReq
     */
    order: Array<number>;
    /**
     * seed_type
     * @type {number}
     * @memberof ClinicSeedSortReq
     */
    seed_type: number;
}
/**
 * 
 * @export
 * @interface ClinicSeedStockModifyReq
 */
export interface ClinicSeedStockModifyReq {
    /**
     * 
     * @type {number}
     * @memberof ClinicSeedStockModifyReq
     */
    clinic_id: number;
    /**
     * label_no
     * @type {string}
     * @memberof ClinicSeedStockModifyReq
     */
    label_no: string;
    /**
     * note
     * @type {string}
     * @memberof ClinicSeedStockModifyReq
     */
    note?: string;
    /**
     * price
     * @type {number}
     * @memberof ClinicSeedStockModifyReq
     */
    price?: number;
    /**
     * seed_lot_id
     * @type {number}
     * @memberof ClinicSeedStockModifyReq
     */
    seed_lot_id: number;
    /**
     * status
     * @type {number}
     * @memberof ClinicSeedStockModifyReq
     */
    status?: number;
    /**
     * watched_at
     * @type {string}
     * @memberof ClinicSeedStockModifyReq
     */
    watched_at?: string;
}
/**
 * 
 * @export
 * @interface ClinicVisitFeeDeleteReq
 */
export interface ClinicVisitFeeDeleteReq {
    /**
     * 
     * @type {number}
     * @memberof ClinicVisitFeeDeleteReq
     */
    clinic_id: number;
    /**
     * 
     * @type {number}
     * @memberof ClinicVisitFeeDeleteReq
     */
    fee_no: number;
    /**
     * 
     * @type {number}
     * @memberof ClinicVisitFeeDeleteReq
     */
    fee_type: number;
}
/**
 * 
 * @export
 * @interface ClinicVisitFeeDto
 */
export interface ClinicVisitFeeDto {
    /**
     * 
     * @type {string}
     * @memberof ClinicVisitFeeDto
     */
    code?: string;
    /**
     * 
     * @type {number}
     * @memberof ClinicVisitFeeDto
     */
    fee: number;
    /**
     * 
     * @type {number}
     * @memberof ClinicVisitFeeDto
     */
    fee_no: number;
    /**
     * 
     * @type {number}
     * @memberof ClinicVisitFeeDto
     */
    fee_type: number;
    /**
     * 
     * @type {string}
     * @memberof ClinicVisitFeeDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ClinicVisitFeeDto
     */
    point_a?: number;
    /**
     * 
     * @type {number}
     * @memberof ClinicVisitFeeDto
     */
    point_b?: number;
    /**
     * 
     * @type {Array<ClinicVisitFeeStepDto>}
     * @memberof ClinicVisitFeeDto
     */
    steps: Array<ClinicVisitFeeStepDto>;
}
/**
 * 
 * @export
 * @interface ClinicVisitFeeModifyReq
 */
export interface ClinicVisitFeeModifyReq {
    /**
     * 
     * @type {number}
     * @memberof ClinicVisitFeeModifyReq
     */
    clinic_id: number;
    /**
     * 
     * @type {string}
     * @memberof ClinicVisitFeeModifyReq
     */
    code?: string;
    /**
     * 
     * @type {number}
     * @memberof ClinicVisitFeeModifyReq
     */
    fee: number;
    /**
     * 新規の場合、null
     * @type {number}
     * @memberof ClinicVisitFeeModifyReq
     */
    fee_no?: number;
    /**
     * 
     * @type {number}
     * @memberof ClinicVisitFeeModifyReq
     */
    fee_type: number;
    /**
     * 
     * @type {string}
     * @memberof ClinicVisitFeeModifyReq
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ClinicVisitFeeModifyReq
     */
    point_a?: number;
    /**
     * 
     * @type {number}
     * @memberof ClinicVisitFeeModifyReq
     */
    point_b?: number;
    /**
     * 
     * @type {Array<ClinicVisitFeeModifyReqStep>}
     * @memberof ClinicVisitFeeModifyReq
     */
    steps?: Array<ClinicVisitFeeModifyReqStep>;
}
/**
 * 
 * @export
 * @interface ClinicVisitFeeModifyReqStep
 */
export interface ClinicVisitFeeModifyReqStep {
    /**
     * 
     * @type {number}
     * @memberof ClinicVisitFeeModifyReqStep
     */
    distance_from: number;
    /**
     * 
     * @type {number}
     * @memberof ClinicVisitFeeModifyReqStep
     */
    distance_step?: number;
    /**
     * 
     * @type {number}
     * @memberof ClinicVisitFeeModifyReqStep
     */
    distance_to?: number;
    /**
     * 
     * @type {number}
     * @memberof ClinicVisitFeeModifyReqStep
     */
    fee: number;
    /**
     * 
     * @type {number}
     * @memberof ClinicVisitFeeModifyReqStep
     */
    point_a?: number;
    /**
     * 
     * @type {number}
     * @memberof ClinicVisitFeeModifyReqStep
     */
    point_b?: number;
}
/**
 * 
 * @export
 * @interface ClinicVisitFeeSortReq
 */
export interface ClinicVisitFeeSortReq {
    /**
     * 
     * @type {number}
     * @memberof ClinicVisitFeeSortReq
     */
    clinic_id: number;
    /**
     * 
     * @type {number}
     * @memberof ClinicVisitFeeSortReq
     */
    fee_type: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ClinicVisitFeeSortReq
     */
    order: Array<number>;
}
/**
 * 
 * @export
 * @interface ClinicVisitFeeStepDto
 */
export interface ClinicVisitFeeStepDto {
    /**
     * 
     * @type {number}
     * @memberof ClinicVisitFeeStepDto
     */
    distance_from: number;
    /**
     * 
     * @type {number}
     * @memberof ClinicVisitFeeStepDto
     */
    distance_step?: number;
    /**
     * 
     * @type {number}
     * @memberof ClinicVisitFeeStepDto
     */
    distance_to?: number;
    /**
     * 
     * @type {number}
     * @memberof ClinicVisitFeeStepDto
     */
    fee: number;
    /**
     * 
     * @type {number}
     * @memberof ClinicVisitFeeStepDto
     */
    point_a?: number;
    /**
     * 
     * @type {number}
     * @memberof ClinicVisitFeeStepDto
     */
    point_b?: number;
}
/**
 * 
 * @export
 * @interface ComClinicIdReq
 */
export interface ComClinicIdReq {
    /**
     * 
     * @type {number}
     * @memberof ComClinicIdReq
     */
    clinic_id: number;
}
/**
 * 
 * @export
 * @interface ComCowAndIdDto
 */
export interface ComCowAndIdDto {
    /**
     * 
     * @type {number}
     * @memberof ComCowAndIdDto
     */
    cow_id: number;
    /**
     * 
     * @type {number}
     * @memberof ComCowAndIdDto
     */
    id: number;
}
/**
 * 
 * @export
 * @interface ComCowIdsReq
 */
export interface ComCowIdsReq {
    /**
     * 
     * @type {Array<number>}
     * @memberof ComCowIdsReq
     */
    cow_ids: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ComCowIdsReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface ComIdDto
 */
export interface ComIdDto {
    /**
     * 
     * @type {number}
     * @memberof ComIdDto
     */
    id: number;
}
/**
 * 
 * @export
 * @interface ComOnpVetellMybatisBreedingEventBaseDao
 */
export interface ComOnpVetellMybatisBreedingEventBaseDao {
    /**
     * created_by
     * @type {string}
     * @memberof ComOnpVetellMybatisBreedingEventBaseDao
     */
    created_by?: string;
    /**
     * watched_at
     * @type {string}
     * @memberof ComOnpVetellMybatisBreedingEventBaseDao
     */
    watched_at?: string;
    /**
     * watched_by
     * @type {string}
     * @memberof ComOnpVetellMybatisBreedingEventBaseDao
     */
    watched_by?: string;
}
/**
 * 
 * @export
 * @interface ComOnpVetellMybatisCowBreedDao
 */
export interface ComOnpVetellMybatisCowBreedDao {
    /**
     * breed_no
     * @type {number}
     * @memberof ComOnpVetellMybatisCowBreedDao
     */
    breed_no?: number;
    /**
     * disp_no
     * @type {number}
     * @memberof ComOnpVetellMybatisCowBreedDao
     */
    disp_no?: number;
    /**
     * is_deleted
     * @type {number}
     * @memberof ComOnpVetellMybatisCowBreedDao
     */
    is_deleted?: number;
    /**
     * name
     * @type {string}
     * @memberof ComOnpVetellMybatisCowBreedDao
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface ComOnpVetellMybatisCowConditionDao
 */
export interface ComOnpVetellMybatisCowConditionDao {
    /**
     * condition_id
     * @type {number}
     * @memberof ComOnpVetellMybatisCowConditionDao
     */
    condition_id?: number;
    /**
     * disp_no
     * @type {number}
     * @memberof ComOnpVetellMybatisCowConditionDao
     */
    disp_no?: number;
    /**
     * is_deleted
     * @type {number}
     * @memberof ComOnpVetellMybatisCowConditionDao
     */
    is_deleted?: number;
    /**
     * name
     * @type {string}
     * @memberof ComOnpVetellMybatisCowConditionDao
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface ComOnpVetellMybatisCowDiseaseDao
 */
export interface ComOnpVetellMybatisCowDiseaseDao {
    /**
     * 
     * @type {number}
     * @memberof ComOnpVetellMybatisCowDiseaseDao
     */
    category?: number;
    /**
     * 
     * @type {number}
     * @memberof ComOnpVetellMybatisCowDiseaseDao
     */
    disease_id?: number;
    /**
     * 
     * @type {number}
     * @memberof ComOnpVetellMybatisCowDiseaseDao
     */
    disp_no?: number;
    /**
     * 
     * @type {number}
     * @memberof ComOnpVetellMybatisCowDiseaseDao
     */
    is_breeding?: number;
    /**
     * 
     * @type {number}
     * @memberof ComOnpVetellMybatisCowDiseaseDao
     */
    is_deleted?: number;
    /**
     * 
     * @type {number}
     * @memberof ComOnpVetellMybatisCowDiseaseDao
     */
    is_epidemic?: number;
    /**
     * 
     * @type {string}
     * @memberof ComOnpVetellMybatisCowDiseaseDao
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface ComOnpVetellMybatisCowDiseaseItemDao
 */
export interface ComOnpVetellMybatisCowDiseaseItemDao {
    /**
     * disp_no
     * @type {number}
     * @memberof ComOnpVetellMybatisCowDiseaseItemDao
     */
    disp_no?: number;
    /**
     * is_deleted
     * @type {number}
     * @memberof ComOnpVetellMybatisCowDiseaseItemDao
     */
    is_deleted?: number;
    /**
     * item_no
     * @type {number}
     * @memberof ComOnpVetellMybatisCowDiseaseItemDao
     */
    item_no?: number;
    /**
     * item_type
     * @type {number}
     * @memberof ComOnpVetellMybatisCowDiseaseItemDao
     */
    item_type?: number;
    /**
     * name
     * @type {string}
     * @memberof ComOnpVetellMybatisCowDiseaseItemDao
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface ComOnpVetellMybatisCowFecesColorDao
 */
export interface ComOnpVetellMybatisCowFecesColorDao {
    /**
     * disp_no
     * @type {number}
     * @memberof ComOnpVetellMybatisCowFecesColorDao
     */
    disp_no?: number;
    /**
     * feces_color
     * @type {string}
     * @memberof ComOnpVetellMybatisCowFecesColorDao
     */
    feces_color?: string;
    /**
     * is_deleted
     * @type {number}
     * @memberof ComOnpVetellMybatisCowFecesColorDao
     */
    is_deleted?: number;
    /**
     * main_no
     * @type {number}
     * @memberof ComOnpVetellMybatisCowFecesColorDao
     */
    main_no?: number;
    /**
     * name
     * @type {string}
     * @memberof ComOnpVetellMybatisCowFecesColorDao
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface ComOnpVetellMybatisCowFecesStateDao
 */
export interface ComOnpVetellMybatisCowFecesStateDao {
    /**
     * disp_no
     * @type {number}
     * @memberof ComOnpVetellMybatisCowFecesStateDao
     */
    disp_no?: number;
    /**
     * is_deleted
     * @type {number}
     * @memberof ComOnpVetellMybatisCowFecesStateDao
     */
    is_deleted?: number;
    /**
     * name
     * @type {string}
     * @memberof ComOnpVetellMybatisCowFecesStateDao
     */
    name?: string;
    /**
     * state_no
     * @type {number}
     * @memberof ComOnpVetellMybatisCowFecesStateDao
     */
    state_no?: number;
}
/**
 * 
 * @export
 * @interface ComOnpVetellMybatisCowMedicineCategoryDao
 */
export interface ComOnpVetellMybatisCowMedicineCategoryDao {
    /**
     * category
     * @type {number}
     * @memberof ComOnpVetellMybatisCowMedicineCategoryDao
     */
    category?: number;
    /**
     * disp_no
     * @type {number}
     * @memberof ComOnpVetellMybatisCowMedicineCategoryDao
     */
    disp_no?: number;
    /**
     * is_deleted
     * @type {number}
     * @memberof ComOnpVetellMybatisCowMedicineCategoryDao
     */
    is_deleted?: number;
    /**
     * name
     * @type {string}
     * @memberof ComOnpVetellMybatisCowMedicineCategoryDao
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface ComOnpVetellMybatisCowTreatKindDao
 */
export interface ComOnpVetellMybatisCowTreatKindDao {
    /**
     * disp_no
     * @type {number}
     * @memberof ComOnpVetellMybatisCowTreatKindDao
     */
    disp_no?: number;
    /**
     * is_deleted
     * @type {number}
     * @memberof ComOnpVetellMybatisCowTreatKindDao
     */
    is_deleted?: number;
    /**
     * is_prevention
     * @type {number}
     * @memberof ComOnpVetellMybatisCowTreatKindDao
     */
    is_prevention?: number;
    /**
     * name
     * @type {string}
     * @memberof ComOnpVetellMybatisCowTreatKindDao
     */
    name?: string;
    /**
     * note_name
     * @type {string}
     * @memberof ComOnpVetellMybatisCowTreatKindDao
     */
    note_name?: string;
    /**
     * short_name
     * @type {string}
     * @memberof ComOnpVetellMybatisCowTreatKindDao
     */
    short_name?: string;
    /**
     * treat_kind_no
     * @type {number}
     * @memberof ComOnpVetellMybatisCowTreatKindDao
     */
    treat_kind_no?: number;
}
/**
 * 
 * @export
 * @interface ComOnpVetellMybatisDeliveryHistoryDao
 */
export interface ComOnpVetellMybatisDeliveryHistoryDao {
    /**
     * delivered_at
     * @type {string}
     * @memberof ComOnpVetellMybatisDeliveryHistoryDao
     */
    delivered_at?: string;
    /**
     * delivery_id
     * @type {number}
     * @memberof ComOnpVetellMybatisDeliveryHistoryDao
     */
    delivery_id?: number;
}
/**
 * 
 * @export
 * @interface ComOnpVetellMybatisFeedAmount2Dao
 */
export interface ComOnpVetellMybatisFeedAmount2Dao {
    /**
     * cow_id
     * @type {number}
     * @memberof ComOnpVetellMybatisFeedAmount2Dao
     */
    cow_id?: number;
    /**
     * delivered
     * @type {number}
     * @memberof ComOnpVetellMybatisFeedAmount2Dao
     */
    delivered?: number;
    /**
     * delivered_raw
     * @type {number}
     * @memberof ComOnpVetellMybatisFeedAmount2Dao
     */
    delivered_raw?: number;
    /**
     * fed_at
     * @type {string}
     * @memberof ComOnpVetellMybatisFeedAmount2Dao
     */
    fed_at?: string;
    /**
     * feed_no
     * @type {number}
     * @memberof ComOnpVetellMybatisFeedAmount2Dao
     */
    feed_no?: number;
    /**
     * feed_type_no
     * @type {number}
     * @memberof ComOnpVetellMybatisFeedAmount2Dao
     */
    feed_type_no?: number;
    /**
     * feeding_id
     * @type {number}
     * @memberof ComOnpVetellMybatisFeedAmount2Dao
     */
    feeding_id?: number;
    /**
     * leftover
     * @type {number}
     * @memberof ComOnpVetellMybatisFeedAmount2Dao
     */
    leftover?: number;
    /**
     * leftover_raw
     * @type {number}
     * @memberof ComOnpVetellMybatisFeedAmount2Dao
     */
    leftover_raw?: number;
    /**
     * local_no
     * @type {string}
     * @memberof ComOnpVetellMybatisFeedAmount2Dao
     */
    local_no?: string;
    /**
     * name
     * @type {string}
     * @memberof ComOnpVetellMybatisFeedAmount2Dao
     */
    name?: string;
    /**
     * trace_id
     * @type {string}
     * @memberof ComOnpVetellMybatisFeedAmount2Dao
     */
    trace_id?: string;
    /**
     * unit_price
     * @type {number}
     * @memberof ComOnpVetellMybatisFeedAmount2Dao
     */
    unit_price?: number;
}
/**
 * 
 * @export
 * @interface ComOnpVetellMybatisFeedAmountDao
 */
export interface ComOnpVetellMybatisFeedAmountDao {
    /**
     * delivered
     * @type {number}
     * @memberof ComOnpVetellMybatisFeedAmountDao
     */
    delivered: number;
    /**
     * delivered_raw
     * @type {number}
     * @memberof ComOnpVetellMybatisFeedAmountDao
     */
    delivered_raw: number;
    /**
     * feed_no
     * @type {number}
     * @memberof ComOnpVetellMybatisFeedAmountDao
     */
    feed_no: number;
    /**
     * feed_type_no
     * @type {number}
     * @memberof ComOnpVetellMybatisFeedAmountDao
     */
    feed_type_no: number;
    /**
     * feeding_id
     * @type {number}
     * @memberof ComOnpVetellMybatisFeedAmountDao
     */
    feeding_id: number;
    /**
     * leftover
     * @type {number}
     * @memberof ComOnpVetellMybatisFeedAmountDao
     */
    leftover?: number;
    /**
     * leftover_raw
     * @type {number}
     * @memberof ComOnpVetellMybatisFeedAmountDao
     */
    leftover_raw?: number;
    /**
     * unit_price
     * @type {number}
     * @memberof ComOnpVetellMybatisFeedAmountDao
     */
    unit_price: number;
}
/**
 * 
 * @export
 * @interface ComOnpVetellMybatisFeedDataDao
 */
export interface ComOnpVetellMybatisFeedDataDao {
    /**
     * comment
     * @type {string}
     * @memberof ComOnpVetellMybatisFeedDataDao
     */
    comment?: string;
    /**
     * cow_id
     * @type {number}
     * @memberof ComOnpVetellMybatisFeedDataDao
     */
    cow_id?: number;
    /**
     * created_by
     * @type {string}
     * @memberof ComOnpVetellMybatisFeedDataDao
     */
    created_by?: string;
    /**
     * fed_at
     * @type {string}
     * @memberof ComOnpVetellMybatisFeedDataDao
     */
    fed_at?: string;
    /**
     * fed_by
     * @type {string}
     * @memberof ComOnpVetellMybatisFeedDataDao
     */
    fed_by?: string;
    /**
     * feed_amount
     * @type {Array<ComOnpVetellMybatisFeedAmountDao>}
     * @memberof ComOnpVetellMybatisFeedDataDao
     */
    feed_amount?: Array<ComOnpVetellMybatisFeedAmountDao>;
    /**
     * feeding_id
     * @type {number}
     * @memberof ComOnpVetellMybatisFeedDataDao
     */
    feeding_id?: number;
    /**
     * is_male
     * @type {string}
     * @memberof ComOnpVetellMybatisFeedDataDao
     */
    is_male?: string;
    /**
     * local_no
     * @type {string}
     * @memberof ComOnpVetellMybatisFeedDataDao
     */
    local_no?: string;
    /**
     * name
     * @type {string}
     * @memberof ComOnpVetellMybatisFeedDataDao
     */
    name?: string;
    /**
     * ranch_id
     * @type {number}
     * @memberof ComOnpVetellMybatisFeedDataDao
     */
    ranch_id?: number;
    /**
     * trace_id
     * @type {string}
     * @memberof ComOnpVetellMybatisFeedDataDao
     */
    trace_id?: string;
}
/**
 * 
 * @export
 * @interface ComOnpVetellMybatisFeedTypeDao
 */
export interface ComOnpVetellMybatisFeedTypeDao {
    /**
     * disp_no
     * @type {number}
     * @memberof ComOnpVetellMybatisFeedTypeDao
     */
    disp_no?: number;
    /**
     * feed_type_detail
     * @type {Array<ComOnpVetellMybatisFeedTypeDetailDao>}
     * @memberof ComOnpVetellMybatisFeedTypeDao
     */
    feed_type_detail?: Array<ComOnpVetellMybatisFeedTypeDetailDao>;
    /**
     * feed_type_no
     * @type {number}
     * @memberof ComOnpVetellMybatisFeedTypeDao
     */
    feed_type_no?: number;
    /**
     * feeding_unit_amount
     * @type {number}
     * @memberof ComOnpVetellMybatisFeedTypeDao
     */
    feeding_unit_amount?: number;
    /**
     * feeding_unit_amount_raw
     * @type {number}
     * @memberof ComOnpVetellMybatisFeedTypeDao
     */
    feeding_unit_amount_raw?: number;
    /**
     * is_deleted
     * @type {number}
     * @memberof ComOnpVetellMybatisFeedTypeDao
     */
    is_deleted?: number;
    /**
     * name
     * @type {string}
     * @memberof ComOnpVetellMybatisFeedTypeDao
     */
    name?: string;
    /**
     * price_unit_amount
     * @type {number}
     * @memberof ComOnpVetellMybatisFeedTypeDao
     */
    price_unit_amount?: number;
    /**
     * unit
     * @type {string}
     * @memberof ComOnpVetellMybatisFeedTypeDao
     */
    unit?: string;
    /**
     * unit_for_price
     * @type {string}
     * @memberof ComOnpVetellMybatisFeedTypeDao
     */
    unit_for_price?: string;
    /**
     * unit_raw
     * @type {string}
     * @memberof ComOnpVetellMybatisFeedTypeDao
     */
    unit_raw?: string;
}
/**
 * 
 * @export
 * @interface ComOnpVetellMybatisFeedTypeDetailDao
 */
export interface ComOnpVetellMybatisFeedTypeDetailDao {
    /**
     * feed_type_detail_no
     * @type {number}
     * @memberof ComOnpVetellMybatisFeedTypeDetailDao
     */
    feed_type_detail_no?: number;
    /**
     * name
     * @type {string}
     * @memberof ComOnpVetellMybatisFeedTypeDetailDao
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface ComOnpVetellMybatisRanchHouseDao
 */
export interface ComOnpVetellMybatisRanchHouseDao {
    /**
     * created_at
     * @type {string}
     * @memberof ComOnpVetellMybatisRanchHouseDao
     */
    created_at?: string;
    /**
     * created_by
     * @type {string}
     * @memberof ComOnpVetellMybatisRanchHouseDao
     */
    created_by?: string;
    /**
     * disp_no
     * @type {number}
     * @memberof ComOnpVetellMybatisRanchHouseDao
     */
    disp_no?: number;
    /**
     * is_deleted
     * @type {number}
     * @memberof ComOnpVetellMybatisRanchHouseDao
     */
    is_deleted?: number;
    /**
     * level
     * @type {number}
     * @memberof ComOnpVetellMybatisRanchHouseDao
     */
    level?: number;
    /**
     * name
     * @type {string}
     * @memberof ComOnpVetellMybatisRanchHouseDao
     */
    name?: string;
    /**
     * no
     * @type {number}
     * @memberof ComOnpVetellMybatisRanchHouseDao
     */
    no?: number;
    /**
     * parent_no
     * @type {number}
     * @memberof ComOnpVetellMybatisRanchHouseDao
     */
    parent_no?: number;
    /**
     * ranch_id
     * @type {number}
     * @memberof ComOnpVetellMybatisRanchHouseDao
     */
    ranch_id?: number;
    /**
     * updated_at
     * @type {string}
     * @memberof ComOnpVetellMybatisRanchHouseDao
     */
    updated_at?: string;
    /**
     * updated_by
     * @type {string}
     * @memberof ComOnpVetellMybatisRanchHouseDao
     */
    updated_by?: string;
}
/**
 * 
 * @export
 * @interface ComOnpVetellMybatisTeam1Dao
 */
export interface ComOnpVetellMybatisTeam1Dao {
    /**
     * address
     * @type {string}
     * @memberof ComOnpVetellMybatisTeam1Dao
     */
    address?: string;
    /**
     * clinic_id
     * @type {number}
     * @memberof ComOnpVetellMybatisTeam1Dao
     */
    clinic_id: number;
    /**
     * 
     * @type {number}
     * @memberof ComOnpVetellMybatisTeam1Dao
     */
    contracts: number;
    /**
     * level
     * @type {number}
     * @memberof ComOnpVetellMybatisTeam1Dao
     */
    level: number;
    /**
     * level_detail
     * @type {number}
     * @memberof ComOnpVetellMybatisTeam1Dao
     */
    level_detail: number;
    /**
     * name
     * @type {string}
     * @memberof ComOnpVetellMybatisTeam1Dao
     */
    name: string;
    /**
     * pref_cd
     * @type {string}
     * @memberof ComOnpVetellMybatisTeam1Dao
     */
    pref_cd?: string;
    /**
     * ranch_id
     * @type {number}
     * @memberof ComOnpVetellMybatisTeam1Dao
     */
    ranch_id: number;
    /**
     * requests
     * @type {Array<TeamAcceptRequestDto>}
     * @memberof ComOnpVetellMybatisTeam1Dao
     */
    requests?: Array<TeamAcceptRequestDto>;
    /**
     * size
     * @type {number}
     * @memberof ComOnpVetellMybatisTeam1Dao
     */
    size: number;
    /**
     * team_id
     * @type {number}
     * @memberof ComOnpVetellMybatisTeam1Dao
     */
    team_id: number;
    /**
     * team_symbol
     * @type {string}
     * @memberof ComOnpVetellMybatisTeam1Dao
     */
    team_symbol: string;
    /**
     * 
     * @type {string}
     * @memberof ComOnpVetellMybatisTeam1Dao
     */
    tel?: string;
}
/**
 * 
 * @export
 * @interface ComOnpVetellMybatisTeamTreatItemDao
 */
export interface ComOnpVetellMybatisTeamTreatItemDao {
    /**
     * 
     * @type {string}
     * @memberof ComOnpVetellMybatisTeamTreatItemDao
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof ComOnpVetellMybatisTeamTreatItemDao
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof ComOnpVetellMybatisTeamTreatItemDao
     */
    created_by?: string;
    /**
     * 
     * @type {number}
     * @memberof ComOnpVetellMybatisTeamTreatItemDao
     */
    disp_no?: number;
    /**
     * 
     * @type {number}
     * @memberof ComOnpVetellMybatisTeamTreatItemDao
     */
    fee?: number;
    /**
     * 
     * @type {string}
     * @memberof ComOnpVetellMybatisTeamTreatItemDao
     */
    fee_unit?: string;
    /**
     * 
     * @type {number}
     * @memberof ComOnpVetellMybatisTeamTreatItemDao
     */
    is_deleted?: number;
    /**
     * 
     * @type {string}
     * @memberof ComOnpVetellMybatisTeamTreatItemDao
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ComOnpVetellMybatisTeamTreatItemDao
     */
    point_a?: number;
    /**
     * 
     * @type {number}
     * @memberof ComOnpVetellMybatisTeamTreatItemDao
     */
    point_b?: number;
    /**
     * 
     * @type {number}
     * @memberof ComOnpVetellMybatisTeamTreatItemDao
     */
    team_id?: number;
    /**
     * 
     * @type {number}
     * @memberof ComOnpVetellMybatisTeamTreatItemDao
     */
    treat_item_no?: number;
    /**
     * 
     * @type {number}
     * @memberof ComOnpVetellMybatisTeamTreatItemDao
     */
    treat_kind_no?: number;
    /**
     * 
     * @type {string}
     * @memberof ComOnpVetellMybatisTeamTreatItemDao
     */
    updated_at?: string;
    /**
     * 
     * @type {string}
     * @memberof ComOnpVetellMybatisTeamTreatItemDao
     */
    updated_by?: string;
}
/**
 * 
 * @export
 * @interface ComRanchAndIdReq
 */
export interface ComRanchAndIdReq {
    /**
     * 
     * @type {number}
     * @memberof ComRanchAndIdReq
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ComRanchAndIdReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface ComRanchIdReq
 */
export interface ComRanchIdReq {
    /**
     * 
     * @type {number}
     * @memberof ComRanchIdReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface ComTeamAndIdReq
 */
export interface ComTeamAndIdReq {
    /**
     * 
     * @type {number}
     * @memberof ComTeamAndIdReq
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ComTeamAndIdReq
     */
    team_id: number;
}
/**
 * 
 * @export
 * @interface ComTeamIdAndSinceReq
 */
export interface ComTeamIdAndSinceReq {
    /**
     * 
     * @type {string}
     * @memberof ComTeamIdAndSinceReq
     */
    since?: string;
    /**
     * 
     * @type {number}
     * @memberof ComTeamIdAndSinceReq
     */
    team_id: number;
}
/**
 * 
 * @export
 * @interface ComTeamIdReq
 */
export interface ComTeamIdReq {
    /**
     * 
     * @type {number}
     * @memberof ComTeamIdReq
     */
    team_id: number;
}
/**
 * 
 * @export
 * @interface ComTeamSortReq
 */
export interface ComTeamSortReq {
    /**
     * 
     * @type {Array<number>}
     * @memberof ComTeamSortReq
     */
    order: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ComTeamSortReq
     */
    team_id: number;
}
/**
 * 
 * @export
 * @interface CowBreedingModifyReq
 */
export interface CowBreedingModifyReq {
    /**
     * activity
     * @type {number}
     * @memberof CowBreedingModifyReq
     */
    activity?: number;
    /**
     * cow_ids
     * @type {Array<number>}
     * @memberof CowBreedingModifyReq
     */
    cow_ids: Array<number>;
    /**
     * open_day
     * @type {string}
     * @memberof CowBreedingModifyReq
     */
    open_day?: string;
    /**
     * ranch_id
     * @type {number}
     * @memberof CowBreedingModifyReq
     */
    ranch_id: number;
    /**
     * state
     * @type {number}
     * @memberof CowBreedingModifyReq
     */
    state: number;
}
/**
 * 
 * @export
 * @interface CowCurrentLocationDto
 */
export interface CowCurrentLocationDto {
    /**
     * 
     * @type {number}
     * @memberof CowCurrentLocationDto
     */
    barn: number;
    /**
     * 
     * @type {number}
     * @memberof CowCurrentLocationDto
     */
    room: number;
    /**
     * 
     * @type {number}
     * @memberof CowCurrentLocationDto
     */
    site: number;
}
/**
 * 
 * @export
 * @interface CowDetailDto
 */
export interface CowDetailDto {
    /**
     * accept_day
     * @type {string}
     * @memberof CowDetailDto
     */
    accept_day?: string;
    /**
     * accept_from
     * @type {string}
     * @memberof CowDetailDto
     */
    accept_from?: string;
    /**
     * accept_price
     * @type {number}
     * @memberof CowDetailDto
     */
    accept_price?: number;
    /**
     * 
     * @type {number}
     * @memberof CowDetailDto
     */
    accept_use_no?: number;
    /**
     * amount_list
     * @type {Array<FeedDayAmountDao>}
     * @memberof CowDetailDto
     */
    amount_list?: Array<FeedDayAmountDao>;
    /**
     * ancestor_1
     * @type {string}
     * @memberof CowDetailDto
     */
    ancestor_1?: string;
    /**
     * 
     * @type {number}
     * @memberof CowDetailDto
     */
    ancestor_1_exid?: number;
    /**
     * ancestor_2
     * @type {string}
     * @memberof CowDetailDto
     */
    ancestor_2?: string;
    /**
     * 
     * @type {number}
     * @memberof CowDetailDto
     */
    ancestor_2_exid?: number;
    /**
     * ancestor_3
     * @type {string}
     * @memberof CowDetailDto
     */
    ancestor_3?: string;
    /**
     * 
     * @type {number}
     * @memberof CowDetailDto
     */
    ancestor_3_exid?: number;
    /**
     * barn
     * @type {number}
     * @memberof CowDetailDto
     */
    barn: number;
    /**
     * birthday
     * @type {string}
     * @memberof CowDetailDto
     */
    birthday?: string;
    /**
     * breed_no
     * @type {number}
     * @memberof CowDetailDto
     */
    breed_no?: number;
    /**
     * breeding_state
     * @type {number}
     * @memberof CowDetailDto
     */
    breeding_state: number;
    /**
     * cow_id
     * @type {number}
     * @memberof CowDetailDto
     */
    cow_id: number;
    /**
     * created_at
     * @type {string}
     * @memberof CowDetailDto
     */
    created_at: string;
    /**
     * created_by
     * @type {string}
     * @memberof CowDetailDto
     */
    created_by: string;
    /**
     * 
     * @type {string}
     * @memberof CowDetailDto
     */
    end_day?: string;
    /**
     * 
     * @type {number}
     * @memberof CowDetailDto
     */
    end_kind?: number;
    /**
     * event_list
     * @type {Array<CowEventHistoryDto>}
     * @memberof CowDetailDto
     */
    event_list?: Array<CowEventHistoryDto>;
    /**
     * is_active
     * @type {number}
     * @memberof CowDetailDto
     */
    is_active: number;
    /**
     * is_deleted
     * @type {number}
     * @memberof CowDetailDto
     */
    is_deleted: number;
    /**
     * is_male
     * @type {number}
     * @memberof CowDetailDto
     */
    is_male: number;
    /**
     * local_no
     * @type {string}
     * @memberof CowDetailDto
     */
    local_no?: string;
    /**
     * 
     * @type {CowMotherDto}
     * @memberof CowDetailDto
     */
    mother?: CowMotherDto;
    /**
     * mother_id
     * @type {number}
     * @memberof CowDetailDto
     */
    mother_id?: number;
    /**
     * mother_name
     * @type {string}
     * @memberof CowDetailDto
     */
    mother_name?: string;
    /**
     * name
     * @type {string}
     * @memberof CowDetailDto
     */
    name?: string;
    /**
     * note
     * @type {string}
     * @memberof CowDetailDto
     */
    note?: string;
    /**
     * open_day
     * @type {string}
     * @memberof CowDetailDto
     */
    open_day?: string;
    /**
     * ranch_id
     * @type {number}
     * @memberof CowDetailDto
     */
    ranch_id: number;
    /**
     * room
     * @type {number}
     * @memberof CowDetailDto
     */
    room: number;
    /**
     * 
     * @type {number}
     * @memberof CowDetailDto
     */
    sell_plan?: number;
    /**
     * site
     * @type {number}
     * @memberof CowDetailDto
     */
    site: number;
    /**
     * start_day
     * @type {string}
     * @memberof CowDetailDto
     */
    start_day?: string;
    /**
     * start_kind
     * @type {number}
     * @memberof CowDetailDto
     */
    start_kind: number;
    /**
     * std_weight 
     * @type {number}
     * @memberof CowDetailDto
     */
    std_weight?: number;
    /**
     * 
     * @type {CowMotherDto}
     * @memberof CowDetailDto
     */
    surrogate_mother?: CowMotherDto;
    /**
     * surrogate_mother_id
     * @type {number}
     * @memberof CowDetailDto
     */
    surrogate_mother_id?: number;
    /**
     * surrogate_mother_name
     * @type {string}
     * @memberof CowDetailDto
     */
    surrogate_mother_name?: string;
    /**
     * tags
     * @type {Array<number>}
     * @memberof CowDetailDto
     */
    tags: Array<number>;
    /**
     * trace_id
     * @type {string}
     * @memberof CowDetailDto
     */
    trace_id: string;
    /**
     * updated_at
     * @type {string}
     * @memberof CowDetailDto
     */
    updated_at: string;
    /**
     * updated_by
     * @type {string}
     * @memberof CowDetailDto
     */
    updated_by: string;
    /**
     * use_no
     * @type {number}
     * @memberof CowDetailDto
     */
    use_no?: number;
    /**
     * 
     * @type {Array<WashoutDto>}
     * @memberof CowDetailDto
     */
    washouts: Array<WashoutDto>;
    /**
     * watching
     * @type {number}
     * @memberof CowDetailDto
     */
    watching: number;
    /**
     * watching_memo
     * @type {string}
     * @memberof CowDetailDto
     */
    watching_memo: string;
}
/**
 * 
 * @export
 * @interface CowDiseaseItemsDto
 */
export interface CowDiseaseItemsDto {
    /**
     * cause
     * @type {Array<ComOnpVetellMybatisCowDiseaseItemDao>}
     * @memberof CowDiseaseItemsDto
     */
    cause?: Array<ComOnpVetellMybatisCowDiseaseItemDao>;
    /**
     * notice
     * @type {Array<ComOnpVetellMybatisCowDiseaseItemDao>}
     * @memberof CowDiseaseItemsDto
     */
    notice?: Array<ComOnpVetellMybatisCowDiseaseItemDao>;
}
/**
 * 
 * @export
 * @interface CowDiseaseItemsReq
 */
export interface CowDiseaseItemsReq {
    /**
     * disease_id
     * @type {string}
     * @memberof CowDiseaseItemsReq
     */
    disease_id: string;
}
/**
 * 
 * @export
 * @interface CowEventHistoryDto
 */
export interface CowEventHistoryDto {
    /**
     * 
     * @type {string}
     * @memberof CowEventHistoryDto
     */
    day: string;
    /**
     * 
     * @type {number}
     * @memberof CowEventHistoryDto
     */
    event_id: number;
    /**
     * 
     * @type {number}
     * @memberof CowEventHistoryDto
     */
    event_kind_no: number;
    /**
     * 
     * @type {string}
     * @memberof CowEventHistoryDto
     */
    reg_time: string;
}
/**
 * 
 * @export
 * @interface CowEventHistoryReq
 */
export interface CowEventHistoryReq {
    /**
     * cow_id
     * @type {number}
     * @memberof CowEventHistoryReq
     */
    cow_id: number;
    /**
     * 
     * @type {number}
     * @memberof CowEventHistoryReq
     */
    defaultEventCount?: number;
    /**
     * event_count
     * @type {number}
     * @memberof CowEventHistoryReq
     */
    event_count?: number;
    /**
     * page_no
     * @type {number}
     * @memberof CowEventHistoryReq
     */
    page_no: number;
    /**
     * ranch_id
     * @type {number}
     * @memberof CowEventHistoryReq
     */
    ranch_id: number;
    /**
     * user_id
     * @type {string}
     * @memberof CowEventHistoryReq
     */
    user_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof CowEventHistoryReq
     */
    valid?: boolean;
}
/**
 * 
 * @export
 * @interface CowEventListDto
 */
export interface CowEventListDto {
    /**
     * event_list
     * @type {Array<CowEventHistoryDto>}
     * @memberof CowEventListDto
     */
    event_list: Array<CowEventHistoryDto>;
    /**
     * page_no
     * @type {number}
     * @memberof CowEventListDto
     */
    page_no: number;
}
/**
 * 
 * @export
 * @interface CowExportDto
 */
export interface CowExportDto {
    /**
     * 
     * @type {string}
     * @memberof CowExportDto
     */
    csv: string;
    /**
     * 
     * @type {string}
     * @memberof CowExportDto
     */
    trace_id: string;
}
/**
 * 
 * @export
 * @interface CowExportReq
 */
export interface CowExportReq {
    /**
     * 
     * @type {number}
     * @memberof CowExportReq
     */
    cow_id: number;
    /**
     * 取得対象のイベント種別コードを指定します。nullの場合、空リストの場合は全イベントを取得対象とします
     * @type {Array<number>}
     * @memberof CowExportReq
     */
    event_kinds?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof CowExportReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface CowFilterDto
 */
export interface CowFilterDto {
    /**
     * 
     * @type {Array<CowFilterItemDto>}
     * @memberof CowFilterDto
     */
    list: Array<CowFilterItemDto>;
}
/**
 * 
 * @export
 * @interface CowFilterItemDto
 */
export interface CowFilterItemDto {
    /**
     * 
     * @type {string}
     * @memberof CowFilterItemDto
     */
    conditions: string;
    /**
     * 
     * @type {number}
     * @memberof CowFilterItemDto
     */
    filter_no: number;
    /**
     * 
     * @type {string}
     * @memberof CowFilterItemDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CowFilterItemDto
     */
    outputs: string;
}
/**
 * 
 * @export
 * @interface CowFilterModifyReq
 */
export interface CowFilterModifyReq {
    /**
     * 
     * @type {string}
     * @memberof CowFilterModifyReq
     */
    conditions: string;
    /**
     * 
     * @type {number}
     * @memberof CowFilterModifyReq
     */
    filter_no?: number;
    /**
     * 
     * @type {number}
     * @memberof CowFilterModifyReq
     */
    is_new: number;
    /**
     * 
     * @type {string}
     * @memberof CowFilterModifyReq
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CowFilterModifyReq
     */
    outputs: string;
    /**
     * 
     * @type {number}
     * @memberof CowFilterModifyReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface CowFilterSortReq
 */
export interface CowFilterSortReq {
    /**
     * 
     * @type {Array<number>}
     * @memberof CowFilterSortReq
     */
    order: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof CowFilterSortReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface CowIdDto
 */
export interface CowIdDto {
    /**
     * cow_id
     * @type {number}
     * @memberof CowIdDto
     */
    cow_id: number;
}
/**
 * 
 * @export
 * @interface CowInfoReq
 */
export interface CowInfoReq {
    /**
     * 
     * @type {number}
     * @memberof CowInfoReq
     */
    cow_id: number;
    /**
     * 
     * @type {number}
     * @memberof CowInfoReq
     */
    defaultEventCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CowInfoReq
     */
    detail?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CowInfoReq
     */
    event_count?: number;
    /**
     * 
     * @type {number}
     * @memberof CowInfoReq
     */
    feed_amount_days?: number;
    /**
     * 
     * @type {number}
     * @memberof CowInfoReq
     */
    ranch_id: number;
    /**
     * 
     * @type {boolean}
     * @memberof CowInfoReq
     */
    valid?: boolean;
}
/**
 * 
 * @export
 * @interface CowListAndCountDto
 */
export interface CowListAndCountDto {
    /**
     * list
     * @type {Array<CowListDto>}
     * @memberof CowListAndCountDto
     */
    list: Array<CowListDto>;
    /**
     * total_cnt
     * @type {number}
     * @memberof CowListAndCountDto
     */
    total_cnt: number;
}
/**
 * 
 * @export
 * @interface CowListDto
 */
export interface CowListDto {
    /**
     * barn
     * @type {number}
     * @memberof CowListDto
     */
    barn: number;
    /**
     * birthday
     * @type {string}
     * @memberof CowListDto
     */
    birthday?: string;
    /**
     * 
     * @type {number}
     * @memberof CowListDto
     */
    breed_no?: number;
    /**
     * breeding_state
     * @type {number}
     * @memberof CowListDto
     */
    breeding_state: number;
    /**
     * cow_id
     * @type {number}
     * @memberof CowListDto
     */
    cow_id: number;
    /**
     * is_active
     * @type {number}
     * @memberof CowListDto
     */
    is_active: number;
    /**
     * is_deleted
     * @type {number}
     * @memberof CowListDto
     */
    is_deleted: number;
    /**
     * local_no
     * @type {string}
     * @memberof CowListDto
     */
    local_no?: string;
    /**
     * moved_at
     * @type {string}
     * @memberof CowListDto
     */
    moved_at: string;
    /**
     * name
     * @type {string}
     * @memberof CowListDto
     */
    name?: string;
    /**
     * rep_no
     * @type {string}
     * @memberof CowListDto
     */
    rep_no?: string;
    /**
     * room
     * @type {number}
     * @memberof CowListDto
     */
    room: number;
    /**
     * site
     * @type {number}
     * @memberof CowListDto
     */
    site: number;
    /**
     * tags
     * @type {Array<number>}
     * @memberof CowListDto
     */
    tags: Array<number>;
    /**
     * trace_id
     * @type {string}
     * @memberof CowListDto
     */
    trace_id: string;
    /**
     * use_no
     * @type {number}
     * @memberof CowListDto
     */
    use_no?: number;
    /**
     * 
     * @type {Array<WashoutDto>}
     * @memberof CowListDto
     */
    washouts: Array<WashoutDto>;
    /**
     * watching
     * @type {number}
     * @memberof CowListDto
     */
    watching: number;
    /**
     * watching_memo
     * @type {string}
     * @memberof CowListDto
     */
    watching_memo: string;
}
/**
 * 
 * @export
 * @interface CowListReq
 */
export interface CowListReq {
    /**
     * age_from
     * @type {number}
     * @memberof CowListReq
     */
    age_from?: number;
    /**
     * age_to
     * @type {number}
     * @memberof CowListReq
     */
    age_to?: number;
    /**
     * breeds
     * @type {Array<number>}
     * @memberof CowListReq
     */
    breeds?: Array<number>;
    /**
     * count
     * @type {number}
     * @memberof CowListReq
     */
    count: number;
    /**
     * houses
     * @type {Array<CowListReqHouse>}
     * @memberof CowListReq
     */
    houses?: Array<CowListReqHouse>;
    /**
     * is_active
     * @type {number}
     * @memberof CowListReq
     */
    is_active?: number;
    /**
     * is_age
     * @type {number}
     * @memberof CowListReq
     */
    is_age?: number;
    /**
     * is_male
     * @type {number}
     * @memberof CowListReq
     */
    is_male?: number;
    /**
     * is_period
     * @type {number}
     * @memberof CowListReq
     */
    is_period?: number;
    /**
     * page
     * @type {number}
     * @memberof CowListReq
     */
    page: number;
    /**
     * period_end
     * @type {string}
     * @memberof CowListReq
     */
    period_end?: string;
    /**
     * period_start
     * @type {string}
     * @memberof CowListReq
     */
    period_start?: string;
    /**
     * ranch_id
     * @type {number}
     * @memberof CowListReq
     */
    ranch_id: number;
    /**
     * since
     * @type {string}
     * @memberof CowListReq
     */
    since?: string;
    /**
     * user_id
     * @type {string}
     * @memberof CowListReq
     */
    user_id?: string;
    /**
     * uses
     * @type {Array<number>}
     * @memberof CowListReq
     */
    uses?: Array<number>;
}
/**
 * 
 * @export
 * @interface CowListReqHouse
 */
export interface CowListReqHouse {
    /**
     * barn
     * @type {number}
     * @memberof CowListReqHouse
     */
    barn?: number;
    /**
     * room
     * @type {number}
     * @memberof CowListReqHouse
     */
    room?: number;
    /**
     * site
     * @type {number}
     * @memberof CowListReqHouse
     */
    site: number;
}
/**
 * 
 * @export
 * @interface CowListSlimDto
 */
export interface CowListSlimDto {
    /**
     * 
     * @type {number}
     * @memberof CowListSlimDto
     */
    barn: number;
    /**
     * 
     * @type {number}
     * @memberof CowListSlimDto
     */
    breed_no?: number;
    /**
     * 
     * @type {number}
     * @memberof CowListSlimDto
     */
    cow_id: number;
    /**
     * 
     * @type {number}
     * @memberof CowListSlimDto
     */
    is_male: number;
    /**
     * 
     * @type {string}
     * @memberof CowListSlimDto
     */
    local_no?: string;
    /**
     * 
     * @type {string}
     * @memberof CowListSlimDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof CowListSlimDto
     */
    room: number;
    /**
     * 
     * @type {number}
     * @memberof CowListSlimDto
     */
    site: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CowListSlimDto
     */
    tags: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof CowListSlimDto
     */
    trace_id: string;
    /**
     * 
     * @type {number}
     * @memberof CowListSlimDto
     */
    use_no?: number;
    /**
     * 
     * @type {number}
     * @memberof CowListSlimDto
     */
    watching_state: number;
}
/**
 * 
 * @export
 * @interface CowLocationDeleteReq
 */
export interface CowLocationDeleteReq {
    /**
     * cow_id
     * @type {number}
     * @memberof CowLocationDeleteReq
     */
    cow_id: number;
    /**
     * ranch_id
     * @type {number}
     * @memberof CowLocationDeleteReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface CowLocationDto
 */
export interface CowLocationDto {
    /**
     * 
     * @type {number}
     * @memberof CowLocationDto
     */
    barn: number;
    /**
     * 
     * @type {number}
     * @memberof CowLocationDto
     */
    room: number;
    /**
     * 
     * @type {number}
     * @memberof CowLocationDto
     */
    site: number;
    /**
     * 
     * @type {string}
     * @memberof CowLocationDto
     */
    start_day: string;
}
/**
 * 
 * @export
 * @interface CowLocationHistDto
 */
export interface CowLocationHistDto {
    /**
     * 
     * @type {Array<CowLocationDto>}
     * @memberof CowLocationHistDto
     */
    list: Array<CowLocationDto>;
}
/**
 * 
 * @export
 * @interface CowLocationHistReq
 */
export interface CowLocationHistReq {
    /**
     * 
     * @type {number}
     * @memberof CowLocationHistReq
     */
    cow_id: number;
    /**
     * 
     * @type {number}
     * @memberof CowLocationHistReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface CowLocationModifyReq
 */
export interface CowLocationModifyReq {
    /**
     * barn
     * @type {number}
     * @memberof CowLocationModifyReq
     */
    barn: number;
    /**
     * cow_ids
     * @type {Array<number>}
     * @memberof CowLocationModifyReq
     */
    cow_ids: Array<number>;
    /**
     * ranch_id
     * @type {number}
     * @memberof CowLocationModifyReq
     */
    ranch_id: number;
    /**
     * room
     * @type {number}
     * @memberof CowLocationModifyReq
     */
    room: number;
    /**
     * schedule_id
     * @type {number}
     * @memberof CowLocationModifyReq
     */
    schedule_id?: number;
    /**
     * site
     * @type {number}
     * @memberof CowLocationModifyReq
     */
    site: number;
    /**
     * start_day
     * @type {string}
     * @memberof CowLocationModifyReq
     */
    start_day: string;
}
/**
 * 
 * @export
 * @interface CowLocationReq
 */
export interface CowLocationReq {
    /**
     * 
     * @type {Array<number>}
     * @memberof CowLocationReq
     */
    cow_id: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof CowLocationReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface CowModifyReq
 */
export interface CowModifyReq {
    /**
     * 
     * @type {string}
     * @memberof CowModifyReq
     */
    accept_day?: string;
    /**
     * 
     * @type {string}
     * @memberof CowModifyReq
     */
    accept_from?: string;
    /**
     * 
     * @type {number}
     * @memberof CowModifyReq
     */
    accept_price?: number;
    /**
     * 
     * @type {number}
     * @memberof CowModifyReq
     */
    accept_use_no?: number;
    /**
     * 
     * @type {number}
     * @memberof CowModifyReq
     */
    accept_weight?: number;
    /**
     * ※廃止
     * @type {string}
     * @memberof CowModifyReq
     */
    ancestor_1?: string;
    /**
     * 
     * @type {number}
     * @memberof CowModifyReq
     */
    ancestor_1_exid?: number;
    /**
     * ※廃止
     * @type {string}
     * @memberof CowModifyReq
     */
    ancestor_2?: string;
    /**
     * 
     * @type {number}
     * @memberof CowModifyReq
     */
    ancestor_2_exid?: number;
    /**
     * ※廃止
     * @type {string}
     * @memberof CowModifyReq
     */
    ancestor_3?: string;
    /**
     * 
     * @type {number}
     * @memberof CowModifyReq
     */
    ancestor_3_exid?: number;
    /**
     * 
     * @type {number}
     * @memberof CowModifyReq
     */
    barn?: number;
    /**
     * 
     * @type {string}
     * @memberof CowModifyReq
     */
    birthday?: string;
    /**
     * 
     * @type {number}
     * @memberof CowModifyReq
     */
    breed_no?: number;
    /**
     * 
     * @type {number}
     * @memberof CowModifyReq
     */
    cow_id?: number;
    /**
     * is_active=0のとき、未来の予定を削除するか
     * @type {number}
     * @memberof CowModifyReq
     */
    delete_schedules?: number;
    /**
     * 
     * @type {number}
     * @memberof CowModifyReq
     */
    delivery_child_no?: number;
    /**
     * 
     * @type {number}
     * @memberof CowModifyReq
     */
    delivery_id?: number;
    /**
     * 
     * @type {string}
     * @memberof CowModifyReq
     */
    end_day?: string;
    /**
     * 
     * @type {number}
     * @memberof CowModifyReq
     */
    end_kind?: number;
    /**
     * 
     * @type {number}
     * @memberof CowModifyReq
     */
    is_active?: number;
    /**
     * 
     * @type {number}
     * @memberof CowModifyReq
     */
    is_deleted?: number;
    /**
     * 
     * @type {number}
     * @memberof CowModifyReq
     */
    is_force?: number;
    /**
     * 
     * @type {number}
     * @memberof CowModifyReq
     */
    is_male?: number;
    /**
     * 
     * @type {number}
     * @memberof CowModifyReq
     */
    is_new: number;
    /**
     * 
     * @type {string}
     * @memberof CowModifyReq
     */
    local_no?: string;
    /**
     * 
     * @type {number}
     * @memberof CowModifyReq
     */
    mother_id?: number;
    /**
     * 
     * @type {string}
     * @memberof CowModifyReq
     */
    mother_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CowModifyReq
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CowModifyReq
     */
    note?: string;
    /**
     * 
     * @type {PreloadedCowProgramReq}
     * @memberof CowModifyReq
     */
    preloaded_program?: PreloadedCowProgramReq;
    /**
     * 
     * @type {number}
     * @memberof CowModifyReq
     */
    ranch_id?: number;
    /**
     * 
     * @type {number}
     * @memberof CowModifyReq
     */
    room?: number;
    /**
     * 
     * @type {number}
     * @memberof CowModifyReq
     */
    sell_plan?: number;
    /**
     * 
     * @type {number}
     * @memberof CowModifyReq
     */
    site?: number;
    /**
     * start_kind
     * @type {number}
     * @memberof CowModifyReq
     */
    start_kind: number;
    /**
     * 
     * @type {number}
     * @memberof CowModifyReq
     */
    surrogate_mother_id?: number;
    /**
     * 
     * @type {string}
     * @memberof CowModifyReq
     */
    surrogate_mother_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CowModifyReq
     */
    trace_id?: string;
    /**
     * 
     * @type {number}
     * @memberof CowModifyReq
     */
    use_no?: number;
    /**
     * 
     * @type {number}
     * @memberof CowModifyReq
     */
    weight?: number;
}
/**
 * 
 * @export
 * @interface CowMotherDto
 */
export interface CowMotherDto {
    /**
     * 
     * @type {string}
     * @memberof CowMotherDto
     */
    birthday?: string;
    /**
     * 
     * @type {number}
     * @memberof CowMotherDto
     */
    breed_no?: number;
    /**
     * 
     * @type {number}
     * @memberof CowMotherDto
     */
    cow_id: number;
    /**
     * 
     * @type {number}
     * @memberof CowMotherDto
     */
    is_active: number;
    /**
     * 
     * @type {number}
     * @memberof CowMotherDto
     */
    is_deleted: number;
    /**
     * 
     * @type {number}
     * @memberof CowMotherDto
     */
    is_male: number;
    /**
     * 
     * @type {string}
     * @memberof CowMotherDto
     */
    local_no?: string;
    /**
     * 
     * @type {string}
     * @memberof CowMotherDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof CowMotherDto
     */
    ranch_id: number;
    /**
     * 
     * @type {string}
     * @memberof CowMotherDto
     */
    trace_id: string;
    /**
     * 
     * @type {number}
     * @memberof CowMotherDto
     */
    use_no?: number;
}
/**
 * 
 * @export
 * @interface CowSearchBalanceDto
 */
export interface CowSearchBalanceDto {
    /**
     * 
     * @type {number}
     * @memberof CowSearchBalanceDto
     */
    balance_category: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchBalanceDto
     */
    price: number;
}
/**
 * 
 * @export
 * @interface CowSearchBreedingDto
 */
export interface CowSearchBreedingDto {
    /**
     * 
     * @type {number}
     * @memberof CowSearchBreedingDto
     */
    bcs?: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchBreedingDto
     */
    breeding_id: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchBreedingDto
     */
    canal_cm?: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchBreedingDto
     */
    canal_tear_score?: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchBreedingDto
     */
    cornu_l_bright_score?: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchBreedingDto
     */
    cornu_l_cm?: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchBreedingDto
     */
    cornu_l_image_score?: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchBreedingDto
     */
    cornu_l_shrink_score?: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchBreedingDto
     */
    cornu_r_bright_score?: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchBreedingDto
     */
    cornu_r_cm?: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchBreedingDto
     */
    cornu_r_image_score?: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchBreedingDto
     */
    cornu_r_shrink_score?: number;
    /**
     * 
     * @type {Array<CowSearchTreatDto>}
     * @memberof CowSearchBreedingDto
     */
    details: Array<CowSearchTreatDto>;
    /**
     * 
     * @type {number}
     * @memberof CowSearchBreedingDto
     */
    ovary_l_mm?: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchBreedingDto
     */
    ovary_r_mm?: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchBreedingDto
     */
    pregnant_score?: number;
    /**
     * 
     * @type {Array<BreedingStructureDto>}
     * @memberof CowSearchBreedingDto
     */
    structures: Array<BreedingStructureDto>;
    /**
     * 
     * @type {number}
     * @memberof CowSearchBreedingDto
     */
    vagina_mucus_score?: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchBreedingDto
     */
    vagina_rut_score?: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchBreedingDto
     */
    vagina_tear_score?: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchBreedingDto
     */
    vulva_blood_score?: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchBreedingDto
     */
    vulva_expand_score?: number;
    /**
     * 
     * @type {string}
     * @memberof CowSearchBreedingDto
     */
    watched_at: string;
}
/**
 * 
 * @export
 * @interface CowSearchCowDto
 */
export interface CowSearchCowDto {
    /**
     * 
     * @type {string}
     * @memberof CowSearchCowDto
     */
    accept_day?: string;
    /**
     * 
     * @type {string}
     * @memberof CowSearchCowDto
     */
    accept_from?: string;
    /**
     * 
     * @type {string}
     * @memberof CowSearchCowDto
     */
    ancestor_1?: string;
    /**
     * 
     * @type {number}
     * @memberof CowSearchCowDto
     */
    ancestor_1_exid?: number;
    /**
     * 
     * @type {string}
     * @memberof CowSearchCowDto
     */
    ancestor_2?: string;
    /**
     * 
     * @type {number}
     * @memberof CowSearchCowDto
     */
    ancestor_2_exid?: number;
    /**
     * 
     * @type {string}
     * @memberof CowSearchCowDto
     */
    ancestor_3?: string;
    /**
     * 
     * @type {number}
     * @memberof CowSearchCowDto
     */
    ancestor_3_exid?: number;
    /**
     * 
     * @type {Array<CowSearchBalanceDto>}
     * @memberof CowSearchCowDto
     */
    balances?: Array<CowSearchBalanceDto>;
    /**
     * 
     * @type {number}
     * @memberof CowSearchCowDto
     */
    barn: number;
    /**
     * 
     * @type {string}
     * @memberof CowSearchCowDto
     */
    birthday?: string;
    /**
     * 
     * @type {string}
     * @memberof CowSearchCowDto
     */
    breed?: string;
    /**
     * 
     * @type {number}
     * @memberof CowSearchCowDto
     */
    breed_no?: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchCowDto
     */
    breeding_state?: number;
    /**
     * 
     * @type {Array<CowSearchBreedingDto>}
     * @memberof CowSearchCowDto
     */
    breedings?: Array<CowSearchBreedingDto>;
    /**
     * 
     * @type {number}
     * @memberof CowSearchCowDto
     */
    cow_id: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchCowDto
     */
    cross_count?: number;
    /**
     * 
     * @type {string}
     * @memberof CowSearchCowDto
     */
    cross_day?: string;
    /**
     * 
     * @type {string}
     * @memberof CowSearchCowDto
     */
    cross_kind?: string;
    /**
     * 
     * @type {Array<CowSearchCrossDto>}
     * @memberof CowSearchCowDto
     */
    crosses?: Array<CowSearchCrossDto>;
    /**
     * 
     * @type {number}
     * @memberof CowSearchCowDto
     */
    days_after_cross?: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchCowDto
     */
    days_after_delivery?: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchCowDto
     */
    days_after_rut?: number;
    /**
     * 
     * @type {Array<CowSearchDeliveryDto>}
     * @memberof CowSearchCowDto
     */
    deliveries?: Array<CowSearchDeliveryDto>;
    /**
     * 
     * @type {number}
     * @memberof CowSearchCowDto
     */
    delivery_count?: number;
    /**
     * 
     * @type {string}
     * @memberof CowSearchCowDto
     */
    delivery_day?: string;
    /**
     * 
     * @type {string}
     * @memberof CowSearchCowDto
     */
    delivery_schedule?: string;
    /**
     * 
     * @type {string}
     * @memberof CowSearchCowDto
     */
    end_day?: string;
    /**
     * 
     * @type {number}
     * @memberof CowSearchCowDto
     */
    end_kind?: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchCowDto
     */
    is_male: number;
    /**
     * 
     * @type {string}
     * @memberof CowSearchCowDto
     */
    local_no: string;
    /**
     * 
     * @type {Array<CowSearchLocationDto>}
     * @memberof CowSearchCowDto
     */
    locations?: Array<CowSearchLocationDto>;
    /**
     * 
     * @type {string}
     * @memberof CowSearchCowDto
     */
    mother_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CowSearchCowDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CowSearchCowDto
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof CowSearchCowDto
     */
    open_day?: string;
    /**
     * 
     * @type {Array<CowSearchPreventionDto>}
     * @memberof CowSearchCowDto
     */
    preventions?: Array<CowSearchPreventionDto>;
    /**
     * 
     * @type {string}
     * @memberof CowSearchCowDto
     */
    rep_no: string;
    /**
     * 
     * @type {number}
     * @memberof CowSearchCowDto
     */
    room: number;
    /**
     * 
     * @type {Array<CowSearchRutDto>}
     * @memberof CowSearchCowDto
     */
    ruts?: Array<CowSearchRutDto>;
    /**
     * 
     * @type {Array<CowSearchScheduleDto>}
     * @memberof CowSearchCowDto
     */
    schedules?: Array<CowSearchScheduleDto>;
    /**
     * 出荷予定月の最終日
     * @type {string}
     * @memberof CowSearchCowDto
     */
    sell_month?: string;
    /**
     * 
     * @type {number}
     * @memberof CowSearchCowDto
     */
    site: number;
    /**
     * 
     * @type {string}
     * @memberof CowSearchCowDto
     */
    surrogate_mother_name?: string;
    /**
     * 
     * @type {Array<CowSearchSymptomPhysicalDto>}
     * @memberof CowSearchCowDto
     */
    symptom_physicals?: Array<CowSearchSymptomPhysicalDto>;
    /**
     * 
     * @type {Array<CowSearchSymptomDto>}
     * @memberof CowSearchCowDto
     */
    symptom_treatments?: Array<CowSearchSymptomDto>;
    /**
     * 
     * @type {Array<CowSearchSymptomDto>}
     * @memberof CowSearchCowDto
     */
    symptoms?: Array<CowSearchSymptomDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CowSearchCowDto
     */
    tags?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CowSearchCowDto
     */
    total_loss?: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchCowDto
     */
    total_profit?: number;
    /**
     * 
     * @type {string}
     * @memberof CowSearchCowDto
     */
    trace_id: string;
    /**
     * 
     * @type {string}
     * @memberof CowSearchCowDto
     */
    use?: string;
    /**
     * 
     * @type {number}
     * @memberof CowSearchCowDto
     */
    use_no?: number;
    /**
     * 
     * @type {Array<WashoutDto>}
     * @memberof CowSearchCowDto
     */
    washouts: Array<WashoutDto>;
    /**
     * 
     * @type {number}
     * @memberof CowSearchCowDto
     */
    watching: number;
    /**
     * 
     * @type {string}
     * @memberof CowSearchCowDto
     */
    watching_memo: string;
}
/**
 * 
 * @export
 * @interface CowSearchCrossDto
 */
export interface CowSearchCrossDto {
    /**
     * 
     * @type {string}
     * @memberof CowSearchCrossDto
     */
    ancestor_2?: string;
    /**
     * 
     * @type {string}
     * @memberof CowSearchCrossDto
     */
    ancestor_3?: string;
    /**
     * 
     * @type {number}
     * @memberof CowSearchCrossDto
     */
    cross_no: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchCrossDto
     */
    cross_type: number;
    /**
     * 
     * @type {string}
     * @memberof CowSearchCrossDto
     */
    father_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CowSearchCrossDto
     */
    lot_name?: string;
    /**
     * 
     * @type {number}
     * @memberof CowSearchCrossDto
     */
    mother_id?: number;
    /**
     * 
     * @type {string}
     * @memberof CowSearchCrossDto
     */
    mother_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CowSearchCrossDto
     */
    seed_ancestor_1?: string;
    /**
     * 
     * @type {string}
     * @memberof CowSearchCrossDto
     */
    seed_ancestor_2?: string;
    /**
     * 
     * @type {string}
     * @memberof CowSearchCrossDto
     */
    seed_ancestor_3?: string;
    /**
     * 
     * @type {string}
     * @memberof CowSearchCrossDto
     */
    seed_ancestor_4?: string;
    /**
     * 
     * @type {number}
     * @memberof CowSearchCrossDto
     */
    seed_cow_id?: number;
    /**
     * 
     * @type {string}
     * @memberof CowSearchCrossDto
     */
    seed_get_from?: string;
    /**
     * 
     * @type {number}
     * @memberof CowSearchCrossDto
     */
    seed_lot_id?: number;
    /**
     * 
     * @type {string}
     * @memberof CowSearchCrossDto
     */
    seed_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CowSearchCrossDto
     */
    seed_sname?: string;
    /**
     * 
     * @type {string}
     * @memberof CowSearchCrossDto
     */
    watched_at: string;
}
/**
 * 
 * @export
 * @interface CowSearchDeliveryDto
 */
export interface CowSearchDeliveryDto {
    /**
     * 
     * @type {Array<DeliveryCowDto>}
     * @memberof CowSearchDeliveryDto
     */
    children: Array<DeliveryCowDto>;
    /**
     * 
     * @type {string}
     * @memberof CowSearchDeliveryDto
     */
    delivered_at: string;
    /**
     * 
     * @type {number}
     * @memberof CowSearchDeliveryDto
     */
    delivery_id: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchDeliveryDto
     */
    difficulty: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchDeliveryDto
     */
    is_aborted: number;
}
/**
 * 
 * @export
 * @interface CowSearchDto
 */
export interface CowSearchDto {
    /**
     * 
     * @type {number}
     * @memberof CowSearchDto
     */
    age_mode: number;
    /**
     * 
     * @type {Array<CowSearchCowDto>}
     * @memberof CowSearchDto
     */
    list: Array<CowSearchCowDto>;
    /**
     * 
     * @type {number}
     * @memberof CowSearchDto
     */
    total_cnt: number;
}
/**
 * 
 * @export
 * @interface CowSearchLocationDto
 */
export interface CowSearchLocationDto {
    /**
     * 
     * @type {number}
     * @memberof CowSearchLocationDto
     */
    barn: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchLocationDto
     */
    room: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchLocationDto
     */
    site: number;
    /**
     * 
     * @type {string}
     * @memberof CowSearchLocationDto
     */
    start_day: string;
}
/**
 * 
 * @export
 * @interface CowSearchPreventionDto
 */
export interface CowSearchPreventionDto {
    /**
     * 
     * @type {Array<CowSearchTreatDto>}
     * @memberof CowSearchPreventionDto
     */
    details: Array<CowSearchTreatDto>;
    /**
     * 
     * @type {number}
     * @memberof CowSearchPreventionDto
     */
    prevention_id: number;
    /**
     * 
     * @type {string}
     * @memberof CowSearchPreventionDto
     */
    watched_at: string;
}
/**
 * 
 * @export
 * @interface CowSearchReq
 */
export interface CowSearchReq {
    /**
     * 
     * @type {CowSearchReqCondition}
     * @memberof CowSearchReq
     */
    condition: CowSearchReqCondition;
    /**
     * 
     * @type {number}
     * @memberof CowSearchReq
     */
    count: number;
    /**
     * 
     * @type {CowSearchReqOutput}
     * @memberof CowSearchReq
     */
    output: CowSearchReqOutput;
    /**
     * 
     * @type {number}
     * @memberof CowSearchReq
     */
    page: number;
    /**
     * 月齢絞込み、分娩後日数や交配後日数等の計算、およびイベント履歴・予定取得範囲の基準となる現在日
     * @type {string}
     * @memberof CowSearchReq
     */
    today?: string;
}
/**
 * 
 * @export
 * @interface CowSearchReqCondition
 */
export interface CowSearchReqCondition {
    /**
     * 
     * @type {number}
     * @memberof CowSearchReqCondition
     */
    age_from?: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchReqCondition
     */
    age_to?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CowSearchReqCondition
     */
    breeding_states?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CowSearchReqCondition
     */
    breeds?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof CowSearchReqCondition
     */
    day_age_from?: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchReqCondition
     */
    day_age_to?: number;
    /**
     * 
     * @type {Array<CowSearchReqEvent>}
     * @memberof CowSearchReqCondition
     */
    events?: Array<CowSearchReqEvent>;
    /**
     * 
     * @type {Array<CowListReqHouse>}
     * @memberof CowSearchReqCondition
     */
    houses?: Array<CowListReqHouse>;
    /**
     * 
     * @type {number}
     * @memberof CowSearchReqCondition
     */
    is_active: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchReqCondition
     */
    is_male?: number;
    /**
     * 
     * @type {string}
     * @memberof CowSearchReqCondition
     */
    period_end?: string;
    /**
     * 
     * @type {string}
     * @memberof CowSearchReqCondition
     */
    period_start?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CowSearchReqCondition
     */
    programs?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof CowSearchReqCondition
     */
    ranch_id: number;
    /**
     * 
     * @type {Array<CowSearchReqSchedule>}
     * @memberof CowSearchReqCondition
     */
    schedules?: Array<CowSearchReqSchedule>;
    /**
     * 
     * @type {number}
     * @memberof CowSearchReqCondition
     */
    start_kind?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CowSearchReqCondition
     */
    tags?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CowSearchReqCondition
     */
    uses?: Array<number>;
}
/**
 * 
 * @export
 * @interface CowSearchReqEvent
 */
export interface CowSearchReqEvent {
    /**
     * 
     * @type {string}
     * @memberof CowSearchReqEvent
     */
    date_from: string;
    /**
     * 
     * @type {string}
     * @memberof CowSearchReqEvent
     */
    date_to: string;
    /**
     * nullの場合、すべての種別
     * @type {number}
     * @memberof CowSearchReqEvent
     */
    event_kind?: number;
    /**
     * event_kind = null のとき、除外するイベント種別
     * @type {number}
     * @memberof CowSearchReqEvent
     */
    excluded_event_kind?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CowSearchReqEvent
     */
    only_treat?: boolean;
}
/**
 * 
 * @export
 * @interface CowSearchReqOutput
 */
export interface CowSearchReqOutput {
    /**
     * 
     * @type {boolean}
     * @memberof CowSearchReqOutput
     */
    ancestors?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CowSearchReqOutput
     */
    balance?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CowSearchReqOutput
     */
    balance_from?: string;
    /**
     * 
     * @type {string}
     * @memberof CowSearchReqOutput
     */
    balance_to?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CowSearchReqOutput
     */
    breed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CowSearchReqOutput
     */
    breedings?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CowSearchReqOutput
     */
    cross_count?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CowSearchReqOutput
     */
    cross_day?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CowSearchReqOutput
     */
    cross_kind?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CowSearchReqOutput
     */
    crosses?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CowSearchReqOutput
     */
    days_after_cross?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CowSearchReqOutput
     */
    days_after_delivery?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CowSearchReqOutput
     */
    days_after_rut?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CowSearchReqOutput
     */
    deliveries?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CowSearchReqOutput
     */
    delivery_count?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CowSearchReqOutput
     */
    delivery_day?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CowSearchReqOutput
     */
    delivery_schedule?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CowSearchReqOutput
     */
    location_days?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CowSearchReqOutput
     */
    note?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CowSearchReqOutput
     */
    prevention_days?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CowSearchReqOutput
     */
    ruts?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CowSearchReqOutput
     */
    schedule_days?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CowSearchReqOutput
     */
    sell_month?: boolean;
    /**
     * 診療記録（体調・治療の両方を含む）の取得日数
     * @type {number}
     * @memberof CowSearchReqOutput
     */
    symptom_days?: number;
    /**
     * 診療記録（体調のみ）の取得日数
     * @type {number}
     * @memberof CowSearchReqOutput
     */
    symptom_physical_days?: number;
    /**
     * 診療記録（治療のみ）の取得日数
     * @type {number}
     * @memberof CowSearchReqOutput
     */
    symptom_treatment_days?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CowSearchReqOutput
     */
    tags?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CowSearchReqOutput
     */
    use?: boolean;
}
/**
 * 
 * @export
 * @interface CowSearchReqSchedule
 */
export interface CowSearchReqSchedule {
    /**
     * 
     * @type {string}
     * @memberof CowSearchReqSchedule
     */
    date_from: string;
    /**
     * 
     * @type {string}
     * @memberof CowSearchReqSchedule
     */
    date_to: string;
    /**
     * 
     * @type {number}
     * @memberof CowSearchReqSchedule
     */
    event_kind?: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchReqSchedule
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface CowSearchRutDto
 */
export interface CowSearchRutDto {
    /**
     * 
     * @type {Array<CowSearchTreatDto>}
     * @memberof CowSearchRutDto
     */
    details: Array<CowSearchTreatDto>;
    /**
     * 
     * @type {number}
     * @memberof CowSearchRutDto
     */
    rut_id: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchRutDto
     */
    signs: number;
    /**
     * 
     * @type {string}
     * @memberof CowSearchRutDto
     */
    watched_at: string;
    /**
     * 
     * @type {number}
     * @memberof CowSearchRutDto
     */
    watched_type: number;
}
/**
 * 
 * @export
 * @interface CowSearchScheduleDto
 */
export interface CowSearchScheduleDto {
    /**
     * 
     * @type {string}
     * @memberof CowSearchScheduleDto
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof CowSearchScheduleDto
     */
    end_at: string;
    /**
     * 
     * @type {number}
     * @memberof CowSearchScheduleDto
     */
    event_kind_no: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchScheduleDto
     */
    has_time: number;
    /**
     * 
     * @type {string}
     * @memberof CowSearchScheduleDto
     */
    note: string;
    /**
     * 
     * @type {number}
     * @memberof CowSearchScheduleDto
     */
    schedule_id: number;
    /**
     * 
     * @type {string}
     * @memberof CowSearchScheduleDto
     */
    start_at: string;
    /**
     * 
     * @type {number}
     * @memberof CowSearchScheduleDto
     */
    status: number;
    /**
     * 
     * @type {string}
     * @memberof CowSearchScheduleDto
     */
    title: string;
}
/**
 * 
 * @export
 * @interface CowSearchSymptomDto
 */
export interface CowSearchSymptomDto {
    /**
     * 
     * @type {number}
     * @memberof CowSearchSymptomDto
     */
    active_score?: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchSymptomDto
     */
    breath_count?: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchSymptomDto
     */
    breath_score?: number;
    /**
     * 
     * @type {string}
     * @memberof CowSearchSymptomDto
     */
    feces_color?: string;
    /**
     * 
     * @type {number}
     * @memberof CowSearchSymptomDto
     */
    feces_state?: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchSymptomDto
     */
    heart_rate?: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchSymptomDto
     */
    hungry_score?: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchSymptomDto
     */
    symptom_id: number;
    /**
     * 
     * @type {string}
     * @memberof CowSearchSymptomDto
     */
    symptom_name: string;
    /**
     * 
     * @type {number}
     * @memberof CowSearchSymptomDto
     */
    temperature_x10?: number;
    /**
     * 
     * @type {Array<CowSearchTreatDto>}
     * @memberof CowSearchSymptomDto
     */
    treats: Array<CowSearchTreatDto>;
    /**
     * 
     * @type {string}
     * @memberof CowSearchSymptomDto
     */
    watched_at: string;
}
/**
 * 
 * @export
 * @interface CowSearchSymptomPhysicalDto
 */
export interface CowSearchSymptomPhysicalDto {
    /**
     * 
     * @type {number}
     * @memberof CowSearchSymptomPhysicalDto
     */
    active_score?: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchSymptomPhysicalDto
     */
    breath_count?: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchSymptomPhysicalDto
     */
    breath_score?: number;
    /**
     * 
     * @type {string}
     * @memberof CowSearchSymptomPhysicalDto
     */
    feces_color?: string;
    /**
     * 
     * @type {number}
     * @memberof CowSearchSymptomPhysicalDto
     */
    feces_state?: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchSymptomPhysicalDto
     */
    heart_rate?: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchSymptomPhysicalDto
     */
    hungry_score?: number;
    /**
     * 
     * @type {number}
     * @memberof CowSearchSymptomPhysicalDto
     */
    symptom_id: number;
    /**
     * 
     * @type {string}
     * @memberof CowSearchSymptomPhysicalDto
     */
    symptom_name: string;
    /**
     * 
     * @type {number}
     * @memberof CowSearchSymptomPhysicalDto
     */
    temperature_x10?: number;
    /**
     * 
     * @type {string}
     * @memberof CowSearchSymptomPhysicalDto
     */
    watched_at: string;
}
/**
 * 
 * @export
 * @interface CowSearchTreatDto
 */
export interface CowSearchTreatDto {
    /**
     * 
     * @type {number}
     * @memberof CowSearchTreatDto
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof CowSearchTreatDto
     */
    medicine_route_name?: string;
    /**
     * 
     * @type {number}
     * @memberof CowSearchTreatDto
     */
    no: number;
    /**
     * 
     * @type {string}
     * @memberof CowSearchTreatDto
     */
    treat_item_name: string;
    /**
     * 
     * @type {string}
     * @memberof CowSearchTreatDto
     */
    treat_item_unit?: string;
    /**
     * 
     * @type {number}
     * @memberof CowSearchTreatDto
     */
    treat_kind_no?: number;
}
/**
 * 
 * @export
 * @interface CowTagDto
 */
export interface CowTagDto {
    /**
     * 
     * @type {number}
     * @memberof CowTagDto
     */
    cow_id: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CowTagDto
     */
    tag_ids: Array<number>;
}
/**
 * 
 * @export
 * @interface CowTagModifyReq
 */
export interface CowTagModifyReq {
    /**
     * 
     * @type {Array<CowTagModifyReqCow>}
     * @memberof CowTagModifyReq
     */
    cows: Array<CowTagModifyReqCow>;
    /**
     * 
     * @type {number}
     * @memberof CowTagModifyReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface CowTagModifyReqCow
 */
export interface CowTagModifyReqCow {
    /**
     * 
     * @type {number}
     * @memberof CowTagModifyReqCow
     */
    cow_id: number;
    /**
     * 
     * @type {Array<TagIdAndNameReq>}
     * @memberof CowTagModifyReqCow
     */
    tags: Array<TagIdAndNameReq>;
}
/**
 * 
 * @export
 * @interface CowUseDao
 */
export interface CowUseDao {
    /**
     * 
     * @type {number}
     * @memberof CowUseDao
     */
    disp_no?: number;
    /**
     * 
     * @type {number}
     * @memberof CowUseDao
     */
    is_deleted?: number;
    /**
     * 
     * @type {string}
     * @memberof CowUseDao
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof CowUseDao
     */
    sell_type?: number;
    /**
     * 
     * @type {number}
     * @memberof CowUseDao
     */
    use_no?: number;
}
/**
 * 
 * @export
 * @interface CowWatchingModifyReq
 */
export interface CowWatchingModifyReq {
    /**
     * 
     * @type {number}
     * @memberof CowWatchingModifyReq
     */
    cow_id: number;
    /**
     * 
     * @type {number}
     * @memberof CowWatchingModifyReq
     */
    ranch_id: number;
    /**
     * 
     * @type {number}
     * @memberof CowWatchingModifyReq
     */
    watching: number;
    /**
     * 
     * @type {string}
     * @memberof CowWatchingModifyReq
     */
    watching_memo: string;
}
/**
 * 
 * @export
 * @interface CrossSeedDto
 */
export interface CrossSeedDto {
    /**
     * 
     * @type {string}
     * @memberof CrossSeedDto
     */
    ancestor_1?: string;
    /**
     * 
     * @type {string}
     * @memberof CrossSeedDto
     */
    ancestor_2?: string;
    /**
     * 
     * @type {number}
     * @memberof CrossSeedDto
     */
    ancestor_2_exid?: number;
    /**
     * 
     * @type {string}
     * @memberof CrossSeedDto
     */
    ancestor_3?: string;
    /**
     * 
     * @type {number}
     * @memberof CrossSeedDto
     */
    ancestor_3_exid?: number;
    /**
     * 
     * @type {string}
     * @memberof CrossSeedDto
     */
    ancestor_4?: string;
    /**
     * 
     * @type {number}
     * @memberof CrossSeedDto
     */
    ancestor_4_exid?: number;
    /**
     * 
     * @type {number}
     * @memberof CrossSeedDto
     */
    father_exid?: number;
    /**
     * 
     * @type {string}
     * @memberof CrossSeedDto
     */
    get_from?: string;
    /**
     * 
     * @type {CrossSeedMotherDto}
     * @memberof CrossSeedDto
     */
    mother?: CrossSeedMotherDto;
    /**
     * 受精卵では母牛名、精液では名号
     * @type {string}
     * @memberof CrossSeedDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof CrossSeedDto
     */
    seed_id: number;
    /**
     * 
     * @type {number}
     * @memberof CrossSeedDto
     */
    seed_type: number;
    /**
     * 
     * @type {string}
     * @memberof CrossSeedDto
     */
    sname?: string;
    /**
     * 
     * @type {number}
     * @memberof CrossSeedDto
     */
    team_id: number;
}
/**
 * 
 * @export
 * @interface CrossSeedMotherDto
 */
export interface CrossSeedMotherDto {
    /**
     * 
     * @type {string}
     * @memberof CrossSeedMotherDto
     */
    ancestor_1?: string;
    /**
     * 
     * @type {number}
     * @memberof CrossSeedMotherDto
     */
    ancestor_1_exid?: number;
    /**
     * 
     * @type {string}
     * @memberof CrossSeedMotherDto
     */
    ancestor_2?: string;
    /**
     * 
     * @type {number}
     * @memberof CrossSeedMotherDto
     */
    ancestor_2_exid?: number;
    /**
     * 
     * @type {string}
     * @memberof CrossSeedMotherDto
     */
    ancestor_3?: string;
    /**
     * 
     * @type {number}
     * @memberof CrossSeedMotherDto
     */
    ancestor_3_exid?: number;
    /**
     * 
     * @type {number}
     * @memberof CrossSeedMotherDto
     */
    cow_id: number;
    /**
     * 
     * @type {string}
     * @memberof CrossSeedMotherDto
     */
    local_no?: string;
    /**
     * 
     * @type {string}
     * @memberof CrossSeedMotherDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CrossSeedMotherDto
     */
    trace_id: string;
}
/**
 * 
 * @export
 * @interface DeliveryAggregateTransitionDto
 */
export interface DeliveryAggregateTransitionDto {
    /**
     * 
     * @type {Array<DeliveryAggregateTransitionMonthDto>}
     * @memberof DeliveryAggregateTransitionDto
     */
    months: Array<DeliveryAggregateTransitionMonthDto>;
}
/**
 * 
 * @export
 * @interface DeliveryAggregateTransitionMonthDto
 */
export interface DeliveryAggregateTransitionMonthDto {
    /**
     * 
     * @type {number}
     * @memberof DeliveryAggregateTransitionMonthDto
     */
    actual: number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryAggregateTransitionMonthDto
     */
    count_for_interval_days: number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryAggregateTransitionMonthDto
     */
    interval_days?: number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAggregateTransitionMonthDto
     */
    lastday: string;
    /**
     * 
     * @type {number}
     * @memberof DeliveryAggregateTransitionMonthDto
     */
    plan: number;
}
/**
 * 
 * @export
 * @interface DeliveryCowDto
 */
export interface DeliveryCowDto {
    /**
     * 
     * @type {number}
     * @memberof DeliveryCowDto
     */
    child_no: number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryCowDto
     */
    cow_id?: number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryCowDto
     */
    is_male: number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryCowDto
     */
    life_status: number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryCowDto
     */
    weight_kg?: number;
}
/**
 * 
 * @export
 * @interface DeliveryDeleteReq
 */
export interface DeliveryDeleteReq {
    /**
     * delivery_id
     * @type {number}
     * @memberof DeliveryDeleteReq
     */
    delivery_id: number;
    /**
     * ranch_id
     * @type {number}
     * @memberof DeliveryDeleteReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface DeliveryDto
 */
export interface DeliveryDto {
    /**
     * 
     * @type {Array<DeliveryCowDto>}
     * @memberof DeliveryDto
     */
    children: Array<DeliveryCowDto>;
    /**
     * 
     * @type {string}
     * @memberof DeliveryDto
     */
    comment: string;
    /**
     * 
     * @type {number}
     * @memberof DeliveryDto
     */
    cow_id: number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryDto
     */
    cross_no?: number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryDto
     */
    delivered_at: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryDto
     */
    delivered_by: string;
    /**
     * 
     * @type {number}
     * @memberof DeliveryDto
     */
    delivery_id: number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryDto
     */
    difficulty: number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryDto
     */
    is_aborted: number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryDto
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface DeliveryHistoryListDto
 */
export interface DeliveryHistoryListDto {
    /**
     * list
     * @type {Array<ComOnpVetellMybatisDeliveryHistoryDao>}
     * @memberof DeliveryHistoryListDto
     */
    list?: Array<ComOnpVetellMybatisDeliveryHistoryDao>;
}
/**
 * 
 * @export
 * @interface DeliveryHistoryReq
 */
export interface DeliveryHistoryReq {
    /**
     * 
     * @type {number}
     * @memberof DeliveryHistoryReq
     */
    cow_id: number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryHistoryReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface DeliveryModifyReq
 */
export interface DeliveryModifyReq {
    /**
     * children
     * @type {Array<DeliveryModifyReqChild>}
     * @memberof DeliveryModifyReq
     */
    children?: Array<DeliveryModifyReqChild>;
    /**
     * comment
     * @type {string}
     * @memberof DeliveryModifyReq
     */
    comment: string;
    /**
     * cow_id
     * @type {number}
     * @memberof DeliveryModifyReq
     */
    cow_id: number;
    /**
     * cross_no
     * @type {number}
     * @memberof DeliveryModifyReq
     */
    cross_no?: number;
    /**
     * delivered_at
     * @type {string}
     * @memberof DeliveryModifyReq
     */
    delivered_at: string;
    /**
     * delivery_id
     * @type {number}
     * @memberof DeliveryModifyReq
     */
    delivery_id?: number;
    /**
     * difficulty
     * @type {number}
     * @memberof DeliveryModifyReq
     */
    difficulty: number;
    /**
     * is_aborted
     * @type {number}
     * @memberof DeliveryModifyReq
     */
    is_aborted: number;
    /**
     * is_new
     * @type {number}
     * @memberof DeliveryModifyReq
     */
    is_new: number;
    /**
     * 
     * @type {PreloadedProgramReq}
     * @memberof DeliveryModifyReq
     */
    preloaded_program: PreloadedProgramReq;
    /**
     * ranch_id
     * @type {number}
     * @memberof DeliveryModifyReq
     */
    ranch_id: number;
    /**
     * schedule_id
     * @type {number}
     * @memberof DeliveryModifyReq
     */
    schedule_id?: number;
}
/**
 * 
 * @export
 * @interface DeliveryModifyReqChild
 */
export interface DeliveryModifyReqChild {
    /**
     * child_no
     * @type {number}
     * @memberof DeliveryModifyReqChild
     */
    child_no?: number;
    /**
     * cow_id
     * @type {number}
     * @memberof DeliveryModifyReqChild
     */
    cow_id?: number;
    /**
     * is_male
     * @type {number}
     * @memberof DeliveryModifyReqChild
     */
    is_male: number;
    /**
     * life_status
     * @type {number}
     * @memberof DeliveryModifyReqChild
     */
    life_status: number;
    /**
     * weight_kg
     * @type {number}
     * @memberof DeliveryModifyReqChild
     */
    weight_kg?: number;
}
/**
 * 
 * @export
 * @interface DiseaseCauseDto
 */
export interface DiseaseCauseDto {
    /**
     * 
     * @type {string}
     * @memberof DiseaseCauseDto
     */
    cause_name: string;
    /**
     * 
     * @type {number}
     * @memberof DiseaseCauseDto
     */
    cause_no: number;
}
/**
 * 
 * @export
 * @interface DiseaseListDto
 */
export interface DiseaseListDto {
    /**
     * 
     * @type {number}
     * @memberof DiseaseListDto
     */
    cow_id: number;
    /**
     * 
     * @type {Array<DiseaseListEventDto>}
     * @memberof DiseaseListDto
     */
    events: Array<DiseaseListEventDto>;
    /**
     * 
     * @type {string}
     * @memberof DiseaseListDto
     */
    local_no?: string;
    /**
     * 
     * @type {string}
     * @memberof DiseaseListDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DiseaseListDto
     */
    trace_id: string;
}
/**
 * 
 * @export
 * @interface DiseaseListEventDto
 */
export interface DiseaseListEventDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof DiseaseListEventDto
     */
    diseases: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof DiseaseListEventDto
     */
    nth_in_series: number;
    /**
     * 
     * @type {string}
     * @memberof DiseaseListEventDto
     */
    watched_at: string;
    /**
     * 
     * @type {string}
     * @memberof DiseaseListEventDto
     */
    watched_by: string;
    /**
     * 
     * @type {string}
     * @memberof DiseaseListEventDto
     */
    watched_by_name?: string;
}
/**
 * 
 * @export
 * @interface DiseaseListReq
 */
export interface DiseaseListReq {
    /**
     * 検診記録を取得する（default: true）
     * @type {boolean}
     * @memberof DiseaseListReq
     */
    breeding?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DiseaseListReq
     */
    clinic_id: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof DiseaseListReq
     */
    cow_ids: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof DiseaseListReq
     */
    from: string;
    /**
     * 初診から何回目の診療／検診を取得するか
     * @type {number}
     * @memberof DiseaseListReq
     */
    nth_in_series?: number;
    /**
     * 
     * @type {number}
     * @memberof DiseaseListReq
     */
    ranch_id: number;
    /**
     * 診療記録を取得する（default: true）
     * @type {boolean}
     * @memberof DiseaseListReq
     */
    symptom?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DiseaseListReq
     */
    to: string;
}
/**
 * 
 * @export
 * @interface DynamicsAgeDto
 */
export interface DynamicsAgeDto {
    /**
     * 
     * @type {number}
     * @memberof DynamicsAgeDto
     */
    age?: number;
    /**
     * 
     * @type {Array<DynamicsAgeScoreDto>}
     * @memberof DynamicsAgeDto
     */
    score: Array<DynamicsAgeScoreDto>;
}
/**
 * 
 * @export
 * @interface DynamicsAgeReq
 */
export interface DynamicsAgeReq {
    /**
     * 対象の年月(yyyy-MM-dd) 日の指定は無視される
     * @type {string}
     * @memberof DynamicsAgeReq
     */
    month: string;
    /**
     * 牧場ID
     * @type {number}
     * @memberof DynamicsAgeReq
     */
    ranch_id: number;
    /**
     * 分場ID 未指定の場合は全分場
     * @type {number}
     * @memberof DynamicsAgeReq
     */
    site?: number;
}
/**
 * 
 * @export
 * @interface DynamicsAgeScoreDto
 */
export interface DynamicsAgeScoreDto {
    /**
     * 
     * @type {number}
     * @memberof DynamicsAgeScoreDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof DynamicsAgeScoreDto
     */
    use_no?: number;
}
/**
 * 
 * @export
 * @interface DynamicsAggregateDto
 */
export interface DynamicsAggregateDto {
    /**
     * 
     * @type {Array<DynamicsAggregateMonthDto>}
     * @memberof DynamicsAggregateDto
     */
    months: Array<DynamicsAggregateMonthDto>;
    /**
     * 
     * @type {DynamicsAggregateTotalDto}
     * @memberof DynamicsAggregateDto
     */
    total: DynamicsAggregateTotalDto;
    /**
     * 
     * @type {DynamicsAggregateTotalDto}
     * @memberof DynamicsAggregateDto
     */
    total_future_included: DynamicsAggregateTotalDto;
}
/**
 * 
 * @export
 * @interface DynamicsAggregateMonthDto
 */
export interface DynamicsAggregateMonthDto {
    /**
     * 
     * @type {number}
     * @memberof DynamicsAggregateMonthDto
     */
    accepted?: number;
    /**
     * 
     * @type {number}
     * @memberof DynamicsAggregateMonthDto
     */
    born?: number;
    /**
     * 
     * @type {number}
     * @memberof DynamicsAggregateMonthDto
     */
    cow_count?: number;
    /**
     * 
     * @type {number}
     * @memberof DynamicsAggregateMonthDto
     */
    cow_count_diff?: number;
    /**
     * 
     * @type {number}
     * @memberof DynamicsAggregateMonthDto
     */
    dead?: number;
    /**
     * 
     * @type {number}
     * @memberof DynamicsAggregateMonthDto
     */
    dead_expect?: number;
    /**
     * 
     * @type {number}
     * @memberof DynamicsAggregateMonthDto
     */
    dead_percent?: number;
    /**
     * 
     * @type {number}
     * @memberof DynamicsAggregateMonthDto
     */
    dead_percent_expect?: number;
    /**
     * 
     * @type {string}
     * @memberof DynamicsAggregateMonthDto
     */
    lastday: string;
    /**
     * 
     * @type {number}
     * @memberof DynamicsAggregateMonthDto
     */
    left?: number;
    /**
     * past/current/future
     * @type {string}
     * @memberof DynamicsAggregateMonthDto
     */
    month_kind: string;
    /**
     * 
     * @type {number}
     * @memberof DynamicsAggregateMonthDto
     */
    sold?: number;
}
/**
 * 
 * @export
 * @interface DynamicsAggregateReq
 */
export interface DynamicsAggregateReq {
    /**
     * 期間開始月 yyyy-MM-dd 日付は無視する
     * @type {string}
     * @memberof DynamicsAggregateReq
     */
    from: string;
    /**
     * 
     * @type {number}
     * @memberof DynamicsAggregateReq
     */
    ranch_id: number;
    /**
     * 
     * @type {number}
     * @memberof DynamicsAggregateReq
     */
    site?: number;
    /**
     * 期間開始月 yyyy-MM-dd 日付は無視する
     * @type {string}
     * @memberof DynamicsAggregateReq
     */
    to: string;
    /**
     * 
     * @type {number}
     * @memberof DynamicsAggregateReq
     */
    use_no?: number;
}
/**
 * 
 * @export
 * @interface DynamicsAggregateTotalDto
 */
export interface DynamicsAggregateTotalDto {
    /**
     * 
     * @type {number}
     * @memberof DynamicsAggregateTotalDto
     */
    accepted?: number;
    /**
     * 
     * @type {number}
     * @memberof DynamicsAggregateTotalDto
     */
    born?: number;
    /**
     * 
     * @type {number}
     * @memberof DynamicsAggregateTotalDto
     */
    cow_count?: number;
    /**
     * 
     * @type {number}
     * @memberof DynamicsAggregateTotalDto
     */
    cow_count_diff?: number;
    /**
     * 
     * @type {number}
     * @memberof DynamicsAggregateTotalDto
     */
    dead?: number;
    /**
     * 
     * @type {number}
     * @memberof DynamicsAggregateTotalDto
     */
    dead_expect?: number;
    /**
     * 
     * @type {number}
     * @memberof DynamicsAggregateTotalDto
     */
    dead_percent?: number;
    /**
     * 
     * @type {number}
     * @memberof DynamicsAggregateTotalDto
     */
    dead_percent_expect?: number;
    /**
     * 
     * @type {number}
     * @memberof DynamicsAggregateTotalDto
     */
    left?: number;
    /**
     * 
     * @type {number}
     * @memberof DynamicsAggregateTotalDto
     */
    sold?: number;
}
/**
 * 
 * @export
 * @interface DynamicsExpectDto
 */
export interface DynamicsExpectDto {
    /**
     * 
     * @type {number}
     * @memberof DynamicsExpectDto
     */
    item_kind: number;
    /**
     * 
     * @type {number}
     * @memberof DynamicsExpectDto
     */
    use_no: number;
    /**
     * 
     * @type {Array<DynamicsExpectValueDto>}
     * @memberof DynamicsExpectDto
     */
    values: Array<DynamicsExpectValueDto>;
}
/**
 * 
 * @export
 * @interface DynamicsExpectListReq
 */
export interface DynamicsExpectListReq {
    /**
     * 取得期間開始月 yyyy-MM-dd （日付は無視）
     * @type {string}
     * @memberof DynamicsExpectListReq
     */
    from: string;
    /**
     * 
     * @type {number}
     * @memberof DynamicsExpectListReq
     */
    item_kind?: number;
    /**
     * 
     * @type {number}
     * @memberof DynamicsExpectListReq
     */
    ranch_id: number;
    /**
     * 取得期間終了月 yyyy-MM-dd （日付は無視）
     * @type {string}
     * @memberof DynamicsExpectListReq
     */
    to: string;
    /**
     * 
     * @type {number}
     * @memberof DynamicsExpectListReq
     */
    use_no?: number;
}
/**
 * 
 * @export
 * @interface DynamicsExpectModifyReq
 */
export interface DynamicsExpectModifyReq {
    /**
     * 
     * @type {Array<DynamicsExpectModifyReqItem>}
     * @memberof DynamicsExpectModifyReq
     */
    items: Array<DynamicsExpectModifyReqItem>;
    /**
     * 
     * @type {number}
     * @memberof DynamicsExpectModifyReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface DynamicsExpectModifyReqItem
 */
export interface DynamicsExpectModifyReqItem {
    /**
     * 
     * @type {number}
     * @memberof DynamicsExpectModifyReqItem
     */
    item_kind: number;
    /**
     * 
     * @type {number}
     * @memberof DynamicsExpectModifyReqItem
     */
    use_no: number;
    /**
     * 
     * @type {Array<DynamicsExpectModifyReqValue>}
     * @memberof DynamicsExpectModifyReqItem
     */
    values: Array<DynamicsExpectModifyReqValue>;
}
/**
 * 
 * @export
 * @interface DynamicsExpectModifyReqValue
 */
export interface DynamicsExpectModifyReqValue {
    /**
     * 
     * @type {number}
     * @memberof DynamicsExpectModifyReqValue
     */
    month_yyyymm: number;
    /**
     * 
     * @type {number}
     * @memberof DynamicsExpectModifyReqValue
     */
    value: number;
}
/**
 * 
 * @export
 * @interface DynamicsExpectValueDto
 */
export interface DynamicsExpectValueDto {
    /**
     * 
     * @type {number}
     * @memberof DynamicsExpectValueDto
     */
    expected: number;
    /**
     * 
     * @type {number}
     * @memberof DynamicsExpectValueDto
     */
    month_yyyymm: number;
}
/**
 * 
 * @export
 * @interface EggStockReq
 */
export interface EggStockReq {
    /**
     * 
     * @type {number}
     * @memberof EggStockReq
     */
    event_id: number;
    /**
     * 
     * @type {string}
     * @memberof EggStockReq
     */
    lot_name: string;
    /**
     * 
     * @type {Array<EggStockReqLot>}
     * @memberof EggStockReq
     */
    lots: Array<EggStockReqLot>;
    /**
     * 
     * @type {number}
     * @memberof EggStockReq
     */
    ranch_id: number;
    /**
     * 
     * @type {number}
     * @memberof EggStockReq
     */
    seed_id: number;
}
/**
 * 
 * @export
 * @interface EggStockReqLot
 */
export interface EggStockReqLot {
    /**
     * 
     * @type {string}
     * @memberof EggStockReqLot
     */
    comment: string;
    /**
     * 
     * @type {number}
     * @memberof EggStockReqLot
     */
    egg_rank: number;
    /**
     * 
     * @type {number}
     * @memberof EggStockReqLot
     */
    egg_stage: number;
    /**
     * 
     * @type {number}
     * @memberof EggStockReqLot
     */
    is_frozen: number;
    /**
     * 
     * @type {string}
     * @memberof EggStockReqLot
     */
    label_from: string;
    /**
     * 
     * @type {string}
     * @memberof EggStockReqLot
     */
    label_to: string;
    /**
     * 
     * @type {Array<TeamSeedTagModifyReqStock>}
     * @memberof EggStockReqLot
     */
    tags?: Array<TeamSeedTagModifyReqStock>;
    /**
     * 
     * @type {number}
     * @memberof EggStockReqLot
     */
    unit_price: number;
}
/**
 * 
 * @export
 * @interface EventInfoDto
 */
export interface EventInfoDto {
    /**
     * breedings
     * @type {Array<ComOnpVetellMybatisBreedingEventBaseDao>}
     * @memberof EventInfoDto
     */
    breedings: Array<ComOnpVetellMybatisBreedingEventBaseDao>;
    /**
     * feed
     * @type {Array<ComOnpVetellMybatisFeedDataDao>}
     * @memberof EventInfoDto
     */
    feed: Array<ComOnpVetellMybatisFeedDataDao>;
    /**
     * symptom
     * @type {Array<IEventDto>}
     * @memberof EventInfoDto
     */
    symptom: Array<IEventDto>;
}
/**
 * 
 * @export
 * @interface EventInfoReq
 */
export interface EventInfoReq {
    /**
     * cow_ids
     * @type {Array<number>}
     * @memberof EventInfoReq
     */
    cow_ids: Array<number>;
    /**
     * day
     * @type {string}
     * @memberof EventInfoReq
     */
    day: string;
    /**
     * ranch_id
     * @type {number}
     * @memberof EventInfoReq
     */
    ranch_id: number;
    /**
     * user_id
     * @type {string}
     * @memberof EventInfoReq
     */
    user_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventInfoReq
     */
    valid?: boolean;
}
/**
 * 
 * @export
 * @interface EventNextScheduleReq
 */
export interface EventNextScheduleReq {
    /**
     * 
     * @type {number}
     * @memberof EventNextScheduleReq
     */
    clinic_id?: number;
    /**
     * 
     * @type {string}
     * @memberof EventNextScheduleReq
     */
    end_at: string;
    /**
     * 
     * @type {number}
     * @memberof EventNextScheduleReq
     */
    event_kind_no: number;
    /**
     * 
     * @type {number}
     * @memberof EventNextScheduleReq
     */
    has_time: number;
    /**
     * 
     * @type {string}
     * @memberof EventNextScheduleReq
     */
    note: string;
    /**
     * 
     * @type {number}
     * @memberof EventNextScheduleReq
     */
    preset_id?: number;
    /**
     * 
     * @type {string}
     * @memberof EventNextScheduleReq
     */
    start_at: string;
    /**
     * 
     * @type {string}
     * @memberof EventNextScheduleReq
     */
    title: string;
}
/**
 * 
 * @export
 * @interface ExtraCowDto
 */
export interface ExtraCowDto {
    /**
     * 
     * @type {number}
     * @memberof ExtraCowDto
     */
    ex_cow_id: number;
    /**
     * 
     * @type {string}
     * @memberof ExtraCowDto
     */
    name: string;
}
/**
 * 
 * @export
 * @interface ExtraCowModifyReq
 */
export interface ExtraCowModifyReq {
    /**
     * 
     * @type {number}
     * @memberof ExtraCowModifyReq
     */
    ex_cow_id?: number;
    /**
     * 
     * @type {string}
     * @memberof ExtraCowModifyReq
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ExtraCowModifyReq
     */
    team_id: number;
}
/**
 * 
 * @export
 * @interface FeedAmountHistDto
 */
export interface FeedAmountHistDto {
    /**
     * list
     * @type {Array<FeedDayAmountDao>}
     * @memberof FeedAmountHistDto
     */
    list?: Array<FeedDayAmountDao>;
}
/**
 * 
 * @export
 * @interface FeedAmoutHistReq
 */
export interface FeedAmoutHistReq {
    /**
     * amount_days
     * @type {number}
     * @memberof FeedAmoutHistReq
     */
    amount_days?: number;
    /**
     * cow_id
     * @type {number}
     * @memberof FeedAmoutHistReq
     */
    cow_id: number;
    /**
     * end_day
     * @type {string}
     * @memberof FeedAmoutHistReq
     */
    end_day: string;
    /**
     * 種別単位でなく、品目単位で集計するときは 1 を指定
     * @type {number}
     * @memberof FeedAmoutHistReq
     */
    is_per_item?: number;
}
/**
 * 
 * @export
 * @interface FeedBulkAddReq
 */
export interface FeedBulkAddReq {
    /**
     * 
     * @type {Array<FeedBulkAddReqCow>}
     * @memberof FeedBulkAddReq
     */
    cows: Array<FeedBulkAddReqCow>;
    /**
     * 
     * @type {string}
     * @memberof FeedBulkAddReq
     */
    fed_at: string;
    /**
     * 
     * @type {number}
     * @memberof FeedBulkAddReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface FeedBulkAddReqCow
 */
export interface FeedBulkAddReqCow {
    /**
     * 
     * @type {string}
     * @memberof FeedBulkAddReqCow
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof FeedBulkAddReqCow
     */
    cow_id: number;
    /**
     * 
     * @type {Array<FeedModifyReqAmount>}
     * @memberof FeedBulkAddReqCow
     */
    feed_amount: Array<FeedModifyReqAmount>;
}
/**
 * 
 * @export
 * @interface FeedDayAmountDao
 */
export interface FeedDayAmountDao {
    /**
     * 
     * @type {string}
     * @memberof FeedDayAmountDao
     */
    day: string;
    /**
     * 
     * @type {number}
     * @memberof FeedDayAmountDao
     */
    delivered: number;
    /**
     * 
     * @type {number}
     * @memberof FeedDayAmountDao
     */
    feed_type_no: number;
    /**
     * 
     * @type {number}
     * @memberof FeedDayAmountDao
     */
    leftover: number;
    /**
     * 
     * @type {string}
     * @memberof FeedDayAmountDao
     */
    name: string;
    /**
     * feed_type_no または feed_no
     * @type {number}
     * @memberof FeedDayAmountDao
     */
    no: number;
    /**
     * 
     * @type {string}
     * @memberof FeedDayAmountDao
     */
    unit: string;
}
/**
 * 
 * @export
 * @interface FeedDto
 */
export interface FeedDto {
    /**
     * 
     * @type {ComOnpVetellMybatisFeedDataDao}
     * @memberof FeedDto
     */
    info?: ComOnpVetellMybatisFeedDataDao;
}
/**
 * 
 * @export
 * @interface FeedLastDayAmountDto
 */
export interface FeedLastDayAmountDto {
    /**
     * delivered
     * @type {number}
     * @memberof FeedLastDayAmountDto
     */
    delivered: number;
    /**
     * delivered_raw
     * @type {number}
     * @memberof FeedLastDayAmountDto
     */
    delivered_raw: number;
    /**
     * feed_no
     * @type {number}
     * @memberof FeedLastDayAmountDto
     */
    feed_no: number;
    /**
     * feed_type_no
     * @type {number}
     * @memberof FeedLastDayAmountDto
     */
    feed_type_no: number;
    /**
     * leftover
     * @type {number}
     * @memberof FeedLastDayAmountDto
     */
    leftover?: number;
    /**
     * leftover_raw
     * @type {number}
     * @memberof FeedLastDayAmountDto
     */
    leftover_raw?: number;
}
/**
 * 
 * @export
 * @interface FeedLastDayDto
 */
export interface FeedLastDayDto {
    /**
     * amounts
     * @type {Array<FeedLastDayAmountDto>}
     * @memberof FeedLastDayDto
     */
    amounts: Array<FeedLastDayAmountDto>;
    /**
     * fed_at
     * @type {string}
     * @memberof FeedLastDayDto
     */
    fed_at: string;
    /**
     * feeding_id
     * @type {number}
     * @memberof FeedLastDayDto
     */
    feeding_id: number;
}
/**
 * 
 * @export
 * @interface FeedLastDayReq
 */
export interface FeedLastDayReq {
    /**
     * 
     * @type {number}
     * @memberof FeedLastDayReq
     */
    cow_id: number;
    /**
     * 
     * @type {string}
     * @memberof FeedLastDayReq
     */
    day: string;
    /**
     * 
     * @type {number}
     * @memberof FeedLastDayReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface FeedLeftListDto
 */
export interface FeedLeftListDto {
    /**
     * list
     * @type {Array<ComOnpVetellMybatisFeedAmount2Dao>}
     * @memberof FeedLeftListDto
     */
    list?: Array<ComOnpVetellMybatisFeedAmount2Dao>;
}
/**
 * 
 * @export
 * @interface FeedLeftListReq
 */
export interface FeedLeftListReq {
    /**
     * cow_id
     * @type {string}
     * @memberof FeedLeftListReq
     */
    cow_id: string;
    /**
     * from_days_before
     * @type {number}
     * @memberof FeedLeftListReq
     */
    from_days_before: number;
    /**
     * ranch_id
     * @type {number}
     * @memberof FeedLeftListReq
     */
    ranch_id: number;
    /**
     * to_days_before
     * @type {number}
     * @memberof FeedLeftListReq
     */
    to_days_before: number;
    /**
     * user_id
     * @type {string}
     * @memberof FeedLeftListReq
     */
    user_id: string;
}
/**
 * 
 * @export
 * @interface FeedLeftModifyReq
 */
export interface FeedLeftModifyReq {
    /**
     * 
     * @type {string}
     * @memberof FeedLeftModifyReq
     */
    day: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof FeedLeftModifyReq
     */
    feeding_ids: Array<number>;
    /**
     * 
     * @type {Array<FeedLeftModifyReqFeed>}
     * @memberof FeedLeftModifyReq
     */
    feeds: Array<FeedLeftModifyReqFeed>;
    /**
     * 
     * @type {number}
     * @memberof FeedLeftModifyReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface FeedLeftModifyReqFeed
 */
export interface FeedLeftModifyReqFeed {
    /**
     * 
     * @type {number}
     * @memberof FeedLeftModifyReqFeed
     */
    feed_no: number;
    /**
     * 
     * @type {number}
     * @memberof FeedLeftModifyReqFeed
     */
    leftover: number;
    /**
     * 
     * @type {number}
     * @memberof FeedLeftModifyReqFeed
     */
    leftover_raw: number;
}
/**
 * 
 * @export
 * @interface FeedModifyMultiReq
 */
export interface FeedModifyMultiReq {
    /**
     * 
     * @type {number}
     * @memberof FeedModifyMultiReq
     */
    ranch_id: number;
    /**
     * 
     * @type {Array<FeedModifyMultiReqRecord>}
     * @memberof FeedModifyMultiReq
     */
    records: Array<FeedModifyMultiReqRecord>;
}
/**
 * 
 * @export
 * @interface FeedModifyMultiReqRecord
 */
export interface FeedModifyMultiReqRecord {
    /**
     * 
     * @type {string}
     * @memberof FeedModifyMultiReqRecord
     */
    comment: string;
    /**
     * 
     * @type {number}
     * @memberof FeedModifyMultiReqRecord
     */
    cow_id: number;
    /**
     * 
     * @type {string}
     * @memberof FeedModifyMultiReqRecord
     */
    fed_at: string;
    /**
     * 
     * @type {Array<FeedModifyReqAmount>}
     * @memberof FeedModifyMultiReqRecord
     */
    feed_amount: Array<FeedModifyReqAmount>;
}
/**
 * 
 * @export
 * @interface FeedModifyReq
 */
export interface FeedModifyReq {
    /**
     * 
     * @type {string}
     * @memberof FeedModifyReq
     */
    comment?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof FeedModifyReq
     */
    cow_ids: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof FeedModifyReq
     */
    fed_at: string;
    /**
     * 
     * @type {Array<FeedModifyReqAmount>}
     * @memberof FeedModifyReq
     */
    feed_amount: Array<FeedModifyReqAmount>;
    /**
     * 
     * @type {number}
     * @memberof FeedModifyReq
     */
    feeding_id?: number;
    /**
     * 
     * @type {number}
     * @memberof FeedModifyReq
     */
    is_deleted: number;
    /**
     * 
     * @type {number}
     * @memberof FeedModifyReq
     */
    is_new: number;
    /**
     * 
     * @type {number}
     * @memberof FeedModifyReq
     */
    ranch_id: number;
    /**
     * 
     * @type {number}
     * @memberof FeedModifyReq
     */
    schedule_id?: number;
}
/**
 * 
 * @export
 * @interface FeedModifyReqAmount
 */
export interface FeedModifyReqAmount {
    /**
     * delivered
     * @type {number}
     * @memberof FeedModifyReqAmount
     */
    delivered: number;
    /**
     * delivered_raw
     * @type {number}
     * @memberof FeedModifyReqAmount
     */
    delivered_raw: number;
    /**
     * feed_no
     * @type {number}
     * @memberof FeedModifyReqAmount
     */
    feed_no: number;
    /**
     * feed_type_no
     * @type {number}
     * @memberof FeedModifyReqAmount
     */
    feed_type_no: number;
    /**
     * leftover
     * @type {number}
     * @memberof FeedModifyReqAmount
     */
    leftover?: number;
    /**
     * leftover_raw
     * @type {number}
     * @memberof FeedModifyReqAmount
     */
    leftover_raw?: number;
    /**
     * unit_price
     * @type {number}
     * @memberof FeedModifyReqAmount
     */
    unit_price: number;
}
/**
 * 
 * @export
 * @interface FeedRecentEachDto
 */
export interface FeedRecentEachDto {
    /**
     * cow_id
     * @type {number}
     * @memberof FeedRecentEachDto
     */
    cow_id?: number;
    /**
     * feed_amount
     * @type {Array<ComOnpVetellMybatisFeedAmountDao>}
     * @memberof FeedRecentEachDto
     */
    feed_amount?: Array<ComOnpVetellMybatisFeedAmountDao>;
}
/**
 * 
 * @export
 * @interface FeedRecentEachReq
 */
export interface FeedRecentEachReq {
    /**
     * cow_id
     * @type {Array<number>}
     * @memberof FeedRecentEachReq
     */
    cow_id: Array<number>;
    /**
     * date
     * @type {string}
     * @memberof FeedRecentEachReq
     */
    date: string;
    /**
     * ranch_id
     * @type {number}
     * @memberof FeedRecentEachReq
     */
    ranch_id: number;
    /**
     * user_id
     * @type {string}
     * @memberof FeedRecentEachReq
     */
    user_id: string;
}
/**
 * 
 * @export
 * @interface FeedRecentReq
 */
export interface FeedRecentReq {
    /**
     * cow_id
     * @type {string}
     * @memberof FeedRecentReq
     */
    cow_id: string;
    /**
     * ranch_id
     * @type {number}
     * @memberof FeedRecentReq
     */
    ranch_id: number;
    /**
     * user_id
     * @type {string}
     * @memberof FeedRecentReq
     */
    user_id: string;
}
/**
 * 
 * @export
 * @interface FeedRecentTimeSpanAmountDto
 */
export interface FeedRecentTimeSpanAmountDto {
    /**
     * delivered
     * @type {number}
     * @memberof FeedRecentTimeSpanAmountDto
     */
    delivered: number;
    /**
     * delivered_raw
     * @type {number}
     * @memberof FeedRecentTimeSpanAmountDto
     */
    delivered_raw: number;
    /**
     * feed_no
     * @type {number}
     * @memberof FeedRecentTimeSpanAmountDto
     */
    feed_no: number;
    /**
     * feed_type_no
     * @type {number}
     * @memberof FeedRecentTimeSpanAmountDto
     */
    feed_type_no: number;
}
/**
 * 
 * @export
 * @interface FeedRecentTimeSpanDto
 */
export interface FeedRecentTimeSpanDto {
    /**
     * amounts
     * @type {Array<FeedRecentTimeSpanAmountDto>}
     * @memberof FeedRecentTimeSpanDto
     */
    amounts: Array<FeedRecentTimeSpanAmountDto>;
    /**
     * cow_id
     * @type {number}
     * @memberof FeedRecentTimeSpanDto
     */
    cow_id: number;
}
/**
 * 
 * @export
 * @interface FeedRecentTimeSpanReq
 */
export interface FeedRecentTimeSpanReq {
    /**
     * cow_ids
     * @type {Array<number>}
     * @memberof FeedRecentTimeSpanReq
     */
    cow_ids: Array<number>;
    /**
     * day_from
     * @type {string}
     * @memberof FeedRecentTimeSpanReq
     */
    day_from: string;
    /**
     * day_to 
     * @type {string}
     * @memberof FeedRecentTimeSpanReq
     */
    day_to: string;
    /**
     * ranch_id
     * @type {number}
     * @memberof FeedRecentTimeSpanReq
     */
    ranch_id: number;
    /**
     * time_from
     * @type {string}
     * @memberof FeedRecentTimeSpanReq
     */
    time_from: string;
    /**
     * time_to
     * @type {string}
     * @memberof FeedRecentTimeSpanReq
     */
    time_to: string;
    /**
     * 
     * @type {boolean}
     * @memberof FeedRecentTimeSpanReq
     */
    valid?: boolean;
}
/**
 * 
 * @export
 * @interface FeedRecordedDto
 */
export interface FeedRecordedDto {
    /**
     * cow_id
     * @type {Array<number>}
     * @memberof FeedRecordedDto
     */
    cow_id?: Array<number>;
}
/**
 * 
 * @export
 * @interface FeedRecordedReq
 */
export interface FeedRecordedReq {
    /**
     * cow_id
     * @type {Array<number>}
     * @memberof FeedRecordedReq
     */
    cow_id: Array<number>;
    /**
     * date
     * @type {string}
     * @memberof FeedRecordedReq
     */
    date: string;
    /**
     * ranch_id
     * @type {number}
     * @memberof FeedRecordedReq
     */
    ranch_id: number;
    /**
     * user_id
     * @type {string}
     * @memberof FeedRecordedReq
     */
    user_id: string;
}
/**
 * 
 * @export
 * @interface FileDownloadDto
 */
export interface FileDownloadDto {
    /**
     * 
     * @type {string}
     * @memberof FileDownloadDto
     */
    body: string;
    /**
     * 
     * @type {string}
     * @memberof FileDownloadDto
     */
    content_type: string;
    /**
     * 
     * @type {string}
     * @memberof FileDownloadDto
     */
    file_name: string;
}
/**
 * 
 * @export
 * @interface GenericResponseBalanceCowChildrenDto
 */
export interface GenericResponseBalanceCowChildrenDto {
    /**
     * 
     * @type {BalanceCowChildrenDto}
     * @memberof GenericResponseBalanceCowChildrenDto
     */
    data?: BalanceCowChildrenDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseBalanceCowChildrenDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseBalanceCowConditionDto
 */
export interface GenericResponseBalanceCowConditionDto {
    /**
     * 
     * @type {BalanceCowConditionDto}
     * @memberof GenericResponseBalanceCowConditionDto
     */
    data?: BalanceCowConditionDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseBalanceCowConditionDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseBalanceCowDeliDayDto
 */
export interface GenericResponseBalanceCowDeliDayDto {
    /**
     * 
     * @type {BalanceCowDeliDayDto}
     * @memberof GenericResponseBalanceCowDeliDayDto
     */
    data?: BalanceCowDeliDayDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseBalanceCowDeliDayDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseBalanceCowDeliTimeDto
 */
export interface GenericResponseBalanceCowDeliTimeDto {
    /**
     * 
     * @type {BalanceCowDeliTimeDto}
     * @memberof GenericResponseBalanceCowDeliTimeDto
     */
    data?: BalanceCowDeliTimeDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseBalanceCowDeliTimeDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseBalanceCowDto
 */
export interface GenericResponseBalanceCowDto {
    /**
     * 
     * @type {BalanceCowDto}
     * @memberof GenericResponseBalanceCowDto
     */
    data?: BalanceCowDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseBalanceCowDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseBalanceDto
 */
export interface GenericResponseBalanceDto {
    /**
     * 
     * @type {BalanceDto}
     * @memberof GenericResponseBalanceDto
     */
    data?: BalanceDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseBalanceDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseBreedingCrossCurrentDto
 */
export interface GenericResponseBreedingCrossCurrentDto {
    /**
     * 
     * @type {BreedingCrossCurrentDto}
     * @memberof GenericResponseBreedingCrossCurrentDto
     */
    data?: BreedingCrossCurrentDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseBreedingCrossCurrentDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseBreedingDto
 */
export interface GenericResponseBreedingDto {
    /**
     * 
     * @type {BreedingDto}
     * @memberof GenericResponseBreedingDto
     */
    data?: BreedingDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseBreedingDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseBreedingHistoryListDto
 */
export interface GenericResponseBreedingHistoryListDto {
    /**
     * 
     * @type {BreedingHistoryListDto}
     * @memberof GenericResponseBreedingHistoryListDto
     */
    data?: BreedingHistoryListDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseBreedingHistoryListDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseCollectionTeamConditionDto
 */
export interface GenericResponseCollectionTeamConditionDto {
    /**
     * 
     * @type {Array<TeamConditionDto>}
     * @memberof GenericResponseCollectionTeamConditionDto
     */
    data?: Array<TeamConditionDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseCollectionTeamConditionDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseComIdDto
 */
export interface GenericResponseComIdDto {
    /**
     * 
     * @type {ComIdDto}
     * @memberof GenericResponseComIdDto
     */
    data?: ComIdDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseComIdDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseCowCurrentLocationDto
 */
export interface GenericResponseCowCurrentLocationDto {
    /**
     * 
     * @type {CowCurrentLocationDto}
     * @memberof GenericResponseCowCurrentLocationDto
     */
    data?: CowCurrentLocationDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseCowCurrentLocationDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseCowDetailDto
 */
export interface GenericResponseCowDetailDto {
    /**
     * 
     * @type {CowDetailDto}
     * @memberof GenericResponseCowDetailDto
     */
    data?: CowDetailDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseCowDetailDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseCowDiseaseItemsDto
 */
export interface GenericResponseCowDiseaseItemsDto {
    /**
     * 
     * @type {CowDiseaseItemsDto}
     * @memberof GenericResponseCowDiseaseItemsDto
     */
    data?: CowDiseaseItemsDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseCowDiseaseItemsDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseCowEventListDto
 */
export interface GenericResponseCowEventListDto {
    /**
     * 
     * @type {CowEventListDto}
     * @memberof GenericResponseCowEventListDto
     */
    data?: CowEventListDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseCowEventListDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseCowExportDto
 */
export interface GenericResponseCowExportDto {
    /**
     * 
     * @type {CowExportDto}
     * @memberof GenericResponseCowExportDto
     */
    data?: CowExportDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseCowExportDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseCowFilterDto
 */
export interface GenericResponseCowFilterDto {
    /**
     * 
     * @type {CowFilterDto}
     * @memberof GenericResponseCowFilterDto
     */
    data?: CowFilterDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseCowFilterDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseCowIdDto
 */
export interface GenericResponseCowIdDto {
    /**
     * 
     * @type {CowIdDto}
     * @memberof GenericResponseCowIdDto
     */
    data?: CowIdDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseCowIdDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseCowListAndCountDto
 */
export interface GenericResponseCowListAndCountDto {
    /**
     * 
     * @type {CowListAndCountDto}
     * @memberof GenericResponseCowListAndCountDto
     */
    data?: CowListAndCountDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseCowListAndCountDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseCowLocationHistDto
 */
export interface GenericResponseCowLocationHistDto {
    /**
     * 
     * @type {CowLocationHistDto}
     * @memberof GenericResponseCowLocationHistDto
     */
    data?: CowLocationHistDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseCowLocationHistDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseCowSearchDto
 */
export interface GenericResponseCowSearchDto {
    /**
     * 
     * @type {CowSearchDto}
     * @memberof GenericResponseCowSearchDto
     */
    data?: CowSearchDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseCowSearchDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseCrossSeedDto
 */
export interface GenericResponseCrossSeedDto {
    /**
     * 
     * @type {CrossSeedDto}
     * @memberof GenericResponseCrossSeedDto
     */
    data?: CrossSeedDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseCrossSeedDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseDeliveryAggregateTransitionDto
 */
export interface GenericResponseDeliveryAggregateTransitionDto {
    /**
     * 
     * @type {DeliveryAggregateTransitionDto}
     * @memberof GenericResponseDeliveryAggregateTransitionDto
     */
    data?: DeliveryAggregateTransitionDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseDeliveryAggregateTransitionDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseDeliveryDto
 */
export interface GenericResponseDeliveryDto {
    /**
     * 
     * @type {DeliveryDto}
     * @memberof GenericResponseDeliveryDto
     */
    data?: DeliveryDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseDeliveryDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseDeliveryHistoryListDto
 */
export interface GenericResponseDeliveryHistoryListDto {
    /**
     * 
     * @type {DeliveryHistoryListDto}
     * @memberof GenericResponseDeliveryHistoryListDto
     */
    data?: DeliveryHistoryListDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseDeliveryHistoryListDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseDynamicsAggregateDto
 */
export interface GenericResponseDynamicsAggregateDto {
    /**
     * 
     * @type {DynamicsAggregateDto}
     * @memberof GenericResponseDynamicsAggregateDto
     */
    data?: DynamicsAggregateDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseDynamicsAggregateDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseEventInfoDto
 */
export interface GenericResponseEventInfoDto {
    /**
     * 
     * @type {EventInfoDto}
     * @memberof GenericResponseEventInfoDto
     */
    data?: EventInfoDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseEventInfoDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseFeedAmountHistDto
 */
export interface GenericResponseFeedAmountHistDto {
    /**
     * 
     * @type {FeedAmountHistDto}
     * @memberof GenericResponseFeedAmountHistDto
     */
    data?: FeedAmountHistDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseFeedAmountHistDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseFeedDto
 */
export interface GenericResponseFeedDto {
    /**
     * 
     * @type {FeedDto}
     * @memberof GenericResponseFeedDto
     */
    data?: FeedDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseFeedDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseFeedLeftListDto
 */
export interface GenericResponseFeedLeftListDto {
    /**
     * 
     * @type {FeedLeftListDto}
     * @memberof GenericResponseFeedLeftListDto
     */
    data?: FeedLeftListDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseFeedLeftListDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseFeedRecordedDto
 */
export interface GenericResponseFeedRecordedDto {
    /**
     * 
     * @type {FeedRecordedDto}
     * @memberof GenericResponseFeedRecordedDto
     */
    data?: FeedRecordedDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseFeedRecordedDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseFileDownloadDto
 */
export interface GenericResponseFileDownloadDto {
    /**
     * 
     * @type {FileDownloadDto}
     * @memberof GenericResponseFileDownloadDto
     */
    data?: FileDownloadDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseFileDownloadDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseGrowthDto
 */
export interface GenericResponseGrowthDto {
    /**
     * 
     * @type {GrowthDto}
     * @memberof GenericResponseGrowthDto
     */
    data?: GrowthDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseGrowthDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseGrowthGraphListDto
 */
export interface GenericResponseGrowthGraphListDto {
    /**
     * 
     * @type {GrowthGraphListDto}
     * @memberof GenericResponseGrowthGraphListDto
     */
    data?: GrowthGraphListDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseGrowthGraphListDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseGrowthStandardListDto
 */
export interface GenericResponseGrowthStandardListDto {
    /**
     * 
     * @type {GrowthStandardListDto}
     * @memberof GenericResponseGrowthStandardListDto
     */
    data?: GrowthStandardListDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseGrowthStandardListDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseIvfDto
 */
export interface GenericResponseIvfDto {
    /**
     * 
     * @type {IvfDto}
     * @memberof GenericResponseIvfDto
     */
    data?: IvfDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseIvfDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseIvfEggDto
 */
export interface GenericResponseIvfEggDto {
    /**
     * 
     * @type {IvfEggDto}
     * @memberof GenericResponseIvfEggDto
     */
    data?: IvfEggDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseIvfEggDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseKarteContentDto
 */
export interface GenericResponseKarteContentDto {
    /**
     * 
     * @type {KarteContentDto}
     * @memberof GenericResponseKarteContentDto
     */
    data?: KarteContentDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseKarteContentDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListActivityCowDto
 */
export interface GenericResponseListActivityCowDto {
    /**
     * 
     * @type {Array<ActivityCowDto>}
     * @memberof GenericResponseListActivityCowDto
     */
    data?: Array<ActivityCowDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListActivityCowDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListActivityDto
 */
export interface GenericResponseListActivityDto {
    /**
     * 
     * @type {Array<ActivityDto>}
     * @memberof GenericResponseListActivityDto
     */
    data?: Array<ActivityDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListActivityDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListBreedingCrossCurrentCrossDto
 */
export interface GenericResponseListBreedingCrossCurrentCrossDto {
    /**
     * 
     * @type {Array<BreedingCrossCurrentCrossDto>}
     * @memberof GenericResponseListBreedingCrossCurrentCrossDto
     */
    data?: Array<BreedingCrossCurrentCrossDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListBreedingCrossCurrentCrossDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListBreedingLastAiFatherDto
 */
export interface GenericResponseListBreedingLastAiFatherDto {
    /**
     * 
     * @type {Array<BreedingLastAiFatherDto>}
     * @memberof GenericResponseListBreedingLastAiFatherDto
     */
    data?: Array<BreedingLastAiFatherDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListBreedingLastAiFatherDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListClinicVisitFeeDto
 */
export interface GenericResponseListClinicVisitFeeDto {
    /**
     * 
     * @type {Array<ClinicVisitFeeDto>}
     * @memberof GenericResponseListClinicVisitFeeDto
     */
    data?: Array<ClinicVisitFeeDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListClinicVisitFeeDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListComCowAndIdDto
 */
export interface GenericResponseListComCowAndIdDto {
    /**
     * 
     * @type {Array<ComCowAndIdDto>}
     * @memberof GenericResponseListComCowAndIdDto
     */
    data?: Array<ComCowAndIdDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListComCowAndIdDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListCowListSlimDto
 */
export interface GenericResponseListCowListSlimDto {
    /**
     * 
     * @type {Array<CowListSlimDto>}
     * @memberof GenericResponseListCowListSlimDto
     */
    data?: Array<CowListSlimDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListCowListSlimDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListCowTagDto
 */
export interface GenericResponseListCowTagDto {
    /**
     * 
     * @type {Array<CowTagDto>}
     * @memberof GenericResponseListCowTagDto
     */
    data?: Array<CowTagDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListCowTagDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListDiseaseListDto
 */
export interface GenericResponseListDiseaseListDto {
    /**
     * 
     * @type {Array<DiseaseListDto>}
     * @memberof GenericResponseListDiseaseListDto
     */
    data?: Array<DiseaseListDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListDiseaseListDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListDynamicsAgeDto
 */
export interface GenericResponseListDynamicsAgeDto {
    /**
     * 
     * @type {Array<DynamicsAgeDto>}
     * @memberof GenericResponseListDynamicsAgeDto
     */
    data?: Array<DynamicsAgeDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListDynamicsAgeDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListDynamicsExpectDto
 */
export interface GenericResponseListDynamicsExpectDto {
    /**
     * 
     * @type {Array<DynamicsExpectDto>}
     * @memberof GenericResponseListDynamicsExpectDto
     */
    data?: Array<DynamicsExpectDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListDynamicsExpectDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListExtraCowDto
 */
export interface GenericResponseListExtraCowDto {
    /**
     * 
     * @type {Array<ExtraCowDto>}
     * @memberof GenericResponseListExtraCowDto
     */
    data?: Array<ExtraCowDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListExtraCowDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListFeedLastDayDto
 */
export interface GenericResponseListFeedLastDayDto {
    /**
     * 
     * @type {Array<FeedLastDayDto>}
     * @memberof GenericResponseListFeedLastDayDto
     */
    data?: Array<FeedLastDayDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListFeedLastDayDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListFeedRecentEachDto
 */
export interface GenericResponseListFeedRecentEachDto {
    /**
     * 
     * @type {Array<FeedRecentEachDto>}
     * @memberof GenericResponseListFeedRecentEachDto
     */
    data?: Array<FeedRecentEachDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListFeedRecentEachDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListFeedRecentTimeSpanDto
 */
export interface GenericResponseListFeedRecentTimeSpanDto {
    /**
     * 
     * @type {Array<FeedRecentTimeSpanDto>}
     * @memberof GenericResponseListFeedRecentTimeSpanDto
     */
    data?: Array<FeedRecentTimeSpanDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListFeedRecentTimeSpanDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListGrowthBirthWeightDto
 */
export interface GenericResponseListGrowthBirthWeightDto {
    /**
     * 
     * @type {Array<GrowthBirthWeightDto>}
     * @memberof GenericResponseListGrowthBirthWeightDto
     */
    data?: Array<GrowthBirthWeightDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListGrowthBirthWeightDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListIvfListDto
 */
export interface GenericResponseListIvfListDto {
    /**
     * 
     * @type {Array<IvfListDto>}
     * @memberof GenericResponseListIvfListDto
     */
    data?: Array<IvfListDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListIvfListDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListKarteListDto
 */
export interface GenericResponseListKarteListDto {
    /**
     * 
     * @type {Array<KarteListDto>}
     * @memberof GenericResponseListKarteListDto
     */
    data?: Array<KarteListDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListKarteListDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListNoteDto
 */
export interface GenericResponseListNoteDto {
    /**
     * 
     * @type {Array<NoteDto>}
     * @memberof GenericResponseListNoteDto
     */
    data?: Array<NoteDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListNoteDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListOpuListDto
 */
export interface GenericResponseListOpuListDto {
    /**
     * 
     * @type {Array<OpuListDto>}
     * @memberof GenericResponseListOpuListDto
     */
    data?: Array<OpuListDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListOpuListDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListPartnerDto
 */
export interface GenericResponseListPartnerDto {
    /**
     * 
     * @type {Array<PartnerDto>}
     * @memberof GenericResponseListPartnerDto
     */
    data?: Array<PartnerDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListPartnerDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListPreventionTreatListDto
 */
export interface GenericResponseListPreventionTreatListDto {
    /**
     * 
     * @type {Array<PreventionTreatListDto>}
     * @memberof GenericResponseListPreventionTreatListDto
     */
    data?: Array<PreventionTreatListDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListPreventionTreatListDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListProgramDto
 */
export interface GenericResponseListProgramDto {
    /**
     * 
     * @type {Array<ProgramDto>}
     * @memberof GenericResponseListProgramDto
     */
    data?: Array<ProgramDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListProgramDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListRanchScheduleDto
 */
export interface GenericResponseListRanchScheduleDto {
    /**
     * 
     * @type {Array<RanchScheduleDto>}
     * @memberof GenericResponseListRanchScheduleDto
     */
    data?: Array<RanchScheduleDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListRanchScheduleDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListRanchSeedListDto
 */
export interface GenericResponseListRanchSeedListDto {
    /**
     * 
     * @type {Array<RanchSeedListDto>}
     * @memberof GenericResponseListRanchSeedListDto
     */
    data?: Array<RanchSeedListDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListRanchSeedListDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListRanchTagDto
 */
export interface GenericResponseListRanchTagDto {
    /**
     * 
     * @type {Array<RanchTagDto>}
     * @memberof GenericResponseListRanchTagDto
     */
    data?: Array<RanchTagDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListRanchTagDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListRutLatestDto
 */
export interface GenericResponseListRutLatestDto {
    /**
     * 
     * @type {Array<RutLatestDto>}
     * @memberof GenericResponseListRutLatestDto
     */
    data?: Array<RutLatestDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListRutLatestDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListSellCowAggregateCarcassDto
 */
export interface GenericResponseListSellCowAggregateCarcassDto {
    /**
     * 
     * @type {Array<SellCowAggregateCarcassDto>}
     * @memberof GenericResponseListSellCowAggregateCarcassDto
     */
    data?: Array<SellCowAggregateCarcassDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListSellCowAggregateCarcassDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListSellCowAggregateTransitionDto
 */
export interface GenericResponseListSellCowAggregateTransitionDto {
    /**
     * 
     * @type {Array<SellCowAggregateTransitionDto>}
     * @memberof GenericResponseListSellCowAggregateTransitionDto
     */
    data?: Array<SellCowAggregateTransitionDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListSellCowAggregateTransitionDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListSellCowDto
 */
export interface GenericResponseListSellCowDto {
    /**
     * 
     * @type {Array<SellCowDto>}
     * @memberof GenericResponseListSellCowDto
     */
    data?: Array<SellCowDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListSellCowDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListSensorHistoryCowDto
 */
export interface GenericResponseListSensorHistoryCowDto {
    /**
     * 
     * @type {Array<SensorHistoryCowDto>}
     * @memberof GenericResponseListSensorHistoryCowDto
     */
    data?: Array<SensorHistoryCowDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListSensorHistoryCowDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListSensorHistoryGroupDto
 */
export interface GenericResponseListSensorHistoryGroupDto {
    /**
     * 
     * @type {Array<SensorHistoryGroupDto>}
     * @memberof GenericResponseListSensorHistoryGroupDto
     */
    data?: Array<SensorHistoryGroupDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListSensorHistoryGroupDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListSensorHistoryListDto
 */
export interface GenericResponseListSensorHistoryListDto {
    /**
     * 
     * @type {Array<SensorHistoryListDto>}
     * @memberof GenericResponseListSensorHistoryListDto
     */
    data?: Array<SensorHistoryListDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListSensorHistoryListDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListSympDataDto
 */
export interface GenericResponseListSympDataDto {
    /**
     * 
     * @type {Array<SympDataDto>}
     * @memberof GenericResponseListSympDataDto
     */
    data?: Array<SympDataDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListSympDataDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListSympEventDto
 */
export interface GenericResponseListSympEventDto {
    /**
     * 
     * @type {Array<SympEventDto>}
     * @memberof GenericResponseListSympEventDto
     */
    data?: Array<SympEventDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListSympEventDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListTeamDiseaseListDto
 */
export interface GenericResponseListTeamDiseaseListDto {
    /**
     * 
     * @type {Array<TeamDiseaseListDto>}
     * @memberof GenericResponseListTeamDiseaseListDto
     */
    data?: Array<TeamDiseaseListDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListTeamDiseaseListDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListTeamProcessDto
 */
export interface GenericResponseListTeamProcessDto {
    /**
     * 
     * @type {Array<TeamProcessDto>}
     * @memberof GenericResponseListTeamProcessDto
     */
    data?: Array<TeamProcessDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListTeamProcessDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListTeamTreatPresetDto
 */
export interface GenericResponseListTeamTreatPresetDto {
    /**
     * 
     * @type {Array<TeamTreatPresetDto>}
     * @memberof GenericResponseListTeamTreatPresetDto
     */
    data?: Array<TeamTreatPresetDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListTeamTreatPresetDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseListVisitListDto
 */
export interface GenericResponseListVisitListDto {
    /**
     * 
     * @type {Array<VisitListDto>}
     * @memberof GenericResponseListVisitListDto
     */
    data?: Array<VisitListDto>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseListVisitListDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseOpuDto
 */
export interface GenericResponseOpuDto {
    /**
     * 
     * @type {OpuDto}
     * @memberof GenericResponseOpuDto
     */
    data?: OpuDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseOpuDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseOtherPlDto
 */
export interface GenericResponseOtherPlDto {
    /**
     * 
     * @type {OtherPlDto}
     * @memberof GenericResponseOtherPlDto
     */
    data?: OtherPlDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseOtherPlDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseOtherPlEventDto
 */
export interface GenericResponseOtherPlEventDto {
    /**
     * 
     * @type {OtherPlEventDto}
     * @memberof GenericResponseOtherPlEventDto
     */
    data?: OtherPlEventDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseOtherPlEventDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponsePreventionDto
 */
export interface GenericResponsePreventionDto {
    /**
     * 
     * @type {PreventionDto}
     * @memberof GenericResponsePreventionDto
     */
    data?: PreventionDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponsePreventionDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseProgramApplyResultDto
 */
export interface GenericResponseProgramApplyResultDto {
    /**
     * 
     * @type {ProgramApplyResultDto}
     * @memberof GenericResponseProgramApplyResultDto
     */
    data?: ProgramApplyResultDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseProgramApplyResultDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseProgramOfCowApplyResultDto
 */
export interface GenericResponseProgramOfCowApplyResultDto {
    /**
     * 
     * @type {ProgramOfCowApplyResultDto}
     * @memberof GenericResponseProgramOfCowApplyResultDto
     */
    data?: ProgramOfCowApplyResultDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseProgramOfCowApplyResultDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseRanchConstDto
 */
export interface GenericResponseRanchConstDto {
    /**
     * 
     * @type {RanchConstDto}
     * @memberof GenericResponseRanchConstDto
     */
    data?: RanchConstDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseRanchConstDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseRanchEventListDto
 */
export interface GenericResponseRanchEventListDto {
    /**
     * 
     * @type {RanchEventListDto}
     * @memberof GenericResponseRanchEventListDto
     */
    data?: RanchEventListDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseRanchEventListDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseRanchFeedDto
 */
export interface GenericResponseRanchFeedDto {
    /**
     * 
     * @type {RanchFeedDto}
     * @memberof GenericResponseRanchFeedDto
     */
    data?: RanchFeedDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseRanchFeedDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseRanchHouseDto
 */
export interface GenericResponseRanchHouseDto {
    /**
     * 
     * @type {RanchHouseDto}
     * @memberof GenericResponseRanchHouseDto
     */
    data?: RanchHouseDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseRanchHouseDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseRanchInfoDto
 */
export interface GenericResponseRanchInfoDto {
    /**
     * 
     * @type {RanchInfoDto}
     * @memberof GenericResponseRanchInfoDto
     */
    data?: RanchInfoDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseRanchInfoDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseRecordIdDto
 */
export interface GenericResponseRecordIdDto {
    /**
     * 
     * @type {RecordIdDto}
     * @memberof GenericResponseRecordIdDto
     */
    data?: RecordIdDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseRecordIdDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseRobotPreviewDto
 */
export interface GenericResponseRobotPreviewDto {
    /**
     * 
     * @type {RobotPreviewDto}
     * @memberof GenericResponseRobotPreviewDto
     */
    data?: RobotPreviewDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseRobotPreviewDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseRutDto
 */
export interface GenericResponseRutDto {
    /**
     * 
     * @type {RutDto}
     * @memberof GenericResponseRutDto
     */
    data?: RutDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseRutDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseSellCowAggregateMotherListDto
 */
export interface GenericResponseSellCowAggregateMotherListDto {
    /**
     * 
     * @type {SellCowAggregateMotherListDto}
     * @memberof GenericResponseSellCowAggregateMotherListDto
     */
    data?: SellCowAggregateMotherListDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseSellCowAggregateMotherListDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseSellCowAggregatePartnerListDto
 */
export interface GenericResponseSellCowAggregatePartnerListDto {
    /**
     * 
     * @type {SellCowAggregatePartnerListDto}
     * @memberof GenericResponseSellCowAggregatePartnerListDto
     */
    data?: SellCowAggregatePartnerListDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseSellCowAggregatePartnerListDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseSellCowDto
 */
export interface GenericResponseSellCowDto {
    /**
     * 
     * @type {SellCowDto}
     * @memberof GenericResponseSellCowDto
     */
    data?: SellCowDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseSellCowDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseSellCowEventDto
 */
export interface GenericResponseSellCowEventDto {
    /**
     * 
     * @type {SellCowEventDto}
     * @memberof GenericResponseSellCowEventDto
     */
    data?: SellCowEventDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseSellCowEventDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseSellMilkDto
 */
export interface GenericResponseSellMilkDto {
    /**
     * 
     * @type {SellMilkDto}
     * @memberof GenericResponseSellMilkDto
     */
    data?: SellMilkDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseSellMilkDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseSellMilkEventDto
 */
export interface GenericResponseSellMilkEventDto {
    /**
     * 
     * @type {SellMilkEventDto}
     * @memberof GenericResponseSellMilkEventDto
     */
    data?: SellMilkEventDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseSellMilkEventDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseSellMilkGraphDto
 */
export interface GenericResponseSellMilkGraphDto {
    /**
     * 
     * @type {SellMilkGraphDto}
     * @memberof GenericResponseSellMilkGraphDto
     */
    data?: SellMilkGraphDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseSellMilkGraphDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseSensorDeviceIdDto
 */
export interface GenericResponseSensorDeviceIdDto {
    /**
     * 
     * @type {SensorDeviceIdDto}
     * @memberof GenericResponseSensorDeviceIdDto
     */
    data?: SensorDeviceIdDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseSensorDeviceIdDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseSensorDeviceListDto
 */
export interface GenericResponseSensorDeviceListDto {
    /**
     * 
     * @type {SensorDeviceListDto}
     * @memberof GenericResponseSensorDeviceListDto
     */
    data?: SensorDeviceListDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseSensorDeviceListDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseSigninDto
 */
export interface GenericResponseSigninDto {
    /**
     * 
     * @type {SigninDto}
     * @memberof GenericResponseSigninDto
     */
    data?: SigninDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseSigninDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseSovDto
 */
export interface GenericResponseSovDto {
    /**
     * 
     * @type {SovDto}
     * @memberof GenericResponseSovDto
     */
    data?: SovDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseSovDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseSymptomDto
 */
export interface GenericResponseSymptomDto {
    /**
     * 
     * @type {SymptomDto}
     * @memberof GenericResponseSymptomDto
     */
    data?: SymptomDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseSymptomDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseSymptomHistoryDto
 */
export interface GenericResponseSymptomHistoryDto {
    /**
     * 
     * @type {SymptomHistoryDto}
     * @memberof GenericResponseSymptomHistoryDto
     */
    data?: SymptomHistoryDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseSymptomHistoryDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseTeamMedicineListDto
 */
export interface GenericResponseTeamMedicineListDto {
    /**
     * 
     * @type {TeamMedicineListDto}
     * @memberof GenericResponseTeamMedicineListDto
     */
    data?: TeamMedicineListDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseTeamMedicineListDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseTeamMedicineRouteListDto
 */
export interface GenericResponseTeamMedicineRouteListDto {
    /**
     * 
     * @type {TeamMedicineRouteListDto}
     * @memberof GenericResponseTeamMedicineRouteListDto
     */
    data?: TeamMedicineRouteListDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseTeamMedicineRouteListDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseTeamNameDto
 */
export interface GenericResponseTeamNameDto {
    /**
     * 
     * @type {TeamNameDto}
     * @memberof GenericResponseTeamNameDto
     */
    data?: TeamNameDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseTeamNameDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseTeamRequestListDto
 */
export interface GenericResponseTeamRequestListDto {
    /**
     * 
     * @type {TeamRequestListDto}
     * @memberof GenericResponseTeamRequestListDto
     */
    data?: TeamRequestListDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseTeamRequestListDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseTeamSettingDto
 */
export interface GenericResponseTeamSettingDto {
    /**
     * 
     * @type {TeamSettingDto}
     * @memberof GenericResponseTeamSettingDto
     */
    data?: TeamSettingDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseTeamSettingDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseTeamTreatItemListDto
 */
export interface GenericResponseTeamTreatItemListDto {
    /**
     * 
     * @type {TeamTreatItemListDto}
     * @memberof GenericResponseTeamTreatItemListDto
     */
    data?: TeamTreatItemListDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseTeamTreatItemListDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseTeamUsersDto
 */
export interface GenericResponseTeamUsersDto {
    /**
     * 
     * @type {TeamUsersDto}
     * @memberof GenericResponseTeamUsersDto
     */
    data?: TeamUsersDto;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseTeamUsersDto
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GenericResponseVoid
 */
export interface GenericResponseVoid {
    /**
     * 
     * @type {ResponseMeta}
     * @memberof GenericResponseVoid
     */
    meta?: ResponseMeta;
}
/**
 * 
 * @export
 * @interface GrowthBirthWeightDto
 */
export interface GrowthBirthWeightDto {
    /**
     * 
     * @type {number}
     * @memberof GrowthBirthWeightDto
     */
    actual?: number;
    /**
     * 
     * @type {string}
     * @memberof GrowthBirthWeightDto
     */
    birthday?: string;
    /**
     * 
     * @type {number}
     * @memberof GrowthBirthWeightDto
     */
    cow_id: number;
    /**
     * 
     * @type {number}
     * @memberof GrowthBirthWeightDto
     */
    standard?: number;
}
/**
 * 
 * @export
 * @interface GrowthDeleteReq
 */
export interface GrowthDeleteReq {
    /**
     * cow_id
     * @type {number}
     * @memberof GrowthDeleteReq
     */
    cow_id: number;
    /**
     * event_id
     * @type {number}
     * @memberof GrowthDeleteReq
     */
    event_id: number;
    /**
     * ranch_id
     * @type {number}
     * @memberof GrowthDeleteReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface GrowthDto
 */
export interface GrowthDto {
    /**
     * 
     * @type {number}
     * @memberof GrowthDto
     */
    abdomen?: number;
    /**
     * 
     * @type {number}
     * @memberof GrowthDto
     */
    bcs?: number;
    /**
     * 
     * @type {number}
     * @memberof GrowthDto
     */
    chest?: number;
    /**
     * 
     * @type {number}
     * @memberof GrowthDto
     */
    cow_id: number;
    /**
     * 
     * @type {number}
     * @memberof GrowthDto
     */
    event_id: number;
    /**
     * 
     * @type {number}
     * @memberof GrowthDto
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof GrowthDto
     */
    ranch_id: number;
    /**
     * 
     * @type {number}
     * @memberof GrowthDto
     */
    rfs?: number;
    /**
     * 
     * @type {string}
     * @memberof GrowthDto
     */
    watched_at: string;
    /**
     * 
     * @type {string}
     * @memberof GrowthDto
     */
    watched_by: string;
    /**
     * 
     * @type {number}
     * @memberof GrowthDto
     */
    weight?: number;
}
/**
 * 
 * @export
 * @interface GrowthGraphDto
 */
export interface GrowthGraphDto {
    /**
     * 
     * @type {number}
     * @memberof GrowthGraphDto
     */
    score: number;
    /**
     * 
     * @type {string}
     * @memberof GrowthGraphDto
     */
    watched_at: string;
}
/**
 * 
 * @export
 * @interface GrowthGraphListDto
 */
export interface GrowthGraphListDto {
    /**
     * 
     * @type {Array<GrowthGraphDto>}
     * @memberof GrowthGraphListDto
     */
    scores: Array<GrowthGraphDto>;
}
/**
 * 
 * @export
 * @interface GrowthGraphReq
 */
export interface GrowthGraphReq {
    /**
     * 
     * @type {number}
     * @memberof GrowthGraphReq
     */
    cow_id: number;
    /**
     * 
     * @type {string}
     * @memberof GrowthGraphReq
     */
    from?: string;
    /**
     * 
     * @type {number}
     * @memberof GrowthGraphReq
     */
    ranch_id: number;
    /**
     * 
     * @type {number}
     * @memberof GrowthGraphReq
     */
    score_type: number;
    /**
     * 
     * @type {string}
     * @memberof GrowthGraphReq
     */
    to?: string;
}
/**
 * 
 * @export
 * @interface GrowthModifyReq
 */
export interface GrowthModifyReq {
    /**
     * 
     * @type {Array<GrowthModifyReqCow>}
     * @memberof GrowthModifyReq
     */
    cows: Array<GrowthModifyReqCow>;
    /**
     * event_id
     * @type {number}
     * @memberof GrowthModifyReq
     */
    event_id?: number;
    /**
     * is_new
     * @type {number}
     * @memberof GrowthModifyReq
     */
    is_new: number;
    /**
     * ranch_id
     * @type {number}
     * @memberof GrowthModifyReq
     */
    ranch_id: number;
    /**
     * 
     * @type {number}
     * @memberof GrowthModifyReq
     */
    schedule_id?: number;
    /**
     * 
     * @type {string}
     * @memberof GrowthModifyReq
     */
    watched_at: string;
}
/**
 * 
 * @export
 * @interface GrowthModifyReqCow
 */
export interface GrowthModifyReqCow {
    /**
     * 
     * @type {number}
     * @memberof GrowthModifyReqCow
     */
    abdomen?: number;
    /**
     * 
     * @type {number}
     * @memberof GrowthModifyReqCow
     */
    bcs?: number;
    /**
     * 
     * @type {number}
     * @memberof GrowthModifyReqCow
     */
    chest?: number;
    /**
     * 
     * @type {number}
     * @memberof GrowthModifyReqCow
     */
    cow_id: number;
    /**
     * 
     * @type {number}
     * @memberof GrowthModifyReqCow
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof GrowthModifyReqCow
     */
    rfs?: number;
    /**
     * 
     * @type {number}
     * @memberof GrowthModifyReqCow
     */
    weight?: number;
}
/**
 * 
 * @export
 * @interface GrowthStandardDto
 */
export interface GrowthStandardDto {
    /**
     * 
     * @type {number}
     * @memberof GrowthStandardDto
     */
    age_from: number;
    /**
     * 
     * @type {number}
     * @memberof GrowthStandardDto
     */
    age_to?: number;
    /**
     * 
     * @type {number}
     * @memberof GrowthStandardDto
     */
    score: number;
}
/**
 * 
 * @export
 * @interface GrowthStandardListDto
 */
export interface GrowthStandardListDto {
    /**
     * 
     * @type {Array<GrowthStandardDto>}
     * @memberof GrowthStandardListDto
     */
    scores: Array<GrowthStandardDto>;
}
/**
 * 
 * @export
 * @interface GrowthStandardListReq
 */
export interface GrowthStandardListReq {
    /**
     * 
     * @type {number}
     * @memberof GrowthStandardListReq
     */
    breed_no: number;
    /**
     * 
     * @type {number}
     * @memberof GrowthStandardListReq
     */
    is_male: number;
    /**
     * 
     * @type {number}
     * @memberof GrowthStandardListReq
     */
    score_type: number;
    /**
     * 
     * @type {number}
     * @memberof GrowthStandardListReq
     */
    use_no: number;
}
/**
 * 
 * @export
 * @interface IEventDto
 */
export interface IEventDto {
    /**
     * watched_at
     * @type {string}
     * @memberof IEventDto
     */
    watched_at: string;
}
/**
 * 
 * @export
 * @interface IvfDto
 */
export interface IvfDto {
    /**
     * 
     * @type {string}
     * @memberof IvfDto
     */
    comment: string;
    /**
     * 
     * @type {number}
     * @memberof IvfDto
     */
    cow_id: number;
    /**
     * 
     * @type {IvfListEggDto}
     * @memberof IvfDto
     */
    egg?: IvfListEggDto;
    /**
     * 
     * @type {number}
     * @memberof IvfDto
     */
    event_id: number;
    /**
     * 
     * @type {number}
     * @memberof IvfDto
     */
    father_exid: number;
    /**
     * 
     * @type {string}
     * @memberof IvfDto
     */
    father_name: string;
    /**
     * 
     * @type {string}
     * @memberof IvfDto
     */
    label_no: string;
    /**
     * 
     * @type {number}
     * @memberof IvfDto
     */
    opu_id: number;
    /**
     * 
     * @type {Array<IvfRankDto>}
     * @memberof IvfDto
     */
    ranks: Array<IvfRankDto>;
    /**
     * 
     * @type {number}
     * @memberof IvfDto
     */
    seed_lot_id: number;
    /**
     * 
     * @type {string}
     * @memberof IvfDto
     */
    watched_at: string;
    /**
     * 
     * @type {string}
     * @memberof IvfDto
     */
    watched_by: string;
}
/**
 * 
 * @export
 * @interface IvfEggDto
 */
export interface IvfEggDto {
    /**
     * 
     * @type {string}
     * @memberof IvfEggDto
     */
    comment: string;
    /**
     * 
     * @type {number}
     * @memberof IvfEggDto
     */
    cow_id: number;
    /**
     * 
     * @type {number}
     * @memberof IvfEggDto
     */
    event_id: number;
    /**
     * 
     * @type {number}
     * @memberof IvfEggDto
     */
    ivf_id: number;
    /**
     * 
     * @type {number}
     * @memberof IvfEggDto
     */
    ranch_id: number;
    /**
     * 
     * @type {Array<IvfEggRankDto>}
     * @memberof IvfEggDto
     */
    ranks: Array<IvfEggRankDto>;
    /**
     * 
     * @type {string}
     * @memberof IvfEggDto
     */
    watched_at: string;
    /**
     * 
     * @type {string}
     * @memberof IvfEggDto
     */
    watched_by: string;
}
/**
 * 
 * @export
 * @interface IvfEggModifyReq
 */
export interface IvfEggModifyReq {
    /**
     * 
     * @type {string}
     * @memberof IvfEggModifyReq
     */
    comment: string;
    /**
     * 
     * @type {number}
     * @memberof IvfEggModifyReq
     */
    cow_id: number;
    /**
     * 新規登録の場合はnull
     * @type {number}
     * @memberof IvfEggModifyReq
     */
    event_id?: number;
    /**
     * 
     * @type {number}
     * @memberof IvfEggModifyReq
     */
    ivf_id: number;
    /**
     * 
     * @type {number}
     * @memberof IvfEggModifyReq
     */
    ranch_id: number;
    /**
     * 
     * @type {Array<IvfEggModifyReqRank>}
     * @memberof IvfEggModifyReq
     */
    ranks: Array<IvfEggModifyReqRank>;
    /**
     * 
     * @type {number}
     * @memberof IvfEggModifyReq
     */
    schedule_id?: number;
    /**
     * 
     * @type {string}
     * @memberof IvfEggModifyReq
     */
    watched_at: string;
}
/**
 * 
 * @export
 * @interface IvfEggModifyReqRank
 */
export interface IvfEggModifyReqRank {
    /**
     * 
     * @type {Array<IvfEggModifyReqRankDetail>}
     * @memberof IvfEggModifyReqRank
     */
    details?: Array<IvfEggModifyReqRankDetail>;
    /**
     * 
     * @type {number}
     * @memberof IvfEggModifyReqRank
     */
    egg_count: number;
    /**
     * 
     * @type {number}
     * @memberof IvfEggModifyReqRank
     */
    egg_rank: number;
    /**
     * 
     * @type {Array<IvfEggModifyReqStage>}
     * @memberof IvfEggModifyReqRank
     */
    stages?: Array<IvfEggModifyReqStage>;
}
/**
 * 
 * @export
 * @interface IvfEggModifyReqRankDetail
 */
export interface IvfEggModifyReqRankDetail {
    /**
     * 
     * @type {number}
     * @memberof IvfEggModifyReqRankDetail
     */
    egg_count: number;
    /**
     * 
     * @type {number}
     * @memberof IvfEggModifyReqRankDetail
     */
    ovum_rank: number;
}
/**
 * 
 * @export
 * @interface IvfEggModifyReqStage
 */
export interface IvfEggModifyReqStage {
    /**
     * 
     * @type {number}
     * @memberof IvfEggModifyReqStage
     */
    egg_count: number;
    /**
     * 
     * @type {number}
     * @memberof IvfEggModifyReqStage
     */
    egg_stage: number;
    /**
     * 
     * @type {number}
     * @memberof IvfEggModifyReqStage
     */
    is_frozen: number;
}
/**
 * 
 * @export
 * @interface IvfEggRankDetailDto
 */
export interface IvfEggRankDetailDto {
    /**
     * 
     * @type {number}
     * @memberof IvfEggRankDetailDto
     */
    egg_count: number;
    /**
     * 
     * @type {number}
     * @memberof IvfEggRankDetailDto
     */
    ovum_rank: number;
}
/**
 * 
 * @export
 * @interface IvfEggRankDto
 */
export interface IvfEggRankDto {
    /**
     * 
     * @type {Array<IvfEggRankDetailDto>}
     * @memberof IvfEggRankDto
     */
    details: Array<IvfEggRankDetailDto>;
    /**
     * 
     * @type {number}
     * @memberof IvfEggRankDto
     */
    egg_count: number;
    /**
     * 
     * @type {number}
     * @memberof IvfEggRankDto
     */
    egg_rank: number;
    /**
     * 
     * @type {Array<IvfEggStageDto>}
     * @memberof IvfEggRankDto
     */
    stages: Array<IvfEggStageDto>;
}
/**
 * 
 * @export
 * @interface IvfEggStageDto
 */
export interface IvfEggStageDto {
    /**
     * 
     * @type {number}
     * @memberof IvfEggStageDto
     */
    egg_count: number;
    /**
     * 
     * @type {number}
     * @memberof IvfEggStageDto
     */
    egg_stage: number;
    /**
     * 
     * @type {number}
     * @memberof IvfEggStageDto
     */
    is_frozen: number;
    /**
     * 
     * @type {number}
     * @memberof IvfEggStageDto
     */
    seed_lot_id?: number;
}
/**
 * 
 * @export
 * @interface IvfListDto
 */
export interface IvfListDto {
    /**
     * 
     * @type {number}
     * @memberof IvfListDto
     */
    cow_id: number;
    /**
     * 
     * @type {IvfListEggDto}
     * @memberof IvfListDto
     */
    egg?: IvfListEggDto;
    /**
     * 
     * @type {number}
     * @memberof IvfListDto
     */
    event_id: number;
    /**
     * 
     * @type {number}
     * @memberof IvfListDto
     */
    father_exid: number;
    /**
     * 
     * @type {string}
     * @memberof IvfListDto
     */
    father_name: string;
    /**
     * 
     * @type {string}
     * @memberof IvfListDto
     */
    label_no: string;
    /**
     * 
     * @type {Array<IvfRankDto>}
     * @memberof IvfListDto
     */
    ranks: Array<IvfRankDto>;
    /**
     * 
     * @type {number}
     * @memberof IvfListDto
     */
    seed_lot_id: number;
    /**
     * 
     * @type {string}
     * @memberof IvfListDto
     */
    watched_at: string;
    /**
     * 
     * @type {string}
     * @memberof IvfListDto
     */
    watched_by: string;
}
/**
 * 
 * @export
 * @interface IvfListEggDto
 */
export interface IvfListEggDto {
    /**
     * 
     * @type {Array<IvfListEggRankDto>}
     * @memberof IvfListEggDto
     */
    ranks: Array<IvfListEggRankDto>;
    /**
     * 
     * @type {string}
     * @memberof IvfListEggDto
     */
    watched_at: string;
}
/**
 * 
 * @export
 * @interface IvfListEggRankDto
 */
export interface IvfListEggRankDto {
    /**
     * 
     * @type {number}
     * @memberof IvfListEggRankDto
     */
    egg_count: number;
    /**
     * 
     * @type {number}
     * @memberof IvfListEggRankDto
     */
    egg_rank: number;
}
/**
 * 
 * @export
 * @interface IvfListReq
 */
export interface IvfListReq {
    /**
     * 
     * @type {number}
     * @memberof IvfListReq
     */
    cow_id: number;
    /**
     * 
     * @type {string}
     * @memberof IvfListReq
     */
    from: string;
    /**
     * 
     * @type {number}
     * @memberof IvfListReq
     */
    ranch_id: number;
    /**
     * 
     * @type {string}
     * @memberof IvfListReq
     */
    to?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IvfListReq
     */
    valid?: boolean;
}
/**
 * 
 * @export
 * @interface IvfModifyReq
 */
export interface IvfModifyReq {
    /**
     * 
     * @type {string}
     * @memberof IvfModifyReq
     */
    comment: string;
    /**
     * 
     * @type {number}
     * @memberof IvfModifyReq
     */
    cow_id: number;
    /**
     * 新規登録の場合はnull
     * @type {number}
     * @memberof IvfModifyReq
     */
    event_id?: number;
    /**
     * 
     * @type {string}
     * @memberof IvfModifyReq
     */
    label_no: string;
    /**
     * 
     * @type {number}
     * @memberof IvfModifyReq
     */
    opu_id: number;
    /**
     * 
     * @type {number}
     * @memberof IvfModifyReq
     */
    ranch_id: number;
    /**
     * 
     * @type {Array<IvfModifyReqRank>}
     * @memberof IvfModifyReq
     */
    ranks: Array<IvfModifyReqRank>;
    /**
     * 
     * @type {number}
     * @memberof IvfModifyReq
     */
    schedule_id?: number;
    /**
     * 
     * @type {number}
     * @memberof IvfModifyReq
     */
    seed_lot_id: number;
    /**
     * 
     * @type {string}
     * @memberof IvfModifyReq
     */
    watched_at: string;
}
/**
 * 
 * @export
 * @interface IvfModifyReqRank
 */
export interface IvfModifyReqRank {
    /**
     * 
     * @type {number}
     * @memberof IvfModifyReqRank
     */
    egg_count: number;
    /**
     * 
     * @type {number}
     * @memberof IvfModifyReqRank
     */
    ovum_rank: number;
}
/**
 * 
 * @export
 * @interface IvfRankDto
 */
export interface IvfRankDto {
    /**
     * 
     * @type {number}
     * @memberof IvfRankDto
     */
    egg_count: number;
    /**
     * 
     * @type {number}
     * @memberof IvfRankDto
     */
    ovum_rank: number;
}
/**
 * 
 * @export
 * @interface KarteContentCoverCowDto
 */
export interface KarteContentCoverCowDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof KarteContentCoverCowDto
     */
    diseases?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof KarteContentCoverCowDto
     */
    first_day?: string;
    /**
     * 
     * @type {string}
     * @memberof KarteContentCoverCowDto
     */
    outcome_day?: string;
    /**
     * 
     * @type {string}
     * @memberof KarteContentCoverCowDto
     */
    trace_id?: string;
}
/**
 * 
 * @export
 * @interface KarteContentCoverDto
 */
export interface KarteContentCoverDto {
    /**
     * 
     * @type {string}
     * @memberof KarteContentCoverDto
     */
    clinic_address?: string;
    /**
     * 
     * @type {string}
     * @memberof KarteContentCoverDto
     */
    clinic_name?: string;
    /**
     * 
     * @type {string}
     * @memberof KarteContentCoverDto
     */
    clinic_tel?: string;
    /**
     * 
     * @type {Array<KarteContentCoverCowDto>}
     * @memberof KarteContentCoverDto
     */
    cows?: Array<KarteContentCoverCowDto>;
    /**
     * 
     * @type {string}
     * @memberof KarteContentCoverDto
     */
    month?: string;
    /**
     * 
     * @type {string}
     * @memberof KarteContentCoverDto
     */
    published_on?: string;
    /**
     * 
     * @type {string}
     * @memberof KarteContentCoverDto
     */
    ranch_address?: string;
    /**
     * 
     * @type {string}
     * @memberof KarteContentCoverDto
     */
    ranch_name?: string;
    /**
     * 
     * @type {string}
     * @memberof KarteContentCoverDto
     */
    receiver?: string;
}
/**
 * 
 * @export
 * @interface KarteContentDetailBreedingHistoryDto
 */
export interface KarteContentDetailBreedingHistoryDto {
    /**
     * 
     * @type {number}
     * @memberof KarteContentDetailBreedingHistoryDto
     */
    delivery_count?: number;
    /**
     * 
     * @type {string}
     * @memberof KarteContentDetailBreedingHistoryDto
     */
    delivery_scheduled_on?: string;
    /**
     * 
     * @type {string}
     * @memberof KarteContentDetailBreedingHistoryDto
     */
    first_rut_at?: string;
    /**
     * 
     * @type {string}
     * @memberof KarteContentDetailBreedingHistoryDto
     */
    last_cross_at?: string;
    /**
     * 
     * @type {number}
     * @memberof KarteContentDetailBreedingHistoryDto
     */
    last_cross_type?: number;
    /**
     * 
     * @type {string}
     * @memberof KarteContentDetailBreedingHistoryDto
     */
    last_delivered_at?: string;
    /**
     * 
     * @type {string}
     * @memberof KarteContentDetailBreedingHistoryDto
     */
    last_delivery_condition?: string;
    /**
     * 
     * @type {number}
     * @memberof KarteContentDetailBreedingHistoryDto
     */
    pregnant_score?: number;
}
/**
 * 
 * @export
 * @interface KarteContentDetailCowDto
 */
export interface KarteContentDetailCowDto {
    /**
     * 
     * @type {string}
     * @memberof KarteContentDetailCowDto
     */
    birthday?: string;
    /**
     * 
     * @type {string}
     * @memberof KarteContentDetailCowDto
     */
    breed_name?: string;
    /**
     * 
     * @type {number}
     * @memberof KarteContentDetailCowDto
     */
    is_male?: number;
    /**
     * 
     * @type {string}
     * @memberof KarteContentDetailCowDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof KarteContentDetailCowDto
     */
    trace_id?: string;
    /**
     * 
     * @type {string}
     * @memberof KarteContentDetailCowDto
     */
    use_name?: string;
}
/**
 * 
 * @export
 * @interface KarteContentDetailDiseaseDto
 */
export interface KarteContentDetailDiseaseDto {
    /**
     * 
     * @type {number}
     * @memberof KarteContentDetailDiseaseDto
     */
    code_no?: number;
    /**
     * 
     * @type {string}
     * @memberof KarteContentDetailDiseaseDto
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface KarteContentDetailDto
 */
export interface KarteContentDetailDto {
    /**
     * 
     * @type {KarteContentDetailBreedingHistoryDto}
     * @memberof KarteContentDetailDto
     */
    breedingHistory?: KarteContentDetailBreedingHistoryDto;
    /**
     * 
     * @type {KarteContentDetailCowDto}
     * @memberof KarteContentDetailDto
     */
    cow?: KarteContentDetailCowDto;
    /**
     * 
     * @type {Array<KarteContentDetailEventDto>}
     * @memberof KarteContentDetailDto
     */
    events?: Array<KarteContentDetailEventDto>;
    /**
     * 
     * @type {KarteContentDetailRanchDto}
     * @memberof KarteContentDetailDto
     */
    ranch?: KarteContentDetailRanchDto;
    /**
     * 
     * @type {KarteContentDetailSummaryDto}
     * @memberof KarteContentDetailDto
     */
    summary?: KarteContentDetailSummaryDto;
}
/**
 * 
 * @export
 * @interface KarteContentDetailEventDto
 */
export interface KarteContentDetailEventDto {
    /**
     * 
     * @type {number}
     * @memberof KarteContentDetailEventDto
     */
    breath_count?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof KarteContentDetailEventDto
     */
    descriptions?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof KarteContentDetailEventDto
     */
    heart_rate?: number;
    /**
     * 
     * @type {string}
     * @memberof KarteContentDetailEventDto
     */
    medical_type?: string;
    /**
     * 
     * @type {number}
     * @memberof KarteContentDetailEventDto
     */
    temperature_x10?: number;
    /**
     * 
     * @type {Array<KarteContentDetailTreatDto>}
     * @memberof KarteContentDetailEventDto
     */
    treats?: Array<KarteContentDetailTreatDto>;
    /**
     * 
     * @type {Array<KarteContentDetailVisitFeeDto>}
     * @memberof KarteContentDetailEventDto
     */
    visit_fees?: Array<KarteContentDetailVisitFeeDto>;
    /**
     * 
     * @type {string}
     * @memberof KarteContentDetailEventDto
     */
    washout_meat?: string;
    /**
     * 
     * @type {string}
     * @memberof KarteContentDetailEventDto
     */
    washout_milk?: string;
    /**
     * 
     * @type {string}
     * @memberof KarteContentDetailEventDto
     */
    watched_at?: string;
    /**
     * 
     * @type {string}
     * @memberof KarteContentDetailEventDto
     */
    watched_by_name?: string;
}
/**
 * 
 * @export
 * @interface KarteContentDetailRanchDto
 */
export interface KarteContentDetailRanchDto {
    /**
     * 
     * @type {string}
     * @memberof KarteContentDetailRanchDto
     */
    ranch_code?: string;
    /**
     * 
     * @type {string}
     * @memberof KarteContentDetailRanchDto
     */
    ranch_name?: string;
}
/**
 * 
 * @export
 * @interface KarteContentDetailSummaryDto
 */
export interface KarteContentDetailSummaryDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof KarteContentDetailSummaryDto
     */
    causes?: Array<string>;
    /**
     * 
     * @type {Array<KarteContentDetailDiseaseDto>}
     * @memberof KarteContentDetailSummaryDto
     */
    diseases?: Array<KarteContentDetailDiseaseDto>;
    /**
     * 
     * @type {string}
     * @memberof KarteContentDetailSummaryDto
     */
    first_watched_at?: string;
    /**
     * 
     * @type {string}
     * @memberof KarteContentDetailSummaryDto
     */
    found_at?: string;
    /**
     * 
     * @type {string}
     * @memberof KarteContentDetailSummaryDto
     */
    last_watched_at?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof KarteContentDetailSummaryDto
     */
    notices?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof KarteContentDetailSummaryDto
     */
    outcome?: string;
    /**
     * 
     * @type {string}
     * @memberof KarteContentDetailSummaryDto
     */
    outcome_day?: string;
    /**
     * 
     * @type {number}
     * @memberof KarteContentDetailSummaryDto
     */
    watched_count?: number;
}
/**
 * 
 * @export
 * @interface KarteContentDetailTreatDto
 */
export interface KarteContentDetailTreatDto {
    /**
     * 
     * @type {boolean}
     * @memberof KarteContentDetailTreatDto
     */
    _benefit?: boolean;
    /**
     * 
     * @type {number}
     * @memberof KarteContentDetailTreatDto
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof KarteContentDetailTreatDto
     */
    fee?: number;
    /**
     * 
     * @type {number}
     * @memberof KarteContentDetailTreatDto
     */
    medicine_price?: number;
    /**
     * 
     * @type {string}
     * @memberof KarteContentDetailTreatDto
     */
    medicine_route_name?: string;
    /**
     * 
     * @type {string}
     * @memberof KarteContentDetailTreatDto
     */
    treat_name?: string;
    /**
     * 
     * @type {string}
     * @memberof KarteContentDetailTreatDto
     */
    unit?: string;
}
/**
 * 
 * @export
 * @interface KarteContentDetailVisitFeeDto
 */
export interface KarteContentDetailVisitFeeDto {
    /**
     * 
     * @type {boolean}
     * @memberof KarteContentDetailVisitFeeDto
     */
    _benefit?: boolean;
    /**
     * 
     * @type {number}
     * @memberof KarteContentDetailVisitFeeDto
     */
    fee?: number;
    /**
     * 
     * @type {number}
     * @memberof KarteContentDetailVisitFeeDto
     */
    fee_type?: number;
}
/**
 * 
 * @export
 * @interface KarteContentDto
 */
export interface KarteContentDto {
    /**
     * 
     * @type {KarteContentCoverDto}
     * @memberof KarteContentDto
     */
    cover?: KarteContentCoverDto;
    /**
     * 
     * @type {Array<KarteContentDetailDto>}
     * @memberof KarteContentDto
     */
    details?: Array<KarteContentDetailDto>;
}
/**
 * 
 * @export
 * @interface KarteListDto
 */
export interface KarteListDto {
    /**
     * 
     * @type {number}
     * @memberof KarteListDto
     */
    cow_id: number;
    /**
     * 
     * @type {string}
     * @memberof KarteListDto
     */
    local_no?: string;
    /**
     * 
     * @type {string}
     * @memberof KarteListDto
     */
    name?: string;
    /**
     * 
     * @type {Array<KarteListSeriesDto>}
     * @memberof KarteListDto
     */
    series: Array<KarteListSeriesDto>;
    /**
     * 
     * @type {string}
     * @memberof KarteListDto
     */
    trace_id: string;
}
/**
 * 
 * @export
 * @interface KarteListReq
 */
export interface KarteListReq {
    /**
     * 検診記録を取得する（default: true）
     * @type {boolean}
     * @memberof KarteListReq
     */
    breeding?: boolean;
    /**
     * 
     * @type {number}
     * @memberof KarteListReq
     */
    clinic_id: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof KarteListReq
     */
    cow_ids: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof KarteListReq
     */
    from: string;
    /**
     * 診療で転帰が「治癒」の場合、転帰日を終診日の何日後とするか（default: 0）
     * @type {number}
     * @memberof KarteListReq
     */
    healing_days?: number;
    /**
     * 
     * @type {number}
     * @memberof KarteListReq
     */
    ranch_id: number;
    /**
     * 診療記録を取得する（default: true）
     * @type {boolean}
     * @memberof KarteListReq
     */
    symptom?: boolean;
    /**
     * 
     * @type {string}
     * @memberof KarteListReq
     */
    to: string;
}
/**
 * 
 * @export
 * @interface KarteListSeriesDto
 */
export interface KarteListSeriesDto {
    /**
     * 
     * @type {string}
     * @memberof KarteListSeriesDto
     */
    event_kind: KarteListSeriesDtoEventKindEnum;
    /**
     * 
     * @type {Array<KarteListSeriesEventDto>}
     * @memberof KarteListSeriesDto
     */
    events: Array<KarteListSeriesEventDto>;
    /**
     * 
     * @type {string}
     * @memberof KarteListSeriesDto
     */
    found_at: string;
    /**
     * 転帰日以降に別の記録が存在するか
     * @type {boolean}
     * @memberof KarteListSeriesDto
     */
    has_next: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum KarteListSeriesDtoEventKindEnum {
    SYMPTOM = 'SYMPTOM',
    BREEDING = 'BREEDING'
}

/**
 * 
 * @export
 * @interface KarteListSeriesEventDto
 */
export interface KarteListSeriesEventDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof KarteListSeriesEventDto
     */
    diseases: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof KarteListSeriesEventDto
     */
    event_id: number;
    /**
     * 
     * @type {number}
     * @memberof KarteListSeriesEventDto
     */
    status?: number;
    /**
     * 
     * @type {Array<KarteListTreatDto>}
     * @memberof KarteListSeriesEventDto
     */
    treats: Array<KarteListTreatDto>;
    /**
     * 
     * @type {string}
     * @memberof KarteListSeriesEventDto
     */
    watched_at: string;
    /**
     * 
     * @type {string}
     * @memberof KarteListSeriesEventDto
     */
    watched_by: string;
    /**
     * 
     * @type {string}
     * @memberof KarteListSeriesEventDto
     */
    watched_by_name?: string;
}
/**
 * 
 * @export
 * @interface KarteListTreatDto
 */
export interface KarteListTreatDto {
    /**
     * 
     * @type {number}
     * @memberof KarteListTreatDto
     */
    benefit_type: number;
    /**
     * treat_no または detail_no
     * @type {number}
     * @memberof KarteListTreatDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof KarteListTreatDto
     */
    medicine_name?: string;
    /**
     * 
     * @type {string}
     * @memberof KarteListTreatDto
     */
    treat_item_name?: string;
}
/**
 * 
 * @export
 * @interface ModifyReqTreat
 */
export interface ModifyReqTreat {
    /**
     * 
     * @type {number}
     * @memberof ModifyReqTreat
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof ModifyReqTreat
     */
    as_default_amount?: number;
    /**
     * 
     * @type {number}
     * @memberof ModifyReqTreat
     */
    as_default_route?: number;
    /**
     * 診療・検診でのみ使用
     * @type {number}
     * @memberof ModifyReqTreat
     */
    benefit_type?: number;
    /**
     * 予防の投薬でのみ使用
     * @type {string}
     * @memberof ModifyReqTreat
     */
    expiring_day?: string;
    /**
     * 1: 出荷制限の対象にしない
     * @type {number}
     * @memberof ModifyReqTreat
     */
    ignores_washout?: number;
    /**
     * 予防の投薬でのみ使用
     * @type {string}
     * @memberof ModifyReqTreat
     */
    lot_no?: string;
    /**
     * 
     * @type {number}
     * @memberof ModifyReqTreat
     */
    medicine_id?: number;
    /**
     * 新規登録時の薬品・処置数量単位。new_medicine_name指定時は必須
     * @type {string}
     * @memberof ModifyReqTreat
     */
    new_item_unit?: string;
    /**
     * 新規登録時の薬品分類。new_medicine_name指定時は必須
     * @type {number}
     * @memberof ModifyReqTreat
     */
    new_medicine_category?: number;
    /**
     * 新規登録時の薬品名。medicine_idが指定されているときは無視する
     * @type {string}
     * @memberof ModifyReqTreat
     */
    new_medicine_name?: string;
    /**
     * 新規登録時の処置名。treat_item_noが指定されているときは無視する
     * @type {string}
     * @memberof ModifyReqTreat
     */
    new_treat_item_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ModifyReqTreat
     */
    note?: string;
    /**
     * 
     * @type {number}
     * @memberof ModifyReqTreat
     */
    route_fee?: number;
    /**
     * 
     * @type {number}
     * @memberof ModifyReqTreat
     */
    route_id?: number;
    /**
     * 
     * @type {number}
     * @memberof ModifyReqTreat
     */
    team_id: number;
    /**
     * 
     * @type {number}
     * @memberof ModifyReqTreat
     */
    treat_item_no?: number;
    /**
     * 
     * @type {number}
     * @memberof ModifyReqTreat
     */
    treat_kind_no?: number;
    /**
     * 
     * @type {number}
     * @memberof ModifyReqTreat
     */
    unit_price: number;
}
/**
 * 
 * @export
 * @interface ModifyReqVisit
 */
export interface ModifyReqVisit {
    /**
     * 
     * @type {number}
     * @memberof ModifyReqVisit
     */
    clinic_id: number;
    /**
     * 
     * @type {number}
     * @memberof ModifyReqVisit
     */
    distance_km?: number;
    /**
     * 
     * @type {Array<ModifyReqVisitFee>}
     * @memberof ModifyReqVisit
     */
    fees: Array<ModifyReqVisitFee>;
}
/**
 * 
 * @export
 * @interface ModifyReqVisitFee
 */
export interface ModifyReqVisitFee {
    /**
     * 
     * @type {Array<number>}
     * @memberof ModifyReqVisitFee
     */
    cow_ids: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ModifyReqVisitFee
     */
    fee_no: number;
    /**
     * 
     * @type {number}
     * @memberof ModifyReqVisitFee
     */
    fee_type: number;
    /**
     * 
     * @type {number}
     * @memberof ModifyReqVisitFee
     */
    is_benefit: number;
}
/**
 * 
 * @export
 * @interface NoteDeleteReq
 */
export interface NoteDeleteReq {
    /**
     * 
     * @type {number}
     * @memberof NoteDeleteReq
     */
    note_id: number;
    /**
     * 
     * @type {number}
     * @memberof NoteDeleteReq
     */
    team_id: number;
}
/**
 * 
 * @export
 * @interface NoteDto
 */
export interface NoteDto {
    /**
     * created_at
     * @type {number}
     * @memberof NoteDto
     */
    created_at: number;
    /**
     * created_by
     * @type {string}
     * @memberof NoteDto
     */
    created_by: string;
    /**
     * created_by_name
     * @type {string}
     * @memberof NoteDto
     */
    created_by_name: string;
    /**
     * note
     * @type {string}
     * @memberof NoteDto
     */
    note: string;
    /**
     * note_id
     * @type {number}
     * @memberof NoteDto
     */
    note_id: number;
    /**
     * updated_at
     * @type {number}
     * @memberof NoteDto
     */
    updated_at: number;
}
/**
 * 
 * @export
 * @interface NoteListReq
 */
export interface NoteListReq {
    /**
     * count
     * @type {number}
     * @memberof NoteListReq
     */
    count?: number;
    /**
     * page
     * @type {number}
     * @memberof NoteListReq
     */
    page?: number;
    /**
     * team_id
     * @type {number}
     * @memberof NoteListReq
     */
    team_id: number;
}
/**
 * 
 * @export
 * @interface NoteModifyReq
 */
export interface NoteModifyReq {
    /**
     * is_new
     * @type {number}
     * @memberof NoteModifyReq
     */
    is_new: number;
    /**
     * note
     * @type {string}
     * @memberof NoteModifyReq
     */
    note: string;
    /**
     * note_id
     * @type {number}
     * @memberof NoteModifyReq
     */
    note_id?: number;
    /**
     * team_id
     * @type {number}
     * @memberof NoteModifyReq
     */
    team_id: number;
}
/**
 * 
 * @export
 * @interface OpuDto
 */
export interface OpuDto {
    /**
     * 
     * @type {string}
     * @memberof OpuDto
     */
    comment: string;
    /**
     * 
     * @type {number}
     * @memberof OpuDto
     */
    cow_id: number;
    /**
     * 
     * @type {number}
     * @memberof OpuDto
     */
    event_id: number;
    /**
     * 
     * @type {Array<OpuListIvfDto>}
     * @memberof OpuDto
     */
    ivfs: Array<OpuListIvfDto>;
    /**
     * 
     * @type {Array<OpuRankDto>}
     * @memberof OpuDto
     */
    ranks: Array<OpuRankDto>;
    /**
     * 
     * @type {string}
     * @memberof OpuDto
     */
    watched_at: string;
    /**
     * 
     * @type {string}
     * @memberof OpuDto
     */
    watched_by: string;
}
/**
 * 
 * @export
 * @interface OpuListDto
 */
export interface OpuListDto {
    /**
     * 
     * @type {number}
     * @memberof OpuListDto
     */
    event_id: number;
    /**
     * 
     * @type {Array<OpuListIvfDto>}
     * @memberof OpuListDto
     */
    ivfs: Array<OpuListIvfDto>;
    /**
     * 
     * @type {Array<OpuRankDto>}
     * @memberof OpuListDto
     */
    ranks: Array<OpuRankDto>;
    /**
     * 
     * @type {string}
     * @memberof OpuListDto
     */
    watched_at: string;
    /**
     * 
     * @type {string}
     * @memberof OpuListDto
     */
    watched_by: string;
}
/**
 * 
 * @export
 * @interface OpuListIvfDto
 */
export interface OpuListIvfDto {
    /**
     * 
     * @type {number}
     * @memberof OpuListIvfDto
     */
    event_id: number;
    /**
     * 
     * @type {string}
     * @memberof OpuListIvfDto
     */
    label_no: string;
    /**
     * 
     * @type {Array<IvfRankDto>}
     * @memberof OpuListIvfDto
     */
    ranks: Array<IvfRankDto>;
    /**
     * 
     * @type {number}
     * @memberof OpuListIvfDto
     */
    seed_lot_id: number;
    /**
     * 
     * @type {string}
     * @memberof OpuListIvfDto
     */
    watched_at: string;
    /**
     * 
     * @type {string}
     * @memberof OpuListIvfDto
     */
    watched_by: string;
}
/**
 * 
 * @export
 * @interface OpuListReq
 */
export interface OpuListReq {
    /**
     * 
     * @type {number}
     * @memberof OpuListReq
     */
    cow_id: number;
    /**
     * 
     * @type {string}
     * @memberof OpuListReq
     */
    from: string;
    /**
     * 
     * @type {number}
     * @memberof OpuListReq
     */
    ranch_id: number;
    /**
     * 
     * @type {string}
     * @memberof OpuListReq
     */
    to?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OpuListReq
     */
    valid?: boolean;
}
/**
 * 
 * @export
 * @interface OpuModifyReq
 */
export interface OpuModifyReq {
    /**
     * 
     * @type {Array<OpuModifyReqCow>}
     * @memberof OpuModifyReq
     */
    cows: Array<OpuModifyReqCow>;
    /**
     * 
     * @type {number}
     * @memberof OpuModifyReq
     */
    is_new: number;
    /**
     * 
     * @type {number}
     * @memberof OpuModifyReq
     */
    ranch_id: number;
    /**
     * 
     * @type {number}
     * @memberof OpuModifyReq
     */
    schedule_id?: number;
    /**
     * 
     * @type {string}
     * @memberof OpuModifyReq
     */
    watched_at: string;
}
/**
 * 
 * @export
 * @interface OpuModifyReqCow
 */
export interface OpuModifyReqCow {
    /**
     * 
     * @type {string}
     * @memberof OpuModifyReqCow
     */
    comment: string;
    /**
     * 
     * @type {number}
     * @memberof OpuModifyReqCow
     */
    cow_id: number;
    /**
     * 
     * @type {number}
     * @memberof OpuModifyReqCow
     */
    event_id?: number;
    /**
     * 
     * @type {Array<OpuModifyReqRank>}
     * @memberof OpuModifyReqCow
     */
    ranks: Array<OpuModifyReqRank>;
}
/**
 * 
 * @export
 * @interface OpuModifyReqRank
 */
export interface OpuModifyReqRank {
    /**
     * 
     * @type {number}
     * @memberof OpuModifyReqRank
     */
    egg_count: number;
    /**
     * 
     * @type {number}
     * @memberof OpuModifyReqRank
     */
    egg_count_l?: number;
    /**
     * 
     * @type {number}
     * @memberof OpuModifyReqRank
     */
    ovum_rank: number;
}
/**
 * 
 * @export
 * @interface OpuRankDto
 */
export interface OpuRankDto {
    /**
     * 
     * @type {number}
     * @memberof OpuRankDto
     */
    egg_count: number;
    /**
     * 
     * @type {number}
     * @memberof OpuRankDto
     */
    egg_count_l?: number;
    /**
     * 
     * @type {number}
     * @memberof OpuRankDto
     */
    ovum_rank: number;
}
/**
 * 
 * @export
 * @interface OtherPlDeleteReq
 */
export interface OtherPlDeleteReq {
    /**
     * 
     * @type {number}
     * @memberof OtherPlDeleteReq
     */
    cow_id?: number;
    /**
     * 
     * @type {number}
     * @memberof OtherPlDeleteReq
     */
    event_id: number;
    /**
     * 
     * @type {number}
     * @memberof OtherPlDeleteReq
     */
    profit_loss: number;
    /**
     * 
     * @type {number}
     * @memberof OtherPlDeleteReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface OtherPlDto
 */
export interface OtherPlDto {
    /**
     * 
     * @type {string}
     * @memberof OtherPlDto
     */
    comment: string;
    /**
     * 
     * @type {number}
     * @memberof OtherPlDto
     */
    cow_id?: number;
    /**
     * 
     * @type {number}
     * @memberof OtherPlDto
     */
    event_id: number;
    /**
     * 
     * @type {number}
     * @memberof OtherPlDto
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof OtherPlDto
     */
    profit_loss: number;
    /**
     * 
     * @type {number}
     * @memberof OtherPlDto
     */
    ranch_id: number;
    /**
     * 
     * @type {string}
     * @memberof OtherPlDto
     */
    watched_at: string;
    /**
     * 
     * @type {string}
     * @memberof OtherPlDto
     */
    watched_by: string;
}
/**
 * 
 * @export
 * @interface OtherPlEventDto
 */
export interface OtherPlEventDto {
    /**
     * 
     * @type {string}
     * @memberof OtherPlEventDto
     */
    comment: string;
    /**
     * 
     * @type {number}
     * @memberof OtherPlEventDto
     */
    cow_id?: number;
    /**
     * 
     * @type {string}
     * @memberof OtherPlEventDto
     */
    created_by: string;
    /**
     * 
     * @type {number}
     * @memberof OtherPlEventDto
     */
    event_id: number;
    /**
     * 
     * @type {number}
     * @memberof OtherPlEventDto
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof OtherPlEventDto
     */
    watched_at: string;
    /**
     * 
     * @type {string}
     * @memberof OtherPlEventDto
     */
    watched_by_name: string;
}
/**
 * 
 * @export
 * @interface OtherPlModifyReq
 */
export interface OtherPlModifyReq {
    /**
     * 
     * @type {Array<OtherPlModifyReqCow>}
     * @memberof OtherPlModifyReq
     */
    cows: Array<OtherPlModifyReqCow>;
    /**
     * 
     * @type {number}
     * @memberof OtherPlModifyReq
     */
    event_id?: number;
    /**
     * 
     * @type {number}
     * @memberof OtherPlModifyReq
     */
    is_new: number;
    /**
     * 
     * @type {number}
     * @memberof OtherPlModifyReq
     */
    profit_loss: number;
    /**
     * 
     * @type {number}
     * @memberof OtherPlModifyReq
     */
    ranch_id: number;
    /**
     * 
     * @type {string}
     * @memberof OtherPlModifyReq
     */
    watched_at: string;
}
/**
 * 
 * @export
 * @interface OtherPlModifyReqCow
 */
export interface OtherPlModifyReqCow {
    /**
     * 
     * @type {string}
     * @memberof OtherPlModifyReqCow
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof OtherPlModifyReqCow
     */
    cow_id?: number;
    /**
     * 
     * @type {number}
     * @memberof OtherPlModifyReqCow
     */
    price: number;
}
/**
 * 
 * @export
 * @interface PartnerDto
 */
export interface PartnerDto {
    /**
     * 
     * @type {number}
     * @memberof PartnerDto
     */
    disp_no: number;
    /**
     * 
     * @type {number}
     * @memberof PartnerDto
     */
    is_client: number;
    /**
     * 
     * @type {number}
     * @memberof PartnerDto
     */
    is_supplier: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerDto
     */
    partner_no: number;
    /**
     * 
     * @type {number}
     * @memberof PartnerDto
     */
    team_id: number;
}
/**
 * 
 * @export
 * @interface PartnerListReq
 */
export interface PartnerListReq {
    /**
     * 
     * @type {number}
     * @memberof PartnerListReq
     */
    is_client: number;
    /**
     * 
     * @type {number}
     * @memberof PartnerListReq
     */
    is_supplier: number;
    /**
     * 
     * @type {number}
     * @memberof PartnerListReq
     */
    team_id: number;
}
/**
 * 
 * @export
 * @interface PartnerModifyReq
 */
export interface PartnerModifyReq {
    /**
     * 
     * @type {number}
     * @memberof PartnerModifyReq
     */
    is_client: number;
    /**
     * 
     * @type {number}
     * @memberof PartnerModifyReq
     */
    is_supplier: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerModifyReq
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerModifyReq
     */
    partner_no?: number;
    /**
     * 
     * @type {number}
     * @memberof PartnerModifyReq
     */
    team_id: number;
}
/**
 * 
 * @export
 * @interface PartnerSortReq
 */
export interface PartnerSortReq {
    /**
     * 
     * @type {number}
     * @memberof PartnerSortReq
     */
    is_client: number;
    /**
     * 
     * @type {number}
     * @memberof PartnerSortReq
     */
    is_supplier: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof PartnerSortReq
     */
    order: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof PartnerSortReq
     */
    team_id: number;
}
/**
 * 
 * @export
 * @interface PreloadedCowProgramReq
 */
export interface PreloadedCowProgramReq {
    /**
     * 
     * @type {Array<PreloadedCowProgramReqItem>}
     * @memberof PreloadedCowProgramReq
     */
    list: Array<PreloadedCowProgramReqItem>;
    /**
     * 
     * @type {Array<PreloadedProgramReqTagItem>}
     * @memberof PreloadedCowProgramReq
     */
    tags: Array<PreloadedProgramReqTagItem>;
}
/**
 * 
 * @export
 * @interface PreloadedCowProgramReqItem
 */
export interface PreloadedCowProgramReqItem {
    /**
     * 
     * @type {number}
     * @memberof PreloadedCowProgramReqItem
     */
    clinic_id?: number;
    /**
     * 
     * @type {number}
     * @memberof PreloadedCowProgramReqItem
     */
    event_kind_no: number;
    /**
     * 
     * @type {number}
     * @memberof PreloadedCowProgramReqItem
     */
    preset_id?: number;
    /**
     * 
     * @type {string}
     * @memberof PreloadedCowProgramReqItem
     */
    scheduled_date: string;
    /**
     * 
     * @type {string}
     * @memberof PreloadedCowProgramReqItem
     */
    title: string;
}
/**
 * 
 * @export
 * @interface PreloadedProgramReq
 */
export interface PreloadedProgramReq {
    /**
     * 
     * @type {Array<PreloadedProgramReqItem>}
     * @memberof PreloadedProgramReq
     */
    list: Array<PreloadedProgramReqItem>;
    /**
     * 
     * @type {Array<PreloadedProgramReqTag>}
     * @memberof PreloadedProgramReq
     */
    tags: Array<PreloadedProgramReqTag>;
}
/**
 * 
 * @export
 * @interface PreloadedProgramReqItem
 */
export interface PreloadedProgramReqItem {
    /**
     * 
     * @type {number}
     * @memberof PreloadedProgramReqItem
     */
    clinic_id?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof PreloadedProgramReqItem
     */
    cow_ids: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof PreloadedProgramReqItem
     */
    event_kind_no: number;
    /**
     * 
     * @type {number}
     * @memberof PreloadedProgramReqItem
     */
    preset_id?: number;
    /**
     * 
     * @type {string}
     * @memberof PreloadedProgramReqItem
     */
    scheduled_date: string;
    /**
     * 
     * @type {string}
     * @memberof PreloadedProgramReqItem
     */
    title: string;
}
/**
 * 
 * @export
 * @interface PreloadedProgramReqTag
 */
export interface PreloadedProgramReqTag {
    /**
     * 
     * @type {number}
     * @memberof PreloadedProgramReqTag
     */
    cow_id: number;
    /**
     * 
     * @type {Array<PreloadedProgramReqTagItem>}
     * @memberof PreloadedProgramReqTag
     */
    tags: Array<PreloadedProgramReqTagItem>;
}
/**
 * 
 * @export
 * @interface PreloadedProgramReqTagItem
 */
export interface PreloadedProgramReqTagItem {
    /**
     * 
     * @type {number}
     * @memberof PreloadedProgramReqTagItem
     */
    action_kind: number;
    /**
     * 
     * @type {number}
     * @memberof PreloadedProgramReqTagItem
     */
    tag_id: number;
}
/**
 * 
 * @export
 * @interface PreventionCertVaccinationReq
 */
export interface PreventionCertVaccinationReq {
    /**
     * 診療所住所
     * @type {string}
     * @memberof PreventionCertVaccinationReq
     */
    clinic_address: string;
    /**
     * 診療所名
     * @type {string}
     * @memberof PreventionCertVaccinationReq
     */
    clinic_name: string;
    /**
     * 牛IDリスト
     * @type {Array<PreventionCertVaccinationReqCow>}
     * @memberof PreventionCertVaccinationReq
     */
    cows: Array<PreventionCertVaccinationReqCow>;
    /**
     * 獣医師名
     * @type {string}
     * @memberof PreventionCertVaccinationReq
     */
    doctor_name: string;
    /**
     * Lot No.を出力するか
     * @type {boolean}
     * @memberof PreventionCertVaccinationReq
     */
    has_lot_no: boolean;
    /**
     * 発行日
     * @type {string}
     * @memberof PreventionCertVaccinationReq
     */
    published_on: string;
    /**
     * 牧場ID
     * @type {number}
     * @memberof PreventionCertVaccinationReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface PreventionCertVaccinationReqCow
 */
export interface PreventionCertVaccinationReqCow {
    /**
     * 牛ID
     * @type {number}
     * @memberof PreventionCertVaccinationReqCow
     */
    cow_id: number;
    /**
     * 使用製品リスト
     * @type {Array<PreventionCertVaccinationReqCowVaccination>}
     * @memberof PreventionCertVaccinationReqCow
     */
    vaccinations: Array<PreventionCertVaccinationReqCowVaccination>;
}
/**
 * 
 * @export
 * @interface PreventionCertVaccinationReqCowVaccination
 */
export interface PreventionCertVaccinationReqCowVaccination {
    /**
     * 有効期限
     * @type {string}
     * @memberof PreventionCertVaccinationReqCowVaccination
     */
    expire_on?: string;
    /**
     * 接種年月日
     * @type {string}
     * @memberof PreventionCertVaccinationReqCowVaccination
     */
    inoculated_on: string;
    /**
     * Lot No.
     * @type {string}
     * @memberof PreventionCertVaccinationReqCowVaccination
     */
    lot_no?: string;
    /**
     * 使用製品名
     * @type {string}
     * @memberof PreventionCertVaccinationReqCowVaccination
     */
    name: string;
}
/**
 * 
 * @export
 * @interface PreventionDetailDto
 */
export interface PreventionDetailDto {
    /**
     * 
     * @type {number}
     * @memberof PreventionDetailDto
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof PreventionDetailDto
     */
    detail_no: number;
    /**
     * 
     * @type {string}
     * @memberof PreventionDetailDto
     */
    expiring_day?: string;
    /**
     * 
     * @type {string}
     * @memberof PreventionDetailDto
     */
    lot_no?: string;
    /**
     * 
     * @type {number}
     * @memberof PreventionDetailDto
     */
    medicine_id?: number;
    /**
     * 
     * @type {string}
     * @memberof PreventionDetailDto
     */
    note?: string;
    /**
     * 
     * @type {number}
     * @memberof PreventionDetailDto
     */
    route_fee?: number;
    /**
     * 
     * @type {number}
     * @memberof PreventionDetailDto
     */
    route_id?: number;
    /**
     * 
     * @type {number}
     * @memberof PreventionDetailDto
     */
    team_id: number;
    /**
     * 
     * @type {number}
     * @memberof PreventionDetailDto
     */
    treat_item_no?: number;
    /**
     * 
     * @type {number}
     * @memberof PreventionDetailDto
     */
    treat_kind_no?: number;
    /**
     * 
     * @type {number}
     * @memberof PreventionDetailDto
     */
    unit_price: number;
}
/**
 * 
 * @export
 * @interface PreventionDto
 */
export interface PreventionDto {
    /**
     * 
     * @type {string}
     * @memberof PreventionDto
     */
    comment: string;
    /**
     * 
     * @type {number}
     * @memberof PreventionDto
     */
    cow_id: number;
    /**
     * 
     * @type {Array<PreventionDetailDto>}
     * @memberof PreventionDto
     */
    detail: Array<PreventionDetailDto>;
    /**
     * 
     * @type {string}
     * @memberof PreventionDto
     */
    local_no?: string;
    /**
     * 
     * @type {string}
     * @memberof PreventionDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof PreventionDto
     */
    prevention_id: number;
    /**
     * 
     * @type {number}
     * @memberof PreventionDto
     */
    ranch_id: number;
    /**
     * 
     * @type {string}
     * @memberof PreventionDto
     */
    trace_id: string;
    /**
     * 
     * @type {string}
     * @memberof PreventionDto
     */
    watched_at: string;
    /**
     * 
     * @type {string}
     * @memberof PreventionDto
     */
    watched_by: string;
}
/**
 * 
 * @export
 * @interface PreventionModifyReq
 */
export interface PreventionModifyReq {
    /**
     * 
     * @type {string}
     * @memberof PreventionModifyReq
     */
    comment?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof PreventionModifyReq
     */
    cow_ids: Array<number>;
    /**
     * 
     * @type {Array<ModifyReqTreat>}
     * @memberof PreventionModifyReq
     */
    detail: Array<ModifyReqTreat>;
    /**
     * 
     * @type {number}
     * @memberof PreventionModifyReq
     */
    is_deleted: number;
    /**
     * 
     * @type {number}
     * @memberof PreventionModifyReq
     */
    is_new: number;
    /**
     * 
     * @type {Array<EventNextScheduleReq>}
     * @memberof PreventionModifyReq
     */
    plans?: Array<EventNextScheduleReq>;
    /**
     * 
     * @type {PreloadedProgramReq}
     * @memberof PreventionModifyReq
     */
    preloaded_program: PreloadedProgramReq;
    /**
     * 
     * @type {number}
     * @memberof PreventionModifyReq
     */
    prevention_id?: number;
    /**
     * 
     * @type {number}
     * @memberof PreventionModifyReq
     */
    ranch_id: number;
    /**
     * 
     * @type {number}
     * @memberof PreventionModifyReq
     */
    schedule_id?: number;
    /**
     * 
     * @type {string}
     * @memberof PreventionModifyReq
     */
    watched_at: string;
}
/**
 * 
 * @export
 * @interface PreventionTreatListDto
 */
export interface PreventionTreatListDto {
    /**
     * 
     * @type {number}
     * @memberof PreventionTreatListDto
     */
    cow_id: number;
    /**
     * 
     * @type {string}
     * @memberof PreventionTreatListDto
     */
    local_no?: string;
    /**
     * 
     * @type {Array<PreventionTreatListDtoMedicine>}
     * @memberof PreventionTreatListDto
     */
    medicines: Array<PreventionTreatListDtoMedicine>;
    /**
     * 
     * @type {string}
     * @memberof PreventionTreatListDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof PreventionTreatListDto
     */
    ranch_id: number;
    /**
     * 
     * @type {string}
     * @memberof PreventionTreatListDto
     */
    trace_id: string;
}
/**
 * 
 * @export
 * @interface PreventionTreatListDtoMedicine
 */
export interface PreventionTreatListDtoMedicine {
    /**
     * 
     * @type {number}
     * @memberof PreventionTreatListDtoMedicine
     */
    detail_no: number;
    /**
     * 
     * @type {string}
     * @memberof PreventionTreatListDtoMedicine
     */
    expiring_day?: string;
    /**
     * 
     * @type {string}
     * @memberof PreventionTreatListDtoMedicine
     */
    lot_no?: string;
    /**
     * 
     * @type {number}
     * @memberof PreventionTreatListDtoMedicine
     */
    medicine_id: number;
    /**
     * 
     * @type {string}
     * @memberof PreventionTreatListDtoMedicine
     */
    medicine_name: string;
    /**
     * 
     * @type {string}
     * @memberof PreventionTreatListDtoMedicine
     */
    watched_at: string;
}
/**
 * 
 * @export
 * @interface PreventionTreatListReq
 */
export interface PreventionTreatListReq {
    /**
     * 牛IDリスト
     * @type {Array<number>}
     * @memberof PreventionTreatListReq
     */
    cow_ids: Array<number>;
    /**
     * 薬品カテゴリ
     * @type {number}
     * @memberof PreventionTreatListReq
     */
    medicine_category: number;
    /**
     * 牧場ID
     * @type {number}
     * @memberof PreventionTreatListReq
     */
    ranch_id: number;
    /**
     * 検索範囲(開始日)
     * @type {string}
     * @memberof PreventionTreatListReq
     */
    search_from: string;
    /**
     * 検索範囲(終了日)
     * @type {string}
     * @memberof PreventionTreatListReq
     */
    search_to: string;
}
/**
 * 
 * @export
 * @interface ProgramAppliedItemsReqBreedingModifyReq
 */
export interface ProgramAppliedItemsReqBreedingModifyReq {
    /**
     * 
     * @type {number}
     * @memberof ProgramAppliedItemsReqBreedingModifyReq
     */
    clinic_id?: number;
    /**
     * 
     * @type {BreedingModifyReq}
     * @memberof ProgramAppliedItemsReqBreedingModifyReq
     */
    eventReq?: BreedingModifyReq;
}
/**
 * 
 * @export
 * @interface ProgramAppliedItemsReqCowModifyReq
 */
export interface ProgramAppliedItemsReqCowModifyReq {
    /**
     * 
     * @type {number}
     * @memberof ProgramAppliedItemsReqCowModifyReq
     */
    clinic_id?: number;
    /**
     * 
     * @type {CowModifyReq}
     * @memberof ProgramAppliedItemsReqCowModifyReq
     */
    eventReq?: CowModifyReq;
}
/**
 * 
 * @export
 * @interface ProgramAppliedItemsReqDeliveryModifyReq
 */
export interface ProgramAppliedItemsReqDeliveryModifyReq {
    /**
     * 
     * @type {number}
     * @memberof ProgramAppliedItemsReqDeliveryModifyReq
     */
    clinic_id?: number;
    /**
     * 
     * @type {DeliveryModifyReq}
     * @memberof ProgramAppliedItemsReqDeliveryModifyReq
     */
    eventReq?: DeliveryModifyReq;
}
/**
 * 
 * @export
 * @interface ProgramAppliedItemsReqPreventionModifyReq
 */
export interface ProgramAppliedItemsReqPreventionModifyReq {
    /**
     * 
     * @type {number}
     * @memberof ProgramAppliedItemsReqPreventionModifyReq
     */
    clinic_id?: number;
    /**
     * 
     * @type {PreventionModifyReq}
     * @memberof ProgramAppliedItemsReqPreventionModifyReq
     */
    eventReq?: PreventionModifyReq;
}
/**
 * 
 * @export
 * @interface ProgramAppliedItemsReqRutModifyReq
 */
export interface ProgramAppliedItemsReqRutModifyReq {
    /**
     * 
     * @type {number}
     * @memberof ProgramAppliedItemsReqRutModifyReq
     */
    clinic_id?: number;
    /**
     * 
     * @type {RutModifyReq}
     * @memberof ProgramAppliedItemsReqRutModifyReq
     */
    eventReq?: RutModifyReq;
}
/**
 * 
 * @export
 * @interface ProgramAppliedItemsReqSymptomBulkModifyReq
 */
export interface ProgramAppliedItemsReqSymptomBulkModifyReq {
    /**
     * 
     * @type {number}
     * @memberof ProgramAppliedItemsReqSymptomBulkModifyReq
     */
    clinic_id?: number;
    /**
     * 
     * @type {SymptomBulkModifyReq}
     * @memberof ProgramAppliedItemsReqSymptomBulkModifyReq
     */
    eventReq?: SymptomBulkModifyReq;
}
/**
 * 
 * @export
 * @interface ProgramAppliedItemsReqSymptomModifyReq
 */
export interface ProgramAppliedItemsReqSymptomModifyReq {
    /**
     * 
     * @type {number}
     * @memberof ProgramAppliedItemsReqSymptomModifyReq
     */
    clinic_id?: number;
    /**
     * 
     * @type {SymptomModifyReq}
     * @memberof ProgramAppliedItemsReqSymptomModifyReq
     */
    eventReq?: SymptomModifyReq;
}
/**
 * 
 * @export
 * @interface ProgramApplyReq
 */
export interface ProgramApplyReq {
    /**
     * 
     * @type {string}
     * @memberof ProgramApplyReq
     */
    base_date?: string;
    /**
     * 診療所プログラムの場合、診療所ID
     * @type {number}
     * @memberof ProgramApplyReq
     */
    clinic_id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProgramApplyReq
     */
    color?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProgramApplyReq
     */
    cow_ids: Array<number>;
    /**
     * 即時生成される予定の内容を確認しない
     * @type {number}
     * @memberof ProgramApplyReq
     */
    force: number;
    /**
     * 既存の記録に対する即時生成は行わない（デフォルト:0=生成する）
     * @type {number}
     * @memberof ProgramApplyReq
     */
    ignores_past_event?: number;
    /**
     * 
     * @type {PreloadedProgramReq}
     * @memberof ProgramApplyReq
     */
    preloaded?: PreloadedProgramReq;
    /**
     * 
     * @type {number}
     * @memberof ProgramApplyReq
     */
    program_id: number;
    /**
     * 診療所プログラムの場合も、適用先の牛の牧場IDを指定
     * @type {number}
     * @memberof ProgramApplyReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface ProgramApplyResultDto
 */
export interface ProgramApplyResultDto {
    /**
     * 
     * @type {Array<ProgramApplyResultItemDto>}
     * @memberof ProgramApplyResultDto
     */
    list: Array<ProgramApplyResultItemDto>;
    /**
     * 
     * @type {Array<ProgramApplyResultTagDto>}
     * @memberof ProgramApplyResultDto
     */
    tags: Array<ProgramApplyResultTagDto>;
}
/**
 * 
 * @export
 * @interface ProgramApplyResultItemDto
 */
export interface ProgramApplyResultItemDto {
    /**
     * 
     * @type {number}
     * @memberof ProgramApplyResultItemDto
     */
    clinic_id?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProgramApplyResultItemDto
     */
    cow_ids: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ProgramApplyResultItemDto
     */
    event_kind_no: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramApplyResultItemDto
     */
    preset_id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProgramApplyResultItemDto
     */
    scheduled_date: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramApplyResultItemDto
     */
    title: string;
}
/**
 * 
 * @export
 * @interface ProgramApplyResultTagDto
 */
export interface ProgramApplyResultTagDto {
    /**
     * 
     * @type {number}
     * @memberof ProgramApplyResultTagDto
     */
    cow_id: number;
    /**
     * 
     * @type {Array<ProgramTagDto>}
     * @memberof ProgramApplyResultTagDto
     */
    tags: Array<ProgramTagDto>;
}
/**
 * 
 * @export
 * @interface ProgramCowDeleteReq
 */
export interface ProgramCowDeleteReq {
    /**
     * 
     * @type {Array<number>}
     * @memberof ProgramCowDeleteReq
     */
    cow_ids: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ProgramCowDeleteReq
     */
    program_id: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramCowDeleteReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface ProgramDeleteReq
 */
export interface ProgramDeleteReq {
    /**
     * 
     * @type {number}
     * @memberof ProgramDeleteReq
     */
    clinic_id?: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramDeleteReq
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramDeleteReq
     */
    ranch_id?: number;
}
/**
 * 
 * @export
 * @interface ProgramDto
 */
export interface ProgramDto {
    /**
     * 
     * @type {number}
     * @memberof ProgramDto
     */
    base_date_kind?: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramDto
     */
    clinic_id?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProgramDto
     */
    cow_ids: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ProgramDto
     */
    is_auto_applied?: number;
    /**
     * 
     * @type {Array<ProgramItemDto>}
     * @memberof ProgramDto
     */
    items: Array<ProgramItemDto>;
    /**
     * 
     * @type {string}
     * @memberof ProgramDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ProgramDto
     */
    program_id: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramDto
     */
    ranch_id?: number;
    /**
     * 
     * @type {Array<ProgramTagDto>}
     * @memberof ProgramDto
     */
    tags: Array<ProgramTagDto>;
    /**
     * 
     * @type {string}
     * @memberof ProgramDto
     */
    trigger_condition?: string;
    /**
     * 
     * @type {number}
     * @memberof ProgramDto
     */
    trigger_event?: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramDto
     */
    trigger_kind: number;
}
/**
 * 
 * @export
 * @interface ProgramItemDto
 */
export interface ProgramItemDto {
    /**
     * 
     * @type {number}
     * @memberof ProgramItemDto
     */
    base_item_no: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramItemDto
     */
    event_kind_no: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramItemDto
     */
    interval_days: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramItemDto
     */
    item_no: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramItemDto
     */
    preset_id?: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramItemDto
     */
    program_id: number;
    /**
     * 
     * @type {string}
     * @memberof ProgramItemDto
     */
    title: string;
}
/**
 * 
 * @export
 * @interface ProgramListReq
 */
export interface ProgramListReq {
    /**
     * 
     * @type {number}
     * @memberof ProgramListReq
     */
    clinic_id?: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramListReq
     */
    ranch_id?: number;
}
/**
 * 
 * @export
 * @interface ProgramModifyReq
 */
export interface ProgramModifyReq {
    /**
     * 
     * @type {number}
     * @memberof ProgramModifyReq
     */
    base_date_kind?: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramModifyReq
     */
    clinic_id?: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramModifyReq
     */
    is_auto_applied?: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramModifyReq
     */
    is_new: number;
    /**
     * 
     * @type {Array<ProgramModifyReqItem>}
     * @memberof ProgramModifyReq
     */
    items: Array<ProgramModifyReqItem>;
    /**
     * 
     * @type {string}
     * @memberof ProgramModifyReq
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ProgramModifyReq
     */
    program_id?: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramModifyReq
     */
    ranch_id?: number;
    /**
     * 
     * @type {Array<ProgramModifyReqTag>}
     * @memberof ProgramModifyReq
     */
    tags?: Array<ProgramModifyReqTag>;
    /**
     * 
     * @type {string}
     * @memberof ProgramModifyReq
     */
    trigger_condition?: string;
    /**
     * 
     * @type {number}
     * @memberof ProgramModifyReq
     */
    trigger_event?: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramModifyReq
     */
    trigger_kind: number;
}
/**
 * 
 * @export
 * @interface ProgramModifyReqItem
 */
export interface ProgramModifyReqItem {
    /**
     * 
     * @type {number}
     * @memberof ProgramModifyReqItem
     */
    base_item_no: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramModifyReqItem
     */
    event_kind_no: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramModifyReqItem
     */
    interval_days: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramModifyReqItem
     */
    item_no: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramModifyReqItem
     */
    preset_id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProgramModifyReqItem
     */
    title: string;
}
/**
 * 
 * @export
 * @interface ProgramModifyReqTag
 */
export interface ProgramModifyReqTag {
    /**
     * 
     * @type {number}
     * @memberof ProgramModifyReqTag
     */
    action_kind: number;
    /**
     * 既存タグの場合必須
     * @type {number}
     * @memberof ProgramModifyReqTag
     */
    tag_id?: number;
    /**
     * 新規タグの場合必須（既存タグでは、指定されたIDのタグが存在しない場合に採用）
     * @type {string}
     * @memberof ProgramModifyReqTag
     */
    tag_name: string;
}
/**
 * 
 * @export
 * @interface ProgramOfCowApplyResultDto
 */
export interface ProgramOfCowApplyResultDto {
    /**
     * 
     * @type {Array<ProgramOfCowApplyResultItem>}
     * @memberof ProgramOfCowApplyResultDto
     */
    list: Array<ProgramOfCowApplyResultItem>;
    /**
     * 
     * @type {Array<ProgramTagDto>}
     * @memberof ProgramOfCowApplyResultDto
     */
    tags: Array<ProgramTagDto>;
}
/**
 * 
 * @export
 * @interface ProgramOfCowApplyResultItem
 */
export interface ProgramOfCowApplyResultItem {
    /**
     * 
     * @type {number}
     * @memberof ProgramOfCowApplyResultItem
     */
    clinic_id?: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramOfCowApplyResultItem
     */
    event_kind_no: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramOfCowApplyResultItem
     */
    preset_id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProgramOfCowApplyResultItem
     */
    scheduled_date: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramOfCowApplyResultItem
     */
    title: string;
}
/**
 * 
 * @export
 * @interface ProgramTagDto
 */
export interface ProgramTagDto {
    /**
     * 
     * @type {number}
     * @memberof ProgramTagDto
     */
    action_kind: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramTagDto
     */
    tag_id: number;
}
/**
 * 
 * @export
 * @interface RanchConstDto
 */
export interface RanchConstDto {
    /**
     * 
     * @type {Array<RanchSellCowAgeDto>}
     * @memberof RanchConstDto
     */
    sell_ages: Array<RanchSellCowAgeDto>;
}
/**
 * 
 * @export
 * @interface RanchConstModifyReq
 */
export interface RanchConstModifyReq {
    /**
     * 
     * @type {number}
     * @memberof RanchConstModifyReq
     */
    ranch_id: number;
    /**
     * 
     * @type {Array<RanchConstModifySellAgeReq>}
     * @memberof RanchConstModifyReq
     */
    sell_ages: Array<RanchConstModifySellAgeReq>;
}
/**
 * 
 * @export
 * @interface RanchConstModifySellAgeReq
 */
export interface RanchConstModifySellAgeReq {
    /**
     * 
     * @type {number}
     * @memberof RanchConstModifySellAgeReq
     */
    age: number;
    /**
     * 
     * @type {number}
     * @memberof RanchConstModifySellAgeReq
     */
    breed_no: number;
    /**
     * 
     * @type {number}
     * @memberof RanchConstModifySellAgeReq
     */
    is_male: number;
    /**
     * 
     * @type {number}
     * @memberof RanchConstModifySellAgeReq
     */
    sell_type: number;
}
/**
 * 
 * @export
 * @interface RanchEventHistoryBaseDto
 */
export interface RanchEventHistoryBaseDto {
    /**
     * day
     * @type {string}
     * @memberof RanchEventHistoryBaseDto
     */
    day: string;
    /**
     * event_id
     * @type {number}
     * @memberof RanchEventHistoryBaseDto
     */
    event_id: number;
    /**
     * event_kind_no
     * @type {number}
     * @memberof RanchEventHistoryBaseDto
     */
    event_kind_no: number;
    /**
     * reg_time
     * @type {string}
     * @memberof RanchEventHistoryBaseDto
     */
    reg_time: string;
}
/**
 * 
 * @export
 * @interface RanchEventHistoryReq
 */
export interface RanchEventHistoryReq {
    /**
     * 
     * @type {number}
     * @memberof RanchEventHistoryReq
     */
    defaultEventCount?: number;
    /**
     * event_count
     * @type {number}
     * @memberof RanchEventHistoryReq
     */
    event_count?: number;
    /**
     * page_no
     * @type {number}
     * @memberof RanchEventHistoryReq
     */
    page_no: number;
    /**
     * ranch_id
     * @type {number}
     * @memberof RanchEventHistoryReq
     */
    ranch_id: number;
    /**
     * 
     * @type {boolean}
     * @memberof RanchEventHistoryReq
     */
    valid?: boolean;
}
/**
 * 
 * @export
 * @interface RanchEventListDto
 */
export interface RanchEventListDto {
    /**
     * event_list
     * @type {Array<RanchEventHistoryBaseDto>}
     * @memberof RanchEventListDto
     */
    event_list: Array<RanchEventHistoryBaseDto>;
    /**
     * page_no
     * @type {number}
     * @memberof RanchEventListDto
     */
    page_no: number;
}
/**
 * 
 * @export
 * @interface RanchFeedDao
 */
export interface RanchFeedDao {
    /**
     * convert_scale
     * @type {number}
     * @memberof RanchFeedDao
     */
    convert_scale?: number;
    /**
     * created_at
     * @type {string}
     * @memberof RanchFeedDao
     */
    created_at: string;
    /**
     * created_by
     * @type {string}
     * @memberof RanchFeedDao
     */
    created_by?: string;
    /**
     * disp_no
     * @type {number}
     * @memberof RanchFeedDao
     */
    disp_no: number;
    /**
     * feed_no
     * @type {number}
     * @memberof RanchFeedDao
     */
    feed_no: number;
    /**
     * feed_type_detail_no
     * @type {number}
     * @memberof RanchFeedDao
     */
    feed_type_detail_no?: number;
    /**
     * feed_type_no
     * @type {number}
     * @memberof RanchFeedDao
     */
    feed_type_no: number;
    /**
     * is_deleted
     * @type {number}
     * @memberof RanchFeedDao
     */
    is_deleted: number;
    /**
     * name
     * @type {string}
     * @memberof RanchFeedDao
     */
    name: string;
    /**
     * ranch_id
     * @type {number}
     * @memberof RanchFeedDao
     */
    ranch_id: number;
    /**
     * 
     * @type {string}
     * @memberof RanchFeedDao
     */
    unit: string;
    /**
     * unit_price
     * @type {number}
     * @memberof RanchFeedDao
     */
    unit_price: number;
    /**
     * updated_at
     * @type {string}
     * @memberof RanchFeedDao
     */
    updated_at: string;
    /**
     * updated_by
     * @type {string}
     * @memberof RanchFeedDao
     */
    updated_by: string;
    /**
     * washout_meat_day
     * @type {number}
     * @memberof RanchFeedDao
     */
    washout_meat_day?: number;
    /**
     * washout_milk_hour
     * @type {number}
     * @memberof RanchFeedDao
     */
    washout_milk_hour?: number;
}
/**
 * 
 * @export
 * @interface RanchFeedDeleteReq
 */
export interface RanchFeedDeleteReq {
    /**
     * feed_no
     * @type {number}
     * @memberof RanchFeedDeleteReq
     */
    feed_no: number;
    /**
     * ranch_id
     * @type {number}
     * @memberof RanchFeedDeleteReq
     */
    ranch_id: number;
    /**
     * user_id
     * @type {string}
     * @memberof RanchFeedDeleteReq
     */
    user_id: string;
}
/**
 * 
 * @export
 * @interface RanchFeedDto
 */
export interface RanchFeedDto {
    /**
     * list
     * @type {Array<RanchFeedDao>}
     * @memberof RanchFeedDto
     */
    list?: Array<RanchFeedDao>;
}
/**
 * 
 * @export
 * @interface RanchFeedModifyReq
 */
export interface RanchFeedModifyReq {
    /**
     * convert_scale
     * @type {number}
     * @memberof RanchFeedModifyReq
     */
    convert_scale?: number;
    /**
     * feed_no
     * @type {number}
     * @memberof RanchFeedModifyReq
     */
    feed_no: number;
    /**
     * feed_type_detail_no
     * @type {number}
     * @memberof RanchFeedModifyReq
     */
    feed_type_detail_no?: number;
    /**
     * feed_type_no
     * @type {number}
     * @memberof RanchFeedModifyReq
     */
    feed_type_no: number;
    /**
     * is_new
     * @type {number}
     * @memberof RanchFeedModifyReq
     */
    is_new: number;
    /**
     * name
     * @type {string}
     * @memberof RanchFeedModifyReq
     */
    name: string;
    /**
     * ranch_id
     * @type {number}
     * @memberof RanchFeedModifyReq
     */
    ranch_id: number;
    /**
     * 
     * @type {string}
     * @memberof RanchFeedModifyReq
     */
    unit: string;
    /**
     * unit_price
     * @type {number}
     * @memberof RanchFeedModifyReq
     */
    unit_price: number;
    /**
     * user_id
     * @type {string}
     * @memberof RanchFeedModifyReq
     */
    user_id: string;
    /**
     * washout_meat_day
     * @type {number}
     * @memberof RanchFeedModifyReq
     */
    washout_meat_day?: number;
    /**
     * washout_milk_hour
     * @type {number}
     * @memberof RanchFeedModifyReq
     */
    washout_milk_hour?: number;
}
/**
 * 
 * @export
 * @interface RanchFeedReq
 */
export interface RanchFeedReq {
    /**
     * ranch_id
     * @type {number}
     * @memberof RanchFeedReq
     */
    ranch_id: number;
    /**
     * since
     * @type {string}
     * @memberof RanchFeedReq
     */
    since?: string;
}
/**
 * 
 * @export
 * @interface RanchFeedSortReq
 */
export interface RanchFeedSortReq {
    /**
     * order
     * @type {Array<number>}
     * @memberof RanchFeedSortReq
     */
    order: Array<number>;
    /**
     * ranch_id
     * @type {number}
     * @memberof RanchFeedSortReq
     */
    ranch_id: number;
    /**
     * user_id
     * @type {string}
     * @memberof RanchFeedSortReq
     */
    user_id: string;
}
/**
 * 
 * @export
 * @interface RanchHouseDeleteReq
 */
export interface RanchHouseDeleteReq {
    /**
     * 
     * @type {number}
     * @memberof RanchHouseDeleteReq
     */
    level: number;
    /**
     * 
     * @type {number}
     * @memberof RanchHouseDeleteReq
     */
    no: number;
    /**
     * 
     * @type {number}
     * @memberof RanchHouseDeleteReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface RanchHouseDto
 */
export interface RanchHouseDto {
    /**
     * list
     * @type {Array<ComOnpVetellMybatisRanchHouseDao>}
     * @memberof RanchHouseDto
     */
    list?: Array<ComOnpVetellMybatisRanchHouseDao>;
}
/**
 * 
 * @export
 * @interface RanchHouseModifyReq
 */
export interface RanchHouseModifyReq {
    /**
     * 
     * @type {number}
     * @memberof RanchHouseModifyReq
     */
    is_new: number;
    /**
     * 
     * @type {number}
     * @memberof RanchHouseModifyReq
     */
    level: number;
    /**
     * 
     * @type {string}
     * @memberof RanchHouseModifyReq
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof RanchHouseModifyReq
     */
    no?: number;
    /**
     * 
     * @type {number}
     * @memberof RanchHouseModifyReq
     */
    parent_no: number;
    /**
     * 
     * @type {number}
     * @memberof RanchHouseModifyReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface RanchHouseReq
 */
export interface RanchHouseReq {
    /**
     * ranch_id
     * @type {number}
     * @memberof RanchHouseReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface RanchHouseSortReq
 */
export interface RanchHouseSortReq {
    /**
     * 
     * @type {number}
     * @memberof RanchHouseSortReq
     */
    level: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof RanchHouseSortReq
     */
    order: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof RanchHouseSortReq
     */
    parent_no: number;
    /**
     * 
     * @type {number}
     * @memberof RanchHouseSortReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface RanchInfoDto
 */
export interface RanchInfoDto {
    /**
     * 
     * @type {Array<DiseaseCauseDto>}
     * @memberof RanchInfoDto
     */
    causes?: Array<DiseaseCauseDto>;
    /**
     * 
     * @type {Array<ComOnpVetellMybatisCowConditionDao>}
     * @memberof RanchInfoDto
     */
    condition?: Array<ComOnpVetellMybatisCowConditionDao>;
    /**
     * 
     * @type {Array<ComOnpVetellMybatisCowBreedDao>}
     * @memberof RanchInfoDto
     */
    cow_breed?: Array<ComOnpVetellMybatisCowBreedDao>;
    /**
     * 
     * @type {Array<CowUseDao>}
     * @memberof RanchInfoDto
     */
    cow_use?: Array<CowUseDao>;
    /**
     * 
     * @type {Array<ComOnpVetellMybatisCowDiseaseDao>}
     * @memberof RanchInfoDto
     */
    disease?: Array<ComOnpVetellMybatisCowDiseaseDao>;
    /**
     * V4.2 deprecated
     * @type {Array<ComOnpVetellMybatisCowDiseaseItemDao>}
     * @memberof RanchInfoDto
     */
    disease_item?: Array<ComOnpVetellMybatisCowDiseaseItemDao>;
    /**
     * 
     * @type {Array<ComOnpVetellMybatisCowFecesColorDao>}
     * @memberof RanchInfoDto
     */
    feces_color?: Array<ComOnpVetellMybatisCowFecesColorDao>;
    /**
     * 
     * @type {Array<ComOnpVetellMybatisCowFecesStateDao>}
     * @memberof RanchInfoDto
     */
    feces_state?: Array<ComOnpVetellMybatisCowFecesStateDao>;
    /**
     * 
     * @type {Array<ComOnpVetellMybatisFeedTypeDao>}
     * @memberof RanchInfoDto
     */
    feed_type?: Array<ComOnpVetellMybatisFeedTypeDao>;
    /**
     * 
     * @type {Array<ComOnpVetellMybatisCowMedicineCategoryDao>}
     * @memberof RanchInfoDto
     */
    medicine_category?: Array<ComOnpVetellMybatisCowMedicineCategoryDao>;
    /**
     * 
     * @type {Array<ComOnpVetellMybatisCowTreatKindDao>}
     * @memberof RanchInfoDto
     */
    treat_kind?: Array<ComOnpVetellMybatisCowTreatKindDao>;
}
/**
 * 
 * @export
 * @interface RanchScheduleCowDto
 */
export interface RanchScheduleCowDto {
    /**
     * 
     * @type {number}
     * @memberof RanchScheduleCowDto
     */
    breed_no?: number;
    /**
     * 
     * @type {number}
     * @memberof RanchScheduleCowDto
     */
    cow_id: number;
    /**
     * 
     * @type {number}
     * @memberof RanchScheduleCowDto
     */
    is_active: number;
    /**
     * ※未設定の場合、nullまたは空文字で返る
     * @type {string}
     * @memberof RanchScheduleCowDto
     */
    local_no?: string;
    /**
     * ※未設定の場合、nullまたは空文字で返る
     * @type {string}
     * @memberof RanchScheduleCowDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof RanchScheduleCowDto
     */
    site: number;
    /**
     * 
     * @type {string}
     * @memberof RanchScheduleCowDto
     */
    trace_id: string;
    /**
     * 
     * @type {number}
     * @memberof RanchScheduleCowDto
     */
    use_no?: number;
    /**
     * 
     * @type {number}
     * @memberof RanchScheduleCowDto
     */
    watching: number;
    /**
     * 
     * @type {string}
     * @memberof RanchScheduleCowDto
     */
    watching_memo: string;
}
/**
 * 
 * @export
 * @interface RanchScheduleDeleteReq
 */
export interface RanchScheduleDeleteReq {
    /**
     * 診療所ID（ranch_id=nullのときだけ指定必要）
     * @type {number}
     * @memberof RanchScheduleDeleteReq
     */
    clinic_id?: number;
    /**
     * 
     * @type {number}
     * @memberof RanchScheduleDeleteReq
     */
    ranch_id?: number;
    /**
     * 
     * @type {number}
     * @memberof RanchScheduleDeleteReq
     */
    schedule_id: number;
}
/**
 * 
 * @export
 * @interface RanchScheduleDto
 */
export interface RanchScheduleDto {
    /**
     * 
     * @type {number}
     * @memberof RanchScheduleDto
     */
    clinic_id?: number;
    /**
     * 
     * @type {string}
     * @memberof RanchScheduleDto
     */
    color?: string;
    /**
     * 
     * @type {Array<RanchScheduleCowDto>}
     * @memberof RanchScheduleDto
     */
    cows?: Array<RanchScheduleCowDto>;
    /**
     * 
     * @type {string}
     * @memberof RanchScheduleDto
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof RanchScheduleDto
     */
    created_by_name: string;
    /**
     * 
     * @type {string}
     * @memberof RanchScheduleDto
     */
    end_at: string;
    /**
     * 
     * @type {number}
     * @memberof RanchScheduleDto
     */
    event_kind_no: number;
    /**
     * 
     * @type {number}
     * @memberof RanchScheduleDto
     */
    has_time: number;
    /**
     * 
     * @type {string}
     * @memberof RanchScheduleDto
     */
    note: string;
    /**
     * 
     * @type {RanchSchedulePresetDto}
     * @memberof RanchScheduleDto
     */
    preset?: RanchSchedulePresetDto;
    /**
     * 
     * @type {number}
     * @memberof RanchScheduleDto
     */
    ranch_id?: number;
    /**
     * 
     * @type {Array<RanchScheduleEventDto>}
     * @memberof RanchScheduleDto
     */
    results: Array<RanchScheduleEventDto>;
    /**
     * 
     * @type {number}
     * @memberof RanchScheduleDto
     */
    schedule_id: number;
    /**
     * この予定に含まれる牛の所属する分場リスト
     * @type {Array<number>}
     * @memberof RanchScheduleDto
     */
    sites: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof RanchScheduleDto
     */
    start_at: string;
    /**
     * 
     * @type {number}
     * @memberof RanchScheduleDto
     */
    status: number;
    /**
     * 
     * @type {string}
     * @memberof RanchScheduleDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof RanchScheduleDto
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof RanchScheduleDto
     */
    updated_by_name: string;
}
/**
 * 
 * @export
 * @interface RanchScheduleEventDto
 */
export interface RanchScheduleEventDto {
    /**
     * 
     * @type {number}
     * @memberof RanchScheduleEventDto
     */
    cow_id?: number;
    /**
     * 
     * @type {number}
     * @memberof RanchScheduleEventDto
     */
    event_id: number;
    /**
     * 
     * @type {number}
     * @memberof RanchScheduleEventDto
     */
    occured_at: number;
}
/**
 * 
 * @export
 * @interface RanchScheduleModifyReq
 */
export interface RanchScheduleModifyReq {
    /**
     * 診療所ID（更新の場合、ranch_id=nullのときだけ指定必要）
     * @type {number}
     * @memberof RanchScheduleModifyReq
     */
    clinic_id?: number;
    /**
     * 
     * @type {string}
     * @memberof RanchScheduleModifyReq
     */
    color?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof RanchScheduleModifyReq
     */
    cow_id: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof RanchScheduleModifyReq
     */
    end_at: string;
    /**
     * 
     * @type {number}
     * @memberof RanchScheduleModifyReq
     */
    event_kind_no: number;
    /**
     * 
     * @type {number}
     * @memberof RanchScheduleModifyReq
     */
    has_time: number;
    /**
     * 
     * @type {number}
     * @memberof RanchScheduleModifyReq
     */
    is_new: number;
    /**
     * 
     * @type {string}
     * @memberof RanchScheduleModifyReq
     */
    note: string;
    /**
     * 
     * @type {number}
     * @memberof RanchScheduleModifyReq
     */
    preset_id?: number;
    /**
     * 
     * @type {number}
     * @memberof RanchScheduleModifyReq
     */
    ranch_id?: number;
    /**
     * 
     * @type {number}
     * @memberof RanchScheduleModifyReq
     */
    schedule_id: number;
    /**
     * 
     * @type {string}
     * @memberof RanchScheduleModifyReq
     */
    start_at: string;
    /**
     * 
     * @type {number}
     * @memberof RanchScheduleModifyReq
     */
    status: number;
    /**
     * 
     * @type {string}
     * @memberof RanchScheduleModifyReq
     */
    title: string;
}
/**
 * 
 * @export
 * @interface RanchSchedulePresetDto
 */
export interface RanchSchedulePresetDto {
    /**
     * 
     * @type {Array<TeamTreatNamedPresetItemDto>}
     * @memberof RanchSchedulePresetDto
     */
    items: Array<TeamTreatNamedPresetItemDto>;
    /**
     * 
     * @type {number}
     * @memberof RanchSchedulePresetDto
     */
    preset_id: number;
    /**
     * 
     * @type {string}
     * @memberof RanchSchedulePresetDto
     */
    preset_name: string;
    /**
     * 組織ID（itemsのteam_idはすべてこのteam_idと一致する）
     * @type {number}
     * @memberof RanchSchedulePresetDto
     */
    team_id: number;
}
/**
 * 
 * @export
 * @interface RanchScheduleReq
 */
export interface RanchScheduleReq {
    /**
     * 
     * @type {Array<number>}
     * @memberof RanchScheduleReq
     */
    cow_id?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof RanchScheduleReq
     */
    end_at: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof RanchScheduleReq
     */
    event_kind_no?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof RanchScheduleReq
     */
    ranch_id: number;
    /**
     * 
     * @type {string}
     * @memberof RanchScheduleReq
     */
    start_at: string;
    /**
     * 
     * @type {number}
     * @memberof RanchScheduleReq
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface RanchSeedDeleteReq
 */
export interface RanchSeedDeleteReq {
    /**
     * ranch_id
     * @type {number}
     * @memberof RanchSeedDeleteReq
     */
    ranch_id: number;
    /**
     * seed_id
     * @type {number}
     * @memberof RanchSeedDeleteReq
     */
    seed_id: number;
    /**
     * seed_type
     * @type {number}
     * @memberof RanchSeedDeleteReq
     */
    seed_type: number;
}
/**
 * 
 * @export
 * @interface RanchSeedDto
 */
export interface RanchSeedDto {
    /**
     * 
     * @type {string}
     * @memberof RanchSeedDto
     */
    ancestor_1?: string;
    /**
     * 
     * @type {string}
     * @memberof RanchSeedDto
     */
    ancestor_2?: string;
    /**
     * 
     * @type {number}
     * @memberof RanchSeedDto
     */
    ancestor_2_exid?: number;
    /**
     * 
     * @type {string}
     * @memberof RanchSeedDto
     */
    ancestor_3?: string;
    /**
     * 
     * @type {number}
     * @memberof RanchSeedDto
     */
    ancestor_3_exid?: number;
    /**
     * 
     * @type {string}
     * @memberof RanchSeedDto
     */
    ancestor_4?: string;
    /**
     * 
     * @type {number}
     * @memberof RanchSeedDto
     */
    ancestor_4_exid?: number;
    /**
     * cow_id
     * @type {number}
     * @memberof RanchSeedDto
     */
    cow_id?: number;
    /**
     * 受精卵は一代祖、精液は名号
     * @type {number}
     * @memberof RanchSeedDto
     */
    father_exid: number;
    /**
     * 
     * @type {string}
     * @memberof RanchSeedDto
     */
    get_from?: string;
    /**
     * lots
     * @type {Array<RanchSeedLotDto>}
     * @memberof RanchSeedDto
     */
    lots: Array<RanchSeedLotDto>;
    /**
     * 受精卵は母牛名、精液は種雄牛名（father_exid）
     * @type {string}
     * @memberof RanchSeedDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof RanchSeedDto
     */
    seed_id: number;
    /**
     * 
     * @type {string}
     * @memberof RanchSeedDto
     */
    sname?: string;
}
/**
 * 
 * @export
 * @interface RanchSeedListDto
 */
export interface RanchSeedListDto {
    /**
     * seed_type
     * @type {number}
     * @memberof RanchSeedListDto
     */
    seed_type: number;
    /**
     * seeds
     * @type {Array<RanchSeedDto>}
     * @memberof RanchSeedListDto
     */
    seeds: Array<RanchSeedDto>;
    /**
     * team_id
     * @type {number}
     * @memberof RanchSeedListDto
     */
    team_id: number;
}
/**
 * 
 * @export
 * @interface RanchSeedLotDto
 */
export interface RanchSeedLotDto {
    /**
     * comment
     * @type {string}
     * @memberof RanchSeedLotDto
     */
    comment: string;
    /**
     * egg_rank
     * @type {number}
     * @memberof RanchSeedLotDto
     */
    egg_rank?: number;
    /**
     * egg_stage
     * @type {number}
     * @memberof RanchSeedLotDto
     */
    egg_stage?: number;
    /**
     * egg_type
     * @type {number}
     * @memberof RanchSeedLotDto
     */
    egg_type?: number;
    /**
     * label_from
     * @type {string}
     * @memberof RanchSeedLotDto
     */
    label_from: string;
    /**
     * label_to
     * @type {string}
     * @memberof RanchSeedLotDto
     */
    label_to: string;
    /**
     * lot_name
     * @type {string}
     * @memberof RanchSeedLotDto
     */
    lot_name: string;
    /**
     * seed_lot_id
     * @type {number}
     * @memberof RanchSeedLotDto
     */
    seed_lot_id: number;
    /**
     * stock_count
     * @type {number}
     * @memberof RanchSeedLotDto
     */
    stock_count: number;
    /**
     * stock_day
     * @type {string}
     * @memberof RanchSeedLotDto
     */
    stock_day: string;
    /**
     * stocks
     * @type {Array<RanchSeedStockDto>}
     * @memberof RanchSeedLotDto
     */
    stocks: Array<RanchSeedStockDto>;
    /**
     * unit_price
     * @type {number}
     * @memberof RanchSeedLotDto
     */
    unit_price: number;
}
/**
 * 
 * @export
 * @interface RanchSeedLotModifyReq
 */
export interface RanchSeedLotModifyReq {
    /**
     * 
     * @type {string}
     * @memberof RanchSeedLotModifyReq
     */
    comment: string;
    /**
     * egg_rank
     * @type {number}
     * @memberof RanchSeedLotModifyReq
     */
    egg_rank?: number;
    /**
     * egg_stage
     * @type {number}
     * @memberof RanchSeedLotModifyReq
     */
    egg_stage?: number;
    /**
     * egg_type
     * @type {number}
     * @memberof RanchSeedLotModifyReq
     */
    egg_type?: number;
    /**
     * is_new
     * @type {number}
     * @memberof RanchSeedLotModifyReq
     */
    is_new: number;
    /**
     * label_from
     * @type {string}
     * @memberof RanchSeedLotModifyReq
     */
    label_from: string;
    /**
     * label_to
     * @type {string}
     * @memberof RanchSeedLotModifyReq
     */
    label_to: string;
    /**
     * lot_name
     * @type {string}
     * @memberof RanchSeedLotModifyReq
     */
    lot_name: string;
    /**
     * ranch_id
     * @type {number}
     * @memberof RanchSeedLotModifyReq
     */
    ranch_id: number;
    /**
     * seed_id
     * @type {number}
     * @memberof RanchSeedLotModifyReq
     */
    seed_id: number;
    /**
     * seed_lot_id
     * @type {number}
     * @memberof RanchSeedLotModifyReq
     */
    seed_lot_id?: number;
    /**
     * seed_type
     * @type {number}
     * @memberof RanchSeedLotModifyReq
     */
    seed_type: number;
    /**
     * stock_count
     * @type {number}
     * @memberof RanchSeedLotModifyReq
     */
    stock_count: number;
    /**
     * stock_day
     * @type {string}
     * @memberof RanchSeedLotModifyReq
     */
    stock_day: string;
    /**
     * unit_price
     * @type {number}
     * @memberof RanchSeedLotModifyReq
     */
    unit_price: number;
}
/**
 * 
 * @export
 * @interface RanchSeedModifyReq
 */
export interface RanchSeedModifyReq {
    /**
     * ※廃止
     * @type {string}
     * @memberof RanchSeedModifyReq
     */
    ancestor_1?: string;
    /**
     * ※廃止
     * @type {string}
     * @memberof RanchSeedModifyReq
     */
    ancestor_2?: string;
    /**
     * 
     * @type {number}
     * @memberof RanchSeedModifyReq
     */
    ancestor_2_exid?: number;
    /**
     * ※廃止
     * @type {string}
     * @memberof RanchSeedModifyReq
     */
    ancestor_3?: string;
    /**
     * 
     * @type {number}
     * @memberof RanchSeedModifyReq
     */
    ancestor_3_exid?: number;
    /**
     * ※廃止
     * @type {string}
     * @memberof RanchSeedModifyReq
     */
    ancestor_4?: string;
    /**
     * 
     * @type {number}
     * @memberof RanchSeedModifyReq
     */
    ancestor_4_exid?: number;
    /**
     * cow_id
     * @type {number}
     * @memberof RanchSeedModifyReq
     */
    cow_id?: number;
    /**
     * 精液なら種雄牛、受精卵なら一代祖
     * @type {number}
     * @memberof RanchSeedModifyReq
     */
    father_exid: number;
    /**
     * 入手元
     * @type {string}
     * @memberof RanchSeedModifyReq
     */
    get_from?: string;
    /**
     * 
     * @type {number}
     * @memberof RanchSeedModifyReq
     */
    is_new: number;
    /**
     * 受精卵のみ、母牛名
     * @type {string}
     * @memberof RanchSeedModifyReq
     */
    name?: string;
    /**
     * ranch_id
     * @type {number}
     * @memberof RanchSeedModifyReq
     */
    ranch_id: number;
    /**
     * 
     * @type {number}
     * @memberof RanchSeedModifyReq
     */
    seed_id: number;
    /**
     * 
     * @type {number}
     * @memberof RanchSeedModifyReq
     */
    seed_type: number;
    /**
     * 精液略号
     * @type {string}
     * @memberof RanchSeedModifyReq
     */
    sname?: string;
}
/**
 * 
 * @export
 * @interface RanchSeedSortReq
 */
export interface RanchSeedSortReq {
    /**
     * order
     * @type {Array<number>}
     * @memberof RanchSeedSortReq
     */
    order: Array<number>;
    /**
     * ranch_id
     * @type {number}
     * @memberof RanchSeedSortReq
     */
    ranch_id: number;
    /**
     * seed_type
     * @type {number}
     * @memberof RanchSeedSortReq
     */
    seed_type: number;
}
/**
 * 
 * @export
 * @interface RanchSeedStockCrossDto
 */
export interface RanchSeedStockCrossDto {
    /**
     * breeding_id
     * @type {number}
     * @memberof RanchSeedStockCrossDto
     */
    breeding_id: number;
    /**
     * cow_id
     * @type {number}
     * @memberof RanchSeedStockCrossDto
     */
    cow_id: number;
    /**
     * cross_no
     * @type {number}
     * @memberof RanchSeedStockCrossDto
     */
    cross_no: number;
    /**
     * local_no
     * @type {string}
     * @memberof RanchSeedStockCrossDto
     */
    local_no?: string;
    /**
     * name
     * @type {string}
     * @memberof RanchSeedStockCrossDto
     */
    name?: string;
    /**
     * ranch_id
     * @type {number}
     * @memberof RanchSeedStockCrossDto
     */
    ranch_id: number;
    /**
     * trace_id
     * @type {string}
     * @memberof RanchSeedStockCrossDto
     */
    trace_id: string;
    /**
     * watched_at
     * @type {string}
     * @memberof RanchSeedStockCrossDto
     */
    watched_at: string;
}
/**
 * 
 * @export
 * @interface RanchSeedStockDto
 */
export interface RanchSeedStockDto {
    /**
     * 
     * @type {RanchSeedStockCrossDto}
     * @memberof RanchSeedStockDto
     */
    cross?: RanchSeedStockCrossDto;
    /**
     * 
     * @type {RanchSeedStockIvfDto}
     * @memberof RanchSeedStockDto
     */
    ivf?: RanchSeedStockIvfDto;
    /**
     * 
     * @type {string}
     * @memberof RanchSeedStockDto
     */
    label_no: string;
    /**
     * 
     * @type {string}
     * @memberof RanchSeedStockDto
     */
    note?: string;
    /**
     * 
     * @type {number}
     * @memberof RanchSeedStockDto
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof RanchSeedStockDto
     */
    status: number;
    /**
     * 
     * @type {Array<RanchTagDto>}
     * @memberof RanchSeedStockDto
     */
    tags: Array<RanchTagDto>;
    /**
     * 
     * @type {string}
     * @memberof RanchSeedStockDto
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof RanchSeedStockDto
     */
    updated_by: string;
    /**
     * 
     * @type {string}
     * @memberof RanchSeedStockDto
     */
    updated_by_name: string;
    /**
     * 
     * @type {string}
     * @memberof RanchSeedStockDto
     */
    watched_at?: string;
}
/**
 * 
 * @export
 * @interface RanchSeedStockIvfDto
 */
export interface RanchSeedStockIvfDto {
    /**
     * 
     * @type {number}
     * @memberof RanchSeedStockIvfDto
     */
    cow_id: number;
    /**
     * 
     * @type {number}
     * @memberof RanchSeedStockIvfDto
     */
    event_id: number;
    /**
     * 
     * @type {string}
     * @memberof RanchSeedStockIvfDto
     */
    local_no?: string;
    /**
     * 
     * @type {string}
     * @memberof RanchSeedStockIvfDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof RanchSeedStockIvfDto
     */
    ranch_id: number;
    /**
     * 
     * @type {string}
     * @memberof RanchSeedStockIvfDto
     */
    trace_id: string;
    /**
     * 
     * @type {string}
     * @memberof RanchSeedStockIvfDto
     */
    watched_at: string;
}
/**
 * 
 * @export
 * @interface RanchSeedStockModifyReq
 */
export interface RanchSeedStockModifyReq {
    /**
     * label_no
     * @type {string}
     * @memberof RanchSeedStockModifyReq
     */
    label_no: string;
    /**
     * note
     * @type {string}
     * @memberof RanchSeedStockModifyReq
     */
    note?: string;
    /**
     * price
     * @type {number}
     * @memberof RanchSeedStockModifyReq
     */
    price?: number;
    /**
     * ranch_id
     * @type {number}
     * @memberof RanchSeedStockModifyReq
     */
    ranch_id: number;
    /**
     * seed_lot_id
     * @type {number}
     * @memberof RanchSeedStockModifyReq
     */
    seed_lot_id: number;
    /**
     * status
     * @type {number}
     * @memberof RanchSeedStockModifyReq
     */
    status?: number;
    /**
     * watched_at
     * @type {string}
     * @memberof RanchSeedStockModifyReq
     */
    watched_at?: string;
}
/**
 * 
 * @export
 * @interface RanchSellCowAgeDto
 */
export interface RanchSellCowAgeDto {
    /**
     * 
     * @type {number}
     * @memberof RanchSellCowAgeDto
     */
    age: number;
    /**
     * 
     * @type {number}
     * @memberof RanchSellCowAgeDto
     */
    breed_no: number;
    /**
     * 
     * @type {number}
     * @memberof RanchSellCowAgeDto
     */
    is_male: number;
    /**
     * 
     * @type {number}
     * @memberof RanchSellCowAgeDto
     */
    sell_type: number;
}
/**
 * 
 * @export
 * @interface RanchTagDto
 */
export interface RanchTagDto {
    /**
     * 
     * @type {number}
     * @memberof RanchTagDto
     */
    tag_id: number;
    /**
     * 
     * @type {string}
     * @memberof RanchTagDto
     */
    tag_name: string;
}
/**
 * 
 * @export
 * @interface RanchTagListReq
 */
export interface RanchTagListReq {
    /**
     * ranch_id
     * @type {number}
     * @memberof RanchTagListReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface RecordIdDto
 */
export interface RecordIdDto {
    /**
     * id
     * @type {number}
     * @memberof RecordIdDto
     */
    id: number;
}
/**
 * 
 * @export
 * @interface ResponseMeta
 */
export interface ResponseMeta {
    /**
     * 
     * @type {object}
     * @memberof ResponseMeta
     */
    data?: object;
    /**
     * 
     * @type {number}
     * @memberof ResponseMeta
     */
    errCode?: number;
    /**
     * 
     * @type {string}
     * @memberof ResponseMeta
     */
    errMsg?: string;
}
/**
 * 
 * @export
 * @interface RobotCowModifyReq
 */
export interface RobotCowModifyReq {
    /**
     * 
     * @type {Array<RobotCowModifyReqCow>}
     * @memberof RobotCowModifyReq
     */
    cows: Array<RobotCowModifyReqCow>;
    /**
     * 
     * @type {number}
     * @memberof RobotCowModifyReq
     */
    ranch_id: number;
    /**
     * 
     * @type {number}
     * @memberof RobotCowModifyReq
     */
    robot_kind: number;
}
/**
 * 
 * @export
 * @interface RobotCowModifyReqCow
 */
export interface RobotCowModifyReqCow {
    /**
     * 
     * @type {number}
     * @memberof RobotCowModifyReqCow
     */
    cow_id?: number;
    /**
     * 
     * @type {string}
     * @memberof RobotCowModifyReqCow
     */
    robot_cow_id: string;
}
/**
 * 
 * @export
 * @interface RobotImportReq
 */
export interface RobotImportReq {
    /**
     * 
     * @type {boolean}
     * @memberof RobotImportReq
     */
    _sync?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RobotImportReq
     */
    csv: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RobotImportReq
     */
    discard_dests?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RobotImportReq
     */
    duplicated: RobotImportReqDuplicatedEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof RobotImportReq
     */
    event_kinds: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof RobotImportReq
     */
    feed_no?: number;
    /**
     * 
     * @type {number}
     * @memberof RobotImportReq
     */
    milk_price?: number;
    /**
     * 
     * @type {number}
     * @memberof RobotImportReq
     */
    ranch_id: number;
    /**
     * 
     * @type {number}
     * @memberof RobotImportReq
     */
    robot_kind: number;
}

/**
    * @export
    * @enum {string}
    */
export enum RobotImportReqDuplicatedEnum {
    ADD = 'ADD',
    SKIP = 'SKIP',
    ABORT = 'ABORT'
}

/**
 * 
 * @export
 * @interface RobotPreviewCowDto
 */
export interface RobotPreviewCowDto {
    /**
     * 
     * @type {number}
     * @memberof RobotPreviewCowDto
     */
    cow_id?: number;
    /**
     * 
     * @type {string}
     * @memberof RobotPreviewCowDto
     */
    local_no?: string;
    /**
     * 
     * @type {string}
     * @memberof RobotPreviewCowDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RobotPreviewCowDto
     */
    robot_cow_id: string;
    /**
     * 
     * @type {string}
     * @memberof RobotPreviewCowDto
     */
    trace_id?: string;
}
/**
 * 
 * @export
 * @interface RobotPreviewDto
 */
export interface RobotPreviewDto {
    /**
     * 
     * @type {number}
     * @memberof RobotPreviewDto
     */
    count: number;
    /**
     * 
     * @type {Array<RobotPreviewCowDto>}
     * @memberof RobotPreviewDto
     */
    cows: Array<RobotPreviewCowDto>;
}
/**
 * 
 * @export
 * @interface RobotPreviewReq
 */
export interface RobotPreviewReq {
    /**
     * 
     * @type {string}
     * @memberof RobotPreviewReq
     */
    csv: string;
    /**
     * 
     * @type {number}
     * @memberof RobotPreviewReq
     */
    ranch_id: number;
    /**
     * 
     * @type {number}
     * @memberof RobotPreviewReq
     */
    robot_kind: number;
}
/**
 * 
 * @export
 * @interface RutDeleteReq
 */
export interface RutDeleteReq {
    /**
     * ranch_id
     * @type {number}
     * @memberof RutDeleteReq
     */
    ranch_id: number;
    /**
     * rut_id
     * @type {number}
     * @memberof RutDeleteReq
     */
    rut_id: number;
}
/**
 * 
 * @export
 * @interface RutDetailDto
 */
export interface RutDetailDto {
    /**
     * 
     * @type {number}
     * @memberof RutDetailDto
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof RutDetailDto
     */
    detail_no: number;
    /**
     * 
     * @type {number}
     * @memberof RutDetailDto
     */
    medicine_category: number;
    /**
     * 
     * @type {number}
     * @memberof RutDetailDto
     */
    medicine_id: number;
    /**
     * 
     * @type {number}
     * @memberof RutDetailDto
     */
    route_id: number;
    /**
     * 
     * @type {number}
     * @memberof RutDetailDto
     */
    rut_id: number;
    /**
     * 
     * @type {number}
     * @memberof RutDetailDto
     */
    team_id: number;
    /**
     * 
     * @type {number}
     * @memberof RutDetailDto
     */
    unit_price: number;
}
/**
 * 
 * @export
 * @interface RutDto
 */
export interface RutDto {
    /**
     * 
     * @type {string}
     * @memberof RutDto
     */
    comment: string;
    /**
     * 
     * @type {number}
     * @memberof RutDto
     */
    cow_id: number;
    /**
     * 
     * @type {Array<RutDetailDto>}
     * @memberof RutDto
     */
    details: Array<RutDetailDto>;
    /**
     * 
     * @type {number}
     * @memberof RutDto
     */
    ranch_id: number;
    /**
     * 
     * @type {number}
     * @memberof RutDto
     */
    rut_id: number;
    /**
     * 
     * @type {number}
     * @memberof RutDto
     */
    signs: number;
    /**
     * 
     * @type {string}
     * @memberof RutDto
     */
    watched_at: string;
    /**
     * 
     * @type {string}
     * @memberof RutDto
     */
    watched_by: string;
    /**
     * 
     * @type {number}
     * @memberof RutDto
     */
    watched_type: number;
}
/**
 * 
 * @export
 * @interface RutLatestDto
 */
export interface RutLatestDto {
    /**
     * 
     * @type {number}
     * @memberof RutLatestDto
     */
    cow_id: number;
    /**
     * 
     * @type {number}
     * @memberof RutLatestDto
     */
    rut_id: number;
    /**
     * 
     * @type {number}
     * @memberof RutLatestDto
     */
    signs: number;
    /**
     * 
     * @type {string}
     * @memberof RutLatestDto
     */
    watched_at: string;
    /**
     * 
     * @type {number}
     * @memberof RutLatestDto
     */
    watched_type: number;
}
/**
 * 
 * @export
 * @interface RutLatestReq
 */
export interface RutLatestReq {
    /**
     * 1: 観察項目のある記録のみ
     * @type {number}
     * @memberof RutLatestReq
     */
    any_signs_only?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof RutLatestReq
     */
    cow_ids: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof RutLatestReq
     */
    end_day: string;
    /**
     * 
     * @type {number}
     * @memberof RutLatestReq
     */
    ranch_id: number;
    /**
     * 
     * @type {string}
     * @memberof RutLatestReq
     */
    start_day: string;
}
/**
 * 
 * @export
 * @interface RutModifyReq
 */
export interface RutModifyReq {
    /**
     * 
     * @type {string}
     * @memberof RutModifyReq
     */
    comment: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof RutModifyReq
     */
    cow_ids: Array<number>;
    /**
     * 
     * @type {Array<ModifyReqTreat>}
     * @memberof RutModifyReq
     */
    details: Array<ModifyReqTreat>;
    /**
     * 
     * @type {number}
     * @memberof RutModifyReq
     */
    is_new: number;
    /**
     * 
     * @type {string}
     * @memberof RutModifyReq
     */
    next_rut_day?: string;
    /**
     * 
     * @type {Array<EventNextScheduleReq>}
     * @memberof RutModifyReq
     */
    plans?: Array<EventNextScheduleReq>;
    /**
     * 
     * @type {PreloadedProgramReq}
     * @memberof RutModifyReq
     */
    preloaded_program: PreloadedProgramReq;
    /**
     * 
     * @type {number}
     * @memberof RutModifyReq
     */
    ranch_id: number;
    /**
     * 
     * @type {number}
     * @memberof RutModifyReq
     */
    rut_id?: number;
    /**
     * 
     * @type {number}
     * @memberof RutModifyReq
     */
    schedule_id?: number;
    /**
     * 
     * @type {number}
     * @memberof RutModifyReq
     */
    signs?: number;
    /**
     * 
     * @type {string}
     * @memberof RutModifyReq
     */
    watched_at: string;
    /**
     * 
     * @type {number}
     * @memberof RutModifyReq
     */
    watched_type?: number;
}
/**
 * 
 * @export
 * @interface SeedLotDeleteReq
 */
export interface SeedLotDeleteReq {
    /**
     * seed_lot_id
     * @type {number}
     * @memberof SeedLotDeleteReq
     */
    seed_lot_id: number;
    /**
     * 
     * @type {number}
     * @memberof SeedLotDeleteReq
     */
    team_id: number;
}
/**
 * 
 * @export
 * @interface SellCowAggregateCarcassDto
 */
export interface SellCowAggregateCarcassDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof SellCowAggregateCarcassDto
     */
    ancestors: Array<string>;
    /**
     * 
     * @type {SellCowCarcassDto}
     * @memberof SellCowAggregateCarcassDto
     */
    carcass?: SellCowCarcassDto;
    /**
     * 
     * @type {string}
     * @memberof SellCowAggregateCarcassDto
     */
    comment: string;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregateCarcassDto
     */
    cow_id: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregateCarcassDto
     */
    dg?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregateCarcassDto
     */
    is_male: number;
    /**
     * 
     * @type {string}
     * @memberof SellCowAggregateCarcassDto
     */
    local_no?: string;
    /**
     * 
     * @type {string}
     * @memberof SellCowAggregateCarcassDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregateCarcassDto
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregateCarcassDto
     */
    sell_age?: number;
    /**
     * 
     * @type {string}
     * @memberof SellCowAggregateCarcassDto
     */
    trace_id: string;
    /**
     * 
     * @type {string}
     * @memberof SellCowAggregateCarcassDto
     */
    watched_at: string;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregateCarcassDto
     */
    weight?: number;
}
/**
 * 
 * @export
 * @interface SellCowAggregateCarcassReq
 */
export interface SellCowAggregateCarcassReq {
    /**
     * 期間開始日　yyyy-MM-dd
     * @type {string}
     * @memberof SellCowAggregateCarcassReq
     */
    from: string;
    /**
     * 枝肉のない記録を含めるか（デフォルト: false）
     * @type {boolean}
     * @memberof SellCowAggregateCarcassReq
     */
    includesNoCarcass?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregateCarcassReq
     */
    is_disuse?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregateCarcassReq
     */
    is_male?: number;
    /**
     * 取引先番号（指定がないときは、partner_no未設定のもののみを取得
     * @type {number}
     * @memberof SellCowAggregateCarcassReq
     */
    partner_no?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregateCarcassReq
     */
    ranch_id: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregateCarcassReq
     */
    sell_type?: number;
    /**
     * 期間終了日　yyyy-MM-dd
     * @type {string}
     * @memberof SellCowAggregateCarcassReq
     */
    to: string;
}
/**
 * 
 * @export
 * @interface SellCowAggregateChildrenCarcassReq
 */
export interface SellCowAggregateChildrenCarcassReq {
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregateChildrenCarcassReq
     */
    cow_id: number;
    /**
     * 期間開始日　yyyy-MM-dd
     * @type {string}
     * @memberof SellCowAggregateChildrenCarcassReq
     */
    from?: string;
    /**
     * 枝肉のない記録を含めるか（デフォルト: false）
     * @type {boolean}
     * @memberof SellCowAggregateChildrenCarcassReq
     */
    includesNoCarcass?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregateChildrenCarcassReq
     */
    is_disuse?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregateChildrenCarcassReq
     */
    is_male?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregateChildrenCarcassReq
     */
    is_surrogate_mother?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregateChildrenCarcassReq
     */
    ranch_id: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregateChildrenCarcassReq
     */
    sell_type?: number;
    /**
     * 期間終了日　yyyy-MM-dd
     * @type {string}
     * @memberof SellCowAggregateChildrenCarcassReq
     */
    to?: string;
}
/**
 * 
 * @export
 * @interface SellCowAggregateMotherDto
 */
export interface SellCowAggregateMotherDto {
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregateMotherDto
     */
    age?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregateMotherDto
     */
    carcass_amount?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregateMotherDto
     */
    carcass_count: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregateMotherDto
     */
    count_female: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregateMotherDto
     */
    count_male: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregateMotherDto
     */
    cow_id?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregateMotherDto
     */
    dg?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregateMotherDto
     */
    high_percent?: number;
    /**
     * 
     * @type {string}
     * @memberof SellCowAggregateMotherDto
     */
    local_no?: string;
    /**
     * 
     * @type {string}
     * @memberof SellCowAggregateMotherDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregateMotherDto
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof SellCowAggregateMotherDto
     */
    trace_id?: string;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregateMotherDto
     */
    unit_price?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregateMotherDto
     */
    weight?: number;
}
/**
 * 
 * @export
 * @interface SellCowAggregateMotherListDto
 */
export interface SellCowAggregateMotherListDto {
    /**
     * 
     * @type {Array<SellCowAggregateMotherDto>}
     * @memberof SellCowAggregateMotherListDto
     */
    mothers: Array<SellCowAggregateMotherDto>;
    /**
     * 
     * @type {SellCowAggregateMotherDto}
     * @memberof SellCowAggregateMotherListDto
     */
    total: SellCowAggregateMotherDto;
}
/**
 * 
 * @export
 * @interface SellCowAggregateMotherReq
 */
export interface SellCowAggregateMotherReq {
    /**
     * 期間開始日　yyyy-MM-dd
     * @type {string}
     * @memberof SellCowAggregateMotherReq
     */
    from: string;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregateMotherReq
     */
    is_disuse?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregateMotherReq
     */
    is_male?: number;
    /**
     * 1:代理母のみ、0:血統上の母のみ、null:代理母優先
     * @type {number}
     * @memberof SellCowAggregateMotherReq
     */
    is_surrogate_mother?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregateMotherReq
     */
    ranch_id: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregateMotherReq
     */
    sellType: number;
    /**
     * 期間終了日　yyyy-MM-dd
     * @type {string}
     * @memberof SellCowAggregateMotherReq
     */
    to: string;
}
/**
 * 
 * @export
 * @interface SellCowAggregatePartnerDto
 */
export interface SellCowAggregatePartnerDto {
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregatePartnerDto
     */
    age?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregatePartnerDto
     */
    carcass_amount?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregatePartnerDto
     */
    carcass_count: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregatePartnerDto
     */
    count_female: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregatePartnerDto
     */
    count_male: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregatePartnerDto
     */
    dg?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregatePartnerDto
     */
    high_percent?: number;
    /**
     * 
     * @type {string}
     * @memberof SellCowAggregatePartnerDto
     */
    partner_name?: string;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregatePartnerDto
     */
    partner_no?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregatePartnerDto
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregatePartnerDto
     */
    unit_price?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregatePartnerDto
     */
    weight?: number;
}
/**
 * 
 * @export
 * @interface SellCowAggregatePartnerListDto
 */
export interface SellCowAggregatePartnerListDto {
    /**
     * 
     * @type {Array<SellCowAggregatePartnerDto>}
     * @memberof SellCowAggregatePartnerListDto
     */
    partners: Array<SellCowAggregatePartnerDto>;
    /**
     * 
     * @type {SellCowAggregatePartnerDto}
     * @memberof SellCowAggregatePartnerListDto
     */
    total: SellCowAggregatePartnerDto;
}
/**
 * 
 * @export
 * @interface SellCowAggregatePartnerReq
 */
export interface SellCowAggregatePartnerReq {
    /**
     * 期間開始日　yyyy-MM-dd
     * @type {string}
     * @memberof SellCowAggregatePartnerReq
     */
    from: string;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregatePartnerReq
     */
    is_disuse?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregatePartnerReq
     */
    is_male?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregatePartnerReq
     */
    ranch_id: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregatePartnerReq
     */
    sellType: number;
    /**
     * 期間終了日　yyyy-MM-dd
     * @type {string}
     * @memberof SellCowAggregatePartnerReq
     */
    to: string;
}
/**
 * 
 * @export
 * @interface SellCowAggregateTransitionDto
 */
export interface SellCowAggregateTransitionDto {
    /**
     * 
     * @type {Array<TransitionMonthDto>}
     * @memberof SellCowAggregateTransitionDto
     */
    months: Array<TransitionMonthDto>;
    /**
     * 
     * @type {number}
     * @memberof SellCowAggregateTransitionDto
     */
    sell_type: number;
}
/**
 * 
 * @export
 * @interface SellCowCarcassDto
 */
export interface SellCowCarcassDto {
    /**
     * 
     * @type {number}
     * @memberof SellCowCarcassDto
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowCarcassDto
     */
    defect: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowCarcassDto
     */
    is_high: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowCarcassDto
     */
    oleic_acid?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowCarcassDto
     */
    quality_bcs?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowCarcassDto
     */
    quality_bms?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowCarcassDto
     */
    quality_fat?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowCarcassDto
     */
    quality_texture?: number;
    /**
     * 
     * @type {string}
     * @memberof SellCowCarcassDto
     */
    reward: string;
    /**
     * 
     * @type {string}
     * @memberof SellCowCarcassDto
     */
    total_grade: string;
    /**
     * 
     * @type {number}
     * @memberof SellCowCarcassDto
     */
    unit_price: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowCarcassDto
     */
    yield_basis?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowCarcassDto
     */
    yield_fat?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowCarcassDto
     */
    yield_loin?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowCarcassDto
     */
    yield_rib?: number;
}
/**
 * 
 * @export
 * @interface SellCowCarcassEventDto
 */
export interface SellCowCarcassEventDto {
    /**
     * amount
     * @type {number}
     * @memberof SellCowCarcassEventDto
     */
    amount: number;
    /**
     * defect
     * @type {number}
     * @memberof SellCowCarcassEventDto
     */
    defect: number;
    /**
     * is_high
     * @type {number}
     * @memberof SellCowCarcassEventDto
     */
    is_high: number;
    /**
     * oleic_acid
     * @type {number}
     * @memberof SellCowCarcassEventDto
     */
    oleic_acid?: number;
    /**
     * quality_bcs
     * @type {number}
     * @memberof SellCowCarcassEventDto
     */
    quality_bcs?: number;
    /**
     * quality_bms
     * @type {number}
     * @memberof SellCowCarcassEventDto
     */
    quality_bms?: number;
    /**
     * quality_fat
     * @type {number}
     * @memberof SellCowCarcassEventDto
     */
    quality_fat?: number;
    /**
     * quality_texture
     * @type {number}
     * @memberof SellCowCarcassEventDto
     */
    quality_texture?: number;
    /**
     * reward
     * @type {string}
     * @memberof SellCowCarcassEventDto
     */
    reward: string;
    /**
     * total_grade
     * @type {string}
     * @memberof SellCowCarcassEventDto
     */
    total_grade: string;
    /**
     * unit_price
     * @type {number}
     * @memberof SellCowCarcassEventDto
     */
    unit_price: number;
    /**
     * yield_basis
     * @type {number}
     * @memberof SellCowCarcassEventDto
     */
    yield_basis?: number;
    /**
     * yield_fat
     * @type {number}
     * @memberof SellCowCarcassEventDto
     */
    yield_fat?: number;
    /**
     * yield_loin
     * @type {number}
     * @memberof SellCowCarcassEventDto
     */
    yield_loin?: number;
    /**
     * yield_rib
     * @type {number}
     * @memberof SellCowCarcassEventDto
     */
    yield_rib?: number;
}
/**
 * 
 * @export
 * @interface SellCowDeleteReq
 */
export interface SellCowDeleteReq {
    /**
     * cow_id
     * @type {number}
     * @memberof SellCowDeleteReq
     */
    cow_id: number;
    /**
     * event_id
     * @type {number}
     * @memberof SellCowDeleteReq
     */
    event_id: number;
    /**
     * ranch_id
     * @type {number}
     * @memberof SellCowDeleteReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface SellCowDto
 */
export interface SellCowDto {
    /**
     * 
     * @type {SellCowCarcassDto}
     * @memberof SellCowDto
     */
    carcass?: SellCowCarcassDto;
    /**
     * 
     * @type {string}
     * @memberof SellCowDto
     */
    comment: string;
    /**
     * 
     * @type {number}
     * @memberof SellCowDto
     */
    cow_id: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowDto
     */
    event_id: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowDto
     */
    is_active: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowDto
     */
    is_disuse: number;
    /**
     * 
     * @type {string}
     * @memberof SellCowDto
     */
    partner_name?: string;
    /**
     * 
     * @type {number}
     * @memberof SellCowDto
     */
    partner_no?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowDto
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowDto
     */
    ranch_id: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowDto
     */
    sell_type: number;
    /**
     * 
     * @type {string}
     * @memberof SellCowDto
     */
    watched_at: string;
    /**
     * 
     * @type {string}
     * @memberof SellCowDto
     */
    watched_by: string;
    /**
     * 
     * @type {number}
     * @memberof SellCowDto
     */
    weight?: number;
}
/**
 * 
 * @export
 * @interface SellCowEventBirthDto
 */
export interface SellCowEventBirthDto {
    /**
     * 
     * @type {number}
     * @memberof SellCowEventBirthDto
     */
    actual_weight?: number;
    /**
     * 
     * @type {string}
     * @memberof SellCowEventBirthDto
     */
    birthday?: string;
    /**
     * 
     * @type {number}
     * @memberof SellCowEventBirthDto
     */
    standard_weight?: number;
}
/**
 * 
 * @export
 * @interface SellCowEventDto
 */
export interface SellCowEventDto {
    /**
     * 
     * @type {SellCowEventBirthDto}
     * @memberof SellCowEventDto
     */
    birth: SellCowEventBirthDto;
    /**
     * 
     * @type {SellCowCarcassEventDto}
     * @memberof SellCowEventDto
     */
    carcass?: SellCowCarcassEventDto;
    /**
     * comment
     * @type {string}
     * @memberof SellCowEventDto
     */
    comment: string;
    /**
     * created_by
     * @type {string}
     * @memberof SellCowEventDto
     */
    created_by: string;
    /**
     * event_id
     * @type {number}
     * @memberof SellCowEventDto
     */
    event_id: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowEventDto
     */
    is_disuse: number;
    /**
     * 
     * @type {string}
     * @memberof SellCowEventDto
     */
    partner_name?: string;
    /**
     * price
     * @type {number}
     * @memberof SellCowEventDto
     */
    price: number;
    /**
     * sell_type
     * @type {number}
     * @memberof SellCowEventDto
     */
    sell_type: number;
    /**
     * watched_at
     * @type {string}
     * @memberof SellCowEventDto
     */
    watched_at: string;
    /**
     * watched_by_name
     * @type {string}
     * @memberof SellCowEventDto
     */
    watched_by_name: string;
    /**
     * 
     * @type {number}
     * @memberof SellCowEventDto
     */
    weight?: number;
}
/**
 * 
 * @export
 * @interface SellCowModifyReq
 */
export interface SellCowModifyReq {
    /**
     * 
     * @type {Array<SellCowModifyReqCow>}
     * @memberof SellCowModifyReq
     */
    cows: Array<SellCowModifyReqCow>;
    /**
     * 廃用フラグ（未指定のときは0扱い）
     * @type {number}
     * @memberof SellCowModifyReq
     */
    is_disuse?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowModifyReq
     */
    partner_no?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowModifyReq
     */
    ranch_id: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowModifyReq
     */
    schedule_id?: number;
    /**
     * 
     * @type {string}
     * @memberof SellCowModifyReq
     */
    watched_at: string;
}
/**
 * 
 * @export
 * @interface SellCowModifyReqCarcass
 */
export interface SellCowModifyReqCarcass {
    /**
     * 
     * @type {number}
     * @memberof SellCowModifyReqCarcass
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowModifyReqCarcass
     */
    defect: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowModifyReqCarcass
     */
    oleic_acid?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowModifyReqCarcass
     */
    quality_bcs?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowModifyReqCarcass
     */
    quality_bms?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowModifyReqCarcass
     */
    quality_fat?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowModifyReqCarcass
     */
    quality_texture?: number;
    /**
     * 
     * @type {string}
     * @memberof SellCowModifyReqCarcass
     */
    reward: string;
    /**
     * 
     * @type {string}
     * @memberof SellCowModifyReqCarcass
     */
    total_grade: string;
    /**
     * 
     * @type {number}
     * @memberof SellCowModifyReqCarcass
     */
    unit_price: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowModifyReqCarcass
     */
    yield_basis?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowModifyReqCarcass
     */
    yield_fat?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowModifyReqCarcass
     */
    yield_loin?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowModifyReqCarcass
     */
    yield_rib?: number;
}
/**
 * 
 * @export
 * @interface SellCowModifyReqCow
 */
export interface SellCowModifyReqCow {
    /**
     * 
     * @type {SellCowModifyReqCarcass}
     * @memberof SellCowModifyReqCow
     */
    carcass?: SellCowModifyReqCarcass;
    /**
     * 
     * @type {string}
     * @memberof SellCowModifyReqCow
     */
    comment: string;
    /**
     * 
     * @type {number}
     * @memberof SellCowModifyReqCow
     */
    cow_id: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowModifyReqCow
     */
    deactivate: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowModifyReqCow
     */
    event_id?: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowModifyReqCow
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof SellCowModifyReqCow
     */
    weight?: number;
}
/**
 * 
 * @export
 * @interface SellCowSearchReq
 */
export interface SellCowSearchReq {
    /**
     * 
     * @type {Array<number>}
     * @memberof SellCowSearchReq
     */
    cow_ids: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof SellCowSearchReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface SellMilkDeleteReq
 */
export interface SellMilkDeleteReq {
    /**
     * cow_id
     * @type {number}
     * @memberof SellMilkDeleteReq
     */
    cow_id?: number;
    /**
     * event_id
     * @type {number}
     * @memberof SellMilkDeleteReq
     */
    event_id: number;
    /**
     * ranch_id
     * @type {number}
     * @memberof SellMilkDeleteReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface SellMilkDto
 */
export interface SellMilkDto {
    /**
     * 
     * @type {number}
     * @memberof SellMilkDto
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof SellMilkDto
     */
    amount_discard: number;
    /**
     * 
     * @type {string}
     * @memberof SellMilkDto
     */
    comment: string;
    /**
     * 
     * @type {number}
     * @memberof SellMilkDto
     */
    cow_id?: number;
    /**
     * 
     * @type {number}
     * @memberof SellMilkDto
     */
    event_id: number;
    /**
     * 
     * @type {number}
     * @memberof SellMilkDto
     */
    ranch_id: number;
    /**
     * 
     * @type {number}
     * @memberof SellMilkDto
     */
    unit_price: number;
    /**
     * 
     * @type {string}
     * @memberof SellMilkDto
     */
    watched_at: string;
    /**
     * 
     * @type {string}
     * @memberof SellMilkDto
     */
    watched_by: string;
}
/**
 * 
 * @export
 * @interface SellMilkEventDto
 */
export interface SellMilkEventDto {
    /**
     * amount
     * @type {number}
     * @memberof SellMilkEventDto
     */
    amount: number;
    /**
     * amount_discard
     * @type {number}
     * @memberof SellMilkEventDto
     */
    amount_discard: number;
    /**
     * comment
     * @type {string}
     * @memberof SellMilkEventDto
     */
    comment: string;
    /**
     * created_by
     * @type {string}
     * @memberof SellMilkEventDto
     */
    created_by: string;
    /**
     * event_id
     * @type {number}
     * @memberof SellMilkEventDto
     */
    event_id: number;
    /**
     * unit_price
     * @type {number}
     * @memberof SellMilkEventDto
     */
    unit_price: number;
    /**
     * watched_at
     * @type {string}
     * @memberof SellMilkEventDto
     */
    watched_at: string;
    /**
     * watched_by_name
     * @type {string}
     * @memberof SellMilkEventDto
     */
    watched_by_name: string;
}
/**
 * 
 * @export
 * @interface SellMilkGraphDayDto
 */
export interface SellMilkGraphDayDto {
    /**
     * 
     * @type {number}
     * @memberof SellMilkGraphDayDto
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof SellMilkGraphDayDto
     */
    amount_discard: number;
    /**
     * 
     * @type {string}
     * @memberof SellMilkGraphDayDto
     */
    day: string;
}
/**
 * 
 * @export
 * @interface SellMilkGraphDto
 */
export interface SellMilkGraphDto {
    /**
     * 
     * @type {Array<SellMilkGraphDayDto>}
     * @memberof SellMilkGraphDto
     */
    days: Array<SellMilkGraphDayDto>;
    /**
     * 
     * @type {number}
     * @memberof SellMilkGraphDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof SellMilkGraphDto
     */
    total_season?: number;
}
/**
 * 
 * @export
 * @interface SellMilkGraphReq
 */
export interface SellMilkGraphReq {
    /**
     * 
     * @type {number}
     * @memberof SellMilkGraphReq
     */
    cow_id: number;
    /**
     * 
     * @type {number}
     * @memberof SellMilkGraphReq
     */
    day_span: number;
    /**
     * 
     * @type {string}
     * @memberof SellMilkGraphReq
     */
    end_day: string;
    /**
     * 
     * @type {number}
     * @memberof SellMilkGraphReq
     */
    ranch_id: number;
    /**
     * 乳期での総乳量を取得する場合、その乳期内の最終日
     * @type {string}
     * @memberof SellMilkGraphReq
     */
    season_end_day?: string;
}
/**
 * 
 * @export
 * @interface SellMilkModifyReq
 */
export interface SellMilkModifyReq {
    /**
     * amount_discards
     * @type {Array<number>}
     * @memberof SellMilkModifyReq
     */
    amount_discards: Array<number>;
    /**
     * amounts
     * @type {Array<number>}
     * @memberof SellMilkModifyReq
     */
    amounts: Array<number>;
    /**
     * comments
     * @type {Array<string>}
     * @memberof SellMilkModifyReq
     */
    comments?: Array<string>;
    /**
     * cow_ids
     * @type {Array<number>}
     * @memberof SellMilkModifyReq
     */
    cow_ids?: Array<number>;
    /**
     * event_id
     * @type {number}
     * @memberof SellMilkModifyReq
     */
    event_id?: number;
    /**
     * is_new
     * @type {number}
     * @memberof SellMilkModifyReq
     */
    is_new: number;
    /**
     * ranch_id
     * @type {number}
     * @memberof SellMilkModifyReq
     */
    ranch_id: number;
    /**
     * schedule_id
     * @type {number}
     * @memberof SellMilkModifyReq
     */
    schedule_id?: number;
    /**
     * unit_price
     * @type {number}
     * @memberof SellMilkModifyReq
     */
    unit_price: number;
    /**
     * 
     * @type {boolean}
     * @memberof SellMilkModifyReq
     */
    valid?: boolean;
    /**
     * watched_at
     * @type {string}
     * @memberof SellMilkModifyReq
     */
    watched_at: string;
}
/**
 * 
 * @export
 * @interface SensorCowModifyReq
 */
export interface SensorCowModifyReq {
    /**
     * 
     * @type {number}
     * @memberof SensorCowModifyReq
     */
    barn: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCowModifyReq
     */
    cow_id: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCowModifyReq
     */
    device_id: number;
    /**
     * 
     * @type {string}
     * @memberof SensorCowModifyReq
     */
    grouped_at: string;
    /**
     * 
     * @type {string}
     * @memberof SensorCowModifyReq
     */
    measured_at: string;
    /**
     * 
     * @type {string}
     * @memberof SensorCowModifyReq
     */
    measured_by: string;
    /**
     * 
     * @type {number}
     * @memberof SensorCowModifyReq
     */
    ranch_id: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCowModifyReq
     */
    room: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCowModifyReq
     */
    site: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCowModifyReq
     */
    temperature: number;
}
/**
 * 
 * @export
 * @interface SensorDeviceDto
 */
export interface SensorDeviceDto {
    /**
     * 
     * @type {number}
     * @memberof SensorDeviceDto
     */
    barn: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDeviceDto
     */
    cow_id: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDeviceDto
     */
    device_id?: number;
    /**
     * 
     * @type {string}
     * @memberof SensorDeviceDto
     */
    device_serial?: string;
    /**
     * 
     * @type {string}
     * @memberof SensorDeviceDto
     */
    device_type?: SensorDeviceDtoDeviceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SensorDeviceDto
     */
    local_no?: string;
    /**
     * 
     * @type {string}
     * @memberof SensorDeviceDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof SensorDeviceDto
     */
    room: number;
    /**
     * 
     * @type {number}
     * @memberof SensorDeviceDto
     */
    site: number;
    /**
     * 
     * @type {string}
     * @memberof SensorDeviceDto
     */
    trace_id: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SensorDeviceDtoDeviceTypeEnum {
    Uct2112 = 'uct2112'
}

/**
 * 
 * @export
 * @interface SensorDeviceIdDto
 */
export interface SensorDeviceIdDto {
    /**
     * 
     * @type {number}
     * @memberof SensorDeviceIdDto
     */
    device_id?: number;
}
/**
 * 
 * @export
 * @interface SensorDeviceListDto
 */
export interface SensorDeviceListDto {
    /**
     * 
     * @type {Array<SensorDeviceDto>}
     * @memberof SensorDeviceListDto
     */
    list: Array<SensorDeviceDto>;
}
/**
 * 
 * @export
 * @interface SensorDeviceListReq
 */
export interface SensorDeviceListReq {
    /**
     * 
     * @type {number}
     * @memberof SensorDeviceListReq
     */
    cow_id?: number;
    /**
     * 
     * @type {string}
     * @memberof SensorDeviceListReq
     */
    device_type: SensorDeviceListReqDeviceTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof SensorDeviceListReq
     */
    ranch_id: number;
}

/**
    * @export
    * @enum {string}
    */
export enum SensorDeviceListReqDeviceTypeEnum {
    Uct2112 = 'uct2112'
}

/**
 * 
 * @export
 * @interface SensorDeviceModifyReq
 */
export interface SensorDeviceModifyReq {
    /**
     * 
     * @type {number}
     * @memberof SensorDeviceModifyReq
     */
    cow_id?: number;
    /**
     * 
     * @type {string}
     * @memberof SensorDeviceModifyReq
     */
    device_serial: string;
    /**
     * 
     * @type {string}
     * @memberof SensorDeviceModifyReq
     */
    device_type: SensorDeviceModifyReqDeviceTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof SensorDeviceModifyReq
     */
    ranch_id: number;
}

/**
    * @export
    * @enum {string}
    */
export enum SensorDeviceModifyReqDeviceTypeEnum {
    Uct2112 = 'uct2112'
}

/**
 * 
 * @export
 * @interface SensorHistoryCowDto
 */
export interface SensorHistoryCowDto {
    /**
     * 
     * @type {number}
     * @memberof SensorHistoryCowDto
     */
    average: number;
    /**
     * 
     * @type {number}
     * @memberof SensorHistoryCowDto
     */
    average_count: number;
    /**
     * 
     * @type {string}
     * @memberof SensorHistoryCowDto
     */
    barn_name?: string;
    /**
     * 
     * @type {number}
     * @memberof SensorHistoryCowDto
     */
    cow: number;
    /**
     * 
     * @type {number}
     * @memberof SensorHistoryCowDto
     */
    house?: number;
    /**
     * 
     * @type {string}
     * @memberof SensorHistoryCowDto
     */
    measured_at: string;
    /**
     * 
     * @type {string}
     * @memberof SensorHistoryCowDto
     */
    site_name?: string;
}
/**
 * 
 * @export
 * @interface SensorHistoryCowReq
 */
export interface SensorHistoryCowReq {
    /**
     * 
     * @type {number}
     * @memberof SensorHistoryCowReq
     */
    cow_id: number;
    /**
     * yyyy-MM-dd
     * @type {string}
     * @memberof SensorHistoryCowReq
     */
    from: string;
    /**
     * 
     * @type {number}
     * @memberof SensorHistoryCowReq
     */
    ranch_id: number;
    /**
     * yyyy-MM-dd
     * @type {string}
     * @memberof SensorHistoryCowReq
     */
    to: string;
}
/**
 * 
 * @export
 * @interface SensorHistoryGroupDto
 */
export interface SensorHistoryGroupDto {
    /**
     * 
     * @type {string}
     * @memberof SensorHistoryGroupDto
     */
    barn_name?: string;
    /**
     * 
     * @type {string}
     * @memberof SensorHistoryGroupDto
     */
    grouped_at: string;
    /**
     * 
     * @type {string}
     * @memberof SensorHistoryGroupDto
     */
    site_name?: string;
    /**
     * 
     * @type {number}
     * @memberof SensorHistoryGroupDto
     */
    submit_id: number;
}
/**
 * 
 * @export
 * @interface SensorHistoryGroupReq
 */
export interface SensorHistoryGroupReq {
    /**
     * yyyy-MM-dd
     * @type {string}
     * @memberof SensorHistoryGroupReq
     */
    from: string;
    /**
     * 
     * @type {number}
     * @memberof SensorHistoryGroupReq
     */
    ranch_id: number;
    /**
     * yyyy-MM-dd
     * @type {string}
     * @memberof SensorHistoryGroupReq
     */
    to: string;
}
/**
 * 
 * @export
 * @interface SensorHistoryListDto
 */
export interface SensorHistoryListDto {
    /**
     * 
     * @type {number}
     * @memberof SensorHistoryListDto
     */
    cow_id: number;
    /**
     * 
     * @type {number}
     * @memberof SensorHistoryListDto
     */
    device_id: number;
    /**
     * 
     * @type {string}
     * @memberof SensorHistoryListDto
     */
    local_no?: string;
    /**
     * 
     * @type {string}
     * @memberof SensorHistoryListDto
     */
    measured_at: string;
    /**
     * 
     * @type {string}
     * @memberof SensorHistoryListDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof SensorHistoryListDto
     */
    temperature: number;
    /**
     * 
     * @type {string}
     * @memberof SensorHistoryListDto
     */
    trace_id: string;
}
/**
 * 
 * @export
 * @interface SensorHistoryListReq
 */
export interface SensorHistoryListReq {
    /**
     * 
     * @type {number}
     * @memberof SensorHistoryListReq
     */
    ranch_id: number;
    /**
     * 
     * @type {number}
     * @memberof SensorHistoryListReq
     */
    submit_id: number;
}
/**
 * 
 * @export
 * @interface SensorHouseModifyReq
 */
export interface SensorHouseModifyReq {
    /**
     * 
     * @type {number}
     * @memberof SensorHouseModifyReq
     */
    barn: number;
    /**
     * 
     * @type {string}
     * @memberof SensorHouseModifyReq
     */
    grouped_at: string;
    /**
     * 
     * @type {string}
     * @memberof SensorHouseModifyReq
     */
    measured_at: string;
    /**
     * 
     * @type {string}
     * @memberof SensorHouseModifyReq
     */
    measured_by: string;
    /**
     * 
     * @type {number}
     * @memberof SensorHouseModifyReq
     */
    ranch_id: number;
    /**
     * 
     * @type {number}
     * @memberof SensorHouseModifyReq
     */
    room: number;
    /**
     * 
     * @type {number}
     * @memberof SensorHouseModifyReq
     */
    site: number;
    /**
     * 
     * @type {number}
     * @memberof SensorHouseModifyReq
     */
    temperature: number;
}
/**
 * 
 * @export
 * @interface SigninDto
 */
export interface SigninDto {
    /**
     * 
     * @type {string}
     * @memberof SigninDto
     */
    icon?: string;
    /**
     * 
     * @type {number}
     * @memberof SigninDto
     */
    licenses: number;
    /**
     * 
     * @type {string}
     * @memberof SigninDto
     */
    mail: string;
    /**
     * 
     * @type {string}
     * @memberof SigninDto
     */
    name: string;
    /**
     * 
     * @type {Array<TeamRequestByUserIdDto>}
     * @memberof SigninDto
     */
    requests?: Array<TeamRequestByUserIdDto>;
    /**
     * 
     * @type {string}
     * @memberof SigninDto
     */
    setting?: string;
    /**
     * 
     * @type {Array<SigninTeamDto>}
     * @memberof SigninDto
     */
    teams: Array<SigninTeamDto>;
    /**
     * 
     * @type {string}
     * @memberof SigninDto
     */
    user_id: string;
}
/**
 * 
 * @export
 * @interface SigninReq
 */
export interface SigninReq {
    /**
     * uh_os
     * @type {string}
     * @memberof SigninReq
     */
    uh_os: string;
}
/**
 * 
 * @export
 * @interface SigninTeamDto
 */
export interface SigninTeamDto {
    /**
     * 
     * @type {string}
     * @memberof SigninTeamDto
     */
    address?: string;
    /**
     * 
     * @type {number}
     * @memberof SigninTeamDto
     */
    as_clinic_user: number;
    /**
     * 
     * @type {number}
     * @memberof SigninTeamDto
     */
    contracts: number;
    /**
     * 
     * @type {number}
     * @memberof SigninTeamDto
     */
    is_ranch: number;
    /**
     * 
     * @type {number}
     * @memberof SigninTeamDto
     */
    level: number;
    /**
     * 
     * @type {number}
     * @memberof SigninTeamDto
     */
    level_detail: number;
    /**
     * 
     * @type {string}
     * @memberof SigninTeamDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SigninTeamDto
     */
    pref_cd?: string;
    /**
     * 
     * @type {Array<TeamAcceptRequestDto>}
     * @memberof SigninTeamDto
     */
    requests?: Array<TeamAcceptRequestDto>;
    /**
     * 
     * @type {number}
     * @memberof SigninTeamDto
     */
    size: number;
    /**
     * 
     * @type {number}
     * @memberof SigninTeamDto
     */
    team_id: number;
    /**
     * 
     * @type {string}
     * @memberof SigninTeamDto
     */
    team_symbol: string;
    /**
     * 
     * @type {string}
     * @memberof SigninTeamDto
     */
    tel?: string;
    /**
     * 
     * @type {Array<SigninUnofficialRanchDto>}
     * @memberof SigninTeamDto
     */
    unofficial_ranches?: Array<SigninUnofficialRanchDto>;
}
/**
 * 
 * @export
 * @interface SigninUnofficialRanchDto
 */
export interface SigninUnofficialRanchDto {
    /**
     * 
     * @type {string}
     * @memberof SigninUnofficialRanchDto
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof SigninUnofficialRanchDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SigninUnofficialRanchDto
     */
    pref_cd?: string;
    /**
     * 
     * @type {number}
     * @memberof SigninUnofficialRanchDto
     */
    team_id: number;
    /**
     * 
     * @type {string}
     * @memberof SigninUnofficialRanchDto
     */
    tel?: string;
}
/**
 * 
 * @export
 * @interface SignupReq
 */
export interface SignupReq {
    /**
     * mail
     * @type {string}
     * @memberof SignupReq
     */
    mail: string;
    /**
     * name
     * @type {string}
     * @memberof SignupReq
     */
    name: string;
}
/**
 * 
 * @export
 * @interface SingleEventReq
 */
export interface SingleEventReq {
    /**
     * event_id
     * @type {number}
     * @memberof SingleEventReq
     */
    event_id: number;
    /**
     * ranch_id
     * @type {number}
     * @memberof SingleEventReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface SovDto
 */
export interface SovDto {
    /**
     * 
     * @type {string}
     * @memberof SovDto
     */
    comment: string;
    /**
     * 
     * @type {number}
     * @memberof SovDto
     */
    cow_id: number;
    /**
     * 
     * @type {number}
     * @memberof SovDto
     */
    event_id: number;
    /**
     * 
     * @type {number}
     * @memberof SovDto
     */
    father_exid: number;
    /**
     * 
     * @type {string}
     * @memberof SovDto
     */
    father_name: string;
    /**
     * 
     * @type {number}
     * @memberof SovDto
     */
    ranch_id: number;
    /**
     * 
     * @type {Array<SovRankDto>}
     * @memberof SovDto
     */
    ranks: Array<SovRankDto>;
    /**
     * 
     * @type {string}
     * @memberof SovDto
     */
    watched_at: string;
    /**
     * 
     * @type {string}
     * @memberof SovDto
     */
    watched_by: string;
}
/**
 * 
 * @export
 * @interface SovModifyReq
 */
export interface SovModifyReq {
    /**
     * 
     * @type {Array<SovModifyReqCow>}
     * @memberof SovModifyReq
     */
    cows: Array<SovModifyReqCow>;
    /**
     * 
     * @type {number}
     * @memberof SovModifyReq
     */
    is_new: number;
    /**
     * 
     * @type {number}
     * @memberof SovModifyReq
     */
    ranch_id: number;
    /**
     * 
     * @type {number}
     * @memberof SovModifyReq
     */
    schedule_id?: number;
    /**
     * 
     * @type {string}
     * @memberof SovModifyReq
     */
    watched_at: string;
}
/**
 * 
 * @export
 * @interface SovModifyReqCow
 */
export interface SovModifyReqCow {
    /**
     * 
     * @type {string}
     * @memberof SovModifyReqCow
     */
    comment: string;
    /**
     * 
     * @type {number}
     * @memberof SovModifyReqCow
     */
    cow_id: number;
    /**
     * 
     * @type {number}
     * @memberof SovModifyReqCow
     */
    event_id?: number;
    /**
     * 
     * @type {number}
     * @memberof SovModifyReqCow
     */
    father_exid: number;
    /**
     * 
     * @type {Array<SovModifyReqRank>}
     * @memberof SovModifyReqCow
     */
    ranks: Array<SovModifyReqRank>;
}
/**
 * 
 * @export
 * @interface SovModifyReqRank
 */
export interface SovModifyReqRank {
    /**
     * 
     * @type {number}
     * @memberof SovModifyReqRank
     */
    egg_count: number;
    /**
     * 
     * @type {number}
     * @memberof SovModifyReqRank
     */
    egg_rank: number;
    /**
     * 
     * @type {Array<SovModifyReqStage>}
     * @memberof SovModifyReqRank
     */
    stages?: Array<SovModifyReqStage>;
}
/**
 * 
 * @export
 * @interface SovModifyReqStage
 */
export interface SovModifyReqStage {
    /**
     * 
     * @type {number}
     * @memberof SovModifyReqStage
     */
    egg_count: number;
    /**
     * 
     * @type {number}
     * @memberof SovModifyReqStage
     */
    egg_stage: number;
    /**
     * 
     * @type {number}
     * @memberof SovModifyReqStage
     */
    is_frozen: number;
}
/**
 * 
 * @export
 * @interface SovRankDto
 */
export interface SovRankDto {
    /**
     * 
     * @type {number}
     * @memberof SovRankDto
     */
    egg_count: number;
    /**
     * 
     * @type {number}
     * @memberof SovRankDto
     */
    egg_rank: number;
    /**
     * 
     * @type {Array<SovStageDto>}
     * @memberof SovRankDto
     */
    stages: Array<SovStageDto>;
}
/**
 * 
 * @export
 * @interface SovStageDto
 */
export interface SovStageDto {
    /**
     * 
     * @type {number}
     * @memberof SovStageDto
     */
    egg_count: number;
    /**
     * 
     * @type {number}
     * @memberof SovStageDto
     */
    egg_stage: number;
    /**
     * 
     * @type {number}
     * @memberof SovStageDto
     */
    is_frozen: number;
    /**
     * 
     * @type {number}
     * @memberof SovStageDto
     */
    seed_lot_id?: number;
}
/**
 * 
 * @export
 * @interface SympConditionDto
 */
export interface SympConditionDto {
    /**
     * class_id
     * @type {number}
     * @memberof SympConditionDto
     */
    class_id: number;
    /**
     * detail_id
     * @type {number}
     * @memberof SympConditionDto
     */
    detail_id: number;
    /**
     * symptom_id
     * @type {number}
     * @memberof SympConditionDto
     */
    symptom_id: number;
}
/**
 * 
 * @export
 * @interface SympConditionEventDto
 */
export interface SympConditionEventDto {
    /**
     * 
     * @type {number}
     * @memberof SympConditionEventDto
     */
    class_id: number;
    /**
     * 
     * @type {string}
     * @memberof SympConditionEventDto
     */
    class_name: string;
    /**
     * 
     * @type {number}
     * @memberof SympConditionEventDto
     */
    detail_id: number;
    /**
     * 
     * @type {string}
     * @memberof SympConditionEventDto
     */
    detail_name: string;
    /**
     * 
     * @type {number}
     * @memberof SympConditionEventDto
     */
    symptom_id: number;
}
/**
 * 
 * @export
 * @interface SympDataDto
 */
export interface SympDataDto {
    /**
     * 
     * @type {number}
     * @memberof SympDataDto
     */
    active_score?: number;
    /**
     * 
     * @type {number}
     * @memberof SympDataDto
     */
    breath_count?: number;
    /**
     * 
     * @type {number}
     * @memberof SympDataDto
     */
    breath_score?: number;
    /**
     * V4.2 deprecated
     * @type {number}
     * @memberof SympDataDto
     */
    cause_no?: number;
    /**
     * V4.2 deprecated
     * @type {number}
     * @memberof SympDataDto
     */
    code_no?: number;
    /**
     * 
     * @type {string}
     * @memberof SympDataDto
     */
    comment: string;
    /**
     * 
     * @type {Array<SympConditionDto>}
     * @memberof SympDataDto
     */
    condition: Array<SympConditionDto>;
    /**
     * 
     * @type {number}
     * @memberof SympDataDto
     */
    cow_id: number;
    /**
     * V4.2 deprecated
     * @type {string}
     * @memberof SympDataDto
     */
    disease_id?: string;
    /**
     * 
     * @type {Array<SympDiseaseDto>}
     * @memberof SympDataDto
     */
    diseases: Array<SympDiseaseDto>;
    /**
     * 
     * @type {string}
     * @memberof SympDataDto
     */
    feces_color?: string;
    /**
     * 
     * @type {number}
     * @memberof SympDataDto
     */
    feces_state?: number;
    /**
     * 
     * @type {number}
     * @memberof SympDataDto
     */
    first_id?: number;
    /**
     * 
     * @type {number}
     * @memberof SympDataDto
     */
    heart_rate?: number;
    /**
     * 
     * @type {number}
     * @memberof SympDataDto
     */
    hungry_score?: number;
    /**
     * 
     * @type {string}
     * @memberof SympDataDto
     */
    notice: string;
    /**
     * V4.2 deprecated
     * @type {number}
     * @memberof SympDataDto
     */
    notice_no?: number;
    /**
     * 
     * @type {number}
     * @memberof SympDataDto
     */
    ranch_id: number;
    /**
     * 
     * @type {number}
     * @memberof SympDataDto
     */
    status?: number;
    /**
     * 
     * @type {number}
     * @memberof SympDataDto
     */
    symptom_id: number;
    /**
     * 
     * @type {string}
     * @memberof SympDataDto
     */
    symptom_name: string;
    /**
     * 
     * @type {number}
     * @memberof SympDataDto
     */
    temperature_x10?: number;
    /**
     * 
     * @type {Array<SympTreatDto>}
     * @memberof SympDataDto
     */
    treat: Array<SympTreatDto>;
    /**
     * 
     * @type {VisitDistanceDto}
     * @memberof SympDataDto
     */
    visit?: VisitDistanceDto;
    /**
     * 
     * @type {string}
     * @memberof SympDataDto
     */
    watched_at: string;
    /**
     * ※APIによって、idの場合と名前の場合がある
     * @type {string}
     * @memberof SympDataDto
     */
    watched_by: string;
}
/**
 * 
 * @export
 * @interface SympDiseaseDto
 */
export interface SympDiseaseDto {
    /**
     * 
     * @type {number}
     * @memberof SympDiseaseDto
     */
    cause_no?: number;
    /**
     * 
     * @type {number}
     * @memberof SympDiseaseDto
     */
    code_no?: number;
    /**
     * 
     * @type {number}
     * @memberof SympDiseaseDto
     */
    disease_id: number;
}
/**
 * 
 * @export
 * @interface SympDiseaseEventDto
 */
export interface SympDiseaseEventDto {
    /**
     * 
     * @type {string}
     * @memberof SympDiseaseEventDto
     */
    cause_name?: string;
    /**
     * 
     * @type {number}
     * @memberof SympDiseaseEventDto
     */
    code_no?: number;
    /**
     * 
     * @type {string}
     * @memberof SympDiseaseEventDto
     */
    disease_name: string;
}
/**
 * 
 * @export
 * @interface SympEventDto
 */
export interface SympEventDto {
    /**
     * 
     * @type {number}
     * @memberof SympEventDto
     */
    active_score?: number;
    /**
     * 
     * @type {number}
     * @memberof SympEventDto
     */
    breath_count?: number;
    /**
     * 
     * @type {number}
     * @memberof SympEventDto
     */
    breath_score?: number;
    /**
     * V4.2 deprecated
     * @type {string}
     * @memberof SympEventDto
     */
    cause_name?: string;
    /**
     * V4.2 deprecated
     * @type {number}
     * @memberof SympEventDto
     */
    cause_no?: number;
    /**
     * V4.2 deprecated
     * @type {number}
     * @memberof SympEventDto
     */
    code_no?: number;
    /**
     * 
     * @type {string}
     * @memberof SympEventDto
     */
    comment: string;
    /**
     * 
     * @type {Array<SympConditionEventDto>}
     * @memberof SympEventDto
     */
    condition: Array<SympConditionEventDto>;
    /**
     * 
     * @type {number}
     * @memberof SympEventDto
     */
    cow_id: number;
    /**
     * 
     * @type {string}
     * @memberof SympEventDto
     */
    created_by: string;
    /**
     * V4.2 deprecated
     * @type {string}
     * @memberof SympEventDto
     */
    disease_id?: string;
    /**
     * V4.2 deprecated
     * @type {string}
     * @memberof SympEventDto
     */
    disease_name?: string;
    /**
     * 
     * @type {Array<SympDiseaseEventDto>}
     * @memberof SympEventDto
     */
    diseases: Array<SympDiseaseEventDto>;
    /**
     * 
     * @type {string}
     * @memberof SympEventDto
     */
    feces_color?: string;
    /**
     * 
     * @type {number}
     * @memberof SympEventDto
     */
    feces_state?: number;
    /**
     * 
     * @type {number}
     * @memberof SympEventDto
     */
    first_id?: number;
    /**
     * 
     * @type {number}
     * @memberof SympEventDto
     */
    heart_rate?: number;
    /**
     * 
     * @type {number}
     * @memberof SympEventDto
     */
    hungry_score?: number;
    /**
     * 
     * @type {string}
     * @memberof SympEventDto
     */
    is_male: string;
    /**
     * 
     * @type {string}
     * @memberof SympEventDto
     */
    local_no?: string;
    /**
     * 
     * @type {string}
     * @memberof SympEventDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SympEventDto
     */
    notice: string;
    /**
     * V4.2 deprecated
     * @type {string}
     * @memberof SympEventDto
     */
    notice_name?: string;
    /**
     * V4.2 deprecated
     * @type {number}
     * @memberof SympEventDto
     */
    notice_no?: number;
    /**
     * 
     * @type {number}
     * @memberof SympEventDto
     */
    ranch_id: number;
    /**
     * 
     * @type {number}
     * @memberof SympEventDto
     */
    status?: number;
    /**
     * 
     * @type {number}
     * @memberof SympEventDto
     */
    symptom_id: number;
    /**
     * 
     * @type {string}
     * @memberof SympEventDto
     */
    symptom_name: string;
    /**
     * 
     * @type {number}
     * @memberof SympEventDto
     */
    temperature_x10?: number;
    /**
     * 
     * @type {string}
     * @memberof SympEventDto
     */
    trace_id: string;
    /**
     * 
     * @type {Array<TreatEventDto>}
     * @memberof SympEventDto
     */
    treat: Array<TreatEventDto>;
    /**
     * 
     * @type {string}
     * @memberof SympEventDto
     */
    watched_at: string;
    /**
     * ※APIによって、idの場合と名前の場合がある
     * @type {string}
     * @memberof SympEventDto
     */
    watched_by: string;
}
/**
 * 
 * @export
 * @interface SympTreatDto
 */
export interface SympTreatDto {
    /**
     * amount
     * @type {number}
     * @memberof SympTreatDto
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof SympTreatDto
     */
    benefit_type: number;
    /**
     * medicine_id
     * @type {number}
     * @memberof SympTreatDto
     */
    medicine_id?: number;
    /**
     * note
     * @type {string}
     * @memberof SympTreatDto
     */
    note?: string;
    /**
     * route_id
     * @type {number}
     * @memberof SympTreatDto
     */
    route_id?: number;
    /**
     * symptom_id
     * @type {number}
     * @memberof SympTreatDto
     */
    symptom_id: number;
    /**
     * team_id
     * @type {number}
     * @memberof SympTreatDto
     */
    team_id: number;
    /**
     * treat_item_no
     * @type {number}
     * @memberof SympTreatDto
     */
    treat_item_no?: number;
    /**
     * treat_kind_no
     * @type {number}
     * @memberof SympTreatDto
     */
    treat_kind_no?: number;
    /**
     * treat_no
     * @type {number}
     * @memberof SympTreatDto
     */
    treat_no: number;
    /**
     * unit_price
     * @type {number}
     * @memberof SympTreatDto
     */
    unit_price: number;
}
/**
 * 
 * @export
 * @interface SymptomBulkModifyReq
 */
export interface SymptomBulkModifyReq {
    /**
     * 
     * @type {Array<SymptomBulkModifyReqCow>}
     * @memberof SymptomBulkModifyReq
     */
    cows: Array<SymptomBulkModifyReqCow>;
    /**
     * 
     * @type {PreloadedProgramReq}
     * @memberof SymptomBulkModifyReq
     */
    preloaded_program: PreloadedProgramReq;
    /**
     * 
     * @type {number}
     * @memberof SymptomBulkModifyReq
     */
    ranch_id: number;
    /**
     * 
     * @type {ModifyReqVisit}
     * @memberof SymptomBulkModifyReq
     */
    visit?: ModifyReqVisit;
    /**
     * 
     * @type {string}
     * @memberof SymptomBulkModifyReq
     */
    watched_at: string;
}
/**
 * 
 * @export
 * @interface SymptomBulkModifyReqCow
 */
export interface SymptomBulkModifyReqCow {
    /**
     * 
     * @type {number}
     * @memberof SymptomBulkModifyReqCow
     */
    active_score?: number;
    /**
     * 
     * @type {number}
     * @memberof SymptomBulkModifyReqCow
     */
    breath_count?: number;
    /**
     * 
     * @type {number}
     * @memberof SymptomBulkModifyReqCow
     */
    breath_score?: number;
    /**
     * V4.2 deprecated
     * @type {number}
     * @memberof SymptomBulkModifyReqCow
     */
    cause_no?: number;
    /**
     * V4.2 deprecated
     * @type {number}
     * @memberof SymptomBulkModifyReqCow
     */
    code_no?: number;
    /**
     * 
     * @type {string}
     * @memberof SymptomBulkModifyReqCow
     */
    comment?: string;
    /**
     * 
     * @type {Array<SymptomModifyReqCondition>}
     * @memberof SymptomBulkModifyReqCow
     */
    conditions?: Array<SymptomModifyReqCondition>;
    /**
     * 
     * @type {number}
     * @memberof SymptomBulkModifyReqCow
     */
    cow_id: number;
    /**
     * V4.2 deprecated
     * @type {Array<number>}
     * @memberof SymptomBulkModifyReqCow
     */
    disease_ids?: Array<number>;
    /**
     * 
     * @type {Array<SymptomModifyReqDisease>}
     * @memberof SymptomBulkModifyReqCow
     */
    diseases?: Array<SymptomModifyReqDisease>;
    /**
     * 
     * @type {string}
     * @memberof SymptomBulkModifyReqCow
     */
    feces_color?: string;
    /**
     * 
     * @type {number}
     * @memberof SymptomBulkModifyReqCow
     */
    feces_state?: number;
    /**
     * 
     * @type {number}
     * @memberof SymptomBulkModifyReqCow
     */
    first_id?: number;
    /**
     * 
     * @type {number}
     * @memberof SymptomBulkModifyReqCow
     */
    heart_rate?: number;
    /**
     * 
     * @type {number}
     * @memberof SymptomBulkModifyReqCow
     */
    hungry_score?: number;
    /**
     * 
     * @type {string}
     * @memberof SymptomBulkModifyReqCow
     */
    notice?: string;
    /**
     * V4.2 deprecated
     * @type {number}
     * @memberof SymptomBulkModifyReqCow
     */
    notice_no?: number;
    /**
     * 
     * @type {Array<EventNextScheduleReq>}
     * @memberof SymptomBulkModifyReqCow
     */
    plans?: Array<EventNextScheduleReq>;
    /**
     * 
     * @type {number}
     * @memberof SymptomBulkModifyReqCow
     */
    status?: number;
    /**
     * 
     * @type {string}
     * @memberof SymptomBulkModifyReqCow
     */
    symptom_name?: string;
    /**
     * 
     * @type {number}
     * @memberof SymptomBulkModifyReqCow
     */
    temperature_x10?: number;
    /**
     * 
     * @type {Array<ModifyReqTreat>}
     * @memberof SymptomBulkModifyReqCow
     */
    treats: Array<ModifyReqTreat>;
}
/**
 * 
 * @export
 * @interface SymptomDto
 */
export interface SymptomDto {
    /**
     * 
     * @type {SympDataDto}
     * @memberof SymptomDto
     */
    info: SympDataDto;
}
/**
 * 
 * @export
 * @interface SymptomHistoryDto
 */
export interface SymptomHistoryDto {
    /**
     * 
     * @type {Array<SymptomHistoryInfoDto>}
     * @memberof SymptomHistoryDto
     */
    info: Array<SymptomHistoryInfoDto>;
}
/**
 * 
 * @export
 * @interface SymptomHistoryInfoDto
 */
export interface SymptomHistoryInfoDto {
    /**
     * 
     * @type {number}
     * @memberof SymptomHistoryInfoDto
     */
    active_score?: number;
    /**
     * 
     * @type {number}
     * @memberof SymptomHistoryInfoDto
     */
    breath_count?: number;
    /**
     * 
     * @type {number}
     * @memberof SymptomHistoryInfoDto
     */
    breath_score?: number;
    /**
     * 
     * @type {string}
     * @memberof SymptomHistoryInfoDto
     */
    comment: string;
    /**
     * 
     * @type {number}
     * @memberof SymptomHistoryInfoDto
     */
    cow_id: number;
    /**
     * 
     * @type {string}
     * @memberof SymptomHistoryInfoDto
     */
    feces_color?: string;
    /**
     * 
     * @type {number}
     * @memberof SymptomHistoryInfoDto
     */
    feces_state?: number;
    /**
     * 
     * @type {number}
     * @memberof SymptomHistoryInfoDto
     */
    first_id?: number;
    /**
     * 
     * @type {number}
     * @memberof SymptomHistoryInfoDto
     */
    heart_rate?: number;
    /**
     * 
     * @type {number}
     * @memberof SymptomHistoryInfoDto
     */
    hungry_score?: number;
    /**
     * 
     * @type {string}
     * @memberof SymptomHistoryInfoDto
     */
    local_no?: string;
    /**
     * 
     * @type {string}
     * @memberof SymptomHistoryInfoDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SymptomHistoryInfoDto
     */
    notice: string;
    /**
     * 
     * @type {number}
     * @memberof SymptomHistoryInfoDto
     */
    ranch_id: number;
    /**
     * 
     * @type {number}
     * @memberof SymptomHistoryInfoDto
     */
    status?: number;
    /**
     * 
     * @type {number}
     * @memberof SymptomHistoryInfoDto
     */
    symptom_id: number;
    /**
     * 
     * @type {string}
     * @memberof SymptomHistoryInfoDto
     */
    symptom_name: string;
    /**
     * 
     * @type {number}
     * @memberof SymptomHistoryInfoDto
     */
    temperature_x10?: number;
    /**
     * 
     * @type {string}
     * @memberof SymptomHistoryInfoDto
     */
    trace_id: string;
    /**
     * 
     * @type {Array<SympTreatDto>}
     * @memberof SymptomHistoryInfoDto
     */
    treat: Array<SympTreatDto>;
    /**
     * 
     * @type {string}
     * @memberof SymptomHistoryInfoDto
     */
    watched_at: string;
    /**
     * ※APIによって、idの場合と名前の場合がある
     * @type {string}
     * @memberof SymptomHistoryInfoDto
     */
    watched_by: string;
}
/**
 * 
 * @export
 * @interface SymptomHistoryReq
 */
export interface SymptomHistoryReq {
    /**
     * 
     * @type {number}
     * @memberof SymptomHistoryReq
     */
    cow_id: number;
    /**
     * 
     * @type {number}
     * @memberof SymptomHistoryReq
     */
    days: number;
    /**
     * 紐づけ済の前回診療の初診ID
     * @type {number}
     * @memberof SymptomHistoryReq
     */
    first_id?: number;
    /**
     * 
     * @type {number}
     * @memberof SymptomHistoryReq
     */
    ranch_id: number;
    /**
     * 編集中の再診診療の記録日時
     * @type {string}
     * @memberof SymptomHistoryReq
     */
    watched_at?: string;
}
/**
 * 
 * @export
 * @interface SymptomInfoReq
 */
export interface SymptomInfoReq {
    /**
     * 
     * @type {number}
     * @memberof SymptomInfoReq
     */
    first_id: number;
    /**
     * 
     * @type {number}
     * @memberof SymptomInfoReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface SymptomLatestReq
 */
export interface SymptomLatestReq {
    /**
     * 症状・処置・投薬経路マスタの参照に使用する診療所ID（薬マスタはこのIDまたはranch_idで参照可能）
     * @type {number}
     * @memberof SymptomLatestReq
     */
    clinic_id?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof SymptomLatestReq
     */
    cow_ids: Array<number>;
    /**
     * 何日間まで遡って探すか
     * @type {number}
     * @memberof SymptomLatestReq
     */
    days: number;
    /**
     * 
     * @type {number}
     * @memberof SymptomLatestReq
     */
    ranch_id: number;
    /**
     * 
     * @type {string}
     * @memberof SymptomLatestReq
     */
    watched_at: string;
}
/**
 * 
 * @export
 * @interface SymptomModifyReq
 */
export interface SymptomModifyReq {
    /**
     * 
     * @type {number}
     * @memberof SymptomModifyReq
     */
    active_score?: number;
    /**
     * 
     * @type {number}
     * @memberof SymptomModifyReq
     */
    breath_count?: number;
    /**
     * 
     * @type {number}
     * @memberof SymptomModifyReq
     */
    breath_score?: number;
    /**
     * V4.2 deprecated
     * @type {number}
     * @memberof SymptomModifyReq
     */
    cause_no?: number;
    /**
     * V4.2 deprecated
     * @type {number}
     * @memberof SymptomModifyReq
     */
    code_no?: number;
    /**
     * 
     * @type {string}
     * @memberof SymptomModifyReq
     */
    comment: string;
    /**
     * 
     * @type {Array<SymptomModifyReqCondition>}
     * @memberof SymptomModifyReq
     */
    condition?: Array<SymptomModifyReqCondition>;
    /**
     * 
     * @type {Array<number>}
     * @memberof SymptomModifyReq
     */
    cow_ids: Array<number>;
    /**
     * V4.2 deprecated
     * @type {Array<number>}
     * @memberof SymptomModifyReq
     */
    disease_ids?: Array<number>;
    /**
     * 
     * @type {Array<SymptomModifyReqDisease>}
     * @memberof SymptomModifyReq
     */
    diseases?: Array<SymptomModifyReqDisease>;
    /**
     * 
     * @type {string}
     * @memberof SymptomModifyReq
     */
    feces_color?: string;
    /**
     * 
     * @type {number}
     * @memberof SymptomModifyReq
     */
    feces_state?: number;
    /**
     * 
     * @type {number}
     * @memberof SymptomModifyReq
     */
    first_id?: number;
    /**
     * 
     * @type {Array<GrowthModifyReqCow>}
     * @memberof SymptomModifyReq
     */
    growths?: Array<GrowthModifyReqCow>;
    /**
     * 
     * @type {number}
     * @memberof SymptomModifyReq
     */
    heart_rate?: number;
    /**
     * 
     * @type {number}
     * @memberof SymptomModifyReq
     */
    hungry_score?: number;
    /**
     * 
     * @type {number}
     * @memberof SymptomModifyReq
     */
    is_new: number;
    /**
     * 
     * @type {string}
     * @memberof SymptomModifyReq
     */
    notice?: string;
    /**
     * V4.2 deprecated
     * @type {number}
     * @memberof SymptomModifyReq
     */
    notice_no?: number;
    /**
     * 
     * @type {Array<EventNextScheduleReq>}
     * @memberof SymptomModifyReq
     */
    plans?: Array<EventNextScheduleReq>;
    /**
     * 
     * @type {PreloadedProgramReq}
     * @memberof SymptomModifyReq
     */
    preloaded_program: PreloadedProgramReq;
    /**
     * 
     * @type {number}
     * @memberof SymptomModifyReq
     */
    ranch_id: number;
    /**
     * 
     * @type {number}
     * @memberof SymptomModifyReq
     */
    schedule_id?: number;
    /**
     * 
     * @type {number}
     * @memberof SymptomModifyReq
     */
    status?: number;
    /**
     * 
     * @type {number}
     * @memberof SymptomModifyReq
     */
    symptom_id?: number;
    /**
     * 
     * @type {string}
     * @memberof SymptomModifyReq
     */
    symptom_name: string;
    /**
     * 
     * @type {number}
     * @memberof SymptomModifyReq
     */
    temperature_x10?: number;
    /**
     * 
     * @type {Array<ModifyReqTreat>}
     * @memberof SymptomModifyReq
     */
    treat: Array<ModifyReqTreat>;
    /**
     * 1: schedule_idで指定されたスケジュールに紐づく原因イベントがあれば初診として扱う（新規のみ有効）
     * @type {number}
     * @memberof SymptomModifyReq
     */
    uses_schedule_as_first_id?: number;
    /**
     * 
     * @type {ModifyReqVisit}
     * @memberof SymptomModifyReq
     */
    visit?: ModifyReqVisit;
    /**
     * 
     * @type {string}
     * @memberof SymptomModifyReq
     */
    watched_at: string;
    /**
     * 
     * @type {number}
     * @memberof SymptomModifyReq
     */
    watching?: number;
}
/**
 * 
 * @export
 * @interface SymptomModifyReqCondition
 */
export interface SymptomModifyReqCondition {
    /**
     * class_id
     * @type {number}
     * @memberof SymptomModifyReqCondition
     */
    class_id: number;
    /**
     * detail_id
     * @type {number}
     * @memberof SymptomModifyReqCondition
     */
    detail_id: number;
}
/**
 * 
 * @export
 * @interface SymptomModifyReqDisease
 */
export interface SymptomModifyReqDisease {
    /**
     * 
     * @type {number}
     * @memberof SymptomModifyReqDisease
     */
    cause_no?: number;
    /**
     * 
     * @type {number}
     * @memberof SymptomModifyReqDisease
     */
    code_no?: number;
    /**
     * 
     * @type {number}
     * @memberof SymptomModifyReqDisease
     */
    disease_id: number;
}
/**
 * 
 * @export
 * @interface TagIdAndNameReq
 */
export interface TagIdAndNameReq {
    /**
     * 
     * @type {number}
     * @memberof TagIdAndNameReq
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TagIdAndNameReq
     */
    name: string;
}
/**
 * 
 * @export
 * @interface TeamAcceptRequestDto
 */
export interface TeamAcceptRequestDto {
    /**
     * from_user_id
     * @type {string}
     * @memberof TeamAcceptRequestDto
     */
    from_user_id: string;
    /**
     * from_user_name
     * @type {string}
     * @memberof TeamAcceptRequestDto
     */
    from_user_name: string;
}
/**
 * 
 * @export
 * @interface TeamAuthDeleteReq
 */
export interface TeamAuthDeleteReq {
    /**
     * team_id
     * @type {number}
     * @memberof TeamAuthDeleteReq
     */
    team_id: number;
    /**
     * user_id
     * @type {string}
     * @memberof TeamAuthDeleteReq
     */
    user_id: string;
}
/**
 * 
 * @export
 * @interface TeamAuthModifyReq
 */
export interface TeamAuthModifyReq {
    /**
     * level
     * @type {number}
     * @memberof TeamAuthModifyReq
     */
    level: number;
    /**
     * level_detail
     * @type {number}
     * @memberof TeamAuthModifyReq
     */
    level_detail: number;
    /**
     * team_id
     * @type {number}
     * @memberof TeamAuthModifyReq
     */
    team_id: number;
    /**
     * user_id
     * @type {string}
     * @memberof TeamAuthModifyReq
     */
    user_id: string;
}
/**
 * 
 * @export
 * @interface TeamConditionBookmarkReq
 */
export interface TeamConditionBookmarkReq {
    /**
     * class_id
     * @type {number}
     * @memberof TeamConditionBookmarkReq
     */
    class_id: number;
    /**
     * is_bookmarked
     * @type {number}
     * @memberof TeamConditionBookmarkReq
     */
    is_bookmarked: number;
    /**
     * team_id
     * @type {number}
     * @memberof TeamConditionBookmarkReq
     */
    team_id: number;
    /**
     * user_id
     * @type {string}
     * @memberof TeamConditionBookmarkReq
     */
    user_id: string;
}
/**
 * 
 * @export
 * @interface TeamConditionClassDto
 */
export interface TeamConditionClassDto {
    /**
     * class_id
     * @type {number}
     * @memberof TeamConditionClassDto
     */
    class_id?: number;
    /**
     * condition_id
     * @type {number}
     * @memberof TeamConditionClassDto
     */
    condition_id?: number;
    /**
     * details
     * @type {Array<TeamConditionDetailDto>}
     * @memberof TeamConditionClassDto
     */
    details?: Array<TeamConditionDetailDto>;
    /**
     * is_bookmarked
     * @type {number}
     * @memberof TeamConditionClassDto
     */
    is_bookmarked?: number;
    /**
     * name
     * @type {string}
     * @memberof TeamConditionClassDto
     */
    name?: string;
    /**
     * team_id
     * @type {number}
     * @memberof TeamConditionClassDto
     */
    team_id?: number;
}
/**
 * 
 * @export
 * @interface TeamConditionDeleteReq
 */
export interface TeamConditionDeleteReq {
    /**
     * class_id
     * @type {number}
     * @memberof TeamConditionDeleteReq
     */
    class_id: number;
    /**
     * team_id
     * @type {number}
     * @memberof TeamConditionDeleteReq
     */
    team_id: number;
    /**
     * user_id
     * @type {string}
     * @memberof TeamConditionDeleteReq
     */
    user_id: string;
}
/**
 * 
 * @export
 * @interface TeamConditionDetailDto
 */
export interface TeamConditionDetailDto {
    /**
     * detail_id
     * @type {number}
     * @memberof TeamConditionDetailDto
     */
    detail_id?: number;
    /**
     * name
     * @type {string}
     * @memberof TeamConditionDetailDto
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface TeamConditionDto
 */
export interface TeamConditionDto {
    /**
     * classes
     * @type {Array<TeamConditionClassDto>}
     * @memberof TeamConditionDto
     */
    classes?: Array<TeamConditionClassDto>;
    /**
     * condition_id
     * @type {number}
     * @memberof TeamConditionDto
     */
    condition_id?: number;
    /**
     * name
     * @type {string}
     * @memberof TeamConditionDto
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface TeamConditionModifyReq
 */
export interface TeamConditionModifyReq {
    /**
     * class_id
     * @type {number}
     * @memberof TeamConditionModifyReq
     */
    class_id: number;
    /**
     * condition_id
     * @type {number}
     * @memberof TeamConditionModifyReq
     */
    condition_id: number;
    /**
     * details
     * @type {Array<TeamConditionDetailDto>}
     * @memberof TeamConditionModifyReq
     */
    details: Array<TeamConditionDetailDto>;
    /**
     * is_new
     * @type {number}
     * @memberof TeamConditionModifyReq
     */
    is_new: number;
    /**
     * name
     * @type {string}
     * @memberof TeamConditionModifyReq
     */
    name: string;
    /**
     * team_id
     * @type {number}
     * @memberof TeamConditionModifyReq
     */
    team_id: number;
    /**
     * user_id
     * @type {string}
     * @memberof TeamConditionModifyReq
     */
    user_id: string;
}
/**
 * 
 * @export
 * @interface TeamConditionSortReq
 */
export interface TeamConditionSortReq {
    /**
     * condition_id
     * @type {number}
     * @memberof TeamConditionSortReq
     */
    condition_id: number;
    /**
     * order
     * @type {Array<number>}
     * @memberof TeamConditionSortReq
     */
    order: Array<number>;
    /**
     * team_id
     * @type {number}
     * @memberof TeamConditionSortReq
     */
    team_id: number;
    /**
     * user_id
     * @type {string}
     * @memberof TeamConditionSortReq
     */
    user_id: string;
}
/**
 * 
 * @export
 * @interface TeamDiseaseCauseDto
 */
export interface TeamDiseaseCauseDto {
    /**
     * 
     * @type {number}
     * @memberof TeamDiseaseCauseDto
     */
    cause_no?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamDiseaseCauseDto
     */
    code_no?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamDiseaseCauseDto
     */
    disease_id: number;
    /**
     * 
     * @type {number}
     * @memberof TeamDiseaseCauseDto
     */
    status: number;
}
/**
 * 
 * @export
 * @interface TeamDiseaseListDto
 */
export interface TeamDiseaseListDto {
    /**
     * 
     * @type {Array<TeamDiseaseCauseDto>}
     * @memberof TeamDiseaseListDto
     */
    list: Array<TeamDiseaseCauseDto>;
    /**
     * 
     * @type {number}
     * @memberof TeamDiseaseListDto
     */
    team_id: number;
}
/**
 * 
 * @export
 * @interface TeamDiseaseModifyReq
 */
export interface TeamDiseaseModifyReq {
    /**
     * 
     * @type {number}
     * @memberof TeamDiseaseModifyReq
     */
    code_no?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamDiseaseModifyReq
     */
    disease_id: number;
    /**
     * 疾病項目のみ編集する場合はnullを指定（空リストの場合は項目をすべて削除）
     * @type {Array<TeamDiseaseModifyReqItem>}
     * @memberof TeamDiseaseModifyReq
     */
    items?: Array<TeamDiseaseModifyReqItem>;
    /**
     * 
     * @type {number}
     * @memberof TeamDiseaseModifyReq
     */
    status: number;
    /**
     * 
     * @type {number}
     * @memberof TeamDiseaseModifyReq
     */
    team_id: number;
}
/**
 * 
 * @export
 * @interface TeamDiseaseModifyReqItem
 */
export interface TeamDiseaseModifyReqItem {
    /**
     * 
     * @type {number}
     * @memberof TeamDiseaseModifyReqItem
     */
    cause_no: number;
    /**
     * 
     * @type {number}
     * @memberof TeamDiseaseModifyReqItem
     */
    code_no?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamDiseaseModifyReqItem
     */
    status: number;
}
/**
 * 
 * @export
 * @interface TeamDiseaseReq
 */
export interface TeamDiseaseReq {
    /**
     * 
     * @type {Array<number>}
     * @memberof TeamDiseaseReq
     */
    team_ids: Array<number>;
}
/**
 * 
 * @export
 * @interface TeamMedicineDao
 */
export interface TeamMedicineDao {
    /**
     * category
     * @type {number}
     * @memberof TeamMedicineDao
     */
    category: number;
    /**
     * created_at
     * @type {string}
     * @memberof TeamMedicineDao
     */
    created_at: string;
    /**
     * created_by
     * @type {string}
     * @memberof TeamMedicineDao
     */
    created_by?: string;
    /**
     * default_amount
     * @type {number}
     * @memberof TeamMedicineDao
     */
    default_amount?: number;
    /**
     * default_amount_weight
     * @type {number}
     * @memberof TeamMedicineDao
     */
    default_amount_weight?: number;
    /**
     * default_route_id
     * @type {number}
     * @memberof TeamMedicineDao
     */
    default_route_id?: number;
    /**
     * disp_no
     * @type {number}
     * @memberof TeamMedicineDao
     */
    disp_no: number;
    /**
     * is_deleted
     * @type {number}
     * @memberof TeamMedicineDao
     */
    is_deleted: number;
    /**
     * is_prevention
     * @type {number}
     * @memberof TeamMedicineDao
     */
    is_prevention: number;
    /**
     * is_treatment
     * @type {number}
     * @memberof TeamMedicineDao
     */
    is_treatment: number;
    /**
     * medicine_id
     * @type {number}
     * @memberof TeamMedicineDao
     */
    medicine_id: number;
    /**
     * name
     * @type {string}
     * @memberof TeamMedicineDao
     */
    name: string;
    /**
     * team_id
     * @type {number}
     * @memberof TeamMedicineDao
     */
    team_id: number;
    /**
     * unit
     * @type {string}
     * @memberof TeamMedicineDao
     */
    unit: string;
    /**
     * unit_price
     * @type {number}
     * @memberof TeamMedicineDao
     */
    unit_price: number;
    /**
     * updated_at
     * @type {string}
     * @memberof TeamMedicineDao
     */
    updated_at: string;
    /**
     * updated_by
     * @type {string}
     * @memberof TeamMedicineDao
     */
    updated_by: string;
    /**
     * washout_meat_day
     * @type {number}
     * @memberof TeamMedicineDao
     */
    washout_meat_day?: number;
    /**
     * washout_milk_hour
     * @type {number}
     * @memberof TeamMedicineDao
     */
    washout_milk_hour?: number;
}
/**
 * 
 * @export
 * @interface TeamMedicineDeleteReq
 */
export interface TeamMedicineDeleteReq {
    /**
     * medicine_id
     * @type {number}
     * @memberof TeamMedicineDeleteReq
     */
    medicine_id: number;
    /**
     * team_id
     * @type {number}
     * @memberof TeamMedicineDeleteReq
     */
    team_id: number;
    /**
     * user_id
     * @type {string}
     * @memberof TeamMedicineDeleteReq
     */
    user_id: string;
}
/**
 * 
 * @export
 * @interface TeamMedicineListDto
 */
export interface TeamMedicineListDto {
    /**
     * list
     * @type {Array<TeamMedicineDao>}
     * @memberof TeamMedicineListDto
     */
    list?: Array<TeamMedicineDao>;
}
/**
 * 
 * @export
 * @interface TeamMedicineModifyReq
 */
export interface TeamMedicineModifyReq {
    /**
     * category
     * @type {number}
     * @memberof TeamMedicineModifyReq
     */
    category: number;
    /**
     * default_amount
     * @type {number}
     * @memberof TeamMedicineModifyReq
     */
    default_amount?: number;
    /**
     * default_amount_weight
     * @type {number}
     * @memberof TeamMedicineModifyReq
     */
    default_amount_weight?: number;
    /**
     * default_route_id
     * @type {number}
     * @memberof TeamMedicineModifyReq
     */
    default_route_id?: number;
    /**
     * is_new
     * @type {number}
     * @memberof TeamMedicineModifyReq
     */
    is_new: number;
    /**
     * is_prevention
     * @type {number}
     * @memberof TeamMedicineModifyReq
     */
    is_prevention: number;
    /**
     * is_treatment
     * @type {number}
     * @memberof TeamMedicineModifyReq
     */
    is_treatment: number;
    /**
     * medicine_id
     * @type {number}
     * @memberof TeamMedicineModifyReq
     */
    medicine_id: number;
    /**
     * name
     * @type {string}
     * @memberof TeamMedicineModifyReq
     */
    name: string;
    /**
     * team_id
     * @type {number}
     * @memberof TeamMedicineModifyReq
     */
    team_id: number;
    /**
     * unit
     * @type {string}
     * @memberof TeamMedicineModifyReq
     */
    unit: string;
    /**
     * unit_price
     * @type {number}
     * @memberof TeamMedicineModifyReq
     */
    unit_price: number;
    /**
     * user_id
     * @type {string}
     * @memberof TeamMedicineModifyReq
     */
    user_id: string;
    /**
     * washout_meat_day
     * @type {number}
     * @memberof TeamMedicineModifyReq
     */
    washout_meat_day?: number;
    /**
     * washout_milk_hour
     * @type {number}
     * @memberof TeamMedicineModifyReq
     */
    washout_milk_hour?: number;
}
/**
 * 
 * @export
 * @interface TeamMedicineRouteDeleteReq
 */
export interface TeamMedicineRouteDeleteReq {
    /**
     * 
     * @type {number}
     * @memberof TeamMedicineRouteDeleteReq
     */
    route_id: number;
    /**
     * 
     * @type {number}
     * @memberof TeamMedicineRouteDeleteReq
     */
    team_id: number;
}
/**
 * 
 * @export
 * @interface TeamMedicineRouteDto
 */
export interface TeamMedicineRouteDto {
    /**
     * 
     * @type {number}
     * @memberof TeamMedicineRouteDto
     */
    base_fee: number;
    /**
     * 
     * @type {number}
     * @memberof TeamMedicineRouteDto
     */
    base_limit?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamMedicineRouteDto
     */
    base_point_a?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamMedicineRouteDto
     */
    base_point_b?: number;
    /**
     * 
     * @type {string}
     * @memberof TeamMedicineRouteDto
     */
    code?: string;
    /**
     * 
     * @type {number}
     * @memberof TeamMedicineRouteDto
     */
    extra_fee?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamMedicineRouteDto
     */
    extra_point_a?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamMedicineRouteDto
     */
    extra_point_b?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamMedicineRouteDto
     */
    extra_step?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamMedicineRouteDto
     */
    is_free_medicine: number;
    /**
     * 
     * @type {number}
     * @memberof TeamMedicineRouteDto
     */
    is_instruction: number;
    /**
     * 
     * @type {number}
     * @memberof TeamMedicineRouteDto
     */
    is_once_fee: number;
    /**
     * 
     * @type {string}
     * @memberof TeamMedicineRouteDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof TeamMedicineRouteDto
     */
    prior_fee_route_id?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamMedicineRouteDto
     */
    route_id: number;
    /**
     * 
     * @type {number}
     * @memberof TeamMedicineRouteDto
     */
    team_id?: number;
}
/**
 * 
 * @export
 * @interface TeamMedicineRouteListDto
 */
export interface TeamMedicineRouteListDto {
    /**
     * 
     * @type {Array<TeamMedicineRouteDto>}
     * @memberof TeamMedicineRouteListDto
     */
    list: Array<TeamMedicineRouteDto>;
}
/**
 * 
 * @export
 * @interface TeamMedicineRouteModifyReq
 */
export interface TeamMedicineRouteModifyReq {
    /**
     * 
     * @type {number}
     * @memberof TeamMedicineRouteModifyReq
     */
    base_fee: number;
    /**
     * 
     * @type {number}
     * @memberof TeamMedicineRouteModifyReq
     */
    base_limit?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamMedicineRouteModifyReq
     */
    base_point_a?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamMedicineRouteModifyReq
     */
    base_point_b?: number;
    /**
     * 
     * @type {string}
     * @memberof TeamMedicineRouteModifyReq
     */
    code?: string;
    /**
     * 
     * @type {number}
     * @memberof TeamMedicineRouteModifyReq
     */
    extra_fee?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamMedicineRouteModifyReq
     */
    extra_point_a?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamMedicineRouteModifyReq
     */
    extra_point_b?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamMedicineRouteModifyReq
     */
    extra_step?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamMedicineRouteModifyReq
     */
    is_free_medicine?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamMedicineRouteModifyReq
     */
    is_instruction?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamMedicineRouteModifyReq
     */
    is_new: number;
    /**
     * 
     * @type {number}
     * @memberof TeamMedicineRouteModifyReq
     */
    is_once_fee?: number;
    /**
     * 
     * @type {string}
     * @memberof TeamMedicineRouteModifyReq
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof TeamMedicineRouteModifyReq
     */
    prior_fee_route_id?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamMedicineRouteModifyReq
     */
    route_id: number;
    /**
     * 
     * @type {number}
     * @memberof TeamMedicineRouteModifyReq
     */
    team_id: number;
}
/**
 * 
 * @export
 * @interface TeamMedicineRouteSortReq
 */
export interface TeamMedicineRouteSortReq {
    /**
     * order
     * @type {Array<number>}
     * @memberof TeamMedicineRouteSortReq
     */
    order: Array<number>;
    /**
     * team_id
     * @type {number}
     * @memberof TeamMedicineRouteSortReq
     */
    team_id: number;
    /**
     * user_id
     * @type {string}
     * @memberof TeamMedicineRouteSortReq
     */
    user_id: string;
}
/**
 * 
 * @export
 * @interface TeamMedicineSortReq
 */
export interface TeamMedicineSortReq {
    /**
     * order
     * @type {Array<number>}
     * @memberof TeamMedicineSortReq
     */
    order: Array<number>;
    /**
     * team_id
     * @type {number}
     * @memberof TeamMedicineSortReq
     */
    team_id: number;
    /**
     * user_id
     * @type {string}
     * @memberof TeamMedicineSortReq
     */
    user_id: string;
}
/**
 * 
 * @export
 * @interface TeamModifyReq
 */
export interface TeamModifyReq {
    /**
     * 
     * @type {string}
     * @memberof TeamModifyReq
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof TeamModifyReq
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TeamModifyReq
     */
    pref_cd: string;
    /**
     * 
     * @type {number}
     * @memberof TeamModifyReq
     */
    team_id: number;
    /**
     * 
     * @type {string}
     * @memberof TeamModifyReq
     */
    tel: string;
}
/**
 * 
 * @export
 * @interface TeamNameDto
 */
export interface TeamNameDto {
    /**
     * name
     * @type {string}
     * @memberof TeamNameDto
     */
    name: string;
    /**
     * team_id
     * @type {number}
     * @memberof TeamNameDto
     */
    team_id: number;
    /**
     * team_type
     * @type {string}
     * @memberof TeamNameDto
     */
    team_type: string;
}
/**
 * 
 * @export
 * @interface TeamNameReq
 */
export interface TeamNameReq {
    /**
     * team_symbol
     * @type {string}
     * @memberof TeamNameReq
     */
    team_symbol: string;
}
/**
 * 
 * @export
 * @interface TeamProcessDto
 */
export interface TeamProcessDto {
    /**
     * 
     * @type {string}
     * @memberof TeamProcessDto
     */
    detail?: string;
    /**
     * 
     * @type {number}
     * @memberof TeamProcessDto
     */
    error_cd: number;
    /**
     * 
     * @type {string}
     * @memberof TeamProcessDto
     */
    error_message: string;
    /**
     * 
     * @type {number}
     * @memberof TeamProcessDto
     */
    process_kind: number;
    /**
     * 
     * @type {number}
     * @memberof TeamProcessDto
     */
    process_status: number;
    /**
     * 
     * @type {string}
     * @memberof TeamProcessDto
     */
    start_at: string;
    /**
     * 
     * @type {string}
     * @memberof TeamProcessDto
     */
    start_by_name?: string;
}
/**
 * 
 * @export
 * @interface TeamRequestAcceptReq
 */
export interface TeamRequestAcceptReq {
    /**
     * from_user_id
     * @type {string}
     * @memberof TeamRequestAcceptReq
     */
    from_user_id: string;
    /**
     * team_id
     * @type {number}
     * @memberof TeamRequestAcceptReq
     */
    team_id: number;
}
/**
 * 
 * @export
 * @interface TeamRequestAddReq
 */
export interface TeamRequestAddReq {
    /**
     * team_id
     * @type {number}
     * @memberof TeamRequestAddReq
     */
    team_id: number;
}
/**
 * 
 * @export
 * @interface TeamRequestByUserIdDto
 */
export interface TeamRequestByUserIdDto {
    /**
     * name
     * @type {string}
     * @memberof TeamRequestByUserIdDto
     */
    name: string;
    /**
     * team_id
     * @type {number}
     * @memberof TeamRequestByUserIdDto
     */
    team_id: number;
    /**
     * team_symbol
     * @type {string}
     * @memberof TeamRequestByUserIdDto
     */
    team_symbol: string;
    /**
     * team_type
     * @type {string}
     * @memberof TeamRequestByUserIdDto
     */
    team_type: string;
}
/**
 * 
 * @export
 * @interface TeamRequestDeleteReq
 */
export interface TeamRequestDeleteReq {
    /**
     * from_user_id
     * @type {string}
     * @memberof TeamRequestDeleteReq
     */
    from_user_id: string;
    /**
     * team_id
     * @type {number}
     * @memberof TeamRequestDeleteReq
     */
    team_id: number;
}
/**
 * 
 * @export
 * @interface TeamRequestListDto
 */
export interface TeamRequestListDto {
    /**
     * accepts
     * @type {Array<ComOnpVetellMybatisTeam1Dao>}
     * @memberof TeamRequestListDto
     */
    accepts?: Array<ComOnpVetellMybatisTeam1Dao>;
    /**
     * requests
     * @type {Array<TeamRequestByUserIdDto>}
     * @memberof TeamRequestListDto
     */
    requests?: Array<TeamRequestByUserIdDto>;
}
/**
 * 
 * @export
 * @interface TeamSeedTagModifyReq
 */
export interface TeamSeedTagModifyReq {
    /**
     * 
     * @type {number}
     * @memberof TeamSeedTagModifyReq
     */
    seed_lot_id: number;
    /**
     * 
     * @type {Array<TeamSeedTagModifyReqStock>}
     * @memberof TeamSeedTagModifyReq
     */
    stocks: Array<TeamSeedTagModifyReqStock>;
    /**
     * 
     * @type {number}
     * @memberof TeamSeedTagModifyReq
     */
    team_id: number;
}
/**
 * 
 * @export
 * @interface TeamSeedTagModifyReqStock
 */
export interface TeamSeedTagModifyReqStock {
    /**
     * 
     * @type {string}
     * @memberof TeamSeedTagModifyReqStock
     */
    label_no: string;
    /**
     * 
     * @type {Array<TagIdAndNameReq>}
     * @memberof TeamSeedTagModifyReqStock
     */
    tags: Array<TagIdAndNameReq>;
}
/**
 * 
 * @export
 * @interface TeamSettingDto
 */
export interface TeamSettingDto {
    /**
     * 
     * @type {number}
     * @memberof TeamSettingDto
     */
    age_mode: number;
}
/**
 * 
 * @export
 * @interface TeamSettingModifyReq
 */
export interface TeamSettingModifyReq {
    /**
     * 
     * @type {number}
     * @memberof TeamSettingModifyReq
     */
    age_mode: number;
    /**
     * 
     * @type {number}
     * @memberof TeamSettingModifyReq
     */
    team_id: number;
}
/**
 * 
 * @export
 * @interface TeamTreatItemDeleteReq
 */
export interface TeamTreatItemDeleteReq {
    /**
     * team_id
     * @type {number}
     * @memberof TeamTreatItemDeleteReq
     */
    team_id: number;
    /**
     * treat_item_no
     * @type {number}
     * @memberof TeamTreatItemDeleteReq
     */
    treat_item_no: number;
    /**
     * treat_kind_no
     * @type {number}
     * @memberof TeamTreatItemDeleteReq
     */
    treat_kind_no: number;
    /**
     * user_id
     * @type {string}
     * @memberof TeamTreatItemDeleteReq
     */
    user_id: string;
}
/**
 * 
 * @export
 * @interface TeamTreatItemListDto
 */
export interface TeamTreatItemListDto {
    /**
     * list
     * @type {Array<ComOnpVetellMybatisTeamTreatItemDao>}
     * @memberof TeamTreatItemListDto
     */
    list?: Array<ComOnpVetellMybatisTeamTreatItemDao>;
}
/**
 * 
 * @export
 * @interface TeamTreatItemListReq
 */
export interface TeamTreatItemListReq {
    /**
     * team_id
     * @type {number}
     * @memberof TeamTreatItemListReq
     */
    team_id: number;
}
/**
 * 
 * @export
 * @interface TeamTreatItemModifyReq
 */
export interface TeamTreatItemModifyReq {
    /**
     * code
     * @type {string}
     * @memberof TeamTreatItemModifyReq
     */
    code?: string;
    /**
     * fee
     * @type {number}
     * @memberof TeamTreatItemModifyReq
     */
    fee: number;
    /**
     * fee_unit
     * @type {string}
     * @memberof TeamTreatItemModifyReq
     */
    fee_unit?: string;
    /**
     * is_new
     * @type {number}
     * @memberof TeamTreatItemModifyReq
     */
    is_new: number;
    /**
     * name
     * @type {string}
     * @memberof TeamTreatItemModifyReq
     */
    name: string;
    /**
     * point_a
     * @type {number}
     * @memberof TeamTreatItemModifyReq
     */
    point_a?: number;
    /**
     * point_b
     * @type {number}
     * @memberof TeamTreatItemModifyReq
     */
    point_b?: number;
    /**
     * team_id
     * @type {number}
     * @memberof TeamTreatItemModifyReq
     */
    team_id: number;
    /**
     * treat_item_no
     * @type {number}
     * @memberof TeamTreatItemModifyReq
     */
    treat_item_no: number;
    /**
     * treat_kind_no
     * @type {number}
     * @memberof TeamTreatItemModifyReq
     */
    treat_kind_no: number;
    /**
     * user_id
     * @type {string}
     * @memberof TeamTreatItemModifyReq
     */
    user_id: string;
}
/**
 * 
 * @export
 * @interface TeamTreatItemSortReq
 */
export interface TeamTreatItemSortReq {
    /**
     * order
     * @type {Array<number>}
     * @memberof TeamTreatItemSortReq
     */
    order: Array<number>;
    /**
     * team_id
     * @type {number}
     * @memberof TeamTreatItemSortReq
     */
    team_id: number;
    /**
     * treat_kind_no
     * @type {number}
     * @memberof TeamTreatItemSortReq
     */
    treat_kind_no: number;
    /**
     * user_id
     * @type {string}
     * @memberof TeamTreatItemSortReq
     */
    user_id: string;
}
/**
 * 
 * @export
 * @interface TeamTreatNamedPresetItemDto
 */
export interface TeamTreatNamedPresetItemDto {
    /**
     * 
     * @type {number}
     * @memberof TeamTreatNamedPresetItemDto
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamTreatNamedPresetItemDto
     */
    benefit_type: number;
    /**
     * 
     * @type {string}
     * @memberof TeamTreatNamedPresetItemDto
     */
    item_name?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamTreatNamedPresetItemDto
     */
    item_unit?: string;
    /**
     * 
     * @type {number}
     * @memberof TeamTreatNamedPresetItemDto
     */
    medicine_id?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamTreatNamedPresetItemDto
     */
    route_id?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamTreatNamedPresetItemDto
     */
    treat_item_no?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamTreatNamedPresetItemDto
     */
    treat_kind_no?: number;
}
/**
 * 
 * @export
 * @interface TeamTreatPresetDeleteReq
 */
export interface TeamTreatPresetDeleteReq {
    /**
     * 
     * @type {number}
     * @memberof TeamTreatPresetDeleteReq
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof TeamTreatPresetDeleteReq
     */
    is_force?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamTreatPresetDeleteReq
     */
    team_id: number;
}
/**
 * 
 * @export
 * @interface TeamTreatPresetDto
 */
export interface TeamTreatPresetDto {
    /**
     * 
     * @type {Array<TeamTreatPresetItemDto>}
     * @memberof TeamTreatPresetDto
     */
    items: Array<TeamTreatPresetItemDto>;
    /**
     * 
     * @type {number}
     * @memberof TeamTreatPresetDto
     */
    preset_id: number;
    /**
     * 
     * @type {string}
     * @memberof TeamTreatPresetDto
     */
    preset_name: string;
}
/**
 * 
 * @export
 * @interface TeamTreatPresetItemDto
 */
export interface TeamTreatPresetItemDto {
    /**
     * 
     * @type {number}
     * @memberof TeamTreatPresetItemDto
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamTreatPresetItemDto
     */
    benefit_type: number;
    /**
     * 
     * @type {number}
     * @memberof TeamTreatPresetItemDto
     */
    medicine_id?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamTreatPresetItemDto
     */
    route_id?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamTreatPresetItemDto
     */
    treat_item_no?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamTreatPresetItemDto
     */
    treat_kind_no?: number;
}
/**
 * 
 * @export
 * @interface TeamTreatPresetModifyReq
 */
export interface TeamTreatPresetModifyReq {
    /**
     * 
     * @type {Array<TeamTreatPresetModifyReqItem>}
     * @memberof TeamTreatPresetModifyReq
     */
    items: Array<TeamTreatPresetModifyReqItem>;
    /**
     * 
     * @type {number}
     * @memberof TeamTreatPresetModifyReq
     */
    preset_id?: number;
    /**
     * 
     * @type {string}
     * @memberof TeamTreatPresetModifyReq
     */
    preset_name: string;
    /**
     * 
     * @type {number}
     * @memberof TeamTreatPresetModifyReq
     */
    team_id: number;
}
/**
 * 
 * @export
 * @interface TeamTreatPresetModifyReqItem
 */
export interface TeamTreatPresetModifyReqItem {
    /**
     * 
     * @type {number}
     * @memberof TeamTreatPresetModifyReqItem
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamTreatPresetModifyReqItem
     */
    benefit_type?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamTreatPresetModifyReqItem
     */
    medicine_id?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamTreatPresetModifyReqItem
     */
    route_id?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamTreatPresetModifyReqItem
     */
    treat_item_no?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamTreatPresetModifyReqItem
     */
    treat_kind_no?: number;
}
/**
 * 
 * @export
 * @interface TeamUserDto
 */
export interface TeamUserDto {
    /**
     * level
     * @type {number}
     * @memberof TeamUserDto
     */
    level: number;
    /**
     * level_detail
     * @type {number}
     * @memberof TeamUserDto
     */
    level_detail: number;
    /**
     * name
     * @type {string}
     * @memberof TeamUserDto
     */
    name: string;
    /**
     * user_id
     * @type {string}
     * @memberof TeamUserDto
     */
    user_id: string;
}
/**
 * 
 * @export
 * @interface TeamUserListReq
 */
export interface TeamUserListReq {
    /**
     * team_id
     * @type {number}
     * @memberof TeamUserListReq
     */
    team_id: number;
}
/**
 * 
 * @export
 * @interface TeamUsersDto
 */
export interface TeamUsersDto {
    /**
     * owner_id
     * @type {string}
     * @memberof TeamUsersDto
     */
    owner_id: string;
    /**
     * users
     * @type {Array<TeamUserDto>}
     * @memberof TeamUsersDto
     */
    users: Array<TeamUserDto>;
}
/**
 * 
 * @export
 * @interface TransitionMonthDto
 */
export interface TransitionMonthDto {
    /**
     * 
     * @type {number}
     * @memberof TransitionMonthDto
     */
    actual: number;
    /**
     * 
     * @type {string}
     * @memberof TransitionMonthDto
     */
    lastday: string;
    /**
     * 
     * @type {number}
     * @memberof TransitionMonthDto
     */
    plan: number;
}
/**
 * 
 * @export
 * @interface TreatEventDto
 */
export interface TreatEventDto {
    /**
     * 
     * @type {number}
     * @memberof TreatEventDto
     */
    amount?: number;
    /**
     * 予防でのみ使用
     * @type {string}
     * @memberof TreatEventDto
     */
    expiring_day?: string;
    /**
     * 予防でのみ使用
     * @type {string}
     * @memberof TreatEventDto
     */
    lot_no?: string;
    /**
     * 
     * @type {number}
     * @memberof TreatEventDto
     */
    medicine_id?: number;
    /**
     * 
     * @type {string}
     * @memberof TreatEventDto
     */
    medicine_route_name: string;
    /**
     * 
     * @type {string}
     * @memberof TreatEventDto
     */
    note?: string;
    /**
     * 
     * @type {number}
     * @memberof TreatEventDto
     */
    route_fee?: number;
    /**
     * 
     * @type {number}
     * @memberof TreatEventDto
     */
    route_id?: number;
    /**
     * 
     * @type {number}
     * @memberof TreatEventDto
     */
    team_id: number;
    /**
     * 
     * @type {string}
     * @memberof TreatEventDto
     */
    treat_item_name: string;
    /**
     * 
     * @type {number}
     * @memberof TreatEventDto
     */
    treat_item_no?: number;
    /**
     * 
     * @type {string}
     * @memberof TreatEventDto
     */
    treat_item_unit: string;
    /**
     * 
     * @type {number}
     * @memberof TreatEventDto
     */
    treat_kind_no?: number;
    /**
     * 
     * @type {number}
     * @memberof TreatEventDto
     */
    unit_price: number;
}
/**
 * 
 * @export
 * @interface UserUpdateReq
 */
export interface UserUpdateReq {
    /**
     * 
     * @type {string}
     * @memberof UserUpdateReq
     */
    icon?: string;
    /**
     * 
     * @type {number}
     * @memberof UserUpdateReq
     */
    licenses?: number;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateReq
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateReq
     */
    setting?: string;
}
/**
 * 
 * @export
 * @interface VisitDeleteReq
 */
export interface VisitDeleteReq {
    /**
     * 
     * @type {number}
     * @memberof VisitDeleteReq
     */
    clinic_id: number;
    /**
     * 
     * @type {number}
     * @memberof VisitDeleteReq
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface VisitDistanceDto
 */
export interface VisitDistanceDto {
    /**
     * 
     * @type {number}
     * @memberof VisitDistanceDto
     */
    clinic_id: number;
    /**
     * 
     * @type {number}
     * @memberof VisitDistanceDto
     */
    distance_km?: number;
    /**
     * 
     * @type {Array<VisitDistanceFeeDto>}
     * @memberof VisitDistanceDto
     */
    fees: Array<VisitDistanceFeeDto>;
}
/**
 * 
 * @export
 * @interface VisitDistanceFeeDto
 */
export interface VisitDistanceFeeDto {
    /**
     * 
     * @type {number}
     * @memberof VisitDistanceFeeDto
     */
    fee_no: number;
    /**
     * 
     * @type {number}
     * @memberof VisitDistanceFeeDto
     */
    fee_type: number;
    /**
     * 
     * @type {number}
     * @memberof VisitDistanceFeeDto
     */
    is_benefit: number;
    /**
     * 
     * @type {string}
     * @memberof VisitDistanceFeeDto
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface VisitListDto
 */
export interface VisitListDto {
    /**
     * 
     * @type {number}
     * @memberof VisitListDto
     */
    clinic_id: number;
    /**
     * 
     * @type {string}
     * @memberof VisitListDto
     */
    code: string;
    /**
     * 
     * @type {Array<VisitListDtoDistance>}
     * @memberof VisitListDto
     */
    distances: Array<VisitListDtoDistance>;
    /**
     * 
     * @type {string}
     * @memberof VisitListDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof VisitListDto
     */
    ranch_id: number;
}
/**
 * 
 * @export
 * @interface VisitListDtoDistance
 */
export interface VisitListDtoDistance {
    /**
     * 
     * @type {number}
     * @memberof VisitListDtoDistance
     */
    distance_km: number;
    /**
     * 
     * @type {string}
     * @memberof VisitListDtoDistance
     */
    name: string;
}
/**
 * 
 * @export
 * @interface VisitListReq
 */
export interface VisitListReq {
    /**
     * 
     * @type {number}
     * @memberof VisitListReq
     */
    clinic_id: number;
}
/**
 * 
 * @export
 * @interface VisitModifyReq
 */
export interface VisitModifyReq {
    /**
     * 
     * @type {number}
     * @memberof VisitModifyReq
     */
    clinic_id: number;
    /**
     * 
     * @type {string}
     * @memberof VisitModifyReq
     */
    code: string;
    /**
     * 
     * @type {Array<VisitModifyReqDistance>}
     * @memberof VisitModifyReq
     */
    distances: Array<VisitModifyReqDistance>;
    /**
     * 
     * @type {number}
     * @memberof VisitModifyReq
     */
    ranch_id: number;
    /**
     * 
     * @type {string}
     * @memberof VisitModifyReq
     */
    team_symbol?: string;
}
/**
 * 
 * @export
 * @interface VisitModifyReqDistance
 */
export interface VisitModifyReqDistance {
    /**
     * 
     * @type {number}
     * @memberof VisitModifyReqDistance
     */
    distance_km: number;
    /**
     * 
     * @type {string}
     * @memberof VisitModifyReqDistance
     */
    name: string;
}
/**
 * 
 * @export
 * @interface WashoutDto
 */
export interface WashoutDto {
    /**
     * 
     * @type {string}
     * @memberof WashoutDto
     */
    end_meat?: string;
    /**
     * 
     * @type {string}
     * @memberof WashoutDto
     */
    end_milk?: string;
    /**
     * 
     * @type {string}
     * @memberof WashoutDto
     */
    item_name: string;
    /**
     * 
     * @type {number}
     * @memberof WashoutDto
     */
    item_type: number;
    /**
     * 
     * @type {string}
     * @memberof WashoutDto
     */
    start_meat?: string;
    /**
     * 
     * @type {string}
     * @memberof WashoutDto
     */
    start_milk?: string;
}

/**
 * ActivityControllerApi - axios parameter creator
 * @export
 */
export const ActivityControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get cows in the activity
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityCowsUsingPOST(req: ComRanchAndIdReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getActivityCowsUsingPOST.');
            }
            const localVarPath = `/api/activity/cows`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get activity list
         * @summary activity list
         * @param {ActivityListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST(req: ActivityListReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling listUsingPOST.');
            }
            const localVarPath = `/api/activity/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary undo an activity
         * @param {ActivityUndoReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        undoUsingPOST(req: ActivityUndoReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling undoUsingPOST.');
            }
            const localVarPath = `/api/activity/undo`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivityControllerApi - functional programming interface
 * @export
 */
export const ActivityControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get cows in the activity
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityCowsUsingPOST(req: ComRanchAndIdReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListActivityCowDto> {
            const localVarAxiosArgs = ActivityControllerApiAxiosParamCreator(configuration).getActivityCowsUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get activity list
         * @summary activity list
         * @param {ActivityListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST(req: ActivityListReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListActivityDto> {
            const localVarAxiosArgs = ActivityControllerApiAxiosParamCreator(configuration).listUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary undo an activity
         * @param {ActivityUndoReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        undoUsingPOST(req: ActivityUndoReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = ActivityControllerApiAxiosParamCreator(configuration).undoUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ActivityControllerApi - factory interface
 * @export
 */
export const ActivityControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary get cows in the activity
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityCowsUsingPOST(req: ComRanchAndIdReq, options?: any): AxiosPromise<GenericResponseListActivityCowDto> {
            return ActivityControllerApiFp(configuration).getActivityCowsUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get activity list
         * @summary activity list
         * @param {ActivityListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST(req: ActivityListReq, options?: any): AxiosPromise<GenericResponseListActivityDto> {
            return ActivityControllerApiFp(configuration).listUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary undo an activity
         * @param {ActivityUndoReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        undoUsingPOST(req: ActivityUndoReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return ActivityControllerApiFp(configuration).undoUsingPOST(req, options)(axios, basePath);
        },
    };
};

/**
 * ActivityControllerApi - object-oriented interface
 * @export
 * @class ActivityControllerApi
 * @extends {BaseAPI}
 */
export class ActivityControllerApi extends BaseAPI {
    /**
     * 
     * @summary get cows in the activity
     * @param {ComRanchAndIdReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityControllerApi
     */
    public getActivityCowsUsingPOST(req: ComRanchAndIdReq, options?: any) {
        return ActivityControllerApiFp(this.configuration).getActivityCowsUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get activity list
     * @summary activity list
     * @param {ActivityListReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityControllerApi
     */
    public listUsingPOST(req: ActivityListReq, options?: any) {
        return ActivityControllerApiFp(this.configuration).listUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary undo an activity
     * @param {ActivityUndoReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityControllerApi
     */
    public undoUsingPOST(req: ActivityUndoReq, options?: any) {
        return ActivityControllerApiFp(this.configuration).undoUsingPOST(req, options)(this.axios, this.basePath);
    }

}


/**
 * BalanceControllerApi - axios parameter creator
 * @export
 */
export const BalanceControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get cow conditions to aggregate balances
         * @summary cow conditions
         * @param {BalanceCowConditionReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalanceCowConditionUsingPOST(req: BalanceCowConditionReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getBalanceCowConditionUsingPOST.');
            }
            const localVarPath = `/api/balance/cow/condition`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the balance of the children
         * @summary balance data of mother and children
         * @param {BalanceCowChildrenReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildrenBalanceUsingPOST(req: BalanceCowChildrenReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getChildrenBalanceUsingPOST.');
            }
            const localVarPath = `/api/balance/children`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get balance data of the cows grouped by age
         * @summary balance data of the cows
         * @param {BalanceCowAgeReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCowAgeBalanceUsingPOST(req: BalanceCowAgeReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getCowAgeBalanceUsingPOST.');
            }
            const localVarPath = `/api/balance/cow/age`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get balance data of the cows
         * @summary balance data of the cows
         * @param {BalanceCowReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCowBalanceUsingPOST(req: BalanceCowReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getCowBalanceUsingPOST.');
            }
            const localVarPath = `/api/balance/cow`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get balance data of the cows grouped by days after delivery
         * @summary balance data of the cows
         * @param {BalanceCowDeliveryDayReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCowDeliveryDayBalanceUsingPOST(req: BalanceCowDeliveryDayReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getCowDeliveryDayBalanceUsingPOST.');
            }
            const localVarPath = `/api/balance/cow/delivery_day`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get balance data of the cows grouped by delivery time
         * @summary balance data of the cows
         * @param {BalanceCowDeliveryTimeReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCowDeliveryTimeBalanceUsingPOST(req: BalanceCowDeliveryTimeReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getCowDeliveryTimeBalanceUsingPOST.');
            }
            const localVarPath = `/api/balance/cow/delivery_time`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get balance data of ranch
         * @summary balance data of ranch
         * @param {BalanceRanchReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRanchBalanceUsingPOST(req: BalanceRanchReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getRanchBalanceUsingPOST.');
            }
            const localVarPath = `/api/balance/ranch`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BalanceControllerApi - functional programming interface
 * @export
 */
export const BalanceControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * get cow conditions to aggregate balances
         * @summary cow conditions
         * @param {BalanceCowConditionReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalanceCowConditionUsingPOST(req: BalanceCowConditionReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseBalanceCowConditionDto> {
            const localVarAxiosArgs = BalanceControllerApiAxiosParamCreator(configuration).getBalanceCowConditionUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get the balance of the children
         * @summary balance data of mother and children
         * @param {BalanceCowChildrenReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildrenBalanceUsingPOST(req: BalanceCowChildrenReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseBalanceCowChildrenDto> {
            const localVarAxiosArgs = BalanceControllerApiAxiosParamCreator(configuration).getChildrenBalanceUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get balance data of the cows grouped by age
         * @summary balance data of the cows
         * @param {BalanceCowAgeReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCowAgeBalanceUsingPOST(req: BalanceCowAgeReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseBalanceCowDto> {
            const localVarAxiosArgs = BalanceControllerApiAxiosParamCreator(configuration).getCowAgeBalanceUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get balance data of the cows
         * @summary balance data of the cows
         * @param {BalanceCowReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCowBalanceUsingPOST(req: BalanceCowReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseBalanceCowDto> {
            const localVarAxiosArgs = BalanceControllerApiAxiosParamCreator(configuration).getCowBalanceUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get balance data of the cows grouped by days after delivery
         * @summary balance data of the cows
         * @param {BalanceCowDeliveryDayReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCowDeliveryDayBalanceUsingPOST(req: BalanceCowDeliveryDayReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseBalanceCowDeliDayDto> {
            const localVarAxiosArgs = BalanceControllerApiAxiosParamCreator(configuration).getCowDeliveryDayBalanceUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get balance data of the cows grouped by delivery time
         * @summary balance data of the cows
         * @param {BalanceCowDeliveryTimeReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCowDeliveryTimeBalanceUsingPOST(req: BalanceCowDeliveryTimeReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseBalanceCowDeliTimeDto> {
            const localVarAxiosArgs = BalanceControllerApiAxiosParamCreator(configuration).getCowDeliveryTimeBalanceUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get balance data of ranch
         * @summary balance data of ranch
         * @param {BalanceRanchReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRanchBalanceUsingPOST(req: BalanceRanchReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseBalanceDto> {
            const localVarAxiosArgs = BalanceControllerApiAxiosParamCreator(configuration).getRanchBalanceUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BalanceControllerApi - factory interface
 * @export
 */
export const BalanceControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * get cow conditions to aggregate balances
         * @summary cow conditions
         * @param {BalanceCowConditionReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalanceCowConditionUsingPOST(req: BalanceCowConditionReq, options?: any): AxiosPromise<GenericResponseBalanceCowConditionDto> {
            return BalanceControllerApiFp(configuration).getBalanceCowConditionUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get the balance of the children
         * @summary balance data of mother and children
         * @param {BalanceCowChildrenReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildrenBalanceUsingPOST(req: BalanceCowChildrenReq, options?: any): AxiosPromise<GenericResponseBalanceCowChildrenDto> {
            return BalanceControllerApiFp(configuration).getChildrenBalanceUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get balance data of the cows grouped by age
         * @summary balance data of the cows
         * @param {BalanceCowAgeReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCowAgeBalanceUsingPOST(req: BalanceCowAgeReq, options?: any): AxiosPromise<GenericResponseBalanceCowDto> {
            return BalanceControllerApiFp(configuration).getCowAgeBalanceUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get balance data of the cows
         * @summary balance data of the cows
         * @param {BalanceCowReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCowBalanceUsingPOST(req: BalanceCowReq, options?: any): AxiosPromise<GenericResponseBalanceCowDto> {
            return BalanceControllerApiFp(configuration).getCowBalanceUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get balance data of the cows grouped by days after delivery
         * @summary balance data of the cows
         * @param {BalanceCowDeliveryDayReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCowDeliveryDayBalanceUsingPOST(req: BalanceCowDeliveryDayReq, options?: any): AxiosPromise<GenericResponseBalanceCowDeliDayDto> {
            return BalanceControllerApiFp(configuration).getCowDeliveryDayBalanceUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get balance data of the cows grouped by delivery time
         * @summary balance data of the cows
         * @param {BalanceCowDeliveryTimeReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCowDeliveryTimeBalanceUsingPOST(req: BalanceCowDeliveryTimeReq, options?: any): AxiosPromise<GenericResponseBalanceCowDeliTimeDto> {
            return BalanceControllerApiFp(configuration).getCowDeliveryTimeBalanceUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get balance data of ranch
         * @summary balance data of ranch
         * @param {BalanceRanchReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRanchBalanceUsingPOST(req: BalanceRanchReq, options?: any): AxiosPromise<GenericResponseBalanceDto> {
            return BalanceControllerApiFp(configuration).getRanchBalanceUsingPOST(req, options)(axios, basePath);
        },
    };
};

/**
 * BalanceControllerApi - object-oriented interface
 * @export
 * @class BalanceControllerApi
 * @extends {BaseAPI}
 */
export class BalanceControllerApi extends BaseAPI {
    /**
     * get cow conditions to aggregate balances
     * @summary cow conditions
     * @param {BalanceCowConditionReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceControllerApi
     */
    public getBalanceCowConditionUsingPOST(req: BalanceCowConditionReq, options?: any) {
        return BalanceControllerApiFp(this.configuration).getBalanceCowConditionUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get the balance of the children
     * @summary balance data of mother and children
     * @param {BalanceCowChildrenReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceControllerApi
     */
    public getChildrenBalanceUsingPOST(req: BalanceCowChildrenReq, options?: any) {
        return BalanceControllerApiFp(this.configuration).getChildrenBalanceUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get balance data of the cows grouped by age
     * @summary balance data of the cows
     * @param {BalanceCowAgeReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceControllerApi
     */
    public getCowAgeBalanceUsingPOST(req: BalanceCowAgeReq, options?: any) {
        return BalanceControllerApiFp(this.configuration).getCowAgeBalanceUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get balance data of the cows
     * @summary balance data of the cows
     * @param {BalanceCowReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceControllerApi
     */
    public getCowBalanceUsingPOST(req: BalanceCowReq, options?: any) {
        return BalanceControllerApiFp(this.configuration).getCowBalanceUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get balance data of the cows grouped by days after delivery
     * @summary balance data of the cows
     * @param {BalanceCowDeliveryDayReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceControllerApi
     */
    public getCowDeliveryDayBalanceUsingPOST(req: BalanceCowDeliveryDayReq, options?: any) {
        return BalanceControllerApiFp(this.configuration).getCowDeliveryDayBalanceUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get balance data of the cows grouped by delivery time
     * @summary balance data of the cows
     * @param {BalanceCowDeliveryTimeReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceControllerApi
     */
    public getCowDeliveryTimeBalanceUsingPOST(req: BalanceCowDeliveryTimeReq, options?: any) {
        return BalanceControllerApiFp(this.configuration).getCowDeliveryTimeBalanceUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get balance data of ranch
     * @summary balance data of ranch
     * @param {BalanceRanchReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceControllerApi
     */
    public getRanchBalanceUsingPOST(req: BalanceRanchReq, options?: any) {
        return BalanceControllerApiFp(this.configuration).getRanchBalanceUsingPOST(req, options)(this.axios, this.basePath);
    }

}


/**
 * BreedingControllerApi - axios parameter creator
 * @export
 */
export const BreedingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * delete breeding
         * @summary delete breeding
         * @param {BreedingDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST(req: BreedingDeleteReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deleteUsingPOST.');
            }
            const localVarPath = `/api/breeding/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the breeding cross current
         * @summary breeding cross current
         * @param {BreedingCrossCurrentReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBreedingCrossCurrentUsingPOST(req: BreedingCrossCurrentReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getBreedingCrossCurrentUsingPOST.');
            }
            const localVarPath = `/api/breeding/cross/current`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the breeding cross history
         * @summary breeding cross history
         * @param {BreedingCrossHistoryReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBreedingCrossHistoryUsingPOST(req: BreedingCrossHistoryReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getBreedingCrossHistoryUsingPOST.');
            }
            const localVarPath = `/api/breeding/cross/history`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the breeding detail
         * @summary breeding detail
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBreedingUsingPOST(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getBreedingUsingPOST.');
            }
            const localVarPath = `/api/breeding/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get info from cross no
         * @summary seed info
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCrossSeedUsingPOST(req: ComRanchAndIdReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getCrossSeedUsingPOST.');
            }
            const localVarPath = `/api/breeding/cross/seed`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get breeding history
         * @summary breeding history
         * @param {BreedingHistoryReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoryListUsingPOST(req: BreedingHistoryReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getHistoryListUsingPOST.');
            }
            const localVarPath = `/api/breeding/history`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get father info of latest AI
         * @param {BreedingLastAiFatherReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastAiFatherUsingPOST(req: BreedingLastAiFatherReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getLastAiFatherUsingPOST.');
            }
            const localVarPath = `/api/breeding/cross/last/ai/father`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get program items witch will be generated by the breeding event.
         * @param {ProgramAppliedItemsReqBreedingModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramsForBreedingUsingPOST(req: ProgramAppliedItemsReqBreedingModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getProgramsForBreedingUsingPOST.');
            }
            const localVarPath = `/api/breeding/program`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add new cow or modify breeding
         * @summary add or modify breeding
         * @param {BreedingModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyUsingPOST(req: BreedingModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyUsingPOST.');
            }
            const localVarPath = `/api/breeding/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get certification of ai
         * @summary get cert of ai
         * @param {BreedingCertAiReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCertAiUsingPOST(req: BreedingCertAiReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling postCertAiUsingPOST.');
            }
            const localVarPath = `/api/breeding/cert/ai`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get certification of et
         * @summary get cert of et
         * @param {BreedingCertEtReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCertEtUsingPOST(req: BreedingCertEtReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling postCertEtUsingPOST.');
            }
            const localVarPath = `/api/breeding/cert/et`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get certification of pregnancy
         * @summary get cert of pregnancy
         * @param {BreedingCertPregnantReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCertPrgnantUsingPOST(req: BreedingCertPregnantReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling postCertPrgnantUsingPOST.');
            }
            const localVarPath = `/api/breeding/cert/pregnant`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BreedingControllerApi - functional programming interface
 * @export
 */
export const BreedingControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * delete breeding
         * @summary delete breeding
         * @param {BreedingDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST(req: BreedingDeleteReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = BreedingControllerApiAxiosParamCreator(configuration).deleteUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get the breeding cross current
         * @summary breeding cross current
         * @param {BreedingCrossCurrentReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBreedingCrossCurrentUsingPOST(req: BreedingCrossCurrentReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseBreedingCrossCurrentDto> {
            const localVarAxiosArgs = BreedingControllerApiAxiosParamCreator(configuration).getBreedingCrossCurrentUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get the breeding cross history
         * @summary breeding cross history
         * @param {BreedingCrossHistoryReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBreedingCrossHistoryUsingPOST(req: BreedingCrossHistoryReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListBreedingCrossCurrentCrossDto> {
            const localVarAxiosArgs = BreedingControllerApiAxiosParamCreator(configuration).getBreedingCrossHistoryUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get the breeding detail
         * @summary breeding detail
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBreedingUsingPOST(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseBreedingDto> {
            const localVarAxiosArgs = BreedingControllerApiAxiosParamCreator(configuration).getBreedingUsingPOST(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get info from cross no
         * @summary seed info
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCrossSeedUsingPOST(req: ComRanchAndIdReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseCrossSeedDto> {
            const localVarAxiosArgs = BreedingControllerApiAxiosParamCreator(configuration).getCrossSeedUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get breeding history
         * @summary breeding history
         * @param {BreedingHistoryReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoryListUsingPOST(req: BreedingHistoryReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseBreedingHistoryListDto> {
            const localVarAxiosArgs = BreedingControllerApiAxiosParamCreator(configuration).getHistoryListUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get father info of latest AI
         * @param {BreedingLastAiFatherReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastAiFatherUsingPOST(req: BreedingLastAiFatherReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListBreedingLastAiFatherDto> {
            const localVarAxiosArgs = BreedingControllerApiAxiosParamCreator(configuration).getLastAiFatherUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get program items witch will be generated by the breeding event.
         * @param {ProgramAppliedItemsReqBreedingModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramsForBreedingUsingPOST(req: ProgramAppliedItemsReqBreedingModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseProgramApplyResultDto> {
            const localVarAxiosArgs = BreedingControllerApiAxiosParamCreator(configuration).getProgramsForBreedingUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * add new cow or modify breeding
         * @summary add or modify breeding
         * @param {BreedingModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyUsingPOST(req: BreedingModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = BreedingControllerApiAxiosParamCreator(configuration).modifyUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get certification of ai
         * @summary get cert of ai
         * @param {BreedingCertAiReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCertAiUsingPOST(req: BreedingCertAiReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseFileDownloadDto> {
            const localVarAxiosArgs = BreedingControllerApiAxiosParamCreator(configuration).postCertAiUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get certification of et
         * @summary get cert of et
         * @param {BreedingCertEtReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCertEtUsingPOST(req: BreedingCertEtReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseFileDownloadDto> {
            const localVarAxiosArgs = BreedingControllerApiAxiosParamCreator(configuration).postCertEtUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get certification of pregnancy
         * @summary get cert of pregnancy
         * @param {BreedingCertPregnantReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCertPrgnantUsingPOST(req: BreedingCertPregnantReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseFileDownloadDto> {
            const localVarAxiosArgs = BreedingControllerApiAxiosParamCreator(configuration).postCertPrgnantUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BreedingControllerApi - factory interface
 * @export
 */
export const BreedingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * delete breeding
         * @summary delete breeding
         * @param {BreedingDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST(req: BreedingDeleteReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return BreedingControllerApiFp(configuration).deleteUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get the breeding cross current
         * @summary breeding cross current
         * @param {BreedingCrossCurrentReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBreedingCrossCurrentUsingPOST(req: BreedingCrossCurrentReq, options?: any): AxiosPromise<GenericResponseBreedingCrossCurrentDto> {
            return BreedingControllerApiFp(configuration).getBreedingCrossCurrentUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get the breeding cross history
         * @summary breeding cross history
         * @param {BreedingCrossHistoryReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBreedingCrossHistoryUsingPOST(req: BreedingCrossHistoryReq, options?: any): AxiosPromise<GenericResponseListBreedingCrossCurrentCrossDto> {
            return BreedingControllerApiFp(configuration).getBreedingCrossHistoryUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get the breeding detail
         * @summary breeding detail
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBreedingUsingPOST(id: number, options?: any): AxiosPromise<GenericResponseBreedingDto> {
            return BreedingControllerApiFp(configuration).getBreedingUsingPOST(id, options)(axios, basePath);
        },
        /**
         * get info from cross no
         * @summary seed info
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCrossSeedUsingPOST(req: ComRanchAndIdReq, options?: any): AxiosPromise<GenericResponseCrossSeedDto> {
            return BreedingControllerApiFp(configuration).getCrossSeedUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get breeding history
         * @summary breeding history
         * @param {BreedingHistoryReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoryListUsingPOST(req: BreedingHistoryReq, options?: any): AxiosPromise<GenericResponseBreedingHistoryListDto> {
            return BreedingControllerApiFp(configuration).getHistoryListUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary get father info of latest AI
         * @param {BreedingLastAiFatherReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastAiFatherUsingPOST(req: BreedingLastAiFatherReq, options?: any): AxiosPromise<GenericResponseListBreedingLastAiFatherDto> {
            return BreedingControllerApiFp(configuration).getLastAiFatherUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary get program items witch will be generated by the breeding event.
         * @param {ProgramAppliedItemsReqBreedingModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramsForBreedingUsingPOST(req: ProgramAppliedItemsReqBreedingModifyReq, options?: any): AxiosPromise<GenericResponseProgramApplyResultDto> {
            return BreedingControllerApiFp(configuration).getProgramsForBreedingUsingPOST(req, options)(axios, basePath);
        },
        /**
         * add new cow or modify breeding
         * @summary add or modify breeding
         * @param {BreedingModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyUsingPOST(req: BreedingModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return BreedingControllerApiFp(configuration).modifyUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get certification of ai
         * @summary get cert of ai
         * @param {BreedingCertAiReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCertAiUsingPOST(req: BreedingCertAiReq, options?: any): AxiosPromise<GenericResponseFileDownloadDto> {
            return BreedingControllerApiFp(configuration).postCertAiUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get certification of et
         * @summary get cert of et
         * @param {BreedingCertEtReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCertEtUsingPOST(req: BreedingCertEtReq, options?: any): AxiosPromise<GenericResponseFileDownloadDto> {
            return BreedingControllerApiFp(configuration).postCertEtUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get certification of pregnancy
         * @summary get cert of pregnancy
         * @param {BreedingCertPregnantReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCertPrgnantUsingPOST(req: BreedingCertPregnantReq, options?: any): AxiosPromise<GenericResponseFileDownloadDto> {
            return BreedingControllerApiFp(configuration).postCertPrgnantUsingPOST(req, options)(axios, basePath);
        },
    };
};

/**
 * BreedingControllerApi - object-oriented interface
 * @export
 * @class BreedingControllerApi
 * @extends {BaseAPI}
 */
export class BreedingControllerApi extends BaseAPI {
    /**
     * delete breeding
     * @summary delete breeding
     * @param {BreedingDeleteReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BreedingControllerApi
     */
    public deleteUsingPOST(req: BreedingDeleteReq, options?: any) {
        return BreedingControllerApiFp(this.configuration).deleteUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get the breeding cross current
     * @summary breeding cross current
     * @param {BreedingCrossCurrentReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BreedingControllerApi
     */
    public getBreedingCrossCurrentUsingPOST(req: BreedingCrossCurrentReq, options?: any) {
        return BreedingControllerApiFp(this.configuration).getBreedingCrossCurrentUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get the breeding cross history
     * @summary breeding cross history
     * @param {BreedingCrossHistoryReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BreedingControllerApi
     */
    public getBreedingCrossHistoryUsingPOST(req: BreedingCrossHistoryReq, options?: any) {
        return BreedingControllerApiFp(this.configuration).getBreedingCrossHistoryUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get the breeding detail
     * @summary breeding detail
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BreedingControllerApi
     */
    public getBreedingUsingPOST(id: number, options?: any) {
        return BreedingControllerApiFp(this.configuration).getBreedingUsingPOST(id, options)(this.axios, this.basePath);
    }

    /**
     * get info from cross no
     * @summary seed info
     * @param {ComRanchAndIdReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BreedingControllerApi
     */
    public getCrossSeedUsingPOST(req: ComRanchAndIdReq, options?: any) {
        return BreedingControllerApiFp(this.configuration).getCrossSeedUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get breeding history
     * @summary breeding history
     * @param {BreedingHistoryReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BreedingControllerApi
     */
    public getHistoryListUsingPOST(req: BreedingHistoryReq, options?: any) {
        return BreedingControllerApiFp(this.configuration).getHistoryListUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get father info of latest AI
     * @param {BreedingLastAiFatherReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BreedingControllerApi
     */
    public getLastAiFatherUsingPOST(req: BreedingLastAiFatherReq, options?: any) {
        return BreedingControllerApiFp(this.configuration).getLastAiFatherUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get program items witch will be generated by the breeding event.
     * @param {ProgramAppliedItemsReqBreedingModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BreedingControllerApi
     */
    public getProgramsForBreedingUsingPOST(req: ProgramAppliedItemsReqBreedingModifyReq, options?: any) {
        return BreedingControllerApiFp(this.configuration).getProgramsForBreedingUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * add new cow or modify breeding
     * @summary add or modify breeding
     * @param {BreedingModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BreedingControllerApi
     */
    public modifyUsingPOST(req: BreedingModifyReq, options?: any) {
        return BreedingControllerApiFp(this.configuration).modifyUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get certification of ai
     * @summary get cert of ai
     * @param {BreedingCertAiReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BreedingControllerApi
     */
    public postCertAiUsingPOST(req: BreedingCertAiReq, options?: any) {
        return BreedingControllerApiFp(this.configuration).postCertAiUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get certification of et
     * @summary get cert of et
     * @param {BreedingCertEtReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BreedingControllerApi
     */
    public postCertEtUsingPOST(req: BreedingCertEtReq, options?: any) {
        return BreedingControllerApiFp(this.configuration).postCertEtUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get certification of pregnancy
     * @summary get cert of pregnancy
     * @param {BreedingCertPregnantReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BreedingControllerApi
     */
    public postCertPrgnantUsingPOST(req: BreedingCertPregnantReq, options?: any) {
        return BreedingControllerApiFp(this.configuration).postCertPrgnantUsingPOST(req, options)(this.axios, this.basePath);
    }

}


/**
 * ClinicControllerApi - axios parameter creator
 * @export
 */
export const ClinicControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create unofficial ranch
         * @param {ClinicRanchCreateReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRanchUsingPOST(req: ClinicRanchCreateReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling createRanchUsingPOST.');
            }
            const localVarPath = `/api/clinic/ranch/create`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete the clinic seed lot
         * @summary delete clinic seed lot
         * @param {SeedLotDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClinicSeedLotUsingPOST(req: SeedLotDeleteReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deleteClinicSeedLotUsingPOST.');
            }
            const localVarPath = `/api/clinic/seed/lot/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete the clinic seed
         * @summary delete clinic seed
         * @param {ClinicSeedDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClinicSeedUsingPOST(req: ClinicSeedDeleteReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deleteClinicSeedUsingPOST.');
            }
            const localVarPath = `/api/clinic/seed/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a visit fee item
         * @param {ClinicVisitFeeDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVisitFeeUsingPOST(req: ClinicVisitFeeDeleteReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deleteVisitFeeUsingPOST.');
            }
            const localVarPath = `/api/clinic/visit/fee/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete the clinic visitation
         * @summary delete clinic visitation
         * @param {VisitDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVisitUsingPOST(req: VisitDeleteReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deleteVisitUsingPOST.');
            }
            const localVarPath = `/api/clinic/visit/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get schedules for clinic
         * @param {ClinicScheduleReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClinicScheduleListUsingPOST(req: ClinicScheduleReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getClinicScheduleListUsingPOST.');
            }
            const localVarPath = `/api/clinic/schedule/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the clinic seed list
         * @summary clinic seed
         * @param {ComClinicIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClinicSeedListUsingPOST(req: ComClinicIdReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getClinicSeedListUsingPOST.');
            }
            const localVarPath = `/api/clinic/seed/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get visit fee list
         * @param {ComClinicIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVisitFeeUsingPOST(req: ComClinicIdReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling listVisitFeeUsingPOST.');
            }
            const localVarPath = `/api/clinic/visit/fee/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the clinic visitation list
         * @summary clinic visitation list
         * @param {VisitListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVisitUsingPOST(req: VisitListReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling listVisitUsingPOST.');
            }
            const localVarPath = `/api/clinic/visit/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * modify the clinic seed lot
         * @summary modify clinic seed lot
         * @param {ClinicSeedLotModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyClinicSeedLotUsingPOST(req: ClinicSeedLotModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyClinicSeedLotUsingPOST.');
            }
            const localVarPath = `/api/clinic/seed/lot/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * modify the clinic seed stock
         * @summary modify clinic seed stock
         * @param {ClinicSeedStockModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyClinicSeedStockUsingPOST(req: ClinicSeedStockModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyClinicSeedStockUsingPOST.');
            }
            const localVarPath = `/api/clinic/seed/stock/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * modify the clinic seed list
         * @summary modify clinic seed
         * @param {ClinicSeedModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyClinicSeedUsingPOST(req: ClinicSeedModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyClinicSeedUsingPOST.');
            }
            const localVarPath = `/api/clinic/seed/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create or update visit fee
         * @param {ClinicVisitFeeModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyVisitFeeUsingPOST(req: ClinicVisitFeeModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyVisitFeeUsingPOST.');
            }
            const localVarPath = `/api/clinic/visit/fee/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * modify the clinic visitation list
         * @summary modify clinic visitation
         * @param {VisitModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyVisitUsingPOST(req: VisitModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyVisitUsingPOST.');
            }
            const localVarPath = `/api/clinic/visit/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * sort disp no of clinic seed
         * @summary sort disp no of clinic seed
         * @param {ClinicSeedSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortClinicSeedUsingPOST(req: ClinicSeedSortReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling sortClinicSeedUsingPOST.');
            }
            const localVarPath = `/api/clinic/seed/sort`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sort visit fees
         * @param {ClinicVisitFeeSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortVisitFeeUsingPOST(req: ClinicVisitFeeSortReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling sortVisitFeeUsingPOST.');
            }
            const localVarPath = `/api/clinic/visit/fee/sort`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClinicControllerApi - functional programming interface
 * @export
 */
export const ClinicControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create unofficial ranch
         * @param {ClinicRanchCreateReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRanchUsingPOST(req: ClinicRanchCreateReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseComIdDto> {
            const localVarAxiosArgs = ClinicControllerApiAxiosParamCreator(configuration).createRanchUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * delete the clinic seed lot
         * @summary delete clinic seed lot
         * @param {SeedLotDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClinicSeedLotUsingPOST(req: SeedLotDeleteReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = ClinicControllerApiAxiosParamCreator(configuration).deleteClinicSeedLotUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * delete the clinic seed
         * @summary delete clinic seed
         * @param {ClinicSeedDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClinicSeedUsingPOST(req: ClinicSeedDeleteReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = ClinicControllerApiAxiosParamCreator(configuration).deleteClinicSeedUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary delete a visit fee item
         * @param {ClinicVisitFeeDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVisitFeeUsingPOST(req: ClinicVisitFeeDeleteReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = ClinicControllerApiAxiosParamCreator(configuration).deleteVisitFeeUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * delete the clinic visitation
         * @summary delete clinic visitation
         * @param {VisitDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVisitUsingPOST(req: VisitDeleteReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = ClinicControllerApiAxiosParamCreator(configuration).deleteVisitUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get schedules for clinic
         * @param {ClinicScheduleReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClinicScheduleListUsingPOST(req: ClinicScheduleReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListRanchScheduleDto> {
            const localVarAxiosArgs = ClinicControllerApiAxiosParamCreator(configuration).getClinicScheduleListUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get the clinic seed list
         * @summary clinic seed
         * @param {ComClinicIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClinicSeedListUsingPOST(req: ComClinicIdReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListRanchSeedListDto> {
            const localVarAxiosArgs = ClinicControllerApiAxiosParamCreator(configuration).getClinicSeedListUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get visit fee list
         * @param {ComClinicIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVisitFeeUsingPOST(req: ComClinicIdReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListClinicVisitFeeDto> {
            const localVarAxiosArgs = ClinicControllerApiAxiosParamCreator(configuration).listVisitFeeUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get the clinic visitation list
         * @summary clinic visitation list
         * @param {VisitListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVisitUsingPOST(req: VisitListReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListVisitListDto> {
            const localVarAxiosArgs = ClinicControllerApiAxiosParamCreator(configuration).listVisitUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * modify the clinic seed lot
         * @summary modify clinic seed lot
         * @param {ClinicSeedLotModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyClinicSeedLotUsingPOST(req: ClinicSeedLotModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = ClinicControllerApiAxiosParamCreator(configuration).modifyClinicSeedLotUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * modify the clinic seed stock
         * @summary modify clinic seed stock
         * @param {ClinicSeedStockModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyClinicSeedStockUsingPOST(req: ClinicSeedStockModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = ClinicControllerApiAxiosParamCreator(configuration).modifyClinicSeedStockUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * modify the clinic seed list
         * @summary modify clinic seed
         * @param {ClinicSeedModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyClinicSeedUsingPOST(req: ClinicSeedModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseComIdDto> {
            const localVarAxiosArgs = ClinicControllerApiAxiosParamCreator(configuration).modifyClinicSeedUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary create or update visit fee
         * @param {ClinicVisitFeeModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyVisitFeeUsingPOST(req: ClinicVisitFeeModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = ClinicControllerApiAxiosParamCreator(configuration).modifyVisitFeeUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * modify the clinic visitation list
         * @summary modify clinic visitation
         * @param {VisitModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyVisitUsingPOST(req: VisitModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = ClinicControllerApiAxiosParamCreator(configuration).modifyVisitUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * sort disp no of clinic seed
         * @summary sort disp no of clinic seed
         * @param {ClinicSeedSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortClinicSeedUsingPOST(req: ClinicSeedSortReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = ClinicControllerApiAxiosParamCreator(configuration).sortClinicSeedUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary sort visit fees
         * @param {ClinicVisitFeeSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortVisitFeeUsingPOST(req: ClinicVisitFeeSortReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = ClinicControllerApiAxiosParamCreator(configuration).sortVisitFeeUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ClinicControllerApi - factory interface
 * @export
 */
export const ClinicControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary create unofficial ranch
         * @param {ClinicRanchCreateReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRanchUsingPOST(req: ClinicRanchCreateReq, options?: any): AxiosPromise<GenericResponseComIdDto> {
            return ClinicControllerApiFp(configuration).createRanchUsingPOST(req, options)(axios, basePath);
        },
        /**
         * delete the clinic seed lot
         * @summary delete clinic seed lot
         * @param {SeedLotDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClinicSeedLotUsingPOST(req: SeedLotDeleteReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return ClinicControllerApiFp(configuration).deleteClinicSeedLotUsingPOST(req, options)(axios, basePath);
        },
        /**
         * delete the clinic seed
         * @summary delete clinic seed
         * @param {ClinicSeedDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClinicSeedUsingPOST(req: ClinicSeedDeleteReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return ClinicControllerApiFp(configuration).deleteClinicSeedUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary delete a visit fee item
         * @param {ClinicVisitFeeDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVisitFeeUsingPOST(req: ClinicVisitFeeDeleteReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return ClinicControllerApiFp(configuration).deleteVisitFeeUsingPOST(req, options)(axios, basePath);
        },
        /**
         * delete the clinic visitation
         * @summary delete clinic visitation
         * @param {VisitDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVisitUsingPOST(req: VisitDeleteReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return ClinicControllerApiFp(configuration).deleteVisitUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary get schedules for clinic
         * @param {ClinicScheduleReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClinicScheduleListUsingPOST(req: ClinicScheduleReq, options?: any): AxiosPromise<GenericResponseListRanchScheduleDto> {
            return ClinicControllerApiFp(configuration).getClinicScheduleListUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get the clinic seed list
         * @summary clinic seed
         * @param {ComClinicIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClinicSeedListUsingPOST(req: ComClinicIdReq, options?: any): AxiosPromise<GenericResponseListRanchSeedListDto> {
            return ClinicControllerApiFp(configuration).getClinicSeedListUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary get visit fee list
         * @param {ComClinicIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVisitFeeUsingPOST(req: ComClinicIdReq, options?: any): AxiosPromise<GenericResponseListClinicVisitFeeDto> {
            return ClinicControllerApiFp(configuration).listVisitFeeUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get the clinic visitation list
         * @summary clinic visitation list
         * @param {VisitListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVisitUsingPOST(req: VisitListReq, options?: any): AxiosPromise<GenericResponseListVisitListDto> {
            return ClinicControllerApiFp(configuration).listVisitUsingPOST(req, options)(axios, basePath);
        },
        /**
         * modify the clinic seed lot
         * @summary modify clinic seed lot
         * @param {ClinicSeedLotModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyClinicSeedLotUsingPOST(req: ClinicSeedLotModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return ClinicControllerApiFp(configuration).modifyClinicSeedLotUsingPOST(req, options)(axios, basePath);
        },
        /**
         * modify the clinic seed stock
         * @summary modify clinic seed stock
         * @param {ClinicSeedStockModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyClinicSeedStockUsingPOST(req: ClinicSeedStockModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return ClinicControllerApiFp(configuration).modifyClinicSeedStockUsingPOST(req, options)(axios, basePath);
        },
        /**
         * modify the clinic seed list
         * @summary modify clinic seed
         * @param {ClinicSeedModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyClinicSeedUsingPOST(req: ClinicSeedModifyReq, options?: any): AxiosPromise<GenericResponseComIdDto> {
            return ClinicControllerApiFp(configuration).modifyClinicSeedUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary create or update visit fee
         * @param {ClinicVisitFeeModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyVisitFeeUsingPOST(req: ClinicVisitFeeModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return ClinicControllerApiFp(configuration).modifyVisitFeeUsingPOST(req, options)(axios, basePath);
        },
        /**
         * modify the clinic visitation list
         * @summary modify clinic visitation
         * @param {VisitModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyVisitUsingPOST(req: VisitModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return ClinicControllerApiFp(configuration).modifyVisitUsingPOST(req, options)(axios, basePath);
        },
        /**
         * sort disp no of clinic seed
         * @summary sort disp no of clinic seed
         * @param {ClinicSeedSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortClinicSeedUsingPOST(req: ClinicSeedSortReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return ClinicControllerApiFp(configuration).sortClinicSeedUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary sort visit fees
         * @param {ClinicVisitFeeSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortVisitFeeUsingPOST(req: ClinicVisitFeeSortReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return ClinicControllerApiFp(configuration).sortVisitFeeUsingPOST(req, options)(axios, basePath);
        },
    };
};

/**
 * ClinicControllerApi - object-oriented interface
 * @export
 * @class ClinicControllerApi
 * @extends {BaseAPI}
 */
export class ClinicControllerApi extends BaseAPI {
    /**
     * 
     * @summary create unofficial ranch
     * @param {ClinicRanchCreateReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicControllerApi
     */
    public createRanchUsingPOST(req: ClinicRanchCreateReq, options?: any) {
        return ClinicControllerApiFp(this.configuration).createRanchUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * delete the clinic seed lot
     * @summary delete clinic seed lot
     * @param {SeedLotDeleteReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicControllerApi
     */
    public deleteClinicSeedLotUsingPOST(req: SeedLotDeleteReq, options?: any) {
        return ClinicControllerApiFp(this.configuration).deleteClinicSeedLotUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * delete the clinic seed
     * @summary delete clinic seed
     * @param {ClinicSeedDeleteReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicControllerApi
     */
    public deleteClinicSeedUsingPOST(req: ClinicSeedDeleteReq, options?: any) {
        return ClinicControllerApiFp(this.configuration).deleteClinicSeedUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary delete a visit fee item
     * @param {ClinicVisitFeeDeleteReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicControllerApi
     */
    public deleteVisitFeeUsingPOST(req: ClinicVisitFeeDeleteReq, options?: any) {
        return ClinicControllerApiFp(this.configuration).deleteVisitFeeUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * delete the clinic visitation
     * @summary delete clinic visitation
     * @param {VisitDeleteReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicControllerApi
     */
    public deleteVisitUsingPOST(req: VisitDeleteReq, options?: any) {
        return ClinicControllerApiFp(this.configuration).deleteVisitUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get schedules for clinic
     * @param {ClinicScheduleReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicControllerApi
     */
    public getClinicScheduleListUsingPOST(req: ClinicScheduleReq, options?: any) {
        return ClinicControllerApiFp(this.configuration).getClinicScheduleListUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get the clinic seed list
     * @summary clinic seed
     * @param {ComClinicIdReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicControllerApi
     */
    public getClinicSeedListUsingPOST(req: ComClinicIdReq, options?: any) {
        return ClinicControllerApiFp(this.configuration).getClinicSeedListUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get visit fee list
     * @param {ComClinicIdReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicControllerApi
     */
    public listVisitFeeUsingPOST(req: ComClinicIdReq, options?: any) {
        return ClinicControllerApiFp(this.configuration).listVisitFeeUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get the clinic visitation list
     * @summary clinic visitation list
     * @param {VisitListReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicControllerApi
     */
    public listVisitUsingPOST(req: VisitListReq, options?: any) {
        return ClinicControllerApiFp(this.configuration).listVisitUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * modify the clinic seed lot
     * @summary modify clinic seed lot
     * @param {ClinicSeedLotModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicControllerApi
     */
    public modifyClinicSeedLotUsingPOST(req: ClinicSeedLotModifyReq, options?: any) {
        return ClinicControllerApiFp(this.configuration).modifyClinicSeedLotUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * modify the clinic seed stock
     * @summary modify clinic seed stock
     * @param {ClinicSeedStockModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicControllerApi
     */
    public modifyClinicSeedStockUsingPOST(req: ClinicSeedStockModifyReq, options?: any) {
        return ClinicControllerApiFp(this.configuration).modifyClinicSeedStockUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * modify the clinic seed list
     * @summary modify clinic seed
     * @param {ClinicSeedModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicControllerApi
     */
    public modifyClinicSeedUsingPOST(req: ClinicSeedModifyReq, options?: any) {
        return ClinicControllerApiFp(this.configuration).modifyClinicSeedUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary create or update visit fee
     * @param {ClinicVisitFeeModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicControllerApi
     */
    public modifyVisitFeeUsingPOST(req: ClinicVisitFeeModifyReq, options?: any) {
        return ClinicControllerApiFp(this.configuration).modifyVisitFeeUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * modify the clinic visitation list
     * @summary modify clinic visitation
     * @param {VisitModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicControllerApi
     */
    public modifyVisitUsingPOST(req: VisitModifyReq, options?: any) {
        return ClinicControllerApiFp(this.configuration).modifyVisitUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * sort disp no of clinic seed
     * @summary sort disp no of clinic seed
     * @param {ClinicSeedSortReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicControllerApi
     */
    public sortClinicSeedUsingPOST(req: ClinicSeedSortReq, options?: any) {
        return ClinicControllerApiFp(this.configuration).sortClinicSeedUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary sort visit fees
     * @param {ClinicVisitFeeSortReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicControllerApi
     */
    public sortVisitFeeUsingPOST(req: ClinicVisitFeeSortReq, options?: any) {
        return ClinicControllerApiFp(this.configuration).sortVisitFeeUsingPOST(req, options)(this.axios, this.basePath);
    }

}


/**
 * CowControllerApi - axios parameter creator
 * @export
 */
export const CowControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * delete new cow location
         * @summary delete cow location
         * @param {CowLocationDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCowLocationUsingPOST(req: CowLocationDeleteReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deleteCowLocationUsingPOST.');
            }
            const localVarPath = `/api/cow/location/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete cow filter
         * @summary delete cow filter
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFilterUsingPOST(req: ComRanchAndIdReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deleteFilterUsingPOST.');
            }
            const localVarPath = `/api/cow/filter/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary export cow event history
         * @param {CowExportReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEventsUsingPOST(req: CowExportReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling exportEventsUsingPOST.');
            }
            const localVarPath = `/api/cow/export`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get active cow list
         * @param {ComRanchIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveCowsUsingPOST(req: ComRanchIdReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getActiveCowsUsingPOST.');
            }
            const localVarPath = `/api/cow/list/active`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get cow detail info
         * @param {CowInfoReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCowInfoUsingPOST(req: CowInfoReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getCowInfoUsingPOST.');
            }
            const localVarPath = `/api/cow/info`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get tag ids by cow
         * @param {ComCowIdsReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCowTagsUsingPOST(req: ComCowIdsReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getCowTagsUsingPOST.');
            }
            const localVarPath = `/api/cow/tag/cow`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary V4.2 deprecated
         * @param {CowDiseaseItemsReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiseaseItemsUsingPOST(req: CowDiseaseItemsReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getDiseaseItemsUsingPOST.');
            }
            const localVarPath = `/api/cow/disease/items`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the cow detail history
         * @summary cow detail history
         * @param {CowEventHistoryReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoUsingPOST(req: CowEventHistoryReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getInfoUsingPOST.');
            }
            const localVarPath = `/api/cow/event/history`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary (deprecated) use /info instead.
         * @param {number} id id
         * @param {number} ranchId ranch_id
         * @param {number} isDetail is_detail
         * @param {string} [userId] user_id
         * @param {number} [amountDays] amount_days
         * @param {number} [eventCount] event_count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoUsingPOST1(id: number, ranchId: number, isDetail: number, userId?: string, amountDays?: number, eventCount?: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getInfoUsingPOST1.');
            }
            // verify required parameter 'ranchId' is not null or undefined
            if (ranchId === null || ranchId === undefined) {
                throw new RequiredError('ranchId','Required parameter ranchId was null or undefined when calling getInfoUsingPOST1.');
            }
            // verify required parameter 'isDetail' is not null or undefined
            if (isDetail === null || isDetail === undefined) {
                throw new RequiredError('isDetail','Required parameter isDetail was null or undefined when calling getInfoUsingPOST1.');
            }
            const localVarPath = `/api/cow/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (ranchId !== undefined) {
                localVarQueryParameter['ranch_id'] = ranchId;
            }

            if (isDetail !== undefined) {
                localVarQueryParameter['is_detail'] = isDetail;
            }

            if (amountDays !== undefined) {
                localVarQueryParameter['amount_days'] = amountDays;
            }

            if (eventCount !== undefined) {
                localVarQueryParameter['event_count'] = eventCount;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the cow list
         * @summary cow list
         * @param {CowListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListUsingPOST(req: CowListReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getListUsingPOST.');
            }
            const localVarPath = `/api/cow/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the cow location history
         * @summary cow location history
         * @param {CowLocationHistReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationInfoListUsingPOST(req: CowLocationHistReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getLocationInfoListUsingPOST.');
            }
            const localVarPath = `/api/cow/location/history`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the common current location of the cows
         * @summary cow current location
         * @param {CowLocationReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationUsingPOST(req: CowLocationReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getLocationUsingPOST.');
            }
            const localVarPath = `/api/cow/location`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get program items witch will be generated by the cow registration.
         * @param {ProgramAppliedItemsReqCowModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramsForCowRegistrationUsingPOST(req: ProgramAppliedItemsReqCowModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getProgramsForCowRegistrationUsingPOST.');
            }
            const localVarPath = `/api/cow/program`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list cow filter
         * @summary list cow filter
         * @param {ComRanchIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFilterUsingPOST(req: ComRanchIdReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling listFilterUsingPOST.');
            }
            const localVarPath = `/api/cow/filter/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * modify the cow breeding and activity
         * @summary modify cow breeding
         * @param {CowBreedingModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyBreedingUsingPOST(req: CowBreedingModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyBreedingUsingPOST.');
            }
            const localVarPath = `/api/cow/breeding/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add new cow location
         * @summary add cow location
         * @param {CowLocationModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyCowLocationUsingPOST(req: CowLocationModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyCowLocationUsingPOST.');
            }
            const localVarPath = `/api/cow/location/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add new cow or modify cow
         * @summary add or modify cow
         * @param {CowModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyCowUsingPOST(req: CowModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyCowUsingPOST.');
            }
            const localVarPath = `/api/cow/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * modify cow\'s watching attribute
         * @summary modify cow\'s watching attribute
         * @param {CowWatchingModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyCowWatchingUsingPOST(req: CowWatchingModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyCowWatchingUsingPOST.');
            }
            const localVarPath = `/api/cow/watching/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * modify cow filter
         * @summary modify cow filter
         * @param {CowFilterModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyFilterUsingPOST(req: CowFilterModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyFilterUsingPOST.');
            }
            const localVarPath = `/api/cow/filter/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * modify cow\'s tag
         * @summary modify cow\'s tag
         * @param {CowTagModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyTagUsingPOST(req: CowTagModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyTagUsingPOST.');
            }
            const localVarPath = `/api/cow/tag/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the cow list with detail conditions
         * @summary cow list
         * @param {CowSearchReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCowUsingPOST(req: CowSearchReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling searchCowUsingPOST.');
            }
            const localVarPath = `/api/cow/search`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sort cow filters
         * @param {CowFilterSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortFilterUsingPOST(req: CowFilterSortReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling sortFilterUsingPOST.');
            }
            const localVarPath = `/api/cow/filter/sort`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CowControllerApi - functional programming interface
 * @export
 */
export const CowControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * delete new cow location
         * @summary delete cow location
         * @param {CowLocationDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCowLocationUsingPOST(req: CowLocationDeleteReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseCowLocationHistDto> {
            const localVarAxiosArgs = CowControllerApiAxiosParamCreator(configuration).deleteCowLocationUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * delete cow filter
         * @summary delete cow filter
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFilterUsingPOST(req: ComRanchAndIdReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = CowControllerApiAxiosParamCreator(configuration).deleteFilterUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary export cow event history
         * @param {CowExportReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEventsUsingPOST(req: CowExportReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseCowExportDto> {
            const localVarAxiosArgs = CowControllerApiAxiosParamCreator(configuration).exportEventsUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get active cow list
         * @param {ComRanchIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveCowsUsingPOST(req: ComRanchIdReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListCowListSlimDto> {
            const localVarAxiosArgs = CowControllerApiAxiosParamCreator(configuration).getActiveCowsUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get cow detail info
         * @param {CowInfoReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCowInfoUsingPOST(req: CowInfoReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseCowDetailDto> {
            const localVarAxiosArgs = CowControllerApiAxiosParamCreator(configuration).getCowInfoUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get tag ids by cow
         * @param {ComCowIdsReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCowTagsUsingPOST(req: ComCowIdsReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListCowTagDto> {
            const localVarAxiosArgs = CowControllerApiAxiosParamCreator(configuration).getCowTagsUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary V4.2 deprecated
         * @param {CowDiseaseItemsReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiseaseItemsUsingPOST(req: CowDiseaseItemsReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseCowDiseaseItemsDto> {
            const localVarAxiosArgs = CowControllerApiAxiosParamCreator(configuration).getDiseaseItemsUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get the cow detail history
         * @summary cow detail history
         * @param {CowEventHistoryReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoUsingPOST(req: CowEventHistoryReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseCowEventListDto> {
            const localVarAxiosArgs = CowControllerApiAxiosParamCreator(configuration).getInfoUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary (deprecated) use /info instead.
         * @param {number} id id
         * @param {number} ranchId ranch_id
         * @param {number} isDetail is_detail
         * @param {string} [userId] user_id
         * @param {number} [amountDays] amount_days
         * @param {number} [eventCount] event_count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoUsingPOST1(id: number, ranchId: number, isDetail: number, userId?: string, amountDays?: number, eventCount?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseCowDetailDto> {
            const localVarAxiosArgs = CowControllerApiAxiosParamCreator(configuration).getInfoUsingPOST1(id, ranchId, isDetail, userId, amountDays, eventCount, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get the cow list
         * @summary cow list
         * @param {CowListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListUsingPOST(req: CowListReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseCowListAndCountDto> {
            const localVarAxiosArgs = CowControllerApiAxiosParamCreator(configuration).getListUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get the cow location history
         * @summary cow location history
         * @param {CowLocationHistReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationInfoListUsingPOST(req: CowLocationHistReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseCowLocationHistDto> {
            const localVarAxiosArgs = CowControllerApiAxiosParamCreator(configuration).getLocationInfoListUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get the common current location of the cows
         * @summary cow current location
         * @param {CowLocationReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationUsingPOST(req: CowLocationReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseCowCurrentLocationDto> {
            const localVarAxiosArgs = CowControllerApiAxiosParamCreator(configuration).getLocationUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get program items witch will be generated by the cow registration.
         * @param {ProgramAppliedItemsReqCowModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramsForCowRegistrationUsingPOST(req: ProgramAppliedItemsReqCowModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseProgramOfCowApplyResultDto> {
            const localVarAxiosArgs = CowControllerApiAxiosParamCreator(configuration).getProgramsForCowRegistrationUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * list cow filter
         * @summary list cow filter
         * @param {ComRanchIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFilterUsingPOST(req: ComRanchIdReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseCowFilterDto> {
            const localVarAxiosArgs = CowControllerApiAxiosParamCreator(configuration).listFilterUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * modify the cow breeding and activity
         * @summary modify cow breeding
         * @param {CowBreedingModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyBreedingUsingPOST(req: CowBreedingModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = CowControllerApiAxiosParamCreator(configuration).modifyBreedingUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * add new cow location
         * @summary add cow location
         * @param {CowLocationModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyCowLocationUsingPOST(req: CowLocationModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = CowControllerApiAxiosParamCreator(configuration).modifyCowLocationUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * add new cow or modify cow
         * @summary add or modify cow
         * @param {CowModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyCowUsingPOST(req: CowModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseCowIdDto> {
            const localVarAxiosArgs = CowControllerApiAxiosParamCreator(configuration).modifyCowUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * modify cow\'s watching attribute
         * @summary modify cow\'s watching attribute
         * @param {CowWatchingModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyCowWatchingUsingPOST(req: CowWatchingModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = CowControllerApiAxiosParamCreator(configuration).modifyCowWatchingUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * modify cow filter
         * @summary modify cow filter
         * @param {CowFilterModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyFilterUsingPOST(req: CowFilterModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseComIdDto> {
            const localVarAxiosArgs = CowControllerApiAxiosParamCreator(configuration).modifyFilterUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * modify cow\'s tag
         * @summary modify cow\'s tag
         * @param {CowTagModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyTagUsingPOST(req: CowTagModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = CowControllerApiAxiosParamCreator(configuration).modifyTagUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get the cow list with detail conditions
         * @summary cow list
         * @param {CowSearchReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCowUsingPOST(req: CowSearchReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseCowSearchDto> {
            const localVarAxiosArgs = CowControllerApiAxiosParamCreator(configuration).searchCowUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary sort cow filters
         * @param {CowFilterSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortFilterUsingPOST(req: CowFilterSortReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = CowControllerApiAxiosParamCreator(configuration).sortFilterUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CowControllerApi - factory interface
 * @export
 */
export const CowControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * delete new cow location
         * @summary delete cow location
         * @param {CowLocationDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCowLocationUsingPOST(req: CowLocationDeleteReq, options?: any): AxiosPromise<GenericResponseCowLocationHistDto> {
            return CowControllerApiFp(configuration).deleteCowLocationUsingPOST(req, options)(axios, basePath);
        },
        /**
         * delete cow filter
         * @summary delete cow filter
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFilterUsingPOST(req: ComRanchAndIdReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return CowControllerApiFp(configuration).deleteFilterUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary export cow event history
         * @param {CowExportReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEventsUsingPOST(req: CowExportReq, options?: any): AxiosPromise<GenericResponseCowExportDto> {
            return CowControllerApiFp(configuration).exportEventsUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary get active cow list
         * @param {ComRanchIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveCowsUsingPOST(req: ComRanchIdReq, options?: any): AxiosPromise<GenericResponseListCowListSlimDto> {
            return CowControllerApiFp(configuration).getActiveCowsUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary get cow detail info
         * @param {CowInfoReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCowInfoUsingPOST(req: CowInfoReq, options?: any): AxiosPromise<GenericResponseCowDetailDto> {
            return CowControllerApiFp(configuration).getCowInfoUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary get tag ids by cow
         * @param {ComCowIdsReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCowTagsUsingPOST(req: ComCowIdsReq, options?: any): AxiosPromise<GenericResponseListCowTagDto> {
            return CowControllerApiFp(configuration).getCowTagsUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary V4.2 deprecated
         * @param {CowDiseaseItemsReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiseaseItemsUsingPOST(req: CowDiseaseItemsReq, options?: any): AxiosPromise<GenericResponseCowDiseaseItemsDto> {
            return CowControllerApiFp(configuration).getDiseaseItemsUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get the cow detail history
         * @summary cow detail history
         * @param {CowEventHistoryReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoUsingPOST(req: CowEventHistoryReq, options?: any): AxiosPromise<GenericResponseCowEventListDto> {
            return CowControllerApiFp(configuration).getInfoUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary (deprecated) use /info instead.
         * @param {number} id id
         * @param {number} ranchId ranch_id
         * @param {number} isDetail is_detail
         * @param {string} [userId] user_id
         * @param {number} [amountDays] amount_days
         * @param {number} [eventCount] event_count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoUsingPOST1(id: number, ranchId: number, isDetail: number, userId?: string, amountDays?: number, eventCount?: number, options?: any): AxiosPromise<GenericResponseCowDetailDto> {
            return CowControllerApiFp(configuration).getInfoUsingPOST1(id, ranchId, isDetail, userId, amountDays, eventCount, options)(axios, basePath);
        },
        /**
         * get the cow list
         * @summary cow list
         * @param {CowListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListUsingPOST(req: CowListReq, options?: any): AxiosPromise<GenericResponseCowListAndCountDto> {
            return CowControllerApiFp(configuration).getListUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get the cow location history
         * @summary cow location history
         * @param {CowLocationHistReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationInfoListUsingPOST(req: CowLocationHistReq, options?: any): AxiosPromise<GenericResponseCowLocationHistDto> {
            return CowControllerApiFp(configuration).getLocationInfoListUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get the common current location of the cows
         * @summary cow current location
         * @param {CowLocationReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationUsingPOST(req: CowLocationReq, options?: any): AxiosPromise<GenericResponseCowCurrentLocationDto> {
            return CowControllerApiFp(configuration).getLocationUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary get program items witch will be generated by the cow registration.
         * @param {ProgramAppliedItemsReqCowModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramsForCowRegistrationUsingPOST(req: ProgramAppliedItemsReqCowModifyReq, options?: any): AxiosPromise<GenericResponseProgramOfCowApplyResultDto> {
            return CowControllerApiFp(configuration).getProgramsForCowRegistrationUsingPOST(req, options)(axios, basePath);
        },
        /**
         * list cow filter
         * @summary list cow filter
         * @param {ComRanchIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFilterUsingPOST(req: ComRanchIdReq, options?: any): AxiosPromise<GenericResponseCowFilterDto> {
            return CowControllerApiFp(configuration).listFilterUsingPOST(req, options)(axios, basePath);
        },
        /**
         * modify the cow breeding and activity
         * @summary modify cow breeding
         * @param {CowBreedingModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyBreedingUsingPOST(req: CowBreedingModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return CowControllerApiFp(configuration).modifyBreedingUsingPOST(req, options)(axios, basePath);
        },
        /**
         * add new cow location
         * @summary add cow location
         * @param {CowLocationModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyCowLocationUsingPOST(req: CowLocationModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return CowControllerApiFp(configuration).modifyCowLocationUsingPOST(req, options)(axios, basePath);
        },
        /**
         * add new cow or modify cow
         * @summary add or modify cow
         * @param {CowModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyCowUsingPOST(req: CowModifyReq, options?: any): AxiosPromise<GenericResponseCowIdDto> {
            return CowControllerApiFp(configuration).modifyCowUsingPOST(req, options)(axios, basePath);
        },
        /**
         * modify cow\'s watching attribute
         * @summary modify cow\'s watching attribute
         * @param {CowWatchingModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyCowWatchingUsingPOST(req: CowWatchingModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return CowControllerApiFp(configuration).modifyCowWatchingUsingPOST(req, options)(axios, basePath);
        },
        /**
         * modify cow filter
         * @summary modify cow filter
         * @param {CowFilterModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyFilterUsingPOST(req: CowFilterModifyReq, options?: any): AxiosPromise<GenericResponseComIdDto> {
            return CowControllerApiFp(configuration).modifyFilterUsingPOST(req, options)(axios, basePath);
        },
        /**
         * modify cow\'s tag
         * @summary modify cow\'s tag
         * @param {CowTagModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyTagUsingPOST(req: CowTagModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return CowControllerApiFp(configuration).modifyTagUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get the cow list with detail conditions
         * @summary cow list
         * @param {CowSearchReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCowUsingPOST(req: CowSearchReq, options?: any): AxiosPromise<GenericResponseCowSearchDto> {
            return CowControllerApiFp(configuration).searchCowUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary sort cow filters
         * @param {CowFilterSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortFilterUsingPOST(req: CowFilterSortReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return CowControllerApiFp(configuration).sortFilterUsingPOST(req, options)(axios, basePath);
        },
    };
};

/**
 * CowControllerApi - object-oriented interface
 * @export
 * @class CowControllerApi
 * @extends {BaseAPI}
 */
export class CowControllerApi extends BaseAPI {
    /**
     * delete new cow location
     * @summary delete cow location
     * @param {CowLocationDeleteReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CowControllerApi
     */
    public deleteCowLocationUsingPOST(req: CowLocationDeleteReq, options?: any) {
        return CowControllerApiFp(this.configuration).deleteCowLocationUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * delete cow filter
     * @summary delete cow filter
     * @param {ComRanchAndIdReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CowControllerApi
     */
    public deleteFilterUsingPOST(req: ComRanchAndIdReq, options?: any) {
        return CowControllerApiFp(this.configuration).deleteFilterUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary export cow event history
     * @param {CowExportReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CowControllerApi
     */
    public exportEventsUsingPOST(req: CowExportReq, options?: any) {
        return CowControllerApiFp(this.configuration).exportEventsUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get active cow list
     * @param {ComRanchIdReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CowControllerApi
     */
    public getActiveCowsUsingPOST(req: ComRanchIdReq, options?: any) {
        return CowControllerApiFp(this.configuration).getActiveCowsUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get cow detail info
     * @param {CowInfoReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CowControllerApi
     */
    public getCowInfoUsingPOST(req: CowInfoReq, options?: any) {
        return CowControllerApiFp(this.configuration).getCowInfoUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get tag ids by cow
     * @param {ComCowIdsReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CowControllerApi
     */
    public getCowTagsUsingPOST(req: ComCowIdsReq, options?: any) {
        return CowControllerApiFp(this.configuration).getCowTagsUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary V4.2 deprecated
     * @param {CowDiseaseItemsReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CowControllerApi
     */
    public getDiseaseItemsUsingPOST(req: CowDiseaseItemsReq, options?: any) {
        return CowControllerApiFp(this.configuration).getDiseaseItemsUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get the cow detail history
     * @summary cow detail history
     * @param {CowEventHistoryReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CowControllerApi
     */
    public getInfoUsingPOST(req: CowEventHistoryReq, options?: any) {
        return CowControllerApiFp(this.configuration).getInfoUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary (deprecated) use /info instead.
     * @param {number} id id
     * @param {number} ranchId ranch_id
     * @param {number} isDetail is_detail
     * @param {string} [userId] user_id
     * @param {number} [amountDays] amount_days
     * @param {number} [eventCount] event_count
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CowControllerApi
     */
    public getInfoUsingPOST1(id: number, ranchId: number, isDetail: number, userId?: string, amountDays?: number, eventCount?: number, options?: any) {
        return CowControllerApiFp(this.configuration).getInfoUsingPOST1(id, ranchId, isDetail, userId, amountDays, eventCount, options)(this.axios, this.basePath);
    }

    /**
     * get the cow list
     * @summary cow list
     * @param {CowListReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CowControllerApi
     */
    public getListUsingPOST(req: CowListReq, options?: any) {
        return CowControllerApiFp(this.configuration).getListUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get the cow location history
     * @summary cow location history
     * @param {CowLocationHistReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CowControllerApi
     */
    public getLocationInfoListUsingPOST(req: CowLocationHistReq, options?: any) {
        return CowControllerApiFp(this.configuration).getLocationInfoListUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get the common current location of the cows
     * @summary cow current location
     * @param {CowLocationReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CowControllerApi
     */
    public getLocationUsingPOST(req: CowLocationReq, options?: any) {
        return CowControllerApiFp(this.configuration).getLocationUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get program items witch will be generated by the cow registration.
     * @param {ProgramAppliedItemsReqCowModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CowControllerApi
     */
    public getProgramsForCowRegistrationUsingPOST(req: ProgramAppliedItemsReqCowModifyReq, options?: any) {
        return CowControllerApiFp(this.configuration).getProgramsForCowRegistrationUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * list cow filter
     * @summary list cow filter
     * @param {ComRanchIdReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CowControllerApi
     */
    public listFilterUsingPOST(req: ComRanchIdReq, options?: any) {
        return CowControllerApiFp(this.configuration).listFilterUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * modify the cow breeding and activity
     * @summary modify cow breeding
     * @param {CowBreedingModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CowControllerApi
     */
    public modifyBreedingUsingPOST(req: CowBreedingModifyReq, options?: any) {
        return CowControllerApiFp(this.configuration).modifyBreedingUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * add new cow location
     * @summary add cow location
     * @param {CowLocationModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CowControllerApi
     */
    public modifyCowLocationUsingPOST(req: CowLocationModifyReq, options?: any) {
        return CowControllerApiFp(this.configuration).modifyCowLocationUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * add new cow or modify cow
     * @summary add or modify cow
     * @param {CowModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CowControllerApi
     */
    public modifyCowUsingPOST(req: CowModifyReq, options?: any) {
        return CowControllerApiFp(this.configuration).modifyCowUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * modify cow\'s watching attribute
     * @summary modify cow\'s watching attribute
     * @param {CowWatchingModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CowControllerApi
     */
    public modifyCowWatchingUsingPOST(req: CowWatchingModifyReq, options?: any) {
        return CowControllerApiFp(this.configuration).modifyCowWatchingUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * modify cow filter
     * @summary modify cow filter
     * @param {CowFilterModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CowControllerApi
     */
    public modifyFilterUsingPOST(req: CowFilterModifyReq, options?: any) {
        return CowControllerApiFp(this.configuration).modifyFilterUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * modify cow\'s tag
     * @summary modify cow\'s tag
     * @param {CowTagModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CowControllerApi
     */
    public modifyTagUsingPOST(req: CowTagModifyReq, options?: any) {
        return CowControllerApiFp(this.configuration).modifyTagUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get the cow list with detail conditions
     * @summary cow list
     * @param {CowSearchReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CowControllerApi
     */
    public searchCowUsingPOST(req: CowSearchReq, options?: any) {
        return CowControllerApiFp(this.configuration).searchCowUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary sort cow filters
     * @param {CowFilterSortReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CowControllerApi
     */
    public sortFilterUsingPOST(req: CowFilterSortReq, options?: any) {
        return CowControllerApiFp(this.configuration).sortFilterUsingPOST(req, options)(this.axios, this.basePath);
    }

}


/**
 * DeliveryControllerApi - axios parameter creator
 * @export
 */
export const DeliveryControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary aggregate delivery count by month
         * @param {AggregateTransitionReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateTransitionUsingPOST(req: AggregateTransitionReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling aggregateTransitionUsingPOST.');
            }
            const localVarPath = `/api/delivery/aggregate/transition`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete delivery
         * @summary delete delivery
         * @param {DeliveryDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST1(req: DeliveryDeleteReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deleteUsingPOST1.');
            }
            const localVarPath = `/api/delivery/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get delivery history
         * @summary delivery history
         * @param {DeliveryHistoryReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoryListUsingPOST1(req: DeliveryHistoryReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getHistoryListUsingPOST1.');
            }
            const localVarPath = `/api/delivery/history`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get delivery detail
         * @summary delivery detail
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoUsingPOST2(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getInfoUsingPOST2.');
            }
            const localVarPath = `/api/delivery/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get program items witch will be generated by the delivery event.
         * @param {ProgramAppliedItemsReqDeliveryModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramsForDeliveryUsingPOST(req: ProgramAppliedItemsReqDeliveryModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getProgramsForDeliveryUsingPOST.');
            }
            const localVarPath = `/api/delivery/program`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add new cow or modify delivery
         * @summary add or modify delivery
         * @param {DeliveryModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyUsingPOST1(req: DeliveryModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyUsingPOST1.');
            }
            const localVarPath = `/api/delivery/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeliveryControllerApi - functional programming interface
 * @export
 */
export const DeliveryControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary aggregate delivery count by month
         * @param {AggregateTransitionReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateTransitionUsingPOST(req: AggregateTransitionReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseDeliveryAggregateTransitionDto> {
            const localVarAxiosArgs = DeliveryControllerApiAxiosParamCreator(configuration).aggregateTransitionUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * delete delivery
         * @summary delete delivery
         * @param {DeliveryDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST1(req: DeliveryDeleteReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = DeliveryControllerApiAxiosParamCreator(configuration).deleteUsingPOST1(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get delivery history
         * @summary delivery history
         * @param {DeliveryHistoryReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoryListUsingPOST1(req: DeliveryHistoryReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseDeliveryHistoryListDto> {
            const localVarAxiosArgs = DeliveryControllerApiAxiosParamCreator(configuration).getHistoryListUsingPOST1(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get delivery detail
         * @summary delivery detail
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoUsingPOST2(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseDeliveryDto> {
            const localVarAxiosArgs = DeliveryControllerApiAxiosParamCreator(configuration).getInfoUsingPOST2(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get program items witch will be generated by the delivery event.
         * @param {ProgramAppliedItemsReqDeliveryModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramsForDeliveryUsingPOST(req: ProgramAppliedItemsReqDeliveryModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseProgramApplyResultDto> {
            const localVarAxiosArgs = DeliveryControllerApiAxiosParamCreator(configuration).getProgramsForDeliveryUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * add new cow or modify delivery
         * @summary add or modify delivery
         * @param {DeliveryModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyUsingPOST1(req: DeliveryModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseRecordIdDto> {
            const localVarAxiosArgs = DeliveryControllerApiAxiosParamCreator(configuration).modifyUsingPOST1(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DeliveryControllerApi - factory interface
 * @export
 */
export const DeliveryControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary aggregate delivery count by month
         * @param {AggregateTransitionReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateTransitionUsingPOST(req: AggregateTransitionReq, options?: any): AxiosPromise<GenericResponseDeliveryAggregateTransitionDto> {
            return DeliveryControllerApiFp(configuration).aggregateTransitionUsingPOST(req, options)(axios, basePath);
        },
        /**
         * delete delivery
         * @summary delete delivery
         * @param {DeliveryDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST1(req: DeliveryDeleteReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return DeliveryControllerApiFp(configuration).deleteUsingPOST1(req, options)(axios, basePath);
        },
        /**
         * get delivery history
         * @summary delivery history
         * @param {DeliveryHistoryReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoryListUsingPOST1(req: DeliveryHistoryReq, options?: any): AxiosPromise<GenericResponseDeliveryHistoryListDto> {
            return DeliveryControllerApiFp(configuration).getHistoryListUsingPOST1(req, options)(axios, basePath);
        },
        /**
         * get delivery detail
         * @summary delivery detail
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoUsingPOST2(id: number, options?: any): AxiosPromise<GenericResponseDeliveryDto> {
            return DeliveryControllerApiFp(configuration).getInfoUsingPOST2(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary get program items witch will be generated by the delivery event.
         * @param {ProgramAppliedItemsReqDeliveryModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramsForDeliveryUsingPOST(req: ProgramAppliedItemsReqDeliveryModifyReq, options?: any): AxiosPromise<GenericResponseProgramApplyResultDto> {
            return DeliveryControllerApiFp(configuration).getProgramsForDeliveryUsingPOST(req, options)(axios, basePath);
        },
        /**
         * add new cow or modify delivery
         * @summary add or modify delivery
         * @param {DeliveryModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyUsingPOST1(req: DeliveryModifyReq, options?: any): AxiosPromise<GenericResponseRecordIdDto> {
            return DeliveryControllerApiFp(configuration).modifyUsingPOST1(req, options)(axios, basePath);
        },
    };
};

/**
 * DeliveryControllerApi - object-oriented interface
 * @export
 * @class DeliveryControllerApi
 * @extends {BaseAPI}
 */
export class DeliveryControllerApi extends BaseAPI {
    /**
     * 
     * @summary aggregate delivery count by month
     * @param {AggregateTransitionReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryControllerApi
     */
    public aggregateTransitionUsingPOST(req: AggregateTransitionReq, options?: any) {
        return DeliveryControllerApiFp(this.configuration).aggregateTransitionUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * delete delivery
     * @summary delete delivery
     * @param {DeliveryDeleteReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryControllerApi
     */
    public deleteUsingPOST1(req: DeliveryDeleteReq, options?: any) {
        return DeliveryControllerApiFp(this.configuration).deleteUsingPOST1(req, options)(this.axios, this.basePath);
    }

    /**
     * get delivery history
     * @summary delivery history
     * @param {DeliveryHistoryReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryControllerApi
     */
    public getHistoryListUsingPOST1(req: DeliveryHistoryReq, options?: any) {
        return DeliveryControllerApiFp(this.configuration).getHistoryListUsingPOST1(req, options)(this.axios, this.basePath);
    }

    /**
     * get delivery detail
     * @summary delivery detail
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryControllerApi
     */
    public getInfoUsingPOST2(id: number, options?: any) {
        return DeliveryControllerApiFp(this.configuration).getInfoUsingPOST2(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get program items witch will be generated by the delivery event.
     * @param {ProgramAppliedItemsReqDeliveryModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryControllerApi
     */
    public getProgramsForDeliveryUsingPOST(req: ProgramAppliedItemsReqDeliveryModifyReq, options?: any) {
        return DeliveryControllerApiFp(this.configuration).getProgramsForDeliveryUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * add new cow or modify delivery
     * @summary add or modify delivery
     * @param {DeliveryModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryControllerApi
     */
    public modifyUsingPOST1(req: DeliveryModifyReq, options?: any) {
        return DeliveryControllerApiFp(this.configuration).modifyUsingPOST1(req, options)(this.axios, this.basePath);
    }

}


/**
 * DynamicsControllerApi - axios parameter creator
 * @export
 */
export const DynamicsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary aggregate dynamics
         * @param {DynamicsAggregateReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateDynamicsUsingPOST(req: DynamicsAggregateReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling aggregateDynamicsUsingPOST.');
            }
            const localVarPath = `/api/dynamics/aggregate`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get age
         * @param {DynamicsAgeReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgeUsingPOST(req: DynamicsAgeReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getAgeUsingPOST.');
            }
            const localVarPath = `/api/dynamics/age`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get dynamics expect settings
         * @param {DynamicsExpectListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpectsUsingPOST(req: DynamicsExpectListReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getExpectsUsingPOST.');
            }
            const localVarPath = `/api/dynamics/expect/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary modify dynamics expect settings
         * @param {DynamicsExpectModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyExpectUsingPOST(req: DynamicsExpectModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyExpectUsingPOST.');
            }
            const localVarPath = `/api/dynamics/expect/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DynamicsControllerApi - functional programming interface
 * @export
 */
export const DynamicsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary aggregate dynamics
         * @param {DynamicsAggregateReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateDynamicsUsingPOST(req: DynamicsAggregateReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseDynamicsAggregateDto> {
            const localVarAxiosArgs = DynamicsControllerApiAxiosParamCreator(configuration).aggregateDynamicsUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get age
         * @param {DynamicsAgeReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgeUsingPOST(req: DynamicsAgeReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListDynamicsAgeDto> {
            const localVarAxiosArgs = DynamicsControllerApiAxiosParamCreator(configuration).getAgeUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get dynamics expect settings
         * @param {DynamicsExpectListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpectsUsingPOST(req: DynamicsExpectListReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListDynamicsExpectDto> {
            const localVarAxiosArgs = DynamicsControllerApiAxiosParamCreator(configuration).getExpectsUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary modify dynamics expect settings
         * @param {DynamicsExpectModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyExpectUsingPOST(req: DynamicsExpectModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = DynamicsControllerApiAxiosParamCreator(configuration).modifyExpectUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DynamicsControllerApi - factory interface
 * @export
 */
export const DynamicsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary aggregate dynamics
         * @param {DynamicsAggregateReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateDynamicsUsingPOST(req: DynamicsAggregateReq, options?: any): AxiosPromise<GenericResponseDynamicsAggregateDto> {
            return DynamicsControllerApiFp(configuration).aggregateDynamicsUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary get age
         * @param {DynamicsAgeReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgeUsingPOST(req: DynamicsAgeReq, options?: any): AxiosPromise<GenericResponseListDynamicsAgeDto> {
            return DynamicsControllerApiFp(configuration).getAgeUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary get dynamics expect settings
         * @param {DynamicsExpectListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpectsUsingPOST(req: DynamicsExpectListReq, options?: any): AxiosPromise<GenericResponseListDynamicsExpectDto> {
            return DynamicsControllerApiFp(configuration).getExpectsUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary modify dynamics expect settings
         * @param {DynamicsExpectModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyExpectUsingPOST(req: DynamicsExpectModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return DynamicsControllerApiFp(configuration).modifyExpectUsingPOST(req, options)(axios, basePath);
        },
    };
};

/**
 * DynamicsControllerApi - object-oriented interface
 * @export
 * @class DynamicsControllerApi
 * @extends {BaseAPI}
 */
export class DynamicsControllerApi extends BaseAPI {
    /**
     * 
     * @summary aggregate dynamics
     * @param {DynamicsAggregateReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicsControllerApi
     */
    public aggregateDynamicsUsingPOST(req: DynamicsAggregateReq, options?: any) {
        return DynamicsControllerApiFp(this.configuration).aggregateDynamicsUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get age
     * @param {DynamicsAgeReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicsControllerApi
     */
    public getAgeUsingPOST(req: DynamicsAgeReq, options?: any) {
        return DynamicsControllerApiFp(this.configuration).getAgeUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get dynamics expect settings
     * @param {DynamicsExpectListReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicsControllerApi
     */
    public getExpectsUsingPOST(req: DynamicsExpectListReq, options?: any) {
        return DynamicsControllerApiFp(this.configuration).getExpectsUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary modify dynamics expect settings
     * @param {DynamicsExpectModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicsControllerApi
     */
    public modifyExpectUsingPOST(req: DynamicsExpectModifyReq, options?: any) {
        return DynamicsControllerApiFp(this.configuration).modifyExpectUsingPOST(req, options)(this.axios, this.basePath);
    }

}


/**
 * EggControllerApi - axios parameter creator
 * @export
 */
export const EggControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary delete a ivf egg record
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIvfEggUsingPOST(req: ComRanchAndIdReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deleteIvfEggUsingPOST.');
            }
            const localVarPath = `/api/egg/ivf/egg/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a ivf record
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIvfUsingPOST(req: ComRanchAndIdReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deleteIvfUsingPOST.');
            }
            const localVarPath = `/api/egg/ivf/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a opu record
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOpuUsingPOST(req: ComRanchAndIdReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deleteOpuUsingPOST.');
            }
            const localVarPath = `/api/egg/opu/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a sov record
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSovUsingPOST(req: ComRanchAndIdReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deleteSovUsingPOST.');
            }
            const localVarPath = `/api/egg/sov/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a ivf egg record
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIvfEggUsingPOST(req: ComRanchAndIdReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getIvfEggUsingPOST.');
            }
            const localVarPath = `/api/egg/ivf/egg/info`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a ivf record
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIvfUsingPOST(req: ComRanchAndIdReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getIvfUsingPOST.');
            }
            const localVarPath = `/api/egg/ivf/info`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a opu record
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpuUsingPOST(req: ComRanchAndIdReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getOpuUsingPOST.');
            }
            const localVarPath = `/api/egg/opu/info`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a sov record
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSovUsingPOST(req: ComRanchAndIdReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getSovUsingPOST.');
            }
            const localVarPath = `/api/egg/sov/info`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list ivf records of a certain period
         * @param {IvfListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listIvfUsingPOST(req: IvfListReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling listIvfUsingPOST.');
            }
            const localVarPath = `/api/egg/ivf/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list opu records of a certain period
         * @param {OpuListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOpuUsingPOST(req: OpuListReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling listOpuUsingPOST.');
            }
            const localVarPath = `/api/egg/opu/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary insert or update a ivf egg record
         * @param {IvfEggModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyIvfEggUsingPOST(req: IvfEggModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyIvfEggUsingPOST.');
            }
            const localVarPath = `/api/egg/ivf/egg/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary insert or update a ivf record
         * @param {IvfModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyIvfUsingPOST(req: IvfModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyIvfUsingPOST.');
            }
            const localVarPath = `/api/egg/ivf/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary insert or update a opu record
         * @param {OpuModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOpuUsingPOST(req: OpuModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyOpuUsingPOST.');
            }
            const localVarPath = `/api/egg/opu/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary insert or update a sov record
         * @param {SovModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifySovUsingPOST(req: SovModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifySovUsingPOST.');
            }
            const localVarPath = `/api/egg/sov/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary stock ivf egg as seeds
         * @param {EggStockReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockIvfEggUsingPOST(req: EggStockReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling stockIvfEggUsingPOST.');
            }
            const localVarPath = `/api/egg/ivf/egg/stock`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary stock sov as seeds
         * @param {EggStockReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockSovUsingPOST(req: EggStockReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling stockSovUsingPOST.');
            }
            const localVarPath = `/api/egg/sov/stock`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EggControllerApi - functional programming interface
 * @export
 */
export const EggControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary delete a ivf egg record
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIvfEggUsingPOST(req: ComRanchAndIdReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = EggControllerApiAxiosParamCreator(configuration).deleteIvfEggUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary delete a ivf record
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIvfUsingPOST(req: ComRanchAndIdReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = EggControllerApiAxiosParamCreator(configuration).deleteIvfUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary delete a opu record
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOpuUsingPOST(req: ComRanchAndIdReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = EggControllerApiAxiosParamCreator(configuration).deleteOpuUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary delete a sov record
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSovUsingPOST(req: ComRanchAndIdReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = EggControllerApiAxiosParamCreator(configuration).deleteSovUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get a ivf egg record
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIvfEggUsingPOST(req: ComRanchAndIdReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseIvfEggDto> {
            const localVarAxiosArgs = EggControllerApiAxiosParamCreator(configuration).getIvfEggUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get a ivf record
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIvfUsingPOST(req: ComRanchAndIdReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseIvfDto> {
            const localVarAxiosArgs = EggControllerApiAxiosParamCreator(configuration).getIvfUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get a opu record
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpuUsingPOST(req: ComRanchAndIdReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseOpuDto> {
            const localVarAxiosArgs = EggControllerApiAxiosParamCreator(configuration).getOpuUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get a sov record
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSovUsingPOST(req: ComRanchAndIdReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseSovDto> {
            const localVarAxiosArgs = EggControllerApiAxiosParamCreator(configuration).getSovUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary list ivf records of a certain period
         * @param {IvfListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listIvfUsingPOST(req: IvfListReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListIvfListDto> {
            const localVarAxiosArgs = EggControllerApiAxiosParamCreator(configuration).listIvfUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary list opu records of a certain period
         * @param {OpuListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOpuUsingPOST(req: OpuListReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListOpuListDto> {
            const localVarAxiosArgs = EggControllerApiAxiosParamCreator(configuration).listOpuUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary insert or update a ivf egg record
         * @param {IvfEggModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyIvfEggUsingPOST(req: IvfEggModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseComIdDto> {
            const localVarAxiosArgs = EggControllerApiAxiosParamCreator(configuration).modifyIvfEggUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary insert or update a ivf record
         * @param {IvfModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyIvfUsingPOST(req: IvfModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseComIdDto> {
            const localVarAxiosArgs = EggControllerApiAxiosParamCreator(configuration).modifyIvfUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary insert or update a opu record
         * @param {OpuModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOpuUsingPOST(req: OpuModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListComCowAndIdDto> {
            const localVarAxiosArgs = EggControllerApiAxiosParamCreator(configuration).modifyOpuUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary insert or update a sov record
         * @param {SovModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifySovUsingPOST(req: SovModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListComCowAndIdDto> {
            const localVarAxiosArgs = EggControllerApiAxiosParamCreator(configuration).modifySovUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary stock ivf egg as seeds
         * @param {EggStockReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockIvfEggUsingPOST(req: EggStockReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = EggControllerApiAxiosParamCreator(configuration).stockIvfEggUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary stock sov as seeds
         * @param {EggStockReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockSovUsingPOST(req: EggStockReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = EggControllerApiAxiosParamCreator(configuration).stockSovUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * EggControllerApi - factory interface
 * @export
 */
export const EggControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary delete a ivf egg record
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIvfEggUsingPOST(req: ComRanchAndIdReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return EggControllerApiFp(configuration).deleteIvfEggUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary delete a ivf record
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIvfUsingPOST(req: ComRanchAndIdReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return EggControllerApiFp(configuration).deleteIvfUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary delete a opu record
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOpuUsingPOST(req: ComRanchAndIdReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return EggControllerApiFp(configuration).deleteOpuUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary delete a sov record
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSovUsingPOST(req: ComRanchAndIdReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return EggControllerApiFp(configuration).deleteSovUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary get a ivf egg record
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIvfEggUsingPOST(req: ComRanchAndIdReq, options?: any): AxiosPromise<GenericResponseIvfEggDto> {
            return EggControllerApiFp(configuration).getIvfEggUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary get a ivf record
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIvfUsingPOST(req: ComRanchAndIdReq, options?: any): AxiosPromise<GenericResponseIvfDto> {
            return EggControllerApiFp(configuration).getIvfUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary get a opu record
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpuUsingPOST(req: ComRanchAndIdReq, options?: any): AxiosPromise<GenericResponseOpuDto> {
            return EggControllerApiFp(configuration).getOpuUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary get a sov record
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSovUsingPOST(req: ComRanchAndIdReq, options?: any): AxiosPromise<GenericResponseSovDto> {
            return EggControllerApiFp(configuration).getSovUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary list ivf records of a certain period
         * @param {IvfListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listIvfUsingPOST(req: IvfListReq, options?: any): AxiosPromise<GenericResponseListIvfListDto> {
            return EggControllerApiFp(configuration).listIvfUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary list opu records of a certain period
         * @param {OpuListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOpuUsingPOST(req: OpuListReq, options?: any): AxiosPromise<GenericResponseListOpuListDto> {
            return EggControllerApiFp(configuration).listOpuUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary insert or update a ivf egg record
         * @param {IvfEggModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyIvfEggUsingPOST(req: IvfEggModifyReq, options?: any): AxiosPromise<GenericResponseComIdDto> {
            return EggControllerApiFp(configuration).modifyIvfEggUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary insert or update a ivf record
         * @param {IvfModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyIvfUsingPOST(req: IvfModifyReq, options?: any): AxiosPromise<GenericResponseComIdDto> {
            return EggControllerApiFp(configuration).modifyIvfUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary insert or update a opu record
         * @param {OpuModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOpuUsingPOST(req: OpuModifyReq, options?: any): AxiosPromise<GenericResponseListComCowAndIdDto> {
            return EggControllerApiFp(configuration).modifyOpuUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary insert or update a sov record
         * @param {SovModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifySovUsingPOST(req: SovModifyReq, options?: any): AxiosPromise<GenericResponseListComCowAndIdDto> {
            return EggControllerApiFp(configuration).modifySovUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary stock ivf egg as seeds
         * @param {EggStockReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockIvfEggUsingPOST(req: EggStockReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return EggControllerApiFp(configuration).stockIvfEggUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary stock sov as seeds
         * @param {EggStockReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockSovUsingPOST(req: EggStockReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return EggControllerApiFp(configuration).stockSovUsingPOST(req, options)(axios, basePath);
        },
    };
};

/**
 * EggControllerApi - object-oriented interface
 * @export
 * @class EggControllerApi
 * @extends {BaseAPI}
 */
export class EggControllerApi extends BaseAPI {
    /**
     * 
     * @summary delete a ivf egg record
     * @param {ComRanchAndIdReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EggControllerApi
     */
    public deleteIvfEggUsingPOST(req: ComRanchAndIdReq, options?: any) {
        return EggControllerApiFp(this.configuration).deleteIvfEggUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary delete a ivf record
     * @param {ComRanchAndIdReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EggControllerApi
     */
    public deleteIvfUsingPOST(req: ComRanchAndIdReq, options?: any) {
        return EggControllerApiFp(this.configuration).deleteIvfUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary delete a opu record
     * @param {ComRanchAndIdReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EggControllerApi
     */
    public deleteOpuUsingPOST(req: ComRanchAndIdReq, options?: any) {
        return EggControllerApiFp(this.configuration).deleteOpuUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary delete a sov record
     * @param {ComRanchAndIdReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EggControllerApi
     */
    public deleteSovUsingPOST(req: ComRanchAndIdReq, options?: any) {
        return EggControllerApiFp(this.configuration).deleteSovUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get a ivf egg record
     * @param {ComRanchAndIdReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EggControllerApi
     */
    public getIvfEggUsingPOST(req: ComRanchAndIdReq, options?: any) {
        return EggControllerApiFp(this.configuration).getIvfEggUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get a ivf record
     * @param {ComRanchAndIdReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EggControllerApi
     */
    public getIvfUsingPOST(req: ComRanchAndIdReq, options?: any) {
        return EggControllerApiFp(this.configuration).getIvfUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get a opu record
     * @param {ComRanchAndIdReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EggControllerApi
     */
    public getOpuUsingPOST(req: ComRanchAndIdReq, options?: any) {
        return EggControllerApiFp(this.configuration).getOpuUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get a sov record
     * @param {ComRanchAndIdReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EggControllerApi
     */
    public getSovUsingPOST(req: ComRanchAndIdReq, options?: any) {
        return EggControllerApiFp(this.configuration).getSovUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary list ivf records of a certain period
     * @param {IvfListReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EggControllerApi
     */
    public listIvfUsingPOST(req: IvfListReq, options?: any) {
        return EggControllerApiFp(this.configuration).listIvfUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary list opu records of a certain period
     * @param {OpuListReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EggControllerApi
     */
    public listOpuUsingPOST(req: OpuListReq, options?: any) {
        return EggControllerApiFp(this.configuration).listOpuUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary insert or update a ivf egg record
     * @param {IvfEggModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EggControllerApi
     */
    public modifyIvfEggUsingPOST(req: IvfEggModifyReq, options?: any) {
        return EggControllerApiFp(this.configuration).modifyIvfEggUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary insert or update a ivf record
     * @param {IvfModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EggControllerApi
     */
    public modifyIvfUsingPOST(req: IvfModifyReq, options?: any) {
        return EggControllerApiFp(this.configuration).modifyIvfUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary insert or update a opu record
     * @param {OpuModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EggControllerApi
     */
    public modifyOpuUsingPOST(req: OpuModifyReq, options?: any) {
        return EggControllerApiFp(this.configuration).modifyOpuUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary insert or update a sov record
     * @param {SovModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EggControllerApi
     */
    public modifySovUsingPOST(req: SovModifyReq, options?: any) {
        return EggControllerApiFp(this.configuration).modifySovUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary stock ivf egg as seeds
     * @param {EggStockReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EggControllerApi
     */
    public stockIvfEggUsingPOST(req: EggStockReq, options?: any) {
        return EggControllerApiFp(this.configuration).stockIvfEggUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary stock sov as seeds
     * @param {EggStockReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EggControllerApi
     */
    public stockSovUsingPOST(req: EggStockReq, options?: any) {
        return EggControllerApiFp(this.configuration).stockSovUsingPOST(req, options)(this.axios, this.basePath);
    }

}


/**
 * ErrorControllerApi - axios parameter creator
 * @export
 */
export const ErrorControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * authorization error
         * @summary authorization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizationUsingGET(options: any = {}): RequestArgs {
            const localVarPath = `/api/error/authorization`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ErrorControllerApi - functional programming interface
 * @export
 */
export const ErrorControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * authorization error
         * @summary authorization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizationUsingGET(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = ErrorControllerApiAxiosParamCreator(configuration).authorizationUsingGET(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ErrorControllerApi - factory interface
 * @export
 */
export const ErrorControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * authorization error
         * @summary authorization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizationUsingGET(options?: any): AxiosPromise<GenericResponseVoid> {
            return ErrorControllerApiFp(configuration).authorizationUsingGET(options)(axios, basePath);
        },
    };
};

/**
 * ErrorControllerApi - object-oriented interface
 * @export
 * @class ErrorControllerApi
 * @extends {BaseAPI}
 */
export class ErrorControllerApi extends BaseAPI {
    /**
     * authorization error
     * @summary authorization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ErrorControllerApi
     */
    public authorizationUsingGET(options?: any) {
        return ErrorControllerApiFp(this.configuration).authorizationUsingGET(options)(this.axios, this.basePath);
    }

}


/**
 * EventControllerApi - axios parameter creator
 * @export
 */
export const EventControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get event history
         * @summary get event history
         * @param {EventInfoReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoUsingPOST3(req: EventInfoReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getInfoUsingPOST3.');
            }
            const localVarPath = `/api/event/info`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventControllerApi - functional programming interface
 * @export
 */
export const EventControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * get event history
         * @summary get event history
         * @param {EventInfoReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoUsingPOST3(req: EventInfoReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseEventInfoDto> {
            const localVarAxiosArgs = EventControllerApiAxiosParamCreator(configuration).getInfoUsingPOST3(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * EventControllerApi - factory interface
 * @export
 */
export const EventControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * get event history
         * @summary get event history
         * @param {EventInfoReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoUsingPOST3(req: EventInfoReq, options?: any): AxiosPromise<GenericResponseEventInfoDto> {
            return EventControllerApiFp(configuration).getInfoUsingPOST3(req, options)(axios, basePath);
        },
    };
};

/**
 * EventControllerApi - object-oriented interface
 * @export
 * @class EventControllerApi
 * @extends {BaseAPI}
 */
export class EventControllerApi extends BaseAPI {
    /**
     * get event history
     * @summary get event history
     * @param {EventInfoReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public getInfoUsingPOST3(req: EventInfoReq, options?: any) {
        return EventControllerApiFp(this.configuration).getInfoUsingPOST3(req, options)(this.axios, this.basePath);
    }

}


/**
 * FeedControllerApi - axios parameter creator
 * @export
 */
export const FeedControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * add or modify feed amount of some cow for multiple cows
         * @summary add or modify feed amount for multiple cows
         * @param {FeedBulkAddReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkAddFeedUsingPOST(req: FeedBulkAddReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling bulkAddFeedUsingPOST.');
            }
            const localVarPath = `/api/feed/bulk/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the symptom info
         * @summary feed detail
         * @param {number} id id
         * @param {string} userId user_id
         * @param {number} ranchId ranch_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfo1UsingPOST(id: number, userId: string, ranchId: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getInfo1UsingPOST.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getInfo1UsingPOST.');
            }
            // verify required parameter 'ranchId' is not null or undefined
            if (ranchId === null || ranchId === undefined) {
                throw new RequiredError('ranchId','Required parameter ranchId was null or undefined when calling getInfo1UsingPOST.');
            }
            const localVarPath = `/api/feed/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (ranchId !== undefined) {
                localVarQueryParameter['ranch_id'] = ranchId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the feed amount history during 30 days
         * @summary feed amount history
         * @param {FeedAmoutHistReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoUsingPOST4(req: FeedAmoutHistReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getInfoUsingPOST4.');
            }
            const localVarPath = `/api/feed/amount/history`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get lastday feed amount
         * @summary get lastday feed amount
         * @param {FeedLastDayReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastDayUsingPOST(req: FeedLastDayReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getLastDayUsingPOST.');
            }
            const localVarPath = `/api/feed/lastday`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get leftover list over last week
         * @summary get leftover list
         * @param {FeedLeftListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeftListUsingPOST(req: FeedLeftListReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getLeftListUsingPOST.');
            }
            const localVarPath = `/api/feed/amount/left/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get recent feed info of the previous day each cow
         * @summary recent feed each cow
         * @param {FeedRecentEachReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentEachUsingPOST(req: FeedRecentEachReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getRecentEachUsingPOST.');
            }
            const localVarPath = `/api/feed/recent/each`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get recent feed info of the previous day
         * @summary recent feed detail
         * @param {FeedRecentReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentInfoUsingPOST(req: FeedRecentReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getRecentInfoUsingPOST.');
            }
            const localVarPath = `/api/feed/recent`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get feed recent timespan
         * @summary get feed recent timespan
         * @param {FeedRecentTimeSpanReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentTimeSpanUsingPOST(req: FeedRecentTimeSpanReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getRecentTimeSpanUsingPOST.');
            }
            const localVarPath = `/api/feed/recent/timespan`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get feed recorded cow of 1 hour before and after
         * @summary get feed recorded cow
         * @param {FeedRecordedReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordedCowIdUsingPOST(req: FeedRecordedReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getRecordedCowIdUsingPOST.');
            }
            const localVarPath = `/api/feed/recorded`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add feed amount of some cow, some times
         * @summary add some feeds amount
         * @param {FeedModifyMultiReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyFeedMultiUsingPOST(req: FeedModifyMultiReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyFeedMultiUsingPOST.');
            }
            const localVarPath = `/api/feed/modify/multi`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add or modify feed amount of some cow
         * @summary add or modify feed amount
         * @param {FeedModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyFeedUsingPOST(req: FeedModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyFeedUsingPOST.');
            }
            const localVarPath = `/api/feed/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * modify leftover
         * @summary modify leftover
         * @param {FeedLeftModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyLeftUsingPOST(req: FeedLeftModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyLeftUsingPOST.');
            }
            const localVarPath = `/api/feed/amount/left/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeedControllerApi - functional programming interface
 * @export
 */
export const FeedControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * add or modify feed amount of some cow for multiple cows
         * @summary add or modify feed amount for multiple cows
         * @param {FeedBulkAddReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkAddFeedUsingPOST(req: FeedBulkAddReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = FeedControllerApiAxiosParamCreator(configuration).bulkAddFeedUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get the symptom info
         * @summary feed detail
         * @param {number} id id
         * @param {string} userId user_id
         * @param {number} ranchId ranch_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfo1UsingPOST(id: number, userId: string, ranchId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseFeedDto> {
            const localVarAxiosArgs = FeedControllerApiAxiosParamCreator(configuration).getInfo1UsingPOST(id, userId, ranchId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get the feed amount history during 30 days
         * @summary feed amount history
         * @param {FeedAmoutHistReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoUsingPOST4(req: FeedAmoutHistReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseFeedAmountHistDto> {
            const localVarAxiosArgs = FeedControllerApiAxiosParamCreator(configuration).getInfoUsingPOST4(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get lastday feed amount
         * @summary get lastday feed amount
         * @param {FeedLastDayReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastDayUsingPOST(req: FeedLastDayReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListFeedLastDayDto> {
            const localVarAxiosArgs = FeedControllerApiAxiosParamCreator(configuration).getLastDayUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get leftover list over last week
         * @summary get leftover list
         * @param {FeedLeftListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeftListUsingPOST(req: FeedLeftListReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseFeedLeftListDto> {
            const localVarAxiosArgs = FeedControllerApiAxiosParamCreator(configuration).getLeftListUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get recent feed info of the previous day each cow
         * @summary recent feed each cow
         * @param {FeedRecentEachReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentEachUsingPOST(req: FeedRecentEachReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListFeedRecentEachDto> {
            const localVarAxiosArgs = FeedControllerApiAxiosParamCreator(configuration).getRecentEachUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get recent feed info of the previous day
         * @summary recent feed detail
         * @param {FeedRecentReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentInfoUsingPOST(req: FeedRecentReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseFeedDto> {
            const localVarAxiosArgs = FeedControllerApiAxiosParamCreator(configuration).getRecentInfoUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get feed recent timespan
         * @summary get feed recent timespan
         * @param {FeedRecentTimeSpanReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentTimeSpanUsingPOST(req: FeedRecentTimeSpanReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListFeedRecentTimeSpanDto> {
            const localVarAxiosArgs = FeedControllerApiAxiosParamCreator(configuration).getRecentTimeSpanUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get feed recorded cow of 1 hour before and after
         * @summary get feed recorded cow
         * @param {FeedRecordedReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordedCowIdUsingPOST(req: FeedRecordedReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseFeedRecordedDto> {
            const localVarAxiosArgs = FeedControllerApiAxiosParamCreator(configuration).getRecordedCowIdUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * add feed amount of some cow, some times
         * @summary add some feeds amount
         * @param {FeedModifyMultiReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyFeedMultiUsingPOST(req: FeedModifyMultiReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = FeedControllerApiAxiosParamCreator(configuration).modifyFeedMultiUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * add or modify feed amount of some cow
         * @summary add or modify feed amount
         * @param {FeedModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyFeedUsingPOST(req: FeedModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = FeedControllerApiAxiosParamCreator(configuration).modifyFeedUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * modify leftover
         * @summary modify leftover
         * @param {FeedLeftModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyLeftUsingPOST(req: FeedLeftModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = FeedControllerApiAxiosParamCreator(configuration).modifyLeftUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * FeedControllerApi - factory interface
 * @export
 */
export const FeedControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * add or modify feed amount of some cow for multiple cows
         * @summary add or modify feed amount for multiple cows
         * @param {FeedBulkAddReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkAddFeedUsingPOST(req: FeedBulkAddReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return FeedControllerApiFp(configuration).bulkAddFeedUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get the symptom info
         * @summary feed detail
         * @param {number} id id
         * @param {string} userId user_id
         * @param {number} ranchId ranch_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfo1UsingPOST(id: number, userId: string, ranchId: number, options?: any): AxiosPromise<GenericResponseFeedDto> {
            return FeedControllerApiFp(configuration).getInfo1UsingPOST(id, userId, ranchId, options)(axios, basePath);
        },
        /**
         * get the feed amount history during 30 days
         * @summary feed amount history
         * @param {FeedAmoutHistReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoUsingPOST4(req: FeedAmoutHistReq, options?: any): AxiosPromise<GenericResponseFeedAmountHistDto> {
            return FeedControllerApiFp(configuration).getInfoUsingPOST4(req, options)(axios, basePath);
        },
        /**
         * get lastday feed amount
         * @summary get lastday feed amount
         * @param {FeedLastDayReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastDayUsingPOST(req: FeedLastDayReq, options?: any): AxiosPromise<GenericResponseListFeedLastDayDto> {
            return FeedControllerApiFp(configuration).getLastDayUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get leftover list over last week
         * @summary get leftover list
         * @param {FeedLeftListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeftListUsingPOST(req: FeedLeftListReq, options?: any): AxiosPromise<GenericResponseFeedLeftListDto> {
            return FeedControllerApiFp(configuration).getLeftListUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get recent feed info of the previous day each cow
         * @summary recent feed each cow
         * @param {FeedRecentEachReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentEachUsingPOST(req: FeedRecentEachReq, options?: any): AxiosPromise<GenericResponseListFeedRecentEachDto> {
            return FeedControllerApiFp(configuration).getRecentEachUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get recent feed info of the previous day
         * @summary recent feed detail
         * @param {FeedRecentReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentInfoUsingPOST(req: FeedRecentReq, options?: any): AxiosPromise<GenericResponseFeedDto> {
            return FeedControllerApiFp(configuration).getRecentInfoUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get feed recent timespan
         * @summary get feed recent timespan
         * @param {FeedRecentTimeSpanReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentTimeSpanUsingPOST(req: FeedRecentTimeSpanReq, options?: any): AxiosPromise<GenericResponseListFeedRecentTimeSpanDto> {
            return FeedControllerApiFp(configuration).getRecentTimeSpanUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get feed recorded cow of 1 hour before and after
         * @summary get feed recorded cow
         * @param {FeedRecordedReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordedCowIdUsingPOST(req: FeedRecordedReq, options?: any): AxiosPromise<GenericResponseFeedRecordedDto> {
            return FeedControllerApiFp(configuration).getRecordedCowIdUsingPOST(req, options)(axios, basePath);
        },
        /**
         * add feed amount of some cow, some times
         * @summary add some feeds amount
         * @param {FeedModifyMultiReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyFeedMultiUsingPOST(req: FeedModifyMultiReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return FeedControllerApiFp(configuration).modifyFeedMultiUsingPOST(req, options)(axios, basePath);
        },
        /**
         * add or modify feed amount of some cow
         * @summary add or modify feed amount
         * @param {FeedModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyFeedUsingPOST(req: FeedModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return FeedControllerApiFp(configuration).modifyFeedUsingPOST(req, options)(axios, basePath);
        },
        /**
         * modify leftover
         * @summary modify leftover
         * @param {FeedLeftModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyLeftUsingPOST(req: FeedLeftModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return FeedControllerApiFp(configuration).modifyLeftUsingPOST(req, options)(axios, basePath);
        },
    };
};

/**
 * FeedControllerApi - object-oriented interface
 * @export
 * @class FeedControllerApi
 * @extends {BaseAPI}
 */
export class FeedControllerApi extends BaseAPI {
    /**
     * add or modify feed amount of some cow for multiple cows
     * @summary add or modify feed amount for multiple cows
     * @param {FeedBulkAddReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedControllerApi
     */
    public bulkAddFeedUsingPOST(req: FeedBulkAddReq, options?: any) {
        return FeedControllerApiFp(this.configuration).bulkAddFeedUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get the symptom info
     * @summary feed detail
     * @param {number} id id
     * @param {string} userId user_id
     * @param {number} ranchId ranch_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedControllerApi
     */
    public getInfo1UsingPOST(id: number, userId: string, ranchId: number, options?: any) {
        return FeedControllerApiFp(this.configuration).getInfo1UsingPOST(id, userId, ranchId, options)(this.axios, this.basePath);
    }

    /**
     * get the feed amount history during 30 days
     * @summary feed amount history
     * @param {FeedAmoutHistReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedControllerApi
     */
    public getInfoUsingPOST4(req: FeedAmoutHistReq, options?: any) {
        return FeedControllerApiFp(this.configuration).getInfoUsingPOST4(req, options)(this.axios, this.basePath);
    }

    /**
     * get lastday feed amount
     * @summary get lastday feed amount
     * @param {FeedLastDayReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedControllerApi
     */
    public getLastDayUsingPOST(req: FeedLastDayReq, options?: any) {
        return FeedControllerApiFp(this.configuration).getLastDayUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get leftover list over last week
     * @summary get leftover list
     * @param {FeedLeftListReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedControllerApi
     */
    public getLeftListUsingPOST(req: FeedLeftListReq, options?: any) {
        return FeedControllerApiFp(this.configuration).getLeftListUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get recent feed info of the previous day each cow
     * @summary recent feed each cow
     * @param {FeedRecentEachReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedControllerApi
     */
    public getRecentEachUsingPOST(req: FeedRecentEachReq, options?: any) {
        return FeedControllerApiFp(this.configuration).getRecentEachUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get recent feed info of the previous day
     * @summary recent feed detail
     * @param {FeedRecentReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedControllerApi
     */
    public getRecentInfoUsingPOST(req: FeedRecentReq, options?: any) {
        return FeedControllerApiFp(this.configuration).getRecentInfoUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get feed recent timespan
     * @summary get feed recent timespan
     * @param {FeedRecentTimeSpanReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedControllerApi
     */
    public getRecentTimeSpanUsingPOST(req: FeedRecentTimeSpanReq, options?: any) {
        return FeedControllerApiFp(this.configuration).getRecentTimeSpanUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get feed recorded cow of 1 hour before and after
     * @summary get feed recorded cow
     * @param {FeedRecordedReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedControllerApi
     */
    public getRecordedCowIdUsingPOST(req: FeedRecordedReq, options?: any) {
        return FeedControllerApiFp(this.configuration).getRecordedCowIdUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * add feed amount of some cow, some times
     * @summary add some feeds amount
     * @param {FeedModifyMultiReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedControllerApi
     */
    public modifyFeedMultiUsingPOST(req: FeedModifyMultiReq, options?: any) {
        return FeedControllerApiFp(this.configuration).modifyFeedMultiUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * add or modify feed amount of some cow
     * @summary add or modify feed amount
     * @param {FeedModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedControllerApi
     */
    public modifyFeedUsingPOST(req: FeedModifyReq, options?: any) {
        return FeedControllerApiFp(this.configuration).modifyFeedUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * modify leftover
     * @summary modify leftover
     * @param {FeedLeftModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedControllerApi
     */
    public modifyLeftUsingPOST(req: FeedLeftModifyReq, options?: any) {
        return FeedControllerApiFp(this.configuration).modifyLeftUsingPOST(req, options)(this.axios, this.basePath);
    }

}


/**
 * GrowthControllerApi - axios parameter creator
 * @export
 */
export const GrowthControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * delete the growth
         * @summary delete growth
         * @param {GrowthDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGrowthUsingPOST(req: GrowthDeleteReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deleteGrowthUsingPOST.');
            }
            const localVarPath = `/api/growth/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get weights at birthday
         * @param {ComCowIdsReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBirthWeightUsingPOST(req: ComCowIdsReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getBirthWeightUsingPOST.');
            }
            const localVarPath = `/api/growth/birth/weight`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get growth scores for graph
         * @summary growth scores for graph
         * @param {GrowthGraphReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGrowthGraphUsingPOST(req: GrowthGraphReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getGrowthGraphUsingPOST.');
            }
            const localVarPath = `/api/growth/graph`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get growth data by event id
         * @summary growth data
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGrowthUsingPOST(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getGrowthUsingPOST.');
            }
            const localVarPath = `/api/growth/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get standard scores of growth
         * @summary standard scores of growth
         * @param {GrowthStandardListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStandardUsingPOST(req: GrowthStandardListReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getStandardUsingPOST.');
            }
            const localVarPath = `/api/growth/standard`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add new cow or modify growth
         * @summary add or modify growth
         * @param {GrowthModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyGrowthUsingPOST(req: GrowthModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyGrowthUsingPOST.');
            }
            const localVarPath = `/api/growth/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GrowthControllerApi - functional programming interface
 * @export
 */
export const GrowthControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * delete the growth
         * @summary delete growth
         * @param {GrowthDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGrowthUsingPOST(req: GrowthDeleteReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = GrowthControllerApiAxiosParamCreator(configuration).deleteGrowthUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get weights at birthday
         * @param {ComCowIdsReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBirthWeightUsingPOST(req: ComCowIdsReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListGrowthBirthWeightDto> {
            const localVarAxiosArgs = GrowthControllerApiAxiosParamCreator(configuration).getBirthWeightUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get growth scores for graph
         * @summary growth scores for graph
         * @param {GrowthGraphReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGrowthGraphUsingPOST(req: GrowthGraphReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseGrowthGraphListDto> {
            const localVarAxiosArgs = GrowthControllerApiAxiosParamCreator(configuration).getGrowthGraphUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get growth data by event id
         * @summary growth data
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGrowthUsingPOST(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseGrowthDto> {
            const localVarAxiosArgs = GrowthControllerApiAxiosParamCreator(configuration).getGrowthUsingPOST(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get standard scores of growth
         * @summary standard scores of growth
         * @param {GrowthStandardListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStandardUsingPOST(req: GrowthStandardListReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseGrowthStandardListDto> {
            const localVarAxiosArgs = GrowthControllerApiAxiosParamCreator(configuration).getStandardUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * add new cow or modify growth
         * @summary add or modify growth
         * @param {GrowthModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyGrowthUsingPOST(req: GrowthModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = GrowthControllerApiAxiosParamCreator(configuration).modifyGrowthUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * GrowthControllerApi - factory interface
 * @export
 */
export const GrowthControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * delete the growth
         * @summary delete growth
         * @param {GrowthDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGrowthUsingPOST(req: GrowthDeleteReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return GrowthControllerApiFp(configuration).deleteGrowthUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary get weights at birthday
         * @param {ComCowIdsReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBirthWeightUsingPOST(req: ComCowIdsReq, options?: any): AxiosPromise<GenericResponseListGrowthBirthWeightDto> {
            return GrowthControllerApiFp(configuration).getBirthWeightUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get growth scores for graph
         * @summary growth scores for graph
         * @param {GrowthGraphReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGrowthGraphUsingPOST(req: GrowthGraphReq, options?: any): AxiosPromise<GenericResponseGrowthGraphListDto> {
            return GrowthControllerApiFp(configuration).getGrowthGraphUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get growth data by event id
         * @summary growth data
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGrowthUsingPOST(id: number, options?: any): AxiosPromise<GenericResponseGrowthDto> {
            return GrowthControllerApiFp(configuration).getGrowthUsingPOST(id, options)(axios, basePath);
        },
        /**
         * get standard scores of growth
         * @summary standard scores of growth
         * @param {GrowthStandardListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStandardUsingPOST(req: GrowthStandardListReq, options?: any): AxiosPromise<GenericResponseGrowthStandardListDto> {
            return GrowthControllerApiFp(configuration).getStandardUsingPOST(req, options)(axios, basePath);
        },
        /**
         * add new cow or modify growth
         * @summary add or modify growth
         * @param {GrowthModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyGrowthUsingPOST(req: GrowthModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return GrowthControllerApiFp(configuration).modifyGrowthUsingPOST(req, options)(axios, basePath);
        },
    };
};

/**
 * GrowthControllerApi - object-oriented interface
 * @export
 * @class GrowthControllerApi
 * @extends {BaseAPI}
 */
export class GrowthControllerApi extends BaseAPI {
    /**
     * delete the growth
     * @summary delete growth
     * @param {GrowthDeleteReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GrowthControllerApi
     */
    public deleteGrowthUsingPOST(req: GrowthDeleteReq, options?: any) {
        return GrowthControllerApiFp(this.configuration).deleteGrowthUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get weights at birthday
     * @param {ComCowIdsReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GrowthControllerApi
     */
    public getBirthWeightUsingPOST(req: ComCowIdsReq, options?: any) {
        return GrowthControllerApiFp(this.configuration).getBirthWeightUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get growth scores for graph
     * @summary growth scores for graph
     * @param {GrowthGraphReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GrowthControllerApi
     */
    public getGrowthGraphUsingPOST(req: GrowthGraphReq, options?: any) {
        return GrowthControllerApiFp(this.configuration).getGrowthGraphUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get growth data by event id
     * @summary growth data
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GrowthControllerApi
     */
    public getGrowthUsingPOST(id: number, options?: any) {
        return GrowthControllerApiFp(this.configuration).getGrowthUsingPOST(id, options)(this.axios, this.basePath);
    }

    /**
     * get standard scores of growth
     * @summary standard scores of growth
     * @param {GrowthStandardListReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GrowthControllerApi
     */
    public getStandardUsingPOST(req: GrowthStandardListReq, options?: any) {
        return GrowthControllerApiFp(this.configuration).getStandardUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * add new cow or modify growth
     * @summary add or modify growth
     * @param {GrowthModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GrowthControllerApi
     */
    public modifyGrowthUsingPOST(req: GrowthModifyReq, options?: any) {
        return GrowthControllerApiFp(this.configuration).modifyGrowthUsingPOST(req, options)(this.axios, this.basePath);
    }

}


/**
 * HealthControllerApi - axios parameter creator
 * @export
 */
export const HealthControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * test database access
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthUsingGET(options: any = {}): RequestArgs {
            const localVarPath = `/api/health`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * test database access
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthUsingPOST(options: any = {}): RequestArgs {
            const localVarPath = `/api/health`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthControllerApi - functional programming interface
 * @export
 */
export const HealthControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * test database access
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthUsingGET(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = HealthControllerApiAxiosParamCreator(configuration).healthUsingGET(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * test database access
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthUsingPOST(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = HealthControllerApiAxiosParamCreator(configuration).healthUsingPOST(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * HealthControllerApi - factory interface
 * @export
 */
export const HealthControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * test database access
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthUsingGET(options?: any): AxiosPromise<void> {
            return HealthControllerApiFp(configuration).healthUsingGET(options)(axios, basePath);
        },
        /**
         * test database access
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthUsingPOST(options?: any): AxiosPromise<void> {
            return HealthControllerApiFp(configuration).healthUsingPOST(options)(axios, basePath);
        },
    };
};

/**
 * HealthControllerApi - object-oriented interface
 * @export
 * @class HealthControllerApi
 * @extends {BaseAPI}
 */
export class HealthControllerApi extends BaseAPI {
    /**
     * test database access
     * @summary health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthControllerApi
     */
    public healthUsingGET(options?: any) {
        return HealthControllerApiFp(this.configuration).healthUsingGET(options)(this.axios, this.basePath);
    }

    /**
     * test database access
     * @summary health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthControllerApi
     */
    public healthUsingPOST(options?: any) {
        return HealthControllerApiFp(this.configuration).healthUsingPOST(options)(this.axios, this.basePath);
    }

}


/**
 * NoteControllerApi - axios parameter creator
 * @export
 */
export const NoteControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * delete the note
         * @summary delete note
         * @param {NoteDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST2(req: NoteDeleteReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deleteUsingPOST2.');
            }
            const localVarPath = `/api/note/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the note list
         * @summary note list
         * @param {NoteListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListUsingPOST1(req: NoteListReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getListUsingPOST1.');
            }
            const localVarPath = `/api/note/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add new note or modify note
         * @summary add or modify note
         * @param {NoteModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyUsingPOST2(req: NoteModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyUsingPOST2.');
            }
            const localVarPath = `/api/note/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NoteControllerApi - functional programming interface
 * @export
 */
export const NoteControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * delete the note
         * @summary delete note
         * @param {NoteDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST2(req: NoteDeleteReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = NoteControllerApiAxiosParamCreator(configuration).deleteUsingPOST2(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get the note list
         * @summary note list
         * @param {NoteListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListUsingPOST1(req: NoteListReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListNoteDto> {
            const localVarAxiosArgs = NoteControllerApiAxiosParamCreator(configuration).getListUsingPOST1(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * add new note or modify note
         * @summary add or modify note
         * @param {NoteModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyUsingPOST2(req: NoteModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = NoteControllerApiAxiosParamCreator(configuration).modifyUsingPOST2(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * NoteControllerApi - factory interface
 * @export
 */
export const NoteControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * delete the note
         * @summary delete note
         * @param {NoteDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST2(req: NoteDeleteReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return NoteControllerApiFp(configuration).deleteUsingPOST2(req, options)(axios, basePath);
        },
        /**
         * get the note list
         * @summary note list
         * @param {NoteListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListUsingPOST1(req: NoteListReq, options?: any): AxiosPromise<GenericResponseListNoteDto> {
            return NoteControllerApiFp(configuration).getListUsingPOST1(req, options)(axios, basePath);
        },
        /**
         * add new note or modify note
         * @summary add or modify note
         * @param {NoteModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyUsingPOST2(req: NoteModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return NoteControllerApiFp(configuration).modifyUsingPOST2(req, options)(axios, basePath);
        },
    };
};

/**
 * NoteControllerApi - object-oriented interface
 * @export
 * @class NoteControllerApi
 * @extends {BaseAPI}
 */
export class NoteControllerApi extends BaseAPI {
    /**
     * delete the note
     * @summary delete note
     * @param {NoteDeleteReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteControllerApi
     */
    public deleteUsingPOST2(req: NoteDeleteReq, options?: any) {
        return NoteControllerApiFp(this.configuration).deleteUsingPOST2(req, options)(this.axios, this.basePath);
    }

    /**
     * get the note list
     * @summary note list
     * @param {NoteListReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteControllerApi
     */
    public getListUsingPOST1(req: NoteListReq, options?: any) {
        return NoteControllerApiFp(this.configuration).getListUsingPOST1(req, options)(this.axios, this.basePath);
    }

    /**
     * add new note or modify note
     * @summary add or modify note
     * @param {NoteModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteControllerApi
     */
    public modifyUsingPOST2(req: NoteModifyReq, options?: any) {
        return NoteControllerApiFp(this.configuration).modifyUsingPOST2(req, options)(this.axios, this.basePath);
    }

}


/**
 * OtherPlControllerApi - axios parameter creator
 * @export
 */
export const OtherPlControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary delete other profit or loss
         * @param {OtherPlDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOtherPlUsingPOST(req: OtherPlDeleteReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deleteOtherPlUsingPOST.');
            }
            const localVarPath = `/api/balance/other/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get detail other profit loss event data
         * @param {SingleEventReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventUsingPOST(req: SingleEventReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getEventUsingPOST.');
            }
            const localVarPath = `/api/balance/other/event`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get other profit loss info
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOtherPlInfoUsingPOST(req: ComRanchAndIdReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getOtherPlInfoUsingPOST.');
            }
            const localVarPath = `/api/balance/other/info`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary modify other profit or loss
         * @param {OtherPlModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOtherPlUsingPOST(req: OtherPlModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyOtherPlUsingPOST.');
            }
            const localVarPath = `/api/balance/other/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OtherPlControllerApi - functional programming interface
 * @export
 */
export const OtherPlControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary delete other profit or loss
         * @param {OtherPlDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOtherPlUsingPOST(req: OtherPlDeleteReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = OtherPlControllerApiAxiosParamCreator(configuration).deleteOtherPlUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get detail other profit loss event data
         * @param {SingleEventReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventUsingPOST(req: SingleEventReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseOtherPlEventDto> {
            const localVarAxiosArgs = OtherPlControllerApiAxiosParamCreator(configuration).getEventUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get other profit loss info
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOtherPlInfoUsingPOST(req: ComRanchAndIdReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseOtherPlDto> {
            const localVarAxiosArgs = OtherPlControllerApiAxiosParamCreator(configuration).getOtherPlInfoUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary modify other profit or loss
         * @param {OtherPlModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOtherPlUsingPOST(req: OtherPlModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = OtherPlControllerApiAxiosParamCreator(configuration).modifyOtherPlUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OtherPlControllerApi - factory interface
 * @export
 */
export const OtherPlControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary delete other profit or loss
         * @param {OtherPlDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOtherPlUsingPOST(req: OtherPlDeleteReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return OtherPlControllerApiFp(configuration).deleteOtherPlUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary get detail other profit loss event data
         * @param {SingleEventReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventUsingPOST(req: SingleEventReq, options?: any): AxiosPromise<GenericResponseOtherPlEventDto> {
            return OtherPlControllerApiFp(configuration).getEventUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary get other profit loss info
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOtherPlInfoUsingPOST(req: ComRanchAndIdReq, options?: any): AxiosPromise<GenericResponseOtherPlDto> {
            return OtherPlControllerApiFp(configuration).getOtherPlInfoUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary modify other profit or loss
         * @param {OtherPlModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOtherPlUsingPOST(req: OtherPlModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return OtherPlControllerApiFp(configuration).modifyOtherPlUsingPOST(req, options)(axios, basePath);
        },
    };
};

/**
 * OtherPlControllerApi - object-oriented interface
 * @export
 * @class OtherPlControllerApi
 * @extends {BaseAPI}
 */
export class OtherPlControllerApi extends BaseAPI {
    /**
     * 
     * @summary delete other profit or loss
     * @param {OtherPlDeleteReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OtherPlControllerApi
     */
    public deleteOtherPlUsingPOST(req: OtherPlDeleteReq, options?: any) {
        return OtherPlControllerApiFp(this.configuration).deleteOtherPlUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get detail other profit loss event data
     * @param {SingleEventReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OtherPlControllerApi
     */
    public getEventUsingPOST(req: SingleEventReq, options?: any) {
        return OtherPlControllerApiFp(this.configuration).getEventUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get other profit loss info
     * @param {ComRanchAndIdReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OtherPlControllerApi
     */
    public getOtherPlInfoUsingPOST(req: ComRanchAndIdReq, options?: any) {
        return OtherPlControllerApiFp(this.configuration).getOtherPlInfoUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary modify other profit or loss
     * @param {OtherPlModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OtherPlControllerApi
     */
    public modifyOtherPlUsingPOST(req: OtherPlModifyReq, options?: any) {
        return OtherPlControllerApiFp(this.configuration).modifyOtherPlUsingPOST(req, options)(this.axios, this.basePath);
    }

}


/**
 * PreventionControllerApi - axios parameter creator
 * @export
 */
export const PreventionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get the prevention info
         * @summary prevention detail
         * @param {number} id id
         * @param {string} userId user_id
         * @param {number} ranchId ranch_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfo1UsingPOST1(id: number, userId: string, ranchId: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getInfo1UsingPOST1.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getInfo1UsingPOST1.');
            }
            // verify required parameter 'ranchId' is not null or undefined
            if (ranchId === null || ranchId === undefined) {
                throw new RequiredError('ranchId','Required parameter ranchId was null or undefined when calling getInfo1UsingPOST1.');
            }
            const localVarPath = `/api/prevention/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (ranchId !== undefined) {
                localVarQueryParameter['ranch_id'] = ranchId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get program items witch will be generated by the prevention event.
         * @param {ProgramAppliedItemsReqPreventionModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramsForPreventionUsingPOST(req: ProgramAppliedItemsReqPreventionModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getProgramsForPreventionUsingPOST.');
            }
            const localVarPath = `/api/prevention/program`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add or modify prevention of some cow
         * @summary add or modify prevention
         * @param {PreventionModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyPreventionUsingPOST(req: PreventionModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyPreventionUsingPOST.');
            }
            const localVarPath = `/api/prevention/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get vaccination certificate
         * @summary get vaccination certificate
         * @param {PreventionCertVaccinationReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPreventionCertVaccinationUsingPOST(req: PreventionCertVaccinationReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling postPreventionCertVaccinationUsingPOST.');
            }
            const localVarPath = `/api/prevention/cert/vaccination`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get prevention treat list
         * @summary get prevention treat list
         * @param {PreventionTreatListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPreventionTreatListUsingPOST(req: PreventionTreatListReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling postPreventionTreatListUsingPOST.');
            }
            const localVarPath = `/api/prevention/treat/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PreventionControllerApi - functional programming interface
 * @export
 */
export const PreventionControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * get the prevention info
         * @summary prevention detail
         * @param {number} id id
         * @param {string} userId user_id
         * @param {number} ranchId ranch_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfo1UsingPOST1(id: number, userId: string, ranchId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponsePreventionDto> {
            const localVarAxiosArgs = PreventionControllerApiAxiosParamCreator(configuration).getInfo1UsingPOST1(id, userId, ranchId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get program items witch will be generated by the prevention event.
         * @param {ProgramAppliedItemsReqPreventionModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramsForPreventionUsingPOST(req: ProgramAppliedItemsReqPreventionModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseProgramApplyResultDto> {
            const localVarAxiosArgs = PreventionControllerApiAxiosParamCreator(configuration).getProgramsForPreventionUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * add or modify prevention of some cow
         * @summary add or modify prevention
         * @param {PreventionModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyPreventionUsingPOST(req: PreventionModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = PreventionControllerApiAxiosParamCreator(configuration).modifyPreventionUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get vaccination certificate
         * @summary get vaccination certificate
         * @param {PreventionCertVaccinationReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPreventionCertVaccinationUsingPOST(req: PreventionCertVaccinationReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseFileDownloadDto> {
            const localVarAxiosArgs = PreventionControllerApiAxiosParamCreator(configuration).postPreventionCertVaccinationUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get prevention treat list
         * @summary get prevention treat list
         * @param {PreventionTreatListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPreventionTreatListUsingPOST(req: PreventionTreatListReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListPreventionTreatListDto> {
            const localVarAxiosArgs = PreventionControllerApiAxiosParamCreator(configuration).postPreventionTreatListUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PreventionControllerApi - factory interface
 * @export
 */
export const PreventionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * get the prevention info
         * @summary prevention detail
         * @param {number} id id
         * @param {string} userId user_id
         * @param {number} ranchId ranch_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfo1UsingPOST1(id: number, userId: string, ranchId: number, options?: any): AxiosPromise<GenericResponsePreventionDto> {
            return PreventionControllerApiFp(configuration).getInfo1UsingPOST1(id, userId, ranchId, options)(axios, basePath);
        },
        /**
         * 
         * @summary get program items witch will be generated by the prevention event.
         * @param {ProgramAppliedItemsReqPreventionModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramsForPreventionUsingPOST(req: ProgramAppliedItemsReqPreventionModifyReq, options?: any): AxiosPromise<GenericResponseProgramApplyResultDto> {
            return PreventionControllerApiFp(configuration).getProgramsForPreventionUsingPOST(req, options)(axios, basePath);
        },
        /**
         * add or modify prevention of some cow
         * @summary add or modify prevention
         * @param {PreventionModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyPreventionUsingPOST(req: PreventionModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return PreventionControllerApiFp(configuration).modifyPreventionUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get vaccination certificate
         * @summary get vaccination certificate
         * @param {PreventionCertVaccinationReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPreventionCertVaccinationUsingPOST(req: PreventionCertVaccinationReq, options?: any): AxiosPromise<GenericResponseFileDownloadDto> {
            return PreventionControllerApiFp(configuration).postPreventionCertVaccinationUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get prevention treat list
         * @summary get prevention treat list
         * @param {PreventionTreatListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPreventionTreatListUsingPOST(req: PreventionTreatListReq, options?: any): AxiosPromise<GenericResponseListPreventionTreatListDto> {
            return PreventionControllerApiFp(configuration).postPreventionTreatListUsingPOST(req, options)(axios, basePath);
        },
    };
};

/**
 * PreventionControllerApi - object-oriented interface
 * @export
 * @class PreventionControllerApi
 * @extends {BaseAPI}
 */
export class PreventionControllerApi extends BaseAPI {
    /**
     * get the prevention info
     * @summary prevention detail
     * @param {number} id id
     * @param {string} userId user_id
     * @param {number} ranchId ranch_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreventionControllerApi
     */
    public getInfo1UsingPOST1(id: number, userId: string, ranchId: number, options?: any) {
        return PreventionControllerApiFp(this.configuration).getInfo1UsingPOST1(id, userId, ranchId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get program items witch will be generated by the prevention event.
     * @param {ProgramAppliedItemsReqPreventionModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreventionControllerApi
     */
    public getProgramsForPreventionUsingPOST(req: ProgramAppliedItemsReqPreventionModifyReq, options?: any) {
        return PreventionControllerApiFp(this.configuration).getProgramsForPreventionUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * add or modify prevention of some cow
     * @summary add or modify prevention
     * @param {PreventionModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreventionControllerApi
     */
    public modifyPreventionUsingPOST(req: PreventionModifyReq, options?: any) {
        return PreventionControllerApiFp(this.configuration).modifyPreventionUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get vaccination certificate
     * @summary get vaccination certificate
     * @param {PreventionCertVaccinationReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreventionControllerApi
     */
    public postPreventionCertVaccinationUsingPOST(req: PreventionCertVaccinationReq, options?: any) {
        return PreventionControllerApiFp(this.configuration).postPreventionCertVaccinationUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get prevention treat list
     * @summary get prevention treat list
     * @param {PreventionTreatListReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreventionControllerApi
     */
    public postPreventionTreatListUsingPOST(req: PreventionTreatListReq, options?: any) {
        return PreventionControllerApiFp(this.configuration).postPreventionTreatListUsingPOST(req, options)(this.axios, this.basePath);
    }

}


/**
 * ProgramControllerApi - axios parameter creator
 * @export
 */
export const ProgramControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * apply program to a cow
         * @summary schedule items which will be generated
         * @param {ProgramApplyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyUsingPOST(req: ProgramApplyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling applyUsingPOST.');
            }
            const localVarPath = `/api/program/cow/apply`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * remove applied programs
         * @summary program data
         * @param {ProgramCowDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAppliedUsingPOST(req: ProgramCowDeleteReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deleteAppliedUsingPOST.');
            }
            const localVarPath = `/api/program/cow/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete program
         * @summary program data
         * @param {ProgramDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST3(req: ProgramDeleteReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deleteUsingPOST3.');
            }
            const localVarPath = `/api/program/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get program list of ranch
         * @summary program list of ranch
         * @param {ProgramListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramListUsingPOST(req: ProgramListReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getProgramListUsingPOST.');
            }
            const localVarPath = `/api/program/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * insert or update program
         * @summary program data
         * @param {ProgramModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyUsingPOST3(req: ProgramModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyUsingPOST3.');
            }
            const localVarPath = `/api/program/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProgramControllerApi - functional programming interface
 * @export
 */
export const ProgramControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * apply program to a cow
         * @summary schedule items which will be generated
         * @param {ProgramApplyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyUsingPOST(req: ProgramApplyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseProgramApplyResultDto> {
            const localVarAxiosArgs = ProgramControllerApiAxiosParamCreator(configuration).applyUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * remove applied programs
         * @summary program data
         * @param {ProgramCowDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAppliedUsingPOST(req: ProgramCowDeleteReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = ProgramControllerApiAxiosParamCreator(configuration).deleteAppliedUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * delete program
         * @summary program data
         * @param {ProgramDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST3(req: ProgramDeleteReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = ProgramControllerApiAxiosParamCreator(configuration).deleteUsingPOST3(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get program list of ranch
         * @summary program list of ranch
         * @param {ProgramListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramListUsingPOST(req: ProgramListReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListProgramDto> {
            const localVarAxiosArgs = ProgramControllerApiAxiosParamCreator(configuration).getProgramListUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * insert or update program
         * @summary program data
         * @param {ProgramModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyUsingPOST3(req: ProgramModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = ProgramControllerApiAxiosParamCreator(configuration).modifyUsingPOST3(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProgramControllerApi - factory interface
 * @export
 */
export const ProgramControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * apply program to a cow
         * @summary schedule items which will be generated
         * @param {ProgramApplyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyUsingPOST(req: ProgramApplyReq, options?: any): AxiosPromise<GenericResponseProgramApplyResultDto> {
            return ProgramControllerApiFp(configuration).applyUsingPOST(req, options)(axios, basePath);
        },
        /**
         * remove applied programs
         * @summary program data
         * @param {ProgramCowDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAppliedUsingPOST(req: ProgramCowDeleteReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return ProgramControllerApiFp(configuration).deleteAppliedUsingPOST(req, options)(axios, basePath);
        },
        /**
         * delete program
         * @summary program data
         * @param {ProgramDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST3(req: ProgramDeleteReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return ProgramControllerApiFp(configuration).deleteUsingPOST3(req, options)(axios, basePath);
        },
        /**
         * get program list of ranch
         * @summary program list of ranch
         * @param {ProgramListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramListUsingPOST(req: ProgramListReq, options?: any): AxiosPromise<GenericResponseListProgramDto> {
            return ProgramControllerApiFp(configuration).getProgramListUsingPOST(req, options)(axios, basePath);
        },
        /**
         * insert or update program
         * @summary program data
         * @param {ProgramModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyUsingPOST3(req: ProgramModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return ProgramControllerApiFp(configuration).modifyUsingPOST3(req, options)(axios, basePath);
        },
    };
};

/**
 * ProgramControllerApi - object-oriented interface
 * @export
 * @class ProgramControllerApi
 * @extends {BaseAPI}
 */
export class ProgramControllerApi extends BaseAPI {
    /**
     * apply program to a cow
     * @summary schedule items which will be generated
     * @param {ProgramApplyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramControllerApi
     */
    public applyUsingPOST(req: ProgramApplyReq, options?: any) {
        return ProgramControllerApiFp(this.configuration).applyUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * remove applied programs
     * @summary program data
     * @param {ProgramCowDeleteReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramControllerApi
     */
    public deleteAppliedUsingPOST(req: ProgramCowDeleteReq, options?: any) {
        return ProgramControllerApiFp(this.configuration).deleteAppliedUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * delete program
     * @summary program data
     * @param {ProgramDeleteReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramControllerApi
     */
    public deleteUsingPOST3(req: ProgramDeleteReq, options?: any) {
        return ProgramControllerApiFp(this.configuration).deleteUsingPOST3(req, options)(this.axios, this.basePath);
    }

    /**
     * get program list of ranch
     * @summary program list of ranch
     * @param {ProgramListReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramControllerApi
     */
    public getProgramListUsingPOST(req: ProgramListReq, options?: any) {
        return ProgramControllerApiFp(this.configuration).getProgramListUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * insert or update program
     * @summary program data
     * @param {ProgramModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgramControllerApi
     */
    public modifyUsingPOST3(req: ProgramModifyReq, options?: any) {
        return ProgramControllerApiFp(this.configuration).modifyUsingPOST3(req, options)(this.axios, this.basePath);
    }

}


/**
 * RanchControllerApi - axios parameter creator
 * @export
 */
export const RanchControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * delete the ranch feed
         * @summary delete ranch feed
         * @param {RanchFeedDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeedUsingPOST(req: RanchFeedDeleteReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deleteFeedUsingPOST.');
            }
            const localVarPath = `/api/ranch/feed/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete the ranch house
         * @summary delete ranch house
         * @param {RanchHouseDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHouseUsingPOST(req: RanchHouseDeleteReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deleteHouseUsingPOST.');
            }
            const localVarPath = `/api/ranch/house/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete the ranch schedule list
         * @summary delete ranch schedule
         * @param {RanchScheduleDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScheduleUsingPOST(req: RanchScheduleDeleteReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deleteScheduleUsingPOST.');
            }
            const localVarPath = `/api/ranch/schedule/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete the ranch seed lot
         * @summary delete ranch seed lot
         * @param {SeedLotDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSeedLotUsingPOST(req: SeedLotDeleteReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deleteSeedLotUsingPOST.');
            }
            const localVarPath = `/api/ranch/seed/lot/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete the ranch seed
         * @summary delete ranch seed
         * @param {RanchSeedDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSeedUsingPOST(req: RanchSeedDeleteReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deleteSeedUsingPOST.');
            }
            const localVarPath = `/api/ranch/seed/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get ranch constant values
         * @param {ComRanchIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConstUsingPOST(req: ComRanchIdReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getConstUsingPOST.');
            }
            const localVarPath = `/api/ranch/const/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the ranch event history
         * @summary ranch event histories
         * @param {RanchEventHistoryReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventListUsingPOST(req: RanchEventHistoryReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getEventListUsingPOST.');
            }
            const localVarPath = `/api/ranch/event/history`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the ranch feed list
         * @summary ranch feed
         * @param {RanchFeedReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeedListUsingPOST(req: RanchFeedReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getFeedListUsingPOST.');
            }
            const localVarPath = `/api/ranch/feed/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the ranch house list
         * @summary ranch house list
         * @param {RanchHouseReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHouseListUsingPOST(req: RanchHouseReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getHouseListUsingPOST.');
            }
            const localVarPath = `/api/ranch/house/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get common master lists
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoUsingPOST5(options: any = {}): RequestArgs {
            const localVarPath = `/api/ranch/info`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the ranch schedule list
         * @summary ranch schedule
         * @param {RanchScheduleReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleListUsingPOST(req: RanchScheduleReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getScheduleListUsingPOST.');
            }
            const localVarPath = `/api/ranch/schedule/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the ranch seed list
         * @summary ranch seed
         * @param {ComRanchIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeedListUsingPOST(req: ComRanchIdReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getSeedListUsingPOST.');
            }
            const localVarPath = `/api/ranch/seed/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the tag list
         * @summary get tag list
         * @param {RanchTagListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagListUsingPOST(req: RanchTagListReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getTagListUsingPOST.');
            }
            const localVarPath = `/api/ranch/tag/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary modify ranch constant values
         * @param {RanchConstModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyConstUsingPOST(req: RanchConstModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyConstUsingPOST.');
            }
            const localVarPath = `/api/ranch/const/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * modify the ranch feed list
         * @summary modify ranch feed
         * @param {RanchFeedModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyFeedUsingPOST1(req: RanchFeedModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyFeedUsingPOST1.');
            }
            const localVarPath = `/api/ranch/feed/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * modify the ranch house
         * @summary modify ranch house
         * @param {RanchHouseModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyHouseUsingPOST(req: RanchHouseModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyHouseUsingPOST.');
            }
            const localVarPath = `/api/ranch/house/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * modify the ranch schedule list
         * @summary modify ranch schedule
         * @param {RanchScheduleModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyScheduleUsingPOST(req: RanchScheduleModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyScheduleUsingPOST.');
            }
            const localVarPath = `/api/ranch/schedule/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * modify the ranch seed lot
         * @summary modify ranch seed lot
         * @param {RanchSeedLotModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifySeedLotUsingPOST(req: RanchSeedLotModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifySeedLotUsingPOST.');
            }
            const localVarPath = `/api/ranch/seed/lot/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * modify the ranch seed stock
         * @summary modify ranch seed stock
         * @param {RanchSeedStockModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifySeedStockUsingPOST(req: RanchSeedStockModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifySeedStockUsingPOST.');
            }
            const localVarPath = `/api/ranch/seed/stock/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * modify the ranch seed list
         * @summary modify ranch seed
         * @param {RanchSeedModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifySeedUsingPOST(req: RanchSeedModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifySeedUsingPOST.');
            }
            const localVarPath = `/api/ranch/seed/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * sort disp no of ranch feed
         * @summary sort disp no of ranch feed
         * @param {RanchFeedSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortFeedUsingPOST(req: RanchFeedSortReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling sortFeedUsingPOST.');
            }
            const localVarPath = `/api/ranch/feed/sort`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * sort disp no of ranch house
         * @summary sort disp no of ranch house
         * @param {RanchHouseSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortHouseUsingPOST(req: RanchHouseSortReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling sortHouseUsingPOST.');
            }
            const localVarPath = `/api/ranch/house/sort`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * sort disp no of ranch seed
         * @summary sort disp no of ranch seed
         * @param {RanchSeedSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortSeedUsingPOST(req: RanchSeedSortReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling sortSeedUsingPOST.');
            }
            const localVarPath = `/api/ranch/seed/sort`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary split a schedule by cows
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        splitScheduleUsingPOST(req: ComRanchAndIdReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling splitScheduleUsingPOST.');
            }
            const localVarPath = `/api/ranch/schedule/split`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RanchControllerApi - functional programming interface
 * @export
 */
export const RanchControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * delete the ranch feed
         * @summary delete ranch feed
         * @param {RanchFeedDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeedUsingPOST(req: RanchFeedDeleteReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = RanchControllerApiAxiosParamCreator(configuration).deleteFeedUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * delete the ranch house
         * @summary delete ranch house
         * @param {RanchHouseDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHouseUsingPOST(req: RanchHouseDeleteReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = RanchControllerApiAxiosParamCreator(configuration).deleteHouseUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * delete the ranch schedule list
         * @summary delete ranch schedule
         * @param {RanchScheduleDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScheduleUsingPOST(req: RanchScheduleDeleteReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = RanchControllerApiAxiosParamCreator(configuration).deleteScheduleUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * delete the ranch seed lot
         * @summary delete ranch seed lot
         * @param {SeedLotDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSeedLotUsingPOST(req: SeedLotDeleteReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = RanchControllerApiAxiosParamCreator(configuration).deleteSeedLotUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * delete the ranch seed
         * @summary delete ranch seed
         * @param {RanchSeedDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSeedUsingPOST(req: RanchSeedDeleteReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = RanchControllerApiAxiosParamCreator(configuration).deleteSeedUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get ranch constant values
         * @param {ComRanchIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConstUsingPOST(req: ComRanchIdReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseRanchConstDto> {
            const localVarAxiosArgs = RanchControllerApiAxiosParamCreator(configuration).getConstUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get the ranch event history
         * @summary ranch event histories
         * @param {RanchEventHistoryReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventListUsingPOST(req: RanchEventHistoryReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseRanchEventListDto> {
            const localVarAxiosArgs = RanchControllerApiAxiosParamCreator(configuration).getEventListUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get the ranch feed list
         * @summary ranch feed
         * @param {RanchFeedReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeedListUsingPOST(req: RanchFeedReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseRanchFeedDto> {
            const localVarAxiosArgs = RanchControllerApiAxiosParamCreator(configuration).getFeedListUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get the ranch house list
         * @summary ranch house list
         * @param {RanchHouseReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHouseListUsingPOST(req: RanchHouseReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseRanchHouseDto> {
            const localVarAxiosArgs = RanchControllerApiAxiosParamCreator(configuration).getHouseListUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get common master lists
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoUsingPOST5(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseRanchInfoDto> {
            const localVarAxiosArgs = RanchControllerApiAxiosParamCreator(configuration).getInfoUsingPOST5(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get the ranch schedule list
         * @summary ranch schedule
         * @param {RanchScheduleReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleListUsingPOST(req: RanchScheduleReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListRanchScheduleDto> {
            const localVarAxiosArgs = RanchControllerApiAxiosParamCreator(configuration).getScheduleListUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get the ranch seed list
         * @summary ranch seed
         * @param {ComRanchIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeedListUsingPOST(req: ComRanchIdReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListRanchSeedListDto> {
            const localVarAxiosArgs = RanchControllerApiAxiosParamCreator(configuration).getSeedListUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get the tag list
         * @summary get tag list
         * @param {RanchTagListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagListUsingPOST(req: RanchTagListReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListRanchTagDto> {
            const localVarAxiosArgs = RanchControllerApiAxiosParamCreator(configuration).getTagListUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary modify ranch constant values
         * @param {RanchConstModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyConstUsingPOST(req: RanchConstModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = RanchControllerApiAxiosParamCreator(configuration).modifyConstUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * modify the ranch feed list
         * @summary modify ranch feed
         * @param {RanchFeedModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyFeedUsingPOST1(req: RanchFeedModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = RanchControllerApiAxiosParamCreator(configuration).modifyFeedUsingPOST1(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * modify the ranch house
         * @summary modify ranch house
         * @param {RanchHouseModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyHouseUsingPOST(req: RanchHouseModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = RanchControllerApiAxiosParamCreator(configuration).modifyHouseUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * modify the ranch schedule list
         * @summary modify ranch schedule
         * @param {RanchScheduleModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyScheduleUsingPOST(req: RanchScheduleModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = RanchControllerApiAxiosParamCreator(configuration).modifyScheduleUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * modify the ranch seed lot
         * @summary modify ranch seed lot
         * @param {RanchSeedLotModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifySeedLotUsingPOST(req: RanchSeedLotModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = RanchControllerApiAxiosParamCreator(configuration).modifySeedLotUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * modify the ranch seed stock
         * @summary modify ranch seed stock
         * @param {RanchSeedStockModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifySeedStockUsingPOST(req: RanchSeedStockModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = RanchControllerApiAxiosParamCreator(configuration).modifySeedStockUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * modify the ranch seed list
         * @summary modify ranch seed
         * @param {RanchSeedModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifySeedUsingPOST(req: RanchSeedModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseComIdDto> {
            const localVarAxiosArgs = RanchControllerApiAxiosParamCreator(configuration).modifySeedUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * sort disp no of ranch feed
         * @summary sort disp no of ranch feed
         * @param {RanchFeedSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortFeedUsingPOST(req: RanchFeedSortReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = RanchControllerApiAxiosParamCreator(configuration).sortFeedUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * sort disp no of ranch house
         * @summary sort disp no of ranch house
         * @param {RanchHouseSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortHouseUsingPOST(req: RanchHouseSortReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = RanchControllerApiAxiosParamCreator(configuration).sortHouseUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * sort disp no of ranch seed
         * @summary sort disp no of ranch seed
         * @param {RanchSeedSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortSeedUsingPOST(req: RanchSeedSortReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = RanchControllerApiAxiosParamCreator(configuration).sortSeedUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary split a schedule by cows
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        splitScheduleUsingPOST(req: ComRanchAndIdReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListRanchScheduleDto> {
            const localVarAxiosArgs = RanchControllerApiAxiosParamCreator(configuration).splitScheduleUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * RanchControllerApi - factory interface
 * @export
 */
export const RanchControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * delete the ranch feed
         * @summary delete ranch feed
         * @param {RanchFeedDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeedUsingPOST(req: RanchFeedDeleteReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return RanchControllerApiFp(configuration).deleteFeedUsingPOST(req, options)(axios, basePath);
        },
        /**
         * delete the ranch house
         * @summary delete ranch house
         * @param {RanchHouseDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHouseUsingPOST(req: RanchHouseDeleteReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return RanchControllerApiFp(configuration).deleteHouseUsingPOST(req, options)(axios, basePath);
        },
        /**
         * delete the ranch schedule list
         * @summary delete ranch schedule
         * @param {RanchScheduleDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScheduleUsingPOST(req: RanchScheduleDeleteReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return RanchControllerApiFp(configuration).deleteScheduleUsingPOST(req, options)(axios, basePath);
        },
        /**
         * delete the ranch seed lot
         * @summary delete ranch seed lot
         * @param {SeedLotDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSeedLotUsingPOST(req: SeedLotDeleteReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return RanchControllerApiFp(configuration).deleteSeedLotUsingPOST(req, options)(axios, basePath);
        },
        /**
         * delete the ranch seed
         * @summary delete ranch seed
         * @param {RanchSeedDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSeedUsingPOST(req: RanchSeedDeleteReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return RanchControllerApiFp(configuration).deleteSeedUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary get ranch constant values
         * @param {ComRanchIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConstUsingPOST(req: ComRanchIdReq, options?: any): AxiosPromise<GenericResponseRanchConstDto> {
            return RanchControllerApiFp(configuration).getConstUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get the ranch event history
         * @summary ranch event histories
         * @param {RanchEventHistoryReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventListUsingPOST(req: RanchEventHistoryReq, options?: any): AxiosPromise<GenericResponseRanchEventListDto> {
            return RanchControllerApiFp(configuration).getEventListUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get the ranch feed list
         * @summary ranch feed
         * @param {RanchFeedReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeedListUsingPOST(req: RanchFeedReq, options?: any): AxiosPromise<GenericResponseRanchFeedDto> {
            return RanchControllerApiFp(configuration).getFeedListUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get the ranch house list
         * @summary ranch house list
         * @param {RanchHouseReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHouseListUsingPOST(req: RanchHouseReq, options?: any): AxiosPromise<GenericResponseRanchHouseDto> {
            return RanchControllerApiFp(configuration).getHouseListUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary get common master lists
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoUsingPOST5(options?: any): AxiosPromise<GenericResponseRanchInfoDto> {
            return RanchControllerApiFp(configuration).getInfoUsingPOST5(options)(axios, basePath);
        },
        /**
         * get the ranch schedule list
         * @summary ranch schedule
         * @param {RanchScheduleReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleListUsingPOST(req: RanchScheduleReq, options?: any): AxiosPromise<GenericResponseListRanchScheduleDto> {
            return RanchControllerApiFp(configuration).getScheduleListUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get the ranch seed list
         * @summary ranch seed
         * @param {ComRanchIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeedListUsingPOST(req: ComRanchIdReq, options?: any): AxiosPromise<GenericResponseListRanchSeedListDto> {
            return RanchControllerApiFp(configuration).getSeedListUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get the tag list
         * @summary get tag list
         * @param {RanchTagListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagListUsingPOST(req: RanchTagListReq, options?: any): AxiosPromise<GenericResponseListRanchTagDto> {
            return RanchControllerApiFp(configuration).getTagListUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary modify ranch constant values
         * @param {RanchConstModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyConstUsingPOST(req: RanchConstModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return RanchControllerApiFp(configuration).modifyConstUsingPOST(req, options)(axios, basePath);
        },
        /**
         * modify the ranch feed list
         * @summary modify ranch feed
         * @param {RanchFeedModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyFeedUsingPOST1(req: RanchFeedModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return RanchControllerApiFp(configuration).modifyFeedUsingPOST1(req, options)(axios, basePath);
        },
        /**
         * modify the ranch house
         * @summary modify ranch house
         * @param {RanchHouseModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyHouseUsingPOST(req: RanchHouseModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return RanchControllerApiFp(configuration).modifyHouseUsingPOST(req, options)(axios, basePath);
        },
        /**
         * modify the ranch schedule list
         * @summary modify ranch schedule
         * @param {RanchScheduleModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyScheduleUsingPOST(req: RanchScheduleModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return RanchControllerApiFp(configuration).modifyScheduleUsingPOST(req, options)(axios, basePath);
        },
        /**
         * modify the ranch seed lot
         * @summary modify ranch seed lot
         * @param {RanchSeedLotModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifySeedLotUsingPOST(req: RanchSeedLotModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return RanchControllerApiFp(configuration).modifySeedLotUsingPOST(req, options)(axios, basePath);
        },
        /**
         * modify the ranch seed stock
         * @summary modify ranch seed stock
         * @param {RanchSeedStockModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifySeedStockUsingPOST(req: RanchSeedStockModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return RanchControllerApiFp(configuration).modifySeedStockUsingPOST(req, options)(axios, basePath);
        },
        /**
         * modify the ranch seed list
         * @summary modify ranch seed
         * @param {RanchSeedModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifySeedUsingPOST(req: RanchSeedModifyReq, options?: any): AxiosPromise<GenericResponseComIdDto> {
            return RanchControllerApiFp(configuration).modifySeedUsingPOST(req, options)(axios, basePath);
        },
        /**
         * sort disp no of ranch feed
         * @summary sort disp no of ranch feed
         * @param {RanchFeedSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortFeedUsingPOST(req: RanchFeedSortReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return RanchControllerApiFp(configuration).sortFeedUsingPOST(req, options)(axios, basePath);
        },
        /**
         * sort disp no of ranch house
         * @summary sort disp no of ranch house
         * @param {RanchHouseSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortHouseUsingPOST(req: RanchHouseSortReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return RanchControllerApiFp(configuration).sortHouseUsingPOST(req, options)(axios, basePath);
        },
        /**
         * sort disp no of ranch seed
         * @summary sort disp no of ranch seed
         * @param {RanchSeedSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortSeedUsingPOST(req: RanchSeedSortReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return RanchControllerApiFp(configuration).sortSeedUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary split a schedule by cows
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        splitScheduleUsingPOST(req: ComRanchAndIdReq, options?: any): AxiosPromise<GenericResponseListRanchScheduleDto> {
            return RanchControllerApiFp(configuration).splitScheduleUsingPOST(req, options)(axios, basePath);
        },
    };
};

/**
 * RanchControllerApi - object-oriented interface
 * @export
 * @class RanchControllerApi
 * @extends {BaseAPI}
 */
export class RanchControllerApi extends BaseAPI {
    /**
     * delete the ranch feed
     * @summary delete ranch feed
     * @param {RanchFeedDeleteReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RanchControllerApi
     */
    public deleteFeedUsingPOST(req: RanchFeedDeleteReq, options?: any) {
        return RanchControllerApiFp(this.configuration).deleteFeedUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * delete the ranch house
     * @summary delete ranch house
     * @param {RanchHouseDeleteReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RanchControllerApi
     */
    public deleteHouseUsingPOST(req: RanchHouseDeleteReq, options?: any) {
        return RanchControllerApiFp(this.configuration).deleteHouseUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * delete the ranch schedule list
     * @summary delete ranch schedule
     * @param {RanchScheduleDeleteReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RanchControllerApi
     */
    public deleteScheduleUsingPOST(req: RanchScheduleDeleteReq, options?: any) {
        return RanchControllerApiFp(this.configuration).deleteScheduleUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * delete the ranch seed lot
     * @summary delete ranch seed lot
     * @param {SeedLotDeleteReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RanchControllerApi
     */
    public deleteSeedLotUsingPOST(req: SeedLotDeleteReq, options?: any) {
        return RanchControllerApiFp(this.configuration).deleteSeedLotUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * delete the ranch seed
     * @summary delete ranch seed
     * @param {RanchSeedDeleteReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RanchControllerApi
     */
    public deleteSeedUsingPOST(req: RanchSeedDeleteReq, options?: any) {
        return RanchControllerApiFp(this.configuration).deleteSeedUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get ranch constant values
     * @param {ComRanchIdReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RanchControllerApi
     */
    public getConstUsingPOST(req: ComRanchIdReq, options?: any) {
        return RanchControllerApiFp(this.configuration).getConstUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get the ranch event history
     * @summary ranch event histories
     * @param {RanchEventHistoryReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RanchControllerApi
     */
    public getEventListUsingPOST(req: RanchEventHistoryReq, options?: any) {
        return RanchControllerApiFp(this.configuration).getEventListUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get the ranch feed list
     * @summary ranch feed
     * @param {RanchFeedReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RanchControllerApi
     */
    public getFeedListUsingPOST(req: RanchFeedReq, options?: any) {
        return RanchControllerApiFp(this.configuration).getFeedListUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get the ranch house list
     * @summary ranch house list
     * @param {RanchHouseReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RanchControllerApi
     */
    public getHouseListUsingPOST(req: RanchHouseReq, options?: any) {
        return RanchControllerApiFp(this.configuration).getHouseListUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get common master lists
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RanchControllerApi
     */
    public getInfoUsingPOST5(options?: any) {
        return RanchControllerApiFp(this.configuration).getInfoUsingPOST5(options)(this.axios, this.basePath);
    }

    /**
     * get the ranch schedule list
     * @summary ranch schedule
     * @param {RanchScheduleReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RanchControllerApi
     */
    public getScheduleListUsingPOST(req: RanchScheduleReq, options?: any) {
        return RanchControllerApiFp(this.configuration).getScheduleListUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get the ranch seed list
     * @summary ranch seed
     * @param {ComRanchIdReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RanchControllerApi
     */
    public getSeedListUsingPOST(req: ComRanchIdReq, options?: any) {
        return RanchControllerApiFp(this.configuration).getSeedListUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get the tag list
     * @summary get tag list
     * @param {RanchTagListReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RanchControllerApi
     */
    public getTagListUsingPOST(req: RanchTagListReq, options?: any) {
        return RanchControllerApiFp(this.configuration).getTagListUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary modify ranch constant values
     * @param {RanchConstModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RanchControllerApi
     */
    public modifyConstUsingPOST(req: RanchConstModifyReq, options?: any) {
        return RanchControllerApiFp(this.configuration).modifyConstUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * modify the ranch feed list
     * @summary modify ranch feed
     * @param {RanchFeedModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RanchControllerApi
     */
    public modifyFeedUsingPOST1(req: RanchFeedModifyReq, options?: any) {
        return RanchControllerApiFp(this.configuration).modifyFeedUsingPOST1(req, options)(this.axios, this.basePath);
    }

    /**
     * modify the ranch house
     * @summary modify ranch house
     * @param {RanchHouseModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RanchControllerApi
     */
    public modifyHouseUsingPOST(req: RanchHouseModifyReq, options?: any) {
        return RanchControllerApiFp(this.configuration).modifyHouseUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * modify the ranch schedule list
     * @summary modify ranch schedule
     * @param {RanchScheduleModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RanchControllerApi
     */
    public modifyScheduleUsingPOST(req: RanchScheduleModifyReq, options?: any) {
        return RanchControllerApiFp(this.configuration).modifyScheduleUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * modify the ranch seed lot
     * @summary modify ranch seed lot
     * @param {RanchSeedLotModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RanchControllerApi
     */
    public modifySeedLotUsingPOST(req: RanchSeedLotModifyReq, options?: any) {
        return RanchControllerApiFp(this.configuration).modifySeedLotUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * modify the ranch seed stock
     * @summary modify ranch seed stock
     * @param {RanchSeedStockModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RanchControllerApi
     */
    public modifySeedStockUsingPOST(req: RanchSeedStockModifyReq, options?: any) {
        return RanchControllerApiFp(this.configuration).modifySeedStockUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * modify the ranch seed list
     * @summary modify ranch seed
     * @param {RanchSeedModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RanchControllerApi
     */
    public modifySeedUsingPOST(req: RanchSeedModifyReq, options?: any) {
        return RanchControllerApiFp(this.configuration).modifySeedUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * sort disp no of ranch feed
     * @summary sort disp no of ranch feed
     * @param {RanchFeedSortReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RanchControllerApi
     */
    public sortFeedUsingPOST(req: RanchFeedSortReq, options?: any) {
        return RanchControllerApiFp(this.configuration).sortFeedUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * sort disp no of ranch house
     * @summary sort disp no of ranch house
     * @param {RanchHouseSortReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RanchControllerApi
     */
    public sortHouseUsingPOST(req: RanchHouseSortReq, options?: any) {
        return RanchControllerApiFp(this.configuration).sortHouseUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * sort disp no of ranch seed
     * @summary sort disp no of ranch seed
     * @param {RanchSeedSortReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RanchControllerApi
     */
    public sortSeedUsingPOST(req: RanchSeedSortReq, options?: any) {
        return RanchControllerApiFp(this.configuration).sortSeedUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary split a schedule by cows
     * @param {ComRanchAndIdReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RanchControllerApi
     */
    public splitScheduleUsingPOST(req: ComRanchAndIdReq, options?: any) {
        return RanchControllerApiFp(this.configuration).splitScheduleUsingPOST(req, options)(this.axios, this.basePath);
    }

}


/**
 * RobotControllerApi - axios parameter creator
 * @export
 */
export const RobotControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary import robot data
         * @param {RobotImportReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importRobotUsingPOST(req: RobotImportReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling importRobotUsingPOST.');
            }
            const localVarPath = `/api/robot/import`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update linking of robot to cow
         * @param {RobotCowModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyRobotCowUsingPOST(req: RobotCowModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyRobotCowUsingPOST.');
            }
            const localVarPath = `/api/robot/cow/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary preview robot data to import
         * @param {RobotPreviewReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        previewRobotUsingPOST(req: RobotPreviewReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling previewRobotUsingPOST.');
            }
            const localVarPath = `/api/robot/preview`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RobotControllerApi - functional programming interface
 * @export
 */
export const RobotControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary import robot data
         * @param {RobotImportReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importRobotUsingPOST(req: RobotImportReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = RobotControllerApiAxiosParamCreator(configuration).importRobotUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary update linking of robot to cow
         * @param {RobotCowModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyRobotCowUsingPOST(req: RobotCowModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = RobotControllerApiAxiosParamCreator(configuration).modifyRobotCowUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary preview robot data to import
         * @param {RobotPreviewReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        previewRobotUsingPOST(req: RobotPreviewReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseRobotPreviewDto> {
            const localVarAxiosArgs = RobotControllerApiAxiosParamCreator(configuration).previewRobotUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * RobotControllerApi - factory interface
 * @export
 */
export const RobotControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary import robot data
         * @param {RobotImportReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importRobotUsingPOST(req: RobotImportReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return RobotControllerApiFp(configuration).importRobotUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary update linking of robot to cow
         * @param {RobotCowModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyRobotCowUsingPOST(req: RobotCowModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return RobotControllerApiFp(configuration).modifyRobotCowUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary preview robot data to import
         * @param {RobotPreviewReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        previewRobotUsingPOST(req: RobotPreviewReq, options?: any): AxiosPromise<GenericResponseRobotPreviewDto> {
            return RobotControllerApiFp(configuration).previewRobotUsingPOST(req, options)(axios, basePath);
        },
    };
};

/**
 * RobotControllerApi - object-oriented interface
 * @export
 * @class RobotControllerApi
 * @extends {BaseAPI}
 */
export class RobotControllerApi extends BaseAPI {
    /**
     * 
     * @summary import robot data
     * @param {RobotImportReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotControllerApi
     */
    public importRobotUsingPOST(req: RobotImportReq, options?: any) {
        return RobotControllerApiFp(this.configuration).importRobotUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary update linking of robot to cow
     * @param {RobotCowModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotControllerApi
     */
    public modifyRobotCowUsingPOST(req: RobotCowModifyReq, options?: any) {
        return RobotControllerApiFp(this.configuration).modifyRobotCowUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary preview robot data to import
     * @param {RobotPreviewReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotControllerApi
     */
    public previewRobotUsingPOST(req: RobotPreviewReq, options?: any) {
        return RobotControllerApiFp(this.configuration).previewRobotUsingPOST(req, options)(this.axios, this.basePath);
    }

}


/**
 * RutControllerApi - axios parameter creator
 * @export
 */
export const RutControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * delete rut
         * @summary delete rut
         * @param {RutDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST4(req: RutDeleteReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deleteUsingPOST4.');
            }
            const localVarPath = `/api/rut/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get rut info
         * @summary rut info
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoUsingPOST6(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getInfoUsingPOST6.');
            }
            const localVarPath = `/api/rut/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get latest rut records
         * @param {RutLatestReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestRutsUsingPOST(req: RutLatestReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getLatestRutsUsingPOST.');
            }
            const localVarPath = `/api/rut/latest`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get program items witch will be generated by the rut event.
         * @param {ProgramAppliedItemsReqRutModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramsForRutUsingPOST(req: ProgramAppliedItemsReqRutModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getProgramsForRutUsingPOST.');
            }
            const localVarPath = `/api/rut/program`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add or modify rut of some cow
         * @summary add or modify rut
         * @param {RutModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyUsingPOST4(req: RutModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyUsingPOST4.');
            }
            const localVarPath = `/api/rut/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RutControllerApi - functional programming interface
 * @export
 */
export const RutControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * delete rut
         * @summary delete rut
         * @param {RutDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST4(req: RutDeleteReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = RutControllerApiAxiosParamCreator(configuration).deleteUsingPOST4(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get rut info
         * @summary rut info
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoUsingPOST6(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseRutDto> {
            const localVarAxiosArgs = RutControllerApiAxiosParamCreator(configuration).getInfoUsingPOST6(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get latest rut records
         * @param {RutLatestReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestRutsUsingPOST(req: RutLatestReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListRutLatestDto> {
            const localVarAxiosArgs = RutControllerApiAxiosParamCreator(configuration).getLatestRutsUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get program items witch will be generated by the rut event.
         * @param {ProgramAppliedItemsReqRutModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramsForRutUsingPOST(req: ProgramAppliedItemsReqRutModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseProgramApplyResultDto> {
            const localVarAxiosArgs = RutControllerApiAxiosParamCreator(configuration).getProgramsForRutUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * add or modify rut of some cow
         * @summary add or modify rut
         * @param {RutModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyUsingPOST4(req: RutModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = RutControllerApiAxiosParamCreator(configuration).modifyUsingPOST4(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * RutControllerApi - factory interface
 * @export
 */
export const RutControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * delete rut
         * @summary delete rut
         * @param {RutDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST4(req: RutDeleteReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return RutControllerApiFp(configuration).deleteUsingPOST4(req, options)(axios, basePath);
        },
        /**
         * get rut info
         * @summary rut info
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoUsingPOST6(id: number, options?: any): AxiosPromise<GenericResponseRutDto> {
            return RutControllerApiFp(configuration).getInfoUsingPOST6(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary get latest rut records
         * @param {RutLatestReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestRutsUsingPOST(req: RutLatestReq, options?: any): AxiosPromise<GenericResponseListRutLatestDto> {
            return RutControllerApiFp(configuration).getLatestRutsUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary get program items witch will be generated by the rut event.
         * @param {ProgramAppliedItemsReqRutModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramsForRutUsingPOST(req: ProgramAppliedItemsReqRutModifyReq, options?: any): AxiosPromise<GenericResponseProgramApplyResultDto> {
            return RutControllerApiFp(configuration).getProgramsForRutUsingPOST(req, options)(axios, basePath);
        },
        /**
         * add or modify rut of some cow
         * @summary add or modify rut
         * @param {RutModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyUsingPOST4(req: RutModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return RutControllerApiFp(configuration).modifyUsingPOST4(req, options)(axios, basePath);
        },
    };
};

/**
 * RutControllerApi - object-oriented interface
 * @export
 * @class RutControllerApi
 * @extends {BaseAPI}
 */
export class RutControllerApi extends BaseAPI {
    /**
     * delete rut
     * @summary delete rut
     * @param {RutDeleteReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RutControllerApi
     */
    public deleteUsingPOST4(req: RutDeleteReq, options?: any) {
        return RutControllerApiFp(this.configuration).deleteUsingPOST4(req, options)(this.axios, this.basePath);
    }

    /**
     * get rut info
     * @summary rut info
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RutControllerApi
     */
    public getInfoUsingPOST6(id: number, options?: any) {
        return RutControllerApiFp(this.configuration).getInfoUsingPOST6(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get latest rut records
     * @param {RutLatestReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RutControllerApi
     */
    public getLatestRutsUsingPOST(req: RutLatestReq, options?: any) {
        return RutControllerApiFp(this.configuration).getLatestRutsUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get program items witch will be generated by the rut event.
     * @param {ProgramAppliedItemsReqRutModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RutControllerApi
     */
    public getProgramsForRutUsingPOST(req: ProgramAppliedItemsReqRutModifyReq, options?: any) {
        return RutControllerApiFp(this.configuration).getProgramsForRutUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * add or modify rut of some cow
     * @summary add or modify rut
     * @param {RutModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RutControllerApi
     */
    public modifyUsingPOST4(req: RutModifyReq, options?: any) {
        return RutControllerApiFp(this.configuration).modifyUsingPOST4(req, options)(this.axios, this.basePath);
    }

}


/**
 * SellCowControllerApi - axios parameter creator
 * @export
 */
export const SellCowControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary aggregate sell cow records by mother
         * @param {SellCowAggregateMotherReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateMotherUsingPOST(req: SellCowAggregateMotherReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling aggregateMotherUsingPOST.');
            }
            const localVarPath = `/api/sell/cow/aggregate/mother`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary aggregate sell cow records by partners
         * @param {SellCowAggregatePartnerReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregatePartnerUsingPOST(req: SellCowAggregatePartnerReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling aggregatePartnerUsingPOST.');
            }
            const localVarPath = `/api/sell/cow/aggregate/partner`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary aggregate sell cow records by months
         * @param {AggregateTransitionReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateTransitionUsingPOST1(req: AggregateTransitionReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling aggregateTransitionUsingPOST1.');
            }
            const localVarPath = `/api/sell/cow/aggregate/transition`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete sold cow data
         * @summary sold cow data
         * @param {SellCowDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST5(req: SellCowDeleteReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deleteUsingPOST5.');
            }
            const localVarPath = `/api/sell/cow/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get carcass records for a mother
         * @param {SellCowAggregateChildrenCarcassReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildrenCarcassListUsingPOST(req: SellCowAggregateChildrenCarcassReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getChildrenCarcassListUsingPOST.');
            }
            const localVarPath = `/api/sell/cow/aggregate/children/carcass`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get detail of a selling cow event
         * @summary detail event data
         * @param {SingleEventReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventUsingPOST1(req: SingleEventReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getEventUsingPOST1.');
            }
            const localVarPath = `/api/sell/cow/event`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get carcass records for a partner
         * @param {SellCowAggregateCarcassReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerCarcassListUsingPOST(req: SellCowAggregateCarcassReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getPartnerCarcassListUsingPOST.');
            }
            const localVarPath = `/api/sell/cow/aggregate/carcass`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * find sold cow data
         * @summary sold cow data list
         * @param {SellCowSearchReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellCowUsingPOST(req: SellCowSearchReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getSellCowUsingPOST.');
            }
            const localVarPath = `/api/sell/cow/search`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get sold cow data by event id
         * @summary sold cow data
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellCowUsingPOST1(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getSellCowUsingPOST1.');
            }
            const localVarPath = `/api/sell/cow/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * register or update sell cow
         * @summary sold cow data
         * @param {SellCowModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifySellCowUsingPOST(req: SellCowModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifySellCowUsingPOST.');
            }
            const localVarPath = `/api/sell/cow/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SellCowControllerApi - functional programming interface
 * @export
 */
export const SellCowControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary aggregate sell cow records by mother
         * @param {SellCowAggregateMotherReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateMotherUsingPOST(req: SellCowAggregateMotherReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseSellCowAggregateMotherListDto> {
            const localVarAxiosArgs = SellCowControllerApiAxiosParamCreator(configuration).aggregateMotherUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary aggregate sell cow records by partners
         * @param {SellCowAggregatePartnerReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregatePartnerUsingPOST(req: SellCowAggregatePartnerReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseSellCowAggregatePartnerListDto> {
            const localVarAxiosArgs = SellCowControllerApiAxiosParamCreator(configuration).aggregatePartnerUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary aggregate sell cow records by months
         * @param {AggregateTransitionReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateTransitionUsingPOST1(req: AggregateTransitionReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListSellCowAggregateTransitionDto> {
            const localVarAxiosArgs = SellCowControllerApiAxiosParamCreator(configuration).aggregateTransitionUsingPOST1(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * delete sold cow data
         * @summary sold cow data
         * @param {SellCowDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST5(req: SellCowDeleteReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = SellCowControllerApiAxiosParamCreator(configuration).deleteUsingPOST5(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get carcass records for a mother
         * @param {SellCowAggregateChildrenCarcassReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildrenCarcassListUsingPOST(req: SellCowAggregateChildrenCarcassReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListSellCowAggregateCarcassDto> {
            const localVarAxiosArgs = SellCowControllerApiAxiosParamCreator(configuration).getChildrenCarcassListUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get detail of a selling cow event
         * @summary detail event data
         * @param {SingleEventReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventUsingPOST1(req: SingleEventReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseSellCowEventDto> {
            const localVarAxiosArgs = SellCowControllerApiAxiosParamCreator(configuration).getEventUsingPOST1(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get carcass records for a partner
         * @param {SellCowAggregateCarcassReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerCarcassListUsingPOST(req: SellCowAggregateCarcassReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListSellCowAggregateCarcassDto> {
            const localVarAxiosArgs = SellCowControllerApiAxiosParamCreator(configuration).getPartnerCarcassListUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * find sold cow data
         * @summary sold cow data list
         * @param {SellCowSearchReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellCowUsingPOST(req: SellCowSearchReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListSellCowDto> {
            const localVarAxiosArgs = SellCowControllerApiAxiosParamCreator(configuration).getSellCowUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get sold cow data by event id
         * @summary sold cow data
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellCowUsingPOST1(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseSellCowDto> {
            const localVarAxiosArgs = SellCowControllerApiAxiosParamCreator(configuration).getSellCowUsingPOST1(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * register or update sell cow
         * @summary sold cow data
         * @param {SellCowModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifySellCowUsingPOST(req: SellCowModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = SellCowControllerApiAxiosParamCreator(configuration).modifySellCowUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SellCowControllerApi - factory interface
 * @export
 */
export const SellCowControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary aggregate sell cow records by mother
         * @param {SellCowAggregateMotherReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateMotherUsingPOST(req: SellCowAggregateMotherReq, options?: any): AxiosPromise<GenericResponseSellCowAggregateMotherListDto> {
            return SellCowControllerApiFp(configuration).aggregateMotherUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary aggregate sell cow records by partners
         * @param {SellCowAggregatePartnerReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregatePartnerUsingPOST(req: SellCowAggregatePartnerReq, options?: any): AxiosPromise<GenericResponseSellCowAggregatePartnerListDto> {
            return SellCowControllerApiFp(configuration).aggregatePartnerUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary aggregate sell cow records by months
         * @param {AggregateTransitionReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateTransitionUsingPOST1(req: AggregateTransitionReq, options?: any): AxiosPromise<GenericResponseListSellCowAggregateTransitionDto> {
            return SellCowControllerApiFp(configuration).aggregateTransitionUsingPOST1(req, options)(axios, basePath);
        },
        /**
         * delete sold cow data
         * @summary sold cow data
         * @param {SellCowDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST5(req: SellCowDeleteReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return SellCowControllerApiFp(configuration).deleteUsingPOST5(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary get carcass records for a mother
         * @param {SellCowAggregateChildrenCarcassReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildrenCarcassListUsingPOST(req: SellCowAggregateChildrenCarcassReq, options?: any): AxiosPromise<GenericResponseListSellCowAggregateCarcassDto> {
            return SellCowControllerApiFp(configuration).getChildrenCarcassListUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get detail of a selling cow event
         * @summary detail event data
         * @param {SingleEventReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventUsingPOST1(req: SingleEventReq, options?: any): AxiosPromise<GenericResponseSellCowEventDto> {
            return SellCowControllerApiFp(configuration).getEventUsingPOST1(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary get carcass records for a partner
         * @param {SellCowAggregateCarcassReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerCarcassListUsingPOST(req: SellCowAggregateCarcassReq, options?: any): AxiosPromise<GenericResponseListSellCowAggregateCarcassDto> {
            return SellCowControllerApiFp(configuration).getPartnerCarcassListUsingPOST(req, options)(axios, basePath);
        },
        /**
         * find sold cow data
         * @summary sold cow data list
         * @param {SellCowSearchReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellCowUsingPOST(req: SellCowSearchReq, options?: any): AxiosPromise<GenericResponseListSellCowDto> {
            return SellCowControllerApiFp(configuration).getSellCowUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get sold cow data by event id
         * @summary sold cow data
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellCowUsingPOST1(id: number, options?: any): AxiosPromise<GenericResponseSellCowDto> {
            return SellCowControllerApiFp(configuration).getSellCowUsingPOST1(id, options)(axios, basePath);
        },
        /**
         * register or update sell cow
         * @summary sold cow data
         * @param {SellCowModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifySellCowUsingPOST(req: SellCowModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return SellCowControllerApiFp(configuration).modifySellCowUsingPOST(req, options)(axios, basePath);
        },
    };
};

/**
 * SellCowControllerApi - object-oriented interface
 * @export
 * @class SellCowControllerApi
 * @extends {BaseAPI}
 */
export class SellCowControllerApi extends BaseAPI {
    /**
     * 
     * @summary aggregate sell cow records by mother
     * @param {SellCowAggregateMotherReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellCowControllerApi
     */
    public aggregateMotherUsingPOST(req: SellCowAggregateMotherReq, options?: any) {
        return SellCowControllerApiFp(this.configuration).aggregateMotherUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary aggregate sell cow records by partners
     * @param {SellCowAggregatePartnerReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellCowControllerApi
     */
    public aggregatePartnerUsingPOST(req: SellCowAggregatePartnerReq, options?: any) {
        return SellCowControllerApiFp(this.configuration).aggregatePartnerUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary aggregate sell cow records by months
     * @param {AggregateTransitionReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellCowControllerApi
     */
    public aggregateTransitionUsingPOST1(req: AggregateTransitionReq, options?: any) {
        return SellCowControllerApiFp(this.configuration).aggregateTransitionUsingPOST1(req, options)(this.axios, this.basePath);
    }

    /**
     * delete sold cow data
     * @summary sold cow data
     * @param {SellCowDeleteReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellCowControllerApi
     */
    public deleteUsingPOST5(req: SellCowDeleteReq, options?: any) {
        return SellCowControllerApiFp(this.configuration).deleteUsingPOST5(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get carcass records for a mother
     * @param {SellCowAggregateChildrenCarcassReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellCowControllerApi
     */
    public getChildrenCarcassListUsingPOST(req: SellCowAggregateChildrenCarcassReq, options?: any) {
        return SellCowControllerApiFp(this.configuration).getChildrenCarcassListUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get detail of a selling cow event
     * @summary detail event data
     * @param {SingleEventReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellCowControllerApi
     */
    public getEventUsingPOST1(req: SingleEventReq, options?: any) {
        return SellCowControllerApiFp(this.configuration).getEventUsingPOST1(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get carcass records for a partner
     * @param {SellCowAggregateCarcassReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellCowControllerApi
     */
    public getPartnerCarcassListUsingPOST(req: SellCowAggregateCarcassReq, options?: any) {
        return SellCowControllerApiFp(this.configuration).getPartnerCarcassListUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * find sold cow data
     * @summary sold cow data list
     * @param {SellCowSearchReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellCowControllerApi
     */
    public getSellCowUsingPOST(req: SellCowSearchReq, options?: any) {
        return SellCowControllerApiFp(this.configuration).getSellCowUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get sold cow data by event id
     * @summary sold cow data
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellCowControllerApi
     */
    public getSellCowUsingPOST1(id: number, options?: any) {
        return SellCowControllerApiFp(this.configuration).getSellCowUsingPOST1(id, options)(this.axios, this.basePath);
    }

    /**
     * register or update sell cow
     * @summary sold cow data
     * @param {SellCowModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellCowControllerApi
     */
    public modifySellCowUsingPOST(req: SellCowModifyReq, options?: any) {
        return SellCowControllerApiFp(this.configuration).modifySellCowUsingPOST(req, options)(this.axios, this.basePath);
    }

}


/**
 * SellMilkControllerApi - axios parameter creator
 * @export
 */
export const SellMilkControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * delete selling cow milk
         * @summary delete selling cow milk
         * @param {SellMilkDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST6(req: SellMilkDeleteReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deleteUsingPOST6.');
            }
            const localVarPath = `/api/sell/milk/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get detail of a selling cow milk event
         * @summary detail event data
         * @param {SingleEventReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventUsingPOST2(req: SingleEventReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getEventUsingPOST2.');
            }
            const localVarPath = `/api/sell/milk/event`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get graph data of milk amount
         * @param {SellMilkGraphReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellMilkGraphUsingPOST(req: SellMilkGraphReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getSellMilkGraphUsingPOST.');
            }
            const localVarPath = `/api/sell/milk/graph`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the selling cow milk data
         * @summary selling cow milk data
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellMilkUsingPOST(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getSellMilkUsingPOST.');
            }
            const localVarPath = `/api/sell/milk/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add new selling cow milk or modify selling cow milk
         * @summary add or modify selling cow milk
         * @param {SellMilkModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyUsingPOST5(req: SellMilkModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyUsingPOST5.');
            }
            const localVarPath = `/api/sell/milk/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SellMilkControllerApi - functional programming interface
 * @export
 */
export const SellMilkControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * delete selling cow milk
         * @summary delete selling cow milk
         * @param {SellMilkDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST6(req: SellMilkDeleteReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = SellMilkControllerApiAxiosParamCreator(configuration).deleteUsingPOST6(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get detail of a selling cow milk event
         * @summary detail event data
         * @param {SingleEventReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventUsingPOST2(req: SingleEventReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseSellMilkEventDto> {
            const localVarAxiosArgs = SellMilkControllerApiAxiosParamCreator(configuration).getEventUsingPOST2(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get graph data of milk amount
         * @param {SellMilkGraphReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellMilkGraphUsingPOST(req: SellMilkGraphReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseSellMilkGraphDto> {
            const localVarAxiosArgs = SellMilkControllerApiAxiosParamCreator(configuration).getSellMilkGraphUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get the selling cow milk data
         * @summary selling cow milk data
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellMilkUsingPOST(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseSellMilkDto> {
            const localVarAxiosArgs = SellMilkControllerApiAxiosParamCreator(configuration).getSellMilkUsingPOST(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * add new selling cow milk or modify selling cow milk
         * @summary add or modify selling cow milk
         * @param {SellMilkModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyUsingPOST5(req: SellMilkModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = SellMilkControllerApiAxiosParamCreator(configuration).modifyUsingPOST5(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SellMilkControllerApi - factory interface
 * @export
 */
export const SellMilkControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * delete selling cow milk
         * @summary delete selling cow milk
         * @param {SellMilkDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST6(req: SellMilkDeleteReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return SellMilkControllerApiFp(configuration).deleteUsingPOST6(req, options)(axios, basePath);
        },
        /**
         * get detail of a selling cow milk event
         * @summary detail event data
         * @param {SingleEventReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventUsingPOST2(req: SingleEventReq, options?: any): AxiosPromise<GenericResponseSellMilkEventDto> {
            return SellMilkControllerApiFp(configuration).getEventUsingPOST2(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary get graph data of milk amount
         * @param {SellMilkGraphReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellMilkGraphUsingPOST(req: SellMilkGraphReq, options?: any): AxiosPromise<GenericResponseSellMilkGraphDto> {
            return SellMilkControllerApiFp(configuration).getSellMilkGraphUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get the selling cow milk data
         * @summary selling cow milk data
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellMilkUsingPOST(id: number, options?: any): AxiosPromise<GenericResponseSellMilkDto> {
            return SellMilkControllerApiFp(configuration).getSellMilkUsingPOST(id, options)(axios, basePath);
        },
        /**
         * add new selling cow milk or modify selling cow milk
         * @summary add or modify selling cow milk
         * @param {SellMilkModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyUsingPOST5(req: SellMilkModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return SellMilkControllerApiFp(configuration).modifyUsingPOST5(req, options)(axios, basePath);
        },
    };
};

/**
 * SellMilkControllerApi - object-oriented interface
 * @export
 * @class SellMilkControllerApi
 * @extends {BaseAPI}
 */
export class SellMilkControllerApi extends BaseAPI {
    /**
     * delete selling cow milk
     * @summary delete selling cow milk
     * @param {SellMilkDeleteReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellMilkControllerApi
     */
    public deleteUsingPOST6(req: SellMilkDeleteReq, options?: any) {
        return SellMilkControllerApiFp(this.configuration).deleteUsingPOST6(req, options)(this.axios, this.basePath);
    }

    /**
     * get detail of a selling cow milk event
     * @summary detail event data
     * @param {SingleEventReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellMilkControllerApi
     */
    public getEventUsingPOST2(req: SingleEventReq, options?: any) {
        return SellMilkControllerApiFp(this.configuration).getEventUsingPOST2(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get graph data of milk amount
     * @param {SellMilkGraphReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellMilkControllerApi
     */
    public getSellMilkGraphUsingPOST(req: SellMilkGraphReq, options?: any) {
        return SellMilkControllerApiFp(this.configuration).getSellMilkGraphUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get the selling cow milk data
     * @summary selling cow milk data
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellMilkControllerApi
     */
    public getSellMilkUsingPOST(id: number, options?: any) {
        return SellMilkControllerApiFp(this.configuration).getSellMilkUsingPOST(id, options)(this.axios, this.basePath);
    }

    /**
     * add new selling cow milk or modify selling cow milk
     * @summary add or modify selling cow milk
     * @param {SellMilkModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellMilkControllerApi
     */
    public modifyUsingPOST5(req: SellMilkModifyReq, options?: any) {
        return SellMilkControllerApiFp(this.configuration).modifyUsingPOST5(req, options)(this.axios, this.basePath);
    }

}


/**
 * SensorControllerApi - axios parameter creator
 * @export
 */
export const SensorControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get sensor history records of a cow
         * @param {SensorHistoryCowReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSensorHistoryCowUsingPOST(req: SensorHistoryCowReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getSensorHistoryCowUsingPOST.');
            }
            const localVarPath = `/api/sensor/history/cow`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get sensor record history groups
         * @param {SensorHistoryGroupReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSensorHistoryGroupsUsingPOST(req: SensorHistoryGroupReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getSensorHistoryGroupsUsingPOST.');
            }
            const localVarPath = `/api/sensor/history/group`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list cows with device link
         * @param {SensorDeviceListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDevicesUsingPOST(req: SensorDeviceListReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling listDevicesUsingPOST.');
            }
            const localVarPath = `/api/sensor/device/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list sensor records
         * @param {SensorHistoryListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSensorHistoryUsingPOST(req: SensorHistoryListReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling listSensorHistoryUsingPOST.');
            }
            const localVarPath = `/api/sensor/history/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary register device if not exist and link to cow
         * @param {SensorDeviceModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyDeviceUsingPOST(req: SensorDeviceModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyDeviceUsingPOST.');
            }
            const localVarPath = `/api/sensor/device/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary register sensor record of cows
         * @param {SensorCowModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifySensorCowUsingPOST(req: SensorCowModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifySensorCowUsingPOST.');
            }
            const localVarPath = `/api/sensor/cow/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary register sensor record of house
         * @param {SensorHouseModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifySensorHouseUsingPOST(req: SensorHouseModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifySensorHouseUsingPOST.');
            }
            const localVarPath = `/api/sensor/house/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SensorControllerApi - functional programming interface
 * @export
 */
export const SensorControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get sensor history records of a cow
         * @param {SensorHistoryCowReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSensorHistoryCowUsingPOST(req: SensorHistoryCowReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListSensorHistoryCowDto> {
            const localVarAxiosArgs = SensorControllerApiAxiosParamCreator(configuration).getSensorHistoryCowUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get sensor record history groups
         * @param {SensorHistoryGroupReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSensorHistoryGroupsUsingPOST(req: SensorHistoryGroupReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListSensorHistoryGroupDto> {
            const localVarAxiosArgs = SensorControllerApiAxiosParamCreator(configuration).getSensorHistoryGroupsUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary list cows with device link
         * @param {SensorDeviceListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDevicesUsingPOST(req: SensorDeviceListReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseSensorDeviceListDto> {
            const localVarAxiosArgs = SensorControllerApiAxiosParamCreator(configuration).listDevicesUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary list sensor records
         * @param {SensorHistoryListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSensorHistoryUsingPOST(req: SensorHistoryListReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListSensorHistoryListDto> {
            const localVarAxiosArgs = SensorControllerApiAxiosParamCreator(configuration).listSensorHistoryUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary register device if not exist and link to cow
         * @param {SensorDeviceModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyDeviceUsingPOST(req: SensorDeviceModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseSensorDeviceIdDto> {
            const localVarAxiosArgs = SensorControllerApiAxiosParamCreator(configuration).modifyDeviceUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary register sensor record of cows
         * @param {SensorCowModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifySensorCowUsingPOST(req: SensorCowModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = SensorControllerApiAxiosParamCreator(configuration).modifySensorCowUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary register sensor record of house
         * @param {SensorHouseModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifySensorHouseUsingPOST(req: SensorHouseModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = SensorControllerApiAxiosParamCreator(configuration).modifySensorHouseUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SensorControllerApi - factory interface
 * @export
 */
export const SensorControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary get sensor history records of a cow
         * @param {SensorHistoryCowReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSensorHistoryCowUsingPOST(req: SensorHistoryCowReq, options?: any): AxiosPromise<GenericResponseListSensorHistoryCowDto> {
            return SensorControllerApiFp(configuration).getSensorHistoryCowUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary get sensor record history groups
         * @param {SensorHistoryGroupReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSensorHistoryGroupsUsingPOST(req: SensorHistoryGroupReq, options?: any): AxiosPromise<GenericResponseListSensorHistoryGroupDto> {
            return SensorControllerApiFp(configuration).getSensorHistoryGroupsUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary list cows with device link
         * @param {SensorDeviceListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDevicesUsingPOST(req: SensorDeviceListReq, options?: any): AxiosPromise<GenericResponseSensorDeviceListDto> {
            return SensorControllerApiFp(configuration).listDevicesUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary list sensor records
         * @param {SensorHistoryListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSensorHistoryUsingPOST(req: SensorHistoryListReq, options?: any): AxiosPromise<GenericResponseListSensorHistoryListDto> {
            return SensorControllerApiFp(configuration).listSensorHistoryUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary register device if not exist and link to cow
         * @param {SensorDeviceModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyDeviceUsingPOST(req: SensorDeviceModifyReq, options?: any): AxiosPromise<GenericResponseSensorDeviceIdDto> {
            return SensorControllerApiFp(configuration).modifyDeviceUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary register sensor record of cows
         * @param {SensorCowModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifySensorCowUsingPOST(req: SensorCowModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return SensorControllerApiFp(configuration).modifySensorCowUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary register sensor record of house
         * @param {SensorHouseModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifySensorHouseUsingPOST(req: SensorHouseModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return SensorControllerApiFp(configuration).modifySensorHouseUsingPOST(req, options)(axios, basePath);
        },
    };
};

/**
 * SensorControllerApi - object-oriented interface
 * @export
 * @class SensorControllerApi
 * @extends {BaseAPI}
 */
export class SensorControllerApi extends BaseAPI {
    /**
     * 
     * @summary get sensor history records of a cow
     * @param {SensorHistoryCowReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SensorControllerApi
     */
    public getSensorHistoryCowUsingPOST(req: SensorHistoryCowReq, options?: any) {
        return SensorControllerApiFp(this.configuration).getSensorHistoryCowUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get sensor record history groups
     * @param {SensorHistoryGroupReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SensorControllerApi
     */
    public getSensorHistoryGroupsUsingPOST(req: SensorHistoryGroupReq, options?: any) {
        return SensorControllerApiFp(this.configuration).getSensorHistoryGroupsUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary list cows with device link
     * @param {SensorDeviceListReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SensorControllerApi
     */
    public listDevicesUsingPOST(req: SensorDeviceListReq, options?: any) {
        return SensorControllerApiFp(this.configuration).listDevicesUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary list sensor records
     * @param {SensorHistoryListReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SensorControllerApi
     */
    public listSensorHistoryUsingPOST(req: SensorHistoryListReq, options?: any) {
        return SensorControllerApiFp(this.configuration).listSensorHistoryUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary register device if not exist and link to cow
     * @param {SensorDeviceModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SensorControllerApi
     */
    public modifyDeviceUsingPOST(req: SensorDeviceModifyReq, options?: any) {
        return SensorControllerApiFp(this.configuration).modifyDeviceUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary register sensor record of cows
     * @param {SensorCowModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SensorControllerApi
     */
    public modifySensorCowUsingPOST(req: SensorCowModifyReq, options?: any) {
        return SensorControllerApiFp(this.configuration).modifySensorCowUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary register sensor record of house
     * @param {SensorHouseModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SensorControllerApi
     */
    public modifySensorHouseUsingPOST(req: SensorHouseModifyReq, options?: any) {
        return SensorControllerApiFp(this.configuration).modifySensorHouseUsingPOST(req, options)(this.axios, this.basePath);
    }

}


/**
 * SymptomControllerApi - axios parameter creator
 * @export
 */
export const SymptomControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary add treats of multiple cows
         * @param {SymptomBulkModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkModifyUsingPOST(req: SymptomBulkModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling bulkModifyUsingPOST.');
            }
            const localVarPath = `/api/symptom/bulk/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete symptom
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSymptomUsingPOST(req: ComRanchAndIdReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deleteSymptomUsingPOST.');
            }
            const localVarPath = `/api/symptom/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get cert disease
         * @param {CertDiseaseReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertDiseaseUsingPOST(req: CertDiseaseReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getCertDiseaseUsingPOST.');
            }
            const localVarPath = `/api/symptom/cert/disease`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get cert karte
         * @param {CertKarteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertKarteUsingPOST(req: CertKarteReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getCertKarteUsingPOST.');
            }
            const localVarPath = `/api/symptom/cert/karte`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get symptom and breeding disease records
         * @param {DiseaseListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiseasesUsingPOST(req: DiseaseListReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getDiseasesUsingPOST.');
            }
            const localVarPath = `/api/symptom/disease/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the symptom history
         * @summary symptom history
         * @param {SymptomHistoryReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoryUsingPOST(req: SymptomHistoryReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getHistoryUsingPOST.');
            }
            const localVarPath = `/api/symptom/history`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the symptom info
         * @summary symptom detail
         * @param {number} id id
         * @param {number} ranchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfo1UsingPOST2(id: number, ranchId: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getInfo1UsingPOST2.');
            }
            // verify required parameter 'ranchId' is not null or undefined
            if (ranchId === null || ranchId === undefined) {
                throw new RequiredError('ranchId','Required parameter ranchId was null or undefined when calling getInfo1UsingPOST2.');
            }
            const localVarPath = `/api/symptom/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ranchId !== undefined) {
                localVarQueryParameter['ranch_id'] = ranchId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get content values for karte
         * @param {CertKarteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKarteContentUsingPOST(req: CertKarteReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getKarteContentUsingPOST.');
            }
            const localVarPath = `/api/symptom/cert/karte/content`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get latest treats for each cows
         * @param {SymptomLatestReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestUsingPOST(req: SymptomLatestReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getLatestUsingPOST.');
            }
            const localVarPath = `/api/symptom/latest`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get symptom and breeding records for karte
         * @param {KarteListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListForKarteUsingPOST(req: KarteListReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getListForKarteUsingPOST.');
            }
            const localVarPath = `/api/symptom/karte/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get program items witch will be generated by the bulk treat.
         * @param {ProgramAppliedItemsReqSymptomBulkModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramsForBulkTreatUsingPOST(req: ProgramAppliedItemsReqSymptomBulkModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getProgramsForBulkTreatUsingPOST.');
            }
            const localVarPath = `/api/symptom/program/bulk`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get program items witch will be generated by the symptom event.
         * @param {ProgramAppliedItemsReqSymptomModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramsForSymptomUsingPOST(req: ProgramAppliedItemsReqSymptomModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getProgramsForSymptomUsingPOST.');
            }
            const localVarPath = `/api/symptom/program`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get symptom series info
         * @summary get symptom series info
         * @param {SymptomInfoReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeriesListUsingPOST(req: SymptomInfoReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getSeriesListUsingPOST.');
            }
            const localVarPath = `/api/symptom/info`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add or modify symptom of some cow
         * @summary add or modify symptom
         * @param {SymptomModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifySymptomUsingPOST(req: SymptomModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifySymptomUsingPOST.');
            }
            const localVarPath = `/api/symptom/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SymptomControllerApi - functional programming interface
 * @export
 */
export const SymptomControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary add treats of multiple cows
         * @param {SymptomBulkModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkModifyUsingPOST(req: SymptomBulkModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = SymptomControllerApiAxiosParamCreator(configuration).bulkModifyUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary delete symptom
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSymptomUsingPOST(req: ComRanchAndIdReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = SymptomControllerApiAxiosParamCreator(configuration).deleteSymptomUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get cert disease
         * @param {CertDiseaseReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertDiseaseUsingPOST(req: CertDiseaseReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseFileDownloadDto> {
            const localVarAxiosArgs = SymptomControllerApiAxiosParamCreator(configuration).getCertDiseaseUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get cert karte
         * @param {CertKarteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertKarteUsingPOST(req: CertKarteReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseFileDownloadDto> {
            const localVarAxiosArgs = SymptomControllerApiAxiosParamCreator(configuration).getCertKarteUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get symptom and breeding disease records
         * @param {DiseaseListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiseasesUsingPOST(req: DiseaseListReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListDiseaseListDto> {
            const localVarAxiosArgs = SymptomControllerApiAxiosParamCreator(configuration).getDiseasesUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get the symptom history
         * @summary symptom history
         * @param {SymptomHistoryReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoryUsingPOST(req: SymptomHistoryReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseSymptomHistoryDto> {
            const localVarAxiosArgs = SymptomControllerApiAxiosParamCreator(configuration).getHistoryUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get the symptom info
         * @summary symptom detail
         * @param {number} id id
         * @param {number} ranchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfo1UsingPOST2(id: number, ranchId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseSymptomDto> {
            const localVarAxiosArgs = SymptomControllerApiAxiosParamCreator(configuration).getInfo1UsingPOST2(id, ranchId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get content values for karte
         * @param {CertKarteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKarteContentUsingPOST(req: CertKarteReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseKarteContentDto> {
            const localVarAxiosArgs = SymptomControllerApiAxiosParamCreator(configuration).getKarteContentUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get latest treats for each cows
         * @param {SymptomLatestReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestUsingPOST(req: SymptomLatestReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListSympDataDto> {
            const localVarAxiosArgs = SymptomControllerApiAxiosParamCreator(configuration).getLatestUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get symptom and breeding records for karte
         * @param {KarteListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListForKarteUsingPOST(req: KarteListReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListKarteListDto> {
            const localVarAxiosArgs = SymptomControllerApiAxiosParamCreator(configuration).getListForKarteUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get program items witch will be generated by the bulk treat.
         * @param {ProgramAppliedItemsReqSymptomBulkModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramsForBulkTreatUsingPOST(req: ProgramAppliedItemsReqSymptomBulkModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseProgramApplyResultDto> {
            const localVarAxiosArgs = SymptomControllerApiAxiosParamCreator(configuration).getProgramsForBulkTreatUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get program items witch will be generated by the symptom event.
         * @param {ProgramAppliedItemsReqSymptomModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramsForSymptomUsingPOST(req: ProgramAppliedItemsReqSymptomModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseProgramApplyResultDto> {
            const localVarAxiosArgs = SymptomControllerApiAxiosParamCreator(configuration).getProgramsForSymptomUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get symptom series info
         * @summary get symptom series info
         * @param {SymptomInfoReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeriesListUsingPOST(req: SymptomInfoReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListSympEventDto> {
            const localVarAxiosArgs = SymptomControllerApiAxiosParamCreator(configuration).getSeriesListUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * add or modify symptom of some cow
         * @summary add or modify symptom
         * @param {SymptomModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifySymptomUsingPOST(req: SymptomModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = SymptomControllerApiAxiosParamCreator(configuration).modifySymptomUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SymptomControllerApi - factory interface
 * @export
 */
export const SymptomControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary add treats of multiple cows
         * @param {SymptomBulkModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkModifyUsingPOST(req: SymptomBulkModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return SymptomControllerApiFp(configuration).bulkModifyUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary delete symptom
         * @param {ComRanchAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSymptomUsingPOST(req: ComRanchAndIdReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return SymptomControllerApiFp(configuration).deleteSymptomUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary get cert disease
         * @param {CertDiseaseReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertDiseaseUsingPOST(req: CertDiseaseReq, options?: any): AxiosPromise<GenericResponseFileDownloadDto> {
            return SymptomControllerApiFp(configuration).getCertDiseaseUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary get cert karte
         * @param {CertKarteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertKarteUsingPOST(req: CertKarteReq, options?: any): AxiosPromise<GenericResponseFileDownloadDto> {
            return SymptomControllerApiFp(configuration).getCertKarteUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary get symptom and breeding disease records
         * @param {DiseaseListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiseasesUsingPOST(req: DiseaseListReq, options?: any): AxiosPromise<GenericResponseListDiseaseListDto> {
            return SymptomControllerApiFp(configuration).getDiseasesUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get the symptom history
         * @summary symptom history
         * @param {SymptomHistoryReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoryUsingPOST(req: SymptomHistoryReq, options?: any): AxiosPromise<GenericResponseSymptomHistoryDto> {
            return SymptomControllerApiFp(configuration).getHistoryUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get the symptom info
         * @summary symptom detail
         * @param {number} id id
         * @param {number} ranchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfo1UsingPOST2(id: number, ranchId: number, options?: any): AxiosPromise<GenericResponseSymptomDto> {
            return SymptomControllerApiFp(configuration).getInfo1UsingPOST2(id, ranchId, options)(axios, basePath);
        },
        /**
         * 
         * @summary get content values for karte
         * @param {CertKarteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKarteContentUsingPOST(req: CertKarteReq, options?: any): AxiosPromise<GenericResponseKarteContentDto> {
            return SymptomControllerApiFp(configuration).getKarteContentUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary get latest treats for each cows
         * @param {SymptomLatestReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestUsingPOST(req: SymptomLatestReq, options?: any): AxiosPromise<GenericResponseListSympDataDto> {
            return SymptomControllerApiFp(configuration).getLatestUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary get symptom and breeding records for karte
         * @param {KarteListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListForKarteUsingPOST(req: KarteListReq, options?: any): AxiosPromise<GenericResponseListKarteListDto> {
            return SymptomControllerApiFp(configuration).getListForKarteUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary get program items witch will be generated by the bulk treat.
         * @param {ProgramAppliedItemsReqSymptomBulkModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramsForBulkTreatUsingPOST(req: ProgramAppliedItemsReqSymptomBulkModifyReq, options?: any): AxiosPromise<GenericResponseProgramApplyResultDto> {
            return SymptomControllerApiFp(configuration).getProgramsForBulkTreatUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary get program items witch will be generated by the symptom event.
         * @param {ProgramAppliedItemsReqSymptomModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramsForSymptomUsingPOST(req: ProgramAppliedItemsReqSymptomModifyReq, options?: any): AxiosPromise<GenericResponseProgramApplyResultDto> {
            return SymptomControllerApiFp(configuration).getProgramsForSymptomUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get symptom series info
         * @summary get symptom series info
         * @param {SymptomInfoReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeriesListUsingPOST(req: SymptomInfoReq, options?: any): AxiosPromise<GenericResponseListSympEventDto> {
            return SymptomControllerApiFp(configuration).getSeriesListUsingPOST(req, options)(axios, basePath);
        },
        /**
         * add or modify symptom of some cow
         * @summary add or modify symptom
         * @param {SymptomModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifySymptomUsingPOST(req: SymptomModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return SymptomControllerApiFp(configuration).modifySymptomUsingPOST(req, options)(axios, basePath);
        },
    };
};

/**
 * SymptomControllerApi - object-oriented interface
 * @export
 * @class SymptomControllerApi
 * @extends {BaseAPI}
 */
export class SymptomControllerApi extends BaseAPI {
    /**
     * 
     * @summary add treats of multiple cows
     * @param {SymptomBulkModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SymptomControllerApi
     */
    public bulkModifyUsingPOST(req: SymptomBulkModifyReq, options?: any) {
        return SymptomControllerApiFp(this.configuration).bulkModifyUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary delete symptom
     * @param {ComRanchAndIdReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SymptomControllerApi
     */
    public deleteSymptomUsingPOST(req: ComRanchAndIdReq, options?: any) {
        return SymptomControllerApiFp(this.configuration).deleteSymptomUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get cert disease
     * @param {CertDiseaseReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SymptomControllerApi
     */
    public getCertDiseaseUsingPOST(req: CertDiseaseReq, options?: any) {
        return SymptomControllerApiFp(this.configuration).getCertDiseaseUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get cert karte
     * @param {CertKarteReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SymptomControllerApi
     */
    public getCertKarteUsingPOST(req: CertKarteReq, options?: any) {
        return SymptomControllerApiFp(this.configuration).getCertKarteUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get symptom and breeding disease records
     * @param {DiseaseListReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SymptomControllerApi
     */
    public getDiseasesUsingPOST(req: DiseaseListReq, options?: any) {
        return SymptomControllerApiFp(this.configuration).getDiseasesUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get the symptom history
     * @summary symptom history
     * @param {SymptomHistoryReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SymptomControllerApi
     */
    public getHistoryUsingPOST(req: SymptomHistoryReq, options?: any) {
        return SymptomControllerApiFp(this.configuration).getHistoryUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get the symptom info
     * @summary symptom detail
     * @param {number} id id
     * @param {number} ranchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SymptomControllerApi
     */
    public getInfo1UsingPOST2(id: number, ranchId: number, options?: any) {
        return SymptomControllerApiFp(this.configuration).getInfo1UsingPOST2(id, ranchId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get content values for karte
     * @param {CertKarteReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SymptomControllerApi
     */
    public getKarteContentUsingPOST(req: CertKarteReq, options?: any) {
        return SymptomControllerApiFp(this.configuration).getKarteContentUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get latest treats for each cows
     * @param {SymptomLatestReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SymptomControllerApi
     */
    public getLatestUsingPOST(req: SymptomLatestReq, options?: any) {
        return SymptomControllerApiFp(this.configuration).getLatestUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get symptom and breeding records for karte
     * @param {KarteListReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SymptomControllerApi
     */
    public getListForKarteUsingPOST(req: KarteListReq, options?: any) {
        return SymptomControllerApiFp(this.configuration).getListForKarteUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get program items witch will be generated by the bulk treat.
     * @param {ProgramAppliedItemsReqSymptomBulkModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SymptomControllerApi
     */
    public getProgramsForBulkTreatUsingPOST(req: ProgramAppliedItemsReqSymptomBulkModifyReq, options?: any) {
        return SymptomControllerApiFp(this.configuration).getProgramsForBulkTreatUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get program items witch will be generated by the symptom event.
     * @param {ProgramAppliedItemsReqSymptomModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SymptomControllerApi
     */
    public getProgramsForSymptomUsingPOST(req: ProgramAppliedItemsReqSymptomModifyReq, options?: any) {
        return SymptomControllerApiFp(this.configuration).getProgramsForSymptomUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get symptom series info
     * @summary get symptom series info
     * @param {SymptomInfoReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SymptomControllerApi
     */
    public getSeriesListUsingPOST(req: SymptomInfoReq, options?: any) {
        return SymptomControllerApiFp(this.configuration).getSeriesListUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * add or modify symptom of some cow
     * @summary add or modify symptom
     * @param {SymptomModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SymptomControllerApi
     */
    public modifySymptomUsingPOST(req: SymptomModifyReq, options?: any) {
        return SymptomControllerApiFp(this.configuration).modifySymptomUsingPOST(req, options)(this.axios, this.basePath);
    }

}


/**
 * TeamControllerApi - axios parameter creator
 * @export
 */
export const TeamControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * accept the team request
         * @summary accept team request
         * @param {TeamRequestAcceptReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptRequestUsingPOST(req: TeamRequestAcceptReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling acceptRequestUsingPOST.');
            }
            const localVarPath = `/api/team/request/accept`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add the team request
         * @summary add team request
         * @param {TeamRequestAddReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRequestUsingPOST(req: TeamRequestAddReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling addRequestUsingPOST.');
            }
            const localVarPath = `/api/team/request/add`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update ancestor as deleted
         * @param {ComTeamAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAncestorUsingPOST(req: ComTeamAndIdReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deleteAncestorUsingPOST.');
            }
            const localVarPath = `/api/team/ancestor/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete the team auth
         * @summary delete team auth
         * @param {TeamAuthDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAuthUsingPOST(req: TeamAuthDeleteReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deleteAuthUsingPOST.');
            }
            const localVarPath = `/api/team/auth/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete the team medicine route
         * @summary delete team medicine route
         * @param {TeamMedicineRouteDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMedicineRouteUsingPOST(req: TeamMedicineRouteDeleteReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deleteMedicineRouteUsingPOST.');
            }
            const localVarPath = `/api/team/medicine/route/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete the team medicine
         * @summary delete team medicine
         * @param {TeamMedicineDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMedicineUsingPOST(req: TeamMedicineDeleteReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deleteMedicineUsingPOST.');
            }
            const localVarPath = `/api/team/medicine/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update partner as deleted
         * @param {ComTeamAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePartnerUsingPOST(req: ComTeamAndIdReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deletePartnerUsingPOST.');
            }
            const localVarPath = `/api/team/partner/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete the team request
         * @summary delete team request
         * @param {TeamRequestDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRequestUsingPOST(req: TeamRequestDeleteReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deleteRequestUsingPOST.');
            }
            const localVarPath = `/api/team/request/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete team
         * @param {ComTeamIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeamUsingPOST(req: ComTeamIdReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deleteTeamUsingPOST.');
            }
            const localVarPath = `/api/team/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete the team condition
         * @summary delete team condition
         * @param {TeamConditionDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTreatItemUsingPOST(req: TeamConditionDeleteReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deleteTreatItemUsingPOST.');
            }
            const localVarPath = `/api/team/condition/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete the team treat item
         * @summary delete team treat item
         * @param {TeamTreatItemDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTreatItemUsingPOST1(req: TeamTreatItemDeleteReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling deleteTreatItemUsingPOST1.');
            }
            const localVarPath = `/api/team/treat/item/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete preset
         * @param {TeamTreatPresetDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detelePresetUsingPOST(req: TeamTreatPresetDeleteReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling detelePresetUsingPOST.');
            }
            const localVarPath = `/api/team/treat/preset/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the team name
         * @summary get team name
         * @param {TeamNameReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNameUsingPOST(req: TeamNameReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getNameUsingPOST.');
            }
            const localVarPath = `/api/team/name`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get team setting
         * @param {ComTeamIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingUsingPOST(req: ComTeamIdReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling getSettingUsingPOST.');
            }
            const localVarPath = `/api/team/setting/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list ancestors
         * @param {ComTeamIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAncestorsUsingPOST(req: ComTeamIdReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling listAncestorsUsingPOST.');
            }
            const localVarPath = `/api/team/ancestor/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the condition info
         * @summary condition info
         * @param {ComTeamIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConditionUsingPOST(req: ComTeamIdReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling listConditionUsingPOST.');
            }
            const localVarPath = `/api/team/condition/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the medicine route info
         * @summary medicine route info
         * @param {ComTeamIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMedicineRouteUsingPOST(req: ComTeamIdReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling listMedicineRouteUsingPOST.');
            }
            const localVarPath = `/api/team/medicine/route/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the medicine info
         * @summary medicine info
         * @param {ComTeamIdAndSinceReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMedicineUsingPOST(req: ComTeamIdAndSinceReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling listMedicineUsingPOST.');
            }
            const localVarPath = `/api/team/medicine/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list partners
         * @param {PartnerListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPartnersUsingPOST(req: PartnerListReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling listPartnersUsingPOST.');
            }
            const localVarPath = `/api/team/partner/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list treat preset
         * @param {ComTeamIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPresetUsingPOST(req: ComTeamIdReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling listPresetUsingPOST.');
            }
            const localVarPath = `/api/team/treat/preset/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list current process states
         * @param {ComTeamIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProcessUsingPOST(req: ComTeamIdReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling listProcessUsingPOST.');
            }
            const localVarPath = `/api/team/process/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get diseases and causes list
         * @param {TeamDiseaseReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTeamDiseasesUsingPOST(req: TeamDiseaseReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling listTeamDiseasesUsingPOST.');
            }
            const localVarPath = `/api/team/disease/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the treat item info
         * @summary treat item info
         * @param {TeamTreatItemListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTreatItemUsingPOST(req: TeamTreatItemListReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling listTreatItemUsingPOST.');
            }
            const localVarPath = `/api/team/treat/item/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list the user
         * @summary list user
         * @param {TeamUserListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserUsingPOST(req: TeamUserListReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling listUserUsingPOST.');
            }
            const localVarPath = `/api/team/user/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary insert or update ancestor
         * @param {ExtraCowModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyAncestorUsingPOST(req: ExtraCowModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyAncestorUsingPOST.');
            }
            const localVarPath = `/api/team/ancestor/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * modify the team auth
         * @summary modify team auth
         * @param {TeamAuthModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyAuthUsingPOST(req: TeamAuthModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyAuthUsingPOST.');
            }
            const localVarPath = `/api/team/auth/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * modify the team condition
         * @summary modify team condition
         * @param {TeamConditionModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyConditionUsingPOST(req: TeamConditionModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyConditionUsingPOST.');
            }
            const localVarPath = `/api/team/condition/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * modify the team medicine route
         * @summary modify team medicine route
         * @param {TeamMedicineRouteModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyMedicineRouteUsingPOST(req: TeamMedicineRouteModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyMedicineRouteUsingPOST.');
            }
            const localVarPath = `/api/team/medicine/route/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * modify the team medicine list
         * @summary modify team medicine
         * @param {TeamMedicineModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyMedicineUsingPOST(req: TeamMedicineModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyMedicineUsingPOST.');
            }
            const localVarPath = `/api/team/medicine/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary insert or update partner
         * @param {PartnerModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyPartnerUsingPOST(req: PartnerModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyPartnerUsingPOST.');
            }
            const localVarPath = `/api/team/partner/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary modify treat preset
         * @param {TeamTreatPresetModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyPresetUsingPOST(req: TeamTreatPresetModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyPresetUsingPOST.');
            }
            const localVarPath = `/api/team/treat/preset/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary modify seed stock\'s tag
         * @param {TeamSeedTagModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifySeedTagUsingPOST(req: TeamSeedTagModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifySeedTagUsingPOST.');
            }
            const localVarPath = `/api/team/seed/stock/tag`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary modify team setting
         * @param {TeamSettingModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifySettingUsingPOST(req: TeamSettingModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifySettingUsingPOST.');
            }
            const localVarPath = `/api/team/setting/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update disease cause relation
         * @param {TeamDiseaseModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyTeamDiseaseUsingPOST(req: TeamDiseaseModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyTeamDiseaseUsingPOST.');
            }
            const localVarPath = `/api/team/disease/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary modify team info
         * @param {TeamModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyTeamUsingPOST(req: TeamModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyTeamUsingPOST.');
            }
            const localVarPath = `/api/team/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * modify the team treat item
         * @summary modify team treat item
         * @param {TeamTreatItemModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyTreatItemUsingPOST(req: TeamTreatItemModifyReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling modifyTreatItemUsingPOST.');
            }
            const localVarPath = `/api/team/treat/item/modify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sort ancestors
         * @param {ComTeamSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortAncestorsUsingPOST(req: ComTeamSortReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling sortAncestorsUsingPOST.');
            }
            const localVarPath = `/api/team/ancestor/sort`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * modify the team condition bookmark
         * @summary modify team condition bookmark
         * @param {TeamConditionBookmarkReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortConditionBookmarkUsingPOST(req: TeamConditionBookmarkReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling sortConditionBookmarkUsingPOST.');
            }
            const localVarPath = `/api/team/condition/bookmark`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * sort disp no of team condition
         * @summary sort disp no of team condition
         * @param {TeamConditionSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortConditionUsingPOST(req: TeamConditionSortReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling sortConditionUsingPOST.');
            }
            const localVarPath = `/api/team/condition/sort`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * sort disp no of team medicine route
         * @summary sort disp no of team medicine route
         * @param {TeamMedicineRouteSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortMedicineRouteUsingPOST(req: TeamMedicineRouteSortReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling sortMedicineRouteUsingPOST.');
            }
            const localVarPath = `/api/team/medicine/route/sort`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * sort disp no of team medicine
         * @summary sort disp no of team medicine
         * @param {TeamMedicineSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortMedicineUsingPOST(req: TeamMedicineSortReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling sortMedicineUsingPOST.');
            }
            const localVarPath = `/api/team/medicine/sort`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sort partners
         * @param {PartnerSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortPartnersUsingPOST(req: PartnerSortReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling sortPartnersUsingPOST.');
            }
            const localVarPath = `/api/team/partner/sort`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sort treat presets
         * @param {ComTeamSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortPresetUsingPOST(req: ComTeamSortReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling sortPresetUsingPOST.');
            }
            const localVarPath = `/api/team/treat/preset/sort`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * sort disp no of team treat item
         * @summary sort disp no of team treat item
         * @param {TeamTreatItemSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortTreatItemUsingPOST(req: TeamTreatItemSortReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling sortTreatItemUsingPOST.');
            }
            const localVarPath = `/api/team/treat/item/sort`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeamControllerApi - functional programming interface
 * @export
 */
export const TeamControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * accept the team request
         * @summary accept team request
         * @param {TeamRequestAcceptReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptRequestUsingPOST(req: TeamRequestAcceptReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).acceptRequestUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * add the team request
         * @summary add team request
         * @param {TeamRequestAddReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRequestUsingPOST(req: TeamRequestAddReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).addRequestUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary update ancestor as deleted
         * @param {ComTeamAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAncestorUsingPOST(req: ComTeamAndIdReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).deleteAncestorUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * delete the team auth
         * @summary delete team auth
         * @param {TeamAuthDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAuthUsingPOST(req: TeamAuthDeleteReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).deleteAuthUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * delete the team medicine route
         * @summary delete team medicine route
         * @param {TeamMedicineRouteDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMedicineRouteUsingPOST(req: TeamMedicineRouteDeleteReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).deleteMedicineRouteUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * delete the team medicine
         * @summary delete team medicine
         * @param {TeamMedicineDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMedicineUsingPOST(req: TeamMedicineDeleteReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).deleteMedicineUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary update partner as deleted
         * @param {ComTeamAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePartnerUsingPOST(req: ComTeamAndIdReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).deletePartnerUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * delete the team request
         * @summary delete team request
         * @param {TeamRequestDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRequestUsingPOST(req: TeamRequestDeleteReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).deleteRequestUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary delete team
         * @param {ComTeamIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeamUsingPOST(req: ComTeamIdReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).deleteTeamUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * delete the team condition
         * @summary delete team condition
         * @param {TeamConditionDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTreatItemUsingPOST(req: TeamConditionDeleteReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).deleteTreatItemUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * delete the team treat item
         * @summary delete team treat item
         * @param {TeamTreatItemDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTreatItemUsingPOST1(req: TeamTreatItemDeleteReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).deleteTreatItemUsingPOST1(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary delete preset
         * @param {TeamTreatPresetDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detelePresetUsingPOST(req: TeamTreatPresetDeleteReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).detelePresetUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get the team name
         * @summary get team name
         * @param {TeamNameReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNameUsingPOST(req: TeamNameReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseTeamNameDto> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).getNameUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get team setting
         * @param {ComTeamIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingUsingPOST(req: ComTeamIdReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseTeamSettingDto> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).getSettingUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary list ancestors
         * @param {ComTeamIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAncestorsUsingPOST(req: ComTeamIdReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListExtraCowDto> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).listAncestorsUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get the condition info
         * @summary condition info
         * @param {ComTeamIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConditionUsingPOST(req: ComTeamIdReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseCollectionTeamConditionDto> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).listConditionUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get the medicine route info
         * @summary medicine route info
         * @param {ComTeamIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMedicineRouteUsingPOST(req: ComTeamIdReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseTeamMedicineRouteListDto> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).listMedicineRouteUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get the medicine info
         * @summary medicine info
         * @param {ComTeamIdAndSinceReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMedicineUsingPOST(req: ComTeamIdAndSinceReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseTeamMedicineListDto> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).listMedicineUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary list partners
         * @param {PartnerListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPartnersUsingPOST(req: PartnerListReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListPartnerDto> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).listPartnersUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary list treat preset
         * @param {ComTeamIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPresetUsingPOST(req: ComTeamIdReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListTeamTreatPresetDto> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).listPresetUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary list current process states
         * @param {ComTeamIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProcessUsingPOST(req: ComTeamIdReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListTeamProcessDto> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).listProcessUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get diseases and causes list
         * @param {TeamDiseaseReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTeamDiseasesUsingPOST(req: TeamDiseaseReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseListTeamDiseaseListDto> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).listTeamDiseasesUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get the treat item info
         * @summary treat item info
         * @param {TeamTreatItemListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTreatItemUsingPOST(req: TeamTreatItemListReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseTeamTreatItemListDto> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).listTreatItemUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * list the user
         * @summary list user
         * @param {TeamUserListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserUsingPOST(req: TeamUserListReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseTeamUsersDto> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).listUserUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary insert or update ancestor
         * @param {ExtraCowModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyAncestorUsingPOST(req: ExtraCowModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseComIdDto> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).modifyAncestorUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * modify the team auth
         * @summary modify team auth
         * @param {TeamAuthModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyAuthUsingPOST(req: TeamAuthModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).modifyAuthUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * modify the team condition
         * @summary modify team condition
         * @param {TeamConditionModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyConditionUsingPOST(req: TeamConditionModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).modifyConditionUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * modify the team medicine route
         * @summary modify team medicine route
         * @param {TeamMedicineRouteModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyMedicineRouteUsingPOST(req: TeamMedicineRouteModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).modifyMedicineRouteUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * modify the team medicine list
         * @summary modify team medicine
         * @param {TeamMedicineModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyMedicineUsingPOST(req: TeamMedicineModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).modifyMedicineUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary insert or update partner
         * @param {PartnerModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyPartnerUsingPOST(req: PartnerModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseComIdDto> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).modifyPartnerUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary modify treat preset
         * @param {TeamTreatPresetModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyPresetUsingPOST(req: TeamTreatPresetModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseComIdDto> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).modifyPresetUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary modify seed stock\'s tag
         * @param {TeamSeedTagModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifySeedTagUsingPOST(req: TeamSeedTagModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).modifySeedTagUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary modify team setting
         * @param {TeamSettingModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifySettingUsingPOST(req: TeamSettingModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).modifySettingUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary update disease cause relation
         * @param {TeamDiseaseModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyTeamDiseaseUsingPOST(req: TeamDiseaseModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).modifyTeamDiseaseUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary modify team info
         * @param {TeamModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyTeamUsingPOST(req: TeamModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).modifyTeamUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * modify the team treat item
         * @summary modify team treat item
         * @param {TeamTreatItemModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyTreatItemUsingPOST(req: TeamTreatItemModifyReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).modifyTreatItemUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary sort ancestors
         * @param {ComTeamSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortAncestorsUsingPOST(req: ComTeamSortReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).sortAncestorsUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * modify the team condition bookmark
         * @summary modify team condition bookmark
         * @param {TeamConditionBookmarkReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortConditionBookmarkUsingPOST(req: TeamConditionBookmarkReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).sortConditionBookmarkUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * sort disp no of team condition
         * @summary sort disp no of team condition
         * @param {TeamConditionSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortConditionUsingPOST(req: TeamConditionSortReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).sortConditionUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * sort disp no of team medicine route
         * @summary sort disp no of team medicine route
         * @param {TeamMedicineRouteSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortMedicineRouteUsingPOST(req: TeamMedicineRouteSortReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).sortMedicineRouteUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * sort disp no of team medicine
         * @summary sort disp no of team medicine
         * @param {TeamMedicineSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortMedicineUsingPOST(req: TeamMedicineSortReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).sortMedicineUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary sort partners
         * @param {PartnerSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortPartnersUsingPOST(req: PartnerSortReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).sortPartnersUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary sort treat presets
         * @param {ComTeamSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortPresetUsingPOST(req: ComTeamSortReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).sortPresetUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * sort disp no of team treat item
         * @summary sort disp no of team treat item
         * @param {TeamTreatItemSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortTreatItemUsingPOST(req: TeamTreatItemSortReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = TeamControllerApiAxiosParamCreator(configuration).sortTreatItemUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TeamControllerApi - factory interface
 * @export
 */
export const TeamControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * accept the team request
         * @summary accept team request
         * @param {TeamRequestAcceptReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptRequestUsingPOST(req: TeamRequestAcceptReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return TeamControllerApiFp(configuration).acceptRequestUsingPOST(req, options)(axios, basePath);
        },
        /**
         * add the team request
         * @summary add team request
         * @param {TeamRequestAddReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRequestUsingPOST(req: TeamRequestAddReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return TeamControllerApiFp(configuration).addRequestUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary update ancestor as deleted
         * @param {ComTeamAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAncestorUsingPOST(req: ComTeamAndIdReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return TeamControllerApiFp(configuration).deleteAncestorUsingPOST(req, options)(axios, basePath);
        },
        /**
         * delete the team auth
         * @summary delete team auth
         * @param {TeamAuthDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAuthUsingPOST(req: TeamAuthDeleteReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return TeamControllerApiFp(configuration).deleteAuthUsingPOST(req, options)(axios, basePath);
        },
        /**
         * delete the team medicine route
         * @summary delete team medicine route
         * @param {TeamMedicineRouteDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMedicineRouteUsingPOST(req: TeamMedicineRouteDeleteReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return TeamControllerApiFp(configuration).deleteMedicineRouteUsingPOST(req, options)(axios, basePath);
        },
        /**
         * delete the team medicine
         * @summary delete team medicine
         * @param {TeamMedicineDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMedicineUsingPOST(req: TeamMedicineDeleteReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return TeamControllerApiFp(configuration).deleteMedicineUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary update partner as deleted
         * @param {ComTeamAndIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePartnerUsingPOST(req: ComTeamAndIdReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return TeamControllerApiFp(configuration).deletePartnerUsingPOST(req, options)(axios, basePath);
        },
        /**
         * delete the team request
         * @summary delete team request
         * @param {TeamRequestDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRequestUsingPOST(req: TeamRequestDeleteReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return TeamControllerApiFp(configuration).deleteRequestUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary delete team
         * @param {ComTeamIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeamUsingPOST(req: ComTeamIdReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return TeamControllerApiFp(configuration).deleteTeamUsingPOST(req, options)(axios, basePath);
        },
        /**
         * delete the team condition
         * @summary delete team condition
         * @param {TeamConditionDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTreatItemUsingPOST(req: TeamConditionDeleteReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return TeamControllerApiFp(configuration).deleteTreatItemUsingPOST(req, options)(axios, basePath);
        },
        /**
         * delete the team treat item
         * @summary delete team treat item
         * @param {TeamTreatItemDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTreatItemUsingPOST1(req: TeamTreatItemDeleteReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return TeamControllerApiFp(configuration).deleteTreatItemUsingPOST1(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary delete preset
         * @param {TeamTreatPresetDeleteReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detelePresetUsingPOST(req: TeamTreatPresetDeleteReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return TeamControllerApiFp(configuration).detelePresetUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get the team name
         * @summary get team name
         * @param {TeamNameReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNameUsingPOST(req: TeamNameReq, options?: any): AxiosPromise<GenericResponseTeamNameDto> {
            return TeamControllerApiFp(configuration).getNameUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary get team setting
         * @param {ComTeamIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingUsingPOST(req: ComTeamIdReq, options?: any): AxiosPromise<GenericResponseTeamSettingDto> {
            return TeamControllerApiFp(configuration).getSettingUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary list ancestors
         * @param {ComTeamIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAncestorsUsingPOST(req: ComTeamIdReq, options?: any): AxiosPromise<GenericResponseListExtraCowDto> {
            return TeamControllerApiFp(configuration).listAncestorsUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get the condition info
         * @summary condition info
         * @param {ComTeamIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConditionUsingPOST(req: ComTeamIdReq, options?: any): AxiosPromise<GenericResponseCollectionTeamConditionDto> {
            return TeamControllerApiFp(configuration).listConditionUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get the medicine route info
         * @summary medicine route info
         * @param {ComTeamIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMedicineRouteUsingPOST(req: ComTeamIdReq, options?: any): AxiosPromise<GenericResponseTeamMedicineRouteListDto> {
            return TeamControllerApiFp(configuration).listMedicineRouteUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get the medicine info
         * @summary medicine info
         * @param {ComTeamIdAndSinceReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMedicineUsingPOST(req: ComTeamIdAndSinceReq, options?: any): AxiosPromise<GenericResponseTeamMedicineListDto> {
            return TeamControllerApiFp(configuration).listMedicineUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary list partners
         * @param {PartnerListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPartnersUsingPOST(req: PartnerListReq, options?: any): AxiosPromise<GenericResponseListPartnerDto> {
            return TeamControllerApiFp(configuration).listPartnersUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary list treat preset
         * @param {ComTeamIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPresetUsingPOST(req: ComTeamIdReq, options?: any): AxiosPromise<GenericResponseListTeamTreatPresetDto> {
            return TeamControllerApiFp(configuration).listPresetUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary list current process states
         * @param {ComTeamIdReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProcessUsingPOST(req: ComTeamIdReq, options?: any): AxiosPromise<GenericResponseListTeamProcessDto> {
            return TeamControllerApiFp(configuration).listProcessUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary get diseases and causes list
         * @param {TeamDiseaseReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTeamDiseasesUsingPOST(req: TeamDiseaseReq, options?: any): AxiosPromise<GenericResponseListTeamDiseaseListDto> {
            return TeamControllerApiFp(configuration).listTeamDiseasesUsingPOST(req, options)(axios, basePath);
        },
        /**
         * get the treat item info
         * @summary treat item info
         * @param {TeamTreatItemListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTreatItemUsingPOST(req: TeamTreatItemListReq, options?: any): AxiosPromise<GenericResponseTeamTreatItemListDto> {
            return TeamControllerApiFp(configuration).listTreatItemUsingPOST(req, options)(axios, basePath);
        },
        /**
         * list the user
         * @summary list user
         * @param {TeamUserListReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserUsingPOST(req: TeamUserListReq, options?: any): AxiosPromise<GenericResponseTeamUsersDto> {
            return TeamControllerApiFp(configuration).listUserUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary insert or update ancestor
         * @param {ExtraCowModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyAncestorUsingPOST(req: ExtraCowModifyReq, options?: any): AxiosPromise<GenericResponseComIdDto> {
            return TeamControllerApiFp(configuration).modifyAncestorUsingPOST(req, options)(axios, basePath);
        },
        /**
         * modify the team auth
         * @summary modify team auth
         * @param {TeamAuthModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyAuthUsingPOST(req: TeamAuthModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return TeamControllerApiFp(configuration).modifyAuthUsingPOST(req, options)(axios, basePath);
        },
        /**
         * modify the team condition
         * @summary modify team condition
         * @param {TeamConditionModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyConditionUsingPOST(req: TeamConditionModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return TeamControllerApiFp(configuration).modifyConditionUsingPOST(req, options)(axios, basePath);
        },
        /**
         * modify the team medicine route
         * @summary modify team medicine route
         * @param {TeamMedicineRouteModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyMedicineRouteUsingPOST(req: TeamMedicineRouteModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return TeamControllerApiFp(configuration).modifyMedicineRouteUsingPOST(req, options)(axios, basePath);
        },
        /**
         * modify the team medicine list
         * @summary modify team medicine
         * @param {TeamMedicineModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyMedicineUsingPOST(req: TeamMedicineModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return TeamControllerApiFp(configuration).modifyMedicineUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary insert or update partner
         * @param {PartnerModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyPartnerUsingPOST(req: PartnerModifyReq, options?: any): AxiosPromise<GenericResponseComIdDto> {
            return TeamControllerApiFp(configuration).modifyPartnerUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary modify treat preset
         * @param {TeamTreatPresetModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyPresetUsingPOST(req: TeamTreatPresetModifyReq, options?: any): AxiosPromise<GenericResponseComIdDto> {
            return TeamControllerApiFp(configuration).modifyPresetUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary modify seed stock\'s tag
         * @param {TeamSeedTagModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifySeedTagUsingPOST(req: TeamSeedTagModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return TeamControllerApiFp(configuration).modifySeedTagUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary modify team setting
         * @param {TeamSettingModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifySettingUsingPOST(req: TeamSettingModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return TeamControllerApiFp(configuration).modifySettingUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary update disease cause relation
         * @param {TeamDiseaseModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyTeamDiseaseUsingPOST(req: TeamDiseaseModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return TeamControllerApiFp(configuration).modifyTeamDiseaseUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary modify team info
         * @param {TeamModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyTeamUsingPOST(req: TeamModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return TeamControllerApiFp(configuration).modifyTeamUsingPOST(req, options)(axios, basePath);
        },
        /**
         * modify the team treat item
         * @summary modify team treat item
         * @param {TeamTreatItemModifyReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyTreatItemUsingPOST(req: TeamTreatItemModifyReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return TeamControllerApiFp(configuration).modifyTreatItemUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary sort ancestors
         * @param {ComTeamSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortAncestorsUsingPOST(req: ComTeamSortReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return TeamControllerApiFp(configuration).sortAncestorsUsingPOST(req, options)(axios, basePath);
        },
        /**
         * modify the team condition bookmark
         * @summary modify team condition bookmark
         * @param {TeamConditionBookmarkReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortConditionBookmarkUsingPOST(req: TeamConditionBookmarkReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return TeamControllerApiFp(configuration).sortConditionBookmarkUsingPOST(req, options)(axios, basePath);
        },
        /**
         * sort disp no of team condition
         * @summary sort disp no of team condition
         * @param {TeamConditionSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortConditionUsingPOST(req: TeamConditionSortReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return TeamControllerApiFp(configuration).sortConditionUsingPOST(req, options)(axios, basePath);
        },
        /**
         * sort disp no of team medicine route
         * @summary sort disp no of team medicine route
         * @param {TeamMedicineRouteSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortMedicineRouteUsingPOST(req: TeamMedicineRouteSortReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return TeamControllerApiFp(configuration).sortMedicineRouteUsingPOST(req, options)(axios, basePath);
        },
        /**
         * sort disp no of team medicine
         * @summary sort disp no of team medicine
         * @param {TeamMedicineSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortMedicineUsingPOST(req: TeamMedicineSortReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return TeamControllerApiFp(configuration).sortMedicineUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary sort partners
         * @param {PartnerSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortPartnersUsingPOST(req: PartnerSortReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return TeamControllerApiFp(configuration).sortPartnersUsingPOST(req, options)(axios, basePath);
        },
        /**
         * 
         * @summary sort treat presets
         * @param {ComTeamSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortPresetUsingPOST(req: ComTeamSortReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return TeamControllerApiFp(configuration).sortPresetUsingPOST(req, options)(axios, basePath);
        },
        /**
         * sort disp no of team treat item
         * @summary sort disp no of team treat item
         * @param {TeamTreatItemSortReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortTreatItemUsingPOST(req: TeamTreatItemSortReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return TeamControllerApiFp(configuration).sortTreatItemUsingPOST(req, options)(axios, basePath);
        },
    };
};

/**
 * TeamControllerApi - object-oriented interface
 * @export
 * @class TeamControllerApi
 * @extends {BaseAPI}
 */
export class TeamControllerApi extends BaseAPI {
    /**
     * accept the team request
     * @summary accept team request
     * @param {TeamRequestAcceptReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public acceptRequestUsingPOST(req: TeamRequestAcceptReq, options?: any) {
        return TeamControllerApiFp(this.configuration).acceptRequestUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * add the team request
     * @summary add team request
     * @param {TeamRequestAddReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public addRequestUsingPOST(req: TeamRequestAddReq, options?: any) {
        return TeamControllerApiFp(this.configuration).addRequestUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary update ancestor as deleted
     * @param {ComTeamAndIdReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public deleteAncestorUsingPOST(req: ComTeamAndIdReq, options?: any) {
        return TeamControllerApiFp(this.configuration).deleteAncestorUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * delete the team auth
     * @summary delete team auth
     * @param {TeamAuthDeleteReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public deleteAuthUsingPOST(req: TeamAuthDeleteReq, options?: any) {
        return TeamControllerApiFp(this.configuration).deleteAuthUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * delete the team medicine route
     * @summary delete team medicine route
     * @param {TeamMedicineRouteDeleteReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public deleteMedicineRouteUsingPOST(req: TeamMedicineRouteDeleteReq, options?: any) {
        return TeamControllerApiFp(this.configuration).deleteMedicineRouteUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * delete the team medicine
     * @summary delete team medicine
     * @param {TeamMedicineDeleteReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public deleteMedicineUsingPOST(req: TeamMedicineDeleteReq, options?: any) {
        return TeamControllerApiFp(this.configuration).deleteMedicineUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary update partner as deleted
     * @param {ComTeamAndIdReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public deletePartnerUsingPOST(req: ComTeamAndIdReq, options?: any) {
        return TeamControllerApiFp(this.configuration).deletePartnerUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * delete the team request
     * @summary delete team request
     * @param {TeamRequestDeleteReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public deleteRequestUsingPOST(req: TeamRequestDeleteReq, options?: any) {
        return TeamControllerApiFp(this.configuration).deleteRequestUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary delete team
     * @param {ComTeamIdReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public deleteTeamUsingPOST(req: ComTeamIdReq, options?: any) {
        return TeamControllerApiFp(this.configuration).deleteTeamUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * delete the team condition
     * @summary delete team condition
     * @param {TeamConditionDeleteReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public deleteTreatItemUsingPOST(req: TeamConditionDeleteReq, options?: any) {
        return TeamControllerApiFp(this.configuration).deleteTreatItemUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * delete the team treat item
     * @summary delete team treat item
     * @param {TeamTreatItemDeleteReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public deleteTreatItemUsingPOST1(req: TeamTreatItemDeleteReq, options?: any) {
        return TeamControllerApiFp(this.configuration).deleteTreatItemUsingPOST1(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary delete preset
     * @param {TeamTreatPresetDeleteReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public detelePresetUsingPOST(req: TeamTreatPresetDeleteReq, options?: any) {
        return TeamControllerApiFp(this.configuration).detelePresetUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get the team name
     * @summary get team name
     * @param {TeamNameReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public getNameUsingPOST(req: TeamNameReq, options?: any) {
        return TeamControllerApiFp(this.configuration).getNameUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get team setting
     * @param {ComTeamIdReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public getSettingUsingPOST(req: ComTeamIdReq, options?: any) {
        return TeamControllerApiFp(this.configuration).getSettingUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary list ancestors
     * @param {ComTeamIdReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public listAncestorsUsingPOST(req: ComTeamIdReq, options?: any) {
        return TeamControllerApiFp(this.configuration).listAncestorsUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get the condition info
     * @summary condition info
     * @param {ComTeamIdReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public listConditionUsingPOST(req: ComTeamIdReq, options?: any) {
        return TeamControllerApiFp(this.configuration).listConditionUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get the medicine route info
     * @summary medicine route info
     * @param {ComTeamIdReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public listMedicineRouteUsingPOST(req: ComTeamIdReq, options?: any) {
        return TeamControllerApiFp(this.configuration).listMedicineRouteUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get the medicine info
     * @summary medicine info
     * @param {ComTeamIdAndSinceReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public listMedicineUsingPOST(req: ComTeamIdAndSinceReq, options?: any) {
        return TeamControllerApiFp(this.configuration).listMedicineUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary list partners
     * @param {PartnerListReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public listPartnersUsingPOST(req: PartnerListReq, options?: any) {
        return TeamControllerApiFp(this.configuration).listPartnersUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary list treat preset
     * @param {ComTeamIdReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public listPresetUsingPOST(req: ComTeamIdReq, options?: any) {
        return TeamControllerApiFp(this.configuration).listPresetUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary list current process states
     * @param {ComTeamIdReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public listProcessUsingPOST(req: ComTeamIdReq, options?: any) {
        return TeamControllerApiFp(this.configuration).listProcessUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary get diseases and causes list
     * @param {TeamDiseaseReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public listTeamDiseasesUsingPOST(req: TeamDiseaseReq, options?: any) {
        return TeamControllerApiFp(this.configuration).listTeamDiseasesUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * get the treat item info
     * @summary treat item info
     * @param {TeamTreatItemListReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public listTreatItemUsingPOST(req: TeamTreatItemListReq, options?: any) {
        return TeamControllerApiFp(this.configuration).listTreatItemUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * list the user
     * @summary list user
     * @param {TeamUserListReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public listUserUsingPOST(req: TeamUserListReq, options?: any) {
        return TeamControllerApiFp(this.configuration).listUserUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary insert or update ancestor
     * @param {ExtraCowModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public modifyAncestorUsingPOST(req: ExtraCowModifyReq, options?: any) {
        return TeamControllerApiFp(this.configuration).modifyAncestorUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * modify the team auth
     * @summary modify team auth
     * @param {TeamAuthModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public modifyAuthUsingPOST(req: TeamAuthModifyReq, options?: any) {
        return TeamControllerApiFp(this.configuration).modifyAuthUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * modify the team condition
     * @summary modify team condition
     * @param {TeamConditionModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public modifyConditionUsingPOST(req: TeamConditionModifyReq, options?: any) {
        return TeamControllerApiFp(this.configuration).modifyConditionUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * modify the team medicine route
     * @summary modify team medicine route
     * @param {TeamMedicineRouteModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public modifyMedicineRouteUsingPOST(req: TeamMedicineRouteModifyReq, options?: any) {
        return TeamControllerApiFp(this.configuration).modifyMedicineRouteUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * modify the team medicine list
     * @summary modify team medicine
     * @param {TeamMedicineModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public modifyMedicineUsingPOST(req: TeamMedicineModifyReq, options?: any) {
        return TeamControllerApiFp(this.configuration).modifyMedicineUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary insert or update partner
     * @param {PartnerModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public modifyPartnerUsingPOST(req: PartnerModifyReq, options?: any) {
        return TeamControllerApiFp(this.configuration).modifyPartnerUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary modify treat preset
     * @param {TeamTreatPresetModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public modifyPresetUsingPOST(req: TeamTreatPresetModifyReq, options?: any) {
        return TeamControllerApiFp(this.configuration).modifyPresetUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary modify seed stock\'s tag
     * @param {TeamSeedTagModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public modifySeedTagUsingPOST(req: TeamSeedTagModifyReq, options?: any) {
        return TeamControllerApiFp(this.configuration).modifySeedTagUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary modify team setting
     * @param {TeamSettingModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public modifySettingUsingPOST(req: TeamSettingModifyReq, options?: any) {
        return TeamControllerApiFp(this.configuration).modifySettingUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary update disease cause relation
     * @param {TeamDiseaseModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public modifyTeamDiseaseUsingPOST(req: TeamDiseaseModifyReq, options?: any) {
        return TeamControllerApiFp(this.configuration).modifyTeamDiseaseUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary modify team info
     * @param {TeamModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public modifyTeamUsingPOST(req: TeamModifyReq, options?: any) {
        return TeamControllerApiFp(this.configuration).modifyTeamUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * modify the team treat item
     * @summary modify team treat item
     * @param {TeamTreatItemModifyReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public modifyTreatItemUsingPOST(req: TeamTreatItemModifyReq, options?: any) {
        return TeamControllerApiFp(this.configuration).modifyTreatItemUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary sort ancestors
     * @param {ComTeamSortReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public sortAncestorsUsingPOST(req: ComTeamSortReq, options?: any) {
        return TeamControllerApiFp(this.configuration).sortAncestorsUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * modify the team condition bookmark
     * @summary modify team condition bookmark
     * @param {TeamConditionBookmarkReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public sortConditionBookmarkUsingPOST(req: TeamConditionBookmarkReq, options?: any) {
        return TeamControllerApiFp(this.configuration).sortConditionBookmarkUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * sort disp no of team condition
     * @summary sort disp no of team condition
     * @param {TeamConditionSortReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public sortConditionUsingPOST(req: TeamConditionSortReq, options?: any) {
        return TeamControllerApiFp(this.configuration).sortConditionUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * sort disp no of team medicine route
     * @summary sort disp no of team medicine route
     * @param {TeamMedicineRouteSortReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public sortMedicineRouteUsingPOST(req: TeamMedicineRouteSortReq, options?: any) {
        return TeamControllerApiFp(this.configuration).sortMedicineRouteUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * sort disp no of team medicine
     * @summary sort disp no of team medicine
     * @param {TeamMedicineSortReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public sortMedicineUsingPOST(req: TeamMedicineSortReq, options?: any) {
        return TeamControllerApiFp(this.configuration).sortMedicineUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary sort partners
     * @param {PartnerSortReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public sortPartnersUsingPOST(req: PartnerSortReq, options?: any) {
        return TeamControllerApiFp(this.configuration).sortPartnersUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary sort treat presets
     * @param {ComTeamSortReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public sortPresetUsingPOST(req: ComTeamSortReq, options?: any) {
        return TeamControllerApiFp(this.configuration).sortPresetUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * sort disp no of team treat item
     * @summary sort disp no of team treat item
     * @param {TeamTreatItemSortReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public sortTreatItemUsingPOST(req: TeamTreatItemSortReq, options?: any) {
        return TeamControllerApiFp(this.configuration).sortTreatItemUsingPOST(req, options)(this.axios, this.basePath);
    }

}


/**
 * UserControllerApi - axios parameter creator
 * @export
 */
export const UserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * delete user
         * @summary delete user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserUsingPOST(options: any = {}): RequestArgs {
            const localVarPath = `/api/user/delete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * sign in with user id
         * @summary signin
         * @param {SigninReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signinUsingPOST(req: SigninReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling signinUsingPOST.');
            }
            const localVarPath = `/api/user/signin`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * sign up
         * @summary signup
         * @param {SignupReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signupUsingPOST(req: SignupReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling signupUsingPOST.');
            }
            const localVarPath = `/api/user/signup`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update user
         * @summary update user
         * @param {UserUpdateReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserUsingPOST(req: UserUpdateReq, options: any = {}): RequestArgs {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling updateUserUsingPOST.');
            }
            const localVarPath = `/api/user/update`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * delete user
         * @summary delete user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserUsingPOST(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = UserControllerApiAxiosParamCreator(configuration).deleteUserUsingPOST(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * sign in with user id
         * @summary signin
         * @param {SigninReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signinUsingPOST(req: SigninReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseSigninDto> {
            const localVarAxiosArgs = UserControllerApiAxiosParamCreator(configuration).signinUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * sign up
         * @summary signup
         * @param {SignupReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signupUsingPOST(req: SignupReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = UserControllerApiAxiosParamCreator(configuration).signupUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * update user
         * @summary update user
         * @param {UserUpdateReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserUsingPOST(req: UserUpdateReq, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponseVoid> {
            const localVarAxiosArgs = UserControllerApiAxiosParamCreator(configuration).updateUserUsingPOST(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * delete user
         * @summary delete user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserUsingPOST(options?: any): AxiosPromise<GenericResponseVoid> {
            return UserControllerApiFp(configuration).deleteUserUsingPOST(options)(axios, basePath);
        },
        /**
         * sign in with user id
         * @summary signin
         * @param {SigninReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signinUsingPOST(req: SigninReq, options?: any): AxiosPromise<GenericResponseSigninDto> {
            return UserControllerApiFp(configuration).signinUsingPOST(req, options)(axios, basePath);
        },
        /**
         * sign up
         * @summary signup
         * @param {SignupReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signupUsingPOST(req: SignupReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return UserControllerApiFp(configuration).signupUsingPOST(req, options)(axios, basePath);
        },
        /**
         * update user
         * @summary update user
         * @param {UserUpdateReq} req req
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserUsingPOST(req: UserUpdateReq, options?: any): AxiosPromise<GenericResponseVoid> {
            return UserControllerApiFp(configuration).updateUserUsingPOST(req, options)(axios, basePath);
        },
    };
};

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI {
    /**
     * delete user
     * @summary delete user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public deleteUserUsingPOST(options?: any) {
        return UserControllerApiFp(this.configuration).deleteUserUsingPOST(options)(this.axios, this.basePath);
    }

    /**
     * sign in with user id
     * @summary signin
     * @param {SigninReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public signinUsingPOST(req: SigninReq, options?: any) {
        return UserControllerApiFp(this.configuration).signinUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * sign up
     * @summary signup
     * @param {SignupReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public signupUsingPOST(req: SignupReq, options?: any) {
        return UserControllerApiFp(this.configuration).signupUsingPOST(req, options)(this.axios, this.basePath);
    }

    /**
     * update user
     * @summary update user
     * @param {UserUpdateReq} req req
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public updateUserUsingPOST(req: UserUpdateReq, options?: any) {
        return UserControllerApiFp(this.configuration).updateUserUsingPOST(req, options)(this.axios, this.basePath);
    }

}


