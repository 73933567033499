import { SigninTeamDto, SigninUnofficialRanchDto } from "../api";
import { FreezedArray, ar } from "./util";

type IUnofficialRanch = SigninUnofficialRanchDto & { isRanch:true, asClinicUser:true };
export type IUserTeam = Omit<SigninTeamDto, "is_ranch"|"as_clinic_user"|"unofficial_ranches"> & {
    isRanch: boolean;
    asClinicUser: boolean;
    unofficial_ranches: Array<IUnofficialRanch> | undefined;
}

/**
 * ユーザの所属する組織情報リストを渡し、条件に一致する組織の検索・列挙を行う
 */
export class UserTeams {
    private readonly teams: FreezedArray<IUserTeam>;

    constructor(user: { teams: FreezedArray<IUserTeam> }) {
        this.teams = user.teams;
    }

    /**
     * 未加入牧場も含めて牧場を検索
     */
    findRanch(id: number) {
        return this.findOfficialRanch(id) ?? this.findUnofficialRanch(id)?.ranch;
    }
    /**
     * 未加入牧場も含めて牧場・診療所を検索
     */
    findTeam(id: number) {
        return this.findOfficialTeam(id) ?? this.findUnofficialRanch(id)?.ranch;
    }
    findOfficialTeam(id: number) {
        return this.teams.find(t => t.team_id === id);
    }
    findOfficialRanch(id: number) {
        return this.teams.find(t => t.team_id === id && t.isRanch);
    }
    findClinic(id: number) {
        return this.teams.find(t => t.team_id === id && !t.isRanch);
    }
    /**
     * 唯一の所属診療所を取得
     */
    findSingleClinic() {
        const clinics = this.teams.filter(t => !t.isRanch);
        if (clinics.length === 0) return undefined;
        if (clinics.length > 1) {
            console.error("more than one clinic found", this.teams);
            return undefined;
        }
        return clinics[0];
    }
    findUnofficialRanch(id: number) {
        //※実際には診療所は1つしか所属できないが、複数あっても見る形にしてある
        for (const clinic of this.teams.filter(t => !t.isRanch)) {
            const ranch = clinic.unofficial_ranches?.find(r => r.team_id === id);
            if (ranch != null) return { clinic, ranch };
        }
        return undefined;
    }
    /**
     * 未加入牧場も含めて全組織を取得
     */
    allTeams() {
        const rtn: Array<IUserTeam|IUnofficialRanch> = [];
        for (const team of this.teams) {
            rtn.push(team);
            (team.unofficial_ranches??[]).forEach(r => rtn.push(r));
        }
        return rtn;
    }
    allRanches() {
        return this.allTeams().filter(t => t.isRanch);
    }
    allOfficialTeams() {
        return this.teams;
    }
    getUnofficialTeamCount() {
        return ar.sum(this.teams.map(t => t.unofficial_ranches?.length ?? 0));
    }
    /**
     * 未加入牧場も含めて1件目の牧場を取得
     */
    firstRanch() {
        return this.allTeams().find(t => t.isRanch);
    }

    getUpdatedAuthTeams(teamId: number, newLevel: number, newLevelDetail: number) {
        return this.teams.map(t => t.team_id !== teamId ? t : ({
            ...t,
            level: newLevel,
            level_detail: newLevelDetail
        }));
    }
    getUnofficialRemovedTeams(unofficialRanchIdToRemove: number) {
        return this.teams.map(t => t.unofficial_ranches == null ? t : ({
            ...t,
            unofficial_ranches: t.unofficial_ranches.filter(ur => ur.team_id !== unofficialRanchIdToRemove)
        }))
    }

}