import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { PageSettings } from '../../config/page-settings.js';
import styles from './incompleted-schedule.module.css';
import { withContext } from '../../stores';
import { ISchedule, SchedulePopup, EventDtoToISchedule, scheduleCustomColorToReq } from '../schedule/schedule-popup';
import { RanchApi, RanchScheduleCowDto, RanchScheduleModifyReq, RanchScheduleDto, ClinicApi } from '../../api';
import classnames from 'classnames';
import { LinkParam } from '../top/Dashboard';
import moment from 'moment';
import { historyLocation, IHistoryLocation } from '../../config/history-location-builder';
import { CowToDispInfo, ICowNameInfo } from '../../components/parts/cows-popup';
import { EventKind } from '../../config/event-kind';
import { Checkbox } from '../../components/form/form-checkbox';
import { FilterBox } from '../../components/parts/filter-box';
import { CommonUtil, ar, QueryUtil } from '../../config/util';
import { AppState } from 'app';
import { UserTeams } from 'config/user-teams';
import { A } from 'config/constant';

type IFilteringCondition = {
    event_kind: number,
    ranch_id: number;
    keyword: string,
    containsFuture: boolean
}

interface MyState {
    teamId?: number;
    isRanch: boolean;
    schedules: ISchedule[];
    condition: IFilteringCondition;
    currentScheduleEvent?: Partial<ISchedule>;
    isScheduleEditing: boolean;
    allRanches: { team_id:number, name:string }[];
}

const DEFAULT_CONDITION: IFilteringCondition = {
    event_kind: 0,
    ranch_id: 0,
    keyword: "",
    containsFuture: true
} as const;

class IncompletedSchedule extends Base<BaseProps<{ id?: string }>, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            isRanch: true,
            schedules: [],
            condition: DEFAULT_CONDITION,
            isScheduleEditing: false,
            allRanches:[]
        }

        this.onStartScheduleEdit = this.onStartScheduleEdit.bind(this);
        this.onFinishScheduleEdit = this.onFinishScheduleEdit.bind(this);
        this.onCowClickInPopup = this.onCowClickInPopup.bind(this);
        this.onSchedulePopupClose = this.onSchedulePopupClose.bind(this);
        this.onScheduleLinkClick = this.onScheduleLinkClick.bind(this);
        this.onUpdateScheduleStatus = this.onUpdateScheduleStatus.bind(this);
        this.onSplit = this.onSplit.bind(this);
        this.onMoveToRanchCalendar = this.onMoveToRanchCalendar.bind(this);
    }

    componentDidMount() {
        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        this.init();
    }
    componentDidUpdate(prevProps: this["props"]) {
        if (prevProps.match.params.id !== this.props.match.params.id
            || prevProps.location.search !== this.props.location.search) {

            this.init();
        }
    }

    private async init() {
        const teamId = this.parseParamId();
        if (teamId == null) return;

        if (this.handleNotAllowAccess(teamId, ["BROWSE_INSIDE"], ["MASTER_REF"])) {
            return;
        }

        const teams = new UserTeams(this.props.rootStore.user);

        const isRanch = teams.findRanch(teamId) != null;
        this.context.handleSetHeader({ title:"未完了予定一覧", iconType:isRanch ? "ranch" : "clinic" });

        const allRanches = isRanch ? [] : teams.allRanches();

        await this.setStateAsync({
            teamId,
            isRanch,
            allRanches,
            currentScheduleEvent:undefined,
            condition: DEFAULT_CONDITION,
            isScheduleEditing: false,
        });

        this.context.handleSetIsLoading(true);
        const loaded = await this.loadScheduleList();
        this.context.handleSetIsLoading(false);

        const scheduleId = QueryUtil.parseNumFromSearch(this.props.location.search, "event");
        if (loaded && scheduleId != null) {
            const event = this.state.schedules.find(e => e.id === scheduleId);
            if (event != null) {
                this.setState({ currentScheduleEvent: event });
            }
        }
    }

    parseParamId = () => {
        const idStr = this.props.match.params.id;
        if (idStr == null) return undefined;
        const id = Number(idStr);
        if (isNaN(id)) return undefined;
        return id;
    }

    onScheduleLinkClick(link: LinkParam) {
        const eventRanchId = this.state.currentScheduleEvent?.ranchId;
        if (CommonUtil.assertNotNull(eventRanchId, "eventRanchId")) {
            this.tryNavigateToRanchPage(eventRanchId, link);
        }
    }

    private searchConditionChange = (c: IFilteringCondition) => {
        this.setState({ condition: c })
    }

    matchesWithFilteringCondition = (schedule: ISchedule, today: Date) => {
        const filter = this.state.condition;
        if (filter.event_kind !== 0 && schedule.eventKindNo !== filter.event_kind) {
            return false;
        }
        if (filter.ranch_id !== 0 && schedule.ranchId !== filter.ranch_id) {
            return false;
        }
        if (filter.keyword !== "" && schedule.dispTitle.indexOf(filter.keyword) < 0) {
            return false;
        }
        if (!filter.containsFuture) {
            const tomorrow = moment(today).add(1, "day").startOf("day");
            if (tomorrow.isSameOrBefore(schedule.start)) {
                return false;
            }
        }
        return true;
    }

    onStartScheduleEdit() {
        this.setState({
            isScheduleEditing: true
        });
    }

    onFinishScheduleEdit() {
        this.setState({
            currentScheduleEvent:undefined,
        }, () => this.loadScheduleList());
    }
    onCowClickInPopup(cow_id: number) {
        const eventRanchId = this.state.currentScheduleEvent?.ranchId;
        if (CommonUtil.assertNotNull(eventRanchId, "eventRanchId")) {
            this.tryNavigateToRanchPage(eventRanchId, historyLocation.toCowInfo(cow_id));
        }
    }

    onSchedulePopupClose() {
        this.setState({
            currentScheduleEvent:undefined,
        });
    }
    onScheduleRowClick(schedule: ISchedule) {
        this.setState({
            currentScheduleEvent: schedule,
            isScheduleEditing: false
        });
    }
    onSplit(results : RanchScheduleDto[]) {
        const oldId = this.state.currentScheduleEvent?.id;
        if (!CommonUtil.assertNotNull(oldId, "id", "onSplit")) return;
        const oldIdx = this.state.schedules.findIndex(s => s.id === oldId);

        const newEvents = ar.notNull(results.map(d => EventDtoToISchedule(d, () => "")));

        this.setState({
            currentScheduleEvent: undefined,
            //再取得されたイベントと入れ替える
            schedules: [
                ...this.state.schedules.slice(0, oldIdx),
                ...newEvents,
                ...this.state.schedules.slice(oldIdx + 1)
            ]
        });
    }

    onMoveToRanchCalendar() {
        const evt = this.state.currentScheduleEvent;
        if (!CommonUtil.assertNotNull(evt, "current event")) return;
        if (!CommonUtil.assertNotNull(evt.id, "schedule id")) return;
        if (!CommonUtil.assertNotNull(evt.ranchId, "ranch id")) return;

        this.tryNavigateToRanchPage(evt.ranchId, historyLocation.toIncompletedSchedule(evt.ranchId, evt.id));
    }

    private async tryNavigateToRanchPage(ranchId: number, dest: IHistoryLocation) {
        if (this.props.rootStore.cur_ranch_id !== ranchId) {
            this.context.handleSetIsLoading(true);
            const res = await this.props.rootStore.tryChangeRanch(ranchId);
            this.context.handleSetIsLoading(false);

            if (res !== true) {
                this.context.showToast(A.MESSAGE.FAILED_TO_LOAD_DATA);
                return;
            }
        }
        this.props.history.push(dest);
    }

    private onUpdateScheduleStatus = async (schedule: ISchedule, toFinish: boolean) => {

        const req: RanchScheduleModifyReq = {
            ranch_id: schedule.ranchId,
            clinic_id: schedule.clinicId,
            is_new: 0,
            schedule_id: schedule.id,
            title: schedule.rawTitle,
            start_at: moment(schedule.start).format("YYYY-MM-DD HH:mm:00"),
            end_at: moment(schedule.end).format("YYYY-MM-DD HH:mm:00"),
            has_time: schedule.allDay ? 0 : 1,
            event_kind_no: schedule.eventKindNo,
            note: schedule.note,
            cow_id: schedule.cows.map(c => c.cow_id),
            status: toFinish ? 1 : 0,
            color: scheduleCustomColorToReq(schedule.customColor),
            preset_id: schedule.preset?.preset_id
        };
    
        this.context.handleSetIsLoading(true);
        const res = await this.comm().send((await RanchApi()).modifyScheduleUsingPOST(req), { asksToReloadWhenNG:true });
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") {
            if (res.result === "NG_RELOAD_REQUIRED") {
                this.context.handleSetIsLoading(true);
                await this.loadScheduleList();
                this.context.handleSetIsLoading(false);
            }
            return;
        }
    
        //ローカルで更新（リロードされるまでは完了のまま残る）
        const schedules = this.state.schedules
        const idx = schedules.findIndex(s => s.id === schedule.id);
        if (0 <= idx) {
            this.setStateAsync({ schedules: [
                ...schedules.slice(0, idx),
                { ...schedule, status: toFinish },
                ...schedules.slice(idx + 1)
            ]});
            return;
        }
    }

    private loadScheduleList = async () => {
        if (!CommonUtil.assertNotNull(this.state.teamId, "teamId")) return;

        const res = this.state.isRanch
                ? await this.comm().send((await RanchApi()).getScheduleListUsingPOST({
                    ranch_id: this.state.teamId,
                    start_at: "0001-01-01",
                    end_at: "9999-12-31",
                    status: 0,
                }))
                : await this.comm().send((await ClinicApi()).getClinicScheduleListUsingPOST({
                    clinic_id: this.state.teamId,
                    start_at: "0001-01-01",
                    end_at: "9999-12-31",
                    status: 0,
                }));

        if (res.result !== "OK" || res.data == null) return false;

        // 開始日の降順にソート
        const data = res.data.sort((s1,s2) => s1.start_at > s2.start_at ? -1 : 1);
        const schedules = data.map(d => EventDtoToISchedule(d, ()=>"")).filter(d => d != null) as ISchedule[];
        await this.setStateAsync({schedules: schedules})
        return true;
    }

    render() {
        const teamId = this.state.teamId;
        if (teamId == null) return <></>

        const getCowStr = (cows?: ICowNameInfo[]) => {
            if(!cows || cows.length === 0) return "";
            const cow = cows[0]
            const str = CowToDispInfo(cow, false);
            return str + (cows.length === 1 ? "" : "他")
        }

        const renderScheduleRow = (s: ISchedule) => 
            <div data-testid="list-item" key={s.id} className={classnames(styles["schedule-row"], {[styles["schedule-done"]]: s.status})}>
                <div data-testid="date" className={styles["schedule-start-date"]} onClick={() => this.onScheduleRowClick(s)}>
                    <span>{moment(s.start).format("YYYY/MM/DD")}</span>
                </div>
                <div className={"checkbox checkbox-css " + styles["schedule-status"]}>
                    <input type="checkbox" id={"chkStatus_" + s.id} checked={s.status} 
                        onChange={(e) => this.onUpdateScheduleStatus(s, e.target.checked)} />
                    <label htmlFor={"chkStatus_" + s.id} />
                </div>
                <div data-testid="cow" className={styles["schedule-cow"]} onClick={() => this.onScheduleRowClick(s)}>
                    <span>{getCowStr(s.cows)}</span>
                </div>
                <div data-testid="title" className={styles["schedule-title"]} onClick={() => this.onScheduleRowClick(s)}>
                    <span>[{EventKind.find(s.eventKindNo)?.schedule?.sname??""}]</span>
                    <span>{s.dispTitle}</span>
                    { this.state.condition.ranch_id === 0 && !this.state.isRanch && s.ranchId != null && (
                        <span className={styles["schedule-sub"]}>{this.state.allRanches.find(r => r.team_id === s.ranchId)?.name}</span>
                    )}
                    { s.results.length > 0 && (
                        <span className={styles["schedule-sub"]}>(記録済)</span>
                    )}
                </div>
            </div>

        const today = moment().startOf("day").toDate();

        const allEventKinds = this.state.isRanch ? EventKind.forSchedule(teamId, this.props.rootStore.user) : EventKind.forScheduleWithClinicUser();

        return (
            <div className="page-root">
                <div className="product product-full-height">
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix">
                            <div className={styles["search-box"]}>
                                <div className={styles["search-select-container"]}>
                                    { this.state.allRanches.length > 0 && (
                                        <div className={styles["search-select"]}>
                                            <select className="form-control" value={this.state.condition.ranch_id} 
                                                onChange={(e) => this.searchConditionChange({...this.state.condition, ranch_id: parseInt(e.target.value)})}>
                                                <option key={0} value={0}>すべての牧場</option>
                                                { this.state.allRanches.map(r => 
                                                    <option key={r.team_id} value={r.team_id}>{r.name}</option>
                                                )}
                                            </select>
                                        </div>
                                    )}
                                    <div className={styles["search-select"]}>
                                        <select className="form-control" value={this.state.condition.event_kind} 
                                            onChange={(e) => this.searchConditionChange({...this.state.condition, event_kind: parseInt(e.target.value)})}>
                                            <option key={0} value={0}>すべての種別</option>
                                            { allEventKinds.map(ek => 
                                                <option key={ek.no} value={ek.no}>{ek.schedule?.sname}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div>
                                    <FilterBox
                                        style={{ marginBottom: "5px" }}
                                        value={this.state.condition.keyword}
                                        onChange={val => this.searchConditionChange({...this.state.condition, keyword: val })}
                                        placeholder="タイトルで絞り込み"
                                    />
                                </div>
                                <div>
                                    <Checkbox checked={this.state.condition.containsFuture}
                                        onChange={e => this.searchConditionChange({...this.state.condition, containsFuture:e.target.checked})}
                                        id="chkFuture" label="明日以降の予定も表示" />
                                </div> 
                            </div>
                            <div className={styles["schedule-box"]}>
                                { this.state.schedules.filter(s => this.matchesWithFilteringCondition(s, today)).map((s, i) => 
                                    renderScheduleRow(s)
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                { this.state.currentScheduleEvent != null && (
                    <SchedulePopup onClose={this.onSchedulePopupClose} onStartEdit={this.onStartScheduleEdit} original={this.state.currentScheduleEvent}
                                   onRegistered={this.onFinishScheduleEdit} onSplit={this.onSplit}
                                   isEditing={this.state.isScheduleEditing} onCowClick={this.onCowClickInPopup}
                                   ranch_id={this.state.isRanch ? teamId : undefined}
                                   onResultLinkClick={this.onScheduleLinkClick}
                                   activeCows={this.state.isRanch ? this.props.rootStore.getActiveCows() : []}
                                   rootStore={this.props.rootStore}
                                   clinic_id={this.props.rootStore.getClinicIdForMaster()}
                                   onMoveToRanchCalendar={this.onMoveToRanchCalendar}
                                   />
                )}
            </div>
        )
    }
}

export default withRouter(withContext(IncompletedSchedule));