import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { A } from '../../config/constant';
import styles from './setting.module.css';
import { PageSettings } from '../../config/page-settings';
import { ConditionEditPopup } from './condition-edit-popup';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ICondition, IConditionClass } from '../../stores/RootStore';
import { withContext } from '../../stores';
import { AppState } from '../../app';
import { TeamConditionSortReq, TeamConditionModifyReq, TeamConditionDeleteReq, TeamConditionBookmarkReq } from '../../api';
import { CommonUtil, FreezedArray } from '../../config/util';
import { DIALOG_BUTTONS } from '../../components/form/form-dialog';
import { MemoSortableList } from '../../components/parts/sortable-list';
import { StarIcon } from './star-icon';
import { useConditionMap, resetConditions } from '../../stores/fetcher';
import { FetchWaiter, FetchError } from '../../components/content/fetch-state';
import { UserTeams } from 'config/user-teams';

interface MyProps extends BaseProps<{id:string}> {
}

interface MyState {
    teamId?: number;
    executing: boolean;
}

export type IEditingCondition = {
    readonly isNew: boolean;
    readonly condition_id: number;
    readonly class_id?: number;
    name: string;
    readonly team_id: number;
    details: IEditingConditionDetail[];
}

export type IEditingConditionDetail = {
    detail_id: number | null;
    name: string;
}

class ConditionMaster extends Base<MyProps, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            executing: false
        };
    }

    componentDidMount() {

        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        this.init();
    }

    componentDidUpdate(prevProps: this['props'], _: MyState) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.init();
        }
    }

    private init() {
        const team_id = Number(this.props.match.params.id);
        if (this.handleNotAllowAccess(team_id, ["MASTER_EDIT"], ["MASTER_EDIT"])) {
            return;
        }
        const isRanch = new UserTeams(this.props.rootStore.user).findRanch(team_id) != null;
        this.context.handleSetHeader({ title:"症状の設定", iconType:isRanch ? "ranch" : "clinic" });

        this.setState({
            teamId: team_id,
        });
    }

    private finishSorting = async (condition_id: number, order: number[]) => {
        if (!CommonUtil.assertNotNull(this.state.teamId)) return false;

        try {
            this.context.handleSetIsLoading(true);
            this.setState({ executing: true });

            const req: TeamConditionSortReq = {
                order,
                condition_id,
                team_id: this.state.teamId,
                user_id: this.props.rootStore.user.id
            };
            const res = await this.comm().send(() => this.context.postAsync("/team/condition/sort", req));
            this.context.handleSetIsLoading(false);
            this.setState({ executing: false });
        
            if (res.result !== "OK") return false;
            
            await resetConditions(false);
            return true;

        } finally {
            this.setState({ executing: false });
            this.context.handleSetIsLoading(false);
        }
    }


    private onSubmitEdit = async (data: IEditingCondition): Promise<boolean> => {

        if (data.name === "") {
            this.context.showToast(A.MESSAGE.NO_CONDITION_NAME_INPUT);
            return false;
        }

        const details = data.details.filter(d => d.name !== "");
        
        if (details.length === 0) {
            this.context.showToast(A.MESSAGE.NO_CONDITION_DETAIL_INPUT);
            return false;
        }

        try {
            this.setState({ executing: true });
            this.context.handleSetIsLoading(true);

            const req: TeamConditionModifyReq = {
                is_new: data.class_id == null ? 1 : 0,
                team_id: data.team_id,
                condition_id: data.condition_id,
                class_id: data.class_id ?? 0,
                name: data.name,
                details: details.map(d => ({...d, detail_id:d.detail_id ?? undefined})),
                user_id: this.props.rootStore.user.id,
            }
            const res = await this.comm().send(() => this.context.postAsync("/team/condition/modify", req));
            if (res.result !== "OK") return false;

            await resetConditions(false);
            return true;

        } finally {
            this.context.handleSetIsLoading(false);
            this.setState({ executing: false });
        }
    }

    private onDelete = async (classId: number) => {
        if (!CommonUtil.assertNotNull(this.state.teamId, "teamId")) return false;

        const confirm = await this.context.showDialog("QUESTION", '症状を削除してよろしいですか？', DIALOG_BUTTONS.DELETE_CANCEL);
        if (confirm !== 0) {
            return false;
        }
        
        this.context.handleSetIsLoading(true);
        this.setState({ executing:true });
        try {
            const req: TeamConditionDeleteReq = {
                class_id: classId,
                team_id: this.state.teamId,
                user_id: this.props.rootStore.user.id
            };
            const res = await this.comm().send(() => this.context.postAsync("/team/condition/delete", req));
            if (res.result !== "OK") return false;

            await resetConditions(false);
            return true;

        } finally {
            this.context.handleSetIsLoading(false);
            this.setState({ executing: false });
        }
    }


    private onBookmarked = async (is_bookmarked:number, classId: number) => {
        const teamId = this.state.teamId;
        if (!CommonUtil.assertNotNull(teamId, "teamId", "onBookmarked")) return false;

        try {
            this.setState({ executing:true });
            this.context.handleSetIsLoading(true);

            const req: TeamConditionBookmarkReq = {
                class_id: classId,
                is_bookmarked: is_bookmarked,
                team_id: teamId,
                user_id: this.props.rootStore.user.id
            };
            const res = await this.comm().send(() => this.context.postAsync("/team/condition/bookmark", req));
            if (res.result !== "OK") return false;

            await resetConditions(false);
            return true;
        
        } finally {
            this.context.handleSetIsLoading(false);
            this.setState({ executing:false });
        }
    }

    render() {
        if (this.state.teamId == null) return <></>

        return (
            <ConditionMasterContent
                teamId={this.state.teamId}
                categoryList={this.props.rootStore.options.condition}
                executing={this.state.executing}
                finishSorting={this.finishSorting}
                onSubmitEdit={this.onSubmitEdit}
                onDelete={this.onDelete}
                onBookmarked={this.onBookmarked}
            />
        )

    }
}

interface ContentProps {
    teamId: number;
    categoryList: FreezedArray<ICondition>;
    executing: boolean;
    finishSorting: (conditionId: number, order: number[]) => Promise<boolean>;
    onSubmitEdit: (item: IEditingCondition) => Promise<boolean>;
    onDelete: (classId: number) => Promise<boolean>;
    onBookmarked: (isBookmarked:1|0, classId: number) => Promise<boolean>;
}

const ConditionMasterContent = React.memo((props: ContentProps) => {

    const conditions = useConditionMap(props.teamId);
    const [ refTab, setRefTab ] = useState<Slider>();
    const [ refContent, setRefContent ] = useState<Slider>();
    const [ sortingList, setSortingList ] = useState<FreezedArray<IConditionClass>>([]);
    const [ isSorting, setIsSorting ] = useState(false);
    const [ editingData, setEditingData] = useState<IEditingCondition>();
    const [ currentCategory, setCurrentCategory ] = useState<number>();

    useEffect(() => {
        setCurrentCategory(props.categoryList.length > 0 ? props.categoryList[0].condition_id : undefined);

    }, [ props.categoryList ]);

    const startSorting = useCallback(() => {
        if (!CommonUtil.assertNotNull(currentCategory, "currentCategory")) return;
        if (!CommonUtil.assertNotNull(conditions.data, "conditions")) return;
        setIsSorting(true);
        setSortingList(conditions.data.get(currentCategory) ?? []);

    }, [ conditions.data, currentCategory ]);

    const cancelSorting = useCallback(() => {
        setIsSorting(false);
        setSortingList([]);
    }, []);

    const finishSorting = useCallback(async () => {
        if (!CommonUtil.assertNotNull(conditions.data, "condition")) return;
        if (!CommonUtil.assertNotNull(currentCategory, "currentCategory")) return;

        const old = conditions.data.get(currentCategory) ?? [];

        const newOrder = sortingList.map(p => p.class_id);
        const oldOrder = old.map(p => p.class_id);
        if (JSON.stringify(newOrder) === JSON.stringify(oldOrder)) {
            setIsSorting(false);
            setSortingList([]);
            return;
        }

        const res = await props.finishSorting(currentCategory, newOrder);
        if (res === true) {
            setIsSorting(false);
            setSortingList([]);
        }

    }, [ currentCategory, sortingList, conditions.data, props.finishSorting ]);

    const addItem = useCallback(() => {
        if (!CommonUtil.assertNotNull(currentCategory, "currentCategory")) return;

        setEditingData({
            isNew:true,
            name:"",
            team_id: props.teamId,
            condition_id: currentCategory,
            details:[]
        });

    }, [ currentCategory, props.teamId ]);

    const startEdit = useCallback((condition: IConditionClass) => {
        setEditingData({
            isNew: false,
            team_id: props.teamId,
            condition_id: condition.condition_id,
            class_id: condition.class_id,
            name: condition.name,
            details: condition.details
        })

    }, [ props.teamId ]);

    const onSubmitEdit = useCallback(async (item: IEditingCondition) => {
        const res = await props.onSubmitEdit(item);
        if (res === true) {
            setEditingData(undefined);
        }

    }, [ props.onSubmitEdit ]);

    const onDelete = useCallback(async () => {
        if (!CommonUtil.assertNotNull(editingData, "editingData")) return;
        if (!CommonUtil.assertNotNull(editingData.class_id, "classId")) return;

        const res = await props.onDelete(editingData.class_id);
        if (res === true) {
            setEditingData(undefined);
        }

    }, [ editingData, props.onDelete ]);

    const sliderContentSettings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:false
    };

    const sliderTabSettings = {
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows:false,
        responsive: [{
            breakpoint: 768,
            settings: {
                slidesToShow: 3,
            }
        }]
    };


    return (
        <div className="page-root">
            <div className="product product-full-height">
                <div className="product-detail" style={{ height: "100%" }}>
                    <div className="product-info product-info-fix w-100">
                        <div className={"product-info-header " + styles["header-with-tab"]}>
                            <div className={styles["sortable-list-shoulder"]}>
                                {!isSorting && (<span className="link" onClick={startSorting}>並び替え</span>)}
                                {isSorting && (<span className="link" onClick={finishSorting}>完了</span>)}
                                {isSorting && (<span className="link" onClick={cancelSorting}>キャンセル</span>)}
                            </div>
                        </div>
                        <div>
                            <Slider asNavFor={refContent}
                                    ref={t => setRefTab(t ?? undefined)}
                                    swipe={!isSorting} focusOnSelect={!isSorting}
                                    {...sliderTabSettings}>
                                {
                                    props.categoryList.map((condition) => (
                                        <div key={condition.condition_id} className={currentCategory === condition.condition_id ? styles["tab-active"] : styles["tab-default"]}>{condition.name}</div>
                                    ))
                                }
                            </Slider>
                        </div>

                        <div className="product-body">
                            { conditions.isLoading ? (
                                <FetchWaiter />
                            ) : (conditions.isError || conditions.data == null) ? (
                                <FetchError />
                            ) : (
                                <Slider className={styles.slider} asNavFor={refTab}
                                        ref={c => setRefContent(c ?? undefined)}
                                        afterChange={idx => setCurrentCategory(props.categoryList[idx].condition_id)} 
                                        swipe={!isSorting}
                                        {...sliderContentSettings}>
                                    { props.categoryList.map(condition => (
                                        <div key={condition.condition_id}>
                                            <MemoSortableList className={styles["list"] + " m-b-0"}
                                                items={isSorting
                                                        ? (currentCategory === condition.condition_id ? sortingList : [])
                                                        : (conditions.data?.get(condition.condition_id) ?? [])}
                                                isSorting={isSorting}
                                                onSorted={setSortingList}
                                                listItem={item => (
                                                    <li key={item.class_id} className={styles["list-item"]}>
                                                        <StarIcon className="p-2 m-b-10 m-r-5"
                                                            isOn={item.is_bookmarked === 1}
                                                            onChange={on => props.onBookmarked(on ? 1 : 0, item.class_id)}
                                                            disable={isSorting}
                                                        />
                                                        <div className={styles["list-item-content"]} style={{alignSelf: "center"}}>
                                                            <div className={styles["list-item-name"]}>
                                                                {item.name}
                                                            </div>
                                                            <div className={styles["list-item-detail"]}>
                                                                {item.details.map(d => d.name).join(",")}
                                                            </div>
                                                        </div>
                                                        { isSorting ? (
                                                            <div className={styles["list-item-icon"]}>
                                                                <i className="fas fa-bars" />
                                                            </div>
                                                        ) : (
                                                            <div onClick={() => startEdit(item)} className={styles["list-item-icon"]}>
                                                                <i className="fas fa-pen clickable" />
                                                            </div>
                                                        )}
                                                    </li>
                                                )}
                                            />
                                        </div>
                                    ))}
                                </Slider>
                            )}

                        </div>
                    </div>
                </div>
            </div>
            <div style={{height:"40px", marginTop:"6px"}} onClick={addItem}>
                { !isSorting && conditions.data != null && (
                    <span className="link">
                        <i className="fa fa-plus"></i>
                        <span> 項目を追加</span>
                    </span>
                )}
            </div>
            {
                editingData != null && (
                    <ConditionEditPopup 
                        data={editingData}
                        conditionList={props.categoryList}
                        onClose={() => setEditingData(undefined)}
                        onDelete={onDelete}
                        onSubmit={onSubmitEdit}
                        isSubmitExecuting={props.executing}
                    />
                )
            }
        </div>
    )

})

export default withRouter(withContext(ConditionMaster));