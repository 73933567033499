import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { FormDatePicker } from '../../components/form/form-date-picker';
import { A, LMT, COW_SELL_TYPE, COW_END_KIND, COW_END_KIND_KEYS, checkInvalidCowPattern } from '../../config/constant';
import { PageSettings } from '../../config/page-settings.js';
import { SelectMotherPopup, IMother } from './select-mother-popup';
import { withContext, IRanchHouse } from '../../stores';
import InputMask from 'react-input-mask';
import { CowModifyReq, CowDetailDto, RanchApi, ExtraCowDto, PreloadedCowProgramReq, CowApi } from '../../api';
import { historyLocation } from '../../config/history-location-builder';
import { AppState } from '../../app';
import { RequiredNumInput, OptionalNumInput } from '../../components/parts/num-input';
import { hasRanchAuth } from '../../config/auth-checker';
import { CommonUtil } from '../../config/util';
import { CowToDispInfo, ICowNameInfo } from '../../components/parts/cows-popup';
import moment from 'moment';
import { FadeInPanel } from '../../components/parts/fadein-panel';
import { AncestorSelect } from '../setting/ancestor-select';
import { SettingAncestorPopup } from '../setting/setting-ancestor-popup';
import { TRACE_ID_LEN, checkTraceId } from './trace-id-checker';
import { UserTeams } from '../../config/user-teams';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { DIALOG_BUTTONS } from '../../components/form/form-dialog';
import { showPreloadedCowProgramSelectDialog, PreloadedCowProgramSelectPopupProps, PreloadedCowProgramSelectPopup } from '../program/preloaded-program-select-popup';
import { getRanchHouses, getAncestors } from '../../stores/fetcher';
import { FetchWaiter, FetchError } from '../../components/content/fetch-state';
import { resetCow } from 'stores/fetcher_cow';

interface IEditingData {
    cow_id: number;
    ranch_id: number;
    trace_id: string;
    local_no: string;
    name: string;
    is_male: 0 | 1;
    birthday: Date | undefined;
    breed_no: number;
    use_no: number;
    site: number;
    barn: number;
    room: number;
    ancestor_1_exid?: number;
    ancestor_2_exid?: number;
    ancestor_3_exid?: number;
    ancestor_1?: string;
    ancestor_2?: string;
    ancestor_3?: string;
    mother_id?: number;
    mother_name: string;
    is_active: boolean;
    mother?: ICowNameInfo;
    note: string;
    surrogate_mother_id?: number;
    surrogate_mother_name: string;
    surrogate_mother?: ICowNameInfo;
    delivery_id?: number;
    delivery_child_no?: number;
    start_kind: number;
    accept_day?: string | Date;
    accept_from?: string;
    accept_price: number;
    accept_weight?: number;
    sell_plan?: number;
    weight?: number;
    accept_use_no: number | undefined;
    end_day: Date | undefined;
    end_kind: number | undefined;
}

interface MyState {
    dispStatus: "ready"|"loading"|"error";
    edit_cow_id: number;
    popup_mother_open: boolean;
    popup_surrogate_mother_open: boolean;
    cow: IEditingData;
    location_sites: Readonly<Readonly<IRanchHouse>[]>;
    location_barns: Readonly<Readonly<IRanchHouse>[]>;
    location_rooms: Readonly<Readonly<IRanchHouse>[]>;
    site_idx: number;
    barn_idx: number;
    room_idx: number;
    executing: boolean;
    ancestorList: ExtraCowDto[];
    isAcceptUseEditing: boolean;
    isAncestorPopupShown: boolean;
    hasTraceIdWarning: boolean;
    isOfficialRanch: boolean;
    preloadedProgram?: PreloadedCowProgramSelectPopupProps;
}

export interface CowWriteLocationState {
    defaults: Readonly<Readonly<Partial<IEditingData>>[]>;
}

const newDefaultCow = ():IEditingData => {
    return {
        cow_id: 0,
        ranch_id: 0,
        trace_id: "",                // ID
        local_no: "",                // 牧場耳標
        name: '',                   // 名前
        is_male: 0,                 // 性別
        birthday: undefined,        // 生年月日
        breed_no: 0,                // 品種
        use_no: 0,                  // 用途
        site: 0,                    // 分場選択
        barn: 0,                    // 牛舎選択
        room: 0,                    // 部屋選択
        mother_id: 0,               // 母牛
        mother_name: "",            // 母牛名
        is_active: true,
        mother: {
            cow_id: 0,
            trace_id: "",           // ID
            local_no: "",           // 牧場耳標
            name: '',               // 名前
        },
        note: "",
        surrogate_mother_id: 0,     // 代理母牛
        surrogate_mother_name: "",  // 代理母牛名
        surrogate_mother: {
            cow_id: 0,
            trace_id: "",           // ID
            local_no: "",           // 牧場耳標
            name: '',               // 名前
        },
        start_kind: A.START_KIND.get("SELF")!.no ,
        accept_day: new Date(),
        accept_from: "",
        accept_price: 0,
        end_day: undefined,
        end_kind: undefined,
        accept_use_no: undefined
    };
}

const CONFIRM_RES_CODES = [ A.ERR_CODE.ERR_COW_DUP_TRACE_ID, A.ERR_CODE.ERR_COW_DUP_DELIVERY_CHILD, A.ERR_CODE.ERR_COW_DUP_ID_AND_DELIVERY_CHILD ] as const;
type ConfirmResCodeKey = typeof CONFIRM_RES_CODES[number];
const CONFIRM_RES_CODE: { [key in ConfirmResCodeKey]: { getMsg: (traceId: string) => string[] } } = {
    [A.ERR_CODE.ERR_COW_DUP_TRACE_ID]: { getMsg:(id)=>[ A.MESSAGE.COW_TRACE_ID_DUPLICATED(id) ] },
    [A.ERR_CODE.ERR_COW_DUP_DELIVERY_CHILD]: { getMsg:()=>[ A.MESSAGE.COW_DELIVERY_CHILD_DUPLICATED ] },
    [A.ERR_CODE.ERR_COW_DUP_ID_AND_DELIVERY_CHILD]: { getMsg:(id)=>[ A.MESSAGE.COW_DELIVERY_CHILD_DUPLICATED, A.MESSAGE.COW_TRACE_ID_DUPLICATED(id) ] },
}

class CowWrite extends Base<BaseProps<{},{},CowWriteLocationState|undefined>, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            dispStatus: "loading",
            edit_cow_id: 0,

            popup_mother_open: false,
            popup_surrogate_mother_open: false,

            cow: newDefaultCow(),

            location_sites: [],
            location_barns: [],
            location_rooms: [],

            site_idx: 0,
            barn_idx: 0,
            room_idx: 0,
            executing: false,
            ancestorList:[],
            isAcceptUseEditing: false,
            isAncestorPopupShown: false,
            hasTraceIdWarning: false,
            isOfficialRanch: true
        }

        this.onSiteSelected = this.onSiteSelected.bind(this);
        this.onBarnSelected = this.onBarnSelected.bind(this);
        this.onRoomSelected = this.onRoomSelected.bind(this);
        this.onMotherSelected = this.onMotherSelected.bind(this);
        this.onActiveChange = this.onActiveChange.bind(this);
        this.showSelectMotherPopup = this.showSelectMotherPopup.bind(this);
        this.closeSelectMotherPopup = this.closeSelectMotherPopup.bind(this);
        this.clearMother = this.clearMother.bind(this);

        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }
    componentDidMount() {
        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        this.init();
    }
    componentDidUpdate(prevProps: this["props"], prevState: MyState) {
        if (prevProps.location !== this.props.location) {
            this.init();
        }
        if (prevState.cow.trace_id !== this.state.cow.trace_id) {
            this.updateTraceIdWarning();
        }
    }
    async init() {
        if (this.handleNotAllowAccess(undefined, ["COW_EDIT"], [])) {
            return;
        }

        const cow_id = this.getCowIdFromPath(this.props.history.location.pathname);
        const title = this.state.edit_cow_id > 0 ? "牛を編集" : "牛を追加";
        this.context.handleSetHeader({ title });
        
        const ranchId = this.props.rootStore.cur_ranch_id;

        const sites = await getRanchHouses(ranchId);
        const ancs = await getAncestors(ranchId);

        await this.setStateAsync({
            location_sites: sites ?? [],
            location_barns: [],
            location_rooms: [],
            site_idx: 0,
            barn_idx: 0,
            room_idx: 0,
            edit_cow_id: cow_id,
            cow: newDefaultCow(),
            ancestorList: ancs ?? [],
            isOfficialRanch: new UserTeams(this.props.rootStore.user).findOfficialRanch(ranchId) != null,
            dispStatus: sites == null || ancs == null ? "error" : "ready"
        });

        if (this.state.edit_cow_id > 0) {
            await this.api_getGetCowInfo();
        }
        else {
            //新規の時、遷移元から初期値の指定があれば反映
            const defaults = this.props.location.state?.defaults ?? [];
            if (defaults.length > 0) {
                const iniData = defaults[0];
                this.setState({
                    cow: {
                        ...this.state.cow,
                        is_male: iniData.is_male ?? 0,
                        birthday: iniData.birthday,
                        breed_no: iniData.breed_no ?? 0,
                        use_no: iniData.use_no ?? 0,
                        site: iniData.site ?? 0,
                        barn: iniData.barn ?? 0,
                        room: iniData.room ?? 0,
                        ancestor_1_exid: iniData.ancestor_1_exid,
                        ancestor_2_exid: iniData.ancestor_2_exid,
                        ancestor_3_exid: iniData.ancestor_3_exid,
                        ancestor_1: iniData.ancestor_1,
                        ancestor_2: iniData.ancestor_2,
                        ancestor_3: iniData.ancestor_3,
                        mother_id: iniData.mother_id ?? iniData.mother?.cow_id ?? 0,
                        mother_name: iniData.mother_name ?? iniData.mother?.name ?? "",
                        is_active: iniData.is_active === true || (iniData.is_active ?? 1) === 1,
                        mother: {
                            cow_id: iniData.mother?.cow_id ?? 0,
                            trace_id: iniData.mother?.trace_id ?? "",
                            local_no: iniData.mother?.local_no ?? "",
                            name: iniData.mother?.name ?? "",
                        },
                        note: iniData.note ?? "",
                        delivery_id: iniData.delivery_id,
                        delivery_child_no: iniData.delivery_child_no,
                        surrogate_mother_id: iniData.surrogate_mother_id ?? iniData.surrogate_mother?.cow_id ?? 0,
                        surrogate_mother_name: iniData.surrogate_mother_name ?? iniData.surrogate_mother?.name ?? "",
                        surrogate_mother: {
                            cow_id: iniData.surrogate_mother?.cow_id ?? 0,
                            trace_id: iniData.surrogate_mother?.trace_id ?? "",
                            local_no: iniData.surrogate_mother?.local_no ?? "",
                            name: iniData.surrogate_mother?.name ?? "",
                        },
                        start_kind: iniData.start_kind ?? A.START_KIND.get("SELF")!.no,
                        accept_day: (iniData.accept_day ?? "") !== "" ? new Date(iniData.accept_day!) : new Date(),
                        accept_from: iniData.accept_from ?? "",
                        sell_plan: A.SINGLE_SELL_PLAN_NO(iniData.use_no ?? 0),
                        weight: iniData.weight == null ? undefined : Math.round(iniData.weight),
                        accept_weight: iniData.accept_weight == null ? undefined : Math.round(iniData.accept_weight)
                    }
                });

            }
        }
    }

    updateTraceIdWarning() {
        const id = this.removeTraceIdMask(this.state.cow.trace_id);
        if (id.length < TRACE_ID_LEN) {
            this.setState({ hasTraceIdWarning: false });
            return;
        }
        this.setState({ hasTraceIdWarning: checkTraceId(id) === false })
    }

    getCowIdFromPath(path: string) {
        let root_path = "/cow/write";
        if (path.length < root_path.length) {
            return 0;
        }

        let id = path.substring(root_path.length);
        if (id.length > 1) {
            id = id.substring(1);
            console.log(id);
        } else {
            return 0;
        }
        return parseInt(id);
    }

    async onAncestorListUpdated() {
        const list = await getAncestors(this.props.rootStore.cur_ranch_id);
        if (list == null) {
            this.setState({ ancestorList: [], dispStatus:"error" })
        } else {
            this.setState({ ancestorList: list });
        }
    }

    onSiteSelected(site_no: number) {
        if (isNaN(site_no)) { site_no = 0; }
        if (site_no === 0) {
            this.state.cow.site = site_no;
            this.state.cow.barn = 0;
            this.state.cow.room = 0;
            this.setState({ location_barns: [], location_rooms: [] });
            return;
        }
        const site = this.state.location_sites.find(s => s.no === site_no);
        if (!CommonUtil.assertNotNull(site)) return;

        if (this.state.cow.site !== site_no) {
            this.state.cow.site = site_no;
            this.state.cow.barn = 0;
            this.state.cow.room = 0;
        }
        if ((site.data?.length ?? []) === 1) {
            this.setState({location_barns: site.data??[]}, () => this.onBarnSelected(site.data![0].no));
        } else {
            this.setState({ location_barns: site.data??[], location_rooms: [] });
        }
    }

    onBarnSelected(barn_no: number) {
        if (isNaN(barn_no)) { barn_no = 0; }
        if (barn_no === 0) {
            this.state.cow.barn = barn_no;
            this.state.cow.room = 0;
            this.setState({ location_rooms: [] });
        }
        let barns = this.state.location_barns;
        for (var i = 0; i < barns.length; i++) {
            if (barns[i].no === barn_no) {
                if (this.state.cow.barn !== barn_no) {
                    this.state.cow.barn = barn_no;
                    this.state.cow.room = 0;
                }
                if ((barns[i].data??[]).length === 1) {
                    this.state.cow.room = barns[i].data![0].no;
                }
                this.setState({ location_rooms: barns[i].data??[] });
                break;
            }
        }
    }

    onRoomSelected(room_no: number) {
        if (isNaN(room_no)) { room_no = 0; }
        if (room_no === 0) {
            this.onStateChange(room_no, "cow.room");
            return;
        }
        let rooms = this.state.location_rooms;
        for (var i = 0; i < rooms.length; i++) {
            if (rooms[i].no === room_no) {
                if (this.state.cow.room !== room_no) {
                    this.onStateChange(room_no, "cow.room");
                }
                break;
            }
        }
    }

    showSelectMotherPopup(is_surrogate_mother) {
        if( is_surrogate_mother ) {
            this.setState({ popup_surrogate_mother_open: true });
        } else {
            this.setState({ popup_mother_open: true });
        }
    }
    
    closeSelectMotherPopup() {
        if( this.state.popup_mother_open ) {
            this.setState({ popup_mother_open: false });
        }
        if( this.state.popup_surrogate_mother_open ) {
            this.setState({ popup_surrogate_mother_open: false });
        }
    }

    clearMother(is_surrogate_mother: boolean) {
        this.setMother(null, is_surrogate_mother)
    }

    onMotherSelected(m: IMother | undefined) {
        if( this.state.popup_mother_open ) {
            this.setMother(m, false);
        }
        if( this.state.popup_surrogate_mother_open ) {
            this.setMother(m, true);
        }
        this.closeSelectMotherPopup();
    }

    setMother(m: IMother | null | undefined, is_surrogate_mother: boolean) {
        const mother = m || {
            cow_id: 0,
            trace_id: "",
            local_no: "",
            name: '',
        };
        const newCow = {...this.state.cow};
        if (is_surrogate_mother) {
            newCow.surrogate_mother = mother;
            newCow.surrogate_mother_id = mother.cow_id;
            newCow.surrogate_mother_name = this.getMotherName(mother) ?? "";
        } else {
            newCow.mother = mother;
            newCow.mother_id = mother.cow_id;
            newCow.mother_name = this.getMotherName(mother) ?? "";
            if (m != null) {
                newCow.ancestor_2_exid = m.ancestor_1_exid;
                newCow.ancestor_2 = m.ancestor_1;
                newCow.ancestor_3_exid = m.ancestor_2_exid;
                newCow.ancestor_3 = m.ancestor_2;
            }
        }
        this.setState({
            cow: newCow
        });
    }

    onActiveChange(is_active: boolean) {
        this.onStateChange(is_active, 'cow.is_active');

        if (!is_active) {
            this.context.showDialog("WARNING", '「飼養終了」が選択されています。\nこの牛の情報は今後、一覧に表示されなくなります。', DIALOG_BUTTONS.OK);
        }
    }

    onSave() {
        this.api_postCowWrite(0, undefined, undefined);
    }

    async onDelete() {
        const msg = '牛の情報を削除すると、過去の記録もあわせて削除されます。\n記録は残したまま、牛の一覧からの表示のみを消したい場合は、削除ではなく、「飼養終了」を利用することができます。\n本当に削除を実行しますか？';
        const res = await this.context.showDialog("QUESTION", msg, DIALOG_BUTTONS.DELETE_CANCEL);
        if (res !== 0) return;

        this.api_postCowDelete();
    }

    getMotherName(mother: ICowNameInfo) {
        if (!CommonUtil.assertNotNull(mother)) return "";

        if ((mother.local_no ?? "") === "" && mother.trace_id === "" && (mother.name ?? "") === "") {
            return "";
        }
        if ((mother.name ?? "") !== "") {
            return mother.name;
        }
        return CowToDispInfo(mother, false);
    }

    api_getGetCowInfo = async () => {

        const query_str = "?user_id=" + this.props.rootStore.user.id + "&ranch_id=" + this.props.rootStore.cur_ranch_id + "&is_detail=0";
        console.log(query_str);

        this.context.handleSetIsLoading(true);
        const response = await this.comm().send<CowDetailDto>(() => this.context.postAsync('/cow/' + this.state.edit_cow_id + query_str, {}), { retries: true });
        this.context.handleSetIsLoading(false);
        if (response.result !== "OK" || response.data == null) return;

        const cow = response.data;

        this.onSiteSelected(cow.site);
        this.onBarnSelected(cow.barn);

        console.log(cow);
        const birth = (cow.birthday == null || cow.birthday === "") ? undefined : new Date(cow.birthday);
        const acceptDay = (cow.accept_day == null || cow.accept_day === "") ? new Date() : new Date(cow.accept_day);
        const endDay = (cow.end_day == null || cow.end_day === "") ? undefined : new Date(cow.end_day);

        this.setState({
            cow: {
                cow_id: cow.cow_id,
                ranch_id: cow.ranch_id,
                trace_id: cow.trace_id,                 // ID
                local_no: cow.local_no ?? "",           // 牧場耳標
                name: cow.name ?? "",                   // 名前
                is_male: cow.is_male === 1 ? 1 : 0,     // 性別
                birthday: birth,                        // 生年月日
                breed_no: cow.breed_no ?? 0,            // 品種
                use_no: cow.use_no ?? 0,                // 用途
                site: cow.site,                         // 分場選択
                barn: cow.barn,                         // 牛舎選択
                room: cow.room,                         // 部屋選択
                ancestor_1_exid: cow.ancestor_1_exid,   // 一代祖
                ancestor_2_exid: cow.ancestor_2_exid,   // 二代祖
                ancestor_3_exid: cow.ancestor_3_exid,   // 三代祖
                ancestor_1: cow.ancestor_1,             // 一代祖
                ancestor_2: cow.ancestor_2,             // 二代祖
                ancestor_3: cow.ancestor_3,             // 三代祖
                mother_id: cow.mother_id !== null ? cow.mother_id : 0, // 母牛
                mother_name: cow.mother_name ?? "",     // 母牛名
                is_active: cow.is_active === 1,
                mother: {
                    cow_id: cow.mother?.cow_id !== null ? cow.mother?.cow_id??0 : 0,
                    trace_id: cow.mother?.trace_id !== null ? cow.mother?.trace_id??"" : "", // ID
                    local_no: cow.mother?.local_no !== null ? cow.mother?.local_no??"" : "", // 牧場耳標
                    name: cow.mother?.name !== null ? cow.mother?.name??"" : "",             // 名前
                },
                note: cow.note ?? "",
                surrogate_mother_id: cow.surrogate_mother_id !== null ? cow.surrogate_mother_id : 0, // 代理母牛
                surrogate_mother_name: cow.surrogate_mother_name ?? "",           // 代理母牛名
                surrogate_mother: {
                    cow_id: cow.surrogate_mother?.cow_id !== null ? cow.surrogate_mother?.cow_id??0 : 0,
                    trace_id: cow.surrogate_mother?.trace_id !== null ? cow.surrogate_mother?.trace_id??"" : "", // ID
                    local_no: cow.surrogate_mother?.local_no !== null ? cow.surrogate_mother?.local_no??"" : "", // 牧場耳標
                    name: cow.surrogate_mother?.name !== null ? cow.surrogate_mother?.name??"" : "",             // 名前
                },
                start_kind: cow.start_kind,
                accept_day: acceptDay,
                accept_from: cow.accept_from??"",
                accept_price: cow.accept_price ?? 0,
                sell_plan: cow.sell_plan,
                accept_use_no: cow.accept_use_no,
                end_kind: cow.end_kind,
                end_day: endDay

            }
        });
    }
    
    api_postCowWrite = async (is_force: 0 | 1, deletesSchedule: 0 | 1 | undefined, preloadedProgram: PreloadedCowProgramReq | undefined) => {
        const cur_cow = this.state.cow;

        const trace_id = this.removeTraceIdMask(cur_cow.trace_id);

        if (trace_id.length !== TRACE_ID_LEN) {
            this.context.showToast(A.MESSAGE.INVALID_TRACE_ID);
            return;
        }

        const invalidMsg = checkInvalidCowPattern(
            this.props.rootStore.options.cow_breed,
            this.props.rootStore.options.cow_use,
            cur_cow.is_male === 1,
            cur_cow.breed_no,
            cur_cow.use_no);
        if (invalidMsg != null) {
            this.context.showToast(invalidMsg);
            return;
        }

        try {
            this.setState({ executing: true });

            const is_new = cur_cow.cow_id === 0 ? 1 : 0;

            if (is_new === 0 && !cur_cow.is_active && deletesSchedule == null) {
                const r = await this.confirmToDeleteSchedules(cur_cow.cow_id);
                if (r == null) return;
                deletesSchedule = r ? 1 : 0
            }

            const birthday = cur_cow.birthday == null ? "" : moment(cur_cow.birthday).format("YYYY-MM-DD");

            const isSelfStart = cur_cow.start_kind === A.START_KIND.get("SELF")!.no;

            const ranchId = this.props.rootStore.cur_ranch_id;

            const params:CowModifyReq = {
                cow_id: cur_cow.cow_id,
                is_new: is_new,
                ranch_id: ranchId,
                trace_id: trace_id,
                local_no: cur_cow.local_no,
                name: cur_cow.name,
                breed_no: cur_cow.breed_no,
                use_no: cur_cow.use_no,
                is_male: cur_cow.is_male,
                birthday: birthday,
                site: cur_cow.site,
                barn: cur_cow.barn,
                room: cur_cow.room,
                ancestor_1_exid: cur_cow.ancestor_1_exid,
                ancestor_2_exid: cur_cow.ancestor_2_exid,
                ancestor_3_exid: cur_cow.ancestor_3_exid,
                mother_id: cur_cow.mother_id,
                mother_name: cur_cow.mother_name,
                note: cur_cow.note,
                is_active: cur_cow.is_active ? 1 : 0,
                is_deleted: 0,
                is_force: is_force,
                delivery_id: cur_cow.delivery_id,
                delivery_child_no: cur_cow.delivery_child_no,
                surrogate_mother_id: cur_cow.surrogate_mother_id,
                surrogate_mother_name: cur_cow.surrogate_mother_name,
                start_kind: cur_cow.start_kind,
                accept_day: isSelfStart ? birthday : cur_cow.accept_day === "" ? "" : moment(cur_cow.accept_day).format("YYYY-MM-DD"),
                accept_from: isSelfStart ? "" : cur_cow.accept_from,
                accept_price: isSelfStart ? undefined : cur_cow.accept_price,
                accept_use_no: isSelfStart ? undefined : is_new === 1 ? (cur_cow.use_no === 0 ? undefined : cur_cow.use_no) : cur_cow.accept_use_no,
                sell_plan: cur_cow.sell_plan,
                delete_schedules: deletesSchedule,
                weight: cur_cow.weight,
                accept_weight: cur_cow.accept_weight,
                //新規での飼養終了時（死産）は誕生日＝飼養終了日にする
                end_day: cur_cow.is_active ? undefined
                        : is_new === 1 ? (birthday === "" ? undefined : birthday)
                        : cur_cow.end_day == null ? undefined
                        : moment(cur_cow.end_day).format("YYYY-MM-DD"),
                //新規での飼養終了は死産
                end_kind: cur_cow.is_active ? undefined : is_new === 1 ? COW_END_KIND.DEAD.no : cur_cow.end_kind,
                preloaded_program: preloadedProgram ?? { list:[], tags:[] }
            }

            //プログラム確認
            if (is_new === 1 && cur_cow.is_active && preloadedProgram == null) {
                const clinic_id = this.props.rootStore.getClinicIdForMaster();
                const pgReq = await showPreloadedCowProgramSelectDialog(
                    this.context,
                    async () => this.comm().send((await CowApi()).getProgramsForCowRegistrationUsingPOST({ eventReq:params, clinic_id })),
                    this.props.rootStore,
                    ranchId,
                    clinic_id,
                    st => this.setState({ preloadedProgram:st }),
                    CowToDispInfo({ cow_id:0, trace_id, local_no:cur_cow.local_no, name:cur_cow.name }, false)
                );
                if (pgReq == null) return false;
                preloadedProgram = pgReq;
                params.preloaded_program = pgReq;
            }

            this.context.handleSetIsLoading(true);
            const opt = { excludedErrCodes: CONFIRM_RES_CODES };
            const response = await this.comm().send((await CowApi()).modifyCowUsingPOST(params), opt);
            this.context.handleSetIsLoading(false);
            if (response.result !== "OK") return;

            const getConfirmMsg = CONFIRM_RES_CODE[response.code as ConfirmResCodeKey]?.getMsg;
            if (getConfirmMsg != null) {
                for (const msg of getConfirmMsg(trace_id)) {
                    const btn = is_new === 1 ? '登録' : '保存';
                    const result = await this.context.showDialog("QUESTION", msg, [ { type:"save", text:btn }, { type:"cancel" } ]);
                    if (result !== 0) {
                        return;
                    }
                }
                await this.api_postCowWrite(1, deletesSchedule, preloadedProgram);
                return;
            }

            //rootStoreのリストを更新しておく
            this.props.rootStore.fetchActiveCows(undefined, "DIFF");
            if (is_new === 0) {
                resetCow(cur_cow.cow_id, false);
            }

            //遷移元から渡された初期値リストが複数頭分ある場合、続けて登録をナビゲート
            if (is_new === 1 && (this.props.location.state?.defaults ?? []).length >= 2) {
                const next = await this.askNextRegister() === 0;
                if (next) {
                    const hisState = { defaults: this.props.location.state!.defaults.slice(1) };
                    this.props.history.replace(historyLocation.toCowWrite(hisState));
                    return;
                }
            }

            if (!cur_cow.is_active) {
                //未加入牧場の場合も確認しない（権限で弾かれる）
                if (hasRanchAuth("BALANCE_COW", this.props.rootStore.cur_ranch_id, this.props.rootStore.user)
                    && is_new === 0
                    && cur_cow.end_kind === COW_END_KIND.SOLD.no
                    && await this.askToSellCow() === 0) {

                    const cowId = response.data?.cow_id;
                    if (cowId != null) {
                        const lo = historyLocation.toSellCow({ cows:[
                            { cow_id: cowId,
                                trace_id,
                                local_no:cur_cow.local_no,
                                use_no:cur_cow.use_no === 0 ? undefined : cur_cow.use_no,
                                breed_no:cur_cow.breed_no === 0 ? undefined : cur_cow.breed_no,
                                name:cur_cow.name,
                                watching: 0,
                                watching_memo: ""
                            }
                        ] });
                        this.props.history.replace(lo);
                        return;
                    }
                }
                if (is_new === 1) {
                    //死産で登録
                    this.props.history.replace(historyLocation.toRanchMain());
                } else {
                    window.history.go(-2);
                }
            } else {
                window.history.go(-1);
            }
        } finally {
            this.setState({ executing: false });
        }
    }

    async confirmToDeleteSchedules(cowId: number): Promise<boolean | undefined> {
        const tomorrow = moment().add(1, "days").startOf("day");
        const res = await this.comm().send((await RanchApi()).getScheduleListUsingPOST({
            ranch_id: this.props.rootStore.cur_ranch_id,
            cow_id: [cowId],
            status: 0,
            start_at: tomorrow.format("YYYY-MM-DD"),
            //2年後まで確認対象とする
            end_at: moment(tomorrow).add(2, "year").format("YYYY-MM-DD")
        }));
        if (res.result !== "OK") return undefined;
        if ((res.data ?? []).length === 0) return false;

        const toDel = await this.context.showDialog("QUESTION", "明日以降のこの牛の予定を削除しますか？", DIALOG_BUTTONS.YES_NO_CANCEL);
        if (toDel == 2 || toDel == null) return undefined;
        return toDel === 0;
    }

    async askNextRegister() {
        return this.context.showDialog("QUESTION", "続けて次の子牛を登録しますか？", [{ type:"decide", text:"子牛を登録" }, { type:"cancel", text:"終了" }]);
    }
    async askToSellCow() {
        return this.context.showDialog("QUESTION", "牛個体販売の売上を入力しますか？", [{ type:"decide", text:"売上を入力" }, { type:"cancel", text:"終了" }]);
    }


    api_postCowDelete = async () => {
        const cur_cow = this.state.cow;
        let params = {
            is_new: 0,
            cow_id: cur_cow.cow_id,
            user_id: this.props.rootStore.user.id,
            ranch_id: this.props.rootStore.cur_ranch_id,
            is_deleted: 1
        }
        console.log(params);

        this.context.handleSetIsLoading(true);
        const response = await this.comm().send(() => this.context.postAsync('/cow/modify', params));
        this.context.handleSetIsLoading(false);
        if (response.result !== "OK") return;

        //rootStoreのリストを更新しておく
        this.props.rootStore.fetchActiveCows(undefined, "DIFF");
        // 削除後は牛一覧に遷移
        this.props.history.replace(historyLocation.toRanchMain());
    }

    removeTraceIdMask = (val:string) => val.replace(/[ _]/g, "");

    render() {
        const state = this.state;
        const currentSellPlans = state.cow.use_no == null ? undefined : A.SELL_PLANS(state.cow.use_no);

        const isOutside = state.cow.start_kind === A.START_KIND.get("OUTSIDE")!.no;
        const isNew = state.edit_cow_id === 0;

        const cowUseList = this.props.rootStore.getCowUses();

        if (this.state.dispStatus === "loading") return <FetchWaiter />
        if (this.state.dispStatus === "error") return <FetchError />

        return (
            <div className="page-root">
                <div className="product product-full-height">
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix p-b-0">
                            <div className="product-body">
                                <div className="form-group row-no-margin">
                                    <label className="col-form-label col-md-4 col-xs-4 text-lg-right">ID<span
                                        className="text-danger">*</span></label>
                                    <div className="col-md-4 col-xs-8">
                                        <InputMask mask="99999 9999 9" className="form-control" type="tel"
                                            data-testid="trace_id"
                                            style={{ fontSize:"1.0rem", letterSpacing:"1px" }}
                                            value={state.cow.trace_id}
                                            onChange={e => this.setState({
                                                cow: {
                                                    ...this.state.cow,
                                                    trace_id: e.target.value
                                                }
                                            })} />
                                        { this.state.hasTraceIdWarning && (
                                            <div style={{ marginTop:"2px", color:"red" }}>※正しくない個体識別番号です</div>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group row-no-margin">
                                    <label className="col-form-label col-md-4 col-xs-4 text-lg-right">牧場耳標</label>
                                    <div className="col-md-4 col-xs-8">
                                        <input type="text" className="form-control" value={state.cow.local_no}
                                            data-testid="local_no"
                                            maxLength={LMT.COW.LOCAL_NO_LEN}
                                            onChange={(e) => this.onValueChange(e, 'cow.local_no')} placeholder="" />
                                    </div>
                                </div>
                                <div className="form-group row-no-margin">
                                    <label className="col-form-label col-md-4 col-xs-4 text-lg-right">名前</label>
                                    <div className="col-md-4 col-xs-8">
                                        <input type="text" className="form-control" value={state.cow.name}
                                            data-testid="name"
                                            maxLength={LMT.COW.NAME_LEN}
                                            onChange={(e) => this.onValueChange(e, 'cow.name')} placeholder="" />
                                    </div>
                                </div>
                                <div className="form-group row-no-margin">
                                    <label className="col-form-label col-xs-4 col-md-4 text-lg-right">性別</label>
                                    <div className="col-md-4 col-xs-8" data-testid="sex">
                                        <div className="radio radio-css m-l-10 radio-inline">
                                            <input type="radio" name="radio_sex" id="sexRadio1" value="1"
                                                onChange={(e) => this.onNumValueChange(e, 'cow.is_male')}
                                                checked={state.cow.is_male === 1} />
                                            <label htmlFor="sexRadio1">オス</label>
                                        </div>
                                        <div className="radio radio-css m-l-10 radio-inline">
                                            <input type="radio" name="radio_sex" id="sexRadio2" value="0"
                                                onChange={(e) => this.onNumValueChange(e, 'cow.is_male')}
                                                checked={state.cow.is_male === 0} />
                                            <label htmlFor="sexRadio2">メス</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row-no-margin">
                                    <label className="col-form-label col-md-4 col-xs-4 text-lg-right">生年月日</label>
                                    <div className="col-md-4 col-xs-8" data-testid="birthday">
                                        <FormDatePicker value={state.cow.birthday} name="cow.birthday"
                                            onChange={this.onDateChange} />
                                    </div>
                                </div>
                                { isNew && state.isOfficialRanch && (
                                    <div className="form-group row-no-margin">
                                        <label className="col-form-label col-md-4 col-xs-4 text-lg-right">出生体重</label>
                                        <div className="col-md-4 col-xs-8" data-testid="weight" style={{ display:"flex", alignItems:"center" }}>
                                            <OptionalNumInput min={LMT.GROWTH.WEIGHT_MIN} max={LMT.GROWTH.WEIGHT_MAX} step={LMT.GROWTH.WEIGHT_STEP}
                                                style={{ width:"120px", marginRight:"5px" }}
                                                value={state.cow.weight}
                                                onChange={n => this.setState({ cow: { ...this.state.cow, weight:n } })} />
                                            <span>kg</span>
                                        </div>
                                    </div>
                                )}
                                <div className="form-group row-no-margin">
                                    <label className="col-form-label col-xs-4 col-md-4 text-lg-right">導入元</label>
                                    <div className="col-md-4 col-xs-8" data-testid="start_kind">
                                        { [...A.START_KIND.values()].map(s => (
                                            <div className="radio radio-css m-l-10 radio-inline" key={s.no}>
                                                <input type="radio" name="radio_start_kind" id={`startKindRadio${s.no}`} value={s.no}
                                                    onChange={(e) => this.onNumValueChange(e, 'cow.start_kind')}
                                                    checked={state.cow.start_kind === s.no} />
                                                <label htmlFor={`startKindRadio${s.no}`}>{s.name}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="form-group row-no-margin">
                                    <label className="col-form-label col-md-4 col-xs-4 text-lg-right">導入日</label>
                                    <div className="col-md-4 col-xs-8" data-testid="accept_day">
                                        { isOutside ? (
                                            <FormDatePicker value={moment(state.cow.accept_day).toDate()} name="cow.accept_day"
                                                onChange={this.onDateChange} />
                                        ) : (
                                            <div style={{ padding: "8px 12px" }}>
                                                {state.cow.birthday != null ? moment(state.cow.birthday).format("YYYY/MM/DD") : "----/--/--"}
                                                （生年月日）
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <FadeInPanel isVisible={isOutside}>
                                    <div>
                                        <div className="form-group row-no-margin">
                                            <label className="col-form-label col-md-4 col-xs-4 text-lg-right">導入牧場名</label>
                                            <div className="col-md-4 col-xs-8">
                                                <input type="text" className="form-control" value={state.cow.accept_from}
                                                    maxLength={LMT.RANCH.NAME_LEN} data-testid="accept_from"
                                                    onChange={(e) => this.onValueChange(e, 'cow.accept_from')} placeholder="" />
                                            </div>
                                        </div>
                                        { state.isOfficialRanch && (
                                            <div className="form-group row-no-margin">
                                                <label className="col-form-label col-md-4 col-xs-4 text-lg-right">導入費（円）</label>
                                                <div className="col-md-4 col-xs-8">
                                                    <RequiredNumInput value={state.cow.accept_price}
                                                        testId="accept_price"
                                                        max={LMT.COW.ACCEPT_PRICE_MAX}
                                                        min={LMT.COW.ACCEPT_PRICE_MIN}
                                                        step={LMT.COW.ACCEPT_PRICE_STEP}
                                                        onChange={n => this.onValueChange(n, "cow.accept_price")} />
                                                </div>
                                            </div>
                                        )}
                                        { isNew && state.isOfficialRanch && (
                                            <div className="form-group row-no-margin">
                                                <label className="col-form-label col-md-4 col-xs-4 text-lg-right">導入体重</label>
                                                <div className="col-md-4 col-xs-8" data-testid="accept-weight" style={{ display:"flex", alignItems:"center" }}>
                                                    <OptionalNumInput min={LMT.GROWTH.WEIGHT_MIN} max={LMT.GROWTH.WEIGHT_MAX} step={LMT.GROWTH.WEIGHT_STEP}
                                                        style={{ width:"120px", marginRight:"5px" }}
                                                        value={state.cow.accept_weight}
                                                        onChange={n => this.setState({ cow: { ...this.state.cow, accept_weight:n } })} />
                                                    <span>kg</span>
                                                </div>
                                            </div>
                                        )}
                                        { !isNew && (
                                            <div className="form-group row-no-margin">
                                                <label className="col-form-label col-md-4 col-xs-4 text-lg-right">用途（導入時）</label>
                                                {/* here */}
                                                { state.isAcceptUseEditing ? (
                                                    <div className="col-md-4 col-xs-8" data-testid="accept_use_no">
                                                    {
                                                        cowUseList.map((value, i) => (
                                                            <div key={i} className="radio radio-css m-l-10 radio-inline">
                                                                <input type="radio" id={"acceptUseRad" + i} value={value.use_no}
                                                                    onChange={() => this.setState({
                                                                        cow: {
                                                                            ...state.cow,
                                                                            accept_use_no: value.use_no
                                                                        }
                                                                    })}
                                                                    checked={state.cow.accept_use_no === value.use_no} />
                                                                <label htmlFor={"acceptUseRad" + i}>{value.name}</label>
                                                            </div>
                                                        ))
                                                    }
                                                    </div>
                                                ) : (
                                                    <div className="col-md-4 col-xs-8 m-l-10" style={{ padding: "8px 12px" }}>
                                                        { state.cow.accept_use_no == null ? "未設定" : cowUseList.find(u => u.use_no === state.cow.accept_use_no)?.name ?? ""}
                                                        <i className="fas fa-pen m-l-5 clickable" onClick={() => this.setState({ isAcceptUseEditing: true })} />
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </FadeInPanel>
                                <div className="form-group row-no-margin">
                                    <label className="col-form-label col-md-4 col-xs-4 text-lg-right">品種</label>
                                    <div className="col-md-4 col-xs-8" data-testid="breed_no">
                                        {
                                            this.props.rootStore.options.cow_breed.map((value, i) => (
                                                <div key={i} className="radio radio-css m-l-10 radio-inline">
                                                    <input type="radio" name="radio_breed" id={"breedRadio" + i} value={value.breed_no}
                                                        onChange={(e) => this.onNumValueChange(e, 'cow.breed_no')}
                                                        checked={state.cow.breed_no === value.breed_no} />
                                                    <label htmlFor={"breedRadio" + i}>{value.name}</label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="form-group row-no-margin">
                                    <label className="col-form-label col-md-4 col-xs-4 text-lg-right">用途</label>
                                    <div className="col-md-4 col-xs-8" data-testid="use_no">
                                        {
                                            cowUseList.map((value, i) => (
                                                <div key={i} className="radio radio-css m-l-10 radio-inline">
                                                    <input type="radio" id={"useRadio" + i} value={value.use_no}
                                                        onChange={() => this.setState({
                                                            cow: {
                                                                ...state.cow,
                                                                use_no: value.use_no,
                                                                sell_plan: A.SINGLE_SELL_PLAN_NO(value.use_no)
                                                            }
                                                        })}
                                                        checked={state.cow.use_no === value.use_no} />
                                                    <label htmlFor={"useRadio" + i}>{value.name}</label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <FadeInPanel isVisible={(currentSellPlans?.length ?? 0) >= 2}>
                                    <div className="form-group row-no-margin">
                                        <label className="col-form-label col-md-4 col-xs-4 text-lg-right">用途（予定）</label>
                                        <div className="col-md-4 col-xs-8" data-testid="use_no">
                                            <div className="radio radio-css m-l-10 radio-inline">
                                                <input type="radio" id={"planRadio0"}
                                                        onChange={() => this.setState({
                                                            cow: { ...state.cow, sell_plan: undefined }
                                                        })}
                                                        checked={state.cow.sell_plan == null} />
                                                <label htmlFor={"planRadio0"}>未定</label>
                                            </div>
                                            {
                                                currentSellPlans!.map(p => COW_SELL_TYPE[p])
                                                    .map(plan => (
                                                    <div key={plan.value} className="radio radio-css m-l-10 radio-inline">
                                                        <input type="radio" id={"planRadio" + plan.value}
                                                            value={plan.value}
                                                            onChange={() => this.setState({
                                                                cow: { ...state.cow, sell_plan: plan.value }
                                                            })}
                                                            checked={state.cow.sell_plan === plan.value} />
                                                        <label htmlFor={"planRadio" + plan.value}>{plan.planName}</label>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </FadeInPanel>

                                { isNew && state.isOfficialRanch && (
                                    <div className="form-group row-no-margin">
                                        <label className="col-form-label col-md-4 col-xs-4 text-lg-right">場所</label>
                                        <div className="col-md-4 col-xs-8">
                                            <select className="form-control" value={state.cow.site}
                                                data-testid="site"
                                                onChange={(e) => { this.onSiteSelected(Number(e.target.value)) }}>
                                                <option value="0">分場選択</option>
                                                {
                                                    state.location_sites.map((value, i) => (
                                                        <option key={i} value={value.no}>{value.name}</option>
                                                    ))
                                                }
                                            </select>
                                            <select className="form-control m-t-5" value={state.cow.barn}
                                                onChange={(e) => { this.onBarnSelected(Number(e.target.value)) }}
                                                data-testid="barn">
                                                <option value="0">牛舎選択</option>
                                                {
                                                    state.location_barns.map((value, i) => (
                                                        <option key={i} value={value.no}>{value.name}</option>
                                                    ))
                                                }
                                            </select>
                                            <select className="form-control m-t-5" value={state.cow.room}
                                                data-testid="room"
                                                onChange={(e) => { this.onRoomSelected(Number(e.target.value)) }}>
                                                <option value="0">部屋選択</option>
                                                {
                                                    state.location_rooms.map((value, i) => (
                                                        <option key={i} value={value.no}>{value.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                )}
                                <div className="form-group row-no-margin">
                                    <label className="col-form-label col-md-4 col-xs-4 text-lg-right">一代祖</label>
                                    <div className="col-md-4 col-xs-8">
                                        <AncestorSelect testId="ancestor_1"
                                            value={state.cow.ancestor_1_exid}
                                            selectedName={state.cow.ancestor_1}
                                            list={state.ancestorList}
                                            onChange={(id,n) => this.setState({
                                                cow: { ...this.state.cow, ancestor_1_exid: id, ancestor_1:n }
                                            })}
                                            onEditClick={() => this.setState({ isAncestorPopupShown: true })}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row-no-margin">
                                    <label className="col-form-label col-md-4 col-xs-4 text-lg-right">母牛</label>
                                    <div className="col-md-4 col-xs-8" style={{display: "flex"}} data-testid="mother">
                                        <button type="button" className="btn btn-primary" onClick={() => this.showSelectMotherPopup(false)}>牧場から選択</button>
                                        { this.state.cow.mother != null && this.state.cow.mother.cow_id > 0 && (
                                            <a className="link m-l-5" style={{alignSelf:"flex-end"}} onClick={() => this.clearMother(false)}>選択クリア</a>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group row-no-margin">
                                    <label className="col-form-label col-md-4 col-xs-4 text-lg-right">&nbsp;</label>
                                    <div className="col-md-4 col-xs-8">
                                        <input type="text" className="form-control" value={state.cow.mother_name}
                                            data-testid="mother_name"
                                            maxLength={LMT.COW.NAME_LEN}
                                            onChange={(e) => this.onValueChange(e, 'cow.mother_name')} placeholder="" 
                                            disabled={(state.cow.mother_id??0) > 0} />
                                    </div>
                                </div>
                                <div className="form-group row-no-margin">
                                    <label className="col-form-label col-md-4 col-xs-4 text-lg-right">代理母</label>
                                    <div className="col-md-4 col-xs-8" style={{display: "flex"}} data-testid="surrogate_mother">
                                        <button type="button" className="btn btn-primary" onClick={() => this.showSelectMotherPopup(true)}>牧場から選択</button>
                                        { this.state.cow.surrogate_mother != null && this.state.cow.surrogate_mother.cow_id > 0 && (
                                            <a className="link m-l-5" style={{alignSelf:"flex-end"}} onClick={() => this.clearMother(true)}>選択クリア</a>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group row-no-margin">
                                    <label className="col-form-label col-md-4 col-xs-4 text-lg-right">&nbsp;</label>
                                    <div className="col-md-4 col-xs-8">
                                        <input type="text" className="form-control" value={state.cow.surrogate_mother_name}
                                            data-testid="surrogate_mother_name"
                                            maxLength={LMT.COW.NAME_LEN}
                                            onChange={(e) => this.onValueChange(e, 'cow.surrogate_mother_name')} placeholder="" 
                                            disabled={(state.cow.surrogate_mother_id??0) > 0} />
                                    </div>
                                </div>
                                <div className="form-group row-no-margin">
                                    <label className="col-form-label col-md-4 col-xs-4 text-lg-right">二代祖</label>
                                    <div className="col-md-4 col-xs-8">
                                        <AncestorSelect testId="ancestor_2"
                                            value={state.cow.ancestor_2_exid}
                                            selectedName={state.cow.ancestor_2}
                                            list={state.ancestorList}
                                            onChange={(id,n) => this.setState({
                                                cow: { ...this.state.cow, ancestor_2_exid: id, ancestor_2:n }
                                            })}
                                            onEditClick={() => this.setState({ isAncestorPopupShown: true })}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row-no-margin">
                                    <label className="col-form-label col-md-4 col-xs-4 text-lg-right">三代祖</label>
                                    <div className="col-md-4 col-xs-8">
                                        <AncestorSelect testId="ancestor_3"
                                            value={state.cow.ancestor_3_exid}
                                            selectedName={state.cow.ancestor_3}
                                            list={state.ancestorList}
                                            onChange={(id,n) => this.setState({
                                                cow: { ...this.state.cow, ancestor_3_exid: id, ancestor_3: n }
                                            })}
                                            onEditClick={() => this.setState({ isAncestorPopupShown: true })}
                                        />
                                    </div>
                                </div>
                                { !isNew && (
                                    <div className="form-group row-no-margin">
                                        <label className="col-form-label col-md-4 col-xs-4 text-lg-right">飼養終了</label>
                                        <div className="col-md-4 col-xs-8">
                                            <div className="checkbox checkbox-css">
                                                <input type="checkbox" name="check_active" id="activeCheck"
                                                    onChange={(e) => { this.onActiveChange(!e.target.checked) }}
                                                    checked={!state.cow.is_active} />
                                                <label htmlFor="activeCheck">この牛の表示をやめる</label>
                                            </div>
                                        </div>
                                    </div>
                                )
                                }
                                <FadeInPanel isVisible={!isNew && !state.cow.is_active}>
                                    <div>
                                        <div className="form-group row-no-margin">
                                            <label className="col-form-label col-md-4 col-xs-4 text-lg-right">飼養終了日</label>
                                            <div className="col-md-4 col-xs-8" data-testid="end_day">
                                                <FormDatePicker value={state.cow.end_day} name="cow.end_day"
                                                    onChange={this.onDateChange} />
                                            </div>
                                        </div>
                                        <div className="form-group row-no-margin">
                                            <label className="col-form-label col-md-4 col-xs-4 text-lg-right">終了理由</label>
                                            <div className="col-md-4 col-xs-8" data-testid="end_kind">
                                                {
                                                    COW_END_KIND_KEYS.map(k => (
                                                        <div key={k} className="radio radio-css m-l-10 radio-inline">
                                                            <input type="radio" id={"endKindRad" + k}
                                                                value={COW_END_KIND[k].no}
                                                                onChange={() => this.setState({
                                                                    cow: { ...state.cow, end_kind: COW_END_KIND[k].no }
                                                                })}
                                                                checked={state.cow.end_kind === COW_END_KIND[k].no} />
                                                            <label htmlFor={"endKindRad" + k}>{COW_END_KIND[k].name}</label>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </FadeInPanel>
                                { isNew && !state.cow.is_active && (
                                    <div className="form-group row-no-margin">
                                        <label className="col-form-label col-md-4 col-xs-4 text-lg-right">飼養終了</label>
                                        <div className="col-md-4 col-xs-8" style={{color:"red", padding:"6px 10px", fontSize:"0.9rem"}}>※飼養終了済（死産）</div>
                                    </div>
                                )
                                }
                                <div className="form-group row-no-maring m-b-0">
                                    <label className="col-form-label text-lg-right p-l-15">メモ</label>
                                </div>
                                <div className="form-group row-no-margin">
                                    <div className="col-md-12 col-xs-12">
                                        <textarea className="form-control" rows={4} maxLength={LMT.COW.MEMO_LEN}
                                            data-testid="note"
                                            value={state.cow.note} onChange={(e) => this.onValueChange(e, "cow.note")}></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="button-page-footer">
                    <ExecutionButton type="save" onClick={this.onSave} disabled={this.state.executing} />
                    { !isNew && (
                        <ExecutionButton type="delete" onClick={this.onDelete} disabled={this.state.executing} />
                    )}
                </div>
                {
                    (state.popup_mother_open || state.popup_surrogate_mother_open) && (
                        <SelectMotherPopup
                            setIsLoading={this.context.handleSetIsLoading}
                            editing_cow_id={state.edit_cow_id}
                            iniCheckedId={state.popup_mother_open ? state.cow.mother?.cow_id : state.cow.surrogate_mother?.cow_id}
                            ranch_id={this.props.rootStore.cur_ranch_id}
                            onClose={this.closeSelectMotherPopup}
                            onSubmit={this.onMotherSelected}
                            comm={this.comm()} />
                    )
                }
                { state.isAncestorPopupShown && (
                    <SettingAncestorPopup
                        comm={this.comm()}
                        confirm={this.context.showQuestionAsync}
                        list={state.ancestorList}
                        teamId={this.props.rootStore.cur_ranch_id}
                        onUpdated={() => this.onAncestorListUpdated()}
                        onClose={() => this.setState({ isAncestorPopupShown: false })}
                    />
                )}
                { this.state.preloadedProgram && (
                    <PreloadedCowProgramSelectPopup
                        {...this.state.preloadedProgram}
                    />
                )}
            </div>
        )
    }
}

export default withRouter(withContext(CowWrite));