import React, { useState, useEffect } from 'react';
import { DeliveryAggregateTransitionMonthDto } from '../../api';
import moment from 'moment';
import { ResponsiveContainer, LineChart, Line, Legend, CartesianGrid, YAxis, XAxis, LineProps } from 'recharts';
import { enumerateStartOfMonth } from '../../config/util';

interface MyProps {
    records: DeliveryAggregateTransitionMonthDto[];
    from: Date;
    to: Date;
}

interface MonthData {
    actual: number,
    plan: number,
    month: number;
}

const convToGraphData = (from: Date, to: Date, records: DeliveryAggregateTransitionMonthDto[]): MonthData[] => {
    const targets = records.map(r => ({ ...r, ymStr: moment(r.lastday).format("YYYYMM")}));

    return enumerateStartOfMonth(from, to)
        .map(d => {
            const mom = moment(d);
            const ymStr = mom.format("YYYYMM");
            const target = targets.find(t => t.ymStr === ymStr);
            if (target == null) {
                return { actual: 0, plan: 0, month:mom.month() + 1 };
            }
            return { actual: target.actual, plan: target.plan, month: mom.month() + 1 };
        });
}

export const ReportDeliveryTransitionGraph = React.memo((props: MyProps) => {
    const [ graphData, setGraphData ] = useState<MonthData[]>([]);

    useEffect(() => {
        setGraphData(convToGraphData(props.from, props.to, props.records));

    }, [ props.records, props.from, props.to ]);

    const lineProps = (dataKind: "plan"|"actual", stroke: string): LineProps => {
        return {
            unit: "頭",
            dataKey: dataKind,
            name: `分娩${dataKind === "actual" ? "実績" : "予定"}`,
            yAxisId: 1,
            stroke,
            type: "monotoneX",
            strokeWidth: 2,
            animationDuration: 500,
            dot: { r: 2 },
            activeDot: { r: 5 },
            strokeDasharray: dataKind === "plan" ? "5 5" : undefined,
        }
    }

    return (
        <div style={{width:"calc(100%-10px)", flex: 1, position:"relative", marginLeft:"-10px"}}>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart data={graphData}>
                    <Line {...lineProps("plan", "#ff9299")} />
                    <Line {...lineProps("actual", "red")} />
                    <XAxis dataKey="month" type="category"
                        tick={{fontSize:"10px"}}
                        tickFormatter={m => m + "月"}
                        height={33}
                    />
                    <YAxis yAxisId={1} />
                    <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />

                    <Legend height={40} margin={{top:40, left:0, right:0, bottom:0}} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    )

})