import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { A, TeamType, isUnusedStock } from '../../config/constant';
import styles from './setting.module.css';
import { PageSettings } from '../../config/page-settings';
import { SeedManagementEditPopup } from './seed-management-edit-popup';
import { SeedManagementLotPopup } from './seed-management-lot-popup';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ISeed, ISeedLot } from '../../stores/RootStore';
import { withContext, ISeedType } from '../../stores';
import { IMother } from '../cow/select-mother-popup';
import { AppState } from '../../app';
import { RanchApi, ClinicApi, RanchTagDto } from '../../api';
import { CommonUtil, ar, FreezedArray } from '../../config/util';
import { CowToDispInfo } from '../../components/parts/cows-popup';
import { IconLink } from '../../components/parts/icon-link';
import { UserTeams } from '../../config/user-teams';
import { SortableList } from '../../components/parts/sortable-list';
import { FetchWaiter, FetchError } from '../../components/content/fetch-state';
import { resetSeeds, getSeeds } from '../../stores/fetcher';
import { DIALOG_BUTTONS } from '../../components/form/form-dialog';

interface MyProps extends BaseProps<{id:string}> {
}

type SeedAndStockCount = ISeed & {
    rest:number,
    total:number,
};

interface MyState {
    teamId?: number;
    teamType?: TeamType;
    seeds: { list?: { [seed_type:number]: FreezedArray<SeedAndStockCount> }, error:boolean };
    sortingList: FreezedArray<SeedAndStockCount>;
    isSorting: boolean;
    editingData: IEditingSeed | null;
    currentSeedType: number;
    selectedSeed: ISeed | null;
    sliderTab?: Slider;
    sliderContent?: Slider;
    executing: boolean;
    allTags: RanchTagDto[];
}

export type IEditingSeed = Omit<ISeed, "lots"|"father_exid"> & {
    readonly isNew: boolean;
    readonly team_id: number;
    readonly seed_type: number;
    readonly seed_id: number;
    readonly father_exid: number | undefined;
}

const getSeedDetail = (seed: SeedAndStockCount) => {
    const from = seed.get_from ?? "";
    const count = `${seed.rest}/${seed.total}`;
    return from === "" ? count : `${from} ${count}`;
}


export const GetPedigreeStr = (seed: ISeed):string => {
    if((seed.ancestor_1 ?? "") !== "") {

        let ancestor = seed.ancestor_2 ?? ""; 
        ancestor += (seed.ancestor_3 ?? "") !== "" ? `×${seed.ancestor_3}` : "";
        ancestor += (seed.ancestor_4 ?? "") !== "" ? `×${seed.ancestor_4}` : "";

        if (seed.name !== "") {
            if (ancestor !== "") {
                return `${seed.ancestor_1}×${seed.name}(${ancestor})`;
            }
            return `${seed.ancestor_1}×${seed.name}`;
        } else {
            return `${seed.ancestor_1}×${ancestor}`;
        }
    }
    if((seed.sname ?? "") !== "") {
        return `${seed.name}(${seed.sname})`;
    }
    return seed.name;
};

export const listAllSeedTags = (seedList: FreezedArray<ISeedType>) => {
    const list : { [seed_type:number]: FreezedArray<ISeed> } = {};
    seedList.forEach(st => list[st.seed_type] = st.seeds);
    return listAllTags(list);
}
const listAllTags = (seedList : { [seed_type:number]: FreezedArray<ISeed> }) => {
    const rtnMap: Map<number, RanchTagDto> = new Map();

    for (const seedType in seedList) {
        const seeds = seedList[seedType];
        if (!Array.isArray(seeds)) continue;

        for (const seed of seeds) {
            for (const lot of seed.lots) {
                for (const stock of lot.stocks) {
                    for (const tag of stock.tags) {
                        if (!rtnMap.has(tag.tag_id)) {
                            rtnMap.set(tag.tag_id, tag);
                        }
                    }
                }
            }
        }
    }

    return [...rtnMap.values()];
}

class SeedManagement extends Base<MyProps, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            seeds: { error: false },
            isSorting: false,
            sortingList: [],
            editingData: null,
            selectedSeed: null,
            currentSeedType: A.SEED_TYPE.SEED.seed_type,
            executing: false,
            allTags: [],
        };

        this.startSorting = this.startSorting.bind(this);
        this.finishSorting = this.finishSorting.bind(this);
        this.cancelSorting = this.cancelSorting.bind(this);
        this.onTabChanged = this.onTabChanged.bind(this);
        // 編集
        this.addItem = this.addItem.bind(this);
        this.startEdit = this.startEdit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onCancelEdit = this.onCancelEdit.bind(this);
        this.onSubmitEdit = this.onSubmitEdit.bind(this);
        this.onNameEdited = this.onNameEdited.bind(this);
        this.onSNameEdited = this.onSNameEdited.bind(this);
        this.onGetFromEdited = this.onGetFromEdited.bind(this);
        this.onFatherSelected = this.onFatherSelected.bind(this);
        this.onAncestor2Selected = this.onAncestor2Selected.bind(this);
        this.onAncestor3Selected = this.onAncestor3Selected.bind(this);
        this.onAncestor4Selected = this.onAncestor4Selected.bind(this);
        this.onMotherSelected = this.onMotherSelected.bind(this);
        // 仕入管理
        this.onShowSeedLot = this.onShowSeedLot.bind(this);
        this.refreshSeedList = this.refreshSeedList.bind(this);
        this.onLinkClickWithStock = this.onLinkClickWithStock.bind(this);
    }

    componentDidMount() {

        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        this.init();
    }

    componentDidUpdate(prevProps: this['props'], prevState: MyState) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.init();
        }
    }

    private async init() {
        const teamId = Number(this.props.match.params.id);
        if (this.handleNotAllowAccess(teamId, ["MASTER_EDIT"], ["MASTER_EDIT"], ["BREEDING"])) {
            return;
        }

        //TODO urlパラメータ seed_type を初期値に反映するためには、currentSeedTypeとsliderContentの連動が必要
        //const paramSeedtype = new URLSearchParams(this.props.location.search).get("seed_type");
        //const seedType = Number(paramSeedtype ?? "") === A.SEED_TYPE.EGG.seed_type ? A.SEED_TYPE.EGG : A.SEED_TYPE.SEED;

        const team = new UserTeams(this.props.rootStore.user).findTeam(teamId);
        const isRanch = CommonUtil.assertNotNull(team) && team.isRanch;

        this.context.handleSetHeader({ title:"精液・受精卵の管理", iconType:isRanch ? "ranch" :"clinic" });

        await this.setStateAsync({
            teamId: teamId,
            teamType: isRanch ? "ranch" : "clinic",
            currentSeedType: A.SEED_TYPE.SEED.seed_type,
        });

        await this.reloadList(false);
    }

    private async reloadList(showsLoading = true) {
        const teamId = this.state.teamId;
        if (teamId == null || teamId === 0) return;
        if (this.state.teamType == null) return;

        if (showsLoading) this.context.handleSetIsLoading(true);

        await resetSeeds(teamId);
        const items = await getSeeds(this.state.teamType, teamId);
        
        if (showsLoading) this.context.handleSetIsLoading(false);

        if (items == null) {
            this.setState({ seeds:{ error:true }});
            return;
        }

        const newList:{ [seed_type:number]: FreezedArray<SeedAndStockCount> } = {};
        for (const item of items) {
            const seeds = item.seeds.map(seed => {
                const total = ar.sum(seed.lots.map(l => l.stock_count));
                const rest = ar.sum(seed.lots.map(l => l.stocks.filter(s => isUnusedStock(s)).length));
                return { ...seed, total, rest };
            })
            newList[item.seed_type] = seeds;
        }
        const allTags = listAllTags(newList);

        this.setState({
            seeds: { list:newList, error:false },
            allTags
        });
    }

    private refreshSeedList(seed_type:number, seed_id:number, lots: ISeedLot[]) {   //TODO 依存方向の見直し
        if (!CommonUtil.assertNotNull(this.state.seeds.list, "seeds")) return;

        const newSeedList = { ...this.state.seeds.list };
        const newSeeds = newSeedList[seed_type].map(s => {
            if (s.seed_id !== seed_id) return s;

            return {
                ...s,
                lots: lots,
                total: ar.sum(lots.map(l => l.stock_count)),
                rest: ar.sum(lots.map(l => l.stocks.filter(s => isUnusedStock(s)).length))
            };
        });
        newSeedList[seed_type] = newSeeds;
        this.setState({
            seeds: { list:newSeedList, error:false },
            allTags:listAllTags(newSeedList)
        })
    }

    private startSorting() {
        if (this.state.isSorting) {
            console.error("already sorting");
            return;
        }
        if (!CommonUtil.assertNotNull(this.state.seeds.list,  "seeds")) return;

        this.setState({
            isSorting: true,
            sortingList:　[...this.state.seeds.list[this.state.currentSeedType]??[]]
        });
    }
    private cancelSorting() {
        if (!this.state.isSorting) {
            console.error("not sotring on cancel");
            return;
        }

        this.setState({
            isSorting: false,
            sortingList:[]
        });
    }
    private async finishSorting() {
        if (!CommonUtil.assertNotNull(this.state.teamId)) return;
        if (!CommonUtil.assertNotNull(this.state.teamType)) return;
        if (!CommonUtil.assertNotNull(this.state.seeds.list, "seeds")) return;

        if (!this.state.isSorting) {
            console.error("not sorting on finish");
            return;
        }

        if (this.state.seeds.list[this.state.currentSeedType] == null) {
            this.setState({
                isSorting: false,
                sortingList:[]
            });
            return;
        }

        const newOrder = this.state.sortingList.map(p => p.seed_id);
        const oldOrder = this.state.seeds.list[this.state.currentSeedType].map(p => p.seed_id);
        if (JSON.stringify(newOrder) === JSON.stringify(oldOrder)) {
            this.setState({
                isSorting: false,
                sortingList:[]
            });
            return;
        }

        //ソート要求
        this.context.handleSetIsLoading(true);
        const comReq = {
            order: newOrder,
            seed_type: this.state.currentSeedType,
        };
        const postSome = this.state.teamType === "ranch"
                        ? (await RanchApi()).sortSeedUsingPOST({ ...comReq, ranch_id: this.state.teamId })
                        : (await ClinicApi()).sortClinicSeedUsingPOST({ ...comReq, clinic_id: this.state.teamId });
        const res = await this.comm().send(postSome);
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") return;

        this.setState({
            isSorting: false,
            sortingList:[]
        });

        this.reloadList();
    }

    private addItem() {
        if (!this.assertNotNull(this.state.teamId, "teamId", "addItem")) return;
        this.setState({
            editingData: {
                isNew: true,
                team_id: this.state.teamId,
                seed_type: this.state.currentSeedType,
                seed_id: 0,
                name: "",
                sname: undefined,
                get_from: undefined,
                cow_id: undefined,
                father_exid: undefined,
            }
        })
    }

    private startEdit(seed: ISeed) {
        if (!this.assertNotNull(this.state.teamId, "teamId", "startEdit")) return;
        this.setState({
            editingData: {
                isNew: false,
                team_id: this.state.teamId,
                seed_type: this.state.currentSeedType,
                seed_id: seed.seed_id,
                name: seed.name ?? "",
                sname: seed.sname,
                get_from: seed.get_from,
                cow_id: seed.cow_id,
                ancestor_1: seed.ancestor_1,
                ancestor_2: seed.ancestor_2,
                ancestor_3: seed.ancestor_3,
                ancestor_4: seed.ancestor_4,
                father_exid: seed.father_exid,
                ancestor_2_exid: seed.ancestor_2_exid,
                ancestor_3_exid: seed.ancestor_3_exid,
                ancestor_4_exid: seed.ancestor_4_exid,
            }
        });
    }

    private assertNotNull<T>(v: T | null | undefined, name?: string, when?: string): v is T {
        if (v == null) {
            console.error(`${name ?? "value"} is null or undef when ${when ?? "assertion"} is called.`);
            return false;
        }
        return true;
    }

    private async onSubmitEdit() {
        const data = this.state.editingData;
        if (!this.assertNotNull(data, "editingData", "submitEdit")) return;
        if (!this.assertNotNull(this.state.teamType)) return;

        // 精液入力チェック
        if (data.seed_type === A.SEED_TYPE.SEED.seed_type) {
            if (data.father_exid == null) {
                this.context.showToast(A.MESSAGE.NO_SEED_NAME_INPUT);
                return;
            }
            if ((data.sname ?? "") === "") {
                this.context.showToast(A.MESSAGE.NO_SEED_SNAME_INPUT);
                return;
            }

        } else if (data.seed_type === A.SEED_TYPE.EGG.seed_type) {
            //受精卵入力チェック

            if (data.father_exid == null) {
                this.context.showToast(A.MESSAGE.NO_SEED_ANCESTOR1_INPUT);
                return;
            }

            if (data.name === "") {
                if (data.ancestor_2_exid == null || data.ancestor_3_exid == null) {
                    this.context.showToast(A.MESSAGE.NO_SEED_ANCESTOR_PATTERN_INPUT);
                    return;
                }
            }

            if ((data.get_from ?? "") === "") {
                this.context.showToast(A.MESSAGE.NO_SEED_GET_FROM_INPUT);
                return;
            }
        } else {
            console.error("invalid seed_type", data.seed_type);
            return;
        }

        try {
            this.setState({ executing: true });

            //更新要求
            this.context.handleSetIsLoading(true);
            const comReq = {
                is_new: Number(data.isNew),
                seed_type: data.seed_type,
                seed_id: data.seed_id ?? 0,
                name: data.name,
                sname: data.sname,
                get_from: data.get_from,
                cow_id: data.cow_id,
                father_exid: data.father_exid,
                ancestor_2_exid: data.ancestor_2_exid,
                ancestor_3_exid: data.ancestor_3_exid,
                ancestor_4_exid: data.ancestor_4_exid,
            };
            const postSome = this.state.teamType === "ranch"
                    ? (await RanchApi()).modifySeedUsingPOST({...comReq, ranch_id: data.team_id })
                    : (await ClinicApi()).modifyClinicSeedUsingPOST({...comReq, clinic_id: data.team_id });
            const res = await this.comm().send(postSome);
            this.context.handleSetIsLoading(false);
            if (res.result !== "OK") return;

            this.setState({
                editingData: null
            });

        } finally {
            this.setState({ executing: false });
        }

        this.reloadList();
    }

    private async onDelete() {
        const msg = `この${A.GET_SEED_TYPE(this.state.currentSeedType)?.name}に紐づく仕入の情報がすべて削除されます。削除してよろしいですか？`;
        const confirmed = await this.context.showDialog("QUESTION", msg, DIALOG_BUTTONS.DELETE_CANCEL);
        if (confirmed === 0) {
            this._execDelete();
        }
    }
    //削除実行
    private async _execDelete() {
        const data = this.state.editingData;
        if (!this.assertNotNull(data, "editingData", "delete")) return;
        if (!this.assertNotNull(data.seed_id, "seed_id", "delete")) return;
        if (!this.assertNotNull(this.state.teamType, "teamType", "delete")) return;

        this.context.handleSetIsLoading(true);
        const comReq = {
            seed_id: data.seed_id,
            seed_type: data.seed_type,
        };
        const postSome = this.state.teamType === "ranch"
                  ? (await RanchApi()).deleteSeedUsingPOST({ ...comReq, ranch_id: data.team_id })
                  : (await ClinicApi()).deleteClinicSeedUsingPOST({ ...comReq, clinic_id: data.team_id});
        const res = await this.comm().send(postSome);

        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") return;

        this.setState({
            editingData: null
        });

        this.reloadList();
    }

    private onCancelEdit() {
        this.setState({
            editingData: null,
        })
    }

    readonly sliderContentSettings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:false
    };

    readonly sliderTabSettings = {
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows:false,
        responsive: [{
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
            }
        }]
    };

    private onTabChanged(index:number) {
        this.setState({
            currentSeedType: A.GET_ALL_SEED_TYPES()[index].seed_type
        });
    }

    private onShowSeedLot(seed: ISeed) {
        if (!this.assertNotNull(this.state.teamId, "teamId", "startLotEdit")) return;
        this.setState({
            selectedSeed: seed,
        });
    }

    private onNameEdited(name: string) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onNameEdited")) return;
        const data = { ...this.state.editingData};
        data.name = name;
        this.setState({
            editingData:data
        });
    }
    private onSNameEdited(sname:string) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onSNameEdited")) return;
        const data = { ...this.state.editingData};
        data.sname = sname;
        this.setState({
            editingData:data
        });

    }
    private onGetFromEdited(get_from:string) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onGetFromEdited")) return;
        const data = { ...this.state.editingData};
        data.get_from = get_from;
        this.setState({
            editingData:data
        });
    }
    private onFatherSelected(id:number | undefined, name: string | undefined) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onFatherSelected")) return;
        const isEgg = this.state.editingData.seed_type === A.SEED_TYPE.EGG.seed_type;
        
        this.setState({
            editingData:{
                ...this.state.editingData,
                father_exid: id,
                name: isEgg ? this.state.editingData.name : (name ?? ""),
                ancestor_1: isEgg ? (name ?? "") : undefined
            }
        });
    }
    private onAncestor2Selected(id:number | undefined, name: string | undefined) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onAncestor2Selected")) return;
        const data = { ...this.state.editingData};
        data.ancestor_2_exid = id;
        data.ancestor_2 = name;
        if (id == null) {
            data.ancestor_3_exid = undefined;
            data.ancestor_4_exid = undefined;
            data.ancestor_3 = undefined;
            data.ancestor_4 = undefined;
        }
        this.setState({
            editingData:data
        });
    }
    private onAncestor3Selected(id:number | undefined, name: string | undefined) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onAncestor3Selected")) return;
        const data = { ...this.state.editingData};
        data.ancestor_3_exid = id;
        data.ancestor_3 = name;
        if (id == null) {
            data.ancestor_4_exid = undefined;
            data.ancestor_4 = undefined;
        }
        this.setState({
            editingData:data
        });
    }
    private onAncestor4Selected(id:number | undefined, name: string | undefined) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onAncestor4Selected")) return;
        this.setState({
            editingData: {
                ...this.state.editingData,
                ancestor_4_exid: id,
                ancestor_4: name
            }
        });
    }

    private onMotherSelected(mother:IMother|null|undefined) {
        if (!this.assertNotNull(this.state.editingData, "editingData", "onMotherSelected")) return;
        const data = { ...this.state.editingData};
        if (mother == null) {
            data.name = "";
            data.cow_id = undefined;
        } else {
            const name = mother.name || CowToDispInfo(mother, false);
            data.name = name;
            data.cow_id = mother.cow_id;
            data.ancestor_2_exid = mother.ancestor_1_exid;
            data.ancestor_3_exid = mother.ancestor_2_exid;
            data.ancestor_4_exid = mother.ancestor_3_exid;
            data.ancestor_2 = mother.ancestor_1;
            data.ancestor_3 = mother.ancestor_2;
            data.ancestor_4 = mother.ancestor_3;
        }
        this.setState({
            editingData:data
        });
    }

    private async onLinkClickWithStock(ranch_id: number | undefined, location:{pathname:string,search?:string,state?:any}) {
        if (ranch_id != null && this.props.rootStore.cur_ranch_id !== ranch_id) {

            this.context.handleSetIsLoading(true);
            const res = await this.props.rootStore.tryChangeRanch(ranch_id);
            this.context.handleSetIsLoading(false);
            if (res !== true) {
                this.context.showToast(A.MESSAGE.FAILED_TO_LOAD_DATA);
                return;
            }
        }

        this.props.history.push(location);
    }

    render() {
        const currentList = this.state.seeds.list == null ? [] : this.state.seeds.list[this.state.currentSeedType] ?? [];

        return (
            <div className="page-root">
                <div className="product product-full-height">
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix w-100">
                            <div className={"product-info-header " + styles["header-with-tab"]}>
                                <div className={styles["sortable-list-shoulder"]}>
                                    {!this.state.isSorting && this.state.seeds.list != null && (<span className="link" onClick={this.startSorting}>並び替え</span>)}
                                    {this.state.isSorting && (<span className="link" onClick={this.finishSorting}>完了</span>)}
                                    {this.state.isSorting && (<span className="link" onClick={this.cancelSorting}>キャンセル</span>)}
                                </div>
                            </div>
                            <div>
                                <Slider asNavFor={this.state.sliderContent}
                                        ref={s => {
                                            if (s != null && s !== this.state.sliderTab) {
                                                this.setState({ sliderTab: s });
                                            }
                                        }}
                                        swipe={!this.state.isSorting} focusOnSelect={!this.state.isSorting}
                                        {...this.sliderTabSettings}>
                                    {
                                        A.GET_ALL_SEED_TYPES().map(type => (
                                            <div key={type.seed_type} data-testid={"tab__" + type.name} className={this.state.currentSeedType === type.seed_type ? styles["tab-active"] : styles["tab-default"]}>{type.name}</div>
                                        ))
                                    }
                                </Slider>
                            </div>

                            <div className="product-body">
                                { this.state.seeds.error ? (
                                    <FetchError />
                                ) : this.state.seeds.list == null ? (
                                    <FetchWaiter />
                                ) : (
                                    <Slider className={styles.slider} asNavFor={this.state.sliderTab}
                                            ref={s => {
                                                if (s != null && s !== this.state.sliderContent) {
                                                    this.setState({ sliderContent: s });
                                                }
                                            }}
                                            afterChange={this.onTabChanged}
                                            swipe={!this.state.isSorting}
                                            {...this.sliderContentSettings}>
                                        { A.GET_ALL_SEED_TYPES().map((type) => (
                                            <div key={type.seed_type} data-testid={"seed_type__" + type.name}>
                                                <SortableList className={styles["list"] + " m-b-0"}
                                                    items={this.state.isSorting ? (this.state.currentSeedType === type.seed_type ? this.state.sortingList : []) : this.state.seeds.list![type.seed_type] ?? []}
                                                    isSorting={this.state.isSorting}
                                                    onSorted={l => this.setState({ sortingList:l })}
                                                    listItem={seed => (
                                                        <li key={seed.seed_id} className={styles["list-item"]}>
                                                            <div className={styles["list-item-content"]}>
                                                                <div data-testid="seed-name" className={styles["list-item-name"]} style={{ wordBreak: "break-all" }}>{GetPedigreeStr(seed)}</div>
                                                                <div className={styles["list-item-detail"]} style={{ wordBreak: "break-all" }}>
                                                                    <span data-testid="seed-detail">{getSeedDetail(seed)}</span>
                                                                    { this.state.isSorting ? (
                                                                        <span className="m-l-5">仕入管理</span>
                                                                    ) : (
                                                                        <a className="link m-l-5" onClick={() => this.onShowSeedLot(seed)}>仕入管理</a>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            { this.state.isSorting ? (
                                                                <div className={styles["list-item-icon"]}><i className="fas fa-bars" /></div>
                                                            ) : (
                                                                <div onClick={()=>this.startEdit(seed)} className={styles["list-item-icon"]}><i className="fas fa-pen clickable" /></div>
                                                            )}
                                                        </li>
                                                    )}
                                                />
                                            </div>
                                        ))}
                                    </Slider>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ height:"40px", marginTop:"6px", display:"flex", justifyContent:"space-between" }}>
                    <div>
                        {!this.state.isSorting && this.state.seeds.list != null && (
                            <IconLink iconType="add" text="項目を追加" onClick={this.addItem} />
                        )}
                    </div>
                    <div style={{ color:"#666666" }}>在庫計 {ar.sum(currentList.map(s => s.rest))}/{ar.sum(currentList.map(s => s.total))}</div>
                </div>
                {
                    this.state.teamId != null
                    && this.state.editingData != null
                    && (
                        <SeedManagementEditPopup 
                            data={this.state.editingData}
                            ranch_id={this.state.teamType === "ranch" ? this.state.teamId : undefined}
                            team_id={this.state.teamId}
                            onClose={this.onCancelEdit}
                            onDelete={this.onDelete}
                            onSubmit={this.onSubmitEdit}
                            onNameEdited={this.onNameEdited}
                            onSNameEdited={this.onSNameEdited}
                            onGetFromEdited={this.onGetFromEdited}
                            onFatherSelected={this.onFatherSelected}
                            onAncestor2Selected={this.onAncestor2Selected}
                            onAncestor3Selected={this.onAncestor3Selected}
                            onAncestor4Selected={this.onAncestor4Selected}
                            onMotherSelected={this.onMotherSelected}
                            comm={this.comm()}
                            isSubmitExecuting={this.state.executing}
                        />
                    )
                }
                {
                    //※ teamId, teamType が null の場合に render自体何も描画しないようにしてしまうと、
                    //   現在の作りでは sliderTab,sliderContentのrefをcomponentDidMountで取得できず、
                    //   tabクリックによる表示切り替えができなくなる
                    //   　⇒ refの参照方法を直したので解決しているかも
                    this.state.teamId != null
                    && this.state.teamType != null
                    && this.state.selectedSeed != null
                    && (
                        <SeedManagementLotPopup 
                            rootStore={this.props.rootStore}
                            seed_type={this.state.currentSeedType}
                            data={this.state.selectedSeed}
                            team_id={this.state.teamId}
                            teamType={this.state.teamType}
                            onClose={()=>{this.setState({selectedSeed: null})}}
                            refreshSeedList={this.refreshSeedList}
                            onLink={this.onLinkClickWithStock}
                            comm={this.comm()}
                            allTags={this.state.allTags}
                        />
                    )
                }
            </div>
        )
    }
}

export default withRouter(withContext(SeedManagement));