import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { A } from '../../config/constant';
import { PageSettings } from '../../config/page-settings';
import { withContext } from '../../stores';
import moment, { Moment } from 'moment';
import { CommonUtil, ar, formatYen, AGE_MODE, FreezedArray } from '../../config/util';
import { historyLocation } from '../../config/history-location-builder';
import { AppState } from '../../app';
import styles from './balance.module.css';
import { BalanceDateSelector } from './balance-date-selector';
import { BalanceApi, BalanceCowReq, BalanceCowAgeReq, BalanceCowConditionReq, BalanceCowCondCowDto, BalanceCowDeliveryTimeReq, BalanceCowDeliveryDayReq, BalanceCowDeliTimeSpanDto, BalanceCowChildrenDto, BalanceCowChildrenReq, SellCowAggregateCarcassDto, SellCowAggregateChildrenCarcassReq, SellCowApi } from '../../api';
import classnames from 'classnames';
import { BalanceGraph, BalanceGraphType, BalanceSpanData } from './balance-graph';
import { AgeSelector } from './AgeSelector';
import { CowsPopup, RenderCowNameInfo, CowToDispInfo } from '../../components/parts/cows-popup';
import { AfterDeliveryDaysSelector } from './after-delivery-days-selector';
import { BalanceChildrenViewer } from './balance-children-viewer';
import { loadCowSummary, ISearchedCow } from '../../components/header/header';
import { SearchedCowPopup } from '../../components/header/searched-cow-popup';
import { IconLink } from '../../components/parts/icon-link';
import { ReportSellCowCarcassPopup, ReportCarcassItemKey } from '../report-sellcow/report-sellcow-carcass-popup';
import { GraphPager } from 'components/parts/graph-pager';
import { loadTeamStorage, saveTeamStorage } from 'stores/local-storage';

type BalanceCondition = CowCondition|AgeCondition|DeliveryDayCondition;
interface CowCondition {
    to : Date;
    kind : "DAY"|"MONTH";
}
interface AgeCondition {
    age_to: number;
    kind: "AGE";
}
// interface DeliveryTimeCondition {
//     kind: "DELIVERY_TIME";
// }
interface DeliveryDayCondition {
    kind : "DELIVERY_DAY"|"DELIVERY_7DAYS"|"DELIVERY_30DAYS";
    time: number;
    day_to: number;
}
// interface ChildCondition {
//     kind: "CHILDREN";
// }
interface ConditionMap {
    "cow": CowCondition;
    "age": AgeCondition;
    "delivery_day": DeliveryDayCondition;
}

interface LoadedData {
    cowCount: number;
    kind: IAggregateKind;
    condition: BalanceCondition | undefined;
    values: Readonly<Readonly<BalanceSpanData>[]>;
    term: string;
    totalProfit: number;
    totalLoss: number;
    hasPrev: boolean;
    hasNext: boolean;
    deliveryDay?: Date;
}

interface MyState {
    cows: Readonly<Readonly<BalanceCowCondCowDto>[]>;
    ranch?: { ranch_id:number, name:string };
    conditionKey: AggregateKindKey;
    condition : Readonly<ConditionMap>;
    currentData: Readonly<LoadedData> | undefined;
    graphType: BalanceGraphType;
    childrenData: Readonly<BalanceCowChildrenDto> | undefined;
    selectedChild: Readonly<ISearchedCow> | undefined;
    childrenCarcass?: {
        carcasses: FreezedArray<SellCowAggregateCarcassDto>,
        name: string
    };
    carcassDispItems?: Set<ReportCarcassItemKey>;
}

const AGGREGATE_KINDS = ["DAY","MONTH","AGE","DELIVERY_TIME", "DELIVERY_DAY", "DELIVERY_7DAYS", "DELIVERY_30DAYS", "CHILDREN"] as const;
type AggregateKindKey = typeof AGGREGATE_KINDS[number];
type IAggregateKind = ICowAggregateKind | IAgeAggregateKind | IDeliveryTimeAggregateKind | IDeliveryDayAggregateKind | IChildAggregateKind;
interface ICowAggregateKind {
    api: "cow";
    name: string;
    span: string;
    graphSpan: number;
    graphSpanUnit: "day"|"month";
    dispSpanInfo: string;
    newPageEndDay: (pre:Date, move:number) => Date;
}
interface IAgeAggregateKind {
    api: "age";
    name: string;
    exCowHint: string;
    graphSpan: number;
    newPageTo: (pre:number, move:number) => number;
}
interface IDeliveryTimeAggregateKind {
    api: "delivery_time";
    name: string;
    exCowHint: string;
}
interface IDeliveryDayAggregateKind {
    api: "delivery_day";
    name: string;
    graphSpan: number;
    graphSpanUnit: "day";
    exCowHint: string;
    daysInGroup: number;
    newPageTo: (pre:number, move:number) => number;
}
interface IChildAggregateKind {
    api:"children";
    name: string;
    disable: (cows:Readonly<Readonly<BalanceCowCondCowDto>[]>) => boolean;
}

const AGGREGATE_KIND: {[key in AggregateKindKey]: IAggregateKind } = {
    DAY:    { api:"cow", name: "日別",     span:"day",    graphSpan:14,  graphSpanUnit:"day",   dispSpanInfo:"14日間",
                newPageEndDay: (pre, move) => moment(pre).add(13 * move, "days").toDate() },
    MONTH:  { api:"cow", name: "月別",     span:"month",  graphSpan:13,  graphSpanUnit:"month", dispSpanInfo:"1年間",
                newPageEndDay: (pre, move) => moment(pre).add(12 * move, "months").endOf("month").startOf("day").toDate() },
    AGE:    { api:"age", name:"月齢別", graphSpan: 13, exCowHint:"生年月日未設定を除外",
                newPageTo: (pre, move) => Math.max(pre + (12 * move), 0) },
    DELIVERY_TIME: { api:"delivery_time", name:"産次別", exCowHint: "繁殖牛/搾乳牛のみ" },
    DELIVERY_DAY: { api: "delivery_day", name:"産後日別", graphSpan:14, graphSpanUnit:"day", exCowHint: "繁殖牛/搾乳牛のみ", daysInGroup:1,
                    newPageTo: (pre, move) => pre + (13 * move) },
    DELIVERY_7DAYS: { api: "delivery_day", name:"産後7日単位", graphSpan:7*13, graphSpanUnit:"day", exCowHint: "繁殖牛/搾乳牛のみ", daysInGroup:7,
                        newPageTo: (pre, move) => pre + (7*12 * move) },
    DELIVERY_30DAYS: { api: "delivery_day", name:"産後30日単位", graphSpan:30*13, graphSpanUnit:"day", exCowHint: "繁殖牛/搾乳牛のみ", daysInGroup:30,
                        newPageTo: (pre, move) => pre + (30*12 * move) },
    CHILDREN: { api: "children", name:"産子の収支", disable: (cows => cows.length !== 1 || cows[0].is_male === 1) }
}

const calcStartDay = (end: Date, kind:ICowAggregateKind) => {
    const m = moment(end);
    if (kind.graphSpanUnit === "month") {
        return m.add(kind.graphSpan * -1 + 1, "months").startOf("month").toDate();
    }
    if (kind.graphSpanUnit === "day") {
        return m.add(kind.graphSpan * -1 + 1, "days").toDate();
    }
    console.error("unknown kind", kind);
    return end;
}

class BalanceCow extends Base<BaseProps, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        const today = moment().startOf("day").toDate();

        this.state = {
            cows: [],
            conditionKey: "DAY",
            condition: {
                "cow": {
                    to: today,
                    kind: "DAY"
                },
                "age": {
                    age_to: 1,
                    kind: "AGE"
                },
                "delivery_day": {
                    kind: "DELIVERY_DAY",
                    time: 1,
                    day_to: 1
                }
            },
            currentData: undefined,
            graphType: "balance",
            childrenData: undefined,
            selectedChild: undefined
        };
    }

    componentDidMount() {

        this.context.handleSetHeader({ title:"収支" });
        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        if (this.handleNotAllowAccess(undefined, ["BALANCE_COW"],[])) {
            return;
        }

        this.init();
    }

    componentDidUpdate(prevProps: this["props"], prevState: MyState) {
        if (this.props.location.search !== prevProps.location.search) {
            this.init();
            return;
        }
    }

    async init() {
        const ranchId = this.props.rootStore.cur_ranch_id;
        const ranchName = this.props.rootStore.getCurRanchName() ?? "";

        const parm = new URLSearchParams(this.props.location.search).get("param");
        if (parm == null) return;

        const cowIds =  parm.split(",")
                            .map(p => parseInt(p))
                            .filter(p => !isNaN(p));
        
        const today = moment().startOf("day");
        
        //※削除済みの牛のIDはレスポンスに含まれない
        const cows = await this.loadCowInfo(ranchId, today, cowIds);

        const maxDeliTimes = Math.max(...cows.map(c => c.delivery_times));

        const calcDays = (to: moment.Moment, from: string | undefined) => {
            if (from == null) return 0;
            const fromDay = moment(from);
            if (!fromDay.isValid) return 0;
            return to.diff(fromDay, "days");
        }

        const maxDaysAfterDeli = maxDeliTimes === 0
            ? 0
            : Math.max(...cows.filter(c => c.delivery_times === maxDeliTimes)
                            .map(c => calcDays(today, c.last_delivery_day)));

        const savedCarcassItems = loadTeamStorage<ReportCarcassItemKey[]>("report_carcass_disp_item", ranchId);

        await this.setStateAsync({
            cows,
            ranch: { ranch_id: ranchId, name:ranchName },
            condition: {
                cow: this.state.condition.cow,
                age: { kind: "AGE", age_to: Math.max(...cows.map(c => c.age ?? 0))},
                delivery_day: { kind: "DELIVERY_DAY", time: maxDeliTimes, day_to: maxDaysAfterDeli + 1 }
            },
            carcassDispItems: savedCarcassItems == null ? undefined : new Set(savedCarcassItems)
        });

        console.log("ini condition", this.state.condition);

        if (this.state.ranch != null && cows.length > 0) {
            this.loadGraph();
        }

    }

    loadCowInfo = async (ranchId: number, today: moment.Moment, cowIds: number[]): Promise<BalanceCowCondCowDto[]> => {
        const req: BalanceCowConditionReq = {
            cow_ids: cowIds,
            date: today.format("YYYY-MM-DD"),
            ranch_id: ranchId
        };
        const res = await this.comm().send((await BalanceApi()).getBalanceCowConditionUsingPOST(req));
        if (res.result !== "OK") return [];
        return res.data?.cows ?? [];
    }

    loadGraph = async (kind?: IAggregateKind, condition?: BalanceCondition) => {
        if (!CommonUtil.assertNotNull(this.state.ranch, "ranch", "loadGraph")) return;
        const ranchId = this.state.ranch.ranch_id;

        if (kind == null) {
            kind = AGGREGATE_KIND[this.state.conditionKey];
            condition = this.state.condition[kind.api];
        }

        if (kind.api === "children") {
            await this.setStateAsync({currentData:undefined});

            this.context.handleSetIsLoading(true);
            const req: BalanceCowChildrenReq = {
                ranch_id: ranchId, cow_id: this.state.cows[0].cow_id
            };
            const res = await this.comm().send((await BalanceApi()).getChildrenBalanceUsingPOST(req));
            this.context.handleSetIsLoading(false);
            if (res.result !== "OK") return;
            await this.setStateAsync({ childrenData: res.data });
            return;
        }

        await this.setStateAsync({ childrenData: undefined });

        this.context.handleSetIsLoading(true);

        let data: LoadedData | null;
        if (kind.api === "cow") {
            if (condition == null || !("to" in condition)) {
                console.error("invalid condition to load", condition, kind);
                return;
            }
            data = await this.loadCowGraphData(ranchId, kind, condition);

        } else if (kind.api === "age") {
            if (condition == null || !("age_to" in condition)) {
                console.error("invalid condition to load", condition, kind);
                return;
            }
            data = await this.loadAgeGraphData(ranchId, kind, condition);

        } else if (kind.api === "delivery_time") {
            data = await this.loadDeliTimeData(ranchId);

        } else if (kind.api === "delivery_day") {
            if (condition == null || !("day_to" in condition)) {
                console.error("invalid condition to load", condition, kind);
                return;
            }
            data = await this.loadDeliDayData(ranchId, kind, condition);

        } else {
            console.error("unknown kind", kind);
            this.context.handleSetIsLoading(false);
            this.setState({currentData:undefined});
            return;
        }

        this.context.handleSetIsLoading(false);
        if (data == null) {
            return;
        }

        await this.setStateAsync({
            currentData: data
        });
    }

    async loadDeliDayData(ranchId: number, kind: IDeliveryDayAggregateKind, cond: DeliveryDayCondition): Promise<LoadedData | null> {
        const breedCows = this.state.cows.filter(c => A.IS_FOR_BREEDING_COW(c.use_no ?? 0));
        const reqCows = breedCows.filter(c => cond.time <= c.delivery_times);

        if (reqCows.length === 0) {
            console.error("invalid state when load deli day data");
            return Promise.resolve(null);
        }

        //「何日まで」と選択された前日までのデータを取得（7日単位で「7日まで」なら、0日後～6日後をひとまとまりとするため）
        const reqTo = cond.day_to - 1;
        const reqFrom = reqTo - kind.graphSpan + 1;

        const req: BalanceCowDeliveryDayReq = {
            ranch_id: ranchId,
            cow_ids: reqCows.map(c => c.cow_id),
            delivery_time: cond.time,
            day_to: reqTo,
            day_span: kind.daysInGroup,
            day_from: reqFrom,
        };

        const res = await this.comm().send((await BalanceApi()).getCowDeliveryDayBalanceUsingPOST(req));
        if (res.result !== "OK" || res.data == null) return null;

        const dayToStr = (d:number) => d < 0 ? `${d * -1}日前` : `${d}日後`;
        const term = `${req.delivery_time}産次 ${dayToStr(req.day_from)} ～ ${dayToStr(req.day_to)}`;

        return ({
            condition: cond,
            cowCount: breedCows.length,
            values: res.data.values.map(v => (
                { ...v,
                    label: v.span_from.toString(),
                    span_from: dayToStr(v.span_from),
                    span_to: dayToStr(v.span_to),
                    additional: `${req.delivery_time}産次: ${reqCows.length}頭`
                })),
            kind: kind,
            term: term,
            totalProfit: ar.sum(res.data.values.map(d => ar.sum(d.profits.map(p => p.price)))),
            totalLoss: ar.sum(res.data.values.map(d => ar.sum(d.losses.map(l => l.price)))),
            hasNext: true,
            hasPrev: true,
            deliveryDay: res.data.delivery_day == null ? undefined : moment(res.data.delivery_day).toDate()
        });
    }

    async loadDeliTimeData(ranchId: number): Promise<LoadedData | null> {
        const cowIds = this.state.cows.filter(c => A.IS_FOR_BREEDING_COW(c.use_no ?? 0)).map(c => c.cow_id);
        if (cowIds.length === 0) {
            console.error("invalid state when load deli time data");
            return Promise.resolve(null);
        }

        const req: BalanceCowDeliveryTimeReq = {
            ranch_id: ranchId,
            cow_ids: cowIds,
        };

        const res = await this.comm().send((await BalanceApi()).getCowDeliveryTimeBalanceUsingPOST(req));
        if (res.result !== "OK" || res.data == null) return null;

        const spans = res.data.values;
        const term = spans.length === 0 ? "分娩記録なし"
                : spans.length === 1 ? "1産次"
                : `1 ～ ${spans.length}産次`;
        
        const getSpanInfo = (s: BalanceCowDeliTimeSpanDto) => `${s.time}産次: ${s.cow_ids.length}頭`;
                
        return ({
            condition: undefined,
            cowCount: req.cow_ids.length,
            values: spans.map(v => ({ ...v, label: v.time.toString(), span_from: getSpanInfo(v), span_to: getSpanInfo(v) })),
            kind: AGGREGATE_KIND[this.state.conditionKey],
            term: term,
            totalProfit: ar.sum(res.data.values.map(d => ar.sum(d.profits.map(p => p.price)))),
            totalLoss: ar.sum(res.data.values.map(d => ar.sum(d.losses.map(l => l.price)))),
            hasPrev: false,
            hasNext: false
        });
    }
    async loadAgeGraphData(ranchId: number, kind: IAgeAggregateKind, cond: AgeCondition): Promise<LoadedData | null> {
        const age_from = Math.max(cond.age_to - kind.graphSpan + 1, 0);
        const req: BalanceCowAgeReq = {
            cow_ids: this.state.cows.filter(c => c.age != null).map(c => c.cow_id),
            ranch_id: ranchId,
            from: age_from,
            to: cond.age_to,
        };

        const res = await this.comm().send((await BalanceApi()).getCowAgeBalanceUsingPOST(req));
        if (res.result !== "OK" || res.data == null) return null;
        
        const dto = res.data;
        return ({
            condition:cond,
            cowCount: req.cow_ids.length,
            values: dto.values,
            kind: kind,
            term: `${req.from} ～ ${req.to}ヵ月齢`,
            totalProfit: ar.sum(dto.values.map(d => ar.sum(d.profits.map(p => p.price)))),
            totalLoss: ar.sum(dto.values.map(d => ar.sum(d.losses.map(l => l.price)))),
            hasNext: true,
            hasPrev: 0 < cond.age_to
        });
    }
    async loadCowGraphData(ranchId: number, kind: ICowAggregateKind, cond: CowCondition): Promise<LoadedData | null> {
        const from = calcStartDay(cond.to, kind);

        const req: BalanceCowReq = {
            from: moment(from).format("YYYY-MM-DD"),
            to: moment(cond.to).format("YYYY-MM-DD"),
            ranch_id: ranchId,
            span: kind.span,
            cow_ids: this.state.cows.map(c => c.cow_id)
        };
        const res = await this.comm().send((await BalanceApi()).getCowBalanceUsingPOST(req));
        if (res.result !== "OK" || res.data == null) return null;

        const dto = res.data;
        return ({
            condition: cond,
            cowCount: req.cow_ids.length,
            values: dto.values,
            kind: kind,
            term: moment(from).format("YYYY/M/D") + " ～ " + moment(cond.to).format("YYYY/M/D"),
            totalProfit: ar.sum(dto.values.map(d => ar.sum(d.profits.map(p => p.price)))),
            totalLoss: ar.sum(dto.values.map(d => ar.sum(d.losses.map(l => l.price)))),
            hasPrev: true,
            hasNext: true
        });
    }
    

    onAggrKindChanged(value: string) {
        if (!AGGREGATE_KINDS.some(k => k === value)) {
            console.error("unknown selected kind", value);
            return;
        }

        this.setState({
            conditionKey: value as AggregateKindKey
        });
    }
    onDateChanged(date: Date) {
        const old = this.state.condition["cow"];
        this.setState({
            condition: { ...this.state.condition, "cow": { ...old, to: date } }
        });
    }
    onAgeChanged(age: number) {
        const old = this.state.condition["age"];
        this.setState({
            condition: { ...this.state.condition, "age": { ...old, age_to: age } }
        });
    }
    onDeliDayChanged(time:number, days:number) {

        const old = this.state.condition["delivery_day"];
        this.setState({
            condition: { ...this.state.condition, delivery_day: { ...old, time:time, day_to: days}}
        })
    }
    async onNewPage(move: number) {
        if (!CommonUtil.assertNotNull(this.state.currentData, "currentData", "newPage")) return;
        const oldCond = this.state.currentData.condition;
        if (!CommonUtil.assertNotNull(oldCond, "loaded condition", "newPage")) return;
        const kind = this.state.currentData.kind;
        let newCond: BalanceCondition;
        if (kind.api === "cow") {
            if (!("to" in oldCond)) {
                console.error("invalid loaded condition", kind, oldCond);
                return;
            }
            newCond = {
                ...oldCond,
                to: kind.newPageEndDay(oldCond.to, move)
            };
        } else if (kind.api === "age") {
            if (!("age_to" in oldCond)) {
                console.error("invalid loaded condition", kind, oldCond);
                return;
            }
            newCond = {
                ...oldCond,
                age_to: kind.newPageTo(oldCond.age_to, move)
            };
        } else if (kind.api === "delivery_day") {
            if (!("day_to" in oldCond)) {
                console.error("invalid loaded condition", kind, oldCond);
                return;
            }
            newCond = {
                ...oldCond,
                day_to: kind.newPageTo(oldCond.day_to, move)
            }
        } else {
            console.error("invalid call onNewPage", kind);
            return;
        }

        await this.loadGraph(kind, newCond);
    }

    async onChildClick(cowId: number) {
        if (!CommonUtil.assertNotNull(this.state.ranch, "ranch", "onChildClick")) return;

        const info = await loadCowSummary(this.state.ranch.ranch_id, this.context, cowId, 0, this.logout);
        if (info == null) return;

        await this.props.rootStore.fetchSettings(this.state.ranch.ranch_id);

        await this.setStateAsync({ selectedChild:info.cow })
    }

    onDaySelected(label: string) {
        console.log("onDaySelect", label);

        let day: moment.Moment;

        if (!CommonUtil.assertNotNull(this.state.currentData, "currentData", "onDaySelect")) return;
        const kind = this.state.currentData.kind;

        if (kind.api === "cow" && kind.graphSpanUnit === "day") {
            day = moment(label, "YYYY/M/D");
        } else if (kind.api === "delivery_day") {
            if (this.state.currentData.deliveryDay == null) return;

            const add = Number(label);
            if (isNaN(add)) {
                console.error("invalid label", label);
                return;
            }
            
            day = moment(this.state.currentData.deliveryDay).add(add, "days");
        } else {
            console.error("invalid condition on graph select", kind);
            return;
        }

        this.props.history.push(historyLocation.toCowEvent(this.state.cows[0].cow_id, day.format("YYYY-MM-DD")))
    }

    async onChildrenCarcassClick() {
        if (this.state.cows.length !== 1) {
            console.error("invalid cows state on children carcass show", this.state.cows);
            return;
        }
        const mother = this.state.cows[0];
        if (!CommonUtil.assertNotNull(this.state.ranch, "ranch", "onChildrenCarcassClick")) return;
        const ranchId = this.state.ranch.ranch_id;
        const req: SellCowAggregateChildrenCarcassReq = {
            cow_id: mother.cow_id,
            ranch_id: ranchId,
            includesNoCarcass: true
        };

        this.context.handleSetIsLoading(true);
        const res = await this.comm().send((await SellCowApi()).getChildrenCarcassListUsingPOST(req));

        this.context.handleSetIsLoading(false);
        if (res.result !== "OK" || res.data == null) return;

        if (res.data.length === 0) {
            this.context.showToast("出荷成績の記録がありません");
            return;
        }

        this.setState({
            childrenCarcass: {
                carcasses: res.data,
                name: CowToDispInfo(mother, false)
            }
        })

    }

    onCarcassDispItemsUpdated(items: Set<ReportCarcassItemKey>) {
        if (!CommonUtil.assertNotNull(this.state.ranch, "ranch")) return;

        this.setState({ carcassDispItems:items });
        saveTeamStorage("report_carcass_disp_item", this.state.ranch.ranch_id, [...items]);
    }
 
    render() {
        const cows = this.state.cows;
        if (this.state.ranch == null || cows.length === 0) {
            return <></>
        }

        const condKind = AGGREGATE_KIND[this.state.conditionKey];
        const data = this.state.currentData;

        const maxDeliTime = Math.max(...this.state.cows.map(c => c.delivery_times));

        const canExec = condKind.api === "cow"
                    || condKind.api === "children"
                    || (condKind.api === "age" && cows.some(c => c.age != null))
                    || (condKind.api === "delivery_time" && cows.some(c => A.IS_FOR_BREEDING_COW(c.use_no ?? 0) && c.delivery_times > 0))
                    || (condKind.api === "delivery_day" && cows.some(c => A.IS_FOR_BREEDING_COW(c.use_no ?? 0) && c.delivery_times > 0));

        const canSelect = (data != null)
                        && ((data.kind.api === "cow" && data.kind.graphSpanUnit === "day")
                           || (data.kind.api === "delivery_day" && data.kind.daysInGroup === 1 && data.deliveryDay != null));

        return (
            <div className="page-root">
                <div className="product" style={{ height: "100%", "minHeight": "0" }}>
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix p-b-0">
                            <div className={classnames("product-info-header", styles["content-header"])}>
                                { cows.length === 1 && (
                                    <>
                                    { RenderCowNameInfo(cows[0]) }
                                    </>
                                )}
                                { cows.length > 1 && (
                                    <>
                                        <span>{cows.length}頭の収支 </span>
                                        {/* ※ここでは要観察は表示なしとする */}
                                        <CowsPopup height="25px" cows={cows.map(c => ({ ...c, watching:0, watching_memo:"" }))} placement="bottom" />
                                    </>
                                )}
                            </div>

                            <div style={{ flex: "auto", overflowY:"auto" }}>
                                <div className={classnames(styles["graph-select-container"], styles["row"])}>
                                    <select className="form-control"
                                        value={this.state.conditionKey}
                                        onChange={e => this.onAggrKindChanged(e.target.value)}>
                                        { AGGREGATE_KINDS.map(k => ({ key: k, kind: AGGREGATE_KIND[k]}))
                                            .filter(a => !("disable" in a.kind) || !a.kind.disable(cows))
                                            .map(a => (
                                            <option key={a.key} value={a.key}>{a.kind.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className={styles["term-selector-row"]}>
                                    { canExec && condKind.api === "cow" && (
                                        <BalanceDateSelector
                                            selectUnit={condKind.graphSpanUnit}
                                            selectedDate={this.state.condition.cow.to}
                                            onDateChanged={d => this.onDateChanged(d)}
                                            spanInfo={condKind.dispSpanInfo} />
                                    )}
                                    { canExec && condKind.api === "age" && (
                                        <AgeSelector
                                            selectedValue={this.state.condition.age.age_to}
                                            onChange={a => this.onAgeChanged(a)} />
                                    )}
                                    { canExec && condKind.api === "delivery_day" && (
                                        <AfterDeliveryDaysSelector
                                            dayStep={condKind.daysInGroup}
                                            onChange={(t,d) => this.onDeliDayChanged(t,d)}
                                            selectedDays={this.state.condition.delivery_day.day_to}
                                            selectedTime={this.state.condition.delivery_day.time}
                                            maxTime={maxDeliTime} />
                                    )}
                                    { !canExec && (
                                        <div>表示可能な牛はいません</div>
                                    )}
                                </div>

                                <div className={styles["load-button-row"]}>
                                    <button className="btn btn-green" onClick={() => this.loadGraph()} disabled={!canExec}>
                                        <i className="far fa-arrow-alt-circle-down"></i>
                                        <span> 再集計</span>
                                    </button>
                                </div>

                                { data != null && (
                                    <div className={styles["data-container"]}>
                                        <GraphPager containerClassName={styles["graph-header"]}
                                            hasPrev={data.hasPrev} hasNext={data.hasNext}
                                            onPrev={() => this.onNewPage(-1)}
                                            onNext={() => this.onNewPage(1)}
                                        >
                                            <div className={styles.summary}>
                                                <span>対象 {data.cowCount}頭{data.cowCount < cows.length && ("exCowHint" in data.kind) ? `（${data.kind.exCowHint}）` : ""}</span><br />
                                                <span>売上 {formatYen(data.totalProfit)}　経費 {formatYen(data.totalLoss)}　収支 {formatYen(data.totalProfit - data.totalLoss)}</span><br />
                                                <span>（{data.term}）</span>
                                            </div>
                                        </GraphPager>
                                        <div className={styles["disptype-selector"]}>
                                            <div className="radio radio-css">
                                                <input type="radio" id="radBalance" checked={this.state.graphType === "balance"}
                                                    onChange={() => this.setState({graphType:"balance"})} />
                                                <label htmlFor="radBalance">収支</label>
                                            </div>
                                            <div className="radio radio-css">
                                                <input type="radio" id="radProfit" checked={this.state.graphType === "profit"}
                                                    onChange={() => this.setState({graphType:"profit"})} />
                                                <label htmlFor="radProfit">売上内訳</label>
                                            </div>
                                            <div className="radio radio-css">
                                                <input type="radio" id="radLoss" checked={this.state.graphType === "loss"}
                                                    onChange={() => this.setState({graphType:"loss"})} />
                                                <label htmlFor="radLoss">経費内訳</label>
                                            </div>
                                        </div>
                                        <BalanceGraph
                                            dispType={this.state.graphType}
                                            data={data.values}
                                            onSelect={canSelect ? l => this.onDaySelected(l) : undefined}
                                        />
                                    </div>
                                )}
                                { this.state.childrenData != null && (
                                    <div>
                                        <BalanceChildrenViewer
                                            mother={this.state.cows[0]}
                                            data={this.state.childrenData}
                                            onCowSelected={id => this.onChildClick(id)}
                                        />
                                        <div className="m-t-10 m-b-10" style={{ textAlign:"right" }}>
                                            <IconLink iconType="popup" text="出荷成績一覧"
                                                onClick={() => this.onChildrenCarcassClick()}
                                            />
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
                { this.state.selectedChild != null && (
                    <SearchedCowPopup
                        ranch_id={this.state.ranch.ranch_id}
                        user={this.props.rootStore.user}
                        onClose={()=>this.setState({selectedChild:undefined})}
                        cow={this.state.selectedChild}
                        ageMode={this.props.rootStore.getSettings(this.state.ranch.ranch_id)?.age_mode ?? AGE_MODE.BIRTHDAY}
                        crossHis={[]}
                        deliveryHis={[]}
                        useList={this.props.rootStore.options.cow_use}
                        breedList={this.props.rootStore.options.cow_breed}
                        feedTypeList={this.props.rootStore.options.feed_type}
                        fecesColorList={this.props.rootStore.options.feces_color}
                        fecesStateList={this.props.rootStore.options.feces_state}
                        treatKindList={this.props.rootStore.options.treat_kind}
                        onCowLinkClick={()=> {
                            const cowId = this.state.selectedChild!.cow_id;
                            this.setState({ selectedChild: undefined });
                            this.props.history.push(historyLocation.toCowInfo(cowId));
                        }}
                        showsEvents={false}
                        showsIsActive={true}
                    />
                )}
                { this.state.childrenCarcass != null && (
                    <ReportSellCowCarcassPopup
                        term={undefined}
                        onClose={() => this.setState({ childrenCarcass: undefined })}
                        carcasses={this.state.childrenCarcass.carcasses}
                        name={this.state.childrenCarcass.name}
                        onCowLinkClick={id => this.props.history.push(historyLocation.toCowInfo(id))}
                        dispItemKeys={this.state.carcassDispItems}
                        onKeysUpdated={items => this.onCarcassDispItemsUpdated(items)}
                    />

                )}
            </div>
        )
    }
}

export default withRouter(withContext(BalanceCow));