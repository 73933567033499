import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { LMT } from '../../config/constant';
import { PageSettings } from '../../config/page-settings.js';
import { FreezedArray, saveBase64File, generateKey, ar } from "../../config/util";
import { withContext } from '../../stores';
import moment from 'moment';
import { DiseaseListReq, SymptomApi, DiseaseListEventDto, CertDiseaseReq, CertKarteReqCow } from '../../api';
import { IEventWriteParamCow, ICowsLocationState } from '../../config/history-location-builder';
import { AppState } from '../../app';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { UserTeams } from '../../config/user-teams';
import { CowToDispInfo, LocalNoOrRepNo } from '../../components/parts/cows-popup';
import { RequiredTermSelector } from '../../components/parts/optional-term-selector';
import styles from './cert-disease.module.css';
import { FormInputBlock, FormCheckInputBlock } from '../../components/form/form-input-block';
import { InfoPopup } from '../../components/parts/info-popup';
import { updateListInUserStorage, loadUserStorage } from '../../stores/local-storage';
import { CertDiseaseListCondition, CertDiseaseConditionSelectPopup } from './cert-disease-condition-select-popup';
import { CertDiseaseOutput, CertDiseaseOutputSelectPopup } from './cert-disease-output-select-popup';
import { IconRight } from '../../components/parts/icon-right';

type Event = DiseaseListEventDto & { key:string, summary:string, detail:string };

interface MyState {
    executing : boolean;
    searchFrom: Date;
    searchTo: Date;
    condition: CertDiseaseListCondition;
    output: CertDiseaseOutput;
    cowDataList: FreezedArray<CowData>;
    ranchId: number;
    clinicId: number;
    allCows: FreezedArray<IEventWriteParamCow>;
    ignoredCowReps: string[];
    clinicNameList: string[];
    addressList: string[];
    receiverList: string[];
    isConditionShown: boolean;
    isOutputShown: boolean;
}

type CowData = {
    cow_id: number;
    repNo: string;
    dispName: string;
    events: FreezedArray<Event>;
    selectedEvent?: Event;
}

interface MyProps extends BaseProps<{id:string},{}, ICowsLocationState|undefined> {
}

class CertDisease extends Base<MyProps, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        const today = moment().startOf("day").toDate();

        this.state = {
            executing: false,
            searchFrom: today,
            searchTo: today,
            condition: { hasBreeding:true, hasSymptom:true, firstOnly:false },
            output: { publishDay:today, occurDay:today, clinicName: "", address: "", tel: "", receiver: "" },
            cowDataList: [],
            ranchId: 0,
            clinicId: 0,
            allCows:[],
            ignoredCowReps: [],
            clinicNameList:[],
            addressList:[],
            receiverList:[],
            isConditionShown:false,
            isOutputShown:false,
        };
    }

    componentDidMount() {
        const ranchId = this.props.rootStore.cur_ranch_id;
        const user = this.props.rootStore.user;
        const userTeams = new UserTeams(user);
        const clinic = userTeams.findSingleClinic();
        if (clinic == null) {
            this.props.rootStore.logout();
            return;
        }
        if (this.handleNotAllowAccess(ranchId, ["TREAT_REF_PRICE"], [])) {
            return;
        }
        if (this.handleNotAllowAccess(clinic.team_id, [], ["MASTER_REF"])) {
            return;
        }

        this.context.handleSetHeader({ title:"事故発生通知出力" });
        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        const allCows = this.props.location.state?.cows ?? [];
        if (allCows.length === 0) return;

        const clinicNameList: string[] = loadUserStorage("cert_my_clinic_name", user.id) ?? [];
        const addressList: string[] = loadUserStorage("cert_my_clinic_address", user.id) ?? [];
        const receiverList: string[] = loadUserStorage("cert_receiver", user.id) ?? [];

        //診療所設定がないときは最新履歴を初期値に
        let clinicName = clinic.name;
        if (clinicName === "") {
            clinicName = clinicNameList[0] ?? "";
        }
        let address = clinic.address ?? "";
        if (address === "") {
            address = addressList[0] ?? "";
        }

        this.setState({
            ranchId,
            clinicId:clinic.team_id,
            output: {
                ...this.state.output,
                clinicName,
                address,
                tel: clinic.tel ?? "",
                receiver: receiverList[0] ?? ""
            },
            allCows,
            clinicNameList,
            addressList,
            receiverList,
        }, () => {
            this.search();
        });
    }

    private setLoading(loading: boolean) {
        this.context.handleSetIsLoading(loading);
        this.setState({ executing: loading });
    }

    private buildEventSummary = (ev:DiseaseListEventDto) => `${moment(ev.watched_at).format("M/D")} ${ev.diseases.join("、")}`;
    private buildEventDetail = (ev:DiseaseListEventDto) => `${ev.watched_by_name ?? ""}（${ev.nth_in_series}回目）`;

    private search = async () => {
        const req: DiseaseListReq = {
            ranch_id: this.state.ranchId,
            clinic_id: this.state.clinicId,
            from: moment(this.state.searchFrom).format("YYYY-MM-DD"),
            to: moment(this.state.searchTo).format("YYYY-MM-DD"),
            breeding: this.state.condition.hasBreeding,
            symptom: this.state.condition.hasSymptom,
            nth_in_series: this.state.condition.firstOnly ? 1 : undefined,
            cow_ids: this.state.allCows.map(c => c.cow_id)
        };

        this.setLoading(true);
        const res = await this.comm().send((await SymptomApi()).getDiseasesUsingPOST(req));
        this.setLoading(false);

        if (res.result !== "OK") return;

        const resCowIdSet = new Set(res.data?.map(c => c.cow_id) ?? []);
        const ignoredCowReps = this.state.allCows.filter(c => !resCowIdSet.has(c.cow_id))
                                    .map(c => CowToDispInfo(c, false));

        const ordered = ar.orderBy(res.data ?? [], cw => LocalNoOrRepNo(cw));

        this.setState({
            cowDataList: ordered.map(data => {
                const events = data.events.map(e => ({
                    ...e,
                    key:generateKey("ev"),
                    summary: this.buildEventSummary(e),
                    detail: this.buildEventDetail(e)
                }));

                return {
                    cow_id: data.cow_id,
                    repNo: CowToDispInfo(data, false),
                    dispName: CowToDispInfo(data, true),
                    events,
                    selectedEvent: events[0]
                };
            }),
            ignoredCowReps
        });

    }

    private onSave = async () => {

        const req : CertDiseaseReq = {
            ranch_id: this.state.ranchId,
            clinic_id: this.state.clinicId,
            clinic_address: this.state.output.address,
            clinic_name: this.state.output.clinicName,
            clinic_tel: this.state.output.tel,
            receiver: this.state.output.receiver,
            published_on: moment(this.state.output.publishDay).format("YYYY-MM-DD"),
            occured_on: moment(this.state.output.occurDay).format("YYYY-MM-DD"),
            cows: this.state.cowDataList
                        .filter(c => c.selectedEvent != null)
                        .map(c => ({ cow_id: c.cow_id, diseases: c.selectedEvent!.diseases })),
        };

        this.setLoading(true);
        const res = await this.comm().send((await SymptomApi()).getCertDiseaseUsingPOST(req))
        this.setLoading(false);

        if (res.result !== "OK" || res.data == null) return;

        saveBase64File(res.data.body, res.data.file_name, res.data.content_type);

        const userId = this.props.rootStore.user.id;
        updateListInUserStorage("cert_my_clinic_address", userId, req.clinic_address ?? "", LMT.CERT.CLINIC_ADDRESS_HIS_COUNT);
        updateListInUserStorage("cert_my_clinic_name", userId, req.clinic_name ?? "", LMT.CERT.CLINIC_NAME_HIS_COUNT);
        updateListInUserStorage("cert_receiver", userId, req.receiver ?? "", LMT.CERT.RECEIVER_HIS_COUNT);
    }

    private buildIgnoredCowsMsg = (): string[] => {
        if (this.state.ignoredCowReps.length === 0) return [];

        const MAX = 10;
        const isOver = this.state.ignoredCowReps.length > MAX;

        const rtn = [
            "以下の牛の記録は見つかりませんでした",
            ...(isOver
                ? this.state.ignoredCowReps.slice(0, MAX)
                : this.state.ignoredCowReps
            ),
        ];
        if (isOver) {
            rtn.push(`他 ${this.state.ignoredCowReps.length - MAX}頭`);
        }
        return rtn;
    }

    render() {
        const cowsToExport = this.state.cowDataList.filter(c => c.selectedEvent != null).length;
        const isOverLimit = LMT.CERT.DISEASE_MAX_COWS < cowsToExport;
        const canExport = cowsToExport !== 0 && !isOverLimit;

        const today = moment().startOf("day");
        const maxStSearchDate = today.toDate();
        const minSearchDate = moment(today).subtract(LMT.CERT.DISEASE_MAX_BACK_MONTH, "month").toDate();

        const outputSummary = `${moment(this.state.output.occurDay).format("M/D")} 発生、${moment(this.state.output.publishDay).format("M/D")} 通知`; 

        return (
            <div className="page-root width-limit">
                <div className="product product-full-height">
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix p-b-0">

                            <div className="product-info-header">
                                <div className="link" onClick={() => this.setState({ isConditionShown: true })}>詳細条件</div>
                                <div className={styles.header}>
                                    <RequiredTermSelector
                                        minStDate={minSearchDate}
                                        maxStDate={maxStSearchDate}
                                        minEdDate={minSearchDate}
                                        maxMonthSpan={LMT.CERT.DISEASE_MAX_MONTH_SPAN}
                                        stValue={this.state.searchFrom}
                                        edValue={this.state.searchTo}
                                        onChange={(st,ed) => this.setState({ searchFrom:st, searchTo:ed })}
                                    />
                                    <button className="btn btn-green" onClick={this.search}>検索</button>
                                </div>
                            </div>

                            <div className={"product-body " + styles.body}>
                                <div className={styles["selection-info"]}>
                                    <span className={styles["cow-count"]}>{cowsToExport} / {this.state.allCows.length}頭 選択中</span>
                                    { this.state.ignoredCowReps.length > 0 && (
                                        <InfoPopup iconType="warning" placement="bottom"
                                            message={this.buildIgnoredCowsMsg()}
                                        />
                                    )}
                                    { isOverLimit && <span className={styles["over-limit"]}>1度に{LMT.CERT.DISEASE_MAX_COWS}頭の選択が可能です</span>}
                                </div>
                                <FormInputBlock label="出力内容">
                                    <div className={styles["output-summary"]}>
                                        <span>{outputSummary}</span>
                                        <IconRight iconType="edit" className="m-l-10" onClick={() => this.setState({ isOutputShown:true })} />
                                    </div>
                                </FormInputBlock>
                                { this.state.cowDataList.map(cw => (
                                    <FormCheckInputBlock key={cw.cow_id} id={"chkCow" + cw.cow_id}
                                        label={cw.repNo}
                                        labelClassName={styles["repno"]}
                                        checked={cw.selectedEvent != null}
                                        onChange={checked => this.setState({
                                            cowDataList: this.state.cowDataList.map(tmpCw => tmpCw.cow_id !== cw.cow_id ? tmpCw : ({
                                                ...cw,
                                                selectedEvent: checked ? cw.events[0] : undefined
                                            }))
                                        })}>
                                        <select className="form-control" value={cw.selectedEvent?.key}
                                            onChange={e => this.setState({
                                                cowDataList: this.state.cowDataList.map(tmpCw => tmpCw.cow_id !== cw.cow_id ? tmpCw : ({
                                                    ...cw,
                                                    selectedEvent: cw.events.find(ev => ev.key === e.target.value)
                                                }))
                                            })}
                                            disabled={cw.selectedEvent == null}>
                                            { cw.events.map(ev => (
                                                <option key={ev.key} value={ev.key}>{ev.summary}</option>
                                            ))}
                                        </select>
                                        { cw.selectedEvent != null && (<div className={styles["event-detail"]}>{cw.selectedEvent.detail}</div>)}
                                    </FormCheckInputBlock>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="button-page-footer">
                    <ExecutionButton type="save" onClick={this.onSave} disabled={this.state.executing || !canExport}>出力</ExecutionButton>
                </div>
                { this.state.isConditionShown && (
                    <CertDiseaseConditionSelectPopup
                        condition={this.state.condition}
                        onClose={() => this.setState({ isConditionShown:false })}
                        onSubmit={d => this.setState({ condition: d, isConditionShown:false })}
                    />
                )}
                { this.state.isOutputShown && (
                    <CertDiseaseOutputSelectPopup
                        output={this.state.output}
                        onClose={() => this.setState({ isOutputShown:false })}
                        onSubmit={d => this.setState({ output:d, isOutputShown:false })}
                        addressList={this.state.addressList}
                        clinicNameList={this.state.clinicNameList}
                        receiverList={this.state.receiverList}
                    />
                )}
            </div>
        )
    }
}

export default withRouter(withContext(CertDisease));