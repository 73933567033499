import React, { useState, useEffect, useMemo } from 'react';
import { CalendarFilterCondition } from './ranch-calendar';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ExecutionButton } from 'components/buttons/execution-button';
import { IUser } from 'stores';
import { EventKind } from 'config/event-kind';
import { ModalInputBlock } from 'components/parts/modal-input-block';
import { Checkbox } from 'components/form/form-checkbox';
import { MultiCheckbox } from 'components/form/form-multi-checkbox';
import styles from './schedule.module.css';
import { UserTeams } from 'config/user-teams';
import { useRanchHouses } from 'stores/fetcher';
import { FetchWaiter, FetchError } from 'components/content/fetch-state';

interface Props {
    ranchId: number | undefined;
    isOfficialRanch: boolean;
    user: IUser;
    condition: CalendarFilterCondition;
    onSubmit: (condition: CalendarFilterCondition) => void;
    onClose: () => void;
}

export const CalendarFilterPopup = (props: Props) => {
    const [ eventKinds, setEventKinds ] = useState<Set<number>>(new Set());
    const [ ranchIds, setRanchIds ] = useState<Set<number>>(new Set());
    const [ isIncompletedOnly, setIsIncompletedOnly ] = useState(false);
    const [ allEventKinds, setAllEventKinds ] = useState<{ name: string, value: number }[]>([]);
    const [ allRanches, setAllRanches ] = useState<{ name:string, value:number }[]>([]);
    const [ allSites, setAllSites ] = useState<{ name: string, value:number }[]>([]);
    const [ sites, setSites ] = useState<Set<number>>(new Set());

    const officialRanchId = props.isOfficialRanch ? props.ranchId : undefined;

    const mHouse = useRanchHouses(officialRanchId, false);

    const canSubmit = useMemo(() => {
        if (eventKinds.size === 0) return false;
        if (props.ranchId == null && ranchIds.size === 0) return false;
        if (officialRanchId != null && sites.size === 0) return false;

        return true;

    }, [ eventKinds, ranchIds, sites, props.ranchId, props.isOfficialRanch ]);

    useEffect(() => {
        if (officialRanchId != null && mHouse.data == null) return;

        const events = props.ranchId != null
                    ? EventKind.forSchedule(props.ranchId, props.user)
                    : EventKind.forScheduleWithClinicUser();
        const allKinds = events.map(e => ({ name:e.schedule.sname, value:e.no }));
        setAllEventKinds(allKinds);

        const ranches = props.ranchId != null ? [] : new UserTeams(props.user).allRanches();
        setAllRanches(ranches.map(r => ({ name:r.name, value:r.team_id })));

        const allSites = (officialRanchId == null || mHouse.data == null)
                        ? []
                        : [
                            { value:0, name: "牛なし/分場未設定" },
                            ...mHouse.data.map(h => ({ value:h.no, name: h.name }))
                        ];
        setAllSites(allSites);

        setEventKinds(new Set(props.condition.eventKinds ?? allKinds.map(e => e.value)));
        setRanchIds(new Set(props.condition.ranchIds ?? ranches.map(r => r.team_id)));
        setIsIncompletedOnly(props.condition.isIncompletedOnly);
        setSites(new Set(props.condition.sites ?? allSites.map(s => s.value)));

    }, [ props.condition, props.user, props.ranchId, props.isOfficialRanch, mHouse.data ]);

    const onEventKindAllCheckChange = (checked: boolean) => {
        if (checked) {
            setEventKinds(new Set(allEventKinds.map(e => e.value)));
        } else {
            setEventKinds(new Set());
        }
    }
    const onRanchAllCheckChange = (checked: boolean) => {
        if (checked) {
            setRanchIds(new Set(allRanches.map(e => e.value)));
        } else {
            setRanchIds(new Set());
        }
    }
    const onSiteAllCheckChange = (checked: boolean) => {
        if (checked) {
            setSites(new Set(allSites.map(e => e.value)));
        } else {
            setSites(new Set());
        }
    }

    const onSubmit = () => {
        let kinds: number[] | undefined = [...eventKinds];
        if (kinds.length === allEventKinds.length) {
            kinds = undefined;
        }
        let rIds: number[] | undefined = [...ranchIds];
        if (rIds.length === allRanches.length) {
            rIds = undefined;
        }
        let si: number[] | undefined = [...sites];
        if (si.length === allSites.length) {
            si = undefined;
        }
        props.onSubmit({ eventKinds: kinds, isIncompletedOnly, ranchIds:rIds, sites:si });
    }

    return (
        <div>
            <Modal isOpen={true} scrollable={true}>
                <ModalHeader toggle={props.onClose}>絞込み条件</ModalHeader>
                <ModalBody>
                    { props.ranchId == null && (
                        <ModalInputBlock label="牧場" labelSize="s" contentClassName={styles["filter-content"]} containerClassName={styles["filter-ranch"]}>
                            <Checkbox label="全選択" checked={allRanches.length === ranchIds.size}
                                onChange={e => onRanchAllCheckChange(e.target.checked)}
                                id="chkAllRanch"
                            />
                            <div className="row-no-margin">
                                <MultiCheckbox
                                    prefix="chkRanch"
                                    allowsNoSelect={true}
                                    onChange={setRanchIds}
                                    options={allRanches}
                                    values={ranchIds}
                                    className={styles["item"]}
                                />
                            </div>
                        </ModalInputBlock>
                    )}
                    <ModalInputBlock label="種別" labelSize="s" contentClassName={styles["filter-content"]} containerClassName={styles["filter-event-kind"]}>
                        <Checkbox label="全選択" checked={allEventKinds.length === eventKinds.size}
                            onChange={e => onEventKindAllCheckChange(e.target.checked)}
                            id="chkAllKind"
                        />
                        <div className="row-no-margin">
                            <MultiCheckbox
                                prefix="chkKind"
                                allowsNoSelect={true}
                                onChange={setEventKinds}
                                options={allEventKinds}
                                values={eventKinds}
                                className={styles["item"]}
                            />
                        </div>
                    </ModalInputBlock>
                    <ModalInputBlock label="状態" labelSize="s" contentClassName={styles["filter-content"]}>
                        <Checkbox id="chkIncompletedOnly"
                            label="未完了のみ" checked={isIncompletedOnly}
                            onChange={e => setIsIncompletedOnly(e.target.checked)} />
                    </ModalInputBlock>
                    { officialRanchId != null && (
                        <ModalInputBlock label="分場" labelSize="s" contentClassName={styles["filter-content"]} containerClassName={styles["filter-site"]}>
                            { mHouse.isLoading ? (
                                <FetchWaiter />
                            ) : (mHouse.isError || mHouse.data == null) ? (
                                <FetchError />
                            ) : (<>
                                <Checkbox label="全選択" checked={allSites.length === sites.size}
                                    onChange={e => onSiteAllCheckChange(e.target.checked)}
                                    id="chkAllSite"
                                />
                                <div className="row-no-margin">
                                    <MultiCheckbox
                                        prefix="chkSite"
                                        allowsNoSelect={true}
                                        onChange={setSites}
                                        options={allSites}
                                        values={sites}
                                        className={styles["item"]}
                                    />
                                </div>
                            </>)}
                        </ModalInputBlock>
                    )}
                </ModalBody>
                <ModalFooter className="modal-footer-fix">
                    <ExecutionButton type="decide" disabled={!canSubmit} onClick={onSubmit} />
                </ModalFooter>
            </Modal>
        </div>
    )

}