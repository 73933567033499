import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { A, VisitFeeType, VISIT_FEE_TYPE, VISIT_FEE_TYPES } from '../../config/constant';
import styles from './setting.module.css';
import { PageSettings } from '../../config/page-settings';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withContext } from '../../stores';
import { AppState } from '../../app';
import { ClinicVisitFeeModifyReq, ClinicVisitFeeDto, ClinicVisitFeeSortReq, ClinicApi, ClinicVisitFeeDeleteReq } from '../../api';
import { CommonUtil, FreezedArray } from '../../config/util';
import { DIALOG_BUTTONS } from '../../components/form/form-dialog';
import { VisitFeeEditPopup } from './visit-fee-edit-popup';
import { SortableList } from '../../components/parts/sortable-list';
import { useVisitFees, resetVisitFees } from '../../stores/fetcher';
import { FetchWaiter, FetchError } from '../../components/content/fetch-state';

export type EditingVisitFee = Omit<ClinicVisitFeeModifyReq, "clinic_id">;

interface MyProps extends BaseProps<{id:string}> {
}

interface MyState {
    teamId?: number;
    executing: boolean;
}

class VisitFeeMaster extends Base<MyProps, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            executing: false,
        };
    }

    componentDidMount() {

        this.context.handleSetHeader({ title:"診療固定費の設定", iconType:"clinic" });
        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        this.init();
    }

    componentDidUpdate(prevProps: this['props']) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.init();
        }
    }

    private init() {
        const team_id = Number(this.props.match.params.id);
        if (this.handleNotAllowAccess(team_id,["MASTER_EDIT"],["MASTER_EDIT"])) {
            return;
        }

        this.setState({
            teamId: team_id
        });
    }


    private finishSorting = async (feeType: number, newOrder: number[]): Promise<boolean> => {
        if (!CommonUtil.assertNotNull(this.state.teamId, "teamId")) return false;

        this.setState({ executing: true });
        this.context.handleSetIsLoading(true);
        try {
            const req: ClinicVisitFeeSortReq = {
                clinic_id: this.state.teamId,
                order: newOrder,
                fee_type: feeType
            };
            const res = await this.comm().send((await ClinicApi()).sortVisitFeeUsingPOST(req));
            if (res.result !== "OK") return false;

            await resetVisitFees(false);
            return true;

        } finally {
            this.context.handleSetIsLoading(false);
            this.setState({ executing:false });
        }
    }

    private onSubmitEdit = async (data: EditingVisitFee): Promise<boolean> => {
        if (!CommonUtil.assertNotNull(this.state.teamId, "teamId")) return false;

        if (data.name === "") {
            this.context.showToast(A.MESSAGE.NO_TREAT_NAME_INPUT);
            return false;
        }

        try {
            this.setState({ executing: true });
            this.context.handleSetIsLoading(true);

            const req: ClinicVisitFeeModifyReq = {
                ...data,
                clinic_id: this.state.teamId,
            };
            const res = await this.comm().send((await ClinicApi()).modifyVisitFeeUsingPOST(req), { excludedErrCodes:[ A.ERR_CODE.INVALID_PARAM ] });
            if (res.result !== "OK") return false;

            if (res.code === A.ERR_CODE.INVALID_PARAM) {
                this.context.showDialog("NOTIFY", "入力内容に誤りがあります", undefined, { subTexts:[ "増加料金は距離に重複がないように設定してください" ]});
                return false;
            }

            await resetVisitFees(false);
            return true;

        } finally {
            this.context.handleSetIsLoading(false);
            this.setState({ executing: false });
        }
    }

    private onDelete = async (feeType: number, feeNo: number): Promise<boolean> => {
        if (!CommonUtil.assertNotNull(this.state.teamId, "teamId")) return false;

        const confirmed = await this.context.showDialog("QUESTION", "診療費項目を削除してよろしいですか？", DIALOG_BUTTONS.DELETE_CANCEL);
        if (confirmed !== 0) return false;

        try {
            this.setState({ executing:true });
            this.context.handleSetIsLoading(true);

            const req: ClinicVisitFeeDeleteReq = {
                clinic_id: this.state.teamId,
                fee_type: feeType,
                fee_no: feeNo
            };
            const res = await this.comm().send((await ClinicApi()).deleteVisitFeeUsingPOST(req));
            if (res.result !== "OK") return false;

            await resetVisitFees(false);
            return true;

        } finally {
            this.context.handleSetIsLoading(false);
            this.setState({ executing:false });
        }
    }

    render() {
        if (this.state.teamId == null) return <></>

        return (
            <VisitFeeMasterContent
                teamId={this.state.teamId}
                executing={this.state.executing}
                finishSorting={this.finishSorting}
                onSubmitEdit={this.onSubmitEdit}
                onDelete={this.onDelete}
            />
        )
    }
}

interface ContentProps {
    teamId: number;
    executing: boolean;
    finishSorting: (feeType: number, newOrder: number[]) => Promise<boolean>;
    onSubmitEdit: (data: EditingVisitFee) => Promise<boolean>;
    onDelete: (feeType: number, feeNo: number) => Promise<boolean>;
}

const VisitFeeMasterContent = React.memo((props: ContentProps) => {

    const feeList = useVisitFees(props.teamId);
    const [ refTab, setRefTab ] = useState<Slider>();
    const [ refContent, setRefContent ] = useState<Slider>();

    const [ sortingList, setSortingList ] = useState<FreezedArray<ClinicVisitFeeDto>>();
    const [ currentFeeType, setCurrentFeeType ] = useState<VisitFeeType>(VISIT_FEE_TYPES[0]);
    const [ editingData, setEditingData ] = useState<EditingVisitFee>();

    const [ groupedList, setGroupedList ] = useState<{ [key in VisitFeeType]: ClinicVisitFeeDto[] }>();

    useEffect(() => {
        if (feeList.data == null) {
            setGroupedList(undefined);
            return;
        }

        const grouped = CommonUtil.groupBy(feeList.data, f => f.fee_type);

        setGroupedList({
            FIRST: grouped.get(VISIT_FEE_TYPE.FIRST.no) ?? [],
            VISITING: grouped.get(VISIT_FEE_TYPE.VISITING.no) ?? [],
            CAR: grouped.get(VISIT_FEE_TYPE.CAR.no) ?? [],
        });

    }, [ feeList.data ]);

    const addItem = useCallback(() => {
        setEditingData({
            fee_type: VISIT_FEE_TYPE[currentFeeType].no,
            fee: 0,
            name: ""
        });

    }, [ currentFeeType ]);

    const startSorting = useCallback(() => {
        if (!CommonUtil.assertNotNull(groupedList, "groupedList")) return;

        setSortingList(groupedList[currentFeeType]);

    }, [ groupedList, currentFeeType ]);

    const finishSorting = useCallback(async () => {
        if (!CommonUtil.assertNotNull(sortingList, "sortingList")) return;
        if (!CommonUtil.assertNotNull(groupedList, "groupedList")) return;

        const old = groupedList[currentFeeType];

        const newOrder = sortingList.map(s => s.fee_no);
        const oldOrder = old.map(o => o.fee_no);
        if (JSON.stringify(newOrder) === JSON.stringify(oldOrder)) {
            setSortingList(undefined);
            return;
        }

        const res = await props.finishSorting(VISIT_FEE_TYPE[currentFeeType].no, newOrder);
        if (res === true) {
            setSortingList(undefined);
        }

    }, [ currentFeeType, sortingList, groupedList, props.finishSorting ]);

    const onDelete = useCallback(async () => {
        if (!CommonUtil.assertNotNull(editingData, "editingData")) return;
        if (!CommonUtil.assertNotNull(editingData.fee_no, "fee_no")) return;

        const res = await props.onDelete(editingData.fee_type, editingData.fee_no);
        if (res === true) {
            setEditingData(undefined);
        }

    }, [ props.onDelete, editingData ]);

    const onSubmitEdit = useCallback(async (data: EditingVisitFee) => {
        const res = await props.onSubmitEdit(data);
        if (res === true) {
            setEditingData(undefined);
        }

    }, [ props.onSubmitEdit ]);

    const buildDetails = useCallback((item: ClinicVisitFeeDto) => {
        const basic = [
            `${item.fee}円`,
            (item.point_a ?? "") !== "" ? `A:${item.point_a}点` : undefined,
            (item.point_b ?? "") !== "" ? `B:${item.point_b}点` : undefined,
            (item.code ?? "") !== "" ? `(${item.code})` : undefined
        ].filter(s => s != null).join(" ");

        const steps = item.steps.map(st => {
            const range = st.distance_to == null ? `${st.distance_from}km` : `${st.distance_from}-${st.distance_to}km`;
            const step = st.distance_step == null ? "" : `/${st.distance_step}km毎`;
            const add = [
                `${st.fee}円`,
                (st.point_a ?? "") !== "" ? `A:${st.point_a}点` : undefined,
                (st.point_b ?? "") !== "" ? `B:${st.point_b}点` : undefined,
            ].filter(s => s != null).join(" ")
            return `(${range}${step} ${add})`;
        });

        return [ basic, ...steps ];
    }, []);

    const isSorting = sortingList != null;

    const sliderContentSettings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:false
    };
    const sliderTabSettings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows:false,
        responsive: [{
            breakpoint: 768,
            settings: {
                slidesToShow: 3,
            }
        }]
    };
    
    return (
        <div className="page-root">
            <div className="product product-full-height">
                <div className="product-detail" style={{ height: "100%" }}>
                    {/* ※width:100がないと、幅が広くなった時にレイアウトが壊れる */}
                    <div className="product-info product-info-fix w-100">
                        <div className={"product-info-header " + styles["header-with-tab"]}>
                            <div className={styles["sortable-list-shoulder"]}>
                                {!isSorting && (<span className="link" onClick={startSorting}>並び替え</span>)}
                                {isSorting && (<span className="link" onClick={finishSorting}>完了</span>)}
                                {isSorting && (<span className="link" onClick={() => setSortingList(undefined)}>キャンセル</span>)}
                            </div>
                        </div>
                        <div>
                            <Slider asNavFor={refContent} ref={s => setRefTab(s ?? undefined)}
                                    swipe={!isSorting} focusOnSelect={!isSorting}
                                    {...sliderTabSettings}>
                                {
                                    VISIT_FEE_TYPES.map(t => (
                                        <div key={t} className={currentFeeType === t ? styles["tab-active"] : styles["tab-default"]}
                                            >{VISIT_FEE_TYPE[t].name}</div>
                                    ))
                                }
                            </Slider>
                        </div>

                        <div className="product-body">
                            { feeList.isLoading ? (
                                <FetchWaiter />
                            ) : (feeList.isError || groupedList == null) ? (
                                <FetchError />
                            ) : (
                                <Slider className={styles.slider} asNavFor={refTab} ref={s => setRefContent(s ?? undefined)}
                                        afterChange={idx => setCurrentFeeType(VISIT_FEE_TYPES[idx])} 
                                        swipe={!isSorting}
                                        {...sliderContentSettings}>
                                    { VISIT_FEE_TYPES.map(t => (
                                        <div key={t}>
                                            <SortableList className={styles["list"] + " m-b-0"}
                                                items={isSorting ? (currentFeeType === t ? sortingList! : []) : groupedList[t]}
                                                isSorting={isSorting}
                                                onSorted={setSortingList}
                                                listItem={item => (
                                                    <li key={item.fee_no} className={styles["list-item"]}>
                                                        <div className={styles["list-item-content"]}>
                                                            <div className={styles["list-item-name"]}>{item.name}</div>
                                                            { buildDetails(item).map((detail,i) => (
                                                                <div key={i} className={styles["list-item-detail"]}>{detail}</div>
                                                            ))}
                                                        </div>
                                                        { isSorting ? (
                                                            <div className={styles["list-item-icon"]}><i className="fas fa-bars" /></div>        
                                                        ) : (
                                                            <div onClick={() => setEditingData(item)} className={styles["list-item-icon"]}><i className="fas fa-pen clickable" /></div>
                                                        )}
                                                    </li>
                                                )}
                                            />
                                        </div>
                                    ))}
                                </Slider>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div style={{height:"40px", marginTop:"6px"}} onClick={addItem}>
                {!isSorting && (
                    <span className="link">
                        <i className="fa fa-plus"></i>
                        <span> 項目を追加</span>
                    </span>
                )}
            </div>
            { editingData != null && (
                <VisitFeeEditPopup
                    original={editingData}
                    isSubmitExecuting={props.executing}
                    onClose={() => setEditingData(undefined)}
                    onDelete={onDelete}
                    onSubmit={onSubmitEdit}
                />
            )}
        </div>
    )
});

export default withRouter(withContext(VisitFeeMaster));