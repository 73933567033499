import React from 'react';
import Base, { BaseProps } from '../../components/content/base';
import { withContext } from '../../stores';
import { withRouter } from 'react-router-dom';
import { PageSettings } from '../../config/page-settings';
import { AppState } from '../../app';
import { CommonUtil, FreezedArray } from '../../config/util';
import { RanchApi, RanchEventHistoryReq, RanchEventHistoryBaseDto } from '../../api';
import { RanchEventList } from './ranch-event-list';
import { historyLocation } from '../../config/history-location-builder';
import { MenuChildrenPopup } from '../menu/menu-children-popup';
import { RanchAuthItemKey, hasRanchAuth } from '../../config/auth-checker';
import { EventKind, EVENT_KIND } from '../../config/event-kind';
import { hasRanchContract, RanchContractItemKey } from '../../config/contract-checker';
import { LoadMore } from '../../components/parts/load-more';

const EVENTS_PER_PAGE = 30;

interface ProfitMenuItem {
    name: string;
    location?: (teamId: number) => ({ pathname: string, search?: string, state?:any });
    rauth: RanchAuthItemKey[];
    constracts: RanchContractItemKey[];
}

const PROFIT_MENU_LIST:FreezedArray<ProfitMenuItem> = [
    { name: "乳代売上" ,     rauth:["BALANCE_RANCH"], constracts:["BREEDING"], location: (_ => historyLocation.toSellMilk(undefined, undefined)) },
    { name: "精液・受精卵",  rauth:["MASTER_EDIT"],   constracts:["BREEDING"], location: (id => historyLocation.toSeedSetting(id))  },
    { name: "収入・損失",    rauth:["BALANCE_RANCH"], constracts:[],           location: (_ => historyLocation.toOtherPl(undefined, undefined))}
];

export type SellSeedEventHisDto = RanchEventHistoryBaseDto & {
    seed_lot_id : number;
    label_no : string;
    price : number;
    seed_type : number;
    seed_id : number;
    name : string;
    sname? : string;
    lot_name : string;
    egg_type? : number;
    egg_rank? : number;
    egg_stage? : number;
    ancestor_1? : string;
    ancestor_2? : string;
    ancestor_3? : string;
    ancestor_4? : string;
    is_deleted : number;
    note? : string;
}
export type BuySeedEventHisDto = RanchEventHistoryBaseDto & {
    seed_lot_id : number;
    seed_type : number;
    seed_id : number;
    name : string;
    sname? : string;
    lot_name : string;
    egg_type? : number;
    egg_rank? : number;
    egg_stage? : number;
    stock_count : number;
    unit_price : number;
    ancestor_1? : string;
    ancestor_2? : string;
    ancestor_3? : string;
    ancestor_4? : string;
    is_deleted : number;
    note? : string;
}
export type SellMilkRanchEventHisDto = RanchEventHistoryBaseDto & {
    unit_price : number;
    amount : number;
    amount_discard : number;
    comment : string;
    created_by : string;
}
export type OtherPlRanchEventHisDto = RanchEventHistoryBaseDto & {
    comment: string;
    price: number;
    profit_loss: number;
    created_by: string;
}

export type RanchEventHis = SellMilkRanchEventHisDto | SellSeedEventHisDto | BuySeedEventHisDto | OtherPlRanchEventHisDto;

export const isSellMilk = (event: RanchEventHis): event is SellMilkRanchEventHisDto => {
    return event.event_kind_no === EVENT_KIND.SELL_MILK_RANCH.no;
}
export const isSellSeed = (event: RanchEventHis): event is SellSeedEventHisDto => {
    return event.event_kind_no === EVENT_KIND.SELL_SEED.no;
}
export const isBuySeed = (event: RanchEventHis): event is BuySeedEventHisDto => {
    return event.event_kind_no === EVENT_KIND.BUY_SEED.no;
}
export const isOtherPlRanch = (event: RanchEventHis): event is OtherPlRanchEventHisDto => {
    return event.event_kind_no === EVENT_KIND.OTHER_PROFIT_RANCH.no
        || event.event_kind_no === EVENT_KIND.OTHER_LOSS_RANCH.no;
}

type TEventKind = {
    values:number[];
    name:string;
    checked:boolean;
}

interface MyState {
    ranch_id?: number;
    has_more_events: boolean;
    current_event_page: number;
    event_kind_list: FreezedArray<TEventKind>;
    events: FreezedArray<RanchEventHis>;
    is_menu_children_popup_shown: boolean;
    profitMenuList: FreezedArray<ProfitMenuItem>;
}

class RanchEvent extends Base<BaseProps, MyState> {
    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            events: [],
            event_kind_list: [],
            current_event_page: 0,
            has_more_events: false,
            is_menu_children_popup_shown: false,
            profitMenuList:[]
        }
    }

    componentDidMount() {
        if (this.handleNotAllowAccess(undefined, ["BALANCE_RANCH"], [])) {
            return;
        }

        this.context.handleSetHeader({ title:"牧場イベント履歴" });
        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        this.init();
    }

    async init() {
        const ranchId = this.props.rootStore.cur_ranch_id;
        const user = this.props.rootStore.user;
        const evtKinds = ranchId === 0
            ? []
            : [...CommonUtil.groupBy(EventKind.forRanchEventHis(ranchId, user), e => e.group).values()]
                .map(g => ({ values: g.map(e => e.no), name:g[0].group, checked:true }));

        const menuList = PROFIT_MENU_LIST.filter(m => m.rauth.every(a => hasRanchAuth(a, ranchId, user))
                                                    && m.constracts.every(c => hasRanchContract(c, ranchId, user)));

        await this.setStateAsync({
            ranch_id: ranchId === 0 ? undefined : ranchId,
            event_kind_list: evtKinds,
            profitMenuList: menuList
        });

        if (this.state.ranch_id == null) return;

        await this.loadEvents(true);
    }

    async loadEvents(reset: boolean) {
        if (!CommonUtil.assertNotNull(this.state.ranch_id)) return;
        
        const pageNo = reset ? 1 : this.state.current_event_page + 1;

        const req: RanchEventHistoryReq = {
            page_no: pageNo,
            ranch_id: this.state.ranch_id,
            event_count: EVENTS_PER_PAGE
        };

        this.context.handleSetIsLoading(true);
        const res = await this.comm().send((await RanchApi()).getEventListUsingPOST(req));
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK" || res.data == null) return;

        const events = res.data.event_list as RanchEventHis[];
        const eventIds = [ ...new Set(events.map(e => e.event_id)) ];
        const hasMore = eventIds.length === EVENTS_PER_PAGE;

        return this.setStateAsync({
            events: reset ? events : this.state.events.concat(events),
            current_event_page: res.data.page_no,
            has_more_events: hasMore
        });
    }

    onAddProfitClick() {
        this.setState({is_menu_children_popup_shown: true });
    }

    onShowGraphClick() {
        this.props.history.push(historyLocation.toBalanceRanch());
    }

    onEdit(ev: RanchEventHis) {
        if (!CommonUtil.assertNotNull(this.state.ranch_id)) return;

        if (isBuySeed(ev) || isSellSeed(ev)) {
            this.props.history.push(historyLocation.toSeedSetting(this.state.ranch_id, ev.seed_type));
            return;
        }
        if (isSellMilk(ev)) {
            this.props.history.push(historyLocation.toSellMilkRanchEdit(ev.event_id));
            return;
        }
        if (isOtherPlRanch(ev)) {
            this.props.history.push(historyLocation.toOtherPlRanchEdit(ev.event_id));
            return;
        }
    }

    onProfitMenuSelect = (idx:number) => {
        if (!CommonUtil.assertNotNull(this.state.ranch_id)) return;

        const menu = this.state.profitMenuList[idx];
        if (menu.location != null) {
            this.props.history.push(menu.location(this.state.ranch_id));
        }
    }

    render() {
        if (this.state.ranch_id == null) {
            return <></>
        }

        const isVisible = (event: RanchEventHis) => this.state.event_kind_list.some(k => k.values.includes(event.event_kind_no) && k.checked);

        return (
            <div style={{ height: "100%" }}>
                <div className="product" style={{ height: "calc(100% - 46px)" }}>
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix" style={{ paddingBottom: "6px" }}>
                            <div style={{ display: "flex", flexWrap: "wrap", marginBottom:"6px", minHeight:"32px" }}>
                                { this.state.event_kind_list.map((event_kind,i) => (
                                    <div key={i} className="checkbox checkbox-css checkbox-inline" style={{margin: "5px"}}>
                                        <input type="checkbox" id={"event-"+ i}
                                            onChange={e => {
                                                this.setState({
                                                    event_kind_list: this.state.event_kind_list.map((k,ii) => i === ii ? { ...k, checked: e.target.checked } : k)
                                                })
                                            }}
                                            checked={event_kind.checked}
                                        />
                                        <label htmlFor={"event-"+ i}>{event_kind.name}</label>
                                    </div>
                                ))}
                            </div>
                            <div style={{ overflowY: "auto" }}>
                                <RanchEventList
                                    events={this.state.events.filter(e => isVisible(e))} user={this.props.rootStore.user}
                                    ranch_id={this.state.ranch_id}
                                    onEdit={ ev => this.onEdit(ev) }
                                />
                                {
                                    this.state.has_more_events && (
                                        <LoadMore onClick={() => this.loadEvents(false)} style={{textAlign:"center", marginTop:"10px", padding:"5px 0" }} />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- BEGIN checkout-footer --> */}
                <div style={{marginTop:"4px", display:"flex", justifyContent:"space-between"}}>
                    <div className="link" onClick={() => this.onAddProfitClick() } style={{ visibility: this.state.profitMenuList.length > 0 ? "visible" : "hidden" }}><i className="fa fa-plus"></i> 販売・購入記録を追加</div>
                    <div className="link" onClick={() => this.onShowGraphClick() }>グラフを表示</div>
                </div>
                {/* <!-- END checkout-footer --> */}
                { this.state.is_menu_children_popup_shown && (
                    <MenuChildrenPopup
                        onClose={() => this.setState({is_menu_children_popup_shown:false})}
                        menu={this.state.profitMenuList.map(m => m.name)}
                        onSelect={this.onProfitMenuSelect}
                     />
                )}
            </div>
        )
    }
}

export default withRouter(withContext(RanchEvent));