import React, { useState } from 'react';
import { OptionalInputContainer } from '../../components/parts/optional-input-container';
import { SliderInput } from '../../components/parts/slider-input';
import { IGrowth } from './growth-write';
import { LMT } from '../../config/constant';
import { DgViewerProps, DgViewer } from '../sellcow/dg-viewer';

const MIN_WEIGHT = LMT.GROWTH.WEIGHT_MIN;
const MAX_WEIGHT = LMT.GROWTH.WEIGHT_MAX;
const STEP_WEIGHT = LMT.GROWTH.WEIGHT_STEP;
const MIN_HEIGHT = 50;
const MAX_HEIGHT = 200;
const STEP_HEIGHT = 1;
const MIN_CHEST = 50;
const MAX_CHEST = 300;
const STEP_CHEST = 1;
const MIN_ABDOMEN = 50;
const MAX_ABDOMEN = 400;
const STEP_ABDOMEN = 1;
const MIN_BCS = 1;
const MAX_BCS = 5;
const STEP_BCS = 0.25;
const MIN_RFS = 1;
const MAX_RFS = 5;
const STEP_RFS = 0.5;

export type IGrowthSelectorData = Omit<IGrowth, "event_id" | "ranch_id" | "cow_id" | "watched_by" | "watched_at"> & {
}

export interface IGrowthSelectorProps {
    data: IGrowthSelectorData;
    onChange: (data:IGrowthSelectorData) => void;
    onAnyInvalidChange: (anyInvalid: boolean) => void;
    dgSource?: Omit<DgViewerProps, "edScore">;
}

export const GrowthSelector: React.FC<IGrowthSelectorProps> = (props) => {
    const [ invalidSet, setInvalidSet ] = useState(new Set<string>());

    const rowClass = "form-group row treat-write-row";
    const rowHeaderClass = "col-form-label col-md-5 col-xs-8 text-lg-right";
    const rowHeaderChkClass = "checkbox checkbox-css col-form-label col-md-4 col-xs-4 text-lg-right m-t-10";

    const onIsValidChange = (name: string, isValid: boolean) => {
        const newInvalidSet = new Set([...invalidSet]);
        if (isValid) {
            newInvalidSet.delete(name);
        } else {
            newInvalidSet.add(name);
        }

        const preAnyInvalid = invalidSet.size > 0;
        const newAnyInvalid = newInvalidSet.size > 0;
        setInvalidSet(newInvalidSet);
        if (preAnyInvalid !== newAnyInvalid) {
            props.onAnyInvalidChange(newAnyInvalid);
        }
    }

    return (<>
        <div className={rowClass} data-testid="weight">
            <div className={rowHeaderChkClass}>
                <input type="checkbox" id="chkWeight"
                    onChange={(e) => props.onChange({...props.data, weight: e.target.checked ? MIN_WEIGHT : undefined})}
                    checked={props.data.weight != null} />
                <label htmlFor="chkWeight">体重(kg)</label>
            </div>
            <OptionalInputContainer className={rowHeaderClass} isSelected={props.data.weight != null}>
                <SliderInput min={MIN_WEIGHT} max={MAX_WEIGHT} value={props.data.weight ?? MIN_WEIGHT} step={STEP_WEIGHT}
                    sliderMin={MIN_WEIGHT} sliderMax={MAX_WEIGHT} inputWidth={68}
                    onChange={v => props.onChange({...props.data, weight: v})}
                    onIsValidChange={v => onIsValidChange("weight", v)}
                />
                { props.dgSource != null && props.data.weight != null && (
                    <DgViewer {...props.dgSource} edScore={props.data.weight} />
                )}
            </OptionalInputContainer>
        </div>
        <div className={rowClass} data-testid="height">
            <div className={rowHeaderChkClass}>
                <input type="checkbox" id="chkHeight"
                    onChange={(e) => props.onChange({...props.data, height: e.target.checked ? MIN_HEIGHT : undefined})}
                    checked={props.data.height != null} />
                <label htmlFor="chkHeight">体高(cm)</label>
            </div>
            <OptionalInputContainer className={rowHeaderClass} isSelected={props.data.height != null}>
                <SliderInput min={MIN_HEIGHT} max={MAX_HEIGHT} value={props.data.height ?? MIN_HEIGHT} step={STEP_HEIGHT}
                    sliderMin={MIN_HEIGHT} sliderMax={MAX_HEIGHT} inputWidth={68}
                    onChange={v => props.onChange({...props.data, height: v})}
                    onIsValidChange={v => onIsValidChange("height", v)}
                />
            </OptionalInputContainer>
        </div>
        <div className={rowClass} data-testid="chest">
            <div className={rowHeaderChkClass}>
                <input type="checkbox" id="chkChest"
                    onChange={(e) => props.onChange({...props.data, chest: e.target.checked ? MIN_CHEST : undefined})}
                    checked={props.data.chest != null} />
                <label htmlFor="chkChest">胸囲(cm)</label>
            </div>
            <OptionalInputContainer className={rowHeaderClass} isSelected={props.data.chest != null}>
                <SliderInput min={MIN_CHEST} max={MAX_CHEST} value={props.data.chest ?? MIN_CHEST} step={STEP_CHEST}
                    sliderMin={MIN_CHEST} sliderMax={MAX_CHEST} inputWidth={68}
                    onChange={v => props.onChange({...props.data, chest: v})}
                    onIsValidChange={v => onIsValidChange("chest", v)}
                />
            </OptionalInputContainer>
        </div>
        <div className={rowClass} data-testid="abdomen">
            <div className={rowHeaderChkClass}>
                <input type="checkbox" id="chkAbdomen"
                    onChange={(e) => props.onChange({...props.data, abdomen: e.target.checked ? MIN_ABDOMEN : undefined})}
                    checked={props.data.abdomen != null} />
                <label htmlFor="chkAbdomen">腹囲(cm)</label>
            </div>
            <OptionalInputContainer className={rowHeaderClass} isSelected={props.data.abdomen != null}>
                <SliderInput min={MIN_ABDOMEN} max={MAX_ABDOMEN} value={props.data.abdomen ?? MIN_ABDOMEN} step={STEP_ABDOMEN}
                    sliderMin={MIN_ABDOMEN} sliderMax={MAX_ABDOMEN} inputWidth={68}
                    onChange={v => props.onChange({...props.data, abdomen: v})}
                    onIsValidChange={v => onIsValidChange("abdomen", v)}
                />
            </OptionalInputContainer>
        </div>
        <div className={rowClass} data-testid="bcs">
            <div className={rowHeaderChkClass}>
                <input type="checkbox" id="chkBcs"
                    onChange={(e) => props.onChange({...props.data, bcs: e.target.checked ? MIN_BCS : undefined})}
                    checked={props.data.bcs != null} />
                <label htmlFor="chkBcs">BCS</label>
            </div>
            <OptionalInputContainer className={rowHeaderClass} isSelected={props.data.bcs != null}>
                <SliderInput min={MIN_BCS} max={MAX_BCS} value={props.data.bcs ?? MIN_BCS} step={STEP_BCS}
                    sliderMin={MIN_BCS} sliderMax={MAX_BCS} inputWidth={68}
                    onChange={v => props.onChange({...props.data, bcs: v})}
                    onIsValidChange={v => onIsValidChange("bcs", v)}
                />
            </OptionalInputContainer>
        </div>
        <div className={rowClass} data-testid="rfs">
            <div className={rowHeaderChkClass}>
                <input type="checkbox" id="chkRfs"
                    onChange={(e) => props.onChange({...props.data, rfs: e.target.checked ? MIN_RFS : undefined})}
                    checked={props.data.rfs != null} />
                <label htmlFor="chkRfs">RFS</label>
            </div>
            <OptionalInputContainer className={rowHeaderClass} isSelected={props.data.rfs != null}>
                <SliderInput min={MIN_RFS} max={MAX_RFS} value={props.data.rfs ?? MIN_RFS} step={STEP_RFS}
                    sliderMin={MIN_RFS} sliderMax={MAX_RFS} inputWidth={68}
                    onChange={v => props.onChange({...props.data, rfs: v})}
                    onIsValidChange={v => onIsValidChange("rfs", v)}
                />
            </OptionalInputContainer>
        </div>
    </>)
}