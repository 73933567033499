import { useQuery } from 'react-query';
import { QUERY, UseQueryResultSummary } from './fetcher';
import { ActivityApi, ActivityCowDto } from '../api';

const fetchActivityCows = async (ranchId: number, activityId: number) => {
    const api = await ActivityApi();
    const res = await api.getActivityCowsUsingPOST({ ranch_id:ranchId, id:activityId })();
    
    if (res.data.meta?.errCode !== 0 || res.data.data == null) {
        console.error(res.data);
        throw new Error(res.data.meta?.errMsg);
    }

    return res.data.data;
}

export const useActivityCows = (ranchId: number, activityId: number, cows:ActivityCowDto[], cowCount:number): UseQueryResultSummary<ActivityCowDto[]> => {

    const shouldFetch = cows.length < cowCount;
    const res = useQuery([QUERY.ACTIVITY_COW, activityId],
        () => shouldFetch ? fetchActivityCows(ranchId, activityId) : [],
        { cacheTime: 2 * 60 * 60 * 1000, staleTime: 30 * 60 * 1000 });

    if (!shouldFetch) {
        return {
            data:cows,
            isError:false,
            isSuccess:true,
            isFetched:true,
            isFetching:false,
            isLoading:false,
            isLoadingError:false
        };
    }

    return res;
}