import React, { useEffect } from 'react';
import styles from './balance.module.css';
import moment from 'moment';
import { FormDatePicker } from '../../components/form/form-date-picker';

export interface BalanceDateSelectorProps {
    selectedDate : Date;
    onDateChanged: (value:Date) => void;
    selectUnit: "day"|"month";
    spanInfo?: string;
}

export const BalanceDateSelector: React.FC<BalanceDateSelectorProps> = (props) => {
    useEffect(() => {
        if (props.selectUnit === "month") {
            const newDate = moment(props.selectedDate).endOf("month").startOf("day");
            if (!newDate.isSame(props.selectedDate)) {
                props.onDateChanged(newDate.toDate());
            }
        }

    }, [props.selectUnit]);

    const onSlide = (span: number) => {
        let mNewDate = moment(props.selectedDate).add(span, props.selectUnit);
        if (props.selectUnit === "month") {
            mNewDate = mNewDate.endOf("month").startOf("day");
        }
        props.onDateChanged(mNewDate.toDate());
    }
    const onChange = (date: Date) => {

        if (props.selectUnit === "month") {
            date = moment(date).endOf("month").startOf("day").toDate();
        }

        props.onDateChanged(date);
    }

    return (
        <div className={styles["date-selector"]}>
            <div className={styles["date-select-controls"]}>
                <button type="button" className="btn btn-sm btn-inverse m-r-5"
                    onClick={() => onSlide(-1)}><i
                        className="fas fa-lg fa-caret-left"></i></button>
                <div>
                    <FormDatePicker
                        value={props.selectedDate}
                        monthOnly={props.selectUnit === "month"}
                        onChange={e => onChange(e.target.value)}
                        name="balance-date"
                        placement="auto"
                    />
                </div>
                <button type="button" className="btn btn-sm btn-inverse m-l-5"
                    onClick={() => onSlide(1)}><i
                        className="fas fa-lg fa-caret-right"></i></button>
            </div>
            { props.spanInfo != null && (
                <div className={styles["suffix"]}>より前{props.spanInfo}</div>
            )}
        </div>

    );
}