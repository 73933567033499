import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { PageSettings } from '../../config/page-settings';
import { withContext } from '../../stores';
import moment from 'moment';
import { CommonUtil, saveTextFile } from '../../config/util';
import { AppState } from '../../app';
import styles from '../report-sellcow/report-sellcow.module.css';
import { isBrowser } from 'react-device-detect';
import ReactToPrint from 'react-to-print';
import { DeliveryAggregateTransitionDto, AggregateTransitionReq, DeliveryApi, DeliveryAggregateTransitionMonthDto } from '../../api';
import { ReportDeliveryTransitionGraph } from './report-delivery-transition-graph';
import { buildHeaders as buildTransitionHeaders, getRowValues as getTransitionRowValues, ReportDeliveryTransitionTable } from './report-delivery-transition-table';
import { RequiredTermSelector } from '../../components/parts/optional-term-selector';
import { TagCondition, SelectTagsPopup } from './select-tags-popup';

type ReportDataType = DeliveryAggregateTransitionDto;

interface LoadedData {
    condition: Readonly<Condition>;
    data: ReportDataType;
}

const loadTransitionReport = async (self: ReportDelivery) => {
    if (!CommonUtil.assertNotNull(self.state.ranch, "ranch", "loadTransitionReport")) return undefined;
    const cond = self.state.condition;
    if (cond.kind !== "TRANSITION") {
        console.error("invalid condition for load transition", cond);
        return undefined;
    }

    const req: AggregateTransitionReq = {
        ranch_id: self.state.ranch.ranch_id,
        from: moment(cond.from).format("YYYY-MM-DD"),
        to: moment(cond.to).format("YYYY-MM-DD"),
        included_tags: cond.tags.filter(t => t.includes).map(t => t.id),
        excluded_tags: cond.tags.filter(t => !t.includes).map(t => t.id)
    };

    const res = await self.comm().send((await DeliveryApi()).aggregateTransitionUsingPOST(req));
    if (res.result !== "OK") return undefined;
    return res.data;
}

const REPORT_KINDS = ["TRANSITION"] as const;
type ReportKindKey = typeof REPORT_KINDS[number];
interface IReportKind {
    name: string;
    defaultCondition: () => Condition;
    loadData: (self: ReportDelivery) => Promise<ReportDataType | undefined>;
}
const REPORT_KIND: {[key in ReportKindKey]: IReportKind} = {
    TRANSITION: {
        name: "月別",
        loadData: loadTransitionReport,
        defaultCondition: () => {
            const from = moment().startOf("year");
            const to = moment(from).endOf("year").startOf("month");
            return {
                kind: "TRANSITION",
                from: from.toDate(),
                to: to.toDate(),
                tags: []
            }
        }
    }
}

interface ICondition {
    kind: ReportKindKey;
}
interface ITransitionCondition extends ICondition {
    kind: "TRANSITION",
    from: Date;
    to: Date;
    tags: TagCondition[];
}
type Condition = ITransitionCondition/* | AnotherCondition */;

const buildConditionText = (cond: Condition) => {
    if (cond.kind === "TRANSITION") {
        return [];
    }
    console.error("unknown condition", cond);
    return [];
}

interface MyState {
    ranch?: { ranch_id:number, name:string };
    condition: Condition;
    loadedData?: Readonly<LoadedData>;
    isTagSelectShown: boolean;
}

class ReportDelivery extends Base<BaseProps, MyState> {

    private refPrintTarget = React.createRef<HTMLDivElement>();

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            condition: REPORT_KIND.TRANSITION.defaultCondition(),
            isTagSelectShown: false
        };
    }

    componentDidMount() {

        this.context.handleSetHeader({ title: "繁殖統計" });
        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        if (this.handleNotAllowAccess(undefined, ["REPORT_RANCH"], [])) {
            return;
        }

        const ranchId = this.props.rootStore.cur_ranch_id;
        const ranchName = this.props.rootStore.getCurRanchName() ?? "";

        this.setState({
            ranch: { ranch_id: ranchId, name:ranchName }

        }, () => {
            this.props.rootStore.fetchCowTags(ranchId);
            this.loadData();
        });
    }

    async loadData() {
        const kind = REPORT_KIND[this.state.condition.kind];

        this.context.handleSetIsLoading(true);

        const data = await kind.loadData(this);

        this.context.handleSetIsLoading(false);

        if (data == null) return;

        this.setState({
            loadedData:{
                data,
                condition: this.state.condition
            }
        });
    }

    onReportKindChanged(key: ReportKindKey) {
        this.setState({
            condition: REPORT_KIND[key].defaultCondition()
        })
    }
    onTransitionTermChanged(st: Date, ed: Date) {
        if (this.state.condition.kind !== "TRANSITION") {
            console.error("invalid condition onTransitionYearChanged", this.state.condition);
            return;
        }

        this.setState({
            condition: {
                ...this.state.condition,
                from: st,
                to: ed
            }
        })
    }
    setTermOfYear(offsetFromThisYear: number) {
        if (this.state.condition.kind !== "TRANSITION") {
            console.error("invalid condition on setThisYear", this.state.condition);
            return;
        }

        const st = moment().add(offsetFromThisYear, "year").startOf("year");
        const ed = moment(st).endOf("year").startOf("day");
        this.setState({
            condition: {
                ...this.state.condition,
                from: st.toDate(),
                to: ed.toDate()
            }
        })
    }


    onCsvClick() {
        const loaded = this.state.loadedData;
        if (!CommonUtil.assertNotNull(loaded, "loadedData", "csv")) return;

        const rows: string[] = [];
        let fileName: string;

        const cond = loaded.condition;
        const valsToRow = (vals: Array<string|number>) => vals.map(v => `"${v}"`).join(",");

        if (cond.kind === "TRANSITION" && ("months" in loaded.data)) {
            const headerRowVals = buildTransitionHeaders(cond.from, cond.to, true);
            headerRowVals.forEach(vals => rows.push(valsToRow(vals)));

            const now = moment();
            
            getTransitionRowValues(cond.from, cond.to, loaded.data.months, true, now.toDate())
                .map(r => valsToRow(r))
                .forEach(r => rows.push(r));
            fileName = `繁殖月別統計_${moment(cond.from).format("YYYY年MM月")}-${moment(cond.to).format("YYYY年MM月")}_${now.format("YYYYMMDD")}.csv`;
        }
        else {
            console.error("invalid report kind on csv click: " + cond.kind, loaded.data);
            return;
        }

        saveTextFile(rows.join("\r\n"), fileName);
    }

    render() {
        if (this.state.ranch == null) {
            return <></>
        }

        return (
            <div className="page-root">
                <div className="product" style={{ height: "100%", "minHeight": "0" }}>
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix p-b-5">

                            <div style={{ height: "100%", display:"flex", flexFlow:"column nowrap" }}>
                                <div className={styles.conditions}>
                                    <div className={styles["condition-row"]}>
                                        <select className="form-control" value={this.state.condition.kind}
                                                onChange={e => this.onReportKindChanged(e.target.value as ReportKindKey) }>
                                            { REPORT_KINDS.map(k => (
                                                <option key={k} value={k}>{REPORT_KIND[k].name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    { this.state.condition.kind === "TRANSITION" && (<>
                                        <div className={styles["condition-row"]}>
                                            <RequiredTermSelector
                                                maxMonthSpan={12}
                                                stValue={this.state.condition.from}
                                                edValue={this.state.condition.to}
                                                monthOnly={true}
                                                onChange={(s,e) => this.onTransitionTermChanged(s,e)}
                                                stPlacement="bottom-start"
                                                edPlacement="bottom"
                                            />
                                            <div className={styles["condition-presets"]}>
                                                <button className="btn btn-sm btn-light" onClick={() => this.setTermOfYear(-1)}>昨年</button>
                                                <button className="btn btn-sm btn-light" onClick={() => this.setTermOfYear(0)}>今年</button>
                                            </div>
                                        </div>
                                        <div className={styles["condition-row"]}>
                                            <span className="link" onClick={() => this.setState({ isTagSelectShown: true })}>
                                                { this.state.condition.tags.length === 0 ? 
                                                    "対象牛をタグで指定"
                                                    :
                                                    "タグ：" + this.state.condition.tags.map(t => `"${t.name}"を${t.includes ? "含む" : "除外"}`).join(" & ")
                                                }
                                            </span>
                                        </div>
                                    </>)}
                                </div>

                                <div className={styles["load-button-row"]}>
                                    <button className="btn btn-green" onClick={() => this.loadData()}>
                                        <i className="far fa-arrow-alt-circle-down"></i>
                                        <span> 再集計</span>
                                    </button>
                                </div>

                                { this.state.loadedData != null && (
                                    <div className={styles["loaded-header"]}>
                                        { buildConditionText(this.state.loadedData.condition).map((r,i) => <div key={i}>{r}</div>)}
                                    </div>
                                )}
                                { this.state.loadedData?.condition?.kind === "TRANSITION"
                                    && ("months" in this.state.loadedData.data) && (
                                    <ReportTransition
                                        records={this.state.loadedData.data.months}
                                        from={this.state.loadedData.condition.from}
                                        to={this.state.loadedData.condition.to}
                                        onCsvClick={() => this.onCsvClick()}
                                        refTable={this.refPrintTarget}
                                    />
                                )}
                            </div>

                        </div>
                    </div>
                </div>
                { this.state.condition.kind === "TRANSITION" && this.state.isTagSelectShown && (
                    <SelectTagsPopup
                        tags={this.state.condition.tags}
                        onClose={() => this.setState({ isTagSelectShown: false })}
                        onSubmit={tags => this.setState({
                            //※他の種別が追加されたら、onSubmit時点での種別チェック必要
                            condition: { ...this.state.condition, tags },
                            isTagSelectShown: false
                        })}
                        allTags={ this.props.rootStore.getCowTags(this.state.ranch.ranch_id)}
                    />
                )}
            </div>
        )
    }
}

const ReportTransition = (props: {
    from: Date,
    to: Date,
    records: DeliveryAggregateTransitionMonthDto[],
    refTable: React.RefObject<HTMLDivElement>,
    onCsvClick: () => void,

}) => {
    const [ view, setView ] = useState<"table"|"graph">("table");

    return (<>
        <div className={styles["switch-view"]}>
            <div className="radio radio-css mr-3">
                <input type="radio" id="rad-table" checked={view === "table"} onChange={() => setView("table")} />
                <label htmlFor="rad-table">推移表</label>
            </div>
            <div className="radio radio-css">
                <input type="radio" id="rad-graph" checked={view === "graph"} onChange={() => setView("graph")} />
                <label htmlFor="rad-graph">グラフ</label>
            </div>
        </div>
        { view === "table" && (<>
            <div ref={props.refTable} className={styles["table-container"]}>
                <ReportDeliveryTransitionTable today={new Date()} from={props.from} to={props.to} records={props.records} />
            </div>
            { isBrowser && (
                <div className={styles["table-footer"]}>
                    <button className="btn btn-orange m-r-5" onClick={() => props.onCsvClick()}>CSV出力</button>
                    <ReactToPrint
                        trigger={() => <button className="btn btn-orange">印刷</button>}
                        content={() => props.refTable.current}
                    />
                </div>
            )}
        </>)}
        { view === "graph" && (
            <ReportDeliveryTransitionGraph from={props.from} to={props.to} records={props.records} />
        )}
    </>)
}


export default withRouter(withContext(ReportDelivery));