import React, { useState, useEffect, useRef, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { A, RANCH_HOUSE_LEVEL, findRanchHouseLevel, IRanchHouseLevel, LMT } from '../../config/constant';
import baseStyles from './setting.module.css';
import styles from './ranch-house-master.module.css';
import { IRanchHouse } from '../../stores/RootStore';
import { PageSettings } from '../../config/page-settings';
import { withContext } from '../../stores';
import { AppState } from '../../app';
import { CommonUtil, FreezedArray } from '../../config/util';
import { RanchApi, RanchHouseModifyReq, RanchHouseDeleteReq, RanchHouseSortReq } from '../../api';
import classnames from 'classnames';
import { SortableList } from '../../components/parts/sortable-list';
import { useRanchHouses, resetRanchHouses } from '../../stores/fetcher';
import { FetchWaiter, FetchError } from '../../components/content/fetch-state';
import { DIALOG_BUTTONS } from '../../components/form/form-dialog';

interface MyProps extends BaseProps<{id:string}> {
}

interface MyState {
    ranchId?: number;
}

class RanchHouseMaster extends Base<MyProps, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
        };

        this.onSort = this.onSort.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    componentDidMount() {

        this.context.handleSetHeader({ title:"場所の設定" });
        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        this.init();
    }

    componentDidUpdate(prevProps: this['props'], _: MyState) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.init();
        }
    }

    private async init() {
        const ranchId = parseInt(this.props.match.params.id);
        if (this.handleNotAllowAccess(ranchId, ["MASTER_EDIT"],[])) {
            return;
        }

        this.setState({ ranchId });
    }

    private async onSort(req: RanchHouseSortReq) {
        this.context.handleSetIsLoading(true);
        const res = await this.comm().send((await RanchApi()).sortHouseUsingPOST(req));
        this.context.handleSetIsLoading(false);
        return res.result === "OK";
    }

    private onSubmit = async (req: RanchHouseModifyReq) => {

        this.context.handleSetIsLoading(true);
        const res = await this.comm().send((await RanchApi()).modifyHouseUsingPOST(req));
        this.context.handleSetIsLoading(false);

        return res.result === "OK";
    }

    private async onDelete(req: RanchHouseDeleteReq) {
        let msg: string;
        switch (req.level) {
            case RANCH_HOUSE_LEVEL.SITE.level:
                msg = A.MESSAGE.CONFIRM_RANCH_HOUSE_SITE_DELETE;
                break;
            case RANCH_HOUSE_LEVEL.BARN.level:
                msg = A.MESSAGE.CONFIRM_RANCH_HOUSE_BARN_DELETE;
                break;
            case RANCH_HOUSE_LEVEL.ROOM.level:
                msg = A.MESSAGE.CONFIRM_RANCH_HOUSE_ROOM_DELETE;
                break;
            default:
                console.error("invalid level " + req.level);
                return false;
        }

        const confirmed = await this.context.showDialog("QUESTION", msg, DIALOG_BUTTONS.DELETE_CANCEL);
        if (confirmed !== 0) return false;

        return this._execDelete(req);
    }

    private _execDelete = async (req: RanchHouseDeleteReq): Promise<boolean> => {
        this.context.handleSetIsLoading(true);
        const res = await this.comm().send((await RanchApi()).deleteHouseUsingPOST(req));
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") return false;

        // アクティブ牛リスト更新(「牛舎・部屋を選択」画面で牛を`(○○未設定)`に表示させるため)
        this.props.rootStore.fetchActiveCows(this.state.ranchId, "DIFF");

        return true;
    }


    render() {
        const ranchId = this.state.ranchId;
        if (ranchId == null) return <></>

        return (
            <RanchHouseMasterContent
                ranchId={ranchId}
                onSubmit={this.onSubmit}
                onSort={this.onSort}
                onDelete={this.onDelete}
            />
        )
    }
}

interface ContentProps {
    ranchId: number;
    onSubmit: (req: RanchHouseModifyReq) => Promise<boolean>;
    onDelete: (req: RanchHouseDeleteReq) => Promise<boolean>;
    onSort: (req: RanchHouseSortReq) => Promise<boolean>;
}

type IRoute = Pick<IRanchHouse, "level"|"no"|"name">;
type IPosition = { level: IRanchHouseLevel, routes: FreezedArray<IRoute> };

const RanchHouseMasterContent = (props: ContentProps) => {
    const [ dispList, setDispList ] = useState<FreezedArray<IRanchHouse>>([]);
    const [ sortingList, setSortingList ] = useState<FreezedArray<IRanchHouse>>();
    /**
     * 新規の場合は負の値
     */
    const [ editingIndex, setEditingIndex ] = useState<number>();
    const [ currentPosition, setCurrentPosition ] = useState<IPosition>({ level:RANCH_HOUSE_LEVEL.SITE, routes:[] });
    const [ executing, setExecuting ] = useState(false);
    const [ inputName, setInputName ] = useState("");

    const inputRef = useRef<HTMLInputElement>(null);

    const siteList = useRanchHouses(props.ranchId, false);

    useEffect(() => {
        if (siteList.data == null) return;
        let list: FreezedArray<IRanchHouse> = siteList.data;

        for (const route of currentPosition.routes) {
            const parent = list.find(h => h.no === route.no);
            if (!CommonUtil.assertNotNull(parent, `selected route of ${route.no} in level ${route.level}`)) {
                setDispList([]);
                return;
            }
            if (route.level !== parent.level) {
                console.error("invalid selected route state", route, parent);
            }
            list = parent.data ?? [];
        }
        setDispList(list);

    }, [ siteList.data, currentPosition ]);

    useEffect(() => {
        setInputName(
            editingIndex == null ? ""
            : editingIndex < 0 ? ""
            : dispList[editingIndex].name
        );

        if (inputRef.current != null) {
            inputRef.current.focus();
        }

    }, [ editingIndex ]);


    const getChildRanchHouseLevel = useCallback((pos: IPosition) => {
        if (pos.level.childLevel == null) return undefined;
        return findRanchHouseLevel(pos.level.childLevel);
    }, []);

    const getParentRanchHouseLevel = useCallback((pos: IPosition) => {
        if (pos.level.parentLevel == null) return undefined;
        return findRanchHouseLevel(pos.level.parentLevel);
    }, []);

    const buildTitle = useCallback((pos: IPosition) => {
        const name = `${pos.level.name}一覧`;
        if (pos.routes.length === 0) return name;

        return `${name}（${pos.routes[pos.routes.length - 1].name}）`;
    }, []);

    const onMoveNext = (house:IRanchHouse) => {
        const childLevel = getChildRanchHouseLevel(currentPosition);
        if (!CommonUtil.assertNotNull(childLevel, "childLevel", "onMoveNext")) return;

        setEditingIndex(undefined);
        setCurrentPosition({
            level: childLevel,
            routes: [ ...currentPosition.routes, house ],
        });
    }

    const onMovePrev = () => {
        const parentLevel = getParentRanchHouseLevel(currentPosition);
        if (!CommonUtil.assertNotNull(parentLevel, "parentLevel", "onMovePrev")) return;

        setEditingIndex(undefined);
        setCurrentPosition({
            level: parentLevel,
            routes: currentPosition.routes.slice(0, -1)
        });
    }

    const finishSorting = async () => {
        if (!CommonUtil.assertNotNull(sortingList, "sortingList")) return;
        const parent_no = currentPosition.routes.length === 0 ? 0 : currentPosition.routes[currentPosition.routes.length - 1].no;

        const newOrder = sortingList.map(p => p.no);
        const oldOrder = dispList.map(p => p.no);
        if (JSON.stringify(newOrder) === JSON.stringify(oldOrder)) {
            setSortingList(undefined);
            return;
        }

        const req:RanchHouseSortReq = {
            order: newOrder,
            ranch_id: props.ranchId,
            level: currentPosition.level.level,
            parent_no,
        };

        setExecuting(true);
        const res = await props.onSort(req);
        setExecuting(false);
        if (res === true) {
            setSortingList(undefined);
            resetRanchHouses();
        }
    }

    const onSubmit = async () => {
        if (!CommonUtil.assertNotNull(editingIndex, "editingIndex", "submit")) return;

        const isNew = editingIndex < 0;

        const pos = currentPosition;

        const req: RanchHouseModifyReq = {
            ranch_id: props.ranchId,
            is_new: isNew ? 1 : 0,
            level: pos.level.level,
            name: inputName,
            no: isNew ? undefined : dispList[editingIndex].no,
            parent_no: pos.routes.length === 0 ? 0 : pos.routes[pos.routes.length - 1].no
        };

        setExecuting(true);
        const res = await props.onSubmit(req);
        setExecuting(false);

        if (res === true) {
            setEditingIndex(undefined);
            resetRanchHouses();
        }
    }

    const onDelete = async () => {
        if (!CommonUtil.assertNotNull(editingIndex, "editingIndex", "delete")) return;

        const req: RanchHouseDeleteReq = {
            ranch_id: props.ranchId,
            no: dispList[editingIndex].no,
            level: currentPosition.level.level
        };

        setExecuting(true);
        const res = await props.onDelete(req);
        setExecuting(false);

        if (res === true) {
            setEditingIndex(undefined);
            resetRanchHouses();
        }
    }

    if (siteList.isLoading) return <FetchWaiter />
    if (siteList.isError || siteList.data == null) return <FetchError />

    return (
        <div className="page-root">
            <div className="product product-full-height">
                <div className="product-detail" style={{ height: "100%" }}>
                    <div className="product-info product-info-fix">
                        <div className={"product-info-header " + baseStyles.header}>
                            <div className={styles["list-title"]}>
                                {buildTitle(currentPosition)}
                            </div>
                            <div className={baseStyles["sortable-list-shoulder"]}>
                                { sortingList == null ? (<>
                                    <span className={classnames("link", { "disabled": editingIndex != null })} onClick={() => setSortingList(dispList)}>並び替え</span>
                                    { currentPosition.level.parentLevel != null && (
                                        <span data-testid="prev-link" className="link" onClick={onMovePrev}>{`< ${getParentRanchHouseLevel(currentPosition)?.name}一覧`}</span>
                                    )}
                                </>) : (<>
                                    <span className="link" onClick={finishSorting}>完了</span>
                                    <span className="link" onClick={() => setSortingList(undefined)}>キャンセル</span>
                                </>)}
                            </div>
                        </div>

                        <div className="ranch-main" style={{flex: "auto"}}>
                            { sortingList != null ? (
                                <SortableList className={baseStyles["list"]}
                                    isSorting={true}
                                    items={sortingList}
                                    onSorted={setSortingList}
                                    listItem={house => (
                                        <li className={styles["list-item"]}>
                                            <div className={styles["list-item-content"]}>
                                                <div className={styles["list-item-name"]}>{house.name}</div>
                                            </div>
                                            <div className={styles["list-item-icon"]}><i className="fas fa-bars"></i></div>
                                        </li>
                                    )}
                                />
                            ) : (
                                <ul className={baseStyles["list"]}>
                                    { dispList.map((house,i) => (
                                        <li key={house.no} className={styles["list-item"]}>
                                            <div className={styles["list-item-content"]}>
                                                { editingIndex !== i ? (<>
                                                    <div className={styles["list-item-name"]}>
                                                        <span>{house.name}</span>
                                                        <i className={classnames("fas", "fa-sm", "fa-pen", "clickable", "m-l-5", {[styles["icon-disabled"]]: editingIndex != null})}
                                                            onClick={() => setEditingIndex(i)} />
                                                    </div>
                                                    { currentPosition.level.childLevel != null && 
                                                        <div onClick={() => onMoveNext(house)} className={styles["list-item-icon"]}>
                                                            <i className="fas fa-lg fa-angle-right clickable p-5" />
                                                        </div>
                                                    }
                                                </>) : (<>
                                                    <div className={styles["list-item-icon"]}>
                                                        <div style={{display: "flex"}}>
                                                            <input type="text" className={"form-control"} style={{height: "28px"}} 
                                                                value={inputName} 
                                                                maxLength={LMT.RANCH.HOUSE_NAME_LEN}
                                                                onChange={e => setInputName(e.target.value)} 
                                                                ref={inputRef}
                                                            />
                                                            <button className="btn btn-green btn-sm m-l-5 text-nowrap"
                                                                disabled={executing || inputName.trim() === ""}
                                                                onClick={onSubmit}>確定</button>
                                                        </div>
                                                        <div style={{fontSize: ".7rem"}}>
                                                            <span className="link" onClick={() => setEditingIndex(undefined)}>キャンセル</span>
                                                        </div>
                                                    </div>
                                                    <div className={styles["list-item-icon"]} style={{marginRight: "0px"}}>
                                                        <button className={"btn btn-danger text-nowrap"}
                                                                disabled={executing}
                                                                onClick={onDelete}>削除</button>
                                                    </div>
                                                </>)}
                                            </div>
                                        </li>
                                    ))}
                                    { editingIndex != null && editingIndex < 0 && (
                                        <li className={styles["list-item"]}>
                                            <div className={styles["list-item-icon"]}>
                                                <div style={{display: "flex"}}>
                                                    <input type="text" className={"form-control"} style={{height: "28px"}} 
                                                        value={inputName} 
                                                        maxLength={LMT.RANCH.HOUSE_NAME_LEN}
                                                        onChange={e => setInputName(e.target.value)} 
                                                        ref={inputRef}
                                                    />
                                                    <button className="btn btn-green btn-sm m-l-5 text-nowrap"
                                                        disabled={executing || inputName.trim() === ""}
                                                        onClick={onSubmit}>確定</button>
                                                </div>
                                                <div style={{fontSize: ".7rem"}}>
                                                    <span className="link" onClick={() => setEditingIndex(undefined)}>キャンセル</span>
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div style={{height:"40px", marginTop:"6px"}}>
                { sortingList == null && (
                    <span className={classnames("link", { "disabled": editingIndex != null })} onClick={() => setEditingIndex(-1)}>
                        <i className="fa fa-plus"></i>
                        <span> {currentPosition.level.name}を追加</span>
                    </span>
                )}
            </div>
        </div>
    )

}


export default withRouter(withContext(RanchHouseMaster));