import React, { Fragment, useState } from 'react';

import styles from './top.module.css'
import { ISchedule } from '../schedule/schedule-popup';
import moment from 'moment';
import { A } from '../../config/constant';
import Slider from 'react-slick';
import { CommonUtil } from '../../config/util';
import { CowToDispInfo, CowsPopupContainer, IPopupCow, ICowNameInfo, ActivityCowsPopupContainer } from '../../components/parts/cows-popup';
import { ActivityDto, RanchScheduleModifyReq } from '../../api';
import { historyLocation } from '../../config/history-location-builder';
import classnames from 'classnames';
import { EventKind } from '../../config/event-kind';
import { Washouts } from '../../config/Washout';
import washoutIcon from '../../assets/icon/washout-off_s.png';
import { ScheduleForwarder } from '../schedule/schedule-forwarder';
import { IconLink } from '../../components/parts/icon-link';
import { ACTIVITY_KINDS, ACTIVITY_KIND } from '../../config/activity-kind';
import { LoadMore } from 'components/parts/load-more';

export interface LinkParam {
  pathname: string;
  search?: string;
  state?: any;
}

type WashoutCows = ICowNameInfo & {
  washouts: Pick<Washouts, "meat"|"milk">;
}

export default React.memo<{
  schedules: ISchedule[],
  preSchedules: ISchedule[],
  todayForSchedule: Date,
  activities: ActivityDto[],
  hasNextActivities: boolean,
  onNextActivityClick: () => void,
  onUndo: (activity:ActivityDto) => void,
  watchingCows: Readonly<IPopupCow[]>,
  washoutCows: Readonly<WashoutCows[]>,
  onCowClick: (cow_id:number) => void,
  onLinkClick: (link:LinkParam) => void,
  ranch_id:number,
  onForwardSchedule: (schedule: RanchScheduleModifyReq) => void,
  onUpdateScheduleStatus: (schedule: ISchedule, toFinish: boolean) => void,
  showQuestionAsync: (text: string, buttons: string[]) => Promise<number|undefined>,
  onScheduleClick: (schedule:ISchedule) => void,
}>(
  (props) => {
    const [ expanded, setExpanded ] = useState(false);

    const buildActClick = (act: ActivityDto) => {
      if (act.cows?.length !== 1) return undefined;
      const cowId = act.cows[0].cow_id;

      if (act.crud !== A.CRUD.CREATE && act.crud !== A.CRUD.UPDATE) return undefined;
      if (act.occured_at == null) return undefined;
      if (act.is_undone === 1) return undefined;

      const actInfoKey = ACTIVITY_KINDS.find(k => ACTIVITY_KIND[k].no === act.activity_kind);
      if (actInfoKey == null) return undefined;

      const actInfo = ACTIVITY_KIND[actInfoKey];
      if (actInfo.eventPageTab == null) return undefined;

      const hisLocation = historyLocation.toCowEvent(cowId, act.occured_at.substr(0, "yyyy-mm-dd".length), actInfo.eventPageTab);
      return () => props.onLinkClick(hisLocation);
    }

    const renderActivityContent = (act:ActivityDto) => {
      const cows = (act.cows ?? [])
        .map(c => ({ ...c, trace_id: c.trace_id ?? "" }));
      if (cows.some(c => c.trace_id === "")) {
        console.error("invalid cow trace_id", cows);
        return <></>
      }

      const time = <span className={styles.time}>{act.created_at.substr("yyyy-mm-dd ".length, "hh:mm".length)} </span>

      const msg = <span className={styles.info}>{buildActivityText(act.activity_kind, act.crud)}（{act.name}）</span>

      const onClick = buildActClick(act);
      const link = onClick == null ? (<></>)
        : (<IconLink className="m-l-10" onClick={onClick} text="確認" iconType="navigate" />);

      if (cows.length === 0) {
        return <>{time}<span>牧場</span>{msg}{link}</>
      }
      if (cows.length === 1) {
        const cowName = CowToDispInfo(cows[0]);
        const cowElm = act.activity_kind === ACTIVITY_KIND.COW.no && act.crud === A.CRUD.DELETE 
                      ? <span className={styles.sentence}>{cowName}</span> 
                      : <span className={styles.sentence + " link"} onClick={()=>props.onCowClick(cows[0].cow_id)}>{cowName}</span>
        return <>{time}{cowElm}{msg}{link}</>
      }

      return (<>
          {time}
          <ActivityCowsPopupContainer placement="bottom"
            cows={act.cows}
            cowCount={act.cow_count}
            ranchId={props.ranch_id}
            onCowClick={c => props.onCowClick(c.cow_id)}
            activityId={act.activity_id}
          >
            <span className={styles.sentence + " link"}>{act.cow_count}頭</span>
          </ActivityCowsPopupContainer>
          {msg}
      </>)
    }

    const buildActivityText = (activityKind:number, crud:number) => {
      const crudName = crud === A.CRUD.CREATE ? "登録"
                     : crud === A.CRUD.UPDATE ? "更新"
                     : crud === A.CRUD.DELETE ? "削除"
                     : null;
      if (crudName == null) return "";

      const actInfoKey = ACTIVITY_KINDS.find(a => ACTIVITY_KIND[a].no === activityKind);
      if (actInfoKey == null) return "";
      const actName = ACTIVITY_KIND[actInfoKey].name;

      switch (activityKind) {
        case ACTIVITY_KIND.COW.no:
          return `の情報を${crudName}しました`;
        case ACTIVITY_KIND.LOCATION.no:
          return crud === A.CRUD.CREATE ? "を移動しました" : `の移動記録を${crudName}しました`;
        default:
          return crud === A.CRUD.CREATE ? `の${actName}を記録しました` : `の${actName}記録を${crudName}しました`;
      }
    }

    const dispScheduleTime = (s: ISchedule, renderFor: "today"|"yesterday", today: Date): string => {

      if (renderFor === "yesterday") {
        return moment(s.start).format("M/D");
      }

      //前日以前から日またぎの予定
      const startDay = moment(s.start).startOf("day");
      if (startDay.isBefore(today)) {
        return startDay.format("M/D") + "～";
      }

      if (s.allDay) return "終日";

      return moment(s.start).format("HH:mm");
    }

    const renderSchedule = (s: ISchedule, renderFor: "today"|"yesterday") => (
      <div key={s.id} className={classnames(styles.schedule, styles.item)} data-testid="schedule-item">
        <div className={"checkbox checkbox-css " + styles.checkbox}>
          <input type="checkbox" id={"chkPlan_" + s.id} checked={s.status} onChange={e => props.onUpdateScheduleStatus(s, e.target.checked)} />
          <label htmlFor={"chkPlan_" + s.id} />
        </div>
        <div className={classnames(styles["schedule-body"], {[styles["schedule-body-done"]]: s.status} )}>
          <span className={styles.time} data-testid="schedule-time">{dispScheduleTime(s, renderFor, props.todayForSchedule)}</span>
          { s.cows.length === 1 && (
            <span data-testid="schedule-cow" className={styles.sentence}>{CowToDispInfo(s.cows[0], true)}</span>
          )}
          { s.cows.length > 1 && (
            <CowsPopupContainer placement="top" cows={s.cows} onCowClick={c => props.onCowClick(c.cow_id)}>
              <span data-testid="schedule-cow" className={classnames(styles.sentence, { "clickable": s.status, "link": !s.status })}>{CowToDispInfo(s.cows[0], true)}他</span>
            </CowsPopupContainer>
          )}
          <span className={classnames(styles.sentence, "clickable")} data-testid="schedule-title" onClick={() => props.onScheduleClick(s)}>
            [{EventKind.find(s.eventKindNo)?.schedule?.sname??""}]{s.dispTitle}
            <i className="far fa-clone p-l-2 p-r-5" />
          </span>
          { s.results.length > 0 && (
            <span className={styles["has-record"]}>(記録済)</span>
          )}
        </div>
        { !s.status && (
          <ScheduleForwarder
            className={styles.tomorrow}
            schedule={s}
            onRequest={r => props.onForwardSchedule({...r, ranch_id:props.ranch_id })}
            showQuestionAsync={props.showQuestionAsync}
            baseDate={props.todayForSchedule}
          />
        )}
      </div>
    )

    const renderWatchingItem = (cow: IPopupCow) => (
      <div key={cow.cow_id} className={ styles.item }>
        <span className="link" onClick={()=>props.onCowClick(cow.cow_id)}>{CowToDispInfo(cow, true)}</span>
        { cow.watching_memo !== "" && (
          <span className={ styles.watchingmemo }>{cow.watching_memo}</span>
        )}
      </div>
    )

    return (<>
      <Slider dots={true} infinite={false} slidesToShow={1} slide="section">
        <div>
          <section className={ styles.board }>
            <h1 className={ styles.boardheader }>本日の予定</h1>
            <div className={ classnames({ [styles.expand]: expanded }) }>
              { props.preSchedules.length > 0 && (
                <>
                  <div className={styles.date}>（前日未完了）</div>
                  { props.preSchedules.map(s => renderSchedule(s, "yesterday"))}
                  <hr />
                </>
              )}
              { props.schedules.map(s => renderSchedule(s, "today"))}
              <BoardExpander expanded={expanded} setExpanded={setExpanded} />
            </div>
          </section>
        </div>
        <div>
          <section className={ styles.board }>
            <h1 className={ styles.boardheader }>アクティビティ</h1>
            <div className={ classnames({ [styles.expand]: expanded }) }>
            { [...CommonUtil.groupBy(props.activities, a => a.created_at.substr(0, "yyyy-mm-dd".length)).entries()]
                .map((kv,i) => (<Fragment key={kv[0]}>
                  { i !== 0 && <hr />}
                  <div className={ styles.date }>{moment(kv[0]).format("M月D日")}</div>
                  { kv[1].map(act => (
                    <div className={classnames(styles.item, styles.activity)} key={act.activity_id} data-testid={`activity__${kv[0]}`}>
                      <div className={classnames(styles.content, { [styles.undone]:act.is_undone === 1 })}>
                        {renderActivityContent(act)}
                      </div>
                      { act.can_undo === 1 && (
                        <div className={styles.undo}>
                          <i className="fas fa-undo-alt clickable" onClick={() => props.onUndo(act)} />
                        </div>
                      )}
                    </div>
                  )) }
                </Fragment>))
            }
            { props.hasNextActivities && (
              <LoadMore className={styles.more} onClick={props.onNextActivityClick} />
            )}
              <BoardExpander expanded={expanded} setExpanded={setExpanded} />
            </div>
          </section>
        </div>
        {/* 要観察 */}
        <div>
          <section className={ styles.board }>
            <h1 className={ styles.boardheader }><i className="fas fa-exclamation-circle"></i> 要観察</h1>
            <div className={ classnames({ [styles.expand]: expanded }) }>
              { props.watchingCows.filter(c => c.watching).map(c => renderWatchingItem(c)) }
              { props.watchingCows.some(c => c.watching) && props.watchingCows.some(c => !c.watching) && (
                <hr />
              )}
              { props.watchingCows.filter(c => !c.watching).map(c => renderWatchingItem(c)) }
              <BoardExpander expanded={expanded} setExpanded={setExpanded} />
            </div>
          </section>
        </div>
        {/* 出荷制限中 */}
        <div>
          <section className={ styles.board }>
            <h1 className={ styles.boardheader }><img src={washoutIcon} style={{height: "16px", marginRight: "5px", float: "left"}} /> 出荷制限中</h1>
            <div className={ classnames({ [styles.expand]: expanded }) }>
              { props.washoutCows.map((cow, i) => (
                <div key={i} className={ styles.item }>
                  <div>
                    <span className="link" onClick={()=>props.onCowClick(cow.cow_id)}>{CowToDispInfo(cow, true)}</span>
                    { cow.washouts.meat && 
                      <span className={ styles["washout-term"] }>肉 {Washouts.formatEndDate(cow.washouts.meat.to)}まで</span>
                    }
                    { cow.washouts.milk && 
                      <span className={ styles["washout-term"] }>乳 {Washouts.formatEndDate(cow.washouts.milk.to)}まで</span>
                    }
                  </div>
                </div>
              ))}
              <BoardExpander expanded={expanded} setExpanded={setExpanded} />
            </div>
          </section>
        </div>
      </Slider>
    </>)
  }
)

const BoardExpander = React.memo((props: {
  expanded: boolean,
  setExpanded: (ex: boolean) => void
}) => {
  return (
    <div className={styles.expander} onClick={() => props.setExpanded(!props.expanded)}>
      <i className={props.expanded ? "fas fa-chevron-up" : "fas fa-chevron-down"} />
    </div>    
  )
});