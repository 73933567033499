import React from 'react';
import { Route, Switch, Redirect, withRouter, RouteComponentProps } from 'react-router-dom';
import routes, { PageRoute } from './../../config/page-route';
import { PageSettings } from './../../config/page-settings.js';
import Auth from './Auth';

function setTitle(path: string, routeArray: PageRoute[]) {

	const target = routeArray.find(r => {
		if (r.title == null) return false;

		const idx = r.path.lastIndexOf(":");
		let trgPath = idx < 0 ? r.path : r.path.substr(0, idx);
		if (trgPath.endsWith("/")) {
			trgPath = trgPath.substr(0, trgPath.length -1);
		}
		return path.includes(trgPath);
	});

	document.title = target?.title != null ? `vetell | ${target.title}` : "vetell";
}

interface MyProps extends RouteComponentProps {
	hasFooter: boolean;
	hasHeader: boolean;
}

class Content extends React.Component<MyProps> {

	static contextType = PageSettings;

	componentDidMount() {
		setTitle(this.props.history.location.pathname, routes);

		this.props.history.listen(() => {
			setTitle(this.props.history.location.pathname, routes);
		});
	}

	render() {
		const calcHeight = () => {
			const minus = this.props.hasHeader && this.props.hasFooter ? 120
						: this.props.hasHeader ? 60
						: this.props.hasFooter ? 60
						: 0;
			
			if (minus === 0) {
				return "100%";
			}
			return `calc(100% - ${minus}px)`;
		}


		return (
			<PageSettings.Consumer>
				{({ pageContentFullWidth, pageContentClass, pageContentInverseMode }) => (
					<div className={'content ' + (pageContentFullWidth ? 'content-full-width ' : '') + (pageContentInverseMode ? 'content-inverse-mode ' : '') + (pageContentClass ?? "") }
						style={{ overflowY: "auto", margin: "0px", height: calcHeight() }}>
						<Switch>
							{routes.filter(route => !route.requireAuth).map((route, index) => (
								<Route
									key={index}
									path={route.path}
									exact={route.exact}
									component={route.component}
								/>
							))}
							<Auth>
								<Switch>
									{routes.filter(route => route.requireAuth).map((route, index) => (
										<Route
											key={index}
											path={route.path}
											exact={route.exact}
											component={route.component}
										/>
									))}
									<Redirect to="/top" />
								</Switch>
							</Auth>
							<Redirect to="/login" />
						</Switch>
					</div>
				)
				}
			</PageSettings.Consumer>
		)
	}
}

export default withRouter(Content);