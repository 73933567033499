import React, { useState, useEffect, useCallback } from 'react';
import { ResponsiveContainer, XAxis, YAxis, CartesianGrid, Legend, ReferenceLine, BarChart, Bar } from 'recharts';
import { useSellMilkGraph } from 'stores/fetcher_sell_milk';
import { FetchWaiter, FetchError } from 'components/content/fetch-state';
import moment from 'moment';
import { SELL_MILK_CHART_DAYS_PER_PAGE } from './cow-info';
import { GraphPager } from 'components/parts/graph-pager';

interface Props {
    ranchId: number;
    cowId: number;
    /**
     * グラフ初期表示の最終日＆今乳期の最終日
     */
    initialEndDay: Date;
    birthday: string | undefined;
}


export const SellMilkGraphContainer = React.memo((props: Props) => {
    const [ endDay, setEndDay ] = useState<Date>(new Date());
    const [ hasNext, setHasNext ] = useState(false);
    const [ hasPrev, setHasPrev ] = useState(false);

    const { data, isError, isLoading } = useSellMilkGraph(props.ranchId, props.cowId, endDay, SELL_MILK_CHART_DAYS_PER_PAGE, props.initialEndDay);

    useEffect(() => {
        setEndDay(props.initialEndDay);

    }, [ props.initialEndDay, props.cowId ]);   //cowIdが変わったときには表示日をリセット

    useEffect(() => {
        const currentStDay = moment(endDay).subtract(SELL_MILK_CHART_DAYS_PER_PAGE - 1, "days");
        setHasPrev(props.birthday == null || currentStDay.isAfter(moment(props.birthday).startOf("day")));
        setHasNext(moment(endDay).isBefore(props.initialEndDay));

    }, [ props.initialEndDay, endDay, props.birthday ]);

    const onPrev = useCallback(() => {
        setEndDay(moment(endDay).subtract(SELL_MILK_CHART_DAYS_PER_PAGE, "days").toDate());
    }, [ endDay ]);

    const onNext = useCallback(() => {
        setEndDay(moment(endDay).add(SELL_MILK_CHART_DAYS_PER_PAGE, "days").toDate());
    }, [ endDay ]);

    const buildSummary = () => {
        if (data == null) return "";

        const to = moment(endDay).format("YYYY/M/D");
        const from = moment(endDay).subtract(SELL_MILK_CHART_DAYS_PER_PAGE - 1, "days").format("YYYY/M/D");
        
        return `${from}～${to} 合計 ${data.total}kg`;
    }

    if (isLoading) return <FetchWaiter />
    if (isError || data == null) return <FetchError />

    return (<>
        <GraphPager containerStyle={{ lineHeight:1 }}
            hasNext={hasNext} hasPrev={hasPrev} onNext={onNext} onPrev={onPrev}
        >
            <div style={{ fontSize:"0.8125rem", color:"#999", paddingTop:"2px" }}>{buildSummary()}</div>
        </GraphPager>
        <div style={{ width:"100%", height:"74%", overflow:"hidden" }}>
            <SellMilkGraph data={data.days} />
        </div>
        { data.total_season != null && (
            <div style={{ textAlign:"right", color:"#999" }}>今乳期合計 {data.total_season}kg</div>
        )}
    </>)
});


interface EventData {
    activeLabel: string;
    activePayload?: Payload[];
}
interface Payload {
    color: string;
    name: string;
    value: number | null;
    dataKey: "amount" | "amount_discard";
}
interface GraphData {
    day: string;
    amount: number;
    amount_discard: number;
}


const SellMilkGraph = React.memo((props: {
    data: GraphData[]
}) => {
    const [ currentData, setCurrentData ] = useState<EventData>();

    const onChartClick = (e:EventData|null) => {
        setCurrentData(e ?? undefined);
    }

    return (
        <div style={{width:"calc(100% - 10px)", height:"100%", position:"relative", marginLeft:"-10px", background:"white", zIndex:2 }}>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart onClick={onChartClick} data={props.data}>
                    <Bar key="amount"
                        dataKey="amount"
                        name="出荷"
                        yAxisId={1}
                        stackId={1}
                        fill="#00ccff"
                        unit="kg"
                    />
                    <Bar key="amount_discard"
                        dataKey="amount_discard"
                        name="廃棄"
                        yAxisId={1}
                        stackId={1}
                        fill="#aaaaaa"
                        unit="kg"
                    />
                    <XAxis dataKey="day" type="category"
                        tickFormatter={(day) => moment(day).format("M/D")}
                        tick={{fontSize:"10px"}}
                        height={33}
                    />
                    <YAxis yAxisId={1} />
                    <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />

                    { currentData != null && (
                        <ReferenceLine x={currentData.activeLabel} stroke="red" strokeDasharray="3 3" yAxisId={1} />
                    ) }
                    <Legend margin={{top:40, left:0, right:0, bottom:0}} />
                </BarChart>
            </ResponsiveContainer>
            { currentData != null && (
                // ※標準のTooltipは、スタイルの自作はできるが、表示非表示の手動制御ができず、
                // 　state更新などのトリガで勝手に閉じてしまうので、自前で作り直す
                <div className="chart-legend">
                    <div>{moment(currentData.activeLabel).format("M/D")}</div>
                    { (currentData.activePayload ?? []).map((p,i) => (
                        <div key={i}>
                            <span style={{ color:p.color }}>●</span>
                            <span>{p.name} : {p.value}kg</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )

});