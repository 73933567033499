import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PageSettings } from '../../config/page-settings.js';
import { IEditingTreat } from './treat-master';
import { ITreatKind } from '../../stores/RootStore';
import { LMT } from '../../config/constant';
import { OptionalNumInput, RequiredNumInput } from '../../components/parts/num-input';
import { ExecutionButton } from '../../components/buttons/execution-button';

interface MyProps {
    data: IEditingTreat | null;
    treatKindList: ITreatKind[];
    onSubmit: ()=>void;
    onClose: ()=>void;
    onDelete: ()=>void;
    onNameEdited:(name:string)=>void;
    onHasUnitChanged:(hasUnit:boolean)=>void;
    onUnitEdited:(unit:string)=>void;
    onFeeEdited:(fee: number)=>void;
    onCodeEdited:(code:string)=>void;
    onPointAEdited:(point:number|null)=>void;
    onPointBEdited:(point:number|null)=>void;
    isSubmitExecuting: boolean;
}
interface MyState {
}

export class TreatEditPopup extends React.Component<MyProps,MyState> {

    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        return (
            <div>
                <Modal isOpen={true} centered={true}>
                    <ModalHeader toggle={this.props.onClose}></ModalHeader>
                    <ModalBody style={{ overflowY: "auto", maxHeight: "calc(100vh - 150px)" }}>
                        <div>
                            <div className="form-group" style={{display:"flex"}}>
                                <label className="col-form-label" style={{width:"80px"}}>種別</label>
                                <div style={{alignSelf:"center", fontWeight:"bold"}}>
                                    {this.props.treatKindList.find(k => k.treat_kind_no === this.props.data?.treat_kind_no)?.name??""}
                                </div>
                            </div>
                            <div className="form-group" style={{display:"flex"}}>
                                <label className="col-form-label" style={{width:"80px"}}>処置名</label>
                                <div>
                                    <input className="form-control" type="text" value={this.props.data?.name ?? ""} maxLength={LMT.TREAT.NAME_LEN}
                                            onChange={(e)=>this.props.onNameEdited(e.target.value)}/>
                                </div>
                            </div>
                            <div className="form-group" style={{display:"flex"}}>
                                <label className="col-form-label" style={{width:"80px"}}>金額</label>
                                <div style={{display:"flex", flexDirection:"column"}}>
                                    <div className="radio radio-css">
                                        <input id="radioNoUnit" type="radio" checked={!this.props.data?.hasUnit}
                                               onChange={() => this.props.onHasUnitChanged(false)}></input>
                                        <label htmlFor="radioNoUnit">1頭あたり</label>
                                    </div>
                                    <div style={{display:"flex", alignItems:"center"}}>
                                        <div className="radio radio-css">
                                            <input id="radioHasUnit" type="radio" checked={this.props.data?.hasUnit} onChange={() => this.props.onHasUnitChanged(true)}></input>
                                            <label htmlFor="radioHasUnit">1</label>
                                        </div>
                                        <input className="form-control" type="text" style={{width:"60px", bottom:0, paddingTop:"12px", paddingLeft:"4px"}}
                                               placeholder="(単位)" onChange={(e)=>this.props.onUnitEdited(e.target.value)} maxLength={LMT.TREAT.UNIT_LEN}
                                               value={this.props.data?.fee_unit ?? ""}
                                               disabled={!this.props.data?.hasUnit}></input>
                                        <span style={{marginTop:"5px", marginLeft:"2px"}}>あたり</span>
                                    </div>
                                    <div style={{ marginTop:"4px", display:"flex", flexWrap:"wrap" }}>
                                        <div style={{ display:"flex", alignItems:"center" }}>
                                            <RequiredNumInput
                                                max={LMT.TREAT.FEE_MAX} min={LMT.TREAT.FEE_MIN} step={LMT.TREAT.FEE_STEP}
                                                style={{ width:"80px", marginRight:"2px" }}
                                                value={this.props.data?.fee ?? 0}
                                                onChange={val => this.props.onFeeEdited(val)}
                                            />
                                            円
                                        </div>
                                        <div style={{ display:"flex", alignItems:"center" }}>
                                            ：点数
                                            <OptionalNumInput
                                                max={LMT.TREAT.POINT_MAX} min={LMT.TREAT.POINT_MIN} step={LMT.TREAT.POINT_STEP}
                                                style={{ width:"60px", marginLeft: "2px" }} 
                                                placeholder="A"
                                                value={this.props.data?.point_a ?? undefined}
                                                onChange={val => this.props.onPointAEdited(val ?? null)}
                                            />
                                            <OptionalNumInput
                                                max={LMT.TREAT.POINT_MAX} min={LMT.TREAT.POINT_MIN} step={LMT.TREAT.POINT_STEP}
                                                style={{ width:"60px" }}
                                                placeholder="B"
                                                value={this.props.data?.point_b ?? undefined}
                                                onChange={val => this.props.onPointBEdited(val ?? null)}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="form-group" style={{display:"flex"}}>
                                <label className="col-form-label" style={{width:"80px"}}>コード</label>
                                <div>
                                    <input className="form-control" type="text" style={{width:"120px"}} value={this.props.data?.code ?? ""} maxLength={LMT.TREAT.CODE_LEN}
                                            onChange={(e)=>this.props.onCodeEdited(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter className="modal-footer-fix">
                        <ExecutionButton type="save" onClick={this.props.onSubmit} disabled={this.props.isSubmitExecuting} />
                        { this.props.data && !this.props.data.isNew && (
                            <ExecutionButton type="delete" onClick={this.props.onDelete} disabled={this.props.isSubmitExecuting} />
                        )}
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}