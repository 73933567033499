import React, { useState, useEffect } from 'react';
import { getEggRanks, findEggStage, findOvumRank } from '../../config/egg-kind';
import { NumericUpDown } from '../../components/parts/numeric-updown';
import { FreezedArray, CommonUtil, ar } from '../../config/util';
import { EggStageData, EggStageCountModal } from './egg-stage-count-modal';
import { LMT } from '../../config/constant';
import { IvfEggRankDetailPopup } from './ivf-egg-rank-detail-popup';
import classnames from 'classnames';

export interface EggRankData {
    count: number;
    ovumCountMap?: Map<number, number>;
    stages: FreezedArray<EggStageData>;
}

export const isValidRankData = (data: EggRankData) => {
    return isValidRankStages(data) && isValidRankDetails(data);    
}
const isValidRankStages = (data: EggRankData) => {
    if (data.stages.length > 0) {
        if (data.count !== ar.sum(data.stages.map(s => s.count))) return false;
    }
    return true;
}
const isValidRankDetails = (data: EggRankData) => {
    if (data.ovumCountMap != null) {
        const ovSum = ar.sum([...data.ovumCountMap.values()]);
        if (ovSum > 0 && data.count !== ovSum) return false;
    }
    return true;
}

interface MyProps {
    ovumCountMap?: Map<number, number>;
    countMap: Map<number, EggRankData>;
    onChange: (countMap: Map<number, EggRankData>) => void;
    style?: React.CSSProperties;
    className?: string;
}

export const calcGrownPercent = (ovumTotalCount: number | undefined, eggCount: number) => {
    if (ovumTotalCount == null || ovumTotalCount === 0) return undefined;
    return Math.round(eggCount / ovumTotalCount * 100);
}

export const EggRankCounter = (props: MyProps) => {
    const [ stageEditingRank, setStageEditingRank ] = useState<number>();
    const [ detailEditingRank, setDetailEditingRank ] = useState<number>();
    const [ ovumCountTotal, setOvumCountTotal ] = useState<number>();

    useEffect(() => {
        if (props.ovumCountMap == null) {
            setOvumCountTotal(undefined);
        } else {
            setOvumCountTotal(ar.sum([...props.ovumCountMap.values()]));
        }

    }, [ props.ovumCountMap ])


    const styles: { [key:string]:React.CSSProperties } = {
        container: {
            display: "flex",
            flexFlow: "column nowrap"
        },
        item: {
            display: "flex",
            flexFlow: "row nowrap",
            marginBottom: "8px"
        },
        counter: {
            width: "90px"
        },
        label: {
            width: "74px",
            paddingTop: "8px",
            fontWeight: "bold",
            color: "#666666"
        },
        colInput: {

        },
        row: {
            display:"flex",
            alignItems:"center"
        },
        suffix: {
            marginLeft: "6px",
            color: "#666666"
        },
        stageBtn: {
            marginLeft: "12px"
        },
        countDetail: {
            margin: "5px 0 2px"
        },
        validStageInfo: {
            color: "#999999"
        },
        invalidStageInfo: {
            color: "red"
        }
    }

    const onRankCountChange = (rank: number, count: number) => {
        const newMap = new Map(props.countMap);

        //ステージ情報は保持するが、合計0件になるときだけステージもクリアしてしまう（保存済みの在庫がないとき）
        const oldRankData = props.countMap.get(rank);
        const newRankData: EggRankData = {
            count,
            stages:oldRankData?.stages ?? [],
            ovumCountMap: oldRankData?.ovumCountMap
        };
        if (count === 0 && !newRankData.stages.some(s => s.hasStocked)) {
            newRankData.stages = [];
            newRankData.ovumCountMap = undefined;
        }

        newMap.set(rank, newRankData);

        props.onChange(newMap);
    }

    const onStageSubmit = (stages: FreezedArray<EggStageData>) => {
        if (!CommonUtil.assertNotNull(stageEditingRank, "editingRank", "onStageSubmit")) return;
        const oldRankData = props.countMap.get(stageEditingRank);
        if (!CommonUtil.assertNotNull(oldRankData, "oldRankData", "onStageSubmit")) return;
        const newMap = new Map(props.countMap);
        newMap.set(stageEditingRank, {
            ...oldRankData,
            stages
        });
        setStageEditingRank(undefined);
        props.onChange(newMap);
    }
    const onDetailCountSubmit = (ovumCountMap: Map<number, number>) => {
        if (!CommonUtil.assertNotNull(detailEditingRank, "detailEditingRank")) return;
        const oldRankData = props.countMap.get(detailEditingRank);
        if (!CommonUtil.assertNotNull(oldRankData, "oldRankData")) return;
        const newMap = new Map(props.countMap);
        newMap.set(detailEditingRank, {
            ...oldRankData,
            ovumCountMap
        });
        setDetailEditingRank(undefined);
        props.onChange(newMap);
    }

    const calcPercent = (val: number) => {
        const total = ovumCountTotal ?? 0;

        return calcGrownPercent(total, val) ?? "-";
    }

    const ovumCountToText = (countMap: Map<number, number>) => {
        return [...countMap.entries()]
            .map(([r,c]) => `${findOvumRank(r)?.name ?? ""}${c}個`).join(" ");
    }

    return (<>
        <div className={props.className} style={{ ...styles.container, ...props.style }}>
            { getEggRanks(false)
                .map(rank => ({ rank, data: props.countMap.get(rank.egg_rank) }))
                .map(item => ({
                    ...item,
                    isValidStages: item.data == null || isValidRankStages(item.data),
                    isValidDetails: item.data == null || isValidRankDetails(item.data)
                }))
                .map(item => (
                <div key={item.rank.egg_rank} style={styles.item} data-testid={"rank__" + item.rank.name}>
                    <div style={styles.label}>{item.rank.name}</div>
                    <div style={styles.colInput}>
                        <div style={styles.row}>
                            <NumericUpDown style={styles.counter}
                                min={0} max={LMT.SOV.MAX_EGG_COUNT}
                                value={item.data?.count ?? 0}
                                onChange={n => onRankCountChange(item.rank.egg_rank, n)} />
                            <span style={styles.suffix}>個</span>
                            { item.rank.canUse && (
                                <button className="btn btn-sm btn-gray" style={styles.stageBtn}
                                    disabled={(item.data?.count ?? 0) === 0}
                                    onClick={() => setStageEditingRank(item.rank.egg_rank)}
                                    >ステージ</button>
                            )}
                        </div>
                        { (item.data?.count ?? 0) > 0 && (ovumCountTotal ?? 0) > 0 && (
                            <div style={styles.countDetail}>
                                <span data-testid="percent">発生率 {calcPercent(item.data!.count)}%</span>
                                <span className={classnames("link", {"invalid":!item.isValidDetails} )} onClick={() => setDetailEditingRank(item.rank.egg_rank)} data-testid="stage-detail">
                                    { (item.data!.ovumCountMap?.size ?? 0) === 0 ? "（内訳）" : `（${ovumCountToText(item.data!.ovumCountMap!)}）`}
                                </span>
                            </div>
                        )}
                        { (item.data?.stages ?? [])
                            .map((st,is) => (
                                <div key={is} style={item.isValidStages ? styles.validStageInfo : styles.invalidStageInfo}>{findEggStage(st.stage)?.name ?? ""} {st.count}個 {st.isFrozen ? "凍結" : "新鮮"}</div>
                            ))}
                    </div>
                </div>
            ))}
        </div>
        { stageEditingRank != null && (
            <EggStageCountModal
                stages={props.countMap.get(stageEditingRank)?.stages ?? []}
                onClose={() => setStageEditingRank(undefined)}
                total={props.countMap.get(stageEditingRank)?.count ?? 0}
                onSubmit={s => onStageSubmit(s)}
            />
        )}
        { detailEditingRank != null && CommonUtil.assertNotNull(props.ovumCountMap) && (
            <IvfEggRankDetailPopup
                onClose={() => setDetailEditingRank(undefined)}
                egg_rank={detailEditingRank}
                rank_total={props.countMap.get(detailEditingRank)?.count ?? 0}
                allOvumCountMap={props.ovumCountMap}
                ovumCountMap={props.countMap.get(detailEditingRank)?.ovumCountMap ?? new Map()}
                onSubmit={onDetailCountSubmit}
            />
        )}
    </>)

}