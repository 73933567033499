import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import moment from 'moment'

import { SellMilkApi, SellMilkModifyReq, SellMilkDto, SellMilkDeleteReq, CowDetailDto } from '../../api'

import { useRootStore } from '../../stores';
import { usePageStore } from '../../config/page-settings';

import styles from './sellmilk.module.css'
import RegisterRanch from './RegisterRanch';
import RegisterCow from './RegisterCow';
import RegisterCows from './RegisterCows';
import { A } from '../../config/constant';
import { IEventWriteLocationState, historyLocation } from '../../config/history-location-builder';
import { CommonUtil } from '../../config/util';
import { hasRanchAuth } from '../../config/auth-checker';
import { hasRanchContract } from '../../config/contract-checker';
import { Communicator } from '../../api/communicator';
import { EVENT_KIND } from '../../config/event-kind';
import { AppState } from '../../app';
import { resetSellMlikGraph } from 'stores/fetcher_sell_milk';
import { ExecutionButton } from 'components/buttons/execution-button';
import { DIALOG_BUTTONS } from 'components/form/form-dialog';

type TargetType = 'ranch' | 'cow' | 'cows'

export type SellMilk = {
  unit_price: number,
  watched_at: string,
  details: Array<SellMilkDetail>,
}

export type SellMilkDetail = {
  cow_id?: number,
  name?: string,
  trace_id?: string,
  local_no?: string,
  amount: number,
  amount_discard: number,
  is_discard: boolean,
  comment: string,
}

const getParamCowIds = (url:string) => {
  const parm = new URLSearchParams(url).get("param");
  if (parm == null) return [];

  return parm.split(",")
              .map(p => parseInt(p))
              .filter(p => !isNaN(p));
}

const getParamCowTop = (url:string) => {
  const parm = new URLSearchParams(url).get("cow_top");
  if (parm == null) return false;

  return parm === "1";
}

export default () => {

  const rootStore = useRootStore()
  const { user, auth, logout } = rootStore

  if ( !auth.enabled ) {
    rootStore.logout();
    return (<></>)
  }

  const { id } = useParams<{id?:string}>()
  const eventId = id == null ? undefined : parseInt(id)
  const location = useLocation<IEventWriteLocationState>()
  const cowIds = getParamCowIds(location.search)
  const isCowTop = getParamCowTop(location.search)
  const milkCows = location.state?.cows.filter(c => A.IS_FOR_MILK(c.use_no??0))

  if(!eventId && location.pathname.match('cow') ) {
    if (cowIds == null || cowIds.length === 0 ) {
      rootStore.logout();
      return (<></>)
    }
    if (milkCows == null || milkCows.length === 0 ) {
      rootStore.logout();
      return (<></>)
    }
  }

  const history = useHistory()

  const { 
    handleSetHeader, handleSetPageError, 
    handleSetFooter, handleSetIsLoading, 
    showToast, postAsync , showModalAsync, showDialog
  } = usePageStore() as AppState;
  
  const [targetType, setTargetType] = useState<TargetType>(location.pathname.match('ranch') ? 'ranch' : eventId || location.state.cows.length === 1 ? 'cow' : 'cows');
  const [sellMilk, setSellMilk] = useState<SellMilk>({
    unit_price: 0, 
    watched_at: moment().format("YYYY/MM/DD HH:mm") + ":00", 
    details: [{
      amount: 0,
      amount_discard: 0,
      is_discard: false,
      comment: "",
    }]
  });
  const [ executing, setExecuting ] = useState(false);

  useEffect(() => {
    (async () => {
      handleSetHeader({ title:eventId ? "乳代を編集" : "乳代を入力" })
      handleSetPageError(false)
      handleSetFooter(true)

      await loadSellMilk();

    })()
  }, [])

  useEffect(() => {
    const item = targetType === "ranch" ? "BALANCE_RANCH" : "BALANCE_COW";
    if (!hasRanchAuth(item, rootStore.cur_ranch_id, user)) {
      rootStore.logout();
      return;
    }
    if (!hasRanchContract("BREEDING", rootStore.cur_ranch_id, user)) {
      rootStore.logout();
      return;
    }

  }, [targetType])

  const comm = new Communicator({ logout, showDialog, showToast });

  const loadSellMilk = async () => {

    if (!eventId) {
      if (targetType !== 'ranch') {
        setSellMilk({ ...sellMilk, details : milkCows.map(c => ({
            cow_id: c.cow_id,
            trace_id: c.trace_id,
            local_no: c.local_no,
            name: c.name,
            amount: 0,
            amount_discard: 0,
            is_discard: false,
            comment: ""
          }))
        })
      }
      return;
    }

    handleSetIsLoading(true);
    const res = await comm.send<SellMilkDto>(() => postAsync("/sell/milk/" + eventId, {}), { retries: true });
    handleSetIsLoading(false);
    if (res.result !== "OK" || res.data == null) return;

    const data = res.data;
    console.log(data)

    if (navToRanchTopIfSelfEditOnly(data.watched_by)) return;

    let cow: CowDetailDto | undefined;
    if (data.cow_id) {
      cow = await loadCowInfo(data.cow_id)
      if (!cow) return;
    }

    const is_discard = data.amount === 0 && data.amount_discard !== 0;
    const amount = targetType === 'ranch' ? data.amount : is_discard ? data.amount_discard : data.amount;

    setSellMilk({ ...sellMilk, 
      unit_price: data.unit_price, 
      watched_at: data.watched_at,
      details: [{
        cow_id: data.cow_id,
        trace_id: cow?.trace_id,
        local_no: cow?.local_no,
        name: cow?.name,
        amount: amount, 
        amount_discard: data.amount_discard,
        comment:data.comment,
        is_discard: is_discard,
      }],
    })
  }

  const navToRanchTopIfSelfEditOnly = (watchedBy: string): boolean => {
    const kind = targetType === "ranch" ? EVENT_KIND.SELL_MILK_RANCH : EVENT_KIND.SELL_MILK_COW;
    if (kind.selfEditOnly !== true) return false;

    if (user.id === watchedBy) return false;

    showModalAsync(A.MESSAGE.SELF_EDIT_ONLY, "ALERT", ["OK"]).then(() => {
      history.replace("/top/" + rootStore.cur_ranch_id);
    });
    return true;
  }

  const loadCowInfo = async (cowId:number) => {
    const query_str = `?ranch_id=${rootStore.cur_ranch_id}&is_detail=0`;
    handleSetIsLoading(true);
    const res = await comm.send<CowDetailDto>(() => postAsync("/cow/" + cowId + query_str, {}), { retries: true });
    handleSetIsLoading(false);
    if (res.result !== "OK") return undefined;

    return res.data;
  }
  
  const canRegist = () => {

    if( isNaN(sellMilk.unit_price)
      || sellMilk.details.some(d => isNaN(d.amount) || isNaN(d.amount_discard))) {
        return false;
    }

    if( sellMilk.unit_price === 0 
      && !sellMilk.details.some(d => d.amount > 0 || d.amount_discard> 0) ) {
        return false;
    }

    return true;
  }

  const registSellMilk = async () => {
    setExecuting(true);
    const req:SellMilkModifyReq = {
      is_new: eventId ? 0 : 1,
      ranch_id: rootStore.cur_ranch_id,
      cow_ids: targetType === 'ranch' ? undefined : sellMilk.details.map(d => d.cow_id!),
      event_id: eventId ? eventId : undefined,
      unit_price: sellMilk.unit_price,
      amounts: sellMilk.details.map(d => targetType === 'ranch' ? d.amount : d.is_discard ? 0 : d.amount),
      amount_discards: sellMilk.details.map(d => targetType === 'ranch' ? d.amount_discard : d.is_discard ? d.amount : 0),
      watched_at: moment(sellMilk.watched_at).format("YYYY-MM-DD HH:mm:00"),
      comments: sellMilk.details.map(d => d.comment),
    }
    console.log(req)
    handleSetIsLoading(true)
    const res = await comm.send((await SellMilkApi()).modifyUsingPOST5(req));
    handleSetIsLoading(false)

    if (res.result !== "OK") {
      setExecuting(false);
      return;
    }

    if (req.cow_ids != null) {
      for (const cowId of req.cow_ids) {
        resetSellMlikGraph(cowId, false);
      }
    }

    isCowTop ? history.replace(historyLocation.toCowInfo(sellMilk.details[0].cow_id!)) : history.go(-1)
  }

  const deleteSellMilk = async () => {
    if (!CommonUtil.assertNotNull(eventId,"eventId", "delete")) return;

    const conf = await showDialog("QUESTION", "記録を削除してよろしいですか？", DIALOG_BUTTONS.DELETE_CANCEL);
    if (conf !== 0) return;
    
    const req:SellMilkDeleteReq = {
      event_id: eventId,
      ranch_id: rootStore.cur_ranch_id,
      cow_id: sellMilk.details[0].cow_id,
    }
    handleSetIsLoading(true)
    const res = await comm.send((await SellMilkApi()).deleteUsingPOST6(req));
    handleSetIsLoading(false)

    if (res.result !== "OK") return;

    if (req.cow_id != null) {
      resetSellMlikGraph(req.cow_id, false);
    }

    isCowTop ? history.replace(historyLocation.toCowInfo(sellMilk.details[0].cow_id!)) : history.go(-1);
  }

  return (
    <main className={ styles.page }>
      <section className={ styles["content-area"] }>
        <div className={ styles["content"] }>
        { targetType === 'ranch' &&
          <RegisterRanch
            sellMilk={sellMilk} 
            setSellMilk={setSellMilk} 
            user={user}
          />
        }
        { targetType === 'cow' &&
          <RegisterCow
            sellMilk={sellMilk}
            setSellMilk={setSellMilk}
            user={user}
            ranchId={rootStore.cur_ranch_id}
          />
        }
        { targetType === 'cows' &&
          <RegisterCows
            cowIds={cowIds}
            sellMilk={sellMilk}
            setSellMilk={setSellMilk}
            user={user}
          />
        }
        </div>
      </section>
      <section className={ styles["fix-bottom"] }>
        <div className={ styles.footmenu }>
          <ExecutionButton type="save" disabled={executing || !canRegist()} onClick={registSellMilk} />
          { eventId && (
              <ExecutionButton type="delete" onClick={deleteSellMilk} disabled={executing} />
          )}
        </div>
      </section>
    </main>
  );
}