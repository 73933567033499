import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { Checkbox } from '../../components/form/form-checkbox';
import { TREAT_BENEFIT_TYPE, getSymptomStatus } from '../../config/constant';
import { KarteListSeriesDto, KarteListSeriesEventDto, KarteListTreatDto, KarteListSeriesDtoEventKindEnum } from '../../api';
import moment from 'moment';
import styles from './cert-karte.module.css';
import { CommonUtil } from '../../config/util';

export type karteSeriesWithSummary = KarteListSeriesDto & {
    key:string,
    summaryForSelect:string,
    summaryForList:string,
    status:number,
};
export type EditingKarteEvent = Omit<KarteListSeriesEventDto, "treats"> & {
    selected:boolean,
    treats:Array<KarteListTreatDto & { selected: boolean }>;
}
export type EditingKarteSeries = Omit<karteSeriesWithSummary, "events"> & { events: EditingKarteEvent[] };

interface MyProps {
    dispName:string;
    series: karteSeriesWithSummary[];
    selectedSeries: EditingKarteSeries;
    onClose: () => void;
    onSubmit: (series: EditingKarteSeries) => void;
}

export const CertKarteEditPopup = (props: MyProps) => {
    const [ data, setData ] = useState<EditingKarteSeries>({ event_kind:KarteListSeriesDtoEventKindEnum.SYMPTOM, events:[], found_at:"", has_next:false, key:"", summaryForList:"", summaryForSelect:"",status:0 });

    useEffect(() => {
        setData(props.selectedSeries);

    }, [ props.selectedSeries ])

    const buildSeriesInfo = (series: EditingKarteSeries) => {
        const kind = series.event_kind === KarteListSeriesDtoEventKindEnum.BREEDING ? "検診記録"
                    : series.event_kind === KarteListSeriesDtoEventKindEnum.SYMPTOM ? "診療記録"
                    : "";
        const status = getSymptomStatus(series.status)?.name ?? "";
        const next = series.has_next ? "※この転帰後にも記録あり" : "";

        return `${kind} ${status} ${next}`;
    }

    const onSeriesSelect = (key:string) => {
        const target = props.series.find(s => s.key === key);
        if (CommonUtil.assertNotNull(target, "series of " + key)) {
            setData({
                ...target,
                events: target.events.map(e => ({
                    ...e,
                    selected:true,
                    treats: e.treats.map(t => ({ ...t, selected:true }))
                })),
            });
        }
    }

    return (
        <div>
            <Modal isOpen={true} scrollable={true}>
                <ModalHeader toggle={props.onClose}>{props.dispName}</ModalHeader>
                <ModalBody>
                    <select className="form-control" value={data.key} onChange={e => onSeriesSelect(e.target.value)}>
                        { props.series.map(se => (
                            <option key={se.key} value={se.key}>{se.summaryForSelect}</option>
                        ))}
                    </select>
                    <div className={styles["popup-series-info"]}>{buildSeriesInfo(data)}</div>

                    <div className={styles["popup-header"]}>出力対象</div>
                    { data.events.map(ev => (
                        <div className={styles["popup-event-container"]} key={ev.event_id}>
                            <Checkbox id={"chkEv" + ev.event_id}
                                label={moment(ev.watched_at).format("YYYY/M/D HH:mm") + " " + (ev.watched_by_name ?? "")}
                                checked={ev.selected}
                                onChange={e => setData({
                                    ...data,
                                    events:data.events.map(de => de.event_id !== ev.event_id ? de : ({
                                        ...de,
                                        selected:e.target.checked,
                                        treats: e.target.checked ? de.treats : de.treats.map(t => ({ ...t, selected:false }))
                                    }))
                                })}
                            />
                            { ev.treats.map(tr => (
                                <Checkbox id={"chkTr" + tr.id} className={styles["treat"]} key={tr.id}
                                    label={`${tr.medicine_name ?? tr.treat_item_name}${tr.benefit_type === TREAT_BENEFIT_TYPE.NO_BENEFIT.no ? " (給付外)" : ""}`}
                                    checked={tr.selected}
                                    onChange={e => setData({
                                        ...data,
                                        events: data.events.map(de => de.event_id !== ev.event_id ? de : ({
                                            ...de,
                                            selected: e.target.checked ? true : de.selected,
                                            treats: de.treats.map(dt => dt.id !== tr.id ? dt : ({ ...dt, selected:e.target.checked }))
                                        }))
                                    })}
                                />
                            ))}
                        </div>
                    ))}
                </ModalBody>
                <ModalFooter className="modal-footer-fix">
                    <ExecutionButton type="decide" disabled={data.events.every(e => !e.selected)} onClick={() => props.onSubmit(data)} />
                </ModalFooter>
            </Modal>
        </div>
    )

}