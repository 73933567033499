import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ar } from '../../config/util';
import { isMobileOnly } from 'react-device-detect';
import { IconLink } from './icon-link';

interface MyProps {
    items: { name: string, values: number[] }[];
    selectedValues: Set<number>;
    onChange: (values: Set<number>) => void;
    style?: React.CSSProperties;
    prefix?: string;
    modalTitle: string;
}

export const MultiFilter = (props: MyProps) => {
    const [ isAllSelected, setIsAllSelected ] = useState(false);
    const [ isPopupShown, setIsPopupShown ] = useState(false);

    useEffect(() => {
        setIsAllSelected(ar.flat(props.items.map(p => p.values)).every(n => props.selectedValues.has(n)));

    }, [ props.selectedValues, props.items ]);

    const onChange = (values: number[], checked: boolean) => {
        const newValues = new Set([...props.selectedValues]);
        if (checked) {
            values.forEach(v => newValues.add(v));
        } else {
            values.forEach(v => newValues.delete(v));
        }
        props.onChange(newValues);
    }
    const onAllChange = (checked: boolean) => {
        if (checked) {
            props.onChange(new Set(ar.flat(props.items.map(p => p.values))));
        } else {
            props.onChange(new Set());
        }
    }

    const prefix = props.prefix ?? "filter-item-";

    if (isMobileOnly) {
        const selectedName = (isAllSelected || props.selectedValues.size === 0) ? "種別で絞り込み"
                        : props.items.filter(i => i.values.every(v => props.selectedValues.has(v))).map(i => i.name).join(",");
        return (<>
            <div style={{ display:"flex", ...props.style }}>
                <IconLink iconType="filter" text={selectedName} onClick={() => setIsPopupShown(true)} />
            </div>
            { isPopupShown && (
                <FilterPopup title={props.modalTitle}
                    prefix={prefix}
                    items={props.items}
                    selectedValues={props.selectedValues}
                    onClose={() => setIsPopupShown(false)}
                    onSubmit={vals => { props.onChange(vals); setIsPopupShown(false) }}
                />
            )}
        </>)
    }


    const styles: { [key: string] : React.CSSProperties } = {
        container: {
            display: "flex",
            flexWrap: "wrap",
            border: "1px solid #BBB",
            borderRadius: "4px",
            background: "#F9F9F9",
            padding: "2px 4px"
        },
        checkbox: {
            padding: "4px 0"
        },
        separator: {
            margin: "auto 10px",
            color: "#AAA"
        }
    }

    return (
        <div style={{ ...styles.container, ...props.style }}>
            <div className="checkbox checkbox-css" style={styles.checkbox}>
                <input type="checkbox" id={prefix + "all"}
                        checked={isAllSelected}
                        onChange={e => onAllChange(e.target.checked)}
                />
                <label htmlFor={prefix + "all"}>全選択</label>
            </div>
            <span style={styles.separator}>|</span>
            { props.items.map((item, i) => (
                <div key={i} className="checkbox checkbox-css checkbox-inline" style={styles.checkbox}>
                    <input type="checkbox" id={prefix + i}
                        checked={item.values.every(v => props.selectedValues.has(v))}
                        onChange={e => onChange(item.values, e.target.checked)}
                    />
                    <label htmlFor={prefix + i}>{item.name}</label>
                </div>
            ))}
        </div>
    );
}


interface FilterPopupProps {
    items: { name: string, values: number[] }[];
    selectedValues: Set<number>;
    onSubmit: (values: Set<number>) => void;
    onClose: () => void;
    prefix: string;
    title: string;
}


export const FilterPopup: React.FC<FilterPopupProps> = (props) => {
    const [ selectedVals, setSelectedVals ] = useState(new Set<number>([]));
    const [ isAllSelected, setIsAllSelected ] = useState(false);

    useEffect(() => {
        setIsAllSelected(ar.flat(props.items.map(p => p.values)).every(n => selectedVals.has(n)));

    }, [ props.items, selectedVals ]);
    
    useEffect(() => {
        setSelectedVals(props.selectedValues);

    }, [ props.selectedValues ]);


    const chkId = (idx: number) => `${props.prefix}_${idx}`;

    const onChange = (values: number[], checked: boolean) => {
        const newValues = new Set([...selectedVals]);
        if (checked) {
            values.forEach(v => newValues.add(v));
        } else {
            values.forEach(v => newValues.delete(v));
        }
        setSelectedVals(newValues);
    }

    const onAllChange = (checked: boolean) => {
        if (checked) {
            setSelectedVals(new Set(ar.flat(props.items.map(p => p.values))));
        } else {
            setSelectedVals(new Set());
        }
    }

    const styles: { [key: string]: React.CSSProperties } = {
        container: {
            maxWidth: "320px",
            margin: "auto"
        },
        footer: {
            display: "flex",
            justifyContent: "center"
        },
        partialContainer: {
            display: "flex",
            flexWrap: "wrap"
        },
        checkbox: {
            marginRight: "16px",
            marginBottom: "12px"
        }
    }

    return (
        <div>
            <Modal isOpen={true} style={styles.container}>
                <ModalHeader toggle={props.onClose}>{props.title}</ModalHeader>
                <ModalBody style={{ overflowY: "auto", maxHeight: "calc(100vh - 180px)" }}>
                    <div>
                        <div className="checkbox checkbox-css">
                            <input type="checkbox" id={props.prefix + "all"}
                                    checked={isAllSelected}
                                    onChange={e => onAllChange(e.target.checked)}
                            />
                            <label htmlFor={props.prefix + "all"}>全選択</label>
                        </div>
                        <hr />
                        <div style={styles.partialContainer}>
                        {
                            props.items.map((item, i) => (
                                <div key={i} className="checkbox checkbox-css" style={styles.checkbox}>
                                    <input type="checkbox" id={chkId(i)}
                                        onChange={(e) => onChange(item.values, e.target.checked)}
                                        checked={item.values.every(v => selectedVals.has(v))} />
                                    <label htmlFor={chkId(i)}>{item.name}</label>
                                </div>
                            ))
                        }
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter style={styles.footer}>
                    <button className="btn btn-success" onClick={() => props.onSubmit(selectedVals)}
                        disabled={selectedVals.size === 0}>
                        確定
                    </button>
                </ModalFooter>
            </Modal>
        </div>
    )
}