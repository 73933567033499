import React from 'react';
import styles from './team-authority.module.css';
import classnames from 'classnames';
import { TeamType } from '../../config/constant';
import { hasAuthLvDetail, getAuthLevelDetailInfo, findAuthUIDetailsByValue, getAuthLevelName } from '../../config/auth-checker';
import { FreezedArray } from '../../config/util';
import { ITeamUserSelectable } from './team-authority';

interface Props {
    teamType: TeamType;
    ownerId: string;
    users: FreezedArray<ITeamUserSelectable>;
    onSelect: (user:ITeamUserSelectable, singleSelection: boolean) => void;
}

export const TeamAuthorityTable = React.memo((props: Props) => {

    const renderRow = (user: ITeamUserSelectable, isOwner: boolean) => {
        const name = isOwner ? `${user.name}（オーナー）` : user.name;

        return (
            <tr key={user.user_id} className={user.selected ? styles.selected : undefined}>
                <td className={styles["user-select"]} onClick={isOwner ? undefined : () => props.onSelect(user, false)}>
                    { !isOwner && (
                        <input type="checkbox" className={styles["row-checkbox"]} checked={user.selected} onChange={() => props.onSelect(user, false)}/>
                    )}
                </td>
                <td className={styles["user-name"]} onClick={isOwner ? undefined : () => props.onSelect(user, true)}><span>{ name }</span></td>
                <td onClick={isOwner ? undefined : () => props.onSelect(user, true)}>
                    <div>{ getUserLevelStr(user.level) }</div>
                    { hasAuthLvDetail(props.teamType, user.level) && (
                        <div>{ getAuthLevelDetailInfo(props.teamType, findAuthUIDetailsByValue(props.teamType, user.level, user.level_detail)) }</div>
                    )}
                </td>
            </tr>
        );
    }

    const getUserLevelStr = (level:number) => {

        return `Lv.${level}: ${getAuthLevelName(props.teamType, level) ?? ""}`;
    }

    const owner = props.users.find(u => u.user_id === props.ownerId);
    const others = props.users.filter(u => u.user_id !== props.ownerId);

    return (
        <table className={classnames("table", styles["user-table"])}>
            { owner != null && (
                <thead>{renderRow(owner, true)}</thead>
            )}
            <tbody>
                { others.map(u => renderRow(u, false))}
            </tbody>
        </table>
    )

})