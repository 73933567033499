import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { Checkbox } from '../../components/form/form-checkbox';
import { ModalInputBlock } from '../../components/parts/modal-input-block';
import PrevNextDatePicker from '../../components/parts/prev-next-date-picker';
import { LMT } from '../../config/constant';
import { DataListInput } from '../../components/parts/data-list-input';
import { FormDatePicker } from '../../components/form/form-date-picker';

export interface CertKarteOutput {
    hasCover: boolean;
    hasDetail: boolean;
    publishDay: Date;
    month: Date;
    receiver: string;
    clinicName: string;
    address: string;
    tel: string;
}

interface MyProps {
    output: CertKarteOutput;
    clinicNameList: string[];
    addressList: string[];
    receiverList: string[];
    onClose: () => void;
    onSubmit: (output: CertKarteOutput) => void;
}

export const CertKarteOutputSelectPopup = (props: MyProps) => {
    const [ data, setData ] = useState<CertKarteOutput>({ hasCover:true, hasDetail:true, publishDay:new Date(), month:new Date(), receiver:"", clinicName:"", address:"", tel:"" });

    useEffect(() => {
        setData(props.output);

    }, [ props.output ])

    const ID = "cert-disease-output-select-popup";

    return (
        <div>
            <Modal isOpen={true} id={ID}>
                <ModalHeader toggle={props.onClose}>出力内容選択</ModalHeader>
                <ModalBody>
                    <ModalInputBlock label="作成物">
                        <Checkbox label="事故内訳" checked={data.hasCover} id="chkHasCover"
                            onChange={e => setData({ ...data, hasCover:e.target.checked })}
                        />
                        <Checkbox label="カルテ" checked={data.hasDetail} id="chkHasDetail"
                            onChange={e => setData({ ...data, hasDetail:e.target.checked })}
                        />
                    </ModalInputBlock>
                    <ModalInputBlock label="発行日">
                        <PrevNextDatePicker
                            name="datePublish"
                            value={data.publishDay}
                            onChange={e => setData({ ...data, publishDay:e.target.value })}
                            portalContainerID={ID}
                            popperPlacement="auto"
                        />
                    </ModalInputBlock>
                    <ModalInputBlock label="月度">
                        <div className="row-no-margin align-items-center">
                            <FormDatePicker
                                disabled={!data.hasCover}
                                monthOnly={true}
                                name="dateMonth"
                                value={data.month}
                                onChange={e => setData({ ...data, month:e.target.value })}
                                portalContainerID={ID}
                                placement="auto"
                            />
                            <span>月分</span>
                        </div>
                    </ModalInputBlock>
                    <ModalInputBlock label="提出先">
                        <DataListInput
                            disabled={!data.hasCover}
                            dataList={props.receiverList}
                            datalistId="receiver-list"
                            value={data.receiver}
                            onChange={receiver => setData({ ...data, receiver })}
                            maxLength={LMT.CERT.RECEIVER_LEN}
                        />
                    </ModalInputBlock>
                    <ModalInputBlock label="住所">
                        <DataListInput
                            disabled={!data.hasCover}
                            dataList={props.addressList}
                            datalistId="address-list"
                            value={data.address}
                            onChange={address => setData({ ...data, address })}
                            maxLength={LMT.RANCH.ADDRESS_LEN}
                        />
                    </ModalInputBlock>
                    <ModalInputBlock label="診療所名">
                        <DataListInput
                            disabled={!data.hasCover}
                            dataList={props.clinicNameList}
                            datalistId="clinic-name-list"
                            value={data.clinicName}
                            onChange={clinicName => setData({ ...data, clinicName })}
                            maxLength={LMT.RANCH.NAME_LEN}
                        />
                    </ModalInputBlock>
                    <ModalInputBlock label="TEL">
                        <input className="form-control" type="text"
                            disabled={!data.hasCover}
                            value={data.tel}
                            onChange={e => setData({ ...data, tel:e.target.value })}
                            maxLength={LMT.RANCH.TEL_LEN}
                        />
                    </ModalInputBlock>
                </ModalBody>
                <ModalFooter className="modal-footer-fix">
                    <ExecutionButton type="decide" disabled={!data.hasCover && !data.hasDetail} onClick={() => props.onSubmit(data)} />
                </ModalFooter>
            </Modal>
        </div>
    )

}