import React, {useEffect,useState} from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Term } from '../schedule/Term';
import moment from 'moment';
import { IDeliveryHis, IBreeding, IDelivery, IRut, CrossHistory } from './cow-event';
import styles from './cow.module.css';
import { renderBreedingInfo, renderCrossInfo, renderTreat, renderComment, renderRutInfo, renderDeliveryInfo, renderDisease } from './cow-event-renderer';
import { BreedingApi } from '../../api';
import { Communicator } from '../../api/communicator';
import { getSymptomStatus } from '../../config/constant';

interface IBreedingSeriesPopupProps {
    cow_id: number;
    term: Term;
    onClose: ()=> void;
    deliveryHistory: IDeliveryHis[];
    crossHistory: CrossHistory[];
    handleSetIsLoading: (isLoading: boolean) => void;
    canRefTreatAmount: boolean;
    canRefCrossName: boolean;
    comm: Communicator;
}

export const BreedingSeriesPopup: React.FC<IBreedingSeriesPopupProps> = (props) => {
    const [ breedings, setBreedings ] = useState<Array<IBreeding|IDelivery|IRut>>([]);

    useEffect(() => {
        api_getSeries();

    },[props.cow_id, props.term]);

    const api_getSeries = async () => {
        const req = {
            cow_id: props.cow_id,
            from: props.term.isFromMin() ? undefined : moment(props.term.from).format("YYYY-MM-DD HH:mm") + ":00",
            to: props.term.isToMax() ? undefined : moment(props.term.to).format("YYYY-MM-DD HH:mm") + ":00"
        };

        props.handleSetIsLoading(true);
        const res = await props.comm.send((await BreedingApi()).getHistoryListUsingPOST(req));
        props.handleSetIsLoading(false);
        if (res.result !== "OK") {
            setBreedings([]);
            return;
        }
        const list = (res.data?.list ?? []) as Array<IBreeding|IDelivery|IRut>;
        setBreedings(list);
    }

    return (
        <div className="modal-wide">
            <Modal isOpen={true} toggle={props.onClose}>
                <ModalHeader toggle={props.onClose}>繁殖経過確認</ModalHeader>
                <ModalBody style={{ overflowY: "auto", maxHeight: "calc(100vh - 150px)" }}>
                {
                    breedings.map((bBase, i) => (
                        <div key={i} className={styles["breeding-series-item"]}>
                            <div className="row" style={{ marginLeft: "0px", marginRight: "0px" }}>
                                <span style={{ lineHeight: "28px", fontWeight: "bold", fontSize: "0.9rem" }}>{moment(bBase.watched_at).format("M月D日 HH:mm")}</span>
                            </div>
                            { ("breeding_id" in bBase) && (
                                <>
                                    <div className="row m-t-5" style={{ marginLeft: "0px", marginRight: "0px", display: "flex" }}>
                                        <div>
                                            <span>［検診］&nbsp;</span>
                                        </div>
                                        <div style={{ flex: "1" }}>
                                            {renderBreedingInfo(bBase)}
                                        </div>
                                    </div>
                                    { renderDisease(bBase) }
                                    { bBase.status != null && (
                                        <div className="row m-t-5" style={{ marginLeft: "0px", marginRight: "0px", display: "flex" }}>
                                            <div>
                                                <span>［転帰］&nbsp;</span>
                                            </div>
                                            <div style={{ flex: "1" }}>
                                                {getSymptomStatus(bBase.status)?.name}
                                            </div>                                                
                                        </div>                                                
                                    )}
                                    { bBase.cross != null && (
                                        <div className="row m-t-5" style={{ marginLeft: "0px", marginRight: "0px", display: "flex" }}>
                                            <div>
                                                <span>［交配］&nbsp;</span>
                                            </div>
                                            <div style={{ flex: "1" }}>
                                                {renderCrossInfo(bBase.cross, bBase.watched_at, props.deliveryHistory, props.crossHistory, props.canRefCrossName)}
                                            </div>
                                        </div>
                                    )}
                                    { bBase.details.map((dtl,i) => (
                                        <div key={i}>{renderTreat(dtl, props.canRefTreatAmount)}</div>
                                    ))}
                                    { renderComment(bBase.comment, "m-t-5") }
                                </>
                            )}
                            { ("rut_id" in bBase) && (
                                <>
                                    <div className="row m-t-5" style={{ marginLeft: "0px", marginRight: "0px", display: "flex" }}>
                                        <div>
                                            <span>［発情］&nbsp;</span>
                                        </div>
                                        <div style={{ flex: "1" }}>
                                            {renderRutInfo(bBase)}
                                        </div>
                                    </div>
                                    { renderComment(bBase.comment, "m-t-5") }
                                </>
                            )}
                            { ("delivery_id" in bBase) && (
                                <>
                                    <div className="row m-t-5" style={{ marginLeft: "0px", marginRight: "0px", display: "flex" }}>
                                        <div>
                                            <span>［分娩］&nbsp;</span>
                                        </div>
                                        <div style={{ flex: "1" }}>
                                            {renderDeliveryInfo(bBase)}
                                        </div>
                                    </div>
                                    { renderComment(bBase.comment, "m-t-5") }
                                </>
                            )
                        }
                        </div>
                    ))
                }
                </ModalBody>
            </Modal>
        </div>
    );
}