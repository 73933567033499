import { BreedingCertEtReqLicenseTypeEnum,BreedingCertAiReqLicenseTypeEnum } from "../api";

const USER_LICENSES = ["AI","ET","DOCTOR"] as const;
export type UserLicenseKey = typeof USER_LICENSES[number];

type UserLicense = {
    /**
     * 便宜的な番号
     */
    no: number,
    name: string,
    canAi?: boolean,
    canEt?: boolean,
    canCheckPreg?: boolean,
    /**
     * ワクチン証明書・診療カルテの出力可否
     */
    canCertificate?: boolean,
    certEtReq: BreedingCertEtReqLicenseTypeEnum
    certAiReq: BreedingCertAiReqLicenseTypeEnum
}

export const USER_LICENSE: { [key in UserLicenseKey]: UserLicense } = {
    AI: { no:1, name:"人工授精師", canAi: true, certEtReq: BreedingCertEtReqLicenseTypeEnum.AI, certAiReq:BreedingCertAiReqLicenseTypeEnum.AI },
    ET: { no:2, name:"受精卵移植師", canAi: true, canEt: true, certEtReq: BreedingCertEtReqLicenseTypeEnum.AI, certAiReq:BreedingCertAiReqLicenseTypeEnum.AI },
    DOCTOR: { no:3, name:"獣医師", canAi: true, canEt: true, canCheckPreg: true, canCertificate:true, certEtReq: BreedingCertEtReqLicenseTypeEnum.DR, certAiReq:BreedingCertAiReqLicenseTypeEnum.DR }
};

export const All_USER_LICENSES = USER_LICENSES.map(k => USER_LICENSE[k]);

export const getLicenseKey = (no:number) => {
    return USER_LICENSES.find(k => USER_LICENSE[k].no === no);
}