import React, { useState, useEffect, ReactNode } from 'react';
import { EventData, isIvfEgg, isIvf, isOpu, isSov, IHisSov, IHisOpu, IHisIvf, IHisIvfEgg, isSellCow, isCowSellMilk, isOtherProfitCow, isOtherLossCow, isGrowth, IHisCowSellMilk, IHisSellCow, IHisOtherProfitCow, IHisOtherLossCow } from './cow-info';
import styles from './cow-event-his-list.module.css';
import cowStyles from './cow.module.css';
import { buildFeedingHisStr, renderSymptomStr, getTreatHisStr, renderRutHisInfo, renderBreedingHisInfo, renderDeliveryHisInfo, renderCrossHisInfo, renderSellCowHisInfo, renderCowSellMilkHisInfo, renderGrowthHisStr, renderSovHis, renderOpuHis, renderIvfHis, renderIvfEggHis, selectTreatTitle } from './cow-event-renderer';
import { EVENT_KIND } from '../../config/event-kind';
import moment from 'moment';
import { EVENT_TYPE_PARAM } from '../../config/constant';
import { IUser, IRanchFeed, IFecesState, IFecesColor, IFeedType, ITreatKind } from '../../stores';
import { hasRanchAuth } from '../../config/auth-checker';
import { IconLink } from '../../components/parts/icon-link';
import { FreezedArray } from '../../config/util';
import { buildLocationName, IRanchHouseForGetName } from '../ranch/cow-list-table';
import { IDeliveryHis, CrossHistory } from './cow-event';
import classnames from 'classnames';

export type DailyEventData = { day:string, events:EventData[] };

export interface CowEventHisListProps {
    dailyDataList: FreezedArray<DailyEventData>;
    selectedEventKinds: Set<number>;
    onEventItemClick: (date: string, type: number) => void;
    ranchId: number;
    user: IUser;
    ranchFeeds: FreezedArray<Pick<IRanchFeed, "feed_no"|"name"|"unit">>;
    fecesStates: FreezedArray<IFecesState>;
    fecesColors: FreezedArray<IFecesColor>;
    feedTypes: FreezedArray<Pick<IFeedType, "feed_type_no"|"name">>;
    treatKinds: FreezedArray<ITreatKind>;
    ranchHouses: FreezedArray<IRanchHouseForGetName>;
    onWriteReexamine: (firstId:number) => void;
    showSellMilkDetail: (event:IHisCowSellMilk) => void;
    showSellCowDetail: (event:IHisSellCow) => void;
    showOtherPlDetail: (event: IHisOtherProfitCow | IHisOtherLossCow) => void;
    deliveryHistory: FreezedArray<IDeliveryHis>;
    crossHistory: FreezedArray<CrossHistory>;
}

type EggEventData = IHisSov | IHisOpu | IHisIvf | IHisIvfEgg;

const isEggEvent = (event:EventData): event is EggEventData => {
    return isSov(event) || isOpu(event) || isIvf(event) || isIvfEgg(event);
}

export const CowEventHisList = React.memo((props: CowEventHisListProps) => {
    const [ filteredList, setFilteredList ] = useState<DailyEventData[]>([]);

    useEffect(() => {
        const filtered = props.dailyDataList.map(d => {
            return {
                ...d,
                events: d.events.filter(e => props.selectedEventKinds.has(e.event_kind_no))
            };
        }).filter(d => d.events.length > 0);

        setFilteredList(filtered);

    }, [ props.dailyDataList, props.selectedEventKinds ]);

    const canRefFeedAmount = hasRanchAuth("FOODING_REF_AMOUNT", props.ranchId, props.user);
    const canTreat = hasRanchAuth("TREAT_EDIT", props.ranchId, props.user);
    const canRefTreatAmount = hasRanchAuth("TREAT_REF_AMOUNT", props.ranchId, props.user);
    const canRefCowBalance = hasRanchAuth("BALANCE_COW", props.ranchId, props.user);
    const canRefCrossName = hasRanchAuth("CROSS_REF_NAME", props.ranchId, props.user);

    const isSameYear = (day1:string, day2:string) => day1.substr(0,4) === day2.substr(0,4);

    return (
        <>
        { filteredList.map((daily, iDay) => (<>
            { (iDay === 0 || !isSameYear(filteredList[iDay-1].day, daily.day)) && (
                <YearSeparator year={moment(daily.day).year()} />
            )}
            <div key={iDay} className={styles["day-container"]} data-testid="daily-event">
                { iDay !== 0 && (<hr className={cowStyles["hr-border"]} />)}
                <label className="col-md-2 col-xs-3 p-l-5 p-r-5">{moment(daily.day).format("M月D日")}</label>
                <div className="col-md-10 col-xs-9 p-l-5 p-r-5">
                    {
                        daily.events
                            //えさ記録は同記録内の品目が分かれて入ってくるので、同じ記録のものかどうかをIDで判断
                            .map((event, iEvt) => ({ ...event, prevId: iEvt === 0 ? undefined : daily.events[iEvt-1].event_id }))
                            .map((event, iEvt) => (
                            <div key={iEvt} data-testid="event-item">
                                {
                                    (iEvt !== 0 && event.prevId !== event.event_id)
                                    && (
                                    <hr className={cowStyles["hr-border"]}/>
                                    )
                                }
                                {/* えさ */}
                                { ("feed_no" in event && event.event_kind_no === EVENT_KIND.FEEDING.no) && (
                                    <EventItem header="えさ" onClick={() => props.onEventItemClick(event.day, EVENT_TYPE_PARAM.FEED)}
                                                regTime={event.prevId !== event.event_id ? event.reg_time : undefined}>
                                        <span>{buildFeedingHisStr(event, props.ranchFeeds, props.feedTypes, canRefFeedAmount)}</span>
                                    </EventItem>
                                )}
                                {/* 体調・治療 */}
                                { ("symptom_id" in event && event.event_kind_no === EVENT_KIND.SYMPTOM.no) && (
                                    <EventItem header="体調" onClick={() => props.onEventItemClick(event.day, EVENT_TYPE_PARAM.SYMPTOM)} regTime={event.reg_time}>
                                        {renderSymptomStr(event, props.fecesStates, props.fecesColors)}
                                    </EventItem>
                                )}
                                { ("treat" in event && event.event_kind_no === EVENT_KIND.SYMPTOM.no) && event.treat.map((treat, i_t) => (
                                    <EventItem key={i_t} header={selectTreatTitle(treat.treat_kind_no, "symptom", props.treatKinds)}
                                                onClick={() => props.onEventItemClick(event.day, EVENT_TYPE_PARAM.SYMPTOM)}>
                                        <span>{ getTreatHisStr(treat.treat_item_name, treat.amount, treat.treat_item_unit, canRefTreatAmount) }</span>
                                    </EventItem>
                                ))}
                                {("treat" in event && event.event_kind_no === EVENT_KIND.SYMPTOM.no)
                                && (event.treat.length > 0 || event.first_id != null) && (
                                    <div className={styles["link-right"]}>
                                        { canTreat && (
                                            <IconLink iconType="reply" text="再診/継続" testId="link-reexamine"
                                                onClick={() => props.onWriteReexamine(event.first_id != null ? event.first_id : event.symptom_id)} />
                                        )}
                                    </div>
                                )}
                                {/* 予防 */}
                                { ("prevention_id" in event && event.event_kind_no === EVENT_KIND.PREVENTION.no) && event.prevention_detail.map((p_detail, i_pd) => (
                                    <EventItem key={i_pd} header={selectTreatTitle(p_detail.treat_kind_no, "prevention", props.treatKinds)}
                                                onClick={() => props.onEventItemClick(event.day, EVENT_TYPE_PARAM.SYMPTOM)}
                                                regTime={i_pd === 0 ? event.reg_time : undefined}>
                                        <span>{ getTreatHisStr(p_detail.treat_item_name, p_detail.amount, p_detail.treat_item_unit, canRefTreatAmount)}</span>
                                    </EventItem>
                                ))}
                                {/* 場所 */}
                                { ("location_id" in event && event.event_kind_no === EVENT_KIND.LOCATION.no) && (
                                    <EventItem header="移動">
                                        <span>{buildLocationName(props.ranchHouses, event.site, event.barn, event.room)}</span>
                                    </EventItem>
                                )}
                                {/* 繁殖 */}
                                { ("rut_id" in event || "delivery_id" in event || "breeding_id" in event) && (<>
                                    <EventItem regTime={event.reg_time} onClick={() => props.onEventItemClick(event.day, EVENT_TYPE_PARAM.BREEDING)}
                                                header={("rut_id" in event) ? "発情" : ("breeding_id" in event) ? "検診" : ("delivery_id" in event) ? "分娩" : ""}>
                                        { ("rut_id" in event) && (
                                            <span>{renderRutHisInfo(event)}</span>
                                        )}
                                        { ("breeding_id" in event) && (
                                            <span>{renderBreedingHisInfo(event)}</span>
                                        )}
                                        { ("delivery_id" in event) && (
                                            <span>{renderDeliveryHisInfo(event)}</span>
                                        )}
                                    </EventItem>
                                    { ("cross" in event) && event.cross != null && (
                                        <EventItem header="交配" onClick={() => props.onEventItemClick(event.day, EVENT_TYPE_PARAM.BREEDING)}>
                                            <span>{renderCrossHisInfo(event.cross, canRefCrossName, { watched_at: event.reg_time, deliveryHistories: props.deliveryHistory, crossHistories: props.crossHistory })}</span>
                                        </EventItem>
                                    )}
                                    { ("rut_id" in event || "breeding_id" in event) && event.details.map((treat, i_t) => (
                                        <EventItem key={i_t} header={selectTreatTitle(treat.treat_kind_no, "breeding", props.treatKinds)}
                                                    onClick={() => props.onEventItemClick(event.day, EVENT_TYPE_PARAM.BREEDING)}>
                                            <span>{ getTreatHisStr(treat.treat_item_name, treat.amount, treat.treat_item_unit, canRefTreatAmount) }</span>
                                        </EventItem>
                                    ))}
                                </>)}
                                {/* 乳代・個体販売 */}
                                { (isSellCow(event) || isCowSellMilk(event)) && (
                                    <EventItem header="販売" regTime={event.reg_time}>
                                        { isSellCow(event) && (<>
                                            <span>{renderSellCowHisInfo(event)} </span>
                                            { canRefCowBalance && (
                                                <IconLink iconType="popup" className="m-l-5" text="詳細" testId="link-sellCow" onClick={() => props.showSellCowDetail(event)} />
                                            )}
                                        </>)}
                                        { isCowSellMilk(event) && (<>
                                            <span>{renderCowSellMilkHisInfo(event)} </span>
                                            { canRefCowBalance && (
                                                <IconLink iconType="popup" className="m-l-5" text="詳細" testId="link-sellMilk" onClick={() => props.showSellMilkDetail(event)} />
                                            )}
                                        </>)}
                                    </EventItem>
                                )}
                                {/* 雑収入・雑損失 */}
                                { (isOtherProfitCow(event) || isOtherLossCow(event)) && (
                                    <EventItem header={isOtherProfitCow(event) ? "販売" : "購入"} regTime={event.reg_time}>
                                        <span>{isOtherProfitCow(event) ? "雑収入" : "雑損失"} </span>
                                        { canRefCowBalance && (
                                            <IconLink iconType="popup" className="m-l-5" text="詳細" onClick={() => props.showOtherPlDetail(event)} />
                                        )}
                                    </EventItem>
                                )}
                                {/* 測定 */}
                                { isGrowth(event) && (
                                    <EventItem header="測定" regTime={event.reg_time} onClick={() => props.onEventItemClick(event.day, EVENT_TYPE_PARAM.SYMPTOM)}>
                                        <span>{renderGrowthHisStr(event)}</span>
                                    </EventItem>
                                )}
                                {/* 採卵 */}
                                { isEggEvent(event) && (
                                    <EventItem regTime={event.reg_time} onClick={() => props.onEventItemClick(event.day, EVENT_TYPE_PARAM.BREEDING)}
                                                header={isSov(event) ? "体内受精卵" : isOpu(event) ? "OPU" : isIvf(event) ? "媒精" : isIvfEgg(event) ? "培養結果" : ""}>
                                        { isSov(event) && (
                                            <span>{renderSovHis(event)}</span>
                                        )}
                                        { isOpu(event) && (
                                            <span>{renderOpuHis(event)}</span>
                                        )}
                                        { isIvf(event) && (
                                            <span>{renderIvfHis(event)}</span>
                                        )}
                                        { isIvfEgg(event) && (
                                            <span>{renderIvfEggHis(event)}</span>
                                        )}
                                    </EventItem>
                                )}
                            </div>
                        ))
                    }
                </div>
            </div>
        </>))}
        </>
    )

});


export const YearSeparator = React.memo((props:{ year:number }) => (
    <div className={styles["event-his-year"]}>
        <hr />
        <div>{props.year}</div>
    </div>
));

const EventItem = React.memo((props: {
    header: string,
    onClick?: () => void,
    regTime?: string,
    children: ReactNode
}) => {

    return (
        <div className={styles["event-item"]} onClick={props.onClick}>
            { props.regTime == null ? (
                <div className={classnames(styles["event-time"], "invisible")}>00:00</div>
            ) : (
                <div className={styles["event-time"]}>{moment(props.regTime).format("HH:mm")}</div>
            )}
            <div>［{props.header}］</div>
            <div className={styles["event-body"]} children={props.children} />
        </div>
    )
})