import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { A, BREEDING_STRUCTURE_KINDS, BREEDING_STRUCTURE, BREEDING_STRUCTURE_SIDE, BREEDING_STATE, LMT, TIMEPRESETS, EVENT_TYPE_PARAM, SYMPTOM_STATUS, convertBenefitType, getDefaultBenefitType } from '../../config/constant';
import { PageSettings } from '../../config/page-settings.js';
import { CommonUtil, ar, FreezedArray, generateKey } from "../../config/util";
import { withContext, ISeedType, IUser, IDisease, ITreatKind, IMedicineCategory } from '../../stores';
import { CowToDispInfo } from '../../components/parts/cows-popup';
import { SliderInput } from '../../components/parts/slider-input';
import { OptionalInputContainer } from '../../components/parts/optional-input-container';
import moment from 'moment';
import StructureCounter, { StructureCounterHeader } from './structure-counter';
import { BreedingDto, CowBreedingModifyReq, CowApi, DeliveryApi, BreedingApi, BreedingCrossCurrentDto, BreedingModifyReq, BreedingCrossDto, TeamTreatPresetDto, CowDetailDto, DiseaseCauseDto, RanchSchedulePresetDto } from '../../api';
import { Collapse } from 'reactstrap';
import PrevNextDatePicker from '../../components/parts/prev-next-date-picker';
import { CrossPopup, EditingCrossOriginal } from './cross-popup';
import { TreatSelector, ITreatSelectorData, validateTreat, TREAT_VALIDATION_RESULT, convPresetToTreatSelectorData, convScheduledPresetToTreatSelectorData, convertTreatsToModifyReq } from '../../components/parts/treat-selector';
import OvarianViewer, { initStructures } from '../../components/OvarianStructure/OvarianViewer';
import { GetPedigreeStr } from '../setting/seed-management';
import { IEventWriteLocationState, IEventWriteParamCow, historyLocation } from '../../config/history-location-builder';
import { ScheduleFinder } from '../schedule/schedule-finder';
import { AppState } from '../../app';
import { NextSchedule, NextScheduleSelector, toNextScheduleReq } from '../../components/next-schedule/next-schedule-selector';
import { BreedingState, hasOpenDay } from './breeding-state';
import { confirmBreedingStateChange, BREEDING_STATE_CONFIRMED, DEFAULT_OPEN_DAYS_AFTER_DELIVERY } from '../../components/parts/breeding-state-selector';
import { IDeliveryHis, CrossHistory } from '../cow/cow-event';
import styles from './breeding.module.css';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { EVENT_KIND } from '../../config/event-kind';
import { V3DateTime } from '../../components/parts/v3-date-time-picker';
import { hasTeamAuth } from '../../config/auth-checker';
import { IconLink } from '../../components/parts/icon-link';
import { TreatPresetSelectPopup } from '../../components/parts/treat-preset-select-popup';
import { DiseaseInputPopup } from '../symptom/disease-input-popup';
import { DiseaseInputItem } from '../symptom/disease-name-input';
import { SymptomStatus } from '../symptom/symptom-status';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { DIALOG_BUTTONS } from '../../components/form/form-dialog';
import { UserTeams } from '../../config/user-teams';
import { VisitFeeSelectPopup, ModifyReqVisitWithName } from '../symptom/visit-fee-select-popup';
import { PreloadedProgramSelectPopupProps, showPreloadedProgramSelectDialog, PreloadedProgramSelectPopup } from '../program/preloaded-program-select-popup';
import { resetTreatItems, resetMedicines, usePresetsWithBothTeamMedicines, useVisitFeesForRanch, useDiseaseRelationsForInput, useBothTeamSeeds, resetSeeds } from '../../stores/fetcher';
import { AutoCollapse } from '../../components/parts/collapse-container';
import classnames from 'classnames';
import { Communicator } from '../../api/communicator';
import { FetchWaiter, FetchError } from '../../components/content/fetch-state';
import { ModifyReqTreat_fix } from '../../api/api-fix';
import { SingleCowHeader } from 'components/cow-header/single-cow-header';
import { MultiCowHeader } from 'components/cow-header/multi-cow-header';
import { getCowWithCache, resetCow } from 'stores/fetcher_cow';

type SliderInputKind = "bcs"|"cornu_l"|"cornu_r"|"ovary_l"|"ovary_r"|"canal";

interface MyState {
    cow_top: boolean;
    cow?: CowDetailDto;
    cows: IEventWriteParamCow[];
    baseOpenDay: Date;
    ranchId: number;
    clinicId: number | undefined;
    executing: boolean;
    preloadedProgram?: PreloadedProgramSelectPopupProps;
    original?: BreedingDto;
}

interface ICurrentCross {
    cross_no: number;
    cross_type: number;
    watched_at: Date;
}

export type IEditingCross = Omit<BreedingCrossDto, "cross_no"> & {
    cross_no: number | undefined;
}

type IData = Omit<IBreeding, "ranch_id" | "cow_id" | "watched_by" | "watched_at" | "structures" | "next_breeding" | "delivery" | "dry_up" | "cross" | "status"> & {
    watched_at: Date;
    structsL: Readonly<IBreedingStructure[]>;
    structsR: Readonly<IBreedingStructure[]>;
    delivery?: Date;
    dry_up?: Date;
    crosses: FreezedArray<IEditingCross>;
    cross_plan: Readonly<{ isSelected: boolean, schedule: NextSchedule }>;
    breeding_plans: FreezedArray<{ isSelected: boolean, schedule: NextSchedule }>;
    state: Readonly<IBreedingState>;
    symptom_status: Readonly<{ isSelected: boolean, value: number }>;
}

interface IBreeding {
    ranch_id : number;
    cow_id : number;
    watched_at : string;
    watched_by : string;
    pregnant_score? : number
    pregnant_note : string;
    bcs? : number;
    bcs_note : string;
    vulva_blood_score? : number;
    vulva_expand_score? : number;
    vulva_note : string;
    vagina_tear_score? : number;
    vagina_mucus_score? : number;
    vagina_rut_score? : number;
    vagina_note : string;
    canal_tear_score? : number;
    canal_cm? : number;
    canal_note : string;
    cornu_l_cm? : number;
    cornu_l_bright_score? : number;
    cornu_l_image_score? : number;
    cornu_l_shrink_score? : number;
    cornu_r_cm? : number;
    cornu_r_bright_score? : number;
    cornu_r_image_score? : number;
    cornu_r_shrink_score? : number;
    cornu_note : string;
    ovary_l_mm? : number;
    ovary_r_mm? : number;
    ovary_note : string;
    comment : string;
    delivery? : string;
    dry_up? : string;
    details: FreezedArray<ITreatSelectorData & { key:string }>;
    structures: Readonly<IBreedingStructure[]>;
    cross?: Readonly<BreedingCrossDto>;
    diseases: DiseaseInputItem[];
    status?: number;
    visit?: ModifyReqVisitWithName;
}

interface IBreedingStructure {
    item_kind : number;
    side : number;
    item_count : number;
}

interface IBreedingState {
    isSelected: boolean;
    value: number; 
    openday?: Date;
}

export interface BreedingWriteSeedParam {
    cow_id: number;
    cross_type : number;
    seed_lot_id? : number;
    label_no_1? : string;
    label_no_2? : string;
    label_no_3? : string;
}
export interface IBreedingWriteLocationState extends IEventWriteLocationState {
    seed?: BreedingWriteSeedParam;
}
interface MyProps extends BaseProps<{id?:string},{}, IBreedingWriteLocationState|undefined> {
}

export const crossPlanTitleFormatter = (prefix: string | undefined, candidate: string | undefined) => {
    return candidate ? `${candidate}予定` : "";
}

const isSameDay = (date1: Date, date2:Date) => {
    if (date1.getTime() === date2.getTime()) return true;
    const day1 = moment(date1).startOf("day");
    const day2 = moment(date2).startOf("day");
    return day1.isSame(day2);
}

const GetPedigreeStrByCross = (cross: Readonly<IEditingCross>):string => {
    let ancestor = cross.ancestor_2 ?? ""; 
    ancestor += (cross.ancestor_3 ?? "") !== "" ? `×${cross.ancestor_3}` : "";
    if (cross.mother_name !== "") {
        if (ancestor !== "") {
            return `${cross.father_name}×${cross.mother_name}(${ancestor})`;
        }
        return `${cross.father_name}×${cross.mother_name}`;
    } else {
        return `${cross.father_name}×${ancestor}`;
    }
};


class BreedingWrite extends Base<MyProps, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            ranchId:0,
            clinicId: undefined,
            baseOpenDay: new Date(),
            cow_top: false,
            cows:[],
            executing: false,
        }

    }

    private getParamCowIds(props: this["props"]) {
        const parm = new URLSearchParams(props.location.search).get("param");
        if (parm == null) return [];

        return parm.split(",")
                    .map(p => parseInt(p))
                    .filter(p => !isNaN(p));
    }
    private getParamCowTop(props: this["props"]) {
        const parm = new URLSearchParams(props.location.search).get("cow_top");
        if (parm == null) return false;

        return parm === "1";
    }

    componentDidMount() {
        if (this.handleNotAllowAccess(undefined, ["BREEDING_EDIT"], [], ["BREEDING"])) {
            return;
        }

        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        this.init();
    }

    componentDidUpdate(prevProps: this["props"], prevState: MyState) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.init();
            return;
        }
        if (this.props.location.search !== prevProps.location.search) {
            this.init();
            return;
        }
    }


    private init = async () => {
        const _mayId = parseInt(this.props.match.params.id ?? "");
        const originalId = isNaN(_mayId) ? undefined : _mayId;
        let cowIds = this.getParamCowIds(this.props);
        const cowTop = this.getParamCowTop(this.props);
        const paramCows = (this.props.location.state?.cows ?? []);

        //validate
        if (originalId != null && cowIds.length >= 2) {
            console.error("invalid location param", originalId, cowIds);
            return;
        }
        if (originalId == null && cowIds.length === 0) {
            console.error("invalid location param: no cow");
            return;
        }
        if (cowIds.length >= 2) {
            if (paramCows.length === 0) {
                console.error("invalid location param: no cows state", cowIds);
                return;
            }
            const containedIds = cowIds.filter(id => paramCows.some(c => c.cow_id === id));
            if (containedIds.length !== cowIds.length) {
                console.error("invalid location param: some cows state not found", cowIds, paramCows);
                cowIds = containedIds;
            }
        }

        this.context.handleSetHeader( { title:"検診記録を" + (originalId == null ? "入力" : "編集") });

        const now = new Date();

        const ranchId = this.props.rootStore.cur_ranch_id;
        const clinicId = this.props.rootStore.getClinicIdForMaster();

        await this.setStateAsync({ ranchId, clinicId });

        try {
            this.context.handleSetIsLoading(true);

            let original: BreedingDto | undefined;
            if (originalId != null) {
                original = await this.loadBreeding(originalId);
                if (original == null) return;
                cowIds = [ original.cow_id ];
            }
            let cow : CowDetailDto | undefined;
            if (cowIds.length === 1) {
                const cowRes = await this.comm().send(() => getCowWithCache(ranchId, cowIds[0]), { retries:true });
                if (cowRes.data == null) return;
                cow = cowRes.data;
            }
    
            const openDay = await this.getBaseOpenDay(cowIds, now);

            this.setState({
                cow_top: cowTop,
                baseOpenDay: openDay,
                cow,
                cows: cow != null ? [{ ...cow, use_no:cow.use_no, breed_no:cow.breed_no }] : paramCows.filter(cw => cowIds.includes(cw.cow_id)),
                original,
            })

        } finally {
            this.context.handleSetIsLoading(false);
        }


    }

    private loadBreeding = async (id: number) => {
        const url = "/breeding/" + id;
        const res = await this.comm().send<BreedingDto>(() => this.context.postAsync(url, {}), { retries: true });
        if (res.result !== "OK") return undefined;
        if (res.data == null) {
            this.context.showToast(A.MESSAGE.NO_DATA);
            return undefined;
        }
        const data = res.data;

        if (this.navToRanchTopIfSelfEditOnly("BREEDING", data.watched_by)) return undefined;

        return data;
    }

    private getBaseOpenDay = async (cowIds: number[], now: Date): Promise<Date> => {
        if (cowIds.length !== 1) {
            return moment(now).add(DEFAULT_OPEN_DAYS_AFTER_DELIVERY, "days").toDate();
        }

        //分娩日からopen予定初期値を決定
        const deliveryHistory = await this.loadDeliveryHistory(this.state.ranchId, cowIds[0]) ?? [];
        if (deliveryHistory.length > 0) {
            const lastDelivery = deliveryHistory[deliveryHistory.length - 1];

            const openDayMom = moment(lastDelivery.delivered_at).add(DEFAULT_OPEN_DAYS_AFTER_DELIVERY, "days");
            if (openDayMom.isAfter(now)) {
                return openDayMom.toDate();
            }
        }
        return moment(now).add(DEFAULT_OPEN_DAYS_AFTER_DELIVERY, "days").toDate();
    }

    private loadDeliveryHistory = async (ranch_id: number, cow_id:number): Promise<IDeliveryHis[]|undefined> => {

        const req = { cow_id, ranch_id };
        const res = await this.comm().send((await DeliveryApi()).getHistoryListUsingPOST1(req), { showsErrorMessage: false });

        if (res.result !== "OK") return undefined;

        return (res.data?.list ?? []).map(h => ({
            delivered_at: moment(h.delivered_at).toDate(),
            delivery_id: h.delivery_id!
        }));
    }

    private loadCurrentCross = async (cowId: number, lastDay: Date): Promise<ICurrentCross | undefined> => {
        const req = {
            cow_id: cowId,
            last_day: moment(lastDay).format("YYYY-MM-DD"),
            ranch_id: this.state.ranchId
        };

        const url = "/breeding/cross/current";
        this.context.handleSetIsLoading(true);
        const res = await this.comm().send<BreedingCrossCurrentDto>(() => this.context.postAsync(url, req));
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") return undefined;

        const crosses = res.data?.crosses ?? [];
        if (crosses.length === 0) return undefined;

        const cls = crosses[crosses.length - 1];
        return {
            watched_at: moment(cls.watched_at).toDate(),
            cross_no: cls.cross_no,
            cross_type: cls.cross_type
        };
    }


    private onSave = async (data: Omit<IData, "details">, details: ModifyReqTreat_fix[], setting:{ hasLR_cm:boolean, hasLR_image:boolean, hasLR_bright:boolean, hasLR_shrink:boolean }) => {
        //validate
        const cnvLab = (lot:number, lab?:string) => lab == null ? null : lot + ":" + lab;
        const allLabsMap = data.crosses
            .map(c => c.seed_lot_id == null 
                    ? []
                    : [ cnvLab(c.seed_lot_id, c.label_no_1), 
                        cnvLab(c.seed_lot_id, c.label_no_2), 
                        cnvLab(c.seed_lot_id, c.label_no_3)]);
        const allLabs = ar.flat(allLabsMap).filter((l): l is string => l != null);

        if (allLabs.length !== ar.distinct(allLabs).length) {
            this.context.showToast(A.MESSAGE.BREEDING_LABEL_DUPLICATE);
            return;
        }

        const cowIds = this.state.cows.map(c => c.cow_id);

        let registered = false;
        try {
            this.setState({ executing: true });

            const validated = await this.validateBreedingState(data);
            if (validated == null) {
                this.context.showToast("繁殖状態の確認に失敗しました");
                return;
            }
            if (validated === BREEDING_STATE_CONFIRMED.CANCEL ) return;

            const plans = [ data.cross_plan, ...data.breeding_plans ].filter(p => p.isSelected)
                                                                        .map(p => toNextScheduleReq(p.schedule, this.state.clinicId));

            const req: BreedingModifyReq = {
                ...data,
                cow_ids    : cowIds,
                is_new     : this.state.original == null ? 1 : 0,
                breeding_id: this.state.original?.breeding_id,
                ranch_id   : this.state.ranchId,
                watched_at : this.convToSendDate(data.watched_at, true),
                cornu_r_cm           : setting.hasLR_cm ? data.cornu_r_cm : data.cornu_l_cm,
                cornu_r_bright_score : setting.hasLR_bright ? data.cornu_r_bright_score : data.cornu_l_bright_score,
                cornu_r_image_score  : setting.hasLR_image ? data.cornu_r_image_score : data.cornu_l_image_score,
                cornu_r_shrink_score : setting.hasLR_shrink ? data.cornu_r_shrink_score : data.cornu_l_shrink_score,
                delivery : data.delivery == null ? undefined : this.convToSendDate(data.delivery, false),
                dry_up   : data.dry_up == null ? undefined : ({
                    cow_ids: this.state.cows.filter(c => A.IS_FOR_MILK(c.use_no ?? 0)).map(c => c.cow_id),
                    day: this.convToSendDate(data.dry_up, false),
                }),
                details,
                structures: [...data.structsL, ...data.structsR].filter(s => s.item_count > 0),
                crosses:    [...data.crosses],
                schedule_id: this.props.location.state?.schedule_id,
                plans:       plans,
                status: data.symptom_status.isSelected ? data.symptom_status.value : undefined,
                preloaded_program: { list:[], tags:[] }
            };

            //関連する予定を探す
            if (req.schedule_id == null && req.cow_ids.length === 1 && req.is_new === 1) {
                const day = moment(data.watched_at).format("YYYY-MM-DD");
                const eventKinds = [ EVENT_KIND.BREEDING.no ];
                if (req.crosses.length > 0) {
                    eventKinds.push(EVENT_KIND.CROSS.no);
                }

                const finder = new ScheduleFinder(this.context, req.ranch_id, this.props.rootStore.user.id);
                const scheRes = await finder.findEventRelatedSchedule(day, eventKinds, req.cow_ids[0]);

                if (scheRes.result === "cancel") return;

                if (scheRes.result === "yes") {
                    req.schedule_id = scheRes.id;
                }
            }

            if (req.is_new === 1) {
                //プログラムの確認
                const pgReq = await showPreloadedProgramSelectDialog(
                    this.context,
                    async () => this.comm().send((await BreedingApi()).getProgramsForBreedingUsingPOST({ eventReq:req, clinic_id:this.state.clinicId })),
                    this.props.rootStore,
                    req.ranch_id,
                    this.state.clinicId,
                    st => this.setState({ preloadedProgram:st }),
                    this.state.cows
                );
                if (pgReq == null) return false;
                req.preloaded_program = pgReq;
            }

            this.context.handleSetIsLoading(true);

            const res = await this.comm().send((await BreedingApi()).modifyUsingPOST(req), { excludedErrCodes: [A.ERR_CODE.ERR_USED_LABEL_NO] });
            this.context.handleSetIsLoading(false);
            if (res.result !== "OK") return;

            if (res.code === A.ERR_CODE.ERR_USED_LABEL_NO) {
                console.error(res.data);
                const cross_type_name = data.crosses[0].cross_type === A.CROSS_TYPE.get("AI")?.no ? "精液" : "受精卵"
                this.context.showToast(A.MESSAGE.BREEDING_USED_SEED_EGG(cross_type_name))
                return;
            }

            const treatTeamId = this.state.clinicId ?? this.state.ranchId;
            //薬マスタを再取得しておく
            resetMedicines(treatTeamId, false);
            //処置作成があれば再取得
            if (details.some(t => t.new_treat_item_name != null)) {
                resetTreatItems(treatTeamId, false);
            }

            //交配を記録したあとはseedマスタをリロードしておく
            resetSeeds(this.state.ranchId, false);
            if (this.state.clinicId != null) {
                resetSeeds(this.state.clinicId, false);
            }

            let failedToUpdateBreedingState = false;
            // 繁殖状態更新
            if (validated === BREEDING_STATE_CONFIRMED.OK) {
                const resbs = await this.onSubmitBreedingState(data);
                if (!resbs) {
                    failedToUpdateBreedingState = true;
                }
            } else {
                // アクティブ牛リストを再取得
                await this.props.rootStore.fetchActiveCows(undefined, "DIFF");
            }

            if (failedToUpdateBreedingState) {
                await this.context.showDialog("WARNING", A.MESSAGE.BREEDING_STATE_COMMIT_FAILED, DIALOG_BUTTONS.OK);
            }
            if (req.is_new === 0) {
                if (!data.state.isSelected) {
                    await this.context.showDialog("WARNING", A.MESSAGE.BREEDING_STATE_NO_COMMIT, DIALOG_BUTTONS.OK);
                }
            }
            registered = true;

        } finally {
            if (!registered) {
                this.setState({ executing: false });
            }
        }

        if (this.state.original == null) {
            if (this.state.cow_top) {
                this.props.history.replace(historyLocation.toCowInfo(cowIds[0]));
            } else {
                window.history.go(-1);
            }
        } else {
            this.props.history.replace(historyLocation.toCowEvent(cowIds[0], this.convToSendDate(data.watched_at, false), EVENT_TYPE_PARAM.BREEDING));
        }
    }

    private validateBreedingState = async (data: Pick<IData, "state"|"pregnant_score">) => {

        let breeding_state = data.state.value;
        if (!data.state.isSelected) {
            if ( this.state.original != null ) return BREEDING_STATE_CONFIRMED.NO;
            if ( data.pregnant_score !== 1 ) return BREEDING_STATE_CONFIRMED.NO;
            breeding_state = BREEDING_STATE.PREG.no // `妊娠鑑定＋`の場合、`PREG`(自動更新）で繁殖状態を確認
        }

        this.context.handleSetIsLoading(true);
        try {
            for (const { cow_id } of this.state.cows) {

                const cowRes = await this.comm().send(() => getCowWithCache(this.state.ranchId, cow_id), { showsErrorMessage: false });
                if (cowRes.result !== "OK" || cowRes.data == null) return;
                const cow = cowRes.data;

                const deliveryHistory = await this.loadDeliveryHistory(this.state.ranchId, cow_id);
                if (!deliveryHistory) return;
                const crossHistory = await this.loadCrossHistory(this.state.ranchId, cow_id);
                if (!crossHistory) return;
                const confirmed = await confirmBreedingStateChange(
                    cow.breeding_state,
                    breeding_state,
                    deliveryHistory,
                    crossHistory,
                    this.context.showQuestionAsync,
                    data.state.isSelected ? ["変更", "キャンセル"] : ["はい","いいえ","キャンセル"]
                );
                if (confirmed === -1) continue;

                if (data.state.isSelected) { 
                    if (confirmed === 1) return BREEDING_STATE_CONFIRMED.CANCEL;
                    if (confirmed !== 0) return BREEDING_STATE_CONFIRMED.CANCEL;
                } else {
                    if (confirmed === 1) return BREEDING_STATE_CONFIRMED.NO;
                    if (confirmed === 2) return BREEDING_STATE_CONFIRMED.CANCEL;
                    if (confirmed !== 0) return BREEDING_STATE_CONFIRMED.CANCEL;
                }
                break;
            }
            return BREEDING_STATE_CONFIRMED.OK;

        } finally {
            this.context.handleSetIsLoading(false);
        }
    }

    private async onSubmitBreedingState(data: Pick<IData, "state"|"pregnant_score">): Promise<boolean> {
        let breeding_state = data.state.value;

        if (!data.state.isSelected) {
            if( data.pregnant_score !== 1 ) return true;
            breeding_state = BREEDING_STATE.PREG.no; // `妊娠鑑定＋`の場合、`PREG`に自動更新
        }

        const req: CowBreedingModifyReq = {
            cow_ids: this.state.cows.map(c => c.cow_id),
            state: breeding_state,
            activity: 0,
            open_day: hasOpenDay(breeding_state) ? moment(data.state.openday).format("YYYY-MM-DD"): undefined,
            ranch_id: this.state.ranchId
        };
        this.context.handleSetIsLoading(true);
        const res = await this.comm().send((await CowApi()).modifyBreedingUsingPOST(req), { showsErrorMessage: false });
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") return false;

        //アクティブ牛リスト更新しておく
        this.props.rootStore.fetchActiveCows(undefined, "DIFF");
        req.cow_ids.forEach(id => resetCow(id, false));
        return true;
    }



    private loadCrossHistory = async (ranch_id: number, cow_id:number): Promise<CrossHistory[]|undefined> => {

        const req = { cow_id, ranch_id };
        const res = await this.comm().send((await BreedingApi()).getBreedingCrossHistoryUsingPOST(req), { showsErrorMessage: false});
        if (res.result !== "OK") return undefined;

        return (res.data ?? []).map(c => ({
            ...c,
            watched_at: moment(c.watched_at).toDate(),
        }));
    }    


    private onDelete = async () => {
        if (!CommonUtil.assertNotNull(this.state.original, "orginal", "onDelete")) return;

        const confirmed = await this.context.showDialog("QUESTION", '記録を削除してよろしいですか？', DIALOG_BUTTONS.DELETE_CANCEL);
        if (confirmed !== 0) return;

        const params = {
            breeding_id: this.state.original.breeding_id,
            ranch_id: this.state.ranchId
        };

        this.setState({ executing:true });
        this.context.handleSetIsLoading(true);
        const res = await this.comm().send(() => this.context.postAsync('/breeding/delete', params));
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") {
            this.setState({ executing:false });
            return;
        }

        //交配記録が削除された可能性があるのでseedマスタをリセットしておく
        resetSeeds(this.state.ranchId, false);
        if (this.state.clinicId != null) {
            resetSeeds(this.state.clinicId, false);
        }

        // アクティブ牛リストを再取得
        this.props.rootStore.fetchActiveCows(undefined, "DIFF");

        this.props.history.replace(
            historyLocation.toCowEvent(
                this.state.cows[0].cow_id, 
                this.state.original ? moment(this.state.original.watched_at).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"), 
                EVENT_TYPE_PARAM.BREEDING
            )
        )
    }


    private convToSendDate = (date:Date, hasTime:boolean) => {
        if (hasTime) {
            return moment(date).format("YYYY-MM-DD HH:mm") + ":00";
        }
        return moment(date).format("YYYY-MM-DD");
    }

    render() {
        if (this.state.cows.length === 0) return <FetchWaiter />

        return (
            <div className="page-root">
                <BreedingWriteContent
                    user={this.props.rootStore.user}
                    ranchId={this.state.ranchId}
                    clinicId={this.state.clinicId}
                    original={this.state.original}
                    cow={this.state.cow}
                    cows={this.state.cows}
                    baseOpenDay={this.state.baseOpenDay}
                    schedulePreset={this.props.location.state?.preset}
                    paramSeed={this.props.location.state?.seed}
                    showAlert={this.context.showAlert}
                    comm={this.comm()}
                    showToast={this.context.showToast}
                    executing={this.state.executing}
                    diseases={this.props.rootStore.options.disease}
                    causes={this.props.rootStore.options.causes}
                    treatKinds={this.props.rootStore.options.treat_kind}
                    medicineCategories={this.props.rootStore.options.medicine_category}
                    onDelete={this.onDelete}
                    onSave={this.onSave}
                    loadCurrentCross={this.loadCurrentCross}
                />

                { this.state.preloadedProgram && (
                    <PreloadedProgramSelectPopup
                        {...this.state.preloadedProgram}
                    />
                )}
            </div>
        )
    }
}

interface ContentProps {
    user: IUser;
    ranchId: number;
    clinicId: number | undefined;
    original: BreedingDto | undefined;
    cow: CowDetailDto | undefined;
    cows: FreezedArray<IEventWriteParamCow>;
    baseOpenDay: Date;

    schedulePreset: RanchSchedulePresetDto | undefined;
    paramSeed: BreedingWriteSeedParam | undefined;

    showAlert: (err:string) => void;
    comm: Communicator;
    showToast: (msg:string) => void;
    executing: boolean;

    diseases: FreezedArray<IDisease>;
    causes: FreezedArray<DiseaseCauseDto>;
    treatKinds: FreezedArray<ITreatKind>;
    medicineCategories: FreezedArray<IMedicineCategory>;

    onDelete: () => void;
    onSave: (data: Omit<IData, "details">, details: ModifyReqTreat_fix[], setting:{ hasLR_cm:boolean, hasLR_image:boolean, hasLR_bright:boolean, hasLR_shrink:boolean }) => void;

    loadCurrentCross: (cowId: number, lastDay:Date) => Promise<ICurrentCross | undefined>;
}

const BreedingWriteContent = React.memo((props: ContentProps) => {
    const treatTeamId = props.clinicId ?? props.ranchId;

    const mPresets = usePresetsWithBothTeamMedicines(props.ranchId, props.clinicId);
    const mVisiting = useVisitFeesForRanch(props.ranchId, props.clinicId);
    const mDiseases = useDiseaseRelationsForInput(treatTeamId);
    const mSeeds = useBothTeamSeeds(props.ranchId, props.clinicId);

    const [ hasLR_cm, setHasLR_cm ] = useState(false);
    const [ hasLR_bright, setHasLR_bright ] = useState(false);
    const [ hasLR_image, setHasLR_image ] = useState(false);
    const [ hasLR_shrink, setHasLR_shrink ] = useState(false);
    const [ isNextScheduleOpen, setIsNextScheduleOpen ] = useState(false);

    const [ data, setData ] = useState<IData>({
        watched_at : new Date(),
        pregnant_note : "",
        bcs_note : "",
        vulva_note : "",
        vagina_note : "",
        canal_note : "",
        cornu_note : "",
        ovary_note : "",
        comment : "",
        details: [],
        structsL: [],
        structsR: [],
        crosses: [],
        cross_plan: { isSelected: false, schedule: { title: "", start: new Date(), end: new Date(), note: "", allDay: true, eventKindNo: EVENT_KIND.CROSS.no, preset:undefined } },
        breeding_plans: [],
        state: { isSelected: false, value: 0 },
        symptom_status: { isSelected:false, value: SYMPTOM_STATUS.CURED.no },
        diseases:[]
    });
    const [ invalid_inputs, setInvalid_inputs ] = useState<Set<SliderInputKind>>(new Set());

    const [ isPresetShown, setIsPresetShown ] = useState(false);
    const [ isDiseasePopupShown, setIsDiseasePopupShown ] = useState(false);
    const [ isVisitFeeSelectShown, setIsVisitFeeSelectShown ] = useState(false);
    const [ selectedCrossCowId, setSelectedCrossCowId ] = useState<number>();

    const [ currentCross, setCurrentCross ] = useState<ICurrentCross>();
    const [ editingCrossInfo, setEditingCrossInfo ] = useState<{ original: EditingCrossOriginal }>();


    // initialize
    useEffect(() => {
        if (mPresets.data == null || mSeeds.data == null) return;

        const tomorrow = moment().add(1, "day").startOf("day").toDate();
        const defaultCrossPlan = {
            isSelected: false,
            schedule: {
                title: crossPlanTitleFormatter(undefined, A.CROSS_TYPE.get("AI")?.sname),
                start: tomorrow,
                end: tomorrow,
                note: "",
                allDay: true,
                eventKindNo: EVENT_KIND.CROSS.no, preset:undefined }
        };
        const defaultBreedingPlans = [{
            isSelected: false,
            schedule: {
                title: "再診",
                start: tomorrow,
                end: tomorrow,
                note: "",
                allDay: true,
                eventKindNo: EVENT_KIND.BREEDING.no,
                preset:undefined
            }
        }];
        const breedingState = props.cow == null
                    ? { isSelected: false, value: BREEDING_STATE.OPEN.no }
                    : {
                        isSelected:false,
                        value: props.cow.breeding_state,
                        openday: props.cow.open_day == null ? undefined : moment(props.cow.open_day).toDate()
                    };
        const defaultSymptomStatus = { isSelected:false, value: SYMPTOM_STATUS.CURED.no };

        if (props.original != null) {
            const recv = props.original;
            const structsL = buildDefaultStructures(BREEDING_STRUCTURE_SIDE.L);
            const structsR = buildDefaultStructures(BREEDING_STRUCTURE_SIDE.R);
            const structures = recv.structures ?? [];
            structures.forEach(s => {
                const trgList = s.side === BREEDING_STRUCTURE_SIDE.L ? structsL
                                : s.side === BREEDING_STRUCTURE_SIDE.R ? structsR
                                : null;
                if (trgList == null) {
                    console.warn("invalid struct side", s);
                    return;
                }
                const idx = trgList.findIndex(p => p.item_kind === s.item_kind);
                if (idx < 0) {
                    console.warn("invalid struct kind", s);
                    return;
                }
                trgList[idx] = s;
            });
    
            const original: IBreeding = {
                ...recv,
                details: (recv.details ?? []).map(d => ({ ...d, unit_price: d.unit_price ?? 0, team_id: d.team_id!, benefit_type:convertBenefitType(d.benefit_type), key: generateKey() })),
                diseases: recv.diseases ?? [],
                structures: structures,
                pregnant_note: recv.pregnant_note ?? "",
                comment: recv.comment ?? "",
                bcs_note: recv.bcs_note ?? "",
                vulva_note: recv.vulva_note ?? "",
                vagina_note: recv.vagina_note ?? "",
                canal_note: recv.canal_note ?? "",
                cornu_note: recv.cornu_note ?? "",
                ovary_note: recv.ovary_note ?? "",
                visit: recv.visit == null ? undefined : {
                    ...recv.visit,
                    fees: recv.visit.fees.map(f => ({ ...f, cow_ids: [ recv.cow_id ]}))
                }
            };
    
            const nextDay = moment(recv.watched_at).add(1, "day").toDate();
            const watchedAt = moment(recv.watched_at).toDate();
            
            setData({
                ...original,
                watched_at: watchedAt,
                // next_breeding: undefined,
                dry_up: recv.dry_up == null ? undefined : moment(recv.dry_up).toDate(),
                delivery: recv.delivery == null ? undefined : moment(recv.delivery).toDate(),
                structsL: structsL,
                structsR: structsR,
                crosses: original.cross == null ? [] : [ original.cross ],
                cross_plan: { isSelected:defaultCrossPlan.isSelected, schedule: { ...defaultCrossPlan.schedule, start:nextDay, end:nextDay } },
                breeding_plans: defaultBreedingPlans.map(p => ({ ...p, schedule:{ ...p.schedule, start: nextDay, end: nextDay } })),
                state: breedingState,
                symptom_status: { isSelected: original.status != null, value: original.status ?? defaultSymptomStatus.value }
            });
            setHasLR_cm(recv.cornu_l_cm !== recv.cornu_r_cm);
            setHasLR_bright(recv.cornu_l_bright_score !== recv.cornu_r_bright_score);
            setHasLR_image(recv.cornu_l_image_score !== recv.cornu_r_image_score);
            setHasLR_shrink(recv.cornu_l_shrink_score !== recv.cornu_r_shrink_score);

            refreshCurrentCross(original.pregnant_score, watchedAt);

        } else {
            const crosses:IEditingCross[] = [];
            if (props.paramSeed != null) {
                const seed = props.paramSeed;
                if (!props.cows.some(cw => seed.cow_id === cw.cow_id)) {
                    console.error("invalid seed state", seed, props.cows);
                } else {
                    crosses.push({ ...seed, cross_no:undefined });
                }
            }
            const preset = props.schedulePreset;
            const list = mPresets.data;
    
            let details: ITreatSelectorData[] = [];
            if (preset != null) {
                const converted = preset.items.map(pi => 
                    convScheduledPresetToTreatSelectorData(pi,
                        preset.team_id,
                        props.ranchId,
                        props.clinicId,
                        list.ranchMedicines,
                        list.clinicMedicines,
                        list.medicineRoutes,
                        list.treatItems,
                        hasTeamAuth("MASTER_EDIT", "MASTER_EDIT", treatTeamId, props.user)
                    )
                );
                details = ar.notNull(converted);
            }

            setData({
                watched_at: new Date(),
                pregnant_note : "",
                bcs_note : "",
                vulva_note : "",
                vagina_note : "",
                canal_note : "",
                cornu_note : "",
                ovary_note : "",
                comment : "",
                structsL: buildDefaultStructures(BREEDING_STRUCTURE_SIDE.L),
                structsR: buildDefaultStructures(BREEDING_STRUCTURE_SIDE.R),
                crosses: crosses,
                cross_plan: defaultCrossPlan,
                breeding_plans: defaultBreedingPlans,
                state: breedingState,
                symptom_status: defaultSymptomStatus,
                details: ar.notNull(details).map(d => ({ ...d, key: generateKey() })),
                diseases: []
            });
        }

    },[ mPresets.data == null, mSeeds.data == null ]);

    const buildDefaultStructures = (side:number) => {
        return BREEDING_STRUCTURE_KINDS.map(k => ({ item_kind: BREEDING_STRUCTURE[k].no, side:side, item_count:0 }));
    }

    useEffect(() => {
        if (props.cows.length >= 2) {
            setSelectedCrossCowId(props.cows[0].cow_id);
        }

    }, [ props.cows ])


    const setPregScore = (score: number | undefined) => {
        setData({ ...data, pregnant_score: score });

        refreshCurrentCross(score, data.watched_at);
    }

    const setWatchedAt = (date: Date) => {
        const pre = data.watched_at;
        setData({ ...data, watched_at: date });

        if (!isSameDay(pre, date)) {
            refreshCurrentCross(data.pregnant_score, date);
        }
    }

    const refreshCurrentCross = async (pregScore: number | undefined, watchedAt: Date) => {
        if (pregScore == null || pregScore <= 0) return;

        //※先頭の代表1頭の記録を確認
        const loaded = await props.loadCurrentCross(props.cows[0].cow_id, watchedAt);
        
        let deliDay: Date | undefined;
        let dryDay: Date | undefined;
        if (loaded != null) {
            const crossType = [...A.CROSS_TYPE.values()].find(v => v.no === loaded.cross_type);
            deliDay = crossType == null ? undefined : moment(loaded.watched_at).add(crossType.daysToDelivery, "days").toDate();
            dryDay = (deliDay === undefined || props.cows.every(c => !A.IS_FOR_MILK(c.use_no ?? 0)))
                        ? undefined
                        : moment(deliDay).add(-60, "days").toDate();
        }

        setCurrentCross(loaded);
        setData(pre => {
            return {
                ...pre,
                delivery: pre.delivery ?? deliDay,
                dry_up: pre.dry_up ?? dryDay
            }
        })
    }


    const onIsValidChange = useCallback((name: SliderInputKind, isValid: boolean) => {
        const newInvalids = new Set([...invalid_inputs]);

        if (isValid) {
            newInvalids.delete(name);
        } else {
            newInvalids.add(name);
        }
        setInvalid_inputs(newInvalids);

    }, [ invalid_inputs ] );

    const onHasLRCmChange = useCallback((hasLR: boolean) => {
        //左右別でなくなるタイミングで、右の不正値有無を無視
        const newInvalids = new Set([...invalid_inputs]);
        if (!hasLR) {
            newInvalids.delete("cornu_r");
        }
        setHasLR_cm(hasLR);
        setInvalid_inputs(newInvalids);
        setData({ ...data, cornu_r_cm: data.cornu_l_cm });

    }, [ invalid_inputs, data ]);

    const onStructureChange = useCallback((idx: number, side: "l" | "r", count: number) => {
        const items = [ ...(side === "l" ? data.structsL : data.structsR)];
        items[idx] = { ...items[idx], item_count: count };
        setData({
            ...data,
            structsL : side === "l" ? items : data.structsL,
            structsR : side === "l" ? data.structsR : items
        });
    }, [ data ]);

    const onAddTreatment = useCallback(() => {
        const teamId = props.clinicId ?? props.ranchId;
        setData({
            ...data,
            details:[
                ...data.details,
                {
                    treat_kind_no: undefined,
                    team_id: teamId,
                    unit_price: 0,
                    benefit_type:getDefaultBenefitType(props.clinicId != null),
                    key:generateKey()
                }
            ]
        });
    }, [ props.clinicId, props.ranchId, data ]);

    const onRemTreatment = useCallback((idx: number) => {
        const newDetails = [...data.details];
        newDetails.splice(idx, 1);
        setData({
            ...data,
            details: newDetails
        });
    }, [ data ]);

    const onTreatUpdated = useCallback((idx: number, values: ITreatSelectorData) => {
        const newDetails = [...data.details];
        newDetails[idx] = { ...values, key:data.details[idx].key };

        //薬品重複
        if (values.treat_kind_no == null) {
            if (values.medicine_id != null
                && newDetails.some((d,i) => i !== idx && d.medicine_id === values.medicine_id && d.team_id === values.team_id)) {
            
                props.showToast(A.MESSAGE.MEDICINE_ALREADY_ADDED);
                return;
            }
        }
        //処置重複
        else {
            if (values.treat_item_no != null
                && newDetails.some((d,i) => i !== idx && d.treat_kind_no === values.treat_kind_no && d.treat_item_no === values.treat_item_no)) {
                
                props.showToast(A.MESSAGE.TREAT_ITEM_ALREADY_ADDED);
                return;
            }
        }

        setData({ ...data, details: newDetails });

    }, [ data, props.showToast ]);

    const onAddNextSchedule = useCallback(() => {
        const date = moment(data.watched_at).add(1, "day").toDate();
        const newSchedule = { title: "再診", start: date, end: date, note: "", allDay: true, eventKindNo: EVENT_KIND.BREEDING.no, preset:undefined };
        setData({
            ...data,
            breeding_plans: [
                ...data.breeding_plans,
                { isSelected: true, schedule: newSchedule },
            ]
        });
    }, [ data ]);

    const setPreset = (preset: TeamTreatPresetDto) => {
        if (!CommonUtil.assertNotNull(mPresets.data, "mPresets")) return;

        const medicines = mPresets.data.clinicMedicines ?? mPresets.data.ranchMedicines;
        const routes = mPresets.data.medicineRoutes;
        const treatItems = mPresets.data.treatItems;

        const details = ar.notNull(preset.items.map(p =>
            convPresetToTreatSelectorData(
                p,
                treatTeamId,
                medicines,
                routes,
                treatItems
            )));

        setData({
            ...data,
            details: details.map(d => ({ ...d, key: generateKey() })),
        });
        setIsPresetShown(false);
    }

    const onCrossSubmit = useCallback((cross: IEditingCross) => {

        const newCrosses = [...data.crosses];
        const idx = newCrosses.findIndex(c=> c.cow_id === cross.cow_id);
        if (idx < 0) {
            newCrosses.push(cross);
        } else {
            newCrosses[idx] = cross;
        }

        setData({ ...data, crosses:newCrosses });
        setEditingCrossInfo(undefined);

    }, [ data ]);

    const showCross = async () => {
        const original = makeCrossPopupOriginal();
        if (original == null) return;

        setEditingCrossInfo({ original });
    }

    const makeCrossPopupOriginal = (): EditingCrossOriginal | undefined => {
        if (props.cows.length === 0) return undefined;

        const cowId = selectedCrossCowId ?? props.cows[0].cow_id;

        //入力済みの値があればそれを使用
        const pre = data.crosses.find(c => c.cow_id === cowId);
        if (pre != null) return pre;

        //他の牛で入力済みの値があれば初期値に反映
        if (data.crosses.length > 0) {
            return {
                ...data.crosses[0],
                cross_no: undefined,
                cow_id: cowId,
                label_no_1: undefined,
                label_no_2: undefined,
                label_no_3: undefined,
                rut_at: undefined
            }
        }

        return { cow_id: cowId };
    }


    const onSave = () => {
        if (!CommonUtil.assertNotNull(mPresets.data, "mPresets")) return;

        const validDetails = new Array<ITreatSelectorData>();
        for (const detail of data.details) {
            const validated = validateTreat(detail, mPresets.data.treatItems, false);
            if (validated === "BLANK") continue;
            if (validated !== "OK") {
                const msg = TREAT_VALIDATION_RESULT[validated].msg;
                props.showToast(msg);
                return;
            }
            validDetails.push(detail);
        }
        const details = convertTreatsToModifyReq(validDetails, mPresets.data.medicineRoutes);

        props.onSave(data, details, { hasLR_cm, hasLR_bright, hasLR_image, hasLR_shrink });
    }

    const NUM_SCORE_MARKS = useCallback((min:number, max:number) => {
        const rtn = {};
        if (max < min) {
            console.error("invalid args", min, max);
            return rtn;
        }
        for (let val = min; val <= max; val++) {
            rtn[val] = val.toString();
        }
        return rtn;
    }, []);

    const SYMBOL_SCORE_MARKS = useCallback((min:number, max: number) => {
        const rtn = {};
        if (max < min) {
            console.error("invalid args", min, max);
            return rtn;
        }
        for (let val = min; val <= max; val++) {
            rtn[val] = val < 0 ? "-".repeat(Math.abs(val))
                     : val > 0 ? "+".repeat(val)
                     : "±";
        }
        return rtn;
    }, []);

    const SUMMARY_CROSS = useCallback((cross: Readonly<IEditingCross>, ranchSeeds: ISeedType[], clinicSeeds: ISeedType[]) => {
        const type = [...A.CROSS_TYPE].find(t => t[1].no === cross.cross_type);
        if (type == null) return "";

        const key = type[0];
        const allSeeds = [ ...ranchSeeds, ...clinicSeeds ];

        if (key === "AI") {
            if (cross.seed_lot_id == null) return `AI：${cross.father_name}`;
            const seeds = cross.seed_lot_id == null ? []
                        : allSeeds.filter(s => s.seed_type === A.SEED_TYPE.SEED.seed_type);

            const seed = ar.flat(seeds.map(s => s.seeds)).find(s => (s.lots ?? []).some(s => s.seed_lot_id === cross.seed_lot_id));
            return seed == null ? "AI" : `AI：${seed.name}`;
        }
        if (key === "ET") {
            if (cross.seed_lot_id == null) return `ET：${GetPedigreeStrByCross(cross)}`;
            const seeds = cross.seed_lot_id == null ? []
                        : allSeeds.filter(s => s.seed_type === A.SEED_TYPE.EGG.seed_type);
            const seed = ar.flat(seeds.map(s => s.seeds)).find(s => (s.lots ?? []).some(s => s.seed_lot_id === cross.seed_lot_id));
            return seed == null ? "ET" : `ET：${GetPedigreeStr(seed)}`;
        }
        return cross.father_name == null ? "本交" : `本交：${cross.father_name}`;
    }, []);


    if (mPresets.isLoading || mVisiting.isLoading || mDiseases.isLoading || mSeeds.isLoading) return <FetchWaiter />
    if (mPresets.isError || mPresets.data == null) return <FetchError />
    if (mVisiting.isError || mVisiting.data == null) return <FetchError />
    if (mDiseases.isError || mDiseases.data == null) return <FetchError />
    if (mSeeds.isError || mSeeds.data == null) return <FetchError />
    if (props.cows.length === 0) return <></>;

    const rowClass = "form-group row treat-write-row";
    const rowHeaderClass = "col-form-label col-md-4 col-xs-4 text-lg-right";
    const rowHeaderChkClass = "checkbox checkbox-css col-form-label col-md-4 col-xs-4 text-lg-right";
    const anyVal = (...items: Array<number|string|undefined|Readonly<IBreedingStructure[]>>) => items.some(i => Array.isArray(i) ? i.some(s => s.item_count > 0) : i != null && i !== "");
    const collapseHeaderClass = (...items: Array<number|string|undefined|Readonly<IBreedingStructure[]>>) => classnames(styles["collapse-header"], { [styles.selected]: anyVal(...items) })
    
    const collapseArrowClass = (isOpen : boolean) => "fas fa-lg fa-fw " + (isOpen ? "fa-angle-up" : "fa-angle-down");

    const canEditMaster = hasTeamAuth("MASTER_EDIT", "MASTER_EDIT", treatTeamId, props.user);
    const isOfficial = new UserTeams(props.user).findOfficialRanch(props.ranchId) != null;

    const isPreg = data.pregnant_score === 1;
    
    const convertStructures = () => {
        const rtn = initStructures();
        if (data.structsL.length === 0 || data.structsR.length === 0) return rtn;

        BREEDING_STRUCTURE_KINDS.forEach((k,i) => {
            rtn.L[k] = data.structsL[i].item_count;
            rtn.R[k] = data.structsR[i].item_count;
        });
        return rtn;
    }

    const selectedVisitFeeNames = props.clinicId == null ? ""
            : (data.visit == null || data.visit.fees.length === 0) ? "選択"
            : data.visit.fees.map(f => f.name ?? "").join(",");

    return (<>
        <div className="product product-full-height">
            <div className="product-detail" style={{ height: "100%" }}>
                <div className="product-info product-info-fix p-b-0">

                    <div className="product-info-header">
                        { props.cows.length === 1 && (
                            <SingleCowHeader
                                ranchId={props.ranchId}
                                cowId={props.cows[0].cow_id}
                            />
                        )}
                        { props.cows.length > 1 && (
                            <MultiCowHeader cowCount={props.cows.length} cows={props.cows} infoName="検診記録" />
                        )}
                    </div>

                    <div className="product-body">
                        <div className={rowClass}>
                            <label className={rowHeaderClass}>検診日時</label>
                            <div style={{ paddingLeft:0, flex: 1 }}>
                                <V3DateTime value={data.watched_at}
                                    timePresets={props.user.setting?.time_preset ?? TIMEPRESETS}
                                    onChange={d => setWatchedAt(d.toDate())} />
                            </div>
                        </div>
                        { props.clinicId != null && (
                            <div className={rowClass}>
                                <label className={rowHeaderClass}>診療費</label>
                                <div style={{ paddingLeft:0, flex: 1, padding:"8px 0" }}>
                                    <IconLink iconType="popup" text={selectedVisitFeeNames}
                                        onClick={() => setIsVisitFeeSelectShown(true)} />
                                </div>
                            </div>
                        )}

                        <AutoCollapse header=" 妊娠鑑定" headerClassName={collapseHeaderClass(data.pregnant_score, data.pregnant_note)}>
                            <div className={rowClass} data-testid="妊娠鑑定">
                                <div className={rowHeaderChkClass}>
                                    <input type="checkbox" id="chkPregScore"
                                        onChange={(e) => setPregScore(e.target.checked ? -1 : undefined)}
                                        checked={data.pregnant_score != null} />
                                    <label htmlFor="chkPregScore">妊娠鑑定</label>
                                </div>
                                <OptionalInputContainer className="col-md-5 col-xs-8" isSelected={data.pregnant_score != null}>
                                    <div style={{ display: "flex", paddingBottom:"8px" }}>
                                        <div style={{ flex: "1" }}>
                                            <Slider min={-1} max={1}
                                                value={data.pregnant_score ?? -1}
                                                onChange={setPregScore}
                                                marks={SYMBOL_SCORE_MARKS(-1,1)} step={1} />
                                        </div>
                                    </div>
                                </OptionalInputContainer>
                            </div>
                            { isPreg && (<>
                                <div className={rowClass} data-testid="分娩予定">
                                    <div className={rowHeaderChkClass}>
                                        <input type="checkbox" id="chkDeliPlan"
                                            onChange={(e) => setData({ ...data, delivery: e.target.checked ? new Date() : undefined})}
                                            checked={data.delivery != null} />
                                        <label htmlFor="chkDeliPlan">分娩予定</label>
                                    </div>
                                    <OptionalInputContainer className="col-md-5 col-xs-8" isSelected={data.delivery != null}>
                                        <div style={{ display: "flex" }}>
                                            <div style={{ flex: "1" }}>
                                                <PrevNextDatePicker name="deliPlan" value={data.delivery ?? new Date()}
                                                    onChange={e => setData({ ...data, delivery: e.target.value })}
                                                    dateSpanPrefix="交配" baseDate={currentCross?.watched_at} />
                                            </div>
                                        </div>
                                    </OptionalInputContainer>
                                </div>
                                { props.cows.some(c => A.IS_FOR_MILK(c.use_no ?? 0)) && (
                                    <div className={rowClass} data-testid="乾乳期間">
                                        <div className={rowHeaderChkClass}>
                                            <input type="checkbox" id="chkDryPlan"
                                                onChange={(e) => setData({ ...data, dry_up: e.target.checked ? new Date() : undefined })}
                                                checked={data.dry_up != null} />
                                            <label htmlFor="chkDryPlan">乾乳開始</label>
                                        </div>
                                        <OptionalInputContainer className="col-md-5 col-xs-8" isSelected={data.dry_up != null}>
                                            <div style={{ display: "flex" }}>
                                                <div style={{ flex: "1" }}>
                                                    <PrevNextDatePicker name="dryPlan" value={data.dry_up ?? new Date()}
                                                        onChange={e => setData({ ...data, dry_up: e.target.value })}
                                                        dateSpanPrefix="分娩" baseDate={data.delivery} />
                                                </div>
                                            </div>
                                        </OptionalInputContainer>
                                    </div>
                                )}
                            </>)}
                            <div className={rowClass}>
                                <div className="col-form-label col-md-2 col-xs-2 text-lg-right">メモ</div>
                                <div className="col-md-10 col-xs-10">
                                    <textarea rows={2} className="form-control" data-testid="妊娠鑑定メモ"
                                        value={data.pregnant_note} maxLength={LMT.BREEDING.ITEM_MEMO_LEN}
                                        onChange={e => setData({ ...data, pregnant_note:e.target.value })} />
                                </div>                                    
                            </div>
                        </AutoCollapse>
                        <AutoCollapse header=" BCS" headerClassName={collapseHeaderClass(data.bcs, data.bcs_note)}>
                            <div className={rowClass} data-testid="BCS">
                                <div className={rowHeaderChkClass}>
                                    <input type="checkbox" id="chkBcs"
                                        onChange={(e) => setData({ ...data, bcs: e.target.checked ? 3 : undefined })}
                                        checked={data.bcs != null} />
                                    <label htmlFor="chkBcs">BCS</label>
                                </div>
                                <OptionalInputContainer className="col-md-5 col-xs-8" isSelected={data.bcs != null}>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ flex: "1" }}>
                                            <SliderInput min={1} max={5} step={0.25} value={data.bcs ?? 3}
                                                            onChange={e => setData({...data, bcs: e})}
                                                            onIsValidChange={v => onIsValidChange("bcs", v)} />
                                        </div>
                                    </div>
                                </OptionalInputContainer>
                            </div>
                            <div className={rowClass}>
                                <div className="col-form-label col-md-2 col-xs-2 text-lg-right">メモ</div>
                                <div className="col-md-10 col-xs-10">
                                    <textarea rows={2} className="form-control" data-testid="BCSメモ"
                                        value={data.bcs_note} maxLength={LMT.BREEDING.ITEM_MEMO_LEN}
                                        onChange={e => setData({ ...data, bcs_note:e.target.value })}></textarea>
                                </div>
                            </div>
                        </AutoCollapse>
                        <AutoCollapse header=" 外陰部" headerClassName={collapseHeaderClass(data.vulva_blood_score, data.vulva_expand_score, data.vulva_note)}>
                            <div className={rowClass} data-testid="外陰部充血">
                                <div className={rowHeaderChkClass}>
                                    <input type="checkbox" id="chkVulva1"
                                        onChange={(e) => setData({...data, vulva_blood_score: e.target.checked ? -1 : undefined})}
                                        checked={data.vulva_blood_score != null} />
                                    <label htmlFor="chkVulva1">充血</label>
                                </div>
                                <OptionalInputContainer className="col-md-5 col-xs-8" isSelected={data.vulva_blood_score != null}>
                                    <div style={{ display: "flex", paddingBottom:"8px" }}>
                                        <div style={{ flex: "1" }}>
                                            <Slider min={-1} max={2}
                                                value={data.vulva_blood_score ?? -1}
                                                onChange={(e) => setData({...data, vulva_blood_score: e})}
                                                marks={SYMBOL_SCORE_MARKS(-1,2)} step={1} />
                                        </div>
                                    </div>
                                </OptionalInputContainer>
                            </div>
                            <div className={rowClass} data-testid="外陰部腫脹">
                                <div className={rowHeaderChkClass}>
                                    <input type="checkbox" id="chkVulva2"
                                        onChange={(e) => setData({...data, vulva_expand_score: e.target.checked ? -1 : undefined})}
                                        checked={data.vulva_expand_score != null} />
                                    <label htmlFor="chkVulva2">腫脹</label>
                                </div>
                                <OptionalInputContainer className="col-md-5 col-xs-8" isSelected={data.vulva_expand_score != null}>
                                    <div style={{ display: "flex", paddingBottom:"8px" }}>
                                        <div style={{ flex: "1" }}>
                                            <Slider min={-1} max={2}
                                                value={data.vulva_expand_score ?? -1}
                                                onChange={(e) => setData({...data, vulva_expand_score: e})}
                                                marks={SYMBOL_SCORE_MARKS(-1,2)} step={1} />
                                        </div>
                                    </div>
                                </OptionalInputContainer>
                            </div>
                            <div className={rowClass}>
                                <div className="col-form-label col-md-2 col-xs-2 text-lg-right">メモ</div>
                                <div className="col-md-10 col-xs-10">
                                    <textarea rows={2} className="form-control" data-testid="外陰部メモ"
                                        value={data.vulva_note} maxLength={LMT.BREEDING.ITEM_MEMO_LEN}
                                        onChange={e => setData({ ...data, vulva_note:e.target.value })}></textarea>
                                </div>
                            </div>
                        </AutoCollapse>

                        <AutoCollapse header=" 膣" headerClassName={collapseHeaderClass(data.vagina_mucus_score, data.vagina_rut_score, data.vagina_tear_score, data.vagina_note)}>
                            <div className={rowClass} data-testid="膣裂傷">
                                <div className={rowHeaderChkClass}>
                                    <input type="checkbox" id="chkVagina1"
                                        onChange={(e) => setData({...data, vagina_tear_score: e.target.checked ? -1 : undefined})}
                                        checked={data.vagina_tear_score != null} />
                                    <label htmlFor="chkVagina1">裂傷</label>
                                </div>
                                <OptionalInputContainer className="col-md-5 col-xs-8" isSelected={data.vagina_tear_score != null}>
                                    <div style={{ display: "flex", paddingBottom:"8px" }}>
                                        <div style={{ flex: "1" }}>
                                            <Slider min={-1} max={3}
                                                value={data.vagina_tear_score ?? -1}
                                                onChange={(e) => setData({...data, vagina_tear_score: e})}
                                                marks={SYMBOL_SCORE_MARKS(-1,3)} step={1} />
                                        </div>
                                    </div>
                                </OptionalInputContainer>
                            </div>
                            <div className={rowClass} data-testid="膣粘液">
                                <div className={rowHeaderChkClass}>
                                    <input type="checkbox" id="chkVagina2"
                                        onChange={(e) => setData({...data, vagina_mucus_score: e.target.checked ? 0 : undefined})}
                                        checked={data.vagina_mucus_score != null} />
                                    <label htmlFor="chkVagina2">膣粘液</label>
                                </div>
                                <OptionalInputContainer className="col-md-5 col-xs-8" isSelected={data.vagina_mucus_score != null}>
                                    <div style={{ display: "flex", paddingBottom:"8px" }}>
                                        <div style={{ flex: "1" }}>
                                            <Slider min={0} max={3}
                                                value={data.vagina_mucus_score ?? 0}
                                                onChange={(e) => setData({...data, vagina_mucus_score: e})}
                                                marks={NUM_SCORE_MARKS(0,3)} step={1} />
                                        </div>
                                    </div>
                                </OptionalInputContainer>
                            </div>
                            <div className={rowClass} data-testid="発情粘液">
                                <div className={rowHeaderChkClass}>
                                    <input type="checkbox" id="chkVagina3"
                                        onChange={(e) => setData({...data, vagina_rut_score: e.target.checked ? -1 : undefined})}
                                        checked={data.vagina_rut_score != null} />
                                    <label htmlFor="chkVagina3">発情粘液</label>
                                </div>
                                <OptionalInputContainer className="col-md-5 col-xs-8" isSelected={data.vagina_rut_score != null}>
                                    <div style={{ display: "flex", paddingBottom:"8px" }}>
                                        <div style={{ flex: "1" }}>
                                            <Slider min={-1} max={2}
                                                value={data.vagina_rut_score ?? -1}
                                                onChange={(e) => setData({...data, vagina_rut_score: e})}
                                                marks={SYMBOL_SCORE_MARKS(-1,2)} step={1} />
                                        </div>
                                    </div>
                                </OptionalInputContainer>
                            </div>
                            <div className={rowClass}>
                                <div className="col-form-label col-md-2 col-xs-2 text-lg-right">メモ</div>
                                <div className="col-md-10 col-xs-10">
                                    <textarea rows={2} className="form-control" data-testid="膣メモ"
                                        value={data.vagina_note} maxLength={LMT.BREEDING.ITEM_MEMO_LEN}
                                        onChange={e => setData({ ...data, vagina_note:e.target.value })}></textarea>
                                </div>                                    
                            </div>
                        </AutoCollapse>

                        <AutoCollapse header=" 子宮頸管" headerClassName={collapseHeaderClass(data.canal_cm, data.canal_tear_score, data.canal_note)}>
                            <div className={rowClass} data-testid="子宮頸管裂傷">
                                <div className={rowHeaderChkClass}>
                                    <input type="checkbox" id="chkCanal1"
                                        onChange={(e) => setData({...data, canal_tear_score: e.target.checked ? -1 : undefined})}
                                        checked={data.canal_tear_score != null} />
                                    <label htmlFor="chkCanal1">裂傷</label>
                                </div>
                                <OptionalInputContainer className="col-md-5 col-xs-8" isSelected={data.canal_tear_score != null}>
                                    <div style={{ display: "flex", paddingBottom:"8px" }}>
                                        <div style={{ flex: "1" }}>
                                            <Slider min={-1} max={3}
                                                value={data.canal_tear_score ?? -1}
                                                onChange={(e) => setData({...data, canal_tear_score: e})}
                                                marks={SYMBOL_SCORE_MARKS(-1,3)} step={1} />
                                        </div>
                                    </div>
                                </OptionalInputContainer>
                            </div>
                            <div className={rowClass} data-testid="子宮頸管径">
                                <div className={rowHeaderChkClass}>
                                    <input type="checkbox" id="chkCanal2"
                                        onChange={(e) => setData({...data, canal_cm: e.target.checked ? 1 : undefined})}
                                        checked={data.canal_cm != null} />
                                    <label htmlFor="chkCanal2">径(cm)</label>
                                </div>
                                <OptionalInputContainer className="col-md-5 col-xs-8" isSelected={data.canal_cm != null}>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ flex: "1" }}>
                                            <SliderInput min={1} max={10} step={0.25} value={data.canal_cm ?? 1}
                                                            onChange={e => setData({...data, canal_cm: e})}
                                                            onIsValidChange={v => onIsValidChange("canal", v)} />
                                        </div>
                                    </div>
                                </OptionalInputContainer>
                            </div>
                            <div className={rowClass}>
                                <div className="col-form-label col-md-2 col-xs-2 text-lg-right">メモ</div>
                                <div className="col-md-10 col-xs-10">
                                    <textarea rows={2} className="form-control" data-testid="子宮頸管メモ"
                                        value={data.canal_note} maxLength={LMT.BREEDING.ITEM_MEMO_LEN}
                                        onChange={e => setData({ ...data, canal_note:e.target.value })}></textarea>
                                </div>                                    
                            </div>
                        </AutoCollapse>

                        <AutoCollapse header=" 子宮角"
                            headerClassName={collapseHeaderClass(
                                data.cornu_l_cm, data.cornu_l_bright_score, data.cornu_l_image_score, data.cornu_l_shrink_score,
                                data.cornu_r_cm, data.cornu_r_bright_score, data.cornu_r_image_score, data.cornu_r_shrink_score,
                                data.cornu_note
                            )}>
                            <div className={rowClass} style={{marginBottom:0}}>
                                <div className={rowHeaderClass}>
                                    <label>径(cm)</label>
                                </div>
                                <div className="checkbox checkbox-css">
                                    <input type="checkbox" id="chkLRCm" checked={hasLR_cm}
                                            onChange={e => onHasLRCmChange(e.target.checked)} />
                                    <label htmlFor="chkLRCm">左右別</label>
                                </div>
                            </div>
                            <div className={rowClass} data-testid="子宮角径左" style={{marginBottom: hasLR_cm ? "2px" : "16px"}}>
                                <div className={rowHeaderChkClass}>
                                    <input type="checkbox" id="chkCornu1L"
                                        onChange={(e) => setData({...data, cornu_l_cm: e.target.checked ? 1 : undefined})}
                                        checked={data.cornu_l_cm != null} />
                                    <label htmlFor="chkCornu1L">{hasLR_cm ? "左" : "左右"}</label>
                                </div>
                                <OptionalInputContainer className="col-md-5 col-xs-8" isSelected={data.cornu_l_cm != null}>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ flex: "1" }}>
                                            <SliderInput min={1} max={10}
                                                value={data.cornu_l_cm ?? 1}
                                                onChange={(e) => setData({...data, cornu_l_cm: e})}
                                                onIsValidChange={v => onIsValidChange("cornu_l", v)}
                                                step={0.25} />
                                        </div>
                                    </div>
                                </OptionalInputContainer>
                            </div>
                            { hasLR_cm &&  (
                            <div className={rowClass} data-testid="子宮角径右">
                                <div className={rowHeaderChkClass}>
                                    <input type="checkbox" id="chkCornu1R"
                                        onChange={(e) => setData({...data, cornu_r_cm: e.target.checked ? 1 : undefined})}
                                        checked={data.cornu_r_cm != null} />
                                    <label htmlFor="chkCornu1R">右</label>
                                </div>
                                <OptionalInputContainer className="col-md-5 col-xs-8" isSelected={data.cornu_r_cm != null}>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ flex: "1" }}>
                                            <SliderInput min={1} max={10}
                                                value={data.cornu_r_cm ?? 1}
                                                onChange={(e) => setData({...data, cornu_r_cm: e})}
                                                onIsValidChange={v => onIsValidChange("cornu_r", v)}
                                                step={0.25} />
                                        </div>
                                    </div>
                                </OptionalInputContainer>
                            </div>
                            )}

                            <div className={rowClass} style={{marginBottom:0}}>
                                <div className={rowHeaderClass}>
                                    <label>ｴｺｰ輝度</label>
                                </div>
                                <div className="checkbox checkbox-css">
                                    <input type="checkbox" id="chkLRBright" checked={hasLR_bright}
                                            onChange={e => {
                                                setHasLR_bright(e.target.checked);
                                                setData({ ...data, cornu_r_bright_score: data.cornu_l_bright_score });
                                            }} />
                                    <label htmlFor="chkLRBright">左右別</label>
                                </div>
                            </div>
                            <div className={rowClass} data-testid="子宮角ｴｺｰ輝度左" style={{marginBottom:hasLR_bright ? "2px" : "16px"}}>
                                <div className={rowHeaderChkClass}>
                                    <input type="checkbox" id="chkCornu2L"
                                        onChange={(e) => setData({...data, cornu_l_bright_score: e.target.checked ? 0 : undefined})}
                                        checked={data.cornu_l_bright_score != null} />
                                    <label htmlFor="chkCornu2L">{hasLR_bright ? "左" : "左右"}</label>
                                </div>
                                <OptionalInputContainer className="col-md-5 col-xs-8" isSelected={data.cornu_l_bright_score != null}>
                                    <div style={{ display: "flex", paddingBottom:"8px" }}>
                                        <div style={{ flex: "1" }}>
                                            <Slider min={0} max={3}
                                                value={data.cornu_l_bright_score ?? 0}
                                                onChange={(e) => setData({...data, cornu_l_bright_score: e})}
                                                marks={NUM_SCORE_MARKS(0,3)} step={1} />
                                        </div>
                                    </div>
                                </OptionalInputContainer>
                            </div>
                            { hasLR_bright &&  (
                            <div className={rowClass} data-testid="子宮角ｴｺｰ輝度右">
                                <div className={rowHeaderChkClass}>
                                    <input type="checkbox" id="chkCornu2R"
                                        onChange={(e) => setData({...data, cornu_r_bright_score: e.target.checked ? 0 : undefined})}
                                        checked={data.cornu_r_bright_score != null} />
                                    <label htmlFor="chkCornu2R">右</label>
                                </div>
                                <OptionalInputContainer className="col-md-5 col-xs-8" isSelected={data.cornu_r_bright_score != null}>
                                    <div style={{ display: "flex", paddingBottom:"8px" }}>
                                        <div style={{ flex: "1" }}>
                                            <Slider min={0} max={3}
                                                value={data.cornu_r_bright_score ?? 0}
                                                onChange={(e) => setData({...data, cornu_r_bright_score: e})}
                                                marks={NUM_SCORE_MARKS(0,3)} step={1} />
                                        </div>
                                    </div>
                                </OptionalInputContainer>
                            </div>
                            )}

                            <div className={rowClass} style={{marginBottom:0}}>
                                <div className={rowHeaderClass}>
                                    <label>ｴｺｰﾌﾘｰ像</label>
                                </div>
                                <div className="checkbox checkbox-css">
                                    <input type="checkbox" id="chkLRImage" checked={hasLR_image}
                                            onChange={e => {
                                                setHasLR_image(e.target.checked);
                                                setData({ ...data, cornu_r_image_score: data.cornu_l_image_score });
                                            }} />
                                    <label htmlFor="chkLRImage">左右別</label>
                                </div>
                            </div>
                            <div className={rowClass} data-testid="子宮角ｴｺｰﾌﾘｰ像左" style={{marginBottom:hasLR_image ? "2px" : "16px"}}>
                                <div className={rowHeaderChkClass}>
                                    <input type="checkbox" id="chkCornu3L"
                                        onChange={(e) => setData({...data, cornu_l_image_score: e.target.checked ? 0 : undefined})}
                                        checked={data.cornu_l_image_score != null} />
                                    <label htmlFor="chkCornu3L">{hasLR_image ? "左" : "左右"}</label>
                                </div>
                                <OptionalInputContainer className="col-md-5 col-xs-8" isSelected={data.cornu_l_image_score != null}>
                                    <div style={{ display: "flex", paddingBottom:"8px" }}>
                                        <div style={{ flex: "1" }}>
                                            <Slider min={0} max={3}
                                                value={data.cornu_l_image_score ?? 0}
                                                onChange={(e) => setData({...data, cornu_l_image_score: e})}
                                                marks={NUM_SCORE_MARKS(0,3)} step={1} />
                                        </div>
                                    </div>
                                </OptionalInputContainer>
                            </div>
                            { hasLR_image &&  (
                            <div className={rowClass} data-testid="子宮角ｴｺｰﾌﾘｰ像右">
                                <div className={rowHeaderChkClass}>
                                    <input type="checkbox" id="chkCornu3R"
                                        onChange={(e) => setData({...data, cornu_r_image_score: e.target.checked ? 0 : undefined})}
                                        checked={data.cornu_r_image_score != null} />
                                    <label htmlFor="chkCornu3R">右</label>
                                </div>
                                <OptionalInputContainer className="col-md-5 col-xs-8" isSelected={data.cornu_r_image_score != null}>
                                    <div style={{ display: "flex", paddingBottom:"8px" }}>
                                        <div style={{ flex: "1" }}>
                                            <Slider min={0} max={3}
                                                value={data.cornu_r_image_score ?? 0}
                                                onChange={(e) => setData({...data, cornu_r_image_score: e})}
                                                marks={NUM_SCORE_MARKS(0,3)} step={1} />
                                        </div>
                                    </div>
                                </OptionalInputContainer>
                            </div>
                            )}

                            <div className={rowClass} style={{marginBottom:0}}>
                                <div className={rowHeaderClass}>
                                    <label>子宮収縮</label>
                                </div>
                                <div className="checkbox checkbox-css">
                                    <input type="checkbox" id="chkLRShrink" checked={hasLR_shrink}
                                            onChange={e => {
                                                setHasLR_shrink(e.target.checked);
                                                setData({ ...data, cornu_r_shrink_score: data.cornu_l_shrink_score });
                                            }} />
                                    <label htmlFor="chkLRShrink">左右別</label>
                                </div>
                            </div>
                            <div className={rowClass} data-testid="子宮収縮左" style={{marginBottom:hasLR_shrink ? "2px" : "16px"}}>
                                <div className={rowHeaderChkClass}>
                                    <input type="checkbox" id="chkCornu4L"
                                        onChange={(e) => setData({...data, cornu_l_shrink_score: e.target.checked ? -1 : undefined})}
                                        checked={data.cornu_l_shrink_score != null} />
                                    <label htmlFor="chkCornu4L">{hasLR_shrink ? "左" : "左右"}</label>
                                </div>
                                <OptionalInputContainer className="col-md-5 col-xs-8" isSelected={data.cornu_l_shrink_score != null}>
                                    <div style={{ display: "flex", paddingBottom:"8px" }}>
                                        <div style={{ flex: "1" }}>
                                            <Slider min={-1} max={2}
                                                value={data.cornu_l_shrink_score ?? -1}
                                                onChange={(e) => setData({...data, cornu_l_shrink_score: e})}
                                                marks={SYMBOL_SCORE_MARKS(-1,2)} step={1} />
                                        </div>
                                    </div>
                                </OptionalInputContainer>
                            </div>
                            { hasLR_shrink &&  (
                            <div className={rowClass} data-testid="子宮収縮右">
                                <div className={rowHeaderChkClass}>
                                    <input type="checkbox" id="chkCornu4R"
                                        onChange={(e) => setData({...data, cornu_r_shrink_score: e.target.checked ? -1 : undefined})}
                                        checked={data.cornu_r_shrink_score != null} />
                                    <label htmlFor="chkCornu4R">右</label>
                                </div>
                                <OptionalInputContainer className="col-md-5 col-xs-8" isSelected={data.cornu_r_shrink_score != null}>
                                    <div style={{ display: "flex", paddingBottom:"8px" }}>
                                        <div style={{ flex: "1" }}>
                                            <Slider min={-1} max={2}
                                                value={data.cornu_r_shrink_score ?? -1}
                                                onChange={(e) => setData({...data, cornu_r_shrink_score: e})}
                                                marks={SYMBOL_SCORE_MARKS(-1,2)} step={1} />
                                        </div>
                                    </div>
                                </OptionalInputContainer>
                            </div>
                            )}
                            <div className={rowClass}>
                                <div className="col-form-label col-md-2 col-xs-2 text-lg-right">メモ</div>
                                <div className="col-md-10 col-xs-10">
                                    <textarea rows={2} className="form-control" data-testid="子宮角メモ"
                                        value={data.cornu_note} maxLength={LMT.BREEDING.ITEM_MEMO_LEN}
                                        onChange={e => setData({ ...data, cornu_note:e.target.value })}></textarea>
                                </div>                                    
                            </div>
                        </AutoCollapse>

                        <AutoCollapse header=" 卵巣" headerClassName={collapseHeaderClass(
                            data.ovary_l_mm, data.ovary_r_mm,
                            data.structsL, data.structsR, data.ovary_note
                        )}>
                            <div className={rowClass} data-testid="卵巣左" style={{marginBottom: "2px" }}>
                                <div className={rowHeaderChkClass}>
                                    <input type="checkbox" id="chkOvary1"
                                        onChange={(e) => setData({ ...data, ovary_l_mm: e.target.checked ? 1 : undefined })}
                                        checked={data.ovary_l_mm != null} />
                                    <label htmlFor="chkOvary1">左(mm)</label>
                                </div>
                                <OptionalInputContainer className="col-md-5 col-xs-8" isSelected={data.ovary_l_mm != null}>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ flex: "1" }}>
                                            <SliderInput min={1} max={100}
                                                value={data.ovary_l_mm ?? 1}
                                                onChange={(e) => setData({...data, ovary_l_mm: e})}
                                                onIsValidChange={v => onIsValidChange("ovary_l", v)}
                                                step={1} />
                                        </div>
                                    </div>
                                </OptionalInputContainer>
                            </div>
                            <div className={rowClass} data-testid="卵巣右">
                                <div className={rowHeaderChkClass}>
                                    <input type="checkbox" id="chkOvary2"
                                        onChange={(e) => setData({ ...data, ovary_r_mm: e.target.checked ? 1 : undefined })}
                                        checked={data.ovary_r_mm != null} />
                                    <label htmlFor="chkOvary2">右(mm)</label>
                                </div>
                                <OptionalInputContainer className="col-md-5 col-xs-8" isSelected={data.ovary_r_mm != null}>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ flex: "1" }}>
                                            <SliderInput min={1} max={100}
                                                value={data.ovary_r_mm ?? 1}
                                                onChange={(e) => setData({...data, ovary_r_mm: e})}
                                                onIsValidChange={v => onIsValidChange("ovary_r", v)}
                                                step={1} />
                                        </div>
                                    </div>
                                </OptionalInputContainer>
                            </div>
                            <div className={rowClass} style={{marginBottom:0}}>
                                <div className={rowHeaderClass}>
                                    <label>構造物</label>
                                </div>
                            </div>
                            <div style={{display:"flex", flexWrap:"wrap", justifyContent:"center"}}>
                                <div>
                                    <StructureCounterHeader />
                                    { data.structsR.length === BREEDING_STRUCTURE_KINDS.length
                                        && BREEDING_STRUCTURE_KINDS.map((k,i) => (
                                        <StructureCounter key={i} l={data.structsL[i].item_count} r={data.structsR[i].item_count}
                                                        label={BREEDING_STRUCTURE[k].name} help={BREEDING_STRUCTURE[k].detail ?? undefined}
                                                        onLChange={n => onStructureChange(i, "l", n)}
                                                        onRChange={n => onStructureChange(i, "r", n)} />
                                    ))}
                                </div>
                                <OvarianViewer structures={convertStructures()}/>
                            </div>
                            <div className={rowClass} style={{marginTop:"5px"}}>
                                <div className="col-form-label col-md-2 col-xs-2 text-lg-right">メモ</div>
                                <div className="col-md-10 col-xs-10">
                                    <textarea rows={2} className="form-control" data-testid="卵巣メモ"
                                        value={data.ovary_note} maxLength={LMT.BREEDING.ITEM_MEMO_LEN}
                                        onChange={e => setData({ ...data, ovary_note:e.target.value })}></textarea>
                                </div>                                    
                            </div>
                        </AutoCollapse>
                        { props.clinicId != null && (
                            <div className={rowClass} style={{ marginTop:"12px" }}>
                                <button style={{ margin: "auto" }} onClick={() => setIsDiseasePopupShown(true)}
                                    className="btn btn-theme btn-sm btn-success col-md-6 col-xs-9">診断名を入力
                                </button>
                            </div>
                        )}
                        
                        { props.cows.length >= 2 && (
                            <div className={rowClass} style={{marginTop:"12px", marginBottom:0}}>

                                <select className="form-control col-md-6 col-xs-9" style={{margin:"auto"}}
                                        onChange={e => setSelectedCrossCowId(Number(e.target.value))}
                                        value={selectedCrossCowId}>
                                    { props.cows.map(c => (
                                        <option key={c.cow_id} value={c.cow_id}>{CowToDispInfo(c)}</option>
                                    ))}
                                </select>
                            </div>
                        )}
                        <div className={rowClass} style={{marginTop:props.cows.length >= 2 ? "6px" : "12px"}}>
                            <button style={{ margin: "auto" }} onClick={showCross}
                                className="btn btn-theme btn-sm btn-success col-md-6 col-xs-9">交配記録を入力
                            </button>
                        </div>
                        { data.crosses.map((cross,i) => (
                            <div className={rowClass} key={cross.cow_id} style={{ marginTop:"-10px" }}>
                                <div className="col-md-6 col-xs-9" style={{ margin:"auto", fontSize:"0.75rem", paddingTop:"2px" }}>
                                    <span>{SUMMARY_CROSS(cross, mSeeds.data!.ofRanch, mSeeds.data!.ofClinic ?? [])}</span>
                                    <i className="fas fa-times clickable" style={{ color: "red", fontSize:"0.85rem", padding:"4px" }}
                                        onClick={()=> setData({
                                            ...data,
                                            crosses: data.crosses.filter((_,xi) => i !== xi)
                                        })} />
                                </div>
                            </div>
                        ))}
                        <div className="treat-block-header">
                            <label>処置</label>
                            <IconLink text="プリセット呼び出し" iconType="popup" onClick={() => setIsPresetShown(true)}/>
                        </div>
                        {
                            data.details.map((detail, i) => (
                                <TreatSelector data={{ ...detail }} key={detail.key} index={i}
                                    onChange={d => onTreatUpdated(i,d)} onDelete={() => onRemTreatment(i)}
                                    treatKinds={props.treatKinds}
                                    medicineCategories={props.medicineCategories}
                                    treatKindMedicineName={"投薬"}
                                    defaultMedicineCategory={A.MEDICINE_CATEGORY_BREEDING} 
                                    weightInfo={ { std_weight: props.cow?.std_weight ?? null, birthday: props.cow?.birthday ?? null} } 
                                    hasAuthToEditMaster={canEditMaster}
                                    isOfficialRanch={isOfficial}
                                    usesBenefitForClinic={true}
                                    ranchId={props.ranchId}
                                    clinicId={props.clinicId}
                                />
                            ))
                        }
                        <div className="form-group row treat-write-row"
                            style={{ display: "flex", marginLeft: "0px", marginRight: "0px" }}>
                            <button style={{ flex: "1" }} className="btn btn-theme btn-sm btn-inverse"
                                onClick={onAddTreatment}>
                                <i className="fas fa-lg fa-fw m-r-10 fa-plus-circle"></i>
                                処置を追加
                            </button>
                        </div>

                        <div className="form-group row treat-write-row">
                            <label className="col-form-label col-md-2 col-xs-2">メモ</label>
                            <div className="col-md-10 col-xs-10">
                                <textarea className="form-control" rows={4} maxLength={LMT.BREEDING.MEMO_LEN} data-testid="memo"
                                    value={data.comment} onChange={(e) => setData({ ...data, comment: e.target.value })}></textarea>
                            </div>
                        </div>

                        <div className={"form-group row treat-write-row"}>
                            <div className={"col-form-label col-md-4 col-xs-6"} data-testid="状態予定開閉"
                                onClick={() => setIsNextScheduleOpen(!isNextScheduleOpen)}>
                                <span>状態と予定を設定</span>
                                <i className={collapseArrowClass(isNextScheduleOpen)}></i>
                            </div>
                        </div>
                        <Collapse isOpen={isNextScheduleOpen}>
                            <SymptomStatus className="m-b-10"
                                outcomeOnly={true}
                                isSelected={data.symptom_status.isSelected}
                                value={data.symptom_status.value}
                                onChange={(isSelected,value) => {
                                    setData({
                                        ...data,
                                        symptom_status: { isSelected, value }
                                    })
                                }}
                                isEpidemic={false}
                                pregOnly={isPreg}
                            />

                            <BreedingState
                                isSelected={data.state.isSelected}
                                state={data.state.value}
                                openday={data.state.openday ?? props.baseOpenDay}
                                onChange={(isSelected, value, openday) => setData({ ...data, state:{ isSelected, value, openday }})}
                            />

                            <hr className={styles["hr-border"]} />

                            <NextScheduleSelector
                                index={-1}
                                label={"交配予定"}
                                isSelected={data.cross_plan.isSelected}
                                baseDate={moment(data.watched_at)}
                                titleCandidates={[...A.CROSS_TYPE.values()].map(v => v.sname)}
                                excludesOther={true}
                                titleFormatter={crossPlanTitleFormatter}
                                value={data.cross_plan.schedule}
                                onChange={(selected,val) => 
                                    setData({ 
                                        ...data,
                                        cross_plan: { isSelected: selected, schedule: val },
                                    })
                                }
                                presetTeamId={treatTeamId}
                                ranchId={props.ranchId}
                            />

                            <hr className={styles["hr-border"]} />

                            <TransitionGroup>
                            { data.breeding_plans.map((plan, i) => (
                                <CSSTransition classNames={{ enter: styles["plan-enter"], enterActive:styles["plan-enter-active"] }} key={i} timeout={500}>
                                    <div>
                                    <NextScheduleSelector key={i}
                                        index={i}
                                        label={"検診予定"}
                                        isSelected={plan.isSelected}
                                        baseDate={moment(data.watched_at)}
                                        titleCandidates={["排卵確認", "黄体確認", "妊娠鑑定", "再診"]}
                                        value={plan.schedule}
                                        onChange={(selected,val) => 
                                            setData({ 
                                                ...data,
                                                breeding_plans: [
                                                    ...data.breeding_plans.slice(0, i),
                                                    { isSelected: selected, schedule: val },
                                                    ...data.breeding_plans.slice(i+1)
                                                ]
                                            })
                                        }
                                        presetTeamId={treatTeamId}
                                        ranchId={props.ranchId}
                                    />
                                    </div>
                                </CSSTransition>

                            ))}
                            </TransitionGroup>
                            <div className="form-group row"
                                style={{ marginLeft: "8px", marginRight: "0px", marginTop: "10px" }}>
                                <button style={{ flex: "1" }} className="btn btn-theme btn-black"
                                    onClick={onAddNextSchedule}>
                                    <i className="fas fa-lg fa-fw m-r-10 fa-plus-circle"></i>
                                    検診予定を追加
                                </button>
                            </div>
                        </Collapse>
                    </div>

                </div>
            </div>
        </div>

        <div className="button-page-footer">
            <ExecutionButton type="save" onClick={onSave} disabled={props.executing || invalid_inputs.size > 0} />
            { props.original != null && (
                <ExecutionButton type="delete" onClick={props.onDelete} disabled={props.executing} />
            )}
        </div>
        { editingCrossInfo != null && (
            <CrossPopup ranchId={props.ranchId}
                        clinicId={props.clinicId}
                        original={editingCrossInfo.original}
                        onClose={()=> setEditingCrossInfo(undefined)}
                        onSubmit={onCrossSubmit} 
                        breeding_id={props.original?.breeding_id}
                        watched_at={data.watched_at}
                        seedsRanch={mSeeds.data.ofRanch}
                        seedsClinic={mSeeds.data.ofClinic ?? []}
                        isOfficialRanch={isOfficial}
                        comm={props.comm} />
        )}
        { isPresetShown && (
            <TreatPresetSelectPopup
                presets={mPresets.data.presets}
                medicinesOnly={false}
                medicines={mPresets.data.clinicMedicines ?? mPresets.data.ranchMedicines}
                routes={mPresets.data.medicineRoutes}
                treatItems={mPresets.data.treatItems}
                onClose={() => setIsPresetShown(false)}
                onSubmit={setPreset}
            />
        )}
        { isDiseasePopupShown
            && (
            <DiseaseInputPopup isOpen={true}
                isBreeding={true}
                relations={mDiseases.data.relations}
                diseases={props.diseases}
                causes={props.causes}
                conditions={[]}
                noticeList={[]}
                data={{
                    conditions: [],
                    notice: "",
                    diseases: data.diseases
                }}
                onClose={() => setIsDiseasePopupShown(false)}
                onSubmit={input => {
                    setData({
                        ...data,
                        diseases: input.diseases,
                    });
                    setIsDiseasePopupShown(false);
                }}
            />
        )}
        { isVisitFeeSelectShown && props.clinicId != null && (
            <VisitFeeSelectPopup
                data={data.visit ?? ({ clinic_id:props.clinicId, fees:[] })}
                onClose={() => setIsVisitFeeSelectShown(false)}
                allCows={props.cows}
                feeItemList={mVisiting.data.fees}
                fromList={mVisiting.data.froms}
                showToast={props.showToast}
                onSubmit={v => {
                    setData({ ...data, visit:v });
                    setIsVisitFeeSelectShown(false);
                }}
            />
        )}
    </>)
});



export default withRouter(withContext(BreedingWrite));