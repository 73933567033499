import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { PageSettings } from '../../config/page-settings';
import { withContext } from '../../stores';
import { AppState } from '../../app';
import { TeamSettingDto, TeamSettingModifyReq, TeamApi } from '../../api';
import { CommonUtil, AGE_MODE } from '../../config/util';
import styles from './other-setting.module.css';
import { InfoPopup } from '../../components/parts/info-popup';
import { ExecutionButton } from '../../components/buttons/execution-button';

type EditingData = TeamSettingDto;

interface MyState {
    ranch_id?: number;
    original?: EditingData;
    editingData?: EditingData;
}

const ageOptions = [
    { name: "誕生日に加齢", value: AGE_MODE.BIRTHDAY, hint:"1月15日生 → 2月15日から月齢1ヵ月" },
    { name: "月初に加齢", value: AGE_MODE.FIRST_OF_MONTH, hint:"1月15日生 → 2月1日から月齢1ヵ月" },
] as const;

class OtherSetting extends Base<BaseProps<{id?:string},{},{}>, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {

        this.context.handleSetHeader({ title:"その他設定" });
        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        this.init();
    }

    componentDidUpdate(prevProps: this['props']) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.init();
        }
    }

    async init() {
        const ranchIdStr = this.props.match.params.id;
        const ranchId = Number(ranchIdStr);
        if (ranchIdStr == null || isNaN(ranchId)) {
            this.setState({ ranch_id: undefined });
            return;
        }

        if (this.handleNotAllowAccess(ranchId, ["MASTER_EDIT"], [])) {
            this.setState({ ranch_id: undefined });
            return;
        }

        await this.setStateAsync({ ranch_id: ranchId });
        this.context.handleSetIsLoading(true);
        const res = await this.props.rootStore.fetchSettings(ranchId, true);
        this.context.handleSetIsLoading(false);
        if (res !== "OK") {
            this.setState({
                editingData: undefined,
                original: undefined
            });
            this.context.showToast("設定値の取得に失敗しました");
        } else {
            const data = this.props.rootStore.getSettings(ranchId);
            this.setState({
                editingData: data,
                original: data
            })
        }
    }

    async onSubmit() {
        if (!CommonUtil.assertNotNull(this.state.ranch_id, "ranch_id", "submit")) return;
        if (!CommonUtil.assertNotNull(this.state.editingData, "editingData", "submit")) return;

        const req: TeamSettingModifyReq = {
            team_id: this.state.ranch_id,
            age_mode: this.state.editingData.age_mode
        };

        this.context.handleSetIsLoading(true);
        try {
            const res = await this.comm().send((await TeamApi()).modifySettingUsingPOST(req));
            if (res.result !== "OK") return;

            this.context.showToast("設定が保存されました");
            this.props.rootStore.fetchSettings(this.state.ranch_id, true);

            this.setState({
                original: this.state.editingData
            })
    
        } finally {
            this.context.handleSetIsLoading(false);
        }
    }

    render() {
        if (this.state.ranch_id == null || this.state.editingData == null || this.state.original == null) {
            return <></>
        }
        const data = this.state.editingData;

        const anyChanged = JSON.stringify(this.state.editingData) !== JSON.stringify(this.state.original);

        const classColLabel = "col-form-label col-md-5 col-xs-5 text-lg-right";

        return (
            <div className="page-root width-limit">
                <div className="product product-full-height">
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix">
                            <div className="product-body">
                                <div className={styles.row}>
                                    <label className={classColLabel}>月齢計算方法</label>
                                    <div className={styles["column-value"]}>
                                        { ageOptions.map(o => (
                                            <div className="radio radio-css" style={{ margin:"2px 0 6px" }} key={o.value}>
                                                <input type="radio" id={`radAge_${o.value}`}
                                                    checked={data.age_mode === o.value}
                                                    onChange={() => this.setState({ editingData: { ...data, age_mode: o.value }})} />
                                                <label htmlFor={`radAge_${o.value}`}>{o.name}</label>
                                                <InfoPopup placement="top" message={o.hint} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="button-page-footer">
                    <ExecutionButton type="save" disabled={!anyChanged} onClick={() => this.onSubmit()} />
                </div>
            </div>
        )
    }
}

export default withRouter(withContext(OtherSetting));