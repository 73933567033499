import { SympTreatDto, TeamMedicineDao, SymptomHistoryInfoDto } from "../../api";
import moment from "moment";
import { ITreatItem } from "../../stores";
import { FreezedArray } from "../../config/util";

export const PREVIOUS_SYMPTOMS_DAYS = 30;

export interface IPreviousSymptom {
    symptom_id: number;
    date      : string;
    name      : string;
    first_id  : number;
    watched_at: string;
}

export const convertPreviousSymptomsForSelect
            = (dataList: FreezedArray<SymptomHistoryInfoDto>,
                onlyFirstId: number | undefined,
                includeFirstId: number | undefined,
                omitFirstId: number | undefined,
                isClinicUser: boolean,
                getMedicines: (teamId: number) => TeamMedicineDao[] | undefined,
                getTreatItems: (teamId: number) => ITreatItem[] | undefined): IPreviousSymptom[] => {

    let targets: SymptomHistoryInfoDto[];
    if (onlyFirstId != null) {
        //onlyFirstId が指定されていれば、診療IDか初診IDがそれと一致するもの
        targets = dataList.filter(s => (s.first_id ?? s.symptom_id) === onlyFirstId);
    } else {
        //指定されていなければ、再診 or 処理ありのもの or includeFirstIdが一致するもの
        targets = dataList.filter(s => s.first_id != null || s.treat.length > 0 || (s.first_id ?? s.symptom_id) === includeFirstId);
        if (omitFirstId != null) {
            //除外ID
            targets = targets.filter(s => s.symptom_id !== omitFirstId && s.first_id !== omitFirstId);
        }
    }
    // 初診IDでグルーピング(初診は診療ID)
    const grouped = groupBy(targets, s => s.first_id ?? s.symptom_id);

    // 初診IDグループ内で最新の診療をピックアップし、リスト生成
    const rtn = new Array<IPreviousSymptom>();
    for (const id in grouped) {
        const items = [...grouped[id]];
        // 初診IDグループ内を記録日時の降順でソート
        items.sort((s1,s2) => s1.watched_at > s2.watched_at ? -1 : 1);
        const item = items[0];
        rtn.push({ 
            symptom_id: item.symptom_id,
            date      : moment(item.watched_at).format("M月D日"),
            name      : getSymptomHistoryName(item.treat, isClinicUser, getMedicines, getTreatItems),
            first_id  : item.first_id ?? item.symptom_id,
            watched_at: item.watched_at,
        });
    }
    // 作成した前回診療リストを記録日時の降順でソート
    rtn.sort((s1,s2) => s1.watched_at > s2.watched_at ? -1 : 1);  
    return rtn;
}

const groupBy = (items: SymptomHistoryInfoDto[], selector: (t: SymptomHistoryInfoDto) => number) => {
    const rtn: { [key:number]: SymptomHistoryInfoDto[] } = {};

    for (const item of items) {
        const key = selector(item);
        let l = rtn[key];
        if (l == null) {
            rtn[key] = [];
            l = rtn[key];
        }
        l.push(item);
    }
    return rtn;
}

const getSymptomHistoryName = (treats: SympTreatDto[],
                                isClinicUser: boolean,
                                getMedicines: (teamId: number) => TeamMedicineDao[] | undefined,
                                getTreatItems: (teamId: number) => ITreatItem[] | undefined) => {
    let name = "";
    if ( treats == null || treats.length === 0 ) {
        return "処置なし";
    }

    const unknownItem = isClinicUser ? "農家診療" : "医師診療";

    const treat = treats[0];
    if ((treat.medicine_id ?? 0) > 0) {
        const medicine_list = getMedicines(treat.team_id);
        if (medicine_list == null) { 
            return unknownItem;
        }
        const medicine = medicine_list.find(m => m.medicine_id === treat.medicine_id);
        if (medicine === undefined) { 
            return unknownItem;
        }
        name = medicine.name;
    }
    if (treat.treat_kind_no != null) {
        const treat_item_list = getTreatItems(treat.team_id);
        if (treat_item_list == null) { 
            return unknownItem;
        }
        const treat_item = treat_item_list.filter((value) => value.treat_kind_no === treat.treat_kind_no)
                            .find(t => t.treat_item_no === treat.treat_item_no);
        if (treat_item === undefined) { 
            return unknownItem;
        }
        name = treat_item.name;
    }
    if (treats.length > 1) {
        name += " 他";
    }
    return name;
}