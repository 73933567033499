import React, { useState, useEffect, useCallback } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { ISeedStock } from '../../stores/RootStore';
import styles from './seed-management-stock-popup.module.css';
import { A, TeamType } from '../../config/constant';
import { FreezedArray } from '../../config/util';
import { RanchTagDto } from '../../api';
import { ExecutionButton } from '../../components/buttons/execution-button';

export type SeedStockItem = ISeedStock & {
    name: string,
    lot_name: string,
    seed_lot_id: number,
    seed_id: number,
    ancestor_1:string | undefined,
};

interface MyProps {
    seed_type: number;
    onClose: () => void;
    teamType: TeamType;
    onSelect: (seedId: number, lotId: number, labelNo: string) => void;
    stocks: FreezedArray<SeedStockItem>;
}

export const SeedStockListPopup = (props: MyProps) => {
    const [ filteringTags, setFilteringTags ] = useState<RanchTagDto[]>([]);
    const [ selected, setSelected ] = useState<SeedStockItem>();
    const [ dispList, setDispList ] = useState<FreezedArray<SeedStockItem>>([]);

    useEffect(() => {
        const newList = props.stocks.filter(s => filteringTags.every(ft => s.tags.some(st => st.tag_id === ft.tag_id)));
        setDispList(newList);
        if (selected != null && !newList.includes(selected)) {
            setSelected(undefined);
        }

    }, [ props.stocks, filteringTags ]);

    const isSeed = props.seed_type === A.SEED_TYPE.SEED.seed_type;
    const seedTypeName = isSeed ? "精液" : "受精卵";

    const buildSeedName = (stock:SeedStockItem) => {
        if (isSeed) return stock.name;
        if (stock.name === "") return stock.ancestor_1;
        return `${stock.ancestor_1}×${stock.name}`;
    }


    return (
        <div>
            <Modal isOpen={true} centered={true} style={{ maxWidth:"600px" }}>
                <ModalHeader toggle={props.onClose}>{seedTypeName}在庫一覧</ModalHeader>
                <ModalBody style={{ overflowY: "auto", display:"flex", flexFlow:"column nowrap", height: "calc(100vh - 210px)" }}>
                    <div className={styles["stock-top"]}>
                        <div className={styles.tags}>
                            <div style={{ visibility: filteringTags.length === 0 ? "hidden" : "visible" }}>絞込み：</div>
                            { filteringTags.map((tg) => (
                                <div key={tg.tag_id} className={styles.tag}>
                                    <span>{tg.tag_name}</span>
                                    <i className="fas fa-times clickable p-2"
                                        onClick={() => setFilteringTags(filteringTags.filter(t => t.tag_id !== tg.tag_id))}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={styles["stock-table-container"]}>
                    { dispList.length > 0 && (
                        <table className={styles["stock"]}>
                            <tbody>
                                { dispList.map((stock, i) => (
                                    <tr key={i} className={selected === stock ? styles.selected : "clickable"}>
                                        <td className={styles["td-label"]} style={{ wordBreak:"break-all" }} onClick={() => setSelected(stock)}>
                                            {buildSeedName(stock)}
                                        </td>
                                        <td className={styles["td-label"]} style={{ wordBreak:"break-all" }} onClick={() => setSelected(stock)}>
                                            {stock.lot_name}
                                        </td>
                                        <td className={styles["td-label"]} style={{ wordBreak:"break-all" }} onClick={() => setSelected(stock)}>
                                            {stock.label_no}
                                        </td>
                                        <td className={styles["td-tag"]} onClick={() => setSelected(stock)}>
                                            <div className={styles["tag-container"]}>
                                                { stock.tags.map(t => (
                                                    <div key={t.tag_id} className={styles.tag}
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            if (!filteringTags.some(f => f.tag_id === t.tag_id)) {
                                                                setFilteringTags([...filteringTags, t ])
                                                            }
                                                        }}
                                                    >{t.tag_name}</div>
                                                ))}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                    </div>
                </ModalBody>
                <ModalFooter className="modal-footer-fix">
                    <ExecutionButton type="decide" disabled={selected == null} onClick={() => props.onSelect(selected!.seed_id, selected!.seed_lot_id, selected!.label_no)} />
                    <ExecutionButton type="cancel" onClick={props.onClose} />
                </ModalFooter>
            </Modal>
        </div>
    )
}