import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { A } from '../../config/constant';
import { PageSettings } from '../../config/page-settings.js';
import styles from './team.module.css';
import { withContext } from '../../stores';
import { TeamRequestByUserIdDto, TeamApi, TeamNameDto, TeamRequestDeleteReq, TeamRequestAddReq } from '../../api';
import classnames from 'classnames';
import { TeamRequestPopup } from './team-request-popup';
import { AppState } from '../../app';
import { FreezedArray } from '../../config/util';
import { IUserTeam, UserTeams } from '../../config/user-teams';

const REQUEST_TEAM_LIMIT = 3;

interface MyState {
    search_team_symbol: string;
    request_team: ITeam;
    teams: FreezedArray<IUserTeam>;
    requests: FreezedArray<TeamRequestByUserIdDto>;
    isTeamRequestPopupShown: boolean;
    executing: boolean;
}

export type ITeam = TeamNameDto & {
    team_symbol: string;
}

class TeamSearch extends Base<BaseProps, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            search_team_symbol: "",
            teams: [],
            requests: [],
            isTeamRequestPopupShown: false,
            request_team: {
                team_id: 0,
                name: "",
                team_symbol: "",
                team_type: "",
            },
            executing: false
        }

        this.SearchTeam = this.SearchTeam.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    componentDidMount() {

        this.context.handleSetHeader({ title:"組織を探す", backBtn:false });
        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        this.init();
    }

    componentDidUpdate() {
    }

    private async init() {
        try {
            this.context.handleSetIsLoading(true);

            const res = await this.props.rootStore.fetchUserInfo();
            if (!res) {
                this.context.showToast(A.MESSAGE.FAILED_TO_LOAD_DATA);
                return;
            }

            await this.setStateAsync({ 
                requests: this.props.rootStore.user.requests,
                teams: new UserTeams(this.props.rootStore.user).allOfficialTeams()
            });

        } finally {
            this.context.handleSetIsLoading(false);
        }
    }

    private async SearchTeam() {
        try {
            this.context.handleSetIsLoading(true);

            const res = await this.comm().send((await TeamApi()).getNameUsingPOST({ team_symbol: this.state.search_team_symbol }));
            if (res.result !== "OK") return;

            if(res.data == null) {
                this.context.showToast(A.MESSAGE.NO_MATCH_TEAM);
                return;
            }
            await this.setStateAsync({ 
                request_team: { ...res.data, team_symbol: this.state.search_team_symbol },
                isTeamRequestPopupShown: true,
            });

        } finally {
            this.context.handleSetIsLoading(false);
        }
    }

    private canSearch() {
        if (this.state.search_team_symbol === "") return false;
        return (this.state.requests ?? []).length < REQUEST_TEAM_LIMIT
    }

    private canRequest() {
        // 申請済み
        if (this.state.requests.some(t => t.team_symbol === this.state.search_team_symbol)) return false;
        // 参加済み
        if (this.state.teams.some(t => t.team_symbol === this.state.search_team_symbol)) return false;
        return true;
    }

    private async onSubmit() {

        if ( this.state.request_team.team_type === 'clinic') {
            // 申請済みまたは参加済の診療所を確認
            if ( (this.state.requests ?? []).some(t => t.team_type === 'clinic')
                || new UserTeams(this.props.rootStore.user).findSingleClinic() != null ) {
                this.context.showAlert(A.MESSAGE.CLINIC_TEAM_ALREADY_JOINED);
                return;
            }
        }

        try {
            this.setState({ executing: true });

            const req:TeamRequestAddReq = {
                team_id: this.state.request_team.team_id
            }
            console.log(req);
    
            this.context.handleSetIsLoading(true);
            const res = await this.comm().send((await TeamApi()).addRequestUsingPOST(req));
            this.context.handleSetIsLoading(false);
            if (res.result !== "OK") return;
    
            this.setState({ isTeamRequestPopupShown: false }, () => { this.init(); });

        } finally {
            this.setState({ executing: false });
        }
    }

    onDelete(team_id:number) {
        this.context.showQuestion('参加申請の取消を実行してよろしいですか？', result => {
            if (result === 0) {
                this.deleteRequest(team_id);
            }
        }, ['実行', 'キャンセル']);
    }    

    private async deleteRequest(team_id:number) {
        const req:TeamRequestDeleteReq = {
            from_user_id: this.props.rootStore.user.id,
            team_id: team_id
        }
        this.context.handleSetIsLoading(true);
        const res = await this.comm().send((await TeamApi()).deleteRequestUsingPOST(req));
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") return;
        this.init();
    }

    render() {
        return (
            <div style={{ height: "100%", "display": "flex", flexFlow: "column nowrap" }}>
                <div className="product" style={{ height: "100%", minHeight: "0" }}>
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix p-b-0">
                            {/* <!-- BEGIN product-info-header --> */}
                            <div className="product-info-header m-b-15" style={{ paddingBottom : ".5rem", border: "none", "flex" : "none" }}>
                                <div style={{ marginTop: "0.3rem" }}>
                                    <span>IDで検索</span>
                                </div>
                                <div style={{ display: "flex", marginTop: "0.3rem" }}>
                                    <input type="text" className="form-control m-r-5" value={this.state.search_team_symbol} style={{maxWidth: "300px"}}
                                        placeholder="farm-vetell" onChange={e => this.setState({search_team_symbol: e.target.value})} />
                                    <button type="button" className="btn btn-success" style={{flex: "none"}} onClick={this.SearchTeam}
                                     disabled={!this.canSearch()}>
                                        検索
                                    </button>
                                </div>
                            </div>
                            {/* <!-- END product-info-header --> */}
                            {/* <!-- BEGIN content --> */}
                            <div id="ranch_main" className="ranch-main" style={{"flex": "auto"}}>
                                <div className="m-b-10">
                                    <span>申請中一覧</span>
                                    <button className="btn btn-warning btn-xs m-l-10" onClick={() => this.init()}>更新<i className="fas fa-redo m-l-5"></i></button>
                                </div>
                                {
                                    (this.state.requests??[]).map((value, i) => (
                                        <div key={i} className="item-row">
                                            <div className={classnames("item", "item-thumbnail")} style={{ display: "flex", marginBottom: "0.3rem" }}>
                                                <div style={{flex: "1", display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                                                    <div className="item-info text-left p-t-5 p-b-5">
                                                        <div className={styles["team-name"]}>{value.name}</div>
                                                        <div className={styles["team-symbol"]}>{value.team_symbol}</div>
                                                    </div>
                                                </div>
                                                <div className="item-info p-t-5 p-b-5" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                                    <button className={classnames("btn", "btn-danger")} onClick={() => this.onDelete(value.team_id)}>取消</button>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            {/* <!-- END content --> */}
                        </div>
                    </div>
                </div>
                {/* <!-- END footer --> */}
                {
                    this.state.isTeamRequestPopupShown && (
                        <TeamRequestPopup
                            team={this.state.request_team}
                            canRequest={this.canRequest()}
                            onClose={() => this.setState({ isTeamRequestPopupShown: false })}
                            onSubmit={this.onSubmit}
                            isSubmitExecuting={this.state.executing}
                        />
                    )
                }                
            </div>
        )
    }
}

export default withRouter(withContext(TeamSearch));