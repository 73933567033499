import React, { useState, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { DiseaseCategory, DISEASE_CATEGORIES, DISEASE_ITEM_STATUS } from '../../config/constant';
import styles from './setting.module.css';
import { PageSettings } from '../../config/page-settings';
import { withContext, IDisease } from '../../stores';
import { AppState } from '../../app';
import { CommonUtil, FreezedArray, formatDiseaseCode } from '../../config/util';
import { DiseaseEditPopup, EditingCause } from './disease-edit-popup';
import classnames from 'classnames';
import { Checkbox } from '../../components/form/form-checkbox';
import { TeamDiseaseModifyReq, TeamApi, TeamDiseaseCauseDto, DiseaseCauseDto } from '../../api';
import { StarIconS } from './star-icon';
import { useDiseaseRelations, resetDiseaseRelations } from '../../stores/fetcher';
import { FetchWaiter, FetchError } from '../../components/content/fetch-state';
import { UserTeams } from 'config/user-teams';

interface MyProps extends BaseProps<{id:string}> {
}
type Disease = {
    disease_id:number;
    name:string;
    category: DiseaseCategory;
    causes: EditingCause[];
    noCauseItem: { code_no?: number, status: number } | undefined;
    isEpidemic: boolean;
}

interface MyState {
    teamId: number | null;
    executing: boolean;
}

class DiseaseMaster extends Base<MyProps, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            teamId: null,
            executing: false,
        };
    }

    componentDidMount() {

        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        this.init();
    }

    componentDidUpdate(prevProps: this['props'], prevState: MyState) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.init();
        }
    }

    private init() {
        const team_id = Number(this.props.match.params.id);
        if (this.handleNotAllowAccess(team_id, ["MASTER_EDIT"], ["MASTER_EDIT"])) {
            return;
        }
        const isRanch = new UserTeams(this.props.rootStore.user).findRanch(team_id) != null;
        this.context.handleSetHeader({ title:"診断項目の管理", iconType:isRanch ? "ranch" : "clinic" });

        this.setState({
            teamId: team_id
        });
    }

    private onChangeDiseaseStatus = async (disease: Pick<Disease, "disease_id"|"noCauseItem">, toVisible: boolean) => {
        if (!CommonUtil.assertNotNull(this.state.teamId)) return false;
        const status = toVisible ? DISEASE_ITEM_STATUS.VISIBLE.no : DISEASE_ITEM_STATUS.HIDDEN.no;
        const req: TeamDiseaseModifyReq = {
            team_id: this.state.teamId,
            disease_id: disease.disease_id,
            status,
            code_no: disease.noCauseItem?.code_no
        };
        return this.save(req);
    }

    private onFinishEditing = async (disease: Pick<Disease,"disease_id"|"noCauseItem">, favorite: boolean, causes: FreezedArray<EditingCause>) => {
        if (!CommonUtil.assertNotNull(this.state.teamId, "teamId")) return false;

        const req: TeamDiseaseModifyReq = {
            team_id: this.state.teamId,
            disease_id: disease.disease_id,
            status: favorite ? DISEASE_ITEM_STATUS.FAVORITE.no : DISEASE_ITEM_STATUS.VISIBLE.no,
            code_no: disease.noCauseItem?.code_no,
            items: causes.map(c => ({
                cause_no: c.cause_no,
                code_no: c.code_no,
                status: c.status
            }))
        };
        return this.save(req);
    }

    private async save(req: TeamDiseaseModifyReq): Promise<boolean> {
        this.setState({ executing: true });
        this.context.handleSetIsLoading(true);
        try {
            const res = await this.comm().send((await TeamApi()).modifyTeamDiseaseUsingPOST(req));
            if (res.result !== "OK") return false;

            await resetDiseaseRelations(false);
            return true;

        } finally {
            this.context.handleSetIsLoading(false);
            this.setState({ executing: false });
        }
    }

    render() {
        const teamId = this.state.teamId;
        if (teamId == null) return <></>;

        return (
            <DiseaseMasterContent
                teamId={teamId}
                diseases={this.props.rootStore.options.disease}
                causes={this.props.rootStore.options.causes}
                executing={this.state.executing}
                onFinishEditing={this.onFinishEditing}
                onChangeDiseaseStatus={this.onChangeDiseaseStatus}
            />
        )
    }
}

interface ContentProps {
    teamId: number;
    diseases: FreezedArray<IDisease>;
    causes: FreezedArray<DiseaseCauseDto>;
    executing: boolean;
    onFinishEditing: (disease: Pick<Disease,"disease_id"|"noCauseItem">, favorite: boolean, causes: FreezedArray<EditingCause>) => Promise<boolean>;
    onChangeDiseaseStatus: (disease: Pick<Disease, "disease_id"|"noCauseItem">, toVisible: boolean) => Promise<boolean>;
}

const DiseaseMasterContent = React.memo((props: ContentProps) => {
    const relations = useDiseaseRelations(props.teamId);

    const [ selectedCategory, setSelectedCategory ] = useState<number>();
    const [ isVisibleOnly, setIsVisibleOnly ] = useState(true);
    const [ editingDisease, setEditingDisease ] = useState<Disease>();

    const [ allDiseaseList, setAllDiseaseList ] = useState<FreezedArray<Disease>>([]);
    const [ currentList, setCurrentList ] = useState<FreezedArray<Disease>>([]);

    useEffect(() => {
        if (relations.data == null) return;

        setAllDiseaseList(buildDiseaseList(props.diseases, props.causes, relations.data))

    }, [ props.diseases, props.causes, relations.data ]);


    const buildDiseaseList = useCallback((diseases: FreezedArray<IDisease>, causes: FreezedArray<DiseaseCauseDto>, relations: TeamDiseaseCauseDto[]) => {
        const relMap = CommonUtil.groupBy(relations, r => r.disease_id);

        const allList: Disease[] = diseases.map(d => (
            {
                disease_id: d.disease_id,
                name: d.name,
                category:DISEASE_CATEGORIES.find(c => c.no === d.category ) ?? { no:0,name:"" },
                causes: (relMap.get(d.disease_id) ?? [])
                        .filter(r => r.cause_no != null)
                        .map(r => ({
                    cause_no:r.cause_no!,
                    code_no:r.code_no,
                    status: r.status,
                    cause_name: causes.find(c => r.cause_no === c.cause_no)?.cause_name ?? ""
                })),
                noCauseItem: relMap.get(d.disease_id)?.find(r => r.cause_no == null),
                isEpidemic: d.is_epidemic === 1
            }));
        
        return allList;
    }, []);

    useEffect(() => {
        const category = selectedCategory;
        const visibleOnly = isVisibleOnly;

        setCurrentList(
            allDiseaseList.filter(d => {
                if (category != null && category !== d.category.no) return false;
                //原因なし項目が存在しない場合も表示対象
                if (visibleOnly && (d.noCauseItem?.status === DISEASE_ITEM_STATUS.HIDDEN.no)) return false;

                return true;
            })
            .map(d => ({
                ...d,
                causes: d.causes.filter(c => !visibleOnly || c.status !== DISEASE_ITEM_STATUS.HIDDEN.no)
            }))
        );

    }, [ allDiseaseList, selectedCategory, isVisibleOnly ]);

    const startEdit = useCallback((disease: Disease) => {
        //非表示項目含めて編集モーダルに渡すため、all list から再取得
        const target = allDiseaseList.find(d => d.disease_id === disease.disease_id);
        if (CommonUtil.assertNotNull(target)) {
            setEditingDisease(target);
        }
    
    }, [ allDiseaseList ]);


    const onFinishEditing = useCallback(async (favorite: boolean, causes: FreezedArray<EditingCause>) => {
        if (!CommonUtil.assertNotNull(editingDisease, "editingDisease")) return;

        const res = await props.onFinishEditing(editingDisease, favorite, causes);
        if (res === true) {
            setEditingDisease(undefined);
        }

    }, [ editingDisease, props.onFinishEditing ]);


    const anyFavorite = useCallback((disease: Disease) => {
        if (disease.noCauseItem?.status === DISEASE_ITEM_STATUS.FAVORITE.no) return true;
        if (disease.noCauseItem?.status === DISEASE_ITEM_STATUS.HIDDEN.no) return false;
        
        return disease.causes.some(c => c.status === DISEASE_ITEM_STATUS.FAVORITE.no);
    }, [])


    if (relations.isLoading) return <FetchWaiter />
    if (relations.isError || relations.data == null) return <FetchError />

    return (
        <div className="page-root">
            <div className="product product-full-height">
                <div className="product-detail" style={{ height: "100%" }}>
                    <div className="product-info product-info-fix">
                        <div className={"product-info-header " + styles.header} style={{ "height" : "45px" }}>
                            <div className={styles["sortable-list-shoulder"]}>
                                <div style={{ marginLeft : "15px", whiteSpace: "nowrap", display:"flex", alignItems:"center" }}>
                                    <Checkbox id="chkVisibleOnly"
                                        label="使用中のみ" checked={isVisibleOnly}
                                        onChange={e => setIsVisibleOnly(e.target.checked)} />
                                </div>
                                <select className="form-control" style={{"maxWidth" : "400px"}} 
                                        value={selectedCategory}
                                        onChange={e => setSelectedCategory(
                                            e.target.value === "" ? undefined : Number(e.target.value)
                                        )}
                                >
                                    <option value="">分類で絞り込み</option>
                                    {
                                        DISEASE_CATEGORIES.map(ct => <option key={ct.no} value={ct.no}>{ct.name}</option>)
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="product-body">
                            <ul className={classnames(styles["list"], "m-b-0")}>
                                { currentList.map(dis => (
                                    <li key={dis.disease_id} className={classnames(styles["list-item"], styles["list-item-mid"], { [styles.hidden]:dis.noCauseItem?.status === DISEASE_ITEM_STATUS.HIDDEN.no })}>
                                        <div className={styles["list-item-content"]}>
                                            <div className={styles["list-item-content-row"]}>
                                                <StarIconS visible={anyFavorite(dis)}
                                                    style={{ verticalAlign:"top", width:"16px" }}
                                                />
                                                <span className={styles["list-item-name"]}>{dis.name}</span>
                                                <DiseaseAddRmIcon
                                                    toAdd={dis.noCauseItem?.status === DISEASE_ITEM_STATUS.HIDDEN.no}
                                                    onClick={toAdd => props.onChangeDiseaseStatus(dis, toAdd)}
                                                />
                                            </div>
                                            <div className={styles["list-item-detail"]} style={{ paddingLeft:"16px"}}>
                                                { dis.isEpidemic && (<span>[法]</span>)}
                                                { dis.noCauseItem?.code_no != null && (
                                                    <span className="m-r-5">{formatDiseaseCode(dis.noCauseItem.code_no)}</span>
                                                )}
                                                { isVisibleOnly ? (
                                                    <span>{ dis.causes.filter(c => c.status !== DISEASE_ITEM_STATUS.HIDDEN.no).map(c => c.cause_name).join(", ") }</span>
                                                ) : dis.causes.map((c,i) => (
                                                    <span key={i}>{ i > 0 && (<span>, </span>)}<span className={c.status === DISEASE_ITEM_STATUS.HIDDEN.no ? styles["item-unused"] : "" }>{c.cause_name}</span></span>
                                                ))
                                                }
                                            </div>
                                        </div>
                                        { dis.noCauseItem?.status !== DISEASE_ITEM_STATUS.HIDDEN.no && (
                                            <div onClick={() => startEdit(dis)} className={styles["list-item-icon"]}>
                                                <i className="fas fa-pen clickable" />
                                            </div>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {
               editingDisease != null && (
                    <DiseaseEditPopup
                        diseaseName={editingDisease.name}
                        isEpidemic={editingDisease.isEpidemic}
                        causes={editingDisease.causes}
                        code_no={editingDisease.noCauseItem?.code_no}
                        isSubmitExecuting={props.executing}
                        onClose={() => setEditingDisease(undefined)}
                        visibleOnly={isVisibleOnly}
                        favorite={editingDisease.noCauseItem?.status === DISEASE_ITEM_STATUS.FAVORITE.no}
                        onSubmit={onFinishEditing}
                    />
                )
            }
        </div>
    )
});

export const DiseaseAddRmIcon = (props: { toAdd:boolean, onClick:(toAdd:boolean) => void }) => {

    const className = classnames(
        "fas",
        props.toAdd ? "fa-plus" : "fa-times",
        "clickable",
        styles["disease-icon"],
        props.toAdd ? styles.add : styles.remove
    );

    return (
        <i className={className} onClick={() => props.onClick(props.toAdd)} />
    )
}

export default withRouter(withContext(DiseaseMaster));