import React from 'react';
import { InfoPopup } from '../../components/parts/info-popup';
import { NumericUpDown } from '../../components/parts/numeric-updown';

interface MyProp {
    l:number;
    r:number;
    onLChange:(l:number) => void;
    onRChange:(r:number) => void;
    label:string;
    help?:string;
}

export const StructureCounterHeader = React.memo(() => (
    <div style={{display:"flex", justifyContent:"center"}}>
        <div style={{width:"70px"}}></div>
        <span style={{width:"90px", fontSize:"0.9rem", textAlign:"center" }}>左</span>
        <span style={{width:"90px", fontSize:"0.9rem", marginLeft:"18px", textAlign:"center"}}>右</span>
    </div>
));
const StructureCounter: React.FC<MyProp> = (prop) => {
    return (
        <div style={{display:"flex", alignItems:"center", justifyContent:"center", borderBottom: "1px dashed #999999", padding:"1px 0 4px", margin:"0 0 3px"}}>
            <div style={{width:"70px"}}>
                <span>{prop.label}</span>
                { prop.help && <InfoPopup message={prop.help} placement="top" /> }
            </div>
            <NumericUpDown style={{ width:"90px" }}
                min={0} max={20}
                value={prop.l}
                onChange={prop.onLChange}
            />
            <NumericUpDown style={{ width:"90px", marginLeft:"18px" }}
                min={0} max={20}
                value={prop.r}
                onChange={prop.onRChange}
            />
        </div>
    );
}

export default StructureCounter;