import React from 'react';
import { withRouter } from 'react-router-dom';
import Base, { BaseProps } from '../../components/content/base';
import { PageSettings } from '../../config/page-settings';
import { withContext } from '../../stores';
import { AppState } from '../../app';
import { TeamApi, TeamModifyReq } from '../../api';
import { CommonUtil } from '../../config/util';
import styles from './team-info.module.css';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { LMT } from '../../config/constant';
import { PREF_CODES } from '../../config/pref-code';
import { UserTeams } from '../../config/user-teams';
import { DIALOG_BUTTONS } from '../../components/form/form-dialog';

type EditingData = {
    name: string;
    address: string;
    pref_cd:string | undefined;
    tel:string;
}

interface MyState {
    teamId?: number;
    original: EditingData;
    editingData: EditingData;
    isRanch: boolean;
    isOfficial: boolean;
}

class TeamInfo extends Base<BaseProps<{id?:string},{},{}>, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            isRanch:true,
            isOfficial:true,
            original: {
                name: "",
                address: "",
                pref_cd: undefined,
                tel: ""
            },
            editingData: {
                name: "",
                address: "",
                pref_cd: undefined,
                tel: ""
            }
        };
    }

    componentDidMount() {

        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        this.init();
    }

    componentDidUpdate(prevProps: this['props']) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.init();
        }
    }

    async init() {
        const teamIdStr = this.props.match.params.id;
        const teamId = Number(teamIdStr);
        if (teamIdStr == null || isNaN(teamId)) {
            this.setState({ teamId: undefined });
            return;
        }

        await this.setStateAsync({ teamId });

        //最新取得
        this.context.handleSetIsLoading(true);
        const res = await this.props.rootStore.fetchUserInfo();
        this.context.handleSetIsLoading(false);

        if (!res) {
            this.context.showToast("設定値の取得に失敗しました");
            this.setState({ teamId: undefined });
            return;
        }

        if (this.handleNotAllowAccess(teamId, ["MASTER_EDIT"], ["MASTER_EDIT"])) {
            this.setState({ teamId: undefined });
            return;
        }

        const userTeams = new UserTeams(this.props.rootStore.user);
        const team = userTeams.findTeam(teamId);
        if (!CommonUtil.assertNotNull(team)) return;

        const isOfficial = userTeams.findOfficialTeam(teamId) != null;

        this.context.handleSetHeader({ iconType:team.isRanch ? "ranch" : "clinic", title:"基本情報の設定" });

        const original = {
            name: team.name,
            address: team.address ?? "",
            tel: team.tel ?? "",
            pref_cd: team.pref_cd === "" ? undefined : team.pref_cd
        };
        this.setState({
            original,
            editingData: original,
            isRanch: team.isRanch,
            isOfficial
        });
    }

    private onEdit = <K extends keyof EditingData>(val: Pick<EditingData, K>) => {
        this.setState({
            editingData: {
                ...this.state.editingData,
                ...val
            }
        })
    }

    private onSubmit = async () => {
        if (!CommonUtil.assertNotNull(this.state.teamId, "teamId", "submit")) return;

        const req: TeamModifyReq = {
            team_id: this.state.teamId,
            name: this.state.editingData.name,
            address: this.state.editingData.address,
            pref_cd: this.state.editingData.pref_cd ?? "",
            tel: this.state.editingData.tel
        };

        this.context.handleSetIsLoading(true);
        try {
            const res = await this.comm().send((await TeamApi()).modifyTeamUsingPOST(req));
            if (res.result !== "OK") return;

            this.context.showToast("設定が保存されました");
            this.props.rootStore.fetchUserInfo();

            this.setState({
                original: this.state.editingData
            })
    
        } finally {
            this.context.handleSetIsLoading(false);
        }
    }

    private onDelete = async () => {
        if (!CommonUtil.assertNotNull(this.state.teamId, "teamId", "delete")) return;
        if (!this.state.isRanch || this.state.isOfficial) {
            console.error("delete called for clinic or official ranch", this.state.teamId, this.state.isRanch);
            return;
        }

        const confirmed = await this.context.showDialog("QUESTION", "この牧場を削除してよろしいですか？", DIALOG_BUTTONS.DELETE_CANCEL);
        if (confirmed !== 0) return;

        this.context.handleSetIsLoading(true);
        const res = await this.comm().send((await TeamApi()).deleteTeamUsingPOST({ team_id: this.state.teamId }));
        this.context.handleSetIsLoading(false);

        if (res.result !== "OK") return;

        this.props.rootStore.setUserInfo({
            teams: new UserTeams(this.props.rootStore.user).getUnofficialRemovedTeams(this.state.teamId)
        });

        this.props.history.push("/top");
    }

    render() {
        if (this.state.teamId == null) {
            return <></>
        }
        const anyChanged = JSON.stringify(this.state.editingData) !== JSON.stringify(this.state.original);

        const classColLabel = "col-form-label col-md-5 col-xs-5 text-lg-right";

        return (
            <div className="page-root width-limit">
                <div className="product product-full-height">
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix">
                            <div className="product-body">
                                <div className={styles.row}>
                                    <label className={classColLabel}>{this.state.isRanch ? "牧場名" : "診療所名"}</label>
                                    <div className={styles["column-value"]}>
                                        <input type="text" className="form-control" data-testid="team-name"
                                            maxLength={LMT.RANCH.NAME_LEN}
                                            value={this.state.editingData.name}
                                            onChange={e => this.onEdit({ name:e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <label className={classColLabel}>住所</label>
                                    <div className={styles["column-value"]}>
                                        <select className={"form-control " + styles.pref} data-testid="prefecture"
                                            value={this.state.editingData.pref_cd ?? ""}
                                            onChange={e => this.onEdit({ pref_cd: e.target.value === "" ? undefined : e.target.value })}>
                                            <option value="">未選択</option>
                                            { PREF_CODES.map(pf => (
                                                <option key={pf.cd} value={pf.cd}>{pf.name}</option>
                                            ))}
                                        </select>
                                        <input type="text" className="form-control" data-testid="address"
                                            maxLength={LMT.RANCH.ADDRESS_LEN}
                                            value={this.state.editingData.address}
                                            onChange={e => this.onEdit({ address:e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <label className={classColLabel}>電話番号</label>
                                    <div className={styles["column-value"]}>
                                        <input type="text" className="form-control" data-testid="tel"
                                            maxLength={LMT.RANCH.TEL_LEN}
                                            value={this.state.editingData.tel}
                                            onChange={e => this.onEdit({ tel:e.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="button-page-footer">
                    <ExecutionButton type="save" disabled={!anyChanged} onClick={this.onSubmit} />
                    { !this.state.isOfficial && (
                        <ExecutionButton type="delete" onClick={this.onDelete} />
                    )}
                </div>
            </div>
        )
    }
}

export default withRouter(withContext(TeamInfo));