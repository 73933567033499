import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Checkbox } from '../../components/form/form-checkbox';
import { RequiredNumInput } from '../../components/parts/num-input';
import styles from './cow-search-popup.module.css';
import { CommonUtil } from '../../config/util';
import { BALANCE_CATEGORIES, BALANCE_CATEGORY } from '../balance/balance-graph';
import { OptionalTermSelector } from '../../components/parts/optional-term-selector';
import moment from 'moment';
import { ExecutionButton } from 'components/buttons/execution-button';

const COW_LIST_OUTPUT_BOOL_KEYS = [
    "rep_no",
    "trace_id",
    "name",
    "sex",
    "birthday",
    "age",
    "day_age",
    "accept_from",
    "accept_day",
    "breed",
    "use",
    "site",
    "barn",
    "room",
    "tag",
    "mother",
    "surrogate_mother",
    "ancestors",
    ...BALANCE_CATEGORIES,
    "profit",
    "loss",
    "balance",
    "breeding_state",
    "cross_count",
    "cross_day",
    "cross_kind",
    "days_after_cross",
    "delivery_count",
    "delivery_day",
    "days_after_delivery",
    "delivery_schedule",
    "days_after_rut",
    "note",
    "sell_month",
    "his_ruts",
    "his_breedings",
    "his_crosses",
    "his_deliveries",
] as const;

const COW_LIST_OUTPUT_DAYS_KEYS = [
    "his_symptom_physical",
    "his_symptom_treatment",
    "his_prevention",
    "his_location",
    "schedule"
] as const;

export type CowListOutputBoolKey = typeof COW_LIST_OUTPUT_BOOL_KEYS[number];
export type CowListOutputDaysKey = typeof COW_LIST_OUTPUT_DAYS_KEYS[number];
export type CowListOutputKey = CowListOutputBoolKey | CowListOutputDaysKey;

const isBoolKey = (key: CowListOutputKey): key is CowListOutputBoolKey => {
    return COW_LIST_OUTPUT_BOOL_KEYS.includes(key as CowListOutputBoolKey);
}
const isDaysKey = (key: CowListOutputKey): key is CowListOutputDaysKey => {
    return COW_LIST_OUTPUT_DAYS_KEYS.includes(key as CowListOutputDaysKey);
}

const OUTPUT_GROUP_KEYS = [ "grp_basic", "grp_location", "grp_blood", "grp_breeding", "grp_detail", "grp_breedingrecord", "grp_balance" ] as const;
export type CowListOutputGroupKey = typeof OUTPUT_GROUP_KEYS[number];

type CowListOutputBoolItemGroup = {
    name: string,
    isBreeding?: boolean,
    needsBalanceAuth?: boolean,
    hasTerm?: boolean
}
type CowListOutputBoolItemInfo = {
    header: string,
    default?: boolean,
    group: CowListOutputGroupKey
}
type CowListOutputDaysItemInfo = {
    header: string,
    default?: boolean,
    days: { max: number, default: number },
    direction: "past"|"future"
}

const COW_LIST_OUTPUT_GROUPS: { [key in CowListOutputGroupKey]: CowListOutputBoolItemGroup } = {
    grp_basic: { name: "基本情報" },
    grp_location: { name: "現在の場所" },
    grp_blood: { name: "血統" },
    grp_breeding: { name: "繁殖管理", isBreeding: true },
    grp_detail: { name: "詳細情報" },
    grp_breedingrecord: { name: "前回分娩後の記録", isBreeding: true },
    grp_balance: { name: "売上・経費", needsBalanceAuth: true, hasTerm: true }
} as const;

const COW_LIST_OUTPUT_BOOL_ITEMS: { [key in CowListOutputBoolKey]: CowListOutputBoolItemInfo } = {
    rep_no: { header: "耳標", group:"grp_basic", default: true },
    trace_id: { header: "ID", group:"grp_basic", default: true },
    name: { header: "名前", group:"grp_basic", default: true },
    sex: { header: "性別", group:"grp_basic" },
    birthday: { header: "生年月日", group:"grp_basic" },
    age: { header: "月齢", group:"grp_basic" },
    day_age: { header: "日齢", group: "grp_basic" },
    accept_from: { header: "導入元", group:"grp_basic" },
    accept_day: { header: "導入日", group:"grp_basic" },
    breed: { header: "品種", group:"grp_basic" },
    use: { header: "用途", group:"grp_basic" },
    site: { header: "分場", group:"grp_location" },
    barn: { header: "牛舎", group:"grp_location" },
    room: { header: "部屋", group:"grp_location" },
    mother: { header: "母牛", group:"grp_blood" },
    surrogate_mother: { header: "代理母", group:"grp_blood" },
    ancestors: { header: "三代祖", group:"grp_blood" },
    MILK:         { header: BALANCE_CATEGORY.MILK.name, group: "grp_balance" },
    EGG:          { header: BALANCE_CATEGORY.EGG.name, group: "grp_balance" },
    DISUSED:      { header: BALANCE_CATEGORY.DISUSED.name, group: "grp_balance" },
    CHILD:        { header: BALANCE_CATEGORY.CHILD.name, group: "grp_balance" },
    CARCASS:      { header: BALANCE_CATEGORY.CARCASS.name, group: "grp_balance" },
    OTHER_PROFIT: { header: BALANCE_CATEGORY.OTHER_PROFIT.name, group: "grp_balance" },
    FEED:         { header: BALANCE_CATEGORY.FEED.name, group: "grp_balance" },
    TREAT:        { header: BALANCE_CATEGORY.TREAT.name, group: "grp_balance" },
    PREVEINTION:  { header: BALANCE_CATEGORY.PREVEINTION.name, group: "grp_balance" },
    BREEDING:     { header: BALANCE_CATEGORY.BREEDING.name, group: "grp_balance" },
    BUY_COW:      { header: BALANCE_CATEGORY.BUY_COW.name, group: "grp_balance" },
    OTHER_LOSS:   { header: BALANCE_CATEGORY.OTHER_LOSS.name, group: "grp_balance" },
    profit: { header: "売上合計", group: "grp_balance" },
    loss: { header: "経費合計", group: "grp_balance" },
    balance: { header: "収支", group: "grp_balance" },
    breeding_state : { header: "繁殖状態", group:"grp_breeding" },
    cross_count: { header: "交配回数", group:"grp_breeding" },
    cross_day : { header: "最終交配日", group:"grp_breeding" },
    cross_kind : { header: "交配種別", group:"grp_breeding" },
    days_after_cross : { header: "交配後日数", group:"grp_breeding" },
    delivery_count : { header: "分娩回数", group:"grp_breeding" },
    delivery_day : { header: "最終分娩日", group:"grp_breeding" },
    days_after_delivery : { header: "分娩後日数", group:"grp_breeding" },
    delivery_schedule : { header: "分娩予定日", group:"grp_breeding" },
    days_after_rut: { header: "発情後日数", group:"grp_breeding" },
    tag: { header: "タグ", group:"grp_detail" },
    note: { header: "メモ", group:"grp_detail" },
    sell_month: { header: "出荷予定月", group:"grp_detail" },
    his_ruts : { header: "発情記録", group:"grp_breedingrecord" },
    his_breedings : { header: "検診記録", group:"grp_breedingrecord" },
    his_crosses : { header: "交配記録", group:"grp_breedingrecord" },
    his_deliveries : { header: "分娩記録", group:"grp_breedingrecord" },
} as const;
const COW_LIST_OUTPUT_DAYS_ITEMS: { [key in CowListOutputDaysKey]: CowListOutputDaysItemInfo } = {
    his_symptom_physical: { header: "体調記録", days: { max: 365, default: 7 }, direction: "past"},
    his_symptom_treatment: { header: "治療記録", days: { max: 365, default: 7 }, direction: "past"},
    his_prevention: { header: "予防記録", days: { max: 365, default: 7 }, direction: "past"},
    his_location: { header: "移動記録", days: { max: 365, default: 7 }, direction: "past"},
    schedule: { header: "予定", days: { max: 365, default: 1 }, direction: "future"}
} as const;

export type ICowListOutput = { [key in CowListOutputBoolKey]: boolean }
    & { [key in CowListOutputDaysKey]: { output: boolean, days: number } }
    & { [key in CowListOutputGroupKey]?: { from?: string, to?: string } };

export const getBoolOutputHeader = (key: CowListOutputBoolKey) => COW_LIST_OUTPUT_BOOL_ITEMS[key].header;
export const getDaysOutputHeader = (key: CowListOutputDaysKey) => COW_LIST_OUTPUT_DAYS_ITEMS[key].header;
export const getOutputHeader = (key: CowListOutputKey) => {
    if (isBoolKey(key)) return getBoolOutputHeader(key);
    if (isDaysKey(key)) return getDaysOutputHeader(key);
    throw new Error("invalid key " + key);
}

export const outputsToDispText = (outputs: ICowListOutput) => {
    return COW_LIST_OUTPUT_BOOL_KEYS.filter(key => outputs[key] ).map(k => getBoolOutputHeader(k))
        .concat(COW_LIST_OUTPUT_DAYS_KEYS.filter(key => outputs[key].output).map(k => getDaysOutputHeader(k)))
        .join(", ");
}
export const anyOutputInGroup = (outputs: ICowListOutput, groupKey: CowListOutputGroupKey) => {
    const keys = COW_LIST_OUTPUT_BOOL_KEYS.filter(k => COW_LIST_OUTPUT_BOOL_ITEMS[k].group === groupKey);
    return keys.some(k => outputs[k] === true);
}

export const outputToRequired = (received: Partial<ICowListOutput & { "his_symptom": { output: boolean, days: number } }>): ICowListOutput => {

    const bools
        = COW_LIST_OUTPUT_BOOL_KEYS
            .map(k => ({ [k]: received[k] ?? COW_LIST_OUTPUT_BOOL_ITEMS[k].default === true }))
            .reduce((p,c) => ({ ...p, ...c }),{}) as { [key in CowListOutputBoolKey]: boolean };
    const days
        = COW_LIST_OUTPUT_DAYS_KEYS
            .map(k => ({ [k]: received[k] ?? ({ days: COW_LIST_OUTPUT_DAYS_ITEMS[k].days.default ?? 0, output: COW_LIST_OUTPUT_DAYS_ITEMS[k].default === true })}))
            .reduce((p,c) => ({ ...p, ...c }), {}) as { [key in CowListOutputDaysKey]: { output: boolean, days: number } };
    //旧い項目の変換
    if (("his_symptom" in received) && received["his_symptom"]?.output) {
        days["his_symptom_physical"] = received["his_symptom"];
        days["his_symptom_treatment"] = received["his_symptom"];
    }
    const groups: { [key in CowListOutputGroupKey]?: { from?: string, to?: string } }
        = OUTPUT_GROUP_KEYS
            .filter(k => k in received)
            .map(k => ({ [k]: received[k] }))
            .reduce((p,c) => ({ ...p, ...c }), {});
    
    return { ...bools, ...days, ...groups };
}

export const removeUnauthorizedOutput = (outputs: ICowListOutput, hasBreedingContract: boolean, hasCowBalanceAuth: boolean) => {
    const rtn = { ...outputs };
    if (!hasBreedingContract) {
        const grps = OUTPUT_GROUP_KEYS.filter(k => COW_LIST_OUTPUT_GROUPS[k].isBreeding);
        const keys = COW_LIST_OUTPUT_BOOL_KEYS.filter(k => grps.includes(COW_LIST_OUTPUT_BOOL_ITEMS[k].group));
        keys.forEach(k => rtn[k] = false);
    }
    if (!hasCowBalanceAuth) {
        const grps = OUTPUT_GROUP_KEYS.filter(k => COW_LIST_OUTPUT_GROUPS[k].needsBalanceAuth);
        const keys = COW_LIST_OUTPUT_BOOL_KEYS.filter(k => grps.includes(COW_LIST_OUTPUT_BOOL_ITEMS[k].group));
        keys.forEach(k => rtn[k] = false);
    }
    return rtn;
}

export interface SelectOutputPopupProps {
    onClose:()=>void;
    outputs: Readonly<ICowListOutput>;
    onSubmit: (outputs: ICowListOutput) => void;
    hasBreedingContract: boolean;
    hasCowBalanceAuth: boolean;
}

export const buildDefaultOutputs = (): ICowListOutput => {
    const rtn: Partial<ICowListOutput> = {};
    COW_LIST_OUTPUT_BOOL_KEYS.forEach(key => {
        const INFO = COW_LIST_OUTPUT_BOOL_ITEMS[key];
        rtn[key] = INFO.default ?? false;
    });
    COW_LIST_OUTPUT_DAYS_KEYS.forEach(key => {
        const INFO = COW_LIST_OUTPUT_DAYS_ITEMS[key];
        rtn[key] = { output: INFO.default ?? false, days: INFO.days.default };
    });
    return rtn as ICowListOutput;
}

export const SelectOutputPopup: React.FC<SelectOutputPopupProps> = (props) => {
    const [ outputs, setOutputs ] = useState(buildDefaultOutputs());

    useEffect(() => {
        setOutputs(props.outputs);

    }, [ props.outputs ]);

    const MODAL_ID = "select-output-popup";

    const onGroupTermChange = (key: CowListOutputGroupKey, st: Date | undefined, ed: Date | undefined) => {
        const from = st == null ? undefined : moment(st).format("YYYY-MM-DD");
        const to = ed == null ? undefined : moment(ed).format("YYYY-MM-DD");

        const newOutputs = { ...outputs };
        newOutputs[key] = { from, to };

        setOutputs(newOutputs);
    }

    const renderBoolItemGroup = (groupKey: CowListOutputGroupKey, keys: CowListOutputBoolKey[]) => {
        const anyChecked = keys.some(k => outputs[k] === true);

        const stDate = outputs[groupKey]?.from == null ? undefined : moment(outputs[groupKey]!.from).toDate();
        const edDate = outputs[groupKey]?.to == null ? undefined : moment(outputs[groupKey]!.to).toDate();

        const group = COW_LIST_OUTPUT_GROUPS[groupKey];

        return (
            <div key={group.name}>
                <label className={styles["output-group-label"]}>{group.name}</label>
                <div className={styles["output-group"]}>
                    <div className={styles["output-group-items"]}>
                        { keys.map(k => renderBoolItem(k))}
                    </div>
                    { group.hasTerm && (<>
                        <OptionalTermSelector
                            label="期間"
                            portalContainerID={MODAL_ID}
                            edPlacement="top"
                            stValue={stDate}
                            edValue={edDate}
                            onChange={(st,ed) => onGroupTermChange(groupKey, st, ed)}
                            disabled={!anyChecked}
                        />
                    </>)}
                </div>
            </div>
        )
    }

    const renderBoolItem = (key: CowListOutputBoolKey) => {
        const checked = outputs[key];
        const ITEM_INFO = COW_LIST_OUTPUT_BOOL_ITEMS[key];

        return (
            <Checkbox
                className={styles["output-bool-check"]}
                key={key}
                label={ITEM_INFO.header}
                id={"chk_" + key}
                checked={checked}
                onChange={e => {
                    const newVals = {...outputs};
                    newVals[key] = e.target.checked;
                    setOutputs(newVals);
                }}
            />
        )        
    }

    const renderDaysItem = (key: CowListOutputDaysKey) => {
        const item = outputs[key];
        const ITEM_INFO = COW_LIST_OUTPUT_DAYS_ITEMS[key];

        return (
            <div className={"form-group " + styles["output-row"]} key={key} data-testid={"item__" + ITEM_INFO.header}>
                <Checkbox
                    label={ITEM_INFO.header}
                    id={"chk_" + key}
                    checked={item.output}
                    onChange={e => {
                        const newVals = {...outputs};
                        newVals[key] = { ...item, output: e.target.checked };
                        setOutputs(newVals);
                    }}
                />
                <span>{ITEM_INFO.direction === "past" ? "：過去" : "：今日から"}</span>
                <RequiredNumInput className={styles["output-days"]}
                    disabled={!item.output}
                    value={item.days} min={1} max={ITEM_INFO.days.max} step={1}
                    onChange={val => {
                        const newVals = {...outputs};
                        newVals[key] = { ...item, days:val };
                        setOutputs(newVals);
                    }}
                />
                <span>日分</span>
            </div>
        )        
    }

    const canRenderGroup = (groupKey: CowListOutputGroupKey) => {
        const group = COW_LIST_OUTPUT_GROUPS[groupKey];
        if (group.isBreeding && !props.hasBreedingContract) return false;
        if (group.needsBalanceAuth && !props.hasCowBalanceAuth) return false;
        return true;
    }

    return (
        <div>
            <Modal isOpen={true} style={{ maxWidth:"460px" }} id={MODAL_ID} scrollable={true}>
                <ModalHeader toggle={props.onClose}>表示項目選択</ModalHeader>
                <ModalBody>
                    <div>
                        { [...CommonUtil.groupBy(COW_LIST_OUTPUT_BOOL_KEYS.map(k => ({ key: k, group: COW_LIST_OUTPUT_BOOL_ITEMS[k].group })), a => a.group)
                            .values()]
                            .filter(items => canRenderGroup(items[0].group))
                            .map(items => renderBoolItemGroup(items[0].group, items.map(i => i.key)))
                        }
                        { COW_LIST_OUTPUT_DAYS_KEYS.map(key => renderDaysItem(key) )}
                    </div>
                </ModalBody>
                <ModalFooter className="modal-footer-fix">
                    <ExecutionButton type="decide" onClick={() => props.onSubmit(outputs)} />
                </ModalFooter>
            </Modal>
        </div>
    )
}