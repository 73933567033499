import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { A, LMT } from '../../config/constant';
import { Communicator } from '../../api/communicator';
import { ExtraCowDto } from '../../api';
import { AncestorSelect } from '../setting/ancestor-select';
import { SettingAncestorPopup } from '../setting/setting-ancestor-popup';
import { CowToDispInfo } from '../../components/parts/cows-popup';
import { usePageStore } from '../../config/page-settings';
import { AppState } from '../../app';
import { useAncestors } from '../../stores/fetcher';
import { FetchWaiter, FetchError } from '../../components/content/fetch-state';
import { ExecutionButton } from '../../components/buttons/execution-button';
import { useCow } from 'stores/fetcher_cow';

interface MyProps {
    father: ExtraCowDto;
    mother_id: number;
    get_from: string;
    ranch_id: number;
    onSubmit: (data: {
        father_exid: number,
        ancestor_2_exid: number | undefined,
        ancestor_3_exid: number | undefined,
        ancestor_4_exid: number | undefined,
        get_from: string
    })=>void;
    onClose: ()=>void;
    comm: Communicator;
    isSubmitExecuting: boolean;
}

export const AddSeedPopup = (props: MyProps) => {
    const [ ancestor_2, setAncestor_2 ] = useState<ExtraCowDto>();
    const [ ancestor_3, setAncestor_3 ] = useState<ExtraCowDto>();
    const [ ancestor_4, setAncestor_4 ] = useState<ExtraCowDto>();
    const [ getFrom, setGetFrom ] = useState("");
    const [ isAncestorPopupShown, setIsAncestorPopupShown ] = useState(false);

    const { showToast, showQuestionAsync } = usePageStore() as AppState;

    const mAncestors = useAncestors(props.ranch_id);
    const mother = useCow(props.ranch_id, props.mother_id);

    useEffect(() => {
        if (mother.data == null) return;

        const toExCow = (id: number | undefined, name: string | undefined) => id == null ? undefined : ({ ex_cow_id: id, name: name ?? "" });

        setAncestor_2(toExCow(mother.data.ancestor_1_exid, mother.data.ancestor_1));
        setAncestor_3(toExCow(mother.data.ancestor_2_exid, mother.data.ancestor_2));
        setAncestor_4(toExCow(mother.data.ancestor_3_exid, mother.data.ancestor_3));

    }, [ mother.data ]);
    
    useEffect(() => {
        setGetFrom(props.get_from);
    }, [ props.get_from ]);

    const onSubmit = () => {
        if (getFrom === "") {
            showToast(A.MESSAGE.NO_SEED_GET_FROM_INPUT);
            return;
        }

        props.onSubmit({
            father_exid: props.father.ex_cow_id,
            ancestor_2_exid: ancestor_2?.ex_cow_id,
            ancestor_3_exid: ancestor_3?.ex_cow_id,
            ancestor_4_exid: ancestor_4?.ex_cow_id,
            get_from: getFrom
        });
    }
    
    return (
        <div>
            <Modal isOpen={true} centered={true} scrollable={true}>
                <ModalHeader toggle={props.onClose}>
                    受精卵の血統を追加
                </ModalHeader>
                { (mAncestors.isLoading || mother.isLoading) ? (
                    <FetchWaiter />
                ) : (mAncestors.isError || mAncestors.data == null || mother.isError || mother.data == null) ? (
                    <FetchError />
                ) : (<>
                    <ModalBody>
                        <div className="form-group" style={{display:"flex"}}>
                            <label className="col-form-label" style={{width:"60px", flexShrink:0}}>一代祖</label>
                            <input className="form-control" type="text" value={props.father.name}
                                disabled={true} data-testid="ancestor_1" />
                        </div>
                        <div className="form-group" style={{display:"flex"}}>
                            <label className="col-form-label" style={{width:"60px", flexShrink:0}}>母牛</label>
                            <input className="form-control" type="text" value={(mother.data.name ?? "") === "" ? CowToDispInfo(mother.data, false) : mother.data.name}
                                disabled={true} data-testid="mother_name" />
                        </div>
                        <div className="form-group" style={{display:"flex"}}>
                            <label className="col-form-label" style={{width:"60px", flexShrink:0}}>二代祖</label>
                            <AncestorSelect testId="ancestor_2"
                                list={mAncestors.data}
                                value={ancestor_2?.ex_cow_id}
                                selectedName={ancestor_2?.name}
                                onChange={(id,n) => setAncestor_2(id == null ? undefined : { ex_cow_id:id, name:n ?? "" })}
                                onEditClick={() => setIsAncestorPopupShown(true)}
                            />
                        </div>
                        <div className="form-group" style={{display:"flex"}}>
                            <label className="col-form-label" style={{width:"60px", flexShrink:0}}>三代祖</label>
                            <AncestorSelect testId="ancestor_3"
                                list={mAncestors.data}
                                value={ancestor_3?.ex_cow_id}
                                selectedName={ancestor_3?.name}
                                onChange={(id,n) => setAncestor_3(id == null ? undefined : { ex_cow_id:id, name:n ?? "" })}
                                onEditClick={() => setIsAncestorPopupShown(true)}
                            />
                        </div>
                        <div className="form-group" style={{display:"flex"}}>
                            <label className="col-form-label" style={{width:"60px", flexShrink:0}}>四代祖</label>
                            <AncestorSelect testId="ancestor_4"
                                list={mAncestors.data}
                                value={ancestor_4?.ex_cow_id}
                                selectedName={ancestor_4?.name}
                                onChange={(id,n) => setAncestor_4(id == null ? undefined : { ex_cow_id:id, name:n ?? "" })}
                                onEditClick={() => setIsAncestorPopupShown(true)}
                            />
                        </div>
                        <div className="form-group" style={{display:"flex"}}>
                            <label className="col-form-label" style={{width:"60px", flexShrink:0}}>入手先</label>
                            <input className="form-control" type="text" value={getFrom} data-testid="get_from"
                                maxLength={LMT.SEED.GET_FROM_LEN}
                                onChange={e => setGetFrom(e.target.value)}/>
                        </div>
                    </ModalBody>
                    <ModalFooter className="modal-footer-fix">
                        <ExecutionButton type="save" onClick={onSubmit} disabled={props.isSubmitExecuting} />
                    </ModalFooter>
                    { isAncestorPopupShown && (
                        <SettingAncestorPopup
                            comm={props.comm}
                            confirm={showQuestionAsync}
                            list={mAncestors.data}
                            onClose={() => setIsAncestorPopupShown(false)}
                            teamId={props.ranch_id}
                        />
                    )}
                </>)}
            </Modal>
        </div>
    )
}