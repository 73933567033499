import React, { useState, useEffect } from 'react';

import { OptionalInputContainer } from '../../components/parts/optional-input-container';
import { BREEDING_STATE, BREEDING_STATES } from '../../config/constant';
import { CommonUtil } from '../../config/util';
import { BreedingStateSelector } from '../../components/parts/breeding-state-selector';

export interface BreedingStateProps {
    isSelected: boolean;
    state: number;
    openday: Date;
    onChange: (selected: boolean, value: number, openday: Date) => void;
    portalContainerIDForDatePicker?: string;
}

export const hasOpenDay = (state:number):boolean => {
    const stateKey = BREEDING_STATES.find(k => BREEDING_STATE[k].no === state);
    if( stateKey == null ) return false;
    return BREEDING_STATE[stateKey].hasOpenDay;
}

export const BreedingState: React.FC<BreedingStateProps> = (props) => {
    const [ currentState, setCurrentState ] = useState(BREEDING_STATE.OPEN.no);
    const [ currentOpenDay, setCurrentOpenDay ] = useState(new Date());

    useEffect(() => {
        setCurrentState(props.state);
    }, [ props.state ]);

    useEffect(() => {
        setCurrentOpenDay(props.openday);
    }, [ props.openday ]);

    const onChange = (state: number, openday: Date) => {
        const stateKey = BREEDING_STATES.find(k => BREEDING_STATE[k].no === state);
        if (!CommonUtil.assertNotNull(stateKey, "breedingState " + state, "onChange")) return;
        setCurrentState(state);
        setCurrentOpenDay(openday);
        props.onChange(true, state, openday);
    }

    return (<>
        <div style={{marginLeft:"8px"}}>
            <div className="checkbox checkbox-css" style={{marginBottom:"4px"}}>
                <input type="checkbox" id={`chkBreedingState`} onChange={e => props.onChange(e.target.checked, currentState, currentOpenDay)} 
                    checked={props.isSelected}/>
                <label htmlFor={`chkBreedingState`}>繁殖状態変更</label>
            </div>
            <OptionalInputContainer isSelected={props.isSelected} containerStyle={{display:"flex", flexDirection:"column"}}>
                <div style={{background:props.isSelected ? "transparent" : "#e5e5e5"}}>
                    <BreedingStateSelector openday={currentOpenDay} state={currentState}
                                    portalContainerID={props.portalContainerIDForDatePicker}
                                    onChange={onChange} />
                </div>
            </OptionalInputContainer>
        </div>
    </>)
}