import React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

import { withContext } from '../../stores';
import Base, { BaseProps } from '../../components/content/base';
import { PageSettings } from '../../config/page-settings';
import { IEventWriteLocationState, historyLocation } from '../../config/history-location-builder';
import { AppState } from '../../app';
import { ICowNameInfo } from '../../components/parts/cows-popup';
import { SellCowModifyReq, SellCowModifyReqCow, SellCowCarcassDto, SellCowDto, SellCowApi, GenericResponseSellCowDto, SellCowSearchReq, PartnerDto, PartnerListReq, TeamApi, GrowthBirthWeightDto, GrowthApi } from '../../api';
import { AxiosResponse } from 'axios';
import { CommonUtil, formatYen } from '../../config/util';
import styles from './sellcow.module.css';
import clsx from 'classnames';
import { CarcassInput } from './carcass-input';
import { Collapse } from 'reactstrap';
import { A, CARCASS_GRADES, LMT, TIMEPRESETS, findSellTypeName } from '../../config/constant';
import { Checkbox } from '../../components/form/form-checkbox';
import { RequiredNumInput, OptionalNumInput } from '../../components/parts/num-input';
import { ScheduleFinder } from '../schedule/schedule-finder';
import { EVENT_KIND } from '../../config/event-kind';
import { V3DateTime } from '../../components/parts/v3-date-time-picker';
import { SettingPartnerPopup } from './setting-partner-popup';
import { DgViewer } from './dg-viewer';
import { CommonSelect } from '../../components/parts/common-select';
import { getCowWithCache, resetCow } from 'stores/fetcher_cow';
import { SingleCowHeader } from 'components/cow-header/single-cow-header';

export type EditingSellCowData = Omit<SellCowModifyReqCow, "carcass"|"cow_id"|"price"> & {
    price_not_carcass: number;
}
export type EditingCarcass = Omit<SellCowModifyReqCarcass, "total_grade"|"is_high"> & {
    total_grade?: string;
}

type SellCowModifyReqCarcass = SellCowCarcassDto;
type SellCowDetail = SellCowDto;

type ICow = ICowNameInfo & { breed_no: number | undefined, sell_type: number | undefined };

interface MyState {
    ranch_id?: number;
    original_id?: number;
    original?: Readonly<SellCowDetail>;
    cow?: Readonly<ICow>;
    data: Readonly<EditingSellCowData>;
    carcass: Readonly<EditingCarcass>;
    hasCarcass: boolean;
    isDisuse: boolean;
    watched_at: Date;
    isCarcassExpand: boolean;
    executing: boolean;
    partners: Readonly<PartnerDto[]>;
    partner_no?: number;
    isPartnerPopupShown: boolean;
    birth: Omit<GrowthBirthWeightDto, "cow_id">;
}

class SellCowSingle extends Base<BaseProps<{id:string},{},IEventWriteLocationState|undefined>, MyState> {

    static contextType = PageSettings;
    context!: AppState;

    constructor(props) {
        super(props);

        this.state = {
            watched_at: new Date(),
            data: { event_id:undefined, comment:"", deactivate:0, price_not_carcass:0 },
            carcass: { amount: 0, defect: 0, reward:"", unit_price:0 },
            hasCarcass: false,
            isDisuse: false,
            isCarcassExpand: false,
            executing: false,
            partners: [],
            isPartnerPopupShown: false,
            birth: {}
        };

        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    private getParamCowId(props: this["props"]) {
        return new URLSearchParams(props.location.search).get("param");
    }

    componentDidMount() {

        this.context.handleSetPageError(false);
        this.context.handleSetFooter(true);

        this.init();
    }
    componentDidUpdate(prevProps: this["props"], prevState: MyState) {
        if (this.props.match.params.id !== prevProps.match.params.id
            || this.getParamCowId(this.props) !== this.getParamCowId(prevProps)) {
            this.init();
        }
    }

    async init() {
        const ranchId = this.props.rootStore.cur_ranch_id;
        if (ranchId === 0) {
            this.setState({ranch_id:undefined});
            return;
        }
        if (this.handleNotAllowAccess(ranchId, ["BALANCE_COW"], [])) {
            return;
        }

        const mayId = parseInt(this.props.match.params.id);
        const oriId = isNaN(mayId) ? undefined : mayId;
        if (oriId == null) {
            this.context.handleSetHeader({ title:"個体販売記録を入力" });
        }
        else {
            this.context.handleSetHeader({ title:"個体販売記録を編集" });
        }

        //※複数頭一括入力には対応しない
        //　（カンマ区切りの牛IDでは先頭のみ対象になる）
        const mayCowId = parseInt(this.getParamCowId(this.props) ?? "");
        let cowId = isNaN(mayCowId) ? undefined : mayCowId;

        let failed = false;
        let original: SellCowDetail|undefined;
        let cowInfo: { cow:ICow, birth: GrowthBirthWeightDto }|undefined;

        this.context.handleSetIsLoading(true);
        const partners = await this.loadPartners(ranchId);

        if (oriId != null) {
            original = await this.loadOriginal(oriId);
            if (original == null) {
                failed = true;
            } else if (cowId != null && original.cow_id !== cowId) {
                console.error("cowId unmatch", cowId, original);
                failed = true;
            } else if (cowId == null) {
                cowId = original.cow_id;
            }
        }
        if (!failed && original == null && cowId != null) {
            const list = await this.loadOriginalByCowId(ranchId, cowId);
            if (list == null) {
                failed = true;
            } else if (list.length === 1) {
                original = list[0];
            }
        }
        if (!failed && cowId != null) {
            cowInfo = await this.loadCowInfo(ranchId, cowId);
        }
        this.context.handleSetIsLoading(false);

        if (failed || cowInfo == null) {
            await this.setStateAsync({
                ranch_id: ranchId,
                original_id: oriId,
                original: undefined,
                cow: undefined,
                birth: {},
                partners
            });
            this.context.showToast("データ取得に失敗しました。");
            return;
        }

        //※個体販売の場合、「編集」からの遷移でなくても、牛に対する記録が存在するときは
        //  実質編集となるため、EventKind.SELF_EDIT_ONLYのチェックは省略

        await this.setStateAsync({
            ranch_id: ranchId,
            original_id: oriId,
            original: original,
            cow: cowInfo.cow,
            birth: cowInfo.birth,
            watched_at: (original != null)
                ? moment(original.watched_at).toDate()
                : new Date(),
            partners,
            partner_no: original?.partner_no,
            data: (original != null) ? { ...original, deactivate: 0, price_not_carcass: original.price } : this.state.data,
            carcass: original?.carcass ?? { unit_price: 0, reward: "", defect: 0, amount: 0 },
            hasCarcass: original?.carcass != null,
            isDisuse: original?.is_disuse === 1,
        });
    }

    async loadPartners(ranch_id: number) {
        const req: PartnerListReq = {
            is_client: 1,
            is_supplier: 0,
            team_id: ranch_id
        };
        const res = await this.comm().send((await TeamApi()).listPartnersUsingPOST(req), { retries: true });
        return res.data ?? [];
    }
    async loadOriginal(id: number): Promise<SellCowDetail | undefined> {
        const res = await this.comm().send(() => this.apiLoadOriginal(id), { retries: true, excludedErrCodes:[ A.ERR_CODE.DELETED] });
        return res.data;
    }
    async apiLoadOriginal(id: number): Promise<AxiosResponse<GenericResponseSellCowDto>> {
        return this.context.postAsync("/sell/cow/" + id, {});
    }
    async loadOriginalByCowId(ranchId: number, cowId: number): Promise<SellCowDetail[] | undefined> {
        const req: SellCowSearchReq = { ranch_id: ranchId, cow_ids: [ cowId ]};
        const res = await this.comm().send((await SellCowApi()).getSellCowUsingPOST(req), { retries: true });
        return res.data;
    }

    async loadCowInfo(ranch_id: number, cow_id: number): Promise<{ cow:ICow, birth: GrowthBirthWeightDto } | undefined> {

        const res = await this.comm().send(() => getCowWithCache(ranch_id, cow_id), { retries: true });
        if (res.data == null) return undefined;

        const useList = this.props.rootStore.options.cow_use;
        const sellType = res.data.use_no == null ? undefined : useList.find(u => u.use_no === res.data!.use_no)?.sell_type;

        const cow = { ...res.data, breed_no: res.data.breed_no, sell_type: sellType };

        const resBirth = await this.comm().send((await GrowthApi()).getBirthWeightUsingPOST({ ranch_id, cow_ids: [ cow_id ]}), { retries: true});
        if (resBirth.data == null) return undefined;
        const birth = resBirth.data.length === 0 ? { cow_id } : resBirth.data[0];
        return { cow, birth };
    }

    onHasCarcassChange(hasCarcass: boolean) {
        this.setState({ hasCarcass });
    }

    async onSave() {
        if (!CommonUtil.assertNotNull(this.state.ranch_id)) return;
        if (!CommonUtil.assertNotNull(this.state.cow)) return;

        //validate
        if (this.state.hasCarcass && this.state.carcass.total_grade == null) {
            this.context.showToast(A.MESSAGE.SELLCOW_GRADE_UNSELECTED);
            return;
        }
        if (this.state.hasCarcass && this.state.cow.breed_no == null) {
            const confirmed = await this.context.showQuestionAsync(A.MESSAGE.SELLCOW_NO_BREED_NO, ["OK", "キャンセル"]) === 0;
            if (!confirmed) return;
        }

        try {
            this.setState({ executing: true });

            const req: SellCowModifyReq = {
                ranch_id: this.state.ranch_id,
                is_disuse: this.state.isDisuse ? 1 : 0,
                cows: [
                    {
                        ...this.state.data,
                        event_id: this.state.original?.event_id,    //記録済みのデータがあるときは種別に関わらずevent_idを流用
                        price: this.state.hasCarcass ? Math.round(this.state.carcass.amount * this.state.carcass.unit_price) : this.state.data.price_not_carcass,
                        carcass: this.state.hasCarcass ? { ...this.state.carcass, total_grade: this.state.carcass.total_grade! } : undefined,
                        cow_id: this.state.cow?.cow_id
                    }
                ],
                watched_at: moment(this.state.watched_at).format("YYYY-MM-DD HH:mm") + ":00",
                partner_no: this.state.partner_no,
                schedule_id: this.props.location.state?.schedule_id
            };
            //関連する予定を探す
            if (req.schedule_id == null && req.cows[0].event_id == null) {
                const day = moment(req.watched_at).format("YYYY-MM-DD");

                const finder = new ScheduleFinder(this.context, req.ranch_id, this.props.rootStore.user.id);
                const scheRes = await finder.findEventRelatedSchedule(day, [ EVENT_KIND.SELL_COW.no ], req.cows[0].cow_id);

                if (scheRes.result === "cancel") return;

                if (scheRes.result === "yes") {
                    req.schedule_id = scheRes.id;
                }
            }

            this.context.handleSetIsLoading(true);
            const res = await this.comm().send((await SellCowApi()).modifySellCowUsingPOST(req));
            this.context.handleSetIsLoading(false);
            if (res.result !== "OK") return;

            //キャッシュリセット
            resetCow(req.cows[0].cow_id, false);
            
            this.props.history.replace(historyLocation.toCowInfo(req.cows[0].cow_id));
            
        } finally {
            this.setState({ executing: false });
        }
    }
    async onDelete() {
        const ranchId = this.state.ranch_id;
        const cowId = this.state.cow?.cow_id;
        const oriId = this.state.original_id;
        if (!CommonUtil.assertNotNull(ranchId)
            || !CommonUtil.assertNotNull(oriId)
            || !CommonUtil.assertNotNull(cowId)) {
            return;
        }

        const confirmed = (await this.context.showQuestionAsync("この記録を削除してよろしいですか？", ["削除", "キャンセル"])) === 0;
        if (!confirmed) return;

        this.context.handleSetIsLoading(true);
        const res = await this.comm().send((await SellCowApi()).deleteUsingPOST5({ ranch_id: ranchId, cow_id: cowId, event_id: oriId }));
        this.context.handleSetIsLoading(false);
        if (res.result !== "OK") return;

        this.props.history.replace(historyLocation.toCowInfo(cowId));
    }

    render() {
        if (this.state.ranch_id == null) {
            return <></>;
        }
        const cow = this.state.cow;
        if (cow == null) return <></>;

        const rowClass = "form-group row treat-write-row";
        const rowHeaderClass = "col-form-label col-md-4 col-xs-4 text-lg-right";
        const collapseHeaderClass = "col-form-label col-md-4 col-xs-6 clickable";
        const collapseArrowClass = (isOpen : boolean) => "fas fa-lg fa-fw " + (isOpen ? "fa-angle-up" : "fa-angle-down");

        const data = this.state.data;
        const carcass = this.state.carcass;

        const sellTypeName = cow.sell_type == null ? undefined : findSellTypeName(cow.sell_type);
        const isValidCow = sellTypeName != null;

        return (
            <div style={{ height: "100%" }}>
                <div className="product product-with-footer">
                    <div className="product-detail" style={{ height: "100%" }}>
                        <div className="product-info product-info-fix p-b-0">
                            <div className="product-info-header">
                                <SingleCowHeader ranchId={this.state.ranch_id} cowId={cow.cow_id} />
                            </div>
                            
                            { isValidCow ? (
                                <div className={styles.content}>
                                    <div className={rowClass}>
                                        <span className={clsx(styles["selltype-label"], styles.single)}>{sellTypeName}</span>
                                        <Checkbox label="廃用/病畜" id="chkDisuse"
                                            className="m-r-15"
                                            checked={this.state.isDisuse}
                                            onChange={e => this.setState({ isDisuse: e.target.checked })}
                                        />
                                        <Checkbox label="枝肉あり" id="chkCarcass"
                                            checked={this.state.hasCarcass}
                                            onChange={e => this.onHasCarcassChange(e.target.checked)}
                                        />
                                    </div>
                                    <hr />
                                    <div className={rowClass}>
                                        <label className={rowHeaderClass}>販売日時</label>
                                        <div style={{ paddingLeft:0, flex:1 }}>
                                            <V3DateTime value={this.state.watched_at}
                                                timePresets={this.props.rootStore.user.setting?.time_preset ?? TIMEPRESETS}
                                                onChange={d => this.setState({ watched_at: d.toDate() })} />
                                        </div>
                                    </div>
                                    <div className={rowClass}>
                                        <label className={rowHeaderClass}>出荷先</label>
                                        <div className={styles.partner}>
                                            <CommonSelect
                                                className={styles["partner-select"]}
                                                value={this.state.partner_no}
                                                options={this.state.partners.map(p => ({ label:p.name, value:p.partner_no }))}
                                                onClear={() => this.setState({ partner_no: undefined })}
                                                onSelect={val => this.setState({ partner_no:val })}
                                                onCreate={undefined}
                                            />
                                            <div className="link" onClick={() => this.setState({ isPartnerPopupShown:true })}>編集</div>
                                        </div>
                                    </div>
                                    <div className={rowClass}>
                                        <label className={rowHeaderClass}>出荷体重</label>
                                        <div className={styles["col-input-wrapper"]}>
                                            <div className={styles["col-input-num"]}>
                                                <OptionalNumInput
                                                    min={LMT.GROWTH.WEIGHT_MIN} max={LMT.GROWTH.WEIGHT_MAX} step={LMT.GROWTH.WEIGHT_STEP}
                                                    onChange={n => this.setState({ data: { ...this.state.data, weight: n } })}
                                                    value={this.state.data.weight}
                                                />kg
                                            </div>
                                            <DgViewer
                                                stDate={this.state.birth.birthday}
                                                stScore={this.state.birth.actual ?? this.state.birth.standard}
                                                isStandardScoreUsed={this.state.birth.actual == null}
                                                edDate={this.state.watched_at}
                                                edScore={this.state.data.weight}
                                            />
                                        </div>                                        
                                    </div>
                                    { this.state.hasCarcass && (<>
                                        <div className={rowClass}>
                                            <label className={rowHeaderClass}>格付</label>
                                            <div>
                                                <select className="form-control" value={carcass.total_grade} onChange={e => { this.setState({carcass: {...carcass, total_grade: e.target.value === "" ? undefined : e.target.value }})}}>
                                                    <option value="">未選択</option>
                                                    { CARCASS_GRADES.map((c,i) => (
                                                        <option key={i} value={c}>{c}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className={rowClass}>
                                            <label className={rowHeaderClass}>枝肉重量</label>
                                            <div className={styles["col-input-num"]}>
                                                <RequiredNumInput
                                                    min={LMT.SELLCOW.WEIGHT_MIN} max={LMT.SELLCOW.WEIGHT_MAX} step={LMT.SELLCOW.WEIGHT_STEP}
                                                    onChange={n => this.setState({ carcass: { ...carcass, amount: n }})}
                                                    value={carcass.amount}
                                                />kg
                                            </div>
                                        </div>
                                        <div className={rowClass}>
                                            <label className={rowHeaderClass}>販売単価</label>
                                            <div className={styles["col-input-num"]}>
                                                <RequiredNumInput
                                                    min={LMT.SELLCOW.UNIT_PRICE_MIN} max={LMT.SELLCOW.UNIT_PRICE_MAX} step={LMT.SELLCOW.UNIT_PRICE_STEP}
                                                    onChange={n => this.setState({ carcass: { ...carcass, unit_price: n }})}
                                                    value={carcass.unit_price}
                                                />円/kg
                                            </div>
                                        </div>
                                        <div className={rowClass}>
                                            <label className={rowHeaderClass}>販売価格</label>
                                            <div className={styles["col-input-num"]}>{formatYen(Math.round(carcass.amount * carcass.unit_price))} 円</div>
                                        </div>
                                    </>) }
                                    { !this.state.hasCarcass && (
                                        <div className={rowClass}>
                                            <label className={rowHeaderClass}>販売価格</label>
                                            <div className={styles["col-input-num"]}>
                                                <RequiredNumInput
                                                    min={LMT.SELLCOW.PRICE_MIN} max={LMT.SELLCOW.PRICE_MAX} step={LMT.SELLCOW.PRICE_STEP}
                                                    onChange={n => this.setState({ data: { ...data, price_not_carcass: n }})}
                                                    value={data.price_not_carcass}
                                                />円
                                            </div>
                                        </div>
                                    )}
                                    <div className={rowClass}>
                                        <label className={rowHeaderClass}>飼養終了</label>
                                        <Checkbox
                                            id="chkDeactivate"
                                            checked={data.deactivate === 1}
                                            onChange={e => {this.setState({ data: { ...data, deactivate:e.target.checked ? 1 : 0 }})}}
                                            label="飼養終了する"
                                        />
                                    </div>
                                    { this.state.hasCarcass && (<>
                                        <div className={rowClass}>
                                            <div className={collapseHeaderClass}
                                                onClick={() => this.setState({isCarcassExpand: !this.state.isCarcassExpand})}>
                                                <i className={collapseArrowClass(this.state.isCarcassExpand)}></i>
                                                <span> 枝肉成績詳細</span>
                                            </div>
                                        </div>
    
                                        <Collapse isOpen={this.state.isCarcassExpand}>
                                            <div style={{marginLeft:"14px"}}>
                                                <CarcassInput
                                                    data={carcass}
                                                    onChange={d => this.setState({ carcass: d })}
                                                />
                                            </div>
                                        </Collapse>
                                    </>)}
    
                                    <div className={rowClass}>
                                        <label className="col-form-label col-md-2 col-xs-2 text-lg-right">メモ</label>
                                        <div className="col-md-10 col-xs-10">
                                            <textarea className="form-control" rows={4} maxLength={LMT.SELLCOW.MEMO_LEN}
                                                    value={this.state.data.comment} onChange={(e) => this.setState({data:{...this.state.data, comment:e.target.value}})}></textarea>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div>個体販売を記録するには、牛の用途を設定する必要があります</div>
                            )}
                        </div>
                    </div>

                    { isValidCow && (
                        <div className="content-footer page-footer">
                            {this.state.original_id == null && (
                                <div className="btn-row" style={{ textAlign: "center" }}>
                                    <button type="button" className="btn btn-green m-r-5" onClick={this.onSave} disabled={this.state.executing}>保存</button>
                                </div>
                            )}
                            {this.state.original_id != null && (
                                <div className="btn-row">
                                    <button className="btn btn-theme btn-sm btn-success m-r-5" onClick={this.onSave} disabled={this.state.executing}>保存</button>
                                    <button className="btn btn-theme btn-sm btn-danger m-l-5" onClick={this.onDelete} disabled={this.state.executing}>削除</button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                { this.state.isPartnerPopupShown && (
                    <SettingPartnerPopup
                        partnerTypeName="出荷先"
                        onClose={() => this.setState({ isPartnerPopupShown: false })}
                        partners={this.state.partners}
                        comm={this.comm()}
                        onUpdated={p => this.setState({ partners: p})}
                        partnerType="client"
                        ranchId={this.state.ranch_id}
                        confirm={this.context.showQuestionAsync}
                    />
                )}
            </div>
        )
    }
}

export default withRouter(withContext(SellCowSingle));

