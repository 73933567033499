import React, { useRef, useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { formatYen, saveTextFile, averageNum, roundDg, FreezedArray, ar, buildCsvRow } from '../../config/util';
import { SellCowAggregateCarcassDto } from '../../api';
import { Table } from "reactstrap";
import styles from './report-sellcow.module.css';
import { isBrowser } from 'react-device-detect';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import { CowToDispInfo } from '../../components/parts/cows-popup';
import { DEFECTS, hasDefect, A } from '../../config/constant';
import { IconLink } from 'components/parts/icon-link';
import { CheckboxPopup } from 'components/parts/checkbox-popup';

type Carcass = SellCowAggregateCarcassDto;

interface MyProps {
    onClose: () => void;
    name: string;
    carcasses: FreezedArray<SellCowAggregateCarcassDto>;
    term: { from: Date, to: Date } | undefined;
    onCowLinkClick: (cow_id:number) => void;
    dispItemKeys: Set<ReportCarcassItemKey> | undefined;
    onKeysUpdated: (keys: Set<ReportCarcassItemKey>) => void;
}

const ITEM_KEYS = [
    "watched_at","rep_no","trace_id","is_male","ancestors","sell_age","weight","dg","price","total_grade","is_high","amount","unit_price","yield_loin","yield_rib","yield_fat","yield_basis","quality_bms","quality_bcs","quality_texture","quality_fat","oleic_acid","defect","reward","comment"
] as const;
export type ReportCarcassItemKey = typeof ITEM_KEYS[number];
type RowData = { [key in ReportCarcassItemKey]: number|string };

const ITEMS: {[key in ReportCarcassItemKey]: { header:string, isCowLink?:boolean, required?:boolean }} = {
    watched_at: { header:"出荷日" },
    //平均の行で「平均」と記載するのに使っていて消すと微妙なので必須としておく
    rep_no: { header:"耳標", isCowLink:true, required:true },
    trace_id: { header:"ID" },
    is_male: { header:"性別" },
    ancestors: { header:"三代祖" },
    sell_age: { header:"出荷月齢" },
    weight: { header:"出荷体重 (kg)" },
    dg: { header:"DG (kg/日)" },
    price: { header:"価格 (円)" },
    total_grade: { header:"格付" },
    is_high: { header:"上物" },
    amount: { header:"重量 (kg)" },
    unit_price: { header:"単価 (円/kg)" },
    yield_loin: { header:"ロース芯面積 (cm2)" },
    yield_rib: { header:"バラ厚さ (cm)" },
    yield_fat: { header:"皮下脂肪 (cm)" },
    yield_basis: { header:"歩留基準" },
    quality_bms: { header:"BMS" },
    quality_bcs: { header:"肉色BCS" },
    quality_texture: { header:"きめ締まり" },
    quality_fat: { header:"脂肪の質" },
    oleic_acid: { header:"オレイン酸含量 (%)" },
    defect: { header:"瑕疵" },
    reward: { header:"褒賞" },
    comment: { header:"メモ" }
};

export const ReportSellCowCarcassPopup = React.memo((props: MyProps) => {
    const [ averageData, setAverageData ] = useState<Carcass>();
    const [ isDispKeySelecting, setIsDispKeySelecting ] = useState(false);

    useEffect(() => {

        if (props.carcasses.length === 0) {
            setAverageData(undefined);
            return;
        }

        const onlyCarcasses = ar.notNull(props.carcasses.map(d => d.carcass));

        const ave: Carcass = {
            watched_at: "",
            cow_id: 0,
            trace_id: "",
            local_no: undefined,
            name: undefined,
            is_male: 0,
            ancestors: [],
            sell_age: averageNum(props.carcasses, d => d.sell_age, 1),
            carcass: onlyCarcasses.length === 0 ? undefined : {
                unit_price: averageNum(onlyCarcasses, d => d.unit_price, 0) ?? 0,
                amount: averageNum(onlyCarcasses, d => d.amount, 1) ?? 0,
                total_grade: "",
                is_high: 0,
                defect: 0,
                reward: "",
                yield_loin: averageNum(onlyCarcasses, d => d.yield_loin, 1),
                yield_rib: averageNum(onlyCarcasses, d => d.yield_rib, 1),
                yield_fat: averageNum(onlyCarcasses, d => d.yield_fat, 1),
                yield_basis: averageNum(onlyCarcasses, d => d.yield_basis, 1),
                quality_bms: averageNum(onlyCarcasses, d => d.quality_bms, 1),
                quality_bcs: averageNum(onlyCarcasses, d => d.quality_bcs, 1),
                quality_texture: averageNum(onlyCarcasses, d => d.quality_texture, 1),
                quality_fat: averageNum(onlyCarcasses, d => d.quality_fat, 1),
                oleic_acid: averageNum(onlyCarcasses, d => d.oleic_acid, 1),
            },
            price: averageNum(props.carcasses, c => c.price, 0) ?? 0,
            weight: averageNum(props.carcasses, d => d.weight, 0),
            dg: averageNum(props.carcasses, d => d.dg, 2),
            comment:""
        };

        setAverageData(ave);

    }, [ props.carcasses ]);

    const refTable = useRef<HTMLDivElement>(null);

    const values = (data: Carcass, forCsv: boolean, isAverage: boolean) : RowData => {
        const carcass = data.carcass;

        const carcassVals = carcass == null ? ({
            total_grade: "",
            is_high: "",
            amount: "",
            unit_price: "",
            yield_loin: "",
            yield_rib: "",
            yield_fat: "",
            yield_basis: "",
            quality_bms: "",
            quality_bcs: "",
            quality_texture: "",
            quality_fat: "",
            oleic_acid: "",
            defect: "",
            reward: "",
        }) : ({
            total_grade: carcass.total_grade,
            is_high: carcass.is_high === 1 ? "●" : "",
            amount: carcass.amount,
            unit_price: forCsv ? carcass.unit_price : formatYen(carcass.unit_price),
            yield_loin: carcass.yield_loin ?? "",
            yield_rib: carcass.yield_rib ?? "",
            yield_fat: carcass.yield_fat ?? "",
            yield_basis: carcass.yield_basis ?? "",
            quality_bms: carcass.quality_bms ?? "",
            quality_bcs: carcass.quality_bcs ?? "",
            quality_texture: carcass.quality_texture ?? "",
            quality_fat: carcass.quality_fat ?? "",
            oleic_acid: carcass.oleic_acid ?? "",
            defect: DEFECTS.filter(d => hasDefect(d, carcass.defect)).join(" "),
            reward: carcass.reward
        });

        return {
            watched_at: isAverage ? "" : moment(data.watched_at).format("YYYY/MM/DD"),
            rep_no: isAverage ? "平均" : CowToDispInfo({ cow_id: data.cow_id, trace_id: data.trace_id, local_no: data.local_no, name: data.name }, false),
            trace_id: isAverage ? "" : data.trace_id,
            is_male: isAverage ? "" : A.GET_SEX_MARK(data.is_male),
            ancestors: data.ancestors.map((a,i) => ({ no:i+1, name:a })).filter(a => a.name !== "").map(a => `${a.no}.${a.name}`).join(" "),
            sell_age: data.sell_age ?? "",
            weight: data.weight ?? "",
            dg: roundDg(data.dg) ?? "",
            price: forCsv ? data.price : formatYen(data.price),
            ...carcassVals,
            comment: data.comment,
        }
    }

    const dispKeys = ITEM_KEYS.filter(k => props.dispItemKeys == null || props.dispItemKeys.has(k) || ITEMS[k].required);

    const onCsvClick = () => {
        const rowCells: Array<string|number>[] = [
            [...dispKeys.map(k => ITEMS[k].header)],
            ...props.carcasses.map(d => {
                const data = values(d, true, false);
                return dispKeys.map(k => data[k]);
            }),
        ];
        if (averageData != null) {
            const vals = values(averageData, true, true);
            rowCells.push(dispKeys.map(k => vals[k]));
        }
        const rows = rowCells.map(r => buildCsvRow(r));

        const term = props.term == null ? moment().format("YYYYMMDD")
                    : `${moment(props.term.from).format("YYYYMMDD")}-${moment(props.term.to).format("YYYYMMDD")}`

        //※props.nameにファイル名にできない文字が含まれる場合は _ に置換される
        const fileName = `出荷成績_${props.name}_${term}.csv`;

        saveTextFile(rows.join("\r\n"), fileName);
    }

    const averageValues = averageData == null ? undefined : values(averageData, false, true);

    return (
        <div>
            <Modal isOpen={true} centered={true} toggle={props.onClose} style={{ maxWidth: "calc(100vw - 60px)", marginLeft:"auto", marginRight:"auto" }}>
                <ModalHeader toggle={props.onClose}>出荷成績：{props.name}</ModalHeader>
                <ModalBody style={{ maxHeight: "calc(100vh - 180px)", display:"flex", flexFlow:"column nowrap" }}>
                    <IconLink iconType="filter" text="表示列の選択" onClick={() => setIsDispKeySelecting(true)} className={styles["carcass-filter-link"]} />
                    <div className={styles["table-container"]}>
                        <Table className={styles.table} innerRef={refTable}>
                            <thead>
                                <tr>
                                    { dispKeys.map(k => <th key={k}>{ITEMS[k].header}</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                { props.carcasses.map(d => ({ ...values(d, false, false), cow_id:d.cow_id })).map(d =>
                                    <tr key={d.cow_id}>
                                        { dispKeys.map(k => ITEMS[k].isCowLink ? (
                                            <td key={k} className="link" onClick={() => props.onCowLinkClick(d.cow_id)}>{d[k]}</td>
                                        ) : (
                                            <td key={k}>{d[k]}</td>
                                        )) }
                                    </tr>
                                )}
                                { averageValues != null && (
                                    <tr className={styles["tr-total"]}>
                                        { dispKeys.map(k => (
                                            <td key={k}>{averageValues[k]}</td>
                                        ))}
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                    
                </ModalBody>
                { isBrowser && (
                    <ModalFooter>
                        <div className={styles["table-footer"]}>
                            <button className="btn btn-orange m-r-5" onClick={onCsvClick}>CSV出力</button>
                            <ReactToPrint
                                trigger={() => <button className="btn btn-orange">印刷</button>}
                                content={() => refTable.current}
                            />
                        </div>
                    </ModalFooter>
                )}
            </Modal>
            { isDispKeySelecting && (
                <CheckboxPopup
                    header="表示列の選択"
                    onClose={() => setIsDispKeySelecting(false)}
                    items={ITEM_KEYS.map((k,i) => ({ value:i, name:ITEMS[k].header })).filter(o => ITEMS[ITEM_KEYS[o.value]].required !== true)}
                    defaultValues={dispKeys.map(k => ITEM_KEYS.indexOf(k))}
                    onSubmit={idxes => {
                        props.onKeysUpdated(new Set(idxes.map(i => ITEM_KEYS[i])));
                        setIsDispKeySelecting(false);
                    }}
                    itemIdPrefix="chkDispKey"
                />
            )}
        </div>
    )
});