import React, { useState } from 'react';
import { CowToDispInfo, LocalNoOrRepNo, RenderFullTraceId } from './cow-to-disp-info';
import { FilterBox } from './filter-box';
import classnames from 'classnames';
import styles from './cow-list.module.css';
import { ICowNameInfo } from './cows-popup';

export type CowListProps = {
    cows      : Readonly<Array<ICowNameInfo>>,
    checkedCowIds: Readonly<Set<number>>,
    setCheckedCowIds: React.Dispatch<React.SetStateAction<Set<number>>>,
    singleSelection?: boolean;

    style?: React.CSSProperties;
}

export const CowList: React.FC<CowListProps> = (props) => {
    const [ filter, setFilter ] = useState("");

    const onClick = (id: number) => {
        if (props.singleSelection) {
            if (props.checkedCowIds.has(id)) return;
            props.setCheckedCowIds(new Set([id]));
            return;
        }

        const newIds = new Set<number>([...props.checkedCowIds.keys()]);
        if (!newIds.delete(id)) {
            newIds.add(id);
        }
        props.setCheckedCowIds(newIds);
    }

    return (
        <div style={{ display: "flex", flexDirection:"column", ...props.style }} data-testid="cow-list">
            <FilterBox value={filter} onChange={val => setFilter(val)} placeholder="耳標・名前で探す" />
            <div className={classnames(styles["list"], "m-t-5")} style={{ flex:1, overflowY:"auto" }}>
                {
                    props.cows
                        .filter(cow => (filter === "" || LocalNoOrRepNo(cow).indexOf(filter) >= 0 || (cow.name ?? "").indexOf(filter) >= 0))
                        .map(cow => (
                        <div key={cow.cow_id} className={classnames(styles["list-item"], { [styles.selected]: props.checkedCowIds.has(cow.cow_id)})}
                            data-testid="cow-list-item">
                            <div className="text-center" style={{ flex: "1", display: "flex" }} onClick={() => onClick(cow.cow_id)}>
                                <span data-testid="cow-disp-no" style={{ width: "60px", lineHeight: "30px", wordBreak:"break-all", margin:"0 4px 0 2px" }}>{ CowToDispInfo(cow, false) }</span>
                                { RenderFullTraceId(cow.trace_id, { lineHeight: "30px" }, { fontSize: "1rem" }) }
                                <span data-testid="cow-name" style={{ flex: "1", lineHeight: "30px" }}>{cow.name}</span>
                            </div>
                        </div>
                    ))
                }
            </div>

        </div>

    )
}