import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import classnames from 'classnames';

export interface CollapseContainerProps {
    isOpen: boolean;
    onIsOpenChange: (isOpen: boolean) => void;
    header: string;
    children: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
    headerStyle?: React.CSSProperties;
    headerClassName?: string;
}

export const CollapseContainer = (props: CollapseContainerProps) => {
    return (
        <div className={props.className} style={props.style}>
            <div className={classnames("clickable", props.headerClassName)}
                style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
                onClick={() => props.onIsOpenChange(!props.isOpen)}>
                <i className={"fas fa-lg fa-fw m-r-5 " + (props.isOpen ? "fa-angle-up" : "fa-angle-down")}></i>
                <span style={props.headerStyle}>{props.header}</span>
            </div>
            <Collapse isOpen={props.isOpen} children={props.children} />
        </div>

    )

}

type AutoCollapseProps = Omit<CollapseContainerProps, "isOpen"|"onIsOpenChange"> & {
    defaultOpen?: boolean;
}

export const AutoCollapse = (props: AutoCollapseProps) => {
    const [ isOpen, setIsOpen ] = useState(props.defaultOpen ?? false);

    return <CollapseContainer {...props} isOpen={isOpen} onIsOpenChange={setIsOpen} />
}