import React, { ReactNode } from "react";
import { IBreeding, IBreedingCross, IDeliveryHis, IDelivery, IRut, IDeliveryCow, IBreedingStructure, CrossHistory, ISovEvent, IIvfEvent, IIvfEggEvent, IOpuEvent, GrowthEventDto } from "./cow-event";
import { FormMultiLineText } from "../../components/form/form-multi-line-text";
import moment from 'moment';
import { A, BREEDING_STRUCTURE_SIDE, BREEDING_STRUCTURE_KINDS, SIDES, BREEDING_STRUCTURE, findBreedingStructure, COW_SELL_TYPE, COW_SELL_TYPES } from "../../config/constant";
import { CommonUtil, FreezedArray, ar, formatDiseaseCode } from "../../config/util";
import { CowToDispInfo } from "../../components/parts/cows-popup";
import { IHisBreeding, IHisRut, IHisDelivery, IHisBreedingCross, IHisDeliveryCow, IHisSymptom, IHisFeed, IHisSellCow, IHisCowSellMilk, IHisGrowth, IHisSov, IHisOpu, IHisIvf, IHisIvfEgg, IEggRankAndCount, IOvumRankAndCount } from "./cow-info";
import OvarianViewer, { initStructures } from "../../components/OvarianStructure/OvarianViewer";
import { InfoPopup } from "../../components/parts/info-popup";
import { IFecesState, IFecesColor, IFeedType, IRanchFeed, ITreatKind } from "../../stores";
import Big from 'big.js';
import { IGrowth } from "../growth/growth-write";
import { CowSearchRutDto, CowSearchDeliveryDto, DeliveryCowDto, CowSearchBreedingDto, TreatEventDto, SympEventDto, CowSearchSymptomPhysicalDto, RutLatestDto } from "../../api";
import { EditingPhisical, calcBreathScore } from "../symptom/symptom-physical-input";
import { getEggTypeName, getEggRankName, getEggStageName, findEggRank, findOvumRank, findEggStage } from "../../config/egg-kind";
import { IconLink } from "../../components/parts/icon-link";
import { calcGrownPercent } from "../egg/egg-rank-counter";
import { DgViewer } from "../sellcow/dg-viewer";

const getAmountWithUnit = (amount: number | null | undefined, treat_item_unit: string | undefined) => {
    if (amount == null) { return ""; }
    return amount + (treat_item_unit ?? "");
}

export const renderTreat = (treat:TreatEventDto, canRefTreatAmount: boolean) => {
    const hasLot = (treat.lot_no ?? "") !== "";
    const hasExpDay = treat.expiring_day != null;

    return (<>
        <div className="row m-t-5" style={{ marginLeft: "0px", marginRight: "0px" }}>
            <span data-testid="treat-content">
                <i className="fas fa-lg fa-fw m-r-5 fa-plus-square"></i>
                {treat.treat_item_name + (canRefTreatAmount ? (" " + getAmountWithUnit(treat.amount, treat.treat_item_unit) + " " + treat.medicine_route_name) : "")}
            </span>
        </div>
        { (hasLot || hasExpDay) && (
            <div className="row m-t-5" style={{ marginRight: "0px", marginLeft:"27px" }} data-testid="vaccine">
                { hasLot && (<span>Lot No.{treat.lot_no}</span>)}
                { hasLot && hasExpDay && (<span>／</span>)}
                { hasExpDay && (<span>有効期限{moment(treat.expiring_day).format("YYYY年M月")}</span>)}
            </div>
        )}
        {((treat.note ?? '') !== '') && (
            <div className="row m-t-5" style={{ marginRight: "0px", marginLeft:"27px" }} data-testid="treat-note">
                <FormMultiLineText>{treat.note}</FormMultiLineText>
            </div>
        )}
    </>);
}

export const getTreatHisStr = (itemName:string|undefined, amount:number|null|undefined, unit:string|undefined, canRefTreatAmount: boolean) => {
    if (itemName == null) return "";
    if (!canRefTreatAmount) {
        return itemName;
    }
    return itemName + " " + getAmountWithUnit(amount, unit);
}

export const renderHeader = (at:string, by:string|undefined, canEdit:boolean, onEdit:()=>void) => (
    <div className="row m-t-10" style={{ marginLeft: "0px", marginRight: "0px" }}>
        <span style={{ width: "80px", lineHeight: "28px", fontWeight: "bold", fontSize: "0.9rem" }} data-testid="time">{moment(at).format("HH:mm")}</span>
        { by != null && (
            <span style={{ lineHeight: "28px", fontSize: "0.9rem" }}>記録者：{by}</span>
        )}
        {
            canEdit && (
                <button type="button" className="btn btn-sm btn-green pull-right m-l-10" onClick={onEdit}>
                    編集
                </button>
            )
        }
    </div>
);

export const renderComment = (comment?:string|null, additionalClass?:string) => {
    if (comment == null || comment === "") return <></>;

    return (
        <div className={"row " + (additionalClass ?? "")} style={{ marginLeft: "0px", marginRight: "0px" }} data-testid="comment">
            <span><i className="fas fa-lg fa-fw m-r-5 fa-comment" style={{ marginTop: "4px" }}></i>{comment}</span>
        </div>
    );
};

const anyVal = (...vals: Array<string|number|undefined>) => {
    return vals.some(v => v != null && v !== "");
}
const convScoreSymbol = (val: number|string|undefined) => {
    if (val == null || typeof(val) === "string") return val;
    
    if (val === 0) return "±";
    if (val < 0) return "-".repeat(val * -1);
    return "+".repeat(val);
}
const buildBreedStrRow = (header: string, ...vals:{ pre?:string, suf?:string, val:number|string|undefined, symbol?:boolean}[]) => {
    return "◆" + header + "："
        + vals.filter(v => v.val != null && v.val !== "")
              .map(v => `${v.pre ?? ""}${v.symbol ? convScoreSymbol(v.val) : v.val}${v.suf ?? ""}`)
              .join(" ");
}

export const renderBreedingInfo = (data: IBreeding, onExportPregCert?:(data:IBreeding) => void) => {
    const rtn: { text:string, link?:ReactNode }[] = [];
    if (anyVal(data.pregnant_score, data.pregnant_note)) {
        const link = onExportPregCert != null && data.pregnant_score === 1
                    ? (<IconLink text="証明書の発行" className="m-l-10" iconType="download" onClick={() => onExportPregCert(data)} />)
                    : undefined;
        rtn.push({
            text: buildBreedStrRow("妊娠鑑定",
                    { val:data.pregnant_score, symbol:true },
                    { val:data.pregnant_note }),
            link
        });
    }
    if (anyVal(data.bcs, data.bcs_note)) {
        rtn.push({ text: buildBreedStrRow("BCS",
        { val:data.bcs },
        { val:data.bcs_note })});
    }
    if (anyVal(data.vulva_blood_score, data.vulva_expand_score, data.vulva_note)) {
        rtn.push({ text: buildBreedStrRow("外陰部",
            { pre:"充血", val:data.vulva_blood_score, symbol:true },
            { pre:"腫脹", val:data.vulva_expand_score, symbol:true },
            { val:data.vulva_note })});
    }
    if (anyVal(data.vagina_tear_score, data.vagina_mucus_score, data.vagina_rut_score, data.vagina_note)) {
        rtn.push({ text: buildBreedStrRow("膣",
            { pre:"裂傷", val:data.vagina_tear_score, symbol:true },
            { pre:"膣粘液", val:data.vagina_mucus_score },
            { pre:"発情粘液", val:data.vagina_rut_score, symbol:true },
            { val:data.vagina_note })});
    }
    if (anyVal(data.canal_tear_score, data.canal_cm, data.canal_note)) {
        rtn.push({ text: buildBreedStrRow("子宮頸管",
            { pre:"裂傷", val:data.canal_tear_score, symbol:true },
            { pre:"径", val:data.canal_cm, suf:"cm" },
            { val:data.canal_note })});
    }
    if (anyVal(data.cornu_l_cm, data.cornu_l_bright_score, data.cornu_l_image_score, data.cornu_l_shrink_score)) {
        rtn.push({ text: buildBreedStrRow("左子宮角",
            { pre:"径", val:data.cornu_l_cm, suf:"cm" },
            { pre:"ｴｺｰ輝度", val:data.cornu_l_bright_score },
            { pre:"ｴｺｰﾌﾘｰ像", val:data.cornu_l_image_score },
            { pre:"子宮収縮", val:data.cornu_l_shrink_score, symbol:true })});
    }
    if (anyVal(data.cornu_r_cm, data.cornu_r_bright_score, data.cornu_r_image_score, data.cornu_r_shrink_score)) {
        rtn.push({ text: buildBreedStrRow("右子宮角",
            { pre:"径", val:data.cornu_r_cm, suf:"cm" },
            { pre:"ｴｺｰ輝度", val:data.cornu_r_bright_score },
            { pre:"ｴｺｰﾌﾘｰ像", val:data.cornu_r_image_score },
            { pre:"子宮収縮", val:data.cornu_r_shrink_score, symbol:true })});
    }
    if (anyVal(data.cornu_note)) {
        rtn.push({ text: data.cornu_note });
    }
    if (anyVal(data.ovary_l_mm, data.ovary_r_mm)) {
        rtn.push({ text: buildBreedStrRow("卵巣",
            { pre:"左", val:data.ovary_l_mm, suf:"mm" },
            { pre:"右", val:data.ovary_r_mm, suf:"mm" })});
    }
    const texts = rtn.map((s,i) => (
        <div data-testid="breeding-score" key={i}>{s.text}{s.link ?? <></>}</div>
    ));
    if (data.structures.length === 0 && data.ovary_note === "") {
        return texts.length > 0 ? texts : <div>診察項目なし</div>;
    }

    const structures = initStructures();
    SIDES.forEach(side => {
        const oneSideData = data.structures.filter(s => s.side === BREEDING_STRUCTURE_SIDE[side]);
        BREEDING_STRUCTURE_KINDS.forEach(kind => {
            const strData = oneSideData.find(d => d.item_kind === BREEDING_STRUCTURE[kind].no);
            if (strData != null) {
                structures[side][kind] = strData.item_count;
            }
        })
    });
    const ovaryInfo = buildOvaryStructuresInfo(data.structures);
    if (data.ovary_note != null) {
        ovaryInfo.push(data.ovary_note);
    }

    const ovary = (
        <div style={{display:"flex", alignItems:"flex-start"}}>
            <OvarianViewer structures={structures} containerStyles={{width:"180px", border:"solid 1px #AAAAAA", borderRadius:"6px"}} />
            <InfoPopup placement="top" message={ovaryInfo} iconType="info" iconStyle={{fontSize:"0.9rem"}}/>
        </div>
    ) 

    return (<>{texts}{ovary}</>);
}

const buildOvaryStructuresInfo = (structures: Readonly<IBreedingStructure[]>) => {
    const structsDataGrps = [...CommonUtil.groupBy(structures, st => st.item_kind).values()];
    const toKindName = (kind:number) => findBreedingStructure(kind)?.name ?? "";
    const toSideName = (side:number) => BREEDING_STRUCTURE_SIDE.L === side ? "左" : BREEDING_STRUCTURE_SIDE.R === side ? "右" : "";
    
    return structsDataGrps.map(g => `${toKindName(g[0].item_kind)}: ${CommonUtil.orderBy(g, g => g.side).map(st => toSideName(st.side) + st.item_count).join(" ")}`);
}

const buildBreedingHisStr = (data: IHisBreeding | CowSearchBreedingDto) => {
    const rtn: string[] = [];
    if (anyVal(data.pregnant_score)) {
        rtn.push(buildBreedStrRow("妊娠鑑定",
            { val:data.pregnant_score, symbol:true }));
    }
    if (anyVal(data.bcs)) {
        rtn.push(buildBreedStrRow("BCS",
        { val:data.bcs }));
    }
    if (anyVal(data.vulva_blood_score, data.vulva_expand_score)) {
        rtn.push(buildBreedStrRow("外陰部",
            { pre:"充血", val:data.vulva_blood_score, symbol:true },
            { pre:"腫脹", val:data.vulva_expand_score, symbol:true }));
    }
    if (anyVal(data.vagina_tear_score, data.vagina_mucus_score, data.vagina_rut_score)) {
        rtn.push(buildBreedStrRow("膣",
            { pre:"裂傷", val:data.vagina_tear_score, symbol:true },
            { pre:"膣粘液", val:data.vagina_mucus_score },
            { pre:"発情粘液", val:data.vagina_rut_score, symbol:true }));
    }
    if (anyVal(data.canal_tear_score, data.canal_cm)) {
        rtn.push(buildBreedStrRow("子宮頸管",
            { pre:"裂傷", val:data.canal_tear_score, symbol:true },
            { pre:"径", val:data.canal_cm, suf:"cm" }));
    }
    if (anyVal(data.cornu_l_cm, data.cornu_l_bright_score, data.cornu_l_image_score, data.cornu_l_shrink_score)) {
        rtn.push(buildBreedStrRow("左子宮角",
            { pre:"径", val:data.cornu_l_cm, suf:"cm" },
            { pre:"ｴｺｰ輝度", val:data.cornu_l_bright_score },
            { pre:"ｴｺｰﾌﾘｰ像", val:data.cornu_l_image_score },
            { pre:"子宮収縮", val:data.cornu_l_shrink_score, symbol:true }));
    }
    if (anyVal(data.cornu_r_cm, data.cornu_r_bright_score, data.cornu_r_image_score, data.cornu_r_shrink_score)) {
        rtn.push(buildBreedStrRow("右子宮角",
            { pre:"径", val:data.cornu_r_cm, suf:"cm" },
            { pre:"ｴｺｰ輝度", val:data.cornu_r_bright_score },
            { pre:"ｴｺｰﾌﾘｰ像", val:data.cornu_r_image_score },
            { pre:"子宮収縮", val:data.cornu_r_shrink_score, symbol:true }));
    }
    if (anyVal(data.ovary_l_mm, data.ovary_r_mm)) {
        rtn.push(buildBreedStrRow("卵巣",
            { pre:"左", val:data.ovary_l_mm, suf:"mm" },
            { pre:"右", val:data.ovary_r_mm, suf:"mm" }));
    }
    return rtn.length === 0 ? "診察項目なし" : rtn.join("／");
}
export const renderBreedingHisInfo = (data: IHisBreeding) => {
    return <>{ buildBreedingHisStr(data) }</>
}
export const buildCowSearchBreeding = (data: CowSearchBreedingDto): { summary: string, structures: string[] } => {
    const summary = buildBreedingHisStr(data);
    const structures = buildOvaryStructuresInfo(data.structures);

    return { summary, structures };
}

export const renderCrossInfo = (cross: IBreedingCross,
        watched_at:string,
        deliveryHistories:IDeliveryHis[],
        crossHistories:CrossHistory[],
        canRefName: boolean,
        onClick?: (cross_no:number)=>void) => {

    let egg:string|undefined;
    if (cross.cross_type === 2 && cross.seed_lot_id != null && canRefName) {
        const rank = getEggRankName(cross) ?? "";
        const etype = getEggTypeName(cross) ?? "";
        const stage = getEggStageName(cross) ?? "";
        egg = `${etype} ${rank} ${stage}`;
    }

    const summary = buildCrossSummaryStr(cross, canRefName);

    const summaryDiv = ((cross.cross_type === 1 || cross.cross_type === 2) && cross.seed_lot_id != null && onClick != null)
                     ? <div className="link" onClick={()=> onClick(cross.cross_no)}>{summary}</div>
                     : <div>{summary}</div>
    return (<>
        <div>{buildCrossTimesStr(cross.cross_no, watched_at, deliveryHistories, crossHistories)}</div>
        { summaryDiv }
        { egg !== undefined && <div>{egg}</div>}
    </>)
}
export const renderCrossHisInfo = (cross: IHisBreedingCross,
        canRefName: boolean,
        forTimes: {
            watched_at:string,
            deliveryHistories:Readonly<IDeliveryHis[]>,
            crossHistories:Readonly<CrossHistory[]>
        }) => {

    const summary = buildCrossSummaryStr(cross, canRefName);
    const times = buildCrossTimesStr(cross.cross_no, forTimes.watched_at, forTimes.deliveryHistories, forTimes.crossHistories);
    return <>{summary} {times}</>
}
export const buildCowSearchCross = (cross: IHisBreedingCross, canRefName: boolean) => {
    return buildCrossSummaryStr(cross, canRefName);
}

export const GetPedigreeStr = (seed: Readonly<IBreedingCross | IHisBreedingCross>):string => {

    const ancestor_1  = seed.seed_lot_id == null ? seed.father_name : seed.seed_ancestor_1;
    const mother_name = seed.seed_lot_id == null ? seed.mother_name : seed.seed_name;
    const ancestor_2  = seed.seed_lot_id == null ? seed.ancestor_2  : seed.seed_ancestor_2;
    const ancestor_3  = seed.seed_lot_id == null ? seed.ancestor_3  : seed.seed_ancestor_3;
    const ancestor_4  = seed.seed_lot_id == null ? undefined        : seed.seed_ancestor_4;

    if((ancestor_1 ?? "") !== "") {
        let ancestor = ancestor_2 ?? "";
        ancestor += (ancestor_3 ?? "") !== "" ? `×${ancestor_3}` : "";
        ancestor += (ancestor_4 ?? "") !== "" ? `×${ancestor_4}` : "";

        if ((mother_name ?? "") !== "") {
            if (ancestor !== "") {
                return `${ancestor_1}×${mother_name}(${ancestor})`;
            }
            return `${ancestor_1}×${mother_name}`;
        } else {
            return `${ancestor_1}×${ancestor}`;
        }
    }
    if((seed.seed_sname ?? "") !== "") {
        return `${seed.seed_name}(${seed.seed_sname})`;
    }
    return seed.seed_name ?? "";
};
const buildCrossSummaryStr = (cross: IBreedingCross | IHisBreedingCross, canRefName: boolean) => {
    if (cross.cross_type === A.CROSS_TYPE.get("AI")?.no) {
        if (!canRefName) return "AI";
        return cross.seed_lot_id == null ? `AI：${cross.father_name}` : `AI：${cross.seed_name}（${cross.seed_sname}）`;
    }
    if (cross.cross_type === A.CROSS_TYPE.get("ET")?.no) {
        if (!canRefName) return "ET";

        return `ET：${GetPedigreeStr(cross)}`;
    }

    if (!canRefName) return "本交";
    return `本交：${cross.father_name ?? ""}`;
}
const buildCrossTimesStr = (cross_no: number, watched_at: string, deliveryHistories:Readonly<IDeliveryHis[]>, crossHistories:Readonly<CrossHistory[]>) => {
    const crossAt = moment(watched_at).startOf("day");
    const nextDeliIdx = deliveryHistories.findIndex(d => crossAt.isBefore(d.delivered_at));
    const lastDeliIdx = nextDeliIdx < 0 ? deliveryHistories.length - 1 : nextDeliIdx - 1;
    const lastDeliAt = lastDeliIdx < 0 ? null : moment(deliveryHistories[lastDeliIdx].delivered_at).startOf("day");

    const pastDays = lastDeliAt == null ? null : "分娩後" + crossAt.diff(lastDeliAt, "days") + "日";
    const trgCrossHis = lastDeliAt == null
                    ? crossHistories
                    : CommonUtil.skipWhlie(crossHistories, h => lastDeliAt.isAfter(h.watched_at));
    const t = trgCrossHis.findIndex(h => h.cross_no === cross_no) + 1;
    const crossTimes = t === 0 ? "" : t + "回目";

    return pastDays == null ? crossTimes : `${pastDays} ${crossTimes}`;
}

export const renderDeliveryInfo = (delivery:IDelivery,
        onNewCow?:(delivery:IDelivery, child_no:number)=>void,
        onMoveToChildTop?:(cow_id:number)=>void) => {
    const rtn: Array<{value:string, addElm?:ReactNode}> = [];

    if (delivery.is_aborted === 1) {
        rtn.push({value:"流産"});
    } else {
        const difc = A.DELIVERY_DIFFICULTY.find(d => d.no === delivery.difficulty);
        if (difc != null) {
            rtn.push({value:`分娩難易度：${difc.sname}(${difc.no})`});
        }
    }

    delivery.children.forEach(child => {

        const cow = child.cow;
        const additional = (cow == null && onNewCow != null)
                        ? <span className="link" onClick={() => onNewCow(delivery, child.child_no)}>(子牛を登録)</span>
                        : (cow != null && onMoveToChildTop != null)
                        ? <span className="link" onClick={() => onMoveToChildTop(cow.cow_id)}>{CowToDispInfo(cow)}</span>
                        : undefined

        rtn.push({value:`${child.child_no}子目 ${buildDeliveryChildStatus(child)}`, addElm:additional});
    })

    return rtn.map((s,i) => s.addElm == null ? (<div key={i}>{s.value}</div>) : (
        <div key={i}>
            <span>{s.value} </span>
            {s.addElm}
        </div>
    ));
}
const buildDeliveryHisStr = (delivery: IHisDelivery) => {
    let str: string;
    if (delivery.is_aborted === 1) {
        str = "流産";

    } else {
        const children: Readonly<Array<IHisDeliveryCow | DeliveryCowDto>> = delivery.children;
        str = children.map(ch => buildDeliveryChildStatus(ch)).join("／");
    }
    return str;
}
const buildDeliveryChildStatus = (child: IDeliveryCow | IHisDeliveryCow | DeliveryCowDto) => {
    const status = A.LIFE_STATES.find(s => s.no === child.life_status)?.name ?? "";
    const sex = A.GET_SEX_MARK(child.is_male);
    const kg = child.weight_kg == null ? null : child.weight_kg + "kg";

    return kg == null ? `${status} ${sex}` : `${status} ${sex} ${kg}`;
}
export const renderDeliveryHisInfo = (delivery: IHisDelivery) => {
    return <>{buildDeliveryHisStr(delivery)}</>
}
export const buildCowSearchDelivery = (delivery: CowSearchDeliveryDto) => {
    if (delivery.is_aborted === 1) {
        return "流産";
    }

    const difc = A.DELIVERY_DIFFICULTY.find(d => d.no === delivery.difficulty);
    const difcStr = difc == null ? "" : `${difc.sname}(${difc.no}) `;

    const children: Readonly<Array<IHisDeliveryCow | DeliveryCowDto>> = delivery.children;
    const chiStr = children.map(ch => buildDeliveryChildStatus(ch)).join("／");

    return difcStr + chiStr;
}


export const renderRutInfo = (rut: IRut) => {
    const type = rut.watched_type === A.RUT_WATCHED_TYPE.PERHAPS_RUT.no ? "※" + A.RUT_WATCHED_TYPE.PERHAPS_RUT.name : "";
    return <div data-testid="rut-watched">{buildRutWatchedStr(rut)} {type}</div>;
}
export const renderRutHisInfo = (rut: IHisRut) => {
    return <>{buildRutWatchedStr(rut)}</>
}
export const buildCowSearchRut = (rut: CowSearchRutDto) => buildRutWatchedStr(rut)

export const buildLatestRutForCross = (rut: RutLatestDto) => {
    const type = rut.watched_type === A.RUT_WATCHED_TYPE.PERHAPS_RUT.no ? "※" + A.RUT_WATCHED_TYPE.PERHAPS_RUT.name : "";
    return `${moment(rut.watched_at).format("M月D日")} ${buildRutWatchedStr(rut)} ${type}`;
}

const buildRutWatchedStr = (rut: { signs: number }) => {
    const signStr = A.RUT_SIGNS.filter(s => (s.sign & rut.signs) === s.sign)
                    .map(s => s.name)
                    .join(", ");
    return signStr === "" ? "観察項目なし" : signStr;
}

export const buildFeedingHisStr = (feed: IHisFeed,
                                feedList: FreezedArray<Pick<IRanchFeed, "feed_no"|"name"|"unit">>,
                                feedTypes: FreezedArray<Pick<IFeedType, "feed_type_no"|"name">>,
                                canRefFeedAmount: boolean) => {
    if (feed.feed_type_no === 0 || feed.feed_no === 0) return "内容なし";

    const feedItem = feedList.find(f => f.feed_no === feed.feed_no);
    const feedType = feedTypes.find(t => t.feed_type_no === feed.feed_type_no);

    const feedName = feedItem?.name ?? feedType?.name ?? "";
    if (!canRefFeedAmount) {
        return feedName;
    }

    const unit = feedItem?.unit ?? "";
    const amount = new Big(feed.delivered).minus(feed.leftover ?? 0);

    return `${feedName} ${amount}${unit}`;
}

export const buildFeedingDetailStr = (amount: { feed_no: number, feed_type_no: number, delivered: number, leftover: number | undefined },
                                        feedList: Readonly<Readonly<IRanchFeed>[]>,
                                        feedTypes: Readonly<Readonly<IFeedType>[]>,
                                        canRefFeedAmount: boolean) => {
    const feed = feedList.find(f => f.feed_no === amount.feed_no);
    const fType = feedTypes.find(t => t.feed_type_no === amount.feed_type_no);

    const name = feed?.name ?? fType?.name ?? "";
    if (!canRefFeedAmount) return name;

    const unit = feed?.unit ?? "";
    let res = `${name} ${amount.delivered}${unit}`;
    if ((amount.leftover ?? -1) === -1) return res;

    return `${res}  残 ${amount.leftover}${unit}`;
}

export const selectTreatTitle = (treat_kind_no: number|undefined, recordType:"symptom"|"prevention"|"breeding", treatKindList: Readonly<Readonly<ITreatKind>[]>) => {

    if (treat_kind_no == null) {
        return recordType === "breeding" ? "投薬"
            : recordType === "prevention" ? "予防"
            : "治療";
    }

    const treatKind = treatKindList.find(t => t.treat_kind_no === treat_kind_no);
    if (treatKind == null) return "";

    return `${treatKind.short_name}`;
}

export const renderDisease = (event: SympEventDto | IBreeding) => {
    const lines: { val:string, testId: string }[] = [];

    (event.diseases ?? []).forEach((d,i) => {
        const vals = [
            `診断${i + 1}：${d.disease_name}`,
            (d.cause_name ?? "") === "" ? undefined : `- ${d.cause_name}`,
            d.code_no == null ? undefined : `(${formatDiseaseCode(d.code_no)})`
        ]
        lines.push({ val: vals.filter(v => v != null).join(" "), testId: "symptom-disease" });
    });
    if ("notice" in event && event.notice !== "") {
        lines.push({ val:`稟告：${event.notice}`, testId: "symptom-notice" });
    }
    if ("condition" in event && event.condition.length > 0) {
        lines.push({ val:`症状：${event.condition.map(c => c.detail_name).join(", ")}`, testId: "symptom-condition"});
    }

    if (lines.length === 0) return <></>;

    return (
        <div style={{ padding:"4px", borderRadius:"4px", background: "#F9F9F9", border: "solid 1px #BBB" }}>
            { lines.map((l,i) => <div key={i} className="m-2" data-testid={l.testId}>{l.val}</div>)}
        </div>
    );
}

const buildSymptomPhysicalStr = (event: SympEventDto|IHisSymptom|CowSearchSymptomPhysicalDto|EditingPhisical, feces_states: Readonly<Readonly<IFecesState>[]>, whenBlank: string) => {
    let str = "";
    if (event.temperature_x10 != null) {
        str = "体温 " + (event.temperature_x10 / 10.0) + "˚C";
    }
    if (event.active_score != null) {
        if (str !== "") {
            str += "／"
        }
        str += "活力 " + (CommonUtil.getActiveLevelStr(event.active_score));
    }
    if (event.hungry_score != null) {
        if (str !== "") {
            str += "／"
        }
        str += "食欲 " + (CommonUtil.getHungryLevelStr(event.hungry_score));
    }
    if (event.heart_rate != null) {
        if (str !== "") {
            str += "／"
        }
        str += "心拍 " + event.heart_rate;
    }
    if (event.breath_count != null) {
        const score = ("breath_score" in event) ? event.breath_score : calcBreathScore(event.breath_count)
        if (str !== "") {
            str += "／"
        }
        str += `呼吸 ${(event.breath_count != null ? event.breath_count : "")}（${(CommonUtil.getBreathLevelStr(score))}）`;
    }
    if (event.feces_state != null || (event.feces_color ?? "") !== "") {
        if (str !== "") {
            str += "／"
        }

        str += "便 ";
        if (event.feces_state != null) {
            str += feces_states.find(f => f.state_no === event.feces_state)?.name ?? "";
        }
    }

    if (str === "" && (event.feces_color ?? "") === "" && event.symptom_name === "") {
        str = whenBlank;
    }
    return str;
}

export const buildPhysicalStrForBulkTreat = (data: EditingPhisical, feces_states: IFecesState[], feces_colors: IFecesColor[]) => {
    let str = buildSymptomPhysicalStr(data, feces_states, "内容なし");
    if ((data.feces_color ?? "") !== "") {
        str += ` ${feces_colors.find(c => c.feces_color === data.feces_color)?.name ?? ""}`;
    }
    return str;
}

export const buildCowSearchSymptom = (event: CowSearchSymptomPhysicalDto,
                feces_states: Readonly<Readonly<IFecesState>[]>,
                feces_colors: Readonly<Readonly<IFecesColor>[]>) => {

    let str = buildSymptomPhysicalStr(event, feces_states, "");
    
    if (event.symptom_name !== "") {
        str = `${event.symptom_name}：${str}`; 
    }
    if ((event.feces_color ?? "") !== "") {
        str += ` ${feces_colors.find(c => c.feces_color === event.feces_color)?.name ?? ""}`;
    }
    
    return str;
}

export const renderSymptomStr = (event: SympEventDto|IHisSymptom, feces_states: Readonly<Readonly<IFecesState>[]>, feces_colors: Readonly<Readonly<IFecesColor>[]>) => {
    const str = buildSymptomPhysicalStr(event, feces_states, "内容なし");

    return (
        <>                                                            
            { event.symptom_name !== "" && (
                <div data-testid="symptom-reason">{event.symptom_name}</div>
            )}
            { str !== "" && (
                <div data-testid="symptom-physical">
                    <span>{str} &nbsp;</span>
                    {
                        (event.feces_color ?? "") !== "" && (
                            <span>
                                <span style={{
                                    height: "16px",
                                    background: "#" + event.feces_color
                                }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                &nbsp;{feces_colors.find(c => c.feces_color === event.feces_color)?.name ?? ""}
                            </span>
                        )
                    }
                </div>
            )}
        </>
    );
}

export const renderSellCowHisInfo = (event: IHisSellCow) => {
    const sellType = COW_SELL_TYPES.find(k => COW_SELL_TYPE[k].value === event.sell_type);
    if (sellType == null) return <></>

    return <>{COW_SELL_TYPE[sellType].name}</>
}

export const renderCowSellMilkHisInfo = (event: IHisCowSellMilk) => {
    if (event.amount_discard > 0) {
        return <>乳量(廃棄) {event.amount_discard}kg</>
    } else {
        return <>乳量 {event.amount}kg</>
    }
}

export const renderGrowthHisStr = (event: Partial<IGrowth>) => {
    const arr:string[] = [];
    if(event.weight != null) arr.push(`体重 ${event.weight}kg`);
    if(event.height != null) arr.push(`体高 ${event.height}cm`);
    if(event.chest != null) arr.push(`胸囲 ${event.chest}cm`);
    if(event.abdomen != null) arr.push(`腹囲 ${event.abdomen}cm`);
    if(event.bcs != null) arr.push(`BCS ${event.bcs}`);
    if(event.rfs != null) arr.push(`RFS ${event.rfs}`);
    return <>{arr.join('／')}</>;
}
export const renderGrowthEvent = (event: GrowthEventDto) => {
    return (<>
        { event.weight != null && (
            <div style={{ display:"flex", alignItems:"center" }}>
                <span className="m-r-10">体重 {event.weight}kg</span>
                { event.birth_weight != null && event.birth_weight.birthday !== moment(event.watched_at).format("YYYY-MM-DD") && (
                    <DgViewer
                        stScore={event.birth_weight.actual ?? event.birth_weight.standard}
                        stDate={event.birth_weight.birthday}
                        isStandardScoreUsed={event.birth_weight.actual == null}
                        edScore={event.weight}
                        edDate={moment(event.watched_at).toDate()}
                    />
                )}
            </div>
        )}
        { event.height != null && <div>体高 {event.height}cm</div> }
        { event.chest != null && <div>胸囲 {event.chest}cm</div> }
        { event.abdomen != null && <div>腹囲 {event.abdomen}cm</div> }
        { event.bcs != null && <div>BCS {event.bcs}</div> }
        { event.rfs != null && <div>RFS {event.rfs}</div> }
    </>)
}

export const buildEggRankCountItemText = (item: IEggRankAndCount) => {
    const rank = findEggRank(item.egg_rank)?.name ?? "";
    return `${rank} ${item.egg_count}個`;
}
export const buildEggRankCountsText = (items: FreezedArray<IEggRankAndCount>) => {
    return items.map(i => buildEggRankCountItemText(i)).join("／");
}
export const buildOvumRankCountItemText = (item: IOvumRankAndCount) => {
    const rank = findOvumRank(item.ovum_rank)?.name ?? "";
    return `${rank} ${item.egg_count}個`;
}
export const buildOvumRankCountsText = (items: FreezedArray<IOvumRankAndCount>) => {
    return items.map(i => buildOvumRankCountItemText(i)).join("／");
}
export const renderSovHis = (event: IHisSov) => {
    const rank = buildEggRankCountsText(event.ranks);
    if (event.father_name == null) {
        return <>{rank}</>
    } else {
        return <>{rank}<br/>{event.father_name}</>;
    }
}
export const renderOpuHis = (event: IHisOpu) => {
    return <>{buildOvumRankCountsText(event.ranks)}</>;
}
export const renderIvfHis = (event: IHisIvf) => {
    const rank = buildOvumRankCountsText(event.ranks);
    if (event.father_name == null) {
        return <>{rank}</>
    } else {
        return <>{rank}<br/>{event.father_name}</>
    }
}
export const renderIvfEggHis = (event: IHisIvfEgg) => {
    return <>{buildEggRankCountsText(event.ranks)}</>
}

const buildStageItemText = (stage: { egg_stage: number, egg_count: number, is_frozen: number }) => {
    const stgName = findEggStage(stage.egg_stage)?.name ?? "";
    return `${stgName} ${stage.is_frozen === 1 ? "凍結" : "新鮮"} ×${stage.egg_count}`;
}
const buildStageTexts = (stages: { egg_stage: number, egg_count: number, is_frozen: number }[]) => {
    return stages.map(s => buildStageItemText(s)).join("／");
}

export const renderSovEvent = (event: ISovEvent, onStock: (() => void) | undefined) => {
    const res: string[] = [];
    if (event.father_name != null) {
        res.push("種雄牛：" + event.father_name);
    }
    event.ranks.forEach(rnk => {
        const r = buildEggRankCountItemText(rnk);
        const stage = buildStageTexts(rnk.stages);
        res.push(stage === "" ? r : `${r}（${stage}）`);
    });

    const canStock = onStock != null
                && event.father_exid != null
                && event.ranks.some(r => r.stages.some(s => s.seed_lot_id == null));

    return (<>
        { res.map((r,i) => (
            <div data-testid="sov-detail" key={i}>{r}</div>
        ))}
        { canStock && (
            <div style={{ display:"flex", justifyContent:"flex-end" }}>
                <IconLink text="在庫登録" onClick={onStock} iconType="stock" />
            </div>
        )}
    </>)
}

export const renderOpuEvent = (event: IOpuEvent, onIvfWrite: (() => void) | undefined) => {
    const res: string[] = [];
    event.ranks.forEach(rnk => {
        let txt = buildOvumRankCountItemText(rnk);
        if (rnk.egg_count_l != null) {
            txt += ` (L${rnk.egg_count_l} R${rnk.egg_count-rnk.egg_count_l})`;
        }
        res.push(txt);
    })
    //合計個数がopuのほうが多ければ、まだ媒精記録可能とみなす
    const opuTotal = ar.sum(event.ranks.map(r => r.egg_count));
    const ivfTotal = ar.sum(event.ivfs.map(iv => ar.sum(iv.ranks.map(ivr => ivr.egg_count))));
    const canWrite = onIvfWrite != null && ivfTotal < opuTotal;

    return (<>
        { res.map((r,i) => (
            <div data-testid="opu-detail" key={i}>{r}</div>
        ))}
        { canWrite && (
            <div>
                <IconLink text="媒精を記録" iconType="navigate" onClick={onIvfWrite} />
            </div>
        )}
    </>)
}

export const renderIvfEvent = (event: IIvfEvent, onIvfEggWrite: (() => void) | undefined, onSeedClick: (() => void) | undefined) => {
    let seed: string | undefined = undefined;
    if (event.seed_name != null) {
        const sname = event.seed_sname ?? "";
        seed = sname === "" ? event.seed_name : `${event.seed_name}（${sname}）`;
    }

    const rankTexts = event.ranks.map(rnk => buildOvumRankCountItemText(rnk));

    const canWrite = onIvfEggWrite != null && event.egg == null;

    return (<>
        { seed != null && (
            <div data-testid="ivf-seed" className={onSeedClick != null ? "link" : undefined} onClick={onSeedClick}>{seed}</div>
        )}
        { rankTexts.map((r,i) => (
            <div data-testid="ivf-detail" key={i}>{r}</div>
        ))}
        { canWrite && (
            <div>
                <IconLink text="培養結果を記録" iconType="navigate" onClick={onIvfEggWrite} />
            </div>
        )}
    </>)
}

export const renderIvfEggEvent = (event: IIvfEggEvent, onStock: (() => void) | undefined, onRankDetail: (() => void) | undefined) => {
    const ovumTotal = ar.sum(event.ivf_ranks.map(r => r.egg_count));

    const res: string[] = [];
    event.ranks.forEach(rnk => {
        const r = buildEggRankCountItemText(rnk);
        const grown = calcGrownPercent(ovumTotal, rnk.egg_count);

        const rnkTxt = grown == null ? r : `${r}　発生率 ${grown}%`;

        const stage = buildStageTexts(rnk.stages);

        res.push(stage === "" ? rnkTxt : `${rnkTxt}（${stage}）`);
    });

    const canStock = onStock != null
                && event.ivf_father_exid != null
                && event.ranks.some(r => r.stages.some(s => s.seed_lot_id == null));

    const hasDetail = onRankDetail != null
                && event.ranks.some(r => r.details.length > 0);

    return (<>
        { res.map((r,i) => (
            <div data-testid="ivf-egg-detail" key={i}>{r}</div>
        ))}
        { (canStock || hasDetail) && (
            <div style={{ display:"flex", justifyContent: "space-between"}}>
                <IconLink iconType="popup" text="内訳" style={{visibility: hasDetail ? "visible" : "hidden" }} onClick={onRankDetail} />
                <IconLink iconType="stock" text="在庫登録" style={{visibility: canStock ? "visible" : "hidden" }} onClick={onStock} />
            </div>
        )}
    </>)
}