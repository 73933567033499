import React from 'react';
import { CSSTransition } from 'react-transition-group';

interface MyProps {
    children: React.ReactChild;
    isVisible: boolean;
    onEntering?: () => void;
}

export const FadeInPanel = (props: MyProps) => {
    return (
        <CSSTransition classNames="fade" timeout={400} unmountOnExit={true} onEntering={props.onEntering} in={props.isVisible}>
            {props.children}
        </CSSTransition>
    )
}